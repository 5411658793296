<template>
    <div class="configurationactivityedit">
        <br>
        <br>
        <div v-if="edit_case===0">
            <div class="container">
                <div class="columns is-centered">
                    <h1 class="is-size-2" style="text-align:center;">Edición del tipo de actividades</h1>
                </div>
                <br>
                <div v-if="isActivityTypesEmpty">
                    <div class="columns is-centered">
                        <h1>
                            No hay registros sobre los tipos de actividades existentes.
                        </h1>
                    </div>
                </div>
                <div v-else>
                    <span class="is-pulled-right">
                        <button class="button is-primary" @click="newActivityType()">
                            <b-icon icon="plus"></b-icon>
                            <span>Añadir un nuevo tipo de actividad</span>
                        </button>
                    </span>
                    <br>
                    <br>
                    <b-table
                        :data="activity_types"
                        :paginated=true
                        per-page=10
                        striped
                        hoverable
                        :current-page="currentPage"
                        :pagination-simple=false
                        pagination-position="bottom"
                        default-sort-direction="asc"
                        sort-icon="arrow-up"
                        sort-icon-size="is-small"
                        default-sort="publication.title"
                        aria-next-label="Siguiente"
                        aria-previous-label="Anterior"
                        aria-page-label="Página"
                        aria-current-label="Página actual">
                        <b-table-column
                            field= 'name'
                            label= 'Nombre'
                            v-slot="props"
                        >
                        {{ props.row.name }}
                        </b-table-column>
                        <b-table-column
                            field= 'description'
                            label= 'Descripción'
                            v-slot="props"
                        >
                        {{ props.row.description }}
                        </b-table-column>
                        <b-table-column v-slot="props">
                            <button class="button is-warning" @click="editActivityType(props.row)">
                                <b-icon icon="edit"></b-icon>
                                <span>Editar</span>
                            </button>
                        </b-table-column>
                        <b-table-column v-slot="props">
                            <button class="button is-danger" @click="confirmCustomDelete(props.row)">
                                <b-icon icon="times"></b-icon>
                                <span>Eliminar</span>
                            </button>
                        </b-table-column>
                    </b-table>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="container">
                <div class="columns is-centered">
                    <h1 class="is-size-2" style="text-align:center;">Edición del tipo de participantes</h1>
                </div>
                <br>
                <div v-if="isActivityAssistantTypesEmpty">
                    <div class="columns is-centered">
                        <h1>
                            No hay registros sobre los tipos de asistentes a actividades existentes.
                        </h1>
                    </div>
                </div>
                <div v-else>
                    <span class="is-pulled-right">
                        <button class="button is-primary" @click="newActivityAssistantType()">
                            <b-icon icon="plus"></b-icon>
                            <span>Añadir un nuevo tipo de participante</span>
                        </button>
                    </span>
                    <br>
                    <br>
                    <b-table
                        :data="activity_assistant_types"
                        :paginated=true
                        per-page=10
                        striped
                        hoverable
                        :current-page="currentPage"
                        :pagination-simple=false
                        pagination-position="bottom"
                        default-sort-direction="asc"
                        sort-icon="arrow-up"
                        sort-icon-size="is-small"
                        default-sort="publication.title"
                        aria-next-label="Siguiente"
                        aria-previous-label="Anterior"
                        aria-page-label="Página"
                        aria-current-label="Página actual">
                        <b-table-column
                            field= 'name'
                            label= 'Nombre'
                            v-slot="props"
                        >
                        {{ props.row.name }}
                        </b-table-column>
                        <b-table-column
                            field= 'assistant_type'
                            label= 'Tipo de participante'
                            v-slot="props"
                        >
                        <div v-if="props.row.assistant_type == 0">Persona</div>
                        <div v-else>Entidad</div>
                        </b-table-column>
                        <b-table-column
                            field= 'description'
                            label= 'Descripción'
                            v-slot="props"
                        >
                        {{ props.row.description }}
                        </b-table-column>
                        <b-table-column v-slot="props">
                            <button class="button is-warning" @click="editActivityAssistantType(props.row)">
                                <b-icon icon="edit"></b-icon>
                                <span>Editar</span>
                            </button>
                        </b-table-column>
                        <b-table-column v-slot="props">
                            <button class="button is-danger" @click="confirmActivityAssistantDelete(props.row)">
                                <b-icon icon="times"></b-icon>
                                <span>Eliminar</span>
                            </button>
                        </b-table-column>
                    </b-table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import ConfigurationActivityTypeNewModal from '../new/configuration_activity_type_new_modal.vue'
import ConfigurationActivityAssistantTypeNewModal from '../new/configuration_activity_assistant_type_new_modal.vue'
import ConfigurationActivityEditChangeTypeModal from '../edit/configuration_activity_edit_change_type_modal.vue'
import ConfigurationActivityEditChangeAssistantTypeModal from './configuration_activity_edit_change_assistant_type_modal.vue'

export default {
    name: 'ConfigurationActivityEdit',
    props:{
        edit_case:{
            type: Number
        }
    },
    data(){
        return {
            activity_types: [],
            activity_assistant_types: [],
            currentPage: 1
        }
    },
    computed:{
        isActivityTypesEmpty(){
            return Object.entries(this.activity_types).length === 0
        },
        isActivityAssistantTypesEmpty(){
            return Object.entries(this.activity_assistant_types).length === 0
        },
    },
    beforeMount(){
        this.getVimeActivityTypes()
        this.getVimeActivityAssistantTypes()
    },
    methods:{
        getVimeActivityTypes(){
            axios.get('/vime_activity_types.json')
            .then(response =>{
                this.activity_types = response.data
            })
            .catch(e => console.log(e))
        },
        getVimeActivityAssistantTypes(){
            axios.get('/vime_assistant_types.json')
            .then(response =>{
                this.activity_assistant_types = response.data
            })
            .catch(e => console.log(e))
            this.isLoading = false
        },
        /* Métodos relacionados con la edición de un tipo de activdad */
        addNewActivityType(newActivityType){
            axios.post('/vime_activity_types.json', newActivityType)
            .then( response =>{
                console.log(response.data)
                this.activity_types.push({
                    'id': response.data.id,
                    'name': response.data.name,
                    'description': response.data.description,
                    'created_at': response.data.created_at,
                    'updated_at': response.data.updated_at,
                    'url': response.data.url
                })
            })
            .catch(e => {
                console.log(e)
            })
        },
        saveEditActivityType(editActivityType){
            axios.put('/vime_activity_types/'+editActivityType.id+'.json', {
                'name': editActivityType.name,
                'description': editActivityType.description
            })
            .then( response =>{
                console.log(response.data)
                this.activity_types.forEach(element => {
                    if(element.id ===  editActivityType.id){
                        element.name = editActivityType.name
                        element.description = editActivityType.description
                    }
                });
            })
            .catch(e => {
                console.log(e)
            })
        },
        confirmCustomDelete(deleteActivityType) {
            var totalActivities = 0
            axios.get('/vime_activities/count_activities_by_type/'+deleteActivityType.id)
            .then( response =>{
                console.log(response.data)
                totalActivities = response.data.total_activities
                this.$buefy.dialog.confirm({
                    title: 'Eliminación de un tipo de actividad',
                    message: '¿Quieres eliminar el tipo de actividad: <b>'+deleteActivityType.name+'</b>? Hay un total de '+totalActivities+' actividades de este tipo.',
                    confirmText: 'Eliminar',
                    cancelText: 'Cancelar',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => this.deleteActivityType(deleteActivityType)
                })
            })
            .catch(e => {
                console.log(e)
            })
        },
        deleteActivityType(deleteActivityType){
            this.$buefy.modal.open({
                parent: this,
                component: ConfigurationActivityEditChangeTypeModal,
                hasModalCard: true,
                props:{
                    'vime_activity_types': this.activity_types,
                    'activity_type_to_delete': deleteActivityType 
                },
                events:{
                    'change-activity-type': this.changeActivitiesTypes
                },
                customClass: 'is-primary',
                trapFocus: true
            })
        },
        changeActivitiesTypes(ActivityAssistanceForm){
            var id_old = ActivityAssistanceForm.delete_activity_type.id
            var id_new = ActivityAssistanceForm.new_activity_type
            axios.get('/vime_activities/change_activity_types/idOld/'+id_old+'/idNew/'+id_new)
            .then( response =>{
                axios.delete('/vime_activity_types/'+id_old+'.json')
                .then( response =>{
                    console.log(response.data)
                    this.activity_types = this.activity_types.filter(
                        element => element !== ActivityAssistanceForm.delete_activity_type
                    )
                    this.$buefy.toast.open({
                        message: 'Tipo de actividad eliminada correctamente.',
                        type: 'is-primary'
                    })
                })
                .catch(e => {
                    console.log(e)
                })  
            })
            .catch(e => {
                console.log(e)
            })
        },
        newActivityType(){
            this.$buefy.modal.open({
                parent: this,
                component: ConfigurationActivityTypeNewModal,
                hasModalCard: true,
                props:{
                    'edit_case': 0,
                    'activity_type_edit': {} 
                },
                events:{
                    'add-new-activity-type': this.addNewActivityType
                },
                customClass: 'is-primary',
                trapFocus: true
            })
        },
        editActivityType(activityType){
            this.$buefy.modal.open({
                parent: this,
                component: ConfigurationActivityTypeNewModal,
                hasModalCard: true,
                props:{
                    'edit_case': 1,
                    'activity_type_edit': activityType
                },
                events:{
                    'edit-activity-type': this.saveEditActivityType
                },
                customClass: 'is-primary',
                trapFocus: true
            })
        },
        /* Métodos relacionados con la edición de un tipo de asistente a una activdad */
        addNewActivityAssistantType(newActivityAssistantType){
            axios.post('/vime_assistant_types.json', newActivityAssistantType)
            .then( response =>{
                console.log(response.data)
                this.activity_assistant_types.push({
                    'id': response.data.id,
                    'name': response.data.name,
                    'assistant_type': response.data.assistant_type,
                    'description': response.data.description,
                    'created_at': response.data.created_at,
                    'updated_at': response.data.updated_at,
                    'url': response.data.url
                })
            })
            .catch(e => {
                console.log(e)
            })
        },
        saveEditActivityAssistantType(editActivityAssistantType){
            axios.put('/vime_assistant_types/'+editActivityAssistantType.id+'.json', {
                'name': editActivityAssistantType.name,
                'description': editActivityAssistantType.description,
                'assistant_type': editActivityAssistantType.assistant_type
            })
            .then( response =>{
                console.log(response.data)
                this.activity_types.forEach(element => {
                    if(element.id ===  editActivityAssistantType.id){
                        element.name = editActivityAssistantType.name
                        element.description = editActivityAssistantType.description
                    }
                });
            })
            .catch(e => {
                console.log(e)
            })
        },
        newActivityAssistantType(){
            this.$buefy.modal.open({
                parent: this,
                component: ConfigurationActivityAssistantTypeNewModal,
                hasModalCard: true,
                props:{
                    'edit_case': 0,
                    'activity_assistant_type_edit': {} 
                },
                events:{
                    'add-new-activity-assistant-type': this.addNewActivityAssistantType
                },
                customClass: 'is-primary',
                trapFocus: true
            })
        },
        editActivityAssistantType(activityAssistantType){
            this.$buefy.modal.open({
                parent: this,
                component: ConfigurationActivityAssistantTypeNewModal,
                hasModalCard: true,
                props:{
                    'edit_case': 1,
                    'activity_assistant_type_edit': activityAssistantType
                },
                events:{
                    'edit-activity-assistant-type': this.saveEditActivityAssistantType
                },
                customClass: 'is-primary',
                trapFocus: true
            })
        },
        confirmActivityAssistantDelete(deleteActivityAssistantType){
            var totalActivityAssistance = 0
            axios.get('/vime_activities/count_activity_assistants_by_type/'+deleteActivityAssistantType.id)
            .then( response =>{
                console.log(response.data)
                totalActivityAssistance = response.data.total_activity_assistance
                this.$buefy.dialog.confirm({
                    title: 'Eliminación de un tipo de asistente',
                    message: '¿Quieres eliminar el tipo de asistente: <b>'+deleteActivityAssistantType.name+'</b>? Hay un total de '+totalActivityAssistance+' asistentes a actividades de este tipo.',
                    confirmText: 'Eliminar',
                    cancelText: 'Cancelar',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        if(totalActivityAssistance > 0){
                            this.deleteActivityAsistantType(deleteActivityAssistantType)
                        }
                        else{
                            axios.delete('/vime_assistant_types/'+deleteActivityAssistantType.id+'.json')
                            .then( response =>{
                                console.log(response.data)
                                this.activity_assistant_types = this.activity_assistant_types.filter(
                                    element => element !== deleteActivityAssistantType
                                )
                                this.$buefy.toast.open({
                                    message: 'Tipo de asistente a actividad eliminado correctamente.',
                                    type: 'is-primary'
                                })
                            })
                            .catch(e => {
                                console.log(e)
                            })
                        }
                    }
                })
            })
            .catch(e => {
                console.log(e)
            })
        },
        deleteActivityAsistantType(deleteActivityAssistantType){
            this.$buefy.modal.open({
                parent: this,
                component: ConfigurationActivityEditChangeAssistantTypeModal,
                hasModalCard: true,
                props:{
                    'vime_activity_assistant_types': this.activity_assistant_types,
                    'activity_assistant_type_to_delete': deleteActivityAssistantType 
                },
                events:{
                    'change-activity-assistant-type': this.changeActivityAssistantTypes
                },
                customClass: 'is-primary',
                trapFocus: true
            })
        },
        changeActivityAssistantTypes(ActivityAssistanceForm){
            var id_old = ActivityAssistanceForm.delete_activity_assistant_type.id
            var id_new = ActivityAssistanceForm.new_activity_assistant_type
            axios.get('/vime_activities/change_activity_assistant_type/idOld/'+id_old+'/idNew/'+id_new)
            .then( response =>{
                axios.delete('/vime_assistant_types/'+id_old+'.json')
                .then( response =>{
                    console.log(response.data)
                    this.activity_assistant_types = this.activity_assistant_types.filter(
                        element => element !== ActivityAssistanceForm.delete_activity_assistant_type
                    )
                    this.$buefy.toast.open({
                        message: 'Tipo de asistente a actividad eliminado correctamente.',
                        type: 'is-primary'
                    })
                })
                .catch(e => {
                    console.log(e)
                })  
            })
            .catch(e => {
                console.log(e)
            })
            /*axios.delete('/vime_assistant_types/'+deleteActivityAssistantType.id+'.json')
            .then( response =>{
                console.log(response.data)
                this.activity_assistant_types = this.activity_assistant_types.filter(
                    element => element !== deleteActivityAssistantType
                )
                this.$buefy.toast.open({
                    message: 'Tipo de asistente a actividad eliminado correctamente.',
                    type: 'is-primary'
                })
            })
            .catch(e => {
                console.log(e)
            })*/
        },
    }
}
</script>