<template>
    <div class="workhistorynewform">
        <div class="container">
           <div class="box">
               <div class="field">
                    <div class="field-label is-normal">
                        <label class="label" style="text-align:left;">Cargo ejercido</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <input class="input" type="text" placeholder="Ingrese el nombre del cargo ejercido" v-model='workHistoryInfo.role_played'>
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column">
                        <div class="field">
                            <div class="field-label is-normal">
                                <label class="label" style="text-align:left;">Institución</label>
                            </div>
                            <div class="field-body">
                                <div class="select">
                                    <select v-model='partner_entity_name'>
                                        <option v-for="entity in partner_entities" :key="entity.id">{{ entity.name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="field">
                            <div class="field-label is-normal">
                                <label class="label" style="text-align:left;">Desde</label>
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <b-datepicker placeholder="Seleccione la fecha de inicio laboral" v-model='workHistoryInfo.start_date_work_activity'> </b-datepicker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="field">
                            <div class="field-label is-normal">
                                <label class="label" style="text-align:left;">Hasta </label>
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <b-datepicker placeholder="Seleccione la fecha de término laboral" v-model='workHistoryInfo.end_date_work_activity'> </b-datepicker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="field">
                            <div class="field-label is-normal">
                                <label class="label" style="text-align:left;">¿Trabajo actual?</label>
                            </div>
                            <div class="field-body">
                                <input type="checkbox" v-model='workHistoryInfo.actual_work'>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field is-grouped is-grouped-right">
                    <div class="control">
                        <button class="button is-primary" @click="save()">Registrar</button>
                    </div>
                    <div class="control">
                        <button class="button is-link is-light" @click="back()">Volver</button>
                    </div>
                </div>
           </div>
       </div>
       <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
    </div>
</template>
<script>
import axios from "axios"
export default {
    name: 'WorkHistoryNewForm',
    props:{
        person_id:{
            type: Number
        }
    },
    data(){
        return {
            partner_entities: [],
            partner_entity_name: '',
            isFullPage: true,
            isLoading: false,
            workHistoryInfo:{
                start_date_work_activity: new Date(),
                end_date_work_activity: new Date(),
                role_played: ' ',
                actual_work: false,
                partner_entity_id: 0,
                person_id:0
            }
        }
    },
    methods:{
        getPartnerEntities(){
            axios.get('/admin/partner_entities.json')
            .then( response =>{
                this.partner_entities = response.data
            })
            .catch(e => { 
                console.log(e)
            })
        },
        getPartnerEntityIdByName(name){
            var id = 0
            this.partner_entities.forEach(function (element){
                if(element.name === name){
                    id = element.id
                }
            })
            return id
        },
        back(){
            this.isLoading=true
            window.location.href="/graduates/show/"+this.person_id
        },
        save(){
            this.workHistoryInfo.partner_entity_id = this.getPartnerEntityIdByName(this.partner_entity_name)
            this.workHistoryInfo.person_id = this.person_id
            axios.post('/work_histories.json', this.workHistoryInfo)
            .then( response =>{
                console.log(response.data)
                window.location.href="/graduates/show/"+this.person_id
            })
            .catch(e => {
                console.log(e)
            })
        }
    },
    beforeMount() {
        this.getPartnerEntities()
    }
}
</script>