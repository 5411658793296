<template>
  <section>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">{{modalHeader}}</p>
          <button
              type="button"
              class="delete"
              @click="$emit('close')"/>
        </header>
        <section class="modal-card-body" ref="cardICCForm">
      <div class="columns">
        <div class="column">
          <b-field label="Estamento">
            <b-input :value="person_unit.estamento" type="text" readonly></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="N° de documento">
            <b-input :value="person_unit.numero_resolucion" type="text" readonly></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Tipo de nombramiento">
            <b-input :value="person_unit.tipo_nombramiento" type="text" readonly></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Unidad de contratación">
            <b-input :value="person_unit.unidad_contratacion" type="text" readonly></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Unidad de desempeño">
            <b-input :value="person_unit.unidad_desempenio" type="text" readonly>
            </b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Jerarquía/Categoría">
            <b-input :value="person_unit.jercat" type="text" readonly></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Grado">
            <b-input :value="person_unit.grado" type="text" readonly></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Rango">
            <b-input :value="person_unit.rango" type="text" readonly></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Nivel">
            <b-input :value="person_unit.nivel" type="text" readonly></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Fecha desde">
            <b-input :value="formatDate(person_unit.inicio)" type="text" readonly></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Fecha hasta">
            <b-input :value="formatDate(person_unit.fin)" type="text" readonly></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Jornada (horas)">
            <b-input :value="person_unit.jornada" type="text" readonly></b-input>
          </b-field>
        </div>
      </div>
        </section>
    </div>
    <footer class="modal-card-foot actions">
      <div class="field is-grouped is-pulled-right">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
      </div>
    </footer>



  </section>
</template>


<script>
import {formatDate} from "../../../packs/utilities";

export default {
  name: "info_appointment",
  props:[
    "id", "modalHeader", "nombramiento"
  ],
  components:{
  },
  data(){
    return {
      isLoading: false,
      person_unit: {},
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      const loadingComponent = this.$buefy.loading.open();
      this.person_unit = this.nombramiento;
      loadingComponent.close();
    },
    formatDate,
  },
}
</script>
