<template>
  <section>
    <form>
      <div class="modal-card" style="width: 1080px">
        <header class="modal-card-head">
          <p class="modal-card-title">Datos de la beca de estudio</p>
          <button
              type="button"
              class="delete"
              @click="$emit('close')"/>
        </header>
        <section class="modal-card-body" style="width: 1080px">
          <div class="columns">
            <div class="column">
              <b-field label="Carácter de la beca">
                <b-input readonly v-model="scholarship.name"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Organismo otorgante">
                <b-input readonly v-model="scholarship.partner_entity_name"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Estudio">
                <b-input readonly v-model="scholarship.study"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Institución">
                <b-input readonly v-model="scholarship.institution_name"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Fecha de inicio">
                <b-input readonly v-model="scholarship.start_date"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Fecha de término">
                <b-input readonly v-model="scholarship.end_date"></b-input>
              </b-field>
            </div>
          </div>
          <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
        </section>
      </div>
      <footer class="modal-card-foot actions">
        <div class="has-text-right is-grouped">
          <button class="button" type="button" @click="$emit('close')">Cerrar</button>
          <b-button class="is-primary"
                    type="submit"
                    @click="editScholarship()">
            Editar beca de estudio
          </b-button>
        </div>
      </footer>
      <b-modal
          v-model="isModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          width="90%"
          aria-role="dialog"
          aria-modal
          :can-cancel="false">
        <template #default="props">
          <modal_new_scholarship
              :person-id="personId"
              :update="update"
              :old_scholarship="old_scholarship"
              @close="props.close"
              @refresh="$emit('refresh')"/>
        </template>
      </b-modal>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import modal_new_scholarship from "./modal_new_scholarship";
import moment from "moment";
export default {
  name: "modal_show_scholarship",
  props:['personId','old_scholarship'],
  components:{modal_new_scholarship},
  data(){
    return {
      isModalActive: false,
      update: false,
      isLoading: false,
      scholarship: {}
    }
  },
  created() {
    this.initialize()
  },
  methods:{
    initialize(){
      this.getScholarship()
    },
    getScholarship(){
      this.update = false
      this.isLoading = true
      axios
          .get('/scholarships/'+this.old_scholarship.id+'.json')
          .then(response => {
            this.scholarship = response.data
            this.scholarship.start_date = moment(this.scholarship.start_date, ["YYYY-MM-DD","DD-MM-YYYY"])._d;
            this.scholarship.start_date = new Date(this.scholarship.start_date).toLocaleDateString("es-CL")
            if (this.scholarship.end_date != null){
              this.scholarship.end_date = moment(this.scholarship.end_date, ["YYYY-MM-DD","DD-MM-YYYY"])._d;
              this.scholarship.end_date = new Date(this.scholarship.end_date).toLocaleDateString("es-CL")
            }
            else{
              this.scholarship.end_date = ''
            }
            this.isLoading = false
          })
          .catch(error=>{
            console.log(error)
            this.isLoading = false
          })
    },
    editScholarship(){
      this.update = true
      this.isModalActive = true
    }
  },
  watch: {
    isModalActive: function () {
      if (!this.isModalActive) {
        this.initialize()
      }
    },
  }
}
</script>

<style scoped>

</style>