<template>
  <section>
    <div class="container">
      <div class="columns">
        <div class="column">
          <div class="control">
            <h1 class="title">Personas externas</h1>
          </div>
        </div>
      </div>
       <external-people-list :can_create="can_create"></external-people-list>
<!--      <div class="box">
        <b-tabs @input="changeTab" type="is-boxed">
          <b-tab-item v-for="item in items"
                      :value="item.content"
                      v-bind:key="item.content"
                      :label="item.tab" >
          </b-tab-item>
        </b-tabs>
        &lt;!&ndash; COMPONENTE AQUI &ndash;&gt;
        <keep-alive>
          <component v-bind:is="currentTabComponent" :person-id="personId"></component>
        </keep-alive>
        &lt;!&ndash; FIN COMPONENTE &ndash;&gt;
      </div>-->
    </div>
  </section>
</template>

<script>
import ExternalPeopleList from "./external_people_list";
export default {
  name: "external_people_tabs",
  components: {'external-people-list':ExternalPeopleList},
  props:['can_create']
}
</script>
