<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <form @submit.prevent="savePerson">
        <div class="modal-card" style="width: 1348px">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" ref="modalPersonForm">
            <div class="columns">
              <div class="column">
                <b-message
                    title="Información Editar"
                    type="is-info"
                    has-icon
                    aria-close-label="Close message">
                  Puede guardar todos los cambios hechos en la persona, o puede guardar parcialmente los cambios de un paso específico.
                </b-message>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion">
                      <div v-if="item">{{ item[0] }}</div>
                    </div>
                  </b-message>
                </div>
              </div>
            </div>

            <div class="is-centered" style="margin-top: 30px;">
              <div class="columns is-centered">
                <div class="box has-text-centered">
                  <template>
                    <b-button
                        outlined
                        type="is-primary"
                        icon-pack="fas"
                        icon-left="chevron-left"
                        :disabled="activeStep==0"
                        @click.prevent="back">
                    </b-button>
                    <b-button
                        outlined
                        type="is-primary"
                        icon-pack="fas"
                        icon-right="chevron-right"
                        :disabled="activeStep==4"
                        @click.prevent="next">
                    </b-button>
                  </template>
                </div>
              </div>
            </div>

            <b-steps :has-navigation="false" v-model="activeStep">
              <b-step-item label="Datos de Identificación" step="1" :clickable="true">
                  <div class="container" style="margin-top: 40px">
                    <div class="columns">
                      <div class="column">
                        <b-field label="Nombres*">
                          <ValidationProvider rules="required" v-slot="{ errors }" name="nombre">
                            <b-input v-model="person.name" type="text"></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Primer Apellido*">
                          <ValidationProvider rules="required" v-slot="{ errors }" name="paterno">
                            <b-input v-model="person.last_name" type="text"></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Segundo Apellido*">
                          <ValidationProvider rules="required" v-slot="{ errors }" name="materno">
                            <b-input v-model="person.second_surname" type="text"></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column is-3">
                        <b-field label="Rut*">
                          <ValidationProvider rules="required|numeric|min_value:1" v-slot="{ errors }" name="rut">
                            <b-input v-model="person.run_attributes.rut"></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                      <div class="column is-1">
                        <b-field label="DV*">
                          <ValidationProvider rules="required" v-slot="{ errors }" name="dv">
                            <b-input v-model="person.run_attributes.dv" maxlength="1" :has-counter="false"></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Correo corporativo*">
                          <ValidationProvider rules="required" v-slot="{ errors }" name="correo_corporativo">
                            <b-input v-model="person.corporate_mail"></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                    </div>
                  </div>

              </b-step-item>
              <!-- Box de Formulario Persona -->
              <b-step-item label="Datos Personales" step="2" :clickable="true">
                <div class="container" style="margin-top: 40px">
                <div class="columns">
                  <div class="column">
                    <b-field label="Fecha de nacimiento*">
                      <ValidationProvider rules="required" v-slot="{errors}" name="fecha_nacimiento">
                        <b-datepicker
                            v-model="person.birthday_date"
                            icon="calendar-alt"
                            trap-focus
                            locale="es-ES"
                            editable
                            placeholder="Formato de Fecha: dd/mm/aaaa"
                        >
                        </b-datepicker>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Género">
                      <multiselect v-model="person.gender_id"
                                   :options="genders.map(type => type.id)"
                                   :custom-label="opt => genders.find(x => x.id === opt).name"
                                   placeholder="Seleccione género"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="Presione para deseleccionar"
                                   :allow-empty="true">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                      </multiselect>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Región">
                      <multiselect v-model="person.region_id"
                                   :options="regions.map(type => type.id)"
                                   :custom-label="opt => regions.find(x => x.id === opt).name"
                                   @input="loadCommunes(person.region_id)"
                                   placeholder="Seleccione Región"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="Presione para deseleccionar"
                                   :allow-empty="true">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                      </multiselect>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Comuna">
                      <multiselect v-model="person.commune_id"
                                   :options="communes.map(type => type.id)"
                                   :custom-label="opt => communes.find(x => x.id === opt).name"
                                   placeholder="Seleccione Comuna"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   @input="fixCommunes(person.commune_id)"
                                   deselectLabel="Presione para deseleccionar"
                                   :allow-empty="true">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                      </multiselect>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Dirección*">
                      <ValidationProvider rules="required" v-slot="{errors}" name="direccion">
                      <b-input v-model="person.address" maxlength="255"></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Nacionalidad">
                      <multiselect v-model="person.nationality_id"
                                   :options="countries.map(type => type.id)"
                                   :custom-label="opt => countries.find(x => x.id === opt).nationality"
                                   placeholder="Seleccione nacionalidad"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="Presione para deseleccionar"
                                   :allow-empty="true">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                      </multiselect>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="País de origen">
                      <multiselect v-model="person.origin_country_id"
                                   :options="countries.map(type => type.id)"
                                   :custom-label="opt => countries.find(x => x.id === opt).name"
                                   placeholder="Seleccione nacionalidad"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="Presione para deseleccionar"
                                   :allow-empty="true">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                      </multiselect>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Lugar de nacimiento">
                      <b-input v-model="person.birthplace"></b-input>
                    </b-field>
                  </div>
                </div>
                </div>



              </b-step-item>
              <b-step-item label="Datos de Contacto" step="3" :clickable="true">
                <div class="container" style="margin-top: 40px">
                <div class="columns">
                  <div class="column">
                    <b-field label="Celular*">

                      <ValidationProvider rules="integer|positive|required" v-slot="{errors}" style="width: 100%" name="celular">

                        <b-input v-model="person.cellphone" expanded></b-input>

                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Teléfono">
                      <ValidationProvider rules="integer|positive" v-slot="{errors}" name="telefono">
                        <b-input v-model="person.phone"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Email personal*">
                      <ValidationProvider rules="email|required" v-slot="{errors}" name="mail_personal">
                        <b-input type="text" v-model="person.personal_mail"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
                </div>
              </b-step-item>
              <b-step-item label="Datos Laborales" step="4" :clickable="true">
                <div class="container" style="margin-top: 40px">
                <div class="columns">
                  <div class="column">
                    <b-field label="Institución de previsión*">
                      <ValidationProvider rules="required" v-slot="{ errors }" name="inst_prevision">
                      <multiselect v-model="person.pension_institution_id"
                                   :options="pension_institutions.map(type => type.id)"
                                   :custom-label="opt => pension_institutions.find(x => x.id === opt).name"
                                   placeholder="Seleccione Institución de previsión"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="Presione para deseleccionar"
                                   :allow-empty="true">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                      </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Institución de salud*">
                      <ValidationProvider rules="required" v-slot="{ errors }" name="inst_salud">
                      <multiselect v-model="person.health_institution_id"
                                   :options="health_institutions.map(type => type.id)"
                                   :custom-label="opt => health_institutions.find(x => x.id === opt).name"
                                   placeholder="Seleccione Institución de salud"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="Presione para deseleccionar"
                                   :allow-empty="true">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                      </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Fecha de ingreso USACH">
                      <ValidationProvider  v-slot="{ errors }" name="ingreso_usach">
                      <b-datepicker
                          v-model="person.university_entrance_date"
                          icon="calendar-alt"
                          trap-focus
                          locale="es-ES"
                          editable
                          placeholder="Formato de Fecha: dd/mm/aaaa"
                      >
                      </b-datepicker>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Fecha de ingreso administración pública">
                      <b-datepicker
                          v-model="person.public_entrance_date"
                          icon="calendar-alt"
                          trap-focus
                          locale="es-ES"
                          editable
                          placeholder="Formato de Fecha: dd/mm/aaaa"
                      >
                      </b-datepicker>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Estado civil*">
                      <ValidationProvider rules="required" v-slot="{ errors }" name="estado_civil">
                      <multiselect v-model="person.civil_status"
                                   :options="marital_statuses.map(type => type.name)"
                                   placeholder="Seleccione Estado civil"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="Presione para deseleccionar"
                                   :allow-empty="true">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                      </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="¿Jubilado(a)?*">
                      <ValidationProvider rules="required" v-slot="{errors}" name="retirado">
                        <div class="columns">
                          <div class="column">
                      <b-radio-button v-model="person.retired"
                                      :native-value="false"
                                      type="is-danger is-light is-outlined"

                      >
                        <b-icon icon="times-circle"></b-icon>
                        <span>No</span>
                      </b-radio-button>
                          </div>
                          <div class="column">
                      <b-radio-button v-model="person.retired"
                                      :native-value="true"
                                      type="is-success is-light is-outlined"
                      >
                        <b-icon icon="check-circle"></b-icon>
                        <span>Si</span>
                      </b-radio-button>
                          </div>
                        </div>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="¿Rinde fianza?*">
                      <ValidationProvider rules="required" v-slot="{errors}" name="fianza">
                        <div class="columns">
                          <div class="column">
                      <b-radio-button v-model="person.pays_bail"
                                      :native-value="false"
                                      type="is-danger is-light is-outlined"
                      >
                        <b-icon icon="times-circle"></b-icon>
                        <span>No</span>
                      </b-radio-button>
                          </div>
                          <div class="column">
                      <b-radio-button v-model="person.pays_bail"
                                      :native-value="true"
                                      type="is-success is-light is-outlined"
                      >
                        <b-icon icon="check-circle"></b-icon>
                        <span>Si</span>
                      </b-radio-button>
                          </div>
                        </div>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
<!--                <div class="columns">
                  <div class="column">
                    <b-field label="Url carpeta documentación Hoja de vida">
                      <ValidationProvider v-slot="{ errors }" rules="max:255">
                        <b-input v-model="person.life_record_link" type="text"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>-->
                </div>
              </b-step-item>
              <b-step-item label="Documentación de ingreso" step="5" :clickable="true">
                <div class="container" style="margin-top: 40px">
                  <div class="columns has-text-centered">
                    <div class="column">
                      <b-field extended label="Archivo PDF (Tamaño max. 25 MB)">
                        <b-upload v-model="files"
                                  drag-drop
                                  multiple
                                  type="is-primary"
                                  accept=".pdf"
                                  @input="processFiles">
                          <section class="section">
                            <div class="content has-text-centered">
                              <p>
                                <b-icon
                                    icon="file-pdf"
                                    size="is-large">
                                </b-icon>
                              </p>
                              <p>Arrastra tus archivos o haz click para subir.</p>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>
                    </div>
                  </div>
                  <b-table :data="process_files"
                           :paginated= true
                           per-page="10"
                           striped
                           hoverable
                           :current-page="currentPage"
                           :pagination-simple=false
                           pagination-position="bottom"
                           default-sort-direction="asc"
                           sort-icon="arrow-up"
                           sort-icon-size="is-small"
                           aria-next-label="Siguiente"
                           aria-previous-label="Anterior"
                           aria-page-label="Página"
                           aria-current-label="Página actual">
                    <b-table-column field="name"
                                    label="Archivo"
                                    sortable
                                    searchable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.name}}
                      </template>
                    </b-table-column>
                    <b-table-column label="Tipo de documento"
                                    v-slot="props"
                                    width="400">
                      <multiselect v-model="props.row.document_type_id" :options="document_types.map(x => x.id)"
                                   :custom-label="opt => document_types.find(x => x.id == opt).name"
                                   placeholder="Seleccione tipo de documento"
                                   selectLabel=""
                                   selectedLabel="Seleccionado"
                                   deselectLabel=""
                                   :allow-empty="false"
                                   @input="changeName(props.index)">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                            No se encontraron elementos.
                          </span>
                      </multiselect>

                    </b-table-column>
                    <b-table-column field="document_name"
                                    label="Nombre Final Documento"
                                    sortable
                                    searchable
                                    width="400">
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.document_name}}
                      </template>
                    </b-table-column>
                    <b-table-column label="Acciones"
                                    v-slot="props">
                      <b-tooltip
                          label="Quitar archivo."
                          position="is-left"
                          :active="true">
                        <b-button
                            pack="fas"
                            icon-left="trash"
                            type="is-danger is-light is-outlined"
                            @click="deleteDropFile(props.index)"/>
                      </b-tooltip>

                    </b-table-column>
                  </b-table>
                </div>
              </b-step-item>
            </b-steps>
            <!-- GUARDAR PERSONA-->
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
          </section>
        </div>
        <footer class="modal-card-foot actions" style="justify-content: space-between;">
          <div class="control is-grouped is-pulled-left move-left">
          <button class="button" type="button" @click="$emit('close')">Cerrar</button>
          <button class="button is-primary" type="submit">Guardar Persona</button>
          </div>
          <button class="button is-primary is-light is-pulled-right float-right"  type="button" @click="savePartial">Guardar Paso {{activeStep+1}}</button>
        </footer>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios"
import moment from "moment"
import * as _ from 'lodash';
import {formatDate} from '../../packs/utilities';
export default {
  name: "modal_person_edit_form",
  data(){
    const data =[]
    return {
      person:{
        run_attributes:{
          rut: null,
          dv: null
        },
        region_id: null,
        is_registered: true
      },
      original_person: {
        run_attributes: {
          rut: null,
          dv: null
        },
        region_id: null,
        is_registered: true
      },
      regions: [],
      genders: [],
      communes: [],
      countries: [],
      marital_statuses: [
        {name: "Soltero(a)" },
        {name: "Casado(a)" },
        {name: "Viudo(a)" },
        {name: "Divorciado(a)" },
        {name: "Conviviente civil" }
      ],
      pension_institutions: [],
      health_institutions: [],
      errores_validacion: {},
      life_record_link:'',
      isLoading:false,
      activeStep: 0,
      cellphone: null,
      progressBar: true,
      files:[],
      process_files:[],
      currentPage: 1,
      document_types: [],
    }
  },
  props:[
    'userId', 'queryType', 'personId', 'modalHeader'
  ],
  created(){
    this.initialize()
  },
  methods: {
    initialize(){
      this.isLoading=true;
      if (this.queryType ==="create"){
        axios
            .get("/people/new.json")
            .then(res => {
              this.regions = res.data["regions"]
              this.genders = res.data["genders"]
              this.countries = res.data["countries"]
              this.pension_institutions = res.data["pension_institutions"]
              this.health_institutions = res.data["health_institutions"]
              this.life_record_link = res.data["life_record_link"]
              this.isLoading = false;
            }).catch(error => {
          console.log(error)
          this.isLoading=false;
        })
      }
      else if (this.queryType === "update"){

        this.isLoading=true;
        axios
            .get("/people/"+this.personId+"/edit.json")
            .then(res => {
              this.regions = res.data["regions"]
              this.genders = res.data["genders"]
              this.countries = res.data["countries"]
              this.pension_institutions = res.data["pension_institutions"]
              this.health_institutions = res.data["health_institutions"]
              this.life_record_link = res.data["life_record_link"]
              if (res.data["communes"] != null) {
                this.communes = res.data["communes"]
              }
              this.person = res.data
              this.original_person = _.cloneDeep(this.person);
              if (res.data["birthday_date"] !== null) {
                this.person.birthday_date = moment(res.data["birthday_date"], 'YYYY-MM-DD')._d;
                this.original_person.birthday_date = moment(res.data["birthday_date"], 'YYYY-MM-DD')._d;
              }
              if (res.data["university_entrance_date"] !== null) {
                this.person.university_entrance_date = moment(res.data["university_entrance_date"], 'YYYY-MM-DD')._d;
                this.original_person.university_entrance_date = moment(res.data["university_entrance_date"], 'YYYY-MM-DD')._d;
              }
              if (res.data["public_entrance_date"] !== null) {
                this.person.public_entrance_date = moment(res.data["public_entrance_date"], 'YYYY-MM-DD')._d;
                this.original_person.public_entrance_date = moment(res.data["public_entrance_date"], 'YYYY-MM-DD')._d;
              }
              this.document_types = res.data["document_types"]
              this.process_files = res.data["process_files"]
              if(this.process_files.length > 0){
                for (const document of this.process_files){
                  this.files.push(document.name)
                }
              }
              this.isLoading=false;
            }).catch(error => {
          console.log(error)
          this.isLoading=false;
        })
      }
    },
    next(){
      if(this.activeStep < 4){
        this.activeStep = this.activeStep +1
      }
    },
    back(){
      if(this.activeStep > 0){
        this.activeStep = this.activeStep -1
      }

    },
    async savePartial(){
      const isValid = await this.$refs.observer.validate();
      if(this.activeStep===0){
        if(this.$refs.observer.fields.nombre.valid===false || this.$refs.observer.fields.paterno.valid===false || this.$refs.observer.fields.correo_corporativo.valid===false
            ||this.$refs.observer.fields.materno.valid===false || this.$refs.observer.fields.rut.valid===false || this.$refs.observer.fields.dv.valid===false){
          return this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Se encontraron errores en el paso 1 del formulario. Revisar formato y campos sin completar.',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        }
        else{
          this.original_person.skip_if_step_one = true
          this.original_person.name = this.person.name
          this.original_person.last_name = this.person.last_name
          this.original_person.second_surname = this.person.second_surname
          this.original_person.run_attributes.rut = this.person.run_attributes.rut
          this.original_person.run_attributes.dv = this.person.run_attributes.dv
          this.original_person.corporate_mail = this.person.corporate_mail
        }
      }
      else if(this.activeStep ===1){
        if(this.$refs.observer.fields.direccion.valid===false
            ||this.$refs.observer.fields.fecha_nacimiento.valid===false){
          return this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Se encontraron errores en el paso 2 del formulario. Revisar formato y campos sin completar.',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        }
        else{
          this.original_person.skip_if_step_two = true
          this.original_person.birthday_date = this.person.birthday_date
          this.original_person.gender_id = this.person.gender_id
          this.original_person.commune_id = this.person.commune_id
          this.original_person.address = this.person.address
          this.original_person.nationality_id = this.person.nationality_id
          this.original_person.origin_country_id = this.person.origin_country_id
          this.original_person.birthplace = this.person.birthplace
        }
      }
      else if (this.activeStep===2){
        if(this.$refs.observer.fields.celular.valid===false || this.$refs.observer.fields.telefono.valid===false || this.$refs.observer.fields.mail_personal.valid===false
        ){
          return this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Se encontraron errores en el paso 3 del formulario. Revisar formato y campos sin completar.',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        }
        else{
          this.original_person.skip_if_step_three = true
          this.original_person.cellphone = this.person.cellphone
          this.original_person.phone = this.person.phone
          this.original_person.personal_mail = this.person.personal_mail
        }
      }
      else if(this.activeStep===3){
        if(this.$refs.observer.fields.fianza.valid===false || this.$refs.observer.fields.retirado.valid===false || this.$refs.observer.fields.estado_civil.valid===false || this.$refs.observer.fields.inst_prevision.valid===false || this.$refs.observer.fields.inst_salud.valid===false){
          return this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Se encontraron errores en el paso 4 del formulario. Revisar formato y campos sin completar.',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        }
        else{
          this.original_person.skip_if_step_four = true
          this.original_person.pension_institution_id = this.person.pension_institution_id
          this.original_person.health_institution_id = this.person.health_institution_id
          this.original_person.university_entrance_date = this.person.university_entrance_date
          this.original_person.public_entrance_date = this.person.public_entrance_date
          this.original_person.civil_status = this.person.civil_status
          this.original_person.retired = this.person.retired
          this.original_person.pays_bail = this.person.pays_bail
          this.original_person.life_record_link = this.person.life_record_link
        }
      }
      else if(this.activeStep===4){
        this.original_person.skip_if_step_five = true
      }
      this.isLoading=true;
      if (this.queryType==="update"){

        let clean_person = this.original_person
        clean_person = this.deleteData(clean_person)

        // Crea un objeto FormData
        let formData = new FormData();

        // Agrega datos al objeto FormData
        formData.append("person", JSON.stringify(clean_person));
        this.files.forEach((file, index) => {
          formData.append(`file[${index}]`, file);
        });
        formData.append("process_files", JSON.stringify(this.process_files));
        axios
            .put("/people/" + this.personId+"/update-person-document.json", formData,{
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {
              this.errores_validacion = {};
              this.isLoading = false;
              this.$buefy.dialog.alert({
                title: 'Éxito',
                message: 'Los datos del paso '+(this.activeStep+1).toString()+ ' se guardaron correctamente.',
                type: 'is-success',
                hasIcon: true,
                icon: 'check-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              this.initialize()
            }).catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalPersonForm.scrollTop = 0;
          this.isLoading=false;
        })
      }
    },
    savePerson(){
      this.isLoading=true;
      if (this.queryType==="update"){

        let clean_person = this.person
        clean_person = this.deleteData(clean_person)

        // Crea un objeto FormData
        let formData = new FormData();

        // Agrega datos al objeto FormData
        formData.append("person", JSON.stringify(clean_person));
        this.files.forEach((file, index) => {
          formData.append(`file[${index}]`, file);
        });
        formData.append("process_files", JSON.stringify(this.process_files));

        axios
            .put("/people/" + this.personId+"/update-person-document.json", formData,{
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {
              this.errores_validacion = {};
              window.location.href = "/people/"+this.personId+"/life_record"
              this.isLoading = false;
            }).catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalPersonForm.scrollTop = 0;
          this.isLoading=false;
        })
      }
    },
    loadCommunes(region_id){
      if( region_id!==null && region_id!==undefined && region_id!==""){
        this.isLoading=true;
        axios
            .get("/regions/"+region_id+"/communes.json")
            .then(res => {
              this.person.commune_id = null;
              this.communes = res.data["communes"];
              this.isLoading = false;
            })
            .catch(error => {
              console.log(error)
              this.isLoading=false;
            })
      }
      else{
        this.person.commune_id = null
        this.communes = []
      }
    },
    fixCommunes(commune){
      this.isLoading = true
      this.isLoading = false
    },
    deleteData(person){
      delete person.communes
      delete person.countries
      delete person.health_institutions
      delete person.pension_institutions
      delete person.genders
      delete person.regions
      delete person.id
      delete person.run_attributes
      delete person.university_id
      delete person.university_name
      delete person.entity_type_id
      delete person.position
      delete person.document_types
      delete person.process_files
      return person
    },
    formatDate,
    deleteDropFile(index) {
      this.process_files.splice(index, 1)
      this.files.splice(index, 1)
    },
    processFiles(){
      var process_files_aux = this.process_files
      var document_type_id = this.document_types.find(x => x.name ===  'OTROS').id
      var new_files = this.files.slice(this.process_files.length);
      var rut = this.person.run_attributes.rut.toString() + "_" + this.person.run_attributes.dv
      var file_name = "DOCUMENTO_INGRESO"
      new_files.forEach(function(file){
        file_name = "DOCUMENTO_INGRESO"
        file_name = file_name+"-"+rut+"-"+file.name
        process_files_aux.push({
          document_id: null,
          name:file.name,
          document_type_id: document_type_id,
          document_name: file_name,
          file:file
        })
        console.log(file)
      })
      this.process_files = process_files_aux
    },
    changeName(index) {
      var tipo = "DOCUMENTO_INGRESO"
      var rut = "-RUT_"+this.person.run_attributes.rut.toString() + "_" + this.person.run_attributes.dv
      var file_name = "-"+this.process_files[index].name
      var document_type_id = this.process_files[index].document_type_id
      var find_document_type = this.document_types.find(x => x.id ===  document_type_id)
      var document_name = ''
      if (find_document_type.name != "OTROS"){
        tipo = find_document_type.initials
        file_name = ''
      }
      document_name = tipo+rut+file_name
      this.process_files[index].document_name = document_name
    },
  },
}
</script>

<style scoped>

</style>