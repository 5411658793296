<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveRole)">
        <!-- Box de Formulario Appointment -->
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body">
            <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
              <b-message type="is-danger" has-icon>
                <div v-for="(item,index) in errores_validacion">
                  {{ item[0] }}
                </div>
              </b-message>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Persona">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input :value="name" type="text" readonly :disabled="true"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Rut">
                  <b-input :value="complete_rut" type="text" readonly :disabled="true"></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Correo corporativo">
                  <b-input :value="persona_seleccionada.corporate_mail" type="text" readonly :disabled="true"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Unidad de desempeño">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.unit_id"
                                 :options="units.map(type => type.id)"
                                 :custom-label="opt => units.find(x => x.id === opt).name"
                                 placeholder="Seleccione una unidad"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :disabled="edit"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Unidad de contratación">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.contracting_unit_id"
                                 :options="units.map(type => type.id)"
                                 :custom-label="opt => units.find(x => x.id === opt).name"
                                 placeholder="Seleccione una unidad"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :disabled="edit"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción">
                    <b-input v-model="person_unit.description" type="text" :disabled="true"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Rol*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.internal_position"
                                 :options="internal_positions.map(type => type.id)"
                                 :custom-label="opt => internal_positions.find(x => x.id === opt).name"
                                 placeholder="Seleccione un rol"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 @input="updateStatus"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Fecha Desde">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-datepicker
                        v-model="person_unit.start_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        :disabled="edit"
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                    >
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Hasta">
                  <b-datepicker
                      v-model="person_unit.end_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      :disabled="edit"
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit">Guardar </button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "modal_add_annex_roles",
  props:[
    "id", "modalHeader", "complete_name", "queryType", "personId", "complete_run"
  ],
  data(){
    return {
      isLoading: false,
      isEditModalActive:false,
      person_unit: {},
      person: {},
      people: [],
      units: [],
      filtered_people: [],
      internal_positions: [],
      errores_validacion:{},
      persona_seleccionada: {},
      complete_rut: null,
      corporate_mail: null,
      is_real: false,
      edit: false,
      name: null
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      const loadingComponent = this.$buefy.loading.open();
      if(this.queryType==="create"){
        return axios
            .get("/admin/person_units/create_roles.json")
            .then(res => {
              this.internal_positions = res.data.internal_positions_filtered
              this.units = res.data.units_all
              loadingComponent.close();
            }).catch(error => {
              console.log(error)
              loadingComponent.close();
            })
      }
      else if(this.queryType==="update"){
        return axios
            .get("/admin/person_units/" + this.id + "/edit_appointments_roles.json")
            .then(res => {
              if(res.data.internal_position===null){
                var internal_position = null
              }
              else{
                var internal_position = res.data.internal_position.id
              }
              var person_unit = {id: res.data.id, internal_position: internal_position, unit_id: res.data.unit.id, description: res.data.description, start_date: res.data.start_date, end_date: res.data.end_date, contracting_unit_id: res.data.contracting_unit_id}
              this.person_unit = person_unit
              if(person_unit.start_date!==null){
                this.person_unit.start_date = new Date(this.formatDate(person_unit.start_date))

              }
              if(person_unit.end_date!==null){
                this.person_unit.end_date = new Date(this.formatDate(person_unit.end_date))
              }
              this.persona_seleccionada = res.data.person
              this.corporate_mail = res.data.person.corporate_mail
              this.name = res.data.person.name + " "+ res.data.person.last_name
              this.complete_rut = res.data.run_attributes.rut.toString()+"-"+res.data.run_attributes.dv
              this.units =res.data.units_all
              this.internal_positions = res.data.internal_positions_filtered
              this.edit = true
              loadingComponent.close();
            }).catch(error => {
              console.log(error)
              loadingComponent.close();
            })
      }

    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
    saveRole(){
      const loadingComponent = this.$buefy.loading.open();

      if (this.queryType==="create"){
        axios
            .post("/admin/person_units/new_role.json", {
              person_unit: this.person_unit,
              pu_relation_type_id: 2,
              person: this.persona_seleccionada[0]
            })
            .then(response =>{
              this.errores_validacion = {};
              this.$buefy.dialog.alert({
                message: 'El rol fue creado con éxito ',
                confirmText: 'Ok'
              })
              this.person_unit = {}
              loadingComponent.close();
              this.errors = [];
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al agregar el rol, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          loadingComponent.close();
        })
      }
      else if (this.queryType==="update"){
        let clean_person_unit = this.person_unit
        axios
            .put("/admin/person_units/" + this.id+"/update_role.json", {
              person_unit:  this.person_unit, pu_relation_type_id: 2,
              person: this.persona_seleccionada
            })
            .then(response => {
              this.$buefy.dialog.alert({
                message: 'El rol del nombramiento fue editado con éxito ',
                confirmText: 'Ok'
              })
              this.errores_validacion = {};
              this.isLoading = false;
              this.person_unit = {}
              loadingComponent.close();
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          let elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al editar el rol del nombramiento, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          loadingComponent.close();
        })
      }

    },

    getFilteredPeople(text){
      this.filtered_people = this.people.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    updateStatus(){
      var internal = this.internal_positions.find(x => x.id === this.person_unit.internal_position)
      this.is_real = internal.is_real
    },
  },
  watch: {
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
  }
}
</script>