<template>
  <div class="box">
    <h3 class="is-size-4 has-text-weight-bold has-text-centered">Información Entidad EFUSACH</h3>
    <br>
    <div class="columns">

      <div class="column">
        <b-field label="Unidad">
          <b-input readonly v-model="surplus.unit" type="text"></b-input>
        </b-field>
      </div>

      <div class="column">
        <b-field label="Código EFUSACH">
          <b-input readonly v-model="surplus.code" type="text"></b-input>
        </b-field>
      </div> 

      <div class="column">
        <b-field label="Entidad de giro">
          <b-input readonly v-model="surplus.turn" type="text"></b-input>
        </b-field>
      </div>

      
      
                      
    </div>

    <b-tabs position="is-centered" @input="changeTab">
      <b-tab-item v-for="item in items"
                  :value="item.content"
                  v-bind:key="item.content"
                  :label="item.tab"
                  :icon="item.icon">
      </b-tab-item>

    </b-tabs>
    <!-- COMPONENTE AQUI -->
      <keep-alive>
        <component v-bind:is="currentTabComponent"
                   :user-id="this.userId"
                   :cargo-principal="this.cargoPrincipal"
                   :surplus="this.surplus"
                   :surplus-id="this.surplusId"
                   :can_create_anual="this.can_create_anual"
                   :can_create_transaction="this.can_create_transaction"
                   ></component>
      </keep-alive>

    <!-- FIN COMPONENTE -->
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>
<script>
import Egresos from './ShowEgresosTab';
import Ingresos from './ShowEgresosTab';
import Apertura from './ShowAperturaTab';
import axios from "axios";
export default {
  components:{ Egresos, Ingresos , Apertura},
  props:[
    'surplusId', 'cargoPrincipal', 'userId','can_create_transaction','can_create_anual'
  ],
  data(){
    return{
      surplus: {
        unit: null,
        turn: null,
        code: null,
      },
      isLoading:false,
      currentPage:1,
      currentTab: 'Egresos',
      items: [
        {tab: 'Egresos',content:'Egresos',icon:"file-alt"},
        //{tab: 'Ingresos',content:'Ingresos',icon:"file"},
        {tab: 'Montos Anuales de apertura',content:'Apertura',icon:"users"},
      ]
    }
  },
  created(){
    let vm = this;
    vm.isLoading=true;
    return axios
        .get("/sdt_surpluses/"+vm.surplusId+".json")
        .then(res => {
          vm.surplus = res.data;      
          vm.isLoading=false;
        })
        .catch(e => {
          console.log(e);
          vm.isLoading=false;
        });

  },
  methods:{
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    changeTab: function(value){
      this.currentTab = value
    },

  },
  computed:{
    currentTabComponent: function () {
      return this.currentTab
    }
  }
}
</script>
