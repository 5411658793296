<template>
  <section>
    <div class="container px-5 py-0" ref="mhv_tutorial">
      <div class="columns is-multiline">
        <div class="column is-3 py-0">
          <b-sidebar position="static" type="is-light" open>
            <div class="p-1 pt-4">
              <div class="block mt-5">
                <h1 class="subtitle is-3 has-text-primary has-text-left">Índice</h1>
              </div>
              <b-menu class="is-custom-mobile" :accordion="false">
                <b-menu-list>
                  <b-menu-item :active="tutorial === 'what_is_liferecord' ? true : false" @click="tutorial = 'what_is_liferecord'" label="1. ¿Qué es Mi Hoja de Vida?"></b-menu-item>
                  <b-menu-item :active="tutorial === 'go_to_mhv' ? true : false" @click="tutorial = 'go_to_mhv'" label="2. ¿Cómo ver Mi Hoja de Vida?"></b-menu-item>
                  <b-menu-item :active="isActive" :expanded.sync="isActive">
                    <template #label="props">
                      3. ¿Qué información hay en Mi Hoja de Vida?
                      <b-icon class="is-pulled-right" :icon="props.expanded ? 'angle-up' : 'angle-down'"></b-icon>
                    </template>
                    <b-menu-item :active="tutorial === 'personal_data' ? true : false" @click="tutorial = 'personal_data'" label="3.1 Datos Personales y Exportar PDF"></b-menu-item>
                    <b-menu-item :active="tutorial === 'appointments' ? true : false" @click="tutorial = 'appointments'" label="3.2 Nombramientos"></b-menu-item>
                    <b-menu-item :active="tutorial === 'functions' ? true : false" @click="tutorial = 'functions'" label="3.3 Funciones"></b-menu-item>
                    <b-menu-item :active="tutorial === 'households' ? true : false" @click="tutorial = 'households'" label="3.4 Asignaciones Familiares"></b-menu-item>
                    <b-menu-item :active="tutorial === 'studies' ? true : false" @click="tutorial = 'studies'" label="3.5 Estudios"></b-menu-item>
                    <b-menu-item :active="tutorial === 'commissions' ? true : false" @click="tutorial = 'commissions'" label="3.6 Comisiones"></b-menu-item>
                    <b-menu-item :active="tutorial === 'events' ? true : false" @click="tutorial = 'events'" label="3.7 Eventos"></b-menu-item>
                  </b-menu-item>
                </b-menu-list>
              </b-menu>
            </div>
          </b-sidebar>
          <div class="buttons-sidebar">
            <b-button class="icon-up" icon-left="arrow-circle-up" type="is-ghost" @click="goUp">
              Volver arriba
            </b-button>
          </div>
        </div>

        <div class="column is-9 px-5" v-if="tutorial === 'what_is_liferecord'">
          <div class="section p-5 pt-0">
            <WhatIsMhv v-if="tutorial === 'what_is_liferecord'"></WhatIsMhv>
          </div>
        </div>
        <div class="column is-9 px-5" v-if="tutorial === 'go_to_mhv'">
          <div class="section p-5 pt-0">
            <GoToMhv v-if="tutorial === 'go_to_mhv'"></GoToMhv>
          </div>
        </div>
        <div class="column is-9 px-5" v-if="tutorial === 'personal_data'">
          <div class="section p-5 pt-0">
            <PersonalData v-if="tutorial === 'personal_data'"></PersonalData>
          </div>
        </div>
        <div class="column is-9 px-5" v-if="tutorial === 'appointments'">
          <div class="section p-5 pt-0">
            <WhatIsAppointmentMhv v-if="tutorial === 'appointments'"></WhatIsAppointmentMhv>
          </div>
        </div>
        <div class="column is-9 px-5" v-if="tutorial === 'functions'">
          <div class="section p-5 pt-0">
            <WhatIsFunctionMhv v-if="tutorial === 'functions'"></WhatIsFunctionMhv>
          </div>
        </div>
        <div class="column is-9 px-5" v-if="tutorial === 'households'">
          <div class="section p-5 pt-0">
            <WhatIsHouseholdMhv v-if="tutorial === 'households'"></WhatIsHouseholdMhv>
          </div>
        </div>
        <div class="column is-9 px-5" v-if="tutorial === 'studies'">
          <div class="section p-5 pt-0">
            <WhatIsStudyMhv v-if="tutorial === 'studies'"></WhatIsStudyMhv>
          </div>
        </div>
        <div class="column is-9 px-5" v-if="tutorial === 'commissions'">
          <div class="section p-5 pt-0">
            <WhatIsCommissionMhv v-if="tutorial === 'commissions'"></WhatIsCommissionMhv>
          </div>
        </div>
        <div class="column is-9 px-5" v-if="tutorial === 'events'">
          <div class="section p-5 pt-0">
            <WhatIsEventMhv v-if="tutorial === 'events'"></WhatIsEventMhv>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>
<script>
import WhatIsMhv from "./mhv/what_is_mhv"
import GoToMhv from "./mhv/go_to_mhv"
import WhatInfoMhv from "./mhv/what_info_mhv"
import EventBus from "../eventBus";
import PersonalData from "./mhv/personal_data"
import WhatIsAppointmentMhv from "./mhv/what_is_appointment_mhv"
import WhatIsFunctionMhv from "./mhv/what_is_function_mhv"
import WhatIsStudyMhv from "./mhv/what_is_study"
import WhatIsCommissionMhv from "./mhv/what_is_commission"
import WhatIsEventMhv from "./mhv/what_is_event"
import WhatIsHouseholdMhv from "./mhv/what_is_household"
export default {
  name: "mhv_tutorial",
  data(){
    return {
      tutorial: "what_is_liferecord",
      isActive: false
    }
  },
  components:{
    WhatIsMhv,
    GoToMhv,
    WhatInfoMhv,
    PersonalData,
    WhatIsAppointmentMhv,
    WhatIsFunctionMhv,
    WhatIsStudyMhv,
    WhatIsCommissionMhv,
    WhatIsEventMhv,
    WhatIsHouseholdMhv
  },
  methods:{
    goUp(){
      var elmnt = document.getElementById("app");
      elmnt.scrollIntoView();
      this.$refs.mhv_tutorial.scrollTop = 0;
    }
  },
  mounted () {
    EventBus.$on('IR_A', (payload) => {
      if(payload===1){
        this.tutorial = "personal_data"
        this.isActive = true;
      }
      else if(payload===2){
        this.tutorial = "appointments"
        this.isActive = true;
      }
      else if(payload===3){
        this.tutorial = "functions"
        this.isActive = true;
      }
      else if(payload===4){
        this.tutorial = "households"
        this.isActive = true;
      }
      else if(payload===5){
        this.tutorial = "studies"
        this.isActive = true;
      }
      else if(payload===6){
        this.tutorial = "commissions"
        this.isActive = true;
      }
      else{
        this.tutorial = "events"
        this.isActive = true;
      }
    })
  }
}
</script>
<style>
.icon-up{
  position: fixed;
  width: 16.5% !important;
  bottom: 0;
  left: 15%;
}
.buttons-sidebar{

}
</style>