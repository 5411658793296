<template>
    <section>

        <div class="container">

            <div class="columns">
                <div class="column has-text-centered">
                    <div class="heading is-size-6">{{ indicator.count_types[0].name }}</div>
                    <div class="title">{{ indicator.count_types[0].value }}</div>
                </div>

                <div class="column has-text-centered">
                    <div class="heading is-size-6">{{ indicator.count_types[1].name }}</div>
                    <div class="title">{{ indicator.count_types[1].value }}</div>
                </div>

                <div class="column has-text-centered">
                    <div class="heading is-size-6">{{ indicator.count_types[2].name }}</div>
                    <div class="title">{{ indicator.count_types[2].value }}</div>
                </div>
            </div>

            <div class="columns">
                <div class="column has-text-centered">
                    <div class="heading is-size-6">{{ indicator.count_types[3].name }}</div>
                    <div class="title">{{ indicator.count_types[3].value }}</div>
                </div>

                <div class="column has-text-centered">
                    <div class="heading is-size-6">{{ indicator.count_types[4].name }}</div>
                    <div class="title">{{ indicator.count_types[4].value }}</div>
                </div>

            </div>

            <div class="columns is-centered">
              <div style="border: 2px solid black; border-radius:10px" class="column is-4 has-text-centered">
                    <div class="heading is-size-6">Total</div>
                    <div class="title">{{ indicator.total }}</div>
                </div>
            </div>
            
        </div>

    </section>
</template>

<script>
export default{
    props: [
        'indicator',
    ],
}
</script>