<template>
  <div>
    <br>
    <div class="columns is-centered">
      <div class="column is-11">

        <div class="box" v-if="indicator.code">
          <h1 class="title is-4">Detalle de composición del indicador {{ this.indicator.code }}</h1>
          <h2 class="subtitle is-6">
            <span class="has-text-weight-bold">{{ this.indicator.name }} </span>
            <br> Unidad: {{ unit.name }}
            <br> Año: {{ selectedYear }}
          </h2>
          <div class="columns">
            <div class="column">
              <button @click="downloadExcel" class="button has-text-white is-excel-color">
                <span class="icon">
                  <i class="far fa-file-excel"></i>
                </span>
                <span>
                  Descargar Composición en Excel
                </span>
              </button>
            </div>
          </div>
          <div v-if="totalData" class="columns">
            <div class="column has-text-right">
              Indicador calculado bajo un total de: {{ this.totalData }} datos
            </div>
          </div>


          <b-tabs position="is-centered" type="is-toggle-rounded" v-model="activeTab">
            <b-tab-item v-if="projects && projects.length>0" label="Proyectos">
              <projects-table v-bind:proyectos="projects"
                              v-bind:hide-update="true" :cargo-principal="''"></projects-table>

            </b-tab-item>
            <b-tab-item v-if="publications && publications.length>0" label="Publicaciones">
              <publications-table v-bind:publicaciones="publications"
                                  v-bind:hide-update="true" :cargo-principal="''"></publications-table>

            </b-tab-item>
            <b-tab-item v-if="patents && patents.length>0" label="Patentes">
              <patents-table v-bind:patentes="patents"
                             v-bind:hide-update="true" :cargo-principal="''"></patents-table>
            </b-tab-item>
            <b-tab-item v-if="disclosures && disclosures.length>0" label="Disclosures">
              <disclosures-table v-bind:disclosures="disclosures"
                                 v-bind:hide-update="true" :cargo-principal="''"></disclosures-table>
            </b-tab-item>
            <b-tab-item v-if="financings && financings.length>0" label="Financiamientos">
              <financings-table v-bind:financings="financings"
                                v-bind:hide-update="true"></financings-table>
            </b-tab-item>
            <b-tab-item v-if="cites && cites.length>0" label="Citas">
              <dashboard-cites-table :cites="cites" :year="selectedYear"></dashboard-cites-table>
            </b-tab-item>

            <b-tab-item v-if="postulated_projects && postulated_projects.length>0" label="Proyectos Postulados">
              <postulated-projects-table :postulated-projects="postulated_projects"></postulated-projects-table>
            </b-tab-item>

            <b-tab-item v-if="people && people.length>0" label="Personas">

              <b-table
                  :data="people"
                  :paginated=true
                  :card-layout="false"
                  per-page=10
                  striped
                  hoverable
                  :current-page="1"
                  :pagination-simple=false
                  pagination-position="bottom"
                  default-sort-direction="asc"
                  sort-icon="arrow-up"
                  sort-icon-size="is-small"
                  default-sort="financing.year"
                  aria-next-label="Siguiente"
                  aria-previous-label="Anterior"
                  aria-page-label="Página"
                  aria-current-label="Página actual"
              >
                <b-table-column
                    field= 'person_name'
                    label= 'Nombre Personas'
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    <a :href="getPersonUrl(props.row.person_id)">{{ props.row.person_name }}</a>
                  </template>
                </b-table-column>
                <b-table-column
                    field= 'jce'
                    label= 'jce'
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.jce }}
                  </template>
                </b-table-column>

                <template slot="empty" slot-scope="props">
                  <div class="columns is-centered">
                    <div class="column is-6 is-centered has-text-centered">
                      <b-message type="is-danger">
                        No se encontraron resultados para el año seleccionado.
                      </b-message>
                    </div>
                  </div>
                </template>
              </b-table>


            </b-tab-item>


          </b-tabs>



        </div>

      </div>
    </div>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  </div>
</template>

<script>
import axios from "axios";
import PublicationsTable from "../publications/publications_table.vue";
import PatentsTable from "../patents/patents_table.vue";
import ProjectsTable from "../projects/projects_table.vue";
import DisclosuresTable from "../disclosure/disclosure_table.vue";
import FinancingsTable from "../financings/FinancingTable.vue";
import moment from "moment";
import DashboardCitesTable from "./DashboardCitesTable";
import PostulatedProjectsTable from "../projects/PostulatedProjectsTable";

export default {
  name: "IndicatorComposition",
  components: {
    PostulatedProjectsTable,
    DashboardCitesTable, PatentsTable, PublicationsTable, ProjectsTable, DisclosuresTable,FinancingsTable},
  data(){
    return{
      currentData:'',
      indicatorData:[],
      isLoading:false,
      indicator:{},
      selectedYear:"",
      unit:{},
      financing:{id:1,amount:100,year:2021,partner_entity:"PE",project:{name:"Project",code:"ex01"},financing_type:"REMESA"},
      activeTab: 0,
      publications:[],
      patents:[],
      projects:[],
      financings:[],
      disclosures:[],
      people:[],
      cites:[],
      postulated_projects:[],
      totalData:null,



    }
  },
  created(){
    this.getIndicatorsComposition();

  },
  props:[
    'indicatorId','year','unitId'
  ],
  methods:{
    getIndicatorsComposition(){
      let vm = this;
      vm.isLoading=true;
      let year=2021;
      let unitId = 2;
      let url = "";
      if (this.year){
        year = vm.year
        vm.selectedYear = vm.year;
      }
      if (vm.unitId)
        unitId = vm.unitId
      if (vm.indicatorId)
        url = "/dashboards/"+vm.indicatorId+"/composition.json"

      axios
        .get(url, {params:{year:year,unit_id:unitId}})
        .then(res => {
          vm.isLoading=false;
          vm.currentData = res.data["data_type"];
          vm.indicatorData = res.data["data"];
          vm.totalData = res.data["total_data"];

          if (res.data.projects && res.data.projects.length>0){
            vm.projects = res.data.projects;
            vm.projects = vm.projects.map(proyecto => {
              var newProyecto = proyecto;
              newProyecto["start_date"] =  moment(newProyecto["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newProyecto["end_date"] =  moment(newProyecto["end_date"] , ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newProyecto;
            });
          }
          if (res.data.financings && res.data.financings.length>0){
            vm.financings = res.data.financings;
          }
          if (res.data.patents && res.data.patents.length>0){
            vm.patents = res.data.patents;
            vm.patents = vm.patents.map(patente => {
              var newPatente = patente
              newPatente["last_patent_state_date"] = moment(newPatente["last_patent_state_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newPatente["presentation_date"] = moment(newPatente["presentation_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newPatente;
            });
          }
          if (res.data.publications && res.data.publications.length>0){
            vm.publications = res.data.publications;
            vm.publications = vm.publications.map(publicacion => {
              var newPublicacion = publicacion
              newPublicacion["publication_date"] = moment(publicacion.publication_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newPublicacion;
            });
          }
          if (res.data.disclosures && res.data.disclosures.length>0){
            vm.disclosures = res.data.disclosures;
            this.disclosures = this.disclosures.map(disclosure => {
              var newDisclosure = disclosure
              newDisclosure["disclosure_date"] = moment(newDisclosure["disclosure_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newDisclosure["invention_date"] = moment(newDisclosure["invention_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newDisclosure;
            });
          }
          if(res.data.people && res.data.people.length>0){
            vm.people = res.data.people;
          }
          if(res.data.cites && res.data.cites.length>0){
            vm.cites = res.data.cites;
          }
          if(res.data.postulated_projects && res.data.postulated_projects.length>0){
            vm.postulated_projects = res.data.postulated_projects;
            vm.postulated_projects = vm.postulated_projects.map(project => {
              var newProject = project
              newProject["postulation_date"] = moment(newProject["postulation_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newProject;
            });
          }
          vm.indicator = res.data["indicator"];
          vm.unit = res.data["unit"];
          vm.selectedYear = res.data["year"];

        })
        .catch(e => {
          console.log(e);
          vm.isLoading=false;
        });

    },
    updateIndicatorsComposition(){

    },
    downloadExcel(){
      window.location.href = "dashboards/"+this.indicatorId+"/composition/download.xlsx?unit_id="+this.unitId+"&year="+this.year
    },
    getPersonUrl(person_id){
      return "/people/"+person_id;
    } ,

  },
  computed:{

  }
}
</script>

<style scoped>

</style>