<template>
  <div>
    <br>
    <div class="container" style="border-radius: 15px">
      <div class="columns is-centered">
        <div class="column box">
          <div class="title is-4">
            Disclosure
          </div>
          <hr class="dropdown-divider" aria-role="menuitem">
          <div class="columns">
            <div class="column">
              <b-field label="Nombre invención">
                <b-input readonly v-model="disclosures.name" type="text"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Fuente Financiamiento">
                <b-input readonly v-model="disclosures.financing_source" type="text"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Fecha de Publicación">
                <b-input readonly v-model="disclosures.disclosure_date" type="text"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Fecha Invención">
                <b-input readonly v-model="disclosures.invention_date" type="text"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <!--            <div class="column">
                          <b-field label="Código Proyecto">
                            <b-input readonly v-model="disclosures.project_id" type="text"></b-input>
                          </b-field>
                        </div>-->
            <div class="column">
              <b-field label="Código">
                <b-input readonly v-model="disclosures.code" type="text"></b-input>
              </b-field>
            </div>
          </div>
<!--          <div class="columns">
            <div class="column">
              <b-field label="Origen del dato">
                <b-input v-if="disclosures.creator_name!=null" readonly v-model="disclosures.creator_name"></b-input>
                <b-input v-else readonly></b-input>
              </b-field>
            </div>
          </div>-->
          <h3 class="is-size-3 has-text-weight-bold">Participantes</h3>
          <h3 class="is-size-4 has-text-weight-bold">Tabla Resumen Participantes</h3>
          <b-table
              :data="inventores"
              :paginated=true
              per-page=5
              striped
              hoverable
              :current-page="currentPage"
              :pagination-simple=false
              pagination-position="bottom"
              default-sort-direction="asc"
              sort-icon="arrow-up"
              sort-icon-size="is-small"
              default-sort="publication.title"
              aria-next-label="Siguiente"
              aria-previous-label="Anterior"
              aria-page-label="Página"
              aria-current-label="Página actual">
            <b-table-column
                field="name"
                label="Nombres"
                sortable
                searchable>
              <template
                  slot="searchable"
                  slot-scope="props">
                <b-input
                    readonly
                    v-model="props.filters[props.column.field]"
                    placeholder=" Buscar"
                    icon="search"
                    size="is-small" />
              </template>
              <template v-slot="props">
                {{ props.row.full_name}}
              </template>
            </b-table-column>
            <b-table-column
                field="alias"
                label="Alias"
                sortable
                searchable>
              <template
                  slot="searchable"
                  slot-scope="props">
                <b-input
                    readonly
                    v-model="props.filters[props.column.field]"
                    placeholder=" Buscar"
                    icon="search"
                    size="is-small" />
              </template>
              <template v-slot="props">
                {{ props.row.alias }}
              </template>
            </b-table-column>
            <b-table-column
                field="corporate_mail"
                label="Correo Corporativo"
                sortable
                searchable>
              <template
                  slot="searchable"
                  slot-scope="props">
                <b-input
                    readonly
                    v-model="props.filters[props.column.field]"
                    placeholder=" Buscar"
                    icon="search"
                    size="is-small" />
              </template>
              <template v-slot="props">
                {{ props.row.corporate_mail }}
              </template>
            </b-table-column>
            <b-table-column
                field="unit"
                label="Unidad"
                sortable
                searchable>
              <template
                  slot="searchable"
                  slot-scope="props">
                <b-input
                    readonly
                    v-model="props.filters[props.column.field]"
                    placeholder=" Buscar"
                    icon="search"
                    size="is-small" />
              </template>
              <template v-slot="props">
                {{ props.row.unit }}
              </template>
            </b-table-column>
          </b-table>
          <hr class="dropdown-divider" aria-role="menuitem">

        </div>
      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data(){
    return{
      disclosures:{
        name: "",
        code: "",
        project_id:null,
        financing_source:null,
        disclosure_date:null,
        invention_date:null,
        year:null
      },
      inventores: [],
      currentPage: 1,
      isLoading: false,
    }
  },
  created(){
    this.isLoading=true;
    return axios
        .get("/disclosures/show_disclosure_nullified/"+this.disclosureId+".json")
        .then(res => {
          this.disclosures = res.data[0]
          var index = 0
          for(const inventor of res.data[0]["inventors"]){
            this.inventores.push({
              id:null,
              full_name:null,
              alias:null,
              unit:null,
              //unit_id:null,
              corporarte_mail:null,
            })
            this.inventores[index].id = inventor.id
            this.inventores[index].full_name = inventor.name
            this.inventores[index].corporate_mail = inventor.corporate_mail
            this.inventores[index].alias = inventor.person_alias
            if(this.inventores[index].corporate_mail != null){
              this.inventores[index].corporate_mail = this.inventores[index].corporate_mail.toUpperCase()
            }
            if(this.inventores[index].alias != null){
              this.inventores[index].alias = this.inventores[index].alias.toUpperCase()
            }
            this.inventores[index].unit = inventor.unit
            //this.inventores[index].unit_id = inventor.unit_id
            index = index + 1
          }

          if (this.disclosures.disclosure_date != null){
            this.disclosures.disclosure_date = this.formatDate(this.disclosures.disclosure_date)
          }
          if ( this.disclosures.invention_date != null){
            this.disclosures.invention_date = this.formatDate(this.disclosures.invention_date)
          }
          this.isLoading=false;
        })
        .catch(e => {
          console.log(e);
          this.isLoading=false;
        });

  },
  props:[
    'disclosureId','cargoPrincipal'
  ],
  methods:{
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
  },
  computed:{

  }
}
</script>