<template>
  <section>
  <div class="columns">
    <div name="contenido" class="column">

      <div class="container mt-6">
        <div class="box">
          <section class="hero is-primary welcome is-small mb-4">
            <div class="hero-body">
              <div class="container">
                <h1 class="title">
                  Hoja de Vida
                </h1>
              </div>
            </div>
          </section>
          <section class="info-tiles">
            <div class="tile is-ancestor has-text-centered">
              <div class="tile is-parent">
                <article class="tile is-child box">
                  <a href="/people">
                    <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-users-cog"></i>
                        </span>
                        <span>Listar Personas</span>
                      </span>
                    </p>
                  </a>
                </article>
              </div>
              <div class="tile is-parent" v-if="can_create==='true'">
                <article class="tile is-child box">
                  <div v-if="can_create==='true'">
                    <a @click="newPerson">
                      <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon">
                          <i class="fas fa-user-plus"></i>
                        </span>
                        <span>Registrar Personas</span>
                      </span>
                      </p>
                    </a>
                  </div>
                  <div v-else>
                    <a style="color: #e6e6e6">
                      <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon">
                          <i class="fas fa-user-plus" style="color: #e6e6e6"></i>
                        </span>
                        <span style="color: #e6e6e6">Registrar Personas</span>
                      </span>
                      </p>
                    </a>
                  </div>
                </article>
              </div>
            </div>
          </section>
          <section class="info-tiles">
            <div class="tile is-ancestor has-text-centered">
              <div class="tile is-parent">
                <article class="tile is-child box">
                  <a href="/life_record/search_life_record">
                    <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-search"></i>
                        </span>
                        <span>Buscar Personas</span>
                      </span>
                    </p>
                  </a>
                </article>
              </div>
              <div class="tile is-parent" v-if="can_renew==='true'">
                <article class="tile is-child box">
                  <a href="/people/list_renewable_appointments">
                    <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon">
                          <i class="fas fa-sync-alt"></i>
                        </span>
                        <span>Renovar Contrataciones</span>
                      </span>
                    </p>
                  </a>
                </article>
              </div>
            </div>
          </section>
          <section class="info-tiles">
            <div class="tile is-ancestor has-text-centered">
              <div class="tile is-parent">
                <article class="tile is-child box">
                  <a href="/life_record/reports">
                    <p class="subtitle is-4">
                      <span class="icon">
                        <i class="fas fa-clipboard-list"></i>
                      </span>
                      Listados por Estamentos
                    </p>
                  </a>
                </article>
              </div>
              <div class="tile is-parent">
                <article class="tile is-child box">
                  <a href="/life_record/appointments">
                    <p class="subtitle is-4">
                      <span class="icon">
                        <i class="fas fa-clipboard-list"></i>
                      </span>
                      Listado de Nombramientos
                    </p>
                  </a>
                </article>
              </div>
            </div>
          </section>
          <section class="info-tiles">
            <div class="tile is-ancestor has-text-centered">
              <div class="tile is-parent">
                <article class="tile is-child box">
                  <a href="/life_record/fee_contracts">
                    <p class="subtitle is-4">
                      <span class="icon">
                        <i class="fas fa-file-contract"></i>
                        
                      </span>
                      Listado de Contratos de Honorarios
                    </p>
                  </a>
                </article>
              </div>
              <div class="tile is-parent" v-if="can_import==='true'">
                <article class="tile is-child box">
                  <a href="/life_record/import_dashboard">
                    <p class="subtitle is-4">
                      <span class="icon">
                        <i class="fas fa-file-import"></i>
                      </span>
                      Módulo de Importación Masiva
                      <b-tooltip label="Módulo que contiene funcionalidades de importación masiva para el ingreso de personas, continuidad y extensión de profesores hora."
                                 position="is-right"
                                 size="is-large"
                                 multilined>
                        <b-icon
                            pack="fas"
                            icon="info-circle"
                            size="is-small"
                            type="is-info">
                        </b-icon>
                      </b-tooltip>
                    </p>
                  </a>
                </article>
              </div>
            </div>
          </section>
          <section class="info-tiles" v-if="can_compare_assets==='true' || can_function==='true'">
            <div class="tile is-ancestor has-text-centered">
              <div class="tile is-parent" v-if="can_compare_assets==='true'">
                <article class="tile is-child box">
                  <a href="/life_record/compare_assets">
                    <p class="subtitle is-4">
                      <span class="icon">
                        <i class="fas fa-money-check-alt"></i>
                      </span>
                      Contrastación de Haberes
                      <b-tooltip label="Funcionalidad que permite importar un archivo de haberes para contrastar la información con los datos de nombramientos guardados en SIGA"
                                 position="is-right"
                                 size="is-large"
                                 multilined>
                        <b-icon
                            pack="fas"
                            icon="info-circle"
                            size="is-small"
                            type="is-info">
                        </b-icon>
                      </b-tooltip>
                    </p>
                  </a>
                </article>
              </div>
              <div class="tile is-parent" v-if="can_function==='true'">
                <article class="tile is-child box">
                  <a href="/life_record/functions-dashboard">
                    <p class="subtitle is-4">
                      <span class="icon">
                        <i class="fas fa-user-tag"></i>
                      </span>
                      Gestor de Funciones
                      <b-tooltip label="Módulo que contiene las funcionalidades relacionadas al listado, búsqueda, creación, edición, anulación y restauración de funciones."
                                 position="is-right"
                                 size="is-large"
                                 multilined>
                        <b-icon
                            pack="fas"
                            icon="info-circle"
                            size="is-small"
                            type="is-info">
                        </b-icon>
                      </b-tooltip>
                    </p>
                  </a>
                </article>
              </div>
            </div>
          </section>
          <section v-if="can_create_massive==='true'" class="info-tiles">
              <div class="tile is-ancestor has-text-centered">
                  <div class="tile is-parent">
                      <article class="tile is-child box">
                          <a href="/life_record/bulk_requests">
                              <p class="subtitle is-4">
                                <span class="icon">
                                  <i class="fas fa-copy"></i>
                                </span>
                                  Solicitudes Masivas
                                  <b-tooltip label="Módulo que contiene las funcionalidades relacionadas a la generación de solicitudes masivas de continuidades para profesores horas clases."
                                             position="is-right"
                                             size="is-large"
                                             multilined>
                                      <b-icon
                                              pack="fas"
                                              icon="info-circle"
                                              size="is-small"
                                              type="is-info">
                                      </b-icon>
                                  </b-tooltip>
                              </p>
                          </a>
                      </article>
                  </div>
              </div>
            </section>
          <section v-if="can_index_adm_permits" class="info-tiles">
            <div class="tile is-ancestor has-text-centered">
              <div class="tile is-parent">
                <a href="/administrative-permits/" class="tile is-child box">
                  <p class="subtitle is-4">
                    <span class="icon">
                      <i class="fas fa-file-invoice"></i>
                    </span>
                    Solicitudes de permisos administrativos
                    <b-tooltip label="Módulo que contiene las funcionalidades relacionadas a la gestión de permisos administrativos."
                                position="is-top"
                                size="is-large"
                                multilined>
                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          size="is-small"
                          type="is-info">
                      </b-icon>
                    </b-tooltip>
                  </p>
                </a>
              </div>
            </div>
          </section>
          <section v-if="can_report_documents === 'true'" class="info-tiles">
<!--          <section class="info-tiles">-->
            <div class="tile is-ancestor has-text-centered">
              <div class="tile is-parent">
                <a href="/life_record/report-documents/" class="tile is-child box">
                  <p class="subtitle is-4">
                    <span class="icon">
                      <i class="fas fa-history"></i>
                    </span>
                    Reportería Documentación
                  </p>
                </a>
              </div>
            </div>
          </section>
          <section class="info-tiles">
            <div class="tile is-ancestor has-text-centered">
              <div class="tile is-parent">
                <article class="tile is-child box">
                  <a href="/life_record/academic_statistics.xlsx">
                    <p class="subtitle is-4">
                      <span class="icon">
                        <i class="fas fa-file-excel"></i>
                      </span>
                      Descargar Estadísticas de Académicos(as)
                      <b-tooltip label="Documento que contiene un resumen de acádemicas/os
                                        por Departamento de la Facultad de ingeniería, según Jornada, Jerarquía,
                                         Calidad de Nombramiento, Estudio de mayor categoría y según Jornada y Doctorado "
                                 position="is-right"
                                 size="is-large"
                                 multilined>
                        <b-icon
                            pack="fas"
                            icon="info-circle"
                            size="is-small"
                            type="is-info">
                        </b-icon>
                      </b-tooltip>
                    </p>
                  </a>
                </article>
              </div>
            </div>
          </section>
        </div>
      </div>


    </div>
  </div>


    <b-modal
        v-model="isAddModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel"
        width="1348">
      <template #default="props">
        <modal-person-form query-type="create"
                           modal-header="Nueva Persona"
                           @close="props.close"></modal-person-form>
      </template>
    </b-modal>
  </section>
</template>

<script>
import ModalPersonForm from "../life_record/modal_person_form"

export default {
  name: "LifeRecordDashboard",
  components:{
    ModalPersonForm,
  },
  props: {
    can_create: String,
    can_import: String,
    can_renew: String,
    can_compare_assets: String,
    can_function: String,
    can_create_massive: String,
    can_index_adm_permits: Boolean,
    can_report_documents: Boolean
  },
  data(){
    return {
      selected: null,
      canCancel: [],
      isAddModalActive: false,
    }
  },
  created(){
    this.isAddModalActive = false
    console.log("VALOR DE PROP CAN_CREATE: ", this.can_create)

  },
  methods: {
    newPerson(){
      this.isAddModalActive = true
    },
  }
}
</script>
