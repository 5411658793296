<template>
  <section>
    <publications-table v-bind:publicaciones="this.publicaciones" :hide-update="true" :cargoPrincipal="cargoPrincipal" @updatePublications="updatePublications"></publications-table>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import axios from "axios";
import PublicationsTable from "../publications/publications_table.vue";
import moment from "moment";
export default {
  props:[
    'objectId','componentType', 'cargoPrincipal','currentYear','minYear','maxYear', 'changeFilter', 'hideUpdate'
  ],
  data(){
    return{
      publicaciones:[],
      isLoading:false,
      year: new Date().getFullYear(),
    }
  },
  components:{
    PublicationsTable
  },
  created(){
    //this.years = this.generateArrayOfYears();
    this.getPublications();

  },
  methods:{

    getPublications(){
      let vm = this;
      vm.isLoading=true;
      var url = "";
      if (vm.componentType == "person"){
        url = "/people/"+this.objectId+"/publications.json"
      }
      else if (vm.componentType=="unit"){
        url = "/admin/units/"+this.objectId+"/publications.json"
      }
      if(this.hideUpdate == true){
        vm.year = 'MyActivity'
      }
      axios
          .get(url, {params:{
              year_f: vm.year,
              minYear: vm.minYear,
              maxYear: vm.maxYear
            }})
          .then(res => {
            vm.isLoading=false;
            vm.publicaciones = res.data;
            vm.publicaciones = vm.publicaciones.map(publicacion => {
              var publicacion = publicacion
              publicacion["publication_date"] = moment(publicacion.publication_date, ["YYYY-MM-DD","DD-MM-YYYY"]);;
              return publicacion;
            });
          })
          .catch(e => {
            console.log(e);
            vm.isLoading=false;

          });
    },
    updatePublications(year){
      this.year = year
      this.getPublications();
    }
  },
  computed:{

  },
  watch:{
    changeFilter: function(){
      this.getPublications()
    }
  }
}
</script>
