<template>
    <div class="vimeactivitynewentitiesrelatedmodal">
        <form action="">
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Nueva entidad</p>
                    <button
                        type="button"
                        class="delete"
                        @click="$emit('close')"/>
                </header>
                <section class="modal-card-body">
                    <b-message title="Acerca de la entidad" type="is-info" has-icon :closable='false'>
                        La entidad tiene que estar previamente registrada en el sistema.
                    </b-message>
                  <div class="columns">
<!--                    <div class="column">
                      <b-field label="Tipo de entidad*">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <multiselect v-model="entity_type"
                                       :options="entity_types"
                                       :custom-label="opt => opt.alt_name ? opt.alt_name : opt.name"
                                       placeholder="Seleccione tipo de entidad"
                                       selectLabel="Presione para seleccionar"
                                       selectedLabel="Seleccionado"
                                       deselectLabel="No se puede deseleccionar"
                                       :allow-empty="false">
                            <template v-slot:noOptions>
                              No existen datos
                            </template>
                            <span slot="noResult">
                            No se encontraron elementos.
                        </span>
                          </multiselect>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>-->
                    <div class="column">
                        <b-field :type="{ 'is-danger': (isAcademicRegister) }"
                                 :message="{ 'Seleccione una entidad registrada.': (isAcademicRegister) }">
                          <template #label>
                            Nombre Entidad Externa* (ingrese por lo menos 3 letras)
                            <b-tooltip
                                label="Se recomienda búsqueda de agrupaciones por sigla, ejemplo: ANID, no Agencia Nacional... mientras que para instituciones educacionales, se recomienda por nombre completo, ejemplo: universidad de santiago de chile"
                                position="is-left"
                                size="is-medium"
                                multilined>

                              <b-icon
                                  pack="fas"
                                  icon="info-circle"
                                  size="is-small"
                                  type="is-info">
                              </b-icon>
                            </b-tooltip>
                          </template>
                            <b-autocomplete
                                :data="partner_entities"
                                placeholder="ej ANID o UNIVERSIDAD DE SANTIAGO DE CHILE  o NOMBRE EMPRESA - ENTIDAD"
                                field="name"
                                :loading="isFetching"
                                @typing="searchPartnerEntities"
                                v-model="partner_entity.name"
                                @select="option => {if (option) {partner_entity.id = option.id; partner_entity.name = option.name;}}">
                              <template slot-scope="props">
                                {{ props.option.show_name ? props.option.show_name : props.option.name}}
                              </template>
                            </b-autocomplete>
                        </b-field>
                      </div>
                  </div>
                    <b-field label="Asiste como"
                        :type="{ 'is-danger': checkActivityAssistantType }"
                        :message="{ 'Seleccione el nombre del tipo de entidad asistente.': checkActivityAssistantType }">
                        <b-select placeholder="Seleccione un tipo de entidad" v-model='newPersonAssistantTypeId'>
                            <option
                                v-for="assistant_type in vime_assistant_types"
                                :value="assistant_type.id"
                                :key="assistant_type.id">
                                {{ assistant_type.name }}
                            </option>
                        </b-select>
                    </b-field>
                </section>
                <footer class="modal-card-foot">
                    <b-button
                        label="Cerrar"
                        @click="$emit('close')" />
                    <b-button
                        label="Añadir"
                        type="is-primary"
                        @click="addAssistant()"/>
                </footer>
            </div>
        </form>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
</template>
<script>
import axios from "axios";

export default {
    name: 'VimeActivityNewEntitiesRelatedModal',
    props:{
        vime_assistant_types:{
            type: Array,
            default: () => []
        }
    },
    data(){
        return {
            isLoading: false,
            keepFirst: false,
            openOnFocus: false,
            vime_activity_assistances_info:[],
            name: '',
            selected: null,
            clearable: false,
            new_entity:{
                partner_entities_id: -1,
                assistance_type_id: -1
            },
            validations:{
                firstTimeActivityAssistantName: true,
                firstTimeActivityAssistantType: true
            },
            entity_type:{},
            entity_types:[],
            partner_entity:{},
            partner_entities:[],
            isFetching:false,
            isAcademicRegister:false,
        }
    },
    computed:{
        filteredDataObj() {
            return this.vime_activity_assistances_info.filter(option => option.fullName.toLowerCase().includes(this.name.toLowerCase())).slice(0,10);
        },
        newEntityId:{
            get(){
                return this.new_entity.partner_entities_id
            },
            set(newValue){
                this.validations.firstTimeActivityAssistantName = false
                this.new_entity.partner_entities_id = newValue
            }
        },
        newPersonAssistantTypeId:{
            get(){
                return this.new_entity.assistance_type_id
            },
            set(newValue){
                this.validations.firstTimeActivityAssistantType = false
                this.new_entity.assistance_type_id = newValue
            }
        },
        checkEntityName(){
            if(this.validations.firstTimeActivityAssistantName)
                return false
            else
                if(this.selected !== null)
                    return this.selected.partner_entities_id === -1
                else
                    return true
        },
        checkActivityAssistantType() {
            if(this.validations.firstTimeActivityAssistantType)
                return false
            else
                return this.new_entity.assistance_type_id === -1
        }
    },
    async beforeMount() {
      //this.getEntitiesAssistancesToModal()
      await this.getAssociationsTypes();
    },
    methods:{
        getName(name, last_name, second_surname){
            return name + ' ' + last_name + ' ' + second_surname
        },
        getEntityNameById(entity_id){
            var name = ''
            this.vime_activity_assistances_info.forEach(function(element){
                if(element.entity_id === entity_id)
                    name = element.fullName
            })
            return name
        },
        getAsistentTypeNameById(assistant_type_id){
            var type_name = ''
            this.vime_assistant_types.forEach(function(element){
                if(element.id === assistant_type_id)
                    type_name = element.name
            })
            return type_name
        },
        getEntityTypeById(entity_id){
            var entity_type = ''
            this.vime_activity_assistances_info.forEach(function(element){
                if(element.entity_id === entity_id)
                    entity_type = element.entity_type
            })
            return entity_type
        },
        getEntityPhoneById(entity_id){
            var entity_phone = ''
            this.vime_activity_assistances_info.forEach(function(element){
                if(element.entity_id === entity_id)
                    entity_phone = element.entity_phone
            })
            return entity_phone
        },
        getEntityEmailById(entity_id){
            var entity_email = ''
            this.vime_activity_assistances_info.forEach(function(element){
                if(element.entity_id === entity_id)
                    entity_email = element.entity_email
            })
            return entity_email
        },
        checkEmptyFields(){
            if(this.validations.firstTimeActivityAssistantName === true){
                this.validations.firstTimeActivityAssistantName = false
            }
            if(this.validations.firstTimeActivityAssistantType === true){
                this.validations.firstTimeActivityAssistantType = false
            }
            
        },
        checkSave(){
            if (this.getPartnerEntityByName(this.partner_entity.name) == 0){
              this.isAcademicRegister = true
            }
            if(this.partner_entity !== null && this.partner_entities !== {} && this.isAcademicRegister == false){
                var A = this.partner_entity.id !== -1
                var B = this.newPersonAssistantTypeId !== -1
                if(A && B){
                    return true
                }
                else{
                    return false
                }
            }
            else{
                }
                return false
        },
        addAssistant(){
            if(this.checkSave()){
                this.new_entity.entity_name = this.partner_entity.name
                this.new_entity.assistance_type_name = this.getAsistentTypeNameById(this.newPersonAssistantTypeId)
                this.new_entity.entity_type = this.entity_type.name
                this.new_entity.entity_phone = this.partner_entity.phone
                this.new_entity.entity_email = this.partner_entity.email
                this.new_entity.entity_id = this.partner_entity.id
                this.newEntityId = this.partner_entity.id
                this.$emit('add-assistant', this.new_entity);
                this.$emit('close', this.new_entity);
            }
            else{
                this.checkEmptyFields()
            }
        },
      getEntitiesAssistancesToModal(){
        this.isLoading = true
        axios.get('/activity_assistances/getEntitiesInfoForModal')
            .then(response =>{
              this.vime_activity_assistances_info = response.data.assistance
              this.isLoading = false
            })
            .catch(e => {
              console.log(e)
              this.isLoading = false
            })
      },
      async getAssociationsTypes(){
        let vm = this;
        vm.isLoading=true;
        axios
            .get("/association-types.json")
            .then(res => {
              //vm.associations_types = res.data.associaton_types;
              vm.entity_types = res.data.entity_types;
              vm.isLoading = false;
            })
            .catch(error => {
              console.log(error)
              vm.isLoading=false;
            })

      },
      searchPartnerEntities(name){
        this.isAcademicRegister =  false
        if (name.length<3) {
          this.partner_entities = []
          return
        }
        let vm = this;
        vm.isLoading=true;
        axios
            .get("/admin/partner_entities/agreement-search.json", { params: { name: name } })
            .then(res => {
              vm.partner_entities = res.data;
              vm.isLoading = false;
            })
            .catch(error => {
              console.log(error)
              vm.isLoading=false;
            })
      },
      getPartnerEntityByName(name){
        var partnerEntity = 0
        //console.log(this.partner_entities_search)
        this.partner_entities.forEach(function (element){
          if(element.name === name){
            partnerEntity = element
          }
        })
        return partnerEntity
      },
    }   
}
</script>
<style lang="scss">
.vimeactivitynewentitiesrelatedmodal{
    .modal-card{
        width: 1020px;
    }
}
</style>