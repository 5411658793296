<template>
  <div>
    <surpluses-table v-bind:surpluses="this.surpluses"
            :unidad="unitId"
            hide-update="true"></surpluses-table>
           
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>       
  </div>
</template>
<script>
import axios from "axios";
import SurplusesTable from "../SurplusesTable.vue";
import moment from "moment";
export default {
  props:[
    'unitId','cargoPrincipal'
  ],
  data(){
    return{
      isLoading:false,
      surpluses:[],
      year: new Date().getFullYear(),
    }
  },
  components:{
   SurplusesTable
  },
  created(){
    this.getSurpluses();

  },
  methods:{
    getSurpluses(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/sdt_surpluses.json", {params:{unit:vm.unitId}})
        .then(res => {
          vm.surpluses = res.data;
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },

  },
}
</script>