<template>
    <div class="agreementhistorytable">
      <b-table
                    :data="sortAgreemetHistory"
                    :paginated=true
                    per-page=10
                    :current-page="currentPage"
                    :pagination-simple=false
                    pagination-position="bottom"
                    aria-next-label="Siguiente"
                    aria-previous-label="Anterior"
                    aria-page-label="Página"
                    aria-current-label="Página actual">
                    <b-table-column
                        field= 'registration_date'
                        label= 'Fecha'
                        sortable
                        searchable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.registration_date}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field= 'agreement_process_name'
                        label= 'Proceso'
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.agreement_process_name}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field= 'agreement_actor_name'
                        label= 'Actor'
                        sortable
                        searchable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.agreement_actor_name}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field= 'notes'
                        label= 'Notas'
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.notes}}
                      </template>
                    </b-table-column>
                  <template slot="empty" slot-scope="props">
                    <div class="columns is-centered">
                      <div class="column is-6 is-centered has-text-centered">
                        <b-message v-if="can_create_history" has-icon type="is-warning">
                          <h1> No se encontraron resultados, haz click <a @click="addNewAgreementHistory()">aquí</a> para añadir un registro de estado del convenio.</h1>
                        </b-message>
                        <b-message v-else has-icon type="is-warning">
                          <h1> No se encontraron resultados.</h1>
                        </b-message>
                      </div>
                    </div>
                  </template>
                </b-table>
    </div>
</template>
<script>
export default {
    name: "AgreementHistoryTable",
    props:{
        agreement_id:{
            type: Number
        },
        agreement_history:{
            type: Array
        },
        can_create_history:{
          type: Boolean
        }
    },
    data(){
        return {
            currentPage:1
        }
    },
    computed:{
        sortAgreemetHistory(){
            this.agreement_history.sort(function(a,b){
                return a.id - b.id
            })
            return this.agreement_history
        },
    },
    methods:{
        addNewAgreementHistory(){
            window.location.href="/agreement_histories/new/"+this.agreement_id
        }
    }
}
</script>