<template>
<section>
  <b-modal width="90%" v-model="activeModal"
           aria-modal
           :can-cancel="canCancel"
            :on-cancel="closeModal">
    <div class="modal-card" style="width:100%">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="closeModal"/>
      </header>
      <section class="modal-card-body" ref="modalPersonForm" style="width:100%">
        <div class="columns">
          <div class="column is-12">
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
                <b-message type="is-danger" has-icon>
                  <div v-for="(item,index) in errores_validacion">
                    {{ item[0] }}
                  </div>
                </b-message>
              </div>
              <form @submit.prevent="handleSubmit(saveFinancing)">
                <!-- Box de Formulario Project -->
                <div class="box">
                  <div class="columns">
                    <div class="column">
                      <b-field label="Monto*">
                        <ValidationProvider rules="required|integer|min_value:0" v-slot="{ errors }">
                          <b-input :has-counter="false" maxlength="14" v-model="financing.amount"></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div class="column is-4">
                      <b-field label="Año*">
                        <ValidationProvider rules="required|integer|min_value:1900" v-slot="{ errors }">
                          <b-input v-model="financing.year"></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>

                      </b-field>
                    </div>
                  </div>
                  <div class="columns" style="margin-bottom: 5em">

                    <div class="column">
                      <b-field>
                        <template #label>
                          Entidad de Financiamiento* (ingrese por lo menos 3 letras)
                          <b-tooltip
                              label="Se recomienda búsqueda por sigla, ejemplo: ANID, no Agencia Nacional... mientras que para instituciones educacionales, se recomienda por nombre completo, ejemplo: universidad de santiago de chile"
                              position="is-right"
                              size="is-medium"
                              multilined>

                            <b-icon
                                pack="fas"
                                icon="info-circle"
                                size="is-small"
                                type="is-info">
                            </b-icon>
                          </b-tooltip>
                        </template>
                        <ValidationProvider rules="required" v-slot="{ validate, errors }">
                          <b-autocomplete
                              @input="validate"
                              :data="partner_entities"
                              placeholder="ej ANID o UNIVERSIDAD DE SANTIAGO DE CHILE  o NOMBRE EMPRESA - ENTIDAD"
                              field="show_name"
                              :loading="isFetching"
                              @typing="searchPartnerEntities"
                              v-model="partner_entity.name"
                              @select="option => {if (option) {partner_entity = option; program={}}}">

                            <template slot-scope="props">
                              {{ props.option.show_name ? props.option.show_name : props.option.name}}
                            </template>
                          </b-autocomplete>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>

                      </b-field>
                    </div>
                    <div v-if="programs.length>0" class="column">
                      <b-field label="Programa de financiamiento">
                        <multiselect v-model="program"
                                     :options="programs"
                                     :custom-label="opt => opt.name"
                                     placeholder="Seleccione programa de financiamiento"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel="Seleccionado"
                                     deselectLabel="Presione para deseleccionar"
                                     :allow-empty="true">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                            No se encontraron elementos.
                        </span>
                        </multiselect>
                      </b-field>
                    </div>
                  </div>

                  <div class="actions has-text-right">
                    <button class="button is-danger" icon-left="trash" @click.prevent="closeModal">Cancelar</button>
                    <button class="button is-primary" type="submit" icon-left="plus" @click="checkValidation()">Guardar Remesa</button>
                  </div>

                </div>


              </form>
            </ValidationObserver>

          </div>
        </div>
      </section>
    </div>
  </b-modal>
  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
</section>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "AddFinancingModal",
  data() {
    return {
      isImageModalActive: false,
      isCardModalActive: false,
      canCancel: ['escape'],
      isLoading: false,
      financing:{},
      partner_entities:[],
      partner_entity:{},
      programs:[],
      program:{},
      errores_validacion:{},
      isFetching:false,
      destroyOnHide:true,
      entity_type:{},
      entity_types:[],
    }
  },
  props:['modalHeader','activeModal','projectId','financingId','actionType','actionButtonName'],
  async created() {
    if (this.activeModal)
      this.isCardModalActive = true;
    //await this.getEntityTypes();
    if (this.financingId){
      this.getProjectFinancing();
    }
  },
  watch: {

    partner_entity: function (val){
      if(this.partner_entity){
        this.searchPrograms();
      }
    },
    financingId: function (val){
      if (this.financingId){
        this.getProjectFinancing();
      }
    }
  },
  methods:{
    async checkValidation() {
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
    saveFinancing(){
      let vm = this;
      if (vm.actionType=="create"){
        vm.createFinancing();
      }
      else if (vm.actionType=="update"){
        vm.updateFinancing();
      }


    },
    createFinancing(){
      let vm = this;
      vm.isLoading=true;
      if (vm.program && vm.financing){
        vm.financing.program_id = vm.program.id;
      }
      if (vm.program && vm.partner_entity){
        vm.financing.partner_entity_id = vm.partner_entity.id;
      }
      axios
        .post("/projects/"+vm.projectId+"/financing.json", {
          financings_attributes: [vm.financing],
          id:vm.projectId
        })
        .then(res => {
          vm.errores_validacion = {};
          vm.$buefy.dialog.alert({
            message: 'La remesa fue asociada satisfactoriamente.',
            type: 'is-success',
            hasIcon: true,
            icon: 'check-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          //EMITIR FINANCIAMIENTO NUEVO A COMPONENTE PADRE
          var retorno = {
            financing:vm.financing,
            partner_entity: vm.partner_entity,
            program: vm.program
          }
          vm.$emit('addFinancing', retorno)
          //vm.financing = {};
          //vm.program = {};
          //vm.partner_entity = {};
          vm.isLoading=false;
        }).
      catch(error => {
        vm.errores_validacion = error.response.data
        var elmnt = document.getElementById("app");
        elmnt.scrollIntoView();
        vm.isLoading=false;
      })

    },
    updateFinancing(){
      let vm = this;
      vm.isLoading=true;
      if (vm.program && vm.financing){
        vm.financing.program_id = vm.program.id;
      }
      if (vm.program && vm.partner_entity){
        vm.financing.partner_entity_id = vm.partner_entity.id;
      }
      axios
        .put("/projects/"+vm.projectId+"/financing.json", {
          financings_attributes: [vm.financing],
          id:vm.projectId
        })
        .then(res => {
          vm.errores_validacion = {};
          vm.$buefy.dialog.alert({
            message: 'La remesa fue actualizada satisfactoriamente.',
            type: 'is-success',
            hasIcon: true,
            icon: 'check-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          //EMITIR FINANCIAMIENTO NUEVO A COMPONENTE PADRE

          vm.$emit('updateFinancing')
          vm.financing = {};
          vm.program = {};
          vm.partner_entity = {};
          vm.isLoading=false;
        }).
        catch(error => {
          vm.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          vm.isLoading=false;
        })

    },

    getProjectFinancing(){
      let vm = this;
      vm.isLoading=true;
      return axios
        .get("/financings/"+vm.financingId+".json")
        .then(res => {
          vm.financing = res.data;
          vm.partner_entity = res.data.selected_entity;
          //vm.entity_type = vm.entity_types.find(o => o.id == vm.partner_entity.entity_type_id)
          vm.isLoading=false;
        })
        .catch(e => {
          console.log(e);
          vm.isLoading=false;
        });
    },
    closeModal(){
      this.isLoading = true;
      this.financing = {};
      this.program = {};
      this.partner_entity = {};
      this.programs = [];
      this.isLoading = false;
      this.$emit('close');
    },
    searchPartnerEntities(name){
      if (name.length<3) {
        this.partner_entities = []
        return
      }
      let vm = this;
      vm.isLoading=true;
      //vm.isFetching = true;
      axios
        .get("/admin/partner_entities/financings-search.json", { params: { name: name } })
        .then(res => {
          vm.partner_entities = res.data;

          vm.isLoading = false;
          //vm.isFetching = false;
        })
        .catch(error => {
          //console.log(error)
          vm.isLoading=false;
          //vm.isFetching = false;
        })

    },
    searchPrograms(){
      let vm = this;
      if (vm.partner_entity.id){
        vm.isLoading = true;
        axios
            .get("/partner_entities/"+vm.partner_entity.id+"/programs.json")
            .then(res => {
              vm.programs = res.data;
              vm.programs = vm.programs.map(program => {
                var program = program
                program["start_date"] = moment(program.start_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
                program["end_date"] = moment(program.end_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
                return program;
              });
              if (vm.financing.program_id && vm.programs.length > 0){
                var program = vm.programs.filter(o => o.id==vm.financing.program_id)
                if (program){
                  vm.program = program[0]
                }
              }
              vm.isLoading=false;
            })
            .catch(e => {
              console.log(e);
              vm.isLoading=false;
            });
      }

    },
    async getEntityTypes(){
      let vm = this;
      //vm.isLoading=true;
      vm.isFetching = true;
      axios
          .get("/admin/entity_types.json")
          .then(res => {
            vm.entity_types = res.data;
            //vm.isLoading = false;
            vm.isFetching = false;
          })
          .catch(error => {
            console.log(error)
            //vm.isLoading=false;
            vm.isFetching = false;
          })
    },

  }
}
</script>

<style scoped>

</style>