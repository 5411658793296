<template>
  <div>
    <div class="columns">
      <div class="column has-text-right">
        <b-field>
          <b-switch v-model="cardLayout">Formato tarjeta (sin filtros ni búsqueda)
            <b-tooltip
                label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                multilined
                position="is-left">
              <b-icon
                  pack="fas"
                  icon="info-circle"
                  type="is-info">
              </b-icon>
            </b-tooltip></b-switch>
        </b-field>
      </div>
    </div>
    <b-table
        :data="projects_nullified"
        :paginated=true
        :card-layout="cardLayout"
        per-page=10
        striped
        hoverable
        :current-page="currentPage"
        :pagination-simple=false
        pagination-position="bottom"
        default-sort-direction="asc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="project.name"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página actual"
        :opened-detailed="defaultOpenedDetails"
        detailed
        detail-key="code"
        :show-detail-icon="showDetailIcon"
    >
      <b-table-column
          field= 'name'
          label= 'Nombre'
          width="30em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.name }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'project_type'
          label= 'Tipo de Proyecto'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.project_type }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'code'
          label= 'Código'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.code }}
        </template>
      </b-table-column>
      <b-table-column
          v-if="!showPostulated"
          field= 'award_year'
          label= 'Año de adjudicación'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.award_year }}
        </template>
      </b-table-column>
      <b-table-column
          v-if="!showPostulated"
          field= 'start_date'
          label= 'Fecha Inicio'
          searchable
          :custom-search="searchInitDate"
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL")  : "" }}
        </template>
      </b-table-column>
      <b-table-column
          v-if="!showPostulated"
          field= 'end_date'
          label= 'Fecha Término'
          searchable
          :custom-search="searchEndDate"
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL") : "" }}
        </template>
      </b-table-column>
      <b-table-column
          v-if="showPostulated"
          field= 'postulation_date'
          label= 'Fecha de Postulación'
          searchable
          :custom-search="searchPostulationDate"
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.postulation_date._isValid ? new Date(props.row.postulation_date).toLocaleDateString("es-CL") : "" }}
        </template>
      </b-table-column>
      <b-table-column
          label="Acciones"
          v-slot="props">
        <b-icon
            pack="fas"
            icon="eye"
            size="is-medium"
            @click.native="execShowModal(props.row.id)"/>
        <b-icon
            pack="fas"
            icon="trash-restore"
            size="is-medium"
            type="is-success"
            @click.native="execRestoreModal(props.row.id, props.row)"/>
      </b-table-column>
      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>Participantes: </strong>
                <span v-for="(participant, index) in props.row.participants" :key="index">
                  <a v-if="participant.url!=null" :href="participant.url">{{ participant.name }}</a>
                  <span v-else>{{ participant.name }}</span>
                  <span v-if="props.row.participants.length>1 && participant!=props.row.participants.slice(-1)[0]">, </span>
                </span>
              </p>
            </div>
          </div>
        </article>
      </template>
      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-danger">
              No se encontraron resultados para el año seleccionado.
            </b-message>
          </div>
        </div>
      </template>
    </b-table>

    <b-modal
        v-model="isShowModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal_show_nullified_project :projectId="projectId"
                                      :cargoPrincipal="cargoPrincipal"
                       query-type="create"
                       modal-header="Proyecto"
                       @close="props.close">
        </modal_show_nullified_project>
      </template>
    </b-modal>


    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  </div>
</template>
<script>
import moment from "moment";
import {searchDate} from "../../packs/utilities";
import axios from "axios";
import modal_show_nullified_project from "./modal_show_nullified_project";
export default {
  components:{
    modal_show_nullified_project
  },
  props:[
    'showPostulated','cargoPrincipal'
  ],
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      years:[],
      year:null,
      isNullifyActive: false,
      projects_nullified:[],
      isShowModalActive: false,
      projectId: null,
    }
  },
  created(){
    if(this.cargoPrincipal.name == 'ANALISTA I+D FING' || this.cargoPrincipal.name == 'SUPERADMIN'){
      this.getProjectsNullified()
    }
    else {
      this.myGetProjectsNullified()
    }

  },
  methods:{
    getProjectUrl(project){
      if(project.is_postulated){
        return "/projects/postulated/"+project.id;
      }
      else{
        return "/projects/"+project.id;
      }
    },
    searchEndDate(row,input){
      return searchDate(row.end_date,input)
    },
    searchInitDate(row,input){
      return searchDate(row.start_date,input)
    },
    searchPostulationDate(row,input){
      return searchDate(row.start_date,input)
    },
    execRestoreModal(project_id, row){
      let vm = this;
      vm.$buefy.dialog.confirm({
        title: 'Restaurar proyecto',
        message: '¿Está seguro que desea <b>restaurar</b> el proyecto? Esta acción revertirá la anulación.',
        confirmText: 'Restaurar Proyecto',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          vm.isRestoreActive = true
          vm.isLoading = true
          axios
              .put("/projects/" + project_id + "/restore_project.json")
              .then(res => {
                vm.$buefy.dialog.alert({
                  message: 'Proyecto restaurado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                var index = this.projects_nullified.indexOf(row)
                this.projects_nullified.splice(index, 1)
                vm.isLoading = false;
                vm.isRestoreActive = false;
              }).catch(error => {
            console.log(error)
            vm.$buefy.dialog.alert({
              message: '<b>Proyecto no pudo ser restaurado</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            vm.isLoading = false;
            vm.isRestoreActive = false;
          })
        }
      })
    },

    execShowModal(id){
      this.projectId =  id
      this.isShowModalActive = true
    },

    getProjectsNullified(){
      this.isLoading=true;
      return axios.get("/projects.json",{params:{year_f:-1}})
          .then(res => {

            this.projects_nullified = res.data;
            this.projects_nullified= this.projects_nullified.map(proyecto => {
              var newProyecto = proyecto
              newProyecto["start_date"] =  moment(newProyecto["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newProyecto["end_date"] =  moment(newProyecto["end_date"] , ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newProyecto;
            });
            this.isLoading=false;
          })
          .catch(e => {
            this.isLoading=false;
            console.log(e);
          });
    },
    myGetProjectsNullified(){
      this.isLoading=true;
      return axios.get("/projects/my_nullified_projects.json")
          .then(res => {

            var projects_nullified_temp = res.data;
            projects_nullified_temp = projects_nullified_temp.map(proyecto => {
              var newProyecto = proyecto
              newProyecto["start_date"] =  moment(newProyecto["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newProyecto["end_date"] =  moment(newProyecto["end_date"] , ["YYYY-MM-DD","DD-MM-YYYY"]);
              if (newProyecto["is_creator"] == true){
                this.projects_nullified.push(newProyecto);
              }
            });
            console.log(this.projects_nullified)
            this.isLoading=false;
          })
          .catch(e => {
            this.isLoading=false;
            console.log(e);
          });
    },


  },
  computed:{

  }
}
</script>

