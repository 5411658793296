<template>
  <div>
    <b-table
        :data="personas"
        :paginated= true
        per-page="10"
        striped
        hoverable
        :current-page="currentPage"
        :pagination-simple=false
        pagination-position="bottom"
        default-sort-direction="asc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="name"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página actual">
      <b-table-column
          field="name"
          label="Nombre"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{props.row.name}}
        </template>
      </b-table-column>
      <b-table-column
          field="last_name"
          label="Primer Apellido"
          sortable
          searchable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{props.row.last_name}}
        </template>
      </b-table-column>
      <b-table-column
          field="second_surname"
          label="Segundo Apellido"
          sortable
          searchable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{props.row.second_surname}}
        </template>
      </b-table-column>
      <b-table-column
          v-if="false"
          field="gender_attributes.name"
          label="Género"
          searchable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{props.row.gender_attributes!=null ? props.row.gender_attributes.name : ""}}
        </template>
      </b-table-column>
      <b-table-column
          label="Acciones"
          v-slot="props"
      >
        <a :href="props.row.url"><b-button type="is-info" icon-left="user-circle">
          Ver perfil
        </b-button></a>
      </b-table-column>
      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message v-if="advanceSearch != true" type="is-danger">
              No se encontraron resultados.
            </b-message>
            <b-message v-else type="is-danger">
              No se encontraron resultados para los criterios de búsqueda seleccionados.
            </b-message>
          </div>
        </div>
      </template>
    </b-table>

  </div>
</template>
<script>
export default {
  props:[
      'personas','advanceSearch'
  ],
  data(){
    return{
      currentPage:1,
    }
  },
  created(){

  },
  methods:{

  },
  computed:{

  }
}
</script>

