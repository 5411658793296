<template>
  <section>
    <div class="container">
      <h1 class="is-size-2">Búsqueda de Entidades EFUSACH</h1>
      <div class="box">
        <div v-if="can_create == 'true'">
          <div class="column has-text-left is-pulled-right">
            <b-dropdown aria-role="list">
              <button class="button is-primary" slot="trigger" slot-scope="{ active }">
                <b-icon icon="plus"></b-icon>
                <span>Añadir Transacción</span>
              </button>
              <b-dropdown-item has-link aria-role="menuitem">
                <a href="/sdt_transactions/new/item">
                  <b-icon icon="shopping-bag"></b-icon>
                  Bien
                </a>
              </b-dropdown-item>
              <b-dropdown-item has-link aria-role="menuitem">
                <a href="/sdt_transactions/new/contract">
                  <b-icon icon="handshake"></b-icon>
                  Contratación
                </a>
              </b-dropdown-item>
              <b-dropdown-item has-link aria-role="menuitem">
                <a href="/sdt_transactions/new/service">
                  <b-icon icon="receipt"></b-icon>
                  Servicio
                </a>
              </b-dropdown-item>
              <b-dropdown-item has-link aria-role="menuitem">
                <a href="/sdt_transactions/new/accountability-fund-item">
                  <b-icon icon="pallet"></b-icon>
                  Rendición de fondos para bienes
                </a>
              </b-dropdown-item>
              <b-dropdown-item has-link aria-role="menuitem">
                <a href="/sdt_transactions/new/accountability-fund-service">
                  <b-icon icon="truck-loading"></b-icon>
                  Rendición de fondos para servicios
                </a>
              </b-dropdown-item> 
              <b-dropdown-item has-link aria-role="menuitem">
                <a href="/sdt_transactions/new/reimbursement-item">
                  <b-icon icon="box-open"></b-icon>
                  Reembolso de gastos para bienes
                </a>
              </b-dropdown-item>
              <b-dropdown-item has-link aria-role="menuitem">
                <a href="/sdt_transactions/new/reimbursement-service">
                  <b-icon icon="boxes"></b-icon>
                  Reembolso de gastos para servicios
                </a>
              </b-dropdown-item>        
            </b-dropdown>
          </div>
        </div>
        
        <br>
        <surpluses-table v-bind:surpluses="this.surpluses" 
            :cargoPrincipal="cargoPrincipal"
            :unidad="unidadPrincipal.id"
            :message="message" 
            :canModify="can_modify"
            @updateSurplusesFilter="updateSurplusesFilter"></surpluses-table>

        
      </div>
     
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import axios from "axios";
import SurplusesTable from "./SurplusesTable.vue";
import moment from "moment";

export default {
  components:{SurplusesTable},
  data(){
    return{
      isLoading:false,
      surpluses:[],
      message: '',

    }
  },
  async created(){
    this.getSurpluses();
  },
  props:[
    'can_create', 'cargoPrincipal','unidadPrincipal', 'userId','can_modify'
  ],
  methods:{
    getSurpluses(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/sdt_surpluses.json", {params:{unit:vm.unidadPrincipal.id}})
        .then(res => {
          vm.surpluses = res.data;        
          vm.message = "Se han encontrado "+vm.surpluses.length+" códigos EFUSACH que cumplen su búsqueda"
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    getSurplusesFilter(unit, entidad){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/sdt_surpluses.json", {params:{
                                              unit:unit,
                                              entidad:entidad,
                                              }})
        .then(res => {
          vm.surpluses = res.data;
          vm.message = "Se han encontrado "+vm.surpluses.length+" códigos EFUSACH que cumplen su búsqueda"
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    updateSurplusesFilter(datos){
      this.getSurplusesFilter(datos["unit"], datos["entidad"]);
    }

  },
  computed:{

  }
}
</script>