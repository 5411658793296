<template>
  <section>
    <b-table striped :data="publications" paginated per-page=10 :pagination-simple=false pagination-position="bottom"
             aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
             aria-current-label="Página actual">

      <b-table-column label="Título del Libro" field="book_title" sortable searchable>
        <template #searchable="props">
          <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Título..." icon="search" />
        </template>
        <template v-slot="props">
          {{ props.row.book_title }}
        </template>
      </b-table-column>

      <b-table-column label="Título del Capítulo" field="title" sortable searchable>
        <template #searchable="props">
          <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Título..." icon="search" />
        </template>
        <template v-slot="props">
          {{ props.row.title }}
        </template>
      </b-table-column>

      <b-table-column label="Autores" field="participants_references" sortable searchable>
        <template #searchable="props">
          <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Autores..." icon="search" />
        </template>
        <template v-slot="props">
          {{ props.row.participants_references }}
        </template>
      </b-table-column>

      <b-table-column label="Pagina Inicio - Final" v-slot="props" width="100">
        {{ props.row.start_page + ' - ' + props.row.end_page }}
      </b-table-column>

      <b-table-column v-show="cargoPrincipal.name == 'ANALISTA I+D FING' || cargoPrincipal.name == 'SUPERADMIN'"
                      label="Acciones" v-slot="props">
        <div class="columns">
          <div v-if="props.row.can_show" class="column">
            <b-tooltip
                label="Mostrar en detalle la información del capítulo de un libro"
                :active="true">
              <b-button pack="fas" icon-right="eye" type="is-info" tag="a" :href="/books/ + props.row.id"
                        target="_blank" />
            </b-tooltip>
          </div>
          <div v-if="props.row.can_edit" class="column">
            <b-tooltip
                label="Editar la información del capítulo de un libro"
                :active="true">
              <b-button pack="fas" icon-right="edit" type="is-warning"
                        @click="isComponentModalActive = true; selectedChapterId = props.row.id" />
            </b-tooltip>
          </div>
          <div v-if="props.row.can_null" class="column">
            <b-tooltip
                label="Anular registro del capítulo de un libro"
                :active="true">
              <b-button pack="fas" icon-right="ban" type="is-danger" @click="nullBookPublication(props.row)" />
            </b-tooltip>
          </div>
        </div>
      </b-table-column>

    </b-table>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

    <b-modal :active="isComponentModalActive" width="90%" :can-cancel="false" aria-modal>
      <template>
        <NewInbookFormVue modal-cancel="true" :cargo-principal="cargoPrincipal"
                          :publication-type-id="'chapter'" :fromAcademic="true" modal-header="Editar Sección" query-type="update"
                          :inbook-id="selectedChapterId" @close="isComponentModalActive = false" @refresh="getPublications()" />
      </template>
    </b-modal>

  </section>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import NewInbookFormVue from '../books/NewInbookForm.vue'
export default {
  props: [
    'objectId','componentType', 'cargoPrincipal','currentYear','minYear','maxYear', 'changeFilter', 'hideUpdate'
  ],
  components: {
    NewInbookFormVue,
  },
  data() {
    return {
      publications: [],
      isLoading: false,
      isComponentModalActive: false,
      selectedChapterId: null,
    }
  },
  created() {
    this.getPublications()
  },
  methods: {
    getPublications() {
      this.isLoading = true
      if(this.hideUpdate == true){
        this.year = 'MyActivity'
      }
      axios
          .get('/admin/units/'+this.objectId+'/booklets.json', {
            params: {
              year_f: this.year,
              minYear: this.minYear,
              maxYear: this.maxYear
            }
          })
          .then(response => {
            this.publications = response.data
            this.publications = this.publications.map(publication => {
              publication.publication_date = moment(publication.publication_date, ['YYYY-MM-DD', 'DD-MM-YYYY'])
              return publication
            })
            this.isLoading = false
          })
          .catch(error => {
            this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Hubo un error al traer sus Publicaciones en Capítulo de Libro, favor de revisar los errores.',
              type: 'is-danger',
              hasIcon: true,
              iconPack: 'fa',
              icon: 'times-circle',
              confirmText: 'Ok',

            })
            console.log(error)
            this.isLoading = false
          })
    },
    nullBookPublication(publication) {
      this.$buefy.dialog.confirm({
        title: 'Anular Publicación en Capítulo de Libro',
        message: '¿Está seguro que desea <b>anular</b> la Publicación en Capítulo de Libro? Esta acción lo dejará inválido.<br>',
        confirmText: 'Anular Publicación en Capítulo de Libro',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          this.isLoading = true
          await axios
              .delete("/publications/" + publication.id + "/nullify_publication.json")
              .then(response => {
                this.$buefy.dialog.alert({
                  title: 'Logrado',
                  type: 'is-success',
                  hasIcon: true,
                  icon: 'check-circle',
                  iconPack: 'fas',
                  message: 'La Publicación en Capítulo de Libro ha sido anulada exitosamente.',
                  confirmText: 'Aceptar'
                })
                this.getPublications()
              })
              .catch(error => {
                this.$buefy.dialog.alert({
                  title: 'Error',
                  message: 'Hubo un error al anular la Publicación en Capítulo de Libro.<br><b>Errores:</b><br>' + error.response.data,
                  type: 'is-danger',
                  hasIcon: true,
                  icon: 'times-circle',
                  iconPack: 'fa',
                  ariaRole: 'alertdialog',
                  ariaModal: true
                })
                this.isLoading = false
              })
        }
      })
    }
  },
  watch: {
    changeFilter: function () {
      this.getPublications()
    }
  }

}
</script>