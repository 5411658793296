<template>
  <div class="container">
    <div class="columns">
      <div class="column is-3">
        <b-field  label="Tipo de Unidad">
          <multiselect v-model="type"
                       :options="unit_types"
                       placeholder="Seleccione tipo de unidad"
                       selectLabel="Presione para seleccionar"
                       selectedLabel="Seleccionado"
                       deselectLabel="No se puede deseleccionar"
                       :allow-empty="false">
            <template v-slot:noOptions>
              No existen datos
            </template>
            <span slot="noResult">
                No se encontraron elementos.
            </span>
          </multiselect>
        </b-field>
      </div>
      <div class="column is-3">
        <b-field  label="Unidad">
          <multiselect v-model="unit"
                       :options="units"
                       placeholder="Seleccione unidad"
                       selectLabel="Presione para seleccionar"
                       selectedLabel="Seleccionado"
                       deselectLabel="No se puede deseleccionar"
                       :allow-empty="false">
            <template v-slot:noOptions>
              No existen datos
            </template>
            <span slot="noResult">
                No se encontraron elementos.
            </span>
          </multiselect>
        </b-field>
      </div>
      <div class="column is-3">
        <b-field label="Estado">
          <multiselect v-model="state"
                       :options="states"
                       placeholder="Seleccione estado"
                       selectLabel="Presione para seleccionar"
                       selectedLabel="Seleccionado"
                       deselectLabel="No se puede deseleccionar"
                       :allow-empty="false">
            <template v-slot:noOptions>
              No existen datos
            </template>
            <span slot="noResult">
                No se encontraron elementos.
            </span>
          </multiselect>
        </b-field>
      </div>
      <div class="column">
        <div class="has-text-right">
          <b-button label="Exportar"
                    class="is-primary is-outlined"
                    @click="exportData"
                    icon-left="file-excel"/>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="actions has-text-right">
          <button class="button is-primary" @click="updateAppointments">Aplicar Filtro</button>
        </div>
      </div>
    </div>
    <div class="columns is-centered" v-if="people.length===1" style="margin-bottom: 40px; margin-top: 40px;">
      <div class="column is-6 is-centered has-text-centered">
        <b-message type="is-warning">
          Se encontró {{this.people.length}} resultado.
        </b-message>
      </div>
    </div>
    <div class="columns is-centered" v-if="people.length!==1" style="margin-bottom: 40px; margin-top: 40px;">
      <div class="column is-6 is-centered has-text-centered">
        <b-message type="is-warning">
          Se encontraron {{this.people.length}} resultados.
        </b-message>
      </div>
    </div>
    <div class="box">
      <b-table
          :data="people"
          :paginated= true
          per-page="20"
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="both"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="product.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field="index"
            label="N°">
          <template v-slot="props">
            {{ people.indexOf(props.row) + 1 }}
          </template>
        </b-table-column>
        <b-table-column
            field="integral"
            label="Rut"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                placeholder=" Buscar"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.integral}}
          </template>
        </b-table-column>
        <b-table-column
            field="name"
            label="Nombre"
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                placeholder=" Buscar"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.name}}
          </template>
        </b-table-column>
        <b-table-column
            field="last_name"
            label="Primer Apellido"
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                placeholder=" Buscar"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.last_name}}
          </template>
        </b-table-column>
        <b-table-column
            field="second_surname"
            label="Segundo Apellido"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                placeholder=" Buscar"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.second_surname}}
          </template>
        </b-table-column>
        <b-table-column
            field="establishment"
            label="Estamento"
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                placeholder=" Buscar"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.establishment!==undefined ? props.row.establishment : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="unit"
            label="Unidad de Desempeño"
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                placeholder=" Buscar"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.unit!==undefined ? props.row.unit : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="contracting_unit"
            label="Unidad de Contratacion"
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                placeholder=" Buscar"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.contracting_unit!==undefined ? props.row.contracting_unit : ""}}
          </template>
        </b-table-column>
        <b-table-column
            label="Acciones"
            v-slot="props">
          <b-icon
              pack="fas"
              icon="eye"
              size="is-medium"
              @click.native="returnShow(props.row.id)"/>
        </b-table-column>
        <template slot="empty" slot-scope="props">
          <div class="columns is-centered">
            <div class="column is-6 is-centered has-text-centered">
              <b-message type="is-danger">
                No se encontraron resultados para filtros aplicados.
              </b-message>
            </div>
          </div>
        </template>
      </b-table>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "FeecontractIndex",
  data(){
    return {
      selected: null,
      people: [],
      currentPage: 1,
      isLoading: false,
      no_filters: true,
      unit: null,
      units: [],
      unit_types: ["UNIDAD DE DESEMPEÑO", "UNIDAD DE CONTRATACIÓN"],
      type: "UNIDAD DE DESEMPEÑO",
      states: ["TODOS", "ACTIVOS", "INACTIVOS"],
      state: "ACTIVOS"
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    initialize(){
      this.isLoading = true
      return axios
          .get("/units/return_unit_names.json")
          .then(res => {
            this.units = res.data.units
            this.unit = res.data.unit
            return axios
                .get("/people/"+"TODAS"+"/"+this.type+"/feecontract_list.json", { params: { state: this.state }})
                .then(res => {
                  this.people = res.data
                  this.isLoading = false
                })
                .catch(error => {
                  console.log(error)
                  this.isLoading = false;
                })
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false
          })
    },
    updateAppointments(){
      this.isLoading = true
      return axios
          .get("/people/"+this.unit+"/"+this.type+"/feecontract_list.json", { params: { state: this.state }})
          .then(res => {
            this.people = res.data
            if(res.data["length"]===0 ){
              this.$buefy.dialog.alert({
                message: 'Su búsqueda no ha arrojado resultados.',
                confirmText: 'Ok',
                type: 'is-warning',
                hasIcon: true,
                icon: 'exclamation-triangle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
            }
            /*else{
              var mensaje = 'Se encontraron '+res.data["length"]+' resultados.'
              this.$buefy.dialog.alert({
                message: mensaje,
                confirmText: 'Ok',
                type: 'is-success',
                hasIcon: true,
                icon: 'check-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
            }*/
            this.isLoading = false
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
          })
    },
    returnShow(person_id){
      this.isLoading = true
      window.open('/people/'+person_id+'/life_record')
      this.isLoading = false
    },
    exportData() {
      this.isLoading = true
      let url = "/people/export_active_list.xlsx?e=FeecontractIndex&unit_type="+this.type+"&unit="+this.unit+"&state="+this.state
      window.open(url)
      this.isLoading = false
    },
  },
}
</script>