<template>
  <section>
        <div class="box">
          <div class="columns">
            <div class="column">
              <b-field grouped group-multiline>
                <div class="control">
                  <h1 class="title is-4">Seleccionar archivo de ingreso masivo</h1>
                  <h2 class="subtitle is-6 has-text-weight-bold">*El archivo debe tener la extensión .xlsx.</h2>
                </div>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div >
                <b-field class="file">
                  <b-upload v-model="file" expanded>
                    <a class="button is-primary is-outlined">
                      <b-icon icon="upload"></b-icon>
                      <span>{{ file.name || "Subir Archivo"}}</span>
                    </a>
                  </b-upload>
                </b-field>
              </div>
            </div>
            <div class="column">
              <div class="has-text-right">
                <b-button
                    label="Ver Ejemplo"
                    type="is-primary"
                    @click="isModalSpreadSheet = true" />
              </div>
            </div>
          </div>
        </div>


        <h1 class="title has-text-centered">Importación de Personas</h1>
        <div class="column">
          <div class="has-text-right">
            <button class="button is-primary" :disabled="file.name.length===0" @click="import_file_personas">Cargar Personas</button>
          </div>
        </div>
        <b-tabs position="is-centered" class="block" v-if="importacion">
          <b-tab-item label="Personas Ingresables" v-if="importacion_personas">
            <div class="box">
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(submitPeople)">
                  <div class="columns">
                    <div class="column is-centered">
                      <b-message type="is-info" has-icon v-if="importacion_personas">
                        <b>Total personas y estudios importados: </b>{{ personas.length }} de {{cantidad_total_personas}}.<br/>
                      </b-message>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field grouped group-multiline>
                        <div class="control">
                          <h1 class="title is-5">Seleccionar personas a ingresar</h1>
                        </div>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-centered">
                      <b-message type="is-info" has-icon v-if="showInfo">
                        <b>Total personas ingresadas exitosamente: </b>{{count_people}} de {{total_people}}.<br/>
                        <b>Total estudios ingresados exitosamente: </b>{{count_studies}} de  {{total_studies}}.<br/>
                      </b-message>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                        <b-message type="is-danger" has-icon>
                          <div v-for="(item,index) in errores_validacion">
                            {{index+1+"-"+item["ERRORES"] }}
                          </div>
                        </b-message>
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <div class="has-text-right">
                      <button class="button is-primary"type="submit" :disabled="checkedRows.length<1">Ingresar Personas</button>
                    </div>
                  </div>

                  <b-table
                      :data="personas"
                      :paginated= true
                      per-page="20"
                      striped
                      hoverable
                      :narrowed=true
                      :current-page="currentPage"
                      :pagination-simple=false
                      pagination-position="both"
                      default-sort-direction="asc"
                      sort-icon="arrow-up"
                      sort-icon-size="is-small"
                      default-sort="product.title"
                      aria-next-label="Siguiente"
                      aria-previous-label="Anterior"
                      aria-page-label="Página"
                      aria-current-label="Página actual"
                      :checked-rows.sync="checkedRows"
                      checkable
                      :checkbox-position="checkboxPosition"
                      @check-all="selectAll">
<!--                      :opened-detailed="defaultOpenedDetails"-->
<!--                      detailed-->
<!--                      detail-key="index"-->
<!--                      detail-transition="fade"-->
<!--                      :show-detail-icon="showDetailIcon">-->

                    <b-table-column
                        field="index"
                        label="N°">
                      <template v-slot="props">
                        {{ personas.indexOf(props.row) + 1 }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="run.rut"
                        label="Rut"
                        sortable
                        searchable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.run.rut}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="run.dv"
                        label="Dv"
                        sortable
                        searchable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.run.dv}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="person.name"
                        label="Nombre"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.person.name}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="person.last_name"
                        label="Apellido Paterno"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.person.last_name}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="person.second_surname"
                        label="Apellido Materno"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.person.second_surname}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="person.birthday_date"
                        label="Fecha Nacimiento"
                        width="110"
                        sortable
                        searchable :custom-search="searchStart">
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.person.birthday_date._isValid ? new Date(props.row.person.birthday_date).toLocaleDateString("es-CL") : ''}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="person.university_entrance_date"
                        label="Fecha Ingreso Usach"
                        width="110"
                        sortable
                        searchable :custom-search="searchStart">
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.person.university_entrance_date._isValid ? new Date(props.row.person.university_entrance_date).toLocaleDateString("es-CL") : ''}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="person.action"
                        label="Acción"
                        width="110"
                        sortable
                        searchable :custom-search="searchStart">
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ (props.row.person.action==="UPDATE") ? "Actualizar" : "Crear"}}
                      </template>
                    </b-table-column>


                    <template slot="empty" slot-scope="props">
                      <div class="columns is-centered">
                        <div class="column is-6 is-centered has-text-centered">
                          <b-message type="is-danger">
                            No se encontraron personas que puedan ser importadas.
                          </b-message>
                        </div>
                      </div>
                    </template>

<!--                    <template #detail="props">
                      <article class="media">
                        <div class="media-content">
                          <div class="content">

                            <div class="box" v-if="props.row.person_study!==undefined&&props.row.person_study!==null">
                              <div class="columns">
                                <div class="column">
                                  <strong>Estudio/Grado: </strong>
                                </div>
                              </div>
                              <div class="columns">
                                <div class="column">
                                  <p>{{props.row.person_study.estudio.name}}</p>
                                </div>
                                <div class="column">
                                  <p>{{props.row.person_study.estudio.study_type}}</p>
                                </div>
                                <div class="column">
                                  <p>{{props.row.person_study.semesters}}</p>
                                </div>
                                <div class="column">
                                  <p>{{props.row.person_study.egress_date}}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </article>
                    </template>-->

                  </b-table>
                </form>
              </ValidationObserver>
            </div>
          </b-tab-item>
          <b-tab-item label="Alertas y Errores de importación" v-if="importacion_personas">
            <div class="columns">
              <div class="column is-centered">
                <b-message type="is-info" has-icon>
                  <b>Alertas:</b> Inconsistencias en el archivo de importación que impiden ingresar datos de estudio, grado o datos no requeridos de la persona.<br/>
                  <b>Errores:</b> Problemas en el archivo de importación que impiden el ingreso completo de una persona, lo que puede ser causado por falta de datos requeridos, existencia de la persona que se quiere importar, etc.
                </b-message>
              </div>
            </div>
            <div v-if="alertas.length>0" class="columns is-centered">
              <div class="column is-11">
                <div class="box">
                  <h1 class="is-size-5">
                    <b-icon
                        pack="fas"
                        icon="exclamation-triangle">
                    </b-icon>
                    Alertas del archivo: {{ this.file_name_errors }}
                  </h1>
                  <div v-if="import_type != 'Convenios'" class="ml-5" v-for="error in alertas">
                    Fila n° {{error.FILA}}: {{ error.ERRORES }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="errores.length>0" class="columns is-centered">
              <div class="column is-11">
                <div class="box">
                  <h1 class="is-size-5">
                    <b-icon
                        pack="fas"
                        icon="exclamation-triangle">
                    </b-icon>
                    Errores del archivo: {{ this.file_name_errors }}
                  </h1>
                  <div v-if="import_type != 'Convenios'" class="ml-5" v-for="error in errores">
                    Fila n° {{error.FILA}}: {{ error.ERRORES }}
                  </div>
                  <div v-if="import_type == 'Convenios'" class="ml-5" v-for="error in errores">
                    Hoja:{{error.PAGINA}} -> Fila n° {{error.FILA}}: {{ error.ERRORES }}
                  </div>
                </div>
              </div>
            </div>
            <div class="columns has-text-right">
              <div class="column">
                <b-button @click="importErrors" v-if="errores.length!=0 || alertas.length!=0" icon-left="file-excel" class="is-excel-color">Exportar alertas y errores en excel</b-button>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item label="Errores ingreso - Personas" v-if="errores_custom.length>0">
            <b-table
                :data="errores_custom"
                :paginated= true
                per-page="20"
                striped
                hoverable
                :narrowed=true
                :current-page="currentPage_2"
                :pagination-simple=false
                pagination-position="both"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="product.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
              <b-table-column
                  field="index"
                  label="N°">
                <template v-slot="props">
                  {{ errores_custom.indexOf(props.row) + 1 }}
                </template>
              </b-table-column>
              <b-table-column
                  field="rut"
                  label="Rut"
                  sortable
                  searchable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{ props.row.rut}}
                </template>
              </b-table-column>
              <b-table-column
                  field="person_name"
                  label="Nombre"
                  searchable
                  sortable
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.person_name}}
                </template>
              </b-table-column>
              <b-table-column
                  field="motivo"
                  label="Razón de error"
              >
                <template v-slot="props">
                  {{props.row.motivo}}
                </template>
              </b-table-column>
              <template slot="empty" slot-scope="props">
                <div class="columns is-centered">
                  <div class="column is-6 is-centered has-text-centered">
                    <b-message type="is-danger">
                      No se encontraron personas.
                    </b-message>
                  </div>
                </div>
              </template>
            </b-table>
          </b-tab-item>
        </b-tabs>



    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="isAlertModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal-confirm-duplication-for-extension :person-id="person_unit.person_id"
                                                 :id="person_unit.id"
                                                 :person_unit="person_unit"
                                                 modal-header="Confirmar Nombramientos"
                                                 @close="props.close"
                                                 @clicked_save="onClickSave"
                                                 @clicked_duplicate="onClickDuplicate">
        </modal-confirm-duplication-for-extension>
      </template>
    </b-modal>

    <b-modal
        v-model="isModalSpreadSheet"
        has-modal-card
        trap-focus
        full-screen
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal-spreadsheet
                                                 modal-header="Cabeceras y Ejemplo"
                                                 @close="props.close">
        </modal-spreadsheet>
      </template>
    </b-modal>

  </section>
</template>
<script>
import axios from "axios"
import moment from "moment";
import {searchDate, formatDate} from "../../../packs/utilities";
import ModalConfirmDuplicationForExtension from "../../life_record/modal_confirm_duplication_for_extension"
import ModalSpreadsheet from "../../life_record/spreadsheet"

export default {
  name: "ImportEntry",
  components:{
    ModalConfirmDuplicationForExtension,
    ModalSpreadsheet
  },
  data(){
    return{
      import_type:"",
      import_types: [{name: "PERSONAS"}, {name: "NOMBRAMIENTOS"}],
      person_unit: {},
      cantidad_total_personas: 0,
      cantidad_personas: 0,
      currentPage: 1,
      currentPage_2: 1,
      file:{name:""},
      dropFiles: [],
      isLoading:false,
      errores:[],
      alertas: [],
      errores_nombramientos: [],
      alertas_nombramientos: [],
      file_name_appointments_errors: "",
      cabeceras_nombramientos: [],
      file_name_errors:"",
      cabeceras:[],
      hojas:[],
      errorFile:{name:""},
      errorFileNombramientos: {name: ""},
      units: [],
      year: null,
      semester: null,
      importacion: false,
      importacion_personas: false,
      importacion_nombramientos: false,
      personas: [],
      nombramientos: [],
      checkedRows: [],
      checkedRows2: [],
      checkboxPosition: "right",
      extension_start_date: null,
      extension_end_date: null,
      errores_validacion:{},
      errores_validacion_nombramientos: {},
      invalid_date: false,
      activate_check: false,
      date_validation: true,
      allow_duplication: false,
      isAlertModalActive: false,
      valid_dates: false,
      disable_import: false,
      disable_load: false,
      errores_custom: [],
      errores_custom_nombramientos: [],
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      activeStep: 0,
      isStepsClickable: true,
      disabledStepsAppointments: true,
      visibleSteps: false,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      isModalSpreadSheet: false,
      total_people: 0,
      total_studies: 0,
      count_studies: 0,
      count_people: 0,
      showInfo: false,
    }
  },
  created(){
    this.initialize()
  },
  props:[

  ],
  methods:{
    initialize(){
      this.isLoading = true
      this.person_unit.person_id = this.personId
      this.isLoading = false
    },
    submitPeople(){
      this.isLoading = true
      this.disable_load = true
      axios
          .post("/life_record/person_study_entry.json", {
            personas: this.checkedRows
          })
          .then(response =>{
            this.errores_validacion = response.data["errores_hash"];
            this.errores_custom = response.data["errores_array"]
            this.total_people = response.data["total_personas"]
            this.total_studies = response.data["total_estudios"]
            this.count_studies = response.data["contador_estudios"]
            this.count_people = response.data["contador_personas"]
            this.showInfo = true;
            if(this.errores_custom.length>0){
              this.$buefy.dialog.alert({
                message: 'Las personas y estudios fueron creadas con éxito, pero sucedieron algunos errores',
                confirmText: 'Ok'
              })
            }
            else{
              this.$buefy.dialog.alert({
                message: 'Las personas y estudios fueron creadas con éxito ',
                confirmText: 'Ok'
              })
            }
            this.isLoading=false;
            this.errors = [];
            this.$emit('close')
          }).catch(error => {
        this.errores_validacion = error.response.data["errores_hash"]
        this.isLoading = false;
      })
    },
    selectAll(rows) {
      if (rows.length > this.checkedRows.length) {
        this.$nextTick(() => { this.checkedRows = this.personas})
      } else {
        this.$nextTick(() => {
          this.checkedRows = []
        })
      }
    },
    selectAll2(rows) {
      if (rows.length > this.checkedRows2.length) {
        this.$nextTick(() => { this.checkedRows2 = this.nombramientos})
      } else {
        this.$nextTick(() => {
          this.checkedRows2 = []
        })
      }
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    import_file_personas(){
        this.total_people = 0
        this.total_studies = 0
        this.count_studies = 0
        this.count_people = 0
        this.cantidad_total_personas = 0
        this.cantidad_personas = 0
        this.isLoading = true;
        this.showInfo = false;
        this.personas = []
        this.checkedRows = []
        this.disable_load = false
        this.file_name_errors = "";
        this.errores_validacion = {}
        this.errores_custom = []
        this.errores = [];
        this.cabeceras = [];
        let formData = new FormData();
        formData.append('file', this.file);
        formData.append('year', this.year);
        formData.append('semester', this.semester)
        this.errorFile = this.file;
        axios.post("/life_record/import_entry.json",
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        ).then(response =>{
          if (response.data["errores"].length>0 || response.data["alertas"].length>0){
            this.importacion_personas = true;
            this.importacion = true;
            this.$buefy.dialog.alert({
              message: 'Algunos datos del archivo "'+this.file.name+'" no han podido ser importados, por favor, revisar errores',
              confirmText: 'Ok',
              type: 'is-warning',
              hasIcon: true,
              icon: 'exclamation-triangle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.file_name_errors = this.file.name;
            this.errores = response.data["errores"];
            this.alertas = response.data["alertas"]
            this.cabeceras = response.data["cabeceras"];
            this.personas = response.data["personas"];
            this.cantidad_total_personas = response.data["cantidad_total_personas"]
            this.cantidad_personas = response.data["cantidad_personas"]
            this.personas = this.personas.map(person => {
              var newPerson = person
              newPerson["person"]["birthday_date"] = moment(newPerson["person"]["birthday_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newPerson["person"]["university_entrance_date"] = moment(newPerson["person"]["university_entrance_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              if(newPerson["person_study"]!== undefined && newPerson["person_study"]!== null){
                newPerson["person_study"]["egress_date"] = moment(newPerson["person_study"]["egress_date"], ["YYYY-MM-DD","DD-MM-YYYY"]).format("DD-MM-YYYY");

              }
              return newPerson;
            });
            this.isLoading = false;
          }
          else{
            this.import_type = '';
            this.disable_import = true;
            this.personas = response.data["personas"];
            this.alertas = []
            this.errores = []
            this.cantidad_total_personas = response.data["cantidad_total_personas"]
            this.cantidad_personas = response.data["cantidad_personas"]
            this.personas = this.personas.map(person => {
              var newPerson = person
              newPerson["person"]["birthday_date"] = moment(newPerson["person"]["birthday_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newPerson["person"]["university_entrance_date"] = moment(newPerson["person"]["university_entrance_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              if(newPerson["person_study"]!== undefined && newPerson["person_study"]!== null){
                newPerson["person_study"]["egress_date"] = moment(newPerson["person_study"]["egress_date"], ["YYYY-MM-DD","DD-MM-YYYY"]).format("DD-MM-YYYY");

              }
              return newPerson;
            });
            this.importacion_personas = true;
            this.importacion = true;
            this.$buefy.dialog.alert({
              message: 'Su archivo "'+this.file.name+'" ha sido importado con éxito',
              confirmText: 'Ok'
            })
            this.file={name:""};
            this.errores = [];
            this.file_name_errors="";
            this.isLoading = false;
          }
        }).catch(error => {
          console.log(error)
              this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Hubo errores en la importación de su archivo. Puede ser un archivo no válido o presenta errores de formato',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              this.isLoading = false;
            });
    },
    searchEndDate(row,input){
      return searchDate(row.end_date,input)
    },
    searchResolution(row,input){
      return searchDate(row.new_res_date,input)
    },
    searchStart(row,input){
      return searchDate(row.start_date,input)
    },
    validDate(start_date, end_date){
      if(start_date && end_date){
        this.valid_dates = true
      }
      else{
        this.valid_dates = false
      }
    },
    importErrors(){
      let vm = this;
      vm.isLoading=true;
      axios
          .post("/life_record/export_errors_entry.xlsx",{alertas: vm.alertas, cabeceras:vm.cabeceras,errores:vm.errores,hojas:vm.hojas},
              {
                responseType: "blob"
              })
          .then(response =>{
            vm.isLoading=false;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Errores y alertas ingreso de personas masivo '+vm.errorFile.name.replace(".xlsx","")+' '+new Date().toLocaleDateString()+'.xlsx');
            document.body.appendChild(link);
            link.click();
          }).catch(error => {
        vm.isLoading=false;
        vm.$buefy.notification.open({
          message: 'Hubo un error en la solicitud',
          type: 'is-danger'
        })
      })
    },
  },
  computed:{

  }
}
</script>

<style>
.custom-style {
  background: #714DD2 !important;
  color: white !important;
}

</style>