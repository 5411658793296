<template>
<div class="container">
  <div class="box">
    <section>
      <b-tabs position="is-centered" class="block">
        <b-tab-item>
          <template #header>
            <span> Publicaciones no encontradas en SIGA <b-tag style="margin-left:0.4em" size="is-medium" type="is-success" rounded> {{ new_publications.length }} </b-tag> </span>
          </template>
          <div v-if="!new_publications || new_publications.length==0">
            <b-message type="is-danger" has-icon>
              No se encontraron publicaciones nuevas desde ORCID.
            </b-message>
          </div>
          <div class="columns" v-for="publication in new_publications" :key="publication.doi ? publication.doi : publication.title">
            <div class="column">
              <b-notification type="is-success is-light" aria-close-label="Cerrar notificación">
                <span>Título: {{publication.title}}</span><br>
                <span>Fecha de Publicación: {{publication.publication_date ? getCorrectDate(publication.publication_date) : ""}} - DOI: {{publication.doi}}</span><br>
                <span>Autores: {{publication.participants_references}}</span><br>
                <span>Revista: {{publication.magazine}} - Volumen: {{publication.volumen}}</span><br>
                <a target="_blank" :href="publication.url"><span>URL: {{publication.url}}</span></a>
                <div class="columns" v-if="publication.publication_date && publication.publication_date.includes('-01-01')">
                  <div class="column">
                    <b-message style="margin-top:0.5em" type="is-warning">
                      <b-icon
                          pack="fas"
                          icon="exclamation-triangle"
                          >
                      </b-icon>
                      ¡Atención! De esta publicación solo se pudo obtener su año, por lo que fecha de publicación debe ser modificada a la correcta en el formulario.
                    </b-message>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                  </div>
                  <div class="column is-narrow">
                    <button class="button is-primary" icon-left="plus" @click.prevent="addPublication(publication)">Completar datos y Añadir</button>
                  </div>
                </div>
              </b-notification>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item>
          <template #header>
            <span> Publicaciones encontradas en SIGA <b-tag style="margin-left:0.4em" size="is-medium" type="is-info" rounded> {{ siga_publications.length }} </b-tag> </span>
          </template>
          <div v-if="!siga_publications || siga_publications.length==0">
            <b-message type="is-danger" has-icon>
              No se encontraron publicaciones en SIGA coincidentes con las obtenidas desde ORCID.
            </b-message>
          </div>
          <div class="columns" v-for="publication in siga_publications" :key="publication.id">
            <div class="column">
              <b-notification type="is-info is-light" aria-close-label="Cerrar notificación">
                <span>Título: {{publication.title ? publication.title.toUpperCase() : ""}}</span><br>
                <span>Fecha de Publicación: {{publication.publication_date}} - DOI: {{publication.doi}}</span><br>
                <span>Autores: {{publication.participants_references}}</span><br>
                <span>Revista: {{publication.magazine ? getMagazineName(publication) : ""}} - Volumen: {{publication.volumen}}</span><br>
                <div class="columns">
                  <div class="column"></div>
                  <div class="column is-narrow">
                    <b-button class="is-info" icon-left="eye" @click="goToPublication(publication)">Abrir Elemento</b-button>
                  </div>
                </div>
              </b-notification>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </section>

  </div>
  <b-modal
      :active="modalActive"
      :can-cancel="true"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal>
    <template #default="props">
      <select-publication-type modal-cancel="true"
                            :cargo-principal="''"
                            :user-id="user.id"
                            modal-header="Seleccionar tipo de Publicación"
                            :orcid-publication="orcidPublication"
                            :person-id="person.id"
                            query-type="create" @close="closeModal" @publicationAdded="publicationAdded"></select-publication-type>
    </template>
  </b-modal>

  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

</div>
</template>

<script>
import moment from "moment";
import SelectPublicationType from "../books/SelectPublicationType";
export default {
  name: "OrcidPublicationsTable",
  components: {SelectPublicationType},

  props:['new_publications','siga_publications','person','user'],
  data(){
    return {
      isLoading:false,
      modalActive:false,
      orcidPublication:{},
    }
  },
  methods:{
    addPublication(publication){
      this.orcidPublication={};
      this.orcidPublication= {...publication};
      //console.log(publication,this.orcidPublication);
      if (publication.publication_date){

        this.orcidPublication.publication_date = new Date(publication.publication_date.replace(/-/g,"/"));
      }
      if (publication.magazine_id){
        this.orcidPublication.magazine_id = publication.magazine_id;
        this.orcidPublication.magazine_name = publication.magazine;
      }
      this.modalActive = true;
    },
    goToPublication(elemento){
      if (elemento.origin_participant_type_id!=3){
        window.open('/publications/'+elemento.id);
      }
      else{
        window.open('/books/'+elemento.id);
      }

    },
    closeModal(){
      this.orcidPublication={};
      this.modalActive = false;
    },
    publicationAdded(publication){
      this.$emit("publicationAdded",publication);
      this.modalActive = false;
    },
    getCorrectDate(date){
      if (date){
        return moment(date).format('DD-MM-YYYY');
      }
      else{
        return "";
      }
    },
    getMagazineName(publication){
      let retorno = ""
      if (publication.magazine && publication.magazine.name){
        retorno = publication.magazine.name
      }
      if (publication.magazine && publication.magazine.length>0 && !publication.magazine.name){
        retorno = publication.magazine.toUpperCase();
      }
      return retorno;

    }
  }
}
</script>

<style scoped>

</style>