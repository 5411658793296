<template>
    <section>
      <div v-if="can_create_academic_activity === 'true'" class="columns" >
        <div class="column is-offset-8">
          <b-field label = " " class="has-text-right">
            <b-button class="is-primary" tag="a" href="/projects/new" target="_blank" icon-left="plus">
              Añadir Proyecto
            </b-button>
          </b-field>
        </div>
      </div>
        <projects-table v-bind:proyectos="this.proyectos" v-bind:unit-id="personId" :hide-update="true"
            :cargo-principal="cargoPrincipal" @updateProjects="updateProjects" />
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </section>
</template>
<script>
import axios from "axios";
import moment from "moment";
import ProjectsTable from "../projects/projects_table.vue";
export default {
    props: [
        'personId', 'cargoPrincipal', 'minYear', 'maxYear', 'changeFilter', 'can_create_academic_activity'
    ],
    data() {
        return {
            proyectos: [],
            isLoading: false,
            year: new Date().getFullYear(),
        }
    },
    components: {
        ProjectsTable
    },
    created() {
        this.getProjects();
    },
    methods: {
        getProjects() {
            this.isLoading = true;
            axios
                .get("/people/" + this.personId + "/projects.json", {params: {
                    minYear: this.minYear,
                    maxYear: this.maxYear,
                    id: this.personId
                }})
                .then(res => {
                    this.proyectos = res.data
                    this.proyectos = this.proyectos.map(proyecto => {
                        var proyecto = proyecto
                        proyecto["start_date"] = moment(proyecto.start_date, ["YYYY-MM-DD", "DD-MM-YYYY"])
                        proyecto["end_date"] = moment(proyecto.end_date, ["YYYY-MM-DD", "DD-MM-YYYY"])
                        return proyecto
                    })
                    this.isLoading = false
                })
                .catch(e => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: 'Hubo un error al traer los Proyectos de la persona consultada, favor de revisar la consola.',
                        type: 'is-danger'
                    })
                    console.log(e)
                    this.isLoading = false
                })
        },
        updateProjects(year) {
            this.year = year
            this.getProjects();
        }
    },
    computed: {

    },
    watch: {
        changeFilter: function () {
            this.getProjects()
        }
    }
}
</script>