<template>
    <section>
        <div v-if="projectIndicator.show">

            <b-collapse :open="isOpenSummary" @open="isOpenSummary = true" class="card" animation="slide" aria-id="contentIdForA11y3">
                <template #trigger="props">
                    <div class="card-header" role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.open">
                        <p class="card-header-title is-centered">
                            Resumen de Proyectos según su Tipología
                        </p>
                        <a class="card-header-icon">
                            <b-icon :icon="props.open ? 'angle-down' : 'angle-up'">
                            </b-icon>
                        </a>
                    </div>
                </template>

                <div class="card-content">
                    <div class="content">
                        <SummaryIndicatorProject :indicator="projectIndicator.summary" />
                    </div>
                </div>
            </b-collapse>
            
            <br>

            <b-collapse v-show="projectIndicator.show_by_years" :open="isOpenYear" @open="isOpenYear = true" class="card" animation="slide" aria-id="contentIdForA11y3">
                <template #trigger="props">
                    <div class="card-header" role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.open">
                        <p class="card-header-title is-centered">
                            Cantidad de Proyectos por Año de Inicio y Tipo
                        </p>
                        <a class="card-header-icon">
                            <b-icon :icon="props.open ? 'angle-down' : 'angle-up'">
                            </b-icon>
                        </a>
                    </div>
                </template>

                <div class="card-content">
                    <div class="content">
                        <IndicatorByYear :years="projectIndicator.indicator_by_years" :title="'project_year'" />
                    </div>
                </div>
            </b-collapse>
            <br>
            <b-collapse v-show="projectIndicator.show_by_type" :open="isOpenType" @open="isOpen = true" class="card" animation="slide" aria-id="contentIdForA11y3">
                <template #trigger="props">
                    <div class="card-header" role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.open">
                        <p class="card-header-title is-centered">
                            Proporción de Proyectos por Tipos
                        </p>
                        <a class="card-header-icon">
                            <b-icon :icon="props.open ? 'angle-down' : 'angle-up'">
                            </b-icon>
                        </a>
                    </div>
                </template>

                <div class="card-content">
                    <div class="content">
                        <IndicatorByType :types="projectIndicator.indicator_by_type" :title="'project_type'" />
                    </div>
                </div>
            </b-collapse>
            <br>
            <b-collapse v-show="projectIndicator.show_by_type_remittances" :open="isOpenTypeRemittances" @open="isOpenTypeRemittances = true" 
                class="card" animation="slide" aria-id="contentIdForA11y3">
                <template #trigger="props">
                    <div class="card-header" role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.open">
                        <p class="card-header-title is-centered">
                            Proporción de Montos de Remesas por Proyectos ANID, CORFO y OTROS
                        </p>
                        <a class="card-header-icon">
                            <b-icon :icon="props.open ? 'angle-down' : 'angle-up'">
                            </b-icon>
                        </a>
                    </div>
                </template>

                <div class="card-content">
                    <div class="content">
                        <IndicatorByType :types="projectIndicator.indicator_by_remittances" :title="'remesas'" />
                    </div>
                </div>
            </b-collapse>
            <br>
            <b-collapse v-show="projectIndicator.show_by_type_partner_public_private" :open="isOpenTypePublicPrivate" @open="isOpenTypePublicPrivate = true" 
                class="card" animation="slide" aria-id="contentIdForA11y3">
                <template #trigger="props">
                    <div class="card-header" role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.open">
                        <p class="card-header-title is-centered">
                            Proporción de Proyectos según Entidades que Aportan Financiamiento
                        </p>
                        <a class="card-header-icon">
                            <b-icon :icon="props.open ? 'angle-down' : 'angle-up'">
                            </b-icon>
                        </a>
                    </div>
                </template>

                <div class="card-content">
                    <div class="content">
                        <IndicatorByType :types="projectIndicator.indicator_by_partner_public_private" :title="'public_private'" />
                    </div>
                </div>
            </b-collapse>
        </div>
        <div v-else>
            <div class="columns is-centered">
                <div class="column is-6 is-centered has-text-centered">
                    <b-message has-icon type="is-warning">
                        <h1> No se encontraron resultados. Porfavor ingrese nuevos Proyectos para mostrar sus indicadores.</h1>
                    </b-message>
                </div>
            </div>
        </div>

        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

    </section>
</template>

<script>
import axios from 'axios'
import IndicatorByType from './indicator_by_type.vue'
import IndicatorByYear from './indicator_by_year.vue'
import SummaryIndicatorProject from './summary_indicator_project.vue'
export default {

    components: {
        IndicatorByType, IndicatorByYear, SummaryIndicatorProject
    },

    props: [
        'minYear', 'maxYear', 'unitId', 'changeFilter'
    ],

    data() {
        return {
            isOpenSummary: true,
            isOpenYear: true,
            isOpenType: true,
            isOpenTypeRemittances: true,
            isOpenTypePublicPrivate: true,
            projectIndicator: {},
            isLoading: false,
        }
    },

    async created() {
        await this.getProjectIndicator()
    },

    methods: {

        async getProjectIndicator() {

            this.isLoading = true

            await axios
                    .get('/dashboards/get-unit-project-indicator.json', {
                        params: {
                            unitId: this.unitId,
                            minYear: this.minYear,
                            maxYear: this.maxYear,
                        }
                    })
                    .then(response => {
                        this.projectIndicator = response.data.projects
                        this.$emit('updatedData')
                        this.isLoading = false
                    })
                    .catch(error => {
                        var error_message
                        if (error.response != null) {
                            error_message = error.response.data.error
                        } else {
                            error_message = error
                        }
                        this.$buefy.dialog.alert({
                            title: 'Error',
                            message: 'Hubo un error al obtener los datos de Indicadores de Proyectos de la Unidad.<br><br> Error:<br>' + error_message,
                            type: 'is-danger',
                            hasIcon: true,
                            icon: 'times-circle',
                            iconPack: 'fa',
                            ariaRole: 'alertdialog',
                            ariaModal: true
                        })
                        this.$emit('updatedData')
                        this.isLoading = false
                    })

        }

    },

    watch: {
        changeFilter() {
            this.getProjectIndicator()
            deep: true
        },
    },

}
</script>