<template>
  <div>
    <section>
      <div class="box">
        <div class="container">
          <b-tabs position="is-centered" @input="changeTab">
            <b-tab-item v-for="item in items"
                        :value="item.content"
                        v-bind:key="item.content"
                        :label="item.tab"
                        :icon="item.icon">
            </b-tab-item>

          </b-tabs>
          <!-- COMPONENTE AQUI -->

            <component v-bind:is="currentTabComponent"
                       :user-id="this.userId"
                       :cargo-principal="this.cargoPrincipal"
                       :participants="this.publication.participants_attributes"
                       :publication="this.publication"
                       :publication-id="this.bookId"></component>

          <!-- FIN COMPONENTE -->

        </div>
      </div>
    </section>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>
<script>
import GeneralBook from './ShowGeneralTab'
import GeneralInbook from './ShowGeneralInbookTab'
import ContenidoArtiBook from './ShowContenidoBookArtiTab.vue'
import ContenidoCharBook from './ShowContenidoBookCharTab.vue'
import Participants from './ShowParticipantsTab'
import AdditionalInformationTab from './AdditionalInformationTab'
import axios from "axios"
export default {
  name: "ShowBook",
  components:{ GeneralBook, Participants, GeneralInbook, ContenidoArtiBook, ContenidoCharBook, AdditionalInformationTab},
  props:[
    'bookId', 'cargoPrincipal', 'userId'
  ],
  data(){
    return{
      publication: {
        publication_type_attributes:[
          {id:-1,name:""}
        ],
        participants_attributes:[],
        validation_attributes:{}
      },
      isLoading:false,
      participantes: [],
      currentPage:1,
      cardLayout:false,
      permissionGeneral: false,
      permissionGeneralInbook: false,
      currentTab: '',
      items: []
    }
  },
  created(){
    this.isLoading=true

    return axios
        .get("/inbooks/"+this.bookId+".json")
        .then(res => {
          this.publication = res.data;
          if(this.publication.publication_type==="Book")
          {
            this.items.push({tab: "General",content:'GeneralBook',icon:"file-alt"})
            this.items.push({tab: 'Participantes',content:'Participants',icon:"users"})
            if (this.publication.contenidos.length>0){
              this.publication.chapters = this.publication.contenidos.filter(obj => {
                return obj.publication_type == "Booklet"
              })
              this.publication.articles = this.publication.contenidos.filter(obj => {
                return obj.publication_type == "Inbook"
              })
            }
            else
            {
              this.publication.chapters = []
              this.publication.articles = []
            }
            this.items.push({tab: "Artículos",content:'ContenidoArtiBook',icon:"bookmark"})
            this.items.push({tab: "Capítulos",content:'ContenidoCharBook',icon:"file"})

            this.currentTab = "GeneralBook"
            
          }
          else{
            this.items.push({tab: "General",content:'GeneralInbook',icon:"file-alt"})
            this.items.push({tab: 'Participantes',content:'Participants',icon:"users"})
            this.currentTab = "GeneralInbook"
          }

          this.items.push({tab: 'Información Adicional',content:'AdditionalInformationTab',icon:"info"})
          
          var index = 0
          for(const participante of this.publication.participants_attributes){
            this.participantes.push({
              id:null,
              full_name:null,
              alias:null,
              unit:null,
              unit_id:null,
              corporarte_mail:null,
              participant_type_id:null,
              participant_type:null,
              is_doc_student:null
            })
            this.participantes[index].id = participante.person_id
            this.participantes[index].is_doc_student = participante.is_doc_student
            this.participantes[index].full_name = participante.person_name
            this.participantes[index].corporate_mail = participante.person_mail
            this.participantes[index].alias = participante.person_alias
            if(this.participantes[index].corporate_mail != null){
              this.participantes[index].corporate_mail = this.participantes[index].corporate_mail.toUpperCase()
            }
            if(this.participantes[index].alias != null){
              this.participantes[index].alias = this.participantes[index].alias.toUpperCase()
            }
            this.participantes[index].unit = participante.unit_name
            this.participantes[index].unit_id = participante.unit_id
            this.participantes[index].participant_type_id = participante.participant_type_id
            this.participantes[index].participant_type = participante.participant_type
            index = index + 1
          }
          this.publication.publication_date = this.formatDate(this.publication.publication_date)
          this.isLoading=false;
        })
        .catch(e => {
          console.log(e);
          this.isLoading=false;
        });

  },
  methods:{
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    changeTab: function(value){
      this.currentTab = value
    }

  },
  computed:{
    currentTabComponent: function () {
      return this.currentTab
    }
  }
}
</script>
