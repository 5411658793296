<template>
  <section>
    <div class="container">
      <!--DATA TABLE-->
      <b-table
          :data="distanced_person_functions"
          :paginated= true
          per-page="10"
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="product.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field="function.name"
            label="Función"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.function!==undefined ? props.row.function.name : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="resolution"
            label="N° de documento"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.resolution}}
          </template>
        </b-table-column>
        <b-table-column
            field="resolution_type.name"
            label="Tipo documento"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.resolution_type ? props.row.resolution_type.name : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="start_date"
            label="Fecha de Libera Función"
            width="110"
            sortable
            searchable :custom-search="searchStartDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            label="Acciones"
            width="120"
            v-slot="props">

          <b-tooltip
              v-if="props.row.can_show"
              label="Ver información completa de la función anulada."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="eye"
                type="is-primary"
                @click="execShowModal(props.row.id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_restore"
              label="Restaurar función anulada."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="trash-restore"
                type="is-success is-light is-outlined"
                @click="execRestoreModal(props.row.id)"/>
          </b-tooltip>

        </b-table-column>
      </b-table>
      <!--FIN DATA TABLE-->
      <!-- MODALES -->
      <b-modal
          v-model="isShowModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <distanced-function :id="selected"
                              :modal-header="'Libera Función - '  +person_name"
                              :person_name="person_name"
                              @close="props.close">
          </distanced-function>
        </template>
      </b-modal>
      <b-loading :is-full-page="true"
                 v-model="isLoading"
                 :can-cancel="false"/>
    </div>
  </section>
</template>

<script>
import Function from "./function.vue";
import axios from "axios";
import DistancedFunction from "./distanced_function";
import {searchDate, formatDate} from "../../../packs/utilities";
import moment from "moment";
export default {
  name: "null_distanced_person_functions",
  props:[
    'personId', 'can_assign_function', 'person_name'
  ],
  components:{
    Function,
    DistancedFunction,
  },
  data(){
    return {
      selected: null,
      index: null,
      canCancel: ['escape'],
      currentPage: 1,
      isAddModalActive: false,
      isEditModalActive: false,
      isShowModalActive: false,
      isLoading:false,
      distanced_person_functions: [],
      selected_person_function_id: null,
      selected_old_start_date: null,
      isRestoreActive: false
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    execRestoreModal(id){
      let vm = this;
      vm.$buefy.dialog.confirm({
        title: 'Restaurar aleja función',
        message: '¿Está seguro que desea <b>restaurar</b> el aleja función? Esta acción revertirá la anulación.',
        confirmText: 'Restaurar Aleja Función',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          vm.isRestoreActive = true
          vm.isLoading = true
          axios
              .put("/person_functions/" + id + "/restore_distanced_person_function.json")
              .then(res => {
                vm.$buefy.dialog.alert({
                  message: 'Aleja función restaurado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                vm.isLoading = false;
                vm.isRestoreActive = false;
              }).catch(error => {
            console.log(error)
            vm.$buefy.dialog.alert({
              message: '<b>Aleja función no pudo ser restaurado</b><br>'+ error.response.data["base"][0],
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            vm.isLoading = false;
            vm.isRestoreActive = false;
          })
        }
      })

    },
    initialize(){
      this.isLoading = true;
      return axios
          .get("/people/"+this.personId+"/null_distanced_person_functions.json")
          .then(res => {
            this.distanced_person_functions = res.data
            this.distanced_person_functions = this.distanced_person_functions.map(personfunction => {
              var newFunction = personfunction
              newFunction["start_date"] = moment(newFunction["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newFunction["end_date"] = moment(newFunction["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newFunction["resolution_date"] = moment(newFunction["resolution_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newFunction;
            });
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })

    },
    execShowModal(id){
      this.selected = id;
      this.isShowModalActive = true;
    },
    execEditModal(row){
      this.selected = row.id;
      this.selected_person_function_id = row.person_function_id
      this.selected_old_start_date = row.old_start_date
      this.isEditModalActive = true;
    },
    // yyyy/mm/dd -> dd/mm/yyyy
    formatDate,
    searchStartDate(row, input){
      return searchDate(row.start_date, input)
    },
    searchEndDate(row, input){
      return searchDate(row.end_date, input)
    }

  },
  watch:{
    isRestoreActive: function () {
      if (!this.isRestoreActive){
        this.initialize()
      }
    },
    isShowModalActive: function () {
      if (!this.isShowModalActive) {
        this.initialize()
      }
    },

  }
}
</script>

<style scoped>

</style>