<template>
  <div class="partnerentitiesvimetabs">
    <section>

      <div class="container">
        <b-tabs position="is-centered" @input="changeTab" type="is-toggle">
          <b-tab-item v-for="item in items"
                      :value="item.content"
                      v-bind:key="item.content"
                      :label="item.tab"
                      :icon="item.icon">
          </b-tab-item>

        </b-tabs>

        <component v-bind:is="currentTabComponent"
                   :agreements='this.agreements'
                   :activities='this.activities'
                   :entity_id="this.entity_id"
                   :can_create_activity="this.can_create_activity"
                   :can_create_agreement="this.can_create_agreement"></component>
      </div>

    </section>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>
<script>
import PartnerEntitiesVimeAgreements from './partner_entities_vime_agreements.vue'
import PartnerEntitiesVimeActivities from './partner_entities_vime_activities.vue'

export default {
  name: 'PartnerEntitiesVimeShowView',
  props:['agreements','activities','can_create_activity','can_create_agreement','entity_id'],
  components:{
    PartnerEntitiesVimeAgreements,
    PartnerEntitiesVimeActivities,
  },
  data(){
    return {

      isFullPage: true,
      isLoading: false,
      ShowViewToShow: 1,
      can_edit: false,
      hasVimePermissions:false,
      currentTab: 'PartnerEntitiesVimeAgreements',
      can_see_related_projects:false,
      can_see_related_people:false,
      items: [
        {tab: 'Convenios',content:'PartnerEntitiesVimeAgreements', icon:'file-alt'},
        {tab: 'Actividades', content: "PartnerEntitiesVimeActivities", icon:'calendar'},

      ],
      sub_entities: []
    }
  },
  methods:{
    changeTab: function(value){
      this.currentTab = value
    },
  },

  computed: {
    currentTabComponent: function () {
      return this.currentTab
    }
  },

}
</script>