<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(verifyDocument)">
        <!-- Box de Formulario Appointment -->
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" ref="modalFeeAppointmentForm">
            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion">
                      {{ item[0] }}
                    </div>
                  </b-message>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción*">
                  <ValidationProvider rules="required|min:5|max:200" v-slot="{ errors }">
                    <b-input v-model="person_unit.description" type="textarea"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de documento">
                  <multiselect v-model="person_unit.resolution_type_id"
                               :options="resolution_types.map(type => type.id)"
                               :custom-label="opt => resolution_types.find(x => x.id === opt).name"
                               placeholder="Seleccione tipo de resolución"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                               :allow-empty="true">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Número de documento">
                  <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                    <b-input v-model="person_unit.resolution" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de documento">
                  <b-datepicker
                      v-model="person_unit.resolution_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Unidad de contratación*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.contracting_unit_id"
                                 :options="contract_units.map(type => type.id)"
                                 :custom-label="opt => contract_units.find(x => x.id === opt).name"
                                 placeholder="Seleccione una unidad"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">

                <b-field label="Unidad de desempeño*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.unit_id"
                                 :options="units.map(type => type.id)"
                                 :custom-label="opt => units.find(x => x.id === opt).name"
                                 placeholder="Seleccione una unidad"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              </div>
            <div class="columns">
              <div class="column">
                <b-field label="Calidad">
                  <multiselect v-model="person_unit.quality"
                               :options="qualities.map(type => type.name)"
                               placeholder="Seleccione calidad de contrato"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                               :allow-empty="true">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Labor*">
                  <ValidationProvider rules="required|min:5|max:255" v-slot="{ errors }">
                    <b-input
                        type="text"
                        v-model="person_unit.labour">

                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción de Labor que Realiza*">
                  <ValidationProvider rules="required|min:5|max:255" v-slot="{ errors }">
                    <b-input
                        type="textarea"
                        v-model="person_unit.labour_description">

                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <h3 class="is-size-6 has-text-weight-bold">Lugar donde realiza el trabajo*
                  <b-tooltip
                      label="Si selecciona 'dependencias de la unidad', este campo se repetirá según la Unidad de Desempeño"
                      position="is-right"
                      size="is-medium"
                      multilined>

                    <b-icon
                        pack="fas"
                        icon="info-circle"
                        size="is-small"
                        type="is-info">
                    </b-icon>
                  </b-tooltip>
                </h3>
                <b-field label=" ">
                  <ValidationProvider rules="required|min:5|max:255" v-slot="{ errors }">
                    <b-input v-model="person_unit.labour_place" type="text" :disabled="unit_dependences"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                    <b-checkbox v-model="unit_dependences" @input="changeLabourPlace" :disabled="!person_unit.unit_id">
                      Dependencias de la Unidad
                    </b-checkbox>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Desde*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-datepicker
                        v-model="person_unit.start_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                    >
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Hasta">
                  <b-datepicker
                      v-model="person_unit.end_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <h3 class="is-size-6 has-text-weight-bold">Horario de la labor
                  <b-tooltip
                      label="Seleccione 'modificar horario' para ir a la pestaña de ingreso de horario según día de trabajo"
                      position="is-right"
                      size="is-medium"
                      multilined>

                    <b-icon
                        pack="fas"
                        icon="info-circle"
                        size="is-small"
                        type="is-info">
                    </b-icon>
                  </b-tooltip>
                </h3>
                <b-field label=" ">
                  <ValidationProvider rules="min:5|max:4000" v-slot="{ errors }" style="width: 100%" >
                    <b-input v-model="stringSchedule" type="text" readonly expanded></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <p class="control">
                    <button class="button is-primary" @click.prevent="editSchedule">Modificar Horario </button>
                  </p>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Monto*">
                  <ValidationProvider rules="integer|positive|max:15" v-slot="{ errors }">
                    <b-input v-model="person_unit.amount" type="text" ></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Total Convenio">
                  <ValidationProvider rules="integer|positive|max:15" v-slot="{ errors }">
                    <b-input v-model="person_unit.total_amount" type="text" ></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Forma de Pago">
                  <multiselect v-model="person_unit.payment"
                               :options="payments_types.map(type => type.name)"
                               placeholder="Seleccione forma de pago"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                               :allow-empty="true">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Ítem Imputación Presupuestaria">
                  <ValidationProvider rules="integer|positive|max:15" v-slot="{ errors }">
                    <b-input v-model="person_unit.item" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="CC Imputación Presupuestaria*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.budget_allocation_cc"
                                 :options="contract_units.map(type => type.cost_center)"
                                 :custom-label="opt => contract_units.find(x => x.cost_center === opt).name"
                                 placeholder="Seleccione una unidad"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>

<!--            <div class="columns">
              <div class="column">
                <b-field label="Url Documentación">
                  <ValidationProvider v-slot="{ errors }">
                    <b-input v-model="person_unit.documentation_link" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>-->
            <div class="columns" style="margin-bottom: 40px;">
              <div class="column has-text-left">
                <div class="row">
                  <p class="control">
                    <b-button class="is-primary" icon-right="link" @click="functionLink">{{link===false ? "Vincular" : "Desvincular"}}</b-button>
                  </p>
                </div>
                <div class="row" v-if="link">

                  <b-field label="Vincular Alejamiento">
                    <multiselect v-model="person_unit.linked_distance_id"
                                 placeholder="Seleccione Alejamiento"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="Presione para deseleccionar"
                                 :allow-empty="true"
                                 :options="options"
                                 :multiple="false"
                                 group-values="distances"
                                 group-label="type"
                                 label="custom_name"
                                 :group-select="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                    </multiselect>
                  </b-field>
                </div>
              </div>
            </div>

            <div v-if="queryType == 'update'" class="columns">
              <div class="column is-4">
                <b-field label="Nombre del documento">
                  <b-input v-model="document_name_update" type="text" :disabled="true"></b-input>
                </b-field>
              </div>
              <div class="column is-4">
                <b-field label="¿Desea cambiar o agregar un archivo?">
                  <b-radio-button v-model="changeFile"
                                  :native-value="true"
                                  type="is-success is-light is-outlined">
                    <b-icon icon="check-circle"></b-icon>
                    <span>Si</span>
                  </b-radio-button>
                  <b-radio-button v-model="changeFile"
                                  :native-value="false"
                                  type="is-danger is-light is-outlined">
                    <b-icon icon="times-circle"></b-icon>
                    <span>No</span>
                  </b-radio-button>
                </b-field>
              </div>
            </div>

            <div v-if="queryType == 'create' || changeFile" class="columns has-text-centered">
              <div class="column">
                <b-field extended label="Archivo PDF (Tamaño max. 25 MB)">
                  <b-upload v-model="file"
                            drag-drop
                            type="is-primary"
                            accept=".pdf"
                            @input="generateName">
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon
                              icon="file-pdf"
                              size="is-large">
                          </b-icon>
                        </p>
                        <p>Arrastra tu PDF o haz click para subir.</p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>
              </div>
            </div>
            <div v-if="queryType == 'create' || changeFile" class="columns tags is-centered">
              <span
                  v-if="file !== null"
                  v-model="file"
                  class="tag is-primary mt-1">
                {{ file.name }}
                <button
                    class="delete is-small"
                    type="button"
                    @click="onDeleteFile"
                ></button>
              </span>
            </div>

            <div v-if="file !== null" class="columns">
              <div class="column">
                <b-field label="Nombre del documento a subir">
                  <b-input disabled v-model="document_name"></b-input>
                </b-field>
              </div>
            </div>

          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit">Guardar </button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="isDistanceModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <distance-form :person-id="person_unit.person_id"
                       :id="person_unit.id"
                       :person_unit_id="person_unit.id"
                       query-type="create"
                       modal-header="Crear Alejamiento"
                       @close="props.close">
        </distance-form>
      </template>
    </b-modal>

    <b-modal
        v-model="isWeeklyModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal-weekly-schedule-form
                        name="planificacion_horario"
                        :person-id="person_unit.person_id"
                       :horarios="schedule"
                        :intervalo="interval"
                        :stringHorario="stringSchedule"
                        :scheduleDays="scheduleDays"
                       query-type="create"
                       modal-header="Determinar Horario Semanal"
                        @clicked="onClickChild"
                       @close="props.close">
        </modal-weekly-schedule-form>
      </template>
    </b-modal>

  </section>
</template>
<script>
import axios from "axios";
import DistanceForm from "../life_record/modal_distance_form"
import ModalWeeklyScheduleForm from "../life_record/modal_weekly_schedule_form"
export default {
  name: "modal_feecontract_appointment_form",
  props:[
    'queryType', 'personId', 'id', 'modalHeader', 'person_name'
  ],
  components:{
    DistanceForm,
    ModalWeeklyScheduleForm
  },
  data(){
    return {
      person_unit:{

      },
      years:[],
      appointment_types:[],
      unit_dependences: false,
      work_plants:[],
      resolution_types:[],
      units: [],
      contract_units:[],
      grades: [],
      levels: [],
      working_days:[],
      qualities: [{name: "PROFESIONAL"},{name: "TECNICO"},{name: "EXPERTO"}],
      payments_types: [{name: "MENSUAL"}, {name: "GLOBAL"}],
      errores_validacion:{},
      documentation_link:'',
      establishments:[],
      isLoading: false,
      isDistanceModalActive: false,
      link: false,
      distances: [],
      performance_areas: [],
      performance_area: null,
      schedule: {},
      interval: 60,
      scheduleDays: [],
      stringSchedule: "",
      schedule: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: []
      },
      isWeeklyModalActive: false,
      options: [{type: 'Dejar de Servir', distances: []}, {type: 'Término de Contrato', distances: []}, {type: 'Renuncia', distances: []}],
      file: null,
      document_name: null,
      changeFile: false,
      document_name_update: null,
      document_id: null
    }
  },
  created() {
    this.isLoading=true;
    if (this.queryType === "create"){
      axios
          .get("/admin/person_units/new.json")
          .then(res => {
            this.person_unit.person_id = this.personId
            this.work_plants = res.data["work_plants"]
            this.appointment_types = res.data["appointment_types"]
            this.units = res.data["units"]
            this.contract_units = res.data["contract_units"]
            this.grades = res.data["grades"]
            this.levels = res.data["levels"]
            this.resolution_types = res.data["resolution_types"]
            this.working_days = res.data["working_days"]
            this.documentation_link = res.data["documentation_link"]
            this.establishments = res.data["establishments"]
            this.performance_areas = res.data["performance_areas"]
            return axios
                .get("/people/"+this.personId+"/distances.json")
                .then(res => {
                  this.distances = res.data
                  var i = 0
                  for(i = 0; i<this.distances.length;i++){
                    if(this.distances[i].appointment_type.name==="DEJA DE SERVIR"){
                      this.options[0].distances.push(this.distances[i])
                    }
                    else if(this.distances[i].appointment_type.name==="RENUNCIA"){
                      this.options[2].distances.push(this.distances[i])
                    }
                    else{
                      this.options[1].distances.push(this.distances[i])
                    }
                  }
                  this.isLoading=false;


                }).catch(error => {
                  console.log(error)
                  this.isLoading=false;
                })
            this.isLoading=false;
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }
    else if (this.queryType === "update"){
      this.isLoading=true;
      axios
          .get("/admin/person_units/"+this.id+"/edit.json")
          .then(res => {
            this.person_unit = res.data

            if(res.data["start_date"]!==null){
              this.person_unit.start_date = new Date(this.formatDate(res.data["start_date"]))
            }
            if(res.data["end_date"]!==null){
              this.person_unit.end_date = new Date(this.formatDate(res.data["end_date"]))
            }
            if(res.data["decree_date"]!==null){
              this.person_unit.decree_date = new Date(this.formatDate(res.data["decree_date"]))
            }
            if(res.data["resolution_date"]!==null){
              this.person_unit.resolution_date = new Date(this.formatDate(res.data["resolution_date"]))
            }
            if(this.person_unit.performance_area){
              this.performance_area = [this.person_unit.performance_area]
            }
            if(this.person_unit.linked_distance_id){
              this.link = true
            }
            this.schedule = JSON.parse(this.person_unit.text_schedule)
            if(this.person_unit.appointment_days.length!==0){
              this.interval = this.person_unit.appointment_days[0].interval
            }
            this.units = res.data["units"]
            this.contract_units = res.data["contract_units"]
            this.resolution_types = res.data["resolution_types"]
            this.work_plants = res.data["work_plants"]
            this.appointment_types = res.data["appointment_types"]
            this.working_days = res.data["working_days"]
            this.grades = res.data["grades"]
            this.levels = res.data["levels"]
            this.documentation_link = res.data["documentation_link"]
            this.establishments = res.data["establishments"]
            this.performance_areas = res.data["performance_areas"]
            this.stringSchedule = res.data["schedule"]
            this.document_id =  res.data["document_id"]
            this.document_name_update = res.data["document_name"]
            this.document_name = res.data["document_name"]
            return axios
                .get("/people/"+this.person_unit.person_id+"/distances.json")
                .then(res => {
                  this.distances = res.data
                  var i = 0
                  for(i = 0; i<this.distances.length;i++){
                    if(this.distances[i].appointment_type.name==="DEJA DE SERVIR"){
                      this.options[0].distances.push(this.distances[i])
                    }
                    else if(this.distances[i].appointment_type.name==="RENUNCIA"){
                      this.options[2].distances.push(this.distances[i])
                    }
                    else{
                      this.options[1].distances.push(this.distances[i])
                    }
                  }
                  this.isLoading=false;


                }).catch(error => {
                  console.log(error)
                  this.isLoading=false;
                })
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }
    //this.isLoading=false;
  },
  methods: {

    editSchedule(){
      this.isWeeklyModalActive = true
    },
    onClickChild(schedule, days, stringSchedule, interval){
      this.stringSchedule = stringSchedule
      this.schedule = schedule
      this.scheduleDays = days
      this.interval = interval
    },
    changeLabourPlace(){
      if(this.unit_dependences===true){
        if(this.person_unit.unit_id){
          this.person_unit.labour_place = this.units.find(x => x.id === this.person_unit.unit_id).name
        }
      }
      else{
        this.person_unit.labour_place = null
      }
    },
    functionLink(){
      if(this.link===true){
        this.link = false
        this.person_unit.linked_distance_id = null
      }
      else{
        this.link = true
      }
    },
    verifyDocument(){
      this.isLoading= true;
      let id = null
      if(this.queryType==="update"){
        id = this.person_unit.id
      }
      axios
          .get("/life_record/validate_resolution_number.json", {
            params: {res_number: this.person_unit.resolution, query_type: this.queryType,
              entity_type: "person_unit", id: id}
          })
          .then(response =>{
            this.saveAppointment()
          }).catch(error => {
        if(error.response.status === 409){ //se encuentra
          this.$buefy.dialog.confirm({
            hasIcon: true,
            type: "is-warning",
            title: "Advertencia",
            message: error.response.data["message"],
            confirmText: "Continuar y guardar",
            cancelText: "Cancelar",
            onConfirm: () => this.saveAppointment(),
            onCancel: () => this.isLoading = false
          })
        }
        else if(error.response.status === 400){//bad request
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalFeeAppointmentForm.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al agregar el nombramiento, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
        }
      })
    },
    saveAppointment(){
      this.isLoading=true;
      this.person_unit.schedule = this.stringSchedule
      this.person_unit.days = this.scheduleDays
      this.person_unit.skip_if_fee_contract = true
      this.person_unit.text_schedule = JSON.stringify(this.schedule)
      let personUnitEstablishment = this.establishments.find(obj => obj.name == "HONORARIOS");
      if (personUnitEstablishment)
        this.person_unit.establishment_id = personUnitEstablishment.id;
      this.person_unit.pu_relation_type_id = 1
      if (this.queryType==="create"){

        if (this.file != null && this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Este archivo no tiene formato PDF",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        if (this.file != null && this.file.size > 1024*1024*25) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "El archivo no puede superar el maximo de 25 MB",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        // Crea un objeto FormData
        let formData = new FormData();

        // Agrega datos al objeto FormData
        formData.append("person_unit", JSON.stringify(this.person_unit));
        formData.append("file", this.file); // Aquí agregamos el archivo
        formData.append("document_name", this.document_name);

        axios
            .post("/admin/person_units/create_document.json", formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
            .then(response =>{
              this.errores_validacion = {};
              this.$buefy.dialog.alert({
                message: 'El nombramiento fue creado con éxito ',
                confirmText: 'Ok'
              })
              this.person_unit = {}
              this.isLoading=false;
              this.errors = [];
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalFeeAppointmentForm.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al agregar el nombramiento, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
        })
      }
      else if (this.queryType==="update"){
        if (this.file != null && this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Este archivo no tiene formato PDF",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        if (this.file != null && this.file.size > 1024*1024*25) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "El archivo no puede superar el maximo de 25 MB",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        let clean_person_unit = this.person_unit
        clean_person_unit = this.deleteData(clean_person_unit)


        // Crea un objeto FormData
        let formData = new FormData();

        // Agrega datos al objeto FormData
        formData.append("person_unit", JSON.stringify(clean_person_unit));
        formData.append("file", this.file); // Aquí agregamos el archivo
        formData.append("document_name", this.document_name);

        axios
            .put("/admin/person_units/"+this.id+"/update_document.json", formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
            .then(response => {
              this.$buefy.dialog.alert({
                message: 'El nombramiento fue editado con éxito ',
                confirmText: 'Ok'
              })
              this.errores_validacion = {};
              this.isLoading = false;
              this.person_unit = {}
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          let elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalFeeAppointmentForm.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al editar el nombramiento, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
        })
      }
    },
    deleteData(person_unit){
      delete person_unit.units
      delete person_unit.internal_positions
      delete person_unit.hierarchies
      delete person_unit.appointment_types
      delete person_unit.work_plants
      delete person_unit.work_plant
      delete person_unit.categories
      delete person_unit.appointment_types
      delete person_unit.performance_areas
      delete person_unit.establishments
      delete person_unit.establishment
      delete person_unit.contract_units
      delete person_unit.working_days
      delete person_unit.grades
      delete person_unit.grade
      delete person_unit.rank
      delete person_unit.levels
      delete person_unit.ranks
      delete person_unit.functions
      delete person_unit.categories
      delete person_unit.resolution_types
      delete person_unit.all_hr_subjects
      delete person_unit.can_renew
      delete person_unit.can_update_hr_subjects
      delete person_unit.hr_subjects
      delete person_unit.has_hr_subjects
      delete person_unit.work_plant
      delete person_unit.days
      delete person_unit.appointment_days
      return person_unit
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
    onDeleteFile() {
      this.file = null;
    },
    generateName(){
      var tipo = "HONORARIO"
      var centroCosto = ""
      var number = ""
      var date = ""
      var start_date = ""
      var end_date = ""
      var full_name = "-"+this.person_name

      if (this.person_unit.resolution_type_id!= null){
        var nombre_tipo = this.resolution_types.find(x => x.id === this.person_unit.resolution_type_id).name
        if(nombre_tipo.includes("RESOLUCION")){
          tipo = "RES"
        }
        else if(nombre_tipo.includes("DECRETO")){
          tipo = "DECR"
        }
        //tipo = this.resolution_types.find(x => x.id === this.person_unit.resolution_type_id).name
        //tipo = this.documentTypesNames.find(type => type.id === this.document.document_type_id).initials
      }

      if (this.person_unit.contracting_unit_id != null){
        centroCosto = "-CC_" + this.contract_units.find(x => x.id == this.person_unit.contracting_unit_id).cost_center
      }

      if (this.person_unit.resolution != null && this.person_unit.resolution != ''){
        number = "-N_" + this.person_unit.resolution
      }

      if (this.person_unit.resolution_date != null){
        date = "-"+("0" + this.person_unit.resolution_date.getDate()).slice(-2)
            + "_" + ("0" + (this.person_unit.resolution_date.getMonth()+1)).slice(-2)
            + "_" + this.person_unit.resolution_date.getFullYear()
      }

      if (this.person_unit.start_date != null){
        start_date = "-"+("0" + this.person_unit.start_date.getDate()).slice(-2)
            + "_" + ("0" + (this.person_unit.start_date.getMonth()+1)).slice(-2)
            + "_" + this.person_unit.start_date.getFullYear()
      }

      if (this.person_unit.end_date != null){
        end_date = "-"+("0" + this.person_unit.end_date.getDate()).slice(-2)
            + "_" + ("0" + (this.person_unit.end_date.getMonth()+1)).slice(-2)
            + "_" + this.person_unit.end_date.getFullYear()
      }

      if ((this.person_unit.resolution_date == null || this.person_unit.resolution_date == '')  &&
          (this.person_unit.resolution == null || this.person_unit.resolution == '')){
        this.document_name = tipo +  centroCosto + full_name + start_date + end_date
      }
      else{
        this.document_name = tipo + number + centroCosto + date
      }
    },
  }

}
</script>

<style scoped>

</style>