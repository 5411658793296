<template>
  <div>
    <br>
    <div class="columns is-centered">
      <div class="column is-11">
        <div class="box">
          <h1 class="title is-4">Seleccionar tipo de actividad académica a importar</h1>
          <h2 class="subtitle is-6 has-text-weight-bold">*El archivo debe tener la extensión .xlsx. <br>*Asegúrese de haber importado Personas antes de las actividades académicas asociadas a estas</h2>
          <multiselect v-model="import_type"
                       :options="import_types"
                       placeholder="Seleccione el tipo de archivo a importar"
                       selectLabel="Presione para seleccionar"
                       selectedLabel="Seleccionado"
                       deselectLabel="No se puede deseleccionar"
                       :allow-empty="false">
            <template v-slot:noOptions>
              No existen datos
            </template>
            <span slot="noResult">
              No se encontraron elementos.
            </span>
          </multiselect>
          <br>
          <div v-if="import_type.length>0">
            <b-field class="file">
              <b-upload v-model="file" expanded>
                <a class="button is-primary is-outlined">
                  <b-icon icon="upload"></b-icon>
                  <span>{{ file.name || "Subir Archivo"}}</span>
                </a>
              </b-upload>
            </b-field>


          </div>

          <div class="has-text-right">
            <b-button v-if="file.name.length!=0" type="is-primary" @click="import_file">Importar</b-button>
          </div>

        </div>
      </div>
    </div>

    <div v-if="errores.length>0" class="columns is-centered">
      <div class="column is-11">
        <div class="box">
          <h1 class="is-size-5">
            <b-icon
              pack="fas"
              icon="exclamation-triangle">
            </b-icon>
            Errores del archivo: {{ this.file_name_errors }}
          </h1>
          <div v-if="import_type != 'Convenios'" class="ml-5" v-for="error in errores">
            Fila n° {{error.FILA}}: {{ error.ERRORES }}
          </div>
          <div v-if="import_type == 'Convenios'" class="ml-5" v-for="error in errores">
           Hoja:{{error.PAGINA}} -> Fila n° {{error.FILA}}: {{ error.ERRORES }}
          </div>
          <div class="columns has-text-right">
            <div class="column">
              <b-button @click="importErrors" v-if="errores.length!=0" icon-left="file-excel" class="is-excel-color">Exportar errores en excel</b-button>
            </div>
          </div>

        </div>
      </div>
    </div>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  </div>
</template>
<script>
import axios from "axios"
export default {
  data(){
    return{
      import_types:["Personas", "Proyectos","Publicaciones","Propiedad Intelectual",
        "Emprendimientos", "Disclosures", "ORCID", "Convenios", "Citas"],
      import_type:"",
      file:{name:""},
      dropFiles: [],
      isLoading:false,
      errores:[],
      file_name_errors:"",
      cabeceras:[],
      hojas:[],
      errorFile:{name:""}

    }
  },
  created(){

  },
  props:[

  ],
  methods:{
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    import_file(){
      this.isLoading = true;
      var url=""
      if (this.import_type==="Propiedad Intelectual")
        url = "/patents/import.json"
      else if (this.import_type==="Publicaciones")
        url = "/publications/import.json"
      else if (this.import_type==="Emprendimientos")
        url = "/projects/import_entrepreneurship.json"
      else if (this.import_type==="Disclosures")
        url = "/disclosures/import.json"
      else if (this.import_type==="Proyectos")
        url = "/projects/import.json"
      else if (this.import_type==="Personas")
        url = "/people/import.json"
      else if (this.import_type==="ORCID")
        url = "/people/import_orcid.json"
      else if (this.import_type==="Convenios")
        url = "/agreements/import.json"
      else if (this.import_type==="Citas")
        url = "/cites/import.json"
      let formData = new FormData();
      formData.append('file', this.file);
      this.errorFile = this.file;
      axios.post( url,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(response =>{
        if (response.data["errores"].length>0){
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: 'Algunos datos del archivo "'+this.file.name+'" no han podido ser importados, por favor, revisar errores',
            confirmText: 'Ok',
            type: 'is-warning',
            hasIcon: true,
            icon: 'exclamation-triangle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.file_name_errors = this.file.name;
          this.errores = response.data["errores"];
          this.cabeceras = response.data["cabeceras"];
          if (this.import_type == "Convenios"){
            this.hojas = response.data["sheets"]
          }
          else{
            this.hojas = []
          }
        }
        else{
          this.import_type = '';
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: 'Su archivo "'+this.file.name+'" ha sido importado con éxito',
            confirmText: 'Ok'
          })
          this.file={name:""};
          this.errores = [];
          this.file_name_errors="";
        }

      })
      .catch(error => {
        //console.log('FAILURE!!');
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo errores en la importación de su archivo. Puede ser un archivo no válido o presenta errores de formato',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = false;
      });
    },
    importErrors(){
      let vm = this;
      vm.isLoading=true;
      axios
          .post("/load-data/export-errors.xlsx",{cabeceras:vm.cabeceras,errores:vm.errores,hojas:vm.hojas},
              {
                responseType: "blob"
              })
          .then(response =>{
            vm.isLoading=false;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Errores importacion masiva de datos '+vm.errorFile.name.replace(".xlsx","")+' '+new Date().toLocaleDateString()+'.xlsx');
            document.body.appendChild(link);
            link.click();
          }).catch(error => {
            vm.isLoading=false;
            vm.$buefy.notification.open({
              message: 'Hubo un error en tu solicitud',
              type: 'is-danger'
            })
          })


    }

  },
  computed:{

  }
}
</script>

