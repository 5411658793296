<template>
  <section>
    <div class="container">
      <h1 class="is-size-2">Funciones Anuladas</h1>
      <b-table
          :data="functions"
          :paginated=true
          per-page=10
          style="word-break:break-all;"
          narrowed
          striped
          hoverable
          :card-layout="cardLayout"
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="desc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="function.name"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual"
          :opened-detailed="defaultOpenedDetails"
          detailed
          detail-key="name"
          @details-open="(row) => $buefy.toast.open(`Expanded ${row.name}`)"
          :show-detail-icon="showDetailIcon"
      >
        <b-table-column
            field= 'name'
            label= 'Nombre'
            width="400"
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.name }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'alt_name'
            label= 'Nombre Alternativo'
            width="400"
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.alt_name }}
          </template>
        </b-table-column>

        <b-table-column
            field= 'uniq'
            label= '¿Es único?'
            searchable
            width="150"
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.text_is_uniq}}
          </template>
        </b-table-column>

        <b-table-column
            field= 'is_authority'
            label= '¿Es autoridad?'
            searchable
            width="150"
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.text_is_authority}}
          </template>
        </b-table-column>
        <b-table-column
            label="Acciones"
            v-slot="props">
          <b-icon
              pack="fas"
              icon="eye"
              size="is-medium"
              type="is-success"
              v-if="props.row.can_show"
              @click.native="openShowModal(props.row.id)"/>
          <b-icon
              v-if="props.row.can_restore"
              pack="fas"
              icon="trash-restore"
              type="is-success"
              size="is-medium"
              @click.native="verifyRestoreModal(props.row.id)"/>
        </b-table-column>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <p>
                  <strong>Función padre: </strong>
                  <span>{{props.row.parent_function}}</span>
                </p>
              </div>
            </div>
          </article>
        </template>

        <template slot="empty" slot-scope="props">
          <div class="columns is-centered">
            <div class="column is-6 is-centered has-text-centered">
              <b-message type="is-danger">
                No se encontraron resultados para los filtros seleccionados.
              </b-message>
            </div>
          </div>
        </template>
      </b-table>

      <b-modal
          v-model="isShowActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <function :id="selected"
                    :modal-header="'Función'"
                    @close="props.close">
          </function>
        </template>
      </b-modal>

      <b-modal
          v-model="isConfirmRestore"
          has-modal-card
          trap-focus
          :width="1080"
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <modal-confirm-restore :id="selected"
                                 :modal-header="'Confirmar Restauración'"
                                 :num_person_functions="num_person_functions"
                                 :num_person_units="num_person_units"
                                 @close="props.close">
          </modal-confirm-restore>
        </template>
      </b-modal>

      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

    </div>
  </section>
</template>
<script>
import axios from "axios";
import Function from "./function";
import FunctionForm from "./modal_function_form"
import ModalConfirmRestore from "./modal_confirm_restore"
import EventBus from "../eventBus";
export default {
  name: "nullified_functions",
  props:[
    'cargoPrincipal', 'can_create'
  ],
  components: {
    Function,
    FunctionForm,
    ModalConfirmRestore
  },
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      functions: [],
      isShowActive: false,
      selected: null,
      canCancel: [],
      isConfirmRestore: false,
      num_person_functions: 0,
      num_person_units: 0
    }
  },
  created(){
    this.initialize()
  },
  methods: {
    verifyRestoreModal(id){
      this.isLoading = true
      this.selected = id
      axios
          .get("/functions/" + id + "/verify-restore-function.json")
          .then(res => {
            this.isLoading = false;
            this.num_person_functions = res.data["num_person_functions"]
            this.num_person_units = res.data["num_person_units"]
            this.isConfirmRestore = true
          }).catch(error => {
        this.$buefy.dialog.alert({
          message: error.response.data["message"],
          duration: 5000,
          type: 'is-danger',
          canCancel: ["escape", "outside"]
        })
        this.isLoading = false;
      })
    },
    initialize() {
      this.isLoading = true
      axios
          .get("/functions/nullified-functions.json")
          .then(res=>{
            this.functions = res.data
            this.isLoading = false
          }).catch(e=>{
            console.log(e)
        this.isLoading=false
      })
    },
    openShowModal(id) {
      this.isLoading = true
      this.selected = id
      this.isShowActive = true
      this.isLoading = false
    },
  },
  watch: {
    isConfirmRestore: function () {
      if (!this.isConfirmRestore) {
        this.initialize()
      }
    },
  },
  computed:{

  }
}
</script>