<template>
    <section>

        <div v-if="can_create_work_experience === 'true'" class="columns">
            <div class="column has-text-right">
                <b-button type="is-primary" icon-left="plus" label="Agregar Exp. Docente Externa"
                    @click="isModalActive = true; selectedWorkExperience = {}; updateWork = false" />
            </div>
        </div>

        <b-table :data="work_experiences" paginated per-page=10 :pagination-simple=false striped
            pagination-position="bottom" aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
            aria-current-label="Página actual">

            <b-table-column label="Carrera" field="career_name" sortable searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Carrera..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.career_name }}
                </template>
            </b-table-column>

            <!-- <b-table-column label="Programa" sortable searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Programa..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row }}
                </template>
            </b-table-column>

            <b-table-column label="Curso" sortable searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Curso..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row }}
                </template>
            </b-table-column> -->

            <b-table-column label="Asignatura" field="subject_name" sortable searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Asignatura..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.subject_name }}
                </template>
            </b-table-column>

            <b-table-column label="Tipo Programa" field="teaching_type_name" sortable searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Tipo..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.teaching_type_name }}
                </template>
            </b-table-column>

            <b-table-column label="TEL" field="tel" sortable searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar TEL..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.tel }}
                </template>
            </b-table-column>

            <b-table-column label="Último Año Dictado" field="year" sortable searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Año..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.year }}
                </template>
            </b-table-column>

            <b-table-column label="Institución" field="partner_entity_name" sortable searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Institución..."
                        icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.partner_entity_name }}
                </template>
            </b-table-column>

            <b-table-column v-show="cargoPrincipal.name == 'ANALISTA I+D FING' || cargoPrincipal.name == 'SUPERADMIN'"
                label="Acciones" v-slot="props">
                <div class="columns">
                    <div v-if="props.row.can_show" class="column">
                      <b-tooltip
                          label="Muestra en detalle de la información de la experiencia docente externa"
                          :active="true">
                          <b-button pack="fas"
                                    icon-right="eye"
                                    type="is-info"
                                    @click="isModalShowActive = true;
                                    selectedWorkExperience = props.row;"/>

                      </b-tooltip>
                    </div>
                    <div v-if="props.row.can_edit" class="column">
                      <b-tooltip
                          label="Editar la información de la experiencia docente externa"
                          :active="true">
                          <b-button
                              pack="fas"
                              icon-right="edit"
                              type="is-warning"
                              @click="selectedWorkExperience = props.row;
                              updateWork = true;
                              isModalActive = true"/>
                      </b-tooltip>
                    </div>
                    <div v-if="props.row.can_null" class="column">
                        <b-tooltip
                            label="Anular registro de la experiencia docente externa"
                            :active="true">
                          <b-button pack="fas"
                                    icon-right="ban"
                                    type="is-danger"
                                    @click="nullExperience(props.row)"/>
                        </b-tooltip>

                    </div>
                </div>
            </b-table-column>

            <template slot="empty" slot-scope="props">
              <div class="columns is-centered">
                <div class="column is-6 is-centered has-text-centered">
                  <b-message type="is-danger">
                    No se encontraron resultados.
                  </b-message>
                </div>
              </div>
            </template>

        </b-table>

        <b-modal v-model="isModalActive" has-modal-card trap-focus :destroy-on-hide="true" width="90%" aria-role="dialog"
            aria-modal :can-cancel="false">
            <template #default="props">
                <ModalNewWorkExperience :person-id="personId" :update="updateWork" :old_work_experience="selectedWorkExperience" :teaching="true"
                    @close="props.close" @refresh="getWorkExperiences()"/>
            </template>
        </b-modal>

        <b-modal v-model="isModalShowActive" has-modal-card trap-focus :destroy-on-hide="true" width="90%" aria-role="dialog"
            aria-modal :can-cancel="false">
            <template #default="props">
                <ModalShowWorkExperience :person-id="personId" :old_work_experience="selectedWorkExperience" :teaching="true"
                    @close="props.close" @refresh="getWorkExperiences()"/>
            </template>
        </b-modal>

        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

    </section>
</template>

<script>
import axios from 'axios'
import ModalNewWorkExperience from "./modal_new_work_experience"
import ModalShowWorkExperience from './modal_show_work_experience.vue'
export default {
    components: {
        ModalNewWorkExperience, ModalShowWorkExperience,
    },
    props: [
        'personId', 'photoUrl', 'cargoPrincipal', 'canCreateDocument', 'can_create_work_experience'
    ],
    data() {
        return {
            isLoading: false,
            isModalActive: false,
            isModalShowActive: false,
            work_experiences: [],
            selectedWorkExperience: {},
            updateWork: false,
        }
    },
    async created() {
        await this.getWorkExperiences()
    },
    methods: {

        async getWorkExperiences() {
            this.isLoading = true
            await axios
                .get('/my-teaching-experiences/' + this.personId + '.json')
                .then(response => {
                    this.work_experiences = response.data
                    this.isLoading = false
                })
                .catch(error => {
                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al traer sus datos de Experiencia Docente Externa, favor de revisar los errores en consola.',
                        type: 'is-danger',
                        hasIcon: true,
                        iconPack: 'fa',
                        icon: 'times-circle',
                        confirmText: 'Ok',
                    })
                    console.log(error)
                    this.isLoading = false
                })
        },

        nullExperience(experience) {
            
            this.$buefy.dialog.confirm({
                title: 'Anular Experiencia Docente Externa',
                message: '¿Está seguro que desea <b>anular</b> la Experiencia Docente Externa? Esta acción lo dejará inválido.<br>',
                confirmText: 'Anular Experiencia Docente Externa',
                cancelText: 'Cancelar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: async () => {
                    this.isLoading = true
                    await axios
                        .delete("/work_experiences/" + experience.id + "/nullify-work-experience.json")
                        .then(response => {
                            this.$buefy.dialog.alert({
                                title: 'Logrado',
                                type: 'is-success',
                                hasIcon: true,
                                icon: 'check-circle',
                                iconPack: 'fas',
                                message: 'La Experiencia Docente Externa ha sido anulada exitosamente.',
                                confirmText: 'Aceptar'
                            })
                            this.getWorkExperiences()
                        })
                        .catch(error => {
                            this.$buefy.dialog.alert({
                                title: 'Error',
                                message: 'Hubo un error al anular la Experiencia Docente Externa.<br><b>Errores:</b><br>' + error.response.data,
                                type: 'is-danger',
                                hasIcon: true,
                                icon: 'times-circle',
                                iconPack: 'fa',
                                ariaRole: 'alertdialog',
                                ariaModal: true
                            })
                            this.isLoading = false
                        })
                }
            })

        }
    }
}
</script>