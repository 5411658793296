import { render, staticRenderFns } from "./rectify_appointment_administrative.vue?vue&type=template&id=7eccd06b"
import script from "./rectify_appointment_administrative.vue?vue&type=script&lang=js"
export * from "./rectify_appointment_administrative.vue?vue&type=script&lang=js"
import style0 from "./rectify_appointment_administrative.vue?vue&type=style&index=0&id=7eccd06b&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports