<template>
  <section>
    <div class="container">
      <div class="box">
        <b-tabs @input="changeTab" :destroy-on-hide="true" v-model="currentTab" position="is-centered" type="is-toggle">
          <b-tab-item v-for="item in items"
                      :value="item.content"
                      v-bind:key="item.content"
                      :label="item.tab"
                      :visible="(item.content ==='NullStudies' || item.content ==='NullContinuingStudies') ? can_list_null === 'true' : true">
          </b-tab-item>
        </b-tabs>
        <!-- COMPONENTE AQUI -->
        <component v-bind:is="currentTabComponent" :person-id="personId" :can_create_study="can_create_study"
                   :person_name="person_name"></component>

        <!-- FIN COMPONENTE -->
      </div>
    </div>
  </section>
</template>
<script>
import NullStudies from "../null_studies"
import NullContinuingStudies from "./null_continuing_studies"
export default {
  name: "null_study_tabs",
  props:[
    'personId', 'can_create_study', 'can_list_null', 'person_name', 'can_create_continuing_study'
  ],
  data(){
    return {
      currentTab: 'NullStudies',
      items: [
        {tab: 'Estudios Anulados',content:'NullStudies'},
        {tab: 'Educación Continua Anulada', content: "NullContinuingStudies"},
      ]
    }
  },
  components:{
    NullStudies,
    NullContinuingStudies,
  },
  methods:{
    changeTab: function(value){
      this.currentTab = value
    },
    selectTab(tab){
      this.currentTab = tab
    },
  },
  computed: {
    currentTabComponent: function () {
      return this.currentTab
    },

  },
}
</script>