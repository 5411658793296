<template>
  <div class="partnerentitiesvimenewview">
    <section>
      <div class="container">
        <h1 class="is-size-2" v-if="edit_case">Editar Entidad</h1>
        <h1 class="is-size-2" v-else>Nueva Entidad</h1>
        <PartnerEntitiesVimeNewForm v-if="edit_case" :entity_id='entity_id' :edit_case="edit_case"/>
        <PartnerEntitiesVimeNewForm v-else/>
      </div>
    </section>
  </div>
</template>
<script>
import PartnerEntitiesVimeNewForm from './partner_entitites_vime_new_form.vue'
export default {
    name: 'PartnerEntitiesVimeNewView',
    props:{
        edit_case:{
            type: Boolean,
            default: false
        },
        entity_id:{
            type: Number
        }
    },
    components:{
        PartnerEntitiesVimeNewForm
    }
}
</script>