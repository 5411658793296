<template>
  <div class="box mt-5">
  <h1 class="title is-2 has-text-weight-medium has-text-centered">Quiénes Somos</h1>
    <div class="rows" v-if="director.id!==null && director.id!==undefined">
      <div class="row">
        <div class="columns">
          <div class="column is-centered">
            <div class="box">
              <div class="columns is-left">
                <div class="column is-2 is-left" style="width:200px">
                  <div class="image-container" style="display: flex;justify-content: left;" href="/people/" @click="redirect(director.id)">
                    <b-image
                        :src=getPhotoUrl(director)
                        alt="Foto de perfil"
                        style="max-width: 150px;height: 150px;"
                        :rounded="true"
                    ></b-image>
                  </div>
                </div>
                <div class="column is-left">
                  <p class="title is-6" style="text-align:left; color: #A2A9AD;">{{director===undefined ? "" : director.cargo}}</p>
                  <h4 class="subtitle is-5" style="text-align:left; color:#EF7D00;">{{director===undefined ? "" : director.nombre}}</h4>
                  <h5 class="title is-6" style="text-align:left; color: #A2A9AD;">Correo Electrónico</h5>
                  <h5 class="subtitle is-6" style="text-align:left; color: #A2A9AD;">{{director===undefined ? "" : director.correo}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rows">
      <div class="row">
        <div class="columns">
          <div class="column" v-if="subdirectores.docencia.id!==null && subdirectores.docencia.id!==undefined">
            <div class="box">
              <div class="columns is-left">
                <div class="column is-2 is-left" style="width:200px">
                  <div class="image-container" style="display: flex;justify-content: left;" href="/people/" @click="redirect(subdirectores.docencia.id)">
                    <b-image
                        :src=getPhotoUrl(subdirectores.docencia)
                        alt="Foto de perfil"
                        style="max-width: 150px;height: 150px;"
                        :rounded="true"
                    ></b-image>
                  </div>
                </div>
                <div class="column is-left">
                  <p class="title is-6" style="text-align:left; color: #A2A9AD;">{{subdirectores.docencia===undefined ? "" : subdirectores.docencia.cargo}}</p>
                  <h4 class="subtitle is-5" style="text-align:left; color:#EF7D00;">{{subdirectores.docencia===undefined ? "" : subdirectores.docencia.nombre}}</h4>
                  <h5 class="title is-6" style="text-align:left; color: #A2A9AD;">Correo Electrónico</h5>
                  <h5 class="subtitle is-6" style="text-align:left; color: #A2A9AD;">{{subdirectores.docencia===undefined ? "" : subdirectores.docencia.correo}}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="column" v-if="subdirectores.inv_des.id!==null && subdirectores.inv_des.id!==undefined">
            <div class="box">
              <div class="columns is-left">
                <div class="column is-2 is-left" style="width:200px">
                  <div class="image-container" style="display: flex;justify-content: left;" href="/people/" @click="redirect(subdirectores.inv_des.id)">
                    <b-image
                        :src=getPhotoUrl(subdirectores.inv_des)
                        alt="Foto de perfil"
                        style="max-width: 150px;height: 150px;"
                        :rounded="true"
                    ></b-image>
                  </div>
                </div>
                <div class="column is-left">
                  <p class="title is-6" style="text-align:left; color: #A2A9AD;">{{subdirectores.inv_des===undefined ? "" : subdirectores.inv_des.cargo}}</p>
                  <h4 class="subtitle is-5" style="text-align:left; color:#EF7D00;">{{subdirectores.inv_des===undefined ? "" : subdirectores.inv_des.nombre}}</h4>
                  <h5 class="title is-6" style="text-align:left; color: #A2A9AD;">Correo Electrónico</h5>
                  <h5 class="subtitle is-6" style="text-align:left; color: #A2A9AD;">{{subdirectores.inv_des===undefined ? "" : subdirectores.inv_des.correo}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rows" v-if="subdirectores.vime.id!==null && subdirectores.vime.id!==undefined">
      <div class="row">
        <div class="columns">
          <div class="column">
            <div class="box">
              <div class="columns is-left">
                <div class="column is-2 is-left" style="width:200px">
                  <div class="image-container" style="display: flex;justify-content: left;" href="/people/" @click="redirect(subdirectores.vime.id)">
                    <b-image
                        :src=getPhotoUrl(subdirectores.vime)
                        alt="Foto de perfil"
                        style="max-width: 150px;height: 150px;"
                        :rounded="true"
                    ></b-image>
                  </div>
                </div>
                <div class="column is-left">
                  <p class="title is-6" style="text-align:left; color: #A2A9AD;">{{subdirectores.vime===undefined ? "" : subdirectores.vime.cargo}}</p>
                  <h4 class="subtitle is-5" style="text-align:left; color:#EF7D00;">{{subdirectores.vime===undefined ? "" : subdirectores.vime.nombre}}</h4>
                  <h5 class="title is-6" style="text-align:left; color: #A2A9AD;">Correo Electrónico</h5>
                  <h5 class="subtitle is-6" style="text-align:left; color: #A2A9AD;">{{subdirectores.vime===undefined ? "" : subdirectores.vime.correo}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rows">
      <div class="row" v-for="(object, i) in this.jefes_carrera">
        <div class="columns" v-if="i%2===0">
          <div class="column" v-if="jefes_carrera[i].id!==null && jefes_carrera[i].id!==undefined">
            <div class="box">
              <div class="columns is-left">
                <div class="column is-2 is-left" style="width:200px">
                  <div class="image-container" style="display: flex;justify-content: left;" href="/people/" @click="redirect(jefes_carrera[i].id)">
                    <b-image
                        :src=getPhotoUrl(jefes_carrera[i])
                        alt="Foto de perfil"
                        style="max-width: 150px;height: 150px;"
                        :rounded="true"
                    ></b-image>
                  </div>
                </div>
                <div class="column is-left">
                  <p class="title is-6" style="text-align:left; color: #A2A9AD;">{{jefes_carrera[i]===undefined ? "" : jefes_carrera[i].cargo}}</p>
                  <h4 class="subtitle is-5" style="text-align:left; color:#EF7D00;">{{jefes_carrera[i]===undefined ? "" : jefes_carrera[i].nombre}}</h4>
                  <h5 class="title is-6" style="text-align:left; color: #A2A9AD;">Correo Electrónico</h5>
                  <h5 class="subtitle is-6" style="text-align:left; color: #A2A9AD;">{{jefes_carrera[i]===undefined ? "" : jefes_carrera[i].correo}}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="column" v-if="i+1<jefes_carrera.length && jefes_carrera[i+1].id!==null && jefes_carrera[i+1].id!==undefined">
            <div class="box">
              <div class="columns is-left">
                <div class="column is-2 is-left" style="width:200px">
                  <div class="image-container" style="display: flex;justify-content: left;" href="/people/" @click="redirect(jefes_carrera[i+1].id)">
                    <b-image
                        :src=getPhotoUrl(jefes_carrera[i+1])
                        alt="Foto de perfil"
                        style="max-width: 150px;height: 150px;"
                        :rounded="true"
                    ></b-image>
                  </div>
                </div>
                <div class="column is-left">
                  <p class="title is-6" style="text-align:left; color: #A2A9AD;">{{jefes_carrera[i+1]===undefined ? "" : jefes_carrera[i+1].cargo}}</p>
                  <h4 class="subtitle is-5" style="text-align:left; color:#EF7D00;">{{jefes_carrera[i+1]===undefined ? "" : jefes_carrera[i+1].nombre}}</h4>
                  <h5 class="title is-6" style="text-align:left; color: #A2A9AD;">Correo Electrónico</h5>
                  <h5 class="subtitle is-6" style="text-align:left; color: #A2A9AD;">{{jefes_carrera[i+1]===undefined ? "" : jefes_carrera[i+1].correo}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rows" v-if="jefe_admin.id !== undefined && jefe_admin.id !== null">
      <div class="row">
        <div class="columns">
          <div class="column is-centered">
            <div class="box">
              <div class="columns is-left">
                <div class="column is-2 is-left" style="width:200px">
                  <div class="image-container" style="display: flex;justify-content: left;" href="/people/" @click="redirect(jefe_admin.id)">
                    <b-image
                        :src=getPhotoUrl(jefe_admin)
                        alt="Foto de perfil"
                        style="max-width: 150px;height: 150px;"
                        :rounded="true"
                    ></b-image>
                  </div>
                </div>
                <div class="column is-left">
                  <p class="title is-6" style="text-align:left; color: #A2A9AD;">{{jefe_admin===undefined ? "" : jefe_admin.cargo}}</p>
                  <h4 class="subtitle is-5" style="text-align:left; color:#EF7D00;">{{jefe_admin===undefined ? "" : jefe_admin.nombre}}</h4>
                  <h5 class="title is-6" style="text-align:left; color: #A2A9AD;">Correo Electrónico</h5>
                  <h5 class="subtitle is-6" style="text-align:left; color: #A2A9AD;">{{jefe_admin===undefined ? "" : jefe_admin.correo}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>


<script>
import axios from "axios";

export default {
  name: "authorities_list",
  data(){
    return{
      type: "is-boxed",
      size: "is-small",
      isLoading:false,
      director: {},
      subdirectores:[],
      jefes_carrera: [],
      jefe_admin: {}
    }
  },
  created(){
    this.isLoading=true;
    return axios.get("/admin/units/"+this.unitId+"/authorities.json")
        .then(res => {
          this.director = res.data['director'];
          this.subdirectores = res.data['subdirectores'];
          this.jefes_carrera = res.data['jefes_carrera'];
          this.jefe_admin = res.data['jefe_admin']
          this.isLoading=false;
        })
        .catch(e => {
          this.isLoading=false;
          console.log(e);
        });
  },
  props:[
    'unitId'
  ],
  methods:{
    redirect(id){
      console.log(id)
      if(id===undefined || id === null){
        return
      }
      else{
        return window.location.href = '/people/'+id
      }
    },
    getPhotoUrl(person){
      var defaultUrl = "/blank_profile_photo.png"
      if (person){
        if (person.photo_url){
          return person.photo_url;
        }
      }
      return defaultUrl;

    }
  },
  computed:{

  }
}
</script>