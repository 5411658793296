<template>
  <section>
    <div class="box">
      <div class="container">
        <h1 class="title is-2 has-text-weight-medium has-text-centered">Facultad de Ingeniería</h1>

        <b-tabs position="is-centered" @input="changeTab">

          <b-tab-item label="Resumen completo" icon="address-card">
            <h3 class="title is-2 has-text-weight-medium has-text-centered">Resumen de servicios EFUSACH de la Facultad de Ingeniería y sus Departamentos Académicos</h3>



            <section class="hero has-background-white-bis" style="box-shadow: 0px 6px 14px -4px rgba(196,196,196,1);">
              <b-collapse
                aria-id="contentIdForA11y2"
                class="custom-collapse"
                animation="slide"
                v-model="isOpen">
                <template #trigger="props">
                  <div
                      class="card-header"
                      role="button"
                      aria-controls="contentId3"
                      :aria-expanded="props.open">
                       <h2 class="card-header-title custom-header-title is-centered">
                        <b-icon type="is-info" class="is-pulled-left" icon="info-circle"></b-icon>
                        Filtros Aplicados
                      </h2>
                      <a class="card-header-icon">
                         <b-icon class="is-info" :icon="props.open ? 'sort-down' : 'sort-up'"></b-icon>
                      </a>
                  </div>
                </template>
                <div class="hero-body">
                  <div class="container">
                  
                    <div class="columns">
                      <div class="column">
                        <b-field label="Año desde:">
                          <multiselect v-model="minYearResum"
                                       :options="year_options"
                                       :searchable="true"
                                       :close-on-select="true"
                                       placeholder="Elija un año"
                                       :allow-empty="true"
                                       selectLabel="Presione para seleccionar"
                                       selectedLabel="Seleccionado"
                                       deselectLabel="Presione para deseleccionar"
                          ></multiselect>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Año hasta:"
                                 :type="{ 'is-danger': verifyYears }"
                                 :message="{ 'Año hasta no puede ser menor al Año desde': verifyYears }">
                          <multiselect v-model="maxYearResum"
                                       :options="year_options"
                                       :searchable="true"
                                       :close-on-select="true"
                                       placeholder="Elija un año"
                                       :allow-empty="true"
                                       selectLabel="Presione para seleccionar"
                                       selectedLabel="Seleccionado"
                                       deselectLabel="Presione para deseleccionar"
                          ></multiselect>
                        </b-field>
                      </div>
                    </div>


                    <div class="box">
                      <div class="columns is-centered has-text-centered">
                        <b-field label="Unidades académicas:"/>
                      </div>


                      <div v-for="(grupo, index) in gruposElementos" :key="index" class="columns is-centered has-text-centered">
                        <div v-for="(item, itemIndex) in grupo" :key="itemIndex" class="column">
                          
                            <b-checkbox class="column" v-model="unidades" :native-value="item.id">
                                {{item.name}}
                            </b-checkbox>

                        </div>
                      </div> 
                    </div> 
                    <div class="columns">
                      <div class="column has-text-right">
                        
                          <b-button class="button right-button is-info" icon-left="search"  @click="getFacultyActivity">
                            Aplicar filtros
                          </b-button>

                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </section>

            <section>
              <div class="box">

                <div class="box">
                  <b-collapse
                    aria-id="contentIdForA11y2"
                    class="custom-collapse"
                    animation="slide"
                    v-model="isOpenGraficoBarrasServicios">
                    <template #trigger="props">
                      <div
                          class="card-header"
                          role="button"
                          aria-controls="contentId3"
                          :aria-expanded="props.open">
                           <h2 class="card-header-title custom-header-title is-centered">                                    
                            Cantidad de servicios por departamento
                          </h2>
                          <a class="card-header-icon">
                             <b-icon class="is-info" :icon="props.open ? 'sort-down' : 'sort-up'"></b-icon>
                          </a>
                      </div>
                    </template>
                    <div class="hero-body">
                      <div class="container">
                        <div ref="graficoBarrasServicios" style="height: 30vw;"></div>
                      </div>
                    </div>
                  </b-collapse>
                </div>
                <div class="box">
                  <b-collapse
                    aria-id="contentIdForA11y2"
                    class="custom-collapse"
                    animation="slide"
                    v-model="isOpenChart">
                    <template #trigger="props">
                      <div
                          class="card-header"
                          role="button"
                          aria-controls="contentId3"
                          :aria-expanded="props.open">
                           <h2 class="card-header-title custom-header-title is-centered">
                            Distribución de tipos de servicio
                          </h2>
                          <a class="card-header-icon">
                             <b-icon class="is-info" :icon="props.open ? 'sort-down' : 'sort-up'"></b-icon>
                          </a>
                      </div>
                    </template>
                    <div class="hero-body">
                      <div class="container">

                        <div class="columns is-multiline">
                          <div class="column has-text-centered">
                            <div class="heading is-size-6">Proyectos de Asistencia técnica</div>
                            <div class="title">{{ AsistenciaTecnica }}</div>
                          </div>
                          <div class="column has-text-centered">
                            <div class="heading is-size-6">Capacitación</div>
                            <div class="title">{{ Capacitacion }}</div>
                          </div>
                          <div class="column has-text-centered">
                            <div class="heading is-size-6">Programas especiales</div>
                            <div class="title">{{ ProyectoEspecial }}</div>
                          </div>
                        </div>
                        <br>

                        <div ref="chart" style="height: 30vw;"></div>
                      </div>
                    </div>
                  </b-collapse>
                </div>
                <div class="box">
                  <b-collapse
                    aria-id="contentIdForA11y2"
                    class="custom-collapse"
                    animation="slide"
                    v-model="isOpenChart2">
                    <template #trigger="props">
                      <div
                          class="card-header"
                          role="button"
                          aria-controls="contentId3"
                          :aria-expanded="props.open">
                           <h2 class="card-header-title custom-header-title is-centered">                                    
                            Distribución de montos en contratación por unidades
                          </h2>
                          <a class="card-header-icon">
                             <b-icon class="is-info" :icon="props.open ? 'sort-down' : 'sort-up'"></b-icon>
                          </a>
                      </div>
                    </template>
                    <div class="hero-body">
                      <div class="container">
                         <div class="columns is-multiline">
                          <div class="column has-text-centered">
                            <div class="heading is-size-6">Contratos por honorarios</div>
                            <div class="title">{{ Contratos }}</div>
                          </div>
                          <div class="column has-text-centered">
                            <div class="heading is-size-6">Monto por honorarios</div>
                            <div class="title">${{ MontoContratos }}</div>
                          </div>
                        </div>
                        <br>
                        <div ref="chart2" style="height: 30vw;"></div>
                      </div>
                    </div>
                  </b-collapse>
                </div>                   
              </div>
            </section>
          </b-tab-item>
          <b-tab-item
              label="Unidades académicas"
              icon="building"
          >


            <h3 class="title is-2 has-text-weight-medium has-text-centered">Resumen de servicios EFUSACH por Unidades académicas</h3>

              <div class="column" v-if=" departamentos.length>0">
                <b-field>
                <template #label>
                              Unidad
                              <b-tooltip
                                  label="Seleccionar la unidad académicos, desplegara la información de esta."
                                  position="is-right"
                                  size="is-medium"
                                  multilined>

                                <b-icon
                                    pack="fas"
                                    icon="info-circle"
                                    size="is-small"
                                    type="is-info">
                                </b-icon>
                              </b-tooltip>
                            </template> 
                    <multiselect v-model="unit"
                                 :options="departamentos.map(type => type.id)"
                                 :custom-label="opt => departamentos.find(x => x.id == opt).name"
                                 @input="updateFilter"
                                 placeholder="Seleccione unidad"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                          No se encontraron elementos.
                      </span>
                    </multiselect>
                  
                </b-field>
              </div>

              <div v-if="unit!=null">
                <sdt-show-unit ref="sdtShowUnitRef" v-bind:unitId="this.unit" :cargoPrincipal="this.cargoPrincipal" faculty="false"></sdt-show-unit>
                
              </div>




          </b-tab-item>

          <!-- b-tab-item
              label="Reportes"
              icon="chart-pie"
          >
            <div class="columns">
              <div class="column">
                <b-field label="Año desde:">
                  <multiselect v-model="minYear"
                               :options="year_options"
                               :searchable="true"
                               :close-on-select="true"
                               placeholder="Elija un año"
                               :allow-empty="false"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                  ></multiselect>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Año hasta:"
                         :type="{ 'is-danger': verifyYears }"
                         :message="{ 'Año hasta no puede ser menor al Año desde': verifyYears }">
                  <multiselect v-model="maxYear"
                               :options="year_options"
                               :searchable="true"
                               :close-on-select="true"
                               placeholder="Elija un año"
                               :allow-empty="false"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                  ></multiselect>
                </b-field>
              </div>
            </div>




              <b-table
              :data="graficos"
              :paginated=true
              per-page=10
              striped
              hoverable
              :current-page="currentPage"
              :pagination-simple=false
              pagination-position="bottom"
              default-sort-direction="desc"
              sort-icon="arrow-up"
              sort-icon-size="is-small"
              aria-next-label="Siguiente"
              aria-previous-label="Anterior"
              aria-page-label="Página"
              aria-current-label="Página actual">

                <b-table-column
                  field= 'name'
                  label= 'Reporte'
                  searchable
                  sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.name }}
                  </template>
                </b-table-column>

                <b-table-column
                    label="Acciones"
                    v-slot="props">
                  <b-button type="is-primary"
                                icon-left="eye"
                                @click="openModal(props.row.content)">
                      </b-button>
                  <b-icon
                      pack="fas"
                      icon="trash"
                      size="is-medium"
                      type="is-danger"
                      @click.native="execNullifyModal(props.row.id, props.row)"/>
                    
                </b-table-column>

                <template slot="empty" slot-scope="props">
                  <div class="columns is-centered">
                    <div class="column is-6 is-centered has-text-centered">
                      <b-message type="is-danger">
                        No se encontraron departamentos acádemicos.
                      </b-message>
                    </div>
                  </div>
                </template>
              </b-table>
          </b-tab-item> -->



        </b-tabs>
      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>


<script>
  import SdtShowUnit from "./SdtShowUnit.vue";
  import axios from "axios";
  import * as echarts from 'echarts';

  export default {
    components:{
    SdtShowUnit
    },
    props:[
        'unitId', 'cargoPrincipal'
    ],
    data(){
      return{
        type: "is-boxed",
        size: "is-small",
        AsistenciaTecnica:0,
        Capacitacion:0,
        ProyectoEspecial:0,
        Contratos:0,
        MontoContratos:0,
        isLoading:false,
        departamentos: [],
        currentPage:1,
        unit: this.unitId,
        canCancel: ['escape'],
        minYear:new Date().getFullYear() - 5,
        maxYear:new Date().getFullYear(),
        minYearResum:new Date().getFullYear() - 5,
        maxYearResum:new Date().getFullYear(),
        verifyYears:false,
        activacion: false,
        UnidadesProyectoEspecial: [],
        UnidadesCapacitacion: [],
        UnidadesAsisTec: [],
        valores_honorarios: [],
        unidades: [],
        nom_deptos: [],
        year_options:Array.from({length:(new Date().getFullYear()-2000+1)},(v,k)=>k+2000).reverse(),
        colores: [ '#e74c3c','#16a085', '#f39c12','#2c3e50', '#9b59b6', '#e67e22', '#3498db', '#d35400','#c0392b'],
        isOpen: true,
        isOpenChart: true,
        isOpenChart2: true,
        isOpenGraficoBarrasServicios: true,
        chart: null,
        chart2: null,
        graficoBarrasServicios: null,
        graficos:[
          ]
      }
    },
    created(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/sdt_services/form-data.json")
          .then(res => {
            vm.departamentos = res.data.unidades;
            vm.unidades = res.data.unidades.map(elemento => elemento.id);
            vm.isLoading=false;
            vm.getFacultyActivity();
            vm.isOpenChart = false;
            vm.isOpenChart2 = false;
            vm.isOpenGraficoBarrasServicios = false;
            

          })
          .catch(e => {
            vm.isLoading=false;
            console.log(e);
          });

    },
    mounted() {
      this.inicializarGrafico();
    },
    
    methods:{
      async openModal(grafico){
        this.isLoading=true;
        this.isLoading=false;

      },
      inicializarGrafico() {
        this.chart = echarts.init(this.$refs.chart);
        this.chart2 = echarts.init(this.$refs.chart2);
        this.graficoBarrasServicios = echarts.init(this.$refs.graficoBarrasServicios);
        this.actalizarGraficoBarrasServicios();
        this.actualizarGrafico();
        this.actualizarGraficoMontosDpto();
      },

      actalizarGraficoBarrasServicios () {
        var nombre_unidades = this.nom_deptos;
        var valores_especial = this.UnidadesProyectoEspecial;
        var valores_cap = this.UnidadesCapacitacion;
        var valores_asisTec = this.UnidadesAsisTec;
        var sumas = nombre_unidades.map((nombre, index) => {
            return valores_especial[index] + valores_cap[index] + valores_asisTec[index] ;
        });
        var option = {
            title: {
                text: 'Cantidad de servicios por departamento según categoría',
                left: 'center'
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: function(params) {
                    var unitIndex = params[0].dataIndex;
                    var totalValue = sumas[unitIndex];


                    var content = `${nombre_unidades[unitIndex]}<br />`;
                    content += `Total: ${Number(totalValue).toLocaleString('es-ES')}<br />`;

                    params.forEach(function(param) {
                        content += `${param.seriesName}: ${Number(param.value).toLocaleString('es-ES')}<br />`;
                    });

                    return content;
                },
            },
            toolbox: {
                feature: {
                    magicType: {
                        type: ['stack']
                    },
                    itemSize:17,
                    saveAsImage:{
                        pixelRatio:3,
                        title:"Descargar",
                        name:"Cantidad de servicios por departamento según categoría",
                    },
                }
            },

            legend: {
              orient: 'vertical',
              right: '0%',
              top: 'center', 
              formatter: function (name) {
                const maxCharactersPerLine = 35;
                const lines = [];
                for (let i = 0; i < name.length; i += maxCharactersPerLine) {
                  lines.push(name.substr(i, maxCharactersPerLine));
                }
                return lines.join('\n');
              },
            },
            color: this.colores,
            grid: {
                left: '3%',
                right: '20%',
                bottom: '3%',
                containLabel: true,
            },
            yAxis: {
              type: 'value',                 
            },
            xAxis: {  
              type: "category",
              data: nombre_unidades,
            },
            series: [
                {
                    name: "Proyecto de Asistencia Técnica",
                    type: 'bar',
                    data: valores_asisTec,
                    stack: 'one',
                    
                    emphasis: {
                        focus: 'series'
                    }
                },
                {
                    name: "Capacitación",
                    type: 'bar',
                    data: valores_cap,
                    stack: 'two',
                    
                    emphasis: {
                        focus: 'series'
                    }
                },
                {
                    name: "Programa Especial",
                    type: 'bar',
                    data: valores_especial,
                    stack: 'three',
                    
                    emphasis: {
                        focus: 'series'
                    }
                },
            ],

        };
        this.graficoBarrasServicios.setOption(option);

      },
      actualizarGrafico() {

        const option = {
          tooltip: {
            trigger: 'item'
          },
          title: {
              text: 'Distribución de tipos de servicio',
              left: 'center'
          },
          legend: {
              orient: 'vertical',
              right: '0%',
              top: 'center', 
              formatter: function (name) {
                const maxCharactersPerLine = 35;
                const lines = [];
                for (let i = 0; i < name.length; i += maxCharactersPerLine) {
                  lines.push(name.substr(i, maxCharactersPerLine));
                }
                return lines.join('\n');
              },
            },
          color: this.colores,
          series: [
            {
              name: 'Servicios',
              type: 'pie',
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 16,
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: [
                { value: this.AsistenciaTecnica, name: 'Proyectos de Asistencia Técnica' },
                { value: this.Capacitacion, name: 'Capacitación' },
                { value: this.ProyectoEspecial, name: 'Programa especial' },
              ]
            }
          ]
        };  
        this.chart.setOption(option);
               
      },
      actualizarGraficoMontosDpto() {

        var option = {
            title: {
                text: 'Distribución de montos en contratación por departamento',
                left: 'center'
            },
            tooltip: {
                trigger: 'item',
            },

            legend: {
              orient: 'vertical',
              right: '0%',
              top: 'center', 
              formatter: function (name) {
                const maxCharactersPerLine = 35;
                const lines = [];
                for (let i = 0; i < name.length; i += maxCharactersPerLine) {
                  lines.push(name.substr(i, maxCharactersPerLine));
                }
                return lines.join('\n');
              },
            },
            color: this.colores,
            series: [
                {
                  name: 'Unidad',
                  type: 'pie',
                  avoidLabelOverlap: false,
                  label: {
                    show: true,
                    fontSize: 16,
                    formatter: '{d}%',
                    position: 'inside',
                  },
                  emphasis: {
                    label: {
                      show: true,
                      fontSize: 16,
                      fontWeight: 'bold'
                    }
                  },
                  labelLine: {
                    show: false
                  },
                  data : this.valores_honorarios,
                },

            ],
            toolbox: {
                feature: {
                    itemSize:20,
                    saveAsImage:{
                        pixelRatio:3,
                        title:"Descargar",
                        name:"Distribución de montos totales por contratos honorarios por departamento",
                    },
                }
            },
        };
        this.chart2.setOption(option);
      },

      async getGraficoHnorariosDpto(){
        let vm = this;
        vm.isLoading=true;
        await axios.get("/sdt_services/get_indicator_honorarios_by_dpto/"+vm.unitId+".json", {params: {unidades: JSON.stringify(vm.unidades)}})
          .then(res => {
            vm.valores_honorarios = res.data.valores_honorarios;
            this.actualizarGraficoMontosDpto();          
          })
          .catch(e => {
            vm.isLoading=false;
            console.log(e);
          });


      },
      updateFilter() {
        this.$refs.sdtShowUnitRef.applyFilter();
      },
      async getFacultyActivity(){
        let vm = this;
        vm.isLoading=true;
        this.getGraficoHnorariosDpto();
        this.getGraficoMontosDpto();
        await axios
            .get("/sdt_services/"+vm.unitId+"/faculty_summary_activity.json",
                {params: {
                    minYear: vm.minYearResum,
                    maxYear: vm.maxYearResum,
                    unidades: JSON.stringify(vm.unidades)
                  }
                })
            .then(res => {
              vm.AsistenciaTecnica = res.data.sizeAsistenciaTecnica;
              vm.Capacitacion = res.data.sizeCapacitacion;
              vm.ProyectoEspecial = res.data.sizeProyectoEspecial;
              vm.Contratos = res.data.sizeContratos;
              vm.MontoContratos = Number(res.data.MontoHonorario).toLocaleString('es-ES');
              //console.log(res)
              


              this.actalizarGraficoBarrasServicios();
              this.actualizarGrafico();
              this.actualizarGraficoMontosDpto();

              vm.isLoading=false;
            })
            .catch(e => {
              console.log(e);
              vm.isLoading=false;
            });
      },
      async getGraficoMontosDpto(){

        let vm = this;
        vm.isLoading=true;
        await axios
            .get("/sdt_services/get_indicator_by_dpto.json",
                {params: {
                    unit_id: vm.unitId,
                    minYear: vm.minYearResum,
                    maxYear: vm.maxYearResum,
                    unidades: JSON.stringify(vm.unidades)
                  }
                })
            .then(res => {
              vm.UnidadesProyectoEspecial = res.data.valores_proyEsp;
              vm.UnidadesCapacitacion = res.data.valores_capacitaciones;
              vm.UnidadesAsisTec = res.data.valores_asisTec;
              vm.nom_deptos = res.data.nombre_unidades;
              this.actalizarGraficoBarrasServicios();
              this.actualizarGrafico();
              this.actualizarGraficoMontosDpto();
            })
            .catch(e => {
              console.log(e);
              vm.isLoading=false;
            });

      },
      changeTab: function(value){
        this.currentTab = value
      },

    },
    computed:{
      gruposElementos() {
        // Agrupa los elementos en conjuntos de 3
        const grupos = [];
        for (let i = 0; i < this.departamentos.length; i += 2) {
          grupos.push(this.departamentos.slice(i, i + 2));
        }
        return grupos;
      },

    }
  }
</script>

<style scoped>
  .right-button{
    margin-left: auto;
  }
  .custom-collapse {
    background-color: #F9F9F9 !important;
    border: 1px solid #000000;
  }
  .custom-header-title {
    font-size: 24px; 
    font-weight: bold; 
    text-align: center !important; 
    margin: 0; 
  }
</style>

