var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveMagazine)}}},[_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"},attrs:{"id":"modal-magazine"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(_vm._s(_vm.modalHeader))]),_vm._v(" "),_c('button',{staticClass:"delete",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}})]),_vm._v(" "),_c('section',{ref:"modalPersonForm",staticClass:"modal-card-body"},[(Object.keys(_vm.errores_validacion).length != 0)?_c('div',{staticClass:"mb-2"},[_c('b-message',{attrs:{"type":"is-danger","has-icon":""}},_vm._l((_vm.errores_validacion),function(item,index){return _c('div',[_vm._v("\n                "+_vm._s(item[0])+"\n              ")])}),0)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Tipo*"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.magazine_types.map(function (type) { return type.id; }),"custom-label":function (opt) { return _vm.magazine_types.find(function (x) { return x.id == opt; }).name; },"placeholder":"Seleccione tipo","selectLabel":"Presione para seleccionar","selectedLabel":"Seleccionado","deselectLabel":"No se puede deseleccionar","allow-empty":false},scopedSlots:_vm._u([{key:"noOptions",fn:function(){return [_vm._v("\n                      No existen datos\n                    ")]},proxy:true}],null,true),model:{value:(_vm.magazine.magazine_type_id),callback:function ($$v) {_vm.$set(_vm.magazine, "magazine_type_id", $$v)},expression:"magazine.magazine_type_id"}},[_vm._v(" "),_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("\n                      No se encontraron elementos.\n                    ")])]),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Nombre Lugar de Publicación*"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"text","maxlength":"255","has-counter":false},model:{value:(_vm.magazine.name),callback:function ($$v) {_vm.$set(_vm.magazine, "name", $$v)},expression:"magazine.name"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Nombre abreviado"}},[_c('b-input',{attrs:{"type":"text","maxlength":"255","has-counter":false},model:{value:(_vm.magazine.abbreviated_name),callback:function ($$v) {_vm.$set(_vm.magazine, "abbreviated_name", $$v)},expression:"magazine.abbreviated_name"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Link*"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"text","maxlength":"255","has-counter":false},model:{value:(_vm.magazine.url),callback:function ($$v) {_vm.$set(_vm.magazine, "url", $$v)},expression:"magazine.url"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)])])]),_vm._v(" "),_c('footer',{staticClass:"modal-card-foot actions"},[_c('div',{staticClass:"field is-grouped is-pulled-right"},[_c('button',{staticClass:"button",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Cerrar")]),_vm._v(" "),_c('button',{staticClass:"button is-primary",attrs:{"type":"submit"},on:{"click":function($event){return _vm.checkValidation()}}},[_vm._v("Guardar ")])])])])]}}])}),_vm._v(" "),_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }