<template>
  <section>
    <div class="columns">
      <div class="column is-12">
        <div class="columns">
          <div class="column">
            <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
              <b-message type="is-danger" has-icon>
                <div v-for="(item,index) in errores_validacion" :key='index'>
                  <div v-if="item">{{ item[0] }}</div>
                </div>
              </b-message>
            </div>
          </div>
        </div>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(saveProject)">
            <div class="box">




                <b-steps
                    v-if="isUpdatingAndDeletingParticipant(false)!=undefined"
                    v-model="activeStep"
                    :animated="isAnimated"
                    :rounded="isRounded"
                    :has-navigation="hasNavigation"
                    :icon-prev="prevIcon"
                    :icon-next="nextIcon"
                    :label-position="labelPosition"
                    :mobile-mode="mobileMode">
                  <b-step-item v-if="isUpdatingAndDeletingParticipant(false)"
                               step="1" label="General" :clickable="isStepsClickable">
                     <!--<h1 class="title has-text-centered">General  [1/3]</h1> -->
                     <h1 class="title has-text-centered">General  [1/2]</h1>

                      <div class="columns">
                        <div class="column" v-if="tipos_proyecto.length>0">
                          <b-field label="Tipo de servicio*">
                            <ValidationProvider rules="required" v-slot="{errors}">
                              <multiselect v-model="project.sdt_service_type_id"
                                           :options="tipos_proyecto.map(type => type.id)"
                                           :custom-label="opt => tipos_proyecto.find(x => x.id == opt).name"
                                           placeholder="Seleccione tipo de servicio"
                                           selectLabel="Presione para seleccionar"
                                           selectedLabel="Seleccionado"
                                           deselectLabel="No se puede deseleccionar"
                                           :disabled="queryType=='update'"
                                           :allow-empty="false">
                                <template v-slot:noOptions>
                                  No existen datos
                                </template>
                                <span slot="noResult">
                                    No se encontraron elementos.
                                </span>
                              </multiselect>
                              <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>
                        <div class="column" v-if="unidades.length>0">
                          <b-field label="Unidad*">
                            <ValidationProvider rules="required" v-slot="{errors}">
                              <multiselect v-model="project.unit_id"
                                           :options="unidades.map(type => type.id)"
                                           :custom-label="opt => unidades.find(x => x.id == opt).name"
                                           placeholder="Seleccione Unidad"
                                           selectLabel="Presione para seleccionar"
                                           selectedLabel="Seleccionado"
                                           deselectLabel="No se puede deseleccionar"
                                           :allow-empty="false">
                                <template v-slot:noOptions>
                                  No existen datos
                                </template>
                                <span slot="noResult">
                                    No se encontraron elementos.
                                </span>
                              </multiselect>
                              <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>

                        
                      </div>
                      <div class="columns">
                        <div class="column">
                          <b-field label="Nombre*">
                            <ValidationProvider rules="required|max:240" v-slot="{ errors }">
                              <b-input v-model="project.name" maxlength="240" placeholder="Ej: Herramientas De Trabajo Colaborativo" type="text"></b-input>
                              <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column">
                          <b-field>
                            <template #label>
                              Código*
                              <b-tooltip
                                  label="Esté código será utilizado como identificador único en caso de que haya alcance de nombre."
                                  position="is-right"
                                  size="is-medium"
                                  multilined>

                                <b-icon
                                    pack="fas"
                                    icon="info-circle"
                                    size="is-small"
                                    type="is-info">
                                </b-icon>
                              </b-tooltip>
                            </template>
                            <ValidationProvider rules="required|max:200" v-slot="{errors}">
                              <b-input type="text" maxlength="200" placeholder="Ej: USA2156_FING" v-model="project.code"></b-input>
                              <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>


                        <div class="column" >
                          <div class="field">
                            <b-field label="Año de creación*">
                              <ValidationProvider rules="required|integer|positive|max:4|min:4" v-slot="{errors}">
                                <b-input type="number" maxlength="4" placeholder="Ej: 2023" v-model="project.award_year"></b-input>
                                <span class="validation-alert">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </b-field>
                          </div>
                        </div>
                        
                      </div>

                      <div class="columns">
                        <div class="column">
                          <div class="field">
                            <b-field label="Fecha de inicio*">
                              <ValidationProvider rules="required" v-slot="{errors}">
                                <b-datepicker
                                    v-model="project.start_date"
                                    icon="calendar-alt"
                                    trap-focus
                                    locale="es-ES"
                                    editable
                                    placeholder="Formato de Fecha: dd/mm/aaaa">
                                </b-datepicker>
                                <span class="validation-alert">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </b-field>
                          </div>
                        </div>
                        <div class="column">
                          <div class="field">
                            <b-field label="Fecha de término">
                              <b-datepicker
                                  v-model="project.end_date"
                                  icon="calendar-alt"
                                  trap-focus
                                  locale="es-ES"
                                  editable
                                  placeholder="Formato de Fecha: dd/mm/aaaa">
                              </b-datepicker>
                            </b-field>
                          </div>
                        </div>
                      </div>

                      <div class="columns">
                        <div class="column">
                          <b-field label="Cliente">
                              <b-autocomplete v-model="entidad_name"
                                              :keep-first="keepFirst"
                                              :open-on-focus="openOnFocus"
                                              :data="partner_entities"
                                              field="name"
                                              empty="Vacio"
                                              placeholder="Ej: ANID"
                                              @typing="searchPartnerEntities"
                                              @select="option => {if (option) {project.partner_entity_id = option.id} }"
                                              :clearable="clearable">
                                <template slot-scope="props">
                                  {{ props.option.show_name ? props.option.show_name : props.option.name}}
                                </template>
                              </b-autocomplete>
                          </b-field>
                        </div>
                        <div class="column is-narrow">
                          <br>
                          <b-field label=" ">
                            <b-tooltip label="Si la entidad que desea agregar no existe, la puede agregar presionando el botón +"
                                       position="is-bottom">
                              <b-button class="is-primary is-outlined" @click="addEntity()">
                                <b-icon icon="plus"></b-icon>
                              </b-button>
                            </b-tooltip>
                          </b-field>
                        </div>
                      </div>


                      <div class="columns">
                        <div class="column">
                          <div class="field">
                            <b-field label="Objetivo general">
                              <b-input
                                  type="textarea"
                                  minlength="0"
                                  maxlength="500"
                                  v-model="project.description">
                              </b-input>
                            </b-field>
                          </div>
                        </div>

                      </div>

                      



                  </b-step-item>


                  <b-step-item step="2" label="Responsables" :clickable="isStepsClickable">
                    <!-- <h1 class="title has-text-centered">Responsables [2/3]</h1> -->
                    <h1 class="title has-text-centered">Responsables [2/2]</h1>

                    <b-tooltip label="En esta sección debe ingresar las(os) responsables del proyecto que
                                  se encuentren registrados en el sistema, de esta manera, los datos ingresados
                                   de este proyecto quedarán asociados a esta persona."
                               position="is-right"
                               size="is-medium"
                               multilined>
                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          size="is-small"
                          type="is-info">
                      </b-icon>
                    </b-tooltip>
                    <div v-if="isUpdatingAndDeletingParticipant(false)" class="is-pulled-right">
                      <b-button  class="mt-2" @click.prevent="execAddModal(serviceId)"
                                 type="is-primary">Administrar Responsables</b-button>
                    </div>

                    <div class="column">
                      <h3 class="is-size-4 has-text-weight-bold">Tabla Resumen Responsables</h3>
                      <b-table
                          :data="participantes"
                          :paginated=true
                          per-page=5
                          striped
                          hoverable
                          :current-page="currentPage"
                          :card-layout="cardLayout"
                          :pagination-simple=false
                          pagination-position="bottom"
                          default-sort-direction="desc"
                          sort-icon="arrow-up"
                          sort-icon-size="is-small"
                          default-sort="last_patent_state_date"
                          aria-next-label="Siguiente"
                          aria-previous-label="Anterior"
                          aria-page-label="Página"
                          aria-current-label="Página actual">
                        <b-table-column
                            field= 'name'
                            label= 'Nombres'
                            width="20em"
                            searchable
                            sortable>
                          <template
                              slot="searchable"
                              slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                icon="search"
                                size="is-small" />
                          </template>
                          <template v-slot="props">
                            {{  props.row.name }}
                          </template>
                        </b-table-column>
                        <b-table-column
                            field= 'corporate_mail'
                            label= 'Correo Corporativo'
                            width="20em"
                            searchable
                            sortable>
                          <template
                              slot="searchable"
                              slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                icon="search"
                                size="is-small" />
                          </template>
                          <template v-slot="props">
                            {{  props.row.corporate_mail }}
                          </template>
                        </b-table-column>
                        <b-table-column
                            field= 'unit'
                            label= 'Unidad'
                            width="15em"
                            searchable
                            sortable>
                          <template
                              slot="searchable"
                              slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                icon="search"
                                size="is-small" />
                          </template>
                          <template v-slot="props">
                            {{  props.row.unit }}
                          </template>
                        </b-table-column>
                        <b-table-column
                          width="15em"
                            label="Tipo de Participación*"
                            v-slot="props">
                          <ValidationProvider v-if="isUpdatingAndDeletingParticipant(props.row)"
                                              rules="required" v-slot="{errors}">
                            <multiselect v-model="props.row.participant_type_id"
                                         :options="tipos_participacion.map(x => x.id)"
                                         :custom-label="opt => tipos_participacion.find(x => x.id == opt).name"
                                         placeholder="Seleccione tipo de participación"
                                         selectLabel=""
                                         selectedLabel=""
                                         deselectLabel=""
                                         :allow-empty="false">
                              <template v-slot:noOptions>
                                No existen datos
                              </template>
                              <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                            </multiselect>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-table-column>

                        <b-table-column
                            label="Acción"
                            width="10"
                            v-slot="props">
                          <b-icon
                              v-if="isUpdatingAndDeletingParticipant(props.row)"
                              pack="fas"
                              style="color: #e50000;"
                              icon="trash"
                              size="is-medium"
                              @click.native="deleteParticipantButton(props.row)"/>
                        </b-table-column>

                        <template slot="empty" slot-scope="props">
                          <div class="columns is-centered">
                            <div class="column is-6 is-centered has-text-centered">
                              <b-message type="is-danger">
                                No se encontraron participantes asociados.
                              </b-message>
                            </div>
                          </div>
                        </template>
                      </b-table>
                    </div>

                  </b-step-item>

                  <!--

                  <b-step-item step="3" label="Documentos" :clickable="isStepsClickable">
                    <h1 class="title has-text-centered">Documentos [3/3]</h1>

                    <div v-if="isUpdatingAndDeletingParticipant(false)" class="is-pulled-right">
                      <b-button  class="mt-2" @click.prevent="execAddDocumentModal(serviceId)"
                                 type="is-primary">Añadir Documento</b-button>
                    </div>

                    <div class="column">
                      <h3 class="is-size-4 has-text-weight-bold">Tabla Resumen Documentos</h3>
                      <b-table
                          :data="documentos"
                          :paginated=true
                          per-page=5
                          striped
                          hoverable
                          :current-page="currentPage"
                          :card-layout="cardLayout"
                          :pagination-simple=false
                          pagination-position="bottom"
                          default-sort-direction="desc"
                          sort-icon="arrow-up"
                          sort-icon-size="is-small"
                          default-sort="last_patent_state_date"
                          aria-next-label="Siguiente"
                          aria-previous-label="Anterior"
                          aria-page-label="Página"
                          aria-current-label="Página actual">
                        <b-table-column
                            field= 'name'
                            label= 'Nombres'
                            width="20em"
                            searchable
                            sortable>
                          <template
                              slot="searchable"
                              slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                icon="search"
                                size="is-small" />
                          </template>
                          <template v-slot="props">
                            {{  props.row.file.name }}
                          </template>
                        </b-table-column>
                        <b-table-column
                            field= 'corporate_mail'
                            label= 'Categoría'
                            searchable
                            sortable>
                          <template
                              slot="searchable"
                              slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                icon="search"
                                size="is-small" />
                          </template>
                          <template v-slot="props">
                            {{  props.row.tipo }}
                          </template>
                        </b-table-column>
                        <b-table-column
                            field= 'unit'
                            label= 'Fecha'
                            searchable
                            sortable>
                          <template
                              slot="searchable"
                              slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                icon="search"
                                size="is-small" />
                          </template>
                          <template v-slot="props">
                            {{ props.row.fecha._isValid ? new Date(props.row.fecha).toLocaleDateString("es-CL"): '' }}
                          </template>
                        </b-table-column>
                        <b-table-column
                            label="Acción"
                            width="10"
                            v-slot="props">
                          <b-icon
                              v-if="isUpdatingAndDeletingParticipant(props.row)"
                              pack="fas"
                              style="color: #e50000;"
                              icon="trash"
                              size="is-medium"
                              @click.native="deleteParticipantButton(props.row)"/>
                        </b-table-column>

                        <template slot="empty" slot-scope="props">
                          <div class="columns is-centered">
                            <div class="column is-6 is-centered has-text-centered">
                              <b-message type="is-danger">
                                No se encontraron documentos asociados.
                              </b-message>
                            </div>
                          </div>
                        </template>
                      </b-table>
                    </div>
                    <hr class="dropdown-divider" aria-role="menuitem">


                  </b-step-item>
                -->

                  <template
                      #navigation="{previous, next}">
                    <div class="columns is-pulled-right" >
                      <div class="column">
                        <b-button
                            outlined
                            icon-left="chevron-left"
                            :disabled="previous.disabled"
                            @click.prevent="previous.action">
                        </b-button>
                        <b-button
                            outlined
                            icon-right="chevron-right"
                            :disabled="next.disabled"
                            @click.prevent="next.action">
                        </b-button>

                      </div>
                    </div>
                  </template>

                </b-steps>
                <br>
                <br>
                <br>
                <div class="columns">
                  <div class="column">
                    <div class="is-pulled-left">
                      <button v-if="modalCancel" class="button is-danger" @click.prevent="$emit('close')">Cancelar</button>
                    </div>
                    <div class="is-pulled-right">
                      <b-button class="button is-primary" icon-left="save" native-type="submit" @click="checkValidation()">Guardar Servicio EFUSACH</b-button>
                    </div>
                  </div>
                </div>



            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>




    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="isModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-participant-project :project-id="serviceId"
                                   :id="selected"
                                   :participantes="participantes"
                                   query-type="update"
                                   modal-header="Añadir Responsable"
                                   @close="props.close"
                                   @clicked="onClickChild"
        >
        </modal-participant-project>
      </template>
    </b-modal>

    <b-modal
        v-model="isModalDocumentActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-document :service-id="serviceId"
                                   :id="selected"
                                   modal-header="Añadir Documento"
                                   @close="props.close"
                                   @clicked="onClickChildDocument"
        >
        </modal-document>
      </template>
    </b-modal>
    <b-modal
            v-model="isAddModalEntityActive"
            :destroy-on-hide="true"
            width="50%"
            :can-cancel="canCancel">
          <template #default="props">
            <modal_partner_entity
                query-type="update"
                modal-header="Añadir nueva entidad"
                @close="props.close"
                @clicked="onClickChildEntity"></modal_partner_entity>
          </template>
        </b-modal>
  </section>
</template>

<script>
import axios from "axios";
import ModalParticipantProject from "./ModalParticipantService";
import modal_partner_entity from "../moduloVIME/agreements/new/modal_partner_entity";
import modalDocument from "./ModalUploadDocument";
import moment from "moment";
export default {
  name: "NewServiceFormForm",
  components:{ ModalParticipantProject, modal_partner_entity, modalDocument },
  data() {
    return {
      activeStep: 0,
      isAnimated: true,
      isRounded: true,
      keepFirst: false,
      isStepsClickable: true,
      hasNavigation: true,
      openOnFocus: false,
      clearable: false,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      errores_validacion: {},
      entidad_name: null,
      project:{
        participants_attributes:[],
        validation_attributes:{},
      },
      tipos_proyecto:[],
      unidades:[],
      documentos:[],
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      partner_entities:[],
      isModalActive:false,
      isModalDocumentActive:false,
      isAddModalEntityActive: false,
      canCancel: ['escape'],
      participantes:[],
      tipos_participacion:[],
      is_validated:false,
      is_validated_participant: false,
      ownerId: null,
    }
  },
  props:[
      'modalHeader','hideHeader','userId','queryType','serviceId','modalCancel', "cargoPrincipal", "unidadPrincipal","showParticipantsFirst", "serviceTable"
  ],
  async created(){
    await this.getServiceTypes();
    if (this.queryType=='update'){
      this.getService();
    }
  },
  methods:{
    async saveProject(){
      let vm = this;
      await vm.checkValidation();
      if(vm.is_validated) {
        await vm.checkParticipants();
      }
      if (vm.is_validated && vm.is_validated_participant){
        vm.isLoading=true;
        //vm.project.participants_attributes = vm.participantes
        //var retorno = JSON.parse(JSON.stringify(this.project.participants_attributes));
        var retorno = [];
        if (vm.participantes){
          vm.project.participants_attributes = vm.participantes
          retorno = JSON.parse(JSON.stringify(vm.project.participants_attributes));
        }
        if (vm.queryType==="create") {
          vm.project.academic_activity_state_id = 1
          axios
              .post("/sdt_services.json", {
                sdt_service: vm.project,
                participants: retorno.map(function(x) {
                  delete x.unit_id;
                  delete x.unit_participants;
                  return x;
                }),
                user_id:vm.userId,
                data_source: vm.project.validation_attributes.data_source,
                ownerId: vm.userId
              })
              .then(response => {
                vm.errores_validacion = {};
                var tabla = ""
                if (vm.project.sdt_service_type_id==1){
                  tabla = "projects"
                }
                else{
                  tabla = "sdt_services"
                }
                window.location.href = '/sdt_services/'+response.data["id"]+'?type='+response.data["table"]
                vm.isLoading=false;
              }).
          catch(error => {
            vm.errores_validacion = error.response.data
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            vm.isLoading=false;
          })
        }

        if (vm.queryType==="update"){
          axios
              .put("/sdt_services/"+vm.serviceId+".json", {
                sdt_service: vm.project,
                participants: retorno.map(function(x) {
                  delete x.unit_id;
                  delete x.unit_participants;
                  return x;
                }),
                user_id:vm.userId,
                ownerId: vm.ownerId
              })
              .then(response => {
                vm.errores_validacion = {};

                this.$emit('clicked', response.data)
                this.$emit('close')
                vm.isLoading=false;
              }).
          catch(error => {
            vm.errores_validacion = error.response.data
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            vm.isLoading=false;

          })
        }

      }


    },
    async getServiceTypes(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/sdt_services/form-data.json")
        .then(res => {
          vm.tipos_proyecto = res.data.project_types;
          vm.tipos_participacion = res.data.participation_types;
          vm.tipos_participacion = vm.tipos_participacion.filter(type => type.name != "INTEGRANTE")
          vm.unidades = res.data.unidades;
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    searchPartnerEntities(name){
      if(name == null   || name.length == 0){
        this.position = null
      }
      if (name.length<3) {
        this.partner_entities = []
        return
      }
      let vm = this;
      vm.isLoading=true;
      axios
          .get("/admin/partner_entities/agreement-search.json", { params: {
            name: name
          } })
          .then(res => {
            vm.partner_entities = res.data;
            vm.isLoading = false;
          })
          .catch(error => {
            console.log(error)
            vm.isLoading=false;
          })
    },
     addEntity(){
      this.isAddModalEntityActive = true;
    },

    async checkValidation() {
      this.is_validated = false;
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar tanto la pestaña General como la de Participantes.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
      else{
        this.is_validated = true;
      }
    },

    async checkParticipants() {
      this.is_validated_participant = false;
      if (this.participantes.length!=2) {
        this.$buefy.dialog.alert({
          message: 'Se debe ingresar unicamente el Jefe de Proyecto(a) y Supervisor(a). Revisar pestaña de Participantes.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
      else if (this.participantes.length==2){
        if(this.participantes[0]["participant_type_id"]==this.participantes[1]["participant_type_id"]){
            this.$buefy.dialog.alert({
              message: 'Se debe ingresar un Jefe de Proyecto(a) y un Supervisor(a). Revisar pestaña de Participantes.',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
        }
        else{
          this.is_validated_participant = true;
        }
      }
      else{
        this.is_validated_participant = true;
      }
    },
    execAddModal(id){
      this.selected = id;
      this.isModalActive = true;
    },
    execAddDocumentModal(id){
      this.selected = id;
      this.isModalDocumentActive = true;
    },
    onClickChild(value){
      this.isLoading = true
      this.participantes = value
      this.isLoading = false

    },
    onClickChildEntity(value){
      this.isLoading = true
      this.searchPartnerEntities(value.name)
      this.entidad_name = value.name
      this.project.partner_entity_id = value.id
      this.isLoading = false
    },
    onClickChildDocument(value){
      this.isLoading = true
      value.fecha = moment(value.fecha, ["YYYY-MM-DD","DD-MM-YYYY"]);
      this.documentos.push(value)
      this.isLoading = false
    },
    getService(){
      let vm = this;
      vm.isLoading=true;
      return axios
          .get("/sdt_services/"+this.serviceId+".json"+"?type="+this.serviceTable)
          .then(res => {
            vm.project = res.data;

            if(vm.project.participants!= null){
              vm.participantes = vm.project.participants
            }
            if(vm.project.participants== ""){
              vm.participantes = []
            }
            if (vm.project.start_date)
              vm.project.start_date = new Date(moment(vm.project.start_date).format('MM/DD/YYYY'));
            if (vm.project.end_date)
              vm.project.end_date = new Date(moment(vm.project.end_date).format('MM/DD/YYYY'));
            if (vm.participantes.filter(person => person.person_id == vm.project.ownerId).length > 0){
              vm.ownerId = vm.project.ownerId
            }
            if(vm.project.partner_entity)
            {
              vm.entidad_name = vm.project.partner_entity_object.name
            }
            vm.activeStep= 0;
            if(vm.showParticipantsFirst){
              vm.activeStep=1;
            }
            vm.isLoading=false;
          })
          .catch(e => {
            console.log(e);
            vm.isLoading=false;
          });
    },
    deleteParticipant(participant){
      if(participant.person_id == this.ownerId){
        this.ownerId = null
      }
      this.participantes = this.participantes.filter(person => person.person_id != participant.person_id);
    },
    isUpdatingAndDeletingParticipant(row){
      if(this.queryType=="create"){
        return true;
      }
      else if(this.queryType=='update'){
        return this.project.can_edit;
      }

    },
    deleteParticipantButton(participant){

      this.$buefy.dialog.confirm({
        title: 'Eliminar Participante',
        message: 'Precaución, esta acción eliminará la asociación con la/el responsable con el servicio de EFUSACH, lo que implica que ya no podrá ver esta información ¿Desea continuar?',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteParticipant(participant)
      })

    },
  }
}
</script>

<style scoped>

  .right-button{
    margin-left: auto;
  }
  .left-button{
    margin-right: auto;
  }


</style>