<template>
<section>
  <div class="columns">
    <div class="column is-12">
      <div class="columns">
        <div class="column">
          <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
            <b-message type="is-danger" has-icon>
              <div v-for="(item,index) in errores_validacion" :key='index'>
                <div v-if="item">{{ item[0] }}</div>
              </div>
            </b-message>
          </div>
        </div>
      </div>
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <form @submit.prevent="savePostulatedProject">
          <div class="box">
            <b-steps
                v-model="activeStep"
                :animated="isAnimated"
                :rounded="isRounded"
                :has-navigation="hasNavigation"
                :icon-prev="prevIcon"
                :icon-next="nextIcon"
                :label-position="labelPosition"
                :mobile-mode="mobileMode">
              <b-step-item step="1" label="General" :clickable="isStepsClickable">
                <h1 class="title has-text-centered">General [1/2]</h1>

                <div class="columns">
                  <div class="column is-4 is-offset-8">
                    <b-switch v-model="requiredFieldOnly">Mostrar solo campos obligatorios</b-switch>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Nombre del Proyecto*">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-input v-model="project.name" type="text"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column" v-if="tipos_proyecto.length>0">
                    <b-field label="Tipo de Proyecto*">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <multiselect v-model="project.project_type_id"
                                     :options="tipos_proyecto.map(type => type.id)"
                                     :custom-label="opt => tipos_proyecto.find(x => x.id == opt).name"
                                     placeholder="Seleccione tipo de proyecto"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel="Seleccionado"
                                     deselectLabel="No se puede deseleccionar"
                                     :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                                  No se encontraron elementos.
                              </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field>
                      <template #label>
                        Código*
                        <b-tooltip
                            label="Esté código será utilizado como identificador único en caso de que haya alcance de nombre. Se sugiere ingresar código otorgado
                          por entidad auspiciante, por ejemplo el código otorgado por la universiad o por ANID"
                            position="is-right"
                            size="is-medium"
                            multilined>

                          <b-icon
                              pack="fas"
                              icon="info-circle"
                              size="is-small"
                              type="is-info">
                          </b-icon>
                        </b-tooltip>
                      </template>
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <b-input type="text" v-model="project.code"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column" v-if="!requiredFieldOnly">
                    <b-field label="Fecha de Postulación">
                      <ValidationProvider v-slot="{ errors }">
                        <b-datepicker
                            v-model="project.postulation_date"
                            icon="calendar-alt"
                            trap-focus
                            locale="es-ES"
                            editable
                            placeholder="Formato de Fecha: dd/mm/aaaa">
                        </b-datepicker>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Rol de la Universidad*">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect v-model="project.university_project_role"
                                     :options="university_project_roles"
                                     :custom-label="opt => opt.alt_name ? opt.alt_name : opt.name"
                                     placeholder="Seleccione rol de la universidad"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel="Seleccionado"
                                     deselectLabel="No se puede deseleccionar"
                                     :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                          No se encontraron elementos.
                      </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>

                <div class="columns" v-if="!requiredFieldOnly">
                  <div class="column">
                    <b-field label="Link carta firmada por la/el Decana/o">
                      <b-input maxlength="255" v-model="project.postulation_letter_link"></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns" v-if="!requiredFieldOnly">
                  <div class="column">
                    <b-field label="Link Documentación">
                      <b-input maxlength="255" v-model="project.anid_letter_link"></b-input>
                    </b-field>
                  </div>
                </div>

              </b-step-item>
              <b-step-item step="2" label="Participantes" :clickable="isStepsClickable">
                <h1 class="title has-text-centered">Participantes [2/2]</h1>

                <b-tooltip label="En esta sección debe ingresar las(os) participantes del proyecto que
                              se encuentren registrados en el sistema, de esta manera, los datos ingresados
                               de este proyecto quedarán asociados a esta persona."
                           position="is-right"
                           size="is-medium"
                           multilined>
                  <b-icon
                      pack="fas"
                      icon="info-circle"
                      size="is-small"
                      type="is-info">
                  </b-icon>
                </b-tooltip>
                <div class="is-pulled-right">
                  <b-button  class="mt-2" @click.prevent="execAddModal(project.id)"
                             type="is-primary">Administrar Participantes</b-button>
                </div>

                <div class="column">
                  <h3 class="is-size-4 has-text-weight-bold">Tabla Resumen Participantes</h3>
                  <b-table
                      :data="participantes"
                      :paginated=true
                      per-page=5
                      striped
                      hoverable
                      :current-page="currentPage"
                      :card-layout="cardLayout"
                      :pagination-simple=false
                      pagination-position="bottom"
                      default-sort-direction="desc"
                      sort-icon="arrow-up"
                      sort-icon-size="is-small"
                      default-sort="last_patent_state_date"
                      aria-next-label="Siguiente"
                      aria-previous-label="Anterior"
                      aria-page-label="Página"
                      aria-current-label="Página actual">
                    <b-table-column
                        field= 'name'
                        label= 'Nombres'
                        width="13em"
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{  props.row.name }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field= 'corporate_mail'
                        label= 'Correo Corporativo'
                        width="15em"
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{  props.row.corporate_mail }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field= 'unit'
                        label= 'Unidad'
                        width="15em"
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{  props.row.unit }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        label="Tipo de Participación*"
                        v-slot="props">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <multiselect v-model="props.row.participant_type_id"
                                     :options="tipos_participacion.map(x => x.id)"
                                     :custom-label="opt => tipos_participacion.find(x => x.id == opt).name"
                                     placeholder="Seleccione tipo de participación"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel="Seleccionado"
                                     deselectLabel="No se puede deseleccionar"
                                     :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                    No se encontraron elementos.
                  </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-table-column>

                    <b-table-column
                        label="Acción"
                        width="10"
                        v-slot="props">
                      <b-icon
                          pack="fas"
                          style="color: #e50000;"
                          icon="trash"
                          size="is-medium"
                          @click.native="deleteParticipantButton(props.row)"/>
                    </b-table-column>

                    <template slot="empty" slot-scope="props">
                      <div class="columns is-centered">
                        <div class="column is-6 is-centered has-text-centered">
                          <b-message type="is-danger">
                            No se encontraron participantes asociados.
                          </b-message>
                        </div>
                      </div>
                    </template>
                  </b-table>
                </div>

              </b-step-item>
              <template
                  #navigation="{previous, next}">
                <div class="columns is-pulled-right" >
                  <div class="column">
                    <b-button
                        outlined
                        icon-left="chevron-left"
                        :disabled="previous.disabled"
                        @click.prevent="previous.action">
                    </b-button>
                    <b-button
                        outlined
                        icon-right="chevron-right"
                        :disabled="next.disabled"
                        @click.prevent="next.action">
                    </b-button>

                  </div>
                </div>

              </template>
            </b-steps>

            <div class="actions has-text-right">
              <button v-if="modalCancel" class="button is-danger" @click.prevent="$emit('close')">Cancelar</button>
              <button class="button is-primary" type="submit">Guardar Proyecto</button>
            </div>
          </div>

        </form>
      </ValidationObserver>
    </div>
  </div>
  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  <b-modal
      v-model="isModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :can-cancel="canCancel">
    <template #default="props">
      <modal-participant-project :project-id="project.id"
                                 :id="selected"
                                 :participantes="participantes"
                                 query-type="update"
                                 modal-header="Añadir Participantes"
                                 @close="props.close"
                                 @clicked="onClickChild"
      >
      </modal-participant-project>
    </template>
  </b-modal>
</section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import ModalParticipantProject from "./modal_participant_project";
export default {
  name: "PostulatedProjectForm",
  components:{ ModalParticipantProject },
  data() {
    return {
      activeStep: 0,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      hasNavigation: true,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      errores_validacion: {},
      isLoading:false,
      requiredFieldOnly:false,
      currentPage:1,
      cardLayout:false,
      isModalActive:false,
      canCancel: ['escape'],
      project:{},
      programs:[],
      program:{},
      partner_entities:[],
      partner_entity:{},
      isFetching:false,
      university_project_roles:[],
      participantes:[],
      tipos_participacion:[],
      tipos_proyecto:[],
      validated:false,
    }
  },
  props:[
    'modalHeader','hideHeader','queryType','projectId','modalCancel', "cargoPrincipal", "unidadPrincipal"
  ],
  async created() {
    if (this.activeModal)
      this.isCardModalActive = true;
    await this.getFormData();
    if (this.projectId && this.queryType=="update"){
      this.project=this.projectId;
      this.participantes = this.projectId.participants;

    }

  },
  watch: {
    partner_entity: function (val){
      if(this.partner_entity){
        this.searchPrograms();
      }
    }
  },
  methods:{
    async savePostulatedProject(){
      let vm = this;
      vm.isLoading=true;
      await vm.checkValidation();
      var retorno = [];
      if (vm.participantes){
        vm.project.participants_attributes = vm.participantes
        retorno = JSON.parse(JSON.stringify(vm.project.participants_attributes));
      }
      if (vm.project.university_project_role){
        vm.project.university_project_role_id = vm.project.university_project_role.id;
      }
      if (vm.queryType==="create" && vm.validated) {
        axios
            .post("/projects/new/postulated.json", {
              project: vm.project,
              participants: retorno.map(function(x) {
                delete x.unit_id;
                delete x.unit_participants;
                return x;
              }),
            })
            .then(response => {
              vm.errores_validacion = {};
              window.location.href = '/projects/postulated/' +response.data["id"]
              vm.isLoading=false;
            }).
            catch(error => {
              vm.errores_validacion = error.response.data
              var elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              vm.isLoading=false;
            })
      }
      if (vm.queryType==="update" && vm.validated) {
        //AGREGAR ASOCIACIÓN ENTRE PART ENTY Y PROGRAMA
        axios
            .put("/projects/postulated/"+vm.project.id+".json", {
              project: vm.project,
              participants: retorno.map(function(x) {
                delete x.unit_id;
                delete x.unit_participants;
                return x;
              }),
            })
            .then(response => {
              vm.errores_validacion = {};
              //window.location.href = '/projects/' +response.data["id"]
              vm.$buefy.dialog.alert({
                message: 'El proyecto postulado fue guardado satisfactoriamente.',
                type: 'is-success',
                hasIcon: true,
                icon: 'check-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              vm.$emit('update')
              vm.$emit('close');
              vm.isLoading=false;
            }).
            catch(error => {
              vm.errores_validacion = error.response.data
              var elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              vm.isLoading=false;
            })
      }
      if(!vm.validated){
        vm.isLoading=false;
      }

    },
    searchPartnerEntities(name){
      if (name.length<3) {
        this.partner_entities = []
        return
      }
      let vm = this;
      vm.isLoading=true;
      vm.isFetching = true;
      axios
          .get("/admin/partner_entities/financings-search.json", { params: { name: name } })
          .then(res => {
            vm.partner_entities = res.data;
            vm.isLoading = false;
            vm.isFetching = false;
          })
          .catch(error => {
            console.log(error)
            vm.isLoading=false;
            vm.isFetching = false;
          })

    },
    searchPrograms(){
      let vm = this;
      if (vm.partner_entity.id){
        vm.isLoading = true;
        axios
            .get("/partner_entities/"+vm.partner_entity.id+"/programs.json")
            .then(res => {
              vm.programs = res.data;
              vm.isLoading=false;
            })
            .catch(e => {
              console.log(e);
              vm.isLoading=false;
            });
      }

    },
    async checkValidation() {
      this.validated = false;
      const valid = await this.$refs.observer.validate()
      let error_msg = 'Faltan algunos campos o presentan errores de formato. Por favor revisar las pestañas General y Participantes del formulario.'
      if(this.participantes.length<1){
        error_msg = 'Faltan algunos campos o presentan errores de formato. Por favor revisar las pestañas General y Participantes del formulario.<br> Recuerde añadir al menos un/a participante en la pestaña Participantes de este formulario'
      }
      if (!valid) {
        this.$buefy.dialog.alert({
          message: error_msg,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
      else{
        this.validated = true;
      }
    },
    execAddModal(id){
      this.selected = id;
      this.isModalActive = true;
    },
    onClickChild(value){
      this.isLoading = true
      this.participantes = value
      this.isLoading = false
    },
    deleteParticipant(participant){
      this.participantes = this.participantes.filter(person => person.person_id != participant.person_id);
    },
    deleteParticipantButton(participant){

      this.$buefy.dialog.confirm({
        title: 'Eliminar Participante',
        message: 'Precaución, al momento de guardar el proyecto esta acción eliminará la asociación con la/el participante, lo que implica que ya no podrá ver este proyecto ni será contado en su actividad académica ¿Desea continuar?',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteParticipant(participant)
      })

    },
    getProject(){
      let vm = this;
      vm.isLoading=true;
      return axios
          .get("/projects/"+vm.projectId+".json")
          .then(res => {
            vm.project = res.data;

            if(vm.project.participants!= null){
              vm.participantes = vm.project.participants
            }
            if(vm.project.participants== ""){
              vm.participantes = []
            }
            if (vm.project.start_date)
              vm.project.start_date = new Date(moment(vm.project.start_date).format('MM/DD/YYYY'));
            if (vm.project.end_date)
              vm.project.end_date = new Date(moment(vm.project.end_date).format('MM/DD/YYYY'));
            vm.activeStep= 0;
            vm.isLoading=false;
          })
          .catch(e => {
            console.log(e);
            vm.isLoading=false;
          });
    },
    async getFormData(){
      let vm = this;
      vm.isLoading=true;
      return axios
          .get("/projects/new/postulated.json")
          .then(res => {
            vm.university_project_roles = res.data.university_project_roles;
            vm.tipos_participacion = res.data.participation_types;
            vm.tipos_proyecto = res.data.project_types;
            vm.isLoading=false;
          })
          .catch(e => {
            console.log(e);
            vm.isLoading=false;
          });
    },
  }
}
</script>

<style scoped>

</style>