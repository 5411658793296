<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
        <b-message type="is-danger" has-icon>
          <div v-for="(item,index) in errores_validacion">
            {{ item[0] }}
          </div>
        </b-message>
      </div>
      <form @submit.prevent="handleSubmit(savePerson)">
        <!-- Box de Formulario Persona -->
        <div class="box">
          <div class="columns">
            <div class="column">
              <b-field label="Nombres*">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-input v-model="person.name" type="text"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Primer Apellido*">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-input v-model="person.last_name" type="text"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Segundo Apellido*">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-input v-model="person.second_surname" type="text"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-3">
              <b-field label="Rut*">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-input v-model="person.run_attributes.rut"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column is-1">
              <b-field label="DV*">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-input v-model="person.run_attributes.dv"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Fecha de nacimiento*">
                <ValidationProvider rules="required" v-slot="{errors}">
                  <b-datepicker
                      v-model="person.birthday_date"
                      placeholder="Fecha de nacimiento"
                      icon="calendar-alt"
                      trap-focus>
                  </b-datepicker>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Género">
                <multiselect v-model="person.gender_id"
                             :options="genders.map(type => type.id)"
                             :custom-label="opt => genders.find(x => x.id === opt).name"
                             placeholder="Seleccione género"
                             selectLabel="Presione para seleccionar"
                             selectedLabel="Seleccionado"
                             deselectLabel="No se puede deseleccionar"
                             :allow-empty="false">
                  <template v-slot:noOptions>
                    No existen datos
                  </template>
                </multiselect>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Región">
                <ValidationProvider rules="required" v-slot="{errors}">
                  <multiselect v-model="person.region_id"
                               :options="regions.map(type => type.id)"
                               :custom-label="opt => regions.find(x => x.id === opt).name"
                               @input="loadCommunes(person.region_id)"
                               placeholder="Seleccione Región"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                  </multiselect>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Comuna">
                <ValidationProvider rules="required" v-slot="{errors}">
                  <multiselect v-model="person.commune_id"
                               :options="communes.map(type => type.id)"
                               :custom-label="opt => communes.find(x => x.id === opt).name"
                               placeholder="Seleccione Comuna"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                  </multiselect>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Dirección">
                <b-input v-model="person.address"></b-input>
              </b-field>
            </div>
<!--            <div class="column">-->
<!--              <b-field label="Lugar de nacimiento">-->
<!--                <b-input v-model="person.birthplace"></b-input>-->
<!--              </b-field>-->
<!--            </div>-->
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Institución de previsión">
                <ValidationProvider rules="required" v-slot="{errors}">
                  <multiselect v-model="person.pension_institution_id"
                               :options="pension_institutions.map(type => type.id)"
                               :custom-label="opt => pension_institutions.find(x => x.id === opt).name"
                               placeholder="Seleccione Institución de previsión"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                  </multiselect>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Institución de salud">
                <ValidationProvider rules="required" v-slot="{errors}">
                  <multiselect v-model="person.health_institution_id"
                               :options="health_institutions.map(type => type.id)"
                               :custom-label="opt => health_institutions.find(x => x.id === opt).name"
                               placeholder="Seleccione Institución de salud"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                  </multiselect>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Nacionalidad">
                <multiselect v-model="person.nationality_id"
                             :options="countries.map(type => type.id)"
                             :custom-label="opt => countries.find(x => x.id === opt).name"
                             placeholder="Seleccione nacionalidad"
                             selectLabel="Presione para seleccionar"
                             selectedLabel="Seleccionado"
                             deselectLabel="No se puede deseleccionar"
                             :allow-empty="false">
                  <template v-slot:noOptions>
                    No existen datos
                  </template>
                </multiselect>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Estado civil">
                <multiselect v-model="person.civil_status"
                             :options="marital_statuses.map(type => type.name)"
                             placeholder="Seleccione Estado civil"
                             selectLabel="Presione para seleccionar"
                             selectedLabel="Seleccionado"
                             deselectLabel="No se puede deseleccionar"
                             :allow-empty="false">
                  <template v-slot:noOptions>
                    No existen datos
                  </template>
                </multiselect>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Teléfono*">
                <ValidationProvider rules="integer|positive|required" v-slot="{errors}">
                  <b-input v-model="person.phone"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Teléfono alternativo*">
                <ValidationProvider rules="integer|positive|required" v-slot="{errors}">
                  <b-input v-model="person.cellphone"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Email personal*">
                <ValidationProvider rules="email|required" v-slot="{errors}">
                  <b-input type="text" v-model="person.personal_mail"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Email corporativo*">
                <ValidationProvider rules="email|required" v-slot="{errors}">
                  <b-input type="text" v-model="person.corporate_mail"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column">
              <b-field label="¿Está jubilado(a)?">
                <b-checkbox label="" v-model="person.retired">
                </b-checkbox>
              </b-field>
            </div>
          </div>
          <!-- GUARDAR PERSONA-->
          <div class="actions has-text-right">
            <button class="button is-primary" type="submit">Guardar Persona</button>
          </div>

        </div>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios"
export default {
  name: "form_person",
  data(){
    const data =[]
    return {
      person:{
        run_attributes:{
          rut: null,
          dv: null
        },
        region_id: null,
      },
      regions: [],
      genders: [],
      communes: [],
      countries: [],
      marital_statuses: [
        {name: "Soltero/a" },
        {name: "Casado/a" },
        {name: "Viudo/a" },
        {name: "Divorciado/a" },
        {name: "Conviviente civil" }
      ],
      pension_institutions: [],
      health_institutions: [],
      errores_validacion: {},
      isLoading:false,
    }
  },
  props:[
    'userId', 'queryType', 'personId'
  ],
  created(){
    this.isLoading=true;
    if (this.queryType ==="create"){
      axios
          .get("/people/new.json")
          .then(res => {
            this.regions = res.data["regions"]
            this.genders = res.data["genders"]
            this.countries = res.data["countries"]
            this.pension_institutions = res.data["pension_institutions"]
            this.health_institutions = res.data["health_institutions"]
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })
    }
    else if (this.queryType === "update"){
     this.isLoading=true;
      axios
          .get("/people/"+this.personId+"/edit.json")
          .then(res => {
            this.person = res.data
            var date_without_format = Date.parse(res.data["birthday_date"])
            this.person.birthday_date = new Date(date_without_format)
            this.regions = res.data["regions"]
            this.genders = res.data["genders"]
            this.countries = res.data["countries"]
            this.pension_institutions = res.data["pension_institutions"]
            this.health_institutions = res.data["health_institutions"]
            this.communes = res.data["communes"]
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })
    }
    this.isLoading = false;
  },
  methods: {
    savePerson(){
      this.isLoading = true;
      if (this.queryType==="create"){
        axios
            .post("/people.json", {
              person: this.person
            })
            .then(response =>{
              this.errores_validacion = {};
              window.location.href = '/people/'+response.data["id"]+"/life_record"
              this.isLoading=false;
            }).catch(error => {
              this.errores_validacion = error.response.data
              var elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              this.isLoading=false;
            })
      }
      else if (this.queryType==="update"){
        let clean_person = this.person
        clean_person = this.deleteData(clean_person)
        axios
            .put("/people/" + this.personId+".json", {
              person:  clean_person
            })
            .then(response => {
              this.errores_validacion = {};
              window.location.href = "/people/"+this.personId+"/life_record"
              this.isLoading = false;
            }).catch(error => {
              this.errores_validacion = error.response.data
              var elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              this.isLoading=false;
            })
      }
    },
    loadCommunes(region_id){
      this.isLoading=true;
      if( region_id!==null && region_id!==undefined ){
        axios
            .get("/regions/"+region_id+"/communes.json")
            .then(res => {
              this.person.commune_id = null;
              this.communes = res.data["communes"];
              this.isLoading = false;
            })
            .catch(error => {
                console.log(error)
                this.isLoading=false;
            })
      }
    },
    deleteData(person){
      delete person.communes
      delete person.countries
      delete person.health_institutions
      delete person.pension_institutions
      delete person.genders
      delete person.regions
      delete person.id
      return person
    }
  }
}
</script>

<style scoped>

</style>