<template>
  <div>
    <section>
      <div class="modal-card" style="width: auto" id="modal-event">
        <header class="modal-card-head">
          <p class="modal-card-title">{{modalHeader}}</p>
          <button
              type="button"
              class="delete"
              @click="$emit('close')"/>
        </header>
        <section class="modal-card-body">
          <b-tabs position="is-centered" @input="changeTab">
            <b-tab-item v-for="item in items"
                        :value="item.content"
                        v-bind:key="item.content"
                        :label="item.tab"
                        :icon="item.icon">
            </b-tab-item>

          </b-tabs>
          <!-- COMPONENTE AQUI -->
          <keep-alive>
            <component v-bind:is="currentTabComponent"
                         :user-id="this.userId"
                         :cargo-principal="this.cargoPrincipal"
                         :participants="this.service.participants"
                         :integrants="this.service.integrants"
                         :service="this.service"
                         :service-id="this.serviceId"
                         :service-table="this.table"
                         ></component>
          </keep-alive>
          <!-- FIN COMPONENTE -->
        </section>
        <footer class="modal-card-foot actions">
          <div class="actions has-text-right">
            <button class="button left-button" type="button" @click="$emit('close')">Cerrar</button>
          </div>
        </footer>
      </div>
    </section>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>
<script>
import General from './ShowGeneralTab';
import Participants from './ShowParticipantsTab';
import Honorarios from './ShowHonorariosTab';
import Documents from './ShowDocumentsTab';
import axios from "axios";
import moment from "moment";
export default {
  props:[
    'serviceId', 'modalHeader', 'cargoPrincipal', 'table', 'userId'
  ],
  components:{ General, Participants , Documents, Honorarios},
  data(){
    return{
      service: {
        participants_attributes:[],
        validation_attributes:{}
      },      
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      currentTab: 'General',
      items: [
        {tab: 'General',content:'General',icon:"file-alt"},
        {tab: 'Responsables',content:'Participants',icon:"users"},
        {tab: 'Personal contratado',content:'Honorarios',icon:"file"},
        {tab: 'Documentos',content:'Documents',icon:"folder-open"},
      ]

    }
  },
  created(){
    let vm = this;
    vm.isLoading=true;
    return axios
        .get("/sdt_services/show_service_nullified/"+vm.serviceId+".json", {params:{type: vm.table,}})
        .then(res => {
          vm.service = res.data;          
          
          vm.service.integrants = vm.service.integrants.map(integrante => {
            var newIntegrante = integrante
            newIntegrante["contract"]["start_date"] = moment(integrante.contract.start_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            newIntegrante["contract"]["end_date"] = moment(integrante.contract.end_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            return newIntegrante;
          });
          if (vm.service.start_date)
            vm.service.start_date = moment(vm.service.start_date).format('MM-DD-YYYY');
          if (vm.service.end_date)
            vm.service.end_date = moment(vm.service.end_date).format('MM-DD-YYYY');

          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
        });

  },
  
  methods:{
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    changeTab: function(value){
      this.currentTab = value
    }

  },
  computed:{
    currentTabComponent: function () {
      return this.currentTab
    }
  }
}
</script>