<template>
  <div class="">
      <div class="columns is-multiline">
        <div class="column has-text-centered">
          <div class="heading is-size-6">Proyectos de Asistencia técnica</div>
          <div class="title">{{ sizeAsistenciaTecnica }}</div>
        </div>
        <div class="column has-text-centered">
          <div class="heading is-size-6">Capacitación</div>
          <div class="title">{{ sizeCapacitacion }}</div>
        </div>
        <div class="column has-text-centered">
          <div class="heading is-size-6">Programas especiales</div>
          <div class="title">{{ sizeProyectoEspecial }}</div>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column has-text-centered">
          <div class="heading is-size-6">Contratos por honorarios</div>
          <div class="title">{{ sizeContratos }}</div>
        </div>
        <div class="column has-text-centered">
          <div class="heading is-size-6">Monto por honorarios</div>
          <div class="title">${{ MontoHonorario }}</div>
        </div>
      </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props:[
      'objectId','componentType','currentYear', 'minYear', 'maxYear', 'changeFilter'
  ],
  data(){
    return{
      sizeAsistenciaTecnica:0,
      sizeCapacitacion:0,
      sizeProyectoEspecial:0,
      sizeContratos:0,
      MontoHonorario:0,
      isLoading:false,
      year:new Date().getFullYear(),
      year_options:Array.from({length:(new Date().getFullYear()-2000+1)},(v,k)=>k+2000).reverse()

    }
  },
  created(){
    this.getAcademicActivity();
  },
  methods:{
    getAcademicActivity(){
      let vm = this;
      vm.isLoading=true;

      axios
          .get("/sdt_services/"+vm.objectId+"/summary_activity.json",
              {params: {
                  minYear: vm.minYear,
                  maxYear: vm.maxYear,
                  componentType: vm.componentType
                }
              })
          .then(res => {
            vm.isLoading=false;
            vm.sizeAsistenciaTecnica = res.data.sizeAsistenciaTecnica;
            vm.sizeCapacitacion = res.data.sizeCapacitacion;
            vm.sizeProyectoEspecial = res.data.sizeProyectoEspecial;
            vm.sizeContratos = res.data.sizeContratos;
            vm.MontoHonorario = Number(res.data.MontoHonorario).toLocaleString('es-ES');
            //console.log(res)
          })
          .catch(e => {
            console.log(e);
            vm.isLoading=false;
          });
    }


  },
  computed:{

  },
  watch:{
    changeFilter: function(){
      this.getAcademicActivity()
    }
  }
}
</script>

