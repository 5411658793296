<template>
    <div class="vimeactivitynewentitiesrelatedform">
        <div class="container">
            <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
            <br>
            <br>
            <div v-if='isActivityAssistancesInfoEmpty'>
                <div class="columns is-centered">
                    <h4 class="title is-4">
                        Esta actividad no presenta un registro de entidades, haga click <a @click="newEntityRelated()">Aquí</a> para añadir un nueva entidad.
                    </h4>
                </div>
            </div>
            <div v-else>
                <div class="columns">
                    <div class="column">
                        <h1 class="title">Lista de entidades</h1>
                    </div>
                    <div class="column">
                        <div class="field is-grouped is-grouped-right">
                            <div class="control">
                                <button class="button is-primary" @click="newEntityRelated()">
                                    <b-icon icon="plus"></b-icon>
                                    <span>Añadir una nueva entidad</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <b-table
                    :data="getGeneralAssistance"
                    :paginated=true
                    per-page=10
                    striped
                    hoverable
                    :current-page="currentPage"
                    :pagination-simple=false
                    pagination-position="bottom"
                    default-sort-direction="asc"
                    sort-icon="arrow-up"
                    sort-icon-size="is-small"
                    default-sort="publication.title"
                    aria-next-label="Siguiente"
                    aria-previous-label="Anterior"
                    aria-page-label="Página"
                    aria-current-label="Página actual"
                    header-class="is-primary">
                    <b-table-column 
                        label= 'Nombre'
                        centered
                        v-slot="props"
                        header-class="is-primary">
                        {{props.row.entity_name}}
                    </b-table-column>
                    <b-table-column 
                        label= 'Asiste como'
                        centered
                        v-slot="props"
                        header-class="is-primary">
                        {{props.row.assistance_type_name}}
                    </b-table-column>
                    <b-table-column centered header-class="is-primary" v-slot="props">
                        <button class="button is-danger" @click="confirmActivityAssistantDelete(props.row)">
                            <b-icon icon="times"></b-icon>
                            <span>Eliminar</span>
                        </button>
                    </b-table-column>
                </b-table>
            </div>
            <br>
            <div class="field is-grouped is-grouped-right">
                <div class="control">
                    <button class="button is-link is-primary" @click="back()">Volver</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import VimeActivityNewEntitiesRelatedModal from './vime_activity_new_entities_related_modal.vue'
export default {
    name: 'VimeActivityNewEntitiesRelatedForm',
    props:{
        vime_activity_id:{
            type: Number
        }
    },
    data(){
        return {
            // Variables para el control
            currentPage:1,
            isFullPage: true,
            isLoading: true,

            // Variables de almacenamiento temporal
            relatedEntities: [],
            modalAssistance: [],
            modalAssistanceTypes: []
        }
    },
    computed:{
        isActivityAssistancesInfoEmpty(){
            return Object.entries(this.relatedEntities).length === 0
        },
        getGeneralAssistance(){
            return this.relatedEntities
        }
    },
    beforeMount(){
        this.getVimeActivityAssistance()
        this.getAssistancesToModal()
        this.getAssistancesTypesToModal()
    },
    methods:{
        getVimeActivityAssistance(){
            axios.get('/vime_activity_assistances/getEntitiesRelatedByActivityId/'+this.vime_activity_id)
            .then(response =>{
                this.relatedEntities = response.data.assistance
            })
            .catch(e => { 
                console.log(e)
            })
        },
        getAssistancesToModal(){
            axios.get('/activity_assistances/getEntitiesInfoForModal')
            .then(response =>{
                this.modalAssistance = response.data.assistance
            })
            .catch(e => {
                console.log(e)
            })
        },
        getAssistancesTypesToModal(){
            axios.get('/vime_assistant_types/get_assistants_types/1.json')
            .then(response =>{
                this.modalAssistanceTypes = response.data
                this.isLoading=false
            })
            .catch(e => { 
                console.log(e)
                this.isLoading=false
            })
        },
        newEntityRelated(){
            this.$buefy.modal.open({
                parent: this,
                component: VimeActivityNewEntitiesRelatedModal,
                props:{
                    'vime_activity_assistances_info': this.modalAssistance,
                    'vime_assistant_types': this.modalAssistanceTypes
                },
                hasModalCard: true,
                events:{
                    'add-assistant': this.addNewAssistance
                },
                customClass: 'is-primary',
                trapFocus: true
            })
        },
        addNewAssistance(newEntityRelated){
            this.isLoading=true
            var addPersonLikeNewAssistant = true
            this.relatedEntities.forEach(function(element){
                if(element.entity_name === newEntityRelated.person_name)
                    addPersonLikeNewAssistant = false
            })
            if(addPersonLikeNewAssistant){
                axios.post('/vime_activity_assistances.json', {
                    'description': ' ',
                    'run_id': newEntityRelated.run_id, 
                    'vime_assistant_type_id': newEntityRelated.assistance_type_id,
                    'vime_activity_id': this.vime_activity_id
                })
                .then( response =>{
                    var newData = newEntityRelated
                    newData.id = response.data.id
                    this.relatedEntities.push({
                        id: newData.id,
                        assistance_type_id: newData.assistance_type_id,
                        assistance_type_name: newData.assistance_type_name,
                        entity_name: newData.person_name,
                        run_id: newData.run_id,
                        rut: newData.rut
                    })
                    this.isLoading=false
                })
                .catch(e => {
                    console.log(e)
                    this.isLoading=false
                })
            }
            else{
                this.isLoading=false
                this.$buefy.dialog.alert({
                    title: 'Advertencia: Entidad ya agregada.',
                    message: 'La entidad que tratas de añadir ya existe.',
                    confirmText: 'Aceptar'
                })
            }
        },
        confirmActivityAssistantDelete(deleteActivityRelatedEntity){
            this.$buefy.dialog.confirm({
                title: 'Eliminación de un asistente',
                message: '¿Quieres eliminar la entidad: <b>'+deleteActivityRelatedEntity.entity_name+'</b>? este cambio no puede deshacerse.',
                confirmText: 'Eliminar',
                cancelText: 'Cancelar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.deleteAssistance(deleteActivityRelatedEntity.id)
            })
        },
        deleteAssistance(id){
            axios.delete('/vime_activity_assistances/'+id+'.json')
            .then( response =>{
                console.log(response.data)
                this.relatedEntities = this.relatedEntities.filter(
                    element => element.id !== id
                )
                this.$buefy.toast.open({
                    message: 'Entidad eliminado correctamente.',
                    type: 'is-primary'
                })
            })
            .catch(e => {
                console.log(e)
            })
        },
        back(){
            this.isLoading = true
            window.location.href='/vime_activities/'+this.vime_activity_id
        }
    }
}
</script>