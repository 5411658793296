<template>
  <section>
    <h1 class="subtitle is-2 has-text-primary">
      ¿Qué información hay en Estudios?
    </h1>
    <div class="mb-3 ">
      <p>En esta sección se listan los estudios que haya informado el académico a Oficina Administrativa.
        Actualmente se registran títulos universitarios, técnicos, documentación asociada a enseñanza básica o media
        y también información de grados: licenciatura, magíster y doctorado.</p>
    </div>
    <img border="2" class="m-2" src="/tutorial_images/estudios.png" style="width:80%">
    <div class="mb-3 ">
      <p>El sistema permite ver un estudio</p>
    </div>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Paso 1
    </h2>
    <p class="my-3 ">Estar situado en la pestaña de <b>Estudios</b> </p>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Paso 2
    </h2>
    <p class="my-3 ">Presionar el botón con forma de <b>ojo</b> situado en la columna de acciones:
    </p>
    <img border="2" class="m-2" src="/tutorial_images/ver_estudios.png" style="width:80%">
    <p class="my-3 ">La acción anterior despliega un estudio y permite su visualización:
    </p>
    <img border="2" class="m-2" src="/tutorial_images/estudio.png" style="width:80%">

  </section>
</template>
<script>
export default {
  name: "what_is_study_mhv",
  data(){
    return {
    }
  },
  methods:{

  },
}
</script>