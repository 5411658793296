<template>
  <div>
    
    <br>
    <div class="box">
      <div class="columns">
        <div class="column" v-if="isFaculty=='True'">
          <div ref="chartOrigen" style="height: 25vw; width: 75vw"></div>
        </div>
        <div class="column" v-else>
          <div ref="chartOrigen" style="height: 25vw; width: 100%;"></div>
        </div>
      </div>
    </div>

    <!--

    <div class="columns is-multiline">
      <div class="column has-text-centered">
        <div class="heading is-size-6">Transacciones de SDT</div>
        <div class="title">{{ sizeTransSdt }}</div>
      </div>
      <div class="column has-text-centered">
        <div class="heading is-size-6">Transacciones de CAP</div>
        <div class="title">{{ sizeTransCap }}</div>
      </div>
      <div class="column has-text-centered">
        <div class="heading is-size-6">Transacciones de FUDE</div>
        <div class="title">{{ sizeTransFude }}</div>
      </div>
      <div class="column has-text-centered" v-if="sizeTransFueo>0">
        <div class="heading is-size-6">Transacciones de FUEO</div>
        <div class="title">{{ sizeTransFueo }}</div>
      </div>
    </div>
    -->

    <br>
    <br>
    <div class="box">
      <div class="columns">
        <div class="column" v-if="isFaculty=='True'">
          <div ref="chartMontos" style="height: 25vw; width: 75vw"></div>
        </div>
        <div class="column" v-else>
          <div ref="chartMontos" style="height: 25vw; width: 100%"></div>
        </div>
      </div>
    </div>
    <!--
    <div class="columns is-multiline">
      <div class="column has-text-centered">
        <div class="heading is-size-6">Transacciones categoría Bien</div>
        <div class="title">{{ originBien }}</div>
      </div>
      <div class="column has-text-centered">
        <div class="heading is-size-6">Transacciones categoría Servicio</div>
        <div class="title">{{ originServicio }}</div>
      </div>
      <div class="column has-text-centered">
        <div class="heading is-size-6">Transacciones categoría Contratación</div>
        <div class="title">{{ originContratacion }}</div>
      </div>
    </div>
    -->
    <div class="box">
      <div class="columns">
        <div class="column" v-if="isFaculty=='True'">
          <div ref="chartTipoBien" style="height: 25vw; width: 75vw"></div>
        </div>
        <div class="column" v-else>
          <div ref="chartTipoBien" style="height: 25vw; width: 100%"></div>
        </div>
      </div>
    </div>
    <div class="box">    
      <div class="columns">
        <div class="column" v-if="isFaculty=='True'">
          <div ref="chartTipoContratacion" style="height: 25vw; width: 75vw"></div>
        </div>
        <div class="column" v-else>
          <div ref="chartTipoContratacion" style="height: 25vw; width: 100%"></div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="columns">
        <div class="column" v-if="isFaculty=='True'">
          <div ref="chartTipoServicio" style="height: 25vw; width: 75vw"></div>
        </div>
        <div class="column" v-else>
          <div ref="chartTipoServicio" style="height: 25vw; width: 100%"></div>
        </div>
      </div>
    </div>


    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>
<script>
import axios from "axios";
import * as echarts from 'echarts';

export default {
  props:[
      'unitId','currentYear', 'minYear', 'maxYear', 'changeFilter','isFaculty', 'entidades', 'entidadesSel'
  ],
  data(){
    return{
      sizeTransSdt:0,
      sizeTransCap:0,
      sizeTransFude:0,
      sizeTransFueo: 0,
      montoBien: 0,
      montoServicio: 0,
      montoContratacion: 0,
      nombresTipoBien: [],
      nombresTipoServicio: [],
      nombresTipoContratacion: [],
      montosTipoBien:[],
      montosTipoContratacion: [],
      montosTipoServicio: [],
      

      isLoading:false,
      year:new Date().getFullYear(),
      year_options:Array.from({length:(new Date().getFullYear()-2000+1)},(v,k)=>k+2000).reverse(),

      chartOrigen: null,
      chartMontos: null,
      chartTipoBien: null,
      chartTipoContratacion: null,
      chartTipoServicio: null,

      colores: [ '#16a085','#e74c3c', '#f39c12','#2c3e50', '#9b59b6', '#e67e22', '#3498db', '#d35400','#c0392b'],

    }
  },
  created(){
    let vm = this; 
    vm.getAcademicActivity()   
    
  },
  mounted() {
    this.inicializarGrafico();
  },
  methods:{
    inicializarGrafico() {
      this.chartOrigen = echarts.init(this.$refs.chartOrigen);
      this.chartMontos = echarts.init(this.$refs.chartMontos);
      this.chartTipoBien = echarts.init(this.$refs.chartTipoBien);
      this.chartTipoContratacion = echarts.init(this.$refs.chartTipoContratacion);
      this.chartTipoServicio = echarts.init(this.$refs.chartTipoServicio);
    },

    actualizarGraficoMontos() {
      const option = {
        
        title: {
            text: 'Montos por categoría de Transacciones',
            left: 'center'
        },
        color: this.colores,
        legend: {
          orient: 'vertical',
          right: '0%',
          top: 'center', 
          formatter: function (name) {
            const maxCharactersPerLine = 35;
            const lines = [];
            for (let i = 0; i < name.length; i += maxCharactersPerLine) {
              lines.push(name.substr(i, maxCharactersPerLine));
            }
            return lines.join('\n');
          },
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '----',
            type: 'pie',
            avoidLabelOverlap: false,
            tooltip: {
              trigger: 'item',
              formatter: function(params) {
                const value = Number(params.value).toLocaleString('es-ES');
                const porcentaje = params.percent + '%';
                return `${params.name} <br/>Suma de montos: $${value} (${porcentaje})`;
              }
            },
            label: {
              formatter: function(params) {
                const valueInMillions = (params.value / 1000000).toFixed(2);
                const porcentaje = params.percent + '%';
                return `$ ${valueInMillions} Mill. (${porcentaje})`;
              }
            },
            labelLine: {
              show: true
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
                fontWeight: 'bold'
              }
            },
            data: [
              { value: this.montoBien, name: 'Monto por Bienes' },
              { value: this.montoServicio, name: 'Monto por Servicios' },
              { value: this.montoContratacion, name: 'Monto por Contratación' },
            ]
          }
        ]
      };  
      this.chartMontos.setOption(option);
             
    },
    actualizarGraficoOrigen() {
      const option = {
        tooltip: {
          trigger: 'item'
        },
        color: this.colores,
        title: {
            text: 'Transacciones por entidades EFUSACH',
            left: 'center'
        },
        legend: {
          orient: 'vertical',
          right: '0%',
          top: 'center', 
          formatter: function (name) {
            const maxCharactersPerLine = 35;
            const lines = [];
            for (let i = 0; i < name.length; i += maxCharactersPerLine) {
              lines.push(name.substr(i, maxCharactersPerLine));
            }
            return lines.join('\n');
          },
        },
        series: [
          {
            name: 'Transacciones',
            type: 'pie',
            avoidLabelOverlap: false,
            label: {
              show: true,
              formatter: '{d}%',
              position: 'inside',
              fontSize: 16,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: this.sizeTransSdt, name: 'Sociedad de Desarrollo Tecnológico' },
              { value: this.sizeTransCap, name: 'Capacitación USACH' },
              { value: this.sizeTransFude, name: 'Fundación para el Desarrollo del Emprendimiento' },
              { value: this.sizeTransFueo, name: 'Fundación Escuela de Oficios' },
            ]
          }
        ]
      };  
      this.chartOrigen.setOption(option);
             
    },



    actualizarGraficoTipoBien() {




      var datosOrdenados = this.nombresTipoBien.map((nombre, index) => ({
          nombre: nombre,
          monto: this.montosTipoBien[index]
        }))
      datosOrdenados.sort((a,b) => a.monto - b.monto);

      var nombres = datosOrdenados.map(dato => dato.nombre);
      var valores = datosOrdenados.map(dato => dato.monto);
      
      var total = valores.reduce((acumulador, numero) => acumulador + numero, 0);
      var porcentajes = valores.map( monto => (monto/total)*100);

      var option = {
            
      color: this.colores,
      tooltip: {
          trigger: 'axis',
          axisPointer: {
              type: 'shadow'
          },
          formatter: function(params) {
              var dataIndex = params[0].dataIndex;
              var monto = (valores[dataIndex] / 1000000).toFixed(2); 
              var porcentaje = porcentajes[dataIndex];
              return `Monto: $ ${monto} Mill. <br>Porcentaje: ${porcentaje.toFixed(2)} %`;
          },
      },
      toolbox: {
        feature: {
            itemSize:20,
            saveAsImage:{
                pixelRatio:3,
                title:"Descargar",
                name:"Porcentaje de egresos por Unidad según tipo de Bien adquirido",
            },
        }
      },
      title: {
        text: 'Distribución de gasto de Tipo Bien',
        left: 'center'
      },
      grid: {
          left: '3%',
          right: '25%',
          bottom: '3%',
          containLabel: true,
      },
      yAxis: {
           type: 'category',
           data: nombres,
           
      },
      xAxis: {
        type: "value",
      },
      series: [{
        type: "bar",
        data: porcentajes,
        
      }]

  };

      this.chartTipoBien.setOption(option);
             
    },

    actualizarGraficoTipoContratacion() {

      var datosOrdenados = this.nombresTipoContratacion.map((nombre, index) => ({
          nombre: nombre,
          monto: this.montosTipoContratacion[index]
        }))
      datosOrdenados.sort((a,b) => a.monto - b.monto);

      var nombres = datosOrdenados.map(dato => dato.nombre);
      var valores = datosOrdenados.map(dato => dato.monto);
      
      var total = valores.reduce((acumulador, numero) => acumulador + numero, 0);
      var porcentajes = valores.map( monto => (monto/total)*100);
  
      var option = {
          
          color: this.colores,
          tooltip: {
              trigger: 'axis',
              axisPointer: {
                  type: 'shadow'
              },
              formatter: function(params) {
                  var dataIndex = params[0].dataIndex;
                  var monto = (valores[dataIndex] / 1000000).toFixed(2); 
                  var porcentaje = porcentajes[dataIndex];
                  return `Monto: $ ${monto} Mill. <br>Porcentaje: ${porcentaje.toFixed(2)} %`;
              },
          },
          title: {
            text: 'Distribución de gasto de Tipo Contratación',
            left: 'center'
          },
          toolbox: {
              feature: {
                  itemSize:20,
                  saveAsImage:{
                      pixelRatio:3,
                      title:"Descargar",
                      name:"Porcentaje de egresos por Unidad según tipo de Contratación adquirido",
                  },
              }
          },
          grid: {
              left: '3%',
              right: '25%',
              bottom: '3%',
              containLabel: true,
          },
          yAxis: {
               type: 'category',
               data: nombres,
               
          },
          xAxis: {
            type: "value",
          },
          series: [{
            type: "bar",
            data: porcentajes,
            
          }]

      }; 
      this.chartTipoContratacion.setOption(option);
             
    },

    actualizarGraficoTipoServicio() {


      var datosOrdenados = this.nombresTipoServicio.map((nombre, index) => ({
          nombre: nombre,
          monto: this.montosTipoServicio[index]
        }))
      datosOrdenados.sort((a,b) => a.monto - b.monto);

      var nombres = datosOrdenados.map(dato => dato.nombre);
      var valores = datosOrdenados.map(dato => dato.monto);
      
      var total = valores.reduce((acumulador, numero) => acumulador + numero, 0);
      var porcentajes = valores.map( monto => (monto/total)*100);
  
      var option = {
          
          color: this.colores,
          tooltip: {
              trigger: 'axis',
              axisPointer: {
                  type: 'shadow'
              },
              formatter: function(params) {
                  var dataIndex = params[0].dataIndex;
                  var monto = (valores[dataIndex] / 1000000).toFixed(2); 
                  var porcentaje = porcentajes[dataIndex];
                  return `Monto: $ ${monto} Mill. <br>Porcentaje: ${porcentaje.toFixed(2)} %`;
              },
          },
          toolbox: {
              feature: {
                  itemSize:20,
                  saveAsImage:{
                      pixelRatio:3,
                      title:"Descargar",
                      name:"Porcentaje de egresos por Unidad según tipo de Servicio adquirido",
                  },
              }
          },
          grid: {
              left: '3%',
              right: '25%',
              bottom: '3%',
              containLabel: true,
          },
          title: {
            text: 'Distribución de gasto de Tipo Servicio',
            left: 'center'
          },

          yAxis: {
               type: 'category',
               data: nombres,
               
          },
          xAxis: {
            type: "value",
          },
          series: [{
            type: "bar",
            data: porcentajes,
            
          }]

      };
      this.chartTipoServicio.setOption(option);
             
    },


    getAcademicActivity(){
      let vm = this;
      vm.isLoading=true;

      axios
          .get("/sdt_transactions/reporting/"+vm.unitId+".json",
              {params: {
                  minYear: vm.minYear,
                  maxYear: vm.maxYear,
                  entidades: JSON.stringify(vm.entidadesSel),

                }
              })
          .then(res => {
            
            vm.sizeTransSdt = res.data.sizeTransSdt;
            vm.sizeTransCap = res.data.sizeTransCap;
            vm.sizeTransFude = res.data.sizeTransFude;
            vm.sizeTransFueo = res.data.sizeTransFueo;

            //vm.originBien = res.data.originBien;
            //vm.originServicio = res.data.originServicio;
            //vm.originContratacion = res.data.originContratacion;

            vm.montoBien = res.data.montoBien;
            vm.montoServicio = res.data.montoServicio;
            vm.montoContratacion = res.data.montoContratacion;


            vm.montosTipoBien= res.data.montosTipoBien;
            vm.montosTipoServicio= res.data.montosTipoServicio
            vm.montosTipoContratacion= res.data.montosTipoContratacion
            vm.nombresTipoBien= res.data.tipoBien
            vm.nombresTipoServicio= res.data.tipoServicio
            vm.nombresTipoContratacion= res.data.tipoContratacion


            vm.actualizarGraficoOrigen();
            vm.actualizarGraficoMontos();
            vm.actualizarGraficoTipoBien();
            vm.actualizarGraficoTipoContratacion();
            vm.actualizarGraficoTipoServicio();
            vm.isLoading=false;
            //console.log(res)
          })
          .catch(e => {
            console.log(e);
            vm.isLoading=false;
          });
    }


  },
  computed:{

  },
  watch:{
    changeFilter: function(){
      this.getAcademicActivity()
    }
  }
}
</script>