<template>
  <section>
    <div class="container">

        <b-tabs @input="changeTab" :destroy-on-hide="true" v-model="currentTab" position="is-centered" type="is-toggle">
          <b-tab-item v-for="item in items"
                      :value="item.content"
                      v-bind:key="item.content"
                      :label="item.tab"
                      :visible="(item.content ==='NullStudyTabs') ? can_list_null === 'true' : true">
          </b-tab-item>
        </b-tabs>
        <!-- COMPONENTE AQUI -->

        <component v-bind:is="currentTabComponent" :person-id="personId" :can_create_study="can_create_study"
                   :person_name="person_name" :can_create_continuing_study="can_create_continuing_study" :can_list_null="can_list_null"></component>

        <!-- FIN COMPONENTE -->

    </div>
  </section>
</template>
<script>
import Studies from "./studies"
import NullStudies from "./null_studies"
import ContinuingStudies from "./continuing_study/continuing_studies"
import NullContinuingStudies from "./continuing_study/null_continuing_studies"
import NullStudyTabs from './continuing_study/null_study_tabs'
import EventBus from "../eventBus";
export default {
  name: "study_tabs",
  props:[
    'personId', 'can_create_study', 'can_list_null', 'person_name', 'can_create_continuing_study'
  ],
  data(){
    return {
      currentTab: 'Studies',
      items: [
        {tab: 'Títulos y Grados',content:'Studies'},
        {tab: 'Educación Continua',content:'ContinuingStudies'},
        {tab: "Anulaciones", content: "NullStudyTabs"}
      ],
    }
  },
  components:{
    Studies,
    NullStudies,
    ContinuingStudies,
    NullContinuingStudies,
    NullStudyTabs
  },
  methods:{
    changeTab: function(value){
      this.currentTab = value
    },
    selectTab(tab){
      this.currentTab = tab
    },
  },
  computed: {
    currentTabComponent: function () {
      return this.currentTab
    },
  },
}
</script>