<template>
  <section>
    <div v-if="(patent.can_edit && patent.academic_activity_state_id == 1) || (patent.can_edit && patent.academic_activity_state_id == null)" class="columns is-centered">
      <div v-if="patent.patent_states_attributes != null && patent.patent_states_attributes.length > 0" class="column">
        <div v-for="(estado,index) in patent.patent_states_attributes" :key="index">
          <div class="columns">
            <div class="column">
              <b-field label="Estado de Patente">
                <ValidationProvider rules="required" v-slot="{errors}">
                  <multiselect v-model="patent.patent_states_attributes[index].patent_state_id"
                               :options="estados_patente.map(type => type.id)"
                               :custom-label="opt => estados_patente.find(x => x.id === opt).name"
                               placeholder="Seleccione Estado de Patente"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                      No se encontraron elementos.
                    </span>
                  </multiselect>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Fecha de estado de Patente">
                <ValidationProvider rules="required" v-slot="{errors}">
                  <b-datepicker
                      v-model="patent.patent_states_attributes[index].hps_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa">
                  </b-datepicker>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>

          <div v-if="isUpdatingAndDeletingParticipant(false)">
            <b-button @click.prevent="deleteState(index)" type="is-danger">Eliminar Estado de Patente</b-button>
            <br>
          </div>

        </div>
        <b-button v-if="isUpdatingAndDeletingParticipant(false)" class="mt-2" @click.prevent="addState" type="is-primary">Añadir Estado de Patente</b-button>
        <div v-if="isUpdatingAndDeletingParticipant(false)" class="actions has-text-right">
          <button class="button is-primary" @click.prevent="savePatent" type="is-primary">Guardar Cambios</button>
        </div>
      </div>
      <div v-else class="column">
        <b-button class="mt-2" @click.prevent="addState" type="is-primary">Añadir Estado de Patente</b-button>
      </div>
    </div>
    <div v-else class="columns is-centered">
      <div class="column">
        <div v-for="(estado,index) in patent.patent_states_attributes">
          <div class="columns">
            <div class="column">
              <b-field label="Estado de Patente">
                <b-input readonly v-model="patent.patent_states_attributes[index].patent_state"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Fecha de estado de Patente">
                <b-input readonly v-model="patent.patent_states_attributes[index].hps_date">
                </b-input>
              </b-field>
            </div>
          </div>

        </div>
      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "PatentStateTab",
  props:['patent','patentId','estados_patente','tipos_patente','userId'],
  data(){
    return{
      isLoading: false,
    }
  },
  created() {
    if (this.patent.patent_states_attributes.length == 0){
      this.patent.patent_states_attributes.push({
        patent_state_id:null,
        hps_date:null,
      })
    }
  },
  methods:{
    addState(){
      this.patent.patent_states_attributes.push({
        patent_state_id:null,
        hps_date:null,
      })
    },
    deleteState(index){
      this.patent.patent_states_attributes.splice(index,1)
      if (this.patent.patent_states_attributes.length == 0){
        this.patent.patent_states_attributes.push({
          patent_state_id:null,
          hps_date:null,
        })
      }
    },
    savePatent(){
      this.isLoading=true;
      axios
          .put("/patents/"+this.patentId+".json", {
            patent: this.patent,
            user_id: this.userId,
            data_source: this.patent.validation_attributes.data_source,
            ownerId: this.patent.ownerId,
          })
          .then(response =>{
            this.errores_validacion = {};
            window.location.href = '/patents/'+this.patentId
            this.isLoading=false;
          }).catch(error => {
        this.errores_validacion = error.response.data
        var elmnt = document.getElementById("app");
        elmnt.scrollIntoView();
        this.isLoading=false;
      })
    },
    isUpdatingAndDeletingParticipant(row){
      return this.patent.is_creator;
    },
  }
}
</script>

