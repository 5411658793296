<template>
  <b-modal v-model="isActive" :can-cancel="[]" :esc="false" has-modal-card>
    <div class="modal-card modal-width">
      <header class="modal-card-head">
        <div class="is-flex-grow-1 p-1">
          <p class="title is-size-4 mb-0">
            {{ modalTitle }}: Solicitud de permiso administrativo
          </p>
          <p class="is-size-6 mb-1">
            {{ modalSubtitle }}
          </p>
          <p class="subtitle is-size-5 mt-0">
            {{ formatedPersonName }}
            <span class="is-size-6 has-text-weight-medium ml-2">
              (RUT: {{ person.run }})
            </span>
          </p>
        </div>
        <button class="delete" aria-label="close" @click="closeModal"></button>
      </header>
      <section class="modal-card-body">
        <div class="columns is-flex-direction-column">
          <AdministrativePermitsDocumentCard
            v-if="!createMode"
            :id="document.id"
            :request-date="formatDateToLocaleString(document.request_date)"
            :url="document.document_url"
            class="column p-0 m-0"
          />
          <AdministrativePermitsPersonDaysCard
            v-if="createMode"
            title="Situación actual"
            :used-days="parseFloat(usedDays(new Date().getFullYear()))"
            class="column"
          />
          <AdministrativePermitsPersonDaysCard
            v-if="createMode && hasPermitsOnNextYear"
            title="Situación futura"
            :used-days="parseFloat(usedDays(new Date().getFullYear() + 1))"
            :current-year="new Date().getFullYear() + 1"
            class="column"
          />
          <div class="column">
            <AdministrativePermitsTableDetails
              v-if="!createMode && !editStatusMode && !editDatesMode"
              table-title="Detalle de días solicitados"
              :permits="permits"
            />
            <AdministrativePermitsTableDetails
              v-if="editStatusMode"
              edit-mode
              :edit-status="editStatusMode"
              table-title="Detalle de días solicitados"
              :permits="permits"
              :all-dates-saved.sync="allDatesSaved"
              :permit-states="permitStates"
              @onChangeDetected="handlePermits"
            />
            <AdministrativePermitsTableDetails
              v-if="editDatesMode"
              edit-mode
              :edit-dates="editDatesMode"
              table-title="Detalle de días solicitados"
              :permits="permits"
              :all-dates-saved.sync="allDatesSaved"
              :permit-states="permitStates"
              @onChangeDetected="handlePermits"
            />
            <AdministrativePermitsTableDetails
              v-if="createMode"
              create-mode
              table-title="Fechas y días a solicitar"
              :all-dates-saved.sync="allDatesSaved"
              @onChangeDetected="handlePermits"
            />
          </div>
        </div>
      </section>
      <footer class="modal-card-foot is-flex is-justify-content-space-between">
        <b-button @click="closeModal">Cerrar</b-button>
        <b-button
          v-if="!createMode && !editStatusMode && !editDatesMode"
          class="is-pdf-color has-text-white"
          @click="onDownload({ documentId: document.id, personId: person.id })"
          ><i class="fas fa-file-pdf"></i> Generar solicitud</b-button
        >
        <b-button
          v-if="editStatusMode"
          :disabled="!allDatesSaved"
          type="is-success"
          icon-left="save"
          @click="
            onEditPermits({
              personId: person.id,
              editedPermits,
              documentId: document.id,
            })
          "
        >
          {{
            allDatesSaved
              ? editedPermits.length === 1
                ? "Guardar estado"
                : "Guardar estados"
              : "No han detectado cambios de estado"
          }}
        </b-button>
        <b-button
          v-if="editDatesMode"
          :disabled="!allDatesSaved || editedPermits.length === 0"
          type="is-success"
          icon-left="save"
          @click="
            onEditPermits({
              personId: person.id,
              editedPermits,
              documentId: document.id,
            })
          "
        >
          {{
            allDatesSaved
              ? "Guardar fechas"
              : "No han detectado cambios de fechas"
          }}
        </b-button>
        <b-button
          v-if="createMode"
          :disabled="!allDatesSaved || editedPermits.length === 0"
          :type="
            editedPermits.length > 0 && allDatesSaved
              ? 'is-pdf-color has-text-white'
              : 'is-danger'
          "
          :icon-left="
            editedPermits.length > 0 && allDatesSaved ? 'file-pdf' : 'save'
          "
          @click="onSavePermits"
        >
          {{
            editedPermits.length > 0 && allDatesSaved
              ? "Crear solicitud"
              : "Fechas sin Guardar"
          }}
        </b-button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import { cloneDeep } from "lodash";
import AdministrativePermitsTableDetails from "./AdministrativePermitsTableDetails.vue";
import AdministrativePermitsPersonDaysCard from "./AdministrativePermitsPersonDaysCard.vue";
import AdministrativePermitsDocumentCard from "./AdministrativePermitsDocumentCard.vue";
import { formatDateToLocaleString } from "../.././packs/utilities";

export default {
  components: {
    AdministrativePermitsTableDetails,
    AdministrativePermitsPersonDaysCard,
    AdministrativePermitsDocumentCard,
  },
  props: {
    showModal: {
      type: Boolean,
    },
    person: {
      type: Object,
      default: () => ({}),
    },
    permits: {
      type: Array,
      default: () => [],
    },
    document: {
      type: Object,
      default: () => ({}),
    },
    personUsedDays: {
      type: Number,
      default: 0,
    },
    permitStates: {
      type: Array,
      default: () => [],
    },
    personTotalDays: {
      type: Number,
      default: 0,
    },
    holidays: {
      type: Array,
      default: () => [],
    },
    adminMode: {
      type: Boolean,
    },
    editStatusMode: {
      type: Boolean,
    },
    editDatesMode: {
      type: Boolean,
    },
    createMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      isActive: this.showModal,
      allDatesSaved: false,
      editedPermits: cloneDeep(this.permits),
    };
  },
  computed: {
    formatedPersonName() {
      if (!this.person) return;
      const name = `${this.person?.name} ${this.person?.last_name} ${this.person?.second_surname}`;
      const lowercaseWords = ["de", "del", "la", "las", "los", "y"];

      return name
        .toLowerCase()
        .split(" ")
        .map((word, index) =>
          lowercaseWords.includes(word) && index !== 0
            ? word
            : word.charAt(0).toUpperCase() + word.substring(1)
        )
        .join(" ");
    },
    modalTitle() {
      if (this.createMode) return "Crear";
      if (this.editStatusMode || this.editDatesMode) return "Editar";
      return "Detalle";
    },
    modalSubtitle() {
      if (this.editStatusMode) return "(Cambio de estado)";
      if (this.editDatesMode) return "(Cambio de fechas)";
      return "";
    },
    totalDays() {
      return this.editedPermits.reduce(
        (total, permit) =>
          parseFloat(total) + parseFloat(permit.number_of_days),
        0
      );
    },
    hasPermitsOnNextYear() {
      if (this.permits.length === 0) {
        return false;
      }
      return this.permits.some((permit) => {
        return (
          permit.adm_permits.filter((p) => {
            const permitYear = new Date(p.start_date).getFullYear();
            return (
              (p.request_state.name === "APROBADO" ||
                p.request_state.name === "EN CURSO") &&
              permitYear === new Date().getFullYear() + 1
            );
          }).length > 0
        );
      });
    },
  },
  watch: {
    showModal: {
      immediate: true,
      handler(newVal) {
        this.isActive = newVal;
        if (!newVal) {
          this.allDatesSaved = false;
          this.editedPermits = [];
        }
      },
    },
  },
  methods: {
    closeModal() {
      this.isActive = false;
      this.$emit("update:showModal", false);
      this.allDatesSaved = false;
    },
    handlePermits(permits) {
      this.editedPermits = permits;
    },

    validateBeforeEmit() {
      return this.editedPermits.length === 0 || !this.allDatesSaved;
    },
    onSavePermits() {
      if (this.validateBeforeEmit()) return;
      this.$emit("create", this.editedPermits);
    },
    onEditPermits({ personId, editedPermits, documentId }) {
      if (this.validateBeforeEmit()) return;
      this.$emit("edit", { personId, editedPermits, documentId });
    },
    onDownload({ documentId, personId }) {
      this.$emit("download", { documentId, personId });
    },
    usedDays(currentYear = new Date().getFullYear()) {
      let total = 0;
      for (const request of this.permits) {
        total += request.adm_permits.reduce((total, permit) => {
          const permitYear = new Date(permit.start_date).getFullYear();
          if (
            (permit.request_state.name === "APROBADO" ||
              permit.request_state.name === "EN CURSO") &&
            permitYear === currentYear
          ) {
            return total + parseFloat(permit.number_of_days);
          }
          return total;
        }, 0);
      }
      return total;
    },
    formatDateToLocaleString,
  },
};
</script>

<style scoped>
@media (min-width: 1024px) {
  .modal-width {
    min-width: 720px;
  }
}
</style>
