<template>
  <section>
    <div class="container">
      <div class="has-text-right mb-1">
        <b-button class="is-primary is-outlined"
                  icon-left="plus"
                  v-if="can_create_commission==='true'"
                  @click="isAddModalActive = true">
          Añadir Comisión
        </b-button>
      </div>

      <!--DATA TABLE-->
      <b-table
          style="word-break: break-all"
          :data="commissions"
          :paginated= true
          per-page="10"
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="product.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">

        <b-table-column
            field="commission_type.name"
            label="Tipo Comisión"
            width="200"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.commission_type.name}}
          </template>
        </b-table-column>
        <b-table-column
            field="resolution"
            label="N° documento"
            width="120"
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.resolution ? props.row.resolution : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="nationality.name"
            label="País"
            width="150"
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.nationality ? props.row.nationality.name : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="place"
            label="Lugar"
            width="200"
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.place ? props.row.place : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="start_date"
            label="Fecha Desde"
            width="110"
            sortable
            searchable :custom-search="searchStartDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            field="end_date"
            label="Fecha Hasta"
            width="110"
            sortable
            searchable :custom-search="searchEndDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            label="Acciones"
            width="120"
            v-slot="props">
          <b-tooltip
              v-if="props.row.can_show"
              label="Ver información completa de la comisión."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="eye"
                type="is-primary"
                @click="execShowModal(props.row)"/>
          </b-tooltip>
          <b-tooltip
              v-if="props.row.can_edit"
              label="Editar información de la comisión."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="edit"
                type="is-info is-light is-outlined"
                @click="execEditModal(props.row)"/>
          </b-tooltip>
          <b-tooltip
              v-if="props.row.can_view_pdf"
              label="Visualizar documento asociado a la comisión."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="file-alt"
                type="is-success is-light is-outlined"
                @click="viewFile(props.row.document_id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_view_pdf"
              label="Descargar documento asociado a la comisión."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="download"
                type="is-primary is-light is-outlined"
                @click="downloadFile(props.row.document_id)"/>
          </b-tooltip>
        </b-table-column>
      </b-table>

      <b-modal
          v-if="canEdit"
          v-model="isAddModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <select-commission-type :person-id="personId"
                                            query-type="create"
                                            modal-header="Seleccionar Tipo de Comisión"
                                            :person_name="person_name"
                                            @close="props.close"></select-commission-type>
        </template>
      </b-modal>

      <b-modal
          v-model="isShowICC"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <modal-icc :id="selected"
                     :modal-header="'ICC - '  +person_name"
                     :person_name="person_name"
                 @close="props.close">
          </modal-icc>
        </template>
      </b-modal>

      <b-modal
          v-model="isShowStudyCommission"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <modal-study-commission :id="selected"
                                  :person_name="person_name"
                                  :modal-header="'Comisión de Estudios - '  +person_name"
                     @close="props.close">
          </modal-study-commission>
        </template>
      </b-modal>

      <b-modal
          v-model="isShowCommission"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <modal-commission :id="selected"
                            :person_name="person_name"
                            :modal-header="'Comisión - '  +person_name"
                            @close="props.close">
          </modal-commission>
        </template>
      </b-modal>

      <b-modal
          v-model="isEditModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <component  :is="formName"
                      :person-id="personId"
                      :id="selected"
                      query-type="update"
                      :modal-header="'Editar Comisión - '  +person_name"
                      :person_name="person_name"
                      @close="props.close">
          </component>
        </template>
      </b-modal>
      <b-modal
          v-model="showPdf"
          :can-cancel="true"
          :destroy-on-hide="true"
          aria-modal
          aria-role="dialog"
          has-modal-card
          trap-focus
          :width="2000"
      >
        <template #default="props">
          <pdf_view
              :src="src"
              @close="props.close"
          ></pdf_view>
        </template>

      </b-modal>
    </div>
    <b-loading :is-full-page="true"
               v-model="isLoading"
               :can-cancel="false"/>
  </section>
</template>

<script>
import axios from "axios";
import ModalIcc from "../life_record/commissions/modal_icc"
import ModalStudyCommission from "../life_record/commissions/modal_study_commission"
import ModalCommission from "../life_record/commissions/modal_commission"
import SelectCommissionType from '../life_record/select_commission_type'
import ModalIccForm from '../life_record/modal_icc_form'
import ModalCommissionForm from '../life_record/commissions/modal_commission_form'
import ModalStudyCommissionForm from '../life_record/commissions/modal_study_commission_form'
import moment from "moment";
import {searchDate, formatDate} from "../../packs/utilities";
import Pdf_view from "../performances/pdf_view.vue";

export default {
  name: "commisions",
  props:[
    'personId', 'can_create_commission', 'person_name'
  ],
  components:{
    Pdf_view,
    ModalIcc,
    ModalStudyCommission,
    ModalCommission,
    SelectCommissionType,
    ModalIccForm,
    ModalCommissionForm,
    ModalStudyCommissionForm
  },
  data(){
    return {
      selected: null,
      currentPage: 1,
      isAddModalActive: false,
      isEditModalActive: false,
      isShowModalActive: false,
      canCancel: [],
      isLoading:false,
      commissions: [],
      canEdit: true,
      isShowICC: false,
      isShowStudyCommission: false,
      isShowCommission: false,
      formName: "",
      showPdf: false,
      src:null,
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    initialize(){
      this.isLoading = true;
      return axios
          .get("/people/"+this.personId+"/commissions.json")
          .then(res => {
            this.commissions = res.data
            this.commissions = this.commissions.map(commission => {
              var newCommission = commission
              newCommission["start_date"] = moment(newCommission["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newCommission["end_date"] = moment(newCommission["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newCommission;
            });
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })
    },
    execShowModal(row){
      this.selected = row.id;
      if(row.commission_type.name==="ICC"){
        this.isShowICC = true;
        return
      }
      else if(row.commission_type.name==="COMISION DE ESTUDIOS"){
        this.isShowStudyCommission = true;
        return
      }
      else{
        this.isShowCommission = true;
        return
      }
    },
    execEditModal(row){
      this.selected = row.id;
      if(row.commission_type.name==="ICC"){
        this.formName = "ModalIccForm";
      }
      else if(row.commission_type.name==="COMISION DE ESTUDIOS"){
        this.formName = "ModalStudyCommissionForm";
      }
      else{
        this.formName = "ModalCommissionForm";
      }
      this.isEditModalActive = true;
    },
    // yyyy/mm/dd -> dd/mm/yyyy
    formatDate,
    searchStartDate(){
      return searchDate(row.start_date,input)
    },
    searchEndDate(){
      return searchDate(row.end_date,input)
    },
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            this.showPdf = true
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    downloadFile(fileId){
      this.isLoading = true;

      if (fileId == null){
        this.isLoading = false;
        return;
      }

      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(res => {
            let blob = new Blob([res.data], {
              type: "application/pdf",
            })
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = res.headers["content-disposition"]
                .split(";")[1]
                .split('"')[1];
            link.click();
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento fue descargado con exito',
              type: 'is-success',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
          .catch(e => {
            console.log(e)
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento no se pudo descargar',
              type: 'is-danger',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
    },

  },
  watch:{
    isAddModalActive: function () {
      if (!this.isAddModalActive) {
        this.initialize()
      }
    },
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
    isShowICC: function () {
      if (!this.isShowICC) {
        this.initialize()
      }
    },
    isShowCommission: function () {
      if (!this.isShowCommission) {
        this.initialize()
      }
    },
    isShowStudyCommission: function () {
      if (!this.isShowStudyCommission) {
        this.initialize()
      }
    },
  }
}
</script>
