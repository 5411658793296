<template>
  <section>
<!--    <div class="columns">
      <div class="column">
        <b-field label="Origen del dato">
          <b-input v-if="patent.creator_name!=null" readonly v-model="patent.creator_name"></b-input>
          <b-input v-else readonly></b-input>
        </b-field>
      </div>
    </div>
    <hr class="dropdown-divider" aria-role="menuitem">-->
    <div class="columns">
      <div class="column">
        <h3 class="is-size-6 has-text-weight-bold">
          Dueña(o)
          <b-tooltip label="Solo la/el usuaria/o que es Dueña o Dueño de esta actividad académica tiene permisos para editar todos sus atributos.
                    Quienes participan en la actividad académica pero no son dueños, solo pueden editar su participación."
                     position="is-right"
                     size="is-large"
                     multilined>
            <b-icon
                pack="fas"
                icon="info-circle"
                size="is-small"
                type="is-info">
            </b-icon>
          </b-tooltip>
        </h3>
        <b-field label=" ">
          <b-input v-if="patent.owner!=''" readonly v-model="patent.owner"></b-input>
          <b-input v-else readonly></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Correo">
          <b-input v-if="patent.owner_mail!=''" readonly v-model="patent.owner_mail"></b-input>
          <b-input v-else readonly></b-input>
        </b-field>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AdditionalInformationTab",
  props:['patent']
}
</script>
