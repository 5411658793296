import { render, staticRenderFns } from "./patentsTutorial.vue?vue&type=template&id=82890bc6&scoped=true"
import script from "./patentsTutorial.vue?vue&type=script&lang=js"
export * from "./patentsTutorial.vue?vue&type=script&lang=js"
import style0 from "./patentsTutorial.vue?vue&type=style&index=0&id=82890bc6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82890bc6",
  null
  
)

export default component.exports