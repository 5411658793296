<template>
    <section>
        <b-table striped :data="my_thesis" paginated per-page=10 :pagination-simple=false
            pagination-position="bottom" aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
            aria-current-label="Página actual">

            <b-table-column label="Código Carrera" field="carreer_code" width="100" sortable searchable>
                <template #searchable="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder="Buscar Código..."
                        icon="search"
                        type="number"/>
                </template>
                <template v-slot="props">
                    {{ props.row.carreer_code }}
                </template>
            </b-table-column>

            <b-table-column label="Rut Estudiante" field="student_rut" sortable searchable>
                <template #searchable="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder="Buscar Rut..."
                        icon="search"/>
                </template>
                <template v-slot="props">
                    {{ props.row.student_rut }}
                </template>
            </b-table-column>

            <b-table-column label="Nombre del Estudiante" field="student_name" sortable searchable>
                <template #searchable="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder="Buscar Estudiante..."
                        icon="search"/>
                </template>
                <template v-slot="props">
                    {{ props.row.student_name }}
                </template>
            </b-table-column>

            <b-table-column label="Tipo de Trabajo" field="thesis_type" sortable searchable>
                <template #searchable="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder="Buscar Tipo..."
                        icon="search"/>
                </template>
                <template v-slot="props">
                    {{ props.row.thesis_type }}
                </template>
            </b-table-column>

            <b-table-column label="Título del Trabajo" field="thesis_title" sortable searchable>
                <template #searchable="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder="Buscar Título..."
                        icon="search"/>
                </template>
                <template v-slot="props">
                    {{ props.row.thesis_title }}
                </template>
            </b-table-column>

            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
        </b-table>
    </section>
</template>

<script>
import axios from 'axios'
export default {
    props:[
        'personId',
    ],
    data() {
        return {
            isLoading:false,
            my_thesis: [],
        }
    },
    created() {
        this.getMyThesis()
    },
    methods: {

        async getMyThesis() {
            this.isLoading = true
            await axios
                .get('/people/my-usach-thesis.json',{ params: {
                    id: this.personId
                }})
                .then(response => {
                    this.my_thesis = response.data
                })
                .catch(error => {
                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al obtener los datos. Intente más tarde.',
                        type: 'is-danger',
                        hasIcon: true,
                        iconPack: 'fa',
                        icon: 'times-circle',
                        confirmText: 'Ok',

                    })
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
    }
}
</script>