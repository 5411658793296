<template>
  <section>
    <div class="buttons has-addons is-right" >
      <b-button icon-left="plus" v-if="canCreateDocument === 'true'" type="is-primary is-light is-outlined" @click="newPerformance()">Subir documento de desempeño</b-button>
    </div>
    <div class="buttons has-addons is-centered" v-if="componentType==='unit'">
      <b-button :type="getType(2)" @click="changeType">Documentación unidad</b-button>
      <b-button :type="getType(1)" @click="changeType">Documentación cuerpo académicos</b-button>
    </div>
    <performances-table v-bind:unit-id="this.objectId"
                        v-bind:performances="this.performances"
                        :summary="this.summary"
                        :hide_update="true"
                        :cargo_principal="cargoPrincipal"
                        :componentType="componentType"
                        :person="person"
                        ref="performances"
                        @updatePerformances="updatePerformances"></performances-table>
    <b-modal v-model:width="showForm"
             :can-cancel="true"
             :destroy-on-hide="true"
              aria-modal
              aria-role="dialog"
              has-modal-card
              trap-focus
              :width="2000"
              scroll="clip">
      <template #default="props">
        <performance-form-modal
                :query-type="'create'"
                :componentType="componentType"
                :person="person"
                :unit="unit"
                @clicked="onClickChild"
                @close="props.close">

        </performance-form-modal>
      </template>
    </b-modal>


    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  </section>
</template>

<script>
import axios from "axios";
import PerformancesTable from "./performances_table.vue"
import performanceForm from "./new_performance_form.vue";
import Pdf_view from "./pdf_view.vue";
import performanceFormModal from "./new_performance_file_modal.vue";

export default {
  props: [
      'objectId', 'componentType', 'cargoPrincipal', 'currentYear', 'minYear', 'maxYear', 'hideUpdate', 'changeFilter', 'person', 'unit', 'canCreateDocument'
  ],
  data(){
    return{
      performances:[],
      isLoading:false,
      year: new Date().getFullYear(),
      summary: true,
      showForm: false,
      link: "",
    }
  },
  components:{
    performanceFormModal,
    Pdf_view,
    PerformancesTable, performanceForm
  },
  created(){
    this.getPerformances()
  },
  mounted() {
    this.$watch(
        "$ref.performances.summary",
        (new_value, old_value) => {
          this.summary = new_value
          console.log(this.summary)
          this.getPerformances()
        }
    )
  },
  methods:{
    updatePerformances(year){
      this.year = year
      this.getPerformances()
    },
    changeType(){
      this.summary = !this.summary
      this.getPerformances()
    },
    getType(type){
      if (type==1){
        if(this.summary){
          return 'is-primary is-light'
        }
        else{
          return 'is-primary'
        }
      }
      else{
        if(this.summary){
          return 'is-primary'
        }
        else{
          return 'is-primary is-light'
        }
      }
    },
    getPerformances(){
      this.isLoading = true;
      var url = "";
      var axiosConfig = null;
      if(this.componentType == "person"){
        url = "/people/" + this.objectId + "/performances.json"
        axiosConfig = { params: {
            minYear: this.minYear,
            maxYear: this.maxYear
          }
        }
      }
      else if (this.componentType == "unit"){
        url = "/admin/units/" + this.objectId + "/performances.json"
        axiosConfig = { params: {
            minYear: this.minYear,
            maxYear: this.maxYear,
            summary: this.summary
          }
        }
      }
      axios
          .get(url, axiosConfig)
          .then(res => {
            this.isLoading = false;
            this.performances = res.data;
          })
          .catch(e => {
            console.log(e);
            this.isLoading = false;
          })
    },
    newPerformance(){
      this.showForm = true;
      this.getPerformances();
      // window.location.href="/documents/new-performance"
    },
    onClickChild(){
      this.getPerformances()
    },
  },
  computed:{

  },
  watch:{
    changeFilter: function(){
      this.getPerformances()
    },
  }
}
</script>