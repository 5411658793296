<template>
  <div>
    <section>
      <div class="box">
        <div class="container">
          <b-tabs position="is-centered" @input="changeTab">
            <b-tab-item v-for="item in items"
                        :value="item.content"
                        v-bind:key="item.content"
                        :label="item.tab"
                        :icon="item.icon">
            </b-tab-item>

          </b-tabs>
          <!-- COMPONENTE AQUI -->
            <keep-alive>
              <component v-bind:is="currentTabComponent"
                         :user-id="this.userId"
                         :cargo-principal="this.cargoPrincipal"
                         :participants="this.service.participants"
                         :integrants="this.service.integrants"
                         :service="this.service"
                         :service-id="this.serviceId"
                         :service-table="this.serviceTable"
                         @clicked="updateData"></component>
            </keep-alive>

          <!-- FIN COMPONENTE -->

        </div>
      </div>
    </section>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>
<script>
import General from './ShowGeneralTab';
import Participants from './ShowParticipantsTab';
import Honorarios from './ShowHonorariosTab';
import Documents from './ShowDocumentsTab';
import Forms from './showForms';
import axios from "axios";
import moment from "moment";
export default {
  components:{ General, Participants , Documents, Honorarios,Forms},
  props:[
    'serviceId', 'cargoPrincipal', 'userId', 'serviceTable'
  ],
  data(){
    return{
      service: {
        participants_attributes:[],
        validation_attributes:{}
      },
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      currentTab: 'General',
      items: [
        {tab: 'General',content:'General',icon:"file-alt"},
        {tab: 'Responsables',content:'Participants',icon:"users"},
        {tab: 'Personal contratado',content:'Honorarios',icon:"file"},
        {tab: 'Documentos',content:'Documents',icon:"folder-open"},
        //{tab: 'Formularios',content:'Forms',icon:"file-invoice"},
      ]
    }
  },
  created(){
    let vm = this;
    vm.isLoading=true;
    return axios
        .get("/sdt_services/"+vm.serviceId+".json"+"?type="+vm.serviceTable)
        .then(res => {
          vm.service = res.data;          
          
          vm.service.integrants = vm.service.integrants.map(integrante => {
            var newIntegrante = integrante
            newIntegrante["contract"]["start_date"] = moment(integrante.contract.start_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            newIntegrante["contract"]["end_date"] = moment(integrante.contract.end_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            return newIntegrante;
          });
          if (vm.service.start_date){
            vm.service.start_date = moment(vm.service.start_date).format('DD-MM-YYYY');
            vm.service.start_date_2 = moment(vm.service.start_date, ["YYYY-MM-DD","DD-MM-YYYY"])
          }
          if (vm.service.end_date){
            vm.service.end_date = moment(vm.service.end_date).format('DD-MM-YYYY');
            vm.service.end_date_2 = moment(vm.service.end_date, ["YYYY-MM-DD","DD-MM-YYYY"])
          }


          vm.isLoading=false;
        })
        .catch(e => {
          console.log(e);
          vm.isLoading=false;
        });

  },
  methods:{
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    changeTab: function(value){
      this.currentTab = value
    },
    updateData(data){      
      this.isLoading = true
      this.service = data;
      this.service.integrants = this.service.integrants.map(integrante => {
        var newIntegrante = integrante
        newIntegrante["contract"]["start_date"] = moment(integrante.contract.start_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
        newIntegrante["contract"]["end_date"] = moment(integrante.contract.end_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
        return newIntegrante;
      });
      if (this.service.start_date)
        this.service.start_date = moment(this.service.start_date).format('MM-DD-YYYY');
      if (this.service.end_date)
        this.service.end_date = moment(this.service.end_date).format('MM-DD-YYYY');
      this.isLoading = false
    },

  },
  computed:{
    currentTabComponent: function () {
      return this.currentTab
    }
  }
}
</script>
