<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form v-if="teaching" @submit.prevent="handleSubmit(checkSave)">
        <div class="modal-card" style="width: 1080px">
          <header class="modal-card-head">
            <p v-if="update" class="modal-card-title">Editar Experiencia Docente</p>
            <p v-else class="modal-card-title">Agregar Nueva Experiencia Docente</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body">
            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion">
                      <div v-if="item">{{ item[0] }}</div>
                    </div>
                  </b-message>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <ValidationProvider name="Asginatura" rules="min:3|max:255|required" 
                  v-slot="{ errors, failedRules }">
                  <b-field label="Asignatura *"
                    :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                    :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                    <b-input v-model="work_experience.subject_name" placeholder="Ej: Cálculo II" />
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column">
                <ValidationProvider name="Carrera, Programa o Curso" rules="min:3|max:255|required" 
                  v-slot="{ errors, failedRules }" >
                  <b-field label="Carrera, Programa o Curso *"
                    :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                    :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                    <b-input v-model="work_experience.career_name" placeholder="Ej: MBA Salud" />
                  </b-field>
                </ValidationProvider>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <ValidationProvider name="Institucion" rules="required"
                  v-slot="{ errors, failedRules }">
                  <b-field label="Institución *"
                    :type="{ 'is-danger': isPartnerEntityRegister || Object.keys(failedRules).length}"
                    :message="{ 'Debe añadir una entidad registrada': isPartnerEntityRegister || Object.keys(failedRules).length }" >
                    <b-autocomplete v-model="work_experience.partner_entity_name"
                                    :keep-first="keepFirst"
                                    :open-on-focus="openOnFocus"
                                    :data="partner_entities"
                                    field="show_name"
                                    empty="Vacio"
                                    @typing="searchPartnerEntities"
                                    @select="option => {if (option) {work_experience.partner_entity_id = option.id} }"
                                    :clearable="clearable"
                                    placeholder="Ej: UNAB">
                      <template slot-scope="props">
                        {{ props.option.show_name ? props.option.show_name : props.option.name}}
                      </template>
                    </b-autocomplete>
                </b-field>
                </ValidationProvider>
              </div>
              <div class="column is-narrow">
                <br>
                <b-field label=" ">
                  <b-tooltip label="Si la entidad que desea agregar no existe, la puede agregar presionando el botón +"
                             position="is-left">
                    <b-button class="is-primary is-outlined" @click="addEntity()">
                      <b-icon icon="plus"></b-icon>
                    </b-button>
                  </b-tooltip>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <ValidationProvider name="Horas de Teoria" rules="required|integer|positive|min:0|max:10" 
                  v-slot="{ errors, failedRules }" >
                  <b-field label="Horas de Teoría *"
                    :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                    :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                    <b-input v-model="work_experience.h_theory" placeholder="Ej: 10" type="number" />
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column">
                <ValidationProvider name="Horas de Ejercicio" rules="required|integer|positive|min:0|max:10" 
                  v-slot="{ errors, failedRules }" >
                  <b-field label="Horas de Ejercicio *"
                    :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                    :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                    <b-input v-model="work_experience.h_exercise" placeholder="Ej: 10" type="number" />
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column">
                <ValidationProvider name="Horas de Laboratorio" rules="required|integer|positive|min:0|max:10" 
                  v-slot="{ errors, failedRules }">
                  <b-field label="Horas de Laboratorio *"
                    :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                    :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                    <b-input v-model="work_experience.h_lab" placeholder="Ej: 10" type="number" />
                  </b-field>
                </ValidationProvider>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <ValidationProvider name="Duración" rules="required" vid="work_experience.duration_teaching_type_id" 
                  v-slot="{ errors, failedRules }">
                  <b-field label="Duración *"
                    :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                    :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                    <multiselect v-model="work_experience.duration_teaching_type_id"
                                 :options="duration_teaching_types.map(type => type.id)"
                                 :custom-label="opt => duration_teaching_types.find(x => x.id === opt).name"
                                 placeholder="Seleccione tipo de duración"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                          No se encontraron elementos.
                        </span>
                    </multiselect>
                  </b-field>
                </ValidationProvider>
              </div>
              <div v-if="work_experience.duration_teaching_type_id == 3" class="column">
                <ValidationProvider name="Duración en Semanas" rules="required_if:work_experience.duration_teaching_type_id,3|integer|positive|min:0|max:10" 
                    v-slot="{ errors, failedRules }" >
                  <b-field label="Duración en Semanas *"
                    :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                    :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                    <b-input v-model="work_experience.duration" placeholder="Ej: 1" />
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column">
                <ValidationProvider name="veces" rules="required|integer|positive|min:0|max:10" 
                  v-slot="{ errors, failedRules }" >
                  <b-field label="Veces Dictado *"
                    :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                    :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                    <b-input v-model="work_experience.times" placeholder="Ej: 1 o 2" />
                  </b-field>
                </ValidationProvider>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <ValidationProvider name="Último Semestre Dictado" rules="integer|positive|min_value:1|max_value:2" 
                  v-slot="{ errors, failedRules }">
                  <b-field label="Último Semestre Dictado"
                    :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                    :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                    <b-input v-model="work_experience.semester" placeholder="Ej: 1 o 2" />
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column">
                <ValidationProvider name="Último año Dictado" rules="required|integer|positive|min_value:1950|max_value:2200" 
                  v-slot="{ errors, failedRules }">
                  <b-field label="Último año Dictado *"
                    :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                    :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                    <b-input v-model="work_experience.year" placeholder="Ej: 2024" />
                  </b-field>
                </ValidationProvider>
              </div>
            </div>
            <div class="columns mb-6">
              <div class="column">
                <ValidationProvider name="Tipo" rules="required" 
                  v-slot="{ errors, failedRules }" >
                  <b-field label="Tipo *"
                    :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                    :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                    <multiselect v-model="work_experience.teaching_type_id"
                                  :options="teaching_types.map(type => type.id)"
                                  :custom-label="opt => teaching_types.find(x => x.id === opt).name"
                                  placeholder="Seleccione tipo"
                                  selectLabel="Presione para seleccionar"
                                  selectedLabel="Seleccionado"
                                  :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                          No se encontraron elementos.
                        </span>
                    </multiselect>
                  </b-field>
                </ValidationProvider>
              </div>
            </div>

            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
          </section>
        </div>
        <footer class="modal-card-foot is-flex is-justify-content-space-between">
          <b-button label="Cerrar" @click="$emit('close')" />
          <b-button label="Guardar Datos" type="is-primary" @click="checkSave()" />
        </footer>
        <b-modal
            v-model="isAddModalEntityActive"
            :destroy-on-hide="true"
            width="55%"
            :can-cancel="canCancel">
          <template #default="props">
            <modal_partner_entity
                query-type="update"
                modal-header="Añadir nueva entidad"
                @close="props.close"
                @clicked="onClickChild"></modal_partner_entity>
          </template>
        </b-modal>
      </form>
      <form v-else @submit.prevent="handleSubmit(checkSave)">
        <div class="modal-card" style="width: 1080px">
          <header class="modal-card-head">
            <p v-if="update" class="modal-card-title">Editar Experiencia Laboral</p>
            <p v-else class="modal-card-title">Agregar Nueva Experiencia Laboral</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body">
            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion">
                      <div v-if="item">{{ item[0] }}</div>
                    </div>
                  </b-message>
                </div>
              </div>
            </div>
<!--            <div class="columns">
              <div class="column">
                <b-field label="Tipo de trabajo*">
                  <ValidationProvider rules="required" v-slot="{ errors }" name="work_type">
                    <multiselect v-model="work_experience.work_type_id"
                                 :options="work_types.map(type => type.id)"
                                 :custom-label="opt => work_types.find(x => x.id === opt).name"
                                 placeholder="Seleccione Tipo de trabajo"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                          No se encontraron elementos.
                        </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>-->
            <div class="columns">
              <div class="column">
                <b-field label="Cargo*">
                  <ValidationProvider rules="required" v-slot="{ errors }" name="cargo">
                    <b-input v-model="work_experience.position" maxlength="255" :has-counter="false" placeholder="Ej: Responsable de Marketing"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Tipo de cargo*">
                  <ValidationProvider rules="required" v-slot="{ errors }" name="work_type">
                    <multiselect v-model="work_experience.work_position_type_id"
                                 :options="work_position_types.map(type => type.id)"
                                 :custom-label="opt => work_position_types.find(x => x.id === opt).name"
                                 placeholder="Seleccione Tipo de cargo"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                          No se encontraron elementos.
                        </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
<!--              <div class="column">
                <b-field label="Tipo de participación">
                  <multiselect v-model="work_experience.work_participant_type_id"
                               :options="work_participant_types.map(type => type.id)"
                               :custom-label="opt => work_participant_types.find(x => x.id === opt).name"
                               placeholder="Seleccione un tipo de participación"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                  </multiselect>
                </b-field>
              </div>-->
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción">
                  <b-input type="textarea" v-model="work_experience.description" maxlength="500" :has-counter="false"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field
                    :type="{ 'is-danger': isPartnerEntityRegister }"
                    :message="{ 'Debe añadir una entidad registrada': isPartnerEntityRegister }"
                    label="Institución*">
                  <ValidationProvider rules="required" v-slot="{ errors }" name="institucion">
                    <b-autocomplete v-model="work_experience.partner_entity_name"
                                    :keep-first="keepFirst"
                                    :open-on-focus="openOnFocus"
                                    :data="partner_entities"
                                    field="show_name"
                                    empty="Vacio"
                                    @typing="searchPartnerEntities"
                                    @select="option => {if (option) {work_experience.partner_entity_id = option.id} }"
                                    :clearable="clearable"
                    placeholder="Ej: Codelco">
                      <template slot-scope="props">
                        {{ props.option.show_name != null ? props.option.show_name : props.option.name}}
                      </template>
                    </b-autocomplete>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column is-narrow">
                <br>
                <b-field label=" ">
                  <b-tooltip label="Si la entidad que desea agregar no existe, la puede agregar presionando el botón +"
                             position="is-left">
                    <b-button class="is-primary is-outlined" @click="addEntity()">
                      <b-icon icon="plus"></b-icon>
                    </b-button>
                  </b-tooltip>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Disciplina">
                  <b-input v-model="work_experience.discipline" maxlength="255" :has-counter="false" placeholder="Ej: Ingeniería en Computación"></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Área de trabajo*">
                  <ValidationProvider rules="required" v-slot="{ errors }" name="area">
                    <multiselect v-model="work_experience.work_area_id"
                                 :options="work_areas.map(type => type.id)"
                                 :custom-label="opt => work_areas.find(x => x.id === opt).name"
                                 placeholder="Seleccione la Área de trabajo"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                          No se encontraron elementos.
                        </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Fecha de inicio*">
                  <ValidationProvider rules="required" v-slot="{ errors }" name="fecha">
                    <b-datepicker
                        v-model="work_experience.start_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="dd/mm/aaaa"
                    >
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de término">
                  <b-datepicker
                      v-model="work_experience.end_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="dd/mm/aaaa"
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label=" ">

                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label=" ">

                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label=" ">

                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label=" ">

                </b-field>
              </div>
            </div>
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="has-text-right is-grouped">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <b-button class="is-primary"
                      type="submit"
                      @click="checkSave()">
              Guardar Datos
            </b-button>
          </div>
        </footer>
        <b-modal
            v-model="isAddModalEntityActive"
            :destroy-on-hide="true"
            width="55%"
            :can-cancel="canCancel">
          <template #default="props">
            <modal_partner_entity
                query-type="update"
                modal-header="Añadir nueva entidad"
                @close="props.close"
                @clicked="onClickChild"></modal_partner_entity>
          </template>
        </b-modal>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import modal_partner_entity from "../moduloVIME/agreements/new/modal_partner_entity"
export default {
  name: "modal_new_work_experience",
  props: ['personId','update','old_work_experience','teaching'],
  components: {modal_partner_entity},
  data(){
    return{
      isLoading: false,
      work_experience: {},
      keepFirst: false,
      openOnFocus: false,
      clearable: false,
      partner_entities:[],
      isAddModalEntityActive: false,
      canCancel: false,
      errores_validacion: {},
      isPartnerEntityRegister: false,
      partner_entity_name: '',
      work_position_types: [],
      work_areas: [],
      work_types: [],
      work_participant_types: [],
      duration_teaching_types: [],
      teaching_types: []
    }
  },
  created() {
    this.isLoading = true
    this.initialize()
  },
  methods:{
    async initialize(){
      if(this.teaching){
        await this.getTeachingTypes()
      }
      else{
        await this.getWorkAreas()
      }

    },
    getWorkExperience() {
      this.isLoading =  true
      axios
          .get('/work_experiences/'+this.old_work_experience.id+'.json')
          .then(response => {
            this.work_experience = response.data
            if(!this.teaching){
              this.work_experience.start_date = moment(this.work_experience.start_date, ["YYYY-MM-DD","DD-MM-YYYY"])._d;
              if (this.work_experience.end_date != null){
                this.work_experience.end_date = moment(this.work_experience.end_date, ["YYYY-MM-DD","DD-MM-YYYY"])._d;
              }
            }
            this.isLoading = false
          })
          .catch(error=>{
            console.log(error)
            this.isLoading = false
          })
    },
    getWorkPositionTypes(){
      axios
          .get('/work_position_types.json')
          .then(response =>{
            this.work_position_types =  response.data
            if(this.update){
              this.getWorkExperience()
            }
            else{
              this.isLoading = false
            }
          })
          .catch(error =>{
            console.log(error)
            this.isLoading = false
          })
    },
    getWorkAreas(){
      axios
          .get('/work_areas.json')
          .then(response =>{
            this.work_areas =  response.data
            this.getWorkTypes()
          })
          .catch(error =>{
            console.log(error)
            this.isLoading = false
          })
    },
    getWorkTypes(){
      axios
          .get('/work_types.json')
          .then(response =>{
            this.work_types =  response.data
            this.getWorkParticipantTypes()
          })
          .catch(error =>{
            console.log(error)
            this.isLoading = false
          })
    },
    getWorkParticipantTypes(){
      axios
          .get('/work_participant_types.json')
          .then(response =>{
            this.work_participant_types =  response.data
            this.getWorkPositionTypes()
          })
          .catch(error =>{
            console.log(error)
            this.isLoading = false
          })
    },

    getTeachingTypes(){
      axios
          .get('/teaching_types.json')
          .then(response =>{
            this.teaching_types =  response.data
            this.getDurationTeachingTypes()
          })
          .catch(error =>{
            console.log(error)
            this.isLoading = false
          })
    },
    getDurationTeachingTypes(){
      axios
          .get('/duration_teaching_types.json')
          .then(response =>{
            this.duration_teaching_types =  response.data
            if(this.update){
              this.getWorkExperience()
            }
            else{
              this.isLoading = false
            }
          })
          .catch(error =>{
            console.log(error)
            this.isLoading = false
          })
    },

    async checkSave(){
      const valid = await this.$refs.observer.validate()
      this.isPartnerEntityRegister = false
      if(!valid){
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
      else{
        if (this.work_experience.partner_entity_name != '' && this.work_experience.partner_entity_name != null) {
          if (this.work_experience.partner_entity_id == null) {
            this.isPartnerEntityRegister = true
          } else {
            this.saveWorkExperience()
          }
        }
        else{
          this.saveWorkExperience()
        }
      }
    },

    saveWorkExperience(){
      this.isLoading = true
      this.work_experience.person_id = this.personId

      if(this.teaching){
        this.work_experience.if_teaching = true
        this.work_experience.is_teaching = true
      }
      else{
        this.work_experience.is_teaching = false
      }

      if(this.update){
        axios
            .put('/work_experiences/'+this.old_work_experience.id+'.json', {
              work_experience: this.work_experience
            })
            .then(response => {
              this.$buefy.dialog.alert({
                message: 'Los datos fueron actualizados satisfactoriamente.',
                confirmText: 'Ok'
              });
              this.errores_validacion = {}
              this.$emit('close')
              this.$emit('refresh')
              this.isLoading = false
            })
            .catch(error => {
              console.log(error)
              this.errores_validacion = error.response.data
              this.isLoading = false
              this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Ocurrio un error al editar los datos.',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
              });
            })
      }
      else{
        axios
            .post('/work_experiences.json', {
              work_experience: this.work_experience
            })
            .then(response => {
              this.$buefy.dialog.alert({
                message: 'Los datos fueron guardados satisfactoriamente.',
                confirmText: 'Ok'
              });
              this.errores_validacion = {}
              this.$emit('close')
              this.$emit('refresh')
              this.isLoading = false
            })
            .catch(error => {
              console.log(error)
              this.errores_validacion = error.response.data
              this.isLoading = false
              this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Ocurrio un error al guardar los datos.',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
              });
            })
      }
    },

    searchPartnerEntities(name){
      this.work_experience.partner_entity_id = null
      if (name.length<3) {
        this.partner_entities = []
        return
      }
      let vm = this;
      vm.isLoading=true;
      axios
          .get("/admin/partner_entities/agreement-search.json", { params: {
              name: name
            } })
          .then(res => {
            vm.partner_entities = res.data;
            this.errores_validacion = {}
            vm.isLoading = false;
          })
          .catch(error => {
            console.log(error)
            this.errores_validacion = error.response.data
            vm.isLoading=false;
          })
    },
    clearEntity(){
      this.work_experience.partner_entity_id = null
      this.work_experience.partner_entity_name = null
    },
    onClickChild(value){
      this.isPartnerEntityRegister = false

      if(value.name != '' && value.name != null){
        this.work_experience.partner_entity_name = value.name
      }
      else{
        this.work_experience.partner_entity_name = value.full_name
      }
      this.work_experience.partner_entity_id = value.id

    },
    addEntity(){
      this.isAddModalEntityActive = true;
    },
  }

}
</script>

<style scoped>

</style>