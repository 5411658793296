<template>
  <div class="container">
    <br>
    <p class="title is-4 is-centered">
      Lista de Roles y Permisos
    </p>
    <br>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(searchPermissions)">

        <div class="box">
          <div class="columns is-centered has-text-centered">
            <div class="column">
              <b-field label="Roles*">
                <ValidationProvider rules="required" v-slot="{errors}">
                  <multiselect v-model="internalPosition"
                               :options="internalPositions"
                               :custom-label="opt => internalPositions.find(x => x.id == opt.id).name"
                               placeholder="Seleccione rol"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                  </multiselect>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Unidades*">
                <ValidationProvider rules="required" v-slot="{errors}">
                  <multiselect v-model="unit"
                               :options="units"
                               :custom-label="opt => units.find(x => x.id == opt.id).name"
                               placeholder="Seleccione unidad"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                  </multiselect>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>
          <div class="has-text-right">
            <b-button native-type="submit" type="is-primary">Buscar permisos</b-button>
          </div>
        </div>

      </form>
    </ValidationObserver>


    <br>
    <div class="box" v-if="hasSearch">
        <permissions-table  :title_internal="titleInternal" :title="title" :unit_id="unitId" :internal_position_id="internalPositionId"></permissions-table>

      <!-- COMPONENTE AQUI -->
      <!-- FIN COMPONENTE -->
    </div>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>

</template>

<script>
import axios from "axios";
import { ToastProgrammatic as Toast } from 'buefy'
import PermissionsTable from './permissions_table.vue'
export default {
  name: "permissions_list",
  data(){
    return{
      internalPositions:[],
      tables:[],
      units:[],
      internalPosition:null,
      table:null,
      unit:null,
      isLoading:false,
      grantedPermissions:[],
      hasSearch:false,
      items: [],
      currentTab: null,
      key: null,
      title: null,
      unitId: null,
      internalPositionId: null
    }
  },
  created(){
    this.isLoading=true;
    return axios.get("/admin/permissions_list.json")
      .then(res => {
        this.internalPositions = res.data.internalPositions;
        this.units = res.data.units;
        for(var i = 0;i<res.data.units.length;i++){
          this.items.push({tab: res.data.units[i].name,content:'PermissionsTable', id: res.data.units[i].id})
        }
        console.log(this.items)
        console.log("fin created")
        this.isLoading=false;
      })
      .catch(e => {
        this.isLoading=false;
        console.log(e);
      });

  },
  props:[

  ],
  components:{
    PermissionsTable,
  },
  methods:{
    searchPermissions(){
      this.hasSearch=false;
      this.unitId = this.unit.id;
      this.internalPositionId = this.internalPosition.id;
      this.titleInternal = this.internalPosition.name;
      this.title=this.unit.name;
      this.hasSearch=true;
    },
    savePermissions(){
      let vm = this;
      vm.isLoading = true;
      let data = {};
      data.cargo=vm.internalPosition.id;
      data.permisos=vm.grantedPermissions;
      if (vm.table)
        data.tabla = vm.table.id;
      if (vm.unit)
        data.unit_id = vm.unit.id;
      axios
        .post("/set_internal_position_permissions",data)
        .then(response =>{
          vm.isLoading=false;
          Toast.open({message:'Guardado exitoso',type:'is-success',position:"is-top-right"})
        }).catch(error => {
          vm.isLoading=false;
          Toast.open({message:'Hubo un error al realizar la solicitud',type:'is-danger',position:"is-top-right"})
        })
    },
  },
  computed:{
    permissionsArray(){
      let retorno = [];
      for (let i = 0; i < this.grantedPermissions.length; i += 4)
        retorno.push(this.grantedPermissions.slice(i, i + 3));
      return retorno;
    },
    currentTabComponent: function () {
      return this.currentTab
    }


  }
}
</script>

<style scoped>

</style>