<template>
  <section>
    <b-modal width="90%" v-model="activeModal"
             aria-modal
             :can-cancel="canCancel"
             :on-cancel="closeModal">
      <div class="modal-card" style="width:100%">
        <header class="modal-card-head">
          <p class="modal-card-title">{{modalHeader}}</p>
          <button
              type="button"
              class="delete"
              @click="closeModal"/>
        </header>
        <section class="modal-card-body" ref="modalPersonForm" style="width:100%">
          <div class="columns">
            <div class="column is-12">
              <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion">
                      {{ item[0] }}
                    </div>
                  </b-message>
                </div>
                <form @submit.prevent="handleSubmit(saveCite)">
                  <!-- Box de Formulario Project -->
                  <div class="box">

                    <div class="columns">
                      <div class="column is-4">
                        <b-field>
                          <template #label>
                            Año*
                            <b-tooltip label="Si ya existe un registro con el año ingresado, este será actualizado con la cantidad ingresada."
                                       position="is-right"
                                       size="is-medium"
                                       multilined>
                              <b-icon
                                  pack="fas"
                                  icon="info-circle"
                                  size="is-small"
                                  type="is-info">
                              </b-icon>
                            </b-tooltip>
                          </template>
                          <ValidationProvider :rules="citeYearValidation" v-slot="{ errors }">
                            <b-input v-model="cite.year"></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>

                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Cantidad de citas*">
                          <ValidationProvider rules="required|integer|min_value:0|max_value:100000" v-slot="{ errors }">
                            <b-input :has-counter="false" v-model="cite.number"></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>

                        </b-field>
                      </div>
                    </div>

                    <div class="actions has-text-right">
                      <button class="button is-danger" icon-left="trash" @click.prevent="closeModal">Cancelar</button>
                      <button class="button is-primary" type="submit" icon-left="plus" @click="checkValidation()">Guardar Cita</button>
                    </div>

                  </div>


                </form>
              </ValidationObserver>

            </div>
          </div>
        </section>
      </div>
    </b-modal>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "PublicationCitesForm",
  data() {
    return {
      isImageModalActive: false,
      isCardModalActive: false,
      canCancel: ['escape'],
      isLoading: false,
      errores_validacion:{},
      destroyOnHide:true,
      citeYearValidation:'required|integer|min_value:1900|max_value:'+new Date().getFullYear(),
    }
  },
  props:['modalHeader','activeModal','publication','cite',],
  async created() {
    if (this.activeModal)
      this.isCardModalActive = true;
    //await this.getEntityTypes();
  },
  methods:{
    async checkValidation() {
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
    closeModal(){
      this.$emit('close');
    },
    saveCite(){
      let vm = this;
      vm.isLoading=true;
      axios
        .post("/publications/"+vm.publication.id+"/cites/add", {
          year: vm.cite.year,
          quantity:vm.cite.number
        })
        .then(res => {
          vm.errores_validacion = {};
          vm.$buefy.dialog.alert({
            message: 'La cita fue asociada satisfactoriamente.',
            type: 'is-success',
            hasIcon: true,
            icon: 'check-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          vm.isLoading=false;
          this.$emit('citeAdded');
        }).
        catch(error => {
          vm.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          vm.$buefy.dialog.alert({
            message: 'Hubo un error en su solicitud.',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          vm.isLoading=false;
        })

    }
  }
}
</script>

<style scoped>

</style>