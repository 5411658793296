<template>
  <div class="agreementnewview">
    <section>

      <div v-if="from_academic" class="modal-card" style="width: 1080px">

        <header class="modal-card-head">
          <p v-if="edit_case" class="modal-card-title">Editar Convenio</p>
          <p v-else class="modal-card-title">Nuevo Convenio</p>
          <button type="button" class="delete" @click="$emit('close')" />
        </header>

        <section class="modal-card-body">
          <AgreementNewForm v-if="edit_case" :edit_case='edit_case' :agreement_id='agreement_id' :step='step'
            @close="$emit('close')" @refresh="$emit('refresh')" />
          <AgreementNewForm v-else :entity_id="entity_id" :from_academic="from_academic" :add_agreement="add_agreement"
            @close="$emit('close')" @refresh="$emit('refresh')" />
        </section>

        <footer v-if="from_academic" class="modal-card-foot actions">
          <div class="has-text-right is-grouped">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <b-button class="is-primary" label="Agregar Convenio" @click="add_agreement = !add_agreement" />
          </div>
        </footer>
      </div>

      <div v-else class="container">
        <h1 class="is-size-2" v-if="edit_case">Editar Convenio</h1>
        <h1 class="is-size-2" v-else>Nuevo Convenio</h1>
        <AgreementNewForm v-if="edit_case" :edit_case='edit_case' :agreement_id='agreement_id' :step='step' />
        <AgreementNewForm v-else :entity_id="entity_id" />
      </div>
    </section>
  </div>
</template>
<script>
import AgreementNewForm from './agreements_new_form.vue'
export default {
  name: "agreementnewview",
  components: {
    AgreementNewForm
  },
  props: {
    edit_case: {
      type: Boolean,
      default: false
    },
    agreement_id: {
      type: Number
    },
    step: {
      type: Number
    },
    entity_id: {
      type: Number,
      default: 0,
    },
    from_academic: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      add_agreement: false
    }
  }
}
</script>