<template>
  <div>
    <b-table
      ref="table"
      class="table-border"
      :data="administrativePermits"
      paginated
      pagination-position="bottom"
      narrowed
      per-page="10"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      striped
      @sort="handleSort"
    >
      <section
        class="columns is-align-items-center is-justify-content-space-between"
      >
        <h3 class="subtitle is-4 has-text-weight-medium mb-0 pl-4 pt-2">
          {{ title }}
        </h3>
        <slot name="filters" />
        <div
          v-if="!adminMode"
          class="column is-narrow is-full-mobile is-flex is-justify-content-flex-end"
        >
          <b-button
            class="is-primary"
            expanded
            icon-left="file-invoice"
            @click="createDetails()"
          >
            Crear Solicitud de Permiso
          </b-button>
        </div>
      </section>
      <b-table-column field="index" label="IDS">
        <template #header="{ column }">
          <b-tooltip
            label="Identificador SIGA"
            position="is-right"
            size="is-small"
            append-to-body
          >
            {{ column.label }}
          </b-tooltip>
        </template>
        <template #default="props">
          {{ props.row.adm_permit_doc.id }}
        </template>
      </b-table-column>
      <b-table-column
        v-if="adminMode"
        width="8rem"
        field="person.run"
        label="RUT"
        :custom-search="searchRun"
        sortable
        searchable
      >
        <template #searchable="props">
          <b-input
            v-model="props.filters[props.column.field]"
            placeholder="Ej: 12345678-9"
            icon="search"
            size="is-small"
            class="pl-2"
          />
        </template>
        <template #default="props">
          <div style="white-space: nowrap">
            {{ props.row.person.run ? props.row.person.run : "" }}
          </div>
        </template>
      </b-table-column>
      <b-table-column
        v-if="adminMode"
        field="person.name"
        label="Nombre"
        sortable
        searchable
      >
        <template #searchable="props">
          <b-input
            v-model="props.filters[props.column.field]"
            placeholder="Ej: Juan"
            icon="search"
            size="is-small"
            class="pl-2"
          />
        </template>
        <template #default="props">
          {{ props.row.person.name }}
        </template>
      </b-table-column>
      <b-table-column
        v-if="adminMode"
        field="person.last_name"
        label="Primer Apellido"
        sortable
        searchable
      >
        <template #searchable="props">
          <b-input
            v-model="props.filters[props.column.field]"
            placeholder="Ej: Pérez"
            icon="search"
            size="is-small"
            class="pl-2"
          />
        </template>
        <template #default="props">
          {{ props.row.person.last_name }}
        </template>
      </b-table-column>

      <b-table-column
        v-if="adminMode"
        field="person.second_surname"
        label="Segundo Apellido"
        sortable
        searchable
      >
        <template #searchable="props">
          <b-input
            v-model="props.filters[props.column.field]"
            placeholder="Ej: González"
            icon="search"
            size="is-small"
            class="pl-2"
          />
        </template>
        <template #default="props">
          {{ props.row.person.second_surname }}
        </template>
      </b-table-column>
      <b-table-column
        field="adm_permits"
        label="Fechas solicitadas"
        :custom-search="searchRequestDate"
        searchable
        sortable
      >
        <template #searchable="props">
          <b-input
            v-model="props.filters[props.column.field]"
            placeholder="Ej: dd-mm-yyyy"
            icon="search"
            size="is-small"
            class="pl-2"
          />
        </template>
        <template #default="props">
          <div class="tags-container">
            <div
              v-for="admPermit in props.row.adm_permits"
              :key="admPermit.id"
              class="tag-column"
            >
              <b-tooltip
                :label="getDaysText(admPermit)"
                :type="colorTypeByStateDates(admPermit.request_state.name)"
                animated
              >
                <b-tag
                  icon="calendar-alt"
                  rounded
                  size="is-small"
                  :type="colorTypeByStateDates(admPermit.request_state.name)"
                  class="tag-border has-text-weight-medium"
                >
                  {{ formatDateToLocaleString(admPermit.start_date) }} a
                  {{ formatDateToLocaleString(admPermit.end_date) }}
                </b-tag>
              </b-tooltip>
            </div>
          </div>
        </template>
      </b-table-column>

      <b-table-column
        field="total_requested_days"
        label="Días solicitados"
        searchable
        sortable
      >
        <template #searchable="props">
          <b-input
            v-model="props.filters[props.column.field]"
            placeholder="Ej: 2"
            icon="search"
            size="is-small"
            class="pl-2"
          />
        </template>
        <template #default="props">
          {{ parseFloat(props.row.total_requested_days) }}
        </template>
      </b-table-column>

      <b-table-column
        field="request_state.name"
        width="8rem"
        label="Estado"
        sortable
        :custom-sort="sortedState"
        searchable
        :custom-search="searchState"
      >
        <template #searchable="props">
          <b-input
            v-model="props.filters[props.column.field]"
            placeholder="Ej: aprobado"
            icon="search"
            size="is-small"
            class="pl-2"
          />
        </template>
        <template #default="props">
          <section class="tags-container">
            <div
              v-for="admPermit in props.row.adm_permits"
              :key="admPermit.id"
              class="tag-column"
            >
              <b-tooltip
                :label="admPermit.request_state.name"
                :type="colorTypeByState(admPermit.request_state.name)"
                position="is-left"
              >
                <b-icon
                  size="is-small"
                  :icon="iconByState(admPermit.request_state.name)"
                  :type="colorTypeByState(admPermit.request_state.name)"
                />
              </b-tooltip>
            </div>
          </section>
        </template>
      </b-table-column>
      <b-table-column v-slot="props" label="Acciones" numeric>
        <div class="columns is-variable is-1 is-justify-content-flex-end p-1">
          <div class="column is-narrow">
            <b-tooltip
              label="Menú de acciones"
              type="is-primary is-light"
              position="is-left"
              size="is-small"
              animated
            >
              <b-dropdown append-to-body trap-focus position="is-bottom-left">
                <button slot="trigger" class="button is-primary is-light">
                  <i class="fas fa-ellipsis-v"></i>
                </button>
                <b-dropdown-item
                  v-if="adminMode"
                  aria-role="listitem"
                  class="left-aligned"
                  @click="goToProfile(props.row.person.id)"
                >
                  <span class="icon is-small">
                    <i class="fas fa-user"></i>
                  </span>
                  Ir al perfil
                </b-dropdown-item>

                <b-dropdown-item
                  aria-role="listitem"
                  class="left-aligned"
                  @click="showDetails(props.row)"
                >
                  <span class="icon is-small">
                    <i class="fas fa-eye"></i>
                  </span>
                  Ver detalles
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="
                    props.row.adm_permits.some(
                      (permit) => permit.request_state.name === 'EN CURSO'
                    )
                  "
                  aria-role="listitem"
                  class="left-aligned"
                  @click="editDates(props.row)"
                >
                  <span class="icon is-small">
                    <i class="fas fa-edit"></i>
                  </span>
                  Editar fechas
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="adminMode"
                  aria-role="listitem"
                  class="left-aligned"
                  @click="editStatus(props.row)"
                >
                  <span class="icon is-small">
                    <i class="fas fa-tasks"></i>
                  </span>
                  Cambiar estado
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="props.row.adm_permit_doc"
                  aria-role="listitem"
                  class="left-aligned"
                  @click="
                    onDownload({
                      documentId: props.row.adm_permit_doc.id,
                      personId: props.row.person.id,
                    })
                  "
                >
                  <span class="icon is-small">
                    <i class="fas fa-file-pdf"></i>
                  </span>
                  Generar documento
                </b-dropdown-item>
              </b-dropdown>
            </b-tooltip>
          </div>
        </div>
      </b-table-column>

      <template slot="empty">
        <div class="is-flex is-justify-content-center">
          <b-message type="is-danger" has-icon>
            No se encontraron resultados.
          </b-message>
        </div>
      </template>
    </b-table>
    <AdministrativePermitsFormModal
      :show-modal.sync="showModalDetails"
      :person="currentPerson"
      :permits="currentPermits"
      :document="currentDocument"
      @download="onDownload"
    />
    <AdministrativePermitsFormModal
      edit-dates-mode
      :show-modal.sync="showModalEditDates"
      :person="currentPerson"
      :permits="currentPermits"
      :document="currentDocument"
      :permit-states="permitStates"
      @edit="onEditPermits"
    />
    <AdministrativePermitsFormModal
      edit-status-mode
      :show-modal.sync="showModalEditStatus"
      :person="currentPerson"
      :permits="currentPermits"
      :document="currentDocument"
      :permit-states="permitStates"
      @edit="onEditPermits"
    />
    <AdministrativePermitsFormModal
      create-mode
      :show-modal.sync="showModalCreate"
      :permits="administrativePermits"
      :person="person"
      :person-used-days="personUsedDays"
      :permit-states="permitStates"
      @create="onSavePermits"
    />
  </div>
</template>

<script>
import moment from "moment";
import {
  formatDateToLocaleString,
  searchDate,
  colorTypeByState,
  iconByState,
} from "../.././packs/utilities";
import AdministrativePermitsFormModal from "./AdministrativePermitsFormModal.vue";

export default {
  components: {
    AdministrativePermitsFormModal,
  },
  props: {
    title: {
      type: String,
      default: "Solicitudes de permisos administrativos",
    },
    administrativePermits: {
      type: Array,
      default: () => [],
    },
    adminMode: {
      type: Boolean,
    },
    permitStates: {
      type: Array,
      default: () => [],
    },
    person: {
      type: Object,
      default: () => ({}),
    },
    personUsedDays: {
      type: Number,
      default: 0,
    },
    modalFormStatus: {
      type: Boolean,
    },
  },
  data() {
    return {
      showModalDetails: false,
      showModalCreate: false,
      showModalEditDates: false,
      showModalEditStatus: false,
      currentPerson: {},
      currentPermits: [],
      currentDocument: {},
    };
  },
  watch: {
    modalFormStatus(status) {
      if (status) {
        this.showModalCreate = false;
        this.showModalEditStatus = false;
        this.showModalEditDates = false;
      }
    },
  },

  methods: {
    searchRequestDate(row, input) {
      for (let i = 0; i < row.adm_permits.length; i++) {
        const adm_permit = row.adm_permits[i];
        return (
          searchDate(moment(adm_permit.start_date), input) ||
          searchDate(moment(adm_permit.end_date), input)
        );
      }
      return false;
    },
    searchRun(row, input) {
      return row.person.run
        .replaceAll(".", "")
        .replaceAll("-", "")
        .includes(input.replaceAll(".", "").replaceAll("-", "").trim());
    },
    handleSort(field, order) {
      this.sortDirection = order === "asc" ? 1 : -1;
    },
    getStateOrder(stateName) {
      switch (stateName) {
        case "EN CURSO":
          return 1;
        case "APROBADO":
          return 2;
        case "RECHAZADO":
          return 3;
        case "ANULADO":
          return 4;
        default:
          return 5;
      }
    },
    sortedState(a, b, isAsc) {
      const minOrderA = Math.min(
        ...a.adm_permits.map((permit) =>
          this.getStateOrder(permit.request_state.name)
        )
      );
      const minOrderB = Math.min(
        ...b.adm_permits.map((permit) =>
          this.getStateOrder(permit.request_state.name)
        )
      );

      if (isAsc) {
        return minOrderA - minOrderB;
      } else {
        return minOrderB - minOrderA;
      }
    },
    searchState(row, input) {
      console.log(row.adm_permits, input);
      return row.adm_permits.some((admPermit) =>
        admPermit.request_state.name.toLowerCase().includes(input.toLowerCase())
      );
    },
    setCurrentInfo(row) {
      this.currentPerson = row.person;
      this.currentPermits = row.adm_permits;
      this.currentDocument = row.adm_permit_doc;
    },
    goToProfile(personId) {
      const url = `${personId}/details`;
      window.open(url, "_blank");
    },
    showDetails(row) {
      this.setCurrentInfo(row);
      this.showModalDetails = true;
    },
    editStatus(row) {
      this.setCurrentInfo(row);
      this.showModalEditStatus = true;
    },
    editDates(row) {
      this.setCurrentInfo(row);
      this.showModalEditDates = true;
    },
    createDetails() {
      this.showModalCreate = true;
    },

    onSavePermits(editedPermits) {
      this.$emit("handleSavePermits", editedPermits);
    },
    onEditPermits({ personId, editedPermits, documentId }) {
      this.$emit("handleEditPermits", { personId, editedPermits, documentId });
    },
    onDownload({ documentId, personId }) {
      this.$emit("handleDownload", {
        documentId,
        personId,
      });
    },
    colorTypeByStateDates(state) {
      return colorTypeByState(state).includes("is-light")
        ? colorTypeByState(state)
        : colorTypeByState(state) + " is-light";
    },
    getDaysText(admPermit) {
      if (parseFloat(admPermit.number_of_days) === parseFloat(1)) {
        return `${admPermit.number_of_days} día solicitado`;
      }
      return `${admPermit.number_of_days} días solicitados`;
    },
    formatDateToLocaleString,
    colorTypeByState,
    iconByState,
  },
};
</script>

<style scoped>
.table-border {
  border: 2rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
  border-color: #e5e7eb;
  background-color: #fff;
  padding: 1rem;
}

.left-aligned {
  text-align: left !important;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.tags-container {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 0.1rem;
}

.tag-column {
  flex: none;
}

.tag-border {
  border: 1px solid #ccc;
  min-width: 170px;
}

@media (max-width: 767px) {
  .tags-container {
    justify-content: flex-end;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .tags-container {
    justify-content: flex-start;
  }
}
@media (min-width: 1024px) {
  .tags-container {
    justify-content: flex-start;
  }
}
</style>
