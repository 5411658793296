<template>
  <div>
    <section>
      <div class="box">
        <div class="container">
          <b-tabs position="is-centered" @input="changeTab">
            <b-tab-item v-for="item in items"
                        :value="item.content"
                        v-bind:key="item.content"
                        :label="item.tab"
                        :icon="item.icon">
            </b-tab-item>

          </b-tabs>
          <!-- COMPONENTE AQUI -->

          <component v-bind:is="currentTabComponent"
                     :user-id="this.userId"
                     :cargo-principal="this.cargoPrincipal"
                     :participantes="this.participantes"
                     :patent-id="this.patent.id"
                     :patent="this.patent"
                     :can_edit="this.patent.can_edit"
                     :tipos_patente="this.tipos_patente"
                     :estados_patente="this.estados_patente"
                     :academic_activity_state_id="this.patent.academic_activity_state_id"
          ></component>
        </div>
      </div>
    </section>
<!--    <div class="container" style="border-radius: 15px">
      <div class="columns is-centered">
        <div class="column box">
          <div class="title is-4">
            Patente
            <div class="has-text-right">
              <b-button type="is-info"
                        icon-left="edit"
                        v-if="(patent.can_edit && patent.academic_activity_state_id == 1) || (patent.can_edit && patent.academic_activity_state_id == null)"
                        @click="editPatent(patentId)">
                Editar Propiedad Intelectual
              </b-button>
              <b-button v-if="(patent.is_creator || cargoPrincipal.name == 'ANALISTA I+D FING') && (patent.academic_activity_state_id == 1 || patent.academic_activity_state_id == null)" type="is-danger"
                        icon-left="ban"
                        @click="execNullifyModal">
                Anular Publicación
              </b-button>
            </div>
          </div>

          <hr class="dropdown-divider" aria-role="menuitem">

          <div class="columns">
            <div class="column is-two-fifths">
              <b-field label="Título">
                <b-input readonly v-model="patent.title" type="text"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Código Protección">
                <b-input readonly v-model="patent.protection_code"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Tipo de Protección">
                <b-input readonly v-model="patent.patent_type"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Título Corto">
                <b-input readonly v-model="patent.short_title"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Fecha de Creación">
                <b-input readonly v-model="patent.presentation_date"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Número de Solicitud">
                <b-input readonly v-model="patent.application_number"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Código Tecnología">
                <b-input readonly v-model="patent.technology_code"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="País">
                <b-input readonly v-model="patent.nationality"></b-input>
              </b-field>
            </div>
          </div>


          <br>
          <h3 class="is-size-3 has-text-weight-bold">Propietarias(os) Intelectuales</h3>
          <h3 class="is-size-4 has-text-weight-bold">Tabla Resumen Participantes</h3>
          <div class="column">
            <b-table
                :data="participantes"
                :paginated=true
                per-page=5
                striped
                hoverable
                :current-page="currentPage"
                :card-layout="cardLayout"
                :pagination-simple=false
                pagination-position="bottom"
                default-sort-direction="desc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="last_patent_state_date"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
              <b-table-column
                  field= 'name'
                  label= 'Nombres'
                  width="20em"
                  searchable
                  sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  <a @click="goToProfile(props.row.person_id)">{{ props.row.name }}</a>
                </template>
              </b-table-column>
              <b-table-column
                  field= 'corporate_mail'
                  label= 'Correo Corporativo'
                  searchable
                  sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{  props.row.corporate_mail }}
                </template>
              </b-table-column>
              <b-table-column
                  field= 'unit'
                  label= 'Unidad'
                  searchable
                  sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{  props.row.unit }}
                </template>
              </b-table-column>
            </b-table>
          </div>

&lt;!&ndash;          <h3 class="is-size-3 has-text-weight-bold">
            Referencias de participantes
          </h3>
          <div class="columns">
            <div class="column">
              <b-field label="Propietarias(os) Intelectuales(registrados y no registrados)">
                <b-input readonly v-model="patent.participants_references" type="text"></b-input>
              </b-field>
            </div>
          </div>&ndash;&gt;

          <h3 class="is-size-3 has-text-weight-bold">Estados de la Patente</h3>
          &lt;!&ndash; FORMULARIO DE ESTADOS &ndash;&gt;
          <div v-for="(estado,index) in patent.patent_states_attributes">
            <div class="columns">
              <div class="column">
                <b-field label="Estado de Patente">
                  <b-input readonly v-model="patent.patent_states_attributes[index].patent_state"></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de estado de Patente">
                  <b-input readonly v-model="patent.patent_states_attributes[index].hps_date">
                  </b-input>
                </b-field>
              </div>
            </div>

          </div>
          <hr class="dropdown-divider" aria-role="menuitem">
          <h3 class="is-size-3 has-text-weight-bold">Información Adicional</h3>
          <div class="columns">
            <div class="column">
              <b-field label="Origen del dato">
                <b-input v-if="patent.validation_attributes!=null" readonly v-model="patent.validation_attributes.data_source"></b-input>
                <b-input v-else readonly></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <h3 class="is-size-6 has-text-weight-bold">
                Dueña(o)
                <b-tooltip label="Solo la/el usuaria/o que es Dueña o Dueño de esta actividad académica tiene permisos para editar todos sus atributos.
                      Quienes participan en la actividad académica pero no son dueños, solo pueden editar su participación."
                           position="is-right"
                           size="is-large"
                           multilined>
                  <b-icon
                      pack="fas"
                      icon="info-circle"
                      size="is-small"
                      type="is-info">
                  </b-icon>
                </b-tooltip>
              </h3>
              <b-field label=" ">
                <b-input v-if="patent.owner!=''" readonly v-model="patent.owner"></b-input>
                <b-input v-else readonly></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Correo">
                <b-input v-if="patent.owner_mail!=''" readonly v-model="patent.owner_mail"></b-input>
                <b-input v-else readonly></b-input>
              </b-field>
            </div>
          </div>



        </div>
      </div>
    </div>-->
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>
<script>
import axios from "axios";
import General from './PatentGeneralTab'
import Participants from './PatentParticipantsTab'
import AdditionalInformationTab from './AdditionalInformationTab'
import PatentStateTab from "./PatentStateTab";
import moment from "moment";
export default {
  components:{General,Participants,AdditionalInformationTab,PatentStateTab},
  data(){
    return{
      currentPage:1,
      cardLayout:false,
      participantes: [],
      tipos_patente:[],
      estados_patente:[],
      patent: {
        patent_type_attributes:[
          {id:-1,name:""}
        ],
        person_patents_attributes:[],
        validation_attributes:{},
      },
      isLoading:false,
      currentTab: 'General',
      items: [
        {tab: 'General',content:'General',icon:"file-alt"},
        {tab: 'Participantes',content:'Participants',icon:"users"},
        {tab: 'Estados de Patente',content:'PatentStateTab',icon:"circle-notch"},
        {tab: 'Información Adicional',content:'AdditionalInformationTab',icon:"info"},
      ]

    }
  },
  created(){
    this.isLoading=true
    
    return axios
        .get("/patents/"+this.patentId+".json")
        .then(res => {
          this.patent = res.data;
          this.patent.presentation_date = this.formatDate(this.patent.presentation_date);
          this.patent.exit_date = this.formatDate(this.patent.exit_date);
          if (this.patent.patent_states_attributes != null)
            this.patent.patent_states_attributes = this.patent.patent_states_attributes.map(
                obj=> ({ ...obj, hps_date: new Date(obj.hps_date.replace(/-/g, '\/')) })
            )
          if(this.patent.person_patents_attributes!= null){
            this.participantes = this.patent.person_patents_attributes
          }
          axios
              .get("/patents/new.json")
              .then(res => {
                this.tipos_patente = res.data["tipos_patente"]
                this.estados_patente = res.data["estados_patente"]
                this.isLoading=false;
              })
              .catch(error => {
                console.log(error);
                this.isLoading=false;
              })

        })
        .catch(e => {
          console.log(e);
          this.isLoading=false;
        });

  },
  props:[
    'patentId','cargoPrincipal', 'userId'

  ],
  methods:{
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    editPatent(patent_id){
      console.log(patent_id)
      window.location.href = "/patents/"+patent_id+"/edit"
      
    },
    execNullifyModal(){
      this.$buefy.dialog.confirm({
        title: 'Anular patente',
        message: '¿Está seguro que desea <b>anular</b> la patente? Esta acción la dejará inválida.<br>',
        confirmText: 'Anular Patente',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/patents/" + this.patentId + "/nullify_patent.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Patente anulada correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                if (document.referrer == ''){
                  window.location.href = history.back()
                }
                else{
                  window.location.href = document.referrer
                }
                this.isLoading = false;
                this.isNullifyActive = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Patente no pudo ser anulado</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },
    changeTab: function(value){
      this.currentTab = value
    }

  },
  computed:{
    currentTabComponent: function () {
      return this.currentTab
    }
  }
}
</script>
