<template>
  <section>
    <div class="container">
      <h1 class="is-size-2">Actividades VIME</h1>
      <div v-if="can_create" class="has-text-right">
        <button class="button is-primary" @click="newActivity()">
          <b-icon icon="plus"></b-icon>
          <span>Añadir Actividad</span>
        </button>
      </div>
      <br>
      <div class="columns">
        <div class="column is-narrow mt-2">
          <span>Actividades año inicio: </span>
        </div>
        <div class="column is-3">
          <b-field>
            <multiselect v-model="year"
                         :options="years"
                         @input="updateActivity"
                         placeholder="Seleccione año"
                         selectLabel="Presione para seleccionar"
                         selectedLabel="Seleccionado"
                         deselectLabel="No se puede deseleccionar"
                         :allow-empty="false">
              <template v-slot:noOptions>
                No existen datos
              </template>
              <span slot="noResult">
                        No se encontraron elementos.
                    </span>
            </multiselect>
          </b-field>
        </div>
        <div class="column has-text-right">
          <b-field>
            <b-switch v-model="cardLayout">
          <span>
            Formato tarjeta (sin filtros ni búsqueda)
             <b-tooltip
                 label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                 multilined
                 position="is-left">
                  <b-icon
                      pack="fas"
                      icon="info-circle"
                      type="is-info">
                  </b-icon>
              </b-tooltip>
          </span>
            </b-switch>
          </b-field>
        </div>
      </div>
      <b-table
          :data="activities"
          :paginated=true
          per-page=10
          striped
          hoverable
          :current-page="currentPage"
          :card-layout="cardLayout"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="publication.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field= 'name'
            label= 'Nombre actividad'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
          </template>
          <template v-slot="props">
              <a @click="goToActivity(props.row.id)"><i class="fas fa-calendar"></i> {{ props.row.name }}</a>
          </template>
        </b-table-column>
        <b-table-column
            field= 'theme'
            label= 'Tema'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
          </template>
          <template v-slot="props">
              {{ props.row.theme }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'vime_activity_type_name'
            label= 'Tipo de actividad'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
          </template>
          <template v-slot="props">
              {{ props.row.vime_activity_type_name }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'start_date'
            label= 'Fecha de inicio'
            searchable :custom-search="searchStart"
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
          </template>
          <template v-slot="props">
              {{ props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL") : '' }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'end_date'
            label= 'Fecha de término'
            searchable :custom-search="searchEnd"
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
          </template>
          <template v-slot="props">
              {{ props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL") : '' }}
          </template>
        </b-table-column>
        <template slot="empty" slot-scope="props">
          <div class="columns is-centered">
            <div class="column is-6 is-centered has-text-centered">
              <b-message type="is-danger">
                No se encontraron resultados para el año seleccionado.
              </b-message>
            </div>
          </div>
        </template>
      </b-table>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
    </div>
  </section>
</template>
<script>
import axios from 'axios'
import jsPDF from 'jspdf'
import VimeActivityAdvancedSearch from './vime_activity_advanced_search.vue'
import {searchDate} from "../../../../packs/utilities";
import moment from "moment";
export default {
    data(){
        return {
            isLoading:false,
            currentPage:1,
            activities: [],
            activity_types: [],
            filteredActivities: [],
            cardLayout:false,
            can_create: false,
            applyFilter: 0,
            months: ['Enero', 'Febrero', 'Marzo', 'Abril', 
                     'Mayo', 'Junio', 'Julio', 'Agosto', 
                     'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            month: '',
            year: null,
            years:[]
        }
    },
    created(){
        this.years = this.generateArrayOfYears();
        this.year = new Date().getFullYear();
        this.updateActivity()
    },
    computed:{
      checkFilteredActivities(){
          return Object.entries(this.activities).length === 0 
      }
    },
    methods:{
        newActivity(){
            this.isLoading=true;
            window.location.href="/vime_activities/new"
        },
        generateArrayOfYears() {
          var max = new Date().getFullYear()
          var min = 1980
          var years = []
          years.push("Todo")
          for (var i = max; i >= min; i--) {
            years.push(i)
          }
          return years
        },
        goToActivity(activity_id){
            this.isLoading=true;
            window.location.href="/vime_activities/"+activity_id
        },
        dateFormater(date){
            var newDate = ""
            var localDate = new Date(date).toLocaleDateString().toString();
            for(var i =0; i<localDate.length; i++){
                if(localDate[i] === '-'){
                    newDate = newDate.concat('/')
                }
                else{
                    newDate = newDate.concat(localDate.charAt(i))
                }
            }
            return newDate
        },
        advancedSearch(){
          this.$buefy.modal.open({
            parent: this,
            component: VimeActivityAdvancedSearch,
            hasModalCard: true,
            events:{
                'advanced-search-activity-filters': this.advancedSearchFilter
            },
            customClass: 'is-primary',
            trapFocus: true
          })
        },
        advancedSearchFilter(filters){
          this.isLoading=true
          axios.get('/get_vime_activities_by_year', { params: {
              maxYear: filters.year,
              minYear: filters.year
          }})
          .then( response =>{
              this.activities = response.data.activities
              this.month = this.months[filters.month - 1]
              if(this.checkFilteredActivities){
                  this.$buefy.dialog.alert({
                      title: "Búsqueda de actividades.",
                      message: "No se han encontrado actividades para el año y mes definido."
                  })
              }
              this.isLoading=false
          })
          .catch(e => {
              console.log(e)
              this.isLoading=false
          })
        },
        removeFilters(){
          this.filteredActivities = this.activities
          this.applyFilter = 0
        },
      searchStart(row,input){
        return searchDate(row.start_date,input)
      },
      searchEnd(row,input){
        return searchDate(row.end_date,input)
      },

        downloadCalendar(){
            // Declaración del PDF
            var doc = new jsPDF('p','pt');
            doc.setFillColor(253, 126, 20);
            doc.rect(0, 0, 600, 10, "F");
            doc.rect(0, 832, 600, 10, "F");
            doc.addImage("/logo_negro.png", "JPEG", 20, 20, 150, 80);

            // Título del calendario
            doc.setFont("times new roman", "bold");
            doc.text("Calendario de actividades VIME mes "+this.month, 120, 130);
            doc.setFont("times new roman", "normal");

            // Encabezados tabla
            var columnsNames = [
                {title: 'Nombre', dataKey: 'name'},
                {title: 'Tema', dataKey: 'theme'},
                {title: 'Tipo de actividad', dataKey: 'activity_type'},
                {title: 'Fecha inicio', dataKey: 'start_date'},
                {title: 'Fecha término', dataKey: 'end_date'},
                {title: '¿Internacional?', dataKey: 'is_national'}
            ]

            // Información de la tabla
            var tableData = []
            var nationalOrNot = ''
            this.filteredActivities.forEach(function(element){
                if(element.is_national){
                    nationalOrNot = 'SI'
                }
                else{
                    nationalOrNot = 'NO'
                }
                tableData.push({
                    name: element.name,
                    theme: element.theme,
                    activity_type: element.vime_activity_type_name,
                    start_date: element.start_date,
                    end_date: element.end_date,
                    is_national: nationalOrNot
                })
            })
            console.log(tableData)
            // Generación del PDF
            doc.autoTable(columnsNames, tableData, {
                headStyles:{
                    halign: 'center',
                    fillColor: [253, 126, 20],
                },
                bodyStyles:{
                    halign: 'center'
                },
                margin: { top: 180 },
                didDrawPage: function (data) { 
                    doc.setFillColor(253, 126, 20);
                    doc.rect(0, 0, 600, 10, "F");
                    doc.rect(0, 832, 600, 10, "F");
                    doc.addImage("/logo_negro.png", "JPEG", 20, 20, 150, 80);
                }
            })
            // Descarga
            doc.save('Calendario de actividades VIME mes '+this.month+'.pdf');
        },
      updateActivity(){
        this.isLoading=true;
        return axios.get("/get_vime_activities_by_year.json", {params:{
              maxYear: this.year,
              minYear: this.year
            }})
            .then(res => {
              this.activities = res.data.activities;
              this.activities = this.activities.map(activities => {
                var newActivities = activities
                newActivities["start_date"] = moment(newActivities["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
                newActivities["end_date"] = moment(newActivities["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
                return newActivities;
              })
              this.can_create = res.data['can_create']
              this.isLoading=false;
            })
            .catch(e => {
              this.isLoading=false;
              console.log(e);
            });
      }
    }
}
</script>