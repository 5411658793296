<template>
  <div>
    <b-table
      :data="tableData"
      :paginated=true
      per-page=40
      striped
      hoverable
      :current-page="currentPage"
      :card-layout="cardLayout"
      :pagination-simple=false
      pagination-position="bottom"
      default-sort-direction="desc"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort="payment_date"
      aria-next-label="Siguiente"
      aria-previous-label="Anterior"
      aria-page-label="Página"
      aria-current-label="Página actual">
      <b-table-column
          field= 'sdt_origin_transaction_type'
          label= 'Tipo'
          width="10em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{  props.row.sdt_origin_transaction_type || " " }}
        </template>
      </b-table-column>

      <b-table-column
          field= 'sdt_transaction_type'
          label= 'Categoría'
          width="15em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{  props.row.sdt_transaction_type || " " }}
        </template>
      </b-table-column>

      <b-table-column
          field= 'amount'
          label= 'Monto bruto total'
          :custom-search="searchAmount"
          width="15em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ "$"+Number(props.row.amount).toLocaleString('es-ES')  || " "}}
        </template>
      </b-table-column>

      <b-table-column
          field= 'payment_date'
          label= 'Fecha de pago'
          width="10em"
          :custom-search="searchTransactionDate"
          searchable
          sortable>
        <template v-slot="props">
          {{ props.row.payment_date._isValid ? new Date(props.row.payment_date).toLocaleDateString("es-CL"): '' }}
        </template>
      </b-table-column>


      

      <b-table-column
        label="Información"
        width="10em"
        v-slot="props">
        <b-button type="is-primary"
                    icon-left="eye"
                    v-if="props.row.id != null "
                    @click="showTransaction(props.row)">
                    Ir a Transacción
        </b-button>
      </b-table-column>

      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-danger">
              No se encontraron transacciones.
            </b-message>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import {searchDate} from "../../../packs/utilities";
import axios from "axios";
export default {
  props:[
      'transacciones'
  ],
  data(){
    return {
      isLoading: false,
      currentPage: 1,
      cardLayout:false,
      queryType: null,
      transactionId: null,
      tituloModal: "",
      canCancel: ['escape'],
    }
  },
  components:{
  },
  created(){

  },
  methods:{
    searchTransactionDate(row,input){
      return searchDate(row.payment_date,input)
    },
    showTransaction(row){
      window.location.href = '/sdt_transactions/'+row.id
    },
    searchAmount(row,input){
      return String(row.amount).includes(input);
    },



  },
  computed:{
    totalAmount: function () {
      return this.transacciones.reduce((total,item) => total + item.amount,0);
    },
    tableData(){
      const newData = [...this.transacciones];
      newData.push({
        id: null,
        sdt_origin_transaction_type: "Total: ",
        payment_date: "",
        amount: this.totalAmount,
      });
      return newData;
    }
  }
}
</script>
<style scoped>

  .right-button{
    margin-left: auto;
  }
  .left-button{
    margin-right: auto;
  }


</style>