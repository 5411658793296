<template>
  <div>
    <section>
      <div class="modal-card" style="width: 120%" id="modal-event">
        <header class="modal-card-head">
          <p class="modal-card-title">{{modalHeader}}</p>
          <button
              type="button"
              class="delete"
              @click="$emit('close')"/>
        </header>
        <section class="modal-card-body">
          <b-tabs position="is-centered" @input="changeTab">
            <b-tab-item v-for="item in items"
                        :value="item.content"
                        v-bind:key="item.content"
                        :label="item.tab"
                        :icon="item.icon">
            </b-tab-item>

          </b-tabs>
          <!-- COMPONENTE AQUI -->
          <keep-alive>
            <component v-bind:is="currentTabComponent"
                       :participants="project.participants"
                       :cargo-principal="cargoPrincipal"
                       :project="project"></component>
          </keep-alive>
          <!-- FIN COMPONENTE -->
        </section>
      </div>
    </section>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>

import PostulatedProjectGeneralTab from "./PostulatedProjectGeneralTab";
import Participants from './ProjectParticipantsTab.vue';
import Entities from './ProjectAssociatedEntitiesTab.vue';
import PostulatedFinancingsTab from './PostulatedFinancingsTab.vue';
export default {
  name: "ModalShowNullifiedPostulatedProject",
  components: {PostulatedProjectGeneralTab,Entities,PostulatedFinancingsTab,Participants},
  data(){
    return{
      isLoading:false,
      major_project_category:null,
      minor_project_category:null,
      vridei_category:null,
      participantes: [],
      currentPage:1,
      cardLayout:false,
      currentTab: 'PostulatedProjectGeneralTab',
      items: [
        {tab: 'General',content:'PostulatedProjectGeneralTab',icon:"file-alt"},
        {tab: 'Participantes',content:'Participants',icon:"users"},
        {tab: 'Aportes Relacionados',content:'PostulatedFinancingsTab',icon:"file-invoice-dollar"},
        {tab: 'Entidades Relacionadas',content:'Entities',icon:"industry"},

      ]

    }
  },
  props:[
    'project', 'modalHeader', 'cargoPrincipal'
  ],
  methods:{
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    editProject(project_id){
      window.location.href = "/projects/"+project_id+"/edit"
    },
    changeTab: function(value){
      this.currentTab = value
    }

  },
  computed:{
    currentTabComponent: function () {
      return this.currentTab
    }
  }

}
</script>

<style scoped>

</style>