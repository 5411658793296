<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveDocument)">
        <div  v-if="!selected">
          <div class="columns" v-if="!selected">
            <div class="column is-centered">
              <b-message type="is-info" title="Información" has-icon aria-close-label="Close message">
                Debe buscar y seleccionar un servicio para continuar con el proceso de subida masiva de documentos.
              </b-message>
            </div>
          </div>

          <div class="columns" >
            <div class="column">
              <b-field  label="Código">
                <b-input v-model="code" placeholder="Ej: USA2156_FING"></b-input>
              </b-field>
            </div>


            <div class="column">
              <b-field  label="Nombre del servicio">
                <b-input v-model="nameFilter" placeholder="Ej: Herramientas De Trabajo Colaborativo"></b-input>
              </b-field>
            </div>                       
            
          </div>
          <div class="columns has-text-right">
            <div class="column ">
                <b-field >
                <b-button type="is-info"
                          icon-left="search"
                          :disabled="selected"
                          
                          @click="updateServicesFilter()">
                  Buscar
                </b-button>
              </b-field>
            </div>
          </div>
          <hr class="dropdown-divider" aria-role="menuitem">

          
            


          <div class="columns" v-if="isActive && !selected">
            <div class="column is-centered">
              <b-message type="is-info" has-icon aria-close-label="Close message" v-model="isActive">
                {{message}}
              </b-message>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-table
                  :data="servicios"
                  :paginated=true
                  per-page=10
                  striped
                  hoverable
                  :current-page="currentPage"
                  :pagination-simple=false
                  pagination-position="bottom"
                  default-sort-direction="desc"
                  sort-icon="arrow-up"
                  sort-icon-size="is-small"
                  default-sort="servicio.start_date"
                  aria-next-label="Siguiente"
                  aria-previous-label="Anterior"
                  aria-page-label="Página"
                  aria-current-label="Página actual"
                  >
                  <b-table-column
                    field= 'sdt_service_type'
                    label= 'Tipo de servicio'
                    width="20em"
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.sdt_service_type }}
                  </template>
                </b-table-column>

                <b-table-column
                    field= 'code'
                    label= 'Código'
                    width="10em"
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.code}}
                  </template>
                </b-table-column>

                <b-table-column
                    field= 'name'
                    label= 'Nombre servicio'
                    width="25em"
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.name }}
                  </template>
                </b-table-column>

                <b-table-column
                    field= 'unit'
                    label= 'Unidad'
                    width="20em"
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.unit }}
                  </template>
                </b-table-column>


                <b-table-column
                    field= 'award_year'
                    label= 'Año creacion'
                    width="10em"
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.award_year }}
                  </template>
                </b-table-column>
                

                
                <b-table-column
                    field= 'start_date'
                    label= 'Fecha de inicio'
                    searchable
                    :custom-search="searchPublicationDate"
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                      {{ props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL"): '' }}
                  </template>
                </b-table-column>
                

                <b-table-column
                    label="Seleccionar"
                    width="10"
                    v-slot="props">

                    <b-button type="is-primary"
                      icon-left="check"
                      @click="selectService(props.row)">
                    </b-button>  
                </b-table-column>

                <template slot="empty" slot-scope="props">
                  <div class="columns is-centered">
                    <div class="column is-6 is-centered has-text-centered">
                      <b-message type="is-danger">
                        No se encontraron resultados.
                      </b-message>
                    </div>
                  </div>
                </template>


              </b-table>
            </div>
          </div>
        </div>
        <br>
        
        <div v-if="selected">

          <div class="columns has-text-right">
            <div class="column ">
              <b-button type="is-warning"
                        icon-left="undo"
                        @click="refreshService()">
                Cambiar Servicio
              </b-button>
            </div>
          </div>
          <div class="box">
            <h3 class="is-size-4 has-text-weight-bold has-text-centered">Información del Servicio Seleccionado</h3>
            <br>
            <div class="columns" >
              <div class="column">
                <p>
                  <strong>Nombre: </strong>
                  <span>{{selected.name}}</span>
                </p>
              </div>
              <div class="column">
                <p>
                  <strong>Código: </strong>
                  <span>{{selected.code}}</span>
                </p>
              </div>
            </div>
            <div class="columns" >
              <div class="column">
                <p>
                  <strong>Año de creación: </strong>
                  <span>{{selected.award_year}}</span>
                </p>
              </div>
              <div class="column">
                <p>
                  <strong>Tipo servicio: </strong>
                  <span>{{selected.sdt_service_type}}</span>
                </p>
              </div>
            </div>
            <div class="columns" >
              <div class="column">
                <p>
                  <strong>Unidad: </strong>
                  <span>{{selected.unit }}</span>
                </p>
              </div>
              <div class="column">
                <p>
                  <strong>Nombre Jefe(a) de proyecto: </strong>
                  <span>{{selected.projectManagment.person_name}}</span>
                </p>
              </div>
            </div>
            <br>
            <div class="columns has-text-right">
              <div class="column ">
                <b-button
                  class="is-primary card-rounded"
                  icon-left="eye"
                  type="is-primary"
                  @click="goService(selected)"
                  >
                    Ir al servicio
                </b-button>
              </div>
            </div>
          </div>

          <br>
          <div class="columns" >
            <div class="column">
              <p  v-if="procesos.length > 0">
                <strong>Proceso: </strong>
                <span>
                  
                  <ValidationProvider rules="" v-slot="{ errors }">
                      <multiselect v-model="proceso"
                                     :options="procesos.map(type => type.id)"
                                     :custom-label="opt => procesos.find(x => x.id == opt).name"
                                     placeholder="Seleccione Proceso"
                                     selectLabel=""
                                     selectedLabel=""
                                     deselectLabel=""
                                     @select="selectProcess"
                                     :allow-empty="true">
                          <template v-slot:noOptions>
                              No existen datos
                          </template>
                          <span slot="noResult">
                              No se encontraron elementos.
                          </span>
                      </multiselect>
                      <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </span>
              </p> 
              
            </div>
          </div>
          <div>
            <div class="columns">
              <div class="column has-text-centered">
                <b-message class="is-inline-block is-centered" type="is-warning" title="¡Importante!" has-icon aria-close-label="Close message">
                        Extensiones de archivo permitido: PDF, DOC, DOCX, XLSX, XLS.
                        <br />
                        Tamaño máximo de archivo permitido: <b> 10 MB</b>
                  </b-message>
                <b-field extended label="Subir documento*">
                  <ValidationProvider rules="" v-slot="{ errors }">
                    <b-upload v-model="files" :disabled="!proceso" drag-drop multiple>
                        <div class="content has-text-centered">
                            <p>
                                <b-icon icon="file-pdf" size="is-large"></b-icon>
                            </p>
                            <p>Arrastra tu documento o haz click para subir.</p>
                        </div>
                    </b-upload>
                    <br>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider> 
                </b-field>
              </div>
            </div>
            <hr class="dropdown-divider" aria-role="menuitem">
            <h3 class="is-size-4 has-text-weight-bold has-text-centered">Documentos listos para subir</h3>
            <br> <br>
            <b-tabs type="is-toggle-rounded" position="is-centered">
              <b-tab-item label="Documentos listos para subir" icon="copy">
                <b-table
                  :data="documentos"
                  :paginated=true
                  per-page=10
                  striped
                  hoverable
                  :pagination-simple=false
                  pagination-position="bottom"
                  default-sort-direction="desc"
                  sort-icon="arrow-up"
                  sort-icon-size="is-small"
                  default-sort="last_patent_state_date"
                  aria-next-label="Siguiente"
                  aria-previous-label="Anterior"
                  aria-page-label="Página"
                  aria-current-label="Página actual">
                <b-table-column
                    field= 'file.name'
                    label= 'Nombre'
                    width= "10em"
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{  props.row.file.name }}
                  </template>
                </b-table-column>
                <b-table-column
                    field= 'tipo'
                    width="15em"
                    label= 'Tipo de documento'>
                  <template v-slot="props">

                    <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect v-model="props.row.tipo"
                                       :options="tipos.map(type => type.id)"
                                       :custom-label="opt => tipos.find(x => x.id == opt).name"
                                       placeholder="Seleccione Tipo de documento"
                                       selectLabel=""
                                       selectedLabel=""
                                       deselectLabel=""
                                       :allow-empty="true">
                            <template v-slot:noOptions>
                                No existen datos
                            </template>
                            <span slot="noResult">
                                No se encontraron elementos.
                            </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </template>
                </b-table-column>

                <b-table-column
                    field= 'person'
                    width="20em"
                    label= 'Persona'>
                  <template v-slot="props">
                    <div v-if="!procesosPersona.includes(props.row.tipo)">
                      Servicio
                    </div>
                    <div v-else>

                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect 
                            v-model="props.row.person" 
                            :options="selected.integrants" 
                            :custom-label="nameWithLang" 
                            placeholder="Seleccione Persona" 
                            track-by="name"
                            selectLabel=""
                            selectedLabel=""
                            deselectLabel=""
                            :allow-empty="true">
                            <template v-slot:noOptions>
                                  No existen datos
                              </template>
                              <span slot="noResult">
                                  No se encontraron elementos.
                              </span>
                        </multiselect>
                          <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </template>
                </b-table-column>

                <b-table-column
                    field= 'fecha'
                    width="15em"
                    label= 'Fecha'>
                  <template v-slot="props">

                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-datepicker
                        v-model="props.row.fecha"
                        icon="calendar-alt"
                        locale="es-ES"
                        editable
                        trap-focus
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                        append-to-body
                      >
                      </b-datepicker>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </template>
                </b-table-column>
                <b-table-column
                    label="Acción"
                    width="8em"
                    v-slot="props">
                    <b-button class="is-danger" icon-left="trash" @click="deleteFileTab(props.row)">
                    </b-button>
                </b-table-column>

                <template slot="empty" slot-scope="props">
                  <div class="columns is-centered">
                    <div class="column is-6 is-centered has-text-centered">
                      <b-message type="is-danger">
                        No se encontraron documentos.
                      </b-message>
                    </div>
                  </div>
                </template>
              </b-table>
                
              </b-tab-item>
              <b-tab-item label="Documentos con errores" :disabled="documentosCorrectos.length == 0 && documentosIncorrectos.length == 0" icon="exclamation-triangle">

                <b-table
                  :data="documentosIncorrectos"
                  :paginated=true
                  per-page=10
                  striped
                  hoverable
                  :pagination-simple=false
                  pagination-position="bottom"
                  default-sort-direction="desc"
                  sort-icon="arrow-up"
                  sort-icon-size="is-small"
                  default-sort="last_patent_state_date"
                  aria-next-label="Siguiente"
                  aria-previous-label="Anterior"
                  aria-page-label="Página"
                  aria-current-label="Página actual">
                <b-table-column
                    field= 'name'
                    label= 'Nombre documento'
                    width="15em"
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{  props.row.name }}
                  </template>
                </b-table-column>
                <b-table-column
                    field= 'error'
                    label= 'Tipo de error'
                    width="15em"
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{  props.row.error }}
                  </template>
                </b-table-column>                

                <template slot="empty" slot-scope="props">
                  <div class="columns is-centered">
                    <div class="column is-6 is-centered has-text-centered">
                      <b-message type="is-danger">
                        No se encontraron documentos erroneos.
                      </b-message>
                    </div>
                  </div>
                </template>
              </b-table>

                
              </b-tab-item>

              <b-tab-item label="Documentos subidos" :disabled="documentosCorrectos.length == 0 && documentosIncorrectos.length == 0" icon="file-upload">
                <b-table
                  :data="documentosCorrectos"
                  :paginated=true
                  per-page=10
                  striped
                  hoverable
                  :pagination-simple=false
                  pagination-position="bottom"
                  default-sort-direction="desc"
                  sort-icon="arrow-up"
                  sort-icon-size="is-small"
                  default-sort="last_patent_state_date"
                  aria-next-label="Siguiente"
                  aria-previous-label="Anterior"
                  aria-page-label="Página"
                  aria-current-label="Página actual">
                <b-table-column
                    field= 'original_name'
                    label= 'Nombre documento'
                    width="15em"
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{  props.row.original_name }}
                  </template>
                </b-table-column>
                <b-table-column
                    field= 'new_name'
                    label= 'Nombre nuevo de documento'
                    width="15em"
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{  props.row.new_name }}
                  </template>
                </b-table-column>                

                <template slot="empty" slot-scope="props">
                  <div class="columns is-centered">
                    <div class="column is-6 is-centered has-text-centered">
                      <b-message type="is-danger">
                        No se encontraron documentos subidos correctamente.
                      </b-message>
                    </div>
                  </div>
                </template>
              </b-table>
                
              </b-tab-item>
            </b-tabs>
          </div>

          <br>
          <br>
          <div class="columns is-justify-content-center mx-4">
            <b-field expanded>
              <b-button  
                class="is-primary card-rounded" 
                left-button="save"
                icon-left="save" 
                native-type="submit" 
                @click="checkValidation()"
                :disabled="files.length == 0">
                  Adjuntar Documentos
              </b-button>
            </b-field>
          </div>




        </div>
      </form>
    </ValidationObserver>        
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import {searchDate} from "../../packs/utilities";
import axios from "axios";
import moment from "moment";
export default {
  props:[
      'cargoPrincipal', 'unidad'
  ],
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      servicios: [],
      code: '',
      files: [],
      documentos: [],
      documentosCorrectos: [],
      documentosIncorrectos: [],
      procesos:[],
      procesosPersona: [],
      proceso: null,
      tipos: [],
      nameFilter: '',
      isActive:false,
      selected: null,
      message: "",
    }
  },
  watch: {
    files: {
      handler(newFiles) {
        // Aquí actualizamos el arreglo 'documentos' cuando se seleccionan nuevos archivos
        this.documentos = newFiles.map(file => {
          const existingDocumento = this.documentos.find(doc => doc.file.name === file.name);
          if (existingDocumento) {
            return {
              file: file,
              person: existingDocumento.person || false,
              tipo: existingDocumento.tipo || '',
              fecha: existingDocumento.fecha || new Date()
            };
          } else {
            return {
              file: file,
              tipo: '',
              person: false,
              fecha: new Date()
            };
          }
        });
      },
      deep: true
    }
  },
  async created(){
    let vm = this;
    vm.isLoading=true;
    await vm.getProcesosPerson();
    axios.get("/origin_document_types/origin-document-sdt.json", {params:{type: "all"}})
      .then(res => {
        vm.procesos = res.data;
        vm.isLoading=false;
      })
      .catch(e => {
        vm.isLoading=false;
        //console.log(e);
      });
  },

  methods:{

    updateServicesFilter(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/sdt_services.json", {params:{
                                              name:vm.nameFilter,
                                              code:vm.code,
                                              }})
        .then(res => {
          vm.servicios = res.data;
          vm.servicios = vm.servicios.map(servicio => {
            var newServicio = servicio
            if(newServicio["start_date"]!=null)
              newServicio["start_date"] = moment(servicio.start_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            return newServicio;
          });
          vm.message = "Se han encontrado "+vm.servicios.length+" servicios que cumplen su búsqueda"
          vm.isActive=true;
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    selectProcess(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/document_type_names/document-names.json",{params:{id:vm.proceso, all: true}})
        .then(res => {
          vm.tipos = [];
          vm.files = [];
          vm.tipos = res.data;
          vm.isLoading = false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });

    },
    nameWithLang ({ name, person_name, run }) { 
      let msg_run = ""
      if (run != null){
        if (run.passport != null){
          msg_run = " ["+run.passport+"]" 
        }
        if (run.rut != null){
          msg_run = " ["+run.rut.toString()+"-"+run.dv+"]"
        }
      }
      let msj = ""
          
      if (name != null )
      {
        msj = name + msg_run 
      }
      else{
        msj = person_name + msg_run 
      }
      return msj
    },
    refreshService(){
      this.documentos = [];
      this.documentosCorrectos = [],
      this.documentosIncorrectos = [],
      this.tipos = [];
      this.proceso = null;
      this.files = [];
      this.selected = null;
    },
    goService(service){
      window.location.href = "/sdt_services/"+service.id+"?type="+service.table;
    },

    searchPublicationDate(row,input){
      return searchDate(row.start_date,input)
    },
    deleteDropFile(index) {
        this.files.splice(index, 1)
    },
    deleteFileTab(dropFile){
      const index = this.documentos.indexOf(dropFile);
      if (index !== -1) {
        this.documentos.splice(index, 1);
        this.files.splice(index,1);
      }
    },
    async checkValidation(){
      const valid = await this.$refs.observer.validate()
      if(!valid){
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. ',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
    async getProcesosPerson() {
      let vm = this;
      
      return axios.get("/document_type_names/all-origin-types-sdt.json", {params:{type: "person"}})
        .then(res => {
          vm.procesosPersona = res.data;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    selectService(row){
      this.selected = row;
      this.selected.integrants = this.selected.integrants.filter(elemento => elemento.run !== null && elemento.run !== undefined);

    },

    saveDocument(){
      let vm = this;
      let run = ""
      let id_run = "" 
      let id_service = "";
      let table_name = "";
      run = vm.selected.projectManagment.run.rut.toString();
      id_run = vm.selected.projectManagment.run_id;
      id_service = vm.selected.id;
      table_name = vm.selected.table;


      const formData = new FormData();

      // Agregar otros datos al FormData, como tipos y fechas
      this.documentos.forEach((documento, index) => {
        formData.append(`file${index}`, documento.file);
        formData.append(`type${index}`, documento.tipo);
        formData.append(`date${index}`, moment(documento.fecha).format("MM_YYYY"));
        formData.append(`table${index}`, table_name);
        formData.append(`id_service${index}`, id_service);
        if(documento.person){
          formData.append(`id_run${index}`, documento.person.run.id);
          if(documento.person.run.rut){
            formData.append(`run${index}`, documento.person.run.rut.toString());
          }
          else{
            formData.append(`run${index}`, documento.person.run.passport);
          }
        }
        else{
          formData.append(`run${index}`, run);
          formData.append(`id_run${index}`, id_run);
        }
        formData.append(`document_date${index}`, documento.fecha);
      });

      vm.isLoading = true;
      axios.post("/documents/masive-upload-sdt.json", formData)
        .then((res) => {
          vm.documentosCorrectos = res.data.correctos;
          vm.documentosIncorrectos = res.data.errores;
          let msg = "" ;
          if (res.data.correctos.length>0){
            msg = `<br>Se han adjuntado <b> Correctamente </b> ${res.data.correctos.length} documentos. <br>`;
          }
          if(res.data.errores.length>0){
            msg = msg + `<br>Han ocurrido <b> Problemas </b> con ${res.data.errores.length} documentos que no pudieron ser adjuntados.<br>`;
          }
          msg = msg + '<br>El detalle de la información se encuentra en las pestañas "Documentos con errores" y "Documentos subidos".'
          vm.proceso = null;
          vm.documentos = [];
          vm.files = [];
          vm.isLoading = false;
          this.$buefy.dialog.alert({
            message: msg ,
            type: 'is-info',
            hasIcon: true,
            confirmText: "Ok",            
          });



        })
        .catch(e => {
        console.log(e);
        this.$buefy.dialog.alert({
          message: 'Error en el proceso de guardado, intente más tarde.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        vm.isLoading=false;
      });       
      
    },



  },
  computed:{

  }
}
</script>

<style>
.multiselect__option {
  white-space: normal; /* permite que el texto de las opciones se ajuste verticalmente */
  word-wrap: break-word; /* permite que el texto de las opciones se divida en líneas */
  max-width: 100%; /* asegura que el texto no se extienda más allá del contenedor */
}
</style>