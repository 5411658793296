<template>
    <section>

        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
                <b-message type="is-danger" has-icon>
                    <div v-for="(item, index) in errores_validacion" :key="index">
                        {{ item[0] }}
                    </div>
                </b-message>
            </div>


            <form @submit.prevent="handleSubmit(saveMyAcademicData)">

                <div class="columns">

                    <div class="column">
                        <ValidationProvider rules="min:3|max:30" v-slot="{errors}">
                            <b-field :type="errors[0] ? 'is-danger' : '' " label="Alias" :message="errors[0]">
                                <b-input type="text" maxlength="30" v-model="person.alias" />
                            </b-field>
                        </ValidationProvider>
                    </div>

                    <div class="column">
                        <ValidationProvider rules="min:3|max:255" v-slot="{errors}">
                            <b-field :type="errors[0] ? 'is-danger' : '' " label="ORCID" :message="errors[0]">
                                <b-input expanded type="text" v-model="person.orcid" />
                                <b-button tag="a"
                                    :href="person.orcid ? 'https://orcid.org/' + person.orcid : 'https://orcid.org/'"
                                    target="_blank" icon-left="external-link-alt" type="is-info" label="Link" />
                            </b-field>
                        </ValidationProvider>
                    </div>

                </div>

                <div class="columns">

                    <div class="column">
                        <ValidationProvider rules="integer|min:3|max:255|positive" v-slot="{errors}">
                            <b-field :type="errors[0] ? 'is-danger' : '' " label="ID Scopus" :message="errors[0]">
                                <b-input expanded type="number" v-model="person.scopus_id" />
                                <b-button tag="a" :href="person.scopus_id ? 'https://www.scopus.com/authid/detail.uri?authorId=' + person.scopus_id : 'https://www.scopus.com/'"
                                    target="_blank" icon-left="external-link-alt" type="is-info" label="Link" />
                            </b-field>
                        </ValidationProvider>
                    </div>

                    <div class="column">
                        <ValidationProvider rules="min:3|max:255" v-slot="{errors}">
                            <b-field :type="errors[0] ? 'is-danger' : '' " label="ID Wos" :message="errors[0]">
                                <b-input expanded type="number" v-model="person.wos_id" />
                                <b-button tag="a" :href="person.wos_id ? 'https://www.webofscience.com/wos/author/record/' + person.wos_id : 'https://www.webofscience.com/'"
                                    target="_blank" icon-left="external-link-alt" type="is-info" label="Link" />
                            </b-field>
                        </ValidationProvider>
                    </div>

                    <div class="column">
                        <ValidationProvider rules="min:3|max:255" v-slot="{errors}">
                            <b-field :type="errors[0] ? 'is-danger' : '' " label="ID Scielo" :message="errors[0]">
                                <b-input type="number" v-model="person.scielo_id" />
                            </b-field>
                        </ValidationProvider>
                    </div>

                </div>

                <div class="columns">

                    <div class="column">
                        <ValidationProvider rules="integer|positive|min:1|max:10" v-slot="{ errors }">
                            <b-field :type="errors[0] ? 'is-danger' : '' " label="H-Index" :message="errors[0]">
                                <b-input type="number" v-model="person.h_index" />
                            </b-field>
                        </ValidationProvider>
                    </div>

                </div>

                <div v-if="person.can_update_my_academic_activity" class="columns">
                    <div class="column has-text-right">
                        <button type="submit" class="button is-primary" @click="checkValidation">
                            <span class="icon">
                                <i class="fas fa-save"></i>
                            </span>
                            <span>Guardar Datos de Investigación</span>
                        </button>
                    </div>
                </div>

            </form>

        </ValidationObserver>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"/>

    </section>
</template>

<script>
import axios from "axios"
export default {
    props: [
        'personId'
    ],

    data() {
        return {
            isLoading: false,
            person: {},
            errores_validacion:[],
        }
    },

    async created() {
        await this.getPerson();
    },

    methods: {
        getPerson() {
            this.isLoading = true;
            axios
                .get("/people/" + this.personId + ".json")
                .then(res => {
                    this.person = res.data
                    this.isLoading = false
                })
                .catch(e => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: 'Hubo un error al traer los datos de Investigación de la persona consultada, favor de revisar la consola.',
                        type: 'is-danger'
                    })
                    console.log(e)
                    this.isLoading = false
                })
        },

        async checkValidation() {
            const valid = await this.$refs.observer.validate()
            if (!valid) {
                this.$buefy.dialog.alert({
                    message: 'Algunos campos presentan errores.',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
            }
        },


        saveMyAcademicData() {
            this.isLoading = true
            axios
                .put("/people/" + this.personId + "/my-academic-data/update.json", {
                    person: this.person,
                },
                )
                .then(response => {
                    this.errores_validacion = []
                    this.$buefy.dialog.alert({
                        title: 'Logrado',
                        message: 'Tus datos de Investigación han sido actualizados satisfactoriamente.',
                        type: 'is-success',
                        hasIcon: true,
                        iconPack: 'fa',
                        icon: 'check-circle',
                        confirmText: 'Ok',
                    })
                    this.isLoading = false;
                    this.getPerson();
                }).
                catch(error => {
                    this.errores_validacion = error.response.data
                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al guardar tus datos de Investigación, favor de revisar los errores.',
                        type: 'is-danger',
                        hasIcon: true,
                        iconPack: 'fa',
                        icon: 'times-circle',
                        confirmText: 'Ok',
                    })
                    var elmnt = document.getElementById("app");
                    elmnt.scrollIntoView();
                    this.isLoading = false;
                })
        },

    }
}
</script>