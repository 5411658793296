<template>
  <section>
    <b-steps
        v-model="activeStep"
        :animated="isAnimated"
        :rounded="isRounded"
        :has-navigation="hasNavigation"
        :icon-prev="prevIcon"
        :icon-next="nextIcon"
        :label-position="labelPosition"
        :mobile-mode="mobileMode">
      <b-step-item step="1" label="Importación archivo de aumento de horas" :clickable="isStepsClickable">
        <h1 class="title has-text-centered">Importación archivo de aumento de horas</h1>
        <b-tabs position="is-centered" class="block">
          <b-tab-item label="Importación archivo de aumento de horas" >
            <ValidationObserver v-slot="{handleSubmit}">
              <form @submit.prevent="handleSubmit(import_file)">
                <div class="box">
                  <div class="columns">
                    <div class="column is-centered">
                      <b-message type="is-info" has-icon v-if="importacion">
                        <b>Nombramientos importados correctamente: </b> {{nombramientos.length}} de {{total_nombramientos}}<br/>
                      </b-message>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field grouped group-multiline>
                        <div class="control">
                          <h1 class="title is-4">Seleccionar Centro de Costo de archivo de aumento de horas</h1>
                        </div>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field>
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <multiselect v-model="unit"
                                       :options="units.map(unit => unit.cost_center)"
                                       :custom-label="opt => units.find(x => x.cost_center === opt).show_name"
                                       placeholder="Seleccione unidad"
                                       selectLabel="Presione para seleccionar"
                                       selectedLabel="Seleccionado"
                                       deselectLabel="No se puede deseleccionar"
                                       :allow-empty="false">
                            <template v-slot:noOptions>
                              No existen datos
                            </template>
                            <span slot="noResult">
                          No se encontraron elementos.
                      </span>
                          </multiselect>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field grouped group-multiline>
                        <div class="control">
                          <h1 class="title is-4">Seleccionar año y semestre del aumento de horas</h1>
                        </div>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="Año*">
                        <ValidationProvider rules="required|numeric|min_value:1980" v-slot="{errors}">
                          <b-input v-model="year"></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Semestre*">
                        <ValidationProvider rules="required|numeric|between:1,2" v-slot="{errors}">
                          <b-input v-model="semester"></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns" v-if="unit!==null">
                    <div class="column">
                      <b-field grouped group-multiline>
                        <div class="control">
                          <h1 class="title is-4">Seleccionar archivo de aumento de horas de nombramientos profesores horas</h1>
                          <h2 class="subtitle is-6 has-text-weight-bold">*El archivo debe tener la extensión .xlsx.</h2>
                        </div>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div v-if="unit!==null">
                        <b-field class="file">
                          <b-upload v-model="file" expanded>
                            <a class="button is-primary is-outlined">
                              <b-icon icon="upload"></b-icon>
                              <span>{{ file.name || "Subir Archivo"}}</span>
                            </a>
                          </b-upload>
                        </b-field>
                      </div>
                    </div>
                    <div class="column">
                      <div class="has-text-right">
                        <b-button
                            label="Ver Ejemplo"
                            type="is-primary"
                            @click="isModalSpreadSheet = true" />
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="has-text-right">
                        <button class="button is-primary" :disabled="file.name.length===0" type="submit">Importar</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </b-tab-item>
          <b-tab-item label="Errores y advertencias de importación" v-if="importacion">
            <div class="columns">
              <div class="column is-centered">
                <b-message type="is-info" has-icon>
                  <b>Advertencias:</b> Inconsistencias respecto a las asignaturas del archivo de importación y las asignaturas de SIGA. No impiden que se realice el aumento de horas, pero podría provocar la duplicación de asignaturas.<br/>
                  <b>Errores:</b> Problemas en el archivo de importación que impiden completamente el aumento de horas. Pueden ser errores de categoría, cantidad de horas (aumento de horas) o errores de tipeo que pueden ser arreglados al modificar el archivo de importación.
                </b-message>
              </div>
            </div>
            <div v-if="errores.length>0" class="columns is-centered">
              <div class="column is-11" v-if="errores.length >0">
                <div class="box">
                  <h1 class="is-size-5">
                    <b-icon
                        pack="fas"
                        icon="exclamation-triangle">
                    </b-icon>
                    Errores del archivo: {{ this.file_name_errors }}
                  </h1>
                  <div v-if="import_type != 'Convenios'" class="ml-5" v-for="error in errores">
                    Fila n° {{error.FILA}}: {{ error.ERRORES }}
                  </div>
                  <div v-if="import_type == 'Convenios'" class="ml-5" v-for="error in errores">
                    Hoja:{{error.PAGINA}} -> Fila n° {{error.FILA}}: {{ error.ERRORES }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="advertencias.length>0" class="columns is-centered">
              <div class="column is-11" v-if="advertencias.length>0">
                <div class="box">
                  <h1 class="is-size-5">
                    <b-icon
                        pack="fas"
                        icon="exclamation-triangle">
                    </b-icon>
                    Advertencias del archivo: {{ this.file_name_errors }}
                  </h1>
                  <div v-if="import_type != 'Convenios'" class="ml-5" v-for="advertencia in advertencias">
                    Fila n° {{advertencia.FILA}}: {{ advertencia.ERRORES }}
                  </div>
                </div>
              </div>
            </div>
            <div class="columns has-text-right">
              <div class="column">
                <b-button @click="importErrors" v-if="errores.length!=0 || advertencias.length != 0" icon-left="file-excel" class="is-excel-color">Exportar errores y advertencias en excel</b-button>
              </div>
            </div>
          </b-tab-item>
        </b-tabs>
      </b-step-item>
      <b-step-item step="2" label="Aumento de Horas" :clickable="isStepsClickable" v-if="importacion">
        <b-tabs position="is-centered" class="block" v-if="importacion">
          <b-tab-item label="Aumento de Horas">
            <div class="box">
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(submitAppointments)">
                  <div class="columns">
                    <div class="column">
                      <b-field grouped group-multiline>
                        <div class="control">
                          <h1 class="title is-5">Completar datos de aumento de horas</h1>
                        </div>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-centered">
                      <b-message type="is-info" has-icon v-if="postIncrease&&!allow_duplication">
                        <b>Aumentos de hora realizados correctamente: </b> {{successful_increase}} de {{checkedRows.length}}.<br/>
                      </b-message>
                      <b-message type="is-info" has-icon v-if="postIncrease&&allow_duplication">
                        <b>*Debido a la separación de nombramientos, cada aumento de horas se compone de dos nombramientos.</b><br/>
                        <b>Aumentos de hora realizados correctamente: </b> {{successful_increase}} de {{checkedRows.length}}.<br/>
                        <b>Nombramientos totales creados correctamente: </b> {{successful_increase_d}} de {{checkedRows.length * 2}}.<br/>
                      </b-message>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                        <b-message type="is-danger" has-icon>
                          <div v-for="(item,index) in errores_validacion">
                            {{ item[0] }}
                          </div>
                        </b-message>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="Fecha de inicio*">
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <b-datepicker
                              v-model="start_date"
                              icon="calendar-alt"
                              trap-focus
                              locale="es-ES"
                              editable
                              placeholder="Formato de Fecha: dd/mm/aaaa"
                              @input="validDate(start_date, end_date)"
                          >
                          </b-datepicker>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Fecha de término*">
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <b-datepicker
                              v-model="end_date"
                              icon="calendar-alt"
                              trap-focus
                              locale="es-ES"
                              editable
                              placeholder="Formato de Fecha: dd/mm/aaaa"
                              @input="validDate(start_date, end_date)"
                          >
                          </b-datepicker>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="Fecha de documento*">
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <b-datepicker
                              v-model="new_res_date"
                              icon="calendar-alt"
                              trap-focus
                              locale="es-ES"
                              editable
                              placeholder="Formato de Fecha: dd/mm/aaaa"
                          >
                          </b-datepicker>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Tipo de documento*">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <multiselect v-model="new_res_type"
                                       :options="resolution_types.map(type => type.id)"
                                       :custom-label="opt => resolution_types.find(x => x.id === opt).name"
                                       placeholder="Seleccione tipo de documento"
                                       selectLabel="Presione para seleccionar"
                                       selectedLabel="Seleccionado"
                                       deselectLabel="Presione para deseleccionar"
                                       :allow-empty="true">
                            <template v-slot:noOptions>
                              No existen datos
                            </template>
                            <span slot="noResult">
                              No se encontraron elementos.
                          </span>
                          </multiselect>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Número de documento*">
                        <ValidationProvider rules="integer|positive|required" v-slot="{ errors }">
                          <b-input v-model="new_res_number" type="text" ></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="Url documentación">
                        <b-input v-model="documentation_link" type="text" maxlength="255"></b-input>
                      </b-field>
                    </div>
                  </div>
                  <div class="column">
                    <div class="has-text-right">
                      <button class="button is-primary"type="submit" :disabled="!valid_dates">Aplicar Aumento de Horas</button>
                    </div>
                  </div>
                  <b-table
                      :data="nombramientos"
                      :paginated= true
                      per-page="20"
                      striped
                      hoverable
                      :narrowed=true
                      :current-page="currentPage"
                      :pagination-simple=false
                      pagination-position="both"
                      default-sort-direction="asc"
                      sort-icon="arrow-up"
                      sort-icon-size="is-small"
                      default-sort="product.title"
                      aria-next-label="Siguiente"
                      aria-previous-label="Anterior"
                      aria-page-label="Página"
                      aria-current-label="Página actual"
                      :checked-rows.sync="checkedRows"
                      checkable
                      :checkbox-position="checkboxPosition"
                      @check-all="selectAll">
                    <b-table-column
                        field="index"
                        label="N°">
                      <template v-slot="props">
                        {{ nombramientos.indexOf(props.row) + 1 }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="person_rut"
                        label="Rut"
                        sortable
                        searchable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.person_rut}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="person_name"
                        label="Nombre"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.person_name}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="establishment"
                        label="Estamento"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.establishment!==undefined ? props.row.establishment.name : ""}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="unit"
                        label="Unidad de Desempeño"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.unit!==undefined ? props.row.unit.name : ""}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="taken_hours"
                        label="Horas"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.taken_hours}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="asignaturas"
                        label="Asignaturas"
                        width="110"
                        sortable
                        searchable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.asignaturas}}
                      </template>
                    </b-table-column>



                    <template slot="empty" slot-scope="props">
                      <div class="columns is-centered">
                        <div class="column is-6 is-centered has-text-centered">
                          <b-message type="is-danger">
                            No se encontraron resultados para filtros aplicados.
                          </b-message>
                        </div>
                      </div>
                    </template>
                  </b-table>

                </form>
              </ValidationObserver>
            </div>
          </b-tab-item>
          <b-tab-item label="Errores aumento de horas" v-if="errores_aumento.length>0">
            <b-table
                :data="errores_aumento"
                :paginated= true
                per-page="20"
                striped
                hoverable
                :narrowed=true
                :current-page="currentPage_2"
                :pagination-simple=false
                pagination-position="both"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="product.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
              <b-table-column
                  field="index"
                  label="N°">
                <template v-slot="props">
                  {{ errores_aumento.indexOf(props.row) + 1 }}
                </template>
              </b-table-column>
              <b-table-column
                  field="rut"
                  label="Rut"
                  sortable
                  searchable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{ props.row.rut}}
                </template>
              </b-table-column>
              <b-table-column
                  field="person_name"
                  label="Nombre"
                  searchable
                  sortable
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.person_name}}
                </template>
              </b-table-column>
              <b-table-column
                  field="motivo"
                  label="Razón de error"
              >
                <template v-slot="props">
                  {{props.row.motivo}}
                </template>
              </b-table-column>
              <template slot="empty" slot-scope="props">
                <div class="columns is-centered">
                  <div class="column is-6 is-centered has-text-centered">
                    <b-message type="is-danger">
                      No se encontraron resultados para filtros aplicados.
                    </b-message>
                  </div>
                </div>
              </template>
            </b-table>
          </b-tab-item>
        </b-tabs>
      </b-step-item>
    </b-steps>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="isAlertModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal-confirm-duplication-for-extension :person-id="person_unit.person_id"
                                                 :id="person_unit.id"
                                                 :person_unit="person_unit"
                                                 modal-header="Confirmar Nombramientos"
                                                 @close="props.close"
                                                 @clicked_save="onClickSave"
                                                 @clicked_duplicate="onClickDuplicate">
        </modal-confirm-duplication-for-extension>
      </template>
    </b-modal>
    <b-modal
        v-model="isModalSpreadSheet"
        has-modal-card
        trap-focus
        full-screen
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal-spreadsheet-continuity
            modal-header="Cabeceras y Ejemplo"
            @close="props.close">
        </modal-spreadsheet-continuity>
      </template>
    </b-modal>


  </section>
</template>
<script>
import axios from "axios"
import moment from "moment";
import {searchDate, formatDate} from "../../../packs/utilities";
import ModalConfirmDuplicationForExtension from "../../life_record/modal_confirm_duplication_for_extension"
import ModalSpreadsheetContinuity from "../../life_record/spreadsheet/spreadsheet_continuity"

export default {
  name: "IncreaseHours",
  components:{
    ModalConfirmDuplicationForExtension,
    ModalSpreadsheetContinuity
  },
  data(){
    return{
      import_type:"",
      file:{name:""},
      dropFiles: [],
      isLoading:false,
      errores:[],
      file_name_errors:"",
      cabeceras:[],
      hojas:[],
      errorFile:{name:""},
      units: [],
      unit: null,
      semester: null,
      nombramientos: [],
      importacion: false,
      start_date: null,
      end_date: null,
      errores_aumento: [],
      new_res_date: null,
      new_res_type: null,
      new_res_number: null,
      resolution_types: [],
      checkedRows: [],
      checkboxPosition: "right",
      allow_duplication: false,
      date_validation: true,
      currentPage: 1,
      currentPage_2: 1,
      isAlertModalActive: false,
      errores_validacion:{},
      valid_dates: false,
      person_unit: {},
      year: null,
      isModalSpreadSheet: false,
      activeStep: 0,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      hasNavigation: true,
      customNavigation: false,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      advertencias: [],
      total_nombramientos: 0,
      message: "",
      postIncrease: false,
      successful_increase: 0,
      successful_increase_d: 0,
      documentation_link: ""
    }
  },
  created(){
    this.initialize()
  },
  props:[

  ],
  methods:{
    updateAppointments(){
      this.isLoading = true;
      if(this.checkedRows.length === 0){
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Debe seleccionar al menos un nombramiento',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = false;
        return
      }
      axios
          .post("/life_record/exec-increase-hours.json", {
            appointments: this.checkedRows, start_date: this.start_date,
            end_date: this.end_date, new_res_date: this.new_res_date, new_res_type: this.new_res_type, new_res_number: this.new_res_number,
            date_validation: this.date_validation, allow_duplication: this.allow_duplication, semester: this.semester, year: this.year,
            documentation_link: this.documentation_link
          })
          .then(response =>{
            this.errores_validacion = response.data["errores_hash"];
            this.errores_aumento= response.data["errores_array"];
            this.successful_increase = response.data["successful_created"]
            this.successful_increase_d = response.data["successful_created_d"]
            this.postIncrease = true
            if(this.errores_aumento.length>0 && this.successful_increase > 0){
              this.$buefy.dialog.alert({
                message: 'Los aumentos de horas fueron creados con éxito, pero sucedieron algunos errores',
                confirmText: 'Ok'
              })
            }
            else if(this.successful_increase === 0 && this.errores_aumento.length > 0){
              this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Los aumentos de horas no fueron creados con éxito',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
            }
            else{
              this.$buefy.dialog.alert({
                message: 'Los aumentos de horas fueron creados con éxito ',
                confirmText: 'Ok'
              })
            }
            this.isLoading=false;
            this.errors = [];
            this.$emit('close')
          }).catch(error => {
        this.errores_validacion = error.response.data
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo errores al intentar realizar el aumento de horas de los nombramientos seleccionados. Revise los datos ingresados.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = false;
      })
    },
    onClickSave(value){
      this.person_unit.date_validation = value
      this.date_validation = value
      this.updateAppointments()
    },
    onClickDuplicate(value){
      this.allow_duplication = value
      this.updateAppointments()
    },
    selectAll(rows) {
      if (rows.length > this.checkedRows.length) {
        this.$nextTick(() => { this.checkedRows = this.nombramientos})
      } else {
        this.$nextTick(() => {
          this.checkedRows = []
        })
      }
    },
    searchEndDate(row,input){
      return searchDate(row.end_date,input)
    },
    searchStart(row,input){
      return searchDate(row.start_date,input)
    },
    submitAppointments(){
      this.person_unit.start_date = this.start_date
      this.person_unit.end_date = this.end_date
      var start_date_moment = moment(this.start_date, "MM/DD/YYYY")
      var end_date_moment = moment(this.end_date, "MM/DD/YYYY")
      var between_date = moment("12/31/"+start_date_moment.year().toString(), "MM/DD/YYYY")
      if(Math.abs(start_date_moment.year()-end_date_moment.year())>1){
        this.allow_duplication = false
        this.isAlertModalActive = true
        this.isLoading = false;
        return
      }
      else if(between_date.isBetween(start_date_moment, end_date_moment)){
        this.allow_duplication = false
        this.isAlertModalActive = true
        this.isLoading = false;
        return
      }
      else if(start_date_moment.date()===31 && start_date_moment.month()+1===12){
        this.isAlertModalActive = true
        this.isLoading = false;
        return
      }
      else if(end_date_moment.date()===31 && end_date_moment.month()+1===12){
        this.isAlertModalActive = true
        this.isLoading = false;
        return
      }
      else{
        this.updateAppointments()
      }
    },
    validDate(start_date, end_date){
      if(start_date && end_date){
        this.valid_dates = true
      }
      else{
        this.valid_dates = false
      }
    },
    initialize(){
      this.isLoading = true
      this.person_unit.person_id = this.personId
      return axios
          .get("/units/return_dptos_ingenieria.json")
          .then(res => {
            this.units = res.data
            this.isLoading = false
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false
          })
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    import_file(){
      this.isLoading = true;
      this.deleteData()
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('unit', this.unit);
      formData.append('year', this.year);
      formData.append('semester', this.semester)
      this.errorFile = this.file;
      axios.post("/life_record/import-increase-hours.json",
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(response =>{
        if (response.data["errores"].length>0){
          this.file_name_errors = this.file.name;
          this.errores = response.data["errores"];
          this.advertencias = response.data["advertencias"]
          this.cabeceras = response.data["cabeceras"];
          this.total_nombramientos = response.data["total_nombramientos"]
          this.importacion = true
          this.nombramientos = response.data["nombramientos"]
          this.resolution_types = response.data["resolution_types"]
          this.nombramientos = this.nombramientos.map(personUnit => {
            var newPersonUnit = personUnit
            newPersonUnit["start_date"] = moment(newPersonUnit["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
            newPersonUnit["end_date"] = moment(newPersonUnit["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
            newPersonUnit["new_res_date"] = moment(newPersonUnit["new_res_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
            return newPersonUnit;
          });
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: 'Algunos datos del archivo "'+this.file.name+'" no han podido ser importados, por favor, revisar errores',
            confirmText: 'Ok',
            type: 'is-warning',
            hasIcon: true,
            icon: 'exclamation-triangle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        }
        else{
          this.import_type = '';
          this.importacion = true
          this.cabeceras = response.data["cabeceras"];
          this.advertencias = response.data["advertencias"]
          this.nombramientos = response.data["nombramientos"]
          this.resolution_types = response.data["resolution_types"]
          this.total_nombramientos = response.data["total_nombramientos"]
          this.nombramientos = this.nombramientos.map(personUnit => {
            var newPersonUnit = personUnit
            newPersonUnit["start_date"] = moment(newPersonUnit["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
            newPersonUnit["end_date"] = moment(newPersonUnit["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
            newPersonUnit["new_res_date"] = moment(newPersonUnit["new_res_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
            return newPersonUnit;
          });
          this.$buefy.dialog.alert({
            message: 'Su archivo "'+this.file.name+'" ha sido importado con éxito',
            confirmText: 'Ok'
          })
          this.file={name:""};
          this.errores = [];
          this.file_name_errors="";
          this.isLoading = false;

        }

      })
          .catch(error => {
            //console.log('FAILURE!!');
            this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Hubo errores en la importación de su archivo. Puede ser un archivo no válido, presenta errores de formato o el centro de costo no coincide con el indicado',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.isLoading = false;
          });
    },
    importErrors(){
      let vm = this;
      vm.isLoading=true;
      axios
          .post("/life_record/" + "export-errors-increase.xlsx",{cabeceras:vm.cabeceras,errores:vm.errores,hojas:vm.hojas, unit: vm.unit, advertencias: vm.advertencias},
              {
                responseType: "blob"
              })
          .then(response =>{
            vm.isLoading=false;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Errores y advertencias aumento de horas masiva '+vm.errorFile.name.replace(".xlsx","")+' '+new Date().toLocaleDateString()+'.xlsx');
            document.body.appendChild(link);
            link.click();
          }).catch(error => {
        vm.isLoading=false;
        vm.$buefy.notification.open({
          message: 'Hubo un error en la solicitud',
          type: 'is-danger'
        })
      })


    },
    deleteData(){
      this.start_date = null
      this.end_date = null
      this.new_res_date = null
      this.new_res_type = null
      this.new_res_number = null
      this.date_validation = true
      this.allow_duplication = false
      //this.semester = null
      //this.year = null
      this.documentation_link = null
      this.importacion = false;
      this.successful_increase = 0
      this.successful_increase_d = 0
      this.file_name_errors = "";
      this.errores = [];
      this.advertencias = [];
      this.cabeceras = [];
      this.checkedRows = [];
      this.errores_validacion = {}
      this.postIncrease = false;
    }
  },
  computed:{

  }
}
</script>