<template>
  <section>

    <patents-table v-bind:patentes="this.patentes" v-bind:unit-id="this.objectId" :hide-update="true" :cargo-principal="cargoPrincipal" @updatePatents="updatePatents"></patents-table>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  </section>
</template>
<script>
import axios from "axios";
import PatentsTable from "../patents/patents_table.vue";
import moment from "moment";
export default {
  props:[
    'objectId','componentType', 'cargoPrincipal', 'currentYear', 'minYear','maxYear', 'changeFilter', 'hideUpdate'
  ],
  data(){
    return{
      patentes:[],
      isLoading:false,
      year: new Date().getFullYear(),
    }
  },
  components:{
    PatentsTable
  },
  created(){
    this.getPatents();
  },
  methods:{
    getPatents(){
      let vm = this;
      vm.isLoading=true;
      var url = "";
      if (vm.componentType == "person"){
        url = "/people/"+this.objectId+"/patents.json"
      }
      else if (vm.componentType=="unit"){
        url = "/admin/units/"+this.objectId+"/patents.json"
      }
      if(this.hideUpdate == true){
        vm.year = 'MyActivity'
      }
      axios
          .get(url,{params:{
              year_f: vm.year,
              minYear: vm.minYear,
              maxYear: vm.maxYear
          }})
          .then(res => {
            vm.isLoading=false;
            vm.patentes = res.data;
            vm.patentes = vm.patentes.map(patente => {
              var patente = patente
              patente["presentation_date"] = moment(patente.presentation_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
              patente["last_patent_state_date"] = moment(patente.last_patent_state_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
              return patente;
            });
          })
          .catch(e => {
            console.log(e);
            vm.isLoading=false;
          });
    },
    updatePatents(year){
      this.year = year
      this.getPatents()
    }
  },
  computed:{

  },
  watch:{
    changeFilter: function(){
      this.getPatents()
    }
  }
}
</script>

