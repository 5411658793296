var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"custom-box",style:({
      border: '2rem',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '0.5rem',
      borderColor: '#e5e7eb',
      backgroundColor: '#fff',
      paddingBottom: '2rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
    })},[_c('section',[(Object.keys(_vm.errores_validacion).length !== 0)?_c('div',[_c('b-message',{attrs:{"type":"is-danger","has-icon":""}},_vm._l((_vm.errores_validacion),function(item,index){return _c('div',{key:index},[(item)?_c('div',[_vm._v(_vm._s(item))]):_vm._e()])}),0)],1):_vm._e(),_vm._v(" "),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.searchPerson)}}},[_c('div',{staticClass:"columns is-variable is-2"},[_c('div',{staticClass:"column mb-0 py-0"},[_c('div',{staticClass:"columns is-variable is-1"},[_c('b-field',{staticClass:"column mb-0 pb-0",attrs:{"label":"Búsqueda por Rut"}},[_c('ValidationProvider',{attrs:{"rules":"integer|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"search","maxlength":"10","placeholder":"Ej. 12345678"},nativeOn:{"paste":function($event){$event.preventDefault();return _vm.handlePaste.apply(null, arguments)}},model:{value:(_vm.searchRut),callback:function ($$v) {_vm.searchRut=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"searchRut"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_vm._v(" "),_c('b-field',{staticClass:"column mb-0 pb-2 is-3",attrs:{"label":"DV"}},[_c('ValidationProvider',{attrs:{"rules":"valid_dv|max:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"search","placeholder":"Ej. 9","maxlength":"1"},model:{value:(_vm.searchDv),callback:function ($$v) {_vm.searchDv=$$v},expression:"searchDv"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"column mb-0 py-0"},[_c('b-field',{attrs:{"label":"Búsqueda por Nombre o Apellidos"}},[_c('ValidationProvider',{attrs:{"rules":{
                    regex: /^[a-zA-ZáéíóúÁÉÍÓÚÑñ\s]*$/,
                    max: 80,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"search","maxlength":"80","placeholder":"Ej. Juan Pérez o Juan o Pérez"},model:{value:(_vm.searchFullName),callback:function ($$v) {_vm.searchFullName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"searchFullName"}},[_vm._v("\n                    >")]),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_vm._v(" "),_c('div',{staticClass:"column is-one-quarter mb-0 py-0"},[_c('b-field',{attrs:{"label":".","custom-class":"has-text-white"}},[_c('div',{style:({
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-end',
                    alignItems: 'end',
                    gap: '0.5rem',
                  })},[_c('b-tooltip',{attrs:{"label":"Restablecer campos de búsqueda"}},[_c('b-button',{on:{"click":_vm.clearForm}},[_c('b-icon',{attrs:{"icon":"eraser","pack":"fas","type":"is-primary"}})],1)],1),_vm._v(" "),_c('button',{staticClass:"button is-primary",style:({
                      flexGrow: 1,
                    }),attrs:{"type":"submit"}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"search","pack":"fas"}}),_vm._v("\n                    Buscar\n                  ")],1)],1)])],1)])])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }