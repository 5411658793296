<template>
  <div ref="projects_tutorial">
      <div class="columns is-multiline">
        <div class="column is-3 py-0">
          <b-sidebar position="static" type="is-light" open>
            <div class="p-1 pt-4">
              <div class="block mt-5">
                <h1 class="subtitle is-3 has-text-primary has-text-left">Índice</h1>
              </div>
              <b-menu class="is-custom-mobile">
                <b-menu-list>
                  <b-menu-item :active="tutorial === 'create_request' ? true : false" @click="tutorial = 'create_request'" label="1. ¿Cómo crear un proyecto?"></b-menu-item>
                  <b-menu-item :active="tutorial === 'view_own_request' ? true : false" @click="tutorial = 'view_own_request'" label="2. ¿Cómo listar proyectos ya creados?"></b-menu-item>
                  <b-menu-item :active="tutorial === 'financings_tutorial' ? true : false" @click="tutorial = 'financings_tutorial'" label="3. ¿Como añadir financiamientos al proyecto?"></b-menu-item>
                  <b-menu-item :active="tutorial === 'entities_tutorial' ? true : false" @click="tutorial = 'entities_tutorial'" label="4. ¿Como asociar entidades al proyecto?"></b-menu-item>


                </b-menu-list>
              </b-menu>
            </div>
          </b-sidebar>
          <div class="buttons-sidebar">
            <b-button class="icon-up" icon-left="arrow-circle-up" type="is-ghost" @click="goUp">
              Volver arriba
            </b-button>
          </div>
        </div>
        <div v-if="tutorial === 'create_request'" class="column is-9">
            <h1 class="subtitle is-2 has-text-primary">
              Creación de un Proyecto
            </h1>
            <div class="mb-3 ">
              <p>SIGA permite la creación de proyectos de académicas/os. Para poder ingresar un proyecto al sistema, usted
              deberá proporcionar en una primera etapa, datos génericos y participantes asociados a este. Posterior a esta creación,
              se podrán asociar financiamientos y entidades al proyecto, en caso de contar con esta información.</p>
            </div>
            <h2 class="subtitle is-3 mt-5 mb-2">
              Paso 1
            </h2>
            <p class="my-3 ">Seleccionar la opción <a href="/projects/new" target="_blank"><b-icon pack="fas" icon="plus"></b-icon>Añadir Proyecto</a>, la cual se puede seleccionar de dos formas: desde la barra lateral o desde
              <a :href="'/people/'+person.id" target="_blank"><b-icon pack="fas" icon="book"></b-icon>Mi Actividad Acádemica</a>.
            </p>
            <ul>
              <li>
                <h5 class="subtitle is-5">1. Desde la barra lateral:</h5>
                <ul class="ml-4">
                  <li>1.1 Abrir la barra lateral</li>
                  <li>1.2 Seleccionar "Actividad Académica"</li>
                  <li>1.3 Seleccionar "Añadir Actividad"</li>
                  <li>1.4 Seleccionar "Proyectos"</li>
                </ul>
              </li>

              <img border="2" class="m-2" src="tutorial_images/add_academic_activity.png" style="width:35%">

              <li>
                <h5 class="subtitle is-5">2. Desde <a :href="'/people/'+person.id" target="_blank"><b-icon pack="fas" icon="book"></b-icon>Mi Actividad Acádemica</a>:</h5>
                <ul>
                  <li>2.1 Desplegar el menú de usuaria/o en la esquina superior derecha del sistema</li>
                  <img border="2" class="m-2" src="tutorial_images/my_profile_menu.png" style="width:40%">
                  <li>2.2 <a :href="'/people/'+person.id" target="_blank"><b-icon pack="fas" icon="book"></b-icon>Seleccionar "Mi Actividad Acádemica"</a></li>
                  <li>2.3 En la vista de <a :href="'/people/'+person.id" target="_blank"><b-icon pack="fas" icon="book"></b-icon>Mi Actividad Acádemica</a>, apretar el botón "Añadir Actividad Académica"</li>
                  <li>2.4 Seleccionar Proyectos</li>
                </ul>
              </li>

              <img border="2" class="m-2" src="tutorial_images/my_academic_activity_add_aa_button.png">

            </ul>

            <h2 class="subtitle is-3 mt-5 mb-2">
              Paso 2
            </h2>
            <p class="my-3 ">Rellenar el formulario para agregar un <a :href="'/projects/new'" target="_blank">Nuevo Proyecto</a>. <br> En este paso se debe rellenar la información
              solicitada en el formulario de proyectos, tanto en su pestaña "General" como en la pestaña "Participantes.
            </p>
          <ul>
            <li>
              <h5 class="subtitle is-5">1. Rellenar pestaña General:</h5>
              <ul class="ml-4">
                <li>1.1 Rellenar los datos del formulario de la pestaña General. Los ítems marcados con un asterisco(*) son de caracter obligatorio</li>
                <li><img border="2" class="m-2" src="tutorial_images/projects_form_general.png"></li>
              </ul>
            </li>

            <li>
              <h5 class="subtitle is-5">2. Rellenar pestaña Participantes:</h5>
              <ul class="ml-4">
                <li>2.1 Ir a la pestaña participantes apretando en la pestaña Participantes del formulario, o, desplazandose
                con las flechas en la parte inferior del formulario.</li>
                <li><img border="2" class="m-2" src="tutorial_images/general_vs_participants_tab.png"></li>
                <li>2.2 Presionar el botón "Administrar Participantes". Con esta acción se desplegará un modal que permitirá
                buscar y añadir participantes al proyecto. <br>*La funcionalidad de "Administrar Participantes", funciona por igual para toda
                la actividad académica.</li>
                <li><img border="2" class="m-2" src="tutorial_images/participants_tab.png"></li>
                <li><img border="2" class="m-2" src="tutorial_images/participants_form.png"></li>
                <li>2.3 Ingresar un nombre para buscar personas registradas dentro del sistema.</li>
                <li>2.4 Presionar el botón "Buscar". Si la búsqueda arroja resultados, estos se verán en la "Tabla Búsqueda", en donde
                  podrá seleccionar las personas que quiera añadir al proyecto, marcandolas con el ticket del sector izquierdo.
                  Su selección quedará reflejada en la "Tabla Resumen Participantes"</li>
                <li><img border="2" class="m-2" src="tutorial_images/participants_form_with_search.png"></li>
                <li>2.5 En caso de no encontrar la persona buscada, tendrá la opcion de añadirla como participante externo,
                  completando un formulario anexo al cual se puede acceder apretando el botón + que se aprecia al sector derecho
                  del modal. Se puede encontrar la explicación de su uso en la sección de participantes externos.</li>
                <li>2.6 (Opcional) Si quiere añadir más de un/a participante, puede volver a realizar la búsqueda y seleccionar otras personas
                  sin necesidad de cerrar el modal.</li>
                <li>2.6 Presionar Guardar del modal de "Añadir Participantes", con lo cual se volverá al formulario del Proyecto con los datos de participantes seleccionados.
                  PRECAUCIÓN: esta acción no guardará los participantes ni el proyecto en el sistema hasta que el proyecto sea guardado.</li>
                <li>2.7 Para cada participante añadida/o se deberá asignar un Tipo de Participanción dentro del proyecto.</li>
                <li>2.8 De manera obligatoria, debe seleccionar un/a dueño/a del proyecto de entre los/as participantes añadidos/as previamente. Esto para reconocer quien tendrá todos los permisos de edición sobre esta actividad académica.</li>
                <li><img border="2" class="m-2" src="tutorial_images/selected_participants.png"></li>


              </ul>
            </li>

          </ul>


            <h2 class="subtitle is-3 mt-5 mb-2">
              Paso 3
            </h2>
            <p class="my-3 ">Guardar el proyecto
            </p>
            <ul>
              <li>3.1 Una vez llenados los datos obligatorios tanto como de la pestaña General y Participantes, debe presionar el botón "Guardar Proyecto" del formulario de Nuevo Proyecto.</li>
              <li>3.2 Si el sistema arroja errores, deben ser resueltos para poder guardar el proyecto.</li>
            </ul>


        </div>

        <div v-if="tutorial === 'view_own_request'" class="column is-9">
          <h1 class="subtitle is-2 has-text-primary">
            Listar proyectos ya creados en los cuales participo.
          </h1>
          <div class="mb-3 ">
            <p>SIGA permite listar los proyectos en donde se le incluye como participante.</p>
          </div>
          <h3 class="subtitle is-4">Paso 1</h3>
          <h5 class="subtitle is-5">1. Ir a <a :href="'/people/'+person.id" target="_blank"><b-icon pack="fas" icon="book"></b-icon>Mi Actividad Acádemica</a>:</h5>
          <ul>
            <li>1.1 Desplegar el menú de usuaria/o en la esquina superior derecha del sistema</li>
            <img border="2" class="m-2" src="tutorial_images/my_profile_menu.png" style="width:40%">
            <li>1.2 Seleccionar <a :href="'/people/'+person.id" target="_blank"><b-icon pack="fas" icon="book"></b-icon>"Mi Actividad Acádemica"</a></li>
            <li>1.3 Seleccionar la pestaña de Proyectos.</li>
            <li>(Opcional) Aplicar filtros de años o quitarlos para buscar más datos de proyectos en los cuales esté listado como participante.</li>
            <li>*Apretar sobre el nombre de un proyecto lo redirigirá a toda la información existente de este en el sistema.</li>
            <li><img border="2" class="m-2" src="tutorial_images/my_projects.png"></li>
          </ul>
        </div>

        <div v-if="tutorial === 'financings_tutorial'" class="column is-9">
          <h1 class="subtitle is-2 has-text-primary">
            Añadir financiamientos a un proyecto
          </h1>
          <div class="mb-3 ">
            <p>SIGA permite relacionar financiamientos a proyectos ya creados en el sistema. Existen dos tipos de financiamientos
              disponibles para añadir a un proyecto: el Monto Adjudicado Total y las Remesas.</p>
          </div>
          <h3 class="subtitle is-4">Paso 1</h3>
          <ul>
            <li>1.1 Ingresar a un proyecto ya existente en el sistema.</li>
            <li><img border="2" class="m-2" src="tutorial_images/created_project_tabs.png"></li>
            <li>1.2 Seleccionar la pestaña de Financiamientos</li>
            <li><img border="2" class="m-2" src="tutorial_images/projects_financings.png"></li>
          </ul>
          <br>
          <h2 class="subtitle is-3">2. Añadir Monto Adjudicado Total</h2>
          <p>El monto adjudicado total, es la suma total de financiamientos adjudicados a través de los años reportada a la Universidad.</p>
          <br>
          <ul>
            <li>2.1 Ingresar el monto adjudicado total por la universidad en el espacio designado.</li>
            <li>2.2 Presionar "Guardar Monto Adjudicado"</li>
            <li><img border="2" class="m-2" src="tutorial_images/projects_financings_adjudicated.png"></li>
          </ul>
          <h2 class="subtitle is-3">3. Añadir Remesa</h2>
          <p>Las remesas se encuentran listadas por debajo del Monto Adjudicado Total, y estos son los montos reportados anualmente a la Universidad.</p>
          <br>
          <ul>
            <li>3.1 Presionar el botón "Añadir Remesa", que activará un modal en donde deberá añadir los datos solicitados.</li>
            <li>3.2 Rellenar los datos obligatorios del formulario.</li>
            <li>SUGERENCIA: al buscar una entidad por nombre, hágalo primeramente con su nombre en el idioma original (si es que no es el español). </li>
            <li>3.3 Al buscar y seleccionar una entidad por nombre, si esta posee Programas de Financiamiento,
              se desplegará un nuevo campo en donde se podrá seleccionar el Programa de Financiamento de la Remesa.</li>
            <li>3.4 Una vez llenados los campos obligatorios del formulario, presionar "Guardar Remesa"</li>
            <li>3.5 Si el sistema arroja errores, deben ser arreglados para añadir la Remesa.</li>
            <li>3.6 Si no se reportan errores, el Financiamiento Remesa quedará instantaneamente relacionado al Proyecto luego de presionar
            el botón "Guardar Remesa", lo que se verá reflejado en la tabla de Remesas del Proyecto</li>
            <li><img border="2" class="m-2" src="tutorial_images/projects_financings_added_remmitance.png"></li>
          </ul>
        </div>

        <div v-if="tutorial === 'entities_tutorial'" class="column is-9">
          <h1 class="subtitle is-2 has-text-primary">
            Asociar Entidades a un proyecto
          </h1>
          <div class="mb-3 ">
            <p>SIGA permite asociar entidades a proyectos ya creados en el sistema.
              A diferencia de las entidades que se asocian a través de un financiamiento, en esta sección
              se pueden asociar entidades que no necesariamente realizan un aporte monetario al proyecto,
              pero que si se relacionan de manera significativa o que están interesadas en este y sus
              datos son importantes para el desarrollo de este proyecto o futuros, por ejemplo,
              otra Universidad participante o interesada del proyecto, alguna institución pública interesada,
              una empresa beneficiaria, etc...</p>
          </div>
          <h3 class="subtitle is-4">Paso 1</h3>
          <ul>
            <li>1.1 Ingresar a un proyecto ya existente en el sistema.</li>
            <li><img border="2" class="m-2" src="tutorial_images/created_project_tabs.png"></li>
            <li>1.2 Seleccionar la pestaña de Entidades Asociadas</li>
            <li><img border="2" class="m-2" src="tutorial_images/project_entities.png"></li>
            <li>1.3 Apretar el botón "Asociar Nueva Entidad" el cuál desplegará un formulario el cuál deberá llenar.</li>
            <li>1.4 Rellenar los datos obligatorios del formulario</li>
            <li>SUGERENCIA: al buscar una entidad por nombre, hágalo primeramente con su nombre en el idioma original (si es que no es el español). </li>
            <li>1.5 Apretar el botón "Asociar Entidad"</li>
            <li>1.6 Si el sistema arroja errores, deben ser arreglados para asociar la entidad.</li>
            <li>1.7 Si no se reportan errores, la Entidad quedará instantaneamente asociada al Proyecto luego de presionar
              el botón "Asociar Entidad", lo que se verá reflejado en la pestaña de "Entidades Asociadas" del Proyecto</li>
            <li><img border="2" class="m-2" src="tutorial_images/projects_entities_added.png"></li>
          </ul>
          <br>

        </div>

      </div>

  </div>

</template>

<script>
export default {
  name: "projectsTutorial.vue",
  props:["person"],
  data() {
    return {
      tutorial:"create_request",
    }
  },
  methods:{
    goUp(){
      var elmnt = document.getElementById("app");
      elmnt.scrollIntoView();
      this.$refs.projects_tutorial.scrollTop = 0;
    }
  },
}
</script>

<style scoped>
.b-sidebar .sidebar-content.is-absolute,
.b-sidebar .sidebar-content.is-static {
  width: 100%;
  background-color: white;
  box-shadow: none;
}
.icon-up{
  position: fixed;
  width: 16.5% !important;
  bottom: 0;
  left: 15%;
}
.buttons-sidebar{

}
</style>