<template>
  <section>
    <div class="container">
      <div class="box">
        <div class="columns">
          <div class="column">
            <h3 class="is-size-3 has-text-weight-bold">Listado de personas externas</h3>
          </div>
          <div v-if="can_create === 'true'" class="column" align="right" style="text-align:right;">
            <b-button class="mt-2" icon-left="plus" @click.prevent="createModal" type="is-primary">Añadir persona externa</b-button>
          </div>
        </div>
        <b-table :data="people"
                 :paginated= true
                 per-page="10"
                 striped
                 hoverable
                 sticky-header
                 height="520"
                 :narrowed=true
                 :current-page="currentPage"
                 :pagination-simple=false
                 pagination-position="both"
                 default-sort-direction="asc"
                 sort-icon="arrow-up"
                 sort-icon-size="is-small"
                 default-sort="product.title"
                 aria-next-label="Siguiente"
                 aria-previous-label="Anterior"
                 aria-page-label="Página"
                 aria-current-label="Página actual">
          <b-table-column
              field="run_attributes.run_completo"
              label="Rut"
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.run_attributes != null ? props.row.run_attributes.run_completo : ""}}
            </template>
          </b-table-column>
          <b-table-column
              field="nombre_completo"
              label="Nombres"
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.nombre_completo }}
            </template>
          </b-table-column>
          <b-table-column
              field="run_attributes.passport"
              label="Pasaporte"
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.run_attributes != null ? props.row.run_attributes.passport : ""}}
            </template>
          </b-table-column>
          <b-table-column
              field="personal_mail"
              label="Email"
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.personal_mail != null ? props.row.personal_mail : ""}}
            </template>
          </b-table-column>
          <b-table-column field="entity_name"
                          label="Entidad"
                          searchable>
            <template slot="searchable"
                      slot-scope="props">
              <b-input v-model="props.filters[props.column.field]"
                       placeholder=" Buscar"
                       icon="search"
                       size="is-small">
              </b-input>
            </template>
            <template v-slot="props">
              <a v-if="props.row.entity_name != null" @click="goToEntity(props.row.entity_id)">{{ props.row.entity_name }}</a>
            </template>
          </b-table-column>
          <b-table-column
              label="Acciones"
              v-slot="props">
            <b-icon
                pack="fas"
                icon="eye"
                size="is-medium"
                @click.native="showModal(props.row.id)"/>
            <b-icon
                v-if="can_create === 'true'"
                pack="fas"
                icon="edit"
                size="is-medium"
                @click.native="editModal(props.row.id)"/>
          </b-table-column>
        </b-table>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
      </div>
    </div>
    <b-modal
        v-model="isEditModal"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-external-people-edit :person-id="selected"
                                query-type="update"
                                modal-header="Editar Datos Personales"
                                @close="props.close"></modal-external-people-edit>
      </template>
    </b-modal>
    <b-modal
        v-model="isCreateModal"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-external-people-create
                                    query-type="create"
                                    modal-header="Agregar datos personales"
                                    @close="props.close"></modal-external-people-create>
      </template>
    </b-modal>
    <b-modal
        v-model="isShowModal"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-external-people-show :person-id="selected"
                                    :can_create="can_create"
                                    modal-header="Datos Personales"
                                    @close="props.close"></modal-external-people-show>
      </template>
    </b-modal>
  </section>
</template>

<script>
import axios from "axios";
import ModalExternalPeopleEdit from './modal_external_people_edit'
import ModalExternalPeopleCreate from './modal_external_people_create'
import ModalExternalPeopleShow from './modal_external_people_show'
export default {
  name: "external_people_list",
  components: {ModalExternalPeopleEdit,ModalExternalPeopleCreate,ModalExternalPeopleShow},
  props:['can_create'],
  data(){
    return{
      isLoading: false,
      people: [],
      currentPage: 1,
      isEditModal: false,
      isCreateModal: false,
      isShowModal: false,
      canCancel: ['escape'],
      selected: null,
    }
  },
  created() {
    this.initialize()
  },
  methods:{
    initialize(){
      this.isLoading = true
      return axios
        .get("../people/external_people_list.json")
        .then(res => {
          this.people = res.data
          this.isLoading = false
        })
       .catch(error => {
         console.log(error)
         this.isLoading = false;
       })
    },
    goToEntity(id){
      window.location.href='/partner_entities/show/'+id
    },
    editModal(id){
      this.isLoading=true
      this.selected = id;
      this.isEditModal = true;
      this.isLoading=false
    },
    createModal(){
      this.isLoading=true
      this.isCreateModal =true
      this.isLoading = false
    },
    showModal(id){
      this.isLoading=true
      this.selected = id;
      this.isShowModal = true;
      this.isLoading=false
    },
  },
  watch:{
    isEditModal: function () {
      if (!this.isEditModal) {
        this.initialize()
      }
    },
    isCreateModal: function () {
      if (!this.isCreateModal) {
        this.initialize()
      }
    },
    isShowModal: function () {
      if (!this.isShowModal) {
        this.initialize()
      }
    },
  },
}
</script>
