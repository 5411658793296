<template>
  <section>
    <div class="container">
      <!--<div class="has-text-right mb-1">
        <b-button class="is-primary is-outlined"
                  icon-left="plus"
                  v-if="can_assign_function==='true'"
                  @click="isAddModalActive = true">
          Crear Función Administrativa
        </b-button>
      </div>-->

      <!--DATA TABLE-->
      <b-table
          :data="person_functions"
          :paginated= true
          per-page="10"
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="product.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field="function.name"
            label="Función"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.function!==undefined ? props.row.function.name : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="unit.name"
            label="Unidad de desempeño"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.unit!==undefined ? props.row.unit.name : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="resolution"
            label="N° de documento"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.resolution}}
          </template>
        </b-table-column>
        <b-table-column
            field="resolution_type.name"
            label="Tipo documento"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.resolution_type ? props.row.resolution_type.name : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="start_date"
            label="Fecha inicio"
            width="110"
            sortable
            searchable :custom-search="searchStartDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            field="end_date"
            label="Fecha termino"
            width="110"
            sortable
            searchable :custom-search="searchEndDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            field="hours"
            label="Jornada (horas semanales)"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.hours}}
          </template>
        </b-table-column>
        <b-table-column
            label="Acciones"
            width="175px"
            v-slot="props">
          <b-tooltip
              v-if="props.row.can_show"
              label="Ver información completa de la función."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="eye"
                type="is-primary"
                @click="execShowModal(props.row.id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_edit"
              label="Editar información de la función."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="edit"
                type="is-info is-light is-outlined"
                @click="execEditModal(props.row.id)"/>
          </b-tooltip>
          <b-tooltip
              v-if="props.row.can_nullify"
              label="Anular función."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="ban"
                type="is-danger is-light is-outlined"
                @click="execNullifyModal(props.row.id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_view_pdf"
              label="Visualizar documento asociado a la función."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="file-alt"
                type="is-success is-light is-outlined"
                @click="viewFile(props.row.document_id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_view_pdf"
              label="Descargar documento asociado a la función."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="download"
                type="is-primary is-light is-outlined"
                @click="downloadFile(props.row.document_id)"/>
          </b-tooltip>
        </b-table-column>
      </b-table>
      <!--FIN DATA TABLE-->
      <!-- MODALES -->
      <b-modal
          v-model="isAddModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <administrative-function-form :person-id="personId"
                         query-type="create"
                         :modal-header="'Nueva Función Administrativa - '  +person_name"
                         :person_function_type_id="3"
                         @close="props.close"></administrative-function-form>
        </template>
      </b-modal>
      <b-modal
          v-model="isShowModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <administrative-function :id="selected"
                    :modal-header="'Función Administrativa - '  +person_name"
                    :person_name="person_name"
                    @close="props.close">
          </administrative-function>
        </template>
      </b-modal>
      <b-modal
          v-model="isEditModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <administrative-function-form :person-id="personId"
                         :id="selected"
                         query-type="update"
                         :modal-header="'Editar Función Administrativa - '  +person_name"
                         @close="props.close">
          </administrative-function-form>
        </template>
      </b-modal>

      <b-modal
          v-model="showPdf"
          :can-cancel="true"
          :destroy-on-hide="true"
          aria-modal
          aria-role="dialog"
          has-modal-card
          trap-focus
          :width="2000"
      >
        <template #default="props">
          <pdf_view
              :src="src"
              @close="props.close"
          ></pdf_view>
        </template>

      </b-modal>

      <b-loading :is-full-page="true"
                 v-model="isLoading"
                 :can-cancel="false"/>
    </div>
  </section>
</template>

<script>
import AdministrativeFunction from "./administrative_function.vue";
import AdministrativeFunctionForm from "./modal_administrative_function_form";
import axios from "axios";
import {searchDate, formatDate} from "../../../packs/utilities";
import moment from "moment";
import Pdf_view from "../../performances/pdf_view.vue";
import pdf from 'vue-pdf';
export default {
  name: "person_functions",
  props:[
    'personId', 'can_assign_function', 'person_name'
  ],
  components:{
    Pdf_view,
    AdministrativeFunction,
    AdministrativeFunctionForm,
  },
  data(){
    return {
      selected: null,
      index: null,
      canCancel: [],
      currentPage: 1,
      person_function: {},
      isAddModalActive: false,
      isEditModalActive: false,
      isShowModalActive: false,
      isDistanceModalActive: false,
      isLoading:false,
      person_functions: [],
      isNullifyActive: false,
      showPdf: false,
      src:null,

    }
  },
  created(){
    this.initialize()
  },
  methods:{
    execNullifyModal(id){
      this.$buefy.dialog.confirm({
        title: 'Anular función',
        message: '¿Está seguro que desea <b>anular</b> la función? Esta acción la dejará inválida y modificará el nombramiento asociado.<br>',
        confirmText: 'Anular Función',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/person_functions/" + id + "/nullify_person_function.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Función anulada correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                this.isLoading = false;
                this.isNullifyActive = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Función no pudo ser anulada</b><br>'+ error.response.data["base"][0],
              duration: 5000,
              type: 'is-damger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },
    initialize(){
      this.isLoading = true;
      return axios
          .get("/people/"+this.personId+"/administrative_person_functions.json")
          .then(res => {
            this.person_functions = res.data
            this.person_functions = this.person_functions.map(personfunction => {
              var newFunction = personfunction
              newFunction["start_date"] = moment(newFunction["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newFunction["end_date"] = moment(newFunction["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newFunction["resolution_date"] = moment(newFunction["resolution_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newFunction;
            });
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })

    },
    execShowModal(id){
      this.selected = id;
      this.isShowModalActive = true;
    },
    execEditModal(id){
      this.selected = id;
      this.isEditModalActive = true;
    },
    execDistanceModal(personFunction){
      this.isLoading = true
      this.person_function = personFunction
      this.isDistanceModalActive = true
      this.isLoading = false
    },
    // yyyy/mm/dd -> dd/mm/yyyy
    formatDate,
    searchStartDate(row, input){
      return searchDate(row.start_date, input)
    },
    searchEndDate(row, input){
      return searchDate(row.end_date, input)
    },
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            this.showPdf = true
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    downloadFile(fileId){
      this.isLoading = true;

      if (fileId == null){
        this.isLoading = false;
        return;
      }

      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(res => {
            let blob = new Blob([res.data], {
              type: "application/pdf",
            })
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = res.headers["content-disposition"]
                .split(";")[1]
                .split('"')[1];
            link.click();
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento fue descargado con exito',
              type: 'is-success',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
          .catch(e => {
            console.log(e)
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento no se pudo descargar',
              type: 'is-danger',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
    },

  },
  watch:{
    isNullifyActive: function () {
      if (!this.isNullifyActive){
        this.initialize()
      }
    },
    isAddModalActive: function () {
      if (!this.isAddModalActive) {
        this.initialize()
      }
    },
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
    isShowModalActive: function () {
      if (!this.isShowModalActive) {
        this.initialize()
      }
    },
    isDistanceModalActive: function () {
      if (!this.isDistanceModalActive) {
        this.initialize()
      }
    },

  }
}
</script>

<style scoped>

</style>