<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveState)">
        <div class="modal-card" style="width: auto; height: 42em">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" ref="modalStudyForm">
            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion">
                      {{ item[0] }}
                    </div>
                  </b-message>
                </div>
              </div>
            </div>
            <div class="columns">
              <b-message type="is-success" has-icon v-if="queryType==='finish'">
                Esta acción aprobará y finalizará la solicitud, además creará los nombramientos solicitados por el Jefe Administrativo de Departamento.
              </b-message>
              <b-message type="is-warning" has-icon v-if="queryType==='stop'">
                Esta acción detendrá la solicitud y requerirá la modificación de los datos de esta. En comentario indique detalladamente los motivos
                de esta acción.
              </b-message>
              <b-message type="is-primary" has-icon v-if="queryType==='restart'">
                Esta acción reiniciará la solicitud y podría requerirse la modificación de los datos de esta. En comentario indique detalladamente los motivos
                de esta acción.
              </b-message>
              <b-message type="is-danger" has-icon v-if="queryType==='reject'">
                Esta acción rechazará la solicitud, indique claramente los motivos de esta acción. Los nombramientos solicitados serán anulados.
              </b-message>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Comentario*">
                  <ValidationProvider rules="required|min:5|max:200" v-slot="{ errors }">
                    <b-input v-model="comment" type="textarea"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit">Guardar</button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import axios from "axios";

export default {
  name: "modal_update_state",
  props:[
    'queryType', 'personId', 'modalHeader', "id"
  ],
  data(){
    return {
      isLoading: false,
      comment: null,
      new_request_state_id: null,
      errores_validacion: {}
    }
  },
  created() {
    if(this.queryType==="finish"){
      this.new_request_state_id = 2
    }
    else if(this.queryType==="stop"){
      this.new_request_state_id = 4
    }
    else if(this.queryType==="restart"){
      this.new_request_state_id = 1
    }
    else if(this.queryType==="reject"){
      this.new_request_state_id = 3
    }
    else{
      this.new_request_state_id = null
    }
  },
  methods: {
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
    saveState(){
      this.isLoading=true;
      axios.put("/requests/"+this.id+"/update-state.json", {
        comment: this.comment,
        query: this.queryType,
        new_state_id: this.new_request_state_id
      })
          .then(response => {
            this.$buefy.dialog.alert({
              title: 'Exito',
              message: 'El estado se actualizó correctamente',
              type: 'is-success',
              hasIcon: true,
              icon: 'check',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.isLoading = false
            this.$emit("close")
          }).catch(error => {
            this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Hubo errores al actualizar el estado',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.isLoading = false;
      })
    },
  },
}
</script>

