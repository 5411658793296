<template>
  <section>
    <div class="box">
      <div class="columns">
        <div class="column">
          <h3 class="is-size-3 has-text-weight-bold">Control de datos de Servicios EFUSACH</h3>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
            <b-message type="is-danger" has-icon>
              {{errores_validacion}}
            </b-message>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-3">
          <b-field label="Unidad de tiempo">
            <multiselect v-model="interval"
                         :options="intervals"
                         placeholder="Seleccione acción"
                         selectLabel=""
                         selectedLabel="Seleccionado"
                         deselectLabel=""
                         @input="selectYears"
                         :allow-empty="false">
              <template v-slot:noOptions>
                No existen datos
              </template>
              <span slot="noResult">
                No se encontraron elementos.
            </span>
            </multiselect>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field label="Tipo de elemento">
            <multiselect v-model="entity"
                         :options="entities"
                         placeholder="Seleccione tipo de elemento"
                         selectLabel=""
                         selectedLabel="Seleccionado"
                         deselectLabel=""
                         :allow-empty="false">
              <template v-slot:noOptions>
                No existen datos
              </template>
              <span slot="noResult">
                No se encontraron elementos.
            </span>
            </multiselect>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field label="Acción">
            <multiselect v-model="action"
                         :options="actions"
                         placeholder="Seleccione acción"
                         selectLabel=""
                         selectedLabel="Seleccionado"
                         deselectLabel=""
                         :allow-empty="false">
              <template v-slot:noOptions>
                No existen datos
              </template>
              <span slot="noResult">
                No se encontraron elementos.
            </span>
            </multiselect>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-narrow mt-2" v-if="show_years">
          <span>Seleccionar año: </span>
        </div>
        <div class="column is-3" v-if="show_years">
          <b-field>
            <multiselect v-model="year"
                         :options="years"
                         placeholder="Seleccione año"
                         selectLabel="Presione para seleccionar"
                         selectedLabel="Seleccionado"
                         deselectLabel="No se puede deseleccionar"
                         :allow-empty="false">
              <template v-slot:noOptions>
                No existen datos
              </template>
              <span slot="noResult">
                No se encontraron elementos.
            </span>
            </multiselect>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <h3 class="is-size-6 has-text-weight-bold">Búsqueda por nombre de usuario
            <b-tooltip
                label="Ingrese al menos 3 caracteres para realizar una búsqueda."
                position="is-bottom"
                size="is-medium"
                multilined>

              <b-icon
                  pack="fas"
                  icon="info-circle"
                  size="is-small"
                  type="is-info">
              </b-icon>
            </b-tooltip>
          </h3>
          <b-field>
          <div class="columns">
            <div class="column">
                <multiselect v-model="user_id"
                           :options="users.map(type => type.id)"
                           :custom-label="opt => users.find(x => x.id === opt).show_name"
                           placeholder="Seleccione Usuario"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="No se puede deseleccionar"
                           @search-change="searchUser"
                           :internal-search="false"
                           :loading="isLoadingMultiselect"
                           :disabled="isLoadingMultiselect || user_id_email !== null"
                           :allow-empty="false">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <template slot="option" slot-scope="props">
                  {{ users.find(x => x.id === props.option).show_name ? users.find(x => x.id === props.option).show_name : users.find(x => x.id === props.option).email}}
                </template>
                <span slot="noResult">
                        No se encontraron elementos.
                      </span>
              </multiselect>
          </div>
        </div>
          </b-field>
        </div>
        <div class="column">
          <h3 class="is-size-6 has-text-weight-bold">Búsqueda por correo de usuario
            <b-tooltip
                label="Ingrese al menos 3 caracteres para realizar una búsqueda."
                position="is-bottom"
                size="is-medium"
                multilined>

              <b-icon
                  pack="fas"
                  icon="info-circle"
                  size="is-small"
                  type="is-info">
              </b-icon>
            </b-tooltip>
          </h3>
          <b-field>
              <multiselect v-model="user_id_email"
                           :options="usersEmail.map(type => type.id)"
                           :custom-label="opt => usersEmail.find(x => x.id === opt).show_name"
                           placeholder="Seleccione Usuario"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="No se puede deseleccionar"
                           @search-change="searchMailUser"
                           :internal-search="false"
                           :loading="isLoadingMultiselectEmail"
                           :disabled="isLoadingMultiselectEmail || user_id !== null"
                           :allow-empty="false">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <template slot="option" slot-scope="props">
                  {{ usersEmail.find(x => x.id === props.option).show_name ? usersEmail.find(x => x.id === props.option).show_name : usersEmail.find(x => x.id === props.option).email}}
                </template>
                <span slot="noResult">
                        No se encontraron elementos.
                      </span>
              </multiselect>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="actions has-text-right">
            <b-button class="button button-clear-filter" icon-left="eraser" @click="clearFilter">Limpiar Filtros</b-button>
            <b-button type="is-info" class="button is-info" icon-left="search" @click="updateAudit">Aplicar Filtro</b-button>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-centered">
          <b-message type="is-info" has-icon v-model="isActive">
            {{message}}
          </b-message>
        </div>
      </div>
      <b-table
          :data="audit"
          :paginated= true
          per-page="30"
          striped
          hoverable
          sticky-header
          :narrowed=true
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="both"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          height="1080"
          sort-icon-size="is-small"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field="user_mail"
            label="Usuario Autor"
            searchable
            width="30"
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                placeholder=" Buscar"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.user_mail || "Sistema"}}
          </template>
        </b-table-column>

        <b-table-column
            field="created_at"
            label="Fecha"
            width="15"
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                placeholder=" Buscar"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.created_at}}
          </template>
        </b-table-column>
        
        <b-table-column
            field="event_esp"
            label="Acción"
            width="15"
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                placeholder=" Buscar"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.event_esp}}
          </template>
        </b-table-column>
        <b-table-column
            field="item_type_esp"
            label="Tipo de elemento"
            width="15"
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                placeholder=" Buscar"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.item_type_esp}}
          </template>
        </b-table-column>
        <b-table-column
            field="item_id"
            label="Identificador de Objeto"
            width="10"
            searchable
            sortable
            :custom-search="searchNumber"
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                placeholder=" Buscar"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.item_id}}
          </template>
        </b-table-column>
        <b-table-column
            label="Acciones"
            width="20"
            v-slot="props">

          <b-button type="is-primary"
                    v-if="props.row.event_esp != 'ANULADO'"
                    icon-left="eye"
                    @click="goService(props.row)">
          </b-button>

          <b-button type="is-"
                    icon-left="info"
                    @click="execComparisionModal(props.row.comparision_esp)">
          </b-button>
          

        </b-table-column>
        <template slot="empty" slot-scope="props">
          <div class="columns is-centered">
            <div class="column is-6 is-centered has-text-centered">
              <b-message type="is-danger">
                No se encontraron resultados para filtros aplicados.
              </b-message>
            </div>
          </div>
        </template>
      </b-table>
      <b-modal
          v-model="isModalComparisionActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          width="100%"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <modal-comparision :person-id="personId"
                             modal-header="Comparación de Versiones"
                             style="width: 70vw"
                             :data="data_for_comparision"
                             @close="props.close"></modal-comparision>
        </template>
      </b-modal>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import ModalComparision from './ModalComparision'
import {setIntervals, createYears} from "../../packs/utilities";
export default {
  name: "sdt_audit",
  components:{
    ModalComparision
  },
  data(){
    return {
      selected: null,
      audit: [],
      isModalComparisionActive: false,
      currentPage: 1,
      isLoading: false,
      canCancel: ['escape'],
      no_filters: true,
      formattedDate: "",
      errores_validacion: {},
      show: false,
      edit: false,
      complete_rut: "",
      complete_name: "",
      corporate_mail: "",
      add: false,
      data_for_comparision: null,
      personId: "",
      queryType: "",
      id: "",
      person: "",
      prop_person: "",
      action: "TODAS",
      actions: ["TODAS", "CREACIÓN", "ACTUALIZACIÓN", "ANULADO"],
      entity: "TODAS",
      entities: ["TODAS", "SERVICIO", "CONTRATOS","PARTICIPANTES", "DOCUMENTOS","MONTOS APERTURA", "TRANSACCIONES"],
      interval: "DIA",
      intervals: ["DIA", "SEMANA", "MES", "AÑO", "TODO"],
      interval_end: "",
      interval_start: "",
      isActive: false,
      message: "",
      year: "ACTUAL",
      years: ["ACTUAL"],
      show_years:false,
      isLoadingMultiselect: false,
      isLoadingMultiselectEmail: false,
      user_id: null,
      users: [],
      user_id_email: null,
      usersEmail: []
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    searchUser(query){
      if(query.length<3){
        return
      }
      this.isLoadingMultiselect = true;
      this.user_id = null;
      axios
          .get("/admin/search-users.json",
              {
                params: {
                  query: query
                }
              })
          .then(res => {
            this.users = res.data
            this.isLoadingMultiselect=false;

          }).catch(error => {
        console.log(error)
        this.isLoadingMultiselect=false;
      })

    },
    searchNumber(row,input){
      return String(row.item_id).includes(input);
    },

    searchMailUser(query){
      if(query.length<3){
        return
      }
      this.isLoadingMultiselectEmail = true;
      this.user_id = null;
      axios
          .get("/admin/search-mail-users.json",
              {
                params: {
                  query: query
                }
              })
          .then(res => {
            this.usersEmail = res.data
            this.isLoadingMultiselectEmail=false;

          }).catch(error => {
        console.log(error)
        this.isLoadingMultiselectEmail=false;
      })

    },

    setIntervals,
    createYears,
    selectYears(){
      if(this.interval==="AÑO"){
        this.show_years = true
      }
      else{
        this.show_years = false
        this.year = "ACTUAL"
      }
    },
    execComparisionModal(comparision_array){
      this.isLoading = true
      this.isModalComparisionActive = true
      this.data_for_comparision = comparision_array
      this.isLoading = false
    },
    initialize(){
      this.isLoading = true
      this.years = createYears()
      this.isLoading = false
    },
    clearFilter(){
      this.action = "TODAS";
      this.entity = "TODAS";
      this.interval = "DIA";
      this.year = "ACTUAL";
      this.user_id = null;    
      this.user_id_email = null;    
      this.usersEmail= [];
      this.users = [];   

    },
    goService(row){
      let vm = this;
      vm.errores_validacion = {};
      vm.isLoading = true
      axios.get("/admin/sdt_audit/"+row.id+".json",
          {
            params: {
              item_type: row.item_type
            }
          })
        .then(res => {
            window.location.href = res.data;
            vm.isLoading = false
          })
          .catch(error => {
            this.$buefy.dialog.alert({
              message: error.response.data+'.',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            vm.isLoading = false;
          })

    },
    updateAudit(){
      let vm = this;
      vm.isLoading = true
      let user = null
      if(vm.user_id){
        user = vm.users.find(user => user.id === vm.user_id)
      }
      if(vm.user_id_email){
        user = vm.usersEmail.find(user => user.id === vm.user_id_email)
      }
      vm.message = setIntervals(vm.interval, vm.year, user)
      return axios
          .get("/admin/sdt_audit.json", { params:
                { audit_action: vm.action,
                  audit_entity: vm.entity,
                  interval: vm.interval,
                  year: vm.year,
                  user: vm.user_id,
                  origin: "SDT",
                  sdtPersonal: true,
                }
          })
          .then(res => {
            vm.errores_validacion = {};
            vm.audit = []
            vm.audit = res.data["sdt_audit"].concat(res.data["sdt_project_audit"])
            vm.audit = vm.audit.concat(res.data["sdt_document"])
            
            vm.audit = vm.audit.map(audit => {
              var newAudit = audit
              newAudit["created_at"] = moment(newAudit["created_at"], "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY HH:mm:ss");
              return newAudit;
            });
            vm.audit = vm.audit.sort((a,b) => moment(b["created_at"], "DD-MM-YYYY HH:mm:ss").diff(moment(a["created_at"], "DD-MM-YYYY HH:mm:ss")))
            vm.isActive = true;
            vm.isLoading = false
          })
          .catch(error => {
            console.log(error)
            vm.errores_validacion = error.response.data
            vm.isLoading = false;
          })
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    }
  },
  watch: {
    add: function () {
      if (!this.add) {
        this.initialize()
      }
    },
    edit: function () {
      if (!this.edit) {
        this.initialize()
      }
    },
  }
}
</script>