var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-modal',{attrs:{"width":"90%","aria-modal":"","can-cancel":_vm.canCancel,"on-cancel":_vm.closeModal},model:{value:(_vm.activeModal),callback:function ($$v) {_vm.activeModal=$$v},expression:"activeModal"}},[_c('div',{staticClass:"modal-card",staticStyle:{"width":"100%"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(_vm._s(_vm.modalHeader))]),_vm._v(" "),_c('button',{staticClass:"delete",attrs:{"type":"button"},on:{"click":_vm.closeModal}})]),_vm._v(" "),_c('section',{ref:"modalPersonForm",staticClass:"modal-card-body",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [(Object.keys(_vm.errores_validacion).length != 0)?_c('div',{staticClass:"mb-2"},[_c('b-message',{attrs:{"type":"is-danger","has-icon":""}},_vm._l((_vm.errores_validacion),function(item,index){return _c('div',[_vm._v("\n                    "+_vm._s(item[0])+"\n                  ")])}),0)],1):_vm._e(),_vm._v(" "),_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addFiliation)}}},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Tipo de entidad*"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.entity_types,"custom-label":function (opt) { return opt.alt_name ? opt.alt_name : opt.name; },"placeholder":"Seleccione tipo de entidad","selectLabel":"Presione para seleccionar","selectedLabel":"Seleccionado","deselectLabel":"No se puede deseleccionar","allow-empty":false},scopedSlots:_vm._u([{key:"noOptions",fn:function(){return [_vm._v("\n                              No existen datos\n                            ")]},proxy:true}],null,true),model:{value:(_vm.entity_type),callback:function ($$v) {_vm.entity_type=$$v},expression:"entity_type"}},[_vm._v(" "),_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("\n                            No se encontraron elementos.\n                        ")])]),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_vm._v(" "),(_vm.entity_type && Object.keys(_vm.entity_type).length!=0)?_c('div',{staticClass:"column"},[_c('b-field',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                          Entidad de Filiación* (ingrese por lo menos 3 letras)\n                          "),_c('b-tooltip',{attrs:{"label":"Se recomienda búsqueda por sigla, ejemplo: ANID, no Agencia Nacional... mientras que para instituciones educacionales, se recomienda por nombre completo, ejemplo: universidad de santiago de chile","position":"is-right","size":"is-medium","multilined":""}},[_c('b-icon',{attrs:{"pack":"fas","icon":"info-circle","size":"is-small","type":"is-info"}})],1)]},proxy:true}],null,true)},[_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('b-autocomplete',{attrs:{"data":_vm.partner_entities,"placeholder":"ej ANID o UNIVERSIDAD DE SANTIAGO DE CHILE  o NOMBRE EMPRESA - ENTIDAD","field":"name","loading":_vm.isFetching},on:{"input":validate,"typing":_vm.searchPartnerEntities,"select":function (option) {if (option) {_vm.modalPartnerEntity = option}}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v("\n                              "+_vm._s(props.option.name)+"\n                            ")]}}],null,true),model:{value:(_vm.modalPartnerEntity.name),callback:function ($$v) {_vm.$set(_vm.modalPartnerEntity, "name", $$v)},expression:"modalPartnerEntity.name"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e()]),_vm._v(" "),_c('br'),_c('br'),_c('br'),_vm._v(" "),_c('div',{staticClass:"actions has-text-right"},[_c('button',{staticClass:"button is-danger",attrs:{"icon-left":"trash"},on:{"click":function($event){$event.preventDefault();return _vm.closeModal.apply(null, arguments)}}},[_vm._v("Cancelar")]),_vm._v(" "),_c('button',{staticClass:"button is-primary",attrs:{"type":"submit","icon-left":"plus"},on:{"click":function($event){return _vm.checkValidation()}}},[_vm._v("Guardar Filiación")])])])])]}}])})],1)])])])]),_vm._v(" "),_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }