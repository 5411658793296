<template>
    <div class="agreementacademics">
        <div>
            <div class="container">
                <div v-if="can_edit" class="columns">
                    <div class="column">
                        <span class="is-pulled-right">
                            <button class="button is-primary" @click="newParticipant()">
                                <b-icon icon="plus"></b-icon>
                                <span>Relacionar otro participante</span>
                            </button>
                        </span>
                    </div>
                </div>
                <div class="columns">
                  <div class="column has-text-right">
                    <b-field>
                    <b-switch v-model="cardLayout">
                      <span>
                        Formato tarjeta (sin filtros ni búsqueda)
                         <b-tooltip
                             label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                             multilined
                             position="is-left">
                              <b-icon
                                  pack="fas"
                                  icon="info-circle"
                                  type="is-info">
                              </b-icon>
                          </b-tooltip>
                      </span>
                    </b-switch>
                  </b-field>
                  </div>
                </div>
            </div>
            <br>
            <b-table
                :data="academics"
                :paginated=true
                per-page=10
                :current-page="currentPage"
                :card-layout="cardLayout"
                :pagination-simple=false
                pagination-position="bottom"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
                <b-table-column
                    field= 'person_name'
                    label= 'Participante'
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    <a @click="goToProfile(props.row.person_id)">{{ props.row.person_name }}</a>
                  </template>
                </b-table-column>
                <b-table-column
                    field= 'person_mail'
                    label= 'Email'
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.person_mail }}
                  </template>
                </b-table-column>
              <b-table-column
                  field= 'person_position'
                  label= 'Cargo'
                  searchable
                  :custom-search="searchPosition"
                  sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.person_function == null ?  props.row.person_position: props.row.person_function_name}}
                </template>
              </b-table-column>
              <b-table-column
                  field= 'participant_type_name'
                  label= 'Participa como'
                  searchable
                  sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.participant_type_name}}
                </template>
              </b-table-column>
              <template slot="empty" slot-scope="props">
                <div class="columns is-centered">
                  <div class="column is-6 is-centered has-text-centered">
                    <b-message v-if="can_edit" has-icon type="is-warning">
                      <h1> No se encontraron resultados, haga click <a @click="newParticipant()">aquí</a> para relacionar participantes.</h1>
                    </b-message>
                    <b-message v-else has-icon type="is-warning">
                      <h1> No se encontraron resultados.</h1>
                    </b-message>
                  </div>
                </div>
              </template>
            </b-table>
        </div>
    </div>
</template>
<script>
export default {
    name: 'AgreementAcademics',
    props:{
        academics:{
            type: Array,
            default: () => []
        },
        agreement_id:{
            type: Number
        },
        can_edit:{
            type: Boolean
        }
    },
    data(){
        return {
            currentPage: 1,
            cardLayout: false
        }
    },
    computed:{
        isAcademicsVoid(){
            return Object.entries(this.academics).length == 0
        }
    },
    methods:{
        newParticipant(){
            window.location.href="/agreements/"+this.agreement_id+"/edit/2"
        },
      goToProfile(id){
        window.location.href='/people/'+id
      },
      searchPosition(row,input){
          if(row.person_work_plant_name == null){
            if(row.person_position != null){
              return input && row.person_position &&  row.person_position.toLowerCase().includes(input.toLowerCase())
            }
          }
          else{
            return input && row.person_work_plant_name &&  row.person_work_plant_name.toLowerCase().includes(input.toLowerCase())
          }
      }
    }
}
</script>