<template>
  <div>
    <br>
    <div class="container" style="border-radius: 15px">
      <div class="columns is-centered">
        <div class="column box">
          <div class="title is-4">
            Patente
          </div>

          <hr class="dropdown-divider" aria-role="menuitem">

          <div class="columns">
            <div class="column is-two-fifths">
              <b-field label="Título">
                <b-input readonly v-model="patent.title" type="text"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Código Protección">
                <b-input readonly v-model="patent.protection_code"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Tipo de Protección">
                <b-input readonly v-model="patent.patent_type"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Título Corto">
                <b-input readonly v-model="patent.short_title"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Fecha de Creación">
                <b-input readonly v-model="patent.presentation_date"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Número de Solicitud">
                <b-input readonly v-model="patent.application_number"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Código Tecnología">
                <b-input readonly v-model="patent.technology_code"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="País">
                <b-input readonly v-model="patent.nationality"></b-input>
              </b-field>
            </div>
          </div>

<!--          <div class="columns">
            <div class="column">
              <b-field label="Origen del dato">
                <b-input v-if="patent.validation_attributes!=null" readonly v-model="patent.validation_attributes.data_source"></b-input>
                <b-input v-else readonly></b-input>
              </b-field>
            </div>
          </div>-->

          <br>
          <h3 class="is-size-3 has-text-weight-bold">Propietarias(os) Intelectuales</h3>
          <h3 class="is-size-4 has-text-weight-bold">Tabla Resumen Participantes</h3>
          <div class="column">
            <b-table
                :data="participantes"
                :paginated=true
                per-page=5
                striped
                hoverable
                :current-page="currentPage"
                :card-layout="cardLayout"
                :pagination-simple=false
                pagination-position="bottom"
                default-sort-direction="desc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="last_patent_state_date"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
              <b-table-column
                  field= 'name'
                  label= 'Nombres'
                  width="20em"
                  searchable
                  sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{  props.row.name }}
                </template>
              </b-table-column>
              <b-table-column
                  field= 'corporate_mail'
                  label= 'Correo Corporativo'
                  searchable
                  sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{  props.row.corporate_mail }}
                </template>
              </b-table-column>
              <b-table-column
                  field= 'unit'
                  label= 'Unidad'
                  searchable
                  sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{  props.row.unit }}
                </template>
              </b-table-column>
            </b-table>
          </div>

          <!--          <h3 class="is-size-3 has-text-weight-bold">
                      Referencias de participantes
                    </h3>
                    <div class="columns">
                      <div class="column">
                        <b-field label="Propietarias(os) Intelectuales(registrados y no registrados)">
                          <b-input readonly v-model="patent.participants_references" type="text"></b-input>
                        </b-field>
                      </div>
                    </div>-->

          <h3 class="is-size-3 has-text-weight-bold">Estados de la Patente</h3>
          <!-- FORMULARIO DE ESTADOS -->
          <div v-for="(estado,index) in patent.patent_states_attributes">
            <div class="columns">
              <div class="column">
                <b-field label="Estado de Patente">
                  <b-input readonly v-model="patent.patent_states_attributes[index].patent_state"></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de estado de Patente">
                  <b-input readonly v-model="patent.patent_states_attributes[index].hps_date">
                  </b-input>
                </b-field>
              </div>
            </div>

          </div>


        </div>
      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data(){
    return{
      currentPage:1,
      cardLayout:false,
      participantes: [],
      patent: {
        patent_type_attributes:[
          {id:-1,name:""}
        ],
        person_patents_attributes:[],
        validation_attributes:{},
      },
      isLoading:false

    }
  },
  created(){
    this.isLoading=true;
    return axios
        .get("/patents/show_patent_nullified/"+this.patentId+".json")
        .then(res => {
          this.patent = res.data[0];
          this.patent.presentation_date = this.formatDate(this.patent.presentation_date);
          this.patent.exit_date = this.formatDate(this.patent.exit_date);
          this.patent.patent_states_attributes = this.patent.patent_states_attributes.map(
              obj=> ({ ...obj, hps_date: this.formatDate(obj.hps_date) })
          )
          if(this.patent.person_patents_attributes!= null){
            this.participantes = this.patent.person_patents_attributes
          }
          this.isLoading=false;
        })
        .catch(e => {
          console.log(e);
          this.isLoading=false;
        });

  },
  props:[
    'patentId','cargoPrincipal'

  ],
  methods:{
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
  },
  computed:{

  }
}
</script>
