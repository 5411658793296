<template>
    <section>
        <div v-if="publicationIndicator.show">
            <b-collapse v-show="publicationIndicator.show_by_type_quartile"
                :open="isOpenSummary" @open="isOpenSummary = true" class="card" animation="slide" aria-id="contentIdForA11y3">
                <template #trigger="props">
                    <div class="card-header" role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.open">
                        <p class="card-header-title is-centered">
                            Resumen Indicador Publicaciones en Revista
                        </p>
                        <a class="card-header-icon">
                            <b-icon :icon="props.open ? 'angle-down' : 'angle-up'">
                            </b-icon>
                        </a>
                    </div>
                </template>
                <div class="card-content">
                    <div class="content">
                        <SummaryIndicatorPublication :total="publicationIndicator.total" />
                    </div>
                </div>
            </b-collapse>
            <br>
            <b-collapse v-show="publicationIndicator.show_by_year" :open="isOpenYear" @open="isOpenYear = true" class="card" animation="slide" aria-id="contentIdForA11y3">
                <template #trigger="props">
                    <div class="card-header" role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.open">
                        <p class="card-header-title is-centered">
                            Cantidad de Publicaciones en Revista por Año
                        </p>
                        <a class="card-header-icon">
                            <b-icon :icon="props.open ? 'angle-down' : 'angle-up'">
                            </b-icon>
                        </a>
                    </div>
                </template>
                <div class="card-content">
                    <div class="content">
                        <IndicatorByYear :years="publicationIndicator.indicator_by_years" :title="'publication_year'" />
                    </div>
                </div>
            </b-collapse>
            <br>
            <b-collapse v-show="publicationIndicator.show_by_type_quartile"
                :open="isOpenType" @open="isOpenType = true" class="card" animation="slide" aria-id="contentIdForA11y3">
                <template #trigger="props">
                    <div class="card-header" role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.open">
                        <p class="card-header-title is-centered">
                            Proporción de Publicaciones según Cuartil
                        </p>
                        <a class="card-header-icon">
                            <b-icon :icon="props.open ? 'angle-down' : 'angle-up'">
                            </b-icon>
                        </a>
                    </div>
                </template>
                <div class="card-content">
                    <div class="content">
                        <IndicatorByType :types="publicationIndicator.indicator_by_type_quartile" :title="'Cuartiles'" />
                    </div>
                </div>
            </b-collapse>
            <br>
            <b-collapse v-show="publicationIndicator.show_by_type_data_base"
                :open="isOpenTypeBarDataBase" @open="isOpenTypeBarDataBase = true" class="card" animation="slide" aria-id="contentIdForA11y3">
                <template #trigger="props">
                    <div class="card-header" role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.open">
                        <p class="card-header-title is-centered">
                            Cantidad de Publicaciones Indexadas en Bases de Datos Wos, Scopus o Scielo
                        </p>
                        <a class="card-header-icon">
                            <b-icon :icon="props.open ? 'angle-down' : 'angle-up'">
                            </b-icon>
                        </a>
                    </div>
                </template>
                <div class="card-content">
                    <div class="content">
                        <b-message type="is-warning" has-icon>
                            ¡Atención! Las Publicaciones en Revista pueden estar indexadas en más de una base de datos a la vez.
                        </b-message>
                        <IndicatorByTypeBar :types="publicationIndicator.indicator_by_type_data_base" :total="publicationIndicator.total" :title="'Bases de Datos'" />
                    </div>
                </div>
            </b-collapse>
            <br>
            <!-- <b-collapse v-show="publicationIndicator.show_by_type_data_base"
                :open="isOpenTypeBarDataBasePolarBar" @open="isOpenTypeBarDataBasePolarBar = true" class="card" animation="slide" aria-id="contentIdForA11y3">
                <template #trigger="props">
                    <div class="card-header" role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.open">
                        <p class="card-header-title is-centered">
                            Cantidad de Publicaciones Indexadas en Bases de Datos Wos, Scopus y Scielo
                        </p>
                        <a class="card-header-icon">
                            <b-icon :icon="props.open ? 'angle-down' : 'angle-up'">
                            </b-icon>
                        </a>
                    </div>
                </template>
                <div class="card-content">
                    <div class="content">
                        <IndicatorByTypePolarBar :types="publicationIndicator.indicator_by_type_data_base" 
                            :total="publicationIndicator.total_publications" :title="'Bases de Datos'" />
                    </div>
                </div>
            </b-collapse> -->
        </div>
        <div v-else>
            <div class="columns is-centered">
                <div class="column is-6 is-centered has-text-centered">
                    <b-message has-icon type="is-warning">
                        <h1> No se encontraron resultados. Porfavor ingrese nuevas Publicaciones en Revista para mostrar sus indicadores.</h1>
                    </b-message>
                </div>
            </div>
        </div>

        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

    </section>
</template>

<script>
import axios from 'axios'
import IndicatorByType from './indicator_by_type.vue'
import IndicatorByYear from './indicator_by_year.vue'
import IndicatorByTypeBar from './indicator_by_type_bar.vue'
// import IndicatorByTypePolarBar from './indicator_by_type_polar_bar.vue'
import SummaryIndicatorPublication from './summary_indicator_publication.vue'
export default {
    components: {
        IndicatorByType, IndicatorByYear, IndicatorByTypeBar, SummaryIndicatorPublication,
    },
    props: [
        'minYear', 'maxYear', 'unitId', 'changeFilter'
    ],
    data() {
        return {
            isOpenSummary: true,
            showYears: true,
            showType: true,
            isOpenType: true,
            isOpenYear: true,
            isOpenTypeBarDataBase: true,
            isOpenTypeBarDataBasePolarBar: true,
            publicationIndicator: {},
            isLoading: false,
        }
    },

    async created() {
        await this.getPublicationIndicator()
    },

    methods: {

        async getPublicationIndicator() {

            this.isLoading = true

            await axios
                    .get('/dashboards/get-unit-publication-indicator.json', {
                        params: {
                            unitId: this.unitId,
                            minYear: this.minYear,
                            maxYear: this.maxYear,
                        }
                    })
                    .then(response => {
                        this.publicationIndicator = response.data.publications
                        this.$emit('updatedData')
                        this.isLoading = false
                    })
                    .catch(error => {
                        var error_message
                        if (error.response != null) {
                            error_message = error.response.data.error
                        } else {
                            error_message = error
                        }
                        this.$buefy.dialog.alert({
                            title: 'Error',
                            message: 'Hubo un error al obtener los datos de Indicadores de Publicaciones de la Unidad.<br><br> Error:<br>' + error_message,
                            type: 'is-danger',
                            hasIcon: true,
                            icon: 'times-circle',
                            iconPack: 'fa',
                            ariaRole: 'alertdialog',
                            ariaModal: true
                        })
                        this.$emit('updatedData')
                        this.isLoading = false
                    })

        }

    },

    watch: {
        changeFilter() {
            this.getPublicationIndicator()
            deep: true
        },
    },


}
</script>