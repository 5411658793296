<template>
  <div>
    <div class="container" style="border-radius: 15px">
      <div class="columns">
        <div class="column">
          <div class="title is-5">
            Afiliación a sociedades profesionales o científicas
            <hr class="dropdown-divider" aria-role="menuitem">
          </div>
          <div class="columns">
            <div class="column">
                    <span class="is-pulled-right">
                        <button class="button is-primary" @click="newRelatedEntity()">
                            <b-icon icon="plus"></b-icon>
                            <span>Agregar Afiliación</span>
                        </button>
                    </span>
            </div>
          </div>
        </div>
      </div>
      <b-table
          :data="related_institutions"
          :paginated=true
          per-page=10
          :current-page="currentPage"
          :card-layout="cardLayout"
          :pagination-simple=false
          pagination-position="bottom"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field= 'partner_entity_name'
            label= 'Nombre de institución'
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.partner_entity_name }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'type_member'
            label= 'Miembro en calidad de'
            sortable
            searchable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            <div v-if="props.row.type_member != null">{{ props.row.type_member }}</div>
            <div v-else></div>
          </template>
        </b-table-column>
        <b-table-column
            field= 'start_date'
            label= 'Fecha de inicio'
            searchable
            :custom-search="searchInitDate"
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL")  : "" }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'end_date'
            label= 'Fecha de término'
            searchable
            :custom-search="searchEndDate"
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL")  : "" }}
          </template>
        </b-table-column>
        <b-table-column
            label="Acciones"
            v-slot="props">
          <b-icon
              pack="fas"
              icon="eye"
              size="is-medium"
              @click.native="showModal(props.row)"/>
          <b-icon
              pack="fas"
              icon="edit"
              size="is-medium"
              @click.native="editModal(props.row)"/>
        </b-table-column>
        <template slot="empty" slot-scope="props">
          <div class="columns is-centered">
            <div class="column is-6 is-centered has-text-centered">
              <!--              <b-message v-if="can_edit" has-icon type="is-warning">
                              <h1> No se encontraron resultados, haga click <a @click="newParticipant()">aquí</a> para relacionar entidades.</h1>
                            </b-message>-->
              <b-message has-icon type="is-warning">
                <h1> No se encontraron resultados.</h1>
              </b-message>
            </div>
          </div>
        </template>
      </b-table>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="isModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        width="90%"
        aria-role="dialog"
        aria-modal
        :can-cancel="false">
      <template #default="props">
        <modal_new_related_institution
            :person-id="personId"
            :update="update"
            :old_related_institution="old_related_institution"
            @close="props.close"></modal_new_related_institution>
      </template>
    </b-modal>
    <b-modal
        v-model="isModalActiveShow"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        width="90%"
        aria-role="dialog"
        aria-modal
        :can-cancel="false">
      <template #default="props">
        <modal_show_related_institution
            :person-id="personId"
            :old_related_institution="old_related_institution"
            @close="props.close"></modal_show_related_institution>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {searchDate} from "../../packs/utilities";
import modal_new_related_institution from "./modal_new_related_institution";
import modal_show_related_institution from "./modal_show_related_institution";
import axios from "axios";
import moment from "moment";

export default {
  name: "related_institutions_data_tab",
  props:['personId'],
  components: {modal_new_related_institution,modal_show_related_institution},
  data(){
    return{
      isLoading: false,
      related_institutions: [],
      currentPage: 1,
      cardLayout: false,
      update: false,
      old_related_institution:{},
      isModalActiveShow: false,
      isModalActive: false
    }
  },
  created() {
    this.getRelatedInstitutions()
  },
  methods:{
    getRelatedInstitutions(){
      this.isLoading = true
      this.old_related_institution = {}
      this.update = false
      axios
          .get('/my-related-institutions/'+this.personId+'.json')
          .then(response =>{
            this.related_institutions = response.data
            this.related_institutions = this.related_institutions.map(institution =>{
              var newInstitution = institution
              newInstitution["start_date"] =  moment(newInstitution["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newInstitution["end_date"] =  moment(newInstitution["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newInstitution;
            })
            this.isLoading = false
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false
          })
    },
    newRelatedEntity(){
      this.update = false
      this.isModalActive = true
    },
    searchInitDate(row,input){
      return searchDate(row.start_date,input)
    },
    searchEndDate(row,input){
      return searchDate(row.end_date,input)
    },
    showModal(dato){
      this.isModalActiveShow = true
      this.old_related_institution = dato
    },
    editModal(dato){
      this.update = true
      this.isModalActive = true
      this.old_related_institution = dato
    }
  },
  watch: {
    isModalActive: function () {
      if (!this.isModalActive) {
        this.getRelatedInstitutions()
      }
    },
    isModalActiveShow: function () {
      if (!this.isModalActiveShow) {
        this.getRelatedInstitutions()
      }
    },
  }
}
</script>
