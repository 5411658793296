<template>
  <div class="box mt-5">
    <h1 class="title is-2 has-text-weight-medium has-text-centered">Facultad de Ingeniería</h1>
    <b-tabs :size="size"
            :type="type"
            :expanded=false
            position="is-centered"
    >
      <b-tab-item label="Autoridades" icon="users">
        <h1 class="title is-2 has-text-weight-medium has-text-centered">Autoridades</h1>
        <div class="columns">
          <div class="column is-centered">
            <div class="box">
              <div class="image-container" style="display: flex;justify-content: center;" href="/people/" @click="redirect(autoridades.decano.id)">
                <b-image
                    :src=getPhotoUrl(autoridades.decano)
                    alt="Foto de perfil"
                    style="max-width: 200px;height: 200px;"
                    :rounded="true"
                ></b-image>
              </div>
                <p class="title is-5" style="text-align:center; color: #A2A9AD;">{{this.autoridades.decano===undefined ? "" : this.autoridades.decano.cargo}}</p>
                <h4 class="subtitle is-4" style="text-align:center; color:#EF7D00;">{{this.autoridades.decano===undefined ? "" : this.autoridades.decano.nombre}}</h4>
                <h5 class="title is-5" style="text-align:center; color: #A2A9AD;">Correo Electrónico</h5>
              <h5 class="subtitle is-5" style="text-align:center; color: #A2A9AD;">{{this.autoridades.decano===undefined ? "" : this.autoridades.decano.correo}}</h5>


            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-centered">
            <div class="box">
              <div class="image-container" style="display: flex;justify-content: center;" @click="redirect(autoridades.vice_forma.id)">
              <b-image
                  :src=getPhotoUrl(autoridades.vice_forma)
                  alt="Foto de perfil"
                  style="max-width: 200px;height: 200px;"
                  :rounded="true"
              ></b-image>
              </div>
              <p class="title is-5" style="text-align:center;color: #A2A9AD">{{this.autoridades.vice_forma===undefined ? "" : this.autoridades.vice_forma.cargo}}</p>
              <h4 class="subtitle is-4" style="text-align:center; color:#EF7D00;">{{this.autoridades.vice_forma===undefined ? "" : this.autoridades.vice_forma.nombre}}</h4>
              <h5 class="title is-5" style="text-align:center; color: #A2A9AD;">Correo Electrónico</h5>
              <h5 class="subtitle is-5" style="text-align:center; color: #A2A9AD;">{{this.autoridades.vice_forma===undefined ? "" : this.autoridades.vice_forma.correo}}</h5>
            </div>
          </div>
          <div class="column">
            <div class="box">
              <div class="image-container" style="display: flex;justify-content: center;" @click="redirect(autoridades.vice_id.id)">
              <b-image
                  :src=getPhotoUrl(autoridades.vice_id)
                  alt="Foto de perfil"
                  style="max-width: 200px;height: 200px;"
                  :rounded="true"
              ></b-image>
                </div>
              <p class="title is-5" style="text-align:center;color: #A2A9AD">{{this.autoridades.vice_id===undefined ? "" : this.autoridades.vice_id.cargo}}</p>
              <h4 class="subtitle is-4" style="text-align:center; color:#EF7D00;">{{this.autoridades.vice_id===undefined ? "" : this.autoridades.vice_id.nombre}}</h4>
              <h5 class="title is-5" style="text-align:center; color: #A2A9AD;">Correo Electrónico</h5>
              <h5 class="subtitle is-5" style="text-align:center; color: #A2A9AD;">{{this.autoridades.vice_id===undefined ? "" : this.autoridades.vice_id.correo}}</h5>
            </div>
          </div>
          <div class="column">
            <div class="box">
              <div class="image-container" style="display: flex;justify-content: center;" @click="redirect(autoridades.secretario.id)">
              <b-image
                  :src=getPhotoUrl(autoridades.secretario)
                  alt="Foto de perfil"
                  style="max-width: 200px;height: 200px;"
                  :rounded="true"
              ></b-image>
                </div>
              <p class="title is-5" style="text-align:center; color: #A2A9AD">{{this.autoridades.secretario===undefined ? "" : this.autoridades.secretario.cargo}}</p>
              <h4 class="subtitle is-4" style="text-align:center; color:#EF7D00;">{{this.autoridades.secretario===undefined ? "" : this.autoridades.secretario.nombre}}</h4>
              <h5 class="title is-5" style="text-align:center; color: #A2A9AD;">Correo Electrónico</h5>
              <h5 class="subtitle is-5" style="text-align:center; color: #A2A9AD;">{{this.autoridades.secretario===undefined ? "" : this.autoridades.secretario.correo}}</h5>
            </div>
          </div>
          <div class="column">
            <div class="box">
              <div class="image-container" style="display: flex;justify-content: center;" @click="redirect(autoridades.dir_vime.id)">
              <b-image
                  :src=getPhotoUrl(autoridades.dir_vime)
                  alt="Foto de perfil"
                  style="max-width:200px;height: 200px;"
                  :rounded="true"
              ></b-image>
                </div>
              <p class="title is-5" style="text-align:center; color: #A2A9AD">{{this.autoridades.dir_vime===undefined ? "" : this.autoridades.dir_vime.cargo}}</p>
              <h4 class="subtitle is-4" style="text-align:center;color:#EF7D00;">{{this.autoridades.dir_vime===undefined ? "" : this.autoridades.dir_vime.nombre}}</h4>
              <h5 class="title is-5" style="text-align:center; color: #A2A9AD;">Correo Electrónico</h5>
              <h5 class="subtitle is-5" style="text-align:center; color: #A2A9AD;">{{this.autoridades.dir_vime===undefined ? "" : this.autoridades.dir_vime.correo}}</h5>
            </div>
          </div>
          <div class="column">
            <div class="box">
              <div class="image-container" style="display: flex;justify-content: center;" @click="redirect(autoridades.director_doc.id)">
              <b-image
                  :src=getPhotoUrl(autoridades.director_doc)
                  alt="Foto de perfil"
                  style="max-width: 200px;height: 200px;"
                  :rounded="true"
              ></b-image>
                </div>
              <p class="title is-5" style="text-align:center; color: #A2A9AD">{{this.autoridades.director_doc===undefined ? "" : this.autoridades.director_doc.cargo}}</p>
              <h4 class="subtitle is-4" style="text-align:center; color:#EF7D00;">{{this.autoridades.director_doc===undefined ? "" : this.autoridades.director_doc.nombre}}</h4>
              <h5 class="title is-5" style="text-align:center; color: #A2A9AD;">Correo Electrónico</h5>
              <h5 class="subtitle is-5" style="text-align:center; color: #A2A9AD;">{{this.autoridades.director_doc===undefined ? "" : this.autoridades.director_doc.correo}}</h5>
            </div>
          </div>
        </div>
        <h1 class="title is-2 has-text-weight-medium has-text-centered">Autoridades Departamentos</h1>
        <div class="rows">
            <div v-for="(object, i) in this.autoridades_departamentos" class="row">
              <div v-if="i%3===0" class="columns">
                <div class="column">
              <div class="box">
                <div class="image-container" style="display: flex;justify-content: center;" href="/people/" @click="redirect(autoridades_departamentos[i].id)">
                  <b-image
                      :src=getPhotoUrl(autoridades_departamentos[i])
                      alt="Foto de perfil"
                      style="max-width: 200px;height: 200px;"
                      :rounded="true"
                  ></b-image>
                </div>
                <p class="title is-5" style="text-align:center; color: #A2A9AD">{{autoridades_departamentos[i].cargo}}</p>
                <h4 class="subtitle is-4" style="text-align:center; color:#EF7D00;">{{autoridades_departamentos[i].director}}</h4>
                <h5 class="title is-5" style="text-align:center; color: #A2A9AD;">Correo Electrónico</h5>
                <h5 class="subtitle is-5" style="text-align:center; color: #A2A9AD;">{{autoridades_departamentos[i].correo}}</h5>
              </div>
                </div>
                <div class="column">
                <div class="box" v-if="i+1<autoridades_departamentos.length">
                  <div class="image-container" style="display: flex;justify-content: center;" href="/people/" @click="redirect(autoridades_departamentos[i+1].id)">
                    <b-image
                        :src=getPhotoUrl(autoridades_departamentos[i+1])
                        alt="Foto de perfil"
                        style="max-width: 200px;height: 200px;"
                        :rounded="true"
                    ></b-image>
                  </div>
                  <p class="title is-5" style="text-align:center; color: #A2A9AD">{{autoridades_departamentos[i+1].cargo}}</p>
                  <h4 class="subtitle is-4" style="text-align:center; color:#EF7D00;">{{autoridades_departamentos[i+1].director}}</h4>
                  <h5 class="title is-5" style="text-align:center; color: #A2A9AD;">Correo Electrónico</h5>
                  <h5 class="subtitle is-5" style="text-align:center; color: #A2A9AD;">{{autoridades_departamentos[i+1].correo}}</h5>
              </div>
                </div>
              <div class="column">
                <div class="box" v-if="i+2<autoridades_departamentos.length">
                  <div class="image-container" style="display: flex;justify-content: center;" href="/people/" @click="redirect(autoridades_departamentos[i+2].id)">
                    <b-image
                        :src=getPhotoUrl(autoridades_departamentos[i+2])
                        alt="Foto de perfil"
                        style="max-width: 200px;height: 200px;"
                        :rounded="true"
                    ></b-image>
                  </div>
                  <p class="title is-5" style="text-align:center; color: #A2A9AD">{{autoridades_departamentos[i+2].cargo}}</p>
                  <h4 class="subtitle is-4" style="text-align:center; color:#EF7D00;">{{autoridades_departamentos[i+2].director}}</h4>
                  <h5 class="title is-5" style="text-align:center; color: #A2A9AD;">Correo Electrónico</h5>
                  <h5 class="subtitle is-5" style="text-align:center; color: #A2A9AD;">{{autoridades_departamentos[i+2].correo}}</h5>
                </div>
              </div>
              </div>
            </div>
        </div>
      </b-tab-item>

      <b-tab-item label="Departamentos académicos" icon="school">
        <h1 class="title is-2 has-text-weight-medium has-text-centered">Departamentos académicos</h1>
        <div class="columns is-multiline" :data="departamentos">
          <div class="column is-one-third" v-for="dpto in departamentos" :key="dpto.id">
            <div class="tile notification is-primary box">
              <a @click="goToUnit(dpto.id)"><p class="title is-4">{{dpto.name}}</p></a>
            </div>
          </div>
        </div>
      </b-tab-item>

    </b-tabs>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>


<script>
import axios from "axios";

export default {
  data(){
    return{
      type: "is-boxed",
      size: "is-small",
      isLoading:false,
      departamentos: [],
      autoridades:[],
      autoridades_departamentos: [],
    }
  },
  created(){
    this.isLoading=true;
    return axios.get("/admin/units/"+this.unitId+".json")
        .then(res => {
          this.autoridades = res.data['autoridades'];
          this.departamentos = res.data['departamentos'];
          this.autoridades_departamentos = res.data['autoridades_departamentos']
          this.isLoading=false;
        })
        .catch(e => {
          this.isLoading=false;
          console.log(e);
        });

  },
  props:[
      'unitId'
  ],
  methods:{
    goToUnit(unit_id){
      this.isLoading=true;
      window.location.href="/admin/units/"+unit_id
    },
    redirect(id){
      console.log(id)
      if(id===undefined || id === null){
        return
      }
      else{
        return window.location.href = '/people/'+id
      }


    },
    getPhotoUrl(person){
      var defaultUrl = "/blank_profile_photo.png"
      if (person){
        if (person.photo_url){
          return person.photo_url;
        }
      }
      return defaultUrl;

    }
  },
  computed:{

  }
}
</script>

