<template>
  <div>
    <div class="container" style="border-radius: 15px">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
          <b-message type="is-danger" has-icon>
            <div v-for="(item,index) in errores_validacion">
              {{ item[0] }}
            </div>
          </b-message>
        </div>
        <form @submit.prevent="handleSubmit(saveMyAcademicData)">
          <div class="columns">
            <div class="column">

                <div class="title is-5">
                  Datos académicos
                  <hr class="dropdown-divider" aria-role="menuitem">
                </div>

                <div class="columns">
                  <div class="column">
                    <b-field label="Alias">
                      <b-input v-model="person.alias" type="text"></b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="ORCID">
                      <b-input expanded v-model="person.orcid" type="text"></b-input>
                      <p class="control">
                        <b-button tag="a" :href="person.orcid ? 'https://orcid.org/'+person.orcid : 'https://orcid.org/'" target="_blank" icon-left="external-link-alt" type="is-link">Link</b-button>
                      </p>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="ID Scopus">
                      <b-input v-model="person.scopus_id" type="text"></b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="ID Wos">
                      <b-input v-model="person.wos_id" type="text"></b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="ID Scielo">
                      <b-input v-model="person.scielo_id" type="text"></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="H-index">
                      <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                        <b-input v-model="person.h_index" type="number"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Link propio">
                      <b-input expanded v-model="person.link" type="text" maxlength="255" :has-counter="false"></b-input>
                      <p class="control">
                        <b-button tag="a" :href="person.link ? person.link : ''" target="_blank" icon-left="external-link-alt" type="is-link">Link</b-button>
                      </p>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Título Curriculum">
                      <b-input v-model="person.title" type="text" maxlength="125"
                               placeholder="Ingrese un título para el curriculum"></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Reseña">
                      <b-input v-model="person.biography" type="textarea" maxlength="500"
                               placeholder="Ingrese una descripción breve sobre ud."></b-input>
                    </b-field>
                  </div>
                </div>

                <hr class="dropdown-divider" aria-role="menuitem">
                <div class="title is-5">
                  Expertiz
                  <b-tooltip
                      label="Presione enter o coma luego de escribir la expertiz para que sea agregada"
                      position="is-right"
                      size="is-medium"
                      multilined>

                    <b-icon
                        pack="fas"
                        icon="info-circle"
                        size="is-small"
                        type="is-info">
                    </b-icon>
                  </b-tooltip>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label=" ">
                      <b-taginput
                          v-model="person.key_words_attributes"
                          :data="filtered_key_words"
                          :allow-new="true"
                          :allow-duplicates="false"
                          field="name"
                          icon="tag"
                          autocomplete
                          placeholder="Agrega una expertiz"
                          :beforeAdding="beforeAddTag"
                          @typing="getFilteredKeyWords">
                      </b-taginput>
                    </b-field>
                  </div>
<!--                  <div class="column is-narrow">
                    <b-field label=" ">
                      <b-tooltip label="Si desea ingresar varios tags al mismo tiempo, presione este botón"
                                 position="is-top">
                        <b-button class="is-primary is-outlined" @click="pasteExpertiz()">
                          Importar Expertiz
                        </b-button>
                      </b-tooltip>
                    </b-field>
                  </div>-->
                </div>
                <div class="columns">
                  <div class="column">
                    <div class="actions has-text-right">
                      <button type="submit" @click="checkValidation" class="button is-primary">Guardar datos académicos</button>
                    </div>
                  </div>
                </div>

            </div>
          </div>
        </form>
<!--        <b-modal
            v-model="isModalActive"
            :destroy-on-hide="true"
            width="50%"
            :can-cancel="canCancel">
          <template #default="props">
            <modal_add_expertiz
                query-type="update"
                modal-header="Importar Expertiz"
                @close="props.close"
                @clicked="onClickChild"></modal_add_expertiz>
          </template>
        </b-modal>-->
      </ValidationObserver>
    </div>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>

</template>

<script>
import axios from "axios";
//import modal_add_expertiz from "./modal_add_expertiz";
export default {
  name: "academic_data_tab",
  data(){
    return{
      isLoading:false,
      person:{},
      lineas_investigacion:[],
      lineas_investigacion_filtradas:[],
      key_words:[],
      filtered_key_words:[],
      errores_validacion:[],
      photo:{name:""},
      isModalActive: false,
      canCancel: false,
      control: false,

    }
  },
  async created(){
    await this.getPerson();
  },
  props:[
    'personId'
  ],
/*  components:{
    modal_add_expertiz
  },*/
  methods:{
    async checkValidation(){
      const valid = await this.$refs.observer.validate()
      if(!valid){
        this.$buefy.dialog.alert({
          message: 'Algunos campos presentan errores.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
    getPerson(){
      let vm = this;
      vm.isLoading=true;
      axios
          .get("/people/"+vm.personId+".json")
          .then(res => {
            vm.person = res.data
            this.getPeopleKeywords()
          })
          .catch(e => {
            console.log(e);
            vm.queryError(vm);
            vm.isLoading=false;
          });
    },
    async getPeopleKeywords(){
      let vm = this;
      vm.isLoading=true;
      axios
          .get("/people/keywords.json")
          .then(res => {
            vm.key_words = res.data
            vm.isLoading=false;
          })
          .catch(e => {
            console.log(e);
            vm.queryError(vm);
            vm.isLoading=false;
          });
    },
    saveMyAcademicData(){
      let vm = this;
      vm.isLoading = true;
      //vm.person.photo = this.photo;
      let formData = new FormData();
      axios
          .put("/people/"+this.personId+"/my-academic-data/update.json", {
                person: vm.person,
              },
          )
          .then(response => {
            vm.errores_validacion = [];
            vm.$buefy.dialog.alert({
              message: 'Tus datos académicos han sido actualizados satisfactoriamente.',
              confirmText: 'Ok'
            });
            vm.isLoading=false;
            vm.getPerson();
          }).
      catch(error => {
        vm.errores_validacion = error.response.data
        var elmnt = document.getElementById("app");
        elmnt.scrollIntoView();
        vm.isLoading=false;
      })
    },
    queryError(vm){
      vm.$buefy.dialog.alert({
        title: 'Error',
        message: 'Hubo un error en su solicitud.',
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true
      })
    },
    getFilteredResearchLines(text) {
      this.lineas_investigacion_filtradas = this.lineas_investigacion.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    getFilteredKeyWords(text) {
      this.filtered_key_words = this.key_words.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    addTags(value){
      var aux = 0
      var key_words_attributes = this.person.key_words_attributes
      var temp_key_word = this.person.key_words_attributes
      if (value != null && value.length > 0){
        value.forEach(function (tag){
          aux = 0
          var auxTag = tag.toUpperCase()
          auxTag = auxTag.trim()
          temp_key_word.forEach(function (word){
            if(word.constructor.name === 'String'){
              if(word === auxTag){
                aux = 1
              }
            }
            else{
              if(word.name === auxTag){
                aux = 1
              }
            }
          })
          if (aux == 0){
            key_words_attributes.push(auxTag)
          }
        })
        this.person.key_words_attributes = key_words_attributes
      }
    },
    /*onClickChild(value){
      var aux = 0
      var key_words_attributes = this.person.key_words_attributes
      var temp_key_word = this.person.key_words_attributes
      if (value != null && value.length > 0){
        value.forEach(function (tag){
          aux = 0
          temp_key_word.forEach(function (word){
            if(word.constructor.name === 'String'){
              if(word === tag){
                aux = 1
              }
            }
            else{
              if(word.name === tag){
                aux = 1
              }
            }
          })
          if (aux == 0){
            key_words_attributes.push(tag)
          }
        })
        this.person.key_words_attributes = key_words_attributes
      }
    },*/
    beforeAddTag(tags){

      if (tags.constructor.name === 'String'){
        var splitTags = tags.split(",")

        if(splitTags.length == 1){
          splitTags = tags.split(";")
        }

        this.addTags(splitTags)
      }
      else{
        var result = this.person.key_words_attributes.filter(tag => tag.name === tags.name)
        if(result.length == 0){
          this.person.key_words_attributes.push(tags)
        }
      }
    }

  },
  computed:{

  }
}
</script>

<style scoped>

</style>