<template>
    <div class="partnerentitiesvimeactivities">
        <div class="container">
          <div v-if="can_create_activity" class="columns">
            <div class="column">
                        <span class="is-pulled-right">
                            <button class="button is-primary" @click="addNewActivity()">
                                <b-icon icon="plus"></b-icon>
                                <span>Añadir Actividad</span>
                            </button>
                        </span>
            </div>
          </div>
          <div class="columns">
            <div class="column has-text-right">
              <b-field>
                <b-switch v-model="cardLayout">
            <span>
              Formato tarjeta (sin filtros ni búsqueda)
               <b-tooltip
                   label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                   multilined
                   position="is-left">
                    <b-icon
                        pack="fas"
                        icon="info-circle"
                        type="is-info">
                    </b-icon>
                </b-tooltip>
            </span>
                </b-switch>
              </b-field>

            </div>
          </div>
        </div>

        <div>
            <b-table
                :data="activities"
                :paginated=true
                per-page=10
                striped
                hoverable
                :current-page="currentPage"
                :card-layout="cardLayout"
                :pagination-simple=false
                pagination-position="bottom"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="publication.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual"
                header-class="is-primary">
                <b-table-column
                    field="activity.name"
                    label= 'Nombre'
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    <a @click="goToActivity(props.row.activity.id)"><i class="fas fa-user-circle"></i> {{ props.row.activity.name }}</a>
                  </template>
                </b-table-column>
                <b-table-column
                    field="activity.theme"
                    label= 'Tema'
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.activity.theme}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="type"
                    label= 'Tipo de actividad'
                    searchable
                    sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{props.row.type}}
                    </template>
                </b-table-column>
                <b-table-column
                    field="activity.start_date"
                    label= 'Fecha inicio'
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    <div v-if="props.row.activity.start_date != null">{{props.row.activity.start_date}}</div>
                    <div v-else> No se ha encontrado registro</div>
                  </template>
                </b-table-column>
                <b-table-column
                    field="activity.end_date"
                    label= 'Fecha término'
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    <div v-if="props.row.activity.end_date">{{props.row.activity.end_date}}</div>
                    <div v-else>No se ha encontrado registro</div>
                  </template>
                </b-table-column>
              <template slot="empty" slot-scope="props">
                <div class="columns is-centered">
                  <div class="column is-6 is-centered has-text-centered">
                    <b-message v-if="can_create_activity" has-icon type="is-warning">
                      <h1> No se encontraron resultados, haz click <a @click="addNewActivity()">aquí</a> para añadir una nueva actividad.</h1>
                    </b-message>
                    <b-message v-else has-icon type="is-warning">
                      <h1> No se encontraron resultados</h1>
                    </b-message>
                  </div>
                </div>
              </template>

            </b-table>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PartnerEntitiesVimeActivities',
    props: {
       activities: {
           type: Array,
           default: () => []
       } ,
      can_create_activity:{
         type: Boolean
      }
    },
    data(){
        return {
            currentPage: 1,
            cardLayout: false
        }
    },
    methods:{
        isActivitiesVoid(){
            return Object.entries(this.activities).length == 0
        },
        goToActivity(activity_id){
            this.isLoading=true;
            window.location.href="/vime_activities/"+activity_id
        },
        addNewActivity(){
            window.location.href="/vime_activities/new"
        }
    }
}
</script>