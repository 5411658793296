<template>
    <section>

        <b-table striped :data="person_functions" paginated per-page=10 :pagination-simple=false
            pagination-position="bottom" aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
            aria-current-label="Página actual">

            <b-table-column label="Función" field="function_name" sortable searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Función..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.function_name }}
                </template>
            </b-table-column>

            <b-table-column label="Unidad de Desempeño" field="unit_name" sortable searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Unidad..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.unit_name }}
                </template>
            </b-table-column>

            <b-table-column label="Fecha de Inicio" field="start_date" sortable searchable :custom-search="searchInitDate">
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL") : '' }}
                </template>
            </b-table-column>

            <b-table-column label="Fecha de Término" field="end_date" sortable searchable :custom-search="searchEndDate">
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL") : 'Actualidad'
                    }}
                </template>
            </b-table-column>

            <template slot="empty" slot-scope="props">
              <div class="columns is-centered">
                <div class="column is-6 is-centered has-text-centered">
                  <b-message type="is-warning">
                    No se encontraron resultados. Si posee información de sus funciones administrativas
                    por favor comuníquese con el jefe administrativo de su departamento y solicite la actualización de su
                    información.
                  </b-message>
                </div>
              </div>
            </template>

        </b-table>

        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

    </section>
</template>

<script>
import axios from 'axios'
import moment from "moment"
import { searchDate } from "../../packs/utilities"
export default {

    props: [
        'personId', 'photoUrl', 'cargoPrincipal', 'canCreateDocument'
    ],

    data() {
        return {
            isLoading: false,
            person_functions: [],
        }
    },

    async created() {
        await this.initialize()
    },

    methods: {

        async initialize() {
            this.isLoading = true
            await axios
                .get("/people/" + this.personId + "/my-administrative-functions.json")
                .then(res => {
                    this.person_functions = res.data
                    this.person_functions = this.person_functions.map(person_function => {
                        var newFunction = person_function
                        newFunction["start_date"] = moment(newFunction["start_date"], ["YYYY-MM-DD", "DD-MM-YYYY"]);
                        newFunction["end_date"] = moment(newFunction["end_date"], ["YYYY-MM-DD", "DD-MM-YYYY"])
                        return newFunction
                    })
                    this.isLoading = false
                }).catch(error => {

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al traer sus datos de Funciones Administrativas, favor de revisar los errores.',
                        type: 'is-danger',
                        hasIcon: true,
                        iconPack: 'fa',
                        icon: 'times-circle',
                        confirmText: 'Ok',

                    })

                    console.log(error)
                    this.isLoading = false
                })

        },

        searchInitDate(row, input) {
            return searchDate(row.start_date, input)
        },
        searchEndDate(row, input) {
            return searchDate(row.end_date, input)
        },

    },

}
</script>