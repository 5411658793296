<template>
  <section>
    <div class="container">
      <!--DATA TABLE-->
      <b-table
          :data="continuing_studies"
          :paginated= true
          per-page="10"
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="product.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field="obtaining_date"
            label="Fecha obtención"
            width="110"
            sortable
            searchable :custom-search="searchEgressDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.obtaining_date._isValid ? new Date(props.row.obtaining_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            field="continuing_study"
            label="Tipo"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.continuing_studies_type!==undefined ? props.row.continuing_studies_type.name : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="name"
            label="Nombre"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row!==undefined ? props.row.name : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="study.entity_show_name"
            label="Institución"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.entity_show_name}}
          </template>
        </b-table-column>
        <b-table-column
            label="Acciones"
            width="120"
            v-slot="props">
          <b-tooltip
              v-if="props.row.can_show"
              label="Ver información completa del estudio anulado."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="eye"
                type="is-primary"
                @click="execShowModal(props.row.id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_restore"
              label="Restaurar estudio anulado."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="trash-restore"
                type="is-success is-light is-outlined"
                @click="execRestoreModal(props.row.id)"/>
          </b-tooltip>
        </b-table-column>
      </b-table>
      <!--FIN DATA TABLE-->
      <!-- MODALES -->
      <b-modal
          v-model="isShowModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          width="1024">
        <template #default="props">
          <component  :is="showName"
                      :id="selected"
                      :person-id="personId"
                      :modal-header="title"
                      :person_name="person_name"
                      @close="props.close"/>
        </template>
      </b-modal>
      <b-loading :is-full-page="true"
                 v-model="isLoading"
                 :can-cancel="false"/>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Course from './course'
import Graduate from './graduate'
import Certificate from './certificate'
import LanguageCertificate from "./language_certificate"
import Other from "./other"
import {searchDate, formatDate} from "../../../packs/utilities";
import moment from "moment";
export default {
  name: "null_continuing_studies",
  props:[
    'personId', 'can_create_study', 'person_name'
  ],
  components:{
    Course,
    Graduate,
    Certificate,
    LanguageCertificate,
    Other
  },
  data(){
    return {
      selected: null,
      index: null,
      canCancel: ['escape'],
      currentPage: 1,
      isAddModalActive: false,
      isEditModalActive: false,
      isShowModalActive: false,
      isLoading:false,
      continuing_studies: [],
      isRestoreActive: false,
      showName: "",
      title: ""
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    initialize(){
      console.log("CAN CREATE STUDY: ", this.can_create_study)
      this.isLoading = true;
      return axios
          .get("/people/"+this.personId+"/null-continuing-studies.json")
          .then(res => {
            this.continuing_studies = res.data
            this.continuing_studies = this.continuing_studies.map(study => {
              var newStudy = study
              newStudy["obtaining_date"] = moment(newStudy["obtaining_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newStudy;
            });
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })

    },
    execShowModal(id){
      this.selected = id;
      let continuing_study = this.getType(id)
      if(continuing_study.continuing_studies_type!== undefined){
        if(continuing_study.continuing_studies_type.name==="DIPLOMADO") {
          console.log("DIPLOMADO¿¿¿")
          this.showName = "Graduate"
          this.title = "Diplomado"+" - "+this.person_name
        }
        else if (continuing_study.continuing_studies_type.name==="CURSO") {
          this.showName = "Course"
          this.title = "Curso"+" - "+this.person_name
        }
        else if (continuing_study.continuing_studies_type.name==="CERTIFICACION") {
          this.showName = "Certificate"
          this.title = "Certificación"+" - "+this.person_name
        }
        else if (continuing_study.continuing_studies_type.name==="CERTIFICACION DE IDIOMA") {
          this.showName = "LanguageCertificate"
          this.title = "Certificación de Idioma"+" - "+this.person_name
        }
        else {
          this.showName = "Other"
          this.title = "Perfeccionamiento"+" - "+this.person_name
        }
      }
      else{
        this.showName = "Other"
        this.title = "Perfeccionamiento"+" - "+this.person_name
      }
      //console.log("APPOINTMENT "+ this.showName)
      this.isShowModalActive = true;
    },
    // yyyy/mm/dd -> dd/mm/yyyy
    formatDate,
    getType(selected){
      return this.continuing_studies.find(o => o.id === selected);
    },
    execRestoreModal(id){
      let vm = this;
      vm.$buefy.dialog.confirm({
        title: 'Restaurar perfeccionamiento',
        message: '¿Está seguro que desea <b>restaurar</b> el perfeccionamiento? Esta acción revertirá la anulación.',
        confirmText: 'Restaurar Perfeccionamiento',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          vm.isRestoreActive = true
          vm.isLoading = true
          axios
              .put("/admin/continuing_studies/" + id + "/restore.json")
              .then(res => {
                vm.$buefy.dialog.alert({
                  message: 'Perfeccionamiento restaurado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                vm.isLoading = false;
                vm.isRestoreActive = false;
              }).catch(error => {
            console.log(error)
            vm.$buefy.dialog.alert({
              message: '<b>Perfeccionamiento no pudo ser restaurado</b><br>',
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            vm.isLoading = false;
            vm.isRestoreActive = false;
          })
        }
      })

    },
    searchEgressDate(row, input){
      return searchDate(row.obtaining_date, input)
    }
  },
  watch:{
    isShowModalActive: function () {
      if (!this.isShowModalActive) {
        this.initialize()
      }
    },
    isRestoreActive: function () {
      if (!this.isRestoreActive){
        this.initialize()
      }
    },

  }
}
</script>

<style scoped>

</style>