<template>
<section>
  <div v-if="project.is_creator && project.add_remmitance_financing_permission"  class="actions has-text-right">
    <b-button type="is-primary" icon-left="plus" @click="openModal()">Añadir Remesa</b-button>
  </div>
  <b-table
      :data="financings"
      :paginated=true
      per-page=10
      striped
      hoverable
      :key="tableDataKey"
      :current-page="currentPage"
      :card-layout="cardLayout"
      :pagination-simple=false
      pagination-position="bottom"
      default-sort-direction="desc"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort="last_patent_state_date"
      aria-next-label="Siguiente"
      aria-previous-label="Anterior"
      aria-page-label="Página"
      aria-current-label="Página actual">

    <b-table-column
        field= 'year'
        label= 'Año'
        searchable
        sortable>
      <template
          slot="searchable"
          slot-scope="props">
        <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small" />
      </template>
      <template v-slot="props">
        {{ props.row.year }}
      </template>
    </b-table-column>
    <b-table-column
        field= 'amount'
        label= 'Monto ($)'
        searchable
        numeric
        sortable>
      <template
          slot="searchable"
          slot-scope="props">
        <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small" />
      </template>
      <template v-slot="props">
        {{ props.row.amount }}
      </template>
    </b-table-column>
    <b-table-column
        field= 'selected_entity'
        label= 'Entidad'
        searchable
        sortable>
      <template
          slot="searchable"
          slot-scope="props">
        <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small" />
      </template>
      <template v-slot="props">
        <a v-if="props.row.selected_entity" @click="goToEntity(props.row)"><i class="fas fa-industry"></i> {{ props.row.selected_entity.name }}</a>
      </template>
    </b-table-column>
    <b-table-column
        field= 'program'
        label= 'Programa'
        searchable
        sortable>
      <template
          slot="searchable"
          slot-scope="props">
        <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small" />
      </template>
      <template v-slot="props">
        {{  props.row.program ?  props.row.program.name : '' }}
      </template>
    </b-table-column>
    <b-table-column
        v-if="project.is_creator"
        label="Acciones"
        v-slot="props">
      <b-button type="is-info"
                icon-right="edit"
                @click.native="editFinancing(props.row)">
      </b-button>
      <b-button type="is-danger"
                icon-right="trash"
                @click.native="deleteFinancingButton(props.row)">
      </b-button>


    </b-table-column>



    <template slot="empty" slot-scope="props">
      <div class="columns is-centered">
        <div class="column is-6 is-centered has-text-centered">
          <b-message type="is-danger">
            No se encontraron financiamientos adjudicados.
          </b-message>
        </div>
      </div>
    </template>

  </b-table>
  <add-financing-modal v-if="activeModal" :modal-header="modalHeader" v-bind:active-modal="activeModal"
                       :project-id="project.id"
                       :financing-id="financingModalId"
                       :action-type="actionType"
                       @close="closeModal"
                       @addFinancing="addAwardedFinancing"
                        @updateFinancing="updateFinancing"></add-financing-modal>

  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
</section>
</template>

<script>
import AddFinancingModal from '/app/javascript/components/financings/AddFinancingModal.vue';
import axios from "axios";
export default {
  name: "AwardedFinancingsTable",
  props:[ 'financings', 'project'],
  components:{ AddFinancingModal },
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      activeModal:false,
      modalHeader: "",
      financingModalId:null,
      actionType:"",
      tableDataKey:0,
      errores_validacion:{}
    }
  },
  methods:{
    deleteFinancingButton(financing){

      this.$buefy.dialog.confirm({
        title: 'Eliminar Remesa',
        message: 'Precaución, esta acción eliminará información sensible de la remesa asociada y afectará el cálculo de indicadores ¿Desea continuar?',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteFinancing(financing)
      })

    },
    deleteFinancing(financing){
      let vm = this;
      vm.isLoading = true;
      axios
        .delete("/projects/"+vm.project.id+"/delete-financing.json", {
          data:{financings_attributes: [financing],}

        })
        .then(res => {
          vm.errores_validacion = {};
          vm.$buefy.dialog.alert({
            message: 'La remesa fue eliminada satisfactoriamente.',
            type: 'is-success',
            hasIcon: true,
            icon: 'check-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })

          var index = vm.financings.findIndex(obj => obj == financing);
          vm.financings.splice(index,1);
          vm.isLoading=false;
        }).
        catch(error => {
          vm.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          vm.isLoading=false;
        })

    },
    editFinancing(financing){
      this.modalHeader = "Editar Remesa del Proyecto";
      this.financingModalId = financing.id;
      this.actionType="update";
      this.activeModal = true;
    },
    openModal() {
      this.modalHeader = "Añadir Remesa al Proyecto";
      this.actionType="create";
      this.activeModal = true;
    },
    closeModal(){
      this.modalHeader = ""
      this.actionType="";
      this.financingModalId = null;
      this.activeModal = false;
    },
    addAwardedFinancing(financings){
      /*var financing = financings.financing
      financing.selected_entity = financings.partner_entity
      financing.program = financings.program
      this.financings.push(financing);*/
      this.$emit('updateFinancing');
      this.closeModal();
    },
    updateFinancing(){
      this.$emit('updateFinancing');
      this.closeModal();
    },
    goToEntity(partner_entity){
      //console.log(partner_entity)
      if(partner_entity.partner_entity_url){
        window.location.href=partner_entity.partner_entity_url
      }
    },
  }
}
</script>

<style scoped>

</style>