<template>
  <div>
    <div class="container" style="border-radius: 15px">
      <div class="columns">
        <div class="column">
          <div class="title is-5">
            Premios, Honores y Distinciones
            <hr class="dropdown-divider" aria-role="menuitem">
          </div>
          <div class="columns">
            <div class="column">
                    <span class="is-pulled-right">
                        <button class="button is-primary" @click="newAward()">
                            <b-icon icon="plus"></b-icon>
                            <span>Agregar Premio</span>
                        </button>
                    </span>
            </div>
          </div>
        </div>
      </div>
      <b-table
          :data="awards"
          :paginated=true
          per-page=10
          :current-page="currentPage"
          :card-layout="cardLayout"
          :pagination-simple=false
          pagination-position="bottom"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field= 'name'
            label= 'Nombre del premio'
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.name }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'description'
            label= 'Características'
            sortable
            searchable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            <div v-if="props.row.description != null">{{ props.row.description }}</div>
            <div v-else></div>
          </template>
        </b-table-column>
        <b-table-column
            field= 'partner_entity_name'
            label= 'Nombre de la Institución'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            <div v-if="props.row.partner_entity_name != null">{{ props.row.partner_entity_name }}</div>
            <div v-else></div>
          </template>
        </b-table-column>
        <b-table-column
            field= 'award_type'
            label= 'Clase del Premio'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            <div v-if="props.row.award_type != null">{{ props.row.award_type }}</div>
            <div v-else></div>
          </template>
        </b-table-column>
        <b-table-column
            field= 'award_date'
            label= 'Fecha'
            searchable
            :custom-search="searchInitDate"
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.award_date._isValid ? new Date(props.row.award_date).toLocaleDateString("es-CL")  : "" }}
          </template>
        </b-table-column>
        <b-table-column
            label="Acciones"
            v-slot="props">
          <b-icon
              pack="fas"
              icon="eye"
              size="is-medium"
              @click.native="showModal(props.row)"/>
          <b-icon
              pack="fas"
              icon="edit"
              size="is-medium"
              @click.native="editModal(props.row)"/>
        </b-table-column>
        <template slot="empty" slot-scope="props">
          <div class="columns is-centered">
            <div class="column is-6 is-centered has-text-centered">
<!--              <b-message v-if="can_edit" has-icon type="is-warning">
                <h1> No se encontraron resultados, haga click <a @click="newParticipant()">aquí</a> para relacionar entidades.</h1>
              </b-message>-->
              <b-message has-icon type="is-warning">
                <h1> No se encontraron resultados.</h1>
              </b-message>
            </div>
          </div>
        </template>
      </b-table>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="isModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        width="90%"
        aria-role="dialog"
        aria-modal
        :can-cancel="false">
      <template #default="props">
        <modal_new_award
            :person-id="personId"
            :update="update"
            :old_award="old_award"
            @close="props.close"></modal_new_award>
      </template>
    </b-modal>
    <b-modal
        v-model="isModalActiveShow"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        width="90%"
        aria-role="dialog"
        aria-modal
        :can-cancel="false">
      <template #default="props">
        <modal_show_award
            :person-id="personId"
            :old_award="old_award"
            @close="props.close"></modal_show_award>
      </template>
    </b-modal>
  </div>
</template>

<script>
import modal_new_award from "./modal_new_award";
import modal_show_award from "./modal_show_award";
import axios from "axios";
import {searchDate} from "../../packs/utilities";
import moment from "moment";
export default {
  name: "awards_data_tab",
  components: {modal_new_award,modal_show_award},
  props:['personId'],
  data(){
    return {
      currentPage: 1,
      cardLayout: false,
      awards: [],
      isModalActive:false,
      isLoading: false,
      update: false,
      old_award:{},
      isModalActiveShow: false
    }
  },
  created() {
    this.getAwards()
  },
  methods: {
    newAward(){
      this.isModalActive = true
    },
    getAwards(){
      this.isLoading = true
      this.old_award = {}
      this.update = false
      axios
          .get('/my-awards/'+this.personId+'.json')
          .then(response => {
            this.awards = response.data
            this.awards = this.awards.map(award =>{
              var newAward = award
              newAward["award_date"] =  moment(newAward["award_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newAward;
            })
            this.isLoading = false
          })
          .catch(error =>{
            console.log(error)
            this.isLoading = false
          })
    },
    searchInitDate(row,input){
      return searchDate(row.award_date,input)
    },
    editModal(dato){
      this.update = true
      this.old_award = dato
      this.isModalActive = true
    },
    showModal(dato){
      this.old_award = dato
      this.isModalActiveShow = true
    }
  },
  watch: {
    isModalActive: function () {
      if (!this.isModalActive) {
        this.getAwards()
      }
    },
    isModalActiveShow: function () {
      if (!this.isModalActiveShow) {
        this.getAwards()
      }
    },
  }
}
</script>

<style scoped>

</style>