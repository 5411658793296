<template>
    <section>

        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
                <b-message type="is-danger" has-icon>
                    <div v-for="(item, index) in errores_validacion" :key="index">
                        {{ item[0] }}
                    </div>
                </b-message>
            </div>

            <form @submit.prevent="handleSubmit(saveSocialnetworks)">
                <div class="columns">

                    <div class="column">
                        <ValidationProvider rules="protocol|min:3|max:255" v-slot="{errors}">
                            <b-field label="Linkedin" :type="errors[0] ? 'is-danger' : '' " :message="errors[0]">
                                <b-input expanded v-model="person.linkedin"
                                    placeholder="Ejemplo https://www.linkedin.com/" />
                                <b-button tag="a" :href="person.linkedin ? person.linkedin : 'https://www.linkedin.com/'"
                                    target="_blank" icon-left="external-link-alt" type="is-info" label="Link" />
                            </b-field>
                        </ValidationProvider>
                    </div>

                    <div class="column">
                        <ValidationProvider rules="protocol|min:3|max:255" v-slot="{errors}">
                            <b-field label="Instagram" :type="errors[0] ? 'is-danger' : '' " :message="errors[0]">
                                <b-input expanded v-model="person.instagram"
                                    placeholder="Ejemplo https://www.instagram.com/" />
                                <b-button tag="a" :href="person.instagram ? person.instagram : 'https://www.instagram.com/'"
                                    target="_blank" icon-left="external-link-alt" type="is-info" label="Link" />
                            </b-field>
                        </ValidationProvider>

                    </div>

                </div>

                <div class="columns">
                    <div class="column">
                        <ValidationProvider rules="protocol|min:3|max:255" v-slot="{errors}">
                            <b-field label="Facebook" :type="errors[0] ? 'is-danger' : '' " :message="errors[0]">
                                <b-input expanded v-model="person.facebook"
                                    placeholder="Ejemplo https://www.facebook.com/" />
                                <b-button tag="a" :href="person.facebook ? person.facebook : 'https://www.facebook.com/'"
                                    target="_blank" icon-left="external-link-alt" type="is-info" label="Link" />
                            </b-field>
                        </ValidationProvider>
                    </div>

                    <div class="column">
                        <ValidationProvider rules="protocol|min:3|max:255" v-slot="{errors}">
                            <b-field label="X (Twitter)" :type="errors[0] ? 'is-danger' : '' " :message="errors[0]">
                                <b-input expanded v-model="person.twitter"
                                    placeholder="Ejemplo https://www.twitter.com/" />
                                <b-button tag="a" :href="person.twitter ? person.twitter : 'https://www.x.com/'" target="_blank"
                                    icon-left="external-link-alt" type="is-info" label="Link" />
                            </b-field>
                        </ValidationProvider>
                        
                    </div>
                </div>

                <div v-if="can_update" class="columns">
                    <div class="column has-text-right">
                        <b-button label="Guardar Redes Sociales" type="is-primary" icon-left="save" native-type="submit"
                            @click="checkValidation" />
                    </div>
                </div>

            </form>
        </ValidationObserver>



        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

    </section>
</template>

<script>
import axios from 'axios'
export default {

    props: [
        'personId', 'photoUrl', 'cargoPrincipal', 'canCreateDocument'
    ],

    data() {
        return {
            isLoading: false,
            person: {},
            errores_validacion: [],
            can_update: true,
        }
    },

    async created() {
        await this.getSocialNetworks()
    },

    methods: {

        async getSocialNetworks() {
            this.isLoading = true
            await axios
                .get('/people/' + this.personId + '/my-social-networks.json')
                .then(response => {
                    this.person = response.data.person
                    this.can_update = response.data.can_update
                    this.isLoading = false
                })
                .catch(error => {
                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al traer sus datos Redes Sociales, favor revisar la consola.',
                        type: 'is-danger',
                        hasIcon: true,
                        iconPack: 'fa',
                        icon: 'times-circle',
                        confirmText: 'Ok',
                    })
                    console.log(error)
                    this.isLoading = true
                })
        },

        async checkValidation() {
            const valid = await this.$refs.observer.validate()
            if (!valid) {
                this.$buefy.dialog.alert({
                    message: 'Algunos campos presentan errores.',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
            }
        },

        async saveSocialnetworks() {
            this.isLoading = true
            await axios
                .put('/people/' + this.personId + '/my-social-networks.json', {
                    person: this.person
                })
                .then(response => {
                    this.errores_validacion = []
                    this.$buefy.dialog.alert({
                        title: 'Logrado',
                        message: 'Sus datos de Redes Sociales han sido actualizados satisfactoriamente.',
                        type: 'is-success',
                        hasIcon: true,
                        iconPack: 'fa',
                        icon: 'check-circle',
                        confirmText: 'Ok',
                    })
                    this.isLoading = false
                })
                .catch(error => {
                    this.errores_validacion = error.response.data
                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al guardar tus datos de Redes Sociales, favor de revisar los errores.',
                        type: 'is-danger',
                        hasIcon: true,
                        iconPack: 'fa',
                        icon: 'times-circle',
                        confirmText: 'Ok',
                    })
                    var elmnt = document.getElementById("app");
                    elmnt.scrollIntoView();
                    this.isLoading = false;
                })
        }

    }
}
</script>