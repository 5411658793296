<template>
  <section>
    <div class="vimeactivityassistancenewmodal">
        <form action="">
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Nuevo participante</p>
                    <button
                        type="button"
                        class="delete"
                        @click="$emit('close')"/>
                </header>
                <section class="modal-card-body">
                    <b-message title="Acerca del participante" type="is-info" has-icon :closable='false'>
                        El participante tiene que estar previamente registrado en el sistema. Si la persona es externa, se puede registrar en este mismo formulario presionando el boton +.
                    </b-message>
                  <h3 class="is-size-6 has-text-weight-bold">Nombre del participante
                  </h3>
                  <div class="columns">
                    <div class="column">
                      <b-field
                               :type="{ 'is-danger': checkActivityName }"
                               :message="{ 'Ingrese el nombre del participante.': checkActivityName }">
                        <b-autocomplete
                            v-model="name"
                            placeholder="e.g. Ana Garrido Cárdenas"
                            :keep-first="keepFirst"
                            :open-on-focus="openOnFocus"
                            :data="filteredDataObj"
                            field="fullName"
                            @select="option => (selected = option)"
                            :clearable="clearable"
                        >
                        </b-autocomplete>
                      </b-field>
                    </div>
                    <div class="column is-narrow">
                      <b-tooltip label="Si la persona externa no se encuentra o no existe, la puede agregar presionando el botón +"
                                 multilined
                                 position="is-left">
                        <b-button class="is-primary is-outlined" @click="addExternalPerson">
                          <b-icon icon="plus"></b-icon>
                        </b-button>
                      </b-tooltip>
                    </div>
                  </div>

                    <b-field label="Participa como"
                        :type="{ 'is-danger': checkActivityAssistantType }"
                        :message="{ 'Seleccione el tipo de participante.': checkActivityAssistantType }">
                        <b-select placeholder="Seleccione un tipo de asistente" v-model='newPersonAssistantTypeId'>
                            <option
                                v-for="assistant_type in vime_assistant_types"
                                :value="assistant_type.id"
                                :key="assistant_type.id">
                                {{ assistant_type.name }}
                            </option>
                        </b-select>
                    </b-field>
                </section>
                <footer class="modal-card-foot">
                    <b-button
                        label="Cerrar"
                        @click="$emit('close')" />
                    <b-button
                        label="Añadir"
                        type="is-primary"
                        @click="addAssistant()"/>
                </footer>
            </div>
        </form>
    </div>
    <b-modal
        v-model="isCreateModal"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-external-people-create
            query-type="create"
            modal-header="Agregar datos personales"
            @clicked="initialize"
            @close="props.close"></modal-external-people-create>
      </template>
    </b-modal>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import axios from 'axios'
import ModalExternalPeopleCreate from "../../../person/modal_external_people_create"
export default {
    name: 'VimeActivityAssistanceNewModal',
    components: {ModalExternalPeopleCreate},
    props:{
        vime_assistant_types:{
            type: Array,
            default: () => []
        }
    },
    data(){
        return {
            assistances_info: [],
            keepFirst: false,
            openOnFocus: false,
            name: '',
            selected: null,
            clearable: false,
            isCreateModal: false,
            isLoading: false,
            canCancel: ['escape'],
            new_person:{
                person_id: -1,
                assistance_type_id: -1,
                corporate_mail: null,
                phone: -1,
                is_registered: null,
            },
            validations:{
                firstTimeActivityAssistantName: true,
                firstTimeActivityAssistantType: true
            }
        }
    },
    computed:{
        filteredDataObj() {
            return this.assistances_info.filter(option => option.fullName.toLowerCase().includes(this.name.toLowerCase()))
        },
        newPersonRut:{
            get(){
                return this.new_person.person_id
            },
            set(newValue){
                this.validations.firstTimeActivityAssistantName = false
                this.new_person.person_id = newValue
            }
        },
        newPersonAssistantTypeId:{
            get(){
                return this.new_person.assistance_type_id
            },
            set(newValue){
                this.validations.firstTimeActivityAssistantType = false
                this.new_person.assistance_type_id = newValue
            }
        },
        checkActivityName(){
            if(this.validations.firstTimeActivityAssistantName)
                return false
            else
                if(this.selected !== null)
                    return this.selected.person_id === -1
                else
                    return true
        },
        checkActivityAssistantType() {
            if(this.validations.firstTimeActivityAssistantType)
                return false
            else
                return this.new_person.assistance_type_id === -1
        }
    },
    beforeMount(){
      this.getAssistancesToModal()
    },
    methods:{
        getName(name, last_name, second_surname){
            return name + ' ' + last_name + ' ' + second_surname
        },
        getPersonNameById(person_id){
            var name = ''
            this.assistances_info.forEach(function(element){
                if(element.person_id === person_id)
                    name = element.fullName
            })
            return name
        },
        getPersonEmailById(person_id){
          var email = ''
          this.assistances_info.forEach(function(element){
            if(element.person_id === person_id)
              email = element.email
          })
          return email
        },
        getPersonPhoneById(person_id){
          var phone = 0
          this.assistances_info.forEach(function(element){
            if(element.person_id === person_id)
              phone = element.phone
          })
          return phone
        },
        getAsistentTypeNameById(assistant_type_id){
            var type_name = ''
            this.vime_assistant_types.forEach(function(element){
                if(element.id === assistant_type_id)
                    type_name = element.name
            })
            return type_name
        },
        checkEmptyFields(){
            if(this.validations.firstTimeActivityAssistantName === true){
                this.validations.firstTimeActivityAssistantName = false
            }
            if(this.validations.firstTimeActivityAssistantType === true){
                this.validations.firstTimeActivityAssistantType = false
            }
            
        },
        checkSave(){
            if(this.selected !== null){
                var A = this.selected.person_id !== -1
                var B = this.new_person.assistance_type_id !== -1
                if(A && B){
                    return true
                }
                else{
                    return false
                }
            }
            else{
                return false
            }
        },
        addAssistant(){
            if(this.checkSave()){
                this.new_person.person_name = this.getPersonNameById(this.selected.person_id)
                this.new_person.assistance_type_name = this.getAsistentTypeNameById(this.new_person.assistance_type_id)
                this.new_person.corporate_mail = this.getPersonEmailById(this.selected.person_id)
                this.new_person.phone = this.getPersonPhoneById(this.selected.person_id)
                this.new_person.is_registered = this.selected.is_registered
                this.new_person.id_person = this.selected.person_id
                this.newPersonRut = this.selected.person_id
                this.$emit('add-assistant', this.new_person);
                this.$emit('close', this.new_person);
            }
            else{
                this.checkEmptyFields()
            }
        },
      addExternalPerson(){
        this.isLoading= true
        this.isCreateModal = true
        this.isLoading = false
      },
      initialize(value){
        this.isLoading = true
        axios.get('/activity_assistances/getAssistancesToModal')
            .then(response =>{
              this.assistances_info = response.data.assistance
              var persons = this.assistances_info.filter(option => option.fullName.toLowerCase().includes(value.toLowerCase()))
              if (persons.length == 1) {
                this.selected = persons[0]
                this.name = persons[0].fullName
              }
              this.isLoading = false
            })
            .catch(e => {
              this.isLoading = false
              console.log(e)
            })
      },
      getAssistancesToModal(){
          this.isLoading = true
        axios.get('/activity_assistances/getAssistancesToModal')
            .then(response =>{
              this.assistances_info = response.data.assistance
              this.isLoading = false
            })
            .catch(e => {
              this.isLoading = false
              console.log(e)
            })
      },
    },
}
</script>