<template>
    <div class="configurationsocialnetworkedit">
        <div class="container">
            <br>
            <div class="columns is-centered">
                <h1 class="is-size-2" style="text-align:center;">Edición de las redes sociales</h1>
            </div>
            <br>
            <div v-if="isSocialNetworksEmpty">
                <div class="columns is-centered">
                    <h1>
                        No hay registros sobre las redes sociales.
                    </h1>
                </div>
            </div>
            <div v-else>
                <span class="is-pulled-right">
                    <button class="button is-primary" @click="newSocialNetwork()">
                        <b-icon icon="plus"></b-icon>
                        <span>Añadir una nueva red social</span>
                    </button>
                </span>
                <br>
                <br>
                <b-table
                    :data="social_networks"
                    :paginated=true
                    per-page=10
                    striped
                    hoverable
                    :current-page="currentPage"
                    :pagination-simple=false
                    pagination-position="bottom"
                    default-sort-direction="asc"
                    sort-icon="arrow-up"
                    sort-icon-size="is-small"
                    default-sort="publication.title"
                    aria-next-label="Siguiente"
                    aria-previous-label="Anterior"
                    aria-page-label="Página"
                    aria-current-label="Página actual">
                    <b-table-column
                        field= 'name'
                        label= 'Nombre'
                        v-slot="props"
                    >
                    {{ props.row.name }}
                    </b-table-column>
                    <b-table-column
                        field= 'description'
                        label= 'Descripción'
                        v-slot="props"
                    >
                    {{ props.row.description }}
                    </b-table-column>
                    <b-table-column
                        field= 'link'
                        label= 'Link'
                        v-slot="props"
                    >
                    {{ props.row.link }}
                    </b-table-column>
                    <b-table-column
                        field= 'is_active'
                        label= '¿Activa?'
                        v-slot="props"
                    >
                    {{ isSocialNetworkActive(props.row) }}
                    </b-table-column>
                    <b-table-column v-slot="props">
                        <button class="button is-warning" @click="editSocialNetwork(props.row)">
                            <b-icon icon="edit"></b-icon>
                            <span>Editar</span>
                        </button>
                    </b-table-column>
                    <b-table-column v-slot="props">
                        <button v-if='props.row.is_active' class="button is-danger" @click="confirmCustomDesactivate(props.row)">
                            <b-icon icon="times"></b-icon>
                            <span>Desactivar</span>
                        </button>
                        <button v-else class="button is-primary" @click="confirmCustomDesactivate(props.row)">
                            <b-icon icon="check"></b-icon>
                            <span>Activar</span>
                        </button>
                    </b-table-column>
                </b-table>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import ConfigurationSocialNetworkNewModal from '../new/configuration_social_network_new_modal.vue'
export default {
    name: 'ConfigurationSocialNetworkEdit',
    data(){
        return {
            currentPage: 1,
            social_networks: []
        }
    },
    computed:{
        isSocialNetworksEmpty(){
            return Object.entries(this.social_networks).length === 0
        }
    },
    beforeMount(){
        this.getSocialNetworks()
    },
    methods:{
        getSocialNetworks(){
            axios.get('/social_networks.json')
            .then( response =>{
                this.social_networks = response.data
            })
            .catch(e => { 
                console.log(e)
            })
        },
        isSocialNetworkActive(social_network_data){
            if(social_network_data.is_active)
                return 'Sí'
            else
                return 'No'
        },
        newSocialNetwork(){
            this.$buefy.modal.open({
                parent: this,
                component: ConfigurationSocialNetworkNewModal,
                hasModalCard: true,
                events:{
                    'add-new-social-network': this.addNewSocialNetwork
                },
                customClass: 'is-primary',
                trapFocus: true
            })
        },
        addNewSocialNetwork(social_network_data){
            axios.post('/social_networks.json', social_network_data)
            .then(response =>{
                this.social_networks.push(response.data)
            })
            .catch(e =>{
                console.log(e)
            })
        },
        editSocialNetwork(social_network_data){
            this.$buefy.modal.open({
                parent: this,
                component: ConfigurationSocialNetworkNewModal,
                hasModalCard: true,
                props:{
                    'action_case': 1,
                    'social_network_to_edit': social_network_data
                },
                events:{
                    'edit-social-network': this.saveEditSocialNetwork
                },
                customClass: 'is-primary',
                trapFocus: true
            })
        },
        saveEditSocialNetwork(edited_social_network){
            axios.put('/social_networks/'+edited_social_network.id+'.json', edited_social_network)
            .then( response =>{
                this.social_networks.forEach(element => {
                    if(element.id ===  edited_social_network.id){
                        element = edited_social_network
                    }
                });
            })
            .catch(e => {
                console.log(e)
            })
        },
        confirmCustomDesactivate(social_network_data){
            if(social_network_data.is_active){
                social_network_data.is_active = false
            }
            else{
                social_network_data.is_active = true
            }
            axios.put("/social_networks/"+social_network_data.id+".json", social_network_data)
            .then(response=>{
                this.social_networks.forEach(function(element){
                    if(element.id == response.data.id){
                        element.is_active = response.data.is_active
                    }
                })
            })
            .catch(e=>{
                console.log(e)
            })
        }
    }
}
</script>