<template>
  <section>
    <h1 class="subtitle is-2 has-text-primary">
      ¿Qué información hay en Funciones?
    </h1>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Asigna Función
    </h2>
    <div class="mb-3 ">
      <p>En esta sección se listan los documentos relacionados a la <b>asignación de funciones</b> sobre
        nombramientos del estamento académico.
        Dentro de estas funciones, se encuentran: Director Departamento, Jefe de Carrera, entre otras.</p>
    </div>
    <img border="2" class="m-2" src="/tutorial_images/funciones.png" style="width:80%">
    <div class="mb-3 ">
      <p>El sistema permite ver un asigna función</p>
    </div>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Paso 1
    </h2>
    <p class="my-3 ">Estar situado en la pestaña de <b>Funciones Asignadas</b> </p>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Paso 2
    </h2>
    <p class="my-3 ">Presionar el botón con forma de <b>ojo</b> situado en la columna de acciones:
    </p>
    <img border="2" class="m-2" src="/tutorial_images/ver_funcion.png" style="width:80%">
    <p class="my-3 ">La acción anterior despliega un asigna función y permite su visualización:
    </p>
    <img border="2" class="m-2" src="/tutorial_images/funcion.png" style="width:80%">

    <h2 class="subtitle is-3 mt-5 mb-2">
      Libera Función
    </h2>
    <div class="mb-3 ">
      <p>En esta sección se listan los documentos asociados a un <b>libera función</b>, es decir,
        al fin de la asignación de funciones sobre un nombramiento de estamento académico.</p>
    </div>
    <img border="2" class="m-2" src="/tutorial_images/alejamientos_funciones.png" style="width:80%">
    <div class="mb-3 ">
      <p>El sistema permite ver un libera función</p>
    </div>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Paso 1
    </h2>
    <p class="my-3 ">Estar situado en la pestaña de <b>Libera Funciones</b> </p>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Paso 2
    </h2>
    <p class="my-3 ">Presionar el botón con forma de <b>ojo</b> situado en la columna de acciones:
    </p>
    <img border="2" class="m-2" src="/tutorial_images/ver_alejamiento_funcion.png" style="width:80%">
    <p class="my-3 ">La acción anterior despliega un libera función y permite su visualización:
    </p>
    <img border="2" class="m-2" src="/tutorial_images/alejamiento_funcion.png" style="width:80%">
  </section>
</template>
<script>
export default {
  name: "what_is_function_mhv",
  data(){
    return {
    }
  },
  methods:{

  },
}
</script>