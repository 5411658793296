<template>
    <section>
        <publications-table v-bind:publicaciones="this.publicaciones" :hide-update="true" :cargoPrincipal="cargoPrincipal" :personId="personId"
            @updatePublications="updatePublications" />
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </section>
</template>

<script>
import axios from 'axios'
import PublicationsTable from "../publications/publications_table.vue"
import moment from 'moment'
export default {
    props: [
        'personId', 'cargoPrincipal', 'minYear', 'maxYear', 'changeFilter'
    ],
    components: {
        PublicationsTable
    },
    data(){
        return {
            publicaciones: [],
            isLoading: false,
            year: new Date().getFullYear(),
        }
    },
    created() {
        this.getPublications()
    },
    methods: {
        getPublications() {
            this.isLoading = true
            axios
                .get('/people/my-magazine-publications.json', {
                    params: {
                        minYear: this.minYear,
                        maxYear: this.maxYear,
                        id: this.personId,
                    }
                })
                .then(response => {
                    this.publicaciones = response.data
                    this.publicaciones = this.publicaciones.map(publication => {
                        publication.publication_date = moment(publication.publication_date, ['YYYY-MM-DD', 'DD-MM-YYYY'])
                        return publication
                    })
                    this.isLoading = false
                })
                .catch(error => {
                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al traer sus Publicaciones en Revista, favor de revisar los errores.',
                        type: 'is-danger',
                        hasIcon: true,
                        iconPack: 'fa',
                        icon: 'times-circle',
                        confirmText: 'Ok',

                    })
                    console.log(error)
                    this.isLoading = false
                })
        },
        updatePublications(year) {
            this.year = year
            this.getPublications()
        }
    },
    watch: {
        changeFilter: function() {
            this.getPublications()
        }
    }

}
</script>