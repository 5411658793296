<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(verifyWarning)">
        <!-- Box de Formulario Appointment -->
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ editMassive!=true ? modalHeader:'Editar datos generales de nombramiento masivo'}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" ref="modalHourAppointmentForm">
            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion">
                      {{ item[0] }}
                    </div>
                  </b-message>
                </div>
              </div>
            </div>
            <div v-if="queryType != 'create-request'" class="columns">
              <div class="column has-text-right">
                <b-field label="Marcar para prórroga semi-automática">
                  <b-checkbox v-model="person_unit.renewable" :disabled="!person_unit.can_renew"></b-checkbox>
                </b-field>
              </div>
            </div>
            <div class="columns" v-if="queryType==='update-request' && state_id != 5">
              <div class="column">
                <b-message
                    type="is-warning"
                    has-icon>
                  En el campo Comentario, debe indicar con detalle los cambios realizados a la solicitud de nombramiento.
                </b-message>
              </div>
            </div>
            <div class="columns" v-if="queryType==='update-request' && state_id != 5">
              <div class="column">
                <b-field label="Comentario*">
                  <ValidationProvider rules="required|min:5|max:200" v-slot="{ errors }">
                    <b-input v-model="comment" type="textarea"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción*">
                  <ValidationProvider rules="required|min:5|max:200" v-slot="{ errors }">
                    <b-input v-model="person_unit.description" type="textarea" :disabled = "request_type_id == 2 && editMassive != true"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div v-if="queryType != 'create-request'" class="columns">
              <div class="column">
                <b-field label="Tipo de documento">
                  <multiselect v-model="person_unit.resolution_type_id"
                               :options="resolution_types.map(type => type.id)"
                               :custom-label="opt => resolution_types.find(x => x.id === opt).name"
                               placeholder="Seleccione tipo de documento"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                               :allow-empty="true"
                               :disabled = "request_type_id == 2 && editMassive != true"
                    @input="generateName">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Número de documento">
                  <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                    <b-input v-model="person_unit.resolution" type="text" :disabled = "request_type_id == 2 && editMassive != true"
                    @input="generateName"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de documento">
                  <b-datepicker
                      v-model="person_unit.resolution_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                      :disabled = "request_type_id == 2 && editMassive != true"
                      @input="generateName"
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de Nombramiento*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.appointment_type_id"
                                 :options="appointment_types.map(type => type.id)"
                                 :custom-label="opt => appointment_types.find(x => x.id === opt).name"
                                 placeholder="Seleccione tipo de nombramiento"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false"
                                 :disabled = "request_type_id == 2 && editMassive != true">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Cargo*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.work_plant_id"
                                 :options="work_plants.map(type => type.id)"
                                 :custom-label="opt => work_plants.find(x => x.id === opt).name"
                                 placeholder="Seleccione un cargo"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false"
                                 :disabled = "request_type_id == 2 && editMassive != true">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column" v-if="editMassive != true">
                <b-field label="Categoría*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.category_id"
                                 :options="categories.map(type => type.id)"
                                 :custom-label="opt => categories.find(x => x.id === opt).name"
                                 placeholder="Seleccione una categoría"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Unidad de contratación*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.contracting_unit_id"
                                 :options="contract_units.map(type => type.id)"
                                 :custom-label="opt => contract_units.find(x => x.id === opt).name"
                                 placeholder="Seleccione una unidad"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false"
                                 :disabled = "request_type_id == 2 && editMassive != true"
                    @input="generateName">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">

                <b-field label="Unidad de desempeño*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.unit_id"
                                 :options="units.map(type => type.id)"
                                 :custom-label="opt => units.find(x => x.id === opt).name"
                                 placeholder="Seleccione una unidad"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false"
                                 :disabled = "request_type_id == 2 && editMassive != true">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Calidad de contrato">
                  <multiselect v-model="person_unit.quality"
                               :options="qualities.map(type => type.name)"
                               placeholder="Seleccione calidad de contrato"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                               :allow-empty="true"
                               :disabled = "request_type_id == 2 && editMassive != true">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Desde*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-datepicker
                        v-model="person_unit.start_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                        :disabled = "request_type_id == 2 && editMassive != true"
                    >
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div v-if="queryType != 'create-request'" class="column">
                <b-field label="Fecha Hasta">
                  <b-datepicker
                      v-model="person_unit.end_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                      :disabled = "request_type_id == 2 && editMassive != true"
                  >
                  </b-datepicker>
                </b-field>
              </div>
              <div v-else class="column">
                <b-field label="Fecha Hasta*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-datepicker
                        v-model="person_unit.end_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                        :disabled = "request_type_id == 2 && editMassive != true"
                    >
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
<!--            <div v-if="queryType != 'create-request'" class="columns">
              <div class="column">
                <b-field label="Url Documentación">
                  <ValidationProvider v-slot="{ errors }">
                    <b-input v-model="person_unit.documentation_link" type="text" :disabled = "request_type_id == 2 && editMassive != true"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>-->
            <div class="container">
              <section>
                <div v-if="editMassive != true" class="columns">
                  <div class="column">
                    <b-field>
                      <template #label>
                        Asignaturas

                      </template>
                    </b-field>
                  </div>
                  <div v-if="editMassive != true" class="column" align="right" style="text-align:right;">
                    <b-button class="mt-2" @click.prevent="addHrSubject" type="is-primary" :disabled="!can_update_hr_subjects">Añadir Asignatura</b-button>
                  </div>
                </div>
                <b-table v-if="editMassive != true"
                    :data="selected_hr_subjects"
                    :paginated=true
                    :bordered="true"
                    per-page=3
                    striped
                    hoverable
                    :current-page="currentPage"
                    :pagination-simple=false
                    pagination-position="bottom"
                    default-sort-direction="asc"
                    sort-icon="arrow-up"
                    sort-icon-size="is-small"
                    default-sort="publication.title"
                    aria-next-label="Siguiente"
                    aria-previous-label="Anterior"
                    aria-page-label="Página"
                    aria-current-label="Página actual">
                  <b-table-column
                      field= 'name'
                      width="30em"
                      sortable
                  >
                    <template v-slot:header="{ column }">
                      <b-tooltip
                          label="Presione Enter luego de escribir el nombre de la asignatura para que sea agregada"
                          position="is-right"
                          size="is-medium"
                          multilined>
                        {{"Nombre de la asignatura*" }}
                        <b-icon
                            pack="fas"
                            icon="info-circle"
                            size="is-small"
                            type="is-info">
                        </b-icon>
                      </b-tooltip>
                    </template>
                    <template v-slot="props">
                      <b-field>
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <b-taginput
                              v-model="props.row.subject"
                              :data="filtered_hr_subjects"
                              type="is-primary is-light"
                              maxtags="1"
                              autocomplete
                              maxlength="155"
                              :allow-new="true"
                              field="name"
                              icon="tag"
                              placeholder="Agregar o escoger asignaturas"
                              @input = "getPsCode(props.row)"
                              @typing="getFilteredHRSubjects">
                          </b-taginput>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </template>
                  </b-table-column>
                  <b-table-column
                      field= 'hours'
                      label= 'Horas de Asignatura*'
                      sortable
                      v-slot="props"
                  >
                    <b-field>
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <b-input v-model="props.row.hours" type="text" min="0" @input="updateHours" :disabled="!can_update_hr_subjects"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>

                    </b-field>
                  </b-table-column>
                  <b-table-column
                      field= 'substracted_hours'
                      label= 'Horas Actuales de Asignatura'
                      sortable
                      v-slot="props"
                      v-if="queryType==='update'"
                  >
                    <b-field>
                        <b-input v-model="props.row.substracted_hours" type="number" min="0" :disabled="true"></b-input>
                    </b-field>
                  </b-table-column>
                  <b-table-column
                      field= 'ps_code'
                      label= 'Código PeopleSoft'
                      sortable
                      v-slot="props"
                  >
                    <b-field>
                      <ValidationProvider v-slot="{errors}">
                        <b-input minlength="0" maxlength="155" v-model="props.row.ps_code" type="text" :disabled="props.row.ps_code_disabled || !can_update_hr_subjects"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </b-table-column>
                  <b-table-column
                      field= 'siac_code'
                      label= 'Código SIAC'
                      sortable
                      v-slot="props"
                  >
                    <b-field>
                      <ValidationProvider v-slot="{errors}">
                        <b-input minlength="0" maxlength="155" v-model="props.row.siac_code" type="text" :disabled="props.row.siac_code_disabled || !can_update_hr_subjects"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </b-table-column>
                  <b-table-column
                      label="Acciones"
                      v-slot="props">
                    <b-icon
                        pack="fas"
                        style="color: #e50000;"
                        icon="trash"
                        size="is-medium"
                        @click.native="deleteHrSubject(props.row)"
                        v-if="can_update_hr_subjects"/>
                  </b-table-column>

                </b-table>
              </section>
            </div>
            <div class="columns">
              <div v-if="editMassive != true" class="column">
                <b-field>
                  <template #label>
                    Jornada (horas)
                    <b-tooltip label="Calculado automaticamente en base a la suma de las horas por asignatura"
                               position="is-right">
                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          type="is-info">
                      </b-icon>
                    </b-tooltip>
                  </template>
                  <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                    <b-input v-model="person_unit.taken_hours" type="number" :disabled="true"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div v-if="(queryType != 'create-request' && queryType!='update-request') || state_id == 5" class="column">
                <b-field label="Año">
                  <ValidationProvider :rules="{numeric: true, integer: true, positive: true, min_value: 1900}" v-slot="{ errors }">
                    <b-input  v-model="person_unit.year"  type="text">
                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div v-else class="column">
                <b-field label="Año*">
                  <ValidationProvider :rules="{numeric: true, integer: true, positive: true, min_value: 1900, required: true}" v-slot="{ errors }">
                    <b-input  v-model="person_unit.year"  type="text" :disabled = "request_type_id == 2 && editMassive != true">
                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div v-if="(queryType != 'create-request' && queryType!='update-request') || state_id == 5" class="column">
              <b-field label="Semestre">
                  <ValidationProvider rules="numeric|integer|positive|min_value:1|max_value:2" v-slot="{ errors }">
                    <b-input  v-model="person_unit.semester" type="text">
                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div v-else class="column">
                <b-field label="Semestre*">
                  <ValidationProvider rules="required|numeric|integer|positive|min_value:1|max_value:2" v-slot="{ errors }">
                    <b-input  v-model="person_unit.semester" type="text" :disabled = "request_type_id == 2 && editMassive != true">
                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div v-if="queryType == 'update' && editMassive != true" class="columns">
              <div class="column is-4">
                <b-field label="Nombre del documento">
                  <b-input v-model="document_name_update" type="text" :disabled="true"></b-input>
                </b-field>
              </div>
              <div class="column is-4">
                <b-field label="¿Desea cambiar o agregar un archivo?">
                  <b-radio-button v-model="changeFile"
                                  :native-value="true"
                                  type="is-success is-light is-outlined">
                    <b-icon icon="check-circle"></b-icon>
                    <span>Si</span>
                  </b-radio-button>
                  <b-radio-button v-model="changeFile"
                                  :native-value="false"
                                  type="is-danger is-light is-outlined">
                    <b-icon icon="times-circle"></b-icon>
                    <span>No</span>
                  </b-radio-button>
                </b-field>
              </div>
            </div>

<!--            <div class="columns" v-if="queryType == 'update' && editMassive != true">
              <div class="column">
                <b-input v-model="document_name_update" type="text" :disabled></b-input>
              </div>
              <div class="column">
                <b-field label="¿Desea cambiar o agregar un archivo?">
                  <b-radio-button v-model="changeFile"
                                  :native-value="true"
                                  type="is-success is-light is-outlined">
                    <b-icon icon="check-circle"></b-icon>
                    <span>Si</span>
                  </b-radio-button>
                  <b-radio-button v-model="changeFile"
                                  :native-value="false"
                                  type="is-danger is-light is-outlined">
                    <b-icon icon="times-circle"></b-icon>
                    <span>No</span>
                  </b-radio-button>
                </b-field>
              </div>
            </div>-->

            <div v-if="queryType == 'create' || changeFile" class="columns has-text-centered">
              <div class="column">
                <b-field extended label="Archivo PDF (Tamaño max. 25 MB)">
                  <b-upload v-model="file"
                            drag-drop
                            type="is-primary"
                            accept=".pdf"
                            @input="generateName">
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon
                              icon="file-pdf"
                              size="is-large">
                          </b-icon>
                        </p>
                        <p>Arrastra tu PDF o haz click para subir.</p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>
              </div>
            </div>
            <div v-if="queryType == 'create' || changeFile" class="columns tags is-centered">
              <span
                  v-if="file !== null"
                  v-model="file"
                  class="tag is-primary mt-1">
                {{ file.name }}
                <button
                    class="delete is-small"
                    type="button"
                    @click="onDeleteFile"
                ></button>
              </span>
            </div>

            <div v-if="file !== null" class="columns">
              <div class="column">
                <b-field label="Nombre del documento a subir">
                  <b-input disabled v-model="document_name"></b-input>
                </b-field>
              </div>
            </div>

          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit">Guardar </button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="isDistanceModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <distance-form :person-id="person_unit.person_id"
                       :id="person_unit.id"
                       :person_unit_id="person_unit.id"
                       query-type="create"
                       modal-header="Crear Alejamiento"
                       @close="props.close">
        </distance-form>
      </template>
    </b-modal>

    <b-modal
        v-model="isAlertModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal-confirm-duplication :person-id="person_unit.person_id"
                       :id="person_unit.id"
                       :person_unit="person_unit"
                       modal-header="Confirmar Nombramiento"
                       @close="props.close"
                                   @clicked_save="onClickSave"
                                   @clicked_duplicate="onClickDuplicate">
        </modal-confirm-duplication>
      </template>
    </b-modal>

  </section>
</template>
<script>
import axios from "axios";
import DistanceForm from "../life_record/modal_distance_form.vue"
import ModalConfirmDuplication from "../life_record/modal_confirm_duplication"
import {request_from_appointment} from "../../packs/utilities"
export default {
  name: "modal_hour_appointment_form",
  props:[
    'queryType', 'personId', 'id', 'modalHeader', 'state_id', 'request_type_id', 'editMassive', 'actual_hours', 'person_name'
  ],
  components:{
    DistanceForm,
    ModalConfirmDuplication
  },
  data(){
    return {
      person_unit:{
        is_hours:true,
        pu_relation_type_id: 1,
      },
      pu_relation_type_id: 1,
      hr_subject_attributes: [],
      years:[],
      appointment_types:[],
      work_plants:[],
      categories: [],
      qualities: [{name: "CONTRATA"},{name: "PLANTA"},{name: "AD-HONOREM"}, {name: "INTERINO"}, {name: "SUPLENTE"}],
      units: [],
      contract_units:[],
      establishments:[],
      working_days:[],
      resolution_types:[],
      hr_subjects: [],
      filtered_hr_subjects: [],
      errores_validacion:{},
      documentation_link:'',
      selected_hr_subjects:[],
      isLoading: false,
      num_hr_subjects: 0,
      currentPage:1,
      cardLayout:false,
      isDistanceModalActive: false,
      allow_duplication: false,
      isAlertModalActive: false,
      year_date: new Date().getFullYear(),
      can_update_hr_subjects: false,
      request: {},
      request_created: false,
      request_id: null,
      comment: null,
      other_person_units: {},
      file: null,
      document_name: null,
      changeFile: false,
      document_name_update: null,
      document_id: null

    }
  },
  created() {
    this.isLoading = true;
    if (this.queryType === "create"){
      axios
          .get("/admin/person_units/new.json")
          .then(res => {
            this.person_unit.person_id = this.personId
            this.person_unit.date_validation = true
            this.work_plants = res.data["work_plants"]
            this.appointment_types = res.data["appointment_types"]
            this.categories = res.data["categories"]
            this.units = res.data["units"]
            this.contract_units = res.data["contract_units"]
            this.resolution_types = res.data["resolution_types"]
            this.working_days = res.data["working_days"]
            this.documentation_link = res.data["documentation_link"]
            this.establishments = res.data["establishments"]
            this.hr_subjects = res.data["hr_subjects"]
            this.person_unit.can_renew = true
            this.can_update_hr_subjects = res.data["can_update_hr_subjects"]
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
      })
    }
    else if (this.queryType === "create-request"){
      axios
          .get("/admin/person_units/new-request-appointment.json")
          .then(res => {
            this.person_unit.person_id = this.personId
            this.person_unit_state_id = 2
            this.person_unit.pu_relation_type_id = 6
            this.person_unit.date_validation = true
            this.work_plants = res.data["work_plants"]
            this.appointment_types = res.data["appointment_types"]
            this.categories = res.data["categories"]
            this.units = res.data["units"]
            this.contract_units = res.data["contract_units"]
            this.resolution_types = res.data["resolution_types"]
            this.working_days = res.data["working_days"]
            this.documentation_link = res.data["documentation_link"]
            this.establishments = res.data["establishments"]
            this.hr_subjects = res.data["hr_subjects"]
            this.person_unit.can_renew = true
            this.can_update_hr_subjects = res.data["can_update_hr_subjects"]
            this.isLoading=false;
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }
    /*else if (this.queryType === "update"){
      this.isLoading=true;
      axios
          .get("/admin/person_units/"+this.id+"/edit.json")
          .then(res => {
            this.person_unit = res.data
            this.can_update_hr_subjects = res.data["can_update_hr_subjects"]
            this.person_unit.date_validation = true
            if(res.data["start_date"]!==null){
              this.person_unit.start_date = new Date(this.formatDate(res.data["start_date"]))
            }
            if(res.data["end_date"]!==null){
              this.person_unit.end_date = new Date(this.formatDate(res.data["end_date"]))
            }
            if(res.data["decree_date"]!==null){
              this.person_unit.decree_date = new Date(this.formatDate(res.data["decree_date"]))
            }
            if(res.data["resolution_date"]!==null){
              this.person_unit.resolution_date = new Date(this.formatDate(res.data["resolution_date"]))
            }
            if (this.person_unit && this.person_unit.hr_subjects){
              for(var i=0;i<this.person_unit.hr_subjects.length;i++){

                this.selected_hr_subjects.push({id:this.person_unit.hr_subjects[i].id,
                  name: this.person_unit.hr_subjects[i].name, ps_code: this.person_unit.hr_subjects[i].ps_code, siac_code: this.person_unit.hr_subjects[i].siac_code, siac_code_disabled: false,
                  hours: this.person_unit.has_hr_subjects[i].hours, substracted_hours: this.person_unit.has_hr_subjects[i].substracted_hours, index: i, ps_code_disabled: false,
                  subject: [this.person_unit.hr_subjects[i].name]})
              }
            }
            this.num_hr_subjects = this.person_unit.hr_subjects.length
            this.units = res.data["units"]
            this.contract_units = res.data["contract_units"]
            this.categories = res.data["categories"]
            this.resolution_types = res.data["resolution_types"]
            this.work_plants = res.data["work_plants"]
            this.appointment_types = res.data["appointment_types"]
            this.documentation_link = res.data["documentation_link"]
            this.working_days = res.data["working_days"]
            this.establishments = res.data["establishments"]
            this.hr_subjects = res.data["all_hr_subjects"]
            this.isLoading=false;
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }*/
    else if (this.queryType === "update" || this.queryType === "copy"){
      this.isLoading=true;
      axios
          .get("/admin/person_units/"+this.id+"/edit.json")
          .then(res => {
            this.person_unit = res.data
            this.can_update_hr_subjects = res.data["can_update_hr_subjects"]
            this.person_unit.date_validation = true
            if(res.data["start_date"]!==null){
              this.person_unit.start_date = new Date(this.formatDate(res.data["start_date"]))
            }
            if(res.data["end_date"]!==null){
              this.person_unit.end_date = new Date(this.formatDate(res.data["end_date"]))
            }
            if(res.data["decree_date"]!==null){
              this.person_unit.decree_date = new Date(this.formatDate(res.data["decree_date"]))
            }
            if(res.data["resolution_date"]!==null){
              this.person_unit.resolution_date = new Date(this.formatDate(res.data["resolution_date"]))
            }
            if (this.person_unit && this.person_unit.hr_subjects){
              for(var i=0;i<this.person_unit.hr_subjects.length;i++){

                this.selected_hr_subjects.push({id:this.person_unit.hr_subjects[i].id,
                  name: this.person_unit.hr_subjects[i].name, ps_code: this.person_unit.hr_subjects[i].ps_code, siac_code: this.person_unit.hr_subjects[i].siac_code, siac_code_disabled: false,
                  hours: this.person_unit.has_hr_subjects[i].hours, index: i, ps_code_disabled: false,
                  subject: [this.person_unit.hr_subjects[i].name]})
              }
            }
            this.num_hr_subjects = this.person_unit.hr_subjects.length
            this.units = res.data["units"]
            this.contract_units = res.data["contract_units"]
            this.categories = res.data["categories"]
            this.resolution_types = res.data["resolution_types"]
            this.work_plants = res.data["work_plants"]
            this.appointment_types = res.data["appointment_types"]
            this.documentation_link = res.data["documentation_link"]
            this.working_days = res.data["working_days"]
            this.establishments = res.data["establishments"]
            this.hr_subjects = res.data["all_hr_subjects"]
            this.document_id =  res.data["document_id"]
            this.document_name_update = res.data["document_name"]
            this.document_name = res.data["document_name"]
            if(this.queryType === "copy"){

              this.person_unit.resolution_date = null
              this.person_unit.resolution = null
              this.person_unit.description = null
              this.person_unit.documentation_link = null
            }
            this.isLoading=false;
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }
    else if (this.queryType === "update-request"){
      this.isLoading=true;
      axios
          .get("/admin/person_units/"+this.id+"/edit-request-appointment.json")
          .then(res => {
            this.person_unit = res.data
            this.can_update_hr_subjects = res.data["can_update_hr_subjects"]
            this.person_unit.date_validation = true
            if(res.data["start_date"]!==null){
              this.person_unit.start_date = new Date(this.formatDate(res.data["start_date"]))
            }
            if(res.data["end_date"]!==null){
              this.person_unit.end_date = new Date(this.formatDate(res.data["end_date"]))
            }
            if(res.data["decree_date"]!==null){
              this.person_unit.decree_date = new Date(this.formatDate(res.data["decree_date"]))
            }
            if(res.data["resolution_date"]!==null){
              this.person_unit.resolution_date = new Date(this.formatDate(res.data["resolution_date"]))
            }
            if (this.person_unit && this.person_unit.hr_subjects){
              for(var i=0;i<this.person_unit.hr_subjects.length;i++){

                this.selected_hr_subjects.push({id:this.person_unit.hr_subjects[i].id,
                  name: this.person_unit.hr_subjects[i].name, ps_code: this.person_unit.hr_subjects[i].ps_code, siac_code: this.person_unit.hr_subjects[i].siac_code, siac_code_disabled: false,
                  hours: this.person_unit.has_hr_subjects[i].hours, index: i, ps_code_disabled: false,
                  subject: [this.person_unit.hr_subjects[i].name]})
              }
            }
            this.num_hr_subjects = this.person_unit.hr_subjects.length
            this.units = res.data["units"]
            this.contract_units = res.data["contract_units"]
            this.categories = res.data["categories"]
            this.resolution_types = res.data["resolution_types"]
            this.work_plants = res.data["work_plants"]
            this.appointment_types = res.data["appointment_types"]
            this.documentation_link = res.data["documentation_link"]
            this.working_days = res.data["working_days"]
            this.establishments = res.data["establishments"]
            this.hr_subjects = res.data["all_hr_subjects"]
            this.isLoading=false;
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }

  },
  methods: {
    request_from_appointment,
    getPsCode(row){
      var newIndex = this.selected_hr_subjects.findIndex(subject => subject.index ===row.index);
    if(row.subject[0] === undefined){
      this.selected_hr_subjects[newIndex].name = null
      this.selected_hr_subjects[newIndex].id = null
      this.selected_hr_subjects[newIndex].ps_code = null
      this.selected_hr_subjects[newIndex].hours = null
      this.selected_hr_subjects[newIndex].ps_code_disabled = false
      this.selected_hr_subjects[newIndex].siac_code = null
      this.selected_hr_subjects[newIndex].siac_code_disabled = false
    }
    else if(row.subject[0].id && row.subject[0].ps_code){
        this.selected_hr_subjects[newIndex].name = row.subject[0].name
        this.selected_hr_subjects[newIndex].id = row.subject[0].id
        this.selected_hr_subjects[newIndex].ps_code = row.subject[0].ps_code
        this.selected_hr_subjects[newIndex].ps_code_disabled = false
        this.selected_hr_subjects[newIndex].siac_code = row.subject[0].siac_code
        this.selected_hr_subjects[newIndex].siac_code_disabled = false
      }
      else if(row.subject[0].id ===undefined && row.subject[0].ps_code === undefined){
        this.selected_hr_subjects[newIndex].name = row.subject[0]
      }
      else if(row.subject[0].id && (row.subject[0].ps_code===undefined || row.subject[0].ps_code===null)){
        this.selected_hr_subjects[newIndex].name = row.subject[0].name
        this.selected_hr_subjects[newIndex].id = row.subject[0].id
        this.selected_hr_subjects[newIndex].ps_code = row.subject[0].ps_code
        this.selected_hr_subjects[newIndex].ps_code_disabled = false
        this.selected_hr_subjects[newIndex].siac_code = row.subject[0].siac_code
        this.selected_hr_subjects[newIndex].siac_code_disabled = false
      }
    },

    async updateHours(){
      var hours = 0
      for(var i = 0;i<this.selected_hr_subjects.length;i++){
        hours = hours + parseInt(this.selected_hr_subjects[i].hours)
      }
      this.person_unit.taken_hours = parseInt(hours)
      await this.verifyMaxHours()

      if ((this.queryType == 'create-request' || this.queryType == 'create') && this.other_person_units != {}){
        if(this.person_unit.taken_hours + this.other_person_units.horas_totales > 16){
          var horas_restantes = 16 - this.other_person_units.horas_totales
          if (horas_restantes < 0 ){
            horas_restantes = 0
          }


          this.$buefy.dialog.alert({
            title: 'Precaución',
            message: 'Se esta superando el maximo de horas para este periodo. Solo puede considerar '+horas_restantes+' horas para este nombramiento.',
            type: 'is-warning',
            hasIcon: true,
            icon: 'exclamation-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        }

      }

      if(this.actual_hours != null && this.actual_hours != undefined && this.actual_hours > 0){
        if(this.person_unit.taken_hours > this.actual_hours){
          this.$buefy.dialog.alert({
            title: 'Precaución',
            message: 'Las horas actuales no deben superar las horas del periodo anterior. Para esos casos realizar solicitud de aumento de horas.',
            type: 'is-warning',
            hasIcon: true,
            icon: 'exclamation-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        }
      }
    },
    addHrSubject(){
      this.hr_subject_attributes.push({
        index:  this.num_hr_subjects,
        subject: null,
        _destroy:null,
        ps_code_disabled: false,
        siac_code_disabled: false
      })
      this.selected_hr_subjects.push({
        index:  this.num_hr_subjects,
        name: null,
        id: null,
        substracted_hours: null,
        ps_code: null,
        hours: null,
        _destroy: null,
        ps_code_disabled: false,
        siac_code_disabled: false,
        siac_code: null
      })
      this.num_hr_subjects =  this.num_hr_subjects+1
    },
    deleteHrSubject(hr_subject){
      this.hr_subject_attributes = this.hr_subject_attributes.filter(subject => subject.index != hr_subject.index);
      this.selected_hr_subjects = this.selected_hr_subjects.filter(subject => subject.index != hr_subject.index);
      this.updateHours()
    },
    verifyDocument(){
      this.isLoading= true;
      let id = null
      if(this.queryType==="update"){
        id = this.person_unit.id
      }
      axios
          .get("/life_record/validate_resolution_number.json", {
            params: {res_number: this.person_unit.resolution, query_type: this.queryType,
              entity_type: "person_unit", id: id}
          })
          .then(response =>{
            this.saveAppointment()
          }).catch(error => {
        if(error.response.status === 409){ //se encuentra
          this.$buefy.dialog.confirm({
            hasIcon: true,
            type: "is-warning",
            title: "Advertencia",
            message: error.response.data["message"],
            confirmText: "Continuar y guardar",
            cancelText: "Cancelar",
            onConfirm: () => this.saveAppointment(),
            onCancel: () => this.isLoading = false
          })
        }
        else if(error.response.status === 400){//bad request
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalHourAppointmentForm.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al agregar el nombramiento, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
        }
        else{//403 y otros
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalHourAppointmentForm.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al hacer la solicitud',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
      }})
    },
    async saveAppointment(){
      this.isLoading = true

      if(this.selected_hr_subjects == null || this.selected_hr_subjects == undefined || this.selected_hr_subjects.length < 1){
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Debe agregar al menos una asignatura.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = false
        return
      }

      let personUnitEstablishment = this.establishments.find(obj => obj.name == "PROFESORES HORAS DE CLASES");
      if (personUnitEstablishment)
        this.person_unit.establishment_id = personUnitEstablishment.id;

      if (this.queryType==="create" && this.editMassive != true){

        if (this.file != null && this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Este archivo no tiene formato PDF",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        if (this.file != null && this.file.size > 1024*1024*25) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "El archivo no puede superar el maximo de 25 MB",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        /*if (this.file != null && (this.person_unit.resolution_date ==  null || this.person_unit.resolution_date ==  undefined) &&
            (this.person_unit.resolution == null || this.person_unit.resolution == undefined || this.person_unit.resolution == '') &&
            (this.person_unit.resolution_type_id == null || this.person_unit.resolution_type_id == undefined)) {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Para adjuntar un archivo, debe llenar al menos uno de los campos del documento",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }*/

        // Crea un objeto FormData
        let formData = new FormData();

        // Agrega datos al objeto FormData
        formData.append("person_unit", JSON.stringify(this.person_unit));
        formData.append("selected_hr_subjects", JSON.stringify(this.selected_hr_subjects));
        formData.append("hr_subject_attributes", JSON.stringify(this.hr_subject_attributes));
        formData.append("pu_relation_type_id", 1);
        formData.append("allow_duplication", this.allow_duplication);
        formData.append("file", this.file); // Aquí agregamos el archivo
        formData.append("document_name", this.document_name);

        axios
        .post("/admin/person_units/create_document.json", formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
        .then(response =>{
          this.errores_validacion = {};
          this.$buefy.dialog.alert({
            message: 'El nombramiento fue creado con éxito ',
            confirmText: 'Ok'
          })
          this.person_unit = {}
          this.isLoading=false;
          this.errors = [];
          this.$emit('close')
        }).catch(error => {
          this.errores_validacion = error.response.data
          if(this.errores_validacion["fechas_nombramiento"]){
            this.isAlertModalActive = true;
            this.person_unit.date_validation = true
            this.allow_duplication = false
            this.isLoading=false;
          }
          else if(this.errores_validacion["fechas_exactas_nombramiento"]){
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            this.$refs.modalHourAppointmentForm.scrollTop = 0;
            this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Hubo errores al agregar el nombramiento, favor revisar formato',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.person_unit.date_validation = true
            this.allow_duplication = false
            this.isLoading=false;
          }
          else{
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            this.$refs.modalHourAppointmentForm.scrollTop = 0;
            this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Hubo errores al agregar el nombramiento, favor revisar formato',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.person_unit.date_validation = true
            this.allow_duplication = false
            this.isLoading=false;
          }

        })
      }
      else if (this.queryType==="update" && this.editMassive != true){
        this.person_unit.hr_subjects = this.selected_hr_subjects;
        let clean_person_unit = this.person_unit
        clean_person_unit = this.deleteData(clean_person_unit)
        if (this.file != null && this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Este archivo no tiene formato PDF",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        if (this.file != null && this.file.size > 1024*1024*25) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "El archivo no puede superar el maximo de 25 MB",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        /*if (this.file != null && (this.person_unit.resolution_date ==  null || this.person_unit.resolution_date ==  undefined) &&
            (this.person_unit.resolution == null || this.person_unit.resolution == undefined || this.person_unit.resolution == '') &&
            (this.person_unit.resolution_type_id == null || this.person_unit.resolution_type_id == undefined)) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Para adjuntar un archivo, debe llenar al menos uno de los campos del documento",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }*/

        // Crea un objeto FormData
        let formData = new FormData();

        // Agrega datos al objeto FormData
        formData.append("person_unit", JSON.stringify(clean_person_unit));
        formData.append("selected_hr_subjects", JSON.stringify(this.selected_hr_subjects));
        formData.append("hr_subject_attributes", JSON.stringify(this.hr_subject_attributes));
        formData.append("pu_relation_type_id", 1);
        formData.append("allow_duplication", this.allow_duplication);
        formData.append("file", this.file); // Aquí agregamos el archivo
        formData.append("document_name", this.document_name);

        axios
            .put("/admin/person_units/"+this.id+"/update_document.json", formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
              })
            .then(response => {
              this.$buefy.dialog.alert({
                message: 'El nombramiento fue editado con éxito ',
                confirmText: 'Ok'
              })
              this.errores_validacion = {};
              this.isLoading = false;
              this.person_unit = {}
              this.isLoading=false;
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          if(this.errores_validacion["fechas_nombramiento"]){
            this.isAlertModalActive = true;
            this.person_unit.date_validation = true
            this.allow_duplication = false
            this.isLoading=false;
          }
          else if(this.errores_validacion["fechas_exactas_nombramiento"]){
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            this.$refs.modalHourAppointmentForm.scrollTop = 0;
            this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Hubo errores al agregar el nombramiento, favor revisar formato',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.person_unit.date_validation = true
            this.allow_duplication = false
            this.isLoading=false;
          }
          else{
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            this.$refs.modalHourAppointmentForm.scrollTop = 0;
            this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Hubo errores al agregar el nombramiento, favor revisar formato',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.person_unit.date_validation = true
            this.allow_duplication = false
            this.isLoading=false;
          }
        })
      }
      else if (this.queryType==="copy" && this.editMassive != true){
        this.person_unit.hr_subjects = this.selected_hr_subjects;
        let clean_person_unit = this.person_unit
        clean_person_unit = this.deleteData(clean_person_unit)
        axios
            .post("/admin/person_units/" + this.id+"/copy_appointment.json", {
              person_unit:  clean_person_unit, selected_hr_subjects: this.selected_hr_subjects,
              allow_duplication: this.allow_duplication
            })
            .then(response => {
              this.$buefy.dialog.alert({
                message: 'El nombramiento fue copiado con éxito ',
                confirmText: 'Ok'
              })
              this.errores_validacion = {};
              this.isLoading = false;
              this.person_unit = {}
              this.isLoading=false;
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          if(this.errores_validacion["fechas_nombramiento"]){
            this.isAlertModalActive = true;
            this.person_unit.date_validation = true
            this.allow_duplication = false
            this.isLoading=false;
          }
          else if(this.errores_validacion["fechas_exactas_nombramiento"]){
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            this.$refs.modalHourAppointmentForm.scrollTop = 0;
            this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Hubo errores al copiar el nombramiento, favor revisar formato',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.person_unit.date_validation = true
            this.allow_duplication = false
            this.isLoading=false;
          }
          else{
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            this.$refs.modalHourAppointmentForm.scrollTop = 0;
            this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Hubo errores al copiar el nombramiento, favor revisar formato',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.person_unit.date_validation = true
            this.allow_duplication = false
            this.isLoading=false;
          }
        })
      }
      else if(this.queryType==="create-request" && this.editMassive != true){
        this.person_unit.pu_relation_type_id = 6
        this.person_unit.person_unit_state_id = 2
        this.request = this.request_from_appointment(this.person_unit)
        if(!this.request_created){
          await axios
              .post("/requests.json", {
                request: this.request
              }).then(response =>{
                this.person_unit.request_id = response.data["id"]
                this.request_id = response.data["id"]
                this.request_created = true

          }).catch(error => {
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            this.$refs.modalHourAppointmentForm.scrollTop = 0;
            this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Hubo errores al agregar la solicitud de nombramiento, favor revisar formato',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.person_unit.date_validation = true
            this.allow_duplication = false
            this.isLoading=false;
          })
        }
          await axios
              .post("/admin/person_units.json", {
                person_unit: this.person_unit, selected_hr_subjects: this.selected_hr_subjects,
                hr_subject_attributes: this.hr_subject_attributes,
                pu_relation_type_id: 6, allow_duplication: this.allow_duplication,
              })
              .then(res =>{
                this.$buefy.dialog.alert({
                  message: '<p>La solicitud de nombramiento fue creada con éxito. Para abrir la solicitud en una nueva pestaña, presione</p><b><a href="/requests/'+this.request_id+'" target="_blank">aquí</a></b>',
                  confirmText: 'Ok'
                })
                this.errores_validacion = {};
                this.person_unit = {}
                this.request = {}
                this.isLoading=false;
                this.errors = [];
                this.$emit('close')
              }).catch(error => {
            this.errores_validacion = error.response.data
            if(this.errores_validacion["fechas_nombramiento"]){
              this.isAlertModalActive = true;
              this.person_unit.date_validation = true
              this.allow_duplication = false
              this.isLoading=false;
            }
            else if(this.errores_validacion["fechas_exactas_nombramiento"]){
              var elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              this.$refs.modalHourAppointmentForm.scrollTop = 0;
              this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Hubo errores al agregar la solicitud de nombramiento, favor revisar formato',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              this.person_unit.date_validation = true
              this.allow_duplication = false
              this.isLoading=false;
            }
            else{
              var elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              this.$refs.modalHourAppointmentForm.scrollTop = 0;
              this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Hubo errores al agregar la solicitud de nombramiento, favor revisar formato',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              this.person_unit.date_validation = true
              this.allow_duplication = false
              this.isLoading=false;
            }

          })
      }
      else if (this.queryType==="update-request" && this.editMassive != true){
        this.person_unit.hr_subjects = this.selected_hr_subjects;
        let clean_person_unit = this.person_unit
        clean_person_unit = this.deleteData(clean_person_unit)
        await axios
            .put("/admin/person_units/" + this.id+"/update-request-appointment.json", {
              person_unit:  clean_person_unit, selected_hr_subjects: this.selected_hr_subjects,
              allow_duplication: this.allow_duplication, comment: this.comment, state_id: this.state_id
            })
            .then(response => {
              this.$buefy.dialog.alert({
                message: 'La solicitud de nombramiento fue editada con éxito ',
                confirmText: 'Ok'
              })
              this.errores_validacion = {};
              this.isLoading = false;
              this.person_unit = {}
              this.isLoading=false;
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          if(this.errores_validacion["fechas_nombramiento"]){
            this.isAlertModalActive = true;
            this.person_unit.date_validation = true
            this.allow_duplication = false
            this.isLoading=false;
          }
          else if(this.errores_validacion["fechas_exactas_nombramiento"]){
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            this.$refs.modalHourAppointmentForm.scrollTop = 0;
            this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Hubo errores al agregar el nombramiento, favor revisar formato',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.person_unit.date_validation = true
            this.allow_duplication = false
            this.isLoading=false;
          }
          else{
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            this.$refs.modalHourAppointmentForm.scrollTop = 0;
            this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Hubo errores al agregar el nombramiento, favor revisar formato',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.person_unit.date_validation = true
            this.allow_duplication = false
            this.isLoading=false;
          }
        })
      }
      else if (this.editMassive === true){
          this.person_unit.hr_subjects = this.selected_hr_subjects;
          let clean_person_unit = this.person_unit
          clean_person_unit = this.deleteData(clean_person_unit)
          await axios
              .put("/admin/person_units/" + this.id+"/update_massive_request_appointment.json", {
                  person_unit:  clean_person_unit, selected_hr_subjects: this.selected_hr_subjects,
                  allow_duplication: this.allow_duplication, comment: this.comment, state_id: this.state_id
              })
              .then(response => {
                  this.$buefy.dialog.alert({
                      message: 'La solicitud de nombramiento fue editada con éxito ',
                      confirmText: 'Ok'
                  })
                  this.errores_validacion = {};
                  this.isLoading = false;
                  this.person_unit = {}
                  this.isLoading=false;
                  this.$emit('close')
              }).catch(error => {
                  this.errores_validacion = error.response.data
                  if(this.errores_validacion["fechas_nombramiento"]){
                      this.isAlertModalActive = true;
                      this.person_unit.date_validation = true
                      this.allow_duplication = false
                      this.isLoading=false;
                  }
                  else if(this.errores_validacion["fechas_exactas_nombramiento"]){
                      var elmnt = document.getElementById("app");
                      elmnt.scrollIntoView();
                      this.$refs.modalHourAppointmentForm.scrollTop = 0;
                      this.$buefy.dialog.alert({
                          title: 'Error',
                          message: 'Hubo errores al agregar el nombramiento, favor revisar formato',
                          type: 'is-danger',
                          hasIcon: true,
                          icon: 'times-circle',
                          iconPack: 'fa',
                          ariaRole: 'alertdialog',
                          ariaModal: true
                      })
                      this.person_unit.date_validation = true
                      this.allow_duplication = false
                      this.isLoading=false;
                  }
                  else{
                      var elmnt = document.getElementById("app");
                      elmnt.scrollIntoView();
                      this.$refs.modalHourAppointmentForm.scrollTop = 0;
                      this.$buefy.dialog.alert({
                          title: 'Error',
                          message: 'Hubo errores al agregar el nombramiento, favor revisar formato',
                          type: 'is-danger',
                          hasIcon: true,
                          icon: 'times-circle',
                          iconPack: 'fa',
                          ariaRole: 'alertdialog',
                          ariaModal: true
                      })
                      this.person_unit.date_validation = true
                      this.allow_duplication = false
                      this.isLoading=false;
                  }
              })
      }
    },
    async update_comment(value, request_id){
      this.isLoading = true
      await axios.put("/requests/"+request_id+"/post-comment.json", {
            comment: value,
          })
          .then(response =>{
            this.$buefy.dialog.alert({
              title: 'Exito',
              message: 'La solicitud se actualizó correctamente',
              type: 'is-success',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Hubo errores al agregar el comentario, favor revisar intentar nuevamente',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.isLoading = false
      })
    },
    deleteData(person_unit){
      delete person_unit.units
      delete person_unit.work_plants
      delete person_unit.categories
      delete person_unit.appointment_types
      delete person_unit.performance_areas
      delete person_unit.establishments
      delete person_unit.units
      delete person_unit.contract_units
      delete person_unit.work_plants
      delete person_unit.hierarchies
      delete person_unit.appointment_types
      delete person_unit.working_days
      delete person_unit.grades
      delete person_unit.levels
      delete person_unit.ranks
      delete person_unit.functions
      delete person_unit.categories
      delete person_unit.resolution_types
      delete person_unit.all_hr_subjects
      delete person_unit.can_renew
      delete person_unit.can_update_hr_subjects
      delete person_unit.hr_subjects
      delete person_unit.has_hr_subjects
      delete person_unit.establishment
      delete person_unit.work_plant
      return person_unit
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
    getFilteredHRSubjects(text) {
      this.filtered_hr_subjects = this.hr_subjects.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    onClickSave(value){
      this.person_unit.date_validation = value
      this.saveAppointment()
    },
    onClickDuplicate(value){
      this.allow_duplication = value
      this.saveAppointment()
    },
    verifyMaxHours(){
      this.other_person_units = {}
      this.isLoading = true
      if(this.person_unit.start_date != null && this.person_unit.start_date != '' && this.person_unit.end_date != null && this.person_unit.end_date != ''){
        return axios
            .post("/verify_max_hours.json",{
              start_date: this.person_unit.start_date,
              end_date: this.person_unit.end_date,
              person_id: this.personId
            })
            .then(res => {
              this.other_person_units = res.data.retorno[0]
              if(this.other_person_units == undefined){
                this.other_person_units = {}
              }
              this.isLoading = false
            })
            .catch(error => {
              this.isLoading = false
              console.log(error)
            })
      }
      else{
        this.isLoading = false
      }
    },
    async verifyWarning(){
      if(this.queryType == 'create-request' || this.queryType == 'create'){
        await this.verifyMaxHours()
        if(this.other_person_units != {}){
          if(this.person_unit.taken_hours + this.other_person_units.horas_totales > 16){
            this.$buefy.dialog.confirm({
              title: 'Confirmación',
              message: 'Se esta superando el máximo de horas para este periodo, de todas formas ¿Desea continuar?',
              cancelText: 'Cancelar',
              confirmText: 'Crear',
              type: 'is-warning',
              hasIcon: true,
              onConfirm: () => this.verifyDocument()
            })
          }
          else{
            this.verifyDocument()
          }
        }
        else{
          this.verifyDocument()
        }

      }
      else{
        this.verifyDocument()
      }
    },
    onDeleteFile() {
      this.file = null;
    },
    generateName(){
      var tipo = "NOMBRAMIENTO"
      var centroCosto = ""
      var number = ""
      var date = ""
      var start_date = ""
      var end_date = ""
      var full_name = "-"+this.person_name


      if (this.person_unit.resolution_type_id!= null){
        var nombre_tipo = this.resolution_types.find(x => x.id === this.person_unit.resolution_type_id).name
        if(nombre_tipo.includes("RESOLUCION")){
          tipo = "RES"
        }
        else if(nombre_tipo.includes("DECRETO")){
          tipo = "DECR"
        }
        //tipo = this.resolution_types.find(x => x.id === this.person_unit.resolution_type_id).name
        //tipo = this.documentTypesNames.find(type => type.id === this.document.document_type_id).initials
      }

      if (this.person_unit.contracting_unit_id != null){
        centroCosto = "-CC_" + this.contract_units.find(x => x.id == this.person_unit.contracting_unit_id).cost_center
      }

      if (this.person_unit.resolution != null && this.person_unit.resolution != ''){
        number = "-N_" + this.person_unit.resolution
      }

      if (this.person_unit.resolution_date != null){
        date = "-"+("0" + this.person_unit.resolution_date.getDate()).slice(-2)
            + "_" + ("0" + (this.person_unit.resolution_date.getMonth()+1)).slice(-2)
            + "_" + this.person_unit.resolution_date.getFullYear()
      }
      if (this.person_unit.start_date != null){
        start_date = "-"+("0" + this.person_unit.start_date.getDate()).slice(-2)
            + "_" + ("0" + (this.person_unit.start_date.getMonth()+1)).slice(-2)
            + "_" + this.person_unit.start_date.getFullYear()
      }

      if (this.person_unit.end_date != null){
        end_date = "-"+("0" + this.person_unit.end_date.getDate()).slice(-2)
            + "_" + ("0" + (this.person_unit.end_date.getMonth()+1)).slice(-2)
            + "_" + this.person_unit.end_date.getFullYear()
      }

      if ((this.person_unit.resolution_date == null || this.person_unit.resolution_date == '')  &&
          (this.person_unit.resolution == null || this.person_unit.resolution == '')){
        this.document_name = tipo +  centroCosto + full_name + start_date + end_date
      }
      else{
        this.document_name = tipo + number + centroCosto + date
      }
    },
  },
  watch:{
    changeFile: function (){
      if(!this.changeFile){
        this.file = null
      }
    },
  }

}
</script>

<style scoped>

</style>