<template>
  <section>
    <!--<div class="block">-->
    <b-steps
        v-model="activeStep"
        :animated="isAnimated"
        :rounded="isRounded"
        :has-navigation="hasNavigation"
        :icon-prev="prevIcon"
        :icon-next="nextIcon"
        :label-position="labelPosition"
        :mobile-mode="mobileMode">
      <b-step-item step="1" label="Importación archivo de extensión" :clickable="isStepsClickable">
        <h1 class="title has-text-centered">Importación archivo de extensión</h1>
        <b-tabs position="is-centered" class="block">
          <b-tab-item label="Importación archivo de extensión" >
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(submitImport)">
                <div class="box">
                  <div class="columns">
                    <div class="column is-centered">
                      <b-message type="is-info" has-icon v-if="importacion">
                        <b>Nombramientos importados correctamente: </b> {{nombramientos.length}} de {{nombramientos.length+advertencias.length+errores.length}}<br/>
                        <b>Advertencias y errores: </b> {{advertencias.length+errores.length}}
                      </b-message>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="mb-2" v-if="Object.keys(errores_validacion_1).length !== 0">
                        <b-message type="is-danger" has-icon>
                          <div v-for="(item,index) in errores_validacion_1">
                            {{ item[0] }}
                          </div>
                        </b-message>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field grouped group-multiline>
                        <div class="control">
                          <h1 class="title is-5">Seleccionar fecha término de nombramientos que serán extendidos</h1>
                        </div>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="Fecha de término*">
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <b-datepicker
                              v-model="end_date"
                              icon="calendar-alt"
                              trap-focus
                              locale="es-ES"
                              editable
                              placeholder="Formato de Fecha: dd/mm/aaaa"
                          >
                          </b-datepicker>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field grouped group-multiline>
                        <div class="control">
                          <h1 class="title is-5">Seleccionar fechas de extensión</h1>
                        </div>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <b-field label="Fecha de inicio*">
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <b-datepicker
                              v-model="extension_start_date"
                              icon="calendar-alt"
                              trap-focus
                              locale="es-ES"
                              editable
                              placeholder="Formato de Fecha: dd/mm/aaaa"
                              @input="validDate(extension_start_date, extension_end_date)"
                          >
                          </b-datepicker>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Fecha de término*">
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <b-datepicker
                              v-model="extension_end_date"
                              icon="calendar-alt"
                              trap-focus
                              locale="es-ES"
                              editable
                              placeholder="Formato de Fecha: dd/mm/aaaa"
                              @input="validDate(extension_start_date, extension_end_date)"
                          >
                          </b-datepicker>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field grouped group-multiline>
                        <div class="control">
                          <h1 class="title is-5">Indicar URL carpeta documentación</h1>
                        </div>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="Url documentación">
                        <b-input v-model="documentation_link" type="text" maxlength="255"></b-input>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field grouped group-multiline>
                        <div class="control">
                          <h1 class="title is-4">Seleccionar archivo de extensión de nombramientos profesores horas</h1>
                          <h2 class="subtitle is-6 has-text-weight-bold">*El archivo debe tener la extensión .xlsx.</h2>
                        </div>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div >
                        <b-field class="file">
                          <b-upload v-model="file" expanded>
                            <a class="button is-primary is-outlined">
                              <b-icon icon="upload"></b-icon>
                              <span>{{ file.name || "Subir Archivo"}}</span>
                            </a>
                          </b-upload>
                        </b-field>
                      </div>
                    </div>
                    <div class="column">
                      <div class="has-text-right">
                        <b-button
                            label="Ver Ejemplo"
                            type="is-primary"
                            @click="isModalSpreadSheet = true" />
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="has-text-right">
                        <button class="button is-primary" :disabled="file.name.length===0||!valid_dates" type="submit">Cargar Nombramientos</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </b-tab-item>
          <b-tab-item label="Errores y advertencias archivo de importación" v-if="importacion">
            <div class="columns">
              <div class="column is-centered">
                <b-message type="is-info" has-icon>
                  <b>Advertencias:</b> Inconsistencias de las fechas ingresadas respecto al archivo de importación y los datos de SIGA. Impiden la importación correcta de los nombramientos.<br/>
                  <b>Errores:</b> Problemas en el archivo de importación que impiden completamente la extensión. Pueden ser errores de tipeo y por lo tanto pueden ser arreglados al modificar el archivo de importación.
                </b-message>
              </div>
            </div>
            <div v-if="errores.length>0" class="columns is-centered">
              <div class="column is-11">
                <div class="box">
                  <h1 class="is-size-5">
                    <b-icon
                        pack="fas"
                        icon="exclamation-triangle">
                    </b-icon>
                    Errores del archivo: {{ this.file_name_errors }}
                  </h1>
                  <div v-if="import_type != 'Convenios'" class="ml-5" v-for="error in errores">
                    Fila n° {{error.FILA}}: {{ error.ERRORES }}
                  </div>
                  <div v-if="import_type == 'Convenios'" class="ml-5" v-for="error in errores">
                    Hoja:{{error.PAGINA}} -> Fila n° {{error.FILA}}: {{ error.ERRORES }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="advertencias.length>0" class="columns is-centered">
              <div class="column is-11">
                <div class="box">
                  <h1 class="is-size-5">
                    <b-icon
                        pack="fas"
                        icon="exclamation-triangle">
                    </b-icon>
                    Advertencias del archivo: {{ this.file_name_errors }}
                  </h1>
                  <div v-if="import_type != 'Convenios'" class="ml-5" v-for="advertencia in advertencias">
                    Fila n° {{advertencia.FILA}}: {{ advertencia.ERRORES }}
                  </div>
                </div>
              </div>
            </div>
            <div class="columns has-text-right">
              <div class="column" v-if="errores.length>0||advertencias.length>0">
                <b-button @click="importErrors" v-if="errores.length!=0 || advertencias.length!=0" icon-left="file-excel" class="is-excel-color">Exportar errores y advertencias en excel</b-button>
              </div>
            </div>
          </b-tab-item>
        </b-tabs>
      </b-step-item>
      <b-step-item step="2" label="Extensión nombramientos" :clickable="isStepsClickable" v-if="importacion">
        <h1 class="title has-text-centered">Extensión nombramientos</h1>
        <b-tabs position="is-centered" class="block" v-if="importacion">
          <b-tab-item label="Nombramientos Extensibles">
            <div class="box">
              <div class="columns">
                <div class="column is-centered">
                  <b-message type="is-info" has-icon v-model="postExtension">
                    {{message}}
                  </b-message>
                </div>
              </div>
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(submitAppointments)">
                  <div class="columns">
                    <div class="column">
                      <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                        <b-message type="is-danger" has-icon>
                          <div v-for="(item,index) in errores_validacion">
                            {{ item[0] }}
                          </div>
                        </b-message>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="has-text-right">
                        <button class="button is-primary"type="submit" :disabled="!valid_dates">Extender</button>
                      </div>
                    </div>
                  </div>
                  <b-table
                      :data="nombramientos"
                      :paginated= true
                      per-page="20"
                      striped
                      hoverable
                      :narrowed=true
                      :current-page="currentPage"
                      :pagination-simple=false
                      pagination-position="both"
                      default-sort-direction="asc"
                      sort-icon="arrow-up"
                      sort-icon-size="is-small"
                      default-sort="product.title"
                      aria-next-label="Siguiente"
                      aria-previous-label="Anterior"
                      aria-page-label="Página"
                      aria-current-label="Página actual"
                      :checked-rows.sync="checkedRows"
                      checkable
                      :checkbox-position="checkboxPosition"
                      @check-all="selectAll"

                      detailed
                      detail-key="id"
                      detail-transition="fade"
                      :show-detail-icon="true">
                    <b-table-column
                        field="index"
                        label="N°">
                      <template v-slot="props">
                        {{ nombramientos.indexOf(props.row) + 1 }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="person_rut"
                        label="Rut"
                        sortable
                        searchable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.person_rut}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="person_name"
                        label="Nombre"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.person_name}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="unit.cost_center"
                        label="CC Desempeño"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.unit!==undefined ? props.row.unit.cost_center : ""}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="contracting_unit.cost_center"
                        label="CC Contratacion"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.contracting_unit!==undefined ? props.row.contracting_unit.cost_center : ""}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="taken_hours"
                        label="Horas"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.taken_hours}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="start_date"
                        label="Fecha Desde"
                        width="110"
                        sortable
                        searchable :custom-search="searchStart">
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL") : ''}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="end_date"
                        label="Fecha Hasta"
                        width="110"
                        sortable
                        searchable :custom-search="searchEndDate">
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL") : ''}}
                      </template>
                    </b-table-column>

                    <b-table-column
                        field="new_res_type.name"
                        label="Nuevo Tipo documento"
                        searchable
                        sortable
                        headerClass= "custom-style-extension"
                        cellClass= "custom-style-extension" >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.new_res_type.name}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="new_res_number"
                        label="Nuevo N° Documento"
                        searchable
                        sortable
                        headerClass= "custom-style-extension"
                        cellClass= "custom-style-extension">
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.new_res_number}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="new_res_date"
                        label="Nueva Fecha Documento"
                        searchable
                        searchable :custom-search="searchResolution"
                        headerClass= "custom-style-extension"
                        cellClass= "custom-style-extension">
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.new_res_date._isValid ? new Date(props.row.new_res_date).toLocaleDateString("es-CL") : ''}}
                      </template>
                    </b-table-column>

                    <template slot="empty" slot-scope="props">
                      <div class="columns is-centered">
                        <div class="column is-6 is-centered has-text-centered">
                          <b-message type="is-danger">
                            No se encontraron nombramientos para extender.
                          </b-message>
                        </div>
                      </div>
                    </template>

                    <template #detail="props">
                      <article class="media">
                        <div class="media-content">
                          <div class="content">
                            <div class="columns">
                              <div class="column is-2">
                                <strong>Asignaturas: </strong>
                              </div>
                              <div class="column">
                                <p>{{props.row.subject_names}}</p>
                              </div>
                            </div>

                          </div>
                        </div>
                      </article>
                    </template>

                  </b-table>
                </form>
              </ValidationObserver>
            </div>
          </b-tab-item>
          <b-tab-item label="Errores extensión" v-if="errores_extension.length>0">
            <b-table
                :data="errores_extension"
                :paginated= true
                per-page="20"
                striped
                hoverable
                :narrowed=true
                :current-page="currentPage_2"
                :pagination-simple=false
                pagination-position="both"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="product.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
              <b-table-column
                  field="index"
                  label="N°">
                <template v-slot="props">
                  {{ errores_extension.indexOf(props.row) + 1 }}
                </template>
              </b-table-column>
              <b-table-column
                  field="rut"
                  label="Rut"
                  sortable
                  searchable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{ props.row.rut}}
                </template>
              </b-table-column>
              <b-table-column
                  field="person_name"
                  label="Nombre"
                  searchable
                  sortable
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.person_name}}
                </template>
              </b-table-column>
              <b-table-column
                  field="motivo"
                  label="Razón de error"
              >
                <template v-slot="props">
                  {{props.row.motivo}}
                </template>
              </b-table-column>
              <template slot="empty" slot-scope="props">
                <div class="columns is-centered">
                  <div class="column is-6 is-centered has-text-centered">
                    <b-message type="is-danger">
                      No se encontraron resultados para filtros aplicados.
                    </b-message>
                  </div>
                </div>
              </template>
            </b-table>
          </b-tab-item>
        </b-tabs>
      </b-step-item>
    </b-steps>
    <!--</div>-->

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="isAlertModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal-confirm-duplication-for-extension :person-id="person_unit.person_id"
                                   :id="person_unit.id"
                                   :person_unit="person_unit"
                                   modal-header="Confirmar Nombramientos"
                                   @close="props.close"
                                   @clicked_save="onClickSave"
                                   @clicked_duplicate="onClickDuplicate">
        </modal-confirm-duplication-for-extension>
      </template>
    </b-modal>
    <b-modal
        v-model="isModalSpreadSheet"
        has-modal-card
        trap-focus
        full-screen
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal-spreadsheet-extension
            modal-header="Cabeceras y Ejemplo"
            @close="props.close">
        </modal-spreadsheet-extension>
      </template>
    </b-modal>

  </section>
</template>
<script>
import axios from "axios"
import moment from "moment";
import {searchDate, formatDate} from "../../../packs/utilities";
import ModalConfirmDuplicationForExtension from "../../life_record/modal_confirm_duplication_for_extension"
import ModalSpreadsheetExtension from "../../life_record/spreadsheet/spreadsheet_extension"


export default {
  name: "ExtensionHours",
  components:{
    ModalConfirmDuplicationForExtension,
    ModalSpreadsheetExtension
  },
  data(){
    return{
      import_type:"",
      person_unit: {},
      currentPage: 1,
      currentPage_2: 1,
      file:{name:""},
      dropFiles: [],
      isLoading:false,
      errores:[],
      advertencias: [],
      file_name_errors:"",
      cabeceras:[],
      hojas:[],
      errorFile:{name:""},
      units: [],
      start_date: null,
      end_date: null,
      importacion: false,
      nombramientos: [],
      checkedRows: [],
      checkboxPosition: "right",
      extension_start_date: null,
      extension_end_date: null,
      errores_validacion:{},
      errores_validacion_1: {},
      invalid_date: false,
      activate_check: false,
      date_validation: true,
      allow_duplication: false,
      isAlertModalActive: false,
      valid_dates: false,
      disable_import: false,
      errores_extension: [],
      isModalSpreadSheet: false,
      activeStep: 0,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      hasNavigation: true,
      customNavigation: false,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      message: "",
      postExtension: false,
      documentation_link: "",
      new_document_url: ""

    }
  },
  created(){
    this.initialize()
  },
  props:[

  ],
  methods:{
    initialize(){
      this.isLoading = true
      this.person_unit.person_id = this.personId
      this.isLoading = false
    },
    submitAppointments(){
        this.updateAppointments()
    },
    onClickSave(value){
      this.person_unit.date_validation = value
      this.date_validation = value
      this.import_file()
    },
    onClickDuplicate(value){
      this.allow_duplication = value
      this.import_file()
    },
    selectAll(rows) {
      if (rows.length > this.checkedRows.length) {
        this.$nextTick(() => { this.checkedRows = this.nombramientos})
      } else {
        this.$nextTick(() => {
          this.checkedRows = []
        })
      }
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    submitImport(){
      var extension_start_date_moment = moment(this.extension_start_date, "MM/DD/YYYY")
      var extension_end_date_moment = moment(this.extension_end_date, "MM/DD/YYYY")
      var between_date = moment("12/31/"+extension_start_date_moment.year().toString(), "MM/DD/YYYY")
      this.person_unit.start_date = this.extension_start_date
      this.person_unit.end_date = this.extension_end_date
      this.nombramientos = []
      if(Math.abs(extension_start_date_moment.year()-extension_end_date_moment.year())>1){
        this.allow_duplication = false
        this.isAlertModalActive = true
        this.isLoading = false;
        return
      }
      else if(between_date.isBetween(extension_start_date_moment, extension_end_date_moment)){
        this.allow_duplication = false
        this.isAlertModalActive = true
        this.isLoading = false;
        return
      }
      else if(extension_start_date_moment.date()===31 && extension_start_date_moment.month()+1===12){
        this.isAlertModalActive = true
        this.isLoading = false;
        return
      }
      else if(extension_end_date_moment.date()===31 && extension_end_date_moment.month()+1===12){
        this.isAlertModalActive = true
        this.isLoading = false;
        return
      }
      else{
        this.import_file()
      }
    },
    import_file(){
      this.isLoading = true;
      this.file_name_errors = "";
      this.errores = [];
      this.advertencias = []
      this.cabeceras = [];
      this.errores_validacion = {}
      this.errores_extension = []
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('end_date', this.end_date)
      formData.append('extension_start_date', this.extension_start_date)
      formData.append('extension_end_date', this.extension_end_date)
      formData.append('date_validation', this.date_validation)
      formData.append('allow_duplication', this.allow_duplication)
      formData.append('documentation_link', this.documentation_link)
      this.message = ""
      this.postExtension = false
      this.errorFile = this.file;
      axios.post("/life_record/import_extension.json",
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(response =>{
        this.isLoading = true;
        this.errores_validacion_1 = {}
        if(response.data["errores"].length>0){
          this.errores = response.data["errores"]
        }
        else{
          this.errores = []
        }
        if(response.data["advertencias"].length>0){
          this.advertencias = response.data["advertencias"]
        }
        else{
          this.advertencias = []
        }
        this.new_document_url = response.data["new_document_url"]
        this.cabeceras = response.data["cabeceras"];
        this.nombramientos = response.data["nombramientos"]
        this.nombramientos = this.nombramientos.map(personUnit => {
          var newPersonUnit = personUnit
          newPersonUnit["start_date"] = moment(newPersonUnit["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
          newPersonUnit["end_date"] = moment(newPersonUnit["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
          newPersonUnit["new_res_date"] = moment(newPersonUnit["new_res_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
          return newPersonUnit;
        });
        this.importacion = true;
        this.disable_import = true;
        if (response.data["errores"].length>0 || response.data["advertencias"].length>0){
          this.file_name_errors = this.file.name;
          this.$buefy.dialog.alert({
            message: 'Algunos datos del archivo "'+this.file.name+'" no han podido ser importados, por favor, revisar errores y advertencias',
            confirmText: 'Ok',
            type: 'is-warning',
            hasIcon: true,
            icon: 'exclamation-triangle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        }
        else{
          this.$buefy.dialog.alert({
            message: 'Su archivo "'+this.file.name+'" ha sido importado con éxito',
            confirmText: 'Ok'
          })
          this.file={name:""};
          this.errores = [];
          this.file_name_errors="";
        }
        this.isLoading = false;
      })
          .catch(error => {
            //console.log('FAILURE!!');
            this.errores_validacion_1 = error.response.data
            this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Hubo errores en la importación de su archivo. Puede ser un archivo no válido o presenta errores de formato',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.isLoading = false;
          });
    },
    searchEndDate(row,input){
      return searchDate(row.end_date,input)
    },
    searchResolution(row,input){
      return searchDate(row.new_res_date,input)
    },
    searchStart(row,input){
      return searchDate(row.start_date,input)
    },
    validDate(start_date, end_date){
      if(start_date && end_date){
        this.valid_dates = true
      }
      else{
        this.valid_dates = false
      }
    },
    updateAppointments(){
      this.isLoading = true;

      if(this.checkedRows.length === 0){
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Debe seleccionar al menos un nombramiento',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = false;
        return
      }
      axios
          .post("/life_record/extend_appointments.json", {
            appointments: this.checkedRows, extension_start_date: this.extension_start_date,
            extension_end_date: this.extension_end_date, start_date: this.start_date, end_date: this.end_date,
            date_validation: this.date_validation, allow_duplication: this.allow_duplication,
            new_document_url: this.new_document_url
          })
          .then(response =>{
            this.errores_validacion = response.data["errores_hash"];
            this.errores_extension = response.data["errores_array"]
            this.postExtension = true
            this.message = "Nombramientos extendidos correctamente: "+response.data["extendidos"]+ " de "+response.data["totales"]+"."
            if(this.errores_extension.length>0 && response["extendidos"] > 0){
              this.$buefy.dialog.alert({
                message: 'Los nombramientos fueron extendidos con éxito, pero sucedieron algunos errores.',
                confirmText: 'Ok'
              })
            }
            else if(response["extendidos"]===0){
              this.$buefy.dialog.alert({
                message: 'No se pudieron extender los nombramientos, revise pestaña de errores.',
                confirmText: 'Ok'
              })
            }
            else{
              this.$buefy.dialog.alert({
                message: 'Los nombramientos fueron extendidos con éxito ',
                confirmText: 'Ok'
              })
                  }
            this.isLoading=false;
            this.errors = [];
            this.$emit('close')
          }).catch(error => {
        this.errores_validacion = error.response.data
        this.isLoading = false;
      })
    },
    importErrors(){
      let vm = this;
      vm.isLoading=true;
      axios
          .post("/life_record/export_errors_extension.xlsx",{cabeceras:vm.cabeceras,errores:vm.errores,hojas:vm.hojas, advertencias: vm.advertencias},
              {
                responseType: "blob"
              })
          .then(response =>{
            vm.isLoading=false;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Errores  y advertencias extensión masiva '+vm.errorFile.name.replace(".xlsx","")+' '+new Date().toLocaleDateString()+'.xlsx');
            document.body.appendChild(link);
            link.click();
          }).catch(error => {
        vm.isLoading=false;
        vm.$buefy.notification.open({
          message: 'Hubo un error en la solicitud',
          type: 'is-danger'
        })
      })


    }

  },
  computed:{

  }
}
</script>

<style>
.custom-style-extension {
  background: #714DD2 !important;
  color: white !important;
}

</style>