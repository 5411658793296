<template>
  <div>
    <b-dropdown aria-role="list" v-if="can_create_document===true || can_create_folder===true">
      <button class="button is-primary" slot="trigger" slot-scope="{ active }">
        <b-icon icon="plus"></b-icon>
        <span>Acciones</span>
      </button>
      <b-dropdown-item has-link aria-role="menuitem" v-if="can_create_document===true">
       <!-- <a @click="addDocument()" v-if="actualFolder">-->
        <a @click="addDocument()">
          <b-icon icon="file-alt"></b-icon>
          Crear Documento
        </a>
       <!-- <a v-else style="pointer-events: none; cursor: default;">
          <b-icon icon="file-alt"></b-icon>
          Documento
        </a>-->
      </b-dropdown-item>
      <b-dropdown-item has-link aria-role="menuitem" v-if="can_create_folder===true">
        <a @click="addFolder()">
          <b-icon icon="folder-open"></b-icon>
          Crear Carpeta
        </a>
      </b-dropdown-item>
      <!--<b-dropdown-item has-link aria-role="menuitem">
        <a @click="editFolder()">
          <b-icon icon="folder-open"></b-icon>
          Editar Carpeta
        </a>
      </b-dropdown-item>-->
    </b-dropdown>

  </div>
</template>
<script>
import EventBus from "../eventBus"
export default {
  data(){
    return{

    }
  },
  created(){

  },
  props:[
    'actualFolder', 'can_create_document', 'can_create_folder'
  ],
  methods:{
    addFolder(){
      this.$emit('clicked', 2)
      this.$emit('close')
    },
    addDocument(){
      this.$emit('clicked', 1)
      this.$emit('close')
    },
    editFolder(){
      this.$emit('clicked', 3)
      this.$emit('close')
    }
  },
  computed:{

  }
}
</script>