<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(rectify)">
        <div class="columns">
          <div class="column">
            <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
              <b-message type="is-danger" has-icon>
                <div v-for="(item,index) in errores_validacion">
                  {{ item[0] }}
                </div>
              </b-message>
            </div>
          </div>
        </div>
        <b-steps
            v-model="activeStep"
            :animated="isAnimated"
            :rounded="isRounded"
            :has-navigation="hasNavigation"
            :icon-prev="prevIcon"
            :icon-next="nextIcon"
            :label-position="labelPosition"
            :mobile-mode="mobileMode">
          <b-step-item step="1" label="Edición Nombramiento" :clickable="isStepsClickable">
            <!-- Box de Formulario Appointment -->
            <div class="is-centered" style="margin-top: 30px;">
              <div class="title is-4 is-centered" style="text-align: center; margin-bottom: 20px">
                Nombramiento
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción*">
                  <ValidationProvider rules="required|min:5|max:200" v-slot="{ errors }">
                    <b-input v-model="person_unit.description" type="textarea"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de documento">
                  <multiselect v-model="person_unit.resolution_type_id"
                               :options="resolution_types.map(type => type.id)"
                               :custom-label="opt => resolution_types.find(x => x.id === opt).name"
                               placeholder="Seleccione tipo de resolución"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                               :allow-empty="true"
                               :disabled="disable_hours || disable_change_cc">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Número de documento">
                  <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                    <b-input v-model="person_unit.resolution" type="text" :disabled="disable_hours || disable_change_cc"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de documento">
                  <b-datepicker
                      v-model="person_unit.resolution_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                      :disabled="disable_hours || disable_change_cc">
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Unidad de contratación*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.contracting_unit_id"
                                 :options="contract_units.map(type => type.id)"
                                 :custom-label="opt => contract_units.find(x => x.id === opt).name"
                                 placeholder="Seleccione una unidad"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false"
                                 :disabled="disable_hours">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">

                <b-field label="Unidad de desempeño*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.unit_id"
                                 :options="units.map(type => type.id)"
                                 :custom-label="opt => units.find(x => x.id === opt).name"
                                 placeholder="Seleccione una unidad"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false"
                                 :disabled="disable_hours">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Calidad">
                  <multiselect v-model="person_unit.quality"
                               :options="qualities.map(type => type.name)"
                               placeholder="Seleccione calidad de contrato"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                               :allow-empty="true"
                               :disabled="disable_hours || disable_change_cc">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Labor*">
                  <ValidationProvider rules="required|min:5|max:255" v-slot="{ errors }">
                    <b-input
                        type="text"
                        v-model="person_unit.labour"
                        :disabled="disable_hours || disable_change_cc">

                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción de Labor que Realiza*">
                  <ValidationProvider rules="required|min:5|max:255" v-slot="{ errors }">
                    <b-input
                        type="textarea"
                        v-model="person_unit.labour_description"
                        :disabled="disable_hours || disable_change_cc">

                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <h3 class="is-size-6 has-text-weight-bold">Lugar donde realiza el trabajo*
                  <b-tooltip
                      label="Si selecciona 'dependencias de la unidad', este campo se repetirá según la Unidad de Desempeño"
                      position="is-right"
                      size="is-medium"
                      multilined>

                    <b-icon
                        pack="fas"
                        icon="info-circle"
                        size="is-small"
                        type="is-info">
                    </b-icon>
                  </b-tooltip>
                </h3>
                <b-field label=" ">
                  <ValidationProvider rules="required|min:5|max:255" v-slot="{ errors }">
                    <b-input v-model="person_unit.labour_place" type="text" :disabled="unit_dependences || disable_hours"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                    <b-checkbox v-model="unit_dependences" @input="changeLabourPlace" :disabled="!person_unit.unit_id || disable_hours">
                      Dependencias de la Unidad
                    </b-checkbox>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Desde*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-datepicker
                        v-model="person_unit.start_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                        :disabled="disable_hours || disable_change_cc">
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Hasta">
                  <b-datepicker
                      v-model="person_unit.end_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                      :disabled="disable_hours || disable_change_cc">
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <h3 class="is-size-6 has-text-weight-bold">Horario de la labor*
                  <b-tooltip
                      label="Seleccione 'modificar horario' para ir a la pestaña de ingreso de horario según día de trabajo"
                      position="is-right"
                      size="is-medium"
                      multilined>

                    <b-icon
                        pack="fas"
                        icon="info-circle"
                        size="is-small"
                        type="is-info">
                    </b-icon>
                  </b-tooltip>
                </h3>
                <b-field label=" ">
                  <ValidationProvider rules="required|min:5|max:4000" v-slot="{ errors }" style="width: 100%" >
                    <b-input v-model="stringSchedule" type="text" readonly expanded></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <p class="control">
                    <button class="button is-primary" @click.prevent="editSchedule" :disabled="disable_change_cc">Modificar Horario </button>
                  </p>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Monto*">
                  <ValidationProvider rules="integer|positive|max:15" v-slot="{ errors }">
                    <b-input v-model="person_unit.amount" type="text" :disabled="disable_hours || disable_change_cc"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Total Convenio">
                  <ValidationProvider rules="integer|positive|max:15" v-slot="{ errors }">
                    <b-input v-model="person_unit.total_amount" type="text" :disabled="disable_hours || disable_change_cc"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Forma de Pago">
                  <multiselect v-model="person_unit.payment"
                               :options="payments_types.map(type => type.name)"
                               placeholder="Seleccione forma de pago"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                               :allow-empty="true"
                               :disabled="disable_hours || disable_change_cc">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Ítem Imputación Presupuestaria">
                  <ValidationProvider rules="integer|positive|max:15" v-slot="{ errors }">
                    <b-input v-model="person_unit.item" type="text" :disabled="disable_hours || disable_change_cc"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="CC Imputación Presupuestaria*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.budget_allocation_cc"
                                 :options="contract_units.map(type => type.cost_center)"
                                 :custom-label="opt => contract_units.find(x => x.cost_center === opt).name"
                                 placeholder="Seleccione una unidad"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false"
                                 :disabled="disable_hours">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <b-field label="Url Documentación">
                  <ValidationProvider v-slot="{ errors }">
                    <b-input v-model="person_unit.documentation_link" type="text" :disabled="disable_hours || disable_change_cc"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
          </b-step-item>
          <b-step-item step="2" :label="'Datos ' +form_name" :clickable="isStepsClickable">
            <div class="is-centered" style="margin-top: 30px;">
              <div class="title is-4 is-centered" style="text-align: center; margin-bottom: 20px">
                {{form_name}}
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de documento">
                  <multiselect v-model="event.resolution_type_id"
                               :options="resolution_types.map(type => type.id)"
                               :custom-label="opt => resolution_types.find(x => x.id === opt).name"
                               placeholder="Seleccione tipo de documento"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                               :allow-empty="true">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                          No se encontraron elementos.
                      </span>
                  </multiselect>
                </b-field>
              </div>
              <div class="column">
                <b-field label="N° Documento">
                  <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                    <b-input v-model="event.decree" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Documento">
                  <b-datepicker
                      v-model="event.decree_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field :label="'Fecha '+form_name+'*'">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-datepicker
                        v-model="event.start_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                    >
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field :label="'Url carpeta documentación ' +form_name">
                  <ValidationProvider v-slot="{ errors }" rules="max:255">
                    <b-input v-model="event.documentation_link" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input ref="input_descripcion" v-model="event.description" type="textarea" minlength="10" maxlength="4000"
                             icon-right="sync-alt"
                             icon-right-clickable
                             @icon-right-click="updateDescription"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="has-text-right is-grouped">
              <button class="button is-primary" type="submit">Guardar {{form_name}}</button>
            </div>
          </b-step-item>
        </b-steps>
      </form>
    </ValidationObserver>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="isWeeklyModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal-weekly-schedule-form
            name="planificacion_horario"
            :person-id="person_unit.person_id"
            :horarios="schedule"
            :intervalo="interval"
            :stringHorario="stringSchedule"
            :scheduleDays="scheduleDays"
            query-type="create"
            modal-header="Determinar Horario Semanal"
            @clicked="onClickChild"
            @close="props.close">
        </modal-weekly-schedule-form>
      </template>
    </b-modal>
  </section>
</template>

<script>
import axios from "axios";
import ModalWeeklyScheduleForm from "../modal_weekly_schedule_form";
import {compareObjects, formatString} from "../../../packs/utilities";
import _ from "lodash";

export default {
  name: "rectify_appointment_feecontract",
  props:[
    'queryType', 'personId', 'id', 'modalHeader', 'form_name', 'event_type_id'
  ],
  components:{
    ModalWeeklyScheduleForm
  },
  data(){
    return {
      event:{
        event_type_id: null,
        person_unit_id: this.id
      },
      person_unit:{
        is_feecontract:true
      },
      old_person_unit:{
        is_feecontract:true
      },
      unit_dependences: false,
      years:[],
      qualities: [{name: "PROFESIONAL"},{name: "TECNICO"},{name: "EXPERTO"}],
      payments_types: [{name: "MENSUAL"}, {name: "GLOBAL"}],
      appointment_types:[],
      work_plants:[],
      grade: {},
      rank: {},
      resolution_types:[],
      hierarchies: [],
      units: [],
      contract_units: [],
      working_days:[],
      errores_validacion:{},
      documentation_link:'',
      establishments:[],
      isLoading: true,
      isDistanceModalActive: false,
      link: false,
      distances: [],
      activeStep: 0, //bsteps
      showSocial: false,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      hasNavigation: true,
      customNavigation: false,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      event_types: [],
      performance_areas: [],
      performance_area: null,
      grades: [],
      levels: [],
      interval: 60,
      scheduleDays: [],
      stringSchedule: "",
      schedule: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: []
      },
      disable_rectification: false,
      disable_hours: false,
      disable_change_cc: false,
      isWeeklyModalActive: false,
      options: [{type: 'Dejar de Servir', distances: []}, {type: 'Término de Contrato', distances: []}, {type: 'Renuncia', distances: []}],
    }
  },
  created() {
    this.isLoading=true;
    axios
        .get("/life_record/rectify/"+this.id+".json")
        .then(res => {
          //this.old_person_unit = res.data["person_unit"]
          this.person_unit = res.data["person_unit"]
          if(res.data["person_unit"]["start_date"]!==null){
            this.person_unit.start_date = new Date(this.formatDate(res.data["person_unit"]["start_date"]))
          }
          if(res.data["person_unit"]["end_date"]!==null){
            this.person_unit.end_date = new Date(this.formatDate(res.data["person_unit"]["end_date"]))
          }
          if(res.data["person_unit"]["decree_date"]!==null){
            this.person_unit.decree_date = new Date(this.formatDate(res.data["person_unit"]["decree_date"]))
          }
          if(res.data["person_unit"]["resolution_date"]!==null){
            this.person_unit.resolution_date = new Date(this.formatDate(res.data["person_unit"]["resolution_date"]))
          }
          if(this.person_unit.performance_area){
            this.performance_area = [this.person_unit.performance_area]
          }
          this.schedule = JSON.parse(this.person_unit.text_schedule)
          if(this.person_unit.appointment_days.length!==0){
            this.interval = this.person_unit.appointment_days[0].interval
          }
          this.old_person_unit = _.cloneDeep(this.person_unit);
          this.units = res.data["person_unit"]["units"]
          this.contract_units = res.data["person_unit"]["contract_units"]
          this.grade = res.data["person_unit"]["grade"]
          this.rank = res.data["person_unit"]["rank"]
          this.hierarchies = res.data["person_unit"]["hierarchies"]
          this.resolution_types = res.data["person_unit"]["resolution_types"]
          this.work_plants = res.data["person_unit"]["work_plants"]
          this.appointment_types = res.data["person_unit"]["appointment_types"]
          this.working_days = res.data["person_unit"]["working_days"]
          this.documentation_link = res.data["person_unit"]["documentation_link"]
          this.grades = res.data["person_unit"]["grades"]
          this.levels = res.data["person_unit"]["levels"]
          this.performance_areas = res.data["person_unit"]["performance_areas"]
          this.establishments = res.data["person_unit"]["establishments"]
          this.event_types = res.data["event_types"]
          this.event.event_type_id = this.event_type_id //RECTIFICACION, AUMENTO DE HORAS, REDUCCION DE HORAS
          if(parseInt(this.event_type_id) === 10){//reduccion de horas
            this.disable_hours = true
          }
          else if(parseInt(this.event_type_id) === 11){//aumento de horas
            this.disable_hours = true
          }
          else if(parseInt(this.event_type_id) === 12){//cambio de cc
            this.disable_change_cc = true
          }
          this.event.person_unit_id = this.person_unit.id
          this.event.person_id = this.person_unit.person_id
          this.stringSchedule = res.data["person_unit"]["schedule"]
          this.person_unit.amount = parseInt(res.data["person_unit"]["amount"]);
          this.person_unit.total_amount = parseInt(res.data["person_unit"]["total_amount"]);
          this.person_unit.item = parseInt(res.data["person_unit"]["item"]);
          this.isLoading = false;
        }).catch(error => {
      console.log(error)
      this.isLoading=false;
    })

  },
  methods: {
    editSchedule(){
      this.isWeeklyModalActive = true
    },
    onClickChild(schedule, days, stringSchedule, interval){
      this.stringSchedule = stringSchedule
      this.schedule = schedule
      this.scheduleDays = days
      this.interval = interval
    },
    changeLabourPlace(){
      if(this.unit_dependences===true){
        if(this.person_unit.unit_id){
          this.person_unit.labour_place = this.units.find(x => x.id === this.person_unit.unit_id).name
        }
      }
      else{
        this.person_unit.labour_place = null
      }
    },
    formatString,
    compareObjects,
    updateDescription(){
      this.isLoading=true
      this.person_unit.schedule = this.stringSchedule
      let description = compareObjects(this.old_person_unit, this.person_unit, ["description", "function_name", "work_plants", "units",
        "contract_units", "establishments", "hierarchies", "working_days", "levels", "grades", "establishment", "function",
        "work_plant", "hr_subjects", "has_hr_subjects", "days", "appointment_days", "appointment_type",
        "performance_areas", "functions", "rank", "grade", "appointment_types", "categories", "resolution_types", "all_hr_subjects", "text_schedule"])

      this.$refs.input_descripcion.updateValue(formatString(description))
      this.isLoading = false
    },
    rectify(){
      this.isLoading=true;
      this.person_unit.schedule = this.stringSchedule
      this.person_unit.days = this.scheduleDays
      this.person_unit.skip_if_fee_contract = true
      this.person_unit.text_schedule = JSON.stringify(this.schedule)
      let personUnitEstablishment = this.establishments.find(obj => obj.name == "HONORARIOS");
      if (personUnitEstablishment)
        this.person_unit.establishment_id = personUnitEstablishment.id;

      let clean_person_unit = this.person_unit
      clean_person_unit = this.deleteData(clean_person_unit)
      axios
          .put("/admin/person_units/" + this.id+".json", {
            person_unit: this.person_unit
          })
          .then(response => {
            this.event.end_date = this.event.start_date
            axios
                .post("/admin/events.json", {
                  event: this.event
                })
                .then(response =>{
                  this.errores_validacion = {};
                  this.$buefy.dialog.alert({
                    message: 'La Rectificación fue creada con éxito ',
                    confirmText: 'Ok'
                  })
                  this.person_unit = {}
                  this.isLoading=false;
                  this.errors = [];
                  window.location.href="/people/"+this.event.person_id+"/life_record"
                }).catch(error => {
              this.errores_validacion = error.response.data
              this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Hubo errores al agregar la rectificación, favor revisar formato',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              this.isLoading=false;
            })
          }).catch(error => {
        this.errores_validacion = error.response.data
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo errores al rectificar el nombramiento, favor revisar formato',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading=false;
      })
    },
    deleteData(person_unit){
      delete person_unit.units
      delete person_unit.internal_positions
      delete person_unit.hierarchies
      delete person_unit.appointment_types
      return person_unit
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
  }
}
</script>
<style>
#description_rectify .control.has-icons-right .icon {
  color: #7957d5;
}
.control.has-icons-right .icon {
  color: #7957d5;
}
</style>