<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(verifyDocument)">
        <div class="modal-card" style="width: auto" id="modal-event">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" id="modal-event2" ref="modalEventForm">
            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion">
                      {{ item[0] }}
                    </div>
                  </b-message>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de Evento*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="event.event_type_id"
                                 :options="event_types.map(type => type.id)"
                                 :custom-label="opt => event_types.find(x => x.id === opt).name"
                                 placeholder="Seleccione tipo de evento"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="N° Documento">
                  <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                    <b-input v-model="event.decree" type="text" @input="generateName"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Documento">
                  <b-datepicker
                      v-model="event.decree_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                      @input="generateName"
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Fecha Desde*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-datepicker
                        v-model="event.start_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                        @input="generateName"
                    >
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column" v-if="event.event_type_id!==2 && event.event_type_id!==21">
                <b-field label="Fecha Hasta*">
                  <ValidationProvider rules="required" v-slot="{errors}" >
                    <b-datepicker
                        v-model="event.end_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                        @input="generateName"
                    >
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>

                </b-field>
              </div>
              <div class="column" v-else>
                <b-field label="Fecha Hasta">
                    <b-datepicker
                        v-model="event.end_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                        @input="generateName"
                    >
                    </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de Documento">
                  <multiselect v-model="event.resolution_type_id"
                               :options="resolution_types.map(type => type.id)"
                               :custom-label="opt => resolution_types.find(x => x.id === opt).name"
                               placeholder="Seleccione tipo de documento"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                               :allow-empty="true"
                               @input="generateName">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
            </div>
<!--            <div class="columns">
              <div class="column">
                <b-field label="Url carpeta documentación Eventos">
                  <ValidationProvider v-slot="{ errors }" rules="max:255">
                    <b-input v-model="event.documentation_link" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>-->
            <div class="columns">
              <div class="column">
                <b-field label="Descripción*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input v-model="event.description" type="textarea" minlength="10" maxlength="1000"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>

            <div v-if="queryType == 'update'" class="columns">
              <div class="column is-4">
                <b-field label="Nombre del documento">
                  <b-input v-model="document_name_update" type="text" :disabled="true"></b-input>
                </b-field>
              </div>
              <div class="column is-4">
                <b-field label="¿Desea cambiar o agregar un archivo?">
                  <b-radio-button v-model="changeFile"
                                  :native-value="true"
                                  type="is-success is-light is-outlined">
                    <b-icon icon="check-circle"></b-icon>
                    <span>Si</span>
                  </b-radio-button>
                  <b-radio-button v-model="changeFile"
                                  :native-value="false"
                                  type="is-danger is-light is-outlined">
                    <b-icon icon="times-circle"></b-icon>
                    <span>No</span>
                  </b-radio-button>
                </b-field>
              </div>
            </div>
            <div v-if="queryType == 'create' || changeFile || queryType == 'copy'" class="columns has-text-centered">
              <div class="column">
                <b-field extended label="Archivo PDF (Tamaño max. 25 MB)">
                  <b-upload v-model="file"
                            drag-drop
                            type="is-primary"
                            accept=".pdf"
                            @input="generateName">
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon
                              icon="file-pdf"
                              size="is-large">
                          </b-icon>
                        </p>
                        <p>Arrastra tu PDF o haz click para subir.</p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>
              </div>
            </div>
            <div v-if="queryType == 'create' || changeFile || queryType == 'copy'" class="columns tags is-centered">
              <span
                  v-if="file !== null"
                  v-model="file"
                  class="tag is-primary mt-1">
                {{ file.name }}
                <button
                    class="delete is-small"
                    type="button"
                    @click="onDeleteFile"
                ></button>
              </span>
            </div>
            <div v-if="file !== null" class="columns">
              <div class="column">
                <b-field label="Nombre del documento a subir">
                  <b-input disabled v-model="document_name"></b-input>
                </b-field>
              </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit" :disabled="isDisabled">Guardar </button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "modal_event_form",
  props:[
    'queryType', 'personId', 'id', 'modalHeader', 'person_name'
  ],
  data(){
    return {
      event:{event_type_id: null, resolution_type_id: null, without_end_date: false},
      event_types:[],
      errores_validacion:[],
      isLoading:false,
      isDisabled: false,
      resolution_types: [],
      file: null,
      document_name: null,
      changeFile: false,
      document_name_update: null,
      document_id: null,
    }
  },
  async created() {
    this.isLoading=true;
    if (this.queryType === "create"){
      this.event.person_id = this.personId
      await axios
        .get("/admin/event_types.json")
        .then(res => {
          this.event_types = res.data
        }).catch(error => {
          console.log(error)
          this.isLoading=false;
        })
      await axios
          .get("/resolution_types.json")
          .then(res => {
            this.resolution_types = res.data
            this.isLoading=false;
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }
    else if (this.queryType === "update"){
      await axios
          .get("/admin/event_types.json")
          .then(res => {
            this.event_types = res.data
          }).catch(error => {
               console.log(error)
               this.isLoading=false;
          })
      await axios
          .get("/resolution_types.json")
          .then(res => {
            this.resolution_types = res.data
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })
      await axios
          .get("/admin/events/"+this.id+".json")
          .then(res => {
            this.event = res.data
            if(res.data["start_date"]!==null){
              this.event.start_date = new Date(this.formatDate(res.data["start_date"]))
            }

            if(res.data["end_date"]!==null){
              this.event.end_date =  new Date(this.formatDate(res.data["end_date"]))
            }
            if(res.data["decree_date"]!==null){
              this.event.decree_date  = new  Date(this.formatDate(res.data["decree_date"]))
            }
            this.document_id =  res.data["document_id"]
            this.document_name_update = res.data["document_name"]
            this.document_name = res.data["document_name"]
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })
      this.isLoading=false;
    }
    else if(this.queryType === "copy"){
      await axios
          .get("/admin/event_types.json")
          .then(res => {
            this.event_types = res.data
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })
      await axios
          .get("/resolution_types.json")
          .then(res => {
            this.resolution_types = res.data
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })
      await axios
          .get("/admin/events/"+this.id+"/init_copy.json", {
            params: {copy: true}
          })
          .then(res => {
            this.event = res.data
            if(res.data["start_date"]!==null){
              this.event.start_date = new Date(this.formatDate(res.data["start_date"]))
            }

            if(res.data["end_date"]!==null){
              this.event.end_date =  new Date(this.formatDate(res.data["end_date"]))
            }
            if(res.data["decree_date"]!==null){
              this.event.decree_date  = new  Date(this.formatDate(res.data["decree_date"]))
            }
            this.event.resolution = null
            this.event.decree = null
            this.event.decree_date = null
            this.event.id = null
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })
      this.isLoading=false;
    }

  },
  methods: {
    verifyDocument(){
      this.isLoading= true;
      let id = null
      if(this.queryType==="update"){
        id = this.event.id
      }
      axios
          .get("/life_record/validate_resolution_number.json", {
            params: {res_number: this.event.decree, query_type: this.queryType,
              entity_type: "event", id: id}
          })
          .then(response =>{
            this.saveEvent()
          }).catch(error => {
        if(error.response.status === 409){ //se encuentra
          this.$buefy.dialog.confirm({
            hasIcon: true,
            type: "is-warning",
            title: "Advertencia",
            message: error.response.data["message"],
            confirmText: "Continuar y guardar",
            cancelText: "Cancelar",
            onConfirm: () => this.saveEvent(),
            onCancel: () => this.isLoading = false
          })
        }
        else if(error.response.status === 400){//bad request
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalEventForm.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al agregar el evento, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
        }
      })
    },
    saveEvent(){
      this.isLoading=true;
      this.isDisabled=true;
      if(this.event.event_type_id===2 || this.event.event_type_id===21){
        this.event.without_end_date = true
      }
      if (this.queryType==="create"){

        if (this.file != null && this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Este archivo no tiene formato PDF",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        if (this.file != null && this.file.size > 1024*1024*25) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "El archivo no puede superar el maximo de 25 MB",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        /*if (this.file != null && (this.event.decree_date ==  null || this.event.decree_date ==  undefined) &&
            (this.event.decree == null || this.event.decree == undefined || this.event.decree == '')&&
            (this.event.resolution_type_id == null || this.event.resolution_type_id == undefined)) {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Para adjuntar un archivo, debe llenar al menos uno de los campos del documento",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }*/

        // Crea un objeto FormData
        let formData = new FormData();

        // Agrega datos al objeto FormData
        formData.append("event", JSON.stringify(this.event));
        formData.append("file", this.file); // Aquí agregamos el archivo
        formData.append("document_name", this.document_name);

        axios
            .post("/admin/events/create-document.json", formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
            .then(response =>{
              this.errores_validacion = {};
              this.$buefy.dialog.alert({
                message: 'El evento fue creado con éxito ',
                confirmText: 'Ok'
              })
              this.person_unit = {}
              this.isLoading=false;
              this.isDisabled=false;
              this.errors = [];
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalEventForm.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al agregar el evento, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
          this.isDisabled=false;
        })
      }
      else if (this.queryType==="update"){
        let clean_event = this.event
        clean_event = this.deleteData(clean_event)

        if (this.file != null && this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Este archivo no tiene formato PDF",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        if (this.file != null && this.file.size > 1024*1024*25) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "El archivo no puede superar el maximo de 25 MB",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        /*if (this.file != null && (this.event.decree_date ==  null || this.event.decree_date ==  undefined) &&
            (this.event.decree == null || this.event.decree == undefined || this.event.decree == '')&&
            (this.event.resolution_type_id == null || this.event.resolution_type_id == undefined)) {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Para adjuntar un archivo, debe llenar al menos uno de los campos del documento",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }*/

        // Crea un objeto FormData
        let formData = new FormData();

        // Agrega datos al objeto FormData
        formData.append("event", JSON.stringify(clean_event));
        formData.append("file", this.file); // Aquí agregamos el archivo
        formData.append("document_name", this.document_name);

        axios
            .put("/admin/events/" + this.id+"/update-document.json", formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
            .then(response => {
              this.$buefy.dialog.alert({
                message: 'El evento fue editado con éxito ',
                confirmText: 'Ok'
              })
              this.errores_validacion = {};
              this.isLoading = false;
              this.event = {}
              this.isLoading=false;
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          let elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalEventForm.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al editar el evento, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isDisabled=false;
          this.isLoading=false;
        })
      }
      else if (this.queryType==="copy"){
        if (this.file != null && this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Este archivo no tiene formato PDF",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        if (this.file != null && this.file.size > 1024*1024*25) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "El archivo no puede superar el maximo de 25 MB",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        /*if (this.file != null && (this.event.decree_date ==  null || this.event.decree_date ==  undefined) &&
            (this.event.decree == null || this.event.decree == undefined || this.event.decree == '')&&
            (this.event.resolution_type_id == null || this.event.resolution_type_id == undefined)) {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Para adjuntar un archivo, debe llenar al menos uno de los campos del documento",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }*/

        // Crea un objeto FormData
        let formData = new FormData();
        let clean_event = this.deleteData(this.event)

        // Agrega datos al objeto FormData
        formData.append("event", JSON.stringify(clean_event));
        formData.append("file", this.file); // Aquí agregamos el archivo
        formData.append("document_name", this.document_name);

        axios
            .post("/admin/events/copy-document.json", formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
            .then(response => {
              this.$buefy.dialog.alert({
                message: 'El evento fue copiado con éxito ',
                confirmText: 'Ok'
              })
              this.errores_validacion = {};
              this.isLoading = false;
              this.event = {}
              this.isLoading=false;
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          let elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalEventForm.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al copiar el evento, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isDisabled=false;
          this.isLoading=false;
        })
      }
    },
    formatDate(date){
      if(date != null && date!==""){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
    deleteData(event){
      delete event.event_types
      delete event.can_view_pdf
      delete event.document_name
      delete event.document_id
      delete event.can_copy
      delete event.can_nullify
      delete event.can_restore
      delete event.can_show
      delete event.can_edit
      delete event.resolution_types
      delete event.event_type
      delete event.event_state
      delete event.resolution_type
      delete event.establishment


      return event
    },
    onDeleteFile() {
      this.file = null;
    },
    generateName(){
      var tipo = "EVENTO"
      var number = ""
      var date = ""
      var start_date = ""
      var end_date = ""
      var full_name = "-"+this.person_name

      if (this.event.resolution_type_id!= null){
        var nombre_tipo = this.resolution_types.find(x => x.id === this.event.resolution_type_id).name
        if(nombre_tipo.includes("RESOLUCION")){
          tipo = "RES"
        }
        else if(nombre_tipo.includes("DECRETO")){
          tipo = "DECR"
        }
        //tipo = this.resolution_types.find(x => x.id === this.person_unit.resolution_type_id).name
        //tipo = this.documentTypesNames.find(type => type.id === this.document.document_type_id).initials
      }

      if (this.event.decree != null && this.event.decree != ''){
        number = "-N_" + this.event.decree
      }

      if (this.event.decree_date != null){
        date = "-"+("0" + this.event.decree_date.getDate()).slice(-2)
            + "_" + ("0" + (this.event.decree_date.getMonth()+1)).slice(-2)
            + "_" + this.event.decree_date.getFullYear()
      }

      if (this.event.start_date != null){
        start_date = "-"+("0" + this.event.start_date.getDate()).slice(-2)
            + "_" + ("0" + (this.event.start_date.getMonth()+1)).slice(-2)
            + "_" + this.event.start_date.getFullYear()
      }

      if (this.event.end_date != null){
        end_date = "-"+("0" + this.event.end_date.getDate()).slice(-2)
            + "_" + ("0" + (this.event.end_date.getMonth()+1)).slice(-2)
            + "_" + this.event.end_date.getFullYear()
      }

      if ((this.event.decree_date == null || this.event.decree_date == '')  &&
          (this.event.decree == null || this.event.decree == '')){
        tipo = "EVENTO"
        this.document_name = tipo + full_name + start_date + end_date
      }
      else{
        this.document_name = tipo + number + date
      }
    },
  }
}
</script>

