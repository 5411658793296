<template>
  <section>
    <div class="box">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
          <b-message type="is-danger" has-icon>
            <div v-for="(item, index) in errores_validacion">
              {{ item[0] }}
            </div>
          </b-message>
        </div>
        <form @submit.prevent="handleSubmit(savePublication)">
          <section v-if="isUpdatingAndDeletingParticipant(false) != undefined" class="modal-card-body"
            ref="modalPersonForm">
            <b-steps ref="publicationsteps" v-model="activeStep" :animated="isAnimated" :rounded="isRounded"
              :has-navigation="hasNavigation" :icon-prev="prevIcon" :icon-next="nextIcon" :label-position="labelPosition"
              :mobile-mode="mobileMode">
              <b-step-item v-if="isUpdatingAndDeletingParticipant(false)" step="1" label="General"
                :clickable="isStepsClickable">
                <h1 class="title has-text-centered">General [1/2]</h1>
                <div class="columns">
                  <div class="column is-4 is-offset-8">
                    <b-switch v-model="requiredFieldOnly">Mostrar todos los campos del formulario</b-switch>
                  </div>
                </div>
                <div class="columns is-centered">
                  <div class="column is-narrow">
                    <keep-alive><bibtex-input @fill-publication="fillPublication"></bibtex-input></keep-alive>
                  </div>
                  <div class="column is-narrow">
                    <keep-alive><doi-search @fill-publication="fillPublication"></doi-search></keep-alive>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Título*">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-input v-model="publication.title" type="text"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
<!--                  <div class="column">
                    <b-field label="Tipo de Publicación*">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect v-model="publication.publication_type_id"
                          :options="tipos_publicacion.map(type => type.id)"
                          :custom-label="opt => tipos_publicacion.find(x => x.id == opt).name" @input="updateInputs"
                          placeholder="Seleccione tipo de publicación" selectLabel="Presione para seleccionar"
                          selectedLabel="Seleccionado" deselectLabel="No se puede deseleccionar" :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                            No se encontraron elementos.
                          </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>-->
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Fecha de Publicación*">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-datepicker v-model="publication.publication_date" icon="calendar-alt" trap-focus locale="es-ES"
                          editable placeholder="Formato de Fecha: dd/mm/aaaa">
                        </b-datepicker>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="DOI">
                      <b-input v-model="publication.doi" type="text" expanded></b-input>
                      <p class="control">
                        <b-button tag="a" :href="'https://doi.org/' + publication.doi" target="_blank"
                          icon-left="external-link-alt" type="is-link">Link</b-button>
                      </p>
                    </b-field>
                  </div>
                  <div class="column" v-if="requiredFieldOnly">
                    <b-field label="ISSN">
                      <b-input v-model="publication.issn" type="text"></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <div class="field">
                      <b-field label="Resumen">
                        <b-input type="textarea" maxlength="4000" v-model="publication.summary">
                        </b-input>
                      </b-field>
                    </div>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <h3 class="is-size-6 has-text-weight-bold">
                      Autores*
                      <b-tooltip
                        label="En esta sección debe ingresar en un campo de texto las y los autores de la publicación en el orden deseado, solo para su visualización.
                                Este campo de texto es solo referencial.
                                Ej de texto:'Shaoshuai Wang, Lili Guo, Ling Chen, Weiyong Liu, Yong Cao, Jingyi Zhang and Ling Feng'"
                        position="is-right" size="is-large" multilined>
                        <b-icon pack="fas" icon="info-circle" size="is-small" type="is-info">
                        </b-icon>
                      </b-tooltip>
                    </h3>
                    <b-field label=" ">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-input v-model="publication.participants_references" type="text" maxlength="255"
                          :has-counter="false"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <h3 class="is-size-6 has-text-weight-bold">Palabras Claves
                      <b-tooltip label="Presione Enter o Coma luego de escribir la palabra clave para que sea agregada"
                        position="is-right" size="is-medium" multilined>

                        <b-icon pack="fas" icon="info-circle" size="is-small" type="is-info">
                        </b-icon>
                      </b-tooltip>
                    </h3>
                    <b-field label=" ">
                      <b-taginput ref="keywordinput" v-model="publication.key_words_attributes"
                        :data="palabras_claves_filtradas" :allow-new="true" :allow-duplicates="false" autocomplete
                        field="name" icon="tag" placeholder="Agrega una palabra clave" :beforeAdding="beforeAddTag"
                        @typing="getFilteredTags">
                      </b-taginput>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column" v-if="requiredFieldOnly">
                    <b-field label="Título Corto">
                      <b-input v-model="publication.short_title" type="text"></b-input>
                    </b-field>
                  </div>
                  <div class="column" v-if="requiredFieldOnly">
                    <b-field label="Serie">
                      <b-input v-model="publication.serie" type="text"></b-input>
                    </b-field>
                  </div>
                  <div class="column" v-if="requiredFieldOnly">
                    <b-field label="Título Serie">
                      <b-input v-model="publication.serial_title" type="text"></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column" v-if="requiredFieldOnly">
                    <b-field label="Texto Serie">
                      <b-input v-model="publication.serial_text" type="text"></b-input>
                    </b-field>
                  </div>
                  <div class="column" v-if="requiredFieldOnly">
                    <b-field label="Archivo">
                      <b-input v-model="publication.archive" type="text"></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column" v-if="requiredFieldOnly">
                    <b-field label="Posición en Archivo">
                      <b-input v-model="publication.archive_position" type="text"></b-input>
                    </b-field>
                  </div>
                  <div class="column" v-if="requiredFieldOnly">
                    <b-field label="Catálogo en Biblioteca">
                      <b-input v-model="publication.library_catalog" type="text"></b-input>
                    </b-field>
                  </div>
                  <div class="column" v-if="requiredFieldOnly">
                    <b-field label="Materia">
                      <b-input v-model="publication.signature" type="text"></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column" v-if="requiredFieldOnly">
                    <b-field label="Copyrights">
                      <b-input v-model="publication.copyright" type="text"></b-input>
                    </b-field>
                  </div>
                  <div class="column" v-if="requiredFieldOnly">
                    <b-field label="Adicional">
                      <b-input v-model="publication.additional" type="text"></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns">

                  <div v-if="requiredMagazine" class="column">
                    <b-field label="Revista*">
                      <template #label>
                        Nombre Lugar de Publicación (revista ,conferencia, etc...)*
                      </template>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-autocomplete v-model="magazine_name" :keep-first="keepFirst" :open-on-focus="openOnFocus"
                          :data="revistas_filtradas" field="name" empty="Vacio" @typing="getRevistasFiltradas"
                          @select="option => { if (option) { publication.magazine_id = option.id } }"
                          :clearable="clearable">
                          <template slot-scope="props">
                            <div>
                              {{ props.option.name }}
                            </div>
                          </template>
                          <template #empty>No se encontraron resultados</template>
                        </b-autocomplete>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div v-if="!requiredMagazine" class="column">
                    <b-field label="Revista">
                      <template #label>
                        Nombre Lugar de Publicación (revista ,conferencia, etc...)
                      </template>
                      <b-autocomplete v-model="magazine_name" :keep-first="keepFirst" :open-on-focus="openOnFocus"
                        :data="revistas_filtradas" field="name" empty="Vacio" @typing="getRevistasFiltradas"
                        @select="option => { if (option) { publication.magazine_id = option.id } }"
                        :clearable="clearable">
                        <template slot-scope="props">
                          <div>
                            {{ props.option.name }}
                          </div>
                        </template>
                        <template #empty>No se encontraron resultados</template>
                      </b-autocomplete>
                    </b-field>
                  </div>
                  <div v-if="publication.magazine_id == null || magazine_name == ''" class="column is-narrow">
                    <br>
                    <b-field label=" ">
                      <b-tooltip
                        label="Si el lugar de publicación que desea agregar no existe, la puede agregar presionando el botón +"
                        multilined position="is-left">
                        <b-button class="is-primary is-outlined" @click="addMagazine()">
                          <b-icon icon="plus"></b-icon>
                        </b-button>
                      </b-tooltip>
                    </b-field>
                  </div>
                  <div v-else class="column is-narrow">
                    <br>
                    <b-field label=" ">
                      <b-tooltip label="Al presionar el botón, podrá editar la información del lugar de publicación"
                        multilined position="is-left">
                        <b-button class="is-success is-outlined" @click="editMagazine()">
                          <b-icon icon="pen"></b-icon>
                        </b-button>
                      </b-tooltip>
                    </b-field>
                  </div>
                  <div class="column is-4">
                    <b-field label="Cuartil">
                      <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                        <div class="field has-addons">
                          <p class="control">
                            <b-radio-button v-model="publication.quartile" :native-value="1"
                              type="is-success is-light is-outlined is-narrow">
                              <span>Q1</span>
                            </b-radio-button>
                          </p>
                          <p class="control">
                            <b-radio-button v-model="publication.quartile" :native-value="2"
                              type="is-success is-light is-outlined">
                              <span>Q2</span>
                            </b-radio-button>
                          </p>
                          <p class="control">
                            <b-radio-button v-model="publication.quartile" :native-value="3"
                              type="is-success is-light is-outlined">
                              <span>Q3</span>
                            </b-radio-button>
                          </p>
                          <p class="control">
                            <b-radio-button v-model="publication.quartile" :native-value="4"
                              type="is-success is-light is-outlined">
                              <span>Q4</span>
                            </b-radio-button>
                          </p>
                        </div>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                </div>

                <div class="columns">
                  <div class="column">
                    <b-field label="Página de inicio">
                      <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                        <b-input v-model="publication.start_page"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Página de término">
                      <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                        <b-input v-model="publication.end_page"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Volumen de la revista">
                      <b-input v-model="publication.volumen" type="text"></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="¿Es SciELO?">
                      <b-radio-button v-model="publication.is_scielo" :native-value="false"
                        type="is-danger is-light is-outlined">
                        <b-icon icon="times-circle"></b-icon>
                        <span>No</span>
                      </b-radio-button>
                      <b-radio-button v-model="publication.is_scielo" :native-value="true"
                        type="is-success is-light is-outlined">
                        <b-icon icon="check-circle"></b-icon>
                        <span>Si</span>
                      </b-radio-button>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="¿Es Scopus?">
                      <b-radio-button v-model="publication.is_scopus" :native-value="false"
                        type="is-danger is-light is-outlined">
                        <b-icon icon="times-circle"></b-icon>
                        <span>No</span>
                      </b-radio-button>
                      <b-radio-button v-model="publication.is_scopus" :native-value="true"
                        type="is-success is-light is-outlined">
                        <b-icon icon="check-circle"></b-icon>
                        <span>Si</span>
                      </b-radio-button>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="¿Es Wos?">
                      <b-radio-button v-model="publication.is_wos" :native-value="false"
                        type="is-danger is-light is-outlined">
                        <b-icon icon="times-circle"></b-icon>
                        <span>No</span>
                      </b-radio-button>
                      <b-radio-button v-model="publication.is_wos" :native-value="true"
                        type="is-success is-light is-outlined">
                        <b-icon icon="check-circle"></b-icon>
                        <span>Si</span>
                      </b-radio-button>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="¿Es Wos Emergente?">
                      <b-radio-button v-model="publication.is_emergent_wos" :native-value="false"
                        type="is-danger is-light is-outlined">
                        <b-icon icon="times-circle"></b-icon>
                        <span>No</span>
                      </b-radio-button>
                      <b-radio-button v-model="publication.is_emergent_wos" :native-value="true"
                        type="is-success is-light is-outlined">
                        <b-icon icon="check-circle"></b-icon>
                        <span>Si</span>
                      </b-radio-button>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="¿Es multidisciplinaria?">
                      <b-radio-button v-model="publication.multidisciplinary" :native-value="false"
                        type="is-danger is-light is-outlined">
                        <b-icon icon="times-circle"></b-icon>
                        <span>No</span>
                      </b-radio-button>
                      <b-radio-button v-model="publication.multidisciplinary" :native-value="true"
                        type="is-success is-light is-outlined">
                        <b-icon icon="check-circle"></b-icon>
                        <span>Si</span>
                      </b-radio-button>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Link de la publicación*">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-input v-model="publication.url"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
              </b-step-item>
              <b-step-item step="2" label="Participantes" :clickable="isStepsClickable">
                <h1 class="title has-text-centered">Participantes [2/2]
                  <b-tooltip label="En esta sección debe ingresar las(os) participantes del proyecto que
                                  se encuentren registrados en el sistema, de esta manera, los datos ingresados
                                   de esta patente quedarán asociados a esta persona." position="is-right"
                    size="is-medium" multilined>
                    <b-icon pack="fas" icon="info-circle" size="is-small" type="is-info">
                    </b-icon>
                  </b-tooltip>
                </h1>
                <div v-if="queryType == 'update'" class="columns is-centered">
                  <div class="column is-centered has-text-centered">
                    <b-message title="Información importante" type="is-warning" aria-close-label="Cerrar mensaje">
                      Solo la/el usuaria/o que es Dueña o Dueño de esta actividad académica tiene permisos para editar
                      todos
                      sus atributos.<br>
                      Quienes participan en la actividad académica pero no son dueños, solo pueden editar su
                      participación.
                      <br><span v-if="publication.creator_name">Origen de la información: {{ publication.creator_name
                      }}</span>
                    </b-message>
                  </div>
                </div>
                <div v-if="isUpdatingAndDeletingParticipant(false)" class="is-pulled-right">
                  <b-button class="mt-2" @click.prevent="addParticipant(publicationId)" type="is-primary">Administrar
                    participantes</b-button>
                </div>
                <div class="column">
                  <h3 class="is-size-4 has-text-weight-bold">Tabla Resumen Participantes</h3>
                  <b-table v-if="showParticipantTable" :key="tableDataKey" :data="participantes" :paginated=true
                    per-page=5 striped hoverable :current-page="currentPage" :card-layout="cardLayout"
                    :pagination-simple=false pagination-position="bottom" default-sort-direction="asc"
                    sort-icon="arrow-up" sort-icon-size="is-small" default-sort="publication.title"
                    aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
                    aria-current-label="Página actual">
                    <b-table-column field="name" label="Nombres" width="13em" sortable searchable>
                      <template slot="searchable" slot-scope="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder=" Buscar" icon="search"
                          size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.name }}
                      </template>
                    </b-table-column>
                    <b-table-column field="unit" label="Unidad" width="13em" sortable searchable>
                      <template slot="searchable" slot-scope="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder=" Buscar" icon="search"
                          size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.unit }}
                      </template>
                    </b-table-column>
                    <b-table-column width="15em" field="participant_type" label="Tipo de participación*" v-slot="props">
                      <ValidationProvider v-if="isUpdatingAndDeletingParticipant(props.row)" rules="required"
                        v-slot="{ errors }">
                        <multiselect v-model="props.row.participant_type_id" :options="tipos_participacion.map(x => x.id)"
                          :custom-label="opt => tipos_participacion.find(x => x.id == opt).name"
                          placeholder="Seleccione tipo de participación" selectLabel="Presione para seleccionar"
                          selectedLabel="Seleccionado" deselectLabel="No se puede deseleccionar" :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                            No se encontraron elementos.
                          </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-table-column>
                    <b-table-column field="is_doc_student" label="¿Es estudiante de doctorado?" width="9em"
                      v-slot="props">
                      <b-checkbox v-if="isUpdatingAndDeletingParticipant(props.row)" v-model="props.row.is_doc_student">
                      </b-checkbox>
                    </b-table-column>
                    <b-table-column field="filiation" label="Filiación" centered v-slot="props">
                      <ValidationProvider v-if="isUpdatingAndDeletingParticipant(props.row)" v-slot="{ errors }">
                        <b-tooltip v-if="isUpdatingAndDeletingParticipant(props.row) && !props.row.partner_entity_id"
                          multilined
                          :label="'Presione para añadir instantaneamente a la Universidad de Santiago de Chile como Institución de Filiación'">
                          <b-button v-if="isUpdatingAndDeletingParticipant(props.row) && !props.row.partner_entity_id"
                            @click.prevent="addUsachFilitation(props.row)" v-model="props.row.partner_entity"
                            style="background-color:#EF7D00;color:#ffffff">
                            <span class="has-text-weight-bold">+ </span>USACH
                          </b-button>
                        </b-tooltip>

                        <b-button v-if="isUpdatingAndDeletingParticipant(props.row) && !props.row.partner_entity_id"
                          @click.prevent="AddFiliation(props.row)" type="is-info" v-model="props.row.partner_entity"
                          icon-right="plus" />
                        <div v-if="isUpdatingAndDeletingParticipant(props.row) && props.row.partner_entity_id > 0"
                          class="columns">
                          <div class="column is-4">
                            {{ props.row.partner_entity ? props.row.partner_entity.name : "" }}
                          </div>
                          <div class="column is-narrow is-pulled-left">
                            <b-button @click.prevent="AddFiliation(props.row)" v-model="props.row.partner_entity"
                              type="is-success" icon-right="edit" />
                            <b-button @click.prevent="DeleteFiliation(props.row)" v-model="props.row.partner_entity"
                              type="is-danger" icon-right="times" />
                          </div>
                        </div>
                        <div><span class="validation-alert">{{ errors[0] }}</span></div>


                      </ValidationProvider>

                    </b-table-column>
                    <b-table-column label="Acciones" width="10" v-slot="props">
                      <b-icon v-if="isUpdatingAndDeletingParticipant(props.row)" pack="fas" icon="trash"
                        style="color: #e50000;" size="is-medium" @click.native="deleteParticipantButton(props.row)" />
                    </b-table-column>
                    <template slot="empty" slot-scope="props">
                      <div class="columns is-centered">
                        <div class="column is-6 is-centered has-text-centered">
                          <b-message type="is-danger">
                            No se encontraron participantes asociados.
                          </b-message>
                        </div>
                      </div>
                    </template>
                  </b-table>
                </div>
                <hr class="dropdown-divider" aria-role="menuitem">
                <div class="columns" v-if="queryType === 'update'">
                  <div class="column" v-if="publication.is_creator">
                    <h3 class="is-size-6 has-text-weight-bold">
                      Dueña(o)*
                      <b-tooltip
                        label="Debe seleccionar al menos una Dueña o Dueño del dato que se encuentre como participante en la actividad académica. Si realiza el cambio, no podrá revertirlo."
                        position="is-right" size="is-large" multilined>
                        <b-icon pack="fas" icon="info-circle" size="is-small" type="is-info">
                        </b-icon>
                      </b-tooltip>
                    </h3>
                    <b-field label=" ">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect v-model="ownerId" :options="participantes.map(type => type.person_id)"
                          :custom-label="opt => participantes.find(x => x.person_id === opt).name"
                          placeholder="Seleccione Dueña(o)" selectLabel="Presione para seleccionar"
                          selectedLabel="Seleccionado" deselectLabel="" :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                            No se encontraron elementos.
                          </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
                <div class="columns" v-else>
                  <div class="column">
                    <h3 class="is-size-6 has-text-weight-bold">
                      Dueña(o)*
                      <b-tooltip
                        label="Debe seleccionar al menos una Dueña o Dueño del dato que se encuentre como participante en la actividad académica. Si realiza el cambio, no podrá revertirlo."
                        position="is-right" size="is-large" multilined>
                        <b-icon pack="fas" icon="info-circle" size="is-small" type="is-info">
                        </b-icon>
                      </b-tooltip>
                    </h3>
                    <b-field label=" ">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect v-model="ownerId" :options="participantes.map(type => type.person_id)"
                          :custom-label="opt => participantes.find(x => x.person_id === opt).name"
                          placeholder="Seleccione Dueña(o)" selectLabel="Presione para seleccionar"
                          selectedLabel="Seleccionado" deselectLabel="" :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                            No se encontraron elementos.
                          </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
              </b-step-item>

              <template #navigation="{ previous, next }">
                <div class="columns is-pulled-right">
                  <div class="column">
                    <b-button outlined icon-left="chevron-left" :disabled="previous.disabled"
                      @click.prevent="previous.action">
                    </b-button>
                    <b-button outlined icon-right="chevron-right" :disabled="next.disabled" @click.prevent="next.action">
                    </b-button>

                  </div>
                </div>
              </template>

            </b-steps>
          </section>

          <br>
          <div class="columns">
            <div class="column">
              <div class="is-pulled-right">
                <button v-if="modalCancel" class="button is-danger" @click.prevent="$emit('close')">Cancelar</button>
                <button class="button is-primary" type="submit" @click="checkValidation()">Guardar Publicación</button>
              </div>
            </div>
          </div>
          <!-- Fin Box Formulario-->
        </form>
      </ValidationObserver>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
      <!-- MODALES -->
      <b-modal v-model="isAddModalActive" has-modal-card trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
        :can-cancel="canCancel">
        <template #default="props">
          <modal-participants-publications query-type="update" modal-header="Añadir participantes"
            :participantes="participantes" :tipos_participacion="tipos_participacion" :publication="publication"
            @close="props.close" @clicked="onClickChild"></modal-participants-publications>
        </template>
      </b-modal>
      <b-modal v-model="isModalMagazineActive" has-modal-card trap-focus :destroy-on-hide="true" aria-role="dialog"
        aria-modal :can-cancel="canCancel">
        <template #default="props">
          <new-magazine-modal modal-header="Añadir Lugar de Publicación" @close="props.close"
            @clicked="onClickChildMagazine" v-bind:query-type="magazineQueryType" :update-magazine="updateMagazineId">
          </new-magazine-modal>
        </template>
      </b-modal>

      <add-participant-filiation modal-header="Añadir Filiación a Participante" @close="closeFiliationModal"
        @setFilitation="setParticipantFilitation" :partner_entity="currentEntity" :participant="currentParticipantEntity"
        :active-modal="activeAddFiliationModal"></add-participant-filiation>
    </div>

  </section>
</template>

<script>
import axios from "axios";
import BibtexInput from "./bibtex_input.vue";
import { bus } from "../../packs/app_vue";
import ModalParticipantsForm from "./modal_participants_publications.vue";
import moment from "moment";
import NewMagazineModal from "./NewMagazineModal";
import DoiSearch from "./DoiSearch";
import AddParticipantFiliation from "../filiations/addParticipantFiliation";

export default {
  name: "NewPublicationForm",
  components: {
    AddParticipantFiliation,
    DoiSearch,
    NewMagazineModal,
    BibtexInput,
    'modal-participants-publications': ModalParticipantsForm
  },
  data() {
    return {
      isModalMagazineActive: false,
      activeStep: 0,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      cardLayout: false,
      hasNavigation: true,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      isLoading: false,
      errores_validacion: {},
      canCancel: ['escape'],
      magazine_name: null,
      keepFirst: false,
      openOnFocus: false,
      clearable: false,
      requiredMagazine: true,
      skip_if_magister: false,

      publication: {
        participants_attributes: [],
        ocde_sub_areas_attributes: [],
        disciplines_attributes: [],
        key_words_attributes: [],
        validation_attributes: {},
        magazine_id: null,
        magazine: {}
      },
      participants: [],
      participantes: [],
      unidades: [],
      ocde_sub_area: [],
      ocde_sub_areas: [],
      ocde_sub_areas_filtradas: [],
      disciplines: [],
      disciplines_filtradas: [],
      tipos_participacion: [],
      tipos_publicacion: [],
      revistas: [],
      revistas_filtradas: [],
      palabras_claves: [],
      palabra_clave: null,
      palabras_claves_filtradas: [],
      requiredFieldOnly: false,
      isAddModalActive: false,
      tableDataKey: 0,
      currentPage: 1,
      activeAddFiliationModal: false,
      currentEntity: {},
      currentParticipantEntity: {},
      ownerId: null,
      usach_filitation: {},
      showParticipantTable: true,
      magazine_types: [],
      doiPublicationFinded: null,
      magazineQueryType: "create",
      updateMagazineId: null
    }
  },
  watch: {
    isAddModalActive: function () {
      if (!this.isAddModalActive) {
        this.initialize()
      }
    },
  },
  props: ['userId', 'queryType', 'publicationId', 'cargoPrincipal', 'unidadPrincipal', 'personId', 'modalCancel', 'orcidPublication', "showParticipantsFirst",
    'responseType', 'fromAcademic'],

  created() {
    bus.$on('fill-publication', this.fillPublication);
    this.isLoading = true;
    axios.get("/publications/new.json")
      .then(res => {
        this.tipos_publicacion = res.data['tipos_publicacion'];
        this.tipos_participacion = res.data['tipos_participacion'];
        this.unidades = res.data['unidades'];
        this.ocde_sub_areas = res.data['ocde_sub_areas'];
        this.ocde_sub_areas_filtradas = res.data['ocde_sub_areas'];
        this.revistas = res.data['revistas'];
        this.revistas_filtradas = res.data['revistas'];
        this.disciplines = res.data['disciplines'];
        this.disciplines_filtradas = res.data['disciplines'];
        this.usach_filitation = res.data['usach_filitation'];
        this.magazine_types = res.data['magazine_types'];
        this.publication.publication_type_id = 1
        if (this.queryType === "create" && this.cargoPrincipal.name === "ACADEMICO") {
          let participant_type = null
          var coautor = this.tipos_participacion.filter(obj => {
            return obj.name == "CO-AUTOR"
          })
          if (coautor) {
            participant_type = coautor[0].id;
          }

          this.participantes.push({
            person_id: res.data["participante"].person_id,
            name: res.data["participante"].person_name,
            alias: res.data["participante"].person_alias != null ? res.data["participante"].person_alias.toUpperCase() : "",
            corporate_mail: res.data["participante"].corporate_mail != null ? res.data["participante"].corporate_mail.toUpperCase() : "",
            unit: res.data["participante"].unit_name,
            unit_id: res.data["participante"].unit_id,
            participant_type_id: participant_type,
          })
          //se añade un participante automaticamente si es que es acádemico
          //if(this.cargoPrincipal.name=="ACADEMICO" || this.cargoPrincipal.name=="HORAS CLASE"){
          // this.publication.participants_attributes.push({
          //  person_id:this.userId,
          // participant_type_id:null,
          // unit_id:this.unidadPrincipal.id,
          // unit_participants:[],
          // _destroy:null
          //})
          //if (this.publication.participants_attributes[0].unit_id != null) {
          // axios
          //    .get("/get_people_by_unit.json", {
          //     params: {unit_id: this.publication.participants_attributes[0].unit_id}
          //  })
          // .then(res => {
          //  this.publication.participants_attributes[0].unit_participants = res.data['personas'];
          // this.publication.participants_attributes[0].person_id=this.personId;
          //}).catch(error => {
          // this.isLoading = false;
          // console.log(error)
          //})
          //}

        }
        this.isLoading = false;
        if (this.orcidPublication) {
          this.publication = this.orcidPublication;
          if (this.orcidPublication.magazine_id) {
            //this.publication.magazine_id = newData.magazine_id;
            this.magazine_name = this.orcidPublication.magazine;
          }
          if (this.publication.participants_attributes) {
            this.setParticipants();
            this.ownerId = this.publication.participants_attributes[0].person_id;
          }
        }
        if (this.queryType == "update") {
          this.isLoading = true;
          axios
            .get("/publications/" + this.publicationId + ".json", { params: { edit_flag: true } })
            .then(res => {
              this.publication = res.data
              this.setParticipants();
              if (this.publication.publication_date != null && this.publication.publication_date != undefined)
                this.publication.publication_date = new Date(moment(this.publication.publication_date).format('MM/DD/YYYY'));
              if (this.publication.validation_attributes == null) {
                this.publication.validation_attributes = {}
                this.publication.data_source = ""
              }
              this.publication.magazine_id = this.publication.magazine_id;
              this.magazine_name = this.publication.magazine;

              var type_id = this.publication.publication_type_id
              if (type_id == 4 || type_id == 6 || type_id == 7 || type_id == 8 || type_id == 9 || type_id == 10 || type_id == 11 || type_id == 12 || type_id == 13) {
                this.requiredMagazine = false
                this.skip_if_magister = true
              }
              else {
                this.requiredMagazine = true
                this.skip_if_magister = false
              }
              if (this.participantes.filter(person => person.person_id == this.publication.ownerId).length > 0) {
                this.ownerId = this.publication.ownerId
              }
              if (this.showParticipantsFirst) {
                this.activeStep = 1;
              }
              this.isLoading = false;

            })
            .catch(e => {
              console.log(e);
              this.isLoading = false;
            });
        }
      })
      .catch(e => {
        console.log(e);
        this.isLoading = false;
      });
  },
  methods: {
    initialize() {

    },
    onClickChild(value) {
      this.isLoading = true
      this.participantes = value
      let participant_type = null
      var coautor = this.tipos_participacion.filter(obj => {
        return obj.name == "CO-AUTOR"
      })
      if (coautor) {
        participant_type = coautor[0];
      }
      this.participantes.forEach(function (participante) {
        if (!participante.participant_type_id && participant_type) {
          participante.participant_type_id = participant_type.id
        }

      })
      this.isLoading = false

    },
    onClickChildMagazine(value) {
      this.isLoading = true
      axios
        .get('/magazines.json')
        .then(response => {
          this.revistas = response.data
          this.revistas_filtradas = response.data
          this.publication.magazine_id = value.id;
          this.magazine_name = value.name;
          this.isModalMagazineActive = false
        }).catch(error => {
          console.log(error)
          this.isLoading = false
        })
      this.isLoading = false
    },
    getMagazine() {
      this.isLoading = true
      axios
        .get('/magazines.json')
        .then(response => {
          this.revistas = response.data
          this.revistas_filtradas = response.data
        }).catch(error => {
          console.log(error)
          this.isLoading = false
        })
    },
    addMagazine() {
      this.magazineQueryType = "create";
      this.updateMagazineId = null;
      this.isModalMagazineActive = true;
    },
    async savePublication() {
      const check_doi = await this.findPublicationByDoi();
      if (check_doi) {
        let person_link = "";
        if (this.doiPublicationFinded.is_participant) {
          person_link = "<br><a href='/publications/" + this.doiPublicationFinded.publication.id + "' target='_blank'>Ver publicación</a> "
        }
        return this.$buefy.dialog.alert({
          message: 'Este DOI ya existe en el sistema y pertenece a la siguiente publicación: <br>' +
            '<br> Título: ' + this.doiPublicationFinded.publication.title + ' ' +
            '<br> DOI: ' + this.doiPublicationFinded.publication.doi +
            '<br> Dueña/o: ' + this.doiPublicationFinded.origin_person_formatted.name +
            person_link +
            '<br><br>Por favor revisar',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
          style: "width:200%"
        })
      }

      this.publication.participants_attributes = []
      var index = 0
      for (var i = 0; i < this.publication.key_words_attributes.length; i++) {
        if (!(typeof this.publication.key_words_attributes[i] === 'object' && this.publication.key_words_attributes[i] !== null)) {
          var name = this.publication.key_words_attributes[i];
          this.publication.key_words_attributes[i] = new Object();
          this.publication.key_words_attributes[i].name = name;
          this.publication.key_words_attributes[i].id = null;
        }
      }
      for (const participante of this.participantes) {
        this.publication.participants_attributes.push({
          person_id: null,
          participant_type_id: null,
          unit_id: null,
          unit_participants: [],
          _destroy: null
        })
        this.publication.participants_attributes[index].person_id = participante.person_id
        this.publication.participants_attributes[index].is_doc_student = participante.is_doc_student
        this.publication.participants_attributes[index].participant_type_id = participante.participant_type_id
        this.publication.participants_attributes[index].unit_id = participante.unit_id
        this.publication.participants_attributes[index].partner_entity_id = participante.partner_entity_id
        index = index + 1
      }
      this.isLoading = true;
      var retorno = JSON.parse(JSON.stringify(this.publication.participants_attributes));

      if (this.checkMagazine()) {
        this.publication.academic_activity_state_id = 1
        this.publication.origin_participant_type_id = 1
        if (this.queryType === "create") {
          if (this.skip_if_magister == true) {
            this.publication.skip_if_magister = true
          }
          this.publication.academic_activity_state_id = 1
          axios
            .post("/publications.json", {
              publication: this.publication,
              participants: retorno.map(function (x) {
                delete x.unit_id;
                delete x.unit_participants;
                return x;
              }),
              user_id: this.userId,
              data_source: this.publication.validation_attributes.data_source,
              ownerId: this.ownerId
            })
            .then(response => {
              this.errores_validacion = {};
              if (this.orcidPublication) {
                this.$emit("publicationAdded", response.data);
              }
              else {
                window.location.href = '/publications/' + response.data["id"]
              }
              this.isLoading = false;
            }).
            catch(error => {
              this.errores_validacion = error.response.data
              var elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              this.isLoading = false;
            })
        }
        if (this.queryType === "update") {
          if (this.skip_if_magister == true) {
            this.publication.skip_if_magister = true
          }
          axios
            .put("/publications/" + this.publicationId + ".json", {
              publication: this.publication,
              participants: retorno.map(function (x) {
                delete x.unit_id;
                delete x.unit_participants;
                return x;
              }),
              user_id: this.userId,
              data_source: this.publication.validation_attributes.data_source,
              ownerId: this.ownerId
            })
            .then(response => {
              this.errores_validacion = {};
              if (this.responseType == "RETORNO") {
                this.$emit("response", response.data);
              }
              else {
                if (this.fromAcademic) {
                  this.$emit('refresh')
                  this.$emit('close')
                }else{
                  window.location.href = '/publications/' + this.publicationId
                }
              }

              this.isLoading = false;
            }).
            catch(error => {
              this.errores_validacion = error.response.data
              var elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              this.isLoading = false;
            })
        }
      }
    },
    addParticipant(id) {
      this.selected = id;
      this.isAddModalActive = true
    },
    deleteParticipant(index) {
      this.publication.participants_attributes.splice(index, 1)
    },

    getFilteredTags(text) {
      this.isLoading = true;
      if (text.length >= 2) {
        axios
          .get("/publications/filter_keywords/" + text.toUpperCase() + ".json")
          .then(res => {
            this.palabras_claves_filtradas = res.data['keywords'];
            this.isLoading = false;
          }).
          catch(error => {
            this.isLoading = false;
            //console.log(error)
          })
      }
      else {
        this.palabras_claves_filtradas = []
        this.isLoading = false;
      }
    },

    deleteParticipantsPub(participants) {
      if (participants.person_id == this.ownerId) {
        this.ownerId = null
      }

      this.participantes = this.participantes.filter(person => person.corporate_mail != participants.corporate_mail);
    },
    findUnitParticipants(index) {
      if (this.publication.participants_attributes[index].unit_id != null) {
        this.isLoading = true;
        axios
          .get("/get_people_by_unit.json", {
            params: { unit_id: this.publication.participants_attributes[index].unit_id }
          })
          .then(res => {
            this.publication.participants_attributes[index].unit_participants = res.data['personas'];
            this.isLoading = false;
          }).
          catch(error => {
            this.isLoading = false;
            console.log(error)
          })
      }
    },
    getFilteredOcdeSubAreas(text) {
      this.ocde_sub_areas_filtradas = this.ocde_sub_areas.filter((option) => {
        return option.name
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
      })
    },
    getFilteredDisciplines(text) {
      this.disciplines_filtradas = this.disciplines.filter((option) => {
        return option.name
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
      })
    },
    async getRevistasFiltradas(text) {
      this.isLoading = true;
      await axios
        .get("/magazines/search.json", {
          params: { name: text, }
        })
        .then(res => {
          this.revistas = res.data;
          this.publication.magazine_id = null

          this.revistas_filtradas = this.revistas.filter((option) => {
            let returnOption = option.name
              .toString()
              .toLowerCase()
              .indexOf(text.toLowerCase()) >= 0
            return returnOption;
          }).slice(0, 30)


          setTimeout(() => {
            this.isLoading = false
          }, 3000);
        }).
        catch(error => {
          this.isLoading = false;
          //console.log(error)
        })



    },
    async checkValidation() {
      const valid = await this.$refs.observer.validate()
      //console.log(valid2)
      if (!valid) {
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar tanto la pestaña General como la de Participantes.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
    checkMagazine() {
      if (this.skip_if_magister == true) {
        return true
      }
      else if (this.publication.magazine_id == null) {
        this.$buefy.dialog.alert({
          message: 'Error en campo revista. Revisar que la revista exista',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = false
        return false
      }
      else {
        return true
      }
    },
    formatDate(inputDate) {
      // Verificar si el inputDate ya está en formato aaaa-mm-dd
      if (typeof inputDate === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(inputDate)) {
        return inputDate;
      }

      // Convertir la fecha al objeto Date si no está en el formato aaaa-mm-dd
      const date = new Date(inputDate);

      if (isNaN(date.getTime())) {
        throw new Error('Fecha inválida');
      }

      // Extraer año, mes y día y formatear a aaaa-mm-dd
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses son base 0 en JavaScript
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    fillPublication(newData) {
      if (newData.publication_date) {
        newData.publication_date = this.formatDate(newData.publication_date)
        newData.publication_date = new Date(newData.publication_date.replace(/-/g, "/"));
      }
      else {
        //newData.publication_date = new Date();
      }
      this.magazine_name = null;
      this.publication.magazine_id = null
      if (newData.magazine_id) {
        this.publication.magazine_id = newData.magazine_id;
        this.magazine_name = newData.magazine;
      }



      var ocde_sub_areas = []
      if (this.ocde_sub_areas && newData.ocde_sub_areas_attributes) {
        for (const ocde of this.ocde_sub_areas) {
          var filtro_ocde = newData.ocde_sub_areas_attributes.filter((sub_area_ocde) => sub_area_ocde.name == ocde.name)
          if (filtro_ocde.length > 0) {
            ocde_sub_areas.push(filtro_ocde[0])
          }
        }
        newData.ocde_sub_areas_attributes = ocde_sub_areas
      }
      let is_creator = undefined;
      if (this.publication.is_creator) {
        is_creator = true;
      }
      this.publication = newData;
      this.publication.is_creator = is_creator;
      this.publication.publication_type_id = 1


    },
    isUpdatingAndDeletingParticipant(row) {
      if (this.queryType == "create") {
        return true;
      }
      else if (this.queryType == 'update') {
        //console.log(this.project.current_user_person_id, row.id,row)
        if (row) {
          return this.publication.is_creator || this.publication.current_user_person_id == row.person_id;
        }
        return this.publication.is_creator;
      }

    },
    updateInputs() {
      var type_id = this.publication.publication_type_id
      if (type_id == 4 || type_id == 6 || type_id == 7 || type_id == 8 || type_id == 9 || type_id == 10 || type_id == 11 || type_id == 12 || type_id == 13) {
        this.requiredMagazine = false
        this.skip_if_magister = true
      }
      else {
        this.requiredMagazine = true
        this.skip_if_magister = false
      }

    },
    deleteParticipantButton(participants) {

      this.$buefy.dialog.confirm({
        title: 'Eliminar Participante',
        message: 'Precaución, al momento de guardar la publicación esta acción eliminará la asociación con la/el participante, lo que implica que ya no podrá ver esta publicación ni será contada en su actividad académica ¿Desea continuar?',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteParticipantsPub(participants)
      })

    },
    AddFiliation(row) {
      if (row.partner_entity) {
        this.currentEntity = row.partner_entity;
      }
      else {
        this.currentEntity = {};
      }
      this.currentParticipantEntity = row;
      this.activeAddFiliationModal = true;
    },
    DeleteFiliation(row) {
      this.isLoading = true;
      this.currentEntity = {};
      this.currentParticipantEntity = {};
      let index = this.participantes.findIndex(participant => participant.person_id == row.person_id)
      if (index >= 0) {
        this.participantes[index].partner_entity_id = null;
        this.participantes[index].partner_entity = {};
        this.tableDataKey++;
      }
      this.isLoading = false;
    },
    closeFiliationModal() {
      this.activeAddFiliationModal = false;
    },
    setParticipantFilitation(res) {
      let index = this.participantes.findIndex(participant => participant == res.participant)
      if (index >= 0) {
        this.participantes[index].partner_entity_id = res.partner_entity.id;
        this.participantes[index].partner_entity = res.partner_entity;
        this.tableDataKey++;
      }

      this.currentEntity = {};
      this.activeAddFiliationModal = false;
    },
    setParticipants() {
      var index = 0
      for (const participante of this.publication.participants_attributes) {
        this.participantes.push({
          id: null,
          name: null,
          alias: null,
          unit: null,
          unit_id: null,
          corporarte_mail: null,
          participant_type_id: null,
          is_doc_student: null
        })
        this.participantes[index].person_id = participante.person_id
        this.participantes[index].is_doc_student = participante.is_doc_student
        this.participantes[index].name = participante.person_name
        this.participantes[index].corporate_mail = participante.person_mail
        this.participantes[index].alias = participante.person_alias
        if (this.participantes[index].corporate_mail != null) {
          this.participantes[index].corporate_mail = this.participantes[index].corporate_mail.toUpperCase()
        }
        if (this.participantes[index].alias != null) {
          this.participantes[index].alias = this.participantes[index].alias.toUpperCase()
        }
        this.participantes[index].unit = participante.unit_name
        this.participantes[index].unit_id = participante.unit_id
        this.participantes[index].participant_type_id = participante.participant_type_id
        this.participantes[index].partner_entity_id = participante.partner_entity_id
        this.participantes[index].partner_entity = participante.partner_entity
        index = index + 1
      }
    },
    addUsachFilitation(participant) {
      this.isLoading = true;
      this.showParticipantTable = false;
      let participantIndex = this.participantes.findIndex(part => part == participant);
      if (participantIndex >= 0 && this.usach_filitation) {
        this.participantes[participantIndex].partner_entity = this.usach_filitation;
        this.participantes[participantIndex].partner_entity_id = this.usach_filitation.id;
      }
      //this.participantes = this.participantes;
      this.showParticipantTable = true;
      this.isLoading = false;

    },
    async findPublicationByDoi() {
      let vm = this;
      vm.isLoading = true;
      var retorno = false;
      let publication_id = null;
      if (vm.publication.id) {
        publication_id = vm.publication.id;
      }
      vm.doiPublicationFinded = null;
      if (vm.publication.doi) {
        await axios
          .get("/publications/search/doi.json", { params: { doi: vm.publication.doi, publication_id: publication_id } })
          .then(res => {
            if (res.data.publication) {
              retorno = true;
              vm.doiPublicationFinded = res.data;
            }
            vm.isLoading = false;
          })
          .catch(e => {
            //console.log(e);
            vm.isLoading = false;
          });
      }
      vm.isLoading = false;
      return retorno;

    },

    editMagazine() {
      this.magazineQueryType = 'update';
      this.updateMagazineId = this.publication.magazine_id;
      this.isModalMagazineActive = true;
    },
    addTags(value) {
      var aux = 0
      var key_words_attributes = this.publication.key_words_attributes
      var temp_key_word = this.publication.key_words_attributes
      if (value != null && value.length > 0) {
        value.forEach(function (tag) {
          aux = 0
          var auxTag = tag.toUpperCase()
          auxTag = auxTag.trim()
          temp_key_word.forEach(function (word) {
            if (word.constructor.name === 'String') {
              if (word === auxTag) {
                aux = 1
              }
            }
            else {
              if (word.name === auxTag) {
                aux = 1
              }
            }
          })
          if (aux == 0) {
            key_words_attributes.push(auxTag)
          }
        })
        this.publication.key_words_attributes = key_words_attributes
      }
    },
    beforeAddTag(tags) {
      if (tags.constructor.name === 'String') {
        var splitTags = tags.split(",")
        if (splitTags.length == 1) {
          splitTags = tags.split(";")
        }
        this.addTags(splitTags)
      }
      else {
        var result = this.publication.key_words_attributes.filter(tag => tag.name === tags.name)
        if (result.length == 0) {
          this.publication.key_words_attributes.push(tags)
        }
      }
    }

  },

}
</script>