<template ref="orcid_tutorial">
  <div>
    <div class="columns is-multiline">
      <div class="column is-3 py-0">
        <b-sidebar position="static" type="is-light" open>
          <div class="p-1 pt-4">
            <div class="block mt-5">
              <h1 class="subtitle is-3 has-text-primary has-text-left">Índice</h1>
            </div>
            <b-menu class="is-custom-mobile">
              <b-menu-list>
                <b-menu-item :active="tutorial === 'create_request' ? true : false" @click="tutorial = 'create_request'" label="1. ¿Cómo buscar publicaciones con ORCID?"></b-menu-item>
              </b-menu-list>
            </b-menu>
          </div>
        </b-sidebar>
        <div class="buttons-sidebar">
          <b-button class="icon-up" icon-left="arrow-circle-up" type="is-ghost" @click="goUp">
            Volver arriba
          </b-button>
        </div>
      </div>
      <div v-if="tutorial === 'create_request'" class="column is-9">
        <h1 class="subtitle is-2 has-text-primary">
          Búsqueda de Publicaciones a través de su ORCID
        </h1>
        <div class="mb-3 ">
          <p>SIGA permite búsqueda de informácion de publicaciones a través de su ORCID registrado en el sistema.</p>
        </div>
        <h2 class="subtitle is-3 mt-5 mb-2">
          Paso 1
        </h2>
        <p class="my-3 ">Verificar que posee un ORCID asociado a su perfil
        </p>
        <ul>
          <li>2.1 Desplegar el menú de usuaria/o en la esquina superior derecha del sistema.</li>
          <img border="2" class="m-2" src="tutorial_images/my_profile_menu.png" style="width:40%">
          <li>2.2 Seleccionar <a :href="'/people/'+person.id+'/my-academic-data'" target="_blank"><b-icon pack="fas" icon="user-circle"></b-icon>Mi Perfil</a></li>
          <li>2.3 En la vista de <a :href="'/people/'+person.id+'/my-academic-data'" target="_blank"><b-icon pack="fas" icon="user-circle"></b-icon>Mi Perfil</a>,ubicar la sección "Datos académicos"</li>
          <li>2.4 Ubicar el campo ORCID.</li>
          <li><img border="2" class="m-2" src="tutorial_images/my_academic_data.png"></li>
          <li>2.4 Rellenar los datos de ORCID.</li>
          <li>2.5 Presionar el botón "Guardar Datos acádemicos".</li>
          <li>2.6 Si el sistema arroja errrores, deben ser solucionados para poder guardar la información.</li>
        </ul>
        <h2 class="subtitle is-3 mt-5 mb-2">
          Paso 2
        </h2>
        <p class="my-3 ">Buscar información de publicación según el ORCID de <a :href="'/people/'+person.id+'/my-academic-data'" target="_blank"><b-icon pack="fas" icon="user-circle"></b-icon>Mi Perfil</a>
        </p>
        <ul>
          <li>2.1 Abrir la Barra Lateral</li>
          <li>2.2 Seleccionar <b-icon pack="fas" icon="atom"></b-icon>Búsqueda activa de Información</li>
          <li><img border="2" class="m-2" src="tutorial_images/orcid_search_button.png"></li>
          <li>2.3 Seleccionar <b-icon pack="fab" icon="orcid"></b-icon> Por ORCID</li>
          <li><img border="2" class="m-2" src="tutorial_images/orcid_search.png"></li>
          <li>2.4 Si usted no posee ORCID y presiona el botón "Buscar información según mi ORCID", aparecerá un error en dónde habrá un link a "Mi Perfil" para poder añadirlo.</li>
          <li><img border="2" class="m-2" src="tutorial_images/orcid_search_error.png"></li>
          <li>2.5 Si usted posee ORCID, al presionar el botón "Buscar información según mi ORCID", el sistema empezará a buscar información sobre los datos que posee en la plataforma ORCID.
            <br>ADVERTENCIA: Esta búsqueda se conecta a una API externa, por lo la respuesta dependerá de la disponibilidad y existencia de los datos.
          </li>
          <li><img border="2" class="m-2" src="tutorial_images/orcid_fullfilled.png"></li>
          <li>2.6 Si la respuesta es exitosa, se desplegarán dos pestañas, la primera con publicaciones provenientes
            de ORCID que no han sido encontradas en SIGA, y la segunda con publicaciones provenientes de ORCID que si fueron encontradas en SIGA.</li>
          <li><img border="2" class="m-2" src="tutorial_images/orcid_search_ok.png"></li>
          <li><img border="2" class="m-2" src="tutorial_images/orcid_search_ok2.png"></li>
        </ul>
        <h2 class="subtitle is-3 mt-5 mb-2">
          Paso 3
        </h2>
        <p class="my-3 "> Rellenar los datos de las publicaciones obtenidas. <br> Debido a que no se pueden obtener todos los datos
          exigidos en SIGA para crear una publicación, si se desea añadir información obtenida desde ORCID debe completarse el formulario.
          En la pestaña "Publicaciones no encontradas en SIGA", se podrán apreciar tarjetas de información con algunos datos que fue posible
          obtener desde ORCID, cada una con su botón para completar los datos y añadir.
        </p>
        <ul>
          <li>3.1 Escoger una publicación y presionar el botón "Completar Datos y Añadir", lo que desplegará un modal
            con el formulario de "Nueva Publicación" con la información obtenida desde ORCID prellenada.</li>
          <li>3.2 Rellenar los datos obligatorios del formulario Nueva Publicación, tanto en la pestaña "General" como en la de "Participantes"
            <br>En la pestaña de "Participantes", la persona que posea el ORCID en SIGA, será añadida automaticamente como "CO-AUTOR" de la publicación.</li>
          <li>IMPORTANTE: Recordar añadir en participantes a todas/os las/os co-autores de la publicación.</li>
          <li>*Los pasos para crear una nueva publicación, se encuentra en la pestaña "Publicaciones" en "Manual de Uso".</li>
          <li>3.3 Guardar la publicación en SIGA.</li>
          <li>3.4 Si la operación de guardado es exitosa, sera redirigida/o a la pestaña de "Publicaciones no encontradas en SIGA", en donde la
          carta de información de la publicación recién añadida ha sido movida a la pestaña de "Publicaciones encontradas en SIGA", y dónde podra abrir
          la publicación correspondiente en una nueva pestaña.</li>

        </ul>
      </div>



    </div>

  </div>

</template>

<script>
export default {
  name: "orcidSearchTutorial",
  props:["person"],
  data() {
    return {
      tutorial:"create_request",
    }
  },
  methods:{
    goUp(){
      var elmnt = document.getElementById("app");
      elmnt.scrollIntoView();
      this.$refs.orcid_tutorial.scrollTop = 0;
    }
  },
}
</script>

<style scoped>
.b-sidebar .sidebar-content.is-absolute,
.b-sidebar .sidebar-content.is-static {
  width: 100%;
  background-color: white;
  box-shadow: none;
}
.icon-up{
  position: fixed;
  width: 16.5% !important;
  bottom: 0;
  left: 15%;
}
.buttons-sidebar{

}
</style>