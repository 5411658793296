<template>
  <section>
    <div class="container">
      <div class="box">
        <b-tabs @input="changeTab" :destroy-on-hide="true" v-model="currentTab" position="is-centered" type="is-toggle">
          <b-tab-item v-for="item in items"
                      :value="item.content"
                      v-bind:key="item.content"
                      :label="item.tab"
                      :visible="(item.content ==='NullAppointments' || item.content ==='NullDistances') ? can_list_null === 'true' : true">
          </b-tab-item>
        </b-tabs>
        <!-- COMPONENTE AQUI -->
        <component v-bind:is="currentTabComponent" :person-id="personId" :can_create_appointment="can_create_appointment"
                   :person_name="person_name"></component>

        <!-- FIN COMPONENTE -->
      </div>
    </div>
  </section>
</template>
<script>
import NullAppointments from "../life_record/null_appointments"
import NullDistances from "../life_record/null_distances"
import NullFeeContracts from "./null_fee_contracts"
export default {
  name: "null_appointments_distances",
  props:[
    'personId', 'can_create_appointment', 'can_list_null', 'person_name'
  ],
  data(){
    return {
      currentTab: 'NullAppointments',
      items: [
        {tab: 'Nombramientos Anulados',content:'NullAppointments'},
        {tab: 'Contratos de Honorarios Anulados', content: "NullFeeContracts"},
        {tab: 'Alejamientos Anulados', content: 'NullDistances'}
      ]
    }
  },
  components:{
    NullAppointments,
    NullDistances,
    NullFeeContracts
  },
  methods:{
    changeTab: function(value){
      this.currentTab = value
    },
    selectTab(tab){
      this.currentTab = tab
    },
  },
  computed: {
    currentTabComponent: function () {
      return this.currentTab
    },

  },
}
</script>