<template>
    <div class="vimeactivitynewentitiesrelatedview">
        <br>
        <br>
        <div class="columns is-centered">
            <h1 class="is-size-2" style="text-align:center;">{{this.vime_activity.name}}</h1>
        </div>
        <VimeActivityNewEntitiesRelatedForm :vime_activity_id='this.vime_activity_id'/>
    </div>
</template>
<script>
import axios from 'axios'
import VimeActivityNewEntitiesRelatedForm from './vime_activity_new_entities_related_form.vue'
export default {
    name: 'VimeActivityNewEntitiesRelatedView',
    components:{
        VimeActivityNewEntitiesRelatedForm
    },
    props:{
        vime_activity_id:{
            type: Number
        }
    },
    data (){
        return {
            vime_activity: {},
            vime_activity_assistances_info: []
        }
    },
    beforeMount(){
        this.getVimeActivity()
        this.getVimeActivityAssistance()
    },
    methods:{
        getVimeActivity(){
            axios.get('/vime_activities/'+this.vime_activity_id+'.json')
            .then(response =>{
                this.vime_activity = response.data
            })
            .catch(e => { 
                console.log(e)
            })
        },
        getVimeActivityAssistance(){
            axios.get('/vime_activity_assistances/getEntitiesRelatedByActivityId/'+this.vime_activity_id)
            .then(response =>{
                this.vime_activity_assistances_info = response.data.assistance
            })
            .catch(e => { 
                console.log(e)
                this.isLoading=false
            })
        },
    }
}
</script>