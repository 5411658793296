<template>
    <section>
      <div v-if="can_create_academic_activity === 'true'" class="columns" >
        <div class="column is-offset-8">
          <b-field label = " " class="has-text-right">
            <b-button class="is-primary" tag="a" href="/disclosures/new" target="_blank" icon-left="plus">
              Añadir Disclosure
            </b-button>
          </b-field>
        </div>
      </div>
      <disclosure-table v-bind:disclosures="disclosures" :cargo-principal="cargoPrincipal" :hide-update="true"
          @updateDisclosures="updateDisclosures" />

      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

    </section>
</template>
<script>
import axios from "axios";
import moment from "moment";
import DisclosureTable from "../disclosure/disclosure_table.vue";
export default {
    props: [
        'personId', 'cargoPrincipal', 'minYear', 'maxYear', 'changeFilter', 'can_create_academic_activity'
    ],
    data() {
        return {
            disclosures: [],
            isLoading: false,
            year: new Date().getFullYear(),
        }
    },
    components: {
        DisclosureTable,
    },
    created() {
        this.getDisclosures()
    },
    methods: {
        getDisclosures() {
            this.isLoading = true
            axios
                .get("/people/" + this.personId + "/disclosures.json", {params: {
                    minYear: this.minYear,
                    maxYear: this.maxYear,
                    id: this.personId,
                }})
                .then(res => {
                    this.disclosures = res.data
                    this.disclosures = this.disclosures.map(disclosure => {
                        var disclosure = disclosure
                        disclosure["disclosure_date"] = moment(disclosure.disclosure_date, ["YYYY-MM-DD", "DD-MM-YYYY"])
                        disclosure["invention_date"] = moment(disclosure.invention_date, ["YYYY-MM-DD", "DD-MM-YYYY"])
                        return disclosure
                    })
                    this.isLoading = false
                })
                .catch(e => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: 'Hubo un error al traer los Disclosures de la persona consultada, favor de revisar la consola.',
                        type: 'is-danger'
                    })
                    console.log(e)
                    this.isLoading = false
                })
        },
        updateDisclosures(year) {
            this.year = year
            this.getDisclosures()
        },
    },
    watch: {
        changeFilter: function () {
            this.getDisclosures()
        }
    }
}
</script>