<template>
  <section>
    <div class="container">


      <!--DATA TABLE-->
      <b-table
          :data="person_units"
          :paginated= true
          per-page="20"
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="product.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual"
          :opened-detailed="defaultOpenedDetails"
          detailed
          detail-key="id"
          detail-transition="fade"
          :show-detail-icon="showDetailIcon"
          scrollable
          :sticky-header=false>
        <b-table-column
            field="resolution_date"
            label="Fecha Documento"
            width="110"
            sortable
            searchable :custom-search="searchResolution">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.resolution_date._isValid ? new Date(props.row.resolution_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>

        <b-table-column
            field="resolution"
            label="N° de documento"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.resolution}}
          </template>
        </b-table-column>
        <b-table-column
            field="resolution_type.name"
            label="Tipo documento"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.resolution_type ? props.row.resolution_type.name : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="appointment_type.name"
            label="Tipo Nombramiento"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.appointment_type ? props.row.appointment_type.name : ""}}
          </template>
        </b-table-column>

        <b-table-column
            field="work_plant.name"
            label="Cargo"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.work_plant ? props.row.work_plant.name : ""}}
          </template>
        </b-table-column>

        <b-table-column
            field="unit.cost_center"
            label="CC Unidad"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            <b-tooltip
                :label="props.row.unit.name"
                multilined
                position="is-left">
              {{props.row.unit ? props.row.unit.cost_center : ""}}
            </b-tooltip>

          </template>
        </b-table-column>
        <b-table-column
            field="start_date"
            label="Fecha Desde"
            width="110"
            sortable
            searchable :custom-search="searchStart">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            field="end_date"
            label="Fecha Hasta"
            width="110"
            sortable
            searchable :custom-search="searchEndDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            field="working_day"
            label="Jornada /Horas"
            width="110"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.working_day ? props.row.working_day.hired_hours : ""}}
          </template>
        </b-table-column>

        <b-table-column
            label="Acciones"
            width="40"
            v-slot="props">
          <b-icon
              v-if="props.row.can_show"
              pack="fas"
              icon="eye"
              size="is-medium"
              @click.native="execShowModal(props.row.id)"/>
          <b-icon
              v-if="props.row.can_restore"
              pack="fas"
              icon="trash-restore"
              type="is-success"
              size="is-medium"
              @click.native="execRestoreModal(props.row.id)"/>
        </b-table-column>

        <template #detail="props">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <div class="columns">
                  <div class="column">
                    <strong>Alejamientos Asociados: </strong>
                  </div>
                </div>
                <div class="columns" v-for="(distance,index) of props.row.distances" :key=index>

                  <div class="column is-narrow">
                    <p>{{distance.appointment_type_name}}</p>
                  </div>
                  <div class="column is-narrow">
                    <p>{{distance.resolution_type_name}}</p>
                  </div>
                  <div class="column is-narrow">
                    <p>{{distance.resolution}}</p>
                  </div>
                  <div class="column is-narrow">
                    <p>{{formatDate(distance.resolution_date)}}</p>
                  </div>
                  <div class="column is-narrow">
                    <p>{{formatDate(distance.start_date)}}</p>
                  </div>
                  <div class="column is-narrow">
                    <b-icon
                        pack="fas"
                        icon="eye"
                        size="is-medium"
                        @click.native="execShowModalDistance(distance.id)"/>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </template>

      </b-table>
      <!--FIN DATA TABLE-->

      <!-- MODALES -->
      <b-modal
          v-if="canEdit"
          v-model="isAddModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <select-appointment-establishment
              :person-id="personId"
              query-type="create"
              modal-header="Seleccionar Nombramiento a Crear"
              @close="props.close"></select-appointment-establishment>
        </template>
      </b-modal>
      <b-modal
          v-model="isShowModalActive"
          has-modal-card
          trap-focus
          width="1024"
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <component  :is="showName"
                      :id="selected"
                      :person-id="personId"
                      :modal-header="title"
                      @close="props.close"/>
        </template>
      </b-modal>
      <b-modal
          v-if="canEdit"
          v-model="isEditModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          width="1024"
          :can-cancel="canCancel">
        <template #default="props">
          <component :is="formName"
                     :person-id="personId"
                     :id="selected"
                     :query-type="query_type"
                     :modal-header="title"
                     @close="props.close"/>
        </template>
      </b-modal>

      <b-modal
          v-model="isShowDistanceModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <component  :is="showName"
                      :id="selected"
                      :modal-header="title"
                      @close="props.close"/>
        </template>
      </b-modal>
      <b-modal
          v-model="isDistanceModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <distance-form :person-id="actual_person_unit.person_id"
                         :id="actual_person_unit.id"
                         :person_unit_id="actual_person_unit.id"
                         query-type="create"
                         modal-header="Crear Alejamiento"
                         @close="props.close">
          </distance-form>
        </template>
      </b-modal>

    </div>
    <b-loading :is-full-page="true"
               v-model="isLoading"
               :can-cancel="false"/>
  </section>
</template>

<script>
import axios from "axios";
import AppointmentAcademic from "../life_record/appointment_academic.vue"
import AppointmentAdministrative from "../life_record/appointment_administrative.vue"
import AppointmentHours from "../life_record/appointment_hours.vue";
import AppointmentAcademicForm from "../life_record/modal_academic_appointment_form.vue"
import AppointmentHoursForm from "../life_record/modal_hour_appointment_form.vue"
import AppointmentAdministrativeForm from "../life_record/modal_administrative_appointment_form.vue"
import SelectAppointmentEstablishment from '../life_record/select_appointment_establishment.vue'
import Distance from "../life_record/distance.vue"
import AppointmentFeeContractForm from "../life_record/modal_feecontract_appointment_form"
import AppointmentFeeContract from "../life_record/appointment_feecontract"
import moment from "moment";
import {searchDate, formatDate} from "../../packs/utilities";
import DistanceForm from "../life_record/modal_distance_form.vue"
export default {
  name: "null_appointments",
  props:[
    'personId','isEditDisabled', 'can_create_appointment', 'person_name'
  ],
  components:{
    AppointmentHours,
    AppointmentAcademic,
    AppointmentAdministrative,
    AppointmentHoursForm,
    AppointmentAcademicForm,
    AppointmentAdministrativeForm,
    SelectAppointmentEstablishment,
    AppointmentFeeContractForm,
    AppointmentFeeContract,
    Distance,
    DistanceForm
  },
  data(){
    return {
      formName: "",
      showName: "",
      title: "",
      selected: null,
      index: null,
      currentPage: 1,
      isAddModalActive: false,
      isEditModalActive: false,
      isShowModalActive: false,
      isLoading:false,
      person_units: [],
      person_unit:{},
      canCancel: ['escape'],
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      useTransition: false,
      isShowDistanceModalActive: false,
      canEdit: true,
      isDistanceModalActive: false,
      actual_person_unit: {},
      query_type: "",
      isRestoreActive: false,
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    initialize(){
      if(this.isEditDisabled){
        this.canEdit = false;
      }
      this.isLoading = true;
      return axios
          .get("/people/"+this.personId+"/null_appointments.json")
          .then(res => {
            this.person_units = res.data
            this.person_units = this.person_units.map(personUnit => {
              var newPersonUnit = personUnit
              newPersonUnit["resolution_date"] = moment(newPersonUnit["resolution_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newPersonUnit["start_date"] = moment(newPersonUnit["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newPersonUnit["end_date"] = moment(newPersonUnit["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newPersonUnit;
            });
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })

    },
    execRestoreModal(id){
      let vm = this;
      vm.$buefy.dialog.confirm({
        title: 'Restaurar nombramiento',
        message: '¿Está seguro que desea <b>restaurar</b> el nombramiento? Esta acción revertirá la anulación.',
        confirmText: 'Restaurar Nombramiento',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          vm.isRestoreActive = true
          vm.isLoading = true
          axios
              .put("/admin/person_units/" + id + "/restore_appointment.json")
              .then(res => {
                vm.$buefy.dialog.alert({
                  message: 'Nombramiento restaurado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                vm.isLoading = false;
                vm.isRestoreActive = false;
              }).catch(error => {
            console.log(error)
            vm.$buefy.dialog.alert({
              message: '<b>Nombramiento no pudo ser restaurado</b><br>'+error.response.data["base"][0],
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            vm.isLoading = false;
            vm.isRestoreActive = false;
          })
        }
      })

    },
    execShowModalDistance(distance_id){
      this.selected = distance_id;
      this.showName = "Distance"
      this.title = "Alejamiento"+" - "+this.person_name
      this.isShowDistanceModalActive = true;
    },
    execShowModal(id){
      this.selected = id;
      let person_unit = this.getEstablishment(id)
      if(person_unit.establishment!== undefined){
        if(person_unit.establishment.name==="ACADEMICOS") {
          this.showName = "AppointmentAcademic"
          this.title = "Nombramiento Estamento Académico"+" - "+this.person_name
        }
        else if (person_unit.establishment.name==="ADMINISTRATIVOS") {
          this.showName = "AppointmentAdministrative"
          this.title = "Nombramiento Estamento Administrativo"+" - "+this.person_name
        }
        else if (person_unit.establishment.name==="HONORARIOS") {
          this.showName = "AppointmentFeeContract"
          this.title = "Nombramiento Estamento Honorarios"+" - "+this.person_name
        }
        else {
          this.showName = "AppointmentHours"
          this.title = "Nombramiento Estamento Profesor Horas Clases"+" - "+this.person_name
        }
      }
      else{
        this.showName = "AppointmentAdministrative"
      }
      //console.log("APPOINTMENT "+ this.showName)
      this.isShowModalActive = true;
    },
    execEditModal(id){
      this.selected = id;
      this.query_type = "update"
      let person_unit = this.getEstablishment(id)
      if(person_unit.establishment!==undefined){
        if(person_unit.establishment.name==="ACADEMICOS") {
          this.formName = "AppointmentAcademicForm"
          this.title = "Editar Nombramiento Estamento Académico"+" - "+this.person_name
        }
        else if (person_unit.establishment.name==="ADMINISTRATIVOS") {
          this.formName = "AppointmentAdministrativeForm"
          this.title = "Editar Nombramiento Estamento Administrativo"+" - "+this.person_name
        }
        else if (person_unit.establishment.name==="HONORARIOS") {
          this.formName = "AppointmentFeeContractForm"
          this.title = "Editar Nombramiento Estamento Honorarios"+" - "+this.person_name
        }
        else {
          this.formName = "AppointmentHoursForm"
          this.title = "Editar Nombramiento Estamento Profesor Horas Clase"+" - "+this.person_name
        }
      }
      else{
        this.formName = "AppointmentAdministrativeForm"

      }
      //console.log("APPOINTMENT "+ this.showName)
      this.isEditModalActive = true;
    },
    execDistanceModal(person_unit){
      this.isLoading = true
      this.actual_person_unit = person_unit
      this.isDistanceModalActive = true
      this.isLoading = false
    },
    getEstablishment(selected){
      return this.person_units.find(o => o.id === selected);
    },
    // yyyy/mm/dd -> dd/mm/yyyy
    formatDate,
    searchEndDate(row,input){
      return searchDate(row.end_date,input)
    },
    searchResolution(row,input){
      return searchDate(row.resolution_date,input)
    },
    searchStart(row,input){
      return searchDate(row.start_date,input)
    }
  },
  watch:{
    isAddModalActive: function () {
      if (!this.isAddModalActive) {
        this.initialize()
      }
    },
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
    isShowModalActive: function () {
      if (!this.isShowModalActive) {
        this.initialize()
      }
    },
    isDistanceModalActive: function () {
      if (!this.isDistanceModalActive) {
        this.initialize()
      }
    },
    isRestoreActive: function () {
      if (!this.isRestoreActive){
        this.initialize()
      }
    }

  }
}
</script>
