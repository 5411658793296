<template>
  <section>
    <!-- Box de Formulario Evento -->
    <div class="modal-card" style="width: auto" id="modal-spreadsheet">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <vue-excel-editor v-model="jsondata" ref="grid">
            <vue-excel-column field="name"   label="Nombre" :readonly="true"/>
            <vue-excel-column field="carpeta"   label="Carpeta" :readonly="true"/>
            <vue-excel-column field="document_type"   label="Tipo de Documento" :readonly="true"/>
            <vue-excel-column field="resolution"   label="Resolucion" :readonly="true"/>
            <vue-excel-column field="document_date"   label="Fecha de Documento" :readonly="true"/>
            <vue-excel-column field="url"   label="Url" :readonly="true"/>
          </vue-excel-editor>
        </div>
      </section>
    </div>
    <footer class="modal-card-foot actions">
      <div class="field is-grouped is-pulled-right">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
        <button @click="exportAsExcel" class="button" type="primary"> Exportar Excel </button>
      </div>
    </footer>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
export default {
  name: "modal_spreadsheet_documents",
  props:[
    'modalHeader',
  ],
  components: {
  },
  data(){
    return {
      isLoading:false,
      isDisabled: false,
      jsondata: [
        {name: "DOCUMENTO NUEVO.PDF", carpeta: "/CARPETA 1/CARPETA 2", document_type: "DECRETO", resolution: "1234", document_date: "1-1-9999", url: "https://drive.google.com/IDDOCUMENTO"},
        {name: "DOCUMENTO_21313.DOCX", carpeta: "/CARPETA 20/CARPETA 21", document_type: "RESOLUCION", resolution: "2345", document_date: "1-1-9999", url: "https://drive.google.com/IDDOCUMENTO"},
        {name: "DOCUMENTO.xlsx", carpeta: "/CARPETA 1", document_type: "", resolution: "3456", document_date: "1-1-9999", url: "https://drive.google.com/IDDOCUMENTO"},
      ],
    }
  },
  created() {
    this.isLoading=true;

    this.isLoading=false;
  },
  methods: {
    deleteData(){
    },
    exportAsExcel () {
      const format = 'xlsx'
      const exportSelectedOnly = false
      const filename = 'documentos'
      this.$refs.grid.exportTable(format, exportSelectedOnly, filename)
    }

  }
}
</script>