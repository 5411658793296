<template>
    <section>
        <div v-if="patentIndicator.show">

            <b-collapse :open="isOpenSummary" @open="isOpenSummary = true" class="card" animation="slide" aria-id="contentIdForA11y3">
                <template #trigger="props">
                    <div class="card-header" role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.open">
                        <p class="card-header-title is-centered">
                            Resumen de Patentes según su Estado
                        </p>
                        <a class="card-header-icon">
                            <b-icon :icon="props.open ? 'angle-down' : 'angle-up'">
                            </b-icon>
                        </a>
                    </div>
                </template>

                <div class="card-content">
                    <div class="content">
                        <SummaryIndicatorPatents :indicator="patentIndicator.summary" />
                    </div>
                </div>
            </b-collapse>

            <br>

            <b-collapse v-show="patentIndicator.show_by_years" :open="isOpenYear" @open="isOpenYear = true" class="card" animation="slide"
                aria-id="contentIdForA11y3">
                <template #trigger="props">
                    <div class="card-header" role="button" aria-controls="contentIdForA11y3"
                        :aria-expanded="props.open">
                        <p class="card-header-title is-centered">
                            Cantidad de Patentes por Año y Estado
                        </p>
                        <a class="card-header-icon">
                            <b-icon :icon="props.open ? 'angle-down' : 'angle-up'">
                            </b-icon>
                        </a>
                    </div>
                </template>

                <div class="card-content">
                    <div class="content">
                        <IndicatorByYear :years="patentIndicator.indicator_by_years"
                            :title="'patent_year'" />
                    </div>
                </div>
            </b-collapse>
            <br>
            <b-collapse v-show="patentIndicator.show_by_type" :open="isOpenType" @open="isOpenType = true" class="card" animation="slide"
                aria-id="contentIdForA11y3">
                <template #trigger="props">
                    <div class="card-header" role="button" aria-controls="contentIdForA11y3"
                        :aria-expanded="props.open">
                        <p class="card-header-title is-centered">
                            Proporción de Patentes por Estado
                        </p>
                        <a class="card-header-icon">
                            <b-icon :icon="props.open ? 'angle-down' : 'angle-up'">
                            </b-icon>
                        </a>
                    </div>
                </template>

                <div class="card-content">
                    <div class="content">
                        <IndicatorByType :types="patentIndicator.indicator_by_type"
                            :title="'patent_type'" />
                    </div>
                </div>
            </b-collapse>
        </div>
        <div v-else>
            <div class="columns is-centered">
                <div class="column is-6 is-centered has-text-centered">
                    <b-message has-icon type="is-warning">
                        <h1> No se encontraron resultados. Porfavor ingrese nuevas Patentes para mostrar sus
                            indicadores.</h1>
                    </b-message>
                </div>
            </div>
        </div>

        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

    </section>
</template>

<script>
import axios from 'axios'
import IndicatorByType from './indicator_by_type.vue'
import IndicatorByYear from './indicator_by_year.vue'
import SummaryIndicatorPatents from './summary_indicator_patents.vue'
export default {

    components: {
        IndicatorByType, IndicatorByYear, SummaryIndicatorPatents
    },

    props: [
        'minYear', 'maxYear', 'unitId', 'changeFilter'
    ],

    data() {
        return {
            isOpenSummary: true,
            showYears: true,
            showType: true,
            isOpenYear: true,
            isOpenType: true,
            patentIndicator: {},
            isLoading: false,
        }
    },

    async created() {
        await this.getPatentIndicator()
    },

    methods: {

        async getPatentIndicator() {

            this.isLoading = true

            await axios
                    .get('/dashboards/get-unit-patent-indicator.json', {
                        params: {
                            unitId: this.unitId,
                            minYear: this.minYear,
                            maxYear: this.maxYear,
                        }
                    })
                    .then(response => {
                        this.patentIndicator = response.data.patents
                        this.$emit('updatedData')
                        this.isLoading = false
                    })
                    .catch(error => {
                        var error_message
                        if (error.response != null) {
                            error_message = error.response.data.error
                        } else {
                            error_message = error
                        }
                        this.$buefy.dialog.alert({
                            title: 'Error',
                            message: 'Hubo un error al obtener los datos de Indicadores de Patentes de la Unidad.<br><br> Error:<br>' + error_message,
                            type: 'is-danger',
                            hasIcon: true,
                            icon: 'times-circle',
                            iconPack: 'fa',
                            ariaRole: 'alertdialog',
                            ariaModal: true
                        })
                        this.$emit('updatedData')
                        this.isLoading = false
                    })

        }

    },

    watch: {
        changeFilter() {
            this.getPatentIndicator()
            deep: true
        },
    },

}
</script>