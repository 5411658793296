<template>
  <section>
    <!-- Box de Formulario Evento -->
    <div class="modal-card" style="width: auto" id="modal-spreadsheet">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <vue-excel-editor v-model="jsondata" ref="grid">
            <vue-excel-column field="rut"   label="RUT" :readonly="true"/>
            <vue-excel-column field="dv"   label="DV" :readonly="true"/>
            <vue-excel-column field="last_name"   label=" AP.PATERNO " :readonly="true"/>
            <vue-excel-column field="surname"   label=" AP.MATERNO " :readonly="true"/>
            <vue-excel-column field="name"   label=" NOMBRES " :readonly="true"/>
            <vue-excel-column field="gender"   label="GENERO" :readonly="true"/>
            <vue-excel-column field="contracting_unit_cc"   label=" CC UNIDAD QUE CONTRATA" :readonly="true"/>
            <vue-excel-column field="contracting_unit"   label=" UNIDAD QUE CONTRATA" :readonly="true"/>
            <vue-excel-column field="working_unit_cc"   label="CC UNIDAD DE DESEMPEÑO" :readonly="true"/>
            <vue-excel-column field="working_unit"   label=" UNIDAD DE DESEMPEÑO" :readonly="true"/>
            <vue-excel-column field="performance_area"   label="AREA DE DESEMPEÑO" :readonly="true"/>
            <vue-excel-column field="resolution_type"   label=" TIPO DE DOCUMENTO (DU/RES/TRA) " :readonly="true"/>
            <vue-excel-column field="resolution_number"   label=" NÚMERO DE DU/RES/TRA " :readonly="true"/>
            <vue-excel-column field="resolution_date"   label=" FECHA DE DU/RES/TRA " :readonly="true"/>
            <vue-excel-column field="establishment"   label=" ESTAMENTO " :readonly="true"/>
            <vue-excel-column field="work_plant"   label=" CARGO " :readonly="true"/>
            <vue-excel-column field="hr_subject"   label=" ASIGNATURA " :readonly="true"/>
            <vue-excel-column field="function_name"   label=" FUNCIÓN " :readonly="true"/>
            <vue-excel-column field="working_day"   label=" JORNADA " :readonly="true"/>
            <vue-excel-column field="taken_hours"   label=" N° HORAS " :readonly="true"/>
            <vue-excel-column field="rank"   label=" RANGO " :readonly="true"/>
            <vue-excel-column field="grade"   label=" GRADO " :readonly="true"/>
            <vue-excel-column field="hierarchy"   label=" JERARQUÍA " :readonly="true"/>
            <vue-excel-column field="level"   label=" NIVEL " :readonly="true"/>
            <vue-excel-column field="category"   label=" CATEGORÍA " :readonly="true"/>
            <vue-excel-column field="quality"   label=" CALIDAD DE NOMBRAMIENTO " :readonly="true"/>
            <vue-excel-column field="appointment_type"   label=" TIPO DE NOMBRAMIENTO" :readonly="true"/>
            <vue-excel-column field="start_date"   label=" F.INICIO CARGO " :readonly="true"/>
            <vue-excel-column field="end_date"   label=" F.TÉRMINO CARGO " :readonly="true"/>
            <vue-excel-column field="study_level"   label="NIVEL DE ESTUDIO" :readonly="true"/>
            <vue-excel-column field="study"   label=" NOMBRE TÍTULO " :readonly="true"/>
            <vue-excel-column field="study_date"   label=" FECHA TÍTULO " :readonly="true"/>
            <vue-excel-column field="study_institution"   label=" INSTITUCIÓN DEL TÍTULO " :readonly="true"/>
            <vue-excel-column field="study_semesters"   label=" N° DE SEMESTRES TÍTULO " :readonly="true"/>
            <vue-excel-column field="study_grade"   label=" NOMBRE GRADO ACADÉMICO " :readonly="true"/>
            <vue-excel-column field="study_grade_date"   label=" FECHA GRADO " :readonly="true"/>
            <vue-excel-column field="study_grade_institution"   label=" INSTITUCIÓN DEL GRADO " :readonly="true"/>
            <vue-excel-column field="study_grade_semesters"   label=" N° DE SEMESTRES GRADO " :readonly="true"/>
            <vue-excel-column field="birthday_date"   label=" F.NACIMIENTO " :readonly="true"/>
            <vue-excel-column field="university_entrance_date"   label=" FECHA INGRESO USACH. " :readonly="true"/>
            <vue-excel-column field="pension_institution"   label=" INST. PREVISIONAL " :readonly="true"/>
            <vue-excel-column field="health_institution"   label=" INST. SALUD " :readonly="true"/>
            <vue-excel-column field="nationality"   label=" NACIONALIDAD " :readonly="true"/>
            <vue-excel-column field="origin_country"   label=" PAÍS DE ORIGEN " :readonly="true"/>
            <vue-excel-column field="birthplace"   label=" LUGAR DE NACIMIENTO " :readonly="true"/>
            <vue-excel-column field="civil_status"   label=" ESTADO CIVIL " :readonly="true"/>
            <vue-excel-column field="phone_code_area"   label="CODIGO DE AREA TELEFONO FIJO" :readonly="true"/>
            <vue-excel-column field="phone"   label=" TELÉFONO FIJO " :readonly="true"/>
            <vue-excel-column field="cellphone_code_area"   label="CODIGO DE AREA CELULAR" :readonly="true"/>
            <vue-excel-column field="cellphone"   label=" CELULAR " :readonly="true"/>
            <vue-excel-column field="personal_mail"   label=" email PERSONAL " :readonly="true"/>
            <vue-excel-column field="corporate_mail"   label=" email CORPORATIVO " :readonly="true"/>
            <vue-excel-column field="is_retired"   label=" JUBILADO SI/NO " :readonly="true"/>
            <vue-excel-column field="address"   label=" DIRECCIÓN PARTICULAR " :readonly="true"/>
            <vue-excel-column field="commune"   label=" COMUNA " :readonly="true"/>
            <vue-excel-column field="region"   label=" REGIÓN " :readonly="true"/>
          </vue-excel-editor>
        </div>
      </section>
    </div>
    <footer class="modal-card-foot actions">
      <div class="field is-grouped is-pulled-right">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
        <button @click="exportAsExcel" class="button" type="primary"> Exportar Excel </button>
      </div>
    </footer>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import axios from "axios";
import moment from 'moment'

export default {
  name: "modal_spreadsheet",
  props:[
   'modalHeader',
  ],
  components: {
  },
  data(){
    return {
      isLoading:false,
      isDisabled: false,
      jsondata: [
        {rut: 12345678, dv: "9", last_name: "APELLIDO PATERNO", surname: "APELLIDO MATERNO", name: "NOMBRE1 NOMBRE2", gender: "F",
        contracting_unit_cc: 40, contracting_unit:	"DECANATO FACULTAD DE INGENIERÍA", working_unit_cc:	40, working_unit:	"DECANATO FACULTAD DE INGENIERÍA",
          performance_area: "DOCENCIA", resolution_type: "SIN INFORMACION", resolution_number: 	3413, resolution_date: 	"12/30/2020", establishment: 	"ADMINISTRATIVOS",
          work_plant: "PROFESIONAL", hr_subject: "",	function_name:	"ANALISTA DE PROYECTOS", 	working_day: "JORNADA COMPLETA", taken_hours:	44, rank: "", grade: 9, hierarchy: "", level: "II", category: "", quality: "CONTRATA",
          appointment_type: "AUMENTO DE GRADO",  start_date:	"1/1/2021", end_date:	"12/31/2021", study_level:	"TITULO UNIVERSITARIO", study:	"PROFESOR DE EDUCACION MEDIA EN INGLES",
          study_date: "1/20/2003", study_institution: 	"UNIVERSIDAD CATOLICA CARDENAL RAUL SILVA HENRÍQUE", study_semesters: "", study_grade: "", study_grade_date: "",
          study_grade_institution: "", study_grade_semesters: "" , birthday_date: "12/2/1976", university_entrance_date:	"11/18/2015",
          pension_institution: "AFP MODELO", health_institution:	"ISAPRE CRUZ BLANCA", nationality:	"CHL", origin_country:	"CHILE", civil_status:		"Soltero(a)",
          phone_code_area: 22, phone:	8863054, cellphone_code_area:	569, cellphone:	97116821, personal_mail: "", corporate_mail:	"nombre.apellido@usach.cl",
          is_retired: "NO", address:	"AVENIDA JOSÉ MANUEL INFANTE #919 302", commune: 	"PROVIDENCIA", region:	"REGIÓN METROPOLITANA"},
        {rut: 12345678, dv: "9", last_name: "APELLIDO PATERNO", surname: "APELLIDO MATERNO", name: "NOMBRE1 NOMBRE2", gender: "F",
          contracting_unit_cc: 40, contracting_unit:	"DECANATO FACULTAD DE INGENIERÍA", working_unit_cc:	40, working_unit:	"DECANATO FACULTAD DE INGENIERÍA",
          performance_area: "", resolution_type: "SIN INFORMACION", resolution_number: 	3413, resolution_date: 	"12/30/2020", establishment: 	"PROFESORES HORAS DE CLASES",
          work_plant: "PROFESOR HORAS DE CLASES", hr_subject: "4-17068-PROCESOS DE CONCENTRACION | 8-9659-CONCENTRACION DE MINERALES ",	function_name:	"PROFESOR HORAS DE CLASES", 	working_day: "JORNADA DOCENTE", taken_hours:	12, rank: "", grade: "", hierarchy: "", level: "", category: "ADJUNTO  II", quality: "CONTRATA",
          appointment_type: "CONTINUIDAD",  start_date:	"14/03/2021", end_date:	"21/08/2021", study_level:	"TITULO UNIVERSITARIO", study:	"PROFESOR DE EDUCACION MEDIA EN INGLES",
          study_date: "1/20/2003", study_institution: 	"UNIVERSIDAD CATOLICA CARDENAL RAUL SILVA HENRÍQUE", study_semesters: "", study_grade: "", study_grade_date: "",
          study_grade_institution: "", study_grade_semesters: "" , birthday_date: "12/2/1976", university_entrance_date:	"11/18/2015",
          pension_institution: "AFP MODELO", health_institution:	"ISAPRE CRUZ BLANCA", nationality:	"CHL", origin_country:	"CHILE", civil_status:		"Soltero(a)",
          phone_code_area: 22, phone:	8863054, cellphone_code_area:	569, cellphone:	97116821, personal_mail: "", corporate_mail:	"nombre.apellido@usach.cl",
          is_retired: "NO", address:	"AVENIDA JOSÉ MANUEL INFANTE #919 302", commune: 	"PROVIDENCIA", region:	"REGIÓN METROPOLITANA"}
      ],
    }
  },
  created() {
    this.isLoading=true;

    this.isLoading=false;
  },
  methods: {
    deleteData(){
    },
    exportAsExcel () {
      const format = 'xlsx'
      const exportSelectedOnly = false
      const filename = 'ingreso'
      this.$refs.grid.exportTable(format, exportSelectedOnly, filename)
    }

  }
}
</script>