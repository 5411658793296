<template>
  <div class="field has-addons">
    <p class="control has-icons-left">
      <input v-model='busqueda' class="input" type="text" id="input-busqueda" placeholder="Búsqueda...">
      <span class="icon is-left"><i class="fas fa-search" aria-hidden="true"></i></span>
    </p>
    <div class="control">
      <b-button @click.prevent="search(busqueda)" class="button is-light" id="boton-buscar">Buscar</b-button>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        busqueda: ""
      }
    },
    create(){
      this.busqueda = ""
    },
    methods:{
      search(query){
        window.location.href = "/searches/index?q="+query
      }
    }
  }
</script>
