<template>
  <section>
    <div class="container">
      <div class="box">
        <div class="columns">
          <div class="column">
            <h3 class="is-size-3 has-text-weight-bold">Unidad: {{title}}</h3>
            <h3 class="is-size-4 has-text-weight-bold">Rol: {{title_internal}}</h3>
          </div>
          <div class="column" align="right" style="text-align:right;" >
            <b-button class="mt-2" @click.prevent="activateEdit" type="is-primary">{{edit_activated ? "Desactivar Edición" : "Activar Edición"}}</b-button>
          </div>
        </div>
        <b-table
            :data="permissions"
            :paginated= true
            per-page="20"
            striped
            hoverable
            sticky-header
            height="640"
            :narrowed=true
            :current-page="currentPage"
            :pagination-simple=false
            pagination-position="both"
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            default-sort="product.title"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual">
          <b-table-column
              field="name"
              label="Tabla"
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.name}}
            </template>
          </b-table-column>
          <b-table-column
              field="action"
              label="Permiso"
              searchable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.action}}
            </template>
          </b-table-column>
          <b-table-column
              field="state"
              label="¿Otorgado?"
              searchable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.state}}
            </template>
          </b-table-column>
          <b-table-column
              label="Acción"
              v-slot="props">
            <b-switch
                      v-model="props.row.state"
                      :rounded="false"
                      type="is-success" passive-type="is-danger" :disabled="!edit_activated"></b-switch>
          </b-table-column>
        </b-table>

        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
      </div>
      <div class="columns">
        <div class="column">
          <div class="has-text-right">
            <b-button @click="savePermissions" type="is-warning" :disabled="!edit_activated">Guardar permisos</b-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "permissions_table",
  props:[
    'title', 'internal_position_id', 'unit_id', 'title_internal'
  ],
  data(){
    return {
      selected: null,
      people: [],
      currentPage: 1,
      isLoading: false,
      canCancel: ['escape'],
      no_filters: true,
      formattedDate: "",
      show: false,
      edit: false,
      permissions: [],
      complete_rut: "",
      complete_name: "",
      corporate_mail: "",
      add: false,
      personId: "",
      queryType: "",
      id: "",
      person: "",
      edit_activated: false
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    savePermissions(){
      let vm = this;
      vm.isLoading = true;
      let data = {};
      data.cargo=vm.internal_position_id;
      data.permisos=vm.permissions;
      if (vm.unit_id)
        data.unit_id = vm.unit_id;
      axios
          .post("/edit_internal_position_permissions",data)
          .then(response =>{
            vm.isLoading=false;
            vm.edit_activated = false;
            this.$buefy.dialog.alert({
              message: 'Las permisos del rol y unidad seleccionados fueron editados correctamente ',
              confirmText: 'Ok'
            })
          }).catch(error => {
        vm.isLoading=false;
        vm.edit_activated = false;
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo errores al editar los permisos',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
    },
    initialize(){
      console.log("initialize permissions table")
      this.isLoading = true
      return axios
          .get("/permissions/"+this.internal_position_id+"/"+this.unit_id+"/permissions_list_by_internal_position.json")
          .then(res => {
            this.permissions = res.data.granted_permissions
            this.permissions.sort( (a,b) => b.state - a.state)
            console.log(this.permissions)
            this.isLoading = false
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
          })
    },
    activateEdit(){
      this.isLoading = true
      this.edit_activated = !this.edit_activated
      this.isLoading = false
    },
    showModal(row){
      this.isLoading = true
      this.id = row.id
      this.complete_rut = row.run.rut.toString() + "-"+row.run.dv
      this.corporate_mail = row.person.corporate_mail
      this.complete_name = row.complete_name
      this.show = true
      this.isLoading = false
    },
    editModal(row){
      this.isLoading = true
      this.id = row.id
      this.personId = row.person.id
      this.queryType = "update"
      this.person = row.person
      this.edit = true
      this.isLoading = false
    },
    addModal(){
      this.isLoading = true
      this.add = true
      this.queryType = "create"
      this.isLoading = false
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    }
  },
  watch: {
    add: function () {
      if (!this.add) {
        this.initialize()
      }
    },
    unit_id: function(){
      this.initialize()
    },
    internal_position_id: function(){
      this.initialize()
    },
    edit: function () {
      if (!this.edit) {
        this.initialize()
      }
    },
  }
}
</script>