<template>
  <section>
    <div class="agreementnewform">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <div class="container is-desktop">
          <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
            <b-message type="is-danger" has-icon>
              <div v-for="(item,index) in errores_validacion" :key='index'>
                {{ item[0] }}
              </div>
            </b-message>
          </div>
         <div class="box" >
           <b-steps v-model="activeStep">
                 <b-step-item :clickable="true" step="1" label="Información general">
                     <div class="columns">
                          <div class="column">
                              <div class="field">
                                  <div class="field-label is-normal">
                                      <label class="label" style="text-align:left;">Fecha de inicio</label>
                                  </div>
                                  <div class="field-body">
                                      <div class="field">
                                          <b-datepicker placeholder="Formato de Fecha: dd/mm/aaaa"
                                                      :value="agreementStartDate != null ? agreementStartDate : null"
                                                      icon="calendar-alt"
                                                      v-model="agreementStartDate"
                                                      locale="es-ES"
                                                      editable>
                                                      </b-datepicker>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="column">
                              <div class="field">
                                  <div class="field-label is-normal">
                                      <label class="label" style="text-align:left;">Fecha de término</label>
                                  </div>
                                  <div class="field-body">
                                      <b-field :type="{ 'is-danger':  checkDisabledEndDate}"
                                               :message="{ 'Debe seleccionar fecha de termino':  checkDisabledEndDate}">
                                          <b-datepicker placeholder="Formato de Fecha: dd/mm/aaaa"
                                                      :min-date="minBenginningEndDate"
                                                      :value="agreementEndDate"
                                                      icon="calendar-alt"
                                                      v-model="agreementEndDate"
                                                        locale="es-ES"
                                                        editable
                                                      :disabled="disabled">
                                                      </b-datepicker>
                                      </b-field>
                                  </div>
                                  </div>
                              </div>
                          <div class="column">
                            <b-field label="¿Es indefinido?">
                              <b-radio-button v-model="disabled"
                                              :native-value="false"
                                              type="is-danger is-light is-outlined">
                                <b-icon icon="times-circle"></b-icon>
                                <span>No</span>
                              </b-radio-button>
                              <b-radio-button v-model="disabled"
                                              :native-value="true"
                                              @input="isDisabled"
                                              type="is-success is-light is-outlined">
                                <b-icon icon="check-circle"></b-icon>
                                <span>Si</span>
                              </b-radio-button>
                            </b-field>
                          </div>
                      </div>
                      <div class="columns">
                          <div class="column">
                          <div class="field">
                            <div class="field-label is-normal">
                              <label class="label" style="text-align:left;">N° de resolución</label>
                            </div>
                            <b-field
                                :type="{ 'is-danger': !checkIsNumber }"
                                :message="{ 'Ingrese un valor válido (sólo números positivos).': !checkIsNumber }">
                              <b-input type="text"
                                       placeholder="Ingrese número de resolución"
                                       maxlength="9"
                                       :has-counter="false"
                                       :value="agreementResolutionNumber!==undefined ? agreementResolutionNumber : ''"
                                       v-model="agreementResolutionNumber">
                              </b-input>
                            </b-field>
                          </div>
                        </div>
                          <div class="column">
                          <div class="field">
                            <div class="field-label is-normal">
                              <label class="label" style="text-align:left;">Fecha de resolución</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <b-datepicker placeholder="Formato de Fecha: dd/mm/aaaa"
                                              :value="agreementInfo.creation_date"
                                              icon="calendar-alt"
                                              locale="es-ES"
                                              editable
                                              v-model="agreementInfo.creation_date">
                                </b-datepicker>
                              </div>
                            </div>
                          </div>
                        </div>
                          <div class="column">
                              <div class="field">
                                  <div class="field-label is-normal">
                                      <label class="label" style="text-align:left;">Tipo de resolución</label>
                                  </div>
                                  <b-field>
                                    <multiselect
                                          v-model="agreementResolutionName"
                                          :options="resolution_types.map(type => type.id)"
                                          :custom-label="opt => resolution_types.find(x => x.id === opt).name"
                                          placeholder="Seleccione un tipo de resolución"
                                          selectLabel="Presione para seleccionar"
                                          selectedLabel="Seleccionado"
                                          deselectLabel="Presione para deseleccionar"
                                          :allow-empty="true">
                                    </multiselect>
                                  </b-field>
                              </div>
                          </div>
                      </div>
                      <div class="columns">
                        <div class="column">
                          <div class="field">
                            <div class="field-label is-normal">
                              <label class="label" style="text-align:left;">Nombre del convenio*</label>
                            </div>
                            <b-field
                                :type="{ 'is-danger': checkAgreementName }"
                                :message="{ 'Ingrese un nombre para el convenio.': checkAgreementName }">
                              <b-input type="text"
                                       placeholder="Ingrese el nombre del convenio"
                                       maxlength="255"
                                       :value="agreementName!==undefined ? agreementName : ''"
                                       v-model="agreementName">
                              </b-input>
                            </b-field>
                          </div>
                        </div>
<!--                        <div class="column">
                          <div class="field">
                            <div class="field-label is-normal">
                              <label class="label" style="text-align:left;">Monto</label>
                            </div>
                            <b-field
                                :type="{ 'is-danger': !checkIsNumberAmount }"
                                :message="{ 'Ingrese un valor válido (sólo números positivos).': !checkIsNumberAmount }">
                              <b-input  type="text"
                                        placeholder="Ingrese Monto"
                                        maxlength="255"
                                        :has-counter="false"
                                        :value="agreementAmount!==undefined ? agreementAmount : ''"
                                        v-model="agreementAmount">
                              </b-input>
                            </b-field>
                          </div>
                        </div>-->
                        <div class="column">
                          <div class="field">
                            <div class="field-label is-normal">
                              <label class="label" style="text-align:left;">Usuario del convenio</label>
                            </div>
                            <b-field
                                :type="{ 'is-danger': false }"
                                :message="{ 'Seleccione un tipo de convenio general.': false }">
                              <multiselect
                                  v-model="agreementUnit"
                                  :options="agreement_units.map(type => type.id)"
                                  :custom-label="opt => agreement_units.find(x => x.id === opt).name"
                                  placeholder="Seleccione un departamento"
                                  selectLabel="Presione para seleccionar"
                                  selectedLabel="Seleccionado"
                                  deselectLabel="Presione para deseleccionar"
                                  :allow-empty="true">
                              </multiselect>
                            </b-field>
                          </div>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column">
                          <div class="field">
                            <div class="field-label is-normal">
                              <label class="label" style="text-align:left;">Tipo de convenio general*</label>
                            </div>
                            <b-field
                                :type="{ 'is-danger': checkAgreementGeneralType }"
                                :message="{ 'Seleccione un tipo de convenio general.': checkAgreementGeneralType }">
                              <multiselect
                                  v-model="agreementGeneralType"
                                  :options="agreement_general_types.map(type => type.id)"
                                  :custom-label="opt => agreement_general_types.find(x => x.id === opt).name"
                                  placeholder="Seleccione un tipo de convenio general"
                                  selectLabel="Presione para seleccionar"
                                  selectedLabel="Seleccionado"
                                  deselectLabel=""
                                  :allow-empty="false">
                              </multiselect>
                            </b-field>
                          </div>
                        </div>
                        <div class="column">
                          <div class="field">
                            <div class="field-label is-normal">
                              <label class="label" style="text-align:left;">Tipo de convenio específico*</label>
                            </div>
                            <b-field
                                :type="{ 'is-danger': checkAgreementSpecificType }"
                                :message="{ 'Seleccione un tipo de convenio específico.': checkAgreementSpecificType }">
                              <multiselect
                                  v-model="agreementSpecificType"
                                  :options="agreement_specific_types.map(type => type.id)"
                                  :custom-label="opt => agreement_specific_types.find(x => x.id === opt).name"
                                  placeholder="Seleccione un tipo de convenio específico"
                                  selectLabel="Presione para seleccionar"
                                  selectedLabel="Seleccionado"
                                  deselectLabel=""
                                  :allow-empty="false">
                              </multiselect>
                            </b-field>
                          </div>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column">
                          <div class="field">
                            <div class="field-label is-normal">
                              <label class="label" style="text-align:left;">Descripción del convenio*</label>
                            </div>
                            <b-field
                                :type="{ 'is-danger': checkAgreementDescription }"
                                :message="{ 'Ingrese una breve descripción sobre el convenio.': checkAgreementDescription }">
                              <b-input type="textarea"
                                       placeholder="Ingrese una descripción sobre el convenio"
                                       maxlength="5000"
                                       :value="agreementDescription!==undefined ? agreementDescription : ''"
                                       v-model="agreementDescription">
                              </b-input>
                            </b-field>
                          </div>
                        </div>

                      </div>
<!--
                      <div class="columns">
                        <div class="column">
                          <div class="field">
                            <div class="field-label is-normal">
                              <label class="label" style="text-align:left;">Glosa de servicio*</label>
                            </div>
                            <b-field
                                :type="{ 'is-danger': checkAgreementServiceGloss }"
                                :message="{ 'Ingrese una breve descripción para la glosa de servicio.': checkAgreementServiceGloss }">
                              <b-input type="textarea"
                                       placeholder="Ingrese brevemente la descripción de la glosa de servicio."
                                       maxlength="255"
                                       :value="agreementServiceGloss!==undefined ? agreementServiceGloss : ''"
                                       v-model="agreementServiceGloss">
                              </b-input>
                            </b-field>
                          </div>
                        </div>
                      </div>
-->

                   <div class="columns">
                     <div class="column">
                       <div class="field">
                         <div class="field-label is-normal">
                           <label class="label" style="text-align:left;">Link del convenio</label>
                         </div>
                         <b-field
                             :type="{ 'is-danger': checkAgreementLink,
                                       'is-danger': checkAgreementLinkFormat }"
                             :message="[//{ 'El link del convenio debe existir.': checkAgreementLink },
                                         { 'Formato no válido.': checkAgreementLinkFormat }]">
                           <b-input type="text"
                                    placeholder="https://link.com"
                                    maxlength="255"
                                    :value="agreementLink!==undefined ? agreementLink : ''"
                                    v-model="agreementLink">
                           </b-input>
                         </b-field>
                       </div>
                     </div>
                     <div class="column is-narrow">
                       <b-field label="Internacionalidad">
                         <b-radio-button v-model="international"
                                         :native-value="false"
                                         type="is-success is-light is-outlined">
                           <span>VIME Nacional</span>
                         </b-radio-button>
                         <b-radio-button v-model="international"
                                         :native-value="true"
                                         type="is-success is-light is-outlined">
                           <span>VIME Internacional</span>
                         </b-radio-button>
                       </b-field>
                     </div>

                   </div>
                 </b-step-item >
                 <b-step-item :clickable="true" step="2" label="Proceso actual">
                   <div class="columns">
                     <div class="column">
                       <div class="field">
                         <div class="field-label is-normal">
                           <label class="label" style="text-align:left;">Etapa del convenio*</label>
                         </div>
                         <b-field
                             :type="{ 'is-danger': checkAgreementStage }"
                             :message="{ 'Seleccione una etapa.': checkAgreementStage }">
                           <multiselect
                               v-model="agreementStage"
                               :options="agreement_stages.map(type => type.id)"
                               :custom-label="opt => agreement_stages.find(x => x.id === opt).name"
                               placeholder="Seleccione una etapa"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel=""
                               @select="disabledProceso()"
                               :allow-empty="false">
                           </multiselect>
                         </b-field>
                       </div>
                     </div>
                   </div>
                   <div class="columns">
                     <div class="column">
                       <div class="field">
                         <div class="field-label is-normal">
                           <label class="label" style="text-align:left;">Proceso del convenio*</label>
                         </div>
                         <b-field
                             :type="{ 'is-danger': checkAgreementState }"
                             :message="{ 'Seleccione un proceso.': checkAgreementState }">
                           <multiselect
                               v-model="agreementState"
                               :options="filtered_agreement_processes.map(type => type.id)"
                               :custom-label="opt => filtered_agreement_processes.find(x => x.id === opt).name"
                               placeholder="Seleccione un proceso"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel=""
                               :disabled="procesDisabled"
                               :allow-empty="false">
                           </multiselect>
                         </b-field>
                       </div>
                     </div>
                   </div>

                 </b-step-item>
                 <b-step-item :clickable="true" step="3" label="Participantes">
                   <div class="columns">
                     <div class="column">
                       <div class="field">
                         <div class="field-label is-normal">
                           <label class="label" style="text-align:left;">Participante*</label>
                         </div>
                         <b-field
                             :type="{ 'is-danger': (checkAcademicRepresentative || isAcademicRegister) }"
                             :message="{ 'Seleccione un participante registrado.': (checkAcademicRepresentative || isAcademicRegister) }">
                           <b-autocomplete
                               v-model="person_name"
                               placeholder="Ingrese nombre de la persona"
                               :keep-first="keepFirst"
                               :open-on-focus="openOnFocus"
                               :data="person_search"
                               field="nombre_completo"
                               @typing="searchPerson"
                               @select="watchFunction"
                               :clearable="clearable"
                           >
                           </b-autocomplete>
                         </b-field>
                       </div>
                     </div>
                     <div class="column" v-if="function_obj !=  null && function_obj.length > 0">
                       <div class="field">
                         <div class="field-label is-normal">
                           <label class="label" style="text-align:left;">Cargo*</label>
                         </div>
                         <b-field :type="{ 'is-danger': validation_position }"
                                  :message="{ 'Seleccione algún cargo.': validation_position }">
                           <multiselect v-model="function_selected"
                                        :options="function_obj.map(x => x.id)"
                                        :custom-label="opt => function_obj.find(x => x.id == opt).name"
                                        placeholder="Seleccione cargo"
                                        selectLabel="Presione para seleccionar"
                                        selectedLabel="Seleccionado"
                                        deselectLabel="No se puede deseleccionar"
                                        @select="validation_position = false"
                                        :allow-empty="false">
                             <template v-slot:noOptions>
                               No existen datos
                             </template>
                             <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                           </multiselect>
                         </b-field>
                       </div>
                     </div>
                     <div class="column is-narrow">
                       <br>
                       <b-field label=" ">
                         <b-tooltip label="Si la persona no se encuentra o no existe y ademas es externa, la puede agregar presionando el botón +"
                                    multilined
                                    position="is-left">
                           <b-button class="is-primary is-outlined" @click="addExternalPerson">
                             <b-icon icon="plus"></b-icon>
                           </b-button>
                         </b-tooltip>
                       </b-field>
                     </div>
                   </div>
                   <div class="columns">
                     <div class="column">
                       <div class="field">
                         <div class="field-label is-normal">
                           <label class="label" style="text-align:left;">Tipo de participación*</label>
                         </div>
                         <b-field
                             :type="{ 'is-danger': checkParticipantsType }"
                             :message="{ 'Seleccione el tipo de participanción.': checkParticipantsType }">
                           <multiselect
                               v-model="participantsType"
                               :options="participants_type.map(type => type.id)"
                               :custom-label="opt => participants_type.find(x => x.id === opt).name"
                               placeholder="Seleccione el tipo de participación"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel=""
                               :allow-empty="false">
                           </multiselect>
                         </b-field>
                       </div>
                     </div>
                   </div>
                   <div class="columns">
                     <div class="column">
                       <b-button type="is-primary" @click="addAcademic(person_name)">
                         <b-icon icon="arrow-down"></b-icon>
                         <span>Agregar participante</span>
                       </b-button>
                     </div>
                   </div>
                   <div class="columns">
                     <div class="column">
                       <div>
                         <br>
                         <b-table
                             :data="agreementInfo.participants"
                             :paginated=true
                             per-page=5
                             striped
                             hoverable
                             :pagination-simple=false
                             pagination-position="bottom"
                             default-sort-direction="asc"
                             sort-icon="arrow-up"
                             sort-icon-size="is-small"
                             aria-next-label="Siguiente"
                             aria-previous-label="Anterior"
                             aria-page-label="Página"
                             aria-current-label="Página actual">
                           <b-table-column
                               field= 'person_name'
                               label= 'Nombre Participante'
                               v-slot="props"
                           >
                             {{props.row.person_name}}
                           </b-table-column>
                           <b-table-column
                               field= 'person_mail'
                               label= 'Email'
                               v-slot="props"
                           >
                             {{props.row.person_mail}}
                           </b-table-column>
                           <b-table-column
                               field= 'person_position'
                               label= 'Cargo'
                               v-slot="props"
                           >
                             {{ props.row.person_function ==  null ?  props.row.person_position :  props.row.person_function.name}}
                           </b-table-column>
                           <b-table-column
                               field= 'participant_type'
                               label= 'Tipo de participación'
                               v-slot="props"
                           >
                             {{props.row.participant_type_name}}
                           </b-table-column>
                           <b-table-column
                               label="Acción"
                               v-slot="props"
                               centered>
                             <button class="button is-danger" @click="confirmCustomDeleteAcademic(props.row)">
                               <b-icon icon="times"></b-icon>
                               <span>Eliminar</span>
                             </button>
                           </b-table-column>
                           <template slot="empty" slot-scope="props">
                             <div class="columns is-centered">
                               <div class="column is-6 is-centered has-text-centered">
                                 <b-message has-icon type="is-danger">
                                   <h1 v-if="agreementInfo.participants.length == 0">No hay datos. Para guardar el convenio debe agregar al menos un participante.</h1>
                                 </b-message>
                               </div>
                             </div>
                           </template>
                         </b-table>
                       </div>
                     </div>
                   </div>

                 </b-step-item>
                 <b-step-item :clickable="true" step="4" label="Entidades">
                        <div class="columns">
                          <!--                          <div class="column">
                            <b-field label="Tipo de entidad*">
                              <multiselect v-model="entity_type_id"
                                           :options="entityTypes.map(type => type.id)"
                                           :custom-label="opt => entityTypes.find(x => x.id == opt).name"
                                           placeholder="Seleccione tipo de entidad"
                                           selectLabel="Presione para seleccionar"
                                           selectedLabel="Seleccionado"
                                           deselectLabel="No se puede deseleccionar"
                                           :allow-empty="false"
                                           @input="getEntityByType()">
                                <template v-slot:noOptions>
                                  No existen datos.
                                </template>
                                <span slot="noResult">
                                  No se encontraron elementos.
                                </span>
                              </multiselect>
                            </b-field>
                          </div>-->
                          <div class="column">
                            <b-field
                                :type="{ 'is-danger': (checkPartnerEntityName || isPartnerEntityRegister) }"
                                :message="{ 'Debe añadir una entidad registrada': (checkPartnerEntityName || isPartnerEntityRegister) }">
                              <template #label>
                                Nombre Entidad Externa* (ingrese por lo menos 3 letras)
                                <b-tooltip
                                    label="Se recomienda búsqueda de agrupaciones por sigla, ejemplo: ANID, no Agencia Nacional... mientras que para instituciones educacionales, se recomienda por nombre completo, ejemplo: universidad de santiago de chile"
                                    position="is-right"
                                    size="is-medium"
                                    multilined>

                                  <b-icon
                                      pack="fas"
                                      icon="info-circle"
                                      size="is-small"
                                      type="is-info">
                                  </b-icon>
                                </b-tooltip>
                              </template>
                              <b-autocomplete
                                  v-model="partnerEntityName"
                                  placeholder="e.g. CODELCO"
                                  :keep-first="keepFirst"
                                  :open-on-focus="openOnFocus"
                                  :data="partner_entities_search"
                                  field="show_name"
                                  @typing="searchPartnerEntities"
                                  @select="option => (selected = option)"
                                  :clearable="clearable"
                              >
                                <template slot-scope="props">
                                  {{ props.option.show_name ? props.option.show_name : props.option.name}}
                                </template>
                              </b-autocomplete>
                            </b-field>
                          </div>
                          <div class="column is-narrow">
                            <br>
                            <b-field label=" ">
                              <b-tooltip label="Si la entidad que desea agregar no existe, la puede agregar presionando el botón +"
                                         position="is-left">
                                <b-button class="is-primary is-outlined" @click="addEntity()">
                                  <b-icon icon="plus"></b-icon>
                                </b-button>
                              </b-tooltip>
                            </b-field>
                          </div>

                      </div>
                      <!--div v-if="hidenContact" class="field">
                          <div class="field-label is-normal">
                              <label class="label" style="text-align:left;">Representante de la entidad del presente convenio*</label>
                          </div>
                          <b-field
                              :type="{ 'is-danger': checkPartnerEntityApplicant }"
                              :message="{ 'Ingrese un nombre para el representante.': checkPartnerEntityApplicant }">
                              <b-input type="text"
                                      placeholder="Ingrese el nombre del representante"
                                      maxlength="255"
                                      v-model="partnerEntityApplicant">
                              </b-input>
                          </b-field>
                      </div>
                      <div v-if="hidenContact" class="field">
                          <div class="field-label is-normal">
                              <label class="label" style="text-align:left;">Email del representante*</label>
                          </div>
                          <b-field
                              :type="{ 'is-danger': (checkPartnerEntityApplicantEmail || checkPartnerEntityApplicantEmailFormat) }"
                              :message="{ 'Ingrese un email para el representante.': checkPartnerEntityApplicantEmail,
                                          'Formato invalido.': checkPartnerEntityApplicantEmailFormat}">
                              <b-input type="text"
                                      placeholder="Ingrese un email del representante"
                                      maxlength="255"
                                      v-model="partnerEntityApplicantEmail">
                              </b-input>
                          </b-field>
                      </div>
                      <div v-if="hidenContact" class="field">
                          <div class="field-label is-normal">
                              <label class="label" style="text-align:left;">Teléfono del representante*</label>
                          </div>
                          <b-field
                              :type="{ 'is-danger': (errors.phoneError || checkPartnerEntityApplicantPhone) }"
                              :message="[
                                      { 'Ingrese el teléfono de la entidad.': checkPartnerEntityApplicantPhone },
                                      { 'El teléfono debe tener entre 9 y 12 números. No se permiten letras ni simbolos': errors.phoneLength },
                                      { 'El teléfono no puede contener números negativos.': errors.phoneNegativeNumbers}
                                  ]">
                              <b-input type="number"
                                      placeholder="Ingrese teléfono del representante"
                                      v-model="partnerEntityApplicantPhone">
                              </b-input>
                          </b-field>
                      </div>-->
                      <b-button type="is-primary" @click="addPartnerEntityContact(partnerEntityName, partnerEntityApplicant)">
                          <b-icon icon="arrow-down"></b-icon>
                          <span>Agregar entidad</span>
                      </b-button>
                      <div >
                          <br>
                          <b-table
                              :data="agreementEntitiesAsociated"
                              :paginated=true
                              per-page=5
                              striped
                              hoverable
                              :pagination-simple=false
                              pagination-position="bottom"
                              default-sort-direction="asc"
                              sort-icon="arrow-up"
                              sort-icon-size="is-small"
                              aria-next-label="Siguiente"
                              aria-previous-label="Anterior"
                              aria-page-label="Página"
                              aria-current-label="Página actual">
                              <b-table-column
                                  field= 'partner_entity_data.alt_full_name'
                                  label= 'Entidad'
                                  v-slot="props"
                              >
                                  {{props.row.partner_entity_data.alt_full_name == null ? props.row.partner_entity_data.alt_name:props.row.partner_entity_data.alt_full_name}}
                              </b-table-column>
                              <b-table-column
                                  field= 'partner_entity_data.entity_type_name'
                                  label= 'Tipo de entidad'
                                  v-slot="props"
                              >
                                  <div>{{props.row.partner_entity_data.entity_type_name != null ? props.row.partner_entity_data.entity_type_name:props.row.entity_type_name}}</div>
                              </b-table-column>
                              <b-table-column
                                  field= 'partner_entity_data.email'
                                  label= 'Email'
                                  v-slot="props"
                              >
                                  <div>{{props.row.partner_entity_data.email}}</div>
                              </b-table-column>
                              <b-table-column
                                  field= 'partner_entity_data.phone'
                                  label= 'Teléfono'
                                  v-slot="props"
                              >
                                  <div>{{props.row.partner_entity_data.phone}}</div>
                              </b-table-column>
                              <b-table-column
                                  label="Acción"
                                  v-slot="props"
                                  centered>
                                  <button class="button is-danger" @click="confirmCustomDeleteEntity(props.row)">
                                      <b-icon icon="times"></b-icon>
                                      <span>Eliminar</span>
                                  </button>
                              </b-table-column>
                              <template slot="empty" slot-scope="props">
                                <div class="columns is-centered">
                                  <div class="column is-6 is-centered has-text-centered">
                                    <b-message has-icon type="is-danger">
                                      <h1 v-if="isAgreementEntitiesAsociatedVoid">No hay datos. Para guardar el convenio debe agregar al menos una entidad.</h1>
                                    </b-message>
                                  </div>
                                </div>
                              </template>
                          </b-table>
                      </div>
                 </b-step-item>
             </b-steps>
           <br>
           <br>

           <div v-show="!from_academic" class="columns">
               <div class="column">
                 <div class="is-pulled-left">
                   <button class="button is-link is-light" @click="back()">Volver</button>
                 </div>
               </div>
               <div class="column">
                 <div class="is-pulled-right" v-if="edit_case == false">
                   <b-button class="button is-primary" @click="save(0)">Guardar Convenio</b-button>
                 </div>
                 <div class="is-pulled-right" v-else>
                   <b-button class="button is-primary" @click="save(1)">Guardar Convenio</b-button>
                 </div>
               </div>
              </div>
         </div>
       </div>
      </ValidationObserver>
      <b-modal
          v-model="isAddModalEntityActive"
          :destroy-on-hide="true"
          width="50%"
          :can-cancel="canCancel">
        <template #default="props">
          <modal_partner_entity
              query-type="update"
              modal-header="Añadir nueva entidad"
              @close="props.close"
              @clicked="onClickChild"></modal_partner_entity>
        </template>
      </b-modal>
      <b-modal
          v-model="isPersonModal"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <modal_external_people_create
              query-type="create"
              modal-header="Agregar datos personales"
              @clicked="onClickChildPerson"
              @close="props.close"></modal_external_people_create>
        </template>
      </b-modal>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
  </section>
</template>
<script>
import axios from "axios"
import modal_partner_entity from "./modal_partner_entity";
import modal_external_people_create from "../../../person/modal_external_people_create";
import unit_data_table from "../../../unit_data_table";

export default {
    name: "agreementnewform",
    props:{
        edit_case:{
            type: Boolean,
            default: false
        },
        agreement_id:{
            type: Number
        },
        step:{
            type: Number,
            default: 0
        },
        entity_id:{
            type: Number,
            default: 0
        },
        from_academic:{
          type: Boolean,
          default: false,
        },
        add_agreement:{
          type: Boolean
        }
    },
    components:{
        modal_partner_entity, modal_external_people_create
    },
    data (){
        return {
            // Para configuración de autocompletes
            procesDisabled: true,
            keepFirst: false,
            openOnFocus: false,
            selected: null,
            clearable: false,
            isAddModalEntityActive: false,
            canCancel: false,
            isAcademicRegister: false,
            isPartnerEntityRegister: false,
            hidenContact: false,
            checkIsNumber: true,
            checkIsNumberAmount: true,
            agreement_stage_id: null,
            agreement_state_id: null,
            errores_validacion: {},
            disabled: false,
            person_name: null,
            person_search:[],
            isPersonModal: false,
            work_plant: [],
            function_obj: [],
            function_selected: null,
            work_plant_selected: null,

            // Para obtener datos previo al renderizado de la página
            isLoading:true,
            activeStep: 0,
            partner_entities: [],
            agreement_general_types: [],
            agreement_specific_types: [],
            agreement_stages: [],
            agreement_processes: [],
            resolution_types: [],
            participants_type: [],
            departaments: [],
            list_departaments:[],
            filtered_agreement_processes: [],
            filtered_academics: [],
            isSubmiting: false,
            entityTypes: [],
            entity_type_id: null,
            international: false,

            // Para almacenamiento temporal
            partner_entity_name: '',
            partner_entity_applicant: '',
            partner_entity_applicant_email: '',
            partner_entity_applicant_phone: '',
            dpto_id: null,
            dpto_name: '',
            academic_id: null,
            academic_name: '',
            participants_type_name: '',
            participants_type_id: null,
            resolution_name: null,
            agreement_general_type_name: null,
            agreement_specific_type_name: '',
            partner_entities_search:[],
            validation_position: false,
            agreementUnit: null,
            agreement_units: [],
            

            // Para envío de datos
            agreementInfo:{
                name: '',
                applicant: '',
                description: '',
                agreement_state: '',
                agreement_stage: '',
                current_manager: '',
                service_gloss: '',
                link: '',
                creation_date: null,
                start_date: null,
                end_date: null,
                amount: '',
                agreement_type_id: 0,
                resolution_type_id: 0,
                resolution_number: '',
                partner_entities: [],
                academics: [],
                participants:[],
            },
            
            // Para validaciones
            validations:{
                isAgreementNameEmpty: true,
                isAgreementApplicantEmpty: true,
                isAgreementDescriptionEmpty: true,
                isAgreementStateEmpty: true,
                isAgreementStageEmpty: true,
                isAgreementCurrentManagerEmpty: true,
                isAgreementServiceGlossEmpty: true,
                isAgreementAmountEmpty: false,
                isAgreementLinkEmpty: true,
                isPartnerEntityNameEmpty: true,
                isPartnerEntityApplicantEmpty: true,
                isPartnerEntityApplicantPhoneEmpty: true,
                isPartnerEntityApplicantEmailEmpty: true,
                isAgreementGeneralTypeNameEmpty: true,
                isAgreementSpecificTypeNameEmpty: true,
                isAgreementLinkFormat: false,
                isAcademicDepartmentEmpty: true,
                isParticipantsTypeEmpty: true,
                isAcademicRepresentativeEmpty: true,
                isAgreementResolutionNumberEmpty: false,
                firstTimeName: true,
                firstTimeApplicant: true,
                firstTimeDescription: true,
                firstTimeState: true,
                firstTimeStage: true,
                firstTimeCurrentManager: true,
                firstTimeServiceGloss: true,
                firstTimeLink: true,
                firstTimePartnerEntityName: true,
                firstTimePartnerEntityApplicant: true,
                firstTimePartnerEntityApplicantPhone: true,
                firstTimePartnerEntityApplicantEmail: true,
                firstTimeAgreementGeneralTypeName: true,
                firstTimeAgreementSpecificTypeName: true,
                firstTimeAcademicDepartment: true,
                firstTimeAcademicRepresentative: true,
                firstTimeParticipantsType: true,

            },
          errors:{
            phoneError: false,
            phoneLength: false,
            phoneNegativeNumbers: false
          },

        }
    },
    computed: {
        // Filtros y visibilidad
        filteredDataAcademicsObj() {
            return this.filtered_academics.filter(option => option.name.toLowerCase().includes(this.academic_name.toLowerCase()))
        },
        filteredDataEntityObj() {
            return this.partner_entities.filter(option => option.name.toLowerCase().includes(this.partner_entity_name.toLowerCase())).slice(0,10)
        },
        // Atributos
        agreementStartDate:{
            get: function(){
                return this.agreementInfo.start_date
            },
            set: function(newDate){
                this.agreementInfo.start_date = newDate
                /*if(newDate > this.agreementInfo.end_date){
                    this.agreementInfo.end_date = newDate
                }*/
            }
        },
        agreementEndDate:{
            get: function(){
                return this.agreementInfo.end_date
            },
            set: function(newDate){
                if(newDate < this.agreementInfo.start_date){
                    this.agreementInfo.end_date = null
                }
                else{
                    this.agreementInfo.end_date = newDate
                }
            }
        },
        agreementName: {
            get: function(){
                return this.agreementInfo.name
            },
            set: function(newName){
                this.validations.firstTimeName = false
                if(newName === '' || newName === null)
                    this.validations.isAgreementNameEmpty = true
                else
                    this.validations.isAgreementNameEmpty = false
                this.agreementInfo.name = newName
            }
        },
        agreementApplicant: {
            get: function(){
                return this.agreementInfo.applicant
            },
            set: function(newApplicant){
                this.validations.firstTimeApplicant = false
                if(newApplicant === '')
                    this.validations.isAgreementApplicantEmpty = true
                else
                    this.validations.isAgreementApplicantEmpty = false
                this.agreementInfo.applicant = newApplicant
            }
        },
        agreementDescription:{
            get: function(){
                return this.agreementInfo.description
            },
            set: function(newDescription){
                this.validations.firstTimeDescription = false
                if(newDescription === '' || newDescription === null)
                    this.validations.isAgreementDescriptionEmpty = true
                else
                    this.validations.isAgreementDescriptionEmpty = false
                this.agreementInfo.description = newDescription
            }
        },
        agreementStage:{
            get: function(){
                return this.agreement_stage_id
            },
            set: function(newStage){
                this.agreement_stage_id = newStage
                this.validations.firstTimeStage = false
                var stageName = ''
                if(newStage === '' || newStage === null || newStage === undefined){
                    this.validations.isAgreementStageEmpty = true
                    this.filtered_agreement_processes = []
                }
                else{
                    this.validations.isAgreementStageEmpty = false
                    var selected_agreement_stage = ''
                    this.agreement_stages.forEach(function(element){
                        if(element.id === newStage){
                            stageName = element.name
                            selected_agreement_stage = element
                        }
                    })
                    this.filtered_agreement_processes = this.agreement_processes.filter(
                        element => element.agreement_stage_id === selected_agreement_stage.id
                    )
                }
                this.agreementInfo.agreement_stage = stageName
                this.agreementState = null
                this.validations.firstTimeState = true
            }
        },
        agreementState:{
            get: function(){
                return this.agreement_state_id
            },
            set: function(newState){
                var stateName
                this.agreement_state_id = newState
                this.validations.firstTimeState = false
                if(newState === '' || newState === null || newState === 'Vigente' || newState === undefined)
                    this.validations.isAgreementStateEmpty = true
                else{
                  this.validations.isAgreementStateEmpty = false
                  this.filtered_agreement_processes.forEach(function(element) {
                    if(element.id == newState){
                      stateName = element.name
                    }
                  })

                }

                this.agreementInfo.agreement_state = stateName
            }
        },
        agreementGeneralType:{
            get: function(){
                return this.agreement_general_type_name
            },
            set: function(newGeneralType){
                this.validations.firstTimeAgreementGeneralTypeName = false
                if(newGeneralType === '' || newGeneralType === null || newGeneralType === undefined)
                    this.validations.isAgreementGeneralTypeNameEmpty = true
                else
                    this.validations.isAgreementGeneralTypeNameEmpty = false
                this.agreement_general_type_name = newGeneralType
            }
        },
        agreementSpecificType:{
            get: function(){
                return this.agreement_specific_type_name
            },
            set: function(newSpecificType){
                this.validations.firstTimeAgreementSpecificTypeName = false
                if(newSpecificType === '' || newSpecificType === null)
                    this.validations.isAgreementSpecificTypeNameEmpty = true
                else
                    this.validations.isAgreementSpecificTypeNameEmpty = false
                this.agreement_specific_type_name = newSpecificType
            }
        },
        agreementCurrentManager:{
            get: function(){
                return this.agreementInfo.current_manager
            },
            set: function(newCurrentManager){
                this.validations.firstTimeCurrentManager = false
                if(newCurrentManager === '' ||  newCurrentManager === null)
                    this.validations.isAgreementCurrentManagerEmpty = true
                else
                    this.validations.isAgreementCurrentManagerEmpty = false
                this.agreementInfo.current_manager = newCurrentManager
            }
        },
        agreementServiceGloss:{
            get: function(){
                return this.agreementInfo.service_gloss
            },
            set: function(newServiceGloss){
                this.validations.firstTimeServiceGloss = false
                if(newServiceGloss === '' || newServiceGloss === null)
                    this.validations.isAgreementServiceGlossEmpty = true
                else
                    this.validations.isAgreementServiceGlossEmpty = false
                this.agreementInfo.service_gloss = newServiceGloss
            }
        },
        agreementAmount:{
            get: function(){
                return this.agreementInfo.amount
            },
            set: function(newAmount){
              if(newAmount == ''){
                this.validations.isAgreementAmountEmpty = true
                this.checkIsNumberAmount = true
              }
              else{
                this.validations.isAgreementAmountEmpty = false
                var number = Number(newAmount)
                if(typeof number === 'number'){
                  if(number >= 0 ){
                    this.checkIsNumberAmount = true
                  }
                  else{
                    this.checkIsNumberAmount = false
                  }
                }
              }
              this.agreementInfo.amount = newAmount
            }
        },
        departamentName:{
            get: function(){
                return this.dpto_id
            },
            set: function(newDepartment){
              this.dpto_id = newDepartment
                this.validations.firstTimeAcademicDepartment = false
                if(newDepartment == '' || newDepartment == null || newDepartment == undefined){
                    this.validations.isAcademicDepartmentEmpty = true
                }
                else{
                    this.validations.isAcademicDepartmentEmpty = false
                }
                this.departaments.departamentos.forEach(element =>{
                    if(element.unit_id == newDepartment){
                        this.dpto_name = element.name
                    }
                })
                axios.get("/people/agreement/participants.json",{
                  params: {name: this.query}
                })
                .then(response =>{
                    this.filtered_academics = response.data
                })
                .catch(e =>{
                    console.log(e)
                })
            }
        },
        academicName:{
            get: function(){
                return this.academic_name
            },
            set: function(newAcademic){
                this.academic_name = newAcademic
                this.validations.firstTimeAcademicRepresentative = false
                if(newAcademic == '' || newAcademic == null || newAcademic == undefined){
                  console.log('AcademicRepresentative',this.agreementInfo.participants.length)
                  if (this.agreementInfo.participants.length == 0){
                    this.validations.isAcademicRepresentativeEmpty = true
                  }
                  else{
                    this.validations.isAcademicRepresentativeEmpty = false
                  }

                }
                else{
                    this.validations.isAcademicRepresentativeEmpty = false
                }
                this.academic_id = null
                this.filtered_academics.forEach(element =>{
                    if(element.name == newAcademic){
                        this.academic_id = element.id
                        this.isAcademicRegister = false
                    }
                })
            }
        },
        participantsType:{
          get: function (){
            return this.participants_type_id
          },
          set: function (newParticipantsType){
            this.participants_type_id = newParticipantsType
            this.validations.firstTimeParticipantsType = false
            console.log("Tipo de participante",newParticipantsType)
            if(newParticipantsType == '' || newParticipantsType == null || newParticipantsType == undefined){

              if(this.agreementInfo.participants.length ==  0){
                this.validations.isParticipantsTypeEmpty = true
              }
              else{
                if(this.person_name != '' || this.person_name != null){
                  this.validations.isParticipantsTypeEmpty = true
                }
                this.validations.isParticipantsTypeEmpty = false
              }

            }
            else{
              this.validations.isParticipantsTypeEmpty = false
            }
            this.participants_type.forEach(element =>{
              if(element.id == newParticipantsType){
                this.participants_type_name = element.name
              }
            })

          }
        },
        partnerEntityName:{
            get: function(){
                return this.partner_entity_name
            },
            set: function(newPartnerEntityName){
                this.validations.firstTimePartnerEntityName = false
                if(newPartnerEntityName == ""){
                    this.validations.isPartnerEntityNameEmpty = true
                }
                else{
                    this.validations.isPartnerEntityNameEmpty = false
                }
                if (this.getPartnerEntityByName(newPartnerEntityName) == null){
                  this.isPartnerEntityRegister = true
                  this.hidenContact = false
                }
                else{
                  this.isPartnerEntityRegister = false
                  this.hidenContact = true
                }
              this.partner_entity_name = newPartnerEntityName

            }
        },
        partnerEntityApplicant:{
            get: function(){
                return this.partner_entity_applicant
            },
            set: function(newPartnerEntityApplicant){
                this.validations.firstTimePartnerEntityApplicant = false
                if(newPartnerEntityApplicant === '')
                    this.validations.isPartnerEntityApplicantEmpty = true
                else
                    this.validations.isPartnerEntityApplicantEmpty = false
                this.partner_entity_applicant = newPartnerEntityApplicant
            }
        },
        partnerEntityApplicantEmail:{
            get: function(){
                return this.partner_entity_applicant_email
            },
            set: function(newPartnerEntityApplicantEmail){
                this.validations.firstTimePartnerEntityApplicantEmail = false
                if(newPartnerEntityApplicantEmail === '')
                    this.validations.isPartnerEntityApplicantEmailEmpty = true
                else
                    this.validations.isPartnerEntityApplicantEmailEmpty = false
                this.partner_entity_applicant_email = newPartnerEntityApplicantEmail
            }
        },
        partnerEntityApplicantPhone:{
            get: function(){
                return this.partner_entity_applicant_phone
            },
            set: function(newPartnerEntityApplicantPhone){
                this.validations.firstTimePartnerEntityApplicantPhone = false
                if(newPartnerEntityApplicantPhone != null) {
                  var phoneLength = (newPartnerEntityApplicantPhone.length < 9 || newPartnerEntityApplicantPhone.length > 12)
                  var phoneNegative = (newPartnerEntityApplicantPhone  < 0)
                  var phoneEmpty = false
                  if(newPartnerEntityApplicantPhone.length < 9 || newPartnerEntityApplicantPhone.length > 12)
                    this.errors.phoneLength = true
                  else
                    this.errors.phoneLength = false
                  if(newPartnerEntityApplicantPhone < 0)
                    this.errors.phoneNegativeNumbers = true
                  else
                    this.errors.phoneNegativeNumbers = false
                  this.validations.isPartnerEntityApplicantPhoneEmpty = false
                  if(phoneLength || phoneNegative || phoneEmpty)
                    this.errors.phoneError = true
                  else
                    this.errors.phoneError = false
                  this.partner_entity_applicant_phone = newPartnerEntityApplicantPhone
                }
                else
                    this.errors.phoneError = true
            }
        },
        agreementLink:{
            get: function(){
                return this.agreementInfo.link
            },
            set: function(newLink){
                this.validations.firstTimeLink = false
                if(newLink == ""  || newLink == null){
                    this.validations.isAgreementLinkEmpty = true
                    this.validations.isAgreementLinkFormat = false
                    this.agreementInfo.link = null
                }
                else{
                    this.validations.isAgreementLinkEmpty = false
                    var regExp =  /http[s]?\:\/\/[a-zA-Z 0-9]+\.[a-z]+/
                    if(!regExp.test(newLink))
                        this.validations.isAgreementLinkFormat = true
                    else
                        this.validations.isAgreementLinkFormat = false
                }
                this.agreementInfo.link = newLink
            }
        },
        agreementResolutionName:{
            get: function(){
                return this.resolution_name
            },
            set: function(newResolutionName){
                this.resolution_name = newResolutionName
            }
        },
        agreementResolutionNumber:{
            get: function(){
                return this.agreementInfo.resolution_number
            },
            set: function(newResolutionNumber){
              if(newResolutionNumber == ''){
                this.validations.isAgreementResolutionNumberEmpty = true
                this.checkIsNumber = true
              }
              else{
                this.validations.isAgreementResolutionNumberEmpty = false
                var number = Number(newResolutionNumber)
                if(typeof number === 'number'){
                  if(number >= 0 ){
                    this.checkIsNumber = true
                  }
                  else{
                    this.checkIsNumber = false
                  }
                }
              }
              this.agreementInfo.resolution_number = newResolutionNumber
            }
        },
        agreementEntitiesAsociated(){
            return this.agreementInfo.partner_entities
        },
        agreementAcademicAsociated(){
            return this.agreementInfo.academics
        },
        checkAgreementName(){
            if(this.validations.firstTimeName === false)
                return this.validations.isAgreementNameEmpty
            else
                return false
        },
        checkAgreementApplicant(){
            if(this.validations.firstTimeApplicant === false)
                return this.validations.isAgreementApplicantEmpty
            else
                return false
        },
        checkAgreementDescription(){
            if(this.validations.firstTimeDescription === false)
                return this.validations.isAgreementDescriptionEmpty
            else
                return false
        },
        checkAgreementStage(){
            if(this.validations.firstTimeStage === false)
                return this.validations.isAgreementStageEmpty
            else
                return false
        },
        checkAgreementState(){
            if(this.validations.firstTimeState === false)
                return this.validations.isAgreementStateEmpty
            else
                return false
        },
        checkAgreementGeneralType(){
            if(this.validations.firstTimeAgreementGeneralTypeName === false)
                return this.validations.isAgreementGeneralTypeNameEmpty
            else
                return false
        },
        checkAgreementSpecificType(){
            if(this.validations.firstTimeAgreementSpecificTypeName === false)
                return this.validations.isAgreementSpecificTypeNameEmpty
            else
                return false
        },
        checkAgreementCurrentManager(){
            if(this.validations.firstTimeCurrentManager === false)
                return this.validations.isAgreementCurrentManagerEmpty
            else
                return false
        },
        checkAgreementServiceGloss(){
            if(this.validations.firstTimeServiceGloss === false)
                return this.validations.isAgreementServiceGlossEmpty
            else
                return false
        },
        checkAgreementAmount(){
            return this.validations.isAgreementAmountEmpty
        },
        checkAcademicDepartment(){
            if(this.validations.firstTimeAcademicDepartment === false)
                return this.validations.isAcademicDepartmentEmpty
            else
                return false
        },
        checkAcademicRepresentative(){
            if(this.validations.firstTimeAcademicRepresentative === false)
                return this.validations.isAcademicRepresentativeEmpty
            else
                return false
        },
        checkPartnerEntityName(){
            if(this.validations.firstTimePartnerEntityName === false)
                return this.validations.isPartnerEntityNameEmpty
            else
                return false
        },
        checkPartnerEntityApplicant(){
            if(this.validations.firstTimePartnerEntityApplicant === false)
                return this.validations.isPartnerEntityApplicantEmpty
            else
                return false
        },
        checkPartnerEntityApplicantPhone(){
            if(this.validations.firstTimePartnerEntityApplicantPhone === false)
                return this.validations.isPartnerEntityApplicantPhoneEmpty
            else
                return false
        },
        checkPartnerEntityApplicantEmail(){
            if(this.validations.firstTimePartnerEntityApplicantEmail === false)
                return this.validations.isPartnerEntityApplicantEmailEmpty
            else
                return false
        },
        checkPartnerEntityApplicantEmailFormat(){
          var regExp = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/
          if(this.validations.firstTimePartnerEntityApplicantEmail === false){
            if(this.partnerEntityApplicantEmail != null && this.partnerEntityApplicantEmail != ""){
              return !regExp.test(this.partnerEntityApplicantEmail)
            }
            else{
              return false
            }
          }
          else{
            return false
          }
        },
        checkAgreementLink(){
            if(this.validations.firstTimeLink === false)
                return this.validations.isAgreementLinkEmpty
            else
                return false
        },
        checkAgreementLinkFormat(){
            var regExp = /http[s]?\:\/\/[a-zA-Z 0-9]+\.[a-z]+/
            if(this.validations.firstTimeLink === false){
                if(this.agreementLink != null && this.agreementLink != ""){
                    return !regExp.test(this.agreementInfo.link)
                }
                else{
                    return false
                }
            }
            else{
                return false
            }
        },
        checkParticipantsType(){
          if(this.validations.firstTimeParticipantsType === false)
            return this.validations.isParticipantsTypeEmpty
          else
            return false
        },
        checkAgreementResolutionNumber(){
            return this.validations.isAgreementResolutionNumberEmpty
        },
        isAgreementEntitiesAsociatedVoid(){ //true = está vacio, false = no está vacio
            return Object.entries(this.agreementInfo.partner_entities).length == 0
        },
        isAgreementAcademicsAsociatedVoid(){
            return Object.entries(this.agreementInfo.participants).length == 0
        },
        minBenginningEndDate: function(){
            return this.agreementInfo.start_date
        },
        checkSubmiting: {
            get: function(){
                return this.isSubmiting
            },
            set: function(newState){
                this.isSubmiting = newState
            }
        },
      checkDisabledEndDate(){
          if (this.disabled == false){
            if (this.agreementStartDate != null){
              if(this.agreementEndDate == null){
                return true
              }
              else{
                return false
              }
            }
            else{
              return false
            }
          }
          else{
            return false
          }
      },
    },
    // Función que se ejecutará siempre previo a renderizar
    async beforeMount() {
        this.isLoading = true
        this.activeStep = this.step
        if(this.edit_case){
            this.procesDisabled = false
            this.getAgreement().then(() =>{
                this.checkEmptyFields()
            })
        }
        else{
          if (this.entity_id != 0){
            await this.loadEntity()
          }

          await this.getAgreementsGeneralTypes()
          await this.getAgreementsStages()
          await this.getAgreementsProcess()
          //this.getPartnerEntities()
          await this.getEntityTypes()
          await this.getDepartaments()
          await this.getResolutionTypes()
          await this.getAgreementSpecificTypes()
          await this.getParticipantsType()

        }

    },
    methods: {
        loadEntity(){
          return axios.get('/partner_entities/'+this.entity_id+'.json')
              .then(response =>{
                this.agreementInfo.partner_entities.push({
                  partner_entity_data: response.data
                })
              })
              .catch(e => {
                console.log(e)
                this.isLoading=false
              })
        },
        disabledProceso(){
          this.procesDisabled = false
        },
        addEntity(){
          this.isAddModalEntityActive = true;
        },
        getEntityByType(name){
          this.isLoading = true
          this.partnerEntityName = ''
          this.partner_entities_search = []
          this.isLoading = false
         /* axios.get('/admin/partner_entities/list_institution/'+this.entity_type_id+'.json')
              .then(response =>{

                this.partner_entities = response.data.partner_entities
                if (name != null){
                  this.partnerEntityName = name
                }
                this.isLoading = false
              })
              .catch(e => {
                console.log(e)
                this.isLoading = false
              })*/
        },
        // Consultas al backend
        getAgreement(){
            return axios.get('/agreements/'+this.agreement_id+'.json')
            .then(response =>{
              this.agreement_general_types       = response.data.agreement_general_types
              this.agreement_specific_types       = response.data.agreement_specifict_types
                this.agreement_stages = response.data.index_active_agreement_stages
                this.agreement_processes = response.data.index_active_agreement_processes
                this.departaments = response.data.departamentos
                this.list_departaments = response.data['departamentos']
                this.resolution_types = response.data.resolution_types
                this.participants_type = response.data.agreement_participant_types
                this.entityTypes = response.data.entity_types
                this.agreement_units = response.data.units
                if((response.data.unit_id == null || response.data.unit_id == undefined) && response.data.university_user == true){
                  this.agreementUnit = 0
                }
                else{
                  this.agreementUnit = response.data.unit_id
                }
                this.entityTypes.unshift({
                  id: 0,
                  name: 'TODOS',
                  created_at: '',
                  updated_at: '',
                })
                this.agreementName                  = response.data.name
                this.agreementApplicant             = response.data.applicant
                this.agreementDescription           = response.data.description
                this.agreementStage                 = response.data.agreement_stage_id
                this.agreementState                 = response.data.agreement_state_id
                this.agreementInfo.creation_date    = response.data.creation_date2 != null? new Date(response.data.creation_date2): null
                this.agreementStartDate             = response.data.start_date2 != null? new Date(response.data.start_date2): null
                this.agreementEndDate               = response.data.end_date2 != null? new Date(response.data.end_date2): null
                this.agreementGeneralType           = response.data.agreement_type.agreement_general_type_id
                this.agreementSpecificType          = response.data.agreement_type.agreement_specifict_type_id
                this.agreementCurrentManager        = response.data.current_manager 
                this.agreementServiceGloss          = response.data.service_gloss
                this.agreementAmount                = response.data.amount
                this.agreementLink                  = response.data.link
                this.agreementInfo.partner_entities = response.data.partner_entities
                //this.agreementInfo.academics        = response.data.academics
                this.agreementInfo.participants     = response.data.academics
                this.agreementResolutionName        = response.data.resolution_type_id
                this.agreementResolutionNumber      = response.data.resolution_number
                if (this.agreementEndDate == null){
                  this.disabled = true
                }
                this.international = response.data.is_international
                this.isLoading = false
            })
            .catch(e => {
                console.log(e)
                this.isLoading=false               
            })
        },
        async getAgreementsGeneralTypes(){
            axios.get('/index_active_agreement_general_types.json')
            .then( response =>{
                this.agreement_general_types = response.data
            })
            .catch(e => { 
                console.log(e)
              this.isLoading = false
            })
        },
        async getAgreementSpecificTypes(){
            axios.get('/index_active_agreement_specifict_types.json')
            .then( response =>{
                this.agreement_specific_types = response.data
            })
            .catch(e => { 
                console.log(e)
              this.isLoading = false
            })
        },
        async getAgreementsStages(){
            axios.get('/index_active_agreement_stages.json')
            .then( response =>{
                this.agreement_stages = response.data
            })
            .catch(e => { 
                console.log(e)
              this.isLoading = false
            })
        },
        async getAgreementsProcess(){
            axios.get('/index_active_agreement_processes.json')
            .then( response =>{
                this.agreement_processes = response.data
            })
            .catch(e => { 
                console.log(e)
              this.isLoading = false
            })
        },
        getPartnerEntities(){
            axios.get('/admin/partner_entities.json')
            .then( response =>{
                this.partner_entities = response.data
            })
            .catch(e => { 
                console.log(e)
              this.isLoading = false
            })
        },
        async getDepartaments(){
            axios.get('/unidades_convenio')
            .then( response =>{
                this.departaments = response.data
                this.agreement_units = response.data['departamentos']
                this.agreement_units.push(
                    {id: 0,
                    name:"UNIVERSIDAD DE SANTIAGO DE CHILE"}
                )
            })
            .catch(e => { 
                console.log(e)
              this.isLoading = false
            })
        },
        async getResolutionTypes(){
            axios.get('/resolution_types.json')
            .then( response =>{
                this.resolution_types = response.data
            })
            .catch(e => { 
                console.log(e)
              this.isLoading = false
            })
        },
        async getParticipantsType(){
          axios.get('/agreement_participant_types.json')
              .then( response =>{
                this.participants_type = response.data
                this.isLoading = false
              })
              .catch(e => {
                console.log(e)
                this.isLoading = false
              })
        },
        async getEntityTypes(){
          axios.get('/admin/entity_types.json')
              .then(response =>{
                this.entityTypes = response.data
                this.entityTypes.unshift({
                  id: 0,
                  name: 'TODOS',
                  created_at: '',
                  updated_at: '',
                })
              })
              .catch(e => {
                console.log(e)
                this.isLoading = false
              })
        },

        // get y setters para transformar ids a nombre y viceversa
        getPartnerEntityByName(name){
            var partnerEntity = null
            //console.log(this.partner_entities_search)
            this.partner_entities_search.forEach(function (element){
                if(element.name === name){
                    partnerEntity = element
                }
                else if(element.show_name === name){
                  partnerEntity = element
                }
            })
            return partnerEntity
        },
        getAgreementGeneralTypeIdByName(name){
            var id = 1
            this.agreement_general_types.forEach(function (element){
                if(element.name === name){
                    id = element.id
                }
            })
            return id
        },
        getAgreementSpecificTypeIdByName(name){
            var id = 1
            this.agreement_specific_types.forEach(function (element){
                if(element.name === name){
                    id = element.id
                }
            })
            return id
        },
        getResolutionIdByName(name){
            var id = null
            this.resolution_types.forEach(function (element){
                if(element.name === name){
                    id = element.id
                }
            })
            return id
        },

        // Métodos
        // Para añadir/eliminar un académico
        checkAcademicContact(){
            var confirmation = true
            var arrayConfirmation = []
            arrayConfirmation.push(this.person_name == '' || this.person_name == '')
            arrayConfirmation.push(this.validations.isParticipantsTypeEmpty)
            //arrayConfirmation.push(this.work_plant_selected == null && (this.work_plant !=  null && this.work_plant.length > 0))
            arrayConfirmation.push(this.function_selected == null && (this.function_obj != null && this.function_obj.length > 0))
            arrayConfirmation.forEach(function(element){
                if(element === true)
                    confirmation = false
            })
            return confirmation
        },
        checkAcademicContactEmptyFields(){
            if(this.validations.firstTimeAcademicDepartment === true){
                this.validations.firstTimeAcademicDepartment = false
            }
            if(this.validations.firstTimeAcademicRepresentative === true){
                this.validations.firstTimeAcademicRepresentative = false
            }
            if(this.validations.firstTimeParticipantsType === true){
              this.validations.firstTimeParticipantsType = false
            }
        },
        addAcademic(name){
            var confirmation = this.checkAcademicContact()
            var person_id = null
            var position = null
            var work_plant = null
            var function_id = null
            var person_mail = null
            var participants_type = this.participants_type_name
            var participantId = this.participantsType
            var error = null
            if(confirmation){
                var addPerson = true
                this.agreementInfo.participants.forEach(function(element){
                    if(element.person_name === name){
                      addPerson = false
                      error = 1
                    }
                    /*else if (element.participant_type_name == participants_type && participantId != null) {
                      addPerson = false
                      error = 2
                    }*/
                    else if(participantId == null){
                      addPerson = false
                      error=  3
                    }

                })
                if(addPerson){
                  this.person_search.forEach(function (element){
                    if(element.nombre_completo == name){
                      person_id = element.id
                      position = element.position
                      if(element.corporate_mail != null){
                        person_mail = element.corporate_mail
                      }
                      else if(element.personal_mail != null){
                        person_mail = element.personal_mail
                      }
                    }
                  })
                  if (person_id == null){
                    this.isAcademicRegister= true
                  }
                  else{
                    if(this.work_plant_selected != null){
                      work_plant = this.work_plant.find(x => x.id == this.work_plant_selected)
                    }

                    if(this.function_selected != null){
                      function_id = this.function_obj.find(x => x.id == this.function_selected)
                    }


                    this.agreementInfo.participants.push({
                      person_id: person_id,
                      person_name: name,
                      person_position: position,
                      person_work_plant: work_plant,
                      person_function: function_id,
                      person_mail: person_mail,
                      participant_type_id: this.participants_type_id,
                      participant_type_name: this.participants_type_name
                    })
                    this.person_search = []
                    this.person_name = null
                    this.participantsType = null
                    this.isAcademicRegister= false
                    this.function_obj = []
                    this.function_selected =  null
                    this.work_plant_selected = null
                    this.work_plant = []
                    this.validations.isAcademicRepresentativeEmpty = false

                  }
                }
                else{
                  if(error == 1){
                    this.$buefy.dialog.alert({
                      message: 'El participante que tratas de añadir ya fue ingresado.',
                      confirmText: 'Aceptar'
                    })
                  }
                  /*else if(error == 2){
                    this.$buefy.dialog.alert({
                      message: 'El tipo de participanción, ya esta en uso.',
                      confirmText: 'Aceptar'
                    })
                  }*/
                  else if(error == 3){
                    this.$buefy.dialog.alert({
                      message: 'Debe seleccionar un tipo de participación.',
                      confirmText: 'Aceptar'
                    })
                  }

                }
            }
            else{
                // if(this.work_plant_selected == null){
                //   this.validation_position = true
                // }

                if(this.function_selected == null && (this.function_obj == null || this.function_obj.length == 0)){
                  this.validation_position = true
                }

                this.checkAcademicContactEmptyFields()
                this.isLoading =false
                this.$buefy.dialog.alert({
                    message: "Faltan campos relacionados al participante por completar.",
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    onConfirm: () => {
                    }
                })
            }
        },
        confirmCustomDeleteAcademic(deletePerson) {
            this.$buefy.dialog.confirm({
                message: '¿Quieres eliminar al participante: <b>'+deletePerson.person_name+'</b>?',
                confirmText: 'Eliminar',
                cancelText: 'Cancelar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.agreementInfo.participants = this.agreementInfo.participants.filter(element => element.person_id !== deletePerson.person_id)
                }
            })
        },

        // Para añadir/eliminar un contacto relacionado a una entidad externa
        checkEntityContact(){
            var confirmation = true
            var arrayConfirmation = []
            arrayConfirmation.push(this.validations.isPartnerEntityNameEmpty)
            arrayConfirmation.push(this.validations.isPartnerEntityApplicantEmpty)
            arrayConfirmation.push(this.validations.isPartnerEntityApplicantEmailEmpty)
            arrayConfirmation.push(this.validations.isPartnerEntityApplicantPhoneEmpty)
            arrayConfirmation.forEach(function(element){
                if(element === true)
                    confirmation = false
            })
            return confirmation
        },
        checkEntityContactEmptyFields(){
            if(this.validations.firstTimePartnerEntityName === true){
                this.validations.firstTimePartnerEntityName = false
            }
            if(this.validations.firstTimePartnerEntityApplicant === true){
                this.validations.firstTimePartnerEntityApplicant = false
            }
            if(this.validations.firstTimePartnerEntityApplicantEmail === true){
                this.validations.firstTimePartnerEntityApplicantEmail = false
            }
            if(this.validations.firstTimePartnerEntityApplicantPhone === true){
                this.validations.firstTimePartnerEntityApplicantPhone = false
            }
        },
        addPartnerEntityContact(partnerEntityName, partnerEntityContactName){
            //var confirmation = this.checkEntityContact()
            if (partnerEntityName != null && partnerEntityName != ''){
              if(!this.isPartnerEntityRegister){
                var addEntity = true
                this.agreementInfo.partner_entities.forEach(function(element){
                  if(element.partner_entity_data.name === partnerEntityName){
                    addEntity = false
                  }
                  else if(element.partner_entity_data.show_name === partnerEntityName){
                    addEntity = false
                  }

                })
                if(addEntity){
                  this.agreementInfo.partner_entities.push({
                    partner_entity_data: this.getPartnerEntityByName(partnerEntityName),
                    //applicant: this.partnerEntityApplicant,
                    //applicant_email: this.partnerEntityApplicantEmail,
                    //applicant_phone: this.partnerEntityApplicantPhone
                  })
                }
                else{
                  this.$buefy.dialog.alert({
                    message: 'La entidad que tratas de añadir ya fue ingresado.',
                    confirmText: 'Aceptar'
                  })
                }
              }
            }
            else{
              this.$buefy.dialog.alert({
                message: 'Debe seleccionar una entidad registrada primero.',
                confirmText: 'Aceptar'
              })
            }


            /*else{
                this.checkEntityContactEmptyFields()
                this.isLoading =false
                this.$buefy.dialog.alert({
                    message: "Faltan campos relacionados al contacto por completar.",
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    onConfirm: () => {
                    }
                })   
            }*/
        },
        confirmCustomDeleteEntity(deleteEntity) {
            var contacto = ''
            if(deleteEntity.partner_entity_data.alt_full_name != null){
              contacto = deleteEntity.partner_entity_data.alt_full_name
            }
            else if(deleteEntity.partner_entity_data.alt_name != null){
              contacto = deleteEntity.partner_entity_data.alt_name
            }
            else {
                contacto = "No se ha encontrado registro"
            }
            this.$buefy.dialog.confirm({
                message: '¿Quieres eliminar el contacto: <b>'+contacto+'</b>?',
                confirmText: 'Eliminar',
                cancelText: 'Cancelar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.agreementInfo.partner_entities = this.agreementInfo.partner_entities.filter(element => element.partner_entity_data.id !== deleteEntity.partner_entity_data.id)
                }
            })
        },

        // Para el checkeo previo y envío a backend
        checkEmptyFields(){
            if(this.validations.firstTimeName === true){
                this.validations.firstTimeName = false
            }
            if(this.validations.firstTimeApplicant === true){
                this.validations.firstTimeApplicant = false
            }
            if(this.validations.firstTimeDescription === true){
                this.validations.firstTimeDescription = false
            }
            if(this.validations.firstTimeState === true){
                this.validations.firstTimeState = false
            }
            if(this.validations.firstTimeAgreementGeneralTypeName === true){
                this.validations.firstTimeAgreementGeneralTypeName = false
            }
            if(this.validations.firstTimeAgreementSpecificTypeName === true){
                this.validations.firstTimeAgreementSpecificTypeName = false
            }
            if(this.validations.firstTimeStage === true){
                this.validations.firstTimeStage = false
            }
            if(this.validations.firstTimeServiceGloss === true){
                this.validations.firstTimeServiceGloss = false
            }
            if(this.validations.firstTimeCurrentManager === true){
                this.validations.firstTimeCurrentManager = false
            }
            if(this.validations.firstTimeAmount === true){
                this.validations.firstTimeAmount = false
            }
            if(this.validations.firstTimeLink === true){
                this.validations.firstTimeLink = false
            }
            if(this.isAgreementEntitiesAsociatedVoid === true){
                if(this.validations.firstTimePartnerEntityName === true){
                    this.validations.firstTimePartnerEntityName = false
                }
                this.checkEntityContactEmptyFields()
            }
            if(this.isAgreementAcademicsAsociatedVoid === true){
                this.checkAcademicContactEmptyFields()
            }
        },
        checkSave(){
            var confirmation = true
            var arrayConfirmation = []
            arrayConfirmation.push(this.validations.isAgreementNameEmpty)
            //arrayConfirmation.push(this.validations.isAgreementApplicantEmpty)
            arrayConfirmation.push(this.validations.isAgreementDescriptionEmpty)
            arrayConfirmation.push(this.validations.isAgreementStateEmpty)
            arrayConfirmation.push(this.validations.isAgreementStageEmpty)
            arrayConfirmation.push(this.validations.isAgreementGeneralTypeNameEmpty)
            arrayConfirmation.push(this.validations.isAgreementSpecificTypeNameEmpty)
            //arrayConfirmation.push(this.validations.isAgreementCurrentManagerEmpty)
            //arrayConfirmation.push(this.validations.isAgreementServiceGlossEmpty)
            //arrayConfirmation.push(this.validations.isAgreementLinkEmpty)
            arrayConfirmation.push(this.validations.isAgreementLinkFormat)
            //arrayConfirmation.push(this.validations.isAgreementAmountEmpty)
            arrayConfirmation.push(this.isAgreementEntitiesAsociatedVoid)
            arrayConfirmation.push(this.isAgreementAcademicsAsociatedVoid)
            arrayConfirmation.push(!this.checkIsNumber)
            arrayConfirmation.push(!this.checkIsNumberAmount)
            arrayConfirmation.push(this.checkDisabledEndDate)
            var int = 0
            arrayConfirmation.forEach(function(element){
                if(element === true){
                  confirmation = false
                }
                int = int + 1

            })
            return confirmation
        },
        save(save_case){
            var confirmaton = this.checkSave()
            if(confirmaton){
                this.isLoading = true
                var searched_agreement_general_type_id  = this.agreement_general_type_name;
                var searched_agreement_specific_type_id = this.agreement_specific_type_name;
                axios.post('/agreement_types_search', { agreement_general_type_id: searched_agreement_general_type_id, agreement_specifict_type_id: searched_agreement_specific_type_id})
                .then( response =>{
                    this.agreementInfo.agreement_type_id  = response.data.agreement_type.id
                    this.agreementInfo.resolution_type_id = this.resolution_name
                    this.agreementInfo.is_international = this.international
                    if(save_case == 0){
                        this.agreementInfo.unit_id = this.agreementUnit
                        if (this.agreementUnit == 0){
                          this.agreementInfo.unit_id = null
                          this.agreementInfo.university_user = 1
                        }
                        axios.post('/agreements.json', this.agreementInfo, {params: {
                          from_academic: this.from_academic
                        }})
                        .then( response =>{
                          this.errores_validacion = {};

                          if (this.from_academic) {
                            this.$emit('close')
                            this.$emit('refresh')
                          }else{
                            window.location.href="/agreements/"+response.data.id
                          }
                          
                        })
                        .catch(e => {
                            console.log(e)
                            this.errores_validacion = e.response.data;
                            this.isLoading = false
                            this.$buefy.dialog.alert({
                                message: "Faltan algunos campos o presentan errores de formato. Por favor revisar",
                                type: 'is-danger',
                                hasIcon: true,
                                icon: 'times-circle',
                                onConfirm: () => {
                                }
                            })
                        })
                    }
                    else{
                        this.isLoading = true
                        this.agreementInfo.is_international = this.international
                        this.agreementInfo.unit_id = this.agreementUnit
                        if (this.agreementUnit == 0){
                          this.agreementInfo.unit_id = null
                          this.agreementInfo.university_user = 1
                        }
                        else{
                          this.agreementInfo.university_user = 0
                        }
                        axios.put('/agreements/'+this.agreement_id+'.json', this.agreementInfo)
                        .then( response =>{
                          this.errores_validacion = {};
                          if (this.from_academic) {
                            this.$emit('close')
                            this.$emit('refresh')
                          }else{
                            window.location.href="/agreements/"+response.data.id
                          }
                        })
                        .catch(e => {
                            this.checkEmptyFields()
                            this.isLoading = false
                            this.errores_validacion = e.response.data;
                            this.$buefy.dialog.alert({
                                message: "Faltan algunos campos o presentan errores de formato. Por favor revisar",
                                type: 'is-danger',
                                hasIcon: true,
                                icon: 'times-circle',
                                onConfirm: () => {
                                  this.isLoading = false
                                }
                            })
                        })
                    }  
                })
                .catch(e => {
                    console.log(e)
                    this.isLoading = false
                })
            }
            else{
                this.checkEmptyFields()
                this.isLoading = false
                this.$buefy.dialog.alert({
                    message: "Faltan algunos campos o presentan errores de formato. Por favor revisar",
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    onConfirm: () => {
                    }
                })
            }
        },
      async onClickChild(value){
        this.isLoading = true
        this.entity_type_id = value.entity_type_id
        var a = await this.searchPartnerEntities(value.name)
        //console.log(a)
        //this.partnerEntityName = value.name

      },
        // Para volver al índice de convenios
        back(){
            this.isLoading=true
            if(this.edit_case){
                window.location.href="/agreements/"+this.agreement_id
            }
            else{
                window.location.href="/agreements"
            }
        },
        searchPartnerEntities(name){
        if (name.length<3) {
          this.partner_entities_search = []
          return
        }
        let vm = this;
        vm.isLoading=true;
        axios
            .get("/admin/partner_entities/agreement-search.json", { params: { name: name} })
            .then(res => {
              vm.partner_entities_search = res.data;
              if (this.getPartnerEntityByName(name) != null){
                this.partnerEntityName = name
              }
              vm.isLoading = false;
            })
            .catch(error => {
              console.log(error)
              vm.isLoading=false;
            })
      },
        async searchPerson(name){
          if (name.length<3) {
            this.work_plant_selected = null
            this.work_plant = []
            this.function_obj = []
            this.function_selected = null
            this.partner_entities_search = []
            return
          }
          let vm = this;
          vm.isLoading=true;
          axios
              .get("/people/agreement/participants.json",{
                params: {name: name}})
              .then(res => {
                vm.person_search = res.data
                vm.work_plant_selected = null
                vm.work_plant = []
                vm.function_obj = []
                vm.function_selected = null
                vm.isLoading = false
              })
              .catch(error => {
                console.log(error)
                vm.isLoading=false
              })
        },
        isDisabled(){
            this.agreementEndDate = null
            this.disabled = true
        },
      addExternalPerson(){
        this.isLoading= true
        this.isPersonModal = true
        this.isLoading = false
      },
      async onClickChildPerson(value){
          var a = await this.searchPerson(value.person_name)
          this.person_name = value.person_name


      },
      watchWorkPlant(object){
          if (object != null)
            this.work_plant = object.work_plant
      },
      watchFunction(object){
        if (object != null)
          this.function_obj = object.function
      }
    },
    watch: {
      add_agreement: function() {
        this.save(0)
      }
    }
}
</script>