<template>
    <div>
      <div class="columns">
        <div v-if="!this.hideUpdate==true" class="column is-narrow mt-2">
          <span>Actividades año inicio: </span>
        </div>
        <div v-if="!this.hideUpdate==true" class="column is-3">
          <b-field>
            <multiselect v-model="year"
                         :options="years"
                         @input="updateActivity"
                         placeholder="Seleccione año"
                         selectLabel="Presione para seleccionar"
                         selectedLabel="Seleccionado"
                         deselectLabel="No se puede deseleccionar"
                         :allow-empty="false">
              <template v-slot:noOptions>
                No existen datos
              </template>
              <span slot="noResult">
                        No se encontraron elementos.
                    </span>
            </multiselect>
          </b-field>
        </div>
        <div class="column has-text-right">
          <b-field>
            <b-switch v-model="cardLayout">
          <span>
            Formato tarjeta (sin filtros ni búsqueda)
             <b-tooltip
                 label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                 multilined
                 position="is-left">
                  <b-icon
                      pack="fas"
                      icon="info-circle"
                      type="is-info">
                  </b-icon>
              </b-tooltip>
          </span>
            </b-switch>
          </b-field>
        </div>
      </div>
        <b-table
                :data="associatedActivities"
                :paginated=true
                per-page=10
                striped
                hoverable
                :current-page="currentPage"
                :pagination-simple=false
                :card-layout="cardLayout"
                pagination-position="bottom"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="publication.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual"
                header-class="is-primary">
                <b-table-column 
                    field='activity.name'
                    label= 'Nombre'
                    searchable
                    sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                    </template>
                    <template v-slot="props">
                        <a @click="goToActivity(props.row.activity.id)"><i class="fas fa-user-circle"></i> {{ props.row.activity.name }}</a>
                    </template>
                </b-table-column>
                <b-table-column 
                    field='activity.theme'
                    label='Tema'
                    searchable
                    sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                    </template>
                    <template v-slot="props">
                        {{props.row.activity.theme}}
                    </template>
                </b-table-column>
                <b-table-column 
                    field='type'
                    label= 'Tipo de actividad'
                    sortable
                    searchable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                    </template>
                    <template v-slot="props">
                        {{props.row.type}}
                    </template>
                </b-table-column>
                <b-table-column
                    field = 'activity.start_date' 
                    label= 'Fecha inicio'
                    searchable :custom-search="searchStart"
                    sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.activity.start_date._isValid ? new Date(props.row.activity.start_date).toLocaleDateString("es-CL") : '' }}
                    </template>
                </b-table-column>
                <b-table-column
                    field='activity.end_date' 
                    label= 'Fecha término'
                    searchable :custom-search="searchEnd"
                    sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{props.row.activity.end_date._isValid ? new Date(props.row.activity.end_date).toLocaleDateString("es-CL") : ''}}
                    </template>
                </b-table-column>

                <template slot="empty">
                    <div class="columns is-centered">
                        <div class="column is-6 is-centered has-text-centered">
                            <b-message type="is-danger">
                                No se encontraron actividades relacionadas.
                            </b-message>
                        </div>
                    </div>
                </template>            
            </b-table>
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"/>
    </div>
</template>
<script>
import axios from 'axios'
import {searchDate} from "../../packs/utilities";
import moment from "moment";
export default {
    name: 'UnitActivities',
    props:[
        'objectId',
        'componentType',
        "hideUpdate",
        'currentYear',
        'minYear',
        'maxYear',
        'changeFilter'
    ],
    data(){
        return{
            associated_activities: [],
            currentPage: 1,
            year: null,
            activities: [],
            years:[],
            cardLayout:false,
            isLoading: true
        }
    },
    computed:{
        associatedActivities:{
            get: function(){
                return this.associated_activities
            },
            set: function(newActvities){
                this.associated_activities = newActvities
            }
        }
    },
    beforeMount(){
        this.years = this.generateArrayOfYears();
        this.year = new Date().getFullYear();
      if(this.hideUpdate == true){
        this.updateActivity()
      }
      else {
        this.updateActivity()
      }
        //this.getAssociatedActivities()
    },
    methods:{
        generateArrayOfYears() {
          var max = new Date().getFullYear()
          var min = 1980
          var years = []
          years.push("Todo")
          for (var i = max; i >= min; i--) {
            years.push(i)
          }
          return years
        },
      // Consultas al backend
        getAssociatedActivities(){
            axios.get("/vime_activities/get_activities_by_unit_id/"+this.objectId+".json")
            .then(response =>{
                this.associatedActivities = response.data.assistance
                this.isLoading = false
            })
            .catch(
                e =>{
                    console.log(e)
                }
            )
        },
        goToActivity(activity_id){
            this.isLoading=true;
            window.location.href="/vime_activities/"+activity_id
        },
      searchStart(row,input){
        return searchDate(row.activity.start_date,input)
      },
      searchEnd(row,input){
        return searchDate(row.activity.end_date,input)
      },
      updateActivity(){
        let vm = this;
        vm.isLoading=true;

        if (this.hideUpdate == true){
          this.year = 'MyActivity'
        }
        return axios.get("/vime_activities/get_activities_by_unit_id/"+this.objectId, {params:{
            year_f: this.year,
            minYear: this.minYear,
            maxYear: this.maxYear
        }})
            .then(res => {
              vm.activities = res.data.assistance;
              vm.activities = this.activities.map(activities => {
                var newActivities = activities
                newActivities.activity["start_date"] = moment(newActivities.activity["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
                newActivities.activity["end_date"] = moment(newActivities.activity["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
                return newActivities;
              });
              vm.associatedActivities = res.data.assistance;
              //vm.can_create = res.data['can_create'];
              vm.associatedActivities = this.associatedActivities.map(activities => {
                var newActivities = activities
                newActivities.activity["start_date"] = moment(newActivities.activity["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
                newActivities.activity["end_date"] = moment(newActivities.activity["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
                return newActivities;
              });
              vm.isLoading=false;
            })
            .catch(e => {
              vm.isLoading=false;
              console.log(e);
            });
      }
    },
  watch: {
    changeFilter: function(){
      if(this.hideUpdate == true){
        this.updateActivity()
      }
    }
  }
}
</script>