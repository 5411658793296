<template>
  <section>
    <div class="modal-card" style="width: 1080px">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column">
            <b-field label="Nombre">
              <b-input :value="function_data.name" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Nombre Alternativo">
              <b-input :value="function_data.alt_name" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Función Padre">
              <b-input :value="function_data.parent_function_name" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="¿Es único?">
              <b-radio-button :value="function_data.uniq"
                              :native-value="false"
                              type="is-danger is-light is-outlined"
                              readonly
                              :disabled="true"

              >
                <b-icon icon="times-circle"></b-icon>
                <span>No</span>
              </b-radio-button>

              <b-radio-button :value="function_data.uniq"
                              :native-value="true"
                              type="is-success is-light is-outlined"
                              readonly
                              :disabled="true"
              >
                <b-icon icon="check-circle"></b-icon>
                <span>Si</span>
              </b-radio-button>
            </b-field>
          </div>
          <div class="column">
            <b-field label="¿Es autoridad?">
              <b-radio-button :value="function_data.is_authority"
                              :native-value="false"
                              type="is-danger is-light is-outlined"
                              readonly
                              :disabled="true"

              >
                <b-icon icon="times-circle"></b-icon>
                <span>No</span>
              </b-radio-button>

              <b-radio-button :value="function_data.is_authority"
                              :native-value="true"
                              type="is-success is-light is-outlined"
                              readonly
                              :disabled="true"
              >
                <b-icon icon="check-circle"></b-icon>
                <span>Si</span>
              </b-radio-button>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Descripción">
              <b-input :value="function_data.description" type="textarea" readonly></b-input>
            </b-field>
          </div>
        </div>
      </section>
    </div>
    <footer class="modal-card-foot actions">
      <div class="has-text-right is-grouped">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
        <b-button class="is-primary"
                  v-if="function_data.can_edit"
                  @click="isEditModalActive = true">
          Editar
        </b-button>
      </div>
    </footer>
    <b-modal
        v-model="isEditModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <function-form
                    :id="function_data.id"
                    query-type="update"
                    :modal-header="'Editar Función'"
                    @close="props.close">
        </function-form>
      </template>
    </b-modal>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import FunctionForm from "../functions/modal_function_form"
import {formatDate} from "../../packs/utilities";

export default {
  name: "function",
  props:[
    "id","modalHeader"
  ],
  components: {
    FunctionForm
  },
  data(){
    return {
      isLoading:false,
      function_data:{},
      isEditModalActive:false,
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.isLoading = true;
      return axios
          .get("/functions/" + this.id + ".json")
          .then(res => {
            this.function_data = res.data
            this.isLoading = false;
          }).catch(error => {
            console.log(error)
            this.isLoading = false;
          })
    },
    formatDate,
  },
  watch: {
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
  }
}
</script>

