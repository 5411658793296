<template>
  <section class="section">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
        <b-message type="is-danger" has-icon>
          <div v-for="(item,index) in errores_validacion">
            {{ item[0] }}
          </div>
        </b-message>
      </div>
      <form @submit.prevent="handleSubmit(savePublication)">
        <!-- Box de Formulario Publicacion -->
        <div class="box">
          <div v-if="queryType=='update'" class="columns is-centered">
            <div class="column is-centered has-text-centered">
              <b-message
                  title="Información importante"
                  type="is-warning"
                  aria-close-label="Cerrar mensaje">
                Solo la/el usuaria/o que creó esta actividad académica tiene permisos para editar todos sus atributos.<br>
                Quienes participan en la actividad académica pero no la crearon, solo pueden editar su participación.
                <br><span v-if="publication.creator_name">Origen de la información: {{ publication.creator_name }}</span>
              </b-message>
            </div>
          </div>
          <section name="General" v-if="isUpdatingAndDeletingParticipant(false)">
            <div class="columns">
              <div class="column is-4 is-offset-8">
                <b-switch v-model="requiredFieldOnly">Mostrar solo campos obligatorios</b-switch>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <keep-alive><bibtex-input @fill-publication="fillPublication"></bibtex-input></keep-alive>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Título*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input v-model="publication.title" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Tipo de Publicación*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="publication.publication_type_id"
                                 :options="tipos_publicacion.map(type => type.id)"
                                 :custom-label="opt => tipos_publicacion.find(x => x.id == opt).name"
                                 placeholder="Seleccione tipo de publicación"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Fecha de Publicación*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-datepicker
                        v-model="publication.publication_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa">
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column" v-if="!requiredFieldOnly">
                <b-field label="DOI">
                  <b-input v-model="publication.doi" type="text"></b-input>
                </b-field>
              </div>
              <div class="column" v-if="!requiredFieldOnly">
                <b-field label="ISSN">
                  <b-input v-model="publication.issn" type="text"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column" v-if="!requiredFieldOnly">
                <div class="field">
                  <b-field label="Resumen">
                    <b-input
                        type="textarea"
                        maxlength="4000"
                        v-model="publication.summary">
                    </b-input>
                  </b-field>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column" v-if="!requiredFieldOnly">
                <h3 class="is-size-6 has-text-weight-bold">Palabras Claves
                  <b-tooltip
                      label="Presione Enter o Coma luego de escribir la palabra clave para que sea agregada"
                      position="is-right"
                      size="is-medium"
                      multilined>

                    <b-icon
                        pack="fas"
                        icon="info-circle"
                        size="is-small"
                        type="is-info">
                    </b-icon>
                  </b-tooltip>
                </h3>
                <b-field label=" ">
                  <b-taginput
                      v-model="publication.key_words_attributes"
                      :data="palabras_claves_filtradas"
                      autocomplete
                      :allow-new="true"
                      :allow-duplicates="false"
                      field="name"
                      icon="tag"
                      placeholder="Agrega una palabra clave"
                      @typing="getFilteredTags">
                  </b-taginput>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column" v-if="!requiredFieldOnly">
                <b-field label="Título Corto">
                  <b-input v-model="publication.short_title" type="text"></b-input>
                </b-field>
              </div>
              <div class="column" v-if="!requiredFieldOnly">
                <b-field label="Serie">
                  <b-input v-model="publication.serie" type="text"></b-input>
                </b-field>
              </div>
              <div class="column" v-if="!requiredFieldOnly">
                <b-field label="Título Serie">
                  <b-input v-model="publication.serial_title" type="text"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column" v-if="!requiredFieldOnly">
                <b-field label="Texto Serie">
                  <b-input v-model="publication.serial_text" type="text"></b-input>
                </b-field>
              </div>
              <div class="column" v-if="!requiredFieldOnly">
                <b-field label="Archivo">
                  <b-input v-model="publication.archive" type="text"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column" v-if="!requiredFieldOnly">
                <b-field label="Posición en Archivo">
                  <b-input v-model="publication.archive_position" type="text"></b-input>
                </b-field>
              </div>
              <div class="column" v-if="!requiredFieldOnly">
                <b-field label="Catálogo en Biblioteca">
                  <b-input v-model="publication.library_catalog" type="text"></b-input>
                </b-field>
              </div>
              <div class="column" v-if="!requiredFieldOnly">
                <b-field label="Materia">
                  <b-input v-model="publication.signature" type="text"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column" v-if="!requiredFieldOnly">
                <b-field label="Copyrights">
                  <b-input v-model="publication.copyright" type="text"></b-input>
                </b-field>
              </div>
              <div class="column" v-if="!requiredFieldOnly">
                <b-field label="Adicional">
                  <b-input v-model="publication.additional" type="text"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Revista*">
                  <template #label>
                    Revista*
                    <b-tooltip
                        label="Presione Enter o seleccione la revista luego de escribir. No se agregarán nuevas revistas."
                        position="is-right"
                        multilined>
                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          type="is-info">
                      </b-icon>
                    </b-tooltip>
                  </template>
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-taginput
                        v-model="revista_seleccionada"
                        :data="revistas_filtradas"
                        type="is-primary is-light"
                        autocomplete
                        :allow-new="false"
                        field="name"
                        icon="tag"
                        placeholder="Escriba para buscar una revista"
                        maxtags="1"
                        @typing="getRevistasFiltradas">
                    </b-taginput>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column" v-if="!requiredFieldOnly">
                <b-field label="Cuartil">
                  <ValidationProvider rules="integer|positive" v-slot="{errors}">
                    <b-input v-model="publication.quartile"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column" v-if="!requiredFieldOnly">
                <b-field label="¿Es multipdisciplinaria?">
                  <b-checkbox v-model="publication.multidisciplinary">
                  </b-checkbox>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column" v-if="!requiredFieldOnly">
                <b-field label="Página de inicio">
                  <ValidationProvider rules="integer|positive" v-slot="{errors}">
                    <b-input v-model="publication.start_page"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column" v-if="!requiredFieldOnly">
                <b-field label="Página de término">
                  <ValidationProvider rules="integer|positive" v-slot="{errors}">
                    <b-input v-model="publication.end_page"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column" v-if="!requiredFieldOnly">
                <b-field label="Volumen de la revista">
                  <b-input v-model="publication.volumen" type="text"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column" v-if="!requiredFieldOnly">
                <b-field label="¿Es SciELO?">
                  <b-checkbox v-model="publication.is_scielo">
                  </b-checkbox>
                </b-field>
              </div>
              <div class="column" v-if="!requiredFieldOnly">
                <b-field label="¿Es Scopus?">
                  <b-checkbox v-model="publication.is_scopus">
                  </b-checkbox>
                </b-field>
              </div>
              <div class="column" v-if="!requiredFieldOnly">
                <b-field label="¿Es Wos?">
                  <b-checkbox v-model="publication.is_wos">
                  </b-checkbox>
                </b-field>
              </div>
              <div class="column" v-if="!requiredFieldOnly">
                <b-field label="¿Es Wos Emergente?">
                  <b-checkbox v-model="publication.is_emergent_wos">
                  </b-checkbox>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column" v-if="!requiredFieldOnly">
                <b-field label="Link de la publicación">
                  <b-input v-model="publication.url"></b-input>
                </b-field>
              </div>
            </div>
          </section>


          <span>
            <h3 class="is-size-3 has-text-weight-bold">
              Participantes*
              <b-tooltip label="En esta sección debe ingresar las y los autores de la publicación que
               se encuentren registrados en el sistema, de esta manera, los datos ingresados de esta publicación quedarán asociados a esta persona."
                         position="is-right"
                         size="is-medium"
                         multilined>
              <b-icon
                  pack="fas"
                  icon="info-circle"
                  size="is-small"
                  type="is-info">
              </b-icon>
            </b-tooltip>
              <div class="is-pulled-right">
                <b-button class="mt-2" @click.prevent="addParticipant(publicationId)" type="is-primary">Administrar participantes</b-button>
              </div>
            </h3>

          </span>
          <h6 class="is-size-6 subtitle">(Se requiere al menos un/a participante. Recuerde añadirse a usted si es que participa en esta actividad académica)</h6>
          <!-- FORMULARIO PARTICIPANTES -->
          <div v-if="participantes.length!=0" class="column">
            <h3 class="is-size-4 has-text-weight-bold">Tabla Resumen Participantes</h3>
            <b-table
                :data="participantes"
                :paginated=true
                per-page=5
                striped
                hoverable
                :current-page="currentPage"
                :pagination-simple=false
                pagination-position="bottom"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="publication.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
              <b-table-column
                  field="name"
                  label="Nombres"
                  sortable
                  searchable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{ props.row.name}}
                </template>
              </b-table-column>
              <b-table-column
                  field="corporate_mail"
                  label="Correo Corporativo"
                  sortable
                  searchable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder="Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.corporate_mail}}
                </template>
              </b-table-column>
              <b-table-column
                  field="unit"
                  label="Unidad"
                  sortable
                  searchable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{ props.row.unit }}
                </template>
              </b-table-column>
              <b-table-column
                  field="participant_type"
                  label="Tipo de participación*"
                  v-slot="props">
                <ValidationProvider v-if="isUpdatingAndDeletingParticipant(props.row)"
                                    rules="required" v-slot="{errors}">
                  <multiselect v-model="props.row.participant_type_id"
                               :options="tipos_participacion.map(x => x.id)"
                               :custom-label="opt => tipos_participacion.find(x => x.id == opt).name"
                               placeholder="Seleccione tipo de participación"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                      No se encontraron elementos.
                    </span>
                  </multiselect>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-table-column>
              <b-table-column
                  field="whos"
                  label="¿Es estudiante de doctorado?"
                  v-slot="props">
                <b-checkbox v-if="isUpdatingAndDeletingParticipant(props.row)"
                            v-model="props.row.is_doc_student">
                </b-checkbox>
              </b-table-column>
              <b-table-column
                  label="Acciones"
                  width="10"
                  v-slot="props">
                <b-icon
                    v-if="isUpdatingAndDeletingParticipant(props.row)"
                    pack="fas"
                    icon="trash"
                    style="color: #e50000;"
                    size="is-medium"
                    @click.native="deleteParticipantButton(props.row)"/>
              </b-table-column>
            </b-table>
          </div>
          <section name="Authors" v-if="isUpdatingAndDeletingParticipant(false)">
            <span>
            <h3 class="is-size-3 has-text-weight-bold">
              Autores
              <b-tooltip label="En esta sección debe ingresar en un campo de texto las y los autores de la publicación en el orden deseado, solo para su visualización.
                                Este campo de texto es solo referencial.
                                Ej de texto:'Shaoshuai Wang, Lili Guo, Ling Chen, Weiyong Liu, Yong Cao, Jingyi Zhang and Ling Feng'"
                         position="is-right"
                         size="is-large"
                         multilined>
              <b-icon
                  pack="fas"
                  icon="info-circle"
                  size="is-small"
                  type="is-info">
              </b-icon>
            </b-tooltip>
            </h3>

          </span>

            <b-field label="Autores (registrados y no registrados el orden deseado)">
              <b-input v-model="publication.participants_references" type="text"></b-input>
            </b-field>

          </section>

          <section name="OCDESubAreas" v-if="isUpdatingAndDeletingParticipant(false)">
            <h3 class="is-size-3 has-text-weight-bold">Sub Áreas OCDE
              <b-tooltip
                  label="Presione Enter o Coma luego de escribir la Sub Áreas OCDE para que sea agregada. No se agregarán Sub Áreas OCDE nuevas, solo se buscarán Sub Áreas OCDE existentes"
                  position="is-right"
                  size="is-medium"
                  multilined>

                <b-icon
                    pack="fas"
                    icon="info-circle"
                    size="is-small"
                    type="is-info">
                </b-icon>
              </b-tooltip>
            </h3>
            <!-- FORMULARIO OCDE SUB AREAS-->
            <div class="columns">
              <div class="column">
                <b-field label="Seleccione la Sub Áreas OCDE">
                  <b-taginput
                      v-model="publication.ocde_sub_areas_attributes"
                      :data="ocde_sub_areas_filtradas"
                      type="is-primary is-light"
                      autocomplete
                      :allow-duplicates="false"
                      :allow-new="false"
                      field="name"
                      icon="tag"
                      placeholder="Agrega una sub área OCDE"
                      @typing="getFilteredOcdeSubAreas">
                  </b-taginput>
                </b-field>
              </div>
            </div>
          </section>

          <section name="Disciplines" v-if="isUpdatingAndDeletingParticipant(false)">
            <h3 class="is-size-3 has-text-weight-bold">Disciplinas
              <b-tooltip
                  label="Presione Enter o Coma luego de escribir la disciplina para que sea agregada. No se agregarán disciplinas nuevas, solo se buscarán disciplinas ya existentes"
                  position="is-right"
                  size="is-medium"
                  multilined>

                <b-icon
                    pack="fas"
                    icon="info-circle"
                    size="is-small"
                    type="is-info">
                </b-icon>
              </b-tooltip>
            </h3>
            <!-- FORMULARIO DISCIPLINAS-->
            <div class="columns">
              <div class="column">
                <b-field label="Seleccione la disciplina">
                  <b-taginput
                      v-model="publication.disciplines_attributes"
                      :data="disciplines_filtradas"
                      type="is-primary is-light"
                      autocomplete
                      :allow-new="false"
                      :allow-duplicates="false"
                      field="name"
                      icon="tag"
                      placeholder="Agrega una disciplina"
                      @typing="getFilteredDisciplines">
                  </b-taginput>
                </b-field>
              </div>
            </div>

          </section>
          <br>
          <div class="actions has-text-right">
            <button class="button is-primary" type="submit" @click="checkValidation()">Guardar Publicación</button>
          </div>
        </div>
        <!-- Fin Box Formulario-->
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <!-- MODALES -->
    <b-modal
        v-model="isAddModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-participants-publications
                           query-type="update"
                           modal-header="Añadir participantes"
                           :participantes="participantes"
                           :tipos_participacion="tipos_participacion"
                           :publication="publication"
                           @close="props.close"
                           @clicked="onClickChild"></modal-participants-publications>
      </template>
    </b-modal>
  </section>
</template>

<script>
import axios from "axios";
import BibtexInput from "./bibtex_input.vue";
import { bus } from "../../packs/app_vue";
import ModalParticipantsForm from "./modal_participants_publications.vue";
import moment from "moment";

export default {
  components:{
    BibtexInput,
    'modal-participants-publications': ModalParticipantsForm
  },
  data(){
    return {
      isLoading:false,
      errores_validacion: {},
      canCancel: ['escape'],

      publication: {
        participants_attributes: [],
        ocde_sub_areas_attributes: [],
        disciplines_attributes: [],
        key_words_attributes:[],
        validation_attributes:{}
      },
      participants: [],
      participantes: [],
      unidades: [],
      ocde_sub_area: [],
      ocde_sub_areas: [],
      ocde_sub_areas_filtradas: [],
      disciplines: [],
      disciplines_filtradas: [],
      tipos_participacion: [],
      tipos_publicacion: [],
      revistas: [],
      revistas_filtradas:[],
      revista_seleccionada:null,
      palabras_claves:[],
      palabra_clave:null,
      palabras_claves_filtradas:[],
      requiredFieldOnly:false,
      isAddModalActive: false,
      currentPage:1
    }
  },
  watch:{
    revista_seleccionada:function(){
      if(this.revista_seleccionada && this.revista_seleccionada[0]){
        this.publication.magazine_id = this.revista_seleccionada[0].id;
      }
      else{
        this.publication.magazine_id = null;
      }
    },
    isAddModalActive: function () {
      if (!this.isAddModalActive) {
        this.initialize()
      }
    },
  },
  props:['userId','queryType','publicationId','cargoPrincipal','unidadPrincipal','personId'],

  created(){
    bus.$on('fill-publication', this.fillPublication);
    this.isLoading = true;
    axios.get("/publications/new.json")
    .then(res => {
      this.tipos_publicacion = res.data['tipos_publicacion'];
      this.tipos_participacion = res.data['tipos_participacion'];
      this.unidades = res.data['unidades'];
      this.ocde_sub_areas = res.data['ocde_sub_areas'];
      this.ocde_sub_areas_filtradas = res.data['ocde_sub_areas'];
      this.revistas = res.data['revistas'];
      this.revistas_filtradas = res.data['revistas'];
      this.disciplines = res.data['disciplines'];
      this.disciplines_filtradas = res.data['disciplines'];
      if (this.queryType==="create" && this.cargoPrincipal.name==="ACADEMICO"){
        this.participantes.push({
          person_id: res.data["participante"].person_id,
          name: res.data["participante"].person_name,
          alias: res.data["participante"].person_alias != null ? res.data["participante"].person_alias.toUpperCase() : "",
          corporate_mail: res.data["participante"].corporate_mail != null ? res.data["participante"].corporate_mail.toUpperCase() : "",
          unit: res.data["participante"].unit_name,
          unit_id: res.data["participante"].unit_id,
          participant_type_id: null
        })
        }
        this.isLoading=false;
      if (this.queryType=="update"){
        this.isLoading=true;
        axios
            .get("/publications/"+this.publicationId+".json",{params:{edit_flag:true}})
            .then(res => {
              this.publication = res.data
              var index = 0
              for(const participante of this.publication.participants_attributes){
                this.participantes.push({
                  id:null,
                  name:null,
                  alias:null,
                  unit:null,
                  unit_id:null,
                  corporarte_mail:null,
                  participant_type_id:null,
                  is_doc_student:null
                })
                this.participantes[index].person_id = participante.person_id
                this.participantes[index].is_doc_student = participante.is_doc_student
                this.participantes[index].name = participante.person_name
                this.participantes[index].corporate_mail = participante.person_mail
                this.participantes[index].alias = participante.person_alias
                if(this.participantes[index].corporate_mail != null){
                  this.participantes[index].corporate_mail = this.participantes[index].corporate_mail.toUpperCase()
                }
                if(this.participantes[index].alias != null){
                  this.participantes[index].alias = this.participantes[index].alias.toUpperCase()
                }
                this.participantes[index].unit = participante.unit_name
                this.participantes[index].unit_id = participante.unit_id
                this.participantes[index].participant_type_id = participante.participant_type_id
                index = index + 1
              }
              if (this.publication.publication_date!=null && this.publication.publication_date!=undefined)
                this.publication.publication_date = new Date(moment(this.publication.publication_date).format('MM/DD/YYYY'));
              if (this.publication.validation_attributes==null){
                this.publication.validation_attributes={}
                this.publication.data_source=""
              }
              this.revista_seleccionada = [{}];
              this.revista_seleccionada[0].id = this.publication.magazine_id;
              this.revista_seleccionada[0].name = this.publication.magazine;
              this.isLoading=false;
            })
            .catch(e => {
              console.log(e);
              this.isLoading=false;
            });
      }
    })
    .catch(e => {
      console.log(e);
      this.isLoading=false;
    });
  },
  methods: {
    initialize(){

    },
    onClickChild(value){
      this.isLoading = true
      this.participantes = value
      this.isLoading = false

    },

    savePublication(){
      this.publication.participants_attributes = []
      var index = 0
      for (var i=0;i<this.publication.key_words_attributes.length;i++){
        if( !(typeof this.publication.key_words_attributes[i] === 'object' && this.publication.key_words_attributes[i] !== null) ){
          var name = this.publication.key_words_attributes[i];
          this.publication.key_words_attributes[i] = new Object();
          this.publication.key_words_attributes[i].name=name;
          this.publication.key_words_attributes[i].id=null;
        }
      }
      for(const participante of this.participantes){
        this.publication.participants_attributes.push({
          person_id:null,
          participant_type_id:null,
          unit_id:null,
          unit_participants:[],
          _destroy:null
        })
        this.publication.participants_attributes[index].person_id = participante.person_id
        this.publication.participants_attributes[index].is_doc_student = participante.is_doc_student
        this.publication.participants_attributes[index].participant_type_id = participante.participant_type_id
        this.publication.participants_attributes[index].unit_id = participante.unit_id
        index = index + 1
      }
      this.isLoading=true;
      var retorno = JSON.parse(JSON.stringify(this.publication.participants_attributes));
      if (this.queryType==="create") {
        axios
            .post("/publications.json", {
              publication: this.publication,
              participants: retorno.map(function(x) {
                delete x.unit_id;
                delete x.unit_participants;
                return x;
              }),
              user_id:this.userId,
              data_source:this.publication.validation_attributes.data_source
            })
            .then(response => {
              this.errores_validacion = {};

              window.location.href = '/publications/'+response.data["id"]

              this.isLoading=false;
            }).
            catch(error => {
              this.errores_validacion = error.response.data
              var elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              this.isLoading=false;
            })
      }
      if (this.queryType==="update"){
        axios
            .put("/publications/"+this.publicationId+".json", {
              publication: this.publication,
              participants: retorno.map(function(x) {
                delete x.unit_id;
                delete x.unit_participants;
                return x;
              }),
              user_id:this.userId,
              data_source:this.publication.validation_attributes.data_source
            })
            .then(response => {
              this.errores_validacion = {};
              window.location.href = '/publications/'+this.publicationId
              this.isLoading=false;
            }).
          catch(error => {
            this.errores_validacion = error.response.data
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            this.isLoading=false;
          })
      }

    },
    addParticipant(id){
      this.selected = id;
      this.isAddModalActive = true
    },
    deleteParticipant(index){
      this.publication.participants_attributes.splice(index,1)
    },

    getFilteredTags(text) {
      if (text.length >= 3){
        axios
            .get("/publications/filter_keywords/"+text.toUpperCase()+".json")
            .then(res => {
              this.palabras_claves_filtradas = res.data['keywords'];
            }).
        catch(error => {
          console.log(error)
        })
      }
      else{
        this.palabras_claves_filtradas = []
      }
    },

    deleteParticipantsPub(participants){
      this.participantes = this.participantes.filter(person => person.corporate_mail != participants.corporate_mail);
    },
    findUnitParticipants(index){
      if(this.publication.participants_attributes[index].unit_id!=null){
        this.isLoading=true;
        axios
            .get("/get_people_by_unit.json", {
              params: {unit_id: this.publication.participants_attributes[index].unit_id}
            })
            .then(res => {
              this.publication.participants_attributes[index].unit_participants = res.data['personas'];
              this.isLoading=false;
            }).
        catch(error => {
          this.isLoading=false;
          console.log(error)
        })
      }
    },
    getFilteredOcdeSubAreas(text) {
      this.ocde_sub_areas_filtradas = this.ocde_sub_areas.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    getFilteredDisciplines(text) {
      this.disciplines_filtradas = this.disciplines.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    getRevistasFiltradas(text) {
      this.revistasFiltradas = this.revistas.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    async checkValidation(){
      const valid = await this.$refs.observer.validate()
      if(!valid){
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
    formatDate(inputDate) {
      // Verificar si el inputDate ya está en formato aaaa-mm-dd
      if (typeof inputDate === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(inputDate)) {
        return inputDate;
      }

      // Convertir la fecha al objeto Date si no está en el formato aaaa-mm-dd
      const date = new Date(inputDate);

      if (isNaN(date.getTime())) {
        throw new Error('Fecha inválida');
      }

      // Extraer año, mes y día y formatear a aaaa-mm-dd
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses son base 0 en JavaScript
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    fillPublication(newData){
      if (newData.publication_date){
        newData.publication_date = this.formatDate(newData.publication_date)
        newData.publication_date = new Date(newData.publication_date.replace(/-/g,"/"));
      }
      else{
        newData.publication_date = new Date();
      }
      this.revista_seleccionada = [];
      if (newData.magazine_id){
        this.revista_seleccionada = [{}];
        this.revista_seleccionada[0].id = newData.magazine_id;
        this.revista_seleccionada[0].name = newData.magazine;
      }



      var ocde_sub_areas = []
      if(newData.ocde_sub_areas_attributes){
        for(const ocde of this.ocde_sub_areas){
          var filtro_ocde =  newData.ocde_sub_areas_attributes.filter((sub_area_ocde) => sub_area_ocde.name == ocde.name)
          if (filtro_ocde.length > 0){
            ocde_sub_areas.push(filtro_ocde[0])
          }
        }
        newData.ocde_sub_areas_attributes = ocde_sub_areas
      }


      this.publication = newData;


    },
    isUpdatingAndDeletingParticipant(row){
      if(this.queryType=="create"){
        return true;
      }
      else if(this.queryType=='update'){
        //console.log(this.project.current_user_person_id, row.id,row)
        if (row){
          return this.publication.is_creator || this.publication.current_user_person_id == row.person_id;
        }
        return this.publication.is_creator;
      }

    },
    deleteParticipantButton(participants){

      this.$buefy.dialog.confirm({
        title: 'Eliminar Participante',
        message: 'Precaución, al momento de guardar la publicación esta acción eliminará la asociación con la/el participante, lo que implica que ya no podrá ver esta publicación ni será contada en su actividad académica ¿Desea continuar?',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteParticipantsPub(participants)
      })

    },

  },

}
</script>