<template>
  <section>
    <div class="container">
      <div class="box">

        <div class="buttons has-addons is-centered">
          <b-button :type="getType(1)" @click="getProfesoresPlanta">Académicos</b-button>
          <b-button :type="getType(2)" @click="getProfesoresHora">Profesores Hora</b-button>
        </div>

        <div class="columns">
          <div class="column"></div>
          <div class="column is-narrow">
            <b-tooltip
                label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio, excepto la sección de acciones"
                multilined
                position="is-left">
              <b-icon
                  pack="fas"
                  icon="info-circle"
                  type="is-info"
                  size="is-medium">
              </b-icon>
            </b-tooltip>
          </div>
        </div>

        <!--DATA TABLE-->
        <b-table
            :data="people"
            :paginated= true
            per-page="10"
            striped
            hoverable
            :current-page="currentPage"
            :pagination-simple=false
            pagination-position="bottom"
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            default-sort="name"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual">
          <b-table-column
              field="name"
              label="Nombre"
              searchable
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.name}}
            </template>
          </b-table-column>
          <b-table-column
              field="last_name"
              label="Primer Apellido"
              sortable
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.last_name}}
            </template>
          </b-table-column>
          <b-table-column
              field="second_surname"
              label="Segundo Apellido"
              sortable
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.second_surname}}
            </template>
          </b-table-column>
          <b-table-column
              v-if="false"
              field="gender_attributes.name"
              label="Género"
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.gender_attributes!=null ? props.row.gender_attributes.name : ""}}
            </template>
          </b-table-column>
          <b-table-column
              label="Acciones"
              v-slot="props"
          >
            <a :href="props.row.url"><b-button type="is-info" icon-left="user-circle">
              Ver perfil
            </b-button></a>
          </b-table-column>
        </b-table>
      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>


  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "people_index",
  props:[
    "objectId"
  ],
  components:{

  },
  data(){
    return {
      selected: null,
      people: [],
      currentPage: 1,
      isLoading: false,
      hourTeachers: false,
      // EL SHOW VA A LA URL people/:id/life_record
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    initialize(){
      //this.setPeople("/admin/units/"+this.objectId+"/people.json")
      this.getProfesoresPlanta()
    },
    getProfesoresPlanta(){
      this.setPeople("/admin/units/"+this.objectId+"/teachers/plant.json")
      this.hourTeachers = false;
    },
    getProfesoresHora(){
      this.setPeople("/admin/units/"+this.objectId+"/teachers/hour.json")
      this.hourTeachers = true;
    },
    setPeople(url){
      this.isLoading = true
      return axios
          .get(url)
          .then(res => {
            this.people = res.data
            this.isLoading = false
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
          })

    },
    getType(type){
      if (type==1){
        if(this.hourTeachers){
          return 'is-primary is-light'
        }
        else{
          return 'is-primary'
        }
      }
      else{
        if(this.hourTeachers){
          return 'is-primary'
        }
        else{
          return 'is-primary is-light'
        }
      }
    }

  },

}
</script>
