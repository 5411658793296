<template>
  <div>
    <programs-table v-bind:programs="this.programs" :partner_entity_id="partnerEntityId" :can_create_program="can_create_program"
    :can_edit_program="can_edit_program"></programs-table>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
import axios from "axios";
import ProgramsTable from '/app/javascript/components/programs/ProgramsTable';
import moment from "moment";
export default {
  name: "PartnerEntitiesPrograms",
  components: {ProgramsTable},
  props:[
    'partnerEntityId','can_create_program','can_edit_program'
  ],
  data(){
    return{
      isLoading:false,
      programs:[],
    }
  },
  created(){
    this.getPartnerEntityPatents();

  },
  methods:{
    getPartnerEntityPatents(){
      let vm = this;
      vm.isLoading=true;
      axios
          .get("/partner_entities/"+vm.partnerEntityId+"/programs.json")
          .then(res => {
            vm.programs = res.data;
            vm.programs = vm.programs.map(program => {
              var program = program
              program["start_date"] = moment(program.start_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
              program["end_date"] = moment(program.end_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
              return program;
            });
            vm.isLoading=false;
          })
          .catch(e => {
            console.log(e);
            vm.isLoading=false;
          });
    }

  },
}
</script>

<style scoped>

</style>