<template>
  <section>
    <div class="container">
      <div class="box">
        <div class="columns">
          <div class="column">
            <h3 class="is-size-3 has-text-weight-bold">Auditoría SIGA</h3>
          </div>
        </div>
        <b-tabs @input="changeTab" type="is-boxed" :destroy-on-hide="true" v-model="currentTab" position="is-centered" expanded>
          <b-tab-item v-for="item in items"
                      :value="item.content"
                      v-bind:key="item.content"
                      :label="item.tab"
                      style="max-height: 100%">
          </b-tab-item>
        </b-tabs>
        <!-- COMPONENTE AQUI -->

        <component v-bind:is="currentTabComponent" :person-id="personId" ></component>

        <!-- FIN COMPONENTE -->
      </div>
    </div>
  </section>
</template>
<script>
import MhvAudit from "../life_record/life_record_audit"
import IdAudit from "./id_audit"
import GeneralAudit from "./general_audit";
import DocumentAudit from "./document_audit";
import SdtAudit from "../sdt_service/SdtAudit.vue";
export default {
  props:[
    'personId',
  ],
  data(){
    return {
      currentTab: 'GeneralAudit',
      items: [
        {tab: 'General', content: 'GeneralAudit'},
        {tab: 'Módulo Hoja de Vida',content:'MhvAudit'},
        {tab: 'Módulo I+D',content:'IdAudit'},
        {tab: 'Módulo Documentos',content:'DocumentAudit'},
        {tab: 'Módulo SDT',content:'SdtAudit'},
      ]
    }
  },
  components:{
    MhvAudit,
    IdAudit,
    GeneralAudit,
    DocumentAudit,
    SdtAudit
  },
  methods:{
    changeTab: function(value){
      this.currentTab = value
    },
    selectTab(tab){
      this.currentTab = tab
    }
  },
  computed: {
    currentTabComponent: function () {
      return this.currentTab
    }
  },
}
</script>