<template>
  <section>
    <div class="columns is-centered">
      <div class="column is-11">
        <b-message title="Importante" type="is-info" has-icon aria-close-label="Cerrar">
          Toda la información generada en el curriculum de jerarquización, es la que se encuentra registrada en el sistema.
        </b-message>
      </div>
    </div>
    <h3 class="is-size-4 has-text-weight-bold has-text-centered">Opciones de descarga</h3>
    <div class="columns">
      <div class="column">
        <b-table :data="items"
                 per-page=5
                 striped
                 hoverable
                 :pagination-simple=false
                 default-sort-direction="desc"
                 sort-icon="arrow-up"
                 sort-icon-size="is-small"
                 aria-page-label="Página"
                 aria-current-label="Página actual">
          <b-table-column field= 'option'
                          label= 'Tipo de documento'>
            <template slot="searchable"
                      slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{  props.row.option }}
            </template>
          </b-table-column>
          <b-table-column label= 'Formato de descarga'
                          v-slot="props"
                          width="250">
            <button id="word-button" @click="downloadWord(props.row)" class="button has-text-white is-word-color">
              <span class="icon">
                <i class="far fa-file-word"></i>
              </span>
            </button>
          </b-table-column>
        </b-table>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
      </div>
    </div>
  </section>
</template>

<script>
import {TemplateHandler} from "easy-template-x";
import { saveAs } from "file-saver";
import axios from "axios";
import moment from "moment";

export default {
  name: "downloads_tab",
  props: ['personId'],
  data(){
    return{
      items:[
        {option: 'Curriculum de Jerarquización', format:'das'},
      ],
      isLoading: false,
      data_cv: null,
    }
  },
  created() {
    this.get_data()
  },
  methods:{
    get_data(){
      this.isLoading=true;
      axios
          .get("/people/"+this.personId+"/cv_extendido.json")
          .then(res => {
            this.data_cv = res.data
            this.isLoading=false;
          })
          .catch(e => {
            console.log(e);
            this.isLoading=false;
          });
    },

   async downloadWord(row){
      //Se carga la plantilla Word
      const response = await fetch('/template-cv-extendido.docx');
      const templateFile = await response.blob();

      var birthday = moment(this.data_cv.birthday_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
      var birthday_date = new Date(birthday).toLocaleDateString("es-CL")

       var entrance = moment(this.data_cv.university_entrance_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
       if (entrance._isValid == true){
         entrance = new Date(entrance).toLocaleDateString("es-CL")
       }
       else{
        entrance = ""
     }

      //Se procesa la plantilla
      const data = {
        rut: this.data_cv.run_integral,
        departamento: this.data_cv.person_unit,
        paterno: this.data_cv.last_name,
        materno: this.data_cv.second_surname,
        nombre: this.data_cv.name,
        nacionalidad: this.data_cv.nationality_attributes.name,
        fecha_nacimiento: birthday_date,
        cargo: this.data_cv.cargo_curriculum,
        grado: this.data_cv.grado_curriculum,
        rango:  this.data_cv.rank,
        fecha_ingreso: entrance,
        titulos: this.data_cv.titulos,
        grados: this.data_cv.grados,
        antecedentes_academicos: this.data_cv.antecedentes_academicos,
        proyectos: this.data_cv.projects,
        publicaciones: this.data_cv.publicaciones,
        cargo_dir: this.data_cv.cargo_dir,
        beca_estudio:this.data_cv.beca_estudio,
        docencia:this.data_cv.docencia,
        evento:this.data_cv.evento,
        servicio:this.data_cv.servicio,
        cursos:this.data_cv.cursos,
        examenes:this.data_cv.examenes,
        docencia_ext:this.data_cv.docencia_ext,
        afiliaciones:this.data_cv.afiliaciones,
        premios:this.data_cv.premios
      };
      //Se procesa el documento para generar el archivo
      const handler = new TemplateHandler();
      const doc = await handler.process(templateFile, data);

      //Se genera el word para guardar
      saveAs(doc, "curriculum-extendido.docx");
      this.isLoading = false
    }
  }
}
</script>
