<template>
  <div class="container" ref="external_tutorial">
    <div>
      <div class="section pt-0">
        <div class="container px-5 py-0">
          <div class="columns is-multiline">
            <div class="column is-3 py-0">
              <b-sidebar position="static" type="is-light" open>
                <div class="p-1 pt-4">
                  <div class="block mt-5">
                    <h1 class="subtitle is-3 has-text-primary has-text-left">Índice</h1>
                  </div>
                  <b-menu class="is-custom-mobile">
                    <b-menu-list>
                      <b-menu-item label="1.¿Cómo agregar un participante externo?"></b-menu-item>

                    </b-menu-list>
                  </b-menu>
                </div>
              </b-sidebar>
              <div class="buttons-sidebar">
                <b-button class="icon-up" icon-left="arrow-circle-up" type="is-ghost" @click="goUp">
                  Volver arriba
                </b-button>
              </div>
            </div>

            <div class="column is-9 px-5">
              <div class="section p-5 pt-0">
                <h1 class="subtitle is-2 has-text-primary">
                  Agregar Participante Externo
                </h1>
                <div class="mb-3 ">
                  <p>El sistema permite ingresar participantes externos para que sean asociados a la actividad académica correspondiente. Esto en caso de que la persona que se desea ingresar como participante no se encuentre registrada en el sistema.</p>
                </div>
                <h2 class="subtitle is-3 mt-5 mb-2">
                  Paso 1
                </h2>
                <p class="my-3 ">En el modal para ingresar a los participantes de la actividad académica, se debe presionar el botón +.
                </p>

                <img border="2" class="m-2" src="/tutorial_images/add-participant-external.png" style="width:70%">


                <h2 class="subtitle is-3 mt-5 mb-2">
                  Paso 2
                </h2>
                <p class="my-3 ">Se despliega un nuevo modal, donde se debe ingresar los datos de la nueva persona externa, el cual se debe hacer en distintos pasos.
                </p>
                <ul class=" ml-4">
                  <li>2.1. En el primer paso del formulario se debe ingresar los datos de identificación, en este paso se encuentran ciertos datos obligatorios, los cuales
                    están identificados con el símbolo (*). Además, se debe ingresar como obligatorio al menos el correo, o el pasaporte o el rut con uno de estos datos es suficiente. Por otro, lado se puede asociar
                    alguna entidad a esta persona, para lo cual existe el campo "Cargo en la entidad" y "Seleccione entidad". Para este último se debe seleccionar alguna de las entidades que se encuentran registradas en el sistema, se deben ingresar al menos tres letras para que se ejecute la búsqueda de las entidades.
                  </li>
                  <img border="2" class="m-2" src="/tutorial_images/modal-external-paso1.png" style="width:70%">
                  <li>2.2. En el segundo paso se deben ingresar los datos personales, los cuales ninguno es obligatorio y si solo se quiera ingresar información adicional sobre la persona.</li>
                  <img border="2" class="m-2" src="/tutorial_images/modal-external-paso2.png" style="width:70%">
                  <li>2.3. En el tercer paso se deben ingresar los datos de contacto de la persona, los cuales tampoco son obligatorios. En este caso se deben especificar los números de teléfono/celular de la persona los cuales deben contener entre 9 a 12 dígitos.</li>
                  <img border="2" class="m-2" src="/tutorial_images/modal-external-paso3.png" style="width:70%">
                  <li>2.4 Cuando se hayan ingresado todos los datos necesarios de la persona, se debe presionar el botón "Guardar Persona" y con esto estaría disponible la persona para ser ingresada en la actividad académica como participante.</li>
                </ul>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "partipantExternalTutorial",
  methods:{
    goUp(){
      var elmnt = document.getElementById("app");
      elmnt.scrollIntoView();
      this.$refs.external_tutorial.scrollTop = 0;
    }
  },
}
</script>

<style>
.icon-up{
  position: fixed;
  width: 16.5% !important;
  bottom: 0;
  left: 15%;
}
.buttons-sidebar{

}
</style>