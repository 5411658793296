<template>
  <section>
    <b-tabs position="is-centered" v-model="activeTab" >
      
            <b-tab-item label="Todos" icon="book">          
              <services-table v-bind:servicios="this.servicios" :cargoPrincipal="this.cargoPrincipal" :unidad="this.objectId"  :hide-update="true" ></services-table>
            </b-tab-item>
            <b-tab-item label="Proyecto de asistencia técnica" icon="bookmark">

              <services-table v-bind:servicios="this.asistenciaTecnicas" :cargoPrincipal="this.cargoPrincipal" :unidad="this.objectId" :hide-update="true" ></services-table>
            </b-tab-item>
            <b-tab-item label="Capacitación" icon="file">

              <services-table v-bind:servicios="this.capacitaciones" :cargoPrincipal="this.cargoPrincipal" :unidad="this.objectId"  :hide-update="true" ></services-table>
            </b-tab-item>
            <b-tab-item label="Programas especiales" icon="file">

              <services-table v-bind:servicios="this.programas" :cargoPrincipal="this.cargoPrincipal" :unidad="this.objectId" :hide-update="true" ></services-table>
            </b-tab-item>
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </b-tabs>

         
  </section>
</template>
<script>
import axios from "axios";
import ServicesTable from "./ServicesTable.vue";
import moment from "moment";
export default {
  props:[
    'objectId','componentType', 'cargoPrincipal','currentYear','minYear','maxYear', 'changeFilter', 'hideUpdate'
  ],
  data(){
    return{
      servicios:[],
      capacitaciones: [],
      programas: [],
      asistenciaTecnicas: [],
      isLoading:false,
      activeTab: 0,
      year: new Date().getFullYear(),
    }
  },
  components:{
   ServicesTable
  },
  created(){
    this.getServices();

  },
  methods:{
    changeTab: function(value){
      this.currentTab = value
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    getServices(){
      let vm = this;
      vm.isLoading=true;
      axios
        .get("/sdt_services/"+vm.objectId+"/services.json",{params:{
            minYear: vm.minYear,
            maxYear: vm.maxYear,
            componentType: vm.componentType
        }})
        .then(res => {
          
          vm.servicios = res.data;
          vm.servicios = vm.servicios.map(servicio => {
            var newServicio = servicio
            if(newServicio["start_date"]!=null)
              newServicio["start_date"] = moment(servicio.start_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            return newServicio;
          });
          vm.capacitaciones = vm.servicios.filter(servicio => servicio.sdt_service_type === "Capacitación");
          vm.asistenciaTecnicas = vm.servicios.filter(servicio => servicio.sdt_service_type === "Proyecto De Asistencia Tecnica");
          vm.programas = vm.servicios.filter(servicio => servicio.sdt_service_type === "Programas Especiales");


          vm.isLoading=false;

        })
        .catch(e => {
          console.log(e);
          vm.isLoading=false;
        });
    }

  },
  computed:{

  },
  watch:{
    changeFilter: function(){
      this.getServices();
    }
  }
}
</script>