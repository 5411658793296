<template>
  <div>
    <div class="modal-card" style="width: auto">
      <section class="modal-card-body">
        <div >
          <div class="columns" v-if="can_edit==true">
            <div class="column has-text-right">
              <br>
              <b-button
                  class="button right-button is-info is-light is-outlined"
                  icon-left="pen"
                  @click="editTransaction()">
                Editar Transacción
              </b-button>
            </div>

          </div>


          <b-tabs position="is-centered" @input="changeTab">
            <b-tab-item label="General" icon="address-card" content="General">

              <div class="columns">
                <div class="column">
                  <b-field label="Unidad">
                    <b-input readonly v-model="transaction.unit" type="text"></b-input>
                  </b-field>
                </div>

                <div class="column">
                  <b-field label="Código de EFUSACH asociado">
                    <b-input readonly expanded v-model="transaction.code_turn" type="text"></b-input>
                    <p class="control">
                      <b-button class="is-primary" icon-right="external-link-alt" @click="goSurplus">
                        Ir</b-button>
                    </p>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Tipo de transacción">
                    <b-input readonly v-model="transaction.sdt_origin_transaction_type" type="text"></b-input>
                  </b-field>
                </div>

                <div class="column" v-if="transaction.sdt_transaction_type !=''">
                  <b-field label="Categoría de transacción" >
                    <b-input readonly v-model="transaction.sdt_transaction_type" type="text"></b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns" v-if="transaction.other_id !=''">
                <div class="column">
                  <b-field label="Descripción de otro Categoría de transacción">
                    <b-input readonly v-model="transaction.other_id" type="text"></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Fecha de pago">
                    <b-input readonly v-model="transaction.payment_date" type="text"></b-input>
                  </b-field>
                </div>
                
                <div class="column">
                  <b-field label="Monto bruto total">
                    <b-input readonly :value="'$'+Number(transaction.amount).toLocaleString('es-ES')" type="text"></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns" v-if="transaction.person != ''">
                <div class="column">
                  <b-field label="Persona Asignada">
                    <b-input readonly v-model="transaction.person" type="text"></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns" v-if="transaction.partner_entity != ''">
                <div class="column">
                  <b-field label="Proveedor">
                    <b-input readonly v-model="transaction.partner_entity" type="text"></b-input>
                  </b-field>
                </div>
              </div>

            </b-tab-item>

            <b-tab-item label="Documentos" icon="book" content="Documentos">
              <b-table
              :data="files"
              :paginated=true
              per-page=10
              striped
              hoverable
              :current-page="currentPage"
              :card-layout="cardLayout"
              :pagination-simple=false
              pagination-position="bottom"
              default-sort-direction="desc"
              sort-icon="arrow-up"
              sort-icon-size="is-small"
              default-sort="document_date"
              aria-next-label="Siguiente"
              aria-previous-label="Anterior"
              aria-page-label="Página"
              aria-current-label="Página actual">
                <b-table-column
                    field= 'document_type.name'
                    label= 'Tipo de documento'
                    width="10em"
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{  props.row.document_type.name || " " }}
                  </template>
                </b-table-column>

                <b-table-column
                    field= 'transaction.code'
                    label= 'Código'
                    width="8em"
                    searchable
                    sortable                   
                    >
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{  props.row.transaction.code || " " }}
                  </template>
                </b-table-column>
                <b-table-column
                    field= 'transaction.amount'
                    label= 'Monto total'
                    width="10em"
                    searchable
                    v-if="transaction.is_parent==true"
                    sortable>
                    <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ "$"+props.row.transaction.amount || " "}}
                  </template>
                </b-table-column>
                <b-table-column
                    field= 'transaction.partner_entity'
                    label= 'Proveedor'
                    width="10em"
                    searchable
                    v-if="transaction.is_parent==true"
                    sortable>
                    <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.transaction.partner_entity || " "}}
                  </template>
                </b-table-column>

                <b-table-column
                    field= 'transaction.payment_date'
                    label= 'Fecha de pago'
                    width="10em"
                    searchable
                    v-if="transaction.is_parent==true"
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.transaction.payment_date || " "}}
                  </template>
                </b-table-column>

                
                <b-table-column
                    label="Documentación"
                    width="10em"
                    v-slot="props">              

                  <b-button type="is-primary is-light is-outlined"
                            icon-left="download"
                            v-if="transaction.is_parent==true"
                            @click="dowloadDocument(props.row)">
                  </b-button>
                  <b-button type="is-primary is-light is-outlined"
                            icon-left="download"
                            v-else
                            @click="dowloadDocument(props.row)">
                            Descargar
                  </b-button>
                  <b-button type="is-success is-light is-outlined"
                            v-if="transaction.is_parent==true"
                            @click="showPdf(props.row)">
                            <span class="icon" style="padding-bottom: 1px">
                              <span class="fa-stack fa-sm">
                                <i class="fas fa-file fa-stack-2x custom-size-icon-behind"></i>
                                <i class="fas fa-eye fa-stack-1x custom-size-icon-front has-text-white"></i>
                              </span>
                            </span>
                  </b-button>
                  <b-button type="is-success is-light is-outlined"
                            v-else
                            @click="showPdf(props.row)">
                            <span class="icon" style="padding-bottom: 1px">
                              <span class="fa-stack fa-sm">
                                <i class="fas fa-file fa-stack-2x custom-size-icon-behind"></i>
                                <i class="fas fa-eye fa-stack-1x custom-size-icon-front has-text-white"></i>
                              </span>
                            </span>
                            <span>  Visualizar</span>
                            
                  </b-button>
                </b-table-column>

                <b-table-column
                    label="Información"
                    width="7em"
                    v-if="transaction.is_parent==true"
                    v-slot="props">              

                  <b-button type="is-primary is-light is-outlined"
                            icon-left="eye"
                             v-if="props.row.document_type.name.includes('ANEXO')"
                            @click="showDocument(props.row)">
                  </b-button>
                </b-table-column>

                <template slot="empty" slot-scope="props">
                  <div class="columns is-centered">
                    <div class="column is-6 is-centered has-text-centered">
                      <b-message type="is-danger">
                        No se encontraron documentos asociados.
                      </b-message>
                    </div>
                  </div>
                </template>
              </b-table>
            </b-tab-item>

          </b-tabs>



        </div>
      </section>
    </div>

    <b-modal
        v-model="isComponentModalPDF"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        width="100%"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-show-pdf   :src="src"
                          style="width: 90vw"
                          @close="props.close"
                         
        >
        </modal-show-pdf>
      </template>
    </b-modal>

    <b-modal
        :active="isComponentModalServicioActive"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        width="90%"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-transaction-servicio  :transaction-id="transactionId"
                                     :query-type = "queryType"
                                     :unidad-principal = "unidadPrincipal"
                                     modal-header="Editar Transaccion de egreso"
                                     modal-cancel="true"
                                     @close="closeModal"
                                     @clickedUpdate="onClickUpdate"
        >
        </modal-transaction-servicio>
      </template>
    </b-modal>

    <b-modal
        :active="isComponentModalContratacionActive"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        width="90%"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-transaction-contratacion  :transaction-id="transactionId"
                                         :query-type = "queryType"
                                         :unidad-principal = "unidadPrincipal"
                                         modal-header="Editar Transaccion de egreso"
                                         modal-cancel="true"
                                         @close="closeModal"
                                         @clickedUpdate="onClickUpdate"
        >
        </modal-transaction-contratacion>
      </template>
    </b-modal>

    <b-modal
        :active="isComponentModalBienActive"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        width="90%"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-transaction-bien  :transaction-id="transactionId"
                                 :query-type = "queryType"
                                 :unidad-principal = "unidadPrincipal"
                                 modal-header="Editar Transaccion de egreso"
                                 modal-cancel="true"
                                 @clickedUpdate="onClickUpdate"
                                 @close="closeModal"
        >
        </modal-transaction-bien>
      </template>
    </b-modal>


    <b-modal
        :active="isComponentModalRendicionBienActive"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        width="90%"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-transaction-render-bien  :transaction-id="transactionId"
                                        :query-type = "queryType"
                                        :unidad-principal = "unidadPrincipal"
                                        modal-header="Editar Transaccion de egreso"
                                        modal-cancel="true"
                                        @clickedUpdate="onClickUpdate"
                                        @close="closeModal"
        >
        </modal-transaction-render-bien>
      </template>
    </b-modal>

    <b-modal
        :active="isComponentModalRendicionActive"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        width="90%"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-transaction-render
            :transaction-id="transactionId"
            :query-type = "queryType"
            :tipo-transaction = "tipoTransaction"
            :unidad-principal = "unidadPrincipal"
            modal-cancel="true"
            modal-header="Editar Transaccion de egreso"
            @clickedUpdate="onClickUpdate"
            @close="closeModal"
        >
        </modal-transaction-render>
      </template>
    </b-modal>


    <b-modal
        :active="isComponentModalShowDocuemnt"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        width="90%"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-document-render-fund
               query-type="show"
               :respaldo="showDocumentInfo"
               modal-header="Ver Transaccion de egreso"
               :tipos-anexo="[]"
               @close="closeModal"
        >
        </modal-document-render-fund>
      </template>
    </b-modal>




    


    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import modalTransactionServicio from "./NewTransactionServicioForm.vue";
import modalTransactionContratacion from "./NewTransactionContratacionForm.vue";
import modalTransactionBien from "./NewTransactionBienForm.vue";
import modalTransactionRenderBien from "./NewTransactionBienRenderFundForm.vue";
import modalTransactionRender from "./ModalEditTransactionRenderFundForm.vue";
import modalTransactionRenderServicio from "./NewTransactionServiceRenderFundForm.vue";
import ModalDocumentRenderFund from "./ModalDocumentRenderFund.vue"
import modalShowPdf from "../ModalShowPDF.vue";
export default {
  components:{modalShowPdf, ModalDocumentRenderFund, modalTransactionServicio, modalTransactionContratacion, modalTransactionBien, modalTransactionRenderServicio, modalTransactionRenderBien, modalTransactionRender},
  props:[
    'transactionId', 'cargoPrincipal', 'userId'
  ],
  data(){
    return{
      transaction: {},
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      can_edit: false,
      currentTab: "General",
      isComponentModalPDF: false,
      src: null,
      canCancel: ['escape'],
      files: [],
      tipoTransaction: null,
      isComponentModalBienActive: false,
      isComponentModalServicioActive: false,
      isComponentModalContratacionActive: false,
      isComponentModalRendicionBienActive: false,
      isComponentModalRendicionServicioActive: false,
      isComponentModalRendicionActive: false,
      isComponentModalShowDocuemnt: false,
      showDocumentInfo: null,
      queryType: null,
      unidadPrincipal: {},
    }
  },
  async created(){
    let vm = this;
    vm.isLoading=true;
    axios
        .get("/sdt_transactions/"+vm.transactionId+".json")
        .then(res => {
          vm.transaction = res.data;
          vm.can_edit = res.data.can_edit;          
          if (vm.transaction.person != ""){
            vm.transaction.person = vm.transaction.person.person_name + " - RUT:  "+vm.transaction.person.run_integral
          }
          if (vm.transaction.partner_entity != ""){
            vm.transaction.partner_entity = vm.transaction.partner_entity.name
          }
          vm.transaction.payment_date = moment(vm.transaction.payment_date).format('DD-MM-YYYY');
        })
        .catch(e => {
          console.log(e);
          vm.isLoading=false;
        });
    await vm.getDocuments();

  },
  methods:{
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    changeTab: function(value){
      this.currentTab = value
    },
    goSurplus(){
      window.open('/sdt_surpluses/'+this.transaction.sdt_surplus_id, '_blank')
    },
    async getDocuments() {
      let vm = this;
      await axios.get("/sdt_transactions/documents/"+vm.transactionId+".json")
        .then(res => {
          vm.files = res.data.documents;
          vm.files = vm.files.map(file => {
            var newFile = file
            newFile["transaction"]["amount"] = Number(file.transaction.amount).toLocaleString('es-ES')
            if (file.transaction.partner_entity != ""){
              newFile["transaction"]["partner_entity"] = file.transaction.partner_entity.name
            }
            else
            {
              newFile["transaction"]["partner_entity"] = " "
            }

            newFile["transaction"]["payment_date"] = moment(file.transaction.payment_date).format('DD-MM-YYYY');

            return newFile;
          });
          this.isLoading = false
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    dowloadDocument(row){
      let vm = this;
      this.isLoading = true;
      axios.get("/documents/" + row.id + "/download", {
            responseType: "blob",
          })
      .then( response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', row.name);
        document.body.appendChild(link);
        link.click();
        vm.isLoading = false;
        this.$buefy.dialog.alert({
          message: 'Se descargo con exito.',
          type: 'is-success',
          hasIcon: true,
          icon: 'check-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      .catch(e =>{
        this.isLoading = false;
        this.$buefy.dialog.alert({
          message: 'Fallo al descargar.',
          type: 'is-danger',
        });
      })
    },

    showPdf(row){
      let vm = this;
      vm.isLoading = true;
      axios
          .get("/documents/" + row.id + "/download", {
            responseType: "blob",            
            params:{type: "pdf"},
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            vm.src = new Uint8Array(buffer);
            vm.isComponentModalPDF = true
            vm.isLoading = false
          })
          .catch(e => {
            vm.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },


    editTransaction(row){
      this.unidadPrincipal.id = this.transaction.unit_id;
      this.queryType = "update"
      this.tipoTransaction = this.transaction.sdt_origin_transaction_type
      if(this.transaction.sdt_origin_transaction_type == "Servicio"){
        if (this.transaction.sdt_transaction_type != ""){
          this.isComponentModalServicioActive = true;
        }
        else
        {
          this.isComponentModalRendicionActive = true;
        }
      }
      else if (this.transaction.sdt_origin_transaction_type == "Bien"){

        if (this.transaction.sdt_transaction_type != ""){
          this.isComponentModalBienActive = true;
        }
        else
        {
          this.isComponentModalRendicionActive = true;
        }
      }
      else{
        this.isComponentModalContratacionActive = true;
      }

    },
    showDocument(row){
      this.showDocumentInfo = row.transaction;
      this.showDocumentInfo.tipoAnexo = row.document_type;
      this.isComponentModalShowDocuemnt = true;
    },
    closeModal(){
      this.isComponentModalBienActive = false;
      this.isComponentModalServicioActive = false;
      this.isComponentModalContratacionActive = false;
      this.isComponentModalRendicionBienActive = false;
      this.isComponentModalRendicionServicioActive = false;
      this.isComponentModalRendicionActive = false;
      this.isComponentModalShowDocuemnt = false;

    },

    async onClickUpdate(elemento){
      this.isLoading = true
      this.transaction = elemento;
      this.transaction.payment_date = moment(this.transaction.payment_date).format('DD-MM-YYYY');
      if (this.transaction.person != ""){
        this.transaction.person = this.transaction.person.person_name + " - RUT:  "+this.transaction.person.run_integral
      }
      if (this.transaction.partner_entity != ""){
        this.transaction.partner_entity = this.transaction.partner_entity.name
      }
      await this.getDocuments();
      this.isLoading = false
    },


  },
  computed:{
    currentTabComponent: function () {
      return this.currentTab
    }
  }
}
</script>


<style scoped>  
  .custom-size-icon-behind{
    width: 0.9em; 
    height: 1em;
    margin-top: 0.1em;
  }
  .custom-size-icon-front{
    margin-top: 0.8em;
  }
  .fa-sm {
    font-size: 0.775em;
  }
</style>