<template>

  <section class="box">
    <div class="container">
      <b-message title="Importante" type="is-info" has-icon aria-close-label="Cerrar mensaje" size="is-small">
        <ul>
          <li>
            - El cálculo de indicadores para la Facultad de Ingeniería se realiza en base a las actividades acádemicas con
            personas relacionadas a los Departamentos académicos de esta, no en base a la suma individual de los indicadores
            de cada departamento, por lo que si realiza esta suma, podría no ser coincidente con el dato mostrado para FING.
            <br> *Si una actividad académica cuenta con más de un participante de distintas unidades, esta actividad contará
            como una para el indicador de cada unidad, y para FING la actividad académica se contaría como única y el resultado
            del indicador sería de uno, y no de dos, que sería el resultado si el cálculo fuera la suma por cada departamento.
            </li>
          <br>
          <li>
            - La vista de composición del indicador trae datos del momento actual con los cuales se calcularia el indicador,
            versus los resultados del cálculo de indicadores,
            que es realizado automáticamente por el sistema en momentos determinados (informacion que se puede visualizar en "Último
            cálculo de indicadores realizado por sistema").
            Si se realizan cálculos en base a la vista de composición podría no coincidir con el dato mostrado y calculado
            por el sistema ya que corresponde a tipos distintos.
          </li>
        </ul>
      </b-message>

    </div>
    <div class="container" style="margin-top: 2.5em">
      <div class="columns is-centered">
        <!-- SELECT DEPARTAMENTO -->
        <div v-if="nombre_unidades.length!==0" class="column is-narrow">
          <b-field>
            <b-select placeholder="Selecciona una unidad" v-model="selectedUnit">
              <option
                  v-for="unidad in nombre_unidades"
                  :value="unidad"
                  :key="unidad.id">
                {{ unidad.name }}
              </option>
            </b-select>
          </b-field>
        </div>

        <div class="column is-narrow" v-if="years.length!==0" >
          <b-field>
            <b-select v-model="selectedYear">
              <option
                  v-for="year in years"
                  :value="year"
                  :key="year">
                {{ year }}
              </option>
            </b-select>
          </b-field>

        </div>
        <div class="column is-narrow">
          <b-button type="is-primary" @click="updateIndicators">Ver indicadores</b-button>
        </div>

      </div>
    </div>
    <div v-if="can_update_indicators" class="container">
      <div class="columns" style="margin-bottom: -20px">
        <div class="column">
          <div class="is-size-5 has-text-weight-semibold">
            Calcular Indicadores:
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field>
            <multiselect v-model="calculus_unit"
                         :options="nombre_unidades"
                         label="name"
                         placeholder="Seleccione unidad"
                         selectLabel="Presione para seleccionar"
                         selectedLabel="Seleccionado"
                         deselectLabel="Presione para deseleccionar"
                         :allow-empty="false">
              <template v-slot:noOptions>
                No existen datos
              </template>
              <span slot="noResult">
                No se encontraron elementos.
            </span>
            </multiselect>
          </b-field>

        </div>

        <div class="column is-narrow">
          <b-field>
            <multiselect v-model="calculus_indicator"
                         :options="allowed_indicators"
                         label="code"
                         placeholder="Seleccione indicador"
                         selectLabel="Presione para seleccionar"
                         selectedLabel="Seleccionado"
                         deselectLabel="Presione para deseleccionar"
                         :allow-empty="true">
              <template v-slot:noOptions>
                No existen datos
              </template>
              <span slot="noResult">
                No se encontraron elementos.
            </span>
            </multiselect>
          </b-field>

        </div>
        <div class="column is-narrow">
          <b-field>
            <multiselect v-model="calculus_year"
                         :options="years"
                         placeholder="Seleccione año"
                         selectLabel="Presione para seleccionar"
                         selectedLabel="Seleccionado"
                         deselectLabel="Presione para deseleccionar"
                         :allow-empty="false">
              <template v-slot:noOptions>
                No existen datos
              </template>
              <span slot="noResult">
                No se encontraron elementos.
            </span>
            </multiselect>
          </b-field>

        </div>
        <div class="column is-narrow has-text-right">
          <b-button type="is-primary" @click="calculateIndicator">Calcular indicadores</b-button>
        </div>
      </div>
    </div>
    <br>

    <template>
      <b-tabs v-if="noData === false">
        <b-tab-item label="Indicadores por año" icon="calendar-alt">

          <indicators_table @aportePorDepartamento="aportePorDepartamento"
                            @evolucionPorAnio="evolucionPorAnio"
                            @indicatorComposition="indicatorComposition"
                            v-bind:indicators="data"
                            v-bind:indicators-names="nombre_indicadores"
                            v-bind:unit-type="'FACULTAD'" v-bind:unit-id="selectedUnit.id"></indicators_table>
        </b-tab-item>
        <b-tab-item v-if="canSeeDptoIndicators" label="Indicadores por departamento" icon="university">
          <b-table
              :data="isEmpty ? [] : indicadores_by_dpto"
              :striped="isStriped"
              :narrowed="isNarrowed"
              :hoverable="isHoverable"
              :mobile-cards="hasMobileCards"
              :loading="isLoading"
              :sticky-header=true
              height="100%"
              v-if="flag_facultad===true"
          >
            <b-table-column field="codigo" label="Código" v-slot="props">
              {{ props.row.codigo }}
            </b-table-column>
            <template v-for="dpto in dptos_ingenieria">
              <b-table-column :label=dpto.short_name v-slot="props">
                {{findValorDpto(props.row.codigo,dpto.name,indicadores_by_dpto)}}{{ props.row.signo }}
              </b-table-column>
            </template>

          </b-table>
        </b-tab-item>
      </b-tabs>
      <template v-else>
        <section style="margin-top: 2%">
          <b-message title="Ups..." type="is-danger" has-icon aria-close-label="Close message">
            No existen registros para el año seleccionado: {{selectedYear}}
          </b-message>
        </section>
      </template>
    </template>


    <b-modal aria-modal width="90%" v-model="isCardModalActive">
      <div class="modal-background"></div>
      <div class="modal-card" style="width:100%">
        <header class="modal-card-head is-centered has-text-centered has-background-primary">
          <div class="columns is-centered has-text-centered">
            <div class="column">
              <p class="modal-card-title has-text-white">Aporte al indicador por departamento</p>
            </div>
          </div>

        </header>
        <section id="vue-modal" class="modal-card-body">

        </section>
      </div>
    </b-modal>

    <b-modal
        v-model="isCompositionModalActive"

        :destroy-on-hide="true"
        width="90%"
        :can-cancel="canCancel">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <div class="columns">
              <div class="column">
                <indicator-composition v-bind:indicator-id="selectedIndicator"
                                       v-bind:unit-id="selectedUnit.id"
                                       v-bind:year="selectedYear"></indicator-composition>
              </div>
            </div>

          </div>
        </div>
      </div>

    </b-modal>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  </section>
</template>

<script>
import axios from "axios";
import Indicators_table from "./dashboard/indicators_table.vue";
import IndicatorComposition from "./dashboard/IndicatorComposition.vue";
export default {
  components: {IndicatorComposition, Indicators_table},
  created() {
    let vm = this;
    vm.isLoading = true;
    return axios
        .get("/dashboards.json",{params:{flag_facultad: true}})
        .then(res => {
          vm.data = res.data['indicadores_unidad'];
          vm.nombre_unidades = res.data['nombre_unidades'];
          vm.nombre_indicadores = res.data['nombre_indicadores'];
          vm.has_permission = res.data['has_permission'];
          vm.years = this.generateArrayOfYears();
          vm.selectedUnit = this.nombre_unidades[0]
          vm.selectedYear = this.years[0]
          vm.indicadores_by_dpto=res.data["indicadores_by_dptos"];
          vm.dptos_ingenieria=res.data["dptos_ingenieria"];
          vm.noData = res.data["no_data"];
          vm.allowed_indicators = res.data["allowed_indicators"];
          vm.can_update_indicators = res.data["can_update_indicators"];
          vm.isLoading = false;
          if (vm.selectedUnit.name=="FACULTAD DE INGENIERIA"){
            vm.canSeeDptoIndicators = true;
          }
          else{
            vm.canSeeDptoIndicators = false;
          }
        })
        .catch(e => {
          console.log(e);
          vm.isLoading = false;
        });
  },
  data() {
    const data = [

    ]

    return {
      data,
      noData: false,
      isEmpty: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: true,
      isLoading: false,
      flag_facultad: true,
      hasMobileCards: true,
      nombre_indicadores:[],
      nombre_unidades:[],
      dptos_ingenieria:[],
      years:[],
      selectedUnit:{},
      selectedYear:null,
      has_permission:false,
      isCardModalActive: false,
      indicadores_by_dpto:[],
      canSeeDptoIndicators:false,
      isCompositionModalActive:false,
      canCancel: ['escape','x'],
      selectedIndicator:0,
      calculus_year:[],
      selected_calculus_year:null,
      calculus_indicator:null,
      calculus_unit:null,
      can_update_indicators:false,
      allowed_indicators:[],
      myData: [],
    }
  },
  methods: {
    findIndicator(indicator_id){
      return this.nombre_indicadores.find(o=>o.id===indicator_id)
    },
    aportePorDepartamento(data){
      //data = {unidad - año - codigo indicador}
      let vm = this;
      vm.isCardModalActive = true;
      $.ajax({
        url: "/dashboards/get_indicator_by_dpto.js",
        type: "get",
        data: data,
        success: function (res) {

        }
      })
    },
    evolucionPorAnio(data){
      //data = unit_id , indicator_code
      event.preventDefault();
      this.isCardModalActive = true
      $.ajax({
        url: "/dashboards/get_indicator_evolution.js",
        type: "get",
        data: data,
        success: function (res) {

        }
      })
    },
    indicatorComposition(data){
      this.selectedIndicator = data.indicator_id;
      this.isCompositionModalActive = true;
    },
    generateArrayOfYears() {
      var max = new Date().getFullYear()
      var min = max - 12
      var years = []

      for (var i = max; i >= min; i--) {
        years.push(i)
      }
      return years
    },
    updateIndicators(){
      let vm = this;
      var unit_id = vm.selectedUnit.id;
      var flag_facultad = true;
      (vm.selectedUnit.name !== "FACULTAD DE INGENIERIA") ? flag_facultad = false : flag_facultad = true
      var mydata = { unit_id: unit_id , year: vm.selectedYear, flag_facultad: flag_facultad};
      vm.isLoading = true;
      return axios
          .get("/dashboards.json",{ params:mydata})
          .then(res => {
            vm.data = res.data['indicadores_unidad'];
            vm.indicadores_by_dpto=res.data["indicadores_by_dptos"];
            vm.noData = res.data["no_data"];
            vm.isLoading = false;
            if (this.selectedUnit.name=="FACULTAD DE INGENIERIA"){
              vm.canSeeDptoIndicators = true;
            }
            else{
              vm.canSeeDptoIndicators = false;
            }
          })
          .catch(e => {
            console.log(e);
            vm.isLoading = false;
          });
    },
    findValorDpto(codigo,dpto,indicadores_by_dpto){
      for(var i=0; i<indicadores_by_dpto.length;i++){
        if (codigo===indicadores_by_dpto[i].codigo){
          for(var j=0; j<indicadores_by_dpto[i].valores_dptos.length;j++){
            if(dpto===indicadores_by_dpto[i].valores_dptos[j].nombre_unidad){
              return indicadores_by_dpto[i].valores_dptos[j].valor_indicador
            }
          }
        }
      }
      return 0
    },
    calculateIndicator(){
      let vm = this;
      vm.isLoading = true;
      let unit_id = null;
      let indicator_id = null;
      let year_id = null;
      if (vm.calculus_unit){
        unit_id = vm.calculus_unit.id;
      }
      if (vm.calculus_indicator){
        indicator_id = vm.calculus_indicator.id;
      }

      return axios
        .post("/dashboards/calculate-indicator.json",{ unit_id:unit_id,indicator_id:indicator_id,year:vm.calculus_year })
        .then(res => {
          vm.isLoading = false;
          vm.$buefy.dialog.alert({
            message: 'El indicador fue calculado satisfactoriamente.',
            type: 'is-success',
            hasIcon: true,
            icon: 'check-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          vm.updateIndicators();
        })
        .catch(e => {
          //console.log(e);
          vm.$buefy.dialog.alert({
            message: 'Hubo un error en su petición.',
            type: 'is-danger',
            hasIcon: true,
            icon: 'check-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          vm.isLoading = false;
        });
    }
  },
  mounted() {

  }


}


</script>
