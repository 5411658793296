<template>
  <div style="width: 100%">
    <h3
      class="has-text-centered is-size-6 has-text-weight-semibold has-background-primary-light"
      :style="{
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '0.5rem 0.5rem 0rem 0rem',
        borderColor: '#e5e7eb',
      }"
    >
      {{ title }}
      <div
        class="has-text-centered is-size-7 has-text-weight-semibold has-background-primary-light"
      >
        Año: {{ currentYear }}
      </div>
    </h3>
    <div
      class="level is-mobile"
      :style="{
        borderWidth: '1px',
        borderStyle: 'solid',
        borderTop: 'none',
        borderRadius: '0rem 0rem 0.5rem 0.5rem',
        borderColor: '#e5e7eb',
        padding: '1rem',
      }"
    >
      <div class="level-item has-text-centered">
        <div v-if="loading">
          <p class="heading">
            Días <br />
            utilizados
          </p>
          <b-icon
            pack="fas"
            icon="sync-alt"
            size="is-medium"
            custom-class="fa-spin"
          />
        </div>
        <div v-else>
          <p class="heading">
            Días <br />
            utilizados
          </p>
          <p class="title">{{ usedDays }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div v-if="loading">
          <p class="heading">
            Días <br />
            restantes
          </p>
          <b-icon
            pack="fas"
            icon="sync-alt"
            size="is-medium"
            custom-class="fa-spin"
          />
        </div>
        <div v-else>
          <p class="heading">
            Días <br />
            restantes
          </p>
          <p class="title" :class="changeTextColor(remainingDays)">
            {{ usedDays > 6 ? 0 : remainingDays }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    usedDays: {
      type: Number,
      default: 0,
    },
    currentYear: {
      type: Number,
      default: new Date().getFullYear(),
    },
    title: {
      type: String,
      default: "Situación actual",
    },
    loading: {
      type: Boolean,
    },
  },
  computed: {
    remainingDays() {
      return 6 - parseFloat(this.usedDays);
    },
  },
  methods: {
    changeTextColor(remainingDays) {
      return remainingDays > 1 ? "has-text-success" : "has-text-danger";
    },
  },
};
</script>
