<template>
  <section>
    <div class="box">
      <div class="container">
        <h1 class="title is-2 has-text-weight-medium has-text-centered">Facultad de Ingeniería</h1>

        <b-tabs position="is-centered" @input="changeTab">

          <b-tab-item label="Resumen completo" icon="address-card">
            <h3 class="title is-2 has-text-weight-medium has-text-centered">Resumen de las transacciones de la Facultad de Ingeniería y sus Departamentos Académicos</h3>


            <section class="hero has-background-white-bis" style="box-shadow: 0px 6px 14px -4px rgba(196,196,196,1);">
              <b-collapse
                aria-id="contentIdForA11y2"
                class="custom-collapse"
                animation="slide"
                v-model="isOpen">
                <template #trigger="props">
                  <div
                      class="card-header"
                      role="button"
                      aria-controls="contentId3"
                      :aria-expanded="props.open">
                       <h2 class="card-header-title custom-header-title is-centered">
                        <b-icon type="is-info" class="is-pulled-left" icon="info-circle"></b-icon>
                        Filtros Aplicados
                      </h2>
                      <a class="card-header-icon">
                         <b-icon class="is-info" :icon="props.open ? 'sort-down' : 'sort-up'"></b-icon>
                      </a>
                  </div>
                </template>
                <div class="hero-body">
                  <div class="container">
                  
                    <div class="columns">
                      <div class="column">
                        <b-field label="Año desde:">
                          <multiselect v-model="minYear"
                                       :options="year_options"
                                       :searchable="true"
                                       :close-on-select="true"
                                       placeholder="Elija un año"
                                       :allow-empty="true"
                                       selectLabel="Presione para seleccionar"
                                       selectedLabel="Seleccionado"
                                       deselectLabel="Presione para deseleccionar"
                          ></multiselect>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Año hasta:"
                                 :type="{ 'is-danger': verifyYears }"
                                 :message="{ 'Año hasta no puede ser menor al Año desde': verifyYears }">
                          <multiselect v-model="maxYear"
                                       :options="year_options"
                                       :searchable="true"
                                       :close-on-select="true"
                                       placeholder="Elija un año"
                                       :allow-empty="true"
                                       selectLabel="Presione para seleccionar"
                                       selectedLabel="Seleccionado"
                                       deselectLabel="Presione para deseleccionar"
                          ></multiselect>
                        </b-field>
                      </div>
                    </div>


                    <div class="box">
                      <div class="columns is-centered has-text-centered">
                        <b-field label="Unidades académicas:"/>
                      </div>


                      <div v-for="(grupo, index) in gruposElementos" :key="index" class="columns is-centered has-text-centered">
                        <div v-for="(item, itemIndex) in grupo" :key="itemIndex" class="column">
                          
                            <b-checkbox class="column" v-model="unidades" :native-value="item.id">
                                {{item.name}}
                            </b-checkbox>

                        </div>
                      </div> 
                    </div>


                    <div class="box"> 
                      <div class="columns is-centered has-text-centered">
                        <b-field label="Entidades EFUSACH:"/>
                      </div>
                      <div class="columns is-centered has-text-centered">
                        <b-checkbox class="column is-centered" v-for="item in entidades" :key="item.id" v-model="entidadesSel" :native-value="item.id">
                            {{item.initials}}
                        </b-checkbox>
                      </div>
                    </div>
                    <br>



                      
                    <div class="columns">
                      <div class="column has-text-right">
                        
                          <b-button class="button right-button is-info" icon-left="search"  @click="getFacultyActivity">
                            Aplicar filtros
                          </b-button>

                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
              

            </section>


            <section>
              <div class="box">
                <div class="container">
                  <div class="">
                    <div class="box">
                      <b-collapse
                        aria-id="contentIdForA11y2"
                        class="custom-collapse"
                        animation="slide"
                        v-model="isOpenGraficoBarrasEntidad">
                        <template #trigger="props">
                          <div
                              class="card-header"
                              role="button"
                              aria-controls="contentId3"
                              :aria-expanded="props.open">
                               <h2 class="card-header-title custom-header-title is-centered">                                    
                                Egresos totales por Unidad según entidad Efusach
                              </h2>
                              <a class="card-header-icon">
                                 <b-icon class="is-info" :icon="props.open ? 'sort-down' : 'sort-up'"></b-icon>
                              </a>
                          </div>
                        </template>
                        <div class="hero-body">
                          <div class="container">
                            <div ref="graficoBarrasEntidad" style="height: 30vw;"></div>
                          </div>
                        </div>
                      </b-collapse>
                    </div>
                    <div class="box">
                      <b-collapse
                        aria-id="contentIdForA11y2"
                        class="custom-collapse"
                        animation="slide"
                        v-model="isOpenGraficoTortaEntidad">
                        <template #trigger="props">
                          <div
                              class="card-header"
                              role="button"
                              aria-controls="contentId3"
                              :aria-expanded="props.open">
                               <h2 class="card-header-title custom-header-title is-centered">                                    
                                Cantidad de egresos por Unidad según Entidad Efusach
                              </h2>
                              <a class="card-header-icon">
                                 <b-icon class="is-info" :icon="props.open ? 'sort-down' : 'sort-up'"></b-icon>
                              </a>
                          </div>
                        </template>
                        <div class="hero-body">
                          <div class="container">
                            <div ref="graficoTortaEntidad" style="height: 35vw;"></div>
                          </div>
                        </div>
                      </b-collapse>
                    </div>
                    <!--
                    <div class="columns is-multiline">
                      <div class="column has-text-centered">
                        <div class="heading is-size-6">Transacciones de SDT</div>
                        <div class="title">{{ cant_total_sdt }}</div>
                      </div>
                      <div class="column has-text-centered">
                        <div class="heading is-size-6">Transacciones de CAP</div>
                        <div class="title">{{ cant_total_cap }}</div>
                      </div>
                      <div class="column has-text-centered">
                        <div class="heading is-size-6">Transacciones de FUDE</div>
                        <div class="title">{{ cant_total_fude }}</div>
                      </div>
                      <div class="column has-text-centered" v-if="cant_total_fueo>0">
                        <div class="heading is-size-6">Transacciones de FUEO</div>
                        <div class="title">{{ cant_total_fueo }}</div>
                      </div>
                    </div>
                    -->
                    <div class="box">
                      <b-collapse
                        aria-id="contentIdForA11y2"
                        class="custom-collapse"
                        animation="slide"
                        v-model="isOpenGraficoBarrasOrigenTipo">
                        <template #trigger="props">
                          <div
                              class="card-header"
                              role="button"
                              aria-controls="contentId3"
                              :aria-expanded="props.open">
                               <h2 class="card-header-title custom-header-title is-centered">                                    
                                Egresos totales por Unidad según Tipo de Transacción
                              </h2>
                              <a class="card-header-icon">
                                 <b-icon class="is-info" :icon="props.open ? 'sort-down' : 'sort-up'"></b-icon>
                              </a>
                          </div>
                        </template>
                        <div class="hero-body">
                          <div class="container">
                            <div ref="graficoBarrasOrigenTipo" style="height: 35vw;"></div>
                          </div>
                        </div>
                      </b-collapse>
                    </div>
                    <div class="box">

                      <b-collapse
                        aria-id="contentIdForA11y2"
                        class="custom-collapse"
                        animation="slide"
                        v-model="isOpenGraficoChartTipoBien">
                        <template #trigger="props">
                          <div
                              class="card-header"
                              role="button"
                              aria-controls="contentId3"
                              :aria-expanded="props.open">
                               <h2 class="card-header-title custom-header-title is-centered">                                    
                                Porcentaje de egresos por Unidad según Categoría de Bien adquirido
                              </h2>
                              <a class="card-header-icon">
                                 <b-icon class="is-info" :icon="props.open ? 'sort-down' : 'sort-up'"></b-icon>
                              </a>
                          </div>
                        </template>
                        <div class="hero-body">
                          <div class="container">
                            <div ref="graficoChartTipoBien" style="height: 35vw;"></div>
                          </div>
                        </div>
                      </b-collapse>
                    </div>
                    <div class="box">
                      <b-collapse
                        aria-id="contentIdForA11y2"
                        class="custom-collapse"
                        animation="slide"
                        v-model="isOpenGraficoChartTipoContratacion">
                        <template #trigger="props">
                          <div
                              class="card-header"
                              role="button"
                              aria-controls="contentId3"
                              :aria-expanded="props.open">
                               <h2 class="card-header-title custom-header-title is-centered">                                    
                                Porcentaje de egresos por Unidad según Categoría de Contratación adquirido
                              </h2>
                              <a class="card-header-icon">
                                 <b-icon class="is-info" :icon="props.open ? 'sort-down' : 'sort-up'"></b-icon>
                              </a>
                          </div>
                        </template>
                        <div class="hero-body">
                          <div class="container">
                            <div ref="graficoChartTipoContratacion" style="height: 35vw;"></div>
                          </div>
                        </div>
                      </b-collapse>
                    </div>
                    <div class="box">
                      <b-collapse
                        aria-id="contentIdForA11y2"
                        class="custom-collapse"
                        animation="slide"
                        v-model="isOpenGraficoChartTipoServicio">
                        <template #trigger="props">
                          <div
                              class="card-header"
                              role="button"
                              aria-controls="contentId3"
                              :aria-expanded="props.open">
                               <h2 class="card-header-title custom-header-title is-centered">                                    
                                Porcentaje de egresos por Unidad según Categoría de Servicio adquirido
                              </h2>
                              <a class="card-header-icon">
                                 <b-icon class="is-info" :icon="props.open ? 'sort-down' : 'sort-up'"></b-icon>
                              </a>
                          </div>
                        </template>
                        <div class="hero-body">
                          <div class="container">
                            <div ref="graficoChartTipoServicio" style="height: 35vw;"></div>
                          </div>
                        </div>
                      </b-collapse>
                    </div>
                    <div class="box">
                      <b-collapse
                        aria-id="contentIdForA11y2"
                        class="custom-collapse"
                        animation="slide"
                        v-model="isOpenGraficoTreemapEntidad">
                        <template #trigger="props">
                          <div
                              class="card-header"
                              role="button"
                              aria-controls="contentId3"
                              :aria-expanded="props.open">
                               <h2 class="card-header-title custom-header-title is-centered">                                    
                                Egresos totales por Unidad según entidad Efusach
                              </h2>
                              <a class="card-header-icon">
                                 <b-icon class="is-info" :icon="props.open ? 'sort-down' : 'sort-up'"></b-icon>
                              </a>
                          </div>
                        </template>
                        <div class="hero-body">
                          <div class="container">
                            <div ref="graficoTreemapEntidad" style="height: 35vw;"></div>
                          </div>
                        </div>
                      </b-collapse>
                    </div>
                    <div class="box">
                      <b-collapse
                        aria-id="contentIdForA11y2"
                        class="custom-collapse"
                        animation="slide"
                        v-model="isOpenGraficoTreemapTipo">
                        <template #trigger="props">
                          <div
                              class="card-header"
                              role="button"
                              aria-controls="contentId3"
                              :aria-expanded="props.open">
                               <h2 class="card-header-title custom-header-title is-centered">                                    
                                Egresos totales según categoría y tipo de transacción
                              </h2>
                              <a class="card-header-icon">
                                 <b-icon class="is-info" :icon="props.open ? 'sort-down' : 'sort-up'"></b-icon>
                              </a>
                          </div>
                        </template>
                        <div class="hero-body">
                          <div class="container">
                            <div ref="graficoTreemapTipo" style="height: 35vw;"></div>
                          </div>
                        </div>
                      </b-collapse>
                    </div>
                    <div class="box">
                      <b-collapse
                        aria-id="contentIdForA11y2"
                        class="custom-collapse"
                        animation="slide"
                        v-model="isOpenGraficoChartTipoDocumento">
                        <template #trigger="props">
                          <div
                              class="card-header"
                              role="button"
                              aria-controls="contentId3"
                              :aria-expanded="props.open">
                               <h2 class="card-header-title custom-header-title is-centered">                                    
                                Egresos totales por Unidad según formulario de transacción utilizado
                              </h2>
                              <a class="card-header-icon">
                                 <b-icon class="is-info" :icon="props.open ? 'sort-down' : 'sort-up'"></b-icon>
                              </a>
                          </div>
                        </template>
                        <div class="hero-body">
                          <div class="container">
                            <div ref="graficoChartTipoDocumento" style="height: 35vw;"></div>
                          </div>
                        </div>
                      </b-collapse>
                    </div>

                    <div class="box">
                      <b-collapse
                          aria-id="contentIdForA11y2"
                          class="custom-collapse"
                          animation="slide"
                          v-model="isOpenGraficoEfusach">
                        <template #trigger="props">
                          <div
                              class="card-header"
                              role="button"
                              aria-controls="contentId3"
                              :aria-expanded="props.open">
                            <h2 class="card-header-title custom-header-title is-centered">
                              Porcentaje de egresos según entidad Efusach
                            </h2>
                            <a class="card-header-icon">
                              <b-icon class="is-info" :icon="props.open ? 'sort-down' : 'sort-up'"></b-icon>
                            </a>
                          </div>
                        </template>
                        <div class="hero-body">
                          <div class="container">
                            <div ref="graficoChartEfusach" style="height: 35vw;"></div>
                          </div>
                        </div>
                      </b-collapse>
                    </div>

                  </div>
                </div>
              </div>
            </section>


            

            
          </b-tab-item>



          <b-tab-item
              label="Unidades académicas"
              icon="building"
          >


            <h3 class="title is-2 has-text-weight-medium has-text-centered">Resumen de Transacciones EFUSACH por Unidades académicas</h3>

              <div class="column" v-if=" departamentos.length>0">
                <b-field>
                <template #label>
                              Unidad
                              <b-tooltip
                                  label="Seleccionar la unidad académicos, desplegara la información de esta."
                                  position="is-right"
                                  size="is-medium"
                                  multilined>

                                <b-icon
                                    pack="fas"
                                    icon="info-circle"
                                    size="is-small"
                                    type="is-info">
                                </b-icon>
                              </b-tooltip>
                            </template> 
                    <multiselect v-model="unit"
                                 :options="departamentos.map(type => type.id)"
                                 :custom-label="opt => departamentos.find(x => x.id == opt).name"
                                 @input="updateFilter"
                                 :show-name="false"
                                 placeholder="Seleccione unidad"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                          No se encontraron elementos.
                      </span>
                    </multiselect>
                  
                </b-field>
              </div>

              <div v-if="unit!=null">
                <sdt-show-unit ref="sdtShowUnitRef" v-bind:unitId="this.unit" isFaculty="True" :cargoPrincipal="this.cargoPrincipal"></sdt-show-unit>
                
              </div>




          </b-tab-item>

          <b-tab-item
              label="Reportes"
              icon="chart-pie"
          >
            <section class="hero has-background-white-bis" style="box-shadow: 0px 6px 14px -4px rgba(196,196,196,1);">
              <b-collapse
                aria-id="contentIdForA11y2"
                class="custom-collapse"
                animation="slide"
                v-model="isOpen">
                <template #trigger="props">
                  <div
                      class="card-header"
                      role="button"
                      aria-controls="contentId3"
                      :aria-expanded="props.open">
                       <h2 class="card-header-title custom-header-title is-centered">
                        <b-icon type="is-info" class="is-pulled-left" icon="info-circle"></b-icon>
                        Filtros Aplicados
                      </h2>
                      <a class="card-header-icon">
                         <b-icon class="is-info" :icon="props.open ? 'sort-down' : 'sort-up'"></b-icon>
                      </a>
                  </div>
                </template>
                <div class="hero-body">
                  <div class="container">
                  
                    <div class="columns">
                      <div class="column">
                        <b-field label="Año desde:">
                          <multiselect v-model="minYear"
                                       :options="year_options"
                                       :searchable="true"
                                       :close-on-select="true"
                                       placeholder="Elija un año"
                                       :allow-empty="true"
                                       selectLabel="Presione para seleccionar"
                                       selectedLabel="Seleccionado"
                                       deselectLabel="Presione para deseleccionar"
                          ></multiselect>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Año hasta:"
                                 :type="{ 'is-danger': verifyYears }"
                                 :message="{ 'Año hasta no puede ser menor al Año desde': verifyYears }">
                          <multiselect v-model="maxYear"
                                       :options="year_options"
                                       :searchable="true"
                                       :close-on-select="true"
                                       placeholder="Elija un año"
                                       :allow-empty="true"
                                       selectLabel="Presione para seleccionar"
                                       selectedLabel="Seleccionado"
                                       deselectLabel="Presione para deseleccionar"
                          ></multiselect>
                        </b-field>
                      </div>
                    </div>


                    <div class="box">
                      <div class="columns is-centered has-text-centered">
                        <b-field label="Unidades académicas:"/>
                      </div>


                      <div v-for="(grupo, index) in gruposElementos" :key="index" class="columns is-centered has-text-centered">
                        <div v-for="(item, itemIndex) in grupo" :key="itemIndex" class="column">
                          
                            <b-checkbox class="column" v-model="unidades" :native-value="item.id">
                                {{item.name}}
                            </b-checkbox>

                        </div>
                      </div> 
                    </div>


                    <div class="box"> 
                      <div class="columns is-centered has-text-centered">
                        <b-field label="Entidades EFUSACH:"/>
                      </div>
                      <div class="columns is-centered has-text-centered">
                        <b-checkbox class="column is-centered" v-for="item in entidades" :key="item.id" v-model="entidadesSel" :native-value="item.id">
                            {{item.initials}}
                        </b-checkbox>
                      </div>
                    </div>
                    <br>
                  </div>
                </div>
              </b-collapse>
              

            </section>
            <br>




              <b-table
              :data="graficos"
              :paginated=true
              per-page=10
              striped
              hoverable
              :current-page="currentPage"
              :pagination-simple=false
              pagination-position="bottom"
              default-sort-direction="desc"
              sort-icon="arrow-up"
              sort-icon-size="is-small"
              aria-next-label="Siguiente"
              aria-previous-label="Anterior"
              aria-page-label="Página"
              aria-current-label="Página actual">

                <b-table-column
                  field= 'name'
                  label= 'Reporte'
                  width="25em"
                  searchable
                  sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.name }}
                  </template>
                </b-table-column>

                <b-table-column
                    label="Acciones"
                    width="10em"
                    v-slot="props">
                  <b-button 
                              class="is-excel-color"
                                icon-left="file-excel"
                                @click="openModal(props.row.content)">
                                Exportar
                      </b-button>
                  <!--<b-icon
                      pack="fas"
                      icon="trash"
                      size="is-medium"
                      type="is-danger"
                      @click.native="execNullifyModal(props.row.id, props.row)"/>
                    -->
                </b-table-column>

                <template slot="empty" slot-scope="props">
                  <div class="columns is-centered">
                    <div class="column is-6 is-centered has-text-centered">
                      <b-message type="is-danger">
                        No se encontraron departamentos acádemicos.
                      </b-message>
                    </div>
                  </div>
                </template>
              </b-table>
          </b-tab-item>



        </b-tabs>
      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>


<script>
  import SdtShowUnit from "./ReportingUnit.vue";
  import axios from "axios";
  import * as echarts from 'echarts';

  export default {
    components:{
    SdtShowUnit
    },
    props:[
        'unitId', 'cargoPrincipal'
    ],
    data(){
      return{
        type: "is-boxed",
        size: "is-small",
        isLoading:false,
        departamentos: [],
        entidades: [],
        entidadesSel: [],
        unidades: [],
        nom_deptos: [],
        montos_transactiones_sdt: [],
        montos_transactiones_cap: [],
        montos_transactiones_fude: [],
        montos_transactiones_fueo: [],
        montos_tipo_bien_uni: [],
        montos_tipo_contr_uni: [],
        montos_tipo_serv_uni: [],
        montos_tipo_docs_uni: [],
        cants_totales_sdt: [],
        cants_totales_cap: [],
        cants_totales_fude: [],
        cants_totales_fueo: [],
        nombresTipoBien: [],
        nombresTipoServicio: [],
        nombresTipoContratacion: [],
        montosTipoBien:[],
        montosTipoContratacion: [],
        montosTipoServicio: [],
        nombresTipoDocumentos: [],
        cant_total_sdt: 0,
        cant_total_cap: 0,
        cant_total_fude: 0,
        cant_total_fueo: 0,
        montos_bien: [],
        montos_contratacion: [],
        montos_servicio: [],
        currentPage:1,
        unit: null,
        canCancel: ['escape'],
        minYear:new Date().getFullYear() - 5,
        maxYear:new Date().getFullYear(),
        minYearResum:new Date().getFullYear() - 5,
        maxYearResum:new Date().getFullYear(),
        verifyYears:false,
        activacion: false,
        year_options:Array.from({length:(new Date().getFullYear()-2000+1)},(v,k)=>k+2000).reverse(),
        graficoBarrasEntidad: null,
        graficoTortaEntidad: null,
        graficoBarrasOrigenTipo: null,
        graficoTreemapEntidad: null,
        graficoChartTipoBien: null,
        graficoChartTipoContratacion: null,
        graficoChartTipoServicio: null,
        graficoTreemapTipo: null,
        graficoChartTipoDocumento: null,
        graficoChartEfusach: null,
        isOpen: true,
        isOpenGraficoBarrasEntidad: true,
        isOpenGraficoTortaEntidad: true,
        isOpenGraficoBarrasOrigenTipo: true,
        isOpenGraficoTreemapEntidad: true,
        isOpenGraficoChartTipoBien: true,
        isOpenGraficoChartTipoContratacion: true,
        isOpenGraficoChartTipoServicio: true,
        isOpenGraficoTreemapTipo: true,
        isOpenGraficoChartTipoDocumento: true,
        isOpenGraficoEfusach: true,
        colores: [ '#16a085','#e74c3c', '#f39c12','#2c3e50', '#9b59b6', '#e67e22', '#3498db', '#d35400','#c0392b'],
        graficos:[ {name: "Reporte de Facultad", content: "All" },
          ]
      }
    },
    created(){
      let vm = this;
      vm.isLoading=true;
      axios.get("/sdt_surpluses/form-data.json")
      .then(res => {
        vm.departamentos = res.data.unidades;
        vm.unidades = res.data.unidades.map(elemento => elemento.id);
        vm.entidades = res.data.entidades;
        vm.entidadesSel = res.data.entidades.map(elemento => elemento.id);
        vm.isLoading=false;
        vm.getFacultyActivity();
        vm.isOpenGraficoBarrasEntidad=false;
        vm.isOpenGraficoTortaEntidad=false;
        vm.isOpenGraficoBarrasOrigenTipo=false;
        vm.isOpenGraficoTreemapEntidad=false;
        vm.isOpenGraficoChartTipoBien=false;
        vm.isOpenGraficoChartTipoContratacion=false;
        vm.isOpenGraficoChartTipoServicio=false;
        vm.isOpenGraficoTreemapTipo=false;
        vm.isOpenGraficoChartTipoDocumento=false;
        vm.isOpenGraficoEfusach=false;
      })
      .catch(e => {
        vm.isLoading=false;
        console.log(e);
      });
    },
    mounted() {
      this.inicializarGrafico();
      this.unit = this.unitId;
    },
    
    methods:{
      async openModal(grafico){
        this.isLoading=true;
        if(grafico=="All")
        {
          await this.importXlsxAll()
        }

      },

      importXlsxAll(){
      let vm = this;
      vm.isLoading=true;
      axios
          .post("/sdt_transactions/report/document.xlsx",
               {
                  minYear: vm.minYear,
                  maxYear: vm.maxYear,
                  entidades: JSON.stringify(vm.entidadesSel),
                  unidades: JSON.stringify(vm.unidades)
              },
              {
                responseType: "blob"
              })
          .then(response =>{


            const fileName = 'Reporte_Transacciones_'+vm.minYear+'_'+vm.maxYear+'_'+new Date().toLocaleDateString() +'.xlsx';
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            vm.isLoading=false;;
          }).catch(error => {
            vm.isLoading=false;
            vm.$buefy.notification.open({
              message: 'Hubo un error en la solicitud',
              type: 'is-danger'
            })
          })
      },

      inicializarGrafico() {
        this.graficoBarrasEntidad = echarts.init(this.$refs.graficoBarrasEntidad);
        this.graficoTortaEntidad = echarts.init(this.$refs.graficoTortaEntidad);
        this.graficoBarrasOrigenTipo = echarts.init(this.$refs.graficoBarrasOrigenTipo);
        this.graficoTreemapEntidad = echarts.init(this.$refs.graficoTreemapEntidad);
        this.graficoChartTipoBien = echarts.init(this.$refs.graficoChartTipoBien);
        this.graficoChartTipoContratacion = echarts.init(this.$refs.graficoChartTipoContratacion);
        this.graficoChartTipoServicio = echarts.init(this.$refs.graficoChartTipoServicio);
        this.graficoTreemapTipo = echarts.init(this.$refs.graficoTreemapTipo);
        this.graficoChartTipoDocumento = echarts.init(this.$refs.graficoChartTipoDocumento);
        this.graficoChartEfusach = echarts.init(this.$refs.graficoChartEfusach);
      },



      actualizarGraficoTipoBien() {

        var datosOrdenados = this.nombresTipoBien.map((nombre, index) => ({
          nombre: nombre,
          monto: this.montosTipoBien[index]
        }))
        datosOrdenados.sort((a,b) => a.monto - b.monto);

        var nombres = datosOrdenados.map(dato => dato.nombre);
        var valores = datosOrdenados.map(dato => dato.monto);
        
        var total = valores.reduce((acumulador, numero) => acumulador + numero, 0);
        var porcentajes = valores.map( monto => (monto/total)*100);
    
        var option = {
            
            color: this.colores,
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: function(params) {
                    var dataIndex = params[0].dataIndex;
                    var monto = (valores[dataIndex] / 1000000).toFixed(2); 
                    var porcentaje = porcentajes[dataIndex];
                    return `Monto: $ ${monto} Mill. <br>Porcentaje: ${porcentaje.toFixed(2)} %`;
                },
            },
            toolbox: {
                feature: {
                    itemSize:20,
                    saveAsImage:{
                        pixelRatio:3,
                        title:"Descargar",
                        name:"Porcentaje de egresos por Unidad según tipo de Bien adquirido",
                    },
                }
            },
            grid: {
                left: '3%',
                right: '25%',
                bottom: '3%',
                containLabel: true,
            },
            yAxis: {
                 type: 'category',
                 data: nombres,
                 
            },
            xAxis: {
              type: "value",
            },
            series: [{
              type: "bar",
              data: porcentajes,
              
            }]

        };
        
        this.graficoChartTipoBien.setOption(option);
               
      },


      actualizarGraficoEfusach() {
        var montoSdt = this.montos_transactiones_sdt.reduce((total, num) => total + num, 0);
        var montoCap = this.montos_transactiones_cap.reduce((total, num) => total + num, 0);
        var montoFude = this.montos_transactiones_fude.reduce((total, num) => total + num, 0);
        var montoFueo = this.montos_transactiones_fueo.reduce((total, num) => total + num, 0);

        const option = {
          tooltip: {
            trigger: 'item'
          },
          color: this.colores,
          legend: {
            orient: 'vertical',
            right: '0%',
            top: 'center',
            formatter: function (name) {
              const maxCharactersPerLine = 35;
              const lines = [];
              for (let i = 0; i < name.length; i += maxCharactersPerLine) {
                lines.push(name.substr(i, maxCharactersPerLine));
              }
              return lines.join('\n');
            },
          },
          series: [
            {
              name: '',
              type: 'pie',
              avoidLabelOverlap: false,
              tooltip: {
                trigger: 'item',
                formatter: function(params) {
                  const value = Number(params.value).toLocaleString('es-ES');
                  const porcentaje = params.percent + '%';
                  return `Entidad: ${params.name} <br/>Suma de montos: $${value} (${porcentaje})`;
                }
              },
              label: {
                formatter: function(params) {
                  const valueInMillions = (params.value / 1000000).toFixed(2);
                  const porcentaje = params.percent + '%';
                  return `$ ${valueInMillions} Mill. (${porcentaje})`;
                }
              },
              labelLine: {
                show: true
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 20,
                  fontWeight: 'bold'
                }
              },
              data: [
                { value: montoSdt, name: 'SDT' },
                { value: montoCap, name: 'CAP' },
                { value: montoFude, name: 'FUDE' },
                { value: montoFueo, name: 'FUEO' },
              ]
            }
          ]
        };
        this.graficoChartEfusach.setOption(option);
               
      },


      actualizarGraficoTipoContratacion() {
        var datosOrdenados = this.nombresTipoContratacion.map((nombre, index) => ({
          nombre: nombre,
          monto: this.montosTipoContratacion[index]
        }))
        datosOrdenados.sort((a,b) => a.monto - b.monto);

        var nombres = datosOrdenados.map(dato => dato.nombre);
        var valores = datosOrdenados.map(dato => dato.monto);
        
        var total = valores.reduce((acumulador, numero) => acumulador + numero, 0);
        var porcentajes = valores.map( monto => (monto/total)*100);
    
        var option = {
            
            color: this.colores,
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: function(params) {
                    var dataIndex = params[0].dataIndex;
                    var monto = (valores[dataIndex] / 1000000).toFixed(2); 
                    var porcentaje = porcentajes[dataIndex];
                    return `Monto: $ ${monto} Mill. <br>Porcentaje: ${porcentaje.toFixed(2)} %`;
                },
            },
            toolbox: {
                feature: {
                    itemSize:20,
                    saveAsImage:{
                        pixelRatio:3,
                        title:"Descargar",
                        name:"Porcentaje de egresos por Unidad según tipo de Contratación adquirido",
                    },
                }
            },
            grid: {
                left: '3%',
                right: '25%',
                bottom: '3%',
                containLabel: true,
            },
            yAxis: {
                 type: 'category',
                 data: nombres,
                 
            },
            xAxis: {
              type: "value",
            },
            series: [{
              type: "bar",
              data: porcentajes,
              
            }]

        };
        
        this.graficoChartTipoContratacion.setOption(option);             
    },

    actualizarGraficoTipoServicio() {
      var datosOrdenados = this.nombresTipoServicio.map((nombre, index) => ({
          nombre: nombre,
          monto: this.montosTipoServicio[index]
        }))
        datosOrdenados.sort((a,b) => a.monto - b.monto);

        var nombres = datosOrdenados.map(dato => dato.nombre);
        var valores = datosOrdenados.map(dato => dato.monto);
        
        var total = valores.reduce((acumulador, numero) => acumulador + numero, 0);
        var porcentajes = valores.map( monto => (monto/total)*100);
    
        var option = {
            
            color: this.colores,
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: function(params) {
                    var dataIndex = params[0].dataIndex;
                    var monto = (valores[dataIndex] / 1000000).toFixed(2); 
                    var porcentaje = porcentajes[dataIndex];
                    return `Monto: $ ${monto} Mill. <br>Porcentaje: ${porcentaje.toFixed(2)} %`;
                },
            },
            toolbox: {
                feature: {
                    itemSize:20,
                    saveAsImage:{
                        pixelRatio:3,
                        title:"Descargar",
                        name:"Porcentaje de egresos por Unidad según tipo de Servicio adquirido",
                    },
                }
            },
            grid: {
                left: '3%',
                right: '25%',
                bottom: '3%',
                containLabel: true,
            },
            yAxis: {
                 type: 'category',
                 data: nombres,
                 
            },
            xAxis: {
              type: "value",
            },
            series: [{
              type: "bar",
              data: porcentajes,
              
            }]

        };
        
        this.graficoChartTipoServicio.setOption(option);
             
    },



    actualizarGraficoTipoDocumento() {
      var nombre_unidades = this.nom_deptos;
      var sumas = this.montos_tipo_docs_uni.reduce((acum, fila) => {
        fila.forEach((valor,index) => {
          acum[index] = (acum[index] || 0) + valor;
        });
        return acum
      },[]);

      var option = {
          
          color: this.colores,
          tooltip: {
              trigger: 'axis',
              axisPointer: {
                  type: 'shadow'
              },
              formatter: function(params) {
                  var unitIndex = params[0].dataIndex;
                  var totalValue = sumas[unitIndex];

                  var content = `${nombre_unidades[unitIndex]}<br />`;
                  content += `Total: $${Number(totalValue).toLocaleString('es-ES')}<br />`;

                  params.forEach(function(param) {
                      content += `${param.seriesName}: $${Number(param.value).toLocaleString('es-ES')}<br />`;
                  });

                  return content;
              },
          },
          toolbox: {
              feature: {
                  itemSize:20,
                  saveAsImage:{
                      pixelRatio:3,
                      title:"Descargar",
                      name:"Tipos documentos ",
                  },
              }
          },

          legend: {
            orient: 'vertical',
            right: '0%',
            top: 'center', 
            formatter: function (name) {
              const maxCharactersPerLine = 16;
              const lines = [];
              for (let i = 0; i < name.length; i += maxCharactersPerLine) {
                lines.push(name.substr(i, maxCharactersPerLine));
              }
              return lines.join('\n');
            },
          },
          grid: {
              left: '3%',
              right: '20%',
              bottom: '3%',
              containLabel: true,
          },
          yAxis: {
               type: 'value',                   
          },
          xAxis: {
            type: "category",
            data: nombre_unidades,
            axisLabel:{
              rotate: 45
            }
          },
          series: this.nombresTipoDocumentos.map((tipo,index) => ({
            name: tipo,
            type: 'bar',
            stack: 'stack',
            data: this.montos_tipo_docs_uni[index],

          }))
          ,

      };
      this.graficoChartTipoDocumento.setOption(option);
             
    },


      actualizarGraficoTortaEntidad() {

        var nombre_unidades = this.nom_deptos;
        var valores_sdt = this.cants_totales_sdt;
        var valores_cap = this.cants_totales_cap;
        var valores_fude = this.cants_totales_fude;
        var valores_fueo = this.cants_totales_fueo;
        var sumas = nombre_unidades.map((nombre, index) => {
            return valores_sdt[index] + valores_cap[index] + valores_fude[index] + valores_fueo[index];
        });
        var option = {
            
            color: this.colores,
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: function(params) {
                    var unitIndex = params[0].dataIndex;
                    var totalValue = sumas[unitIndex];

                    var content = `${nombre_unidades[unitIndex]}<br />`;
                    content += `Total:$ ${Number(totalValue).toLocaleString('es-ES')}<br />`;

                    params.forEach(function(param) {
                        content += `${param.seriesName}: $${Number(param.value).toLocaleString('es-ES')}<br />`;
                    });

                    return content;
                },
            },
            toolbox: {
                feature: {
                    magicType: {
                        type: ['stack']
                    },
                    itemSize:20,
                    saveAsImage:{
                        pixelRatio:3,
                        title:"Descargar",
                        name:"Cantidad de egresos por Unidad según entidad EFUSACH",
                    },
                }
            },

            legend: {
                orient: 'vertical',
                top: 'middle',
                right: '10',
            },
            grid: {
                left: '3%',
                right: '20%',
                bottom: '3%',
                containLabel: true,
            },
            yAxis: {
                 type: 'value',                   
            },
            xAxis: {
              type: "category",
              data: nombre_unidades,
              axisLabel:{
                rotate: 45
              }
            },
            series: [
                {
                    name: "SDT",
                    type: 'bar',
                    data: valores_sdt,
                    stack: 'one',
                    emphasis: {
                        focus: 'series'
                    }
                },
                {
                    name: "CAP",
                    type: 'bar',
                    data: valores_cap,
                    stack: 'two',
                    emphasis: {
                        focus: 'series'
                    }
                },
                {
                    name: "FUDE",
                    type: 'bar',
                    data: valores_fude,
                    stack: 'three',
                    emphasis: {
                        focus: 'series'
                    }
                },
                {
                    name: "FUEO",
                    type: 'bar',
                    data: valores_fueo,
                    stack: 'four',
                    emphasis: {
                        focus: 'series'
                    }
                },
            ],

        };
        this.graficoTortaEntidad.setOption(option);
               
      },
      actualizarGraficoBarrasEntidad(){
        var nombre_unidades = this.nom_deptos;
        var valores_sdt = this.montos_transactiones_sdt;
        var valores_cap = this.montos_transactiones_cap;
        var valores_fude = this.montos_transactiones_fude;
        var valores_fueo = this.montos_transactiones_fueo;
        var sumas = nombre_unidades.map((nombre, index) => {
            return valores_sdt[index] + valores_cap[index] + valores_fude[index] + valores_fueo[index];
        });
        var option = {

            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: function(params) {
                    var unitIndex = params[0].dataIndex;
                    var totalValue = sumas[unitIndex];


                    var content = `${nombre_unidades[unitIndex]}<br />`;
                    content += `Total: $${Number(totalValue).toLocaleString('es-ES')}<br />`;

                    params.forEach(function(param) {
                        content += `${param.seriesName}: $${Number(param.value).toLocaleString('es-ES')}<br />`;
                    });

                    return content;
                },
            },
            toolbox: {
                feature: {
                    magicType: {
                        type: ['stack']
                    },
                    itemSize:17,
                    saveAsImage:{
                        pixelRatio:3,
                        title:"Descargar",
                        name:"Egresos totales por Unidad según entidad Efusach",
                    },
                }
            },

            legend: {
                orient: 'vertical',
                top: 'middle',
                right: '10',
            },
            color: this.colores,
            grid: {
                left: '3%',
                right: '20%',
                bottom: '3%',
                containLabel: true,
            },
            yAxis: {
              type: 'value',                 
            },
            xAxis: {  
              type: "category",
              data: nombre_unidades,
              axisLabel:{
                rotate: 45
              }
            },
            series: [
                {
                    name: "SDT",
                    type: 'bar',
                    data: valores_sdt,
                    stack: 'one',
                    
                    emphasis: {
                        focus: 'series'
                    }
                },
                {
                    name: "CAP",
                    type: 'bar',
                    data: valores_cap,
                    stack: 'two',
                    
                    emphasis: {
                        focus: 'series'
                    }
                },
                {
                    name: "FUDE",
                    type: 'bar',
                    data: valores_fude,
                    stack: 'three',
                    
                    emphasis: {
                        focus: 'series'
                    }
                },
                {
                    name: "FUEO",
                    type: 'bar',
                    data: valores_fueo,
                    stack: 'four',
                    
                    emphasis: {
                        focus: 'series'
                    }
                },
            ],

        };
        this.graficoBarrasEntidad.setOption(option);

      },

      actualizarGraficoBarrasOrigenTipo(){
        var nombre_unidades = this.nom_deptos;
        var valores_bien = this.montos_bien;
        var valores_cont = this.montos_contratacion;
        var valores_serv = this.montos_servicio;
        var sumas = nombre_unidades.map((nombre, index) => {
            return valores_bien[index] + valores_cont[index] + valores_serv[index];
        });
        var option = {
            
            color: this.colores,
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: function(params) {
                    var unitIndex = params[0].dataIndex;
                    var totalValue = sumas[unitIndex];

                    var content = `${nombre_unidades[unitIndex]}<br />`;
                    content += `Total: $${Number(totalValue).toLocaleString('es-ES')}<br />`;

                    params.forEach(function(param) {
                        content += `${param.seriesName}: $${Number(param.value).toLocaleString('es-ES')}<br />`;
                    });

                    return content;
                },
            },
            toolbox: {
                feature: {
                    magicType: {
                        type: ['stack']
                    },
                    itemSize:20,
                    saveAsImage:{
                        pixelRatio:3,
                        title:"Descargar",
                        name:"Egresos totales por Unidad según categoría de Transacción",
                    },
                }
            },

            legend: {
                orient: 'vertical',
                top: 'middle',
                right: '10',
            },
            grid: {
                left: '3%',
                right: '20%',
                bottom: '3%',
                containLabel: true,
            },
            yAxis: {
                 type: 'value',                   
            },
            xAxis: {
                
                type: "category",
                data: nombre_unidades,
                axisLabel:{
                  rotate: 45
                }
            },
            series: [
                {
                    name: "Bien",
                    type: 'bar',
                    data: valores_bien,
                    stack: 'one',
                    emphasis: {
                        focus: 'series'
                    }
                },
                {
                    name: "Contratación",
                    type: 'bar',
                    data: valores_cont,
                    stack: 'two',
                    emphasis: {
                        focus: 'series'
                    }
                },
                {
                    name: "Servicios",
                    type: 'bar',
                    data: valores_serv,
                    stack: 'three',
                    emphasis: {
                        focus: 'series'
                    }
                },
            ],

        };
        this.graficoBarrasOrigenTipo.setOption(option);

      },

      getLevelOption() {
        return [
          {
            itemStyle: {
              borderColor: '#777',
              borderWidth: 0,
              gapWidth: 1
            },
            upperLabel: {
              show: false
            }
          },
          {
            itemStyle: {
              borderColor: '#555',
              borderWidth: 5,
              gapWidth: 1
            },
            emphasis: {
              itemStyle: {
                borderColor: '#ddd'
              }
            }
          },
          {
            colorSaturation: [0.35, 0.5],
            itemStyle: {
              borderWidth: 5,
              gapWidth: 1,
              borderColorSaturation: 0.6
            }
          }
        ];
      },


      actualizarGraficoMontosDeptTremap(){

        var nombre_unidades = this.nom_deptos;
        var valores_sdt = this.montos_transactiones_sdt;
        var valores_cap = this.montos_transactiones_cap;
        var valores_fude = this.montos_transactiones_fude;
        var valores_fueo = this.montos_transactiones_fueo;

        const treemapData = nombre_unidades.map((nombre, index) => ({
          name: nombre,
          value: valores_sdt[index]+valores_cap[index]+valores_fude[index]+valores_fueo[index],
          children:
            [
              {name: "SDT", value:valores_sdt[index]},
              {name: "CAP", value:valores_cap[index]},
              {name: "FUDE", value:valores_fude[index]},
              {name: "FUEO", value:valores_fueo[index]},
            ]


        }));


        var option = {
            color: this.colores,
            tooltip: {
              formatter: function (info) {
                var value = info.value;
                var treePathInfo = info.treePathInfo;
                var treePath = [];
                for (var i = 1; i < treePathInfo.length; i++) {
                  treePath.push(treePathInfo[i].name);
                }
                return [
                  '<div class="tooltip-title">' +
                    echarts.format.encodeHTML(treePath.join('/')) +
                    '</div>',
                  'Monto gastado: $' + Number(value).toLocaleString('es-ES') 
                ].join('');
              }
            },
            series: [
              {
                type: 'treemap',
                data: treemapData,
                label: {
                  show: true,
                  formatter: function(params) {
                    const formattedValue = Number(params.value).toLocaleString('es-ES');
                    return `${params.name}: $${formattedValue}`;
                  },
                  fontSize: 16,
                  fontWeight: 'bold',
                },
                upperLabel: {
                  show: true,
                  formatter: function (params) {
                    const formattedValue = Number(params.value).toLocaleString('es-ES');
                    return `{a|${params.name}}\n${formattedValue}`;
                  },
                  offset: [0, 5],
                  rich: {
                    a: {
                      fontWeight: 'bold',
                      color: '#fff',
                    },
                  },
                },
                itemStyle: {
                  borderColor: '#fff'
                },
                levels: this.getLevelOption(),
              },
            ],

        };


        this.graficoTreemapEntidad.setOption(option);

      },



      actualizarGraficoMontosTipoTremap(){

        const treemapData = [
        {
          name: "BIEN",
          value: this.montosTipoBien.reduce((acumulador, numero) => acumulador + numero, 0),
          children: this.montosTipoBien.map((monto, index) => ({
            name: this.nombresTipoBien[index],
            value: monto
            }))
        },

        {
          name: "CONTRATACIÓN",
          value: this.montosTipoContratacion.reduce((acumulador, numero) => acumulador + numero, 0),
          children: this.montosTipoContratacion.map((monto, index) => ({
            name: this.nombresTipoContratacion[index],
            value: monto
            }))
        },

        {
          name: "SERVICIO",
          value: this.montosTipoServicio.reduce((acumulador, numero) => acumulador + numero, 0),
          children: this.montosTipoServicio.map((monto, index) => ({
            name: this.nombresTipoServicio[index],
            value: monto
            }))
        },
        ]


        var option = {
            
            color: this.colores,
            tooltip: {
              formatter: function (info) {
                var value = info.value;
                var treePathInfo = info.treePathInfo;
                var treePath = [];
                for (var i = 1; i < treePathInfo.length; i++) {
                  treePath.push(treePathInfo[i].name);
                }
                return [
                  '<div class="tooltip-title">' +
                    echarts.format.encodeHTML(treePath.join('/')) +
                    '</div>',
                  'Monto: $' + Number(value).toLocaleString('es-ES') 
                ].join('');
              }
            },
            series: [
              {
                type: 'treemap',
                data: treemapData,
                label: {
                  show: true,
                  formatter: function(params) {
                    const formattedValue = Number(params.value).toLocaleString('es-ES');
                    return `${params.name}: $${formattedValue}`;
                  },
                  fontSize: 16,
                  fontWeight: 'bold',
                },
                upperLabel: {
                  show: true,
                  formatter: function (params) {
                    const formattedValue = Number(params.value).toLocaleString('es-ES');
                    return `{a|${params.name}}\n${formattedValue}`;
                  },
                  offset: [0, 5],
                  rich: {
                    a: {
                      fontWeight: 'bold',
                      color: '#fff',
                    },
                  },
                },
                itemStyle: {
                  borderColor: '#fff'
                },
                levels: this.getLevelOption(),
              },
            ],

        };


        this.graficoTreemapTipo.setOption(option);

      },

      updateFilter() {
        this.$refs.sdtShowUnitRef.applyFilter();
      },
      changeTab: function(value){
        this.currentTab = value
      },

      getFacultyActivity(){
        let vm = this;
        vm.isLoading=true;
        axios
          .get("/sdt_transactions/reporting-faculty.json",
              {params: {
                  minYear: vm.minYear,
                  maxYear: vm.maxYear,
                  entidades: JSON.stringify(vm.entidadesSel),
                  unidades: JSON.stringify(vm.unidades)

                }
              })
          .then(res => {
            vm.nom_deptos = res.data.nom_deptos;

            vm.montos_transactiones_sdt = res.data.montos_transactiones_sdt;
            vm.montos_transactiones_cap = res.data.montos_transactiones_cap;
            vm.montos_transactiones_fude = res.data.montos_transactiones_fude;
            vm.montos_transactiones_fueo = res.data.montos_transactiones_fueo;

            vm.montos_tipo_bien_uni = res.data.montos_tipo_bien_uni;
            vm.montos_tipo_contr_uni = res.data.montos_tipo_contr_uni;
            vm.montos_tipo_serv_uni = res.data.montos_tipo_serv_uni;
            vm.montos_tipo_docs_uni = res.data.montos_tipo_docs_uni;
            vm.nombresTipoDocumentos = res.data.nom_tipo_docs;

            vm.cants_totales_sdt = res.data.cants_totales_sdt;
            vm.cants_totales_cap = res.data.cants_totales_cap;
            vm.cants_totales_fude = res.data.cants_totales_fude;
            vm.cants_totales_fueo = res.data.cants_totales_fueo;

            vm.cant_total_sdt = res.data.cant_total_sdt;
            vm.cant_total_cap = res.data.cant_total_cap;
            vm.cant_total_fude = res.data.cant_total_fude;
            vm.cant_total_fueo = res.data.cant_total_fueo;

            vm.montos_bien = res.data.montos_bien;
            vm.montos_contratacion = res.data.montos_contratacion;
            vm.montos_servicio = res.data.montos_servicio;


            vm.nombresTipoBien= res.data.nom_tipo_bien;
            vm.nombresTipoServicio= res.data.nom_tipo_serv;;
            vm.nombresTipoContratacion= res.data.nom_tipo_contr;
            vm.montosTipoBien= res.data.monto_tipo_bien;
            vm.montosTipoServicio= res.data.monto_tipo_serv;
            vm.montosTipoContratacion= res.data.monto_tipo_contr;


            vm.actualizarGraficoBarrasEntidad();
            vm.actualizarGraficoTortaEntidad();
            vm.actualizarGraficoBarrasOrigenTipo();
            vm.actualizarGraficoMontosDeptTremap();
            vm.actualizarGraficoTipoBien();
            vm.actualizarGraficoTipoContratacion();
            vm.actualizarGraficoTipoServicio();
            vm.actualizarGraficoMontosTipoTremap();
            vm.actualizarGraficoTipoDocumento();
            vm.actualizarGraficoEfusach();
            vm.isLoading=false;
            //console.log(res)
          })
          .catch(e => {
            console.log(e);
            vm.isLoading=false;
          });
      },
    },

    computed:{
      gruposElementos() {
        // Agrupa los elementos en conjuntos de 3
        const grupos = [];
        for (let i = 0; i < this.departamentos.length; i += 2) {
          grupos.push(this.departamentos.slice(i, i + 2));
        }
        return grupos;
      },

    }
  }
</script>
<style scoped>
  .right-button{
    margin-left: auto;
  }
  .custom-collapse {
    background-color: #F9F9F9 !important;
    border: 1px solid #000000;
  }
  .custom-header-title {
    font-size: 24px; 
    font-weight: bold; 
    text-align: center !important; 
    margin: 0; 
  }
</style>