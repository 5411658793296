<template>
  <section>
    <div class="container">
      <!--DATA TABLE-->
      <b-table
          :data="distanced_household_allowances"
          :paginated= true
          per-page="10"
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="product.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field="decree_date"
            label="Fecha Resolución"
            width="110"
            sortable
            searchable :custom-search="searchDecreeDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.decree_date._isValid ? new Date(props.row.decree_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            field="decree"
            label="Resolución"
            numeric
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.decree}}
          </template>
        </b-table-column>
        <b-table-column
            field="relationship"
            label="Parentesco"
            numeric
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.relationship}}
          </template>
        </b-table-column>
        <b-table-column
            field="dependent_type.name"
            label="Tipo de Carga"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.dependent_type.name}}
          </template>
        </b-table-column>

        <b-table-column
            field="rut"
            label="Rut"
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.rut}}
          </template>
        </b-table-column>
        <b-table-column
            field="nombre_completo"
            label="Nombres"
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.nombre_completo}}
          </template>
        </b-table-column>
        <b-table-column
            field="start_date"
            label="Fecha cese de asignación"
            sortable
            searchable :custom-search="searchStartDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL"):''}}
          </template>
        </b-table-column>
        <b-table-column
            label="Acciones"
            width="125"
            v-slot="props">

          <b-tooltip
              v-if="props.row.can_show"
              label="Ver información completa del cese de la asignación familiar."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="eye"
                type="is-primary"
                @click="execShowModal(props.row.id)"/>
          </b-tooltip>


          <b-tooltip
              v-if="props.row.can_edit"
              label="Editar información del cese de la asignación familiar."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="edit"
                type="is-info is-light is-outlined"
                @click="execEditModal(props.row)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_view_pdf"
              label="Visualizar documento asociado al cese de la asignación familiar."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="file-alt"
                type="is-success is-light is-outlined"
                @click="viewFile(props.row.document_id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_view_pdf"
              label="Descargar documento asociado al cese de la asignación familiar."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="download"
                type="is-primary is-light is-outlined"
                @click="downloadFile(props.row.document_id)"/>
          </b-tooltip>
        </b-table-column>
      </b-table>
      <!--FIN DATA TABLE--><!-- MODALES -->
      <b-modal
          v-model="isShowModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <distance-household :id="selected"
                     :modal-header="'Cese de Asignación Familiar - '  +person_name"
                     :person_name="person_name"
                     @close="props.close">
          </distance-household>
        </template>
      </b-modal>
      <b-modal
          v-model="isEditModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <modal-distance-household-form :person-id="personId"
                         :household_allowance_id="old_household_allowance.id"
                         :person_name="old_household_allowance.name"
                         :person_last_name="old_household_allowance.last_name"
                         :person_second_surname="old_household_allowance.second_surname"
                         :person_birthday="old_household_allowance.birthday"
                         :rut="old_household_allowance.rut"
                         :old_start_date="old_household_allowance.start_date"
                          :id="selected"
                          query-type="update"
                          :modal-header="'Editar Cese de Carga Familiar - '  +person_name"
                          @close="props.close">
          </modal-distance-household-form>
        </template>
      </b-modal>
      <b-modal
          v-model="showPdf"
          :can-cancel="true"
          :destroy-on-hide="true"
          aria-modal
          aria-role="dialog"
          has-modal-card
          trap-focus
          :width="2000"
      >
        <template #default="props">
          <pdf_view
              :src="src"
              @close="props.close"
          ></pdf_view>
        </template>

      </b-modal>
    </div>
    <b-loading :is-full-page="true"
               v-model="isLoading"
               :can-cancel="false"/>
  </section>
</template>

<script>
import axios from "axios";
import ModalDistanceHouseholdForm from "./modal_distance_household"
import DistanceHousehold from "./distance_household"
import {searchDate, formatDate} from "../../../packs/utilities";
import moment from "moment";
import Pdf_view from "../../performances/pdf_view.vue";
export default {
  name: "distanced_household_allowances",
  props:[
    'personId', 'person_name'
  ],
  components:{
    Pdf_view,
    DistanceHousehold, ModalDistanceHouseholdForm
  },
  data(){
    return {
      selected: null,
      index: null,
      currentPage: 1,
      canCancel: ['escape'],
      isEditModalActive: false,
      isShowModalActive: false,
      isLoading:false,
      old_household_allowance: {
        id: "",
        name: "",
        last_name: "",
        second_surname: "",
        birthday: "",
        rut: "",
        old_start_date: ""},
      distanced_household_allowances: [],
      distanced_household_allowance: {},
      showPdf: false,
      src:null,
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    initialize(){
      this.isLoading = true;
      return axios
          .get("/people/"+this.personId+"/distanced_household_allowances.json")
          .then(res => {
            this.distanced_household_allowances = res.data
            this.distanced_household_allowances = this.distanced_household_allowances.map(allowance => {
              // create a new object to store full name.
              var newHousehold = allowance
              newHousehold["decree_date"] = moment(newHousehold["decree_date"] , ["YYYY-MM-DD","DD-MM-YYYY"]);
              newHousehold["start_date"] = moment(newHousehold["start_date"] , ["YYYY-MM-DD","DD-MM-YYYY"]);
              newHousehold["expiration_date"] = moment(newHousehold["expiration_date"] , ["YYYY-MM-DD","DD-MM-YYYY"]);
              // return our new object.
              return newHousehold;
            });
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })

    },
    execShowModal(id){
      this.selected = id;
      this.isShowModalActive = true;
    },
    execEditModal(row){
      this.selected = row.id;
      this.old_household_allowance.id = row.old_household_allowance.id
      this.old_household_allowance.name = row.old_household_allowance.name
      this.old_household_allowance.last_name = row.old_household_allowance.last_name
      this.old_household_allowance.second_surname = row.old_household_allowance.second_surname
      this.old_household_allowance.birthday = row.old_household_allowance.birthday
      this.old_household_allowance.rut = row.old_household_allowance.rut
      this.old_household_allowance.old_start_date = row.old_household_allowance.old_start_date
      this.isEditModalActive = true;
    },
    // yyyy/mm/dd -> dd/mm/yyyy
    formatDate,
    searchDecreeDate(row, input){
      return searchDate(row.decree_date, input)
    },
    searchStartDate(row, input){
      return searchDate(row.start_date, input)
    },
    searchExpirationDate(row, input){
      return searchDate(row.expiration_date, input)
    },
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            this.showPdf = true
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    downloadFile(fileId){
      this.isLoading = true;

      if (fileId == null){
        this.isLoading = false;
        return;
      }

      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(res => {
            let blob = new Blob([res.data], {
              type: "application/pdf",
            })
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = res.headers["content-disposition"]
                .split(";")[1]
                .split('"')[1];
            link.click();
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento fue descargado con exito',
              type: 'is-success',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
          .catch(e => {
            console.log(e)
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento no se pudo descargar',
              type: 'is-danger',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
    },
  },
  watch:{
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
    isShowModalActive: function () {
      if (!this.isShowModalActive) {
        this.initialize()
      }
    },
  }
}
</script>
