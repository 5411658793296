<template>
    <!-- Encabezado de la vista-->
    <div class="hero">
        <section class="hero has-background-white-bis" style="box-shadow: 0px 6px 14px -4px rgba(196,196,196,1);">
            <div class="hero-body">
                <div class="container">
                    <div class="columns is-centered">
                        <h1 class="is-size-2" style="text-align:center;">
                            {{ this.graduated.name +' '+ this.graduated.last_name +' '+ this.graduated.second_surname}}
                        </h1>
                    </div>
                </div>
            </div>
            <div class="hero-foot">
                <div class="tabs is-large is-centered is-boxed" id="tabs-research">
                    <ul class="is-large">
                        <li class="is-active" @click="tabSelection(0)" ref="InformacionGeneral">
                            <a>
                                <span class="icon"><i class="fas fa-address-card"></i></span>
                                <span class="h1">Datos personales</span>
                            </a>
                        </li>
                        <li @click="tabSelection(1)" ref="DatosAcadémicos">
                            <a>
                                <span class="icon"><i class="fas fa-address-card"></i></span>
                                <span class="h1">Datos académicos</span>
                            </a>
                        </li>
                        <li @click="tabSelection(2)" ref="TrayectriaLaboral">
                            <a>
                                <span class="icon"><i class="fas fa-address-card"></i></span>
                                <span class="h1">Trayectoria laboral</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <!-- Contenido por cada vista-->
        <div class="container">
            <br>
            <ShowGeneralInformation v-if="ShowViewToShow == 1" :graduated='this.graduated' :social_networks='this.social_networks'/>
            <ShowAcademicInformation v-if="ShowViewToShow == 2" :units='this.units' :carrers='this.carreras'/>
            <ShowWorkHistory v-if="ShowViewToShow == 3" 
                             :work_history='this.work_history' 
                             :work_history_found='this.work_history_found'
                             :partner_entities_information ='this.partner_entities_information'
                             :graduated_id='this.graduated_id'/> 
        </div>
    </div>
    
</template>
<script>
/* importaciones generales */ 
import axios from "axios"

/* importaciones de componentes */
import ShowGeneralInformation from './show_general_information.vue'
import ShowAcademicInformation from './show_academic_information.vue'
import ShowWorkHistory from './show_work_history.vue' 

export default {
    name: 'ShowView',
    components:{
        ShowGeneralInformation,
        ShowAcademicInformation,
        ShowWorkHistory
    },
    props:{
        graduated_id:{
            type: Number
        }
    },
    data () {
        return{
            graduated: { },
            run: { },
            units: { },
            carreras: { },
            social_networks: [ ],
            work_history: [],
            work_history_found: false,
            partner_entities_information: [],
            ShowViewToShow: 1
        }
    },
    methods:{
        getGraduate(){
            axios.get('/graduates/show/'+this.graduated_id+'.json')
            .then(response =>{
                this.graduated = response.data.personas
                this.run = response.data.run
                this.units = response.data.unidades
                this.carreras = response.data.carrera
                this.social_networks = response.data.redes_sociales_personales
            })
            .catch(e => console.log(e))
        },
        getWorkHistory(){
            axios.get('/work_history/getWorkHistoryByPersonId/'+this.graduated_id)
            .then(response =>{
                this.work_history                   = response.data.work_history
                this.partner_entities_information   = response.data.partner_entities_information 
                this.work_history_found = true
            })
            .catch(e => console.log(e))
        },
        tabSelection(tabCase){
            switch(tabCase){
                case 0:
                    this.$refs.InformacionGeneral.classList.add("is-active")

                    if(this.$refs.TrayectriaLaboral.classList.contains("is-active"))
                        this.$refs.TrayectriaLaboral.classList.remove("is-active")
                    
                    if(this.$refs.DatosAcadémicos.classList.contains("is-active"))
                        this.$refs.DatosAcadémicos.classList.remove("is-active")
                    
                    this.ShowViewToShow = 1 
                    break;

                case 1:
                    this.$refs.DatosAcadémicos.classList.add("is-active")
                    
                    if(this.$refs.InformacionGeneral.classList.contains("is-active"))
                        this.$refs.InformacionGeneral.classList.remove("is-active")
                    
                    if(this.$refs.TrayectriaLaboral.classList.contains("is-active"))
                        this.$refs.TrayectriaLaboral.classList.remove("is-active")
                    
                    this.ShowViewToShow = 2
                    break;
                default:
                    this.$refs.TrayectriaLaboral.classList.add("is-active")
                    
                    if(this.$refs.InformacionGeneral.classList.contains("is-active"))
                        this.$refs.InformacionGeneral.classList.remove("is-active")
                    
                    if(this.$refs.DatosAcadémicos.classList.contains("is-active"))
                        this.$refs.DatosAcadémicos.classList.remove("is-active")

                    this.ShowViewToShow = 3
            }
        }
    },
    beforeMount (){
        this.getGraduate()
        this.getWorkHistory()
    }
}
</script>