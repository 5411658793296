<template>
  <section>
    <b-modal width="90%" v-model="activeModal"
             aria-modal
             :can-cancel="canCancel"
             :on-cancel="closeModal">
      <div class="modal-card" style="width:100%">
        <header class="modal-card-head">
          <p class="modal-card-title">{{modalHeader}}</p>
          <button
              type="button"
              class="delete"
              @click="closeModal"/>
        </header>
        <section class="modal-card-body" ref="modalPersonForm" style="width:100%">
          <div class="columns">
            <div class="column is-12">
              <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion">
                      {{ item[0] }}
                    </div>
                  </b-message>
                </div>
                <form @submit.prevent="handleSubmit(saveFinancing)">
                  <!-- Box de Formulario Project -->
                  <div class="box">

                    <div class="columns">
                      <div class="column">
                        <b-field label="Monto*">
                          <ValidationProvider rules="required|integer|min_value:0" v-slot="{ errors }">
                            <b-input :has-counter="false" maxlength="14" v-model="financing.amount"></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>

                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Año*">
                          <ValidationProvider rules="required|integer|min_value:1900|max_value:2100" v-slot="{ errors }">
                            <b-input v-model="financing.year"></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>

                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Tipo de aporte*">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <multiselect v-model="financing.financing_type"
                                         :options="financing_types"
                                         :custom-label="opt => opt.alt_name ? opt.alt_name : opt.name"
                                         placeholder="Seleccione tipo de aporte"
                                         selectLabel="Presione para seleccionar"
                                         selectedLabel="Seleccionado"
                                         deselectLabel="No se puede deseleccionar"
                                         :allow-empty="false">
                              <template v-slot:noOptions>
                                No existen datos
                              </template>
                              <span slot="noResult">
                            No se encontraron elementos.
                        </span>
                            </multiselect>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns" style="margin-bottom: 5em;">
                      <div class="column">
                        <b-field>
                          <template #label>
                            Entidad Fuente del Aporte* (ingrese por lo menos 3 letras)
                            <b-tooltip
                                label="Se recomienda búsqueda por sigla, ejemplo: ANID, no Agencia Nacional... mientras que para instituciones educacionales, se recomienda por nombre completo, ejemplo: universidad de santiago de chile"
                                position="is-right"
                                size="is-medium"
                                multilined>

                              <b-icon
                                  pack="fas"
                                  icon="info-circle"
                                  size="is-small"
                                  type="is-info">
                              </b-icon>
                            </b-tooltip>
                          </template>
                          <ValidationProvider rules="required" v-slot="{ validate, errors }">
                            <b-autocomplete
                                @input="validate"
                                :data="partner_entities"
                                placeholder="ej ANID o UNIVERSIDAD DE SANTIAGO DE CHILE  o NOMBRE EMPRESA - ENTIDAD"
                                field="show_name"
                                :loading="isFetching"
                                @typing="searchPartnerEntities"
                                v-model="partner_entity.name"
                                @select="option => {if (option) {partner_entity = option; program={}}}">

                              <template slot-scope="props">
                                {{ props.option.show_name ? props.option.show_name : props.option.name}}
                              </template>
                            </b-autocomplete>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>

                        </b-field>
                      </div>
                      <div class="column is-narrow">
                        <b-field>
                          <template #label>
                            <div class="has-text-centered">
                              <b-tooltip
                                  label="Si la entidad que desea agregar no existe, la puede agregar presionando el botón +"
                                  position="is-bottom"
                                  size="is-medium"
                                  multilined>

                              <b-icon
                                  pack="fas"
                                  icon="info-circle"
                                  type="is-info">
                              </b-icon>
                            </b-tooltip>

                            </div>

                          </template>
                          <b-button class="is-primary is-outlined" @click="addEntity()">
                            <b-icon icon="plus"></b-icon>
                          </b-button>
                        </b-field>

                      </div>
                      <div v-if="show_programs" class="column">
                        <b-field label="Programa de postulación">
                          <multiselect v-model="program"
                                       :options="programs"
                                       :custom-label="opt => opt.name"
                                       placeholder="Seleccione programa de postulación"
                                       selectLabel="Presione para seleccionar"
                                       selectedLabel="Seleccionado"
                                       deselectLabel="Presione para deseleccionar"
                                       :allow-empty="true">
                            <template v-slot:noOptions>
                              No existen datos
                            </template>
                            <span slot="noResult">
                                No se encontraron elementos.
                            </span>
                          </multiselect>
                        </b-field>
                      </div>
                      <div v-if="show_programs" class="column is-narrow">
                        <b-field>
                          <template #label>
                            <div class="has-text-centered">
                              <b-tooltip
                                  label="Si el programa que desea agregar no existe, lo puede agregar presionando el botón +"
                                  position="is-left"
                                  size="is-medium"
                                  multilined>

                                <b-icon
                                    pack="fas"
                                    icon="info-circle"
                                    type="is-info">
                                </b-icon>
                              </b-tooltip>
                            </div>
                          </template>
                          <b-button class="is-primary is-outlined" @click="addProgram()">
                            <b-icon icon="plus"></b-icon>
                          </b-button>
                        </b-field>

                      </div>
                    </div>

                    <div class="actions has-text-right">
                      <button class="button is-danger" icon-left="trash" @click="closeModal">Cancelar</button>
                      <button class="button is-primary" type="submit" icon-left="plus" @click="checkValidation()">Guardar Aporte</button>
                    </div>

                  </div>


                </form>
              </ValidationObserver>

            </div>
          </div>
        </section>
      </div>
    </b-modal>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="isAddModalEntityActive"
        :destroy-on-hide="true"
        width="80%"
        :can-cancel="canCancel">
      <template #default="props">
        <modal_partner_entity_life_record
            query-type="create"
            modal-header="Añadir nueva entidad"
            v-bind:all-entity-types="true"
            @close="props.close"
            @clicked="onClickChild"></modal_partner_entity_life_record>
      </template>
    </b-modal>
    <b-modal
        v-model="isAddProgramModalActive"
        :destroy-on-hide="true"
        :can-cancel="canCancel"
        has-modal-card>
      <template #default="props">
        <modal_new_programs v-bind:modal-header="'Añadir Programa'"
                            :partner_entity_id="partner_entity.id"
                            :query-type="'create'"
                            @close="props.close"
                            @clicked="newProgramMessage"></modal_new_programs>
      </template>
    </b-modal>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import modal_partner_entity_life_record from "../life_record/partner_entity/modal_partner_entity_life_record";
import modal_new_programs from "../moduloVIME/partner_entities_vime/show/modal_new_programs";
export default {
name: "AddPostulatedFinancingModal",
  components:{modal_new_programs, modal_partner_entity_life_record},
  data() {
    return {
      isImageModalActive: false,
      isCardModalActive: false,
      canCancel: ['escape'],
      isLoading: false,
      financing:{},
      partner_entities:[],
      partner_entity:{},
      programs:[],
      program:{},
      financing_types:[],
      financing_type:{},
      errores_validacion:{},
      isFetching:false,
      destroyOnHide:true,
      entity_type:{},
      entity_types:[],
      isAddModalEntityActive: false,
      isAddProgramModalActive: false,
      show_programs:false
    }
  },
  props:['modalHeader','activeModal','projectId','financingId','actionType','actionButtonName','showPecuniaryContribution'],
  async created() {
    await this.getFinancingTypes();
    if (this.activeModal)
      this.isCardModalActive = true;
    if (this.financingId){
      this.getProjectFinancing();
    }
  },
  watch: {

    partner_entity: function (val){
      if(this.partner_entity){
        this.searchPrograms();
      }
      else{
        this.show_programs = false;
      }
    },
    financingId: function (val){
      if (this.financingId){
        this.getProjectFinancing();
      }
    }
  },
  methods:{
    async checkValidation() {
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
    async getFinancingTypes(){
      let vm = this;
      vm.isLoading=true;
      return axios
        .get("/projects/postulated-financing-types.json")
        .then(res => {
          vm.financing_types = res.data.financing_types;
          vm.entity_types = res.data.entity_types;
          vm.isLoading=false;
        })
        .catch(e => {
          console.log(e);
          vm.isLoading=false;
        });
    },
    saveFinancing(){
      let vm = this;
      if (vm.actionType=="create"){
        vm.createFinancing();
      }
      else if (vm.actionType=="update"){
        vm.updateFinancing();
      }


    },
    createFinancing(){
      let vm = this;
      vm.isLoading=true;
      if (vm.program && vm.financing){
        vm.financing.program_id = vm.program.id;
      }
      if (vm.program && vm.partner_entity){
        vm.financing.partner_entity_id = vm.partner_entity.id;
      }
      if(vm.financing && vm.financing.financing_type){
        vm.financing.financing_type_id = vm.financing.financing_type.id;
      }else{
        vm.checkValidation();
      }
      axios
          .post("/projects/postulated/"+vm.projectId+"/financing.json", {
            financings_attributes: [vm.financing],
            id:vm.projectId
          })
          .then(res => {
            vm.errores_validacion = {};
            vm.$buefy.dialog.alert({
              message: 'El aporte fue asociado satisfactoriamente.',
              type: 'is-success',
              hasIcon: true,
              icon: 'check-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            //EMITIR FINANCIAMIENTO NUEVO A COMPONENTE PADRE
            var retorno = {
              financing:vm.financing,
              partner_entity: vm.partner_entity,
              program: vm.program
            }
            vm.$emit('addFinancing', retorno)
            //vm.financing = {};
            //vm.program = {};
            //vm.partner_entity = {};
            vm.isLoading=false;
          }).
      catch(error => {
        vm.errores_validacion = error.response.data
        var elmnt = document.getElementById("app");
        elmnt.scrollIntoView();
        vm.isLoading=false;
      })

    },
    updateFinancing(){
      let vm = this;
      vm.isLoading=true;
      if (vm.program && vm.financing){
        vm.financing.program_id = vm.program.id;
      }
      if (vm.program && vm.partner_entity){
        vm.financing.partner_entity_id = vm.partner_entity.id;
      }
      if(vm.financing && vm.financing.financing_type){
        vm.financing.financing_type_id = vm.financing.financing_type.id;
      }else{
        vm.checkValidation();
      }
      axios
          .put("/projects/postulated/"+vm.projectId+"/financing.json", {
            financings_attributes: [vm.financing],
            id:vm.projectId
          })
          .then(res => {
            vm.errores_validacion = {};
            vm.$buefy.dialog.alert({
              message: 'El aporte fue actualizado satisfactoriamente.',
              type: 'is-success',
              hasIcon: true,
              icon: 'check-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            //EMITIR FINANCIAMIENTO NUEVO A COMPONENTE PADRE

            vm.$emit('updateFinancing')
            vm.financing = {};
            vm.program = {};
            vm.partner_entity = {};
            vm.isLoading=false;
          }).
      catch(error => {
        vm.errores_validacion = error.response.data
        var elmnt = document.getElementById("app");
        elmnt.scrollIntoView();
        vm.isLoading=false;
      })

    },

    getProjectFinancing(){
      let vm = this;
      vm.isLoading=true;
      return axios
          .get("/financings/"+vm.financingId+".json")
          .then(res => {
            vm.financing = res.data;
            vm.partner_entity = res.data.selected_entity;
            vm.financing.financing_type = vm.financing_types.find(obj => obj.name==vm.financing.financing_type);
            vm.entity_type = vm.entity_types.find(o => o.id == vm.partner_entity.entity_type_id)
            vm.isLoading=false;
          })
          .catch(e => {
            console.log(e);
            vm.isLoading=false;
          });
    },
    closeModal(){
      this.isLoading = true;
      this.financing = {};
      this.program = {};
      this.partner_entity = {};
      this.programs = [];
      this.isLoading = false;
      this.$emit('close');
    },
    searchPartnerEntities(name){
      if (name.length<3) {
        this.partner_entities = [];
        this.programs = [];
        this.show_programs = false;
        return
      }
      let vm = this;
      vm.isLoading = true;
      axios
          .get("/admin/partner_entities/financings-search.json", { params: { name: name } })
          .then(res => {
            vm.partner_entities = res.data;
            vm.isLoading = false;
          })
          .catch(error => {
            console.log(error)
            vm.isLoading = false;
          })

    },
    searchPrograms(){
      let vm = this;
      if (vm.partner_entity.id){
        vm.isLoading = true;
        axios
          .get("/partner_entities/"+vm.partner_entity.id+"/programs.json")
          .then(res => {
            vm.programs = res.data;
            if (vm.financing.program_id && vm.programs.length > 0){
              var program = vm.programs.filter(o => o.id==vm.financing.program_id)
              if (program){
                vm.program = program[0]
              }
            }
            vm.show_programs = true;
            vm.isLoading=false;
          })
          .catch(e => {
            console.log(e);
            vm.isLoading=false;
          });
      }

    },

    addEntity(){
      this.isAddModalEntityActive = true;
    },
    onClickChild(){
      this.$buefy.toast.open({
        message: 'La entidad se agregó correctamente',
        type: 'is-success',
        duration: 5000,
      })
    },
    addProgram(){
      this.isAddProgramModalActive = true;
    },
    newProgramMessage(){
      this.$buefy.toast.open({
        message: 'El programa se agregó correctamente',
        type: 'is-success',
        duration: 5000,
      })
      this.searchPrograms();
    }

  }
}
</script>

<style scoped>

</style>