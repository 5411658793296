<template>
  <section>
    <div class="container">
      <div class="columns">
        <div class="column">
          <div class="control">
            <h1 class="title">Asignación de Roles</h1>
          </div>
        </div>
      </div>
      <div class="box">
        <b-tabs @input="changeTab" type="is-boxed">
          <b-tab-item v-for="item in items"
                      :value="item.content"
                      v-bind:key="item.content"
                      :label="item.tab" >
          </b-tab-item>
        </b-tabs>
        <!-- COMPONENTE AQUI -->
        <keep-alive>
          <component v-bind:is="currentTabComponent" :person-id="personId"></component>
        </keep-alive>
        <!-- FIN COMPONENTE -->
      </div>
    </div>
  </section>
</template>
<script>
import RolesList from "../person/roles_list.vue"
import AppointmentsList from "../person/appointments_list.vue"
export default {
  name: "profiles_tabs",
  props:[
    'personId'
  ],
  data(){
    return {
      currentTab: 'RolesList',
      items: [
        {tab: 'Roles',content:'RolesList'},
        {tab: 'Nombramientos',content:'AppointmentsList'},
      ]
    }
  },
  components:{
    RolesList,
    AppointmentsList,
  },
  methods:{
    changeTab: function(value){
      this.currentTab = value
    }
  },
  computed: {
    currentTabComponent: function () {
      return this.currentTab
    }
  }
}
</script>