<template>
  <section>
    <h1 class="subtitle is-2 has-text-primary">
      ¿Qué información hay en Datos Personales?
    </h1>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Datos de Identificación Básicos
    </h2>
    <div class="mb-3 ">
      <p>En la sección de datos personales, se pueden visualizar los nombres y apellidos del académico,
        además su rut, lugar de nacimiento y fecha de nacimiento.</p>
    </div>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Datos de Contacto
    </h2>
    <div class="mb-3 ">
      <p>En esta sección, se pueden visualizar datos respecto a la comuna, región y dirección de residencia.
       Además, se tienen otros datos de contacto como el número de teléfono, número de celular, correo personal y
      correo corporativo. </p>
    </div>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Datos Laborales
    </h2>
    <div class="mb-3 ">
      <p>En esta sección, se pueden visualizar datos respecto a la institución de previsión, institución de salud,
        fecha de ingreso a la administración pública y fecha ingreso USACH. Además, se indica si el académico
      está jubilado o si rinde fianza y un link a la carpeta de documentación de Oficina Administrativa que contiene
        todos los respaldos que ratifican los datos ingresados en Hoja de Vida. </p>
    </div>
    <img border="2" class="m-2" src="/tutorial_images/datos_personales.png" style="width:80%">
    <h2 class="subtitle is-3 mt-5 mb-2">
      Exportar PDF
    </h2>
    <div class="mb-3 ">
      <p>Finalmente, el sistema permite descargar un archivo PDF con el resumen de los datos ingresados
        en Mi Hoja de Vida.</p>
    </div>
    <ul class=" ml-4">
      <li>1. Clickear en el botón "Exportar" que se encuentra junto a <b>Hoja de Vida</b></li>
      <img border="2" class="m-2" src="/tutorial_images/datos_personales_resalted.png" style="width:80%">
    </ul>
  </section>
</template>
<script>
import EventBus from "../../eventBus";
export default {
  name: "personal_data",
  data(){
    return {
    }
  },
  methods:{

  },
}
</script>