<template>
  <section>
    <!-- Box de Formulario function -->
    <div class="modal-card" style="width: auto" id="show-person-function">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body" id="show-person-function" ref="showPersonFunction">
        <div class="columns">
          <h3 class="is-size-3 has-text-weight-bold">Información Personal y Nombramiento</h3>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Nombre Completo">
              <b-input :value="person!==undefined ? person.nombre_completo : ''" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Rut">
              <b-input :value="person!==undefined ? person.run_completo : ''" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Nombramiento">
              <b-input :value="nombramiento!==undefined ? nombramiento.custom_name : ''" type="text" readonly></b-input>
            </b-field>
            <p class="control">
              <b-button v-if="nombramiento.id !== undefined && nombramiento.id !== null" class="mt-2" @click.prevent="isShowPersonUnitActive = true" type="is-primary" :disabled="nombramiento===undefined||nombramiento===null">Mostrar Nombramiento</b-button>
            </p>
          </div>
        </div>
        <div class="columns">
          <h3 class="is-size-3 has-text-weight-bold">Información Función Administrativa</h3>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Función">
              <b-input :value="(person_function.function!==undefined && person_function.function!==null) ? person_function.function.name : ''" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Descripción">
              <b-input :value="person_function.description" type="textarea" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Unidad de desempeño">
              <b-input :value="(person_function.unit!==undefined && person_function.unit!==null) ? person_function.unit.name : ''" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Tipo de documento">
              <b-input :value="(person_function.resolution_type!==undefined && person_function.resolution_type!==null) ? person_function.resolution_type.name : ''" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Número de documento">
              <b-input :value="person_function.resolution" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha de documento">
              <b-input :value="formatDate(person_function.resolution_date)" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Fecha de inicio">
              <b-input :value="formatDate(person_function.start_date)" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha de término">
              <b-input :value="formatDate(person_function.end_date)" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Jornada (horas semanales)">
              <b-input :value="person_function.hours" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
<!--        <div class="columns">
          <div class="column">
            <b-field label="Url carpeta documentación Función">
              <b-input expanded readonly v-model="person_function.documentation_link" type="text"></b-input>
              <p class="control">
                <b-button tag="a" :href="person_function.documentation_link" target="_blank" icon-left="external-link-alt" type="is-link">Link</b-button>
              </p>
            </b-field>
          </div>
        </div>-->
      </section>
    </div>
    <footer class="modal-card-foot actions">
      <div class="field is-grouped is-pulled-right">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
        <b-button class="is-primary"
                  v-if="person_function.can_edit"
                  @click="isEditModalActive = true">
          Editar
        </b-button>
      </div>
    </footer>
    <b-modal
        v-model="isEditModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        :can-cancel="canCancel"
        aria-modal>
      <template #default="props">
        <administrative-function-form :person-id="person_function.person_id"
                       :id="person_function.id"
                       query-type="update"
                       :modal-header="'Editar Función Administrativa - '+person_name"
                       @close="props.close">
        </administrative-function-form>
      </template>
    </b-modal>
    <b-modal
        v-model="isShowPersonUnitActive"
        :destroy-on-hide="true">
      <template #default="props">
        <info-appointment
            modal-header="Nombramiento Asociado"
            :nombramiento = "nombramiento"
            @close="props.close"
        ></info-appointment>
      </template>
    </b-modal>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios"
import AdministrativeFunctionForm from "./modal_administrative_function_form";
import {formatDate} from "../../../packs/utilities"
import InfoAppointment from "../commissions/info_appointment";
export default {
  name: "show_administrative_function",
  props: [
    'queryType', 'personId', 'id','modalHeader', 'person_name'
  ],
  components:{
    AdministrativeFunctionForm,
    InfoAppointment,
  },
  data(){
    return {
      person_function: {},
      person_function_to_distance: {},
      errores_validacion:{},
      resolution_types: [],
      person_function_type: {},
      functions: [],
      units: [],
      isLoading: false,
      funcion_filtrada: [],
      function_name: null,
      isEditModalActive: false,
      canCancel: ['escape'],
      nombramiento: {},
      person: {},
      isShowPersonUnitActive: false,
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize(){
      this.isLoading=true;
      return axios
          .get("/person_functions/" + this.id + ".json")
          .then(res => {
            this.person_function = res.data
            this.person = res.data["person"]
            this.nombramiento = res.data["nombramiento"]
            this.isLoading = false;
          }).catch(error => {
            console.log(error)
            this.isLoading = false;
          })
    },
    formatDate,

  },
  watch:{
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
  }
}
</script>