<template>
  <section>
    <div class="modal-card" style="width: 1024px">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column">
            <b-field label="Comentario">
              <b-input :value="request_state_history.comment" type="textarea" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Estado">
              <b-tag :type="request_state_history!==undefined ? request_state_history.type : 'is-info'" size="is-large">{{ request_state_history.request_state!==undefined ? request_state_history.request_state.name : "" }}</b-tag>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Nombre">
              <b-input :value="request_state_history.person_name" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Rol">
              <b-input :value="request_state_history.internal_position!==undefined ? request_state_history.internal_position.name : ''" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Fecha">
              <b-input :value="request_state_history.formatted_date" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Acción">
              <b-input :value="request_state_history.action" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
      </section>
    </div><footer class="modal-card-foot actions">
    <div class="has-text-right is-grouped">
      <button class="button" type="button" @click="$emit('close')">Cerrar</button>
    </div>
  </footer>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import {formatDate} from "../../packs/utilities";

export default {
  name: "request_state_history_show",
  props:[
    "id", "modalHeader"
  ],

  data(){
    return {
      isLoading: false,
      request_state_history: {

      }
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.isLoading = true;
      return axios
          .get("/request_state_histories/" + this.id + ".json")
          .then(res => {
            this.request_state_history = res.data
            this.isLoading = false;
          }).catch(error => {
            console.log(error)
            this.isLoading = false;
          })
    },
    formatDate,
  },
}
</script>

