<template>
  <div>
    <div class="columns">
      <div class="column has-text-right">
        <b-field>
          <b-switch v-model="cardLayout">Formato tarjeta (sin filtros ni búsqueda)
            <b-tooltip
                label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                multilined
                position="is-left">
              <b-icon
                  pack="fas"
                  icon="info-circle"
                  type="is-info">
              </b-icon>
            </b-tooltip></b-switch>
        </b-field>
      </div>
    </div>


    <b-table
        :data="disclosures"
        :paginated=true
        :card-layout="cardLayout"
        per-page=10
        striped
        hoverable
        :current-page="currentPage"
        :pagination-simple=false
        pagination-position="bottom"
        default-sort-direction="asc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="disclosure.name"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página actual"
        :opened-detailed="defaultOpenedDetails"
        detailed
        detail-key="code"
        @details-open="(row) => $buefy.toast.open(`Expanded ${row.name}`)"
        :show-detail-icon="showDetailIcon"
    >
      <b-table-column
          field= 'name'
          label= 'Nombre'
          width="30em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.name }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'code'
          label= 'Código'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.code }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'financing_source'
          label= 'Fuente de Financiamiento'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.financing_source }}
        </template>
      </b-table-column>

      <b-table-column
          field= 'disclosure_date'
          label= 'Fecha Recepción'
          searchable
          :custom-search="searchDisclosureDate"
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.disclosure_date._isValid ? new Date(props.row.disclosure_date).toLocaleDateString("es-CL") : '' }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'invention_date'
          label= 'Fecha Invención'
          searchable
          :custom-search = "searchInventionDate"
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.invention_date._isValid ? new Date(props.row.invention_date).toLocaleDateString("es-CL"): '' }}
        </template>
      </b-table-column>
      <b-table-column
          label="Acciones"
          v-slot="props">
        <b-icon
            pack="fas"
            icon="eye"
            size="is-medium"
            @click.native="execShowModal(props.row.id)"/>
        <b-icon
            pack="fas"
            icon="trash-restore"
            size="is-medium"
            type="is-success"
            @click.native="execRestoreModal(props.row.id, props.row)"/>
      </b-table-column>
      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>Participantes: </strong>
                <span v-for="participant in props.row.authors" >
                  <a v-if="participant.url!=null" :href="participant.url">{{ participant.name }}</a>
                  <span v-else>{{ participant.name }}</span>
                  <span v-if="props.row.authors.length>1 && participant!=props.row.authors.slice(-1)[0]">, </span>
                </span>
              </p>
            </div>
          </div>
        </article>
      </template>
      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-danger">
              No se encontraron resultados para el año seleccionado.
            </b-message>
          </div>
        </div>
      </template>
    </b-table>

    <b-modal
        v-model="isShowModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal_show_nulliefied_disclosure :disclosureId="disclosureId"
                                          :cargoPrincipal="cargoPrincipal"
                                          modal-header="Disclosure"
                                          @close="props.close">
        </modal_show_nulliefied_disclosure>
      </template>
    </b-modal>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  </div>
</template>
<script>
import moment from "moment";
import {searchDate} from "../../packs/utilities";
import axios from "axios";
import modal_show_nulliefied_disclosure from "./modal_show_nulliefied_disclosure";
export default {
  props:[
    'hideUpdate','cargoPrincipal'
  ],
  components:{
    modal_show_nulliefied_disclosure
  },
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      years: [],
      year: null,
      isNullifyActive: false,
      isShowModalActive: false,
      disclosureId: null,
      disclosures:[],
    }
  },
  created(){
    if(this.cargoPrincipal.name == 'ANALISTA I+D FING' || this.cargoPrincipal.name == 'SUPERADMIN'){
      this.getDisclosuresNullified()
    }
    else {
      this.myGetDisclosuresNullified()
    }
  },
  methods:{
    searchInventionDate(row,input){
      return searchDate(row.invention_date,input)
    },
    searchDisclosureDate(row,input){
      return searchDate(row.disclosure_date,input)
    },
    getDisclosuresNullified(){
      this.isLoading = true
      return axios.get("/disclosures.json", {params:{year_f:-1}})
          .then(res => {

            this.disclosures = res.data;
            this.disclosures = this.disclosures.map(disclosure => {
              var newDisclosure = disclosure
              newDisclosure["disclosure_date"] = moment(newDisclosure["disclosure_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newDisclosure["invention_date"] = moment(newDisclosure["invention_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newDisclosure;
            });
            this.isLoading=false;
          })
          .catch(e => {
            this.isLoading=false;
            console.log(e);
          });
    },
    myGetDisclosuresNullified(){
      this.isLoading = true
      return axios.get("/disclosures/my_nullified_disclosures.json")
          .then(res => {

            this.disclosures = res.data;
            this.disclosures = this.disclosures.map(disclosure => {
              var newDisclosure = disclosure
              newDisclosure["disclosure_date"] = moment(newDisclosure["disclosure_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newDisclosure["invention_date"] = moment(newDisclosure["invention_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newDisclosure;
            });
            this.isLoading=false;
          })
          .catch(e => {
            this.isLoading=false;
            console.log(e);
          });
    },

    execShowModal(id){
      this.disclosureId = id
      this.isShowModalActive = true
    },
    execRestoreModal(id, row){
      let vm = this;
      vm.$buefy.dialog.confirm({
        title: 'Restaurar disclosure',
        message: '¿Está seguro que desea <b>restaurar</b> el disclosure? Esta acción revertirá la anulación.',
        confirmText: 'Restaurar Disclosure',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          vm.isLoading = true
          axios
              .put("/disclosures/" + id + "/restore_disclosure.json")
              .then(res => {
                vm.$buefy.dialog.alert({
                  message: 'Disclosure restaurada correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                var index = this.disclosures.indexOf(row)
                this.disclosures.splice(index, 1)
                vm.isLoading = false;
              }).catch(error => {
            console.log(error)
            vm.$buefy.dialog.alert({
              message: '<b>Disclosure no pudo ser restaurado</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            vm.isLoading = false;
            vm.isRestoreActive = false;
          })
        }
      })
    },

  },

  computed:{

  }
}
</script>
