var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [(Object.keys(_vm.errores_validacion).length != 0)?_c('div',{staticClass:"mb-2"},[_c('b-message',{attrs:{"type":"is-danger","has-icon":""}},_vm._l((_vm.errores_validacion),function(item,index){return _c('div',{key:index},[_vm._v("\n                    "+_vm._s(item[0])+"\n                ")])}),0)],1):_vm._e(),_vm._v(" "),_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveMyAcademicData)}}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column has-text-right"},[_c('b-button',{attrs:{"type":"is-pdf-color has-text-white","icon-left":"file-pdf","label":"Descargar Curriculum PDF"},on:{"click":function($event){return _vm.remember()}}})],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"name":"Reseña","rules":"min:3|max:500"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-field',{attrs:{"label":"Reseña","type":{ 'is-danger': Object.keys(failedRules).length > 0 },"message":Object.keys(failedRules).length > 0 ? errors[0] : ''}},[_c('b-input',{attrs:{"type":"textarea","placeholder":"Ingrese una descripción sobre ud."},model:{value:(_vm.person.biography),callback:function ($$v) {_vm.$set(_vm.person, "biography", $$v)},expression:"person.biography"}})],1)]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{ref:"researchLines",attrs:{"name":"Líneas de Investigación (Expertiz)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-field',{attrs:{"label":"Líneas de Investigación (Expertiz)","type":{ 'is-danger': Object.keys(failedRules).length > 0 },"message":Object.keys(failedRules).length > 0 ? errors[0] : ''}},[_c('b-taginput',{attrs:{"data":_vm.filtered_key_words,"allow-new":true,"allow-duplicates":false,"field":"name","icon":"tag","autocomplete":"","placeholder":"Agrega una expertiz","beforeAdding":_vm.beforeAddTag},on:{"typing":_vm.getFilteredKeyWords},model:{value:(_vm.person.key_words_attributes),callback:function ($$v) {_vm.$set(_vm.person, "key_words_attributes", $$v)},expression:"person.key_words_attributes"}})],1)]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"name":"Link Propio","rules":"min:3|max:255|protocol"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-field',{attrs:{"label":"Link Propio","type":{ 'is-danger': Object.keys(failedRules).length > 0 },"message":Object.keys(failedRules).length > 0 ? errors[0] : ''}},[_c('b-input',{attrs:{"type":"link","expanded":"","placeholder":"Ingrese Link Propio"},model:{value:(_vm.person.link),callback:function ($$v) {_vm.$set(_vm.person, "link", $$v)},expression:"person.link"}}),_vm._v(" "),_c('b-button',{attrs:{"type":"is-link","icon-left":"external-link-alt","label":"Link","tag":"a","href":_vm.person.link ? _vm.person.link : '',"target":"_blank"}})],1)]}}],null,true)})],1)]),_vm._v(" "),(_vm.person.can_update)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column has-text-right"},[_c('button',{staticClass:"button is-primary",attrs:{"type":"submit"},on:{"click":_vm.checkValidation}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-save"})]),_vm._v(" "),_c('span',[_vm._v("Guardar Datos Curriculum VIME Largo")])])])]):_vm._e()])]}}])}),_vm._v(" "),_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),_vm._v(" "),_c('img',{staticStyle:{"display":"none"},attrs:{"id":"logo","src":"/logo_negro_facultad_ing.png"}}),_vm._v(" "),_c('img',{staticStyle:{"display":"none"},attrs:{"id":"fondo","src":"/fondo_curriculum_corto.png"}}),_vm._v(" "),_c('img',{staticStyle:{"display":"none"},attrs:{"id":"mail","src":"/mail_icon.png"}}),_vm._v(" "),_c('img',{staticStyle:{"display":"none"},attrs:{"id":"mundo","src":"/icono_mundo2.png"}}),_vm._v(" "),_c('img',{staticStyle:{"display":"none"},attrs:{"id":"photo","src":this.person.photo_url}}),_vm._v(" "),_c('img',{staticStyle:{"display":"none"},attrs:{"id":"pfp","src":"/blank_profile_photo.png"},on:{"load":_vm.loadImage}}),_vm._v(" "),_c('img',{staticStyle:{"display":"none"},attrs:{"id":"linea","src":"/linea_divisora.png"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }