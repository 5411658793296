<template>
  <section>

    <disclosure-table v-bind:disclosures="disclosures" :cargo-principal="cargoPrincipal" :hide-update="true" @updateDisclosures="updateDisclosures"></disclosure-table>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  </section>
</template>
<script>
import axios from "axios";
import moment from "moment";
import DisclosuresTable from "../disclosure/disclosure_table.vue";
export default {
  props:[
    'objectId','componentType','cargoPrincipal', 'currentYear', 'minYear','maxYear', 'changeFilter','hideUpdate'
  ],
  data(){
    return{
      disclosures:[],
      isLoading:false,
      year:new Date().getFullYear(),
    }
  },
  components:{
    'disclosure-table':DisclosuresTable,
  },
  created(){
    this.getDisclosures();
  },
  methods:{
    getDisclosures(){
      this.isLoading=true;
      var url = "";
      if (this.componentType == "person"){
        url = "/people/"+this.objectId+"/disclosures.json"
      }
      else if (this.componentType=="unit"){
        url = "/admin/units/"+this.objectId+"/disclosures.json"
      }
      if(this.hideUpdate == true){
        this.year = 'MyActivity'
      }
      console.log(this.hideUpdate)
      axios
          .get(url, {params:{
              year_f:this.year,
              minYear: this.minYear,
              maxYear: this.maxYear
          }})
          .then(res => {
            this.isLoading=false;
            this.disclosures = res.data;
            this.disclosures = this.disclosures.map(disclosure => {
              var disclosure = disclosure
              disclosure["disclosure_date"] = moment(disclosure.disclosure_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
              disclosure["invention_date"] = moment(disclosure.invention_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
              return disclosure;
            });
          })
          .catch(e => {
            console.log(e);
            this.isLoading=false;
          });
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    updateDisclosures(year){
      this.year = year
      this.getDisclosures()
    },
  },
  computed:{

  },
  watch:{
    changeFilter: function(){
      this.getDisclosures()
    }
  }
}
</script>