<template>
    <div class="showworkhistory">
        <div v-if='this.work_history_found'>
            <div class="container">
                <div class="columns">
                    <div class="column">
                        <span class="is-pulled-right">
                            <button class="button is-primary" @click="addNewWorkHistory()">
                            <b-icon icon="plus"></b-icon>
                            <span>Agregar una nueva experiencia laboral</span>
                            </button>
                        </span>
                    </div>
                </div>
                <table class="table is-primary">
                    <thead>
                        <tr style="text-align:center;">
                            <th style="color:#000;">Inicio de actividades</th>
                            <th style="color:#000;">Término de actividades</th>
                            <th style="color:#000;">Cargo ejercido</th>
                            <th style="color:#000;">Trabajo actual</th>
                            <th style="color:#000;">Institución</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="color: #000;text-align:center;" v-for="fact in this.work_history" :key="fact.id">
                            <td> {{fact.start_date_work_activity}}</td>
                            <td> {{fact.end_date_work_activity}}</td>
                            <td> {{fact.role_played}}</td>
                            <td v-if="fact.actual_work"> Sí </td>
                            <td v-else> No </td>
                            <td> {{getPartnerEntityNameById(fact.partner_entity_id)}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-else>
            <h1>El egresado seleccionado no presenta una trayectoria laboral, haz click <a @click="addNewWorkHistory()">aquí</a> para agregar una nueva experiencia laboral</h1>
        </div>
    </div>
</template>
<script>
import axios from "axios"
export default {
    name: 'ShowWorkHistory',
    props:{
        work_history:{
            type: Array
        },
        work_history_found:{
            type: Boolean
        },
        partner_entities_information:{
            type: Array
        },
        graduated_id:{
            type: Number
        }
    },
    data () {
        return {
        }
    },
    methods:{
        getPartnerEntityNameById(id){
            var name = ''
            this.partner_entities_information.forEach(function(element){
                if (element.id == id)
                    name = element.name 
            })
            return name
        },
        addNewWorkHistory(){
            window.location.href="/work_history/new/"+this.graduated_id
        }
    }
}
</script>