<template>
  <section>
    <div >
      <div class="modal-card" style="width: auto" id="modal-nullable">
        <header class="modal-card-head">
          <p class="modal-card-title">{{modalHeader}}</p>
          <button
              type="button"
              class="delete"
              @click="$emit('close')"/>
        </header>
        <section class="modal-card-body" ref="modalConfirmDuplication">
          <div class="columns">
            <div class="column is-1">
              <b-icon
                  pack="fas"
                  icon="exclamation-triangle"
                  size="is-large"
                  type="is-danger">
              </b-icon>
            </div>
            <div class="column">
              <p><b>Relaciones asociadas a la función seleccionada.</b>. <br>
              </p>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <p>
                <b>Número de Asigna/Libera funciones asociados: </b><b>{{ num_person_functions }}</b><br>
              </p>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <p>
                <b>Número de Nombramientos asociados: </b><b>{{ num_person_units }}</b><br>
              </p>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="control" v-if="functions_prop.length>0">
                <p><b>¿Desea anular la función y reemplazarla en las entidades relacionadas?</b></p>
              </div>
              <div class="control" v-else>
                <p><b>¿Desea anular la función?</b></p>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <p><b>Al anular sin reemplazar, debe reemplazar manualmente las funciones de las asociaciones, ya sea asigna/libera funciones o de los nombramientos correspondientes</b></p>
            </div>
          </div>
          <div class="columns" v-if="functions_prop.length>0">
            <div class="column">
              <b-field label="">
                <template #label>
                  Función a reemplazar
                  <b-tooltip
                      label="Solo completar este campo si la funcion si desea reemplazar la función que está anulando"
                      position="is-right"
                      size="is-medium"
                      multilined>

                    <b-icon
                        pack="fas"
                        icon="info-circle"
                        size="is-small"
                        type="is-info">
                    </b-icon>
                  </b-tooltip>
                </template>
                <multiselect v-model="selected_replace_id"
                             :options="functions_prop.map(type => type.id)"
                             :custom-label="opt => functions_prop.find(x => x.id === opt).name"
                             placeholder="Seleccione función para reemplazar"
                             selectLabel="Presione para seleccionar"
                             selectedLabel="Seleccionado"
                             deselectLabel="No se puede deseleccionar"
                             :allow-empty="false">
                  <template v-slot:noOptions>
                    No existen datos
                  </template>
                  <span slot="noResult">
                      No se encontraron elementos.
                    </span>
                </multiselect>
              </b-field>
            </div>
          </div>
        </section>
      </div>
      <footer class="modal-card-foot actions">
        <div class="field is-grouped is-pulled-right">
          <button class="button is-warning" type="button" @click="$emit('close')">Cancelar</button>
          <button class="button is-danger" @click="null_all" >Anular sin reemplazar </button>
          <b-tooltip label="Al anular la función, esta ya no será seleccionable para asociarla a Asigna/Libera funciones o nombramientos"
                     position="is-top">
            <button class="button is-success" @click="replace" :disabled="functions_prop.length===0">Si, reemplazar </button>
          </b-tooltip>
        </div>
      </footer>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "modal_confirm_nullify",
  props:[
    'personId', 'id', 'modalHeader', 'functions', 'num_person_functions', 'num_person_units'
  ],
  data(){
    return {
      isLoading:false,
      disabled: false,
      selected_replace_id: null,
      functions_prop: []
    }
  },
  created() {
    this.isLoading=true
    this.functions_prop = this.functions
    this.isLoading=false
  },
  methods: {
    null_all(){
      this.execNullifyModal(this.id, 1, null)
    },
    replace(){
      if(this.selected_replace_id){
        this.execNullifyModal(this.id, 2, this.selected_replace_id)
      }
      else{
        this.$buefy.dialog.confirm({
          hasIcon: true,
          type: "is-warning",
          title: "Advertencia",
          message: "Para seleccionar esta opción, debe escoger la función de reemplazo",
        })
      }
    },
    execNullifyModal(id, type, selected_replace_id){
      this.$buefy.dialog.confirm({
        title: 'Anular función',
        message: '¿Está seguro que desea <b>anular</b> la función? Esta acción la dejará inválida.<br>',
        confirmText: 'Anular Función',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/functions/" + id + "/nullify-function.json", {params: {type: type, selected_replace_id: selected_replace_id}})
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Función anulada correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                this.isLoading = false;
                this.isNullifyActive = false;
                this.$emit('close')
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Función no pudo ser anulada</b><br>'+error.response.data["message"],
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },
  }
}
</script>