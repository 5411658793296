<template>
  <section>
    <div class="container">
      <b-table
          :data="actividades"
          :paginated=true
          per-page=10
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="publication.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field= 'name'
            label= 'Nombre actividad'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            <a @click="goToActivity(props.row.id)"><i class="fas fa-calendar"></i> {{ props.row.name }}</a>
          </template>
        </b-table-column>
        <b-table-column
            field= 'theme'
            label= 'Tema'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.theme }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'vime_activity_type_name.name'
            label= 'tipo de actividad'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.vime_activity_type.name }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'start_date'
            label= 'Fecha de inicio'
            searchable :custom-search="searchStart"
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL") : '' }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'end_date'
            label= 'Fecha de término'
            searchable :custom-search="searchEnd"
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL") : '' }}
          </template>
        </b-table-column>
        <template slot="empty" slot-scope="props">
          <div class="columns is-centered">
            <div class="column is-6 is-centered has-text-centered">
              <b-message v-if="advanceSearch != true" type="is-danger">
                No se encontraron resultados.
              </b-message>
              <b-message v-else type="is-danger">
                No se encontraron resultados para los criterios de búsqueda seleccionados.
              </b-message>
            </div>
          </div>
        </template>
      </b-table>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
    </div>
  </section>
</template>
<script>
import axios from 'axios'
import jsPDF from 'jspdf'
import VimeActivityAdvancedSearch from './vime_activity_advanced_search.vue'
import {searchDate} from "../../../../packs/utilities";
import moment from "moment";
export default {
  props:["actividades","advanceSearch"],
  data(){
    return {
      isLoading:false,
      currentPage:1,
      activities: [],
      activity_types: [],
      filteredActivities: [],
      applyFilter: 0,
      months: ['Enero', 'Febrero', 'Marzo', 'Abril',
        'Mayo', 'Junio', 'Julio', 'Agosto',
        'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      month: ''
    }
  },
  created(){
    this.isLoading=true;
    this.activities = this.actividades;
    this.activities = this.activities.map(activities => {
      var newActivities = activities
      newActivities["start_date"] = moment(newActivities["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
      newActivities["end_date"] = moment(newActivities["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
      return newActivities;
    });
    this.filteredActivities = this.actividades;
    this.filteredActivities = this.filteredActivities.map(activities => {
      var newActivities = activities
      newActivities["start_date"] = moment(newActivities["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
      newActivities["end_date"] = moment(newActivities["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
      return newActivities;
    });
    this.isLoading=false;

  },
  computed:{
    checkFilteredActivities(){
      return Object.entries(this.filteredActivities).length === 0
    }
  },
  methods:{
    newActivity(){
      this.isLoading=true;
      window.location.href="/vime_activities/new"
    },
    goToActivity(activity_id){
      this.isLoading=true;
      window.location.href="/vime_activities/"+activity_id
    },
    dateFormater(date){
      var newDate = ""
      var localDate = new Date(date).toLocaleDateString().toString();
      for(var i =0; i<localDate.length; i++){
        if(localDate[i] === '-'){
          newDate = newDate.concat('/')
        }
        else{
          newDate = newDate.concat(localDate.charAt(i))
        }
      }
      return newDate
    },
    advancedSearch(){
      this.$buefy.modal.open({
        parent: this,
        component: VimeActivityAdvancedSearch,
        hasModalCard: true,
        events:{
          'advanced-search-activity-filters': this.advancedSearchFilter
        },
        customClass: 'is-primary',
        trapFocus: true
      })
    },
    advancedSearchFilter(filters){
      this.isLoading=true
      axios.post('/vime_activities_get_activities_by_year_and_month', {
        year: filters.year,
        month: filters.month
      })
          .then( response =>{
            this.filteredActivities = response.data.activities
            this.month = this.months[filters.month - 1]
            if(this.checkFilteredActivities){
              this.$buefy.dialog.alert({
                title: "Búsqueda de actividades.",
                message: "No se han encontrado actividades para el año y mes definido."
              })
              this.filteredActivities = this.activities
              this.applyFilter = 0
              this.isLoading=false
            }else{
              this.applyFilter = 1
              this.isLoading=false
            }
          })
          .catch(e => {
            console.log(e)
            this.applyFilter = 0
            this.isLoading=false
          })
    },
    removeFilters(){
      this.filteredActivities = this.activities
      this.applyFilter = 0
    },
    searchStart(row,input){
      return searchDate(row.start_date,input)
    },
    searchEnd(row,input){
      return searchDate(row.end_date,input)
    },

    downloadCalendar(){
      // Declaración del PDF
      var doc = new jsPDF('p','pt');
      doc.setFillColor(253, 126, 20);
      doc.rect(0, 0, 600, 10, "F");
      doc.rect(0, 832, 600, 10, "F");
      doc.addImage("/logo_negro.png", "JPEG", 20, 20, 150, 80);

      // Título del calendario
      doc.setFont("times new roman", "bold");
      doc.text("Calendario de actividades VIME mes "+this.month, 120, 130);
      doc.setFont("times new roman", "normal");

      // Encabezados tabla
      var columnsNames = [
        {title: 'Nombre', dataKey: 'name'},
        {title: 'Tema', dataKey: 'theme'},
        {title: 'Tipo de actividad', dataKey: 'activity_type'},
        {title: 'Fecha inicio', dataKey: 'start_date'},
        {title: 'Fecha término', dataKey: 'end_date'},
        {title: '¿Internacional?', dataKey: 'is_national'}
      ]

      // Información de la tabla
      var tableData = []
      var nationalOrNot = ''
      this.filteredActivities.forEach(function(element){
        if(element.is_national){
          nationalOrNot = 'SI'
        }
        else{
          nationalOrNot = 'NO'
        }
        tableData.push({
          name: element.name,
          theme: element.theme,
          activity_type: element.vime_activity_type_name,
          start_date: element.start_date,
          end_date: element.end_date,
          is_national: nationalOrNot
        })
      })
      // Generación del PDF
      doc.autoTable(columnsNames, tableData, {
        headStyles:{
          halign: 'center',
          fillColor: [253, 126, 20],
        },
        bodyStyles:{
          halign: 'center'
        },
        margin: { top: 180 },
        didDrawPage: function (data) {
          doc.setFillColor(253, 126, 20);
          doc.rect(0, 0, 600, 10, "F");
          doc.rect(0, 832, 600, 10, "F");
          doc.addImage("/logo_negro.png", "JPEG", 20, 20, 150, 80);
        }
      })
      // Descarga
      doc.save('Calendario de actividades VIME mes '+this.month+'.pdf');
    },
  }
}
</script>