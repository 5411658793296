<template>
  <section>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <b-table
            :data="entities"
            :paginated=true
            :card-layout="cardLayout"
            per-page=10
            striped
            hoverable
            :current-page="currentPage"
            :pagination-simple=false
            pagination-position="bottom"
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            default-sort="financing.year"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"
        >
          <b-table-column
              field= 'name'
              label= 'Nombre'
              searchable
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.name }}
            </template>
          </b-table-column>
          <b-table-column
              field= 'association_type.name'
              label= 'Tipo de Asociación'
              searchable
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.association_type ? props.row.association_type.name : ''}}
            </template>
          </b-table-column>
          <b-table-column
              field= 'contact_name'
              label= 'Nombre Contacto'
              searchable
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.contact_name}}
            </template>
          </b-table-column>
          <b-table-column
              field= 'contact_mail'
              label= 'Email de Contacto'
              searchable
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.contact_mail}}
            </template>
          </b-table-column>

          <template slot="empty" slot-scope="props">
            <div class="columns is-centered">
              <div class="column is-6 is-centered has-text-centered">
                <b-message type="is-danger">
                  No se encontraron resultados.
                </b-message>
              </div>
            </div>
          </template>
        </b-table>
      </section>
    </div><footer class="modal-card-foot actions">
    <div class="has-text-right is-grouped">
      <button class="button" type="button" @click="$emit('close')">Cerrar</button>
    </div>
  </footer>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
export default {
  name: "modal_postulated_entities",
  props:[
    "id", "modalHeader", "entities"
  ],
  data(){
    return {
      isLoading: false,
      isEditModalActive:false,
      currentPage:1,
      cardLayout:false,
    }
  },
  created() {
  },
  methods: {
  },
}
</script>