<template>
  <section>
      <section>

        <div class="columns">
          <div class="column">
            <b-field label="Descripción">
              <b-input :value="person_unit.description" type="textarea" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Tipo de documento">
              <b-input :value="person_unit.resolution_type!==undefined ? person_unit.resolution_type.name : ''" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="N° de documento">
              <b-input :value="person_unit.resolution" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha documento">
              <b-input :value="formatDate(person_unit.resolution_date)" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Unidad de contratación">
              <b-input :value="person_unit.contracting_unit!==undefined ? person_unit.contracting_unit.name : ''" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Unidad de desempeño">
              <b-input :value="person_unit.unit!==undefined? person_unit.unit.name : ''" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
            <div class="column">
              <b-field label="Calidad">
                <b-input :value="person_unit.quality!==undefined? person_unit.quality : ''" type="text" readonly></b-input>
              </b-field>
            </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Labor">
              <b-input :value="person_unit.labour!==undefined? person_unit.labour : ''" type="textarea" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Descripción de Labor que Realiza">
              <b-input :value="person_unit.labour_description!==undefined? person_unit.labour_description : ''" type="textarea" readonly></b-input>

            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Lugar donde realiza el trabajo">
              <b-input :value="person_unit.labour_place!==undefined? person_unit.labour_place : ''" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha desde">
              <b-input :value="formatDate(person_unit.start_date)" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha hasta">
              <b-input :value="formatDate(person_unit.end_date)" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Horario">
              <b-input :value="person_unit.schedule!==undefined? person_unit.schedule : ''" type="text" readonly expanded></b-input>
              <p class="control">
                <button class="button is-primary" @click.prevent="showSchedule">Ver Horario </button>
              </p>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Monto">
              <div class="input">
                <money-format :value="person_unit.amount"
                              locale='es-CL'
                              currency-code='CLP'
                              :subunits-value=true
                >
                </money-format>
              </div>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Total Convenio">
              <div class="input">
                <money-format :value="person_unit.total_amount!==undefined? person_unit.total_amount : 0"
                              locale='es-CL'
                              currency-code='CLP'
                              :subunits-value=true
                >
                </money-format>
              </div>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Forma de Pago">
              <b-input :value="person_unit.payment!==undefined? person_unit.payment : ''" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Ítem Imputación Presupuestaria">
              <b-input :value="person_unit.item!==undefined? person_unit.item : ''" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="CC Imputación Presupuestaria">
              <b-input :value="person_unit.budget_allocation_cc!==undefined? person_unit.budget_allocation_cc : ''" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
<!--        <div class="columns">
          <div class="column">
            <b-field label="Url Documentación">
              <b-input expanded readonly v-model="person_unit.documentation_link" type="text"></b-input>
              <p class="control">
                <b-button tag="a" :href="person_unit.documentation_link" target="_blank" icon-left="external-link-alt" type="is-link">Link</b-button>
              </p>
            </b-field>
          </div>
        </div>-->
      </section>


    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

    <b-modal
        v-model="isWeeklyModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal-weekly-schedule
            name="planificacion_horario"
            :person-id="person_unit.person_id"
            :horarios="schedule"
            :intervalo="interval"
            :stringHorario="stringSchedule"
            :scheduleDays="scheduleDays"
            query-type="create"
            modal-header="Ver Horario Semanal"
            @close="props.close">
        </modal-weekly-schedule>
      </template>
    </b-modal>
  </section>
</template>
<script>
import axios from "axios";
import DistanceForm from "../life_record/modal_distance_form"
import AppointmentForm from "../life_record/modal_feecontract_appointment_form"
import ModalWeeklySchedule from "../life_record/modal_weekly_schedule"
import Distance from "../life_record/distance"
import MoneyFormat from 'vue-money-format'
import {formatDate} from "../../packs/utilities";

export default {
  name: "modal_feecontract_appointment",
  props:[
    'queryType', 'personId', 'id', 'modalHeader'
  ],
  components:{
    DistanceForm,
    ModalWeeklySchedule,
    AppointmentForm,
    Distance,
    'money-format': MoneyFormat
  },
  data(){
    return {
      person_unit:{

      },
      years:[],
      appointment_types:[],
      unit_dependences: false,
      work_plants:[],
      functions: [],
      function_name: null,
      resolution_types:[],
      units: [],
      contract_units:[],
      grades: [],
      levels: [],
      working_days:[],
      errores_validacion:{},
      documentation_link:'',
      establishments:[],
      isLoading: false,
      isDistanceModalActive: false,
      isEditModalActive: false,
      isLinkedDistanceModalActive: false,
      link: false,
      distances: [],
      schedule: {},
      interval: 60,
      scheduleDays: [],
      stringSchedule: "",
      schedule: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: []
      },
      isWeeklyModalActive: false,
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize(){
      this.isLoading=true;
      console.log("initializeen show fee contract")
      axios
          .get("/admin/person_units/"+this.id+".json")
          .then(res => {
            this.person_unit = res.data
            if(this.person_unit.linked_distance_id){
              this.link = true
            }
            this.schedule = JSON.parse(this.person_unit.text_schedule)
            if(this.person_unit.appointment_days.length!==0){
              this.interval = this.person_unit.appointment_days[0].interval
              console.log("INTERVALO: ", this.interval)
              console.log("INTERVALO: ", this.person_unit.appointment_days[0].interval)
            }
            this.stringSchedule = res.data["schedule"]
            console.log("FIN INITIALIZE")
            console.log(this.person_unit)
            this.isLoading = false
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
      //this.isLoading=false;
    },
    showSchedule(){
      this.isWeeklyModalActive = true
    },
    functionLink(){
      if(this.link===true){
        this.link = false
        this.person_unit.linked_distance_id = null
      }
      else{
        this.link = true
      }
    },
    deleteData(person_unit){
      delete person_unit.units
      delete person_unit.work_plants
      delete person_unit.functions
      delete person_unit.appointment_types
      delete person_unit.levels
      delete person_unit.grades
      return person_unit
    },
    openLinkedDistance(linkedDistance){
      this.selected = linkedDistance.id;
      this.showName = "Distance"
      this.title = "Alejamiento"
      //console.log("APPOINTMENT "+ this.showName)
      this.isLinkedDistanceModalActive = true;
    },
    formatDate,
  },
  watch: {
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
    isDistanceModalActive: function () {
      if (!this.isDistanceModalActive) {
        this.initialize()
      }
    },
  }

}
</script>

<style scoped>

</style>