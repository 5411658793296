<template>
    <div class="showgeneralinformation">
        <div class="columns">
            <div class="column">
                <table class="table is-primary">
                    <thead>
                        <tr style="text-align:center;">
                        <th colspan="2" style="color:#000;"> Información general </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="color: #000;text-align:center;">
                            <td><strong>Género:</strong></td>
                            <td v-if="this.graduated.sex == null"> No se ha registrado. </td>
                            <td v-else> {{this.graduated.sex}} </td>
                        </tr>
                        <tr style="color: #000;text-align:center;">
                            <td><strong>Carrera cursada:</strong></td>
                            <td> No se ha registrado. </td>
                        </tr>
                        <tr style="color: #000;text-align:center;">
                            <td><strong>Ciudad:</strong></td>
                            <td> Por definir </td>
                        </tr>
                        <tr style="color: #000;text-align:center;">
                            <td><strong>Celular:</strong></td>
                            <td> {{ this.graduated.cellphone}} </td>
                        </tr>
                        <tr style="color: #000;text-align:center;">
                            <td><strong>Teléfono:</strong></td>
                            <td v-if="this.graduated.phone != null"> {{ this.graduated.phone}} </td>
                            <td v-else> No se encuentra registrado. </td>
                        </tr>
                        <tr style="color: #000;text-align:center;">
                            <td><strong>Correo personal:</strong></td>
                            <td v-if="this.graduated.personal_mail != null"> {{ this.graduated.personal_mail}} </td>
                            <td v-else> No se encuentra registrado. </td>
                        </tr>
                        <tr style="color: #000;text-align:center;">
                        <td><strong>Correo corporativo:</strong></td>
                            <td v-if="this.graduated.corporate_mail != null"> {{ this.graduated.corporate_mail}} </td>
                            <td v-else> No se encuentra registrado. </td>
                        </tr>
                        <tr style="color: #000;text-align:center;">
                            <td><strong>¿Egresado de la usach?</strong></td>
                            <td v-if="this.graduated.is_usach_graduate"> Sí </td>
                            <td v-else> No </td>
                        </tr>
                        <tr style="color: #000;text-align:center;">
                            <td><strong>Fecha de ingreso:</strong></td>
                            <td v-if="this.graduated.admission_date != null"> {{ this.graduated.admission_date}} </td>
                            <td v-else> No se encuentran registros de esta fecha. </td>
                        </tr>
                        <tr style="color: #000;text-align:center;">
                            <td><strong>Fecha de egreso:</strong></td>
                            <td v-if="this.graduated.graduate_date != null"> {{ this.graduated.graduate_date}} </td>
                            <td v-else> No se encuentran regitros de esta fecha. </td>
                        </tr>
                        <tr style="color: #000;text-align:center;">
                            <td><strong>Fecha de titulación:</strong></td>
                            <td v-if="this.graduated.titulation_date != null"> {{ this.graduated.titulation_date}} </td>
                            <td v-else> No se encuentra registrado. </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="column">
                <ShowSocialNetworks :graduated='this.graduated' :social_networks='this.social_networks'/>
            </div>
        </div>
    </div>
</template>
<script>
import ShowSocialNetworks from './show_social_networks.vue'
export default {
    name: 'ShowGeneralInformation',
    components:{
        ShowSocialNetworks
    },
    props:{
        graduated: {
            type: Object
        },
        social_networks:{
            type: Array,
            default: []
        }
    },
    data () {
        return {
            
        }
    }
}
</script>