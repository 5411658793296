<template>
    <section>

        <div class="container">
            <div class="box">

                <div class="columns">
                    <div class="column has-text-centered">
                        <h1 class="is-size-4 has-text-weight-bold has-text-centered">Seleccione Tipo de Publicación</h1>
                    </div>
                </div>
                <b-tabs position="is-centered" type="is-toggle" v-model="showTab" @input="handleTabChangePub">

                    <b-tab-item label="Publicación en Revista" icon="newspaper">
                        <NewPublicationFormVue :key="key_publication" :user-id="userId" :person-id="personId" :unidad-principal="unidadPrincipal" :cargoPrincipal="cargoPrincipal" query-type="create"/>
                    </b-tab-item>

                    <b-tab-item label="Publicación en Libro" icon="book">

                        <b-tabs position="is-centered" @input="handleTabChange">

                            <b-tab-item label="Libro" icon="book">
                                <NewBookForm :key="key_element" :user-id="userId" :person-id="personId" :unidad-principal="unidadPrincipal" :cargoPrincipal="cargoPrincipal" query-type="create"/>
                            </b-tab-item>

                            <b-tab-item label="Capítulo de Libro" icon="book-open" >
                              <NewInbookFormVue :key="key_element" publication-type-id="chapter" :user-id="userId" :person-id="personId" :unidad-principal="unidadPrincipal" :cargoPrincipal="cargoPrincipal" query-type="create"/>
                            </b-tab-item>


                            <b-tab-item label="Artículo de Libro" icon="file-alt">
                                <NewInbookFormVue :key="key_element" publication-type-id="article" :user-id="userId" :person-id="personId" :unidad-principal="unidadPrincipal" :cargoPrincipal="cargoPrincipal" query-type="create"/>
                            </b-tab-item>

                        </b-tabs>

                    </b-tab-item>

                    <b-tab-item label="Publicación en Congreso" disabled/>

                </b-tabs>


            </div>
        </div>

    </section>
</template>

<script>
import NewBookForm from '../books/NewBookForm.vue'
import NewInbookFormVue from '../books/NewInbookForm.vue'
import NewPublicationFormVue from './NewPublicationForm.vue'
export default {

    props: ['userId', 'personId', 'unidadPrincipal', 'cargoPrincipal'],

    components: {
        NewBookForm, NewInbookFormVue, NewPublicationFormVue,
    },

    data () {
        return {
            showBook: false,
            showTab: null,
            key_element: 0,
            key_publication: 0,
        }
    },
  methods: {
      handleTabChange(){
        this.key_element = this.key_element + 1
      },
      handleTabChangePub(){
        this.key_publication = this.key_publication + 1
      }
  }

}
</script>