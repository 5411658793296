var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('h3',{staticClass:"has-text-centered is-size-6 has-text-weight-semibold has-background-primary-light",style:({
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '0.5rem 0.5rem 0rem 0rem',
      borderColor: '#e5e7eb',
    })},[_vm._v("\n    "+_vm._s(_vm.title)+"\n    "),_c('div',{staticClass:"has-text-centered is-size-7 has-text-weight-semibold has-background-primary-light"},[_vm._v("\n      Año: "+_vm._s(_vm.currentYear)+"\n    ")])]),_vm._v(" "),_c('div',{staticClass:"level is-mobile",style:({
      borderWidth: '1px',
      borderStyle: 'solid',
      borderTop: 'none',
      borderRadius: '0rem 0rem 0.5rem 0.5rem',
      borderColor: '#e5e7eb',
      padding: '1rem',
    })},[_c('div',{staticClass:"level-item has-text-centered"},[(_vm.loading)?_c('div',[_vm._m(0),_vm._v(" "),_c('b-icon',{attrs:{"pack":"fas","icon":"sync-alt","size":"is-medium","custom-class":"fa-spin"}})],1):_c('div',[_vm._m(1),_vm._v(" "),_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.usedDays))])])]),_vm._v(" "),_c('div',{staticClass:"level-item has-text-centered"},[(_vm.loading)?_c('div',[_vm._m(2),_vm._v(" "),_c('b-icon',{attrs:{"pack":"fas","icon":"sync-alt","size":"is-medium","custom-class":"fa-spin"}})],1):_c('div',[_vm._m(3),_vm._v(" "),_c('p',{staticClass:"title",class:_vm.changeTextColor(_vm.remainingDays)},[_vm._v("\n          "+_vm._s(_vm.usedDays > 6 ? 0 : _vm.remainingDays)+"\n        ")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"heading"},[_vm._v("\n          Días "),_c('br'),_vm._v("\n          utilizados\n        ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"heading"},[_vm._v("\n          Días "),_c('br'),_vm._v("\n          utilizados\n        ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"heading"},[_vm._v("\n          Días "),_c('br'),_vm._v("\n          restantes\n        ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"heading"},[_vm._v("\n          Días "),_c('br'),_vm._v("\n          restantes\n        ")])}]

export { render, staticRenderFns }