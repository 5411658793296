<template>
  <div class="container">
    <div class="box">
      <AdministrativePermitsBreadcrums :breadcrumbs="breadcrumbs" />
      <h2 class="subtitle">Permisos administrativos:</h2>
      <h1 class="title">Gestor de Permisos</h1>
      <AdministrativePermitsTable
        admin-mode
        :administrative-permits="administrativePermits"
        :permit-states="permitStates"
        :modal-form-status="modalFormStatus"
        @handleEditPermits="onEditPermits"
        @handleDownload="downloadPdf"
      >
        <template #filters>
          <div class="m-3">
            <AdministrativePermitsDateFilter
              class="column is-narrow is-full-mobile"
              @filter="getAllAdministrativePermitsWithDates"
            />
          </div>
        </template>
      </AdministrativePermitsTable>

      <b-loading v-model="isLoading" :is-full-page="true" :can-cancel="false" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AdministrativePermitsBreadcrums from "./AdministrativePermitsBreadcrums.vue";
import AdministrativePermitsTable from "./AdministrativePermitsTable.vue";
import AdministrativePermitsDateFilter from "./AdministrativePermitsDateFilter.vue";
import { handleNetworkError } from "../.././packs/utilities";

export default {
  components: {
    AdministrativePermitsBreadcrums,
    AdministrativePermitsTable,
    AdministrativePermitsDateFilter,
  },
  props: {
    permitStates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      breadcrumbs: [
        { href: "/life_record", label: "Menú principal" },
        { href: "/administrative-permits", label: "Permisos administrativos" },
        {
          href: "/administrative-permits/manage-permits",
          label: "Gestor de Permisos",
          isActive: true,
        },
      ],
      isLoading: false,
      modalFormStatus: false,
      administrativePermits: [],
    };
  },
  async created() {
    await this.getAllAdministrativePermitsWithDates({ costCenter: 40 });
  },
  methods: {
    handleNetworkError,
    async getAllAdministrativePermitsWithDates({ startDate, endDate }) {
      this.isLoading = true;
      try {
        const response = await axios.get(
          "/administrative-permits/permits.json",
          {
            params: {
              start_date: startDate,
              end_date: endDate,
              cost_center: 40,
            },
          }
        );
        if (response.status === 200 && response.data) {
          this.administrativePermits = response.data;
          if (response.data.length === 0) {
            this.$buefy.toast.open({
              message:
                "No se encontraron solicitudes de permisos administrativos para el rango de fechas seleccionado.",
              type: "is-danger",
              size: "is-medium",
              "has-icon": true,
              position: "is-bottom-right",
              "progress-bar": true,
              duration: 5000,
              queue: true,
            });
          } else {
            this.$buefy.toast.open({
              message: `Se encontraron ${response.data.length} solicitudes de permisos administrativos para el rango de fechas seleccionado.`,
              type: "is-success",
              size: "is-medium",
              "has-icon": true,
              position: "is-bottom-right",
              "progress-bar": true,
              duration: 5000,
              queue: true,
            });
          }
        }
      } catch (error) {
        if (this.handleNetworkError(error)) {
          return;
        }
        const errorMessage =
          error.response && error.response.data.errors
            ? error.response.data.errors
            : "Ocurrió un error al obtener los permisos administrativos";

        this.$buefy.notification.open({
          message: errorMessage,
          type: "is-danger",
          size: "is-medium",
          "has-icon": true,
          position: "is-bottom-right",
          indefinite: true,
          queue: true,
          role: "alert",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async downloadPdf({ documentId, personId }) {
      this.isLoading = true;
      try {
        const response = await axios.get(
          `/administrative-permits/${personId}/permits-pdf/${documentId}.pdf`,
          { responseType: "blob" }
        );

        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        const filename = this.extractFilenameFromHeaders(response.headers);

        link.href = url;
        link.setAttribute("download", `${filename}.pdf`);
        link.click();
        this.$buefy.notification.open({
          message: `Se ha generado el permiso administrativo (IDS ${documentId}) exitosamente`,
          type: "is-success",
          size: "is-medium",
          "has-icon": true,
          position: "is-bottom-right",
          indefinite: true,
          "progress-bar": true,
          queue: true,
        });
      } catch (error) {
        if (this.handleNetworkError(error)) {
          return;
        }
        const errorMessage =
          error.response && error.response.data.errors
            ? error.response.data.errors
            : "Ocurrió un error al generar el permiso administrativo";

        this.$buefy.notification.open({
          message: errorMessage,
          type: "is-danger",
          size: "is-medium",
          "has-icon": true,
          position: "is-bottom-right",
          queue: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
    extractFilenameFromHeaders(headers) {
      const contentDisposition = headers["content-disposition"];
      const match = contentDisposition.match(/filename="(.+?)"/);

      if (match && match[1]) {
        return decodeURIComponent(match[1]);
      }

      return null;
    },

    async onEditPermits({ personId, editedPermits, documentId }) {
      this.isLoading = true;
      this.modalFormStatus = false;

      try {
        const response = await axios.put(
          `/administrative-permits/${personId}/permits.json`,
          {
            person_id: personId,
            permits: editedPermits,
            document_id: documentId,
          }
        );
        if (response.status === 200) {
          const permitPosition = this.administrativePermits.findIndex(
            (permit) => {
              if (
                permit.adm_permit_doc.id === response.data.adm_permit_doc.id
              ) {
                return response.data;
              }
            }
          );
          this.administrativePermits.splice(permitPosition, 1, response.data);

          this.$buefy.notification.open({
            message: "Se ha actualizado el permiso administrativo exitosamente",
            type: "is-success",
            size: "is-medium",
            "has-icon": true,
            position: "is-bottom-right",
            "progress-bar": true,
            duration: 5000,
            queue: true,
          });
          this.modalFormStatus = true;
        }
      } catch (error) {
        if (this.handleNetworkError(error)) {
          return;
        }
        const errorMessage =
          error.response && error.response.data.errors
            ? error.response.data.errors
            : "Ocurrió un error al actualizar el permiso administrativo";

        this.$buefy.notification.open({
          message: errorMessage,
          type: "is-danger",
          size: "is-medium",
          "has-icon": true,
          position: "is-bottom-right",
          indefinite: true,
          queue: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 767px) {
  .container {
    padding: 0;
  }
  .box {
    border-radius: 0%;
    box-shadow: none;
    min-height: calc(100vh - 64px);
    background-color: white;
  }
  .title {
    font-size: 1.5rem;
  }
  .subtitle {
    font-size: 1.2rem;
  }
  .position {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .box {
    border-radius: 0%;
    box-shadow: none;
    min-height: calc(100vh - 64px);
    background-color: white;
  }
}

@media (min-width: 1024px) {
  .container {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .position {
    width: 50%;
  }
}
</style>
