<template>
    <div class="agreementpartnerentities">
        <div class="container">
            <div v-if="can_edit" class="columns">
                <div class="column">
                    <span class="is-pulled-right">
                        <button class="button is-primary" @click="newParticipant()">
                            <b-icon icon="plus"></b-icon>
                            <span>Relacionar otra entidad</span>
                        </button>
                    </span>
                </div>
            </div>
          <div class="columns">
            <div class="column has-text-right">
              <b-field>
                <b-switch v-model="cardLayout">
                      <span>
                        Formato tarjeta (sin filtros ni búsqueda)
                         <b-tooltip
                             label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                             multilined
                             position="is-left">
                              <b-icon
                                  pack="fas"
                                  icon="info-circle"
                                  type="is-info">
                              </b-icon>
                          </b-tooltip>
                      </span>
                </b-switch>
              </b-field>
            </div>
          </div>
        </div>
        <br>
        <b-table
            :data="agreement_entity"
            :paginated=true
            per-page=10
            :current-page="currentPage"
            :card-layout="cardLayout"
            :pagination-simple=false
            pagination-position="bottom"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual">
            <b-table-column
                field= 'partner_entity_data.name'
                label= 'Entidad'
                searchable
                sortable>
              <template
                  slot="searchable"
                  slot-scope="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    icon="search"
                    size="is-small" />
              </template>
              <template v-slot="props">
                <a @click="goToEntity(props.row.partner_entity_data.id)"><i class="fas fa-industry"></i> {{ props.row.entity_name }}</a>

              </template>
            </b-table-column>
            <b-table-column
                field= 'entity_type_name'
                label= 'Tipo de entidad'
                sortable
                searchable
            >
              <template
                  slot="searchable"
                  slot-scope="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    icon="search"
                    size="is-small" />
              </template>
              <template v-slot="props">
                <div v-if="props.row.entity_type_name != null">{{ props.row.entity_type_name }}</div>
                <div v-else></div>
              </template>
            </b-table-column>
            <b-table-column
                field= 'applicant_email'
                label= 'Email'
                searchable
                sortable
            >
              <template
                  slot="searchable"
                  slot-scope="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    icon="search"
                    size="is-small" />
              </template>
              <template v-slot="props">
                <div v-if="props.row.partner_entity_data.email != null">{{ props.row.partner_entity_data.email }}</div>
                <div v-else></div>
              </template>
            </b-table-column>
            <b-table-column
                field= 'applicant_phone'
                label= 'Teléfono'
                searchable
                sortable
            >
              <template
                  slot="searchable"
                  slot-scope="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    icon="search"
                    size="is-small" />
              </template>
              <template v-slot="props">
                <div v-if="props.row.partner_entity_data.phone != null">{{ props.row.partner_entity_data.phone }}</div>
                <div v-else></div>
              </template>
            </b-table-column>
          <template slot="empty" slot-scope="props">
            <div class="columns is-centered">
              <div class="column is-6 is-centered has-text-centered">
                <b-message v-if="can_edit" has-icon type="is-warning">
                  <h1> No se encontraron resultados, haga click <a @click="newParticipant()">aquí</a> para relacionar entidades.</h1>
                </b-message>
                <b-message v-else has-icon type="is-warning">
                  <h1> No se encontraron resultados.</h1>
                </b-message>
              </div>
            </div>
          </template>
        </b-table>
    </div>
</template>
<script>
export default {
    name: 'AgreementPartnerEntities',
    props:{
        entities:{
            type: Array,
            default: () => []
        },
        agreement_id:{
            type: Number
        },
      can_edit:{
          type: Boolean
      }
    },
    data(){
        return {
            currentPage: 1,
            cardLayout: false,
            agreement_entity: []
        }
    },
    beforeMount(){
        this.agreement_entity = this.entities
        this.agreement_entity.forEach(element =>{
          if(element.applicant_phone != null){
            element.applicant_phone =  element.applicant_phone.toString(10)
          }
        })
    },
    methods:{
        goToEntity(id){
            window.location.href='/partner_entities/show/'+id
        },
        newParticipant(){
            window.location.href="/agreements/"+this.agreement_id+"/edit/3"
        }
    }
}
</script>