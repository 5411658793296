<template>
  <section>
    <div class="container">
      <h1 class="is-size-2">Libros</h1>
      <div class="box">
        <div class="columns">
          <div class="column">
            <b-notification type="is-info" class="is-light" has-icon aria-close-label="Cerrar notificación">
              <span>El filtro de editorial solo está disponible para el tipo de sección "LIBRO", los Capítulos y Artículos no presentan este atributo </span><br>
            </b-notification>
          </div>
        </div>
          <div class="has-text-right" v-if="can_create==='true'">

            <b-button type="button is-primary"
                      icon-left="plus"
                      tag="a"
                      href="/books/new">
                Añadir Libro
            </b-button>
            <!--
            <b-button type="button is-primary"
                      tag="a"
                      icon-left="plus"
                      href="/inbooks/new?type=article">
                Añadir Artículo
            </b-button>
            <b-button type="is-primary is-outlined"
                      tag="a"
                      icon-left="plus"
                      href="/inbooks/new?type=chapter">
                Añadir Capítulo
            </b-button>
            -->
          </div>
          
        
        
      <br>
      <books-table v-bind:libros="this.libros" :cargoPrincipal="cargoPrincipal" @updateBooks="updateBooks" @updateBooksFilter="updateBooksFilter"></books-table>
        
      </div>
     
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import axios from "axios";
import BooksTable from "./BooksTable.vue";
import moment from "moment";

export default {
  components:{BooksTable},
  data(){
    return{
      isLoading:false,
      currentPage:1,
      libros:[],
      year: new Date().getFullYear(),

    }
  },
  created(){
    this.getBooks(this.year);
  },
  props:[
    'can_create', 'cargoPrincipal'
  ],
  methods:{
    newBooks(){
      window.location.href="/books/new"
    },
    getBookUrl(book_id){
      return "/books/"+book_id;
    },
    getBooks(year){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/books.json", {params:{year_f:year,}})
        .then(res => {
          vm.libros = res.data;
          vm.libros = vm.libros.map(libro => {
            var newLibro = libro
            if(newLibro["publication_date"]!=null)
              newLibro["publication_date"] = moment(libro.publication_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            else
              newLibro["publication_date"] = moment(libro.fecha, ["YYYY-MM-DD","DD-MM-YYYY"]);
            return newLibro;
          });
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    getBooksFilter(year,title,editorial_id, type_publication){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/books.json", {params:{year_f:year,
                                              title:title,
                                              editorial:editorial_id,
                                              publication_type:type_publication,
                                              }})
        .then(res => {
          vm.libros = res.data;
          vm.libros = vm.libros.map(libro => {
            var newLibro = libro
            if(newLibro["publication_date"]!=null)
              newLibro["publication_date"] = moment(libro.publication_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            else
              newLibro["publication_date"] = moment(libro.fecha, ["YYYY-MM-DD","DD-MM-YYYY"]);
            return newLibro;
          });
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    updateBooks(year){
      this.getBooks(year);
    },
    updateBooksFilter(datos){
      this.getBooksFilter(datos["year"], datos["name"], datos["editorial"], datos["type"]);
    }

  },
  computed:{

  }
}
</script>

