<template>
  <section>
    <div class="container">

        <b-tabs @input="changeTab" position="is-centered" :destroy-on-hide="true" v-model="currentTab">
          <b-tab-item label="Asignación de Funciones" value="AssignedFunctions">
            <b-tabs @input="changeTab_1" :destroy-on-hide="true" v-model="currentTab_1" position="is-centered" type="is-toggle">
              <b-tab-item v-for="item in items_1"
                          :value="item.content"
                          v-bind:key="item.content"
                          :label="item.tab">
              </b-tab-item>
            </b-tabs>
            <!-- COMPONENTE AQUI -->

            <component v-bind:is="currentTabComponent_1" :person-id="personId" :can_assign_function="can_assign_function"
                       :person_name="person_name"></component>
          </b-tab-item>
          <b-tab-item label="Funciones Anuladas" value="NullFunctionsDistances" :visible="can_list_null === 'true'">
            <b-tabs @input="changeTab_3" :destroy-on-hide="true" v-model="currentTab_3" position="is-centered" type="is-toggle">
              <b-tab-item v-for="item in items_3"
                          :value="item.content"
                          v-bind:key="item.content"
                          :label="item.tab">
              </b-tab-item>
            </b-tabs>
            <!-- COMPONENTE AQUI -->

            <component v-bind:is="currentTabComponent_3" :person-id="personId" :can_assign_function="can_assign_function"
                       :person_name="person_name"></component>
          </b-tab-item>
        </b-tabs>


        <!-- FIN COMPONENTE -->

    </div>
  </section>
</template>
<script>
import Functions from "./functions"
import AdministrativeFunctions from './administrative_functions'
import AuthorityFunctions from './authority_functions'
import DistancedFunctions from "./distanced_functions"
import NullDistancedFunctions from "./null_distanced_functions"
import NullFunctions from "./null_functions"
import EventBus from "../../eventBus";
export default {
  name: "function_tabs",
  props:[
    'personId', 'can_assign_function', 'person_name', 'can_administrative_function', 'can_list_null'
  ],
  data(){
    return {
      currentTab: 'AssignedFunctions',
      currentTab_1: 'Functions',
      currentTab_2: 'AuthorityFunctions',
      currentTab_3: "NullFunctions",
      items_1: [
        {tab: 'Funciones Asignadas',content:'Functions'},
        {tab: 'Libera Funciones', content: "DistancedFunctions"},
        {tab: 'Funciones Directivos Superiores', content: "AuthorityFunctions"},
        {tab: 'Funciones Administrativas', content: "AdministrativeFunctions"},
      ],
      items_3:[
        {tab: 'Funciones Anuladas', content: "NullFunctions"},
        {tab: 'Libera Funciones Anulados', content: "NullDistancedFunctions"},
      ]
    }
  },
  components:{
    Functions,
    DistancedFunctions,
    AdministrativeFunctions,
    AuthorityFunctions,
    NullFunctions,
    NullDistancedFunctions
  },
  methods:{
    changeTab: function(value){
      this.currentTab = value
    },
    changeTab_1: function(value){
      this.currentTab_1 = value
    },
    selectTab_1(tab){
      this.currentTab_1 = tab
    },
    changeTab_3: function(value){
      this.currentTab_3 = value
    },
    selectTab_2(tab){
      this.currentTab_2 = tab
    }
  },
  computed: {
    currentTabComponent_1: function () {
      return this.currentTab_1
    },
    currentTabComponent_3: function () {
      return this.currentTab_3
    }
  },
  mounted () {
    EventBus.$on('FUNCION_LIBERADA', (payload) => {
      this.selectTab_1("DistancedFunctions")
    })
  }
}
</script>