<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveDistanceHouseholdAllowance)">
        <!-- Box de Formulario HouseholdAllowance -->
        <div class="modal-card" style="width: auto" id="modal-distance-person-function">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" id="modal-distance-household-allowance" ref="modalDistanceHouseholdAllowance">
            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion" :key='index'>
                      {{ item[0] }}
                    </div>
                  </b-message>
                </div>
              </div>
            </div>
            <div class="columns">
              <h3 class="is-size-3 has-text-weight-bold">Información Asignación Familiar</h3>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Nombre">
                  <b-input :value="person_name" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Primer Apellido">
                  <b-input :value="person_last_name" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Segundo Apellido">
                  <b-input :value="person_second_surname" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Rut">
                  <b-input :value="rut" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de Nacimiento">
                  <b-input :value="person_birthday_formatted" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <h3 class="is-size-3 has-text-weight-bold">Datos Cese de Asignación Familiar</h3>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Número de documento">
                  <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                    <b-input v-model="household_allowance.decree" type="text" @input="generateName"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de documento">
                  <b-datepicker
                      v-model="household_allowance.decree_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                      @input="generateName"
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Fecha cese de asignación*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-datepicker
                        v-model="household_allowance.start_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        :min-date="household_start_date"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                        @input="generateName"

                    >
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
<!--            <div class="columns">
              <div class="column">
                <b-field label="Url carpeta documentación Cese de Asignación">
                  <ValidationProvider v-slot="{ errors }" rules="max:255">
                    <b-input v-model="household_allowance.documentation_link" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>-->
            <div class="columns">
              <div class="column">
                <b-field label="Observaciones">
                  <b-input v-model="household_allowance.observations" type="textarea" minlength="1" maxlength="255"></b-input>
                </b-field>
              </div>
            </div>

            <div v-if="queryType == 'update'" class="columns">
              <div class="column is-4">
                <b-field label="Nombre del documento">
                  <b-input v-model="document_name_update" type="text" :disabled="true"></b-input>
                </b-field>
              </div>
              <div class="column is-4">
                <b-field label="¿Desea cambiar o agregar un archivo?">
                  <b-radio-button v-model="changeFile"
                                  :native-value="true"
                                  type="is-success is-light is-outlined">
                    <b-icon icon="check-circle"></b-icon>
                    <span>Si</span>
                  </b-radio-button>
                  <b-radio-button v-model="changeFile"
                                  :native-value="false"
                                  type="is-danger is-light is-outlined">
                    <b-icon icon="times-circle"></b-icon>
                    <span>No</span>
                  </b-radio-button>
                </b-field>
              </div>
            </div>
            <div v-if="queryType == 'create' || changeFile" class="columns has-text-centered">
              <div class="column">
                <b-field extended label="Archivo PDF (Tamaño max. 25 MB)">
                  <b-upload v-model="file"
                            drag-drop
                            type="is-primary"
                            accept=".pdf"
                            @input="generateName">
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon
                              icon="file-pdf"
                              size="is-large">
                          </b-icon>
                        </p>
                        <p>Arrastra tu PDF o haz click para subir.</p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>
              </div>
            </div>
            <div v-if="queryType == 'create' || changeFile" class="columns tags is-centered">
              <span
                  v-if="file !== null"
                  v-model="file"
                  class="tag is-primary mt-1">
                {{ file.name }}
                <button
                    class="delete is-small"
                    type="button"
                    @click="onDeleteFile"
                ></button>
              </span>
            </div>

            <div v-if="file !== null" class="columns">
              <div class="column">
                <b-field label="Nombre del documento a subir">
                  <b-input disabled v-model="document_name"></b-input>
                </b-field>
              </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit">Guardar </button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios"
import moment from "moment";
import EventBus from "../../eventBus";
export default {
  name: "modal_distance_household_form",
  props: [
    'queryType', 'personId', 'id','modalHeader', 'household_allowance_id', 'person_name', 'rut', 'person_last_name','person_second_surname',
      'person_birthday', 'old_start_date', 'person_real_name'
  ],
  data(){
    return {
      old_household_allowance: {},
      household_allowance: {},
      errores_validacion:{},
      resolution_types: [],
      person_function_type: {},
      isLoading: false,
      household_start_date: null,
      person_birthday_formatted: "",
      file: null,
      document_name: null,
      changeFile: false,
      document_name_update: null,
      document_id: null,
    }
  },
  created() {
    this.isLoading=true;
    if(this.person_birthday){
      this.person_birthday_formatted = moment(this.person_birthday, "YYYY-MM-DD").format("DD-MM-YYYY")
    }
    else{
      this.person_birthday_formatted = ""
    }
    if (this.queryType === "create"){
      this.household_allowance.person_id = this.personId
      axios
          .get("/household_allowances/"+this.household_allowance_id+"/new_distance.json")
          .then(res => {
            this.household_allowance.household_allowance_type_id = res.data["household_allowance_type"]["id"]
            this.household_start_date = moment(this.old_start_date).add(1,'days')._d
            this.isLoading=false;
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }
    else if (this.queryType === 'update'){
      axios
          .get("/household_allowances/"+this.id+"/edit_distance.json")
          .then(res => {
            this.household_allowance = res.data
            this.household_start_date = moment(this.old_start_date).add(1,'days')._d
            if(res.data["start_date"]!==null){
              this.household_allowance.start_date = new Date(this.formatDate(res.data["start_date"]))
            }
            if(res.data["decree_date"]!==null){
              this.household_allowance.decree_date = new Date(this.formatDate(res.data["decree_date"]))
            }
            this.document_id =  res.data["document_id"]
            this.document_name_update = res.data["document_name"]
            this.document_name = res.data["document_name"]
            this.isLoading = false;
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }
  },
  methods: {
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
    saveDistanceHouseholdAllowance(){
      this.isLoading=true;

      if (this.queryType==="create"){
        if (this.file != null && this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Este archivo no tiene formato PDF",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        if (this.file != null && this.file.size > 1024*1024*25) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "El archivo no puede superar el maximo de 25 MB",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        /*if (this.file != null && (this.household_allowance.decree_date ==  null || this.household_allowance.decree_date ==  undefined) &&
            (this.household_allowance.decree == null || this.household_allowance.decree == undefined || this.household_allowance.decree == '')) {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Para adjuntar un archivo, debe llenar al menos uno de los campos del documento",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }*/


        this.household_allowance.person_id = this.personId;

        let formData = new FormData();

        // Agrega datos al objeto FormData
        formData.append("household_allowance", JSON.stringify(this.household_allowance));
        formData.append("old_household_allowance_id", this.household_allowance_id);
        formData.append("file", this.file); // Aquí agregamos el archivo
        formData.append("document_name", this.document_name);

        axios
            .post("/household_allowances/"+this.id+"/create-document-distance"+".json", formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
            .then(response =>{
              this.errores_validacion = {};
              this.$buefy.dialog.alert({
                message: 'Cese de asignación exitoso ',
                confirmText: 'Ok'
              })
              EventBus.$emit('ALEJAMIENTO_CREADO', response.data);
              this.isLoading=false;
              this.errors = [];
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalDistanceHouseholdAllowance.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores en el cese de asignación, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
        })
      }
      else if (this.queryType==="update"){
        if (this.file != null && this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Este archivo no tiene formato PDF",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        if (this.file != null && this.file.size > 1024*1024*25) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "El archivo no puede superar el maximo de 25 MB",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        /*if (this.file != null && (this.household_allowance.decree_date ==  null || this.household_allowance.decree_date ==  undefined) &&
            (this.household_allowance.decree == null || this.household_allowance.decree == undefined || this.household_allowance.decree == '')) {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Para adjuntar un archivo, debe llenar al menos uno de los campos del documento",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }*/

        let formData = new FormData();
        let clean_household_allowance = this.deleteData(this.household_allowance)

        // Agrega datos al objeto FormData
        formData.append("household_allowance", JSON.stringify(clean_household_allowance));
        formData.append("old_household_allowance_id", this.household_allowance_id);
        formData.append("file", this.file); // Aquí agregamos el archivo
        formData.append("document_name", this.document_name);

        axios
            .put("/household_allowances/"+this.household_allowance.id+"/update-document-distance.json", formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
            .then(response =>{
              this.errores_validacion = {};
              this.$buefy.dialog.alert({
                message: 'El cese de asignación editado con éxito ',
                confirmText: 'Ok'
              })
              this.isLoading=false;
              this.errors = [];
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalDistanceHouseholdAllowance.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al editar el cese de asignación, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
        })
      }
    },
    onDeleteFile() {
      this.file = null;
    },
    generateName(){
      var tipo = "CESE_ASIGNACION"
      var number = ""
      var date = ""
      var start_date = ""
      var full_name = "-"+this.person_real_name

      if (this.household_allowance.decree != null && this.household_allowance.decree != ''){
        number = "-N_" + this.household_allowance.decree
        tipo = "RES"
      }

      if (this.household_allowance.decree_date != null){
        date = "-"+("0" + this.household_allowance.decree_date.getDate()).slice(-2)
            + "_" + ("0" + (this.household_allowance.decree_date.getMonth()+1)).slice(-2)
            + "_" + this.household_allowance.decree_date.getFullYear()
        tipo = "RES"
      }

      if (this.household_allowance.start_date != null){
        start_date = "-"+("0" + this.household_allowance.start_date.getDate()).slice(-2)
            + "_" + ("0" + (this.household_allowance.start_date.getMonth()+1)).slice(-2)
            + "_" + this.household_allowance.start_date.getFullYear()
      }


      if ((this.household_allowance.decree_date == null || this.household_allowance.decree_date == '')  &&
          (this.household_allowance.decree == null || this.household_allowance.decree == '')){
        this.document_name = tipo + full_name + start_date
      }
      else{
        this.document_name = tipo + number + date
      }
    },
    deleteData(household_allowance){
      delete household_allowance.document_id
      delete household_allowance.document_name
      delete household_allowance.can_view_pdf
      delete household_allowance.can_edit
      delete household_allowance.can_show
      delete household_allowance.dependent_types
      delete household_allowance.household_allowance_type
      delete household_allowance.dependent_type

      return household_allowance
    },

  }
}
</script>