<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent @keydown.enter="$event.preventDefault()">
        <!-- Box de Formulario Evento -->
        <div class="modal-card" style="width: 100%" id="modal-event">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <!-- Componente de la busqueda -->
          <section class="modal-card-body" id="modal-events">
            <div class="columns">
              <div class="column"></div>
              <div class="column is-6">
                <div class="has-text-centered">
                  <b-field>
                    <b-input type="text" expanded minlength="3" icon="search" v-model='busqueda' @keyup.enter.native.prevent="search(busqueda)" placeholder="Búsqueda con al menos 3 letras..."></b-input>
                    <p class="control">
                      <b-button @click.prevent="search(busqueda)" class="is-primary" id="boton-buscar">Buscar</b-button>
                    </p>
                  </b-field>
                </div>
              </div>
              <div class="column"></div>
            </div>
            <!-- Resultado de la busqueda -->
            <div class="columns">
              <div class="column">
                <h3 class="is-size-4 has-text-weight-bold">Tabla Búsqueda
                  <b-tooltip label="Al seleccionar un participante desde la tabla de búsqueda, se agregará automáticamente a la Tabla Resumen de Participantes."
                             position="is-right"
                             size="is-medium"
                             multilined>
                    <b-icon
                        pack="fas"
                        icon="info-circle"
                        size="is-small"
                        type="is-info">
                    </b-icon>
                  </b-tooltip>
                </h3>
              </div>
              <div class="column is-narrow">
                <b-tooltip label="Si la persona no se encuentra o no existe y ademas es externa, la puede agregar presionando el botón +"
                           multilined
                           position="is-left">
                  <b-button class="is-primary is-outlined" @click="addExternalPerson">
                    <b-icon icon="plus"></b-icon>
                  </b-button>
                </b-tooltip>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-table
                    :data="people"
                    :paginated=true
                    per-page=5
                    striped
                    hoverable
                    :current-page="currentPage"
                    :pagination-simple=false
                    pagination-position="bottom"
                    default-sort-direction="asc"
                    sort-icon="arrow-up"
                    sort-icon-size="is-small"
                    default-sort="publication.title"
                    aria-next-label="Siguiente"
                    aria-previous-label="Anterior"
                    aria-page-label="Página"
                    aria-current-label="Página actual"
                    :checked-rows.sync="checkedRows"
                    :is-row-checkable="(row) => row.id !== 3 && row.id !== 4"
                    checkable
                    :checkbox-position="checkboxPosition"

                >
                  <b-table-column
                      field="name"
                      label="Nombres"
                      sortable
                      searchable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          placeholder=" Buscar"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{props.row.name}}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field="corporate_mail"
                      label="Correo"
                      sortable
                      searchable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          placeholder=" Buscar"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.corporate_mail ? props.row.corporate_mail : props.row.personal_mail }}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field="alias"
                      label="Alias"
                      sortable
                      searchable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          placeholder=" Buscar"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.alias}}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field="unit"
                      label="Unidad"
                      sortable
                      searchable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          placeholder=" Buscar"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.unit }}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field= 'active'
                      label= '¿Está activo?'
                      searchable
                      sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{  props.row.active }}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field= 'external'
                      label= '¿Es externo?'
                      searchable
                      sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{  props.row.external }}
                    </template>
                  </b-table-column>
                </b-table>
                <h3 class="is-size-4 has-text-weight-bold">Tabla Resumen Participantes</h3>
                <b-table
                    :data="checkedRows"
                    :paginated=true
                    per-page=5
                    striped
                    hoverable
                    :current-page="currentPage"
                    :pagination-simple=false
                    pagination-position="bottom"
                    default-sort-direction="asc"
                    sort-icon="arrow-up"
                    sort-icon-size="is-small"
                    default-sort="publication.title"
                    aria-next-label="Siguiente"
                    aria-previous-label="Anterior"
                    aria-page-label="Página"
                    aria-current-label="Página actual">
                  <b-table-column
                      field="name"
                      label="Nombres"
                      sortable
                      searchable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          placeholder=" Buscar"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{props.row.name}}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field="corporate_mail"
                      label="Correo"
                      sortable
                      searchable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          placeholder=" Buscar"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.corporate_mail ? props.row.corporate_mail : props.row.personal_mail }}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field="alias"
                      label="Alias"
                      sortable
                      searchable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          placeholder=" Buscar"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.alias}}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field="unit"
                      label="Unidad"
                      sortable
                      searchable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          placeholder=" Buscar"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.unit }}
                    </template>
                  </b-table-column>
                  <b-table-column
                      label="Acciones"
                      width="10"
                      v-slot="props">
                    <b-icon
                        pack="fas"
                        icon="trash"
                        style="color: #e50000;"
                        size="is-medium"
                        @click.native="deleteParticipantButton(props.row)"/>
                  </b-table-column>
                </b-table>
              </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions" style="justify-content: flex-end;">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" @click="saveParticipants" type="submit" >Guardar </button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-modal
        v-model="isCreateModal"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-external-people-create
            query-type="create"
            modal-header="Agregar datos personales"
            @clicked="onClickChild"
            @close="props.close"></modal-external-people-create>
      </template>
    </b-modal>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import ModalExternalPeopleCreate from "../person/modal_external_people_create"

export default {
  name: 'modal_inventors',
  components: {ModalExternalPeopleCreate},
  data() {
    return {
      isLoading: false,
      isCreateModal: false,
      canCancel: ['escape'],
      busqueda: "",
      people: [],
      checkedRows: [],
      checkboxPosition: "left",
      currentPage:1,
      tipos_participaciones: []
    }
  },
  props:[
    'modalHeader', 'inventores', 'id'
  ],
  created(){
    this.isLoading=true
    this.checkedRows = this.inventores
    this.isLoading=false
    this.busqueda = ""

  },
  methods: {
    search(query){
      this.isLoading = true
      if(query.length<2){
        this.$buefy.dialog.alert({
          message: 'Por favor ingrese a lo menos 3 letras.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = false;
        return false;
      }
      var checkedRows = []
      for (var i = 0, l = this.checkedRows.length; i < l; i++) {
        if(this.checkedRows[i] !== undefined){
          checkedRows.push({person_id: this.checkedRows[i].person_id})
        }
        else{
          checkedRows.push({person_id: -1})
        }
      }
      checkedRows.push({person_id: -1})
      return axios
          .get("/people/"+query+"/"+JSON.stringify({ checkedRows: checkedRows })+"/search_people.json"
          ).then(res => {
            this.people = res.data.participants
            this.people.forEach(function(participante){
              if (participante.active == true){
                participante.active = "SI"
              }
              else{
                participante.active = "NO"
              }
              if (participante.external == true){
                participante.external = "SI"
              }
              else{
                participante.external = "NO"
              }
            })
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })
      this.isLoading=false;
    },
    saveParticipants(){
      this.selectedParticipants = this.checkedRows
      this.$emit('clicked', this.selectedParticipants)
      this.$emit('close')
    },
    deleteParticipant(participants){
      this.checkedRows = this.checkedRows.filter(person => person.person_id != participants.person_id);
    },
    addParticipant(participants){
      if (this.checkedRows.filter(person => person.corporate_mail == participants.corporate_mail).length == 0){
        this.checkedRows.push(participants);
        this.people = this.people.filter(person => person.corporate_mail != participants.corporate_mail);
      }
    },
    addExternalPerson(){
      this.isLoading= true
      this.isCreateModal = true
      this.isLoading = false
    },
    onClickChild(value){
      this.search(value)
    },
    deleteParticipantButton(participants){
      this.$buefy.dialog.confirm({
        title: 'Eliminar Participante',
        message: 'Precaución, al momento de guardar esta actividad académica esta acción eliminará la asociación con la/el participante, lo que implica que ya no podrá ver esta información ni será contada en su actividad académica ¿Desea continuar?',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteParticipant(participants)
      })
    },
  }
}
</script>

<style scoped>

</style>
