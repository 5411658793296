<template>
    <section>
        <b-table striped :data="publications" paginated per-page=10 :pagination-simple=false
            pagination-position="bottom" aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
            aria-current-label="Página actual">

            <b-table-column label="Título" field="title" sortable searchable>
                <template #searchable="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder="Buscar Título..."
                        icon="search"/>
                </template>
                <template v-slot="props">
                    {{ props.row.title }}
                </template>
            </b-table-column>

            <b-table-column label="Nombre Editorial" field="editorial" sortable searchable>
                <template #searchable="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder="Buscar Editorial..."
                        icon="search"/>
                </template>
                <template v-slot="props">
                    {{ props.row.editorial }}
                </template>
            </b-table-column>

            <b-table-column label="Autores" field="participants_references" sortable searchable>
                <template #searchable="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder="Buscar Autores..."
                        icon="search"/>
                </template>
                <template v-slot="props">
                    {{ props.row.participants_references }}
                </template>
            </b-table-column>

            <b-table-column label="Fecha de Inicio" field='publication_date' sortable searchable :custom-search="searchPublicationDate">
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.publication_date._isValid ? new Date(props.row.publication_date).toLocaleDateString("es-CL") : 'No Existe Fecha' }}
                </template>
            </b-table-column>

            <b-table-column v-show="cargoPrincipal.name == 'ANALISTA I+D FING' || cargoPrincipal.name == 'SUPERADMIN'"
                label="Acciones" v-slot="props">
                <div class="columns">
                    <div v-if="props.row.can_show" class="column">
                      <b-tooltip
                          label="Mostrar en detalle la información del libro"
                          :active="true">
                          <b-button
                              pack="fas"
                              icon-right="eye"
                              type="is-info"
                              tag="a"
                              :href="/books/ + props.row.id"
                              target="_blank"/>
                      </b-tooltip>
                    </div>
                    <div v-if="props.row.can_edit" class="column">
                      <b-tooltip
                          label="Editar la información del libro"
                          :active="true">
                          <b-button
                              pack="fas"
                              icon-right="edit"
                              type="is-warning"
                              @click="isComponentModalActive = true;
                              selectedBookId = props.row.id"/>

                      </b-tooltip>
                    </div>
                    <div v-if="props.row.can_null" class="column">
                        <b-tooltip
                            label="Anular registro del libro"
                            :active="true">
                            <b-button
                                pack="fas"
                                icon-right="ban"
                                type="is-danger"
                                @click="nullBookPublication(props.row)"/>
                        </b-tooltip>
                    </div>
                </div>
            </b-table-column>

        </b-table>
        
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

        <b-modal :active="isComponentModalActive" width="90%" :can-cancel="false" aria-modal>
            <template>
                <NewBookFormVue modal-cancel="true" :cargo-principal="cargoPrincipal" :user-id="personId" modal-header="Editar Libro" :fromAcademic="true"
                                query-type="update" :book-id="selectedBookId" @close="isComponentModalActive = false" @refresh="getPublications()"/>
            </template>
        </b-modal>
    </section>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { searchDate } from "../../packs/utilities"
import NewBookFormVue from '../books/NewBookForm.vue'
export default {
    props: [
        'personId', 'cargoPrincipal', 'minYear', 'maxYear', 'changeFilter'
    ],
    components: {
        NewBookFormVue,
    },
    data(){
        return {
            publications: [],
            isLoading: false,
            isComponentModalActive: false,
            selectedBookId: null,
        }
    },
    async created() {
        await this.getPublications()
    },
    methods: {
        async getPublications() {
            this.isLoading = true
            await axios
                .get('/people/my-book-publications.json', {
                    params: {
                        minYear: this.minYear,
                        maxYear: this.maxYear,
                        id: this.personId
                    }
                })
                .then(response => {
                    this.publications = response.data
                    this.publications = this.publications.map(publication => {
                        publication.publication_date = moment(publication.publication_date, ['YYYY-MM-DD', 'DD-MM-YYYY'])
                        return publication
                    })
                    this.isLoading = false
                })
                .catch(error => {
                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al traer sus Publicaciones en Libro, favor de revisar los errores.',
                        type: 'is-danger',
                        hasIcon: true,
                        iconPack: 'fa',
                        icon: 'times-circle',
                        confirmText: 'Ok',

                    })
                    console.log(error)
                    this.isLoading = false
                })
        },
        searchPublicationDate(row, input) {
            return searchDate(row.publication_date, input)
        },
        nullBookPublication(publication) {
            this.$buefy.dialog.confirm({
                title: 'Anular Publicación en Libro',
                message: '¿Está seguro que desea <b>anular</b> la Publicación en Libro? Esta acción lo dejará inválido.<br>',
                confirmText: 'Anular Publicación en Libro',
                cancelText: 'Cancelar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: async () => {
                    this.isLoading = true
                    await axios
                        .delete("/publications/" + publication.id + "/nullify_publication.json")
                        .then(response => {
                            this.$buefy.dialog.alert({
                                title: 'Logrado',
                                type: 'is-success',
                                hasIcon: true,
                                icon: 'check-circle',
                                iconPack: 'fas',
                                message: 'La Publicación en Libro ha sido anulada exitosamente.',
                                confirmText: 'Aceptar'
                            })
                            this.getPublications()
                        })
                        .catch(error => {
                            this.$buefy.dialog.alert({
                                title: 'Error',
                                message: 'Hubo un error al anular la Publicación en Libro.<br><b>Errores:</b><br>' + error.response.data,
                                type: 'is-danger',
                                hasIcon: true,
                                icon: 'times-circle',
                                iconPack: 'fa',
                                ariaRole: 'alertdialog',
                                ariaModal: true
                            })
                            this.isLoading = false
                        })
                }
            })
        }
    },
    watch: {
        changeFilter: function() {
            this.getPublications()
        }
    }

}
</script>