<template>
  <div class="box">
    <AdministrativePermitsBreadcrums :breadcrumbs="breadcrumbs" />
    <h2 class="title">Permisos administrativos: Solicitar por persona</h2>
    <h1 class="subtitle">Búsqueda de Personas</h1>
    <section>
      <AdministrativePermitsSearchPerson
        class="mb-4"
        @search="onSearch"
        @clear="onClear"
      />
      <AdministrativePermitsSearchTable
        :persons="loadedPersons"
        title="Resultados de la búsqueda"
      />
    </section>
    <b-loading v-model="isLoading" :is-full-page="true" :can-cancel="false" />
  </div>
</template>

<script>
import axios from "axios";
import AdministrativePermitsBreadcrums from "./AdministrativePermitsBreadcrums.vue";
import AdministrativePermitsSearchPerson from "./AdministrativePermitsSearchPerson.vue";
import AdministrativePermitsSearchTable from "./AdministrativePermitsSearchTable.vue";

export default {
  components: {
    AdministrativePermitsBreadcrums,
    AdministrativePermitsSearchPerson,
    AdministrativePermitsSearchTable,
  },
  data() {
    return {
      breadcrumbs: [
        { href: "/life_record", label: "Menú principal" },
        {
          href: "/administrative-permits",
          label: "Permisos administrativos",
        },
        {
          href: "/administrative-permits/search",
          label: "Solicitar por persona",
          isActive: true,
        },
      ],
      isLoading: false,
      loadedPersons: [],
      search: {
        rut: "",
        dv: "",
        fullName: "",
      },
    };
  },
  computed: {
    isSearchApplied() {
      return (
        this.search.rut.length > 0 ||
        this.search.dv.length > 0 ||
        this.search.fullName.length > 0
      );
    },
  },
  methods: {
    async onSearch(search) {
      this.search = search;
      await this.searchPerson(search);
    },

    onClear() {
      this.search = {
        rut: "",
        dv: "",
        fullName: "",
      };
      this.loadedPersons = [];
    },

    async searchPerson(search) {
      this.isLoading = true;
      try {
        const response = await axios.get(
          "/administrative-permits/search-persons.json",
          {
            params: {
              rut: search.rut,
              dv: search.dv,
              fullName: search.fullName,
            },
          }
        );
        if (response.status === 200 && response.data) {
          this.loadedPersons = response.data.persons;
          this.$buefy.toast.open({
            message: this.messageByStatus(),
            type: this.colorByStatus(),
            position: "is-bottom-right",
            duration: 5000,
          });
        }
      } catch (error) {
        let errorMessage = "Ocurrió un error desconocido.";
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          errorMessage = error.response.data.error;
        }
        this.$buefy.toast.open({
          message: errorMessage,
          type: "is-danger",
          position: "is-bottom-right",
          duration: 5000,
        });
      }
      this.isLoading = false;
    },
    textSearched() {
      let searchText = "";
      if (this.search.rut.length > 0) {
        searchText += `RUT: ${this.search.rut}`;
      }
      if (this.search.dv.length > 0) {
        if (searchText.length > 0) {
          searchText += " y ";
        }
        searchText +=
          this.search.rut.length > 0
            ? `DV: ${this.search.dv}`
            : `DV (sin RUT): ${this.search.dv}`;
      }
      if (this.search.fullName.length > 0) {
        if (searchText.length > 0) {
          searchText += " y el texto: ";
        }
        searchText += `${this.search.fullName}`;
      }
      return searchText.length > 0
        ? searchText
        : "No se ingresó ningún dato de búsqueda";
    },
    messageByStatus() {
      const countPersons = this.loadedPersons.length;
      if (countPersons === 0) {
        return `La búsqueda con el término '${this.textSearched()}' no produjo ningún resultado.`;
      }
      return `La búsqueda con el término '${this.textSearched()}' ${
        countPersons === 1
          ? `produjo ${countPersons} coincidencia`
          : `produjo ${countPersons} coincidencias`
      }.`;
    },
    colorByStatus() {
      const countPersons = this.loadedPersons.length;
      if (countPersons === 0) {
        return "is-danger";
      }
      return "is-success";
    },
    goToAdministrativePermits(personId) {
      window.open("/administrative-permits/" + personId, "_blank");
    },
  },
};
</script>
<style scoped>
@media (max-width: 767px) {
  .box {
    border-radius: 0%;
    box-shadow: none;
    min-height: calc(100vh - 64px);
    background-color: white;
  }
  .title {
    font-size: 1.5rem;
  }
  .subtitle {
    font-size: 1.2rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .box {
    border-radius: 0%;
    box-shadow: none;
    min-height: calc(100vh - 64px);
    background-color: white;
  }
}

@media (min-width: 1024px) {
  .box {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
