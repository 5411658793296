<template>
  <section>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Información de integrante</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body" ref="modalPersonForm">
        <div class="container" style="margin-top: 40px">
          <div class="columns">
            <div class="column">
              <b-field label="Nombres">
                  <b-input readonly v-model="person.name" type="text"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Primer Apellido">
                  <b-input readonly v-model="person.last_name" type="text"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Segundo Apellido">
                  <b-input readonly v-model="person.second_surname" type="text"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns" v-if="person.run_attributes">
            <div class="column is-3">
              <b-field label="Rut">
                  <b-input readonly v-model="person.run_attributes.rut"></b-input>
              </b-field>
            </div>
            <div class="column is-1">
              <b-field label="DV">
                <b-input readonly v-model="person.run_attributes.dv" maxlength="1" :has-counter=false></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Correo personal">
                  <b-input readonly v-model="person.personal_mail"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Pasaporte">
                <b-input readonly v-model="person.run_attributes.passport" maxlength="20" :has-counter=false></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns" v-else>
            <div class="column is-3">
              <b-field label="Rut">
                  <b-input readonly ></b-input>
              </b-field>
            </div>
            <div class="column is-1">
              <b-field label="DV">
                <b-input readonly ></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Correo personal">
                  <b-input readonly v-model="person.personal_mail"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Pasaporte">
                <b-input readonly maxlength="20" :has-counter=false></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">

            <div class="column">
              <b-field label="Nacionalidad" v-if="person.nationality_attributes">
                <b-input  readonly v-model="person.nationality_attributes.name" maxlength="255" :has-counter=false></b-input>
              </b-field>
              <b-field label="Nacionalidad" v-else>
                <b-input  readonly maxlength="255" :has-counter=false></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Dirección">
                <b-input readonly v-model="person.address" maxlength="255" :has-counter=false></b-input>
              </b-field>
            </div>



          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Celular">
                  <b-input readonly v-model="person.cellphone" expanded></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Teléfono">
                  <b-input readonly v-model="person.phone"></b-input>
              </b-field>
            </div>
          </div>


        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
      </section>
    </div>
    <footer class="modal-card-foot actions">
      <div class="actions has-text-right">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>            
      </div>
    </footer>
  </section>
</template> 

<script>
import axios from "axios"
import moment from "moment"
export default {
  name: "ModalPersonMember",
  data(){
    const data =[]
    return {
      person:{run_attributes: null},
      countries: [],
      errores_validacion: {},
      isLoading:false,
    }
  },

  props:[
    'userId', 'queryType', 'personId', 'modalHeader'
  ],
  created(){
    let vm = this;
    vm.isLoading=true;
    axios
        .get("/people/sdt/"+vm.personId+".json")
        .then(res => {
          vm.person = res.data.person;
          vm.isLoading=false;
        })
        .catch(e => {
          console.log(e);
          vm.queryError(vm);
          vm.isLoading=false;
        });
    
  },
  methods: {
    queryError(vm){
      vm.$buefy.dialog.alert({
        title: 'Error',
        message: 'Hubo un error en su solicitud.',
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true
      })
    },
    
  }
}
</script>

<style scoped>

</style>