<template>
  <section>
    <h1 class="subtitle is-2 has-text-primary">
      ¿Qué información hay en Eventos?
    </h1>
    <div class="mb-3 ">
      <p>En esta sección se listan eventos generales registrados por el académico.
        Algunos de estos eventos son: renovación automática, rectificación, bienios, licencias,
        asignaciones profesionales y otros.</p>
    </div>
    <img border="2" class="m-2" src="/tutorial_images/eventos.png" style="width:80%">
    <div class="mb-3 ">
      <p>El sistema permite ver un evento</p>
    </div>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Paso 1
    </h2>
    <p class="my-3 ">Estar situado en la pestaña de <b>Eventos</b> </p>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Paso 2
    </h2>
    <p class="my-3 ">Presionar el botón con forma de <b>ojo</b> situado en la columna de acciones:
    </p>
    <img border="2" class="m-2" src="/tutorial_images/ver_eventos.png" style="width:80%">
    <p class="my-3 ">La acción anterior despliega un evento y permite su visualización:
    </p>
    <img border="2" class="m-2" src="/tutorial_images/evento.png" style="width:80%">
  </section>
</template>
<script>
export default {
  name: "what_is_event_mhv",
  data(){
    return {
    }
  },
  methods:{

  },
}
</script>