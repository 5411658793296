var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',[_c('br'),_vm._v(" "),_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-11"},[_c('b-message',{attrs:{"title":"Importante","type":"is-info","has-icon":"","aria-close-label":"Cerrar","size":"is-small"}},[_vm._v("\n            La información de actividad académica generada con filtro por año activado, se dará según los siguientes criterios:\n            "),_c('ul',[_c('li',[_vm._v("Patentes: patentes presentadas el año seleccionado.")]),_vm._v(" "),_c('li',[_vm._v("Proyectos: proyectos en curso el año seleccionado.")]),_vm._v(" "),_c('li',[_vm._v("Publicaciones: publicaciones publicadas el año seleccionado.")]),_vm._v(" "),_c('li',[_vm._v("Disclosures: disclosures con fecha de recepción el año seleccionado.")])])])],1)])]),_vm._v(" "),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.exportData)}}},[_c('br'),_vm._v(" "),_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-11"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('h1',{staticClass:"title is-4"},[_vm._v("Exportación de actividad académica")]),_vm._v(" "),_c('b-field',{attrs:{"label":"Tipo de actividad académica a exportar*"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.export_types,"placeholder":"Seleccione el tipo de archivo a exportar","selectLabel":"Presione para seleccionar","selectedLabel":"Seleccionado","deselectLabel":"No se puede deseleccionar","allow-empty":false},scopedSlots:_vm._u([{key:"noOptions",fn:function(){return [_vm._v("\n                          No existen datos\n                        ")]},proxy:true}],null,true),model:{value:(_vm.export_type),callback:function ($$v) {_vm.export_type=$$v},expression:"export_type"}},[_vm._v(" "),_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("\n                          No se encontraron elementos.\n                        ")])]),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Unidad*"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.units.map(function (type) { return type.id; }),"custom-label":function (opt) { return _vm.units.find(function (x) { return x.id === opt; }).name; },"placeholder":"Seleccione una unidad","selectLabel":"Presione para seleccionar","selectedLabel":"Seleccionado","deselectLabel":"No se puede deseleccionar","allow-empty":false},scopedSlots:_vm._u([{key:"noOptions",fn:function(){return [_vm._v("\n                          No existen datos\n                        ")]},proxy:true}],null,true),model:{value:(_vm.unitId),callback:function ($$v) {_vm.unitId=$$v},expression:"unitId"}},[_vm._v(" "),_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("\n                        No se encontraron elementos.\n                      ")])]),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_vm._v(" "),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Año*"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.years,"placeholder":"Seleccione un año","selectLabel":"Presione para seleccionar","selectedLabel":"Seleccionado","deselectLabel":"No se puede deseleccionar","allow-empty":false},scopedSlots:_vm._u([{key:"noOptions",fn:function(){return [_vm._v("\n                          No existen datos\n                        ")]},proxy:true}],null,true),model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}},[_vm._v(" "),_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("\n                        No se encontraron elementos.\n                      ")])]),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"has-text-right"},[(_vm.export_type.length>0)?_c('b-button',{attrs:{"type":"is-primary","native-type":"submit"}},[_vm._v("Exportar")]):_vm._e()],1)])])])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }