<template>
    <section>

        <div>
            <b-modal v-model="showFormActivity" has-modal-card trap-focus :destroy-on-hide="true" width="100%" aria-role="dialog"
                aria-modal :can-cancel="false">
                <template #default="props">
                    <modalNewEditVimeActivity :personId="personId" :cargoPrincipal="cargoPrincipal" :activityId="selectedActivityId" :edit="editActivity" 
                        @close="props.close" @refresh="getMyVimeActivities()"/>
                </template>
            </b-modal>

            <b-modal v-model="showActivity" has-modal-card trap-focus :destroy-on-hide="true" width="100%" aria-role="dialog"
                aria-modal :can-cancel="false">
                <template #default="props">
                    <ModalShowActivity :personId="personId" :cargoPrincipal="cargoPrincipal" :activityId="selectedActivityId"
                        @close="props.close"/>
                </template>
            </b-modal>
        </div>

        <div class="columns" v-show="canCreateMyVimeActivity">
            <div class="column has-text-right">
                <b-button type="is-primary" icon-left="plus" label="Agregar Actividad" @click="showFormActivity = true; editActivity = false"/>
            </div>
        </div>

        <b-table striped :data="vime_activities" :paginated=true per-page=10 pagination-position="bottom"
            aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
            aria-current-label="Página actual">

            <b-table-column label="Nombre Actividad" field="name" sortable searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Actividad..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.name }}
                </template>
            </b-table-column>

            <b-table-column label="Tema" field="theme" sortable searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Tema..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.theme }}
                </template>
            </b-table-column>

            <b-table-column label="Tipo de Actividad" field="vime_activity_type.name" sortable searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Tipo..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.vime_activity_type.name }}
                </template>
            </b-table-column>

            <b-table-column label="Fecha de Inicio" field="start_date" sortable searchable :custom-search="searchInitDate">
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL") : '' }}
                </template>
            </b-table-column>

            <b-table-column label="Fecha de Término" field="end_date" sortable searchable :custom-search="searchEndDate">
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL") : '' }}
                </template>
            </b-table-column>

            <b-table-column
                v-show="cargoPrincipal.name == 'ANALISTA I+D FING' || cargoPrincipal.name == 'SUPERADMIN' || cargoPrincipal.name == 'ACADEMICO'" 
                label="Acciones"
                v-slot="props">
                <div class="columns">
                    <div class="column">
                        <b-button pack="fas" icon-right="eye" type="is-info" @click="showActivity = true; selectedActivityId = props.row.id" />
                    </div>
                    <div v-show="props.row.can_edit" class="column">
                        <b-tooltip
                            label="Editar en detalle la información de la actividad VIME"
                            :active="true">
                            <b-button pack="fas" icon-right="edit" type="is-warning" @click="showFormActivity = true; selectedActivityId = props.row.id; editActivity = true"/>
                        </b-tooltip>
                    </div>
                </div>
            </b-table-column>

            <template slot="empty">
                <div class="columns is-centered">
                    <div class="column is-6 is-centered has-text-centered">
                        <b-message has-icon type="is-warning">
                            <h1> No se encontraron resultados.</h1>
                        </b-message>
                    </div>
                </div>
            </template>

        </b-table>

        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

    </section>
</template>

<script>
import axios from 'axios'
import moment from "moment"
import newActivityForm from '../moduloVIME/vime_activity/new/vime_activity_new_view.vue'
import modalNewEditVimeActivity from './new_edit_academic_vime_activity.vue'
import ModalShowActivity from '../moduloVIME/vime_activity/show/modal_show_academic_activity.vue'
export default {
    components: {
        newActivityForm, modalNewEditVimeActivity, ModalShowActivity
    },
    props: [
        'personId', 'cargoPrincipal', 'canCreateDocument', 'minYear', 'maxYear', 'changeFilter', 'canCreateMyVimeActivity'
    ],
    data() {
        return {
            isLoading: false,
            vime_activities: [],
            showFormActivity: false,
            selectedActivityId: null,
            editActivity: false,
            showActivity: false,
        }
    },
    async created() {
        await this.getMyVimeActivities()
    },
    methods: {

        async getMyVimeActivities() {
            this.isLoading = true
            await axios
                .get('/people/' + this.personId + '/vime_activity.json', {
                    params: {
                        minYear: this.minYear,
                        maxYear: this.maxYear
                    }
                })
                .then(response => {
                    this.vime_activities = response.data
                    this.vime_activities = this.vime_activities.map(activity => {
                        var newActivity = activity
                        newActivity["start_date"] = moment(newActivity["start_date"], ["YYYY-MM-DD", "DD-MM-YYYY"])
                        newActivity["end_date"] = moment(newActivity["end_date"], ["YYYY-MM-DD", "DD-MM-YYYY"])
                        return newActivity
                    })
                    this.isLoading = false
                })
                .catch(error => {
                    var showError = error.response ? ' Favor revisar errores.<br>Errores:<br>' +  error.response.data : ' Favor intentarlo más tarde.'

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al traer las Actividades Vime de la persona consultada.' + showError ,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })

                    this.isLoading = false
                })
        },

        searchInitDate(row, input) {
            return searchDate(row.start_date, input)
        },
        searchEndDate(row, input) {
            return searchDate(row.end_date, input)
        },
    },
    watch: {
        changeFilter: function () {
            this.getMyVimeActivities()
        }
    }
}
</script>