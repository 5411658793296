<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveDocumentCategory)">
        <div class="modal-card" style="width: 1200px">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" ref="cardDocumentCategoryForm">
            <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
              <b-message type="is-danger" has-icon>
                <div v-for="(item,index) in errores_validacion" :key="index">
                  {{ item[0] }}
                </div>
              </b-message>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Nombre Carpeta*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input v-model="document_category.name" type="text" minlength="3" maxlength="255" expanded></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
<!--            <div class="columns">
              <div class="column">
                <b-message
                    title="Atención"
                    type="is-warning"
                    has-icon
                    aria-close-label="Close message">
                  La estructura del link de una carpeta de google drive es: https://drive.google.com/drive/u/0/folders/<b>GOOGLE_ID</b>.<br>
                  Por lo tanto, debe copiar el GOOGLE_ID e ingresarlo en el campo <b>Google ID Carpeta*</b>.
                </b-message>
              </div>
            </div>-->
<!--            <div class="columns">
              <div class="column">
                <b-field label="Google ID Carpeta*">
                  <ValidationProvider v-slot="{ errors }" rules="max:255|required">
                    <b-input v-model="document_category.google_id" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>-->
            <h3 class="is-size-6 has-text-weight-bold">Carpeta padre*
<!--              <b-tooltip
                  label="Ingrese al menos 3 caracteres para realizar una búsqueda."
                  position="is-bottom"
                  size="is-medium"
                  multilined>

                <b-icon
                    pack="fas"
                    icon="info-circle"
                    size="is-small"
                    type="is-info">
                </b-icon>
              </b-tooltip>-->
            </h3>
            <div class="columns">
              <div class="column">
                <b-field>
                    <multiselect v-model="document_category.document_category_id"
                                 :options="document_categories.map(type => type.id)"
                                 :custom-label="opt => document_categories.find(x => x.id === opt).show_name"
                                 placeholder="Seleccione Carpeta de Documento padre"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 @search-change="searchCategory"
                                 :internal-search="false"
                                 :loading="isLoadingMultiselect"
                                 :disabled="true"
                                 :allow-empty="true">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <template slot="option" slot-scope="props">
                        {{ document_categories.find(x => x.id === props.option).show_name}}
                      </template>
                      <span slot="noResult">
                          No se encontraron elementos.
                        </span>
                    </multiselect>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                 <span>
                  <h3 class="is-size-3 has-text-weight-bold">
                    Roles y Permisos*
                    <b-tooltip label="En esta sección debe ingresar los roles y las acciones que podrán realizar sobre esta carpeta."
                               position="is-right"
                               size="is-medium"
                               multilined>
                    <b-icon
                        pack="fas"
                        icon="info-circle"
                        size="is-small"
                        type="is-info">
                    </b-icon>
                  </b-tooltip>
                    <!--Añadir Rol -->
                  </h3>
                </span>
              </div>
              <div class="column">
                <div class="is-pulled-right">
                  <b-button  class="mt-2" @click.prevent="execAddModal(categoryId)"
                             type="is-primary">Administrar Roles</b-button>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="control">
                  <b-switch v-model="loadRolesParent" @input="updateRoles" :disabled="document_category.document_category_id===null || document_category.document_category_id===undefined"> ¿Heredar permisos de carpeta padre?
                    <b-tooltip
                        label="Se cargarán los permisos de la carpeta padre"
                        multilined
                        position="is-left">
                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          type="is-info">
                      </b-icon>
                    </b-tooltip></b-switch>
                </div>
              </div>
            </div>
            <div class="columns">
              <div v-if="document_category.added_roles.length!=0" class="column">
                <h3 class="is-size-4 has-text-weight-bold">Tabla Resumen Roles</h3>
                <b-table
                    :data="document_category.added_roles"
                    :paginated=true
                    per-page=5
                    striped
                    hoverable
                    :current-page="currentPage"
                    :card-layout="cardLayout"
                    :pagination-simple=false
                    pagination-position="bottom"
                    default-sort-direction="desc"
                    sort-icon="arrow-up"
                    sort-icon-size="is-small"
                    default-sort="last_patent_state_date"
                    aria-next-label="Siguiente"
                    aria-previous-label="Anterior"
                    aria-page-label="Página"
                    aria-current-label="Página actual">
                  <b-table-column
                      field= 'name'
                      label= 'Rol'
                      width="20em"
                      searchable
                      sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{  props.row.name }}
                    </template>
                  </b-table-column>
                  <b-table-column
                      label="Acciones Permitidas*"
                      v-slot="props">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <b-field label=" ">
                        <b-taginput
                            v-model="props.row.actions"
                            :data="filtered_permissions"
                            type="is-primary is-light"
                            autocomplete
                            field="name"
                            icon="tag"
                            placeholder="Agrega un permiso permitido"
                            @typing="getFilteredPermissions">
                        </b-taginput>
                      </b-field>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-table-column>
                  <b-table-column
                      label="Acción"
                      width="10"
                      v-slot="props">
                    <b-icon
                        pack="fas"
                        style="color: #e50000;"
                        icon="trash"
                        size="is-medium"
                        @click.native="deleteRole(props.row)"/>
                  </b-table-column>
                </b-table>
              </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit">Guardar </button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-modal
        v-model="isModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-permission-crud :category-id="categoryId"
                                   :id="selected"
                                   :roles_anteriores="document_category.added_roles"
                                   query-type="update"
                                   modal-header="Añadir Roles y Permisos"
                                   @close="props.close"
                                   @clicked="onClickChild"
        >
        </modal-permission-crud>
      </template>
    </b-modal>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import ModalPermissionCrud from "./modal_permission_crud"

export default {
  name: "modal_document_category_form",
  components:{
    ModalPermissionCrud
  },
  data() {
    return{
      isLoading: false,
      errores_validacion:{},
      canCancel: ['escape'],
      document_category:{
        roles_attributes: [],
        document_category_id: null,
        added_roles: []
      },
      document_categories: [],
      roles:[],
      isLoadingMultiselect: false,
      currentPage:1,
      filtered_roles: [],
      loadRolesParent: false,
      actualRole: null,
      isModalActive: false,
      selected: null,
      permission_types: [],
      cardLayout:false,
      filtered_permissions: []
    }
  },
  props:['queryType','categoryId', "cargoPrincipal", 'modalHeader', 'actualFolder'],

  created() {
    this.initialize()
  },
  methods :{
    initialize(){
      this.isLoading = true
      if (this.queryType === "update"){
        axios
            .get("/document_categories/"+this.categoryId+"/edit.json")
            .then(res => {
              this.document_category = res.data
              if(this.actualFolder && this.categoryId !== 0){
                this.document_categories.push(this.document_category.parent_category)
              }
              this.permission_types = res.data.permission_types
              this.isLoading = false
            })
            .catch(e=>{
              console.log(e);
              this.isLoading = false;
            })
      }
      else if(this.queryType==="create"){
        axios
            .get("/document_categories/new.json", {params: { category: this.actualFolder.id}})
            .then(res => {
              if(res.data["actual_role"]){
                console.log(res.data["actual_role"])
                this.actualRole = res.data["actual_role"]
                this.document_category.added_roles.push(this.roles.find(x => x.id === res.data["actual_role"].id))
              }
              if(this.actualFolder){
                this.document_categories.push(this.actualFolder)
                this.document_category.document_category_id = this.actualFolder.id
                console.log(this.actualFolder.name)
              }
              this.permission_types = res.data.permission_types
              this.document_category.added_roles = []
              this.isLoading = false;
            }).catch(error => {
          console.log(error);
          this.isLoading = false;
        })
      }
    },
    saveDocumentCategory(){
      if(this.document_category.added_roles.length===0){
        return this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Se debe agregar al menos un rol y sus permisos',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
      this.isLoading = true;
      if(this.queryType === "create"){
        axios
            .post("/document_categories.json",{
              document_category: this.document_category
            })
            .then(response => {
              this.errores_validacion = {};
              this.$emit('close')
              this.isLoading=false;
            }).
        catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.isLoading=false;
        })
      }
      if(this.queryType === "update"){
        axios
            .put("/document_categories/"+this.categoryId+".json",{
              document_category: this.document_category
            })
            .then(response => {
              this.errores_validacion = {};
              this.$emit('close')
              this.isLoading=false;
            })
            .catch(error => {
              this.errores_validacion = error.response.data
              var elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              this.isLoading=false;
            })
      }
    },
    searchCategory(query){
      if(query.length<3){
        return
      }
      this.isLoadingMultiselect = true;
      this.document_category.document_category_id = null;
      axios
          .get("/document_categories/search-categories.json",
              {
                params: {
                  query: query,
                  type: 2 //PARA AGREGAR CARPETAS
                }
              })
          .then(res => {
            this.document_categories = res.data
            this.isLoadingMultiselect=false;

          }).catch(error => {
        console.log(error)
        this.isLoadingMultiselect=false;
      })
    },
    updateRoles(){
      this.isLoading = true
      if(this.loadRolesParent){
        axios
            .get("/document_categories/get-permissions.json",
                {
                  params: {
                    document_category_id: this.document_category.document_category_id
                  }
                })
            .then(res => {
              if(res.data["parent_roles"].length>0){
                this.document_category.added_roles = res.data["parent_roles"]
              }
              this.isLoading=false;

            }).catch(error => {
          console.log(error)
          this.errores_validacion = error.response.data
          this.isLoading=false;
        })
      }
      else{
        this.document_category.added_roles = []
        this.isLoading = false
      }
    },
    execAddModal(id){
      this.selected = id;
      this.isModalActive = true;
    },
    onClickChild(value){
      this.isLoading = true
      this.document_category.added_roles = value
      this.isLoading = false

    },
    deleteRole(role){
      this.document_category.added_roles = this.document_category.added_roles.filter(r => r.internal_position_id != role.internal_position_id);
    },
    getFilteredRoles(text){
      this.filtered_roles = this.roles.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    getFilteredPermissions(text){
      this.filtered_permissions = this.permission_types.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    filter_duplicates(tags, value){
      if(!value.find(x => x.id === tag.id)){
        return value.push(tags)
      }
    }
  },



}

</script>