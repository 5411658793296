<template>
  <section>
    <!-- Box de Formulario Evento -->
    <div class="modal-card" style="width: auto" id="modal-spreadsheet">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <vue-excel-editor v-model="jsondata" ref="grid">
            <vue-excel-column field="rut"   label="RUN" :readonly="true"/>
            <vue-excel-column field="dv"   label="DV" :readonly="true"/>
            <vue-excel-column field="last_name"   label="APELLIDO P" :readonly="true"/>
            <vue-excel-column field="surname"   label="APELLIDO M" :readonly="true"/>
            <vue-excel-column field="name"   label="NOMBRE" :readonly="true"/>
            <vue-excel-column field="hrs"   label="HRS" :readonly="true"/>
            <vue-excel-column field="hr_subject"   label="ASIGNATURA" :readonly="true"/>
            <vue-excel-column field="code"   label="CÓDIGO" :readonly="true"/>
            <vue-excel-column field="category"   label="CATEGORIA" :readonly="true"/>
          </vue-excel-editor>
        </div>
        <div class="columns">
          <p><b>*Debe agregar las siguientes filas (arriba de las cabeceras) para que el archivo sea válido: </b></p>
        </div>
        <div class="columns">
          <ul id="example-1">
            <li>{{ "(fila vacía)" }}</li>
            <li>{{ "Facultad de Ingeniería" }}</li>
            <li>{{ "CC: número_centro_de_costo DEPARTAMENTO DE nombre_departamento" }}</li>
          </ul>
        </div>
      </section>
    </div>
    <footer class="modal-card-foot actions">
      <div class="field is-grouped is-pulled-right">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
        <button @click="exportAsExcel" class="button" type="primary"> Exportar Excel </button>
      </div>
    </footer>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import axios from "axios";
import moment from 'moment'

export default {
  name: "modal_spreadsheet_continuity",
  props:[
    'modalHeader',
  ],
  components: {
  },
  data(){
    return {
      isLoading:false,
      isDisabled: false,
      jsondata: [
        {rut: 12345678, dv: "9", last_name: "APELLIDO PATERNO", surname: "APELLIDO MATERNO", name: "NOMBRE1 NOMBRE2", hrs: "4",
          hr_subject: "ASIGNATURA 1", code:	"1234", category:	"ADJUNTO II"
        },
        {rut: 12345678, dv: "9", last_name: "APELLIDO PATERNO", surname: "APELLIDO MATERNO", name: "NOMBRE1 NOMBRE2", hrs: "5",
          hr_subject: "ASIGNATURA 2", code:	"1234", category:	"ADJUNTO II"
          },
        {rut: 98765421, dv: "1", last_name: "APELLIDO PATERNO 2", surname: "APELLIDO MATERNO 2", name: "NOMBRE NOMBRE2", hrs: "5",
          hr_subject: "ASIGNATURA 3", code:	"1234", category:	"ADJUNTO II"
        }
      ],
    }
  },
  created() {
    this.isLoading=true;

    this.isLoading=false;
  },
  methods: {
    deleteData(){
    },
    exportAsExcel () {
      const format = 'xlsx'
      const exportSelectedOnly = false
      const filename = 'continuidad'
      this.$refs.grid.exportTable(format, exportSelectedOnly, filename)
    }

  }
}
</script>