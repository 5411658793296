<template>
  <section>
    <section class="hero has-background-white-bis" style="box-shadow: 0px 6px 14px -4px rgba(196,196,196,1);">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-capitalized">
            {{ this.unit.name }}
          </h1>
          <h2 v-if="unit.faculty_name!=null" class="subtitle">
            {{ this.unit.faculty_name }}
          </h2>
          <div class="columns">
            <div class="column">
              <b-field label="Año desde:">
                <multiselect v-model="minYear"
                             :options="year_options"
                             :searchable="true"
                             :close-on-select="true"
                             placeholder="Elija un año"
                             :allow-empty="true"
                             selectLabel="Presione para seleccionar"
                             selectedLabel="Seleccionado"
                             deselectLabel="Presione para deseleccionar"
                ></multiselect>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Año hasta:"
                       :type="{ 'is-danger': verifyYears }"
                       :message="{ 'Año hasta no puede ser menor al Año desde': verifyYears }">
                <multiselect v-model="maxYear"
                             :options="year_options"
                             :searchable="true"
                             :close-on-select="true"
                             placeholder="Elija un año"
                             :allow-empty="true"
                             selectLabel="Presione para seleccionar"
                             selectedLabel="Seleccionado"
                             deselectLabel="Presione para deseleccionar"
                ></multiselect>
              </b-field>
            </div>
            <div class="column">
              <br>
              <b-field label=" ">
                <b-tooltip label="Seleccione un rango de fechas para filtrar actividad académica."
                           position="is-left">
                  <b-button type="is-info" @click="applyFilter">
                    Aplicar filtro
                  </b-button>
                </b-tooltip>
              </b-field>

            </div>
<!--            <div class="column">
              <span class="is-pulled-right">
                <add-academic-activity-dropdown></add-academic-activity-dropdown>
              </span>
            </div>-->
          </div>
        </div>
      </div>

    </section>


    <section>
      <div class="box">
        <div class="container">
          <b-tabs position="is-centered" @input="changeTab" :multiline="true">
            <b-tab-item v-for="item in items"
                        :value="item.content"
                        v-bind:key="item.content"
                        :label="item.tab"
                        :icon="item.icon"
                        v-if="item.permission"
                        :visible="(item.content ==='Performances') ? canSeeDocument === 'true' : true">
            </b-tab-item>
          </b-tabs>
          <!-- COMPONENTE AQUI -->
          <keep-alive>
            <component  v-bind:is="currentTabComponent" :object-id="unitId" :component-type="'unit'" :cargoPrincipal="cargoPrincipal"
                       :currentYear="year" :min-year="minYear" :max-year="maxYear" :change-filter="changeFilter" :hide-update="true"
                        :unit-id="unitId" :unit="unit" :can-create-document="canCreateDocument"></component>
          </keep-alive>
          <!-- FIN COMPONENTE -->

        </div>
      </div>
    </section>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  </section>




</template>
<script>

import axios from "axios";
import Summary from "../person/summary_tab.vue";
import Publications from "../person/publications_tab.vue";
import Projects from "../person/projects_tab.vue";
import Disclosures from "../person/disclosures_tab.vue";
import Patents from "../person/patents_tab.vue";
import People from "./people_tab.vue";
import Indicators from "./indicators_tab.vue";
import Books from "../person/books_tab.vue";
import Vime from "./vime.vue";
import Performances from "../performances/performances_tab.vue"
import AuthoritiesList from "./authorities_list"
export default {
  components:{
    Summary,Publications,Projects,Patents,People,Indicators,Disclosures,Vime,AuthoritiesList,Performances, Books
  },
  props:[
    'unitId','cargoPrincipal', 'canCreateDocument', 'canSeeDocument'
  ],
  data(){
    return{
      unit:{},
      isLoading:false,
      currentTab: 'AuthoritiesList',
      items: [
        {tab: "Quiénes Somos", content:"AuthoritiesList", icon: "user-tie", permission: false},
        {tab: 'Indicadores',content:'Summary',icon:"address-card", permission: true},
        {tab: 'Colaboradores',content:'People',icon:"users", permission: true},
        {tab: 'Publicaciones',content:'Publications',icon:"file-alt", permission: true},
        //{tab: 'Experiencia',content:'Experience',icon:"file-invoice", permission: true},
        {tab: 'Proyectos',content:'Projects',icon:"folder-open", permission: true},
        {tab: 'Propiedad Intelectual',content:'Patents',icon:"lightbulb", permission: true},
        // {tab: 'Disclosures',content: 'Disclosures', icon:"folder", permission: true},
        //{tab: 'Libros',content: 'Books', icon:"book-open", permission: true},
        //{tab: 'Indicadores',content:'Indicators',icon:"chart-line", permission: true},
        //{tab: 'Descargas',content:'Download',icon:"file-download", permission: true},
        {tab: 'Documentos de desempeño', content:'Performances', icon:'list-alt', permission: true},
        {tab: 'VIME',content:'Vime',icon:"globe", permission: true},
      ],
      year:new Date().getFullYear(),
      minYear:new Date().getFullYear() - 5,
      maxYear:new Date().getFullYear(),
      year_options:Array.from({length:(new Date().getFullYear()-2000+1)},(v,k)=>k+2000).reverse(),
      currentYear:null,
      historicFilter:false,
      verifyYears:false,
      changeFilter:0,


    }
  },
  created(){
    this.isLoading=true;
    axios
        .get("/admin/units/"+this.unitId+".json")
        .then(res => {
          this.unit = res.data.unit
          this.items[0].permission = res.data["can_view_authorities"]
          if(!res.data["can_view_authorities"]){
           this.currentTab = "Summary"
          }
          this.isLoading=false;
        })
        .catch(e => {
          console.log(e);
          this.isLoading=false;
        });

  },
  methods:{
    changeTab: function(value){
      this.currentTab = value
    },
    applyFilter(){
      if (this.minYear!= null && this.maxYear!=null){
        if(this.minYear > this.maxYear){
          this.verifyYears = true
        }
        else{
          this.verifyYears = false
          if (this.changeFilter == 0){
            this.changeFilter = 1
          }
          else{
            this.changeFilter = 0
          }
        }
      }
      else{
        this.verifyYears = false
        if (this.changeFilter == 0){
          this.changeFilter = 1
        }
        else{
          this.changeFilter = 0
        }
      }
    }

  },
  computed:{
    currentTabComponent: function () {
      return this.currentTab
    }
  }
}
</script>

