<template>
  <section>
    <div class="container">
      <div class="columns">
        <div class="column">
          <div class="control">
            <h1 class="title">Listados Estamentos</h1>
          </div>
        </div>
      </div>
      <b-tabs @input="changeTab" type="is-boxed">
        <b-tab-item v-for="item in items"
                    :value="item.content"
                    v-bind:key="item.content"
                    :label="item.tab" >
        </b-tab-item>
      </b-tabs>
      <!-- COMPONENTE AQUI -->
      <keep-alive>
        <component v-bind:is="currentTabComponent"></component>
      </keep-alive>
      <!-- FIN COMPONENTE -->
    </div>
  </section>
</template>
<script>
import AcademicIndex from "../person/AcademicIndex.vue";
import AdministrativeIndex from "../person/AdministrativeIndex.vue";
import HourClassIndex from "../person/HourClassIndex.vue";
import FeecontractIndex from "../person/FeecontractIndex";
export default {
  name: "establishment_tabs",
  data(){
    return {
      currentTab: 'AcademicIndex',
      unit: "TODAS",
      units: [],
      items: [
        {tab: 'Académicos/as',content:'AcademicIndex'},
        {tab: 'Horas Clase',content:'HourClassIndex'},
        {tab: 'Administrativos/as',content:'AdministrativeIndex'},
        //{tab: 'Honorarios',content:'FeecontractIndex'},
      ]
    }
  },
  components:{
    AcademicIndex,
    HourClassIndex,
    AdministrativeIndex,
    FeecontractIndex
  },
  methods:{
    changeTab: function(value){
      this.currentTab = value
    },
    exportData() {
      this.isLoading = true
      let url = "/people/export_active_list.xlsx?e=" + this.currentTab
      window.open(url)
      this.isLoading = false
    }
  },
  computed: {
    currentTabComponent: function () {
      return this.currentTab
    }
  }
}
</script>