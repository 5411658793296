<template>
  <div class="">
    <div class="box">
      <div class="columns is-multiline">
        <div class="column has-text-centered">
          <div class="heading is-size-6">Publicaciones</div>
          <div class="title">{{ sizePub }}</div>
        </div>
        <div class="column has-text-centered">
          <div class="heading is-size-6">Proyectos en curso</div>
          <div class="title">{{ sizePro }}</div>
        </div>
        <div class="column has-text-centered">
          <div class="heading is-size-6">Propiedad Intelectual Concedida</div>
          <div class="title">{{ sizePat }}</div>
        </div>
      </div>
      <div class="columns is-multiline">
        
        <div class="column has-text-centered">
          <div class="heading is-size-6">Libros</div>
          <div class="title">{{ sizeBook }}</div>
        </div>
        <div class="column has-text-centered">
          <div class="heading is-size-6">Capítulos de Libro</div>
          <div class="title">{{ sizeCap }}</div>
        </div>
        <div class="column has-text-centered">
          <div class="heading is-size-6">Artículos de Libro</div>
          <div class="title">{{ sizeArti }}</div>
        </div>
      </div>
      <div class="columns is-multiline">
<!--        <div class="column has-text-centered">
          <div class="heading is-size-6">Disclosures</div>
          <div class="title">{{ sizeDis }}</div>
        </div>-->
        <div class="column has-text-centered">
          <div class="heading is-size-6">Vinculación con el medio</div>
          <div class="title">{{ sizeAct }}</div>
        </div>
        
      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props:[
      'objectId','componentType','currentYear', 'minYear', 'maxYear', 'changeFilter'
  ],
  data(){
    return{
      sizePub:null,
      sizePro:null,
      sizePat:null,
      sizeDis:null,
      sizeAct:null,
      sizeBook:null,
      sizeCap:null,
      sizeArti:null,
      isLoading:false,
      year:new Date().getFullYear(),
      year_options:Array.from({length:(new Date().getFullYear()-2000+1)},(v,k)=>k+2000).reverse()

    }
  },
  created(){
    this.getAcademicActivity();
  },
  methods:{
    getAcademicActivity(){
      this.isLoading=true;
      var url = "";
      if (this.componentType == "person"){
        url = "/people/"+this.objectId+"/size_academic_activity.json"
      }
      else if (this.componentType=="unit"){
        url = "/admin/units/"+this.objectId+"/size_academic_activity.json"
      }

      axios
          .get(url,
              {params: {
                  minYear: this.minYear,
                  maxYear: this.maxYear
                }
              })
          .then(res => {
            this.isLoading=false;
            this.sizePub = res.data.publications;
            this.sizePro = res.data.projects;
            this.sizePat = res.data.patents;
            this.sizeDis = res.data.disclosures;
            this.sizeAct = res.data.activities;
            this.sizeBook = res.data.books;
            this.sizeCap = res.data.charapters;
            this.sizeArti = res.data.articles;
            //console.log(res)
          })
          .catch(e => {
            console.log(e);
            this.isLoading=false;
          });
    }


  },
  computed:{

  },
  watch:{
    changeFilter: function(){
      this.getAcademicActivity()
    }
  }
}
</script>

