<template>

    <b-dropdown aria-role="list" class="has-text-left">
      <button class="button is-primary" slot="trigger" slot-scope="{ active }">
        <b-icon icon="plus"></b-icon>
        <span>Añadir Actividad de Investigación</span>
      </button>
      <b-dropdown-item has-link aria-role="menuitem">
        <a href="/publications/type" target="_blank">
          <b-icon icon="file-alt"></b-icon>
          Publicaciones
        </a>
      </b-dropdown-item>
      <b-dropdown-item has-link aria-role="menuitem">
        <a href="/projects/new" target="_blank">
          <b-icon icon="folder-open"></b-icon>
          Proyectos
        </a>
      </b-dropdown-item>
      <!-- #TODO DESCOMENTAR CUANDO SE PUEDAN VER PRODUCTOS
      <b-dropdown-item has-link aria-role="menuitem">
        <a href="/products/new">
          <b-icon icon="lightbulb"></b-icon>
          Productos
        </a>
      </b-dropdown-item>
      -->
      <b-dropdown-item has-link aria-role="menuitem">
        <a href="/patents/new" target="_blank">
          <b-icon icon="lightbulb"></b-icon>
          Patentes
        </a>
      </b-dropdown-item>
      <!-- <b-dropdown-item has-link aria-role="menuitem">
        <a href="/disclosures/new">
          <b-icon icon="folder"></b-icon>
          Disclosures
        </a>
      </b-dropdown-item>
      <b-dropdown-item has-link aria-role="menuitem">
        <a href="/vime_activities/new">
          <b-icon icon="calendar"></b-icon>
          Vinculación con el medio
        </a>
      </b-dropdown-item> -->
      <!-- <b-dropdown-item has-link aria-role="menuitem">
        <a href="/books/new">
          <b-icon icon="book"></b-icon>
          Libro
        </a>
      </b-dropdown-item>
      <b-dropdown-item has-link aria-role="menuitem">
        <a href="/inbooks/new?type=article">
          <b-icon icon="bookmark"></b-icon>
          Artículo de libro
        </a>
      </b-dropdown-item>
      <b-dropdown-item has-link aria-role="menuitem">
        <a href="/inbooks/new?type=chapter">
          <b-icon icon="file"></b-icon>
          Capítulo de libro
        </a>
      </b-dropdown-item> -->
    </b-dropdown>

</template>
<script>
export default {
  data(){
    return{

    }
  },
  created(){

  },
  props:[

  ],
  methods:{

  },
  computed:{

  }
}
</script>

