<template>
  <div>
    <div class="columns">
      <div class="column has-text-right">
        <b-field>
          <b-switch v-model="cardLayout">
          <span>
            Formato tarjeta (sin filtros ni búsqueda)
             <b-tooltip
                 label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                 multilined
                 position="is-left">
                  <b-icon
                      pack="fas"
                      icon="info-circle"
                      type="is-info">
                  </b-icon>
              </b-tooltip>
          </span>
          </b-switch>
        </b-field>

      </div>
    </div>
    <b-table
        :data="patentes"
        :paginated=true
        per-page=10
        striped
        hoverable
        :current-page="currentPage"
        :card-layout="cardLayout"
        :pagination-simple=false
        pagination-position="bottom"
        default-sort-direction="desc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="last_patent_state_date"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página actual"
        :opened-detailed="defaultOpenedDetails"
        detailed
        detail-key="id"
        :show-detail-icon="showDetailIcon">
      <b-table-column
          field= 'title'
          label= 'Título'
          width="20em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.title }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'patent_type'
          label= 'Tipo de Protección'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.patent_type }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'protection_code'
          label= 'Código de Protección'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.protection_code }}
        </template>
      </b-table-column>

      <b-table-column
          field= 'nationality'
          label= 'Nacionalidad'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{props.row.nationality }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'presentation_date'
          label= 'Fecha de presentación'
          searchable
          :custom-search="searchPresentationDate"
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{  props.row.presentation_date._isValid ? new Date(props.row.presentation_date).toLocaleDateString("es-CL") : '' }}
        </template>
      </b-table-column>

      <b-table-column
          field= 'last_patent_state'
          label= 'Último estado'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{props.row.last_patent_state }}
        </template>
      </b-table-column>

      <b-table-column
          field= 'last_patent_state_date'
          label= 'Fecha último estado'
          searchable
          :custom-search="searchLastPatentStateDate"
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{  props.row.last_patent_state_date._isValid ? new Date(props.row.last_patent_state_date).toLocaleDateString("es-CL") : '' }}
        </template>
      </b-table-column>
      <b-table-column
          label="Acciones"
          v-slot="props">
        <b-icon
            pack="fas"
            icon="eye"
            size="is-medium"
            @click.native="execShowModal(props.row.id)"/>
        <b-icon
            pack="fas"
            icon="trash-restore"
            size="is-medium"
            type="is-success"
            @click.native="execRestoreModal(props.row.id, props.row)"/>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>Participantes: </strong>
                <span v-for="participant in props.row.person_patents_attributes" >
                  <a v-if="participant.person_url!=null" :href="participant.person_url">{{ participant.person_name }}</a>
                  <span v-else>{{ participant.person_name }}</span>
                  <span v-if="props.row.person_patents_attributes.length>1 && participant!=props.row.person_patents_attributes.slice(-1)[0]">, </span>
                </span>
              </p>
            </div>
          </div>
        </article>
      </template>
      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-danger">
              No se encontraron resultados para el año seleccionado.
            </b-message>
          </div>
        </div>
      </template>

    </b-table>

    <b-modal
        v-model="isShowModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal_show_nullified_patent      :patentId="patentId"
                                          :cargoPrincipal="cargoPrincipal"
                                          modal-header="Patente"
                                          @close="props.close">
        </modal_show_nullified_patent>
      </template>
    </b-modal>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  </div>
</template>
<script>
import {searchDate} from "../../packs/utilities";
import moment from "moment";
import axios from "axios";
import modal_show_nullified_patent from "./modal_show_nullified_patent";
export default {
  props:[
    'unitId','hideUpdate','cargoPrincipal'
  ],
  components:{modal_show_nullified_patent},
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon:true,
      years:[],
      year:null,
      isNullifyActive: false,
      patentes:[],
      isShowModalActive: false,
      patentId: null
    }
  },
  created(){
    if(this.cargoPrincipal.name == 'ANALISTA I+D FING' || this.cargoPrincipal.name == 'SUPERADMIN'){
      this.getPatentsNullified()
    }
    else {
      this.myGetPatentsNullified()
    }
  },

  methods:{

    searchLastPatentStateDate(row,input){
      return searchDate(row.last_patent_state_date,input)
    },
    searchPresentationDate(row,input){
      return searchDate(row.presentation_date,input)
    },
    getPatentsNullified(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/patents.json", {params:{year_f:-1}})
          .then(res => {
            vm.patentes = res.data;
            vm.patentes = vm.patentes.map(patente => {
              var newPatente = patente
              newPatente["last_patent_state_date"] = moment(newPatente["last_patent_state_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newPatente["presentation_date"] = moment(newPatente["presentation_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newPatente;
            });
            vm.isLoading=false;
          })
          .catch(e => {
            vm.isLoading=false;
            console.log(e);
          });
    },
    myGetPatentsNullified(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/patents/my_nullified_patents.json")
          .then(res => {
            vm.patentes = res.data;
            vm.patentes = vm.patentes.map(patente => {
              var newPatente = patente
              newPatente["last_patent_state_date"] = moment(newPatente["last_patent_state_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newPatente["presentation_date"] = moment(newPatente["presentation_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newPatente;
            });
            vm.isLoading=false;
          })
          .catch(e => {
            vm.isLoading=false;
            console.log(e);
          });
    },
    execShowModal(id){
      this.patentId = id
      this.isShowModalActive = true
    },
    execRestoreModal(id, row){
      let vm = this;
      vm.$buefy.dialog.confirm({
        title: 'Restaurar patente',
        message: '¿Está seguro que desea <b>restaurar</b> la patente? Esta acción revertirá la anulación.',
        confirmText: 'Restaurar Patente',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          vm.isLoading = true
          axios
              .put("/patents/" + id + "/restore_patent.json")
              .then(res => {
                vm.$buefy.dialog.alert({
                  message: 'Patente restaurada correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                var index = this.patentes.indexOf(row)
                this.patentes.splice(index, 1)
                vm.isLoading = false;
              }).catch(error => {
            console.log(error)
            vm.$buefy.dialog.alert({
              message: '<b>Patente no pudo ser restaurado</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            vm.isLoading = false;
            vm.isRestoreActive = false;
          })
        }
      })
    },

  },
  computed:{

  }
}
</script>

