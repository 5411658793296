<template>
  <div class="section">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <div class="field is-grouped">
          <p class="control">
            <b-button class="is-primary is-outlined"
                      @click="isAcademic = true">
              Académico/a
            </b-button>
          </p>
          <p class="control">
            <b-button class="is-primary is-outlined"
                      @click="isHours = true">
              Horas Clases
            </b-button>
          </p>
          <p class="control">
            <b-button class="is-primary is-outlined"
                      @click="isAdmin = true">
              Administrativo/a
            </b-button>
          </p>
        </div>
      </section>

    </div>
    <footer class="modal-card-foot actions">
      <div class="field is-grouped is-pulled-right">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
      </div>
    </footer>

    <b-modal
        v-model="isAcademic"
        has-modal-card
        trap-focus
        width="1024"
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-academic-app-form :person-id="personId"
                                 query-type="create"
                                 :modal-header="'Nuevo Nombramiento Académico - '  +person_name"
                                 :person_name="person_name"
                                 @close="props.close"></modal-academic-app-form>
      </template>
    </b-modal>
    <b-modal
        v-model="isHours"
        has-modal-card
        trap-focus
        width="1024"
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-hours-app-form :person-id="personId"
                              query-type="create"
                              :modal-header="'Nuevo Nombramiento Horas Clase - '  +person_name"
                              :person_name="person_name"
                              @close="props.close"></modal-hours-app-form>
      </template>
    </b-modal>
    <b-modal
        v-model="isAdmin"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        width="1024"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-administrative-app-form :person-id="personId"
                                       query-type="create"
                                       :modal-header="'Nuevo Nombramiento Administrativo - '  +person_name"
                                       :person_name="person_name"
                                       @close="props.close"></modal-administrative-app-form>
      </template>
    </b-modal>

    <b-modal
        v-model="isFeeContract"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        width="1024"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-feecontract-appointment-form :person-id="personId"
                                       query-type="create"
                                      :modal-header="'Nuevo Nombramiento Honorarios - '  +person_name"
                                            :person_name="person_name"
                                       @close="props.close"></modal-feecontract-appointment-form>
      </template>
    </b-modal>

  </div>

</template>

<script>
import ModalAcademicAppForm from '../life_record/modal_academic_appointment_form'
import ModalHoursAppForm from '../life_record/modal_hour_appointment_form'
import ModalAdministrativeAppForm from '../life_record/modal_administrative_appointment_form'
import ModalFeecontractAppointmentForm from '../life_record/modal_feecontract_appointment_form'
export default {
  name: "select_appointment_establishment",
  props:[
    'queryType', 'personId', 'modalHeader', 'person_name'
  ],
  components:{
    ModalAcademicAppForm,
    ModalHoursAppForm,
    ModalAdministrativeAppForm,
    ModalFeecontractAppointmentForm
  },
  data(){
    return{
      isAcademic: false,
      isHours: false,
      isAdmin: false,
      isFeeContract: false,
      isLoading: true,
      canCancel: []
    }
  },
  created() {
    this.isLoading =false
  },
  methods:{

  }
}
</script>

<style scoped>

</style>
