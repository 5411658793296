<template>
    <div class="showacademicinformation">
        <div v-if='Object.entries(this.units).length === 0 '>
            <h1>El egresado seleccionado no presenta un registro académico</h1>            
        </div>
        <div v-else>
            <div class="container">
                <div class="columns">
                    <div class="column">
                        <table class="table is-primary">
                            <thead>
                                <tr style="text-align:center;">
                                    <th> Departamento académico </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="color: #000;text-align:center;" v-for="departamento in this.units" :key="departamento.id">
                                    <td> {{departamento.name}} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="column">
                        <div v-if='carrers.length !== 0'>
                            <table class="table is-primary">
                                <thead>
                                    <tr style="text-align:center;">
                                        <th> Carrera </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style="color: #000;text-align:center;" v-for="carrera in this.carrers" :key="carrera.id">
                                        <td> {{carrera.name}} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else>
                            <table class="table is-primary">
                                <thead>
                                    <tr style="text-align:center;">
                                        <th> Carrera </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style="color: #000;text-align:center;">
                                        <td> No hay carreras registradas </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ShowAcademicInformation',
    props:{
        units:{
            type:Array,
            default: []
        },
        carrers:{
            type:Array,
            default: []
        }
    }
}
</script>