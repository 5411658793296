<template>
<section>
  <b-tabs position="is-centered" type="is-toggle-rounded" v-model="activeTab">
    <b-tab-item label="Proyectos Asociados">
      <div class="box">
        <projects-table
            :proyectos="associated_projects" @updateProjects="updateProjects" :cargo-principal="''"></projects-table>
      </div>
    </b-tab-item>
    <b-tab-item label="Proyectos con financiamiento">
      <financed-projects-tab v-if="clicked_financed" v-bind:entity="entity" :project_type="'financiados'"></financed-projects-tab>
    </b-tab-item>
    <b-tab-item v-if="entity.show_postulated_projects" label="Proyectos postulados relacionados">
      <financed-projects-tab v-if="clicked_associated_postulated" v-bind:entity="entity" :project_type="'postulados_asociados'"></financed-projects-tab>
    </b-tab-item>
    <b-tab-item v-if="entity.show_postulated_projects" label="Proyectos postulados con aportes">
      <financed-projects-tab v-if="clicked_postulated" v-bind:entity="entity" :project_type="'postulados_con_aporte'"></financed-projects-tab>
    </b-tab-item>

  </b-tabs>

  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

</section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import ProjectsTable from "/app/javascript/components/projects/projects_table";
import FinancedProjectsTab from './FinancedProjectsTab';
export default {
  name: "AssociatedProjectsTab",
  data(){
    return{
      errores_validacion:{},
      associated_projects:[],
      isLoading:false,
      year:new Date().getFullYear(),
      activeTab: 0,
      clicked_financed:false,
      clicked_postulated:false,
      clicked_associated_postulated:false,
    }
  },
  props:['entity'],
  components:{ ProjectsTable, FinancedProjectsTab },
  created() {
    this.getProjectFinancings(this.year);
  },
  methods:{
    getProjectFinancings(year){
      let vm = this;
      vm.isLoading=true;
      return axios
        .get("/partner_entities/"+vm.entity.id+"/associated-projects.json",{params:{year_f:year}})
        .then(res => {
          vm.associated_projects = res.data;
          vm.associated_projects = vm.associated_projects.map(proyecto => {
            var newProyecto = proyecto
            newProyecto["start_date"] =  moment(newProyecto["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
            newProyecto["end_date"] =  moment(newProyecto["end_date"] , ["YYYY-MM-DD","DD-MM-YYYY"]);
            return newProyecto;
          });
          vm.isLoading=false;
        })
        .catch(e => {
          //console.log(e);
          vm.isLoading=false;
        });
    },
    updateProjects(year){
      this.getProjectFinancings(year);
    }
  },
  watch:{
    activeTab:function(){
      if(this.activeTab==1){
        this.clicked_financed = true;
      }
      if(this.activeTab==2){
        this.clicked_associated_postulated = true;
      }
      if(this.activeTab==3){
        this.clicked_postulated = true;
      }
    }
  }

}
</script>

<style scoped>

</style>