<template>
    <section>

        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
                <b-message type="is-danger" has-icon>
                    <div v-for="(item, index) in errores_validacion" :key="index">
                        {{ item[0] }}
                    </div>
                </b-message>
            </div>

            <form @submit.prevent="handleSubmit(saveMyAcademicData)">

                <div class="columns">
                    <div class="column has-text-right">
                        <b-button type="is-word-color" icon-left="file-word" label="Descargar Curriculum Word"
                            @click="remember(); format = 'word'" />
                        <b-button type="is-pdf-color has-text-white" icon-left="file-pdf"
                            label="Descargar Curriculum PDF" @click="remember(); format = 'pdf'" />
                    </div>
                </div>

                <div class="columns">
                    <div class="column">
                        <ValidationProvider name="Nombre Investigación Principal" rules="min:3|max:125"
                            v-slot="{ errors, failedRules }">
                            <b-field label="Nombre Investigación Principal"
                                :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                <b-input type="text" placeholder="Ingrese Nombre de su Investigación Principal"
                                    v-model="person.title" />
                            </b-field>
                        </ValidationProvider>

                    </div>
                </div>

                <div class="columns">
                    <div class="column">
                        <ValidationProvider name="Reseña de la Línea de Investigación" rules="min:3|max:500"
                            v-slot="{ errors, failedRules }">
                            <b-field label="Reseña de la Línea de Investigación"
                                :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                <b-input type="textarea"
                                    placeholder="Ingrese una descripción sobre su Línea de Investigación."
                                    v-model="person.line_research_biography" />
                            </b-field>
                        </ValidationProvider>
                    </div>
                </div>



                <div class="columns">

                    <div class="column">
                        <ValidationProvider ref="researchLines" name="Líneas de Investigación (Expertiz)"
                            v-slot="{ errors, failedRules }">
                            <b-field label="Líneas de Investigación (Expertiz)"
                                :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                <b-taginput v-model="person.key_words_attributes" :data="filtered_key_words"
                                    :allow-new="true" :allow-duplicates="false" field="name" icon="tag" autocomplete
                                    placeholder="Agrega una expertiz" :beforeAdding="beforeAddTag"
                                    @typing="getFilteredKeyWords" />
                            </b-field>
                        </ValidationProvider>

                    </div>

                    <div class="column">
                        <ValidationProvider name="Link Propio" rules="min:3|max:255|protocol" v-slot="{ errors, failedRules }">
                            <b-field label="Link Propio" :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                <b-input type="link" expanded placeholder="Ingrese Link Propio" v-model="person.link" />
                                <b-button type="is-link" icon-left="external-link-alt" label="Link" tag="a"
                                    :href="person.link ? person.link : ''" target="_blank" />
                            </b-field>
                        </ValidationProvider>

                    </div>

                </div>

                <div v-if="person.can_update" class="columns">
                    <div class="column has-text-right">
                        <button type="submit" class="button is-primary" @click="checkValidation">
                            <span class="icon">
                                <i class="fas fa-save"></i>
                            </span>
                            <span>Guardar Datos Curriculum VIME Corto</span>
                        </button>
                    </div>
                </div>

            </form>

        </ValidationObserver>

        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

        <img id="logo" src="/logo_negro_facultad_ing.png" style="display: none;" />
        <img id="fondo" src="/fondo_curriculum_corto.png" style="display: none;" />
        <img id="mail" src="/mail_icon.png" style="display: none;" />
        <img id="mundo" src="/icono_mundo2.png" style="display: none;" />
        <img id="photo" :src="this.person.photo_url" style="display: none;" />
        <img id="pfp" src="/blank_profile_photo.png" style="display: none;" @load="loadImage" />
        <img id="linea" src="/linea_divisora.png" style="display: none;" />

    </section>
</template>

<script>
import axios from "axios"
import moment from "moment"
import jsPDF from 'jspdf'
import { TemplateHandler } from 'easy-template-x'
import { saveAs } from "file-saver"
import {BrandonGrotesqueMedium} from "./fonts/BrandonGrotesque-Medium-normal.js"
import {BrandonGrotesqueBlack} from "./fonts/BrandonGrotesque-Black-normal"
import {BrandonGrotesqueBold} from "./fonts/BrandonGrotesque-Bold-normal"
import {BrandonGrotesqueRegular} from "./fonts/BrandonGrotesque-Regular-normal"
export default {
    props: [
        'personId', 'cargoPrincipal', 'canCreateDocument'
    ],
    data() {
        return {
            isLoading: false,
            person: {},
            key_words: [],
            filtered_key_words: [],
            errores_validacion: [],

            publications: [],
            projects: [],
            patents: [],
            disclosures: [],
            vime_activities: [],
            books: [],
            inbooks: [],

            reference_project: [],
            reference_publication: [],
            reference_publication_journal: [],
            reference_publication_conference: [],
            reference_publication_book: [],
            reference_publication_inbook: [],
            reference_publication_otros: [],
            reference_patent: [],
            reference_disclosure: [],
            reference_book: [],
            reference_inbook: [],
            reference_inbook_charapter: [],
            reference_inbook_article: [],

            exten_projects: [],
            exten_publications: [],
            exten_patents: [],
            exten_disclosures: [],
            exten_vime_activities: [],
            exten_books: [],
            person_studies: [],
            person_jobs: [],

            format: '',
        }
    },

    async created() {
        await this.getPerson();
    },

    methods: {

        async checkValidation() {
            const valid = await this.$refs.observer.validate()
            if (!valid) {
                this.$buefy.dialog.alert({
                    message: 'Algunos campos presentan errores.',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
            }
        },

        saveMyAcademicData() {
            this.isLoading = true
            axios
                .put("/people/" + this.personId + "/my-academic-data/update.json", {
                    person: this.person,
                },
                )
                .then(response => {
                    this.errores_validacion = []
                    this.$buefy.dialog.alert({
                        title: 'Logrado',
                        message: 'Se han guardado satisfactoriamente sus datos de Curriculum VIME Corto.',
                        type: 'is-success',
                        hasIcon: true,
                        iconPack: 'fa',
                        icon: 'check-circle',
                        confirmText: 'Ok',
                    })
                    this.isLoading = false
                    this.getPerson()
                }).
                catch(error => {
                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al guardar sus datos de Curriculum VIME Corto, favor revisar la consola.',
                        type: 'is-danger',
                        hasIcon: true,
                        iconPack: 'fa',
                        icon: 'times-circle',
                        confirmText: 'Ok',
                    })
                    this.errores_validacion = error.response.data
                    var elmnt = document.getElementById("app")
                    elmnt.scrollIntoView()
                    this.isLoading = false
                })
        },
        queryError(vm) {
            vm.$buefy.dialog.alert({
                title: 'Error',
                message: 'Hubo un error en su solicitud.',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
            })
        },

        async getPerson() {
            this.isLoading = true;
            await axios
                .get("/people/my-vime-short-resume.json", {params: {
                    id: this.personId
                }})
                .then(res => {
                    this.person = res.data
                    this.getPeopleKeywords()
                    this.loadImage()
                })
                .catch(e => {
                    console.log(e);
                    this.queryError(this);
                    this.isLoading = false;
                });
        },
        async getPeopleKeywords() {
            this.isLoading = true;
            await axios
                .get("/people/keywords.json")
                .then(res => {
                    this.key_words = res.data
                    //this.isLoading = false;
                })
                .catch(e => {
                    console.log(e);
                    this.queryError(this);
                    this.isLoading = false;
                });
        },

        getFilteredKeyWords(text) {
            this.filtered_key_words = this.key_words.filter((option) => {
                return option.name
                    .toString()
                    .toLowerCase()
                    .indexOf(text.toLowerCase()) >= 0
            })
        },

        addTags(value) {
            var aux = 0
            var key_words_attributes = this.person.key_words_attributes
            var temp_key_word = this.person.key_words_attributes
            if (value != null && value.length > 0) {
                value.forEach(function (tag) {
                    aux = 0
                    var auxTag = tag.toUpperCase()
                    auxTag = auxTag.trim()
                    temp_key_word.forEach(function (word) {
                        if (word.constructor.name === 'String') {
                            if (word === auxTag) {
                                aux = 1
                            }
                        }
                        else {
                            if (word.name === auxTag) {
                                aux = 1
                            }
                        }
                    })
                    if (aux == 0) {
                        key_words_attributes.push(auxTag)
                    }
                })
                this.person.key_words_attributes = key_words_attributes
            }else if(value != null || value.length > 0) {

                this.$refs.researchLines.setErrors(['La Linea de Investigación Ingresada no debe de ser nula'])

            } else {

                this.$refs.researchLines.setErrors(['La Linea de Investigación Ingresada no debe superar los 255 caracteres'])

            }
        },

        beforeAddTag(tags) {

            if (tags.constructor.name === 'String') {
                var splitTags = tags.split(",")

                if (splitTags.length == 1) {
                    splitTags = tags.split(";")
                }

                this.addTags(splitTags)
            }
            else {
                var result = this.person.key_words_attributes.filter(tag => tag.name === tags.name)
                if (result.length == 0) {
                    this.person.key_words_attributes.push(tags)
                }
            }
        },

        async loadImage() {
            try {
                this.isLoading = true
                setTimeout(() => {
                    //Metodo para hacer redonda la foto de perfil
                    var c = document.createElement('canvas');
                    var img = document.getElementById('pfp')

                    if (this.person.photo_url != null) {
                        img = document.getElementById('photo');
                    }
                    //var img = document.createElement('img');
                    //img.src = img_path

                    if (img.height == 0) {
                        img = document.getElementById('pfp')
                    }

                    c.height = 805;
                    c.width = 805;
                    var ctx = c.getContext('2d');
                    ctx.save();
                    ctx.beginPath();
                    ctx.arc(402.5, 402.5, 402.5, 0, Math.PI * 2, true);
                    ctx.closePath();
                    ctx.clip();
                    ctx.drawImage(img, 0, 0, 805, 805);
                    ctx.beginPath();
                    ctx.arc(0, 0, 402.5, 0, Math.PI * 2, true);
                    ctx.clip();
                    ctx.closePath();
                    ctx.restore();
                    this.imageRounded = c.toDataURL();
                }, 2000);
                setTimeout(() => {
                    this.isLoading = false
                }, 3000);
            }
            catch (err) {
                console.log('Errores:')
            }
        },

        remember() {
            this.$buefy.dialog.confirm({
                title: 'Recordatorio',
                message: 'Recuerde <b>Guardar</b> sus datos de Curriculum VIME Corto',
                confirmText: 'Descargar Curriculum VIME Corto',
                cancelText: 'Cancelar',
                type: 'is-warning',
                hasIcon: true,
                iconPack: 'fa',
                icon: 'exclamation-triangle',
                onConfirm: async () => {
                    this.getPublicationsShort()
                }
            })
        },

        async getPublicationsShort() {
            this.isLoading = true
            axios
                .get("/people/" + this.personId + "/publications.json", { params: { year_f: 'Todo' } })
                .then(res => {
                    this.publications = res.data
                    this.publications = this.publications.map(publicacion => {
                        var publicacion = publicacion
                        publicacion["publication_date"] = moment(publicacion.publication_date, ["YYYY-MM-DD", "DD-MM-YYYY"]);;
                        return publicacion;
                    });

                    if (this.publications.length > 0) {
                        this.publications.every(publication => {
                            var autores = ''
                            var name = ''
                            var revista = ''
                            var volumen = ''
                            var fecha = ''
                            var pag_ini = ''
                            var pag_fin = ''
                            var doi = ''
                            var ref = ''

                            if (publication.participants_references != null) {
                                autores = publication.participants_references + '.'
                            }
                            if (publication.title != null) {

                                if (publication.participants_references == null) {
                                    name = publication.title + '.'
                                }
                                else {
                                    name = " " + publication.title + '.'
                                }
                            }
                            if (publication.magazine != null) {
                                revista = " " + publication.magazine + '.'
                            }
                            if (publication.volumen != null) {
                                volumen = " " + publication.volumen + '.'
                            }
                            if (publication.publication_date._isValid == true) {
                                if (publication.volumen == null) {
                                    fecha = "(" + publication.publication_date.year() + ")."
                                }
                                else {
                                    fecha = " (" + publication.publication_date.year() + ")."
                                }
                            }
                            if (publication.start_page != null) {
                                pag_ini = " " + publication.start_page + '-'
                            }
                            if (publication.end_page != null) {
                                if (publication.start_page == null) {
                                    pag_fin = "-" + publication.end_page + '.'
                                }
                                else {
                                    pag_fin = publication.end_page + '.'
                                }
                            }
                            if (publication.doi != null) {
                                doi = " " + publication.doi + '.'
                            }
                            ref = "· " + autores + name + revista + volumen + fecha + pag_ini + pag_fin + doi
                            this.reference_publication.push({ ref_name: ref })
                            if (this.reference_publication.length == 5) {
                                return false
                            }
                            else {
                                return true
                            }
                        })

                    }

                    this.getProjectsShort()
                })
                .catch(e => {
                    console.log(e);
                    this.isLoading = false;
                })
        },

        async getProjectsShort() {
            axios
                .get("/people/" + this.personId + "/projects.json", { params: { year_f: 'Todo' } })
                .then(res => {
                    this.projects = res.data
                    this.projects = this.projects.map(proyecto => {
                        var proyecto = proyecto
                        proyecto["start_date"] = moment(proyecto.start_date, ["YYYY-MM-DD", "DD-MM-YYYY"]);
                        proyecto["end_date"] = moment(proyecto.end_date, ["YYYY-MM-DD", "DD-MM-YYYY"]);
                        return proyecto;
                    });
                    if (this.projects.length > 0) {
                        this.projects.every(project => {
                            var start_year = ''
                            var end_year = ''
                            var name = ''
                            if (project.start_date._isValid == true) {
                                start_year = project.start_date.year()
                            }
                            if (project.end_date._isValid == true) {
                                end_year = project.end_date.year()
                            }

                            if (start_year == '' && end_year != '') {
                                name = "· " + project.name + ", " + project.code + ", ( -" + end_year + ")"
                            }

                            else if (start_year != '' && end_year == '') {
                                name = "· " + project.name + ", " + project.code + ", (" + start_year + "- )"
                            }
                            else if (start_year != '' && end_year != '') {
                                name = "· " + project.name + ", " + project.code + ", (" + start_year + "-" + end_year + ")"
                            }
                            else {
                                name = "· " + project.name + ", " + project.code
                            }
                            this.reference_project.push({ ref_name: name })
                            if (this.reference_project.length == 5) {
                                return false
                            }
                            else {
                                return true
                            }

                        })
                    }
                    if (this.format == 'pdf') {
                        this.downloadShortPdf()
                    }
                    if (this.format == 'word') {
                        this.downloadShortWord()
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.isLoading = false;
                });
        },

        async downloadShortPdf() {
            //Se crea el documento
            const doc = new jsPDF({
                orientation: "landscape",
                unit: 'mm',
                format: [338.7, 190.5]
            });

            //Se agregan las fuentes del curriculum de ejemplo enviado por VIME
            doc.addFileToVFS('BrandonGrotesque-Medium-normal.ttf', BrandonGrotesqueMedium());
            doc.addFileToVFS('BrandonGrotesque-Black-normal.ttf', BrandonGrotesqueBlack());
            doc.addFileToVFS('BrandonGrotesque-Bold-normal.ttf', BrandonGrotesqueBold());
            doc.addFileToVFS('BrandonGrotesque-Regular-normal.ttf', BrandonGrotesqueRegular());
            doc.addFont('BrandonGrotesque-Medium-normal.ttf', 'BrandonGrotesque-Medium', 'normal');
            doc.addFont('BrandonGrotesque-Black-normal.ttf', 'BrandonGrotesque-Black', 'normal');
            doc.addFont('BrandonGrotesque-Bold-normal.ttf', 'BrandonGrotesque-Bold', 'normal');
            doc.addFont('BrandonGrotesque-Regular-normal.ttf', 'BrandonGrotesque-Regular', 'normal');

            //Funcion
            String.prototype.toProperCase = function () {
                return this.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
            };

            //Se agregan las keywords de las personas
            var expertice = ''
            this.person.key_words_attributes.forEach(element => {
                if (expertice == '') {
                    expertice = element.name.toProperCase()
                }
                else {
                    expertice = expertice + ", " + element.name.toProperCase()
                }
            })

            //Se llama a funcion que genera imagen de perfil redonda
            //var tmpCanvas = await this.loadImage(img_path)


            //Variable que guarda el ORCID
            var orcid = this.person.orcid
            if (orcid == null) {
                orcid = 'ORCID'
            }

            var h_index = this.person.h_index == null ? '' : this.person.h_index
            var biography = this.person.line_research_biography == null ? '' : this.person.line_research_biography
            var link = this.person.link == null ? '' : this.person.link
            var title = this.person.title == null ? '' : this.person.title


            var canvasLogo = document.createElement('canvas');
            var imgLogo = document.getElementById('logo');
            canvasLogo.height = imgLogo.naturalHeight;
            canvasLogo.width = imgLogo.naturalWidth;
            var ctxLogo = canvasLogo.getContext('2d');
            ctxLogo.drawImage(imgLogo, 0, 0, canvasLogo.width, canvasLogo.height)
            var dataLogo = canvasLogo.toDataURL();
            doc.addImage(dataLogo, "PNG", 269.2, 14.93, 54.27, 13.55);

            //Se agrega la imagen del fondo de los datos personales
            var canvasFondo = document.createElement('canvas');
            var imgFondo = document.getElementById('fondo');
            canvasFondo.height = imgFondo.naturalHeight;
            canvasFondo.width = imgFondo.naturalWidth;
            var ctxFondo = canvasFondo.getContext('2d');
            ctxFondo.drawImage(imgFondo, 0, 0, canvasFondo.width, canvasFondo.height)
            var dataFondo = canvasFondo.toDataURL();

            doc.addImage(dataFondo, "PNG", 0, 0, 105.92, 190.5);


            //Se agrega y se crea el espacio para la foto de perfil
            doc.setFillColor('#b10f6d')

            if (this.person.link != null && this.person.h_index != null) {
                doc.circle(52.96, 45.23, 30.3, 'F');
            }
            else {
                doc.circle(52.84, 52.84, 37.91, 'F');
            }

            doc.setFont("BrandonGrotesque-Bold", "normal");
            var startingPage = doc.internal.getCurrentPageInfo().pageNumber;

            //Se guarda la url de la foto de perfil
            var img_path = this.person.rounded_photo_url
            if (img_path != null) {
                img_path = "/blank_profile_photo.png"
                var foto_id = 'photo'
            } else {
                var foto_id = 'pfp'
            }

            var imgPhoto = document.getElementById(foto_id);

            if (imgPhoto.height == 0) {
                this.$buefy.dialog.alert({
                    message: 'Ocurrio un error con la foto de perfil, el curriculum corto se descargó con una foto predeterminada.',
                    type: 'is-warning',
                    hasIcon: true,
                    icon: 'exclamation-triangle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
            }

            if (this.person.link != null && this.person.h_index != null) {
                doc.addImage(this.imageRounded, 'PNG', 25.16, 17.43, 55.6, 55.6);
            }
            else {
                doc.addImage(this.imageRounded, 'PNG', 18.76, 18.76, 68.16, 68.16);
            }

            //Se agrega la imagen del icono de mail
            var canvasMail = document.createElement('canvas');
            var imgMail = document.getElementById('mail');
            canvasMail.height = imgMail.naturalHeight;
            canvasMail.width = imgMail.naturalWidth;
            var ctxMail = canvasMail.getContext('2d');
            ctxMail.drawImage(imgMail, 0, 0, canvasMail.width, canvasMail.height)
            var dataMail = canvasMail.toDataURL();

            //Se agrega la imagen del icono del mundo
            var canvasMundo = document.createElement('canvas');
            var imgMundo = document.getElementById('mundo');
            canvasMundo.height = imgMundo.naturalHeight;
            canvasMundo.width = imgMundo.naturalWidth;
            var ctxMundo = canvasMundo.getContext('2d');
            ctxMundo.drawImage(imgMundo, 0, 0, canvasMundo.width, canvasMundo.height)
            var dataMundo = canvasMundo.toDataURL();

            var start_name = 90
            if (this.person.link != null && this.person.h_index != null) {
                start_name = 75.53
            }

            //Tabla con el nombre de la persona
            doc.autoTable({
                startY: start_name,
                theme: 'plain',
                columnStyles: { 0: { halign: 'center', font: 'BrandonGrotesque-Black', fontStyle: 'normal', fontSize: 20, textColor: [255, 255, 255] } },
                margin: { top: 0, left: 14.93, right: 247.95 },
                body: [[this.person.person_name_curriculum]],
            })

            //Tabla con el mail de la persona
            doc.autoTable({
                startY: doc.previousAutoTable.finalY,
                theme: 'plain',
                didDrawCell: (data) => {
                    if (data.section === 'body' && data.row.index === 0) {
                        if (data.column.index === 0) {
                            doc.setFillColor('#FFFFFF')
                            doc.circle(data.cell.x + 8.75, data.cell.y + 3.75, 3.75, 'F');
                            doc.addImage(dataMail, 'JPEG', data.cell.x + 6, data.cell.y + 1, 5.5, 5.5)
                        }
                    }
                },
                columnStyles: {
                    1: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: 12.5, textColor: [255, 255, 255] },
                    0: { halign: 'right', cellWidth: 15 }
                },
                margin: { top: 0, left: 14.93, right: 247.95 },
                body: [[[], [this.person.corporate_mail]]],
            })

            var start_datos_basicos = doc.previousAutoTable.finalY
            //Tabla con el ORCID de la persona
            if (this.person.orcid != null) {
                doc.autoTable({
                    startY: start_datos_basicos,
                    theme: 'plain',
                    didDrawCell: (data) => {
                        if (data.section === 'body' && data.row.index === 0) {
                            if (data.column.index === 0) {
                                doc.setFillColor('#FFFFFF')
                                doc.circle(data.cell.x + 8.75, data.cell.y + 3.75, 3.75, 'F');
                                doc.setTextColor(0, 57, 99)
                                doc.setFont("BrandonGrotesque-Bold", "normal");
                                doc.setFontSize(12.5)
                                doc.text('ID', data.cell.x + 6.5, data.cell.y + 5.5)
                            }
                        }
                    },
                    columnStyles: {
                        1: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: 12.5, textColor: [255, 255, 255] },
                        0: { halign: 'right', cellWidth: 15 }
                    },
                    margin: { top: 0, left: 14.93, right: 247.95 },
                    body: [[[], [this.person.orcid]]],
                })
                start_datos_basicos = doc.previousAutoTable.finalY
            }

            //Tabla con el h-index de la persona
            if (this.person.h_index != null) {
                doc.autoTable({
                    startY: start_datos_basicos,
                    theme: 'plain',
                    didDrawCell: (data) => {
                        if (data.section === 'body' && data.row.index === 0) {
                            if (data.column.index === 0) {
                                doc.setFillColor('#FFFFFF')
                                doc.circle(data.cell.x + 8.75, data.cell.y + 3.75, 3.75, 'F');
                                doc.setTextColor(0, 57, 99)
                                doc.setFontSize(20)
                                doc.setFont("BrandonGrotesque-Bold", "normal");
                                doc.text('#', data.cell.x + 6.8, data.cell.y + 6.3)
                            }
                        }
                    },
                    columnStyles: {
                        1: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: 12.5, textColor: [255, 255, 255] },
                        0: { halign: 'right', cellWidth: 15 }
                    },
                    margin: { top: 0, left: 14.93, right: 247.95 },
                    body: [[[], ['h-index: ' + this.person.h_index]]],
                })
                start_datos_basicos = doc.previousAutoTable.finalY
            }

            //Tabla con el link de la persona
            if (this.person.link != null) {
                doc.autoTable({
                    startY: start_datos_basicos,
                    theme: 'plain',
                    didDrawCell: (data) => {
                        if (data.section === 'body' && data.row.index === 0) {
                            if (data.column.index === 0) {
                                doc.setFillColor('#FFFFFF')
                                doc.circle(data.cell.x + 8.75, data.cell.y + 3.75, 3.75, 'F');
                                doc.setTextColor(0, 57, 99)
                                doc.addImage(dataMundo, 'JPEG', data.cell.x + 6.1, data.cell.y + 1, 5.5, 5.5)
                            }
                            else if (data.column.index === 1) {
                                doc.setTextColor(255, 255, 255)
                                doc.setFontSize(12.5)
                                var texto = doc.splitTextToSize(this.person.link, 58)

                                var x = 1.75
                                var y = 5.5

                                texto.forEach(element => {
                                    doc.textWithLink(element, data.cell.x + x, data.cell.y + y, { url: this.person.link })
                                    y = y + 5.3
                                })

                            }
                        }
                    },
                    columnStyles: {
                        1: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: 12.5, textColor: [255, 255, 255] },
                        0: { halign: 'right', cellWidth: 15 }
                    },
                    margin: { top: 0, left: 14.93, right: 247.95 },
                    body: [[[], []]],
                })
                start_datos_basicos = doc.previousAutoTable.finalY
            }

            var person_unit = 'SIN INFORMACION'

            if (this.person.person_unit_name != null || this.person.person_unit_name != undefined) {
                person_unit = this.person.person_unit_name
            }
            //Tabla con la unidad

            if (this.person.link != null) {
                var texto = doc.splitTextToSize(this.person.link, 58)
                var add_space = 5.3 * (texto.length - 1)
            }
            else {
                var add_space = 0
            }
            doc.autoTable({
                startY: doc.previousAutoTable.finalY + 5 + add_space,
                theme: 'plain',
                columnStyles: { 0: { halign: 'center', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 12.5, textColor: [255, 255, 255] } },
                margin: { left: 0, right: 232.75 },
                body: [
                    [{ content: person_unit, styles: { halign: 'center', font: 'BrandonGrotesque-Bold', fillColor: [0, 57, 99], fontStyle: 'regular', fontSize: 12.5, textColor: [255, 255, 255] } }],
                ]
            })

            //Tabla con los datos de las keywords
            if (expertice != '') {
                doc.autoTable({
                    startY: doc.previousAutoTable.finalY,
                    theme: 'plain',
                    columnStyles: { 0: { halign: 'center', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 12.5, textColor: [255, 255, 255] } },
                    margin: { left: 0, right: 232.75 },
                    body: [
                        ['EXPERTIZ'],
                        [{ content: expertice, styles: { halign: 'center', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: 10.5, textColor: [255, 255, 255] } }],
                    ]
                })
            }



            doc.setPage(1)
            var font_size = 11.5

            if ((this.reference_project.length + this.reference_publication.length) > 7) {
                font_size = 8.5
            }

            doc.autoTable({
                startY: 14.93,
                theme: 'plain',
                didDrawCell: (data) => {
                },
                columnStyles: { 0: { halign: 'left', font: 'BrandonGrotesque-Black', fontStyle: 'normal', fontSize: 20, textColor: [33, 57, 103] } },
                margin: { top: 96.75, left: 117.95, right: 73.9 },
                body: [
                    [title],
                ],
            })

            var start = 30
            if (doc.previousAutoTable.finalY < 30) {
                start = 30
            } else {
                start = doc.previousAutoTable.finalY
            }

            if (biography != '') {
                doc.autoTable({
                    startY: start,
                    theme: 'plain',
                    didDrawCell: (data) => {
                    },
                    columnStyles: { 0: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: 10, textColor: [33, 57, 103] } },
                    margin: { top: 96.75, left: 117.95, right: 14.93 },
                    body: [
                        [biography],
                    ],
                })
                start = doc.previousAutoTable.finalY
            }

            //Se agrega imagen de la linea divisora
            var canvasLine = document.createElement('canvas');
            var imgLine = document.getElementById('linea');
            canvasLine.height = imgLine.naturalHeight;
            canvasLine.width = imgLine.naturalWidth;
            var ctxLine = canvasLine.getContext('2d');
            ctxLine.drawImage(imgLine, 0, 0, canvasLine.width, canvasLine.height)
            var dataLine = canvasLine.toDataURL();

            if (this.reference_project.length > 0) {
                //Tabla con los ultimos 5 proyectos de la persona
                doc.autoTable({
                    startY: start,
                    theme: 'plain',
                    columns: [
                        { dataKey: 'ref_name', header: '' },
                    ],
                    headStyles: { halign: 'left', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 11.5, textColor: [33, 57, 103] },
                    head: [['Últimos proyectos']],
                    columnStyles: { 0: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: font_size, textColor: [33, 57, 103] } },
                    margin: { top: 29, left: 117.95, right: 14.93 },
                    body: this.reference_project
                })

                doc.autoTable({
                    startY: doc.previousAutoTable.finalY,
                    theme: 'plain',
                    didDrawCell: (data) => {
                        if (data.section === 'body' && data.row.index === 0) {
                            doc.addImage(dataLine, 'PNG', data.cell.x, data.cell.y + 3.5, 205.12, 0.4)
                        }
                    },
                    columnStyles: { 0: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: 11.5, textColor: [33, 57, 103] } },
                    margin: { top: 29, left: 117.95, right: 14.93 },
                    body: [
                        [{ content: '', styles: { halign: 'left', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 11.5, textColor: [33, 57, 103] } }],
                    ]
                })
                start = doc.previousAutoTable.finalY
            }

            if (this.reference_publication.length > 0) {
                //Tabla con las ultimas 5 publicaciones en formato referencia
                doc.autoTable({
                    startY: doc.previousAutoTable.finalY,
                    theme: 'plain',
                    columns: [
                        { dataKey: 'ref_name', header: '' },
                    ],
                    headStyles: { halign: 'left', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 11.5, textColor: [33, 57, 103] },
                    head: [['Últimas publicaciones']],
                    columnStyles: { 0: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: font_size, textColor: [33, 57, 103] } },
                    margin: { top: 29, left: 117.95, right: 14.93 },
                    body: this.reference_publication
                })
            }



            //Circulos parte del diseno del curriculum de ejemplo
            doc.setFillColor('#213967')
            doc.circle(289.5, 179.15, 3.75, 'F');

            doc.setFillColor('#275d9d')
            doc.circle(299.85, 179.15, 3.75, 'F');

            doc.setFillColor('#b10f6d')
            doc.circle(309.75, 179.15, 3.75, 'F');

            doc.setFillColor('#b93ca5')
            doc.circle(319.65, 179.15, 3.75, 'F');

            //se Guarda el curriculum
            //doc.addImage(tmpCanvas, "PNG", 18.76, 18.76, 68.16, 68.16);
            if (doc.internal.getNumberOfPages() > 1) {
                doc.deletePage(2)
            }

            this.isLoading = false
            doc.save('curriculum-corto.pdf')

            this.reference_project = []
            this.reference_publication = []
        },

        async downloadShortWord() {
            //Se carga la plantilla Word
            const response = await fetch('/template-cv-corto.docx');
            const templateFile = await response.blob();
            //Se agregan las keywords de las personas
            var expertice = ''
            this.person.key_words_attributes.forEach(element => {
                if (expertice == '') {
                    expertice = element.name
                }
                else {
                    expertice = expertice + ", " + element.name
                }
            })

            //Variable que guarda el ORCID
            var orcid = this.person.orcid
            if (orcid == null) {
                orcid = 'ORCID'
            }

            //variable que guarda la unidad de la persona
            var person_unit = 'SIN INFORMACION'
            if (this.person.person_unit_name != null || this.person.person_unit_name != undefined) {
                person_unit = this.person.person_unit_name
            }


            //Se genera la imagen de perfil en tipo blob
            var img_path = this.person.rounded_photo_url
            if (img_path != null) {
                img_path = "/blank_profile_photo.png"
                var foto_id = 'photo'
            } else {
                var foto_id = 'pfp'
            }

            var imgPhoto = document.getElementById(foto_id);

            if (imgPhoto.height == 0) {
                this.$buefy.dialog.alert({
                    message: 'Ocurrio un error con la foto de perfil, el curriculum corto se descargó con una foto predeterminada.',
                    type: 'is-warning',
                    hasIcon: true,
                    icon: 'exclamation-triangle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
            }

            var blob = await fetch(this.imageRounded).then(res => res.blob())

            var link = ''
            var unidad = 'SIN INFORMACION'
            var index = ''

            if (this.person.link != null) {
                link = this.person.link
            }
            if (this.person.h_index != null) {
                index = this.person.h_index
            }
            if (this.person.person_unit_name != null) {
                unidad = this.person.person_unit_name
            }

            //Se procesa la plantilla
            const data = {
                nombre: this.person.person_name_curriculum,
                foto_perfil: {
                    _type: "image",
                    source: blob,
                    format: "image/jpeg",
                    width: 257,
                    height: 257
                },
                mail: this.person.corporate_mail,
                orcid: orcid,
                h_index: "h-index: " + index,
                link: link,
                departamento: unidad,
                keywords: expertice,
                title: this.person.title,
                biography: this.person.line_research_biography,
                proyectos: this.reference_project,
                publicaciones: this.reference_publication,
            };

            //Se procesa el documento para generar el archivo
            const handler = new TemplateHandler();
            const doc = await handler.process(templateFile, data);

            //Se genera el word para guardar
            saveAs(doc, "curriculum-corto.docx");
            this.isLoading = false

            this.reference_project = []
            this.reference_publication = []
        },
    }
}
</script>