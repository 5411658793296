<script>
export default {
  name: 'welcome_siga',
  created() {

  },
  data() {
    return {
      carousel: 0,
      animated: 'fade',
      drag: false,
      autoPlay: true,
      pauseHover: true,
      pauseInfo: false,
      repeat: true,
      pauseType: 'is-primary',
      interval: 6000,
      indicator: true,
      indicatorBackground: true,
      indicatorInside: false,
      indicatorMode: 'hover',
      indicatorPosition: 'is-top',
      indicatorStyle: 'is-lines',
      carousels: [
        {color: '#7957d5',image:'Banners/default1.6.png',link:'/#'},
      ],
    }
  },
  methods: {
    info(value) {
      this.carousel = value
      this.$buefy.toast.open({
        message: `This Slide ${value} !`,
        type: 'is-info'
      })
    }
  }
}

</script>

<template>
  <div>
    <div>
      <b-carousel
          v-model="carousel"
          :animated="animated"
          :has-drag="drag"
          :autoplay="autoPlay"
          :pause-hover="pauseHover"
          :pause-info="pauseInfo"
          :pause-info-type="pauseType"
          :interval="interval"
          :repeat="repeat"
          :indicator="indicator"
          :indicator-background="indicatorBackground"
          :indicator-inside="indicatorInside"
          :indicator-mode="indicatorMode"
          :indicator-position="indicatorPosition"
          :indicator-style="indicatorStyle"
          icon-size="is-large">
        <!--          @change="info($event)"-->
        <b-carousel-item v-for="(carousel, i) in carousels" :key="i">
          <a :href="carousel.link">
            <section :class="`hero is-medium is-${carousel.color} is-bold`" :style="`background-color: ${carousel.color}; height: 500px;`">
              <b-image class="image" :src=carousel.image></b-image>
            </section>
          </a>
        </b-carousel-item>
      </b-carousel>
    </div>
  </div>
</template>

