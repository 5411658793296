<template>
  <section>
    <div class="container">
      <h1 class="is-size-2">Proyectos</h1>
      <div class="has-text-right">
        <b-button type="is-info"
                  icon-left="plus"
                  v-if="can_create==='true'"
                  @click="newProject()">
          Añadir Proyecto
        </b-button>
      </div>
      <br>
      <projects-table v-bind:proyectos="proyectos" :cargo-principal="cargoPrincipal" @updateProjects="updateProjects"></projects-table>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import axios from "axios";
import ProjectsTable from './projects/projects_table.vue';
import moment from "moment";

export default {
  data(){
    return{
      isLoading:false,
      currentPage:1,
      proyectos:[],
      year:new Date().getFullYear(),
    }
  },
  components:{ProjectsTable},
  created(){
    this.getProjects(this.year)

  },
  props:[
    'can_create', 'cargoPrincipal'
  ],
  methods:{
    newProject(){
      window.location.href="/projects/new"
    },
    formatDate(date) {
      if (date != null && date != "" && date != undefined) {
        var arreglo = date.split("-")
        return arreglo[2] + "-" + arreglo[1] + "-" + arreglo[0]
      } else {
        return ""
      }
    },
    getProjectUrl(project_id){
      return "/projects/"+project_id;
    },
    getProjects(year){
      this.isLoading=true;
      return axios.get("/projects.json",{params:{year_f:year}})
          .then(res => {

            this.proyectos = res.data;
            this.proyectos = this.proyectos.map(proyecto => {
              var newProyecto = proyecto
              newProyecto["start_date"] =  moment(newProyecto["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newProyecto["end_date"] =  moment(newProyecto["end_date"] , ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newProyecto;
            });
            this.isLoading=false;
          })
          .catch(e => {
            this.isLoading=false;
            console.log(e);
          });
    },
    updateProjects(year){
      this.getProjects(year);
    }

  },
  computed:{

  }
}
</script>

