<template>
  <section>
    <div class="container">
      <div class="box">
        <h1 class="is-size-2">Documentos Convenio de Desempeño</h1>
        <div class="columns">
          <div class="column">
            <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
              <b-message type="is-danger" has-icon>
                <div v-for="(item,index) in errores_validacion" :key='index'>
                  <div v-if="item">{{ item }}</div>
                </div>
              </b-message>
            </div>
          </div>
        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(searchPerformances())">
            <div class="columns">
              <div class="column">
                <b-field label="Unidad">
                  <multiselect v-model="selected_unit"
                               :options="units.map(type => type.id)"
                               :custom-label="opt => units.find(x => x.id === opt).name"
                               placeholder="Seleccione unidad"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                    No se encontraron elementos.
                </span>
                  </multiselect>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Naturaleza del documento">
                  <multiselect v-model="selected_document_owner"
                               :options="document_owners"
                               placeholder="Seleccione naturaleza del documento"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                    No se encontraron elementos.
                </span>
                  </multiselect>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de Documento">
                  <multiselect v-model="selected_document_type"
                               :options="aux_document_types.map(type => type.id)"
                               :custom-label="opt => aux_document_types.find(x => x.id === opt).name"
                               placeholder="Seleccione tipo de documento"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                    No se encontraron elementos.
                </span>
                  </multiselect>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Número de documento">
                  <ValidationProvider :rules="{numeric: true, integer: true, positive: true}" v-slot="{ errors }">
                    <b-input v-model="selected_number_document" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Año desde">
                  <ValidationProvider :rules="{numeric: true, integer: true, positive: true, min_value: 1900, max_value: 3000}" v-slot="{ errors }">
                    <b-input v-model="selected_start_year" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Año hasta">
                  <ValidationProvider :rules="{numeric: true, integer: true, positive: true, min_value: 1900, max_value: 3000}" v-slot="{ errors }">
                    <b-input v-model="selected_end_year" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Semestre">
                  <ValidationProvider rules="numeric|integer|positive|min_value:1|max_value:2" v-slot="{ errors }">
                    <b-input v-model="selected_semester" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="¿Está firmado?">
                  <b-radio-button v-model="is_sign"
                                  :native-value="false"
                                  type="is-danger is-light is-outlined">
                    <span>No</span>
                  </b-radio-button>
                  <b-radio-button v-model="is_sign"
                                  :native-value="true"
                                  type="is-success is-light is-outlined">
                    <span>Si</span>
                  </b-radio-button>
                  <b-radio-button v-model="is_sign"
                                  :native-value="null"
                                  type="is-primary is-light is-outlined">
                    <span>Ambas</span>
                  </b-radio-button>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="actions has-text-centered">
                  <button class="button is-primary">Aplicar Filtro</button>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
        <div class="columns is-centered" v-if="documents.length===1" style="margin-bottom: 40px; margin-top: 40px;">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-warning">
              Se encontró {{this.documents.length}} resultado.
            </b-message>
          </div>
        </div>
        <div class="columns is-centered" v-if="documents.length!==1" style="margin-bottom: 40px; margin-top: 40px;">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-warning">
              Se encontraron {{this.documents.length}} resultados.
            </b-message>
          </div>
        </div>
        <b-table
            :data="documents"
            :paginated=true
            per-page=10
            style="word-break:break-all;"
            narrowed
            striped
            hoverable
            :card-layout="cardLayout"
            :current-page="currentPage"
            :pagination-simple=false
            pagination-position="bottom"
            default-sort-direction="desc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"
        >
          <b-table-column
              field= 'type'
              label= 'Tipo'
              searchable
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.type}}
            </template>
          </b-table-column>
          <b-table-column
              field= 'unit_name'
              label= 'Unidad'
              searchable
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.unit_name}}
            </template>
          </b-table-column>
          <b-table-column
              field= 'year'
              label= 'Año'
              searchable
              width="100"
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.year}}
            </template>
          </b-table-column>

          <b-table-column
              field= 'semester'
              label= 'Semestre'
              searchable
              width="100"
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.semester}}
            </template>
          </b-table-column>

          <b-table-column
              field= 'person_name'
              label= 'Académico'
              searchable
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.person_name}}
            </template>
          </b-table-column>
          <b-table-column  label="Acciones"
                           v-slot="props"
                           width="10em">
            <b-button
                pack="fas"
                icon-left="info"
                type="is-primary"
                target="_blank"
                v-if="props.row.can_show"
                @click="viewData(props.row)"/>
            <b-button
                pack="fas"
                icon-left="edit"
                type="is-info is-light is-outlined"
                v-if="props.row.can_edit"
                @click="editPerformance(props.row.performance_id)"/>
            <b-button
                pack="fas"
                icon-left="trash"
                type="is-danger is-light is-outlined"
                v-if="props.row.can_nullify"
                @click="execNullifyModal(props.row.performance_id, props.row)"/>
          </b-table-column>
          <b-table-column
              label="Documentación"
              v-slot="props"
              width="10em">
            <b-button
                pack="fas"
                icon-left="download"
                type="is-primary is-light is-outlined"
                v-if="props.row.can_show"
                @click="downloadFile(props.row.performance_id)"/>
            <b-button
                icon-pack="fas"
                icon-left="eye"
                type="is-success is-light is-outlined"
                target="_blank"
                v-if="props.row.can_show"
                @click="viewFile(props.row.performance_id)"/>
          </b-table-column>

          <template slot="empty" slot-scope="props">
            <div class="columns is-centered">
              <div class="column is-6 is-centered has-text-centered">
                <b-message type="is-danger">
                  No se encontraron resultados para los filtros seleccionados.
                </b-message>
              </div>
            </div>
          </template>
        </b-table>
        <b-modal
            v-model="showPdf"
            :can-cancel="true"
            :destroy-on-hide="true"
            aria-modal
            aria-role="dialog"
            has-modal-card
            trap-focus
            :width="2000"
        >
          <template #default="props">
            <pdf_view
                :src="src"
                @close="props.close"
            ></pdf_view>
          </template>

        </b-modal>
        <b-modal
            v-model="showDocument"
            :can-cancel="true"
            :destroy-on-hide="true"
            aria-modal
            aria-role="dialog"
            has-modal-card
            trap-focus
            size="xl"
        >
          <template #default="props">
            <show-performance-modal
                :performance="performance"

                @close="props.close"
            ></show-performance-modal>
          </template>

        </b-modal>
      </div>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
  </section>
</template>

<script>

import axios from "axios";
import Pdf_view from "./pdf_view.vue";
import showPerformanceModal from "./show_performance_modal.vue";

export default {
  components: {showPerformanceModal, Pdf_view},
  data(){
    return{
      isLoading: false,
      document_owners: ["TODOS","DOCUMENTACIÓN UNIDAD","DOCUMENTACIÓN CUERPO ACADÉMICO"],
      units: [],
      document_types: [],
      selected_semester: null,
      selected_year: null,
      selected_document_type: null,
      selected_unit: null,
      selected_document_owner: null,
      start_date: null,
      end_date: null,
      is_sign: null,
      errores_validacion: {},
      selected_number_document: null,
      documents:[],
      currentPage:1,
      cardLayout:false,
      showPdf: false,
      showDocument: false,
      selected_start_year: null,
      selected_end_year: null,
      aux_document_types: []
    }
  },
  created(){
    this.isLoading = true
    axios
        .get("/documents/data-index-performances.json")
        .then(response => {
          this.document_types = response.data.document_types
          this.document_types.unshift({
            id: -1,
            name: "TODOS",
            initials: 'all'
          })
          this.units = response.data.units
          this.selected_document_type = this.document_types[0].id
          this.aux_document_types = this.document_types
          this.selected_unit = this.units[0].id
          this.selected_document_owner = this.document_owners[0]
          this.isLoading = false
        })
        .catch(error=>{
          this.isLoading = false
          console.log(error)
        })
  },
  methods:{
    searchPerformances(){
      this.isLoading = true
      if(this.selected_start_year != null && this.selected_start_year != undefined && this.selected_start_year != ''){
        if(this.selected_end_year != null && this.selected_end_year != undefined && this.selected_end_year != ''){
          if(this.selected_start_year > this.selected_end_year){
            this.$buefy.dialog.alert({
              message: "El año desde no puede ser mayor al año hasta",
              type: "is-danger",
              hasIcon: true
            })
            this.isLoading = false
            return
          }
        }
      }

      axios
          .get("/documents/filter-performance.json", {
            params:{
              is_sign: this.is_sign,
              document_type: this.selected_document_type,
              number: this.selected_number_document,
              //year: this.selected_year,
              semester: this.selected_semester,
              //start_date: this.start_date,
              //end_date: this.end_date,
              selected_start_year: this.selected_start_year,
              selected_end_year: this.selected_end_year,
              unit_id: this.selected_unit,
              document_owner: this.selected_document_owner,
            }
          })
          .then(res =>{
            this.documents = res.data
          })
          .catch(e => {
            console.log(e)
          })
          .finally(() =>{
            this.isLoading = false
          })
    },
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            this.showPdf = true
            this.isLoading = false
          })
          .catch(e => {
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    execNullifyModal(id, row){
      this.$buefy.dialog.confirm({
        title: 'Anular Documento',
        message: '¿Está seguro que desea <b>anular</b> el documento? Esta acción lo dejará inválido.<br>',
        confirmText: 'Anular Documento',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/documents/" + id + "/nullify-document.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Documento anulado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })

                var index = this.documents.indexOf(row)
                this.documents.splice(index, 1)
                this.isLoading = false;
                this.isNullifyActive = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Documento no pudo ser anulado</b>',
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },
    viewData(row){
      this.performance = row
      this.showDocument = true
    },
    editPerformance(id){
      window.location.href = "/documents/" + id + "/edit-performance"
    },
    downloadFile(fileId){
      this.isLoading = true;

      if (fileId == null){
        this.isLoading = false;
        return;
      }

      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
          })
          .then(res => {
            let blob = new Blob([res.data], {
              type: "application/pdf",
            })
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = res.headers["content-disposition"]
                .split(";")[1]
                .split('"')[1];
            link.click();
            this.isLoading=false
          })
          .catch(e => {
            console.log(e)
            this.isLoading=false
          })
    },
  },
  watch:{
    selected_document_owner: function () {
      if (this.selected_document_owner == 'DOCUMENTACIÓN UNIDAD') {
        this.aux_document_types = this.document_types.filter(type => type.initials.includes("DD") || type.initials.includes("DO") || type.initials.includes("all"))
        this.selected_document_type = this.aux_document_types[0].id
      }
      else if(this.selected_document_owner == 'DOCUMENTACIÓN CUERPO ACADÉMICO'){
        this.aux_document_types = this.document_types.filter(type => type.initials.includes("DA") || type.initials.includes("all"))
        this.selected_document_type = this.aux_document_types[0].id
      }
      else{
        this.aux_document_types = this.document_types
        this.selected_document_type = this.aux_document_types[0].id
      }
    },
  }
}

</script>



<style scoped>

</style>