<template>
<section>
  <div class="box">
    <div class="container">
      <section>
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><a href="/">Home</a></li>
            <li class="is-active"><a href="/projects/postulated">Proyectos Postulados</a></li>
          </ul>
        </nav>
      </section>
      <br>

      <div class="has-text-right mb-1">
        <b-button type="is-info"
                  icon-left="plus"
                  @click="newPostulatedProject()">
          Añadir Proyecto Postulado
        </b-button>
      </div>
      <div class="columns">
        <div class="column is-narrow mt-2">
          <span>Proyectos postulados año: </span>
        </div>
        <div class="column is-3">
          <b-field>
            <multiselect v-model="year"
                         :options="years"
                         @input="updateProjects"
                         placeholder="Seleccione año"
                         selectLabel="Presione para seleccionar"
                         selectedLabel="Seleccionado"
                         deselectLabel="No se puede deseleccionar"
                         :allow-empty="false">
              <template v-slot:noOptions>
                No existen datos
              </template>
              <span slot="noResult">
                No se encontraron elementos.
            </span>
            </multiselect>
          </b-field>
        </div>

        <div class="column has-text-right">
          <b-field>
            <b-switch v-model="cardLayout">Formato tarjeta (sin filtros ni búsqueda)
              <b-tooltip
                  label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                  multilined
                  position="is-left">
                <b-icon
                    pack="fas"
                    icon="info-circle"
                    type="is-info">
                </b-icon>
              </b-tooltip></b-switch>
          </b-field>

        </div>
      </div>

      <b-table
          :data="PostulatedProjects"
          :paginated=true
          :card-layout="cardLayout"
          per-page=10
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="project.name"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual"
          :opened-detailed="defaultOpenedDetails"
          detailed
          detail-key="code"
          @details-open="(row) => $buefy.toast.open(`Expanded ${row.name}`)"
          :show-detail-icon="showDetailIcon"
      >
        <b-table-column
            field= 'name'
            label= 'Nombre'
            width="30em"
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            <a :href="getProjectUrl(props.row.id)">{{ props.row.name }}</a>
          </template>
        </b-table-column>
        <b-table-column
            field= 'project_type.name'
            label= 'Tipo de Proyecto'
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.project_type ? props.row.project_type.name : "" }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'code'
            label= 'Código'
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.code }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'postulation_date'
            label= 'Fecha Postulación'
            searchable
            :custom-search="funcionCustom"
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.postulation_date._isValid ? new Date(props.row.postulation_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            field= 'university_project_role.name'
            label= 'Rol Universidad'
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.university_project_role ? props.row.university_project_role.name : "" }}
          </template>
        </b-table-column>
        <b-table-column
            label="Acciones"
            v-slot="props">
          <b-icon
              pack="fas"
              icon="trash"
              size="is-medium"
              type="is-danger"
              @click.native="execDeleteModal(props.row.id, props.row)"/>
        </b-table-column>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <p>
                  <strong>Participantes: </strong>
                  <span v-for="(participant, index) in props.row.participants" :key="index">
                  <a v-if="participant.url!=null" :href="participant.url">{{ participant.name }}</a>
                  <span v-else>{{ participant.name }}</span>
                  <span v-if="props.row.participants.length>1 && participant!=props.row.participants.slice(-1)[0]">, </span>
                </span>
                </p>
              </div>
            </div>
          </article>
        </template>
        <template slot="empty" slot-scope="props">
          <div class="columns is-centered">
            <div class="column is-6 is-centered has-text-centered">
              <b-message type="is-danger">
                No se encontraron resultados.
              </b-message>
            </div>
          </div>
        </template>
      </b-table>
    </div>
  </div>
  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

</section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import {searchDate} from "../../packs/utilities";

export default {
  name: "PostulatedProjectsList",
  props:[''],
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      years:[],
      year:null,
      PostulatedProjects:[]

    }
  },
  created(){
    this.years = this.generateArrayOfYears();
    this.year = new Date().getFullYear();
    this.getProjects(this.year);
  },
  methods: {
    getProjectUrl(project_id) {
      return "/projects/postulated/" + project_id;
    },
    generateArrayOfYears() {
      var max = new Date().getFullYear()
      var min = 1980
      var years = []
      years.push("Todo")
      for (var i = max; i >= min; i--) {
        years.push(i)
      }
      return years
    },
    getProjects(year){
      this.isLoading=true;
      return axios.get("/projects/postulated.json",{params:{year:year}})
          .then(res => {
            this.PostulatedProjects = res.data;
            this.PostulatedProjects = this.PostulatedProjects.map(proyecto => {
              var newProyecto = proyecto
              newProyecto["postulation_date"] =  moment(newProyecto["postulation_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newProyecto;
            });
            this.isLoading=false;
          })
          .catch(e => {
            this.isLoading=false;
            console.log(e);
          });
    },
    updateProjects(year){
      this.getProjects(year);
    },
    newPostulatedProject(){
      window.location.href = '/projects/new/postulated'
    },
    getFormattedDate(date){
      return moment(date).format('DD/MM/YYYY')
    },
    funcionCustom(row,input){
      return searchDate(row.postulation_date,input)
    },
    execDeleteModal(project_id,row){

      let vm = this;
      vm.$buefy.dialog.confirm({
        title: 'Anular Proyecto Postulado',
        message: '¿Está segura/o que desea <b>anular</b> este proyecto postulado?.',
        confirmText: 'Anular Proyecto Postulado',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          vm.isLoading = true
          axios
            .delete("/projects/" + project_id + "/nullify_project.json")
            .then(res => {
              vm.$buefy.dialog.alert({
                message: 'Proyecto postulado eliminado correctamente',
                duration: 5000,
                type: 'is-success',
                canCancel: ["escape", "outside"]
              })
              var index = this.PostulatedProjects.indexOf(row)
              this.PostulatedProjects.splice(index, 1)
              vm.isLoading = false;
            }).catch(error => {
              vm.$buefy.dialog.alert({
                message: 'Este proyecto postulado no pudo ser eliminado<br>',
                duration: 5000,
                type: 'is-danger',
                canCancel: ["escape", "outside"]
              })
              vm.isLoading = false;
            })
        }
      })

    }

  }
}
</script>

<style scoped>

</style>