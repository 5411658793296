<template>
    <div class="workhistorynewview">
        <div class="hero">
            <div class="hero-head">
                <br>
                <br>
                <div class="columns is-centered">
                    <h1 class="is-size-2" style="text-align:center;">Ingrese la nueva experiencia laboral</h1>
                </div>
            </div>
            <div class="hero-body">
                <WorkHistoryNewForm :person_id='this.person_id'/>
            </div>
        </div>
    </div>
</template>
<script>
import WorkHistoryNewForm from './work_history_new_form.vue'
export default {
    name: 'WorkHistoryNewView',
    props:{
        person_id:{
            type: Number
        }
    },
    components:{
        WorkHistoryNewForm
    }
}
</script>