<template>
  <section>
    <div class="container">
      <!--DATA TABLE-->
      <b-table
          :data="events"
          :paginated= true
          per-page="10"
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="product.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field="decree_date"
            label="Fecha Decreto"
            width="110"
            sortable
            searchable :custom-search="searchDecreeDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.decree_date._isValid ? new Date(props.row.decree_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            field="decree"
            label="Decreto"
            numeric
            searchable
            sortable
            width="100">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.decree}}
          </template>
        </b-table-column>
        <b-table-column
            field="event_type.name"
            label="Tipo Evento"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.event_type.name}}
          </template>
        </b-table-column>
        <b-table-column
            field="start_date"
            label="Fecha Desde"
            width="110"
            sortable
            searchable :custom-search="searchStartDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            field="end_date"
            label="Fecha Hasta"
            width="110"
            sortable
            searchable :custom-search="searchEndDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            label="Acciones"
            width="120"
            v-slot="props">

          <b-tooltip
              v-if="props.row.can_show"
              label="Ver información completa del evento anulado."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="eye"
                type="is-primary"
                @click="execShowModal(props.row)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_restore"
              label="Restaurar evento anulado."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="trash-restore"
                type="is-success is-light is-outlined"
                @click="execRestoreModal(props.row.id)"/>
          </b-tooltip>

        </b-table-column>
      </b-table>
      <b-modal
          v-model="isShowModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <event :id="selected"
                 :modal-header="'Evento - '  +person_name"
                 :person_name="person_name"
                 @close="props.close">
          </event>
        </template>
      </b-modal>
      <b-modal
          v-model="isShowModalActiveRectification"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <rectification :id="selected"
                         :modal-header="form_name"
                         :person_name="person_name"
                         @close="props.close"
                         :event_type_name="event_type_name">
          </rectification>
        </template>
      </b-modal>
    </div>
    <b-loading :is-full-page="true"
               v-model="isLoading"
               :can-cancel="false"/>
  </section>
</template>

<script>
import axios from "axios";
import Event from "../life_record/event.vue"
import moment from "moment";
import {searchDate, formatDate} from "../../packs/utilities";
import Rectification from "./rectify/rectification"
export default {
  name: "null_event",
  props:[
    'personId', 'person_name'
  ],
  components:{
    Event,
    Rectification,
  },
  data(){
    return {
      selected: null,
      currentPage: 1,
      isShowModalActive: false,
      isShowModalActiveRectification: false,
      canCancel: ['escape'],
      isLoading:false,
      events: [],
      form_name: "",
      type_of_event: "",
      event_type_name: "",
      isRestoreActive: false
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    initialize(){
      this.isLoading = true;
      return axios
          .get("/people/"+this.personId+"/null_events.json")
          .then(res => {
            this.events = res.data
            this.events = this.events.map(event => {
              var newEvent = event
              newEvent["decree_date"] = moment(newEvent["decree_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newEvent["start_date"] = moment(newEvent["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newEvent["end_date"] = moment(newEvent["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newEvent;
            });
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })
    },
    execShowModal(row){
      this.selected = row.id;
      if(row.event_type.name==="RECTIFICACION"){
        this.isShowModalActiveRectification = true;
        this.type_of_event = row.event_type.name
        this.form_name = "Rectificación - "+this.person_name
        this.event_type_name = "Rectificacón"
      }
      else if(row.event_type.name==="REDUCCION DE HORAS"){
        this.isShowModalActiveRectification = true;
        this.type_of_event = row.event_type.name
        this.form_name = "Reducción de Horas - "+this.person_name
        this.event_type_name = "Reducción de Horas"
      }
      else if(row.event_type.name==="AUMENTO DE HORAS"){
        this.isShowModalActiveRectification = true;
        this.type_of_event = row.event_type.name
        this.form_name = "Aumento de Horas - "+this.person_name
        this.event_type_name = "Aumento de Horas"
      }
      else if(row.event_type.name==="CAMBIO DE CENTRO DE COSTO"){
        this.isShowModalActiveRectification = true;
        this.type_of_event = row.event_type.name
        this.form_name = "Cambio de Centro de Costo - "+this.person_name
        this.event_type_name = "Cambio de Centro de Costo"
      }
      else{
        this.isShowModalActive = true;
      }
    },
    execRestoreModal(id){
      let vm = this;
      vm.$buefy.dialog.confirm({
        title: 'Restaurar evento',
        message: '¿Está seguro que desea <b>restaurar</b> el evento? Esta acción revertirá la anulación.',
        confirmText: 'Restaurar Evento',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          vm.isRestoreActive = true
          vm.isLoading = true
          axios
              .put("/admin/events/" + id + "/restore_event.json")
              .then(res => {
                vm.$buefy.dialog.alert({
                  message: 'Evento restaurado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                vm.isLoading = false;
                vm.isRestoreActive = false;
              }).catch(error => {
            console.log(error)
            vm.$buefy.dialog.alert({
              message: '<b>Evento no pudo ser restaurado</b><br>',
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            vm.isLoading = false;
            vm.isRestoreActive = false;
          })
        }
      })

    },
    execEditModal(row){
      this.selected = row.id;
      if(row.event_type.name==="RECTIFICACION"){
        this.isEditModalActiveRectification = true;
        this.type_of_event = row.event_type.name
        this.form_name = "Editar Rectificación - "+this.person_name
        this.event_type_name = "Rectificación"
      }
      else if(row.event_type.name==="REDUCCION DE HORAS"){
        this.isEditModalActiveRectification = true;
        this.type_of_event = row.event_type.name
        this.form_name = "Editar Reducción de Horas - "+this.person_name
        this.event_type_name = "Reducción de Horas"
      }
      else if(row.event_type.name==="AUMENTO DE HORAS"){
        this.isEditModalActiveRectification = true;
        this.type_of_event = row.event_type.name
        this.form_name = "Editar Aumento de Horas - "+this.person_name
        this.event_type_name = "Aumento de Horas"
      }
      else if(row.event_type.name==="CAMBIO DE CENTRO DE COSTO"){
        this.isEditModalActiveRectification = true;
        this.type_of_event = row.event_type.name
        this.form_name = "Editar Cambio de Centro de Costo - "+this.person_name
        this.event_type_name = "Cambio de Centro de Costo"
      }
      else{
        this.isEditModalActive = true;
      }
    },
    // yyyy/mm/dd -> dd/mm/yyyy
    formatDate,
    searchDecreeDate(row, input){
      return searchDate(row.decree_date, input)
    },
    searchStartDate(row, input){
      return searchDate(row.start_date, input)
    },
    searchEndDate(row, input){
      return searchDate(row.end_date, input)
    }
  },
  watch:{
    isRestoreActive: function () {
      if (!this.isRestoreActive){
        this.initialize()
      }
    },
    isAddModalActive: function () {
      if (!this.isAddModalActive) {
        this.initialize()
      }
    },
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
    isEditModalActiveRectification: function () {
      if (!this.isEditModalActiveRectification) {
        this.initialize()
      }
    },
    isShowModalActive: function () {
      if (!this.isShowModalActive) {
        this.initialize()
      }
    },
    isShowModalActiveRectification: function () {
      if (!this.isShowModalActiveRectification) {
        this.initialize()
      }
    },
  }
}
</script>
