<template>
  <section>
    <div class="container">
      <h1 class="is-size-2">Propiedad Intelectual</h1>
      <div class="has-text-right">
        <b-button type="is-info"
                  icon-left="plus"
                  v-if="can_create==='true'"
                  @click="newPatent()">
          Añadir Propiedad Intelectual
        </b-button>
      </div>
      <br>
      <patents-table v-bind:patentes="patentes" :cargoPrincipal="cargoPrincipal" @updatePatents="updatePatents"></patents-table>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import axios from "axios";
import PatentsTable from './patents/patents_table.vue'
import moment from "moment";

export default {
  data(){
    return{
      isLoading:false,
      currentPage:1,
      patentes:[],
      year: new Date().getFullYear(),
    }
  },
  components: {PatentsTable},
  created(){
    this.getPatents(this.year)

  },
  props:[
    'can_create', 'cargoPrincipal'
  ],
  methods:{
    newPatent(){
      window.location.href="/patents/new"
    },
    getPatentUrl(patent_id){
      return "/patents/"+patent_id
    },
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    getPatents(year){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/patents.json", {params:{year_f:year}})
          .then(res => {
            vm.patentes = res.data;
            vm.patentes = vm.patentes.map(patente => {
              var newPatente = patente
              newPatente["last_patent_state_date"] = moment(newPatente["last_patent_state_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newPatente["presentation_date"] = moment(newPatente["presentation_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newPatente;
            });
            vm.isLoading=false;
          })
          .catch(e => {
            vm.isLoading=false;
            console.log(e);
          });

    },
    updatePatents(year){
      this.getPatents(year)

    }

  },
  computed:{

  }
}
</script>

