<template>
  <section>
    <div class="container">
      <div class="has-text-right mb-1">
        <b-button class="is-primary is-outlined"
                  icon-left="plus"
                  v-if="can_create_event==='true'"
                  @click="isAddModalActive = true">
          Añadir Evento
        </b-button>
      </div>

      <!--DATA TABLE-->
      <b-table
          :data="events"
          :paginated= true
          per-page="10"
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="product.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field="decree_date"
            label="Fecha Decreto"
            width="110"
            sortable
            searchable :custom-search="searchDecreeDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.decree_date._isValid ? new Date(props.row.decree_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            field="decree"
            label="Decreto"
            numeric
            searchable
            sortable
            width="100">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.decree}}
          </template>
        </b-table-column>
        <b-table-column
            field="event_type.name"
            label="Tipo Evento"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.event_type.name}}
          </template>
        </b-table-column>
        <b-table-column
            field="start_date"
            label="Fecha Desde"
            width="110"
            sortable
            searchable :custom-search="searchStartDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            field="end_date"
            label="Fecha Hasta"
            width="110"
            sortable
            searchable :custom-search="searchEndDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            label="Acciones"
            width="150"
            v-slot="props">


          <b-tooltip
              v-if="props.row.can_show"
              label="Ver información completa del evento."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="eye"
                type="is-primary"
                @click="execShowModal(props.row)"/>

          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_edit"
              label="Editar información del evento."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="edit"
                type="is-info is-light is-outlined"
                @click="execEditModal(props.row)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_copy && (props.row.event_type.id!== 9 && props.row.event_type.id!== 10 &&
              props.row.event_type.id!== 11 && props.row.event_type.id!== 12 && props.row.event_type.id !== 13)"
              label="Copiar evento."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="copy"
                type="is-danger is-light is-outlined"
                @click="execCopyModal(props.row.id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_nullify"
              label="Anular evento."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="ban"
                type="is-danger is-light is-outlined"
                @click="execNullifyModal(props.row.id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_view_pdf"
              label="Visualizar documento asociado al evento."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="file-alt"
                type="is-success is-light is-outlined"
                @click="viewFile(props.row.document_id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_view_pdf"
              label="Descargar documento asociado al evento."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="download"
                type="is-primary is-light is-outlined"
                @click="downloadFile(props.row.document_id)"/>
          </b-tooltip>

        </b-table-column>
      </b-table>

      <b-modal
          v-model="isAddModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <event-form :person-id="personId"
                            query-type="create"
                            :modal-header="'Nuevo Evento - '  +person_name"
                      :person_name="person_name"
                            @close="props.close"></event-form>
        </template>
      </b-modal>
      <b-modal
          v-model="isShowModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <event :id="selected"
                       :modal-header="'Evento - '  +person_name"
                       :person_name="person_name"
                       @close="props.close">
          </event>
        </template>
      </b-modal>
      <b-modal
          v-model="isEditModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <event-form :person-id="personId"
                            :id="selected"
                            :query-type="query_type"
                            :modal-header="form_name"
                      :person_name="person_name"
                            @close="props.close">
          </event-form>
        </template>
      </b-modal>
      <b-modal
          v-model="isShowModalActiveRectification"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <rectification :id="selected"
                         :modal-header="form_name"
                         :person_name="person_name"
                         @close="props.close"
                         :event_type_name="event_type_name">
          </rectification>
        </template>
      </b-modal>
      <b-modal
          v-model="isEditModalActiveRectification"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <rectification-form :person-id="personId"
                              :id="selected"
                              :query-type="query_type"
                              :modal-header="form_name"
                              :event_type_name="event_type_name"
                              :person_name="person_name"
                              @close="props.close">
          </rectification-form>
        </template>
      </b-modal>

      <b-modal
          v-model="showPdf"
          :can-cancel="true"
          :destroy-on-hide="true"
          aria-modal
          aria-role="dialog"
          has-modal-card
          trap-focus
          :width="2000"
      >
        <template #default="props">
          <pdf_view
              :src="src"
              @close="props.close"
          ></pdf_view>
        </template>

      </b-modal>


    </div>
    <b-loading :is-full-page="true"
               v-model="isLoading"
               :can-cancel="false"/>
  </section>
</template>

<script>
import axios from "axios";
import Event from "../life_record/event.vue"
import EventForm from "../life_record/modal_event_form.vue"
import moment from "moment";
import {searchDate, formatDate} from "../../packs/utilities";
import Rectification from "./rectify/rectification"
import RectificationForm from "./rectify/rectification_form"
import Pdf_view from "../performances/pdf_view.vue";
export default {
  name: "events",
  props:[
      'personId', 'can_create_event', 'person_name'
  ],
  components:{
    Pdf_view,
    Event,
    EventForm,
    Rectification,
    RectificationForm
  },
  data(){
    return {
      selected: null,
      currentPage: 1,
      isAddModalActive: false,
      isEditModalActive: false,
      isEditModalActiveRectification: false,
      isShowModalActive: false,
      isShowModalActiveRectification: false,
      canCancel: [],
      isLoading:false,
      events: [],
      form_name: "",
      type_of_event: "",
      event_type_name: "",
      isNullifyActive: false,
      query_type: "",
      showPdf: false,
      downloadLink: "",
      src:null,
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    initialize(){
      this.isLoading = true;
      return axios
          .get("/people/"+this.personId+"/events.json")
          .then(res => {
            this.events = res.data
            this.events = this.events.map(event => {
              var newEvent = event
              newEvent["decree_date"] = moment(newEvent["decree_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newEvent["start_date"] = moment(newEvent["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newEvent["end_date"] = moment(newEvent["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newEvent;
            });
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })
    },
    execCopyModal(id){
      this.isLoading = true;
      this.selected = id;
      this.query_type = "copy"
      this.form_name = 'Copiar Evento - '  +this.person_name
      this.isEditModalActive = true;
      this.isLoading = false
    },
    execNullifyModal(id){
      this.$buefy.dialog.confirm({
        title: 'Anular evento',
        message: '¿Está seguro que desea <b>anular</b> el evento? Esta acción lo dejará inválido.<br>',
        confirmText: 'Anular Evento',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/admin/events/" + id + "/nullify_event.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Evento anulado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                this.isLoading = false;
                this.isNullifyActive = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Evento no pudo ser anulado</b><br>',
              duration: 5000,
              type: 'is-damger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },
    execShowModal(row){
      this.selected = row.id;
      if(row.event_type.name==="RECTIFICACION"){
        this.isShowModalActiveRectification = true;
        this.type_of_event = row.event_type.name
        this.form_name = "Rectificación - "+this.person_name
        this.event_type_name = "Rectificación"
      }
      else if(row.event_type.name==="REDUCCION DE HORAS"){
        this.isShowModalActiveRectification = true;
        this.type_of_event = row.event_type.name
        this.form_name = "Reducción de Horas - "+this.person_name
        this.event_type_name = "Reducción de Horas"
      }
      else if(row.event_type.name==="AUMENTO DE HORAS"){
        this.isShowModalActiveRectification = true;
        this.type_of_event = row.event_type.name
        this.form_name = "Aumento de Horas - "+this.person_name
        this.event_type_name = "Aumento de Horas"
      }
      else if(row.event_type.name==="CAMBIO DE CENTRO DE COSTO"){
        this.isShowModalActiveRectification = true;
        this.type_of_event = row.event_type.name
        this.form_name = "Cambio de Centro de Costo - "+this.person_name
        this.event_type_name = "Cambio de Centro de Costo"
      }
      else if(row.event_type.name==="DEJA SIN EFECTO"){
        this.isShowModalActiveRectification = true;
        this.type_of_event = row.event_type.name
        this.form_name = "Deja sin Efecto - "+this.person_name
        this.event_type_name = "Deja sin Efecto"
      }
      else{
        this.isShowModalActive = true;
      }
    },
    execEditModal(row){
      this.selected = row.id;
      if(row.event_type.name==="RECTIFICACION"){
        this.isEditModalActiveRectification = true;
        this.type_of_event = row.event_type.name
        this.form_name = "Editar Rectificación - "+this.person_name
        this.event_type_name = "Rectificación"
        this.query_type = "update"
      }
      else if(row.event_type.name==="REDUCCION DE HORAS"){
        this.isEditModalActiveRectification = true;
        this.type_of_event = row.event_type.name
        this.form_name = "Editar Reducción de Horas - "+this.person_name
        this.event_type_name = "Reducción de Horas"
        this.query_type = "update"
      }
      else if(row.event_type.name==="AUMENTO DE HORAS"){
        this.isEditModalActiveRectification = true;
        this.type_of_event = row.event_type.name
        this.form_name = "Editar Aumento de Horas - "+this.person_name
        this.event_type_name = "Aumento de Horas"
        this.query_type = "update"
      }
      else if(row.event_type.name==="CAMBIO DE CENTRO DE COSTO"){
        this.isEditModalActiveRectification = true;
        this.type_of_event = row.event_type.name
        this.form_name = "Editar Cambio de Centro de Costo - "+this.person_name
        this.event_type_name = "Cambio de Centro de Costo"
        this.query_type = "update"
      }
      else{
        this.query_type = "update"
        this.form_name = 'Editar Evento - '  +this.person_name
        this.isEditModalActive = true;
      }
    },
    // yyyy/mm/dd -> dd/mm/yyyy
    formatDate,
    searchDecreeDate(row, input){
      return searchDate(row.decree_date, input)
    },
    searchStartDate(row, input){
      return searchDate(row.start_date, input)
    },
    searchEndDate(row, input){
      return searchDate(row.end_date, input)
    },
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            this.showPdf = true
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    downloadFile(fileId){
      this.isLoading = true;

      if (fileId == null){
        this.isLoading = false;
        return;
      }

      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(res => {
            let blob = new Blob([res.data], {
              type: "application/pdf",
            })
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = res.headers["content-disposition"]
                .split(";")[1]
                .split('"')[1];
            link.click();
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento fue descargado con exito',
              type: 'is-success',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
          .catch(e => {
            console.log(e)
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento no se pudo descargar',
              type: 'is-danger',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
    },

  },
  watch:{
    isNullifyActive: function () {
      if (!this.isNullifyActive){
        this.initialize()
      }
    },
    isAddModalActive: function () {
      if (!this.isAddModalActive) {
        this.initialize()
      }
    },
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
    isEditModalActiveRectification: function () {
      if (!this.isEditModalActiveRectification) {
        this.initialize()
      }
    },
    isShowModalActive: function () {
      if (!this.isShowModalActive) {
        this.initialize()
      }
    },
    isShowModalActiveRectification: function () {
      if (!this.isShowModalActiveRectification) {
        this.initialize()
      }
    },
  }
}
</script>
