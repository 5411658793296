<template>
  <section>
    <div class="container">
      <br>
      <h1 class="is-size-2" style="text-align:center;">Egresados</h1>
      <br>
      <span class="is-pulled-right">
        <div class="columns">
          <div class="column" v-if="applyFilter==0">
            <button class="button is-primary" v-if="applyFilter==0" @click="advancedSearch()">
              <b-icon icon="search"></b-icon>
              <span>Búsqueda avanzada</span>
            </button>
          </div>
          <div class="column" v-if="applyFilter==1">
            <button class="button is-danger" v-if="applyFilter==1" @click="removeFilters()">
              <b-icon icon="trash-alt"></b-icon>
              <span>Remover filtro</span>
            </button>
          </div>  
          <div class="column">
            <button class="button is-primary" @click="newPerson()">
              <b-icon icon="plus"></b-icon>
              <span>Agregar a un nuevo egresado</span>
            </button>
          </div>  
        </div>
      </span>
      <br>
      <br>
      <b-table
          :data="graduatesFiltered"
          :paginated=true
          per-page=10
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="publication.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field= 'full_name'
            label= 'Nombre'
            width="30em"
            searchable
            sortable
            v-slot="props"
        >
          <a @click="goToPerson(props.row.id)"><i class="fas fa-user-circle"></i> {{ props.row.full_name }}</a>

        </b-table-column>
        <b-table-column
            field= 'rut'
            label= 'RUN'
            searchable
            sortable
            v-slot="props"
        >
          {{ props.row.rut }}
        </b-table-column>
        <b-table-column
            field= 'corporate_mail'
            label= 'Correo Corporativo'
            searchable
            sortable
            v-slot="props"
        >
          {{ props.row.corporate_mail }}
        </b-table-column>
        <b-table-column
            field= 'sex'
            label= 'Sexo'
            searchable
            sortable
            v-slot="props"
        >
          {{ props.row.sex }}
        </b-table-column>
      </b-table>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import PeopleDataTableGraduatesAdvancedSearch from '../index/people_data_table_graduates_advanced_search.vue'
export default {
  data(){
    return{
      isLoading:false,
      currentPage:1,
      personas:[],
      departamentos: [],
      carreras: [],
      selectedUnit: '',
      graduates:[],
      applyFilter: 0
    }
  },
  created(){
    this.isLoading=true;
    return axios.get("/graduates.json")
        .then(res => {
          this.personas = res.data['personas'];
          this.graduates = res.data['personas'];
          this.isLoading=false;
        })
        .catch(e => {
          this.isLoading=false;
          console.log(e);
        });
  },
  computed:{
    graduatesFiltered(){
      return this.graduates
    },
    normalizeUnitInGraduates(){
      this.graduates.forEach(function(element){
        if(element.unit!= null){
          element.unit = element.unit.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        }
      })
      return this.graduates
    },
    normalizeCareersInGraduates(){
      this.graduates.forEach(function(element){
        if(element.unit!= null){
          element.carreer = element.carreer.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        }
      })
      return this.graduates
    }
  },
  beforeMount(){
    this.getDepartamentosIngenieria()
    this.getCareers()
  },
  methods:{
    newPerson(){
      this.isLoading=true;
      window.location.href="/graduates/new/"
    },
    goToPerson(people_id){
      this.isLoading=true;
      window.location.href="/graduates/show/"+people_id
    },
    getDepartamentosIngenieria(){
      axios.get("/dptos_ingenieria")
        .then(res => {
          this.departamentos = res.data['departamentos'];
        })
        .catch(e => {
          console.log(e);
        });
    },
    getCareers(){
      axios.get("/job_titles.json")
        .then(res => {
          this.carreras = res.data;
        })
        .catch(e => {
          console.log(e);
        });
    },
    advancedSearch(){
      this.$buefy.modal.open({
        parent: this,
        component: PeopleDataTableGraduatesAdvancedSearch,
        hasModalCard: true,
        props:{
            'departamentos': this.departamentos,
            'carreras': this.carreras
        },
        events:{
            'advanced-search-filters': this.advancedSearchFilter
        },
        customClass: 'is-primary',
        trapFocus: true
      })
    },
    advancedSearchFilter(filters){
      if(filters.selectedDepto !== ""){
        console.log('Ingreso a filtrado de depto')
        this.graduates = this.normalizeUnitInGraduates.filter(
          element => element.unit === filters.selectedDepto
        )
      }
      if(filters.selectedCareer !== ''){
        console.log('Ingreso a filtrado de carrera')
        console.log(filters.selectedCareer)
        this.graduates = this.graduates.filter(
          element => element.carreer === filters.selectedCareer
        )
      }
      if(filters.selectedTypeYear !== ''){
        console.log("Ingresamos a fltrar por tipo de año")
        switch(filters.selectedTypeYear){
          case 'Ingreso':
            this.graduates = this.graduates.filter(
              element => element.admission_date !== null
            )
            if(filters.selectedDesde !== 0){
              this.graduates = this.graduates.filter(
                element => new Date(element.admission_date).getFullYear() >= filters.selectedDesde
              )
            }
            if(filters.selectedHasta !== 0){
              this.graduates = this.graduates.filter(
                element => new Date(element.admission_date).getFullYear() <= filters.selectedHasta
              )
            }
            break;
          case 'Egreso':
            this.graduates = this.graduates.filter(
              element => element.graduate_date !== null
            )
            if(filters.selectedDesde !== 0){
              this.graduates = this.graduates.filter(
                element => new Date(element.graduate_date).getFullYear() >= filters.selectedDesde
              )
            }
            if(filters.selectedHasta !== 0){
              this.graduates = this.graduates.filter(
                element => new Date(element.graduate_date).getFullYear() <= filters.selectedHasta
              )
            }
            break;
          default:
            this.graduates = this.graduates.filter(
              element => element.titulation_date !== null
            )
            if(filters.selectedDesde !== 0){
              console.log("Ingresamos a fltrar por tipo de año TITULACIÓN DESDE.")
              this.graduates = this.graduates.filter(
                element => new Date(element.titulation_date).getFullYear() >= filters.selectedDesde
              )
            }
            if(filters.selectedHasta !== 0){
              console.log("Ingresamos a fltrar por tipo de año TITULACIÓN HASTA.")
              this.graduates = this.graduates.filter(
                element => new Date(element.titulation_date).getFullYear() <= filters.selectedHasta
              )
            }
        }
      }
      this.applyFilter = 1
    },
    removeFilters(){
      this.graduates = this.personas
      this.applyFilter = 0
    }
  }
}
</script>
<style lang="sass">
.tr{
  background-color: '#7957d5'
}
</style>