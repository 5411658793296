<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(verifyDocument)">
        <!-- Box de Formulario Appointment -->
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" ref="modalDistanceForm">
            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion">
                      {{ item[0] }}
                    </div>
                  </b-message>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de Alejamiento*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.distance_type_id"
                                 :options="distance_types.map(type => type.id)"
                                 :custom-label="opt => distance_types.find(x => x.id === opt).name"
                                 placeholder="Seleccione tipo de alejamiento"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 @input="selectDistanceType(person_unit.distance_type_id)"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input v-model="person_unit.description" type="text" minlength="5" maxlength="200"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de documento">
                  <multiselect v-model="person_unit.resolution_type_id"
                               :options="resolution_types.map(type => type.id)"
                               :custom-label="opt => resolution_types.find(x => x.id === opt).name"
                               placeholder="Seleccione tipo de documento"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                               :allow-empty="true"
                               @input="generateName">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Número de documento">
                  <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                    <b-input v-model="person_unit.resolution" type="text" @input="generateName"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Documento">
                    <b-datepicker
                        v-model="person_unit.resolution_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                        @input="generateName"
                    >
                    </b-datepicker>
                </b-field>
              </div>
            </div>
<!--            <div class="columns">
              <div class="column">
                <b-field label="Url Documentación Alejamiento">
                  <ValidationProvider v-slot="{ errors }">
                    <b-input v-model="person_unit.documentation_link" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>-->

            <div class="columns">
              <div class="column is-4">
                <b-field label="Fecha*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                  <b-datepicker
                      v-model="person_unit.start_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      :min-date="appointment_start_date"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                      @input="generateName"
                  >
                  </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                </b-field>
              </div>
            </div>

            <div class="container" v-if="hour_class&&es_renuncia">
              <section>
                <div class="columns">
                  <div class="column">
                    <b-field>
                      <template #label>
                        Asignaturas
                        <b-tooltip
                            label="En 'horas renunciadas' indique la cantidad de horas a las que se renuncia para cada asignatura. Un 0 implica que no se renuncia a horas de tal asignatura y un valor igual a 'horas de asignatura' implica una renuncia total a dicha asignatura"
                            position="is-right"
                            size="is-medium"
                            multilined>

                          <b-icon
                              pack="fas"
                              icon="info-circle"
                              size="is-small"
                              type="is-info">
                          </b-icon>
                        </b-tooltip>
                      </template>
                    </b-field>
                  </div>
                </div>
                <b-table
                    :data="selected_hr_subjects"
                    :paginated=true
                    :bordered="true"
                    per-page=3
                    striped
                    hoverable
                    :current-page="currentPage"
                    :pagination-simple=false
                    pagination-position="bottom"
                    default-sort-direction="asc"
                    sort-icon="arrow-up"
                    sort-icon-size="is-small"
                    default-sort="publication.title"
                    aria-next-label="Siguiente"
                    aria-previous-label="Anterior"
                    aria-page-label="Página"
                    aria-current-label="Página actual">
                  <b-table-column
                      field= 'ps_code'
                      label= 'Código PeopleSoft'
                      sortable
                      v-slot="props"
                  >
                    <b-field>
                      <ValidationProvider v-slot="{errors}">
                        <b-input minlength="0" maxlength="155" v-model="props.row.ps_code" type="text" :disabled="true"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </b-table-column>
                  <b-table-column
                      field= 'siac_code'
                      label= 'Código SIAC'
                      sortable
                      v-slot="props"
                  >
                    <b-field>
                      <ValidationProvider v-slot="{errors}">
                        <b-input minlength="0" maxlength="155" v-model="props.row.siac_code" type="text" :disabled="true"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </b-table-column>
                  <b-table-column
                      field= 'name'
                      label= 'Nombre de la Asignatura*'
                      width="30em"
                      sortable
                      v-slot="props"
                  >

                    <b-field>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-taginput
                            v-model="props.row.subject"
                            :data="filtered_hr_subjects"
                            type="is-primary is-light"
                            maxtags="1"
                            autocomplete
                            maxlength="155"
                            :allow-new="true"
                            :disabled="true"
                            field="name"
                            icon="tag"
                            placeholder="Agregar o escoger asignaturas"
                            @input = "getPsCode(props.row)"
                            @typing="getFilteredHRSubjects">
                        </b-taginput>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </b-table-column>
                  <b-table-column
                      field= 'hours'
                      label= 'Horas Originales de Asignatura'
                      sortable
                      v-slot="props"
                  >
                    <b-field>
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <b-input v-model="props.row.hours" type="text" min="0" :disabled="true"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </b-table-column>
                  <b-table-column
                      field= 'hours'
                      label= 'Horas Actuales de Asignatura'
                      sortable
                      v-slot="props"
                  >
                    <b-field>
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <b-input v-model="props.row.substracted_hours" type="number" min="0" :disabled="true"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </b-table-column>
                  <b-table-column
                      field= 'distanced_hours'
                      label= 'Horas Renunciadas'
                      sortable
                      v-slot="props"
                  >
                    <b-field>
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <b-input v-model="props.row.distanced_hours" type="text" min="0" :max="props.row.substracted_hours" @input="updateHours"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </b-table-column>
                </b-table>
              </section>
              <div class="columns">
                <div class="column">
                  <b-field>
                    <template #label>
                      Total Horas Renunciadas
                      <b-tooltip label="Calculado automaticamente en base a la suma de las horas renunciadas por asignatura"
                                 position="is-right">
                        <b-icon
                            pack="fas"
                            icon="info-circle"
                            type="is-info">
                        </b-icon>
                      </b-tooltip>
                    </template>
                    <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                      <b-input v-model="person_unit.taken_hours" type="number" :disabled="true"></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>
            </div>

            <div v-if="queryType == 'update'" class="columns">
              <div class="column is-4">
                <b-field label="Nombre del documento">
                  <b-input v-model="document_name_update" type="text" :disabled="true"></b-input>
                </b-field>
              </div>
              <div class="column is-4">
                <b-field label="¿Desea cambiar o agregar un archivo?">
                  <b-radio-button v-model="changeFile"
                                  :native-value="true"
                                  type="is-success is-light is-outlined">
                    <b-icon icon="check-circle"></b-icon>
                    <span>Si</span>
                  </b-radio-button>
                  <b-radio-button v-model="changeFile"
                                  :native-value="false"
                                  type="is-danger is-light is-outlined">
                    <b-icon icon="times-circle"></b-icon>
                    <span>No</span>
                  </b-radio-button>
                </b-field>
              </div>
            </div>

            <div v-if="queryType == 'create' || changeFile" class="columns has-text-centered">
              <div class="column">
                <b-field extended label="Archivo PDF (Tamaño max. 25 MB)">
                  <b-upload v-model="file"
                            drag-drop
                            type="is-primary"
                            accept=".pdf"
                            @input="generateName">
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon
                              icon="file-pdf"
                              size="is-large">
                          </b-icon>
                        </p>
                        <p>Arrastra tu PDF o haz click para subir.</p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>
              </div>
            </div>
            <div v-if="queryType == 'create' || changeFile" class="columns tags is-centered">
              <span
                  v-if="file !== null"
                  v-model="file"
                  class="tag is-primary mt-1">
                {{ file.name }}
                <button
                    class="delete is-small"
                    type="button"
                    @click="onDeleteFile"
                ></button>
              </span>
            </div>

            <div v-if="file !== null" class="columns">
              <div class="column">
                <b-field label="Nombre del documento a subir">
                  <b-input disabled v-model="document_name"></b-input>
                </b-field>
              </div>
            </div>
          </section>
        </div>

        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-danger" type="submit">Guardar </button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import axios from "axios";
import moment from "moment";
import EventBus from '../eventBus';
export default {
  name: "modal_distance_form",
  props:[
    'queryType', 'personId', 'id', 'modalHeader', 'person_unit_id', 'person_name'
  ],
  data(){
    return {
      person_unit:{
        hour_class:null,
        pu_relation_type_id: 1,
      },
      pu_relation_type_id: 1,
      hr_subject_attributes: [],
      years:[],
      distance_types:[],
      establishments:[],
      resolution_types:[],
      hr_subjects: [],
      filtered_hr_subjects: [],
      errores_validacion:{},
      selected_hr_subjects:[],
      isLoading: false,
      num_hr_subjects: 0,
      currentPage:1,
      cardLayout:false,
      hour_class: false,
      establishment: null,
      es_renuncia: false,
      fee_contract: false,
      appointment_start_date: null,
      file: null,
      document_name: null,
      changeFile: false,
      document_name_update: null,
      document_id: null,
      cost_center: null

    }
  },
  created() {
    this.isLoading=true;

    if (this.queryType === "create"){
      axios
          .get("/admin/person_units/"+this.person_unit_id+"/new_distance.json")
          .then(res => {
            this.person_unit.person_id = this.personId
            this.person_unit.taken_hours = 0
            this.distance_types = res.data["distance_types"]
            this.establishments = res.data["establishments"]
            this.establishment = res.data["establishment"]
            this.resolution_types = res.data["resolution_types"]
            this.cost_center =  res.data["cc"]
            this.appointment_start_date = moment(res.data["appointment_start_date"]).add(1,'days')._d
            if(res.data["establishment"].name==="PROFESORES HORAS DE CLASES"){
              this.hour_class= true
            }
            else{
              this.hour_class = false
            }
            if(res.data["establishment"].name==="HONORARIOS"){
              this.fee_contract= true
              this.distance_types = this.distance_types.filter(distance_type => distance_type.name !== "DEJA DE SERVIR");
            }
            else{
              this.fee_contract = false
            }
            this.person_unit.hour_class = this.hour_class
            if (res.data["hr_subjects"]){
              for(var i=0;i<res.data["hr_subjects"].length;i++){

                this.selected_hr_subjects.push({id:res.data["hr_subjects"][i].id,
                  name: res.data["hr_subjects"][i].name, ps_code: res.data["hr_subjects"][i].ps_code, siac_code: res.data["hr_subjects"][i].siac_code,
                  hours: res.data["has_hr_subjects"][i].hours, index: i, ps_code_disabled: true, subject: [res.data["hr_subjects"][i].name],
                  distanced_hours: 0, substracted_hours: res.data["appointment_has_hr_subjects"][i].substracted_hours})
              }
            }
            this.isLoading=false;
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }
    else if (this.queryType === "update"){
      this.isLoading=true;
      axios
          .get("/admin/person_units/"+this.person_unit_id+"/edit_distance.json")
          .then(res => {
            this.person_unit = res.data
            if(res.data["start_date"]!==null){
              this.person_unit.start_date = new Date(this.formatDate(res.data["start_date"]))
            }
            if(res.data["resolution_date"]!==null){
              this.person_unit.resolution_date = new Date(this.formatDate(res.data["resolution_date"]))
            }
            this.person_unit.person_id = this.personId
            this.person_unit.taken_hours = 0
            this.distance_types = res.data["distance_types"]
            this.establishments = res.data["establishments"]
            this.establishment = res.data["establishment"]
            this.resolution_types = res.data["resolution_types"]
            this.appointment_start_date = moment(res.data["appointment_start_date"]).add(1,'days')._d
            if(res.data["establishment"].name==="PROFESORES HORAS DE CLASES"){
              this.hour_class= true
            }
            else{
              this.hour_class = false
            }
            if(this.person_unit.appointment_type.name==="RENUNCIA"){
              this.es_renuncia = true
            }
            this.person_unit.hour_class = this.hour_class
            if (res.data["hr_subjects"]){
              for(var i=0;i<res.data["hr_subjects"].length;i++){

                this.selected_hr_subjects.push({id:res.data["hr_subjects"][i].id,
                  name: res.data["hr_subjects"][i].name, ps_code: res.data["hr_subjects"][i].ps_code,
                  hours: res.data["appointment_has_hr_subjects"][i].hours, index: i, ps_code_disabled: true, subject: [res.data["hr_subjects"][i].name],
                  distanced_hours: res.data["has_hr_subjects"][i].hours, substracted_hours: res.data["appointment_has_hr_subjects"][i].substracted_hours})
                this.person_unit.taken_hours = this.person_unit.taken_hours + res.data["has_hr_subjects"][i].hours
              }
            }
            this.num_hr_subjects = this.person_unit.hr_subjects.length

            this.document_id =  res.data["document_id"]
            this.document_name_update = res.data["document_name"]
            this.document_name = res.data["document_name"]
            this.isLoading=false;
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }

  },
  methods: {
    updateHours(){

      var hours = 0
      for(var i = 0;i<this.selected_hr_subjects.length;i++){

        hours = hours + parseInt(this.selected_hr_subjects[i].distanced_hours)
      }
      this.person_unit.taken_hours = parseInt(hours)
    },
    selectDistanceType(id){

      var name = this.distance_types.find(x => x.id === id).name;

      if(name==="RENUNCIA"){
        this.es_renuncia=true

      }
      else{
        this.es_renuncia=false
      }
    },
    getPsCode(row){
      var newIndex = this.selected_hr_subjects.findIndex(subject => subject.index ===row.index);
      if(row.subject[0] === undefined){
        this.selected_hr_subjects[newIndex].name = null
        this.selected_hr_subjects[newIndex].id = null
        this.selected_hr_subjects[newIndex].ps_code = null
        this.selected_hr_subjects[newIndex].hours = null
        this.selected_hr_subjects[newIndex].ps_code_disabled = false
      }
      else if(row.subject[0].id && row.subject[0].ps_code){
        this.selected_hr_subjects[newIndex].name = row.subject[0].name
        this.selected_hr_subjects[newIndex].id = row.subject[0].id
        this.selected_hr_subjects[newIndex].ps_code = row.subject[0].ps_code
        this.selected_hr_subjects[newIndex].ps_code_disabled = true
      }
      else if(row.subject[0].id ===undefined && row.subject[0].ps_code === undefined){
        this.selected_hr_subjects[newIndex].name = row.subject[0]
      }
    },
    addHrSubject(){
      this.hr_subject_attributes.push({
        index:  this.num_hr_subjects,
        subject: null,
        _destroy:null,
        ps_code_disabled: false
      })
      this.selected_hr_subjects.push({
        index:  this.num_hr_subjects,
        name: null,
        id: null,
        ps_code: null,
        hours: null,
        _destroy: null,
        ps_code_disabled: false,
      })
      this.num_hr_subjects =  this.num_hr_subjects+1
    },
    deleteHrSubject(hr_subject){
      this.hr_subject_attributes = this.hr_subject_attributes.filter(subject => subject.index != hr_subject.index);
      this.selected_hr_subjects = this.selected_hr_subjects.filter(subject => subject.index != hr_subject.index);
      this.updateHours()
    },
    verifyDocument(){
      this.isLoading= true;
      let id = null
      if(this.queryType==="update"){
        id = this.person_unit.id
      }
      axios
          .get("/life_record/validate_resolution_number.json", {
            params: {res_number: this.person_unit.resolution, query_type: this.queryType,
              entity_type: "person_unit", id: id}
          })
          .then(response =>{
            this.saveDistance()
          }).catch(error => {
        if(error.response.status === 409){ //se encuentra
          this.$buefy.dialog.confirm({
            hasIcon: true,
            type: "is-warning",
            title: "Advertencia",
            message: error.response.data["message"],
            confirmText: "Continuar y guardar",
            cancelText: "Cancelar",
            onConfirm: () => this.saveDistance(),
            onCancel: () => this.isLoading = false
          })
        }
        else if(error.response.status === 400){//bad request
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalDistanceForm.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al agregar el alejamiento, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
        }
      })
    },
    saveDistance(){
      this.isLoading=true;
      let personUnitEstablishment = this.establishments.find(obj => obj.name == "PROFESORES HORAS DE CLASES");
      if (personUnitEstablishment)
        this.person_unit.establishment_id = personUnitEstablishment.id;


      if (this.queryType==="create"){
        if (this.file != null && this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Este archivo no tiene formato PDF",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        if (this.file != null && this.file.size > 1024*1024*25) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "El archivo no puede superar el maximo de 25 MB",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        /*if (this.file != null && (this.person_unit.resolution_date ==  null || this.person_unit.resolution_date ==  undefined) &&
            (this.person_unit.resolution == null || this.person_unit.resolution == undefined || this.person_unit.resolution == '') &&
            (this.person_unit.resolution_type_id == null || this.person_unit.resolution_type_id == undefined)) {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Para adjuntar un archivo, debe llenar al menos uno de los campos del documento",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }*/
        // Crea un objeto FormData
        let formData = new FormData();

        // Agrega datos al objeto FormData
        formData.append("distance", JSON.stringify(this.person_unit));
        formData.append("selected_hr_subjects", JSON.stringify(this.selected_hr_subjects));
        formData.append("file", this.file); // Aquí agregamos el archivo
        formData.append("document_name", this.document_name);

        axios
            .post("/admin/person_units/"+this.person_unit_id+"/create-document-distance.json", formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
            .then(response =>{
              this.errores_validacion = {};
              this.$buefy.dialog.alert({
                message: 'El alejamiento fue creado con éxito ',
                confirmText: 'Ok'
              })
              this.person_unit = {}
              this.isLoading=false;
              this.errors = [];
              EventBus.$emit('ALEJAMIENTO_CREADO', response.data);
              this.$emit('close')

            }).catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalDistanceForm.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al agregar el alejamiento, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
        })
      }
      else if (this.queryType==="update"){
        if (this.file != null && this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Este archivo no tiene formato PDF",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        if (this.file != null && this.file.size > 1024*1024*25) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "El archivo no puede superar el maximo de 25 MB",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        /*if (this.file != null && (this.person_unit.resolution_date ==  null || this.person_unit.resolution_date ==  undefined) &&
            (this.person_unit.resolution == null || this.person_unit.resolution == undefined || this.person_unit.resolution == '') &&
            (this.person_unit.resolution_type_id == null || this.person_unit.resolution_type_id == undefined)) {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Para adjuntar un archivo, debe llenar al menos uno de los campos del documento",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }*/
        this.person_unit.hr_subjects = this.selected_hr_subjects;
        let clean_person_unit = this.person_unit
        clean_person_unit = this.deleteData(clean_person_unit)

        // Crea un objeto FormData
        let formData = new FormData();

        // Agrega datos al objeto FormData
        formData.append("distance", JSON.stringify(clean_person_unit));
        formData.append("selected_hr_subjects", JSON.stringify(this.selected_hr_subjects));
        formData.append("file", this.file); // Aquí agregamos el archivo
        formData.append("document_name", this.document_name);

        axios
            .put("/admin/person_units/" + this.person_unit.id+"/update-document-distance.json",  formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
            .then(response => {
              this.$buefy.dialog.alert({
                message: 'El alejamiento fue editado con éxito ',
                confirmText: 'Ok'
              })
              this.errores_validacion = {};
              this.isLoading = false;
              this.person_unit = {}
              this.isLoading=false;
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          let elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalDistanceForm.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al editar el alejamiento, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
        })
      }
    },
    deleteData(person_unit){
      delete person_unit.units
      delete person_unit.work_plants
      delete person_unit.categories
      delete person_unit.appointment_types
      delete person_unit.resolution_type
      delete person_unit.establishment
      delete person_unit.hr_subjects
      delete person_unit.has_hr_subjects
      delete person_unit.appointment_has_hr_subjects
      delete person_unit.hierarchies
      delete person_unit.distance_types
      delete person_unit.establishments
      delete person_unit.working_days
      delete person_unit.resolution_types
      return person_unit
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
    getFilteredTags(text) {
      this.funcion_filtrada = this.functions.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    getFilteredHRSubjects(text) {
      this.filtered_hr_subjects = this.hr_subjects.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    onDeleteFile() {
      this.file = null;
    },
    generateName(){
      var tipo = "ALEJAMIENTO"
      var centroCosto = ""
      var number = ""
      var date = ""
      var start_date = ""
      var full_name = "-"+this.person_name

      if (this.person_unit.resolution_type_id!= null){
        var nombre_tipo = this.resolution_types.find(x => x.id === this.person_unit.resolution_type_id).name
        if(nombre_tipo.includes("RESOLUCION")){
          tipo = "RES"
        }
        else if(nombre_tipo.includes("DECRETO")){
          tipo = "DECR"
        }

        //tipo = this.resolution_types.find(x => x.id === this.person_unit.resolution_type_id).name
        //tipo = this.documentTypesNames.find(type => type.id === this.document.document_type_id).initials
      }

      if (this.cost_center != null){
        centroCosto = "-CC_" + this.cost_center.toString()
      }

      if (this.person_unit.resolution != null && this.person_unit.resolution != ''){
        number = "-N_" + this.person_unit.resolution
      }

      if (this.person_unit.resolution_date != null){
        date = "-"+("0" + this.person_unit.resolution_date.getDate()).slice(-2)
            + "_" + ("0" + (this.person_unit.resolution_date.getMonth()+1)).slice(-2)
            + "_" + this.person_unit.resolution_date.getFullYear()
      }

      if (this.person_unit.start_date != null){
        start_date = "-"+("0" + this.person_unit.start_date.getDate()).slice(-2)
            + "_" + ("0" + (this.person_unit.start_date.getMonth()+1)).slice(-2)
            + "_" + this.person_unit.start_date.getFullYear()
      }

      if ((this.person_unit.resolution_date == null || this.person_unit.resolution_date == '')  &&
          (this.person_unit.resolution == null || this.person_unit.resolution == '')){
        this.document_name = tipo +  centroCosto + full_name + start_date
      }
      else{
        this.document_name = tipo + number + centroCosto + date
      }
    },
  }

}
</script>

<style scoped>

</style>