<template>
  <section>
    <div class="container">
      <h1 class="is-size-2">Productos</h1>
      <div class="has-text-right">
        <b-button type="is-info"
                  icon-left="plus"
                  @click="newProduct()">
          Añadir Producto
        </b-button>
      </div>
      <br>
      <b-table
          :data="productos"
          :paginated=true
          per-page=10
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="product.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field= 'title'
            label= 'Título'
            width= '30em'
            searchable
            sortable
            v-slot="props"
        >
          <a :href="getProductUrl(props.row.id)">{{ props.row.title }}</a>
        </b-table-column>
        <b-table-column
            field= 'product_type'
            label= 'Tipo de Producto'
            searchable
            sortable
            v-slot="props"
        >
          {{ props.row.product_type }}
        </b-table-column>
        <b-table-column
            field= 'year'
            label= 'Año'
            numeric
            searchable
            sortable
            v-slot="props"
        >
          {{ props.row.year }}
        </b-table-column>
        <b-table-column
            field= 'serie'
            label= 'Serie'
            searchable
            sortable
            v-slot="props"
        >
          {{props.row.serie }}
        </b-table-column>
        <b-table-column
            field= 'serial_number'
            label= 'N° Serial'
            numeric
            searchable
            sortable
            v-slot="props"
        >
          {{ props.row.serial_number }}
        </b-table-column>
      </b-table>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import axios from "axios";

export default {
  data(){
    return{
      isLoading:false,
      currentPage:1,
      productos:[

      ],
      tipos_producto:[],

    }
  },
  created(){
    this.isLoading=true;
    return axios.get("/products.json")
        .then(res => {
          this.tipos_producto = res.data['tipos_producto'];
          this.productos = res.data['productos'];

          this.isLoading=false;
        })
        .catch(e => {
          this.isLoading=false;
          console.log(e);
        });

  },
  props:[

  ],
  methods:{
    newProduct(){
      window.location.href="/products/new"
    },
    getProductUrl(producto_id){
      return "/products/"+producto_id;
    }

  },
  computed:{

  }
}
</script>

