<template>

<section>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
      <b-message type="is-danger" has-icon>
        <div v-for="(item,index) in errores_validacion">
          {{ item[0] }}
        </div>
      </b-message>
    </div>
      <form @submit.prevent="handleSubmit(saveProduct)">
      <div class="box">
        <div class="columns">
          <div class="column is-3 is-offset-9">
            <b-switch v-model="requiredFieldOnly">Mostrar solo campos obligatorios</b-switch>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Título*">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-input v-model="product.title" type="text"></b-input>
                <span class="validation-alert">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Tipo de Producto*">
              <ValidationProvider rules="required" v-slot="{errors}">
                <multiselect v-model="product.product_type_attributes"
                             :options="tipos_producto"
                             label="name"
                             placeholder="Seleccione un tipo de producto"
                             selectLabel="Presione para seleccionar"
                             selectedLabel="Seleccionado"
                             deselectLabel="No se puede deseleccionar"
                             :allow-empty="false">
                  <template v-slot:noOptions>
                    No existen datos
                  </template>
                </multiselect>
                <span class="validation-alert">{{ errors[0] }}</span>
              </ValidationProvider>

            </b-field>
          </div>
        </div>

        <div class="columns">
          <div class="column" v-if="!requiredFieldOnly">
            <div class="field">
              <b-field label="Título Corto">
                <b-input v-model="product.short_title"></b-input>
              </b-field>
            </div>
          </div>
          <div class="column">
            <div class="field" v-if="!requiredFieldOnly">
              <b-field label="Año">
                <ValidationProvider rules="integer|positive" v-slot="{errors}">
                  <b-input type="number" v-model="product.year"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column" v-if="!requiredFieldOnly">
            <div class="field">
              <b-field label="Resumen">
                <b-input
                    type="textarea"
                    minlength="10"
                    maxlength="500"
                    v-model="product.summary">
                </b-input>
              </b-field>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column" v-if="!requiredFieldOnly">
            <div class="field">
              <b-field label="Serie">
                <b-input v-model="product.serie"></b-input>
              </b-field>
            </div>
          </div>
          <div class="column" v-if="!requiredFieldOnly">
            <div class="field">
              <b-field label="N° Serial">
                <ValidationProvider rules="integer|positive" v-slot="{errors}">
                  <b-input v-model="product.serial_number"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column" v-if="!requiredFieldOnly">
            <div class="field">
              <b-field label="Lugar">
                <b-input v-model="product.place"></b-input>
              </b-field>
            </div>
          </div>
          <div class="column">
            <b-field label="Fecha*">
              <ValidationProvider rules="required" v-slot="{errors}">
                <b-datepicker
                    v-model="product.product_date"
                    icon="calendar-alt"
                    trap-focus
                    locale="es-ES"
                    editable
                    placeholder="Formato de Fecha: dd/mm/aaaa">
                </b-datepicker>
                <span class="validation-alert">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>
          </div>
          <div class="column" v-if="!requiredFieldOnly">
            <b-field label="URL">
              <b-input v-model="product.url"></b-input>
            </b-field>
          </div>
        </div>

        <div class="columns">
          <div class="column" v-if="!requiredFieldOnly">
            <b-field label="Archivo">
              <b-input v-model="product.archive"></b-input>
            </b-field>
          </div>
          <div class="column" v-if="!requiredFieldOnly">
            <b-field label="Posición en el archivo">
              <b-input v-model="product.archive_position"></b-input>
            </b-field>
          </div>
          <div class="column" v-if="!requiredFieldOnly">
            <b-field label="Materia">
              <b-input v-model="product.signature"></b-input>
            </b-field>
          </div>
        </div>

        <div class="columns">
          <div class="column is-2" v-if="!requiredFieldOnly">
            <b-field label="Edición">
              <ValidationProvider rules="integer|positive" v-slot="{errors}">
                <b-input v-model="product.edition"></b-input>
                <span class="validation-alert">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Origen del dato*">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-input v-model="product.validation_attributes.data_source"></b-input>
                <span class="validation-alert">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>
          </div>
        </div>
        <br>
        <span>
          <h3 class="is-size-3 has-text-weight-bold">
            Participantes*
            <b-tooltip label="En esta sección debe ingresar las y los participantes del producto que
             se encuentren registrados en el sistema, de esta manera, los datos ingresados de este producto quedarán asociados a esta persona."
                       position="is-right"
                       size="is-medium"
                       multilined>
            <b-icon
                pack="fas"
                icon="info-circle"
                size="is-small"
                type="is-info">
            </b-icon>
          </b-tooltip>
          </h3>

        </span>
        <h6 class="is-size-6 subtitle">(Se requiere al menos un/a participante. Recuerde añadirse a usted si es que participa en esta actividad académica)</h6>
        <!-- FORMULARIO PARTICIPANTES -->
        <div v-for="(participant,index) in product.participants_attributes">

          <div class="columns">
            <div class="column">
              <b-field label="Seleccione unidad*">
                <ValidationProvider rules="required" v-slot="{errors}">
                  <multiselect v-model="product.participants_attributes[index].unit_id"
                               :options="unidades.map(x => x.id)"
                               :custom-label="opt => unidades.find(x => x.id == opt).name"
                               @input="findUnitParticipants(index)"
                               placeholder="Seleccione unidad"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                  </multiselect>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>

              </b-field>
            </div>
            <div class="column">
              <b-field label="Seleccione participante*">
                <ValidationProvider rules="required" v-slot="{errors}">
                  <multiselect v-model="product.participants_attributes[index].person_id"
                               :options="product.participants_attributes[index].unit_participants.map(x => x.id)"
                               :custom-label="opt => product.participants_attributes[index].unit_participants.find(x => x.id == opt).nombre"
                               placeholder="Seleccione participante"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                  </multiselect>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>

          </div>

          <div class="columns">
            <div class="column">
              <b-field label="Tipo de participación*">
                <ValidationProvider rules="required" v-slot="{errors}">
                  <multiselect v-model="product.participants_attributes[index].participant_type_id"
                               :options="tipos_participacion.map(x => x.id)"
                               :custom-label="opt => tipos_participacion.find(x => x.id == opt).name"
                               placeholder="Seleccione tipo de participación"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                  </multiselect>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Estados de participación*">
                <ValidationProvider rules="required" v-slot="{errors}">
                  <multiselect v-model="product.participants_attributes[index].participant_state_id"
                               :options="estados_participacion.map(x => x.id)"
                               :custom-label="opt => estados_participacion.find(x => x.id == opt).name"
                               placeholder="Seleccione estado de participación"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                  </multiselect>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>

          <div>
            <b-button @click.prevent="deleteParticipant(index)" type="is-danger">Eliminar Participante</b-button>
            <br>
          </div>

        </div>
        <b-button class="mt-2" @click.prevent="addParticipant" type="is-primary">Añadir Participante</b-button>

        <span>
          <h3 class="is-size-3 has-text-weight-bold">
            Referencias de participantes
            <b-tooltip label="En esta sección debe ingresar en un campo de texto las y los participantes del producto en el orden deseado, solo para su visualización.
                                Este campo de texto es solo referencial."
                       position="is-right"
                       size="is-small"
                       multilined>
            <b-icon
                pack="fas"
                icon="info-circle"
                size="is-small"
                type="is-info">
            </b-icon>
          </b-tooltip>
          </h3>

        </span>
        <b-field label="Participantes(registrados y no registrados)">
          <b-input v-model="product.participants_references" type="text"></b-input>
        </b-field>

        <div class="actions has-text-right">
          <button @click="checkValidation" class="button is-primary" type="submit">Guardar Producto</button>
        </div>


      </div>
    </form>
  </ValidationObserver>

  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>



</section>
</template>

<script>
import axios from "axios";
export default {
  created() {
    this.isLoading=true;
    axios
        .get("/products/new.json")
        .then(res => {
          this.tipos_producto = res.data['tipos_producto'];
          this.participantes = res.data['participantes'];
          this.tipos_participacion = res.data['tipos_participacion'];
          this.estados_participacion = res.data['estados_participacion'];
          this.unidades = res.data['unidades'];
          if (this.queryType!="update"){
            //se añade un participante automaticamente si es que es acádemico
            if(this.cargoPrincipal.name=="ACADEMICO"){
              this.product.participants_attributes.push({
                person_id:null,
                participant_state_id:null,
                participant_type_id:null,
                unit_id:this.unidadPrincipal.id,
                unit_participants:[],
                _destroy:null
              })
              if (this.product.participants_attributes[0].unit_id != null) {
                axios
                    .get("/get_people_by_unit.json", {
                      params: {unit_id: this.product.participants_attributes[0].unit_id}
                    })
                    .then(res => {
                      this.product.participants_attributes[0].unit_participants = res.data['personas'];
                      this.product.participants_attributes[0].person_id=this.personId;
                    }).catch(error => {
                  this.isLoading = false;
                  console.log(error)
                })
              }
            }
            this.isLoading=false;
          }
          if (this.queryType=="update"){
            axios
                .get("/products/"+this.productId+".json")
                .then(res => {
                  this.product = res.data["producto"]
                  if (this.product.product_date!=null && this.product.product_date!=undefined)
                    this.product.product_date = new Date(this.product.product_date);
                  this.isLoading=false;
                  if (this.product.validation_attributes==null){
                    this.product.validation_attributes={}
                    this.product.data_source=""
                  }
                })
          }
        })
        .catch(e => {
          console.log(e);
          this.isLoading=false;
        });

  },
  data() {
    const data = [

    ]

    return {
      data,
      product:{
        product_type_attributes:{},
        participants_attributes:[],
        validation_attributes:{}
      },
      tipos_producto:[],
      participantes:[],
      participante:{},
      tipos_participacion:[],
      estados_participacion:[],
      unidades:[],
      errores_validacion:{},
      isLoading:false,
      requiredFieldOnly:false,
    }
  },
  props:[
    'userId','queryType','productId','cargoPrincipal','unidadPrincipal','personId'

  ],
  methods:{
    saveProduct(){
      var retorno = JSON.parse(JSON.stringify(this.product.participants_attributes));
      this.isLoading=true;
      if (this.queryType=="create"){
        axios
            .post("/products.json", {
              product: this.product, participants: retorno.map(function(x) {
                delete x.unit_id;
                delete x.unit_participants;
                return x;
              }),
              user_id:this.userId,
              data_source:this.product.validation_attributes.data_source
            })
            .then(response => {
              //console.log(response)
              this.errores_validacion = {};
              this.isLoading=false;
              window.location.href = '/products/'+response.data["id"]
            }).
        catch(error => {
          //console.log(error);
          //console.log(error.response);
          //console.log(error.response.data);
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.isLoading=false;
        })
      }

      if (this.queryType=="update"){
        axios
            .put("/products/"+this.productId+".json", {
              product: this.product,
              user_id: this.userId,
              data_source: this.product.validation_attributes.data_source
            })
            .then(response =>{
              this.errores_validacion = {};
              window.location.href = '/products/'+this.productId
              this.isLoading=false;
            }).catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.isLoading=false;
        })
      }

    },
    addParticipant(){
      this.product.participants_attributes.push({
        person_id:null,
        participant_state_id:null,
        participant_type_id:null,
        unit_id:null,
        unit_participants:[],
        _destroy:null
      })
    },
    deleteParticipant(index){
      this.product.participants_attributes.splice(index,1)
    },
    findUnitParticipants(index) {
      if (this.product.participants_attributes[index].unit_id != null) {
        this.isLoading = true;
        axios
            .get("/get_people_by_unit.json", {
              params: {unit_id: this.product.participants_attributes[index].unit_id}
            })
            .then(res => {
              this.product.participants_attributes[index].unit_participants = res.data['personas'];
              this.isLoading = false;
            }).catch(error => {
          this.isLoading = false;
          console.log(error)
        })
      }
    },
    blur: function () {
      this.computedForm.refs.selected.validate();
    },
    async checkValidation(){
      const valid = await this.$refs.observer.validate()
      if(!valid){
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },

  },
  computed: {
    computedForm: function () {
      return this.$refs.form;
    }
  },



}
</script>