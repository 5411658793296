<template>
<div>
  <div class="container">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent @keydown.enter="$event.preventDefault()">
        <!-- Box de Formulario Evento -->
        <div class="modal-card" style="width: 100%" id="modal-event">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <!-- Componente de la busqueda -->
          <section class="modal-card-body" id="modal-events">
            <section v-if="showTable">
              <div class="columns">
                <div class="column"></div>
                <div class="column is-6">
                  <div class="has-text-centered">
                    <b-field>
                      <b-input type="text" expanded minlength="3" icon="search" v-model='busqueda' @keyup.enter.native.prevent="search(busqueda)" placeholder="Búsqueda con al menos 3 letras..."></b-input>
                      <p class="control">
                        <b-button @click.prevent="search(busqueda)" class="is-primary" id="boton-buscar">Buscar</b-button>
                      </p>
                    </b-field>
                  </div>
                </div>
                <div class="column"></div>
              </div>
              <div class="columns">
                <div class="column">
                  <h3 class="is-size-4 has-text-weight-bold">Tabla Búsqueda
                    <b-tooltip label="Al seleccionar un participante desde la tabla de búsqueda, se agregará automáticamente a la Tabla Resumen de Participantes."
                               position="is-right"
                               size="is-medium"
                               multilined>
                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          size="is-small"
                          type="is-info">
                      </b-icon>
                    </b-tooltip>
                  </h3>
                </div>
                <div class="column is-narrow">
                  <b-tooltip label="Si la persona no se encuentra o no existe y ademas es externa, la puede agregar presionando el botón +"
                             multilined
                             position="is-left">
                    <b-button class="is-primary is-outlined" @click="addExternalPerson">
                      <b-icon icon="plus"></b-icon>
                    </b-button>
                  </b-tooltip>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-table
                      :data="people"
                      :paginated=true
                      per-page=5
                      striped
                      hoverable
                      :current-page="currentPage"
                      :pagination-simple=false
                      pagination-position="bottom"
                      default-sort-direction="asc"
                      sort-icon="arrow-up"
                      sort-icon-size="is-small"
                      default-sort="publication.title"
                      aria-next-label="Siguiente"
                      aria-previous-label="Anterior"
                      aria-page-label="Página"
                      aria-current-label="Página actual"
                  >
                    <b-table-column
                        field="name"
                        label="Nombres"
                        sortable
                        searchable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.name}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="corporate_mail"
                        label="Correo"
                        sortable
                        searchable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.corporate_mail ? props.row.corporate_mail : props.row.personal_mail }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="unit"
                        label="Unidad"
                        sortable
                        searchable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.unit }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field= 'biggest_grade'
                        label= 'Grado Mayor'
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{  props.row.biggest_grade }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field= 'active'
                        label= '¿Está activo?'
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{  props.row.active }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field= 'external'
                        label= '¿Es externo?'
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{  props.row.external }}
                      </template>
                    </b-table-column>

                    <b-table-column
                        label="Acciones"
                        width="10"
                        v-slot="props">
                      <b-button type="is-success"
                                @click.prevent="selectPerson(props.row)"
                                icon-left="plus"> Seleccionar </b-button>
                    </b-table-column>
                  </b-table>
                </div>
              </div>
            </section>
            <section class="modal-card-body" id="modal-events" v-if="program_person && !showTable">
              <div class="columns">
                <div class="column">
                  <form @submit.prevent="handleSubmit(saveProgramPerson)">
                    <div class="columns has-text-centered">
                      <div class="column">
                        <h1 class="is-size-3">{{ program_person.name}}</h1> <br v-if="program_person.unit!=null"> {{ program_person.unit!=null ? program_person.unit:'' }}
                        <br v-if="program_person.biggest_grade!=null"> {{ program_person.biggest_grade!=null? "GRADO MAYOR - "+program_person.biggest_grade:'' }}
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <b-field label="Seleccionar Función*">
                          <ValidationProvider rules="required" v-slot="{errors}">
                            <multiselect v-model="program_person.program_people_type_id"
                                         :options="program_people_types.map(type => type.id)"
                                         :custom-label="opt => program_people_types.find(x => x.id == opt).name"
                                         placeholder="Seleccione función"
                                         selectLabel="Seleccionar"
                                         selectedLabel="Seleccionado"
                                         deselectLabel=""
                                         :allow-empty="false">
                              <template v-slot:noOptions>
                                No existen datos
                              </template>
                            </multiselect>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <b-field label="Fecha de Inicio*">
                          <ValidationProvider rules="required" v-slot="{errors}">
                            <b-datepicker
                                v-model="program_person.start_date"
                                icon="calendar-alt"
                                trap-focus
                                locale="es-ES"
                                editable
                                placeholder="Formato de Fecha: dd/mm/aaaa">
                            </b-datepicker>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Fecha de Término">
                          <b-datepicker
                              v-model="program_person.end_date"
                              icon="calendar-alt"
                              trap-focus
                              locale="es-ES"
                              editable
                              placeholder="Formato de Fecha: dd/mm/aaaa">
                          </b-datepicker>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <b-field label="Link de documentación">
                          <b-input v-model="program_person.url"></b-input>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column is-narrow">
                        <b-field label="¿Es Director/a del Programa?">
                          <b-radio-button v-model="director"
                                          :native-value="false"
                                          type="is-danger is-light is-outlined">
                            <span>No</span>
                          </b-radio-button>
                          <b-radio-button v-model="director"
                                          :native-value="true"
                                          @input="activate_comittee"
                                          type="is-success is-light is-outlined">
                            <span>Si</span>
                          </b-radio-button>
                        </b-field>
                      </div>
                      <div class="column is-narrow">
                        <b-field label="¿Es Miembro del Cómite?">
                          <b-radio-button v-model="comittee"
                                          :native-value="false"
                                          :disabled="director"
                                          type="is-danger is-light is-outlined">
                            <span>No</span>
                          </b-radio-button>
                          <b-radio-button v-model="comittee"
                                          :native-value="true"
                                          :disabled="director"
                                          type="is-success is-light is-outlined">
                            <span>Si</span>
                          </b-radio-button>
                        </b-field>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </section>
          </section>
        </div>
        <footer class="modal-card-foot actions" style="justify-content: flex-end;">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="closeModal">Cerrar</button>
            <b-button  v-if="program_person && !showTable && queryType != 'update'" type="is-danger"
                      @click="cancel()"> Cancelar </b-button>
            <b-button  v-if="program_person && !showTable && queryType != 'update'" type="is-success"
                      @click.prevent="saveAndRepeat()"
                      icon-left="plus"> Guardar y Añadir Otro </b-button>
            <button class="button is-primary" @click="saveParticipants" icon-left="plus" v-if="program_person && !showTable" type="submit" >Guardar </button>
          </div>
        </footer>
        <b-modal
            v-model="isModalActive"
            has-modal-card
            trap-focus
            :destroy-on-hide="true"
            aria-role="dialog"
            aria-modal
            :can-cancel="canCancel">
          <template #default="props">
            <add-external-academic
                queryType="create"
                modal-header="Añadir Académico Externo"
                @close="props.close"></add-external-academic>
          </template>
        </b-modal>
      </form>
    </ValidationObserver>
  </div>
  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

</div>
</template>

<script>
import axios from "axios";
import AddExternalAcademic from "./AddExternalAcademic";
import moment from "moment";

export default {
  name: "AddAcademic",
  components:{AddExternalAcademic},
  data() {
    return {
      isLoading: false,
      isCreateModal: false,
      canCancel: ['escape'],
      busqueda: "",
      people: [],
      checkedRows: [],
      checkboxPosition: "left",
      currentPage:1,
      tipos_participaciones: [],
      program_person:{},
      program_people_types:[{id:1,name:"PROFESOR/A DE CLAUSTRO/NUCLEO"}, {id:2,name:"PROFESOR/A COLABORADOR/A"}, {id:3,name:"PROFESOR/A VISITANTE"}],
      showTable:true,
      director:false,
      comittee:false,
      isModalActive:false,
    }
  },
  props:[
    'modalHeader', 'inventores', 'id', 'code_program_career', 'mention', 'resolution_number', 'version','queryType', 'program_person_selected', 'comittee_active'
  ],
  created(){
    this.isLoading=true
    this.checkedRows = this.inventores
    this.isLoading=false
    this.busqueda = ""
    if(this.queryType == 'update'){
      this.program_person.name = this.program_person_selected.person_name
      this.program_person.unit =  this.program_person_selected.unit_name
      this.program_person.biggest_grade = this.program_person_selected.grade
      this.program_person.program_people_type_id = this.program_person_selected.program_person.program_people_types_id
      this.program_person.start_date = new Date(moment(this.program_person_selected.program_person.start_date).format('MM/DD/YYYY'))
      if (this.program_person_selected.program_person.end_date != null){
        this.program_person.end_date = new Date(moment(this.program_person_selected.program_person.end_date).format('MM/DD/YYYY'))
      }
      else{
        this.program_person.end_date = null
      }

      this.program_person.url = this.program_person_selected.program_person.documentantion_link
      this.director = this.program_person_selected.program_person.is_director
      this.comittee = this.program_person_selected.program_person.is_comittee_member
      this.showTable = false
    }

  },
  methods: {
    search(query) {
      this.isLoading = true
      if (query.length < 2) {
        this.$buefy.dialog.alert({
          message: 'Por favor ingrese a lo menos 3 letras.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = false;
        return false;
      }
      var checkedRows = []
      for (var i = 0, l = this.checkedRows.length; i < l; i++) {
        if (this.checkedRows[i] !== undefined) {
          checkedRows.push({person_id: this.checkedRows[i].person_id})
        } else {
          checkedRows.push({person_id: -1})
        }
      }
      checkedRows.push({person_id: -1})
      return axios
          .get("/people/" + query + "/" + JSON.stringify({checkedRows: checkedRows}) + "/search_people.json"
          ).then(res => {
            this.people = res.data.participants
            this.people.forEach(function (participante) {
              if (participante.active == true) {
                participante.active = "SI"
              } else {
                participante.active = "NO"
              }
              if (participante.external == true) {
                participante.external = "SI"
              } else {
                participante.external = "NO"
              }
            })
            this.isLoading = false;
          }).catch(error => {
            console.log(error)
            this.isLoading = false;
          })
      this.isLoading = false;
    },
    saveParticipants() {
      this.isLoading = true

      if(this.queryType == 'update'){

        var fecha = new Date(Date.now())
        var rango_fecha = false

        if (this.program_person.end_date == null || this.program_person.end_date == undefined){
          rango_fecha = true
          if(this.program_person.start_date >= fecha){
            rango_fecha = false
          }
        }
        else if(this.program_person.end_date > fecha){
          rango_fecha = true
          if(this.program_person.start_date >= fecha){
            rango_fecha = false
          }
        }
        else{
          rango_fecha = false
        }
        if(this.comittee_active.length > 0 && this.director && rango_fecha){
          if (this.comittee_active.filter(option => (option.program_person.is_director && option.program_person.people_id != this.program_person.person_id)).length > 0){
            this.isLoading = false
            return this.$buefy.dialog.alert({
              message: 'No puede haber más de un director(a) activo a la fecha.',
              confirmText: 'Ok',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          }
        }

        axios
            .put("/program_people/"+this.program_person_selected.program_person.id+".json",{
              program_person: { program_people_types_id: this.program_person.program_people_type_id,
                people_id:this.program_person.person_id,
                documentantion_link:this.program_person.url,
                start_date:this.program_person.start_date,
                end_date:this.program_person.end_date,
                is_director:this.director,
                is_comittee_member:this.comittee},
            })
            .then(res => {
              this.$emit('clicked', this.selectedParticipants)
              this.$emit('close')
              this.isLoading = false
            })
            .catch(error =>{
              console.log(error)
              this.isLoading = false
            })
      }
      else{
        var fecha = new Date(Date.now())
        var rango_fecha = false

        if (this.program_person.end_date == null || this.program_person.end_date == undefined){
          rango_fecha = true
          if(this.program_person.start_date >= fecha){
            rango_fecha = false
          }
        }
        else if(this.program_person.end_date > fecha){
          rango_fecha = true
          if(this.program_person.start_date >= fecha){
            rango_fecha = false
          }
        }
        else{
          rango_fecha = false
        }
        if(this.comittee_active.length > 0 && this.director && rango_fecha){
          if (this.comittee_active.filter(option => (option.program_person.is_director)).length > 0){
            this.isLoading = false
            return this.$buefy.dialog.alert({
              message: 'No puede haber más de un director(a) activo a la fecha.',
              confirmText: 'Ok',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          }
        }
        axios
            .post("/program_people.json",{
              program_person: { program_people_types_id: this.program_person.program_people_type_id,
                people_id:this.program_person.person_id,
                documentantion_link:this.program_person.url,
                start_date:this.program_person.start_date,
                end_date:this.program_person.end_date,
                is_director:this.director,
                is_comittee_member:this.comittee},
              program_career:{code: this.code_program_career,
                version:this.version,
                resolution_number:this.resolution_number,
                mention:this.mention}
            })
            .then(res => {
              this.$emit('clicked', this.selectedParticipants)
              this.$emit('close')
              this.isLoading = false
            })
            .catch(error =>{
              console.log(error)
              this.isLoading = false
            })
      }


    },
    deleteParticipant(participants) {
      this.checkedRows = this.checkedRows.filter(person => person.person_id != participants.person_id);
    },
    addParticipant(participants) {
      if (this.checkedRows.filter(person => person.corporate_mail == participants.corporate_mail).length == 0) {
        this.checkedRows.push(participants);
        this.people = this.people.filter(person => person.corporate_mail != participants.corporate_mail);
      }
    },
    addExternalPerson(){
      this.isModalActive = true
    },
    onClickChild(value){
      this.search(value)
    },
    deleteParticipantButton(participants){
      this.$buefy.dialog.confirm({
        title: 'Eliminar Participante',
        message: 'Precaución, al momento de guardar esta actividad académica esta acción eliminará la asociación con la/el participante, lo que implica que ya no podrá ver esta información ni será contada en su actividad académica ¿Desea continuar?',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteParticipant(participants)
      })
    },
    saveProgramPerson(){

    },
    saveProgramPersonAndOther(){
      this.saveProgramPerson();
      this.cleanProgramPerson();
    },
    cleanProgramPerson(){
      this.program_person = {};
      this.showTable = true;
    },

    selectPerson(person){
      this.isLoading = true;
      this.program_person = person;
      this.showTable = false;
      this.isLoading = false;
    },
    cancel(){
      this.cleanProgramPerson()
    },
    saveAndRepeat(){
      this.isLoading = true
      axios
          .post("/program_people.json",{
            program_person: { program_people_types_id: this.program_person.program_people_type_id,
              people_id:this.program_person.person_id,
              documentantion_link:this.program_person.url,
              start_date:this.program_person.start_date,
              end_date:this.program_person.end_date},
            program_career:{code: this.code_program_career,
              version:this.version,
              resolution_number:this.resolution_number,
              mention:this.mention}
          })
          .then(res => {
            this.cleanProgramPerson()
            this.isLoading = false
          })
          .catch(error =>{
            console.log(error)
            this.isLoading = false
          })
    },
    closeModal(){
      this.$emit('clicked')
      this.$emit('close')
    },
    activate_comittee(){
      if (!this.comittee){
        this.comittee = true
      }
    }
  }
}
</script>

<style scoped>

</style>