<template>
    <div class="peopledatatablegraduatesadvancedsearch">
        <form action="">
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head" style="background-color: #8c67ef; text-align:center;">
                    <p class="modal-card-title" style="color:#fff;">Búsqueda avanzada</p>
                    <button
                        type="button"
                        class="delete"
                        @click="$emit('close')"/>
                </header>
                <section class="modal-card-body">
                    <b-field label="Departamento">
                        <b-select placeholder="Seleccione un departamento" v-model="filters.selectedDepto">
                            <option
                                v-for="(unidad, index) in departamentos"
                                :value="unidad"
                                :key="index">
                            {{ unidad }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-field label="Carrera">
                        <b-select placeholder="Seleccione una carrera" v-model="filters.selectedCareer">
                            <option
                                v-for="(carrera, index) in carreras"
                                :value="carrera.name"
                                :key="index">
                            {{ carrera.name }}
                            </option>
                        </b-select>
                    </b-field>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Tipo de año">
                                <b-select placeholder="Seleccione un tipo de año" v-model="filters.selectedTypeYear">
                                    <option
                                        v-for="(year_type, index) in years_type"
                                        :value="year_type"
                                        :key="index">
                                    {{ year_type }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Desde">
                                <b-select placeholder="Seleccione un año" v-model="filters.selectedDesde">
                                    <option
                                        v-for="(year, index) in years"
                                        :value="year"
                                        :key="index">
                                    {{ year }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Hasta">
                                <b-select placeholder="Seleccione un año" v-model="filters.selectedHasta">
                                    <option
                                        v-for="(year, index) in years"
                                        :value="year"
                                        :key="index">
                                    {{ year }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <b-button
                        label="Cerrar"
                        @click="$emit('close')" />
                    <b-button
                        label="Buscar"
                        type="is-primary"
                        @click="search()"/>
                </footer>
            </div>
        </form>
    </div>
</template>
<script>
export default {
    name: 'PeopleDataTableGraduatesAdvancedSearch',
    props:{
        departamentos:{
            type: Array
        },
        carreras:{
            type: Array
        }
    },
    data(){
        return{
            years:[],
            years_type:['Ingreso', 'Egreso', 'Titulación'],
            filters:{
                selectedDepto: '',
                selectedCareer: '',
                selectedTypeYear: '',
                selectedDesde: 0,
                selectedHasta: 0
            }
        }
    },
    beforeMount(){
        this.createYears()
    },
    methods:{
        createYears(){
            var actualDate = new Date()
            var actualYear = actualDate.getFullYear()
            for(var i=1980; i<=parseInt(actualYear); i++){
                this.years.push(i)
            }
        },
        search(){
            this.$emit('advanced-search-filters', this.filters)
            this.$emit('close',)
        }
    }
}
</script>