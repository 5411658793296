import { render, staticRenderFns } from "./permissions_manager.vue?vue&type=template&id=97dfdf06&scoped=true"
import script from "./permissions_manager.vue?vue&type=script&lang=js"
export * from "./permissions_manager.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97dfdf06",
  null
  
)

export default component.exports