<template>
<section>
  <div class="modal-card" style="width:100%">
    <header class="modal-card-head">
      <p class="modal-card-title">{{modalHeader}}</p>
      <button
          type="button"
          class="delete"
          @click="$emit('close')"/>
    </header>
    <section class="modal-card-body" ref="modalPersonForm" style="width:100%">
      <div class="columns">
        <div class="column is-12">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
              <b-message type="is-danger" has-icon>
                <div v-for="(item,index) in errores_validacion">
                  {{ item[0] }}
                </div>
              </b-message>
            </div>
            <form @submit.prevent="handleSubmit(saveAssociation)">
              <!-- Box de Formulario Project -->
              <div class="box">

                <div class="columns">

                  <div class="column">
                    <b-field>
                      <template #label>
                        Nombre Entidad Externa* (ingrese por lo menos 3 letras)
                        <b-tooltip
                            label="Se recomienda búsqueda de agrupaciones por sigla, ejemplo: ANID, no Agencia Nacional... mientras que para instituciones educacionales, se recomienda por nombre completo, ejemplo: universidad de santiago de chile"
                            position="is-right"
                            size="is-medium"
                            multilined>

                          <b-icon
                              pack="fas"
                              icon="info-circle"
                              size="is-small"
                              type="is-info">
                          </b-icon>
                        </b-tooltip>
                      </template>
                      <ValidationProvider rules="required" v-slot="{ validate, errors }">
                        <b-autocomplete
                            @input="validate"
                            :data="partner_entities"
                            placeholder="ej ANID o UNIVERSIDAD DE SANTIAGO DE CHILE  o NOMBRE EMPRESA - ENTIDAD"
                            field="show_name"
                            :loading="isFetching"
                            @typing="searchPartnerEntities"
                            v-model="partner_entity.name"
                            @select="option => {if (option) {partner_entity.id = option.id; partner_entity.name = option.name; }}">

                          <template slot-scope="props">
                            {{ props.option.show_name ? props.option.show_name : props.option.name}}
                          </template>
                        </b-autocomplete>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>

                    </b-field>
                  </div>
                  <div v-if="project.is_postulated" class="column is-narrow">
                    <b-field>
                      <template #label>
                        <div class="has-text-centered">
                          <b-tooltip
                              label="Si la entidad que desea agregar no existe, la puede agregar presionando el botón +"
                              position="is-bottom"
                              size="is-medium"
                              multilined>

                            <b-icon
                                pack="fas"
                                icon="info-circle"
                                type="is-info">
                            </b-icon>
                          </b-tooltip>

                        </div>

                      </template>
                      <b-button class="is-primary is-outlined" @click="addEntity()">
                        <b-icon icon="plus"></b-icon>
                      </b-button>
                    </b-field>

                  </div>
                  <div v-if="associations_types.length>0" class="column">
                    <ValidationProvider rules="required" v-slot="{ validate, errors }">
                      <b-field label="Tipo de asociación*">
                        <multiselect v-model="partner_entity.association_type"
                                     :options="associations_types"
                                     :custom-label="opt => opt.name"
                                     placeholder="Seleccione tipo de asociación"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel="Seleccionado"
                                     deselectLabel="No se puede deseleccionar"
                                     :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                            No se encontraron elementos.
                        </span>
                        </multiselect>
                      </b-field>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>



                <div class="columns">
                  <div class="column">
                    <ValidationProvider :rules="{max: 255}" v-slot="{ errors }">
                      <b-field label="Nombre de contacto">
                        <b-input v-model="partner_entity.contact_name"></b-input>
                      </b-field>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <b-field label="Telefono de contacto">
                      <ValidationProvider rules="integer|positive|max:12" v-slot="{ errors }">
                        <b-input v-model="partner_entity.contact_phone"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Correo de contacto">
                      <ValidationProvider rules="max:255" v-slot="{ errors }">
                        <b-input v-model="partner_entity.contact_email"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>


                <div class="actions has-text-right">
                  <button class="button is-danger" icon-left="trash" @click.prevent="$emit('close')">Cancelar</button>
                  <button class="button is-primary" type="submit" icon-left="plus" @click="checkValidation()">{{ saveButtonName }}</button>
                </div>

              </div>


            </form>
          </ValidationObserver>

        </div>
      </div>
    </section>
  </div>
  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  <b-modal
      v-model="isAddModalEntityActive"
      :destroy-on-hide="true"
      width="80%"
      :can-cancel="canCancel">
    <template #default="props">
      <modal_partner_entity_life_record
          query-type="create"
          modal-header="Añadir nueva entidad"
          v-bind:all-entity-types="true"
          @close="props.close"
          @clicked="onClickChild"></modal_partner_entity_life_record>
    </template>
  </b-modal>
</section>
</template>

<script>
import axios from "axios";
import modal_partner_entity_life_record from "../life_record/partner_entity/modal_partner_entity_life_record";
export default {
  name: "AssociatedEntityModal",
  components:{ modal_partner_entity_life_record},
  data() {
    return {
      isImageModalActive: false,
      isCardModalActive: false,
      canCancel: ['escape'],
      isLoading: false,
      associations_types:[],
      partner_entity:{},
      partner_entities:[],
      errores_validacion:{},
      isFetching:false,
      checkboxCustom:'No',
      entity_type:{},
      entity_types:[],
      isAddModalEntityActive: false,
    }
  },
  async created(){
    await this.getAssociationsTypes();
    if (this.project.id && this.entityId){
      this.getEntity();
    }
  },
  props:[ 'modalHeader', 'project','entityId', 'actionType', 'saveButtonName' ],
  methods: {
    async checkValidation() {
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      } else {

      }
    },
    searchPartnerEntities(name){
      if (name.length<3) {
        this.partner_entities = []
        return
      }
      let vm = this;
      vm.isLoading=true;
      axios
          .get("/admin/partner_entities/financings-search.json", { params: { name: name } })
          .then(res => {
            vm.partner_entities = res.data;
            vm.isLoading = false;
          })
          .catch(error => {
            console.log(error)
            vm.isLoading=false;
          })
    },
    saveAssociation(){
      var entity = this.partner_entity;
      let vm = this;
      vm.isLoading=true;
      if (entity.contact_email == ""){
        entity.contact_email = null
      }
      if(vm.actionType == "create"){
        axios
          .post("/projects/"+vm.project.id+"/associate-entity.json", {
            project: {partner_entities_attributes: [entity]},
          })
          .then(res => {
            vm.errores_validacion = {};
            vm.$buefy.dialog.alert({
              message: 'La entidad fue asociada satisfactoriamente.',
              type: 'is-success',
              hasIcon: true,
              icon: 'check-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            ;
            vm.isLoading=false;
            this.$emit('associateEntity', this.partner_entity);
            this.$emit('close');
          }).
          catch(error => {
            vm.errores_validacion = error.response.data
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            vm.isLoading=false;
          })

      }
      else if(vm.actionType == "update"){
        axios
          .put("/projects/"+vm.project.id+"/associate-entity/"+vm.entityId+".json", {
            project: {partner_entities_attributes: [entity]},
          })
          .then(res => {
            vm.errores_validacion = {};
            vm.$buefy.dialog.alert({
              message: 'La entidad fue actualizada satisfactoriamente.',
              type: 'is-success',
              hasIcon: true,
              icon: 'check-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            ;
            vm.isLoading=false;
            vm.$emit('updateEntity');
            vm.$emit('close');
          }).
          catch(error => {
            vm.errores_validacion = error.response.data
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            vm.isLoading=false;
          })

      }



    },
    async getAssociationsTypes(){
      let vm = this;
      vm.isLoading=true;
      axios
        .get("/association-types.json")
        .then(res => {
          vm.associations_types = res.data.associaton_types;
          vm.entity_types = res.data.entity_types;
          vm.isLoading = false;
        })
        .catch(error => {
          console.log(error)
          vm.isLoading=false;
        })

    },
    getEntity(){
      let vm = this;
      vm.isLoading=true;
      axios
        .get("/projects/"+vm.project.id+"/associated-entities/"+vm.entityId+".json")
        .then(res => {
          vm.partner_entities = [res.data.partner_entity];
          vm.partner_entity = res.data.partner_entity;
          vm.entity_type = vm.partner_entity.entity_type
          vm.isLoading = false;
        })
        .catch(error => {
          //console.log(error)
          vm.isLoading=false;
        })
    },
    addEntity(){
      this.isAddModalEntityActive = true;
    },
    onClickChild(){
      this.$buefy.toast.open({
        message: 'La entidad se agregó correctamente',
        type: 'is-success',
        duration: 5000,
      })
    },
  }
}
</script>

<style scoped>

</style>