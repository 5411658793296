<template>
<div>
  <br>
  <section>
    <div class="container">
      <h1 class="is-size-2">Programas de Postgrado</h1>
      <div class="container box">
        <div class="columns">
          <div class="column">
            <b-field label="Departamento" >
              <multiselect v-model="unit"
                           :options="units"
                           placeholder="Seleccione Departamento"
                           label="nombre"
                           track-by="nombre"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="Presione para deseleccionar"
                           @input="getCareers"
                           :allow-empty="false">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                No se encontraron elementos.
              </span>
              </multiselect>

            </b-field>
          </div>
        </div>
        <div class="columns" v-if="unit">
          <div class="column">
            <b-field label="Programas" >
              <multiselect v-model="career"
                           :options="careers"
                           placeholder="Seleccione Carrera"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="Presione para deseleccionar"
                           @input="getCareer"
                           :allow-empty="false">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                No se encontraron elementos.
              </span>
              </multiselect>

            </b-field>
          </div>
        </div>
        <div class="columns" v-if="career">
          <div class="column">
            <b-field label="Versión, Plan y Mención" >
              <multiselect v-model="version"
                           :options="versions"
                           placeholder="Seleccione Versión"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="Presione para deseleccionar"
                           :custom-label="getVersionName"
                           @input="getData"
                           :allow-empty="false">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                No se encontraron elementos.
              </span>
              </multiselect>

            </b-field>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section v-if="version">
    <br>
    <div class="container box">

      <div class="columns has-text-centered">
        <div class="column">
          <h3 class="title is-3">{{ version.codigo }} - {{ version.nombre_carrera}}</h3>
          <h5 class="subtitle is-5">Resolución N° : {{ version.resolucion }} ({{ version.fecha_resolucion }})</h5>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <b-field label="Link de Documentación del Programa">
            <b-input expanded v-model="documentation_link" type="text"></b-input>
            <p class="control">
              <b-button tag="a" :href="documentation_link" target="_blank" icon-left="external-link-alt" type="is-link">Link</b-button>
            </p>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Link de Página del Programa">
            <b-input expanded v-model="page_link" type="text"></b-input>
            <p class="control">
              <b-button tag="a" :href="page_link" target="_blank" icon-left="external-link-alt" type="is-link">Link</b-button>
            </p>
          </b-field>
        </div>
      </div>
      <div class="columns has-text-right">
        <div class="column">
          <b-button @click.prevent="UpdateProgram" type="is-primary"
                    icon-left="save"> Actualizar Links del Programa
          </b-button>
        </div>
      </div>

      <b-tabs position="is-centered" class="block">
        <b-tab-item label="Cuerpo Académico" icon="pencil-ruler">
          <div class="columns has-text-right">
            <div class="column is-4 is-offset-8">
              <b-switch v-model="isActive">Mostrar miembros activos</b-switch>
            </div>
          </div>
          <div class="columns has-text-right">
            <div class="column">
              <b-button @click="AddAcademic" type="is-primary"
                        icon-left="plus"> Agregar Académica/o
              </b-button>
            </div>
          </div>
          <div class="columns">
            <div v-if="isActive" class="column">
              <b-table :data="academics_active"
                       :paginated=true
                       per-page=5
                       striped
                       hoverable
                       :current-page="currentPage"
                       :pagination-simple=false
                       pagination-position="bottom"
                       default-sort-direction="asc"
                       sort-icon="arrow-up"
                       sort-icon-size="is-small"
                       default-sort="publication.title"
                       aria-next-label="Siguiente"
                       aria-previous-label="Anterior"
                       aria-page-label="Página"
                       aria-current-label="Página actual">
                <b-table-column
                    field="person_name"
                    label="Nombre"
                    sortable
                    searchable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.person_name}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="function.name"
                    label="Función"
                    sortable
                    searchable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.function.name}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="grade"
                    label="Grado Mayor"
                    sortable
                    searchable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.grade}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="program_person.documentantion_link"
                    label="Documentación"
                    width="10"
                    v-slot="props">
                  <b-icon v-if="props.row.program_person.documentantion_link!=null &&
                            props.row.program_person.documentantion_link!=undefined &&
                            props.row.program_person.documentantion_link!=''"
                          :style="'cursor:pointer'"
                          pack="fas"
                          size="is-medium"
                          :type="'is-info'"
                          @click.native="goLink(props.row.program_person.documentantion_link)"
                          icon="external-link-alt"></b-icon>
                </b-table-column>
                <b-table-column
                    label="Acciones"
                    width="10"
                    v-slot="props">
                  <b-icon
                      style="cursor:pointer"
                      pack="fas"
                      @click.native="showProgram(props.row)"
                      size="is-medium"
                      type="is-gray"
                      icon="eye"></b-icon>
                  <b-icon
                      style="cursor:pointer"
                      pack="fas"
                      size="is-medium"
                      type="is-success"
                      @click.nart.native="editProgram(props.row)"
                      icon="edit"></b-icon>
                </b-table-column>
              </b-table>
            </div>
            <div v-else class="column">
              <b-table :data="academics_inactive"
                       :paginated=true
                       per-page=5
                       striped
                       hoverable
                       :current-page="currentPage"
                       :pagination-simple=false
                       pagination-position="bottom"
                       default-sort-direction="asc"
                       sort-icon="arrow-up"
                       sort-icon-size="is-small"
                       default-sort="publication.title"
                       aria-next-label="Siguiente"
                       aria-previous-label="Anterior"
                       aria-page-label="Página"
                       aria-current-label="Página actual">
                <b-table-column
                    field="person_name"
                    label="Nombre"
                    sortable
                    searchable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.person_name}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="function.name"
                    label="Función"
                    sortable
                    searchable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.function.name}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="grade"
                    label="Grado Mayor"
                    sortable
                    searchable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.grade}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="program_person.documentantion_link"
                    label="Documentación"
                    width="10"
                    v-slot="props">
                  <b-icon v-if="props.row.program_person.documentantion_link!=null &&
                            props.row.program_person.documentantion_link!=undefined &&
                            props.row.program_person.documentantion_link!=''"
                          :style="'cursor:pointer'"
                          pack="fas"
                          size="is-medium"
                          :type="'is-info'"
                          @click.native="goLink(props.row.program_person.documentantion_link)"
                          icon="external-link-alt"></b-icon>
                </b-table-column>
                <b-table-column
                    label="Acciones"
                    width="10"
                    v-slot="props">
                  <b-icon
                      style="cursor:pointer"
                      pack="fas"
                      @click.native="showProgram(props.row)"
                      size="is-medium"
                      type="is-gray"
                      icon="eye"></b-icon>
                  <b-icon
                      style="cursor:pointer"
                      pack="fas"
                      size="is-medium"
                      type="is-success"
                      @click.nart.native="editProgram(props.row)"
                      icon="edit"></b-icon>
                </b-table-column>
              </b-table>
            </div>
          </div>

        </b-tab-item>
        <b-tab-item label="Cómite del Programa" icon="users">
          <div class="columns has-text-right">
            <div class="column is-4 is-offset-8">
              <b-switch v-model="isActive">Mostrar miembros activos</b-switch>
            </div>
          </div>
          <div class="columns">
            <div v-if="isActive" class="column">
              <b-table :data="comittee_active"
                       :paginated=true
                       per-page=5
                       striped
                       hoverable
                       :current-page="currentPage"
                       :pagination-simple=false
                       pagination-position="bottom"
                       default-sort-direction="asc"
                       sort-icon="arrow-up"
                       sort-icon-size="is-small"
                       default-sort="publication.title"
                       aria-next-label="Siguiente"
                       aria-previous-label="Anterior"
                       aria-page-label="Página"
                       aria-current-label="Página actual">
                <b-table-column
                    field="person_name"
                    label="Nombre"
                    sortable
                    searchable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.person_name}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="program_person"
                    label="Función"
                    sortable
                    searchable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.program_person.is_director ? "Director/a":"Miembro del Cómite"}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="grade"
                    label="Grado Mayor"
                    sortable
                    searchable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.grade}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="program_person.documentantion_link"
                    label="Documentación"
                    width="10"
                    v-slot="props">
                  <b-icon v-if="props.row.program_person.documentantion_link!=null &&
                            props.row.program_person.documentantion_link!=undefined &&
                            props.row.program_person.documentantion_link!=''"
                      :style="'cursor:pointer'"
                      pack="fas"
                      size="is-medium"
                      :type="'is-info'"
                      @click.native="goLink(props.row.program_person.documentantion_link)"
                      icon="external-link-alt"></b-icon>
                </b-table-column>
                <b-table-column
                    label="Acciones"
                    width="10"
                    v-slot="props">
                  <b-icon
                      style="cursor:pointer"
                      pack="fas"
                      @click.native="showProgram(props.row)"
                      size="is-medium"
                      type="is-gray"
                      icon="eye"></b-icon>
                  <b-icon
                      style="cursor:pointer"
                      pack="fas"
                      size="is-medium"
                      type="is-success"
                      @click.nart.native="editProgram(props.row)"
                      icon="edit"></b-icon>
                </b-table-column>
              </b-table>
            </div>

            <div v-else class="column">
              <b-table :data="comittee_inactive"
                       :paginated=true
                       per-page=5
                       striped
                       hoverable
                       :current-page="currentPage"
                       :pagination-simple=false
                       pagination-position="bottom"
                       default-sort-direction="asc"
                       sort-icon="arrow-up"
                       sort-icon-size="is-small"
                       default-sort="publication.title"
                       aria-next-label="Siguiente"
                       aria-previous-label="Anterior"
                       aria-page-label="Página"
                       aria-current-label="Página actual">
                <b-table-column
                    field="person_name"
                    label="Nombre"
                    sortable
                    searchable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.person_name}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="program_person"
                    label="Función"
                    sortable
                    searchable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.program_person.is_director ? "Director/a":"Miembro del Cómite"}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="grade"
                    label="Grado Mayor"
                    sortable
                    searchable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.grade}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="program_person.documentantion_link"
                    label="Documentación"
                    width="10"
                    v-slot="props">
                  <b-icon v-if="props.row.program_person.documentantion_link!=null &&
                            props.row.program_person.documentantion_link!=undefined &&
                            props.row.program_person.documentantion_link!=''"
                          :style="'cursor:pointer'"
                          pack="fas"
                          size="is-medium"
                          :type="'is-info'"
                          @click.native="goLink(props.row.program_person.documentantion_link)"
                          icon="external-link-alt"></b-icon>
                </b-table-column>
                <b-table-column
                    label="Acciones"
                    width="10"
                    v-slot="props">
                  <b-icon
                      style="cursor:pointer"
                      pack="fas"
                      @click.native="showProgram(props.row)"
                      size="is-medium"
                      type="is-gray"
                      icon="eye"></b-icon>
                  <b-icon
                      style="cursor:pointer"
                      pack="fas"
                      size="is-medium"
                      type="is-success"
                      @click.nart.native="editProgram(props.row)"
                      icon="edit"></b-icon>
                </b-table-column>
              </b-table>
            </div>
          </div>

        </b-tab-item>
      </b-tabs>

    </div>
  </section>

  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  <b-modal
      width="90%"
      v-model="modalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :can-cancel="canCancel">
    <template #default="props">
      <add-academic
          :query-type="queryType"
          :modal-header="modalHeader"
          :program_person_selected="program_person_selected"
          :inventores="academic_body"
          :code_program_career="version.codigo"
          :resolution_number="version.resolucion"
          :mention="version.mencion"
          :version="version.version_plan.toString() +' - '+version.plan.toString()"
          :comittee_active="comittee_active"
          @clicked="onClickChild"
          @close="props.close"></add-academic>
    </template>
  </b-modal>
  <b-modal
      width="90%"
      v-model="showModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :can-cancel="canCancel">
    <template #default="props">
      <show-academic
          modal-header="Datos del académico"
          :program_person="program_person_selected"
          :code_program_career="version.codigo"
          :resolution_number="version.resolucion"
          :mention="version.mencion"
          :version="version.version_plan.toString() +' - '+version.plan.toString()"
          @close="props.close">

      </show-academic>
    </template>
  </b-modal>


</div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import AddAcademic from "./AddAcademic";
import ShowAcademic from "./ShowAcademic";
import moment from "moment";

export default {
  name: "PostgraduateSubjectsSearch",
  props: ['units','can_create'],
  components: {AddAcademic,ShowAcademic},
  data() {
    return {
      unit:null,
      careers:[],
      career:null,
      version:null,
      version_plan:null,
      all_careers:[],
      versions:[],
      isLoading:false,
      modalActive:false,
      canCancel: ['escape'],
      academic_body:[],
      program_comittee:[],
      currentPage:1,
      codigo: null,
      mention:null,
      resolution_number:null,
      program_person_selected:null,
      showModalActive:false,
      start_date: null,
      end_date: null,
      queryType:'create',
      modalHeader:'Añadir académicas/os',
      program:null,
      documentation_link:null,
      page_link:null,
      isActive:true,
      comittee_active:[],
      academics_active:[],
      comittee_inactive:[],
      academics_inactive:[],
    }
  },
  methods:{
    getCareers(){
      let vm = this;
      vm.isLoading=true;
      vm.all_careers = [];
      vm.careers = [];
      vm.career = null;
      vm.version = null;
      vm.version_plan = null;
      vm.codigo = null;
      vm.mention = null;
      vm.resolution_number = null
      return axios
        .get("siac/units/"+vm.unit.codigo+"/careers.json")
        .then(res => {
          vm.all_careers = res.data;
          const unique = [...new Set(vm.all_careers.map(obj => obj.nombre_carrera))].filter(name => name.includes("MAGISTER") || name.includes("DOCTORADO"));
          vm.careers = unique;
          vm.isLoading=false;
        })
        .catch(e => {
          console.log(e);
          vm.isLoading=false;
        });
    },
    getCareer(){
      let vm = this;
      vm.isLoading = true;
      vm.version = null;
      vm.versions = _.cloneDeep(vm.all_careers).filter(obj => obj.nombre_carrera == vm.career).sort((a,b)=>(b.plan+b.version_plan)-(a.plan+a.version_plan));
      vm.isLoading = false;
      vm.codigo = null;
      vm.mention = null;
      vm.resolution_number = null
      vm.version_plan = null;

    },
    getVersionName({ codigo, version_plan, plan, mencion, resolucion, fecha_resolucion }) {
      this.codigo = {codigo}
      this.mention = {mencion}
      this.version_plan = `${version_plan} - ${plan}`
      this.resolution_number = {resolucion}
      return `Código : ${codigo}  [${version_plan} - ${plan}] Mención: ${mencion}, Resolución N° : ${resolucion} (${fecha_resolucion})`
    },
    getData(){
      let vm = this;
      vm.isLoading = true
      vm.academic_body = []
      vm.program_comittee = []
      vm.academics_active = []
      vm.academics_inactive = []
      vm.comittee_active = []
      vm.comittee_inactive = []
      vm.documentation_link = null
      vm.page_link = null
      return axios
          .get("/program_careers/code/"+vm.version.codigo+"/"+vm.version.version_plan.toString() +' - '+vm.version.plan.toString()+".json" )
          .then(res =>{
            vm.program = res.data
            vm.documentation_link = res.data['documentation_link']
            vm.page_link = res.data['page_link']
            vm.academic_body = res.data['academic_body']
            var temp_academics_active = []
            var temp_academics_inactive = []
            var temp_comittee_active = []
            var temp_comittee_inactive = []

            if (vm.academic_body != null && vm.academic_body != undefined && vm.academic_body.length > 0){
              vm.academic_body.forEach(function (academic){
                academic.program_person.show_start_date = null
                academic.program_person.show_end_date = null
                if(academic.program_person.is_director == null){
                  academic.program_person.is_director = false
                }

                if(academic.program_person.is_comittee_member == null){
                  academic.program_person.is_comittee_member = false
                }

                if (academic.program_person.start_date !== null) {
                  academic.program_person.show_start_date = moment(academic.program_person.start_date, 'YYYY-MM-DD')._d;
                  academic.program_person.show_start_date = new Date(academic.program_person.show_start_date).toLocaleDateString("es-CL")
                }

                if (academic.program_person.end_date !== null) {
                  academic.program_person.show_end_date = moment(academic.program_person.end_date, 'YYYY-MM-DD')._d;
                  academic.program_person.show_end_date = new Date(academic.program_person.show_end_date).toLocaleDateString("es-CL")
                }
                if (academic.is_active){
                  temp_academics_active.push(academic)
                }
                else{
                  temp_academics_inactive.push(academic)
                }

              })
              vm.academics_active = temp_academics_active
              vm.academics_inactive = temp_academics_inactive
            }
            else{
              vm.academic_body = []
            }
            vm.program_comittee = res.data['program_comittee']
            if (vm.program_comittee != null && vm.program_comittee != undefined && vm.program_comittee.length > 0){
              vm.program_comittee.forEach(function (academic){

                if(academic.program_person.is_director == null){
                  academic.program_person.is_director = false
                }

                if(academic.program_person.is_comittee_member == null){
                  academic.program_person.is_comittee_member = false
                }

                if (academic.program_person.start_date !== null) {
                  academic.program_person.show_start_date = moment(academic.program_person.start_date, 'YYYY-MM-DD')._d;
                  academic.program_person.show_start_date = new Date(academic.program_person.show_start_date).toLocaleDateString("es-CL")
                }

                if (academic.program_person.end_date !== null) {
                  academic.program_person.show_end_date = moment(academic.program_person.end_date, 'YYYY-MM-DD')._d;
                  academic.program_person.show_end_date = new Date(academic.program_person.show_end_date).toLocaleDateString("es-CL")
                }

                if (academic.is_active){
                  temp_comittee_active.push(academic)
                }
                else{
                  temp_comittee_inactive.push(academic)
                }

              })
              vm.comittee_active = temp_comittee_active
              vm.comittee_inactive = temp_comittee_inactive
            }
            else{
              vm.program_comittee = []
            }






            vm.isLoading = false
          })
          .catch(e =>{
            console.log(e)
            vm.isLoading = false
          })
    },
    AddAcademic(){
      this.queryType = 'create'
      this.modalHeader = 'Añadir académicas/os'
      if (this.academic_body != null && this.academic_body != undefined && this.academic_body.length > 0){
        this.program_person_selected = this.academic_body[0]
      }
      else{
        this.program_person_selected = []
      }

      this.modalActive = true;
    },
    goLink(url){
      if (url != null  && url != undefined){
        window.open(url)
      }

    },
    showProgram(row){
      this.program_person_selected = row
      this.showModalActive = true
    },
    editProgram(row){
      this.queryType = 'update'
      this.modalHeader = 'Editar académico'
      this.program_person_selected = row
      this.modalActive = true
    },
    onClickChild(){
      this.getData()
    },
    UpdateProgram(){
      let vm = this;
      vm.isLoading = true
      if(vm.program != null){
        return axios
            .put("/program_careers/"+vm.program.id+".json",{
              program_career:{
                documentation_link: vm.documentation_link,
                page_link: vm.page_link
              }
            })
            .then(res => {
              this.$buefy.dialog.alert({
                message: 'Links del programa fueron actualizados correctamentes',
                type: 'is-success',
                hasIcon: true,
                icon: 'check-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              this.isLoading = false
            })
            .catch(error => {
              this.$buefy.dialog.alert({
                message: 'Hubo un error al actualizar los links del programa.',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              console.log(error)
              this.isLoading = false
            })
      }
      else{
        return axios
            .post("/program_careers.json",{
              program_career:{
                code: vm.version.codigo,
                resolution_number:vm.version.resolucion,
                mention:vm.version.mencion,
                version:vm.version.version_plan.toString() +' - '+vm.version.plan.toString(),
                documentation_link: vm.documentation_link,
                page_link: vm.page_link
              }
            })
            .then(res =>{
              this.$buefy.dialog.alert({
                message: 'Links del programa fueron actualizados correctamentes',
                type: 'is-success',
                hasIcon: true,
                icon: 'check-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              this.isLoading = false
            })
            .catch(error => {
              this.$buefy.dialog.alert({
                message: 'Hubo un error al actualizar los links del programa.',
                type: 'is-success',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              console.log(error)
              this.isLoading = false
            })
      }

    }
  },
  watch: {
    showModalActive: function () {
      if (!this.showModalActive) {
        this.getData()
      }
    },
  }
}
</script>

<style scoped>

</style>