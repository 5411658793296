<template>
<div ref="publications_tutorial">
  <div class="columns is-multiline">
    <div class="column is-3 py-0">
      <b-sidebar position="static" type="is-light" open>
        <div class="p-1 pt-4">
          <div class="block mt-5">
            <h1 class="subtitle is-3 has-text-primary has-text-left">Índice</h1>
          </div>
          <b-menu class="is-custom-mobile">
            <b-menu-list>
              <b-menu-item :active="tutorial === 'create_request' ? true : false" @click="tutorial = 'create_request'" label="1. ¿Cómo crear una publicación?"></b-menu-item>
              <b-menu-item :active="tutorial === 'view_own_request' ? true : false" @click="tutorial = 'view_own_request'" label="2. ¿Cómo listar publicaciones ya creadas?"></b-menu-item>
              <b-menu-item :active="tutorial === 'ocde_tutorial' ? true : false" @click="tutorial = 'ocde_tutorial'" label="3. ¿Como añadir sub areas ocde a la Publicación?"></b-menu-item>
              <b-menu-item :active="tutorial === 'cites_tutorial' ? true : false" @click="tutorial = 'cites_tutorial'" label="4. ¿Como añadir conteo de citas a la Publicación?"></b-menu-item>
              <b-menu-item :active="tutorial === 'bibtex_tutorial' ? true : false" @click="tutorial = 'bibtex_tutorial'" label="5. Rellenar campos por Bibtex o DOI"></b-menu-item>
              <b-menu-item :active="tutorial === 'magazine_type_tutorial' ? true : false" @click="tutorial = 'magazine_type_tutorial'" label="7. Agregar nuevo lugar de publicación"></b-menu-item>


            </b-menu-list>
          </b-menu>
        </div>
      </b-sidebar>
      <div class="buttons-sidebar">
        <b-button class="icon-up" icon-left="arrow-circle-up" type="is-ghost" @click="goUp">
          Volver arriba
        </b-button>
      </div>
    </div>
    <div v-if="tutorial === 'create_request'" class="column is-9">
      <h1 class="subtitle is-2 has-text-primary">
        Creación de una Publicación
      </h1>
      <div class="mb-3 ">
        <p>SIGA permite la creación de publicaciones de académicas/os. Para poder ingresar una publicación al sistema, usted
          deberá proporcionar en una primera etapa, datos génericos y participantes asociados a esta. Posterior a esta creación,
          se podrán asociar sub areas ocde y conteo de citas, en caso de contar con esta información.</p>
      </div>
      <h2 class="subtitle is-3 mt-5 mb-2">
        Paso 1
      </h2>
      <p class="my-3 ">Seleccionar la opción <a href="/publications/new" target="_blank"><b-icon pack="fas" icon="plus"></b-icon>Añadir Publicación</a>, la cual se puede seleccionar de dos formas: desde la barra lateral o desde
        <a :href="'/people/'+person.id" target="_blank"><b-icon pack="fas" icon="book"></b-icon>Mi Actividad Acádemica</a>.
      </p>
      <ul>
        <li>
          <h5 class="subtitle is-5">1. Desde la barra lateral:</h5>
          <ul class="ml-4">
            <li>1.1 Abrir la barra lateral</li>
            <li>1.2 Seleccionar "Actividad Académica"</li>
            <li>1.3 Seleccionar "Añadir Actividad"</li>
            <li>1.4 Seleccionar "Publicaciones"</li>
          </ul>
        </li>

        <img border="2" class="m-2" src="tutorial_images/add_academic_activity.png" style="width:35%">

        <li>
          <h5 class="subtitle is-5">2. Desde <a :href="'/people/'+person.id" target="_blank"><b-icon pack="fas" icon="book"></b-icon>Mi Actividad Acádemica</a>:</h5>
          <ul>
            <li>2.1 Desplegar el menú de usuaria/o en la esquina superior derecha del sistema</li>
            <img border="2" class="m-2" src="tutorial_images/my_profile_menu.png" style="width:40%">
            <li>2.2 <a :href="'/people/'+person.id" target="_blank"><b-icon pack="fas" icon="book"></b-icon>Seleccionar "Mi Actividad Acádemica"</a></li>
            <li>2.3 En la vista de <a :href="'/people/'+person.id" target="_blank"><b-icon pack="fas" icon="book"></b-icon>Mi Actividad Acádemica</a>, apretar el botón "Añadir Actividad Académica"</li>
            <li>2.4 Seleccionar Publicaciones</li>
          </ul>
        </li>

        <img border="2" class="m-2" src="tutorial_images/my_academic_activity_add_aa_button.png">

      </ul>

      <h2 class="subtitle is-3 mt-5 mb-2">
        Paso 2
      </h2>
      <p class="my-3 ">Rellenar el formulario para agregar una <a :href="'/publications/new'" target="_blank">Nueva Publicación</a>. <br> En este paso se debe rellenar la información
        solicitada en el formulario de publicaciones, tanto en su pestaña "General" como en la pestaña "Participantes.
      </p>
      <ul>
        <li>
          <h5 class="subtitle is-5">1. Rellenar pestaña General:</h5>
          <ul class="ml-4">
            <li>1.1 Rellenar los datos del formulario de la pestaña General. Los ítems marcados con un asterisco(*) son de caracter obligatorio</li>
            <li>IMPORTANTE: En el campo autores de la publicación, debe ingresar el nombre o alias de las personas en el orden en el cuál deseen ser visualizadas.</li>
            <li><img border="2" class="m-2" src="tutorial_images/publication_form_general.png"></li>
          </ul>
        </li>

        <li>
          <h5 class="subtitle is-5">2. Rellenar pestaña Participantes:</h5>
          <ul class="ml-4">
            <li>2.1 Ir a la pestaña participantes apretando en la pestaña Participantes del formulario, o, desplazandose
              con las flechas en la parte inferior del formulario.</li>
            <li><img border="2" class="m-2" src="tutorial_images/general_vs_participants_tab.png"></li>
            <li>2.2 Presionar el botón "Administrar Participantes". Con esta acción se desplegará un modal que permitirá
              buscar y añadir participantes a la publicación. <br>*La funcionalidad de "Administrar Participantes", funciona por igual para toda
              la actividad académica.</li>
            <li><img border="2" class="m-2" src="tutorial_images/publications_participants.png"></li>
            <li><img border="2" class="m-2" src="tutorial_images/participants_form.png"></li>
            <li>2.3 Ingresar un nombre para buscar personas registradas dentro del sistema.</li>
            <li>2.4 Presionar el botón "Buscar". Si la búsqueda arroja resultados, estos se verán en la "Tabla Búsqueda", en donde
              podrá seleccionar las personas que quiera añadir al proyecto, marcandolas con el ticket del sector izquierdo.
              Su selección quedará reflejada en la "Tabla Resumen Participantes"</li>
            <li><img border="2" class="m-2" src="tutorial_images/participants_form_with_search.png"></li>
            <li>2.5 En caso de no encontrar la persona buscada, tendrá la opcion de añadirla como participante externo,
              completando un formulario anexo al cual se puede acceder apretando el botón + que se aprecia al sector derecho
              del modal. Se puede encontrar la explicación de su uso en la sección de Participantes Externos.</li>
            <li>2.6 (Opcional) Si quiere añadir más de un/a participante, puede volver a realizar la búsqueda y seleccionar otras personas
              sin necesidad de cerrar el modal.</li>
            <li>2.6 Presionar Guardar del modal de "Añadir Participantes", con lo cual se volverá al formulario de Nueva Publicación con los datos de participantes seleccionados.
              PRECAUCIÓN: esta acción no guardará los participantes ni la publicación en el sistema hasta que la publicación sea guardada.</li>
            <li>2.7 Para cada participante añadida/o se deberá asignar un Tipo de Participanción dentro de la publicación
              (de manera predeterminada se encuentra seleccionado el Tipo de Participación "CO-AUTOR"),
              su Filiación  al momento de publicarse, dónde existe la posiblidad de añadir a la Universidad de Santiago de Chile de manera instantanea
              presionando el botón "+USACH",
              y también se debe marcar si es estudiante de doctorado al momento de publicarse.</li>
            <li>2.8 De manera obligatoria, debe seleccionar un/a dueño/a de la publicación de entre los/as participantes añadidos/as previamente. Esto para reconocer quien tendrá todos los permisos de edición sobre esta actividad académica.</li>
            <li><img border="2" class="m-2" src="tutorial_images/publications_participants_fullfilled.png"></li>


          </ul>
        </li>

      </ul>


      <h2 class="subtitle is-3 mt-5 mb-2">
        Paso 3
      </h2>
      <p class="my-3 ">Guardar la publicación
      </p>
      <ul>
        <li>3.1 Una vez llenados los datos obligatorios tanto como de la pestaña General y Participantes, debe presionar el botón "Guardar Publicación" del formulario de Nueva Publicación.</li>
        <li>3.2 Si el sistema arroja errores, deben ser resueltos para poder guardar la publicación.</li>
      </ul>


    </div>

    <div v-if="tutorial === 'view_own_request'" class="column is-9">
      <h1 class="subtitle is-2 has-text-primary">
        Listar publicaciones ya creadas en las cuales participo.
      </h1>
      <div class="mb-3 ">
        <p>SIGA permite listar las publicaciones en donde se le incluye como participante.</p>
      </div>
      <h3 class="subtitle is-4">Paso 1</h3>
      <h5 class="subtitle is-5">1. Ir a <a :href="'/people/'+person.id" target="_blank"><b-icon pack="fas" icon="book"></b-icon>Mi Actividad Acádemica</a>:</h5>
      <ul>
        <li>1.1 Desplegar el menú de usuaria/o en la esquina superior derecha del sistema</li>
        <img border="2" class="m-2" src="tutorial_images/my_profile_menu.png" style="width:40%">
        <li>1.2 Seleccionar <a :href="'/people/'+person.id" target="_blank"><b-icon pack="fas" icon="book"></b-icon>"Mi Actividad Acádemica"</a></li>
        <li>1.3 Seleccionar la pestaña de Publicaciones.</li>
        <li>(Opcional) Aplicar filtros de años o quitarlos para buscar más datos de publicaciones en las cuales esté listado como participante.</li>
        <li>*Apretar sobre el nombre de una publicación lo redirigirá a toda la información existente de esta en el sistema.</li>
        <li><img border="2" class="m-2" src="tutorial_images/my_publications.png"></li>
      </ul>
    </div>

    <div v-if="tutorial === 'ocde_tutorial'" class="column is-9">
      <h1 class="subtitle is-2 has-text-primary">
        Añadir sub áreas y disciplinas OCDE a una publicación.
      </h1>
      <div class="mb-3 ">
        <p>SIGA permite añadir sub áreas y disciplinas OCDE a una publicación ya creada.</p>
      </div>
      <h3 class="subtitle is-4">Paso 1</h3>
      <ul>
        <li>1.1 Ingresar a una publicación ya existente en el sistema.</li>
        <li><img border="2" class="m-2" src="tutorial_images/publication.png"></li>
        <li>1.2 Seleccionar la pestaña de Sub Áreas OCDE</li>
        <li><img border="2" class="m-2" src="tutorial_images/publication_ocde.png"></li>
        <li>1.3 Apretar el botón "Editar Sub Áreas OCDE", lo que habilitará los campos de edición.</li>
        <li>1.4 Rellenar el campo "Sub Áreas Ocde". Mientras vaya escribiendo aparecerán sugerencias de las cuales podrá seleccionar.
        Se puede seleccionar más de una Sub Área Ocde para la publicación.</li>
        <li>1.5 Rellenar el campo "Disciplinas". En base a los campos seleccionados en "Sub Áreas OCDE", mientras escriba aparecerán sugerencias
        de disciplinas asociadas a las sub áreas de las cuales podrá seleccionar. Se puede seleccionar más de una disciplina por publicación.</li>
        <li><img border="2" class="m-2" src="tutorial_images/publication_ocde_fullfilled.png"></li>
        <li>1.6 Presionar el botón "Guardar cambios".</li>
      </ul>
    </div>

    <div v-if="tutorial === 'cites_tutorial'" class="column is-9">
      <h1 class="subtitle is-2 has-text-primary">
        Añadir conteo de citas a una publicación.
      </h1>
      <div class="mb-3 ">
        <p>SIGA permite añadir conteo de citas por año a una publicación ya creada.</p>
      </div>
      <h3 class="subtitle is-4">Paso 1</h3>
      <ul>
        <li>1.1 Ingresar a una publicación ya existente en el sistema.</li>
        <li><img border="2" class="m-2" src="tutorial_images/publication.png"></li>
        <li>1.2 Seleccionar la pestaña de Citas</li>
        <li><img border="2" class="m-2" src="tutorial_images/publication_cites.png"></li>
        <li>1.3 Presionar el botón "Añadir conteo de citas" que abrirá el formulario.</li>
        <li>1.4 Rellenar los campos obligatorios del formulario de "Añadir conteo de citas".</li>
        <li><img border="2" class="m-2" src="tutorial_images/publication_cites_form.png"></li>
        <li>
          1.5 Presionar el botón "Guardar cita".
          <br>PRECAUCIÓN: si el año ingresado ya existe en la publicación, el conteo de este año será reemplazado por el valor ingresado en el formulario.
        </li>
        <li>1.6 Cualquier error reportado por el sistema debe ser solucionado para guardar el conteo. </li>
        <li>1.7 Si no hay errores, se guardará inmediatamente el conteo de citas de la publicación, quedando reflejado
        en la tabla de Citas.</li>
        <li><img border="2" class="m-2" src="tutorial_images/publication_cites_fullfilled.png"></li>

      </ul>
    </div>

    <div v-if="tutorial === 'bibtex_tutorial'" class="column is-9">
      <h1 class="subtitle is-2 has-text-primary">
        Rellenar datos de una publicación por bibtex o DOI.
      </h1>
      <div class="mb-3 ">
        <p>SIGA permite rellenar los datos del formulario de una publicación a través de un bibtex o por una búsqueda de su DOI.</p>
      </div>
      <br>
      <h3 class="subtitle is-4">1. Rellenar datos con Bibtex.</h3>
      <h3 class="subtitle is-5">Pasos a seguir.</h3>
      <ul>
        <li>1.1 Ingresar al formulario de <a href="/publications/new" target="_blank">"Nueva Publicación"</a></li>
        <li>1.2 Presionar el botón "Rellenar datos con bibtex", lo que abrirá un formulario de ingreso de bibtex.</li>
        <li><img border="2" class="m-2" src="tutorial_images/publication_bibtex_doi.png"></li>
        <li>1.3 Ingresar el bibtex que posee.</li>
        <li><img border="2" class="m-2" src="tutorial_images/publication_bibtex.png"></li>
        <li>1.4 Presionar el botón "Rellenar Datos". <br>PRECAUCIÓN: Si esta acción tiene éxito, sobreescribirá los datos que hayan sido llenados previamente en el formulario.</li>
        <li>1.5 IMPORTANTE: Rellenar los datos faltantes y obligatorios, tanto en la pestaña General como en la de Participantes,
          ya que no siempre se puede obtener toda la información requerida por el formulario.</li>
        <li><img border="2" class="m-2" src="tutorial_images/publication_bibtex_fullfilled.png"></li>
      </ul>

      <h3 class="subtitle is-4">2. Rellenar datos con DOI.</h3>
      <h3 class="subtitle is-5">Pasos a seguir.</h3>
      <ul>
        <li>2.1 Ingresar al formulario de <a href="/publications/new" target="_blank">"Nueva Publicación"</a></li>
        <li>2.2 Presionar el botón "Rellenar datos con DOI", lo que abrirá un formulario de ingreso del DOI.</li>
        <li><img border="2" class="m-2" src="tutorial_images/publication_bibtex_doi.png"></li>
        <li>2.3 Ingresar el DOI a buscar.</li>
        <li><img border="2" class="m-2" src="tutorial_images/publication_doi.png"></li>
        <li>2.4 Presionar el botón "Rellenar Datos".
          <br>ADVERTENCIA: Esta búsqueda se conecta a una API externa, por lo la respuesta dependerá de la disponibilidad y existencia de los datos.
          <br>PRECAUCIÓN: Si esta acción tiene éxito, sobreescribirá los datos que hayan sido llenados previamente en el formulario.
        </li>
        <li>2.5 IMPORTANTE: Rellenar los datos faltantes y obligatorios, tanto en la pestaña General como en la de Participantes,
          ya que no siempre se puede obtener toda la información requerida por el formulario.</li>
        <li><img border="2" class="m-2" src="tutorial_images/publication_doi_fullfilled.png"></li>
      </ul>

    </div>

    <div v-if="tutorial === 'magazine_type_tutorial'" class="column is-9">
      <h1 class="subtitle is-2 has-text-primary">
        Agregar un nuevo lugar de publicación.
      </h1>
      <div class="mb-3 ">
        <p>SIGA permite agregar un nuevo Lugar de Publicación en caso de que este no sea encontrado en el sistema.</p>
      </div>
      <br>
      <ul>
        <li>1.1 Estar en el formulario de Nueva Publicación o Editar Publicación</li>
        <li><img border="2" class="m-2" src="tutorial_images/publication_form_general.png"></li>
        <li>1.2 Ubicar el campo "Nombre Lugar de Publicación (revista ,conferencia, etc...)"</li>
        <li><img border="2" class="m-2" src="tutorial_images/publication_new_magazine.png"></li>
        <li>1.3 Presionar el botón "+" ubicado al lado derecho del campo "Nombre Lugar de Publicación", lo que desplegará un nuevo formulario.</li>
        <li><img border="2" class="m-2" src="tutorial_images/publication_new_magazine_form.png"></li>
        <li>1.4 Rellenar los datos obligatorios del formulario.</li>
        <li>1.5 Presionar el botón "Guardar".</li>
        <li>1.6 Si el sistema reporta errores, deben ser arreglados para añadir el nuevo Lugar de Publicación.</li>
        <li>1.7 Si el sistema no reporta errores, el Lugar de Publicación será guardado en el sismema y será añadido automáticamente al
        campo de "Lugar de Publicación" del formulario "Nueva Publicación".</li>
      </ul>
    </div>

  </div>
</div>
</template>

<script>
export default {
  name: "publicationsTutorial.vue",
  props:["person"],
  data() {
    return {
      tutorial:"create_request",
    }
  },
  methods:{
    goUp(){
      var elmnt = document.getElementById("app");
      elmnt.scrollIntoView();
      this.$refs.publications_tutorial.scrollTop = 0;
    }
  },
}
</script>

<style scoped>
.b-sidebar .sidebar-content.is-absolute,
.b-sidebar .sidebar-content.is-static {
  width: 100%;
  background-color: white;
  box-shadow: none;
}
</style>