<template>
    <div class="vimeactivityadvancedsearch">
        <form action="">
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head" style="background-color: #8c67ef; text-align:center;">
                    <p class="modal-card-title" style="color:#fff;">Búsqueda avanzada</p>
                    <button
                        type="button"
                        class="delete"
                        @click="$emit('close')"/>
                </header>
                <section class="modal-card-body">
                    <div class="columns">
                        <div class="column">
                            <b-field horizontal label="Año"
                                :type="{ 'is-danger': checkSelectedYear }"
                                :message="{ 'Seleccione un año.': checkSelectedYear }">
                                <b-select v-model='selectedYear'>
                                    <option
                                        v-for="(year, index) in years"
                                        :value="year"
                                        :key="index">
                                    {{ year }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field horizontal label="Mes"
                                :type="{ 'is-danger': checkSelectedMonth }"
                                :message="{ 'Seleccione un mes.': checkSelectedMonth }">
                                <b-select v-model="selectedMonth">
                                    <option
                                        v-for="(month, index) in months"
                                        :value="month"
                                        :key="index">
                                    {{ month }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <div class="buttons is-right">
                        <b-button
                        label="Cerrar"
                        @click="$emit('close')" />
                        <b-button
                            label="Buscar"
                            type="is-primary"
                            @click="searchActivities()"/>
                    </div>
                </footer>
            </div>
        </form>
    </div>
</template>
<script>
export default {
    name: 'VimeActivityAdvancedSearch',
    data(){
        return {
            years:[],
            months: ['Enero', 'Febrero', 'Marzo', 'Abril', 
                     'Mayo', 'Junio', 'Julio', 'Agosto', 
                     'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            filters: { 
                year: null,
                month: '',
            },
            validations:{
                isYearEmpty: true,
                isMonthEmpty: true,
                firstTimeYear: true,
                firstTimeMonth: true
            }
        }
    },
    computed:{
        selectedYear:{
            get: function(){
                return this.filters.year
            },
            set: function(newYear){
                this.validations.firstTimeYear = false
                if(newYear === null)
                    this.validations.isYearEmpty = true
                else
                    this.validations.isYearEmpty = false
                this.filters.year = newYear
            }   
        },
        selectedMonth: {
            get: function(){
                return this.filters.month
            },
            set: function(newMonth){
                this.validations.firstTimeMonth = false
                if(newMonth === '')
                    this.validations.isMonthEmpty = true
                else
                    this.validations.isMonthEmpty = false
                this.filters.month = newMonth
            }
        },
        checkSelectedYear(){
            if(this.validations.firstTimeYear === false)
                return this.validations.isYearEmpty
            else
                return false
        },
        checkSelectedMonth(){
            if(this.validations.firstTimeMonth === false)
                return this.validations.isMonthEmpty
            else
                return false
        }
    },
    beforeMount(){
        this.createYears()
    },
    methods:{
        createYears(){
            var actualDate = new Date()
            var actualYear = actualDate.getFullYear()
            for(var i=1980; i<=parseInt(actualYear); i++){
                this.years.push(i)
            }
        },
        checkEmptyFields(){
            if(this.validations.firstTimeYear === true){
                this.validations.firstTimeYear = false
            }
            if(this.validations.firstTimeMonth === true){
                this.validations.firstTimeMonth = false
            }
        },
        checkSearch(){
            var confirmation = true
            var arrayConfirmation = []
            arrayConfirmation.push(this.validations.isYearEmpty)
            arrayConfirmation.push(this.validations.isMonthEmpty)
            arrayConfirmation.forEach(function(element){
                if(element === true)
                    confirmation = false
            })
            return confirmation
        },
        searchActivities(){
            var confirmaton = this.checkSearch()
            if(confirmaton){
                this.filters.month = this.months.indexOf(this.filters.month)+1
                this.$emit('advanced-search-activity-filters', this.filters)
                this.$emit('close')
            }
            else{
                this.checkEmptyFields()
            }
        },
    }
}
</script>