<template>
  <div class="container">
    <br>
    <p class="title is-4 is-centered">
      Administración de Permisos
    </p>
    <br>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(searchPermissions)">

        <div class="box">
          <div class="columns is-centered has-text-centered">
            <div class="column is-4">
              <b-field label="Cargos*">
                <ValidationProvider rules="required" v-slot="{errors}">
                  <multiselect v-model="internalPosition"
                               :options="internalPositions"
                               :custom-label="opt => internalPositions.find(x => x.id == opt.id).name"
                               placeholder="Seleccione cargo"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                  </multiselect>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Tablas*">
                <ValidationProvider rules="required" v-slot="{errors}">
                  <multiselect v-model="table"
                               :options="tables"
                               :custom-label="opt => tables.find(x => x.id == opt.id).subject_class"
                               placeholder="Seleccione tabla"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                  </multiselect>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Unidades">
                <multiselect v-model="unit"
                             :options="units"
                             :custom-label="opt => units.find(x => x.id == opt.id).name"
                             placeholder="Seleccione unidad"
                             selectLabel="Presione para seleccionar"
                             selectedLabel="Seleccionado">
                  <template v-slot:noOptions>
                    No existen datos
                  </template>
                </multiselect>
              </b-field>
            </div>
          </div>
          <div class="has-text-right">
            <b-button native-type="submit" type="is-primary">Buscar permisos</b-button>
          </div>
        </div>

      </form>
    </ValidationObserver>


    <br>

    <div class="box" v-if="hasSearch">
      <template v-if="permissionsArray.length>0">
        <div class="columns" v-for="permissionArray in permissionsArray">
          <div class="column" v-for="i in 3">
            <b-switch v-if="permissionArray[i-1]"
                      v-model="permissionArray[i-1].state"
                      :rounded="false"
                      type="is-success" passive-type="is-danger">{{ permissionArray[i-1].action }}</b-switch>
          </div>
        </div>
        <div class="has-text-right">
          <b-button @click="savePermissions" type="is-warning">Guardar permisos</b-button>
        </div>
      </template>
      <template v-else>
        <div class="colums has-text-centered">
          <div class="column">
            <h2>No existen datos</h2>
          </div>
        </div>
      </template>

    </div>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>

</template>

<script>
import axios from "axios";
import { ToastProgrammatic as Toast } from 'buefy'
export default {
  name: "permissions_manager",
  data(){
    return{
      internalPositions:[],
      tables:[],
      units:[],
      internalPosition:null,
      table:null,
      unit:null,
      isLoading:false,
      grantedPermissions:[],
      hasSearch:false,

    }
  },
  created(){
    let vm = this;
    vm.isLoading=true;
    return axios.get("/permissions.json")
      .then(res => {
        vm.internalPositions = res.data.internalPositions;
        vm.tables = res.data.tables;
        vm.units = res.data.units;
        vm.isLoading=false;
      })
      .catch(e => {
        vm.isLoading=false;
        console.log(e);
      });

  },
  props:[

  ],
  methods:{
    searchPermissions(){
      let vm = this;
      vm.isLoading=true;
      let data = {};
      data.internal_position_id=vm.internalPosition.id;
      if (vm.table)
        data.table_id = vm.table.id;
      if (vm.unit)
        data.unit_id = vm.unit.id;
      return axios.get("/get_by_internal_position.json",{params:data})
        .then(res => {
          vm.grantedPermissions = res.data.granted_permissions;
          vm.hasSearch = true;
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          Toast.open({message:'Hubo un error al realizar la solicitud',type:'is-danger',position:"is-top-right"})
          console.log(e);
        });
    },
    savePermissions(){
      let vm = this;
      vm.isLoading = true;
      let data = {};
      data.cargo=vm.internalPosition.id;
      data.permisos=vm.grantedPermissions;
      if (vm.table)
        data.tabla = vm.table.id;
      if (vm.unit)
        data.unit_id = vm.unit.id;
      axios
        .post("/set_internal_position_permissions",data)
        .then(response =>{
          vm.isLoading=false;
          Toast.open({message:'Guardado exitoso',type:'is-success',position:"is-top-right"})
        }).catch(error => {
          vm.isLoading=false;
          Toast.open({message:'Hubo un error al realizar la solicitud',type:'is-danger',position:"is-top-right"})
        })
    }

  },
  computed:{
    permissionsArray(){
      let retorno = [];
      for (let i = 0; i < this.grantedPermissions.length; i += 4)
        retorno.push(this.grantedPermissions.slice(i, i + 3));
      return retorno;
    }

  }
}
</script>

<style scoped>

</style>