<template>
  <section>
      <div class="container">
          <div class="columns">
              <div class="column">
                  <div class="control">
                      <h1 class="title">Solicitudes Masivas</h1>
                  </div>
              </div>
          </div>
          <b-message title="Importante" type="is-info" has-icon aria-close-label="Cerrar">
              <ul>
                  <li>
                      - Los solicitudes masivas solo aplican a renovaciones de nombramiento de los profesores horas clase.
                      Para ello debe presionar el botón “Listar profesores Horas Clases periodo anterior”, especificando previamente la fecha de termino del nombramiento del periodo anterior.
                  </li>
              </ul>
          </b-message>
          <div class="columns">
              <div class="column">
                  <b-field grouped group-multiline>
                      <div class="control">
                          <h1 class="title is-4">Unidad de Profesores Horas Clase</h1>
                      </div>
                  </b-field>
              </div>
          </div>
          <div class="columns">
              <div class="column">
                  <b-field>
                          <b-input v-model="unit" type="text" readonly></b-input>
                  </b-field>
              </div>
          </div>
          <div class="columns">
              <div class="column">
                  <b-field grouped group-multiline>
                      <div class="control">
                          <h1 class="title is-4">Seleccionar Fecha de término de Nombramientos periodo anterior</h1>
                      </div>
                  </b-field>
              </div>
          </div>
          <div class="columns">
              <div class="column">
                  <b-field label="Fecha de término de nombramientos periodo anterior*">
                          <b-datepicker
                              v-model="end_date"
                              icon="calendar-alt"
                              trap-focus
                              locale="es-ES"
                              editable
                              placeholder="Formato de Fecha: dd/mm/aaaa"
                          >
                          </b-datepicker>
                  </b-field>
              </div>
          </div>
          <br>
          <br>
          <div class="columns">
              <div class="column">
                  <div class="has-text-centered">
                      <b-button type="is-primary"
                                icon-left="tasks"
                                @click="loadHoursClass()">
                          Listar Profesores Horas Clases periodo anterior
                      </b-button>
                  </div>
              </div>
          </div>
          <ValidationObserver v-if="!button_press" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(createMassiveRequestConfirm)">
                  <div class="columns">
                      <div class="column">
                          <div class="control">
                              <h1 class="title">Datos de nombramiento</h1>
                          </div>
                      </div>
                  </div>
                  <div class="box">
                      <div class="columns">
                          <div class="column">
                              <b-field label="Descripción*">
                                  <ValidationProvider rules="required|min:5|max:200" v-slot="{ errors }">
                                      <b-input v-model="person_unit.description" type="textarea"></b-input>
                                      <span class="validation-alert">{{ errors[0] }}</span>
                                  </ValidationProvider>
                              </b-field>
                          </div>
                      </div>
                      <div class="columns">
                          <div class="column">
                              <b-field label="Tipo de Nombramiento*">
                                  <ValidationProvider rules="required" v-slot="{errors}">
                                      <multiselect v-model="person_unit.appointment_type_id"
                                                   :options="appointment_types.map(type => type.id)"
                                                   :custom-label="opt => appointment_types.find(x => x.id === opt).name"
                                                   placeholder="Seleccione tipo de nombramiento"
                                                   selectLabel="Presione para seleccionar"
                                                   selectedLabel="Seleccionado"
                                                   deselectLabel="No se puede deseleccionar"
                                                   :allow-empty="false">
                                          <template v-slot:noOptions>
                                              No existen datos
                                          </template>
                                          <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                                      </multiselect>
                                      <span class="validation-alert">{{ errors[0] }}</span>
                                  </ValidationProvider>
                              </b-field>
                          </div>
                          <div class="column">
                              <b-field label="Cargo*">
                                  <ValidationProvider rules="required" v-slot="{errors}">
                                      <multiselect v-model="person_unit.work_plant_id"
                                                   :options="work_plants.map(type => type.id)"
                                                   :custom-label="opt => work_plants.find(x => x.id === opt).name"
                                                   placeholder="Seleccione un cargo"
                                                   selectLabel="Presione para seleccionar"
                                                   selectedLabel="Seleccionado"
                                                   deselectLabel="No se puede deseleccionar"
                                                   :allow-empty="false">
                                          <template v-slot:noOptions>
                                              No existen datos
                                          </template>
                                          <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                                      </multiselect>
                                      <span class="validation-alert">{{ errors[0] }}</span>
                                  </ValidationProvider>
                              </b-field>
                          </div>
                      </div>
                      <div class="columns">
                          <div class="column">
                              <b-field label="Unidad de contratación*">
                                  <ValidationProvider rules="required" v-slot="{errors}">
                                      <multiselect v-model="person_unit.contracting_unit_id"
                                                   :options="contract_units.map(type => type.id)"
                                                   :custom-label="opt => contract_units.find(x => x.id === opt).name"
                                                   placeholder="Seleccione una unidad"
                                                   selectLabel="Presione para seleccionar"
                                                   selectedLabel="Seleccionado"
                                                   deselectLabel="No se puede deseleccionar"
                                                   :allow-empty="false">
                                          <template v-slot:noOptions>
                                              No existen datos
                                          </template>
                                          <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                                      </multiselect>
                                      <span class="validation-alert">{{ errors[0] }}</span>
                                  </ValidationProvider>
                              </b-field>
                          </div>
                          <div class="column">
                              <b-field label="Unidad de desempeño*">
                                  <ValidationProvider rules="required" v-slot="{errors}">
                                      <multiselect v-model="person_unit.unit_id"
                                                   :options="units.map(type => type.id)"
                                                   :custom-label="opt => units.find(x => x.id === opt).name"
                                                   placeholder="Seleccione una unidad"
                                                   selectLabel="Presione para seleccionar"
                                                   selectedLabel="Seleccionado"
                                                   deselectLabel="No se puede deseleccionar"
                                                   :allow-empty="false">
                                          <template v-slot:noOptions>
                                              No existen datos
                                          </template>
                                          <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                                      </multiselect>
                                      <span class="validation-alert">{{ errors[0] }}</span>
                                  </ValidationProvider>
                              </b-field>
                          </div>
                      </div>
                      <div class="columns">
                          <div class="column">
                              <b-field label="Calidad de contrato">
                                  <multiselect v-model="person_unit.quality"
                                               :options="qualities.map(type => type.name)"
                                               placeholder="Seleccione calidad de contrato"
                                               selectLabel="Presione para seleccionar"
                                               selectedLabel="Seleccionado"
                                               deselectLabel="Presione para deseleccionar"
                                               :allow-empty="true">
                                      <template v-slot:noOptions>
                                          No existen datos
                                      </template>
                                      <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                                  </multiselect>
                              </b-field>
                          </div>
                          <div class="column">
                              <b-field label="Fecha Desde*">
                                  <ValidationProvider rules="required" v-slot="{errors}">
                                      <b-datepicker
                                          v-model="person_unit.start_date"
                                          icon="calendar-alt"
                                          trap-focus
                                          locale="es-ES"
                                          editable
                                          placeholder="Formato de Fecha: dd/mm/aaaa"
                                      >
                                      </b-datepicker>
                                      <span class="validation-alert">{{ errors[0] }}</span>
                                  </ValidationProvider>
                              </b-field>
                          </div>
                          <div class="column">
                              <b-field label="Fecha Hasta*">
                                  <ValidationProvider rules="required" v-slot="{errors}">
                                      <b-datepicker
                                          v-model="person_unit.end_date"
                                          icon="calendar-alt"
                                          trap-focus
                                          locale="es-ES"
                                          editable
                                          placeholder="Formato de Fecha: dd/mm/aaaa"
                                      >
                                      </b-datepicker>
                                      <span class="validation-alert">{{ errors[0] }}</span>
                                  </ValidationProvider>
                              </b-field>
                          </div>
                      </div>
                      <div class="columns">
                          <div class="column">
                              <b-field label="Año*">
                                  <ValidationProvider :rules="{numeric: true, integer: true, positive: true, min_value: 1900, max_value: 3000, required: true}" v-slot="{ errors }">
                                      <b-input  v-model="person_unit.year"  type="text">
                                      </b-input>
                                      <span class="validation-alert">{{ errors[0] }}</span>
                                  </ValidationProvider>
                              </b-field>
                          </div>
                          <div class="column">
                              <b-field label="Semestre*">
                                  <ValidationProvider rules="required|numeric|integer|positive|min_value:1|max_value:2" v-slot="{ errors }">
                                      <b-input  v-model="person_unit.semester" type="text">
                                      </b-input>
                                      <span class="validation-alert">{{ errors[0] }}</span>
                                  </ValidationProvider>
                              </b-field>
                          </div>
                      </div>
                      <div v-if="person_unit.start_date != null && person_unit.start_date != '' && person_unit.end_date != null && person_unit.end_date != ''" class="actions has-text-centered">
                        <b-button label="Comprobar horas maximas"
                                  class="is-info"
                                  @click="verifyMaxHours(true)"
                                  icon-left=""/>
                      </div>
                      <br>
                      <div class="columns is-centered" v-if="person_unit.start_date != null && person_unit.start_date != '' && person_unit.end_date != null && person_unit.end_date != ''">
                        <div class="column is-centered has-text-centered">
                          <b-message type="is-info" has-icon>
                            Importante: El icono de advertencia que aparece en el listado de nombramiento,
                            indica que la persona sobrepasa el máximo de horas permitidos, considerando las horas de esta solicitud. Para ver el detalle, hacer click sobre el icono de advertencia.
                          </b-message>
                        </div>
                      </div>
                      <div class="actions has-text-right">
                          <button class="button is-primary" type="submit">Crear Solicitud</button>
                          <b-button label="Exportar"
                                    class="is-primary is-outlined"
                                    @click="exportSolicitud"
                                    icon-left="file-excel"/>
                      </div>
                    <div class="columns">
                      <div class="column is-4 is-offset-8">
                        <b-switch @input="changeSwitch" v-model="warningOnly">Mostrar solo nombramientos con advertencias.</b-switch>
                      </div>
                    </div>
                  </div>
              </form>
          </ValidationObserver>
          <div class="box" v-if="!button_press">
              <b-table
                  :data="filter_person_units"
                  :paginated= true
                  per-page="20"
                  striped
                  hoverable
                  :current-page="currentPage"
                  :pagination-simple=false
                  pagination-position="both"
                  default-sort-direction="asc"
                  sort-icon="arrow-up"
                  sort-icon-size="is-small"
                  aria-next-label="Siguiente"
                  aria-previous-label="Anterior"
                  aria-page-label="Página"
                  aria-current-label="Página actual">
                  <b-table-column
                      field="index"
                      label="N°">
                      <template v-slot="props">
                          {{ filter_person_units.indexOf(props.row) + 1 }}
                      </template>
                  </b-table-column>
                  <b-table-column
                      field="run_attributes"
                      label="Rut"
                      searchable
                      sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                          <b-input
                              v-model="props.filters[props.column.field]"
                              placeholder=" Buscar"
                              icon="search"
                              size="is-small" />
                      </template>
                      <template v-slot="props">
                          {{ props.row.run_attributes.rut.toString()+"-"+props.row.run_attributes.dv }}
                      </template>
                  </b-table-column>
                  <b-table-column
                      field="person_name"
                      label="Nombre Completo"
                      searchable
                      sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                          <b-input
                              v-model="props.filters[props.column.field]"
                              placeholder=" Buscar"
                              icon="search"
                              size="is-small" />
                      </template>
                      <template v-slot="props">
                          {{ props.row.person_name }}
                      </template>
                  </b-table-column>
                  <b-table-column
                      field="category"
                      label="Categoría"
                      searchable
                      sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                          <b-input
                              v-model="props.filters[props.column.field]"
                              placeholder=" Buscar"
                              icon="search"
                              size="is-small" />
                      </template>
                      <template v-slot="props">
                          {{ props.row.category }}
                      </template>
                  </b-table-column>
                  <b-table-column
                      field="selected_hr_subjects"
                      label="Asignaturas"
                      searchable
                      sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                          <b-input
                              v-model="props.filters[props.column.field]"
                              placeholder=" Buscar"
                              icon="search"
                              size="is-small" />
                      </template>
                      <template v-slot="props">
                          <ul>
                              <li v-for="item in props.row.selected_hr_subjects">
                                <span class="tag is-primary">
                                    {{ item.name }}
                                </span>
                              </li>
                          </ul>
                      </template>
                  </b-table-column>
                  <b-table-column
                      field="selected_hr_subjects"
                      label="Horas">
                      <template
                          slot-scope="props">
                          <b-input
                              v-model="props.filters[props.column.field]"
                              placeholder=" Buscar"
                              icon="search"
                              size="is-small" />
                      </template>
                      <template v-slot="props">
                          <ul>
                              <li v-for="item in props.row.selected_hr_subjects">
                                <span class="tag is-primary">
                                  {{ item.hours }}
                                </span>
                              </li>
                          </ul>
                      </template>
                  </b-table-column>
                  <b-table-column
                      field="selected_hr_subjects"
                      label="Código Siac">
                      <template
                          slot-scope="props">
                          <b-input
                              v-model="props.filters[props.column.field]"
                              placeholder=" Buscar"
                              icon="search"
                              size="is-small" />
                      </template>
                      <template v-slot="props">
                          <ul>
                              <li v-for="item in props.row.selected_hr_subjects">
                                <span class="tag is-primary">
                                  {{ item.siac_code ? item.siac_code:'-' }}
                                </span>
                              </li>
                          </ul>
                      </template>
                  </b-table-column>
                  <b-table-column
                      label="Acciones"
                      v-slot="props">
                      <b-icon
                          pack="fas"
                          icon="edit"
                          size="is-medium"
                          type="is-info"
                          @click.native="editSubject(props.row)"/>
                      <b-icon
                          pack="fas"
                          icon="trash"
                          size="is-medium"
                          type="is-danger"
                          @click.native="deleteTeacherConfirm(props.row)"/>
                      <b-tooltip  v-if="props.row.horas_totales+props.row.actual_horas> 16 && (16 - props.row.horas_totales) >= 0"
                                  :label="'Advertencia. La persona tiene asignada más de 16 horas en este periodo de tiempo.\nsolo puede considerar ' + (16 - props.row.horas_totales).toString() + ' horas para esta solicitud.\nPara más detalle click acá'"
                                  position="is-left"
                                  size="is-medium"
                                  multilined>
                        <b-icon
                            pack="fas"
                            icon="exclamation-circle"
                            size="is-medium"
                            type="is-warning"
                            @click.native="showWarningPersonUnits(props.row)">
                        </b-icon>
                      </b-tooltip>
                      <b-tooltip  v-if="props.row.horas_totales+props.row.actual_horas > 16 && (16 - props.row.horas_totales) < 0"
                                  :label="'Advertencia. La persona tiene asignada más de 16 horas en este periodo de tiempo.\nActualmente tiene ' + (props.row.horas_totales).toString() + ' horas.\nPara más detalle click acá'"
                                  position="is-left"
                                  size="is-medium"
                                  multilined>
                        <b-icon
                            pack="fas"
                            icon="exclamation-circle"
                            size="is-medium"
                            type="is-warning"
                            @click.native="showWarningPersonUnits(props.row)">
                        </b-icon>
                      </b-tooltip>
                  </b-table-column>
                  <template slot="empty" slot-scope="props">
                      <div class="columns is-centered">
                          <div class="column is-6 is-centered has-text-centered">
                              <b-message has-icon type="is-warning">
                                  <h1> No se encontraron resultados, para la fecha indicada.</h1>
                              </b-message>
                          </div>
                      </div>
                  </template>
              </b-table>
          </div>
      </div>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
      <b-modal
          v-model="isActiveModal"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
          <template #default="props">
              <modal_modify_subjec :category_id="category_id"
                                   :categories="categories"
                                   :temp_selected_hr_subjects="temp_selected_hr_subjects"
                                   :total_hours = "total_hours"
                                   :person_name = "person_name"
                                   @clicked="onClickChild"
                                   @close="props.close">
              </modal_modify_subjec>
          </template>
      </b-modal>
      <b-modal
          v-model="isModalWarning"
          class="modal-custom"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="canCancel"
          :width="1300"
      >
        <template #default="props">
          <modal_warning_person_units @close="props.close"
                                      :person_units_warning="prop_person_units_warning"
                                      :request_id="-1"
                                      :modal-header="person_name"
          ></modal_warning_person_units>
        </template>
      </b-modal>
  </section>
</template>

<script>
import axios from "axios";
import modal_modify_subjec from "./modal_modify_subjec.vue";
import modal_warning_person_units from "../requests/modal_warning_person_units.vue";
import {request_from_appointment, request_from_appointment_massive} from "../../packs/utilities";

export default {
    name: "bulk_requests",
    components: {modal_modify_subjec, modal_warning_person_units},
    props:['unit','unit_id','appointment_types_text','unit_cc'],
    created() {
        this.isLoading = true
        this.loadUnits()
    },
    data(){
        return{
            button_press: true,
            isLoading: false,
            hourclass_old: [],
            currentPage: 1,
            end_date: null,
            isActiveModal: false,
            category_id: null,
            categories:[],
            selected_hr_subjects:[],
            temp_selected_hr_subjects:[],
            indice: null,
            person_unit:{
                is_hours:true,
                pu_relation_type_id: 1,
            },
            qualities: [{name: "CONTRATA"},{name: "PLANTA"},{name: "AD-HONOREM"}, {name: "INTERINO"}, {name: "SUPLENTE"}],
            units: [],
            contract_units:[],
            work_plants:[],
            appointment_types:[],
            request: {},
            request_created: false,
            request_id: null,
            comment: null,
            total_hours: null,
            person_name:null,
            isModalWarning:false,
            person_units_warning: null,
            prop_person_units_warning: null,
            canCancel: ['escape'],
            filter_person_units: [],
            warningOnly: false,
        }
    },
    methods:{
        request_from_appointment,
        request_from_appointment_massive,
        loadHoursClass(){
            this.isLoading = true
            this.hourclass_old = []
            this.filter_person_units = []
            this.warningOnly = false
            this.button_press = true
            if(this.end_date == null || this.end_date == undefined){
                this.$buefy.dialog.alert({
                    title: 'Error',
                    message: 'Debe seleccionar una fecha valida',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
                this.isLoading = false;
                return
            }
            return axios
                .post("/get_hourclass_old.json",{end_date: this.end_date})
                .then(res => {
                    this.hourclass_old = res.data
                    this.filter_person_units = this.hourclass_old
                    this.button_press = false
                    this.isLoading = false
                })
                .catch(error =>{
                    console.log(error)
                    this.isLoading = false
                })
        },
        deleteTeacherConfirm(row){
            if(this.hourclass_old.length > 1){
                this.$buefy.dialog.confirm({
                    title: 'Eliminar Profesor Horas Clases',
                    message: 'Precaución, se eliminará el profesor horas clases de la solicitud, ¿Desea continuar?',
                    cancelText: 'Cancelar',
                    confirmText: 'Eliminar',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => this.deleteTeacher(row)
                })
            }
            else{
                this.$buefy.dialog.alert({
                    title: 'Error',
                    message: 'No se puede eliminar al profesor horas de clases, al menos debe haber uno en la solicitud',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
            }
        },
        deleteTeacher(row){
            const index = this.hourclass_old.indexOf(row)
            this.hourclass_old.splice(index,1)
            this.filter_person_units = this.hourclass_old
            if(this.warningOnly){
              this.filter_person_units = this.hourclass_old.filter(hourclass_old =>
                  hourclass_old.horas_totales + hourclass_old.actual_horas > 16
              )
            }
        },
        editSubject(row){
            const temp_selected_subject = []
            this.indice = this.hourclass_old.indexOf(row)
            this.category_id = row.category_id
            this.categories = row.categories
            row.selected_hr_subjects.forEach(datos => {
              temp_selected_subject.push(datos)
            })
            this.temp_selected_hr_subjects = temp_selected_subject
            this.total_hours = row.total_horas
            this.person_name = row.person_name
            this.isActiveModal = true
        },
        async createMassiveRequestConfirm(){

            const sizeWarning = this.hourclass_old.filter(hourclass_old =>
                hourclass_old.horas_totales + hourclass_old.actual_horas > 16
            )

            if(this.hourclass_old.length > 1){

                await this.verifyMaxHours(false)
                const sizeWarning = this.hourclass_old.filter(hourclass_old =>
                    hourclass_old.horas_totales + hourclass_old.actual_horas > 16
                ).length

                if(sizeWarning>0){
                  this.$buefy.dialog.confirm({
                    title: 'Confirmar Solicitud Masiva',
                    message: 'Se encontraron advertencias respecto a las horas maximas de algunos profesores por hora, de todas formas ¿Desea continuar?',
                    cancelText: 'Revisar',
                    confirmText: 'Crear',
                    type: 'is-warning',
                    hasIcon: true,
                    onConfirm: () => this.createMassiveRequest( )
                  })
                }
                else{
                  this.$buefy.dialog.confirm({
                    title: 'Confirmar Solicitud Masiva',
                    message: 'Estás a punto de crear la solicitud masiva de nombramiento, ¿Desea continuar?',
                    cancelText: 'Cancelar',
                    confirmText: 'Crear',
                    type: 'is-info',
                    hasIcon: true,
                    onConfirm: () => this.createMassiveRequest( )
                  })
                }
            }
            else{
                this.$buefy.dialog.alert({
                    title: 'Error',
                    message: 'No se pudo crear la solicitud, deben haber, como mínimo, dos profesor horas clases en la solicitud',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
            }
        },
        async createMassiveRequest(){
            this.isLoading = true
            this.person_unit.pu_relation_type_id = 6
            this.person_unit.person_unit_state_id = 2
            this.request = this.request_from_appointment_massive(this.person_unit)
            await axios
                .post("/requests.json", {
                request: this.request
            }).then(response =>{
                this.person_unit.request_id = response.data["id"]
                this.request_id = response.data["id"]
                this.request_created = true

            }).catch(error => {
                var elmnt = document.getElementById("app");
                elmnt.scrollIntoView();
                this.$refs.modalHourAppointmentForm.scrollTop = 0;
                this.$buefy.dialog.alert({
                    title: 'Error',
                    message: 'Hubo errores al agregar la solicitud de nombramiento, favor revisar formato',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
                this.person_unit.date_validation = true
                this.allow_duplication = false
                this.isLoading=false;
            })

            await axios
                .post("/admin/person_units/create_massive.json", {
                    person_unit: this.person_unit,
                    hourclass_old: this.hourclass_old,
                    request_id:this.request_id,
                    //selected_hr_subjects: this.selected_hr_subjects,
                    //hr_subject_attributes: this.hr_subject_attributes,
                    pu_relation_type_id: 6,
                    allow_duplication: this.allow_duplication,
                })
                .then(res =>{
                    this.$buefy.dialog.alert({
                        message: '<p>La solicitud de nombramiento fue creada con éxito. Para abrir la solicitud en una nueva pestaña, presione</p><b><a href="/requests/'+this.request_id+'" target="_blank">aquí</a></b>',
                        confirmText: 'Ok'
                    })
                    this.errores_validacion = {};
                    this.person_unit = {}
                    this.request = {}
                    this.isLoading=false;
                    this.errors = [];
                    this.button_press = true
                    this.hourclass_old = []
                    this.filter_person_units = []
                    this.indice = null
                    this.category_id = null
                    this.categories = []
                    this.selected_hr_subjects = []
                    this.person_unit.contracting_unit_id = parseInt(this.unit_id)
                    this.person_unit.unit_id = parseInt(this.unit_id)
                    this.person_unit.quality = "CONTRATA"
                    this.person_unit.work_plant_id = this.work_plants.find(x => x.name === "PROFESOR HORAS DE CLASES").id
                    this.person_unit.appointment_type_id = this.appointment_types.find(x => x.name === "CONTINUIDAD").id
                    if(res.data.length > 0){
                      this.$buefy.dialog.alert({
                        title: 'Advertencia',
                        message: 'Algunos nombramientos no se pudieron crear',
                        type: 'is-warning',
                        hasIcon: true,
                        icon: 'exclamation-triangle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                      })
                    }
                    this.$emit('close')
                })
                .catch(error =>{
                  console.log(error.response.data)
                  this.isLoading = false
                  this.$buefy.dialog.alert({
                    title: 'Error',
                    message: 'No se pudo crear la solicitud algunos datos estan erroneos',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                  })
                })

        },
        onClickChild(value){
            this.isLoading = true
            this.temp_selected_hr_subjects = []
            this.total_hours = null
            this.person_name = null
            this.hourclass_old[this.indice].selected_hr_subjects = value.new_selected_hr_subjects
            this.hourclass_old[this.indice].subjects = value.subjects
            this.hourclass_old[this.indice].category_id = value.category_id
            this.hourclass_old[this.indice].category = value.category
            this.hourclass_old[this.indice].actual_horas = value.total_hours
            this.filter_person_units = this.hourclass_old
            if(this.warningOnly){
              this.filter_person_units = this.hourclass_old.filter(hourclass_old =>
                  hourclass_old.horas_totales + hourclass_old.actual_horas > 16
              )
            }
            this.isLoading = false
        },
        loadWorkPlant(){
            axios
                .get("/work_plants.json")
                .then(res =>{
                    this.work_plants = res.data
                    this.appointment_types = JSON.parse(this.appointment_types_text)
                    this.person_unit.work_plant_id = this.work_plants.find(x => x.name === "PROFESOR HORAS DE CLASES").id
                    this.person_unit.appointment_type_id = this.appointment_types.find(x => x.name === "CONTINUIDAD").id
                    this.isLoading = false
                })
                .catch( err =>{
                    console.log(err)
                    this.isLoading = false
                })
        },
        loadUnits(){
            axios
                .get("/admin/units.json")
                .then(res =>{
                    this.units = res.data["unidades"]
                    this.contract_units = res.data["unidades"]
                    this.person_unit.contracting_unit_id = parseInt(this.unit_id)
                    this.person_unit.unit_id = parseInt(this.unit_id)
                    this.person_unit.quality = "CONTRATA"
                    this.loadWorkPlant()
                })
                .catch( err =>{
                    console.log(err)
                    this.isLoading = false
                })
        },
        exportSolicitud(){
          let vm = this;
          vm.isLoading = true
          axios
              .post('/life_record/export_request_massive.xlsx',{
                nombramientos: vm.hourclass_old,
                depto_name: vm.unit_cc
              },
              {
                responseType: "blob"
              })
              .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Solicitud Nombramiento Masivos.xlsx');
                document.body.appendChild(link);
                link.click();
                vm.isLoading = false
              })
              .catch(e => {
                console.log(e.data)
                vm.isLoading = false
              })
        },
        verifyMaxHours(bandera){
          if(this.person_unit.start_date != null && this.person_unit.start_date != '' && this.person_unit.end_date != null && this.person_unit.end_date != ''){
            this.isLoading = true
            return axios
                .post("/update_hourclass_old.json",{
                    hours_class_old: this.hourclass_old,
                    person_unit_start_date: this.person_unit.start_date,
                    person_unit_end_date: this.person_unit.end_date
                })
                .then(res => {
                  this.hourclass_old = res.data.data
                  this.filter_person_units = this.hourclass_old
                  if(this.warningOnly){
                    this.filter_person_units = this.hourclass_old.filter(hourclass_old =>
                        hourclass_old.horas_totales + hourclass_old.actual_horas > 16
                    )
                  }
                  const size_warnings = this.hourclass_old.filter(person_unit => person_unit.horas_totales + person_unit.actual_horas > 16).length
                  this.button_press = false
                  this.isLoading = false
                  if(size_warnings > 0 && bandera){
                    this.$buefy.dialog.alert({
                      title: 'Advertencia',
                      message: 'Se encontraron advertencias respecto a las horas maximas de algunos profesores por hora',
                      type: 'is-warning',
                      confirmText: 'Ok',
                      hasIcon: true,
                    })
                  }
                  else if(bandera){
                    this.$buefy.dialog.alert({
                      title: 'Sin advertencias',
                      message: 'No se encontraron problemas con las horas maximas de los profesores por hora',
                      confirmText: 'Ok',
                      type: 'is-success',
                      hasIcon: true,
                    })
                  }
                })
                .catch(error =>{
                  console.log(error)
                  this.isLoading = false
                })
          }
          else{
            this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Las fechas desde y hasta deben ser validas',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          }
          
        },
      showWarningPersonUnits(row){
        this.isLoading = true
        this.person_units_warning = null
        this.prop_person_units_warning = null
        this.person_name = row.person_name
        this.person_units_warning = row.nomb_horas_totales
        this.prop_person_units_warning = row.nomb_horas_totales
        this.isModalWarning = true
        this.isLoading = false
      },
      changeSwitch(){
        if(this.warningOnly){
          this.filter_person_units = this.hourclass_old.filter(hourclass_old =>
              hourclass_old.horas_totales + hourclass_old.actual_horas > 16
          )
        }
        else{
          this.filter_person_units = this.hourclass_old
        }
      },
    }

}
</script>

<style scoped>
.colored-li {
  background-color: rgb(121, 87, 213);
  color: white;
  margin-top: 3px
}
.custom-column {
  padding-right: 0em;
  padding-left: 0em;/* Modifica el valor según tus necesidades */
}
</style>