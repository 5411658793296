var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveRole)}}},[_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(_vm._s(_vm.modalHeader))]),_vm._v(" "),_c('button',{staticClass:"delete",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}})]),_vm._v(" "),_c('section',{staticClass:"modal-card-body"},[(Object.keys(_vm.errores_validacion).length !== 0)?_c('div',{staticClass:"mb-2"},[_c('b-message',{attrs:{"type":"is-danger","has-icon":""}},_vm._l((_vm.errores_validacion),function(item,index){return _c('div',[_vm._v("\n                "+_vm._s(item[0])+"\n              ")])}),0)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Persona"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"value":_vm.name,"type":"text","readonly":"","disabled":true}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_vm._v(" "),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Rut"}},[_c('b-input',{attrs:{"value":_vm.complete_rut,"type":"text","readonly":"","disabled":true}})],1)],1),_vm._v(" "),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Correo corporativo"}},[_c('b-input',{attrs:{"value":_vm.persona_seleccionada.corporate_mail,"type":"text","readonly":"","disabled":true}})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Unidad de desempeño"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.units.map(function (type) { return type.id; }),"custom-label":function (opt) { return _vm.units.find(function (x) { return x.id === opt; }).name; },"placeholder":"Seleccione una unidad","selectLabel":"Presione para seleccionar","selectedLabel":"Seleccionado","deselectLabel":"No se puede deseleccionar","disabled":_vm.edit,"allow-empty":false},scopedSlots:_vm._u([{key:"noOptions",fn:function(){return [_vm._v("\n                      No existen datos\n                    ")]},proxy:true}],null,true),model:{value:(_vm.person_unit.unit_id),callback:function ($$v) {_vm.$set(_vm.person_unit, "unit_id", $$v)},expression:"person_unit.unit_id"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_vm._v(" "),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Unidad de contratación"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.units.map(function (type) { return type.id; }),"custom-label":function (opt) { return _vm.units.find(function (x) { return x.id === opt; }).name; },"placeholder":"Seleccione una unidad","selectLabel":"Presione para seleccionar","selectedLabel":"Seleccionado","deselectLabel":"No se puede deseleccionar","disabled":_vm.edit,"allow-empty":false},scopedSlots:_vm._u([{key:"noOptions",fn:function(){return [_vm._v("\n                      No existen datos\n                    ")]},proxy:true}],null,true),model:{value:(_vm.person_unit.contracting_unit_id),callback:function ($$v) {_vm.$set(_vm.person_unit, "contracting_unit_id", $$v)},expression:"person_unit.contracting_unit_id"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Descripción"}},[_c('b-input',{attrs:{"type":"text","disabled":true},model:{value:(_vm.person_unit.description),callback:function ($$v) {_vm.$set(_vm.person_unit, "description", $$v)},expression:"person_unit.description"}})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Rol*"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.internal_positions.map(function (type) { return type.id; }),"custom-label":function (opt) { return _vm.internal_positions.find(function (x) { return x.id === opt; }).name; },"placeholder":"Seleccione un rol","selectLabel":"Presione para seleccionar","selectedLabel":"Seleccionado","deselectLabel":"No se puede deseleccionar","allow-empty":false},on:{"input":_vm.updateStatus},scopedSlots:_vm._u([{key:"noOptions",fn:function(){return [_vm._v("\n                      No existen datos\n                    ")]},proxy:true}],null,true),model:{value:(_vm.person_unit.internal_position),callback:function ($$v) {_vm.$set(_vm.person_unit, "internal_position", $$v)},expression:"person_unit.internal_position"}},[_vm._v(" "),_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("\n                      No se encontraron elementos.\n                    ")])]),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Fecha Desde"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-datepicker',{attrs:{"icon":"calendar-alt","trap-focus":"","locale":"es-ES","editable":"","disabled":_vm.edit,"placeholder":"Formato de Fecha: dd/mm/aaaa"},model:{value:(_vm.person_unit.start_date),callback:function ($$v) {_vm.$set(_vm.person_unit, "start_date", $$v)},expression:"person_unit.start_date"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_vm._v(" "),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Fecha Hasta"}},[_c('b-datepicker',{attrs:{"icon":"calendar-alt","trap-focus":"","locale":"es-ES","editable":"","disabled":_vm.edit,"placeholder":"Formato de Fecha: dd/mm/aaaa"},model:{value:(_vm.person_unit.end_date),callback:function ($$v) {_vm.$set(_vm.person_unit, "end_date", $$v)},expression:"person_unit.end_date"}})],1)],1)])])]),_vm._v(" "),_c('footer',{staticClass:"modal-card-foot actions"},[_c('div',{staticClass:"field is-grouped is-pulled-right"},[_c('button',{staticClass:"button",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Cerrar")]),_vm._v(" "),_c('button',{staticClass:"button is-primary",attrs:{"type":"submit"}},[_vm._v("Guardar ")])])])])]}}])}),_vm._v(" "),_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }