<template>
  <section>
    <div class="container">
      <!--DATA TABLE-->
      <b-table
          :data="person_units"
          :paginated= true
          per-page="20"
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="product.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual"
          scrollable
          :opened-detailed="defaultOpenedDetails"
          detailed
          detail-key="id"
          detail-transition="fade"
          :show-detail-icon="showDetailIcon"
          :sticky-header=false>
        <b-table-column
            field="resolution_date"
            label="Fecha Documento"
            width="110"
            sortable
            searchable :custom-search="searchResolutionDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.resolution_date._isValid ?  new Date(props.row.resolution_date).toLocaleDateString("es-CL"): ''}}
          </template>
        </b-table-column>
        <b-table-column
            field="description"
            label="Descripción"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.description}}
          </template>
        </b-table-column>
        <b-table-column
            field="resolution"
            label="N° de documento"
            width="110"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.resolution}}
          </template>
        </b-table-column>
        <b-table-column
            field="resolution_type.name"
            label="Tipo documento"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.resolution_type ? props.row.resolution_type.name : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="appointment_type.name"
            label="Tipo de Alejamiento"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.appointment_type ? props.row.appointment_type.name : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="start_date"
            label="Fecha"
            width="110"
            sortable
            searchable :custom-search="searchDistanceDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>

        <b-table-column
            label="Acciones"
            width="150px"
            v-slot="props">
          <b-tooltip
              v-if="props.row.can_show"
              label="Ver información completa del alejamiento."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="eye"
                type="is-primary"
                @click="execShowModal(props.row)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_edit"
              label="Editar información del alejamiento."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="edit"
                type="is-info is-light is-outlined"
                @click="execEditDistanceModal(props.row)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_nullify"
              label="Anular alejamiento."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="ban"
                type="is-danger is-light is-outlined"
                @click="execNullifyModal(props.row.id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_view_pdf"
              label="Visualizar documento asociado al alejamiento."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="file-alt"
                type="is-success is-light is-outlined"
                @click="viewFile(props.row.document_id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_view_pdf"
              label="Descargar documento asociado al alejamiento."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="download"
                type="is-primary is-light is-outlined"
                @click="downloadFile(props.row.document_id)"/>
          </b-tooltip>


        </b-table-column>
        <template #detail="props">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <div class="columns">
                  <div class="column">
                    <strong>Nombramiento Asociado: </strong>
                  </div>
                </div>
                  <div class="columns">
                    <div class="column is-narrow">
                      <p>{{props.row.appointment.resolution}}</p>
                    </div>
                    <div class="column is-narrow">
                      <p>{{formatDate(props.row.appointment.start_date)}}</p>
                    </div>
                    <div class="column is-narrow">
                      <p>{{formatDate(props.row.appointment.end_date)}}</p>
                    </div>
                    <div class="column is-narrow">
                      <p>{{props.row.appointment.appointment_type_name}}</p>
                    </div>
                    <div class="column">
                      <b-icon
                          pack="fas"
                          icon="eye"
                          size="is-medium"
                          @click.native="execShowModalAppointment(props.row)"/>
                    </div>
                  </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
      <!--FIN DATA TABLE-->

      <!-- MODALES -->
      <b-modal
          v-model="isAddModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <select-appointment-establishment :person-id="personId"
                                            query-type="create"
                                            modal-header="Seleccionar Nombramiento a Crear"
                                            @close="props.close"></select-appointment-establishment>
        </template>
      </b-modal>
      <b-modal
          v-model="isShowAppointmentModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <component  :is="showName"
                      :id="selected"
                      :modal-header="title"
                      @close="props.close"/>
        </template>
      </b-modal>
      <b-modal
          v-model="isShowModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <component  :is="showName"
                      :id="selected"
                      :modal-header="title"
                      @close="props.close"/>
        </template>
      </b-modal>
      <b-modal
          v-model="isEditModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <component :is="formName"
                     :person-id="personId"
                     :id="selected"
                     query-type="update"
                     :modal-header="title"
                     @close="props.close"/>
        </template>
      </b-modal>
      <b-modal
          v-model="isEditDistanceModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <distance-form :person-id="person_unit.person_id"
                         :id="selected"
                         :person_unit_id="selected"
                         query-type="update"
                         :modal-header="'Editar Alejamiento - '  +person_name"
                         @close="props.close">
          </distance-form>
        </template>
      </b-modal>

      <b-modal
          v-model="showPdf"
          :can-cancel="true"
          :destroy-on-hide="true"
          aria-modal
          aria-role="dialog"
          has-modal-card
          trap-focus
          :width="2000"
      >
        <template #default="props">
          <pdf_view
              :src="src"
              @close="props.close"
          ></pdf_view>
        </template>

      </b-modal>

    </div>
    <b-loading :is-full-page="true"
               v-model="isLoading"
               :can-cancel="false"/>
  </section>
</template>

<script>
import axios from "axios";
import AppointmentAcademic from "../life_record/appointment_academic"
import AppointmentAdministrative from "../life_record/appointment_administrative"
import AppointmentHours from "../life_record/appointment_hours";
import AppointmentAcademicForm from "../life_record/modal_academic_appointment_form"
import AppointmentHoursForm from "../life_record/modal_hour_appointment_form"
import AppointmentAdministrativeForm from "../life_record/modal_administrative_appointment_form"
import SelectAppointmentEstablishment from '../life_record/select_appointment_establishment'
import Distance from "../life_record/distance"
import DistanceForm from "../life_record/modal_distance_form"
import AppointmentFeeContract from "../life_record/appointment_feecontract"
import moment from "moment";
import {searchDate, formatDate} from "../../packs/utilities";
import pdf from 'vue-pdf';
import Pdf_view from "../performances/pdf_view.vue"

export default {
  name: "distances",
  props:[
    'personId', 'person_name'
  ],
  components:{
    Pdf_view,
    AppointmentHours,
    AppointmentAcademic,
    AppointmentAdministrative,
    AppointmentHoursForm,
    AppointmentAcademicForm,
    AppointmentAdministrativeForm,
    SelectAppointmentEstablishment,
    Distance,
    AppointmentFeeContract,
    DistanceForm
  },
  data(){
    return {
      formName: "",
      showName: "",
      title: "",
      selected: null,
      index: null,
      currentPage: 1,
      isAddModalActive: false,
      isEditModalActive: false,
      isShowModalActive: false,
      isShowAppointmentModalActive: false,
      isLoading:false,
      person_units: [],
      person_unit:{},
      canCancel: [],
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      useTransition: false,
      isEditDistanceModalActive: false,
      isNullifyActive: false,
      showPdf: false,
      downloadLink: "",
      src:null,
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    initialize(){
      this.isLoading = true;
      return axios
          .get("/people/"+this.personId+"/distances.json")
          .then(res => {
            this.person_units = res.data
            this.person_units = this.person_units.map(personUnit => {
              var newPersonUnit = personUnit
              newPersonUnit["resolution_date"] = moment(newPersonUnit["resolution_date"] , ["YYYY-MM-DD","DD-MM-YYYY"]);
              newPersonUnit["start_date"] = moment(newPersonUnit["start_date"] , ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newPersonUnit;
            });
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })

    },
    execNullifyModal(id){
      this.$buefy.dialog.confirm({
        title: 'Anular Alejamiento',
        message: '¿Está seguro que desea <b>anular</b> el alejamiento? Esta acción lo dejará inválido. <br>' +
            '<b>*Debe reestablecer manualmente la fecha de fin del nombramiento asociado en caso de ser necesario</b>',
        confirmText: 'Anular Alejamiento',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/admin/person_units/" + id + "/nullify_distance.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Alejamiento anulado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                this.isLoading = false;
                this.isNullifyActive = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Alejamiento no pudo ser anulado</b><br>'+error.response.data["base"][0],
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })

    },
    execShowModal(row){
      this.selected = row.id;
      this.showName = "Distance"
      this.title = "Alejamiento"+" - "+this.person_name
      this.isShowModalActive = true;
    },
    execShowModalAppointment(row){
      this.selected = row.person_unit_id;
      if(row!== undefined){
        if(row.associated_appointment_establishment==="ACADEMICOS") {
          this.showName = "AppointmentAcademic"
          this.title = "Nombramiento Estamento Académico"+" - "+this.person_name
        }
        else if (row.associated_appointment_establishment==="ADMINISTRATIVOS") {
          this.showName = "AppointmentAdministrative"
          this.title = "Nombramiento Estamento Administrativo"+" - "+this.person_name
        }
        else if (row.associated_appointment_establishment==="HONORARIOS") {
          this.showName = "AppointmentFeeContract"
          this.title = "Nombramiento Estamento Honorarios"+" - "+this.person_name
        }
        else {
          this.showName = "AppointmentHours"
          this.title = "Nombramiento Estamento Profesor Horas Clases"+" - "+this.person_name
        }
      }
      else{
        this.showName = "AppointmentAdministrative"
      }
      //console.log("APPOINTMENT "+ this.showName)
      this.isShowAppointmentModalActive = true;
    },
    execEditDistanceModal(row){
      this.selected = row.id;
      this.showName = "Distance"
      this.title = "Alejamiento"+" - "+this.person_name
      //console.log("APPOINTMENT "+ this.showName)
      this.isEditDistanceModalActive = true;
    },
    execEditModal(row){
      this.selected = id;
      let person_unit = this.getEstablishment(id)
      if(person_unit.establishment!==undefined){
        if(person_unit.establishment.name==="ACADEMICOS") {
          this.formName = "AppointmentAcademicForm"
          this.title = "Editar Nombramiento Estamento Académico"+" - "+this.person_name
        }
        else if (person_unit.establishment.name==="ADMINISTRATIVOS") {
          this.formName = "AppointmentAdministrativeForm"
          this.title = "Editar Nombramiento Estamento Administrativo"+" - "+this.person_name
        }
        else {
          this.formName = "AppointmentHoursForm"
          this.title = "Editar Nombramiento Estamento Profesor Horas Clase"+" - "+this.person_name
        }
      }
      else{
        this.formName = "AppointmentAdministrativeForm"

      }
      //console.log("APPOINTMENT "+ this.showName)
      this.isEditModalActive = true;
    },
    getEstablishment(selected){
      return this.person_units.find(o => o.id === selected);
    },
    // yyyy/mm/dd -> dd/mm/yyyy
    formatDate,
    searchDistanceDate(row, input){
      return searchDate(row.start_date,input)
    },
    searchResolutionDate(){
      return searchDate(row.resolution_date,input)
    },
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            this.showPdf = true
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    downloadFile(fileId){
      this.isLoading = true;

      if (fileId == null){
        this.isLoading = false;
        return;
      }

      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(res => {
            let blob = new Blob([res.data], {
              type: "application/pdf",
            })
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = res.headers["content-disposition"]
                .split(";")[1]
                .split('"')[1];
            link.click();
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento fue descargado con exito',
              type: 'is-success',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
          .catch(e => {
            console.log(e)
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento no se pudo descargar',
              type: 'is-danger',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
    },
  },
  watch:{
    isAddModalActive: function () {
      if (!this.isAddModalActive) {
        this.initialize()
      }
    },
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
    isShowModalActive: function () {
      if (!this.isShowModalActive) {
        this.initialize()
      }
    },
    isEditDistanceModalActive: function () {
      if (!this.isEditDistanceModalActive) {
        this.initialize()
      }
    },
    isNullifyActive: function () {
      if (!this.isNullifyActive){
        this.initialize()
      }
    }

  },
}
</script>

