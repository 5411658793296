<template>
  <section>
    <div class="container">
      <h3 class="is-size-3 has-text-weight-bold">Listado Usuarios y Roles</h3>
      <div class="box">
        <b-table
            :data="people"
            :paginated= true
            per-page="10"
            striped
            hoverable
            sticky-header
            height="1000"
            :narrowed=true
            :key="keyupdates"
            :current-page="currentPage"
            :pagination-simple=false
            pagination-position="both"
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            default-sort="product.title"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"
            :opened-detailed="defaultOpenedDetails"
            detailed
            detail-key="id"
            @details-open="(row) => $buefy.toast.open(`Expandiendo ${row.name}`)"
            :show-detail-icon="showDetailIcon">
          <b-table-column
              field="run_completo"
              label="Rut"
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.run_completo!=="0" ? props.row.run_completo : ""}}
            </template>
          </b-table-column>
          <b-table-column
              field="name"
              label="Nombres"
              searchable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.name}}
            </template>
          </b-table-column>

          <b-table-column
              field="corporate_mail"
              label="Correo corporativo"
              searchable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.corporate_mail}}
            </template>
          </b-table-column>
          <b-table-column
            field= 'can_create_user'
            label="Habilitado para tener usuario"
            sortable>
          <template v-slot="props">
            <b-checkbox disabled :value="props.row.can_create_user">
            </b-checkbox>
          </template>
        </b-table-column>
        <b-table-column
            field= 'has_user'
            label= 'Posee usuario'
            sortable>
          <template v-slot="props">
            <b-checkbox disabled :value="props.row.has_user">
            </b-checkbox>
          </template>
        </b-table-column>
        <b-table-column
            field= 'user_enabled'
            label= 'Usuario habilitado'
            sortable>
          <template v-slot="props">
            <b-checkbox disabled :value="props.row.user_enabled">
            </b-checkbox>
          </template>
        </b-table-column>
          <b-table-column
              label="Acciones"
              v-slot="props">


            <b-button type="is-primary"
                      :disabled="!props.row.can_create_user"
                      icon-left="plus"
                      @click="addModal(props.row)">
            </b-button> 

            <b-button type="is-info"
                      icon-left="pen"
                      :disabled="props.row.rols.length==0"
                      @click="editModal(props.row)">
          </b-button>
          <b-button type="is-success"
                      icon-left="eye"
                      :disabled="props.row.rols.length==0"
                      @click="showModal(props.row)">
          </b-button>  
          </b-table-column>
          <template slot="detail" slot-scope="props">
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <p> Roles </p>
                  <p v-if="props.row.rols.length==0">
                    Sin rol asignado
                  </p>
                  <p v-for="(object, i) in props.row.rols" > 
                     {{object.start_date_table}} {{object.internal_position.name || "a"}}
                   </p>

                </div>
              </div>
            </article>
          </template>

        </b-table>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
      </div>
    </div>
    <b-modal
        v-model="show"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        width="100%"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal-roles
                                :id="id"
                                :complete_name="complete_name"
                                :complete_rut="complete_rut"
                                :corporate_mail="corporate_mail"
                                :queryType="queryType"
                                :modal-header="header"
                                :rol="roles"
                                 style="width: 70vw"
                                 @addRole="addRole"
                                 @editRole="editRole"
                                @close="props.close"></modal-roles>
      </template>
    </b-modal>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import ModalRoles from '../sdt_service/ModalRoles.vue';
export default {
  name: "sdt_rols",
  components:{
    ModalRoles,
  },
  data(){
    return {
      selected: null,
      people: [],
      currentPage: 1,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      isLoading: false,
      canCancel: ['escape'],
      no_filters: true,
      formattedDate: "",
      show: false,
      edit: false,
      complete_rut: "",
      complete_name: "",
      corporate_mail: "",
      add: false,
      personId: "",
      queryType: "",
      id: "",
      roles: null,
      person: "",
      header: "",
      keyupdates: 0,
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    initialize(){
      this.isLoading = true
      return axios
          .get("/admin/sdt_rols.json")
          .then(res => {
            this.people = res.data.people
            this.people = this.people.map(person => {
              var newPerson = person
              if(newPerson["rols"]){
                newPerson["rols"] = newPerson["rols"].map(rol =>{
                  var newRol = rol;
                  if(newRol["start_date"]!==null){
                    newRol["start_date"] = new Date(moment(rol.start_date).format('MM/DD/YYYY'));
                    newRol["start_date_table"] = moment(rol.start_date).format('MM/DD/YYYY');
                  }
                  if(newRol["end_date"]!==null){
                    newRol["end_date"] = new Date(moment(rol.end_date).format('MM/DD/YYYY'));
                    newRol["end_date_table"] = moment(rol.end_date).format('MM/DD/YYYY');
                  }
                  return newRol;
                })                    
              }
              return newPerson;
            });
            this.isLoading = false
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
          })
    },
    showModal(row){
      this.isLoading = true
      this.id = row.id
      this.header = "Ver Rol"
      this.queryType = "show"
      this.complete_rut = row.run_completo
      this.corporate_mail = row.corporate_mail
      this.complete_name = row.name
      this.roles = row.rols
      this.show = true
      this.isLoading = false
    },
    addModal(row){
      this.isLoading = true
      this.id = row.id
      this.header = "Añadir Rol"
      this.queryType = "create"
      this.complete_rut = row.run_completo
      this.corporate_mail = row.corporate_mail
      this.complete_name = row.name
      this.roles = row.rols
      this.show = true
      this.isLoading = false
    },
    editModal(row){
      this.isLoading = true
      this.id = row.id
      this.header = "Editar Rol"
      this.queryType = "update"
      this.complete_rut = row.run_completo
      this.corporate_mail = row.corporate_mail
      this.complete_name = row.name
      this.roles = row.rols
      this.show = true
      this.isLoading = false
    },
    editRole(data){
      var id = data[1];
      var rol = data[0];
      var newRol = rol;
      if(newRol["start_date"]!==null){
        newRol["start_date"] = new Date(moment(rol.start_date).format('MM/DD/YYYY'));
        newRol["start_date_table"] = moment(rol.start_date).format('MM/DD/YYYY');
      }
      if(newRol["end_date"]!==null){
        newRol["end_date"] = new Date(moment(rol.end_date).format('MM/DD/YYYY'));
        newRol["end_date_table"] = moment(rol.end_date).format('MM/DD/YYYY');
      }
      this.keyupdates = this.keyupdates+1;
      var elemento = this.people.find((elemento) => elemento.id === id);
      elemento.rols = elemento.rols.filter((elemento) => elemento.id !== rol.id);
      elemento.rols.push(rol);
      this.people = this.people.filter(elemento => elemento.id !== id);
      this.people.push(elemento);
      this.keyupdates = this.keyupdates+1;
    },
    addRole(data){
      var id = data[1];
      var rol = data[0];
      var newRol = rol;
      if(newRol["start_date"]!==null){
        newRol["start_date"] = new Date(moment(rol.start_date).format('MM/DD/YYYY'));
        newRol["start_date_table"] = moment(rol.start_date).format('MM/DD/YYYY');
      }
      if(newRol["end_date"]!==null){
        newRol["end_date"] = new Date(moment(rol.end_date).format('MM/DD/YYYY'));
        newRol["end_date_table"] = moment(rol.end_date).format('MM/DD/YYYY');
      }
      this.keyupdates = this.keyupdates+1;
      var elemento = this.people.find((elemento) => elemento.id === id);
      elemento.rols.push(rol);
      this.people = this.people.filter(elemento => elemento.id !== id);
      this.people.push(elemento);
      this.keyupdates = this.keyupdates+1;

    },
    formatDate2(date){
      if (date!=null && date!=="" && date!==undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
  },
  watch: {
    add: function () {
      if (!this.add) {
        this.initialize()
      }
    },
    edit: function () {
      if (!this.edit) {
        this.initialize()
      }
    },
  }
}
</script>
