<template>
  <section>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column">
            <b-field label="Tipo de evento">
              <b-input :value="event.event_type!==undefined ? event.event_type.name : '' " type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="N° documento">
              <b-input :value="event.decree" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha documento">
              <b-input :value="formatDate(event.decree_date)" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Fecha desde">
              <b-input :value="formatDate(event.start_date)" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha hasta">
              <b-input :value="formatDate(event.end_date)" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Tipo de  documento">
              <b-input :value="(event.resolution_type!==undefined && event.resolution_type!==null) ? event.resolution_type.name : '' " type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns is-gapless">
          <div class="column">
            <b-field label="Documento">
              <b-input expanded readonly v-model="event.document_name" type="text"></b-input>
            </b-field>
          </div>
          <div class="column is-narrow">
            <br>
            <b-field v-if="event.can_view_pdf" label="  ">
              <b-button @click="viewFile(event.document_id)" icon-left="eye" type="is-success">ver</b-button>
              <b-button @click="downloadFile(event.document_id)" icon-left="download" type="is-info">Descargar</b-button>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Descripción">
              <b-input :value="event.description" type="textarea" readonly></b-input>
            </b-field>
          </div>
        </div>
      </section>
    </div>
    <footer class="modal-card-foot actions">
      <div class="has-text-right is-grouped">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
        <b-button class="is-primary"
                  v-if="event.can_edit"
                  @click="isEditModalActive = true">
          Editar
        </b-button>
      </div>
    </footer>
    <b-modal
        v-model="isEditModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <event-form :person-id="event.person_id"
                          :id="event.id"
                          query-type="update"
                          :modal-header="'Editar Evento - '+person_name"
                          @close="props.close">
        </event-form>
      </template>
    </b-modal>
    <b-modal
        v-model="showPdf"
        :can-cancel="true"
        :destroy-on-hide="true"
        aria-modal
        aria-role="dialog"
        has-modal-card
        trap-focus
        :width="2000"
    >
      <template #default="props">
        <pdf_view
            :src="src"
            @close="props.close"
        ></pdf_view>
      </template>

    </b-modal>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import EventForm from "../life_record/modal_event_form.vue"
import {formatDate} from "../../packs/utilities";
import Pdf_view from "../performances/pdf_view.vue";

export default {
  name: "event",
  props:[
    "id","modalHeader", "person_name"
  ],
  components: {
    Pdf_view,
    EventForm
  },
  data(){
    return {
      isLoading:false,
      event:{},
      isEditModalActive:false,
      showPdf: false,
      src:null,
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.isLoading = true;
      return axios
          .get("/admin/events/" + this.id + ".json")
          .then(res => {
            this.event = res.data
            this.isLoading = false;
          }).catch(error => {
            console.log(error)
            this.isLoading = false;
          })
    },
    formatDate,
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            this.showPdf = true
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    downloadFile(fileId){
      this.isLoading = true;

      if (fileId == null){
        this.isLoading = false;
        return;
      }

      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(res => {
            let blob = new Blob([res.data], {
              type: "application/pdf",
            })
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = res.headers["content-disposition"]
                .split(";")[1]
                .split('"')[1];
            link.click();
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento fue descargado con exito',
              type: 'is-success',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
          .catch(e => {
            console.log(e)
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento no se pudo descargar',
              type: 'is-danger',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
    },
  },
  watch: {
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
  }
}
</script>

