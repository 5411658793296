import { render, staticRenderFns } from "./PartnerEntitySubEntity.vue?vue&type=template&id=f1bf1834&scoped=true"
import script from "./PartnerEntitySubEntity.vue?vue&type=script&lang=js"
export * from "./PartnerEntitySubEntity.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1bf1834",
  null
  
)

export default component.exports