<template>
  <section>
    <div class="container">

        <b-tabs @input="changeTab" :destroy-on-hide="true" v-model="currentTab" position="is-centered" type="is-toggle">
          <b-tab-item v-for="item in items"
                      :value="item.content"
                      v-bind:key="item.content"
                      :label="item.tab"
                      :visible="(item.content ==='NullHousehold' || item.content ==='NullDistances') ? can_list_null === 'true' : true">
          </b-tab-item>
        </b-tabs>
        <!-- COMPONENTE AQUI -->

        <component v-bind:is="currentTabComponent" :person-id="personId" :can_create_household="can_create_household"
                   :person_name="person_name"></component>

        <!-- FIN COMPONENTE -->

    </div>
  </section>
</template>
<script>
import HouseholdAllowances from "../household_allowances"
import DistancedHouseholdAllowances from "./distanced_household_allowances"
import EventBus from "../../eventBus";
export default {
  name: "household_tabs",
  props:[
    'personId', 'can_create_household', 'can_list_null', 'person_name'
  ],
  data(){
    return {
      currentTab: 'HouseholdAllowances',
      items: [
        {tab: 'Asignaciones Familiares',content:'HouseholdAllowances'},
        {tab: 'Cese de Asignaciones', content: "DistancedHouseholdAllowances"},


      ]
    }
  },
  components:{
    HouseholdAllowances,
    DistancedHouseholdAllowances,

  },
  methods:{
    changeTab: function(value){
      this.currentTab = value
    },
    selectTab(tab){
      this.currentTab = tab
    }
  },
  computed: {
    currentTabComponent: function () {
      return this.currentTab
    }
  },
  mounted () {
    EventBus.$on('ALEJAMIENTO_CREADO', (payload) => {
      this.selectTab("DistancedHouseholdAllowances")
    })
  }
}
</script>