<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(verifyNameDuplication)">
        <div class="modal-card" style="width: 1080px" id="modal-event">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" id="modal-event2" ref="modalFunctionForm">
            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion">
                      {{ item[0] }}
                    </div>
                  </b-message>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Nombre*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input v-model="function_data.name" type="text" minlength="3" maxlength="255"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Nombre Alternativo">
                  <b-input v-model="function_data.alt_name" type="text" minlength="3" maxlength="255"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="¿Es única?*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <div class="columns">
                      <div class="column">
                        <b-radio-button v-model="function_data.uniq"
                                        :native-value="false"
                                        type="is-danger is-light is-outlined"

                        >
                          <b-icon icon="times-circle"></b-icon>
                          <span>No</span>
                        </b-radio-button>
                      </div>
                      <div class="column">
                        <b-radio-button v-model="function_data.uniq"
                                        :native-value="true"
                                        type="is-success is-light is-outlined"
                        >
                          <b-icon icon="check-circle"></b-icon>
                          <span>Si</span>
                        </b-radio-button>
                      </div>
                    </div>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="¿Es autoridad?*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <div class="columns">
                      <div class="column">
                        <b-radio-button v-model="function_data.is_authority"
                                        :native-value="false"
                                        type="is-danger is-light is-outlined"

                        >
                          <b-icon icon="times-circle"></b-icon>
                          <span>No</span>
                        </b-radio-button>
                      </div>
                      <div class="column">
                        <b-radio-button v-model="function_data.is_authority"
                                        :native-value="true"
                                        type="is-success is-light is-outlined"
                        >
                          <b-icon icon="check-circle"></b-icon>
                          <span>Si</span>
                        </b-radio-button>
                      </div>
                    </div>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="">
                  <template #label>
                    Función Padre
                    <b-tooltip
                        label="Solo completar este campo si la funcion forma parte de una jerarquia especifica dentro de la Facultad o algun departamento"
                        position="is-right"
                        size="is-medium"
                        multilined>

                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          size="is-small"
                          type="is-info">
                      </b-icon>
                    </b-tooltip>
                  </template>
                  <multiselect v-model="function_data.function_id"
                               :options="functions.map(type => type.id)"
                               :custom-label="opt => functions.find(x => x.id === opt).name"
                               placeholder="Seleccione función padre"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Seleccione nuevamente para deseleccionar"
                               :allow-empty="true">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                      No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input v-model="function_data.description" type="textarea" minlength="10" maxlength="1000"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit">Guardar </button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import EventBus from "../eventBus"
export default {
  name: "modal_function_form",
  props:[
    'queryType', 'personId', 'id', 'modalHeader'
  ],
  data(){
    return {
      function_data:{function_state_id: 1},
      errores_validacion:[],
      isLoading:false,
      functions: [],
    }
  },
  created() {
    this.isLoading=true;
    if (this.queryType === "create"){
      axios
          .get("/functions/new.json")
          .then(res=> {
            this.functions = res.data["functions"]
            this.isLoading = false
          }).catch(error => {
              console.log(error)
              this.isLoading=false;
            })
    }
    else if (this.queryType === "update"){
      axios
          .get("/functions/"+this.id+"/edit.json")
          .then(res => {
            this.function_data = res.data
            this.functions = res.data["functions"]
            this.isLoading = false
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })
    }
  },
  methods: {
    verifyNameDuplication(){
      this.isLoading= true;
      let id = null
      if(this.queryType==="update"){
        id = this.function_data.id
      }
      axios
          .get("/functions/find-name-coincidence.json", {
            params: {name: this.function_data.name, queryType: this.queryType,
              alt_name: this.function_data.alt_name, id: id}
          })
          .then(response =>{
            this.saveFunction()
          }).catch(error => {
        if(error.response.status === 409){ //se encuentra
          this.$buefy.dialog.confirm({
            hasIcon: true,
            type: "is-warning",
            title: "Advertencia",
            message: error.response.data["message"],
            confirmText: "Continuar y guardar",
            cancelText: "Cancelar",
            onConfirm: () => this.saveFunction(),
            onCancel: () => this.isLoading = false
          })
        }
        else if(error.response.status === 400){//bad request
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalFunctionForm.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al agregar/editar la función, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
        }
      })
    },
    saveFunction(){
      this.isLoading=true;
      let clean_function_data = this.function_data
      clean_function_data = this.deleteFunction(clean_function_data)
      if (this.queryType==="create"){
        axios
            .post("/functions.json", {
              function: clean_function_data
            })
            .then(response =>{
              this.errores_validacion = {};
              this.$buefy.dialog.alert({
                message: 'La función fue creada con éxito ',
                confirmText: 'Ok'
              })
              this.function_data = {}
              this.$emit('save', true)
              this.isLoading=false;
              this.errors = [];
              this.$emit('clicked')
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalFunctionForm.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al agregar la función, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.$emit('save', false)
          this.isLoading=false;
        })
      }
      else if (this.queryType==="update"){
        axios
            .put("/functions/" + this.id+".json", {
              function:  clean_function_data
            })
            .then(response => {
              this.$buefy.dialog.alert({
                message: 'La función fue editada con éxito ',
                confirmText: 'Ok'
              })
              this.errores_validacion = {};
              this.isLoading = false;
              this.function_data = {}
              this.isLoading=false;
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          let elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalFunctionForm.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al editar la función, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
        })
      }
    },
    formatDate(date){
      if(date != null && date!==""){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
    deleteFunction(function_data){
      delete function_data.functions
      return function_data
}
  }
}
</script>

