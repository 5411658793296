<template>
  <div>
    <section>
      <b-sidebar
          type="is-light"
          :fullheight=true
          v-model="open"
          id="sidebar-menu"

      >
        <div class="p-1">
          <div style="text-align: center;">
            <img style="height:4rem" src="/opc_2_color.png">
          </div>

          <b-menu>
            <b-menu-list label="Menu">
              <multiselect v-if="cargosActivos!=null && cargosActivos.length>0 && cargoActivoSeleccionado!=null"
                           v-model="cargoActivoSeleccionado"
                           :options="cargosActivos.map(type => type.id)"
                           :custom-label="opt => cargosActivos.find(x => x.id === opt).name"
                           @input="changeActiveProfile"
                           placeholder=""
                           selectLabel=""
                           selectedLabel=""
                           deselectLabel=""
                           :allow-empty="false">
                <template v-slot:noOptions>
                  No existen datos
                </template>
              </multiselect>
              <!--<b-menu-list>
                <b-menu-item v-if="isAuthorized('LifeRecord') || isAuthorized('Functions')">
                  <template slot="label" slot-scope="props">
                    <i class="fas fa-tools"></i> Hoja de Vida
                      <b-icon class="is-pulled-right" :icon="props.expanded ? 'sort-down' : 'sort-up'"></b-icon>
                  </template>
                  <b-menu-list v-if="isAuthorized('Functions') || isAuthorized('LifeRecord')">
                    <b-menu-item>
                      <template slot="label" slot-scope="props">
                        <i class="fas fa-user-tag"></i> Funciones
                        <b-icon class="is-pulled-right" :icon="props.expanded ? 'sort-down' : 'sort-up'"></b-icon>
                      </template>

                      <b-menu-list>
                        <b-menu-item v-if="isAuthorized('AdministrateFunctions')" href="/functions"
                                     icon="clipboard-list" label="Administrar Funciones"></b-menu-item>
                      </b-menu-list>
                      <b-menu-list>
                        <b-menu-item v-if="isAuthorized('NullifiedFunctions')" href="/functions/nullified-functions"
                                     icon="ban" label="Funciones Anuladas"></b-menu-item>
                      </b-menu-list>
                    </b-menu-item>
                    <b-menu-item
                    href="/life_record"
                    v-if="isAuthorized('LifeRecord')"
                    icon="tools"
                    label="Módulo Hoja de Vida">
                    </b-menu-item>
                  </b-menu-list>
                </b-menu-item>
              </b-menu-list>-->
              <b-menu-item v-if="isAuthorized('LifeRecord')" href="/life_record"
                                         icon="tools" label="Hojas de vida"></b-menu-item>
              <b-menu-item v-if="isAuthorized('Dashboard')" href="/dashboards"
                           icon="chart-line" label="Indicadores"></b-menu-item>

            </b-menu-list>
            <b-menu-list>
            <b-menu-item v-if="isAuthorized('Administrate') ||isAuthorized('Import') || isAuthorized('UsersManagement')">
                <template slot="label" slot-scope="props">
                  <i class="fas fa-tools"></i> Administración
                  <b-icon class="is-pulled-right" :icon="props.expanded ? 'sort-down' : 'sort-up'"></b-icon>
                </template>
              <b-menu-list v-if="isAuthorized('Administrate') || isAuthorized('UsersManagement')">
                <b-menu-item>
                  <template slot="label" slot-scope="props">
                    <i class="fas fa-users-cog"></i> Control de Acceso
                    <b-icon class="is-pulled-right" :icon="props.expanded ? 'sort-down' : 'sort-up'"></b-icon>
                  </template>
                  <b-menu-item href="/admin/permissions_list"
                               v-if="isAuthorized('Administrate')"
                               icon="id-badge"
                               label="Lista de Roles y Permisos"></b-menu-item>
                  <b-menu-item href="/admin/profiles_tabs"
                               v-if="isAuthorized('Administrate')"
                               icon="id-badge"
                               label="Asignación de roles"></b-menu-item>
                  <!--<b-menu-item v-if="isAuthorized('Administrate')" href="/permissions/profile-asignation"
                               icon="id-badge" label="Asignar perfiles"></b-menu-item>-->
                  <b-menu-item v-if="isAuthorized('UsersManagement')" href="/permissions/user-creation"
                               icon="users" label="Habilitación de usuarios"></b-menu-item>
                </b-menu-item>

                <b-menu-item v-if="isAuthorized('Administrate')" href="/administrate"
                             icon="database" label="Administración BD"></b-menu-item>
                <b-menu-item v-if="isAuthorized('Import')" href="/load_data/index"
                             icon="tools" label="Importación de datos"></b-menu-item>

                  <b-menu-item v-if="isAuthorized('Unit')" href="/admin/units"
                               icon="school" label="Unidades"></b-menu-item>

                <b-menu-item v-if="isAuthorized('Administrate')" href="/administrate/vime_configuration_index"
                           icon="wrench" label="Configuración VIME"></b-menu-item>
                <b-menu-item href="/admin/audit"
                             v-if="isAuthorized('Administrate')"
                             icon="history"
                             label="Auditoría"></b-menu-item>
                <b-menu-item href="/dashboards/data-control"
                             v-if="isAuthorized('Administrate')"
                             icon="spell-check"
                             label="Control de Datos"></b-menu-item>
                <b-menu-item href="/api-drive/first-access"
                             v-if="isAuthorized('Administrate')"
                             icon="share"
                             label="Acceso Api Drive"></b-menu-item>
<!--                <b-menu-item href=""-->
<!--                             v-if="isAuthorized('Administrate')"-->
<!--                             icon="sync"-->
<!--                             label="Sincronización de carpetas drive"></b-menu-item>-->
              </b-menu-list>

           </b-menu-item>
            </b-menu-list >
              <b-menu-list>
                  <b-menu-item v-if="isAuthorized('FirmaDigital')">
                      <template slot="label" slot-scope="props">
                          <i class="fas fa-signature"></i> Firma de Documentos
                          <b-icon class="is-pulled-right" :icon="props.expanded ? 'sort-down' : 'sort-up'"></b-icon>
                      </template>
                      <b-menu-list>
                          <b-menu-item href="/firma_digital_simple"
                                       icon="file-pdf"
                                       label="Firma Digital Simple">
                          </b-menu-item>
                      </b-menu-list>
                  </b-menu-item>
              </b-menu-list>
            <b-menu-list>
              <b-menu-item v-if="isAuthorized('DigitalAccreditation')">
                <template slot="label" slot-scope="props">
                  <i class="fas fa-qrcode"></i> Acreditación Digital
                  <b-icon class="is-pulled-right" :icon="props.expanded ? 'sort-down' : 'sort-up'"></b-icon>
                </template>
                <b-menu-list>
                  <b-menu-item v-if="isAuthorized('DigitalAccreditation')"
                    href="/digital-accreditation"
                               icon="qrcode"
                    label="Acreditación Digital"
                  >
                  </b-menu-item>
                  <b-menu-item v-if="isAuthorized('AccreditationControl')"
                    href="/digital-accreditation/control"
                    icon="tools"
                    label="Dashboard de Control"
                  >
                  </b-menu-item>
                </b-menu-list>
              </b-menu-item>
            </b-menu-list>
            <!-- Comment
            <b-menu-list>
            <b-menu-item v-if="isAuthorized('MyValidations')||isAuthorized('Dashboard')">
                <template slot="label" slot-scope="props">
                  <i class="fas fa-check-circle"></i> Herramientas de Validación
                  <b-icon class="is-pulled-right" :icon="props.expanded ? 'sort-down' : 'sort-up'"></b-icon>
                </template>
            <b-menu-item v-if="this.personId!=null && isAuthorized('MyValidations')" :href=toMyValidations()
                           icon="tasks" label="Mis Validaciones"></b-menu-item>
            <b-menu-item v-if="isAuthorized('Dashboard')" href="/validations"
                           icon="clipboard-check" label="Validaciones"></b-menu-item>
            </b-menu-item>
            </b-menu-list>-->
            <!-- Comment
            <b-menu-list>
              <b-menu-item v-if="isAuthorized('PersonShow')">
                <template slot="label" slot-scope="props">
                  <i class="fas fa-user-circle"></i> Perfil
                  <b-icon class="is-pulled-right" :icon="props.expanded ? 'sort-down' : 'sort-up'"></b-icon>
                </template>
                <b-menu-item v-if="isAuthorized('PersonShow') && this.personId!=null" :href=toMyData()
                               icon="book" label="Mi actividad académica"></b-menu-item>-->
                <!--TODO descomentar cuando se implemente
                <b-menu-item v-if="isAuthorized('MyLifeRecord') && this.personId!=null" :href=toMyLifeRecord()
                             icon="id-card" label="Mi hoja de vida"></b-menu-item>
                             -->
            <!-- Comment
                <b-menu-item v-if="isAuthorized('MyAcademicData') && this.personId!=null" :href=toMyAcademicData()
                             icon="user-circle" label="Mi perfil"></b-menu-item>
                <b-menu-item :href="toMyUnit()"
                             v-if="isAuthorized('UnitShow')"
                             icon="school"
                             label=" Mi unidad"></b-menu-item>


                </b-menu-item>
            </b-menu-list>
              -->



            <b-menu-list v-if="isAuthorized('Documents')">
              <b-menu-item>
                <template slot="label" slot-scope="props">
                  <i class="fas fa-folder"></i> Documentación
                  <b-icon class="is-pulled-right" :icon="props.expanded ? 'sort-down' : 'sort-up'"></b-icon>
                </template>

                <b-menu-list>
                  <b-menu-item v-if="isAuthorized('AddDocuments')" href="/documents/new"
                               icon="folder-plus" label="Agregar Documentos"></b-menu-item>
                  <b-menu-item v-if="isAuthorized('ListDocuments')" href="/documents/"
                               icon="search" label="Buscar Documentos"></b-menu-item>
<!--                  <b-menu-item v-if="isAuthorized('ListDocuments')" href="/documents/search-documents"
                               icon="search" label="Buscar Documentos"></b-menu-item>-->
                  <b-menu-item v-if="isAuthorized('DocumentsNullified')" href="/documents/nullified-documents"
                               icon="ban" label="Documentos Anulados"></b-menu-item>
                  <b-menu-item v-if="isAuthorized('ImportDocuments')" href="/documents/import-documents-view"
                               icon="file-import" label="Importación de Documentos"></b-menu-item>
<!--                  <b-menu-item v-if="isAuthorized('ListDocuments')" href="/documents/"
                               icon="folder-open" label="Listar Documentos"></b-menu-item>-->
                  <b-menu-item v-if="isAuthorized('ListDocuments')" href="/documents/documents-navigation"
                               icon="stream" label="Navegación de Documentos"></b-menu-item>
                  <b-menu-item v-if="isAuthorized('DocumentsControl')" href="/documents/control"
                               icon="history" label="Reportería"></b-menu-item>

                  <b-menu-item v-if="isAuthorized('ListPerformance')">
                    <template slot="label" slot-scope="props">
                      <i class="fas fa-list-alt"></i> Convenios de Desempeño
                      <b-icon class="is-pulled-right" :icon="props.expanded ? 'sort-down' : 'sort-up'"></b-icon>
                    </template>
                    <b-menu-list>
                      <b-menu-item href="/documents/performances"
                                   icon="search" label="Buscar Documentos"></b-menu-item>
                      <b-menu-item v-if="isAuthorized('AddDocuments')" href="/documents/new-performance"
                                   icon="file-upload" label="Agregar Documento de desempeño"></b-menu-item>
                    </b-menu-list>
                  </b-menu-item>
                </b-menu-list>
              </b-menu-item>

            </b-menu-list>



            <b-menu-list >

              <b-menu-item v-if="addAcademicActivityAuthorized()||isDataAuthorized()||isAuthorized('ExportAcademicActivity')">
              <template slot="label" slot-scope="props">
                    <i class="fas fa-book"></i> Actividad Académica
                    <b-icon class="is-pulled-right" :icon="props.expanded ? 'sort-down' : 'sort-up'"></b-icon>
                  </template>
              <b-menu-list v-if="addAcademicActivityAuthorized()">
                <b-menu-item>
                  <template slot="label" slot-scope="props">
                    <i class="fas fa-plus-circle"></i> Añadir Actividad
                    <b-icon class="is-pulled-right" :icon="props.expanded ? 'sort-down' : 'sort-up'"></b-icon>
                  </template>
                  <b-menu-item href="/projects/new"
                             v-if="isAuthorized('AddProject')"
                             icon="folder-open"
                             label="Proyectos"></b-menu-item>
                  <b-menu-item href="/publications/type"
                             v-if="isAuthorized('AddPublication')"
                             icon="file-alt" label="Publicaciones"></b-menu-item>
                  <!-- <b-menu-item>
                        <template slot="label" slot-scope="props">
                        <i class="fas fa-book-open"></i> Libros
                        <b-icon class="is-pulled-right" :icon="props.expanded ? 'sort-down' : 'sort-up'"></b-icon>
                        </template>
                        <b-menu-list>

                          <b-menu-item href="/books/new"
                                 v-if="isAuthorized('AddBook')"
                                 label="Nuevo Libro">
                          </b-menu-item>
                          <b-menu-item href="/inbooks/new?type=chapter"
                                   v-if="isAuthorized('AddInbook')"
                                    label="Nuevo Capítulo">
                          </b-menu-item>
                          <b-menu-item href="/inbooks/new?type=article"
                                   v-if="isAuthorized('AddInbook')"
                                   label="Nuevo Artículo">
                          </b-menu-item>
                      </b-menu-list>

                  </b-menu-item> -->
                  
                  <b-menu-item href="/patents/new"
                               v-if="isAuthorized('AddPatent')"
                               icon="lightbulb" label="Patentes"></b-menu-item>
                  <b-menu-item href="/disclosures/new"
                               icon="folder" label="Disclosures"></b-menu-item>
                  <!--
                  <b-menu-item href="/conferences/new"
                               v-if="isAuthorized('AddConference')"
                               icon="podcast" label="Conferencias"></b-menu-item>
                  -->

                  <b-menu-item href="/vime_activities/new"
                               v-if="isAuthorized('AddVimeActivity')"
                               icon="calendar" label="Vinculación con el medio">
                  </b-menu-item>
                  <!-- TODO DESCOMENTAR CUANDO SE MUESTREN PRODUCTOS
                  <b-menu-item href="/products/new"
                               v-if="isAuthorized('AddProduct')"
                               icon="lightbulb" label="Productos"></b-menu-item>
                   -->
                </b-menu-item>
              </b-menu-list>

              <b-menu-list v-if="isDataAuthorized()">
                <b-menu-item>
                  <template slot="label" slot-scope="props">
                    <i class="fas fa-database"></i> Ver Actividad
                    <b-icon class="is-pulled-right" :icon="props.expanded ? 'sort-down' : 'sort-up'"></b-icon>
                  </template>
                  <b-menu-item href="/projects"
                               v-if="isAuthorized('Project')"
                               icon="folder-open"
                               label="Proyectos"></b-menu-item>
                  <b-menu-item href="/publications"
                               v-if="isAuthorized('Publication')"
                               icon="file-alt" label="Publicaciones"></b-menu-item>
                  <b-menu-item href="/patents"
                               v-if="isAuthorized('Patent')"
                               icon="lightbulb" label="Patentes"></b-menu-item>
                  <b-menu-item href="/disclosures"
                               icon="folder" label="Disclosures"></b-menu-item>

                  <b-menu-item href="/vime_activities"
                               v-if="isAuthorized('IndexVimeActivities')"
                               icon="calendar" label="Vinculación con el medio">                  
                  </b-menu-item>
                  <b-menu-item href="/books"
                              v-if="isAuthorized('Book')"
                               icon="book" label="Libros y Secciones"></b-menu-item>
                  <!-- #TODO DESCOMENTAR CUANDO SE PUEDAN VER LOS PRODUCTOS
                  <b-menu-item href="/products"
                               v-if="isAuthorized('Product')"
                               icon="lightbulb" label="Productos"></b-menu-item>
                   -->
                </b-menu-item>
              </b-menu-list>

              <b-menu-list >
                  <b-menu-item href="/dashboards/get-unit-indicator"
                               icon="chart-bar" label="Reportería de Mi Unidad" />
              </b-menu-list>

              <b-menu-list v-if="isAuthorized('ExportAcademicActivity')" >
                  <b-menu-item href="/load-data/export/academic-activity"
                               icon="file-export" label="Exportación de datos"></b-menu-item>
              </b-menu-list>



              <b-menu-list v-if="isAuthorized('NullifiedActivity')">
                <b-menu-item href="/admin/nullified_activity">
                  <template slot="label" slot-scope="props">
                    <i class="fas fa-ban"></i> Actividades Anuladas
                  </template>
                </b-menu-item>
              </b-menu-list>
            </b-menu-item>

            </b-menu-list>
            <b-menu-list>
              <b-menu-item v-if="isAuthorized('AdvancedSearch')" href="/advanced_search"
                           icon="search" label="Búsqueda avanzada"></b-menu-item>
            </b-menu-list>
            <b-menu-list>
              <b-menu-item v-if="isAuthorized('IndexVimeActivities') || isAuthorized('IndexAgreements')">
                <template slot="label" slot-scope="props">
                  <i class="fas fa-globe"></i> Gestión VIME
                  <b-icon class="is-pulled-right" :icon="props.expanded ? 'sort-down' : 'sort-up'"></b-icon>
                </template>
                <b-menu-list>
                  <b-menu-item v-if="isAuthorized('IndexVimeActivities')" href="/vime_activities"
                               icon="calendar" label="Actividades"></b-menu-item>

                  <b-menu-item v-if="isAuthorized('IndexAgreements')" href="/agreements"
                               icon="handshake" label="Convenios"></b-menu-item>

                  <b-menu-item v-if="isAuthorized('IndexAgreements')" href="/agreements/nullified_view"
                               icon="ban" label="Convenios Anulados"></b-menu-item>
                </b-menu-list>
              </b-menu-item>
              <!--<b-menu-item v-if="isAuthorized('IndexGraduates')" href="/graduates"
                           icon="user-graduate" label="Egresados"></b-menu-item>-->
              <b-menu-item v-if="isAuthorized('ExternalPeople')" href="/admin/external_people_tabs"
                           icon="id-card" label="Personas externas"></b-menu-item>
              <b-menu-item v-if="isAuthorized('IndexPartnerEntities')" href="/partner_entities/index"
                           icon="industry" label="Entidades"></b-menu-item>
              <b-menu-item v-if="isAuthorized('ActiveAgreements')" href="/agreements/active"
                           icon="handshake" label="Convenios"></b-menu-item>

            </b-menu-list>
            <b-menu-list>
              <b-menu-item v-if="isDataSdtAuthorized() || isDataSdtTransactionAuthorized()">
                <template slot="label" slot-scope="props">
                  <i class="fas fa-database"></i> Proyectos EFUSACH
                  <b-icon class="is-pulled-right" :icon="props.expanded ? 'sort-down' : 'sort-up'"></b-icon>
                </template>

                <b-menu-list>
                  <b-menu-item v-if="isDataSdtAuthorized()">
                    <template slot="label" slot-scope="props">
                      <i class="fas fa-handshake"></i> Servicios
                      <b-icon class="is-pulled-right" :icon="props.expanded ? 'sort-down' : 'sort-up'"></b-icon>
                    </template>
                    <b-menu-item v-if="isAuthorized('AddSdtService')" href="/sdt_services/new"
                               icon="folder-open" label="Añadir servicio EFUSACH"></b-menu-item>

                    <b-menu-item v-if="isAuthorized('SdtServices')" href="/sdt_services"
                                 icon="database" label="Ver servicios EFUSACH"></b-menu-item>
                    <b-menu-item :href="toMySdtUnit()" v-if="isAuthorized('UnitSdt') &&
                                 cargosActivos.find(x => x.id === cargoActivoSeleccionado).name.includes('EFUSACH')  &&
                                 !cargosActivos.find(x => x.id === cargoActivoSeleccionado).name.includes('MONITOREO') "
                                  icon="school"
                                    label="Dashboard de servicios"></b-menu-item>
                    <b-menu-item :href="toMySdtUnit()" v-else-if="isAuthorized('UnitSdt') "
                      icon="chart-bar"
                      label="Reportería"></b-menu-item>
                    <b-menu-item href="/sdt_services/masive_upload"
                                 v-if="isAuthorized('MasiveUploadSdt')"
                                 icon="upload"
                                 label="Subida Masiva"></b-menu-item>
                    <!--<b-menu-item href="/admin/sdt_rols"
                               v-if="isAuthorized('RolsSdt')"
                               icon="users"
                               label="Usuarios y roles"></b-menu-item>
                  -->
                  </b-menu-item>
                </b-menu-list>


                <b-menu-list>
                  <b-menu-item v-if="isDataSdtTransactionAuthorized()">
                    <template slot="label" slot-scope="props">
                      <i class="fas fa-clipboard-list"></i> Transacciones
                      <b-icon class="is-pulled-right" :icon="props.expanded ? 'sort-down' : 'sort-up'"></b-icon>
                    </template>

                    <b-menu-item v-if="isAuthorized('AddTransactionsSdt')">
                      <template slot="label" slot-scope="props">
                        <i class="fas fa-file-invoice"></i> Añadir Egreso
                        <b-icon class="is-pulled-right" :icon="props.expanded ? 'sort-down' : 'sort-up'"></b-icon>
                      </template>
                      <b-menu-list>

                        <b-menu-item href="/sdt_transactions/new/item"
                               label="Bien">
                        </b-menu-item>
                        <b-menu-item href="/sdt_transactions/new/contract"
                               label="Contratación">
                        </b-menu-item>
                        <b-menu-item href="/sdt_transactions/new/service"
                               label="Servicio">
                        </b-menu-item>
                      </b-menu-list>
                    </b-menu-item>

                    <b-menu-item v-if="isAuthorized('AddTransactionsSdt')">
                      <template slot="label" slot-scope="props">
                        <i class="fas fa-clipboard-list"></i> Añadir Rendir Fondo
                        <b-icon class="boxes" :icon="props.expanded ? 'sort-down' : 'sort-up'"></b-icon>
                      </template>
                      <b-menu-list>

                        <b-menu-item href="/sdt_transactions/new/accountability-fund-item"
                               label="Bien">
                        </b-menu-item>
                        <b-menu-item href="/sdt_transactions/new/accountability-fund-service"
                               label="Servicio">
                        </b-menu-item>
                      </b-menu-list>
                    </b-menu-item>
                    <b-menu-item v-if="isAuthorized('AddTransactionsSdt')">
                      <template slot="label" slot-scope="props">
                        <i class="fab fa-stack-overflow"></i> Añadir Reembolso de Gastos
                        <b-icon class="boxes" :icon="props.expanded ? 'sort-down' : 'sort-up'"></b-icon>
                      </template>
                      <b-menu-list>

                        <b-menu-item href="/sdt_transactions/new/reimbursement-item"
                               label="Bien">
                        </b-menu-item>
                        <b-menu-item href="/sdt_transactions/new/reimbursement-service"
                               label="Servicio">
                        </b-menu-item>
                      </b-menu-list>
                    </b-menu-item>

                    <b-menu-item href="/sdt_surpluses"
                                 v-if="isAuthorized('IndexTransactionsSdt')"
                                 icon="database" label="Búsqueda por Entidades EFUSACH"></b-menu-item>
                    <b-menu-item :href="toReportsSdt()"
                                 v-if="isAuthorized('ReportingTransactionsSdt')"
                                 icon="chart-bar" label="Reportería"></b-menu-item>
                  </b-menu-item>
                </b-menu-list>
                <b-menu-list>
                  <b-menu-item href="/admin/sdt_audit"
                                 v-if="isAuthorized('AuditSdt')"
                                 icon="history"
                                 label="Control de datos"></b-menu-item>
                </b-menu-list>



                
              </b-menu-item>

            </b-menu-list>

            <b-menu-list v-if="isAuthorized('ExportPeopleId') || isAuthorized('PostulatedProjectsList')">
              <b-menu-item>
                <template slot="label" slot-scope="props">
                  <i class="fas fa-book"></i> Gestión I+D
                  <b-icon class="is-pulled-right" :icon="props.expanded ? 'sort-down' : 'sort-up'"></b-icon>
                </template>

                <b-menu-list>
                  <b-menu-item v-if="isAuthorized('ExportPeopleId')" href="/people/show-export-people-for-i-d"
                               icon="users" label="Exportar Académicos"></b-menu-item>
                  <b-menu-item v-if="isAuthorized('PostulatedProjectsList')" href="/projects/postulated"
                               icon="folder-open" label="Proyectos Postulados"></b-menu-item>
                  <b-menu-item v-if="isAuthorized('PostulatedNullified')" href="/projects/nullified-postulated"
                               icon="ban" label="Postulaciones Anuladas"></b-menu-item>
                  <!--<b-menu-item v-if="isAuthorized('ExportAcademicActivity')" href="/load-data/export/academic-activity"
                               icon="file-export" label="Exportación de datos"></b-menu-item>-->
                  <b-menu-item v-if="isAuthorized('IdIndicators')" href="/dashboards/i-d"
                               icon="search" label="Consultas I+D"></b-menu-item>
                  <b-menu-item href="/dashboards/data-control/i-d"
                               v-if="isAuthorized('IdIndicators')"
                               icon="spell-check"
                               label="Control Duplicados"></b-menu-item>
                  <b-menu-item href="/projects/compare-data"
                               v-if="isAuthorized('CompareAnidData')"
                               icon="list-ul"
                               label="Conexión con ANID"></b-menu-item>
                  <b-menu-item href="/patents/compare-data"
                               v-if="isAuthorized('CompareInapiData')"
                               icon="copyright"
                               label="Comparación con INAPI"></b-menu-item>
                </b-menu-list>
              </b-menu-item>

            </b-menu-list>

            <b-menu-list v-if="isAuthorized('ViewPostGraduate')">
              <b-menu-item href="/postgraduate" icon="graduation-cap" label="Postgrado"></b-menu-item>
            </b-menu-list>

            <b-menu-list v-if="isAuthorized('ActiveResearch')">
              <b-menu-item>
                <template slot="label" slot-scope="props">
                  <i class="fas fa-atom"></i> Búsqueda activa <br> de información
                  <b-icon class="is-pulled-right" :icon="props.expanded ? 'sort-down' : 'sort-up'"></b-icon>
                </template>

                <b-menu-list>
                  <b-menu-item v-if="isAuthorized('ActiveResearch')" :href=toMyOrcidSearch()
                               icon="orcid" icon-pack="fab" label="Por ORCID"></b-menu-item>

                </b-menu-list>
              </b-menu-item>

            </b-menu-list>
          </b-menu>
        </div>
        <footer class="footer" id="sidebar-footer">
          <b-menu-list label="Ayuda">
            <b-menu-item  href="https://docs.google.com/forms/d/e/1FAIpQLSfJUNG_z5XHt-IH4W6CvxF0k-glFAbQVdr2Ejgd-dDONsAZEQ/viewform?usp=sf_link"
                          icon="exclamation-circle" label="Reporte de errores"></b-menu-item>
              <b-menu-item  @click="showModal =  true"
                            icon="address-book" label="Contacto"></b-menu-item>
<!--            <b-menu-item v-if="isAuthorized('Tutorials')"  href="/tutorials"
                          icon="info-circle" label="Manual de usuario"></b-menu-item>-->
<!--              <b-menu-item v-if="isAuthorized('VideoTutorials')" href="/video_tutorials"
                           icon="play-circle" label="Video tutoriales"></b-menu-item>-->
          </b-menu-list>
        </footer>
      </b-sidebar>

      <b-button class="is primary is-small" @click="open = true" icon-left="bars"></b-button>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
      <b-modal
          v-model="showModal"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <contact-modal
              @close="props.close"
             ></contact-modal>
        </template>
      </b-modal>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import ContactModal from "./contact_modal.vue";
export default {
  components:{
    ContactModal,
  },
  data(){
    return{
      open: false,
      isLoading: false,
      cargoActivoSeleccionado:null,
      showModal: false,
    }
  },
  created(){
    this.isLoading=true;
    if(this.cargosActivos != null && this.cargosActivos.length == 1){
      this.cargoActivoSeleccionado = this.cargosActivos[0].id
    }
    else{
      this.cargoActivoSeleccionado = this.cargoActivo
    }
    this.isLoading=false;
  },
  props:[
      'userId','permisos','personId','cargosActivos','cargoActivo','unidadActiva'
  ],
  methods:{
    isAuthorized(nombre){
      var objeto = this.permisos.find(obj => obj.nombre === nombre)
      if (objeto!==undefined){
        return objeto.value;
      }
      else{
        return false
      }

    },
    isDataAuthorized(){
      return !!(this.isAuthorized('Project') || this.isAuthorized('Publication') ||
          this.isAuthorized('Product') || this.isAuthorized('Patent') || this.isAuthorized('Disclosure') || this.isAuthorized('Book'));

    },
    isDataSdtAuthorized(){
      return !!(this.isAuthorized('AddSdtService') || this.isAuthorized('SdtServices') || this.isAuthorized('UnitSdt')
          || this.isAuthorized('MasiveUploadSdt'));
    },
    isDataSdtTransactionAuthorized(){
      return !!(this.isAuthorized('AddTransactionsSdt') || this.isAuthorized('IndexTransactionsSdt') || this.isAuthorized('ReportingTransactionsSdt'));
    },
    toMyData(){
      return "/people/"+this.personId
    },
    toMyValidations(){
      return "/validations/user/"+this.userId
    },
    toMyUnitValidation(){
      return "/validations/my-unit/"
    },
    toMyUnit(){
      return "/admin/units/"+this.unidadActiva
    },
    toMySdtUnit(){
      return "/units/sdt/"+this.unidadActiva
    },
    toReportsSdt(){
      return "/sdt_transactions/reporting/"+this.unidadActiva
    },
    toMyLifeRecord(){
      return "/people/"+this.personId+"/my-life-record"
    },
    toMyAcademicData(){
      return "/people/"+this.personId+"/my-academic-data"
    },
    toMyOrcidSearch(){
      return "/people/"+this.personId+"/active-search/orcid"
    },
    addAcademicActivityAuthorized(){
      return !!(this.isAuthorized('AddProject') || this.isAuthorized('AddPublication') ||
          this.isAuthorized('AddProduct') || this.isAuthorized('AddPatent') || this.isAuthorized('AddDisclosure') || this.isAuthorized('AddBook') || this.isAuthorized('AddInbook') );
    },
    changeActiveProfile(){
      this.isLoading=true
      axios
          .post("/users/"+this.userId+"/change-profile", {
            active_profile: this.cargoActivoSeleccionado
          })
          .then(async response => {
            await window.location.reload(false);
            //window.location.href = "/#"
            this.isLoading = false;
          }).
          catch(error => {
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            this.isLoading=false;
          })
    }

  },
  computed:{

  }
}
</script>


