<template>
  <section>
    <div class="columns">
      <div class="column">
        <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
          <b-message type="is-danger" has-icon>
            <div v-for="(item,index) in errores_validacion" :key='index'>
              <div v-if="item">{{ item[0] }}</div>
            </div>
          </b-message>
        </div>
      </div>
    </div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveTransaction)">
        <div class="box"> 

          <b-steps
             v-model="activeStep"
            :animated="isAnimated"
            :rounded="isRounded"
            :has-navigation="hasNavigation"
            :icon-prev="prevIcon"
            :icon-next="nextIcon"
            :label-position="labelPosition"
            :mobile-mode="mobileMode">
            <b-step-item step="1" label="General" :clickable="isStepsClickable">
              <div class="box">
                <h1 class="title has-text-centered">General  [1/3]</h1>
                <div class="columns">
                  
                  <div class="column" v-if="unidades.length>0">
                    <b-field label="Unidad*">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <multiselect v-model="transaction.unit_id"
                                     :options="unidades.map(type => type.id)"
                                     :custom-label="opt => unidades.find(x => x.id == opt).name"
                                     placeholder="Seleccione Unidad"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel="Seleccionado"
                                     :disabled="!can_create_people"
                                     deselectLabel="No se puede deseleccionar"
                                     @select="getCodes"
                                     :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                              No se encontraron elementos.
                          </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Código de EFUSACH asociado*">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <multiselect v-model="transaction.sdt_surplus_id"
                                     :options="codes.map(type => type.id)"
                                     :custom-label="opt => codes.find(x => x.id == opt).code"
                                     placeholder="Seleccione código de EFUSACH"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel=""
                                     deselectLabel=""
                                     :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                              No se encontraron elementos.
                          </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  
                </div>
                <div class="columns">                
                  <div class="column" v-if="tipos_finanzas.length>0">
                    <b-field label="Categoría de contratación*">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <multiselect v-model="transaction.sdt_transaction_type_id"
                                     :options="tipos_finanzas.map(type => type.id)"
                                     :custom-label="opt => tipos_finanzas.find(x => x.id == opt).name"
                                     placeholder="Seleccione Categoría de contratación"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel="Seleccionado"
                                     deselectLabel="No se puede deseleccionar"
                                     @select="showOther"
                                     :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                              No se encontraron elementos.
                          </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Descripción de otro*" v-if="show_other == true" >                            
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <b-autocomplete v-model="transaction.other_id"
                                        :keep-first="keepFirst"
                                        :open-on-focus="openOnFocus"
                                        :data="other_types"
                                        field="description"
                                        empty="Vacio"
                                        placeholder="Ej: Tramitaciones legales"
                                        @typing="searchOtherTypes"
                                        @select="option => {if (option) {transaction.other_id = option.id} }"
                                        :clearable="clearable">
                          <template slot-scope="props">
                            {{ props.option.description }}
                          </template>
                        </b-autocomplete>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  
                </div>
              </div>
              
            </b-step-item>
            <b-step-item step="2" label="Persona Asociada" :clickable="isStepsClickable" >

              <div class="box"> 
                <h1 class="title has-text-centered"> Seleccionar Persona Asociada [2/3]</h1>
                <br>

                <h3 class="is-size-4 has-text-weight-bold has-text-centered">Búsqueda de personas</h3>
                <div class="columns">
                  <div class="column">
                    <b-field label="Primer Apellido">
                      <ValidationProvider :rules="{regex: /^[a-zA-ZáéíóúÁÉÍÓÚÑñ\s]*$/}" v-slot="{ errors }">
                        <b-input v-model="search_last_name" type="text"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Segundo Apellido">
                      <ValidationProvider :rules="{regex: /^[a-zA-ZáéíóúÁÉÍÓÚÑñ\s]*$/}" v-slot="{ errors }">
                        <b-input v-model="search_surname" type="text"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Nombres">
                      <ValidationProvider :rules="{regex: /^[a-zA-ZáéíóúÁÉÍÓÚÑñ\s]*$/}" v-slot="{ errors }">
                        <b-input v-model="search_name" type="text" expanded></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                        
                    </b-field>
                  </div>                

                </div>
                <div class="columns">
                  <div class="column is-3">
                    <b-field label="Rut">
                      <ValidationProvider rules="numeric" v-slot="{ errors }">
                        <b-input v-model="search_rut"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column is-1">
                    <b-field label="Dv">
                      <ValidationProvider rules="alpha_num|length:1" v-slot="{ errors }">
                        <b-input v-model="search_dv"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column has-text-right">          
                    <b-button
                              class="button right-button button-clear-filter"
                              icon-left="eraser"
                              @click="clean()">
                      Limpiar Filtros
                    </b-button>
                    <b-button type="is-info"
                              icon-left="search"
                              class="button right-button is-info"
                              @click="buscar_persona()">
                      Buscar Personas
                    </b-button>

                  </div>
                </div>

                <div class="columns" v-if="can_create_people == true">
                  <div class="column">                     
                    <b-button class="is-primary is-outlined is-pulled-right"  icon-left="plus" @click="addExternalPerson">
                      Añadir persona al sistema
                    </b-button>
                  </div>
                </div>

                <br>
                <hr class="dropdown-divider" aria-role="menuitem">
                <h3 class="is-size-4 has-text-weight-bold has-text-centered">Tabla de Resultados Personas Buscadas</h3>
                <br>

                <b-table
                  :data="people"
                  :paginated= true
                  per-page="20"
                  striped
                  hoverable
                  :current-page="currentPage"
                  :pagination-simple=false
                  pagination-position="bottom"
                  default-sort-direction="asc"
                  sort-icon="arrow-up"
                  sort-icon-size="is-small"
                  default-sort="product.title"
                  aria-next-label="Siguiente"
                  aria-previous-label="Anterior"
                  aria-page-label="Página"
                  aria-current-label="Página actual">
                  <b-table-column
                      field="index"
                      label="N°">
                    <template v-slot="props">
                      {{ people.indexOf(props.row) + 1 }}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field="integral"
                      label="Rut"
                      sortable
                      searchable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          placeholder=" Buscar"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.integral}}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field="name"
                      label="Nombre"
                      sortable
                      searchable
                  >
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          placeholder=" Buscar"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{props.row.name}}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field="last_name"
                      label="Primer Apellido"
                      sortable
                      searchable
                  >
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          placeholder=" Buscar"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{props.row.last_name}}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field="second_surname"
                      label="Segundo Apellido"
                      sortable
                      searchable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          placeholder=" Buscar"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{props.row.second_surname}}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field="corporate_mail"
                      label="Correo Corporativo"
                      searchable
                      sortable
                  >
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          placeholder=" Buscar"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{props.row.corporate_mail!==undefined ? props.row.corporate_mail : ""}}
                    </template>
                  </b-table-column>
                  <b-table-column
                      label="Acciones"
                      v-slot="props">
                    <b-field >
                      <p class="control">
                        <b-button class="is-primary" icon-right="user" @click="selectPerson(props.row.id)">Seleccionar</b-button>
                      </p>
                    </b-field>
                  </b-table-column>

                  <template slot="empty" slot-scope="props">
                    <div class="columns is-centered">
                      <div class="column is-6 is-centered has-text-centered">
                        <b-message type="is-danger">
                          No se encontraron resultados para datos ingresados.
                        </b-message>
                      </div>
                    </div>
                  </template>
                </b-table>
                <hr class="dropdown-divider" aria-role="menuitem">
                <br>
                <div class="columns">
                  <div class="column">
                    <b-field label="Persona asociada seleccionada*" >                            
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <b-input type="text" v-model="persona_sel"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>                
                </div>
              </div>
            </b-step-item>
            <b-step-item step="3" label="Documentos" :clickable="isStepsClickable">


              <div class="box"> 

                <h1 class="title has-text-centered">Adjuntar Documentos  [3/3]</h1>
                <br>
                <h2 class="has-text-centered new-h2">Documentación de boleta</h2>
                <br><br>



                <div class="columns">
                  <div class="column">
                    <b-field label="Código de boleta*" >
                      <ValidationProvider rules="required|max:20" v-slot="{errors}">
                        <b-input type="text" maxlength="20" placeholder="Ej: 002651" v-model="transaction.code"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Fecha de pago*">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <b-datepicker
                            v-model="transaction.payment_date"
                            icon="calendar-alt"
                            trap-focus
                            locale="es-ES"
                            editable
                            placeholder="Formato de Fecha: dd/mm/aaaa">
                        </b-datepicker>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Monto Bruto Total*" >                            
                      <ValidationProvider rules="required|numeric|positive|max:11" v-slot="{errors}">
                        <b-input type="text" maxlength="11" placeholder="Ej: 500000" v-model="transaction.amount"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>


                </div>

                  <br><br>


                <div class="columns">
                  <div class="column is-centered has-text-centered">
                    <b-message class="is-inline-block is-centered" type="is-warning" title="¡Importante!" has-icon aria-close-label="Close message">
                        Extensiones de archivo permitido: PDF, DOC, DOCX, XLSX, XLS.
                        <br />
                        Tamaño máximo de archivo permitido: <b> 10 MB</b>
                    </b-message>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-field class="file is-primary is-centered" :class="{'has-name': !!file2}">
                        <b-upload v-model="file2" class="file-label" @input="AddFile">
                            <span class="file-cta">
                                <b-icon class="file-icon" icon="upload"></b-icon>
                                <span class="file-label">Subir boleta de honorario</span>
                            </span>
                            <span class="file-name" v-if="file2">
                                {{ file2.name }}
                            </span>
                        </b-upload>
                      </b-field>
                      <span class="validation-alert has-text-centered is-centered">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>

                <br>
                

                
                <div class="columns">
                  <div class="column is-centered has-text-centered">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field class="file is-primary is-centered" :class="{'has-name': !!file1}">
                          <b-upload v-model="file1" class="file-label" @input="AddFile">
                              <span class="file-cta">
                                  <b-icon class="file-icon" icon="upload"></b-icon>
                                  <span class="file-label">Subir formulario pago honorario</span>
                              </span>
                              <span class="file-name" v-if="file1">
                                  {{ file1.name }}
                              </span>
                          </b-upload>
                        </b-field>
                        <span class="validation-alert has-text-centered is-centered">{{ errors[0] }}</span>
                      </ValidationProvider>
                  </div>
                </div>
                <br>
                <div class="columns">
                  <div class="column has-text-centered">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field class="file is-primary is-centered" :class="{'has-name': !!file3}">
                          <b-upload v-model="file3" class="file-label" @input="AddFile">
                              <span class="file-cta">
                                  <b-icon class="file-icon" icon="upload"></b-icon>
                                  <span class="file-label">Subir contrato</span>
                              </span>
                              <span class="file-name" v-if="file3">
                                  {{ file3.name }}
                              </span>
                          </b-upload>
                        </b-field>
                        <span class="validation-alert has-text-centered is-centered">{{ errors[0] }}</span>
                      </ValidationProvider>
                  </div>
                </div>
              </div>



            </b-step-item>
            
            <template
                #navigation="{previous, next}">
              <div class="columns is-pulled-right" >
                <div class="column">
                  <b-button
                      outlined
                      icon-left="chevron-left"
                      :disabled="previous.disabled"
                      @click.prevent="previous.action">
                  </b-button>
                  <b-button
                      outlined
                      icon-right="chevron-right"
                      :disabled="next.disabled"
                      @click.prevent="next.action">
                  </b-button>

                </div>
              </div>
            </template>
          </b-steps>
          <br>
          <br>
          <br>
          <div class="columns">
            <div class="column">
              <div class="is-pulled-left">
                <button v-if="modalCancel" class="button left-button" type="button" @click="$emit('close')">Cerrar</button>
              </div>
              <div class="is-pulled-right">
                <b-button class="button is-primary" icon-left="save" native-type="submit" @click="checkValidation()">Guardar transacción</b-button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

    <b-modal
        v-model="isCreateModal"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-sdt-people-create
            query-type="create"
            modal-header="Agregar datos personales"
            @clicked="onClickChild"
            @close="props.close"></modal-sdt-people-create>
      </template>
    </b-modal>

  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import ModalSdtPeopleCreate from "../ModalSdtPeopleCreate.vue"
export default {
  name: "NewTransactionContratacionForm",
  components:{ModalSdtPeopleCreate},
  data() {
    return {
      keepFirst: false,
      hasNavigation: true,
      openOnFocus: false,
      clearable: false,
      errores_validacion: {},
      transaction:{},
      tipos_finanzas: [],
      procesos: [],
      proceso: null,
      tipo: null,
      unidades:[],
      codes: [],
      categorias_finanzas: [],
      category_transaction:null,
      documentos:[],
      isLoading:false,
      other_types:[],
      files: [],
      isDisabled: true,
      is_validated:false,
      show_other: false,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      activeStep: 0,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      tipos: [],
      currentPage: 1,
      people: [],
      person_selected: null,
      persona_sel: null,
      search_rut: "",
      search_dv: "",
      search_name: "",
      search_last_name: "",
      search_surname: "",
      file1: null,
      file2: null,
      file3: null,
      isCreateModal: false,
      filesRecibidos: [],
      canCancel: ['escape'],
      can_create_people: false,
    }
  },
  watch: {
    documentos: {
      handler(newFiles) {
        // Aquí actualizamos el arreglo 'documentos' cuando se seleccionan nuevos archivos
        this.files = newFiles.map(file => {
          const existingDocumento = this.files.find(doc => doc.file.name === file.name);
          if (existingDocumento) {
            return {
              file: file,
              amount: existingDocumento.amount || null,
            };
          } else {
            return {
              file: file,
              amount: null,
            };
          }
        });
      },
      deep: true
    }
  },
  props:[
      'modalHeader','userId','queryType','transactionId','modalCancel', "unidadPrincipal", 'hideHeader',
  ],
  async created(){
    await this.getTransactionTypes();
    this.transaction.unit_id = this.unidadPrincipal.id;
    await this.getCodes();
    
    if (this.queryType=='update'){
      await this.getTransaction();
      await this.getDocuments();
    }
  },
  methods:{
    async saveTransaction(){
      let vm = this;
      await vm.checkValidation();
      if (vm.is_validated){
        vm.isLoading=true;
        const formData = new FormData();
        vm.files.forEach((documento, index) => {
          formData.append(`file${index}`, documento);
          formData.append(`typeFile${index}`, vm.tipos[index].id);
          if(vm.queryType=='update'){
            formData.append(`originalFiles${index}`, vm.filesRecibidos[index].id); 
          }
        });        
        formData.append('transaction',JSON.stringify(vm.transaction));
        if (vm.queryType=="update"){
          axios
            .put("/sdt_transactions/"+vm.transaction.id+".json", formData)
            .then((res) => {
              vm.isLoading = false;
              this.$buefy.dialog.alert({
                message: "La rendición de finanzas fue actualizado con éxito.<br/>",
                confirmText: "Ok",            
              });
              res.data.payment_date = moment(res.data.payment_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
              vm.$emit("clickedUpdate",res.data);
              vm.$emit('close')
            })
            .catch(e => {
              vm.errores_validacion = e.response.data
              vm.isLoading=false;
          });   
        }
        else{
          axios
          .post("/sdt_transactions.json", formData)
          .then((res) => {
            vm.isLoading = false;
            this.$buefy.dialog.alert({
              message: "La transacción fue guardada correctamente <br/>",
              confirmText: "Ok",            
            });
            window.location.href = '/sdt_transactions/'+res.data["id"]
            vm.isLoading=false;
          })
          .catch(e => {
            vm.errores_validacion = e.response.data
            vm.isLoading=false;
        });   


        }

      }


    },
    async getTransactionTypes(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/sdt_transactions/new/contract.json")
        .then(res => {
          vm.tipos_finanzas = res.data.tipos_finanzas;
          vm.unidades = res.data.unidades; 
          vm.can_create_people = res.data.can_create_people;
          vm.tipos = res.data.tipos_documentos;
          vm.transaction.proceso = res.data.proceso;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    getCodes() {
      let vm = this;
      vm.isLoading=true;      
      axios.get("/sdt_surpluses/unit-surplus/"+vm.transaction.unit_id+".json")
        .then(async (res) => {
          vm.transaction.sdt_surplus_id = null;
          vm.codes = res.data;          
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    onDeleteFile() {
        this.file = null;
    },
    searchOtherTypes(name){
      if(name == null   || name.length == 0){
        this.position = null
      }
      if (name.length<3) {
        this.partner_entities = []
        return
      }
      let vm = this;
      vm.isLoading=true;
      axios
          .get("/sdt_transaction_types/types-search.json", { params: {
            description: name,
            type: vm.transaction.sdt_transaction_type_id
          } })
          .then(res => {
            vm.other_types = res.data;
            vm.isLoading = false;
          })
          .catch(error => {
            console.log(error)
            vm.isLoading=false;
          })
    },

    async checkValidation() {
      this.is_validated = false;
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar los campos a rellenar.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
      else{
        this.is_validated = true;
      }
    },
    getTotalFileSize(){
      let totalSize= 0;
      if (this.file !=null) {
        totalSize = this.file.size; 
      }
      return Math.round(totalSize*100/1000000)/100;
    },
    showOther(){
          
      if(this.tipos_finanzas.find(x => x.id == this.transaction.sdt_transaction_type_id).name.includes("OTRAS ") || this.tipos_finanzas.find(x => x.id == this.transaction.sdt_transaction_type_id).name.includes("OTROS ") ){
        this.show_other = true;
      }
      else
      {
        this.show_other = false;
      }

    },
    async getTransaction(){
      let vm = this;
      vm.isLoading=true;
      await axios
          .get("/sdt_transactions/"+this.transactionId+".json")
          .then(res => {
            vm.transaction = res.data;
            vm.transaction.payment_date = new Date(moment(vm.transaction.payment_date).format('MM/DD/YYYY'));
            if(vm.transaction.partner_entity != "")
            {
              vm.entidad_name = vm.transaction.partner_entity
            }
            if(vm.transaction.other_id != ""){
              if(vm.tipos_finanzas.length>0){
                vm.transaction.sdt_transaction_type_id = vm.tipos_finanzas.find(tipo => tipo.name.toUpperCase() === vm.transaction.sdt_transaction_type.toUpperCase()).id;
              }
              vm.show_other = true;
            }            
            if (vm.transaction.person != ""){
              vm.persona_sel = vm.transaction.person.person_name + " - RUT:  "+vm.transaction.person.run_integral
            }
            vm.isLoading=false;
          })
          .catch(e => {
            console.log(e);
            vm.isLoading=false;
          });
    },
    async getDocuments() {
      let vm = this;
      vm.isLoading=true;
      await axios.get("/sdt_transactions/documents/"+vm.transactionId+".json")
        .then(res => {
          let filesRec = res.data.documents;
          vm.file1 = filesRec[0];
          vm.file2 = filesRec[1];
          vm.file3 = filesRec[2];
          vm.files = [vm.file1, vm.file2, vm.file3];
          vm.filesRecibidos = res.data.documents;
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    AddFile(){
      this.files = [this.file1, this.file2, this.file3];
    },
    clean(){
      this.isLoading = true
      this.search_surname = ""
      this.search_dv = ""
      this.search_last_name = ""
      this.search_name = ""
      this.search_rut = ""
      this.isLoading = false
    },

    buscar_persona(){
      this.isLoading = true
      if(this.search_rut==="" && this.search_dv ==="" && this.name ==="" && this.search_last_name ==="" && this.search_surname ===""){
        return this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Debe llenar algún campo para realizar una búsqueda',
          type: 'is-warning',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
      else{
        return axios.get('/sdt_transactions/search-by-rut-names.json', {
          params: {
            rut: this.search_rut,
            dv: this.search_dv,
            names: this.search_name,
            last_name: this.search_last_name,
            surname: this.search_surname
          }
        }).then(response=>{
          this.people = response.data
          if(this.people.length === 0){
            this.isLoading=false;
            return this.$buefy.dialog.alert({
              title: 'Error',
              message: 'No se encontraron resultados para su búsqueda',
              type: 'is-warning',
              hasIcon: true,
              icon: 'exclamation-triangle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          }          
          this.isLoading=false;

            })
            .catch(error => {
              console.log("error")
              console.log(error.response)
              this.errores_validacion = error.response.data
              console.log(this.errores_validacion)
              this.isLoading=false;
        })
      }
    },

    selectPerson(id){
      this.person_selected = this.people.find(x => x.id == id)
      this.transaction.person_id = this.person_selected.id
      this.persona_sel = this.person_selected.name +" "+ this.person_selected.last_name + " - RUT:  "+this.person_selected.integral 
    },

    calculoMontos(){
      if (!isNaN(this.transaction.amount)) {
        if (this.transaction.amount - this.totalAmount > 0) {
          this.estado = "Monto pendiente a declarar: " + (this.transaction.amount - this.totalAmount);
        } else if (this.transaction.amount - this.totalAmount == 0) {
          this.estado = "Monto total declarado "
        } else {
          this.estado = "Monto excedente declarado: " + (this.totalAmount - this.transaction.amount);
        }
      }
    },

    addExternalPerson(){
      this.isLoading= true
      this.isCreateModal = true
      this.isLoading = false
    },
    onClickChild(value){
      value.integral = value.run_integral;
      this.people.push(value);
    },



  }
}
</script>

<style scoped>

  .right-button{
    margin-left: auto;
  }
  .left-button{
    margin-right: auto;
  }

  .new-h2 {
    font-size: 24px;
    font-weight: bold;
  }


</style>