<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(verify_names)">
        <div class="modal-card" style="width: auto" id="modal-event">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body">
            <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
              <b-message type="is-danger" has-icon>
                <div v-for="(item,index) in errores_validacion" :key='index'>
                  {{ item[0] }}
                </div>
              </b-message>
            </div>
            <div class="columns">
              <div class="column">
                <b-field :label="getNameRequired('CORTO') ? 'Nombre Corto' :'Nombre Corto*'">
                  <ValidationProvider :rules="entityFullName.length > 0 ? '' :'required'" v-slot="{ errors }">
                    <b-input type="text" maxlength="255"
                             placeholder="Ingrese el nombre de la entidad"
                             :value="entityName!==undefined ? entityName : ''"
                             v-model="entityName"
                             :has-counter=false>
                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field :label="getNameRequired('COMPLETO') ? 'Nombre Completo' :'Nombre Completo*'">
                  <ValidationProvider :rules="entityName.length > 0 ? '' :'required'" v-slot="{ errors }">
                    <b-input type="text" maxlength="255"
                             placeholder="Ingrese el nombre completo de la entidad"
                             :value="entityFullName!==undefined ? entityFullName : ''"
                             v-model="entityFullName"
                             :has-counter=false>
                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
<!--              <div class="column">

                <b-field label="Nombre*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input type="text" maxlength="255"
                             placeholder="Ingrese el nombre de la entidad"
                             :value="entityName!==undefined ? entityName : ''"
                             v-model="entityName"
                             :has-counter=false>
                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>

              </div>-->
              <div class="column is-3">
                <b-field label="Rut">
                  <b-input placeholder="ej: 12345678" type="number" min="0" :value="entityRut" v-model="entityRut"></b-input>
                </b-field>
              </div>
              <div class="column is-1">
                <b-field label="DV">
                  <b-input type="text" maxlength="1" :value="entityDv" v-model="entityDv"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input type="textarea" maxlength="255"
                             placeholder="Ingrese brevemente la descripción de la entidad"
                             :value="entityDescription!==undefined ? entityDescription : ''"
                             v-model="entityDescription"
                             :has-counter=false>
                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Email">
                  <ValidationProvider rules="email" v-slot="{ errors }">
                    <b-input  type="text"
                              maxlength="255"
                              placeholder="Ingrese el email de la entidad"
                              :value="entityEmail!==undefined ? entityEmail : ''"
                              v-model="entityEmail"
                              :has-counter=false>
                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Teléfono">
                  <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                    <b-input
                        placeholder="Ingrese un número de teléfono"
                        :value="entityPhone!==undefined ? entityPhone : null"
                        v-model="entityPhone">
                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Ciudad">
                  <ValidationProvider rules="" v-slot="{errors}">
                    <b-input type="text"
                             maxlength="255"
                             placeholder="Ingrese la ciudad de la entidad"
                             :value="entityCity!==undefined ? entityCity : ''"
                             v-model="entityCity"
                             :has-counter=false>
                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Dirección">
                  <ValidationProvider rules="" v-slot="{errors}">
                    <b-input type="text"
                             maxlength="255"
                             placeholder="Ingrese la dirección de la entidad"
                             :value="entityDirection!==undefined ? entityDirection : ''"
                             v-model="entityDirection"
                             :has-counter=false>
                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="País*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-autocomplete
                        :value="entityNationality!==undefined ? entityNationality : ''"
                        v-model="entityNationality"
                        placeholder="e.g. Chile"
                        :keep-first="keepFirst"
                        :open-on-focus="openOnFocus"
                        :data="filteredDataObjNationality"
                        field="name"
                        @select="option => (selected = option)"
                        :clearable="clearable">
                    </b-autocomplete>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns" v-if="isVisibleByNationality">
              <div class="column">
                <b-field label="Región*" >
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-autocomplete
                        :value="entityRegion!==undefined ? entityRegion : ''"
                        v-model="entityRegion"
                        placeholder="e.g. Metropolitana"
                        :keep-first="keepFirst"
                        :open-on-focus="openOnFocus"
                        :data="filteredDataObjRegion"
                        field="name"
                        @input="loadCommunes(getRegionIdByRegionName(entityRegion))"
                        @select="option => (selected = option)"
                        :clearable="clearable">
                    </b-autocomplete>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Comuna*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-autocomplete
                        :value="entityCommune!==undefined ? entityCommune : ''"
                        v-model="entityCommune"
                        placeholder="e.g. Providencia"
                        :keep-first="keepFirst"
                        :open-on-focus="openOnFocus"
                        :data="filteredDataObjComune"
                        field="name"
                        @select="option => (selected = option)"
                        :clearable="clearable">
                    </b-autocomplete>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Rubro económico">
                  <b-autocomplete
                      :value="entityEconomicActivity!==undefined ? entityEconomicActivity : ''"
                      v-model="entityEconomicActivity"
                      placeholder="e.g. Cultivo de maíz"
                      :keep-first="keepFirst"
                      :open-on-focus="openOnFocus"
                      :data="filteredDataObj"
                      field="name"
                      @select="option => (selected = option)"
                      :clearable="clearable">
                  </b-autocomplete>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Código postal">
                  <ValidationProvider v-slot="{ errors }">
                    <b-input
                        placeholder="Ingrese código postal"
                        maxlength="9"
                        :has-counter=false
                        :value="entityPostalCode!==undefined ? entityPostalCode : null"
                        v-model="entityPostalCode">
                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Link*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input
                        placeholder="https://link.com"
                        maxlength="255"
                        :has-counter=false
                        :value="entityLink!==undefined ? entityLink : null"
                        v-model="entityLink">
                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de entidad*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect
                        v-model="entityType"
                        :options="entity_types_data.map(type => type.id)"
                        :custom-label="opt => entity_types_data.find(x => x.id === opt).name"
                        placeholder="Seleccione un tipo de entidad"
                        selectLabel="Presione para seleccionar"
                        selectedLabel="Seleccionado"
                        deselectLabel=""
                        :allow-empty="false">
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="¿Es entidad pública?">
                  <div class="columns">
                    <div class="column">
                      <b-radio-button v-model="entityIsPublic"
                                      :native-value="false"
                                      type="is-danger is-light is-outlined"

                      >
                        <b-icon icon="times-circle"></b-icon>
                        <span>No</span>
                      </b-radio-button>
                    </div>
                    <div class="column">
                      <b-radio-button v-model="entityIsPublic"
                                      :native-value="true"
                                      type="is-success is-light is-outlined"
                      >
                        <b-icon icon="check-circle"></b-icon>
                        <span>Si</span>
                      </b-radio-button>
                    </div>
                  </div>

                </b-field>
              </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-lefts">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit" :disabled="isDisabled">Agregar</button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import axios from 'axios'
export default {
  name: 'modal_partner_entity',
  props:[
    'queryType','id','modalHeader'],
  data(){
    return {
      errores_validacion: {},
      // variables controladoras
      isLoading:true,
      isFullPage: true,
      isSubmiting: false,
      keepFirst: false,
      openOnFocus: false,
      selected: null,
      clearable: false,
      isDisabled: false,

      // Para almacenamiento temporal
      nationalities_data: [],
      regions_data: [],
      communes_data: [],
      economic_activities_data: [],
      entity_types_data: [],
      nationality_name: '',
      region_name: '',
      commune_name: '',
      economic_activity_name: '',
      entity_type_name: '',
      entityFullName: "",

      // para el envío de datos
      entityInfo:{
        name: '',
        description: '',
        email: '',
        phone: null,
        established: null,
        direction: '',
        city: '',
        is_public: false,
        nationality_id: null,
        region_id: null,
        commune_id: null,
        economic_activity_id: null,
        entity_type_id: null,
        postal_code: null,
        url: null,
        run_attributes:{
          rut: null,
          dv: null
        }
      },
    }
  },
  computed:{
    // Filtros y visibilidad
    filteredDataObj() {
      return this.economic_activities_data.filter(option => option.name.toLowerCase().includes(this.economic_activity_name.toLowerCase()))
    },
    filteredDataObjComune() {
      return this.communes_data.filter(option => option.name.toLowerCase().includes(this.commune_name.toLowerCase()))
    },
    filteredDataObjRegion() {
      return this.regions_data.filter(option => option.name.toLowerCase().includes(this.region_name.toLowerCase()))
    },
    filteredDataObjNationality() {
      return this.nationalities_data.filter(option => option.name.toLowerCase().includes(this.nationality_name.toLowerCase()))
    },
    isVisibleByNationality(){
      return this.nationality_name.toUpperCase() === 'CHILE'
    },

    // Para el control de los atributos
    entityName:{
      get: function(){
        return this.entityInfo.name
      },
      set: function(newName){
        this.entityInfo.name = newName
      }
    },
    entityRut:{
      get: function(){
        return this.entityInfo.run_attributes.rut
      },
      set: function(newRut){
        this.entityInfo.run_attributes.rut = newRut
      }
    },
    entityDv:{
      get: function(){
        return this.entityInfo.run_attributes.dv
      },
      set: function(newDv){
        this.entityInfo.run_attributes.dv = newDv
      }
    },
    entityDescription:{
      get: function(){
        return this.entityInfo.description
      },
      set: function(newDescription){
        this.entityInfo.description = newDescription
      }
    },
    entityPostalCode:{
      get: function(){
        return this.entityInfo.postal_code
      },
      set: function(newCode){
        if (newCode){
          this.entityInfo.postal_code = newCode
        }

      }
    },
    entityLink:{
      get: function(){
        return this.entityInfo.url
      },
      set: function(newLink){
        if (newLink){
          this.entityInfo.url = newLink
        }

      }
    },
    entityEmail:{
      get: function(){
        return this.entityInfo.email
      },
      set: function(newEmail){
        this.entityInfo.email = newEmail
      }
    },
    entityPhone:{
      get: function(){
        return this.entityInfo.phone
      },
      set: function(newPhone){
        this.entityInfo.phone = parseInt(newPhone, 10)
      }
    },
    entityDirection:{
      get: function(){
        return this.entityInfo.direction
      },
      set: function(newDirection){
        this.entityInfo.direction = newDirection
      }
    },
    entityCity:{
      get: function(){
        return this.entityInfo.city
      },
      set: function(newCity){
        this.entityInfo.city = newCity
      }
    },
    entityNationality:{
      get: function(){
        return this.nationality_name
      },
      set: function(newNationality){
        if(newNationality.toUpperCase() !== 'CHILE'){
          this.region_name = ""
          this.commune_name = ""
          this.economic_activity_name = ""
        }
        this.nationality_name = newNationality
      }
    },
    entityRegion:{
      get: function(){
        return this.region_name
      },
      set: function(newRegion){
        this.region_name = newRegion
      }
    },
    entityCommune:{
      get: function(){
        return this.commune_name
      },
      set: function(newCommune){
        this.commune_name = newCommune
      }
    },
    entityEconomicActivity:{
      get: function(){
        return this.economic_activity_name
      },
      set: function(newEconomicActivity){
        this.economic_activity_name = newEconomicActivity
      }
    },
    entityType:{
      get: function(){
        return this.entity_type_name
      },
      set: function(newEntityType){
        this.entity_type_name = newEntityType
      }
    },
    entityIsPublic:{
      get: function(){
        return this.entityInfo.is_public
      },
      set: function(newEntityIsPublic){
        this.entityInfo.is_public = newEntityIsPublic
      }
    },

    // Validaciones en los campos del formulario
    checkSubmiting: {
      get: function(){
        return this.isSubmiting
      },
      set: function(newState){
        this.isSubmiting = newState
      }
    }
  },
  beforeMount(){
    // Se ejecutarán antes de renderizar la página
    this.getNationalities()

  },
  methods:{
    // Consultas al backend
    getEntity(){
      axios.get('/partner_entities/show/'+this.entity_id+'.json')
          .then( response =>{
            this.entityName = response.data.entity.name
            this.entityDescription = response.data.entity.description
            this.entityEmail = response.data.entity.email
            this.entityPhone = response.data.entity.phone
            this.entityDirection = response.data.entity.direction
            this.entityCity = response.data.entity.city
            if (response.data.entity.entity_nationality != null){
              this.entityNationality = response.data.entity.entity_nationality
            }
            this.entityRegion = response.data.entity.entity_region
            this.entityCommune = response.data.entity.entity_commune
            this.entityEconomicActivity = response.data.entity.entity_economic_activity
            this.entityType = response.data.entity.entity_type_id
            this.entityIsPublic = response.data.entity.is_public
            this.entityRut = (response.data.entity.rut == null) ? null : response.data.entity.rut.rut
            this.entityDv = (response.data.entity.rut == null) ? null : response.data.entity.rut.dv
            if (this.entityRegion != null)
            {
              this.loadCommunes(this.getRegionIdByRegionName(this.entityRegion))
            }
            this.isLoading=false
          })
          .catch(e =>{
            console.log(e)
            this.isLoading=false
          })
    },
    getNationalities(){
      axios.get('/nationalities.json')
          .then( response =>{
            this.isLoading = true
            this.nationalities_data = response.data
            this.getRegions()
          })
          .catch(e => {
            this.isLoading = false
            console.log(e)
          })
    },
    getRegions(){
      axios.get("/admin/regions.json")
          .then(response =>{
            this.regions_data = response.data
            this.getEconomicActivities()
          })
          .catch(e =>{
            this.isLoading = false
            console.log(e)
          })
    },
    /*getCommunes(){
        axios.get("/admin/communes.json")
        .then(response =>{
            this.communes_data = response.data
        })
        .catch(e =>{
            console.log(e)
        })
    },*/
    getEconomicActivities(){
      axios.get("/economic_activities.json")
          .then(response =>{
            this.economic_activities_data = response.data
            this.getEntityTypes()
          })
          .catch(e =>{
            this.isLoading = false
            console.log(e)
          })
    },
    getEntityTypes(){
      axios.get("/admin/entity_types.json")
          .then(response =>{
            this.entity_types_data = response.data
            if(this.edit_case){
              this.getEntity()
            }
            else{
              this.isLoading = false
            }
          })
          .catch(e =>{
            console.log(e)
            this.isLoading=false
          })
    },
    // FUnciones transformadoras de Nombre a Id y viceversa
    getNationalityIdByNationalityName(nationality_name){
      var id = 0
      this.nationalities_data.forEach(function(element){
        if (element.name === nationality_name){
          id = element.id
        }
      })
      return id
    },
    getRegionIdByRegionName(region_name){
      var id = null
      this.regions_data.forEach(function(element){
        if (element.name === region_name){
          id = element.id
        }
      })
      return id
    },
    getCommuneIdByCommuneName(commune_name){
      var id = null
      this.communes_data.forEach(function(element){
        if (element.name === commune_name){
          id = element.id
        }
      })
      return id
    },
    getEconomicActvityIdByEconomicActivityName(economic_activity_name){
      var id = null
      this.economic_activities_data.forEach(function(element){
        if (element.name === economic_activity_name){
          id = element.id
        }
      })
      return id
    },
    getEntityTypeIdByEntityTypeName(entity_type_name){
      var id = 0
      this.entity_types_data.forEach(function(element){
        if (element.name === entity_type_name){
          id = element.id
        }
      })
      return id
    },
    back(){
      this.isLoading=true
      window.location.href="/partner_entities/index"
    },
    // Envío de datos al backend
    async save_entity(){
      this.isLoading=true
      this.checkSubmiting = true
      const isValid = await this.$refs.observer.validate();
      if (!isValid){
        this.checkSubmiting = false
        return this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Se encontraron errores, revisar formato y datos requeridos en formulario',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

      }

      if (this.getNationalityIdByNationalityName(this.nationality_name) == 0){
        this.isLoading = false
        this.disabled = false
        return this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Se encontraron errores en campo País, por favor revisar que exista',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }

      this.entityInfo.nationality_id = this.getNationalityIdByNationalityName(this.nationality_name)
      if(this.nationality_name.toUpperCase() === 'CHILE'){
        this.entityInfo.region_id = this.getRegionIdByRegionName(this.region_name)
        this.entityInfo.commune_id = this.getCommuneIdByCommuneName(this.commune_name)
        this.entityInfo.economic_activity_id = this.getEconomicActvityIdByEconomicActivityName(this.economic_activity_name)
      }
      else{
        this.entityInfo.region_id = null
        this.entityInfo.commune_id = null
        this.entityInfo.economic_activity_id = null
      }

      if(this.entityInfo.run_attributes.rut == ""  || this.entityInfo.run_attributes.dv == "")
      {
        this.entityInfo.run_attributes.rut = null
        this.entityInfo.run_attributes.dv = null
      }

      this.entityInfo.entity_type_id = this.entity_type_name
      this.entityInfo.full_name = this.entityFullName;
      if (this.entityInfo.full_name.length == 0 && this.entityInfo.name.length == 0){
        this.isLoading = false
        this.disabled = false
        return this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Por favor, ingrese al menos uno de estos dos campos: Nombre Corto o Nombre Completo',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }

      this.entityInfo.partner_entity_id = this.id

      axios.post('/admin/partner_entities.json', this.entityInfo)
          .then( response =>{
            this.errores_validacion = {};
            this.$buefy.dialog.alert({
              title: "Entidad creada con éxito",
              message: "La entidad ha sido creada sin ningún problema.",
              onConfirm: () => {
                this.checkSubmiting = false
                this.isLoading=false
                this.$emit('clicked',response.data)
                this.$emit('close')
              }
            })
            this.isLoading=false
          })
          .catch(e => {
            this.isLoading = false
            this.errores_validacion = e.response.data;
            //this.checkEmptyFields()
            this.$buefy.dialog.alert({
              message: "Faltan algunos campos o presentan errores de formato. Por favor revisar",
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              onConfirm: () => {
              }
            })
            this.isLoading=false
          })


    },
    loadCommunes(region_id){
      if( region_id!==null && region_id!==undefined && region_id!==""){
        this.isLoading=true;
        axios
            .get("/regions/"+region_id+"/communes.json")
            .then(res => {
              this.communes_data = res.data["communes"];
              this.isLoading = false;
            })
            .catch(error => {
              console.log(error)
              this.isLoading=false;
            })
      }
      else{
        this.communes = []
      }
    },
    getNameRequired(type){
      let retorno = true;
      if(type=="CORTO"){
        if(this.entityFullName.length>0){
          retorno = false;
        }
      }
      if(type=="COMPLETO"){
        if(this.entityName.length>0){
          retorno = false;
        }
      }
      if(this.entityFullName.length>0 && this.entityName.length>0){
        retorno = true;
      }
      return retorno;
    },
    verify_names(){
      this.isLoading= true;
      axios
          .get("/partner_entities/find_name_coincidence.json", {
            params: {name: this.entityName, full_name: this.entityFullName,
              id: this.entity_id, queryType: "create"}
          })
          .then(response =>{
            this.save_entity()
            this.isLoading=false;
          }).catch(error => {
        if(error.response.status === 409){ //se encuentra
          this.isLoading = false
          this.$buefy.dialog.confirm({
            hasIcon: true,
            type: "is-warning",
            title: "Advertencia",
            message: error.response.data["message"],
            confirmText: "Continuar y guardar",
            cancelText: "Cancelar",
            onConfirm: () => this.save_entity(),
            onCancel: () => this.isLoading = false
          })
        }
        else if(error.response.status === 400){//bad request
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalPersonForm.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al agregar la entidad, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
        }
      })
    },
  }

  // Instrucciones para hacer a rubro económico obligatorio
  /*
  1.- Pegar estas lineas al lado de la etiqueta b-field:
      :type="{ 'is-danger': checkEntityEconomicActivity }"
      :message="{ 'Seleccione un rubro económico.': checkEntityEconomicActivity }"

  2.- Descomentar las líneas (1) y (2)
  */
}
</script>