<template>
  <section>
    <Activities :objectId='objectId' :hideUpdate="true" :currentYear="currentYear" :min-year="minYear" :max-year="maxYear" :change-filter="changeFilter"/>
  </section>
</template>
<script>
import Activities from "./activities.vue";
import Agreements from "./agreements.vue";
export default {
    name: 'Vime',
    props:[
        'objectId',
        'componentType',
        'currentYear',
        'minYear',
        'maxYear',
        'changeFilter'
    ],
    data(){
        return {
            type: "is-toggle",
            size: "is-medium",
            position: "is-centered",
            expanded: false
        }
    },
    components:{
        Activities,
        Agreements
    }
}
</script>