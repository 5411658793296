<template>
  <section>

    <div class="columns is-centered">
      <div class="column">

        <b-message type="is-info" has-icon>
          Este Proyecto solo puede ser <b>editado</b> o <b>anulado</b> por quien agregó estos datos al sistema. Si hay un error en la información por favor ponerse en contacto con la persona propietaria. 
          Si el dato que ve no corresponde a usted, puede <b>eliminar</b> su participación en la sección <b>Participantes</b>, y este dato dejará de estar visible.
        </b-message>

      </div>
    </div>

    <div class="columns is-centered">
      <div class="column">
        <div class="has-text-right">
          <b-button v-if="project.can_edit && project.academic_activity_state_id == 1" type="is-info"
                    icon-left="edit"
                    @click="editProject">
            Editar Proyecto
          </b-button>
          <b-button v-if="(project.is_creator || cargoPrincipal.name == 'ANALISTA I+D FING') && project.academic_activity_state_id == 1" type="is-danger"
                    icon-left="ban"
                    @click="execNullifyModal">
            Anular Proyecto
          </b-button>
        </div>
        <hr class="dropdown-divider" aria-role="menuitem">
        <div class="columns">
          <div class="column">
            <b-field label="Nombre">
              <b-input readonly v-model="project.name" type="text"></b-input>
            </b-field>
          </div>
        </div>

        <div class="columns">

          <div class="column">
            <b-field label="Tipo de Proyecto">
              <b-input readonly v-model="project.project_type" type="text"></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Código">
              <b-input readonly type="text" v-model="project.code"></b-input>
            </b-field>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <div class="field">
              <b-field label="Año de adjudicación">
                <b-input readonly v-model="project.award_year"></b-input>
              </b-field>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <b-field label="Fecha de inicio">
                <b-input readonly v-model="project.start_date"></b-input>
              </b-field>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <b-field label="Fecha de termino">
                <b-input readonly v-model="project.end_date"></b-input>
              </b-field>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-checkbox disabled v-model="project.public_contract">
              ¿Es Contrato Público?
            </b-checkbox>
          </div>
          <div class="column">
            <b-checkbox disabled v-model="project.multidisciplinary">
              ¿Es Multidisciplinario?
            </b-checkbox>
          </div>
          <div class="column">
            <b-checkbox disabled v-model="project.international_agreement">
              ¿Tiene Acuerdo Internacional?
            </b-checkbox>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <div class="field">
              <b-field label="Resumen">
                <b-input readonly
                         type="textarea"
                         minlength="10"
                         maxlength="500"
                         v-model="project.description">
                </b-input>
              </b-field>
            </div>
          </div>
        </div>

        <h3 class="is-size-3 has-text-weight-bold">Palabras clave</h3>
        <b-field label="Palabras clave">
          <b-taginput
              disabled
              v-model="project.key_words_attributes"
              autocomplete
              :allow-new="true"
              field="name"
              icon="tag"
              placeholder="Palabras claves">
          </b-taginput>
        </b-field>

<!--
        <h3 class="is-size-3 has-text-weight-bold">Líneas de Investigación</h3>
        &lt;!&ndash; FORMULARIO LINEAS DE INVESTIGACION &ndash;&gt;
        <div class="columns">
          <div class="column">
            <b-field label="Líneas de investigación">
              <b-taginput
                  disabled
                  v-model="project.research_lines_attributes"
                  type="is-primary is-light"
                  autocomplete
                  :allow-new="false"
                  field="name"
                  icon="tag"
                  placeholder="Líneas de investigación">
              </b-taginput>
            </b-field>
          </div>
        </div>
-->




      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

    <b-modal
        :active="isComponentModalActive"
        width="90%"
        :can-cancel="canCancel"
        aria-modal>
      <template #default="props">
        <new-project-form modal-cancel="true"
                          :cargo-principal="cargoPrincipal"
                          modal-header="Editar Proyecto"
                          query-type="update" v-bind:project-id="project.id" v-bind:project="project" @close="closeModal"></new-project-form>
      </template>
    </b-modal>

  </section>

</template>

<script>
import newProjectForm from './NewProjectForm.vue';
import axios from "axios";
export default {
  name: "ShowGeneralTab",
  components:{ newProjectForm },
  props:['project','cargoPrincipal'],
  data(){
    return{

      isLoading:false,
      isComponentModalActive:false,
      cardLayout:false,
      canCancel: ['escape']

    }
  },
  methods:{
    editProject(){
      //window.location.href = "/projects/"+project_id+"/edit"
      this.isComponentModalActive = true;
    },
    closeModal(){
      this.isComponentModalActive = false;
    },
    execNullifyModal(){
      this.$buefy.dialog.confirm({
        title: 'Anular Proyecto',
        message: '¿Está seguro que desea <b>anular</b> el proyecto? Esta acción lo dejará inválido.<br>',
        confirmText: 'Anular Proyecto',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/projects/" + this.project.id + "/nullify_project.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Proyecto anulado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                if (document.referrer == ''){
                  window.location.href = history.back()
                }
                else{
                  window.location.href = document.referrer
                }
                this.isLoading = false;
                this.isNullifyActive = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Proyecto no pudo ser anulado</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },
  }

}
</script>

<style scoped>

</style>