<template>
  <section>
    <b-tabs position="is-centered" v-model="activeTab" >
      
            <b-tab-item label="Libros" icon="book">
              <books-table v-bind:libros="this.libros" v-bind:unit-id="objectId" :hide-update="true" :cargo-principal="cargoPrincipal" @updateProjects="updateBooks"></books-table>
            </b-tab-item>
            <b-tab-item label="Artículo" icon="bookmark">

              <sections-table v-bind:secciones="this.articles" :hide-update="true" :cargoPrincipal="cargoPrincipal" @updateSections="updateSections"></sections-table>
            </b-tab-item>
            <b-tab-item label="Capítulo" icon="file">

              <sections-table v-bind:secciones="this.chapters" :hide-update="true" :cargoPrincipal="cargoPrincipal" @updateSections="updateSections"></sections-table>
            </b-tab-item>
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
            <b-loading :is-full-page="true" v-model="isLoadingBook" :can-cancel="false"></b-loading>
            <b-loading :is-full-page="true" v-model="isLoadingInook" :can-cancel="false"></b-loading>
    </b-tabs>

         
  </section>
</template>
<script>
import axios from "axios";
import BooksTable from "../books/BooksTable.vue";
import SectionsTable from "../books/SectionsTable.vue";
import moment from "moment";
export default {
  props:[
    'objectId','componentType', 'cargoPrincipal','currentYear','minYear','maxYear', 'changeFilter', 'hideUpdate'
  ],
  data(){
    return{
      libros:[],
      secciones:[],
      articles: [],
      chapters: [],
      isLoading:false,
      isLoadingInook: false,
      isLoadingBook: false,
      activeTab: 0,
      year: new Date().getFullYear(),
    }
  },
  components:{
    BooksTable, SectionsTable
  },
  created(){
    //this.years = this.generateArrayOfYears();
    this.getBooks();
    this.getSections();

  },
  methods:{
    changeTab: function(value){
      this.currentTab = value
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    getBooks(){
      this.isLoadingBook=true;
      var url = "";
      if (this.componentType == "person"){
        url = "/people/"+this.objectId+"/books.json"
      }
      else if (this.componentType=="unit"){
        url = "/admin/units/"+this.objectId+"/books.json"
      }
      if(this.hideUpdate == true){
        this.year = 'MyActivity'
      }
      axios
        .get(url,{params:{
            year_f: this.year,
            minYear: this.minYear,
            maxYear: this.maxYear
        }})
        .then(res => {
          
          this.libros = res.data;
          this.libros = this.libros.map(libro => {
            var libro = libro
            libro["publication_date"] = moment(libro.publication_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            return libro;
          });
          this.isLoadingBook=false;
        })
        .catch(e => {
          console.log(e);
          this.isLoadingBook=false;
        });
    },
    getSections(){
      let vm = this;
      vm.isLoadingInook=true;
      var url = "";
      if (vm.componentType == "person"){
        url = "/people/"+this.objectId+"/sections.json"
      }
      else if (vm.componentType=="unit"){
        url = "/admin/units/"+this.objectId+"/sections.json"
      }
      if(this.hideUpdate == true){
        vm.year = 'MyActivity'
      }
      axios
        .get(url, {params:{
          year_f: vm.year,
            minYear: vm.minYear,
            maxYear: vm.maxYear
        }})
        .then(res => {
          
          vm.secciones = res.data;
          vm.secciones = vm.secciones.map(seccion => {
            var seccion = seccion
            seccion["publication_date"] = moment(seccion.fecha, ["YYYY-MM-DD","DD-MM-YYYY"]);            
            return seccion;
          });
          vm.chapters = vm.secciones.filter(obj => {
              return obj.publication_type_id == 3
            })
          vm.articles = vm.secciones.filter(obj => {
              return obj.publication_type_id == 5
            })
          vm.isLoadingInook=false;
        })
        .catch(e => {
          console.log(e);
          vm.isLoadingInook=false;

        });
    },
    updateBooks(year){
      this.year = year
      this.getBooks();
    },
    updateSections(year){
      this.year = year
      this.getSections();
    },

  },
  computed:{

  },
  watch:{
    changeFilter: function(){
      this.getBooks();
      this.getSections();
    }
  }
}
</script>

