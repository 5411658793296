<template>
  <section>
    <div class="container">
      <br>
      <div class="box">
        <b-tabs @input="changeTab" type="is-boxed">
          <b-tab-item v-for="item in items"
                      :value="item.content"
                      v-bind:key="item.content"
                      :label="item.tab" >
          </b-tab-item>
        </b-tabs>
        <!-- COMPONENTE AQUI -->
        <keep-alive>
          <component v-bind:is="currentTabComponent" :person-id="personId" :is-edit-disabled="true"></component>
        </keep-alive>
        <!-- FIN COMPONENTE -->
      </div>
    </div>
  </section>
</template>

<script>
import PersonalData from "../person/personal_data.vue"
import Appointments from "../life_record/appointments.vue"
import HouseholdAllowances from "../life_record/household_allowances.vue"
import Events from "../life_record/events.vue"
import Studies from "../life_record/studies.vue"
export default {
  name: "MyLifeRecord",
  props:[
    'personId'
  ],
  data(){
    return {
      currentTab: 'PersonalData',
      items: [
        {tab: 'Datos Personales',content:'PersonalData'},
        {tab: 'Nombramientos',content:'Appointments'},
        {tab: 'Estudios',content:'Studies'},
        {tab: 'Asignación Familiar', content: 'HouseholdAllowances'},
        {tab: 'Eventos',content:'Events'}
      ]
    }
  },
  components:{
    PersonalData,
    Appointments,
    HouseholdAllowances,
    Studies,
    Events
  },
  methods:{
    changeTab: function(value){
      this.currentTab = value
    }
  },
  computed: {
    currentTabComponent: function () {
      return this.currentTab
    }
  }
}
</script>

<style scoped>

</style>