<template>
  <section>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <b-table
            :data="data"
            :paginated= true
            per-page="25"
            striped
            hoverable
            sticky-header
            height="640"
            :narrowed=true
            :current-page="currentPage"
            :pagination-simple=false
            pagination-position="both"
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"
            :row-class="(row, index) => row.actual !== row.older ? 'custom-style' : ''">
          <b-table-column
              field="older"
              label="Datos Versión Anterior"
              searchable
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.older}}
            </template>
          </b-table-column>
          <b-table-column
              field="actual"
              label="Datos Actuales"
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.actual}}
            </template>
          </b-table-column>
        </b-table>
      </section>
    </div>
    <footer class="modal-card-foot actions">
      <div class="has-text-right is-grouped">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
      </div>
    </footer>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import {formatDate} from "../../packs/utilities";

export default {
  name: "modal_comparision",
  props:[
    "id","modalHeader", "data"
  ],
  components: {
  },
  data(){
    return {
      isLoading:false,
      currentPage: 1,
      isEditModalActive:false,
    }
  },
  created() {
    this.isLoading = true
    this.isLoading = false
  },
  methods: {

    formatDate,
  },
  watch: {

  }
}
</script>

<style>
.custom-style {
  background: darkred !important;
  color: white !important;
}

</style>