<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveAnnal)">
        <div class="modal-card" style="width: auto" id="modal-event">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" ref="modalPersonForm">
            <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
              <b-message type="is-danger" has-icon>
                <div v-for="(item,index) in errores_validacion">
                  {{ item[0] }}
                </div>
              </b-message>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Nombre de Evento*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input type="text" v-model="annal.name" maxlength="255" :has-counter="false">
                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Fecha Inicio*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                        <b-datepicker
                            v-model="annal.start_date"
                            icon="calendar-alt"
                            trap-focus
                            locale="es-ES"
                            editable
                            placeholder="Formato de Fecha: dd/mm/aaaa">
                        </b-datepicker>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Termino">
                        <b-datepicker
                            v-model="annal.finish_date"
                            icon="calendar-alt"
                            trap-focus
                            locale="es-ES"
                            editable
                            placeholder="Formato de Fecha: dd/mm/aaaa">
                        </b-datepicker>              
                </b-field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <b-field label="Pais origen*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <multiselect v-model="annal.nationality_id"
                                     :options="countries.map(type => type.id)"
                                     :custom-label="opt => countries.find(x => x.id === opt).name"
                                     placeholder="Seleccione Pais"
                                     selectLabel=""
                                     selectedLabel="Seleccionado"
                                     deselectLabel=""
                                     :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                          No se encontraron elementos.
                      </span>
                        </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
                
              </div>
              
            </div>


            <div class="columns">
              <div class="column">
                <b-field label="Ubicación*">
                  <b-input type="text" v-model="annal.location" maxlength="255" :has-counter="false">
                  </b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Nombre abreviado">
                  <b-input type="text" v-model="annal.abbreviated_name" maxlength="255" :has-counter="false">
                  </b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Link del evento">
                  <b-input v-model="annal.url" placeholder="https://link.cl"></b-input>
                </b-field>
              </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit"  @click="checkValidation()">Guardar </button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "NewAnnalModal",
  data(){
    return{
      annal: {
        name: null,
        abbreviated_name: null,
        url: null,
        nationality_id: null,
        location: null,
        finish_date: null,
        start_date: null,
      },
      countries: [],
      isLoading: false,
      canCancel: ['escape'],
      errores_validacion: {},
    }
  },
  props: ['modalHeader','queryType','updateAnnal'],
  async created() {

    await this.getCountries();
    if(this.queryType==='update' && this.updateAnnal){
      let vm = this;
      vm.isLoading = true
      axios
        .get('/annals/'+this.updateAnnal+'.json')
        .then(res => {
          vm.annal = res.data;
          if (vm.annal.start_date!=null && vm.annal.start_date!=undefined)
            vm.annal.start_date = new Date(moment(vm.annal.start_date).format('MM/DD/YYYY'));
          if (vm.annal.finish_date!=null && vm.annal.finish_date!=undefined)
            vm.annal.finish_date = new Date(moment(vm.annal.finish_date).format('MM/DD/YYYY'));
          vm.isLoading = false
        }).
      catch(error => {
        vm.isLoading = false
      })
    }
  },
  methods: {
    async getCountries(){
      let vm = this;
      vm.isLoading = true
      await axios
        .get('/nationalities.json')
        .then(res => {
          vm.countries = res.data;
          vm.isLoading = false
        }).
      catch(error => {
        vm.isLoading = false
      })
    },
    saveAnnal(){
      if (this.queryType=='create'){
        this.isLoading = true
        axios
            .post('/annals.json',this.annal)
            .then(response => {
              this.isLoading =false
              this.errores_validacion = {};
              this.$emit('clicked', response.data)
              this.$emit('close')
            }).
        catch(error => {
          console.log(error)
          this.errores_validacion = error.response.data
          this.isLoading = false
        })
      }
      if (this.queryType=='update'){
        this.isLoading = true
        axios
          .put('/annals/'+this.annal.id+'.json',this.annal)
          .then(response => {
            this.isLoading =false
            this.errores_validacion = {};
            this.$emit('clicked', response.data)
            this.$emit('close')
          }).
          catch(error => {
            //console.log(error)
            this.errores_validacion = error.response.data
            this.isLoading = false
          })
      }

    },
    async checkValidation(){
      const valid = await this.$refs.observer.validate()
      if(!valid){
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
  }
}
</script>

<style scoped>

</style>