<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(savePerson)">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" ref="modalPersonForm">

            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion">
                      <div v-if="item">{{ item[0] }}</div>
                    </div>
                  </b-message>
                </div>
              </div>
            </div>

                <div class="container" style="margin-top: 40px">
                  <div class="columns">
                    <div class="column">
                      <b-field label="Nombres*">
                        <ValidationProvider rules="required|alpha_spaces" v-slot="{ errors }" name="nombre">
                          <b-input placeholder="Ej: Ana María" v-model="person.name" type="text"></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Primer Apellido*">
                        <ValidationProvider rules="required|alpha_spaces" v-slot="{ errors }" name="paterno">
                          <b-input placeholder="Ej: Gonzalez" v-model="person.last_name" type="text"></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Segundo Apellido">
                       <ValidationProvider rules="alpha_spaces" v-slot="{ errors }" name="materno">
                          <b-input placeholder="EJ: Henriquez" v-model="person.second_surname" type="text"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">                    
                    <div class="column is-3">
                      <b-field label="Rut"
                               :type="{'is-danger': (checkUnique || checkDV)}"
                               :message="[{'Ingrese al menos uno de estos campos': checkUnique },
                                 {'Ingrese Rut': checkDV }]">
                        <ValidationProvider rules="integer|positive" v-slot="{ errors }" name="rut">
                          <b-input placeholder="Ej: 1234567" v-model="person.run_attributes.rut"></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div class="column is-1">
                      <b-field :type="{'is-danger': checkRut}"
                               :message="{'DV requerido': checkRut}">
                        <template #label>
                          DV*
                          <b-tooltip
                              label="Se debe ingresar al menos un campo de: Rut, Email o Pasaporte."
                              position="is-right"
                              size="is-medium"
                              multilined>

                            <b-icon
                                pack="fas"
                                icon="info-circle"
                                size="is-small"
                                type="is-info">
                            </b-icon>
                          </b-tooltip>
                        </template>

                        <b-input placeholder="K" v-model="person.run_attributes.dv" maxlength="1" :has-counter=false></b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field :type="{'is-danger': checkUnique}"
                               :message="{ 'Ingrese al menos uno de estos campos': checkUnique }">
                        <template #label>
                            Correo personal*
                          <b-tooltip
                              label="Se debe ingresar al menos un campo de: Rut, Email o Pasaporte."
                              position="is-right"
                              size="is-medium"
                              multilined>

                            <b-icon
                                pack="fas"
                                icon="info-circle"
                                size="is-small"
                                type="is-info">
                            </b-icon>
                          </b-tooltip>
                        </template>

                        <ValidationProvider rules="email" v-slot="{ errors }" name="personal_mail">
                          <b-input placeholder="Ej: email@email.com" v-model="person.personal_mail"></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field :type="{'is-danger': checkUnique}"
                               :message="{ 'Ingrese al menos uno de estos campos': checkUnique }">
                          <template #label>
                          Pasaporte*
                          <b-tooltip
                              label="Se debe ingresar al menos un campo de: Rut, Email o Pasaporte."
                              position="is-right"
                              size="is-medium"
                              multilined>

                            <b-icon
                                pack="fas"
                                icon="info-circle"
                                size="is-small"
                                type="is-info">
                            </b-icon>
                          </b-tooltip>
                        </template>
                        <b-input placeholder="Ej: AB00001" v-model="person.run_attributes.passport" maxlength="20" :has-counter=false></b-input>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">

                    <div class="column">
                      <b-field label="Nacionalidad">
                        <multiselect v-model="person.nationality_id"
                                     :options="countries.map(type => type.id)"
                                     :custom-label="opt => countries.find(x => x.id === opt).nationality"
                                     placeholder="Seleccione nacionalidad"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel="Seleccionado"
                                     deselectLabel="Presione para deseleccionar"
                                     :allow-empty="true">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                        </multiselect>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="Dirección">
                        <b-input placeholder="Ej: Calle 123" v-model="person.address" maxlength="255" :has-counter=false></b-input>
                      </b-field>
                    </div>



                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="Celular">
                        <ValidationProvider rules="integer|positive" v-slot="{errors}" style="width: 100%" name="celular">

                          <b-input placeholder="Ej: 912345678" v-model="cellphone" expanded></b-input>

                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Teléfono">
                        <ValidationProvider rules="integer|positive" v-slot="{errors}" name="telefono">
                          <b-input placeholder="Ej: 221345678" v-model="person.phone"></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>


                </div>
            <!-- GUARDAR PERSONA-->
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
          </section>
        </div>
        <footer class="modal-card-foot actions">
            <button class="button  left-button" type="button" @click="$emit('close')">Cerrar</button>
            <b-button class="button  right-button is-primary" icon-left="save" native-type="submit" @click="checkSavePerson()">Guardar Persona</b-button>
        </footer>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios"
import moment from "moment"
export default {
  name: "ModalSdtPeopleCreate",
  data(){
    const data =[]
    return {
      person:{
        run_attributes:{
          rut: null,
          dv: null,
          passport: null,
          run_completo: null
        },
        region_id: null,
        nationality_id: null,
        is_registered: false,
        skip_if_external_people: true,
        university_name: null,
        university_id:null
      },
      countries: [],
      checkUnique: false,
      checkRut: false,
      checkDV: false,
      errores_validacion: {},
      isLoading:false,
      cellphone: null,
      position: "",
    }
  },

  props:[
    'userId', 'queryType', 'personId', 'modalHeader'
  ],
  components:{
  },
  created(){
    let vm = this;
    vm.isLoading=true;
    if (vm.queryType ==="create"){
      axios
          .get("/people/new.json")
          .then(res => {
            vm.countries = res.data["countries"]
            vm.person.nationality_id = vm.countries.find(type => type.name == "CHILE")
            vm.person.nationality_id = vm.person.nationality_id.id
            
          }).catch(error => {
        console.log(error)
      })
    }
    else if (vm.queryType === "update"){
      axios
          .get("/people/"+vm.personId+"/edit.json")
          .then(res => {
            vm.person = res.data
            var number = vm.person.cellphone
            if(number !==null){
              var strNumber = number.toString()
              if(strNumber.includes(569) && strNumber.slice(0,3) =="569"){
                vm.cellphone = parseInt(strNumber.slice(3))
              }
            }            
          }).catch(error => {
        console.log(error)
      })
    }
    vm.isLoading=false;
  },
  methods: {
    checkSavePerson(){
      if (this.person.run_attributes.passport == ""){
        this.person.run_attributes.passport = null
      }
      if(this.person.run_attributes.rut == ""){
        this.person.run_attributes.rut = null
      }
      if(this.person.personal_mail == ""){
        this.person.personal_mail = null
      }

      if (this.person.run_attributes.rut == null && this.person.run_attributes.passport == null && this.person.personal_mail == null){
        this.checkUnique = true
      }
      else if (this.person.run_attributes.rut != null && this.person.run_attributes.dv == null){
        this.checkRut = true
        this.checkDV = false
      }
      else if (this.person.run_attributes.rut == null && this.person.run_attributes.dv != null){
        this.checkDV = true
        this.checkRut = false
      }

      else{
        this.checkRut = false
        this.checkDV = false
        this.checkUnique = false
        //this.savePerson()
      }
    },
    async savePerson(){
      this.checkSavePerson()
      if (this.checkRut == false && this.checkDV == false && this.checkUnique == false) {
        const isValid = await this.$refs.observer.validate();
        if (this.$refs.observer.fields.nombre.valid === false || this.$refs.observer.fields.paterno.valid === false
            || this.$refs.observer.fields.materno.valid === false) {
          return this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Se encontraron errores en el paso 1 del formulario. Revisar formato y campos sin completar.',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        }
        if (this.$refs.observer.fields.celular.valid === false || this.$refs.observer.fields.telefono.valid === false
        ) {
          return this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Se encontraron errores en el paso 3 del formulario. Revisar formato y campos sin completar.',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        }
        this.isLoading = true;
        var number = this.cellphone
        if (number === null) {
          this.person.cellphone = null
        } else {
          var strNumber = number.toString()
          this.person.cellphone = parseInt(strNumber)
        }
        if (this.queryType === "create") {
          var full_name = this.person.name + " " + this.person.last_name + " " + this.person.second_surname
          //this.person.skip_if_import  = true
          this.person.skip_if_step_three = true
          this.person.if_external_people = true
          this.person.is_sdt_people = true
          if(this.position.length == 0){
            this.person.position =  null
          }
          else{
            this.person.position = this.position
          }

          axios
              .post("/people/sdt_people/create.json", {
                person: this.person,
                position: this.person.position
              })
              .then(response => {
                this.errores_validacion = {};
                this.$emit('clicked', response.data)
                this.$emit('close')
                this.isLoading = false;
              }).catch(error => {
            this.errores_validacion = error.response.data
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            this.$refs.modalPersonForm.scrollTop = 0;
            this.isLoading = false;
          })
        } else if (this.queryType === "update") {
          var ruta = ''

          if (this.person.run_attributes == null) {
            ruta = "/people/" + this.personId + "/external_people/update.json"
          } else if (this.person.run_attributes.rut == null || this.person.run_attributes.dv == null) {
            ruta = "/people/" + this.personId + "/external_people/update.json"
          } else if (this.person.run_attributes.rut == '' || this.person.run_attributes.dv == '') {
            ruta = "/people/" + this.personId + "/external_people/update.json"
          } else {
            ruta = "/people/" + this.personId + "/external_people_run/update.json"
          }

          let clean_person = this.person
          clean_person = this.deleteData(clean_person)
          clean_person.is_registered = false
          axios
              .put(ruta, {
                person: clean_person,
                position: this.person.position
              })
              .then(response => {
                this.errores_validacion = {};
                this.$emit('close')
                this.isLoading = false;
              }).catch(error => {
            this.errores_validacion = error.response.data
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            this.$refs.modalPersonForm.scrollTop = 0;
            this.isLoading = false;
          })
        }
      }
    },
    deleteData(person){
      delete person.communes
      delete person.countries
      delete person.genders
      delete person.regions
      delete person.id
      return person
    },
  }
}
</script>

<style scoped>

  .right-button{
    margin-left: auto;
  }
  .left-button{
    margin-right: auto;
  }


</style>