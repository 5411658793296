<template>
  <div>
    <div class="columns is-vcentered mb-0">
      <div class="column">
        <div class="has-text-weight-semibold is-size-5 pl-2">
          {{ tableTitle }}
        </div>
      </div>
      <div v-if="createMode || editDates" class="column is-narrow mb-0">
        <b-button
          :disabled="editedPermits.length >= 6"
          icon-pack="fa"
          icon-left="plus"
          class="is-success"
          @click="addNewPermitDetail"
        >
          Agregar nueva fecha
        </b-button>
      </div>
    </div>
    <ValidationObserver ref="observer">
      <b-table ref="table" :data="editedPermits" style="width: 100%">
        <b-table-column
          v-slot="props"
          label="N°"
          field="id"
          :sortable="!editMode && !createMode"
          :cell-class="'is-align-items-center'"
        >
          {{ props.index + 1 }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Desde"
          field="start_date"
          :sortable="!editMode && !createMode"
          :cell-class="'is-align-items-center'"
        >
          <ValidationProvider
            v-if="isValidState(props.row.request_state.name) && !editStatus"
            v-slot="{ errors }"
            :rules="{
              required: true,
              start_date_not_past: false,
              start_date_before_end_date: props.row.end_date,
            }"
          >
            <b-datepicker
              v-if="
                props.row.editMode &&
                (createMode || editDates || !editStatus) &&
                isValidState(props.row.request_state.name)
              "
              v-model="props.row.start_date"
              :first-day-of-week="1"
              :min-date="lastTenYears"
              :unselectable-days-of-week="[0, 6]"
              position="is-top-right"
              placeholder="dd/mm/yyyy"
              editable
              locale="es-ES"
              icon="calendar-alt"
              icon-right-clickable
              trap-focus
              append-to-body
              @input="updateEndDateByStartDate(props)"
            />
            <span class="validation-alert">{{ errors[0] }}</span>
          </ValidationProvider>
          <template
            v-if="
              !props.row.editMode ||
              editStatus ||
              !isValidState(props.row.request_state.name)
            "
          >
            {{ formatDateToLocaleString(props.row.start_date) }}
          </template>
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Hasta"
          field="end_date"
          :sortable="!editMode && !createMode"
          :cell-class="'is-align-items-center'"
        >
          <ValidationProvider
            v-if="isValidState(props.row.request_state.name) && !editStatus"
            ref="end_date_provider"
            v-slot="{ errors }"
            :rules="{
              required: true,
              end_date_not_before_start_date: props.row.start_date,
            }"
          >
            <b-datepicker
              v-if="
                props.row.editMode &&
                (createMode || editDates || !editStatus) &&
                isValidState(props.row.request_state.name)
              "
              v-model="props.row.end_date"
              :first-day-of-week="1"
              :min-date="props.row.start_date"
              :unselectable-dates="unselectableEndDates(props.row.start_date)"
              :unselectable-days-of-week="[0, 6]"
              indicators="bars"
              position="is-top-right"
              placeholder="dd/mm/yyyy"
              editable
              locale="es-ES"
              icon="calendar-alt"
              icon-right-clickable
              trap-focus
              append-to-body
              @input="setNumberOfDays(props)"
            />
            <span class="validation-alert">{{ errors[0] }}</span>
          </ValidationProvider>
          <template
            v-if="
              !props.row.editMode ||
              editStatus ||
              !isValidState(props.row.request_state.name)
            "
          >
            {{ formatDateToLocaleString(props.row.end_date) }}
          </template>
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Días solicitados"
          field="number_of_days"
          :sortable="!editMode && !createMode"
          centered
        >
          <b-tag
            v-if="
              !datesAreEqualAndNotNull(props.row) ||
              !props.row.editMode ||
              editStatus ||
              !isValidState(props.row.request_state.name)
            "
            class="is-info is-light"
            icon-pack="fa"
            icon="calendar-day"
            icon-size="is-small"
            :style="{
              width: '9rem',
            }"
          >
            <span class="is-size-6">
              {{ changeDayTextMessage(props.row.number_of_days) }}
            </span>
            <span class="is-size-6">
              {{
                props.row.adm_permit_journey.name === "COMPLETA"
                  ? ""
                  : props.row.adm_permit_journey.name
              }}
            </span>
          </b-tag>
          <div
            v-if="
              datesAreEqualAndNotNull(props.row) &&
              props.row.editMode &&
              (createMode || editDates) &&
              isValidState(props.row.request_state.name)
            "
          >
            <b-dropdown
              v-model="props.row.adm_permit_journey.name"
              position="is-top-right"
              @input="updateNumberOfDays(props)"
            >
              <b-tag
                slot="trigger"
                class="is-info is-light"
                icon-pack="fa"
                icon="calendar-day"
                icon-size="is-small"
                :style="{
                  width: '9rem',
                  cursor: 'pointer',
                }"
              >
                <span class="is-size-6">
                  {{ changeDayTextMessage(props.row.number_of_days) }}
                </span>
                <span class="is-size-6">
                  {{
                    props.row.adm_permit_journey.name === "COMPLETA"
                      ? ""
                      : props.row.adm_permit_journey.name
                  }}
                  <b-icon icon="caret-down" />
                </span>
              </b-tag>

              <b-dropdown-item
                value="COMPLETA"
                class="left-aligned has-text-weight-bold"
              >
                <b-icon pack="fas" icon="business-time" />
                Todo el día
              </b-dropdown-item>
              <b-dropdown-item value="AM" class="left-aligned">
                <b-icon pack="fas" icon="clock" />
                Media jornada:<span class="has-text-weight-bold">Mañana</span>
              </b-dropdown-item>
              <b-dropdown-item value="PM" class="left-aligned">
                <b-icon pack="fas" icon="clock" />
                Media jornada:<span class="has-text-weight-bold">Tarde</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-table-column>
        <b-table-column
          v-if="!createMode && !editDates"
          v-slot="props"
          label="Estado"
          field="request_state.name"
          numeric
          width="10rem"
          :sortable="!editMode"
        >
          <b-dropdown
            v-if="props.row.editMode"
            v-model="props.row.request_state.name"
            position="is-top-left"
            expanded
          >
            <b-tag
              slot="trigger"
              :style="{
                cursor: 'pointer',
                width: '8rem',
              }"
              icon-pack="fa"
              :icon="iconByState(props.row.request_state.name)"
              :type="colorTypeByState(props.row.request_state.name)"
            >
              {{ props.row.request_state.name }} <b-icon icon="caret-down" />
            </b-tag>

            <b-dropdown-item
              v-for="(state, index) in permitStates"
              :key="index"
              :value="state"
              class="left-aligned"
            >
              <b-icon :icon="iconByState(state)" />
              {{ state }}
            </b-dropdown-item>
          </b-dropdown>
          <b-tag
            v-if="!editMode || !props.row.editMode"
            icon-pack="fa"
            :style="{
              width: '8rem',
            }"
            :icon="iconByState(props.row.request_state.name)"
            :type="colorTypeByState(props.row.request_state.name)"
          >
            {{ props.row.request_state.name }}
          </b-tag>
        </b-table-column>
        <b-table-column
          v-if="createMode || editDates"
          v-slot="props"
          label="Acciones"
          field="actions"
          numeric
        >
          <b-tooltip
            v-if="
              !isValidState(props.row.request_state.name) ||
              editedPermits.length <= 1
            "
            :label="getTooltipLabel(props.row.request_state.name)"
            position="is-left"
            size="is-small"
            multilined
          >
            <b-button
              type="is-danger"
              icon-pack="fa"
              icon-left="trash"
              outlined
              :disabled="
                !isValidState(props.row.request_state.name) ||
                editedPermits.length <= 1
              "
              @click="
                deletePermitDetail(
                  editedPermits.indexOf(props.row),
                  props.row.request_state.name
                )
              "
            />
          </b-tooltip>
          <b-button
            v-else
            type="is-danger"
            icon-pack="fa"
            icon-left="trash"
            outlined
            :disabled="!isValidState(props.row.request_state.name)"
            @click="
              deletePermitDetail(
                editedPermits.indexOf(props.row),
                props.row.request_state.name
              )
            "
          />
        </b-table-column>

        <template #empty>
          <section>
            <b-message type="is-info" has-icon class="my-4">
              {{
                createMode
                  ? "Para agregar una nueva fecha, presiona el botón 'Agregar nueva fecha'."
                  : "No hay solicitudes de permisos administrativos con goce de sueldo registradas."
              }}
            </b-message>
          </section>
        </template>
        <template #footer>
          <div class="has-text-right">
            Total de días
            {{
              createMode || editDates
                ? " a solicitar: " + totalDaysRequested
                : " solicitados: " + totalDays
            }}
          </div>
        </template>
      </b-table>
    </ValidationObserver>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import moment from "moment";

import {
  colorTypeByState,
  iconByState,
  formatDateToLocaleString,
} from "../.././packs/utilities";

export default {
  props: {
    permits: {
      type: Array,
      default: () => [],
    },
    createMode: {
      type: Boolean,
    },
    editMode: {
      type: Boolean,
    },
    editDates: {
      type: Boolean,
    },
    editStatus: {
      type: Boolean,
    },
    tableTitle: {
      type: String,
      default: "Solicitudes de permisos administrativos con goce de sueldo",
    },
    allDatesSaved: {
      type: Boolean,
    },
    holidays: {
      type: Array,
      default: () => [],
    },
    permitStates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      editedPermits: this.permitsByMode(),
      lastTenYears: moment().subtract(10, "years").toDate(),
      isValidated: this.allDatesSaved,
    };
  },
  computed: {
    totalDaysRequested() {
      return this.editedPermits.reduce(
        (total, permit) =>
          parseFloat(total) + parseFloat(permit.number_of_days),
        0
      );
    },
    totalDays() {
      return this.permits.reduce(
        (total, permit) =>
          parseFloat(total) + parseFloat(permit.number_of_days),
        0
      );
    },
  },
  watch: {
    editedPermits: {
      async handler() {
        if (this.editedPermits.length === 0) {
          this.$emit("update:allDatesSaved", false);
          return;
        }
        await this.$nextTick(async () => {
          this.isValidated = await this.$refs.observer.validate();
          if (this.isValidated) {
            this.$emit("update:allDatesSaved", true);
          } else {
            this.$emit("update:allDatesSaved", false);
            return;
          }
        });
        let savedPermits = [];
        if (this.editMode || this.createMode) {
          savedPermits = this.editedPermits.map((permit) => ({
            start_date: formatDateToLocaleString(permit.start_date),
            end_date: formatDateToLocaleString(permit.end_date),
            number_of_days: parseFloat(permit.number_of_days),
            journey: permit.adm_permit_journey.name,
            state: permit.request_state.name,
            ...(this.editMode ? { id: permit.id } : {}),
          }));
        }
        this.$emit("update:allDatesSaved", true);
        this.$emit("onChangeDetected", Object.freeze(cloneDeep(savedPermits)));
      },
      deep: true,
    },
    allDatesSaved(newVal) {
      this.isValidated = newVal;
    },
  },

  methods: {
    permitsByMode() {
      if (this.createMode) return [];
      if (this.editMode) {
        return cloneDeep(this.permits).map((permit) => ({
          ...permit,
          editMode: true,
          start_date: moment(permit.start_date).toDate(),
          end_date: moment(permit.end_date).toDate(),
        }));
      }
      return this.permits;
    },
    changeDayTextMessage(numberOfDays) {
      const formattedNumberOfDays = parseFloat(numberOfDays);
      return formattedNumberOfDays === 1
        ? `${formattedNumberOfDays} Día`
        : `${formattedNumberOfDays} días`;
    },
    deletePermitDetail(permitId, state) {
      if (this.editDates && !this.isValidState(state)) return;
      this.editedPermits.splice(permitId, 1);
      this.$nextTick(async () => {
        this.isValidated = await this.$refs.observer.validate();
        if (this.isValidated) this.$emit("update:allDatesSaved", true);
      });
    },

    async addNewPermitDetail() {
      const newRow = {
        start_date: null,
        end_date: null,
        number_of_days: 0,
        adm_permit_journey: {
          name: "COMPLETA",
        },
        request_state: {
          name: "EN CURSO",
        },
        editMode: true,
      };
      if (this.editedPermits.length >= 6) return;
      this.editedPermits.push(newRow);
      this.$nextTick(async () => {
        this.isValidated = await this.$refs.observer.validate();
        this.$emit("update:allDatesSaved", false);
      });
    },
    calculateNumberOfDays(startDate, endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      const holidayDates = this.holidays.map((holiday) =>
        moment(holiday).format("YYYY-MM-DD")
      );
      let weekdaysCount = 0;

      for (let day = start; day <= end; day.add(1, "days")) {
        if (this.isWeekday(day) && !this.isHoliday(day, holidayDates)) {
          weekdaysCount++;
        }
      }
      return weekdaysCount;
    },

    datesAreEqualAndNotNull(row) {
      if (row.start_date === null || row.end_date === null) return false;
      return moment(row.start_date).isSame(moment(row.end_date), "day");
    },
    updateNumberOfDays(props) {
      const selection = props.row.adm_permit_journey.name;
      let numberOfDays = 1;
      if (selection === "AM" || selection === "PM") {
        numberOfDays = 0.5;
      }
      if (!this.isWeekday(moment(props.row.start_date))) {
        numberOfDays = this.calculateNumberOfDays(
          props.row.start_date,
          props.row.end_date
        );
        this.editedPermits[props.index].adm_permit_journey.name = "COMPLETA";
      }
      this.editedPermits[props.index].number_of_days = numberOfDays;
    },

    isWeekday(day) {
      return day.isoWeekday() < 6;
    },

    isHoliday(day, holidayDates) {
      const formattedDay = day.format("YYYY-MM-DD");
      return holidayDates.includes(formattedDay);
    },
    unselectableEndDates(startDate) {
      const selectedStartDate = moment(startDate).toDate();
      return (date) => {
        return date < selectedStartDate;
      };
    },
    async updateEndDateByStartDate(table) {
      const index = table.index;
      const permit = table.row;
      const { start_date } = permit;
      this.editedPermits[index].end_date = start_date;
      this.setNumberOfDays(table);
    },

    setNumberOfDays(table) {
      const index = table.index;
      const permit = table.row;
      const { start_date, end_date } = permit;
      const numberOfDays = this.calculateNumberOfDays(start_date, end_date);
      this.editedPermits[index].number_of_days = numberOfDays;
      this.editedPermits[index].adm_permit_journey.name = "COMPLETA";
    },

    isValidState(state) {
      return state === "EN CURSO";
    },
    getTooltipLabel(requestStateName) {
      if (this.editedPermits.length <= 1) {
        return "No se puede eliminar, es el único permiso en el documento.";
      }
      return `Este rango de fechas está en estado: ${requestStateName}. No se puede eliminar.`;
    },
    formatDateToLocaleString,
    colorTypeByState,
    iconByState,
  },
};
</script>

<style scoped>
.left-aligned {
  text-align: left !important;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
</style>
