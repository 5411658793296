<template>
  <section>
    <div class="container">

        <b-tabs @input="changeTab" :destroy-on-hide="true" v-model="currentTab" position="is-centered" type="is-toggle">
              <b-tab-item v-for="item in items"
                          :value="item.content"
                          v-bind:key="item.content"
                          :label="item.tab"
                          :visible="(item.content ==='NullEvents') ? can_list_null === 'true' : true">
              </b-tab-item>
        </b-tabs>
        <!-- COMPONENTE AQUI -->

        <component v-bind:is="currentTabComponent" :person-id="personId" :can_create_event="can_create_event"
                   :person_name="person_name"></component>

        <!-- FIN COMPONENTE -->

    </div>
  </section>
</template>
<script>
import Events from "./events"
import NullEvents from "./null_events"
import EventBus from "../eventBus";
export default {
  name: "event_tabs",
  props:[
    'personId', 'can_create_event', 'can_list_null', 'person_name'
  ],
  data(){
    return {
      currentTab: 'Events',
      items: [
        {tab: 'Eventos',content:'Events'},
        {tab: 'Eventos Anulados', content: "NullEvents"},
      ],
    }
  },
  components:{
    NullEvents,
    Events,
  },
  methods:{
    changeTab: function(value){
      this.currentTab = value
    },
    selectTab(tab){
      this.currentTab = tab
    },
  },
  computed: {
    currentTabComponent: function () {
      return this.currentTab
    },
  },
}
</script>