<template>
  <section>
    <div class="columns">
      <div class="column is-12">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
            <b-message type="is-danger" has-icon>
              <div v-for="(item,index) in errores_validacion" :key="index">
                {{ item[0] }}
              </div>
            </b-message>
          </div>
          <form @submit.prevent="handleSubmit(savePatent)">
            <!-- Box de Formulario Patente -->
            <div class="box">


                <b-steps
                    v-if="isUpdatingAndDeletingParticipant(false)!=undefined"
                    ref="patentsteps"
                    v-model="activeStep"
                    :animated="isAnimated"
                    :rounded="isRounded"
                    :has-navigation="hasNavigation"
                    :icon-prev="prevIcon"
                    :icon-next="nextIcon"
                    :label-position="labelPosition"
                    :mobile-mode="mobileMode">

                  <b-step-item v-if="isUpdatingAndDeletingParticipant(false)"
                               step="1" label="General" :clickable="isStepsClickable">
                    <h1 class="title has-text-centered">General [1/2]</h1>
                    <div class="columns">
                      <div class="column is-4 is-offset-8">
                        <b-switch v-model="requiredFieldOnly">Mostrar solo campos obligatorios</b-switch>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <b-field label="Título*">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <b-input v-model="patent.title" type="text"></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                      <div class="column" v-if="!requiredFieldOnly">
                        <b-field label="Título Corto">
                          <b-input v-model="patent.short_title"></b-input>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <b-field label="Código Protección*">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <b-input v-model="patent.protection_code"></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Tipo de Protección*">
                          <ValidationProvider rules="required" v-slot="{errors}">
                            <multiselect v-model="patent.patent_type_id"
                                         :options="tipos_patente.map(type => type.id)"
                                         :custom-label="opt => tipos_patente.find(x => x.id === opt).name"
                                         placeholder="Seleccione tipo de protección"
                                         selectLabel="Presione para seleccionar"
                                         selectedLabel="Seleccionado"
                                         deselectLabel="No se puede deseleccionar"
                                         :allow-empty="false">
                              <template v-slot:noOptions>
                                No existen datos
                              </template>
                              <span slot="noResult">
                      No se encontraron elementos.
                    </span>
                            </multiselect>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column" v-if="!requiredFieldOnly">
                        <b-field label="Fecha de Creación">
                          <b-datepicker
                              v-model="patent.presentation_date"
                              icon="calendar-alt"
                              trap-focus
                              locale="es-ES"
                              editable
                              placeholder="Formato de Fecha: dd/mm/aaaa">
                          </b-datepicker>
                        </b-field>
                      </div>
                      <div class="column" v-if="!requiredFieldOnly">
                        <b-field label="Número de Solicitud">
                          <b-input v-model="patent.application_number"></b-input>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column" v-if="!requiredFieldOnly">
                        <b-field label="Código Tecnología">
                          <b-input v-model="patent.technology_code"></b-input>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="País*">
                          <ValidationProvider rules="required" v-slot="{errors}">
                            <multiselect v-model="patent.nationality_id"
                                         :options="paises.map(type => type.id)"
                                         :custom-label="opt => paises.find(x => x.id === opt).name"
                                         placeholder="Seleccione un país"
                                         selectLabel="Presione para seleccionar"
                                         selectedLabel="Seleccionado"
                                         deselectLabel="No se puede deseleccionar"
                                         :allow-empty="false">
                              <template v-slot:noOptions>
                                No existen datos
                              </template>
                              <span slot="noResult">
                      No se encontraron elementos.
                    </span>
                            </multiselect>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                    </div>
                  </b-step-item>
                  <b-step-item step="2" label="Participantes" :clickable="isStepsClickable">
                    <h1 class="title has-text-centered">
                      Participantes [2/2]
                      <b-tooltip label="En esta sección debe ingresar las(os) propietarias(os) intelectuales de la patente que
               se encuentren registrados en el sistema, de esta manera, los datos ingresados de esta patente quedarán asociados a esta persona."
                                 position="is-right"
                                 size="is-medium"
                                 multilined>
                        <b-icon
                            pack="fas"
                            icon="info-circle"
                            size="is-small"
                            type="is-info">
                        </b-icon>
                      </b-tooltip>
                    </h1>
                    <div v-if="queryType=='update'" class="columns is-centered">
                      <div class="column is-centered has-text-centered">
                        <b-message
                            title="Información importante"
                            type="is-warning"
                            aria-close-label="Cerrar mensaje">
                          Solo la/el usuaria/o que es Dueña o Dueño de esta actividad académica tiene permisos para editar todos sus atributos.<br>
                          Quienes participan en la actividad académica pero no son dueños, solo pueden editar su participación.
                          <br><span v-if="patent.creator_name">Origen de la información: {{ patent.creator_name }}</span>
                        </b-message>
                      </div>
                    </div>
                    <div class="is-pulled-right" v-if="isUpdatingAndDeletingParticipant(false)">
                      <b-button  class="mt-2" @click.prevent="execAddModal(patentId)"
                                 type="is-primary">Administrar Participantes</b-button>
                    </div>
                    <div class="column">
                      <h3 class="is-size-4 has-text-weight-bold">Tabla Resumen Participantes</h3>
                      <b-table
                          :data="participantes"
                          :paginated=true
                          per-page=5
                          striped
                          hoverable
                          :current-page="currentPage"
                          :card-layout="cardLayout"
                          :pagination-simple=false
                          pagination-position="bottom"
                          default-sort-direction="desc"
                          sort-icon="arrow-up"
                          sort-icon-size="is-small"
                          default-sort="last_patent_state_date"
                          aria-next-label="Siguiente"
                          aria-previous-label="Anterior"
                          aria-page-label="Página"
                          aria-current-label="Página actual">
                        <b-table-column
                            field= 'name'
                            label= 'Nombres'
                            width="20em"
                            searchable
                            sortable>
                          <template
                              slot="searchable"
                              slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                icon="search"
                                size="is-small" />
                          </template>
                          <template v-slot="props">
                            {{  props.row.name }}
                          </template>
                        </b-table-column>
                        <b-table-column
                            field= 'corporate_mail'
                            label= 'Correo Corporativo'
                            searchable
                            sortable>
                          <template
                              slot="searchable"
                              slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                icon="search"
                                size="is-small" />
                          </template>
                          <template v-slot="props">
                            {{  props.row.corporate_mail }}
                          </template>
                        </b-table-column>
                        <b-table-column
                            field= 'unit'
                            label= 'Unidad'
                            searchable
                            sortable>
                          <template
                              slot="searchable"
                              slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                icon="search"
                                size="is-small" />
                          </template>
                          <template v-slot="props">
                            {{  props.row.unit }}
                          </template>
                        </b-table-column>
                        <b-table-column
                            label="Acción"
                            width="10"
                            v-slot="props">
                          <b-icon
                              v-if="isUpdatingAndDeletingParticipant(props.row)"
                              pack="fas"
                              style="color: #e50000;"
                              icon="trash"
                              size="is-medium"
                              @click.native="deleteParticipantButton(props.row)"/>
                        </b-table-column>
                      </b-table>
                      <hr class="dropdown-divider" aria-role="menuitem">
                      <div class="columns" v-if="queryType === 'update'">
                        <div class="column" v-if="patent.is_creator">
                          <h3 class="is-size-6 has-text-weight-bold">
                            Dueña(o)*
                            <b-tooltip label="Debe seleccionar al menos una Dueña o Dueño del dato que se encuentre como participante en la actividad académica. Si realiza el cambio, no podrá revertirlo."
                                       position="is-right"
                                       size="is-large"
                                       multilined>
                              <b-icon
                                  pack="fas"
                                  icon="info-circle"
                                  size="is-small"
                                  type="is-info">
                              </b-icon>
                            </b-tooltip>
                          </h3>
                          <b-field label=" ">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                              <multiselect v-model="ownerId"
                                           :options="participantes.map(type => type.person_id)"
                                           :custom-label="opt => participantes.find(x => x.person_id === opt).name"
                                           placeholder="Seleccione Dueña(o)"
                                           selectLabel="Presione para seleccionar"
                                           selectedLabel="Seleccionado"
                                           deselectLabel=""
                                           :allow-empty="false">
                                <template v-slot:noOptions>
                                  No existen datos
                                </template>
                                <span slot="noResult">
                          No se encontraron elementos.
                      </span>
                              </multiselect>
                              <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>
                      </div>
                      <div class="columns" v-else>
                        <div class="column">
                          <h3 class="is-size-6 has-text-weight-bold">
                            Dueña(o)*
                            <b-tooltip label="Debe seleccionar al menos una Dueña o Dueño del dato que se encuentre como participante en la actividad académica. Si realiza el cambio, no podrá revertirlo."
                                       position="is-right"
                                       size="is-large"
                                       multilined>
                              <b-icon
                                  pack="fas"
                                  icon="info-circle"
                                  size="is-small"
                                  type="is-info">
                              </b-icon>
                            </b-tooltip>
                          </h3>
                          <b-field label=" ">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                              <multiselect v-model="ownerId"
                                           :options="participantes.map(type => type.person_id)"
                                           :custom-label="opt => participantes.find(x => x.person_id === opt).name"
                                           placeholder="Seleccione Dueña(o)"
                                           selectLabel="Presione para seleccionar"
                                           selectedLabel="Seleccionado"
                                           deselectLabel=""
                                           :allow-empty="false">
                                <template v-slot:noOptions>
                                  No existen datos
                                </template>
                                <span slot="noResult">
                          No se encontraron elementos.
                      </span>
                              </multiselect>
                              <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>
                      </div>
                    </div>
                  </b-step-item>
                  <template
                      #navigation="{previous, next}">
                    <div class="columns is-pulled-right" >
                      <div class="column">
                        <b-button
                            outlined
                            icon-left="chevron-left"
                            :disabled="previous.disabled"
                            @click.prevent="previous.action">
                        </b-button>
                        <b-button
                            outlined
                            icon-right="chevron-right"
                            :disabled="next.disabled"
                            @click.prevent="next.action">
                        </b-button>

                      </div>
                    </div>

                  </template>

                </b-steps>
                <!-- GUARDAR Patente-->
                <div class="actions has-text-right">
                  <button v-if="modalCancel" class="button is-danger" @click.prevent="$emit('close')">Cancelar</button>
                  <button class="button is-primary" type="submit" @click="checkValidation()">Guardar Patente</button>
                </div>


            </div>
            <!--FIN BOX-->
          </form>
        </ValidationObserver>
      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="isModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-participant-patent :patent-id="patentId"
                                  :participantes="participantes"
                                  query-type="update"
                                  modal-header="Añadir Participantes"
                                  @close="props.close"
                                  @clicked="onClickChild"
        >
        </modal-participant-patent>
      </template>
    </b-modal>
  </section>
</template>

<script>
import ModalParticipantPatent from "./modal_participant_patent";
import axios from "axios";
import moment from 'moment'

export default {
  name: "NewPatentForm",
  data(){
    const data =[]
    return {
      isModalActive: false,
      canCancel: ['escape'],
      patent:{
        person_patents_attributes: [],
        patent_states_attributes: [],
        presentation_date: null, //listop
        exit_date: null, //listop
        title: null,//listop
        short_title: null,//listop
        patent_code: null,//listop
        application_number: null,//listop
        protection_code: null, //listop
        technology_code: null, //listop
        patent_type_id: null, //listop
        nationality_id: null, //listop
        validation_attributes:{},
      },
      participantes: [],
      estados_patente: [],
      tipos_patente: [],
      unidades: [],
      errores_validacion: {},
      paises: [],
      isLoading:false,
      requiredFieldOnly:false,
      currentPage:1,
      cardLayout:false,
      ownerId: null,
      activeStep: 0,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      hasNavigation: true,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
    }
  },
  props:[
    'userId', 'queryType','patentId','cargoPrincipal','unidadPrincipal','personId', 'hideHeader', 'modalCancel','modalHeader',
      'showParticipantsFirst', 'fromAcademic'
  ],
  components:{
    ModalParticipantPatent,
  },
  created() {
    this.isLoading=true;
    axios
        .get("/patents/new.json")
        .then(res => {
          this.tipos_patente = res.data["tipos_patente"]
          this.unidades = res.data["unidades"]
          this.paises = res.data["paises"]
          this.estados_patente = res.data["estados_patente"]
          if (this.queryType!=="update" && this.cargoPrincipal.name==="ACADEMICO" ){
            //se añade un participante automaticamente si es que es acádemico
            this.participantes.push({
              person_id: res.data["participante"].person_id,
              name: res.data["participante"].person_name,
              alias: res.data["participante"].person_alias != null ? res.data["participante"].person_alias.toUpperCase() : "",
              corporate_mail: res.data["participante"].corporate_mail != null ? res.data["participante"].corporate_mail.toUpperCase() : "",
              unit: res.data["participante"].unit_name,
              unit_id: res.data["participante"].unit_id,
              in_charge: null,
              property_percentage: null,
              patent_id: null
            })

          }
          this.isLoading=false;
        })
        .catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.isLoading=false;
        })
    if (this.queryType==="update"){
      this.isLoading=true;
      axios
          .get("/patents/"+this.patentId+".json")
          .then(res => {
            this.patent = res.data
            if (this.patent.presentation_date != null){
              this.patent.presentation_date = new Date(moment(this.patent.presentation_date).format('MM/DD/YYYY'));
            }
            if (this.patent.exit_date != null)
              this.patent.exit_date = new Date(moment(this.patent.exit_date).format('MM/DD/YYYY'));
            if (this.patent.patent_states_attributes != null)
              this.patent.patent_states_attributes = this.patent.patent_states_attributes.map(
                  obj=> ({ ...obj, hps_date: new Date(obj.hps_date.replace(/-/g, '\/')) })
              )
            if (this.patent.validation_attributes==null){
              this.patent.validation_attributes={}
              this.patent.data_source=""
            }
            if(this.patent.person_patents_attributes!= null){
              this.participantes = this.patent.person_patents_attributes
            }

            if (this.participantes.filter(person => person.person_id == res.data['ownerId']).length > 0){
              this.ownerId = this.patent.ownerId
            }
            if(this.showParticipantsFirst){
              this.activeStep=1;
            }

            //this.isLoading=false;

          })
          .catch(error => {
            this.errores_validacion = error.response.data
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            //this.isLoading=false;
          }).finally( ()=>{
            this.isLoading=false;
          })
    }
  },
  methods: {
    deleteParticipant(participant){
      if (participant.person_id == this.ownerId){
        this.ownerId = null
      }
      this.participantes = this.participantes.filter(person => person.person_id != participant.person_id);
    },
    execAddModal(id){
      this.selected = id;
      this.isModalActive = true;
    },
    onClickChild(value){
      this.isLoading = true
      this.participantes = value
      this.isLoading = false

    },
    savePatent() {
      this.isLoading=true;
      this.patent.person_patents_attributes = this.participantes
      if (this.queryType==="create"){
        axios
            .post("/patents.json", {
              patent: this.patent,
              user_id: this.userId,
              data_source: this.patent.validation_attributes.data_source,
              ownerId: this.ownerId
            })
            .then(response =>{
              this.errores_validacion = {};
              window.location.href = '/patents/'+response.data["id"]
              this.isLoading=false;
            }).catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.isLoading=false;
        })

      }
      if (this.queryType==="update"){
        this.isLoading=true;
        axios
            .put("/patents/"+this.patentId+".json", {
              patent: this.patent,
              user_id: this.userId,
              data_source: this.patent.validation_attributes.data_source,
              ownerId: this.ownerId
            })
            .then(response =>{
              this.errores_validacion = {};
              if (this.fromAcademic) {
                this.$emit('refresh')
                this.$emit('close')
              }else{
                window.location.href = '/patents/'+this.patentId
              }
              this.isLoading=false;
            }).catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.isLoading=false;
        })
      }

    },
    addPersonPatent() {
      this.patent.person_patents_attributes.push({
        person_id: null,
        in_charge: null,
        property_percentage: null,
        unit_id: null,
        unit_person_patents:[],
        _destroy: null
      })
    },
    addState(){
      this.patent.patent_states_attributes.push({
        patent_state_id:null,
        hps_date:null,
      })
    },
    deletePersonPatent(index){
      this.patent.person_patents_attributes.splice(index,1)
    },
    deleteState(index){
      this.patent.patent_states_attributes.splice(index,1)
    },
    findUnitPersonPatents(index){
      this.isLoading=true;
      if(this.patent.person_patents_attributes[index].unit_id!=null){
        axios
            .get("/get_people_by_unit.json", {
              params: {unit_id: this.patent.person_patents_attributes[index].unit_id}
            })
            .then(res => {
              this.patent.person_patents_attributes[index].unit_person_patents = res.data['personas'];
              this.isLoading=false;
            }).
        catch(error => {
          console.log(error)
          this.isLoading=false;
        })

      }
    },
    blur: function () {
      this.computedForm.refs.selected.validate();
    },
    isUpdatingAndDeletingParticipant(row){
      if(this.queryType=="create"){
        return true;
      }
      else if(this.queryType=='update'){
        //console.log(this.project.current_user_person_id, row.id,row)
        if (row){
          return this.patent.is_creator || this.patent.current_user_person_id == row.person_id;
        }
        return this.patent.is_creator;
      }
    },
    deleteParticipantButton(participants){

      this.$buefy.dialog.confirm({
        title: 'Eliminar Participante',
        message: 'Precaución, al momento de guardar la patente esta acción eliminará la asociación con la/el participante, lo que implica que ya no podrá ver esta patente ni será contada en su actividad académica ¿Desea continuar?',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteParticipant(participants)
      })

    },
    async checkValidation(){
      const valid = await this.$refs.observer.validate()
      if(!valid){
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar tanto la pestaña General como la de Participantes.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },

  },
  computed: {
    computedForm: function () {
      return this.$refs.form;
    }
  },
}
</script>

