<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(checkSave)">
        <div class="modal-card" style="width: 1080px">
          <header class="modal-card-head">
            <p v-if="update" class="modal-card-title">Editar Reconocimiento</p>
            <p v-else class="modal-card-title">Agregar Nuevo Reconocimiento</p>
            <button type="button" class="delete" @click="$emit('close')" />
          </header>
          <section class="modal-card-body">
            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion">
                      <div v-if="item">{{ item[0] }}</div>
                    </div>
                  </b-message>
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <ValidationProvider name="Nombre del Premio, Honor o Distinción" rules="min:3|max:255|required"
                  v-slot="{ errors, failedRules }">
                  <b-field label="Nombre del Premio, Honor o Distinción *"
                    :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                    :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                    <b-input v-model="award.name"
                      placeholder="Ej: Premio a la Excelencia en Vinculación con el Medio" />
                  </b-field>
                </ValidationProvider>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <ValidationProvider name="Características del Reconocimiento" rules="min:3|max:500"
                  v-slot="{ errors, failedRules }">
                  <b-field label="Características del Reconocimiento"
                    :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                    :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                    <b-input type="textarea" v-model="award.description" />
                  </b-field>
                </ValidationProvider>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <ValidationProvider name="Clase del Reconocimiento" rules="required" v-slot="{ errors, failedRules }">
                  <b-field label="Clase del Reconocimiento *"
                    :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                    :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                    <multiselect v-model="award.award_type_id" :options="award_type.map(type => type.id)"
                      :custom-label="opt => award_type.find(x => x.id === opt).name"
                      placeholder="Seleccione Clase del premio" selectLabel="Presione para seleccionar"
                      selectedLabel="Seleccionado" :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                  </b-field>
                </ValidationProvider>

              </div>
              <div class="column">
                <ValidationProvider name="Institución Otorgante" rules="min:3|max:255"
                  v-slot="{ errors, failedRules }">
                  <b-field :type="{ 'is-danger': isPartnerEntityRegister || Object.keys(failedRules).length > 0 }"
                    :message="{ 'Debe añadir una entidad registrada': isPartnerEntityRegister || Object.keys(failedRules).length > 0 }"
                    label="Institución Otorgante">
                    <b-autocomplete v-model="award.partner_entity_name" :keep-first="keepFirst"
                      :open-on-focus="openOnFocus" :data="partner_entities" field="show_name" empty="Vacio"
                      @typing="searchPartnerEntities"
                      @select="option => {if (option) {award.partner_entity_id = option.id} }" :clearable="clearable"
                      placeholder="Ej: Universidad de Santiago de Chile">
                      <template slot-scope="props">
                        {{ props.option.show_name ? props.option.show_name : props.option.name}}
                      </template>
                    </b-autocomplete>
                  </b-field>
                </ValidationProvider>

              </div>
              <div class="column is-narrow">
                <br>
                <b-field label=" ">
                  <b-tooltip label="Si la entidad que desea agregar no existe, la puede agregar presionando el botón +"
                    position="is-left">
                    <b-button class="is-primary is-outlined" @click="addEntity()">
                      <b-icon icon="plus"></b-icon>
                    </b-button>
                  </b-tooltip>
                </b-field>
              </div>
            </div>
            <div class="columns mb-6">
              <div class="column">
                <ValidationProvider name="Fecha" rules="required" v-slot="{ errors, failedRules }">
                  <b-field label="Fecha *" :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                    :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                    <b-datepicker v-model="award.award_date" icon="calendar-alt" trap-focus locale="es-ES" editable
                      placeholder="dd/mm/aaaa" />
                  </b-field>
                </ValidationProvider>
              </div>
            </div>
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
          </section>
        </div>
        <footer class="modal-card-foot is-flex is-justify-content-space-between">
          <b-button label="Cerrar" @click="$emit('close')" />
          <b-button label="Guardar Datos" type="is-primary" @click="checkSave()" />
        </footer>
        <b-modal v-model="isAddModalEntityActive" :destroy-on-hide="true" width="55%" :can-cancel="canCancel">
          <template #default="props">
            <modal_partner_entity query-type="update" modal-header="Añadir nueva entidad" @close="props.close"
              @clicked="onClickChild"></modal_partner_entity>
          </template>
        </b-modal>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios";
import modal_partner_entity from "../moduloVIME/agreements/new/modal_partner_entity"
import moment from "moment/moment";

export default {
  name: "modal_new_award",
  components:{modal_partner_entity},
  props:['personId','update','old_award'],
  created(){
    this.isLoading = true
    this.getAwardTypes()
  },
  data(){
    return{
      isLoading: false,
      award_type: [],
      keepFirst: false,
      openOnFocus: false,
      clearable: false,
      partner_entities:[],
      award: {},
      isAddModalEntityActive: false,
      canCancel: false,
      partner_entity_name: '',
      errores_validacion: {},
      isPartnerEntityRegister: false,
    }
  },
  methods:{
    getAwardTypes(){
      axios
          .get('/award_types.json')
          .then(response =>{
            this.award_type = response.data
            this.errores_validacion = {}
            if(this.update){
              this.getAward()
            }
            else{
              this.isLoading = false
            }
          })
          .catch(error =>{
            console.log(error)
            this.errores_validacion = error.response.data
            this.isLoading = false
          })
    },
    async checkSave() {
      const valid = await this.$refs.observer.validate()
      this.isPartnerEntityRegister = false
      if(!valid){
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
      else{
        if (this.award.partner_entity_name != '' && this.award.partner_entity_name != null) {
          if (this.award.partner_entity_id == null) {
            this.isPartnerEntityRegister = true
          } else {
            this.saveAward()
          }
        }
        else{
          this.saveAward()
        }
      }

    },
    saveAward(){
      this.isLoading = true
      this.award.person_id = this.personId
      if(this.update){
        axios
            .put('/awards/'+this.award.id+'.json',{
              award:this.award
            })
            .then(response=>{
              this.$buefy.dialog.alert({
                message: 'Los datos del premio fueron actualizados satisfactoriamente.',
                confirmText: 'Ok'
              });
              this.errores_validacion = {}
              this.$emit('close')
              this.$emit('refresh')
              this.isLoading = false
            })
            .catch(error=>{
              console.log(error)
              this.errores_validacion = error.response.data
              this.isLoading = false
              this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Ocurrio un error al editar los datos.',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
              });
            })
      }
      else{
        axios
            .post('/awards.json',{
              award: this.award
            })
            .then(response =>{
              this.$buefy.dialog.alert({
                message: 'Los datos del premio fueron guardados satisfactoriamente.',
                confirmText: 'Ok'
              });
              this.errores_validacion = {}
              this.$emit('close')
              this.$emit('refresh')
              this.isLoading = false
            })
            .catch(error => {
              console.log(error)
              this.errores_validacion = error.response.data
              this.isLoading = false
              this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Ocurrio un error al guardar los datos.',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
              });
            })
      }
    },
    getAward(){
      this.isLoading = true
      axios
          .get('/awards/'+this.old_award.id+'.json')
          .then(response => {
            this.award = response.data
            this.award.award_date = moment(this.award.award_date, ["YYYY-MM-DD","DD-MM-YYYY"])._d;
            this.isLoading = false
          })
          .catch(error =>{
            console.log(error)
            this.isLoading = false
          })
    },
    searchPartnerEntities(name){
      this.award.partner_entity_id = null
      if (name.length<3) {
        this.partner_entities = []
        return
      }
      let vm = this;
      vm.isLoading=true;
      axios
          .get("/admin/partner_entities/agreement-search.json", { params: {
              name: name
            } })
          .then(res => {
            vm.partner_entities = res.data;
            this.errores_validacion = {}
            vm.isLoading = false;
          })
          .catch(error => {
            console.log(error)
            this.errores_validacion = error.response.data
            vm.isLoading=false;
          })
    },
    clearEntity(){
      this.award.partner_entity_id = null
      this.award.partner_entity_name = null
    },
    onClickChild(value){
      this.isPartnerEntityRegister = false
      if(value.name != '' && value.name != null){
        this.award.partner_entity_name = value.name
      }
      else{
        this.award.partner_entity_name = value.full_name
      }
      this.award.partner_entity_id = value.id

    },
    addEntity(){
      this.isAddModalEntityActive = true;
    },
  }
}
</script>

<style scoped>

</style>