<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(verifyDocument)">
        <!-- Box de Formulario HouseholdAllowance -->
        <div class="modal-card" style="width: auto" id="modal-event">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" id="modal-event2" ref="modalHouseholdForm">
            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion" :key='index'>
                      {{ item[0] }}
                    </div>
                  </b-message>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Nombres*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input v-model="household_allowance.name" type="text" minlength="1" maxlength="255"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Primer Apellido*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input v-model="household_allowance.last_name" type="text" minlength="1" maxlength="255"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Segundo Apellido">
                  <b-input v-model="household_allowance.second_surname" type="text" maxlength="255"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column is-3">
                <b-field label="Rut">
                  <ValidationProvider rules="numeric" v-slot="{ errors }">
                    <b-input v-model="rut"  type="text" @input="updateDv"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column is-1">
                <b-field label="Dv">
                  <b-input v-model="dv"  type="text" :disabled="!rut"></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de nacimiento">
                  <ValidationProvider v-slot="{errors}">
                    <b-datepicker
                        v-model="household_allowance.birthday"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                    >
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
              <b-field label="Tipo de Carga*">
                <ValidationProvider rules="required" v-slot="{errors}">
                  <multiselect v-model="household_allowance.dependent_type_id"
                               :options="dependent_types.map(type => type.id)"
                               :custom-label="opt => dependent_types.find(x => x.id === opt).name"
                               placeholder="Seleccione tipo de carga"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                  </multiselect>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
              <div class="column">
                <b-field label="Parentesco">
                  <multiselect v-model="household_allowance.relationship"
                               :options="relationships.map(type => type.name)"
                               placeholder="Seleccione Parentesco"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                               :allow-empty="true">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Fecha de inicio*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-datepicker
                        v-model="household_allowance.start_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                        @input="generateName"

                    >
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de término">
                  <ValidationProvider  v-slot="{errors}">
                    <b-datepicker
                        v-model="household_allowance.expiration_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                        @input="generateName"
                    >
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Resolución">
                  <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                    <b-input v-model="household_allowance.decree" type="text" @input="generateName"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Resolución">
                  <b-datepicker
                      v-model="household_allowance.decree_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                      @input="generateName"
                  >
                  </b-datepicker>
                </b-field>
              </div>

            </div>
<!--            <div class="columns">
              <div class="column">
                <b-field label="Url carpeta documentación Asignación Familiar">
                  <ValidationProvider v-slot="{ errors }" rules="max:255">
                    <b-input v-model="household_allowance.documentation_link" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>-->
            <div class="columns">
              <div class="column">
                <div class="field">
                  <b-field label="Observaciones">
                    <b-input
                        type="textarea"
                        minlength="10"
                        maxlength="500"
                        v-model="household_allowance.observations">
                    </b-input>
                  </b-field>
                </div>
              </div>
            </div>

            <div v-if="queryType == 'update'" class="columns">
              <div class="column is-4">
                <b-field label="Nombre del documento">
                  <b-input v-model="document_name_update" type="text" :disabled="true"></b-input>
                </b-field>
              </div>
              <div class="column is-4">
                <b-field label="¿Desea cambiar o agregar un archivo?">
                  <b-radio-button v-model="changeFile"
                                  :native-value="true"
                                  type="is-success is-light is-outlined">
                    <b-icon icon="check-circle"></b-icon>
                    <span>Si</span>
                  </b-radio-button>
                  <b-radio-button v-model="changeFile"
                                  :native-value="false"
                                  type="is-danger is-light is-outlined">
                    <b-icon icon="times-circle"></b-icon>
                    <span>No</span>
                  </b-radio-button>
                </b-field>
              </div>
            </div>
            <div v-if="queryType == 'create' || changeFile || queryType == 'copy'" class="columns has-text-centered">
              <div class="column">
                <b-field extended label="Archivo PDF (Tamaño max. 25 MB)">
                  <b-upload v-model="file"
                            drag-drop
                            type="is-primary"
                            accept=".pdf"
                            @input="generateName">
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon
                              icon="file-pdf"
                              size="is-large">
                          </b-icon>
                        </p>
                        <p>Arrastra tu PDF o haz click para subir.</p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>
              </div>
            </div>
            <div v-if="queryType == 'create' || changeFile || queryType == 'copy'" class="columns tags is-centered">
              <span
                  v-if="file !== null"
                  v-model="file"
                  class="tag is-primary mt-1">
                {{ file.name }}
                <button
                    class="delete is-small"
                    type="button"
                    @click="onDeleteFile"
                ></button>
              </span>
            </div>

            <div v-if="file !== null" class="columns">
              <div class="column">
                <b-field label="Nombre del documento a subir">
                  <b-input disabled v-model="document_name"></b-input>
                </b-field>
              </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit">Guardar </button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios"
export default {
  name: "modal_household_form",
  props: [
      'queryType', 'personId', 'id','modalHeader', 'person_name'
  ],
  data(){
    return {
      household_allowance:{},
      rut: "",
      dv: "",
      errores_validacion:{},
      relationships: [
        {name: "CONYUGE"},
        {name: "CONYUGE INVALIDO"},
        {name: "HIJO(A)"},
        {name: "HIJO(A) MAYOR DE 18 AÑOS"},
        {name: "NIETO(A)"},
        {name: "BISNIETO(A)"},
        {name: "ASCENDIENTE MAYOR DE 65 AÑOS"},
        {name: "PADRE"},
        {name: "MADRE"},
        {name: "ABUELO(A)"},
        {name: "MADRE VIUDA"},
        {name: "CAUSANTE INVALIDO"}

      ],
      dependent_types: [],
      isLoading: false,
      file: null,
      document_name: null,
      changeFile: false,
      document_name_update: null,
      document_id: null,
    }
  },
  created() {
    this.isLoading=true;
    if (this.queryType === "create"){
      this.household_allowance.person_id = this.personId
      this.household_allowance.household_allowance_type_id = 1
      axios
          .get("/household_allowances/new.json")
          .then(res => {

            this.dependent_types = res.data["dependent_types"]
            this.isLoading=false;
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }
    else if (this.queryType === 'update'){
      axios
          .get("/household_allowances/"+this.id+"/edit.json")
          .then(res => {
            this.household_allowance = res.data
            this.household_allowance.household_allowance_type_id = 1
            this.dependent_types = res.data.dependent_types
            if(res.data["rut"]!==null){
              var rut = res.data["rut"].split("-");
              this.rut = rut[0];
              this.dv = rut[1];
            }
            if(res.data["birthday"]!==null){
              this.household_allowance.birthday = new Date(this.formatDate(res.data["birthday"]))
            }
            if(res.data["decree_date"]!==null){
              this.household_allowance.decree_date = new Date(this.formatDate(res.data["decree_date"]))
            }
            if(res.data["expiration_date"]!==null){
              this.household_allowance.expiration_date = new Date(this.formatDate(res.data["expiration_date"]))
            }
            if(res.data["start_date"]!==null){
              this.household_allowance.start_date = new Date(this.formatDate(res.data["start_date"]))
            }
            this.document_id =  res.data["document_id"]
            this.document_name_update = res.data["document_name"]
            this.document_name = res.data["document_name"]
            this.isLoading = false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })
    }
    else if(this.queryType === "copy"){
      axios
          .get("/household_allowances/"+this.id+"/init-copy.json", {
            params: {copy: true}
          })
          .then(res => {
            this.household_allowance = res.data
            this.household_allowance.household_allowance_type_id = 1
            this.dependent_types = res.data.dependent_types
            if(res.data["rut"]!==null){
              var rut = res.data["rut"].split("-");
              this.rut = rut[0];
              this.dv = rut[1];
            }
            if(res.data["birthday"]!==null){
              this.household_allowance.birthday = new Date(this.formatDate(res.data["birthday"]))
            }
            if(res.data["expiration_date"]!==null){
              this.household_allowance.expiration_date = new Date(this.formatDate(res.data["expiration_date"]))
            }
            if(res.data["decree_date"]!==null){
              this.household_allowance.decree_date = new Date(this.formatDate(res.data["decree_date"]))
            }
            if(res.data["start_date"]!==null){
              this.household_allowance.start_date = new Date(this.formatDate(res.data["start_date"]))
            }
            this.isLoading = false;
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }
  },
  methods: {
    updateDv(){
      if(!this.rut){
        this.dv = ""
      }
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
    verifyDocument(){
      this.isLoading= true;
      let id = null
      if(this.queryType==="update"){
       id = this.household_allowance.id
      }
      axios
          .get("/life_record/validate_resolution_number.json", {
            params: {res_number: this.household_allowance.decree, query_type: this.queryType,
            entity_type: "household_allowance", id: id}
          })
          .then(response =>{
            this.saveHouseholdAllowance()
          }).catch(error => {
            console.log(error)
        if(error.response.status === 409){ //se encuentra
          this.$buefy.dialog.confirm({
            hasIcon: true,
            type: "is-warning",
            title: "Advertencia",
            message: error.response.data["message"],
            confirmText: "Continuar y guardar",
            cancelText: "Cancelar",
            onConfirm: () => this.saveHouseholdAllowance(),
            onCancel: () => this.isLoading = false
          })
        }
        else if(error.response.status === 400){//bad request
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalHouseholdForm.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al agregar la carga familiar, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
        }
      })
    },
    saveHouseholdAllowance(){
      this.isLoading=true;
      if(this.rut && this.dv){
        this.household_allowance.rut = this.rut+"-"+this.dv;
      }
      else{
        this.household_allowance.rut = ""
      }
      if (this.queryType==="create"){

        if (this.file != null && this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Este archivo no tiene formato PDF",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        if (this.file != null && this.file.size > 1024*1024*25) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "El archivo no puede superar el maximo de 25 MB",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        /*if (this.file != null && (this.household_allowance.decree_date ==  null || this.household_allowance.decree_date ==  undefined) &&
            (this.household_allowance.decree == null || this.household_allowance.decree == undefined || this.household_allowance.decree == '')) {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Para adjuntar un archivo, debe llenar al menos uno de los campos del documento",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }*/

        let formData = new FormData();

        // Agrega datos al objeto FormData
        formData.append("household_allowance", JSON.stringify(this.household_allowance));
        formData.append("file", this.file); // Aquí agregamos el archivo
        formData.append("document_name", this.document_name);

        axios
            .post("/household_allowances/create-document", formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
            .then(response =>{
              this.errores_validacion = {};
              this.$buefy.dialog.alert({
                message: 'La carga familiar fue agregada con éxito ',
                confirmText: 'Ok'
              })
              this.isLoading=false;
              this.errors = [];
              this.$emit('close')
            }).catch(error => {
              this.errores_validacion = error.response.data
              var elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              this.$refs.modalHouseholdForm.scrollTop = 0;
              this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Hubo errores al agregar la carga familiar, favor revisar formato',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              this.isLoading=false;
            })
      }
      else if (this.queryType==="update"){
        if (this.file != null && this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Este archivo no tiene formato PDF",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        if (this.file != null && this.file.size > 1024*1024*25) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "El archivo no puede superar el maximo de 25 MB",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        /*if (this.file != null && (this.household_allowance.decree_date ==  null || this.household_allowance.decree_date ==  undefined) &&
            (this.household_allowance.decree == null || this.household_allowance.decree == undefined || this.household_allowance.decree == '')) {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Para adjuntar un archivo, debe llenar al menos uno de los campos del documento",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }*/

        let formData = new FormData();
        let clean_household_allowance = this.deleteData(this.household_allowance)

        // Agrega datos al objeto FormData
        formData.append("household_allowance", JSON.stringify(clean_household_allowance));
        formData.append("file", this.file); // Aquí agregamos el archivo
        formData.append("document_name", this.document_name);
        axios
            .put("/household_allowances/" + this.id+"/update-document.json", formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
            .then(response => {
              this.$buefy.dialog.alert({
                message: 'La carga familiar fue editada con éxito ',
                confirmText: 'Ok'
              })
              this.errores_validacion = {};
              this.isLoading=false;
              this.$emit('close')
            }).catch(error => {
              this.errores_validacion = error.response.data
              let elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              this.$refs.modalHouseholdForm.scrollTop = 0;
              this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Hubo errores al editar la carga familiar, favor revisar formato',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              this.isLoading=false;
          })
      }
      else if (this.queryType==="copy"){
        if (this.file != null && this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Este archivo no tiene formato PDF",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        if (this.file != null && this.file.size > 1024*1024*25) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "El archivo no puede superar el maximo de 25 MB",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        /*if (this.file != null && (this.household_allowance.decree_date ==  null || this.household_allowance.decree_date ==  undefined) &&
            (this.household_allowance.decree == null || this.household_allowance.decree == undefined || this.household_allowance.decree == '')) {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Para adjuntar un archivo, debe llenar al menos uno de los campos del documento",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }*/

        let formData = new FormData();
        let clean_household_allowance = this.deleteData(this.household_allowance)

        // Agrega datos al objeto FormData
        formData.append("household_allowance", JSON.stringify(clean_household_allowance));
        formData.append("file", this.file); // Aquí agregamos el archivo
        formData.append("document_name", this.document_name);
        axios
            .post("/household_allowances/copy.json", formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
            .then(response => {
              this.$buefy.dialog.alert({
                message: 'La carga familiar fue copiada con éxito ',
                confirmText: 'Ok'
              })
              this.errores_validacion = {};
              this.isLoading=false;
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          let elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalHouseholdForm.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al copiar la carga familiar, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
        })
      }
    },
    onDeleteFile() {
      this.file = null;
    },
    generateName(){
      var tipo = "ASIGNACION_FAMILIAR"
      var number = ""
      var date = ""
      var start_date = ""
      var end_date = ""
      var full_name = "-"+this.person_name


      if (this.household_allowance.decree != null && this.household_allowance.decree != ''){
        number = "-N_" + this.household_allowance.decree
        tipo = "RES"
      }

      if (this.household_allowance.decree_date != null){
        date = "-"+("0" + this.household_allowance.decree_date.getDate()).slice(-2)
            + "_" + ("0" + (this.household_allowance.decree_date.getMonth()+1)).slice(-2)
            + "_" + this.household_allowance.decree_date.getFullYear()

        tipo = "RES"
      }

      if (this.household_allowance.start_date != null){
        start_date = "-"+("0" + this.household_allowance.start_date.getDate()).slice(-2)
            + "_" + ("0" + (this.household_allowance.start_date.getMonth()+1)).slice(-2)
            + "_" + this.household_allowance.start_date.getFullYear()
      }

      if (this.household_allowance.expiration_date != null){
        end_date = "-"+("0" + this.household_allowance.expiration_date.getDate()).slice(-2)
            + "_" + ("0" + (this.household_allowance.expiration_date.getMonth()+1)).slice(-2)
            + "_" + this.household_allowance.expiration_date.getFullYear()
      }

      if ((this.household_allowance.decree_date == null || this.household_allowance.decree_date == '')  &&
          (this.household_allowance.decree == null || this.household_allowance.decree == '')){
        this.document_name = tipo + full_name + start_date + end_date
      }
      else{
        this.document_name = tipo + number + date
      }
    },
    deleteData(household_allowance){
      delete household_allowance.document_id
      delete household_allowance.document_name
      delete household_allowance.can_view_pdf
      delete household_allowance.dependent_types
      delete household_allowance.household_allowance_type
      delete household_allowance.dependent_type

      return household_allowance
    },

  }
}
</script>
