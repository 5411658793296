<template>
    <section>

        <div v-if="can_create_related_institution === 'true'" class="columns">
            <div class="column has-text-right">
                <b-button type="is-primary" icon-left="plus" label="Agregar Afiliación" @click="newRelatedEntity()" />
            </div>
        </div>

        <b-table striped :data="related_institutions" paginated per-page=10 :pagination-simple=false
            pagination-position="bottom" aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
            aria-current-label="Página actual">

            <b-table-column label="Nombre Institución" field="partner_entity_name" sortable searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Institución..."
                        icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.partner_entity_name }}
                </template>
            </b-table-column>

            <b-table-column label="Miembro en Calidad de" field="type_member" sortable searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Calidad..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.type_member }}
                </template>
            </b-table-column>

            <b-table-column label="Fecha de Inicio" field="start_date" sortable searchable :custom-search="searchInitDate">
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL") : '' }}
                </template>
            </b-table-column>

            <b-table-column label="Fecha de Término" field="end_date" sortable searchable :custom-search="searchEndDate">
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL") : 'Actualidad'
                    }}
                </template>
            </b-table-column>

            <b-table-column v-show="cargoPrincipal.name == 'ANALISTA I+D FING' || cargoPrincipal.name == 'SUPERADMIN'"
                label="Acciones" v-slot="props">
                <div class="columns">
                    <div v-if="props.row.can_show" class="column">
                      <b-tooltip
                          label="Mostrar en detalle la información de la afiliación"
                          :active="true">
                          <b-button pack="fas" icon-right="eye" type="is-info"
                                  @click="isModalShowActive = true; selectedRelatedInstitution = props.row" />
                      </b-tooltip>
                    </div>
                    <div v-if="props.row.can_update" class="column">
                        <b-tooltip
                            label="Editar la información de la afiliación"
                            :active="true">
                            <b-button pack="fas" icon-right="edit" type="is-warning"
                                    @click="isModalActive = true; selectedRelatedInstitution = props.row; update = true" />
                        </b-tooltip>
                    </div>
                    <div v-if="props.row.can_null" class="column">
                        <b-tooltip
                            label="Anular registro de la afiliación"
                            :active="true">
                            <b-button pack="fas" icon-right="ban" type="is-danger"
                                    @click="nullRelatedInstitution(props.row)" />
                        </b-tooltip>

                    </div>
                </div>
            </b-table-column>

            <template slot="empty">
                <div class="columns is-centered">
                    <div class="column is-6 is-centered has-text-centered">
                        <b-message has-icon type="is-warning">
                            <h1> No se encontraron resultados.</h1>
                        </b-message>
                    </div>
                </div>
            </template>

        </b-table>

        <b-modal v-model="isModalActive" has-modal-card trap-focus :destroy-on-hide="true" width="90%" aria-role="dialog"
            aria-modal :can-cancel="false">
            <template #default="props">
                <ModalNewRelatedInstitution :person-id="personId" :update="update"
                    :old_related_institution="selectedRelatedInstitution" @close="props.close" @refresh="getRelatedInstitutions()"/>
            </template>
        </b-modal>

        <b-modal v-model="isModalShowActive" has-modal-card trap-focus :destroy-on-hide="true" width="90%" aria-role="dialog"
            aria-modal :can-cancel="false">
            <template #default="props">
                <ModalShowRelatedInstitution :person-id="personId"
                    :old_related_institution="selectedRelatedInstitution" @close="props.close" @refresh="getRelatedInstitutions()"/>
            </template>
        </b-modal>

        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

    </section>
</template>

<script>
import axios from 'axios'
import moment from "moment"
import { searchDate } from "../../packs/utilities"
import ModalNewRelatedInstitution from "./modal_new_related_institution"
import ModalShowRelatedInstitution from './modal_show_related_institution.vue'
export default {
    props: [
        'personId', 'photoUrl', 'cargoPrincipal', 'canCreateDocument', 'minYear', 'maxYear', 'changeFilter', 'can_create_related_institution'
    ],

    components: {
        ModalNewRelatedInstitution, ModalShowRelatedInstitution
    },

    data() {
        return {
            isLoading: false,
            related_institutions: [],
            isModalActive: false,
            isModalShowActive: false,
            selectedRelatedInstitution: {},
            update: false,
        }
    },

    async created() {
        await this.getRelatedInstitutions()
    },

    methods: {

        async getRelatedInstitutions() {
            this.isLoading = true
            await axios
                .get('/my-related-institutions/' + this.personId + '.json', {params: {
                    minYear: this.minYear,
                    maxYear: this.maxYear,
                }})
                .then(response => {
                    this.related_institutions = response.data
                    this.related_institutions = this.related_institutions.map(institution => {
                        var newInstitution = institution
                        newInstitution["start_date"] = moment(newInstitution["start_date"], ["YYYY-MM-DD", "DD-MM-YYYY"]);
                        newInstitution["end_date"] = moment(newInstitution["end_date"], ["YYYY-MM-DD", "DD-MM-YYYY"])
                        return newInstitution
                    })
                    this.isLoading = false
                })
                .catch(error => {
                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al traer sus datos Afiliaciones, favor de revisar los errores.',
                        type: 'is-danger',
                        hasIcon: true,
                        iconPack: 'fa',
                        icon: 'times-circle',
                        confirmText: 'Ok',

                    })
                    console.log(error)
                    this.isLoading = false
                })
        },
        searchInitDate(row, input) {
            return searchDate(row.start_date, input)
        },
        searchEndDate(row, input) {
            return searchDate(row.end_date, input)
        },

        newRelatedEntity() {
            this.update = false
            this.isModalActive = true
        },

        nullRelatedInstitution(relatedInstitution) {
            
            this.$buefy.dialog.confirm({
                title: 'Anular Afiliación',
                message: '¿Está seguro que desea <b>anular</b> la Afiliación? Esta acción lo dejará inválido.<br>',
                confirmText: 'Anular Afiliación',
                cancelText: 'Cancelar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: async () => {
                    this.isLoading = true
                    await axios
                        .delete("/related_institutions/" + relatedInstitution.id + "/nullify-related-institution.json")
                        .then(response => {
                            this.$buefy.dialog.alert({
                                title: 'Logrado',
                                type: 'is-success',
                                hasIcon: true,
                                icon: 'check-circle',
                                iconPack: 'fas',
                                message: 'La Afiliación ha sido anulada exitosamente.',
                                confirmText: 'Aceptar'
                            })
                            this.getRelatedInstitutions()
                        })
                        .catch(error => {
                            this.$buefy.dialog.alert({
                                title: 'Error',
                                message: 'Hubo un error al anular la Afiliación.<br><b>Errores:</b><br>' + error.response.data,
                                type: 'is-danger',
                                hasIcon: true,
                                icon: 'times-circle',
                                iconPack: 'fa',
                                ariaRole: 'alertdialog',
                                ariaModal: true
                            })
                            this.isLoading = false
                        })
                }
            })

        }

    },
    watch: {
        changeFilter: function () {
            this.getRelatedInstitutions()
        }
    }
}
</script>