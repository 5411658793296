<template>
  <section>

        <div class="columns">

          <div class="column has-text-right">
            <b-field label="Marcado para prórroga semi-automática">
              <b-checkbox :value="person_unit.renewable" disabled></b-checkbox>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Descripción">
              <b-input :value="person_unit.description" type="textarea" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Tipo de documento">
              <b-input :value="person_unit.resolution_type!==undefined ? person_unit.resolution_type.name : ''" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="N° de documento">
              <b-input :value="person_unit.resolution" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha documento">
              <b-input :value="formatDate(person_unit.resolution_date)" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Tipo de nombramiento">
              <b-input :value="person_unit.appointment_type!==undefined ? person_unit.appointment_type.name : ''" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Cargo">
              <b-input :value="person_unit.work_plant ? person_unit.work_plant.name : ''" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Categoría">
              <b-input :value="person_unit.category!==undefined ? person_unit.category.category : ''" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
      <div class="columns">
          <div class="column">
            <b-field label="Unidad de contratación">
              <b-input :value="person_unit.contracting_unit!==undefined ? person_unit.contracting_unit.name : ''" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Unidad de desempeño">
              <b-input :value="person_unit.unit!==undefined? person_unit.unit.name : ''" type="text" readonly>
              </b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Calidad de contrato">
              <b-input :value="person_unit.quality!==undefined ? person_unit.quality : ''" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha desde">
              <b-input :value="formatDate(person_unit.start_date)" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha hasta">
              <b-input :value="formatDate(person_unit.end_date)" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
<!--        <div class="columns">
          <div class="column">
            <b-field label="Url Documentación">
              <b-input expanded readonly v-model="person_unit.documentation_link" type="text"></b-input>
              <p class="control">
                <b-button tag="a" :href="person_unit.documentation_link" target="_blank" icon-left="external-link-alt" type="is-link">Link</b-button>
              </p>
            </b-field>
          </div>
        </div>-->
        <div class="container">
          <section>
            <b-field label="Asignaturas">

            </b-field>

            <b-table
                :data="selected_hr_subjects"
                :paginated=true
                :bordered="true"
                per-page=3
                striped
                hoverable
                :current-page="currentPage"
                :pagination-simple=false
                pagination-position="bottom"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="publication.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
              <b-table-column
                  field= 'name'
                  label= 'Nombre de la Asignatura*'
                  width="30em"
                  sortable
                  v-slot="props"
              >
                <b-field>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-taginput
                        v-model="props.row.subject"
                        :data="filtered_hr_subjects"
                        type="is-primary is-light"
                        maxtags="1"
                        autocomplete
                        maxlength="155"
                        :allow-new="true"
                        field="name"
                        icon="tag"
                        :disabled="true"
                        placeholder="Agregar o escoger asignaturas"
                    >
                    </b-taginput>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </b-table-column>
              <b-table-column
                  field= 'hours'
                  label= 'Horas de Asignatura*'
                  sortable
                  v-slot="props"
              >
                <b-field>
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-input v-model="props.row.hours" type="number" min="0" :disabled="true"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>

                </b-field>
              </b-table-column>
              <b-table-column
                  field= 'hours'
                  label= 'Horas Actuales de Asignatura'
                  sortable
                  v-slot="props"
              >
                <b-field>
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-input v-model="props.row.substracted_hours" type="number" min="0" :disabled="true"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </b-table-column>
              <b-table-column
                  field= 'ps_code'
                  label= 'Código PeopleSoft'
                  sortable
                  v-slot="props"
              >
                <b-field>
                  <ValidationProvider v-slot="{errors}">
                    <b-input minlength="0" maxlength="155" v-model="props.row.ps_code" type="text" :disabled="true"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </b-table-column>
              <b-table-column
                  field= 'siac_code'
                  label= 'Código SIAC'
                  sortable
                  v-slot="props"
              >
                <b-field>
                  <ValidationProvider v-slot="{errors}">
                    <b-input minlength="0" maxlength="155" v-model="props.row.siac_code" type="text" :disabled="true"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </b-table-column>

            </b-table>
          </section>
        </div>

        <div class="columns">
          <div class="column">
            <b-field label="Jornada (horas)">
              <b-input :value="person_unit.taken_hours" type="text" readonly>
              </b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Jornada actual (horas actuales)">
              <b-input :value="person_unit.actual_hours" type="text" readonly>
              </b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Año">
              <b-input :value="person_unit.year!==undefined? person_unit.year : ''" type="text" readonly>
              </b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Semestre">
              <b-input :value="person_unit.semester!==undefined? person_unit.semester : ''" type="text" readonly>
              </b-input>
            </b-field>
          </div>
        </div>



    <b-loading :is-full-page="true"
               v-model="isLoading"
               :can-cancel="false"/>
  </section>
</template>

<script>
import axios from "axios";
import AppointmentForm from "../life_record/modal_hour_appointment_form.vue"
import DistanceForm from "../life_record/modal_distance_form.vue"
import {formatDate} from "../../packs/utilities";

export default {
  name: "appointment",
  props:[
    "id", "modalHeader"
  ],
  components:{
    AppointmentForm,
    DistanceForm
  },
  data(){
    return {
      isLoading: false,
      isEditModalActive:false,
      person_unit: {},
      currentPage:1,
      cardLayout:false,
      selected_hr_subjects: [],
      filtered_hr_subjects:[],
      has_hr_subjects:[],
      isDistanceModalActive: false
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.isLoading = true;
      this.selected_hr_subjects = []
      return axios
          .get("/admin/person_units/" + this.id + ".json")
          .then(res => {
            this.person_unit = res.data
            if (this.person_unit && this.person_unit.hr_subjects){
              for(var i=0;i<this.person_unit.hr_subjects.length;i++){

                this.selected_hr_subjects.push({id:this.person_unit.hr_subjects[i].id,
                  name: this.person_unit.hr_subjects[i].name, ps_code: this.person_unit.hr_subjects[i].ps_code, siac_code: this.person_unit.hr_subjects[i].siac_code,
                  hours: this.person_unit.has_hr_subjects[i].hours, index: i, ps_code_disabled: true,
                  subject: [this.person_unit.hr_subjects[i].name], substracted_hours: res.data["appointment_has_hr_subjects"][i].substracted_hours})
              }
            }
            this.isLoading = false;
          }).catch(error => {
            console.log(error)
            this.isLoading = false;
          })
    },
    formatDate,
  },
  watch: {
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
  }
}
</script>