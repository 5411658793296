<template>
    <section>

        <div>
            <b-modal v-model="showFormAgreement" has-modal-card trap-focus :destroy-on-hide="true" width="100%"
                aria-role="dialog" aria-modal :can-cancel="false">
                <template #default="props">
                    <ModalNewEditAgreement :personId="personId" :cargoPrincipal="cargoPrincipal"
                        :principalUnitId="principalUnitId" :edit="editAgreement" :agreementId="selectedAgreementId"
                        @close="props.close" @refresh="getAgreements()" />
                </template>
            </b-modal>

            <b-modal v-model="showAgreement" has-modal-card trap-focus :destroy-on-hide="true" width="100%"
                aria-role="dialog" aria-modal :can-cancel="false">
                <template #default="props">
                    <ModalShowAgreement :personId="personId" :cargoPrincipal="cargoPrincipal"
                        :agreementId="selectedAgreementId" @close="props.close" />
                </template>
            </b-modal>
        </div>

        <div class="columns" v-show="canCreateMyVimeAgreement">
            <div class="column has-text-right">
                <b-button type="is-primary" icon-left="plus" label="Agregar Convenio"
                    @click="showFormAgreement = true; editAgreement = false" />
            </div>
        </div>

        <b-table striped :data="agreements" :paginated=true per-page=10 pagination-position="bottom"
            aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
            aria-current-label="Página actual">

            <b-table-column label="Nombre" field="name" sortable searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.name }}
                </template>
            </b-table-column>

            <b-table-column label="Categoría General" field="agreement_type.agreement_general_type" sortable searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar General..."
                        icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.agreement_type.agreement_general_type }}
                </template>
            </b-table-column>

            <b-table-column label="Categoría Específica" field="agreement_type.agreement_specifict_type" sortable
                searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Específica..."
                        icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.agreement_type.agreement_specifict_type }}
                </template>
            </b-table-column>

            <b-table-column label="Etapa del Convenio" field="agreement_stage" sortable searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Etapa..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.agreement_stage }}
                </template>
            </b-table-column>

            <b-table-column label="Estado del Convenio" field="agreement_state" sortable searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Estado..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.agreement_state }}
                </template>
            </b-table-column>

            <b-table-column v-show="cargoPrincipal.name == 'ANALISTA I+D FING' || cargoPrincipal.name == 'SUPERADMIN' || cargoPrincipal.name == 'ACADEMICO'" 
                label="Acciones" v-slot="props">
                <div class="columns">
                    <div class="column">
                        <b-button pack="fas" icon-right="eye" type="is-info"
                            @click="showAgreement = true; selectedAgreementId = props.row.id" />
                    </div>
                    <div v-show="props.row.can_edit" class="column">
                        <b-tooltip label="Editar en detalle la información del Convenio VIME" :active="true">
                            <b-button pack="fas" icon-right="edit" type="is-warning"
                                @click="showFormAgreement = true; selectedAgreementId = props.row.id; editAgreement = true" />
                        </b-tooltip>
                    </div>
                </div>
            </b-table-column>

            <template slot="empty">
                <div class="columns is-centered">
                    <div class="column is-6 is-centered has-text-centered">
                        <b-message has-icon type="is-warning">
                            <h1> No se encontraron resultados.</h1>
                        </b-message>
                    </div>
                </div>
            </template>

        </b-table>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </section>
</template>

<script>
import axios from 'axios'
import ModalNewEditAgreement from './new_edit_academic_agreement.vue'
import ModalShowAgreement from '../moduloVIME/agreements/show/modal_show_academic_agreement.vue'
export default {
    components: {
        ModalNewEditAgreement, ModalShowAgreement
    },
    props: [
        'personId', 'cargoPrincipal', 'canCreateDocument', 'minYear', 'maxYear', 'changeFilter', 'principalUnitId', 'canCreateMyVimeAgreement'
    ],
    data() {
        return {
            isLoading: false,
            agreements: [],
            showFormAgreement: false,
            editAgreement: false,
            selectedAgreementId: null,
            showAgreement: false
        }
    },
    created() {
        this.getAgreements()
    },
    methods: {
        async getAgreements() {
            this.isLoading = true
            await axios
                .get('/people/'+ this.personId +'/all_agreements.json', {
                    params: {
                        minYear: this.minYear,
                        maxYear: this.maxYear,
                    }
                })
                .then(response => {
                    this.agreements = response.data
                    this.isLoading = false
                })
                .catch(e => {
                    this.$buefy.toast.alert({
                        title: 'Error',
                        message: 'Hubo un error al traer los Convenios Vime de la persona consultada, favor de revisar la consola.',
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    console.log(e)
                    this.isLoading = false
                })
        },
    },
    watch: {
        changeFilter: function () {
            this.getAgreements()
        }
    }
}
</script>