<template>
  <section>
    <div class="modal-card" style="width: auto" id="modal-event">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column">
            <b-table :data="peopleFound"
                     :paginated=true
                     per-page=10
                     striped
                     hoverable
                     :current-page="currentPage"
                     :pagination-simple=false
                     pagination-position="bottom"
                     default-sort-direction="desc"
                     sort-icon="arrow-up"
                     sort-icon-size="is-small"
                     default-sort="year"
                     aria-next-label="Siguiente"
                     aria-previous-label="Anterior"
                     aria-page-label="Página"
                     aria-current-label="Página actual">
              <b-table-column
                  field= 'name'
                  label= 'Nombre'
                  width="20em"
                  searchable
                  sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  <a :href="getPersonUrl(props.row.id)" target="_blank">{{ props.row.name }}</a>
                </template>
              </b-table-column>
              <b-table-column
                  field= 'corporate_mail'
                  label= 'Correo'
                  searchable
                  sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{ props.row.corporate_mail ? props.row.corporate_mail : props.row.personal_mail }}
                </template>
              </b-table-column>
              <b-table-column
                  field= 'unit'
                  label= 'Unidad'
                  searchable
                  sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{  props.row.unit }}
                </template>
              </b-table-column>
              <b-table-column
                  field= 'active'
                  label= '¿Está registrado?'
                  searchable
                  sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{  props.row.is_registered }}
                </template>
              </b-table-column>
              <b-table-column
                  field= 'active'
                  label= '¿Está activo?'
                  searchable
                  sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{  props.row.active }}
                </template>
              </b-table-column>
            </b-table>
          </div>
        </div>
      </section>
    </div>
    <footer class="modal-card-foot actions" style="justify-content: flex-end;">
      <div class="field is-grouped is-pulled-right">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: "ModalPeopleFound",
  props:['modalHeader','peopleFound'],
  data(){
    return{
      currentPage: 1
    }
  },
  methods:{
    getPersonUrl(person_id){
        return "/people/"+person_id;
    },
  }
}
</script>

<style scoped>

</style>