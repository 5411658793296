<template>
  <section>
    <form>
      <div class="modal-card" style="width: 1080px">
        <header class="modal-card-head">
          <p class="modal-card-title">Datos del Premio</p>
          <button
              type="button"
              class="delete"
              @click="$emit('close')"/>
        </header>
        <section class="modal-card-body">

          <div class="columns">
            <div class="column">
              <b-field label="Nombre del Premio, Honor o Distinción">
                  <b-input v-model="award.name" readonly></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Características del premio">
                <b-input type="textarea" v-model="award.description" readonly></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Clase del premio">
                <b-input v-model="award.award_type" readonly> </b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Institución Otorgante">
                <b-input v-model="award.partner_entity_name" readonly></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Fecha">
                <b-input
                    v-model="award.award_date"
                    readonly
                >
                </b-input>
              </b-field>
            </div>
          </div>
          <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
        </section>
      </div>
      <footer class="modal-card-foot actions">
        <div class="has-text-right is-grouped">
          <button class="button" type="button" @click="$emit('close')">Cerrar</button>
          <b-button class="is-primary"
                    type="submit"
                    @click="editAward()">
            Editar Premio
          </b-button>
        </div>
      </footer>
      <b-modal
          v-model="isModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          width="1024"
          aria-role="dialog"
          aria-modal
          :can-cancel="false">
        <template #default="props">
          <modal_new_award
              :person-id="personId"
              :update="update"
              :old_award="award"
              @close="props.close"
              @refresh="$emit('refresh')" />
        </template>
      </b-modal>
    </form>
  </section>
</template>

<script>
import modal_new_award from "./modal_new_award";
import axios from "axios";
import moment from "moment";
export default {
  name: "modal_show_award",
  components:{modal_new_award},
  props:['personId','old_award'],
  created() {
    this.initialize()
  },
  data(){
    return{
      isLoading: false,
      update: false,
      award: {},
      isModalActive: false
    }
  },
  methods:{
    editAward(){
      this.update = true
      this.isModalActive = true
    },
    initialize(){
      this.update = false
      this.isLoading = true
      axios
          .get('/awards/'+this.old_award.id+'.json')
          .then(response => {
            this.award = response.data
            this.award.award_date = moment(this.award.award_date, ["YYYY-MM-DD","DD-MM-YYYY"])._d;
            this.award.award_date = new Date(this.award.award_date).toLocaleDateString("es-CL")
            this.isLoading = false
          })
          .catch(error =>{
            console.log(error)
            this.isLoading = false
          })
    }
  },
  watch: {
    isModalActive: function () {
      if (!this.isModalActive) {
        this.initialize()
      }
    },
  }
}
</script>

<style scoped>

</style>