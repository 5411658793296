<template>
  <div class="box">
    <AdministrativePermitsBreadcrums :breadcrumbs="breadcrumbs" />
    <section class="columns mb-0">
      <div class="column">
        <h1 class="subtitle">Permisos con goce de remuneraciones:</h1>
        <h2 class="title mb-0">
          {{ formatedPersonName() }}
        </h2>
        <span
          class="has-text-weight-medium subtitle no-selectable mouse-pointer"
          :style="{
            whiteSpace: 'nowrap',
          }"
          @click="copyToClipboard(person.run)"
        >
          (RUT: {{ person.run }})
        </span>
      </div>
      <div
        class="column is-full-mobile is-flex is-justify-content-flex-end"
        :class="!hasPermitsOnNextYear ? 'is-two-fifths-desktop' : ''"
      >
        <div style="width: 100%" class="custom-columns">
          <AdministrativePermitsPersonDaysCard
            :used-days="usedDays(new Date().getFullYear())"
            :remaining-days="6 - usedDays(new Date().getFullYear())"
            :loading="isLoading"
          />
          <AdministrativePermitsPersonDaysCard
            v-if="hasPermitsOnNextYear"
            title="Situación futura"
            :current-year="new Date().getFullYear() + 1"
            :used-days="usedDays(new Date().getFullYear() + 1)"
            :remaining-days="6 - usedDays(new Date().getFullYear() + 1)"
            :loading="isLoading"
          />
        </div>
      </div>
    </section>
    <AdministrativePermitsTable
      :administrative-permits="administrativePermits"
      :permit-states="permitStates"
      :person="person"
      :person-used-days="usedDays(new Date().getFullYear())"
      :modal-form-status="modalFormStatus"
      @handleEditPermits="onEditPermits"
      @handleSavePermits="onSavePermits"
      @handleDownload="downloadPdf"
    />
    <b-loading v-model="isLoading" :is-full-page="true" :can-cancel="false" />
  </div>
</template>

<script>
import axios from "axios";
import AdministrativePermitsBreadcrums from "./AdministrativePermitsBreadcrums.vue";
import AdministrativePermitsTable from "./AdministrativePermitsTable.vue";
import AdministrativePermitsPersonDaysCard from "./AdministrativePermitsPersonDaysCard.vue";
import { handleNetworkError } from "../.././packs/utilities";

export default {
  components: {
    AdministrativePermitsBreadcrums,
    AdministrativePermitsTable,
    AdministrativePermitsPersonDaysCard,
  },
  props: {
    person: {
      type: Object,
      required: true,
    },
    permitStates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      breadcrumbs: [
        { href: "/life_record", label: "Menú principal" },
        {
          href: "/administrative-permits",
          label: "Permisos administrativos",
        },
        {
          href: "/administrative-permits/search",
          label: "Buscador de personas",
        },
        {
          href: "#",
          label: this.formatedPersonName(),
          isActive: true,
        },
      ],
      isLoading: false,
      administrativePermits: [],
      failedFetch: false,
      modalFormStatus: false,
    };
  },
  computed: {
    hasPermitsOnNextYear() {
      if (this.administrativePermits.length === 0) {
        return false;
      }
      return this.administrativePermits.some((permit) => {
        return (
          permit.adm_permits.filter((p) => {
            const permitYear = new Date(p.start_date).getFullYear();
            return (
              (p.request_state.name === "APROBADO" ||
                p.request_state.name === "EN CURSO") &&
              permitYear === new Date().getFullYear() + 1
            );
          }).length > 0
        );
      });
    },
  },
  async created() {
    this.isLoading = true;
    await this.getAdministrativePermits();
    this.isLoading = false;
  },

  methods: {
    handleNetworkError,
    async getAdministrativePermits() {
      this.failedFetch = false;
      try {
        const response = await axios.get(
          `/administrative-permits/${this.person.id}/permits.json`,
          { headers: { Accept: "application/json" } }
        );
        if (response.status === 200) {
          this.administrativePermits = response.data;
        }
      } catch (error) {
        this.failedFetch = true;
      }
    },
    async onSavePermits(editedPermits) {
      this.isLoading = true;
      this.modalFormStatus = false;
      try {
        const response = await axios.post(
          `/administrative-permits/${this.person.id}/permits.json`,
          { permits: editedPermits }
        );
        if (response.status === 200) {
          this.administrativePermits.unshift(response.data);
          this.$buefy.notification.open({
            message: "Se ha creado el permiso administrativo exitosamente",
            type: "is-success",
            size: "is-medium",
            "has-icon": true,
            position: "is-bottom-right",
            "progress-bar": true,
            duration: 5000,
            queue: true,
          });
          await this.downloadPdf({
            documentId: response.data.adm_permit_doc.id,
            personId: this.person.id,
          });
          this.modalFormStatus = true;
        }
      } catch (error) {
        if (this.handleNetworkError(error)) {
          return;
        }
        const errorMessage =
          error.response && error.response.data.errors
            ? error.response.data.errors
            : "Ocurrió un error al crear el permiso administrativo";

        this.$buefy.notification.open({
          message: errorMessage,
          type: "is-danger",
          size: "is-medium",
          "has-icon": true,
          position: "is-bottom-right",
          indefinite: true,
          queue: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async downloadPdf({ documentId, personId }) {
      this.isLoading = true;
      try {
        const response = await axios.get(
          `/administrative-permits/${personId}/permits-pdf/${documentId}.pdf`,
          { responseType: "blob" }
        );

        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        const filename = this.extractFilenameFromHeaders(response.headers);

        link.href = url;
        link.setAttribute("download", `${filename}.pdf`);
        link.click();
        this.$buefy.notification.open({
          message: `Se ha generado el permiso administrativo (IDS ${documentId}) exitosamente`,
          type: "is-success",
          size: "is-medium",
          "has-icon": true,
          position: "is-bottom-right",
          indefinite: true,
          "progress-bar": true,
          queue: true,
        });
      } catch (error) {
        if (this.handleNetworkError(error)) {
          return;
        }
        const errorMessage =
          error.response && error.response.data.errors
            ? error.response.data.errors
            : "Ocurrió un error al generar el permiso administrativo";

        this.$buefy.notification.open({
          message: errorMessage,
          type: "is-danger",
          size: "is-medium",
          "has-icon": true,
          position: "is-bottom-right",
          queue: true,
        });
      } finally {
        this.isLoading = false;
      }
    },

    extractFilenameFromHeaders(headers) {
      const contentDisposition = headers["content-disposition"];
      const match = contentDisposition.match(/filename="(.+?)"/);

      if (match && match[1]) {
        return decodeURIComponent(match[1]);
      }

      return null;
    },

    async onEditPermits({ personId, editedPermits, documentId }) {
      this.isLoading = true;
      this.modalFormStatus = false;

      try {
        const response = await axios.put(
          `/administrative-permits/${personId}/permits.json`,
          {
            person_id: personId,
            permits: editedPermits,
            document_id: documentId,
          }
        );
        if (response.status === 200) {
          const permitPosition = this.administrativePermits.findIndex(
            (permit) => {
              if (
                permit.adm_permit_doc.id === response.data.adm_permit_doc.id
              ) {
                return response.data;
              }
            }
          );
          this.administrativePermits.splice(permitPosition, 1, response.data);

          this.$buefy.notification.open({
            message: "Se ha actualizado el permiso administrativo exitosamente",
            type: "is-success",
            size: "is-medium",
            "has-icon": true,
            position: "is-bottom-right",
            "progress-bar": true,
            duration: 5000,
            queue: true,
          });
          this.modalFormStatus = true;
        }
      } catch (error) {
        if (this.handleNetworkError(error)) {
          return;
        }
        const errorMessage =
          error.response && error.response.data.errors
            ? error.response.data.errors
            : "Ocurrió un error al actualizar el permiso administrativo";

        this.$buefy.notification.open({
          message: errorMessage,
          type: "is-danger",
          size: "is-medium",
          "has-icon": true,
          position: "is-bottom-right",
          indefinite: true,
          queue: true,
        });
      } finally {
        this.isLoading = false;
      }
    },

    async getHolydays() {
      this.isLoading = true;
      // Implementación a futuro.
      this.isLoading = false;
    },
    colorByStatus() {
      const countPersons = this.administrativePermits.length;
      return countPersons === 0 ? "is-danger" : "is-success";
    },
    formatedPersonName() {
      const name = `${this.person.name} ${this.person.last_name} ${this.person.second_surname}`;
      const lowercaseWords = ["de", "del", "la", "las", "los", "y"];

      return name
        .toLowerCase()
        .split(" ")
        .map((word, index) =>
          lowercaseWords.includes(word) && index !== 0
            ? word
            : word.charAt(0).toUpperCase() + word.substring(1)
        )
        .join(" ");
    },
    async copyToClipboard(rut) {
      try {
        await navigator.clipboard.writeText(rut);
        this.$buefy.toast.open({
          message: "RUT copiado al portapapeles",
          type: "is-success",
          size: "is-medium",
          position: "is-bottom-right",
          "progress-bar": true,
          duration: 5000,
          queue: true,
        });
      } catch (err) {
        this.$buefy.toast.open({
          message: "No se pudo copiar el RUT al portapapeles",
          type: "is-danger",
          size: "is-medium",
          position: "is-bottom-right",
          "progress-bar": true,
          duration: 5000,
          queue: true,
        });
      }
    },
    usedDays(currentYear = new Date().getFullYear()) {
      let total = 0;
      for (const request of this.administrativePermits) {
        total += request.adm_permits.reduce((total, permit) => {
          const permitYear = new Date(permit.start_date).getFullYear();
          if (
            (permit.request_state.name === "APROBADO" ||
              permit.request_state.name === "EN CURSO") &&
            permitYear === currentYear
          ) {
            return total + parseFloat(permit.number_of_days);
          }
          return total;
        }, 0);
      }
      return total;
    },
  },
};
</script>
<style scoped>
.no-selectable {
  user-select: none;
}
.mouse-pointer {
  cursor: pointer;
}

@media (max-width: 767px) {
  .box {
    border-radius: 0%;
    box-shadow: none;
    min-height: calc(100vh - 64px);
    background-color: white;
  }
  .title {
    font-size: 1.5rem;
  }
  .subtitle {
    font-size: 1.2rem;
  }
  .custom-columns {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .box {
    border-radius: 0%;
    box-shadow: none;
    min-height: calc(100vh - 64px);
    background-color: white;
  }
  .custom-columns {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
}

@media (min-width: 1024px) {
  .box {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .custom-columns {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
}
</style>
