<template>
  <section>
      <div class="box">
        <div class="columns">
          <div class="column">
            <!-- Nombre de la actividad -->
            <div class="field">
              <div class="field-label is-normal">
                <label class="label" style="text-align:left;">Nombre*</label>
              </div>
              <div class="field-body">
                <b-field
                    :type="{ 'is-danger': checkActivityName }"
                    :message="{ 'Ingrese un nombre para la actividad.': checkActivityName }">
                  <b-input type="text"
                           maxlength="255"
                           placeholder="Ingrese el nombre de la actividad"
                           :value="activityName!==undefined ? activityName : ''"
                           v-model="activityName">
                  </b-input>
                </b-field>
              </div>
            </div>
          </div>
          <div class="column">
            <!-- Tema de la actividad -->
            <div class="field">
              <div class="field-label is-normal">
                <label class="label" style="text-align:left;">Tema*</label>
              </div>
              <div class="field-body">
                <b-field
                    :type="{ 'is-danger': checkActivityTheme }"
                    :message="{ 'Ingrese un tema para la actividad.': checkActivityTheme }">
                  <b-input type="text"
                           maxlength="255"
                           placeholder="Ingrese brevemente el tema de la actividad"
                           :value="activityTheme!==undefined ? activityTheme : ''"
                           v-model="activityTheme">
                  </b-input>
                </b-field>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <!-- Descripción de la actividad -->
            <div class="field">
              <div class="field-label is-normal">
                <label class="label" style="text-align:left;">Descripción*</label>
              </div>
              <div class="field-body">
                <b-field
                    :type="{ 'is-danger': checkActivityDescription }"
                    :message="{ 'Ingrese una descripción para la actividad.': checkActivityDescription }">
                  <b-input type="textarea"
                           placeholder="Ingrese brevemente la descripción de la actividad"
                           maxlength="1024"
                           :value="activityDescription!==undefined ? activityDescription : ''"
                           v-model="activityDescription">
                  </b-input>
                </b-field>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <!-- Link de la actividad -->
            <div class="field">
              <div class="field-label is-normal">
                <label class="label" style="text-align:left;">Link*</label>
              </div>
              <div class="field-body">
                <b-field
                    :type="{ 'is-danger': checkActivityLink,
                                     'is-danger': checkActivityLinkFormat }"
                    :message="[{ 'Ingrese el link para la actividad.': checkActivityLink },
                                       { 'Formato no válido.': checkActivityLinkFormat }]">
                  <b-input type="text"
                           maxlength="255"
                           placeholder="Ingrese el link de la actividad"
                           :value="activityLink!==undefined ? activityLink : ''"
                           v-model="activityLink">
                  </b-input>
                </b-field>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <div class="field-label is-normal">
                <label class="label" style="text-align:left;">Número de asistentes</label>
              </div>
              <div class="field-body">
                <b-field
                    :type="{ 'is-danger': !checkIsNumber }"
                    :message="{ 'Ingrese un número valido.': !checkIsNumber }">
                  <b-input type="text"
                           maxlength="255"
                           placeholder="Ingrese el número de asistentes"
                           :value="activityParticipant!==undefined ? activityParticipant : ''"
                           v-model="activityParticipant">
                  </b-input>
                </b-field>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <!-- Fecha de inicio de la actividad -->
            <div class="field">
              <div class="field-label is-normal">
                <label class="label" style="text-align:left;">Fecha de inicio</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <b-datepicker placeholder="Seleccione la fecha de inicio de la actividad"
                                :value="activityStartDate!=null ? activityStartDate : ''"
                                icon="calendar-alt"
                                editable
                                locale="es-ES"
                                v-model="activityStartDate"
                                required> </b-datepicker>
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <!-- Fecha de término de la actividad -->
            <div class="field">
              <div class="field-label is-normal">
                <label class="label" style="text-align:left;">Fecha de término</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <b-datepicker placeholder="Seleccione la fecha de término de la actividad"
                                required
                                icon="calendar-alt"
                                :min-date="minBenginningEndDate"
                                :value="activityEndDate!=null ? activityEndDate : ''"
                                v-model="activityEndDate"
                                editable
                                locale="es-ES"
                                :disabled='this.activityInfo.start_date == null'>
                  </b-datepicker>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <!-- Tipo de actividad -->
            <div class="field">
              <div class="field-label is-normal">
                <label class="label" style="text-align:left;">Tipo de actividad*</label>
              </div>
              <b-field
                  :type="{ 'is-danger': checkActivityTypeName }"
                  :message="{ 'Seleccione un tipo de actividad.': checkActivityTypeName }">
                <multiselect
                    v-model="activityType"
                    :options="activity_types.map(type => type.id)"
                    :custom-label="opt => activity_types.find(x => x.id === opt).name"
                    placeholder="Seleccione un tipo de actividad"
                    selectLabel="Presione para seleccionar"
                    selectedLabel="Seleccionado"
                    deselectLabel=""
                    :allow-empty="false">
                </multiselect>
              </b-field>
            </div>
          </div>
          <div v-if="cargo_principal.name == 'ACADEMICO' && query_type == 'create'" class="column">
            <b-field label="Participo como*"
                     :type="{ 'is-danger': checkAssistanceTypeName}"
                     :message="{ 'Seleccione el tipo de participación.': checkAssistanceTypeName }">
              <multiselect
                  v-model='newPersonAssistantTypeId'
                  :options="vime_assistant_types.map(type => type.id)"
                  :custom-label="opt => vime_assistant_types.find(x => x.id === opt).name"
                  placeholder="Seleccione un tipo de participación"
                  selectLabel="Presione para seleccionar"
                  selectedLabel="Seleccionado"
                  deselectLabel=""
                  :allow-empty="false">
              </multiselect>
            </b-field>
          </div>
          <div class="column">
            <!-- Es o no internacional la actividad -->
            <b-field label="¿Es internacional?">
              <div class="columns">
                <div class="column">
                  <b-radio-button v-model="activityInfo.is_national"
                                  :native-value="false"
                                  type="is-danger is-light is-outlined"

                  >
                    <b-icon icon="times-circle"></b-icon>
                    <span>No</span>
                  </b-radio-button>
                </div>
                <div class="column">
                  <b-radio-button v-model="activityInfo.is_national"
                                  :native-value="true"
                                  type="is-success is-light is-outlined"
                  >
                    <b-icon icon="check-circle"></b-icon>
                    <span>Si</span>
                  </b-radio-button>
                </div>
              </div>
            </b-field>

          </div>
        </div>
        <!-- Botones de control -->
        <div v-show="!from_academic" class="columns">
          <div class="column">
            <div class="is-pulled-left">
              <button class="button is-link is-light" @click="back()">Volver</button>
            </div>
          </div>
          <div class="column">
            <div class="is-pulled-right" v-if="edit_case == true">
              <b-button class="button is-primary" @click="save(1)" >Guardar Actividad</b-button>
            </div>
            <div class="is-pulled-right" v-else>
              <b-button class="button is-primary" @click="save(0)" >Guardar Actividad</b-button>
            </div>
          </div>
        </div>
      </div>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
  </section>
</template>
<script>
import axios from 'axios'
import moment from "moment";
export default {
    name: 'VimeActivityNewForm',
    props: ['edit_case','vime_activity_id','cargo_principal','query_type', 'from_academic', 'add_activity'],
    data (){
        return {
            // variables controladoras
            isLoading:true,
            isSubmiting: false,
            checkIsNumber: true,
            // Para almacenamiento temporal
            activity_types: [],
            vime_activity_type_name: null,
            vime_assistant_types:[],
            vime_assistant_type_name: null,

            new_person:{
              person_id: -1,
              assistance_type_id: -1,
              corporate_mail: null,
              phone: -1,
              is_registered: null,
            },

            // para el envío de datos
            activityInfo:{
                name: '',
                theme: '',
                description: '',
                link: '',
                start_date: null,
                end_date: null,
                is_national: false,
                vime_activity_type_id: 0,
                assistants_number: null,
            },

            // Para las validaciones
            validations:{
                isActivityNameEmpty: true,
                isActivityThemeEmpty: true,
                isActivityDescriptionEmpty: true,
                isActivityParticipantEmpty: true,
                isActivityLinkEmpty: true,
                isActivityTypeNameEmpty: true,
                isAssistantTypeNameEmpty: true,
                isActivityLinkFormat: true,
                firstTimeName: true,
                firstTimeTheme: true,
                firstTimeDescription: true,
                firstTimeLink: true,
                firstTimeParticipant: true,
                firstTimeActivityTypeName: true,
                firstTimeActivityAssistantType: true,
                firstTimeActivityAssistantTypeName: true,
            }
        }
    },
    computed:{
        // Para el control de los atributos
        activityStartDate:{
            get: function(){
                return this.activityInfo.start_date
            },
            set: function(newDate){
                this.activityInfo.start_date = newDate
                if(newDate > this.activityInfo.end_date){
                    this.activityInfo.end_date = newDate
                }
                if(newDate == null){
                    this.activityInfo.end_date = null
                }
            }
        },
        activityEndDate:{
            get: function(){
                return this.activityInfo.end_date
            },
            set: function(newDate){
                if(this.activityInfo.start_date == null){
                    this.activityInfo.end_date = null
                }
                else{
                    if(newDate < this.activityInfo.start_date){
                        this.activityInfo.end_date = null
                    }
                    else{
                        this.activityInfo.end_date = newDate
                    }
                }
            }
        },
        newPersonAssistantTypeId:{
          get(){
            return this.vime_assistant_type_name
          },
          set(newValue){
            this.validations.firstTimeActivityAssistantType = false
            if(newValue === '' || newValue === null || newValue === undefined)
              this.validations.isAssistantTypeNameEmpty = true
            else
              this.validations.isAssistantTypeNameEmpty = false
            this.vime_assistant_type_name = newValue
          }
        },
        activityName:{
            get: function(){
                return this.activityInfo.name
            },
            set: function(newName){
                this.validations.firstTimeName = false
                if(newName === '')
                    this.validations.isActivityNameEmpty = true
                else
                    this.validations.isActivityNameEmpty = false
                this.activityInfo.name = newName
            }
        },
        activityTheme:{
            get: function(){
                return this.activityInfo.theme
            },
            set: function(newTheme){
                if(newTheme === '')
                    this.validations.isActivityThemeEmpty = true
                else
                    this.validations.isActivityThemeEmpty = false
                this.activityInfo.theme = newTheme
            }
        },
        activityDescription:{
            get: function(){
                return this.activityInfo.description
            },
            set: function(newDescription){
                if(newDescription === '')
                    this.validations.isActivityDescriptionEmpty = true
                else
                    this.validations.isActivityDescriptionEmpty = false
                this.activityInfo.description = newDescription
            }
        },
        activityParticipant:{
          get: function (){
            return this.activityInfo.assistants_number
          },
          set: function(newParticipant){
            if(newParticipant === ''){
              this.validations.isActivityParticipantEmpty = true
              this.checkIsNumber = true
            }
            else {
              this.validations.isActivityParticipantEmpty = false
              var number = Number(newParticipant)
              if (typeof number === 'number') {
                if (number >= 0) {
                  this.checkIsNumber = true
                } else {
                  this.checkIsNumber = false
                }
              }
            }
            this.activityInfo.assistants_number= newParticipant
          }
        },
        activityLink:{
            get: function(){
                return this.activityInfo.link
            },
            set: function(newLink){
                if(newLink === ''){
                    this.validations.isActivityLinkEmpty = true
                }
                else{
                    this.validations.isActivityLinkEmpty = false
                    var regExp = /http[s]?\:\/\/[a-zA-Z 0-9]+\.[a-z]+/
                    if(!regExp.test(newLink))
                        this.validations.isActivityLinkFormat = true
                    else
                        this.validations.isActivityLinkFormat = false
                }
                this.activityInfo.link = newLink
            }
        },
        activityType:{
            get: function(){
                return this.vime_activity_type_name
            },
            set: function(newName){
                this.validations.firstTimeActivityTypeName = false
                if(newName === '' || newName === null || newName === undefined)
                    this.validations.isActivityTypeNameEmpty = true
                else
                    this.validations.isActivityTypeNameEmpty = false              
                this.vime_activity_type_name = newName
            }
        },

        // Validaciones en los campos del formulario
        checkActivityName(){
            if(this.validations.firstTimeName === false)
                return this.validations.isActivityNameEmpty
            else
                return false
        },
        checkActivityTheme(){
            if(this.validations.firstTimeTheme === false)
                return this.validations.isActivityThemeEmpty
            else
                return false
        },
        checkActivityDescription(){
            if(this.validations.firstTimeDescription === false)
                return this.validations.isActivityDescriptionEmpty
            else
                return false
        },
        checkActivityParticipant(){
          if(this.validations.firstTimeParticipant === false)
            return this.validations.isActivityParticipantEmpty
          else
            return false
        },
        checkActivityLink(){
            if(this.validations.firstTimeLink === false)
                return this.validations.isActivityLinkEmpty
            else
                return false
        },
        checkActivityLinkFormat(){
            var regExp = /http[s]?\:\/\/[a-zA-Z 0-9]+\.[a-z]+/
            if(this.validations.firstTimeLink === false){
                return !regExp.test(this.activityInfo.link)
            }
            else{
                return false
            }
        },
        checkActivityTypeName(){
            if(this.validations.firstTimeActivityTypeName === false)
                return this.validations.isActivityTypeNameEmpty
            else
                return false
        },
        checkAssistanceTypeName(){
          if(this.validations.firstTimeActivityAssistantTypeName === false)
            return this.validations.isAssistantTypeNameEmpty
          else
            return false
        },
        minBenginningEndDate: function(){
            return this.activityInfo.start_date
        },
        checkActivityAssistantType() {
          if(this.validations.firstTimeActivityAssistantType)
            return false
          else
            return this.new_person.assistance_type_id === -1
        }

    },
    beforeMount(){
        // Se ejecutarán antes de renderizar la página
        this.getEntitiesAssistancesTypesToModal()

        // En caso de que se desee editar una actividad
        if(this.edit_case){
            this.getActivityTypesUpdate()
            this.validations.isActivityNameEmpty = false
            this.validations.isActivityThemeEmpty = false
            this.validations.isActivityDescriptionEmpty = false
            this.validations.isActivityLinkEmpty = false
            this.validations.isActivityTypeNameEmpty = false
            this.validations.isActivityLinkFormat = false
        }
        else{
          this.getActivityTypes()
        }
    },
    methods:{
        // Consultas al backend
        getVimeActivity(){
            axios.get('/vime_activities/'+this.vime_activity_id+'.json')
            .then(response =>{
                this.vime_activity = response.data
                this.activityInfo.name = response.data.name
                this.activityInfo.theme = response.data.theme
                this.activityInfo.description = response.data.description
                this.activityInfo.link = response.data.link
                if (response.data.start_date != null){
                  this.activityInfo.start_date = new Date(moment(response.data.start_date).format('MM/DD/YYYY'));
                }
                if (response.data.end_date != null){
                  this.activityInfo.end_date = new Date(moment(response.data.end_date).format('MM/DD/YYYY'));
                }
                this.activityInfo.is_national = response.data.is_national
                this.activityInfo.assistants_number = response.data.assistants_number
                this.vime_activity_type_name = response.data.vime_activity_type.id
                this.isLoading = false
            })
            .catch(e => {
              this.isLoading = false
                console.log(e)
            })
        },

        getActivityTypesUpdate(){
          axios.get("/vime_activity_types.json")
              .then(res => {
                this.activity_types = res.data;
                this.getVimeActivity()

              })
              .catch(e => {
                console.log(e);
                this.isLoading=false
              });
        },

        getActivityTypes(){
            axios.get("/vime_activity_types.json")
            .then(res => {
                this.activity_types = res.data;
                this.isLoading=false
            })
            .catch(e => {
                console.log(e);
                this.isLoading=false
            });   
        },
        getActivityIdTypeByName(activityTypeName){
            var activtyTypeId = 1
            this.activity_types.forEach(function(element){
                if(element.name === activityTypeName){
                    activtyTypeId = element.id
                }
            })
            return activtyTypeId
        },
        // Identificación de campos vacios
        checkEmptyFields(){
            if(this.validations.firstTimeActivityAssistantType === true){
              this.validations.firstTimeActivityAssistantType = false
            }
            if(this.validations.firstTimeName === true){
                this.validations.firstTimeName = false
            }
            if(this.validations.firstTimeTheme === true){
                this.validations.firstTimeTheme = false
            }
            if(this.validations.firstTimeDescription === true){
                this.validations.firstTimeDescription = false
            }
            if(this.validations.firstTimeLink === true){
                this.validations.firstTimeLink = false
            }
            if(this.validations.firstTimeActivityTypeName === true){
                this.validations.firstTimeActivityTypeName = false
            }
            if(this.validations.firstTimeActivityAssistantTypeName === true){
              this.validations.firstTimeActivityAssistantTypeName = false
            }
        },
        // Validación final previo al envio a backend
        checkSave(){
            var confirmation = true
            var arrayConfirmation = []
            arrayConfirmation.push(this.validations.isActivityNameEmpty)
            arrayConfirmation.push(this.validations.isActivityThemeEmpty)
            arrayConfirmation.push(this.validations.isActivityDescriptionEmpty)
            arrayConfirmation.push(this.validations.isActivityLinkEmpty)
            arrayConfirmation.push(this.validations.isActivityLinkFormat)
            arrayConfirmation.push(this.validations.isActivityTypeNameEmpty)
            if (this.query_type == 'create' && this.cargo_principal.name == 'ACADEMICO'){
              arrayConfirmation.push(this.validations.isAssistantTypeNameEmpty)
            }
            arrayConfirmation.push(!this.checkIsNumber)
            arrayConfirmation.forEach(function(element){
                if(element === true)
                    confirmation = false
            })
            return confirmation
        },
        // Envío de datos al backend
        save(save_case){
            var confirmaton = this.checkSave()
            if(confirmaton === true){
                this.activityInfo.vime_activity_type_id = this.vime_activity_type_name
                this.new_person.assistance_type_id = this.newPersonAssistantTypeId
                if(save_case == 0){
                    this.isLoading=true
                    axios.post('/vime_activities.json', {vime_activity: this.activityInfo, assistance_type_id: this.newPersonAssistantTypeId, from_academic: this.from_academic})
                    .then( response =>{
                      if (this.from_academic) {
                        this.$emit('close')
                        this.$emit('refresh')
                      }else{
                        window.location.href="/vime_activities/"+response.data.id
                      }
                    })
                    .catch(e => {
                        console.log(e)
                        this.isLoading = false
                    })
                }
                else{
                    this.isLoading=true
                    axios.put('/vime_activities/'+this.vime_activity_id+'.json', this.activityInfo, {params: {
                      from_academic: this.from_academic
                    }})
                    .then( response =>{
                      if (this.from_academic) {
                        this.$emit('close')
                        this.$emit('refresh')
                      }else{
                        window.location.href="/vime_activities/"+response.data.id
                      }
                    })
                    .catch(e => {
                        this.isLoading = false
                        console.log(e)
                    })
                }
            }
            else{
                this.checkEmptyFields()
                if(save_case == 0){
                    this.$buefy.dialog.alert({
                        message: "Faltan algunos campos o presentan errores de formato. Por favor revisar",
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        onConfirm: () => {
                        }
                    })
                }
                else{
                    this.$buefy.dialog.alert({
                        message: "Faltan algunos campos o presentan errores de formato. Por favor revisar",
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        onConfirm: () => {
                        }
                    })
                }
            }
        },
        // Para volver al index
        back(){
            window.location.href="/vime_activities"
        },
      getEntitiesAssistancesTypesToModal(){
        axios.get('/vime_assistant_types/get_assistants_types/0.json')
            .then(response =>{
              this.vime_assistant_types = response.data
            })
            .catch(e => {
              console.log(e)
            })
      },
    },
    watch: {
      add_activity: function() {
        this.save(0)
      }
    }
}
</script>