<template>
    <section>
        <div class="columns">
            <div class="column">
                <h3 class="is-size-6 has-text-weight-bold">
                    Editora(or)
                    <b-tooltip
                        label="Solo la/el usuaria/o que es Editora o Editor de esta actividad académica tiene permisos para editar todos sus atributos.
                        Quienes participan en la actividad académica pero no son dueños, solo pueden editar su participación."
                        position="is-right" size="is-large" multilined>
                        <b-icon pack="fas" icon="info-circle" size="is-small" type="is-info">
                        </b-icon>
                    </b-tooltip>
                </h3>
                <b-field label=" ">
                    <b-input v-if="publication.owner!=''" readonly v-model="publication.owner"></b-input>
                    <b-input v-else readonly></b-input>
                </b-field>
            </div>
            <div class="column">
                <b-field label="Correo">
                    <b-input v-if="publication.owner_mail!=''" readonly v-model="publication.owner_mail"></b-input>
                    <b-input v-else readonly></b-input>
                </b-field>
            </div>
        </div>
    </section>
</template>
  
  <script>
  export default {
    name: "AdditionalInformationTab",
    props:['publication']
  }
  </script>
