<template>
  <section>
    <div >
      <div class="modal-card" style="width: auto" id="modal-restore">
        <header class="modal-card-head">
          <p class="modal-card-title">{{modalHeader}}</p>
          <button
              type="button"
              class="delete"
              @click="$emit('close')"/>
        </header>
        <section class="modal-card-body" ref="modalConfirmRestore">
          <div class="columns">
            <div class="column is-2">
              <b-icon
                  pack="fas"
                  icon="exclamation-triangle"
                  size="is-large"
                  type="is-danger">
              </b-icon>
            </div>
            <div class="column" style="padding-left: 10px">
                <p><b>¿Desea restaurar la función?</b></p>
            </div>
            <!--<div class="column">
              <p><b>Relaciones asociadas a la función seleccionada.</b>. <br>
              </p>
            </div>-->
          </div>
          <!--<div class="columns">
            <div class="column">
              <p>
                <b>Número de Asigna/Libera funciones asociados: </b><b>{{ num_person_functions }}</b><br>
              </p>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <p>
                <b>Número de Nombramientos asociados: </b><b>{{ num_person_units }}</b><br>
              </p>
            </div>
          </div>-->
          <!--<div class="columns">
            <div class="column">
              <div class="control" v-if="num_person_functions+num_person_units>0">
                <p><b>¿Desea restaurar la función y todos sus elementos asociados?</b></p>
              </div>
              <div class="control" v-else>
                <p><b>¿Desea restaurar la función?</b></p>
              </div>
            </div>
          </div>-->
        </section>
      </div>
      <footer class="modal-card-foot actions">
        <div class="field is-grouped is-pulled-right">
          <button class="button is-warning" type="button" @click="$emit('close')">Cancelar</button>
          <button class="button is-success" @click="dont_restore_all" >Restaurar <!--solo--> la función </button>
          <!--<b-tooltip label="Al anular la función, esta ya no será seleccionable para asociarla a Asigna/Libera funciones o nombramientos"
                     position="is-top">
            <button class="button is-success" @click="restore_all" :disabled="num_person_functions+num_person_units===0">Si, restaurar asociados </button>
          </b-tooltip>-->
        </div>
      </footer>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "modal_confirm_restore",
  props:[
    'personId', 'id', 'modalHeader', 'num_person_functions', 'num_person_units'
  ],
  data(){
    return {
      isLoading:false,
      disabled: false,
    }
  },
  created() {
    this.isLoading=true
    this.isLoading=false
  },
  methods: {
    dont_restore_all(){
      this.execRestoreModal(this.id, 1)
    },
    restore_all(){
      this.execRestoreModal(this.id, 2)
    },
    execRestoreModal(id, type){
      this.$buefy.dialog.confirm({
        title: 'Restaurar función',
        message: '¿Está seguro que desea <b>restaurar</b> la función? Esta acción la dejará válida.<br>',
        confirmText: 'Restaurar Función',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true
          axios
              .put("/functions/" + id + "/restore-function.json",{type: type})
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Función restaurada correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                this.isLoading = false;
                this.$emit('close')
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Función no pudo ser restaurada</b><br>'+error.response.data["message"],
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
          })
        }
      })
    },
  }
}
</script>