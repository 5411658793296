<template>
  <section>
    <div class="container">
      <h1 class="is-size-2">Entidades</h1>
      <div v-if="can_create" class="has-text-right">
        <button class="button is-primary" @click="newEntity()">
          <b-icon icon="plus"></b-icon>
          <span>Añadir Entidad</span>
        </button>
      </div>
      <br>
      <div  class="box">
        <div class="columns">
          <div class="column">
            <div class="content">
              <h4 class="title is-4">Búsqueda de entidades</h4>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Nombre de entidad">
              <b-input @keyup.enter.native="getPartnerEntities" placeholder="Ingres nombre de entidad" type="text" v-model="name" maxlength="255" :has-counter="false">
              </b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Tipo de entidad">
              <multiselect
                  v-model="entityType"
                  :options="entity_types_data.map(type => type.id)"
                  :custom-label="opt => entity_types_data.find(x => x.id === opt).name"
                  placeholder="Seleccione un tipo de entidad"
                  selectLabel="Presione para seleccionar"
                  selectedLabel="Seleccionado"
                  deselectLabel="Presione para deseleccionar"
              >
              </multiselect>
            </b-field>
          </div>
          <div class="column">
            <b-field label="País">
              <multiselect
                  v-model="entityNationality"
                  :options="nationalities.map(type => type.id)"
                  :custom-label="opt => nationalities.find(x => x.id === opt).name"
                  placeholder="Seleccione un País"
                  selectLabel="Presione para seleccionar"
                  selectedLabel="Seleccionado"
                  deselectLabel="Presione para deseleccionar">
              </multiselect>
            </b-field>
          </div>
          <div class="column is-narrow">
            <b-field label="Entidades relevantes">
              <b-radio-button v-model="entityRelevant"
                              :native-value="false"
                              type="is-danger is-light is-outlined">
                <b-icon icon="times-circle"></b-icon>
                <span>No</span>
              </b-radio-button>
              <b-tooltip
                  label="Hace referencia a las entidades que estan asociadas a personas, proyectos, actividades y programas"
                  multilined
                  position="is-left">
                <b-radio-button v-model="entityRelevant"
                                :native-value="true"
                                type="is-success is-light is-outlined">
                  <b-icon icon="check-circle"></b-icon>
                  <span>Si</span>
                </b-radio-button>
              </b-tooltip>
            </b-field>
          </div>
          <div class="column is-narrow">
            <b-field>
              <template #label>
                <span style="visibility: hidden">Buscar</span>
              </template>
              <b-button icon-left="search" type="is-primary" @click.prevent="getPartnerEntities">Buscar</b-button>
            </b-field>
          </div>
        </div>
      </div>
      <div v-if="hasSearched" class="box">
        <div class="columns">
          <div class="column has-text-right">
            <b-field>
              <b-switch v-model="cardLayout">
          <span>
            Formato tarjeta (sin filtros ni búsqueda)
             <b-tooltip
                 label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                 multilined
                 position="is-left">
                  <b-icon
                      pack="fas"
                      icon="info-circle"
                      type="is-info">
                  </b-icon>
              </b-tooltip>
          </span>
              </b-switch>
            </b-field>
          </div>
        </div>
        <b-table
            :data="partnerEntities"
            :paginated=true
            per-page=10
            striped
            hoverable
            :current-page="currentPage"
            :card-layout="cardLayout"
            :pagination-simple=false
            pagination-position="bottom"
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            default-sort="publication.title"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual">
          <b-table-column
              field= 'name'
              label= 'Nombre entidad'
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              <a @click="goToEntity(props.row.id)"><i class="fas fa-industry"></i> {{ props.row.show_name ? props.row.show_name : props.row.name }}</a>
            </template>
          </b-table-column>
          <b-table-column
              field= 'entity_type'
              label= 'Tipo de entidad'
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.entity_type }}
            </template>
          </b-table-column>
          <b-table-column
              field="email"
              label="Correo"
              searchable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.email }}
            </template>
          </b-table-column>
          <b-table-column
              field="phone"
              label="Teléfono"
              searchable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"/>
            </template>
            <template v-slot="props">
              {{ props.row.phone }}
            </template>
          </b-table-column>
          <b-table-column
              field="nationality"
              label="País"
              searchable
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"/>
            </template>
            <template v-slot="props">
              {{ props.row.nationality }}
            </template>
          </b-table-column>
          <b-table-column
              field="is_public"
              label="¿Es pública?"
              searchable
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"/>
            </template>
            <template v-slot="props">
              {{ props.row.is_public }}
            </template>
          </b-table-column>
          <template slot="empty" slot-scope="props">
            <div class="columns is-centered">
              <div class="column is-6 is-centered has-text-centered">
                <b-message type="is-danger">
                  No se encontraron resultados.
                </b-message>
              </div>
            </div>
          </template>
        </b-table>
      </div>

      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
  </section>
</template>
<script>
import axios from 'axios'
export default {
    name: 'PartnerEntitiesVimeIndex',
    data(){
        return {
          partnerEntities: [],
          isLoading: true,
          currentPage:1,
          cardLayout: false,
          entityType: null,
          entity_types_data: [],
          entityNationality: null,
          entityRelevant: false,
          nationalities: [],
          name:"",
          hasSearched:false,


        }
    },
    props:["can_create"],
    beforeMount(){
        this.isLoading = true
        this.getEntityTypes()
    },
    methods:{
        getPartnerEntities(){
          this.isLoading = true
          this.hasSearched = true;
            axios.get("/partner_entities/index.json",{params:{nationality_id:this.entityNationality,
                entity_type_id:this.entityType, entity_relevant:this.entityRelevant, name:this.name}})
            .then(res => {
                this.partnerEntities = res.data["partner_entities"];
                //this.can_create = res.data["can_create"];
                this.isLoading = false
            })
            .catch(e => {
                this.isLoading=false;
                console.log(e);
            });
        },
        newEntity(){
            this.isLoading=true;
            window.location.href="/partner_entities/new"
        },
        goToEntity(id){
            window.location.href='/partner_entities/show/'+id
        },
        getEntityTypes(){
        axios.get("/admin/entity_types.json")
            .then(response =>{
              this.isLoading = true
              this.entity_types_data = response.data
              //this.entityType = 1
              this.getNationalities()
            })
            .catch(e =>{
              console.log(e)
              this.isLoading = false
            })
      },
      getNationalities(){
        axios.get('/nationalities.json')
            .then( response =>{
              this.nationalities = response.data
              //this.entityNationality = 43
              //this.getPartnerEntities()
              this.isLoading =false

            })
            .catch(e => {
              console.log(e)
              this.isLoading =false
            })
      },
    }
}
</script>