<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveAmount)">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" >
            <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
              <b-message type="is-danger" has-icon>
                <div v-for="(item,index) in errores_validacion" :key="index">
                  {{ item[0] }}
                </div>
              </b-message>
            </div>

            <br>
            <br>

            <div class="columns">
              <div class="column">
                <b-field label="Año*" >
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="newAmount.year"
                                 :options="years"
                                 placeholder="Seleccione año"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                            No se encontraron elementos.
                        </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Monto*" >
                  <ValidationProvider rules="required|numeric|positive|max:12" v-slot="{errors}">
                    <b-input type="text" maxlength="12" placeholder="Ej: 500000" v-model="newAmount.amount"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>

            <br><br>
            <br><br>
            <br><br>


          </section>
        </div>
        <footer class="modal-card-foot actions">
          <button class="button  left-button" type="button" @click="$emit('close')">Cerrar</button>
          <b-button class="button  right-button is-primary" left-button="save" native-type="submit" @click="checkValidation()">Guardar </b-button>

        </footer>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "ModalUploadDocument",
  components:{
  },
  data() {
    return{
      isLoading: false,
      newAmount: {sdt_surplus_id: null, year: new Date().getFullYear(), amount: null},
      errores_validacion:{},
      canCancel: ['escape'],
      message: "",
      years: [],
    }
  },
  props:['queryType','openingAmount', 'modalHeader','idSurplus'
  ],

  created() {
    this.years = this.generateArrayOfYears();
    if (this.queryType=="update"){
      this.newAmount = { ...this.openingAmount};
    }
  },
  methods :{

    saveAmount(){
      let vm = this;
      vm.isLoading = true;
      vm.newAmount.sdt_surplus_id = vm.idSurplus;
      if (vm.queryType=="update"){
        axios
            .put("/sdt_opening_amounts/"+vm.openingAmount.id+".json", {
              sdt_opening_amount: vm.newAmount
            })
            .then((res) => {
              vm.isLoading = false;
              this.$buefy.dialog.alert({
                message: "El monto de apertura anual fue actualizado con éxito.<br/> <br/>",
                confirmText: "Ok",
              });
              vm.$emit("clickedUpdate", res.data)
              vm.$emit('close')
              })
              .catch(e => {
                vm.errores_validacion = e.response.data
                vm.isLoading=false;
              });
      }
      else{

        axios
            .post("/sdt_opening_amounts.json", {
              sdt_opening_amount: vm.newAmount
            })
            .then((res) => {
              vm.isLoading = false;
              this.$buefy.dialog.alert({
                message: "El monto de apertura anual fue creado con éxito.<br/> <br/>",
                confirmText: "Ok",
              });
              vm.$emit("clickedCreate", res.data)
              vm.$emit('close')
            })
            .catch(e => {
              vm.errores_validacion = e.response.data
              vm.isLoading=false;
            });
      }
    },

    generateArrayOfYears() {
      var max = new Date().getFullYear()
      var min = 1980
      var years = []
      years.push("Todo")
      for (var i = max; i >= min; i--) {
        years.push(i)
      }
      return years
    },

    async checkValidation(){
      const valid = await this.$refs.observer.validate()
      if(!valid){
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },

  },



}

</script>

<style scoped>

.right-button{
  margin-left: auto;
}
.left-button{
  margin-right: auto;
}


</style>