<template>
  <div>
    <b-table
        :data="contratos"
        :paginated=true
        per-page=10
        striped
        hoverable
        :current-page="currentPage"
        :pagination-simple=false
        pagination-position="bottom"
        default-sort-direction="desc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="publication.publication_date"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página actual">
    <!---
        :opened-detailed="defaultOpenedDetails"
        detailed
        detail-key="id"
        @details-open="(row) => $buefy.toast.open(`Expandiendo ${row.personName}`)"
        :show-detail-icon="showDetailIcon"
      -->
        


        <b-table-column
          field= 'personName'
          label= 'Nombres'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.personName }}
        </template>
      </b-table-column>


      <b-table-column
          field= 'servicio.code'
          label= 'Código'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
            {{ props.row.servicio.code}}
        </template>
      </b-table-column>
      

      <b-table-column
          field= 'start_date'
          label= 'Inicio Contrato'
          sortable>
        <template v-slot="props">
          {{ props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL"): '' }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'end_date'
          label= 'Término Contrato'
          sortable>
        <template v-slot="props">
          {{ props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL"): '' }}
        </template>
      </b-table-column>
      <b-table-column
          label="Acciones"
          v-slot="props">
        <b-button type="is-primary"
                      icon-left="eye"
                      @click="goService(props.row.servicio, props.row.table)">
            </b-button>  
        <!--<b-icon
            pack="fas"
            icon="trash"
            size="is-medium"
            type="is-danger"
            @click.native="execNullifyModal(props.row.id, props.row)"/>
          -->
      </b-table-column>

      


      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>Nombre del servicio: </strong>
                <span>{{props.row.servicio.name}}</span>
              </p>
              <p>
                <strong>Forma de pago: </strong>
                <span>{{props.row.sdt_payment_type.name}}</span>
              </p>
              <p>
                <strong>Monto: </strong>
                <span>{{props.row.amount}}</span>
              </p>
              <p>
                <strong>Monto Total: </strong>
                <span>{{props.row.total_amount}}</span>
              </p>
            </div>
          </div>
        </article>
      </template>

      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-danger">
              No se encontraron resultados para el año seleccionado.
            </b-message>
          </div>
        </div>
      </template>


    </b-table>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
  props:[
    'objectId','componentType','cargoPrincipal', 'currentYear', 'minYear','maxYear', 'changeFilter', 'hideUpdate'
  ],
  data(){
    return{
      contratos:[],
      isLoading:false,
      defaultOpenedDetails: [1],
      currentPage:1,
      showDetailIcon: true,
      year: new Date().getFullYear(),
    }
  },
  components:{
  },
  created(){
    this.getHonorarios();
  },
  methods:{
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    goService(service,table){
      window.location.href = "/sdt_services/"+service.id+"?type="+table;
    },
    getHonorarios(){
      let vm = this;
      vm.isLoading=true;
      axios
        .get("/sdt_services/"+vm.objectId+"/contracts.json",{params:{
            minYear: vm.minYear,
            maxYear: vm.maxYear,
            componentType: vm.componentType,
        }})
        .then(res => {
          
          vm.contratos = res.data;
          vm.contratos = vm.contratos.map(contrato => {
            var newContrato = contrato
            newContrato["start_date"] = moment(contrato.start_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            newContrato["end_date"] = moment(contrato.end_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            return newContrato;
          });
          vm.isLoading=false;
        })
        .catch(e => {
          console.log(e);
          vm.isLoading=false;
        });
    }
  },
  computed:{

  },
  watch:{
    changeFilter: function(){
      this.getHonorarios()
    }
  }
}
</script>

