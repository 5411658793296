<template>
  <section>
    <validation-observer ref="observer" v-slot="{handleSubmit}">
      <form @submit.prevent="handleSubmit(savePerformance)">
        <div class="modal-card" :style="'width:'+ancho +'px'">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body">
            <div v-if="firstButton && (componentType == 'unit' || componentType == null)">
              <section class="info-tiles">
                <h1 class="title has-text-centered">Selecciona naturaleza del documento
                </h1>
                <div class="tile is-ancestor has-text-centered">
                  <div class="tile is-parent">
                    <article class="tile is-child box">
                      <a @click="unitDocumentation()">
                        <p class="subtitle is-4">
                    <span class="icon-text">
                      <span>Documentación unidad</span>
                    </span>
                        </p>
                      </a>
                    </article>
                  </div>
                  <div class="tile is-parent">
                    <article class="tile is-child box">
                      <a @click="academicDocumentation()">
                        <p class="subtitle is-4">
                    <span class="icon-text">
                      <span>Documentación cuerpo académico</span>
                    </span>
                        </p>
                      </a>
                    </article>
                  </div>
                </div>
              </section>
            </div>
            <div v-else class="box">
              <div class="columns is-centered" v-if="componentType == 'unit' || componentType == null">
                <div class="column is-narrow">
                  <div class="has-text-centered">
                    <b-field label="Naturaleza del documento">
                      <b-radio-button v-model="hasAcademic"
                                      :native-value="false"
                                      type="is-primary is-light is-outlined">
                        <span>Documentación unidad</span>
                      </b-radio-button>
                      <b-radio-button v-model="hasAcademic"
                                      :native-value="true"
                                      type="is-primary is-light is-outlined">
                        <span>Documentación cuerpo académico</span>
                      </b-radio-button>
                    </b-field>
                  </div>
                </div>
              </div>
              <div class="mb-2" v-if="Object.keys(erroresValidacion).length !== 0">
                <b-message type="is-danger" has-icon>
                  <div v-for="(item,index) in erroresValidacion" :key="index">
                    {{ item[0] }}
                  </div>
                </b-message>
              </div>
              <div class="columns" v-if="unit != null">
                <div class="column">
                  <b-field label="Unidad">
                    <b-input disabled v-model="unit.name"></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Centro de costo">
                    <b-input disabled v-model="unit.cost_center"></b-input>
                  </b-field>
                </div>
              </div>

              <div v-if="hasAcademic">
                <div class="columns">
                  <div class="column">
                    <div class="field has-addons">
                      <b-field label="Buscar personas en el sistema">

                        <p class="control has-icons-left">
                          <input v-model='busqueda' class="input" type="text" id="input-busqueda" placeholder="Búsqueda...">
                          <span class="icon is-left"><i class="fas fa-search" aria-hidden="true"></i></span>
                        </p>

                        <div class="control">
                          <b-button @click.prevent="search(busqueda)" class="is-primary" id="boton-buscar">Buscar</b-button>
                        </div>
                      </b-field>
                    </div>
                  </div>
                </div>
                <div class="columns">
                  <div class="column has">
                    <section>
                      <b-table
                          id="tabla-participantes"
                          :data="people"
                          :paginated=true
                          per-page=5
                          striped
                          hoverable
                          :current-page="currentPage"
                          :pagination-simple=false
                          pagination-position="bottom"
                          default-sort-direction="desc"
                          sort-icon="arrow-up"
                          sort-icon-size="is-small"
                          aria-next-label="Siguiente"
                          aria-previous-label="Anterior"
                          aria-page-label="Página"
                          aria-current-label="Página actual"
                      >
                        <b-table-column
                            field= 'person_name'
                            label= 'Nombre'
                            width="20em"
                            searchable
                            sortable>
                          <template
                              slot="searchable"
                              slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                icon="search"
                                size="is-small" />
                          </template>
                          <template v-slot="props">
                            {{  props.row.person_name }}
                          </template>
                        </b-table-column>
                        <b-table-column
                            field= 'run_integral'
                            label= 'RUT'
                            width="20em"
                            searchable
                            sortable>
                          <template
                              slot="searchable"
                              slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                icon="search"
                                size="is-small" />
                          </template>
                          <template v-slot="props">
                            {{  props.row.run_integral }}
                          </template>
                        </b-table-column>
                        <b-table-column
                            label="Acciones"
                            class="align-right"
                            width="20em"
                            v-slot="props">
                          <b-button
                              pack="fas"
                              label="Seleccionar"
                              icon-left="download"
                              type="is-primary is-light is-outlined"
                              @click="seleccionar(props.row)"/>
                        </b-table-column>
                      </b-table>
                    </section>
                  </div>
                </div>
              </div>

              <div class="columns" v-if="persona_seleccionada != null">
                <div class="column">
                  <b-field label="Académico">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-input disabled v-model="persona_seleccionada.person_name"></b-input>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="RUT">
                    <b-input disabled v-model="persona_seleccionada.run_integral"></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns" v-if="persona_seleccionada != null && componentType != 'person'">
                <div class="column">
                  <b-field>
                    <b-button
                        label="Borrar Selección"
                        type="is-danger"
                        icon-left="times"
                        :disabled="!persona_seleccionada"
                        @click="deletePerson" />
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Tipo de documento*">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <multiselect v-model="document_type_name_id"
                                   :options="typesToSelect.map(type => type.id)"
                                   :custom-label="opt => typesToSelect.find(x => x.id === opt).name"
                                   placeholder="Seleccione tipo de documento"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="Presione para deseleccionar"
                                   :allow-empty="true"
                                   @input="generateName">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                      </multiselect>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column" v-if="unit == null">
                  <b-field label="Unidad*">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <multiselect v-model="unidadId"
                                   :options="units.map(unidad => unidad.id)"
                                   :custom-label="opt => units.find(x => x.id === opt).name"
                                   placeholder="Seleccione unidad"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="Presione para deseleccionar"
                                   :allow-empty="true"
                                   @input="generateName">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                      </multiselect>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>

              <div class="columns" v-if="typePrec">
                <div class="column">
                  <b-field label="¿La precalificación es de más de un año?" >
                    <b-radio-button v-model="multipleYears"
                                    :native-value="true"
                                    type="is-success is-light is-outlined">
                      <b-icon icon="check-circle"></b-icon>
                      <span>Si</span>
                    </b-radio-button>
                    <b-radio-button v-model="multipleYears"
                                    :native-value="false"
                                    type="is-danger is-light is-outlined">
                      <b-icon icon="times-circle"></b-icon>
                      <span>No</span>
                    </b-radio-button>
                  </b-field>
                </div>
              </div>

              <div class="columns" v-if="multipleYears">
                <div class="column">
                  <b-field label="Año inicio*" >
                    <validationProvider rules="required" v-slot="{ errors }">
                      <multiselect v-model="startYear"
                                   :options="years.map(year => year)"
                                   placeholder="Seleccione un año"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="Presione para deseleccionar"
                                   :allow-empty="true"
                                   @input="generateName">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                      </multiselect>
                    </validationProvider>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Año termino*">
                    <validationProvider rules="required|greaterThan:startYear" v-slot="{ errors }">
                      <multiselect v-model="endYear"
                                   :options="years.map(year => year)"
                                   placeholder="Seleccione un año"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="Presione para deseleccionar"
                                   :allow-empty="true"
                                   @input="generateName">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                      </multiselect>
                    </validationProvider>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column" v-if="!hasDate && !multipleYears && document.document_type_id != null">
                  <b-field label="Año*">
                    <validationProvider rules="required" v-slot="{ errors }">
                      <multiselect v-model="document.year"
                                   :options="years.map(year => year)"
                                   placeholder="Seleccione año"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="Presione para deseleccionar"
                                   :allow-empty="true"
                                   @input="generateName">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                      </multiselect>
                    </validationProvider>
                  </b-field>
                </div>
                <div class="column" v-if="hasSemester && !hasDate">
                  <b-field label="Semestre*">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <multiselect v-model="document.semester"
                                   :options="semesters.map(semestre => semestre)"
                                   placeholder="Seleccione un semestre"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="Presione para deseleccionar"
                                   :allow-empty="true"
                                   @input="generateName">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                      </multiselect>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column" v-if="hasNumber">
                  <b-field label="N° Documento*" >
                    <ValidationProvider rules="integer|positive|required" v-slot="{ errors }">
                      <b-input v-model="document.resolution" type="text" @input="generateName"></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column" v-if="hasDate">
                  <b-field label="Fecha Documento*">
                    <b-datepicker
                        v-model="document.document_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                        @input="generateName"
                    >
                    </b-datepicker>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="¿El documento está firmado?">
                    <b-radio-button v-model="document.signed"
                                    :native-value="true"
                                    @input="generateName"
                                    type="is-success is-light is-outlined">
                      <b-icon icon="check-circle"></b-icon>
                      <span>Si</span>
                    </b-radio-button>
                    <b-radio-button v-model="document.signed"
                                    :native-value="false"
                                    @input="generateName"
                                    type="is-danger is-light is-outlined">
                      <b-icon icon="times-circle"></b-icon>
                      <span>No</span>
                    </b-radio-button>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Nombre del documento a subir">
                    <b-input disabled v-model="document.name"></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns has-text-centered">
                <div class="column">
                  <b-field extended label="Archivo PDF (Tamaño max. 25 MB)*">
                    <b-upload v-model="file"
                              drag-drop
                              :required="true"
                              type="is-primary"
                              accept=".pdf">
                      <section class="section">
                        <div class="content has-text-centered">
                          <p>
                            <b-icon
                                icon="file-pdf"
                                size="is-large">
                            </b-icon>
                          </p>
                          <p>Arrastra tu PDF o haz click para subir.</p>
                        </div>
                      </section>
                    </b-upload>
                  </b-field>
                </div>
              </div>
              <div class="columns tags is-centered">
            <span
                v-if="file !== null"
                v-model="file"
                class="tag is-primary mt-1"
            >
              {{ file.name }}
              <button
                  class="delete is-small"
                  type="button"
                  @click="onDeleteFile"
              ></button>
            </span>
              </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <button class="button" type="button" @click="$emit('close')">Cerrar</button>
          <button v-if="!firstButton || (componentType != 'unit' && componentType != null)" class="button is-primary" type="submit">Guardar Documento</button>
        </footer>
      </form>
    </validation-observer>
    <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="false"></b-loading>
  </section>
</template>

<script>

import axios from "axios";

export default{
  name: "new_performance_form",
  data(){
    return{
      ancho:1100,

      documentTypes: [],
      documentTypesNames: [],
      typesToSelect: [],
      units: [],
      people: [],
      years: [],
      semesters: [1, 2],

      file: null,
      persona_seleccionada: null,
      document:{
        name: "",
        alt_name: "",
        resolution: null,
        document_date: null,
        document_state_id: 1,
        document_type_id: null,
        year: null,
        semester: null,
        signed: false,
      },

      modalHeader: "",

      hasAcademic: false,
      hasNumber: false,
      hasSemester:false,
      hasDate: false,
      multipleYears: false,
      academicOwner: false,
      typePrec:false,
      startYear: null,
      endYear: null,
      unidadId: null,
      erroresValidacion: [],
      isLoading: false,
      busqueda: "",
      currentPage: 1,
      personId: null,
      document_type_name_id: null,
      firstButton:true

    }
  },
  props:[
    'queryType', 'person', 'componentType', 'unit'
  ],
  components: {

  },
  created(){
    this.years = this.generateArrayOfYears();

    if (this.person != null){
      this.persona_seleccionada = this.person
    }
    if (this.unit != null){
      this.unidadId = this.unit.id
    }
    if (this.queryType == 'create'){
      this.modalHeader = "Subir documento de desempeño - "
      if (this.person != null){
        this.modalHeader += this.person.person_name
      }
      else{
        this.modalHeader += this.unit.name
      }
    }
    else{
      this.modalHeader = "Editar documento de desempeño - " + this.document.name
    }
    this.initialize();
  },
  methods:{
    initialize(){
      this.isLoading = true;
      if (this.queryType === "create"){
        axios
            .get("/documents/new-performance.json")
            .then(res => {
              this.documentTypesNames = res.data["document_types_names"]
              this.units = res.data["units"]
              this.documentTypes = res.data["document_type"]
              if (this.person != null){
                this.typesToSelect = this.documentTypesNames.filter(type => type.initials.includes("DA"))
              }
              else{
                this.typesToSelect = this.documentTypesNames.filter(type => type.initials.includes("DO") || type.initials.includes("DD"))
              }
              this.generateName()
            })
            .catch(e =>{
              console.log(e)
            })
            .finally(() => {
              this.isLoading = false
            });
      }
      this.isLoading = false;
    },
    seleccionar(persona){
      this.persona_seleccionada = persona
    },
    savePerformance(){
      this.isLoading = true;
      this.generateName();

      if(this.queryType === "create"){
        this.document.end_year = this.endYear
        if(this.document.year == null || this.document.year == undefined || this.document.year ==''){
          this.document.year = this.startYear
        }
        if (this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Este archivo no tiene formato PDF",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        if (this.file.size > 1024*1024*25) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "El archivo no puede superar el maximo de 25 MB",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        let formData = new FormData();
        formData.append('file', this.file)
        Object.entries(this.document).forEach(([key, value]) => {
          formData.append(key, value)
        })
        formData.append("unitId", this.unidadId)
        formData.append("personId", this.personId)
        if (this.hasAcademic && this.persona_seleccionada == null){
          this.$buefy.dialog.alert({
            message: 'Error: debe seleccionar un académico',
            duration: 5000,
            type: 'is-danger',
            canCancel: ["escape", "outside"]
          })
          this.isLoading = false
          return;
        }
        axios
            .post("/documents/upload-document", formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
            .then( res => {
              this.$buefy.dialog.alert({
                message: 'Archivo subido de manera exitosa',
                duration: 5000,
                type: 'is-success',
                canCancel: ["escape", "outside"]
              })
              this.$emit('clicked')
              this.$emit('close')
            })
            .catch(e => {
              console.log(e)
              this.$buefy.dialog.alert({
                message: 'Error: no fue posible subir el documento',
                duration: 5000,
                type: 'is-danger',
                canCancel: ["escape", "outside"]
              })
            })
            .finally(() => {
              this.isLoading = false;
            })
      }

    },
    generateName(){
      var tipo = ""
      var centroCosto = ""
      var rut = ""
      var number = ""
      var date = ""
      var signed = ""

      if (this.document.document_type_id != null){
        tipo = this.documentTypesNames.find(type => type.id === this.document.document_type_id).initials
      }

      if (this.unidadId != null){
        centroCosto = "-CC_" + this.units.find(x => x.id == this.unidadId).cost_center
      }

      if (this.persona_seleccionada != null){
        rut = "_RUT_" + this.persona_seleccionada.run_integral.replace("-", "_")
      }

      if (this.document.resolution != null){
        number = "_N_" + this.document.resolution
      }

      if (this.hasDate && this.document.document_date != null){
        date = "-" + ("0" + this.document.document_date.getDate()).slice(-2) + "_"
            + ("0" + (this.document.document_date.getMonth()+1)).slice(-2) +
            "_" + this.document.document_date.getFullYear()
        this.document.year = this.document.document_date.getFullYear()
      }
      else if (this.multipleYears && this.startYear != null && this.endYear != null){
        date = "-AI_" + this.startYear + "_AF_" + this.endYear
      }
      else{
        if (this.document.semester != null && this.document.year != null){
          date = "-0" + this.document.semester + "_" + this.document.year
        }
        if (this.document.year != null && this.document.semester == null) {
          date = "-" + this.document.year
        }
      }

      if (this.document.signed){
        signed = "-CF"
      }
      else{
        signed = "-SF"
      }

      this.document.name = tipo + centroCosto + rut + number + date + signed
      this.document.alt_name = this.document.name
    },
    generateArrayOfYears() {
      var max = new Date().getFullYear()
      var min = 1980
      var years = []
      for (var i = max + 1; i >= min; i--) {
        years.push(i)
      }
      return years
    },
    deletePerson(){
      this.persona_seleccionada = null
    },
    search(busqueda){
      const loadingComponent = this.$buefy.loading.open();
      return axios
          .get("/people/"+busqueda+"/search_all_people.json")
          .then(res => {
            this.people = res.data
            loadingComponent.close();
          }).catch(error => {
            console.log(error)
            loadingComponent.close();
          })
    },
    onDeleteFile() {
      this.file = null;
    },
    unitDocumentation(){
      this.firstButton = false
      this.hasAcademic = false
    },
    academicDocumentation(){
      this.firstButton = false
      this.hasAcademic = true
    }
  },
  watch:{
    hasAcademic(newValue, oldValue){
      this.document.document_type_id = null
      if (newValue){
        this.typesToSelect = this.documentTypesNames.filter(type => type.initials.includes("DA"))
      }
      else{
        this.typesToSelect = this.documentTypesNames.filter(type => type.initials.includes("DD") || type.initials.includes("DO"))
      }
    },
    document_type_name_id(newValue, oldValue){
      var type = this.documentTypesNames.find(type => type.id === newValue).initials

      this.multipleYears = false
      this.persona_seleccionada = null
      this.document.resolution = null
      this.document.semester = null
      this.document.date = null
      this.startYear = null
      this.endYear = null
      this.document.year = null

      if (this.person != null){
        this.persona_seleccionada = this.person
      }

      if(type.includes("DO") && !type.includes("CART_DECAN")){
        this.hasNumber = true
      }
      else{
        this.hasNumber = false
      }

      if(type.includes("COMP_ACADE") || type.includes("CUAD_SEMES")){
        this.hasSemester = true
      }
      else{
        this.hasSemester = false
      }

      if(type.includes("DO") || type.includes("INFO_COMIT")){
        this.hasDate = true
      }
      else{
        this.hasDate = false
      }

      if(type.includes("CUAD_PRECA")){
        this.typePrec = true
      }
      else{
        this.typePrec = false
      }

      this.document.document_type_id = this.documentTypes.find(type => type.document_type_name_id === newValue).id
      this.generateName()
    },
    persona_seleccionada(){
      this.generateName()
      if (this.persona_seleccionada != null){
        this.personId = this.persona_seleccionada.id
      }
      else{
        this.personId = null
      }
    }
  }
}
</script>