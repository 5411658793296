<template>
    <div class="partnerentitiesvimegeneralinformation">
        <div class="columns is-centered">
          <div class="column">
            <div class="has-text-right">
              <b-button v-if="can_edit" type="is-primary"
                        icon-left="edit"
                        @click="editEntity">
                Editar Entidad
              </b-button>
            </div>
            <hr class="dropdown-divider" aria-role="menuitem">
            <div class="columns">
              <div class="column">
                <b-field label="Nombre Corto">

                    <b-input type="text" maxlength="255"
                             readonly
                             v-model="entity.alt_name ? entity.alt_name:entity.name"
                             >
                    </b-input>

                </b-field>
              </div>
              <div class="column">
                <b-field label="Nombre Completo">

                  <b-input type="text" maxlength="255"
                           readonly
                           v-model="entity.alt_full_name"
                  >
                  </b-input>

                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Rut">
                  <b-input type="text" readonly v-model="rut"></b-input>
                </b-field>
              </div>
              <div class="column is-1">
                <b-field label="DV">
                  <b-input type="text" readonly v-model="dv"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción">

                    <b-input type="textarea"
                             readonly
                             v-model="entity.description"
                             >
                    </b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Email">
                    <b-input  type="text"
                              readonly
                              v-model="entity.email"
                              >
                    </b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Teléfono">
                    <b-input
                        type="text"
                        readonly
                        v-model="entity.phone">
                    </b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="País">
                    <b-input
                        type="text"
                        readonly
                        v-model="entity.entity_nationality"
                        >
                    </b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Ciudad">
                    <b-input type="text"
                             readonly
                             v-model="entity.city"
                             >
                    </b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Dirección">
                    <b-input type="text"
                             readonly
                             v-model="entity.direction"
                             >
                    </b-input>
                </b-field>
              </div>
            </div>
            <div class="columns" v-if='isVisibleByNationality'>
              <div class="column">
                <b-field label="Región" >
                    <b-input
                        type="text"
                        readonly
                        v-model="entity.entity_region"
                        >
                    </b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Comuna">
                    <b-input
                        type="text"
                        readonly
                        v-model="entity.entity_commune"
                        >
                    </b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Rubro económico">
                  <b-input
                      type="text"
                      v-model="entity.entity_economic_activity"
                      readonly>
                  </b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Código postal">
                    <b-input
                        type="text"
                        readonly
                        v-model="entity.postal_code">
                    </b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Link">
                    <b-input
                        expanded
                        type="text"
                        readonly
                        v-model="entity.url">
                    </b-input>
                  <p class="control">
                    <b-button tag="a" :href="entity.url" target="_blank" icon-left="external-link-alt" type="is-link">Link</b-button>
                  </p>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de entidad">
                    <b-input
                        type="text"
                        readonly
                        v-model="entity.entity_type">
                    </b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="¿Es entidad pública?">
                  <div class="columns">
                    <div class="column">
                      <b-radio-button v-model="entity.is_public"
                                      disabled
                                      :native-value="false"
                                      type="is-danger is-light is-outlined"

                      >
                        <b-icon icon="times-circle"></b-icon>
                        <span>No</span>
                      </b-radio-button>
                    </div>
                    <div class="column">
                      <b-radio-button v-model="entity.is_public"
                                      disabled
                                      :native-value="true"
                                      type="is-success is-light is-outlined"
                      >
                        <b-icon icon="check-circle"></b-icon>
                        <span>Si</span>
                      </b-radio-button>
                    </div>
                  </div>

                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="¿Es Sub Entidad?">
                  <div class="columns">
                    <div class="column">
                      <b-radio-button v-model="isSubEntity"
                                      disabled
                                      :native-value="false"
                                      type="is-danger is-light is-outlined"

                      >
                        <b-icon icon="times-circle"></b-icon>
                        <span>No</span>
                      </b-radio-button>
                    </div>
                    <div class="column">
                      <b-radio-button v-model="isSubEntity"
                                      disabled
                                      :native-value="true"
                                      type="is-success is-light is-outlined"
                      >
                        <b-icon icon="check-circle"></b-icon>
                        <span>Si</span>
                      </b-radio-button>
                    </div>
                  </div>

                </b-field>
              </div>
              <div class="column">
                <b-field v-if="isSubEntity" label="Pertenece a la entidad">
                  <b-input
                      type="text"
                      readonly
                      v-model="nameEntityMajor">
                  </b-input>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      <br>
      <br>
      </div>
</template>
<script>
export default {
    name: 'PartnerEntitiesVimeGeneralInformation',
    props:['entity','economic_activities', 'entity_types','communes','can_edit','rut','dv', 'isSubEntity','nameEntityMajor'],
    computed:{
        isVisibleByNationality(){
            return (this.entity.entity_nationality === null) ? false : this.entity.entity_nationality === 'CHILE'
        }
    },
    methods:{
      getEntityTypeById(id){
          var name = ''
          this.entity_types.forEach(element => {
              if(element.id === id)
                  name = element.name
          })
          return name
      },
      editEntity(){
        window.location.href='/partner_entities/edit/'+this.entity.id
      },
      linkToEntity(){
        window.open(this.entity.url)
      }
    }
}
</script>