<template>
  <div>
    <div class="columns has-text-right">
      <div class="column is-narrow mt-2">
        <span v-if="!this.hideUpdate==true">Publicaciones del año: </span>

      </div>
      <div class="column is-3">
        <b-field v-if="!this.hideUpdate==true">
          <multiselect v-model="year"
                       :options="years"
                       @input="updateSections"
                       placeholder="Seleccione año"
                       selectLabel="Presione para seleccionar"
                       selectedLabel="Seleccionado"
                       deselectLabel="No se puede deseleccionar"
                       :allow-empty="false">
            <template v-slot:noOptions>
              No existen datos
            </template>
            <span slot="noResult">
                        No se encontraron elementos.
                    </span>
          </multiselect>
        </b-field>
      </div>
      <div class="column">
        <b-switch v-model="cardLayout">Formato tarjeta (sin filtros ni búsqueda)
             <b-tooltip
                 label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                 multilined
                 position="is-left">
                  <b-icon
                      pack="fas"
                      icon="info-circle"
                      type="is-info">
                  </b-icon>
              </b-tooltip></b-switch>
      </div>
    </div>
    <b-table
        :data="secciones"
        :paginated=true
        per-page=10
        striped
        hoverable
        :card-layout="cardLayout"
        :current-page="currentPage"
        :pagination-simple=false
        pagination-position="bottom"
        default-sort-direction="desc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="publication.publication_date"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página actual"
        :opened-detailed="defaultOpenedDetails"
        detailed
        detail-key="title"
        @details-open="(row) => $buefy.toast.open(`Expanded ${row.title}`)"
        :show-detail-icon="showDetailIcon"
        >


        <b-table-column
          field= 'title'
          label= 'Título'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          <a :href="getInbookUrl(props.row.id)"> {{ props.row.title }} </a>
        </template>
      </b-table-column>


      <b-table-column
          field= 'publication_date'
          label= 'Fecha de publicación'
          searchable
          :custom-search="searchPublicationDate"
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          <div v-if="props.row.books_attributes">
            {{ props.row.publication_date._isValid ? new Date(props.row.publication_date).toLocaleDateString("es-CL"): '' }}
          </div>
          <div v-else>
            ---
          </div>    
        </template>
      </b-table-column>


      <b-table-column
          field= 'editorial'
          label= 'Editorial del libro'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          <div v-for="(item,index) in props.row.libros" :key="index">
            {{ item.editorial}}
          </div>          
        </template>
      </b-table-column>
      

      <b-table-column
          field= 'title'
          label= 'Título del libro'
          width="20em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>


        <template v-slot="props">

          <div v-for="(item,index) in props.row.libros" :key="index">
            <a :href="getBookUrl(item.id)">{{ item.title}}</a>
          </div> 
        </template>
      </b-table-column>

      

      <b-table-column
          v-if="cargoPrincipal.name == 'ANALISTA I+D FING' || cargoPrincipal.name == 'SUPERADMIN'"
          label="Acciones"
          v-slot="props">
        <b-icon
            pack="fas"
            icon="trash"
            size="is-medium"
            type="is-danger"
            @click.native="execNullifyModal(props.row.id, props.row)"/>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>Autores: </strong>
                <span>{{props.row.participants_references}}</span>
              </p>
            </div>
          </div>
        </article>
      </template>

      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-danger">
              No se encontraron resultados para el año seleccionado.
            </b-message>
          </div>
        </div>
      </template>


    </b-table>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  </div>
</template>
<script>
import {searchDate} from "../../packs/utilities";
import axios from "axios";
export default {
  props:[
      'secciones','hideUpdate','cargoPrincipal'
  ],
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      year: new Date().getFullYear(),
      years:[],
      isNullifyActive: false
    }
  },
  created(){
    this.years = this.generateArrayOfYears();
  },
  methods:{
    getBookUrl(book_id){
      return "/books/"+book_id;
    },
    getInbookUrl(inbook_id){
      return "/books/"+inbook_id;
    },
    generateArrayOfYears() {
      var max = new Date().getFullYear()
      var min = 1980
      var years = []
      years.push("Todo")
      for (var i = max; i >= min; i--) {
        years.push(i)
      }
      return years
    },
    updateBooks(){
      this.$emit('updateSections',this.year)
    },
    searchPublicationDate(row,input){
      return searchDate(row.publication_date,input)
    },
    execNullifyModal(id, row){

      let titulo = ""
      let msj = ""
      let msjError = ""
      let msjExito = ""
      let conf = ""
      let tipo = 0

      if (row.publication_type=='Book'){
        titulo = "Anular Libro"
        msj = '¿Está seguro que desea <b>anular</b> el libro? Esta acción lo dejará inválido.<br>'
        msjError = '<b>Libro no pudo ser anulado</b><br>'
        msjExito = "Libro anulado correctamente"
      }
      else if (row.publication_type == 'Inbook'){
        titulo = "Anular Artículo de Libro"
        msj = '¿Está seguro que desea <b>anular</b> el artículo de libro? Esta acción lo dejará inválido.<br>'
        msjError = '<b>artículo de Libro no pudo ser anulado</b><br>'
        msjExito = "Artículo de libro anulado correctamente"
      }
      else if (row.publication_type == 'Booklet'){
        titulo = "Anular Capítulo de Libro"
        msj = '¿Está seguro que desea <b>anular</b> el capítulo de libro? Esta acción lo dejará inválido.<br>'
        msjError = '<b>Capítulo de Libro no pudo ser anulado</b><br>'
        msjExito = "Capítulo de libro anulado correctamente"
      }

      this.$buefy.dialog.confirm({
        title: titulo,
        message: msj,
        confirmText: titulo,
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/books/" + id + "/nullify_book.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: msjExito,
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })

                var index = this.secciones.indexOf(row)
                this.secciones.splice(index, 1)
                this.isLoading = false;
                this.isNullifyActive = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: msjError+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },

  },
  watch:{
    isNullifyActive: function () {
      if (!this.isNullifyActive) {
        this.updateBooks()
      }
    },
  },
  computed:{

  }
}
</script>