<template>
  <div>
    <div class="columns">
      <div class="column is-narrow mt-2">
        <span v-if="!this.hideUpdate==true">Disclosures año de recepción: </span>

      </div>
      <div class="column is-3">
        <b-field v-if="!this.hideUpdate==true">
          <multiselect v-model="year"
                       :options="years"
                       @input="updateDisclosures"
                       placeholder="Seleccione año"
                       selectLabel="Presione para seleccionar"
                       selectedLabel="Seleccionado"
                       deselectLabel="No se puede deseleccionar"
                       :allow-empty="false">
            <template v-slot:noOptions>
              No existen datos
            </template>
            <span slot="noResult">
                No se encontraron elementos.
            </span>
          </multiselect>
        </b-field>
      </div>
      <div class="column has-text-right">
        <b-field>
          <b-switch v-model="cardLayout">Formato tarjeta (sin filtros ni búsqueda)
            <b-tooltip
                label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                multilined
                position="is-left">
              <b-icon
                  pack="fas"
                  icon="info-circle"
                  type="is-info">
              </b-icon>
            </b-tooltip></b-switch>
        </b-field>
      </div>
    </div>


    <b-table
        :data="disclosures"
        :paginated=true
        :card-layout="cardLayout"
        per-page=10
        striped
        hoverable
        :current-page="currentPage"
        :pagination-simple=false
        pagination-position="bottom"
        default-sort-direction="asc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="disclosure.name"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página actual"
        :opened-detailed="defaultOpenedDetails"
        detailed
        detail-key="code"
        @details-open="(row) => $buefy.toast.open(`Expanded ${row.name}`)"
        :show-detail-icon="showDetailIcon"
    >
      <b-table-column
          field= 'name'
          label= 'Nombre'
          width="30em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          <a :href="getDisclosureUrl(props.row.id)">{{ props.row.name }}</a>
        </template>
      </b-table-column>
      <b-table-column
          field= 'code'
          label= 'Código'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.code }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'financing_source'
          label= 'Fuente de Financiamiento'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.financing_source }}
        </template>
      </b-table-column>

      <b-table-column
          field= 'disclosure_date'
          label= 'Fecha Recepción'
          searchable
          :custom-search="searchDisclosureDate"
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.disclosure_date._isValid ? new Date(props.row.disclosure_date).toLocaleDateString("es-CL") : '' }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'invention_date'
          label= 'Fecha Invención'
          searchable
          :custom-search = "searchInventionDate"
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.invention_date._isValid ? new Date(props.row.invention_date).toLocaleDateString("es-CL"): '' }}
        </template>
      </b-table-column>
      <b-table-column
          v-if="cargoPrincipal.name == 'ANALISTA I+D FING' || cargoPrincipal.name == 'SUPERADMIN'"
          label="Acciones"
          v-slot="props">
        <b-icon
            pack="fas"
            icon="trash"
            size="is-medium"
            type="is-danger"
            @click.native="execNullifyModal(props.row.id, props.row)"/>
      </b-table-column>
      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>Participantes: </strong>
                <span v-for="participant in props.row.authors" >
                  <a v-if="participant.url!=null" :href="participant.url">{{ participant.name }}</a>
                  <span v-else>{{ participant.name }}</span>
                  <span v-if="props.row.authors.length>1 && participant!=props.row.authors.slice(-1)[0]">, </span>
                </span>
              </p>
            </div>
          </div>
        </article>
      </template>
      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-danger">
              No se encontraron resultados para el año seleccionado.
            </b-message>
          </div>
        </div>
      </template>
    </b-table>


    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  </div>
</template>
<script>
import moment from "moment";
import {searchDate} from "../../packs/utilities";
import axios from "axios";
export default {
  props:[
    'disclosures','hideUpdate','cargoPrincipal'
  ],
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      years: [],
      year: null,
      isNullifyActive: false
    }
  },
  created(){
    this.years = this.generateArrayOfYears();
    this.year = new Date().getFullYear();
  },
  methods:{
    getDisclosureUrl(disclosure_id){
      return "/disclosures/"+disclosure_id;
    },

    generateArrayOfYears() {
      var max = new Date().getFullYear()
      var min = 1980
      var years = []
      years.push("Todo")
      for (var i = max; i >= min; i--) {
        years.push(i)
      }
      return years
    },
    updateDisclosures(){
      this.$emit('updateDisclosures',this.year)
    },
    searchInventionDate(row,input){
      return searchDate(row.invention_date,input)
    },
    searchDisclosureDate(row,input){
      return searchDate(row.disclosure_date,input)
    },
    execNullifyModal(id, row){
      this.$buefy.dialog.confirm({
        title: 'Anular disclosure',
        message: '¿Está seguro que desea <b>anular</b> el disclosure? Esta acción lo dejará inválido.<br>',
        confirmText: 'Anular Disclosure',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/disclosures/" + id + "/nullify_disclosure.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Disclosure anulada correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })

                var index = this.disclosures.indexOf(row)
                this.disclosures.splice(index, 1)
                this.isLoading = false;
                this.isNullifyActive = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Disclosure no pudo ser anulada</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },

  },
  watch:{
    isNullifyActive: function () {
      if (!this.isNullifyActive) {
        this.updateDisclosures()
      }
    },
  },
  computed:{

  }
}
</script>
