<template>
  <section>
    <h3 class="is-size-4 has-text-weight-bold has-text-centered">Tabla de Participantes</h3>
    <div class="has-text-right">
        <b-button v-if="(can_edit && academic_activity_state_id == 1) || (can_edit && academic_activity_state_id == null)" type="is-info"
                  icon-left="edit"
                  @click="editDisclosure(disclosureId)">
          Editar Participantes
        </b-button>
      </div>
    <div class="columns">
      <div class="column">
        <b-table
            :data="inventores"
            :paginated=true
            per-page=5
            striped
            hoverable
            :current-page="currentPage"
            :pagination-simple=false
            pagination-position="bottom"
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            default-sort="publication.title"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual">
          <b-table-column
              field="name"
              label="Nombres"
              sortable
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  readonly
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.full_name}}
            </template>
          </b-table-column>
          <b-table-column
              field="alias"
              label="Alias"
              sortable
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  readonly
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.alias }}
            </template>
          </b-table-column>
          <b-table-column
              field="corporate_mail"
              label="Correo Corporativo"
              sortable
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  readonly
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.corporate_mail }}
            </template>
          </b-table-column>
          <b-table-column
              field="unit"
              label="Unidad"
              sortable
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  readonly
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.unit }}
            </template>
          </b-table-column>
        </b-table>
      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        :active="isComponentModalActive"
        width="90%"
        :can-cancel="canCancel"
        aria-modal>
      <template #default="props">
        <new-disclosure-form modal-cancel="true"
                             :cargo-principal="cargoPrincipal"
                             :user-id="userId"
                             :show-participants-first="true"
                             modal-header="Editar Disclosures"
                             query-type="update" v-bind:disclosure-id="disclosureId" @close="closeModal"></new-disclosure-form>
      </template>
    </b-modal>
  </section>

</template>

<script>
import NewDisclosureForm from "./NewDisclosureForm";
export default {
  name: "DisclosureParticipantsTab",
  components:{NewDisclosureForm},
  data(){
    return{
      isLoading: false,
      currentPage: 1,
      isComponentModalActive: false,
      canCancel: ['escape']
    }
  },
  props:['inventores','can_edit','academic_activity_state_id','disclosureId','cargoPrincipal','userId'],
  methods:{
    editDisclosure(){
      this.isComponentModalActive = true;
    },
    closeModal(){
      this.isComponentModalActive = false;
    },
  }
}
</script>
