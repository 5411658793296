<template>
  <section>
    <div class="container">
      <div class="has-text-right mb-1">
        <b-button class="is-primary is-outlined"
                  v-if="can_edit_person==='true'"
                  icon-left="edit"
                  @click="isEditModalActive = true">
          Editar
        </b-button>
      </div>
        <div class="columns">
          <div class="column">
            <b-field label="Nombre">
              <b-input :value="person.name" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Primer Apellido">
              <b-input :value="person.last_name" type="text"readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Segundo Apellido">
              <b-input :value="person.second_surname" type="text"readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-3">
            <b-field label="Rut">
              <b-input :value="person.run_attributes.rut"readonly></b-input>
            </b-field>
          </div>
          <div class="column is-1">
            <b-field label="DV">
              <b-input :value="person.run_attributes.dv"readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha de nacimiento">
                <b-input :value="person.birthday_date" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Género">
              <b-input :value="person.gender_attributes!==undefined ? person.gender_attributes.name : ''" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Región">
              <b-input :value="person.region_attributes!==undefined ? person.region_attributes.name : ''" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Comuna">
              <b-input :value="person.commune_attributes!==undefined ? person.commune_attributes.name : ''" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Dirección">
              <b-input :value="person.address" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Nacionalidad">
              <b-input :value="person.nationality_attributes!==undefined ? person.nationality_attributes.name : ''" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="País de origen">
              <b-input :value="person.origin_country_attributes!==undefined ? person.origin_country_attributes.name : ''" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Lugar de nacimiento">
              <b-input :value="person.birthplace" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Institución de previsión">
              <b-input :value="person.pension_attributes!==undefined ? person.pension_attributes.name : ''" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Institución de salud">
              <b-input :value="person.health_attributes!==undefined ? person.health_attributes.name : ''" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Teléfono">
              <b-input :value="person.phone" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Celular">
              <b-input :value="person.cellphone" readonly expanded></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Email personal">
              <b-input type="text" :value="person.personal_mail" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Email coorporativo">
              <b-input type="text" :value="person.corporate_mail" readonly></b-input>
            </b-field>
          </div>
          </div>
      <div class="columns">
          <div class="column">
            <b-field label="Fecha de ingreso USACH">
              <b-input :value="formatDate(person.university_entrance_date)" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha de ingreso administración pública">
              <b-input :value="formatDate(person.public_entrance_date)" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Estado civil">
              <b-input :value="person.civil_status" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="¿Jubilado(a)?">
              <b-radio-button :value="person.retired"
                              :native-value="false"
                              type="is-danger is-light is-outlined"
                              readonly
                              :disabled="true"

              >
                <b-icon icon="times-circle"></b-icon>
                <span>No</span>
              </b-radio-button>

              <b-radio-button :value="person.retired"
                              :native-value="true"
                              type="is-success is-light is-outlined"
                              readonly
                              :disabled="true"
              >
                <b-icon icon="check-circle"></b-icon>
                <span>Si</span>
              </b-radio-button>
            </b-field>
          </div>
          <div class="column">
            <b-field label="¿Rinde fianza?">
              <b-radio-button :value="person.pays_bail"
                              :native-value="false"
                              type="is-danger is-light is-outlined"
                              readonly
                              :disabled="true"
              >
                <b-icon icon="times-circle"></b-icon>
                <span>No</span>
              </b-radio-button>

              <b-radio-button :value="person.pays_bail"
                              :native-value="true"
                              type="is-success is-light is-outlined"
                              readonly
                              :disabled="true"
              >
                <b-icon icon="check-circle"></b-icon>
                <span>Si</span>
              </b-radio-button>
            </b-field>
          </div>
        </div>

<!--      <div class="columns">
        <div class="column is-12">
          <b-field label="Url carpeta documentación Hoja de vida">
            <b-input expanded readonly v-model="person.life_record_link" type="text"></b-input>
            <p class="control">
              <b-button tag="a" :href="person.life_record_link" target="_blank" icon-left="external-link-alt" type="is-link">Link</b-button>
            </p>
          </b-field>
        </div>
      </div>-->

      <div class="title is-5">
        Documentos de ingreso
        <hr class="dropdown-divider" aria-role="menuitem">
      </div>

      <div class="columns">
        <div class="column">
          <b-table :data="process_files"
                   :paginated= true
                   per-page="10"
                   striped
                   hoverable
                   :current-page="currentPage"
                   :pagination-simple=false
                   pagination-position="bottom"
                   default-sort-direction="asc"
                   sort-icon="arrow-up"
                   sort-icon-size="is-small"
                   aria-next-label="Siguiente"
                   aria-previous-label="Anterior"
                   aria-page-label="Página"
                   aria-current-label="Página actual">
            <b-table-column field="name"
                            label="Nombre Documento"
                            sortable
                            searchable>
              <template
                  slot="searchable"
                  slot-scope="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    icon="search"
                    size="is-small" />
              </template>
              <template v-slot="props">
                {{props.row.name}}
              </template>
            </b-table-column>
            <b-table-column field="document_type_name"
                            label="Tipo Documento"
                            sortable
                            searchable>
              <template
                  slot="searchable"
                  slot-scope="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    icon="search"
                    size="is-small" />
              </template>
              <template v-slot="props">
                {{props.row.document_type_name}}
              </template>
            </b-table-column>
            <b-table-column label="Acciones"
                            v-slot="props">
              <b-tooltip
                  label="Visualizar documento."
                  position="is-left"
                  :active="true">
                <b-button
                    pack="fas"
                    icon-left="file-alt"
                    type="is-success is-light is-outlined"
                    @click="viewFile(props.row.document_id)"/>
              </b-tooltip>

              <b-tooltip
                  label="Descargar documento."
                  position="is-left"
                  :active="true">
                <b-button
                    pack="fas"
                    icon-left="download"
                    type="is-primary is-light is-outlined"
                    @click="downloadFile(props.row.document_id)"/>
              </b-tooltip>

            </b-table-column>
            <template slot="empty" slot-scope="props">
              <div class="columns is-centered">
                <div class="column is-6 is-centered has-text-centered">
                  <b-message type="is-warning">
                    No se encontraron documentos de ingreso asociados a la persona.
                  </b-message>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <b-loading :is-full-page="true"
                 v-model="isLoading"
                 :can-cancel="false"/>
      </div>
    <b-modal
        v-if="showEdit"
        v-model="isEditModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel"
        width="1348">
      <template #default="props">
        <modal-person-edit-form :person-id="personId"
                           query-type="update"
                           modal-header="Editar Datos Personales"
                           @close="props.close"></modal-person-edit-form>
      </template>
    </b-modal>
    <b-modal
        v-model="showPdf"
        :can-cancel="true"
        :destroy-on-hide="true"
        aria-modal
        aria-role="dialog"
        has-modal-card
        trap-focus
        :width="2000"
    >
      <template #default="props">
        <pdf_view
            :src="src"
            @close="props.close"
        ></pdf_view>
      </template>

    </b-modal>
  </section>
</template>

<script>
import axios from "axios";
import ModalPersonEditForm from "../life_record/modal_person_edit_form.vue"
import Pdf_view from "../performances/pdf_view.vue";

export default {
  data(){
    const data =[]
    return {
      isLoading:false,
      isEditModalActive: false,
      canCancel: [],
      person: {
        run_attributes: {
          rut: null,
          dv: null
        },
        region_attributes: {
          name: " "
        },
        commune_attributes: {
          name: " "
        },
        health_attributes: {
          name: " "
        },
        pension_attributes: {
          name: " "
        },
        gender_attributes: {
          name: " "
        },
        nationality_attributes: {
          name: " "
        },
        origin_country_attributes:{
          name: " "
        }
      },
      showEdit: true,
      process_files: [],
      currentPage: 1,
      showPdf: false,
      downloadLink: "",
      src:null,
    }
  },
  components:{
    Pdf_view,
    ModalPersonEditForm
  },
  created(){
    this.initialize()
  },
  props:[
    'personId','isEditDisabled', 'can_edit_person'
  ],
  methods:{
    initialize(){
      this.isLoading=true;
      if (this.isEditDisabled){
        this.showEdit = false;
      }
      return axios
          .get("/people/"+this.personId+".json")
          .then(res => {
            this.person = res.data
            this.person.birthday_date = this.formatDate(this.person.birthday_date)
            this.process_files = res.data['process_files']
            this.isLoading=false;
          }).catch(error => {
            //console.log(error)
            this.isLoading=false;
          })
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        var arreglo = date.split("-")
        return arreglo[2].slice(0,2)+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    formatDate2(date){
      if (date!=null && date!=="" && date!==undefined){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            this.showPdf = true
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    downloadFile(fileId){
      this.isLoading = true;

      if (fileId == null){
        this.isLoading = false;
        return;
      }

      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(res => {
            let blob = new Blob([res.data], {
              type: "application/pdf",
            })
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = res.headers["content-disposition"]
                .split(";")[1]
                .split('"')[1];
            link.click();
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento fue descargado con exito',
              type: 'is-success',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
          .catch(e => {
            console.log(e)
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento no se pudo descargar',
              type: 'is-danger',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
    },
  },
  watch: {
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
  }
}
</script>

