<template>
  <section>
    <div class="columns is-centered">
        <div class="column">
          <div class="has-text-right">
            <b-button type="is-info"
                      icon-left="edit"
                      v-if="(publication.can_edit && publication.academic_activity_state_id == 1) || (publication.can_edit && publication.academic_activity_state_id == null)"
                      @click="editPublication()">
              Editar Conferencia
            </b-button>
            <b-button v-if="(publication.is_creator || cargoPrincipal.name == 'ANALISTA I+D FING') && (publication.academic_activity_state_id == 1 || publication.academic_activity_state_id == null)" type="is-danger"
                      icon-left="ban"
                      @click="execNullifyModal">
              Anular Conferencia
            </b-button>
          </div>

          <hr class="dropdown-divider" aria-role="menuitem">
          <div class="columns">
            <div class="column">
              <b-field label="Título">
                  <b-input readonly v-model="publication.title" type="text"></b-input>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-field label="Autores">
                  <b-input  readonly v-model="publication.participants_references" type="text" maxlength="255" :has-counter="false"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Evento">
                <b-input readonly v-model="publication.annal" type="text"></b-input>
              </b-field>
            </div>                 
          </div>

          
          
          <div class="columns">
            <div class="column">
              <b-field label="Información adicional">
                <b-input readonly v-model="publication.additional" type="text"></b-input>
              </b-field>
            </div>                 
          </div>

          
        </div>
      </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        :active="isComponentModalActive"
        width="90%"
        :can-cancel="canCancel"
        aria-modal>
      <template #default="props">
        <new-conference-form modal-cancel="true"
                          :cargo-principal="cargoPrincipal"
                          :user-id="userId"
                          modal-header="Editar Sección"
                          query-type="update" v-bind:conference-id="publication.id" @close="closeModal"></new-conference-form>
      </template>
    </b-modal>
  </section>
</template>
<script>
import axios from "axios";
import NewConferenceForm from "./NewConferenceForm";
export default {
  name: "ShowGeneralTab",
  components: {NewConferenceForm},
  props:['cargoPrincipal','userId','conferenceId','publication','participantes'],
  data(){
    return{
      currentPage:1,
      isLoading:false,
      requiredFieldOnly:false,
      isComponentModalActive: false,
      canCancel: ['escape']

    }
  },

  methods:{
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    editPublication(){
      this.isComponentModalActive = true;
    },
    closeModal(){
      this.isComponentModalActive = false;
    },
    execNullifyModal(){
      this.$buefy.dialog.confirm({
        title: 'Anular conferencia',
        message: '¿Está seguro que desea <b>anular</b> la Conferencia? Esta acción la dejará inválida.<br>',
        confirmText: 'Anular Conferencia',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true
          axios
              .delete("/publications/" + this.publication.id + "/nullify_publication.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Conferencia anulada correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                if (document.referrer == ''){
                  window.location.href = history.back()
                }
                else{
                  window.location.href = document.referrer
                }
                this.isLoading = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Conferencia no pudo ser anulado</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
          })
        }
      })
    },

  },
  computed:{
  }
}
</script>
<style scoped>

</style>