<template>
  <section>
    <div class="container">
      <div class="box">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><a href="/life_record">Menú principal</a></li>
            <li class="is-active"><a href="#" aria-current="page">Búsqueda de Personas</a></li>
          </ul>
        </nav>
        <div class="columns">
          <div class="column">
            <b-field grouped group-multiline>
              <div class="control">
                <h1 class="title">Búsqueda de Personas</h1>
              </div>
            </b-field>
          </div>
        </div>
          <section>

            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion" :key='index'>
                      <div v-if="item">{{ item }}</div>
                    </div>
                  </b-message>
                </div>
              </div>
            </div>

            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(buscar_persona)">
                <div class="columns">
                  <div class="column is-4">
                    <b-field label="Rut">
                      <ValidationProvider rules="numeric" v-slot="{ errors }">
                        <b-input v-model="search_rut"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column is-2">
                    <b-field label="Dv">
                      <ValidationProvider rules="alpha_num|length:1" v-slot="{ errors }">
                        <b-input v-model="search_dv"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Primer Apellido">
                      <ValidationProvider :rules="{regex: /^[a-zA-ZáéíóúÁÉÍÓÚÑñ\s]*$/}" v-slot="{ errors }">
                        <b-input v-model="search_last_name" type="text"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Segundo Apellido">
                      <ValidationProvider :rules="{regex: /^[a-zA-ZáéíóúÁÉÍÓÚÑñ\s]*$/}" v-slot="{ errors }">
                        <b-input v-model="search_surname" type="text"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column is-4">
                    <b-field label="Nombres">
                      <ValidationProvider :rules="{regex: /^[a-zA-ZáéíóúÁÉÍÓÚÑñ\s]*$/}" v-slot="{ errors }">
                        <b-input v-model="search_name" type="text" expanded></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                        <div class="control">
                          <button type="submit" class="button is-primary">Buscar</button>
                        </div>
                        <div class="control">
                          <b-button @click="clean">Limpiar</b-button>
                        </div>
                    </b-field>
                  </div>
                  <div class="column">

                  </div>
                  <div class="column">

                  </div>

                </div>
              </form>
            </ValidationObserver>
          </section>

        <!--DATA TABLE-->
        <div class="columns is-centered" v-if="!search_applied" style="margin-bottom: 40px; margin-top: 40px;">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-warning">
              Debe realizar una búsqueda para ver resultados de personas
            </b-message>
          </div>
        </div>
        <div class="columns is-centered" v-if="search_applied&&people.length===1" style="margin-bottom: 40px; margin-top: 40px;">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-warning">
              Se encontró {{this.people.length}} resultado.
            </b-message>
          </div>
        </div>
        <div class="columns is-centered" v-if="search_applied&&people.length!==1" style="margin-bottom: 40px; margin-top: 40px;">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-warning">
              Se encontraron {{this.people.length}} resultados.
            </b-message>
          </div>
        </div>
        <b-table
            :data="people"
            :paginated= true
            v-if="search_applied"
            per-page="20"
            striped
            hoverable
            :current-page="currentPage"
            :pagination-simple=false
            pagination-position="both"
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            default-sort="product.title"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual">
          <b-table-column
              field="index"
              label="N°">
            <template v-slot="props">
              {{ people.indexOf(props.row) + 1 }}
            </template>
          </b-table-column>
          <b-table-column
              field="integral"
              label="Rut"
              sortable
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.integral}}
            </template>
          </b-table-column>
          <b-table-column
              field="name"
              label="Nombre"
              sortable
              searchable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.name}}
            </template>
          </b-table-column>
          <b-table-column
              field="last_name"
              label="Primer Apellido"
              sortable
              searchable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.last_name}}
            </template>
          </b-table-column>
          <b-table-column
              field="second_surname"
              label="Segundo Apellido"
              sortable
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.second_surname}}
            </template>
          </b-table-column>
          <b-table-column
              field="corporate_mail"
              label="Correo Corporativo"
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.corporate_mail!==undefined ? props.row.corporate_mail : ""}}
            </template>
          </b-table-column>
          <b-table-column
              label="Acciones"
              v-slot="props">
            <b-field >
              <p class="control">
                <b-button class="is-primary" icon-right="user" @click="goToLifeRecord(props.row.id)">Ir a Hoja de Vida</b-button>
              </p>
            </b-field>
          </b-table-column>

          <template slot="empty" slot-scope="props">
            <div class="columns is-centered">
              <div class="column is-6 is-centered has-text-centered">
                <b-message type="is-danger">
                  No se encontraron resultados para datos ingresados.
                </b-message>
              </div>
            </div>
          </template>

        </b-table>


      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <!-- MODALES -->
    <b-modal
        v-model="isAddModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-person-form query-type="create"
                           modal-header="Nueva Persona"
                           @close="props.close"></modal-person-form>
      </template>
    </b-modal>
    <b-modal
        v-model="isEditModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-person-edit-form :person-id="selected"
                                query-type="update"
                                modal-header="Editar Datos Personales"
                                @close="props.close"></modal-person-edit-form>
      </template>
    </b-modal>
  </section>
</template>

<script>
import axios from "axios";
import ModalPersonForm from "../life_record/modal_person_form"
import FiltersPeople from "../person/filters"
import ModalPersonEditForm from "../life_record/modal_person_edit_form"

export default {
  name: "search_life_record",
  props:[
    "unitId"
  ],
  components:{
    ModalPersonForm,
    FiltersPeople,
    ModalPersonEditForm
  },
  data(){
    return {
      selected: null,
      people_name: [],
      people_rut: [],
      people: [],
      currentPage: 1,
      isLoading: false,
      isAddModalActive: false,
      isEditModalActive: false,
      canCancel: ['escape'],
      no_filters: true,
      active_f: "",
      search_applied: false,
      search_rut: "",
      search_dv: "",
      search_name: "",
      search_last_name: "",
      search_surname: "",
      errores_validacion: {}
      // EL SHOW VA A LA URL people/:id/life_record
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    initialize(){

    },
    returnShow(person_id){
      this.isLoading = true
      window.location.href = '/people/'+person_id+'/life_record'
    },
    newPerson(){
      this.isAddModalActive = true
    },
    execEditModal(id){
      this.isLoading=true
      this.selected = id;
      this.isEditModalActive = true;
      this.isLoading=false
    },
    clean(){
      this.isLoading = true
      this.search_surname = ""
      this.search_dv = ""
      this.search_last_name = ""
      this.search_name = ""
      this.search_rut = ""
      this.isLoading = false
    },
    buscar_persona(){
      this.isLoading = true
      if(this.search_rut==="" && this.search_dv ==="" && this.name ==="" && this.search_last_name ==="" && this.search_surname ===""){
        return this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Debe llenar algún campo para realizar una búsqueda',
          type: 'is-warning',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
      else{
        return axios.get('/life_record/search_by_rut_names.json', {
          params: {
            rut: this.search_rut,
            dv: this.search_dv,
            names: this.search_name,
            last_name: this.search_last_name,
            surname: this.search_surname
          }
        }).then(response=>{
          this.people = response.data
          if(this.people.length === 0){
            this.isLoading=false;
            return this.$buefy.dialog.alert({
              title: 'Error',
              message: 'No se encontraron resultados para su búsqueda',
              type: 'is-warning',
              hasIcon: true,
              icon: 'exclamation-triangle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          }
          /*if(this.people.length === 1){
            var mensaje = 'Se encontró '+response.data["length"]+' resultado.'
            this.$buefy.dialog.alert({
              message: mensaje,
              confirmText: 'Ok',
              type: 'is-success',
              hasIcon: true,
              icon: 'check-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          }
          else{
            var mensaje = 'Se encontraron '+response.data["length"]+' resultados.'
            this.$buefy.dialog.alert({
              message: mensaje,
              confirmText: 'Ok',
              type: 'is-success',
              hasIcon: true,
              icon: 'check-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          }*/
          this.search_applied = true
          this.isLoading=false;

            })
            .catch(error => {
              console.log("error")
              console.log(error.response)
              this.errores_validacion = error.response.data
              console.log(this.errores_validacion)
              this.isLoading=false;
        })
      }
    },
    goToLifeRecord(id){
      window.open('/people/'+id+'/life_record')
    },
    checkFilterParams(){

      this.no_filters = this.gender_f == undefined && (this.academics_f == undefined || this.academics_f == false) &&
          (this.teachers_f == undefined || this.teachers_f == false) && (this.administratives_f == undefined || this.administratives_f == false) &&
          this.work_plant_f == undefined && this.category_f == undefined && this.hierarchy_f == undefined && this.quality_f == undefined &&
          this.hired_hours_f == undefined && this.contracting_unit_f == undefined && this.work_unit_f == undefined &&
          this.start_date_start_f == undefined && this.start_date_end_f == undefined &&
          this.university_entrance_date_end_f == undefined
          && this.university_entrance_date_start_f == undefined
          && this.birthday_date_start_f == undefined && this.birthday_date_end_f == undefined;
    }
  },
  watch: {
    isAddModalActive: function () {
      if (!this.isAddModalActive) {
        this.initialize()
      }
    },
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
  }
}
</script>

