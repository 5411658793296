<template>
  <section>
    <div class="columns">
      <div class="column">
        <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
          <b-message type="is-danger" has-icon>
            <div v-for="(item,index) in errores_validacion" :key='index'>
              <div v-if="item">{{ item[0] }}</div>
            </div>
          </b-message>
        </div>
      </div>
    </div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveSurplus)">
        <div class="box">
          <h1 class="title has-text-centered">General</h1>
          <div class="columns">                
            <div class="column" v-if="unidades.length>0">
              <b-field label="Unidad*">
                <ValidationProvider rules="required" v-slot="{errors}">
                  <multiselect v-model="surplus.unit_id"
                               :options="unidades.map(type => type.id)"
                               :custom-label="opt => unidades.find(x => x.id == opt).name"
                               placeholder="Seleccione Unidad"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               @select="getCodes"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Entidad de giro*">
                <ValidationProvider rules="required" v-slot="{errors}">
                  <multiselect v-model="surplus.sdt_turn_id"
                               :options="codes.map(type => type.id)"
                               :custom-label="opt => codes.find(x => x.id == opt).code"
                               placeholder="Seleccione entidad de giro"
                               selectedLabel=""
                               deselectLabel=""
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>

            
          </div>

          <div class="columns">

            <div class="column">
              <b-field label="Código EFUSACH*">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-input v-model="surplus.code" placeholder="Ej: SDT_CAP_001" type="text"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>

          <div class="columns">

            <div class="column">
              <b-field label="Monto">
                <ValidationProvider rules="required|integer|min_value:0" v-slot="{ errors }">
                  <b-input v-model="surplus.amount" placeholder="Ej: 500000" type="text"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>


              

            <br>
            <div class="columns">
              <div class="column">
                <div class="is-pulled-left">
                  <button v-if="modalCancel" class="button is-danger" @click.prevent="$emit('close')">Cancelar</button>
                </div>
                <div class="is-pulled-right">
                  <b-button class="button is-primary" icon-left="save" native-type="submit" @click="checkValidation()">Guardar código EFUSACH</b-button>
                </div>
              </div>
            </div>



        </div>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "NewSurplusForm",
  components:{ },
  data() {
    return {
      errores_validacion: {},
      surplus:{},
      unidades:[],
      codes: [],
      isLoading: false,
      is_validated:false,
    }
  },
  props:[
      'modalHeader','userId','queryType','surplusId','modalCancel', "unidadPrincipal", 'hideHeader',
  ],
  async created(){
    await this.getFinanceTypes();    
    if (this.queryType=='update'){
      this.getSurplus();
    }
  },
  methods:{
    async saveSurplus(){
      let vm = this;
      await vm.checkValidation();
      if (vm.is_validated){
        vm.isLoading=true;
        if (vm.queryType=="update"){
          axios
            .put("/sdt_surpluses/"+vm.surplusId+".json", formData)
            .then((res) => {
              vm.isLoading = false;
              this.$buefy.dialog.alert({
                message: "El código EFUSACH fue actualizado con éxito.<br/>",
                confirmText: "Ok",            
              });
              vm.$emit("clickedUpdate",res.data);
              vm.$emit('close')
            })
            .catch(e => {
              vm.errores_validacion = e.response.data
              vm.isLoading=false;
          });   
        }
        else{
          axios
          .post("/sdt_surpluses.json", {
                sdt_surplus: vm.surplus
              })
          .then((res) => {
            vm.isLoading = false;
            this.$buefy.dialog.alert({
              message: "El código EFUSACH fue creado correctamente <br/>",
              confirmText: "Ok",            
            });
            window.location.href = '/sdt_surpluses/'+res.data["id"]
            vm.isLoading=false;
          })
          .catch(e => {
            vm.errores_validacion = e.response.data
            vm.isLoading=false;
        });   


        }

      }


    },
    async getFinanceTypes(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/sdt_finances/form-data.json")
        .then(res => {
          vm.unidades = res.data.unidades;
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    getCodes() {
      let vm = this;
      vm.isLoading=true;      
      axios.get("/sdt_surpluses/unit-surplus/"+vm.surplus.unit_id+".json")
        .then(async (res) => {
          vm.surplus.sdt_turn_id = null;
          vm.codes = res.data;          
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },

    async checkValidation() {
      this.is_validated = false;
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar los campos a rellenar.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
      else{
        this.is_validated = true;
      }
    },
    getSurplus(){
      let vm = this;
      vm.isLoading=true;
      return axios
          .get("/sdt_surpluses/"+this.serviceId+".json")
          .then(res => {
            vm.surplus = res.data;
            vm.isLoading=false;
          })
          .catch(e => {
            console.log(e);
            vm.isLoading=false;
          });
    },
  }
}
</script>

<style scoped>

  .right-button{
    margin-left: auto;
  }
  .left-button{
    margin-right: auto;
  }


</style>