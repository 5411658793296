<template>
  <div>
    <div class="columns has-text-right">
      <div class="column">
        <b-switch v-model="cardLayout">Formato tarjeta (sin filtros ni búsqueda)
          <b-tooltip
              label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
              multilined
              position="is-left">
            <b-icon
                pack="fas"
                icon="info-circle"
                type="is-info">
            </b-icon>
          </b-tooltip></b-switch>
      </div>
    </div>
    <b-table
        :data="publicaciones"
        :paginated=true
        per-page=10
        striped
        hoverable
        :card-layout="cardLayout"
        :current-page="currentPage"
        :pagination-simple=false
        pagination-position="bottom"
        default-sort-direction="desc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="publication.publication_date"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página actual"
        :opened-detailed="defaultOpenedDetails"
        detailed
        detail-key="title"
        @details-open="(row) => $buefy.toast.open(`Expanded ${row.title}`)"
        :show-detail-icon="showDetailIcon"
    >
      <b-table-column
          field= 'title'
          label= 'Título'
          width="30em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.title }}
        </template>
      </b-table-column>

      <b-table-column
          field= 'publication_type'
          label= 'Tipo de Publicacion'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.publication_type }}
        </template>
      </b-table-column>

      <b-table-column
          field= 'magazine'
          label= 'Revista'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.magazine }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'publication_date'
          label= 'Fecha de publicación'
          searchable
          :custom-search="searchPublicationDate"
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.publication_date._isValid ? new Date(props.row.publication_date).toLocaleDateString("es-CL"): '' }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'is_wos'
          label= 'Es Wos'
          sortable
          v-slot="props"
      >
        <b-checkbox disabled v-model="props.row.is_wos">
        </b-checkbox>
      </b-table-column>
      <b-table-column
          field= 'is_scielo'
          label= 'Es Scielo'
          sortable
          v-slot="props"
      >
        <b-checkbox disabled v-model="props.row.is_scielo">
        </b-checkbox>
      </b-table-column>
      <b-table-column
          field= 'is_scopus'
          label= 'Es Scopus'
          sortable
          v-slot="props"
      >
        <b-checkbox disabled v-model="props.row.is_scopus">
        </b-checkbox>
      </b-table-column>
      <b-table-column
          label="Acciones"
          v-slot="props">
        <b-icon
            pack="fas"
            icon="eye"
            size="is-medium"
            @click.native="execShowModal(props.row.id)"/>
        <b-icon
            pack="fas"
            icon="trash-restore"
            size="is-medium"
            type="is-success"
            @click.native="execRestoreModal(props.row.id, props.row)"/>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>Autores: </strong>
                <span>{{props.row.participants_references}}</span>
              </p>
            </div>
          </div>
        </article>
      </template>

      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-danger">
              No se encontraron resultados para el año seleccionado.
            </b-message>
          </div>
        </div>
      </template>
    </b-table>

    <b-modal
        v-model="isShowModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal_show_nullified_publication :publicationId="publicationtId"
                                      :cargoPrincipal="cargoPrincipal"
                                      query-type="create"
                                      modal-header="Publicacion"
                                      @close="props.close">
        </modal_show_nullified_publication>
      </template>
    </b-modal>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  </div>
</template>
<script>
import {searchDate} from "../../packs/utilities";
import axios from "axios";
import moment from "moment";
import modal_show_nullified_publication from "./modal_show_nullified_publication";
export default {
  props:[
    'hideUpdate','cargoPrincipal'
  ],
  components:{
    modal_show_nullified_publication
  },

  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      year: new Date().getFullYear(),
      years:[],
      isNullifyActive: false,
      publicaciones: [],
      isShowModalActive: false,
      publicationtId: null
    }
  },
  created(){
    if(this.cargoPrincipal.name == 'ANALISTA I+D FING' || this.cargoPrincipal.name == 'SUPERADMIN'){
      this.getPublicationsNullified()
    }
    else {
      this.myGetPublicationsNullified()
    }
  },
  methods:{
    getPublicationtUrl(publication_id){
      return "/publications/"+publication_id;
    },
    generateArrayOfYears() {
      var max = new Date().getFullYear()
      var min = 1980
      var years = []
      years.push("Todo")
      for (var i = max; i >= min; i--) {
        years.push(i)
      }
      return years
    },

    searchPublicationDate(row,input){
      return searchDate(row.publication_date,input)
    },
    execRestoreModal(id, row){
      let vm = this;
      vm.$buefy.dialog.confirm({
        title: 'Restaurar publicación',
        message: '¿Está seguro que desea <b>restaurar</b> la publicación? Esta acción revertirá la anulación.',
        confirmText: 'Restaurar Publicación',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          vm.isLoading = true
          axios
              .put("/publications/" + id + "/restore_publication.json")
              .then(res => {
                vm.$buefy.dialog.alert({
                  message: 'Publicación restaurada correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                var index = this.publicaciones.indexOf(row)
                this.publicaciones.splice(index, 1)
                vm.isLoading = false;
              }).catch(error => {
            console.log(error)
            vm.$buefy.dialog.alert({
              message: '<b>Publicación no pudo ser restaurado</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            vm.isLoading = false;
            vm.isRestoreActive = false;
          })
        }
      })
    },
    getPublicationsNullified(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/publications.json", {params:{year_f:-1}})
          .then(res => {
            vm.publicaciones = res.data['publicaciones'];
            vm.publicaciones = vm.publicaciones.map(publicacion => {
              var newPublicacion = publicacion
              newPublicacion["publication_date"] = moment(publicacion.publication_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newPublicacion;
            });
            vm.isLoading=false;
          })
          .catch(e => {
            vm.isLoading=false;
            //console.log(e);
          });
    },
    myGetPublicationsNullified(){
      this.isLoading=true;
      return axios.get("/publications/my_nullified_publications.json")
          .then(res => {
            var publicaciones_temp = res.data;
            publicaciones_temp.map(publicacion => {
              var newPublicacion = publicacion
              newPublicacion["publication_date"] = moment(publicacion.publication_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
              if (newPublicacion["is_creator"] == true){
                this.publicaciones.push(newPublicacion);
              }
            });
            this.isLoading=false;
          })
          .catch(e => {
            this.isLoading=false;
            console.log(e);
          });
    },
    execShowModal(id){
      this.publicationtId = id
      this.isShowModalActive = true
    },

  },
  computed:{

  }
}
</script>

