<template>
  <section>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column">
            <b-field label="Rut">
              <b-input :value="person_unit.person_rut" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Nombre Completo">
              <b-input :value="person_unit.person_name" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Estamento">
              <b-input :value="person_unit.establishment.name" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Planta">
              <b-input :value="person_unit.work_plant.name" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="N° de documento">
              <b-input :value="person_unit.resolution" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Unidad de contratación">
              <b-input :value="person_unit.unit.name" type="text" readonly>
              </b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Calidad">
              <b-input :value="person_unit.quality" type="text" readonly>
              </b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Jerarquía/Categoría">
              <b-input :value="person_unit.hier_cat_name" type="text" readonly>
              </b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Grado">
              <b-input :value="(person_unit.grade!==undefined && person_unit.grade!==null) ? person_unit.grade.name : ''" type="text" readonly>
              </b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Jornada (horas semanales)">
              <b-input :value="person_unit.taken_hours" type="text" readonly>
              </b-input>
            </b-field>
          </div>
        </div>
      </section>
    </div>
    <footer class="modal-card-foot actions">
      <div class="field is-grouped is-pulled-right">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
      </div>
    </footer>



  </section>
</template>


<script>
import {formatDate} from "../../../packs/utilities";

export default {
  name: "simple_modal",
  props:[
    "id", "modalHeader", "nombramiento"
  ],
  components:{
  },
  data(){
    return {
      isLoading: false,
      person_unit: {},
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      const loadingComponent = this.$buefy.loading.open();
      this.person_unit = this.nombramiento;
      loadingComponent.close();
    },
    formatDate,
  },
}
</script>