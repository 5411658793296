<template>
  <div class="modal-card" :style="'width:' + ancho + 'px'">
    <header class="modal-card-head">
      <p class="modal-card-title">{{modalHeader}}</p>
      <b-button
          type="button"
          class="delete"
          @click="$emit('close')"/>
    </header>
    <section class="modal-card-body">

      <div class="columns">
        <div class="column">
          <b-field label="Nombre del documento">
            <b-input v-model="performance.document_name" disabled></b-input>
          </b-field>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <b-field label="Unidad">
            <b-input v-model="performance.unit_name" disabled></b-input>
          </b-field>
        </div>
      </div>

      <div class="columns" v-if="performance.rut != null">
        <div class="column">
          <b-field label="Académico">
            <b-input v-model="performance.person_name" disabled></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="RUT">
            <b-input v-model="performance.rut" disabled></b-input>
          </b-field>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <b-field label="Tipo de documento">
            <b-input v-model="performance.type" disabled></b-input>
          </b-field>
        </div>
      </div>

      <div class="columns">
        <div class="column" v-if="!performance.signed">
          <b-field label="¿Está firmado?">
            <b-input disabled v-model="firma" ></b-input>
          </b-field>
        </div>
        <div class="column" v-if="performance.signed">
          <b-field label="¿Está firmado?">
            <b-input disabled v-model="firma"></b-input>
          </b-field>
        </div>

        <div class="column" v-if="performance.number != null">
          <b-field label="N° de documento">
            <b-input disabled v-model="performance.number"></b-input>
          </b-field>
        </div>

        <div class="column" v-if="performance.year != null && performance.year_last == null">
          <b-field label="Año">
            <b-input disabled v-model="performance.year" ></b-input>
          </b-field>
        </div>
        <div class="column" v-if="performance.year != null && performance.year_last != null">
          <b-field label="Año inicio">
            <b-input disabled v-model="performance.year" ></b-input>
          </b-field>
        </div>
        <div class="column" v-if="performance.year != null && performance.year_last != null">
          <b-field label="Año hasta">
            <b-input disabled v-model="performance.year" ></b-input>
          </b-field>
        </div>

        <div class="column" v-if="performance.semester != null">
          <b-field label="Semestre">
            <b-input disabled v-model="performance.semester" ></b-input>
          </b-field>
        </div>

        <div class="column" v-if="performance.date != null">
          <b-field label="Fecha">
            <b-input disabled v-model="date" ></b-input>
          </b-field>
        </div>
      </div>

    </section>
    <footer class="modal-card-foot actions">
      <div class="has-text-right is-grouped">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
        <b-button v-if="performance.can_edit" class="is-primary"
                  type="submit"
                  @click="editPerformance">
          Editar documento
        </b-button>
      </div>
    </footer>
  </div>
</template>

<script>

  import moment from "moment";

  export default {
    props: [
      'performance'
    ],
    data(){
      return {
        isLoading: true,
        modalWidth: 'auto',
        firma: "No",
        date: "",
        modalHeader: "Información - ",
        ancho: 1000,
      }
    },
    created() {
      if (this.performance.signed){
        this.firma = "Si"
      }
      else{
        this.firma = "No"
      }
      if (this.performance.date != null){
        this.date = this.performance.date.split("-").reverse().join("/");
      }
      this.modalHeader += this.performance.type
    },
    methods: {
      closeModal() {
        this.$emit("close");
      },
      editPerformance(){
        window.open("/documents/" + this.performance.performance_id + "/edit-performance", '_blank')
      },
    },
  }

</script>
