<template>
  <section>
    <h3 class="is-size-4 has-text-weight-bold has-text-centered">Tabla de Participantes</h3>
    <div class="has-text-right">
      <b-button type="is-info"
                icon-left="edit"
                v-if="(patent.can_edit && patent.academic_activity_state_id == 1) || (patent.can_edit && patent.academic_activity_state_id == null)"
                @click="editPatent">
        Editar Participantes
      </b-button>
    </div>
    <div class="columns">
      <div class="column">
        <b-table
            :data="participantes"
            :paginated=true
            per-page=5
            striped
            hoverable
            :current-page="currentPage"
            :pagination-simple=false
            pagination-position="bottom"
            default-sort-direction="desc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            default-sort="last_patent_state_date"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual">
          <b-table-column
              field= 'person_name'
              label= 'Nombres'
              width="20em"
              searchable
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{  props.row.person_name }}
            </template>
          </b-table-column>
          <b-table-column
              field= 'corporate_mail'
              label= 'Correo Corporativo'
              searchable
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{  props.row.corporate_mail }}
            </template>
          </b-table-column>
          <b-table-column
              field= 'unit'
              label= 'Unidad'
              searchable
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{  props.row.unit }}
            </template>
          </b-table-column>
        </b-table>
      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        :active="isComponentModalActive"
        width="90%"
        :can-cancel="canCancel"
        aria-modal>
      <template #default="props">
        <new-patent-form modal-cancel="true"
                         :cargo-principal="cargoPrincipal"
                         :user-id="userId"
                         :show-participants-first="true"
                         modal-header="Editar Patente"
                         query-type="update" v-bind:patent-id="patent.id" @close="closeModal"></new-patent-form>
      </template>
    </b-modal>
  </section>
</template>

<script>
import NewPatentForm from "./NewPatentForm";
export default {
  name: "PatentParticipantsTab",
  props:['patent','participantes','userId','cargoPrincipal'],
  components:{NewPatentForm},
  data(){
    return{
      isLoading: false,
      currentPage: 1,
      isComponentModalActive: false,
      canCancel: ['escape']
    }
  },
  methods:{
    editPatent(){
      this.isComponentModalActive = true;
    },
    closeModal(){
      this.isComponentModalActive = false;
    },
  }
}
</script>
