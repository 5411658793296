<template>
    <div class='configurationsocialnetworknewmodal'>
        <div v-if="this.action_case == 0">
            <form action="">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Nueva red social</p>
                        <button
                            type="button"
                            class="delete"
                            @click="$emit('close')"/>
                    </header>
                    <section class="modal-card-body">
                        <p>* campos obligatorios</p>
                        <b-field label="Nombre*"
                            :type="{ 'is-danger': checkSocialNetworkName }"
                            :message="{ 'Ingrese un nombre para la nueva red social.': checkSocialNetworkName }">
                            <b-input
                                :value='socialNetworkName'
                                v-model='socialNetworkName'
                                placeholder="Nombre de la red social"
                                maxlength="255"
                                required>
                            </b-input>
                        </b-field>

                        <b-field label="Descripción*"
                            :type="{ 'is-danger': checkSocialNetworkDescription }"
                            :message="{ 'Ingrese una breve descripción.': checkSocialNetworkDescription }">
                            <b-input
                                type="textarea"
                                :value='socialNetworkDescription'
                                v-model='socialNetworkDescription'
                                maxlength="255"
                                placeholder="Ingrese una breve descripción"
                                required>
                            </b-input>
                        </b-field>

                        <b-field label="Link*"
                            :type="{'is-danger': (checkSocialNetworkLinkFormat || checkSocialNetworkLink)}"
                            :message="{ 'Ingrese un link para la nueva red social.': checkSocialNetworkLink,
                                        'Formato no valido': checkSocialNetworkLinkFormat}">
                            <b-input
                                :value='socialNetworkLink'
                                v-model='socialNetworkLink'
                                placeholder="Link de la red social"
                                maxlength="255"
                                required>
                            </b-input>
                        </b-field>
                    </section>
                    <footer class="modal-card-foot">
                        <b-button
                            label="Cerrar"
                            @click="closeModal()" />
                        <b-button
                            label="Añadir"
                            type="is-primary" 
                            @click="addSocialNetwork()"
                            :loading='checkSubmiting'/>
                    </footer>
                </div>
            </form>
        </div>
        <div v-else>
            <form action="">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Editar red social</p>
                        <button
                            type="button"
                            class="delete"
                            @click="closeModal()"/>
                    </header>
                    <section class="modal-card-body">
                        <p>* campos obligatorios</p>
                        <b-field label="Nombre*"
                            :type="{ 'is-danger': checkEditSocialNetworkName }"
                            :message="{ 'Ingrese un nombre para la nueva red social.': checkEditSocialNetworkName }">
                            <b-input
                                :value='socialNetworkNameEdit'
                                v-model='socialNetworkNameEdit'
                                placeholder="Nombre de la red social"
                                required>
                            </b-input>
                        </b-field>

                        <b-field label="Descripción*"
                            :type="{ 'is-danger': checkSocialNetworkDescriptionEdit }"
                            :message="{ 'Ingrese una breve descripción.': checkSocialNetworkDescriptionEdit }">
                            <b-input
                                type="textarea"
                                :value='socialNetworkDescriptionEdit'
                                v-model='socialNetworkDescriptionEdit'
                                placeholder="Ingrese una breve descripción"
                                required>
                            </b-input>
                        </b-field>

                        <b-field label="Link*"
                            :type="{ 'is-danger': (checkSocialNetworkLinkFormatEdit || checkSocialNetworkLinkEdit)}"
                            :message="{ 'Ingrese un link para la nueva red social.': checkSocialNetworkLinkEdit,
                                        'Formato no valido': checkSocialNetworkLinkFormatEdit}">
                            <b-input
                                :value='socialNetworkLinkEdit'
                                v-model='socialNetworkLinkEdit'
                                placeholder="Link de la red social"
                                required>
                            </b-input>
                        </b-field>
                    </section>
                    <footer class="modal-card-foot">
                        <b-button
                            label="Cerrar"
                            @click="closeModal()" />
                        <b-button
                            label="Editar"
                            type="is-primary" 
                            @click="editSocialNetwork()"
                            :loading='checkSubmiting'/>
                    </footer>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ConfigurationSocialNetworkNewModal',
    props:{
        action_case:{
            type: Number,
            default: 0
        },
        social_network_to_edit:{
            type: Object
        }
    },
    data(){
        return {
            social_network_to_edit_past:{
                name: '',
                description: '',
                link: ''
            },
            new_social_network:{
                name: '',
                description: '',
                link: ''
            },
            validations:{
                // Añadir
                isSocialNetworkNameEmpty: true,
                isSocialNetworkDescriptionEmpty: true,
                isSocialNetworkLinkEmpty: true,
                firstTimeSocialNetworkName: true,
                firstTimeSocialNetworkDescription: true,
                firstTimeSocialNetworkLink: true,
                
                // Editar
                isSocialNetworkNameEditEmpty: false,
                isSocialNetworkDescriptionEditEmpty: false,
                isSocialNetworkLinkEditEmpty: false,
                firstTimeSocialNetworkNameEdit: true,
                firstTimeSocialNetworkDescriptionEdit: true,
                firstTimeSocialNetworkLinkEdit: true,
            },
            isSubmiting: false
        }
    },
    computed:{
        // Añadir
        socialNetworkName:{
            get(){
                return this.new_social_network.name
            },
            set(newName){
                this.validations.firstTimeSocialNetworkName = false
                if(newName === '')
                    this.validations.isSocialNetworkNameEmpty = true
                else
                    this.validations.isSocialNetworkNameEmpty = false
                this.new_social_network.name = newName
            }
        },
        socialNetworkDescription:{
            get: function (){
                return this.new_social_network.description
            },
            set: function (newDescription) {
                this.validations.firstTimeSocialNetworkDescription = false
                if(newDescription === '')
                    this.validations.isSocialNetworkDescriptionEmpty = true
                else
                    this.validations.isSocialNetworkDescriptionEmpty = false
                this.new_social_network.description = newDescription
            }
        },
        socialNetworkLink:{
            get(){
                return this.new_social_network.link
            },
            set(newLink){
                this.validations.firstTimeSocialNetworkLink = false
                if(newLink === '')
                    this.validations.isSocialNetworkLinkEmpty = true
                else
                    this.validations.isSocialNetworkLinkEmpty = false
                this.new_social_network.link = newLink
            }
        },
        checkSocialNetworkName(){
            if(this.validations.firstTimeSocialNetworkName === false)
                return this.validations.isSocialNetworkNameEmpty
            else
                return false
        },
        checkSocialNetworkDescription(){
            if(this.validations.firstTimeSocialNetworkDescription === false)
                return this.validations.isSocialNetworkDescriptionEmpty
            else
                return false
        },
        checkSocialNetworkLink(){
            if(this.validations.firstTimeSocialNetworkLink === false)
                return this.validations.isSocialNetworkLinkEmpty
            else
                return false
        },
      checkSocialNetworkLinkFormat(){
        var regExp = /http[s]?\:\/\/[a-zA-Z 0-9]+\.[a-z]+/
        if(this.validations.firstTimeSocialNetworkLink === false){
          if(this.socialNetworkLink != null && this.socialNetworkLink != ""){
            return !regExp.test(this.new_social_network.link)
          }
          else{
            return false
          }
        }
        else{
          return false
        }
      },

        // Editar
        socialNetworkNameEdit:{
            get(){
                return this.social_network_to_edit.name
            },
            set(editName){
                this.validations.firstTimeSocialNetworkNameEdit = false
                if(editName === '')
                    this.validations.isSocialNetworkNameEditEmpty = true
                else
                    this.validations.isSocialNetworkNameEditEmpty = false
                this.social_network_to_edit.name = editName
            }
        },
        socialNetworkDescriptionEdit:{
            get: function (){
                return this.social_network_to_edit.description
            },
            set: function (editDescription) {
                this.validations.firstTimeSocialNetworkDescriptionEdit = false
                if(editDescription === '')
                    this.validations.isSocialNetworkDescriptionEditEmpty = true
                else
                    this.validations.isSocialNetworkDescriptionEditEmpty = false
                this.social_network_to_edit.description = editDescription
            }
        },
        socialNetworkLinkEdit:{
            get(){
                return this.social_network_to_edit.link
            },
            set(editLink){
                this.validations.firstTimeSocialNetworkLinkEdit = false
                if(editLink === '')
                    this.validations.isSocialNetworkLinkEditEmpty = true
                else
                    this.validations.isSocialNetworkLinkEditEmpty = false
                this.social_network_to_edit.link = editLink
            }
        },
        checkEditSocialNetworkName(){
            if(this.validations.firstTimeSocialNetworkNameEdit === false)
                return this.validations.isSocialNetworkNameEditEmpty
            else
                return false
        },
        checkSocialNetworkDescriptionEdit(){
            if(this.validations.firstTimeSocialNetworkDescriptionEdit === false)
                return this.validations.isSocialNetworkDescriptionEditEmpty
            else
                return false
        },
        checkSocialNetworkLinkEdit(){
            if(this.validations.firstTimeSocialNetworkLinkEdit === false)
                return this.validations.isSocialNetworkLinkEditEmpty
            else
                return false
        },
      checkSocialNetworkLinkFormatEdit(){
        var regExp = /http[s]?\:\/\/[a-zA-Z 0-9]+\.[a-z]+/
        if(this.validations.firstTimeSocialNetworkLinkEdit === false){
          if(this.socialNetworkLinkEdit != null && this.socialNetworkLinkEdit != ""){
            return !regExp.test(this.social_network_to_edit.link)
          }
          else{
            return false
          }
        }
        else{
          return false
        }
      },
        // Para indicar que se está subiendo la nueva red social y/o guardando cambios
        checkSubmiting: {
            get: function(){
                return this.isSubmiting
            },
            set: function(newState){
                this.isSubmiting = newState
            }
        }
    },
    beforeMount(){
        this.social_network_to_edit_past.name = this.social_network_to_edit.name
        this.social_network_to_edit_past.description = this.social_network_to_edit.description
        this.social_network_to_edit_past.link = this.social_network_to_edit.link
    },
    methods:{
        checkEmptyFields(action_case){
            if(action_case == 0){
                if(this.validations.firstTimeSocialNetworkName === true){
                    this.validations.firstTimeSocialNetworkName = false
                }
                if(this.validations.firstTimeSocialNetworkDescription === true){
                    this.validations.firstTimeSocialNetworkDescription = false
                }
                if(this.validations.firstTimeSocialNetworkLink === true){
                    this.validations.firstTimeSocialNetworkLink = false
                }
            }
            else{
                if(this.validations.firstTimeSocialNetworkNameEdit === true){
                    this.validations.firstTimeSocialNetworkNameEdit = false
                }
                if(this.validations.firstTimeSocialNetworkDescriptionEdit === true){
                    this.validations.firstTimeSocialNetworkDescriptionEdit = false
                }
                if(this.validations.firstTimeSocialNetworkLinkEdit === true){
                    this.validations.firstTimeSocialNetworkLinkEdit = false
                }
            }
        },
        checkSave(action_case){
            if(action_case === 0){
                var confirmation = true
                var arrayConfirmation = []
                arrayConfirmation.push(this.validations.isSocialNetworkNameEmpty)
                arrayConfirmation.push(this.validations.isSocialNetworkDescriptionEmpty)
                arrayConfirmation.push(this.validations.isSocialNetworkLinkEmpty)
                arrayConfirmation.forEach(function(element){
                    if(element === true)
                        confirmation = false
                })
                return new Promise((resolve) => {
                    resolve(confirmation)
                })
            }
            else{
                var confirmation = true
                var arrayConfirmation = []
                arrayConfirmation.push(this.validations.isSocialNetworkNameEditEmpty)
                arrayConfirmation.push(this.validations.isSocialNetworkDescriptionEditEmpty)
                arrayConfirmation.push(this.validations.isSocialNetworkLinkEditEmpty)
                arrayConfirmation.forEach(function(element){
                    if(element === true)
                        confirmation = false
                })
                return new Promise((resolve) => {
                    resolve(confirmation)
                })
            }
        },
        async addSocialNetwork(){
            this.checkSubmiting = true
            var confirmacion = await this.checkSave(0)
            if(confirmacion){
                this.$emit('add-new-social-network', this.new_social_network);
                this.$emit('close');
            }
            else{
                this.checkEmptyFields(0);
                this.checkSubmiting = false
            }
        },
        async editSocialNetwork(){
            this.checkSubmiting = true
            var confirmacion = await this.checkSave(1)
            if(confirmacion){
                this.$emit('edit-social-network', this.social_network_to_edit);
                this.$emit('close');
            }
            else{
                this.checkEmptyFields(0);
                this.checkSubmiting = false
            }
        },
        closeModal(){
            if(this.action_case === 1){
                this.social_network_to_edit.name = this.social_network_to_edit_past.name
                this.social_network_to_edit.description = this.social_network_to_edit_past.description
                this.social_network_to_edit.link = this.social_network_to_edit_past.link
                this.$emit('close');
            }
            else{
                this.$emit('close');
            }
        }
    }
}
</script>