<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveIsbn)">
        <div class="modal-card" style="width: auto" id="modal-magazine">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" ref="modalPersonForm">
            <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
              <b-message type="is-danger" has-icon>
                <div v-for="(item,index) in errores_validacion">
                  {{ item[0] }}
                </div>
              </b-message>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Código ISBN*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input type="text" v-model="isbn.code" maxlength="255" :has-counter="false">
                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo Código ISBN*">
                  <b-switch v-model="isbn.is_physical">
                    <span v-if="isbn.is_physical"> Fisico </span>
                    <span v-else> Digital</span> 
                  </b-switch>
                  
                </b-field>
              </div>
            </div>

          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit"  @click="checkValidation()">Guardar </button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "NewMagazineModal",
  data(){
    return{
      isbn: {
        code: null,
        is_physical: false
      },
      isLoading: false,
      canCancel: ['escape'],
      errores_validacion: {},
    }
  },
  props: ['modalHeader'],
  methods: {
    saveIsbn(){
      this.$emit('clicked', this.isbn)
      this.$emit('close')

    },
    async checkValidation(){
      const valid = await this.$refs.observer.validate()
      if(!valid){
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
  }
}
</script>

<style scoped>

</style>