<template>
  <div>

    <div class="columns">
      <div class="column is-narrow mt-2">
        <span v-if="!this.hideUpdate==true">Financiamientos del año: </span>

      </div>
      <div class="column is-3">
        <b-field v-if="!this.hideUpdate==true">
          <multiselect v-model="year"
                       :options="years"
                       @input="updateFinancings"
                       placeholder="Seleccione año"
                       selectLabel="Presione para seleccionar"
                       selectedLabel="Seleccionado"
                       deselectLabel="No se puede deseleccionar"
                       :allow-empty="false">
            <template v-slot:noOptions>
              No existen datos
            </template>
            <span slot="noResult">
                No se encontraron elementos.
            </span>
          </multiselect>
        </b-field>
      </div>
      <div class="column has-text-right">
        <b-field>
          <b-switch v-model="cardLayout">Formato tarjeta (sin filtros ni búsqueda)
            <b-tooltip
                label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                multilined
                position="is-left">
              <b-icon
                  pack="fas"
                  icon="info-circle"
                  type="is-info">
              </b-icon>
            </b-tooltip></b-switch>
        </b-field>

      </div>
    </div>

    <b-table
        :data="financings"
        :paginated=true
        :card-layout="cardLayout"
        per-page=10
        striped
        hoverable
        :current-page="currentPage"
        :pagination-simple=false
        pagination-position="bottom"
        default-sort-direction="asc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="financing.year"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página actual"
    >
      <b-table-column
          field= 'project'
          label= 'Proyecto'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          <a v-if="props.row.project" :href="getProjectUrl(props.row.project.id)">{{ props.row.project.name }}</a>
        </template>
      </b-table-column>
      <b-table-column
          field= 'code'
          label= 'Código proyecto'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{props.row.project ? props.row.project.code : ""}}
        </template>
      </b-table-column>
      <b-table-column
          field= 'amount'
          label= 'Monto ($)'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.amount }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'year'
          label= 'Año'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.year }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'financing_type'
          label= 'Tipo Financiamiento'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{props.row.financing_type ? props.row.financing_type : ""}}
        </template>
      </b-table-column>
      <b-table-column
          field= 'source'
          label= 'Entidad de financiamiento'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{props.row.partner_entity ? props.row.partner_entity : ""}}
        </template>
      </b-table-column>
      <b-table-column
          field= 'partner_entity_is_public'
          label= '¿Entidad Pública?'
          sortable>
        <template v-slot="props">
          <span v-if="props.row.partner_entity_is_public">SÍ</span>
          <span v-else>NO</span>
        </template>
      </b-table-column>
      <b-table-column
          field= 'partner_is_international'
          label= '¿Entidad Internacional?'
          sortable>
        <template v-slot="props">
          <span v-if="props.row.partner_is_international">SÍ</span>
          <span v-else>NO</span>

        </template>
      </b-table-column>

      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-danger">
              No se encontraron resultados para el año seleccionado.
            </b-message>
          </div>
        </div>
      </template>
    </b-table>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  </div>
</template>

<script>
export default {
  name: "FinancingTable",
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      years:[],
      year:null,

    }
  },
  created(){
    this.years = this.generateArrayOfYears();
    this.year = new Date().getFullYear();

  },
  props:[
      'financings','hideUpdate'

  ],
  methods:{
    generateArrayOfYears() {
      var max = new Date().getFullYear()
      var min = 1980
      var years = []
      years.push("Todo")
      for (var i = max; i >= min; i--) {
        years.push(i)
      }
      return years
    },

    updateFinancings(){
      this.$emit('updateFinancings',this.year)
    },
    getProjectUrl(project_id){
      return "/projects/"+project_id;
    },

  },
  computed:{

  }
}
</script>

<style scoped>

</style>