<template>
  <section>
    <div class="container">
      <div class="box">
        <div class="columns">
          <div class="column is-two-thirds">
            <h1 class="title">Personas con contrato renovable</h1>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="column is-narrow mt-2">
              <span>Filtrar contrataciones renovables por año de inicio: </span>
            </div>

            <b-field>
              <multiselect v-model="start_year"
                           :options="years"
                           placeholder="Seleccione año"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="No se puede deseleccionar"
                           :allow-empty="false"
                           @input="updateAppointments">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                No se encontraron elementos.
            </span>
              </multiselect>
            </b-field>

          </div>
          <div class="column">
            <div class="column is-narrow mt-2">
              <span>Filtrar contrataciones renovables por año de término: </span>
            </div>

            <b-field>
              <multiselect v-model="end_year"
                           :options="years"
                           placeholder="Seleccione año"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="No se puede deseleccionar"
                           :allow-empty="false"
                           @input="updateAppointments">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                No se encontraron elementos.
            </span>
              </multiselect>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="column is-narrow mt-2">
              <span>Filtrar contrataciones renovables por unidad de desempeño: </span>
            </div>
            <b-field>
              <multiselect v-model="unit"
                           :options="units"
                           placeholder="Seleccione unidad"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="No se puede deseleccionar"
                           :allow-empty="false"
                           @input="updateAppointments">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                No se encontraron elementos.
            </span>
              </multiselect>
            </b-field>
          </div>
        </div>
        <!--DATA TABLE-->
        <b-tabs type="is-toggle" position="is-centered">
          <b-tab-item label="Nombramientos Renovables" >
            <b-table
                :data="people"
                :paginated= true
                per-page="15"
                striped
                hoverable
                :current-page="currentPage"
                :pagination-simple=false
                pagination-position="bottom"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="product.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual"
                :checked-rows.sync="checkedRows"
                checkable
                sticky-checkbox
                :checkbox-position="checkPosition"
                @check-all="selectAll">
              <template #bottom>
                <h1 class="is-1 has-text-weight-bold">Total seleccionados</h1>: {{ checkedRows.length }}
              </template>
              <b-table-column
                  field="complete_run"
                  label="Rut"
                  sortable
                  searchable
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{ props.row.complete_run}}
                </template>
              </b-table-column>
              <b-table-column
                  field="complete_name"
                  label="Nombres"
                  sortable
                  searchable
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.complete_name ? props.row.complete_name : ""}}
                </template>
              </b-table-column>
              <b-table-column
                  field="resolution"
                  label="N°"
                  sortable
                  searchable
                  width="15px"
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.resolution ? props.row.resolution : ""}}
                </template>
              </b-table-column>
              <b-table-column
                  field="unit"
                  label="Unidad de Desempeño"
                  sortable
                  searchable
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.unit ? props.row.unit : ""}}
                </template>
              </b-table-column>
              <b-table-column
                  field="establishment"
                  label="Estamento"
                  searchable
                  sortable
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.establishment!==undefined ? props.row.establishment : ""}}
                </template>
              </b-table-column>
              <b-table-column
                  field="start_date"
                  label="Fecha inicio"
                  sortable
                  searchable :custom-search="searchStart"
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL")  : ""}}
                </template>
              </b-table-column>
              <b-table-column
                  field="end_date"
                  label="Fecha término"
                  sortable
                  searchable :custom-search="searchEnd"
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL") : ""}}
                </template>
              </b-table-column>
              <b-table-column
                  label="Acciones"
                  v-slot="props">
                <b-icon
                    pack="fas"
                    icon="eye"
                    size="is-medium"
                    @click.native="execShowModal(props.row)"/>
                <a v-if="props.row.person" :href=getPersonLink(props.row)><b-icon
                    pack="fas"
                    icon="user-cog"
                    type="is-dark"
                    size="is-medium"/></a>
              </b-table-column>
            </b-table>
          </b-tab-item>
          <b-tab-item label="Seleccionados para Renovación" >
            <div class="columns">
              <div class="column is-centered">
                <b-message type="is-info" has-icon>
                  <b>N° seleccionados: </b> {{checkedRows.length}}<br/>
                </b-message>
              </div>
            </div>
            <b-table
                :data="checkedRows"
                :paginated= true
                per-page="15"
                striped
                hoverable
                :current-page="currentPage"
                :pagination-simple=false
                pagination-position="bottom"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="product.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
              <b-table-column
                  field="complete_run"
                  label="Rut"
                  sortable
                  searchable
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{ props.row.complete_run}}
                </template>
              </b-table-column>
              <b-table-column
                  field="complete_name"
                  label="Nombres"
                  sortable
                  searchable
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.complete_name ? props.row.complete_name : ""}}
                </template>
              </b-table-column>
              <b-table-column
                  field="resolution"
                  label="N°"
                  sortable
                  searchable
                  width="15px"
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.resolution ? props.row.resolution : ""}}
                </template>
              </b-table-column>
              <b-table-column
                  field="unit"
                  label="Unidad de Desempeño"
                  sortable
                  searchable
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.unit ? props.row.unit : ""}}
                </template>
              </b-table-column>
              <b-table-column
                  field="establishment"
                  label="Estamento"
                  searchable
                  sortable
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.establishment!==undefined ? props.row.establishment : ""}}
                </template>
              </b-table-column>
              <b-table-column
                  field="start_date"
                  label="Fecha inicio"
                  sortable
                  searchable :custom-search="searchStart"
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL")  : ""}}
                </template>
              </b-table-column>
              <b-table-column
                  field="end_date"
                  label="Fecha término"
                  sortable
                  searchable :custom-search="searchEnd"
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL") : ""}}
                </template>
              </b-table-column>
              <b-table-column
                  label="Acciones"
                  v-slot="props">
                <b-icon
                    pack="fas"
                    icon="eye"
                    size="is-medium"
                    @click.native="execShowModal(props.row)"/>
                <a v-if="props.row.person" :href=getPersonLink(props.row)><b-icon
                    pack="fas"
                    icon="user-cog"
                    type="is-dark"
                    size="is-medium"/></a>
              </b-table-column>
            </b-table>
          </b-tab-item>
          <b-tab-item label="Errores Renovación" v-if="incorrectos.length>0">
            <b-table
                :data="incorrectos"
                :paginated= true
                per-page="15"
                striped
                hoverable
                :current-page="currentPage2"
                :pagination-simple=false
                pagination-position="bottom"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="product.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
              <b-table-column
                  field="complete_run"
                  label="Rut"
                  sortable
                  searchable
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{ props.row.complete_run}}
                </template>
              </b-table-column>
              <b-table-column
                  field="person_name"
                  label="Nombres"
                  sortable
                  searchable
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.person_name ? props.row.person_name : ""}}
                </template>
              </b-table-column>
              <b-table-column
                  field="resolution"
                  label="N°"
                  sortable
                  searchable
                  width="15px"
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.resolution ? props.row.resolution : ""}}
                </template>
              </b-table-column>
              <b-table-column
                  field="unit"
                  label="Unidad de Desempeño"
                  searchable
                  sortable
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.unit!==undefined ? props.row.unit : ""}}
                </template>
              </b-table-column>
              <b-table-column
                  field="establishment"
                  label="Estamento"
                  searchable
                  sortable
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.establishment!==undefined ? props.row.establishment : ""}}
                </template>
              </b-table-column>
              <b-table-column
                  field="start_date"
                  label="Fecha inicio"
                  sortable
                  searchable :custom-search="searchStart"
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL")  : ""}}
                </template>
              </b-table-column>
              <b-table-column
                  field="end_date"
                  label="Fecha término"
                  sortable
                  searchable :custom-search="searchEnd"
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL") : ""}}
                </template>
              </b-table-column>
              <b-table-column
                  field="error"
                  label="Error"
                  sortable
                  searchable
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.error ? props.row.error : ""}}
                </template>
              </b-table-column>
            </b-table>
          </b-tab-item>
        </b-tabs>
        <div>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(applyExtend)">
              <div class="columns">
                <div class="column">
                  <b-field grouped group-multiline>
                    <div class="control">
                      <h1 class="title is-5">Completar datos de renovación automática</h1>
                    </div>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                    <b-message type="is-danger" has-icon>
                      <div v-for="(item,index) in errores_validacion">
                        {{ item[0] }}
                      </div>
                    </b-message>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Año para la renovación de contratación*">
                    <ValidationProvider rules="required|numeric|min_value:1980" v-slot="{errors}">
                      <b-input v-model="new_year"></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>
              <!--
              <div class="columns">
                <div class="column">
                  <b-field label="Tipo de documento">
                    <multiselect v-model="resolution_type_id"
                                 :options="resolution_types.map(type => type.id)"
                                 :custom-label="opt => resolution_types.find(x => x.id === opt).name"
                                 placeholder="Seleccione tipo de documento"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="Presione para deseleccionar"
                                 :allow-empty="true">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                    </multiselect>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Número de documento">
                    <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                      <b-input v-model="resolution" type="text" ></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Fecha de documento">
                    <b-datepicker
                        v-model="resolution_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                        icon-pack="fas"
                        icon-right="sync-alt"
                        icon-right-clickable
                        @icon-right-click="updateDate"
                    >
                    </b-datepicker>
                  </b-field>
                </div>
              </div>-->
              <div class="columns">
                <div class="column">
                  <b-field label="Url documentación">
                    <b-input v-model="documentation_link" type="text" maxlength="255"></b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <div class="has-text-right">
                    <button
                        type="submit"
                        class="button is-primary">Aplicar Renovación</button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <b-modal
        v-model="isShowModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <component  :is="showName"
                    :id="selected"
                    modal-header="Nombramiento"
                    @close="props.close"/>
      </template>
    </b-modal>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import AppointmentAcademic from "../../life_record/appointment_academic.vue"
import AppointmentHours from "../../life_record/appointment_hours.vue";
import AppointmentAdministrative from "../../life_record/appointment_administrative.vue";
import moment from "moment";
import {searchDate, formatDate} from "../../../packs/utilities"
export default {
  name: "renew_academic",
  props:[
    "unitId"
  ],
  components:{
    AppointmentHours,
    AppointmentAcademic,
    AppointmentAdministrative,
  },
  data(){
    return {
      selected: null,
      people: [],
      showName: "",
      currentPage: 1,
      currentPage2:1,
      isLoading: false,
      isShowModalActive: false,
      checkedRows: [],
      checkPosition: "left",
      appointmentsIds: [],
      years: ["Todo"],
      start_year: "Todo",
      end_year: "Todo",
      new_year: "",
      new_semester: "",
      incorrectos: [],
      errores_validacion: {},
      unit: "TODAS",
      units: ["TODAS"],
      resolution: null,
      resolution_date: null,
      resolution_type_id: null,
      resolution_types: [],
      documentation_link: ""
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    searchEnd(row,input){
      return searchDate(row.end_date,input)
    },
    searchStart(row,input){
      return searchDate(row.start_date,input)
    },
    selectAll(rows) {
      if (rows.length > this.checkedRows.length) {
        this.$nextTick(() => { this.checkedRows = this.people})
      } else {
        this.$nextTick(() => {
          this.checkedRows = []
        })
      }
    },
    updateDate(){
      console.log("FECHA ACTUAL")
      this.resolution_date = moment({h:0, m:0, s:0, ms:0})._d;
    },
    updateAppointments(){
      this.isLoading = true
      axios
          .get("/people/list_renewable_appointments.json?start_year="+this.start_year+"&end_year="+this.end_year+"&establishment="+1+"&unit="+this.unit)
          .then(res => {
            this.people = res.data["appointments"]
            this.resolution_types = res.data["resolution_types"]
            this.people = this.people.map(people => {
              var newPeople = people
              newPeople["start_date"] = moment(newPeople["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newPeople["end_date"] = moment(newPeople["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newPeople;
            });
            this.isLoading = false
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
          })
    },
    initialize(){
      this.isLoading = true
      this.year = "Todo"
      this.years = this.createYears()
      return axios
          .get("/units/return_unit_names.json")
          .then(res => {
            this.units = res.data.units
            this.unit = res.data.unit
            return axios
                .get("/people/list_renewable_appointments.json?start_year="+this.start_year+"&end_year="+this.end_year+"&establishment="+1+"&unit="+this.unit)
                .then(res => {
                  this.people = res.data["appointments"]
                  this.resolution_types = res.data["resolution_types"]
                  this.people = this.people.map(people => {
                    var newPeople = people
                    newPeople["start_date"] = moment(newPeople["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
                    newPeople["end_date"] = moment(newPeople["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
                    return newPeople;
                  });
                  this.isLoading = false
                })
                .catch(error => {
                  console.log(error)
                  this.isLoading = false;
                })
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false
          })
    },
    createYears(){
      var i = 0;
      var actualDate = new Date()
      var array = ["Todo"]
      for(i = 0;i<30; i++){
        array.push(actualDate.getFullYear()+2-i)
      }
      return array
    },
    applyExtend(){
      if(this.checkedRows.length!==0){
        this.isLoading =true
        axios
            .post("/people/extend_appointments.json",{appointments_ids: this.appointmentsIds, year: this.new_year, resolution_type_id: this.resolution_type_id,
              resolution_date: this.resolution_date, resolution: this.resolution, documentation_link: this.documentation_link})
            .then(res => {
              this.isLoading = false
              this.incorrectos = res.data["incorrectos"]
              this.incorrectos = this.incorrectos.map(incorrecto => {
                var newIncorrecto = incorrecto
                newIncorrecto["start_date"] = moment(newIncorrecto["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
                newIncorrecto["end_date"] = moment(newIncorrecto["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
                return newIncorrecto;
              });
              if(res.data["incorrectos"].length===0){
                this.$buefy.dialog.alert({
                  title: 'Éxito',
                  message: 'Las contrataciones fueron renovadas con éxito',
                  confirmText: 'Ok'
                })
                this.checkedRows = []
                this.initialize()
              }
              else if(res.data["incorrectos"].length===this.checkedRows.length){
                this.$buefy.dialog.alert({
                  title: 'Error',
                  message: 'Las contrataciones no fueron renovadas con éxito. Revise pestaña de errores.',
                  type: 'is-danger',
                  confirmText: 'Ok'
                })
                this.checkedRows = []
              }
              else{
                this.$buefy.dialog.alert({
                  title: 'Precaución',
                  message: 'Algunas contrataciones fueron renovadas con éxito. Revise pestaña de errores.',
                  type: 'is-warning',
                  confirmText: 'Ok'
                })
                this.checkedRows = []
                axios
                    .get("/people/list_renewable_appointments.json?start_year="+this.start_year+"&end_year="+this.end_year+"&establishment="+1+"&unit="+this.unit)
                    .then(res => {
                      this.people = res.data["appointments"]
                      this.resolution_types = res.data["resolution_types"]
                      this.people = this.people.map(people => {
                        var newPeople = people
                        newPeople["start_date"] = moment(newPeople["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
                        newPeople["end_date"] = moment(newPeople["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
                        return newPeople;
                      });
                      this.isLoading = false
                    })
                    .catch(error => {
                      console.log(error)
                      this.isLoading = false;
                    })
              }
            })
            .catch(error => {
              console.log(error)
              this.$buefy.dialog.alert({
                title: 'Error',
                message: error.response.data["message"],
                type: 'is-danger',
                confirmText: 'Ok'
              })
              this.isLoading = false;
              this.checkedRows = []
              this.initialize()
            })
      }
    },
    getPerson(id){
      return this.people.find(o => o.person.id === id);
    },
    execShowModal(appointment){
      let person =this.getPerson(appointment.person.id)
      this.selected = appointment.id;
      if(person.establishment ==="ACADEMICOS") {
        this.showName = "AppointmentAcademic"
      }
      else if (person.establishment ==="ADMINISTRATIVOS") {
        this.showName = "AppointmentAdministrative"
      }
      else if (person.establishment==="PROFESORES HORAS DE CLASES") {
        this.showName = "AppointmentHours"
      }
      if ( this.showName!==""){
        this.isShowModalActive = true;
      }
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    getPersonLink(appointment){
      if (appointment.person){
        return "/people/"+appointment.person.id+"/life_record"
      }
      else{
        "/"
      }

    }
  },
  watch: {
    checkedRows: function (){
      if (this.checkedRows.length!==0){
        this.appointmentsIds = []
        this.checkedRows.forEach(row =>{ this.appointmentsIds.push(row.id)})
      }
      else{
        this.appointmentsIds = []
      }
    },
  }
}
</script>