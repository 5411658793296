<template>
  <div>
    <div class="container">
      <br>
      <div class="box">
        <b-tabs position="is-centered" class="block">
          <b-tab-item label="Errores de Publicaciones" icon="file-alt">
            <div class="columns">
              <div class="column is-8 is-size-4">
                Buscar y gestionar Publicaciones con DOI repetidos
                <b-tooltip
                    position="is-bottom"
                    size="is-large"
                    multilined>
                  <b-icon
                      size="is-small"
                      pack="fas"
                      icon="question-circle"
                      type="is-info">
                  </b-icon>
                  <template v-slot:content>
                    Busca publicaciones con DOI repetidos y permite su edición o eliminación.
                  </template>
                </b-tooltip>
              </div>
              <div class="column is-narrow">
                <b-button @click.prevent="searchDuplicatedDois"
                          icon-left="search" type="is-primary">
                  Buscar
                </b-button>
              </div>
            </div>

            <div class="columns" v-if="showPublicationTable">
              <div class="column">
                <b-message
                    title="Pasos a seguir"
                    type="is-warning"
                    has-icon
                    aria-close-label="Cerrar mensaje">
                  Para poder gestionar las publicaciones duplicadas, primero deben revisarse las publicaciones, seleccionar la que tenga
                  los datos más precisos y ver si debe ser complementada con los datos de la que no fue seleccionada.
                  Luego debe eliminarse la publicación cuyos datos sean los menos fiables, así, finalmente, cuando se edite la publicación correcta,
                  al momento de guardar no se arrojará el error de que ya existe una publicación con el DOI ingresado.
                </b-message>

              </div>
            </div>

            <div class="columns" v-if="showPublicationTable">
              <div class="column">
                <b-table
                    :key="tableDataKey"
                    :data="badPublications"
                    :paginated=true
                    per-page=10
                    striped
                    hoverable
                    :current-page="currentPage"
                    :card-layout="cardLayout"
                    :pagination-simple=false
                    pagination-position="bottom"
                    default-sort-direction="desc"
                    sort-icon="arrow-up"
                    sort-icon-size="is-small"
                    default-sort="last_patent_state_date"
                    aria-next-label="Siguiente"
                    aria-previous-label="Anterior"
                    aria-page-label="Página"
                    aria-current-label="Página actual">
                  <b-table-column
                      field= 'title'
                      label= 'Título'
                      searchable
                      sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{  props.row.title }}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field= 'doi'
                      label= 'DOI'
                      searchable
                      sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{  props.row.doi }}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field= 'publicaton_date'
                      label= 'Fecha'
                      searchable
                      sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{  props.row.publication_date }}
                    </template>
                  </b-table-column>
                  <b-table-column
                      label="Acciones"
                      width="20%"
                      v-slot="props">
                    <a :href="'/publications/'+props.row.id" target="_blank">
                      <b-button type="is-info"
                                icon-right="external-link-alt" />
                    </a>
                    <b-button type="is-success"
                              @click.prevent="editPublication(props.row)"
                              icon-right="edit" />
                    <b-button type="is-danger"
                              @click.prevent="confirmDeletePublication(props.row)"
                              icon-right="trash" />
                  </b-table-column>
                  <template slot="empty" slot-scope="props">
                    <div class="columns is-centered">
                      <div class="column is-6 is-centered has-text-centered">
                        <b-message type="is-danger">
                          No se encontraron datos.
                        </b-message>
                      </div>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>

            <b-modal
                :active="isComponentModalActive"
                width="90%"
                :can-cancel="true"
                aria-modal>
              <template #default="props">
                <new-publication-form modal-cancel="true"
                                      :cargo-principal="cargoPrincipal"
                                      :user-id="userId"
                                      :response-type="'RETORNO'"
                                      modal-header="Editar Publicación"
                                      v-on:response="updatePublicationResponse"
                                      query-type="update" v-bind:publication-id="selectedPublication.id" @close="closeModal"></new-publication-form>
              </template>
            </b-modal>

          </b-tab-item>
        </b-tabs>
      </div>


    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
import NewPublicationForm from "../publications/NewPublicationForm";
import axios from "axios";

export default {
  name: "IDControlDashboard",
  components:{NewPublicationForm},
  props:['userId','cargoPrincipal'],
  data(){
    return{
      errores_validacion:{},
      isLoading:false,
      bad_ruts:[],
      fake_runs:[],
      badPublications:[],
      currentPage:1,
      cardLayout:false,
      currentCite:{},
      showPublicationTable:false,
      selectedPublication:{},
      isComponentModalActive:false,
      showTable:true,
      tableDataKey:0
    }
  },
  methods:{
    searchDuplicatedDois(){
      let vm = this;
      vm.isLoading=true;
      axios
          .get("/dashboards/data-control/repeated-dois.json")
          .then(res => {
            vm.badPublications = res.data;
            vm.showPublicationTable = true;
            vm.isLoading=false;
          }).
      catch(error => {
        vm.$buefy.dialog.alert({
          message: 'Hubo un error en su petición.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        vm.isLoading=false;
      })
    },
    closeModal(){
      this.isComponentModalActive = false;
    },
    editPublication(row){
      this.isComponentModalActive = true;
      this.selectedPublication = row;
    },
    confirmDeletePublication(row) {
      this.$buefy.dialog.confirm({
        title: 'Anular publicación',
        message: '¿Está seguro que desea <b>anular</b> la publicación? Esta acción la dejará inválida.<br>',
        confirmText: 'Anular Publicación',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/publications/" + row.id + "/nullify_publication.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Publicación anulada correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                let badPublicationIndex = this.badPublications.findIndex(obj => obj==row);
                if (badPublicationIndex>=0){
                  this.badPublications.splice(badPublicationIndex,1);
                }

                this.isLoading = false;
                this.isNullifyActive = false;
              }).catch(error => {
            this.$buefy.dialog.alert({
              message: '<b>Hubo un error en su solicitud</b><br>',
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
          })
        }
      })
    },
    updatePublicationResponse(publication){
      let newPublicationIndex = this.badPublications.findIndex(obj => obj.id==publication.id);
      if (newPublicationIndex>=0){
        this.badPublications[newPublicationIndex] = publication;
        this.isComponentModalActive = false;
        this.tableDataKey = this.tableDataKey+1;
      }
    }
  }
}
</script>

<style scoped>

</style>