<template>
  <section>
    <div class="columns">
      <div class="column is-12">
        <div class="columns">
          <div class="column">
            <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
              <b-message type="is-danger" has-icon>
                <div v-for="(item,index) in errores_validacion" :key='index'>
                  <div v-if="item">{{ item[0] }}</div>
                </div>
              </b-message>
            </div>
          </div>
        </div>
        <ValidationObserver ref="observer" v-slot="{ invalid }">
          <form @submit.prevent="saveProject">
            <div class="box">




                <b-steps
                    v-if="isUpdatingAndDeletingParticipant(false)!=undefined"
                    v-model="activeStep"
                    :animated="isAnimated"
                    :rounded="isRounded"
                    :has-navigation="hasNavigation"
                    :icon-prev="prevIcon"
                    :icon-next="nextIcon"
                    :label-position="labelPosition"
                    :mobile-mode="mobileMode">
                  <b-step-item v-if="isUpdatingAndDeletingParticipant(false)"
                               step="1" label="General" :clickable="isStepsClickable">
                    <h1 class="title has-text-centered">General  [1/2]</h1>

                      <div class="columns">
                        <div class="column is-4 is-offset-8">
                          <b-switch v-model="requiredFieldOnly">Mostrar solo campos obligatorios</b-switch>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column">
                          <b-field label="Nombre*">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                              <b-input v-model="project.name" type="text"></b-input>
                              <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>
                      </div>
                      <!-- TIPO PROYECTO -->
                      <div class="columns">
                        <div class="column" v-if="tipos_proyecto.length>0">
                          <b-field label="Tipo de Proyecto*">
                            <ValidationProvider rules="required" v-slot="{errors}">
                              <multiselect v-model="project.project_type_id"
                                           :options="tipos_proyecto.map(type => type.id)"
                                           :custom-label="opt => tipos_proyecto.find(x => x.id == opt).name"
                                           placeholder="Seleccione tipo de proyecto"
                                           selectLabel="Presione para seleccionar"
                                           selectedLabel="Seleccionado"
                                           deselectLabel="No se puede deseleccionar"
                                           :allow-empty="false">
                                <template v-slot:noOptions>
                                  No existen datos
                                </template>
                                <span slot="noResult">
                                    No se encontraron elementos.
                                </span>
                              </multiselect>
                              <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>
                        <div class="column">
                          <b-field>
                            <template #label>
                              Código*
                              <b-tooltip
                                  label="Esté código será utilizado como identificador único en caso de que haya alcance de nombre. Se sugiere ingresar código otorgado
                            por entidad auspiciante, por ejemplo el código otorgado por la universiad o por ANID"
                                  position="is-right"
                                  size="is-medium"
                                  multilined>

                                <b-icon
                                    pack="fas"
                                    icon="info-circle"
                                    size="is-small"
                                    type="is-info">
                                </b-icon>
                              </b-tooltip>
                            </template>
                            <ValidationProvider rules="required" v-slot="{errors}">
                              <b-input type="text" v-model="project.code"></b-input>
                              <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>
                      </div>

                      <div class="columns">
                        <div class="column" v-if="!requiredFieldOnly">
                          <div class="field">
                            <b-field label="Año de adjudicación">
                              <ValidationProvider rules="integer|positive" v-slot="{errors}">
                                <b-input type="number" v-model="project.award_year"></b-input>
                                <span class="validation-alert">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </b-field>
                          </div>
                        </div>
                        <div class="column" v-if="!requiredFieldOnly">
                          <div class="field">
                            <b-field label="Fecha de inicio">
                              <b-datepicker
                                  v-model="project.start_date"
                                  icon="calendar-alt"
                                  trap-focus
                                  locale="es-ES"
                                  editable
                                  placeholder="Formato de Fecha: dd/mm/aaaa">
                              </b-datepicker>
                            </b-field>
                          </div>
                        </div>
                        <div class="column" v-if="!requiredFieldOnly">
                          <div class="field">
                            <b-field label="Fecha de termino">
                              <b-datepicker
                                  v-model="project.end_date"
                                  icon="calendar-alt"
                                  trap-focus
                                  locale="es-ES"
                                  editable
                                  placeholder="Formato de Fecha: dd/mm/aaaa">
                              </b-datepicker>
                            </b-field>
                          </div>
                        </div>
                      </div>

                      <div class="columns">
                        <div class="column" v-if="!requiredFieldOnly">
                          <b-checkbox v-model="project.public_contract">
                            ¿Es Contrato Público?
                          </b-checkbox>
                        </div>
                        <div class="column" v-if="!requiredFieldOnly">
                          <b-checkbox v-model="project.multidisciplinary">
                            ¿Es Multidisciplinario?
                          </b-checkbox>
                        </div>
                        <div class="column" v-if="!requiredFieldOnly">
                          <b-checkbox v-model="project.international_agreement">
                            ¿Tiene Acuerdo Internacional?
                          </b-checkbox>
                        </div>
                      </div>

                      <div class="columns">
                        <div class="column" v-if="!requiredFieldOnly">
                          <div class="field">
                            <b-field label="Resumen del Proyecto">
                              <b-input
                                  type="textarea"
                                  minlength="10"
                                  maxlength="500"
                                  v-model="project.description">
                              </b-input>
                            </b-field>
                          </div>
                        </div>

                      </div>


                      <h3 class="is-size-3 has-text-weight-bold">Palabras clave
                        <b-tooltip
                            label="Presione Enter o coma luego de escribir la palabra clave para que sea agregada. Ingrese al menos 3 palabras para activar la búsqueda."
                            position="is-right"
                            size="is-medium"
                            multilined>

                          <b-icon
                              pack="fas"
                              icon="info-circle"
                              size="is-small"
                              type="is-info">
                          </b-icon>
                        </b-tooltip>
                      </h3>
                      <b-field label="Ingrese o seleccione palabras clave">
                        <b-taginput
                            v-model="project.key_words_attributes"
                            :data="palabras_claves_filtradas"
                            autocomplete
                            :allow-new="true"
                            field="name"
                            icon="tag"
                            placeholder="Agrega una palabra clave"
                            @typing="getFilteredTags">
                        </b-taginput>
                      </b-field>

                      <!--                  <h3 class="is-size-3 has-text-weight-bold">Líneas de Investigación
                                          <b-tooltip
                                              label="No se agregarán líneas de investigación nuevas, solo se buscarán líneas de investigación ya existentes"
                                              position="is-right"
                                              size="is-medium"
                                              multilined>

                                            <b-icon
                                                pack="fas"
                                                icon="info-circle"
                                                size="is-small"
                                                type="is-info">
                                            </b-icon>
                                          </b-tooltip>

                                        </h3>

                                        &lt;!&ndash; FORMULARIO LINEAS DE INVESTIGACION &ndash;&gt;
                                        <div class="columns">
                                          <div class="column">
                                            <b-field label="Ingrese líneas de investigación">
                                              <b-taginput
                                                  v-model="project.research_lines_attributes"
                                                  :data="lineas_investigacion_filtradas"
                                                  type="is-primary is-light"
                                                  autocomplete
                                                  :allow-new="false"
                                                  field="name"
                                                  icon="tag"
                                                  placeholder="Agrega una línea de investigación"
                                                  @typing="getFilteredResearchLines">
                                              </b-taginput>
                                            </b-field>
                                          </div>
                                        </div>-->

                  </b-step-item>


                  <b-step-item step="2" label="Participantes" :clickable="isStepsClickable">
                    <h1 class="title has-text-centered">Participantes [2/2]</h1>
                    <div v-if="queryType=='update'" class="columns is-centered">
                      <div class="column is-centered has-text-centered is-8">
                        <b-message
                            title="Información importante"
                            type="is-warning"
                            aria-close-label="Cerrar mensaje">
                          Solo la/el usuaria/o que es Dueña o Dueño de esta actividad académica tiene permisos para editar todos sus atributos.<br>
                          Quienes participan en la actividad académica pero no son dueños, solo pueden editar su participación.
                          <br><span v-if="project.creator_name">Origen de la información: {{ project.creator_name }}</span>
                        </b-message>
                      </div>
                    </div>

                    <b-tooltip label="En esta sección debe ingresar las(os) participantes del proyecto que
                                  se encuentren registrados en el sistema, de esta manera, los datos ingresados
                                   de este proyecto quedarán asociados a esta persona."
                               position="is-right"
                               size="is-medium"
                               multilined>
                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          size="is-small"
                          type="is-info">
                      </b-icon>
                    </b-tooltip>
                    <div v-if="isUpdatingAndDeletingParticipant(false)" class="is-pulled-right">
                      <b-button  class="mt-2" @click.prevent="execAddModal(projectId)"
                                 type="is-primary">Administrar Participantes</b-button>
                    </div>

                    <div class="column">
                      <h3 class="is-size-4 has-text-weight-bold">Tabla Resumen Participantes</h3>
                      <b-table
                          :data="participantes"
                          :paginated=true
                          per-page=5
                          striped
                          hoverable
                          :current-page="currentPage"
                          :card-layout="cardLayout"
                          :pagination-simple=false
                          pagination-position="bottom"
                          default-sort-direction="desc"
                          sort-icon="arrow-up"
                          sort-icon-size="is-small"
                          default-sort="last_patent_state_date"
                          aria-next-label="Siguiente"
                          aria-previous-label="Anterior"
                          aria-page-label="Página"
                          aria-current-label="Página actual">
                        <b-table-column
                            field= 'name'
                            label= 'Nombres'
                            width="20em"
                            searchable
                            sortable>
                          <template
                              slot="searchable"
                              slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                icon="search"
                                size="is-small" />
                          </template>
                          <template v-slot="props">
                            {{  props.row.name }}
                          </template>
                        </b-table-column>
                        <b-table-column
                            field= 'corporate_mail'
                            label= 'Correo Corporativo'
                            searchable
                            sortable>
                          <template
                              slot="searchable"
                              slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                icon="search"
                                size="is-small" />
                          </template>
                          <template v-slot="props">
                            {{  props.row.corporate_mail }}
                          </template>
                        </b-table-column>
                        <b-table-column
                            field= 'unit'
                            label= 'Unidad'
                            searchable
                            sortable>
                          <template
                              slot="searchable"
                              slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                icon="search"
                                size="is-small" />
                          </template>
                          <template v-slot="props">
                            {{  props.row.unit }}
                          </template>
                        </b-table-column>
                        <b-table-column
                            label="Tipo de Participación*"
                            v-slot="props">
                          <ValidationProvider v-if="isUpdatingAndDeletingParticipant(props.row)"
                                              rules="required" v-slot="{errors}">
                            <multiselect v-model="props.row.participant_type_id"
                                         :options="tipos_participacion.map(x => x.id)"
                                         :custom-label="opt => tipos_participacion.find(x => x.id == opt).name"
                                         placeholder="Seleccione tipo de participación"
                                         selectLabel="Presione para seleccionar"
                                         selectedLabel="Seleccionado"
                                         deselectLabel="No se puede deseleccionar"
                                         :allow-empty="false">
                              <template v-slot:noOptions>
                                No existen datos
                              </template>
                              <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                            </multiselect>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-table-column>

                        <b-table-column
                            label="Acción"
                            width="10"
                            v-slot="props">
                          <b-icon
                              v-if="isUpdatingAndDeletingParticipant(props.row)"
                              pack="fas"
                              style="color: #e50000;"
                              icon="trash"
                              size="is-medium"
                              @click.native="deleteParticipantButton(props.row)"/>
                        </b-table-column>

                        <template slot="empty" slot-scope="props">
                          <div class="columns is-centered">
                            <div class="column is-6 is-centered has-text-centered">
                              <b-message type="is-danger">
                                No se encontraron participantes asociados.
                              </b-message>
                            </div>
                          </div>
                        </template>
                      </b-table>
                    </div>
                    <hr class="dropdown-divider" aria-role="menuitem">
                    <div class="columns" v-if="queryType === 'update'">
                      <div class="column" v-if="project.is_creator">
                        <h3 class="is-size-6 has-text-weight-bold">
                          Dueña(o)*
                          <b-tooltip label="Debe seleccionar al menos una Dueña o Dueño del dato que se encuentre como participante en la actividad académica. Si realiza el cambio, no podrá revertirlo."
                                     position="is-right"
                                     size="is-large"
                                     multilined>
                            <b-icon
                                pack="fas"
                                icon="info-circle"
                                size="is-small"
                                type="is-info">
                            </b-icon>
                          </b-tooltip>
                        </h3>
                        <b-field label=" ">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <multiselect v-model="ownerId"
                                         :options="participantes.map(type => type.person_id)"
                                         :custom-label="opt => participantes.find(x => x.person_id === opt).name"
                                         placeholder="Seleccione Dueña(o)"
                                         selectLabel="Presione para seleccionar"
                                         selectedLabel="Seleccionado"
                                         deselectLabel=""
                                         :allow-empty="false">
                              <template v-slot:noOptions>
                                No existen datos
                              </template>
                              <span slot="noResult">
                          No se encontraron elementos.
                      </span>
                            </multiselect>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                    </div>

                    <div class="columns" v-else>
                      <div class="column">
                        <h3 class="is-size-6 has-text-weight-bold">
                          Dueña(o)*
                          <b-tooltip label="Debe seleccionar al menos una Dueña o Dueño del dato que se encuentre como participante en la actividad académica. Si realiza el cambio, no podrá revertirlo."
                                     position="is-right"
                                     size="is-large"
                                     multilined>
                            <b-icon
                                pack="fas"
                                icon="info-circle"
                                size="is-small"
                                type="is-info">
                            </b-icon>
                          </b-tooltip>
                        </h3>
                        <b-field label=" ">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <multiselect v-model="ownerId"
                                         :options="participantes.map(type => type.person_id)"
                                         :custom-label="opt => participantes.find(x => x.person_id === opt).name"
                                         placeholder="Seleccione Dueña(o)"
                                         selectLabel="Presione para seleccionar"
                                         selectedLabel="Seleccionado"
                                         deselectLabel=""
                                         :allow-empty="false">
                              <template v-slot:noOptions>
                                No existen datos
                              </template>
                              <span slot="noResult">
                          No se encontraron elementos.
                      </span>
                            </multiselect>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                    </div>

                  </b-step-item>

                  <template
                      #navigation="{previous, next}">
                    <div class="columns is-pulled-right" >
                      <div class="column">
                        <b-button
                            outlined
                            icon-left="chevron-left"
                            :disabled="previous.disabled"
                            @click.prevent="previous.action">
                        </b-button>
                        <b-button
                            outlined
                            icon-right="chevron-right"
                            :disabled="next.disabled"
                            @click.prevent="next.action">
                        </b-button>

                      </div>
                    </div>
                  </template>

                </b-steps>
                <div class="actions has-text-right">
                  <button v-if="modalCancel" class="button is-danger" @click.prevent="$emit('close')">Cancelar</button>
                  <button class="button is-primary" type="submit" @click="checkValidation()">Guardar Proyecto</button>
                </div>



            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>




    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="isModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-participant-project :project-id="projectId"
                                   :id="selected"
                                   :participantes="participantes"
                                   query-type="update"
                                   modal-header="Añadir Participantes"
                                   @close="props.close"
                                   @clicked="onClickChild"
        >
        </modal-participant-project>
      </template>
    </b-modal>
  </section>
</template>

<script>
import axios from "axios";
import ModalParticipantProject from "./modal_participant_project";
import moment from "moment";
export default {
  name: "NewProjectForm",
  components:{ ModalParticipantProject },
  data() {
    return {
      activeStep: 0,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      hasNavigation: true,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      errores_validacion: {},
      project:{
        participants_attributes:[],
        financings_attributes:[],
        research_lines_attributes:[],
        project_states_attributes:[],
        key_words_attributes:[],
        validation_attributes:{}
      },
      tipos_proyecto:[],
      isLoading:false,
      requiredFieldOnly:false,
      currentPage:1,
      cardLayout:false,
      palabras_claves:[],
      palabras_claves_filtradas:[],
      lineas_investigacion:[],
      lineas_investigacion_filtradas:[],
      isModalActive:false,
      canCancel: ['escape'],
      participantes:[],
      tipos_participacion:[],
      is_validated:false,
      ownerId: null,
    }
  },
  props:[
      'modalHeader','hideHeader','userId','queryType','projectId','modalCancel', "cargoPrincipal", "unidadPrincipal","showParticipantsFirst", 'fromAcademic'
  ],
  async created(){
    await this.getProjectTypes();
    if (this.queryType=='update'){
      this.getProject();
    }
  },
  methods:{
    async saveProject(){
      let vm = this;
      await vm.checkValidation();
      if (vm.is_validated){
        vm.isLoading=true;
        //vm.project.participants_attributes = vm.participantes
        //var retorno = JSON.parse(JSON.stringify(this.project.participants_attributes));
        var retorno = [];
        if (vm.participantes){
          vm.project.participants_attributes = vm.participantes
          retorno = JSON.parse(JSON.stringify(vm.project.participants_attributes));
        }
        for (var i=0;i<vm.project.key_words_attributes.length;i++){
          if( !(typeof vm.project.key_words_attributes[i] === 'object' && vm.project.key_words_attributes[i] !== null) ){
            var name = vm.project.key_words_attributes[i];
            vm.project.key_words_attributes[i] = new Object();
            vm.project.key_words_attributes[i].name=name;
            vm.project.key_words_attributes[i].id=null;
          }
        }
        if (vm.queryType==="create") {
          vm.project.academic_activity_state_id = 1
          axios
              .post("/projects.json", {
                project: vm.project,
                participants: retorno.map(function(x) {
                  delete x.unit_id;
                  delete x.unit_participants;
                  return x;
                }),
                user_id:vm.userId,
                data_source: vm.project.validation_attributes.data_source,
                ownerId: vm.ownerId
              })
              .then(response => {
                vm.errores_validacion = {};
                window.location.href = '/projects/' +response.data["id"]
                vm.isLoading=false;
              }).
          catch(error => {
            vm.errores_validacion = error.response.data
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            vm.isLoading=false;
          })
        }

        if (vm.queryType==="update"){
          axios
              .put("/projects/"+vm.projectId+".json", {
                project: vm.project,
                participants: retorno.map(function(x) {
                  delete x.unit_id;
                  delete x.unit_participants;
                  return x;
                }),
                user_id:vm.userId,
                data_source: vm.project.validation_attributes.data_source,
                ownerId: vm.ownerId
              })
              .then(response => {
                vm.errores_validacion = {};
                if(this.fromAcademic) {
                  this.$emit('refresh')
                  this.$emit('close')
                }else{
                  window.location.href = '/projects/'+this.projectId
                }
                vm.isLoading=false;
              }).
          catch(error => {
            vm.errores_validacion = error.response.data
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            vm.isLoading=false;

          })
        }

      }


    },
    async getProjectTypes(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/projects/form-data.json")
        .then(res => {
          vm.tipos_proyecto = res.data.project_types;
          vm.estados_participacion = res.data.participation_states;
          vm.tipos_participacion = res.data.participation_types;
          if (vm.cargoPrincipal.name==="ACADEMICO" && vm.queryType ==="create") {
            //se añade un participante automaticamente si es que es acádemico
            vm.participantes.push({
              person_id: res.data["participante"].person_id,
              name: res.data["participante"].name,
              alias: res.data["participante"].alias != null ? res.data["participante"].alias.toUpperCase() : "",
              corporate_mail: res.data["participante"].corporate_mail != null ? res.data["participante"].corporate_mail.toUpperCase() : "",
              unit: res.data["participante"].unit,
              url: res.data["participante"].url
            })
          }
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    getFilteredTags(text) {
      if (text.length >= 3){
        axios
            .get("/projects/filter_keywords_project/"+text.toUpperCase()+".json")
            .then(res => {
              this.palabras_claves_filtradas = res.data['keywords'];
            }).
        catch(error => {
          console.log(error)
        })
      }
      else{
        this.palabras_claves_filtradas = []
      }

      this.palabras_claves_filtradas = this.palabras_claves.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    getFilteredResearchLines(text) {
      this.lineas_investigacion_filtradas = this.lineas_investigacion.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    async checkValidation() {
      this.is_validated = false;
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar tanto la pestaña General como la de Participantes.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
      else{
        this.is_validated = true;
      }
    },
    execAddModal(id){
      this.selected = id;
      this.isModalActive = true;
    },
    onClickChild(value){
      this.isLoading = true
      this.participantes = value
      this.isLoading = false

    },
    getProject(){
      let vm = this;
      vm.isLoading=true;
      return axios
          .get("/projects/"+vm.projectId+".json")
          .then(res => {
            vm.project = res.data;

            if(vm.project.participants!= null){
              vm.participantes = vm.project.participants
            }
            if(vm.project.participants== ""){
              vm.participantes = []
            }
            if (vm.project.start_date)
              vm.project.start_date = new Date(moment(vm.project.start_date).format('MM/DD/YYYY'));
            if (vm.project.end_date)
              vm.project.end_date = new Date(moment(vm.project.end_date).format('MM/DD/YYYY'));
            if (vm.participantes.filter(person => person.person_id == vm.project.ownerId).length > 0){
              vm.ownerId = vm.project.ownerId
            }
            vm.activeStep= 0;
            if(vm.showParticipantsFirst){
              vm.activeStep=1;
            }
            vm.isLoading=false;
          })
          .catch(e => {
            console.log(e);
            vm.isLoading=false;
          });
    },
    deleteParticipant(participant){
      if(participant.person_id == this.ownerId){
        this.ownerId = null
      }
      this.participantes = this.participantes.filter(person => person.person_id != participant.person_id);
    },
    isUpdatingAndDeletingParticipant(row){
      if(this.queryType=="create"){
        return true;
      }
      else if(this.queryType=='update'){
        //console.log(this.project.current_user_person_id, row.id,row)
        if (row){
          return this.project.is_creator || this.project.current_user_person_id == row.person_id;
        }
        return this.project.is_creator;
      }

    },
    deleteParticipantButton(participant){

      this.$buefy.dialog.confirm({
        title: 'Eliminar Participante',
        message: 'Precaución, al momento de guardar el proyecto esta acción eliminará la asociación con la/el participante, lo que implica que ya no podrá ver este proyecto ni será contado en su actividad académica ¿Desea continuar?',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteParticipant(participant)
      })

    },
  }
}
</script>

<style scoped>

</style>