<template>
  <section>
    <div class="container">
      <b-table
          :data="convenios"
          :paginated=true
          per-page=10
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="publication.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field= 'name'
            label= 'Nombre'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            <a @click="goToAgreement(props.row.id)"><i class="fas fa-user-circle"></i> {{ props.row.name }}</a>
          </template>
        </b-table-column>
        <b-table-column
            field= 'agreement_type.agreement_general_type'
            label= 'Categoría general'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.agreement_type.agreement_general_type }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'agreement_type.agreement_specifict_type'
            label= 'Categoría específica'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.agreement_type.agreement_specifict_type }}
          </template>
        </b-table-column>

        <b-table-column
            field= 'agreement_stage'
            label= 'Etapa del convenio'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.agreement_stage }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'agreement_state'
            label= 'Estado del convenio'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.agreement_state }}
          </template>
        </b-table-column>
        <!--<b-table-column
            field= 'signature_year'
            label= 'Firmado en'
            searchable
            sortable
            v-slot="props"
        >
          <div v-if="props.row.signature_year !== null">{{ props.row.signature_year }}</div>
          <div v-else>No se ha encontrado registro</div>
        </b-table-column>
        <b-table-column
            field= 'agreement_users'
            label= 'Usuarios'
            searchable
            sortable
            v-slot="props"
        >
          <div v-if="props.row.agreement_users !== null">{{ props.row.agreement_users }}</div>
          <div v-else>No se ha encontrado registro</div>
        </b-table-column>-->
      </b-table>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
  </section>
</template>
<script>
import axios from "axios"
export default {
  props:["convenios"],
  data(){
    return {
      //Variables para el control
      isLoading:false,
      currentPage:1,
      applyFilter: 0,

      // para almacenamiento temporal
      convenios_temp: [],
      partner_entities: []
    }
  },
  created(){
    this.isLoading=true;
    this.convenios_temp = this.convenios;
    this.filtered_convenios = this.convenios;
    this.isLoading=false;
  },
  methods:{
    newAgreement(){
      this.isLoading=true;
      window.location.href="/agreements/new"
    },
    goToAgreement(agreement_id){
      this.isLoading=true;
      window.location.href="/agreements/"+agreement_id
    },
  },
  computed:{

  }
}
</script>