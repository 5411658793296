<template>
  <section>

        <!-- Box de Formulario Evento -->
        <div class="modal-card" style="width: auto" id="modal-event">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body">
            <div class="columns">
              <div class="column">
                <b-field label="Intervalo">
                  <b-input :value="interval" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <VueSchedule disabled readonly v-model="schedule" :steps="interval" :dayTable="['Lun','Mar','Mie','Jue','Vie','Sab', 'Dom']"  :key="componentKey"/>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
          </div>
        </footer>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import axios from "axios";
import 'vue-daily-scheduler/dist/vue-schedule.min.css'
import VueSchedule from 'vue-daily-scheduler'
import moment from 'moment'

export default {
  name: "modal_weekly_schedule",
  props:[
    'queryType', 'personId', 'id', 'modalHeader', 'horarios', 'intervalo', 'stringHorario', 'scheduleDays'
  ],
  components: {
    VueSchedule
  },
  data(){
    return {
      project:{},
      nameDays: ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"],
      busqueda: "",
      currentPage:1,
      cardLayout:false,
      actualSchedule:{},
      errores_validacion:[],
      isLoading:false,
      isDisabled: false,
      timeArrayFront: [],
      timeArrayTime: [],
      interval: 60,
      stringSchedule: "",
      componentKey: 0,
      days: [],
      intervals: [{name: "10 min", number: 10}, {name: "20 min", number: 20}, {name: "30 min", number: 30}, {name: "40 min", number: 40},
        {name: "50 min", number: 50}, {name: "60 min", number: 60}],
      schedule: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: []
      }
    }
  },
  created() {
    this.isLoading=true;
    this.schedule = this.horarios
    this.interval = this.intervalo
    this.stringSchedule = this.stringHorario
    this.days = this.scheduleDays
    this.forceRerender()
    this.isLoading=false;
  },
  methods: {
    deleteData(){
    },
    forceRerender() {
      this.componentKey += 1;
    },



  }
}
</script>