<template>
    <div class="showsocialnetworks">
        <table class="table">
            <thead>
                <tr style="text-align:center;">
                    <th colspan="2" style="color:#000;"> Redes sociales </th>
                </tr>
            </thead>
            <tbody v-if='this.social_networks.length !== 0'>
                <tr style="color: #000;text-align:center;" v-for='(social_network, index) in this.social_networks' :key='index'>
                    <td><strong>{{social_network.social_network_name}}</strong></td>
                    <td v-if='social_network.link !== null'>
                        <a @click="linkToSocialNetwork(social_network.link)">{{social_network.link}}</a>
                    </td>
                    <td v-else> No se ha registrado un link para esta red social.</td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr style="color: #000;text-align:center;">
                    <td>No hay redes sociales registradas</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
    name: 'ShowSocialNetworks',
    props:{
        social_networks:{
            type: Array,
            dafault: []
        }
    },
    methods:{
        linkToSocialNetwork(link){
            window.open(link)
        }
    }
}
</script>