<template>
  <section>

    <div class="columns is-centered">
      <div class="column">
        <div  class="has-text-right">
          <b-button v-if="(disclosures.can_edit && disclosures.academic_activity_state_id == 1) || (disclosures.can_edit && disclosures.academic_activity_state_id == null)" type="is-info"
                    icon-left="edit"
                    @click="editDisclosure(disclosureId)">
            Editar Disclosure
          </b-button>
          <b-button v-if="(disclosures.is_creator || cargoPrincipal.name == 'ANALISTA I+D FING') && (disclosures.academic_activity_state_id == 1 || disclosures.academic_activity_state_id == null)" type="is-danger"
                    icon-left="ban"
                    @click="execNullifyModal">
            Anular Disclosure
          </b-button>
        </div>
        <hr class="dropdown-divider" aria-role="menuitem">
        <div class="columns">
          <div class="column">
            <b-field label="Nombre invención">
              <b-input readonly v-model="disclosures.name" type="text"></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fuente Financiamiento">
              <b-input readonly v-model="disclosures.financing_source" type="text"></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Fecha de Publicación">
              <b-input readonly v-model="disclosures.disclosure_date" type="text"></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha Invención">
              <b-input readonly v-model="disclosures.invention_date" type="text"></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Código">
              <b-input readonly v-model="disclosures.code" type="text"></b-input>
            </b-field>
          </div>
        </div>
      </div>
    </div>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        :active="isComponentModalActive"
        width="90%"
        :can-cancel="canCancel"
        aria-modal>
      <template #default="props">
        <new-disclosure-form modal-cancel="true"
                              :cargo-principal="cargoPrincipal"
                              :user-id="userId"
                              modal-header="Editar Disclosures"
                              query-type="update" v-bind:disclosure-id="disclosures.id" @close="closeModal"></new-disclosure-form>
      </template>
    </b-modal>
  </section>
</template>

<script>
import axios from "axios";
import NewDisclosureForm from "./NewDisclosureForm";


export default {
  name: "ShowGeneralTab",
  components: {NewDisclosureForm},
  data(){
    return{
      isLoading: false,
      isComponentModalActive: false,
      canCancel: ['escape']
    }
  },
  props:['disclosures','cargoPrincipal','userId','disclosureId'],
  methods:{
    editDisclosure(){
      this.isComponentModalActive = true;
    },
    execNullifyModal(){
      this.$buefy.dialog.confirm({
        title: 'Anular disclosure',
        message: '¿Está seguro que desea <b>anular</b> el disclosure? Esta acción lo dejará inválido.<br>',
        confirmText: 'Anular Disclosure',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/disclosures/" + this.disclosureId + "/nullify_disclosure.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Disclosure anulada correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                if (document.referrer == ''){
                  window.location.href = history.back()
                }
                else{
                  window.location.href = document.referrer
                }

                window.location.href = document.referrer
                this.isLoading = false;
                this.isNullifyActive = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Disclosure no pudo ser anulada</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },
    closeModal(){
      this.isComponentModalActive = false;
    },
  }
}
</script>