<template>
  <section>
    <div class="container">
      <div class="box">
        <div class="columns">
          <div class="column">
            <b-field grouped group-multiline>
              <div class="control">
                <h1 class="title">Búsqueda de Documentos</h1>
              </div>
            </b-field>
          </div>
        </div>
        <section>

          <div class="columns">
            <div class="column">
              <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                <b-message type="is-danger" has-icon>
                  <div v-for="(item,index) in errores_validacion" :key='index'>
                    <div v-if="item">{{ item }}</div>
                  </div>
                </b-message>
              </div>
            </div>
          </div>

          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(buscar_documento)">
              <div class="columns">
                <div class="column is-4">
                  <b-field label="Búsqueda Simple">
                    <ValidationProvider :rules="{max: 255, regex: /^[0-9a-zA-ZáéíóúÁÉÍÓÚÑñ\-\_\s]*$/}" v-slot="{ errors }">
                      <b-input v-model="search_all" type="text"></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column is-4">
                  <b-field label="Nombre">
                    <ValidationProvider :rules="{max: 255, regex: /^[a-zA-Z0-9áéíóúÁÉÍÓÚÑñ\-\_\7\s]*$/}" v-slot="{ errors }">
                      <b-input v-model="search_name"></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column is-4">
                  <b-field label="Carpeta Documento">
                    <ValidationProvider :rules="{max: 255, regex: /^[a-zA-Z0-9áéíóúÁÉÍÓÚÑñ\-\_\.\/\s]*$/}" v-slot="{ errors }">
                      <b-input v-model="search_category" type="text"></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column is-4">
                  <b-field label="Tipo de Documento">
                    <multiselect v-model="search_document_type"
                                 :options="document_types.map(type => type.id)"
                                 :custom-label="opt => document_types.find(x => x.id === opt).name"
                                 placeholder="Seleccione tipo de documento"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="Presione para deseleccionar"
                                 :allow-empty="true">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                    </multiselect>
                  </b-field>
                </div>
<!--                <div class="column is-4">
                  <b-field label="Google ID Documento">
                    <ValidationProvider :rules="{max: 255, regex: /^[a-zA-Z0-9áéíóúÁÉÍÓÚÑñ\-\_\/\s]*$/}" v-slot="{ errors }">
                      <b-input v-model="search_google_id" type="text"></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>-->
              </div>
              <div class="columns">
                <div class="column is-4">
                  <b-field label="Número de Documento">
                    <ValidationProvider rules="numeric" v-slot="{ errors }">
                      <b-input v-model="search_resolution" type="text" expanded></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column is-4">
                  <b-field label="Año de Documento">
                    <ValidationProvider rules="numeric" v-slot="{ errors }">
                      <b-input v-model="search_year" type="text"></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <div class="control">
                      <button type="submit" class="button is-primary">Buscar</button>
                    </div>
                    <div class="control">
                      <b-button @click="clean">Limpiar</b-button>
                    </div>
                  </b-field>
                </div>

              </div>
            </form>
          </ValidationObserver>
        </section>

        <!--DATA TABLE-->
        <div class="columns is-centered" v-if="!search_applied" style="margin-bottom: 40px; margin-top: 40px;">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-warning">
              Debe realizar una búsqueda para ver resultados de documentos
            </b-message>
          </div>
        </div>
        <div class="columns is-centered" v-if="search_applied&&documents.length===1" style="margin-bottom: 40px; margin-top: 40px;">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-warning">
              Se encontró {{this.documents.length}} resultado.
            </b-message>
          </div>
        </div>
        <div class="columns is-centered" v-if="search_applied&&documents.length!==1" style="margin-bottom: 40px; margin-top: 40px;">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-warning">
              Se encontraron {{this.documents.length}} resultados.
            </b-message>
          </div>
        </div>
        <b-field grouped group-multiline>
          <div class="control">
            <b-switch v-model="hideFolder" @input="hideColumn">{{text_folder}}
              <b-tooltip
                  label="Oculta/muestra la columna Carpeta de Documento"
                  multilined
                  position="is-left">
                <b-icon
                    pack="fas"
                    icon="info-circle"
                    type="is-info">
                </b-icon>
              </b-tooltip></b-switch>
          </div>
        </b-field>
        <b-table
            narrowed
            :data="documents"
            :paginated= true
            v-if="search_applied"
            per-page="20"
            striped
            hoverable
            :current-page="currentPage"
            :pagination-simple=false
            pagination-position="both"
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            default-sort="product.title"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual">
<!--            :opened-detailed="defaultOpenedDetails"
            detailed
            detail-key="name"
            @details-open="(row) => $buefy.toast.open(`Expanded ${row.name}`)"
            :show-detail-icon="showDetailIcon"-->

          <b-table-column
              field="index"
              label="N°"
              width="80">
            <template v-slot="props">
              {{ documents.indexOf(props.row) + 1 }}
            </template>
          </b-table-column>
<!--          <b-table-column
              field="name"
              label="Nombre"
              :width="size_name"
              sortable
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              <a :href="props.row.documentation_link" target="_blank">{{ props.row.show_name }}</a>
            </template>
          </b-table-column>-->
          <b-table-column
              field="document_category_name"
              label="Carpeta de Documento"
              sortable
              :visible="!hideFolder"
              searchable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.document_category_name}}
            </template>
          </b-table-column>
          <b-table-column
              field="document_type_name"
              label="Tipo Documento"
              sortable
              searchable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.document_type_name}}
            </template>
          </b-table-column>
          <b-table-column
              field="resolution"
              label="N° Documento"
              sortable
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.resolution}}
            </template>
          </b-table-column>
          <b-table-column
              field="formatted_document_date"
              label="Fecha documento"
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.document_date!= null ? props.row.formatted_document_date:props.row.year}}
            </template>
          </b-table-column>
<!--          <b-table-column
              field="google_id"
              label="Google ID Documento"
              sortable
              width="100"
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.google_id}}
            </template>
          </b-table-column>-->
          <b-table-column
              field= 'description'
              label= 'Nombre adicional del documento'
              searchable
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.document_type_name==="SIN INFORMACION" || props.row.document_type_name==="ACTA"?props.row.name:props.row.description }}
            </template>
          </b-table-column>
          <b-table-column
              label="Acciones"
              v-slot="props"
              width="110">
            <b-tooltip
                v-if="props.row.can_show"
                label="Muestra en detalle de la información relacionada al documento"
                :active="true">
              <b-button
                  pack="fas"
                  icon-left="info"
                  type="is-primary"
                  target="_blank"
                  v-if="props.row.can_show"
                  @click.native="goToDocument(props.row.id)"/>
            </b-tooltip>
          </b-table-column>
          <b-table-column
              label="Documentación"
              v-slot="props"
              width="135">
            <b-tooltip
                v-if="props.row.can_download"
                label="Descarga el documento"
                :active="true">
              <b-button
                  pack="fas"
                  icon-left="download"
                  type="is-primary is-light is-outlined"
                  v-if="props.row.can_download"
                  @click="downloadFile(props.row.id)"/>
            </b-tooltip>
            <b-tooltip
                v-if="props.row.can_show"
                label="Visualiza el documento. Solo permite archivos PDF"
                :active="true">
              <b-button
                  icon-pack="fas"
                  icon-left="eye"
                  type="is-success is-light is-outlined"
                  target="_blank"
                  v-if="props.row.can_show"
                  @click="viewFile(props.row.id)"/>
            </b-tooltip>
          </b-table-column>
          <template slot="detail" slot-scope="props">
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <p>
                    <strong>Etiquetas: </strong>
                    <span>{{props.row.name_tags}}</span>
                  </p>
                </div>
              </div>
            </article>
          </template>

          <template slot="empty" slot-scope="props">
            <div class="columns is-centered">
              <div class="column is-6 is-centered has-text-centered">
                <b-message type="is-danger">
                  No se encontraron resultados para datos ingresados.
                </b-message>
              </div>
            </div>
          </template>

        </b-table>


      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <!-- MODALES -->
    <b-modal
        v-model="showPdf"
        :can-cancel="true"
        :destroy-on-hide="true"
        aria-modal
        aria-role="dialog"
        has-modal-card
        trap-focus
        :width="2000"
    >
      <template #default="props">
        <pdf_view
            :src="src"
            @close="props.close"
        ></pdf_view>
      </template>

    </b-modal>
  </section>
</template>

<script>
import axios from "axios";
import Pdf_view from "../performances/pdf_view.vue";
import pdf from "vue-pdf";

export default {
  name: "search_documents",
  props:[
    "unitId"
  ],
  components:{
    Pdf_view, pdf

  },
  data(){
    return {
      selected: null,
      documents: [],
      currentPage: 1,
      isLoading: false,
      canCancel: ['escape'],
      search_applied: false,
      search_all: "",
      search_category: "",
      search_resolution: "",
      search_document_type: "",
      search_name: "",
      errores_validacion: {},
      simple_search: true,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      document_types: [],
      search_year: "",
      search_google_id: "",
      hideFolder: true,
      text_folder: "Ocultar Carpeta",
      size_name: 700,
      showPdf: false,
      src:null,
      // EL SHOW VA A LA URL people/:id/life_record
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    hideColumn(){
      this.isLoading = true
      if(this.hideFolder){
        this.text_folder = "Ocultar Carpeta"
        this.size_name = 700
      }
      else{
        this.text_folder = "Mostrar Carpeta"
        this.size_name = 300
      }
      this.isLoading = false
    },
    initialize(){
      this.isLoading = true;
      axios.get('/document_types.json').then(response=>{
        this.document_types = response.data
        this.isLoading=false;
      })
          .catch(error => {
            this.isLoading=false;
          })
    },
    clean(){
      this.isLoading = true
      this.search_all = ""
      this.search_category = ""
      this.search_resolution = ""
      this.search_name = ""
      this.search_document_type = ""
      this.search_year = ""
      this.search_google_id = ""
      this.isLoading = false
    },
    updateSearch(){
      this.isLoading = true
      this.search_all = ""
      this.search_category = ""
      this.search_resolution = ""
      this.search_name = ""
      this.search_document_type = ""
      this.search_year = ""
      this.search_google_id = ""
      this.isLoading = false
    },
    buscar_documento(){
      this.isLoading = true
      this.errores_validacion = {}
      if(this.search_all==="" && this.search_category ==="" && this.search_name ==="" && this.search_resolution ==="" && this.search_document_type ==="" && this.search_year === "" && this.search_google_id === ""){
        this.isLoading = false
        return this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Debe llenar algún campo para realizar una búsqueda',
          type: 'is-warning',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
      return axios.get('/documents/search-documents.json', {
        params: {
          all: this.search_all,
          category: this.search_category,
          name: this.search_name,
          resolution: this.search_resolution,
          document_type: this.search_document_type,
          year: this.search_year,
          google_id: this.search_google_id
        }
      }).then(response=>{
        this.documents = response.data
        if(this.documents.length === 0){
          this.isLoading=false;
          return this.$buefy.dialog.alert({
            title: 'Error',
            message: 'No se encontraron resultados para su búsqueda',
            type: 'is-warning',
            hasIcon: true,
            icon: 'exclamation-triangle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        }
        this.search_applied = true
        this.isLoading=false;

      })
          .catch(error => {
            this.errores_validacion = error.response.data
            this.isLoading=false;
          })
    },
    goToDocument(id){
      window.open('/documents/'+id)
    },
    goToUrl(url){
      window.open(url)
    },
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/view-document", {
            responseType: "blob",
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            if(res.data.type != "application/pdf"){
              this.$buefy.dialog.alert({
                message: "El formato del archivo no es compatible con el visualizador. Solo permite PDF.",
                type: "is-danger",
                hasIcon: true
              })
              this.isLoading = false
            }
            else{
              this.showPdf = true
              this.isLoading = false
            }
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    downloadFile(fileId){
      this.isLoading = true;

      if (fileId == null){
        this.isLoading = false;
        return;
      }

      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
          })
          .then(res => {
            console.log(res.data.type)
            let blob = new Blob([res.data], {
              type: res.data.type,
            })
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = res.headers["content-disposition"]
                .split(";")[1]
                .split('"')[1];
            link.click();
            this.isLoading=false
          })
          .catch(e => {
            console.log(e)
            this.isLoading=false
          })
    },
  },
  watch: {
  }
}
</script>