<template>
  <div class="container">
    <div class="box">
      <div class="columns is-centered">
        <div class="column is-narrow">
        </div>
        <div class="column is-narrow" v-if="years.length!==0" >
          <b-field>
            <b-select v-model="selectedYear">
              <option
                  v-for="year in years"
                  :value="year"
                  :key="year">
                {{ year }}
              </option>
            </b-select>
          </b-field>

        </div>
        <div class="column is-narrow">
          <b-button type="is-primary" @click="updateIndicators">Actualizar</b-button>
        </div>
      </div>

      <indicators-table v-bind:indicators="indicators"
                        v-bind:indicatorsNames="indicatorsNames"
                        v-bind:unitType="'DEPARTAMENTO'"
                        v-bind:unit-id="objectId"
                        @evolucionPorAnio="evolucionPorAnio"></indicators-table>
    </div>
    <b-modal aria-modal width="90%" v-model="isCardModalActive">
      <div class="modal-background"></div>
      <div class="modal-card" style="width:100%">
        <header class="modal-card-head is-centered has-text-centered has-background-primary">
          <div class="columns is-centered has-text-centered">
            <div class="column">
              <p class="modal-card-title has-text-white">Aporte al indicador por departamento</p>
            </div>
          </div>

        </header>
        <section id="vue-modal" class="modal-card-body">

        </section>
      </div>
    </b-modal>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>

</template>

<script>
import axios from "axios";
import IndicatorsTable from "../dashboard/indicators_table.vue";
export default {
  name: "indicators_tab",
  data(){
    return{
      isCardModalActive:false,
      indicators:[],
      indicatorsNames:[],
      unitType:"DEPARTAMENTO",
      selectedYear:new Date().getFullYear(),
      years:[],
      isLoading:false,


    }
  },
  components:{IndicatorsTable},
  created(){
    let vm = this;
    vm.years = vm.generateArrayOfYears();
    vm.isLoading = true;
    vm.updateIndicators();


  },
  props:[
      'objectId'
  ],
  methods:{
    evolucionPorAnio(data){
      //data = unit_id , indicator_code
      event.preventDefault();
      this.isCardModalActive = true
      $.ajax({
        url: "/dashboards/get_indicator_evolution.js",
        type: "get",
        data: data,
        success: function (res) {

        }
      })
    },
    updateIndicators() {
      let vm = this;
      vm.isLoading = true;
      axios
          .get("/get_unit_indicators.json",{params:{unit_id: vm.objectId, year: vm.selectedYear}})
          .then(res => {
            vm.indicators = res.data.indicators;
            vm.indicatorsNames = res.data.indicators_names;
            vm.isLoading=false;
          })
          .catch(e => {
            //console.log(e);
            vm.isLoading=false;
          });

    },
    generateArrayOfYears() {
      var max = new Date().getFullYear()
      var min = max - 12
      var years = []

      for (var i = max; i >= min; i--) {
        years.push(i)
      }
      return years
    },

  },
  computed:{

  }
}
</script>

<style scoped>

</style>