<template>
  <div>
    <b-table
        :data="indicatorsData"
        :striped="false"
        :narrowed="true"
        :hoverable="true"
        :mobile-cards="false"
        :loading="isLoading">

      <b-table-column width="10%" v-if="has_permission" v-slot="props">

        <b-dropdown aria-role="list">
          <button class="button is-primary is-small" slot="trigger" slot-scope="{ active }">
            <b-icon :icon="active ? 'caret-up' : 'caret-down'"></b-icon>
          </button>
          <b-dropdown-item v-if="unitType=='FACULTAD'" :year="props.row.year" :code="findIndicator(props.row.indicator_id).code"
                           v-on:click="aportePorDepartamento" aria-role="listitem">Aporte por departamento</b-dropdown-item>
          <b-dropdown-item aria-role="listitem" :code="findIndicator(props.row.indicator_id).code"
                           v-on:click="evolucionPorAnio">Evolución por año</b-dropdown-item>
          <b-dropdown-item aria-role="listitem" :id="props.row.indicator_id" :year="props.row.year"
                           v-on:click="indicatorComposition">Ver composición</b-dropdown-item>
          <b-dropdown-item v-show="props.row.can_show_type" aria-role="listitem" :id="props.row.indicator_id" :year="props.row.year" :code="findIndicator(props.row.indicator_id).code"
                            v-on:click="indicatorByType">Cantidad por tipo</b-dropdown-item>
        </b-dropdown>
      </b-table-column>

      <b-table-column width="10%" field="codigo" searchable label="Código">
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.codigo }}
        </template>
      </b-table-column>

      <b-table-column width="60%" field="nombre_indicador" searchable label="Nombre">
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.nombre_indicador }}
        </template>
      </b-table-column>

      <b-table-column width="20%" field="indicator_value" label="Valor" v-slot="props">
        {{ props.row.indicator_value }} {{ props.row.signo }}
      </b-table-column>

      <b-table-column field="updated_at" label="Última actualización" v-slot="props">
        {{ props.row.updated_at ? new Date( props.row.updated_at ).toLocaleString() : '' }}
      </b-table-column>

      <b-table-column field="code_description" label="Cálculo" v-slot="props">
        <b-button type="is-primary"
                  @click="activeCodeDescriptionModal(props.row)"
                  icon-right="eye" />
      </b-table-column>


    </b-table>

    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">

        <div class="card-content">

          <div class="content">
            <p class="title is-5">Cálculo del indicador: {{ this.activeIndicator.codigo }}
              - {{ this.activeIndicator.nombre_indicador }}</p>
            {{ this.activeIndicator.code_description }}

          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
name: "indicators_table",
  data(){
    return{
      isLoading: false,
      has_permission:true,
      indicatorsData:[],
      isCardModalActive:false,
      activeIndicator:{}

    }
  },
  created(){
    this.indicatorsData = this.indicators;
  },
  watch:{
    indicators(){
      this.indicatorsData = this.indicators;
    },
    unitId(){

    }
  },
  props:[
      'indicators','indicatorsNames','unitType','unitId'
  ],
  methods:{
    aportePorDepartamento(){
      let ano = parseInt(event.target.getAttribute("year"));
      let indicator_code = event.target.getAttribute("code");
      let myData = {unit_id: this.unitId, year: ano, indicator_code: indicator_code};
      this.$emit('aportePorDepartamento', myData);
    },
    evolucionPorAnio(){
      let indicator_code = event.target.getAttribute("code");
      let myData = {unit_id: this.unitId, indicator_code: indicator_code};
      this.$emit('evolucionPorAnio', myData);
    },
    indicatorComposition(){
      let indicator_id = event.target.getAttribute("id");
      let ano = parseInt(event.target.getAttribute("year"));
      let myData = {unit_id: this.unitId, indicator_id: indicator_id, year:ano};
      this.$emit('indicatorComposition', myData);
    },
    indicatorByType () {
      let indicator_id = event.target.getAttribute("id")
      let ano = parseInt(event.target.getAttribute("year"))
      let indicator_code = event.target.getAttribute("code")
      let myData = {unit_id: this.unitId, indicator_id: indicator_id, year:ano, indicator_code: indicator_code}
      this.$emit('indicatorByType', myData);
    },
    findIndicator(indicator_id){
      return this.indicatorsNames.find(o=>o.id===indicator_id)
    },
    activeCodeDescriptionModal(indicator){
      this.activeIndicator = indicator;
      this.isCardModalActive = true;
    }

  },
  computed:{

  }
}
</script>

<style scoped>

</style>