<template>
  <section>
    <!--<div class="block">-->
    <b-steps
        v-model="activeStep"
        :animated="isAnimated"
        :rounded="isRounded"
        :has-navigation="hasNavigation"
        :icon-prev="prevIcon"
        :icon-next="nextIcon"
        :label-position="labelPosition"
        :mobile-mode="mobileMode">
      <b-step-item step="1" label="Importación archivo de haberes" :clickable="isStepsClickable">
        <h1 class="title has-text-centered">Importación archivo de haberes</h1>
        <b-tabs position="is-centered" class="block">
          <b-tab-item label="Importación archivo de haberes" >
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(import_file)">
                <div class="box">
                  <div class="columns">
                    <div class="column is-centered">
                      <b-message type="is-info" has-icon v-if="importacion">
                        <b>Total de Haberes importados: </b> {{nombramientos.length+appointments_not_found.length}} de {{nombramientos.length+appointments_not_found.length+errores.length}}<br/>
                        <b>Haberes encontrados en SIGA: </b> {{nombramientos.length}} de {{nombramientos.length+appointments_not_found.length}}
                      </b-message>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                        <b-message type="is-danger" has-icon>
                          <div v-for="(item,index) in errores_validacion">
                            {{ item[0] }}
                          </div>
                        </b-message>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field grouped group-multiline>
                        <div class="control">
                          <h1 class="title is-5">Seleccionar mes, año y unidad de archivo haberes</h1>
                        </div>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <b-field label="Fecha archivo Haberes*">
                            <b-datepicker
                                type="month"
                                v-model="assets_date"
                                icon="calendar-alt"
                                trap-focus
                                locale="es-ES"
                                editable
                                placeholder="Formato de Fecha: dd/mm/aaaa">
                            </b-datepicker>
                          </b-field>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="Unidad de archivo haberes">
                        <multiselect v-model="unit"
                                     :options="units"
                                     placeholder="Seleccione unidad"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel="Seleccionado"
                                     deselectLabel="No se puede deseleccionar"
                                     :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                             No se encontraron elementos.
                          </span>
                        </multiselect>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field grouped group-multiline>
                        <div class="control">
                          <h1 class="title is-4">Seleccionar archivo de haberes</h1>
                          <h2 class="subtitle is-6 has-text-weight-bold">*El archivo debe tener la extensión .xlsx.</h2>
                        </div>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div >
                        <b-field class="file">
                          <b-upload v-model="file" expanded>
                            <a class="button is-primary is-outlined">
                              <b-icon icon="upload"></b-icon>
                              <span>{{ file.name || "Subir Archivo"}}</span>
                            </a>
                          </b-upload>
                        </b-field>
                      </div>
                    </div>
                    <div class="column">
                      <div class="has-text-right">
                        <b-button
                            label="Ver Ejemplo"
                            type="is-primary"
                            @click="isModalSpreadSheet = true" />
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="has-text-right">
                        <button class="button is-primary" :disabled="file.name.length===0" type="submit">Cargar Haberes</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </b-tab-item>
          <b-tab-item label="Errores y advertencias archivo de haberes" v-if="importacion">
            <div class="columns">
              <div class="column is-centered">
                <b-message type="is-info" has-icon>
                  <b>Errores:</b> Problemas en el archivo de importación que impiden completamente la contrastación de haberes.
                  <b>Advertencias:</b> Condiciones que impiden la contrastación de un haber, pero que no corresponden a errores de datos o de formato del archivo.
                </b-message>
              </div>
            </div>
            <div v-if="errores.length>0" class="columns is-centered">
              <div class="column is-11">
                <div class="box">
                  <h1 class="is-size-5">
                    <b-icon
                        pack="fas"
                        icon="exclamation-triangle">
                    </b-icon>
                    Errores del archivo: {{ this.file_name_errors }}
                  </h1>
                  <div v-if="import_type != 'Convenios'" class="ml-5" v-for="error in errores">
                    Fila n° {{error.FILA}}: {{ error.ERRORES }}
                  </div>
                  <div v-if="import_type == 'Convenios'" class="ml-5" v-for="error in errores">
                    Hoja:{{error.PAGINA}} -> Fila n° {{error.FILA}}: {{ error.ERRORES }}
                  </div>
                </div>
              </div>
            </div>
            <div class="columns has-text-right">
              <div class="column" v-if="errores.length>0">
                <b-button @click="importErrors" v-if="errores.length!=0" icon-left="file-excel" class="is-excel-color">Exportar errores en excel</b-button>
              </div>
            </div>
            <div v-if="advertencias.length>0" class="columns is-centered">
              <div class="column is-11">
                <div class="box">
                  <h1 class="is-size-5">
                    <b-icon
                        pack="fas"
                        icon="exclamation-triangle">
                    </b-icon>
                    Advertencias del archivo: {{ this.file_name_errors }}
                  </h1>
                  <div v-if="import_type != 'Convenios'" class="ml-5" v-for="advertencia in advertencias">
                    Fila n° {{advertencia.FILA}}: {{ advertencia.ERRORES }}
                  </div>
                  <div v-if="import_type == 'Convenios'" class="ml-5" v-for="advertencia in advertencias">
                    Hoja:{{advertencia.PAGINA}} -> Fila n° {{advertencia.FILA}}: {{ advertencia.ERRORES }}
                  </div>
                </div>
              </div>
            </div>
          </b-tab-item>
        </b-tabs>
      </b-step-item>
      <b-step-item step="2" label="Haberes Contrastados" :clickable="isStepsClickable" v-if="importacion">
        <h1 class="title has-text-centered">Haberes Contrastados</h1>
        <b-tabs position="is-centered" class="block" v-if="importacion">
          <b-tab-item label="Haberes encontrados en SIGA">
            <div class="box">
              <div class="columns">
                <div class="column is-centered">
                  <b-message type="is-info" has-icon v-model="importacion">
                    <b>Haberes encontrados en SIGA: </b> {{nombramientos.length}} de {{nombramientos.length+appointments_not_found.length}}
                  </b-message>
                </div>
              </div>
                  <b-table
                      :data="nombramientos"
                      :paginated= true
                      per-page="20"
                      striped
                      hoverable
                      :narrowed=true
                      :current-page="currentPage"
                      :pagination-simple=false
                      pagination-position="both"
                      default-sort-direction="asc"
                      sort-icon="arrow-up"
                      sort-icon-size="is-small"
                      default-sort="product.title"
                      aria-next-label="Siguiente"
                      aria-previous-label="Anterior"
                      aria-page-label="Página"
                      aria-current-label="Página actual"
                      detailed
                      detail-key="id"
                      detail-transition="fade"
                      :show-detail-icon="true">
                    <b-table-column
                        field="index"
                        label="N°">
                      <template v-slot="props">
                        {{ nombramientos.indexOf(props.row) + 1 }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="person_rut"
                        label="Rut"
                        sortable
                        searchable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.person_rut}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="person_name"
                        label="Nombre"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.person_name}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="unit.cost_center"
                        label="CC Contratación"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.unit!==undefined ? props.row.unit.cost_center : ""}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="establishment.name"
                        label="Estamento"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.establishment!==undefined ? props.row.establishment.name : ""}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="taken_hours"
                        label="Horas"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.taken_hours}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="start_date"
                        label="Fecha Desde"
                        width="110"
                        sortable
                        searchable :custom-search="searchStart">
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL") : ''}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="end_date"
                        label="Fecha Hasta"
                        width="110"
                        sortable
                        searchable :custom-search="searchEndDate">
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL") : ''}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        label="Acciones"
                        width="40"
                        v-slot="props">
                      <b-icon
                          pack="fas"
                          icon="eye"
                          size="is-medium"
                          @click.native="execShowModal(props.row, nombramientos)"/>
                    </b-table-column>

                    <template slot="empty" slot-scope="props">
                      <div class="columns is-centered">
                        <div class="column is-6 is-centered has-text-centered">
                          <b-message type="is-danger">
                            No se encontraron nombramientos.
                          </b-message>
                        </div>
                      </div>
                    </template>

                    <template #detail="props">
                      <article class="media">
                        <div class="media-content">
                          <div class="content">
                            <div class="columns">
                              <div class="column is-2">
                                <strong>Asignaturas: </strong>
                              </div>
                              <div class="column">
                                <p>{{props.row.subjects}}</p>
                              </div>
                            </div>

                          </div>
                        </div>
                      </article>
                    </template>

                  </b-table>
            </div>
          </b-tab-item>

          <b-tab-item label="Haberes no encontrados en SIGA">
            <div class="box">
              <div class="columns">
                <div class="column is-centered">
                  <b-message type="is-info" has-icon v-model="importacion">
                    <b>Haberes no encontrados en SIGA: </b> {{appointments_not_found.length}} de {{nombramientos.length+appointments_not_found.length}}
                  </b-message>
                </div>
              </div>
              <b-field grouped group-multiline>
                <div class="control">
                  <b-switch v-model="hideResolution" @input="updateAssets">Ocultar 'Resolución'
                    <b-tooltip
                        label="Se ocultará el motivo 'Resolución'"
                        multilined
                        position="is-left">
                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          type="is-info">
                      </b-icon>
                    </b-tooltip></b-switch>
                </div>
              </b-field>
              <b-table
                  :data="appointments_not_found"
                  :paginated= true
                  per-page="20"
                  striped
                  hoverable
                  :narrowed=true
                  :current-page="currentPage"
                  :pagination-simple=false
                  pagination-position="both"
                  default-sort-direction="asc"
                  sort-icon="arrow-up"
                  sort-icon-size="is-small"
                  default-sort="product.title"
                  aria-next-label="Siguiente"
                  aria-previous-label="Anterior"
                  aria-page-label="Página"
                  aria-current-label="Página actual">
                <b-table-column
                    field="index"
                    label="N°">
                  <template v-slot="props">
                    {{ appointments_not_found.indexOf(props.row) + 1 }}
                  </template>
                </b-table-column>
                <b-table-column
                    field="person_rut"
                    label="Rut"
                    sortable
                    searchable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.person_rut}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="person_name"
                    label="Nombre"
                    searchable
                    sortable
                >
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.person_name}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="unit.cost_center"
                    label="CC"
                    searchable
                    sortable
                >
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.unit!==undefined ? props.row.unit.cost_center : ""}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="resolution"
                    label="N°"
                    searchable
                    sortable
                >
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.resolution}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="establishment.name"
                    label="Estamento"
                    searchable
                    sortable
                >
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.establishment!==undefined ? props.row.establishment.name : ""}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="hier_cat_name"
                    label="Jerarquía/Categoría"
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.hier_cat_name}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="quality"
                    label="Calidad"
                    searchable
                    sortable
                >
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.quality}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="work_plant.name"
                    label="Cargo"
                    searchable
                    sortable
                >
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.work_plant!==undefined ? props.row.work_plant.name : ""}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="taken_hours"
                    label="Horas"
                    searchable
                    sortable
                >
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.taken_hours}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="short_reason"
                    label="Motivo"
                    searchable
                    sortable
                    v-if="!hideResolution"
                >
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.short_reason}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="filtered_short_reason"
                    label="Motivo"
                    searchable
                    sortable
                    v-if="hideResolution"
                >
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.filtered_short_reason}}
                  </template>
                </b-table-column>
                <b-table-column
                    label="Acciones"
                    width="40"
                    v-slot="props">
                  <b-icon
                      pack="fas"
                      icon="eye"
                      size="is-medium"
                      @click.native="execSimpleModal(props.row)"/>
                  <b-icon
                    pack="fas"
                    icon="user"
                    size="is-medium"
                    @click.native="goToLifeRecord(props.row)"/>
                </b-table-column>
                <template slot="empty" slot-scope="props">
                  <div class="columns is-centered">
                    <div class="column is-6 is-centered has-text-centered">
                      <b-message type="is-danger">
                        No se encontraron nombramientos.
                      </b-message>
                    </div>
                  </div>
                </template>

              </b-table>
              <div class="box">
                <div class="columns has-text-right">
                  <div class="column" v-if="appointments_not_found.length>0">
                    <b-button @click="importNotFound(appointments_not_found, false)" v-if="appointments_not_found.length!=0" icon-left="file-excel" class="is-excel-color">Exportar no encontrados en excel</b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Nombramientos no encontrados en haberes">
            <div class="box">
              <div class="columns">
                <div class="column is-centered">
                  <b-message type="is-info" has-icon v-model="importacion">
                    <b>*Por unidad de contratación</b><br/>
                    <b>En esta pestaña se listan nombramientos SIGA que coincidan con la fecha del archivo de haberes, pero que no se encuentra información de estos en el archivo.</b> <br/>
                    <b>Nombramientos SIGA no encontrados (Por unidad de contratación): </b> {{contracting_unit_not_found.length}}
                  </b-message>
                </div>
              </div>
              <b-table
                  :data="contracting_unit_not_found"
                  :paginated= true
                  per-page="20"
                  striped
                  hoverable
                  :narrowed=true
                  :current-page="currentPage"
                  :pagination-simple=false
                  pagination-position="both"
                  default-sort-direction="asc"
                  sort-icon="arrow-up"
                  sort-icon-size="is-small"
                  default-sort="product.title"
                  aria-next-label="Siguiente"
                  aria-previous-label="Anterior"
                  aria-page-label="Página"
                  aria-current-label="Página actual"
                  detailed
                  detail-key="id"
                  detail-transition="fade"
                  :show-detail-icon="true">
                <b-table-column
                    field="index"
                    label="N°">
                  <template v-slot="props">
                    {{ contracting_unit_not_found.indexOf(props.row) + 1 }}
                  </template>
                </b-table-column>
                <b-table-column
                    field="person_rut"
                    label="Rut"
                    sortable
                    searchable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.person_rut}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="person_name"
                    label="Nombre"
                    searchable
                    sortable
                >
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.person_name}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="unit.cost_center"
                    label="CC Contratación"
                    searchable
                    sortable
                >
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.unit!==undefined ? props.row.unit.cost_center : ""}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="establishment.name"
                    label="Estamento"
                    searchable
                    sortable
                >
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.establishment!==undefined ? props.row.establishment.name : ""}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="hours"
                    label="Horas"
                    searchable
                    sortable
                >
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.hours}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="start_date"
                    label="Fecha Desde"
                    width="110"
                    sortable
                    searchable :custom-search="searchStart">
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL") : ''}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="end_date"
                    label="Fecha Hasta"
                    width="110"
                    sortable
                    searchable :custom-search="searchEndDate">
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL") : ''}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="short_reason"
                    label="Motivo"
                    width="110"
                    sortable
                    searchable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.short_reason}}
                  </template>
                </b-table-column>
                <b-table-column
                    label="Acciones"
                    width="40"
                    v-slot="props">
                  <b-icon
                      pack="fas"
                      icon="eye"
                      size="is-medium"
                      @click.native="execShowModal(props.row, contracting_unit_not_found)"/>
                </b-table-column>

                <template slot="empty" slot-scope="props">
                  <div class="columns is-centered">
                    <div class="column is-6 is-centered has-text-centered">
                      <b-message type="is-danger">
                        No se encontraron nombramientos.
                      </b-message>
                    </div>
                  </div>
                </template>

                <template #detail="props">
                  <article class="media">
                    <div class="media-content">
                      <div class="content">
                        <div class="columns">
                          <div class="column is-2">
                            <strong>Asignaturas: </strong>
                          </div>
                          <div class="column">
                            <p>{{props.row.subjects}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </template>

              </b-table>
              <div class="box">
                <div class="columns has-text-right">
                  <div class="column" v-if="contracting_unit_not_found.length>0">
                    <b-button @click="importNotFound(contracting_unit_not_found, true)" v-if="contracting_unit_not_found.length!=0" icon-left="file-excel" class="is-excel-color">Exportar no encontrados en excel</b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-tab-item>

        </b-tabs>
      </b-step-item>
    </b-steps>
    <!--</div>-->

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="isModalSpreadSheet"
        has-modal-card
        trap-focus
        full-screen
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal-spreadsheet-assets
            modal-header="Cabeceras y Ejemplo"
            @close="props.close">
        </modal-spreadsheet-assets>
      </template>
    </b-modal>
    <b-modal
        v-model="isShowModalActive"
        has-modal-card
        trap-focus
        width="1024"
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <component  :is="showName"
                    :id="selected"
                    :person-id="personId"
                    :modal-header="title"
                    :person_name="person_name"
                    @close="props.close"/>
      </template>
    </b-modal>
    <b-modal
        v-model="showSimpleModal"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <simple-modal
            modal-header="Datos importados de archivo haberes"
            :nombramiento="asset_to_show"
            @close="props.close">
        </simple-modal>
      </template>
    </b-modal>


  </section>
</template>
<script>
import axios from "axios"
import moment from "moment";
import {searchDate, formatDate} from "../../../packs/utilities";
import ModalSpreadsheetAssets from "../../life_record/spreadsheet/spreadsheet_assets"
import AppointmentHours from "../appointment_hours";
import AppointmentAcademic from "../appointment_academic";
import AppointmentAdministrative from "../appointment_administrative";
import AppointmentFeeContract from "../appointment_feecontract";
import SimpleModal from "./simple_modal"


export default {
  name: "CompareAssets",
  components:{
    ModalSpreadsheetAssets,
    AppointmentHours,
    AppointmentAcademic,
    AppointmentAdministrative,
    AppointmentFeeContract,
    SimpleModal
  },
  data(){
    return{
      import_type:"",
      assets_date: null,
      person_unit: {},
      currentPage: 1,
      file:{name:""},
      dropFiles: [],
      isLoading:false,
      errores:[],
      advertencias: [],
      file_name_errors:"",
      errores_validacion: {},
      cabeceras:[],
      hojas:[],
      errorFile:{name:""},
      importacion: false,
      nombramientos: [],
      no_encontrados: [],
      appointments_not_found: [],
      disable_import: false,
      isModalSpreadSheet: false,
      activeStep: 0,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      hasNavigation: true,
      customNavigation: false,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      postImport: false,
      selected: null,
      isShowModalActive: false,
      showName: "",
      title: "",
      person_name: "",
      showSimpleModal: false,
      asset_to_show: null,
      contracting_unit_not_found: [],
      siga_total_contracting_unit: null,
      units: [],
      unit: null,
      hideResolution: false,
      all_appointments_not_found: []
    }
  },
  created(){
    this.initialize()
  },
  props:[
    "personId"
  ],
  methods:{
    updateAssets(){
      this.isLoading = true
      if(this.hideResolution){
        this.all_appointments_not_found = this.appointments_not_found
        this.appointments_not_found = this.appointments_not_found.filter(nombramiento => nombramiento.filtered_short_reason !== " ");
      }
      else{
        this.appointments_not_found = this.all_appointments_not_found
      }
      this.isLoading = false
    },
    getEstablishment(selected, nombramientos){
      return nombramientos.find(o => o.id === selected);
    },
    goToLifeRecord(row){
      window.open('/people/'+row.person_id+'/life_record')
    },
    execSimpleModal(row){
      this.isLoading = true
      this.asset_to_show = row
      this.showSimpleModal = true
      this.isLoading = false
    },
    execShowModal(row, appointments){
      this.selected = row.id;
      let person_unit = this.getEstablishment(row.id, appointments)
      this.person_name = row.person_name
      if(person_unit.establishment!== undefined){
        if(person_unit.establishment.name==="ACADEMICOS") {
          this.showName = "AppointmentAcademic"
          this.title = "Nombramiento Estamento Académico"+" - "+row.person_name
        }
        else if (person_unit.establishment.name==="ADMINISTRATIVOS") {
          this.showName = "AppointmentAdministrative"
          this.title = "Nombramiento Estamento Administrativo"+" - "+row.person_name
        }
        else if (person_unit.establishment.name==="HONORARIOS") {
          this.showName = "AppointmentFeeContract"
          this.title = "Nombramiento Estamento Honorarios"+" - "+row.person_name
        }
        else {
          this.showName = "AppointmentHours"
          this.title = "Nombramiento Estamento Profesor Horas Clases"+" - "+row.person_name
        }
      }
      else{
        this.showName = "AppointmentAdministrative"
      }
      this.isShowModalActive = true;
    },
    initialize(){
      this.isLoading = true
      this.person_unit.person_id = this.personId
      axios
          .get("/life_record/compare_assets.json")
          .then(res => {
            this.units = res.data["unit_names"]
            this.unit = res.data["unit"]
            this.isLoading = false
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false
          })
    },
    selectAll(rows) {
      if (rows.length > this.checkedRows.length) {
        this.$nextTick(() => { this.checkedRows = this.nombramientos})
      } else {
        this.$nextTick(() => {
          this.checkedRows = []
        })
      }
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    import_file(){
      this.isLoading = true;
      this.file_name_errors = "";
      this.errores = [];
      this.no_encontrados = [];
      this.appointments_not_found = [];
      this.cabeceras = [];
      this.nombramientos = []
      this.working_unit_not_found = []
      this.contracting_unit_not_found = []
      this.all_appointments_not_found = []
      this.hideResolution = false
      this.errores_validacion = {}
      this.advertencias = []
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('assets_date', this.assets_date)
      formData.append('unit', this.unit)
      this.postImport = false
      this.errorFile = this.file;
      axios.post("/life_record/import_assets.json",
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(response =>{
        this.isLoading = true;
        this.errores_validacion = {}
        this.advertencias = response.data["advertencias"]
        if(response.data["invalid"]){
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores en la importación de su archivo. Puede ser un archivo no válido o presenta errores de formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading = false;
          return
        }
        if(response.data["errores"].length>0){
          this.errores = response.data["errores"]
        }
        else{
          this.errores = []
        }
        this.no_encontrados = response.data["no_encontrados"]
        this.cabeceras = response.data["cabeceras"];
        this.nombramientos = response.data["nombramientos"]
        this.contracting_unit_not_found = response.data["contracting_unit_not_found"]
        this.siga_total_contracting_unit = response.data["siga_total_contracting_unit"]
        this.appointments_not_found = response.data["appointments_not_found"]
        this.nombramientos = this.nombramientos.map(personUnit => {
          var newPersonUnit = personUnit
          newPersonUnit["start_date"] = moment(newPersonUnit["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
          newPersonUnit["end_date"] = moment(newPersonUnit["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
          return newPersonUnit;
        });
        this.contracting_unit_not_found = this.contracting_unit_not_found.map(personUnit => {
          var newPersonUnit = personUnit
          newPersonUnit["start_date"] = moment(newPersonUnit["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
          newPersonUnit["end_date"] = moment(newPersonUnit["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
          return newPersonUnit;
        });
        this.importacion = true;
        this.disable_import = true;
        this.file_name_errors = this.file.name;
        if (response.data["errores"].length>0 || response.data["no_encontrados"].length>0){
          this.$buefy.dialog.alert({
            message: 'Algunos datos del archivo "'+this.file.name+'" no han podido ser importados, por favor, revisar errores',
            confirmText: 'Ok',
            type: 'is-warning',
            hasIcon: true,
            icon: 'exclamation-triangle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        }
        else{
          this.$buefy.dialog.alert({
            message: 'Su archivo "'+this.file.name+'" ha sido importado con éxito',
            confirmText: 'Ok'
          })
          this.file={name:""};
          this.errores = [];
          this.file_name_errors="";
        }
        this.isLoading = false;
      })
          .catch(error => {
            //console.log('FAILURE!!');
            this.errores_validacion = error.response.data
            this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Hubo errores en la importación de su archivo. Puede ser un archivo no válido o presenta errores de formato',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.isLoading = false;
          });
    },
    searchEndDate(row,input){
      return searchDate(row.end_date,input)
    },
    searchResolution(row,input){
      return searchDate(row.new_res_date,input)
    },
    searchStart(row,input){
      return searchDate(row.start_date,input)
    },
    importErrors(){
      let vm = this;
      vm.isLoading=true;
      axios
          .post("/life_record/export_errors_assets.xlsx",{cabeceras:vm.cabeceras,errores: vm.errores,hojas:vm.hojas},
              {
                responseType: "blob"
              })
          .then(response =>{
            vm.isLoading=false;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Errores contrastación de haberes '+vm.errorFile.name.replace(".xlsx","")+' '+new Date().toLocaleDateString()+'.xlsx');
            document.body.appendChild(link);
            link.click();
          }).catch(error => {
        vm.isLoading=false;
        vm.$buefy.notification.open({
          message: 'Hubo un error en la solicitud',
          type: 'is-danger'
        })
      })
    },
    importNotFound(data, inverse){
      let vm = this;
      vm.isLoading=true;
      axios
          .post("/life_record/export_not_found_assets.xlsx",{errores: data,hojas:vm.hojas, inverse_comparision: inverse},
              {
                responseType: "blob"
              })
          .then(response =>{
            vm.isLoading=false;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Nombramientos - haberes no encontrados '+vm.errorFile.name.replace(".xlsx","")+' '+new Date().toLocaleDateString()+'.xlsx');
            document.body.appendChild(link);
            link.click();
          }).catch(error => {
        vm.isLoading=false;
        vm.$buefy.notification.open({
          message: 'Hubo un error en la solicitud',
          type: 'is-danger'
        })
      })
    }

  },
  computed:{

  }
}
</script>
