<template>
  <section>
      <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(saveNewSubjects)">
              <div class="modal-card" style="width: auto">
                  <header class="modal-card-head">
                      <p class="modal-card-title">{{person_name}} - Editar datos</p>
                      <button
                              type="button"
                              class="delete"
                              @click="$emit('close')"/>
                  </header>
                  <section class="modal-card-body">
                      <div class="columns">
                          <div class="column">
                              <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                                  <b-message type="is-danger" has-icon>
                                      <div v-for="(item,index) in errores_validacion">
                                          {{ item[0] }}
                                      </div>
                                  </b-message>
                              </div>
                          </div>
                      </div>
                      <div class="columns">
                          <div class="column">
                              <b-field label="Categoría*">
                                  <ValidationProvider rules="required" v-slot="{errors}">
                                      <multiselect v-model="new_category_id"
                                                   :options="categories.map(type => type.id)"
                                                   :custom-label="opt => categories.find(x => x.id === opt).name"
                                                   placeholder="Seleccione una categoría"
                                                   selectLabel="Presione para seleccionar"
                                                   selectedLabel="Seleccionado"
                                                   deselectLabel="No se puede deseleccionar"
                                                   :allow-empty="false">
                                          <template v-slot:noOptions>
                                              No existen datos
                                          </template>
                                          <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                                      </multiselect>
                                      <span class="validation-alert">{{ errors[0] }}</span>
                                  </ValidationProvider>
                              </b-field>
                          </div>
                      </div>

                      <div class="container">
                          <section>
                              <div class="columns">
                                  <div class="column">
                                      <b-field>
                                          <template #label>
                                              Asignaturas
                                          </template>
                                      </b-field>
                                  </div>
                                  <div class="column" align="right" style="text-align:right;">
                                      <b-button class="mt-2" @click="addHrSubject" type="is-primary">Añadir Asignatura</b-button>
                                  </div>
                              </div>
                              <b-table
                                      :data="new_selected_hr_subjects"
                                      :paginated=true
                                      :bordered="true"
                                      per-page=3
                                      striped
                                      hoverable
                                      :current-page="currentPage"
                                      :pagination-simple=false
                                      pagination-position="bottom"
                                      default-sort-direction="asc"
                                      sort-icon="arrow-up"
                                      sort-icon-size="is-small"
                                      default-sort="publication.title"
                                      aria-next-label="Siguiente"
                                      aria-previous-label="Anterior"
                                      aria-page-label="Página"
                                      aria-current-label="Página actual">
                                  <b-table-column
                                          field= 'name'
                                          width="30em"
                                          sortable
                                  >
                                      <template v-slot:header="{ column }">
                                          <b-tooltip
                                                  label="Presione Enter luego de escribir el nombre de la asignatura para que sea agregada"
                                                  position="is-right"
                                                  size="is-medium"
                                                  multilined>
                                              {{"Nombre de la asignatura*" }}
                                              <b-icon
                                                      pack="fas"
                                                      icon="info-circle"
                                                      size="is-small"
                                                      type="is-info">
                                              </b-icon>
                                          </b-tooltip>
                                      </template>
                                      <template v-slot="props">
                                          <b-field>
                                              <ValidationProvider rules="required" v-slot="{ errors }">
                                                  <b-taginput
                                                          v-model="props.row.subject"
                                                          :data="filtered_hr_subjects"
                                                          type="is-primary is-light"
                                                          maxtags="1"
                                                          autocomplete
                                                          maxlength="155"
                                                          :allow-new="true"
                                                          field="name"
                                                          icon="tag"
                                                          placeholder="Agregar o escoger asignaturas"
                                                          @input = "getPsCode(props.row)"
                                                          @typing="getFilteredHRSubjects">
                                                  </b-taginput>
                                                  <span class="validation-alert">{{ errors[0] }}</span>
                                              </ValidationProvider>
                                          </b-field>
                                      </template>
                                  </b-table-column>
                                  <b-table-column
                                          field= 'hours'
                                          label= 'Horas de Asignatura*'
                                          sortable
                                          v-slot="props"
                                  >
                                      <b-field>
                                          <ValidationProvider rules="required" v-slot="{errors}">
                                              <b-input v-model="props.row.hours" type="text" min="0" @input="updateHours"></b-input>
                                              <span class="validation-alert">{{ errors[0] }}</span>
                                          </ValidationProvider>

                                      </b-field>
                                  </b-table-column>
                                  <b-table-column
                                          field= 'ps_code'
                                          label= 'Código PeopleSoft'
                                          sortable
                                          v-slot="props"
                                  >
                                      <b-field>
                                          <ValidationProvider v-slot="{errors}">
                                              <b-input minlength="0" maxlength="155" v-model="props.row.ps_code" type="text" :disabled="props.row.ps_code_disabled"></b-input>
                                              <span class="validation-alert">{{ errors[0] }}</span>
                                          </ValidationProvider>
                                      </b-field>
                                  </b-table-column>
                                  <b-table-column
                                          field= 'siac_code'
                                          label= 'Código SIAC'
                                          sortable
                                          v-slot="props"
                                  >
                                      <b-field>
                                          <ValidationProvider v-slot="{errors}">
                                              <b-input minlength="0" maxlength="155" v-model="props.row.siac_code" type="text" :disabled="props.row.siac_code_disabled"></b-input>
                                              <span class="validation-alert">{{ errors[0] }}</span>
                                          </ValidationProvider>
                                      </b-field>
                                  </b-table-column>
                                  <b-table-column
                                          label="Acciones"
                                          v-slot="props">
                                      <b-icon
                                              pack="fas"
                                              style="color: #e50000;"
                                              icon="trash"
                                              size="is-medium"
                                              @click.native="deleteHrSubject(props.row)"
                                              />
                                  </b-table-column>
                              </b-table>
                          </section>
                      </div>
                      <div class="columns">
                          <div class="column">
                              <b-field>
                                  <template #label>
                                      Jornada (horas)
                                      <b-tooltip label="Calculado automaticamente en base a la suma de las horas por asignatura"
                                                 position="is-right">
                                          <b-icon
                                              pack="fas"
                                              icon="info-circle"
                                              type="is-info">
                                          </b-icon>
                                      </b-tooltip>
                                  </template>
                                  <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                                      <b-input v-model="taken_hours" type="number" :disabled="true"></b-input>
                                      <span class="validation-alert">{{ errors[0] }}</span>
                                  </ValidationProvider>
                              </b-field>
                          </div>
                        <div class="column">
                          <b-field>
                            <template #label>
                              Jornada periodo anterior (Horas)
                            </template>
                            <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                              <b-input v-model="total_hours" type="number" :disabled="true"></b-input>
                              <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>
                      </div>
                  </section>
              </div>
              <footer class="modal-card-foot actions">
                  <div class="field is-grouped is-pulled-right">
                      <button class="button" type="button" @click="$emit('close')">Cerrar</button>
                      <button class="button is-primary" type="submit">Aceptar</button>
                  </div>
              </footer>
          </form>
      </ValidationObserver>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>

import axios from "axios";

export default {
    name: "modal_modify_subjec.vue",
    props:["categories","category_id","temp_selected_hr_subjects","total_hours","person_name"],
    created() {
      this.isLoading = true
        var temp_subjects = []
        this.temp_selected_hr_subjects.forEach(subject => {
          var hours= subject.hours
          var id = subject.id
          var index= subject.index
          var name = subject.name
          var ps_code= subject.ps_code
          var ps_code_disabled = subject.ps_code_disabled
          var siac_code = subject.siac_code
          var siac_code_disabled = subject.siac_code_disabled
          var subject = subject.subject
          temp_subjects.push({
            hours: hours,
            id : id,
            index: index,
            name : name,
            ps_code:ps_code,
            ps_code_disabled : ps_code_disabled,
            siac_code : siac_code,
            siac_code_disabled : siac_code_disabled,
            subject : subject
          })
        })
        this.new_selected_hr_subjects = temp_subjects
        this.new_category_id = this.category_id
        this.num_hr_subjects = this.temp_selected_hr_subjects.length
      axios
          .get("/hr_subjects.json")
          .then(res =>{
              this.hr_subjects = res.data
              this.updateHours()
              this.isLoading = false
          })
          .catch( err =>{
              console.log(err)
              this.isLoading = false
          })
    },
    data(){
        return{
            errores_validacion:{},
            hr_subject_attributes: [],
            hr_subjects: [],
            filtered_hr_subjects: [],
            currentPage:1,
            isLoading:false,
            taken_hours:null,
            new_selected_hr_subjects:[],
            new_category_id: null,
            num_hr_subjects: 0,
        }
    },
    methods:{
        addHrSubject(){
            this.new_selected_hr_subjects.push({
                index:  this.num_hr_subjects,
                name: null,
                id: null,
                substracted_hours: null,
                ps_code: null,
                hours: null,
                _destroy: null,
                ps_code_disabled: false,
                siac_code_disabled: false,
                siac_code: null
            })
            this.num_hr_subjects =  this.num_hr_subjects+1
        },
        deleteHrSubject(hr_subject){
            this.new_selected_hr_subjects = this.new_selected_hr_subjects.filter(subject => subject.index != hr_subject.index);
            this.num_hr_subjects = this.num_hr_subjects - 1
            this.updateHours()
        },
        updateHours(){
            var hours = 0
            for(var i = 0;i<this.new_selected_hr_subjects.length;i++){
                hours = hours + parseInt(this.new_selected_hr_subjects[i].hours)
            }
            this.taken_hours = parseInt(hours)

            if(this.taken_hours > this.total_hours){
              this.$buefy.dialog.alert({
                title: 'Precaución',
                message: 'Las horas actuales no deben superar las horas del periodo anterior. Para esos casos realizar solicitud de aumento de horas.',
                type: 'is-warning',
                hasIcon: true,
                icon: 'exclamation-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
            }

        },
        getPsCode(row){
            var newIndex = this.new_selected_hr_subjects.findIndex(subject => subject.index ===row.index);
            if(row.subject[0] === undefined){
                this.new_selected_hr_subjects[newIndex].name = null
                this.new_selected_hr_subjects[newIndex].id = null
                this.new_selected_hr_subjects[newIndex].ps_code = null
                this.new_selected_hr_subjects[newIndex].hours = null
                this.new_selected_hr_subjects[newIndex].ps_code_disabled = false
                this.new_selected_hr_subjects[newIndex].siac_code = null
                this.new_selected_hr_subjects[newIndex].siac_code_disabled = false
            }
            else if(row.subject[0].id && row.subject[0].ps_code){
                this.new_selected_hr_subjects[newIndex].name = row.subject[0].name
                this.new_selected_hr_subjects[newIndex].id = row.subject[0].id
                this.new_selected_hr_subjects[newIndex].ps_code = row.subject[0].ps_code
                this.new_selected_hr_subjects[newIndex].ps_code_disabled = false
                this.new_selected_hr_subjects[newIndex].siac_code = row.subject[0].siac_code
                this.new_selected_hr_subjects[newIndex].siac_code_disabled = false
            }
            else if(row.subject[0].id ===undefined && row.subject[0].ps_code === undefined){
                this.new_selected_hr_subjects[newIndex].name = row.subject[0]
            }
            else if(row.subject[0].id && (row.subject[0].ps_code===undefined || row.subject[0].ps_code===null)){
                this.new_selected_hr_subjects[newIndex].name = row.subject[0].name
                this.new_selected_hr_subjects[newIndex].id = row.subject[0].id
                this.new_selected_hr_subjects[newIndex].ps_code = row.subject[0].ps_code
                this.new_selected_hr_subjects[newIndex].ps_code_disabled = false
                this.new_selected_hr_subjects[newIndex].siac_code = row.subject[0].siac_code
                this.new_selected_hr_subjects[newIndex].siac_code_disabled = false
            }
        },
        getFilteredHRSubjects(text) {
            this.filtered_hr_subjects = this.hr_subjects.filter((option) => {
                return option.name
                    .toString()
                    .toLowerCase()
                    .indexOf(text.toLowerCase()) >= 0
            })
        },
        saveNewSubjects(){
            String.prototype.toProperCase = function () {
                return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
            };
            var list_subjects = []
            this.new_selected_hr_subjects.forEach(function (element){
                if(element.siac_code != null && element.siac_code != '' && element.siac_code != undefined){
                    list_subjects.push(element.name.toProperCase()+"-"+element.hours.toString()+"-"+element.siac_code)
                }
                else{
                    list_subjects.push(element.name.toProperCase()+"-"+element.hours.toString())
                }

            })
            const value = {
                new_selected_hr_subjects: this.new_selected_hr_subjects,
                subjects: list_subjects,
                category_id: this.new_category_id,
                category: this.categories.find(x => x.id === this.new_category_id).name,
                total_hours: this.taken_hours
            }

            if(this.new_selected_hr_subjects.length < 1){
              this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Debe ingresar al menos una asignatura.',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
            }
            else{
              this.$emit('clicked', value)
              this.$emit('close')
            }


        }
    }
}
</script>

<style scoped>

</style>