<template>
    <div class="agreementgeneralinformation">

        <div class="columns is-centered">
          <div class="column">
            <div class="has-text-right">
              <b-button v-if="can_edit" type="is-primary"
                        icon-left="edit"
                        @click="editAgreement">
                Editar Convenio
              </b-button>
              <b-button v-if="is_null != true" type="is-danger"
                        icon-left="ban"
                        @click="execNullifyModal">
                Anular Convenio
              </b-button>
              <button v-if="is_null != true" class="button is-info" @click="downloadReport()">
                        <span class="icon is-small">
                            <i class="fas fa-file-pdf"></i>
                        </span>
                <span> PDF ficha de reporte</span>
              </button>
            </div>
            <hr class="dropdown-divider" aria-role="menuitem">
            <div class="columns">
              <div class="column">
                <b-field label="Nombre del convenio">
                  <b-input type="text" readonly v-model="agreement.name">
                  </b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Usuario del convenio">
                  <b-input type="text" readonly v-model="agreement.unit_name">
                  </b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">

                <b-field label="Entidad/es del convenio">
                  <b-table
                      :data="entities"
                      per-page=3
                      :current-page="currentPage"
                      :card-layout="cardLayout">
                    <b-table-column
                        field= 'partner_entity_data.name'>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        <a @click="goToEntity(props.row.partner_entity_data.id)"><i class="fas fa-industry"></i> {{ props.row.entity_name }}</a>
                      </template>
                    </b-table-column>
                  </b-table>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Fecha de inicio">
                  <b-input type="text" readonly v-model="agreement.start_date">
                  </b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de término">
                  <b-input type="text" readonly v-model="agreement.end_date">
                  </b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="¿Es indefinido?">
                  <b-radio-button v-model="agreement.disabled_date"
                                  :native-value="false"
                                  :disabled="true"
                                  type="is-danger is-light is-outlined">
                    <b-icon icon="times-circle"></b-icon>
                    <span>No</span>
                  </b-radio-button>
                  <b-radio-button v-model="agreement.disabled_date"
                                  :native-value="true"
                                  :disabled="true"
                                  type="is-success is-light is-outlined">
                    <b-icon icon="check-circle"></b-icon>
                    <span>Si</span>
                  </b-radio-button>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="N° de resolución">
                  <b-input type="text" readonly v-model="agreement.resolution_number"></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de resolución">
                  <b-input type="text" readonly v-model="agreement.creation_date"></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Tipo de resolución">
                  <b-input type="text" readonly v-model="agreement.resolution_type"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de convenio general">
                  <b-input type="text" readonly v-model="agreement_type_general"></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Tipo de convenio específico">
                  <b-input type="text" readonly v-model="agreement_type_specific"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción del convenio">
                  <b-input type="textarea" readonly v-model="agreement.description"></b-input>
                </b-field>
              </div>
            </div>
<!--            <div class="columns">
              <div class="column">
                <b-field label="Glosa de servicio">
                  <b-input type="textarea" readonly v-model="agreement.service_gloss"></b-input>
                </b-field>
              </div>
            </div>-->
            <div class="columns">
              <div class="column">
                <b-field label="Etapa del convenio">
                  <b-input type="text" readonly v-model="agreement.agreement_stage"></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Estado del convenio">
                  <b-input type="text" readonly v-model="agreement.agreement_state"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Link">
                  <b-input expanded type="text" readonly v-model="agreement.link"></b-input>
                  <p class="control">
                    <b-button tag="a" :href="agreement.link" target="_blank" icon-left="external-link-alt" type="is-link">Link</b-button>
                  </p>
                </b-field>
              </div>
              <div class="column is-narrow">
                <b-field label="Internacionalidad">
                  <b-radio-button v-model="agreement.is_international"
                                  :native-value="false"
                                  :disabled="true"
                                  type="is-success is-light is-outlined">
                    <span>VIME Nacional</span>
                  </b-radio-button>
                  <b-radio-button v-model="agreement.is_international"
                                  :native-value="true"
                                  :disabled="true"
                                  type="is-success is-light is-outlined">
                    <span>VIME Internacional</span>
                  </b-radio-button>
                </b-field>
              </div>
            </div>
            <br>
            <br>
          </div>
        </div>
<!--        <table class="table is-bordered is-primary" ref="GeneralInformationTable">
            <thead>
                <tr style="text-align:center;">
                    <th colspan="2" style="color:#000;"> Información general del convenio </th>
                </tr>
            </thead>
            <tbody>
                <tr style="color: #000;text-align:center;">
                    <td> Descripción </td>
                    <td v-if='this.agreement.description == null || this.agreement.description == ""'> No se ha encontrado registro </td>
                    <td v-else> {{this.agreement.description}}</td>
                </tr>
                <tr style="color: #000;text-align:center;">
                    <td> Glosa de servicio </td>
                    <td v-if='this.agreement.service_gloss == null || this.agreement.service_gloss == ""'> No se ha encontrado registro </td>
                    <td v-else> {{this.agreement.service_gloss}}</td>
                </tr>
                <tr style="color: #000;text-align:center;">
                    <td> Monto </td>
                    <td v-if="this.agreement.amount != undefined"> ${{amountFormater(this.agreement.amount)}} CLP</td>
                    <td v-else>$0 CLP</td>
                </tr>
                <tr style="color: #000;text-align:center;">
                    <td> Etapa del convenio</td>
                    <td v-if='this.agreement.agreement_stage == null || this.agreement.agreement_stage == ""'> No se ha encontrado registro </td>
                    <td v-else> {{this.agreement.agreement_stage}}</td>
                </tr>
                <tr style="color: #000;text-align:center;">
                    <td> Estado del convenio</td>
                    <td v-if='this.agreement.agreement_state == null || this.agreement.agreement_state == ""'> No se ha encontrado registro </td>
                    <td v-else> {{this.agreement.agreement_state}}</td>
                </tr>
                <tr style="color: #000;text-align:center;">
                    <td> Encargado actual</td>
                    <td v-if='this.agreement.current_manager == null || this.agreement.current_manager == ""'> No se ha encontrado registro </td>
                    <td v-else> {{this.agreement.current_manager}}</td>
                </tr>
                <tr style="color: #000;text-align:center;">
                    <td> Fecha de inicio</td>
                    <td v-if='this.agreement.start_date == null'> No se ha encontrado registro </td>
                    <td v-else> {{this.agreement.start_date}}</td>
                </tr> 
                <tr style="color: #000;text-align:center;">
                    <td> Fecha de término</td>
                    <td v-if='this.agreement.end_date == null'> No se ha encontrado registro </td>
                    <td v-else> {{this.agreement.end_date}}</td>
                </tr>
                <tr style="color: #000;text-align:center;">
                    <td> Fecha de resolución </td>
                    <td v-if='this.agreement.creation_date == null'> No se ha encontrado registro </td>
                    <td v-else> {{this.agreement.creation_date}}</td>
                </tr>
                <tr style="color: #000;text-align:center;">
                    <td> Tipo de resolución </td>
                    <td v-if='this.agreement.resolution_type == null'> No se ha encontrado registro </td>
                    <td v-else> {{this.agreement.resolution_type}}</td>
                </tr>
                <tr style="color: #000;text-align:center;">
                    <td> N° de resolución </td>
                    <td v-if='this.agreement.resolution_number == null'> No se ha encontrado registro </td>
                    <td v-else> {{this.agreement.resolution_number}}</td>
                </tr>
                <tr style="color: #000;text-align:center;">
                    <td> Link </td>
                    <td v-if="this.agreement.link == null || !valid_link(this.agreement.link)"> No se ha encontrado registro </td>
                    <td v-else> <a @click="linkToAgreement()">{{this.agreement.link}}</a></td>
                </tr>
            </tbody>
        </table>-->
      </div>

</template>
<script>
import jsPDF from "jspdf";
import axios from "axios";

export default {
    name: "AgreementGeneralInformation",
    props:[ 'agreement', 'can_edit', 'agreement_history',"agreement_type","agreement_type_general",
      "agreement_type_specific","entidades","entities","academics","is_null"],
    data(){
        return{
          currentPage: 1,
          cardLayout: false,
        }
    },
  methods:{
        dateFormater(date){
            var newDate = ""
            var localDate = new Date(date).toLocaleDateString().toString();
            for(var i =0; i<localDate.length; i++){
                if(localDate[i] === '-'){
                    newDate = newDate.concat('-')
                }
                else{
                    newDate = newDate.concat(localDate.charAt(i))
                }
            }
            return newDate
        },
      editAgreement(){
        window.location.href="/agreements/"+this.agreement.id+"/edit/0"
      },
      downloadReport(){
        // Declaración del PDF
        var amount
        if (this.agreement.amount == null || this.agreement.amount == undefined){
          amount = 0
        }
        var doc = new jsPDF('p','pt');
        doc.setFillColor(253, 126, 20);
        doc.rect(0, 0, 600, 10, "F");
        doc.rect(0, 832, 600, 10, "F");
        doc.addImage("/logo_negro.png", "JPEG", 20, 20, 150, 80);
        /*doc.text("Universidad de Santiago de Chile", 200, 40);
        doc.text("Facultad de ingeniería", 240, 60);
        doc.text("Vicerrectoría de vinculación con el medio", 180, 80);*/

        // Título del convenio

        doc.autoTable({
          startY: 130,
          theme: 'plain',
          columnStyles: {0: { halign: 'center', font:'times new roman', fontStyle:'bold', fontSize:20,textColor:[0,0,0] }},
          margin: { top: 0, left: 40 },
          body: [[this.agreement.name]],
        })

        doc.setFont("times new roman", "normal");

        // Información general
        doc.autoTable({
          head: [
            [
              {
                content: 'Información general del convenio',
                colSpan: 2,
                styles:{
                  halign: 'center',
                  fillColor: [253, 126, 20]
                }
              }
            ]
          ],
          body: [
            ['Descripción', this.agreement.description],


            ['Etapa del convenio', this.agreement.agreement_stage],
            ['Estado del convenio', this.agreement.agreement_state],

            //['Fecha de creación', this.dateFormater(this.agreement.creation_date)],
            ['Fecha de inicio', this.agreement.start_date],
            ['Fecha de término', this.agreement.end_date != null ? this.agreement.end_date:"Indefinido"],
            ['Fecha de resolución',this.agreement.creation_date],
            ['Tipo de resolución',this.agreement.resolution_type],
            ['N° de resolución',this.agreement.resolution_number],
            ['Link',this.agreement.link]

          ],
          margin: { top: 170 }
        })

        // Entidades relacionadas
        doc.addPage();
        doc.setFillColor(253, 126, 20);
        doc.rect(0, 0, 600, 10, "F");
        doc.rect(0, 832, 600, 10, "F");
        doc.addImage("/logo_negro.png", "JPEG", 20, 20, 150, 80);
        doc.setFont("times new roman", "bold");
        doc.text("Entidades relacionadas", 220, 130);
        doc.setFont("times new roman", "normal");
        var columnsEntitiesRelatedNames = [
          {title: 'Entidad', dataKey: 'entity_name'},
          {title: 'Tipo de Entidad', dataKey: 'type_entity'},
          {title: 'Email', dataKey: 'applicant_email'},
          {title: 'Teléfono', dataKey: 'applicant_phone'}
        ]

        var agreement_entities_data = []
        this.entities.forEach(function(element){
          agreement_entities_data.push({
            entity_name: element.entity_name,
            type_entity: element.entity_type_name,
            applicant_email: element.partner_entity_data.email,
            applicant_phone: element.partner_entity_data.phone
          })
        })

        doc.autoTable(columnsEntitiesRelatedNames, agreement_entities_data, {
          headStyles:{
            //halign: 'center',
            fillColor: [253, 126, 20],
          },
          margin: { top: 160 },
          didDrawPage: function (data) {
            doc.setFillColor(253, 126, 20);
            doc.rect(0, 0, 600, 10, "F");
            doc.rect(0, 832, 600, 10, "F");
            doc.addImage("/logo_negro.png", "JPEG", 20, 20, 150, 80);
            doc.setFont("times new roman", "bold");
            doc.text("Entidades relacionadas", 220, 130);
            doc.setFont("times new roman", "normal");
          }
        })

        // Académicos relacionados
        doc.addPage();
        doc.setFillColor(253, 126, 20);
        doc.rect(0, 0, 600, 10, "F");
        doc.rect(0, 832, 600, 10, "F");
        doc.addImage("/logo_negro.png", "JPEG", 20, 20, 150, 80);
        doc.setFont("times new roman", "bold");
        doc.text("Personas Relacionadas", 220, 130);
        doc.setFont("times new roman", "normal");
        var columnsAcademicsRelatedNames = [
          {title: 'Nombre', dataKey: 'name'},
          {title: 'Email', dataKey: 'email'},
          {title: 'Cargo', dataKey: 'cargo'},
          {title: 'Participa Como', dataKey: 'participa'}
        ]

        var agreement_academics_data = []
        this.academics.forEach(function(element){
          agreement_academics_data.push({
            name: element.person_name,
            email: element.person_mail,
            cargo: element.person_function == null ?  element.person_position: element.person_function_name,
            participa: element.participant_type_name
          })
        })

        doc.autoTable(columnsAcademicsRelatedNames, agreement_academics_data, {
          headStyles:{
            //halign: 'center',
            fillColor: [253, 126, 20],
          },
          margin: { top: 160 },
          didDrawPage: function (data) {
            doc.setFillColor(253, 126, 20);
            doc.rect(0, 0, 600, 10, "F");
            doc.rect(0, 832, 600, 10, "F");
            doc.addImage("/logo_negro.png", "JPEG", 20, 20, 150, 80);
            doc.setFont("times new roman", "bold");
            doc.text("Personas Relacionadas", 220, 130);
            doc.setFont("times new roman", "normal");
          }
        })

        // Historial
        // Encabezados tabla historial
        var columnsNames = [
          {title: 'Fecha', dataKey: 'date'},
          {title: 'Proceso', dataKey: 'process'},
          {title: 'Actor', dataKey: 'actor'},
          {title: 'Notas', dataKey: 'notes'}
        ]


        this.agreement_history.forEach( function(element){
          doc.addPage();
          //sectionsDateFormater(element.agreement_stage_process);
          var agreement_stage_data = []
          element.agreement_stage_process.forEach( function(process_element){
            agreement_stage_data.push({
              date: process_element.registration_date,
              process: process_element.agreement_process_name,
              actor: process_element.agreement_actor_name,
              notes: process_element.notes
            })
          })
          if(Object.entries(element.agreement_stage_process).length === 0){
            doc.text("No hay registros de esta sección", 50, 190);
            doc.setFillColor(253, 126, 20);
            doc.rect(0, 0, 600, 10, "F");
            doc.rect(0, 832, 600, 10, "F");
            doc.addImage("/logo_negro.png", "JPEG", 20, 20, 150, 80);
            doc.setFont("times new roman", "bold");
            doc.text("Historial del convenio", 220, 130);
            doc.setFont("times new roman", "normal");
            doc.text(element.agreement_stage_name, 30, 160);
          }
          else{
            doc.autoTable(columnsNames, agreement_stage_data, {
              headStyles:{
                //halign: 'center',
                fillColor: [253, 126, 20],
              },
              margin: { top: 180 },
              didDrawPage: function (data) {
                doc.setFillColor(253, 126, 20);
                doc.rect(0, 0, 600, 10, "F");
                doc.rect(0, 832, 600, 10, "F");
                doc.addImage("/logo_negro.png", "JPEG", 20, 20, 150, 80);
                doc.setFont("times new roman", "bold");
                doc.text("Historial del convenio", 220, 130);
                doc.setFont("times new roman", "normal");
                doc.text(element.agreement_stage_name, 30, 160);
              }
            })
          }
        })

        // Descarga
        doc.save(this.agreement.name+'.pdf');
      },
        amountFormater(amount){
            var aux         = amount.toString()
            var aux_list    = []
            var aux_list2   = []
            var newAmount   = ""
            for(var i = 0; i<aux.length; i++){
                aux_list.push(String(aux[i]))
            }
            aux_list    = aux_list.reverse()
            for(var i=0; i<aux_list.length; i++){
                if(i%3 === 0 && i != 0){
                    aux_list2.push('.')
                    aux_list2.push(aux_list[i])
                }
                else{
                    aux_list2.push(aux_list[i])
                }
            } 
            aux_list2.reverse()
            for(var i=0; i<aux_list2.length; i++){
                newAmount = newAmount.concat(aux_list2[i])
            }
            return newAmount
        },
        linkToAgreement(){
            var regExp = /http[s]?\:\/\/[a-zA-Z 0-9]+\.[a-z]+/
            if(this.agreement.link != null && this.agreement.link!= ""){
              if (regExp.test(this.agreement.link)){
                window.open(this.agreement.link)
              }
            }
            else{
              window.open(this.agreement.link)
            }

        },
        valid_link(link){
          var regExp = /http[s]?\:\/\/[a-zA-Z 0-9]+\.[a-z]+/
          if(link != null && link!= ""){
            return regExp.test(link)
          }
          else{
            return false
          }
        },
      goToEntity(id){
        window.location.href='/partner_entities/show/'+id
      },
    execNullifyModal(){
      this.$buefy.dialog.confirm({
        title: 'Anular disclosure',
        message: '¿Está seguro que desea <b>anular</b> el convenio? Esta acción lo dejará inválido.<br>',
        confirmText: 'Anular Convenio',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true
          axios
              .delete("/agreements/" + this.agreement.id + "/nullify_agreement.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Convenio anulado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                if (document.referrer == ''){
                  window.location.href = history.back()
                }
                else{
                  window.location.href = document.referrer
                }

                window.location.href = document.referrer
                this.isLoading = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Convenio no pudo ser anulada</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
          })
        }
      })
    },
  }
}
</script>