<template>
    <section>

        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
                <b-message type="is-danger" has-icon>
                    <div v-for="(item, index) in errores_validacion" :key="index">
                        {{ item[0] }}
                    </div>
                </b-message>
            </div>

            <form @submit.prevent="handleSubmit(saveMyAcademicData)">

                <div class="columns">
                    <div class="column has-text-right">
                        <b-button type="is-pdf-color has-text-white" icon-left="file-pdf"
                            label="Descargar Curriculum PDF" @click="remember()" />
                    </div>
                </div>

                <div class="columns">
                    <div class="column">
                        <ValidationProvider name="Reseña" rules="min:3|max:500" v-slot="{ errors, failedRules }">
                            <b-field label="Reseña" :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                <b-input type="textarea" placeholder="Ingrese una descripción sobre ud."
                                    v-model="person.biography" />
                            </b-field>
                        </ValidationProvider>
                    </div>
                </div>


                <div class="columns">

                    <div class="column">
                        <ValidationProvider ref="researchLines" name="Líneas de Investigación (Expertiz)"
                            v-slot="{ errors, failedRules }">
                            <b-field label="Líneas de Investigación (Expertiz)"
                                :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                <b-taginput v-model="person.key_words_attributes" :data="filtered_key_words"
                                    :allow-new="true" :allow-duplicates="false" field="name" icon="tag" autocomplete
                                    placeholder="Agrega una expertiz" :beforeAdding="beforeAddTag"
                                    @typing="getFilteredKeyWords" />
                            </b-field>
                        </ValidationProvider>

                    </div>

                    <div class="column">
                        <ValidationProvider name="Link Propio" rules="min:3|max:255|protocol" v-slot="{ errors, failedRules }">
                            <b-field label="Link Propio" :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                <b-input type="link" expanded placeholder="Ingrese Link Propio" v-model="person.link" />
                                <b-button type="is-link" icon-left="external-link-alt" label="Link" tag="a"
                                    :href="person.link ? person.link : ''" target="_blank" />
                            </b-field>
                        </ValidationProvider>

                    </div>

                </div>

                <div v-if="person.can_update" class="columns">
                    <div class="column has-text-right">
                        <button type="submit" class="button is-primary" @click="checkValidation">
                            <span class="icon">
                                <i class="fas fa-save"></i>
                            </span>
                            <span>Guardar Datos Curriculum VIME Largo</span>
                        </button>
                    </div>
                </div>
            </form>

        </ValidationObserver>

        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

        <img id="logo" src="/logo_negro_facultad_ing.png" style="display: none;" />
        <img id="fondo" src="/fondo_curriculum_corto.png" style="display: none;" />
        <img id="mail" src="/mail_icon.png" style="display: none;" />
        <img id="mundo" src="/icono_mundo2.png" style="display: none;" />
        <img id="photo" :src="this.person.photo_url" style="display: none;" />
        <img id="pfp" src="/blank_profile_photo.png" style="display: none;" @load="loadImage" />
        <img id="linea" src="/linea_divisora.png" style="display: none;" />

    </section>
</template>

<script>
import axios from "axios"
import moment from "moment"
import jsPDF from 'jspdf'
import {BrandonGrotesqueMedium} from "./fonts/BrandonGrotesque-Medium-normal.js"
import {BrandonGrotesqueBlack} from "./fonts/BrandonGrotesque-Black-normal"
import {BrandonGrotesqueBold} from "./fonts/BrandonGrotesque-Bold-normal"
import {BrandonGrotesqueRegular} from "./fonts/BrandonGrotesque-Regular-normal"
export default {
    props: [
        'personId', 'cargoPrincipal', 'canCreateDocument'
    ],
    data() {
        return {
            isLoading: false,
            person: {
                person_unit_name: null,
            },
            key_words: [],
            filtered_key_words: [],
            errores_validacion: [],
            publications: [],
            projects: [],
            patents: [],
            disclosures: [],
            books: [],
            inbooks: [],
            person_studies: [],
            person_jobs: [],
            person_functions: [],
            reference_publication: [],
            reference_publication_book: [],
            reference_publication_inbook: [],
            reference_publication_otros: [],
            reference_publication_journal: [],
            reference_publication_conference: [],
            reference_project: [],
            reference_patent: [],
            reference_disclosure: [],
            reference_book: [],
            reference_inbook: [],
            reference_inbook_charapter: [],
            reference_inbook_article: [],
            imageRounded: null,
        }
    },

    async created() {
        await this.getPerson();
    },

    methods: {

        async checkValidation() {
            const valid = await this.$refs.observer.validate()
            if (!valid) {
                this.$buefy.dialog.alert({
                    message: 'Algunos campos presentan errores.',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
            }
        },

        saveMyAcademicData() {
            this.isLoading = true
            axios
                .put("/people/" + this.personId + "/my-academic-data/update.json", {
                    person: this.person,
                },
                )
                .then(response => {
                    this.errores_validacion = []
                    this.$buefy.dialog.alert({
                        title: 'Logrado',
                        message: 'Se han guardado satisfactoriamente sus datos de Curriculum VIME Largo.',
                        type: 'is-success',
                        hasIcon: true,
                        iconPack: 'fa',
                        icon: 'check-circle',
                        confirmText: 'Ok',
                    })
                    this.isLoading = false
                    this.getPerson()
                }).
                catch(error => {
                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al guardar sus datos de Curriculum VIME Largo, favor revisar la consola.',
                        type: 'is-danger',
                        hasIcon: true,
                        iconPack: 'fa',
                        icon: 'times-circle',
                        confirmText: 'Ok',
                    })
                    this.errores_validacion = error.response.data
                    var elmnt = document.getElementById("app")
                    elmnt.scrollIntoView()
                    this.isLoading = false
                })
        },
        queryError(vm) {
            vm.$buefy.dialog.alert({
                title: 'Error',
                message: 'Hubo un error en su solicitud.',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
            })
        },

        async getPerson() {
            this.isLoading = true;
            await axios
                .get("/people/my-vime-large-resume.json", {params: {
                    id: this.personId,
                }})
                .then(res => {
                    this.person = res.data
                    this.getPeopleKeywords()
                    this.loadImage()
                })
                .catch(e => {
                    console.log(e);
                    this.queryError(this);
                    this.isLoading = false;
                });
        },
        async getPeopleKeywords() {
            //this.isLoading = true;
            await axios
                .get("/people/keywords.json")
                .then(res => {
                    this.key_words = res.data
                    //this.isLoading = false;
                })
                .catch(e => {
                    console.log(e);
                    this.queryError(this);
                    this.isLoading = false;
                });
        },

        getFilteredKeyWords(text) {
            this.filtered_key_words = this.key_words.filter((option) => {
                return option.name
                    .toString()
                    .toLowerCase()
                    .indexOf(text.toLowerCase()) >= 0
            })
        },

        addTags(value) {
            var aux = 0
            var key_words_attributes = this.person.key_words_attributes
            var temp_key_word = this.person.key_words_attributes
            if (value != null && value.length > 0 && value.length <= 255) {
                value.forEach(function (tag) {
                    aux = 0
                    var auxTag = tag.toUpperCase()
                    auxTag = auxTag.trim()
                    temp_key_word.forEach(function (word) {
                        if (word.constructor.name === 'String') {
                            if (word === auxTag) {
                                aux = 1
                            }
                        }
                        else {
                            if (word.name === auxTag) {
                                aux = 1
                            }
                        }
                    })
                    if (aux == 0) {
                        key_words_attributes.push(auxTag)
                    }
                })
                this.person.key_words_attributes = key_words_attributes
            } else if(value != null || value.length > 0) {

                this.$refs.researchLines.setErrors(['La Linea de Investigación Ingresada no debe de ser nula']);
        
            } else {
                this.$refs.researchLines.setErrors(['La Linea de Investigación Ingresada no debe superar los 255 caracteres']);
            }
        },

        beforeAddTag(tags) {

            if (tags.constructor.name === 'String') {
                var splitTags = tags.split(",")

                if (splitTags.length == 1) {
                    splitTags = tags.split(";")
                }

                this.addTags(splitTags)
            }
            else {
                var result = this.person.key_words_attributes.filter(tag => tag.name === tags.name)
                if (result.length == 0) {
                    this.person.key_words_attributes.push(tags)
                }
            }
        },

        remember() {
            this.$buefy.dialog.confirm({
                title: 'Recordatorio',
                message: 'Recuerde <b>Guardar</b> sus datos de Curriculum VIME Largo',
                confirmText: 'Descargar Curriculum VIME Largo',
                cancelText: 'Cancelar',
                type: 'is-warning',
                hasIcon: true,
                iconPack: 'fa',
                icon: 'exclamation-triangle',
                onConfirm: async () => {
                    this.getPublication()
                }
            })
        },

        getPublication() {
            this.isLoading = true
            axios
                .get("/people/" + this.personId + "/publications.json", { params: { year_f: 'Todo', id: this.personId} })
                .then(res => {
                    this.publications = res.data
                    this.publications = this.publications.map(publicacion => {
                        var publicacion = publicacion
                        publicacion["publication_date"] = moment(publicacion.publication_date, ["YYYY-MM-DD", "DD-MM-YYYY"]);;
                        return publicacion;
                    });
                    var indice = 0
                    var indice_otros = 1
                    var indice_journal = 1
                    var indice_conference = 1
                    var indice_book = 1
                    var indice_inbook = 1
                    if (this.publications.length > 0) {
                        this.publications.every(publication => {
                            var autores = ''
                            var name = ''
                            var revista = ''
                            var volumen = ''
                            var fecha = ''
                            var pag_ini = ''
                            var pag_fin = ''
                            var doi = ''
                            var ref = ''

                            if (publication.participants_references != null) {
                                autores = publication.participants_references + '.'
                            }
                            if (publication.title != null) {

                                if (publication.participants_references == null) {
                                    name = publication.title + '.'
                                }
                                else {
                                    name = " " + publication.title + '.'
                                }
                            }
                            if (publication.magazine != null) {
                                revista = " " + publication.magazine + '.'
                            }
                            if (publication.volumen != null) {
                                volumen = " " + publication.volumen + '.'
                            }
                            if (publication.publication_date._isValid == true) {
                                if (publication.volumen == null) {
                                    fecha = "(" + publication.publication_date.year() + ")."
                                }
                                else {
                                    fecha = " (" + publication.publication_date.year() + ")."
                                }
                            }
                            if (publication.start_page != null) {
                                pag_ini = " " + publication.start_page + '-'
                            }
                            if (publication.end_page != null) {
                                if (publication.start_page == null) {
                                    pag_fin = "-" + publication.end_page + '.'
                                }
                                else {
                                    pag_fin = publication.end_page + '.'
                                }
                            }
                            if (publication.doi != null) {
                                doi = " " + publication.doi + '.'
                            }

                            if (publication.magazine_type == null) {
                                if (publication.publication_type_id == 2) {
                                    ref = "[" + indice_book.toString() + "] " + autores + name + revista + volumen + fecha + pag_ini + pag_fin + doi
                                    this.reference_publication_book.push({ ref_name: ref })
                                    indice_book = indice_book + 1
                                }
                                else if (publication.publication_type_id == 5) {
                                    ref = "[" + indice_inbook.toString() + "] " + autores + name + revista + volumen + fecha + pag_ini + pag_fin + doi
                                    this.reference_publication_inbook.push({ ref_name: ref })
                                    indice_inbook = indice_inbook + 1
                                }
                                else {
                                    ref = "[" + indice_otros.toString() + "] " + autores + name + revista + volumen + fecha + pag_ini + pag_fin + doi
                                    this.reference_publication_otros.push({ ref_name: ref })
                                    indice_otros = indice_otros + 1
                                }
                            }
                            else {
                                if (publication.magazine_type == "JOURNAL" && publication.publication_type_id == 1) {
                                    ref = "[" + indice_journal.toString() + "] " + autores + name + revista + volumen + fecha + pag_ini + pag_fin + doi
                                    this.reference_publication_journal.push({ ref_name: ref })
                                    indice_journal = indice_journal + 1
                                }
                                else if (publication.magazine_type == 'CONFERENCE AND PROCEEDINGS' && publication.publication_type_id == 1) {
                                    ref = "[" + indice_conference.toString() + "] " + autores + name + revista + volumen + fecha + pag_ini + pag_fin + doi
                                    this.reference_publication_conference.push({ ref_name: ref })
                                    indice_conference = indice_conference + 1
                                }
                                else {
                                    if (publication.publication_type_id == 2) {
                                        ref = "[" + indice_book.toString() + "] " + autores + name + revista + volumen + fecha + pag_ini + pag_fin + doi
                                        this.reference_publication_book.push({ ref_name: ref })
                                        indice_book = indice_book + 1
                                    }
                                    else if (publication.publication_type_id == 5) {
                                        ref = "[" + indice_inbook.toString() + "] " + autores + name + revista + volumen + fecha + pag_ini + pag_fin + doi
                                        this.reference_publication_inbook.push({ ref_name: ref })
                                        indice_inbook = indice_inbook + 1
                                    }
                                    else {
                                        ref = "[" + indice_otros.toString() + "] " + autores + name + revista + volumen + fecha + pag_ini + pag_fin + doi
                                        this.reference_publication_otros.push({ ref_name: ref })
                                        indice_otros = indice_otros + 1
                                    }
                                }
                            }



                            ref = "[" + indice.toString() + "] " + autores + name + revista + volumen + fecha + pag_ini + pag_fin + doi
                            this.reference_publication.push({ ref_name: ref })
                            indice = indice + 1

                            return true
                            /*if (this.reference_publication.length == 5) {
                              return false
                            }
                            else{
                              return true
                            }*/
                        })

                    }

                    this.getProjects()
                })
                .catch(e => {
                    console.log(e);
                    this.isLoading = false;
                });
        },

        getProjects() {
            axios
                .get("/people/" + this.personId + "/projects.json", { params: { year_f: 'Todo' } })
                .then(res => {
                    this.projects = res.data
                    this.projects = this.projects.map(proyecto => {
                        var proyecto = proyecto
                        proyecto["start_date"] = moment(proyecto.start_date, ["YYYY-MM-DD", "DD-MM-YYYY"]);
                        proyecto["end_date"] = moment(proyecto.end_date, ["YYYY-MM-DD", "DD-MM-YYYY"]);
                        return proyecto;
                    });
                    var indice = 1
                    if (this.projects.length > 0) {
                        this.projects.every(project => {
                            var start_year = ''
                            var end_year = ''
                            var name = ''
                            if (project.start_date._isValid == true) {
                                start_year = project.start_date.year()
                            }
                            if (project.end_date._isValid == true) {
                                end_year = project.end_date.year()
                            }

                            if (start_year == '' && end_year != '') {
                                name = indice.toString() + ".- " + project.name + ", " + project.code + ", -" + end_year
                            }

                            else if (start_year != '' && end_year == '') {
                                name = indice.toString() + ".- " + project.name + ", " + project.code + ", " + start_year + "-"
                            }
                            else if (start_year != '' && end_year != '') {
                                name = indice.toString() + ".- " + project.name + ", " + project.code + ", " + start_year + "-" + end_year
                            }
                            else {
                                name = indice.toString() + ".- " + project.name + ", " + project.code
                            }
                            indice = indice + 1
                            this.reference_project.push({ ref_name: name })
                            return true
                            /*if (this.reference_project.length == 5) {
                              return false
                            }
                            else{
                              return true
                            }*/

                        })
                    }
                    this.getPatents()
                })
                .catch(e => {
                    console.log(e);
                    this.isLoading = false;
                });
        },

        getPatents() {
            axios
                .get("/people/" + this.personId + "/patents.json", { params: { year_f: 'Todo' } })
                .then(res => {
                    this.patents = res.data
                    var indice = 1
                    if (this.patents.length > 0) {
                        this.patents.every(patent => {
                            var name = ''
                            var title = ''
                            var codigo = ''
                            var fecha = ''
                            var pais = ''
                            var participantes = ''

                            title = patent.title
                            codigo = patent.protection_code
                            String.prototype.toProperCase = function () {
                                return this.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
                            };
                            patent.person_patents_attributes.every(participant => {
                                if (participantes == '') {
                                    if (participant.last_name != null && participant.last_name != undefined) {
                                        participantes = participant.name.toProperCase() + ' ' + participant.last_name.toProperCase()
                                    }
                                    else {
                                        participantes = participant.name.toProperCase()
                                    }
                                }
                                else {
                                    if (participant.last_name != null && participant.last_name != undefined) {
                                        participantes = participantes + ', ' + participant.name.toProperCase() + ' ' + participant.last_name.toProperCase()
                                    }
                                    else {
                                        participantes = participantes + ', ' + participant.name.toProperCase()
                                    }

                                }
                                return true
                            })
                            if (patent.presentation_date != null && patent.presentation_date._isValid === true) {
                                fecha = patent.presentation_date.year()
                            }
                            pais = patent.nationality
                            if (fecha != '') {
                                name = indice.toString() + ".- " + participantes + '. ' + title + '. ' + codigo + '. ' + fecha + '. ' + pais + '.'
                            }
                            else {
                                name = indice.toString() + ".- " + participantes + '. ' + title + '. ' + codigo + '. ' + pais + '.'
                            }
                            indice = indice + 1
                            this.reference_patent.push({ ref_name: name })
                            return true

                        })

                    }
                    this.getDisclosures()

                })
                .catch(e => {
                    console.log(e);
                    this.isLoading = false;
                });
        },

        getDisclosures() {
            axios
                .get("/people/" + this.personId + "/disclosures.json", { params: { year_f: 'Todo' } })
                .then(res => {
                    this.disclosures = res.data
                    if (this.disclosures.length > 0) {
                        this.disclosures.every(disclosure => {
                            var name = ''
                            var title = ''
                            var codigo = ''
                            var fecha = ''
                            var participantes = ''


                            codigo = disclosure.code
                            String.prototype.toProperCase = function () {
                                return this.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
                            };
                            title = disclosure.name.toProperCase()
                            disclosure.inventors.every(participant => {
                                if (participantes == '') {
                                    participantes = participant.name.toProperCase()
                                }
                                else {
                                    participantes = participantes + ', ' + participant.name.toProperCase()
                                }
                                return true
                            })
                            if (disclosure.disclosure_date != null && disclosure.disclosure_date._isValid === true) {
                                fecha = disclosure.disclosure_date.year()
                            }
                            if (fecha != '') {
                                name = "· " + participantes + '. ' + title + '. ' + codigo + '. ' + fecha + '.'
                            }
                            else {
                                name = "· " + participantes + '. ' + title + '. ' + codigo + '.'
                            }
                            this.reference_disclosure.push({ ref_name: name })
                            return true
                        })
                    }
                    this.getBooks()

                })
                .catch(e => {
                    console.log(e);
                    this.isLoading = false;
                });
        },

        getBooks() {
            this.isLoading = true
            axios
                .get("/people/" + this.personId + "/books.json", { params: { year_f: 'Todo' } })
                .then(res => {
                    this.books = res.data
                    this.books = this.books.map(publicacion => {
                        var publicacion = publicacion
                        publicacion["publication_date"] = moment(publicacion.publication_date, ["YYYY-MM-DD", "DD-MM-YYYY"]);;
                        return publicacion;
                    });
                    var indice = 1
                    if (this.books.length > 0) {
                        this.books.every(publication => {
                            var autores = ''
                            var name = ''
                            var revista = ''
                            var volumen = ''
                            var fecha = ''
                            var pag_ini = ''
                            var pag_fin = ''
                            var doi = ''
                            var msj_isbn = ''
                            var msj_prueba = ''
                            var ref = ''
                            var inicial = ''
                            var adicional = ''
                            var imprenta = ''
                            var isbn_digital = ''
                            var isbn_fisico = ''
                            var tipo_part = ''
                            var compiladores = ''
                            var editores = ''
                            var revisores = ''

                            if (publication.participants_references != null) {
                                autores = " " + publication.participants_references
                            }

                            if (publication.publication_date._isValid == true) {
                                fecha = " (" + publication.publication_date.year() + ")."
                            }


                            if (publication.title != null) {
                                if (publication.volumen != null) {
                                    name = " " + publication.title
                                }
                                else {
                                    name = " " + publication.title + "."
                                }

                            }


                            if (publication.volumen != null) {
                                volumen = " (Vol." + publication.volumen + ').'
                            }

                            if (publication.editorial != null) {
                                revista = " " + publication.editorial + '.'
                            }

                            if (publication.printing != null) {
                                imprenta = " Imprenta " + publication.printing + '.'
                            }


                            if (publication.isbns != null) {
                                publication.isbns.forEach(dato => {
                                    if (dato.is_physical == true) {
                                        if (isbn_fisico != '') {
                                            isbn_fisico = isbn_fisico + ", " + dato.code
                                        }
                                        else {
                                            isbn_fisico = ' ISBN edición impresa: ' + dato.code
                                        }
                                    }
                                    else {
                                        if (isbn_digital != '') {
                                            isbn_digital = isbn_digital + ", " + dato.code
                                        }
                                        else {
                                            isbn_digital = ' ISBN edición digital: ' + dato.code
                                        }
                                    }



                                })
                                if (isbn_fisico != '') {
                                    isbn_fisico = isbn_fisico + "."
                                }
                                if (isbn_digital != '') {
                                    isbn_digital = isbn_digital + "."
                                }
                                msj_isbn = isbn_fisico + isbn_digital

                            }



                            if (publication.doi != null) {
                                doi = " " + publication.doi + '.'
                            }
                            if (publication.url != null) {
                                doi = doi + " " + publication.url + '.'
                            }



                            if (publication.archive != null) {
                                adicional = " " + publication.archive + " "
                                if (publication.archive_position != null) {
                                    adicional = adicional + " " + publication.archive_position
                                }
                                adicional = adicional + "."
                            }

                            if (publication.additional != null) {
                                adicional = adicional + " " + publication.additional + "."
                            }

                            publication.participants_attributes.forEach(participante => {
                                if (participante.participant_type == "Editor") {
                                    if (editores == '') {
                                        editores = " " + this.titleaize(participante.person_name)
                                    }
                                    else {
                                        editores = editores + ", " + this.titleaize(participante.person_name)
                                    }
                                }
                                else if (participante.participant_type == "Compilador") {
                                    if (compiladores == '') {
                                        compiladores = " " + this.titleaize(participante.person_name)
                                    }
                                    else {
                                        compiladores = compiladores + ", " + this.titleaize(participante.person_name)
                                    }
                                }
                                else if (participante.participant_type == "Revisor") {
                                    if (revisores == '') {
                                        revisores = " " + this.titleaize(participante.person_name)
                                    }
                                    else {
                                        revisores = revisores + ", " + this.titleaize(participante.person_name)
                                    }
                                }

                            })

                            if (compiladores != '') {
                                autores = autores + " ," + compiladores + " (Comp.)"
                            }
                            if (revisores != '') {
                                autores = autores + " ," + revisores + " (Rev.)"
                            }
                            if (editores != '') {
                                autores = autores + " ," + editores + " (Ed.)"
                            }





                            ref = "[" + indice.toString() + "] " + autores + fecha + name + volumen + revista + imprenta + msj_isbn + adicional + doi
                            this.reference_book.push({ ref_name: ref })
                            indice = indice + 1


                            return true
                        })

                    }

                    this.getInbooks()
                })
                .catch(e => {
                    console.log(e);
                    this.isLoading = false;
                });
        },

        getInbooks() {
            this.isLoading = true
            axios
                .get("/people/" + this.personId + "/sections.json", { params: { year_f: 'Todo' } })
                .then(res => {
                    this.inbooks = res.data
                    this.inbooks = this.inbooks.map(publicacion => {
                        var publicacion = publicacion
                        publicacion["publication_date"] = moment(publicacion.publication_date, ["YYYY-MM-DD", "DD-MM-YYYY"]);;
                        return publicacion;
                    });
                    var indice = 1
                    var indice_article = 1
                    var indice_charapter = 1
                    if (this.inbooks.length > 0) {
                        this.inbooks.every(publication => {
                            var informacion_libro = ''
                            var name_section = ''
                            var autores_section = ''
                            var pag_ini = ''
                            var pag_fin = ''
                            var fecha = ''
                            var ref = ''



                            //Obtencion de la informacion del articulo
                            if (publication.participants_references != null) {
                                autores_section = " " + publication.participants_references
                            }
                            if (publication.anno != null) {
                                fecha = " (" + publication.anno + ")."
                            }




                            if (publication.title != null) {
                                name_section = " " + publication.title
                            }



                            if (publication.start_page != null) {
                                pag_ini = " pp " + publication.start_page
                            }

                            if (publication.end_page != null) {
                                if (publication.start_page != null) {
                                    pag_fin = "-" + publication.end_page + '.'
                                }
                                else {
                                    pag_fin = " Páginas: " + publication.end_page + '.'
                                }
                            }
                            else {
                                pag_fin = "."
                            }



                            //Obtencion de la informacion del libro asociado


                            if (publication.libros != null) {
                                publication.libros.forEach(libro => {
                                    var autores = ''
                                    var name = ''
                                    var revista = ''
                                    var volumen = ''
                                    var doi = ''
                                    var msj_isbn = ''
                                    var msj_prueba = ''
                                    var inicial = ''
                                    var adicional = ''
                                    var infoLibro = ''
                                    var imprenta = ''
                                    var isbn_fisico = ''
                                    var isbn_digital = ''
                                    var compiladores = ''
                                    var editores = ''
                                    var revisores = ''

                                    if (libro.participants_references != null) {
                                        autores = " en " + libro.participants_references
                                    }
                                    if (libro.title != null) {
                                        name = " " + libro.title
                                    }

                                    if (libro.volumen != null) {
                                        if (publication.start_page != null || publication.end_page != null) {
                                            volumen = " (Vol. " + libro.volumen + ',' + pag_ini + pag_fin + ")."
                                        }
                                        else {
                                            volumen = " (Vol. " + libro.volumen + ').'
                                        }

                                    }
                                    else {
                                        volumen = " (" + pag_ini + pag_fin + ")."
                                    }


                                    if (libro.editorial != null) {
                                        revista = " " + libro.editorial + '.'
                                    }

                                    if (libro.printing != null) {
                                        imprenta = " Imprenta " + libro.printing + '.'
                                    }



                                    if (libro.isbns != null) {
                                        libro.isbns.forEach(dato => {
                                            if (dato.is_physical == true) {
                                                if (isbn_fisico != '') {
                                                    isbn_fisico = isbn_fisico + ", " + dato.code
                                                }
                                                else {
                                                    isbn_fisico = ' ISBN edición impresa: ' + dato.code
                                                }
                                            }
                                            else {
                                                if (isbn_digital != '') {
                                                    isbn_digital = isbn_digital + ", " + dato.code
                                                }
                                                else {
                                                    isbn_digital = ' ISBN edición digital: ' + dato.code
                                                }
                                            }

                                        })
                                        if (isbn_fisico != '') {
                                            isbn_fisico = isbn_fisico + "."
                                        }
                                        if (isbn_digital != '') {
                                            isbn_digital = isbn_digital + "."
                                        }

                                        msj_isbn = isbn_fisico + isbn_digital

                                    }



                                    libro.participants_attributes.forEach(participante => {
                                        if (participante.participant_type == "Editor") {
                                            if (editores == '') {
                                                editores = " " + this.titleaize(participante.person_name)
                                            }
                                            else {
                                                editores = editores + ", " + this.titleaize(participante.person_name)
                                            }
                                        }
                                        else if (participante.participant_type == "Compilador") {
                                            if (compiladores == '') {
                                                compiladores = " " + this.titleaize(participante.person_name)
                                            }
                                            else {
                                                compiladores = compiladores + ", " + this.titleaize(participante.person_name)
                                            }
                                        }
                                        else if (participante.participant_type == "Revisor") {
                                            if (revisores == '') {
                                                revisores = " " + this.titleaize(participante.person_name)
                                            }
                                            else {
                                                revisores = revisores + ", " + this.titleaize(participante.person_name)
                                            }
                                        }

                                    })

                                    if (compiladores != '') {
                                        autores = autores + " ," + compiladores + " (Comp.)"
                                    }
                                    if (revisores != '') {
                                        autores = autores + " ," + revisores + " (Rev.)"
                                    }
                                    if (editores != '') {
                                        autores = autores + " ," + editores + " (Ed.)"
                                    }
                                    autores = autores + ","





                                    if (libro.archive != null) {
                                        adicional = " " + libro.archive + " "
                                        if (libro.archive_position != null) {
                                            adicional = adicional + " " + libro.archive_position
                                        }
                                        adicional = adicional + "."
                                    }

                                    if (libro.additional != null) {
                                        adicional = adicional + " " + libro.additional + "."
                                    }


                                    if (libro.url != null) {
                                        doi = " " + libro.url + "."
                                    }



                                    informacion_libro = informacion_libro + " " + autores + name + volumen + revista + imprenta + msj_isbn + adicional + doi


                                })
                            }

                            if (publication.publication_type_id == 3) {
                                ref = "[" + indice_charapter.toString() + "] " + autores_section + fecha + name_section + informacion_libro
                                this.reference_inbook_charapter.push({ ref_name: ref })
                                indice_charapter = indice_charapter + 1
                            }
                            else {
                                ref = "[" + indice_article.toString() + "] " + autores_section + fecha + name_section + informacion_libro
                                this.reference_inbook_article.push({ ref_name: ref })
                                indice_article = indice_article + 1
                            }

                            ref = "[" + indice.toString() + "] " + name_section
                            this.reference_inbook.push({ ref_name: ref })
                            indice = indice + 1


                            return true
                        })

                    }

                    this.get_data()
                })
                .catch(e => {
                    console.log(e);
                    this.isLoading = false;
                });
        },

        get_data() {
            this.isLoading = true;
            axios
                .get("/people/" + this.personId + "/cv_extendido.json")
                .then(res => {
                    this.person_studies = res.data['antecedentes_academicos']
                    this.person_jobs = res.data['person_jobs']
                    this.person_functions = res.data['person_functions']
                    this.person.person_unit_name = res.data['person_unit_name']
                    this.downloadExtendCVPdf()
                })
                .catch(e => {
                    console.log(e);
                    this.isLoading = false;
                });
        },

        async downloadExtendCVPdf() {
            //Se crea el documento
            const doc = new jsPDF({
                orientation: "portrait", //Vertical
                unit: 'mm',
                format: [215.9, 279.4] //Tamaño carta
            });


            //Se agregan las fuentes del curriculum de ejemplo enviado por VIME
            doc.addFileToVFS('BrandonGrotesque-Medium-normal.ttf', BrandonGrotesqueMedium());
            doc.addFileToVFS('BrandonGrotesque-Black-normal.ttf', BrandonGrotesqueBlack());
            doc.addFileToVFS('BrandonGrotesque-Bold-normal.ttf', BrandonGrotesqueBold());
            doc.addFileToVFS('BrandonGrotesque-Regular-normal.ttf', BrandonGrotesqueRegular());
            doc.addFont('BrandonGrotesque-Medium-normal.ttf', 'BrandonGrotesque-Medium', 'normal');
            doc.addFont('BrandonGrotesque-Black-normal.ttf', 'BrandonGrotesque-Black', 'normal');
            doc.addFont('BrandonGrotesque-Bold-normal.ttf', 'BrandonGrotesque-Bold', 'normal');
            doc.addFont('BrandonGrotesque-Regular-normal.ttf', 'BrandonGrotesque-Regular', 'normal');


            //Funcion
            String.prototype.toProperCase = function () {
                return this.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
            };

            //Se agregan las keywords de las personas
            var expertice = ''
            this.person.key_words_attributes.forEach(element => {
                if (expertice == '') {
                    expertice = element.name.toProperCase()
                }
                else {
                    expertice = expertice + ", " + element.name.toProperCase()
                }
            })

            //Se llama a funcion que genera imagen de perfil redonda
            //var tmpCanvas = await this.loadImage(img_path)


            //Variable que guarda el ORCID
            var orcid = this.person.orcid
            if (orcid == null) {
                orcid = 'ORCID'
            }

            var h_index = this.person.h_index == null ? '' : this.person.h_index
            var biography = this.person.biography == null ? '' : this.person.biography
            var link = this.person.link == null ? '' : this.person.link
            var title = this.person.person_name_curriculum == null ? '' : this.person.person_name_curriculum

            var canvasLogo = document.createElement('canvas');
            var imgLogo = document.getElementById('logo');
            canvasLogo.height = imgLogo.naturalHeight;
            canvasLogo.width = imgLogo.naturalWidth;
            var ctxLogo = canvasLogo.getContext('2d');
            ctxLogo.drawImage(imgLogo, 0, 0, canvasLogo.width, canvasLogo.height)
            var dataLogo = canvasLogo.toDataURL();
            doc.addImage(dataLogo, "PNG", 158.35, 10, 43.42, 10.84);
            //doc.addImage(dataLogo, "PNG", 147.5, 14.93, 54.27, 13.55);

            //Circulos parte del diseno del curriculum de ejemplo
            doc.setFillColor('#213967')
            doc.circle(160.65 + 8, 269.25, 3.75, 'F');

            doc.setFillColor('#275d9d')
            doc.circle(170.15 + 8, 269.25, 3.75, 'F');

            doc.setFillColor('#b10f6d')
            doc.circle(179.65 + 8, 269.25, 3.75, 'F');

            doc.setFillColor('#b93ca5')
            doc.circle(189.15 + 8, 269.25, 3.75, 'F');

            //Se agrega la imagen del fondo de los datos personales
            var canvasFondo = document.createElement('canvas');
            var imgFondo = document.getElementById('fondo');
            canvasFondo.height = imgFondo.naturalHeight;
            canvasFondo.width = imgFondo.naturalWidth;
            var ctxFondo = canvasFondo.getContext('2d');
            ctxFondo.drawImage(imgFondo, 0, 0, canvasFondo.width, canvasFondo.height)
            var dataFondo = canvasFondo.toDataURL();

            doc.addImage(dataFondo, "PNG", 0, 0, 76.4, 287.7);

            //Se agrega y se crea el espacio para la foto de perfil
            doc.setFillColor('#b10f6d')
            doc.circle(38.1, 44.9, 30.0, 'F');
            doc.setFont("BrandonGrotesque-Bold", "normal");
            var startingPage = doc.internal.getCurrentPageInfo().pageNumber;

            //Se agrega la imagen del icono de mail
            var canvasMail = document.createElement('canvas');
            var imgMail = document.getElementById('mail');
            canvasMail.height = imgMail.naturalHeight;
            canvasMail.width = imgMail.naturalWidth;
            var ctxMail = canvasMail.getContext('2d');
            ctxMail.drawImage(imgMail, 0, 0, canvasMail.width, canvasMail.height)
            var dataMail = canvasMail.toDataURL();

            //Se agrega la imagen del icono del mundo
            var canvasMundo = document.createElement('canvas');
            var imgMundo = document.getElementById('mundo');
            canvasMundo.height = imgMundo.naturalHeight;
            canvasMundo.width = imgMundo.naturalWidth;
            var ctxMundo = canvasMundo.getContext('2d');
            ctxMundo.drawImage(imgMundo, 0, 0, canvasMundo.width, canvasMundo.height)
            var dataMundo = canvasMundo.toDataURL();


            //Se guarda la url de la foto de perfil
            var img_path = this.person.rounded_photo_url
            if (img_path != null) {
                img_path = "/blank_profile_photo.png"
                var foto_id = 'photo'
            } else {
                var foto_id = 'pfp'
            }

            var imgPhoto = document.getElementById(foto_id);

            if (imgPhoto.height == 0) {
                this.$buefy.dialog.alert({
                    message: 'Ocurrio un error con la foto de perfil, el curriculum largo se descargó con una foto predeterminada.',
                    type: 'is-warning',
                    hasIcon: true,
                    icon: 'exclamation-triangle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
            }


            doc.addImage(this.imageRounded, 'PNG', 10.6, 17.4, 55, 55);

            doc.autoTable({
                startY: 10,
                theme: 'plain',
                didDrawCell: (data) => {
                },
                columnStyles: { 0: { halign: 'left', font: 'BrandonGrotesque-Black', fontStyle: 'normal', fontSize: 18, textColor: [33, 57, 103] } },
                margin: { top: 96.75, left: 5 + 76.4, right: 56.35 },
                body: [
                    [title],
                ],
            })
            //Agregar titulo y cambiar variables de link, biografia y h index
            var start = 22.84
            if (doc.previousAutoTable.finalY > 22.84) {
                start = doc.previousAutoTable.finalY
            }

            if (biography != '') {
                doc.autoTable({
                    startY: start,
                    theme: 'plain',
                    didDrawCell: (data) => {
                    },
                    columnStyles: { 0: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: 11, textColor: [33, 57, 103] } },
                    margin: { top: 96.75, left: 5 + 76.4, right: 14.93 },
                    body: [
                        [biography],
                    ],
                })
                start = doc.previousAutoTable.finalY
            }

            //Se agrega linea divisora
            var canvasLine = document.createElement('canvas');
            var imgLine = document.getElementById('linea');
            canvasLine.height = imgLine.naturalHeight;
            canvasLine.width = imgLine.naturalWidth;
            var ctxLine = canvasLine.getContext('2d');
            ctxLine.drawImage(imgLine, 0, 0, canvasLine.width, canvasLine.height)
            var dataLine = canvasLine.toDataURL();

            if (this.person_studies != null) {
                //Tabla con todos los estudios de la persona
                var font_size = 11.5
                doc.autoTable({
                    startY: start,
                    theme: 'plain',
                    columns: [
                        { dataKey: 'fecha_egreso', header: '' },
                        { dataKey: 'nombre_carr', header: '' },
                        { dataKey: 'institucion_full', header: '' },
                    ],
                    headStyles: { halign: 'left', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 15, textColor: [33, 57, 103] },
                    head: [['Estudios']],
                    columnStyles: {
                        0: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: font_size, textColor: [33, 57, 103], cellWidth: 24 },
                        1: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: font_size, textColor: [0, 0, 0] },
                        2: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: font_size, textColor: [0, 0, 0], cellWidth: 40 }
                    },
                    margin: { top: 29, left: 5 + 76.4, right: 14.93 },
                    body: this.person_studies
                })

                if (this.person_functions != null) {
                    doc.autoTable({
                        startY: doc.previousAutoTable.finalY,
                        theme: 'plain',
                        didDrawCell: (data) => {
                            if (data.section === 'body' && data.row.index === 0) {
                                doc.addImage(dataLine, 'PNG', data.cell.x, data.cell.y + 3.5, 120, 0.6)
                            }
                        },
                        columnStyles: { 0: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: 11.5, textColor: [33, 57, 103] } },
                        margin: { top: 29, left: 5 + 76.4, right: 14.93 },
                        body: [
                            [{ content: '', styles: { halign: 'left', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 11.5, textColor: [33, 57, 103] } }],
                        ]
                    })
                }

                start = doc.previousAutoTable.finalY


            }

            if (this.person_functions != null) {
                //Tabla con todos las experiencias de la persona
                var font_size = 11.5

                //Tabla con los datos basicos de las persona
                doc.autoTable({
                    startY: start,
                    theme: 'plain',
                    columnStyles: { 0: { halign: 'left', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 15, textColor: [33, 57, 103] } },
                    margin: { top: 29, left: 5 + 76.4, right: 14.93 },
                    body: [['Experiencia Académica Administrativa']],
                })

                doc.autoTable({
                    startY: doc.previousAutoTable.finalY,
                    theme: 'plain',
                    columns: [
                        { dataKey: 'fecha', header: '' },
                        { dataKey: 'cargo', header: '' },
                        { dataKey: 'institucion', header: '' },
                    ],
                    columnStyles: {
                        0: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: font_size, textColor: [33, 57, 103], cellWidth: 24 },
                        1: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: font_size, textColor: [0, 0, 0] },
                        2: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: font_size, textColor: [0, 0, 0], cellWidth: 40 }
                    },
                    margin: { top: 29, left: 5 + 76.4, right: 14.93 },
                    body: this.person_functions
                })
                start = doc.previousAutoTable.finalY
            }

            if (doc.internal.getNumberOfPages() > 1) {
                doc.setPage(1)
                doc.deletePage(2)
            }

            //Tabla con los datos basicos de las persona
            doc.autoTable({
                startY: 78.5,
                theme: 'plain',
                columnStyles: { 0: { halign: 'center', font: 'BrandonGrotesque-Black', fontStyle: 'normal', fontSize: 18, textColor: [255, 255, 255] } },
                margin: { top: 0, left: 0, right: 139.5 },
                body: [[this.person.person_name_curriculum]],
            })

            //Tabla con el mail de la persona
            doc.autoTable({
                startY: doc.previousAutoTable.finalY,
                theme: 'plain',
                didDrawCell: (data) => {
                    if (data.section === 'body' && data.row.index === 0) {
                        if (data.column.index === 0) {
                            doc.setFillColor('#FFFFFF')
                            doc.circle(data.cell.x + 3.75 + 5, data.cell.y + 3.75, 3.75, 'F');
                            doc.addImage(dataMail, 'JPEG', data.cell.x + 1 + 5, data.cell.y + 1, 5.5, 5.5)
                        }
                    }
                },
                columnStyles: {
                    1: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: 12.5, textColor: [255, 255, 255] },
                    0: { halign: 'right', cellWidth: 12 }
                },
                margin: { top: 78.1, left: 0, right: 140 },
                body: [[[], [this.person.corporate_mail]]],
            })
            var start_datos_basicos = doc.previousAutoTable.finalY

            //Tabla con los datos del ORCID
            if (this.person.orcid != null) {
                doc.autoTable({
                    startY: start_datos_basicos,
                    theme: 'plain',
                    didDrawCell: (data) => {
                        if (data.section === 'body' && data.row.index === 0) {
                            if (data.column.index === 0) {
                                doc.setFillColor('#FFFFFF')
                                doc.circle(data.cell.x + 3.75 + 5, data.cell.y + 3.75, 3.75, 'F');
                                doc.setTextColor(0, 57, 99)
                                doc.setFont("BrandonGrotesque-Bold", "normal");
                                doc.setFontSize(12.5)
                                doc.text('ID', data.cell.x + 1.5 + 5, data.cell.y + 5.5)
                            }
                        }
                    },
                    columnStyles: {
                        1: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: 12.5, textColor: [255, 255, 255] },
                        0: { halign: 'right', cellWidth: 12 }
                    },
                    margin: { top: 78.1, left: 0, right: 140 },
                    body: [[[], [this.person.orcid]]],
                })
                start_datos_basicos = doc.previousAutoTable.finalY
            }

            //Tabla con los datos del h-index de la persona
            if (this.person.h_index != null) {
                doc.autoTable({
                    startY: start_datos_basicos,
                    theme: 'plain',
                    didDrawCell: (data) => {
                        if (data.section === 'body' && data.row.index === 0) {
                            if (data.column.index === 0) {
                                doc.setFillColor('#FFFFFF')
                                doc.circle(data.cell.x + 3.75 + 5, data.cell.y + 3.75, 3.75, 'F');
                                doc.setTextColor(0, 57, 99)
                                doc.setFontSize(20)
                                doc.setFont("BrandonGrotesque-Bold", "normal");
                                doc.text('#', data.cell.x + 1.8 + 5, data.cell.y + 6.3)
                            }
                        }
                    },
                    columnStyles: {
                        1: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: 12.5, textColor: [255, 255, 255] },
                        0: { halign: 'right', cellWidth: 12 }
                    },
                    margin: { top: 78.1, left: 0, right: 140 },
                    body: [[[], ['h-index: ' + this.person.h_index]]],
                })
                start_datos_basicos = doc.previousAutoTable.finalY
            }

            //Tabla con los datos del link de la persona
            if (this.person.link != null) {
                doc.autoTable({
                    startY: start_datos_basicos,
                    theme: 'plain',
                    didDrawCell: (data) => {
                        if (data.section === 'body' && data.row.index === 0) {
                            if (data.column.index === 0) {
                                doc.setFillColor('#FFFFFF')
                                doc.circle(data.cell.x + 3.75 + 5, data.cell.y + 3.75, 3.75, 'F');
                                doc.setTextColor(0, 57, 99)
                                doc.addImage(dataMundo, 'JPEG', data.cell.x + 1 + 5, data.cell.y + 1, 5.5, 5.5)
                            }
                            else if (data.column.index === 1) {
                                doc.setTextColor(255, 255, 255)
                                doc.setFontSize(12.5)
                                var texto = doc.splitTextToSize(this.person.link, 60)

                                var x = 1.75
                                var y = 5.5

                                texto.forEach(element => {
                                    doc.textWithLink(element, data.cell.x + x, data.cell.y + y, { url: this.person.link })
                                    y = y + 5.3
                                })

                            }
                        }
                    },
                    columnStyles: {
                        1: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: 12.5, textColor: [255, 255, 255] },
                        0: { halign: 'right', cellWidth: 12 }
                    },
                    margin: { top: 78.1, left: 0, right: 140.5 },
                    body: [[[], []]],
                })
                start_datos_basicos = doc.previousAutoTable.finalY
            }


            var person_unit = 'SIN INFORMACION'

            if (this.person.person_unit_name != null || this.person.person_unit_name != undefined) {
                person_unit = this.person.person_unit_name
            }

            var resta = 60
            if (expertice == '') {
                resta = 20
            }

            //Tabla con la unidad
            doc.autoTable({
                startY: doc.internal.pageSize.getHeight() - resta,
                theme: 'plain',
                columnStyles: { 0: { halign: 'center', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 12.5, textColor: [255, 255, 255] } },
                margin: { left: 0, right: 139.5, bottom: 0 },
                body: [
                    [{ content: person_unit, styles: { halign: 'center', font: 'BrandonGrotesque-Bold', fillColor: [33, 57, 103], fontStyle: 'regular', fontSize: 10.5, textColor: [255, 255, 255] } }],
                ]
            })
            //Tabla con los datos de las keywords
            if (expertice != '') {
                doc.autoTable({
                    startY: doc.previousAutoTable.finalY,
                    theme: 'plain',
                    columnStyles: { 0: { halign: 'center', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 12.5, textColor: [255, 255, 255] } },
                    margin: { left: 0, right: 139.5, bottom: 0 },
                    body: [
                        ['EXPERTIZ'],
                        [{ content: expertice, styles: { halign: 'center', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: 10.5, textColor: [255, 255, 255] } }],
                    ]
                })
            }

            //console.log(doc.internal.pageSize.getHeight())


            //Tabla con todos los proyectos de la persona
            if (doc.internal.getNumberOfPages() > 1) {
                doc.setPage(1)
                doc.deletePage(2)
            }

            if (this.reference_project.length > 0 || this.reference_publication.length > 0 || this.reference_patent.length > 0 || this.reference_book.length > 0) {
                doc.addPage()
            }


            var font_size = 11.5

            var start_page_data = 0


            //Tabla con todos los proyectos en formato referencia
            if (this.reference_project.length > 0) {
                start_page_data = 35
                doc.autoTable({
                    startY: start_page_data,
                    theme: 'plain',
                    showHead: 'firstPage',
                    columns: [
                        { dataKey: 'ref_name', header: '' },
                    ],
                    headStyles: { halign: 'left', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 15, textColor: [33, 57, 103] },
                    head: [['Proyectos']],
                    columnStyles: { 0: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: font_size, textColor: [33, 57, 103] } },
                    margin: { top: 29, left: 14.93, right: 14.93 },
                    body: this.reference_project
                })

                //Se agrega linea divisora

                if (this.reference_publication.length > 0 || this.reference_patent.length > 0) {
                    doc.autoTable({
                        startY: doc.previousAutoTable.finalY,
                        theme: 'plain',
                        didDrawCell: (data) => {
                            if (data.section === 'body' && data.row.index === 0) {
                                doc.addImage(dataLine, 'PNG', data.cell.x, data.cell.y + 3.5, 186, 0.6)
                            }
                        },
                        columnStyles: { 0: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: 11.5, textColor: [33, 57, 103] } },
                        margin: { top: 29, left: 14.93, right: 14.93 },
                        body: [
                            [{ content: '', styles: { halign: 'left', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 11.5, textColor: [33, 57, 103] } }],
                        ]
                    })
                }


            }


            //Tabla con todas las publicaciones en formato referencia
            if (this.reference_publication.length > 0) {
                if (start_page_data == 0) {
                    start_page_data = 35
                }
                else {
                    start_page_data = doc.previousAutoTable.finalY
                }

                doc.autoTable({
                    startY: start_page_data,
                    theme: 'plain',
                    showHead: 'firstPage',
                    columns: [
                        { dataKey: 'ref_name', header: '' },
                    ],
                    headStyles: { halign: 'left', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 15, textColor: [33, 57, 103] },
                    head: [['Publicaciones']],
                    columnStyles: { 0: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: font_size, textColor: [33, 57, 103] } },
                    margin: { top: 29, left: 14.93, right: 14.93 },
                    body: []
                })

                start_page_data = doc.previousAutoTable.finalY


                if (this.reference_publication_journal.length > 0) {
                    doc.autoTable({
                        startY: start_page_data,
                        theme: 'plain',
                        showHead: 'firstPage',
                        columns: [
                            { dataKey: 'ref_name', header: '' },
                        ],
                        headStyles: { halign: 'left', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 12, textColor: [33, 57, 103] },
                        head: [['Journal Articles']],
                        columnStyles: { 0: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: font_size, textColor: [33, 57, 103] } },
                        margin: { top: 29, left: 14.93, right: 14.93 },
                        body: this.reference_publication_journal
                    })
                    start_page_data = doc.previousAutoTable.finalY
                }

                if (this.reference_publication_book.length > 0) {
                    doc.autoTable({
                        startY: start_page_data,
                        theme: 'plain',
                        showHead: 'firstPage',
                        columns: [
                            { dataKey: 'ref_name', header: '' },
                        ],
                        headStyles: { halign: 'left', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 12, textColor: [33, 57, 103] },
                        head: [['Books']],
                        columnStyles: { 0: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: font_size, textColor: [33, 57, 103] } },
                        margin: { top: 29, left: 14.93, right: 14.93 },
                        body: this.reference_publication_book
                    })
                    start_page_data = doc.previousAutoTable.finalY
                }
                if (this.reference_publication_inbook.length > 0) {
                    doc.autoTable({
                        startY: start_page_data,
                        theme: 'plain',
                        showHead: 'firstPage',
                        columns: [
                            { dataKey: 'ref_name', header: '' },
                        ],
                        headStyles: { halign: 'left', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 12, textColor: [33, 57, 103] },
                        head: [['Book Chapters']],
                        columnStyles: { 0: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: font_size, textColor: [33, 57, 103] } },
                        margin: { top: 29, left: 14.93, right: 14.93 },
                        body: this.reference_publication_inbook
                    })
                    start_page_data = doc.previousAutoTable.finalY
                }
                if (this.reference_publication_conference.length > 0) {
                    doc.autoTable({
                        startY: start_page_data,
                        theme: 'plain',
                        showHead: 'firstPage',
                        columns: [
                            { dataKey: 'ref_name', header: '' },
                        ],
                        headStyles: { halign: 'left', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 12, textColor: [33, 57, 103] },
                        head: [['Conference Articles']],
                        columnStyles: { 0: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: font_size, textColor: [33, 57, 103] } },
                        margin: { top: 29, left: 14.93, right: 14.93 },
                        body: this.reference_publication_conference
                    })
                    start_page_data = doc.previousAutoTable.finalY
                }
                if (this.reference_publication_otros.length > 0) {
                    doc.autoTable({
                        startY: start_page_data,
                        theme: 'plain',
                        showHead: 'firstPage',
                        columns: [
                            { dataKey: 'ref_name', header: '' },
                        ],
                        headStyles: { halign: 'left', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 12, textColor: [33, 57, 103] },
                        head: [['Otros']],
                        columnStyles: { 0: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: font_size, textColor: [33, 57, 103] } },
                        margin: { top: 29, left: 14.93, right: 14.93 },
                        body: this.reference_publication_otros
                    })
                }


                //Se agrega linea divisora
                if (this.reference_patent.length > 0) {
                    doc.autoTable({
                        startY: doc.previousAutoTable.finalY,
                        theme: 'plain',
                        didDrawCell: (data) => {
                            if (data.section === 'body' && data.row.index === 0) {
                                doc.addImage(dataLine, 'PNG', data.cell.x, data.cell.y + 3.5, 186, 0.4)
                            }
                        },
                        columnStyles: { 0: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: 11.5, textColor: [33, 57, 103] } },
                        margin: { top: 29, left: 14.93, right: 14.93 },
                        body: [
                            [{ content: '', styles: { halign: 'left', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 11.5, textColor: [33, 57, 103] } }],
                        ]
                    })
                }
            }


            if (this.reference_inbook.length > 0) {
                if (start_page_data == 0) {
                    start_page_data = 35
                }
                else {
                    start_page_data = doc.previousAutoTable.finalY
                }

                doc.autoTable({
                    startY: start_page_data,
                    theme: 'plain',
                    showHead: 'firstPage',
                    columns: [
                        { dataKey: 'ref_name', header: '' },
                    ],
                    headStyles: { halign: 'left', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 15, textColor: [33, 57, 103] },
                    head: [['Artículos y Publicaciones en Libros']],
                    columnStyles: { 0: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: font_size, textColor: [33, 57, 103] } },
                    margin: { top: 29, left: 14.93, right: 14.93 },
                    body: []
                })

                start_page_data = doc.previousAutoTable.finalY


                if (this.reference_inbook_charapter.length > 0) {
                    doc.autoTable({
                        startY: start_page_data,
                        theme: 'plain',
                        showHead: 'firstPage',
                        columns: [
                            { dataKey: 'ref_name', header: '' },
                        ],
                        headStyles: { halign: 'left', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 12, textColor: [33, 57, 103] },
                        head: [['Capítulos de Libro']],
                        columnStyles: { 0: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: font_size, textColor: [33, 57, 103] } },
                        margin: { top: 29, left: 14.93, right: 14.93 },
                        body: this.reference_inbook_charapter
                    })
                    start_page_data = doc.previousAutoTable.finalY
                }

                if (this.reference_inbook_article.length > 0) {
                    doc.autoTable({
                        startY: start_page_data,
                        theme: 'plain',
                        showHead: 'firstPage',
                        columns: [
                            { dataKey: 'ref_name', header: '' },
                        ],
                        headStyles: { halign: 'left', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 12, textColor: [33, 57, 103] },
                        head: [['Artículos de Libros']],
                        columnStyles: { 0: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: font_size, textColor: [33, 57, 103] } },
                        margin: { top: 29, left: 14.93, right: 14.93 },
                        body: this.reference_inbook_article
                    })
                    start_page_data = doc.previousAutoTable.finalY
                }

            }



            // Tabla con lodos los libros en formato referencia
            if (this.reference_book.length > 0) {
                if (start_page_data == 0) {
                    start_page_data = 35
                }
                else {
                    start_page_data = doc.previousAutoTable.finalY
                }

                doc.autoTable({
                    startY: start_page_data,
                    theme: 'plain',
                    showHead: 'firstPage',
                    columns: [
                        { dataKey: 'ref_name', header: '' },
                    ],
                    headStyles: { halign: 'left', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 15, textColor: [33, 57, 103] },
                    head: [['Libros']],
                    columnStyles: { 0: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: font_size, textColor: [33, 57, 103] } },
                    margin: { top: 29, left: 14.93, right: 14.93 },
                    body: this.reference_book
                })

                start_page_data = doc.previousAutoTable.finalY
            }



            //Tabla con todas las patentes en formato referencia
            if (this.reference_patent.length > 0) {
                if (start_page_data == 0) {
                    start_page_data = 35
                }
                else {
                    start_page_data = doc.previousAutoTable.finalY
                }
                doc.autoTable({
                    startY: doc.previousAutoTable.finalY,
                    theme: 'plain',
                    showHead: 'firstPage',
                    columns: [
                        { dataKey: 'ref_name', header: '' },
                    ],
                    headStyles: { halign: 'left', font: 'BrandonGrotesque-Bold', fontStyle: 'normal', fontSize: 15, textColor: [33, 57, 103] },
                    head: [['Patentes']],
                    columnStyles: { 0: { halign: 'left', font: 'BrandonGrotesque-Medium', fontStyle: 'normal', fontSize: font_size, textColor: [33, 57, 103] } },
                    margin: { top: 29, left: 14.93, right: 14.93 },
                    body: this.reference_patent
                })
            }


            for (var i = 0; i < doc.internal.getNumberOfPages(); i++) {
                if (i != 1) {
                    doc.setPage(i)
                    doc.addImage(dataLogo, "PNG", 158.35, 10, 43.42, 10.84);
                    //doc.addImage(dataLogo, "PNG", 147.5, 14.93, 54.27, 13.55);
                    //Circulos parte del diseno del curriculum de ejemplo
                    doc.setFillColor('#213967')
                    doc.circle(160.65 + 8, 269.25, 3.75, 'F');

                    doc.setFillColor('#275d9d')
                    doc.circle(170.15 + 8, 269.25, 3.75, 'F');

                    doc.setFillColor('#b10f6d')
                    doc.circle(179.65 + 8, 269.25, 3.75, 'F');

                    doc.setFillColor('#b93ca5')
                    doc.circle(189.15 + 8, 269.25, 3.75, 'F');
                }


            }

            this.reference_publication = []
            this.reference_publication_book = []
            this.reference_publication_inbook = []
            this.reference_publication_otros =  []
            this.reference_publication_journal = []
            this.reference_publication_conference = []
            this.reference_project = []
            this.reference_patent = []
            this.reference_disclosure = []
            this.reference_book =  []
            this.reference_inbook = []

            this.isLoading = false
            doc.save('curriculum-largo.pdf');
            //window.location.href = "/people/"+this.personId+"/export_short_curriculum.pdf"
        },

        async loadImage() {
            try {
                this.isLoading = true
                setTimeout(() => {
                    //Metodo para hacer redonda la foto de perfil
                    var c = document.createElement('canvas');
                    var img = document.getElementById('pfp')
                    if (this.person.photo_url != null) {
                        img = document.getElementById('photo');
                    }
                    //var img = document.createElement('img');
                    //img.src = img_path

                    if (img.height == 0) {
                        img = document.getElementById('pfp')
                    }

                    c.height = 805;
                    c.width = 805;
                    var ctx = c.getContext('2d');
                    ctx.save();
                    ctx.beginPath();
                    ctx.arc(402.5, 402.5, 402.5, 0, Math.PI * 2, true);
                    ctx.closePath();
                    ctx.clip();
                    ctx.drawImage(img, 0, 0, 805, 805);
                    ctx.beginPath();
                    ctx.arc(0, 0, 402.5, 0, Math.PI * 2, true);
                    ctx.clip();
                    ctx.closePath();
                    ctx.restore();
                    this.imageRounded = c.toDataURL();
                }, 2000);
                setTimeout(() => {
                    this.isLoading = false
                }, 3000);
            }
            catch (err) {
                console.log('Errores:')
            }
        },

    }
}
</script>