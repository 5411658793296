<template>
  <div class="AgreementShowView">
    <section>
      <div class="box">
        <div class="container">
          <b-tabs position="is-centered" @input="changeTab">
            <b-tab-item v-for="item in items"
                        :value="item.content"
                        v-bind:key="item.content"
                        :label="item.tab"
                        :icon="item.icon">
            </b-tab-item>
          </b-tabs>
          <component v-bind:is="currentTabComponent"
                     :agreement='this.agreement'
                     :academics='this.agreement.academics'
                     :agreement_id="this.agreement_id"
                     :entities='this.agreement.partner_entities'
                     :agreement_history_found='this.agreement_history_found'
                     :agreement_history='this.agreement_history'
                     :can_create_history="this.can_create_history"
                     :agreement_type_general = "this.general"
                     :agreement_type_specific = "this.especif"
                     :entidades="this.entidades"
                     :can_edit="this.can_edit">
          </component>
          <div class="field is-grouped is-grouped-left">
            <div class="control">
              <button class="button is-link is-light" @click="back()">Volver</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>
<script>
/* importaciones generales */ 
import axios from "axios"
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

/* importaciones de componentes */
import AgreementGeneralInformation from "./agreement_general_information.vue"
import AgreementAcademics from "./agreement_academics.vue" 
import AgrementHistory from "./agreement_history.vue"
import AgreementPartnerEntities from './agreement_partner_entities.vue'

export default {
    name: 'AgreementShowView',
    components:{
        AgreementGeneralInformation,
        AgrementHistory,
        AgreementPartnerEntities,
        AgreementAcademics
    },
    data (){
        return {
            agreement_history_found: false,
            agreement_history: [],
            agreement: {},
            partner_entities: [],
            isFullPage: true,
            isLoading: true,
            ShowViewToShow: 1,
            can_edit: false,
            can_create_history: false,
            especif: null,
            general: null,
            entidades:'',
            currentTab: 'AgreementGeneralInformation',
          items:[
            {tab:'Información general', content:'AgreementGeneralInformation', icon:'info-circle'},
            {tab:'Participantes', content: 'AgreementAcademics', icon: 'address-card'},
            {tab:'Entidades relacionadas', content: 'AgreementPartnerEntities', icon: 'industry'},
            /*{tab:'Historial del convenio', content: 'AgrementHistory', icon: 'history'}*/
          ]
        }
    },
    computed:{
      currentTabComponent: function () {
        return this.currentTab
      }
    },
    props:{
        agreement_id:{
            type: Number
        }
    },
    methods: {
        changeTab: function(value){
          this.currentTab = value
        },
       async getAgreement(){
            axios.get('/agreements/'+this.agreement_id+'.json')
            .then(response =>{
                this.agreement = response.data
                this.can_edit = response.data.can_edit
              this.especif = this.agreement.agreement_type.agreement_general_type
              this.general = this.agreement.agreement_type.agreement_specifict_type
              this.agreement.partner_entities.forEach(entidad =>{
                if (this.entidades == ''){
                  this.entidades = entidad.entity_name + "\n"

                }
                else{
                  this.entidades = this.entidades + "\n" + entidad.entity_name + "\n"
                }
              })
            })
            .catch(e => {
              console.log(e)
              this.isLoading = false
            })
        },
       async getAgreementHistory(){
            axios.get('/agreement_histories/getAgreementHistoryByAgreementId/'+this.agreement_id)
            .then(response =>{
                this.agreement_history = response.data.agreement_history
                this.can_create_history = response.data.can_create_history
                this.agreement_history_found = true
                this.isLoading=false
            })
            .catch(e => { 
                console.log(e)
                this.isLoading=false
            })
        },
        getPartnerEntityNameById(id){
            var name = ''
            this.partner_entities.forEach(function (element){
                if(element.id == id){
                    name = element.name
                }
            })
            return name
        },
        dateFormater(date){
            if(date != null){
                var newDate = ""
                var localDate = new Date(date).toLocaleDateString().toString();
                for(var i =0; i<localDate.length; i++){
                    if(localDate[i] === '-'){
                        newDate = newDate.concat('/')
                    }
                    else{
                        newDate = newDate.concat(localDate.charAt(i))
                    }
                }
                return newDate
            }
            else{
                return "No se ha encontrado registro"
            }
        },
        sectionsDateFormater(section){
            if(typeof section != 'undefined'){
                for(var i=0; i<section.length; i++){
                    section[i].registration_date = this.dateFormater(section[i].registration_date);
                }
            }
        },
        amountFormater(amount){
            var aux         = amount.toString()
            var aux_list    = []
            var aux_list2   = []
            var newAmount   = ""
            for(var i = 0; i<aux.length; i++){
                aux_list.push(String(aux[i]))
            }
            aux_list    = aux_list.reverse()
            for(var i=0; i<aux_list.length; i++){
                if(i%3 === 0 && i != 0){
                    aux_list2.push('.')
                    aux_list2.push(aux_list[i])
                }
                else{
                    aux_list2.push(aux_list[i])
                }
            } 
            aux_list2.reverse()
            for(var i=0; i<aux_list2.length; i++){
                newAmount = newAmount.concat(aux_list2[i])
            }
            return newAmount
        },
        editAgreement(){
            window.location.href="/agreements/"+this.agreement_id+"/edit/0"
        },
        downloadReport(){
            // Declaración del PDF
            var doc = new jsPDF('p','pt');
            doc.setFillColor(253, 126, 20);
            doc.rect(0, 0, 600, 10, "F");
            doc.rect(0, 832, 600, 10, "F");
            doc.addImage("/logo_negro.png", "JPEG", 20, 20, 150, 80);
            /*doc.text("Universidad de Santiago de Chile", 200, 40);
            doc.text("Facultad de ingeniería", 240, 60);
            doc.text("Vicerrectoría de vinculación con el medio", 180, 80);*/
            
            // Título del convenio
            doc.setFont("times new roman", "bold");
            doc.text(this.agreement.name, 40, 130);
            doc.setFont("times new roman", "normal");

            // Información general
            doc.autoTable({
                head: [
                    [
                        {
                            content: 'Información general del convenio',
                            colSpan: 2,
                            styles:{
                                halign: 'center',
                                fillColor: [253, 126, 20]
                            }
                        }
                    ]
                ],
                body: [
                    ['Descripción', this.agreement.description],
                    ['Glosa de servicio', this.agreement.service_gloss],
                    ['Monto', '$'+this.amountFormater(this.agreement.amount)+' CLP'],
                    ['Etapa del convenio', this.agreement.agreement_stage],
                    ['Estado del convenio', this.agreement.agreement_state],
                    ['Encargado actual', this.agreement.current_manager],
                    //['Fecha de creación', this.dateFormater(this.agreement.creation_date)],
                    ['Fecha de inicio', this.agreement.start_date],
                    ['Fecha de término', this.agreement.end_date],
                    ['Fecha de resolución',this.agreement.creation_date],
                    ['Tipo de resolución',this.agreement.resolution_type],
                    ['N° de resolución',this.agreement.resolution_number],
                    ['Link',this.agreement.link]

                ],
                margin: { top: 170 }
            })

            // Entidades relacionadas
            doc.addPage();
            doc.setFillColor(253, 126, 20);
            doc.rect(0, 0, 600, 10, "F");
            doc.rect(0, 832, 600, 10, "F");
            doc.addImage("/logo_negro.png", "JPEG", 20, 20, 150, 80);
            doc.setFont("times new roman", "bold");
            doc.text("Entidades relacionadas", 220, 130);
            doc.setFont("times new roman", "normal");
            var columnsEntitiesRelatedNames = [
                {title: 'Entidad', dataKey: 'entity_name'},
                {title: 'Representante', dataKey: 'applicant'},
                {title: 'Email', dataKey: 'applicant_email'},
                {title: 'Teléfono', dataKey: 'applicant_phone'}
            ]

            var agreement_entities_data = []
            this.agreement.partner_entities.forEach(function(element){
                agreement_entities_data.push({
                    entity_name: element.partner_entity_data.name,
                    applicant: element.applicant,
                    applicant_email: element.applicant_email,
                    applicant_phone: element.applicant_phone
                })
            })

            doc.autoTable(columnsEntitiesRelatedNames, agreement_entities_data, {
                headStyles:{
                    //halign: 'center',
                    fillColor: [253, 126, 20],
                },
                margin: { top: 160 },
                didDrawPage: function (data) { 
                    doc.setFillColor(253, 126, 20);
                    doc.rect(0, 0, 600, 10, "F");
                    doc.rect(0, 832, 600, 10, "F");
                    doc.addImage("/logo_negro.png", "JPEG", 20, 20, 150, 80);
                    doc.setFont("times new roman", "bold");
                    doc.text("Entidades relacionadas", 220, 130);
                    doc.setFont("times new roman", "normal");
                }
            })

            // Académicos relacionados
            doc.addPage();
            doc.setFillColor(253, 126, 20);
            doc.rect(0, 0, 600, 10, "F");
            doc.rect(0, 832, 600, 10, "F");
            doc.addImage("/logo_negro.png", "JPEG", 20, 20, 150, 80);
            doc.setFont("times new roman", "bold");
            doc.text("Académicos relacionados", 220, 130);
            doc.setFont("times new roman", "normal");
            var columnsAcademicsRelatedNames = [
                {title: 'Académico/a', dataKey: 'academic_name'},
                {title: 'Departamento', dataKey: 'dpto_name'}
            ]

            var agreement_academics_data = []
            this.agreement.academics.forEach(function(element){
                agreement_academics_data.push({
                    academic_name: element.academic_name,
                    dpto_name: element.dpto_name
                })
            })

            doc.autoTable(columnsAcademicsRelatedNames, agreement_academics_data, {
                headStyles:{
                    //halign: 'center',
                    fillColor: [253, 126, 20],
                },
                margin: { top: 160 },
                didDrawPage: function (data) { 
                    doc.setFillColor(253, 126, 20);
                    doc.rect(0, 0, 600, 10, "F");
                    doc.rect(0, 832, 600, 10, "F");
                    doc.addImage("/logo_negro.png", "JPEG", 20, 20, 150, 80);
                    doc.setFont("times new roman", "bold");
                    doc.text("Académicos relacionados", 220, 130);
                    doc.setFont("times new roman", "normal");
                }
            })

            // Historial
            // Encabezados tabla historial
            var columnsNames = [
                {title: 'Fecha', dataKey: 'date'},
                {title: 'Proceso', dataKey: 'process'},
                {title: 'Actor', dataKey: 'actor'},
                {title: 'Notas', dataKey: 'notes'}
            ]


            this.agreement_history.forEach( function(element){
                doc.addPage();
                //sectionsDateFormater(element.agreement_stage_process);
                var agreement_stage_data = []
                element.agreement_stage_process.forEach( function(process_element){
                    agreement_stage_data.push({
                        date: process_element.registration_date,
                        process: process_element.agreement_process_name,
                        actor: process_element.agreement_actor_name,
                        notes: process_element.notes
                    })
                })
                if(Object.entries(element.agreement_stage_process).length === 0){
                    doc.text("No hay registros de esta sección", 50, 190);
                    doc.setFillColor(253, 126, 20);
                    doc.rect(0, 0, 600, 10, "F");
                    doc.rect(0, 832, 600, 10, "F");
                    doc.addImage("/logo_negro.png", "JPEG", 20, 20, 150, 80);
                    doc.setFont("times new roman", "bold");
                    doc.text("Historial del convenio", 220, 130);
                    doc.setFont("times new roman", "normal");
                    doc.text(element.agreement_stage_name, 30, 160);
                }
                else{
                    doc.autoTable(columnsNames, agreement_stage_data, {
                        headStyles:{
                            //halign: 'center',
                            fillColor: [253, 126, 20],
                        },
                        margin: { top: 180 },
                        didDrawPage: function (data) { 
                            doc.setFillColor(253, 126, 20);
                            doc.rect(0, 0, 600, 10, "F");
                            doc.rect(0, 832, 600, 10, "F");
                            doc.addImage("/logo_negro.png", "JPEG", 20, 20, 150, 80);
                            doc.setFont("times new roman", "bold");
                            doc.text("Historial del convenio", 220, 130);
                            doc.setFont("times new roman", "normal");
                            doc.text(element.agreement_stage_name, 30, 160);
                        }
                    })
                }
            })

            // Descarga
            doc.save(this.agreement.name+'.pdf');
        },
        back(){
            this.isLoading=true
            window.location.href="/agreements"
        }
    },
    async beforeMount() {
      this.isLoading = true
       await this.getAgreement()
       await this.getAgreementHistory()
    }
    
}
</script>