<template>
  <nav class="breadcrumb" aria-label="breadcrumbs">
    <ul>
      <li
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="index"
        :class="{ 'is-active': breadcrumb.isActive }"
      >
        <a
          :href="breadcrumb.href"
          :aria-current="breadcrumb.isActive ? 'page' : null"
          >{{ breadcrumb.label }}</a
        >
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [{ href: "/", label: "Inicio", isActive: true }],
    },
  },
};
</script>
