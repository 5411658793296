<template>
  <section>
    <div class="container">
      <h1 class="is-size-2">Publicaciones</h1>
      <div class="has-text-right">
        <b-button type="is-info"
                  icon-left="plus"
                  v-if="can_create==='true'"
                  @click="newPublication()">
          Añadir Publicación
        </b-button>
      </div>
      <br>
      <publications-table v-bind:publicaciones="this.publicaciones" :cargoPrincipal="cargoPrincipal" @updatePublications="updatePublications"></publications-table>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import axios from "axios";
import PublicationsTable from "./publications/publications_table.vue";
import moment from "moment";

export default {
  components:{PublicationsTable},
  data(){
    return{
      isLoading:false,
      currentPage:1,
      publicaciones:[],
      year: new Date().getFullYear(),

    }
  },
  created(){
    this.getPublications(this.year);
  },
  props:[
    'can_create', 'cargoPrincipal'
  ],
  methods:{
    newPublication(){
      window.location.href="/publications/new"
    },

    getPublicationtUrl(publication_id){
      return "/publications/"+publication_id;
    },
    getPublications(year){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/publications.json", {params:{year_f:year}})
        .then(res => {
          vm.publicaciones = res.data['publicaciones'];
          vm.publicaciones = vm.publicaciones.map(publicacion => {
            var newPublicacion = publicacion
            newPublicacion["publication_date"] = moment(publicacion.publication_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            return newPublicacion;
          });
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    updatePublications(year){
      this.getPublications(year);
    }

  },
  computed:{

  }
}
</script>

