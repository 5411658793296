<template>
  <section>

    <div v-if="can_create_scholarship === 'true'" class="columns">
      <div class="column has-text-right">
        <b-button type="is-primary" icon-left="plus" label="Agregar Beca de Estudio" @click="newScholarship" />
      </div>
    </div>

    <b-table striped :data="scholarships" :paginated=true per-page=10 pagination-position="bottom"
      aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
      aria-current-label="Página actual">

      <b-table-column label="Carácter de la Beca" field='name' sortable searchable>
        <template #searchable="props">
          <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Carácter..." icon="search" />
        </template>
        <template v-slot="props">
          {{ props.row.name }}
        </template>
      </b-table-column>

      <b-table-column label="Organismo Otorgante" field='partner_entity_name' sortable searchable>
        <template #searchable="props">
          <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Organismo..." icon="search" />
        </template>
        <template v-slot="props">
          {{ props.row.partner_entity_name }}
        </template>
      </b-table-column>

      <b-table-column label="Motivo del Estudio" field='study' sortable searchable>
        <template #searchable="props">
          <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Estudios..." icon="search" />
        </template>
        <template v-slot="props">
          {{ props.row.study }}
        </template>
      </b-table-column>

      <b-table-column label="Institución" field='institution_name' sortable searchable>
        <template #searchable="props">
          <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Institución..." icon="search" />
        </template>
        <template v-slot="props">
          {{ props.row.institution_name }}
        </template>
      </b-table-column>

      <b-table-column label="Fecha de Inicio" field='start_date' sortable searchable :custom-search="searchInitDate">
        <template #searchable="props">
          <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha..." icon="search" />
        </template>
        <template v-slot="props">
          {{ props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL") : '' }}
        </template>
      </b-table-column>

      <b-table-column label="Fecha de Término" field='end_date' sortable searchable :custom-search="searchEndDate">
        <template #searchable="props">
          <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha..." icon="search" />
        </template>
        <template v-slot="props">
          {{ props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL") : '' }}
        </template>
      </b-table-column>

      <b-table-column v-show="cargoPrincipal.name == 'ANALISTA I+D FING' || cargoPrincipal.name == 'SUPERADMIN'"
        label="Acciones" v-slot="props">
        <div class="columns">
            <div v-if="props.row.can_show" class="column">
                <b-tooltip
                    label="Mostrar en detalle la información de la beca"
                    :active="true">
                    <b-button pack="fas" icon-right="eye" type="is-info" @click="isModalShowActive = true; selectedScholarship = props.row"/>
                </b-tooltip>
            </div>
            <div v-if="props.row.can_update" class="column">
                <b-tooltip
                    label="Editar la información de la beca"
                    :active="true">
                    <b-button pack="fas" icon-right="edit" type="is-warning" @click="isModalActive = true; selectedScholarship = props.row; update = true"/>
                </b-tooltip>
            </div>
            <div v-if="props.row.can_null" class="column">
                <b-tooltip
                    label="Anular registro de la beca"
                    :active="true">
                  <b-button pack="fas" icon-right="ban" type="is-danger" @click="nullScholarship(props.row)"/>
                </b-tooltip>
            </div>
        </div>
      </b-table-column>

      <template slot="empty">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message has-icon type="is-warning">
              <h1> No se encontraron resultados.</h1>
            </b-message>
          </div>
        </div>
      </template>

    </b-table>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal v-model="isModalActive" has-modal-card trap-focus :destroy-on-hide="true" width="90%" aria-role="dialog"
      aria-modal :can-cancel="false">
      <template #default="props">
        <modal_new_scholarship :person-id="personId" :update="update" :old_scholarship="selectedScholarship"
          @close="props.close" @refresh="getScholarships()" />
      </template>
    </b-modal>

    <b-modal v-model="isModalShowActive" has-modal-card trap-focus :destroy-on-hide="true" width="90%" aria-role="dialog"
      aria-modal :can-cancel="false">
      <template #default="props">
        <ModalShowScholarShip :person-id="personId" :old_scholarship="selectedScholarship" @close="props.close"
          @refresh="getScholarships()" />
      </template>
    </b-modal>

  </section>
</template>

<script>
import modal_new_scholarship from "./modal_new_scholarship"
import ModalShowScholarShip from "./modal_show_scholarship.vue"
import { searchDate } from "../../packs/utilities"
import axios from "axios"
import moment from "moment"
export default {
  components: {
    modal_new_scholarship, ModalShowScholarShip,
  },
  props: [
    'personId', 'minYear', 'maxYear', 'changeFilter', 'cargoPrincipal', 'can_create_scholarship'
  ],
  data() {
    return {
      isLoading: false,
      scholarships: [],
      isModalActive: false,
      isModalShowActive: false,
      selectedScholarship: {},
      update: false,
    }
  },
  created() {
    this.getScholarships()
  },
  methods: {
    getScholarships() {
      this.isLoading = true
      this.update = false
      axios
        .get('/my-scholarships/' + this.personId + '.json', {
          params: {
            minYear: this.minYear,
            maxYear: this.maxYear,
          }
        })
        .then(response => {
          this.scholarships = response.data
          this.scholarships = this.scholarships.map(scholarship => {
            var newScholarship = scholarship
            newScholarship["start_date"] = moment(newScholarship["start_date"], ["YYYY-MM-DD", "DD-MM-YYYY"]);
            newScholarship["end_date"] = moment(newScholarship["end_date"], ["YYYY-MM-DD", "DD-MM-YYYY"]);
            return newScholarship;
          })
          this.isLoading = false
        })
        .catch(error => {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Hubo un error al traer las Becas de la persona consultada, favor de revisar la consola.',
            type: 'is-danger'
          })
          console.log(error)
          this.isLoading = false
        })
    },
    newScholarship() {
      this.update = false
      this.isModalActive = true
    },
    searchInitDate(row, input) {
      return searchDate(row.start_date, input)
    },
    searchEndDate(row, input) {
      return searchDate(row.end_date, input)
    },
    nullScholarship(scholarship) {

      this.$buefy.dialog.confirm({
        title: 'Anular Beca de Estudio',
        message: '¿Está seguro que desea <b>anular</b> la Beca de Estudio? Esta acción lo dejará inválido.<br>',
        confirmText: 'Anular Beca de Estudio',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          this.isLoading = true
          await axios
            .delete("/scholarships/" + scholarship.id + "/nullify-scholarship.json")
            .then(response => {
              this.$buefy.dialog.alert({
                title: 'Logrado',
                type: 'is-success',
                hasIcon: true,
                icon: 'check-circle',
                iconPack: 'fas',
                message: 'La Beca de Estudio ha sido anulada exitosamente.',
                confirmText: 'Aceptar'
              })
              this.getScholarships()
            })
            .catch(error => {
              this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Hubo un error al anular la Beca de Estudio.<br><b>Errores:</b><br>' + error.response.data,
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              this.isLoading = false
            })
        }
      })

    }
  },
  watch: {
    changeFilter: function () {
      this.getScholarships()
    }
  }
}
</script>