<template>
  <section>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column">
            <b-field label="Tipo de documento">
              <b-input :value="(rectification.resolution_type!==undefined && rectification.resolution_type!==null) ? rectification.resolution_type.name : ''" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="N° documento">
              <b-input :value="rectification.decree" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha documento">
              <b-input :value="formatDate(rectification.decree_date)" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field :label='"Fecha "+event_type_name'>
              <b-input :value="formatDate(rectification.start_date)" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field :label='"Url " +event_type_name'>
              <b-input expanded readonly v-model="rectification.documentation_link" type="text"></b-input>
              <p class="control">
                <b-button tag="a" :href="rectification.documentation_link" target="_blank" icon-left="external-link-alt" type="is-link">Link</b-button>
              </p>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Descripción">
              <b-input :value="rectification.description" type="textarea" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="container">
          <b-collapse
              aria-id="contentIdForA11y2"
              class="panel"
              animation="slide"
              v-model="isOpen">
            <template #trigger>
              <div
                  style="margin-bottom:40px;"
                  class="card-header"
                  role="button"
              >
                <p class="card-header-title">
                  <strong>{{isOpen ? "Ocultar Nombramiento Relacionado" : "Mostrar Nombramiento Relacionado"}}</strong>
                </p>
                <a class="card-header-icon">
                  <b-icon class="is-pulled-right" :icon="isOpen ? 'sort-down' : 'sort-up'"></b-icon>
                </a>
              </div>
            </template>
            <div class="title is-4">
              Información del Nombramiento
            </div>
            <component :is="formName"
                       :id="rectification.person_unit_id"
            />
          </b-collapse>


        </div>
      </section>
    </div>
    <footer class="modal-card-foot actions">
      <div class="has-text-right is-grouped">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
        <b-button class="is-primary"
                  v-if="rectification.can_edit"
                  @click="isEditModalActive = true">
          Editar
        </b-button>
      </div>
    </footer>
    <b-modal
        v-model="isEditModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <rectification-form :person-id="rectification.person_id"
                    :id="rectification.id"
                    query-type="update"
                    :modal-header="'Editar '+ event_type_name +' - '+person_name"
                    :event_type_name="event_type_name"
                    @close="props.close">
        </rectification-form>
      </template>
    </b-modal>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import RectificationForm from "../rectify/rectification_form.vue"
import {formatDate} from "../../../packs/utilities";
import AppointmentHoursShow from "../appointment_hours_show";
import AppointmentAcademicShow from "../appointment_academic_show";
import AppointmentAdministrativeShow from "../appointment_administrative_show";
import AppointmentFeeContractShow from "../appointment_fee_contract_show";

export default {
  name: "rectification",
  props:[
    "id","modalHeader", "person_name", 'event_type_name'
  ],
  components: {
    RectificationForm,
    AppointmentHoursShow,
    AppointmentAcademicShow,
    AppointmentAdministrativeShow,
    AppointmentFeeContractShow,
  },
  data(){
    return {
      isLoading:false,
      rectification:{},
      isEditModalActive:false,
      formName: "",
      isOpen: false,
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.isLoading = true;
      return axios
          .get("/admin/events/" + this.id + ".json")
          .then(res => {
            this.rectification = res.data
            if(res.data["establishment"].name==="ACADEMICOS") {
              this.formName = "AppointmentAcademicShow"
            }
            else if (res.data["establishment"].name==="ADMINISTRATIVOS") {
              this.formName = "AppointmentAdministrativeShow"
            }
            else if (res.data["establishment"].name==="HONORARIOS"){
              this.formName = "AppointmentFeeContractShow"
            }
            else {
              this.formName = "AppointmentHoursShow"
            }
            this.isLoading = false;
          }).catch(error => {
            console.log(error)
            this.isLoading = false;
          })
    },
    formatDate,
  },
  watch: {
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
  }
}
</script>

