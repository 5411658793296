<template>
    <div class="newform">
       <div class="container">
           <div class="box">
               <b-steps>
                    <b-step-item label="Datos personales" icon="fas fa account-key">
                        <br>
                        <div class="columns is-centered">
                            <div class="column">
                                <div class="field">
                                    <div class="field-label is-normal">
                                        <label class="label" style="text-align:left;">Nombres</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <input class="input" type="text" placeholder="Ingrese sus nombres" v-model="graduateInfo.name">
                                        </div>
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="field-label is-normal">
                                        <label class="label" style="text-align:left;">Rut</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <input class="input" type="text" placeholder="Ej: 12.345.678-9" v-model="graduateRut">
                                        </div>
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="field-label is-normal">
                                        <label class="label" style="text-align:left;">Nacionalidad</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="select">
                                            <select v-model="graduateInfo.nationality_name">
                                                <option v-for="nacionalidad in nacionalidades" :key="nacionalidad.id">{{ nacionalidad.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="field-label is-normal">
                                        <label class="label" style="text-align:left;">Fecha de nacimiento</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <b-datepicker placeholder="Selecciones su fecha de cumpleaños" v-model="graduateInfo.birthday_date"> </b-datepicker>
                                        </div>
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="field-label is-normal">
                                        <label class="label" style="text-align:left;">Correo corporativo</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <input class="input" type="text" placeholder="Ingrese su correo corporativo" v-model="graduateInfo.corporate_mail">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <div class="field-label is-normal">
                                        <label class="label" style="text-align:left;">Primer Apellido</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <input class="input" type="text" placeholder="Ingrese su primer apellido" v-model="graduateInfo.last_name">
                                        </div>
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="field-label is-normal">
                                        <label class="label" style="text-align:left;">Teléfono</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="field is-expanded">
                                            <div class="field has-addons">
                                                <p class="control">
                                                    <a class="button is-static">
                                                        +562
                                                    </a>
                                                </p>
                                                <p class="control is-expanded">
                                                    <input class="input" type="tel" placeholder="Ingrese su teléfono" v-model="graduateInfo.phone">
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="field-label is-normal">
                                        <label class="label" style="text-align:left;">Comuna</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="select">
                                            <select v-model="graduateInfo.commune_name">
                                                <option v-for="comuna in comunas" :key="comuna.id">{{ comuna.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="field-label is-normal">
                                        <label class="label" style="text-align:left;">Lugar de nacimiento</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <input class="input" type="text" placeholder="Ingrese el lugar de nacimiento" v-model="graduateInfo.birthplace">
                                        </div>
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="field-label is-normal">
                                        <label class="label" style="text-align:left;">Correo personal</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <input class="input" type="text" placeholder="Ingrese su correo personal" v-model="graduateInfo.personal_mail">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <div class="field-label is-normal">
                                        <label class="label" style="text-align:left;">Segundo Apellido</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <input class="input" type="text" placeholder="Ingrese su Segundo apellido" v-model="graduateInfo.second_surname">
                                        </div>
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="field-label is-normal">
                                        <label class="label" style="text-align:left;">Celular</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="field is-expanded">
                                            <div class="field has-addons">
                                                <p class="control">
                                                    <a class="button is-static">
                                                        +569
                                                    </a>
                                                </p>
                                                <p class="control is-expanded">
                                                    <input class="input" type="tel" placeholder="Ingrese su celular" v-model="graduateInfo.cellphone">
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="field-label is-normal">
                                        <label class="label" style="text-align:left;">Dirección</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="field">
                                            <input class="input" type="text" placeholder="Ingrese su dirección" v-model="graduateInfo.address">
                                        </div>
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="field-label is-normal">
                                        <label class="label" style="text-align:left;">Género</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="select">
                                            <select v-model="graduateInfo.sex">
                                                <option>Masculino</option>
                                                <option>Femenino</option>
                                                <option>Prefiero no decir mi género</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="field-label is-normal">
                                        <label class="label" style="text-align:left;">Estado civil</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="select">
                                            <select v-model="graduateInfo.civil_status">
                                                <option>Soltero</option>
                                                <option>Casado</option>
                                                <option>Separado</option>
                                                <option>Viudo</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </b-step-item>
                    <b-step-item label="Datos académicos" icon="fas fa account">
                        <br>
                        <div class="columns is-centered">
                            <div class="column">
                                    <div class="field">
                                        <div class="field-label is-normal">
                                            <label class="label" style="text-align:left;">Fecha de ingreso</label>
                                        </div>
                                        <div class="field-body">
                                            <div class="field">
                                                <b-datepicker placeholder="Selecciones su fecha de cumpleaños" v-model="graduateInfo.admission_date"> </b-datepicker>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div class="column">
                                    <div class="field">
                                        <div class="field-label is-normal">
                                            <label class="label" style="text-align:left;">Fecha de egreso</label>
                                        </div>
                                        <div class="field-body">
                                            <div class="field">
                                                <b-datepicker placeholder="Selecciones su fecha de egreso" v-model="graduateInfo.graduate_date"> </b-datepicker>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div class="column">
                                    <div class="field">
                                        <div class="field-label is-normal">
                                            <label class="label" style="text-align:left;">Fecha de titulación</label>
                                        </div>
                                        <div class="field-body">
                                            <div class="field">
                                                <b-datepicker placeholder="Seleccione su fecha de titulación" v-model="graduateInfo.titulation_date"> </b-datepicker>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <div class="field-label is-normal">
                                        <label class="label" style="text-align:left;">¿Estudiante de doctorado?</label>
                                    </div>
                                    <div class="field-body">
                                        <input type="checkbox" v-model="graduateInfo.is_doc_student">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="Object.entries(this.carreras_seleccionadas).length !== 0">
                            <b-table
                                :data="carreras_seleccionadas"
                                :paginated=true
                                per-page=10
                                striped
                                hoverable
                                :current-page="currentPage"
                                :pagination-simple=false
                                pagination-position="bottom"
                                default-sort-direction="asc"
                                sort-icon="arrow-up"
                                sort-icon-size="is-small"
                                default-sort="publication.title"
                                aria-next-label="Siguiente"
                                aria-previous-label="Anterior"
                                aria-page-label="Página"
                                aria-current-label="Página actual">
                                <b-table-column
                                    field= 'dpto'
                                    label= 'Departamento'
                                    v-slot="props"
                                    header-class="is-primary"
                                >
                                    {{props.row.dpto}}
                                </b-table-column>
                                <b-table-column
                                    field= 'carreer'
                                    label= 'Carrera'
                                    header-class="is-primary"
                                    v-slot="props"
                                >
                                    {{props.row.carreer}}
                                </b-table-column>
                                <b-table-column header-class="is-primary" v-slot="props">
                                    <button class="button is-danger" @click="confirmCustomDelete(props.row)">
                                        <b-icon icon="times"></b-icon>
                                        <span>Eliminar</span>
                                    </button>
                                </b-table-column>
                            </b-table>
                        </div>
                        <div class="columns">
                            <div class="column is-narrow">
                                <div class="field">
                                    <div class="field-label is-normal">
                                        <label class="label" style="text-align:left;">Departamento (Mínimo uno)</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="select">
                                            <select v-model="graduateInfo.unit_id">
                                                <option v-for="departamento in departamentos" :key="departamento.id" :value="departamento.id">
                                                    <p>{{ departamento.name }}</p>
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="column is-narrow">
                                <div class="field">
                                    <div class="field-label is-normal">
                                        <label class="label" style="text-align:left;">Carrera (Mínimo una)</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="select">
                                            <select v-model="graduateInfo.job_title_id">
                                                <option v-for="carrera in carreras" :key="carrera.id" :value="carrera.id">
                                                    <p>{{ carrera.name }}</p>
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-narrow">
                                <b-button type="is-primary" @click="addCarrer()">
                                    <b-icon icon="plus"></b-icon>
                                    <span>Añadir carrera</span>
                                </b-button>
                            </div>
                        </div>
                    </b-step-item>
                    <b-step-item label="Redes Sociales" icon="fas fa account-plus">
                        <br>
                        <div v-if="Object.entries(this.redes_sociales_seleccionadas).length !== 0">
                            <b-table
                                :data="redes_sociales_seleccionadas"
                                :paginated=true
                                per-page=10
                                striped
                                hoverable
                                :current-page="currentPage"
                                :pagination-simple=false
                                pagination-position="bottom"
                                default-sort-direction="asc"
                                sort-icon="arrow-up"
                                sort-icon-size="is-small"
                                default-sort="publication.title"
                                aria-next-label="Siguiente"
                                aria-previous-label="Anterior"
                                aria-page-label="Página"
                                aria-current-label="Página actual">
                                <b-table-column
                                    field= 'social_nework'
                                    label= 'Red social'
                                    v-slot="props"
                                    header-class="is-primary"
                                >
                                    {{props.row.social_nework}}
                                </b-table-column>
                                <b-table-column
                                    field= 'link'
                                    label= 'Link'
                                    header-class="is-primary"
                                    v-slot="props"
                                >
                                    {{props.row.link}}
                                </b-table-column>
                                <b-table-column header-class="is-primary" v-slot="props" centered>
                                    <button class="button is-danger" @click="confirmCustomDeleteSocialNetwork(props.row)">
                                        <b-icon icon="times"></b-icon>
                                        <span>Eliminar</span>
                                    </button>
                                </b-table-column>
                            </b-table>
                        </div>
                        <div class="columns">
                            <div class="column is-narrow">
                                <div class="field">
                                    <div class="field-label is-normal">
                                        <label class="label" style="text-align:left;">Red social</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="select">
                                            <select v-model="nombre_red_social">
                                                <option v-for="red_social in redes_sociales" :key="red_social.id">
                                                    <p>{{ red_social.name }}</p>
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <div class="field-label is-normal">
                                        <label class="label" style="text-align:left;">Link</label>
                                    </div>
                                    <b-field>
                                        <b-input type="text" 
                                                placeholder="Ingrese el link de la red social"
                                                v-model="link_red_social">
                                        </b-input>
                                    </b-field>
                                </div>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-narrow">
                                <b-button type="is-primary" @click="addSocialNetwork()">
                                    <b-icon icon="plus"></b-icon>
                                    <span>Añadir red social</span>
                                </b-button>
                            </div>
                        </div>
                    </b-step-item>
                </b-steps>
                <div class="field is-grouped is-grouped-right">
                    <div class="control">
                        <button class="button is-primary" @click="save()">Registrar</button>
                    </div>
                    <div class="control">
                        <button class="button is-link is-light" @click="back()">Volver</button>
                    </div>
                </div>
           </div>
       </div>
       <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: 'NewForm',
    props:{

    },
    data (){
        return {
            isFullPage: true,
            isLoading: false,
            currentPage: 1,

            // Para recuperar por medio de consultas iniciales
            nacionalidades: [],
            comunas: [],
            departamentos: [],
            carreras: [],
            redes_sociales: [],

            // Para almacenar datos momentaneamente
            nombre_red_social: '',
            link_red_social: '',
            rut: '',

            // Para envío de datos
            deptos_seleccionados: [],
            carreras_seleccionadas: [],
            redes_sociales_seleccionadas: [],
            graduateInfo:{
                name: '',
                last_name:'',
                second_surname: '',
                rut: '',
                birthday_date: new Date(),
                birthplace: '',
                sex: '',
                phone: 123456789,
                cellphone: 123456789,
                personal_mail: '',
                civil_status: '',
                corporate_mail: '',
                address: '',
                is_usach_graduate: true,
                is_doc_student: false,
                commune_name: '',
                nationality_name: '',
                admission_date: new Date(),
                graduate_date: new Date(),
                titulation_date: new Date(),
                commune_id: 0,
                nationality_id: 0,
                job_title_id: 0,
                unit_id: 0,
                run_attributes:{
                    rut: '', 
                    dv: ''
                }
            }
            // Para validaciones
        }
    },
    computed:{
        graduateRut:{
            get(){
                return this.rut
            },
            set(newRut){
                this.rut = newRut
            }
        }
    },
    methods:{
        getCommunes(){
            axios.get('/admin/communes.json')
            .then( response =>{
                this.comunas = response.data
            })
            .catch(e => { 
                console.log(e)
            })
        },
        getNationalities(){
            axios.get('/nationalities.json')
            .then( response =>{
                this.nacionalidades = response.data
            })
            .catch(e => { 
                console.log(e)
            })
        },
        getDptos(){
            axios.get('/dptos_general')
            .then( response =>{
                this.departamentos = response.data['departamentos']
            })
            .catch(e => { 
                console.log(e)
            })
        },
        getCareers(){
            axios.get('/job_titles.json')
            .then( response =>{
                this.carreras = response.data
            })
            .catch(e => { 
                console.log(e)
            })
        },
        getSocialNetworks(){
            axios.get('/index_active_social_networks.json')
            .then( response =>{
                this.redes_sociales = response.data
            })
            .catch(e => { 
                console.log(e)
            })
        },
        back(){
            this.isLoading=true
            window.location.href="/graduates"
        },
        getCommuneIdByName(communeName){
            var id = 1
            this.comunas.forEach(function (element){
                if (element.name ===  communeName){
                    id = element.id
                }
            })
            return id
        },
        getNationalitIdByName(nationalityName){
            var id = 1
            this.nacionalidades.forEach(function(element){
                if (element.name === nationalityName){
                    id = element.id
                }
            })
            return id
        },
        addCarrer(){
            var unidad_id = this.graduateInfo.unit_id
            var carrera_id = this.graduateInfo.job_title_id
            var departamento_seleccionado = ''
            var carrera_seleccionada = ''
            this.departamentos.forEach(function(element){
                if(element.id === unidad_id)
                    departamento_seleccionado = element.name
            })
            this.carreras.forEach(function(element) {
                if(element.id === carrera_id)
                    carrera_seleccionada = element.name
            })
            this.carreras_seleccionadas.push({
                dpto: departamento_seleccionado,
                carreer: carrera_seleccionada,
                carreer_id: carrera_id,
                unit_id: unidad_id,
                start_date: new Date(),
                end_date: new Date()
            })
        },
        confirmCustomDelete(deleteCarrerSelected) {
            this.$buefy.dialog.confirm({
                title: 'Eliminación de una carrera',
                message: '¿Quieres eliminar la carrera: <b>'+deleteCarrerSelected.carreer+'</b>?',
                confirmText: 'Eliminar',
                cancelText: 'Cancelar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.carreras_seleccionadas = this.carreras_seleccionadas.filter(element => element.carreer !== deleteCarrerSelected.carreer)
                }
            })
        },
        addSocialNetwork(){
            var social_network_id = 1
            var social_network_name = this.nombre_red_social 
            this.redes_sociales.forEach(function (element) {
                if (element.name === social_network_name)
                    social_network_id = element.id                
            })
            this.redes_sociales_seleccionadas.push({
                social_nework: this.nombre_red_social,
                link: this.link_red_social,
                id: social_network_id
            })
        },
        confirmCustomDeleteSocialNetwork(deleteSocialNetworkSelected) {
            this.$buefy.dialog.confirm({
                title: 'Eliminación de una red social',
                message: '¿Quieres eliminar la red social: <b>'+deleteSocialNetworkSelected.social_nework+'</b>?',
                confirmText: 'Eliminar',
                cancelText: 'Cancelar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.redes_sociales_seleccionadas = this.redes_sociales_seleccionadas.filter(element => element.link !== deleteSocialNetworkSelected.link)
                }
            })
        },
        savePerson(){

            // REGISTRO DEL RUT
            var rut = ' '
            var dv = ' '
            var dv_flag = false
            for(let i = 0; i<this.rut.length; i++){
                if(this.rut[i] !== '.'){
                    if(this.rut[i] !== '-'){
                        if(dv_flag){
                            dv = this.rut[i] 
                        }
                        else{
                            rut = rut.concat(this.rut[i])
                        }    
                    }
                    else{
                        dv_flag = true
                    }
                } 
            }
            this.graduateInfo.run_attributes.rut = rut
            this.graduateInfo.run_attributes.dv = dv
            console.log(this.graduateInfo.run_attributes.rut)
            console.log(this.graduateInfo.run_attributes.dv)

            // ENVIO DE DATOS
            var person_id = 0
            axios.post('/graduates.json', this.graduateInfo)
            .then( response =>{
                console.log(response.data)
                person_id = response.data.person_id
                this.saveJobTitle(response.data.person_id)        
            })
            .catch(e => {
                console.log(e)
            })
        },
        saveJobTitle(entrada){
            console.log('JobTitle: '+ entrada)
            axios.post('/graduates/assign_job_title_to_person', {
                person_id: entrada,
                job_titles: this.carreras_seleccionadas
            })
            .then( response =>{
                this.saveUnit(entrada)
            })
            .catch(e => {
                console.log(e)
            })
        },
        saveUnit(person_id){
            console.log('unit: '+ person_id)
            axios.post('/graduates/assign_depto_to_person', {
                person_id: person_id,
                units: this.carreras_seleccionadas
            })
            .then( response =>{
                console.log('logrado!')
                this.saveSocialNetworks(person_id)
            })
            .catch(e => {
                console.log(e)
            })
        },
        saveSocialNetworks(entrada){
            console.log('entrando...'+entrada)
            axios.post('/register_graduate_networks', {
                person_id: entrada,
                social_networks: this.redes_sociales_seleccionadas
            })
            .then( response =>{
                window.location.href="/graduates/show/"+entrada
            })
            .catch(e => {
                console.log(e)
            })
        },
        save(){
            this.isLoading = true
            this.graduateInfo.commune_id        = this.getCommuneIdByName(this.graduateInfo.commune_name)
            this.graduateInfo.nationality_id    = this.getNationalitIdByName(this.graduateInfo.nationality_name)
            this.savePerson()
        }
    },
    beforeMount(){
        this.getCommunes()
        this.getNationalities()
        this.getDptos()
        this.getCareers()
        this.getSocialNetworks()
    }
}
</script>