<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(updateContract)">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" ref="modalPersonForm">

            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion">
                      <div v-if="item">{{ item[0] }}</div>
                    </div>
                  </b-message>
                </div>
              </div>
            </div>

                <div class="columns">
                  <div class="column">
                    <div class="field">
                      <b-field label="Fecha de inicio*">
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <b-datepicker
                              v-model="contract.start_date"
                              icon="calendar-alt"
                              trap-focus
                              locale="es-ES"
                              :disabled="isView"
                              editable
                              placeholder="Formato de Fecha: dd/mm/aaaa">
                          </b-datepicker>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>
                  <div class="column">
                    <div class="field">
                      <b-field label="Fecha de término">
                        <b-datepicker
                            v-model="contract.end_date"
                            icon="calendar-alt"
                            trap-focus
                            locale="es-ES"
                            :disabled="isView"
                            editable
                            placeholder="Formato de Fecha: dd/mm/aaaa">
                        </b-datepicker>
                      </b-field>
                    </div>
                  </div>
                </div>

                <div class="columns">
                  <div class="column" v-if="tipos_pago.length>0">
                    <b-field label="Forma de pago*">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <multiselect v-model="contract.sdt_payment_type_id"
                                     :options="tipos_pago.map(type => type.id)"
                                     :custom-label="opt => tipos_pago.find(x => x.id == opt).name"
                                     placeholder="Seleccione forma de pago"
                                     selectLabel=""
                                     selectedLabel="Seleccionado"
                                     :disabled="isView"
                                     deselectLabel=""
                                     :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                                    No se encontraron elementos.
                                </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                </div>

                <div class="columns">
                  <div class="column">
                    <b-field label="Monto*">
                      <ValidationProvider rules="required|integer|min_value:0" v-slot="{ errors }">
                        <b-input :disabled="isView" v-model="contract.amount" type="text"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Monto Total*">
                      <ValidationProvider rules="required|integer|min_value:0" v-slot="{ errors }">
                        <b-input :disabled="isView" v-model="contract.total_amount" type="text"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>



                <div class="columns">
                  <div class="column">
                    <div class="field">
                      <b-field label="Servicios Prestados">
                        <ValidationProvider rules="max:4000" v-slot="{ errors }">
                          <b-input
                              type="textarea"
                              minlength="0"
                              :disabled="isView"
                              maxlength="4000"
                              v-model="contract.service">
                          </b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>

                </div>
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="actions has-text-right">
            <button class="button left-button" type="button" @click="$emit('close')">Cerrar</button>
            <b-button
              icon-left="save"
              class="button right-button is-primary"
              native-type="submit"
              v-if="queryType=='update'"
              @click="checkValidation()">
                Actualizar Contrato
            </b-button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios"
import moment from "moment"
export default {
  name: "ModalContract",
  data(){
    const data =[]
    return {
      contract:{},
      tipos_pago: [],
      isView: false,
      errores_validacion:{},
      isLoading: false,
      is_validated:false,

      
    }
  },

  props:[
    'userId', 'queryType', 'contractId', 'modalHeader'
  ],
  components:{
  },
  async created(){
    let vm = this;
    vm.isLoading=true;
    if(vm.queryType === "view")
    {
      vm.isView = true;
    }
    await vm.getPaymentTypes();    
    axios
        .get("/sdt_contracts/"+vm.contractId+".json")
        .then(res => {
          vm.contract = res.data
          if (vm.contract.start_date)
            vm.contract.start_date = new Date(moment(vm.contract.start_date).format('MM/DD/YYYY'));
          if (vm.contract.end_date)
            vm.contract.end_date = new Date(moment(vm.contract.end_date).format('MM/DD/YYYY'));
          vm.isLoading=false;
          
        }).catch(error => {
      console.log(error)
      vm.isLoading=false;
    })
    
  },
  methods: {
    async getPaymentTypes(){
      let vm = this;
      return axios.get("/sdt_payment_types.json")
        .then(res => {
          vm.tipos_pago = res.data;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    async checkValidation() {
      this.is_validated = false;
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar tanto la pestaña Integrante como la de Contrato.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
      else{
        this.is_validated = true;
      }
    },
    
    updateContract(){      
      let vm = this;
      if (vm.is_validated ){
        vm.isLoading=true;
        var retorno = [];
        axios
          .put("/sdt_contracts/"+vm.contractId+".json", {
              sdt_contract: vm.contract,
            })
            .then(response => {
              vm.errores_validacion = {};
              response.data.start_date = moment(response.data.start_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
              if (response.data.end_date)
                response.data.end_date = moment(response.data.end_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
              this.$emit('clicked', response.data)
              this.$emit('close')
              vm.isLoading=false;
            }).
        catch(error => {
          vm.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          vm.isLoading=false;
        })
      }
      
    },
  }
}
</script>

<style scoped>

</style>