<template>
    <div class="vimeactivitygeneralinformation">
      <div class="columns is-centered">
        <div class="column">
          <div class="has-text-right">
            <b-button v-if="can_edit" type="is-primary"
                      icon-left="edit"
                      @click="editActivity">
              Editar Actividad
            </b-button>
          </div>
          <hr class="dropdown-divider" aria-role="menuitem">
          <div class="columns">
            <div class="column">
              <b-field label="Nombre">
                <b-input type="text"
                         readonly
                         v-model="vime_activity.name">
                </b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Tema">
                <b-input type="text"
                         readonly
                         v-model="vime_activity.theme">
                </b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Descripción">
                <b-input type="textarea"
                         readonly
                         v-model="vime_activity.description">
                </b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <!-- Link de la actividad -->
              <div class="field">
                <b-field label="Link">
                  <b-input type="text"
                           expanded
                           readonly
                           v-model="vime_activity.link">
                  </b-input>
                  <p class="control">
                    <b-button tag="a" :href="vime_activity.link" target="_blank" icon-left="external-link-alt" type="is-link">Link</b-button>
                  </p>
                </b-field>
              </div>
            </div>
            <div class="column">
              <b-field label="Número de asistentes">
                <b-input type="text"
                         readonly
                         v-model="vime_activity.assistants_number">
                </b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Fecha de inicio">
                <b-input type="text" readonly v-model="vime_activity.start_date"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Fecha de término">
                <b-input type="text"
                              readonly
                              v-model="vime_activity.end_date"
                             >
                </b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <b-field label="Tipo de actividad">
                  <b-input
                      type="text"
                      readonly
                      v-model="vime_activity_type_name"
                      >
                  </b-input>
                </b-field>
              </div>
            </div>
            <div class="column">
              <b-field label="¿Es internacional?">
                <div class="columns">
                  <div class="column">
                    <b-radio-button v-model="vime_activity.is_national"
                                    disabled
                                    :native-value="false"
                                    type="is-danger is-light is-outlined"

                    >
                      <b-icon icon="times-circle"></b-icon>
                      <span>No</span>
                    </b-radio-button>
                  </div>
                  <div class="column">
                    <b-radio-button v-model="vime_activity.is_national"
                                    disabled
                                    :native-value="true"
                                    type="is-success is-light is-outlined"
                    >
                      <b-icon icon="check-circle"></b-icon>
                      <span>Si</span>
                    </b-radio-button>
                  </div>
                </div>
              </b-field>

            </div>
          </div>
        </div>
      </div>
      <br>
      <br>
    </div>
</template>
<script>
import axios from 'axios'

export default {
    name: 'VimeActivityGeneralInformation',
    props: ['vime_activity','can_edit','vime_activity_type_name'],
    /*props:{
        vime_activity: {
            type: Object
        },
      can_edit:{
          type: Boolean
      },
      vime_activity_type_name: {
          type: String
      }
    },*/
    data (){
        return {
            activity_types: []
        }
    },
    methods:{
        dateFormater(date){
            var newDate = ""
            var localDate = date;
            for(var i =0; i<localDate.length; i++){
                if(localDate[i] === '-'){
                    newDate = newDate.concat('/')
                }
                else{
                    newDate = newDate.concat(localDate.charAt(i))
                }
            }
            return newDate
        },
        editActivity(){
          window.location.href='/vime_activities/'+this.vime_activity.id+'/edit'
        },
        linkToActivity(){
            window.open(this.vime_activity.link)
        }
    }
}
</script>