<template>
  <section>
    <div class="container">
      <!--DATA TABLE-->
      <b-table
          :data="person_units"
          :paginated= true
          per-page="20"
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="product.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual"
          scrollable
          :opened-detailed="defaultOpenedDetails"
          detailed
          detail-key="id"
          detail-transition="fade"
          :show-detail-icon="showDetailIcon"
          :sticky-header=false>
        <b-table-column
            field="resolution_date"
            label="Fecha Documento"
            width="110"
            sortable
            searchable :custom-search="searchResolutionDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.resolution_date._isValid ?  new Date(props.row.resolution_date).toLocaleDateString("es-CL"): ''}}
          </template>
        </b-table-column>
        <b-table-column
            field="description"
            label="Descripción"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.description}}
          </template>
        </b-table-column>
        <b-table-column
            field="resolution"
            label="N° de documento"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.resolution}}
          </template>
        </b-table-column>
        <b-table-column
            field="resolution_type.name"
            label="Tipo documento"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.resolution_type ? props.row.resolution_type.name : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="appointment_type.name"
            label="Tipo de Alejamiento"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.appointment_type ? props.row.appointment_type.name : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="start_date"
            label="Fecha"
            width="110"
            sortable
            searchable :custom-search="searchDistanceDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>

        <b-table-column
            label="Acciones"
            width="10"
            v-slot="props">
          <b-icon
              pack="fas"
              v-if="props.row.can_show"
              icon="eye"
              size="is-medium"
              @click.native="execShowModal(props.row)"/>
          <b-icon
              v-if="props.row.can_restore"
              pack="fas"
              icon="trash-restore"
              type="is-success"
              size="is-medium"
              @click.native="execRestoreModal(props.row.id)"/>
        </b-table-column>
        <template #detail="props" >
          <article class="media" >
            <div class="media-content">
              <div class="content">
                <div class="columns">
                  <div class="column">
                    <strong>Nombramiento Asociado: </strong>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-narrow">
                    <p>{{props.row.appointment.resolution}}</p>
                  </div>
                  <div class="column is-narrow">
                    <p>{{formatDate(props.row.appointment.start_date)}}</p>
                  </div>
                  <div class="column is-narrow">
                    <p>{{formatDate(props.row.appointment.end_date)}}</p>
                  </div>
                  <div class="column is-narrow">
                    <p>{{props.row.appointment.appointment_type_name}}</p>
                  </div>
                  <div class="column is-narrow">
                    <p>{{props.row.appointment.state}}</p>
                  </div>
                  <div class="column">
                    <b-icon
                        pack="fas"
                        icon="eye"
                        size="is-medium"
                        @click.native="execShowModalAppointment(props.row)"/>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
      <!--FIN DATA TABLE-->

      <!-- MODALES -->
      <b-modal
          v-model="isAddModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <select-appointment-establishment :person-id="personId"
                                            query-type="create"
                                            modal-header="Seleccionar nombramiento a crear"
                                            @close="props.close"></select-appointment-establishment>
        </template>
      </b-modal>
      <b-modal
          v-model="isShowAppointmentModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <component  :is="showName"
                      :id="selected"
                      :modal-header="title"
                      @close="props.close"/>
        </template>
      </b-modal>
      <b-modal
          v-model="isShowModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <component  :is="showName"
                      :id="selected"
                      :modal-header="title"
                      @close="props.close"/>
        </template>
      </b-modal>
      <b-modal
          v-model="isEditModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <component :is="formName"
                     :person-id="personId"
                     :id="selected"
                     query-type="update"
                     :modal-header="title"
                     @close="props.close"/>
        </template>
      </b-modal>
      <b-modal
          v-model="isEditDistanceModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <distance-form :person-id="person_unit.person_id"
                         :id="selected"
                         :person_unit_id="selected"
                         query-type="update"
                         :modal-header="'Editar Alejamiento - '  +person_name"
                         @close="props.close">
          </distance-form>
        </template>
      </b-modal>

    </div>
    <b-loading :is-full-page="true"
               v-model="isLoading"
               :can-cancel="false"/>
  </section>
</template>

<script>
import axios from "axios";
import AppointmentAcademic from "../life_record/appointment_academic"
import AppointmentAdministrative from "../life_record/appointment_administrative"
import AppointmentHours from "../life_record/appointment_hours";
import AppointmentAcademicForm from "../life_record/modal_academic_appointment_form"
import AppointmentHoursForm from "../life_record/modal_hour_appointment_form"
import AppointmentAdministrativeForm from "../life_record/modal_administrative_appointment_form"
import SelectAppointmentEstablishment from '../life_record/select_appointment_establishment'
import Distance from "../life_record/distance"
import DistanceForm from "../life_record/modal_distance_form"
import AppointmentFeeContract from "../life_record/appointment_feecontract"
import moment from "moment";
import {searchDate, formatDate} from "../../packs/utilities";
import { ToastProgrammatic as Toast } from 'buefy'
export default {
  name: "null_distances",
  props:[
    'personId', 'person_name'
  ],
  components:{
    AppointmentHours,
    AppointmentAcademic,
    AppointmentAdministrative,
    AppointmentHoursForm,
    AppointmentAcademicForm,
    AppointmentAdministrativeForm,
    SelectAppointmentEstablishment,
    Distance,
    AppointmentFeeContract,
    DistanceForm
  },
  data(){
    return {
      formName: "",
      showName: "",
      title: "",
      selected: null,
      index: null,
      currentPage: 1,
      isAddModalActive: false,
      isEditModalActive: false,
      isShowModalActive: false,
      isShowAppointmentModalActive: false,
      isLoading:false,
      person_units: [],
      person_unit:{},
      canCancel: ['escape'],
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      useTransition: false,
      isEditDistanceModalActive: false,
      isRestoreActive: false,
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    initialize(){
      this.isLoading = true;
      return axios
          .get("/people/"+this.personId+"/null_distances.json")
          .then(res => {
            this.person_units = res.data
            this.person_units = this.person_units.map(personUnit => {
              var newPersonUnit = personUnit
              newPersonUnit["resolution_date"] = moment(newPersonUnit["resolution_date"] , ["YYYY-MM-DD","DD-MM-YYYY"]);
              newPersonUnit["start_date"] = moment(newPersonUnit["start_date"] , ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newPersonUnit;
            });
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })

    },
    execRestoreModal(id){
      let vm = this;
      vm.$buefy.dialog.confirm({
        title: 'Restaurar Alejamiento',
        message: '¿Está seguro que desea <b>restaurar</b> el alejamiento? Esta acción revertirá la anulación.',
        confirmText: 'Restaurar Alejamiento',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          vm.isRestoreActive = true
          vm.isLoading = true
          axios
              .put("/admin/person_units/" + id + "/restore_distance.json")
              .then(res => {
                vm.$buefy.dialog.alert({
                  message: 'Alejamiento restaurado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                vm.isLoading = false;
                vm.isRestoreActive = false;
              }).catch(error => {
            vm.$buefy.dialog.alert({message: '<b>Alejamiento no pudo ser restaurado</b><br>'+error.response.data["base"][0],
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]})
            vm.isLoading = false;
            vm.isRestoreActive = false;
          })
        }
      })

    },
    execShowModal(row){
      this.selected = row.id;
      this.showName = "Distance"
      this.title = "Alejamiento"+" - "+this.person_name
      this.isShowModalActive = true;
    },
    execShowModalAppointment(row){
      this.selected = row.person_unit_id;
      if(row!== undefined){
        if(row.associated_appointment_establishment==="ACADEMICOS") {
          this.showName = "AppointmentAcademic"
          this.title = "Nombramiento Estamento Académico"+" - "+this.person_name
        }
        else if (row.associated_appointment_establishment==="ADMINISTRATIVOS") {
          this.showName = "AppointmentAdministrative"
          this.title = "Nombramiento Estamento Administrativo"+" - "+this.person_name
        }
        else if (row.associated_appointment_establishment==="HONORARIOS") {
          this.showName = "AppointmentFeeContract"
          this.title = "Nombramiento Estamento Honorarios"+" - "+this.person_name
        }
        else {
          this.showName = "AppointmentHours"
          this.title = "Nombramiento Estamento Profesor Horas Clases"+" - "+this.person_name
        }
      }
      else{
        this.showName = "AppointmentAdministrative"
      }
      //console.log("APPOINTMENT "+ this.showName)
      this.isShowAppointmentModalActive = true;
    },
    execEditDistanceModal(row){
      this.selected = row.id;
      this.showName = "Distance"
      this.title = "Alejamiento"+" - "+this.person_name
      //console.log("APPOINTMENT "+ this.showName)
      this.isEditDistanceModalActive = true;
    },
    execEditModal(row){
      this.selected = id;
      let person_unit = this.getEstablishment(id)
      if(person_unit.establishment!==undefined){
        if(person_unit.establishment.name==="ACADEMICOS") {
          this.formName = "AppointmentAcademicForm"
          this.title = "Editar Nombramiento Estamento Académico"+" - "+this.person_name
        }
        else if (person_unit.establishment.name==="ADMINISTRATIVOS") {
          this.formName = "AppointmentAdministrativeForm"
          this.title = "Editar Nombramiento Estamento Administrativo"+" - "+this.person_name
        }
        else {
          this.formName = "AppointmentHoursForm"
          this.title = "Editar Nombramiento Estamento Profesor Horas Clase"+" - "+this.person_name
        }
      }
      else{
        this.formName = "AppointmentAdministrativeForm"

      }
      //console.log("APPOINTMENT "+ this.showName)
      this.isEditModalActive = true;
    },
    getEstablishment(selected){
      return this.person_units.find(o => o.id === selected);
    },
    // yyyy/mm/dd -> dd/mm/yyyy
    formatDate,
    searchDistanceDate(row, input){
      return searchDate(row.start_date,input)
    },
    searchResolutionDate(){
      return searchDate(row.resolution_date,input)
    }
  },
  watch:{
    isAddModalActive: function () {
      if (!this.isAddModalActive) {
        this.initialize()
      }
    },
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
    isShowModalActive: function () {
      if (!this.isShowModalActive) {
        this.initialize()
      }
    },
    isEditDistanceModalActive: function () {
      if (!this.isEditDistanceModalActive) {
        this.initialize()
      }
    },
    isRestoreActive: function () {
      if (!this.isRestoreActive){
        this.initialize()
      }
    }

  },
}
</script>

