<template>
  <div>
    <form>
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Agregar sub-entidad</p>
          <button
              type="button"
              class="delete"
              @click="$emit('close')"/>
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column">
                <b-field :type="{ 'is-danger': ischeck}"
                         :message="{ 'Seleccione una entidad registrada.':  ischeck}">
                  <template #label>
                    Nombre Entidad Externa* (ingrese por lo menos 3 letras)
                    <b-tooltip
                        label="Se recomienda búsqueda de agrupaciones por sigla, ejemplo: ANID, no Agencia Nacional... mientras que para instituciones educacionales, se recomienda por nombre completo, ejemplo: universidad de santiago de chile"
                        position="is-left"
                        size="is-medium"
                        multilined>

                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          size="is-small"
                          type="is-info">
                      </b-icon>
                    </b-tooltip>
                  </template>
                  <b-autocomplete
                      :data="partner_entities"
                      placeholder="ej ANID o UNIVERSIDAD DE SANTIAGO DE CHILE  o NOMBRE EMPRESA - ENTIDAD"
                      field="name"
                      :loading="isFetching"
                      @typing="searchPartnerEntities"
                      v-model="partner_entity.name"
                      @select="option => {if (option) {partner_entity.id = option.id; partner_entity.name = option.name;}}">
                    <template slot-scope="props">
                      {{ props.option.show_name ? props.option.show_name : props.option.name}}
                    </template>
                  </b-autocomplete>
                </b-field>
            </div>
            <div class="column is-narrow">
              <br>
              <b-field label=" ">
                <b-tooltip label="Si la entidad que desea agregar no existe, la puede agregar presionando el botón +"
                           position="is-left">
                  <b-button class="is-primary is-outlined" @click="newEntity()">
                    <b-icon icon="plus"></b-icon>
                  </b-button>
                </b-tooltip>
              </b-field>
            </div>

          </div>
          <div class="columns">
            <div class="column">
              <br>
              <b-field label=" ">

              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <br>
              <b-field label=" ">

              </b-field>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button
              label="Cerrar"
              @click="$emit('close')" />
          <b-button
              label="Añadir"
              type="is-primary"
              @click="checkEntity()"/>
        </footer>
      </div>
    </form>
    <b-modal
        v-model="isAddModalEntityActive"
        :destroy-on-hide="true"
        width="50%"
        :can-cancel="canCancel">
      <template #default="props">
        <modal-new-partner-entity
            query-type="update"
            modal-header="Añadir nueva entidad"
            :id="entity.id"
            :entity="entity"
            @close="props.close"
            @clicked="onClickChild"></modal-new-partner-entity>
      </template>
    </b-modal>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
import axios from "axios";
import modalNewPartnerEntity from "./modalNewPartnerEntity";

export default {
  name: "modalAddEntity",
  props:['entity'],
  components:{modalNewPartnerEntity},
  data(){
    return{
      partner_entities:[],
      isLoading: false,
      isFetching:false,
      partner_entity:{},
      ischeck: false,
      selected_entity:{},
      isAddModalEntityActive: false,
      canCancel: false,
    }
  },
  methods:{
    searchPartnerEntities(name){
      this.isAcademicRegister =  false
      if (name.length<3) {
        this.partner_entities = []
        return
      }
      let vm = this;
      vm.isLoading=true;
      axios
          .get("/admin/partner_entities/agreement-search.json", { params: { name: name } })
          .then(res => {
            vm.partner_entities = res.data;
            vm.isLoading = false;
          })
          .catch(error => {
            console.log(error)
            vm.isLoading=false;
          })
    },

    checkEntity(){
      if(this.partner_entity.id == null){
        this.ischeck = true
      }

      else if(this.partner_entity.name.length > 0){
        this.isLoading = true
        this.ischeck = false
        axios.get('/partner_entities/show/'+this.partner_entity.id+'.json')
            .then( response =>{
              if (response.data.entity.entity_mayor != null){
                this.$buefy.dialog.alert({
                  message: "La entidad que desea agregar, ya se encuentra asociada.",
                  type: 'is-danger',
                  hasIcon: true,
                  icon: 'times-circle',
                  onConfirm: () => {
                    this.isLoading=false
                  }
                })
                this.isLoading=false
              }
              else if(response.data.entity.id == this.entity.id){
                this.$buefy.dialog.alert({
                  message: "No se puede agregar la entidad así mismo como sub-entidad.",
                  type: 'is-danger',
                  hasIcon: true,
                  icon: 'times-circle',
                  onConfirm: () => {
                    this.isLoading=false
                  }
                })
                this.isLoading=false
              }
              else{
                if(this.entity.entity_mayor != null && response.data.entity.id == this.entity.entity_mayor.id){
                  this.$buefy.dialog.alert({
                    message: "No puede agregar como sub-entidad la entidad a la que pertenece.",
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    onConfirm: () => {
                      this.isLoading=false
                    }
                  })
                  this.isLoading = false
                }
                else{
                  this.selected_entity = response.data.entity
                  this.addEntity()
                }
              }
            })
            .catch(e =>{
              console.log(e)
              this.isLoading=false

            })
      }
      else{
        this.ischeck = true
      }
    },

    addEntity(){
      ////REVISAR DESDE ACA
      this.selected_entity.partner_entity_id = this.entity.id
      this.isLoading = true
      axios.put('/admin/partner_entities/'+this.partner_entity.id+'.json', this.selected_entity)
          .then( response =>{
            this.errores_validacion = {};
            this.isLoading = false
            this.$emit('clicked')
            this.$emit('close')
            //window.location.href="/partner_entities/show/"+response.data.id

          })
          .catch(e => {
            this.isLoading = false
            this.disabled = false
            this.errores_validacion = e.response.data;
            this.$buefy.dialog.alert({
              message: "Faltan algunos campos o presentan errores de formato. Por favor revisar",
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              onConfirm: () => {
              }
            })
          })
    },
    onClickChild(){
      this.$emit('clicked')
      this.$emit('close')

    },
    newEntity(){
      this.isAddModalEntityActive = true
    }
  }
}
</script>
