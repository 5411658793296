<template>
  <section>
        <div class="columns">
          <div class="column">
            <b-field label="Tipo de evento">
              <b-input :value="event.event_type!==undefined ? event.event_type.name : '' " type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="N° documento">
              <b-input :value="event.decree" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha documento">
              <b-input :value="formatDate(event.decree_date)" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Fecha desde">
              <b-input :value="formatDate(event.start_date)" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha hasta">
              <b-input :value="formatDate(event.end_date)" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Tipo de  documento">
              <b-input :value="(event.resolution_type!==undefined && event.resolution_type!==null) ? event.resolution_type.name : '' " type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
<!--        <div class="columns">
          <div class="column">
            <b-field label="Url Documentación">
              <b-input expanded readonly v-model="event.documentation_link" type="text"></b-input>
              <p class="control">
                <b-button tag="a" :href="event.documentation_link" target="_blank" icon-left="external-link-alt" type="is-link">Link</b-button>
              </p>
            </b-field>
          </div>
        </div>-->
        <div class="columns">
          <div class="column">
            <b-field label="Descripción">
              <b-input :value="event.description" type="textarea" readonly></b-input>
            </b-field>
          </div>
        </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import {formatDate} from "../../packs/utilities";

export default {
  name: "event",
  props:[
    "id"
  ],
  components: {
  },
  data(){
    return {
      isLoading:false,
      event:{},
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.isLoading = true;
      return axios
          .get("/admin/events/" + this.id + ".json")
          .then(res => {
            this.event = res.data
            this.isLoading = false;
          }).catch(error => {
            console.log(error)
            this.isLoading = false;
          })
    },
    formatDate,
  },
  watch: {
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
  }
}
</script>

