var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[(Object.keys(_vm.errores_validacion).length !== 0)?_c('div',{staticClass:"mb-2"},[_c('b-message',{attrs:{"type":"is-danger","has-icon":""}},_vm._l((_vm.errores_validacion),function(item,index){return _c('div',{key:index},[(item)?_c('div',[_vm._v(_vm._s(item[0]))]):_vm._e()])}),0)],1):_vm._e()])]),_vm._v(" "),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveSurplus)}}},[_c('div',{staticClass:"box"},[_c('h1',{staticClass:"title has-text-centered"},[_vm._v("General")]),_vm._v(" "),_c('div',{staticClass:"columns"},[(_vm.unidades.length>0)?_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Unidad*"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.unidades.map(function (type) { return type.id; }),"custom-label":function (opt) { return _vm.unidades.find(function (x) { return x.id == opt; }).name; },"placeholder":"Seleccione Unidad","selectLabel":"Presione para seleccionar","selectedLabel":"Seleccionado","deselectLabel":"No se puede deseleccionar","allow-empty":false},on:{"select":_vm.getCodes},scopedSlots:_vm._u([{key:"noOptions",fn:function(){return [_vm._v("\n                    No existen datos\n                  ")]},proxy:true}],null,true),model:{value:(_vm.surplus.unit_id),callback:function ($$v) {_vm.$set(_vm.surplus, "unit_id", $$v)},expression:"surplus.unit_id"}},[_vm._v(" "),_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("\n                      No se encontraron elementos.\n                  ")])]),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Entidad de giro*"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.codes.map(function (type) { return type.id; }),"custom-label":function (opt) { return _vm.codes.find(function (x) { return x.id == opt; }).code; },"placeholder":"Seleccione entidad de giro","selectedLabel":"","deselectLabel":"","allow-empty":false},scopedSlots:_vm._u([{key:"noOptions",fn:function(){return [_vm._v("\n                    No existen datos\n                  ")]},proxy:true}],null,true),model:{value:(_vm.surplus.sdt_turn_id),callback:function ($$v) {_vm.$set(_vm.surplus, "sdt_turn_id", $$v)},expression:"surplus.sdt_turn_id"}},[_vm._v(" "),_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("\n                      No se encontraron elementos.\n                  ")])]),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Código EFUSACH*"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"placeholder":"Ej: SDT_CAP_001","type":"text"},model:{value:(_vm.surplus.code),callback:function ($$v) {_vm.$set(_vm.surplus, "code", $$v)},expression:"surplus.code"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Monto"}},[_c('ValidationProvider',{attrs:{"rules":"required|integer|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"placeholder":"Ej: 500000","type":"text"},model:{value:(_vm.surplus.amount),callback:function ($$v) {_vm.$set(_vm.surplus, "amount", $$v)},expression:"surplus.amount"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_vm._v(" "),_c('br'),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"is-pulled-left"},[(_vm.modalCancel)?_c('button',{staticClass:"button is-danger",on:{"click":function($event){$event.preventDefault();return _vm.$emit('close')}}},[_vm._v("Cancelar")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"is-pulled-right"},[_c('b-button',{staticClass:"button is-primary",attrs:{"icon-left":"save","native-type":"submit"},on:{"click":function($event){return _vm.checkValidation()}}},[_vm._v("Guardar código EFUSACH")])],1)])])])])]}}])}),_vm._v(" "),_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }