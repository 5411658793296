<template>
  <div class="container" ref="vime_tutorial">
    <div>
      <div class="section pt-0">
        <div class="container px-5 py-0">
          <div class="columns is-multiline">
            <div class="column is-3 py-0">
              <b-sidebar position="static" type="is-light" open>
                <div class="p-1 pt-4">
                  <div class="block mt-5">
                    <h1 class="subtitle is-3 has-text-primary has-text-left">Índice</h1>
                  </div>
                  <b-menu class="is-custom-mobile">
                    <b-menu-list>
                      <b-menu-item :active="tutorial === 'create_vime' ? true : false" @click="tutorial = 'create_vime'" label="1.¿Cómo agregar una actividad VIME?"></b-menu-item>
                      <b-menu-item :active="tutorial === 'add_participants' ? true : false" @click="tutorial = 'add_participants'" label="2.¿Cómo agregar un participante en la actividad VIME?"></b-menu-item>
                      <b-menu-item :active="tutorial === 'add_entities' ? true : false" @click="tutorial = 'add_entities'" label="3.¿Cómo asociar una entidad en la actividad VIME?"></b-menu-item>
                    </b-menu-list>
                  </b-menu>
                </div>
              </b-sidebar>
              <div class="buttons-sidebar">
                <b-button class="icon-up" icon-left="arrow-circle-up" type="is-ghost" @click="goUp">
                  Volver arriba
                </b-button>
              </div>
            </div>

            <div v-if="tutorial == 'create_vime'" class="column is-9 px-5">
              <div class="section p-5 pt-0">
                <h1 class="subtitle is-2 has-text-primary">
                  Agregar actividad de vinculación con el medio
                </h1>
                <div class="mb-3 ">
                  <p>SIGA permite la creación de actividades relacionadas a la vinculación con el medio. Para poder ingresar una actividad al sistema, usted deberá proporcionar en una primera etapa, datos génericos.</p>
                </div>
                <h2 class="subtitle is-3 mt-5 mb-2">
                  Paso 1
                </h2>
                <p class="my-3 ">Para agregar una nueva actividad VIME, se puede realizar de dos formas: desde la barra lateral o desde "Mi Actividad Acádemica".
                </p>


                <ul>
                  <li>
                    <h5 class="subtitle is-5">1. Desde la barra lateral:</h5>
                    <ul class="ml-4">
                      <li>1.1 Abrir la barra lateral.</li>
                      <li>1.2 Seleccionar "Actividad Académica".</li>
                      <li>1.3 Seleccionar "Añadir Actividad".</li>
                      <li>1.4 Seleccionar "Vinculación con el medio".</li>
                    </ul>
                  </li>

                  <img border="2" class="m-2" src="tutorial_images/add_academic_activity.png" style="width:35%">

                  <li>
                    <h5 class="subtitle is-5">2. Desde
                      <a :href="'/people/'+person.id" target="_blank"><b-icon pack="fas" icon="book"></b-icon>Mi Actividad Acádemica</a>:</h5>
                    <ul>
                      <li>2.1 Desplegar el menú de usuaria/o en la esquina superior derecha del sistema.</li>
                      <img border="2" class="m-2" src="tutorial_images/my_profile_menu.png" style="width:40%">
                      <li>2.2 Seleccionar <a :href="'/people/'+person.id" target="_blank"><b-icon pack="fas" icon="book"></b-icon>"Mi Actividad Acádemica".</a></li>
                      <li>2.3 En la vista de <a :href="'/people/'+person.id" target="_blank"><b-icon pack="fas" icon="book"></b-icon>Mi Actividad Acádemica</a>, apretar el botón "Añadir Actividad Académica".</li>
                      <li>2.4 Seleccionar Vinculación con el medio.</li>
                    </ul>
                  </li>

                  <img border="2" class="m-2" src="tutorial_images/my_academic_activity_add_aa_button.png">

                </ul>
                <h2 class="subtitle is-3 mt-5 mb-2">
                  Paso 2
                </h2>
                <p class="my-3 ">Rellenar el formulario para agregar una <a :href="'/vime_activities/new'" target="_blank">Nueva Actividad VIME</a>. <br> En este paso se debe rellenar la información
                  solicitada en el formulario de actividades VIME.
                </p>
                <ul>
                  <li>
                    <h5 class="subtitle is-5">1. Rellenar pestaña General:</h5>
                    <ul class="ml-4">
                      <li>1.1 Rellenar los datos del formulario de la pestaña General. Los ítems marcados con un asterisco(*) son de caracter obligatorio.</li>
                      <li><img border="2" class="m-2" src="tutorial_images/form-vime.png"></li>
                    </ul>
                  </li>

                </ul>

                <h2 class="subtitle is-3 mt-5 mb-2">
                  Paso 3
                </h2>
                <p class="my-3 ">Guardar la actividad VIME
                </p>
                <ul>
                  <li>3.1 Una vez llenados los datos obligatorios, debe presionar el botón "Guardar Actividad" del formulario.</li>
                  <li>3.2 Si el sistema arroja errores, deben ser resueltos para poder guardar la actividad.</li>
                </ul>

              </div>
            </div>

            <div v-if="tutorial == 'add_participants'" class="column is-9 px-5">
              <div class="section p-5 pt-0">
                <h1 class="subtitle is-2 has-text-primary">
                  Agregar participantes en actividad VIME
                </h1>
                <div class="mb-3 ">
                  <p>Ya creada la actividad VIME, redirige automáticamente a la vista general con los datos ingresados. En esta vista existen varias pestañas que muestra información adicional a los datos generales, como los participantes y entidades.</p>
                </div>
                <h2 class="subtitle is-3 mt-5 mb-2">
                  Paso 1
                </h2>
                <p class="my-3 ">Para agregar un nuevo participante, debe presionar la pestaña "Participantes".
                </p>
                <ul>

                  <li>1.1 En la pestaña participantes debe presionar el botón "Añadir un nuevo participante".</li>

                  <li><img border="2" class="m-2" src="tutorial_images/anadir-participant-vime.png"></li>

                </ul>
                <h2 class="subtitle is-3 mt-5 mb-2">
                  Paso 2
                </h2>
                <p class="my-3 ">Se desplegará una nueva ventana con los campos a rellenar.
                </p>
                <ul>
                  <li>
                    <ul class="ml-4">
                      <li>2.1 Debe seleccionar en el campo "Nombre del participante" una persona que se encuentre registrada en el sistema.
                        En caso que no se encuentre la persona registrada, puede agregarla como persona externa, para ello debe presionar el botón +.
                        Para más información consultar la sección de participantes externos.</li>
                      <li>2.2 Debe seleccionar el tipo de participación de la persona en la actividad, entre varias opciones que se despliegan en el campo "Participa como".</li>
                      <li><img border="2" class="m-2" src="tutorial_images/modal-participant-vime.png"></li>
                      <li>2.3 Finalmente, debe presionar el botón "Añadir", para que la persona quede asociada a la actividad VIME. Se verá reflejado en la tabla que se encuentra en la pestaña "Participantes".</li>
                      <li><img border="2" class="m-2" src="tutorial_images/info-participant-vime.png"></li>
                    </ul>
                  </li>
                </ul>
                <h2 class="subtitle is-3 mt-5 mb-2">
                  Paso Opcional
                </h2>
                <p class="my-3 ">El sistema permite eliminar cualquier participante asocidada a la actividad VIME.
                </p>
                <ul>
                  <li>
                    <ul class="ml-4">
                      <li>3.1 En la columna "Acciones" debe presionar el botón rojo "Eliminar", en la fila del participante que desea eliminar.</li>
                      <li><img border="2" class="m-2" src="tutorial_images/info-participant-vime-cut.png"></li>
                      <li>3.2 Se le indicará con un mensaje de advertencia que esta a punto de eliminar un participante. Si esta seguro de eliminar el participante presione el botón "Eliminar."</li>
                      <li><img border="2" class="m-2" src="tutorial_images/delete-participant-vime.png"></li>
                    </ul>
                  </li>
                </ul>

              </div>
            </div>

            <div v-if="tutorial == 'add_entities'" class="column is-9 px-5">
              <div class="section p-5 pt-0">
                <h1 class="subtitle is-2 has-text-primary">
                  Asociar entidades en actividad VIME
                </h1>
                <div class="mb-3 ">
                  <p>Ya creada la actividad VIME, redirige automáticamente a la vista general con los datos ingresados. En esta vista existen varias pestañas que muestra información adicional a los datos generales, como los participantes y entidades.</p>
                </div>
                <h2 class="subtitle is-3 mt-5 mb-2">
                  Paso 1
                </h2>
                <p class="my-3 ">Para asociar una nueva entidad, debe presionar la pestaña "Entidades relacionadas".
                </p>
                <ul>

                  <li>1.1 En la pestaña de entidades relacionadas debe presionar el botón "Añadir una nueva entidad".</li>

                  <li><img border="2" class="m-2" src="tutorial_images/add-entity-vime.png"></li>

                </ul>
                <h2 class="subtitle is-3 mt-5 mb-2">
                  Paso 2
                </h2>
                <p class="my-3 ">Se desplegará una nueva ventana con los campos a rellenar.
                </p>
                <ul>
                  <li>
                    <ul class="ml-4">
                      <li>2.1 Debe seleccionar en el campo "Nombre entidad externa" alguna entidad que se encuentre registrada en el sistema. Se deben ingresar al menos tres letras para que comience a mostrar resultados.</li>
                      <li>2.2 Debe seleccionar el tipo de participación de la entidad en la actividad, entre varias opciones que se despliegan en el campo "Asiste como"</li>
                      <li><img border="2" class="m-2" src="tutorial_images/modal-entity.png"></li>
                      <li>2.3 Finalmente, debe presionar el botón "Añadir", para que la entidad quede asociada a la actividad VIME. Se verá reflejado en la tabla que se encuentra en la pestaña "Entidades relacionadas".</li>
                      <li><img border="2" class="m-2" src="tutorial_images/info-entity-vime.png"></li>
                    </ul>
                  </li>
                </ul>
                <h2 class="subtitle is-3 mt-5 mb-2">
                  Paso Opcional
                </h2>
                <p class="my-3 ">El sistema permite eliminar cualquier entidad asocidada a la actividad VIME.
                </p>
                <ul>
                  <li>
                    <ul class="ml-4">
                      <li>3.1 En la columna "Acciones" debe presionar el botón rojo "Eliminar", en la fila de la entidad que se desea eliminar.</li>
                      <li><img border="2" class="m-2" src="tutorial_images/info-entity-vime-cut.png"></li>
                      <li>3.2 Se le indicará con un mensaje de advertencia que esta a punto de eliminar la entidad asociada. Si esta seguro de eliminar la entidad asociada presione el botón "Eliminar."</li>
                      <li><img border="2" class="m-2" src="tutorial_images/delete-entity-vime.png"></li>
                    </ul>
                  </li>
                </ul>

              </div>
            </div>


          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "vimeActivityTutorial",
  data(){
    return{
      tutorial: 'create_vime'
    }
  },
  methods:{
    goUp(){
      var elmnt = document.getElementById("app");
      elmnt.scrollIntoView();
      this.$refs.vime_tutorial.scrollTop = 0;
    }
  },
  props:['person']
}
</script>
<style>
.icon-up{
  position: fixed;
  width: 16.5% !important;
  bottom: 0;
  left: 15%;
}
.buttons-sidebar{

}
</style>
