<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveSchedule)">
        <!-- Box de Formulario Evento -->
        <div class="modal-card" style="width: auto" id="modal-event">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body">
            <div class="columns">
              <div class="column">
                <multiselect v-model="interval"
                             :options="intervals.map(type => type.number)"
                             :custom-label="opt => intervals.find(x => x.number === opt).name"
                             placeholder="Seleccione intervalo de tiempo"
                             selectLabel="Presione para seleccionar"
                             selectedLabel="Seleccionado"
                             deselectLabel="Presione para deseleccionar"
                             @input="change"
                             :allow-empty="true">
                  <template v-slot:noOptions>
                    No existen datos
                  </template>
                  <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                </multiselect>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <VueSchedule v-model="schedule" :steps="interval" :dayTable="['Lun','Mar','Mie','Jue','Vie','Sab', 'Dom']"  :key="componentKey"/>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit" :disabled="isDisabled">Guardar </button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import axios from "axios";
import 'vue-daily-scheduler/dist/vue-schedule.min.css'
import VueSchedule from 'vue-daily-scheduler'
import moment from 'moment'

export default {
  name: "modal_weekly_schedule_form",
  props:[
    'queryType', 'personId', 'id', 'modalHeader', 'horarios', 'intervalo', 'stringHorario', 'scheduleDays'
  ],
  components: {
    VueSchedule
  },
  data(){
    return {
      project:{},
      nameDays: ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"],
      busqueda: "",
      currentPage:1,
      cardLayout:false,
      actualSchedule:{},
      errores_validacion:[],
      isLoading:false,
      isDisabled: false,
      timeArrayFront: [],
      timeArrayTime: [],
      interval: 60,
      stringSchedule: "",
      componentKey: 0,
      days: [],
      intervals: [{name: "10 min", number: 10}, {name: "15 min", number: 15}, {name: "20 min", number: 20}, {name: "30 min", number: 30}, {name: "40 min", number: 40},
        {name: "50 min", number: 50}, {name: "60 min", number: 60}],
      schedule: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: []
      }
    }
  },
  created() {
    this.isLoading=true;
    this.schedule = this.horarios
    this.interval = this.intervalo
    this.formatTime()
    this.stringSchedule = this.stringHorario
    this.days = this.scheduleDays
    this.forceRerender()
    this.isLoading=false;
  },
  methods: {
    formatTime(){
      this.timeArrayTime = []
      var initialHour = moment("00:00", "H:m");
      var actualHour = moment("00:00", "H:m");
      var finalHour = moment("23:00", "H:m");
      this.timeArrayTime.push({horaMoment: actualHour, horaFormateada: actualHour.format("HH:mm")})
      if(this.interval===10){
        while(actualHour.format("HH:mm")!==moment("23:50", "H:m").format("HH:mm")){
          actualHour.add(10, 'minutes')
          this.timeArrayTime.push({horaMoment: actualHour, horaFormateada: actualHour.format("HH:mm")})
        }
      }
      else if(this.interval===15){
        while(actualHour.format("HH:mm")!==moment("23:45", "H:mm").format("HH:mm")){
          actualHour.add(15, 'minutes')
          this.timeArrayTime.push({horaMoment: actualHour, horaFormateada: actualHour.format("HH:mm")})
        }
      }
      else if(this.interval===20){
        while(actualHour.format("HH:mm")!==moment("23:40", "H:m").format("HH:mm")){
          actualHour.add(20, 'minutes')
          this.timeArrayTime.push({horaMoment: actualHour, horaFormateada: actualHour.format("HH:mm")})
        }
      }
      else if(this.interval===30){
        while(actualHour.format("HH:mm")!==moment("23:30", "H:m").format("HH:mm")){
          actualHour.add(30, 'minutes')
          this.timeArrayTime.push({horaMoment: actualHour, horaFormateada: actualHour.format("HH:mm")})
        }
      }
      else if(this.interval===40){
        while(actualHour.format("HH:mm")!==moment("23:20", "H:m").format("HH:mm")){
          actualHour.add(40, 'minutes')
          this.timeArrayTime.push({horaMoment: actualHour, horaFormateada: actualHour.format("HH:mm")})
        }
      }
      else if(this.interval===50){
        while(actualHour.format("HH:mm")!==moment("23:20", "H:m").format("HH:mm")){
          actualHour.add(50, 'minutes')
          this.timeArrayTime.push({horaMoment: actualHour, horaFormateada: actualHour.format("HH:mm")})
        }
      }
      else{
        while(actualHour.format("HH:mm")!==finalHour.format("HH:mm")){
          actualHour.add(60, 'minutes')
          this.timeArrayTime.push({horaMoment: actualHour, horaFormateada: actualHour.format("HH:mm")})
        }
      }

    },
    deleteData(){
    },
    forceRerender() {
      this.componentKey += 1;
    },
    change(){
      this.formatTime()
      this.days = []
      this.schedule = {
        0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: []
      }
      this.stringSchedule = ""
      this.forceRerender()
    },
    lastHour(interval){
      if(interval===60){
        return moment("23:00", "H:m").format("HH:mm")
      }
      else if(interval===50){
        return moment("23:20", "H:m").format("HH:mm")
      }
      else if(interval===40){
        return moment("23:20", "H:m").format("HH:mm")
      }
      else if(interval===30){
        return moment("23:30", "H:m").format("HH:mm")
      }
      else if(interval===20){
        return moment("23:40", "H:m").format("HH:mm")
      }
      else if(interval===15){
        return moment("23:45", "H:mm").format("HH:mm")
      }
      else if(interval===10){
        return moment("23:50", "H:m").format("HH:mm")
      }
    },
    formatReturnSchedule(dia){
      this.schedule[dia].sort(function(a,b){return a - b;})
      var largoDia = this.schedule[dia].length
      var inicioIntervalo = 0
      var finIntervalo = 0
      for(var i = 0; i<largoDia;i++){
        if(largoDia===1 && this.timeArrayTime[this.schedule[dia][i]].horaFormateada!==this.lastHour(this.interval)){
          this.days.push({nombre: this.nameDays[dia], hora_inicial: this.timeArrayTime[this.schedule[dia][i]].horaFormateada, hora_final: this.timeArrayTime[this.schedule[dia][i]+1].horaFormateada, interval: this.interval})
        }
        else if(largoDia===1 && this.timeArrayTime[this.schedule[dia][i]].horaFormateada===this.lastHour(this.interval)){
          this.days.push({nombre: this.nameDays[dia], hora_inicial: this.timeArrayTime[this.schedule[dia][i]].horaFormateada, hora_final: moment("23:59", "H:m").format("HH:mm"), interval: this.interval})
        }
        if(this.schedule[dia][i+1]!==undefined){
          if(Math.abs(this.schedule[dia][i]-this.schedule[dia][i+1])>1){
            this.days.push({nombre: this.nameDays[dia], hora_inicial: this.timeArrayTime[this.schedule[dia][inicioIntervalo]].horaFormateada, hora_final: this.timeArrayTime[this.schedule[dia][i]+1].horaFormateada, interval: this.interval})
            inicioIntervalo=i+1
          }
        }
        else if(this.schedule[dia][i+1]===undefined && largoDia>1){
          if(this.timeArrayTime[this.schedule[dia][i]].horaFormateada===this.lastHour(this.interval)){
            this.days.push({nombre: this.nameDays[dia], hora_inicial: this.timeArrayTime[this.schedule[dia][inicioIntervalo]].horaFormateada, hora_final: moment("23:59", "H:m").format("HH:mm"), interval: this.interval})
          }
          else{
            this.days.push({nombre: this.nameDays[dia], hora_inicial: this.timeArrayTime[this.schedule[dia][inicioIntervalo]].horaFormateada, hora_final: this.timeArrayTime[this.schedule[dia][i]+1].horaFormateada, interval: this.interval})
          }
        }

      }
    },
    formatStringSchedule(){
      for(var i = 0; i < this.days.length;i++){
        this.stringSchedule = this.stringSchedule + this.days[i].nombre+" de "+this.days[i].hora_inicial+" a "+ this.days[i].hora_final+" "
      }
    },
    saveSchedule(){
      this.days = []
      this.stringSchedule = ""
      this.formatReturnSchedule(0)
      this.formatReturnSchedule(1)
      this.formatReturnSchedule(2)
      this.formatReturnSchedule(3)
      this.formatReturnSchedule(4)
      this.formatReturnSchedule(5)
      this.formatReturnSchedule(6)
      this.formatStringSchedule()
      this.$emit('clicked', this.schedule, this.days, this.stringSchedule, this.interval)
      this.$emit('close')
    }
  }
}
</script>
