<template>
<section>
  <b-modal width="90%" v-model="activeModal"
           aria-modal
           :can-cancel="canCancel"
           :on-cancel="closeModal">
    <div class="modal-card" style="width:100%">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="closeModal"/>
      </header>
      <section class="modal-card-body" ref="modalPersonForm" style="width:100%">
        <div class="columns">
          <div class="column is-12">
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
                <b-message type="is-danger" has-icon>
                  <div v-for="(item,index) in errores_validacion">
                    {{ item[0] }}
                  </div>
                </b-message>
              </div>
              <form @submit.prevent="handleSubmit(addFiliation)">
                <!-- Box de Formulario Project -->
                <div class="box">

                  <div class="columns">
                    <div class="column">
                      <b-field label="Tipo de entidad*">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <multiselect v-model="entity_type"
                                       :options="entity_types"
                                       :custom-label="opt => opt.alt_name ? opt.alt_name : opt.name"
                                       placeholder="Seleccione tipo de entidad"
                                       selectLabel="Presione para seleccionar"
                                       selectedLabel="Seleccionado"
                                       deselectLabel="No se puede deseleccionar"
                                       :allow-empty="false">
                            <template v-slot:noOptions>
                              No existen datos
                            </template>
                            <span slot="noResult">
                            No se encontraron elementos.
                        </span>
                          </multiselect>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div v-if="entity_type && Object.keys(entity_type).length!=0" class="column">
                      <b-field>
                        <template #label>
                          Entidad de Filiación* (ingrese por lo menos 3 letras)
                          <b-tooltip
                              label="Se recomienda búsqueda por sigla, ejemplo: ANID, no Agencia Nacional... mientras que para instituciones educacionales, se recomienda por nombre completo, ejemplo: universidad de santiago de chile"
                              position="is-right"
                              size="is-medium"
                              multilined>

                            <b-icon
                                pack="fas"
                                icon="info-circle"
                                size="is-small"
                                type="is-info">
                            </b-icon>
                          </b-tooltip>
                        </template>
                        <ValidationProvider rules="required" v-slot="{ validate, errors }">
                          <b-autocomplete
                              @input="validate"
                              :data="partner_entities"
                              placeholder="ej ANID o UNIVERSIDAD DE SANTIAGO DE CHILE  o NOMBRE EMPRESA - ENTIDAD"
                              field="name"
                              :loading="isFetching"
                              @typing="searchPartnerEntities"
                              v-model="modalPartnerEntity.name"
                              @select="option => {if (option) {modalPartnerEntity = option}}">

                            <template slot-scope="props">
                              {{ props.option.name }}
                            </template>
                          </b-autocomplete>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>

                      </b-field>
                    </div>


                  </div>
                  <br><br><br>

                  <div class="actions has-text-right">
                    <button class="button is-danger" icon-left="trash" @click.prevent="closeModal">Cancelar</button>
                    <button class="button is-primary" type="submit" icon-left="plus" @click="checkValidation()">Guardar Filiación</button>
                  </div>

                </div>


              </form>
            </ValidationObserver>

          </div>
        </div>
      </section>
    </div>
  </b-modal>
  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
</section>
</template>

<script>
import axios from "axios";
export default {
  name: "addParticipantFiliation",
  data() {
    return {
      canCancel: ['escape'],
      isLoading: false,
      partner_entities:[],
      errores_validacion:{},
      isFetching:false,
      entity_type:{},
      entity_types:[],
      modalPartnerEntity:{}
    }
  },
  props:['modalHeader','activeModal','partner_entity','participant'],
  async created() {
    //await this.getFinancingTypes();
    await this.getEntityTypes();
    if (this.partner_entity){
      this.entity_type = this.partner_entity.entity_type;
      this.modalPartnerEntity = this.partner_entity;
    }

  },
  watch:{
    activeModal(){
      if (this.partner_entity){
        this.entity_type = this.partner_entity.entity_type;
        this.modalPartnerEntity = this.partner_entity;
      }
      else{
        this.entity_type = {};
        this.modalPartnerEntity = {};
      }
    }
  },
  methods:{
    async checkValidation() {
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
    closeModal(){
      this.isLoading = true;
      this.entity_type = {};
      this.modalPartnerEntity = {};
      this.isLoading = false;
      this.$emit('close');
    },
    async getEntityTypes(){
      let vm = this;
      vm.isLoading = true;
      axios
        .get("/admin/entity_types.json")
        .then(res => {
          vm.entity_types = res.data;
          vm.isLoading = false;
        })
        .catch(error => {
          //console.log(error)
          vm.isLoading = false;
        })
    },
    addFiliation(){
      this.modalPartnerEntity.entity_type = this.entity_type;
      let response = {participant:this.participant, partner_entity:this.modalPartnerEntity}
      this.$emit('setFilitation',response);
      this.closeModal();
    },
    searchPartnerEntities(name){
      if (name.length<3) {
        this.partner_entities = [];
        return
      }
      let vm = this;
      vm.isLoading = true;
      axios
          .get("/admin/partner_entities/financings-search.json", { params: { name: name, entity_type:vm.entity_type } })
          .then(res => {
            vm.partner_entities = res.data;
            vm.isLoading = false;
          })
          .catch(error => {
            console.log(error)
            vm.isLoading = false;
          })

    },

  }

}
</script>

<style scoped>

</style>