<template>
  <div>
    <div>
      <div class="container">
        <div class="columns">
          <div class="column has-text-right">
            <b-field>
              <b-switch v-model="cardLayout">
                      <span>
                        Formato tarjeta (sin filtros ni búsqueda)
                         <b-tooltip
                             label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                             multilined
                             position="is-left">
                              <b-icon
                                  pack="fas"
                                  icon="info-circle"
                                  type="is-info">
                              </b-icon>
                          </b-tooltip>
                      </span>
              </b-switch>
            </b-field>
          </div>
        </div>
      </div>
      <b-table :data="people"
             :paginated=true
             per-page=10
             striped
             hoverable
             :current-page="currentPage"
             :card-layout="cardLayout"
             :pagination-simple=false
             pagination-position="bottom"
             default-sort-direction="asc"
             sort-icon="arrow-up"
             sort-icon-size="is-small"
             aria-next-label="Siguiente"
             aria-previous-label="Anterior"
             aria-page-label="Página"
             aria-current-label="Página actual"
             header-class="is-primary">
        <b-table-column
            field="run_attributes.run_completo"
            label="Rut"
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                placeholder=" Buscar"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.run_attributes != null ? props.row.run_attributes.run_completo : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="nombre_completo"
            label='Nombre Completo'
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            <a @click="goToPerson(props.row.id)"><i class="fas fa-user-circle"></i> {{props.row.nombre_completo}}</a>
          </template>
        </b-table-column>
        <b-table-column
            field="run_attributes.passport"
            label="Pasaporte"
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                placeholder=" Buscar"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.run_attributes != null ? props.row.run_attributes.passport : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="personal_mail"
            label="Email"
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                placeholder=" Buscar"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.personal_mail != null ? props.row.personal_mail : ""}}
          </template>
        </b-table-column>
        <template slot="empty" slot-scope="props">
          <div class="columns is-centered">
            <div class="column is-6 is-centered has-text-centered">
              <b-message type="is-danger">
                <h1>No se encontraron resultados</h1>
              </b-message>
            </div>
          </div>
        </template>
      </b-table>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PartnerEntitiesPerson",
  props: ['partnerEntityId'],
  data(){
    return {
      currentPage: 1,
      cardLayout: false,
      isLoading: false,
      canCancel: ['escape'],
      people: []
    }
  },
  created() {
    this.getPeople()
  },
  methods:{
    getPeople(){
      this.isLoading = true
      return axios
          .get("/people/people_by_entity/"+this.partnerEntityId+".json")
          .then(res => {
            this.people = res.data
            this.isLoading = false
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
          })
    },
    goToPerson(id){
      window.location.href='/people/'+id
    },
  },

}
</script>

<style scoped>

</style>