import { render, staticRenderFns } from "./NewMagazineModal.vue?vue&type=template&id=22407e1f&scoped=true"
import script from "./NewMagazineModal.vue?vue&type=script&lang=js"
export * from "./NewMagazineModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22407e1f",
  null
  
)

export default component.exports