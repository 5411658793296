<template>
  <section>
    <div class="container">
      <div class="box">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><a href="/life_record">Menú principal</a></li>
            <li class="is-active"><a href="#" aria-current="page">Contratos renovables</a></li>
          </ul>
        </nav>
        <div class="columns">
          <div class="column">
            <div class="control">
              <h1 class="title">Renovación Automática por Estamentos</h1>
            </div>
          </div>
        </div>
        <b-tabs @input="changeTab" type="is-boxed" :destroy-on-hide="true" v-model="currentTab">
          <b-tab-item v-for="item in items"
                      :value="item.content"
                      v-bind:key="item.content"
                      :label="item.tab" >
          </b-tab-item>
        </b-tabs>
        <!-- COMPONENTE AQUI -->
          <component v-bind:is="currentTabComponent"></component>
      <!-- FIN COMPONENTE -->
      </div>
    </div>
  </section>
</template>
<script>
import RenewAcademic from "../life_record/renew_appointments/renew_academic"
import RenewAdministrative from "../life_record/renew_appointments/renew_administrative"
import RenewHourClass from "../life_record/renew_appointments/renew_hourclass"
export default {
  name: "list_renewable_appointments",
  data(){
    return {
      currentTab: 'RenewAdministrative',
      unit: "TODAS",
      units: [],
      items: [
        {tab: 'Administrativos/as',content:'RenewAdministrative'},
        {tab: 'Académicos/as',content:'RenewAcademic'},
        {tab: 'Horas Clase',content:'RenewHourClass'},
      ]
    }
  },
  components:{
    RenewAcademic,
    RenewHourClass,
    RenewAdministrative,
  },
  methods:{
    changeTab: function(value){
      this.currentTab = value
    },
  },
  computed: {
    currentTabComponent: function () {
      return this.currentTab
    }
  }
}
</script>

