<template>
  <section>
    <h3 class="is-size-4 has-text-weight-bold has-text-centered">Tabla de Participantes</h3>
    <div class="has-text-right">
      <b-button v-if="publication.can_edit && publication.academic_activity_state_id == 1" type="is-info"
                icon-left="edit"
                @click="editPublication">
        Editar Participantes
      </b-button>
    </div>
    <div class="column">
      <b-table
          :data="participants"
          :paginated=true
          per-page=5
          striped
          hoverable
          :current-page="currentPage"
          :card-layout="cardLayout"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="desc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="last_patent_state_date"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field= 'person_name'
            label= 'Nombres'
            width="15em"
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            <a @click="goToProfile(props.row.person_id)">{{ props.row.person_name }}</a>
          </template>
        </b-table-column>
        <b-table-column
            field= 'person_mail'
            label= 'Correo Corporativo'
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{  props.row.person_mail ? props.row.person_mail.toUpperCase() : '' }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'unit_name'
            label= 'Unidad'
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{  props.row.unit_name }}
          </template>
        </b-table-column>
        <b-table-column
            label="Tipo de Participación"
            v-slot="props">
          <template>
            {{  props.row.participant_type }}
          </template>
        </b-table-column>
        <b-table-column
            field="is_doc_student"
            label="¿Es estudiante de doctorado?"
            v-slot="props">
          <b-checkbox disabled v-model="props.row.is_doc_student">
          </b-checkbox>
        </b-table-column>
        <b-table-column
            field="partner_entity_id"
            label="Filiación"
            v-slot="props">
          {{ props.row.partner_entity ? props.row.partner_entity.name : "" }}
        </b-table-column>
        <template slot="empty" slot-scope="props">
          <div class="columns is-centered">
            <div class="column is-6 is-centered has-text-centered">
              <b-message type="is-danger">
                No se encontraron participantes.
              </b-message>
            </div>
          </div>
        </template>
      </b-table>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        :active="isComponentModalActive"
        width="90%"
        :can-cancel="canCancel"
        aria-modal>
      <template #default="props">
        <new-publication-form modal-cancel="true"
                              :cargo-principal="cargoPrincipal"
                              :user-id="userId"
                              :show-participants-first="true"
                              modal-header="Editar Publicación"
                              query-type="update" v-bind:publication-id="publication.id" @close="closeModal"></new-publication-form>
      </template>
    </b-modal>
  </section>
</template>

<script>
import axios from "axios";
import newPublicationForm from './NewPublicationForm';
export default {
  name: "PublicationParticipantsTab",
  props:[ 'participants', 'publication', 'cargoPrincipal', 'canEdit', 'userId'],
  components:{newPublicationForm},
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      years:[],
      year:null,
      isComponentModalActive:false,
      canCancel: ['escape']
    }
  },
  created(){

  },
  methods:{
    editPublication(){
      this.isComponentModalActive = true;
    },
    closeModal(){
      this.isComponentModalActive = false;
    },
    goToProfile(id){
      window.location.href='/people/'+id
    },
  }

}
</script>

<style scoped>

</style>