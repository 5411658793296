<template>
    <div class="columns is-multiline">
        <div class="column is-3 py-0">
            <b-sidebar position="static" type="is-light" open>
                <div class="p-1 pt-4">
                    <div class="block mt-5">
                        <h1 class="subtitle is-3 has-text-primary has-text-left">Índice</h1>
                    </div>
                    <b-menu class="is-custom-mobile">
                        <b-menu-list>
                            <b-menu-item :active="tutorial === 'academic_activity' ? true : false" @click="refresh('academic_activity')" label="1. Mi Actividad Académica"></b-menu-item>
                            <b-menu-item :active="tutorial === 'life_record' ? true : false" @click="refresh('life_record')" label="2. Mi Hoja de Vida"></b-menu-item>
                            <b-menu-item :active="tutorial === 'profile' ? true : false" @click="refresh('profile')" label="3. Mi Perfil"></b-menu-item>
                            <b-menu-item :active="tutorial === 'search_help' ? true : false" @click="refresh('search_help')" label="4. Búsqueda y Ayuda"></b-menu-item>
                            <b-menu-item :active="tutorial === 'agreements' ? true : false" @click="refresh('agreements')" label="5. Convenios"></b-menu-item>
                            <b-menu-item :active="tutorial === 'documentation' ? true : false" @click="refresh('documentation')" label="6. Documentación"></b-menu-item>
                        </b-menu-list>
                    </b-menu>
                </div>
            </b-sidebar>
        </div>
        <div v-if="tutorial === 'academic_activity'" class="column is-9">
            <academic_activity :first_time="first_academic_activity"></academic_activity>
        </div>
        <div v-if="tutorial === 'life_record'" class="column is-9">
            <my_life_record :first_time="first_life_record"></my_life_record>
        </div>
        <div v-if="tutorial === 'profile'" class="column is-9">
            <my_profile :first_time="first_profile"></my_profile>
        </div>
        <div v-if="tutorial === 'search_help'" class="column is-9">
            <search_help :first_time="first_search_help"></search_help>
        </div>
        <div v-if="tutorial === 'agreements'" class="column is-9">
            <agreements :first_time="first_agreements"></agreements>
        </div>
        <div v-if="tutorial === 'documentation'" class="column is-9">
            <documentation :first_time="first_documentation"></documentation>
        </div>
    </div>
</template>

<script>
import Academic_activity from "./academic_tutorials/academic_activity.vue";
import my_life_record from "./academic_tutorials/my_life_record.vue";
import my_profile from "./academic_tutorials/my_profile.vue";
import search_help from "./academic_tutorials/search_help.vue";
import agreements from "./academic_tutorials/agreements.vue";
import documentation from "./academic_tutorials/documentation.vue";
export default {
    name: "academic",
    components: {Academic_activity, my_life_record, my_profile, search_help, agreements, documentation},
    props:['first_time'],
    data(){
        return{
            tutorial:"academic_activity",
            key_academic_activity: 0,
            first_academic_activity: true,
            first_life_record: true,
            first_profile: true,
            first_search_help: true,
            first_agreements: true,
            first_documentation: true,
        }
    },
    created() {
      this.first_academic_activity = this.first_time
    },
    methods:{
        refresh(name){
            this.tutorial = name
            if (name === "academic_activity"){
                this.first_academic_activity = false
            }
            else if(name === "life_record"){
                this.first_life_record = false
            }
            else if(name === "profile"){
                this.first_profile = false
            }
            else if(name === "search_help"){
                this.first_search_help = false
            }
            else if(name === "agreements"){
                this.first_agreements = false
            }
            else if(name === "documentation"){
                this.first_documentation = false
            }

        }
    }
}
</script>

<style scoped>
.b-sidebar .sidebar-content.is-absolute,
.b-sidebar .sidebar-content.is-static {
    width: 100%;
    background-color: white;
    box-shadow: none;
}
.icon-up{
    position: fixed;
    width: 16.5% !important;
    bottom: 0;
    left: 15%;
}



</style>