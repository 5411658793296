<template>
  <section>
    <div class="box">
      <b-message type="is-warning" has-icon title="Importante" aria-close-label="Close message">
        Si encuentra información desactualizada o errónea en su hoja de vida, por favor comuníquese con
        el jefe administrativo de su departamento y comunique el error o solicite la actualización
        de su información correspondiente a Datos Personales, Nombramientos, Funciones, Asignaciones Familiares, Estudios, Comisiones y Eventos.
      </b-message>
    </div>
  </section>
</template>
<script>
export default {
  name: "contact_info",
  data(){
    return {
    }
  },
  methods:{

  }
}
</script>