<template>
    <section>
        <b-tabs position="is-centered" @input="changeTab" :multiline="true">
            <b-tab-item v-for="item in items" v-bind:key="item.content" :value="item.content" :label="item.tab"
                :icon="item.icon" />
        </b-tabs>
        <!-- COMPONENTE AQUI -->
        <keep-alive>
            <component v-bind:is="currentTabComponent" :object-id="personId" :photo-url="photoUrl" :person="person"
                :component-type="'person'" :cargo-principal="cargoPrincipal" :currentYear="currentYear" :min-year="minYear"
                :max-year="maxYear" :change-filter="changeFilter" :hide-update="true"
                :can-create-document="canCreateDocument" />
        </keep-alive>

        <!-- FIN COMPONENTE -->
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </section>
</template>

<script>
import MyPerformances from "../performances/performances_tab.vue"
export default {
    components:{
        MyPerformances,
    },
    props:[
        'personId','photoUrl', 'cargoPrincipal', 'canCreateDocument', 'minYear', 'maxYear', 'changeFilter', 'currentYear', 'person'
    ],
    data() {
        return {
            isLoading:false,
            currentTab: 'MyPerformances',
            items: [
                {tab: 'Evaluación y Calificación del Desempeño',content: 'MyPerformances', icon:"list-alt"},
            ],
        }
    },
    methods: {
        changeTab: function(value){
            this.currentTab = value
        },
    },
    computed: {
        currentTabComponent: function () {
            return this.currentTab
        }
    }
}
</script>