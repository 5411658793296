<template>
<div class="columns">
  <div class="column">

    <div>
      <br>
      <div class="columns is-centered">
        <div class="column is-11">
          <b-message title="Importante" type="is-info" has-icon aria-close-label="Cerrar" size="is-small">
            La información de actividad académica generada con filtro por año activado, se dará según los siguientes criterios:
            <ul>
              <li>Patentes: patentes presentadas el año seleccionado.</li>
              <li>Proyectos: proyectos en curso el año seleccionado.</li>
              <li>Publicaciones: publicaciones publicadas el año seleccionado.</li>
              <li>Disclosures: disclosures con fecha de recepción el año seleccionado.</li>
            </ul>
          </b-message>
        </div>
      </div>
    </div>


    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(exportData)">
        <br>
        <div class="columns is-centered">
          <div class="column is-11">
            <div class="box">
              <div class="columns">
                <div class="column">
                  <h1 class="title is-4">Exportación de actividad académica</h1>
                  <b-field label="Tipo de actividad académica a exportar*">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <multiselect v-model="export_type"
                                   :options="export_types"
                                   placeholder="Seleccione el tipo de archivo a exportar"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="No se puede deseleccionar"
                                   :allow-empty="false">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                          No se encontraron elementos.
                        </span>
                      </multiselect>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>


              <div class="columns">
                <div class="column">
                  <b-field label="Unidad*">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <multiselect v-model="unitId"
                                   :options="units.map(type => type.id)"
                                   :custom-label="opt => units.find(x => x.id === opt).name"
                                   placeholder="Seleccione una unidad"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="No se puede deseleccionar"
                                   :allow-empty="false">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                      </multiselect>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>

                </div>
                <div class="column">
                  <b-field label="Año*">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <multiselect v-model="year"
                                   :options="years"
                                   placeholder="Seleccione un año"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="No se puede deseleccionar"
                                   :allow-empty="false">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                      </multiselect>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>

                </div>
              </div>

              <div class="has-text-right">
                <b-button v-if="export_type.length>0" type="is-primary" native-type="submit">Exportar</b-button>
              </div>

            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>



  </div>
</div>
</template>

<script>
import axios from "axios";
export default {
  name: "exportAcademicActivity",
  data(){
    return{
      export_type:"",
      export_types:["PATENTES","PROYECTOS"],
      unitId:null,
      year:null,
      years:[],
      units:[],
      unit:{},
      isLoading:false

    }
  },
  created(){
    let vm = this;
    vm.years = vm.generateArrayOfYears();
    vm.isLoading=vm;
    axios
        .get("/load-data/export/academic-activity.json")
        .then(res => {
          vm.units = res.data["units"];
          vm.export_types = res.data["academic_activity"];
          vm.isLoading=false;
        }).catch(error => {
          //console.log(error)
          vm.isLoading=false;
        })

  },
  props:[

  ],
  methods:{
    exportData(){
      let vm = this;
      if (vm.export_type == "PATENTES"){
        vm.exportPatents();
      }
      else if (vm.export_type == "PROYECTOS"){
        vm.exportProjects();
      }
      else if (vm.export_type == "PUBLICACIONES"){
        vm.exportPublications();
      }
      else if (vm.export_type == "DISCLOSURES"){
        vm.exportDisclosures();
      }
      else if (vm.export_type == "PERSONAS"){
        vm.exportPeople();
      }
      else if (vm.export_type == "PUBLICACIONES LITE"){
        vm.exportPublicationsSuperAdmin();
      }

    },
    exportPublicationsSuperAdmin(){
      this.isLoading=true;
      var url = "/publications/export-publications-superadmin.xlsx?year_f="+this.year;
      if (this.unitId){
        url = url+"&unit_f="+this.unitId;
      }
      window.location.href = url;
      this.isLoading = false;
    },
    exportPatents(){
      //let vm = this;
      var url = "/patents/export-patents.xlsx?year_f="+this.year;
      if (this.unitId){
        url = url+"&unit_f="+this.unitId;
      }
      window.open(url);
      this.isLoading = false;
    },
    exportProjects(){
      //let vm = this;
      var url = "/projects/export-projects.xlsx?year_f="+this.year;
      if (this.unitId){
        url = url+"&unit_f="+this.unitId;
      }
      window.open(url);
      this.isLoading = false;
    },
    exportPublications(){
      this.isLoading=true;
      var url = "/publications/export-publications.xlsx?year_f="+this.year;
      if (this.unitId){
        url = url+"&unit_f="+this.unitId;
      }
      window.open(url);
      this.isLoading = false;
    },
    exportDisclosures(){
      this.isLoading=true;
      var url = "/disclosures/export-disclosures.xlsx?year_f="+this.year;
      if (this.unitId){
        url = url+"&unit_f="+this.unitId;
      }
      window.open(url);
      this.isLoading = false;
    },
    exportPeople(){
      this.isLoading=true;
      var url = "/people/export-i-d-people.xlsx?year_f="+this.year;
      if (this.unitId){
        url = url+"&unit_f="+this.unitId;
      }
      window.open(url);
      this.isLoading = false;
    },
    generateArrayOfYears() {
      var max = new Date().getFullYear()
      var min = 1980
      var years = []
      years.push("Todo")
      for (var i = max; i >= min; i--) {
        years.push(i)
      }
      return years
    },

  },
  computed:{

  }
}
</script>

<style scoped>

</style>