var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveUnit)}}},[_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head",attrs:{"id":"modal-event"}},[_c('p',{staticClass:"modal-card-title"},[_vm._v(_vm._s(_vm.modalHeader))]),_vm._v(" "),_c('button',{staticClass:"delete",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}})]),_vm._v(" "),_c('section',{staticClass:"modal-card-body",attrs:{"id":"modal-event2"}},[(Object.keys(_vm.errores_validacion).length !== 0)?_c('div',{staticClass:"mb-2"},[_c('b-message',{attrs:{"type":"is-danger","has-icon":""}},_vm._l((_vm.errores_validacion),function(item,index){return _c('div',{key:index},[(item)?_c('div',[_vm._v(_vm._s(item[0]))]):_vm._e()])}),0)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Nombre*"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"text","minlength":"3","maxlength":"100"},model:{value:(_vm.unit.name),callback:function ($$v) {_vm.$set(_vm.unit, "name", $$v)},expression:"unit.name"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_vm._v(" "),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Tipo de Unidad*"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.unit_types.map(function (type) { return type.id; }),"custom-label":function (opt) { return _vm.unit_types.find(function (x) { return x.id === opt; }).name; },"placeholder":"Seleccione tipo de unidad","selectLabel":"Presione para seleccionar","selectedLabel":"Seleccionado","deselectLabel":"No se puede deseleccionar","allow-empty":false},scopedSlots:_vm._u([{key:"noOptions",fn:function(){return [_vm._v("\n                          No existen datos\n                        ")]},proxy:true}],null,true),model:{value:(_vm.unit.unit_type_id),callback:function ($$v) {_vm.$set(_vm.unit, "unit_type_id", $$v)},expression:"unit.unit_type_id"}},[_vm._v(" "),_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("\n                          No se encontraron elementos.\n                        ")])]),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Nombre corto"}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"text","minlength":"3","maxlength":"50"},model:{value:(_vm.unit.short_name),callback:function ($$v) {_vm.$set(_vm.unit, "short_name", $$v)},expression:"unit.short_name"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_vm._v(" "),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Centro de Costo*"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"number"},model:{value:(_vm.unit.cost_center),callback:function ($$v) {_vm.$set(_vm.unit, "cost_center", $$v)},expression:"unit.cost_center"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[(_vm.unit.unit_type_id === 1 || _vm.unit.unit_type_id === 3)?_c('div',[_c('b-field',{attrs:{"label":"Unidad Mayor"}},[_c('multiselect',{attrs:{"options":_vm.faculties.map(function (type) { return type.cost_center; }),"custom-label":function (opt) { return _vm.faculties.find(function (x) { return x.cost_center === opt; }).name; },"placeholder":"Seleccione Centro de Costo de Facultad","selectLabel":"Presione para seleccionar","selectedLabel":"Seleccionado","deselectLabel":"Presione deseleccionar","allow-empty":true},scopedSlots:_vm._u([{key:"noOptions",fn:function(){return [_vm._v("\n                          No existen datos\n                        ")]},proxy:true}],null,true),model:{value:(_vm.unit.faculty_cost_center),callback:function ($$v) {_vm.$set(_vm.unit, "faculty_cost_center", $$v)},expression:"unit.faculty_cost_center"}},[_vm._v(" "),_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("\n                          No se encontraron elementos.\n                        ")])])],1)],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"¿Unidad de contratación?"}},[_c('b-checkbox',{attrs:{"label":""},model:{value:(_vm.unit.contracting_unit),callback:function ($$v) {_vm.$set(_vm.unit, "contracting_unit", $$v)},expression:"unit.contracting_unit"}},[_vm._v(" Sí\n                    ")])],1)],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Url"}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"text","maxlength":"100"},model:{value:(_vm.unit.url),callback:function ($$v) {_vm.$set(_vm.unit, "url", $$v)},expression:"unit.url"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_vm._v(" "),_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}})],1)]),_vm._v(" "),_c('footer',{staticClass:"modal-card-foot actions"},[_c('div',{staticClass:"actions has-text-right"},[_c('button',{staticClass:"button",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Cerrar")]),_vm._v(" "),_c('button',{staticClass:"button is-primary",attrs:{"type":"submit","disabled":_vm.isDisabled}},[_vm._v("Guardar Unidad")])])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }