<template>
  <div class="section">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <div class="field is-grouped">
          <p class="control">
            <b-button class="is-primary is-outlined"
                      @click="isGraduate = true">
              Diplomado
            </b-button>
          </p>
          <p class="control">
            <b-button class="is-primary is-outlined"
                      @click="isCertification = true">
              Certificación
            </b-button>
          </p>
          <p class="control">
            <b-button class="is-primary is-outlined"
                      @click="isLanguageCertification = true">
              Certificación de Idioma
            </b-button>
          </p>
          <p class="control">
            <b-button class="is-primary is-outlined"
                      @click="isCourse = true">
              Curso
            </b-button>
          </p>
          <p class="control">
            <b-button class="is-primary is-outlined"
                      @click="isOther = true">
              Otro
            </b-button>
          </p>
        </div>
      </section>

    </div>
    <footer class="modal-card-foot actions">
      <div class="field is-grouped is-pulled-right">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
      </div>
    </footer>

    <b-modal
        v-model="isGraduate"
        has-modal-card
        trap-focus
        width="1024"
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-graduate-form :person-id="personId"
                                 query-type="create"
                                 :modal-header="'Nuevo Diplomado - '  +person_name"
                                 @close="props.close"></modal-graduate-form>
      </template>
    </b-modal>

    <b-modal
        v-model="isCourse"
        has-modal-card
        trap-focus
        width="1024"
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-course-form :person-id="personId"
                             query-type="create"
                             :modal-header="'Nuevo Curso - '  +person_name"
                             @close="props.close"></modal-course-form>
      </template>
    </b-modal>

    <b-modal
        v-model="isCertification"
        has-modal-card
        trap-focus
        width="1024"
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-certificate-form :person-id="personId"
                           query-type="create"
                           :modal-header="'Nueva Certificación - '  +person_name"
                           @close="props.close"></modal-certificate-form>
      </template>
    </b-modal>

    <b-modal
        v-model="isLanguageCertification"
        has-modal-card
        trap-focus
        width="1024"
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-language-certificate-form :person-id="personId"
                                query-type="create"
                                :modal-header="'Nueva Certificación de Idioma - '  +person_name"
                                @close="props.close"></modal-language-certificate-form>
      </template>
    </b-modal>

    <b-modal
        v-model="isOther"
        has-modal-card
        trap-focus
        width="1024"
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-other-form :person-id="personId"
                                         query-type="create"
                                         :modal-header="'Nuevo Perfeccionamiento - '  +person_name"
                                         @close="props.close"></modal-other-form>
      </template>
    </b-modal>


  </div>

</template>

<script>
import ModalGraduateForm from "./modal_graduate_form";
import ModalCourseForm from "./modal_course_form"
import ModalCertificateForm from "./modal_certificate_form"
import ModalLanguageCertificateForm from "./modal_language_certificate_form"
import ModalOtherForm from "./modal_other_form"
export default {
  name: "select_continuing_studies_type",
  props:[
    'queryType', 'personId', 'modalHeader', 'person_name'
  ],
  components:{
    ModalGraduateForm,
    ModalCourseForm,
    ModalCertificateForm,
    ModalLanguageCertificateForm,
    ModalOtherForm
  },
  data(){
    return{
      isGraduate: false,
      isCertification: false,
      isLanguageCertification: false,
      isCourse: false,
      isOther: false,
      isLoading: true,
      canCancel: []
    }
  },
  created() {
    this.isLoading =false
  },
  methods:{

  }
}
</script>

<style scoped>

</style>
