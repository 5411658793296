<template>
  <section>
    <div class="container">
      <div class="has-text-right mb-1">
        <b-button class="is-primary is-outlined"
                  icon-left="plus"
                  v-if="can_create_continuing_study==='true'"
                  @click="isAddModalActive = true">
          Añadir Educación Continua
        </b-button>
      </div>

      <!--DATA TABLE-->
      <b-table
          :data="continuing_studies"
          :paginated= true
          per-page="10"
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="product.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field="obtaining_date"
            label="Fecha obtención"
            width="110"
            sortable
            searchable :custom-search="searchEgressDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.obtaining_date._isValid ? new Date(props.row.obtaining_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            field="continuing_study"
            label="Tipo"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.continuing_studies_type!==undefined ? props.row.continuing_studies_type.name : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="name"
            label="Nombre"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row!==undefined ? props.row.name : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="study.entity_show_name"
            label="Institución"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.entity_show_name}}
          </template>
        </b-table-column>
        <b-table-column
            label="Acciones"
            width="125"
            v-slot="props">

          <b-tooltip
              v-if="props.row.can_show"
              label="Ver información completa del estudio."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="eye"
                type="is-primary"
                @click="execShowModal(props.row.id)"/>
          </b-tooltip>


          <b-tooltip
              v-if="props.row.can_edit"
              label="Editar información del estudio."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="edit"
                type="is-info is-light is-outlined"
                @click="execEditModal(props.row.id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_nullify"
              label="Anular estudio."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="trash"
                type="is-danger is-light is-outlined"
                @click="execNullifyModal(props.row.id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_view_pdf"
              label="Visualizar documento asociado al estudio."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="file-alt"
                type="is-success is-light is-outlined"
                @click="viewFile(props.row.document_id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_view_pdf"
              label="Descargar documento asociado al estudio."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="download"
                type="is-primary is-light is-outlined"
                @click="downloadFile(props.row.document_id)"/>
          </b-tooltip>

        </b-table-column>
      </b-table>
      <!--FIN DATA TABLE-->
      <!-- MODALES -->
      <b-modal
          v-model="isAddModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <select-continuing-studies-type
              :person-id="personId"
              query-type="create"
              modal-header="Seleccionar Tipo de Educación Continua"
              :person_name="person_name"
              @close="props.close"></select-continuing-studies-type>
        </template>
      </b-modal>

      <b-modal
          v-model="isShowModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          width="1024">
        <template #default="props">
          <component  :is="showName"
                      :id="selected"
                      :person-id="personId"
                      :modal-header="title"
                      :person_name="person_name"
                      @close="props.close"/>
        </template>
      </b-modal>
      <b-modal
          v-model="isEditModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          width="1024"
          :can-cancel="canCancel">
        <template #default="props">
          <component
                      :is="formName"
                      :person-id="personId"
                      :id="selected"
                      query-type="update"
                      :modal-header="title"
                      :person_name="person_name"
                      @close="props.close"/>
        </template>
      </b-modal>
      <b-modal
          v-model="showPdf"
          :can-cancel="true"
          :destroy-on-hide="true"
          aria-modal
          aria-role="dialog"
          has-modal-card
          trap-focus
          :width="2000"
      >
        <template #default="props">
          <pdf_view
              :src="src"
              @close="props.close"
          ></pdf_view>
        </template>

      </b-modal>
      <b-loading :is-full-page="true"
                 v-model="isLoading"
                 :can-cancel="false"/>
    </div>
  </section>
</template>

<script>

import axios from "axios";
import SelectContinuingStudiesType from "./select_continuing_studies_type";
import ModalGraduateForm from "./modal_graduate_form"
import Course from './course'
import ModalCourseForm from './modal_course_form'
import Graduate from './graduate'
import ModalCertificateForm from './modal_certificate_form'
import Certificate from './certificate'
import ModalLanguageCertificateForm from './modal_language_certificate_form'
import LanguageCertificate from "./language_certificate"
import ModalOtherForm from "./modal_other_form"
import Other from "./other"
import {searchDate, formatDate} from "../../../packs/utilities";
import moment from "moment";
import Pdf_view from "../../performances/pdf_view.vue"
export default {
  name: "continuing_studies",
  props:[
    'personId', 'can_create_continuing_study', 'person_name'
  ],
  components:{
    SelectContinuingStudiesType,
    Graduate,
    ModalGraduateForm,
    Course,
    ModalCourseForm,
    ModalCertificateForm,
    Certificate,
    LanguageCertificate,
    ModalLanguageCertificateForm,
    ModalOtherForm,
    Other,
    Pdf_view
  },
  data(){
    return {
      selected: null,
      index: null,
      canCancel: [],
      currentPage: 1,
      isAddModalActive: false,
      isEditModalActive: false,
      isShowModalActive: false,
      isLoading:false,
      continuing_studies: [],
      isNullifyActive: false,
      showName: null,
      title: null,
      formName: null,
      showPdf: false,
      src:null,
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    execNullifyModal(id){
      this.$buefy.dialog.confirm({
        title: 'Anular perfeccionamiento',
        message: '¿Está seguro que desea <b>anular</b> el perfeccionamiento? Esta acción lo dejará inválido.<br>',
        confirmText: 'Anular Perfeccionamiento',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/admin/continuing_studies/" + id + "/nullify.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Perfeccionamiento anulado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                this.isLoading = false;
                this.isNullifyActive = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Perfeccionamiento no pudo ser anulado</b><br>',
              duration: 5000,
              type: 'is-damger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },
    initialize(){
      this.isLoading = true;
      return axios
          .get("/people/"+this.personId+"/continuing-studies.json")
          .then(res => {
            this.continuing_studies = res.data
            this.continuing_studies = this.continuing_studies.map(study => {
              var newStudy = study
              newStudy["obtaining_date"] = moment(newStudy["obtaining_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newStudy;
            });
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })

    },
    execShowModal(id){
      this.selected = id;
      let continuing_study = this.getType(id)
      if(continuing_study.continuing_studies_type!== undefined){
        if(continuing_study.continuing_studies_type.name==="DIPLOMADO") {
          this.showName = "Graduate"
          this.title = "Diplomado"+" - "+this.person_name
        }
        else if (continuing_study.continuing_studies_type.name==="CURSO") {
          this.showName = "Course"
          this.title = "Curso"+" - "+this.person_name
        }
        else if (continuing_study.continuing_studies_type.name==="CERTIFICACION") {
          this.showName = "Certificate"
          this.title = "Certificación"+" - "+this.person_name
        }
        else if (continuing_study.continuing_studies_type.name==="CERTIFICACION DE IDIOMA") {
          this.showName = "LanguageCertificate"
          this.title = "Certificación de Idioma"+" - "+this.person_name
        }
        else {
          this.showName = "Other"
          this.title = "Perfeccionamiento"+" - "+this.person_name
        }
      }
      else{
        this.showName = "Other"
        this.title = "Perfeccionamiento"+" - "+this.person_name
      }
      //console.log("APPOINTMENT "+ this.showName)
      this.isShowModalActive = true;
    },
    getType(selected){
      return this.continuing_studies.find(o => o.id === selected);
    },
    execEditModal(id){
      this.selected = id;
      let continuing_study = this.getType(id)
      if(continuing_study.continuing_studies_type!== undefined){
        if(continuing_study.continuing_studies_type.name==="DIPLOMADO") {
          this.formName = "ModalGraduateForm"
          this.title = "Editar Diplomado"+" - "+this.person_name
        }
        else if (continuing_study.continuing_studies_type.name==="CURSO") {
          this.formName = "ModalCourseForm"
          this.title = "Editar Curso"+" - "+this.person_name
        }
        else if (continuing_study.continuing_studies_type.name==="CERTIFICACION") {
          this.formName = "ModalCertificateForm"
          this.title = "Editar Certificación"+" - "+this.person_name
        }
        else if (continuing_study.continuing_studies_type.name==="CERTIFICACION DE IDIOMA") {
          this.formName = "ModalLanguageCertificateForm"
          this.title = "Editar Certificación de Idioma"+" - "+this.person_name
        }
        else {
          this.formName = "ModalOtherForm"
          this.title = "Editar Perfeccionamiento"+" - "+this.person_name
        }
      }
      else{
        this.formName = "ModalOtherForm"
        this.title = "Editar Perfeccionamiento"+" - "+this.person_name
      }
      //console.log("APPOINTMENT "+ this.showName)
      this.isEditModalActive = true;
    },
    // yyyy/mm/dd -> dd/mm/yyyy
    formatDate,
    searchEgressDate(row, input){
      return searchDate(row.obtaining_date, input)
    },
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            this.showPdf = true
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    downloadFile(fileId){
      this.isLoading = true;

      if (fileId == null){
        this.isLoading = false;
        return;
      }

      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(res => {
            let blob = new Blob([res.data], {
              type: "application/pdf",
            })
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = res.headers["content-disposition"]
                .split(";")[1]
                .split('"')[1];
            link.click();
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento fue descargado con exito',
              type: 'is-success',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
          .catch(e => {
            console.log(e)
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento no se pudo descargar',
              type: 'is-danger',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
    },
  },
  watch:{
    isNullifyActive: function () {
      if (!this.isNullifyActive){
        this.initialize()
      }
    },
    isAddModalActive: function () {
      if (!this.isAddModalActive) {
        this.initialize()
      }
    },
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
    isShowModalActive: function () {
      if (!this.isShowModalActive) {
        this.initialize()
      }
    },

  }
}
</script>

<style scoped>

</style>