<template>
  <section>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
          <section class="modal-card-body" ref="modalFeeContractForm">
            <div class="columns">
              <div class="column">
                <b-button class="is-danger" v-if="person_unit.can_distance"
                          @click="isDistanceModalActive = true">
                  Alejamiento
                </b-button>
              </div>
            </div>
            <div class ="columns">
              <div class="column">
                <b-dropdown aria-role="list" v-if="person_unit.can_rectify">
                  <template #trigger="{ active }">
                    <b-button
                        label="Acciones"
                        type="is-warning"
                        :icon-right="active ? 'chevron-circle-up' : 'chevron-circle-down'"/>
                  </template>


                  <b-dropdown-item aria-role="listitem" @click="rectify()">Rectificar</b-dropdown-item>
                  <b-dropdown-item aria-role="listitem" @click="addHours()">Aumento Horas</b-dropdown-item>
                  <b-dropdown-item aria-role="listitem" @click="reduceHours()">Reducción Horas</b-dropdown-item>
                  <b-dropdown-item aria-role="listitem" @click="changeCC()">Cambio CC</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción">
                  <b-input :value="person_unit.description" type="textarea" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de documento">
                  <b-input :value="person_unit.resolution_type!==undefined ? person_unit.resolution_type.name : ''" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="N° de documento">
                  <b-input :value="person_unit.resolution" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha documento">
                  <b-input :value="formatDate(person_unit.resolution_date)" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Unidad de contratación">
                  <b-input :value="person_unit.contracting_unit!==undefined ? person_unit.contracting_unit.name : ''" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Unidad de desempeño">
                  <b-input :value="person_unit.unit!==undefined? person_unit.unit.name : ''" type="text" readonly></b-input>
                </b-field>
              </div>
              </div>
            <div class="columns">
              <div class="column">
                <b-field label="Calidad">
                  <b-input :value="person_unit.quality!==undefined? person_unit.quality : ''" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Labor">
                  <b-input :value="person_unit.labour!==undefined? person_unit.labour : ''" type="textarea" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción de Labor que Realiza">
                  <b-input :value="person_unit.labour_description!==undefined? person_unit.labour_description : ''" type="textarea" readonly></b-input>

                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Lugar donde realiza el trabajo">
                  <b-input :value="person_unit.labour_place!==undefined? person_unit.labour_place : ''" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha desde">
                  <b-input :value="formatDate(person_unit.start_date)" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha hasta">
                  <b-input :value="formatDate(person_unit.end_date)" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Horario">
                  <b-input :value="person_unit.schedule!==undefined? person_unit.schedule : ''" type="text" readonly expanded></b-input>
                    <p class="control">
                      <button class="button is-primary" @click.prevent="showSchedule">Ver Horario </button>
                    </p>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Monto">
                  <div class="input">
                  <money-format :value="person_unit.amount"
                                locale='es-CL'
                                currency-code='CLP'
                                :subunits-value=true
                                >
                  </money-format>
                  </div>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Total Convenio">
                  <div class="input">
                  <money-format :value="person_unit.total_amount!==undefined && person_unit.total_amount!==null? person_unit.total_amount : 0"
                                locale='es-CL'
                                currency-code='CLP'
                                :subunits-value=true
                  >
                  </money-format>
                  </div>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Forma de Pago">
                  <b-input :value="person_unit.payment!==undefined? person_unit.payment : ''" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Ítem Imputación Presupuestaria">
                  <b-input :value="person_unit.item!==undefined? person_unit.item : ''" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="CC Imputación Presupuestaria">
                  <b-input :value="person_unit.budget_allocation_cc!==undefined? person_unit.budget_allocation_cc : ''" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns is-gapless">
              <div class="column">
                <b-field label="Documento">
                  <b-input expanded readonly v-model="person_unit.document_name" type="text"></b-input>
                </b-field>
              </div>
              <div class="column is-narrow">
                <br>
                <b-field v-if="person_unit.can_view_pdf" label="  ">
                  <b-button @click="viewFile(person_unit.document_id)" icon-left="eye" type="is-success">ver</b-button>
                  <b-button @click="downloadFile(person_unit.document_id)" icon-left="download" type="is-info">Descargar</b-button>
                </b-field>
              </div>
            </div>
            <div class="columns" style="margin-bottom: 40px;" v-if="link">
              <div class="column has-text-left">
                <div class="row">
                  <b-field label="Alejamiento Vinculado">
                    <p class="control">
                      <b-button class="is-primary" icon-right="link" @click="openLinkedDistance(person_unit.linked_distance_id)">Ver {{person_unit.linked_distance_id.custom_name}}</b-button>
                    </p>
                  </b-field>
                </div>
              </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="has-text-right is-grouped">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <b-button class="is-primary"
                      v-if="person_unit.can_edit"
                      @click="isEditModalActive = true">
              Editar
            </b-button>
          </div>
        </footer>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="isDistanceModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <distance-form :person-id="person_unit.person_id"
                       :id="person_unit.id"
                       :person_unit_id="person_unit.id"
                       query-type="create"
                       :modal-header="'Nuevo Alejamiento - '+person_name"
                       @close="props.close">
        </distance-form>
      </template>
    </b-modal>

    <b-modal
        v-model="isWeeklyModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal-weekly-schedule
            name="planificacion_horario"
            :person-id="person_unit.person_id"
            :horarios="schedule"
            :intervalo="interval"
            :stringHorario="stringSchedule"
            :scheduleDays="scheduleDays"
            query-type="create"
            modal-header="Ver Horario Semanal"
            @close="props.close">
        </modal-weekly-schedule>
      </template>
    </b-modal>
    <b-modal
        v-model="isEditModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <appointment-form :person-id="person_unit.person_id"
                          :id="person_unit.id"
                          query-type="update"
                          :modal-header="'Editar Nombramiento - '+person_name"
                          @close="props.close">
        </appointment-form>
      </template>
    </b-modal>
    <b-modal
        v-model="isLinkedDistanceModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <component  :is="showName"
                    :id="selected"
                    :modal-header="title"
                    @close="props.close"/>
      </template>
    </b-modal>
    <b-modal
        v-model="showPdf"
        :can-cancel="true"
        :destroy-on-hide="true"
        aria-modal
        aria-role="dialog"
        has-modal-card
        trap-focus
        :width="2000"
    >
      <template #default="props">
        <pdf_view
            :src="src"
            @close="props.close"
        ></pdf_view>
      </template>

    </b-modal>

  </section>
</template>
<script>
import axios from "axios";
import DistanceForm from "../life_record/modal_distance_form"
import AppointmentForm from "../life_record/modal_feecontract_appointment_form"
import ModalWeeklySchedule from "../life_record/modal_weekly_schedule"
import Distance from "../life_record/distance"
import MoneyFormat from 'vue-money-format'
import {formatDate} from "../../packs/utilities";
import Pdf_view from "../performances/pdf_view.vue";

export default {
  name: "modal_feecontract_appointment",
  props:[
    'queryType', 'personId', 'id', 'modalHeader', 'person_name'
  ],
  components:{
    Pdf_view,
    DistanceForm,
    ModalWeeklySchedule,
    AppointmentForm,
    Distance,
    'money-format': MoneyFormat
  },
  data(){
    return {
      person_unit:{

      },
      years:[],
      appointment_types:[],
      unit_dependences: false,
      work_plants:[],
      functions: [],
      function_name: null,
      resolution_types:[],
      units: [],
      contract_units:[],
      grades: [],
      levels: [],
      working_days:[],
      errores_validacion:{},
      documentation_link:'',
      establishments:[],
      isLoading: false,
      isDistanceModalActive: false,
      isEditModalActive: false,
      isLinkedDistanceModalActive: false,
      link: false,
      distances: [],
      interval: 60,
      scheduleDays: [],
      stringSchedule: "",
      schedule: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: []
      },
      isWeeklyModalActive: false,
      showPdf: false,
      src:null,
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    changeCC(){
      console.log("cambio cc...")
      let url = "/life_record/rectify/"+this.id+"?event_type_id=12" //establishment académicos
      window.location.href = url;
    },
    addHours(){
      console.log("aumentar horas...")
      let url = "/life_record/rectify/"+this.id+"?event_type_id=11" //establishment académicos
      window.location.href = url;
    },
    reduceHours(){
      console.log("reducir horas...")
      let url = "/life_record/rectify/"+this.id+"?event_type_id=10" //establishment académicos
      window.location.href = url;
    },
    rectify(){
      console.log("rectificar...")
      let url = "/life_record/rectify/"+this.id+"?event_type_id=9" //establishment académicos
      window.location.href = url;
    },
    initialize(){
      this.isLoading=true;
      axios
          .get("/admin/person_units/"+this.id+".json")
          .then(res => {
            this.person_unit = res.data
            if(this.person_unit.linked_distance_id){
              this.link = true
            }
            this.schedule = JSON.parse(this.person_unit.text_schedule)
            if(this.person_unit.appointment_days.length!==0){
              this.interval = this.person_unit.appointment_days[0].interval
            }
            this.stringSchedule = res.data["schedule"]
            this.isLoading = false
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
      //this.isLoading=false;
    },
    showSchedule(){
      this.isWeeklyModalActive = true
    },
    functionLink(){
      if(this.link===true){
        this.link = false
        this.person_unit.linked_distance_id = null
      }
      else{
        this.link = true
      }
    },
    deleteData(person_unit){
      delete person_unit.units
      delete person_unit.work_plants
      delete person_unit.functions
      delete person_unit.appointment_types
      delete person_unit.levels
      delete person_unit.grades
      return person_unit
    },
    openLinkedDistance(linkedDistance){
      this.selected = linkedDistance.id;
      this.showName = "Distance"
      this.title = "Alejamiento"
      //console.log("APPOINTMENT "+ this.showName)
      this.isLinkedDistanceModalActive = true;
    },
    formatDate,
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            this.showPdf = true
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    downloadFile(fileId){
      this.isLoading = true;

      if (fileId == null){
        this.isLoading = false;
        return;
      }

      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(res => {
            let blob = new Blob([res.data], {
              type: "application/pdf",
            })
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = res.headers["content-disposition"]
                .split(";")[1]
                .split('"')[1];
            link.click();
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento fue descargado con exito',
              type: 'is-success',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
          .catch(e => {
            console.log(e)
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento no se pudo descargar',
              type: 'is-danger',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
    },
  },
  watch: {
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
    isDistanceModalActive: function () {
      if (!this.isDistanceModalActive) {
        this.initialize()
      }
    },
  }

}
</script>

<style scoped>

</style>