var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateContract)}}},[_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(_vm._s(_vm.modalHeader))]),_vm._v(" "),_c('button',{staticClass:"delete",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}})]),_vm._v(" "),_c('section',{ref:"modalPersonForm",staticClass:"modal-card-body"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[(Object.keys(_vm.errores_validacion).length !== 0)?_c('div',{staticClass:"mb-2"},[_c('b-message',{attrs:{"type":"is-danger","has-icon":""}},_vm._l((_vm.errores_validacion),function(item,index){return _c('div',[(item)?_c('div',[_vm._v(_vm._s(item[0]))]):_vm._e()])}),0)],1):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('b-field',{attrs:{"label":"Fecha de inicio*"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-datepicker',{attrs:{"icon":"calendar-alt","trap-focus":"","locale":"es-ES","disabled":_vm.isView,"editable":"","placeholder":"Formato de Fecha: dd/mm/aaaa"},model:{value:(_vm.contract.start_date),callback:function ($$v) {_vm.$set(_vm.contract, "start_date", $$v)},expression:"contract.start_date"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('b-field',{attrs:{"label":"Fecha de término"}},[_c('b-datepicker',{attrs:{"icon":"calendar-alt","trap-focus":"","locale":"es-ES","disabled":_vm.isView,"editable":"","placeholder":"Formato de Fecha: dd/mm/aaaa"},model:{value:(_vm.contract.end_date),callback:function ($$v) {_vm.$set(_vm.contract, "end_date", $$v)},expression:"contract.end_date"}})],1)],1)])]),_vm._v(" "),_c('div',{staticClass:"columns"},[(_vm.tipos_pago.length>0)?_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Forma de pago*"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.tipos_pago.map(function (type) { return type.id; }),"custom-label":function (opt) { return _vm.tipos_pago.find(function (x) { return x.id == opt; }).name; },"placeholder":"Seleccione forma de pago","selectLabel":"","selectedLabel":"Seleccionado","disabled":_vm.isView,"deselectLabel":"","allow-empty":false},scopedSlots:_vm._u([{key:"noOptions",fn:function(){return [_vm._v("\n                          No existen datos\n                        ")]},proxy:true}],null,true),model:{value:(_vm.contract.sdt_payment_type_id),callback:function ($$v) {_vm.$set(_vm.contract, "sdt_payment_type_id", $$v)},expression:"contract.sdt_payment_type_id"}},[_vm._v(" "),_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("\n                                  No se encontraron elementos.\n                              ")])]),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Monto*"}},[_c('ValidationProvider',{attrs:{"rules":"required|integer|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"disabled":_vm.isView,"type":"text"},model:{value:(_vm.contract.amount),callback:function ($$v) {_vm.$set(_vm.contract, "amount", $$v)},expression:"contract.amount"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_vm._v(" "),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Monto Total*"}},[_c('ValidationProvider',{attrs:{"rules":"required|integer|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"disabled":_vm.isView,"type":"text"},model:{value:(_vm.contract.total_amount),callback:function ($$v) {_vm.$set(_vm.contract, "total_amount", $$v)},expression:"contract.total_amount"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('b-field',{attrs:{"label":"Servicios Prestados"}},[_c('ValidationProvider',{attrs:{"rules":"max:4000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"textarea","minlength":"0","disabled":_vm.isView,"maxlength":"4000"},model:{value:(_vm.contract.service),callback:function ($$v) {_vm.$set(_vm.contract, "service", $$v)},expression:"contract.service"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)])]),_vm._v(" "),_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}})],1)]),_vm._v(" "),_c('footer',{staticClass:"modal-card-foot actions"},[_c('div',{staticClass:"actions has-text-right"},[_c('button',{staticClass:"button left-button",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Cerrar")]),_vm._v(" "),(_vm.queryType=='update')?_c('b-button',{staticClass:"button right-button is-primary",attrs:{"icon-left":"save","native-type":"submit"},on:{"click":function($event){return _vm.checkValidation()}}},[_vm._v("\n              Actualizar Contrato\n          ")]):_vm._e()],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }