<template>
<section>
  <b-button
      label="Rellenar datos con ISBN"
      type="is-primary"
      @click="showIsbnSearch = true" />

  <b-modal v-model="showIsbnSearch" :width="640" scroll="keep">
    <div class="card">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(fillData)">
          <div class="card-content">
            <div class="content">
              <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
                <b-message type="is-danger" has-icon>
                  <div v-for="(item,index) in errores_validacion">
                    {{ item[0] }}
                  </div>
                </b-message>
              </div>

              <div class="field">
                <b-field label="Ingrese ISBN">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input
                      maxlength="100"
                      placeholder="Ej: 978-846-8025-384"
                      v-model="isbn">
                    </b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  
                </b-field>
              </div>
              <b-message
                  type="is-warning"
                  has-icon
                  aria-close-label="Cerrar">
                ¡Precaución! Si esta operación tiene éxito, se sobreescribiran los datos llenados en el formulario.
              </b-message>
            </div>
          </div>
          <footer class="card-footer">
            <b-button @click="closeModal" class="card-footer-item">Cancelar</b-button>
            <b-button @click="checkValidation()" native-type="submit"  class="card-footer-item">  
              <i class="fab fa-google"></i>
              - Rellenar datos
            </b-button>
          </footer>
        </form>
      </ValidationObserver>
    </div>
  </b-modal>

  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
</section>
</template>

<script>
import axios from "axios";
import { bus } from "../../packs/app_vue";
export default {
  name: "IsbnSearch",
  data(){
    return {
      isbn:"",
      showIsbnSearch:false,
      isLoading:false,
      errores_validacion: {},
    }
  },
  methods:{
    closeModal(){
      this.showIsbnSearch=false;
    },
    async checkValidation(){
      const valid = await this.$refs.observer.validate()
      if(!valid){
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
    fillData(){
      let vm = this;
      var isbnL = vm.isbn.replace(/-/g,"")
      vm.isLoading = true;
      axios
          .get("/publications/parse/isbn.json", {
            params: {isbn:isbnL}
          })
          .then(response =>{
            bus.$emit('fill-publication', response.data.book)
            bus.$emit('fill-isbn', response.data.isbns)
            
            vm.$buefy.dialog.alert({
              title: 'Petición exitosa',
              message: 'Su petición fue exitosa. Recuerde rellenar los campos faltantes.',
              type: 'is-success',
              hasIcon: true,
              icon: 'check-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            vm.isLoading = false;
            vm.isbn = ""
            vm.closeModal();
          }).catch(error => {
            console.log(error.response.status);
            let error_message = "Hubo un error en su solicitud.";
            if(error.response.status==404){
              error_message = "El ISBN no fue encontrado en la API ISBN.";
            }
            if(error.response.status==504){
              error_message = "Hubo un error en su solicitud debido a que la API ISBN no se encuentra disponible en estos momentos."
            }
            vm.$buefy.dialog.alert({
              title: 'Error',
              message: error_message,
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            vm.isLoading=false;
          })

    }
  }
}
</script>

<style scoped>

</style>