<template>
<section>
  <h3 class="is-size-4 has-text-weight-bold has-text-centered">Tabla de Citas</h3>
  <div class="columns is-centered">
    <div class="column is-9">
      <div class="has-text-right">
        <b-button v-if="publication.can_edit" type="is-info"
                  icon-left="plus"
                  @click="addCite">
          Añadir conteo de citas
        </b-button>
      </div>
    </div>
  </div>
  <div class="columns is-centered">
    <div class="column is-centered is-9">
      <b-table
          :data="cites"
          :paginated=true
          per-page=10
          striped
          hoverable
          :current-page="currentPage"
          :card-layout="cardLayout"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="desc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="last_patent_state_date"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field= 'year'
            label= 'Año'
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{  props.row.year }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'number'
            label= 'Cantidad de Citas'
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{  props.row.number }}
          </template>
        </b-table-column>
        <b-table-column
            label="Acciones"
            v-slot="props">
          <b-button type="is-info"
                    @click.prevent="editCite(props.row)"
                    icon-right="edit" />
          <b-button type="is-danger"
                    @click.prevent="confirmDeleteCite(props.row)"
                    icon-right="trash" />
        </b-table-column>
        <template slot="empty" slot-scope="props">
          <div class="columns is-centered">
            <div class="column is-6 is-centered has-text-centered">
              <b-message type="is-danger">
                No se encontraron datos.
              </b-message>
            </div>
          </div>
        </template>
      </b-table>
    </div>

  </div>

  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  <publication-cites-form :active-modal="activeCiteModal"
                          :modal-header="CiteModalHeader"
                          :publication="publication"
                          :cite="currentCite"
                          @citeAdded="citeAdded"
                          @close="closeCiteModal"></publication-cites-form>
</section>
</template>

<script>
import axios from "axios";
import PublicationCitesForm from '../cites/PublicationCitesForm';
export default {
  name: "PublicationCitesTab",
  components: {PublicationCitesForm},
  data(){
    return{
      isLoading:false,
      cites: [],
      currentPage:1,
      cardLayout:false,
      currentCite:{},
      activeCiteModal:false,
      CiteModalHeader:""
    }
  },
  props:['publication'],
  created() {
    this.getCites();
  },
  methods:{
    addCite(){
      this.activeCiteModal = true;
      this.currentCite = {};
      this.CiteModalHeader = "Añadir conteo de citas a la publicación"
    },
    editCite(row){
      this.activeCiteModal = true;
      this.currentCite = row;
      this.CiteModalHeader = "Editar conteo de citas a la publicación"
    },
    getCites(){
      let vm = this;
      vm.isLoading = true;
      axios.get("/publications/"+vm.publication.id+"/cites.json")
        .then(res => {
          vm.cites = res.data;
          vm.isLoading = false
        })
        .catch(e => {
          //console.log(e);
          vm.isLoading=false;
        });
    },
    closeCiteModal(){
      this.activeCiteModal = false;
      this.CiteModalHeader = '';
      this.currentCite = {};
    },
    async citeAdded(){
      await this.getCites();
      this.closeCiteModal();
    },
    confirmDeleteCite(row){
      this.$buefy.dialog.confirm({
        title: 'Eliminar Cita',
        message: 'Precaución, realizar esta acción podría afectar el cálculo de indicadores de cada participante de la publicación ¿Desea continuar?',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteCite(row)
      })
    },
    deleteCite(row){
      let vm = this;
      vm.isLoading = true;
      axios.delete("/cites/"+row.id+".json")
        .then(res => {
          vm.$buefy.dialog.alert({
            message: 'La cita fue eliminada satisfactoriamente.',
            type: 'is-success',
            hasIcon: true,
            icon: 'check-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          vm.isLoading = false
          vm.getCites();
        })
        .catch(e => {
          //console.log(e);
          vm.$buefy.dialog.alert({
            message: 'Hubo un error en su petición.',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          vm.isLoading=false;
        });

    }
  }

}
</script>

<style scoped>

</style>