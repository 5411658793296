<template>
  <section>
    <div class="container">
      <h1 class="is-size-2">Convenios</h1>
      <div v-if="can_create" class="has-text-right">
        <button class="button is-primary" @click="newAgreement()">
          <b-icon icon="plus"></b-icon>
          <span>Añadir Convenio</span>
        </button>
      </div>
      <br>
      <div class="columns">
        <div class="column is-narrow mt-2">
          <span>Filtrar por departamento: </span>
        </div>
        <div class="column is-4">
          <b-field >
            <multiselect v-model="unit"
                         :options="units.map(x => x.name)"
                         @input="updateAgreements"
                         placeholder="Seleccione departamento"
                         selectLabel="Presione para seleccionar"
                         selectedLabel="Seleccionado"
                         deselectLabel="Presione para deseleccionar"
                         :allow-empty="true">
              <template v-slot:noOptions>
                No existen datos
              </template>
              <span slot="noResult">
                No se encontraron elementos.
            </span>
            </multiselect>
          </b-field>
        </div>
        <div class="column has-text-right">
          <b-field>
            <b-switch v-model="cardLayout">
          <span>
            Formato tarjeta (sin filtros ni búsqueda)
             <b-tooltip
                 label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                 multilined
                 position="is-left">
                  <b-icon
                      pack="fas"
                      icon="info-circle"
                      type="is-info">
                  </b-icon>
              </b-tooltip>
          </span>
            </b-switch>
          </b-field>

        </div>
      </div>
      <b-table
          :data="filtered_convenios"
          :paginated=true
          per-page=10
          striped
          hoverable
          :current-page="currentPage"
          :card-layout="cardLayout"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="publication.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field= 'name'
            label= 'Nombre'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
          </template>
          <template v-slot="props">
              <a @click="goToAgreement(props.row.id)"><i class="fas fa-user-circle"></i> {{ props.row.name }}</a>
          </template>
        </b-table-column>
        <b-table-column
            field= 'agreement_type.agreement_general_type'
            label= 'Categoría general'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
          </template>
          <template v-slot="props">
              {{ props.row.agreement_type.agreement_general_type }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'agreement_type.agreement_specifict_type'
            label= 'Categoría específica'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
          </template>
          <template v-slot="props">
              {{ props.row.agreement_type.agreement_specifict_type }}
          </template>
        </b-table-column>

        <b-table-column
            field= 'agreement_stage'
            label= 'Etapa del convenio'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
          </template>
          <template v-slot="props">
              {{ props.row.agreement_stage }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'agreement_state'
            label= 'Estado del convenio'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
          </template>
          <template v-slot="props">
              {{ props.row.agreement_state }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'agreement_unit_name'
            label= 'Usuario del convenio'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.agreement_unit_name }}
          </template>
        </b-table-column>
      </b-table>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
  </section>
</template>
<script>
import axios from "axios"
export default {
    data(){
        return {
            //Variables para el control
            isLoading:false,
            currentPage:1,
            applyFilter: 0,
            cardLayout: false,
            // para almacenamiento temporal
            convenios: [],
          filtered_convenios:[],
            partner_entities: [],
            can_create: false,
          units:[],
          unit:null
        }
    },
    created(){
    this.isLoading=true;
    return axios.get("/agreements.json")
        .then(res => {
          this.convenios = res.data['convenios'];
          this.filtered_convenios = res.data['convenios'];
          this.can_create = res.data['can_create']
          this.units = res.data['units']
          this.isLoading=false;
        })
        .catch(e => {
          this.isLoading=false;
          console.log(e);
        });
    },
    methods:{
        newAgreement(){
            this.isLoading=true;
            window.location.href="/agreements/new"
        },
        goToAgreement(agreement_id){
            this.isLoading=true;
            window.location.href="/agreements/"+agreement_id
        },
      updateAgreements(){

          this.filtered_convenios =  this.convenios.filter(
              element => element.agreement_unit_name === this.unit
          )
        if(this.unit ==  null || this.unit == ''){
          this.filtered_convenios = this.convenios
        }

      }
    }
}
</script>