<template>
<section>
  <div class="box">
    <div class="columns">
      <div class="column">
        <b-field label="Nombre del Proyecto">
            <b-input v-model="project.name" type="text"></b-input>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field label="Tipo de Proyecto">
          <b-input readonly v-model="project.project_type ? project.project_type.name : project.project_type"></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Código">
          <b-input readonly type="text" v-model="project.code"></b-input>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field label="Fecha de Postulacion">
          <b-input readonly :value="project.postulation_date ? this.getFormattedDate() : ''"></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Rol de la Universidad">
          <b-input readonly v-model="project.university_project_role ? project.university_project_role.name : project.university_project_role"></b-input>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field label="Link carta firmada por la/el Decana/o">
          <b-input expanded readonly v-model="project.postulation_letter_link" type="text"></b-input>
          <p class="control">
            <b-button tag="a" :href="project.postulation_letter_link" target="_blank" icon-left="external-link-alt" type="is-link">Link</b-button>
          </p>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field label="Link Documentación">
          <b-input expanded readonly v-model="project.anid_letter_link" type="text"></b-input>
          <p class="control">
            <b-button tag="a" :href="project.anid_letter_link" target="_blank" icon-left="external-link-alt" type="is-link">Link</b-button>
          </p>
        </b-field>
      </div>
    </div>
  </div>

</section>
</template>

<script>
import moment from "moment";
export default {
  name: "PostulatedProjectGeneralTab",
  props:[ 'project' ],
  methods:{
    getFormattedDate(){
      return moment(this.project.postulation_date).format('DD/MM/YYYY')
    }
  }

}
</script>

<style scoped>

</style>