<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveRole)">
        <!-- Box de Formulario Appointment -->
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body">
            <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
              <b-message type="is-danger" has-icon>
                <div v-for="(item,index) in errores_validacion" :key="index">
                  {{ item[0] }}
                </div>
              </b-message>
            </div>

            <div v-if="create">
            <div class="columns">
              <div class="column"></div>
              <div class="column">
                <div class="has-text-centered">
                  <div class="field has-addons">
                    <b-field label="Buscar personas en el sistema">

                    <p class="control has-icons-left">
                      <input v-model='busqueda' class="input" type="text" id="input-busqueda" placeholder="Búsqueda...">
                      <span class="icon is-left"><i class="fas fa-search" aria-hidden="true"></i></span>
                    </p>

                    <div class="control">
                      <b-button @click.prevent="search(busqueda)" class="is-primary" id="boton-buscar">Buscar</b-button>

                    </div>
                      <b-tooltip
                          label="Para seleccionar una persona existente, debe realizar una búsqueda y luego clickear en la tabla a la persona escogida"
                          multilined
                          position="is-right">
                        <b-icon
                            pack="fas"
                            icon="info-circle"
                            type="is-info">
                        </b-icon>
                      </b-tooltip>
                    </b-field>
                  </div>
                </div>
              </div>
              <div class="column"></div>
            </div>
            <div class="columns">
              <div class="column">
                <section>
                  <b-field>
                    <b-button
                        label="Borrar Selección"
                        type="is-danger"
                        icon-left="close"
                        :disabled="!persona_seleccionada"
                        @click="deletePerson" />
                  </b-field>

                  <b-table
                      id="tabla-participantes"
                      :data="people"
                      :paginated=true
                      :selected.sync="persona_seleccionada"
                      per-page=5
                      striped
                      hoverable
                      :current-page="currentPage"
                      :card-layout="cardLayout"
                      :pagination-simple=false
                      pagination-position="bottom"
                      default-sort-direction="desc"
                      sort-icon="arrow-up"
                      sort-icon-size="is-small"
                      aria-next-label="Siguiente"
                      aria-previous-label="Anterior"
                      aria-page-label="Página"
                      aria-current-label="Página actual"
                  >
                    <b-table-column
                        field= 'name'
                        label= 'Nombre'
                        width="20em"
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{  props.row.name }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field= 'last_name'
                        label= 'Apellido'
                        width="20em"
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{  props.row.last_name }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field= 'second_surname'
                        label= 'Segundo apellido'
                        width="20em"
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{  props.row.second_surname }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field= 'corporate_mail'
                        label= 'Correo Corporativo'
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{  props.row.corporate_mail }}
                      </template>
                    </b-table-column>
                  </b-table>
                </section>
              </div>
            </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Nombres*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input v-model="person.name" type="text"  :disabled="!not_selected" minlength="5" maxlength="200"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Apellido*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input v-model="person.last_name" type="text"  :disabled="!not_selected" minlength="5" maxlength="200"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Segundo apellido">
                  <ValidationProvider  v-slot="{ errors }">
                    <b-input v-model="person.second_surname" type="text"  :disabled="!not_selected" minlength="5" maxlength="200"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Rut*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input v-model="person.run_attributes.rut" type="number"  :disabled="!not_selected"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Dv*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input v-model="person.run_attributes.dv" type="text"  :disabled="!not_selected"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Correo corporativo*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input v-model="person.corporate_mail" type="text"  :disabled="!not_selected" minlength="5" maxlength="200"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input v-model="person_unit.description" type="text" minlength="5" maxlength="200"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Rol*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.internal_position"
                                 :options="internal_positions.map(type => type.id)"
                                 :custom-label="opt => internal_positions.find(x => x.id === opt).name"
                                 placeholder="Seleccione un rol"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 @input="updateStatus"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Unidad*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.unit_id"
                                 :options="units.map(type => type.id)"
                                 :custom-label="opt => units.find(x => x.id === opt).name"
                                 placeholder="Seleccione una unidad"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Fecha Desde*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-datepicker
                        v-model="person_unit.start_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                    >
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Hasta">
                  <b-datepicker
                      v-model="person_unit.end_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit">Guardar </button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "modal_add_annex_roles",
  props:[
    "id", "modalHeader", "complete_name", "queryType", "personId", "complete_run"
  ],
  data(){
    return {
      isLoading: false,
      isEditModalActive:false,
      person_unit: {},
      person: {id: null, run_attributes: {rut: null, dv: null}, name: null, last_name: null, second_surname: null, corporate_mail: null},
      people: [],
      units: [],
      currentPage: 1,
      filtered_people: [],
      internal_positions: [],
      errores_validacion:{},
      persona_seleccionada: null,
      complete_rut: null,
      corporate_mail: null,
      is_real: false,
      edit: false,
      not_selected: true,
      busqueda: "",
      create: false,
      cardLayout:false,

    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      const loadingComponent = this.$buefy.loading.open();
      if(this.queryType==="create"){
        this.create=true
        return axios
            .get("/admin/person_units/create_roles.json")
            .then(res => {
              this.internal_positions = res.data.internal_positions_filtered
              this.units = res.data.units_all
              loadingComponent.close();
            }).catch(error => {
              console.log(error)
              loadingComponent.close();
            })
      }
      else if(this.queryType==="update"){
        return axios
            .get("/admin/person_units/" + this.id + "/edit_roles.json")
            .then(res => {
              var person_unit = {id: res.data.id, internal_position: res.data.internal_position.id, unit_id: res.data.unit.id, description: res.data.description, start_date: res.data.start_date, end_date: res.data.end_date}
              this.person_unit = person_unit
              if(person_unit.start_date!==null){
                this.person_unit.start_date = new Date(this.formatDate(person_unit.start_date))
              }
              if(person_unit.end_date!==null){
                this.person_unit.end_date = new Date(this.formatDate(person_unit.end_date))
              }

              this.person = {id: res.data.person.id, run_attributes: {rut: res.data.run_attributes.rut, dv: res.data.run_attributes.dv}, name: res.data.person.name,
                last_name: res.data.person.last_name, second_surname: res.data.person.second_surname, corporate_mail: res.data.person.corporate_mail}
              this.not_selected = false
              this.corporate_mail = res.data.person.corporate_mail
              this.complete_rut = res.data.run_attributes.rut.toString()+"-"+res.data.run_attributes.dv
              this.units =res.data.units_all
              this.internal_positions = res.data.internal_positions_filtered
              this.edit = true
              loadingComponent.close();
            }).catch(error => {
              console.log(error)
              loadingComponent.close();
            })
      }

    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
    saveRole(){
      const loadingComponent = this.$buefy.loading.open();

      if (this.queryType==="create"){
        axios
            .post("/admin/person_units/new_role.json", {
              person_unit: this.person_unit,
              pu_relation_type_id: 2,
              person: this.person
            })
            .then(response =>{
              this.errores_validacion = {};
              this.$buefy.dialog.alert({
                message: 'El rol fue creado con éxito ',
                confirmText: 'Ok'
              })
              this.person_unit = {}
              loadingComponent.close();
              this.errors = [];
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al agregar el rol, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          loadingComponent.close();
        })
      }
      else if (this.queryType==="update"){
        let clean_person_unit = this.person_unit
        axios
            .put("/admin/person_units/" + this.id+"/update_role.json", {
              person_unit:  this.person_unit, pu_relation_type_id: 2,
              person: this.person
            })
            .then(response => {
              this.$buefy.dialog.alert({
                message: 'El rol fue editado con éxito ',
                confirmText: 'Ok'
              })
              this.errores_validacion = {};
              this.isLoading = false;
              this.person_unit = {}
              loadingComponent.close();
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          let elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al editar el rol, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          loadingComponent.close();
        })
      }

    },
    getFilteredPeople(text){
      this.filtered_people = this.people.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    updateStatus(){
      var internal = this.internal_positions.find(x => x.id === this.person_unit.internal_position)
      this.is_real = internal.is_real
    },
    deletePerson(){
      this.persona_seleccionada = null
      this.not_selected = true
    },
    search(busqueda){
      const loadingComponent = this.$buefy.loading.open();
      return axios
          .get("/people/"+busqueda+"/search_all_people.json")
          .then(res => {
            this.people = res.data
            loadingComponent.close();
          }).catch(error => {
            console.log(error)
            loadingComponent.close();
          })
    },
  },
  watch: {
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
    persona_seleccionada: function (){
      if(this.persona_seleccionada){
        this.not_selected = false
        this.person.id = this.persona_seleccionada.id
        this.person.run_attributes.rut = this.persona_seleccionada.run_attributes.rut
        this.person.run_attributes.dv = this.persona_seleccionada.run_attributes.dv
        this.person.corporate_mail = this.persona_seleccionada.corporate_mail
        this.person.name = this.persona_seleccionada.name
        this.person.last_name = this.persona_seleccionada.last_name
        this.person.second_surname = this.persona_seleccionada.second_surname
      }
      else{
        this.person = {id: null, run_attributes: {rut: null, dv: null}, name: null, last_name: null, second_surname: null, corporate_mail: null}
      }
    },

  }
}
</script>
