<template>
    <div class="vimeactivityassistances">
        <div>
            <div class="container">
                <div v-if="can_create_participant" class="columns">
                    <div class="column">
                        <span class="is-pulled-right">
                            <button class="button is-primary" @click="newAssistant()">
                                <b-icon icon="plus"></b-icon>
                                <span>Añadir un nuevo participante</span>
                            </button>
                        </span>
                    </div>
                </div>
                <div class="columns">
                <div class="column has-text-right">
                  <b-field>
                    <b-switch v-model="cardLayout">
                      <span>
                        Formato tarjeta (sin filtros ni búsqueda)
                         <b-tooltip
                             label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                             multilined
                             position="is-left">
                              <b-icon
                                  pack="fas"
                                  icon="info-circle"
                                  type="is-info">
                              </b-icon>
                          </b-tooltip>
                      </span>
                    </b-switch>
                  </b-field>
                </div>
              </div>
            </div>
            <br>
            <b-table
                :data="activity_assistance_data"
                :paginated=true
                per-page=10
                striped
                hoverable
                :current-page="currentPage"
                :card-layout="cardLayout"
                :pagination-simple=false
                pagination-position="bottom"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="publication.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
                <b-table-column
                    field="person_name"
                    label= 'Nombre'
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    <a @click="goToPerson(props.row.id_person)">{{ props.row.person_name }}</a>
                  </template>
                </b-table-column>
                <b-table-column
                    field="assistance_type_name"
                    label= 'Participa como'
                    searchable
                    sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{props.row.assistance_type_name}}
                    </template>
                </b-table-column>
                <b-table-column
                    field="corporate_mail"
                    label= 'Correo corporativo'
                    searchable
                    sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      <div v-if='props.row.corporate_mail == null'> No se ha encontrado registrado</div>
                      <div v-else>{{props.row.corporate_mail}}</div>
                    </template>
                </b-table-column>
                <b-table-column
                    field="phone"
                    label= 'Teléfono'
                    searchable
                    sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      <div v-if='props.row.phone == null'> No se ha encontrado registro</div>
                      <div v-else>{{props.row.phone}}</div>
                    </template>
                </b-table-column>
              <b-table-column
                  field="is_registered"
                  label= '¿Es externo?'
                  sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  <div v-if='props.row.is_registered == true'>No</div>
                  <div v-else>Si</div>
                </template>
              </b-table-column>
                <b-table-column
                    v-if="can_destroy_participant"
                    label='Acción'
                    v-slot="props">
                    <button class="button is-danger" @click="confirmActivityAssistantDelete(props.row)">
                        <b-icon icon="times"></b-icon>
                        <span>Eliminar</span>
                    </button>
                </b-table-column>
              <template slot="empty" slot-scope="props">
                <div class="columns is-centered">
                  <div class="column is-6 is-centered has-text-centered">
                    <b-message has-icon type="is-warning">
                      <h1 v-if="can_create_participant"> No se encontraron resultados, haz click <a @click="newAssistant()">aquí</a> para añadir un nuevo participantes.</h1>
                      <h1 v-else> No se encontraron resultados </h1>
                    </b-message>
                  </div>
                </div>
              </template>
            </b-table>
        </div>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
</template>
<script>
import VimeActivityAssistanceNewModal from '../../vime_activity_assistance/new/vime_activity_assistance_new_modal.vue'
import axios from 'axios'
export default {
    name: 'VimeActivityAssistances',
    props:{
        activity_assistances_found:{
            type: Boolean,
            default: false
        },
        activity_assistances:{
            type: Array,
            default: () => []
        },
        vime_activity_id:{
            type: Number
        },
        modalAssistance:{
            type: Array,
            default: () => []
        },
        modalAssistanceTypes:{
            type: Array,
            default: () => []
        },
        can_create_participant:{
          type: Boolean
        },
        can_destroy_participant:{
          type: Boolean
        }
    },
    data(){
        return {
            currentPage:1,
            activity_assistance_data: [],
            isLoading: false,
            cardLayout: false
        }
    },
    computed:{
        isActivityAssistanceVoid(){
            return Object.entries(this.activity_assistance_data).length == 0
        },
        activityAsistance:{
            get(){
                return this.activity_assistance_data
            },
            set(newAssistant){
                this.activity_assistance_data = newAssistant
            }
        }
    },
    beforeMount(){
      this.getVimeActivityAssistance()
    },
    methods:{
        newAssistant(){
            this.$buefy.modal.open({
                parent: this,
                component: VimeActivityAssistanceNewModal,
                props:{
                    'vime_activity_assistances_info': this.modalAssistance,
                    'vime_assistant_types': this.modalAssistanceTypes
                },
                hasModalCard: true,
                events:{
                    'add-assistant': this.addNewAssistance
                },
                customClass: 'is-primary',
                trapFocus: true
            })
        },
        addNewAssistance(newAssistance){
            var addPersonLikeNewAssistant = true
            this.activityAsistance.forEach(function(element){
                if(element.id_person === newAssistance.id_person)
                    addPersonLikeNewAssistant = false
            })
            if(addPersonLikeNewAssistant){
                this.isLoading = true
                axios.post('/vime_activity_assistances.json', {
                    'description': ' ',
                    'person_id': newAssistance.person_id, 
                    'vime_assistant_type_id': newAssistance.assistance_type_id,
                    'vime_activity_id': this.vime_activity_id
                })
                .then( response =>{
                    this.getVimeActivityAssistance()
                    this.$emit("change-asistance", this.activityAsistance)
                })
                .catch(e => {
                    console.log(e)
                    this.isLoading = false
                })
            }
            else{
                this.$buefy.dialog.alert({
                    title: 'Advertencia: Invitado ya agregado.',
                    message: 'El invitado que tratas de añadir ya fue ingresado.',
                    confirmText: 'Aceptar'
                })
            }
        },
        confirmActivityAssistantDelete(deleteActivityAssistantType){
            this.$buefy.dialog.confirm({
                message: '¿Quieres eliminar al asistente: <b>'+deleteActivityAssistantType.person_name+'</b>? este cambio no puede deshacerse.',
                confirmText: 'Eliminar',
                cancelText: 'Cancelar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.deleteAssistance(deleteActivityAssistantType.id)
            })
        },
        deleteAssistance(id){
            this.isLoading = true
            axios.delete('/vime_activity_assistances/'+id+'.json')
            .then( response =>{
                this.activityAsistance = this.activityAsistance.filter(
                    element => element.id !== id
                )
                this.$emit("change-asistance", this.activityAsistance)
                this.$buefy.toast.open({
                    message: 'Asistente a actividad eliminado correctamente.',
                    type: 'is-primary'
                })
              this.isLoading = false
            })
            .catch(e => {
                console.log(e)
              this.isLoading = false
            })
        },
        goToNewAssistance(){
            window.location.href="/vime_activity_assistances/new/"+this.vime_activity_id
        },
      goToPerson(id){
        window.location.href='/people/'+id
      },
      getVimeActivityAssistance(){
          this.isLoading = true
        axios.get('/vime_activity_assistances/getAssistanceByActivityId/'+this.vime_activity_id)
            .then(response =>{
              this.activity_assistance_data  = response.data.assistance
              this.activity_assistance_data.forEach(element =>{
                if(element.phone != null){
                  element.phone =  element.phone.toString(10)
                }
              })
              this.isLoading = false
            })
            .catch(e => {
              this.isLoading = false
              console.log(e)
            })
      },
    }
}
</script>