<template>
  <section>

    <!-- FORMULARIO AGREGAR PROYECTO -->
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
        <b-message type="is-danger" has-icon>
          <div v-for="(item,index) in errores_validacion">
            {{ item[0] }}
          </div>
        </b-message>
      </div>
      <form @submit.prevent="handleSubmit(saveProject)">
        <!-- Box de Formulario Project -->
        <div class="box">
          <div class="columns">
            <div class="column is-4 is-offset-8">
              <b-switch v-model="requiredFieldOnly">Mostrar solo campos obligatorios</b-switch>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Nombre*">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-input v-model="project.name" type="text"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>
          <!-- TIPO PROYECTO -->
          <div class="columns">
            <div class="column" v-if="project_categories.length>0">
              <b-field label="Categoría de Proyecto*">
                <ValidationProvider rules="required" v-slot="{errors}">
                  <multiselect v-model="major_project_category"
                               :options="project_categories.map(type => type.id)"
                               :custom-label="opt => project_categories.find(x => x.id == opt).name"
                               placeholder="Seleccione categoría de proyecto"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false"
                                @input="getMinorCategories">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column" v-if="tipos_proyecto.length>0">
              <b-field label="Tipo de Proyecto*">
                <ValidationProvider rules="required" v-slot="{errors}">
                  <multiselect v-model="project.project_type_id"
                               :options="tipos_proyecto.map(type => type.id)"
                               :custom-label="opt => tipos_proyecto.find(x => x.id == opt).name"
                               placeholder="Seleccione tipo de proyecto"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column" v-if="!requiredFieldOnly">
              <div class="field">
                <b-field label="Resumen">
                  <b-input
                      type="textarea"
                      minlength="10"
                      maxlength="500"
                      v-model="project.description">
                  </b-input>
                </b-field>
              </div>
            </div>

          </div>
          <div class="columns">
            <div class="column" v-if="!requiredFieldOnly">
              <div class="field">
                <b-field label="Año de adjudicación">
                  <ValidationProvider rules="integer|positive" v-slot="{errors}">
                    <b-input type="number" v-model="project.award_year"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="column" v-if="!requiredFieldOnly">
              <div class="field">
                <b-field label="Fecha de inicio">
                  <b-datepicker
                      v-model="project.start_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa">
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="column" v-if="!requiredFieldOnly">
              <div class="field">
                <b-field label="Fecha de termino">
                  <b-datepicker
                      v-model="project.end_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa">
                  </b-datepicker>
                </b-field>
              </div>
            </div>
          </div>
          
          <div class="columns">
            <div class="column" v-if="!requiredFieldOnly">
              <b-field label="Folio">
                <b-input type="text" v-model="project.folio"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Código*">
                <ValidationProvider rules="required" v-slot="{errors}">
                  <b-input type="text" v-model="project.code"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column" v-if="!requiredFieldOnly">
              <b-field label="Potencial de mercado">
                <b-input type="text" v-model="project.market_potential"></b-input>
              </b-field>
            </div>
          </div>
          
          <div class="columns">
            <div class="column" v-if="!requiredFieldOnly">
              <b-checkbox v-model="project.public_contract">
                ¿Es Contrato Público?
              </b-checkbox>
            </div>
            <div class="column" v-if="!requiredFieldOnly">
              <b-checkbox v-model="project.multidisciplinary">
                ¿Es Multidisciplinario?
              </b-checkbox>
            </div>
            <div class="column" v-if="!requiredFieldOnly">
              <b-checkbox v-model="project.international_agreement">
                ¿Tiene Acuerdo Internacional?
              </b-checkbox>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Origen del dato*">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-input v-model="project.validation_attributes.data_source"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>

          <span>
            <h3 class="is-size-3 has-text-weight-bold">
              Participantes*
              <b-tooltip label="En esta sección debe ingresar las y los participantes del proyecto que
               se encuentren registrados en el sistema, de esta manera, los datos ingresados de este proyecto quedarán asociados a esta persona."
                         position="is-right"
                         size="is-medium"
                         multilined>
              <b-icon
                  pack="fas"
                  icon="info-circle"
                  size="is-small"
                  type="is-info">
              </b-icon>
            </b-tooltip>
              <!--Añadir Participate -->
            <div class="is-pulled-right">
              <b-button  class="mt-2" @click.prevent="execAddModal(projectId)"
                  type="is-primary">Administrar Participantes</b-button>
            </div>
            </h3>

          </span>
          <h6 class="is-size-6 subtitle">(Se requiere al menos un/a participante. Recuerde añadirse a usted si es que participa en esta actividad académica)</h6>
          <!-- FORMULARIO PARTICIPANTES -->
          <h6 class="is-size-6 subtitle">(Se requiere al menos un/a propietario/a intelectual. Recuerde añadirse a usted si es que es propietario/a en esta actividad académica)</h6>
          <!-- FORMULARIO PERSON PATENT -->
          <div v-if="participantes.length!=0" class="column">
            <h3 class="is-size-4 has-text-weight-bold">Tabla Resumen Participantes</h3>
            <b-table
                :data="participantes"
                :paginated=true
                per-page=5
                striped
                hoverable
                :current-page="currentPage"
                :card-layout="cardLayout"
                :pagination-simple=false
                pagination-position="bottom"
                default-sort-direction="desc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="last_patent_state_date"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
              <b-table-column
                  field= 'name'
                  label= 'Nombres'
                  width="20em"
                  searchable
                  sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{  props.row.name }}
                </template>
              </b-table-column>
              <b-table-column
                  field= 'corporate_mail'
                  label= 'Correo Corporativo'
                  searchable
                  sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{  props.row.corporate_mail }}
                </template>
              </b-table-column>
              <b-table-column
                  field= 'unit'
                  label= 'Unidad'
                  searchable
                  sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{  props.row.unit }}
                </template>
              </b-table-column>
              <b-table-column
                label="Tipo de Participación*"
                v-slot="props">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="props.row.participant_type_id"
                                 :options="tipos_participacion.map(x => x.id)"
                                 :custom-label="opt => tipos_participacion.find(x => x.id == opt).name"
                                 placeholder="Seleccione tipo de participación"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
              </b-table-column>
              <b-table-column
                  label="Estado de Participación*"
                  v-slot="props">
                <ValidationProvider rules="required" v-slot="{errors}">
                  <multiselect v-model="props.row.participant_state_id"
                               :options="estados_participacion.map(x => x.id)"
                               :custom-label="opt => estados_participacion.find(x => x.id == opt).name"
                               placeholder="Seleccione estado de participación"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                  </multiselect>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-table-column>
              <b-table-column
                  label="Acción"
                  width="10"
                  v-slot="props">
                <b-icon
                    pack="fas"
                    style="color: #e50000;"
                    icon="trash"
                    size="is-medium"
                    @click.native="deleteParticipant(props.row)"/>
              </b-table-column>
            </b-table>
          </div>
          <!--  -->
          <span>
            <h3 class="is-size-3 has-text-weight-bold">
              Referencias de participantes
              <b-tooltip label="En esta sección debe ingresar en un campo de texto las y los participantes del proyecto en el orden deseado, solo para su visualización.
                                Este campo de texto es solo referencial."
                         position="is-right"
                         size="is-medium"
                         multilined>
              <b-icon
                  pack="fas"
                  icon="info-circle"
                  size="is-small"
                  type="is-info">
              </b-icon>
            </b-tooltip>
            </h3>

          </span>

          <b-field label="Participantes(registrados y no registrados)">
            <b-input placeholder="Nombre participante 1, nombre participante 2,..." v-model="project.participants_references" type="text"></b-input>
          </b-field>
          <div v-if="cargoPrincipal.name!=='ACADEMICO'">
            <h3 class="is-size-3 has-text-weight-bold">Financiamientos
              <div class="is-pulled-right">
                <b-button class="mt-2" @click.prevent="addFinancing" type="is-primary">Añadir Financiamiento</b-button>
              </div>
            </h3>

            <!-- FORMULARIO FINANCIAMIENTOS -->

            <div v-if="project.financings_attributes.length!=0" class="column">
              <b-table
                  :data="project.financings_attributes"
                  :paginated=true
                  per-page=5
                  striped
                  hoverable
                  :current-page="currentPage"
                  :pagination-simple=false
                  pagination-position="bottom"
                  default-sort-direction="asc"
                  sort-icon="arrow-up"
                  sort-icon-size="is-small"
                  default-sort="publication.title"
                  aria-next-label="Siguiente"
                  aria-previous-label="Anterior"
                  aria-page-label="Página"
                  aria-current-label="Página actual">
                <b-table-column
                    field="partner_entity"
                    label="Institución de financiamiento"
                    sortable
                    searchable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{entidades_externas.find(x => x.id == props.row.partner_entity_id).name}}
                  </template>
                </b-table-column>

                <b-table-column
                    field="financing_type"
                    label="Tipo Financiamiento"
                    sortable
                    searchable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{tipos_financiamiento.find(x => x.id == props.row.financing_type_id).name}}
                  </template>
                </b-table-column>

                <b-table-column
                    field="year"
                    label="Año"
                    sortable
                    searchable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.year}}
                  </template>
                </b-table-column>

                <b-table-column
                    field="amount"
                    label="Monto"
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.amount}}
                  </template>
                </b-table-column>
                <b-table-column
                    label="Acciones"
                    width="10"
                    v-slot="props">
                  <b-icon
                      pack="fas"
                      icon="edit"
                      style="color: #000000;"
                      size="is-medium"
                      @click.native="editFinancing(props.index)"/>
                  <b-icon
                      pack="fas"
                      icon="trash"
                      style="color: #e50000;"
                      size="is-medium"
                      @click.native="deleteFinancing(props.index)"/>
                </b-table-column>
              </b-table>
            </div>
          </div>
          <h3 class="is-size-3 has-text-weight-bold">Palabras clave
            <b-tooltip
                label="Presione Enter o Coma luego de escribir la palabra clave para que sea agregada"
                position="is-right"
                size="is-medium"
                multilined>

              <b-icon
                  pack="fas"
                  icon="info-circle"
                  size="is-small"
                  type="is-info">
              </b-icon>
            </b-tooltip>
          </h3>
          <b-field label="Ingrese o seleccione palabras clave">
            <b-taginput
                v-model="project.key_words_attributes"
                :data="palabras_claves_filtradas"
                autocomplete
                :allow-new="true"
                field="name"
                icon="tag"
                placeholder="Agrega una palabra clave"
                @typing="getFilteredTags">
            </b-taginput>
          </b-field>

          <h3 class="is-size-3 has-text-weight-bold">Líneas de Investigación
              <b-tooltip
                  label="No se agregarán líneas de investigación nuevas, solo se buscarán líneas de investigación ya existentes"
                  position="is-right"
                  size="is-medium"
                  multilined>
   
                <b-icon
                  pack="fas"
                  icon="info-circle"
                  size="is-small"
                  type="is-info">
                </b-icon>
              </b-tooltip>

          </h3>
         
          <!-- FORMULARIO LINEAS DE INVESTIGACION -->
          <div class="columns">
            <div class="column">
              <b-field label="Ingrese líneas de investigación">
                <b-taginput
                    v-model="project.research_lines_attributes"
                    :data="lineas_investigacion_filtradas"
                    type="is-primary is-light"
                    autocomplete
                    :allow-new="false"
                    field="name"
                    icon="tag"
                    placeholder="Agrega una línea de investigación"
                    @typing="getFilteredResearchLines">
                </b-taginput>
              </b-field>
            </div>
          </div>

          <h3 class="is-size-3 has-text-weight-bold">Estados del Proyecto</h3>
          <!-- FORMULARIO DE ESTADOS -->
          <div v-for="(estado,index) in project.project_states_attributes">
            <div class="columns">
              <div class="column">
                <b-field label="Seleccione Estado del Proyecto*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="project.project_states_attributes[index].project_state_id"
                                 :options="estados_proyectos.map(x => x.id)"
                                 :custom-label="opt => estados_proyectos.find(x => x.id == opt).name"
                                 placeholder="Seleccione estado"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>

                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de estado del Proyecto*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                      <b-datepicker
                          v-model="project.project_states_attributes[index].state_date"
                          icon="calendar-alt"
                          trap-focus
                          locale="es-ES"
                          editable
                          placeholder="Formato de Fecha: dd/mm/aaaa">
                      </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>

            <div>
              <b-button @click.prevent="deleteState(index)" type="is-danger">Eliminar Estado de Proyecto</b-button>
              <br>
            </div>

          </div>

          <b-button class="mt-2" @click.prevent="addState" type="is-primary">Añadir Estado de Proyecto</b-button>



          <div class="actions has-text-right">
            <button class="button is-primary" type="submit" @click="checkValidation()">Guardar Proyecto</button>
          </div>

        </div>

      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="isModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-participant-project :project-id="projectId"
                                  :id="selected"
                                  :participantes="participantes"
                                  query-type="update"
                                  modal-header="Añadir Participantes"
                                  @close="props.close"
                                  @clicked="onClickChild"
        >
        </modal-participant-project>
      </template>
    </b-modal>

    <b-modal
        v-model="isAddModalFinancingActive"
        :width="1200"
        :destroy-on-hide="true"
        :can-cancel="canCancel">
      <template #default="props">
        <modal-financing-project
            query-type="update"
            modal-header="Añadir financiamiento"
            :entidad = "entidades_externas"
            :tipo = "tipos_financiamiento"
            :actual_financing = "actualFinancing"
            :actual_index = "actualIndex"
            @close="props.close"
            @clicked="onClickChild2"></modal-financing-project>
      </template>
    </b-modal>


  </section>
</template>
<script>
import axios from "axios";
import moment from "moment";
import ModalParticipantProject from "../projects/modal_participant_project.vue";
import ModalFinancingProject from "../projects/modal_financing_project.vue";
import NewProjectForm from "./NewProjectForm.vue";

export default {

  data(){
    return{
      errores_validacion:{},
      canCancel: ['escape'],
      project:{
        participants_attributes:[],
        financings_attributes:[],
        research_lines_attributes:[],
        project_states_attributes:[],
        key_words_attributes:[],
        validation_attributes:{}
      },
      tipos_proyecto:[],
      tipos_participacion:[],
      estados_participacion:[],
      unidades:[],
      estados_proyectos:[],
      entidades_externas:[],
      tipos_financiamiento:[],
      palabras_claves:[],
      palabra_clave:null,
      palabras_claves_filtradas:[],
      linea_investigacion:[],
      lineas_investigacion:[],
      lineas_investigacion_filtradas:[],
      project_categories:[],
      major_project_category:null,
      minor_project_categories:[],
      minor_project_category:null,
      vridei_categories:[],
      vridei_category:null,
      isLoading:false,
      requiredFieldOnly:false,
      participantes: [],
      currentPage:1,
      cardLayout:false,
      isModalActive: false,
      isAddModalFinancingActive: false,
      actualFinancing: null,
      actualIndex: null,
      financiamientos: [{
        amount: null,
        year: null,
        financing_type: null,
        partner_entity: null
      }]
    }
  },
  created(){
    this.isLoading=true;
    return axios.get("/projects/new.json")
        .then(res => {
          //this.tipos_proyecto = res.data['tipos_proyecto'];
          this.tipos_participacion = res.data['tipos_participacion'];
          this.estados_participacion = res.data['estados_participacion'];
          this.unidades = res.data['unidades'];
          this.estados_proyectos = res.data['estados_proyectos'];
          this.entidades_externas = res.data['entidades_externas'];
          this.tipos_financiamiento = res.data['tipos_financiamiento'];
          this.lineas_investigacion = res.data['lineas_investigacion'];
          this.lineas_investigacion_filtradas = res.data['lineas_investigacion'];
          this.getMajorCategories();
          this.isLoading = true;

          if (this.queryType!="update"){
            //se añade un participante automaticamente si es que es acádemico
            if(this.cargoPrincipal.name=="ACADEMICO" || this.cargoPrincipal.name=="HORAS CLASE"){
              this.project.participants_attributes.push({
                person_id:null,
                participant_state_id:null,
                participant_type_id:null,
                unit_id:this.unidadPrincipal.id,
                unit_participants:[],
                _destroy:null
              })
              if (this.project.participants_attributes[0].unit_id != null) {
                axios
                    .get("/get_people_by_unit.json", {
                      params: {unit_id: this.project.participants_attributes[0].unit_id}
                    })
                    .then(res => {
                      this.project.participants_attributes[0].unit_participants = res.data['personas'];
                      this.project.participants_attributes[0].person_id=this.personId;
                    }).catch(error => {
                      this.isLoading = false;
                      console.log(error)
                })
              }
            }
            this.isLoading=false;
          }
          if (this.queryType=="update"){
            axios
                .get("/projects/"+this.projectId+".json")
                .then(res => {
                  this.project = res.data
                  if (this.project.start_date)
                    this.project.start_date = new Date(moment(this.project.start_date).format('MM/DD/YYYY'));
                  if (this.project.end_date)
                    this.project.end_date = new Date(moment(this.project.end_date).format('MM/DD/YYYY'));
                  if (this.project.project_states_attributes!=null && this.project.project_states_attributes!=undefined){
                    var i = 0;
                    for(i =0; i<this.project.project_states_attributes.length;i++){
                      if(this.project.project_states_attributes[i].state_date!== null && this.project.project_states_attributes[i].state_date!== undefined &&this.project.project_states_attributes[i].state_date!= null){
                        this.project.project_states_attributes[i].state_date = new Date(this.project.project_states_attributes[i].state_date.replace(/-/g, '\/'))
                      }
                    }
                  }


                  if (this.project.validation_attributes==null){
                    this.project.validation_attributes={}
                    this.project.data_source=""
                  }
                  if(this.project.project_type_object!=null && this.project.project_type_object!=undefined){
                    this.major_project_category = this.project.project_type_object.parent_category_id
                    this.getMinorCategories();
                    this.project.project_type_id = this.project.project_type_object.id

                  }
                  if(this.project.participants!= null){
                    this.participantes = this.project.participants
                  }
                  this.isLoading=false;
                })
          }
        })
        .catch(e => {
          this.isLoading=false;
          console.log(e);
        });

  },
  props:[
      'userId','queryType','projectId','cargoPrincipal','unidadPrincipal','personId'
  ],
  components:{
    ModalParticipantProject,
    ModalFinancingProject,
    NewProjectForm,
  },
  methods:{
    deleteParticipant(participant){
      this.participantes = this.participantes.filter(person => person.person_id != participant.person_id);
    },
    execAddModal(id){
      this.selected = id;
      this.isModalActive = true;
    },
    onClickChild(value){
      this.isLoading = true
      this.participantes = value
      this.isLoading = false

    },
    onClickChild2(value){
      this.isLoading = true

      if (this.actualIndex != null){
        this.project.financings_attributes[this.actualIndex].financing_type_id = value.financing_type_id
        this.project.financings_attributes[this.actualIndex].partner_entity_id = value.partner_entity_id
        this.project.financings_attributes[this.actualIndex].year = value.year
        this.project.financings_attributes[this.actualIndex].amount = value.amount
        this.actualIndex = null
        this.actualFinancing = null
        this.isLoading = false
      }
      else{
        this.project.financings_attributes.push(value)
        this.isLoading = false
      }



    },
    saveProject(){
      this.isLoading=true;
      this.project.participants_attributes = this.participantes
      var retorno = JSON.parse(JSON.stringify(this.project.participants_attributes));
      for (var i=0;i<this.project.key_words_attributes.length;i++){
        if( !(typeof this.project.key_words_attributes[i] === 'object' && this.project.key_words_attributes[i] !== null) ){
          var name = this.project.key_words_attributes[i];
          this.project.key_words_attributes[i] = new Object();
          this.project.key_words_attributes[i].name=name;
          this.project.key_words_attributes[i].id=null;
        }
      }
      if (this.queryType==="create") {
        axios
            .post("/projects.json", {
              project: this.project,
              participants: retorno.map(function(x) {
                delete x.unit_id;
                delete x.unit_participants;
                return x;
              }),
              user_id:this.userId,
              data_source: this.project.validation_attributes.data_source
            })
            .then(response => {
              this.errores_validacion = {};
              window.location.href = '/projects/' +response.data["id"]
              this.isLoading=false;
            }).
          catch(error => {
            this.errores_validacion = error.response.data
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            this.isLoading=false;
          })
      }

      if (this.queryType==="update"){
        axios
            .put("/projects/"+this.projectId+".json", {
              project: this.project,
              participants: retorno.map(function(x) {
                delete x.unit_id;
                delete x.unit_participants;
                return x;
              }),
              user_id:this.userId,
              data_source: this.project.validation_attributes.data_source
            })
            .then(response => {
              this.errores_validacion = {};
              window.location.href = '/projects/'+this.projectId;
              this.isLoading=false;
            }).
            catch(error => {
              this.errores_validacion = error.response.data
              var elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              this.isLoading=false;
            })
      }

    },
    addParticipant(){
      this.project.participants_attributes.push({
        person_id:null,
        participant_state_id:null,
        participant_type_id:null,
        unit_id:null,
        unit_participants:[],
        _destroy:null
      })
    },
    addFinancing(){
      this.isAddModalFinancingActive = true
    },
    addState(){
      this.project.project_states_attributes.push({
        project_state_id:null,
        state_date:null,
      })
    },
    deleteFinancing(index){
      this.project.financings_attributes.splice(index,1)
    },
    editFinancing(index){
      this.actualFinancing = this.project.financings_attributes[index]
      this.actualIndex = index
      this.isAddModalFinancingActive = true
    },
    deleteState(index){
      this.project.project_states_attributes.splice(index,1)
    },
    findUnitParticipants(index){
      if(this.project.participants_attributes[index].unit_id!=null){
        this.isLoading=true;
        axios
            .get("/get_people_by_unit.json", {
              params: {unit_id: this.project.participants_attributes[index].unit_id}
            })
            .then(res => {
              this.project.participants_attributes[index].unit_participants = res.data['personas'];
              this.isLoading=false;
            }).
        catch(error => {
          this.isLoading=false;
          console.log(error)
        })

      }
    },
    blur: function () {
      this.computedForm.refs.selected.validate();
    },
    getFilteredTags(text) {
      if (text.length >= 3){
        axios
            .get("/projects/filter_keywords_project/"+text.toUpperCase()+".json")
            .then(res => {
              this.palabras_claves_filtradas = res.data['keywords'];
            }).
        catch(error => {
          console.log(error)
        })
      }
      else{
        this.palabras_claves_filtradas = []
      }

      this.palabras_claves_filtradas = this.palabras_claves.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    getFilteredResearchLines(text) {
      this.lineas_investigacion_filtradas = this.lineas_investigacion.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    async checkValidation(){
      const valid = await this.$refs.observer.validate()
      if(!valid){
        this.$buefy.dialog.alert({
              message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
      }
    },
    getMajorCategories(){
      this.isLoading = true;
      axios
          .get("/major_project_categories.json")
          .then(res => {
            this.project_categories = res.data;
            this.isLoading = false;
          }).catch(error => {
            this.isLoading = false;
            console.log(error)
          })

    },
    getMinorCategories(){

      this.setProjectTypeByCategory();


    },
    getVRIDEICategories(){
      this.isLoading = true;
      axios
          .get("/minor_project_categories.json",
              {params:{major_category_id:this.minor_project_category}})
          .then(res => {
            this.vridei_categories = res.data;
            this.isLoading = false;
            this.tipos_proyecto=[];
            this.project.project_type_id=null;
          }).catch(error => {
            this.isLoading = false;
            console.log(error)
      })

    },
    setProjectTypeByCategory(){
      this.isLoading = true;
      this.project.project_type_id = null;
      this.tipos_proyecto = [];
      var url = "/project_categories/"+this.major_project_category+"/major_project_types.json"
      axios
        .get(url)
        .then(res => {
          console.log(res);
          this.tipos_proyecto = res.data;
          this.isLoading = false;
        }).catch(error => {
          this.isLoading = false;
          console.log(error)
        })
    },
    getSubcategories(){
      this.isLoading = true;
      var aux_vridei = this.vridei_category;
      var aux_object = this.project.project_type_object
      axios
          .get("/project_categories/"+aux_vridei+"/subcategory.json")
          .then(res => {
            this.minor_project_category = res.data.id
            this.isLoading = true;
            setTimeout(() => {
              this.isLoading = true;
              axios
                  .get("/minor_project_categories.json",
                      {params:{major_category_id:this.minor_project_category}})
                  .then(res => {
                    this.vridei_categories = res.data;
                    this.vridei_category = aux_vridei;
                    this.project.project_type_id = aux_object.id;
                    this.isLoading = false;
                  }).catch(error => {
                    this.isLoading = false;
                    console.log(error)
                  })
            }, 2000);

          }).catch(error => {
            this.isLoading = false;
            console.log(error)
          })
      this.isLoading=false;

    },

  },
  computed:{
    computedForm: function () {
      return this.$refs.form;
    }
  }
}
</script>