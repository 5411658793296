<template>
  <section>
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body">
            <div class="is-centered" style="margin-top: 30px;">
              <div class="columns is-centered">
                <div class="box has-text-centered">
                  <template>
                    <b-button
                        outlined
                        type="is-primary"
                        icon-pack="fas"
                        icon-left="chevron-left"
                        :disabled="activeStep==0"
                        @click.prevent="back">
                    </b-button>
                    <b-button
                        outlined
                        type="is-primary"
                        icon-pack="fas"
                        icon-right="chevron-right"
                        :disabled="activeStep==2"
                        @click.prevent="next">
                    </b-button>
                  </template>
                </div>
              </div>
            </div>

            <b-steps :has-navigation="false" v-model="activeStep">
              <b-step-item label="Datos de Identificación" step="1" :clickable="true">
                <div class="container" style="margin-top: 40px">
                  <div class="columns">
                    <div class="column">
                      <b-field label="Nombres">

                          <b-input readonly v-model="person.name" type="text"></b-input>

                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Primer Apellido">

                          <b-input readonly v-model="person.last_name" type="text"></b-input>

                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Segundo Apellido">

                          <b-input readonly v-model="person.second_surname" type="text"></b-input>

                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-3">
                      <b-field label="Rut">

                          <b-input readonly v-model="rut"></b-input>

                      </b-field>
                    </div>
                    <div class="column is-1">
                      <b-field label="DV">
                        <b-input v-model="dv" maxlength="1" :has-counter=false></b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Correo personal">

                          <b-input readonly v-model="person.personal_mail"></b-input>

                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Pasaporte">
                        <b-input readonly v-model="passport" maxlength="20" :has-counter=false></b-input>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="Entidad">
                        <b-input readonly v-model="person.university_name" maxlength="255" :has-counter=false></b-input>
                      </b-field>
                    </div>
                  </div>
                </div>
              </b-step-item>
              <b-step-item label="Datos Personales" step="2" :clickable="true">
                <div class="container" style="margin-top: 40px">
                  <div class="columns">
                    <div class="column">
                      <b-field label="Fecha de nacimiento">
                        <b-input v-model="person.birthday_date" readonly></b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Género">
                        <b-input v-model="person.genders" readonly></b-input>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="Región">
                        <b-input v-model="person.regions" readonly></b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Comuna">
                        <b-input v-model="person.communes" readonly=""></b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Dirección">
                        <b-input v-model="person.address" maxlength="255" readonly></b-input>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="Nacionalidad">
                        <b-input v-model="person.nationality" readonly></b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="País de origen">
                        <b-input v-model="person.origin_country" readonly></b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Lugar de nacimiento">
                        <b-input v-model="person.birthplace" readonly=""></b-input>
                      </b-field>
                    </div>
                  </div>
                </div>

              </b-step-item>
              <b-step-item label="Datos de Contacto" step="3" :clickable="true">
                <div class="container" style="margin-top: 40px">
                  <div class="columns">
                    <div class="column">
                      <b-field label="Celular">
                          <b-input v-model="person.cellphone" expanded readonly></b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Teléfono">
                          <b-input v-model="person.phone" readonly></b-input>
                      </b-field>
                    </div>
                  </div>
                </div>
              </b-step-item>
            </b-steps>
            <!-- GUARDAR PERSONA-->
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="actions has-text-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <b-button v-if="can_create === 'true'" class="is-primary" @click="isEditModalActive = true">Editar</b-button>
          </div>
        </footer>
    <b-modal
        v-model="isEditModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-external-people-edit :person-id="person.id"
                                    query-type="update"
                                    modal-header="Editar Datos Personales"
                                    @close="props.close"></modal-external-people-edit>
      </template>
    </b-modal>
  </section>
</template>
<script>
import axios from "axios"
import moment from "moment"
import ModalExternalPeopleEdit from './modal_external_people_edit'
export default {
  name: "modal_person_show",
  components: {ModalExternalPeopleEdit},
  data(){
    const data =[]
    return {
      person:{
        run_attributes:{
          rut: null,
          dv: null,
          passport: null,
          run_completo: null
        },
        region_id: null,
        is_registered: false,
        skip_if_external_people: true,
        university_name: null,
      },
      rut:'',
      dv:'',
      passport:'',
      canCancel: ['escape'],
      isEditModalActive: false,
      regions: [],
      genders: [],
      communes: [],
      countries: [],
      marital_statuses: [
        {name: "Soltero(a)" },
        {name: "Casado(a)" },
        {name: "Viudo(a)" },
        {name: "Divorciado(a)" },
        {name: "Conviviente civil" }
      ],
      checkUnique: false,
      checkRut: false,
      checkDV: false,
      errores_validacion: {},
      isLoading:false,
      activeStep: 0,
      cellphone: null
    }
  },
  props:[
    'userId', 'queryType', 'personId', 'modalHeader','can_create'
  ],
  created(){
    this.initialize()
  },
  methods: {
    initialize(){
      this.isLoading=true;
      axios
          .get("/people/"+this.personId+".json")
          .then(res => {
            this.person = res.data
            if (res.data["birthday_date"] !== null) {
              this.person.birthday_date = moment(res.data["birthday_date"], 'YYYY-MM-DD')._d;
              this.person.birthday_date = new Date(this.person.birthday_date).toLocaleDateString("es-CL")
            }

            if (this.person.run_attributes != null){
              this.rut = this.person.run_attributes.rut
              this.dv = this.person.run_attributes.dv
              this.passport = this.person.run_attributes.passport
            }
            this.person.nationality = res.data["nationality_attributes"]["name"]
            this.person.regions = res.data["region_attributes"]["name"]
            this.person.genders = res.data["gender_attributes"]["name"]
            this.person.origin_country = res.data["origin_country_attributes"]["name"]
            if (res.data["commune_attributes"] != null) {
              this.person.communes = res.data["commune_attributes"]["name"]
            }
            else{
              this.person.communes = ''
            }
            this.isLoading=false;
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    },
    next(){
      if(this.activeStep < 4){
        this.activeStep = this.activeStep +1
      }
    },
    back(){
      if(this.activeStep > 0){
        this.activeStep = this.activeStep -1
      }
    },
  },
  watch:{
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
  }
}
</script>

<style scoped>

</style>