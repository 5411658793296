<template>
    <section>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(validateData)">
              <div class="modal-card" style="width: 1200px">
                <header class="modal-card-head">
                  <p class="modal-card-title">{{modalHeader}}</p>
                  <button
                      type="button"
                      class="delete"
                      @click="$emit('close')"/>
                </header>
                <section class="modal-card-body" ref="cardAcademicAppointmentForm">
                  <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
                    <b-message type="is-danger" has-icon>
                      <div v-for="(item,index) in errores_validacion" :key="index">
                        {{ item[0] }}
                      </div>
                    </b-message>
                  </div>
                  <h3 class="is-size-6 has-text-weight-bold">Búsqueda de carpeta de documento*
                    <b-tooltip
                        label="Ingrese al menos 3 caracteres para realizar una búsqueda."
                        position="is-bottom"
                        size="is-medium"
                        multilined>

                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          size="is-small"
                          type="is-info">
                      </b-icon>
                    </b-tooltip>
                  </h3>
                  <div class="columns">
                    <div class="column">
                      <b-field>
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <multiselect v-model="document.document_category_id"
                                       :options="document_categories.map(type => type.id)"
                                       :custom-label="opt => document_categories.find(x => x.id === opt).show_name"
                                       placeholder="Seleccione Carpeta de Documento (ingrese al menos 3 caracteres para realizar la búsqueda)"
                                       selectLabel="Presione para seleccionar"
                                       selectedLabel="Seleccionado"
                                       deselectLabel="No se puede deseleccionar"
                                       @search-change="searchCategory"
                                       :internal-search="false"
                                       :loading="isLoadingMultiselect"
                                       :disabled="isLoadingMultiselect"
                                       :allow-empty="false">
                            <template v-slot:noOptions>
                              No existen datos
                            </template>
                            <template slot="option" slot-scope="props">
                              {{ document_categories.find(x => x.id === props.option).show_name}}
                            </template>
                            <span slot="noResult">
                            No se encontraron elementos.
                          </span>
                          </multiselect>

                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="Tipo de documento*">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <multiselect v-model="document.document_type_id"
                                     :options="document_types.map(type => type.id)"
                                     :custom-label="opt => document_types.find(x => x.id === opt).name"
                                     placeholder="Seleccione tipo de documento"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel="Seleccionado"
                                     deselectLabel="Presione para deseleccionar"
                                     :allow-empty="true"
                                       @input="changeDocumenType">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                              No se encontraron elementos.
                          </span>
                        </multiselect>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div v-if="visible_number" class="column">
                      <b-field label="N° Documento">
                        <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                          <b-input v-model="document.resolution" type="text" @input="generateName"></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div v-if="visible_date" class="column">
                      <b-field label="Fecha Documento">
                        <b-datepicker
                            v-model="document.document_date"
                            icon="calendar-alt"
                            trap-focus
                            locale="es-ES"
                            editable
                            placeholder="Formato de Fecha: dd/mm/aaaa"
                            @input="generateName"
                        >
                        </b-datepicker>
                      </b-field>
                    </div>
                    <div v-if="visible_year" class="column">
                      <b-field label="Año Documento">
                        <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                          <b-input v-model="document.year" type="text" @input="generateName" ></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>
                  <div v-if="visible_description" class="columns">
                    <div class="column">
                      <b-field label="Nombre adicional del documento">
                        <b-input v-model="document.description" type="text" minlength="5" maxlength="255" @input="generateName"></b-input>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="¿El documento está firmado?">
                        <b-radio-button v-model="document.signed"
                                        :native-value="true"
                                        type="is-success is-light is-outlined">
                          <b-icon icon="check-circle"></b-icon>
                          <span>Si</span>
                        </b-radio-button>
                        <b-radio-button v-model="document.signed"
                                        :native-value="false"
                                        type="is-danger is-light is-outlined">
                          <b-icon icon="times-circle"></b-icon>
                          <span>No</span>
                        </b-radio-button>
                      </b-field>
                    </div>
                  </div>
                  <h3 class="is-size-6 has-text-weight-bold">Etiquetas
                    <b-tooltip
                        label="Para agregar una etiqueta, presione enter o coma para que esta sea guardada."
                        position="is-bottom"
                        size="is-medium"
                        multilined>

                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          size="is-small"
                          type="is-info">
                      </b-icon>
                    </b-tooltip>
                  </h3>
                  <div class="columns">
                    <div class="column">
                      <b-field label=" ">
                        <b-taginput
                            v-model="document.tags_attributes"
                            :data="filtered_tags"
                            type="is-primary is-light"
                            autocomplete
                            :allow-new="true"
                            field="name"
                            icon="tag"
                            placeholder="Agrega una etiqueta"
                            @typing="getFilteredTags">
                        </b-taginput>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns has-text-centered">
                    <div class="column">
                      <b-field extended label="Archivo PDF, Word o Excel (Tamaño max. 25 MB)*">
                        <b-upload v-model="file"
                                  drag-drop
                                  type="is-primary"
                                  accept=".docx,.pdf,.doc,.xlsx"
                                  @input="generateName">
                          <section class="section">
                            <div class="content has-text-centered">
                              <p>
                                <b-icon
                                    icon="file-alt"
                                    size="is-large">
                                </b-icon>
                              </p>
                              <p>Arrastra tu documento o haz click.</p>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns tags is-centered">
                    <span
                        v-if="file !== null"
                        v-model="file"
                        class="tag is-primary mt-1">
                      {{ file.name }}
                      <button
                          class="delete is-small"
                          type="button"
                          @click="onDeleteFile"
                      ></button>
                    </span>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="Nombre de archivo a subir">
                        <b-input disabled v-model="document_name"></b-input>
                      </b-field>
                    </div>
                  </div>
                </section>
              </div>
              <footer class="modal-card-foot actions">
                <div class="field is-grouped is-pulled-right">
                  <button class="button" type="button" @click="$emit('close')">Cerrar</button>
                  <button class="button is-primary" type="submit">Guardar </button>
                </div>
              </footer>
            </form>
          </ValidationObserver>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import {parse_drive_link} from "../../packs/utilities"

export default {
  name: "modal_document_form",
  components:{
  },
  data() {
    return{
      isLoading: false,
      errores_validacion:{},
      canCancel: ['escape'],
      document:{
        document_state_id: 1,
        tags_attributes: []
      },
      document_types: [],
      document_categories: [],
      tags:[],
      isLoadingMultiselect: false,
      currentPage:1,
      filtered_tags: [],
      file: null,
      document_name: null,
      document_types_name: [],
      visible_year: false,
      visible_date: true,
      visible_number: true,
      visible_description: true,

    }
  },
  props:['queryType','documentId', "cargoPrincipal", 'modalHeader', 'actualFolder'],

  created() {
    this.initialize()
  },
  methods :{
    initialize(){
      this.isLoading = true
      if (this.queryType === "update"){
        axios
            .get("/documents/"+this.documentId+"/edit.json")
            .then(res => {
              this.document = res.data
              if (res.data['document_date'] != null){
                this.document.document_date = new Date(moment(res.data['document_date']).format('MM/DD/YYYY'));
              }
              this.document_categories = res.data["document_categories"]
              this.document_types = res.data["document_types"]
              this.filtered_tags = res.data["all_tags"]
              if(this.actualFolder){
                this.document_categories.push(this.actualFolder)
                this.document.document_category_id = this.actualFolder.id
              }
              this.isLoading = false
            })
            .catch(e=>{
              console.log(e);
              this.isLoading = false;
            })
      }
      else if(this.queryType==="create"){
        axios
            .get("/documents/new.json")
            .then(res => {
              this.document_types = res.data["document_types"]
              this.tags = res.data["tags"]
              if(this.actualFolder){
                this.document_categories.push(this.actualFolder)
                this.document.document_category_id = this.actualFolder.id
              }
              this.document_types_name = res.data["document_types_name"]
              this.isLoading = false;
            }).catch(error => {
          console.log(error);
          this.isLoading = false;
        })
      }
    },
    parse_drive_link,
    updateId(){
      this.isLoading = true
      this.document.google_id = this.parse_drive_link(this.document.documentation_link)
      this.isLoading = false
    },
    validateData(){
      if(this.file == null || this.file == undefined){
        this.file = null;
        this.isLoading = false;
        this.$buefy.toast.open({
          message:
              "Tiene que adjuntar un archivo primero.",
          type: "is-danger",
          size: "is-medium",
          "has-icon": true,
          position: "is-bottom-right",
          "progress-bar": true,
          duration: 5000,
          queue: true,
        });
        return;
      }
      else if (this.file != null && (this.file.name.split(".").at(-1).toLowerCase() !== "pdf") && (this.file.name.split(".").at(-1).toLowerCase() !== "docx") && (this.file.name.split(".").at(-1).toLowerCase() !== "doc") && (this.file.name.split(".").at(-1).toLowerCase() !== "xlsx") ) {
        this.file = null;
        this.isLoading = false;
        this.$buefy.toast.open({
          message:
              "Este archivo no tiene el formato solicitado.",
          type: "is-danger",
          size: "is-medium",
          "has-icon": true,
          position: "is-bottom-right",
          "progress-bar": true,
          duration: 5000,
          queue: true,
        });
        return;
      }
      else if (this.file != null && this.file.size > 1024*1024*25) {
        this.file = null;
        this.isLoading = false;
        this.$buefy.toast.open({
          message:
              "El archivo no puede superar el maximo de 25 MB.",
          type: "is-danger",
          size: "is-medium",
          "has-icon": true,
          position: "is-bottom-right",
          "progress-bar": true,
          duration: 5000,
          queue: true,
        });
        return;
      }
      else if(this.document.signed == false || this.document.signed == null || this.document.signed == undefined){
        this.$buefy.dialog.confirm({
          title: 'Documento sin firma',
          message: 'Recuerde que se deben subir documentos oficiales y firmados, de todas formas ¿Desea continuar?',
          cancelText: 'Cancelar',
          confirmText: 'Continuar',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => this.saveDocument(),
        })
      }
      else{
        this.saveDocument()
      }

    },
    saveDocument(){
      this.isLoading = true;
      if(this.queryType === "create"){
        this.generateName();
        let formData = new FormData();
        formData.append('file', this.file)
        Object.entries(this.document).forEach(([key, value]) => {
          // Si el valor es un arreglo (como el caso de "tags")
          if (Array.isArray(value)) {
            // Convertir cada elemento del arreglo a un string
            value.forEach((item, index) => {
              // Verificar si es un objeto para convertirlo a JSON
              if (typeof item === 'object') {
                formData.append(`${key}[${index}]`, JSON.stringify(item));
              } else {
                formData.append(`${key}[${index}]`, item);
              }
            });
          } else {
            // Si no es un arreglo, agregarlo directamente al FormData
            formData.append(key, value);
          }
        })
        formData.append("document_name", this.document_name)
        axios
            .post("/documents.json",formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
            .then(response => {
              this.errores_validacion = {};
              this.$buefy.toast.open({
                message:
                    "El documento fue guardado correctamente.",
                type: "is-success",
                size: "is-medium",
                "has-icon": true,
                position: "is-bottom-right",
                "progress-bar": true,
                duration: 5000,
                queue: true,
              });
              this.$emit('close')
              this.isLoading=false;
            }).
        catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$buefy.notification.open({
            message: "Ocurrió un error al guardar el documento.",
            type: "is-danger",
            size: "is-medium",
            "has-icon": true,
            position: "is-bottom-right",
            indefinite: true,
            queue: true,
            role: "alert",
          });
          this.isLoading=false;
        })
      }
      if(this.queryType === "update"){
        axios
            .put("/documents/"+this.documentId+".json",{
              document: this.document,
            })
            .then(response => {
              this.errores_validacion = {};
              this.$buefy.toast.open({
                message:
                    "El documento fue guardado correctamente.",
                type: "is-success",
                size: "is-medium",
                "has-icon": true,
                position: "is-bottom-right",
                "progress-bar": true,
                duration: 5000,
                queue: true,
              });
              this.$emit('close')
              this.isLoading=false;
            })
            .catch(error => {
              this.errores_validacion = error.response.data
              var elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              this.$buefy.notification.open({
                message: "Ocurrió un error al guardar el documento.",
                type: "is-danger",
                size: "is-medium",
                "has-icon": true,
                position: "is-bottom-right",
                indefinite: true,
                queue: true,
                role: "alert",
              });
              this.isLoading=false;
            })
      }
    },
    searchCategory(query){
      if(query.length<3){
        return
      }
      this.isLoadingMultiselect = true;
      this.document.document_category_id = null;
      axios
          .get("/document_categories/search-categories.json",
              {
                params: {
                  query: query,
                  type: 1 //PARA AGREGAR DOCUMENTOS
                }
              })
          .then(res => {
            this.document_categories = res.data
            this.isLoadingMultiselect=false;

          }).catch(error => {
        console.log(error)
        this.isLoadingMultiselect=false;
      })
    },
    getFilteredTags(text){
      this.filtered_tags = this.tags.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    onDeleteFile() {
      this.file = null;
    },
    generateName(){
      var tipo = ""
      var number = ""
      var date = ""
      var year = ""
      var name = ""

      if (this.document.document_type_id!= null){
        const document_type_id = this.document_types.find(x => x.id === this.document.document_type_id).document_type_name_id;
        tipo = this.document_types_name.find(x => x.id === document_type_id).initials
        if(tipo == null){
          tipo = "SIN_INFO"
        }
      }

      if (this.document.resolution != null && this.document.resolution != ""){
        number = "-N_" + this.document.resolution
        if(tipo == "PCR_RC"){
          number = "-V_"+this.document.resolution
        }
      }

      if (this.document.document_date != null && this.document.document_date != ""){
        date = "-" + ("0" + this.document.document_date.getDate()).slice(-2)
            + "_" + ("0" + (this.document.document_date.getMonth()+1)).slice(-2)
            + "_" + this.document.document_date.getFullYear()

      }
      if(this.document.description != null && this.document.description != ""){
        name = "-"+this.document.description
      }
      if(this.document.year != null && this.document.year != ""){
        year = "-"+this.document.year
      }
      this.document_name = tipo+number+year+date+name
    },
    changeDocumenType(){
      var tipo = ""
      if (this.document.document_type_id!= null){
        const document_type_id = this.document_types.find(x => x.id === this.document.document_type_id).document_type_name_id;
        tipo = this.document_types_name.find(x => x.id === document_type_id).initials
        if(tipo == "ACTACF_SO"|| tipo == "ACTACF_SE" || tipo == "M_ACTACF_SO" || tipo == "M_ACTACF_SE" || tipo == "ACTACD" || tipo == "ACTAID" || tipo == "ACTAVIME" || tipo == "ACTAPOST"){
          this.document.year = null
          this.visible_year = false
          this.visible_date = true
          if(tipo == "M_ACTACF_SO" || tipo == "M_ACTACF_SE"){
            this.visible_description = true
          }
          else{
            this.document.description = null
            this.visible_description = false
          }
          this.generateName()
        }
        else if(tipo == "CRIT" || tipo == "MAN" ||  tipo == "POL" ||  tipo == "CONV" ||  tipo == "NORM" ||  tipo == "PRT" ||  tipo == "EST" || tipo == "REGL" || tipo == "RES" ||  tipo == "DECR" ||  tipo == "ORD" ||  tipo == "LEY" ||  tipo == "CIR"){
          this.document.document_date = null
          this.visible_year = true
          this.visible_date = false
          this.visible_description = true
          if(tipo == "CRIT" || tipo == "MAN" || tipo == "POL" || tipo == "CONV" ||  tipo == "NORM" ||  tipo == "PRT" ||  tipo == "EST" ){
            this.document.resolution = null
            this.visible_number = false
          }
          else{
            this.visible_number = true
          }
          this.generateName()
        }
        else if(tipo == "PCR_RC" || tipo == "FORM"){
          this.document.document_date = null
          this.document.year = null
          this.visible_year = false
          this.visible_date = false
          this.visible_description = true
          if(tipo == "PCR_RC" ){
            this.visible_number = true
          }
          else{
            this.document.resolution = null
            this.visible_number = false
          }
          this.generateName()

        }
        else{
          this.document.year = null
          this.visible_year = false
          this.visible_date = true
          this.generateName()
        }
      }
    }

  },



}

</script>