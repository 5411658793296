<template>
  <b-notification class="m-3" :closable="false">
    <section class="m-3">
      <div class="columns">
        <article class="column has-text-centered">
          <header
            class="is-flex is-align-items-flex-start is-justify-content-center"
          >
            <b-icon icon="calendar-alt" class="mr-2"></b-icon>
            <div>
              <strong>Fecha de petición</strong>
              <p>{{ requestDate }}</p>
            </div>
          </header>
        </article>
        <article class="column has-text-centered">
          <header
            class="is-flex is-align-items-flex-start is-justify-content-center"
          >
            <b-icon icon="id-card" class="mr-2"></b-icon>
            <div>
              <strong>Identificador SIGA (IDS)</strong>
              <p>#{{ id }}</p>
            </div>
          </header>
        </article>
        <article v-if="false" class="column has-text-centered">
          <header
            class="is-flex is-align-items-flex-start is-justify-content-center"
          >
            <b-icon icon="link" class="mr-2"></b-icon>
            <div>
              <strong>Url del documento</strong>
              <p>
                <a :href="url" target="_blank" rel="noopener noreferrer"
                  >Ver documento</a
                >
              </p>
            </div>
          </header>
        </article>
      </div>
    </section>
  </b-notification>
</template>

<script>
export default {
  props: {
    requestDate: {
      type: String,
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    url: {
      type: String,
      default: "",
    },
  },
};
</script>
