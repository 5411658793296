<template>
  <section>
    <section>
      <div class="box">
        <div class="columns">
          <div class="column has-text-centered">
            <div class="title is-3 is-centered">
              Mi perfil
            </div>
          </div>
        </div>
        <div class="container">
<!--          <b-tabs position="is-centered" @input="changeTab">
            <b-tab-item v-for="item in items"
                        v-bind:key="item.content"
                        :value="item.content"
                        :label="item.tab"
                        :icon="item.icon"
                        >
            </b-tab-item>
          </b-tabs>
          &lt;!&ndash; COMPONENTE AQUI &ndash;&gt;
          <keep-alive>
            <component v-bind:is="currentTabComponent" :person-id="personId"></component>
          </keep-alive>-->
          <personal_data_tab :person-id="personId"></personal_data_tab>

          <!-- FIN COMPONENTE -->
        </div>
      </div>
    </section>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import personal_data_tab from "./personal_data_tab";
import academic_data_tab from "./academic_data_tab";
import work_experiences_tab from "./work_experiences_tab";
import awards_data_tab from "./awards_data_tab";
import scholarships_data_tab from "./scholarships_data_tab";
import related_institutions_data_tab from "./related_institutions_data_tab";
import teaching_data_tab from "./teaching_data_tab";
export default {
  components: {personal_data_tab, academic_data_tab, work_experiences_tab, awards_data_tab, scholarships_data_tab, related_institutions_data_tab,
               teaching_data_tab},
  name: "ProfileForm",
  data(){
    return{
      isLoading:false,
      currentTab: 'personal_data_tab',
      items: [
        {tab: 'Datos Personales',content:'personal_data_tab',icon:"user", permission: true},
        {tab: 'Datos Académicos',content:'academic_data_tab',icon:"user-graduate", permission: true},
        {tab: 'Exp. Profesional',content:'work_experiences_tab',icon:"briefcase", permission: this.can_add_data},
        {tab: 'Exp. Docente',content:'teaching_data_tab',icon:"chalkboard", permission: this.can_add_data},
        {tab: 'Premios',content:'awards_data_tab',icon:"award", permission: this.can_add_data},
        {tab: 'Becas de Estudio',content: 'scholarships_data_tab', icon:"school", permission: this.can_add_data},
        {tab: 'Instituciones relacionadas',content:'related_institutions_data_tab',icon:"industry", permission: this.can_add_data}
      ],

    }
  },
  props:[
      'personId','can_add_data','cargoPrincipal'
  ],
  methods:{

    changeTab: function(value){
      this.currentTab = value
    },
  },
  computed:{
    currentTabComponent: function () {
      return this.currentTab
    }
  }
}
</script>

<style scoped>

</style>