<template>
  <section>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column">
            <b-button class="is-danger" v-if="household_allowance.can_cease"
                      @click="isDistanceModalActive = true">
              Cese de Asignación Familiar
            </b-button>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Rut">
              <b-input :value="household_allowance.rut" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column is-three-fifths">
            <b-field label="Nombres">
              <b-input :value="household_allowance.nombre_completo" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Tipo de Carga">
              <b-input :value="household_allowance.dependent_type.name" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Parentesco">
              <b-input :value="household_allowance.relationship" type="text" readonly></b-input>
            </b-field>
          </div>

        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Fecha de inicio">
              <b-input :value="formatDate(household_allowance.start_date)" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha de término">
              <b-input :value="formatDate(household_allowance.expiration_date)" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Fecha de nacimiento">
              <b-input :value="formatDate(household_allowance.birthday)" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Resolución">
              <b-input :value="household_allowance.decree" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha de Resolución">
              <b-input :value="formatDate(household_allowance.decree_date)" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns is-gapless">
          <div class="column">
            <b-field label="Documento">
              <b-input expanded readonly v-model="household_allowance.document_name" type="text"></b-input>
            </b-field>
          </div>
          <div class="column is-narrow">
            <br>
            <b-field v-if="household_allowance.can_view_pdf" label="  ">
              <b-button @click="viewFile(household_allowance.document_id)" icon-left="eye" type="is-success">ver</b-button>
              <b-button @click="downloadFile(household_allowance.document_id)" icon-left="download" type="is-info">Descargar</b-button>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Observaciones">
              <b-input :value="household_allowance.observations" type="textarea" readonly></b-input>
            </b-field>
          </div>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
      </section>
    </div>
    <footer class="modal-card-foot actions">
      <div class="has-text-right is-grouped">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
        <b-button class="is-primary"
                  v-if="household_allowance.can_edit"
                  @click="isEditModalActive = true">
          Editar
        </b-button>
      </div>
    </footer>
    <b-modal
        v-model="isEditModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal-household :person-id="household_allowance.person_id"
                          :id="household_allowance.id"
                          query-type="update"
                         :modal-header="'Editar Carga Familiar - '+person_name"
                          @close="props.close">
        </modal-household>
      </template>
    </b-modal>
    <b-modal
        v-model="isDistanceModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        :can-cancel="canCancel"
        aria-modal>
      <template #default="props">
        <modal-distance-household-form :person-id="household_allowance.person_id"
                                :household_allowance_id="household_allowance.id"
                                :id="household_allowance.id"
                                :person_name="household_allowance.name"
                                :person_last_name="household_allowance.last_name"
                                :person_second_surname="household_allowance.second_surname"
                                :person_birthday="household_allowance.birthday"
                                :rut="household_allowance.rut"
                                :old_start_date="household_allowance.start_date"
                                query-type="create"
                                :modal-header="'Cese de Asignación - '+person_name"
                                @close="props.close">
        </modal-distance-household-form>
      </template>
    </b-modal>
    <b-modal
        v-model="showPdf"
        :can-cancel="true"
        :destroy-on-hide="true"
        aria-modal
        aria-role="dialog"
        has-modal-card
        trap-focus
        :width="2000"
    >
      <template #default="props">
        <pdf_view
            :src="src"
            @close="props.close"
        ></pdf_view>
      </template>

    </b-modal>
  </section>
</template>

<script>
import axios from "axios";
import ModalHousehold from "../life_record/modal_household_form.vue";
import ModalDistanceHouseholdForm from "../life_record/household_allowances/modal_distance_household"
import {formatDate} from "../../packs/utilities";
import Pdf_view from "../performances/pdf_view.vue";

export default {
  name: "household_allowance",
  props:[
      "id", "modalHeader", "person_name"
  ],
  components:{
    Pdf_view,
    ModalHousehold,
    ModalDistanceHouseholdForm
  },
  data(){
    return {
      household_allowance:{dependent_type: {name: ""}},
      isLoading: false,
      isEditModalActive:false,
      isDistanceModalActive: false,
      canCancel: ['escape'],
      showPdf: false,
      src:null,
    }
  },
  created(){
    this.initialize();
  },
  methods:{
    initialize() {
      this.isLoading = true;
      axios
          .get("/household_allowances/" + this.id + ".json")
          .then(res => {
            this.household_allowance = res.data
            this.isLoading = false;
          }).catch(error => {
            console.log(error)
            this.isLoading = false;
          })
    },
    formatDate,
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            this.showPdf = true
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    downloadFile(fileId){
      this.isLoading = true;

      if (fileId == null){
        this.isLoading = false;
        return;
      }

      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(res => {
            let blob = new Blob([res.data], {
              type: "application/pdf",
            })
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = res.headers["content-disposition"]
                .split(";")[1]
                .split('"')[1];
            link.click();
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento fue descargado con exito',
              type: 'is-success',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
          .catch(e => {
            console.log(e)
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento no se pudo descargar',
              type: 'is-danger',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
    },
  },
  watch: {
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
  }
}
</script>

<style scoped>

</style>