<template>
    <section>

        <div>
            <b-modal v-model="isPersonModalActive" has-modal-card trap-focus :destroy-on-hide="true" width="100%"
                aria-role="dialog" aria-modal :can-cancel="false">
                <template #default="props">
                    <ModalNewExternalPerson query-type="create" modal-header="Agregar Datos Personales"
                        @clicked="onClickChildPerson" @close="props.close" />
                </template>
            </b-modal>

            <b-modal v-model="isEntityModalActive" has-modal-card trap-focus :destroy-on-hide="true" width="100%"
                aria-role="dialog" aria-modal :can-cancel="false">
                <template #default="props">
                    <ModalNewParterEntity qquery-type="update" modal-header="Añadir Nueva Entidad" @close="props.close"
                        @clicked="opt => searchPartnerEntities(opt.name)" />
                </template>
            </b-modal>
        </div>

        <div class="modal-card" style="width: 1280px; max-width: 100%;">
            <header class="modal-card-head">
                <p class="modal-card-title">{{ edit ? 'Editar Convenio' : 'Nuevo Convenio' }}</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">


                <b-steps v-model="activeStep">

                    <b-step-item step="1" label="Información General" :clickable="true">

                        <ValidationObserver v-slot="{ handleSubmit }" ref="observer1">
                            <form @submit.prevent="handleSubmit(check())">

                                <div class="columns mt-2">

                                    <div class="column">
                                        <ValidationProvider name="Nombre del Convenio" rules="min:3|max:255|required"
                                            v-slot="{ errors, failedRules }">
                                            <b-field label="Nombre del Convenio *"
                                                :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                                :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                                <b-input v-model="agreement.name"
                                                    placeholder="Ingrese el nombre del Convenio" />
                                            </b-field>
                                        </ValidationProvider>
                                    </div>

                                    <div class="column">
                                        <ValidationProvider name="Unidad del Convenio" v-slot="{ errors, failedRules }">
                                            <b-field label="Unidad del Convenio"
                                                :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                                :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                                <multiselect v-model="agreement.unit_id"
                                                    :options="agreement_units.map(type => type.id)"
                                                    :custom-label="opt => agreement_units.find(x => x.id === opt).name"
                                                    placeholder="Seleccione una Unidad"
                                                    selectLabel="Presione para seleccionar" selectedLabel="Seleccionado"
                                                    deselectLabel="" :allow-empty="false" noOptions="No hay elementos"
                                                    noResult="No se han encontrado resultados para su búsqueda">
                                                    <template v-slot:noOptions> No hay datos. </template>
                                                    <template v-slot:noResult> No se han encontrado resultados para su
                                                        búsqueda, pruebe con otra. </template>
                                                </multiselect>
                                            </b-field>
                                        </ValidationProvider>
                                    </div>

                                </div>

                                <div class="columns">

                                    <div class="column">
                                        <ValidationProvider name="Tipo de Convenio General" rules="required"
                                            v-slot="{ errors, failedRules }">
                                            <b-field label="Tipo de Convenio General *"
                                                :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                                :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                                <multiselect v-model="agreement_general_type_id"
                                                    :options="agreement_general_types.map(type => type.id)"
                                                    :custom-label="opt => agreement_general_types.find(x => x.id === opt).name"
                                                    placeholder="Seleccione un Tipo"
                                                    selectLabel="Presione para seleccionar" selectedLabel="Seleccionado"
                                                    deselectLabel="" :allow-empty="false" noOptions="No hay elementos"
                                                    noResult="No se han encontrado resultados para su búsqueda">
                                                    <template v-slot:noOptions> No hay datos. </template>
                                                    <template v-slot:noResult> No se han encontrado resultados para su
                                                        búsqueda, pruebe con otra. </template>
                                                </multiselect>
                                            </b-field>
                                        </ValidationProvider>
                                    </div>

                                    <div class="column">
                                        <ValidationProvider name="Tipo de Convenio Específico" rules="required"
                                            v-slot="{ errors, failedRules }">
                                            <b-field label="Tipo de Convenio Específico *"
                                                :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                                :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                                <multiselect v-model="agreement_specific_type_id"
                                                    :options="agreement_specific_types.map(type => type.id)"
                                                    :custom-label="opt => agreement_specific_types.find(x => x.id === opt).name"
                                                    placeholder="Seleccione un Tipo"
                                                    selectLabel="Presione para seleccionar" selectedLabel="Seleccionado"
                                                    deselectLabel="" :allow-empty="false" noOptions="No hay elementos"
                                                    noResult="No se han encontrado resultados para su búsqueda">
                                                    <template v-slot:noOptions> No hay datos. </template>
                                                    <template v-slot:noResult> No se han encontrado resultados para su
                                                        búsqueda, pruebe con otra. </template>
                                                </multiselect>
                                            </b-field>
                                        </ValidationProvider>
                                    </div>

                                </div>

                                <div class="columns">

                                    <div class="column">
                                        <ValidationProvider name="N° de Resolución" rules="max:255|numeric"
                                            v-slot="{ errors, failedRules }">
                                            <b-field label="N° de Resolución"
                                                :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                                :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                                <b-input v-model="agreement.resolution_number"
                                                    placeholder="Ingrese el número de resolución" type="number" />
                                            </b-field>
                                        </ValidationProvider>
                                    </div>

                                    <div class="column">
                                        <ValidationProvider name="Fecha de Resolución" v-slot="{ errors, failedRules }">
                                            <b-field label="Fecha de Resolución"
                                                :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                                :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                                <b-datepicker placeholder="Formato de Fecha: dd/mm/aaaa"
                                                    :value="agreement.creation_date" icon="calendar-alt" locale="es-ES"
                                                    editable v-model="agreement.creation_date" />
                                            </b-field>
                                        </ValidationProvider>
                                    </div>

                                    <div class="column">
                                        <ValidationProvider name="Tipo de Resolución" v-slot="{ errors, failedRules }">
                                            <b-field label="Tipo de Resolución "
                                                :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                                :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                                <multiselect v-model="agreement.resolution_type_id"
                                                    :options="resolution_types.map(type => type.id)"
                                                    :custom-label="opt => resolution_types.find(x => x.id === opt).name"
                                                    placeholder="Seleccione un Tipo"
                                                    selectLabel="Presione para seleccionar" selectedLabel="Seleccionado"
                                                    deselectLabel="" :allow-empty="false" noOptions="No hay elementos"
                                                    noResult="No se han encontrado resultados para su búsqueda">
                                                    <template v-slot:noOptions> No hay datos. </template>
                                                    <template v-slot:noResult> No se han encontrado resultados para su
                                                        búsqueda, pruebe con otra. </template>
                                                </multiselect>
                                            </b-field>
                                        </ValidationProvider>
                                    </div>

                                </div>

                                <div class="columns">

                                    <div class="column">
                                        <ValidationProvider name="Fecha de Inicio"
                                            :rules="{start_date_before_end_date: agreement.end_date}"
                                            v-slot="{ errors, failedRules }">
                                            <b-field label="Fecha de Inicio"
                                                :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                                :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                                <b-datepicker placeholder="Formato de Fecha: dd/mm/aaaa"
                                                    :value="agreement.start_date" icon="calendar-alt" locale="es-ES"
                                                    editable v-model="agreement.start_date" />
                                            </b-field>
                                        </ValidationProvider>
                                    </div>

                                    <div class="column">
                                        <ValidationProvider name="Fecha de Término"
                                            :rules="{end_date_not_before_start_date: agreement.start_date}"
                                            v-slot="{ errors, failedRules }">
                                            <b-field label="Fecha de Término"
                                                :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                                :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                                <b-datepicker placeholder="Formato de Fecha: dd/mm/aaaa"
                                                    :value="agreement.end_date" icon="calendar-alt" locale="es-ES"
                                                    editable v-model="agreement.end_date" :disabled="disabledEndDate" />
                                            </b-field>
                                        </ValidationProvider>
                                    </div>

                                    <div class="column is-narrow">
                                        <b-field label="¿Es indefinido?">
                                            <b-radio-button v-model="disabledEndDate" :native-value="false"
                                                type="is-danger">
                                                <b-icon icon="times-circle"></b-icon>
                                                <span>No</span>
                                            </b-radio-button>
                                            <b-radio-button v-model="disabledEndDate" :native-value="true"
                                                @input="agreement.end_date = null" type="is-success">
                                                <b-icon icon="check-circle"></b-icon>
                                                <span>Si</span>
                                            </b-radio-button>
                                        </b-field>
                                    </div>

                                </div>

                                <div class="columns">
                                    <div class="column">
                                        <ValidationProvider name="Descripción del Convenio"
                                            rules="min:3|max:1024|required" v-slot="{ errors, failedRules }">
                                            <b-field label="Descripción del Convenio *"
                                                :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                                :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                                <b-input v-model="agreement.description"
                                                    placeholder="Ingrese el Descripción del Convenio" type="textarea" />
                                            </b-field>
                                        </ValidationProvider>
                                    </div>
                                </div>

                                <div class="columns">

                                    <div class="column">
                                        <ValidationProvider name="Link del Convenio" rules="min:3|max:255"
                                            v-slot="{ errors, failedRules }">
                                            <b-field label="Link del Convenio "
                                                :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                                :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                                <b-input v-model="agreement.link"
                                                    placeholder="Ej. https://www.drive.com" type="link" />
                                            </b-field>
                                        </ValidationProvider>
                                    </div>

                                    <div class="column is-narrow">
                                        <b-field label="Internacionalidad">
                                            <b-radio-button v-model="agreement.is_international" :native-value="false"
                                                type="is-success">
                                                <span>Nacional</span>
                                            </b-radio-button>
                                            <b-radio-button v-model="agreement.is_international" :native-value="true"
                                                type="is-success">
                                                <span>Internacional</span>
                                            </b-radio-button>
                                        </b-field>
                                    </div>

                                </div>

                            </form>
                        </ValidationObserver>

                    </b-step-item>

                    <b-step-item step="2" label="Lista de Entidades Externas Relacionadas" :clickable="true">

                        <ValidationObserver ref="observer4">

                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider name="Nombre Entidad Externa" rules="required"
                                        v-slot="{ errors, failedRules }">
                                        <b-field label="Nombre Entidad Externa *"
                                            :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                            :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                            <b-autocomplete v-model="partner_name"
                                                placeholder="Ingrese nombre de la Entidad" :keep-first="false"
                                                :open-on-focus="false" :data="partner_entity_search" field="show_name"
                                                @typing="searchPartnerEntities" @select="selectPartner"
                                                :clearable="false" />
                                        </b-field>
                                    </ValidationProvider>
                                </div>

                                <div class="column is-narrow">
                                    <br>
                                    <b-field label=" ">
                                        <b-tooltip
                                            label="Si la entidad que desea agregar no existe, la puede agregar presionando el botón +"
                                            multilined position="is-left">
                                            <b-button class="is-primary is-outlined" icon-left="plus"
                                                @click="isEntityModalActive = true" />
                                        </b-tooltip>
                                    </b-field>
                                </div>
                            </div>

                            <div v-show="showAdd" class="columns is-mobile is-centered">
                                <div class="column is-narrow">
                                    <b-button type="is-success" icon-left="arrow-down" label="Agregar Entidad Externa"
                                        @click="addPartner()" />
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <b-table :data="partner_entities" :paginated=true per-page=5 striped
                                        hoverable :pagination-simple=false pagination-position="bottom"
                                        default-sort-direction="asc" sort-icon="arrow-up" sort-icon-size="is-small"
                                        aria-next-label="Siguiente" aria-previous-label="Anterior"
                                        aria-page-label="Página" aria-current-label="Página actual">
                                        <b-table-column field='alt_full_name' label='Entidad' v-slot="props">
                                            {{props.row.alt_full_name == null ?
                                            props.row.alt_name:props.row.alt_full_name}}
                                        </b-table-column>
                                        <b-table-column field='entity_type_name' label='Tipo de entidad' v-slot="props">
                                            <div>{{props.row.entity_type_name != null ?
                                                props.row.entity_type_name:props.row.entity_type_name}}
                                            </div>
                                        </b-table-column>
                                        <b-table-column field='email' label='Email' v-slot="props">
                                            <div>{{props.row.email}}</div>
                                        </b-table-column>
                                        <b-table-column field='phone' label='Teléfono' v-slot="props">
                                            <div>{{props.row.phone}}</div>
                                        </b-table-column>
                                        <b-table-column label="Acción" v-slot="props" centered>
                                            <button class="button is-danger"
                                                @click="confirmCustomDeletePartner(props.row)">
                                                <b-icon icon="times"></b-icon>
                                                <span>Eliminar</span>
                                            </button>
                                        </b-table-column>
                                        <template slot="empty" slot-scope="props">
                                            <div class="columns is-centered">
                                                <div class="column is-6 is-centered has-text-centered">
                                                    <b-message has-icon type="is-danger">
                                                        <h1 v-if="partner_entities.length == 0">No hay datos.
                                                            Para
                                                            guardar el convenio debe agregar al menos una entidad.</h1>
                                                    </b-message>
                                                </div>
                                            </div>
                                        </template>
                                    </b-table>
                                </div>
                            </div>

                        </ValidationObserver>

                    </b-step-item>

                </b-steps>


            </section>
            <footer class="modal-card-foot">
                <div class="columns is-mobile">
                    <div class="column">
                        <b-button label="Cerrar" @click="$emit('close')" />
                    </div>
                    <div class="column">
                        <p class="control">
                            <b-button :label="edit ? 'Editar Convenio' : 'Guardar Convenio'" type="is-primary"
                                :icon-left="edit ? 'edit' : 'save'" @click="check()" />
                        </p>
                    </div>
                </div>
            </footer>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </section>
</template>

<script>
import axios from 'axios'
import ModalNewExternalPerson from './modal_external_people_create.vue'
import ModalNewParterEntity from '../moduloVIME/agreements/new/modal_partner_entity.vue'
import moment from 'moment'
export default {

    name: 'new_edit_academic_agreement',

    components: {
        ModalNewExternalPerson, ModalNewParterEntity,
    },

    props: [
        'personId', 'cargoPrincipal', 'agreementId', 'edit', 'principalUnitId'
    ],

    data() {
        return {
            isLoading: false,
            activeStep: 0,
            isPersonModalActive: false,
            isEntityModalActive: false,
            showAdd: false,

            //Datos para manejos
            disabledEndDate: false,
            partner_name: null,
            partner_entity: {},

            // Atributos del Convenio
            agreement: {
                name: null,
                applicant: null,
                description: null,
                agreement_state: "CONVENIO FIRMADO",
                agreement_stage: "CONVENIO APROBADO",
                current_manager: null,
                service_gloss: null,
                link: null,
                creation_date: null,
                start_date: null,
                end_date: null,
                amount: null,
                agreement_type_id: null,
                resolution_type_id: null,
                resolution_number: null,
                is_international: false,
                unit_id: null,
            },
            agreement_general_type_id: null,
            agreement_specific_type_id: null,
            partner_entities: [],

            // Datos para el formulario
            agreement_units: [],
            agreement_general_types: [],
            agreement_specific_types: [],
            resolution_types: [],
            agreement_stages: [],
            agreement_processes: [],
            person_search:[],
            partner_entity_search: [],
        }
    },

    async created() {
        await this.getInfo()
    },

    methods: {

        async getInfo() {
            this.isLoading = true
            await this.getDepartaments()
            await this.getAgreementsGeneralTypes()
            await this.getAgreementSpecificTypes()
            await this.getResolutionTypes()
            if (this.edit){
                await this.getAgreement()
            }
            this.isLoading = false
        },

        async getAgreement() {
            await axios.get('/academic_agreements.json', { params: { id: this.agreementId } })
                .then(res => {
                    this.agreement = res.data

                    if (this.agreement.start_date != null) {
                        this.agreement.start_date = moment(this.agreement.start_date, ["YYYY-MM-DD","DD-MM-YYYY"])
                        this.agreement.start_date = new Date(this.agreement.start_date)
                    }
                    
                    if (this.agreement.end_date != null) {
                        this.agreement.end_date = moment(this.agreement.end_date, ["YYYY-MM-DD","DD-MM-YYYY"])
                        this.agreement.end_date = new Date(this.agreement.end_date)
                    }

                    if (this.agreement.creation_date != null) {
                        this.agreement.creation_date = moment(this.agreement.creation_date, ["YYYY-MM-DD","DD-MM-YYYY"])
                        this.agreement.creation_date = new Date(this.agreement.creation_date)
                    }
                    
                    this.disabledEndDate = this.agreement.disabled_date
                    
                    this.partner_entities = this.agreement.partner_entities

                    this.agreement_general_type_id = this.agreement.agreement_type.agreement_general_type_id
                    this.agreement_specific_type_id = this.agreement.agreement_type.agreement_specifict_type_id
                    
                })
                .catch(error => {
                    var showError = ' Favor intentarlo más tarde.'
                    if (error.response) {
                        showError = ' Favor revisar errores.<br>Errores:<br>'
                        for (const propiedad in error.response.data) {
                            if (error.response.data.hasOwnProperty(propiedad)) {
                                showError += error.response.data[propiedad].join(', ') + '<br>'
                            }
                        }
                    }

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al traer el Convenio.' + showError,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.$emit('close')
                    this.isLoading = false
                })
        },

        async getDepartaments() {
            await axios.get('/unidades_convenio')
                .then(response => {
                    this.agreement_units = response.data['departamentos']
                    this.agreement_units.push(
                        {
                            id: 0,
                            name: "UNIVERSIDAD DE SANTIAGO DE CHILE"
                        }
                    )
                    if(!this.edit) this.agreement.unit_id = this.principalUnitId
                })
                .catch(error => {
                    let showError = error.response ? ' Favor revisar errores.<br>Errores:<br>' + error.response.data : ' Favor intentarlo más tarde.'

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al traer las Unidades.' + showError,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.$emit('close')
                    this.isLoading = false
                })
        },

        async getAgreementsGeneralTypes() {
            await axios.get('/index_active_agreement_general_types.json')
                .then(response => {
                    this.agreement_general_types = response.data
                })
                .catch(error => {
                    let showError = error.response ? ' Favor revisar errores.<br>Errores:<br>' + error.response.data : ' Favor intentarlo más tarde.'

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al traer los Tipos de Convenio General.' + showError,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.$emit('close')
                    this.isLoading = false
                })
        },

        async getAgreementSpecificTypes() {
            await axios.get('/index_active_agreement_specifict_types.json')
                .then(response => {
                    this.agreement_specific_types = response.data
                })
                .catch(error => {
                    let showError = error.response ? ' Favor revisar errores.<br>Errores:<br>' + error.response.data : ' Favor intentarlo más tarde.'

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al traer los Tipos de Convenio Específico.' + showError,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.$emit('close')
                    this.isLoading = false
                })
        },

        async getResolutionTypes() {
            await axios.get('/resolution_types.json')
                .then(response => {
                    this.resolution_types = response.data
                })
                .catch(error => {
                    let showError = error.response ? ' Favor revisar errores.<br>Errores:<br>' + error.response.data : ' Favor intentarlo más tarde.'

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al traer los Tipos de Resolución.' + showError,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.$emit('close')
                    this.isLoading = false
                })
        },

        async searchPerson(name) {
            if (name.length < 3) return
            this.isLoading = true
            await axios.get("/people/agreement/participants.json", {
                    params: { name: name }
                })
                .then(res => {
                    this.person_search = res.data
                    this.isLoading = false
                })
                .catch(error => {
                    let showError = error.response ? ' Favor revisar errores.<br>Errores:<br>' + error.response.data : ' Favor intentarlo más tarde.'

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al buscar al Participante.' + showError,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.$emit('close')
                    this.isLoading = false
                })
        },

        savePersonSelectedInformation(person){
            if (person != null) {
                this.participant.person_id = person.id
                this.participant.person_name = person.nombre_completo
                this.participant.person_position = person.position ? person.position : ''
                this.participant.person_mail = person.corporate_mail
                this.function_obj = person.function ? person.function : []
            }
        },

        async onClickChildPerson(value){
            this.participant.person_name = value.person_name
        },

        async addAcademic() {
            const valid = await this.$refs.observer3.validate()
            if (!valid) {
                this.$buefy.dialog.alert(
                    {
                        title: 'Error',
                        message: 'Algunos campos presentan errores al agregar Participante',
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    }
                )
                return
            } else if (this.participants.find(participant => participant.person_id === this.participant.person_id)) {
                this.$buefy.dialog.alert(
                    {
                        title: 'Error',
                        message: 'El Participante ya fue agregado.',
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    }
                )
                return
            } else {
                this.participants.push(this.participant)
                this.person_name = null
                this.participant = {
                    person_id: null,
                    person_name: null,
                    person_position: [],
                    person_work_plant: null,
                    person_function: null,
                    person_mail: null,
                    participant_type_id: null,
                    participant_type_name: null,
                }
                this.$refs.observer3.reset()
            }
        },

        confirmCustomDeleteAcademic(deletePerson) {
            this.$buefy.dialog.confirm({
                message: '¿Quieres eliminar al participante: <b>'+deletePerson.person_name+'</b>?',
                confirmText: 'Eliminar',
                cancelText: 'Cancelar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.participants = this.participants.filter(element => element.person_id !== deletePerson.person_id)
                }
            })
        },

        async searchPartnerEntities(name) {
            if (name.length < 3) {
                this.partner_entity_search = []
                return
            }
            this.isLoading = true
            await axios.get("/admin/partner_entities/agreement-search.json", { params: { name: name } })
                .then(res => {
                    this.partner_entity_search = res.data
                    this.isLoading = false
                })
                .catch(error => {
                    let showError = error.response ? ' Favor revisar errores.<br>Errores:<br>' + error.response.data : ' Favor intentarlo más tarde.'

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al buscar la Entidad Externa.' + showError,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.$emit('close')
                    this.isLoading = false
                })
        },

        selectPartner(option) {
            if (option != null) {
                this.partner_entity = option
                this.showAdd = true
            }
        },

        async addPartner() {
            this.showAdd = false
            const valid = await this.$refs.observer4.validate()
            if (!valid) {
                this.$buefy.dialog.alert(
                    {
                        title: 'Error',
                        message: 'Porfavor ingresar una Entidad Externa',
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    }
                )
                return
            } else if (this.partner_entities.find(partner => partner.id === this.partner_entity.id)) {
                this.$buefy.dialog.alert(
                    {
                        title: 'Error',
                        message: 'La Entidad ya fue agregada.',
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    }
                )
                return
            } else {
                this.partner_entities.push(this.partner_entity)
                this.partner_name = null
                this.partner_entity = {}
                this.$refs.observer4.reset()
            }
        },

        confirmCustomDeletePartner(deleteEntity) {
            this.$buefy.dialog.confirm({
                message: '¿Quieres eliminar la Entidad Externa: <b>'+deleteEntity.alt_name+'</b>?',
                confirmText: 'Eliminar',
                cancelText: 'Cancelar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.partner_entities = this.partner_entities.filter(element => element.id !== deleteEntity.id)
                }
            })
        },

        async check() {
            const valid1 = await this.$refs.observer1.validate()
            const valid4 = this.partner_entities.length > 0
            if (!valid1 || !valid4) {
                var msg = 'Algunos campos presentan errores en:'
                if (!valid1) msg = msg + '<br><b>Información General<b>'
                if (!valid4) msg = msg + '<br><b>Entidades<b>'
                this.$buefy.dialog.alert(
                    {
                        title: 'Error',
                        message: msg,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    }
                )
            }
            else if (this.edit) {
                this.$buefy.dialog.confirm({
                    title: "Editar Convenio",
                    message: `¿Esta seguro de Editar el Convenio?`,
                    cancelText: "Cancelar",
                    confirmText: "Editar Actividad",
                    type: "is-info",
                    onConfirm: () => this.editAgreement(),
                })
            }
            else {
                this.$buefy.dialog.confirm({
                    title: "Nuevo Convenio",
                    message: `¿Esta seguro de Guardar el Convenio?`,
                    cancelText: "Cancelar",
                    confirmText: "Guardar Convenio",
                    type: "is-info",
                    onConfirm: () => this.saveAgreement(),
                })
            }
        },

        async saveAgreement() {
            this.isLoading = true
            await axios.post('/agreement_types_search', { agreement_general_type_id: this.agreement_general_type_id, agreement_specifict_type_id: this.agreement_specific_type_id})
                .then(response => {
                    this.agreement.agreement_type_id  = response.data.agreement_type.id
                })
                .catch(error => {
                    var showError = ' Favor intentarlo más tarde.'
                    if (error.response) {
                        showError = ' Favor revisar errores.<br>Errores:<br>'
                        for (const propiedad in error.response.data) {
                            if (error.response.data.hasOwnProperty(propiedad)) {
                                showError += error.response.data[propiedad].join(', ') + '<br>'
                            }
                        }
                    }

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un al buscar el Tipo de Convenio .' + showError,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.$emit('close')
                    this.isLoading = false
                    return
                })
            await axios.post('/academic_agreements.json', {
                    agreement: this.agreement,
                    partner_entities: this.partner_entities,
                    person_id: this.personId,
                })
                .then(response => {
                    this.$buefy.dialog.alert({
                        title: 'Logrado',
                        message: 'Se ha agregado correctamente el Convenio.',
                        type: 'is-success',
                        hasIcon: true,
                        icon: 'check-circle',
                        iconPack: 'fas',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.$emit('refresh')
                    this.$emit('close')
                    this.isLoading = false
                })
                .catch(error => {
                    var showError = ' Favor intentarlo más tarde.'
                    if (error.response) {
                        showError = ' Favor revisar errores.<br>Errores:<br>'
                        for (const propiedad in error.response.data) {
                            if (error.response.data.hasOwnProperty(propiedad)) {
                                showError += error.response.data[propiedad].join(', ') + '<br>'
                            }
                        }
                    }

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al guardar el Convenio.' + showError,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.isLoading = false
                })
        },

        async editAgreement() {
            this.isLoading = true
            await axios.post('/agreement_types_search', { agreement_general_type_id: this.agreement_general_type_id, agreement_specifict_type_id: this.agreement_specific_type_id})
                .then(response => {
                    this.agreement.agreement_type_id  = response.data.agreement_type.id
                })
                .catch(error => {
                    var showError = ' Favor intentarlo más tarde.'
                    if (error.response) {
                        showError = ' Favor revisar errores.<br>Errores:<br>'
                        for (const propiedad in error.response.data) {
                            if (error.response.data.hasOwnProperty(propiedad)) {
                                showError += error.response.data[propiedad].join(', ') + '<br>'
                            }
                        }
                    }

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un al buscar el Tipo de Convenio .' + showError,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.$emit('close')
                    this.isLoading = false
                    return
                })
            await axios.put('/academic_agreements.json', {
                    id: this.agreementId,
                    agreement: this.agreement,
                    partner_entities: this.partner_entities,
                    person_id: this.personId,
                })
                .then(response => {
                    this.$buefy.dialog.alert({
                        title: 'Logrado',
                        message: 'Se ha editado correctamente el Convenio.',
                        type: 'is-success',
                        hasIcon: true,
                        icon: 'check-circle',
                        iconPack: 'fas',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.$emit('refresh')
                    this.$emit('close')
                    this.isLoading = false
                })
                .catch(error => {
                    var showError = ' Favor intentarlo más tarde.'
                    if (error.response) {
                        showError = ' Favor revisar errores.<br>Errores:<br>'
                        for (const propiedad in error.response.data) {
                            if (error.response.data.hasOwnProperty(propiedad)) {
                                showError += error.response.data[propiedad].join(', ') + '<br>'
                            }
                        }
                    }

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al editar el Convenio.' + showError,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.isLoading = false
                })
        },

    }

}
</script>