<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent @keydown.enter="$event.preventDefault()">
        <!-- Box de Formulario Evento -->
        <div class="modal-card" style="width: auto" id="modal-event">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body">
            <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
              <b-message type="is-danger" has-icon>
                <div v-for="(item,index) in errores_validacion">
                  {{ item[0] }}
                </div>
              </b-message>
            </div>
            <div class="columns">
              <div class="column"></div>
              <div class="column is-6">
                <div class="has-text-centered">
                  <b-field>
                    <b-input type="text" expanded minlength="3" icon="search" v-model='busqueda' @keyup.enter.native.prevent="search(busqueda)" placeholder="Búsqueda con al menos 3 letras..."></b-input>
                    <p class="control">
                      <b-button @click.prevent="search(busqueda)" class="is-primary" id="boton-buscar">Buscar</b-button>
                    </p>
                  </b-field>
                </div>
              </div>
              <div class="column"></div>
            </div>
            <div class="columns">
              <div class="column">
                  <h3 class="is-size-4 has-text-weight-bold">Tabla Búsqueda
                    <b-tooltip label="Al seleccionar un participante desde la tabla de búsqueda, se agregará automáticamente a la Tabla Resumen de Participantes."
                               position="is-right"
                               size="is-medium"
                               multilined>
                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          size="is-small"
                          type="is-info">
                      </b-icon>
                    </b-tooltip>
                  </h3>
                </div>
                  <div class="column is-narrow">
                    <b-tooltip label="Si la persona no se encuentra o no existe y ademas es externa, la puede agregar presionando el botón +"
                               multilined
                               position="is-left">
                      <b-button class="is-primary is-outlined" @click="addExternalPerson">
                        <b-icon icon="plus"></b-icon>
                      </b-button>
                    </b-tooltip>
                  </div>
              </div>
            <div class="columns">
                <div class="column">
                  <b-table
                      id="tabla-participantes"
                      :data="participants"
                      :paginated=true
                      per-page=5
                      striped
                      hoverable
                      :current-page="currentPage"
                      :card-layout="cardLayout"
                      :pagination-simple=false
                      pagination-position="bottom"
                      default-sort-direction="desc"
                      sort-icon="arrow-up"
                      sort-icon-size="is-small"
                      aria-next-label="Siguiente"
                      aria-previous-label="Anterior"
                      aria-page-label="Página"
                      aria-current-label="Página actual"
                      :checked-rows.sync="checkedRows"
                      checkable
                      :checkbox-position="checkboxPosition"
                      >
                    <b-table-column
                        field= 'name'
                        label= 'Nombre'
                        width="20em"
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{  props.row.name }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field= 'corporate_mail'
                        label= 'Correo'
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.corporate_mail ? props.row.corporate_mail : props.row.personal_mail }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field= 'alias'
                        label= 'Alias'
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{  props.row.alias}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field= 'unit'
                        label= 'Unidad'
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{  props.row.unit }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field= 'active'
                        label= '¿Está activo?'
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{  props.row.active }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field= 'external'
                        label= '¿Es externo?'
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{  props.row.external }}
                      </template>
                    </b-table-column>
                  </b-table>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                  <h3 class="is-size-4 has-text-weight-bold">Tabla Resumen Participantes
                  </h3>
                  <b-table
                      :data="checkedRows"
                      :paginated=true
                      per-page=5
                      striped
                      hoverable
                      :current-page="currentPage"
                      :card-layout="cardLayout"
                      :pagination-simple=false
                      pagination-position="bottom"
                      default-sort-direction="desc"
                      sort-icon="arrow-up"
                      sort-icon-size="is-small"
                      aria-next-label="Siguiente"
                      aria-previous-label="Anterior"
                      aria-page-label="Página"
                      aria-current-label="Página actual">
                    <b-table-column
                        field= 'name'
                        label= 'Nombres'
                        width="20em"
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{  props.row.name }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field= 'corporate_mail'
                        label= 'Correo'
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.corporate_mail ? props.row.corporate_mail : props.row.personal_mail }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        label="Acción"
                        width="10"
                        v-slot="props">
                      <b-icon
                          pack="fas"
                          style="color: #e50000;"
                          icon="trash"
                          size="is-medium"
                          @click.native="deleteParticipantButton(props.row)"/>
                    </b-table-column>
                  </b-table>
                </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions" style="justify-content: flex-end;">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit" @click="saveParticipant">Guardar </button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-modal
        v-model="isCreateModal"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-external-people-create
            query-type="create"
            modal-header="Agregar datos personales"
            @clicked="onClickChild"
            @close="props.close"></modal-external-people-create>
      </template>
    </b-modal>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import ModalExternalPeopleCreate from "../person/modal_external_people_create"
const qs = require('qs')
export default {
  name: "modal_participant_patent",
  components: {ModalExternalPeopleCreate},
  props:[
    'queryType', 'personId', 'id', 'modalHeader', 'patentId', 'participantes'
  ],
  data(){
    return {
      patent:{},
      busqueda: "",
      isCreateModal: false,
      canCancel: ['escape'],
      currentPage:1,
      cardLayout:false,
      participants:[],
      numeroParticipantes: 0,
      errores_validacion:[],
      selectedParticipants: [],
      isLoading:false,
      checkedRows: [],
      checkboxPosition: "left"
    }
  },
  created() {
    this.isLoading=true;
    this.checkedRows = this.participantes
    this.isLoading=false;
  },
  methods: {
    deleteParticipant(participant){
      this.checkedRows = this.checkedRows.filter(person => person.person_id != participant.person_id);
    },
    search(query){
      this.isLoading = true
      if(query.length<2){
        this.$buefy.dialog.alert({
          message: 'Por favor ingrese a lo menos 3 letras.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = false;
        return false;
      }
      var checkedRows = []
      for (var i = 0, l = this.checkedRows.length; i < l; i++) {
        if(this.checkedRows[i] !== undefined){
          checkedRows.push({person_id: this.checkedRows[i].person_id})
        }
        else{
          checkedRows.push({person_id: -1})
        }
      }
      checkedRows.push({person_id: -1})
      return axios
          .get("/people/"+query+"/"+JSON.stringify({ checkedRows: checkedRows })+"/search_people.json"
          ).then(res => {
            this.participants = res.data.participants
            this.numeroParticipantes = this.participants.length
            this.participants.forEach(function(participante){
              if (participante.active == true){
                participante.active = "SI"
              }
              else{
                participante.active = "NO"
              }
              if (participante.external == true){
                participante.external = "SI"
              }
              else{
                participante.external = "NO"
              }
            })
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })
      this.isLoading=false;
    },
    updateCheckedRows(participants){
      var rows = participants.filter(item => this.checkedRows.includes(item))
      return rows
    },
    saveParticipant(){
      console.log("guardando uwu")
      this.selectedParticipants = this.checkedRows
      this.$emit('clicked', this.selectedParticipants)
      this.$emit('close')
    },
    deleteData(participants){
    },
    addExternalPerson(){
      this.isLoading= true
      this.isCreateModal = true
      this.isLoading = false
    },
    onClickChild(value){
      this.search(value)
    },
    deleteParticipantButton(participants){
      this.$buefy.dialog.confirm({
        title: 'Eliminar Participante',
        message: 'Precaución, al momento de guardar esta actividad académica esta acción eliminará la asociación con la/el participante, lo que implica que ya no podrá ver esta información ni será contada en su actividad académica ¿Desea continuar?',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteParticipant(participants)
      })
    },
  }
}
</script>

