<template>
  <section>
    <div class="container">
      <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0 || untilBeforeSince">
        <b-message type="is-danger" has-icon>
          <div v-for="(item,index) in errores_validacion" :key="index">
            {{ index }} - {{ item }}
          </div>
          {{errormessge}}
        </b-message>
      </div>
      <div class="box">
        <div class="columns">
          <div class="column">
            <b-field label="Contiene">
              <b-input maxlength="200" v-model="search.contains" type="text"></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Unidad">
              <multiselect :options="units"
                           @input="loadUnitPeople"
                           v-model="search.unit"
                           placeholder="Seleccione Unidad"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="Presione para deseleccionar"
                           label="name"
                           track-by="name"
                           :allow-empty="true">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                  No se encontraron elementos.
                </span>
              </multiselect>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Persona de la unidad">
              <multiselect :options="people"
                           v-model="search.person"
                           placeholder="Seleccione persona de la unidad"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="Presione para deseleccionar"
                           label="nombre"
                           track-by="nombre"
                           :allow-empty="true">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                  No se encontraron elementos.
                </span>
              </multiselect>
            </b-field>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <b-field label="Jornada">
              <multiselect :options="journeys"
                           v-model="search.journey"
                           placeholder="Seleccione jornada"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="Presione para deseleccionar"
                           label="name"
                           track-by="name"
                           :allow-empty="true">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                  No se encontraron elementos.
                </span>
              </multiselect>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Género">
              <multiselect :options="genders"
                           v-model="search.gender"
                           placeholder="Seleccione género"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="Presione para deseleccionar"
                           label="name"
                           track-by="name"
                           :allow-empty="true">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                  No se encontraron elementos.
                </span>
              </multiselect>
            </b-field>
          </div>
        </div>

        <div class="columns is-centered">
          <div class="column">
            <b-field label="Actividad académica">
              <multiselect :options="academicActivities"
                           v-model="search.academicActivity"
                           placeholder="Seleccione actividad académica"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="Presione para deseleccionar"
                           :allow-empty="true">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                  No se encontraron elementos.
                </span>
              </multiselect>
            </b-field>
          </div>
          <div class="column" v-if="types.length>0">
            <b-field label="Subcategoría">
              <multiselect :options="types"
                           v-model="search.type"
                           placeholder="Seleccione subcategoría de actividad académica"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="Presione para deseleccionar"
                           label="name"
                           track-by="name"
                           :allow-empty="true">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                  No se encontraron elementos.
                </span>
              </multiselect>
            </b-field>
          </div>
          <div class="column" v-if="search.academicActivity=='PROYECTOS'">
            <b-field label="Financiamiento">
              <multiselect :options="financings"
                           v-model="search.financing"
                           placeholder="Seleccione financiamiento"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="Presione para deseleccionar"
                           label="name"
                           track-by="name"
                           :allow-empty="true">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                  No se encontraron elementos.
                </span>
              </multiselect>
            </b-field>
          </div>
          <div id="magazine-column" class="column" v-if="search.academicActivity=='PUBLICACIONES'">
            <b-field label="Indexacion">
              <multiselect :options="indexs"
                           v-model="search.index"
                           placeholder="Seleccione indexación"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="Presione para deseleccionar"
                           label="name"
                           track-by="name"
                           :allow-empty="true">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                  No se encontraron elementos.
                </span>
              </multiselect>
            </b-field>
          </div>
          <div class="column" v-if="search.academicActivity == 'VINCULACIÓN CON EL MEDIO'">
            <b-field label="Categoria general">
              <multiselect :options="vimeActivityTypes"
                           v-model="search.vimeActivityType"
                           placeholder="Seleccione actividades vime"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="Presione para deseleccionar"
                           label="name"
                           track-by="name"
                           :allow-empty="true">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                  No se encontraron elementos.
                </span>
              </multiselect>
            </b-field>
          </div>
          <div class="column" v-if="search.academicActivity != 'VINCULACIÓN CON EL MEDIO'">
            <b-field label="Tipo participación">
              <multiselect :options="participantTypes"
                           v-model="search.participantType"
                           placeholder="Seleccione tipo de participación"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="Presione para deseleccionar"
                           label="name"
                           track-by="name"
                           :allow-empty="true">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                  No se encontraron elementos.
                </span>
              </multiselect>
            </b-field>
          </div>
        </div>

<!--        <div class="columns is-centered">
          <div class="column">
            <b-field label="Categoria VIME">
              <multiselect :options="vime"
                           v-model="search.vime"
                           placeholder="Seleccione categoria VIME"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="Presione para deseleccionar"
                           :allow-empty="true">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                  No se encontraron elementos.
                </span>
              </multiselect>
            </b-field>
          </div>

          <div class="column" v-if="search.vime == 'CONVENIOS'">
            <b-field label="Categoria general">
              <multiselect :options="agreementGeneralTypes"
                           v-model="search.agreementGeneralType"
                           placeholder="Seleccione categoria general del convenio"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="Presione para deseleccionar"
                           label="name"
                           track-by="name"
                           :allow-empty="true">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                  No se encontraron elementos.
                </span>
              </multiselect>
            </b-field>
          </div>

          <div class="column" v-if="search.vime == 'CONVENIOS'">
          <b-field label="Categoria especifica">
            <multiselect :options="agreementSpecificTypes"
                         v-model="search.agreementSpecificType"
                         placeholder="Seleccione categoria especifica del convenio"
                         selectLabel="Presione para seleccionar"
                         selectedLabel="Seleccionado"
                         deselectLabel="Presione para deseleccionar"
                         label="name"
                         track-by="name"
                         :allow-empty="true">
              <template v-slot:noOptions>
                No existen datos
              </template>
              <span slot="noResult">
                  No se encontraron elementos.
                </span>
            </multiselect>
          </b-field>
        </div>

          <div class="column" v-if="search.vime == 'ACTIVIDADES'">
        <b-field label="Categoria general">
          <multiselect :options="vimeActivityTypes"
                       v-model="search.vimeActivityType"
                       placeholder="Seleccione actividades vime"
                       selectLabel="Presione para seleccionar"
                       selectedLabel="Seleccionado"
                       deselectLabel="Presione para deseleccionar"
                       label="name"
                       track-by="name"
                       :allow-empty="true">
            <template v-slot:noOptions>
              No existen datos
            </template>
            <span slot="noResult">
                  No se encontraron elementos.
                </span>
          </multiselect>
        </b-field>
      </div>
        </div>-->

        <div class="columns is-centered">
          <div class="column">
            <div class="field">
              <b-field label="Año desde:">
                <ValidationProvider ref="since" rules="integer|positive" v-slot="{errors}">
                  <b-input type="number" v-model="search.sinceYear"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <b-field label="Año hasta:">
                <ValidationProvider rules="integer|positive" v-slot="{errors}">
                  <b-input type="number" v-model="search.untilYear"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>
        </div>

        <div class="has-text-right">
          <b-button @click="buscar" icon-left="search" type="is-primary">Buscar</b-button>
        </div>

        <section v-if="showSearch">
          <div class="columns has-text-centered">
            <div class="column">
              <h1 class="title">
                Resultados de la búsqueda
              </h1>
            </div>
          </div>
          <b-tabs position="is-centered" @input="changeTab">
            <b-tab-item v-for="tab in tabs"
                        :value="tab.content"
                        v-bind:key="tab.content"
                        :label="tab.tab"
                        :icon="tab.icon">
            </b-tab-item>

          </b-tabs>
          <!-- COMPONENTE AQUI -->
          <keep-alive>
            <component v-bind:is="currentTabComponent"
                       v-bind:personas="peopleFounded"
                       v-bind:proyectos="projectsFounded"
                       v-bind:patentes="patentsFounded"
                       v-bind:publicaciones="publicationsFounded"
                       v-bind:actividades="activityFounded"
                       v-bind:cargoPrincipal="cargoPrincipal"
                       v-bind:advanceSearch="true">
<!--                       v-bind:convenios="agreementsFounded">-->
            </component>
          </keep-alive>
          <!-- FIN COMPONENTE -->
          <br>
          <div class="has-text-right">
            <b-button @click="exportSearch" icon-left="file-excel" class="is-excel-color">Exportar búsqueda en excel</b-button>
          </div>
        </section>

      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import axios from "axios";
import Patents from "../patents/patents_table.vue";
import People from "../person/people_table.vue";
import Publications from "../publications/publications_table.vue";
import Projects from "../projects/projects_table.vue";
import VimeActivity from "../moduloVIME/vime_activity/index/vime_activity_table"
import Agreements from "../moduloVIME/agreements/index/agreements_table"
import moment from "moment";

export default {
  components:{Patents,People,Publications,Projects, VimeActivity, Agreements},
  data(){
    return{
      errores_validacion:{},
      search:{
        contains:"",
        unit:null,
        person:null,
        participantType:null,
        journey:null,
        gender:null,
        academicActivity:null,
        category:null,
        sinceYear:null,
        untilYear:null,
        type:null,
        financing:null,
        agreementSpecificType:null,
        agreementGeneralType:null,
        vimeActivityType:null,
        vime:null,
        index:null,
      },
      people:[],
      units:[],
      journeys:[],
      genders:[],
      academicActivities:[],
      participantTypes:[],
      categories:[],
      patentTypes:[],
      projectTypes:[],
      agreementGeneralTypes:[],
      agreementSpecificTypes:[],
      vimeActivityTypes:[],
      vime:[],
      types:[],
      publicationTypes:[],
      financings:[],
      indexs:[],
      isLoading:false,
      currentTab: 'People',
      tabs: [
        {tab: 'Personas',content:'People',icon:"users"},
        {tab: 'Publicaciones',content:'Publications',icon:"file-alt"},
        {tab: 'Proyectos',content:'Projects',icon:"folder-open"},
        {tab: 'Propiedad Intelectual',content:'Patents',icon:"lightbulb"},
        {tab: 'Actividades',content:'VimeActivity',icon:"calendar"},
        //{tab: 'Convenios',content: 'Agreements',icon:'handshake'}
      ],
      publicationsFounded:[],
      projectsFounded:[],
      patentsFounded:[],
      peopleFounded:[],
      activityFounded:[],
      agreementsFounded:[],
      showSearch:false,
      untilBeforeSince:false,
      errormessge:'',
    }
  },
  created(){
    this.isLoading = true;
    axios
      .get("/advanced_search.json")
      .then(response =>{
        let data = response.data;
        this.units = data.units;
        this.genders = data.genders;
        this.journeys = data.journeys;
        this.academicActivities = data.academic_activity;
        this.vime = data.vime;
        this.participantTypes = data.participant_types;
        this.projectTypes = data.project_types;
        this.patentTypes = data.patent_types;
        this.publicationTypes = data.research_types;
        this.financings = data.financings;
        this.agreementGeneralTypes = data.agreement_general_types
        this.agreementSpecificTypes =  data.agreement_specific_types
        this.vimeActivityTypes = data.vime_activity_types
        this.indexs = data.indexs;
        this.$forceUpdate();
        this.isLoading=false;
      }).catch(error => {
        this.isLoading=false;
      })

  },
  props:[
      'cargoPrincipal'
  ],
  methods:{
   buscar() {
     const provider = this.$refs["since"];
     if((parseInt(this.search.sinceYear, 10) > parseInt(this.search.untilYear, 10)) && this.search.untilYear != "" && this.search.untilYear != null){
       this.untilBeforeSince = true;
       this.errormessge = "Año hasta no puede ser inferior al Año desde"
       this.showSearch = false;
     }
     else{
       this.errormessge = ''
         this.untilBeforeSince = false;
         this.isLoading = true;
       let data = this.generateData();
       axios
           .get("/specific_search.json", {
             params: data
           })
           .then(response => {
             this.errores_validacion = {};
             this.publicationsFounded = [];
             this.patentsFounded = [];
             this.projectsFounded = [];
             this.peopleFounded = [];
             this.activityFounded = [];
             this.agreementsFounded = [];
             this.publicationsFounded = response.data.publicaciones;
             if(this.publicationsFounded.length!==0){
               this.publicationsFounded = this.publicationsFounded.map(publicacion => {
                 var newPublicacion = publicacion
                 newPublicacion["publication_date"] = this.formatDate(publicacion.publication_date);
                 return newPublicacion;
               });
             }
             this.patentsFounded = response.data.patentes;
             if(this.patentsFounded.length!==0){
               this.patentsFounded = this.patentsFounded.map(patente => {
                 var newPatente = patente
                 newPatente["last_patent_state_date"] = this.formatDate(patente.last_patent_state_date);
                 return newPatente;
               });
             }
             this.projectsFounded = response.data.proyectos;
             if(this.projectsFounded.length!==0){
               this.projectsFounded = this.projectsFounded.map(proyecto => {
                 var newProyecto = proyecto
                 newProyecto["start_date"] = this.formatDate(proyecto.start_date);
                 newProyecto["end_date"] = this.formatDate(proyecto.end_date);
                 return newProyecto;
               });
             }
             this.peopleFounded = response.data.personas;
             this.activityFounded = response.data.actividades_vime
             if(this.activityFounded.length!==0){
               this.activityFounded = this.activityFounded.map(activities => {
                 var newActivities = activities
                 newActivities["start_date"] = moment(newActivities["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
                 newActivities["end_date"] = moment(newActivities["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
                 return newActivities;
               });
             }
             this.agreementsFounded = response.data.convenios
             this.showSearch = true;
             this.isLoading = false;
           }).catch(error => {
         console.log(error);
         this.errores_validacion = error.response.data
         var elmnt = document.getElementById("app");
         elmnt.scrollIntoView();
         this.isLoading = false;
       })
     }
   },
    generateData(){
      let data = {unit_id: null , since_year: null, until_year: null,
        name: null, academic_activity:null,
        person:null, category: null, indexacion: null,
        financiamiento: null, cargo: null,jornada:null,
        gender:null, vime_category:null, specific_type: null,
        general_type: null, activity_type:null,
      };
      if (this.search.contains){
        data.name = this.search.contains;
      }
      if (this.search.unit){
        data.unit_id = this.search.unit.id;
      }
      if (this.search.person){
        data.person = this.search.person.id;
      }
      if (this.search.journey){
        data.jornada = this.search.journey.id;
      }
      if (this.search.gender){
        data.gender = this.search.gender.id;
      }
      if (this.search.participantType!=null){
        data.cargo = this.search.participantType.id;
      }
      if(this.search.academicActivity){
        data.academic_activity = this.search.academicActivity;
      }
      if (this.search.type){
        data.category = this.search.type.id;
      }
      if(this.search.financing){
        data.financiamiento = this.search.financing.id;
      }
      if(this.search.index){
        data.indexacion = this.search.index.id;
      }
      if(this.search.sinceYear){
        data.since_year = this.search.sinceYear;
      }
      if(this.search.untilYear){
        data.until_year = this.search.untilYear;
      }
      if(this.search.vime){
        data.vime_category = this.search.vime
      }
      if(this.search.agreementSpecificType){
        data.specific_type = this.search.agreementSpecificType.name
      }
      if(this.search.agreementGeneralType){
        data.general_type = this.search.agreementGeneralType.name
      }
      if(this.search.vimeActivityType){
        data.activity_type =  this.search.vimeActivityType.name
      }
      return data;
    },
    exportSearch(){
      let vm = this;
      vm.isLoading=true;
      let data = vm.generateData();
      axios
          .get("/advanced_search/export_search.xlsx", {
            params:data,responseType:'blob'
          })
          .then(response =>{
            vm.isLoading=false;
            //window.open(response);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Búsqueda avanzada '+new Date().toLocaleDateString()+'.xlsx');
            document.body.appendChild(link);
            link.click();
          }).catch(error => {
            vm.isLoading=false;
          })
    },
    changeTab: function(value){
      this.currentTab = value
    },
    loadUnitPeople(){
      if(this.search.unit!=null){
        this.isLoading=true;
        axios
            .get("/get_all_people_by_unit.json",{params:{unit_id:this.search.unit.id}})
            .then(response =>{
              let data = response.data;
              this.people = data.personas;
              this.$forceUpdate();
              this.isLoading=false;
              }).catch(error => {
                console.log(error)
                this.isLoading=false;
              })
      }
      else{
        this.search.person=null;
        this.people=[];
      }

    },
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    changeSubcategory(){
      this.search.category = null;
      this.search.index = null;
      this.search.financing = null;
      if(this.search.academicActivity=="PROYECTOS"){
        this.types = this.projectTypes;
      }
      else if(this.search.academicActivity=="PATENTES"){
        this.types = this.patentTypes;
      }
      else if(this.search.academicActivity=="PUBLICACIONES"){
        this.types = this.publicationTypes;
      }
      else{
        this.types = [];
      }
    },
    changeSubcategoryVime(){

    }
  },
  computed:{
    currentTabComponent: function () {
      return this.currentTab
    }

  }
}
</script>

