<template>
  <section>
    <div class="container">
      <h1 class="is-size-2">Personas</h1>

      <br>
      <b-table
          :data="personas"
          :paginated=true
          per-page=10
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="publication.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field= 'full_name'
            label= 'Nombre'
            width="30em"
            searchable
            sortable
            v-slot="props"
        >
          <a @click="goToPerson(props.row.id)"><i class="fas fa-user-circle"></i> {{ props.row.full_name }}</a>

        </b-table-column>
        <b-table-column
            field= 'rut'
            label= 'RUN'
            searchable
            sortable
            v-slot="props"
        >
          {{ props.row.rut }}
        </b-table-column>
        <b-table-column
            field= 'internal_position'
            label= 'Cargo'
            searchable
            sortable
            v-slot="props"
        >
          {{ props.row.internal_position }}
        </b-table-column>
        <b-table-column
            field= 'corporate_mail'
            label= 'Correo Corporativo'
            searchable
            sortable
            v-slot="props"
        >
          {{ props.row.corporate_mail }}
        </b-table-column>
        <b-table-column
            field= 'sex'
            label= 'Sexo'
            searchable
            sortable
            v-slot="props"
        >
          {{ props.row.sex }}
        </b-table-column>

      </b-table>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>

  </section>
</template>
<script>
import axios from "axios";

export default {
  data(){
    return{
      isLoading:false,
      currentPage:1,
      personas:[],

    }
  },
  created(){
    this.isLoading=true;
    return axios.get("/people.json")
        .then(res => {
          this.personas = res.data['personas'];
          this.isLoading=false;
        })
        .catch(e => {
          this.isLoading=false;
          console.log(e);
        });

  },
  props:[

  ],
  methods:{
    newPerson(){
      this.isLoading=true;
      window.location.href="/people/new"
    },
    goToPerson(people_id){
      this.isLoading=true;
      window.location.href="/people/"+people_id
    },

  },
  computed:{

  }
}
</script>

