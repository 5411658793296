/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import App from '../app.vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import '../stylesheets/estilos'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Multiselect from 'vue-multiselect'
import VueExcelEditor from 'vue-excel-editor'
import moment from 'moment'


library.add(fas)
Vue.component('vue-fontawesome', FontAwesomeIcon);
Vue.component('multiselect', Multiselect)
Vue.use(Buefy, { defaultIconComponent: "vue-fontawesome",defaultIconPack: 'fas' })
Vue.use(TurbolinksAdapter)
Vue.use(VueExcelEditor)
Vue.config.devtools = true;
moment.updateLocale('en', {
  week: {
    dow : 1, // Monday is the first day of the week.
  }
});
import setupCSRF from "./setupCSRF";

//COMPONENTES DESARROLLADOS
// I+D
import ButtonComponent from '../components/cosa.vue'
import DashboardTable from '../components/dashboard_table.vue'
import AddProduct from '../components/product/add_product.vue'
import AddProject from '../components/projects/add_project.vue'
import AddPatent from '../components/patents/add_patent'
import AddPublication from '../components/publications/add_publication'
import AddDisclosure from '../components/disclosure/add_disclosure'
import PageSidebar from '../components/page_sidebar'
import ProductDataTable from '../components/products_data_table'
import PublicationDataTable from '../components/publication_data_table'
import PatentDataTable from '../components/patent_data_table'
import ProjectDataTable from '../components/project_data_table'
import DisclosureDataTable from '../components/disclosure_data_table'
import UnitDataTable from '../components/unit_data_table'
import PeopleDataTable from '../components/people_data_table'
import ValidationTable from "../components/validation_table";
import AddAcademicActivityDropdown from "../components/person/add_academic_activity_dropdown";
import ShowProduct from "../components/product/show_product";
import ShowPatent from "../components/patents/show_patent";
import ShowProject from "../components/projects/show_project";
import ShowPublication from "../components/publications/show_publication";
import ShowDisclosure from "../components/disclosure/show_disclosure";
import Faculty from '../components/faculty'
import NavBarSearch from '../components/search/navbar_search'
import ProfileAsignation from "../components/permissions/profile_asignation";
import FormPerson from "../components/person/form_person";
import LifeRecordTabs from "../components/life_record/tabs"
import ContactInfo from "../components/life_record/contact_info"
import SearchLifeRecord from "../components/life_record/search_life_record"
import PeopleIndex from "../components/person/people_index"
import LoadData from "../components/import/load_data";
import ShowPerson from "../components/person/show_person";
import ShowUnit from "../components/units/show_unit";
import ListRenewableAppointments from "../components/person/list_renewable_appointments"
import PersonByAppointmentList from "../components/person/person_by_appointment_list"
import EstablishmentTabs from "../components/life_record/establishment_tabs"
import ProfilesTabs from "../components/person/profiles_tabs"
import ExternalPeopleTabs from "../components/person/external_people_tabs"
import PermissionsList from "../components/permissions/permissions_list"
import ShowParentC from "../components/ejemplos/show_parent_c";
import AdvancedSearch from "../components/search/advanced_search";
import PermissionsManager from "../components/permissions/permissions_manager";
import UserCreation from "../components/permissions/user_creation"
import LifeRecordDashboard from "../components/life_record/LifeRecordDashboard"
import ExtensionHours from "../components/life_record/extension/extension_hours"
import CompareAssets from "../components/life_record/assets/compare_assets"
import NewProjectForm from "../components/projects/NewProjectForm"
import ContinuityHours from "../components/life_record/continuity/continuity_hours"
import IncreaseHours from "../components/life_record/increase_hours/increase_hours"
import LifeRecordImportDashboard from "../components/life_record/LifeRecordImportDashboard"
import ImportEntry from "../components/life_record/entry/people_appointment_import"
import ImportEntryAppointment from "../components/life_record/entry/entry_life_record_appointment_import"
import LifeRecordAudit from "../components/life_record/life_record_audit"
import AuditTabs from "../components/administrate/audit_tabs"
import ExportMhvForId from "../components/life_record/ExportMhvForId"
import NewPublicationForm from "../components/publications/NewPublicationForm";
import NewMagazineModal from "../components/publications/NewMagazineModal";
import PostulatedProjectForm from "../components/projects/PostulatedProjectForm"
import ShowPostulatedProject from "../components/projects/ShowPostulatedProject"
import PostulatedProjectsList from "../components/projects/PostulatedProjectsList"
import ApiOrcidActiveSearch from "../components/person/ApiOrcidActiveSearch"
import nullified_activity from "../components/nullified_activity";
import nullified_project from "../components/projects/nullified_project";
import modal_show_nullified_project from "../components/projects/modal_show_nullified_project";
import FinancingsNullifiedTab from "../components/projects/FinancingsNullifiedTab";
import nulliefied_publication from "../components/publications/nulliefied_publication";
import modal_show_nullified_publication from "../components/publications/modal_show_nullified_publication";
import nulliefied_patent from "../components/patents/nulliefied_patent";
import modal_show_nullified_patent from "../components/patents/modal_show_nullified_patent";
import nullified_show_patent from "../components/patents/nullified_show_patent";
import modal_show_nulliefied_disclosure from "../components/disclosure/modal_show_nulliefied_disclosure";
import nullified_disclosure from "../components/disclosure/nullified_disclosure";
import nullified_show_disclosure from "../components/disclosure/nullified_show_disclosure";
import IdDashboard from "../components/dashboard/IdDashboard";
import additionalInformationTab from "../components/projects/AdditionalInformationTab";
import NewDisclosureForm from "../components/disclosure/NewDisclosureForm";
import ShowGeneralTab from "../components/disclosure/ShowGeneralTab";
import NewPatentForm from "../components/patents/NewPatentForm";
import IDControlDashboard from "../components/dashboard/IDControlDashboard";
import CompareAnidData from "../components/projects/CompareAnidData";
import CompareInapiData from "../components/patents/CompareInapiData";
import PublicationType from "../components/publications/PublicationType";

// MODULO VIME
// Egresados
import PeopleDataTableGraduates from '../components/moduloVIME/people/index/people_data_table_graduates.vue'
import ShowView from '../components/moduloVIME/people/show/show_view.vue'
import NewView from '../components/moduloVIME/people/new/new_view.vue'
// Entidades
import PartnerEntitiesVimeIndex from '../components/moduloVIME/partner_entities_vime/index/partner_entities_vime_index.vue'
import PartnerEntitiesVimeNewView from '../components/moduloVIME/partner_entities_vime/new/partner_entities_vime_new_view.vue'
import PartnerEntitiesVimeShowView from '../components/moduloVIME/partner_entities_vime/show/partner_entities_vime_show_view.vue'
import PartnerEntitiesPerson from "../components/moduloVIME/partner_entities_vime/show/PartnerEntitiesPerson";
// Convenios
import AgreementsIndexView from '../components/moduloVIME/agreements/index/agreements_index_view.vue'
import AgreementsNewView from '../components/moduloVIME/agreements/new/agreements_new_view.vue'
import AgreementsShowView from '../components/moduloVIME/agreements/show/agreement_show_view.vue'
import AgreementsHistoryNewView from '../components/moduloVIME/agreements/new/agreements_history_new_view.vue'
import AgreementsActive from "../components/moduloVIME/agreements/index/agreements-active";
// Trayectoria Laboral
import WorkHistoryNewView from '../components/moduloVIME/work_history/new/work_history_new_view.vue'
// Actividades VIME
import VimeActivityIndexView from '../components/moduloVIME/vime_activity/index/vime_activity_index_view.vue'
import VimeActivityNewView from '../components/moduloVIME/vime_activity/new/vime_activity_new_view.vue'
import VimeActivityShowView from '../components/moduloVIME/vime_activity/show/vime_activity_show_view.vue'
import VimeActivityAssistanceNewView from '../components/moduloVIME/vime_activity_assistance/new/vime_activity_assistance_new_view.vue'
import VimeActivityNewEntitiesRelatedView from '../components/moduloVIME/vime_activity_assistance/new_entities/vime_activity_new_entities_related_view.vue'
import VimeActivityCalendarView from '../components/moduloVIME/vime_activity/calendar/vime_activity_calendar_view.vue'
// Configuración VIME
import ConfigurationIndexView from '../components/moduloVIME/configuration/index/configuration_index_view.vue'
import ConfigurationActivityEdit from '../components/moduloVIME/configuration/edit/configuration_activity_edit.vue'
import ConfigurationSocialNetworkEdit from '../components/moduloVIME/configuration/edit/configuration_social_network_edit.vue'
import ConfigurationAgreements from '../components/moduloVIME/configuration/edit/configuration_agreements.vue'
import { ValidationProvider,ValidationObserver, extend } from 'vee-validate';
import ProfileForm from "../components/person/ProfileForm.vue";
import MyLifeRecord from "../components/person/MyLifeRecord.vue";
import exportAcademicActivity from "../components/exports/exportAcademicActivity.vue";
import IndicatorComposition from "../components/dashboard/IndicatorComposition.vue"
import AgreementNullifiedView from "../components/moduloVIME/agreements/index/agreements_nullified_view";
// Rectificacion
import RectifyAppointmentAcademic from "../components/life_record/rectify/rectify_appointment_academic";
import RectifyAppointmentAdministrative from "../components/life_record/rectify/rectify_appointment_administrative";
import RectifyAppointmentHourclass from "../components/life_record/rectify/rectify_appointment_hours_form";
import RectifyAppointmentFeecontract from "../components/life_record/rectify/rectify_appointment_feecontract";
import NoEffectAppointmentHourclass from "../components/life_record/rectify/noeffect_appointment_hours_form"
//EN ORDEN DE AÑADICION
import DataControlDashboard from "../components/dashboard/DataControlDashboard";
import NullifiedPostulatedProjectsList from "../components/projects/NullifiedPostulatedProjectsList";

//Tutoriales
import Tutorials from "../components/utils/tutorials"
import PersonByFeeContractList from "../components/life_record/person_by_fee_contract_list"
import VideoTutorials from "../components/utils/video_tutorials.vue";
//Documentos
import NewDocumentForm from "../components/documents/new_document_form"
import ShowDocument from "../components/documents/show_document"
import Documents from "../components/documents/documents"
import SearchDocuments from "../components/documents/search_documents"
import NullDocuments from "../components/documents/null_documents"
import DocumentsNavigation from "../components/documents/documents_navigation"
import ImportDocuments from "../components/documents/import_documents"
import NewPerformanceForm from "../components/performances/new_performance_form.vue"
import ControlDocument from "../components/documents/control_document.vue";
//Funciones
import Functions from "../components/functions/functions"
import NullifiedFunctions from "../components/functions/nullified_functions"
import LifeRecordFunctionsDashboard from "../components/life_record/LifeRecordFunctionsDashboard";
//MHV
//REQUESTS
import RequestShow from "../components/requests/request_show"
import MyRequests from "../components/requests/my_requests"
import NewExtensionHours from "../components/life_record/extension/new_extension_hours"
import Requests from "../components/requests/requests"
import BulkRequests from "../components/life_record/bulk_requests.vue";
import report_documents from "../components/life_record/report_documents.vue";

//POSTGRADO
import PostgraduateSubjectsSearch from "../components/postgraduate/PostgraduateSubjectsSearch";

//Libros
import NewBookForm from "../components/books/NewBookForm";
import NewInbookForm from "../components/books/NewInbookForm";
import NewConferenceForm from "../components/books/NewConferenceForm";
import NewIsbnModal from "../components/books/NewIsbnModal";
import NewEditorialModal from "../components/books/NewEditorialModal";
import ShowBook from "../components/books/ShowBook";
import BookTable from "../components/books/BooksTable";
import BookDataTable from "../components/books/BookDataTable";
import IsbnSearch from "../components/books/IsbnSearch";
import ShowConference from "../components/books/ShowConference";



import FirmaDigitalSimple from "../components/utils/firma_digital_simple/firma_digital_simple.vue";

//Landing Page
import landing_page from "../components/landing_page.vue";
import welcome_siga from "../components/welcome_siga.vue";

import PerformancesIndex from "../components/performances/performances_index.vue";

//PERMISOS ADMINISTRATIVOS CGS
import AdministrativePermitsView from "../components/administrative_permits/AdministrativePermitsView.vue";
import AdministrativePermitsSearchView from "../components/administrative_permits/AdministrativePermitsSearchView.vue";
import AdministrativePermitsPersonView from "../components/administrative_permits/AdministrativePermitsPersonView.vue";
import AdministrativePermitsManageView from "../components/administrative_permits/AdministrativePermitsManageView.vue";
import AdministrativePermitsResolutionsView from "../components/administrative_permits/AdministrativePermitsResolutionsView.vue";

//Dashboard
import IndicatorByUnit from '../components/dashboard/IndicatorByUnit.vue'

// Acreditación digital
import DigitalAccreditation from "../components/accreditation/DigitalAccreditation.vue";
import AccreditationControl from "../components/accreditation/AccreditationControl.vue";

//SDT
import NewSdtServiceForm from "../components/sdt_service/NewServiceForm.vue"
import SdtServiceDataTable from "../components/sdt_service/ServiceDataTable.vue"
import ShowSdtService from "../components/sdt_service/ShowService.vue"
import SdtFaculty from '../components/sdt_service/SdtFaculty.vue'
import SdtShowUnit from '../components/sdt_service/SdtShowUnit.vue'
import SdtAudit from '../components/sdt_service/SdtAuditPersonal.vue'
import PersonProfileSdt from '../components/sdt_service/PersonProfileSdt.vue'
import MasiveUploadDocuments from '../components/sdt_service/MasiveUploadDocuments.vue'
import SdtRols from '../components/sdt_service/SdtRols.vue'
import NullifiedServices from "../components/sdt_service/NullifiedServices.vue";
import NewSdtTransactionForm from "../components/sdt_service/transactions/NewTransactionServicioForm.vue"
import SdtSurplusesDataTable from "../components/sdt_service/SurplusesDataTable.vue"
import NewSdtSurplusForm from "../components/sdt_service/transactions/NewSurplusForm.vue"
import NewSdtTransactionBienForm from "../components/sdt_service/transactions/NewTransactionBienForm.vue"
import NewSdtTransactionContratacionForm from "../components/sdt_service/transactions/NewTransactionContratacionForm.vue"
import NewSdtTransactionServicioForm from "../components/sdt_service/transactions/NewTransactionServicioForm.vue"
import ShowSurplus from "../components/sdt_service/transactions/ShowSurplus.vue"
import SdtReportingUnit from "../components/sdt_service/transactions/ReportingUnit.vue"
import SdtReportingFaculty from "../components/sdt_service/transactions/ReportingFaculty.vue"
import NewSdtTransactionBienRenderFundForm from "../components/sdt_service/transactions/NewTransactionBienRenderFundForm.vue"
import NewSdtTransactionServiceRenderFundForm from "../components/sdt_service/transactions/NewTransactionServiceRenderFundForm.vue"
import ShowTransaction from "../components/sdt_service/transactions/ShowTransaction.vue"


Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
import { required, email,min_value, max_value,integer,numeric, min, max, regex, alpha_num, length, alpha_dash, between, alpha_spaces, required_if} from 'vee-validate/dist/rules';
import postgraduateSubjectsSearch from "../components/postgraduate/PostgraduateSubjectsSearch";
extend('between', {
  ...between,
  message: "Valor no se encuentra entre los números permitidos"
});
extend('email', {
  ...email,
  message: 'No es un email válido'
});
extend('min_value', {
  ...min_value,
  params: ['min'],
  message: "El valor no puede ser menor a {min}.",
});
extend('max_value', {
  ...max_value,
  params: ['max'],
  message: "El valor no puede ser mayor a {max}.",
});
extend('required', {
  ...required,
  message: 'Este campo es requerido'
});
extend('numeric', {
  ...numeric,
  message: 'Este campo debe ser númerico'
});
extend('positive', value => {
  if (value >= 0) {
    return true;
  }
  return 'Este campo debe ser mayor a 0';
});
extend('integer', {
  ...integer,
  message: 'Este campo debe ser un número'
});
extend('alpha_num', {
  ...alpha_num,
  message: 'Este campo debe ser un número o letra'
});
extend('alpha_dash', {
  ...alpha_dash,
  message: 'Este campo debe ser un número, letra, espacios, guiones o guión bajo'
});
extend('alpha_spaces', {
  ...alpha_spaces,
  message: 'Este campo debe ser solo letras'
});
extend('length', {
  validate(value, { length }) {
    return value.length == length;
  },
  params: ['length'],
  message: 'El campo debe tener {length} caracteres'
});
extend('min', {
  validate(value, { length }) {
    return value.toString().length >= length;
  },
  params: ['length'],
  message: 'El campo debe tener al menos {length} caracteres'
});
extend('max', {
  validate(value, { length }) {
    return value.toString().length <= length;
  },
  params: ['length'],
  message: 'El campo debe tener máximo {length} caracteres'
});
extend('regex', {
  ...regex,
  message: 'El campo tiene caracteres invalidos'
});
extend('start_date_not_past', {
  validate(value) {
    return moment(value).isSameOrAfter(moment().startOf('day'), 'day');
  },
  message: 'La fecha de inicio no puede ser anterior a la fecha actual'
});
extend('end_date_not_past', {
  validate(value) {
    return moment(value).isSameOrAfter(moment().startOf('day'), 'day');
  },
  message: 'La fecha de término no puede ser anterior a la fecha actual'
});
extend('start_date_before_end_date', {
  params: ['end_date'],
  validate(value, { end_date }) {
    if (!end_date) {
      return true;
    }
    return moment(value).isSameOrBefore(moment(end_date), 'day');
  },
  message: 'La fecha de inicio no puede ser posterior a la fecha de término'
});
extend('end_date_not_before_start_date', {
  params: ['startDate'],
  validate: (value, { startDate }) => {
    const endDate = moment(value);
    const start = moment(startDate);
    return endDate >= start;
  },
  message: 'La fecha de término no puede ser anterior a la fecha de inicio'
});

extend('valid_dv', {
  validate: value => /^[0-9Kk]$/.test(value),
  message: 'El DV solo puede ser un número del 0 al 9 o la letra K/k'
});

extend('protocol', value => {
  var protocol = 'https://'
  if (value.includes(protocol)) {
    return true
  }
  return 'No es una URL válida debe contener https:// '
});

extend('required_if', {
  ...required_if,
  message: 'Este campo es requerido'
})

/*extend('regex', {
  validate(value, { reg }) {
    var regex = new RegExp(reg);
    console.log("expresion reguar: ",  reg)
    return reg.test(value);
  },
  params: ['reg'],
  message: 'El campo debe contener solo letras y espacios'
});*/

document.addEventListener('DOMContentLoaded', () => {
 // Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  setupCSRF();
  const app = new Vue({
    el: '#app',
    data:{

    },
    components:{App,ButtonComponent,DashboardTable,AddProduct,
      AddProject, AddPatent, Multiselect, AddPublication,PageSidebar,ProductDataTable,
      PublicationDataTable,PatentDataTable,ProjectDataTable,UnitDataTable,PeopleDataTable,
      ValidationTable,AddAcademicActivityDropdown,ShowProduct,ShowPatent,ShowProject,
      AdvancedSearch, ListRenewableAppointments, EstablishmentTabs, PermissionsManager, UserCreation,
      ShowPublication, Faculty, NavBarSearch, PeopleDataTableGraduates, ShowView, NewView,
      AgreementsIndexView, AgreementsNewView, AgreementsShowView, AgreementsHistoryNewView,
      WorkHistoryNewView, ProfileAsignation,LoadData,ShowPerson,ShowUnit, VimeActivityIndexView, VimeActivityNewView,
      VimeActivityShowView, VimeActivityCalendarView, VimeActivityAssistanceNewView, ConfigurationIndexView, ConfigurationAgreements, 
      ConfigurationActivityEdit, ConfigurationSocialNetworkEdit, FormPerson, LifeRecordTabs, PeopleIndex, ShowParentC, 
      PartnerEntitiesVimeIndex, PartnerEntitiesVimeNewView, PartnerEntitiesVimeShowView, VimeActivityNewEntitiesRelatedView,
      PersonByAppointmentList, LifeRecordDashboard, ProfilesTabs, ExternalPeopleTabs, PermissionsList, ShowDisclosure, AddDisclosure, DisclosureDataTable, ProfileForm, MyLifeRecord,
      exportAcademicActivity, IndicatorComposition, SearchLifeRecord, ExtensionHours, ContinuityHours, NewPerformanceForm,
      NewProjectForm, LifeRecordImportDashboard, ImportEntry, LifeRecordAudit, AuditTabs, ImportEntryAppointment,
      ExportMhvForId, NewPublicationForm, NewMagazineModal, RectifyAppointmentAcademic, RectifyAppointmentAdministrative, RectifyAppointmentHourclass,
      RectifyAppointmentFeecontract,
      PostulatedProjectForm, ShowPostulatedProject, PostulatedProjectsList,PartnerEntitiesPerson,
      ApiOrcidActiveSearch, nullified_activity, nullified_project,
      modal_show_nullified_project, FinancingsNullifiedTab, nulliefied_publication, modal_show_nullified_publication, nulliefied_patent,
      modal_show_nullified_patent, nullified_show_patent, modal_show_nulliefied_disclosure, nullified_disclosure, nullified_show_disclosure, additionalInformationTab,
      CompareAssets, IdDashboard,DataControlDashboard,NullifiedPostulatedProjectsList, NewDisclosureForm, ShowGeneralTab, NewPatentForm,Tutorials, ContactInfo,
      PersonByFeeContractList, NewDocumentForm, ShowDocument, Documents, SearchDocuments, NullDocuments, DocumentsNavigation, ImportDocuments,
      IDControlDashboard, Functions, NullifiedFunctions, LifeRecordFunctionsDashboard, NewExtensionHours, CompareAnidData, CompareInapiData, PublicationType, AgreementsActive, IncreaseHours,
      Requests, 
      AdministrativePermitsView, AdministrativePermitsSearchView, AdministrativePermitsPersonView, AdministrativePermitsManageView, AdministrativePermitsResolutionsView,
      NoEffectAppointmentHourclass, AgreementNullifiedView, PostgraduateSubjectsSearch, NewBookForm, NewIsbnModal, NewEditorialModal, ShowBook,
      NewInbookForm, BookDataTable, BookTable, IsbnSearch, NewConferenceForm, ShowConference, RequestShow, MyRequests, Requests,BulkRequests, VideoTutorials, FirmaDigitalSimple, landing_page,
      PerformancesIndex, IndicatorByUnit, welcome_siga, DigitalAccreditation, AccreditationControl,
      IDControlDashboard, Functions, NullifiedFunctions, LifeRecordFunctionsDashboard, NewExtensionHours, CompareAnidData, CompareInapiData, AgreementsActive, IncreaseHours,
      NoEffectAppointmentHourclass, RequestShow, MyRequests, Requests, AgreementNullifiedView, PostgraduateSubjectsSearch,BulkRequests, VideoTutorials, FirmaDigitalSimple, landing_page,
      NewSdtServiceForm, SdtServiceDataTable, ShowSdtService, SdtFaculty, SdtShowUnit, SdtAudit,PersonProfileSdt, MasiveUploadDocuments, SdtRols, NullifiedServices, NewSdtTransactionForm,
      NoEffectAppointmentHourclass, AgreementNullifiedView, PostgraduateSubjectsSearch, NewBookForm, NewIsbnModal, NewEditorialModal, ShowBook, SdtSurplusesDataTable,
      NewInbookForm, BookDataTable, BookTable, IsbnSearch, NewConferenceForm, ShowConference, RequestShow, MyRequests, Requests,BulkRequests, VideoTutorials, FirmaDigitalSimple, landing_page,
      NewSdtSurplusForm, NewSdtTransactionBienForm, NewSdtTransactionContratacionForm, NewSdtTransactionServicioForm, ShowSurplus, SdtReportingUnit, SdtReportingFaculty,
      NewSdtTransactionBienRenderFundForm, NewSdtTransactionServiceRenderFundForm, ShowTransaction, ControlDocument, report_documents
    }
  })
})
export const bus = new Vue();



// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'hello_vue' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>


// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: () => {
//       return {
//         message: "Can you say hello?"
//       }
//     },
//     components: { App }
//   })
// })
