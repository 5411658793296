<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <form @submit.prevent="saveMember">
        <!-- Box de Formulario Evento -->
        <div class="modal-card" style="width: auto" id="modal-event">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <!-- Componente de la busqueda -->
          <section class="modal-card-body" id="modal-events">
            
            <b-steps
                v-model="activeStep"
                :animated="isAnimated"
                :rounded="isRounded"
                :has-navigation="hasNavigation"
                :icon-prev="prevIcon"
                :icon-next="nextIcon"
                :label-position="labelPosition"
                :mobile-mode="mobileMode">
              <b-step-item step="1" label="Persona" :clickable="isStepsClickable">
                <h1 class="title has-text-centered">Seleccionar Persona  [1/3]</h1>

                  <div class="columns">
                    <div class="column"></div>
                    <div class="column is-6">
                      <div class="has-text-centered">
                        <b-field>
                          <b-input type="text" expanded minlength="3" icon="search" v-model='busqueda' @keyup.enter.native.prevent="search(busqueda)" placeholder="Búsqueda con al menos 3 letras..."></b-input>
                          <p class="control">
                            <b-button @click.prevent="search(busqueda)" icon-left="search" class="is-primary" id="boton-buscar">Buscar</b-button>
                          </p>
                          
                        </b-field>
                      </div>
                    </div>
                    <div class="column"></div>
                  </div>
              <!-- Resultado de la busqueda -->
                  <div class="columns">
                    <div class="column">
                      <h3 class="is-size-4 has-text-weight-bold">Tabla Resultados
                        <b-tooltip label="Debe seleccionar una persona desde la tabla de resultados."
                                   position="is-right"
                                   size="is-medium"
                                   multilined>
                          <b-icon
                              pack="fas"
                              icon="info-circle"
                              size="is-small"
                              type="is-info">
                          </b-icon>
                        </b-tooltip>
                      </h3>
                    </div>
                    <div class="column is-narrow">
                     
                        <b-button class="is-primary is-outlined"  icon-left="plus" @click="addExternalPerson">
                          Añadir persona nueva al sistema
                        </b-button>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-table
                          :data="people"
                          :paginated=true
                          per-page=5
                          striped
                          hoverable
                          :current-page="currentPage"
                          :pagination-simple=false
                          pagination-position="bottom"
                          default-sort-direction="asc"
                          sort-icon="arrow-up"
                          sort-icon-size="is-small"
                          default-sort="people.name"
                          aria-next-label="Siguiente"
                          aria-previous-label="Anterior"
                          aria-page-label="Página"
                          aria-current-label="Página actual"

                      >
                        <b-table-column
                            field="name"
                            label="Nombres"
                            sortable
                            searchable>
                          <template
                              slot="searchable"
                              slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                placeholder=" Buscar"
                                icon="search"
                                size="is-small" />
                          </template>
                          <template v-slot="props">
                            {{props.row.name}}
                          </template>
                        </b-table-column>
                        <b-table-column
                            field="corporate_mail"
                            label="Correo"
                            sortable
                            searchable>
                          <template
                              slot="searchable"
                              slot-scope="props">
                            <b-input
                                v-model="props.filters[props.column.field]"
                                placeholder=" Buscar"
                                icon="search"
                                size="is-small" />
                          </template>
                          <template v-slot="props">
                            {{ props.row.corporate_mail ? props.row.corporate_mail : props.row.personal_mail }}
                          </template>
                        </b-table-column>

                        <b-table-column
                            label="Acción"
                            width="10"
                            v-slot="props">

                            <b-radio v-model="selected"
                              name="name"
                              :native-value="props.row">
                          </b-radio>
                        </b-table-column>

                      </b-table>


                    </div>
                </div>
              </b-step-item>
              <b-step-item
                           step="2" label="Contrato" :clickable="isStepsClickable">
                <h1 class="title has-text-centered">Datos de contrato  [2/3]</h1>


                <div class="columns">
                  <div class="column">
                    <div class="field">
                      <b-field label="Fecha de inicio*">
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <b-datepicker
                              v-model="contract.start_date"
                              icon="calendar-alt"
                              trap-focus
                              locale="es-ES"
                              editable
                              placeholder="Formato de Fecha: dd/mm/aaaa">
                          </b-datepicker>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>
                  <div class="column">
                    <div class="field">
                      <b-field label="Fecha de término">
                        <b-datepicker
                            v-model="contract.end_date"
                            icon="calendar-alt"
                            trap-focus
                            locale="es-ES"
                            editable
                            placeholder="Formato de Fecha: dd/mm/aaaa">
                        </b-datepicker>
                      </b-field>
                    </div>
                  </div>
                </div>

                <div class="columns">
                  <div class="column" v-if="tipos_pago.length>0">
                    <b-field label="Forma de pago*">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <multiselect v-model="contract.sdt_payment_type_id"
                                     :options="tipos_pago.map(type => type.id)"
                                     :custom-label="opt => tipos_pago.find(x => x.id == opt).name"
                                     placeholder="Seleccione forma de pago"
                                     selectLabel=""
                                     selectedLabel=""
                                     deselectLabel=""
                                     :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                                    No se encontraron elementos.
                                </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Monto según forma de pago*">
                      <ValidationProvider rules="required|integer|min_value:0|max_value:99999999999|max:11" v-slot="{ errors }">
                        <b-input v-model="contract.amount" maxlength="11" placeholder="Ej: 250000" type="text"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                </div>

                <div class="columns">
                  
                  <div class="column">
                    <b-field label="Monto Total*">
                      <ValidationProvider rules="required|integer|min_value:0|max_value:99999999999|max:11" v-slot="{ errors }">
                        <b-input v-model="contract.total_amount" maxlength="11" placeholder="Ej: 500000" type="text"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>



                <div class="columns">
                  <div class="column">
                    <div class="field">
                      <b-field label="Servicios Prestados">
                        <ValidationProvider rules="max:4000" v-slot="{ errors }">
                          <b-input
                              type="textarea"
                              minlength="0"
                              maxlength="4000"
                              v-model="contract.service">
                          </b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>

                </div>



              </b-step-item>

              <b-step-item step="3" label="Documentación asociada" :clickable="isStepsClickable">
                <h1 class="title has-text-centered">Documentación  [3/3]</h1>
                <div class="columns" >
                  <div class="column">
                    <p  v-if="procesos.length > 0">
                      <strong>Proceso: </strong>
                      <span>

                  <ValidationProvider rules="" v-slot="{ errors }">
                      <multiselect v-model="proceso"
                                   :options="procesos.map(type => type.id)"
                                   :custom-label="opt => procesos.find(x => x.id == opt).name"
                                   placeholder="Seleccione Proceso"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   @select="selectProcess"
                                   :allow-empty="true">
                          <template v-slot:noOptions>
                              No existen datos
                          </template>
                          <span slot="noResult">
                              No se encontraron elementos.
                          </span>
                      </multiselect>
                      <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </span>
                    </p>

                  </div>
                </div>
                <div class="columns">
                  <div class="column has-text-centered">
                    <b-message class="is-inline-block is-centered" type="is-warning" title="¡Importante!" has-icon aria-close-label="Close message">
                        Extensiones de archivo permitido: PDF, DOC, DOCX, XLSX, XLS.
                        <br />
                        Tamaño máximo de archivo permitido: <b> 10 MB</b>
                    </b-message>
                    <b-field extended label="Subir documento*">
                      <ValidationProvider rules="" v-slot="{ errors }">
                        <b-upload v-model="files" :disabled="!proceso" drag-drop multiple>
                          <div class="content has-text-centered">
                            <p> <b-icon icon="file-pdf" size="is-large"></b-icon> </p>
                            <p>Arrastra tu documento o haz click para subir.</p>
                          </div>
                        </b-upload>
                        <br>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
                <hr class="dropdown-divider" aria-role="menuitem">
                <h3 class="is-size-4 has-text-weight-bold">Documentos listos para subir</h3>
                <br> <br>
                <b-table
                    :data="documentos"
                    :paginated=true
                    per-page=10
                    striped
                    hoverable
                    :pagination-simple=false
                    pagination-position="bottom"
                    default-sort-direction="desc"
                    sort-icon="arrow-up"
                    sort-icon-size="is-small"
                    default-sort="last_patent_state_date"
                    aria-next-label="Siguiente"
                    aria-previous-label="Anterior"
                    aria-page-label="Página"
                    aria-current-label="Página actual">
                  <b-table-column
                      field= 'name'
                      label= 'Nombres'
                      width="12em"
                      searchable
                      sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{  props.row.file.name }}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field= 'tipo'
                      width="20em"
                      label= 'Tipo de documento'>
                    <template v-slot="props">

                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect v-model="props.row.tipo"
                                     :options="tipos.map(type => type.id)"
                                     :custom-label="opt => tipos.find(x => x.id == opt).name"
                                     placeholder="Seleccione Tipo de documento"
                                     selectLabel=""
                                     selectedLabel=""
                                     deselectLabel=""
                                     :allow-empty="true">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                          No se encontraron elementos.
                      </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </template>
                  </b-table-column>
                  <b-table-column
                      field= 'fecha'
                      width="8em"
                      label= 'Fecha'>
                    <template v-slot="props">

                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-datepicker
                            v-model="props.row.fecha"
                            icon="calendar-alt"
                            locale="es-ES"
                            editable
                            trap-focus
                            placeholder="Formato de Fecha: dd/mm/aaaa"
                            append-to-body
                        >
                        </b-datepicker>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </template>
                  </b-table-column>
                  <b-table-column
                      label="Acción"
                      width="10"
                      v-slot="props">
                    <b-button class="is-danger" icon-left="trash" @click="deleteFileTab(props.row)">
                    </b-button>
                  </b-table-column>

                  <template slot="empty" slot-scope="props">
                    <div class="columns is-centered">
                      <div class="column is-6 is-centered has-text-centered">
                        <b-message type="is-danger">
                          No se encontraron documentos.
                        </b-message>
                      </div>
                    </div>
                  </template>
                </b-table>
              </b-step-item>
            </b-steps>
            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion" :key='index'>
                      <div v-if="item">{{ item[0] }}</div>
                    </div>
                  </b-message>
                </div>
              </div>
            </div>

          </section>
        </div>
        <footer class="modal-card-foot actions">
          
            <button class="button left-button" type="button" @click="$emit('close')">Cerrar</button>
            <b-button
              icon-left="save"
              class="button right-button is-primary"
              native-type="submit"
              @click="checkValidation()">
                Guardar
            </b-button>
        
          
        </footer>
      </form>
    </ValidationObserver>
    <b-modal
        v-model="isCreateModal"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-sdt-people-create
            query-type="create"
            modal-header="Agregar datos personales"
            @clicked="onClickChild"
            @close="props.close"></modal-sdt-people-create>
      </template>
    </b-modal>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import ModalSdtPeopleCreate from "./ModalSdtPeopleCreate.vue"
import moment from "moment/moment";

export default {
  name: 'modal_member_service',
  components: {ModalSdtPeopleCreate},
  data() {
    return {
      isLoading: false,
      isCreateModal: false,
      canCancel: ['escape'],
      busqueda: "",
      people: [],
      currentPage:1,
      publicacions: [],
      selected: null,
      is_validated: false,
      activeStep: 0,
      tipos_pago:[],
      contract: {},
      isAnimated: true,
      isRounded: true,
      keepFirst: false,
      isStepsClickable: true,
      hasNavigation: true,
      procesos:[],
      proceso: null,
      tipos: [],
      files: [],
      documentos: [],
      errores_validacion: {},
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      llega:[],
    }
  },
  props:[
    'modalHeader', 'serviceId', 'serviceTable', 'integrants'
  ],
  async created(){

    let vm = this;
    vm.isLoading=true;
    axios.get("/origin_document_types/origin-document-sdt.json", {params:{type: "person"}})
        .then(async(res) => {
          vm.procesos = res.data;
          vm.busqueda = ""
          await vm.getPaymentTypes();
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });

  },
  watch: {
    files: {
      handler(newFiles) {
        // Aquí actualizamos el arreglo 'documentos' cuando se seleccionan nuevos archivos
        this.documentos = newFiles.map(file => {
          const existingDocumento = this.documentos.find(doc => doc.file.name === file.name);
          if (existingDocumento) {
            return {
              file: file,
              tipo: existingDocumento.tipo || '',
              fecha: existingDocumento.fecha || new Date()
            };
          } else {
            return {
              file: file,
              tipo: null,
              fecha: new Date() 
            };
          }
        });
      },
      deep: true
    }
  },

  methods: {
    search(query){
      this.isLoading = true
      if(query.length<2){
        this.$buefy.dialog.alert({
          message: 'Por favor ingrese a lo menos 3 letras.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = false;
        return false;
      }
      var checkedRows = []
      if(this.selected !== null){
        checkedRows.push({person_id: this.selected.person_id})
      }

      checkedRows.push({person_id: -1})
      return axios
          .get("/people/"+query+"/"+JSON.stringify({ checkedRows: checkedRows })+"/search_all_sdt_people.json"
          ).then(res => {
            this.people = res.data.participants
            this.llega = res.data.participants
            this.people = this.people.filter((consulta) => !this.integrants.some((integr) => integr.person_id === consulta.person_id));
            this.people.forEach(function(participante){
              if (participante.external == true){
                participante.external = "SI"
              }
              else{
                participante.external = "NO"
              }
            })          
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })
      this.isLoading=false;
    },

    selectProcess(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/document_type_names/document-names.json",{params:{id:vm.proceso}})
          .then(res => {
            vm.tipos = [];
            vm.files = [];
            vm.tipos = res.data;
            vm.isLoading = false;
          })
          .catch(e => {
            vm.isLoading=false;
            //console.log(e);
          });

    },

    deleteFileTab(dropFile){
      const index = this.documentos.indexOf(dropFile);
      if (index !== -1) {
        this.documentos.splice(index, 1);
        this.files.splice(index,1);
      }
    },

    async saveMember(){

      let vm = this;
      let id_service = "";
      let table_name = "";
      id_service = vm.serviceId;
      table_name = vm.serviceTable;

      const formData = new FormData();

      // Agregar otros datos al FormData, como tipos y fechas
      this.documentos.forEach((documento, index) => {
        formData.append(`file${index}`, documento.file);
        formData.append(`type${index}`, documento.tipo);
        formData.append(`date${index}`, moment(documento.fecha).format("MM_YYYY"));
        formData.append(`document_date${index}`, documento.fecha);
      });
      formData.append('contract_attributes',JSON.stringify(vm.contract));
      formData.append('sdt_participant',JSON.stringify(vm.selected));
      formData.append('service', id_service);
      formData.append('table', table_name);

      if (vm.is_validated ){
        vm.isLoading=true;
        var retorno = [];
        axios
            .post("/sdt_participants.json", formData)
            .then(response => {
              vm.errores_validacion = {};
              response.data.participant.contract.start_date = moment(response.data.participant.contract.start_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
              if (response.data.participant.contract.end_date != null)
                response.data.participant.contract.end_date = moment(response.data.participant.contract.end_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
              this.$emit('clicked', response.data.participant)
              if (response.data.documents_errors.length>0) {
                let msj = "Persona contratada agregada correctamente <br> Los siguientes Archivos <b>fallaron al momento de ser adjuntados</b>: <br> <br>"
                response.data.documents_errors.forEach((elemento, index) => {
                  msj = msj+"- "+elemento.name+": "+elemento.error+"<br> <br>"
                })
                this.$buefy.dialog.alert({
                  message: msj,
                  type: 'is-danger',
                  hasIcon: true,
                  icon: 'times-circle',
                  iconPack: 'fa',
                  ariaRole: 'alertdialog',
                  ariaModal: true,
                   onConfirm: () => this.$emit('close')
                })
              }
              else{
                this.$buefy.dialog.alert({
                  message: "Persona contratada agregada correctamente",
                  type: 'is-success',
                  ariaModal: true,
                  onConfirm: () => this.$emit('close')
                })
              }
              
              vm.isLoading=false;
            }).
        catch(error => {
          vm.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          vm.isLoading=false;
        })
      }
      
    },
    async checkValidation() {
      this.is_validated = false;
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar Paso 2 "Contrato".',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
      else if (this.selected == null)
      {
        this.$buefy.dialog.alert({
          message: 'Debe seleccionar a una persona. Por favor revisar Paso 1 "Persona".',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
      else{
        this.is_validated = true;
      }
    },
    async getPaymentTypes(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/sdt_payment_types.json")
        .then(res => {
          vm.tipos_pago = res.data;
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    addExternalPerson(){
      this.isCreateModal = true
    },
    onClickChild(value){
      this.people.push(value)
      this.selected = value
    },
  }
}
</script>

<style scoped>
  .right-button{
    margin-left: auto;
  }
  .left-button{
    margin-right: auto;
  }

</style>

