<template>
  <section>
    <div class="box">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
          <b-message type="is-danger" has-icon>
            <div v-for="(item,index) in errores_validacion">
              {{ item[0] }}
            </div>
          </b-message>
        </div>
        <form @submit.prevent="handleSubmit(saveConference)">
          <section v-if="isUpdatingAndDeletingParticipant(false)!=undefined" class="modal-card-body" ref="modalPersonForm">
            <b-steps
                ref="publicationsteps"
                v-model="activeStep"
                :animated="isAnimated"
                :rounded="isRounded"
                :has-navigation="hasNavigation"
                :icon-prev="prevIcon"
                :icon-next="nextIcon"
                :label-position="labelPosition"
                :mobile-mode="mobileMode">
              <b-step-item v-if="isUpdatingAndDeletingParticipant(false)"
                           step="1"
                           label="General"
                           :clickable="isStepsClickable">
                <h1 class="title has-text-centered">General [1/2]</h1>
                <div class="columns">
                  <div class="column">
                    <b-field label="Título*">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-input v-model="publication.title" type="text"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
                

                <div class="columns">
                  <div class="column">
                    <h3 class="is-size-6 has-text-weight-bold">
                      Autores*
                      <b-tooltip label="En esta sección debe ingresar en un campo de texto las y los autores de la sección en el orden deseado, solo para su visualización.
                                Este campo de texto es solo referencial.
                                Ej de texto:'Shaoshuai Wang, Lili Guo, Ling Chen, Weiyong Liu, Yong Cao, Jingyi Zhang and Ling Feng'"
                                 position="is-right"
                                 size="is-large"
                                 multilined>
                        <b-icon
                            pack="fas"
                            icon="info-circle"
                            size="is-small"
                            type="is-info">
                        </b-icon>
                      </b-tooltip>
                    </h3>
                    <b-field label=" ">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <b-input v-model="publication.participants_references" type="text" maxlength="255" :has-counter="false"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <b-field label="Evento*">
                      <template #label>
                        Evento*
                      </template>
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <b-autocomplete v-model="annal_title"
                                        :keep-first="keepFirst"
                                        :open-on-focus="openOnFocus"
                                        :data="eventos_filtrados"
                                        field="name"
                                        empty="Vacio"
                                        @typing="getEventosFiltrados"
                                        @select="option => {if (option) {publication.annal_id = option.id} }"
                                        :clearable="clearable">
                          <template slot-scope="props">
                            <div v-if="props.option.abbreviated_name!=null">
                              {{props.option.name}} ({{props.option.abbreviated_name}})
                            </div>
                            <div v-else>
                              {{props.option.name}}
                            </div>
                          </template>
                          <template #empty>No se encontraron resultados</template>
                        </b-autocomplete>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div v-if="publication.annal_id == null || annal_title == ''" class="column is-narrow">
                    <br>
                    <b-field label=" ">
                      <b-tooltip label="Si el Evento que desea agregar no existe, la puede agregar presionando el botón +"
                                 multilined
                                 position="is-left">
                        <b-button class="is-primary is-outlined" @click="addEvent()">
                          <b-icon icon="plus"></b-icon>
                        </b-button>
                      </b-tooltip>
                    </b-field>
                  </div>
                  <div v-else class="column is-narrow">
                    <br>
                    <b-field label=" ">
                      <b-tooltip label="Al presionar el botón, podrá editar la información del lugar del evento"
                                 multilined
                                 position="is-left">
                        <b-button class="is-success is-outlined" @click="editEvent()">
                          <b-icon icon="pen"></b-icon>
                        </b-button>
                      </b-tooltip>
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <h3 class="is-size-6 has-text-weight-bold">
                      Adicional
                      <b-tooltip label="En esta sección debe ingresar en un campo de texto la informacion extra para para su visualización. Este campo de texto es solo referencial. Ej:'Revisión de texto: Shaoshuai Wang, Diseño de portada e interior: Lili Guo, Colaboradores: Ling Chen, Weiyong Liu and Yong Cao'."
                                 position="is-right"
                                 size="is-large"
                                 multilined>
                        <b-icon
                            pack="fas"
                            icon="info-circle"
                            size="is-small"
                            type="is-info">
                        </b-icon>
                      </b-tooltip>
                    </h3>
                    <b-field label=" ">                      
                      <b-input v-model="publication.additional" type="text"></b-input>
                    </b-field>
                  </div>
                </div>


                
                
                

                


              
              </b-step-item>
              <b-step-item step="2"
                           label="Participantes"
                           :clickable="isStepsClickable">
                <h1 class="title has-text-centered">Participantes [2/2]
                  <b-tooltip label="En esta sección debe ingresar las(os) participantes del proyecto que
                                  se encuentren registrados en el sistema, de esta manera, los datos ingresados
                                   de esta patente quedarán asociados a esta persona."
                             position="is-right"
                             size="is-medium"
                             multilined>
                    <b-icon
                        pack="fas"
                        icon="info-circle"
                        size="is-small"
                        type="is-info">
                    </b-icon>
                  </b-tooltip></h1>
                <div v-if="queryType=='update'" class="columns is-centered">
                  <div class="column is-centered has-text-centered">
                    <b-message
                        title="Información importante"
                        type="is-warning"
                        aria-close-label="Cerrar mensaje">
                      Solo la/el usuaria/o que es Editora o Editor de esta actividad académica tiene permisos para editar todos sus atributos.<br>
                      Quienes participan en la actividad académica pero no son editores, solo pueden editar su participación.
                      <br><span v-if="publication.creator_name">Origen de la información: {{ publication.creator_name }}</span>
                    </b-message>
                  </div>
                </div>
                <div v-if="isUpdatingAndDeletingParticipant(false)"  class="is-pulled-right">
                  <b-button class="mt-2" @click.prevent="addParticipant(conferenceId)" type="is-primary">Administrar participantes</b-button>
                </div>
                <div class="column">
                  <h3 class="is-size-4 has-text-weight-bold">Tabla Resumen Participantes</h3>
                  <b-table
                      v-if="showParticipantTable"

                      :key="tableDataKey"
                      :data="participantes"
                      :paginated=true
                      per-page=5
                      striped
                      hoverable
                      :current-page="currentPage"
                      :card-layout="cardLayout"
                      :pagination-simple=false
                      pagination-position="bottom"
                      default-sort-direction="asc"
                      sort-icon="arrow-up"
                      sort-icon-size="is-small"
                      default-sort="publication.title"
                      aria-next-label="Siguiente"
                      aria-previous-label="Anterior"
                      aria-page-label="Página"
                      aria-current-label="Página actual">
                    <b-table-column
                        field="name"
                        label="Nombres"
                        width="13em"
                        sortable
                        searchable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.name}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="unit"
                        label="Unidad"
                        width="13em"
                        sortable
                        searchable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.unit }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        width="15em"
                        field="participant_type"
                        label="Tipo de participación*"
                        v-slot="props">
                      <ValidationProvider v-if="isUpdatingAndDeletingParticipant(props.row)"
                                          rules="required" v-slot="{errors}">
                        <multiselect v-model="props.row.participant_type_id"
                                     :options="tipos_participacion.map(x => x.id)"
                                     :custom-label="opt => tipos_participacion.find(x => x.id == opt).name"
                                     placeholder="Seleccione tipo de participación"
                                     selectLabel=" "
                                     selectedLabel=" "
                                     deselectLabel="No se puede deseleccionar"
                                     :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                            No se encontraron elementos.
                          </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-table-column>
                    <b-table-column
                        label="Acciones"
                        width="10"
                        v-slot="props">
                      <b-icon
                          v-if="isUpdatingAndDeletingParticipant(props.row)"
                          pack="fas"
                          icon="trash"
                          style="color: #e50000;"
                          size="is-medium"
                          @click.native="deleteParticipantButton(props.row)"/>
                    </b-table-column>
                    <template slot="empty" slot-scope="props">
                      <div class="columns is-centered">
                        <div class="column is-6 is-centered has-text-centered">
                          <b-message type="is-danger">
                            No se encontraron participantes asociados.
                          </b-message>
                        </div>
                      </div>
                    </template>
                  </b-table>
                </div>
                <hr class="dropdown-divider" aria-role="menuitem">
                <div class="columns" v-if="queryType === 'update'">
                  <div class="column" v-if="publication.is_creator">
                    <h3 class="is-size-6 has-text-weight-bold">
                      Editor(a) del dato*
                      <b-tooltip label="Debe seleccionar al menos una Editora o Editor del dato que se encuentre como participante en la actividad académica, quien será el único capaz de editar toda la información. Si realiza el cambio, no podrá revertirlo."
                                 position="is-right"
                                 size="is-large"
                                 multilined>
                        <b-icon
                            pack="fas"
                            icon="info-circle"
                            size="is-small"
                            type="is-info">
                        </b-icon>
                      </b-tooltip>
                    </h3>
                    <b-field label=" ">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect v-model="ownerId"
                                     :options="participantes.map(type => type.person_id)"
                                     :custom-label="opt => participantes.find(x => x.person_id === opt).name"
                                     placeholder="Seleccione Editor(a)"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel="Seleccionado"
                                     deselectLabel=""
                                     :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                          No se encontraron elementos.
                      </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
                <div class="columns" v-else>
                  <div class="column">
                    <h3 class="is-size-6 has-text-weight-bold">
                      Editor(a) del dato*
                      <b-tooltip label="Debe seleccionar al menos una Editora o Editor del dato que se encuentre como participante en la actividad académica, quien será el único capaz de editar toda la información. Si realiza el cambio, no podrá revertirlo."
                                 position="is-right"
                                 size="is-large"
                                 multilined>
                        <b-icon
                            pack="fas"
                            icon="info-circle"
                            size="is-small"
                            type="is-info">
                        </b-icon>
                      </b-tooltip>
                    </h3>
                    <b-field label=" ">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect v-model="ownerId"
                                     :options="participantes.map(type => type.person_id)"
                                     :custom-label="opt => participantes.find(x => x.person_id === opt).name"
                                     placeholder="Seleccione Editor(a)"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel="Seleccionado"
                                     deselectLabel=""
                                     :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                          No se encontraron elementos.
                      </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
              </b-step-item>

              <template
                  #navigation="{previous, next}">
                <div class="columns is-pulled-right" >
                  <div class="column">
                    <b-button
                        outlined
                        icon-left="chevron-left"
                        :disabled="previous.disabled"
                        @click.prevent="previous.action">
                    </b-button>
                    <b-button
                        outlined
                        icon-right="chevron-right"
                        :disabled="next.disabled"
                        @click.prevent="next.action">
                    </b-button>

                  </div>
                </div>
              </template>

            </b-steps>
          </section>

          <br>
          <div class="columns">
            <div class="column">
              <div class="is-pulled-right">
                <button v-if="modalCancel" class="button is-danger" @click.prevent="$emit('close')">Cancelar</button>                
                <button class="button is-primary" type="submit" @click="checkValidation()">Guardar Conferencia</button>
              </div>
            </div>
          </div>
          <!-- Fin Box Formulario-->
        </form>
      </ValidationObserver>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <!-- MODALES -->
    <b-modal
        v-model="isAddModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-participants-publications
            query-type="update"
            modal-header="Añadir participantes"
            :participantes="participantes"
            :tipos_participacion="tipos_participacion"
            :publication="publication"
            @close="props.close"
            @clicked="onClickChild"></modal-participants-publications>
      </template>
    </b-modal>
    <b-modal
        v-model="isModalAnnalActive"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <new-annal-modal
            query-type="modal"
            type-modal="modals"
            modal-header="Añadir evento"
            @close="props.close"
            @clicked="onClickChildAnnal"
            v-bind:query-type="annalQueryType"
            :update-annal="updateAnnalId"
            >
        </new-annal-modal>
      </template>
    </b-modal>

    <add-participant-filiation modal-header="Añadir Filiación a Participante"
                               @close="closeFiliationModal"
                               @setFilitation="setParticipantFilitation"
                               :partner_entity="currentEntity"
                               :participant="currentParticipantEntity"
                               :active-modal="activeAddFiliationModal"></add-participant-filiation>

  </section>
</template>

<script>
import axios from "axios";
import BibtexInput from "../publications/bibtex_input.vue";
import { bus } from "../../packs/app_vue";
import ModalParticipantsForm from "../publications/modal_participants_publications.vue";
import moment from "moment";
import NewAnnalModal from "./NewAnnalModal";
import AddParticipantFiliation from "../filiations/addParticipantFiliation";

export default {
  name: "NewConferenceForm",
  components:{
    AddParticipantFiliation,
    NewAnnalModal,
    BibtexInput,
    'modal-participants-publications': ModalParticipantsForm
  },
  data(){
    return {
      isModalAnnalActive: false,
      activeStep: 0,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      cardLayout: false,
      hasNavigation: true,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      isLoading:false,
      errores_validacion: {},
      canCancel: ['escape'],
      annal_title: null,
      keepFirst: false,
      openOnFocus: false,
      clearable: false,
      requiredEditorial: true,
      skip_if_magister: false,

      publication: {
        participants_attributes: [],
        validation_attributes:{},
        annal_id: null,
      },
      participants: [],
      participantes: [],
      unidades: [],
      tipos_participacion: [],
      countries: [],
      eventos_filtrados:[],
      eventos:[],
      requiredFieldOnly: false,
      isAddModalActive: false,
      tableDataKey:0,
      currentPage:1,
      activeAddFiliationModal:false,
      currentEntity:{},
      currentParticipantEntity:{},
      ownerId: null,
      usach_filitation:{},
      showParticipantTable:true,
      doiPublicationFinded:null,
      annalQueryType:"create",
      updateAnnalId:null,
      tipo_publicacion: 0,
    }
  },
  watch:{
    isAddModalActive: function () {
      if (!this.isAddModalActive) {
        this.initialize()
      }
    },
  },
  props:['userId','publicationTypeId','queryType','conferenceId','cargoPrincipal','unidadPrincipal','personId','modalCancel',"showParticipantsFirst",'titleAnnal','AnnalId',
  'responseType'],

  created(){
    bus.$on('fill-publication', this.fillPublication);    
    
    this.isLoading = true;
    axios.get("/conferences/new.json")
        .then(res => {
          this.tipos_participacion = res.data['tipos_participacion'];
          this.unidades = res.data['unidades'];
          this.usach_filitation = res.data['usach_filitation'];          
          if (this.queryType==="create" && this.cargoPrincipal.name==="ACADEMICO"){
            let participant_type = null
            var coautor = this.tipos_participacion.filter(obj => {
              return obj.name == "CO-AUTOR"
            })
            if (coautor){
              participant_type = coautor[0].id;
            }

            this.participantes.push({
              person_id: res.data["participante"].person_id,
              name: res.data["participante"].person_name,
              alias: res.data["participante"].person_alias != null ? res.data["participante"].person_alias.toUpperCase() : "",
              corporate_mail: res.data["participante"].corporate_mail != null ? res.data["participante"].corporate_mail.toUpperCase() : "",
              unit: res.data["participante"].unit_name,
              unit_id: res.data["participante"].unit_id,
              participant_type_id: participant_type,
            })        
          }
          this.isLoading=false;
          if (this.queryType=="update"){
            this.isLoading=true;
            axios
                .get("/conferences/"+this.conferenceId+".json",{params:{edit_flag:true}})
                .then(res => {
                  this.publication = res.data
                  this.setParticipants();
                  if (this.publication.publication_date!=null && this.publication.publication_date!=undefined)
                    this.publication.publication_date = new Date(moment(this.publication.publication_date).format('MM/DD/YYYY'));
                  if (this.publication.validation_attributes==null){
                    this.publication.validation_attributes={}
                    this.publication.data_source=""
                  }
                  this.publication.annal_id = this.publication.annals_attributes.id;
                  this.annal_title = this.publication.annal;               
                  if (this.participantes.filter(person => person.person_id == this.publication.ownerId).length > 0){
                    this.ownerId = this.publication.ownerId
                  }
                  if(this.showParticipantsFirst){
                    this.activeStep=1;
                  }
                  this.isLoading=false;

                })
                .catch(e => {
                  console.log(e);
                  this.isLoading=false;
                });
          }
        })
        .catch(e => {
          console.log(e);
          this.isLoading=false;
        });
  },
  methods: {
    initialize(){

    },
    onClickChild(value){
      this.isLoading = true
      this.participantes = value
      let participant_type = null
      var coautor = this.tipos_participacion.filter(obj => {
        return obj.name == "CO-AUTOR"
      })
      if (coautor){
        participant_type = coautor[0];
      }
      this.participantes.forEach(function(participante) {
        if(!participante.participant_type_id && participant_type){
          participante.participant_type_id = participant_type.id
        }

      })
      this.isLoading = false

    },
    onClickChildAnnal(value){
      this.isLoading = true
      axios
          .get('/annals.json')
          .then(response => {
            this.eventos = response.data
            this.eventos_filtrados = response.data
            this.publication.annal_id = value.id;
            this.annal_title = value.name;
            this.isModalAnnalActive = false
          }).catch(error => {
        console.log(error)
        this.isLoading = false
      })
      this.isLoading = false
    },
    getAnnal(){
      this.isLoading = true
      axios
      .get('/annals.json')
      .then(response => {
        this.eventos = response.data
        this.eventos_filtrados = response.data
      }).catch(error => {
        console.log(error)
        this.isLoading = false
      })
    },
    addEvent(){
      this.annalQueryType = "create";
      this.updateAnnalId = null;
      this.isModalAnnalActive = true;
    },
   async saveConference(){
      this.publication.participants_attributes = []
      var index = 0
      for(const participante of this.participantes){
        this.publication.participants_attributes.push({
          person_id:null,
          participant_type_id:null,
          unit_id:null,
          unit_participants:[],
          _destroy:null
        })
        this.publication.participants_attributes[index].person_id = participante.person_id
        this.publication.participants_attributes[index].is_doc_student = participante.is_doc_student
        this.publication.participants_attributes[index].participant_type_id = participante.participant_type_id
        this.publication.participants_attributes[index].unit_id = participante.unit_id
        this.publication.participants_attributes[index].partner_entity_id = participante.partner_entity_id
        index = index + 1
      }
      this.isLoading=true;
      var retorno = JSON.parse(JSON.stringify(this.publication.participants_attributes));

      if(this.checkAnnal()){
        this.publication.origin_participant_type_id = 3
        if (this.queryType==="create") {
          if (this.skip_if_magister == true){
            this.publication.skip_if_magister = true
          }
          this.publication.academic_activity_state_id = 1
          
          axios
              .post("/conferences.json", {
                conference: this.publication,
                participants: retorno.map(function(x) {
                  delete x.unit_id;
                  delete x.unit_participants;
                  return x;
                }),
                user_id:this.userId,
                data_source:this.publication.validation_attributes.data_source,
                ownerId: this.ownerId
              })
              .then(response => {
                this.errores_validacion = {};
                window.location.href = '/conferences/'+response.data["id"]                
                this.isLoading=false;
              }).
          catch(error => {
            this.errores_validacion = error.response.data
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            this.isLoading=false;
          })
        }
        if (this.queryType==="update"){
          if (this.skip_if_magister == true){
            this.publication.skip_if_magister = true
          }
          axios
            .put("/conferences/"+this.conferenceId+".json", {
              conference: this.publication,
              participants: retorno.map(function(x) {
                delete x.unit_id;
                delete x.unit_participants;
                return x;
              }),
              user_id:this.userId,
              data_source:this.publication.validation_attributes.data_source,
              ownerId: this.ownerId
            })
            .then(response => {
              this.errores_validacion = {};
              if(this.responseType=="RETORNO"){
                this.$emit("response",response.data);
              }
              else{
                window.location.href = '/conferences/'+this.conferenceId
              }

              this.isLoading=false;
            }).
            catch(error => {
              this.errores_validacion = error.response.data
              var elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              this.isLoading=false;
            })
        }
      }
    },
    addParticipant(id){
      this.selected = id;
      this.isAddModalActive = true
    },
    deleteParticipant(index){
      this.publication.participants_attributes.splice(index,1)
    },

    deleteParticipantsPub(participants){
      if(participants.person_id == this.ownerId){
        this.ownerId = null
      }

      this.participantes = this.participantes.filter(person => person.corporate_mail != participants.corporate_mail);
    },
    findUnitParticipants(index){
      if(this.publication.participants_attributes[index].unit_id!=null){
        this.isLoading=true;
        axios
            .get("/get_people_by_unit.json", {
              params: {unit_id: this.publication.participants_attributes[index].unit_id}
            })
            .then(res => {
              this.publication.participants_attributes[index].unit_participants = res.data['personas'];
              this.isLoading=false;
            }).
        catch(error => {
          this.isLoading=false;
          console.log(error)
        })
      }
    },
    async getEventosFiltrados(text) {
      this.isLoading=true;
      await axios
        .get("/annals/search.json", {
          params: {name: text,}
        })
        .then(res => {
          this.eventos_filtrados = res.data;
          this.publication.annal_id = null
          


          setTimeout(()=>{
            this.isLoading = false
          },3000);
        }).
        catch(error => {
          this.isLoading=false;
          //console.log(error)
        })



    },
    async checkValidation(){
      const valid = await this.$refs.observer.validate()
      if(!valid){
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar tanto la pestaña General como la de Participantes.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
    checkAnnal(){
      if (this.skip_if_magister == true){
       return true
      }
      else if(this.publication.annal_id == null){
        this.$buefy.dialog.alert({
          message: 'Error en campo eventos. Revisar que el eventos exista',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = false
        return false
      }
      else{
        return true
      }
    },
    formatDate(inputDate) {
      // Verificar si el inputDate ya está en formato aaaa-mm-dd
      if (typeof inputDate === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(inputDate)) {
        return inputDate;
      }

      // Convertir la fecha al objeto Date si no está en el formato aaaa-mm-dd
      const date = new Date(inputDate);

      if (isNaN(date.getTime())) {
        throw new Error('Fecha inválida');
      }

      // Extraer año, mes y día y formatear a aaaa-mm-dd
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses son base 0 en JavaScript
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    fillPublication(newData){
      if (newData.publication_date){
        newData.publication_date = this.formatDate(newData.publication_date)
        newData.publication_date = new Date(newData.publication_date.replace(/-/g,"/"));
      }
      this.annal_title = null;
      this.publication.annal_id = null
      if (newData.annal_id){
        this.publication.annal_id = newData.annal_id;
        this.annal_title = newData.annal;
      }


      let is_creator = undefined;
      if(this.publication.is_creator){
        is_creator = true;
      }
      this.publication = newData;
      this.publication.is_creator = is_creator;


    },
    isUpdatingAndDeletingParticipant(row){
      if(this.queryType=="create"){
        return true;
      }
      else if(this.queryType=='update'){
        //console.log(this.project.current_user_person_id, row.id,row)
        if (row){
          return this.publication.is_creator || this.publication.current_user_person_id == row.person_id;
        }
        return this.publication.is_creator;
      }

    },
    editEvent(){
      this.annalQueryType = "update";
      this.updateAnnalId = this.publication.annal_id;
      this.isModalAnnalActive = true;

    },
    deleteParticipantButton(participants){

      this.$buefy.dialog.confirm({
        title: 'Eliminar Participante',
        message: 'Precaución, al momento de guardar la publicación esta acción eliminará la asociación con la/el participante, lo que implica que ya no podrá ver esta publicación ni será contada en su actividad académica ¿Desea continuar?',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteParticipantsPub(participants)
      })

    },
    AddFiliation(row){
      if(row.partner_entity){
        this.currentEntity = row.partner_entity;
      }
      else{
        this.currentEntity = {};
      }
      this.currentParticipantEntity = row;
      this.activeAddFiliationModal = true;
    },
    DeleteFiliation(row){
      this.isLoading = true;
      this.currentEntity = {};
      this.currentParticipantEntity = {};
      let index = this.participantes.findIndex(participant => participant.person_id==row.person_id)
      if (index>=0){
        this.participantes[index].partner_entity_id = null;
        this.participantes[index].partner_entity = {};
        this.tableDataKey++;
      }
      this.isLoading = false;
    },
    closeFiliationModal(){
      this.activeAddFiliationModal = false;
    },
    setParticipantFilitation(res){
      let index = this.participantes.findIndex(participant => participant==res.participant)
      if (index>=0){
        this.participantes[index].partner_entity_id = res.partner_entity.id;
        this.participantes[index].partner_entity = res.partner_entity;
        this.tableDataKey++;
      }

      this.currentEntity = {};
      this.activeAddFiliationModal = false;
    },
    setParticipants(){
      var index = 0
      for(const participante of this.publication.participants_attributes){
        this.participantes.push({
          id:null,
          name:null,
          alias:null,
          unit:null,
          unit_id:null,
          corporarte_mail:null,
          participant_type_id:null,
          is_doc_student:null
        })
        this.participantes[index].person_id = participante.person_id
        this.participantes[index].is_doc_student = participante.is_doc_student
        this.participantes[index].name = participante.person_name
        this.participantes[index].corporate_mail = participante.person_mail
        this.participantes[index].alias = participante.person_alias
        if(this.participantes[index].corporate_mail != null){
          this.participantes[index].corporate_mail = this.participantes[index].corporate_mail.toUpperCase()
        }
        if(this.participantes[index].alias != null){
          this.participantes[index].alias = this.participantes[index].alias.toUpperCase()
        }
        this.participantes[index].unit = participante.unit_name
        this.participantes[index].unit_id = participante.unit_id
        this.participantes[index].participant_type_id = participante.participant_type_id
        this.participantes[index].partner_entity_id = participante.partner_entity_id
        this.participantes[index].partner_entity = participante.partner_entity
        index = index + 1
      }
    },
    addUsachFilitation(participant){
      this.isLoading = true;
      this.showParticipantTable=false;
      let participantIndex = this.participantes.findIndex(part => part==participant );
      if(participantIndex>=0 && this.usach_filitation){
        this.participantes[participantIndex].partner_entity = this.usach_filitation;
        this.participantes[participantIndex].partner_entity_id = this.usach_filitation.id;
      }
      //this.participantes = this.participantes;
      this.showParticipantTable=true;
      this.isLoading = false;

    },

  },

}
</script>