<template>
  <section>
    <div class="container">
      <h1 class="is-size-2">Disclosures</h1>
      <div class="has-text-right">
        <b-button type="is-info"
                  icon-left="plus"
                  v-if="can_create==='true'"
                  @click="newDisclosure()">
          Añadir Disclosure
        </b-button>
      </div>
      <br>
      <disclosure-table v-bind:disclosures="disclosures" :cargoPrincipal="cargoPrincipal" @updateDisclosures="updateDisclosures"></disclosure-table>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import axios from "axios";
import DisclosuresTable from './disclosure/disclosure_table.vue'
import moment from "moment";
export default {
  data(){
    return{
      isLoading:false,
      currentPage:1,
      disclosures:[],
      year:new Date().getFullYear(),
    }
  },
  components:{'disclosure-table':DisclosuresTable},
  created(){
    this.getDisclosures(this.year)
  },
  props:[
    "can_create", "cargoPrincipal"
  ],
  methods:{
    newDisclosure(){
      window.location.href="/disclosures/new"
    },
    getDisclosures(year) {
      this.isLoading = true
      return axios.get("/disclosures.json", {params:{year_f:year}})
          .then(res => {

            this.disclosures = res.data;
            this.disclosures = this.disclosures.map(disclosure => {
              var newDisclosure = disclosure
              newDisclosure["disclosure_date"] = moment(newDisclosure["disclosure_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newDisclosure["invention_date"] = moment(newDisclosure["invention_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newDisclosure;
            });
            this.isLoading=false;
          })
          .catch(e => {
            this.isLoading=false;
            console.log(e);
          });
    },
    formatDate(date) {
      if (date != null && date != "" && date != undefined) {
        var arreglo = date.split("-")
        return arreglo[2] + "-" + arreglo[1] + "-" + arreglo[0]
      } else {
        return ""
      }
    },
    getDisclosureUrl(disclosure_id){
      return "/disclosures/"+disclosure_id;
    },

    updateDisclosures(year){
      this.getDisclosures(year);
    }

  },
  computed:{

  }
}
</script>