<template>
<section>
  <b-button
      label="Rellenar datos con DOI"
      type="is-primary"
      @click="showDoiSearch = true" />

  <b-modal v-model="showDoiSearch" :width="640" scroll="keep">
    <div class="card">
      <div class="card-content">
        <div class="content">
          <div class="field">
            <b-field label="Ingrese DOI">
              <b-input
                  maxlength="100"
                  v-model="doi">
              </b-input>
            </b-field>
          </div>
          <b-message
              type="is-warning"
              has-icon
              aria-close-label="Cerrar">
            ¡Precaución! Si esta operación tiene éxito, se sobreescribiran los datos llenados en el formulario.
          </b-message>
        </div>
      </div>
      <footer class="card-footer">
        <a @click="closeModal" class="card-footer-item">Cancelar</a>
        <a @click="fillData" class="card-footer-item">Rellenar datos</a>
      </footer>

    </div>
  </b-modal>

  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
</section>
</template>

<script>
import axios from "axios";
import { bus } from "../../packs/app_vue";
export default {
  name: "DoiSearch",
  data(){
    return {
      doi:"",
      showDoiSearch:false,
      isLoading:false,
    }
  },
  methods:{
    closeModal(){
      this.showDoiSearch=false;
    },
    fillData(){
      let vm = this;
      vm.isLoading = true;
      axios
          .get("/publications/parse/doi.json", {
            params: {doi:this.doi}
          })
          .then(response =>{
            bus.$emit('fill-publication', response.data)
            vm.$buefy.dialog.alert({
              title: 'Petición exitosa',
              message: 'Su petición fue exitosa. Recuerde rellenar los campos faltantes.',
              type: 'is-success',
              hasIcon: true,
              icon: 'check-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            vm.isLoading = false;
            vm.closeModal();
          }).catch(error => {
            //console.log("HOLAAAA",error, error.response, error.response.data);
            console.log(error.response.status);
            let error_message = "Hubo un error en su solicitud.";
            if(error.response.status==404){
              error_message = "El DOI no fue encontrado en la API DOI.";
            }
            if(error.response.status==504){
              error_message = "Hubo un error en su solicitud debido a que la API DOI no se encuentra disponible en estos momentos."
            }
            vm.$buefy.dialog.alert({
              title: 'Error',
              message: error_message,
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            vm.isLoading=false;
          })

    }
  }
}
</script>

<style scoped>

</style>