<template>
  <section>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <div class=" has-text-centered">
          
          <div class="columns">

            Recomendados: 
          </div>
          <div class="columns">

            <div class="column">
              <b-button class="is-primary is-outlined"
                        @click="isPublication = true">
                Publicación
              </b-button>
            </div>
            <div class="column" v-if="orcidPublication.publication_type_id==2">
              <b-button class="is-primary is-outlined"
                        @click="isBook = true"
                        >
                Libro
              </b-button>
            </div>
            <div class="column" v-if="orcidPublication.publication_type_id==5">
              <b-button class="is-primary is-outlined"
                        @click="isInbookChar = true"
                        >
                Capítulo Libro
              </b-button>     
            </div>
            <div class="column" v-if="orcidPublication.publication_type_id==4 || orcidPublication.publication_type_id==1 || orcidPublication.publication_type_id==5">     
              <b-button class="is-primary is-outlined"
                        @click="isInbookArti = true"
                        >
                Artículo Libro
              </b-button>
            
            </div>
            
          </div>
        
        
          <div class="columns">
            Todos:
          </div>

          <div class="columns is-grouped">
            <div class="column">
                <b-button class="is-primary is-outlined"
                          @click="isPublication = true">
                  Publicación
                </b-button>
            </div>
            <div class="column">
                <b-button class="is-primary is-outlined"
                          @click="isBook = true">
                  Libro
                </b-button>
            </div>
            <div class="column" >
                <b-button class="is-primary is-outlined"
                          @click="isInbookChar = true">
                  Capítulo Libro
                </b-button>
            </div>
            <div class="column" >
                <b-button class="is-primary is-outlined"
                          @click="isInbookArti = true">
                  Artículo Libro
                </b-button>
            </div>
            
          </div>
        </div>
      </section>

    </div>
    <footer class="modal-card-foot actions">
      <div class="field is-grouped is-pulled-right">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
      </div>
    </footer>


    <b-modal
      v-model="isPublication"
      width="90%"
      :can-cancel="true"
      :destroy-on-hide="true"
      aria-modal>
      <template #default="props">
        <new-publication-form modal-cancel="true"
                              :cargo-principal="''"
                              :user-id="userId"
                              :orcid-publication="orcidPublication"
                              :person-id="personId"
                              query-type="create" @close="closeModal" @publicationAdded="publicationAdded"></new-publication-form>
      </template>
    </b-modal>
    <b-modal
      v-model="isBook"
      width="90%"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-modal>
      <template #default="props">
        <new-book-form modal-cancel="true"
                              :cargo-principal="''"
                              :user-id="userId"
                              modal-header="Añadir Publicación"
                              :orcid-publication="orcidPublication"
                              :person-id="personId"
                              query-type="create" @close="closeModal" @publicationAdded="publicationAdded"></new-book-form>
      </template>
    </b-modal>
    <b-modal
      v-model="isInbookChar"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal>
      <template #default="props">
        <new-inbook-form modal-cancel="true"
                              :cargo-principal="''"
                              :user-id="userId"
                              :orcid-publication="orcidPublication"
                              publication-type-id="chapter"
                              :person-id="personId"
                              query-type="create" @close="closeModal" @publicationAdded="publicationAdded"></new-inbook-form>
      </template>
    </b-modal>

    <b-modal
      v-model="isInbookArti"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      >
      <template #default="props">
        <new-inbook-form modal-cancel="true"
                              :cargo-principal="''"
                              :user-id="userId"
                              modal-header="Añadir Publicación"
                              :orcid-publication="orcidPublication"
                              publication-type-id="article"
                              :person-id="personId"
                              query-type="create" @close="closeModal" @publicationAdded="publicationAdded"></new-inbook-form>
      </template>
    </b-modal>
  </section>
</template>

<script>


import NewPublicationForm from "../publications/NewPublicationForm";
import NewBookForm from "../books/NewBookForm";
import NewInbookForm from "../books/NewInbookForm";
export default {
  name: "select_Publication_Type",
  props:[
    'queryType', 'personId', 'modalHeader', 'person_name', 'userId', 'orcidPublication'
  ],
  components:{
    NewPublicationForm,
    NewBookForm,
    NewInbookForm
  },
  data(){
    return{
      isInbookArti: false,
      isInbookChar: false,
      isBook: false,
      isPublication: false,
      isLoading: true,
      canCancel: []
    }
  },
  created() {
    this.isLoading =false
  },
  methods:{
    publicationAdded(publication){
      this.$emit("publicationAdded",publication);
      this.modalActive = false;
    },
    closeModal(){
      this.isInbookArti = false;
      this.isInbookChar = false;
      this.isBook = false;
      this.isPublication = false;
      this.modalActive = false;
    },

  }
}
</script>

<style scoped>

</style>
