<template>
    <div class="agreementshistorynewform">
        <div class="container">
            <br>
            <br>
           <div class="box" >
               <div class="columns is-centered">
                   <div class="column">
                        <div class="field">
                            <div class="field-label is-normal">
                                <label class="label" style="text-align:left;">Notas</label>
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <b-input type="textarea"
                                        placeholder="Ingrese cualquier información adicional aquí"
                                        :value='agreementRecordNotes!==undefined ? agreementRecordNotes : ""'
                                        v-model="agreementRecordNotes"></b-input>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="field">
                            <div class="field-label is-normal">
                                <label class="label" style="text-align:left;">Fecha evento*</label>
                            </div>
                            <b-field
                                :type="{ 'is-danger': checkAgreementRecordDate }"
                                :message="{ 'Ingrese una fecha.': checkAgreementRecordDate }">
                                <b-datepicker 
                                    placeholder="Seleccione la fecha cuando ocurrió el evento"
                                    icon="calendar-alt"
                                    :value='agreementRecordRegistrationDate' 
                                    v-model="agreementRecordRegistrationDate">
                                </b-datepicker>
                            </b-field>
                        </div>
                        <br>
                        <div class="field">
                            <div class="field-label is-normal">
                                <label class="label" style="text-align:left;">Etapa*</label>
                            </div>
                            <b-field
                                :type="{ 'is-danger': checkAgreementRecordStage }"
                                :message="{ 'Seleccione una etapa.': checkAgreementRecordStage }">
                                <b-select placeholder="Seleccione una etapa" 
                                        :value="agreementRecordStageName!==undefined ? agreementRecordStageName : ''"
                                        v-model="agreementRecordStageName"
                                        expanded>
                                        <option v-for="stage in agreement_history_stages" :key="stage.id">
                                            {{ stage.name }}
                                        </option>
                                </b-select>
                            </b-field>
                        </div>
                        <br>
                        <div class="field">
                            <div class="field-label is-normal">
                                <label class="label" style="text-align:left;">Proceso de la etapa*</label>
                            </div>
                            <b-field
                                :type="{ 'is-danger': checkAgreementRecordProcess }"
                                :message="{ 'Seleccione un proceso.': checkAgreementRecordProcess }">
                                <b-select placeholder="Seleccione un proceso" 
                                        v-model="agreementRecordProcessName" 
                                        :value="agreementRecordProcessName!==undefined ? agreementRecordProcessName : ''"
                                        expanded>
                                        <option v-for="process in filtered_agreement_processes" :key="process.id">
                                            {{ process.name }}
                                        </option>
                                </b-select>
                            </b-field>
                        </div>
                        <br>
                        <div class="field">
                            <div class="field-label is-normal">
                                <label class="label" style="text-align:left;">Actor*</label>
                            </div>
                            <b-field
                                :type="{ 'is-danger': checkAgreementRecordActor }"
                                :message="{ 'Seleccione un actor.': checkAgreementRecordActor }">
                                <b-select placeholder="Seleccione un actor" 
                                        v-model="agreementRecordActorName" 
                                        :value="agreementRecordActorName!==undefined ? agreementRecordActorName : ''"
                                        expanded>
                                        <option v-for="actor in agreement_history_actors" :key="actor.id">
                                            {{ actor.name }}
                                        </option>
                                </b-select>
                            </b-field>
                        </div>
                   </div>
               </div>
               <div class="field is-grouped is-grouped-right">
                    <div class="control">
                        <b-button class="button is-primary" @click="save()">Registrar</b-button>
                    </div>
                    <div class="control">
                        <b-button class="button is-link is-light" @click="back()">Volver</b-button>
                    </div>
                </div>
           </div>
       </div>
      <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
    </div>
</template>
<script>
import axios from "axios"
export default {
    name: 'AgreementsHistoryNewForm',
    props:{
        agreement_id:{
            type: Number
        },
        agreement_history_stages:{
            type: Array
        },
        agreement_history_processes:{
            type: Array
        },
        agreement_history_actors:{
            type: Array
        }
    },
    data(){
        return {
            // Para e control de flujo del formulario
            isSubmiting: false,
            isLoading: false,
            isFullPage: true,
            // Para el almacenamiento temporal
            agreement_stage_name: null,
            agreement_process_name: null,
            agreement_actor_name: null,
            filtered_agreement_processes: [],

            // Para el envío de datos
            agreement_record:{
                notes: undefined,
                registration_date: null,
                agreement_id: 0,
                agreement_process_id: 0,
                agreement_actor_id: 0,
            },

            // Para validaciones
            validations:{
                isAgreementRecordRegistrationDateEmpty: true,
                isAgreementRecordStageNameEmpty: true,
                isAgreementRecordProcessNameEmpty: true,
                isAgreementRecordActorNameEmpty: true,
                firstTimeAgreementRecordRegistrationDate: true,
                firstTimeAgreementRecordStageName: true,
                firstTimeAgreementRecordProcessName: true,
                firstTimeAgreementRecordActorName: true,
            }
        }
    },
    computed:{
        // Para el manejo de atributos
        agreementRecordNotes:{
            get: function(){
                return this.agreement_record.notes
            },
            set: function(newNote){
                this.agreement_record.notes = newNote 
            }
        },
        agreementRecordRegistrationDate:{
            get: function(){
                return this.agreement_record.registration_date
            },
            set: function(newDate){
                this.validations.firstTimeAgreementRecordRegistrationDate = false
                console.log(newDate)
                if(newDate == null){
                    this.validations.isAgreementRecordRegistrationDateEmpty = false
                }
                else{
                    this.validations.isAgreementRecordRegistrationDateEmpty = false
                }
                this.agreement_record.registration_date = newDate 
            }
        },
        agreementRecordStageName:{
            get: function(){
                return this.agreement_stage_name
            },
            set: function(newStage){
                this.validations.firstTimeAgreementRecordStageName = false
                if(newStage === ''){
                    this.validations.isAgreementRecordStageNameEmpty = true
                    this.filtered_agreement_processes = []
                }
                else{
                    this.validations.isAgreementRecordStageNameEmpty = false
                    var selected_agreement_stage = ''
                    this.agreement_history_stages.forEach(function(element){
                        if(element.name === newStage){
                            selected_agreement_stage = element
                        }
                    })
                    this.filtered_agreement_processes = this.agreement_history_processes.filter(
                        element => element.agreement_stage_id === selected_agreement_stage.id
                    )
                }
                this.agreement_stage_name = newStage 
            }
        },
        agreementRecordProcessName:{
            get: function(){
                return this.agreement_process_name
            },
            set: function(newName){
                this.validations.firstTimeAgreementRecordProcessName = false
                if(newName == '')
                    this.validations.isAgreementRecordProcessNameEmpty = true
                else
                    this.validations.isAgreementRecordProcessNameEmpty = false
                this.agreement_process_name = newName 
            }
        },
        agreementRecordActorName:{
            get: function(){
                return this.agreement_actor_name
            },
            set: function(newActorName){
                this.validations.firstTimeAgreementRecordActorName = false
                if(newActorName == '')
                    this.validations.isAgreementRecordActorNameEmpty = true
                else
                    this.validations.isAgreementRecordActorNameEmpty = false
                this.agreement_actor_name = newActorName
            }
        },
        // Para las validaciones del formulario
        checkAgreementRecordDate(){
            if(this.validations.firstTimeAgreementRecordRegistrationDate === false)
                return this.validations.isAgreementRecordRegistrationDateEmpty
            else
                return false
        },
        checkAgreementRecordStage(){
            if(this.validations.firstTimeAgreementRecordStageName === false)
                return this.validations.isAgreementRecordStageNameEmpty
            else
                return false
        },
        checkAgreementRecordProcess(){
            if(this.validations.firstTimeAgreementRecordProcessName === false)
                return this.validations.isAgreementRecordProcessNameEmpty
            else
                return false
        },
        checkAgreementRecordActor(){
            if(this.validations.firstTimeAgreementRecordActorName === false)
                return this.validations.isAgreementRecordActorNameEmpty
            else
                return false
        },
        // Para el control del flujo del formulario
        checkSubmiting: {
            get: function(){
                return this.isSubmiting
            },
            set: function(newState){
                this.isSubmiting = newState
            }
        }
    },
    methods:{
        getStageIdByStageName(name){
            var id = 0
            this.agreement_history_stages.forEach(function(element){
                if(element.name === name){
                    id = element.id
                }
            });
            return id
        },
        getProcessIdByProcessName(name){
            var id = 0
            this.agreement_history_processes.forEach(function(element){
                if(element.name === name){
                    id = element.id
                }
            });
            return id
        },
        getActorIdByActorName(name){
            var id = 0
            this.agreement_history_actors.forEach(function(element){
                if(element.name === name){
                    id = element.id
                }
            });
            return id
        },
        checkEmptyFields(){
            if(this.validations.firstTimeAgreementRecordRegistrationDate === true){
                this.validations.firstTimeAgreementRecordRegistrationDate = false
            }
            if(this.validations.firstTimeAgreementRecordStageName === true){
                this.validations.firstTimeAgreementRecordStageName = false
            }
            if(this.validations.firstTimeAgreementRecordProcessName === true){
                this.validations.firstTimeAgreementRecordProcessName = false
            }
            if(this.validations.firstTimeAgreementRecordActorName === true){
                this.validations.firstTimeAgreementRecordActorName = false
            }
        },
        checkSave(){
            var confirmation = true
            var arrayConfirmation = []
            arrayConfirmation.push(this.validations.isAgreementRecordRegistrationDateEmpty)
            arrayConfirmation.push(this.validations.isAgreementRecordStageNameEmpty)
            arrayConfirmation.push(this.validations.isAgreementRecordProcessNameEmpty)
            arrayConfirmation.push(this.validations.isAgreementRecordActorNameEmpty)
            arrayConfirmation.forEach(function(element){
                if(element === true)
                    confirmation = false
            })
            return confirmation
        },
        save(){
            this.checkSubmiting = true
            this.isLoading = true
            var confirmation = this.checkSave()
            if(confirmation){
                this.agreement_record.agreement_id          = this.agreement_id
                this.agreement_record.agreement_process_id  = this.getProcessIdByProcessName(this.agreement_process_name)
                this.agreement_record.agreement_actor_id    = this.getActorIdByActorName(this.agreement_actor_name)
                axios.post('/agreement_histories.json', this.agreement_record)
                .then( response =>{
                    this.$buefy.dialog.alert({
                        title: "Antecedente registrado con éxito",
                        message: "El antecedente ha sido registrado sin ningún problema.",
                        onConfirm: () => {
                            this.checkSubmiting = false
                            this.isLoading = false
                            window.location.href="/agreements/"+this.agreement_id
                        }
                    })
                  this.isLoading = false
                })
                .catch(e => {
                    console.log(e)
                })
            }
            else{
                this.checkEmptyFields()
                this.$buefy.dialog.alert({
                    title: "Error al registrar un antecendente.",
                    message: "Faltan campos de este formulario por rellenar.",
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.checkSubmiting = false
                        this.isLoading = false
                    }
                })
            }
        },
        back(){
            window.location.href="/agreements/"+this.agreement_id
        }
    }
}
</script>