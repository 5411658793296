<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(savePersonFunction)">
        <!-- Box de Formulario HouseholdAllowance -->
        <div class="modal-card" style="width: auto" id="modal-person-function">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" id="modal-person-function" ref="modalPersonFunction">
            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion" :key='index'>
                      {{ item[0] }}
                    </div>
                  </b-message>
                </div>
              </div>
            </div>
            <div class="columns">
              <h3 class="is-size-3 has-text-weight-bold">Información Personal y Nombramiento</h3>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Nombres">
                  <b-input :value="person.nombre_completo!==undefined ? person.nombre_completo : ''" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column is-4">
                <b-field label="Rut">
                  <b-input :value="person.run_attributes!==undefined ? person.run_attributes.rut : ''" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Dv">
                  <b-input :value="person.run_attributes!==undefined ? person.run_attributes.dv : ''" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Seleccionar Nombramiento">
                  <multiselect v-model="nombramiento"
                               placeholder="Seleccione Nombramiento"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione nuevamente para deseleccionar"
                               :allow-empty="true"
                               :options="options"
                               :multiple="false"
                               group-values="person_units"
                               group-label="type"
                               label="custom_name"
                               :group-select="false"
                               expanded>
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                          No se encontraron elementos.
                      </span>
                  </multiselect>
                </b-field>
                <p class="control">
                  <b-button class="mt-2" @click.prevent="isShowPersonUnitActive = true" type="is-primary" :disabled="nombramiento===undefined||nombramiento===null">Mostrar Nombramiento</b-button>
                </p>
              </div>
            </div>
            <div class="columns">
              <h3 class="is-size-3 has-text-weight-bold">Información Función Administrativa </h3>
            </div>
            <h3 class="is-size-6 has-text-weight-bold">Función*
              <b-tooltip
                  label="Presione + para añadir una función"
                  position="is-right"
                  size="is-medium"
                  multilined>

                <b-icon
                    pack="fas"
                    icon="info-circle"
                    size="is-small"
                    type="is-info">
                </b-icon>
              </b-tooltip>
            </h3>
            <div class="columns">
              <div class="column">
                <b-field label=" ">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-taginput
                        v-model="person_function.function_name"
                        :data="funcion_filtrada"
                        type="is-primary is-light"
                        autocomplete
                        :allow-new="false"
                        field="name"
                        icon="tag"
                        maxtags="1"
                        placeholder="Escoger una función"
                        @typing="getFilteredTags">
                    </b-taginput>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column is-narrow">
                <b-button class="is-primary is-outlined" @click="addFunction()">
                  <b-icon icon="plus"></b-icon>
                </b-button>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input v-model="person_function.description" type="textarea" minlength="1" maxlength="255"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Unidad de desempeño*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_function.unit_id"
                                 :options="units.map(type => type.id)"
                                 :custom-label="opt => units.find(x => x.id === opt).name"
                                 placeholder="Seleccione una unidad"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de documento">
                  <multiselect v-model="person_function.resolution_type_id"
                               :options="resolution_types.map(type => type.id)"
                               :custom-label="opt => resolution_types.find(x => x.id === opt).name"
                               placeholder="Seleccione tipo de resolución"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                               :allow-empty="true"
                               @input="generateName">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Número de documento">
                  <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                    <b-input v-model="person_function.resolution" type="text" @input="generateName"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de documento">
                  <b-datepicker
                      v-model="person_function.resolution_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                      @input="generateName"
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Fecha de inicio*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-datepicker
                        v-model="person_function.start_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"

                    >
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de término">
                    <b-datepicker
                        v-model="person_function.end_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                    >
                    </b-datepicker>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Jornada (horas semanales)">
                  <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                    <b-input v-model="person_function.hours" type="text" ></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
<!--            <div class="columns">
              <div class="column">
                <b-field label="Url carpeta documentación Función">
                  <ValidationProvider v-slot="{ errors }" rules="max:255">
                    <b-input v-model="person_function.documentation_link" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>-->
            <div v-if="queryType == 'update'" class="columns">
              <div class="column is-4">
                <b-field label="Nombre del documento">
                  <b-input v-model="document_name_update" type="text" :disabled="true"></b-input>
                </b-field>
              </div>
              <div class="column is-4">
                <b-field label="¿Desea cambiar o agregar un archivo?">
                  <b-radio-button v-model="changeFile"
                                  :native-value="true"
                                  type="is-success is-light is-outlined">
                    <b-icon icon="check-circle"></b-icon>
                    <span>Si</span>
                  </b-radio-button>
                  <b-radio-button v-model="changeFile"
                                  :native-value="false"
                                  type="is-danger is-light is-outlined">
                    <b-icon icon="times-circle"></b-icon>
                    <span>No</span>
                  </b-radio-button>
                </b-field>
              </div>
            </div>
            <div v-if="queryType == 'create' || changeFile" class="columns has-text-centered">
              <div class="column">
                <b-field extended label="Archivo PDF (Tamaño max. 25 MB)">
                  <b-upload v-model="file"
                            drag-drop
                            type="is-primary"
                            accept=".pdf"
                            @input="generateName">
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon
                              icon="file-pdf"
                              size="is-large">
                          </b-icon>
                        </p>
                        <p>Arrastra tu PDF o haz click para subir.</p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>
              </div>
            </div>
            <div v-if="queryType == 'create' || changeFile" class="columns tags is-centered">
              <span
                  v-if="file !== null"
                  v-model="file"
                  class="tag is-primary mt-1">
                {{ file.name }}
                <button
                    class="delete is-small"
                    type="button"
                    @click="onDeleteFile"
                ></button>
              </span>
            </div>
            <div v-if="file !== null" class="columns">
              <div class="column">
                <b-field label="Nombre del documento a subir">
                  <b-input disabled v-model="document_name"></b-input>
                </b-field>
              </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit">Guardar </button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

    <b-modal
        v-model="isShowPersonUnitActive"
        :destroy-on-hide="true">
      <template #default="props">
        <info-appointment
            modal-header="Nombramiento Asociado"
            :nombramiento = "nombramiento"
            @close="props.close"
        ></info-appointment>
      </template>
    </b-modal>
    <b-modal
        v-model="isAddModalFunctionActive"
        :destroy-on-hide="true"
        :width="1080"
        :can-cancel="canCancel">
      <template #default="props">
        <modal-function-form
            query-type="create"
            modal-header="Añadir nueva función"
            @close="props.close"
            @clicked="onClickChild"></modal-function-form>
      </template>
    </b-modal>
  </section>
</template>

<script>
import axios from "axios"
import InfoAppointment from "../commissions/info_appointment"
import ModalFunctionForm from "../../functions/modal_function_form"
export default {
  name: "modal_administrative_function_form",
  props: [
    'queryType', 'personId', 'id','modalHeader', 'person_function_type_id'
  ],
  components: {
    InfoAppointment,
    ModalFunctionForm
  },
  data(){
    return {
      person_function: {},
      errores_validacion:{},
      resolution_types: [],
      person_function_type: {},
      functions: [],
      units: [],
      isLoading: false,
      funcion_filtrada: [],
      function_name: null,
      isShowPersonUnitActive: false,
      nombramiento: null,
      person: {},
      canCancel: [],
      options: [{type: 'Inactivos', person_units: []}, {type: 'Activos', person_units: []}],
      isAddModalFunctionActive: false,
      file: null,
      document_name: null,
      changeFile: false,
      document_name_update: null,
      document_id: null,
    }
  },
  created() {
    this.isLoading=true;
    if (this.queryType === "create"){
      this.person_function.person_id = this.personId
      axios
          .get("/person_functions/new.json", {params: {
              person_id: this.personId,
              person_function_type_id: this.person_function_type_id
            }})
          .then(res => {

            this.resolution_types = res.data["resolution_types"]
            //this.person_function_type = res.data["person_function_type"]
            this.person_function.person_function_type_id = this.person_function_type_id //administrativo
            this.functions = res.data["functions"]
            this.units = res.data["units"]
            if(res.data["units"].length===0){
              this.$buefy.dialog.alert({
                message: 'No se puede asignar una función para una persona que no tenga nombramientos',
                confirmText: 'Ok',
                type: 'is-danger'
              })
              this.isLoading=false;
              this.$emit('close')
            }
            axios
                .get("/admin/commissions/"+this.personId+"/person_commission.json",
                ).then(res => {
              this.person = res.data["person"]
              var i = 0
              for(i = 0; i<res.data["nombramientos_activos"].length;i++){
                this.options[1].person_units.push(res.data["nombramientos_activos"][i])
              }
              this.nombramiento = res.data["nombramientos_activos"].find(x => x.estamento === "ACADEMICOS");
              for(i = 0; i<res.data["nombramientos_inactivos"].length;i++){
                this.options[0].person_units.push(res.data["nombramientos_inactivos"][i])
              }

              this.isLoading=false;
            }).catch(error => {
              console.log(error)
              this.isLoading=false;
            })
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }
    else if (this.queryType === 'update'){
      axios
          .get("/person_functions/"+this.id+"/edit.json")
          .then(res => {
            this.person_function = res.data
            this.resolution_types = res.data["resolution_types"]
            //this.person_function_type = res.data["person_function_type"]
            this.nombramiento = res.data["nombramiento"]
            this.person = res.data["person"]
            this.functions = res.data["functions"]
            this.units = res.data["units"]
            if(res.data["units"].length===0){
              this.$buefy.dialog.alert({
                message: 'No se puede asignar una función para una persona que no tenga nombramientos',
                confirmText: 'Ok',
                type: 'is-danger'
              })
              this.isLoading=false;
              this.$emit('close')
            }
            if(res.data["start_date"]!==null){
              this.person_function.start_date = new Date(this.formatDate(res.data["start_date"]))
            }
            if(res.data["end_date"]!==null){
              this.person_function.end_date = new Date(this.formatDate(res.data["end_date"]))
            }
            if(res.data["resolution_date"]!==null){
              this.person_function.resolution_date = new Date(this.formatDate(res.data["resolution_date"]))
            }
            if(this.person_function.function){
              this.person_function.function_name = [this.person_function.function]
            }
            var i = 0;
            for(i = 0; i<res.data["nombramientos_activos"].length;i++){
              this.options[1].person_units.push(res.data["nombramientos_activos"][i])
            }
            for(i = 0; i<res.data["nombramientos_inactivos"].length;i++){
              this.options[0].person_units.push(res.data["nombramientos_inactivos"][i])
            }
            this.document_id =  res.data["document_id"]
            this.document_name_update = res.data["document_name"]
            this.document_name = res.data["document_name"]
            this.isLoading = false;
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }
  },
  methods: {
    addFunction(){
      this.isAddModalFunctionActive = true
    },
    onClickChild(){
      this.$buefy.toast.open({
        message: 'La función se agregó correctamente, puede buscarla y agregarla',
        type: 'is-success',
        duration: 5000,
      })
      this.getFunctions()
    },
    getFunctions(){
      this.isLoading = true
      axios
          .get("/functions/get-functions-by-type.json", {params: {type: this.person_function_type_id}})
          .then(response=>{
            this.functions = response.data
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
          })
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
    savePersonFunction(){
      this.isLoading=true;
      if(this.nombramiento){
        this.person_function.person_unit_id = this.nombramiento.id
      }
      if(this.person_function.function_name){
        if(this.person_function.function_name.length>0){
          if(!this.person_function.function_name[0].name){

            const name = this.person_function.function_name[0]
            this.person_function.function_name = [
              {
                id: null,
                name: name,
              }
            ]
          }
        }
      }
      if (this.queryType==="create"){
        if (this.file != null && this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Este archivo no tiene formato PDF",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        if (this.file != null && this.file.size > 1024*1024*25) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "El archivo no puede superar el maximo de 25 MB",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        if (this.file != null && (this.person_function.resolution_date ==  null || this.person_function.resolution_date ==  undefined) &&
            (this.person_function.resolution == null || this.person_function.resolution == undefined || this.person_function.resolution == '') &&
            (this.person_function.resolution_type_id == null || this.person_function.resolution_type_id == undefined)) {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Para adjuntar un archivo, debe llenar al menos uno de los campos del documento",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        this.person_function.person_id = this.personId;

        // Crea un objeto FormData
        let formData = new FormData();

        // Agrega datos al objeto FormData
        formData.append("person_function", JSON.stringify(this.person_function));
        formData.append("function_name", JSON.stringify(this.person_function.function_name[0]));
        formData.append("file", this.file); // Aquí agregamos el archivo
        formData.append("document_name", this.document_name);

        axios
            .post("/person_functions/create-document.json", formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
            .then(response =>{
              this.errores_validacion = {};
              this.$buefy.dialog.alert({
                message: 'La función fue agregada con éxito ',
                confirmText: 'Ok'
              })
              this.person_unit = {}
              this.isLoading=false;
              this.errors = [];
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalPersonFunction.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al agregar la función, favor revisar formato y verificar que función asociada no se encuentre anulada',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
        })
      }
      else if (this.queryType==="update"){
        if (this.file != null && this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Este archivo no tiene formato PDF",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        if (this.file != null && this.file.size > 1024*1024*25) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "El archivo no puede superar el maximo de 25 MB",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        if (this.file != null && (this.person_function.resolution_date ==  null || this.person_function.resolution_date ==  undefined) &&
            (this.person_function.resolution == null || this.person_function.resolution == undefined || this.person_function.resolution == '') &&
            (this.person_function.resolution_type_id == null || this.person_function.resolution_type_id == undefined)) {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Para adjuntar un archivo, debe llenar al menos uno de los campos del documento",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        let clean_person_function= this.person_function
        clean_person_function = this.deleteData(clean_person_function)
        // Crea un objeto FormData
        let formData = new FormData();

        // Agrega datos al objeto FormData
        formData.append("person_function", JSON.stringify(clean_person_function));
        formData.append("function_name", JSON.stringify(this.person_function.function_name[0]));
        formData.append("file", this.file); // Aquí agregamos el archivo
        formData.append("document_name", this.document_name);
        axios
            .put("/person_functions/"+this.person_function.id+"/update-document.json", formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
            .then(response =>{
              this.errores_validacion = {};
              this.$buefy.dialog.alert({
                message: 'La función fue editada con éxito ',
                confirmText: 'Ok'
              })
              this.person_unit = {}
              this.isLoading=false;
              this.errors = [];
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalPersonFunction.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al editar la función, favor revisar formato y verificar que función asociada no se encuentre anulada',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
        })
      }
    },
    getFilteredTags(text) {
      this.funcion_filtrada = this.functions.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    onDeleteFile() {
      this.file = null;
    },
    generateName(){
      var tipo = ""
      var centroCosto = ""
      var number = ""
      var date = ""

      if (this.person_function.resolution_type_id!= null){
        var nombre_tipo = this.resolution_types.find(x => x.id === this.person_function.resolution_type_id).name
        if(nombre_tipo.includes("RESOLUCION")){
          tipo = "RES"
        }
        else if(nombre_tipo.includes("DECRETO")){
          tipo = "DECR"
        }
        else{
          tipo = "SIN_INFO"
        }
        //tipo = this.resolution_types.find(x => x.id === this.person_unit.resolution_type_id).name
        //tipo = this.documentTypesNames.find(type => type.id === this.document.document_type_id).initials
      }

      if (this.person_function.unit_id != null){
        centroCosto = "-CC_" + this.units.find(x => x.id == this.person_function.unit_id).cost_center
      }

      if (this.person_function.resolution != null && this.person_function.resolution != ''){
        number = "-N_" + this.person_function.resolution
      }

      if (this.person_function.resolution_date != null){
        date = "-"+("0" + this.person_function.resolution_date.getDate()).slice(-2)
            + "_" + ("0" + (this.person_function.resolution_date.getMonth()+1)).slice(-2)
            + "_" + this.person_function.resolution_date.getFullYear()
      }
      this.document_name = tipo + number + centroCosto + date
    },
    deleteData(person_function){
      delete person_function.resolution_type
      delete person_function.function
      delete person_function.unit
      delete person_function.person_unit
      delete person_function.resolution_types
      delete person_function.functions
      delete person_function.units
      delete person_function.person
      delete person_function.nombramiento
      delete person_function.nombramientos_activos
      delete person_function.nombramientos_inactivos
      delete person_function.document_id
      delete person_function.document_name
      delete person_function.can_view_pdf

      return person_function
    },

  }
}
</script>