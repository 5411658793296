<template>
    <div class="configurationactivityassistanttypenewmodal">
        <form action="">
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title" v-if='edit_case == 0'>Nuevo tipo de participante</p>
                    <p class="modal-card-title" v-if='edit_case == 1'>Editar tipo de participante</p>
                    <button
                        type="button"
                        class="delete"
                        @click="$emit('close')"/>
                </header>
                <section class="modal-card-body" v-if='edit_case == 0'>
                    <b-field label="Nombre"
                        :type="{ 'is-danger': checkActivityAssistantTypeName }"
                        :message="{ 'Ingrese un nombre para el tipo de participante.': checkActivityAssistantTypeName }">
                        <b-input 
                            v-model='activityAssistantTypeName'
                            maxlength="255"
                            placeholder="Nombre del tipo de participante"
                            required>
                        </b-input>
                    </b-field>

                    <b-field label="Tipo de participante">
                        <b-select placeholder="Seleccione un tipo de participante" 
                                  v-model='activityAssistantTypeAssistantType'
                                  :value='activityAssistantTypeAssistantType' 
                                  expanded>
                            <option> Persona </option>
                            <option> Entidad </option>
                        </b-select>
                    </b-field>

                    <b-field label="Descripción"
                        :type="{ 'is-danger': checkActivityAssistantTypeDescription }"
                        :message="{ 'Ingrese una descripción para el tipo de participante.': checkActivityAssistantTypeDescription }">
                        <b-input
                            type="textarea"
                            v-model='activityAssistantTypeDescription'
                            maxlength="255"
                            placeholder="Descripción del tipo de participante"
                            required>
                        </b-input>
                    </b-field>
                </section>
                <section class="modal-card-body" v-if='edit_case == 1'>
                    <b-field label="Nombre"
                        :type="{ 'is-danger': checkActivityAssistantTypeNameEdit }"
                        :message="{ 'Ingrese un nombre para el tipo de participante.': checkActivityAssistantTypeNameEdit }">
                        <b-input 
                            :value='activityTypeAssistantNameEdit'
                            v-model='activityTypeAssistantNameEdit'
                            maxlength="255"
                            placeholder="Nombre del tipo de participante"
                            required>
                        </b-input>
                    </b-field>

                    <b-field label="Tipo de participante">
                        <b-select placeholder="Seleccione un tipo de participante" 
                                  v-model='activityAssistantTypeAssistantTypeEdit'
                                  :value='activityAssistantTypeAssistantTypeEdit' 
                                  expanded>
                            <option> Persona </option>
                            <option> Entidad </option>
                        </b-select>
                    </b-field>
                    
                    <b-field label="Descripción"
                        :type="{ 'is-danger': checkActivityAssistantTypeDescriptionEdit }"
                        :message="{ 'Ingrese una descripción para el tipo de participante.': checkActivityAssistantTypeDescriptionEdit }">
                        <b-input
                            type="textarea"
                            :value='activityTypeAssistantDescriptionEdit'
                            v-model='activityTypeAssistantDescriptionEdit'
                            maxlength="255"
                            placeholder="Descripción del tipo de participante."
                            required>
                        </b-input>
                    </b-field>
                </section>
                <footer class="modal-card-foot">
                    <b-button
                        label="Cerrar"
                        @click="closeModal()" />
                    <b-button v-if='edit_case == 0'
                        label="Añadir"
                        type="is-primary" 
                        @click="addNewActivityAssistantType()"
                        :loading='checkSubmiting'/>
                    <b-button v-if='edit_case == 1'
                        label="Guardar cambios"
                        type="is-primary" 
                        @click="editActivityAssistantType()"
                        :loading='checkSubmiting'/>
                </footer>
            </div>
        </form>
    </div>
</template>
<script>
export default {
    name: 'ConfigurationActivityAssistantTypeNewModal',
    props:{
        activity_assistant_type_edit:{
            type: Object
        },
        edit_case:{
            type: Number
        }
    },
    data(){
        return {
            new_activity_assistant_type:{
                name: '',
                description: '',
                assistant_type: 0
            },
            name: '',
            description: '',
            validations:{
                isActivityAssistantTypeNameEmpty: true,
                isActivityAssistantTypeDescriptionEmpty: true,
                firstTimeActivityAssistantTypeName: true,
                firstTimeActivityAssistantTypeDescription: true
            },
            isSubmiting: false
        }
    },
    computed:{
        // Añadir
        activityAssistantTypeName:{
            get: function(){
                return this.new_activity_assistant_type.name
            },
            set: function(newActivityAssistantTypeName){
                this.validations.firstTimeActivityAssistantTypeName   = false
                if(newActivityAssistantTypeName === '')
                    this.validations.isActivityAssistantTypeNameEmpty = true
                else
                    this.validations.isActivityAssistantTypeNameEmpty = false
                this.new_activity_assistant_type.name = newActivityAssistantTypeName
            }
        },
        activityAssistantTypeDescription:{
            get: function(){
                return this.new_activity_assistant_type.description
            },
            set: function(newActivityAssistantTypeDescription){
                this.validations.firstTimeActivityAssistantTypeDescription = false
                if(newActivityAssistantTypeDescription === '')
                    this.validations.isActivityAssistantTypeDescriptionEmpty = true
                else
                    this.validations.isActivityAssistantTypeDescriptionEmpty = false
                this.new_activity_assistant_type.description = newActivityAssistantTypeDescription
            }
        },
        activityAssistantTypeAssistantType:{
            get: function(){
                if(this.new_activity_assistant_type.assistant_type === 0)
                    return 'Persona'
                else
                    return 'Entidad'
            },
            set: function(newActivityAssistantTypeAssistantType){
                this.validations.firstTimeActivityAssistantTypeDescription = false
                if(newActivityAssistantTypeAssistantType === 'Persona')
                    this.new_activity_assistant_type.assistant_type = 0
                else
                    this.new_activity_assistant_type.assistant_type = 1
            }
        },
        checkActivityAssistantTypeName(){
            if(this.validations.firstTimeActivityAssistantTypeName === false)
                return this.validations.isActivityAssistantTypeNameEmpty
            else
                return false
        },
        checkActivityAssistantTypeDescription(){
            if(this.validations.firstTimeActivityAssistantTypeDescription === false)
                return this.validations.isActivityAssistantTypeDescriptionEmpty
            else
                return false
        },
        // Editar
        activityTypeAssistantNameEdit:{
            get: function(){
                return this.activity_assistant_type_edit.name
            },
            set: function(newActivityAssistantTypeName){
                if(newActivityAssistantTypeName === '')
                    this.validations.isActivityAssistantTypeNameEmpty = true
                else
                    this.validations.isActivityAssistantTypeNameEmpty = false
                this.activity_assistant_type_edit.name = newActivityAssistantTypeName
            }
        },
        activityTypeAssistantDescriptionEdit:{
            get: function(){
                return this.activity_assistant_type_edit.description
            },
            set: function(newActivityAssistantTypeDescription){
                if(newActivityAssistantTypeDescription === '')
                    this.validations.isActivityAssistantTypeDescriptionEmpty = true
                else
                    this.validations.isActivityAssistantTypeDescriptionEmpty = false
                this.activity_assistant_type_edit.description = newActivityAssistantTypeDescription
            }
        },
        activityAssistantTypeAssistantTypeEdit:{
            get: function(){
                if(this.activity_assistant_type_edit.assistant_type === 0)
                    return 'Persona'
                else
                    return 'Entidad'
            },
            set: function(newActivityAssistantTypeAssistantType){
                this.validations.firstTimeActivityAssistantTypeDescription = false
                if(newActivityAssistantTypeAssistantType === 'Persona')
                    this.activity_assistant_type_edit.assistant_type = 0
                else
                    this.activity_assistant_type_edit.assistant_type = 1
            }
        },
        checkActivityAssistantTypeNameEdit(){
            return this.validations.isActivityAssistantTypeNameEmpty
        },
        checkActivityAssistantTypeDescriptionEdit(){
            return this.validations.isActivityAssistantTypeDescriptionEmpty
        },
        checkSubmiting: {
            get: function(){
                return this.isSubmiting
            },
            set: function(newState){
                this.isSubmiting = newState
            }
        }
    },
    beforeMount(){
        this.name = this.activity_assistant_type_edit.name
        this.description = this.activity_assistant_type_edit.description
        if(this.edit_case === 1){
            this.validations.isActivityAssistantTypeNameEmpty        = false
            this.validations.isActivityAssistantTypeDescriptionEmpty = false
        }
    },
    methods:{
        checkEmptyFields(){
            if(this.validations.firstTimeActivityAssistantTypeName === true){
                this.validations.firstTimeActivityAssistantTypeName = false
            }
            if(this.validations.firstTimeActivityAssistantTypeDescription === true){
                this.validations.firstTimeActivityAssistantTypeDescription = false
            }
        },
        checkSave(){
            var confirmation = true
            var arrayConfirmation = []
            arrayConfirmation.push(this.validations.isActivityAssistantTypeNameEmpty)
            arrayConfirmation.push(this.validations.isActivityAssistantTypeDescriptionEmpty)
            arrayConfirmation.forEach(function(element){
                if(element === true)
                    confirmation = false
            })
            return new Promise((resolve) => {
                resolve(confirmation)
            })
        },
        async addNewActivityAssistantType(){
            this.checkSubmiting = true
            var confirmacion    = await this.checkSave()
            if(confirmacion){
                this.$emit('add-new-activity-assistant-type', this.new_activity_assistant_type);
                this.$emit('close');
            }
            else{
                this.checkEmptyFields();
                this.checkSubmiting = false
            }
        },
        async editActivityAssistantType(){
            this.checkSubmiting = true
            var confirmacion    = await this.checkSave()
            if(confirmacion){
                this.$emit('edit-activity-assistant-type', this.activity_assistant_type_edit);
                this.$emit('close');
                this.checkSubmiting = false
            }
            else{
                this.checkEmptyFields();
                this.checkSubmiting = false
            }
        },
        closeModal(){
            if(this.edit_case === 1){
                this.activityTypeAssistantNameEdit        = this.name
                this.activityTypeAssistantDescriptionEdit = this.description
                this.$emit('close');
            }
            else{
                this.$emit('close');
            }
        }
    }
}
</script>