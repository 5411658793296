<template>
  <section>
    <br>
    <div v-if="cargoPrincipal" class="container box">
      <div class="columns">
        <div class="column">
          <b-tabs position="is-centered" class="block">
            <b-tab-item v-if="cargoPrincipal && cargoPrincipal.name!='ACADEMICO'" label="Búsqueda por Personas">
              <div class="columns">
                <div class="column">
                  <b-field label="Ingresa nombre de la persona a buscar">
                    <b-input @keyup.enter.native="searchByPerson" v-model="personName" ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-button style="margin-top:1.1em" type="is-success is-light"
                            size="is-medium"
                            icon-left="user"
                            @click="searchByPerson"
                            icon-pack="fas">
                    Buscar por nombre de persona
                  </b-button>
                </div>
              </div>
              <b-table v-if="hasSearchedPeople"
                       :data="people"
                       :paginated=true
                       per-page=10
                       striped
                       hoverable
                       :current-page="currentPage"
                       :pagination-simple=false
                       pagination-position="bottom"
                       default-sort-direction="asc"
                       sort-icon="arrow-up"
                       sort-icon-size="is-small"
                       aria-next-label="Siguiente"
                       aria-previous-label="Anterior"
                       aria-page-label="Página"
                       aria-current-label="Página actual"
              >
                <b-table-column
                    field= 'name'
                    label= 'Nombre'
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    <a target="_blank" :href="getPersonUrl(props.row.person_id)">{{ props.row.name }}</a>
                  </template>
                </b-table-column>
                <b-table-column
                    field= 'unit'
                    label= 'Unidad'
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.unit }}
                  </template>
                </b-table-column>
                <b-table-column
                    field= 'orcid'
                    label= 'ORCID'
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    <div class="columns">
                      <div class="column">
                        <ValidationProvider rules="max:250" v-slot="{ errors }">
                          <b-input v-model="props.row.orcid" />
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>

                      </div>
                      <div class="column is-narrow">
                        <b-button type="is-success" @click.prevent="confirmAddOrcid(props.row)"
                                  icon-right="save" />
                      </div>
                    </div>
                  </template>
                </b-table-column>
                <b-table-column
                    cell-class="has-text-right"
                    v-slot="props"
                    field= 'actions'
                    label= 'Acciones'>
                  <b-button v-if="props.row.orcid" type="is-link" @click.prevent="personOrcidSearch(props.row)"
                            icon-right="search" />

                </b-table-column>

                <template slot="empty" slot-scope="props">
                  <div class="columns is-centered">
                    <div class="column is-6 is-centered has-text-centered">
                      <b-message type="is-danger">
                        No se encontraron resultados.
                      </b-message>
                    </div>
                  </div>
                </template>
              </b-table>
            </b-tab-item>

            <b-tab-item v-if="cargoPrincipal && cargoPrincipal.name!='ACADEMICO'" label="Búsqueda por ORCID">
              <div class="columns">
                <div class="column">
                  <b-field label="Orcid a buscar">
                    <b-input @keyup.enter.native="searchByOrcid" v-model="orcid" ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-button style="margin-top:1.1em" type="is-success is-light"
                            icon-left="orcid"
                            @click="searchByOrcid"
                            size="is-medium"
                            icon-pack="fab">
                    Buscar información por ORCID
                  </b-button>
                </div>
              </div>
            </b-tab-item>

            <b-tab-item label="Búsqueda por Mi ORCID">
              <div class="columns">
                <div class="column">
                  <b-field label="Tu Orcid">
                    {{ this.person.orcid }}
                  </b-field>
                </div>
                <div class="column">
                  <b-button type="is-success is-light"
                            size="is-medium"
                            icon-left="orcid"
                            @click="searchByMyOrcid"
                            icon-pack="fab">
                    Buscar información según mi ORCID
                  </b-button>
                </div>
              </div>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </div>


    <div v-if="requestActivated" class="columns">
      <div class="column">
        <orcid-publications-table v-bind:new_publications="publications"
                                  :user="user"
                                  :siga_publications="siga_publications"
                                  @publicationAdded="publicationAdded"
                                  :person="person"></orcid-publications-table>
      </div>
    </div>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import orcidPublicationsTable from "./OrcidPublicationsTable";
export default {
  name: "ApiOrcidActiveSearch",
  components: {orcidPublicationsTable},

  data(){
    return{
      isLoading: false,
      showModal: false,
      publications:[],
      siga_publications:[],
      modalActive:false,
      requestActivated:false,
      orcid:"",
      personName:"",
      people:[],
      currentPage:1,
      hasSearchedPeople:false
    }
  },
  created(){
    this.isLoading=true;
    this.isLoading=false;
  },
  props:[
    'person','user','cargoPrincipal'
  ],
  methods:{
    orcidSearch(orcid){
      let vm = this;
      vm.isLoading = true;
      if(vm.cargoPrincipal.name=="ACADEMICO" && !vm.person.orcid){
        vm.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error en su solicitud ya que usted no posee DOI. Puede editarlo en su perfil. <br><b><a href="/people/'+vm.person.id+'/my-academic-data" target="_blank">Ir a mi perfil</a></b>',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        vm.isLoading=false;
        return false;
      }
      axios
        .get("/people/"+vm.person.id+"/active-search/orcid/search.json", {
          params: {orcid:orcid}
        })
        .then(res =>{
          //console.log(res);
          vm.requestActivated = true;
          vm.publications = res.data.publications;
          vm.siga_publications = res.data.publications_on_siga;

          vm.$buefy.dialog.alert({
            title: 'Petición exitosa',
            message: 'Su petición fue exitosa. Recuerde rellenar la información obtenida.',
            type: 'is-success',
            hasIcon: true,
            icon: 'check-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          vm.isLoading = false;
        }).catch(error => {
          //console.log(error);
          let errorMessage = 'Hubo un error en su solicitud. Esto puede deberse a un error interno del sistema, o a que la API ORCID o la API DOI no estén funcionando.';
          //console.log(error.response,error.code,error.message);
          if(error.response.data && error.response.data.message){
            errorMessage = error.response.data.message;
          }
          vm.$buefy.dialog.alert({
            title: 'Error',
            message: errorMessage,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          vm.isLoading=false;
        })


    },
    publicationAdded(publication){
      this.publications = this.publications.filter( pub => (pub.doi!= publication.doi))
      this.publications = this.publications.filter( pub => (pub.title!= publication.title))
      this.siga_publications.unshift(publication);
      this.$buefy.dialog.alert({
        title: 'Petición exitosa',
        message: 'Su petición fue exitosa y la publicación ha sido añadida al sistema. <br> *Esta publicación fue sacada del tab "Publicaciones no encontradas en SIGA" y fue añadida al tab de "Pubicaciones encontradas en SIGA" donde podrá abrir esta infromación en una pestaña nueva',
        type: 'is-success',
        hasIcon: true,
        icon: 'check-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true
      })
    },
    searchByOrcid(){
      this.orcidSearch(this.orcid);
    },
    searchByMyOrcid(){
      this.orcidSearch(this.person.orcid);
    },
    searchByPerson(){
      this.isLoading=true;
      return axios
          .get("/people/"+this.personName+"/"+JSON.stringify({ checkedRows: [] })+"/search_people.json"
          ).then(res => {
            this.people = res.data.participants
            if(this.people && this.people.length>0){
              this.people = this.people.filter(person => person.external==false);
            }
            this.hasSearchedPeople = true;
            this.isLoading=false;
          }).catch(error => {
            //console.log(error)
            this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Hubo un error en su solicitud.',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.isLoading=false;
          })
    },
    getPersonUrl(person_id){
      return "/people/"+person_id;
    },
    savePersonOrcid(person){
      this.isLoading = true;
      let orcid = person.orcid;
      let person_id = person.person_id;
      axios.put('/people/'+person_id+'/update/orcid/'+orcid)
        .then(
            response =>{
              this.$buefy.dialog.alert({
                title: 'Petición exitosa',
                message: 'Su petición fue exitosa. El ORCID de '+person.name+' fue actualizado.',
                type: 'is-success',
                hasIcon: true,
                icon: 'check-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              this.isLoading = false
            })
        .catch(
            e => {
              let additional_message = ""
              if(e.response && e.response.data && e.response.data.message){
                additional_message = e.response.data.message;
              }
              this.$buefy.dialog.alert({
                message: "Hubo un error en su petición. "+additional_message,
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                onConfirm: () => {
                }
              })
              this.isLoading = false
            }
        )
    },
    personOrcidSearch(person){
      this.orcidSearch(person.orcid);
      this.people = [person];
    },
    confirmAddOrcid(person) {
      this.$buefy.dialog.confirm({
        message: 'Esta operación creará o sobreescribirá el ORCID asociado a esta persona ¿Desea continuar?.',
        confirmText: 'Guardar ORCID',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.savePersonOrcid(person)
      })
    },

  }
}
</script>

<style scoped>

</style>