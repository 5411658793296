<template>
    <section>

        <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
            <b-message type="is-danger" has-icon>
                <div v-for="(item, index) in errores_validacion" :key="index">
                    {{ item[0] }}
                </div>
            </b-message>
        </div>

      <div v-if="data.can_life_record" class="columns">
        <div class="column has-text-right">
          <b-button  pack="fas" icon-left="file-alt" style="background-color: #e3f6f4; border-color:#00aea7" type="is-primary is-light" label="Ir a Mi Hoja de Vida" tag="a" :href="'/people/'+personId+'/life_record'" target="_blank"/>
        </div>
      </div>

        <div class="title is-5">
            Datos Académicos
            <hr class="dropdown-divider" aria-role="menuitem">
        </div>

        <div class="columns">

            <div class="column">
                <b-field label="Nombres">
                    <b-input readonly type="text" v-model="data.person.name" />
                </b-field>
            </div>


            <div class="column">
                <b-field label="Primer Apellido">
                    <b-input readonly type="text" v-model="data.person.last_name" />
                </b-field>
            </div>

            <div class="column">
                <b-field label="Segundo Apellido">
                    <b-input readonly type="text" v-model="data.person.second_surname" />
                </b-field>
            </div>

        </div>

        <div class="columns">

            <div class="column">
                <b-field label="Correo Institucional">
                    <b-input readonly type="text" v-model="data.person.corporate_mail" />
                </b-field>
            </div>

            <div class="column">
                <b-field label="Jerarquía">
                    <b-input readonly type="text" v-model="data.hierarchy_name" />
                </b-field>
            </div>

        </div>

        <div class="columns">

            <div class="column">
                <b-field label="Unidad de Desempeño">
                    <b-input readonly type="text" v-model="data.unit_assigned"/>
                </b-field>
            </div>

            <div class="column">
                <ValidationObserver ref="observerNumberOffice" v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveMyOfficeNumber)">
                        <ValidationProvider rules="min:3|max:255" v-slot="{errors}">
                            <b-field :type="errors[0] ? 'is-danger' : '' " label="Número de Oficina" :message="errors[0]">
                                <b-input type="text" maxlength="255" expanded v-model="data.person.office_location" />
                                <b-button v-if="office" tag="button" native-type="submit" type="is-primary" icon-left="save" label="Guardar N° Oficina" @click="checkValidationNumberOffice"/>
                            </b-field>
                        </ValidationProvider>
                    </form>
                </ValidationObserver>
            </div>

            <div class="column">
                <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveMyOfficeIp)">
                        <ValidationProvider rules="integer|min:3|max:12" v-slot="{errors}">
                            <b-field :type="errors[0] ? 'is-danger' : '' " label="IP Oficina" :message="errors[0]">
                                <b-input type="number" maxlength="12" expanded v-model="data.person.annex_phone" />
                                <button v-if="anex" type="submit" class="button is-primary" @click="checkValidation">
                                    <span class="icon">
                                        <i class="fas fa-save"></i>
                                    </span>
                                  <span>Guardar IP</span>
                                </button>
                            </b-field>
                        </ValidationProvider>
                    </form>
                </ValidationObserver>
            </div>
            
        </div>

        <!-- <div class="title is-5">
            Mis Unidades Actuales
            <hr class="dropdown-divider" aria-role="menuitem">
        </div> -->

        <!-- <div class="columns">
            <div class="column">
                <li v-for="(unit, index) in data.units" :key="index">
                    {{ unit.name }}
                </li>
            </div>
        </div> -->
        

        <div class="title is-5">
            Mis Estudios
            <hr class="dropdown-divider" aria-role="menuitem">
        </div>

        <b-tabs position="is-centered">

            <b-tab-item>

                <template #header>
                    <span>Grados <b-tag type="is-primary" style="margin-left: 3px"rounded> {{ data.grades.length }} </b-tag> </span>
                </template>

                <b-table striped :data="data.grades" paginated per-page=10 pagination-position="bottom"
                    aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
                    aria-current-label="Página actual">

                    <b-table-column width="60" label="Fecha de Obtención" field="egress_date" sortable searchable
                        :custom-search="searchGradeDate">
                        <template #searchable="props">
                            <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha..."
                                icon="search" />
                        </template>
                        <template v-slot="props">
                            {{ props.row.egress_date._isValid ? new Date(props.row.egress_date).toLocaleDateString("es-CL")
                                : '' }}
                        </template>
                    </b-table-column>

                    <b-table-column label="Tipo Grado" field="type" sortable searchable>
                        <template #searchable="props">
                            <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Grado..."
                                icon="search" />
                        </template>
                        <template v-slot="props">
                            {{ props.row.type }}
                        </template>
                    </b-table-column>

                    <b-table-column label="Nombre Estudio" field="name" sortable searchable>
                        <template #searchable="props">
                            <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Estudio..."
                                icon="search" />
                        </template>
                        <template v-slot="props">
                            {{ props.row.name }}
                        </template>
                    </b-table-column>

                    <b-table-column label="Institución" field="partner" sortable searchable>
                        <template #searchable="props">
                            <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Institución..."
                                icon="search" />
                        </template>
                        <template v-slot="props">
                            {{ props.row.partner }}
                        </template>
                    </b-table-column>
                  <template slot="empty" slot-scope="props">
                    <div class="columns is-centered">
                      <div class="column is-6 is-centered has-text-centered">
                        <b-message type="is-warning">
                          No se encontraron registros de grados. Si posee información de estudios,
                          por favor comuníquese con el jefe administrativo de su departamento y solicite la actualización de su
                          información de estudios.
                        </b-message>
                      </div>
                    </div>
                  </template>
                </b-table>

            </b-tab-item>

            <b-tab-item>

                <template #header>
                    <span>Títulos <b-tag style="margin-left: 3px" type="is-primary" rounded> {{ data.degrees.length }} </b-tag> </span>
                </template>

                <b-table striped :data="data.degrees" paginated per-page=10 pagination-position="bottom"
                    aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
                    aria-current-label="Página actual">

                    <b-table-column width="40" label="Fecha Obtención" field="egress_date" sortable searchable
                        :custom-search="searchDegreeDate">
                        <template #searchable="props">
                            <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha..."
                                icon="search" />
                        </template>
                        <template v-slot="props">
                            {{ props.row.egress_date._isValid ? new Date(props.row.egress_date).toLocaleDateString("es-CL")
                                : '' }}
                        </template>
                    </b-table-column>

                    <b-table-column label="Tipo Título" field="type" sortable searchable>
                        <template #searchable="props">
                            <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Título..."
                                icon="search" />
                        </template>
                        <template v-slot="props">
                            {{ props.row.type }}
                        </template>
                    </b-table-column>

                    <b-table-column label="Nombre Estudio" field="name" sortable searchable>
                        <template #searchable="props">
                            <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Estudio..."
                                icon="search" />
                        </template>
                        <template v-slot="props">
                            {{ props.row.name }}
                        </template>
                    </b-table-column>

                    <b-table-column label="Institución" field="partner" sortable searchable>
                        <template #searchable="props">
                            <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Institución..."
                                icon="search" />
                        </template>
                        <template v-slot="props">
                            {{ props.row.partner }}
                        </template>
                    </b-table-column>

                  <template slot="empty" slot-scope="props">
                    <div class="columns is-centered">
                      <div class="column is-12 is-centered has-text-centered">
                        <b-message type="is-warning">
                          No se encontraron registros de títulos. Si posee información de estudios,
                          por favor comuníquese con el jefe administrativo de su departamento y solicite la actualización de su
                          información de estudios.
                        </b-message>
                      </div>
                    </div>
                  </template>

                </b-table>

            </b-tab-item>

        </b-tabs>

        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </section>
</template>

<script>
import axios from 'axios'
import { searchDate } from "../../packs/utilities"
import moment from "moment"
export default {
    components: {
    },
    props: [
        'personId', 'cargoPrincipal', 'canCreateDocument'
    ],
    data() {
        return {
            isLoading: false,
            data: {
                person: {},
                grades: [],
                degrees: [],
            },
            errores_validacion: [],
            anex: true,
            office: true
        }
    },
    async created() {
        await this.myAcademicData()
    },
    methods: {

        async myAcademicData() {
            this.isLoading = true
            await axios
                .get("/people/" + this.personId + "/my-all-academic-data.json")
                .then(res => {
                    this.data = res.data
                    this.data.grades = this.data.grades.map(grade => {
                        grade.egress_date = moment(grade.egress_date, ["YYYY-MM-DD", "DD-MM-YYYY"])
                        return grade
                    })
                    this.data.degrees = this.data.degrees.map(degree => {
                        degree.egress_date = moment(degree.egress_date, ["YYYY-MM-DD", "DD-MM-YYYY"])
                        return degree
                    })
                  this.anex = this.data.can_update_anex
                  this.office = this.data.can_update_office
                    this.isLoading = false
                })
                .catch(e => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: 'Hubo un error al traer los datos de la persona consultada, favor de revisar la consola.',
                        type: 'is-danger'
                    })
                    //console.log(e)
                    this.isLoading = false
                })
        },

        searchGradeDate(row, input) {
            return searchDate(row.award_date, input)
        },
        searchDegreeDate(row, input) {
            return searchDate(row.award_date, input)
        },

        saveMyOfficeIp() {
            this.isLoading = true
            axios
                .put("/people/office-ip.json", {
                    person: this.data.person,
                    id: this.personId
                },
                )
                .then(response => {
                    this.errores_validacion = []
                    this.$buefy.dialog.alert({
                        title: 'Logrado',
                        message: 'Se ha actualizado su IP Oficina.',
                        type: 'is-success',
                        hasIcon: true,
                        iconPack: 'fa',
                        icon: 'check-circle',
                        confirmText: 'Ok',
                    })
                    this.isLoading = false
                    this.myAcademicData()
                }).
                catch(error => {
                    this.errores_validacion = error.response.data
                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al guardar su IP Oficina, favor de revisar los errores.',
                        type: 'is-danger',
                        hasIcon: true,
                        iconPack: 'fa',
                        icon: 'times-circle',
                        confirmText: 'Ok',

                    })
                    var elmnt = document.getElementById("app");
                    elmnt.scrollIntoView();
                    this.isLoading = false;
                })
        },

        saveMyOfficeNumber() {
            this.isLoading = true
            axios
                .put("/people/office-number.json", {
                    person: this.data.person,
                    id: this.personId
                },
                )
                .then(response => {
                    this.errores_validacion = []
                    this.$buefy.dialog.alert({
                        title: 'Logrado',
                        message: 'Se ha actualizado su Número de Oficina.',
                        type: 'is-success',
                        hasIcon: true,
                        iconPack: 'fa',
                        icon: 'check-circle',
                        confirmText: 'Ok',
                    })
                    this.isLoading = false
                    this.myAcademicData()
                }).
                catch(error => {
                    this.errores_validacion = error.response.data
                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al guardar su Número de Oficina, favor de revisar los errores.',
                        type: 'is-danger',
                        hasIcon: true,
                        iconPack: 'fa',
                        icon: 'times-circle',
                        confirmText: 'Ok',

                    })
                    var elmnt = document.getElementById("app");
                    elmnt.scrollIntoView();
                    this.isLoading = false;
                })
        },
        
        async checkValidation() {
            const valid = await this.$refs.observer.validate()
            if (!valid) {
                this.$buefy.dialog.alert({
                    message: 'La IP Oficina ingresada presenta errores.',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
            }
        },

        async checkValidationNumberOffice() {
            const valid = await this.$refs.observerNumberOffice.validate()
            if (!valid) {
                this.$buefy.dialog.alert({
                    message: 'El Número de Oficina ingresado presenta errores.',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
            }
        },
    },
    computed: {
    }
}
</script>