<template>
  <section>
    <div class="box">
      <div class="inside-box">
        <AdministrativePermitsBreadcrums :breadcrumbs="breadcrumbs" />
        <div>
          <h1 class="title">Panel de Gestión de Permisos Administrativos</h1>
          <div>
            <div class="columns is-multiline">
              <div
                  v-for="item in menuItems"
                  :key="item.route"
                  class="column is-full is-half-tablet"
              >
                <AdministrativePermitsMenuItem
                    :route="item.route"
                    :icon="item.icon"
                    :text="item.text"
                    :tooltip-text="item.tooltipText"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div>
        <div class="columns">
          <div class="column">
            <div class="title is-5">
              Permisos Administrativos
              <hr class="dropdown-divider" aria-role="menuitem">
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column">
            <b-message type="is-info" has-icon>
              Esta tabla rápida de permisos administrativos, considera a las personas que tienen nombramientos activos en la Facultad de Ingeniería.
              En caso de no encontrar a una persona en específico, favor de utilizar la herramienta <b>Solicitar por Persona</b>.
            </b-message>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <AdministrativePermitsQuickTable :permit_people="permit_people"></AdministrativePermitsQuickTable>
          </div>
        </div>
      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import AdministrativePermitsBreadcrums from "./AdministrativePermitsBreadcrums.vue";
import AdministrativePermitsMenuItem from "./AdministrativePermitsMenuItem.vue";
import AdministrativePermitsQuickTable from "./AdministrativePermitsQuickTable.vue";
import axios from "axios"

export default {
  components: {
    AdministrativePermitsBreadcrums,
    AdministrativePermitsMenuItem,
    AdministrativePermitsQuickTable,
  },
  created() {
    this.initialize()
  },
  data() {
    return {
      breadcrumbs: [
        { href: "/life_record", label: "Menú principal" },
        {
          href: "/administrative-permits/search",
          label: "Permisos administrativos",
          isActive: true,
        },
      ],
      menuItems: [
        {
          route: "/administrative-permits/search",
          icon: "fa-search",
          text: "Solicitar por Persona",
          tooltipText:
            "Buscador de personas para acceder al perfil de permisos administrativos.",
        },
        {
          route: "/administrative-permits/manage",
          icon: "fa-tasks",
          text: "Gestor de Permisos",
          tooltipText:
            "Administra las solicitudes de permisos administrativos.",
        },
        {
          route: "/administrative-permits/resolutions",
          icon: "fa-file-alt",
          text: "Resoluciones Mensuales",
          tooltipText:
            "Administra las resoluciones mensuales de permisos administrativos.",
        },
      ],
      isLoading: false,
      permit_people: []
    };
  },
  methods :{
    initialize(){
      this.isLoading=true;
      axios
          .get("/administrative-permits/get-person-permits.json")
          .then(res => {
            this.permit_people = res.data
            this.isLoading=false;
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }
  }
};
</script>

<style scoped>
@media (max-width: 767px) {
  .title {
    font-size: 1.5rem;
  }
  .subtitle {
    font-size: 1.2rem;
  }
}

@media (max-width: 1023px) {
  .box {
    margin: 1rem;
  }
}

@media (min-width: 1024px) {
  .box {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
