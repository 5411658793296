<template>
  <section>
    <div class="columns">
      <div name="contenido" class="column">

        <div class="container mt-6">
          <div class="box">
            <section class="hero is-primary welcome is-small mb-4">
              <div class="hero-body">
                <div class="container">
                  <h1 class="title">
                    Módulo Administración de Funciones
                  </h1>
                </div>
              </div>
            </section>


            <section class="info-tiles">
              <div class="tile is-ancestor has-text-centered">
                <div class="tile is-parent">
                  <article class="tile is-child box">
                    <a href="/functions">
                      <p class="subtitle is-4">
                      <span class="icon">
                        <i class="fas fa-user-tag"></i>
                      </span>
                        Administrar Funciones
                        <b-tooltip label="Búsqueda, listado, creación y edición de funciones."
                                   position="is-right"
                                   size="is-large"
                                   multilined>
                          <b-icon
                              pack="fas"
                              icon="info-circle"
                              size="is-small"
                              type="is-info">
                          </b-icon>
                        </b-tooltip>
                      </p>
                    </a>
                  </article>
                </div>
                <div class="tile is-parent">
                  <article class="tile is-child box">
                    <a href="/functions/nullified-functions">
                      <p class="subtitle is-4">
                      <span class="icon">
                        <i class="fas fa-ban"></i>
                      </span>
                        Funciones Anuladas
                        <b-tooltip label="Listado de funciones anuladas."
                                   position="is-right"
                                   size="is-large"
                                   multilined>
                          <b-icon
                              pack="fas"
                              icon="info-circle"
                              size="is-small"
                              type="is-info">
                          </b-icon>
                        </b-tooltip>
                      </p>
                    </a>
                  </article>
                </div>
              </div>
            </section>
          </div>
        </div>


      </div>
    </div>

  </section>
</template>

<script>

export default {
  name: "LifeRecordFunctionsDashboard",
  data(){
    return {
      selected: null,
      canCancel: ['escape'],
      isAddModalActive: false
    }
  },
  created(){
  },
  methods: {
  }
}
</script>