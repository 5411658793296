<template>

  <section class="section">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
        <b-message type="is-danger" has-icon>
          <div v-for="(item,index) in errores_validacion">
            {{ item[0] }}
          </div>
        </b-message>
      </div>
      <form @submit.prevent="handleSubmit(savePosition)">
        <div class="columns is-centered">
          <div class="column is-11">
            <div class="box">

              <div class="columns">
                <div class="column">
                  <b-field v-if="users.length>0" label="Nombre Usuaria/o*">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <multiselect v-model="user"
                                   :options = "users"
                                   track-by="name" label="name"
                                   placeholder="Seleccione usuaria/o"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="No se puede deseleccionar"
                                   :allow-empty="false">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                          No se encontraron elementos.
                        </span>
                      </multiselect>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column">
                  <b-field v-if="users.length>0" label="Nombre Perfil*">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <multiselect v-model="profile"
                                   :options = "profiles"
                                   track-by="name" label="name"
                                   placeholder="Seleccione perfil"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="No se puede deseleccionar"
                                   :allow-empty="false">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                          No se encontraron elementos.
                        </span>
                      </multiselect>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>

              <div class="actions has-text-right">
                <button class="button is-primary"  type="submit" @click="checkValidation()">Guardar</button>
              </div>

            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import axios from "axios";

export default {
  data(){
    return{
      users:[],
      user:null,
      profiles:[],
      profile:null,
      isLoading:false,
      errores_validacion: {},

    }
  },
  created(){
    this.isLoading=true;
    axios
        .get("/permissions/profile-asignation.json")
        .then(res => {
          console.log(res.data)
              this.profiles = res.data["profiles"];
              this.users = res.data["users"];
              this.isLoading=false;
        }
        ).
        catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.isLoading=false;
        })

  },
  props:[

  ],
  methods:{
    savePosition(){
      this.isLoading=true;
      axios
          .post("/permissions/assign-profile.json", {
            profile: this.profile,
            user: this.user,
          })
          .then(response => {
            this.errores_validacion = {};
            this.$buefy.dialog.alert({
              message: 'El cargo '+ this.profile.name +' se ha asignado correctamente a '+this.user.name,
              confirmText: 'Volver'
            })
            this.profile = null;
            this.user = null;
            this.isLoading=false;
          }).
      catch(error => {
        this.errores_validacion = error.response.data
        var elmnt = document.getElementById("app");
        elmnt.scrollIntoView();
        this.isLoading=false;
      })

    },
    blur: function () {
      this.computedForm.refs.selected.validate();
    },
    async checkValidation(){
      const valid = await this.$refs.observer.validate()
      if(!valid){
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    }

  },
  computed:{
    computedForm: function () {
      return this.$refs.form;
    }

  }
}
</script>