<template>
  <section>
    <div class="container">
      <h1 class="is-size-2">Validación de Actividad Acádemica</h1>
      <br>
      <div class="columns">
        <div class="column"></div>
        <div class="column is-narrow">
          <b-tooltip
              label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio, excepto la sección de acciones"
              multilined
              position="is-left">
            <b-icon
                pack="fas"
                icon="info-circle"
                type="is-info"
            size="is-medium">
            </b-icon>
          </b-tooltip>
        </div>
      </div>
      <b-table
          :data="data"
          :paginated=true
          ref="validationTable"
          per-page=10
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field= 'name_of_model'
            label= 'Tipo'
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.name_of_model }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'title'
            label= 'Título'
            width="30em"
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.title }}
          </template>
        </b-table-column>

        <b-table-column
            field= 'data_source'
            label= 'Fuente de obtención'
            width="10em"
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.data_source }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'validated_by_academic'
            label= 'Validado por acádemico'
            sortable
            centered
            v-slot="props"
        >
          <b-checkbox v-model="props.row.validated_by_academic"
                      :type="props.row.validated_by_academic ? 'is-success' : 'is-danger'"
                      disabled>
            <span v-if="props.row.validated_by_academic">
              Sí
            </span>
            <span v-else>
              No
            </span>
          </b-checkbox>
        </b-table-column>
        <b-table-column
            field= 'validated_by_department'
            label= 'Validado por Departamento Acádemico'
            sortable
            centered
            v-slot="props"
        >
          <b-checkbox v-model="props.row.validated_by_department"
                      :type="props.row.validated_by_department ? 'is-success' : 'is-danger'"
                      disabled>
            <span v-if="props.row.validated_by_department">
              Sí
            </span>
            <span v-else>
              No
            </span>
          </b-checkbox>
        </b-table-column>
        <b-table-column
            field= 'validated_by_central'
            label= 'Validado por Casa Central'
            sortable
            v-slot="props"
        >
          <b-checkbox v-model="props.row.validated_by_central"
                      :type="props.row.validated_by_central ? 'is-success' : 'is-danger'"
                      disabled>
            <span v-if="props.row.validated_by_central">
              Sí
            </span>
            <span v-else>
              No
            </span>
          </b-checkbox>
        </b-table-column>
        <b-table-column
            width="20em"
            label= 'Acciones'
            v-slot="props"
        >
          <b-button @click="validateObject(props.row)" icon-left="tasks" type="is-primary">Validar</b-button>
          <b-tooltip v-if="false" label="Eliminar Validación">
            <b-button type="is-danger"
                      icon-right="trash-alt" />
          </b-tooltip>
          <b-tooltip label="Ver historial de validaciones">
            <b-button @click="seeValidationHistory(props.row)" type="is-info" icon-right="comment-alt">
            </b-button>
          </b-tooltip>
        </b-table-column>
      </b-table>
    </div>
    <b-modal v-model="isModalActive" width="90%"
             :destroy-on-hide="false"
             :on-cancel="cancelValidation">
      <div class="card">
        <div class="card-content">

          <b-button v-if="this.userId==this.selectedObject.origin_user_id && currentComponent != 'validationHistory'" class="is-pulled-right mr-4" @click="updateObject" icon-left="edit" type="is-success is-light">Editar</b-button>
          <br v-if="this.userId==this.selectedObject.origin_user_id"><br v-if="this.userId==this.selectedObject.origin_user_id">
          <div v-if="currentComponent != 'validationHistory'">
            <template v-if="currentComponent == 'product' && objectId!=null">
              <show-product :product-id="objectId" ></show-product>
            </template>
            <template v-if="currentComponent == 'patent' && objectId!=null">
              <show-patent :patent-id="objectId" ></show-patent>
            </template>
            <template v-if="currentComponent == 'project' && objectId!=null">
              <show-project :project-id="objectId" v-bind:cargo-principal="cargoPrincipal"></show-project>
            </template>
            <template v-if="currentComponent == 'publication' && objectId!=null">
              <show-publication :publication-id="objectId" ></show-publication>
            </template>
            <br>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <b-field label="Comentario de validación">
                    <b-input
                        type="textarea"
                        maxlength="500"
                        v-model="validationComment">
                    </b-input>
                  </b-field>
                </div>
              </div>
            </div>
            <div class="columns has-text-centered">
              <div class="column">
                <b-button @click="CancelValidation" icon-left="times-circle">Cerrar</b-button>
              </div>
              <div class="column">
                <b-button @click="SubmitValidation(false)" icon-left="times" type="is-danger">Rechazar</b-button>
              </div>
              <div class="column">
                <b-button @click="SubmitValidation(true)" icon-left="check-double" type="is-success">Validar</b-button>
              </div>
            </div>

          </div>

          <div v-else>
            <div v-if="validationsHistory.length==0">
              No existen validaciones para el objeto seleccionado
            </div>
            <div v-for="validationHistory in validationsHistory">
              <article class="media card mb-2">
                <figure class="media-left">
                  <b-icon icon="user" size="is-large"
                          type="is-primary"></b-icon>
                </figure>
                <div class="media-content">
                  <div class="content">
                    <p>
                      <strong>{{ validationHistory.validator_name }}</strong> <small>{{ validationHistory.internal_position }}</small> <small>{{ formatDate(validationHistory.validation_date) }}</small>
                      <br>
                      {{ validationHistory.message }}
                    </p>
                    <div class="has-text-right mb-2 mr-2">
                      Resultado de validación
                      <br>
                      <b-button v-if="validationHistory.successful" type="is-success" icon-left="check">Validada</b-button>
                      <b-button v-else type="is-danger" icon-left="times">Rechazada</b-button>
                    </div>
                  </div>

                </div>
              </article>
              <hr class="dropdown-divider">
            </div>

          </div>

        </div>
      </div>
    </b-modal>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import axios from "axios";
import ShowProduct from './product/show_product.vue'
import ShowPatent from './patents/show_patent.vue'
import ShowProject from "./projects/show_project.vue";
import ShowPublication from "./publications/show_publication.vue";

export default {
  components:{
    ShowProduct,ShowPatent,ShowProject,ShowPublication
  },
  data(){
    return{
      data:[],
      isLoading:false,
      currentPage:1,
      validaciones:[],
      isModalActive:false,
      objectId:null,
      currentComponent:'',
      validationComment:'',
      selectedObject:{},
      validationsHistory:[]
    }
  },
  created(){
    this.isLoading=true;
    var url = "";
    if (this.accionDefinida=="index"){
      url="/validations.json";
    }
    else if (this.accionDefinida=="myValidations"){
      url="/validations/user/"+this.userId+".json";
    }
    else if (this.accionDefinida=="unitValidation"){
      url="/validations/my-unit.json";
    }
    return axios.get(url)
        .then(res => {
          this.validaciones = res.data['validaciones'];
          this.data=[];
          this.validaciones.forEach((item) => {
            this.data.push(item)
          })
          this.isLoading=false;
        })
        .catch(e => {
          this.isLoading=false;
          console.log(e);
        });

  },
  props:[
      'userId','accionDefinida','cargoPrincipal'
  ],
  methods:{
    validateObject(objeto){
      this.currentComponent=objeto.name_of_model;
      this.isModalActive=true;
      this.objectId=objeto.object_id;
      this.selectedObject=objeto;
    },
    SubmitValidation(validation_value){
      this.isLoading=true;
      var url=''
      if (this.currentComponent=='product' || this.currentComponent=='patent'
          || this.currentComponent=='project' || this.currentComponent=='publication')
          url = "/validations/validate-object.json"
      return axios.post(url,{
            selected_object: this.selectedObject,
            comment: this.validationComment,
            was_validated: validation_value,
            validator_id: this.userId,
          })
          .then(res => {
            var validacion = res.data['validacion'];
            const index = this.validaciones.findIndex(v => v.id === validacion.id);
            this.validaciones[index] = validacion;
            this.data=[];
            this.validaciones.forEach((item) => {
              this.data.push(item)
            })
            this.isLoading=false;
            this.CancelValidation();
          })
          .catch(e => {
            this.isLoading=false;
            this.CancelValidation();
            console.log(e);
          });

    },
    CancelValidation(){
      this.isModalActive=false;
      this.cancelValidation();
    },
    cancelValidation(){
      this.objectId=null;
      this.currentComponent='';
      this.validationComment='';
      this.selectedObject={};
      this.validationsHistory=[];
    },
    seeValidationHistory(objeto){
      this.currentComponent="validationHistory";
      this.isModalActive=true;
      this.selectedObject=objeto;
      const index = this.validaciones.findIndex(v => v.id === objeto.id);
      this.validationsHistory = this.validaciones[index].has_validations_attributes;
    },
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    updateObject(){
      console.log(this.currentComponent)
      if (this.currentComponent === 'publication' && this.objectId!=null)
        window.location.href = '/publications/'+this.objectId+"/edit"
      if (this.currentComponent === 'project' && this.objectId!=null)
        window.location.href = '/projects/'+this.objectId+"/edit"
      if (this.currentComponent === 'patent' && this.objectId!=null)
        window.location.href = '/patents/'+this.objectId+"/edit"
      if (this.currentComponent === 'product' && this.objectId!=null)
        window.location.href = '/products/'+this.objectId+"/edit"
    }


  },
  computed:{

  }
}
</script>

