<template>
  <div class="vimeactivitynewview">
    <section>

      <div v-if="from_academic" class="modal-card" style="width: 1080px">

        <header class="modal-card-head">
          <p v-if="edit_case" class="modal-card-title">Editar Actividad VIME</p>
          <p v-else class="modal-card-title">Nueva Actividad VIME</p>
          <button type="button" class="delete" @click="$emit('close')" />
        </header>

        <section class="modal-card-body">
          <VimeActivityNewForm :cargo_principal="cargoPrincipal" :query_type="queryType"
            :vime_activity_id='vime_activity_id' :edit_case='edit_case' :from_academic="from_academic"
            :add_activity="add_activity" @close="$emit('close')" @refresh="$emit('refresh')" />
        </section>

        <footer v-if="from_academic" class="modal-card-foot actions">
          <div class="has-text-right is-grouped">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <b-button class="is-primary" label="Agregar Actividad VIME" @click="add_activity = !add_activity" />
          </div>
        </footer>
      </div>

      <div v-else class="container">
        <h1 v-if="edit_case == 1" class="is-size-2">Editar Actividad</h1>
        <h1 v-else class="is-size-2">Nueva Actividad</h1>
        <VimeActivityNewForm :cargo_principal="cargoPrincipal" :query_type="queryType"
          :vime_activity_id='vime_activity_id' :edit_case='edit_case' />
      </div>
    </section>
  </div>
</template>
<script>
import VimeActivityNewForm from '../new/vime_activity_new_form.vue'
export default {
  name: 'vimeactivitynewview',
  components: {
    VimeActivityNewForm
  },
  props: {
    vime_activity_id: {
      Type: Number
    },
    edit_case: {
      Type: Boolean,
      default: 0
    },
    userId: {
      type: String
    },
    personId: {
      type: String
    },
    cargoPrincipal: {
      type: Object
    },
    queryType: {
      type: String
    },
    from_academic: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      add_activity: false
    }
  }
}
</script>