<template>
  <div>
    <div v-if="!hideUpdate">
      <div class="columns" >

        <div class="column" v-if=" units.length>0">
          <b-field label="Unidad">
              <multiselect v-model="unit"
                           :options="units.map(type => type.id)"
                           :custom-label="opt => units.find(x => x.id == opt).name"
                           placeholder="Seleccione unidad"
                           selectLabel=" "
                           selectedLabel=" "
                           :disabled="canModify == 'false'"
                           deselectLabel=" ">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                    No se encontraron elementos.
                </span>
              </multiselect>
          </b-field>
        </div>

        <div class="column" v-if=" units.length>0">
          <b-field label="Entidad EFUSACH">
            <multiselect v-model="entidad"
                         :options="entidades"
                         :custom-label="nameEntidad"
                         placeholder="Seleccione entidad"
                         selectLabel=" "
                         selectedLabel=" "
                         deselectLabel=" ">
              <template v-slot:noOptions>
                No existen datos
              </template>
              <span slot="noResult">
                    No se encontraron elementos.
                </span>
            </multiselect>
          </b-field>
        </div>

      </div>
      <div class="columns">
        <div class="column has-text-right">          
          <b-button
                    class="button right-button button-clear-filter"
                    icon-left="eraser"
                    @click="clearFilter()">
            Limpiar Filtros
          </b-button>
          <b-button type="is-info"
                    icon-left="search"
                    class="button right-button is-info"
                    @click="updateSurplusesFilter()">
            Buscar
          </b-button>

        </div>
      </div>

      <div class="columns">
        <div class="column is-centered">
          <b-message type="is-info" has-icon v-model="isActive">
            {{message}}
          </b-message>
        </div>
      </div>


    </div>  
    <b-table
        :data="surpluses"
        :paginated=true
        per-page=10
        striped
        hoverable
        :current-page="currentPage"
        :pagination-simple=false
        pagination-position="bottom"
        default-sort-direction="desc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="servicio.start_date"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página actual"
        >
        <b-table-column
          field= 'code'
          label= 'Código'
          width="15em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.code }}
        </template>
      </b-table-column>


      <b-table-column
          field= 'unit'
          label= 'Unidad'
          width="20em"
          searchable
          v-if="!hideUpdate"
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.unit }}
        </template>
      </b-table-column>

      <b-table-column
          field= 'turn'
          label= 'Entidad EFUSACH'
          width="20em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.turn_with_code }}
        </template>
      </b-table-column>




      <b-table-column
          label="Acciones"
          width="10em"
          v-slot="props">
        <b-button type="is-primary"
                  icon-left="eye"
                  @click="goSurplus(props.row)">
          Ver Transacciones
        </b-button>
      </b-table-column>
    
      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-danger">
              No se encontraron resultados.
            </b-message>
          </div>
        </div>
      </template>


    </b-table>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  </div>
</template>
<script>
import {searchDate} from "../../packs/utilities";
import axios from "axios";
export default {
  props:[
      'surpluses','hideUpdate','cargoPrincipal', 'message', 'unidad', 'canModify'
  ],
  data(){
    return {
      isLoading: false,
      currentPage: 1,
      units: [],
      unit: '',
      entidades: [],
      entidad: null,
      keepFirst: false,
      openOnFocus: false,
      clearable: false,
      nameFilter: '',
      isActive:false,
    }
  },
  created(){
    let vm = this;
    vm.isLoading = true
    return axios.get("/sdt_surpluses/form-data.json")
        .then(res => {
          vm.units = res.data.unidades;
          vm.unit = vm.unidad;
          vm.entidades = res.data.entidades;
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          console.log(e);
        });

  },
  methods:{
    goSurplus(service){
      window.location.href = "/sdt_surpluses/"+service.id;
    },
    nameEntidad ({ name, initials }) {
      let msg = name + " ["+initials+"]";
      return msg
    },
    async updateSurplusesFilter(){
      var datos = {
        unit: this.unit,
        entidad: this.entidad
      }
      await this.$emit('updateSurplusesFilter',datos)
      this.isActive=true;
    },
    clearFilter(){
      this.unit = null;
      this.entidad = null;

    }

  },
  computed:{

  }
}
</script>
<style scoped>

  .right-button{
    margin-left: auto;
  }
  .left-button{
    margin-right: auto;
  }


</style>