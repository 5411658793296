<template>
  <div>
    <div class="container">
      <div class="columns">
        <div class="column">
                        <span v-if="cargoPrincipal.name == 'SUPERADMIN'" class="is-pulled-right">
                            <button class="button is-primary" @click="addNewSubEntity()">
                                <b-icon icon="plus"></b-icon>
                                <span>Añadir Sub-Entidad</span>
                            </button>
                        </span>
        </div>
      </div>
    </div>
    <b-table :data="sub_entities"
             :paginated=true
             per-page=10
             :current-page="currentPage"
             :card-layout="cardLayout"
             :pagination-simple=false
             pagination-position="bottom"
             aria-next-label="Siguiente"
             aria-previous-label="Anterior"
             aria-page-label="Página"
             aria-current-label="Página actual">
      <b-table-column
          field= 'name'
          label= 'Nombre entidad'
          searchable
          sortable
      >
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          <a @click="goToEntity(props.row.id)"><i class="fas fa-industry"></i> {{ props.row.show_name ? props.row.show_name : props.row.name }}</a>
        </template>
      </b-table-column>
      <b-table-column
          field= 'entity_type'
          label= 'Tipo de entidad'
          searchable
          sortable
      >
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.entity_type }}
        </template>
      </b-table-column>
      <b-table-column
          field="email"
          label="Correo"
          searchable
      >
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.email }}
        </template>
      </b-table-column>
      <b-table-column
          field="phone"
          label="Teléfono"
          searchable
      >
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small"/>
        </template>
        <template v-slot="props">
          {{ props.row.phone }}
        </template>
      </b-table-column>
      <b-table-column
          field="is_public"
          label="¿Es pública?"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small"/>
        </template>
        <template v-slot="props">
          {{ props.row.is_public == true ? "Si":"No" }}
        </template>
      </b-table-column>
      <b-table-column
          v-if="cargoPrincipal.name=='SUPERADMIN'"
          label="Acción"
          v-slot="props">
        <b-button type="is-danger"
                  @click.prevent="checkDeleteSubentity(props.row)"
                  icon-right="trash" />
      </b-table-column>
      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-danger">
              No se encontraron resultados.
            </b-message>
          </div>
        </div>
      </template>
    </b-table>
    <b-modal
        v-model="isAddModalEntityActive"
        :destroy-on-hide="true"
        width="50%"
        :can-cancel="canCancel">
      <template #default="props">
        <modal-add-entity
            query-type="update"
            modal-header="Añadir nueva entidad"
            :id="partnerEntityId"
            :entity="entity"
            @close="props.close"
            @clicked="onClickChild"></modal-add-entity>
      </template>
    </b-modal>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
import axios from "axios";
import modalNewPartnerEntity from "./modalNewPartnerEntity";
import modalAddEntity from "./modalAddEntity";

export default {
  name: "PartnerEntitySubEntity",
  props:['subEntities','partnerEntityId','entity','cargoPrincipal'],
  components:{modalNewPartnerEntity,modalAddEntity},
  create(){
    console.log(this.partnerEntityId)
    console.log(this.subEntities)
  },
  data(){
    return{
      currentPage:1,
      cardLayout:false,
      isAddModalEntityActive: false,
      isLoading: false,
      sub_entities: this.subEntities,
      canCancel: false,
    }
  },
  methods:{
    goToEntity(id){
      window.location.href='/partner_entities/show/'+id
    },
    addNewSubEntity(){
      this.isAddModalEntityActive = true
    },
    async getEntity(){
      axios.get('/partner_entities/show/'+this.partnerEntityId+'.json')
          .then( response =>{

            this.sub_entities = response.data.entity.sub_entities

            this.isLoading=false
          })
          .catch(e =>{
            console.log(e)
            this.isLoading=false
          })
    },
    async onClickChild(){
      this.isLoading = true
      await this.getEntity()

    },

    checkDeleteSubentity(entidad){
      this.$buefy.dialog.confirm({
        title: 'Eliminar Sub-Entidad',
        message: 'A continuación, eliminará la subentidad ¿Desea continuar?',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteSubentity(entidad)
      })
    },

    deleteSubentity(entidad){
      entidad.partner_entity_id = null
      this.isLoading = true
      axios.put('/admin/partner_entities/'+entidad.id+'.json', entidad)
          .then( response =>{
            this.getEntity()
            //window.location.href="/partner_entities/show/"+response.data.id

          })
          .catch(e => {
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "Error al intentar eliminar la entidad.",
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              onConfirm: () => {
              }
            })
          })

    }
  }
}
</script>

<style scoped>

</style>