<template>
  <section>
    <form @submit.prevent="handleSubmit(saveUnit)">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head" id="modal-event">
          <p class="modal-card-title">{{modalHeader}}</p>
          <button
              type="button"
              class="delete"
              @click="$emit('close')"/>
        </header>
        <section class="modal-card-body" id="modal-event2">
          <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
            <b-message type="is-danger" has-icon>
              <div v-for="(item,index) in errores_validacion" :key="index">
                <div v-if="item">{{ item[0] }}</div>
              </div>
            </b-message>
          </div>
            <!-- Box de Formulario Unidad -->
            <div class="columns">
              <div class="column">
                <b-field label="Nombre">
                    <b-input v-model="unit.name" type="text" :readonly=true></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Tipo de Unidad">
                  <b-input v-model="unit_type_name" type="text" :readonly=true></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Nombre corto">
                    <b-input v-model="unit.short_name" type="text" :readonly=true></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Centro de Costo">
                    <b-input v-model="unit.cost_center" type="text" :readonly=true></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Nombre de Unidad Mayor">
                  <b-input v-model="faculty_cost_center_name" type="text" :readonly=true></b-input>
                </b-field>

              </div>
              <div class="column">
                  <b-field label="Centro de Costo de Unidad Mayor">
                    <b-input v-model="unit.faculty_cost_center" type="text" :readonly=true></b-input>
                  </b-field>

              </div>

              <div class="column">
                <b-field label="¿Unidad de contratación?">
                  <b-checkbox label="" v-model="unit.contracting_unit" :disabled=true> Sí
                  </b-checkbox>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Url">
                    <b-input v-model="unit.url" type="text" :readonly=true></b-input>
                </b-field>
              </div>
            </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
      </section>
      </div>
      <footer class="modal-card-foot actions">
        <div class="actions has-text-right">
          <button class="button" type="button" @click="$emit('close')">Cerrar</button>
        </div>
      </footer>
    </form>
  </section>
</template>

<script>
import axios from "axios"
export default {
  name: "modal_unit_data",
  data(){
    const data =[]
    return {
      unit:{},
      errores_validacion: {},
      isLoading:false,
      isDisabled: false,
      faculty_cost_center_name: "",
      unit_type_name: ""
    }
  },
  props:[
    'unitId', 'modalHeader'
  ],
  created(){
    this.isLoading=true;
    return axios
        .get("/admin/units/"+this.unitId+".json")
        .then(res => {
          if(res.data["unit"]!== undefined){
            this.unit = res.data["unit"]
            this.faculty_cost_center_name = res.data["unit_faculty_cost_center_name"]
            this.unit_type_name = res.data["unit_type_name"]
          }
          else{
            this.unit = res.data
          }

          console.log(res.data)
          this.isLoading=false;
        }).catch(error => {
          console.log(error)
          this.isLoading=false;
        })

  },
  methods: {

  }
}
</script>

<style scoped>

</style>
