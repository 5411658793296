<template>
  <div class="tile is-ancestor has-text-centered">
    <div class="tile is-parent">
      <a :href="route" class="tile is-child box">
        <p class="subtitle is-4">
          <span class="icon">
            <i :class="['fas', icon]"></i>
          </span>
          {{ text }}
          <b-tooltip
            :label="tooltipText"
            position="is-top"
            size="is-small"
            multilined
          >
            <b-icon
              pack="fas"
              icon="info-circle"
              size="is-small"
              type="is-info"
            ></b-icon>
          </b-tooltip>
        </p>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    route: {
      type: String,
      default: "/",
    },
    icon: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "Menu item text",
    },
    tooltipText: {
      type: String,
      default: "Tooltip text",
    },
  },
};
</script>
