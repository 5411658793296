<template>
    <section>
        <b-tabs position="is-centered" v-model="currentTab" :multiline="true">
            <b-tab-item v-for="item in items" v-bind:key="item.content" :value="item.content" :label="item.tab"
                :icon="item.icon" :disabled="item.disabled" />
        </b-tabs>
        <!-- COMPONENTE AQUI -->
        <keep-alive>
            <component v-bind:is="currentTabComponent" :person-id="personId" :photo-url="photoUrl"
                :cargo-principal="cargoPrincipal" :hide-update="true" :can-create-document="canCreateDocument" :can_create_work_experience='can_create_work_experience'/>
        </keep-alive>

        <!-- FIN COMPONENTE -->
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </section>
</template>

<script>
import MyAcademicExperienceUSACH from './my_academic_experiences_usach_tab.vue'
import MyAcademicExperienceExternal from './my_academic_experience_external_tab.vue'
import MyAcademicThesisUsach from './my_academic_thesis_usach_tab.vue'
export default {
    components: {
        MyAcademicExperienceUSACH, MyAcademicExperienceExternal, MyAcademicThesisUsach
    },
    props: [
        'personId', 'photoUrl', 'cargoPrincipal', 'canCreateDocument', 'can_create_work_experience'
    ],
    data() {
        return {
            isLoading: false,
            currentTab: 'MyAcademicExperienceExternal',
            items: [
                { tab: 'Experiencia Docente USACH', content: 'MyAcademicExperienceUSACH', icon: "briefcase", disabled: true },
                //{ tab: 'Trabajos Guiados de Titulación USACH', content: 'MyAcademicThesisUsach', icon: "graduation-cap", disabled: true },
                { tab: 'Experiencia Docente Externa', content: 'MyAcademicExperienceExternal', icon: "briefcase", disabled: false },
            ],
        }
    },
    methods: {
        changeTab: function (value) {
            this.currentTab = value
        },
    },
    computed: {
        currentTabComponent: function () {
            return this.currentTab
        }
    }
}
</script>