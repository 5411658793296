<template>
  <section>
    <form v-if="teaching">
      <div class="modal-card" style="width: 1080px">
        <header class="modal-card-head">
          <p class="modal-card-title">Datos de la experiencia docente</p>
          <button
              type="button"
              class="delete"
              @click="$emit('close')"/>
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column">
              <b-field label="Asignatura">
                <b-input v-model="work_experience.subject_name"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Carrera, programa o curso">
                <b-input v-model="work_experience.career_name"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Institución">
                <b-input v-model="work_experience.partner_entity_name"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Horas de teoría">
                <b-input v-model="work_experience.h_theory"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Horas de ejercicio">
                <b-input v-model="work_experience.h_exercise"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Horas de laboratorio">
                <b-input v-model="work_experience.h_lab"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Duración">
                <b-input v-model="work_experience.duration_teaching_type_name"></b-input>
              </b-field>
            </div>
            <div v-if="work_experience.duration_teaching_type_id == 3" class="column">
              <b-field label="Duracion en semanas">
                <b-input v-model="work_experience.duration"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Veces dictado">
                <b-input v-model="work_experience.times"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Último semestre dictado">
                <b-input v-model="work_experience.semester"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Último año dictado">
                <b-input v-model="work_experience.year"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Tipo">
                <b-input v-model="work_experience.teaching_type_name"></b-input>
              </b-field>
            </div>
          </div>
          <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
        </section>
      </div>
      <footer class="modal-card-foot actions">
        <div class="has-text-right is-grouped">
          <button class="button" type="button" @click="$emit('close')">Cerrar</button>
          <b-button class="is-primary"
                    type="submit"
                    @click="editWorkExperience()">
            Editar Experiencia Laboral
          </b-button>
        </div>
      </footer>
      <b-modal
          v-model="isModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          width="90%"
          aria-role="dialog"
          aria-modal
          :can-cancel="false">
        <template #default="props">
          <modal_new_work_experience
              :person-id="personId"
              :update="update"
              :teaching="teaching"
              :old_work_experience="old_work_experience"
              @close="props.close"
              @refresh="$emit('refresh')"/>
        </template>
      </b-modal>
    </form>
    <form v-else>
      <div class="modal-card" style="width: 1080px">
        <header class="modal-card-head">
          <p class="modal-card-title">Datos de la experiencia laboral</p>
          <button
              type="button"
              class="delete"
              @click="$emit('close')"/>
        </header>
        <section class="modal-card-body">
          <!--          <div class="columns">
                      <div class="column">
                        <b-field label="Tipo de trabajo">
                          <b-input v-model="work_experience.work_type_name"></b-input>
                        </b-field>
                      </div>
                    </div>-->
          <div class="columns">
            <div class="column">
              <b-field label="Cargo">
                <b-input v-model="work_experience.position"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Tipo de cargo">
                <b-input v-model="work_experience.position_type_name"></b-input>
              </b-field>
            </div>
            <!--            <div class="column">
                          <b-field label="Tipo de participación">
                            <b-input v-model="work_experience.work_participant_type_name"></b-input>
                          </b-field>
                        </div>-->
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Descripción">
                <b-input v-model="work_experience.description"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Institución">
                <b-input v-model="work_experience.partner_entity_name"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Disciplina">
                <b-input v-model="work_experience.discipline"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Área de trabajo">
                <b-input v-model="work_experience.work_area_name"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Fecha de inicio">
                <b-input v-model="work_experience.start_date"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Fecha de término">
                <b-input v-model="work_experience.end_date"></b-input>
              </b-field>
            </div>
          </div>
          <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
        </section>
      </div>
      <footer class="modal-card-foot actions">
        <div class="has-text-right is-grouped">
          <button class="button" type="button" @click="$emit('close')">Cerrar</button>
          <b-button class="is-primary"
                    type="submit"
                    @click="editWorkExperience()">
            Editar Experiencia Laboral
          </b-button>
        </div>
      </footer>
      <b-modal
          v-model="isModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          width="90%"
          aria-role="dialog"
          aria-modal
          :can-cancel="false">
        <template #default="props">
          <modal_new_work_experience
              :person-id="personId"
              :update="update"
              :teaching="false"
              :old_work_experience="old_work_experience"
              @close="props.close"></modal_new_work_experience>
        </template>
      </b-modal>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import modal_new_work_experience from "./modal_new_work_experience";
import moment from "moment";
export default {
  name: "modal_show_work_experience",
  components: {modal_new_work_experience},
  props:['personId','old_work_experience','teaching'],
  data(){
    return{
      isModalActive: false,
      update: false,
      isLoading: false,
      work_experience: {}
    }
  },
  created() {
    this.initialize()
  },
  methods:{
    initialize(){
      this.getWorkExperience()
    },
    getWorkExperience(){
      this.update = false
      this.isLoading = true
      axios
          .get('/work_experiences/'+this.old_work_experience.id+'.json')
          .then(response => {
            this.work_experience = response.data
            if(!this.teaching){
              this.work_experience.start_date = moment(this.work_experience.start_date, ["YYYY-MM-DD","DD-MM-YYYY"])._d;
              this.work_experience.start_date = new Date(this.work_experience.start_date).toLocaleDateString("es-CL")
              if (this.work_experience.end_date != null){
                this.work_experience.end_date = moment(this.work_experience.end_date, ["YYYY-MM-DD","DD-MM-YYYY"])._d;
                this.work_experience.end_date = new Date(this.work_experience.end_date).toLocaleDateString("es-CL")
              }
              else{
                this.work_experience.end_date = ''
              }
            }

            this.isLoading = false
          })
          .catch(error=>{
            console.log(error)
            this.isLoading = false
          })
    },
    editWorkExperience(){
      this.update = true
      this.isModalActive = true
    }
  },
  watch: {
    isModalActive: function () {
      if (!this.isModalActive) {
        this.initialize()
      }
    },
  }
}
</script>

<style scoped>

</style>