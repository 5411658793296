<template>
  <section>
    <section class="hero has-background-white-bis" style="box-shadow: 0px 6px 14px -4px rgba(196,196,196,1);">
      <div class="hero-body">
        <div class="container">
          <div v-if="faculty=='true' && componentType != 'person'">
            <h1 class="title is-capitalized">
              {{ unidad.name}}
            </h1>
          </div>
          <div v-if="componentType == 'person'">
            <h1 class="title is-capitalized">
              Mis servicios
            </h1>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Año desde:">
                <multiselect v-model="minYear"
                             :options="year_options"
                             :searchable="true"
                             :close-on-select="true"
                             placeholder="Elija un año"
                             :allow-empty="true"
                             selectLabel="Presione para seleccionar"
                             selectedLabel="Seleccionado"
                             deselectLabel="Presione para deseleccionar"
                ></multiselect>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Año hasta:"
                       :type="{ 'is-danger': verifyYears }"
                       :message="{ 'Año hasta no puede ser menor al Año desde': verifyYears }">
                <multiselect v-model="maxYear"
                             :options="year_options"
                             :searchable="true"
                             :close-on-select="true"
                             placeholder="Elija un año"
                             :allow-empty="true"
                             selectLabel="Presione para seleccionar"
                             selectedLabel="Seleccionado"
                             deselectLabel="Presione para deseleccionar"
                ></multiselect>
              </b-field>
            </div>
            <div class="column">
              <br>
              <b-field label=" ">
                <b-tooltip label="Seleccione un rango de fechas para filtrar servicios EFUSACH."
                           position="is-left">
                  <b-button type="is-info" @click="applyFilter">
                    Aplicar filtro
                  </b-button>
                </b-tooltip>
              </b-field>

            </div>
          </div>
        </div>
      </div>

    </section>


    <section>
      <div class="box">
        <div class="container">
          <b-tabs position="is-centered" @input="changeTab">
            <b-tab-item v-for="item in items"
                        :value="item.content"
                    v-bind:key="item.content"
                    :label="item.tab"
                    :icon="item.icon"
                    v-if="item.permission">
        </b-tab-item>

      </b-tabs>
      <!-- COMPONENTE AQUI -->
      <keep-alive>
        <component  v-bind:is="currentTabComponent" :object-id="unitId" :component-type="componentType" :cargoPrincipal="cargoPrincipal"
                   :currentYear="year" :min-year="minYear" :max-year="maxYear" :change-filter="changeFilter" :hide-update="true" :unit-id="unitId"></component>
          </keep-alive>
          <!-- FIN COMPONENTE -->

        </div>
      </div>
    </section>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  </section>




</template>
<script>
import Summary from "./SummaryTab.vue";
import Services from "./ServicesTab.vue";
import Honorarios from "./HonorariosTab.vue";
import Customers from "./CustomersTab.vue";
import axios from "axios";
export default {
  components:{
    Summary,Honorarios, Services,Customers
  },
  props:[
    'unitId','cargoPrincipal','faculty','componentType'
  ],
  data(){
    return{
      units:[{name: " ", id:0}],
      isLoading:false,
      currentTab: 'Summary',
      items: [
        {tab: 'Resumen',content:'Summary',icon:"address-card", permission: true},
        {tab: 'Servicios',content:'Services',icon:"file-alt", permission: true},
        {tab: 'Honorarios',content:'Honorarios',icon:"users", permission: true},
        {tab: 'Clientes',content:'Customers',icon:"industry", permission: true},
      ],
      year:new Date().getFullYear(),
      minYear:new Date().getFullYear() - 5,
      maxYear:new Date().getFullYear(),
      year_options:Array.from({length:(new Date().getFullYear()-2000+1)},(v,k)=>k+2000).reverse(),
      currentYear:null,
      historicFilter:false,
      verifyYears:false,
      unidad: {},
      changeFilter:0,


    }
  },
  created(){
    let vm = this;
    vm.isLoading=true;
    axios
        .get("/sdt_services/form-data.json")
        .then(res => {
          vm.units = res.data.unidades;
          vm.unidad = vm.units.find(elemento => elemento.id == vm.unitId);
          vm.isLoading = false;
        })
        .catch(e => {
          console.log(e);
          vm.isLoading=false;
        });

  },
  methods:{
    changeTab: function(value){
      this.currentTab = value
    },
    applyFilter(){
      if (this.minYear!= null && this.maxYear!=null){
        if(this.minYear > this.maxYear){
          this.verifyYears = true
        }
        else{
          this.verifyYears = false
          if (this.changeFilter == 0){
            this.changeFilter = 1
          }
          else{
            this.changeFilter = 0
          }
        }
      }
      else{
        this.verifyYears = false
        if (this.changeFilter == 0){
          this.changeFilter = 1
        }
        else{
          this.changeFilter = 0
        }
      }
    }

  },
  computed:{
    currentTabComponent: function () {
      return this.currentTab
    }
  }
}
</script>

