<template>
    <div class="vimeactivitypartnerentitiesrelated">
        <div>
            <div class="container">
                <div v-if="can_create_participant" class="columns">
                    <div class="column">
                        <span class="is-pulled-right">
                            <button class="button is-primary" @click="newEntityRelated()">
                                <b-icon icon="plus"></b-icon>
                                <span>Añadir una nueva entidad</span>
                            </button>
                        </span>
                    </div>
                </div>
                <div class="columns">
                <div class="column has-text-right">
                  <b-field>
                    <b-switch v-model="cardLayout">
                      <span>
                        Formato tarjeta (sin filtros ni búsqueda)
                         <b-tooltip
                             label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                             multilined
                             position="is-left">
                              <b-icon
                                  pack="fas"
                                  icon="info-circle"
                                  type="is-info">
                              </b-icon>
                          </b-tooltip>
                      </span>
                    </b-switch>
                  </b-field>
                </div>
              </div>
            </div>
            <br>
            <b-table
                :data="activity_entities_assistance_data"
                :paginated=true
                per-page=10
                striped
                hoverable
                :current-page="currentPage"
                :card-layout="cardLayout"
                :pagination-simple=false
                pagination-position="bottom"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="publication.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
                <b-table-column
                    field="entity_name"
                    label= 'Nombre'
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    <a @click="goToEntity(props.row.id_entity)"><i class="fas fa-industry"></i> {{ props.row.entity_name }}</a>
                  </template>
                </b-table-column>
                <b-table-column
                    field="entity_type"
                    label= 'Tipo de entidad'
                    sortable
                    searchable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.entity_type}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="assistance_type_name"
                    label= 'Asiste como'
                    sortable
                    searchable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{props.row.assistance_type_name}}
                    </template>
                </b-table-column>
                <b-table-column
                    field="entity_phone"
                    label= 'Teléfono'
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    <div v-if='props.row.entity_phone === null'>No se ha encontrado registro</div>
                    <div v-else>{{props.row.entity_phone}}</div>
                  </template>
                </b-table-column>
                <b-table-column
                    field="entity_email"
                    label= 'Correo'
                    sortable
                    searchable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      <div v-if='props.row.entity_email === null'>No ha encontrado registro</div>
                      <div v-else>{{props.row.entity_email}}</div>
                    </template>
                </b-table-column>
                <b-table-column
                    v-if="can_destroy_participant"
                    label='acción'
                    v-slot="props">
                    <button class="button is-danger" @click="confirmActivityAssistantDelete(props.row)">
                        <b-icon icon="times"></b-icon>
                        <span>Eliminar</span>
                    </button>
                </b-table-column>
                <template slot="empty" slot-scope="props">
                  <div class="columns is-centered">
                    <div class="column is-6 is-centered has-text-centered">
                      <b-message has-icon type="is-warning">
                        <h1 v-if="can_create_participant"> No se encontraron resultados, haz click <a @click="newEntityRelated()">aquí</a> para ir a la gestión de entidades participantes.</h1>
                        <h1 v-else> No se encontraron resultados.</h1>
                      </b-message>
                    </div>
                  </div>
                </template>
            </b-table>
        </div>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
</template>
<script>
import axios from 'axios'
import VimeActivityNewEntitiesRelatedModal from '../../vime_activity_assistance/new_entities/vime_activity_new_entities_related_modal.vue'
export default {
    name: 'VimeActivityPartnerEntitiesRelated',
    props: {
        entities_related:{
            type: Array,
            default: () => []
        },
        vime_activity_id:{
            type: Number
        },
        modalEntitiesAssistance:{
            type: Array,
            default: () => []
        },
        modalEntitiesAssistanceTypes:{
            type: Array,
            default: () => []
        },
        can_create_participant:{
          type: Boolean
        },
        can_destroy_participant:{
          type: Boolean
        }
    },
    data(){
        return {
            currentPage:1,
            activity_entities_assistance_data: [],
            isLoading: false,
            cardLayout: false
        }
    },
    computed:{
        isEntitiesRelatedEmpty(){
            return Object.entries(this.entities_related).length !== 0
        },
        entitiesRelated:{
            get(){
                return this.activity_entities_assistance_data
            },
            set(newAssistant){
                this.activity_entities_assistance_data = newAssistant
            }
        }
    },
    beforeMount(){
      this.getVimeEntitiesAssistance()
    },
    methods:{
        newEntityRelated(){
            this.$buefy.modal.open({
                parent: this,
                component: VimeActivityNewEntitiesRelatedModal,
                props:{
                    'vime_activity_assistances_info': this.modalEntitiesAssistance,
                    'vime_assistant_types': this.modalEntitiesAssistanceTypes
                },
                hasModalCard: true,
                events:{
                    'add-assistant': this.addNewAssistance
                },
                customClass: 'is-primary',
                trapFocus: true
            })
        },
        addNewAssistance(newEntityRelated){
            var addPersonLikeNewAssistant = true
            this.entitiesRelated.forEach(function(element){
                if(element.id_entity === newEntityRelated.partner_entities_id)
                    addPersonLikeNewAssistant = false
            })
            if(addPersonLikeNewAssistant){
                this.isLoading=true
                axios.post('/vime_activity_assistances.json', {
                    'description': ' ',
                    'partner_entities_id': newEntityRelated.partner_entities_id,
                    'vime_assistant_type_id': newEntityRelated.assistance_type_id,
                    'vime_activity_id': this.vime_activity_id
                })
                .then( response =>{
                    var newData = newEntityRelated
                    newData.id = response.data.id
                    this.getVimeEntitiesAssistance()
                    this.$emit("change-entity-asistance",this.entitiesRelated)
                })
                .catch(e => {
                    console.log(e)
                    this.isLoading=false
                })
            }
            else{
                this.isLoading=false
                this.$buefy.dialog.alert({
                    title: 'Advertencia: Entidad ya agregada.',
                    message: 'La entidad que tratas de añadir ya existe.',
                    confirmText: 'Aceptar'
                })
            }
        },
        confirmActivityAssistantDelete(deleteActivityRelatedEntity){
            this.$buefy.dialog.confirm({
                message: '¿Quieres eliminar la entidad: <b>'+deleteActivityRelatedEntity.entity_name+'</b> del registro de participantes? este cambio no puede deshacerse.',
                confirmText: 'Eliminar',
                cancelText: 'Cancelar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.deleteAssistance(deleteActivityRelatedEntity.id)
            })
        },
        deleteAssistance(id){
          this.isLoading = true
            axios.delete('/vime_activity_assistances/'+id+'.json')
            .then( response =>{
                this.getVimeEntitiesAssistance()
                this.$emit("change-entity-asistance",this.entitiesRelated)
                this.$buefy.toast.open({
                    message: 'Entidad participante eliminada correctamente.',
                    type: 'is-primary'
                })
              this.isLoading = false
            })
            .catch(e => {
                console.log(e)
                this.isLoading = false
            })
        },
      goToEntity(id){
        window.location.href='/partner_entities/show/'+id
      },
      getVimeEntitiesAssistance(){
        this.isLoading = true
        axios.get('/vime_activity_assistances/getEntitiesRelatedByActivityId/'+this.vime_activity_id)
            .then(response =>{
              this.activity_entities_assistance_data = response.data.assistance
              this.activity_entities_assistance_data.forEach(element =>{
                if(element.entity_phone != null){
                  element.entity_phone =  element.entity_phone.toString(10)
                }
              })
              this.isLoading = false
            })
            .catch(e => {
              this.isLoading = false
              console.log(e)
            })
      },
    }
}
</script>
