<template>
  <section>


    <b-tabs position="is-centered" v-model="currentTab" :multiline="true" type="is-toggle">
      <b-tab-item v-for="item in items" v-bind:key="item.content" :value="item.content" :label="item.tab"
                  :icon="item.icon" :disabled="item.disabled" />
    </b-tabs>
    <!-- COMPONENTE AQUI -->
    <keep-alive>
      <component v-bind:is="currentTabComponent" :hide-update="hideUpdate" :min-year="minYear" :max-year="maxYear" :change-filter="changeFilter"
                 :cargo-principal="cargoPrincipal" :current-year="currentYear" :object-id="objectId" :component-type="componentType"/>
    </keep-alive>

<!--
    <publications-table v-bind:publicaciones="this.publicaciones" :hide-update="true" :cargoPrincipal="cargoPrincipal" @updatePublications="updatePublications"></publications-table>
-->
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  </section>
</template>
<script>
import axios from "axios";
import PublicationsTable from "../publications/publications_table.vue";
import MagazinePublicationTab from "../units/magazine_publication_tab.vue";
import BookPublicationTab from "../units/book_publication_tab.vue";
import moment from "moment";
export default {
  props:[
    'objectId','componentType', 'cargoPrincipal','currentYear','minYear','maxYear', 'changeFilter', 'hideUpdate'
  ],
  data(){
    return{
      publicaciones:[],
      isLoading:false,
      year: new Date().getFullYear(),
      currentTab: 'MagazinePublicationTab',
      items: [
        {tab: 'Publicaciones en Revista',content: 'MagazinePublicationTab', icon:"newspaper", disabled: false},
        {tab: 'Publicaciones en Libro',content: 'BookPublicationTab', icon:"book", disabled: false},
      ],
    }
  },
  components:{
    PublicationsTable, MagazinePublicationTab, BookPublicationTab
  },
  created(){
    //this.years = this.generateArrayOfYears();
    //this.getPublications();

  },
  methods:{

    getPublications(){
      let vm = this;
      vm.isLoading=true;
      var url = "";
      if (vm.componentType == "person"){
        url = "/people/"+this.objectId+"/publications.json"
      }
      else if (vm.componentType=="unit"){
        url = "/admin/units/"+this.objectId+"/publications.json"
      }
      if(this.hideUpdate == true){
        vm.year = 'MyActivity'
      }
      axios
        .get(url, {params:{
          year_f: vm.year,
            minYear: vm.minYear,
            maxYear: vm.maxYear
        }})
        .then(res => {
          vm.isLoading=false;
          vm.publicaciones = res.data;
          vm.publicaciones = vm.publicaciones.map(publicacion => {
            var publicacion = publicacion
            publicacion["publication_date"] = moment(publicacion.publication_date, ["YYYY-MM-DD","DD-MM-YYYY"]);;
            return publicacion;
          });
        })
        .catch(e => {
          console.log(e);
          vm.isLoading=false;

        });
    },
    updatePublications(year){
      this.year = year
      this.getPublications();
    }
  },
  computed: {
    currentTabComponent: function () {
      return this.currentTab
    }
  },
  /*watch:{
    changeFilter: function(){
      this.getPublications()
    }
  }*/
}
</script>

