<template>
    <div class="configurationagreementeditsectionmodal">
        <form action="">
            <div class="modal-card" style="width: auto;">
                <header class="modal-card-head">
                    <p class="modal-card-title" v-if='edit_case === 0'>Añadir un tipo de convenio general</p>
                    <p class="modal-card-title" v-if='edit_case === 1'>Añadir un tipo de convenio específico</p>
                    <p class="modal-card-title" v-if='edit_case === 2'>Añadir una nueva etapa </p>
                    <p class="modal-card-title" v-if='edit_case === 3'>Añadir un proceso </p>
                    <p class="modal-card-title" v-if='edit_case === 4'>Añadir un actor </p>
                    <p class="modal-card-title" v-if='edit_case === 5'>Editar tipo de convenio general</p>
                    <p class="modal-card-title" v-if='edit_case === 6'>Editar tipo de convenio específico</p>
                    <p class="modal-card-title" v-if='edit_case === 7'>Editar etapa </p>
                    <p class="modal-card-title" v-if='edit_case === 8'>Editar proceso </p>
                    <p class="modal-card-title" v-if='edit_case === 9'>Editar actor </p>
                    <button
                        type="button"
                        class="delete"
                        @click="$emit('close')"/>
                </header>
                <section class="modal-card-body">

                    <!-- convenio general -->
                    <div v-if='edit_case === 0 || edit_case === 5'>
                        <b-field label="Nombre"
                            :type="{ 'is-danger': checkAgreementGeneralTypeName }"
                            :message="{ 'Ingrese un nombre para el tipo de convenio general.': checkAgreementGeneralTypeName }">
                            <b-input 
                                v-model='agreementGeneralTypeName'
                                :value='agreementGeneralTypeName!=undefined ? agreementGeneralTypeName : ""'
                                maxlength="255"
                                placeholder="Nombre del tipo de convenio general"
                                required>
                            </b-input>
                        </b-field>

                        <b-field label="Descripción"
                            :type="{ 'is-danger': checkAgreementGeneralTypeDescription }"
                            :message="{ 'Ingrese una descripción para el tipo de convenio general.': checkAgreementGeneralTypeDescription }">
                            <b-input
                                type="textarea"
                                v-model='agreementGeneralTypeDescription'
                                :value='agreementGeneralTypeDescription!=undefined ? agreementGeneralTypeDescription : ""'
                                maxlength="255"
                                placeholder="Descripción del tipo de convenio general"
                                required>
                            </b-input>
                        </b-field>

                        <b-field label="Objetivo"
                            :type="{ 'is-danger': checkAgreementGeneralTypeObjective }"
                            :message="{ 'Ingrese el objetivo para este tipo de convenio general.': checkAgreementGeneralTypeObjective }">
                            <b-input 
                                v-model='agreementGeneralTypeObjective'
                                :value='agreementGeneralTypeObjective!=undefined ? agreementGeneralTypeObjective : ""'
                                maxlength="255"
                                placeholder="Ingrese el objetivo de este tipo de convenio"
                                required>
                            </b-input>
                        </b-field>

                        <b-field label="Aprueba"
                            :type="{ 'is-danger': checkAgreementGeneralTypeSignatory }"
                            :message="{ 'Ingrese la autoridad que aprueba este tipo de convenio general.': checkAgreementGeneralTypeSignatory }">
                            <b-input 
                                v-model='agreementGeneralTypeSignatory'
                                :value='agreementGeneralTypeSignatory!=undefined ? agreementGeneralTypeSignatory : ""'
                                maxlength="255"
                                placeholder="Ingrese la autoridad relacionada a este tipo de convenio"
                                required>
                            </b-input>
                        </b-field>

                        <b-field label="Información adicional"
                            :type="{ 'is-danger': checkAgreementGeneralTypeAditionalInformation }"
                            :message="{ 'Ingrese la información adicional.': checkAgreementGeneralTypeAditionalInformation }">
                            <b-input
                                type="textarea"
                                v-model='agreementGeneralTypeAditionalInformation'
                                :value='agreementGeneralTypeAditionalInformation!=undefined ? agreementGeneralTypeAditionalInformation : ""'
                                maxlength="255"
                                placeholder="Información adicional del tipo de convenio general"
                                required>
                            </b-input>
                        </b-field>
                    </div>

                    <!-- convenio específico -->
                    <div v-if='edit_case === 1 || edit_case === 6'>
                        <b-field label="Nombre"
                            :type="{ 'is-danger': checkAgreementSpecifictTypeName }"
                            :message="{ 'Ingrese un nombre para el tipo de convenio específico.': checkAgreementSpecifictTypeName }">
                            <b-input 
                                v-model='agreementSpecifictTypeName'
                                :value='agreementSpecifictTypeName!=undefined ? agreementSpecifictTypeName : ""'
                                maxlength="255"
                                placeholder="Nombre del tipo de convenio específico"
                                required>
                            </b-input>
                        </b-field>

                        <b-field label="Descripción"
                            :type="{ 'is-danger': checkAgreementSpecifictTypeDescription }"
                            :message="{ 'Ingrese una descripción para el tipo de convenio específico.': checkAgreementSpecifictTypeDescription }">
                            <b-input
                                type="textarea"
                                v-model='agreementSpecifictTypeDescription'
                                :value='agreementSpecifictTypeDescription!=undefined ? agreementSpecifictTypeDescription : ""'
                                maxlength="255"
                                placeholder="Descripción del tipo de convenio específico"
                                required>
                            </b-input>
                        </b-field>
                    </div>

                    <!-- etapas -->
                    <div v-if='edit_case === 2 || edit_case === 7'>
                        <b-field label="Nombre"
                            :type="{ 'is-danger': checkAgreementStageName }"
                            :message="{ 'Ingrese un nombre para la etapa.': checkAgreementStageName }">
                            <b-input 
                                v-model='agreementStageName'
                                :value='agreementStageName!=undefined ? agreementStageName : ""'
                                maxlength="255"
                                placeholder="Nombre de la etapa"
                                required>
                            </b-input>
                        </b-field>

                        <b-field label="Descripción"
                            :type="{ 'is-danger': checkAgreementStageDescription }"
                            :message="{ 'Ingrese una descripción para la etapa.': checkAgreementStageDescription }">
                            <b-input
                                type="textarea"
                                v-model='agreementStageDescription'
                                :value='agreementStageDescription!=undefined ? agreementStageDescription : ""'
                                maxlength="255"
                                placeholder="Descripción de la etapa"
                                required>
                            </b-input>
                        </b-field>
                    </div>

                    <!-- procesos -->
                    <div v-if='edit_case === 3 || edit_case === 8'>
                        <b-field label="Nombre"
                            :type="{ 'is-danger': checkAgreementProcessName }"
                            :message="{ 'Ingrese un nombre para el proceso.': checkAgreementProcessName }">
                            <b-input 
                                v-model='agreementProcessName'
                                :value='agreementProcessName!=undefined ? agreementProcessName : ""'
                                maxlength="255"
                                placeholder="Nombre del proceso"
                                required>
                            </b-input>
                        </b-field>
                        
                        <b-field label="Etapa asociada"
                            :type="{ 'is-danger': checkAgreementProcessAgreementStageName }"
                            :message="{ 'Seleccione una etapa para este proceso.': checkAgreementProcessAgreementStageName }">
                            <b-select placeholder="Seleccione una etapa" 
                                        v-model="agreementProcessAgreementStageName" 
                                        :value="agreementProcessAgreementStageName!==undefined ? agreementProcessAgreementStageName : ''"
                                        expanded>
                                    <option v-for="general_type in agreement_stages" :key="general_type.id">
                                        {{ general_type.name }}
                                    </option>
                            </b-select>
                        </b-field>

                        <b-field label="Descripción"
                            :type="{ 'is-danger': checkAgreementProcessDescription }"
                            :message="{ 'Ingrese una descripción para el proceso.': checkAgreementProcessDescription }">
                            <b-input
                                type="textarea"
                                v-model='agreementProcessDescription'
                                :value='agreementProcessDescription!=undefined ? agreementProcessDescription : ""'
                                maxlength="255"
                                placeholder="Descripción del proceso"
                                required>
                            </b-input>
                        </b-field>

                        <b-field label="Documentos asociados"
                            :type="{ 'is-danger': checkAgreementProcessAssociatedDocuments }"
                            :message="{ 'Ingrese los documentos asociados.': checkAgreementProcessAssociatedDocuments }">
                            <b-input
                                type="textarea"
                                v-model='agreementProcessAssociatedDocuments'
                                :value='agreementProcessAssociatedDocuments!=undefined ? agreementProcessAssociatedDocuments : ""'
                                maxlength="255"
                                placeholder="e.g. Borrador convenio, convenio, resolución de convenio, etc."
                                required>
                            </b-input>
                        </b-field>
                    </div>
                    
                    <!-- actores -->
                    <div v-if='edit_case === 4 || edit_case === 9'>
                        <b-field label="Nombre"
                            :type="{ 'is-danger': checkAgreementActorName }"
                            :message="{ 'Ingrese un nombre para el actor.': checkAgreementActorName }">
                            <b-input 
                                v-model='agreementActorName'
                                :value='agreementActorName!=undefined ? agreementActorName : ""'
                                maxlength="255"
                                placeholder="Nombre del actor"
                                required>
                            </b-input>
                        </b-field>

                        <b-field label="Tipo de actor"
                            :type="{ 'is-danger': checkAgreementActorActorType }"
                            :message="{ 'Ingrese el tipo de actor.': checkAgreementActorActorType }">
                            <b-input 
                                v-model='agreementActorActorType'
                                :value='agreementActorActorType!=undefined ? agreementActorActorType : ""'
                                maxlength="255"
                                placeholder="e.g. Decano, Rector, etc."
                                required>
                            </b-input>
                        </b-field>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <b-button
                        label="Cerrar"
                        @click="$emit('close')" />
                    <b-button
                        v-if='this.edit_case < 5'
                        label="Añadir"
                        type="is-primary"
                        @click="sendAgreementSection()"/>
                    <b-button
                        v-else
                        label="Guardar cambios"
                        type="is-primary"
                        @click="sendAgreementSection()"/>
                </footer>
            </div>
        </form>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: 'ConfigurationAgreementEditSectionModal',
    props:{
        edit_case:{
            type: Number
        },
        agreement_data_section:{
            type: undefined
        }
    },
    data(){
        return {
            // Para el almacenamiento temporal
            agreement_stages: [],

            // Para el envio de datos
            agreement_general_type:{
                name: undefined,
                description: undefined,
                objective: undefined,
                signatory: undefined,
                aditional_information: undefined
            },
            agreement_specifict_type:{
                name: undefined,
                description: undefined   
            },
            agreement_stage:{
                name: undefined,
                description: undefined
            },
            agreement_process:{
                name: undefined,
                description: undefined,
                associated_documents: undefined,
                agreement_stage_id: undefined
            },
            agreement_actor:{
                name: undefined,
                actor_type: undefined
            },

            // Para las validaciones
            validations:{
                // Agreement general type empty
                isAgreementGeneralTypeNameEmpty: true,
                isAgreementGeneralTypeDescriptionEmpty: true,
                isAgreementGeneralTypeObjectiveEmpty: true,
                isAgreementGeneralTypeSignatoryEmpty: true,
                isAgreementGeneralTypeAditionalInformationEmpty: true,

                // Agreement specifict type empty
                isAgreementSpecifictTypeNameEmpty: true,
                isAgreementSpecifictTypeDescriptionEmpty: true,

                // Agreement stage empty
                isAgreementStageNameEmpty: true,
                isAgreementStageDescriptionEmpty: true,
                
                // Agreement process empty
                isAgreementProcessNameEmpty: true,
                isAgreementProcessDescriptionEmpty: true,
                isAgreementProcessAssociatedDocumentsEmpty: true,
                isAgreementProcessAgreementStageNameEmpty: true,

                // Agreement actor empty
                isAgreementActorNameEmpty: true,
                isAgreementActorActorTypeEmpty: true,

                // First time agreement general type
                firstTimeAgreementGeneralTypeName: true,
                firstTimeAgreementGeneralTypeDescription: true,
                firstTimeAgreementGeneralTypeObjective: true,
                firstTimeAgreementGeneralTypeSignatory: true,
                firstTimeAgreementGeneralTypeAditionalInformation: true,

                // First time agreement specifict type
                firstTimeAgreementSpecifictTypeName: true,
                firstTimeAgreementSpecifictTypeDescription: true,

                // First time agreement stage
                firstTimeAgreementStageName: true,
                firstTimeAgreementStageDescription: true,

                // First time agreement process
                firstTimeAgreementProcessName: true,
                firstTimeAgreementProcessDescription: true,
                firstTimeAgreementProcessAssociatedDocuments: true,
                firstTimeAgreementProcessAgreementStageName: true,

                // First time agreement actor
                firstTimeAgreementActorName: true,
                firstTimeAgreementActorActorType: true,
            },
        }
    },
    computed:{
        // Para el control de los atributos
        agreementGeneralTypeName:{
            get: function (){
                return this.agreement_general_type.name
            },
            set: function (newName){
                this.validations.firstTimeAgreementGeneralTypeName = false
                if(newName === '')
                    this.validations.isAgreementGeneralTypeNameEmpty = true
                else
                    this.validations.isAgreementGeneralTypeNameEmpty = false
                this.agreement_general_type.name = newName
            }
        },
        agreementGeneralTypeDescription:{
            get: function (){
                return this.agreement_general_type.description
            },
            set: function (newDescription){
                this.validations.firstTimeAgreementGeneralTypeDescription = false
                if(newDescription === '')
                    this.validations.isAgreementGeneralTypeDescriptionEmpty = true
                else
                    this.validations.isAgreementGeneralTypeDescriptionEmpty = false
                this.agreement_general_type.description = newDescription
            }
        },
        agreementGeneralTypeObjective:{
            get: function (){
                return this.agreement_general_type.objective
            },
            set: function (newObjective){
                this.validations.firstTimeAgreementGeneralTypeObjective = false
                if(newObjective === '')
                    this.validations.isAgreementGeneralTypeObjectiveEmpty = true
                else
                    this.validations.isAgreementGeneralTypeObjectiveEmpty = false
                this.agreement_general_type.objective = newObjective
            }
        },
        agreementGeneralTypeSignatory:{
            get: function (){
                return this.agreement_general_type.signatory
            },
            set: function (newSignatory){
                this.validations.firstTimeAgreementGeneralTypeSignatory = false
                if(newSignatory === '')
                    this.validations.isAgreementGeneralTypeSignatoryEmpty = true
                else
                    this.validations.isAgreementGeneralTypeSignatoryEmpty = false
                this.agreement_general_type.signatory = newSignatory
            }
        },
        agreementGeneralTypeAditionalInformation:{
            get: function (){
                return this.agreement_general_type.aditional_information
            },
            set: function (newAditionalInformation){
                this.validations.firstTimeAgreementGeneralTypeAditionalInformation = false
                if(newAditionalInformation === '')
                    this.validations.isAgreementGeneralTypeAditionalInformationEmpty = true
                else
                    this.validations.isAgreementGeneralTypeAditionalInformationEmpty = false
                this.agreement_general_type.aditional_information = newAditionalInformation
            }
        },
        agreementSpecifictTypeName:{
            get: function (){
                return this.agreement_specifict_type.name
            },
            set: function (newName){
                this.validations.firstTimeAgreementSpecifictTypeName = false
                if(newName === '')
                    this.validations.isAgreementSpecifictTypeNameEmpty = true
                else
                    this.validations.isAgreementSpecifictTypeNameEmpty = false
                this.agreement_specifict_type.name = newName
            }
        },
        agreementSpecifictTypeDescription:{
            get: function (){
                return this.agreement_specifict_type.description
            },
            set: function (newDescription){
                this.validations.firstTimeAgreementSpecifictTypeDescription = false
                if(newDescription === '')
                    this.validations.isAgreementSpecifictTypeDescriptionEmpty = true
                else
                    this.validations.isAgreementSpecifictTypeDescriptionEmpty = false
                this.agreement_specifict_type.description = newDescription
            }
        },
        agreementStageName:{
            get: function(){
                return this.agreement_stage.name
            },
            set: function(newName){
                this.validations.firstTimeAgreementStageName = false
                if(newName === '')
                    this.validations.isAgreementStageNameEmpty = true
                else
                    this.validations.isAgreementStageNameEmpty = false
                this.agreement_stage.name = newName
            }
        },
        agreementStageDescription:{
            get: function(){
                return this.agreement_stage.description
            },
            set: function(newDescription){
                this.validations.firstTimeAgreementStageDescription = false
                if(newDescription === '')
                    this.validations.isAgreementStageDescriptionEmpty = true
                else
                    this.validations.isAgreementStageDescriptionEmpty = false
                this.agreement_stage.description = newDescription
            }
        },
        agreementProcessName:{
            get: function(){
                return this.agreement_process.name
            },
            set: function(newName){
                this.validations.firstTimeAgreementProcessName = false
                if(newName === '')
                    this.validations.isAgreementProcessNameEmpty = true
                else
                    this.validations.isAgreementProcessNameEmpty = false
                this.agreement_process.name = newName
            }
        },
        agreementProcessDescription:{
            get: function(){
                return this.agreement_process.description
            },
            set: function(newDescription){
                this.validations.firstTimeAgreementProcessDescription = false
                if(newDescription === '')
                    this.validations.isAgreementProcessDescriptionEmpty = true
                else
                    this.validations.isAgreementProcessDescriptionEmpty = false
                this.agreement_process.description = newDescription
            }
        },
        agreementProcessAssociatedDocuments:{
            get: function(){
                return this.agreement_process.associated_documents
            },
            set: function(newAssociatedDocuments){
                this.validations.firstTimeAgreementProcessAssociatedDocuments = false
                if(newAssociatedDocuments === '')
                    this.validations.isAgreementProcessAssociatedDocumentsEmpty = true
                else
                    this.validations.isAgreementProcessAssociatedDocumentsEmpty = false
                this.agreement_process.associated_documents = newAssociatedDocuments
            }
        },
        agreementProcessAgreementStageName:{
            get: function(){
                return this.agreement_stage.name
            },
            set: function(newAgreementStageName){
                this.validations.firstTimeAgreementProcessAgreementStageName = false
                if(newAgreementStageName === '')
                    this.validations.isAgreementProcessAgreementStageNameEmpty = true
                else
                    this.validations.isAgreementProcessAgreementStageNameEmpty = false
                this.agreement_stage.name = newAgreementStageName
            }
        },
        agreementActorName:{
            get: function(){
                return this.agreement_actor.name
            },
            set: function(newName){
                this.validations.firstTimeAgreementActorName = false
                if(newName === '')
                    this.validations.isAgreementActorNameEmpty = true
                else
                    this.validations.isAgreementActorNameEmpty = false
                this.agreement_actor.name = newName
            }
        },
        agreementActorActorType:{
            get: function(){
                return this.agreement_actor.actor_type
            },
            set: function(newActorType){
                this.validations.firstTimeAgreementActorActorType = false
                if(newActorType === '')
                    this.validations.isAgreementActorActorTypeEmpty = true
                else
                    this.validations.isAgreementActorActorTypeEmpty = false
                this.agreement_actor.actor_type = newActorType
            }
        },

        // Validaciones en los campos del formulario
        checkAgreementGeneralTypeName(){
            if(this.validations.firstTimeAgreementGeneralTypeName === false)
                return this.validations.isAgreementGeneralTypeNameEmpty
            else
                return false
        },
        checkAgreementGeneralTypeDescription(){
            if(this.validations.firstTimeAgreementGeneralTypeDescription === false)
                return this.validations.isAgreementGeneralTypeDescriptionEmpty
            else
                return false
        },
        checkAgreementGeneralTypeObjective(){
            if(this.validations.firstTimeAgreementGeneralTypeObjective === false)
                return this.validations.isAgreementGeneralTypeObjectiveEmpty
            else
                return false
        },
        checkAgreementGeneralTypeSignatory(){
            if(this.validations.firstTimeAgreementGeneralTypeSignatory === false)
                return this.validations.isAgreementGeneralTypeSignatoryEmpty
            else
                return false
        },
        checkAgreementGeneralTypeAditionalInformation(){
            if(this.validations.firstTimeAgreementGeneralTypeAditionalInformation === false)
                return this.validations.isAgreementGeneralTypeAditionalInformationEmpty
            else
                return false
        },
        checkAgreementSpecifictTypeName(){
            if(this.validations.firstTimeAgreementSpecifictTypeName === false)
                return this.validations.isAgreementSpecifictTypeNameEmpty
            else
                return false
        },
        checkAgreementSpecifictTypeDescription(){
            if(this.validations.firstTimeAgreementSpecifictTypeDescription === false)
                return this.validations.isAgreementSpecifictTypeDescriptionEmpty
            else
                return false
        },
        checkAgreementStageName(){
            if(this.validations.firstTimeAgreementStageName === false)
                return this.validations.isAgreementStageNameEmpty
            else
                return false
        },
        checkAgreementStageDescription(){
            if(this.validations.firstTimeAgreementStageDescription === false)
                return this.validations.isAgreementStageDescriptionEmpty
            else
                return false
        },
        checkAgreementProcessName(){
            if(this.validations.firstTimeAgreementProcessName === false)
                return this.validations.isAgreementProcessNameEmpty
            else
                return false
        },
        checkAgreementProcessDescription(){
            if(this.validations.firstTimeAgreementProcessDescription === false)
                return this.validations.isAgreementProcessDescriptionEmpty
            else
                return false
        },
        checkAgreementProcessAssociatedDocuments(){
            if(this.validations.firstTimeAgreementProcessAssociatedDocuments === false)
                return this.validations.isAgreementProcessAssociatedDocumentsEmpty
            else
                return false
        },
        checkAgreementProcessAgreementStageName(){
            if(this.validations.firstTimeAgreementProcessAgreementStageName === false)
                return this.validations.isAgreementProcessAgreementStageNameEmpty
            else
                return false
        },
        checkAgreementActorName(){
            if(this.validations.firstTimeAgreementActorName === false)
                return this.validations.isAgreementActorNameEmpty
            else
                return false
        },
        checkAgreementActorActorType(){
            if(this.validations.firstTimeAgreementActorActorType === false)
                return this.validations.isAgreementActorActorTypeEmpty
            else
                return false
        },
    },
    beforeMount(){
        if(this.edit_case == 3){
            this.getAgreementsStages()
        }
        else if(this.edit_case >= 5){
            switch(this.edit_case){
                case 5:
                    this.agreementGeneralTypeName = this.agreement_data_section.name
                    this.agreementGeneralTypeDescription = this.agreement_data_section.description
                    this.agreementGeneralTypeObjective = this.agreement_data_section.objective
                    this.agreementGeneralTypeSignatory = this.agreement_data_section.signatory
                    this.agreementGeneralTypeAditionalInformation = this.agreement_data_section.aditional_information
                    this.agreement_general_type.id = this.agreement_data_section.id
                    break;
                case 6:
                    this.agreementSpecifictTypeName = this.agreement_data_section.name
                    this.agreementSpecifictTypeDescription = this.agreement_data_section.description
                    this.agreement_specifict_type.id = this.agreement_data_section.id                    
                    break;
                case 7:
                    this.agreementStageName = this.agreement_data_section.name 
                    this.agreementStageDescription = this.agreement_data_section.description
                    this.agreement_stage.id = this.agreement_data_section.id
                    break;
                case 8:
                    this.getAgreementsStages()
                    this.agreementProcessName = this.agreement_data_section.name
                    this.agreementProcessAgreementStageName = this.agreement_data_section.agreement_stage
                    this.agreementProcessDescription = this.agreement_data_section.description
                    this.agreementProcessAssociatedDocuments = this.agreement_data_section.associated_documents
                    this.agreement_process.id = this.agreement_data_section.id
                    break;
                default:
                    this.agreementActorName = this.agreement_data_section.name
                    this.agreementActorActorType = this.agreement_data_section.actor_type
                    this.agreement_actor.id = this.agreement_data_section.id
                    
            }
        }
    },
    methods:{
        // Consultas al backend
        getAgreementsStages(){
            axios.get('/agreement_stages.json')
            .then( response =>{
                this.agreement_stages = response.data
            })
            .catch(e => { 
                console.log(e)
            })
        },
        // Transormación de datos
        getStageIdByStageName(searched_stage_name){
            var id = 0
            this.agreement_stages.forEach(function(element){
                if(element.name === searched_stage_name){
                    id = element.id
                }
            });
            return id
        },
        getStageNameByStageId(searched_stage_id){
            var stage_name = ""
            this.agreement_stages.forEach(function(element){
                if(element.id === searched_stage_id){
                    stage_name = element.name
                }
            });
            return stage_name
        },
        // Revisión del formulario y respuestas
        checkEmptyFields(){
            if(this.edit_case === 0 || this.edit_case === 5){
                if(this.validations.firstTimeAgreementGeneralTypeName === true){
                    this.validations.firstTimeAgreementGeneralTypeName = false
                }
                if(this.validations.firstTimeAgreementGeneralTypeDescription === true){
                    this.validations.firstTimeAgreementGeneralTypeDescription = false
                }
                if(this.validations.firstTimeAgreementGeneralTypeObjective === true){
                    this.validations.firstTimeAgreementGeneralTypeObjective = false
                }
                if(this.validations.firstTimeAgreementGeneralTypeSignatory === true){
                    this.validations.firstTimeAgreementGeneralTypeSignatory = false
                }
                if(this.validations.firstTimeAgreementGeneralTypeAditionalInformation === true){
                    this.validations.firstTimeAgreementGeneralTypeAditionalInformation = false
                }
            }
            else if(this.edit_case === 1 || this.edit_case === 6){
                if(this.validations.firstTimeAgreementSpecifictTypeName === true){
                    this.validations.firstTimeAgreementSpecifictTypeName = false
                }
                if(this.validations.firstTimeAgreementSpecifictTypeDescription === true){
                    this.validations.firstTimeAgreementSpecifictTypeDescription = false
                }
            }
            else if(this.edit_case === 2 || this.edit_case === 7){
                if(this.validations.firstTimeAgreementStageName === true){
                    this.validations.firstTimeAgreementStageName = false
                }
                if(this.validations.firstTimeAgreementStageDescription === true){
                    this.validations.firstTimeAgreementStageDescription = false
                }
            }
            else if(this.edit_case === 3 || this.edit_case === 8){
                if(this.validations.firstTimeAgreementProcessName === true){
                    this.validations.firstTimeAgreementProcessName = false
                }
                if(this.validations.firstTimeAgreementProcessDescription === true){
                    this.validations.firstTimeAgreementProcessDescription = false
                }
                if(this.validations.firstTimeAgreementProcessAssociatedDocuments === true){
                    this.validations.firstTimeAgreementProcessAssociatedDocuments = false
                }
                if(this.validations.firstTimeAgreementProcessAgreementStageName === true){
                    this.validations.firstTimeAgreementProcessAgreementStageName = false
                }
            }
            else if(this.edit_case === 4 || this.edit_case === 9){
                if(this.validations.firstTimeAgreementActorName === true){
                    this.validations.firstTimeAgreementActorName = false
                }
                if(this.validations.firstTimeAgreementActorActorType === true){
                    this.validations.firstTimeAgreementActorActorType = false
                }
            }
        },
        checkSave(){
            var confirmation = true
            var arrayConfirmation = []

            if(this.edit_case === 0 || this.edit_case === 5){
                arrayConfirmation.push(this.validations.isAgreementGeneralTypeNameEmpty)
                arrayConfirmation.push(this.validations.isAgreementGeneralTypeDescriptionEmpty)
                arrayConfirmation.push(this.validations.isAgreementGeneralTypeObjectiveEmpty)
                arrayConfirmation.push(this.validations.isAgreementGeneralTypeSignatoryEmpty)
                arrayConfirmation.push(this.validations.isAgreementGeneralTypeAditionalInformationEmpty)    
            }
            else if(this.edit_case === 1 || this.edit_case === 6){
                arrayConfirmation.push(this.validations.isAgreementSpecifictTypeNameEmpty)
                arrayConfirmation.push(this.validations.isAgreementSpecifictTypeDescriptionEmpty)
            }
            else if(this.edit_case === 2 || this.edit_case === 7){
                arrayConfirmation.push(this.validations.isAgreementStageNameEmpty)
                arrayConfirmation.push(this.validations.isAgreementStageDescriptionEmpty)
            }
            else if(this.edit_case === 3 || this.edit_case === 8){
                arrayConfirmation.push(this.validations.isAgreementProcessNameEmpty)
                arrayConfirmation.push(this.validations.isAgreementProcessDescriptionEmpty)
                arrayConfirmation.push(this.validations.isAgreementProcessAssociatedDocumentsEmpty)
                arrayConfirmation.push(this.validations.isAgreementProcessAgreementStageNameEmpty)
            }
            else if(this.edit_case === 4 || this.edit_case === 9){
                arrayConfirmation.push(this.validations.isAgreementActorNameEmpty)
                arrayConfirmation.push(this.validations.isAgreementActorActorTypeEmpty)
            }
            arrayConfirmation.forEach(function(element){
                if(element === true)
                    confirmation = false
            })
            return new Promise((resolve) => {
                resolve(confirmation)
            })
        },
        async sendAgreementSection(){
            this.checkSubmiting = true
            var confirmacion    = await this.checkSave()
            var data_to_send    = {}
            data_to_send.edit_case = this.edit_case
            if(confirmacion){
                switch(this.edit_case){
                    case 0:
                        data_to_send.data = this.agreement_general_type
                        this.$emit('add-new-agreement-section', data_to_send);
                        break;
                    case 1:
                        data_to_send.data = this.agreement_specifict_type
                        this.$emit('add-new-agreement-section', data_to_send);
                        break;
                    case 2:
                        data_to_send.data = this.agreement_stage
                        this.$emit('add-new-agreement-section', data_to_send);
                        break;
                    case 3:
                        this.agreement_process.agreement_stage_id = this.getStageIdByStageName(this.agreementProcessAgreementStageName)
                        data_to_send.data = this.agreement_process
                        this.$emit('add-new-agreement-section', data_to_send);
                        break;
                    case 4:
                        data_to_send.data = this.agreement_actor
                        this.$emit('add-new-agreement-section', data_to_send);
                        break;
                    case 5:
                        data_to_send.data = this.agreement_general_type
                        this.$emit('edit-agreement-section', data_to_send);
                        break;
                    case 6:
                        data_to_send.data = this.agreement_specifict_type
                        this.$emit('edit-agreement-section', data_to_send);
                        break;
                    case 7:
                        data_to_send.data = this.agreement_stage
                        this.$emit('edit-agreement-section', data_to_send);
                        break;
                    case 8:
                        this.agreement_process.agreement_stage_id = this.getStageIdByStageName(this.agreementProcessAgreementStageName)
                        data_to_send.data = this.agreement_process
                        this.$emit('edit-agreement-section', data_to_send);
                        break;
                    default:
                        data_to_send.data = this.agreement_actor
                        this.$emit('edit-agreement-section', data_to_send);
                }
                this.$emit('close');
            }
            else{
                this.checkEmptyFields();
                this.checkSubmiting = false
            }
        }
    }
}
</script>