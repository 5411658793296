import { render, staticRenderFns } from "./modal_external_people_show.vue?vue&type=template&id=4404e1ff&scoped=true"
import script from "./modal_external_people_show.vue?vue&type=script&lang=js"
export * from "./modal_external_people_show.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4404e1ff",
  null
  
)

export default component.exports