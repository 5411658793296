<template>
  <section>
    <div class="container">
      <h1 class="is-size-2">Navegación de Documentos</h1>
      <div class="box">
        <section>
          <nav class="breadcrumb is-large" aria-label="breadcrumbs">
            <ul>
              <li v-for="(link, linkIndex) in url" :key="linkIndex">
                <a @click="updateCategories(link.id)">{{link.name==="INTRANET FACULTAD DE INGENIERIA" ? "INICIO" : link.name}}</a>
              </li>

              <!--<li><a href="/life_record">Menú principal</a></li>
              <li class="is-active"><a href="#" aria-current="page">Búsqueda de Personas</a></li>-->
            </ul>
          </nav>
        </section>
      </div>
      <hr style="border-top: 3px solid #bbb;">
      <div class="box">
        <div class="columns">
          <div class="column">
              <h3 class="is-size-3" v-if="(actualFolder!==null && actualFolder!==undefined)">
                {{actualFolder.name==="INTRANET FACULTAD DE INGENIERIA" ? "INICIO" : actualFolder.name}}</h3>
              <h3 class="is-size-3" v-else></h3>
          </div>
          <div class="column">
            <div class="buttons has-addons is-right">
              <div class="control">
                <b-button type="is-info"
                          icon-left="edit"
                          v-if="can_edit_folder===true"
                          @click="goToEdit()">
                  Editar Carpeta
                </b-button>
              </div>
              <div class="is-pulled-right" style="margin-left: 10px;">
                <add-folder-document-dropdown @clicked="onClickChild" :actual-folder="actualId" :can_create_document="can_create_document"
                                              :can_create_folder="can_create_folder"></add-folder-document-dropdown>
              </div>
            </div>
          </div>
        </div>
        <hr style="border-top: 3px solid #bbb;">
        <div class="container">
          <div class="columns" v-for="(row, rowIndex) in matrix_categories" :key="rowIndex" v-if="matrix_categories.length>0">
            <div class="column is-4" v-for="(column, columnIndex) in row" :key="columnIndex">
             <!-- <p class="control">
                <b-button type="is-primary"
                          icon-left="folder"
                          @click="updateCategories(column.id)">
                  {{column.name}}
                </b-button>-->
              <div class="rows" >
                <a class="box" @click="updateCategories(column.id)">
                <div class="row has-text-centered">
                  <b-icon
                      pack="fas"
                      icon="folder"
                      size="is-large"
                      type="is-primary"/>
                </div>
                <div class="row has-text-centered">
                  <p>{{column.name}}</p>
                </div>
                </a>
              </div>
              <!--<div class="card">
                <div class="card-content">
                  <b-icon
                      pack="fas"
                      icon="folder"
                      size="is-medium"
                      type="is-primary"
                      @click.native="updateCategories(column.id)"/>
                </div>
                <footer class="card-footer">
                  <p class="card-footer-item">
                    <span>
                      {{column.name}}
                    </span>
                    </p>
                  </p>
                </footer>
              </div>
              </p>-->
            </div>
          </div>
          <b-message
              type="is-warning"
              has-icon
              v-if="document_categories.length===0">
            Esta carpeta no tiene asociadas sub carpetas
          </b-message>
          <hr style="border-top: 3px solid #bbb;">
          <div class="columns has-text-right">
            <div class="column has-text-right">
              <p class="control">
                <b-button class="is-primary" icon-left="arrow-circle-left" @click="goToPrevious(previous_id)" :disabled="actualId===0">Volver</b-button>
              </p>
            </div>
          </div>
        </div>
      </div>

      <h3 class="is-size-3">Documentos</h3>
      <hr style="border-top: 3px solid #bbb;">
      <b-table
          :data="documents"
          :paginated=true
          per-page=10
          striped
          hoverable
          narrowed
          :card-layout="cardLayout"
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="desc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="documents.formatted_document_date"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual"
          :opened-detailed="defaultOpenedDetails"
          detailed
          detail-key="name"
          style="word-break: break-word;"
          @details-open="(row) => $buefy.toast.open(`Expandido ${row.name}`)"
          :show-detail-icon="showDetailIcon"
      >
        <b-table-column
            field= 'document_type_name'
            label= 'Tipo Documento'
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.document_type_name}}
          </template>
        </b-table-column>
        <b-table-column
            field= 'resolution'
            label= 'N° Documento'
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.resolution }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'formatted_document_date'
            label= 'Fecha Documento'
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.document_date!= null ? props.row.formatted_document_date:props.row.year}}
          </template>
        </b-table-column>
        <b-table-column
            field= 'description'
            label= 'Nombre Adicional del documento'
            searchable
            sortable
            width="450px">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ (props.row.visible_description && props.row.description == null) || props.row.document_type_name==="ACTA"?props.row.name:props.row.description }}
<!--            {{props.row.name}}-->
          </template>
        </b-table-column>

        <b-table-column  label="Acciones"
                         v-slot="props"
                         width="130">
          <b-tooltip
              v-if="props.row.can_show"
              label="Muestra en detalle de la información relacionada al documento"
              :active="true">
            <b-button
                pack="fas"
                icon-left="info"
                type="is-primary"
                target="_blank"
                v-if="props.row.can_show"
                @click.native="goToDocument(props.row.id)"/>
          </b-tooltip>
          <b-tooltip
              v-if="props.row.can_edit"
              label="Edita la información relacionada al documento"
              :active="true">
            <b-button
                pack="fas"
                icon-left="edit"
                type="is-info is-light is-outlined"
                v-if="props.row.can_edit"
                @click.native="goToEditDoc(props.row.id)"/>
          </b-tooltip>
          <b-tooltip
              v-if="props.row.can_nullify"
              label="Anular documento"
              :active="true">
            <b-button
                pack="fas"
                icon-left="trash"
                type="is-danger is-light is-outlined"
                v-if="props.row.can_nullify"
                @click.native="execNullifyModal(props.row.id, props.row.document_category_id)"/>
          </b-tooltip>
        </b-table-column>
        <b-table-column
            label="Documentación"
            v-slot="props"
            width="135">
          <b-tooltip
              v-if="props.row.can_download"
              label="Descarga el documento"
              :active="true">
            <b-button
                pack="fas"
                icon-left="download"
                type="is-primary is-light is-outlined"
                v-if="props.row.can_download"
                @click="downloadFile(props.row.id)"/>
          </b-tooltip>
          <b-tooltip
              v-if="props.row.can_show"
              label="Visualiza el documento. Solo permite archivos PDF"
              :active="true">
            <b-button
                icon-pack="fas"
                icon-left="eye"
                type="is-success is-light is-outlined"
                target="_blank"
                v-if="props.row.can_show"
                @click="viewFile(props.row.id)"/>
          </b-tooltip>
        </b-table-column>
        <template slot="detail" slot-scope="props">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <p>
                  <strong>Nomenclatura de descarga:</strong>
                  <span>{{props.row.name}}</span>
                </p>
                <p>
                  <strong>Etiquetas: </strong>
                  <span>{{props.row.name_tags}}</span>
                </p>
              </div>
            </div>
          </article>
        </template>

        <template slot="empty" slot-scope="props">
          <div class="columns is-centered">
            <div class="column is-6 is-centered has-text-centered">
              <b-message type="is-danger">
                No se encontraron documentos para la carpeta seleccionada.
              </b-message>
            </div>
          </div>
        </template>


      </b-table>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

      <b-modal
          v-model="addDocument"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="can_cancel"
          width="1200px">
        <template #default="props">
          <modal-document-form :cargo-principal-id="cargoPrincipal"
                         query-type="create"
                         modal-header="Crear Documento"
                         :actual-folder="actualFolder"
                         @close="props.close">
          </modal-document-form>
        </template>
      </b-modal>

      <b-modal
          v-model="addFolder"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="can_cancel"
          width="1200px">
        <template #default="props">
          <modal-document-category-form :cargo-principal-id="cargoPrincipal"
                               query-type="create"
                               modal-header="Crear Carpeta"
                               :actual-folder="actualFolder"
                               @close="props.close">
          </modal-document-category-form>
        </template>
      </b-modal>

      <b-modal
          v-model="editFolder"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="can_cancel"
          width="1200px">
        <template #default="props">
          <modal-document-category-form :cargo-principal-id="cargoPrincipal"
                                        query-type="update"
                                        modal-header="Editar Carpeta"
                                        :category-id="actualId"
                                        :actual-folder="actualFolder"
                                        @close="props.close">
          </modal-document-category-form>
        </template>
      </b-modal>

      <b-modal
          v-model="showPdf"
          :can-cancel="true"
          :destroy-on-hide="true"
          aria-modal
          aria-role="dialog"
          has-modal-card
          trap-focus
          :width="2000"
      >
        <template #default="props">
          <pdf_view
              :src="src"
              @close="props.close"
          ></pdf_view>
        </template>

      </b-modal>

    </div>
  </section>
</template>
<script>
import {generateGrid} from "../../packs/utilities";
import AddFolderDocumentDropdown from "./add_folder_or_document_dropdown"
import ModalDocumentCategoryForm from "./modal_document_category_form";
import ModalDocumentForm from "./modal_document_form"
import axios from "axios";
import Pdf_view from "../performances/pdf_view.vue";
import pdf from "vue-pdf";
export default {
  props: [
    'cargoPrincipal'//, 'can_create_document', 'can_create_folder', 'can_edit_folder'
  ],
  components: {
    Pdf_view,
    AddFolderDocumentDropdown,
    ModalDocumentForm,
    ModalDocumentCategoryForm,
    pdf,
  },
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      isNullifyActive: false,
      documents: [],
      url: [],
      document_types: [],
      document_type_id: null,
      document_category_id: null,
      document_categories: [],
      resolution: null,
      errores_validacion: {},
      start_date: null,
      end_date: null,
      matrix_categories: [],
      can_cancel: [],
      previous_id: null,
      addDocument: false,
      actualId: null,
      actualFolder: null,
      addFolder: false,
      editFolder: false,
      editDocument: false,
      can_create_document: false,
      can_create_folder: false,
      can_edit_folder: false,
      showPdf: false,
      src:null,
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    goToPrevious(previous_id){
      if(previous_id===null || previous_id === undefined || previous_id === "null"){
        this.initialize()
      }
      else{
        this.updateCategories(previous_id)
      }
    },
    generateGrid,
    initialize(){
      this.isLoading = true
      return axios.get("/documents/documents-navigation.json")
          .then(res => {
            this.documents = res.data["documents"]
            this.url = res.data["url"]
            this.previous_id = res.data["previous_id"]
            this.actualFolder = res.data["actual_folder"]
            this.document_categories = res.data["document_categories"]
            this.matrix_categories = this.generateGrid(this.document_categories)
            this.actualId = 0
            this.can_create_document = res.data["can_create_document"]
            this.can_create_folder = res.data["can_create_folder"]
            this.can_edit_folder = res.data["can_edit_folder"]
            this.isLoading=false;
          })
          .catch(e => {
            this.errores_validacion = e.response.data
            this.isLoading=false;
          });
    },
    getDocumentUrl(document_id){
      return "/documents/"+document_id;
    },
    goToEditDoc(id){
      window.open('/documents/'+id+"/edit")
    },
    goToUrl(url){
      window.open(url)
    },
    updateCategories(id){
      this.isLoading = true;
      this.actualId = id;
      return axios.get("/document_categories/info-category/"+id+".json")
          .then(res => {
            this.documents = res.data["documents"]
            this.url = res.data["url"]
            this.previous_id = res.data["previous_id"]
            this.actualFolder = res.data["actual_folder"]
            this.document_categories = res.data["document_categories"]
            this.matrix_categories = this.generateGrid(this.document_categories)
            this.can_create_document = res.data["can_create_document"]
            this.can_create_folder = res.data["can_create_folder"]
            this.can_edit_folder = res.data["can_edit_folder"]
            this.isLoading=false;
          })
          .catch(e => {
            this.isLoading=false;
            //console.log(e);
          });
    },
    goToDocument(id){
      window.open('/documents/'+id)
    },
    onClickChild(value){
      if(value===1){
        this.addDocument = true;
      }
      else if (value === 2){
        this.addFolder = true;
      }
      else{
        this.editFolder = true;
      }
    },
    goToEdit(){
      this.editFolder = true;
    },
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/view-document", {
            responseType: "blob",
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            if(res.data.type != "application/pdf"){
              this.$buefy.dialog.alert({
                message: "El formato del archivo no es compatible con el visualizador. Solo permite PDF.",
                type: "is-danger",
                hasIcon: true
              })
              this.isLoading = false
            }
            else{
              this.showPdf = true
              this.isLoading = false
            }
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    downloadFile(fileId){
      this.isLoading = true;

      if (fileId == null){
        this.isLoading = false;
        return;
      }

      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
          })
          .then(res => {
            console.log(res.headers["content-disposition"])
            let blob = new Blob([res.data], {
              type: res.data.type,
            })
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            const nombre_archivo = res.headers["content-disposition"]
                .split(";")[1]
                .split('"')[1];
            const nombre_archivo_real = res.headers["content-disposition"].match(/filename\*=UTF-8''(.+)$/);
            if (nombre_archivo_real){
              link.download = decodeURIComponent(nombre_archivo_real[1]);
            }
            else{
              link.download = decodeURIComponent(nombre_archivo);
            }
            link.click();
            this.isLoading=false
          })
          .catch(e => {
            console.log(e)
            this.isLoading=false
          })
    },
    execNullifyModal(id, category_id) {
      this.$buefy.dialog.confirm({
        title: 'Anular Documento',
        message: '¿Está seguro que desea <b>anular</b> el documento? Esta acción lo dejará inválido.<br>',
        confirmText: 'Anular Documento',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/documents/" + id + "/nullify-document.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Documento anulado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                this.isLoading = false;
                this.isNullifyActive = false;

                this.updateCategories(category_id)
                /*if (document.referrer == '') {
                  window.location.href = history.back()
                } else {
                  window.location.href = document.referrer
                }*/

              }).catch(error => {
            console.log(error.response.data.error)
            this.$buefy.dialog.alert({
              message: '<b>Documento no pudo ser anulado</b><br>' + error.response.data.error,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },

  },
  watch:{
    addDocument: function () {
      if (!this.addDocument) {
        this.updateCategories(this.actualId)
      }
    },
    editFolder: function () {
      if (!this.editFolder) {
        this.updateCategories(this.actualId)
      }
    },
    addFolder: function () {
      if (!this.addFolder) {
        if(this.actualFolder===null ||this.actualFolder===undefined){
          this.initialize()
        }
        else{
          this.updateCategories(this.actualId)
        }
      }
    },
  },
  computed:{

  }
}
</script>