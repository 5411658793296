<template>
  <section>

    <div class="columns is-centered">
      <div class="column">

        <b-message type="is-info" has-icon>
          Esta Publicación de Libro solo puede ser <b>editada</b> o <b>anulada</b> por quien agregó estos datos al sistema. Si hay un error en la información por favor ponerse en contacto con la persona propietaria. 
          Si el dato que ve no corresponde a usted, puede <b>eliminar</b> su participación en la sección <b>Participantes</b>, y este dato dejará de estar visible.
        </b-message>

      </div>
    </div>

    <div class="columns is-centered">
        <div class="column">
          <div class="has-text-right">             
            <b-button type="is-info"
                      icon-left="edit"
                      v-if="(publication.can_edit && publication.academic_activity_state_id == 1) || (publication.can_edit && publication.academic_activity_state_id == null)"
                      @click="editPublication()">
              Editar Libro
            </b-button>
            <b-button v-if="(publication.is_creator || cargoPrincipal.name == 'ANALISTA I+D FING') && (publication.academic_activity_state_id == 1 || publication.academic_activity_state_id == null)" type="is-danger"
                      icon-left="ban"
                      @click="execNullifyModal">
              Anular Libro
            </b-button>
          </div>
          <hr class="dropdown-divider" aria-role="menuitem">
          <div class="columns">
            <div class="column is-4 is-offset-8">
              <b-switch v-model="requiredFieldOnly">Mostrar todos los campos del formulario</b-switch>
            </div>
          </div>


          <div class="columns">
            <div class="column">
              <b-field label="Título">
                <b-input readonly v-model="publication.title" type="text"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Fecha de Publicación">
                <b-input readonly v-model="publication.publication_date" type="text"></b-input>
              </b-field>
            </div>

            <div class="column"  v-if="requiredFieldOnly">
              <b-field label="ISSN">
                <b-input readonly v-model="publication.issn" type="text"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Editorial">
                <b-input readonly :value="publication.editorial" type="text"></b-input>
              </b-field>
            </div>
            
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Autores(registrados y no registrados)">
                <b-input readonly v-model="publication.participants_references" type="text"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column" v-if="requiredFieldOnly">
              <div class="field">
                <b-field label="Reseña">
                  <b-input readonly
                           type="textarea"
                           minlength="10"
                           maxlength="500"
                           v-model="publication.summary"
                           :has-counter="false">
                  </b-input>
                </b-field>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-field label="Link del libro">
                <b-input readonly v-model="publication.url" expanded></b-input>
                <p class="control">
                  <b-button tag="a" :href="publication.url" target="_blank" icon-left="external-link-alt" type="is-link">Link</b-button>
                </p>
              </b-field>
            </div>
          </div>
          
          <div class="columns">
            <div class="column"  v-if="requiredFieldOnly">
              <b-field label="Título Corto">
                <b-input readonly v-model="publication.short_title" type="text"></b-input>
              </b-field>
            </div>
            <div class="column"  v-if="requiredFieldOnly">
              <b-field label="Serie">
                <b-input readonly v-model="publication.serie" type="text"></b-input>
              </b-field>
            </div>
            
          </div>
          <div class="columns">
            <div class="column"  v-if="requiredFieldOnly">
              <b-field label="Título Serie">
                <b-input readonly v-model="publication.serial_title" type="text"></b-input>
              </b-field>
            </div>
            <div class="column"  v-if="requiredFieldOnly">
              <b-field label="Volumen">
                <b-input readonly v-model="publication.volumen" type="text"></b-input>
              </b-field>
            </div>
            
          </div>
          <div class="columns">
            <div class="column"  v-if="requiredFieldOnly">
              <b-field label="Archivo">
                <b-input readonly v-model="publication.archive" type="text"></b-input>
              </b-field>
            </div>

            <div class="column" >
              <b-field label="Posición en Archivo"  v-if="requiredFieldOnly">
                <b-input readonly v-model="publication.archive_position" type="text"></b-input>
              </b-field>
            </div>
            
          </div>
          <div class="columns">
            <div class="column"  v-if="requiredFieldOnly">
              <b-field label="DOI" >
                <b-input readonly v-model="publication.doi" type="text" expanded></b-input>
                <p class="control">
                  <b-button tag="a" :href="'https://doi.org/'+publication.doi" target="_blank" icon-left="external-link-alt" type="is-link">Link</b-button>
                </p>
              </b-field>
            </div>

            <div class="column"  v-if="requiredFieldOnly">
              <b-field label="Adicional">
                <b-input readonly v-model="publication.additional" type="text"></b-input>
              </b-field>
            </div>
            
          </div>

          <div class="columns">
            <div class="column"  v-if="requiredFieldOnly">
              <b-field label="Imprenta">
                <b-input readonly v-model="publication.printing" type="text"></b-input>
              </b-field>
            </div>
          </div>

          <div class="column">
            <h3 class="is-size-4 has-text-weight-bold">Tabla De ISBN</h3>
            <b-table
                :key="tableDataKey"
                :data="publication.isbns"
                :paginated=true
                per-page=5
                striped
                hoverable
                :current-page="currentPage"
                :card-layout="cardLayout"
                :pagination-simple=false
                pagination-position="bottom"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="publication.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
              <b-table-column
                  field="code"
                  label="Código"
                  width="13em"
                  sortable
                  searchable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{ props.row.code}}
                </template>
              </b-table-column>
              <b-table-column
                  width="10em"
                  field="country_id"
                  label="Tipo de ISBN"
                  v-slot="props">

                      <b-switch disabled v-model="props.row.is_physical">
                      </b-switch>
                      <span v-if="props.row.is_physical"> Fisico </span>
                      <span v-else> Digital</span>                          
              </b-table-column>
              
              <template slot="empty" slot-scope="props">
                <div class="columns is-centered">
                  <div class="column is-6 is-centered has-text-centered">
                    <b-message type="is-danger">
                      No se han añadido ISBN.
                    </b-message>
                  </div>
                </div>
              </template>
            </b-table>
          </div>

          
        </div>
      </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        :active="isComponentModalActive"
        width="90%"
        :can-cancel="canCancel"
        aria-modal>
      <template #default="props">
        <new-book-form modal-cancel="true"
                          :cargo-principal="cargoPrincipal"
                          :user-id="userId"
                          modal-header="Editar Libro"
                          query-type="update" v-bind:book-id="publication.id" @close="closeModal"></new-book-form>
      </template>
    </b-modal>
    <b-modal
        :active="isComponentModalInbookActive"
        width="90%"
        :can-cancel="canCancel"
        aria-modal>
      <template #default="props">
        <new-inbook-form modal-cancel="true"
                          :cargo-principal="cargoPrincipal"
                          :user-id="userId"
                          modal-header="Crear"
                          response-type="RETORNO"
                          query-type="create" 
                          :title-book="publication.title"
                          :publication-type-id="tipoContent"
                          v-bind:book-id="publication.id" 
                          
                          @close="closeModal"></new-inbook-form>
      </template>
    </b-modal>
  </section>
</template>
<script>
import axios from "axios";
import NewBookForm from "./NewBookForm";
import NewInbookForm from "./NewInbookForm";
export default {
  name: "ShowGeneralTab",
  components: {NewBookForm, NewInbookForm},
  props:['cargoPrincipal','userId','bookId','publication','participantes'],
  data(){
    return{
      currentPage:1,
      isLoading:false,
      isComponentModalActive: false,
      isComponentModalInbookActive: false,
      canCancel: ['escape'],
      tableDataKey:0,
      cardLayout:false,
      tipoContent: null,
      requiredFieldOnly: false,

    }
  },

  methods:{
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    editPublication(){
      this.isComponentModalActive = true;
    },
    newContent(tipo){
      this.tipoContent = tipo;
      this.isComponentModalInbookActive = true;
    },
    
    closeModal(){
      this.isComponentModalActive = false;
      this.isComponentModalInbookActive = false;
    },
    execNullifyModal(){
      this.$buefy.dialog.confirm({
        title: 'Anular publicación',
        message: '¿Está seguro que desea <b>anular</b> el libro? Esta acción la dejará inválida.<br>',
        confirmText: 'Anular Libro',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true
          axios
              .delete("/books/" + this.publication.id + "/nullify_book.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Libro anulado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                if (document.referrer == ''){
                  window.location.href = history.back()
                }
                else{
                  window.location.href = document.referrer
                }
                this.isLoading = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Libro no pudo ser anulado</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
          })
        }
      })
    },

  },
  computed:{
  }
}
</script>
<style scoped>

</style>