<template>
<section>
  <div class="columns">
    <div class="column has-text-right">
      <b-field>
        <b-switch v-model="cardLayout">Formato tarjeta (sin filtros ni búsqueda)
          <b-tooltip
              label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
              multilined
              position="is-left">
            <b-icon
                pack="fas"
                icon="info-circle"
                type="is-info">
            </b-icon>
          </b-tooltip></b-switch>
      </b-field>
    </div>
  </div>

  <b-table
      :data="cites"
      :paginated=true
      :card-layout="cardLayout"
      per-page=10
      striped
      hoverable
      :current-page="currentPage"
      :pagination-simple=false
      pagination-position="bottom"
      default-sort-direction="asc"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort="cite.year"
      aria-next-label="Siguiente"
      aria-previous-label="Anterior"
      aria-page-label="Página"
      aria-current-label="Página actual"
  >
    <b-table-column
        field= 'publication_title'
        label= 'Título de Publicación'
        searchable
        sortable>
      <template
          slot="searchable"
          slot-scope="props">
        <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small" />
      </template>
      <template v-slot="props">
        <a v-if="props.row.publication_title" :href="getPublicationUrl(props.row.publication_id)">{{ props.row.publication_title }}</a>
      </template>
    </b-table-column>
    <b-table-column
        field= 'publication_year'
        label= 'Año de publicación'
        searchable
        sortable>
      <template
          slot="searchable"
          slot-scope="props">
        <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small" />
      </template>
      <template v-slot="props">
        {{ props.row.publication_year }}
      </template>
    </b-table-column>
    <b-table-column
        field= 'cite_year'
        label= 'Año de citas'
        searchable
        sortable>
      <template
          slot="searchable"
          slot-scope="props">
        <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small" />
      </template>
      <template v-slot="props">
        {{ props.row.cite_year }}
      </template>
    </b-table-column>
    <b-table-column
        field= 'cite_number'
        label= 'Cantidad de citas'
        searchable
        sortable>
      <template
          slot="searchable"
          slot-scope="props">
        <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small" />
      </template>
      <template v-slot="props">
        {{ props.row.cite_number }}
      </template>
    </b-table-column>

    <template slot="empty" slot-scope="props">
      <div class="columns is-centered">
        <div class="column is-6 is-centered has-text-centered">
          <b-message type="is-danger">
            No se encontraron resultados para el año seleccionado.
          </b-message>
        </div>
      </div>
    </template>
  </b-table>

  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
</section>
</template>

<script>
export default {
  name: "DashboardCitesTable",
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,

    }
  },
  props:[
    'cites','year'
  ],
  methods:{
    getPublicationUrl(id){
      return "/publications/"+id;
    }
  }
}
</script>

<style scoped>

</style>