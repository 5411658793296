<template>
  <section>
    <div class="columns is-centered">
      <div class="column">
        <h1 class="is-size-4 has-text-weight-bold title has-text-centered">Sub Áreas OCDE
          <b-tooltip
              v-if="publication.can_edit && publication.academic_activity_state_id == 1"
              label="Presione Enter o Coma luego de escribir la Sub Áreas OCDE o Disciplinas para que sea agregada. No se agregarán Sub Áreas OCDE nuevas ni Disciplinas, solo se buscarán las existentes"
              position="is-right"
              size="is-medium"
              multilined>

            <b-icon
                pack="fas"
                icon="info-circle"
                size="is-small"
                type="is-info">
            </b-icon>
          </b-tooltip>
        </h1>
        <div class="has-text-right">
          <b-button type="is-info"
                    icon-left="edit"
                    v-if="publication.can_edit && publication.academic_activity_state_id == 1"
                    @click="editOCDE()">
            Editar Sub Áreas OCDE
          </b-button>
        </div>
        <hr class="dropdown-divider" aria-role="menuitem">
        <div class="columns">
          <div class="column">
            <b-field label="Sub Áreas OCDE">
              <b-taginput
                  v-model="selected_ocde"
                  :data="ocde_sub_areas_filtradas"
                  :disabled="disabled"
                  autocomplete
                  :allow-new="true"
                  :allow-duplicates="false"
                  type="is-primary is-light"
                  field="alt_name"
                  icon="tag"
                  @remove="deleteSubAreaOcde"
                  @add="clickSubAreaOcde"
                  placeholder="Sub área OCDE"
                  @typing="getFilteredOcdeSubAreas">
              </b-taginput>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Disciplinas">
              <b-taginput
                  :disabled="disabled"
                  v-model="selected_disciplinas"
                  :data="disciplines_filtradas"
                  autocomplete
                  :allow-new="false"
                  :allow-duplicates="false"
                  type="is-primary is-light"
                  field="name"
                  icon="tag"
                  placeholder="Disciplina"
                  @typing="getFilteredDisciplines">
              </b-taginput>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="is-pulled-left" v-if="!disabled">
              <button class="button is-link is-light" @click="cancel()">Cancelar</button>
            </div>
          </div>
          <div class="column">
            <div class="is-pulled-right" v-if="!disabled">
              <b-button class="button is-primary" @click="save()">Guardar cambios</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "PublicationSubAreaOcdeTab",
  props:[ 'participants', 'publication', 'cargoPrincipal', 'canEdit', 'disciplines', 'ocde_sub_areas'],
  data(){
    return{
      sub_areas_ocde: [],
      ocde_sub_areas_filtradas: [],
      disciplinas: [],
      disciplines_filtradas: [],
      selected_ocde: [],
      selected_disciplinas: [],
      disciplinas_total: [],
      disabled:true,
      isLoading: false,
    }
  },
  created(){
    this.getSubAreasOCDE()
  },
  methods:{
    editOCDE(){
      this.disabled = false
    },
    getSubAreasOCDE(){
      this.isLoading = true;
      axios.get("/publications/get_ocde.json")
          .then(res => {
            this.sub_areas_ocde = res.data['ocde_sub_areas'];
            this.ocde_sub_areas_filtradas = res.data['ocde_sub_areas'];
            this.disciplinas = res.data['disciplines'];
            var ocde_sub_areas_temp = this.sub_areas_ocde
            var disciplines_temp = this.disciplinas
            var selected_ocde_temp = []
            var selected_disciplinas_temp = []
            this.publication.ocde_sub_areas_attributes.forEach(function (element){
              ocde_sub_areas_temp.forEach(function (ocde){
                if (ocde.id == element.id){
                  selected_ocde_temp.push(ocde)
                }
              })
            })
            this.publication.disciplines_attributes.forEach(function (element){
              disciplines_temp.forEach(function (discipline){
                if (discipline.id == element.id){
                  if (discipline.alt_name == null){
                    discipline.alt_name = discipline.name
                  }
                  selected_disciplinas_temp.push(discipline)
                }
              })
            })
            this.selected_ocde = selected_ocde_temp
            this.selected_disciplinas = selected_disciplinas_temp
            this.clickSubAreaOcde()
            this.isLoading = false
          })
          .catch(e => {
            console.log(e);
            this.isLoading=false;
          });
    },
    getFilteredOcdeSubAreas(text) {
      this.ocde_sub_areas_filtradas = this.sub_areas_ocde.filter((option) => {
        return option.alt_name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    getFilteredDisciplines(text) {
      this.disciplines_filtradas = this.disciplinas_total.filter((option) => {
        return option.alt_name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    deleteSubAreaOcde(value){
      if(this.selected_ocde.length == 0){
        this.selected_disciplinas = []
        this.disciplines_filtradas = []
        this.disciplinas_total = []
      }
      else{
        this.selected_disciplinas = this.selected_disciplinas.filter(discipline => discipline.ocde_sub_area_id != value.id);
      }
      this.clickSubAreaOcde()

    },
    clickSubAreaOcde(){
      var disciplinas_temp = this.disciplinas
      var disciplinas_total_temp = []

      this.selected_ocde.forEach(function (element){
        disciplinas_temp.forEach(function (discipline){
          if (element.id === discipline.ocde_sub_area_id){
            disciplinas_total_temp.push(discipline)
          }
        })
      })
      this.disciplinas_total = disciplinas_total_temp
      this.disciplines_filtradas = disciplinas_total_temp

    },
    cancel(){
      this.isLoading = true
      this.disabled = true
      this.selected_ocde = this.publication.ocde_sub_areas_attributes
      this.selected_disciplinas = this.publication.disciplines_attributes
      this.clickSubAreaOcde()

      this.isLoading = false
    },
    save(){
      this.isLoading = true
      this.publication.ocde_sub_areas_attributes = this.selected_ocde
      this.publication.disciplines_attributes = this.selected_disciplinas
      axios
          .put("/publications/update_sub_areas_ocde/"+this.publication.id+".json", {
            publication: this.publication,
          })
          .then(response => {
            this.disabled = true
            this.getSubAreasOCDE()
          })
          .catch(error => {
            console.log(error)
            this.isLoading=false;
          })
    },
  },
}
</script>

<style scoped>

</style>