<template>
    <section>
        <div class="modal-card" style="width: 1280px; max-width: 100%;">
            <header class="modal-card-head">
                <p class="modal-card-title">{{ edit ? 'Editar Actividad' : 'Nueva Actividad' }}</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">

                <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
                    <form @submit.prevent="handleSubmit(check())">

                        <div class="columns">
                            <div class="column">
                                <ValidationProvider name="Tipo de Actividad" rules="required"
                                    v-slot="{ errors, failedRules }">
                                    <b-field label="Tipo de Actividad *"
                                        :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                        :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                        <multiselect v-model="activity.vime_activity_type_id"
                                            :options="activity_types.map(type => type.id)"
                                            :custom-label="opt => activity_types.find(x => x.id === opt).name"
                                            placeholder="Seleccione un tipo de actividad"
                                            selectLabel="Presione para seleccionar" selectedLabel="Seleccionado"
                                            deselectLabel="" :allow-empty="false">
                                            <template v-slot:noOptions> No hay datos. </template>
                                            <template v-slot:noResult> No se han encontrado resultados para su búsqueda,
                                                pruebe con otra. </template>
                                        </multiselect>
                                    </b-field>
                                </ValidationProvider>
                            </div>
                            <div class="column">
                                <ValidationProvider name="Nombre de la Actividad" rules="min:3|max:255|required"
                                    v-slot="{ errors, failedRules }">
                                    <b-field label="Nombre de la Actividad *"
                                        :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                        :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                        <b-input v-model="activity.name"
                                            placeholder="Ingrese el nombre de la actividad" />
                                    </b-field>
                                </ValidationProvider>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column">
                                <ValidationProvider name="Fecha de Inicio"
                                    :rules="{start_date_before_end_date: activity.end_date}"
                                    v-slot="{ errors, failedRules }">
                                    <b-field label="Fecha de Inicio"
                                        :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                        :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                        <b-datepicker placeholder="Seleccione la fecha de inicio de la actividad"
                                            icon="calendar-alt" editable locale="es-ES" v-model="activity.start_date" />
                                    </b-field>
                                </ValidationProvider>
                            </div>
                            <div class="column">
                                <ValidationProvider name="Fecha de Término"
                                    :rules="{end_date_not_before_start_date: activity.start_date}"
                                    v-slot="{ errors, failedRules }">
                                    <b-field label="Fecha de Término"
                                        :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                        :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                        <b-datepicker placeholder="Seleccione la fecha de término de la actividad"
                                            icon="calendar-alt" editable locale="es-ES" v-model="activity.end_date" />
                                    </b-field>
                                </ValidationProvider>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column">
                                <ValidationProvider name="Tema de la Actividad" rules="min:3|max:255|required"
                                    v-slot="{ errors, failedRules }">
                                    <b-field label="Tema de la Actividad *"
                                        :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                        :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                        <b-input v-model="activity.theme"
                                            placeholder="Ingrese el tema de la actividad" />
                                    </b-field>
                                </ValidationProvider>
                            </div>
                            <div class="column is-narrow">
                                <ValidationProvider name="Internacionalidad" rules="min:3|max:1024|required"
                                    v-slot="{ errors, failedRules }">
                                    <b-field label="¿Es Internacional? *"
                                        :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                        :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                        <b-radio-button v-model="activity.is_national" :native-value="false"
                                            type="is-danger">
                                            <b-icon icon="times-circle"></b-icon>
                                            <span>No</span>
                                        </b-radio-button>
                                        <b-radio-button v-model="activity.is_national" :native-value="true"
                                            type="is-success">
                                            <b-icon icon="check-circle"></b-icon>
                                            <span>Si</span>
                                        </b-radio-button>
                                    </b-field>
                                </ValidationProvider>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column">
                                <ValidationProvider name="Descripción de la Actividad" rules="min:3|max:1024|required"
                                    v-slot="{ errors, failedRules }">
                                    <b-field label="Descripción de la Actividad *"
                                        :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                        :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                        <b-input v-model="activity.description"
                                            placeholder="Ingrese la descripción de la actividad" type="textarea" />
                                    </b-field>
                                </ValidationProvider>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column">
                                <ValidationProvider name="Link de la Actividad" rules="min:3|max:255"
                                    v-slot="{ errors, failedRules }">
                                    <b-field label="Link de la Actividad"
                                        :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                        :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                        <b-input v-model="activity.link"
                                            placeholder="Ingrese el link. Ejemplo: https://www.drive.com" />
                                    </b-field>
                                </ValidationProvider>
                            </div>
                            <div v-show="cargoPrincipal.name == 'ACADEMICO' && edit == false" class="column">
                                <ValidationProvider name="Participación"
                                    :rules="cargoPrincipal.name == 'ACADEMICO'? 'required' : ''"
                                    v-slot="{ errors, failedRules }">
                                    <b-field label="Participo Como*"
                                        :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                        :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                        <multiselect v-model='assistance_type_id'
                                            :options="vime_assistant_types.map(type => type.id)"
                                            :custom-label="opt => vime_assistant_types.find(x => x.id === opt).name"
                                            placeholder="Seleccione un tipo de participación"
                                            selectLabel="Presione para seleccionar" selectedLabel="Seleccionado"
                                            deselectLabel="" :allow-empty="false" />
                                    </b-field>
                                </ValidationProvider>
                            </div>
                            <div class="column">
                                <ValidationProvider name="Número de Asistentes de la Actividad" rules="required"
                                    v-slot="{ errors, failedRules }">
                                    <b-field label="Número de Asistentes de la Actividad *"
                                        :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                        :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                        <b-input v-model="activity.assistants_number"
                                            placeholder="Ingrese la cantidad de asistentes de la actividad"
                                            type="number" />
                                    </b-field>
                                </ValidationProvider>
                            </div>
                        </div>

                    </form>
                </ValidationObserver>

            </section>
            <footer class="modal-card-foot is-flex is-justify-content-space-between">
                <b-button label="Cerrar" @click="$emit('close')" />
                <b-button :label="edit ? 'Editar Actividad' : 'Guardar Actividad'" type="is-primary"
                    :icon-left="edit ? 'edit' : 'save'" @click="check()" />
            </footer>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </section>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {

    name: 'new_edit_academic_vime_activity',

    props: [
        'personId', 'cargoPrincipal', 'activityId', 'edit',
    ],

    data() {
        return {
            isLoading: false,

            // Atributos de la Actividad
            activity: {
                name: null,
                theme: null,
                description: null,
                link: null,
                assistants_number: null,
                start_date: null,
                end_date: null,
                vime_activity_type_id: null,
                is_national: null,
                id_academic: parseInt(this.personId),
            },
            assistance_type_id: null,

            // Datos para el formulario
            activity_types: [],
            vime_assistant_types: [],
        }
    },

    async created() {
        await this.getActivityTypes()
        if (this.cargoPrincipal.name === 'ACADEMICO') await this.getAssistancesTypes()
        if (this.edit) await this.getActivity()
    },

    methods: {

        async getActivity(){
            this.isLoading = true
            await axios.get("/academic_vime_activities.json", {params:{id: this.activityId}})
                        .then(res => {
                            this.activity = res.data
                            this.activity.vime_activity_type_id = this.activity.vime_activity_type.id
                            if (this.activity.start_date != null) {
                                this.activity.start_date = moment(this.activity.start_date, ["YYYY-MM-DD","DD-MM-YYYY"])
                                this.activity.start_date = new Date(this.activity.start_date)
                            }

                            if (this.activity.end_date != null) {
                                this.activity.end_date = moment(this.activity.end_date, ["YYYY-MM-DD","DD-MM-YYYY"])
                                this.activity.end_date = new Date(this.activity.end_date)
                            }
                            
                            this.isLoading = false
                        })
                        .catch(error => {
                            var showError = ' Favor intentarlo más tarde.'
                            if (error.response) {
                                showError = ' Favor revisar errores.<br>Errores:<br>'
                                for (const propiedad in error.response.data) {
                                    if (error.response.data.hasOwnProperty(propiedad)) {
                                        showError += error.response.data[propiedad].join(', ') + '<br>'
                                    }
                                }
                            }

                            this.$buefy.dialog.alert({
                                title: 'Error',
                                message: 'Hubo un error al traer los datos de la Actividad.' + showError ,
                                type: 'is-danger',
                                hasIcon: true,
                                icon: 'times-circle',
                                iconPack: 'fa',
                                ariaRole: 'alertdialog',
                                ariaModal: true
                            })
                            this.$emit('close')
                            this.isLoading = false
                        })  
        },

        async getActivityTypes(){
            this.isLoading = true
            await axios.get("/vime_activity_types.json")
                        .then(res => {
                            this.activity_types = res.data;
                            this.isLoading = false
                        })
                        .catch(error => {
                            var showError = ' Favor intentarlo más tarde.'
                            if (error.response) {
                                showError = ' Favor revisar errores.<br>Errores:<br>'
                                for (const propiedad in error.response.data) {
                                    if (error.response.data.hasOwnProperty(propiedad)) {
                                        showError += error.response.data[propiedad].join(', ') + '<br>'
                                    }
                                }
                            }

                            this.$buefy.dialog.alert({
                                title: 'Error',
                                message: 'Hubo un error al traer los Tipos de Actividades.' + showError ,
                                type: 'is-danger',
                                hasIcon: true,
                                icon: 'times-circle',
                                iconPack: 'fa',
                                ariaRole: 'alertdialog',
                                ariaModal: true
                            })
                            this.$emit('close')
                            this.isLoading = false
                        })  
        },

        async getAssistancesTypes(){
            this.isLoading = true
            await axios.get('/vime_assistant_types/get_assistants_types/0.json')
                        .then(response =>{
                            this.vime_assistant_types = response.data
                            this.isLoading = false
                        })
                        .catch(error => {
                            var showError = ' Favor intentarlo más tarde.'
                            if (error.response) {
                                showError = ' Favor revisar errores.<br>Errores:<br>'
                                for (const propiedad in error.response.data) {
                                    if (error.response.data.hasOwnProperty(propiedad)) {
                                        showError += error.response.data[propiedad].join(', ') + '<br>'
                                    }
                                }
                            }

                            this.$buefy.dialog.alert({
                                title: 'Error',
                                message: 'Hubo un error al traer los Tipos de Participación.' + showError ,
                                type: 'is-danger',
                                hasIcon: true,
                                icon: 'times-circle',
                                iconPack: 'fa',
                                ariaRole: 'alertdialog',
                                ariaModal: true
                            })
                            this.$emit('close')
                            this.isLoading = false
                        })
        },

        async check() {
            const valid = await this.$refs.observer.validate()
            if (!valid) {
                this.$buefy.dialog.alert(
                {
                    title: 'Error',
                    message: 'Algunos campos presentan errores.', 
                    type: 'is-danger',
                    hasIcon: true, 
                    icon: 'times-circle', 
                    iconPack: 'fa', 
                    ariaRole: 'alertdialog', 
                    ariaModal: true
                }
                )
            }
            else if(this.edit) {
                this.$buefy.dialog.confirm({
                    title: "Editar Actividad",
                    message: `¿Esta seguro de Editar la Actividad?`,
                    cancelText: "Cancelar",
                    confirmText: "Editar Actividad",
                    type: "is-info",
                    onConfirm: () => this.editActivity(),
                })
            }
            else {
                this.$buefy.dialog.confirm({
                    title: "Nueva Actividad",
                    message: `¿Esta seguro de Guardar Actividad?`,
                    cancelText: "Cancelar",
                    confirmText: "Guardar Actividad",
                    type: "is-info",
                    onConfirm: () => this.saveActivity(),
                })
            }
        },

        async saveActivity() {
            this.isLoading = true
            await axios
                .post('/academic_vime_activities.json', {
                    vime_activity: this.activity,
                    person_id: this.personId,
                    assistance_type_id: this.assistance_type_id,
                })
                .then(response => {
                    this.$buefy.dialog.alert({
                        title: 'Logrado',
                        message: 'Se ha agregado correctamente la Actividad.',
                        type: 'is-success',
                        hasIcon: true,
                        icon: 'check-circle',
                        iconPack: 'fas',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.$emit('refresh')
                    this.$emit('close')
                    this.isLoading = false
                })
                .catch(error => {
                    var showError = ' Favor intentarlo más tarde.'
                    if (error.response) {
                        showError = ' Favor revisar errores.<br>Errores:<br>'
                        for (const propiedad in error.response.data) {
                            if (error.response.data.hasOwnProperty(propiedad)) {
                                showError += error.response.data[propiedad].join(', ') + '<br>'
                            }
                        }
                    }

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al guardar la Actividad.' + showError ,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.isLoading = false
                })
        },

        async editActivity() {
            this.isLoading = true
            await axios
                .put('/academic_vime_activities.json', {
                    id: this.activityId,
                    vime_activity: this.activity,
                    person_id: this.personId,
                    assistance_type_id: this.assistance_type_id,
                })
                .then(response => {
                    this.$buefy.dialog.alert({
                        title: 'Logrado',
                        message: 'Se ha editado correctamente la Actividad.',
                        type: 'is-success',
                        hasIcon: true,
                        icon: 'check-circle',
                        iconPack: 'fas',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.$emit('refresh')
                    this.$emit('close')
                    this.isLoading = false
                })
                .catch(error => {
                    var showError = ' Favor intentarlo más tarde.'
                    if (error.response) {
                        showError = ' Favor revisar errores.<br>Errores:<br>'
                        for (const propiedad in error.response.data) {
                            if (error.response.data.hasOwnProperty(propiedad)) {
                                showError += error.response.data[propiedad].join(', ') + '<br>'
                            }
                        }
                    }

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al editar la Actividad.' + showError ,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.isLoading = false
                })
        },

    }

}
</script>