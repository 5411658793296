<template>
    <section>

        <b-tabs position="is-centered" v-model="currentTab" :multiline="true">
            <b-tab-item v-for="item in items" v-bind:key="item.content" :value="item.content" :label="item.tab"
                :icon="item.icon" :disabled="item.disabled" />
        </b-tabs>
        <!-- COMPONENTE AQUI -->
        <keep-alive>
            <component v-bind:is="currentTabComponent" :person-id="personId" :photo-url="photoUrl" :min-year="minYear" :max-year="maxYear" :change-filter="changeFilter"
                :cargo-principal="cargoPrincipal" :hide-update="true" :can-create-document="canCreateDocument" />
        </keep-alive>

        <!-- FIN COMPONENTE -->
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

    </section>
</template>

<script>
import MyBookPublicationTab from './my_book_publication_data.vue'
import MyBookLetPublications from './my_booklet_publication_data.vue'
import MyInBookPublication from './my_inbook_publication_data.vue'
export default {
    props: [
        'personId', 'canCreateDocument', 'photoUrl', 'cargoPrincipal', 'minYear', 'maxYear', 'changeFilter'
    ],
    components: {
        MyBookPublicationTab, MyBookLetPublications, MyInBookPublication,
    },
    data() {
        return {
            publicaciones: [],
            isLoading: false,
            year: new Date().getFullYear(),
            currentTab: 'MyBookPublicationTab',
            items: [
                {tab: 'Mis Publicaciones en Libro',content: 'MyBookPublicationTab', icon:"book", disabled: false},
                {tab: 'Mis Publicaciones en Capítulo de Libro',content: 'MyBookLetPublications', icon:"book-open", disabled: false},
                {tab: 'Mis Publicaciones en Artículo de Libro',content: 'MyInBookPublication', icon:"file-alt", disabled: false},
            ],
        }
    },
    
    computed: {
        currentTabComponent: function () {
            return this.currentTab
        }
    },
}
</script>