var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.checkSave)}}},[_c('div',{staticClass:"modal-card",staticStyle:{"width":"1080px"}},[_c('header',{staticClass:"modal-card-head"},[(_vm.update)?_c('p',{staticClass:"modal-card-title"},[_vm._v("Editar Afiliación")]):_c('p',{staticClass:"modal-card-title"},[_vm._v("Agregar Nueva Afiliación")]),_vm._v(" "),_c('button',{staticClass:"delete",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}})]),_vm._v(" "),_c('section',{staticClass:"modal-card-body"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[(Object.keys(_vm.errores_validacion).length !== 0)?_c('div',{staticClass:"mb-2"},[_c('b-message',{attrs:{"type":"is-danger","has-icon":""}},_vm._l((_vm.errores_validacion),function(item,index){return _c('div',[(item)?_c('div',[_vm._v(_vm._s(item[0]))]):_vm._e()])}),0)],1):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"name":"Nombre Institución","rules":"min:3|max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-field',{attrs:{"type":{ 'is-danger': _vm.isPartnerEntityRegister || Object.keys(failedRules).length > 0 },"message":{ 'Debe añadir una entidad registrada': _vm.isPartnerEntityRegister || Object.keys(failedRules).length > 0 },"label":"Nombre Institución *"}},[_c('b-autocomplete',{attrs:{"keep-first":_vm.keepFirst,"open-on-focus":_vm.openOnFocus,"data":_vm.partner_entities,"field":"show_name","empty":"Vacio","clearable":_vm.clearable,"placeholder":"Ej: Sociedad Chilena de Ciencia de la Computación"},on:{"typing":_vm.searchPartnerEntities,"select":function (option) {if (option) {_vm.related_institution.partner_entity_id = option.id} }},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v("\n                      "+_vm._s(props.option.show_name ? props.option.show_name : props.option.name)+"\n                    ")]}}],null,true),model:{value:(_vm.related_institution.partner_entity_name),callback:function ($$v) {_vm.$set(_vm.related_institution, "partner_entity_name", $$v)},expression:"related_institution.partner_entity_name"}})],1)]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"column is-narrow"},[_c('br'),_vm._v(" "),_c('b-field',{attrs:{"label":" "}},[_c('b-tooltip',{attrs:{"label":"Si la entidad que desea agregar no existe, la puede agregar presionando el botón +","position":"is-left"}},[_c('b-button',{staticClass:"is-primary is-outlined",on:{"click":function($event){return _vm.addEntity()}}},[_c('b-icon',{attrs:{"icon":"plus"}})],1)],1)],1)],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"name":"Miembro en Calidad de","rules":"min:3|max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-field',{attrs:{"label":"Miembro en Calidad de *","type":{ 'is-danger': Object.keys(failedRules).length > 0 },"message":Object.keys(failedRules).length > 0 ? errors[0] : ''}},[_c('b-input',{attrs:{"placeholder":"Ej: Tesorero, Regular, etc"},model:{value:(_vm.related_institution.type_member),callback:function ($$v) {_vm.$set(_vm.related_institution, "type_member", $$v)},expression:"related_institution.type_member"}})],1)]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"columns mb-6"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"name":"Fecha de Inicio","rules":{ start_date_before_end_date: _vm.related_institution.end_date, required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-field',{attrs:{"label":"Fecha de Inicio *","type":{ 'is-danger': Object.keys(failedRules).length > 0 },"message":Object.keys(failedRules).length > 0 ? errors[0] : ''}},[_c('b-datepicker',{attrs:{"icon":"calendar-alt","trap-focus":"","locale":"es-ES","editable":"","placeholder":"dd/mm/aaaa"},model:{value:(_vm.related_institution.start_date),callback:function ($$v) {_vm.$set(_vm.related_institution, "start_date", $$v)},expression:"related_institution.start_date"}})],1)]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"name":"Fecha de Término","rules":{ end_date_not_before_start_date: _vm.related_institution.start_date }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-field',{attrs:{"label":"Fecha de Término","type":{ 'is-danger': Object.keys(failedRules).length > 0 },"message":Object.keys(failedRules).length > 0 ? errors[0] : ''}},[_c('b-datepicker',{attrs:{"icon":"calendar-alt","trap-focus":"","locale":"es-ES","editable":"","placeholder":"dd/mm/aaaa"},model:{value:(_vm.related_institution.end_date),callback:function ($$v) {_vm.$set(_vm.related_institution, "end_date", $$v)},expression:"related_institution.end_date"}})],1)]}}],null,true)})],1)]),_vm._v(" "),_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}})],1)]),_vm._v(" "),_c('footer',{staticClass:"modal-card-foot is-flex is-justify-content-space-between"},[_c('b-button',{attrs:{"label":"Cerrar"},on:{"click":function($event){return _vm.$emit('close')}}}),_vm._v(" "),_c('b-button',{attrs:{"label":"Guardar Datos","type":"is-primary"},on:{"click":function($event){return _vm.checkSave()}}})],1),_vm._v(" "),_c('b-modal',{attrs:{"destroy-on-hide":true,"width":"55%","can-cancel":_vm.canCancel},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('modal_partner_entity',{attrs:{"query-type":"update","modal-header":"Añadir nueva entidad"},on:{"close":props.close,"clicked":_vm.onClickChild}})]}}],null,true),model:{value:(_vm.isAddModalEntityActive),callback:function ($$v) {_vm.isAddModalEntityActive=$$v},expression:"isAddModalEntityActive"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }