<template>
    <div class="container">
        <div class="box">
            <div class="section pt-0">
                <!-- Titulo de la sección -->
                <div class="is-flex is-justify-content-center">
                    <h1 class="subtitle is-1 has-text-secondary">
                        Video Tutoriales
                    </h1>
                </div>
                <b-tabs :destroy-on-hide="true" @input="change()" expanded>
                    <b-tab-item v-if="academic === 'true' || permisoAcademic === 'true'" label="Académico" >
                        <academic :first_time="first_time"></academic>
                    </b-tab-item>
                    <b-tab-item v-if="jefeDepto === 'true' || is_super_admin === 'true'" label="Jefe Administrativo de departamento">
                        <jefe_admin ></jefe_admin>
                    </b-tab-item>
                </b-tabs>
            </div>
        </div>
    </div>
</template>

<script>

import academic from "./video_tutorials/academic.vue";
import jefe_admin from "./video_tutorials/jefe_admin.vue";
export default {
    name: "video_tutorials",
    props:['academic','jefeDepto','permisoAcademic','is_super_admin'],
    components: {
        academic,
        jefe_admin
    },
    data(){
        return{
            first_time: true
        }
    },
    methods:{
        change(){
            this.first_time = false
        }
    }
}
</script>

<style scoped>

</style>