<template>
<section>
  <br>
  <div class="container">
    <h1 class="is-size-2">Postulaciones Anuladas</h1>
  </div>
  <br>
  <div class="container">
    <b-table
        :data="PostulatedProjects"
        :paginated=true
        :card-layout="cardLayout"
        per-page=10
        striped
        hoverable
        :current-page="currentPage"
        :pagination-simple=false
        pagination-position="bottom"
        default-sort-direction="asc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="project.name"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página actual"
        :opened-detailed="defaultOpenedDetails"
        detailed
        detail-key="code"
        :show-detail-icon="showDetailIcon"
    >
      <b-table-column
          field= 'name'
          label= 'Nombre'
          width="30em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.name }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'project_type.name'
          label= 'Tipo de Proyecto'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.project_type ? props.row.project_type.name : "" }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'code'
          label= 'Código'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.code }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'postulation_date'
          label= 'Fecha Postulación'
          searchable
          :custom-search="funcionCustom"
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{props.row.postulation_date._isValid ? new Date(props.row.postulation_date).toLocaleDateString("es-CL") : ''}}
        </template>
      </b-table-column>
      <b-table-column
          field= 'university_project_role.name'
          label= 'Rol Universidad'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.university_project_role ? props.row.university_project_role.name : "" }}
        </template>
      </b-table-column>
      <b-table-column
          label="Acciones"
          v-slot="props">
        <b-icon
            pack="fas"
            icon="eye"
            size="is-medium"
            @click.native="execShowModal(props.row)"/>
        <b-icon
            pack="fas"
            icon="trash-restore"
            size="is-medium"
            type="is-success"
            @click.native="execRestoreModal(props.row.id, props.row)"/>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>Participantes: </strong>
                <span v-for="(participant, index) in props.row.participants" :key="index">
                  <a v-if="participant.url!=null" :href="participant.url">{{ participant.name }}</a>
                  <span v-else>{{ participant.name }}</span>
                  <span v-if="props.row.participants.length>1 && participant!=props.row.participants.slice(-1)[0]">, </span>
                </span>
              </p>
            </div>
          </div>
        </article>
      </template>
      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-danger">
              No se encontraron resultados.
            </b-message>
          </div>
        </div>
      </template>
    </b-table>
  </div>

  <b-modal
      v-model="isShowModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal>
    <template #default="props">
      <modal-show-nullified-postulated-project
          :project="project"
          :cargoPrincipal="''"
          query-type="create"
          modal-header="Proyecto"
          @close="props.close"
      ></modal-show-nullified-postulated-project>
    </template>
  </b-modal>

  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
</section>
</template>

<script>
import moment from "moment";
import axios from "axios";
import ModalShowNullifiedPostulatedProject from "./ModalShowNullifiedPostulatedProject";
export default {
  name: "NullifiedPostulatedProjectsList",
  components: {ModalShowNullifiedPostulatedProject},
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      PostulatedProjects:[],
      isShowModalActive:false,
      project:{}

    }
  },
  created(){
    this.getProjects();
  },
  methods:{
    getProjects(year){
      this.isLoading=true;
      return axios.get("/projects/nullified-postulated.json",{params:{year_f:year}})
          .then(res => {
            this.PostulatedProjects = res.data;
            this.PostulatedProjects = this.PostulatedProjects.map(proyecto => {
              var newProyecto = proyecto
              newProyecto["postulation_date"] =  moment(newProyecto["postulation_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newProyecto;
            });
            this.isLoading=false;
          })
          .catch(e => {
            this.isLoading=false;
            console.log(e);
          });
    },
    getFormattedDate(date){
      return moment(date).format('DD/MM/YYYY')
    },
    funcionCustom(row,input){
      return searchDate(row.postulation_date,input)
    },
    execShowModal(project){
      this.project = project;
      this.isShowModalActive = true;
    },
    execRestoreModal(project_id,row){
      let vm = this;
      vm.$buefy.dialog.confirm({
        title: 'Restaurar proyecto postulado anulado',
        message: '¿Está segura/o que desea <b>restaurar</b> este proyecto postulado? Esta acción revertirá su anulación.',
        confirmText: 'Restaurar Proyecto Postulado',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          //vm.isRestoreActive = true
          vm.isLoading = true
          axios
            .put("/projects/" + project_id + "/restore_project.json")
            .then(res => {
              vm.$buefy.dialog.alert({
                message: 'Proyecto postulado restaurado correctamente',
                duration: 5000,
                type: 'is-success',
                canCancel: ["escape", "outside"]
              })
              var index = this.PostulatedProjects.indexOf(row)
              this.PostulatedProjects.splice(index, 1)
              vm.isLoading = false;
              //vm.isRestoreActive = false;
            }).catch(error => {
              //console.log(error)
              vm.$buefy.dialog.alert({
                message: '<b>Este proyecto postulado no pudo ser restaurado</b><br>'+error.response.data,
                duration: 5000,
                type: 'is-danger',
                canCancel: ["escape", "outside"]
              })
              vm.isLoading = false;
              //vm.isRestoreActive = false;
            })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>