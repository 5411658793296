<template>
  <section>

    <b-tabs position="is-centered" v-model="currentTab" :multiline="true">
      <b-tab-item v-for="item in items" v-bind:key="item.content" :value="item.content" :label="item.tab"
                  :icon="item.icon" :disabled="item.disabled" />
    </b-tabs>
    <!-- COMPONENTE AQUI -->
    <keep-alive>
      <component v-bind:is="currentTabComponent" :min-year="minYear" :max-year="maxYear" :change-filter="changeFilter"
                 :cargo-principal="cargoPrincipal" :current-year="currentYear" :object-id="objectId" :hide-update="hideUpdate"
                 :component-type="componentType"/>
    </keep-alive>

    <!-- FIN COMPONENTE -->
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

  </section>
</template>

<script>
import BookPublication from "./book_publication.vue";
import BookletPublication from "./booklet_publication.vue";
import InbookPublication from "./inbook_publication.vue";
export default {
  props: [
    'objectId','componentType', 'cargoPrincipal','currentYear','minYear','maxYear', 'changeFilter', 'hideUpdate'
  ],
  components: {
    BookPublication, BookletPublication, InbookPublication,
  },
  data() {
    return {
      publicaciones: [],
      isLoading: false,
      year: new Date().getFullYear(),
      currentTab: 'BookPublication',
      items: [
        {tab: 'Publicaciones en Libro',content: 'BookPublication', icon:"book", disabled: false},
        {tab: 'Publicaciones en Capítulo de Libro',content: 'BookletPublication', icon:"book-open", disabled: false},
        {tab: 'Publicaciones en Artículo de Libro',content: 'InbookPublication', icon:"file-alt", disabled: false},
      ],
    }
  },

  computed: {
    currentTabComponent: function () {
      return this.currentTab
    }
  },
}
</script>