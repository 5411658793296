<template>
    <section>
      <div class="columns">
        <div class="column">
          <b-message type="is-info" has-icon>
            La actividad académica solo puede ser <b>editada</b> o <b>anulada</b> por quien agregó estos datos
            al sistema. Si hay un error en la información por favor ponerse en contacto con la persona propietaria.
            Si el dato que ve no corresponde a usted, puede <b>eliminar</b> su participación en la sección
            <b>Participantes</b>, y este dato dejará de estar visible.
          </b-message>
        </div>
      </div>
<!--      <div v-if="can_create_academic_activity === 'true'" class="columns" >
        <div class="column is-offset-8">
          <b-field label = " " class="has-text-right">
            <add-academic-activity-dropdown></add-academic-activity-dropdown>
          </b-field>
        </div>
      </div>-->
        <b-tabs position="is-centered" @input="changeTab" :multiline="true">
            <b-tab-item v-for="item in items" v-bind:key="item.content" :value="item.content" :label="item.tab"
                :icon="item.icon" :disabled="item.disabled"/>
        </b-tabs>
        <!-- COMPONENTE AQUI -->
        <keep-alive>
            <component v-bind:is="currentTabComponent" :person-id="personId" :photo-url="photoUrl" :min-year="minYear" :max-year="maxYear" :change-filter="changeFilter"
                :cargo-principal="cargoPrincipal" :hide-update="true" :can-create-document="canCreateDocument" :can_create_academic_activity="can_create_academic_activity" />
        </keep-alive>

        <!-- FIN COMPONENTE -->
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </section>
</template>

<script>
import MyResearchData from './my_research_data_tab.vue'
import MyPublications from './my_publications_tab.vue'
import MyProjects from './my_projects_tab.vue'
import MyPatents from './my_patents_tab.vue'
import MyDisclosures from './my_disclosures_tab.vue'
import AddAcademicActivityDropdown from "./add_academic_activity_dropdown.vue"
export default {
    components: {
        MyResearchData, MyPublications, MyProjects, MyPatents, MyDisclosures, AddAcademicActivityDropdown
    },
    props:[
        'personId','photoUrl', 'cargoPrincipal', 'canCreateDocument', 'minYear', 'maxYear', 'changeFilter', 'can_create_academic_activity'
    ],
    data() {
        return {
            isLoading:false,
            currentTab: 'MyResearchData',
            items: [
                {tab: 'Mis Datos de Investigación',content: 'MyResearchData', icon:"book-reader", disabled: false},
                {tab: 'Publicaciones',content: 'MyPublications', icon:"file-alt", disabled: false},
                {tab: 'Proyectos',content: 'MyProjects', icon:"folder-open", disabled: false},
                {tab: 'Patentes',content: 'MyPatents', icon:"lightbulb", disabled: false},
                {tab: 'Disclosures',content: 'MyDisclosures', icon:"folder", disabled: true},
            ],
        }
    },
    methods: {
        changeTab: function(value){
            this.currentTab = value
        },
    },
    computed: {
        currentTabComponent: function () {
            return this.currentTab
        }
    }
}
</script>