var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [(Object.keys(_vm.errores_validacion).length != 0)?_c('div',{staticClass:"mb-2"},[_c('b-message',{attrs:{"type":"is-danger","has-icon":""}},_vm._l((_vm.errores_validacion),function(item,index){return _c('div',{key:index},[_vm._v("\n                    "+_vm._s(item[0])+"\n                ")])}),0)],1):_vm._e(),_vm._v(" "),_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveMyAcademicData)}}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"min:3|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":"Alias","message":errors[0]}},[_c('b-input',{attrs:{"type":"text","maxlength":"30"},model:{value:(_vm.person.alias),callback:function ($$v) {_vm.$set(_vm.person, "alias", $$v)},expression:"person.alias"}})],1)]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"min:3|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":"ORCID","message":errors[0]}},[_c('b-input',{attrs:{"expanded":"","type":"text"},model:{value:(_vm.person.orcid),callback:function ($$v) {_vm.$set(_vm.person, "orcid", $$v)},expression:"person.orcid"}}),_vm._v(" "),_c('b-button',{attrs:{"tag":"a","href":_vm.person.orcid ? 'https://orcid.org/' + _vm.person.orcid : 'https://orcid.org/',"target":"_blank","icon-left":"external-link-alt","type":"is-info","label":"Link"}})],1)]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"integer|min:3|max:255|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":"ID Scopus","message":errors[0]}},[_c('b-input',{attrs:{"expanded":"","type":"number"},model:{value:(_vm.person.scopus_id),callback:function ($$v) {_vm.$set(_vm.person, "scopus_id", $$v)},expression:"person.scopus_id"}}),_vm._v(" "),_c('b-button',{attrs:{"tag":"a","href":_vm.person.scopus_id ? 'https://www.scopus.com/authid/detail.uri?authorId=' + _vm.person.scopus_id : 'https://www.scopus.com/',"target":"_blank","icon-left":"external-link-alt","type":"is-info","label":"Link"}})],1)]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"min:3|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":"ID Wos","message":errors[0]}},[_c('b-input',{attrs:{"expanded":"","type":"number"},model:{value:(_vm.person.wos_id),callback:function ($$v) {_vm.$set(_vm.person, "wos_id", $$v)},expression:"person.wos_id"}}),_vm._v(" "),_c('b-button',{attrs:{"tag":"a","href":_vm.person.wos_id ? 'https://www.webofscience.com/wos/author/record/' + _vm.person.wos_id : 'https://www.webofscience.com/',"target":"_blank","icon-left":"external-link-alt","type":"is-info","label":"Link"}})],1)]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"min:3|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":"ID Scielo","message":errors[0]}},[_c('b-input',{attrs:{"type":"number"},model:{value:(_vm.person.scielo_id),callback:function ($$v) {_vm.$set(_vm.person, "scielo_id", $$v)},expression:"person.scielo_id"}})],1)]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"integer|positive|min:1|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":"H-Index","message":errors[0]}},[_c('b-input',{attrs:{"type":"number"},model:{value:(_vm.person.h_index),callback:function ($$v) {_vm.$set(_vm.person, "h_index", $$v)},expression:"person.h_index"}})],1)]}}],null,true)})],1)]),_vm._v(" "),(_vm.person.can_update_my_academic_activity)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column has-text-right"},[_c('button',{staticClass:"button is-primary",attrs:{"type":"submit"},on:{"click":_vm.checkValidation}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-save"})]),_vm._v(" "),_c('span',[_vm._v("Guardar Datos de Investigación")])])])]):_vm._e()])]}}])}),_vm._v(" "),_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }