<template>
  <section>
    <div class="container">
      <div class="box mt-5">
        <div class="columns">
          <div class="column">
            <b-field grouped group-multiline>
              <div class="title is-1">Reportería Documentación</div>
            </b-field>
          </div>
        </div>
        <section>
          <div class="columns">
            <div class="column">
              <b-field label="Fecha desde">
                <b-datepicker
                    v-model="start_date"
                    icon="calendar-alt"
                    trap-focus
                    locale="es-ES"
                    editable
                    placeholder="Formato de Fecha: dd/mm/aaaa">
                </b-datepicker>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Fecha hasta">
                <b-datepicker
                    v-model="end_date"
                    icon="calendar-alt"
                    trap-focus
                    locale="es-ES"
                    editable
                    placeholder="Formato de Fecha: dd/mm/aaaa">
                </b-datepicker>
              </b-field>
            </div>
          </div>
          <div class="columns has-text-right">
            <div class="column">
              <b-button icon-left="search" class="is-primary" @click="generateReport">
                Buscar
              </b-button>
            </div>
          </div>
        </section>
      </div>
      <div v-if="users.length > 0" class="box mt-5">
        <div class="title is-3">
          {{title_document}}
        </div>
        <div class="subtitle">
          Se gestionaron en total
          <b>{{size_documents}}</b>
          documentos.
        </div>
        <section>
          <b-table
              :data="users"
              :paginated=true
              per-page=10
              striped
              hoverable
              narrowed
              :card-layout="cardLayout"
              :current-page="currentPage"
              :pagination-simple=false
              pagination-position="bottom"
              default-sort-direction="desc"
              sort-icon="arrow-up"
              sort-icon-size="is-small"
              default-sort="documents.formatted_document_date"
              aria-next-label="Siguiente"
              aria-previous-label="Anterior"
              aria-page-label="Página"
              aria-current-label="Página actual"
              :opened-detailed="defaultOpenedDetails"
              detailed
              detail-key="corporate_mail"
              style="word-break: break-word;"
              :show-detail-icon="showDetailIcon"
          >

            <b-table-column
                field= 'corporate_mail'
                label= 'Correo institucional'
                searchable
                sortable>
              <template
                  slot="searchable"
                  slot-scope="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    icon="search"
                    size="is-small" />
              </template>
              <template v-slot="props">
                {{ props.row.corporate_mail}}
              </template>
            </b-table-column>
            <b-table-column
                field= 'full_name'
                label= 'Nombre completo'
                searchable
                sortable>
              <template
                  slot="searchable"
                  slot-scope="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    icon="search"
                    size="is-small" />
              </template>
              <template v-slot="props">
                {{ props.row.full_name}}
              </template>
            </b-table-column>

            <b-table-column
                field= 'document_size'
                label= 'Cantidad de documentos gestionados'
                sortable>
              <template v-slot="props">
                {{ props.row.document_size}}
              </template>
            </b-table-column>
            <template slot="detail" slot-scope="props">
              <section>
                <div class="title is-4">Documentos gestionados por {{props.row.full_name}}</div>
                <div class="box mt-5">
                  <b-table
                      :data="props.row.list_documents"
                      :paginated=true
                      per-page=10
                      striped
                      hoverable
                      narrowed
                      :card-layout="cardLayoutSub"
                      :current-page="currentPageSub"
                      :pagination-simple=false
                      pagination-position="bottom"
                      default-sort-direction="desc"
                      sort-icon="arrow-up"
                      sort-icon-size="is-small"
                      default-sort="documents.formatted_document_date"
                      aria-next-label="Siguiente"
                      aria-previous-label="Anterior"
                      aria-page-label="Página"
                      aria-current-label="Página actual"
                  >
                    <b-table-column
                        field= 'document_name'
                        label= 'Nomenclatura del documento'
                        searchable
                        width="350"
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.document_name}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field= 'document_type_name'
                        label= 'Tipo del documento'
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.document_type_name}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field= 'accion_type'
                        label= 'Tipo de acción'
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.accion_type}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field= 'accion_date'
                        label= 'Fecha de acción'
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.accion_date}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        label="Acciones"
                        v-slot="props"
                        width="110">
                      <b-tooltip
                          v-if="props.row.can_show"
                          label="Visualiza el documento. Solo permite archivos PDF"
                          :active="true">
                        <b-button
                            icon-pack="fas"
                            icon-left="eye"
                            type="is-success is-light is-outlined"
                            target="_blank"
                            v-if="props.row.can_show"
                            @click="viewFile(props.row.document_id)"/>
                      </b-tooltip>
                    </b-table-column>
                  </b-table>
                </div>
              </section>
            </template>
          </b-table>
        </section>
      </div>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

      <b-modal
          v-model="showPdf"
          :can-cancel="true"
          :destroy-on-hide="true"
          aria-modal
          aria-role="dialog"
          has-modal-card
          trap-focus
          :width="2000"
      >
        <template #default="props">
          <pdf_view
              :src="src"
              @close="props.close"
          ></pdf_view>
        </template>

      </b-modal>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Pdf_view from "../performances/pdf_view.vue";

export default {
  name: "control_document",
  components:{
    Pdf_view,
  },
  data(){
    return{
      isLoading:false,
      start_date: null,
      end_date: null,
      size_documents: 0,
      users:[],
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      currentPage:1,
      currentPageSub:1,
      cardLayoutSub:false,
      start_date_info: null,
      end_date_info: null,
      showPdf: false,
      src:null,
      title_document: ""
    }
  },
  created() {

  },
  methods:{
    generateReport(){
      this.isLoading = true
      this.title_document = ''
      this.size_documents = 0
      if(this.start_date != null && this.start_date != '' && this.end_date != null && this.end_date != '' && this.start_date > this.end_date){
        this.isLoading = false
        this.$buefy.toast.open({
          message:
              'La fecha desde no puede ser mayor a la fecha hasta.',
          type: "is-danger",
          size: "is-medium",
          "has-icon": true,
          position: "is-top",
          "progress-bar": true,
          duration: 5000,
          queue: true,
        });

        return
      }

      axios.get("/life_record/get-report-documents.json",{params: {
          start_date: this.start_date,
          end_date: this.end_date
        }})
          .then(res => {
            this.size_documents = res.data['document_size']
            this.users = res.data['users']
            this.start_date_info = moment(this.start_date).format('DD/MM/YYYY')
            this.end_date_info = moment(this.end_date).format('DD/MM/YYYY')
            if(this.start_date != null && this.start_date != '' && (this.end_date == null || this.end_date == '')){
              this.title_document = 'Documentos gestionados desde la fecha '+this.start_date_info
            }
            else if(this.end_date != null && this.end_date != '' && (this.start_date == null || this.start_date == '')){
              this.title_document = 'Documentos gestionados hasta la fecha '+this.end_date_info
            }
            else if(this.start_date != null && this.start_date != '' && this.end_date != null && this.end_date != '' && this.start_date > this.end_date){
              this.title_document = 'Documentos gestionados entre las fechas '+this.start_date_info+' y '+this.end_date_info
            }
            else{
              this.title_document = 'Todos los documentos gestionados hasta la fecha'
            }
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading=false;
          });
    },
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            if(res.data.type != "application/pdf"){
              this.$buefy.dialog.alert({
                message: "El formato del archivo no es compatible con el visualizador. Solo permite PDF.",
                type: "is-danger",
                hasIcon: true
              })
              this.isLoading = false
            }
            else{
              this.showPdf = true
              this.isLoading = false
            }
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
  },
  computed: {
    formated_start_date(){
      if(this.start_date){
        return moment(this.start_date).format('DD/MM/YYYY')
      }
      return ""
    },
    formated_end_date(){
      if(this.end_date){
        return moment(this.end_date).format('DD/MM/YYYY')
      }
      return ""
    },
  }
}
</script>


<style scoped>

</style>