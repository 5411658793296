<template>
    <section>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(saveDocument)">
              <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                  <p class="modal-card-title">{{modalHeader}}</p>
                  <button
                      type="button"
                      class="delete"
                      @click="$emit('close')"/>
                </header>
                <section class="modal-card-body" >
                  <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
                    <b-message type="is-danger" has-icon>
                      <div v-for="(item,index) in errores_validacion" :key="index">
                        {{ item[0] }}
                      </div>
                    </b-message>
                  </div>
                  <div class="mb-2" v-if="notRun">
                    <b-message type="is-danger" has-icon>
                      {{message}}
                    </b-message>
                  </div>
                  <div class="columns" v-if="typeDocument=='personExternal'">
                    <div class="column">
                      <b-field label="Servicio*">
                        <ValidationProvider rules="required" v-slot="{ errors }">

                          <multiselect
                              v-model="service"
                              :options="services"
                              :custom-label="nameService"
                              placeholder="Seleccione Servicio"
                              track-by="name"
                              selectLabel=""
                              @select="updateName"
                              selectedLabel=""
                              deselectLabel=""
                              :allow-empty="true">

                            <template slot="singleLabel" slot-scope="{ option }">
                              {{ option.name || option.person_name }}
                            </template>


                          </multiselect>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>

                    </div>

                  </div>



                  <div class="columns" v-if="typeDocument=='person'">
                    <div class="column">
                      <b-field label="Persona*">
                          <ValidationProvider rules="required" v-slot="{ errors }">

                            <multiselect 
                                v-model="integrante" 
                                :options="integrantes" 
                                :custom-label="nameWithLang" 
                                placeholder="Seleccione Persona" 
                                track-by="name"
                                selectLabel=""
                                @select="updateName"
                                selectedLabel=""
                                :disabled="isShow"
                                deselectLabel=""
                                :allow-empty="true">
                                


                                </multiselect>
                              <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                      </b-field>
                      
                    </div>
                    
                  </div>
                  <div class="columns">
                    <div class="column " v-if="procesos.length>0">
                      <b-field label="Proceso*">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                              <multiselect v-model="proceso"
                                             :options="procesos.map(type => type.id)"
                                             :custom-label="opt => procesos.find(x => x.id == opt).name"
                                             placeholder="Seleccione Proceso"
                                             selectLabel=""
                                             :disabled="isShow"
                                             selectedLabel=""
                                             deselectLabel=""
                                             @select="selectProcess"
                                             :allow-empty="true">
                                  <template v-slot:noOptions>
                                      No existen datos
                                  </template>
                                  <span slot="noResult">
                                      No se encontraron elementos.
                                  </span>
                              </multiselect>
                              <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                      </b-field>
                    </div> 

                    <div class="column " v-if="procesos.length>0">
                      <b-field label="Tipo documento*">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                              <multiselect v-model="tipo"
                                             :options="tipos.map(type => type.id)"
                                             :custom-label="opt => tipos.find(x => x.id == opt).name"
                                             placeholder="Seleccione Tipo de documento"
                                             selectLabel=""
                                             selectedLabel=""
                                             deselectLabel=""
                                             :disabled="isDisabled || isShow"
                                             @select="updateName"
                                             :allow-empty="true">
                                  <template v-slot:noOptions>
                                      No existen datos
                                  </template>
                                  <span slot="noResult">
                                      No se encontraron elementos.
                                  </span>
                              </multiselect>
                              <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                      </b-field>
                    </div> 


                     
                    

                     

                  </div>
                  <div class="columns">
                    <div class="column ">
                      <b-field label="Fecha de documento*">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <b-datepicker
                            v-model="fecha"
                            icon="calendar-alt"
                            :disabled="isShow"
                            locale="es-ES"
                            editable
                            trap-focus
                            @input="updateName"
                            placeholder="Formato de Fecha: dd/mm/aaaa"
                            append-to-body
                          >
                          </b-datepicker>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns" v-if="!isShow">
                    <div class="column has-text-centered">
                      <b-message class="is-inline-block is-centered" type="is-warning" title="¡Importante!" has-icon aria-close-label="Close message">
                        Extensiones de archivo permitido: PDF, DOC, DOCX, XLSX, XLS.
                        <br />
                        Tamaño máximo de archivo permitido: <b> 10 MB</b>
                      </b-message>

                      <b-field v-if="queryType!='update'" extended label="Subir documento*">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <b-upload v-model="file" drag-drop>
                              <div class="content has-text-centered">
                                  <p>
                                      <b-icon icon="file-pdf" size="is-large"></b-icon>
                                  </p>
                                  <p>Arrastra tu documento o haz click para subir.</p>
                              </div>
                          </b-upload>
                          <br>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider> 
                      </b-field>
                      <b-field v-else extended label="Subir documento">
                        <ValidationProvider rules="" v-slot="{ errors }">
                          <b-upload v-model="file" drag-drop>
                              <div class="content has-text-centered">
                                  <p>
                                      <b-icon icon="file-pdf" size="is-large"></b-icon>
                                  </p>
                                  <p>Arrastra tu documento o haz click para subir.</p>
                              </div>
                          </b-upload>
                          <br>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider> 
                      </b-field>
                      <div class="columns tags is-centered">
                        <span
                                v-if="file !== null"
                                v-model="file"
                                class="tag is-primary mt-1"
                        >
                          {{ file.name }}
                          <button
                                  class="delete is-small"
                                  type="button"
                                  @click="onDeleteFile"
                          ></button>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <b-field v-if="queryType!='show'" label="Nombre final del documento" >
                        <b-input v-model="name_prob" disabled type="text"></b-input>
                      </b-field>
                      <b-field v-else label="Nombre del documento">
                          <b-input v-model="name_prob" disabled type="text"></b-input>
                      </b-field>
                      
                    </div>
                    
                  </div>


                </section>
              </div>
              <footer class="modal-card-foot actions">
                  <button class="button  left-button" type="button" @click="$emit('close')">Cerrar</button>
                  <b-button v-if="queryType != 'show'" class="button  right-button is-primary" left-button="save" native-type="submit" :disabled="notRun" @click="checkValidation()">Guardar </b-button>
                
              </footer>
            </form>
          </ValidationObserver>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </section>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "ModalUploadDocument",
  components:{
  },
  data() {
    return{
      isLoading: false,
      errores_validacion:{},
      canCancel: ['escape'],
      procesos: [],
      tipos: [],
      integrante: null,
      src: [],
      fecha: new Date(),
      file: null,
      proceso: null,
      tipo: null,
      isDisabled: true,
      name_prob: "",
      message: "",
      notRun: false,
      flag: true,
      service: null,
      isShow: false,


    }
  },
  props:['queryType','serviceCode','serviceProjectManagment','serviceId','serviceTable', 'document',
        "cargoPrincipal", 'modalHeader','isServiceCreated','typeDocument','integrantes','services',
        ],

  created() {
    let vm = this;
    vm.isLoading=true;
    vm.updateName();
    if (vm.serviceProjectManagment.run == null){
      vm.message = "La información del RUT de la persona no se encuentra disponible, el documento no puede ser almacenado por falta de información."
      vm.notRun = true;
    }
    axios.get("/origin_document_types/origin-document-sdt.json", {params:{type: vm.typeDocument}})
      .then(async (res) => {
        vm.procesos = res.data;
        if (vm.queryType=="update" || vm.queryType=="show"){
          vm.proceso = vm.document.origin_document_type.id;                  
          await vm.selectProcess();
          if (vm.document.document_date!=null && vm.document.document_date!=undefined);
            vm.fecha = new Date(moment(vm.document.document_date).format('MM/DD/YYYY'));
          vm.tipo = vm.document.document_type_id;
          if (vm.typeDocument == "person"){
            vm.integrante = vm.integrantes.find(elemento => elemento.person_id === vm.document.owner);
          }
          if (vm.queryType=="show"){
            vm.isShow = true;
          }
          vm.updateName();          
        }
        this.isLoading=false;
      })
      .catch(e => {
        vm.isLoading=false;
        //console.log(e);
      });
  },
  methods :{

    saveDocument(file){
      let vm = this;

      const archivoDatos = {
        file: vm.file,
        fecha: vm.fecha,
        tipo: vm.proceso,
      };
      if(vm.isServiceCreated == false)
      {
        vm.$emit("clicked",archivoDatos);
        vm.$emit('close')
      }
      else
      {
        let run = ""
        let id_run = ""
        if (vm.typeDocument != "person"){
          if (vm.serviceProjectManagment.run.rut != null){
            run = vm.serviceProjectManagment.run.rut.toString();
            id_run = vm.serviceProjectManagment.run.id;
          }
          else if (vm.serviceProjectManagment.run.passport != null)
          {
            run = vm.serviceProjectManagment.run.passport.toString();
            id_run = vm.serviceProjectManagment.run.id;
          }
        }
        else{
          if (vm.integrante.run.rut != null){
            run = vm.integrante.run.rut.toString();
            id_run = vm.integrante.run.id;
          }
          else if (vm.integrante.run.passport != null)
          {
            run = vm.integrante.run.passport.toString();
            id_run = vm.integrante.run.id;
          }
        }
        let code = "";
        let id_service = "";
        let table_name = "";
        if(vm.typeDocument == "personExternal"){
          if(vm.service!=null){
            code = vm.service.code;
            id_service = vm.service.id;
            table_name = vm.service.table;
          }
        }
        else{
          code = vm.serviceCode;
          id_service = vm.serviceId;
          table_name = vm.serviceTable;
        }

        const params = {
          "type": vm.tipo,
          "table": table_name,
          "id_service": id_service,
          "date": moment(vm.fecha).format("MM_YYYY"),
          "run": run,
          "id_run": id_run,
          "document_date": vm.fecha,
          "file":vm.file,
        };
        let formData = new FormData();
        Object.entries(params).forEach(
          ([key,value]) => formData.append(key,value))


        vm.isLoading = true;
        if (vm.queryType=="update"){
          axios
            .put("/documents/"+vm.document.id+"/update-sdt.json", formData)
            .then((res) => {
              let nuevo = "";
              nuevo = "El documento fue actualizado con éxito.<br/> <br/>Nombre: <br/>'"+res.data.name.toString()+"'<br/>";
              vm.isLoading = false;
              this.$buefy.dialog.alert({
                message: nuevo,
                confirmText: "Ok",            
              });

              res.data.document_date = moment(res.data.document_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
              if(vm.typeDocument != "person"){
                vm.$emit("clickedUpdate",res.data);
              }
              else{
                vm.$emit("clickedUpdatePerson", res.data)
              }
              vm.$emit('close')
            })
            .catch(e => {
              vm.errores_validacion = e.response.data
              vm.isLoading=false;
          });   
        }
        else{

          axios
          .post("/documents/save-document-sdt.json", formData)
          .then((res) => {
            let nuevo = "";
            nuevo = "El documento fue creado con éxito.<br/> <br/>Nombre: <br/>'"+res.data.name.toString()+"'<br/>";
            vm.isLoading = false;
            this.$buefy.dialog.alert({
              message: nuevo,
              confirmText: "Ok",            
            });

            res.data.document_date = moment(res.data.document_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            if(vm.typeDocument != "person"){
              vm.$emit("clicked",res.data);
            }
            else{
              vm.$emit("clickedPerson", res.data)
            }
            vm.$emit('close')
          })
          .catch(e => {
            vm.errores_validacion = e.response.data
            vm.isLoading=false;
        });   


        }

        
        
      }
      
    },
    async onAddFile() {
      if (this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
         // this.ayuda = this.file;
          this.file = null;
          this.$buefy.dialog.alert({
              message: "Este archivo no tiene formato adecuado",
              type: "is-danger",
              hasIcon: true,
          });
          return;
      }

    },
    nameWithLang ({ name, person_name, run }) { 
      let msg_run = ""
      if (run != null){
        if (run.passport != null){
          msg_run = " ["+run.passport+"]" 
        }
        if (run.rut != null){
          msg_run = " ["+run.rut.toString()+"-"+run.dv+"]"
        }
      }
      let msj = ""
          
      if (name != null )
      {
        msj = name + msg_run 
      }
      else{
        msj = person_name + msg_run 
      }
      return msj
    },
    nameService ({ name, code }) {
      return `${name} [${code}] `
    },
    updateName(){
      let vm = this;
      let run = "XXXXXXXX"
      if (vm.typeDocument != "person"){
        if (vm.serviceProjectManagment.run != null){
          if (vm.serviceProjectManagment.run.rut != null){
            run = vm.serviceProjectManagment.run.rut.toString();
          }
          else if (vm.serviceProjectManagment.run.passport != null)
          {
            run = vm.serviceProjectManagment.run.passport.toString();
          }
        }
      }
      else{
        if (vm.integrante != null){ 
          if (vm.integrante.run != null){
            if (vm.integrante.run.rut != null){
              run = vm.integrante.run.rut.toString();
              vm.message = ""
              vm.notRun = false;
            }
            else if (vm.integrante.run.passport != null)
            {
              run = vm.integrante.run.passport.toString();
              vm.message = ""
              vm.notRun = false;
            }
          }
          else{
            vm.message = "La información del RUT de la persona no se encuentra disponible, el documento no puede ser almacenado por falta de información."
            vm.notRun = true;
          }
        }


      }
      let code = "XXX"
      if(vm.typeDocument == "personExternal"){
        if(vm.service!=null){
          code = vm.service.code
        }
      }
      else{
        code = vm.serviceCode
      }

      let sigla = "XX" 
      if (vm.tipo != null){
        sigla = vm.tipos.find(elemento => elemento.id === vm.tipo);
        sigla = sigla.initials;
      }
      let date = "MM_AAAA" 
      if (vm.fecha != null){
        date = moment(vm.fecha).format("MM_YYYY");
      }
      vm.name_prob= sigla+"-"+code+"_RUT_"+run+"-"+date
    },
    onDeleteFile() {
        this.src = [];
        this.file = null;
    },
    async selectProcess(){
      let vm = this;
      vm.isLoading=true;
      await  axios.get("/document_type_names/document-names.json",{params:{id:vm.proceso}})
        .then(res => {
          vm.tipos = [];
          vm.tipo = null;
          vm.tipos = res.data;
          vm.isDisabled = false;
          vm.isLoading = false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });

    },
    async checkValidation(){
      const valid = await this.$refs.observer.validate()
      if(!valid){
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },

  },



}

</script>

<style scoped>

  .right-button{
    margin-left: auto;
  }
  .left-button{
    margin-right: auto;
  }


</style>