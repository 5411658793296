var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"modal-card",staticStyle:{"width":"100%"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(_vm._s(_vm.modalHeader))]),_vm._v(" "),_c('button',{staticClass:"delete",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}})]),_vm._v(" "),_c('section',{ref:"modalPersonForm",staticClass:"modal-card-body",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [(Object.keys(_vm.errores_validacion).length != 0)?_c('div',{staticClass:"mb-2"},[_c('b-message',{attrs:{"type":"is-danger","has-icon":""}},_vm._l((_vm.errores_validacion),function(item,index){return _c('div',[_vm._v("\n                  "+_vm._s(item[0])+"\n                ")])}),0)],1):_vm._e(),_vm._v(" "),_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveAssociation)}}},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                        Nombre Entidad Externa* (ingrese por lo menos 3 letras)\n                        "),_c('b-tooltip',{attrs:{"label":"Se recomienda búsqueda de agrupaciones por sigla, ejemplo: ANID, no Agencia Nacional... mientras que para instituciones educacionales, se recomienda por nombre completo, ejemplo: universidad de santiago de chile","position":"is-right","size":"is-medium","multilined":""}},[_c('b-icon',{attrs:{"pack":"fas","icon":"info-circle","size":"is-small","type":"is-info"}})],1)]},proxy:true}],null,true)},[_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('b-autocomplete',{attrs:{"data":_vm.partner_entities,"placeholder":"ej ANID o UNIVERSIDAD DE SANTIAGO DE CHILE  o NOMBRE EMPRESA - ENTIDAD","field":"show_name","loading":_vm.isFetching},on:{"input":validate,"typing":_vm.searchPartnerEntities,"select":function (option) {if (option) {_vm.partner_entity.id = option.id; _vm.partner_entity.name = option.name; }}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v("\n                            "+_vm._s(props.option.show_name ? props.option.show_name : props.option.name)+"\n                          ")]}}],null,true),model:{value:(_vm.partner_entity.name),callback:function ($$v) {_vm.$set(_vm.partner_entity, "name", $$v)},expression:"partner_entity.name"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_vm._v(" "),(_vm.project.is_postulated)?_c('div',{staticClass:"column is-narrow"},[_c('b-field',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"has-text-centered"},[_c('b-tooltip',{attrs:{"label":"Si la entidad que desea agregar no existe, la puede agregar presionando el botón +","position":"is-bottom","size":"is-medium","multilined":""}},[_c('b-icon',{attrs:{"pack":"fas","icon":"info-circle","type":"is-info"}})],1)],1)]},proxy:true}],null,true)},[_vm._v(" "),_c('b-button',{staticClass:"is-primary is-outlined",on:{"click":function($event){return _vm.addEntity()}}},[_c('b-icon',{attrs:{"icon":"plus"}})],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.associations_types.length>0)?_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Tipo de asociación*"}},[_c('multiselect',{attrs:{"options":_vm.associations_types,"custom-label":function (opt) { return opt.name; },"placeholder":"Seleccione tipo de asociación","selectLabel":"Presione para seleccionar","selectedLabel":"Seleccionado","deselectLabel":"No se puede deseleccionar","allow-empty":false},scopedSlots:_vm._u([{key:"noOptions",fn:function(){return [_vm._v("\n                            No existen datos\n                          ")]},proxy:true}],null,true),model:{value:(_vm.partner_entity.association_type),callback:function ($$v) {_vm.$set(_vm.partner_entity, "association_type", $$v)},expression:"partner_entity.association_type"}},[_vm._v(" "),_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("\n                            No se encontraron elementos.\n                        ")])])],1),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":{max: 255}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Nombre de contacto"}},[_c('b-input',{model:{value:(_vm.partner_entity.contact_name),callback:function ($$v) {_vm.$set(_vm.partner_entity, "contact_name", $$v)},expression:"partner_entity.contact_name"}})],1),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Telefono de contacto"}},[_c('ValidationProvider',{attrs:{"rules":"integer|positive|max:12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{model:{value:(_vm.partner_entity.contact_phone),callback:function ($$v) {_vm.$set(_vm.partner_entity, "contact_phone", $$v)},expression:"partner_entity.contact_phone"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_vm._v(" "),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Correo de contacto"}},[_c('ValidationProvider',{attrs:{"rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{model:{value:(_vm.partner_entity.contact_email),callback:function ($$v) {_vm.$set(_vm.partner_entity, "contact_email", $$v)},expression:"partner_entity.contact_email"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"actions has-text-right"},[_c('button',{staticClass:"button is-danger",attrs:{"icon-left":"trash"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('close')}}},[_vm._v("Cancelar")]),_vm._v(" "),_c('button',{staticClass:"button is-primary",attrs:{"type":"submit","icon-left":"plus"},on:{"click":function($event){return _vm.checkValidation()}}},[_vm._v(_vm._s(_vm.saveButtonName))])])])])]}}])})],1)])])]),_vm._v(" "),_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),_vm._v(" "),_c('b-modal',{attrs:{"destroy-on-hide":true,"width":"80%","can-cancel":_vm.canCancel},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('modal_partner_entity_life_record',{attrs:{"query-type":"create","modal-header":"Añadir nueva entidad","all-entity-types":true},on:{"close":props.close,"clicked":_vm.onClickChild}})]}}]),model:{value:(_vm.isAddModalEntityActive),callback:function ($$v) {_vm.isAddModalEntityActive=$$v},expression:"isAddModalEntityActive"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }