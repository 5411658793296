<template>
  <section>
    <div class="container">
      <!--DATA TABLE-->
      <b-table
          :data="person_studies"
          :paginated= true
          per-page="10"
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="product.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field="egress_date"
            label="Fecha obtención"
            width="110"
            sortable
            searchable :custom-search="searchEgressDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.egress_date._isValid ? new Date(props.row.egress_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            field="study.study_type"
            label="Tipo Estudio"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.study!==undefined ? props.row.study.study_type : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="study.name"
            label="Nombre Estudio"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.study!==undefined ? props.row.study.name : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="study.partner_entity"
            label="Institución"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.study.partner_entity!==undefined ? props.row.study.partner_entity : ""}}
          </template>
        </b-table-column>
        <b-table-column
            label="Acciones"
            width="120"
            v-slot="props">
          <b-tooltip
              v-if="props.row.can_show"
              label="Ver información completa del estudio anulado."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="eye"
                type="is-primary"
                @click="execShowModal(props.row.id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_restore"
              label="Restaurar estudio anulado."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="trash-restore"
                type="is-success is-light is-outlined"
                @click="execRestoreModal(props.row.id)"/>
          </b-tooltip>
        </b-table-column>
      </b-table>
      <!--FIN DATA TABLE-->
      <!-- MODALES -->
      <b-modal
          v-model="isShowModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <study :id="selected"
                 :modal-header="'Estudio - '  +person_name"
                 :person_name="person_name"
                 @close="props.close">
          </study>
        </template>
      </b-modal>
      <b-loading :is-full-page="true"
                 v-model="isLoading"
                 :can-cancel="false"/>
    </div>
  </section>
</template>

<script>
import Study from "./study.vue";
import axios from "axios";
import {searchDate, formatDate} from "../../packs/utilities";
import moment from "moment";
export default {
  name: "null_studies",
  props:[
    'personId', 'can_create_study', 'person_name'
  ],
  components:{
    Study,
  },
  data(){
    return {
      selected: null,
      index: null,
      canCancel: ['escape'],
      currentPage: 1,
      isAddModalActive: false,
      isEditModalActive: false,
      isShowModalActive: false,
      isLoading:false,
      person_studies: [],
      isRestoreActive: false
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    initialize(){
      console.log("CAN CREATE STUDY: ", this.can_create_study)
      this.isLoading = true;
      return axios
          .get("/people/"+this.personId+"/null_studies.json")
          .then(res => {
            this.person_studies = res.data
            this.person_studies = this.person_studies.map(study => {
              var newStudy = study
              newStudy["egress_date"] = moment(newStudy["egress_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newStudy;
            });
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })

    },
    execShowModal(id){
      this.selected = id;
      this.isShowModalActive = true;
    },
    execEditModal(id){
      this.selected = id;
      this.isEditModalActive = true;
    },
    // yyyy/mm/dd -> dd/mm/yyyy
    formatDate,
    execRestoreModal(id){
      let vm = this;
      vm.$buefy.dialog.confirm({
        title: 'Restaurar estudio',
        message: '¿Está seguro que desea <b>restaurar</b> el estudio? Esta acción revertirá la anulación.',
        confirmText: 'Restaurar Estudio',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          vm.isRestoreActive = true
          vm.isLoading = true
          axios
              .put("/admin/person_studies/" + id + "/restore_study.json")
              .then(res => {
                vm.$buefy.dialog.alert({
                  message: 'Estudio restaurado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                vm.isLoading = false;
                vm.isRestoreActive = false;
              }).catch(error => {
            console.log(error)
            vm.$buefy.dialog.alert({
              message: '<b>Estudio no pudo ser restaurado</b><br>',
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            vm.isLoading = false;
            vm.isRestoreActive = false;
          })
        }
      })

    },
    searchEgressDate(row, input){
      return searchDate(row.egress_date, input)
    }

  },
  watch:{
    isAddModalActive: function () {
      if (!this.isAddModalActive) {
        this.initialize()
      }
    },
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
    isShowModalActive: function () {
      if (!this.isShowModalActive) {
        this.initialize()
      }
    },
    isRestoreActive: function () {
      if (!this.isRestoreActive){
        this.initialize()
      }
    },

  }
}
</script>

<style scoped>

</style>