<template>
<section>

  <h3 class="is-size-4 has-text-weight-bold has-text-centered">Tabla de Formularios disponibles</h3>
  <br>
  <div class="column">
    <b-table
        :data="forms"
        :paginated=true
        per-page=15
        striped
        hoverable
        :key="keyupdates"
        :current-page="currentPage"
        :card-layout="cardLayout"
        :pagination-simple=false
        pagination-position="bottom"
        default-sort-direction="desc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="last_patent_state_date"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página actual">
      <b-table-column
          field= 'name'
          label= 'Nombres'
          width="25em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.name }}
        </template>
      </b-table-column>
      <b-table-column
          label="Documentación"
          width="10em"
          v-slot="props">
             
          <b-button type="is-info is-light is-outlined"
                      icon-left="file-word"
                      @click="downloadForm(props.row)">
                      Generar
          </b-button>  

      </b-table-column>




      

      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-danger">
              No se encontraron personas contratadas.
            </b-message>
          </div>
        </div>
      </template>
    </b-table>


  </div>



</section>
</template>

<script>
import axios from "axios";
import {searchDate} from "../../packs/utilities";
import moment from "moment/moment";
import { saveAs } from "file-saver";
import { TemplateHandler } from 'easy-template-x';
export default {
  name: "ShowHonorariosTab",
  props:[ 'participants', 'service', 'cargoPrincipal', 'canEdit', 'integrants','userId','serviceTable'],
  components:{},
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      years:[],
      keyupdates: 0,
      year:null,
      contractType: '',
      contractHeader: '',
      member: null,
      canCancel: ['escape'],
      contract: null,
      ownerDocument: null,
      forms: [
      {id: 1, name: "Formulario de apertura de proyecto"},
      ]
    }
  },
  created(){

  },
  methods:{

    downloadForm(row){
      this.downloadWordFormAperturaProyecto();
      //switch (row.id) {
      //  case 1:
      //    this.downloadWordFormAperturaProyecto();
      //  case 2:
      //    this.downloadWordFormAperturaProyecto();
      //  default:
      //    console.log("Selección no soportada");
      //}
    },

    diferenciaMeses(inicio, termino) {

      const diferenciaMilisegundos = termino - inicio;
      const diferenciaMeses = (termino.getFullYear() - inicio.getFullYear()) * 12 + (termino.getMonth() - inicio.getMonth());
      console.log(diferenciaMeses);
      return diferenciaMeses;
    },
    
    async downloadWordFormAperturaProyecto(row) {
      const response = await fetch('/template-formulario-apertura-proyecto-sdt.docx');
      const templateFile = await response.blob();

      var unidad_menor = "";
      var cost_center = "";
      if (this.service.unit != ""){
        unidad_menor = this.service.unit;
        cost_center = this.service.unit_object.cost_center.toString();
      }
      var unidad_mayor = ""
      if (this.service.unit_faculty != ""){
        unidad_mayor = this.service.unit_faculty;
      }
      var code_serv = this.service.code;


      var type_serv = "";
      var is_especial = "";
      var is_cap = "";
      var is_asist = "";

      if(this.service.sdt_service_type != null){
        type_serv = this.service.sdt_service_type;
        if(this.service.sdt_service_type == "Programas Especiales")
        {
          is_especial = "X";
        }
        else if(this.service.sdt_service_type == "Capacitación")
        {
          is_cap = "X";
        }
        else{
          is_asist = "X";
        }

      }

      var nom_serv = this.service.name;
      var obj_serv = "";
      var nom_completo_jefe_serv = "";
      var rut_jefe = "";
      if (this.service.projectManagment != null){
        nom_completo_jefe_serv = this.service.projectManagment.person_name;
        if (this.service.projectManagment.run != null){
          rut_jefe = this.service.projectManagment.run.rut.toLocaleString('es-ES').toString()+"-"+this.service.projectManagment.run.dv;
        }
        
      }

      if (this.service.description != null){
        obj_serv = this.service.description;
      }

      var mes_inicio = "";
      var year = "";
      var start_date = "";
      if (this.service.start_date != null){
        var date_start = new Date(this.service.start_date_2);
        start_date = date_start.getDate()+"/"+(date_start.getMonth()+1)+"/"+date_start.getFullYear()
        year = date_start.getFullYear().toString();
      }



      var end_date = "";
      var duration = "";
      if (this.service.end_date != null){
        var date_end = new Date(this.service.end_date_2);
        end_date = date_end.getDate()+"/"+(date_end.getMonth()+1)+"/"+date_end.getFullYear()
        duration = this.diferenciaMeses(date_start, date_end);
      }


      var client = "";
      if (this.service.partner_entity != null){
        client = this.service.partner_entity;
      }

      var participants = "";
      var name_aux = "";
      var service_person_aux = "";
      if (this.integrants.length != 0){
        this.integrants.forEach((persona, index) => {
          service_person_aux = "";
          name_aux = "";
          if (persona.name != null){
            name_aux = persona.name;
          }else{
            name_aux = persona.person_name;
          }

          if (persona.contract.service != null){
            service_person_aux = persona.contract.service;
          }
          participants = participants+" "+name_aux+": "+service_person_aux+", ";
        });
      }



      
      var nom_supervisor = ""
      this.service.participants.forEach((persona, index) => {
        if(persona.participant_type == "SUPERVISOR(A)"){
          nom_supervisor = persona.name
        }
      });
        






      //Se procesa la plantilla
      const data = {
        unit_mayor: unidad_mayor,
        unit_menor: unidad_menor,
        cost_center: cost_center,
        cod_serv: code_serv,
        year: year,
        type_serv: type_serv,
        nom_serv: nom_serv,
        obj_serv: obj_serv,
        is_especial : is_especial,
        is_cap: is_cap,
        is_asist: is_asist,
        start_date: start_date,
        end_date: end_date,
        duration: duration,
        client: client,
        participants: participants,
        nom_jefe: nom_completo_jefe_serv,
        rut_jefe: rut_jefe,
        nom_supervisor: nom_supervisor
      };
      console.log(data);

      //Se procesa el documento para generar el archivo
      const handler = new TemplateHandler();
      const doc = await handler.process(templateFile, data);

      //Se genera el word para guardar
      saveAs(doc, "formulario-apertura-proyecto.docx");
      this.isLoading = false
    },
  }

}
</script>
<style scoped>
.icon-button-container {
  display: flex;
  align-items: center;
}

.custom-icon-button {
  padding: 0; /* Elimina el padding del botón */
  border: none; /* Elimina el borde del botón */
}

.custom-icon {
  margin: 0 5px; /* Ajusta el espaciado entre los iconos según tus preferencias */
}

.with-border {
  border: 1px solid black; /* Añade un borde negro al botón */
}
.with-border:hover {
  border: 1px solid black; /* Mantén el borde negro al pasar el mouse sobre el botón */
}


</style>