<template>
  <div>
    <div class="container" style="border-radius: 15px">
      <div class="columns">
        <div class="column">
          <div class="title is-5">
            Experiencia Docente
            <hr class="dropdown-divider" aria-role="menuitem">
          </div>
          <div class="columns">
            <div class="column">
                    <span class="is-pulled-right">
                        <button class="button is-primary" @click="newWorkExperience()">
                            <b-icon icon="plus"></b-icon>
                            <span>Agregar Experiencia Docente Externa</span>
                        </button>
                    </span>
            </div>
          </div>
        </div>
      </div>
      <b-table
          :data="work_experiences"
          :paginated=true
          per-page=10
          :current-page="currentPage"
          :card-layout="cardLayout"
          :pagination-simple=false
          pagination-position="bottom"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field= 'subject_name'
            label= 'Asignatura'
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.subject_name }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'career_name'
            label= 'Carrera, programa o curso'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            <div v-if="props.row.career_name != null">{{ props.row.career_name }}</div>
            <div v-else></div>
          </template>
        </b-table-column>
        <b-table-column
            field= 'partner_entity_name'
            label= 'Institución'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            <div v-if="props.row.partner_entity_name != null">{{ props.row.partner_entity_name}}</div>
            <div v-else></div>
          </template>
        </b-table-column>
        <b-table-column
            label="Acciones"
            v-slot="props">
          <b-icon
              pack="fas"
              icon="eye"
              size="is-medium"
              @click.native="showModal(props.row)"/>
          <b-icon
              pack="fas"
              icon="edit"
              size="is-medium"
              @click.native="editModal(props.row)"/>
        </b-table-column>
        <template slot="empty" slot-scope="props">
          <div class="columns is-centered">
            <div class="column is-6 is-centered has-text-centered">
              <!--              <b-message v-if="can_edit" has-icon type="is-warning">
                              <h1> No se encontraron resultados, haga click <a @click="newParticipant()">aquí</a> para relacionar entidades.</h1>
                            </b-message>-->
              <b-message has-icon type="is-warning">
                <h1> No se encontraron resultados.</h1>
              </b-message>
            </div>
          </div>
        </template>
      </b-table>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="isModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        width="90%"
        aria-role="dialog"
        aria-modal
        :can-cancel="false">
      <template #default="props">
        <modal_new_work_experience
            :person-id="personId"
            :update="update"
            :old_work_experience="old_work_experience"
            :teaching="true"
            @close="props.close"></modal_new_work_experience>
      </template>
    </b-modal>
    <b-modal
        v-model="isModalActiveShow"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        width="90%"
        aria-role="dialog"
        aria-modal
        :can-cancel="false">
      <template #default="props">
        <modal_show_work_experience
            :person-id="personId"
            :old_work_experience="old_work_experience"
            :teaching="true"
            @close="props.close"></modal_show_work_experience>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import modal_new_work_experience from "./modal_new_work_experience";
import modal_show_work_experience from "./modal_show_work_experience";
import {searchDate} from "../../packs/utilities";
import moment from "moment";

export default {
  name: "teaching_tab",
  props:['personId'],
  components:{modal_show_work_experience,modal_new_work_experience},
  data(){
    return{
      isLoading: false,
      work_experiences: [],
      old_work_experience: {},
      isModalActive: false,
      isModalActiveShow: false,
      update:false,
      currentPage: 1,
      cardLayout: false,
    }
  },
  created() {
    this.getWorkExperiences()
  },
  methods:{
    getWorkExperiences(){
      this.isLoading = true
      this.update = false
      this.old_work_experience = {}
      axios
          .get('/my-teaching-experiences/'+this.personId+'.json')
          .then(response =>{
            this.work_experiences = response.data
            this.isLoading = false
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false
          })
    },
    newWorkExperience(){
      this.update = false
      this.isModalActive = true
    },
    showModal(dato){
      this.old_work_experience = dato
      this.isModalActiveShow = true
    },
    editModal(dato){
      this.update = true
      this.old_work_experience = dato
      this.isModalActive = true
    },
  },
  watch: {
    isModalActive: function () {
      if (!this.isModalActive) {
        this.getWorkExperiences()
      }
    },
    isModalActiveShow: function () {
      if (!this.isModalActiveShow) {
        this.getWorkExperiences()
      }
    },
  }
}
</script>

<style scoped>

</style>