<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
        <b-message type="is-danger" has-icon>
          <div v-for="(item,index) in errores_validacion" :key='index'>
            {{ item[0] }}
          </div>
        </b-message>
      </div>
      <form @submit.prevent="handleSubmit(verify_names)">
        <section class="modal-card-body" ref="modalPersonForm">
          <div class="columns is-centered">
            <div class="column">
              <div class="columns">
                <div class="column">
                  <b-field :label="getNameRequired('CORTO') ? 'Nombre Corto*' :'Nombre Corto'">
                    <ValidationProvider :rules="!getNameRequired('CORTO') ? '' :'required'" v-slot="{ errors }">
                      <b-input type="text" maxlength="255"
                               placeholder="Ingrese el nombre de la entidad"
                               :value="entityName!==undefined ? entityName : ''"
                               v-model="entityName"
                               :has-counter=false>
                      </b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column">
                  <b-field :label="getNameRequired('COMPLETO') ? 'Nombre Completo*' :'Nombre Completo'">
                    <ValidationProvider :rules="!getNameRequired('COMPLETO') ? '' :'required'" v-slot="{ errors }">
                      <b-input type="text" maxlength="255"
                               placeholder="Ingrese el nombre completo de la entidad"
                               :value="entityFullName!==undefined ? entityFullName : ''"
                               v-model="entityFullName"
                               :has-counter=false>
                      </b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column is-3">
                  <b-field label="Rut">
                    <b-input placeholder="ej: 12345678" type="number" min="0" :value="entityRut" v-model="entityRut"></b-input>
                  </b-field>
                </div>
                <div class="column is-1">
                  <b-field label="DV">
                    <b-input type="text" maxlength="1" :value="entityDv" v-model="entityDv"></b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Descripción*">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-input type="textarea" maxlength="4000"
                               placeholder="Ingrese la descripción de la entidad"
                               :value="entityDescription!==undefined ? entityDescription : ''"
                               v-model="entityDescription">
                      </b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Email">
                    <ValidationProvider rules="email" v-slot="{ errors }">
                      <b-input  type="text"
                                maxlength="255"
                                placeholder="Ingrese el email de la entidad"
                                :value="entityEmail!==undefined ? entityEmail : ''"
                                v-model="entityEmail"
                                :has-counter=false>
                      </b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Teléfono">
                    <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                      <b-input
                          placeholder="Ingrese un número de teléfono"
                          :value="entityPhone!==undefined ? entityPhone : null"
                          v-model="entityPhone">
                      </b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="País*">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <b-autocomplete
                          :value="entityNationality!==undefined ? entityNationality : ''"
                          v-model="entityNationality"
                          placeholder="e.g. Chile"
                          :keep-first="keepFirst"
                          :open-on-focus="openOnFocus"
                          :data="filteredDataObjNationality"
                          field="name"
                          @select="option => (selected = option)"
                          :clearable="clearable">
                      </b-autocomplete>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Ciudad">
                    <ValidationProvider v-slot="{errors}">
                      <b-input type="text"
                               maxlength="255"
                               placeholder="Ingrese la ciudad de la entidad"
                               :value="entityCity!==undefined ? entityCity : ''"
                               v-model="entityCity"
                               :has-counter=false>
                      </b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Dirección">
                    <ValidationProvider v-slot="{errors}">
                      <b-input type="text"
                               maxlength="255"
                               placeholder="Ingrese la dirección de la entidad"
                               :value="entityDirection!==undefined ? entityDirection : ''"
                               v-model="entityDirection"
                               :has-counter=false>
                      </b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>
              <div class="columns" v-if="isVisibleByNationality">
                <div class="column">
                  <b-field label="Región*" >
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <b-autocomplete
                          :value="entityRegion!==undefined ? entityRegion : ''"
                          v-model="entityRegion"
                          placeholder="e.g. Metropolitana"
                          :keep-first="keepFirst"
                          :open-on-focus="openOnFocus"
                          :data="filteredDataObjRegion"
                          field="name"
                          @input="loadCommunes(getRegionIdByRegionName(entityRegion))"
                          @select="option => (selected = option)"
                          :clearable="clearable">
                      </b-autocomplete>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Comuna*">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <b-autocomplete
                          :value="entityCommune!==undefined ? entityCommune : ''"
                          v-model="entityCommune"
                          placeholder="e.g. Providencia"
                          :keep-first="keepFirst"
                          :open-on-focus="openOnFocus"
                          :data="filteredDataObjComune"
                          field="name"
                          @select="option => (selected = option)"
                          :clearable="clearable">
                      </b-autocomplete>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Rubro económico">
                    <b-autocomplete
                        :value="entityEconomicActivity!==undefined ? entityEconomicActivity : ''"
                        v-model="entityEconomicActivity"
                        placeholder="e.g. Cultivo de maíz"
                        :keep-first="keepFirst"
                        :open-on-focus="openOnFocus"
                        :data="filteredDataObj"
                        field="name"
                        @select="option => (selected = option)"
                        :clearable="clearable">
                    </b-autocomplete>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Código postal">
                    <ValidationProvider v-slot="{ errors }">
                      <b-input
                          placeholder="Ingrese código postal"
                          maxlength="9"
                          :has-counter=false
                          :value="entityPostalCode!==undefined ? entityPostalCode : null"
                          v-model="entityPostalCode">
                      </b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Link">
                      <b-input
                          placeholder="https://link.com"
                          maxlength="255"
                          :has-counter=false
                          :value="entityLink!==undefined ? entityLink : null"
                          v-model="entityLink">
                      </b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Tipo de entidad*">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <multiselect
                          v-model="entityType"
                          :options="entity_types_data.map(type => type.id)"
                          :custom-label="opt => entity_types_data.find(x => x.id === opt).name"
                          placeholder="Seleccione un tipo de entidad"
                          selectLabel="Presione para seleccionar"
                          selectedLabel="Seleccionado"
                          deselectLabel=""
                          :allow-empty="false">
                      </multiselect>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="¿Es entidad pública?">
                    <div class="columns">
                      <div class="column">
                        <b-radio-button v-model="entityIsPublic"
                                        :native-value="false"
                                        type="is-danger is-light is-outlined"

                        >
                          <b-icon icon="times-circle"></b-icon>
                          <span>No</span>
                        </b-radio-button>
                      </div>
                      <div class="column">
                        <b-radio-button v-model="entityIsPublic"
                                        :native-value="true"
                                        type="is-success is-light is-outlined"
                        >
                          <b-icon icon="check-circle"></b-icon>
                          <span>Si</span>
                        </b-radio-button>
                      </div>
                    </div>

                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="¿Es Sub Entidad?">
                    <div class="columns">
                      <div class="column">
                        <b-radio-button v-model="sub_entity"
                                        :native-value="false"
                                        type="is-danger is-light is-outlined"

                        >
                          <b-icon icon="times-circle"></b-icon>
                          <span>No</span>
                        </b-radio-button>
                      </div>
                      <div class="column">
                        <b-radio-button v-model="sub_entity"
                                        :native-value="true"
                                        type="is-success is-light is-outlined"
                        >
                          <b-icon icon="check-circle"></b-icon>
                          <span>Si</span>
                        </b-radio-button>
                      </div>
                    </div>
                  </b-field>
                </div>
                <div class="column">
                  <b-field v-if="sub_entity">
                    <template #label>
                      Pertenece a la entidad (ingrese por lo menos 3 letras)
                      <b-tooltip
                          label="Se recomienda búsqueda por sigla, ejemplo: ANID, no Agencia Nacional... mientras que para instituciones educacionales, se recomienda por nombre completo, ejemplo: universidad de santiago de chile"
                          position="is-right"
                          size="is-medium"
                          multilined>

                        <b-icon
                            pack="fas"
                            icon="info-circle"
                            size="is-small"
                            type="is-info">
                        </b-icon>
                      </b-tooltip>
                    </template>
                    <ValidationProvider :rules="sub_entity ? 'required':''" v-slot="{ validate, errors }">
                      <b-autocomplete
                          @input="validate"
                          :data="partner_entities"
                          placeholder="ej ANID o UNIVERSIDAD DE SANTIAGO DE CHILE  o NOMBRE EMPRESA - ENTIDAD"
                          field="show_name"
                          :loading="isFetching"
                          @typing="searchPartnerEntities"
                          @select="option => {if (option) {partner_entity.id = option.id} }"
                          v-model="partner_entity.name">
                        <template slot-scope="props">
                          {{ props.option.show_name ? props.option.show_name : props.option.name}}
                        </template>
                      </b-autocomplete>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>

                  </b-field>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="is-pulled-left">
                <button class="button is-link is-light" @click="back()">Volver</button>
              </div>
            </div>
            <div class="column">
              <div class="is-pulled-right">
                <b-button class="button is-primary" :disabled="disabled" @click="verify_names">Guardar Entidad</b-button>
              </div>
            </div>
          </div>
        </section>
      </form>
    </ValidationObserver>
    <div class="partnerentitiesvimenewform">
        <div class="container">

       </div>
       <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
  </section>
</template>
<script>
import axios from 'axios'
export default {
    name: 'PartnerEntitiesVimeNewForm',
  props:{
        edit_case:{
            type: Boolean,
            default: false
        },
        entity_id:{
            type: Number
        }
    },
    data(){
        return {
            disabled: false,
            errores_validacion: {},
            // variables controladoras
            isLoading:true,
            isFullPage: true,
            isSubmiting: false,
            keepFirst: false,
            openOnFocus: false,
            selected: null,
            clearable: false,

            // Para almacenamiento temporal
            nationalities_data: [],
            regions_data: [],
            communes_data: [],
            economic_activities_data: [],
            entity_types_data: [],
            nationality_name: '',
            region_name: '',
            commune_name: '',
            economic_activity_name: '',
            entity_type_name: null,
            entityFullName: "",
            // para el envío de datos
            entityInfo:{
                name: '',
                description: '',
                email: '',
                phone: null,
                established: null,
                direction: '',
                city: '',
                is_public: false,
                nationality_id: null,
                region_id: null,
                commune_id: null,
                economic_activity_id: null,
                entity_type_id: null,
                postal_code: null,
                url: null,
                run_attributes:{
                    rut: null, 
                    dv: null
                },
            },
          sub_entity: false,
          partner_entities:[],
          isFetching:false,
          partner_entity:{}
        }
    },
    computed:{
        // Filtros y visibilidad
        filteredDataObj() {
          if(this.economic_activity_name!=null && this.economic_activities_data && this.economic_activities_data.length>0){
            return this.economic_activities_data.filter(option => option.name.toLowerCase().includes(this.economic_activity_name.toLowerCase()))
          }
        },
        filteredDataObjComune() {
          if(this.commune_name == null){
            this.commune_name = ''
          }
            return this.communes_data.filter(option => option.name.toLowerCase().includes(this.commune_name.toLowerCase()))
        },
        filteredDataObjRegion() {
          if(this.region_name == null){
            this.region_name = ""
          }
            return this.regions_data.filter(option => option.name.toLowerCase().includes(this.region_name.toLowerCase()))
        },
        filteredDataObjNationality() {
            return this.nationalities_data.filter(option => option.name.toLowerCase().includes(this.nationality_name.toLowerCase()))
        },
        isVisibleByNationality(){
            return this.nationality_name.toUpperCase() === 'CHILE'
        },

        // Para el control de los atributos
        entityName:{
            get: function(){
                return this.entityInfo.name
            },
            set: function(newName){
                this.entityInfo.name = newName
            }
        },
        entityRut:{
            get: function(){
                return this.entityInfo.run_attributes.rut
            },
            set: function(newRut){
                this.entityInfo.run_attributes.rut = newRut
            }
        },
        entityDv:{
            get: function(){
                return this.entityInfo.run_attributes.dv
            },
            set: function(newDv){
                this.entityInfo.run_attributes.dv = newDv
            }
        },
        entityDescription:{
            get: function(){
                return this.entityInfo.description
            },
            set: function(newDescription){
                this.entityInfo.description = newDescription
            }
        },
        entityEmail:{
            get: function(){
                return this.entityInfo.email
            },
            set: function(newEmail){
                this.entityInfo.email = newEmail
            }
        },
        entityPhone:{
            get: function(){
                return this.entityInfo.phone
            },
            set: function(newPhone){
              if (newPhone){
                this.entityInfo.phone = parseInt(newPhone, 10)
              }

            }
        },
        entityPostalCode:{
          get: function(){
            return this.entityInfo.postal_code
          },
          set: function(newCode){
            if (newCode){
              this.entityInfo.postal_code = newCode
            }

          }
        },
        entityLink:{
          get: function(){
            return this.entityInfo.url
          },
          set: function(newLink){
            if (newLink){
              this.entityInfo.url = newLink
            }

          }
        },
        entityDirection:{
            get: function(){
                return this.entityInfo.direction
            },
            set: function(newDirection){
                this.entityInfo.direction = newDirection
            }
        },
        entityCity:{
            get: function(){
                return this.entityInfo.city
            },
            set: function(newCity){
                this.entityInfo.city = newCity
            }
        },
        entityNationality:{
            get: function(){
                return this.nationality_name
            },
            set: function(newNationality){
              if (newNationality != null  || newNationality != undefined || newNationality != ""){
                if(newNationality.toUpperCase() !== 'CHILE'){
                  this.region_name = ""
                  this.commune_name = ""
                  this.economic_activity_name = ""
                }
                this.nationality_name = newNationality
              }
              else{
                this.region_name = ""
                this.commune_name = ""
                this.economic_activity_name = ""
                this.nationality_name = ""
              }
            }
        },
        entityRegion:{
            get: function(){
                return this.region_name
            },
            set: function(newRegion){
                this.region_name = newRegion
            }
        },
        entityCommune:{
            get: function(){
                return this.commune_name
            },
            set: function(newCommune){
                this.commune_name = newCommune
            }
        },
        entityEconomicActivity:{
            get: function(){
                return this.economic_activity_name
            },
            set: function(newEconomicActivity){
                this.economic_activity_name = newEconomicActivity
            }
        },
        entityType:{
            get: function(){
                return this.entity_type_name
            },
            set: function(newEntityType){
                this.entity_type_name = newEntityType
            }
        },
        entityIsPublic:{
            get: function(){
                return this.entityInfo.is_public
            },
            set: function(newEntityIsPublic){
                this.entityInfo.is_public = newEntityIsPublic
            }
        },
    },
    beforeMount(){
        // Se ejecutarán antes de renderizar la página
        this.getNationalities()

    },
    methods:{
        // Consultas al backend
        getEntity(){
            axios.get('/partner_entities/show/'+this.entity_id+'.json')
            .then( response =>{
                this.isLoading = true
                this.entityName = response.data.entity.alt_name ? response.data.entity.alt_name : response.data.entity.name;
                this.entityFullName = response.data.entity.alt_full_name ? response.data.entity.alt_full_name : response.data.entity.full_name;

                if(this.entityName == null){
                  this.entityName = ''
                }
                if(this.entityFullName == null){
                  this.entityFullName = ''
                }
                this.entityDescription = response.data.entity.description
                this.entityEmail = response.data.entity.email
                this.entityPhone = response.data.entity.phone
                this.entityDirection = response.data.entity.direction
                this.entityCity = response.data.entity.city
                if (response.data.entity.entity_nationality != null){
                  this.entityNationality = response.data.entity.entity_nationality
                }
                this.entityRegion = response.data.entity.entity_region
                this.entityCommune = response.data.entity.entity_commune
                this.entityEconomicActivity = response.data.entity.entity_economic_activity
                this.entityType = response.data.entity.entity_type_id
                this.entityIsPublic = response.data.entity.is_public
                this.entityLink = response.data.entity.url
                this.entityPostalCode = response.data.entity.postal_code
                this.entityRut = (response.data.entity.rut == null) ? null : response.data.entity.rut.rut
                this.entityDv = (response.data.entity.rut == null) ? null : response.data.entity.rut.dv
                if (this.entityRegion != null)
                {
                  this.loadCommunes(this.getRegionIdByRegionName(this.entityRegion))
                }
                if (response.data.entity.entity_mayor != null){
                  this.searchPartnerEntities(response.data.entity.entity_mayor.name)
                  this.partner_entity = response.data.entity.entity_mayor
                  this.sub_entity = true
                  this.isLoading=false
                }
                else{
                  this.sub_entity = false
                  this.isLoading=false
                }


            })
            .catch(e =>{
                console.log(e)
                this.isLoading=false
            })
        },
        getNationalities(){
            axios.get('/nationalities.json')
            .then( response =>{
                this.isLoading = true
                this.nationalities_data = response.data
                this.getRegions()

            })
            .catch(e => { 
                console.log(e)
                this.isLoading =false
            })
        },
        getRegions(){
            axios.get("/admin/regions.json")
            .then(response =>{
                this.regions_data = response.data
                this.getEconomicActivities()
            })
            .catch(e =>{
                console.log(e)
                this.isLoading = false
            })
        },
        /*getCommunes(){
            axios.get("/admin/communes.json")
            .then(response =>{
                this.communes_data = response.data
            })
            .catch(e =>{
                console.log(e)
            })
        },*/
        getEconomicActivities(){
            axios.get("/economic_activities.json")
            .then(response =>{
                this.economic_activities_data = response.data
                this.getEntityTypes()
            })
            .catch(e =>{
                console.log(e)
                this.isLoading = false
            })
        },
        getEntityTypes(){
            axios.get("/admin/entity_types.json")
            .then(response =>{
                this.entity_types_data = response.data
                if(this.edit_case){
                  this.getEntity()
                }
                else{
                  this.isLoading = false
                }
            })
            .catch(e =>{
                console.log(e)
                this.isLoading = false
            })
        },
        // FUnciones transformadoras de Nombre a Id y viceversa
        getNationalityIdByNationalityName(nationality_name){
            var id = 0
            this.nationalities_data.forEach(function(element){
                if (element.name === nationality_name){
                    id = element.id
                }
            })
            return id
        },
        getRegionIdByRegionName(region_name){
            var id = null
            this.regions_data.forEach(function(element){
                if (element.name === region_name){
                    id = element.id
                }
            })
            return id
        },
        getCommuneIdByCommuneName(commune_name){
            var id = null
            this.communes_data.forEach(function(element){
                if (element.name === commune_name){
                    id = element.id
                }
            })
            return id
        },
        getEconomicActvityIdByEconomicActivityName(economic_activity_name){
            var id = null
            this.economic_activities_data.forEach(function(element){
                if (element.name === economic_activity_name){
                    id = element.id
                }
            })
            return id
        },
        getEntityTypeIdByEntityTypeName(entity_type_name){
            var id = null
            this.entity_types_data.forEach(function(element){
                if (element.name === entity_type_name){
                    id = element.id
                }
            })
            return id
        },
        back(){
            this.isLoading=true
            window.location.href="/partner_entities/index"
        },
      //verificacion de nombres
      verify_names(){
        this.isLoading= true;
        var queryType
        if (this.edit_case){
          queryType = "update"
        }
        else{
          queryType = "create"
        }

        if ((this.entityFullName == null || this.entityFullName == '') && (this.entityName == null || this.entityName == '')){
          this.isLoading = false
          this.disabled = false
          return this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Por favor, ingrese al menos uno de estos dos campos: Nombre Corto o Nombre Completo',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        }
        else{
          axios
              .get("/partner_entities/find_name_coincidence.json", {
                params: {name: this.entityName, full_name: this.entityFullName,
                  id: this.entity_id, queryType: queryType}
              })
              .then(response =>{
                this.save_entity()
                this.isLoading=false;
              }).catch(error => {
            if(error.response.status === 409){ //se encuentra
              this.isLoading = false
              this.$buefy.dialog.confirm({
                hasIcon: true,
                type: "is-warning",
                title: "Advertencia",
                message: error.response.data["message"],
                confirmText: "Continuar y guardar",
                cancelText: "Cancelar",
                onConfirm: () => this.save_entity(),
                onCancel: () => this.isLoading = false
              })
            }
            else if(error.response.status === 400){//bad request
              this.errores_validacion = error.response.data
              var elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              this.$refs.modalPersonForm.scrollTop = 0;
              this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Hubo errores al agregar la entidad, favor revisar formato',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              this.isLoading=false;
            }
          })
        }
      },
      // Envío de datos al backend
        async save_entity(){
          var save_case
          if (this.edit_case){
            save_case = 1
          }
          else{
            save_case = 0
          }
          this.isLoading = true
          this.disabled = true
          const isValid = await this.$refs.observer.validate();
          if (!isValid){
            this.isLoading = false
            this.disabled = false
            return this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })


          }
          if (this.getNationalityIdByNationalityName(this.nationality_name) == 0){
            this.isLoading = false
            this.disabled = false
            return this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Se encontraron errores en campo País, por favor revisar que exista',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          }

          if (this.getRegionIdByRegionName(this.entityRegion) == null && this.isVisibleByNationality){
            this.isLoading = false
            this.disabled = false
            return this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Se encontraron errores en campo Region, por favor revisar que exista',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          }

          if (this.getCommuneIdByCommuneName(this.entityCommune) == null && this.isVisibleByNationality){
            this.isLoading = false
            this.disabled = false
            return this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Se encontraron errores en campo Comuna, por favor revisar que exista',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          }

          this.entityInfo.nationality_id = this.getNationalityIdByNationalityName(this.nationality_name)
          if(this.nationality_name.toUpperCase() === 'CHILE'){
            this.entityInfo.region_id = this.getRegionIdByRegionName(this.region_name)
            this.entityInfo.commune_id = this.getCommuneIdByCommuneName(this.commune_name)
            this.entityInfo.economic_activity_id = this.getEconomicActvityIdByEconomicActivityName(this.economic_activity_name)
          }
          else{
            this.entityInfo.region_id = null
            this.entityInfo.commune_id = null
            this.entityInfo.economic_activity_id = null
          }

          if(this.entityInfo.run_attributes.rut == ""  || this.entityInfo.run_attributes.dv == "")
          {
            this.entityInfo.run_attributes.rut = null
            this.entityInfo.run_attributes.dv = null
          }

          this.entityInfo.entity_type_id = this.entity_type_name
          this.entityInfo.full_name = this.entityFullName;
          if ((this.entityInfo.full_name == null || this.entityInfo.full_name == '') && (this.entityInfo.name == null || this.entityInfo.name == '')){
            this.isLoading = false
            this.disabled = false
            return this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Por favor, ingrese al menos uno de estos dos campos: Nombre Corto o Nombre Completo',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          }
          if(save_case == 0){
            this.isLoading=true
            this.disabled = true

            if(this.partner_entity.name != null){
              if(this.partner_entity.name.length > 0){
                this.entityInfo.partner_entity_id = this.partner_entity.id
              }
              else{
                this.entityInfo.partner_entity_id = null
              }
            }
            else{
              this.entityInfo.partner_entity_id = null
            }


            axios.post('/admin/partner_entities.json', this.entityInfo)
                .then( response =>{
                  this.errores_validacion = {};
                  window.location.href="/partner_entities/show/"+response.data.id
                })
                .catch(e => {
                  this.isLoading = false
                  this.disabled = false
                  //console.log(e)
                  this.errores_validacion = e.response.data;
                  this.$buefy.dialog.alert({
                    message: "Faltan algunos campos o presentan errores de formato. Por favor revisar",
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    onConfirm: () => {
                    }
                  })
                })

          }
          else{
            this.isLoading=true
            this.disabled = true
            if(this.partner_entity.name != null){
              if(this.partner_entity.name.length > 0){
                this.entityInfo.partner_entity_id = this.partner_entity.id
              }
              else{
                this.entityInfo.partner_entity_id = null
              }
            }
            else{
              this.entityInfo.partner_entity_id = null
            }
            axios.put('/admin/partner_entities/'+this.entity_id+'.json', this.entityInfo)
                .then( response =>{
                  this.errores_validacion = {};
                  window.location.href="/partner_entities/show/"+response.data.id

                })
                .catch(e => {
                  this.isLoading = false
                  this.disabled = false
                  this.errores_validacion = e.response.data;
                  this.$buefy.dialog.alert({
                    message: "Faltan algunos campos o presentan errores de formato. Por favor revisar",
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    onConfirm: () => {
                    }
                  })
                })

          }


        },
      loadCommunes(region_id){
        if( region_id!==null && region_id!==undefined && region_id!==""){
          this.isLoading=true;
          this.disabled = true
          axios
              .get("/regions/"+region_id+"/communes.json")
              .then(res => {
                this.communes_data = res.data["communes"];
                this.isLoading = false;
                this.disabled = false
              })
              .catch(error => {
                console.log(error)
                this.disabled = false
                this.isLoading=false;
              })
        }
        else{
          this.communes = []
        }
      },

      getNameRequired(type){
          let retorno = true;
          if(type=="CORTO"){
            if(this.entityFullName != null && this.entityFullName.length>0){
              retorno = false;
            }
          }
          if(type=="COMPLETO"){
            if(this.entityName !=  null && this.entityName.length>0){
              retorno = false;
            }
          }
          if(this.entityName !=  null && this.entityFullName != null && this.entityFullName.length>0 && this.entityName.length>0){
            retorno = true;
          }
          return retorno;
      },

      searchPartnerEntities(name){
        if (name.length<3) {
          this.partner_entities = [];
          return
        }
        let vm = this;
        vm.isLoading = true;
        axios
            .get("/admin/partner_entities/financings-search.json", { params: { name: name } })
            .then(res => {
              vm.partner_entities = res.data;
              vm.isLoading = false;
            })
            .catch(error => {
              console.log(error)
              vm.isLoading = false;
            })

      },
    },
  watch:{
    sub_entity: function(){
      if (this.sub_entity == false){
        this.partner_entity = {}
      }
    }
  }

    // Instrucciones para hacer a rubro económico obligatorio
    /*
    1.- Pegar estas lineas al lado de la etiqueta b-field:
        :type="{ 'is-danger': checkEntityEconomicActivity }"
        :message="{ 'Seleccione un rubro económico.': checkEntityEconomicActivity }"

    2.- Descomentar las líneas (1) y (2)
    */
}
</script>