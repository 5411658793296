<template>
  <section>
    <div class="box">
      <!-- Box de Formulario household -->
      <div class="title is-4">
        Información de Asignación Familiar
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Rut">
            <b-input :value="household_allowance.rut" type="text" readonly></b-input>
          </b-field>
        </div>
        <div class="column is-three-fifths">
          <b-field label="Nombres">
            <b-input :value="household_allowance.nombre_completo" type="text" readonly></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Tipo de Carga">
            <b-input :value="(household_allowance.dependent_type!==null &&household_allowance.dependent_type!==undefined) ? household_allowance.dependent_type.name : ''" type="text" readonly></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Parentesco">
            <b-input :value="household_allowance.relationship" type="text" readonly></b-input>
          </b-field>
        </div>

      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Fecha de inicio">
            <b-input :value="formatDate(household_allowance.start_date)" type="text" readonly></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Fecha de término">
            <b-input :value="formatDate(household_allowance.expiration_date)" type="text" readonly></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Fecha de nacimiento">
            <b-input :value="formatDate(household_allowance.birthday)" type="text" readonly></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Resolución">
            <b-input :value="household_allowance.decree" type="text" readonly></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Fecha de Resolución">
            <b-input :value="formatDate(household_allowance.decree_date)" type="text" readonly></b-input>
          </b-field>
        </div>
      </div>
<!--      <div class="columns">
        <div class="column">
          <b-field label="Url Documentación">
            <b-input expanded readonly v-model="household_allowance.documentation_link" type="text"></b-input>
            <p class="control">
              <b-button tag="a" :href="household_allowance.documentation_link" target="_blank" icon-left="external-link-alt" type="is-link">Link</b-button>
            </p>
          </b-field>
        </div>
      </div>-->
      <div class="columns">
        <div class="column">
          <b-field label="Observaciones">
            <b-input :value="household_allowance.observations" type="textarea" readonly></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Documento asociado a la función">
            <b-input expanded readonly v-model="household_allowance.document_name" type="text"></b-input>
            <p v-if="household_allowance.can_view_pdf" class="control">
              <b-button  @click="viewFile(household_allowance.document_id)" icon-left="file-alt" type="is-link">Visualizar</b-button>
            </p>
          </b-field>
        </div>
      </div>
      <b-modal
          v-model="showPdf"
          :can-cancel="true"
          :destroy-on-hide="true"
          aria-modal
          aria-role="dialog"
          has-modal-card
          trap-focus
          :width="2000"
      >
        <template #default="props">
          <pdf_view
              :src="src"
              @close="props.close"
          ></pdf_view>
        </template>
      </b-modal>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
  </section>
</template>

<script>
import {formatDate} from "../../../packs/utilities"
import Pdf_view from "../../performances/pdf_view.vue";
import axios from "axios";
export default {
  name: "household_in_distance",
  components: {Pdf_view},
  props: [
    'person_name', 'household'
  ],
  data(){
    return {
      household_allowance: {name: "", observations: "", dependant: "", decree: "",
        decree_date: "", start_date: "", last_name: "", second_surname: "", run: "", documentation_link: "",
      expiration_date: "", dependent_type: "", birthday: "", relationship: "", nombre_completo: ""},
      isLoading: false,
      showPdf: false,
      src:null,
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize(){
      this.isLoading=true;
      this.household_allowance = this.household
      this.isLoading=false;
    },
    formatDate,
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            this.showPdf = true
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },

  },
}
</script>