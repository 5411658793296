<template>
  <section>
      <div class="has-text-right mb-1">
        <b-button class="is-primary is-outlined"
                  v-if="can_create_appointment==='true'"
                  icon-left="plus"
                  @click="isAddModalActive = true">
          Añadir Nombramiento
        </b-button>
      </div>
      <div class="has-text-right mb-1">
        <b-button class="is-primary is-outlined"
                  v-if="can_request_appointment==='true'"
                  icon-left="plus"
                  @click="isRequestModalActive = true">
          Solicitar Nombramiento
        </b-button>
      </div>
      <b-collapse :open="dateActive" aria-id="collapseDate">
        <div class="columns is-centered">
          <div class="column is-3">
            <b-field label="Fecha">
              <b-datepicker
                  v-model="date"
                  icon="calendar-alt"
                  trap-focus
                  locale="es-ES"
                  editable
                  @input="updateAppointments"
                  placeholder="Formato de Fecha: dd/mm/aaaa"
                  size="is-small"
              >
              </b-datepicker>
            </b-field>
          </div>
        </div>
      </b-collapse>
      <b-field grouped group-multiline>
        <div class="control">
          <b-switch v-model="hideUpdate" @input="updateDates">Sólo Activos
            <b-tooltip
                label="Los nombramientos activos se destacarán con otro color"
                multilined
                position="is-left">
              <b-icon
                  pack="fas"
                  icon="info-circle"
                  type="is-info">
              </b-icon>
            </b-tooltip></b-switch>
        </div>
        <div class="control">
          <b-switch v-model="dateActive" @input="activateDates">Activar filtro por fecha
            <b-tooltip
                label="Este botón activa la aplicación de filtros por fecha"
                multilined
                position="is-left">
              <b-icon
                  pack="fas"
                  icon="info-circle"
                  type="is-info">
              </b-icon>
            </b-tooltip></b-switch>
        </div>
      </b-field>

      <!--DATA TABLE-->
      <b-table
          :data="person_units"
          :paginated= true
          per-page="20"
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="product.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual"
          :opened-detailed="defaultOpenedDetails"
          detailed
          detail-key="id"
          detail-transition="fade"
          detail-icon="chevron-right"
          :show-detail-icon="showDetailIcon"
          scrollable
          :sticky-header=false
          :row-class="onRowClass">
        <b-table-column
            field="resolution_date"
            label="Fecha Documento"
            width="110"
            sortable
            searchable :custom-search="searchResolution">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.resolution_date._isValid ? new Date(props.row.resolution_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>

        <b-table-column
            field="resolution"
            label="N° de documento"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.resolution}}
          </template>
        </b-table-column>
        <b-table-column
            field="resolution_type.name"
            label="Tipo documento"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.resolution_type ? props.row.resolution_type.name : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="appointment_type.name"
            label="Tipo Nombramiento"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.appointment_type ? props.row.appointment_type.name : ""}}
          </template>
        </b-table-column>

        <b-table-column
            field="work_plant.name"
            label="Cargo"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.work_plant ? props.row.work_plant.name : ""}}
          </template>
        </b-table-column>

        <b-table-column
            field="unit.cost_center"
            label="CC Unidad"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            <b-tooltip
                :label="props.row.unit.name"
                multilined
                position="is-left">
              {{props.row.unit ? props.row.unit.cost_center : ""}}
            </b-tooltip>

          </template>
        </b-table-column>
        <b-table-column
            field="start_date"
            label="Fecha Desde"
            width="110"
            sortable
            searchable :custom-search="searchStart">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            field="end_date"
            label="Fecha Hasta"
            width="110"
            sortable
            searchable :custom-search="searchEndDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            field="working_day"
            label="Jornada /Horas"
            width="110"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            <div v-if="props.row.establishment_id===3">
              {{props.row.actual_hours ? props.row.actual_hours : ""}}
            </div>
            <div v-else>
              {{props.row.working_day ? props.row.working_day.hired_hours : ""}}
            </div>
          </template>
        </b-table-column>

        <b-table-column
            label="Acciones"
            width="140px"
            v-slot="props">
          <b-tooltip
              v-if="props.row.can_show"
              label="Ver información completa del nombramiento."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="eye"
                type="is-primary"
                @click="execShowModal(props.row.id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_edit"
              label="Editar información del nombramiento."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="edit"
                type="is-info is-light is-outlined"
                @click="execEditModal(props.row.id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_distance"
              label="Crear alejamiento."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="sign-out-alt"
                type="is-danger is-light is-outlined"
                @click="execDistanceModal(props.row)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_copy"
              label="Copiar nombramiento."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="copy"
                type="is-danger is-light is-outlined"
                @click="execCopyModal(props.row.id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_nullify"
              label="Anular nombramiento."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="ban"
                type="is-danger is-light is-outlined"
                @click="execNullifyModal(props.row.id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_view_pdf"
              label="Visualizar documento asociado al nombramiento."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="file-alt"
                type="is-success is-light is-outlined"
                @click="viewFile(props.row.document_id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_view_pdf"
              label="Descargar documento asociado al nombramiento."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="download"
                type="is-primary is-light is-outlined"
                @click="downloadFile(props.row.document_id)"/>
          </b-tooltip>

        </b-table-column>

        <template #detail="props">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <div class="columns">
                  <div class="column">
                    <strong>Alejamientos Asociados: </strong>
                  </div>
                </div>
                <div class="columns" v-for="(distance,index) of props.row.distances" :key=index>

                    <div class="column is-narrow">
                      <p>{{distance.appointment_type_name}}</p>
                    </div>
                    <div class="column is-narrow">
                      <p>{{distance.resolution_type_name}}</p>
                    </div>
                    <div class="column is-narrow">
                      <p>{{distance.resolution}}</p>
                    </div>
                    <div class="column is-narrow">
                      <p>{{formatDate(distance.resolution_date)}}</p>
                    </div>
                    <div class="column is-narrow">
                      <p>{{formatDate(distance.start_date)}}</p>
                    </div>
                    <div class="column is-narrow">
                      <b-icon
                          pack="fas"
                          icon="eye"
                          size="is-medium"
                          @click.native="execShowModalDistance(distance.id)"/>
                    </div>
                </div>
              </div>
            </div>
          </article>
          <article class="media" v-if="props.row.establishment_id===3">
            <div class="media-content">
              <div class="content">
                <div class="columns">
                  <div class="column">
                    <strong>Asignaturas: </strong>
                  </div>
                </div>
                <b-taglist>
                  <b-tag type="is-primary" v-for="(subject,index) of props.row.subjects_and_hours" :key=index>{{subject}}</b-tag>
                </b-taglist>
              </div>
            </div>
          </article>
        </template>

      </b-table>
      <!--FIN DATA TABLE-->

      <!-- MODALES -->
      <b-modal
          v-if="canEdit"
          v-model="isAddModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <select-appointment-establishment
              :person-id="personId"
              query-type="create"
              modal-header="Seleccionar Nombramiento a Crear"
              :person_name="person_name"
              @close="props.close"></select-appointment-establishment>
        </template>
      </b-modal>

      <b-modal
          v-if="canEdit"
          v-model="isRequestModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <request-appointment-establishment
              :person-id="personId"
              query-type="create"
              modal-header="Seleccionar Nombramiento a Crear"
              :person_name="person_name"
              @close="props.close"></request-appointment-establishment>
        </template>
      </b-modal>

      <b-modal
          v-model="isShowModalActive"
          has-modal-card
          trap-focus
          width="1024"
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <component  :is="showName"
                      :id="selected"
                      :person-id="personId"
                      :modal-header="title"
                      :person_name="person_name"
                      @close="props.close"/>
        </template>
      </b-modal>
      <b-modal
          v-if="canEdit"
          v-model="isEditModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          width="1024"
          :can-cancel="canCancel">
        <template #default="props">
          <component :is="formName"
                     :person-id="personId"
                     :id="selected"
                     :query-type="query_type"
                     :modal-header="title"
                     :person_name="person_name"
                     @close="props.close"/>
        </template>
      </b-modal>

      <b-modal
          v-model="isShowDistanceModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <component  :is="showName"
                      :id="selected"
                      :modal-header="title"
                      @close="props.close"/>
        </template>
      </b-modal>
      <b-modal
          v-model="isDistanceModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <distance-form :person-id="actual_person_unit.person_id"
                         :id="actual_person_unit.id"
                         :person_unit_id="actual_person_unit.id"
                         query-type="create"
                         :modal-header="'Crear Alejamiento - '  +person_name"
                         :person_name="person_name"
                         @close="props.close">
          </distance-form>
        </template>
      </b-modal>
      <b-modal
          v-model="showPdf"
          :can-cancel="true"
          :destroy-on-hide="true"
          aria-modal
          aria-role="dialog"
          has-modal-card
          trap-focus
          :width="2000"
      >
        <template #default="props">
          <pdf_view
              :src="src"
              @close="props.close"
          ></pdf_view>
        </template>

      </b-modal>

    <b-loading :is-full-page="true"
               v-model="isLoading"
               :can-cancel="false"/>
  </section>
</template>

<script>
import axios from "axios";
import AppointmentAcademic from "../life_record/appointment_academic.vue"
import AppointmentAdministrative from "../life_record/appointment_administrative.vue"
import AppointmentHours from "../life_record/appointment_hours.vue";
import AppointmentAcademicForm from "../life_record/modal_academic_appointment_form.vue"
import AppointmentHoursForm from "../life_record/modal_hour_appointment_form.vue"
import AppointmentAdministrativeForm from "../life_record/modal_administrative_appointment_form.vue"
import SelectAppointmentEstablishment from '../life_record/select_appointment_establishment.vue'
import RequestAppointmentEstablishment from '../life_record/request_appointment_establishment.vue'
import Distance from "../life_record/distance.vue"
import AppointmentFeeContractForm from "../life_record/modal_feecontract_appointment_form"
import AppointmentFeeContract from "../life_record/appointment_feecontract"
import moment from "moment";
import {searchDate, formatDate} from "../../packs/utilities";
import DistanceForm from "../life_record/modal_distance_form.vue";
import pdf from 'vue-pdf';
import Pdf_view from "../performances/pdf_view.vue"
export default {
  name: "appointments",
  props:[
      'personId','isEditDisabled', 'can_create_appointment', 'person_name', 'can_request_appointment'
  ],
  components:{
    AppointmentHours,
    AppointmentAcademic,
    AppointmentAdministrative,
    AppointmentHoursForm,
    AppointmentAcademicForm,
    AppointmentAdministrativeForm,
    SelectAppointmentEstablishment,
    AppointmentFeeContractForm,
    AppointmentFeeContract,
    Distance,
    DistanceForm,
    RequestAppointmentEstablishment,
    Pdf_view,
    pdf,
  },
  data(){
    return {
      formName: "",
      showName: "",
      title: "",
      selected: null,
      index: null,
      currentPage: 1,
      isAddModalActive: false,
      isEditModalActive: false,
      isShowModalActive: false,
      isLoading:false,
      person_units: [],
      person_unit:{},
      canCancel: [],
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      useTransition: false,
      isShowDistanceModalActive: false,
      canEdit: true,
      isDistanceModalActive: false,
      actual_person_unit: {},
      query_type: "",
      all_appointment: [],
      isNullifyActive: false,
      dateActive: false,
      hideUpdate: false,
      date: null,
      isRequestModalActive: false,
      showPdf: false,
      downloadLink: "",
      src:null,
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    activateDates() {
      if(!this.dateActive){
        this.date = null;
        this.updateAppointments()
      }
    },
    onRowClass: function(row, index){
      if(row.is_active && this.hideUpdate){
        return "is-active"
      }
      if(!row.is_active && this.hideUpdate){
        return "is-inactive"
      }
    },
    updateDates(){
      this.isLoading = true
      if(this.hideUpdate){
        this.all_appointment = this.person_units
        this.person_units = this.person_units.filter(nombramiento => nombramiento.is_active === true);
      }
      else{
        this.person_units = this.all_appointment
      }
      this.isLoading = false
    },
    updateAppointments(){
      this.isLoading = true;
      return axios
          .get("/people/"+this.personId+"/appointments.json", {params:{date:this.date}})
          .then(res => {
            this.person_units = res.data
            this.person_units = this.person_units.map(personUnit => {
              var newPersonUnit = personUnit
              newPersonUnit["resolution_date"] = moment(newPersonUnit["resolution_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newPersonUnit["start_date"] = moment(newPersonUnit["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newPersonUnit["end_date"] = moment(newPersonUnit["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newPersonUnit;
            });
            this.hideUpdate = false;
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })
    },
    initialize(){
      if(this.isEditDisabled){
        this.canEdit = false;
      }
      this.isLoading = true;
      this.hideUpdate = false;
      this.dateActive = false;
      this.date = null;
      //this.start_date = new Date(moment().startOf("day").format("DD/MM/YYYY"))
      return axios
          .get("/people/"+this.personId+"/appointments.json", {params:{date:this.date}})
          .then(res => {
            this.person_units = res.data
            this.person_units = this.person_units.map(personUnit => {
              var newPersonUnit = personUnit
              newPersonUnit["resolution_date"] = moment(newPersonUnit["resolution_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newPersonUnit["start_date"] = moment(newPersonUnit["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newPersonUnit["end_date"] = moment(newPersonUnit["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newPersonUnit;
            });
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })
    },
    execNullifyModal(id){
      this.$buefy.dialog.confirm({
        title: 'Anular nombramiento',
        message: '¿Está seguro que desea <b>anular</b> el nombramiento? Esta acción lo dejará inválido.<br>'+
        '<b>*También se anularán los alejamientos asociados al nombramiento</b>',
        confirmText: 'Anular Nombramiento',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/admin/person_units/" + id + "/nullify_appointment.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Nombramiento anulado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                this.isLoading = false;
                this.isNullifyActive = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Nombramiento no pudo ser anulado</b><br>'+error.response.data["base"][0],
              duration: 5000,
              type: 'is-damger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })

    },
    execCopyModal(id){
      this.selected = id;
      let person_unit = this.getEstablishment(id)
      if(person_unit.establishment!==undefined){
        if(person_unit.establishment.name==="ACADEMICOS") {
          this.formName = "AppointmentAcademicForm"
          this.title = "Copiar Nombramiento Estamento Académico"+" - "+this.person_name
        }
        else if (person_unit.establishment.name==="ADMINISTRATIVOS") {
          this.formName = "AppointmentAdministrativeForm"
          this.title = "Copiar Nombramiento Estamento Administrativo"+" - "+this.person_name
        }
        else if (person_unit.establishment.name==="HONORARIOS") {
          this.formName = "AppointmentFeeContractForm"
          this.title = "Copiar Nombramiento Estamento Honorarios"+" - "+this.person_name
        }
        else {
          this.formName = "AppointmentHoursForm"
          this.title = "Copiar Nombramiento Estamento Profesor Horas Clase"+" - "+this.person_name
        }
      }
      else{
        this.formName = "AppointmentAdministrativeForm"

      }
      //console.log("APPOINTMENT "+ this.showName)
      this.isEditModalActive = true;
      this.query_type = "copy"
    },
    execShowModalDistance(distance_id){
      this.selected = distance_id;
      this.showName = "Distance"
      this.title = "Alejamiento"+" - "+this.person_name
      this.isShowDistanceModalActive = true;
    },
    execShowModal(id){
      this.selected = id;
      let person_unit = this.getEstablishment(id)
      if(person_unit.establishment!== undefined){
        if(person_unit.establishment.name==="ACADEMICOS") {
          this.showName = "AppointmentAcademic"
          this.title = "Nombramiento Estamento Académico"+" - "+this.person_name
        }
        else if (person_unit.establishment.name==="ADMINISTRATIVOS") {
          this.showName = "AppointmentAdministrative"
          this.title = "Nombramiento Estamento Administrativo"+" - "+this.person_name
        }
        else if (person_unit.establishment.name==="HONORARIOS") {
          this.showName = "AppointmentFeeContract"
          this.title = "Nombramiento Estamento Honorarios"+" - "+this.person_name
        }
        else {
          this.showName = "AppointmentHours"
          this.title = "Nombramiento Estamento Profesor Horas Clases"+" - "+this.person_name
        }
      }
      else{
        this.showName = "AppointmentAdministrative"
      }
      //console.log("APPOINTMENT "+ this.showName)
      this.isShowModalActive = true;
    },
    execEditModal(id){
      this.selected = id;
      this.query_type = "update"
      let person_unit = this.getEstablishment(id)
      if(person_unit.establishment!==undefined){
        if(person_unit.establishment.name==="ACADEMICOS") {
          this.formName = "AppointmentAcademicForm"
          this.title = "Editar Nombramiento Estamento Académico"+" - "+this.person_name
        }
        else if (person_unit.establishment.name==="ADMINISTRATIVOS") {
          this.formName = "AppointmentAdministrativeForm"
          this.title = "Editar Nombramiento Estamento Administrativo"+" - "+this.person_name
        }
        else if (person_unit.establishment.name==="HONORARIOS") {
          this.formName = "AppointmentFeeContractForm"
          this.title = "Editar Nombramiento Estamento Honorarios"+" - "+this.person_name
        }
        else {
          this.formName = "AppointmentHoursForm"
          this.title = "Editar Nombramiento Estamento Profesor Horas Clase"+" - "+this.person_name
        }
      }
      else{
        this.formName = "AppointmentAdministrativeForm"

      }
      //console.log("APPOINTMENT "+ this.showName)
      this.isEditModalActive = true;
    },
    execDistanceModal(person_unit){
      this.isLoading = true
      this.actual_person_unit = person_unit
      this.isDistanceModalActive = true
      this.isLoading = false
    },
    getEstablishment(selected){
      return this.person_units.find(o => o.id === selected);
    },
    // yyyy/mm/dd -> dd/mm/yyyy
    formatDate,
    searchEndDate(row,input){
      return searchDate(row.end_date,input)
    },
    searchResolution(row,input){
      return searchDate(row.resolution_date,input)
    },
    searchStart(row,input){
      return searchDate(row.start_date,input)
    },
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            this.showPdf = true
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    downloadFile(fileId){
      this.isLoading = true;

      if (fileId == null){
        this.isLoading = false;
        return;
      }

      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(res => {
            let blob = new Blob([res.data], {
              type: "application/pdf",
            })
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = res.headers["content-disposition"]
                .split(";")[1]
                .split('"')[1];
            link.click();
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento fue descargado con exito',
              type: 'is-success',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
          .catch(e => {
            console.log(e)
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento no se pudo descargar',
              type: 'is-danger',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
    },
  },
  watch:{
    isAddModalActive: function () {
      if (!this.isAddModalActive) {
        this.initialize()
      }
    },
    isRequestModalActive: function () {
      if (!this.isRequestModalActive) {
        this.initialize()
      }
    },
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
    isShowModalActive: function () {
      if (!this.isShowModalActive) {
        this.initialize()
      }
    },
    isDistanceModalActive: function () {
      if (!this.isDistanceModalActive) {
        this.initialize()
      }
    },
    isNullifyActive: function () {
      if (!this.isNullifyActive){
        this.initialize()
      }
    }

  }
}
</script>

<style>
tr.is-active {
  /*background: #7957d5;
  color: #fff;*/
}
</style>

