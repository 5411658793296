<template>
  <section>
    <h1 class="subtitle is-2 has-text-primary">
      ¿Como ver Mi Hoja de Vida?
    </h1>
    <div class="mb-3 ">
      <p>Con el perfil de académico, tiene acceso a ver los datos de su propia hoja de vida, la cual puede acceder mediante el menú lateral derecho.</p>
    </div>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Paso 1
    </h2>
    <p class="my-3 ">Desplegar menú lateral:
    </p>
    <ul class=" ml-4">
      <li>1. Abriendo el menú lateral derecho deslizando el puntero sobre la imagen de su perfil</li>
      <img border="2" class="m-2" src="/tutorial_images/desplegar_menu_lateral.png" style="width:35%">
    </ul>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Paso 2
    </h2>
    <p class="my-3 ">Seleccionar opción <b>Mi Hoja de Vida</b>:
    </p>
    <ul class=" ml-4">
      <li>1. En el menú lateral derecho, seleccionar la opción</li>
      <img border="2" class="m-2" src="/tutorial_images/menu_lateral_reslated.png" style="width:35%">
    </ul>
  </section>
</template>
<script>
export default {
  name: "go_to_mhv",
  data(){
    return {
    }
  },
  methods:{

  }
}
</script>