<template>
    <section>
    <div class="container">
      <h1 class="is-size-2">Documentos</h1>
      <div class="columns">
        <div class="column">
          <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
            <b-message type="is-danger" has-icon>
              <div v-for="(item,index) in errores_validacion" :key='index'>
                <div v-if="item">{{ item }}</div>
              </div>
            </b-message>
          </div>
        </div>
      </div>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(buscar_documento)">
          <h3 class="is-size-6 has-text-weight-bold">Búsqueda rápida
            <b-tooltip
                label="La búsqueda rápida busca sobre el nombre del documento, el tipo del documento y número del documento."
                position="is-bottom"
                size="is-medium"
                multilined>

              <b-icon
                  pack="fas"
                  icon="info-circle"
                  size="is-small"
                  type="is-info">
              </b-icon>
            </b-tooltip>
          </h3>
          <div class="columns">
            <div class="column is-3" style="padding-right: 0">
              <b-field>
                <ValidationProvider :rules="{max: 255, regex: /^[0-9a-zA-ZáéíóúÁÉÍÓÚÑñ\-\_\s]*$/, required: true}" v-slot="{ errors }">
                  <b-input v-model="search_all" type="text" expanded placeholder="Ej: Resolucion"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column is-1" style="padding-left: 0">
                <button type="submit" class="button is-primary is-fullwidth">Buscar</button>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(updateDocuments)">
          <div class="columns">
            <div class="column">
              <b-field grouped group-multiline>
                <div class="control">
                  <h1 class="title is-4">Búsqueda Avanzada de Documentos</h1>
                </div>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Carpeta de Documento">
                <multiselect v-model="document_category_id"
                             :options="document_categories.map(type => type.id)"
                             :custom-label="opt => document_categories.find(x => x.id === opt).show_name"
                             placeholder="Seleccione Carpeta de documento"
                             selectLabel="Presione para seleccionar"
                             selectedLabel="Seleccionado"
                             deselectLabel="No se puede deseleccionar"
                             :allow-empty="false">
                  <template v-slot:noOptions>
                    No existen datos
                  </template>
                  <span slot="noResult">
                    No se encontraron elementos.
                </span>
                </multiselect>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Tipo de Documento">
                <multiselect v-model="document_type_id"
                             :options="document_types.map(type => type.id)"
                             :custom-label="opt => document_types.find(x => x.id === opt).name"
                             placeholder="Seleccione tipo de documento"
                             selectLabel="Presione para seleccionar"
                             selectedLabel="Seleccionado"
                             deselectLabel="No se puede deseleccionar"
                             :allow-empty="false">
                  <template v-slot:noOptions>
                    No existen datos
                  </template>
                  <span slot="noResult">
                    No se encontraron elementos.
                </span>
                </multiselect>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Número de Documento">
                <ValidationProvider rules="numeric" v-slot="{ errors }">
                  <b-input v-model="resolution" type="text" placeholder="Ej:4323"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Fecha desde documentos">
                <b-datepicker
                    v-model="start_date"
                    icon="calendar-alt"
                    trap-focus
                    locale="es-ES"
                    editable
                    placeholder="Formato de Fecha: dd/mm/aaaa"
                    @input="selected_date"
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Fecha hasta documentos">
                <b-datepicker
                    v-model="end_date"
                    icon="calendar-alt"
                    trap-focus
                    locale="es-ES"
                    editable
                    placeholder="Formato de Fecha: dd/mm/aaaa"
                    @input="selected_date"
                >
                </b-datepicker>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <b-field label="Año de Documento">
                <ValidationProvider rules="numeric" v-slot="{ errors }">
                  <b-input v-model="search_year" type="text" @input="selected_year"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column is-6">
              <b-field label="Nombre del documento">
                <ValidationProvider :rules="{max: 255, regex: /^[a-zA-Z0-9áéíóúÁÉÍÓÚÑñ\-\_\7\s]*$/}" v-slot="{ errors }">
                  <b-input v-model="search_name"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="actions has-text-centered">
                <button class="button is-primary">Aplicar Filtro</button>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <div class="columns is-centered" v-if="documents.length===1" style="margin-bottom: 40px; margin-top: 40px;">
        <div class="column is-6 is-centered has-text-centered">
          <b-message type="is-warning">
            Se encontró {{this.documents.length}} resultado.
          </b-message>
        </div>
      </div>
      <div class="columns is-centered" v-if="documents.length!==1" style="margin-bottom: 40px; margin-top: 40px;">
        <div class="column is-6 is-centered has-text-centered">
          <b-message type="is-warning">
            Se encontraron {{this.documents.length}} resultados.
          </b-message>
        </div>
      </div>
      <div class="has-text-right">
        <b-button type="is-info"
                  icon-left="plus"
                  v-if="can_create==='true'"
                  @click="newDocumento()">
          Añadir Documento
        </b-button>
      </div>
      <br>
      <b-field grouped group-multiline>
        <div class="control">
          <b-switch v-model="hideFolder" @input="hideColumn">{{text_folder}}
            <b-tooltip
                label="Oculta/muestra la columna Carpeta de Documento"
                multilined
                position="is-left">
              <b-icon
                  pack="fas"
                  icon="info-circle"
                  type="is-info">
              </b-icon>
            </b-tooltip></b-switch>
        </div>
      </b-field>
      <b-table
          :data="documents"
          :paginated=true
          per-page=10
          narrowed
          striped
          hoverable
          :card-layout="cardLayout"
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="desc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="documents.formatted_document_date"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual"
          :opened-detailed="defaultOpenedDetails"
          detailed
          detail-key="name"
          style="word-break: break-word;"
          @details-open="(row) => $buefy.toast.open(`Expanded ${row.name}`)"
          :show-detail-icon="showDetailIcon"
      >
        <b-table-column
            field= 'document_category_name'
            label= 'Carpeta'
            searchable
            :visible="!hideFolder"
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.document_category_name }}
          </template>
        </b-table-column>

        <b-table-column
            field= 'document_type_name'
            label= 'Tipo Documento'
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.document_type_name }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'resolution'
            label= 'N° Documento'
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.resolution }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'formatted_document_date'
            label= 'Fecha Documento'
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.document_date!= null ? props.row.formatted_document_date:props.row.year}}
          </template>
        </b-table-column>
        <b-table-column
            field= 'description'
            label= 'Nombre Adicional del Documento'
            searchable
            sortable
            width="450px">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
<!--            {{ (props.row.visible_description && props.row.description == null) || props.row.document_type_name==="ACTA"?props.row.name:props.row.description }}-->
<!--            {{props.row.name}}-->
            {{props.row.description}}
          </template>
        </b-table-column>

        <b-table-column
            label="Acciones"
            v-slot="props"
            width="148">
          <b-tooltip
              v-if="props.row.can_show"
              label="Muestra en detalle de la información relacionada al documento"
              :active="true">
            <b-button
                pack="fas"
                icon-left="info"
                type="is-primary"
                target="_blank"
                v-if="props.row.can_show"
                @click.native="goToDocument(props.row.id)"/>
          </b-tooltip>
          <b-tooltip
              v-if="props.row.can_edit"
              label="Edita la información relacionada al documento"
              :active="true">
            <b-button
                pack="fas"
                icon-left="edit"
                type="is-info is-light is-outlined"
                v-if="props.row.can_edit"
                @click.native="GoToEdit(props.row.id)"/>
          </b-tooltip>
          <b-tooltip
              v-if="props.row.can_nullify"
              label="Anula el documento"
              :active="true">
            <b-button
                pack="fas"
                icon-left="ban"
                type="is-danger is-light is-outlined"
                v-if="props.row.can_nullify"
                @click.native="execNullifyModal(props.row.id, props.row)"/>

          </b-tooltip>
        </b-table-column>
        <b-table-column
            label="Documentación"
            v-slot="props"
            width="135">
          <b-tooltip
              v-if="props.row.can_download"
              label="Descarga el documento"
              :active="true">
            <b-button
                pack="fas"
                icon-left="download"
                type="is-primary is-light is-outlined"
                v-if="props.row.can_download"
                @click="downloadFile(props.row.id)"/>
          </b-tooltip>
          <b-tooltip
              v-if="props.row.can_show"
              label="Visualiza el documento. Solo permite archivos PDF"
              :active="true">
            <b-button
                icon-pack="fas"
                icon-left="eye"
                type="is-success is-light is-outlined"
                target="_blank"
                v-if="props.row.can_show"
                @click="viewFile(props.row.id)"/>
          </b-tooltip>
        </b-table-column>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <p>
                  <strong>Nomenclatura de descarga:</strong>
                  <span>{{props.row.name}}</span>
                </p>
                <p>
                  <strong>Etiquetas: </strong>
                  <span>{{props.row.name_tags}}</span>
                </p>
              </div>
            </div>
          </article>
        </template>

        <template slot="empty" slot-scope="props">
          <div class="columns is-centered">
            <div class="column is-6 is-centered has-text-centered">
              <b-message type="is-danger">
                No se encontraron resultados para los filtros seleccionados.
              </b-message>
            </div>
          </div>
        </template>


      </b-table>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
      <b-modal
          v-model="showPdf"
          :can-cancel="true"
          :destroy-on-hide="true"
          aria-modal
          aria-role="dialog"
          has-modal-card
          trap-focus
          :width="2000"
      >
        <template #default="props">
          <pdf_view
              :src="src"
              @close="props.close"
          ></pdf_view>
        </template>

      </b-modal>
    </div>
  </section>
</template>
<script>
import {searchDate, generateArrayOfYears} from "../../packs/utilities";
import axios from "axios";
import Pdf_view from "../performances/pdf_view.vue";
import pdf from "vue-pdf";
export default {
  components: {Pdf_view,pdf},
  props:[
   'cargoPrincipal', 'can_create'
  ],
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      isNullifyActive: false,
      documents: [],
      document_types: [],
      document_type_id: null,
      document_category_id: null,
      document_categories: [],
      resolution: null,
      errores_validacion: {},
      start_date: null,
      end_date: null,
      hideFolder: true,
      text_folder: "Ocultar Carpeta",
      size_name: 700,
      showPdf: false,
      src:null,
      search_all: "",
      search_year: "",
      search_name: "",
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    hideColumn(){
      this.isLoading = true
      if(this.hideFolder){
        this.text_folder = "Ocultar Carpeta"
        this.size_name = 700
      }
      else{
        this.text_folder = "Mostrar Carpeta"
        this.size_name = 300
      }
      this.isLoading = false
    },
    goToUrl(url){
      window.open(url)
    },
    initialize(){
      this.isLoading = true
      return axios.get("/documents/get-data-filters.json")
          .then(res => {
            this.document_types = res.data["document_types"]
            this.document_categories = res.data["document_categories"]
            this.document_categories = this.document_categories.sort((a, b) => a.show_name.length - b.show_name.length);
            this.document_types.unshift({
              id: -1,
              name: "TODOS",
            })
            this.document_categories.unshift({
              id: -1,
              show_name: "TODAS",
            })
            this.document_type_id = this.document_types[0].id
            this.document_category_id = this.document_categories[0].id
            this.isLoading=false;
          })
          .catch(e => {
            this.errores_validacion = e.response.data
            this.isLoading=false;
          });
    },
    getDocumentUrl(document_id){
      return "/documents/"+document_id;
    },
    updateDocuments(){
      this.isLoading = true
      this.clean_fast_search()
      return axios.get("/documents/advanced-search.json", {params:{start_date:this.start_date, end_date:this.end_date, document_category_id: this.document_category_id,
        document_type_id: this.document_type_id, resolution: this.resolution, year: this.search_year, name: this.search_name}})
          .then(res => {
            this.documents = res.data
            this.isLoading=false;
          })
          .catch(e => {
            this.isLoading=false;
            //console.log(e);
          });
    },
    execNullifyModal(id, row){
      this.$buefy.dialog.confirm({
        title: 'Anular Documento',
        message: '¿Está seguro que desea <b>anular</b> el documento? Esta acción lo dejará inválido.<br>',
        confirmText: 'Anular Documento',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/documents/" + id + "/nullify-document.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Documento anulado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })

                var index = this.documents.indexOf(row)
                this.documents.splice(index, 1)
                this.isLoading = false;
                this.isNullifyActive = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Docunento no pudo ser anulado</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },
    newDocumento(){
      window.open('/documents/new')
    },
    GoToEdit(id){
      window.open('/documents/'+id+'/edit')
    },
    goToDocument(id){
      window.open('/documents/'+id)
    },
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/view-document", {
            responseType: "blob",
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            if(res.data.type != "application/pdf"){
              this.$buefy.dialog.alert({
                message: "El formato del archivo no es compatible con el visualizador. Solo permite PDF.",
                type: "is-danger",
                hasIcon: true
              })
              this.isLoading = false
            }
            else{
              this.showPdf = true
              this.isLoading = false
            }
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    downloadFile(fileId){
      this.isLoading = true;

      if (fileId == null){
        this.isLoading = false;
        return;
      }

      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
          })
          .then(res => {
            console.log(res.data.type)
            let blob = new Blob([res.data], {
              type: res.data.type,
            })
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = res.headers["content-disposition"]
                .split(";")[1]
                .split('"')[1];
            link.click();
            this.isLoading=false
          })
          .catch(e => {
            console.log(e)
            this.isLoading=false
          })
    },
    buscar_documento(){
      this.isLoading = true
      this.clean_advance_search()
      this.errores_validacion = {}
      return axios.get('/documents/search-documents.json', {
        params: {
          all: this.search_all,
          category: "",
          name: "",
          resolution: "",
          document_type: "",
          year: "",
          google_id: ""
        }
      }).then(response=>{
        this.documents = response.data
        if(this.documents.length === 0){
          this.isLoading=false;
          return this.$buefy.dialog.alert({
            title: 'Error',
            message: 'No se encontraron resultados para su búsqueda',
            type: 'is-warning',
            hasIcon: true,
            icon: 'exclamation-triangle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        }
        this.isLoading=false;

      })
          .catch(error => {
            console.log(error)
            this.isLoading=false;
          })
    },
    clean_advance_search(){
      this.start_date = null
      this.end_date = null
      this.document_category_id = -1
      this.document_type_id = -1
      this.resolution = null
      this.search_year = ""
      this.$refs.observer.reset();
    },
    clean_fast_search(){
      this.search_all = ""
      this.$refs.observer.reset();
    },
    selected_year(){
      if(this.search_year != null && this.search_year != '' && this.search_year != undefined){
        this.start_date = null
        this.end_date = null
      }
    },
    selected_date(){
      if(this.start_date != "" && this.start_date != null && this.start_date != undefined){
        this.search_year = ""
      }
      else if(this.end_date != "" && this.end_date != null && this.end_date != undefined){
        this.search_year = ""
      }
    }

  },
  watch:{
    isNullifyActive: function () {
      if (!this.isNullifyActive) {
        this.updateDocuments()
      }
    },
  },
  computed:{

  }
}
</script>