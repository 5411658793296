<template>
  <section>
    <div class="columns is-centered">
        <div class="column">
          <div class="has-text-right">

            <b-button type="is-info"
                      
                      icon-left="plus"
                      v-if="(publication.can_edit && publication.academic_activity_state_id == 1) || (publication.can_edit && publication.academic_activity_state_id == null)"
                      @click="newContent('chapter')">
              Añadir Capítulo
            </b-button>
          </div>
          <hr class="dropdown-divider" aria-role="menuitem">
          <b-table
            :data="publication.chapters"
            :paginated=true
            per-page=10
            striped
            hoverable
            :card-layout="cardLayout"
            :current-page="currentPage"
            :pagination-simple=false
            pagination-position="bottom"
            default-sort-direction="desc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            default-sort="publication.publication_date"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"
            :opened-detailed="defaultOpenedDetails"
            detailed
            detail-key="title"
            @details-open="(row) => $buefy.toast.open(`Expanded ${row.title}`)"
            :show-detail-icon="showDetailIcon"
            >
              <b-table-column
                field= 'title'
                label= 'Título'
                searchable
                sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  <a :href="getInbookUrl(props.row.id)"> {{ props.row.title }} </a>
                </template>
              </b-table-column>

              <b-table-column
                  field= 'editorial'
                  label= 'Pág. Inicio'
                  searchable
                  sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{ props.row.start_page}}       
                </template>
              </b-table-column>
            

              <b-table-column
                  field= 'title'
                  label= 'Pág. Termino'
                  width="20em"
                  searchable
                  sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>


                <template v-slot="props">

                  {{ props.row.end_page}} 
                </template>
              </b-table-column>

              <b-table-column
                    label="Acciones"
                    v-slot="props">
                  <b-icon
                      pack="fas"
                      icon="eye"
                      size="is-medium"
                      @click.native="execShowModal(props.row.id)"/>

                  <b-icon
                      pack="fas"
                      icon="pen"
                      size="is-medium"
                      v-if="props.row.can_edit"
                      @click.native="editPublication(props.row.id)"/>

                </b-table-column>



              <template slot="detail" slot-scope="props">
                <article class="media">
                  <div class="media-content">
                    <div class="content">
                      <p>
                        <strong>Autores: </strong>
                        <span>{{props.row.participants_references}}</span>
                      </p>
                    </div>
                  </div>
                </article>
              </template>

              <template slot="empty" slot-scope="props">
                <div class="columns is-centered">
                  <div class="column is-6 is-centered has-text-centered">
                    <b-message type="is-danger">
                      No se encontraron capítulos asociados.
                    </b-message>
                  </div>
                </div>
              </template>
            </b-table>
        </div>
      </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

    <b-modal
        :active="isComponentModalInbookActive"
        width="90%"
        :can-cancel="canCancel"
        aria-modal>
      <template #default="props">
        <new-inbook-form modal-cancel="true"
                          :cargo-principal="cargoPrincipal"
                          :user-id="userId"
                          modal-header="Crear"
                          response-type="RETORNO"
                          query-type="create" 
                          :title-book="publication.title"
                          :publication-type-id="tipoContent"
                          v-bind:book-id="publication.id"
                          @publicationAdded="publicationAdded" 
                          
                          @close="closeModal"></new-inbook-form>
      </template>
    </b-modal>

    <b-modal
        :active="isComponentModalActive"
        width="90%"
        :can-cancel="canCancel"
        aria-modal>
      <template #default="props">
        <new-inbook-form modal-cancel="true"
                          :cargo-principal="cargoPrincipal"
                          :user-id="userId"
                          modal-header="Editar Sección"
                          publication-type-id="chapter"
                          query-type="update" v-bind:inbook-id="inbookId"
                          response-type="RETORNOBOOK"
                          @inbookAdded="inbookAdded"
                          @close="closeModal"></new-inbook-form>
      </template>
    </b-modal>

    <b-modal
        v-model="isShowModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal_show_nullified_book :bookId="inbookId"
                                    :cargoPrincipal="cargoPrincipal"
                                    query-type="create"
                                    show="True"
                                    :user-id="userId"
                                    modal-header="Capítulo"
                                    @inbookAdded="inbookAdded"
                                    @close="props.close">
        </modal_show_nullified_book>
      </template>
    </b-modal>
  </section>
</template>
<script>
import axios from "axios";
import NewInbookForm from "./NewInbookForm";
import modal_show_nullified_book from "./ModalShowNullifiedBook";
export default {
  name: "ShowContenidoBookCharTab",
  components: { NewInbookForm, modal_show_nullified_book},
  props:['cargoPrincipal','userId','bookId','publication','participantes'],
  data(){
    return{
      currentPage:1,
      cardLayout: false,
      isLoading:false,
      requiredFieldOnly:false,
      isComponentModalActive: false,
      isComponentModalInbookActive: false,
      canCancel: ['escape'],
      tipoContent: null,
      defaultOpenedDetails: [1],
      isShowModalActive: false,
      showDetailIcon: true,
      inbookId: null

    }
  },

  methods:{
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    editPublication(id){
      this.inbookId = id
      this.isComponentModalActive = true;
    },
    execShowModal(id){
      this.inbookId = id
      this.isShowModalActive = true
    },
    newContent(tipo){
      this.tipoContent = tipo;
      this.isComponentModalInbookActive = true;
    },
    closeModal(){
      this.isComponentModalActive = false;
      this.isComponentModalInbookActive = false;
    },
    getInbookUrl(inbook_id){
      return "/books/"+inbook_id;
    },
    publicationAdded(publication){
      this.publication.chapters.unshift(publication);
      this.isComponentModalInbookActive = false;
      this.$buefy.dialog.alert({
        title: 'Petición exitosa',
        message: 'Su petición fue exitosa y el capítulo ha sido añadida al sistema.',
        type: 'is-success',
        hasIcon: true,
        icon: 'check-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true
      })
    },
    inbookAdded(publication){
      this.publication.chapters = this.publication.chapters.filter( pub => (pub.id!= publication.id))
      this.publication.chapters.unshift(publication);
      this.isComponentModalActive = false;
      if(this.isShowModalActive!=true)
      {
        this.$buefy.dialog.alert({
          title: 'Petición exitosa',
          message: 'Su petición fue exitosa y el capítulo ha sido actualizado en el sistema.',
          type: 'is-success',
          hasIcon: true,
          icon: 'check-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },

  },
  computed:{
  }
}
</script>
<style scoped>

</style>