<template>
  <section>
      <form>
        <!-- Box de Formulario Appointment -->
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" ref="modalDistanceShow">

            <div class="container" style="margin-bottom:40px;">

            <div class="title is-4">
              Información del Alejamiento
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de Alejamiento">
                    <b-input :value="(distance.appointment_type!==undefined && distance.appointment_type!==null) ? distance.appointment_type.name : ''" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción">
                    <b-input :value="distance.description" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de documento">
                  <b-input :value="(distance.resolution_type!==undefined && distance.resolution_type!==null) ? distance.resolution_type.name : ''" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Número de documento">
                    <b-input :value="distance.resolution" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Documento">
                  <b-input :value="formatDate(distance.resolution_date)" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>

            <div class="columns">
              <div class="column is-4">
                <b-field label="Fecha">
                  <b-input :value="formatDate(distance.start_date)" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns is-gapless">
              <div class="column">
                <b-field label="Documento">
                  <b-input expanded readonly v-model="distance.document_name" type="text"></b-input>
                </b-field>
              </div>
              <div class="column is-narrow">
                <br>
                <b-field v-if="distance.can_view_pdf" label="  ">
                  <b-button @click="viewFile(distance.document_id)" icon-left="eye" type="is-success">ver</b-button>
                  <b-button @click="downloadFile(distance.document_id)" icon-left="download" type="is-info">Descargar</b-button>
                </b-field>
              </div>
            </div>
            <div class="container" v-if="hour_class&&es_renuncia">
              <section>
                <div class="columns">
                  <div class="column">
                    <b-field>
                      <template #label>
                        Asignaturas
                        <b-tooltip
                            label="En 'horas renunciadas' indique la cantidad de horas a las que se renuncia para cada asignatura. Un 0 implica que no se renuncia a horas de tal asignatura y un valor igual a 'horas de asignatura' implica una renuncia total a dicha asignatura"
                            position="is-right"
                            size="is-medium"
                            multilined>

                          <b-icon
                              pack="fas"
                              icon="info-circle"
                              size="is-small"
                              type="is-info">
                          </b-icon>
                        </b-tooltip>
                      </template>
                    </b-field>
                  </div>
                </div>
                <b-table
                    :data="selected_hr_subjects"
                    :paginated=true
                    :bordered="true"
                    per-page=3
                    striped
                    hoverable
                    :current-page="currentPage"
                    :pagination-simple=false
                    pagination-position="bottom"
                    default-sort-direction="asc"
                    sort-icon="arrow-up"
                    sort-icon-size="is-small"
                    default-sort="publication.title"
                    aria-next-label="Siguiente"
                    aria-previous-label="Anterior"
                    aria-page-label="Página"
                    aria-current-label="Página actual">
                  <b-table-column
                      field= 'ps_code'
                      label= 'Código PeopleSoft'
                      sortable
                      v-slot="props"
                  >
                    <b-field>
                        <b-input minlength="0" maxlength="155" v-model="props.row.ps_code" type="text" :disabled="true"></b-input>
                    </b-field>
                  </b-table-column>
                  <b-table-column
                      field= 'siac_code'
                      label= 'Código SIAC'
                      sortable
                      v-slot="props"
                  >
                    <b-field>
                      <b-input minlength="0" maxlength="155" v-model="props.row.siac_code" type="text" :disabled="true"></b-input>
                    </b-field>
                  </b-table-column>
                  <b-table-column
                      field= 'name'
                      label= 'Nombre de la Asignatura'
                      width="30em"
                      sortable
                      v-slot="props"
                  >

                    <b-field>
                        <b-taginput
                            v-model="props.row.subject"
                            :data="filtered_hr_subjects"
                            type="is-primary is-light"
                            maxtags="1"
                            autocomplete
                            maxlength="155"
                            :allow-new="true"
                            :disabled="true"
                            field="name"
                            icon="tag"
                            placeholder="Agregar o escoger asignaturas"
                           >
                        </b-taginput>
                    </b-field>
                  </b-table-column>
                  <b-table-column
                      field= 'hours'
                      label= 'Horas Originales de Asignatura'
                      sortable
                      v-slot="props"
                  >
                    <b-field>
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <b-input v-model="props.row.hours" type="number" min="0" :disabled="true"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </b-table-column>
                  <b-table-column
                      field= 'hours'
                      label= 'Horas Actuales de Asignatura'
                      sortable
                      v-slot="props"
                  >
                    <b-field>
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <b-input v-model="props.row.substracted_hours" type="number" min="0" :disabled="true"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </b-table-column>
                  <b-table-column
                      field= 'distanced_hours'
                      label= 'Horas Renunciadas'
                      sortable
                      v-slot="props"
                  >
                    <b-field>
                        <b-input v-model="props.row.distanced_hours" type="number" min="0" :disabled="true"></b-input>
                    </b-field>
                  </b-table-column>
                </b-table>
              </section>
            </div>
            </div>


            <div class="container" v-if="!distance.appointment_is_nil">
              <b-collapse
                  aria-id="contentIdForA11y2"
                  class="panel"
                  animation="slide"
                  v-model="isOpen">
                <template #trigger>
                  <div
                      style="margin-bottom:40px;"
                      class="card-header"
                      role="button"
                  >
                    <p class="card-header-title">
                      <strong>{{isOpen ? "Ocultar Nombramiento Asociado" : "Mostrar Nombramiento Asociado"}}</strong>
                    </p>
                    <a class="card-header-icon">
                      <b-icon class="is-pulled-right" :icon="isOpen ? 'sort-down' : 'sort-up'"></b-icon>
                    </a>
                  </div>
                </template>
                <div class="title is-4">
                  Información del Nombramiento
                </div>
                <component :is="formName"
                           :person-id="personId"
                           :id="distance.person_unit_id"
                />
              </b-collapse>


            </div>

          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <b-button class="is-primary"
                      v-if="distance.can_edit"
                      @click="isEditDistanceModalActive = true">
              Editar
            </b-button>
          </div>
        </footer>
      </form>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="isEditDistanceModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <distance-form :person-id="distance.person_id"
                       :id="distance.id"
                       :person_unit_id="distance.id"
                       query-type="update"
                       modal-header="Editar Alejamiento"
                       @close="props.close">
        </distance-form>
      </template>
    </b-modal>
    <b-modal
        v-model="showPdf"
        :can-cancel="true"
        :destroy-on-hide="true"
        aria-modal
        aria-role="dialog"
        has-modal-card
        trap-focus
        :width="2000"
    >
      <template #default="props">
        <pdf_view
            :src="src"
            @close="props.close"
        ></pdf_view>
      </template>

    </b-modal>
  </section>
</template>
<script>
import axios from "axios";
import AppointmentAcademicShow from "../life_record/appointment_academic_show"
import AppointmentAdministrativeShow from "../life_record/appointment_administrative_show"
import AppointmentHoursShow from "../life_record/appointment_hours_show";
import AppointmentFeeContractShow from "../life_record/appointment_fee_contract_show"
import DistanceForm from "../life_record/modal_distance_form"
import Pdf_view from "../performances/pdf_view.vue";

export default {
  name: "distance",
  components:{
    AppointmentHoursShow,
    AppointmentAcademicShow,
    AppointmentAdministrativeShow,
    AppointmentFeeContractShow,
    DistanceForm,
    Pdf_view

  },
  props:[
    'queryType', 'personId', 'id', 'modalHeader', 'person_unit_id'
  ],
  data(){
    return {
      pu_relation_type_id: 1,
      hr_subject_attributes: [],
      years:[],
      distance_types:[],
      establishments:[],
      resolution_types:[],
      hr_subjects: [],
      filtered_hr_subjects: [],
      errores_validacion:{},
      selected_hr_subjects:[],
      isLoading: false,
      num_hr_subjects: 0,
      currentPage:1,
      cardLayout:false,
      hour_class: false,
      establishment: null,
      es_renuncia: false,
      distance: {},
      person_unit: {},
      formName: "",
      isOpen: false,
      isEditDistanceModalActive: false,
      showPdf: false,
      src:null,

    }
  },
  created() {
    this.isLoading=true;

    axios
        .get("/admin/person_units/"+this.id+"/show_distance.json")
        .then(res => {
          this.distance = res.data
          this.person_unit = res.data["appointment"]
          if(res.data["establishment"].name==="ACADEMICOS") {
            this.formName = "AppointmentAcademicShow"
          }
          else if (res.data["establishment"].name==="ADMINISTRATIVOS") {
            this.formName = "AppointmentAdministrativeShow"
          }
          else if (res.data["establishment"].name==="HONORARIOS"){
            this.formName = "AppointmentFeeContractShow"
          }
          else {
            this.formName = "AppointmentHoursShow"
          }
          if(res.data["establishment"].name==="PROFESORES HORAS DE CLASES"){
            this.hour_class= true
          }
          else{
            this.hour_class = false
          }
          this.distance.hour_class = this.hour_class
          if(this.distance.appointment_type.name==="RENUNCIA"){
            this.es_renuncia = true
          }
          if (res.data["hr_subjects"]){
            for(var i=0;i<res.data["hr_subjects"].length;i++){

              this.selected_hr_subjects.push({id:res.data["hr_subjects"][i].id,
                name: res.data["hr_subjects"][i].name, ps_code: res.data["hr_subjects"][i].ps_code, siac_code: res.data["hr_subjects"][i].siac_code,
                hours: res.data["appointment_has_hr_subjects"][i].hours, index: i, ps_code_disabled: true, subject: [res.data["hr_subjects"][i].name],
                distanced_hours: res.data["has_hr_subjects"][i].hours, substracted_hours: res.data["appointment_has_hr_subjects"][i].substracted_hours})
            }
          }
          this.isLoading=false;
        }).catch(error => {
      console.log(error)
      this.isLoading=false;
    })
  },
  methods: {
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            this.showPdf = true
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    downloadFile(fileId){
      this.isLoading = true;

      if (fileId == null){
        this.isLoading = false;
        return;
      }

      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(res => {
            let blob = new Blob([res.data], {
              type: "application/pdf",
            })
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = res.headers["content-disposition"]
                .split(";")[1]
                .split('"')[1];
            link.click();
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento fue descargado con exito',
              type: 'is-success',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
          .catch(e => {
            console.log(e)
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento no se pudo descargar',
              type: 'is-danger',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
    },
  }

}
</script>

<style scoped>

</style>