<template>
  <div>
    <div class="container">
      <div class="modal-card" style="width: 100%" id="modal-event">
        <header class="modal-card-head">
          <p class="modal-card-title">{{modalHeader}}</p>
        </header>
        <section class="modal-card-body" id="modal-events">
            <div class="columns is-centered">
              <div class="field is-grouped">
                <p class="control">
                  <b-button class="is-primary is-outlined"
                            @click="searchAndAdjudicate = true">
                    Buscar y Relacionar Proyecto Adjudicado en SIGA
                  </b-button>
                </p>
                <p class="control">
                  <b-button class="is-primary is-outlined"
                            @click="create = true">
                    Crear y Adjudicar Proyecto
                  </b-button>
                </p>
              </div>
            </div>
        </section>
      </div>
      <footer class="modal-card-foot actions" style="justify-content: flex-end;">
        <div class="field is-grouped is-pulled-right">
          <b-button type="is-danger"
                     @click="$emit('close')"> Cancelar </b-button>
        </div>
      </footer>
      <b-modal
          :active="searchAndAdjudicate"
          width="90%"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <adjudicate-postulated-project
              modal-cancel="true"
              :cargo-principal="cargoPrincipal"
              modal-header="Relacionar a adjudicado existente"
              query-type="create" v-bind:project-id="project.id" @close="closeModalRelation"></adjudicate-postulated-project>
        </template>
      </b-modal>
      <b-modal
          :active="create"
          width="90%"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <new-adjudicate-project
              modal-cancel="true"
              :cargo-principal="cargoPrincipal"
              modal-header="Adjudicar Proyecto"
              query-type="create" v-bind:project-id="project.id" @close="closeModalNew"></new-adjudicate-project>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>

import AdjudicatePostulatedProject from "./AdjudicatePostulatedProject";
import NewAdjudicateProject from "./NewAdjudicatedProject"

export default {
  name: "select_adjudication_process",
  props:[
    'queryType', 'personId', 'modalHeader', 'person_name', 'cargoPrincipal', 'project', 'projectId'
  ],
  components:{
    AdjudicatePostulatedProject, NewAdjudicateProject,
  },
  data(){
    return{
      searchAndAdjudicate: false,
      create: false,
      isLoading: true,
      canCancel: [],
      isARelateAdjudicateActive: false,
      isNewAdjudicatedActive: false
    }
  },
  created() {
    this.isLoading =false
  },
  methods:{
    closeModalRelation(){
      this.searchAndAdjudicate = false;
    },
    closeModalNew(){
      this.create = false;
    },
  }
}
</script>

<style scoped>

</style>
