<template>
  <section>
    <div class="columns">
      <div class="column">
        <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
          <b-message type="is-danger" has-icon>
            <div v-for="(item,index) in errores_validacion">
              {{ item[0] }}
            </div>
          </b-message>
        </div>
      </div>
    </div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(adjudicateProject)">
        <!-- Box de Formulario Appointment -->
        <div class="box">
            <div class="columns">
              <div class="column">
                <h3 class="is-size-4 has-text-weight-bold">Proyectos Adjudicados Encontrados</h3>
                <h6 class="is-size-6 has-text-weight-bold">Se buscan posibles proyectos relacionados según el nombre del proyecto postulado</h6>
                <b-table
                    :data="projects"
                    :paginated=true
                    per-page=5
                    striped
                    hoverable
                    :current-page="currentPage"
                    :card-layout="cardLayout"
                    :pagination-simple=false
                    pagination-position="bottom"
                    default-sort-direction="desc"
                    sort-icon="arrow-up"
                    sort-icon-size="is-small"
                    default-sort="last_patent_state_date"
                    aria-next-label="Siguiente"
                    aria-previous-label="Anterior"
                    aria-page-label="Página"
                    aria-current-label="Página actual"
                    detailed
                    detail-key="code"
                    detail-transition="fade"
                    detail-icon="chevron-right"
                    :show-detail-icon="showDetailIcon"
                    focusable>
                  <b-table-column
                      field= 'name'
                      label= 'Nombre'
                      width="100"
                      searchable
                      sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{  props.row.name }}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field= 'code'
                      label= 'Código Proyecto'
                      searchable
                      sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{  props.row.code }}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field= 'start_date'
                      label= 'Fecha Inicio'
                      searchable
                      :custom-search="searchInitDate"
                      sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.show_start_date._isValid ? new Date(props.row.show_start_date).toLocaleDateString("es-CL")  : "" }}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field= 'end_date'
                      label= 'Fecha Término'
                      searchable
                      :custom-search="searchEndDate"
                      sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.show_end_date._isValid ? new Date(props.row.show_end_date).toLocaleDateString("es-CL") : "" }}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field= 'project_type'
                      label= 'Tipo de Proyecto'
                      searchable
                      sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.project_type }}
                    </template>
                  </b-table-column>
                  <b-table-column
                      label="Acciones"
                      v-slot="props">
                    <b-button type="is-success" v-if="selected_id!==props.row.id"
                              icon-right="hand-pointer"
                              @click.native="execSelect(props.row)"> Seleccionar
                    </b-button>
                    <b-button type="is-danger" v-else
                              icon-right="trash"
                              @click.native="execDelete(props.row)"> Borrar selección
                    </b-button>
                  </b-table-column>

                  <template slot="empty" slot-scope="props">
                    <div class="columns is-centered">
                      <div class="column is-6 is-centered has-text-centered">
                        <b-message type="is-danger">
                          No se encontraron proyectos asociados.
                        </b-message>
                      </div>
                    </div>
                  </template>

                  <template #detail="props">
                    <article class="media">
                      <div class="media-content">
                        <div class="content">
                          <div class="columns">
                            <div class="column">
                              <strong>Participantes Asociados: </strong>
                            </div>
                          </div>
                          <div class="columns" v-for="(participant, index) in props.row.participants" :key="index">
                            <div class="column is-narrow">
                              <p>{{participant.name}}</p>
                            </div>
                            <div class="column is-narrow">
                              <p>{{participant.corporate_mail}}</p>
                            </div>
                            <div class="column is-narrow">
                              <p>{{participant.unit}}</p>
                            </div>
                            <div class="column is-narrow">
                              <p>{{participant.participant_type}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                  </template>
                </b-table>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <h3 class="is-size-4 has-text-weight-bold">Proyecto Seleccionado: </h3>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-message type="is-success" has-icon  v-if="selected">
                  Seleccionó el proyecto: {{selected.show_name}}
                </b-message>
                <b-message type="is-danger" has-icon v-else>
                  Si desea relacionar el proyecto postulado con uno ya existente, debe seleccionar algún proyecto
                  de la tabla anterior.
                </b-message>
              </div>
            </div>
          <div class="box" v-if="selected">
            <div class="columns">
              <div class="column">
                <h3 class="is-size-4 has-text-weight-bold">Editar Proyecto Seleccionado </h3>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Nombre">
                  <b-input v-model="selected.name" type="text" :disabled="true"></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field>
                  <template #label>
                    Código Adjudicado
                    <b-tooltip
                        label="Esté código será utilizado como identificador único en caso de que haya alcance de nombre. Se sugiere ingresar código otorgado
                            por entidad auspiciante, por ejemplo el código otorgado por la universiad o por ANID"
                        position="is-right"
                        size="is-medium"
                        multilined>

                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          size="is-small"
                          type="is-info">
                      </b-icon>
                    </b-tooltip>
                  </template>
                  <b-input type="text" v-model="selected.code" :disabled="true"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Año de adjudicación">
                  <ValidationProvider rules="integer|positive" v-slot="{errors}">
                    <b-input type="number" v-model="selected.award_year"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Fecha Inicio*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-datepicker
                        v-model="selected.start_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                    >
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Término">
                  <b-datepicker
                      v-model="selected.end_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
          </div>
          <div class="actions has-text-right">
            <button class="button is-danger" @click.prevent="$emit('close')">Cancelar</button>
            <button class="button is-primary" type="submit" :disabled="selected===null || selected === undefined">Seleccionar Adjudicado Existente </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  </section>
</template>
<script>
import axios from "axios";
import {searchDate} from "../../packs/utilities";
import moment from 'moment'

export default {
  name: "adjudicate_postulated_project",
  props:[
    'queryType', 'projectId', 'modalHeader'
  ],
  components:{

  },
  data(){
    return {
      project:{
        is_postulated: false,
        keep_participants: true,
        keep_entities: true,
        participants: [],
        associated_entities: [],
        financings: []
      },
      postulated_project: {},
      errores_validacion:{},
      isLoading: false,
      currentPage:1,
      cardLayout:false,
      tipos_proyecto: [],
      projects: [],
      isLoadingMultiselect: false,
      isDisabled: false,
      showDetailIcon: true,
      selected: null,
      currentPageParticipants: 1,
      currentPageEntities: 1,
      financing_types: [],
      selected_id: null
    }
  },
  created() {
    this.isLoading=true;
    if (this.queryType === "create"){
      axios
          .get("/projects/postulated/"+this.projectId+"/adjudicate-new.json")
          .then(res => {
            this.projects = res.data["projects"]
            this.projects = this.projects.map(proyecto => {
              var newProyecto = proyecto
              newProyecto["show_start_date"] =  moment(newProyecto["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newProyecto["show_end_date"] =  moment(newProyecto["end_date"] , ["YYYY-MM-DD","DD-MM-YYYY"]);
              newProyecto["start_date"] =  new Date(moment(newProyecto["start_date"]).format('MM/DD/YYYY'));
              newProyecto["end_date"] =  new Date(moment(newProyecto["end_date"]).format('MM/DD/YYYY'));
              return newProyecto;
            });
            this.isLoading=false;
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }
  },
  methods: {
    execSelect(row){
      this.isLoading = true;
      this.selected = row
      this.selected_id = row.id
      this.isLoading = false;
    },
    execDelete(row){
      this.isLoading = true;
      this.selected = null
      this.selected_id = null
      this.isLoading = false;
    },
    adjudicateProject(){
      this.isLoading = true
      axios
          .put("/projects/postulated/"+this.projectId+"/adjudicate-existing.json",
              {
                postulated_project_id: this.projectId,
                selected: this.selected
              })
          .then(res => {
            var id = res.data["id"]
            this.$buefy.dialog.alert({
              message: '<p>Proyecto postulado fue adjudicado correctamente. Para abrir el proyecto en una nueva pestaña, presione</p><b><a href="/projects/'+id+'" target="_blank">aquí</a></b>',
              type: 'is-success',
              hasIcon: true,
              icon: 'check-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
              canCancel: [],
              onConfirm: () => {
                this.errores_validacion = {};
                this.isLoading = false;
                window.location.href = '/projects/postulated/' + this.projectId
              }
            })
          }).catch(error => {
        console.log(error)
        this.$buefy.dialog.alert({
          message: 'Sucedió un error al intentar adjudicar el proyecto, revise el formato.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading=false;
      })

    },
    searchProject(query){
      if(query.length<3){
        return
      }
      this.isLoadingMultiselect = true;
      this.project.parent_project_id = null;
      axios
          .get("/projects/postulated/search-projects.json",
              {
                params: {
                  query: query
                }
              })
          .then(res => {
            this.projects = res.data
            this.isLoadingMultiselect=false;

          }).catch(error => {
        console.log(error)
        this.isLoadingMultiselect=false;
      })
    },
    deleteData(project){

    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
    searchEndDate(row,input){
      return searchDate(row.end_date,input)
    },
    searchInitDate(row,input){
      return searchDate(row.start_date,input)
    },
  }

}
</script>

<style>

</style>