<template>
  <div>
    <section>
      <div class="modal-card" style="width: auto" id="modal-event">
        <header class="modal-card-head">
          <p class="modal-card-title">{{modalHeader}}</p>
          <button
              type="button"
              class="delete"
              @click="$emit('close')"/>
        </header>
        <section class="modal-card-body">
          <nullified_show_disclosure :cargo-principal="cargoPrincipal" :disclosure-id="disclosureId">
          </nullified_show_disclosure>
        </section>
      </div>
    </section>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>
<script>
import nullified_show_disclosure from "./nullified_show_disclosure";
export default {
  name: "modal_show_nulliefied_disclosure",
  components:{
    nullified_show_disclosure
  },
  props:[
    'modalHeader','cargoPrincipal', 'disclosureId'
  ],
  data(){
    return{
      isLoading: false
    }
  },
}
</script>

<style scoped>

</style>