<template>
<div>
  <div class="container">
    <div v-if="can_create_program" class="columns">
      <div class="column">
        <span class="is-pulled-right">
          <button class="button is-primary" @click="addNewProgram()">
              <b-icon icon="plus"></b-icon>
              <span>Añadir Programa de Financiamiento</span>
          </button>
        </span>
      </div>
    </div>
    <div class="columns">
      <div class="column has-text-right">
        <b-field>
          <b-switch v-model="cardLayout">
          <span>
            Formato tarjeta (sin filtros ni búsqueda)
             <b-tooltip
                 label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                 multilined
                 position="is-left">
                  <b-icon
                      pack="fas"
                      icon="info-circle"
                      type="is-info">
                  </b-icon>
              </b-tooltip>
          </span>
          </b-switch>
        </b-field>
      </div>
    </div>
  </div>


  <b-table
      :data="programs_entity"
      :paginated=true
      per-page=10
      striped
      hoverable
      :current-page="currentPage"
      :card-layout="cardLayout"
      :pagination-simple=false
      pagination-position="bottom"
      default-sort-direction="desc"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort="last_patent_state_date"
      aria-next-label="Siguiente"
      aria-previous-label="Anterior"
      aria-page-label="Página"
      aria-current-label="Página actual">

    <b-table-column
        field= 'alt_name'
        label= 'Nombre'
        searchable
        sortable>
      <template
          slot="searchable"
          slot-scope="props">
        <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small" />
      </template>
      <template v-slot="props">
        {{  props.row.alt_name ? props.row.alt_name : props.row.name }}
      </template>
    </b-table-column>
    <b-table-column
        field= 'program_url'
        label= 'URL'
        searchable
        sortable>
      <template
          slot="searchable"
          slot-scope="props">
        <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small" />
      </template>
      <template v-slot="props">
        {{ props.row.program_url }}
      </template>
    </b-table-column>

    <b-table-column label="Acciones"
                    v-slot="props">
      <b-icon
          pack="fas"
          icon="eye"
          size="is-medium"
          @click.native="showProgram(props.row)"
      />


      <b-icon
          v-if="can_edit_program"
          pack="fas"
          icon="edit"
          size="is-medium"
          @click.native="editProgram(props.row)"/>
    </b-table-column>
    <template slot="empty" slot-scope="props">
      <div class="columns is-centered">
        <div class="column is-6 is-centered has-text-centered">
          <b-message v-if="can_create_program" has-icon type="is-warning ">
            <h1>
              No se encontraron resultados. haz click <a @click="addNewProgram()">aquí</a> para añadir un nuevo programa de financiamiento.
            </h1>
          </b-message>
          <b-message v-else has-icon type="is-warning ">
            <h1>
              No se encontraron resultados.
            </h1>
          </b-message>
        </div>
      </div>
    </template>

  </b-table>

  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  <b-modal v-model="isAddModalActive"
           has-modal-card
           trap-focus
           :destroy-on-hide="true"
           aria-role="dialog"
           aria-modal
           :can-cancel="canCancel">
    <template #default="props">
      <modal_new_programs
          :partner_entity_id="partner_entity_id"
          :queryType="queryType"
          :programRow="programRow"
          :modalHeader="modalHeader"
          @close="props.close"
          @clicked="onClickChild">
      </modal_new_programs>
    </template>
  </b-modal>
  <b-modal v-model="isShowModalActive"
           has-modal-card
           trap-focus
           :destroy-on-hide="true"
           aria-role="dialog"
           aria-modal
           :can-cancel="canCancel">
    <template #default="props">
      <modal_show_programs
          :partner_entity_id="partner_entity_id"
          :programRow="programRow"
          :can_edit_program="can_edit_program"
          @close="props.close"
          @clicked="onClickChild">
      </modal_show_programs>
    </template>
  </b-modal>
</div>
</template>

<script>
import {searchDate} from "../../packs/utilities";
import axios from "axios";
import modal_new_programs from "../moduloVIME/partner_entities_vime/show/modal_new_programs";
import modal_show_programs from "../moduloVIME/partner_entities_vime/show/modal_show_programs";
import moment from "moment";
export default {
  name: "ProgramsTable",
  components: {modal_new_programs,modal_show_programs},
  props:[
    'programs','hideUpdate','partner_entity_id','can_create_program','can_edit_program'
  ],
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      isAddModalActive: false,
      isShowModalActive: false,
      canCancel: ['escape'],
      programs_entity:[],
      queryType: 'create',
      programRow: null,
      modalHeader: 'Añadir Programa de Financiamiento'
    }
  },
  created() {
    this.getPartnerEntityPatents()
  },
  methods:{
    getPartnerEntityPatents(){
      let vm = this;
      vm.isLoading=true;
      axios
          .get("/partner_entities/"+this.partner_entity_id+"/programs.json")
          .then(res => {
            vm.programs_entity = res.data;
            vm.isLoading=false;
          })
          .catch(e => {
            console.log(e);
            vm.isLoading=false;
          });
    },
    searchStartDate(row,input){
      return searchDate(row.start_date,input)
    },
    searchEndDate(row,input){
      return searchDate(row.end_date,input)
    },
    onClickChild(){
      this.getPartnerEntityPatents()
    },
    addNewProgram(){
      this.queryType = 'create'
      this.modalHeader = 'Añadir Programa de Financiamiento'
      this.isAddModalActive = true
    },
    editProgram(program){
      this.queryType = 'edit'
      this.programRow = program
      this.modalHeader = 'Editar Programa de Financiamiento'
      this.isAddModalActive = true
    },
    showProgram(program){
      this.programRow = program
      this.isShowModalActive = true
    }
  }
}
</script>
