<template>
    <section>
        <div class="modal-card" style="width: 1280px; max-width: 100%;">
            <header class="modal-card-head">
                <p class="modal-card-title">{{ 'Detalle del Convenio' }}</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">

                <b-tabs position="is-centered">
                    <b-tab-item label="Información General" icon="info-circle">

                        <div class="columns">
                            <div class="column">
                                <b-field label="Nombre del Convenio">
                                    <b-input v-model="agreement.name" 
                                        placeholder="No hay dato de el nombre del Convenio" readonly />
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field label="Unidad del Convenio">
                                    <b-input v-model="agreement.unit_name" 
                                        placeholder="No hay dato de el nombre de la Unidad" readonly />
                                </b-field>
                            </div>

                        </div>

                        <div class="columns">
                            <div class="column">
                                <b-field label="N° de la Resolución">
                                    <b-input v-model="agreement.resolution_number" 
                                        placeholder="No hay dato de el N° de la Resolución" readonly />
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field label="Fecha de la Resolución">
                                    <b-input v-model="agreement.creation_date" 
                                        placeholder="No hay dato de la Fecha de la Resolución" readonly />
                                </b-field>
                            </div>

                            <div class="column">
                                <b-field label="Tipo de Resolución">
                                    <b-input v-model="agreement.resolution_type" 
                                        placeholder="No hay dato de el Tipo de Resolución" readonly />
                                </b-field>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column">
                                <b-field label="Fecha de Inicio">
                                    <b-input v-model="agreement.start_date" 
                                        placeholder="No hay dato de la Fecha de Inicio" readonly />
                                </b-field>
                            </div>

                            <div class="column">
                                <b-field label="Fecha de Término">
                                    <b-input v-model="agreement.end_date" 
                                        placeholder="No hay dato de la Fecha de Término"
                                        :disable="disabledEndDate" readonly />
                                </b-field>
                            </div>

                            <div class="column is-narrow">
                                <b-field label="¿Es Indefinido?">
                                    <b-radio-button v-model="disabledEndDate" :native-value="false"
                                        type="is-danger" disabled>
                                        <b-icon icon="times-circle"></b-icon>
                                        <span>No</span>
                                    </b-radio-button>
                                    <b-radio-button v-model="disabledEndDate" :native-value="true"
                                        type="is-success" disabled>
                                        <b-icon icon="check-circle"></b-icon>
                                        <span>Si</span>
                                    </b-radio-button>
                                </b-field>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column">
                                <b-field label="Descripción del Convenio">
                                    <b-input v-model="agreement.description" 
                                        placeholder="No hay dato de la Descripción del Convenio" type="textarea"
                                        readonly />
                                </b-field>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column">
                                <b-field label="Etapa del Convenio">
                                    <b-input v-model="agreement.agreement_stage" 
                                        placeholder="No hay dato de la Etapa del Convenio" readonly />
                                </b-field>
                            </div>

                            <div class="column">
                                <b-field label="Estado del Convenio">
                                    <b-input v-model="agreement.agreement_state" 
                                        placeholder="No hay dato del Estado del Convenio" readonly />
                                </b-field>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column">
                                <b-field label="Link">
                                    <b-input v-model="agreement.link" placeholder="No hay dato del Link del Convenio" 
                                        readonly expanded type="text"  />
                                    <p class="control">
                                        <b-button tag="a" :href="agreement.link" target="_blank" icon-left="external-link-alt" type="is-link">Link</b-button>
                                    </p>
                                </b-field>
                            </div>

                            <div class="column is-narrow">
                                <b-field label="Internacionalidad">
                                    <b-radio-button v-model="agreement.is_international" :native-value="false"
                                        type="is-success" disabled>
                                        <span>VIME Nacional</span>
                                    </b-radio-button>
                                    <b-radio-button v-model="agreement.is_international" :native-value="true"
                                        type="is-success" disabled>
                                        <span>VIME Internacional</span>
                                    </b-radio-button>
                                </b-field>
                            </div>
                        </div>

                    </b-tab-item>

                    <b-tab-item label="Entidades Relacionadas" icon="industry">
                        <div class="columns">
                            <div class="column">

                                <b-table :data="partner_entities" :paginated=true per-page=5 striped
                                    hoverable :pagination-simple=false pagination-position="bottom"
                                    default-sort-direction="asc" sort-icon="arrow-up" sort-icon-size="is-small"
                                    aria-next-label="Siguiente" aria-previous-label="Anterior"
                                    aria-page-label="Página" aria-current-label="Página actual">
                                    <b-table-column field='alt_name' label='Entidad' sortable searchable>
                                        <template #searchable="props">
                                            <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..." icon="search" />
                                        </template>
                                        <template v-slot="props">
                                            {{ props.row.alt_name }}
                                        </template>
                                    </b-table-column>
                                    <b-table-column field='entity_type_name' label='Tipo de entidad' sortable searchable>
                                        <template #searchable="props">
                                            <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Tipo..." icon="search" />
                                        </template>
                                        <template v-slot="props">
                                            {{ props.row.entity_type_name }}
                                        </template>
                                    </b-table-column>
                                    <b-table-column field='email' label='Email' sortable searchable>
                                        <template #searchable="props">
                                            <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Email..." icon="search" />
                                        </template>
                                        <template v-slot="props">
                                            {{ props.row.email }}
                                        </template>
                                    </b-table-column>
                                    <b-table-column field='phone' label='Teléfono' sortable searchable>
                                        <template #searchable="props">
                                            <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Teléfono..." icon="search" />
                                        </template>
                                        <template v-slot="props">
                                            {{ props.row.phone }}
                                        </template>
                                    </b-table-column>
                                    <template slot="empty" slot-scope="props">
                                        <div class="columns is-centered">
                                            <div class="column is-6 is-centered has-text-centered">
                                                <b-message has-icon type="is-danger">
                                                    <h1 v-if="partner_entities.length == 0">No hay datos.</h1>
                                                </b-message>
                                            </div>
                                        </div>
                                    </template>
                                </b-table>

                            </div>
                        </div>
                    </b-tab-item>

                </b-tabs>

            </section>
            <footer class="modal-card-foot">
                <div class="columns is-mobile">
                    <div class="column">
                        <b-button label="Cerrar" @click="$emit('close')" />
                    </div>
                </div>
            </footer>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </section>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {

    name: 'modal_show_academic_agreement',

    props: [
        'personId', 'cargoPrincipal', 'agreementId',
    ],

    data(){
        return {
            isLoading: false,
            disabledEndDate: false,

            //Datos a traer Convenio
            agreement: {

            },
            participants: [],
            partner_entities: [],
        }
    },

    async created() {
        await this.getAgreement()
    },

    methods: {
        async getAgreement() {
            this.isLoading = true
            await axios.get('/academic_agreements.json', { params: { id: this.agreementId } })
                .then(res => {
                    this.agreement = res.data

                    if (this.agreement.start_date != null) {
                        this.agreement.start_date = moment(this.agreement.start_date, ["YYYY-MM-DD","DD-MM-YYYY"])
                        this.agreement.start_date = new Date(this.agreement.start_date).toLocaleDateString("es-CL")
                    }
                    
                    if (this.agreement.end_date != null) {
                        this.agreement.end_date = moment(this.agreement.end_date, ["YYYY-MM-DD","DD-MM-YYYY"])
                        this.agreement.end_date = new Date(this.agreement.end_date).toLocaleDateString("es-CL")
                    }

                    if (this.agreement.creation_date != null ) {
                        this.agreement.creation_date = moment(this.agreement.creation_date, ["YYYY-MM-DD","DD-MM-YYYY"])
                        this.agreement.creation_date = new Date(this.agreement.creation_date).toLocaleDateString("es-CL")
                    }
                    
                    this.disabledEndDate = this.agreement.disabled_date
                    this.partner_entities = this.agreement.partner_entities
                    

                    this.isLoading = false
                })
                .catch(error => {
                    var showError = ' Favor intentarlo más tarde.'
                    if (error.response) {
                        showError = ' Favor revisar errores.<br>Errores:<br>'
                        for (const propiedad in error.response.data) {
                            if (error.response.data.hasOwnProperty(propiedad)) {
                                showError += error.response.data[propiedad].join(', ') + '<br>'
                            }
                        }
                    }

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al traer el Convenio.' + showError,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.$emit('close')
                    this.isLoading = false
                })
        },
    }

}
</script>