<template>
  <section>
        <!-- Box de Formulario HouseholdAllowance -->
        <div class="modal-card" style="width: auto" id="distance-person-function">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" id="distance-person-function" ref="distancePersonFunction">
            <div class="columns">
              <div class="column">
                <b-field label="Descripción">
                  <b-input :value="person_function.description" type="textarea" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de documento">
                  <b-input :value="(person_function.resolution_type!==undefined && person_function.resolution_type!==null) ? person_function.resolution_type.name : ''" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Número de documento">
                  <b-input :value="person_function.resolution" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de documento">
                  <b-input :value="formatDate(person_function.resolution_date)" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Fecha libera función">
                  <b-input :value="formatDate(person_function.start_date)" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns is-gapless">
              <div class="column">
                <b-field label="Documento">
                  <b-input expanded readonly v-model="person_function.document_name" type="text"></b-input>
                </b-field>
              </div>
              <div class="column is-narrow">
                <br>
                <b-field v-if="person_function.can_view_pdf" label="  ">
                  <b-button @click="viewFile(person_function.document_id)" icon-left="eye" type="is-success">ver</b-button>
                  <b-button @click="downloadFile(person_function.document_id)" icon-left="download" type="is-info">Descargar</b-button>
                </b-field>
              </div>
            </div>
            <div class="container">
              <b-collapse
                  aria-id="contentIdForA11y2"
                  class="panel"
                  animation="slide"
                  v-model="isOpen">
                <template #trigger>
                  <div
                      style="margin-bottom:40px;"
                      class="card-header"
                      role="button"
                  >
                    <p class="card-header-title">
                      <strong>{{isOpen ? "Ocultar Asigna Funciones" : "Mostrar Asigna Funciones"}}</strong>
                    </p>
                    <a class="card-header-icon">
                      <b-icon class="is-pulled-right" :icon="isOpen ? 'sort-down' : 'sort-up'"></b-icon>
                    </a>
                  </div>
                </template>
                <component :is="formName"
                           :assigned_function="person_function.assigned_function"
                />
              </b-collapse>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <b-button class="is-primary"
                      v-if="person_function.can_edit"
                      @click="isEditModalActive = true">
              Editar
            </b-button>
          </div>
        </footer>
    <b-modal
        v-model="isEditModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        :can-cancel="canCancel"
        aria-modal>
      <template #default="props">
        <distance-function-form :person-id="person_function.person_id"
                                :id="person_function.id"
                                :person_function_id="person_function.assigned_function.id"
                                :old_start_date="person_function.assigned_function.start_date"
                                query-type="update"
                                :modal-header="'Editar Libera Función - '+person_name"
                                @close="props.close">
        </distance-function-form>
      </template>
    </b-modal>
    <b-modal
        v-model="showPdf"
        :can-cancel="true"
        :destroy-on-hide="true"
        aria-modal
        aria-role="dialog"
        has-modal-card
        trap-focus
        :width="2000"
    >
      <template #default="props">
        <pdf_view
            :src="src"
            @close="props.close"
        ></pdf_view>
      </template>

    </b-modal>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios"
import moment from "moment";
import EventBus from "../../eventBus";
import FunctionInDistance from "./function_in_distance"
import DistanceFunctionForm from "./modal_distance_function_form"
import {formatDate} from "../../../packs/utilities"
import Pdf_view from "../../performances/pdf_view.vue";
export default {
  name: "distanced_function",
  props: [
    'queryType', 'personId', 'id','modalHeader', 'person_name'
  ],
  components: {
    Pdf_view,
    FunctionInDistance,
    DistanceFunctionForm
  },
  data(){
    return {
      person_function: {},
      isLoading: false,
      isEditModalActive: false,
      showPersonFunction: false,
      isOpen: false,
      formName: "",
      canCancel: ['escape'],
      showPdf: false,
      src:null,
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    formatDate,
    initialize(){
      this.isLoading=true;
      axios
          .get("/person_functions/"+this.id+"/show_distanced_function.json")
          .then(res => {
            this.person_function = res.data
            this.formName = "FunctionInDistance"
            this.isLoading=false;
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    },
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            this.showPdf = true
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    downloadFile(fileId){
      this.isLoading = true;

      if (fileId == null){
        this.isLoading = false;
        return;
      }

      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(res => {
            let blob = new Blob([res.data], {
              type: "application/pdf",
            })
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = res.headers["content-disposition"]
                .split(";")[1]
                .split('"')[1];
            link.click();
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento fue descargado con exito',
              type: 'is-success',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
          .catch(e => {
            console.log(e)
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento no se pudo descargar',
              type: 'is-danger',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
    },
  },
  watch:{
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
  }
}
</script>