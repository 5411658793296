<template>
  <section>
    <div class="container">
      <h1 class="is-size-2">Convenios Anulados</h1>
      <br>
      <div class="columns">
        <div class="column is-narrow mt-2">
          <span>Filtrar por departamento: </span>
        </div>
        <div class="column is-4">
          <b-field >
            <multiselect v-model="unit"
                         :options="units.map(x => x.name)"
                         @input="updateAgreements"
                         placeholder="Seleccione departamento"
                         selectLabel="Presione para seleccionar"
                         selectedLabel="Seleccionado"
                         deselectLabel="Presione para deseleccionar"
                         :allow-empty="true">
              <template v-slot:noOptions>
                No existen datos
              </template>
              <span slot="noResult">
                No se encontraron elementos.
            </span>
            </multiselect>
          </b-field>
        </div>
        <div class="column has-text-right">
          <b-field>
            <b-switch v-model="cardLayout">
          <span>
            Formato tarjeta (sin filtros ni búsqueda)
             <b-tooltip
                 label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                 multilined
                 position="is-left">
                  <b-icon
                      pack="fas"
                      icon="info-circle"
                      type="is-info">
                  </b-icon>
              </b-tooltip>
          </span>
            </b-switch>
          </b-field>

        </div>
      </div>
      <b-table
          :data="filtered_convenios"
          :paginated=true
          per-page=10
          striped
          hoverable
          :current-page="currentPage"
          :card-layout="cardLayout"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="publication.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field= 'name'
            label= 'Nombre'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.name }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'agreement_type.agreement_general_type'
            label= 'Categoría general'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.agreement_type.agreement_general_type }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'agreement_type.agreement_specifict_type'
            label= 'Categoría específica'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.agreement_type.agreement_specifict_type }}
          </template>
        </b-table-column>

        <b-table-column
            field= 'agreement_stage'
            label= 'Etapa del convenio'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.agreement_stage }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'agreement_state'
            label= 'Estado del convenio'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.agreement_state }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'agreement_unit_name'
            label= 'Usuario del convenio'
            searchable
            sortable
        >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.agreement_unit_name }}
          </template>
        </b-table-column>
        <b-table-column
            label="Acciones"
            v-slot="props">
          <b-icon
              pack="fas"
              icon="eye"
              size="is-medium"
              @click.native="execShowModal(props.row.id)"/>
          <b-icon
              pack="fas"
              icon="trash-restore"
              size="is-medium"
              type="is-success"
              @click.native="execRestoreModal(props.row.id, props.row)"/>
        </b-table-column>
      </b-table>

      <b-modal
          v-model="isShowModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <agreement_nullified_show :agreement_id="agreement_id"
                                            @close="props.close">
          </agreement_nullified_show>
        </template>
      </b-modal>

      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
  </section>
</template>
<script>
import axios from "axios"
import Agreement_nullified_show from "../show/agreement_nullified_show";
export default {
  data(){
    return {
      //Variables para el control
      isLoading:false,
      currentPage:1,
      applyFilter: 0,
      cardLayout: false,
      // para almacenamiento temporal
      convenios: [],
      filtered_convenios:[],
      partner_entities: [],
      can_create: false,
      units:[],
      unit:null,
      isShowModalActive: false,
      agreement_id:null,
    }
  },
  components:{
    Agreement_nullified_show
  },
  created(){
    this.isLoading=true;
    return axios.get("nullified_agreements.json")
        .then(res => {
          this.convenios = res.data['convenios'];
          this.filtered_convenios = res.data['convenios'];
          this.can_create = res.data['can_create']
          this.units = res.data['units']
          this.isLoading=false;
        })
        .catch(e => {
          this.isLoading=false;
          console.log(e);
        });
  },
  methods:{
    goToAgreement(agreement_id){
      this.isLoading=true;
      window.location.href="/agreements/"+agreement_id
    },
    updateAgreements(){

      this.filtered_convenios =  this.convenios.filter(
          element => element.agreement_unit_name === this.unit
      )
      if(this.unit ==  null || this.unit == ''){
        this.filtered_convenios = this.convenios
      }

    },
    execRestoreModal(id, row){
      let vm = this;
      vm.$buefy.dialog.confirm({
        title: 'Restaurar convenio',
        message: '¿Está seguro que desea <b>restaurar</b> el convenio? Esta acción revertirá la anulación.',
        confirmText: 'Restaurar Convenio',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          vm.isLoading = true
          axios
              .put("/agreements/" + id + "/restore_agreement.json")
              .then(res => {
                vm.$buefy.dialog.alert({
                  message: 'Convenio restaurado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                var index = this.filtered_convenios.indexOf(row)
                this.filtered_convenios.splice(index, 1)
                vm.isLoading = false;
              }).catch(error => {
            console.log(error)
            vm.$buefy.dialog.alert({
              message: '<b>Convenio no pudo ser restaurado</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            vm.isLoading = false;
            vm.isRestoreActive = false;
          })
        }
      })
    },

    execShowModal(id){
      this.agreement_id = id
      this.isShowModalActive = true
    }

  }
}
</script>