<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent @keydown.enter="$event.preventDefault()">
        <!-- Box de Formulario Evento -->
        <div class="modal-card" style="width: auto" id="modal-event">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body">

            <div class="columns">
              <div class="column"></div>
              <div class="column is-6">
                <div class="has-text-centered">
                  <b-field>
                    <b-input type="text" expanded minlength="3" icon="search" v-model='busqueda' @keyup.enter.native.prevent="search(busqueda)" placeholder="Búsqueda con al menos 3 letras..."></b-input>
                    <p class="control">
                      <b-button @click.prevent="search(busqueda)" class="is-primary" id="boton-buscar">Buscar</b-button>
                    </p>
                  </b-field>
                </div>
              </div>
              <div class="column"></div>
            </div>

            <div>
              <div class="columns">
                <div class="column">
                  <h3 class="is-size-4 has-text-weight-bold">Tabla Búsqueda
                    <b-tooltip label="Al seleccionar un rol desde la tabla de búsqueda, se agregará automáticamente a la Tabla Resumen de Roles."
                               position="is-right"
                               size="is-medium"
                               multilined>
                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          size="is-small"
                          type="is-info">
                      </b-icon>
                    </b-tooltip>
                  </h3>
                </div>
              </div>
              <div class="columns">
                <div class="column">

                  <b-table
                      id="tabla-roles"
                      :data="roles"
                      :paginated=true
                      per-page=5
                      striped
                      hoverable
                      :current-page="currentPage"
                      :card-layout="cardLayout"
                      :pagination-simple=false
                      pagination-position="bottom"
                      default-sort-direction="desc"
                      sort-icon="arrow-up"
                      sort-icon-size="is-small"
                      aria-next-label="Siguiente"
                      aria-previous-label="Anterior"
                      aria-page-label="Página"
                      aria-current-label="Página actual"
                      :checked-rows.sync="checkedRows"
                      checkable
                      :checkbox-position="checkboxPosition"
                  >
                    <b-table-column
                        field= 'name'
                        label= 'Rol'
                        width="20em"
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{  props.row.name }}
                      </template>
                    </b-table-column>
                  </b-table>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <h3 class="is-size-4 has-text-weight-bold">Tabla Resumen Roles y Permisos</h3>
                  <b-table
                      :data="checkedRows"
                      :paginated=true
                      per-page=5
                      striped
                      hoverable
                      :current-page="currentPage"
                      :card-layout="cardLayout"
                      :pagination-simple=false
                      pagination-position="bottom"
                      default-sort-direction="desc"
                      sort-icon="arrow-up"
                      sort-icon-size="is-small"
                      aria-next-label="Siguiente"
                      aria-previous-label="Anterior"
                      aria-page-label="Página"
                      aria-current-label="Página actual">
                    <b-table-column
                        field= 'name'
                        label= 'Rol'
                        width="20em"
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{  props.row.name }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field= 'corporate_mail'
                        label= 'Correo'
                        searchable
                        sortable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.corporate_mail ? props.row.corporate_mail : props.row.personal_mail }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        label="Acción"
                        width="10"
                        v-slot="props">
                      <b-icon
                          pack="fas"
                          style="color: #e50000;"
                          icon="trash"
                          size="is-medium"
                          @click.native="deleteRoleButton(props.row)"/>
                    </b-table-column>
                  </b-table>
                </div>
              </div>

            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions" style="justify-content: flex-end;">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" @click="saveRole" type="submit">Guardar </button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import ModalExternalPeopleCreate from "../person/modal_external_people_create"
const qs = require('qs')
export default {
  name: "modal_permission_crud",
  props:[
    'queryType', 'personId', 'id', 'modalHeader', 'categoryId', 'roles_anteriores'
  ],
  data(){
    return {
      isCreateModal: false,
      canCancel: ['escape'],
      busqueda: "",
      currentPage:1,
      cardLayout:false,
      roles:[],
      numeroRoles: 0,
      errores_validacion:[],
      selectedRoles: [],
      isLoading:false,
      checkedRows: [],
      checkboxPosition: "left"
    }
  },
  created() {
    this.isLoading=true;
    this.checkedRows = this.roles_anteriores
    this.isLoading=false;
  },
  methods: {
    deleteRole(selected_role){
      this.checkedRows = this.checkedRows.filter(role => role.internal_position_id != selected_role.internal_position_id);
    },
    search(query){
      this.isLoading = true
      if(query.length<2){
        this.$buefy.dialog.alert({
          message: 'Por favor ingrese a lo menos 3 letras.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = false;
        return false;
      }
      var checkedRows = []
      for (var i = 0, l = this.checkedRows.length; i < l; i++) {
        if(this.checkedRows[i] !== undefined){
          checkedRows.push({internal_position_id: this.checkedRows[i].internal_position_id})
        }
        else{
          checkedRows.push({internal_position_id: -1})
        }
      }
      checkedRows.push({internal_position_id: -1})
      return axios
          .get("/internal_positions/"+query+"/"+JSON.stringify({ checkedRows: checkedRows })+"/search_roles.json"
          ).then(res => {
            this.roles = res.data.roles
            this.numeroRoles = this.roles.length
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })
    },
    updateCheckedRows(roles){
      var rows = roles.filter(item => this.checkedRows.includes(item))
      return rows
    },
    saveRole(){
      this.selectedRoles = this.checkedRows
      this.$emit('clicked', this.selectedRoles)
      this.$emit('close')
    },
    deleteData(participants){
    },
    onClickChild(value){
      this.search(value)
    },
    deleteRoleButton(role){
      this.$buefy.dialog.confirm({
        title: 'Eliminar Rol',
        message: 'Precaución, al momento de guardar esta carpeta, esta acción eliminará la asociación con el rol, lo que implica que ya no podrá manipular esta información',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteRole(role)
      })
    },
  }
}
</script>

