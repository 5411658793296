<template>
  <section>
        <!-- Box de Formulario Appointment -->
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" ref="cardStudyCommission">
            <div class="columns">
              <h3 class="is-size-3 has-text-weight-bold">Información Personal y Nombramiento</h3>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Nombre Completo">
                  <b-input :value="person!==undefined ? person.nombre_completo : ''" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Rut">
                  <b-input :value="person!==undefined ? person.run_completo : ''" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Nombramiento">
                  <b-input :value="nombramiento!==undefined ? nombramiento.custom_name : ''" type="text" readonly></b-input>
                </b-field>
                <p class="control">
                  <b-button class="mt-2" @click.prevent="isShowPersonUnitActive = true" type="is-primary" :disabled="nombramiento===undefined||nombramiento===null">Mostrar Nombramiento</b-button>
                </p>
              </div>
            </div>
            <div class="columns">
              <h3 class="is-size-3 has-text-weight-bold">Información Comisión de Estudios</h3>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Lugar">
                    <b-input :value="commission.place" type="text"></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Institución">
                  <b-input :value="commission.partner_entity!==undefined ? commission.partner_entity.show_name : '' " type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="País">
                  <b-input :value="commission.nationality!==undefined ? commission.nationality.name : '' " type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Fecha Solicitud Comisión">
                  <b-input :value="formatDate(commission.commission_request_date)" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Inicio Actividad">
                  <b-input :value="formatDate(commission.start_date)" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Fin Actividad">
                  <b-input :value="formatDate(commission.end_date)" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Duración de la Actividad (n° días)">
                  <b-input :value="commission.activity_length" type="number" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de documento">
                  <b-input :value="(commission.resolution_type!==undefined && commission.resolution_type!==null) ? commission.resolution_type.name : ''" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="N° de documento">
                  <b-input :value="commission.resolution" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha documento">
                  <b-input :value="formatDate(commission.resolution_date)" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <h3 class="is-size-3 has-text-weight-bold">Actividad a Realizar
              </h3>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Duración de los estudios (N° de semestres)">
                  <b-input :value="commission.study_length" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label= "Nombre Programa">
                  <b-input :value="commission.study_program_name" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Interés para la Universidad">
                  <b-input :value="commission.university_interest" type="textarea" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Objetivos de la Actividad">
                  <b-input :value="commission.objectives" type="textarea" readonly></b-input>
                </b-field>
              </div>
            </div>

            <div class="columns">
              <h3 class="is-size-3 has-text-weight-bold">Idioma
              </h3>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Nombre del examen rendido">
                  <b-input :value="commission.exam_name" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label= "Lugar del examen rendido">
                  <b-input :value="commission.exam_place" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label= "Puntaje del examen rendido">
                  <b-input :value="commission.exam_score" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns is-gapless">
              <div class="column">
                <b-field label="Documento">
                  <b-input expanded readonly v-model="commission.document_name" type="text"></b-input>
                </b-field>
              </div>
              <div class="column is-narrow">
                <br>
                <b-field v-if="commission.can_view_pdf" label="  ">
                  <b-button @click="viewFile(commission.document_id)" icon-left="eye" type="is-success">ver</b-button>
                  <b-button @click="downloadFile(commission.document_id)" icon-left="download" type="is-info">Descargar</b-button>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <h3 class="is-size-3 has-text-weight-bold">Financiamientos
                </h3>
                <b-table
                    :data="commission.financings!==undefined ? commission.financings : []"
                    :paginated=true
                    per-page=5
                    striped
                    hoverable
                    :current-page="currentPage"
                    :pagination-simple=false
                    pagination-position="bottom"
                    default-sort-direction="asc"
                    sort-icon="arrow-up"
                    sort-icon-size="is-small"
                    default-sort="publication.title"
                    aria-next-label="Siguiente"
                    aria-previous-label="Anterior"
                    aria-page-label="Página"
                    aria-current-label="Página actual">
                  <b-table-column
                      field="partner_entity"
                      label="Institución de financiamiento"
                      sortable
                      searchable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          placeholder=" Buscar"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{props.row.partner_entity}}
                    </template>
                  </b-table-column>

                  <b-table-column
                      field="financing_type"
                      label="Tipo Financiamiento"
                      sortable
                      searchable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          placeholder=" Buscar"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{props.row.financing_type}}
                    </template>
                  </b-table-column>

                  <b-table-column
                      field="amount"
                      label="Monto (CLP)"
                      sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          placeholder=" Buscar"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">

                      <money-format :value="props.row.amount!==undefined? parseInt(props.row.amount) : 0"
                                    locale='es-CL'
                                    currency-code='CLP'
                                    v-if="props.row.amount!==undefined && props.row.amount!==null"
                      >
                      </money-format>
                      <p v-if="props.row.amount===undefined || props.row.amount===null"></p>
                    </template>
                  </b-table-column>
                  <b-table-column
                      field="usd_amount"
                      label="Monto (USD)"
                      sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          placeholder=" Buscar"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      <money-format :value="props.row.usd_amount!==undefined? parseInt(props.row.usd_amount) : 0"
                                    locale='en'
                                    currency-code='USD'
                                    v-if="props.row.usd_amount!==undefined && props.row.usd_amount!==null"
                      >
                      </money-format>
                      <p v-if="props.row.usd_amount===undefined || props.row.usd_amount===null"></p>
                    </template>
                  </b-table-column>
                </b-table>

              </div>
            </div>

          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
          </div>
        </footer>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="isShowPersonUnitActive"
        :destroy-on-hide="true">
      <template #default="props">
        <info-appointment
            modal-header="Nombramiento Asociado"
            :nombramiento = "nombramiento"
            @close="props.close"
        ></info-appointment>
      </template>
    </b-modal>
    <b-modal
        v-model="showPdf"
        :can-cancel="true"
        :destroy-on-hide="true"
        aria-modal
        aria-role="dialog"
        has-modal-card
        trap-focus
        :width="2000"
    >
      <template #default="props">
        <pdf_view
            :src="src"
            @close="props.close"
        ></pdf_view>
      </template>

    </b-modal>
  </section>
</template>

<script>
import axios from "axios";
import ModalFinancingIcc from "./modal_financing_icc";
import MoneyFormat from 'vue-money-format'
import InfoAppointment from "./info_appointment"
import {formatDate} from "../../../packs/utilities";
import Pdf_view from "../../performances/pdf_view.vue";

export default {
  name: "modal_study_commission",
  props:[
    'queryType', 'personId', 'id', 'modalHeader'
  ],
  components:{
    Pdf_view,
    ModalFinancingIcc,
    InfoAppointment,
    'money-format': MoneyFormat
  },
  data(){
    return {
      commission:{
        financings_attributes:[
        ],
        person_id: this.personId,
        commission_type_id: 2,//COMISION DE ESTUDIOS
      },
      commission_types: [],
      nationalities: [],
      previous_commissions: [],
      isLoading: true,
      person: {},
      nombramiento: {},
      isShowPersonUnitActive: false,
      errores_validacion:[],
      partner_entities: [],
      isLoadingMultiselect: false,
      isAddModalFinancingActive: false,
      tipos_financiamiento: [],
      actualFinancing: null,
      actualIndex: null,
      canCancel: ['escape'],
      cardLayout:false,
      currentPage:1,
      financiamientos: [{
        amount: null,
        year: null,
        financing_type: null,
        partner_entity: null
      }],
      showPdf: false,
      src:null,
    }
  },
  created() {
    this.isLoading=true;
    axios
        .get("/admin/commissions/" + this.id + ".json",
        ).then(res => {
      this.commission = res.data
      this.person = res.data["person"]
      this.nombramiento = res.data["nombramiento"]
      this.isLoading=false;
    }).catch(error => {
      console.log(error)
      this.isLoading=false;
    })
  },
  methods: {
    formatDate,
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            this.showPdf = true
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    downloadFile(fileId){
      this.isLoading = true;

      if (fileId == null){
        this.isLoading = false;
        return;
      }

      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(res => {
            let blob = new Blob([res.data], {
              type: "application/pdf",
            })
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = res.headers["content-disposition"]
                .split(";")[1]
                .split('"')[1];
            link.click();
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento fue descargado con exito',
              type: 'is-success',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
          .catch(e => {
            console.log(e)
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento no se pudo descargar',
              type: 'is-danger',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
    },
  }
}
</script>
