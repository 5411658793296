<template>
  <section>
    <div class="container">

      <!--DATA TABLE-->
      <b-table
          :data="rectifications"
          :paginated= true
          per-page="10"
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field="decree_date"
            label="Fecha Decreto"
            sortable
            searchable :custom-search="searchDecreeDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.decree_date._isValid ? new Date(props.row.decree_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            field="decree"
            label="Decreto"
            numeric
            searchable
            sortable
           >
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.decree}}
          </template>
        </b-table-column>
        <b-table-column
            field="start_date"
            label="Fecha Rectificación"
            sortable
            searchable :custom-search="searchStartDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            label="Acciones"
            v-slot="props">
          <b-icon
              pack="fas"
              icon="eye"
              size="is-medium"
              v-if="props.row.can_show"
              @click.native="execShowModal(props.row.id)"/>
          <b-icon
              pack="fas"
              icon="edit"
              size="is-medium"
              v-if="props.row.can_edit"
              @click.native="execEditModal(props.row.id)"/>
        </b-table-column>
      </b-table>

      <b-modal
          v-model="isShowModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <rectification :id="selected"
                 :modal-header="'Rectificación - '  +person_name"
                 :person_name="person_name"
                 @close="props.close">
          </rectification>
        </template>
      </b-modal>
      <b-modal
          v-model="isEditModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <rectification-form :person-id="personId"
                      :id="selected"
                      query-type="update"
                      :modal-header="'Editar Rectificación - '  +person_name"
                      @close="props.close">
          </rectification-form>
        </template>
      </b-modal>
    </div>
    <b-loading :is-full-page="true"
               v-model="isLoading"
               :can-cancel="false"/>
  </section>
</template>

<script>
import axios from "axios";
import Rectification from "../rectify/rectification"
import RectificationForm from "../rectify/rectification_form"
import moment from "moment";
import {searchDate, formatDate} from "../../../packs/utilities";

export default {
  name: "rectifications",
  props:[
    'personId', 'can_create_event', 'person_name'
  ],
  components:{
    Rectification,
    RectificationForm
  },
  data(){
    return {
      selected: null,
      currentPage: 1,
      isAddModalActive: false,
      isEditModalActive: false,
      isShowModalActive: false,
      canCancel: ['escape'],
      isLoading:false,
      rectifications: [],

    }
  },
  created(){
    this.initialize()
  },
  methods:{
    initialize(){
      this.isLoading = true;
      return axios
          .get("/people/"+this.personId+"/rectifications.json")
          .then(res => {
            this.rectifications = res.data
            this.rectifications = this.rectifications.map(rectification => {
              var newRectification = rectification
              newRectification["decree_date"] = moment(newRectification["decree_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newRectification["start_date"] = moment(newRectification["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newRectification["end_date"] = moment(newRectification["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newRectification;
            });
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })
    },
    execShowModal(id){
      this.selected = id;
      this.isShowModalActive = true;
    },
    execEditModal(id){
      this.selected = id;
      this.isEditModalActive = true;
    },
    // yyyy/mm/dd -> dd/mm/yyyy
    formatDate,
    searchDecreeDate(row, input){
      return searchDate(row.decree_date, input)
    },
    searchStartDate(row, input){
      return searchDate(row.start_date, input)
    },
    searchEndDate(row, input){
      return searchDate(row.end_date, input)
    }

  },
  watch:{
    isAddModalActive: function () {
      if (!this.isAddModalActive) {
        this.initialize()
      }
    },
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
    isShowModalActive: function () {
      if (!this.isShowModalActive) {
        this.initialize()
      }
    },
  }
}
</script>
