<template>
  <section>
    <div class="container">
      <div class="box">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><a href="/life_record">Menú principal</a></li>
            <li class="is-active"><a href="#" aria-current="page">Personas</a></li>
          </ul>
        </nav>
        <div class="columns">
          <div class="column">
            <b-field grouped group-multiline>
              <div class="control">
                <h1 class="title">Listar Personas</h1>
              </div>
            </b-field>
          </div>
          <div class="column">
            <div class="has-text-right">
              <b-button label="Exportar"
                        class="is-primary is-outlined"
                        v-if="filters_applied"
                        @click="exportData"
                        icon-left="file-excel"/>
            </div>
          </div>
        </div>
        <b-collapse :open="true" aria-id="contentCollapse">
          <template #trigger>
            <b-button
                class="mb-2"
                label="Filtros de nombramiento"
                type="is-primary"
                aria-controls="contentCollapse"
                icon-left="filter" />
          </template>
          <!-- COMPONENTE FILTROS -->
          <filters-people v-on:updatePeople="updatePeople"/>
        </b-collapse>
        <!--DATA TABLE-->
        <div class="columns is-centered" v-if="!filters_applied" style="margin-bottom: 40px; margin-top: 40px;">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-warning">
              Debe aplicar filtros para realizar una búsqueda de personas
            </b-message>
          </div>
        </div>
        <div class="columns is-centered" v-if="filters_applied&&people.length===1" style="margin-bottom: 40px; margin-top: 40px;">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-warning">
              Se encontró {{this.people.length}} resultado.
            </b-message>
          </div>
        </div>
        <div class="columns is-centered" v-if="filters_applied&&people.length!==1" style="margin-bottom: 40px; margin-top: 40px;">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-warning">
              Se encontraron {{this.people.length}} resultados.
            </b-message>
          </div>
        </div>
        <b-table
            :data="people"
            :paginated= true
            v-if="filters_applied"
            per-page="20"
            striped
            hoverable
            :current-page="currentPage"
            :pagination-simple=false
            pagination-position="both"
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual">
          <b-table-column
              field="index"
              label="N°">
            <template v-slot="props">
              {{ people.indexOf(props.row) + 1 }}
            </template>
          </b-table-column>
          <b-table-column
              field="integral"
              label="Rut"
              sortable
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.integral}}
            </template>
          </b-table-column>
          <b-table-column
              field="name"
              label="Nombre"
              sortable
              searchable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.name}}
            </template>
          </b-table-column>
          <b-table-column
              field="last_name"
              label="Primer Apellido"
              sortable
              searchable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.last_name}}
            </template>
          </b-table-column>
          <b-table-column
              field="second_surname"
              label="Segundo Apellido"
              sortable
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.second_surname}}
            </template>
          </b-table-column>
          <b-table-column
              field="establishment"
              label="Estamento"
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.establishment!==undefined ? props.row.establishment : ""}}
            </template>
          </b-table-column>
          <b-table-column
              field="unit"
              label="Unidad de desempeño"
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.unit!==undefined ? props.row.unit : ""}}
            </template>
          </b-table-column>
          <b-table-column
              label="Acciones"
              v-slot="props">
            <b-tooltip label="Ir a Hoja de Vida"
                       position="is-top">
              <b-icon
                  pack="fas"
                  icon="eye"
                  size="is-medium"
                  @click.native="returnShow(props.row.id)"/>
            </b-tooltip>

            <b-icon
                pack="fas"
                icon="edit"
                size="is-medium"
                v-if="props.row.can_edit"
                @click.native="execEditModal(props.row.id)"/>
          </b-table-column>

          <template slot="empty" slot-scope="props">
            <div class="columns is-centered">
              <div class="column is-6 is-centered has-text-centered">
                <b-message type="is-danger">
                  No se encontraron resultados para los filtros seleccionados.
                </b-message>
              </div>
            </div>
          </template>

        </b-table>
      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <!-- MODALES -->
    <b-modal
        v-model="isAddModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-person-form query-type="create"
                           modal-header="Nueva Persona"
                           @close="props.close"></modal-person-form>
      </template>
    </b-modal>
    <b-modal
        v-model="isEditModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-person-edit-form :person-id="selected"
                           query-type="update"
                           modal-header="Editar Datos Personales"
                           @close="props.close"></modal-person-edit-form>
      </template>
    </b-modal>
  </section>
</template>

<script>
import axios from "axios";
import ModalPersonForm from "../life_record/modal_person_form.vue"
import FiltersPeople from "../person/filters.vue"
import ModalPersonEditForm from "../life_record/modal_person_edit_form.vue"

export default {
  name: "people_index",
  props:[
      "unitId"
  ],
  components:{
    ModalPersonForm,
    FiltersPeople,
    ModalPersonEditForm
  },
  data(){
    return {
      selected: null,
      people: [],
      currentPage: 1,
      isLoading: false,
      isAddModalActive: false,
      isEditModalActive: false,
      gender_f:"",
      academics_f: "",
      teachers_f:"",
      administratives_f:"",
      feecontract_f: "",
      work_plant_f:"",
      canCancel: [],
      category_f:"",
      hierarchy_f:"",
      quality_f:"",
      hired_hours_f:"",
      contracting_unit_f:"",
      work_unit_f:"",
      start_date_start_f: "",
      start_date_end_f: "",
      university_entrance_date_start_f: "",
      university_entrance_date_end_f: "",
      no_filters: true,
      active_f: "",
      filters_applied: false,
      birthday_date_start_f: "",
      birthday_date_end_f: ""
      // EL SHOW VA A LA URL people/:id/life_record
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    initialize(){

    },
    returnShow(person_id){
      this.isLoading = true
      window.open('/people/'+person_id+'/life_record')
      this.isLoading = false
    },
    newPerson(){
      this.isAddModalActive = true
    },
    execEditModal(id){
      this.isLoading=true
      this.selected = id;
      this.isEditModalActive = true;
      this.isLoading=false
    },
    updatePeople(people, f_gender,f_academics,f_teachers,f_administratives,f_work_plant,f_category,f_hierarchy,
    f_quality,f_work_unit,f_hired_hours,work_unit_f, start_date_start_f, start_date_end_f,
                 university_entrance_date_start_f, university_entrance_date_end_f, birthday_date_start_f,
                 birthday_date_end_f, f_feecontract, active_f){
     this.isLoading = true
     this.people = people
     this.gender_f = f_gender
     this.academics_f = f_academics
     this.teachers_f = f_teachers
     this.administratives_f = f_administratives
     this.feecontract_f = f_feecontract
     this.work_plant_f = f_work_plant
     this.category_f = f_category
     this.hierarchy_f = f_hierarchy
     this.quality_f = f_quality
     this.contracting_unit_f = f_work_unit
     this.work_unit_f = work_unit_f
     this.hired_hours_f = f_hired_hours
     this.start_date_start_f = start_date_start_f
      this.active_f = active_f
      this.start_date_end_f = start_date_end_f
      this.university_entrance_date_start_f = university_entrance_date_start_f
      this.university_entrance_date_end_f = university_entrance_date_end_f
      this.birthday_date_start_f = birthday_date_start_f
      this.birthday_date_end_f = birthday_date_end_f
      this.filters_applied=true
      this.isLoading =false


    },
    exportData(){
      this.isLoading = true
      this.checkFilterParams()
      if (this.no_filters === true){
        window.open("/people/export_data.xlsx?no_filters="+true)
        this.isLoading = false
      }
      else{
        window.open("/people/export_data.xlsx?gender_f="+this.gender_f+"&academics_f="+this.academics_f+"&teachers_f="+
          this.teachers_f+"&administratives_f="+this.administratives_f+"&work_plant_f="+this.work_plant_f+
          "&category_f="+this.category_f+"&hierarchy_f="+this.hierarchy_f+"&quality_f="+this.quality_f+"&hired_hours_f="+
          this.hired_hours_f+"&contracting_unit_f="+this.contracting_unit_f+"&work_unit_f="+this.work_unit_f+"&start_date_start_f="+this.start_date_start_f+
        "&start_date_end_f="+this.start_date_end_f+"&university_entrance_date_start_f="+this.university_entrance_date_start_f+"&university_entrance_date_end_f="+this.university_entrance_date_end_f+"&birthday_date_start_f="+
            this.birthday_date_start_f+"&birthday_date_end_f="+this.birthday_date_end_f+"&feecontract_f="+this.feecontract_f+"&active_f="+this.active_f)
        this.isLoading = false
      }
    },
    checkFilterParams(){

      this.no_filters = this.gender_f == undefined && (this.academics_f == undefined || this.academics_f == false) && (this.feecontract_f == undefined || this.feecontract_f == false) &&
          (this.teachers_f == undefined || this.teachers_f == false) && (this.administratives_f == undefined || this.administratives_f == false) &&
          this.work_plant_f == undefined && this.category_f == undefined && this.hierarchy_f == undefined && this.quality_f == undefined &&
          this.hired_hours_f == undefined && this.contracting_unit_f == undefined && this.work_unit_f == undefined &&
          this.start_date_start_f == undefined && this.start_date_end_f == undefined &&
          this.university_entrance_date_end_f == undefined
          && this.university_entrance_date_start_f == undefined
          && this.birthday_date_start_f == undefined && this.birthday_date_end_f == undefined;
    }
  },
  watch: {
    isAddModalActive: function () {
      if (!this.isAddModalActive) {
        this.initialize()
      }
    },
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
  }
}
</script>

