<template>
<section>

  <h3 class="is-size-4 has-text-weight-bold has-text-centered">Tabla de Personas Contratadas</h3>
  <div class="has-text-right">
    <b-button v-if="service.can_add_people"
              icon-left="plus"
              type="is-primary"
              @click="editItegrantes()">
      Añadir Persona contratada
    </b-button>
  </div>
  <div class="column">
    <b-table
        :data="integrants"
        :paginated=true
        per-page=15
        striped
        hoverable
        :key="keyupdates"
        :current-page="currentPage"
        :card-layout="cardLayout"
        :pagination-simple=false
        pagination-position="bottom"
        default-sort-direction="desc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="last_patent_state_date"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página actual">
      <b-table-column
          field= 'name'
          label= 'Nombres'
          width="20em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.name || props.row.person_name }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'mail'
          label= 'Correo'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{  props.row.mail }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'contract.start_date'
          label= 'Inicio Contrato'
          sortable>
        <template v-slot="props">
          {{ props.row.contract.start_date._isValid ? new Date(props.row.contract.start_date).toLocaleDateString("es-CL"): '' }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'contract.end_date'
          label= 'Término Contrato'
          sortable>
        <template v-slot="props">
          <div v-if="props.row.contract.end_date != null">
            {{ props.row.contract.end_date._isValid ? new Date(props.row.contract.end_date).toLocaleDateString("es-CL"): '' }}  
          </div>
          
          
        </template>
      </b-table-column>
      <b-table-column
          label="Acciones"
          v-slot="props">
             
          <b-button type="is-success is-light is-outlined"
                    v-if="props.row.contract"
                      icon-left="eye"
                      @click="viewContract(props.row)">
          </b-button>  
          <b-button type="is-info is-light is-outlined"
                    v-if="props.row.contract  && service.can_edit"
                      icon-left="pen"

                      @click="editContract(props.row)">
          </b-button> 
            
          <b-button @click="goToProfile(props.row.person_id)" type="is-primary is-light is-outlined">
            <b-icon
              icon="user"
              size="is-small">
            </b-icon>
          </b-button>
            

      </b-table-column>
      <!--
      <b-table-column
          label="Documentación"
          v-slot="props">
             
          <b-button type="is-info is-light is-outlined"
                    v-if="props.row.contract"
                      icon-left="file-word"
                      @click="downloadWordContract(props.row)">
          </b-button>  

      </b-table-column>
    -->




      

      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-danger">
              No se encontraron personas contratadas.
            </b-message>
          </div>
        </div>
      </template>
    </b-table>


  </div>
  <b-modal
      :active="isComponentModalActive"
      width="90%"
      :can-cancel="canCancel"
      aria-modal>
    <template #default="props">
      <new-service-form modal-cancel="true"
                        :cargo-principal="cargoPrincipal"
                        :user-id="userId"
                        :show-participants-first="true"
                        modal-header="Editar Servicio de EFUSACH"
                        query-type="update" v-bind:service-id="service.id" v-bind:service-table="service.table" 
                        @close="closeModal"
                        @clicked="updateData"></new-service-form>
    </template>
  </b-modal>

  <b-modal
      v-model="isComponentModalIntegrantesActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      width="100%"
      aria-modal
      :can-cancel="canCancel">
    <template #default="props">
      <modal-member :service-id="service.id"
                    :service-table="service.table"
                    :integrants="integrants"
                    style="width: 70vw"
                     modal-header="Añadir Persona Contratada"
                     @close="props.close"
                     @clicked="saveMember"
                                 
      >
      </modal-member>
    </template>
  </b-modal>

  <b-modal
      v-model="isComponentModalContract"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      width="100%"
      aria-modal
      :can-cancel="canCancel">
    <template #default="props">
      <modal-contract :contract-id="contract.id"
                    :query-type="contractType"
                    :modal-header="contractHeader"
                      style="width: 70vw"
                     @close="props.close"
                     @clicked="updateContract"
      >
      </modal-contract>
    </template>
  </b-modal>


  <b-modal
        v-model="isComponentModalDocumentActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-document :service-id="service.id"
                        :service-code="service.code"
                        :service-project-managment="ownerDocument"
                        :service-table="serviceTable"
                                   modal-header="Añadir Documento"
                                   type-document="person"
                                   :isServiceCreated="true"
                                   @close="props.close"
        >
        </modal-document>
      </template>
    </b-modal>


</section>
</template>

<script>
import axios from "axios";
import {searchDate} from "../../packs/utilities";
import NewServiceForm from "./NewServiceForm";
import modalMember from "./ModalMemberService";
import modalDocument from "./ModalUploadDocument";
import modalContract from "./ModalContract";
import moment from "moment/moment";
import { saveAs } from "file-saver";
import { TemplateHandler } from 'easy-template-x';
import ModalParticipantProject from "./ModalParticipantService.vue";
export default {
  name: "ShowHonorariosTab",
  props:[ 'participants', 'service', 'cargoPrincipal', 'canEdit', 'integrants','userId','serviceTable'],
  components:{ModalParticipantProject, NewServiceForm, modalMember, modalContract, modalDocument},
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      years:[],
      keyupdates: 0,
      year:null,
      contractType: '',
      contractHeader: '',
      member: null,
      isComponentModalActive:false,
      isComponentModalDocumentActive: false,
      isComponentModalIntegrantesActive: false,
      isComponentModalContract: false,
      canCancel: ['escape'],
      contract: null,
      ownerDocument: null,
    }
  },
  created(){

  },
  methods:{
    editResponsables(){
      this.isComponentModalActive = true;
    },
    editItegrantes(){
      this.isComponentModalIntegrantesActive = true;
    },
    closeModal(){
      this.isComponentModalActive = false;
      this.isComponentModalIntegrantesActive = false;
      this.isComponentModalContract = false;
    },
    saveMember(data){
      this.keyupdates = this.keyupdates+1;
      this.integrants.push(data);
      this.keyupdates = this.keyupdates+1;
    },
    goToProfile(id){
      const url = '/people/sdt/' + id;
      window.open(url, '_blank');
    },
    updateData(data){
      this.$emit("clicked",data)
    },
    newDocument(row){
      this.ownerDocument = row
      this.isComponentModalDocumentActive = true;
    },
    viewContract(row){
      this.contract = row.contract;
      this.member = row;
      this.contractHeader = "Ver Contrato"
      this.contractType = "view"
      this.isComponentModalContract = true;
    },
    editContract(row){
      this.contract = row.contract;
      this.member = row;
      this.contractType = "update"
      this.contractHeader = "Editar Contrato"
      this.isComponentModalContract = true;
    },
    updateContract(data){
      this.keyupdates = this.keyupdates+1;
      this.member.contract = data
      this.keyupdates = this.keyupdates+1;
    },
    searchStartDate(row,input){
      return searchDate(row.contract.start_date,input)
    },
    searchEndDate(row,input){
      return searchDate(row.contract.end_date,input)
    },
    async downloadWordContract(row) {
      const response = await fetch('/template-contrato-servicios-profesionales-sdt.docx');
      const templateFile = await response.blob();
      const nombresMeses = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
      ];

      var unidad_menor = "";
      if (this.service.unit != ""){
        unidad_menor = this.service.unit;
      }
      var unidad_mayor = ""
      if (this.service.unit_faculty != ""){
        unidad_mayor = this.service.unit_faculty;
      }
      var code = this.service.code;
      const fechaActual = new Date();
      var year = fechaActual.getFullYear();
      var mes = nombresMeses[fechaActual.getMonth()];
      
      var nom_completo = "";
      if (row.name == ""){
        nom_completo = row.person_name;
      }
      else{
        nom_completo = row.name;
      }

      var run = "";
      if (row.run != null){
        run = row.run.rut.toLocaleString('es-ES').toString()+"-"+row.run.dv;
      }
      var prof = "____";

      var dom = "____";
      if (row.addres != null){
        dom = row.addres;
      }

      var nom_serv = this.service.name;
      var nom_completo_jefe_serv = "";
      if (this.service.projectManagment != null){
        nom_completo_jefe_serv = this.service.projectManagment.person_name;
      }

      var descr_contr = "";
      if (row.contract.service != null){
        descr_contr = row.contract.service;
      }


      var dia_inicio = "";
      var mes_inicio = "";
      var year_inicio = "";
      if (row.contract.start_date != null){
        var fecha_inicio = new Date(row.contract.start_date);
        dia_inicio = fecha_inicio.getDate();
        mes_inicio = nombresMeses[fecha_inicio.getMonth()];
        year_inicio = fecha_inicio.getFullYear();
      }


      var dia_termino = "";
      var mes_termino = "";
      var year_termino = "";
      if (row.contract.end_date != null){
        var fecha_inicio = new Date(row.contract.end_date);
        dia_termino = fecha_inicio.getDate();
        mes_termino = nombresMeses[fecha_inicio.getMonth()];
        year_termino = fecha_inicio.getFullYear();
      }

      var total_amount = "";
      if (row.contract.total_amount != null){
        total_amount = row.contract.total_amount.toLocaleString('es-ES').toString();
      }

      var tipo_monto = "";
      if (row.contract.sdt_payment_type != null){
        tipo_monto = row.contract.sdt_payment_type.name;
      }






      //Se procesa la plantilla
      const data = {
        unidad_mayor: unidad_mayor,
        unidad_menor: unidad_menor,
        code: code,
        mes: mes,
        year: year,
        nom_completo: nom_completo,
        run: run,
        prof:prof,
        domicilio : dom,
        nom_serv:nom_serv,
        nom_completo_jefe_serv:nom_completo_jefe_serv,
        descr_contr: descr_contr,
        dia_inicio: dia_inicio,
        mes_inicio: mes_inicio,
        year_inicio: year_inicio,
        dia_termino: dia_termino,
        mes_termino: mes_termino,
        year_termino: year_termino,
        monto_global: total_amount,
        tipo_monto: tipo_monto,
      };
      console.log(data);

      //Se procesa el documento para generar el archivo
      const handler = new TemplateHandler();
      const doc = await handler.process(templateFile, data);

      //Se genera el word para guardar
      saveAs(doc, "formulario-contrato-prestaciones-servicios-profesionales.docx");
      this.isLoading = false
    },
  }

}
</script>
<style scoped>
.icon-button-container {
  display: flex;
  align-items: center;
}

.custom-icon-button {
  padding: 0; /* Elimina el padding del botón */
  border: none; /* Elimina el borde del botón */
}

.custom-icon {
  margin: 0 5px; /* Ajusta el espaciado entre los iconos según tus preferencias */
}

.with-border {
  border: 1px solid black; /* Añade un borde negro al botón */
}
.with-border:hover {
  border: 1px solid black; /* Mantén el borde negro al pasar el mouse sobre el botón */
}


</style>