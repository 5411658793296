<template>
  <section>
    <div class="modal-card" style="width: 1300px">
      <header class="modal-card-head">
        <p class="modal-card-title">Nombramientos en conflicto - {{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column">
            <b-table
                :data="person_units_warning"
                :paginated= true
                per-page="10"
                :current-page="1"
                :pagination-simple=false
                pagination-position="bottom"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="product.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual"
                scrollable
                :sticky-header=false>
              <b-table-column
                  field="category_name"
                  label="Categoria"
                  sortable
                  searchable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.category_name ? props.row.category_name : ""}}
                </template>
              </b-table-column>

              <b-table-column
                  field="unit_name"
                  label="Unidad"
                  sortable
                  searchable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                    {{props.row.unit_name ? props.row.unit_name: ""}}
                </template>
              </b-table-column>
              <b-table-column
                  field="nombramiento.start_date"
                  label="Fecha Desde"
                  sortable
                  searchable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{ props.row.nombramiento.start_date._isValid ? new Date(props.row.nombramiento.start_date).toLocaleDateString("es-CL") : ''}}
                </template>
              </b-table-column>
              <b-table-column
                  field="nombramiento.end_date"
                  label="Fecha Hasta"
                  sortable
                  searchable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{ props.row.nombramiento.end_date._isValid ? new Date(props.row.nombramiento.end_date).toLocaleDateString("es-CL") : ''}}
                </template>
              </b-table-column>
              <b-table-column
                  field="list_of_subjects"
                  label="Asignaturas"
                  searchable
                  sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  <ul>
                    <li v-for="item in props.row.list_of_subjects">
                                  <span class="tag is-primary">
                                      {{ item.name }}
                                  </span>
                    </li>
                  </ul>
                </template>
              </b-table-column>
              <b-table-column
                  field="list_of_subjects"
                  label="Horas">
                <template
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  <ul>
                    <li v-for="item in props.row.list_of_subjects">
                                    <span class="tag is-primary">
                                      {{ item.hours }}
                                    </span>
                    </li>
                  </ul>
                </template>
              </b-table-column>
              <b-table-column
                  field="list_of_subjects"
                  label="Código Siac">
                <template
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  <ul>
                    <li v-for="item in props.row.list_of_subjects">
                                    <span class="tag is-primary">
                                      {{ item.siac_code ? item.siac_code:'-' }}
                                    </span>
                    </li>
                  </ul>
                </template>
              </b-table-column>
              <b-table-column
                  field="request"
                  label="Origen Nombramiento"
                  sortable
                  searchable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.nombramiento.request_id == request_id  ? "SOLICITUD ACTUAL": props.row.request}}
                </template>
              </b-table-column>
              <b-table-column
                  field="estado_request"
                  label="Estado Solicitud"
                  sortable
                  searchable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.estado_request ? props.row.estado_request: ""}}
                </template>
              </b-table-column>
            </b-table>
          </div>
        </div>
      </section>
    </div>
    <footer class="modal-card-foot actions" style="width: 1300px">
      <div class="field is-grouped is-pulled-right">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
      </div>
    </footer>
  </section>
</template>


<script>
import moment from "moment";

export default {
  name: "modal_warning_person_units",
  props:['person_units_warning','modalHeader', "request_id"],
  data(){
    return{
      defaultOpenedDetails: [1],
      showDetailIcon: true,
    }
  },

  created(){
    this.initialize()
  },
  methods :{
    initialize(){
      this.person_units_warning = this.person_units_warning.map(personUnit => {
        var newPersonUnit = personUnit
        newPersonUnit["nombramiento"]["start_date"] = moment(newPersonUnit["nombramiento"]["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
        newPersonUnit["nombramiento"]["end_date"] = moment(newPersonUnit["nombramiento"]["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
        return newPersonUnit;
      })
    }
  }
}

</script>


<style scoped>

</style>