import { render, staticRenderFns } from "./AdministrativePermitsTable.vue?vue&type=template&id=25c767ab&scoped=true"
import script from "./AdministrativePermitsTable.vue?vue&type=script&lang=js"
export * from "./AdministrativePermitsTable.vue?vue&type=script&lang=js"
import style0 from "./AdministrativePermitsTable.vue?vue&type=style&index=0&id=25c767ab&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25c767ab",
  null
  
)

export default component.exports