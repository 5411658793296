<template>
    <div class="configurationactivityinfo">
        <br>
        <br>
        <div class="columns">
            <div class="column">
                <h1 class="subtitle is-2">
                    Tipos de actividades
                </h1>
            </div>
            <div class="column">
                <span class="is-pulled-right">
                    <button class="button is-primary" @click="editActivityTypes()">
                        <b-icon icon="edit"></b-icon>
                        <span>Editar los tipos de actividades</span>
                    </button>
                    <a class="button is-primary" href="/vime_activity_types.xlsx">
                        <b-icon icon="file-excel"></b-icon>
                        <span>Descargar en excel</span>
                    </a>
                </span>
            </div>
        </div>
        <div v-if="isActivityTypesEmpty">
            <div class="columns is-centered">
                <br>
                <b-message title='Tipos de actividades no encontrados' has-icon type="is-warning" size="is-medium" :closable='false'>
                    No hay registros sobre los tipos de actividades existentes, agregue nuevos tipos de actividades haciendo click <a @click='editActivityTypes()'>aquí</a>.               
                </b-message>
                <br>
            </div>
        </div>
        <div v-else>
            <b-table
                :data="activity_types"
                :paginated=true
                per-page=10
                striped
                hoverable
                :current-page="currentPage"
                :pagination-simple=false
                pagination-position="bottom"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="publication.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
                <b-table-column
                    field= 'name'
                    label= 'Nombre'
                    v-slot="props"
                >
                {{ props.row.name }}
                </b-table-column>
                <b-table-column
                    field= 'description'
                    label= 'Descripción'
                    v-slot="props"
                >
                {{ props.row.description }}
                </b-table-column>
            </b-table>
        </div>
        <br>
        <br>
        <br>
        <div class="columns">
            <div class="column">
                <h1 class="subtitle is-2">
                    Tipos de participantes
                </h1>
            </div>
            <div class="column">
                <span class="is-pulled-right">
                    <button class="button is-primary" @click="editActivityAssistantTypes()">
                        <b-icon icon="edit"></b-icon>
                        <span>Editar los tipos de participantes</span>
                    </button>
                    <a class="button is-primary" href="/vime_assistant_types.xlsx">
                        <b-icon icon="file-excel"></b-icon>
                        <span>Descargar en excel</span>
                    </a>
                </span>
            </div>
        </div>
        <div v-if="isActivityAssistentTypesEmpty">
            <div class="columns is-centered">
                <br>
                <b-message title='Tipos de participantes no encontrados' has-icon type="is-warning" size="is-medium" :closable='false'>
                    No hay registros sobre los tipos de asistentes a actividades existentes, agregue nuevos tipos de actividaes haciendo click <a @click="editActivityAssistantTypes()">aquí</a>.               
                </b-message>
                <br>
            </div>
        </div>
        <div v-else>
            <b-table
                :data="activity_assistant_types"
                :paginated=true
                per-page=10
                striped
                hoverable
                :current-page="currentPage"
                :pagination-simple=false
                pagination-position="bottom"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="publication.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
                <b-table-column
                    field= 'name'
                    label= 'Nombre'
                    v-slot="props"
                >
                {{ props.row.name }}
                </b-table-column>
                <b-table-column
                    label= 'Tipo participante'
                    v-slot="props"
                >
                <div v-if="props.row.assistant_type == 0">Persona</div>
                <div v-else>Entidad</div>
                </b-table-column>
                <b-table-column
                    field= 'description'
                    label= 'Descripción'
                    v-slot="props"
                >
                {{ props.row.description }}
                </b-table-column>
            </b-table>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: 'ConfigurationActivityInfo',
    props:{
        activity_types:{
            type: Array,
            default:() => []
        },
        activity_assistant_types:{
            type: Array,
            default:() => []
        }
    },
    data(){
        return {
            currentPage:1
        }
    },
    computed:{
        isActivityTypesEmpty(){
            return Object.entries(this.activity_types).length === 0
        },
        isActivityAssistentTypesEmpty(){
            return Object.entries(this.activity_assistant_types).length === 0
        }
    },
    methods:{
        editActivityTypes(){
            window.location.href="/administrate/vime_configuration_activity_types_edit/"
        },
        editActivityAssistantTypes(){
            window.location.href="/administrate/vime_configuration_activity_assistant_types_edit/"
        }
    }
}
</script>