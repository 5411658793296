<template>
  <section class="section">

    <div class="columns has-text-centered">
      <div class="column">
        <div class="is-size-3">Habilitación de usuarios</div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-message title="Información importante" type="is-info" aria-close-label="Close message">
          <ul>
            <li>Una persona habilitada para tener un un usuario, debe poseer un correo corporativo con extensión '@usach.cl'.</li>
            <li>Si una persona está habilitada para tener un usuario, pero no cuenta con uno creado, este se le creará al momento de pulsar el botón "Habilitar usuario".</li>
            <li>Si una persona posee una cuenta de usuario pero la casilla de "Usuario habilitado" no se encuentra marcado, es porque posee un usuario pero se encuentra deshabilitado para ingresar al sistema. </li>
          </ul>
        </b-message>
      </div>
    </div>
    <div v-if="showErrorMessage || showSuccessMessage" class="columns">
      <div class="column is-3 is-offset-9">
        <b-message auto-close v-model="showSuccessMessage" type="is-success" has-icon icon-size="is-small">
          Usuarios habilitados
        </b-message>
        <b-message auto-close v-model="showErrorMessage" type="is-danger" has-icon icon-size="is-small">
          Hubo un error en su solicitud
        </b-message>
      </div>
    </div>
    <div class="box">
      <div class="columns has-text-right">
        <div class="column ">
          <b-button @click="enableUsers" type="is-primary">Habilitar usuario</b-button>
          <b-button @click="disableUsers" type="is-danger">Deshabilitar usuario</b-button>
        </div>
      </div>

      <b-table
          :data="people"
          :paginated=true
          per-page=10
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="desc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="last_patent_state_date"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual"
          :opened-detailed="defaultOpenedDetails"
          detailed
          detail-key="id"
          detail-transition="fade"
          :show-detail-icon="showDetailIcon">
        <b-table-column
            field= 'name'
            label= 'Nombre'
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.name }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'corporate_mail'
            label= 'Correo corporativo'
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.corporate_mail }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'can_create_user'
            label="Habilitado para tener usuario"
            sortable>
          <template v-slot="props">
            <b-checkbox disabled :value="props.row.can_create_user">
            </b-checkbox>
          </template>
        </b-table-column>
        <b-table-column
            field= 'has_user'
            label= 'Posee usuario'
            sortable>
          <template v-slot="props">
            <b-checkbox disabled :value="props.row.has_user">
            </b-checkbox>
          </template>
        </b-table-column>
        <b-table-column
            field= 'user_enabled'
            label= 'Usuario habilitado'
            sortable>
          <template v-slot="props">
            <b-checkbox disabled :value="props.row.user_enabled">
            </b-checkbox>
          </template>
        </b-table-column>
        <b-table-column label= 'Seleccionar usuario'>
          <template v-slot="props">
            <b-checkbox :disabled="!props.row.can_create_user"
                        v-model="props.row.selected">
            </b-checkbox>
          </template>
        </b-table-column>

        <template #detail="props">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <p>
                <div class="columns">
                  <div class="column">
                    <strong>Roles Activos: </strong>
                  </div>
                </div>

                <div class="columns" v-for="role of props.row.roles">

                  <div class="column is-narrow">
                    <p>{{role.internal_position_name}}</p>
                  </div>

                </div>

                </span>
                </p>
              </div>
            </div>
          </article>
        </template>

      </b-table>

    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>

</template>

<script>
import axios from "axios";
import { ToastProgrammatic as Toast } from 'buefy'
export default {
  name: "user_creation",
  props:[

  ],
  data(){
    return{
      people:[],
      person:null,
      profiles:[],
      profile:null,
      isLoading:false,
      currentPage:1,
      successToast:null,
      showSuccessMessage:false,
      showErrorMessage:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      useTransition: false,
      isShowDistanceModalActive: false,
    }
  },
  created(){
    this.getUsers();
  },
  methods:{
    enableUsers(){
      let vm = this;
      vm.isLoading=true;
      let data = {};
      data.people = vm.people.filter(person => person.selected==true);
      axios
        .post("/permissions/enable-users",data)
        .then(async function(response){
          await vm.getUsers();
          vm.isLoading = false;
          vm.showSuccessMessage = true;
        })
        .catch(error => {
          vm.isLoading=false;
          vm.showErrorMessage =true;
        })

    },
    disableUsers(){
      let vm = this;
      vm.isLoading=true;
      let data = {};
      data.people = vm.people.filter(person => person.selected==true);
      axios
        .post("/permissions/disable-users",data)
        .then(async function(response){
          await vm.getUsers();
          vm.showSuccessMessage = true;
          vm.isLoading=false;
        })
        .catch(error => {
          vm.isLoading=false;
          vm.showErrorMessage =true;
        })
    },
    getUsers(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/permissions/user-creation.json")
        .then(res => {
          vm.people = res.data.people;
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          vm.showErrorMessage =true;
          //console.log(e);
        });

    }

  },
  computed:{

  }
}
</script>

<style scoped>

</style>