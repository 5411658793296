<template>
  <section>
    <b-modal width="90%" v-model="activeModal"
             aria-modal
             :can-cancel="canCancel"
             :on-cancel="closeModal">
      <div class="modal-card" style="width:100%">
        <header class="modal-card-head">
          <p class="modal-card-title">{{modalHeader}}</p>
          <button
              type="button"
              class="delete"
              @click="closeModal"/>
        </header>
        <section class="modal-card-body" ref="modalPersonForm" style="width:100%">
          <div class="columns">
            <div class="column is-12">
                  <!-- Box de Formulario Project -->
              <div class="box">

                <div class="columns">
                  <div class="column">
                    <b-field label="Monto">
                        <b-input :has-counter="false" maxlength="14" v-model="financing.amount"></b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Año*">
                        <b-input v-model="financing.year"></b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Tipo de aporte">
                      <b-input readonly :value="financing.financing_type"></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Tipo de entidad">
                      <b-input readonly :value="financing.selected_entity_type ? financing.selected_entity_type.name : ''"></b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Entidad">
                      <b-input readonly :value="financing.selected_entity ? financing.selected_entity.name : ''"></b-input>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Programa de postulación">
                      <b-input readonly :value="financing.program ? financing.program.name : ''"></b-input>
                    </b-field>
                  </div>

                </div>

                <div class="actions has-text-right">
                  <button class="button is-danger" icon-left="trash" @click="closeModal">Cerrar</button>
                </div>

              </div>


            </div>
          </div>
        </section>
      </div>
    </b-modal>
  </section>

</template>

<script>
export default {
  name: "ShowPostulatedFinancing",
  props:['financing','modalHeader','activeModal'],
  data() {
    return {
      canCancel: ['escape'],
      destroyOnHide:true,
    }
  },
  methods:{
    closeModal(){
      this.$emit('close');
    },
  }
}
</script>

<style scoped>

</style>