<template>
  <section>
    <projects-table v-bind:proyectos="this.proyectos" v-bind:unit-id="objectId" :hide-update="true" :cargo-principal="cargoPrincipal" @updateProjects="updateProjects"></projects-table>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import axios from "axios";
import moment from "moment";
import ProjectsTable from "../projects/projects_table.vue";
export default {
  props:[
    'objectId','componentType','cargoPrincipal', 'currentYear', 'minYear','maxYear', 'changeFilter', 'hideUpdate'
  ],
  data(){
    return{
      proyectos:[],
      isLoading:false,
      year: new Date().getFullYear(),
    }
  },
  components:{
    ProjectsTable
  },
  created(){
    this.getProjects();
  },
  methods:{
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    getProjects(){
      this.isLoading=true;
      var url = "";
      if (this.componentType == "person"){
        url = "/people/"+this.objectId+"/projects.json"
      }
      else if (this.componentType=="unit"){
        url = "/admin/units/"+this.objectId+"/projects.json"
      }
      if(this.hideUpdate == true){
        this.year = 'MyActivity'
      }
      axios
        .get(url,{params:{
            year_f: this.year,
            minYear: this.minYear,
            maxYear: this.maxYear
        }})
        .then(res => {
          this.isLoading=false;
          this.proyectos = res.data;
          this.proyectos = this.proyectos.map(proyecto => {
            var proyecto = proyecto
            proyecto["start_date"] = moment(proyecto.start_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            proyecto["end_date"] = moment(proyecto.end_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            return proyecto;
          });
        })
        .catch(e => {
          console.log(e);
          this.isLoading=false;
        });
    },
    updateProjects(year){
      this.year = year
      this.getProjects();
    }
  },
  computed:{

  },
  watch:{
    changeFilter: function(){
      this.getProjects()
    }
  }
}
</script>

