<template>
    <section>
        <div :id="'pie_' + title" style="width: 100%; height: 500px;" />
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </section>
</template>

<script>
import * as echarts from 'echarts'
export default {

    props: [
        'types', 'title',
    ],

    data() {
        return {
            isLoading: false,
            colorPalette: ['#00A499', '#EA7600', '#394049', '#8C4799', '#498BCA', '#EAAA00', '#C8102E'],
            option: {
                tooltip: {
                    trigger: 'item',
                },
                legend: {
                    right: '0%',
                    top: 'center',
                    orient: 'vertical',
                    data: [],
                    selected: {},
                },
                toolbox: {
                    show: true,
                    feature: {
                        mark: { show: true },
                        dataView: {
                            show: true,
                            readOnly: true,
                            title: 'Datos',
                            lang: ["Datos", "Cerrar", "Actualizar"],
                            optionToContent: function (opt) {
                                var series = opt.series[0].data
                                var table = '<table class="echarts-dataview-table">'
                                table += '<thead><tr><th>Nombres</th><th>Cantidad</th></tr></thead>'
                                table += '<tbody>'

                                series.forEach((element) => {
                                    var value = element.value == 0 ? element.value : '<b>' + element.value + '</b>'
                                    table += '<tr>'
                                        + '<td style="text-align: left; border: 1px solid #ccc; ">' + element.name + '</td>'
                                        + '<td style="text-align: center; border: 1px solid #ccc">' + value + '</td>'
                                        + '</tr>';
                                })
                                table += '</tbody></table>'
                                return table
                            }
                        },
                        saveAsImage: {
                            show: true,
                            pixelRatio: 3,
                            title: "Descargar",
                            name: 'indicator_by_type',
                        },
                    }
                },
                series: [
                    {
                        type: 'pie',
                        radius: '50%',
                        labelLine: {
                            length: 30
                        },
                        data: [],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                        label: {
                            show: true,
                            formatter(param) {
                                const formatter = new Intl.NumberFormat('es-ES')
                                return param.name + ': ' + formatter.format(param.value) + ' (' + param.percent + '%)';
                            }
                        },
                    }
                ]
            }
        }
    },

    async mounted() {
        this.$nextTick(() => {
            this.initECharts()
        })
    },

    methods: {
        initECharts() {
            this.isLoading = true

            this.option.series[0].data = this.types
            this.option.series[0].color = this.colorPalette

            this.option.legend.data = []
            this.option.legend.selected = {}

            this.types.forEach(type => {
                this.option.legend.data.push(type.name)
                this.option.legend.selected[type.name] = type.value !== 0
            })

            // based on prepared DOM, initialize echarts instance
            this.dom = echarts.init(document.getElementById('pie_' + this.title))
            this.dom.setOption(this.option)
            new ResizeObserver(() => this.dom.resize()).observe(document.getElementById('pie_' + this.title))

            this.isLoading = false
        },
    },

    watch: {
        types() {
            this.initECharts()
            deep: true
        },
    },
}
</script>