<template>
    <section>
        <div class="hero is-white is-small">
            <div class="hero-body">
                <div class="container">
                    <h1 class="title">
                        Reportería {{ this.unitName }}
                    </h1>

                    <ValidationObserver ref="observer" 
                        class="columns is-centered is-multiline" v-slot="{ invalid }">

                        <div class="column">
                            <b-field label="Año desde:" >

                                <multiselect v-model="minYear" :options="years" :searchable="true"
                                    :close-on-select="true" placeholder="Elija un año" :allow-empty="false"
                                    selectLabel="Presiene para seleccionar" selectedLabel="Seleccionado"
                                    deselectLabel="Presione para deseleccionar" />

                            </b-field>
                        </div>

                        <div class="column">
                            <b-field label="Año hasta:" :type="{ 'is-danger': verifyYears }"
                                :message="{ 'Año hasta no puede ser menor al Año desde': verifyYears }">

                                <multiselect v-model="maxYear" :options="years" :searchable="true"
                                    :close-on-select="true" placeholder="Elija un año" :allow-empty="false"
                                    selectLabel="Presiene para seleccionar" selectedLabel="Seleccionado"
                                    deselectLabel="Presione para deseleccionar" />

                            </b-field>
                        </div>

                        <div v-show="fromFaculty"  class="column">
                            <b-field label="Seleccione una Unidad">
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <multiselect 
                                        v-model="selectedUnit" :options="optionDepartaments" label="name" track-by="id"
                                        placeholder="Seleccione Unidad" selectLabel="Presione para seleccionar"
                                        selectedLabel="Seleccionado" deselectLabel="Presione para deseleccionar"
                                        :multiple="false" :allow-empty="false">
                                        <template v-slot:noOptions>
                                            No existen datos
                                        </template>
                                        <span slot="noResult">
                                            No se encontraron elementos.
                                        </span>
                                    </multiselect>
                                    <span class="validation-alert">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </b-field>
                        </div>

                        <div class="column">
                            <br>
                            <b-field label=" ">
                                <b-button type="is-info" label="Aplicar Filtro" native-type="submit"
                                    @click="checkFilters()" />
                            </b-field>
                        </div>

                    </ValidationObserver>

                    <div class="columns">
                        
                    </div>

                </div>
            </div>
        </div>

        <div v-show="showTabs" class="container mt-6">

            <div class="box">

                <b-tabs position="is-centered" v-model="currentTab" :multiline="true">
                    <b-tab-item v-for="item in items" v-bind:key="item.content" :value="item.content" :label="item.tab"
                        :icon="item.icon" />
                </b-tabs>
                <!-- COMPONENTE AQUI -->
                <keep-alive>
                    <component v-bind:is="currentTab" :minYear="minYear" :maxYear="maxYear" 
                        :unitId="unitIdToChildren" :changeFilter="changeFilter" @updatedData="updatedData" />
                </keep-alive>

            </div>

        </div>

        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

    </section>
</template>

<script>
import UnitIndicatorPublicationTab from './unit_indicator_publicaton_tab.vue'
import UnitIndicatorPublicationBook from './unit_indicator_publication_book_tab.vue'
import UnitIndicatorProject from './unit_indicator_project_tab.vue'
import UnitIndicatorPatent from './unit_indicator_patent_tab.vue'
import UnitIndicatorAcademic from './unit_indicator_academic_tab.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
export default {

    name: 'IndicatorByUnit',

    components: {
        UnitIndicatorPublicationTab, UnitIndicatorPublicationBook, UnitIndicatorProject, UnitIndicatorPatent, UnitIndicatorAcademic,
    },

    props: [
        'userId', 'cargoPrincipal', 'unitName', 'unitIndicators', 'unitId', 'fromFaculty', 'departaments'
    ],

    data() {
        return {
            minYear: new Date().getFullYear() - 5,
            maxYear: new Date().getFullYear(),
            verifyYears: false,
            years: Array.from({length:(new Date().getFullYear()-2000+1)},(v,k)=>k+2000).reverse(),
            isLoading: false,
            currentTab: 'UnitIndicatorPublicationTab',
            selectedUnit: null,
            showTabs: false,
            optionDepartaments: [],
            items: [
                { tab: 'Publicaciones en Revista', content: 'UnitIndicatorPublicationTab', icon: "newspaper" },
                { tab: 'Publicaciones en Libro', content: 'UnitIndicatorPublicationBook', icon: "book" },
                { tab: 'Proyectos', content: 'UnitIndicatorProject', icon: "folder-open" },
                { tab: 'Patentes', content: 'UnitIndicatorPatent', icon: "lightbulb" },
                { tab: 'Cuerpo Académico', content: 'UnitIndicatorAcademic', icon: "user-graduate" },
            ],
            changeFilter: false,
            unitIdToChildren: this.unitId,
        }
    },

    async created() {
        
        if (!this.fromFaculty) {
            this.changeFilter = !this.changeFilter
            this.isLoading = true
        } else {
            this.optionDepartaments = this.departaments
        }

    },

    methods: {

        async checkFilters(){

            const valid = await this.$refs.observer.validate()

            if (!valid && this.fromFaculty) {

                this.$buefy.dialog.alert({
                    title: 'Error',
                    message: 'Porfavor seleccione una Unidad.',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
                return
            }

            if ((this.minYear <= this.maxYear) || (this.minYear == null) || (this.maxYear == null)) {

                this.verifyYears = false

                if (this.fromFaculty) {
                    this.unitIdToChildren = this.selectedUnit.id
                }
                
                this.changeFilter = !this.changeFilter
                this.isLoading = true

                this.showTabs = this.fromFaculty ? false : true
            }else{
                this.verifyYears = true
            }
        },

        updatedData(){

            if (this.fromFaculty) {
                if (this.selectedUnit != null) {
                    if (this.unitId != this.selectedUnit.id) {
                        this.showTabs = true
                    }
                }
            }else{
                this.showTabs = true
            }
            

            this.isLoading = false

        }
    }

}
</script>