<template>
    <div class="configurationagreements">
        <div class="container">
            <br>
            <br>
            <div class="columns is-centered">
                <h1 class="is-size-2" style="text-align:center;" v-if="edit_case === 0" >Edición de los tipos de convenios generales</h1>
                <h1 class="is-size-2" style="text-align:center;" v-if="edit_case === 1" >Edición de los tipos de convenios específicos</h1>
                <h1 class="is-size-2" style="text-align:center;" v-if="edit_case === 2" >Edición de las etapas de un convenio</h1>
                <h1 class="is-size-2" style="text-align:center;" v-if="edit_case === 3" >Edición de los procesos de un convenio</h1>
                <h1 class="is-size-2" style="text-align:center;" v-if="edit_case === 4" >Edición de los actores de un convenio</h1>
            </div>
            <br>
            <!-- Edición tipología convenios generales -->
            <div v-if="edit_case === 0">
                <div v-if="isAgreementGeneralTypesEmpty">
                    <div class="columns is-centered">
                        <h1>
                            No hay registros sobre los convenios generales, para añadir uno nuevo haga clic <a @click="newAgreementSection(0)">aquí</a>.
                        </h1>
                    </div>
                </div>
                <div v-else>
                    <span class="is-pulled-right">
                        <button class="button is-primary" @click="newAgreementSection(0)">
                            <b-icon icon="plus"></b-icon>
                            <span>Añadir un tipo de convenio general</span>
                        </button>
                    </span>
                    <br>
                    <br>
                    <b-table
                        :data="getAgreementGeneralTypes"
                        :paginated=true
                        per-page=5
                        striped
                        hoverable
                        :current-page="currentPage"
                        :pagination-simple=false
                        pagination-position="bottom"
                        default-sort-direction="asc"
                        sort-icon="arrow-up"
                        sort-icon-size="is-small"
                        default-sort="publication.title"
                        aria-next-label="Siguiente"
                        aria-previous-label="Anterior"
                        aria-page-label="Página"
                        aria-current-label="Página actual">
                        <b-table-column
                            field= 'name'
                            label= 'Nombre'
                            v-slot="props"
                        >
                        {{ props.row.name }}
                        </b-table-column>
                        <b-table-column
                            field= 'description'
                            label= 'Descripción'
                            v-slot="props"
                        >
                        {{ props.row.description }}
                        </b-table-column>
                        <b-table-column
                            field= 'objective'
                            label= 'Objetivo'
                            v-slot="props"                            
                        >
                        {{ props.row.objective }}
                        </b-table-column>
                        <b-table-column
                            field= 'signatory'
                            label= 'Aprueba'
                            v-slot="props"
                        >
                        {{ props.row.signatory }}
                        </b-table-column>
                        <b-table-column
                            field= 'aditional_information'
                            label= 'Información adicional'
                            v-slot="props"
                        >
                        {{ props.row.aditional_information }}
                        </b-table-column>
                        <b-table-column
                            field= 'is_active'
                            label= '¿Activo?'
                            v-slot="props"
                        >
                        {{ isActive(props.row.is_active) }}
                        </b-table-column>
                        <b-table-column  v-slot="props">
                             <div class="field is-grouped is-right">
                                <p class="control"> 
                                    <button class="button is-warning" @click="editAgreementSection(5, props.row)">
                                        <b-icon icon="edit"></b-icon>
                                        <span>Editar</span>
                                    </button>
                                </p> 
                                <p class="control" v-if="props.row.is_active">
                                    <button class="button is-danger" @click="activateOrDesactiveAgreementSection(0, props.row)">
                                        <b-icon icon="times"></b-icon>
                                        <span>Desactivar</span>
                                    </button>
                                </p>
                                <p class="control" v-else>
                                    <button class="button is-primary" @click="activateOrDesactiveAgreementSection(0, props.row)">
                                        <b-icon icon="times"></b-icon>
                                        <span>Activar</span>
                                    </button>
                                </p>
                             </div>
                        </b-table-column>
                    </b-table>
                </div>
            </div>
            <!-- Edición tipología convenios específicos -->
            <div v-if="edit_case === 1">
                <div v-if="isAgreementSpecifictTypesEmpty">
                    <div class="columns is-centered">
                        <h1>
                            No hay registros sobre los tipos de convenios específicos, para añadr uno nuevo haga clic <a @click="newActivityType()">aquí</a>.
                        </h1>
                    </div>
                </div>
                <div v-else>
                    <span class="is-pulled-right">
                        <button class="button is-primary" @click="newAgreementSection(1)">
                            <b-icon icon="plus"></b-icon>
                            <span>Añadir un tipo de convenio específico</span>
                        </button>
                    </span>
                    <br>
                    <br>
                    <b-table
                        :data="getAgreementSpecifictTypes"
                        :paginated=true
                        per-page=5
                        striped
                        hoverable
                        :current-page="currentPage"
                        :pagination-simple=false
                        pagination-position="bottom"
                        default-sort-direction="asc"
                        sort-icon="arrow-up"
                        sort-icon-size="is-small"
                        default-sort="publication.title"
                        aria-next-label="Siguiente"
                        aria-previous-label="Anterior"
                        aria-page-label="Página"
                        aria-current-label="Página actual">
                        <b-table-column
                            field= 'name'
                            label= 'Nombre'
                            v-slot="props"
                        >
                        {{ props.row.name }}
                        </b-table-column>
                        <b-table-column
                            field= 'description'
                            label= 'Descripción'
                            v-slot="props"
                        >
                        {{ props.row.description }}
                        </b-table-column>
                        <b-table-column
                            field= 'is_active'
                            label= '¿Activo?'
                            v-slot="props"
                        >
                        {{ isActive(props.row.is_active) }}
                        </b-table-column>
                        <b-table-column  v-slot="props">
                             <div class="buttons is-right">
                                <p class="control"> 
                                    <button class="button is-warning" @click="editAgreementSection(6, props.row)">
                                        <b-icon icon="edit"></b-icon>
                                        <span>Editar</span>
                                    </button>
                                </p> 
                                <p class="control" v-if="props.row.is_active">
                                    <button class="button is-danger" @click="activateOrDesactiveAgreementSection(1, props.row)">
                                        <b-icon icon="times"></b-icon>
                                        <span>Desactivar</span>
                                    </button>
                                </p>
                                <p class="control" v-else>
                                    <button class="button is-primary" @click="activateOrDesactiveAgreementSection(1, props.row)">
                                        <b-icon icon="times"></b-icon>
                                        <span>Activar</span>
                                    </button>
                                </p>
                            </div>
                        </b-table-column>
                    </b-table>
                </div>
            </div>
            <!-- Edición etapas de un convenio -->
            <div v-if="edit_case === 2">
                <div v-if="isAgreementStagesEmpty">
                    <div class="columns is-centered">
                        <h1>
                            No hay registros sobre las etapas de un convenio, para añadir una nueva haga clic <a @click="newActivityType()">aquí</a>.
                        </h1>
                    </div>
                </div>
                <div v-else>
                    <span class="is-pulled-right">
                        <button class="button is-primary" @click="newAgreementSection(2)">
                            <b-icon icon="plus"></b-icon>
                            <span>Añadir una nueva etapa</span>
                        </button>
                    </span>
                    <br>
                    <br>
                    <b-table
                        :data="getAgreementStages"
                        :paginated=true
                        per-page=5
                        striped
                        hoverable
                        :current-page="currentPage"
                        :pagination-simple=false
                        pagination-position="bottom"
                        default-sort-direction="asc"
                        sort-icon="arrow-up"
                        sort-icon-size="is-small"
                        default-sort="publication.title"
                        aria-next-label="Siguiente"
                        aria-previous-label="Anterior"
                        aria-page-label="Página"
                        aria-current-label="Página actual">
                        <b-table-column
                            field= 'name'
                            label= 'Nombre'
                            v-slot="props"
                        >
                        {{ props.row.name }}
                        </b-table-column>
                        <b-table-column
                            field= 'description'
                            label= 'Descripción'
                            v-slot="props"
                        >
                        {{ props.row.description }}
                        </b-table-column>
                        <b-table-column
                            field= 'is_active'
                            label= '¿Activo?'
                            v-slot="props"
                        >
                        {{ isActive(props.row.is_active) }}
                        </b-table-column>
                        <b-table-column  v-slot="props">
                             <div class="field is-grouped is-right">
                                <p class="control"> 
                                    <button class="button is-warning" @click="editAgreementSection(7, props.row)">
                                        <b-icon icon="edit"></b-icon>
                                        <span>Editar</span>
                                    </button>
                                </p> 
                                <p class="control" v-if="props.row.is_active">
                                    <button class="button is-danger" @click="activateOrDesactiveAgreementSection(2, props.row)">
                                        <b-icon icon="times"></b-icon>
                                        <span>Desactivar</span>
                                    </button>
                                </p>
                                <p class="control" v-else>
                                    <button class="button is-primary" @click="activateOrDesactiveAgreementSection(2, props.row)">
                                        <b-icon icon="times"></b-icon>
                                        <span>Activar</span>
                                    </button>
                                </p>
                             </div>
                        </b-table-column>
                    </b-table>
                </div>
            </div>
            <!-- Edición procesos de un convenio -->
            <div v-if="edit_case === 3">
                <div v-if="isAgreementProcessesEmpty">
                    <div class="columns is-centered">
                        <h1>
                            No hay registros sobre los procesos de un convenio, para añadir uno nuevo haga clic <a @click="newActivityType()">aquí</a>.
                        </h1>
                    </div>
                </div>
                <div v-else>
                    <span class="is-pulled-right">
                        <button class="button is-primary" @click="newAgreementSection(3)">
                            <b-icon icon="plus"></b-icon>
                            <span>Añadir un nuevo proceso</span>
                        </button>
                    </span>
                    <br>
                    <br>
                    <b-table
                        :data="getAgreementProcesses"
                        :paginated=true
                        per-page=5
                        striped
                        hoverable
                        :current-page="currentPage"
                        :pagination-simple=false
                        pagination-position="bottom"
                        default-sort-direction="asc"
                        sort-icon="arrow-up"
                        sort-icon-size="is-small"
                        default-sort="publication.title"
                        aria-next-label="Siguiente"
                        aria-previous-label="Anterior"
                        aria-page-label="Página"
                        aria-current-label="Página actual">
                        <b-table-column
                            field= 'name'
                            label= 'Nombre'
                            v-slot="props"
                        >
                        {{ props.row.name }}
                        </b-table-column>
                        <b-table-column
                            field= 'description'
                            label= 'Descripción'
                            v-slot="props"
                        >
                        {{ props.row.description }}
                        </b-table-column>
                        <b-table-column
                            field= 'associated_documents'
                            label= 'Documentos asociados'
                            v-slot="props"
                        >
                        {{ props.row.associated_documents }}
                        </b-table-column>
                        <b-table-column
                            field= 'agreement_stage'
                            label= 'Etapa asociada'
                            v-slot="props"
                        >
                        {{ props.row.agreement_stage }}
                        </b-table-column>
                        <b-table-column
                            field= 'is_active'
                            label= '¿Activo?'
                            v-slot="props"
                        >
                        {{ isActive(props.row.is_active) }}
                        </b-table-column>
                        <b-table-column  v-slot="props">
                             <div class="field is-grouped is-right">
                                <p class="control"> 
                                    <button class="button is-warning" @click="editAgreementSection(8, props.row)">
                                        <b-icon icon="edit"></b-icon>
                                        <span>Editar</span>
                                    </button>
                                </p> 
                                <p class="control" v-if="props.row.is_active">
                                    <button class="button is-danger" @click="activateOrDesactiveAgreementSection(3, props.row)">
                                        <b-icon icon="times"></b-icon>
                                        <span>Desactivar</span>
                                    </button>
                                </p>
                                <p class="control" v-else>
                                    <button class="button is-primary" @click="activateOrDesactiveAgreementSection(3, props.row)">
                                        <b-icon icon="times"></b-icon>
                                        <span>Activar</span>
                                    </button>
                                </p>
                             </div>
                        </b-table-column>
                    </b-table>
                </div>
            </div>
            <!-- Edición actores de un convenio -->
            <div v-if="edit_case === 4">
                <div v-if="isAgreementActorsEmpty">
                    <div class="columns is-centered">
                        <h1>
                            No hay registros sobre los actores de un convenio, para añadir uno nuevo haga clic <a @click="newActivityType()">aquí</a>.
                        </h1>
                    </div>
                </div>
                <div v-else>
                    <span class="is-pulled-right">
                        <button class="button is-primary" @click="newAgreementSection(4)">
                            <b-icon icon="plus"></b-icon>
                            <span>Añadir un nuevo actor</span>
                        </button>
                    </span>
                    <br>
                    <br>
                    <b-table
                        :data="getAgreementActors"
                        :paginated=true
                        per-page=5
                        striped
                        hoverable
                        :current-page="currentPage"
                        :pagination-simple=false
                        pagination-position="bottom"
                        default-sort-direction="asc"
                        sort-icon="arrow-up"
                        sort-icon-size="is-small"
                        default-sort="publication.title"
                        aria-next-label="Siguiente"
                        aria-previous-label="Anterior"
                        aria-page-label="Página"
                        aria-current-label="Página actual">
                        <b-table-column
                            field= 'name'
                            label= 'Nombre'
                            v-slot="props"
                        >
                        {{ props.row.name }}
                        </b-table-column>
                        <b-table-column
                            field= 'actor_type'
                            label= 'Tipo de actor'
                            v-slot="props"
                        >
                        {{ props.row.actor_type }}
                        </b-table-column>
                        <b-table-column
                            field= 'is_active'
                            label= '¿Activo?'
                            v-slot="props"
                        >
                        {{ isActive(props.row.is_active) }}
                        </b-table-column>
                        <b-table-column  v-slot="props">
                             <div class="buttons is-right">
                                <p class="control"> 
                                    <button class="button is-warning" @click="editAgreementSection(9, props.row)">
                                        <b-icon icon="edit"></b-icon>
                                        <span>Editar</span>
                                    </button>
                                </p> 
                                <p class="control" v-if="props.row.is_active">
                                    <button class="button is-danger" @click="activateOrDesactiveAgreementSection(4, props.row)">
                                        <b-icon icon="times"></b-icon>
                                        <span>Desactivar</span>
                                    </button>
                                </p>
                                <p class="control" v-else>
                                    <button class="button is-primary" @click="activateOrDesactiveAgreementSection(4, props.row)">
                                        <b-icon icon="times"></b-icon>
                                        <span>Activar</span>
                                    </button>
                                </p>
                             </div>
                        </b-table-column>
                    </b-table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import ConfigurationAgreementEditSectionModal from '../edit/configuration_agreements_edit_section_modal.vue'
export default {
    name: 'ConfigurationAgreements',
    props:{
        edit_case:{
            type: Number
        }
    },
    data(){
        return {
            activity_types: [],
            activity_assistant_types: [],
            agreement_general_types: [],
            agreement_specifict_types: [],
            agreement_stages: [],
            agreement_processes: [],
            agreement_actors: [],
            currentPage: 1
        }
    },
    computed:{
        // get
        getAgreementGeneralTypes(){
            return this.agreement_general_types
        },
        getAgreementSpecifictTypes(){
            return this.agreement_specifict_types
        },
        getAgreementStages(){
            return this.agreement_stages
        },
        getAgreementProcesses(){
            return this.agreement_processes
        },
        getAgreementActors(){
            return this.agreement_actors
        },
        // Validadores
        isAgreementGeneralTypesEmpty(){
            return Object.entries(this.agreement_general_types).length === 0
        },
        isAgreementSpecifictTypesEmpty(){
            return Object.entries(this.agreement_specifict_types).length === 0
        },
        isAgreementStagesEmpty(){
            return Object.entries(this.agreement_stages).length === 0
        },
        isAgreementProcessesEmpty(){
            return Object.entries(this.agreement_processes).length === 0
        },
        isAgreementActorsEmpty(){
            return Object.entries(this.agreement_actors).length === 0
        }
    },
    beforeMount(){
        switch(this.edit_case){
            case 0:
                this.getVimeAgreementGeneralTypes()
                break;
            case 1:
                this.getVimeAgreementSpecifictTypes()
                break;
            case 2:
                this.getVimeAgreementStages()
                break;
            case 3:
                this.getVimeAgreementProcesses()
                break;
            default:
                this.getVimeAgreementActors()
        }
    },
    methods:{
        // Consultas al backend
        getVimeAgreementGeneralTypes(){
            axios.get('/agreement_general_types.json')
            .then(response =>{
                this.agreement_general_types = response.data
            })
            .catch(e => console.log(e))
        },
        getVimeAgreementSpecifictTypes(){
            axios.get('/agreement_specifict_types.json')
            .then(response =>{
                this.agreement_specifict_types = response.data
            })
            .catch(e => console.log(e))
        },
        getVimeAgreementStages(){
            axios.get('/agreement_stages.json')
            .then(response =>{
                this.agreement_stages = response.data
            })
            .catch(e => console.log(e))
        },
        getVimeAgreementProcesses(){
            axios.get('/agreement_processes.json')
            .then(response =>{
                this.agreement_processes = response.data
            })
            .catch(e => console.log(e))
        },
        getVimeAgreementActors(){
            axios.get('/agreement_actors.json')
            .then(response =>{
                this.agreement_actors = response.data
            })
            .catch(e => console.log(e))
        },
        // Añadir una sección
        newAgreementSection(agreement_section){
            this.$buefy.modal.open({
                parent: this,
                component: ConfigurationAgreementEditSectionModal,
                hasModalCard: true,
                props:{
                    'edit_case': agreement_section,
                },
                events:{
                    'add-new-agreement-section': this.addNewAgreementSection
                },
                customClass: 'is-primary',
                trapFocus: true
            })
        },
        addNewAgreementSection(agreement_section){
            console.log(agreement_section)
            switch(agreement_section.edit_case){
                case 0:
                    axios.post('/agreement_general_types.json', agreement_section.data)
                    .then( response =>{
                        console.log(response.data)
                        this.getAgreementGeneralTypes.push(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })
                    break;
                case 1:
                    axios.post('/agreement_specifict_types.json', agreement_section.data)
                    .then( response =>{
                        console.log(response.data)
                        this.getAgreementSpecifictTypes.push(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })
                    break;
                case 2:
                    axios.post('/agreement_stages.json', agreement_section.data)
                    .then( response =>{
                        console.log(response.data)
                        this.getAgreementStages.push(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })
                    break;
                case 3:
                    axios.post('/agreement_processes.json', agreement_section.data)
                    .then( response =>{
                        console.log(response.data)
                        this.getAgreementProcesses.push(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })
                    break;
                default:
                    axios.post('/agreement_actors.json', agreement_section.data)
                    .then( response =>{
                        console.log(response.data)
                        this.getAgreementActors.push(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            }
        },
        // Editar una sección
        editAgreementSection(agreement_section, agreement_data_section){
            this.$buefy.modal.open({
                parent: this,
                component: ConfigurationAgreementEditSectionModal,
                hasModalCard: true,
                props:{
                    'edit_case': agreement_section,
                    'agreement_data_section': agreement_data_section
                },
                events:{
                    'edit-agreement-section': this.saveChangesAgreementSection,
                },
                customClass: 'is-primary',
                trapFocus: true
            })
        },
        saveChangesAgreementSection(agreement_section){
            console.log(agreement_section)
            switch(agreement_section.edit_case){
                case 5:
                    axios.put('/agreement_general_types/'+agreement_section.data.id+'.json', agreement_section.data)
                    .then( response =>{
                        console.log(response.data)
                        this.getAgreementGeneralTypes.forEach(element =>{
                            if(element.id == agreement_section.data.id){
                                element.name = agreement_section.data.name
                                element.description = agreement_section.data.description
                                element.objective = agreement_section.data.objective
                                element.signatory = agreement_section.data.signatory
                                element.aditional_information = agreement_section.data.aditional_information
                            }
                        })
                    })
                    .catch(e => {
                        console.log(e)
                    })
                    break;
                case 6:
                    axios.put('/agreement_specifict_types/'+agreement_section.data.id+'.json', agreement_section.data)
                    .then( response =>{
                        console.log(response.data)
                        this.getAgreementSpecifictTypes.forEach(element =>{
                            if(element.id == agreement_section.data.id){
                                element.name = agreement_section.data.name
                                element.description = agreement_section.data.description
                            }
                        })
                    })
                    .catch(e => {
                        console.log(e)
                    })
                    break;
                case 7:
                    axios.put('/agreement_stages/'+agreement_section.data.id+'.json', agreement_section.data)
                    .then( response =>{
                        console.log(response.data)
                        this.getAgreementStages.forEach(element =>{
                            if(element.id == agreement_section.data.id){
                                element.name = agreement_section.data.name
                                element.description = agreement_section.data.description
                            }
                        })
                    })
                    .catch(e => {
                        console.log(e)
                    })
                    break;
                case 8:
                    axios.put('/agreement_processes/'+agreement_section.data.id+'.json', agreement_section.data)
                    .then( response =>{
                        console.log(response.data)
                        this.getAgreementProcesses.forEach(element =>{
                            if(element.id == agreement_section.data.id){
                                element.name = agreement_section.data.name
                                element.description = agreement_section.data.description
                                element.associated_documents = agreement_section.data.associated_documents
                                element.agreement_stage = response.data.agreement_stage
                            }
                        })
                    })
                    .catch(e => {
                        console.log(e)
                    })
                    break;
                default:
                    axios.put('/agreement_actors/'+agreement_section.data.id+'.json', agreement_section.data)
                    .then( response =>{
                        console.log(response.data)
                        this.getAgreementActors.forEach(element =>{
                            if(element.id == agreement_section.data.id){
                                element.name = agreement_section.data.name
                                element.actor_type = agreement_section.data.actor_type
                            }
                        })
                    })
                    .catch(e => {
                        console.log(e)
                    })
            }
        },
        activateOrDesactiveAgreementSection(agreement_section_case, agreement_section_data){
            var url_to_send = ''
            switch(agreement_section_case){
                case 0: // Convenios generales
                    url_to_send = '/agreement_general_types/'
                    break;
                case 1: // Convenios especíicos
                    url_to_send = '/agreement_specifict_types/'
                    break;
                case 2: // Etapas
                    url_to_send = '/agreement_stages/'
                    break;
                case 3: // Procesos
                    url_to_send = '/agreement_processes/'
                    break;
                default: // Actores
                    url_to_send = '/agreement_actors/'
                    break;
            }
            if(agreement_section_data.is_active){
                agreement_section_data.is_active = false
            }
            else{
                agreement_section_data.is_active = true
            }
            axios.put(url_to_send+agreement_section_data.id+".json", agreement_section_data)
            .then(response=>{
                switch(agreement_section_case){
                    case 0: // Convenios generales
                        this.getAgreementGeneralTypes.forEach(function(element){
                            if(element.id == response.data.id){
                                element.is_active = response.data.is_active
                            }
                        })
                        break;
                    case 1: // Convenios especíicos
                        this.getAgreementSpecifictTypes.forEach(function(element){
                            if(element.id == response.data.id){
                                element.is_active = response.data.is_active
                            }
                        })
                        break;
                    case 2: // Etapas
                        this.getAgreementStages.forEach(function(element){
                            if(element.id == response.data.id){
                                element.is_active = response.data.is_active
                            }
                        })
                        break;
                    case 3: // Procesos
                        this.getAgreementProcesses.forEach(function(element){
                            if(element.id == response.data.id){
                                element.is_active = response.data.is_active
                            }
                        })
                        break;
                    default: // Actores
                        this.getAgreementActors.forEach(function(element){
                            if(element.id == response.data.id){
                                element.is_active = response.data.is_active
                            }
                        })
                        break;
                }
            })
            .catch(e=>{
                console.log(e)
            })
        },
        isActive(data){
            if(data){
                return "Sí"
            }
            else{
                return "No"
            }
        }
    }
}
</script>