<template>
    <div class="partnerentitiesvimeshowview">
      <section>
        <div class="box">
          <div class="container">
            <h1 class="title is-3">{{ entity.alt_name }}</h1>
            <h2 class="subtitle is-5">{{ entity.alt_full_name }}</h2>
            <b-tabs position="is-centered" @input="changeTab">
              <b-tab-item v-for="item in items"
                          :value="item.content"
                          v-bind:key="item.content"
                          :label="item.tab"
                          :icon="item.icon">
              </b-tab-item>

            </b-tabs>

            <component v-bind:is="currentTabComponent"
                       :entity='this.entity'
                       :entity_id="this.entity_id"
                       :rut="this.rut"
                       :dv="this.dv"
                       :can_edit="can_edit"
                       :economic_activities='this.economic_activities'
                       :entity_types='this.entity_types'
                       :communes='this.communes'
                       :agreements='this.entity_agreements'
                       :activities='this.entity_activities'
                       :can_create_activity="this.can_create_activity"
                       :can_create_agreement="this.can_create_agreement"
                       :can_create_program="this.can_create_program"
                       :can_edit_program="this.can_edit_program"
                       :sub-entities="this.sub_entities"
                        :partner-entity-id="this.entity.id"
                        :isSubEntity = "this.isSubEntity"
                        :nameEntityMajor="this.nameEntityMajor"
                        :cargo-principal="this.cargoPrincipal"></component>

            <div class="field is-grouped is-grouped-left">
              <div class="control">
                <button class="button is-link is-light" @click="back()">Volver</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
</template>
<script>
import axios from 'axios'
import PartnerEntitiesVimeGeneralInformation from './partner_entities_vime_general_information.vue'
import PartnerEntitiesVimeAgreements from './partner_entities_vime_agreements.vue'
import PartnerEntitiesVimeActivities from './partner_entities_vime_activities.vue'
import PartnerEntitiesPrograms from './PartnerEntitiesPrograms.vue'
import AssociatedProjectsTab from './AssociatedProjectsTab.vue'
import PartnerEntityPerson from './PartnerEntitiesPerson'
import PartnerEntitySubEntity from './PartnerEntitySubEntity'
import PartnerEntityVimeTab from "./PartnerEntityVimeTab";

export default {
    name: 'PartnerEntitiesVimeShowView',
    props:["entity_id","cargoPrincipal"],
    components:{
        PartnerEntitiesVimeGeneralInformation,
        PartnerEntitiesVimeAgreements,
        PartnerEntitiesVimeActivities,
        PartnerEntitiesPrograms,
      AssociatedProjectsTab,
      PartnerEntityPerson,
      PartnerEntitySubEntity,
      PartnerEntityVimeTab
    },
    data(){
        return {
          entity: [],
          economic_activities: [],
          entity_types: [],
          communes: [],
          entity_agreements: [],
          entity_activities: [],
          isFullPage: true,
          isLoading: true,
          ShowViewToShow: 1,
          can_edit: false,
          can_create_activity: false,
          can_create_agreement: false,
          can_create_program: false,
          can_edit_program: false,
          hasVimePermissions:false,
          rut: null,
          dv: null,
          currentTab: 'PartnerEntitiesVimeGeneralInformation',
          can_see_related_projects:false,
          can_see_related_people:false,
          items: [
            {tab: 'Información general',content:'PartnerEntitiesVimeGeneralInformation', icon:'info-circle'},
            {tab: 'VIME',content:'PartnerEntityVimeTab', icon:'globe'},
            {tab: 'Programas de Financiamiento',content:'PartnerEntitiesPrograms', icon:'file-invoice-dollar'},
            {tab: 'Sub-Entidades',content:'PartnerEntitySubEntity', icon:'industry'},


          ],
          sub_entities: [],
          isSubEntity: false,
          nameEntityMajor: ''
        }
    },
    async beforeMount(){
        this.isLoading = true
        await this.getEconomicActivities()
        await this.getEntitiesTypes()
        await this.getCommunes()
        await this.getEntity()
    },
    computed: {
      currentTabComponent: function () {
        return this.currentTab
      }
    },
    methods:{
      changeTab: function(value){
        this.currentTab = value
      },
      editEntity(){
        window.location.href='/partner_entities/edit/'+this.entity_id
      },
      selectTab(tab){
        this.currentTab = tab
      },
        async getEntity(){
            axios.get('/partner_entities/show/'+this.entity_id+'.json')
            .then( response =>{
              this.entity = response.data.entity
              if (response.data.entity['rut'] != null){
                this.rut = response.data.entity['rut'].rut
                this.dv = response.data.entity['rut'].dv
              }
              this.entity_agreements = response.data.agreements
              this.entity_activities = response.data.activities
              this.hasVimePermissions = response.data.has_vime_permissions;
              this.can_edit = response.data.can_edit
              this.can_create_activity = response.data.can_create_activity
              this.can_create_agreement = response.data.can_create_agreement
              this.can_create_program = response.data.can_create_program
              this.can_edit_program = response.data.can_edit_program
              this.can_see_related_projects = response.data.can_see_related_projects
              this.can_see_related_people = response.data.can_see_related_people
              this.sub_entities = response.data.entity.sub_entities

              if (response.data.entity.entity_mayor != null){
                this.isSubEntity = true
                this.nameEntityMajor = response.data.entity.entity_mayor.name
              }

              if(this.can_see_related_projects){
                this.items.push({tab: 'Proyectos Relacionados',content:'AssociatedProjectsTab', icon:'folder-open'});
              }
              if(this.can_see_related_people){
               this.items.push({tab: 'Personas Relacionadas',content:'PartnerEntityPerson',icon:'address-card'})
              }
              this.isLoading=false
            })
            .catch(e =>{
                console.log(e)
                this.isLoading=false
            })
        },
        async getEconomicActivities(){
            axios.get('/economic_activities.json')
            .then( response =>{
                this.economic_activities = response.data
            })
            .catch(e =>{
                console.log(e)
                this.isLoading = false
            })
        },
        async getEntitiesTypes(){
            axios.get('/admin/entity_types.json')
            .then( response =>{
                this.entity_types = response.data
            })
            .catch(e =>{
                console.log(e)
                this.isLoading = false
            })
        },
        async getCommunes(){
            axios.get('/admin/communes.json')
            .then( response =>{
                this.communes = response.data
            })
            .catch(e =>{
                console.log(e)
                this.isLoading = false
            })
        },

        back(){
            window.location.href='/partner_entities/index'
        }
    }
}
</script>