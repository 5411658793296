<template>
  <section>
    <section class="hero has-background-white-bis" style="box-shadow: 0 6px 14px -4px rgba(196,196,196,1);">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-2">
              <img id="pfp" src="/blank_profile_photo.png" style="display: none"/>
              <img v-if="photoUrl != null" id="photo" :src="photoUrl" style="display: none"/>
              <img id="photo2" :src="this.imageRounded" />
            </div>
            <div class="column">
              <div class="columns">
                <div class="column">
                  <h1 class="title is-capitalized">
                    {{ personName }}
                  </h1>
<!--                  <h2 v-if="person.person_unit!=null" class="subtitle">
                    {{ this.person.person_unit }}
                  </h2>-->
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Año desde:">
                    <multiselect v-model="minYear"
                                 :options="year_options"
                                 :searchable="true"
                                 :close-on-select="true"
                                 placeholder="Elija un año"
                                 :allow-empty="true"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="Presione para deseleccionar"
                    ></multiselect>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Año hasta:"
                           :type="{ 'is-danger': verifyYears }"
                           :message="{ 'Año hasta no puede ser menor al Año desde': verifyYears }">
                    <multiselect v-model="maxYear"
                                 :options="year_options"
                                 :searchable="true"
                                 :close-on-select="true"
                                 placeholder="Elija un año"
                                 :allow-empty="true"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="Presione para deseleccionar"
                    ></multiselect>
                  </b-field>
                </div>
                <div class="column">
                  <br>
                  <b-field label=" ">
                    <b-tooltip label="Seleccione un rango de fechas para filtrar actividad académica."
                               position="is-left">
                      <b-button type="is-info" @click="applyFilter">
                        Aplicar filtro
                      </b-button>
                    </b-tooltip>
                  </b-field>
                </div>
<!--                <div v-if="can_create_academic_activity === 'true'" class="column is-narrow">
                  <br>
                  <b-field label = " ">
                    <add-academic-activity-dropdown></add-academic-activity-dropdown>
                  </b-field>
                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="box">
        <div class="container">
          <b-tabs position="is-centered" @input="changeTab" :multiline="true">
            <b-tab-item v-for="item in items"
                        v-bind:key="item.content"
                        :value="item.content"
                        :label="item.tab"
                        :icon="item.icon">
            </b-tab-item>
          </b-tabs>
          <!-- COMPONENTE AQUI -->
          <keep-alive>
            <component v-bind:is="currentTabComponent" :person-id="personId" :photo-url="photoUrl" :person="person"
                       :cargo-principal="cargoPrincipal" :currentYear="year" :min-year="minYear" :max-year="maxYear" :change-filter="changeFilter" :hide-update="true"
                       :can-create-document="canCreateDocument" :can_create_work_experience="can_create_work_experience"
                       :can_create_award="can_create_award" :can_create_scholarship="can_create_scholarship"
                       :can_create_related_institution="can_create_related_institution"
                       :principalUnitId="principalUnitId" :can_create_academic_activity="can_create_academic_activity"
                       :canCreateMyVimeActivity="canCreateMyVimeActivity" :canCreateMyVimeAgreement="canCreateMyVimeAgreement"
                       :can_life_record="can_life_record"/>
          </keep-alive>

          <!-- FIN COMPONENTE -->
        </div>
      </div>
    </section>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import AddAcademicActivityDropdown from "./add_academic_activity_dropdown.vue"
import MyAcademicData from './my_academic_data_tab.vue'
import MyAcademicProfile from "./my_academic_profile.vue"
import MyResearchProfile from "./my_research_profile_tab.vue"
import MyVimeProfile from "./my_vime_profile_tab.vue"
import MyAdministrativeProfile from "./my_administrative_profile_tab.vue"
import MyDocuments from './my_documents_tabs.vue'
import MySocialNetworks from './my_social_networks.vue'
import axios from "axios";
import Downloads_tab from "../life_record/downloads_tab.vue";
export default {
  components:{
    AddAcademicActivityDropdown, MyAcademicData, MyAcademicProfile, MyResearchProfile, MyVimeProfile,
    MyAdministrativeProfile, MyDocuments, MySocialNetworks, Downloads_tab
  },
  props:[
      'personId','photoUrl', 'cargoPrincipal', 'canCreateDocument', 'personName', 'can_create_award', 'can_create_work_experience',
      'can_create_scholarship', 'can_create_related_institution', 'can_create_academic_activity', 'principalUnitId', 'canCreateMyVimeActivity',
      'canCreateMyVimeAgreement', 'can_life_record'
  ],
  data(){
    return{
      person:{},
      isLoading:false,
      currentTab: 'MyAcademicData',
      items: [
        {tab: 'Mi Perfil Académico',content:'MyAcademicData',icon:"user-graduate"},
        {tab: 'Mi Perfil Docente',content:'MyAcademicProfile',icon:"briefcase"},
        {tab: 'Mi Perfil de Investigación',content:'MyResearchProfile',icon:"book-reader"},
        {tab: 'Mi Perfil VIME',content:'MyVimeProfile',icon:"globe"},
        {tab: 'Mi Perfil Administrativo',content:'MyAdministrativeProfile',icon:"user-tie"},
        {tab: 'Desempeño',content:'MyDocuments',icon:"file"},
        {tab: 'Jerarquización', content: 'Downloads_tab', icon:"sitemap"},
        {tab: 'Redes Sociales',content:'MySocialNetworks',icon:"hashtag"},
      ],
      isOpen:true,
      year:new Date().getFullYear(),
      minYear:new Date().getFullYear() - 5,
      maxYear:new Date().getFullYear(),
      year_options:Array.from({length:(new Date().getFullYear()-2000+1)},(v,k)=>k+2000).reverse(),
      currentYear:null,
      historicFilter:false,
      verifyYears:false,
      changeFilter:0,
      imageRounded: null,
    }
  },
  created(){
    this.isLoading=true;
    axios
        .get("/people/"+this.personId+".json")
        .then(res => {
          this.person = res.data
          this.loadImage()
        })
        .catch(e => {
          //console.log(e);
          this.isLoading=false;
        });
  },
  mounted(){
    
  },
  methods:{
    changeTab: function(value){
      this.currentTab = value
    },
    applyFilter(){

      if (this.minYear!= null && this.maxYear!=null){
        if(this.minYear > this.maxYear){
          this.verifyYears = true
        }
        else{
          this.verifyYears = false
          if (this.changeFilter == 0){
            this.changeFilter = 1
          }
          else{
            this.changeFilter = 0
          }
        }
      }
      else{
        this.verifyYears = false
        if (this.changeFilter == 0){
          this.changeFilter = 1
        }
        else{
          this.changeFilter = 0
        }
      }



    },
    async loadImage(){
      try {
        this.isLoading = true
        setTimeout(() => {
          //Metodo para hacer redonda la foto de perfil
          var c = document.createElement('canvas');
          var img = document.getElementById('pfp')

          if(this.person.photo_url != null){
            img = document.getElementById('photo');
          }
          //var img = document.createElement('img');
          //img.src = img_path

          if(img.height == 0){
            img = document.getElementById('pfp')
          }

          c.height = 805;
          c.width = 805;
          var ctx = c.getContext('2d');
          ctx.save();
          ctx.beginPath();
          ctx.arc(402.5, 402.5, 402.5, 0, Math.PI * 2, true);
          ctx.closePath();
          ctx.clip();
          ctx.drawImage(img, 0, 0, 805, 805);
          ctx.beginPath();
          ctx.arc(0, 0, 402.5, 0, Math.PI * 2, true);
          ctx.clip();
          ctx.closePath();
          ctx.restore();
          this.imageRounded = c.toDataURL();
        },  1000);
        setTimeout(()=>{
          this.isLoading = false
        },1000);
      }
      catch(err){
        console.log('Errores:')
      }
    },

  },
  computed:{
    currentTabComponent: function () {
      return this.currentTab
    }
  }
}
</script>

