<template>
  <section>

    <div class="columns is-centered">
      <div class="column">

        <b-message type="is-info" has-icon>
          Esta Patente solo puede ser <b>editada</b> o <b>anulada</b> por quien agregó estos datos al sistema. Si hay un error en la información por favor ponerse en contacto con la persona propietaria. 
          Si el dato que ve no corresponde a usted, puede <b>eliminar</b> su participación en la sección <b>Participantes</b>, y este dato dejará de estar visible.
        </b-message>

      </div>
    </div>

    <div class="columns is-centered">
      <div class="column">
        <div class="has-text-right">
          <b-button type="is-info"
                    icon-left="edit"
                    v-if="(patent.can_edit && patent.academic_activity_state_id == 1) || (patent.can_edit && patent.academic_activity_state_id == null)"
                    @click="editPatent">
            Editar Propiedad Intelectual
          </b-button>
          <b-button v-if="(patent.is_creator || cargoPrincipal.name == 'ANALISTA I+D FING') && (patent.academic_activity_state_id == 1 || patent.academic_activity_state_id == null)" type="is-danger"
                    icon-left="ban"
                    @click="execNullifyModal">
            Anular Propiedad Intelectual
          </b-button>
        </div>

        <hr class="dropdown-divider" aria-role="menuitem">
        <div class="columns">
          <div class="column">
            <b-field label="Título">
              <b-input readonly v-model="patent.title" type="text"></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Título Corto">
              <b-input readonly v-model="patent.short_title"></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Código Protección">
              <b-input readonly v-model="patent.protection_code"></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Tipo de Protección">
              <b-input readonly v-model="patent.patent_type"></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Fecha de Creación">
              <b-input readonly v-model="patent.presentation_date"></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Número de Solicitud">
              <b-input readonly v-model="patent.application_number"></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Código Tecnología">
              <b-input readonly v-model="patent.technology_code"></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="País">
              <b-input readonly v-model="patent.nationality"></b-input>
            </b-field>
          </div>
        </div>
      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        :active="isComponentModalActive"
        width="90%"
        :can-cancel="canCancel"
        aria-modal>
      <template #default="props">
        <new-patent-form modal-cancel="true"
                             :cargo-principal="cargoPrincipal"
                             :user-id="userId"
                             modal-header="Editar Patente"
                             query-type="update" v-bind:patent-id="patent.id" @close="closeModal"></new-patent-form>
      </template>
    </b-modal>
  </section>
</template>

<script>
import axios from "axios";
import NewPatentForm from "./NewPatentForm";

export default {
  name: "DisclosureGeneralTab",
  components:{NewPatentForm},
  data(){
    return{
      isLoading: false,
      isComponentModalActive: false,
      canCancel: ['escape']
    }
  },
  props:['patent','cargoPrincipal','userId','patentId'],
  methods:{
    execNullifyModal(){
      this.$buefy.dialog.confirm({
        title: 'Anular patente',
        message: '¿Está seguro que desea <b>anular</b> la patente? Esta acción la dejará inválida.<br>',
        confirmText: 'Anular Patente',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/patents/" + this.patentId + "/nullify_patent.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Patente anulada correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                if (document.referrer == ''){
                  window.location.href = history.back()
                }
                else{
                  window.location.href = document.referrer
                }
                this.isLoading = false;
                this.isNullifyActive = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Patente no pudo ser anulado</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },
    editPatent(){
      this.isComponentModalActive = true;
    },
    closeModal(){
      this.isComponentModalActive = false;
    },
  }
}
</script>
