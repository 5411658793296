<template>
  <div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
    <div class="container box my-5">
      <div class="title is-2">Acreditación de Identidad</div>
      <div class="columns is-centered">
        <div class="column ">
          <b-message
              title="Información importante"
              type="is-warning"
              has-icon  
              aria-close-label="Cerrar mensaje">
            Antes de comenzar con la acreditación por QR tiene que tomar las siguientes consideraciones:
            <ul>
              <li>
                -El sistema utilizado en su dispositivo tiene que ser Android
              </li>
              <li>
                -El navegador a utilizar tiene que ser FireFox
              </li>
              <li>
                -Recuerde que para una mejor lectura tiene que centrar el QR en la camara del dispositivo utilizado
              </li>
            </ul>
          </b-message>
        </div>
      </div>
      <b-tabs type="is-toggle-rounded" position="is-centered">
        <b-tab-item label="Acreditación de identidad" icon="qrcode">
          <div class="box">
            <div class="title is-4 has-text-centered">Acreditación por QR</div>
            <div class="columns">
              <div class="column has-text-centered">
                <b-button
                  size="is-large"
                  icon-left="qrcode"
                  type="is-primary"
                  @click="onShowCamera"
                >
                  Escanear QR
                </b-button>
              </div>
            </div>
            <div v-if="showCamera" class="columns">
              <div class="column has-text-centered">
                <video ref="videoStream"></video>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="title is-4 has-text-centered">Acreditación manual</div>
            <ValidationObserver ref="rutObserver" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(submit)">
                <b-field label="Ingrese RUT del estudiante (ej: 12345678-K)*">
                  <ValidationProvider
                    :rules="{
                      required: true,
                      regex: /^\d{1,8}-[0-9K]$/,
                    }"
                    v-slot="{ errors }"
                  >
                    <b-input
                      v-model="formattedRut"
                      placeholder="Ingrese RUT del estudiante"
                    ></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
                <div class="columns">
                  <div class="column has-text-right">
                    <b-button
                      icon-left="id-badge"
                      type="is-primary"
                      native-type="submit"
                      >Acreditar manualmente
                    </b-button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </b-tab-item>
        <b-tab-item label="Lista completa de estudiantes" icon="pen">
          <div class="columns">
            <div class="column">
              <b-field label="Buscar por RUT">
                <b-input
                  v-model="searchByRut"
                  placeholder="Ingrese RUT a buscar"
                ></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Buscar por Nombre">
                <b-input
                  v-model="searchByName"
                  placeholder="Ingrese nombre a buscar"
                ></b-input>
              </b-field>
            </div>
          </div>
          <b-table
            :data="studentsFound"
            :paginated="true"
            per-page="10"
            striped
            hoverable
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"
            class="mt-5"
          >
            <b-table-column field="rut" label="RUT" searchable sortable>
              <template slot="searchable" slot-scope="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.rut }}
              </template>
            </b-table-column>
            <b-table-column field="name" label="Nombres" searchable sortable>
              <template slot="searchable" slot-scope="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.name }}
              </template>
            </b-table-column>
            <b-table-column
              field="last_name"
              label="Primer Apellido"
              searchable
              sortable
            >
              <template slot="searchable" slot-scope="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.last_name }}
              </template>
            </b-table-column>
            <b-table-column
              field="second_last_name"
              label="Segundo Apellido"
              searchable
              sortable
            >
              <template slot="searchable" slot-scope="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.second_last_name }}
              </template>
            </b-table-column>
            <b-table-column field="career" label="Carrera" searchable sortable>
              <template slot="searchable" slot-scope="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.career }}
              </template>
            </b-table-column>
            <b-table-column field="gender" label="Género" searchable sortable>
              <template slot="searchable" slot-scope="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.gender }}
              </template>
            </b-table-column>
            <template #empty>No se encontraron resultados</template>
          </b-table>
        </b-tab-item>
      </b-tabs>
    </div>
    <div class="container box" v-if="studentsAccreditated.length > 0">
      <div class="title is-4">Tabla de estudiantes acreditados</div>
      <b-table
        :data="studentsAccreditated"
        :paginated="true"
        per-page="10"
        striped
        hoverable
        default-sort-direction="asc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página actual"
      >
        <b-table-column field="rut" label="RUT" searchable sortable>
          <template slot="searchable" slot-scope="props">
            <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small"
            />
          </template>
          <template v-slot="props">
            {{ props.row.rut }}
          </template>
        </b-table-column>
        <b-table-column field="name" label="Nombres" searchable sortable>
          <template slot="searchable" slot-scope="props">
            <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small"
            />
          </template>
          <template v-slot="props">
            {{ props.row.name }}
          </template>
        </b-table-column>
        <b-table-column
          field="last_name"
          label="Primer Apellido"
          searchable
          sortable
        >
          <template slot="searchable" slot-scope="props">
            <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small"
            />
          </template>
          <template v-slot="props">
            {{ props.row.last_name }}
          </template>
        </b-table-column>
        <b-table-column
          field="second_last_name"
          label="Segundo Apellido"
          searchable
          sortable
        >
          <template slot="searchable" slot-scope="props">
            <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small"
            />
          </template>
          <template v-slot="props">
            {{ props.row.second_last_name }}
          </template>
        </b-table-column>
        <b-table-column field="career" label="Carrera" searchable sortable>
          <template slot="searchable" slot-scope="props">
            <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small"
            />
          </template>
          <template v-slot="props">
            {{ props.row.career }}
          </template>
        </b-table-column>
        <b-table-column field="gender" label="Género" searchable sortable>
          <template slot="searchable" slot-scope="props">
            <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small"
            />
          </template>
          <template v-slot="props">
            {{ props.row.gender }}
          </template>
        </b-table-column>
        <b-table-column
          field="date"
          label="Hora de Entrada"
          searchable
          sortable
          :custom-sort="sortDates"
        >
          <template slot="searchable" slot-scope="props">
            <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small"
            />
          </template>
          <template v-slot="props">
            {{ props.row.date }}
          </template>
        </b-table-column>
        <template #empty>No se encontraron resultados</template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { ready, scan } from "qr-scanner-wechat";
import axios from "axios";

export default {
  data() {
    return {
      isLoading: false,
      showCamera: false,
      students: [],
      rut: "",
      stream: null,
      studentsAccreditated: [],
      searchByRut: "",
      searchByName: "",
    };
  },

  computed: {
    formattedRut: {
      get() {
        if (this.rut.length > 1) {
          return this.rut.replace(/([0-9a-zA-Z]+)([0-9a-zA-Z])/, "$1-$2");
        }
      },
      set(value) {
        this.rut = value.replace("k", "K").replace(/-/g, "");
      },
    },
    studentsFound() {
      // Filter students by rut and name as substring if they are not empty
      return this.students.filter((student) => {
        let studentName = `${student.name.toLowerCase()} ${student.last_name.toLowerCase()} ${student.second_last_name.toLowerCase()}`;
        studentName = studentName
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        const nameSearched = this.searchByName
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        return (
          student.rut.startsWith(this.searchByRut) &&
          studentName.includes(nameSearched)
        );
      });
    },
  },

  methods: {
    postAccreditation(rut) {
      let vm = this;
      vm.isLoading = true;
      axios
        .post("/digital-accreditation/accredit.json", {
          rut: rut,
        })
        .then((response) => {
          vm.rut = "";
          vm.$refs.rutObserver.reset();
          const student = response.data.student;
          vm.studentsAccreditated.unshift(student);
          if (student.already_accreditated)
            vm.$buefy.dialog.alert({
              title: "Advertencia",
              message:
                "Este estudiante ya realizó su acreditación al menos una vez. <br/>" +
                `Nombre: ${student.name} ${student.last_name} ${student.second_last_name} (${student.rut}) <br/>` +
                `Carrera: ${student.career} <br/>` +
                `Género: ${student.gender} <br/>`,
              confirmText: "OK",
              type: "is-warning",
              hasIcon: true,
            });
          else
            vm.$buefy.dialog.alert({
              title: "Éxito",
              message:
                "Acreditación exitosa del estudiante <br/>" +
                `Nombre: ${student.name} ${student.last_name} ${student.second_last_name} (${student.rut}) <br/>` +
                `Carrera: ${student.career} <br/>` +
                `Género: ${student.gender} <br/>`,
              confirmText: "OK",
              type: "is-success",
            });
        })
        .catch((e) => {
          console.log(e);
          if (e.response.status === 404) {
            vm.$buefy.dialog.alert({
              title: "Error",
              message: `El estudiante con RUT ${rut} no se encontró en la base de datos.`,
              confirmText: "OK",
              type: "is-warning",
            });
          } else {
            vm.$buefy.dialog.alert({
              title: "Error",
              message:
                "Error al acreditar al estudiante, reintente nuevamente.",
              confirmText: "OK",
              type: "is-danger",
            });
          }
        })
        .finally(() => {
          vm.isLoading = false;
        });
    },
    async onDetect(str) {
      const regex = /RUN=([0-9]+-[0-9kK])/;
      let matches = str.match(regex);
      if (matches && matches.length > 1) {
        let rut = matches[1].toUpperCase();
        this.showCamera = false;
        this.postAccreditation(rut);
      } else {
        this.showCamera = false;
        this.$buefy.dialog.alert({
          title: "Error",
          message: "Error al detectar el código QR, reintente nuevamente.",
          confirmText: "OK",
          type: "is-danger",
        });
      }
    },
    async initCamera() {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: { exact: "environment" } },
        });
        setTimeout(() => {
          this.$refs.videoStream.srcObject = this.stream;
          this.$refs.videoStream.play();

          setInterval(this.scanFrame, 500);
        }, 4000);
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.alert({
          title: "Error",
          message: "Error al acceder a la cámara, reintente nuevamente.",
          confirmText: "OK",
          type: "is-danger",
        });
        this.showCamera = false;
      }
    },
    async scanFrame() {
      if (this.showCamera === false) return null;
      const canvas = document.createElement("canvas");
      canvas.width = this.$refs.videoStream.videoWidth;
      canvas.height = this.$refs.videoStream.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(this.$refs.videoStream, 0, 0, canvas.width, canvas.height);
      const result = await scan(canvas);

      if (result?.text) {
        this.onDetect(result.text);
      }
    },
    onShowCamera() {
      this.showCamera = !this.showCamera;
      if (this.showCamera) this.initCamera();
    },
    fetchStudents() {
      let vm = this;
      vm.isLoading = true;
      axios
        .get("/digital-accreditation/students.json")
        .then((response) => {
          vm.students = response.data.students;
        })
        .catch((e) => {
          console.log(e);
          vm.$buefy.dialog.alert({
            title: "Error",
            message: "Error al obtener los estudiantes.",
            confirmText: "OK",
            type: "is-danger",
          });
        })
        .finally(() => {
          vm.isLoading = false;
        });
    },
    submit() {
      const rut = this.formattedRut;
      this.postAccreditation(rut);
    },
    sortDates(a, b, isAsc) {
      const dateA = this.convertToDate(a.date);
      const dateB = this.convertToDate(b.date);
      return isAsc ? dateA - dateB : dateB - dateA;
    },
    convertToDate(dateStr) {
      const [day, month, yearTime] = dateStr.split("/");
      const [year, time] = yearTime.split(" ");
      const [hour, minute, second] = time.split(":");
      return new Date(year, month - 1, day, hour, minute, second);
    },
  },
  beforeDestroy() {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  },
  created() {
    this.fetchStudents();
  },
  async mounted() {
    this.isLoading = true;
    await ready();
    this.isLoading = false;
  },
};
</script>
