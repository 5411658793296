<template>
  <section>
        <!-- Box de Formulario function -->
        <div class="modal-card" style="width: auto" id="show-person-function">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" id="show-person-function" ref="showPersonFunction">
            <div class="columns">
              <div class="column">
                <b-button class="is-danger" v-if="person_function.can_distance"
                          @click="isDistanceModalActive = true">
                  Liberar Función
                </b-button>
              </div>
            </div>
            <div class="columns">
              <h3 class="is-size-3 has-text-weight-bold">Información Personal y Nombramiento</h3>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Nombre Completo">
                  <b-input :value="person!==undefined ? person.nombre_completo : ''" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Rut">
                  <b-input :value="person!==undefined ? person.run_completo : ''" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Nombramiento">
                  <b-input :value="nombramiento!==undefined ? nombramiento.custom_name : ''" type="text" readonly></b-input>
                </b-field>
                <p class="control">
                  <b-button v-if="nombramiento.id!==undefined && nombramiento.id!==null" class="mt-2" @click.prevent="isShowPersonUnitActive = true" type="is-primary" :disabled="nombramiento===undefined||nombramiento===null">Mostrar Nombramiento</b-button>
                </p>
              </div>
            </div>
            <div class="columns">
              <h3 class="is-size-3 has-text-weight-bold">Información Función</h3>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Función">
                  <b-input :value="(person_function.function!==undefined && person_function.function!==null) ? person_function.function.name : ''" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción">
                    <b-input :value="person_function.description" type="textarea" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Unidad de desempeño">
                  <b-input :value="(person_function.unit!==undefined && person_function.unit!==null) ? person_function.unit.name : ''" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de documento">
                  <b-input :value="(person_function.resolution_type!==undefined && person_function.resolution_type!==null) ? person_function.resolution_type.name : ''" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Número de documento">
                  <b-input :value="person_function.resolution" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de documento">
                  <b-input :value="formatDate(person_function.resolution_date)" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Fecha de inicio">
                  <b-input :value="formatDate(person_function.start_date)" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de término">
                  <b-input :value="formatDate(person_function.end_date)" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Jornada (horas semanales)">
                  <b-input :value="person_function.hours" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns is-gapless">
              <div class="column">
                <b-field label="Documento">
                  <b-input expanded readonly v-model="person_function.document_name" type="text"></b-input>
                </b-field>
              </div>
              <div class="column is-narrow">
                <br>
                <b-field v-if="person_function.can_view_pdf" label="  ">
                  <b-button @click="viewFile(person_function.document_id)" icon-left="eye" type="is-success">ver</b-button>
                  <b-button @click="downloadFile(person_function.document_id)" icon-left="download" type="is-info">Descargar</b-button>
                </b-field>
              </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <b-button class="is-primary"
                      v-if="person_function.can_edit"
                      @click="isEditModalActive = true">
              Editar
            </b-button>
          </div>
        </footer>
    <b-modal
        v-model="isEditModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        :can-cancel="canCancel"
        aria-modal>
      <template #default="props">
        <function-form :person-id="person_function.person_id"
                    :id="person_function.id"
                    query-type="update"
                    :modal-header="'Editar Función - '+person_name"
                    @close="props.close">
        </function-form>
      </template>
    </b-modal>
    <b-modal
        v-model="isDistanceModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        :can-cancel="canCancel"
        aria-modal>
      <template #default="props">
        <distance-function-form :person-id="person_function.person_id"
                       :id="person_function.id"
                       :person_function_id="person_function.id"
                       :unit="person_function.unit.name"
                       :function_name="person_function.function.name"
                       :hours="person_function.hours"
                        :old_start_date="person_function.start_date"
                       query-type="create"
                       :modal-header="'Liberar Función - '+person_name"
                       @close="props.close">
        </distance-function-form>
      </template>
    </b-modal>
    <b-modal
        v-model="isShowPersonUnitActive"
        :destroy-on-hide="true">
      <template #default="props">
        <info-appointment
            modal-header="Nombramiento Asociado"
            :nombramiento = "nombramiento"
            @close="props.close"
        ></info-appointment>
      </template>
    </b-modal>
    <b-modal
        v-model="showPdf"
        :can-cancel="true"
        :destroy-on-hide="true"
        aria-modal
        aria-role="dialog"
        has-modal-card
        trap-focus
        :width="2000"
    >
      <template #default="props">
        <pdf_view
            :src="src"
            @close="props.close"
        ></pdf_view>
      </template>

    </b-modal>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios"
import FunctionForm from "./modal_function_form";
import DistanceFunctionForm from "./modal_distance_function_form"
import {formatDate} from "../../../packs/utilities"
import InfoAppointment from "../commissions/info_appointment";
import Pdf_view from "../../performances/pdf_view.vue";
export default {
  name: "show_function",
  props: [
    'queryType', 'personId', 'id','modalHeader', 'person_name'
  ],
  components:{
    Pdf_view,
    FunctionForm,
    DistanceFunctionForm,
    InfoAppointment,
  },
  data(){
    return {
      person_function: {},
      person_function_to_distance: {},
      errores_validacion:{},
      resolution_types: [],
      person_function_type: {},
      functions: [],
      units: [],
      isLoading: false,
      funcion_filtrada: [],
      function_name: null,
      isEditModalActive: false,
      isDistanceModalActive: false,
      canCancel: ['escape'],
      nombramiento: {},
      person: {},
      isShowPersonUnitActive: false,
      showPdf: false,
      src:null,
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize(){
      this.isLoading=true;
      return axios
          .get("/person_functions/" + this.id + ".json")
          .then(res => {
            this.person_function = res.data
            this.person = res.data["person"]
            this.nombramiento = res.data["nombramiento"]
            this.isLoading = false;
          }).catch(error => {
            console.log(error)
            this.isLoading = false;
          })
    },
    formatDate,
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            this.showPdf = true
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    downloadFile(fileId){
      this.isLoading = true;

      if (fileId == null){
        this.isLoading = false;
        return;
      }

      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(res => {
            let blob = new Blob([res.data], {
              type: "application/pdf",
            })
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = res.headers["content-disposition"]
                .split(";")[1]
                .split('"')[1];
            link.click();
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento fue descargado con exito',
              type: 'is-success',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
          .catch(e => {
            console.log(e)
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento no se pudo descargar',
              type: 'is-danger',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
    },

  },
  watch:{
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
    isDistanceModalActive: function () {
      if (!this.isDistanceModalActive) {
        this.initialize()
      }
    },
  }
}
</script>