<template>
    <div class="vimeactivityshowview">
          <section>
            <div class="box">
              <div class="container">
                <b-tabs position="is-centered" @input="changeTab">
                  <b-tab-item v-for="item in items"
                              :value="item.content"
                              v-bind:key="item.content"
                              :label="item.tab"
                              :icon="item.icon">
                  </b-tab-item>
                </b-tabs>
                <component v-bind:is="currentTabComponent"
                           :can_edit="this.can_edit"
                           :vime_activity='this.vime_activity'
                           :vime_activity_type_name="this.vime_activity_type_name"
                           :vime_activity_id='this.vime_activity_id'
                           :activity_assistances_found='this.activity_assistances_found'
                           :activity_assistances='this.vime_activity_assistance'
                           :modalAssistanceTypes='this.modalAssistanceTypes'
                           :modalEntitiesAssistanceTypes = 'this.modalEntitiesAssistanceTypes'
                           :entities_related='vime_activity_entities'
                           :can_create_participant="this.can_create_participant"
                           :can_destroy_participant="this.can_destroy_participant"
                           v-on:change-asistance="changeAssistance"
                ></component>
                <div class="field is-grouped is-grouped-left">
                  <div class="control">
                    <button class="button is-link is-light" @click="back()">Volver</button>
                  </div>
                </div>
              </div>
            </div>
          </section>
      <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
</template>
<script>
import axios from 'axios'
import VimeActivityGeneralInformation from '../show/vime_activity_general_information.vue'
import VimeActivityAssistances from '../show/vime_activity_assistances.vue'
import VimeActivityPartnerEntitiesRelated from '../show/vime_activity_partner_entities_related.vue'
import moment from "moment";
export default {
    name: 'VimeActivityShowView',
    components:{
        VimeActivityGeneralInformation,
        VimeActivityAssistances,
        VimeActivityPartnerEntitiesRelated
    },
    props:{
        vime_activity_id:{
            type: Number
        }
    },
    data (){
        return {
            vime_activity: {},
            activity_name: '',
            vime_activity_assistance: [],
            vime_activity_entities: [],
            vime_activity_type_name: null,
            modalAssistance: [],
            modalAssistanceTypes: [],
            modalEntitiesAssistance: [],
            modalEntitiesAssistanceTypes: [],
            can_edit: false,
            can_create_participant: false,
            can_destroy_participant: false,


            activity_assistances_found: false,
            activity_assistances_entities_found: false,
            isFullPage: true,
            isLoading: true,
            ShowViewToShow: 1,
            currentTab: 'VimeActivityGeneralInformation',
            items:[
              {tab:'Información general', content:'VimeActivityGeneralInformation', icon:'info-circle'},
              {tab:'Participantes', content: 'VimeActivityAssistances', icon: 'address-card'},
              {tab:'Entidades relacionadas', content: 'VimeActivityPartnerEntitiesRelated', icon: 'industry'}
            ]
        }
    },
    computed:{
        checkActivitiesAssistance(){
            return Object.entries(this.vime_activity_assistance).length === 0
        },
        checkActivitiesEntitiesAssistance(){
            return Object.entries(this.vime_activity_entities).length === 0
        },
        currentTabComponent: function () {
          return this.currentTab
        }
    },
    beforeMount(){
        this.getVimeActivity()
        this.getVimeActivityAssistance()
        this.getVimeEntitiesAssistance()
        //this.getAssistancesToModal()
        this.getAssistancesTypesToModal()
        //this.getEntitiesAssistancesToModal()
        this.getEntitiesAssistancesTypesToModal()

    },
    methods:{
      changeTab: function(value){
        this.currentTab = value
      },
      selectTab(tab){
        this.currentTab = tab
      },
        getVimeActivity(){
            axios.get('/vime_activities/'+this.vime_activity_id+'.json')
            .then(response =>{
                this.vime_activity = response.data
                if (this.vime_activity.start_date != null){
                  this.vime_activity.start_date = new Date(moment(this.vime_activity.start_date)).toLocaleDateString("es-CL");
                }
                if (this.vime_activity.end_date != null){
                  this.vime_activity.end_date = new Date(moment(this.vime_activity.end_date)).toLocaleDateString("es-CL");
                }
                this.vime_activity_type_name = response.data.vime_activity_type.name
                this.activity_name = response.data["name"].toUpperCase()
                this.can_edit = response.data["can_edit"]
                this.can_destroy_participant = response.data["can_destroy_participant"]
                this.can_create_participant = response.data["can_create_participant"]
            })
            .catch(e => { 
                console.log(e)
            })
        },
        getVimeActivityAssistance(){
            axios.get('/vime_activity_assistances/getAssistanceByActivityId/'+this.vime_activity_id)
            .then(response =>{
                this.vime_activity_assistance = response.data.assistance
                if(this.checkActivitiesAssistance)
                    this.activity_assistances_found=false
                else
                    this.activity_assistances_found=true
            })
            .catch(e => { 
                console.log(e)
            })
        },
        getVimeEntitiesAssistance(){
            axios.get('/vime_activity_assistances/getEntitiesRelatedByActivityId/'+this.vime_activity_id)
            .then(response =>{
                this.vime_activity_entities = response.data.assistance
                if(this.checkActivitiesEntitiesAssistance)
                    this.activity_assistances_entities_found=false
                else
                    this.activity_assistances_entities_found=true
            })
            .catch(e => { 
                console.log(e)
            })
        },
        getAssistancesToModal(){
            axios.get('/activity_assistances/getAssistancesToModal')
            .then(response =>{
                this.modalAssistance = response.data.assistance
            })
            .catch(e => {
                console.log(e)
            })
        },
        getAssistancesTypesToModal(){
            axios.get('/vime_assistant_types/get_assistants_types/0.json')
            .then(response =>{
                this.modalAssistanceTypes = response.data
                this.isLoading=false
            })
            .catch(e => {
                console.log(e)
                this.isLoading=false
            })
        },
        getEntitiesAssistancesToModal(){
            axios.get('/activity_assistances/getEntitiesInfoForModal')
            .then(response =>{
                this.modalEntitiesAssistance = response.data.assistance
            })
            .catch(e => {
                console.log(e)
            })
        },
        getEntitiesAssistancesTypesToModal(){
            axios.get('/vime_assistant_types/get_assistants_types/1.json')
            .then(response =>{
                this.modalEntitiesAssistanceTypes = response.data
            })
            .catch(e => { 
                console.log(e)
            })
        },
        changeAssistance(newAssistance){
            this.vime_activity_assistance = newAssistance 
        },
        changeEntityAssistance(newEntityAssistance){
            this.vime_activity_entities = newEntityAssistance 
        },
        editActivity(){
            window.location.href='/vime_activities/'+this.vime_activity_id+'/edit'
        },
        back(){
            window.location.href='/vime_activities/'
        }
    }
}
</script>