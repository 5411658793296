<template>
  <section>
    <!-- Box de Formulario Evento -->
    <div class="modal-card" style="width: auto" id="modal-spreadsheet">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <vue-excel-editor v-model="jsondata" ref="grid">
            <vue-excel-column field="rut"   label="Rut" :readonly="true"/>
            <vue-excel-column field="nro_reg_empleado"   label="Nro Reg Empleado" :readonly="true"/>
            <vue-excel-column field="Nombre"   label="Nombre" :readonly="true"/>
            <vue-excel-column field="centro_de_costo"   label="Centro de Costo" :readonly="true"/>
            <vue-excel-column field="descripcion_cc"   label="Descripción Cc" :readonly="true"/>
            <vue-excel-column field="jornada"   label="Jornada" :readonly="true"/>
            <vue-excel-column field="nombre_largo"   label="Nombre Largo" :readonly="true"/>
            <vue-excel-column field="relacion"   label="Relación" :readonly="true"/>
            <vue-excel-column field="descripcion_relacion"   label="Descripción Relación" :readonly="true"/>
            <vue-excel-column field="grado"   label="Grado" :readonly="true"/>
            <vue-excel-column field="hrs_estand"   label="Hrs Estand/Semn" :readonly="true"/>
            <vue-excel-column field="jerarquia_categoria"   label="Jerarquía/Categ" :readonly="true"/>
            <vue-excel-column field="descripcion_jerarquia_categoria"   label="Descripción Jerarquía/Categ" :readonly="true"/>
            <vue-excel-column field="estamento"   label="Estamento" :readonly="true"/>
            <vue-excel-column field="nombre_estamento"   label="Nombre Estamento" :readonly="true"/>
            <vue-excel-column field="afp"   label="AFP" :readonly="true"/>
            <vue-excel-column field="descripcion_afp"   label="Descripción AFP" :readonly="true"/>
            <vue-excel-column field="cd_isapre"   label="Cd ISAPRE" :readonly="true"/>
            <vue-excel-column field="descripcion_isapre"   label="Descripción ISAPRE" :readonly="true"/>
            <vue-excel-column field="cnt_bie"   label="Cnt Bie/Trie" :readonly="true"/>
            <vue-excel-column field="planta"   label="Planta" :readonly="true"/>
            <vue-excel-column field="descripcion_planta"   label="Descripción Planta" :readonly="true"/>
            <vue-excel-column field="nro_res"   label="Nro Resolucion" :readonly="true"/>
          </vue-excel-editor>
        </div>
      </section>
    </div>
    <footer class="modal-card-foot actions">
      <div class="field is-grouped is-pulled-right">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
        <button @click="exportAsExcel" class="button" type="primary"> Exportar Excel </button>
      </div>
    </footer>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import axios from "axios";
import moment from 'moment'

export default {
  name: "modal_spreadsheet_extension",
  props:[
    'modalHeader',
  ],
  components: {
  },
  data(){
    return {
      isLoading:false,
      isDisabled: false,
      jsondata: [
        {rut: "01111111-1", nro_reg_empleado: "", nombre: "NOMBRE APELLIDO", centro_de_costo: "15",
          descripcion_cc: "FACULTAD DE INGENIERÍA", jornada: "", nombre_largo: "JORNADA COMPLETA", relacion: "",
          descripcion_relacion: "CONTRATA", grado: "9", hrs_estand: "44", jerarquia_categoria: "N/A",
          descripcion_jerarquia_categoria: "N/A", estamento: "", nombre_estamento: "ADMINISTRATIVO",
          afp: "32", descripcion_afp: "AFP MODELO", cd_isapre: "", descripcion_isapre: "ISAPRE CRUZ BLANCA",
          cnt_bie: "", planta: "PROFES", descripcion_planta: "PROFESIO", nro_res: "1111"}
      ],
    }
  },
  created() {
    this.isLoading=true;

    this.isLoading=false;
  },
  methods: {
    deleteData(){
    },
    exportAsExcel () {
      const format = 'xlsx'
      const exportSelectedOnly = false
      const filename = 'haberes'
      this.$refs.grid.exportTable(format, exportSelectedOnly, filename)
    }

  }
}
</script>