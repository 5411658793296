<template>
    <section>
        <div :id="'bar_' + title" style="width: 100%; height: 700px;" />
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </section>
</template>

<script>
import * as echarts from 'echarts'
export default {

    props: [
        'years', 'title',
    ],

    data() {
        return {
            isLoading: false,
            colorPalette: ['#00A499', '#EA7600', '#394049', '#8C4799', '#498BCA', '#EAAA00', '#C8102E'],
            option: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: function (params) {
                        var res = params[0].axisValueLabel + '<br/>';
                        var total = 0;
                        params.forEach(function (item) {
                            var dataValue = parseFloat(item.data); // Asegúrate de que el dato es un número
                            if (!(item.seriesName === 'Total')) {
                                if (!isNaN(dataValue)) {
                                    total += dataValue
                                    res += item.marker + " " + item.seriesName + ": " + item.data + '<br/>'
                                } else {
                                    // Si el dato no es numérico, simplemente no sumar al total
                                    res += item.marker + " " + item.seriesName + ": Data not available<br/>"
                                }
                            }else{
                                total += dataValue
                            }
                        })
                        res += '<b>Total: ' + total + '</b>'
                        return res;
                    }
                },
                toolbox: {
                    show: true,
                    feature: {
                        mark: { show: true },
                        dataView: {
                            show: true,
                            readOnly: true,
                            title: 'Datos',
                            lang: ["Datos", "Cerrar", "Actualizar"],
                            optionToContent: function (opt) {
                                var series = opt.series
                                var years = opt.xAxis[0].data
                                var table = '<table class="echarts-dataview-table">'
                                table += '<thead><tr><th>Nombre</th>'
                                years.forEach((year) => {
                                    table += '<th>'+ year + '</th>'
                                })
                                table += '</tr></thead>'
                                table += '<tbody>'
                                for (var i = 0, l = series.length; i < l; i++) {
                                    table += '<tr>'
                                        + '<td style="text-align: left; border: 1px solid #ccc; ">' + series[i].name + '</td>'

                                    series[i].data.forEach((value) => {
                                        var newValue = value == 0 ? value : '<b>' + value + '</b>'
                                        table += '<td style="text-align: center; border: 1px solid #ccc">' + newValue  + '</td>'
                                    })

                                    table += '</tr>'
                                }
                                table += '</tbody></table>'
                                return table;
                            },
                        },
                        saveAsImage: {
                            show: true,
                            pixelRatio: 3,
                            title: "Descargar",
                            name: 'indicator_by_year',
                        },
                    }
                },
                dataZoom: [
                    {
                        type: 'inside'
                    },
                    {
                        type: 'slider'
                    }
                ],
                grid: {
                    left: '0%',
                    right: '20%',
                    top: '5%',
                    containLabel: true
                },
                legend: {
                    show: true,
                    right: '0%',
                    top: 'center',
                    orient: 'vertical',
                    data: [],
                    selected: {},
                },
                xAxis: [
                    {
                        type: 'category',
                        data: [],
                        axisTick: {
                            alignWithLabel: true
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                ]
            }
        }
    },

    async mounted() {
        this.$nextTick(() => {
            this.initECharts()
        })
    },

    methods: {
        async initECharts() {
            this.isLoading = true

            this.option.legend.data = []
            this.option.legend.selected = {}
            this.option.series = []

            this.option = await this.setOptions(this.years, this.option, this.colorPalette)

            // based on prepared DOM, initialize echarts instance
            this.dom = echarts.init(document.getElementById('bar_' + this.title))
            this.dom.setOption(this.option)
            new ResizeObserver(() => this.dom.resize()).observe(document.getElementById('bar_' + this.title))

            this.isLoading = false
        },

        setOptions(years, option, colorPalette) {
            return new Promise(function (resolve) {

                option.xAxis[0].data = years.years

                var i = 0

                years.series.forEach(function (serie, index) {
                    if (i == colorPalette.length) {
                        i = 0
                    }
                    option.legend.data.push(serie.name)

                    option.legend.selected[serie.name] = serie.data.some(value => value !== 0)

                    option.series.push({
                        name: serie.name,
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false,
                            formatter(param) {
                                return param.value != 0 ? param.seriesName + ': ' + param.value : ''
                            }
                        },
                        emphasis: {
                            focus: 'series',
                            label: {
                                show: true
                            }
                        },
                        data: serie.data,
                        itemStyle: {
                            color: colorPalette[i],
                        },
                    })
                    i++
                })

                resolve(option)
            })

        },

    },

    watch: {
        years() {
            this.initECharts()
            deep: true
        },
    },
}
</script>