<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveRole)">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body">
            <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
              <b-message type="is-danger" has-icon>
                <div v-for="(item,index) in errores_validacion" :key="index">
                  {{ item[0] }}
                </div>
              </b-message>
            </div>
            <div class="columns" v-if="queryType=='show'">
              <div class="column is-centered">
                <b-message type="is-info" title="Información" has-icon aria-close-label="Close message">
                  Seleccione el Rol que desee ver.
                </b-message>
              </div>
            </div>
            <div class="columns" v-if="queryType=='update'">
              <div class="column is-centered">
                <b-message type="is-info" title="Información" has-icon aria-close-label="Close message">
                  Seleccione el Rol que desee actualizar.
                </b-message>
              </div>
            </div>
            <div class="columns" v-if="queryType!='create'">
              <div class="column">
                <b-field label="Rol">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit"
                                 :options="rols"
                                 :custom-label="opt => opt.internal_position ? opt.internal_position.name : ''"
                                 placeholder="Seleccione una unidad"
                                 selectLabel=""
                                 selectedLabel=""
                                 deselectLabel=""
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <b-field label="Nombre">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input v-model="complete_name" type="text"  readonly minlength="5" maxlength="200"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>

              </div>
              <div class="column">
                <b-field label="Rut">
                  <ValidationProvider rules="" v-slot="{ errors }">
                    <b-input v-model="complete_rut" type="text"  readonly></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Correo corporativo">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input v-model="corporate_mail" type="text"  readonly minlength="5" maxlength="200"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción">
                  <ValidationProvider rules="" v-slot="{ errors }">
                    <b-input v-model="person_unit.description" type="text"  :readonly="queryType=='show'" minlength="0" maxlength="200"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div v-if="queryType!='show'">
                  <b-field label="Rol*">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <multiselect v-model="person_unit.internal_position"
                                   :options="internal_positions"
                                   :custom-label="opt => opt.name"
                                   placeholder="Seleccione un rol"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   :allow-empty="false">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                          No se encontraron elementos.
                        </span>
                      </multiselect>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div v-else>
                  <b-field label="Rol">
                    <b-input :value="person_unit.internal_position!==undefined ? person_unit.internal_position.name : ''" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>
              <div class="column">
                <div v-if="queryType!='show'">
                  <b-field label="Unidad*">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <multiselect v-model="person_unit.unit"
                                   :options="units"
                                   :custom-label="opt => opt.name"
                                   placeholder="Seleccione una unidad"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   :allow-empty="false">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                          No se encontraron elementos.
                        </span>
                      </multiselect>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div v-else>
                  <b-field label="Unidad">
                    <b-input :value="person_unit.unit!==undefined? person_unit.unit.name : ''" type="text" readonly>
                    </b-input>
                  </b-field>
                </div>
              </div>
            </div>
            <div class="columns" v-if="queryType!='show'">
              <div class="column">
                <b-field label="Fecha Desde*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-datepicker
                        v-model="person_unit.start_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                    >
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Hasta">
                  <b-datepicker
                      v-model="person_unit.end_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="columns" v-else>
              <div class="column">
                <b-field label="Fecha desde">
                  <b-input readonly v-model="person_unit.start_date_table" type="text"></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha hasta">
                  <b-input :value="person_unit.end_date_table" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="has-text-right is-grouped">
            <button class="button  left-button" type="button" @click="$emit('close')">Cerrar</button>
            <b-button v-if="queryType!='show'" class="button  right-button is-primary" left-button="save" native-type="submit" @click="checkValidation()">Guardar </b-button>
          </div>
        </footer>
      </form>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "modal_roles",
  props:[
    "id", "modalHeader", "complete_name", "complete_rut", "corporate_mail", "person","queryType", "rol"
  ],
  data(){
    return {
      isLoading: false,
      isEditModalActive:false,
      person_unit: {},
      rols: [],
      id_to_edit: "",
      person_to_edit: "",
      queryType_to_edit: "",
      personId_to_edit: "",
      internal_positions: [],
      units: [],
      errores_validacion:{},
      canCancel: ['escape'],
      isLoading:false,
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      let vm = this;
      vm.isLoading = true;

      return axios
          .get("/admin/sdt/rols_form_data.json")
          .then(res => {
            vm.units = res.data.units;
            vm.internal_positions = res.data.internal_positions;
            vm.rols = Object.assign([], vm.rol);
            if(vm.queryType!=="create"){
              vm.person_unit = vm.rols[0];  
            }
            vm.isLoading = false
          }).catch(error => {
            console.log(error)
            vm.isLoading = false
          })
    },
    saveRole(){
      let vm = this;
      vm.isLoading = true;
      if (vm.queryType==="create"){
        axios
            .post("/admin/person_units/new_role_sdt.json", {
              person_unit: vm.person_unit,
              pu_relation_type_id: 3,
              person: vm.id
            })
            .then(response =>{
              vm.errores_validacion = {};
              this.$buefy.dialog.alert({
                message: 'El rol fue creado con éxito ',
                confirmText: 'Ok'
              })
              vm.person_unit = {}
              vm.errors = [];
              vm.$emit('addRole',[response.data, vm.id])
              vm.$emit('close')
            })
            .catch(error => {
              this.errores_validacion = error.response.data
              var elmnt = document.getElementById("app");
              this.isLoading = false;
              elmnt.scrollIntoView();
              this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Hubo errores al agregar el rol, favor revisar formato',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
            })
      }
      else if (vm.queryType==="update"){
        axios
            .put("/admin/person_units/" + vm.person_unit.id+"/update_role_sdt.json", {
              person_unit:  vm.person_unit, 
              pu_relation_type_id: 2,
            })
            .then(response => {
              this.$buefy.dialog.alert({
                message: 'El rol fue editado con éxito ',
                confirmText: 'Ok'
              })
              this.errores_validacion = {};
              this.isLoading = false;
              this.person_unit = {}
              vm.$emit('editRole',[response.data, vm.id])
              this.$emit('close')
            }).catch(error => {
              this.errores_validacion = error.response.data
              let elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              this.isLoading = false;
              this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Hubo errores al editar el rol, favor revisar formato',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
            })
      }

    },
    async checkValidation() {
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
  },
  watch: {
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
  }
}
</script>
