<template>
  <section>
    <div class="container">

        <b-tabs @input="changeTab" type="is-boxed" :destroy-on-hide="true" v-model="currentTab">
          <b-tab-item v-for="item in items"
                      :value="item.content"
                      v-bind:key="item.content"
                      :label="item.tab"
                      :visible="(item.content ==='DownloadsTab') ? can_download === 'true' : true">
          </b-tab-item>
        </b-tabs>
        <!-- COMPONENTE AQUI -->

          <component v-bind:is="currentTabComponent" :person-id="personId" :can_create_appointment="can_create_appointment" :can_request_appointment="can_request_appointment"
          :can_edit_person="can_edit_person" :can_create_study="can_create_study" :can_create_household="can_create_household"
                     :can_create_event="can_create_event" :can_create_commission="can_create_commission" :can_list_null="can_list_null"
                     :can_assign_function="can_assign_function" :can_administrative_function="can_administrative_function"
          :person_name="person_name" :can_create_continuing_study="can_create_continuing_study"></component>

        <!-- FIN COMPONENTE -->

    </div>
  </section>
</template>
<script>
import PersonalData from "../person/personal_data"
import AppointmentsTabs from "../life_record/appointments_tabs"
import StudyTabs from "../life_record/study_tabs"
import HouseholdTabs from "../life_record/household_allowances/household_tabs"
import Comissions from "../life_record/comissions"
import EventBus from '../eventBus';
import Rectifications from '../life_record/rectify/rectifications'
import FunctionTabs from './functions/function_tabs'
import EventTabs from './event_tabs'
import DownloadsTab from './downloads_tab'
export default {
  props:[
    'personId', 'can_create_appointment', 'can_edit_person', 'can_create_study', 'can_create_household', 'can_create_event',
    'can_create_commission', 'can_list_null', 'person_name', 'can_assign_function', 'can_administrative_function','can_download',
      'can_create_continuing_study', 'can_request_appointment'
  ],
  data(){
    return {
      currentTab: 'PersonalData',
      items: [
        {tab: 'Datos Personales',content:'PersonalData'},
        {tab: 'Nombramientos',content:'AppointmentsTabs'},
        {tab: 'Funciones', content: 'FunctionTabs'},
        {tab: 'Asignación Familiar', content: 'HouseholdTabs'},
        {tab: 'Estudios',content:'StudyTabs'},
        {tab: 'Comisiones', content: 'Comissions'},
        //{tab: "Rectificaciones", content: 'Rectifications'},
        {tab: 'Eventos',content:'EventTabs'},
        //{tab:'Descargas',content:'DownloadsTab'}

      ]
    }
  },
  components:{
    PersonalData,
    AppointmentsTabs,
    HouseholdTabs,
    StudyTabs,
    EventTabs,
    Comissions,
    Rectifications,
    FunctionTabs,
    DownloadsTab
  },
  methods:{
    changeTab: function(value){
      this.currentTab = value
    },
    selectTab(tab){
      this.currentTab = tab
    }
  },
  computed: {
    currentTabComponent: function () {
      return this.currentTab
    }
  },
}
</script>












































