<template>
  <section>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column">
            <b-field label="Unidad">
              <b-input :value="person_unit.unit!==undefined? person_unit.unit.name : ''" type="text" readonly>
              </b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Nombre">
              <b-input :value="complete_name" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Rut">
              <b-input :value="complete_rut" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Correo corporativo">
              <b-input :value="corporate_mail" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Descripción">
              <b-input :value="person_unit.description" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Rol">
              <b-input :value="person_unit.internal_position!==undefined ? person_unit.internal_position.name : ''" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Fecha desde">
              <b-input :value="formatDate(person_unit.start_date)" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha hasta">
              <b-input :value="formatDate(person_unit.end_date)" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
      </section>
    </div>
    <footer class="modal-card-foot actions">
      <div class="has-text-right is-grouped">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
        <b-button class="is-primary"
                  @click="openEdit">
          Editar
        </b-button>
      </div>
    </footer>
    <b-modal
        v-model="isEditModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        width="1024"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-add-annex-roles
            :id="id_to_edit"
            :queryType="queryType_to_edit"
            :personId="personId_to_edit"
            query-type="update"
            modal-header="Rol"
            @close="props.close"></modal-add-annex-roles>
      </template>
    </b-modal>
  </section>
</template>

<script>
import axios from "axios";
import ModalAddAnnexRoles from './modal_add_annex_roles';
export default {
  name: "modal_show_annex_roles",
  props:[
    "id", "modalHeader", "complete_name", "complete_rut", "corporate_mail", "person"
  ],
  components:{
    ModalAddAnnexRoles
  },
  data(){
    return {
      isLoading: false,
      isEditModalActive:false,
      person_unit: {},
      id_to_edit: "",
      person_to_edit: "",
      queryType_to_edit: "",
      personId_to_edit: "",
      canCancel: ['escape'],
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      const loadingComponent = this.$buefy.loading.open();
      return axios
          .get("/admin/person_units/" + this.id + ".json")
          .then(res => {
            this.person_unit = res.data
            loadingComponent.close();
          }).catch(error => {
            console.log(error)
            loadingComponent.close();
          })
    },
    openEdit(){
      this.isLoading = true
      this.id_to_edit = this.id
      this.personId_to_edit = this.person.id
      this.queryType_to_edit = "update"
      this.person_to_edit = this.person
      this.isEditModalActive = true
      this.isLoading = false
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
  },
  watch: {
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
  }
}
</script>
