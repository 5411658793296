<template>
  <section>
    <div class="columns">
      <div name="contenido" class="column">

        <div class="container mt-6">
          <div class="box">
            <section class="hero is-primary welcome is-small mb-4">
              <div class="hero-body">
                <div class="container">
                  <h1 class="title">
                    Importación Masiva
                  </h1>
                </div>
              </div>
            </section>


            <section class="info-tiles">
              <div class="tile is-ancestor has-text-centered">
                <div class="tile is-parent">
                  <article class="tile is-child box">
                    <a href="/life_record/extension_hours">
                      <p class="subtitle is-4">
                      <span class="icon">
                        <i class="fas fa-chalkboard-teacher"></i>
                      </span>
                        Extensión Profesores Hora
                        <b-tooltip label="Aplicación masiva de extensión de Profesores Hora mediante la importación de archivos excel."
                                   position="is-right"
                                   size="is-large"
                                   multilined>
                          <b-icon
                              pack="fas"
                              icon="info-circle"
                              size="is-small"
                              type="is-info">
                          </b-icon>
                        </b-tooltip>
                      </p>
                    </a>
                  </article>
                </div>
                <div class="tile is-parent">
                  <article class="tile is-child box">
                    <a href="/life_record/continuity_hours">
                      <p class="subtitle is-4">
                      <span class="icon">
                        <i class="fas fa-chalkboard-teacher"></i>
                      </span>
                        Continuidad Profesores Hora
                        <b-tooltip label="Aplicación masiva de continuidad de Profesores Hora mediante la importación de archivos excel por departamentos."
                                   position="is-right"
                                   size="is-large"
                                   multilined>
                          <b-icon
                              pack="fas"
                              icon="info-circle"
                              size="is-small"
                              type="is-info">
                          </b-icon>
                        </b-tooltip>
                      </p>
                    </a>
                  </article>
                </div>
                <div class="tile is-parent">
                  <article class="tile is-child box">
                    <a href="/life_record/increase-hours">
                      <p class="subtitle is-4">
                      <span class="icon">
                        <i class="fas fa-plus-circle"></i>
                      </span>
                        Aumento Horas Profesores Hora
                        <b-tooltip label="Aplicación masiva de aumentos de hora para Profesores Hora mediante la importación de archivos excel por departamentos."
                                   position="is-right"
                                   size="is-large"
                                   multilined>
                          <b-icon
                              pack="fas"
                              icon="info-circle"
                              size="is-small"
                              type="is-info">
                          </b-icon>
                        </b-tooltip>
                      </p>
                    </a>
                  </article>
                </div>
              </div>
            </section>



            <section class="info-tiles">
              <div class="tile is-ancestor has-text-centered">
                <div class="tile is-parent">
                  <article class="tile is-child box">
                    <a href="/life_record/entry_life_record">
                      <p class="subtitle is-4">
                      <span class="icon">
                        <i class="fas fa-users"></i>
                      </span>
                        Ingreso masivo de Personas
                        <b-tooltip label="Ingreso masivo de personas a Hoja de Vida."
                                   position="is-right"
                                   size="is-large"
                                   multilined>
                          <b-icon
                              pack="fas"
                              icon="info-circle"
                              size="is-small"
                              type="is-info">
                          </b-icon>
                        </b-tooltip>
                      </p>
                    </a>
                  </article>
                </div>
              </div>
            </section>
            <section class="info-tiles">
              <div class="tile is-ancestor has-text-centered">
                <div class="tile is-parent">
                  <article class="tile is-child box">
                    <a href="/life_record/entry_life_record_appointment">
                      <p class="subtitle is-4">
                      <span class="icon">
                        <i class="fas fa-users"></i>
                      </span>
                        Ingreso masivo de Nombramientos
                        <b-tooltip label="Ingreso masivo de nombramientos a Hoja de Vida."
                                   position="is-right"
                                   size="is-large"
                                   multilined>
                          <b-icon
                              pack="fas"
                              icon="info-circle"
                              size="is-small"
                              type="is-info">
                          </b-icon>
                        </b-tooltip>
                      </p>
                    </a>
                  </article>
                </div>
              </div>
            </section>

          </div>
        </div>


      </div>
    </div>

  </section>
</template>

<script>

export default {
  name: "LifeRecordImportDashboard",
  data(){
    return {
      selected: null,
      canCancel: ['escape'],
      isAddModalActive: false
    }
  },
  created(){
  },
  methods: {
  }
}
</script>