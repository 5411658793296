<template>
    <section>

        <div v-if="can_create_award === 'true'" class="columns">
            <div class="column has-text-right">
                <b-button type="is-primary" icon-left="plus" label="Agregar Reconocimiento" @click="newAward()" />
            </div>
        </div>

        <b-table striped :data="awards" paginated per-page=10 pagination-position="bottom" aria-next-label="Siguiente"
            aria-previous-label="Anterior" aria-page-label="Página" aria-current-label="Página actual">

            <b-table-column label="Nombre del Reconocimiento" field="name" sortable searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.name }}
                </template>
            </b-table-column>

            <b-table-column label="Características" field="description" sortable searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Características..."
                        icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.description }}
                </template>
            </b-table-column>

            <b-table-column label="Nombre de la Institución" field="partner_entity_name" sortable searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Institución..."
                        icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.partner_entity_name }}
                </template>
            </b-table-column>

            <b-table-column label="Clase del Reconocimiento" field="award_type" sortable searchable>
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Clase..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.award_type }}
                </template>
            </b-table-column>

            <b-table-column label="Fecha" field="award_date" sortable searchable :custom-search="searchInitDate">
                <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha..." icon="search" />
                </template>
                <template v-slot="props">
                    {{ props.row.award_date._isValid ? new Date(props.row.award_date).toLocaleDateString("es-CL") : '' }}
                </template>
            </b-table-column>

            <b-table-column v-show="cargoPrincipal.name == 'ANALISTA I+D FING' || cargoPrincipal.name == 'SUPERADMIN'"
                label="Acciones" v-slot="props">
                <div class="columns">
                    <div v-if="props.row.can_show" class="column">
                        <b-tooltip
                            label="Mostrar en detalle la información del reconocimiento"
                            :active="true">
                            <b-button pack="fas" icon-right="eye" type="is-info"
                                    @click="isModalShowActive = true; selectedAward = props.row" />
                        </b-tooltip>
                    </div>
                    <div v-if="props.row.can_update" class="column">
                      <b-tooltip
                          label="Editar la información del reconocimiento"
                          :active="true">
                        <b-button pack="fas" icon-right="edit" type="is-warning"
                                  @click="isModalActive = true; selectedAward = props.row; update = true" />
                      </b-tooltip>
                    </div>
                    <div v-if="props.row.can_null" class="column">
                        <b-tooltip
                            label="Anular registro del reconocimiento"
                            :active="true">
                            <b-button pack="fas" icon-right="ban" type="is-danger" @click="nullAward(props.row)" />
                        </b-tooltip>

                    </div>
                </div>
            </b-table-column>

            <template slot="empty">
                <div class="columns is-centered">
                    <div class="column is-6 is-centered has-text-centered">
                        <b-message has-icon type="is-warning">
                            <h1> No se encontraron resultados.</h1>
                        </b-message>
                    </div>
                </div>
            </template>

        </b-table>

        <b-modal v-model="isModalActive" has-modal-card trap-focus :destroy-on-hide="true" width="90%" aria-role="dialog"
            aria-modal :can-cancel="false">
            <template #default="props">
                <modal_new_award :person-id="personId" :update="update" :old_award="selectedAward" @close="props.close"
                    @refresh="getAwards()" />
            </template>
        </b-modal>

        <b-modal v-model="isModalShowActive" has-modal-card trap-focus :destroy-on-hide="true" width="90%"
            aria-role="dialog" aria-modal :can-cancel="false">
            <template #default="props">
                <ModalShowAward :person-id="personId" :old_award="selectedAward" @close="props.close"
                    @refresh="getAwards()" />
            </template>
        </b-modal>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

    </section>
</template>

<script>
import modal_new_award from "./modal_new_award"
import ModalShowAward from "./modal_show_award.vue"
import axios from "axios"
import { searchDate } from "../../packs/utilities"
import moment from "moment"
export default {
    name: "awards_data_tab",
    components: {
        modal_new_award, ModalShowAward,
    },
    props: [
        'personId', 'minYear', 'maxYear', 'changeFilter', 'cargoPrincipal', 'can_create_award'
    ],
    data() {
        return {
            awards: [],
            isModalActive: false,
            isLoading: false,
            update: false,
            isModalShowActive: false,
            selectedAward: {},
        }
    },
    created() {
        this.getAwards()
    },
    methods: {
        newAward() {
            this.isModalActive = true
        },
        getAwards() {
            this.isLoading = true
            this.update = false
            axios
                .get('/my-awards/' + this.personId + '.json', {
                    params: {
                        minYear: this.minYear,
                        maxYear: this.maxYear,
                    }
                })
                .then(response => {
                    this.awards = response.data
                    this.awards = this.awards.map(award => {
                        var newAward = award
                        newAward["award_date"] = moment(newAward["award_date"], ["YYYY-MM-DD", "DD-MM-YYYY"]);
                        return newAward;
                    })
                    this.isLoading = false
                })
                .catch(error => {
                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al traer sus Reconocimientos de la persona consultada, favor de revisar la consola.',
                        type: 'is-danger',
                        hasIcon: true,
                        iconPack: 'fa',
                        icon: 'times-circle',
                        confirmText: 'Ok',
                    })
                    console.log(error)
                    this.isLoading = false
                })
        },
        searchInitDate(row, input) {
            return searchDate(row.award_date, input)
        },

        nullAward(award) {

            this.$buefy.dialog.confirm({
                title: 'Anular Reconocimiento',
                message: '¿Está seguro que desea <b>anular</b> el Reconocimiento? Esta acción lo dejará inválido.<br>',
                confirmText: 'Anular Reconocimiento',
                cancelText: 'Cancelar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: async () => {
                    this.isLoading = true
                    await axios
                        .delete("/awards/" + award.id + "/nullify-award.json")
                        .then(response => {
                            this.$buefy.dialog.alert({
                                title: 'Logrado',
                                type: 'is-success',
                                hasIcon: true,
                                icon: 'check-circle',
                                iconPack: 'fas',
                                message: 'El Reconocimiento ha sido anulada exitosamente.',
                                confirmText: 'Aceptar'
                            })
                            this.getAwards()
                        })
                        .catch(error => {
                            this.$buefy.dialog.alert({
                                title: 'Error',
                                message: 'Hubo un error al anular el Reconocimiento.<br><b> Errores:</b><br>' + error.response.data,
                                type: 'is-danger',
                                hasIcon: true,
                                icon: 'times-circle',
                                iconPack: 'fa',
                                ariaRole: 'alertdialog',
                                ariaModal: true
                            })
                            this.isLoading = false
                        })
                }
            })

        }

    },
    watch: {
        changeFilter: function () {
            this.getAwards()
        }
    }
}
</script>