<template>
  <section>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <div class="container">
          <div class="columns has-text-centered">
            <div class="column">
              <h1 class="is-size-3">{{ program_person.person_name}}</h1> <br v-if="program_person.unit_name != null"> {{ program_person.unit_name != null? program_person.unit_name:'' }}
              <br v-if="program_person.grade != null">{{ program_person.grade != null? "GRADO MAYOR - "+program_person.grade:'' }}
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Función">
                <b-input readonly v-model="program_person.function.name" type="text"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Fecha de Inicio">
                <b-input readonly v-model="program_person.program_person.show_start_date" type="text"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Fecha de Término">
                <b-input readonly v-model="program_person.program_person.show_end_date" type="text"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Link de documentación">
                <b-input readonly v-model="program_person.program_person.documentantion_link" type="text"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-narrow">
              <b-field label="¿Es Director/a del Programa?">
                <b-radio-button v-model="program_person.program_person.is_director"
                                :native-value="false"
                                :disabled="true"
                                type="is-danger is-light is-outlined">
                  <span>No</span>
                </b-radio-button>
                <b-radio-button v-model="program_person.program_person.is_director"
                                :native-value="true"
                                :disabled="true"
                                type="is-success is-light is-outlined">
                  <span>Si</span>
                </b-radio-button>
              </b-field>
            </div>
            <div class="column is-narrow">
              <b-field label="¿Es Miembro del Cómite?">
                <b-radio-button v-model="program_person.program_person.is_comittee_member"
                                :native-value="false"
                                :disabled="true"
                                type="is-danger is-light is-outlined">
                  <span>No</span>
                </b-radio-button>
                <b-radio-button v-model="program_person.program_person.is_comittee_member"
                                :native-value="true"
                                :disabled="true"
                                type="is-success is-light is-outlined">
                  <span>Si</span>
                </b-radio-button>
              </b-field>
            </div>
          </div>
        </div>
      </section>
    </div>
    <footer class="modal-card-foot actions" style="justify-content: flex-end;">
      <div class="field is-grouped is-pulled-right">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
        <b-button @click="editAcademic" class="is-primary">Editar</b-button>
      </div>
    </footer>
    <b-modal
        width="90%"
        v-model="modalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <add-academic
            query-type="update"
            modal-header="Editar académico"
            :program_person_selected="program_person"
            :code_program_career="code_program_career"
            :resolution_number="resolution_number"
            :mention="mention"
            :version="version"
            :id="program_person.program_person.id"
            @clicked="onClickChild"
            @close="props.close"></add-academic>
      </template>
    </b-modal>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import AddAcademic from "./AddAcademic";
import axios from "axios";
import moment from "moment";
export default {
  name: "ShowAcademic",
  props:['modalHeader','program_person','code_program_career', 'mention', 'resolution_number', 'version','start_date','end_date'],
  components:{AddAcademic},
  data(){
    return{
      modalActive: false,
      canCancel: ['escape'],
      isLoading:false
    }
  },
  methods:{
    onClickChild(){
      this.isLoading = true
      let vm = this;
      vm.isLoading = true
      return axios
          .get("/program_people/"+vm.program_person.program_person.id+".json" )
          .then(res =>{
            vm.program_person.program_person = res.data

            vm.program_person.program_person.show_start_date = null
            vm.program_person.program_person.show_end_date = null
            if(vm.program_person.program_person.is_director == null){
              vm.program_person.program_person.is_director = false
            }

            if(vm.program_person.program_person.is_comittee_member == null){
              vm.program_person.program_person.is_comittee_member = false
            }

            if (vm.program_person.program_person.start_date !== null) {
              vm.program_person.program_person.show_start_date = moment(vm.program_person.program_person.start_date, 'YYYY-MM-DD')._d;
              vm.program_person.program_person.show_start_date = new Date(vm.program_person.program_person.show_start_date).toLocaleDateString("es-CL")
            }

            if (vm.program_person.program_person.end_date !== null) {
              vm.program_person.program_person.show_end_date = moment(vm.program_person.program_person.end_date, 'YYYY-MM-DD')._d;
              vm.program_person.program_person.show_end_date = new Date(vm.program_person.program_person.show_end_date).toLocaleDateString("es-CL")
            }

            vm.program_person.function = res.data['function']
            vm.isLoading = false
          })
          .catch(e =>{
            console.log(e)
            vm.isLoading = false
          })
    },
    editAcademic(){
      this.modalActive = true
    }
  }
}
</script>

<style scoped>

</style>