<template>
  <section>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <div class="columns" v-if="person_unit.has_rectification">
          <RectificationAlert v-if="person_unit.has_rectification"></RectificationAlert>
        </div>
        <div class="columns">
          <div class="column">
            <b-button class="is-danger" v-if="person_unit.can_distance"
                      @click="isDistanceModalActive = true">
              Alejamiento
            </b-button>
          </div>
          <div class="column has-text-right">
            <b-field label="Marcado para prórroga semi-automática">
              <b-checkbox :value="person_unit.renewable" disabled></b-checkbox>
            </b-field>
          </div>
        </div>
        <div class ="columns">
          <div class="column">
            <b-dropdown aria-role="list" v-if="person_unit.can_rectify">
              <template #trigger="{ active }">
                <b-button
                    label="Acciones"
                    type="is-warning"
                    :icon-right="active ? 'chevron-circle-up' : 'chevron-circle-down'" />
              </template>


              <b-dropdown-item aria-role="listitem" @click="rectify()">Rectificar</b-dropdown-item>
              <b-dropdown-item aria-role="listitem" @click="addHours()">Aumento Horas</b-dropdown-item>
              <b-dropdown-item aria-role="listitem" @click="reduceHours()">Reducción Horas</b-dropdown-item>
              <b-dropdown-item aria-role="listitem" @click="changeCC()">Cambio CC</b-dropdown-item>
              <!--<b-dropdown-item aria-role="listitem" @click="noEffect()">Deja Sin Efecto</b-dropdown-item>-->
            </b-dropdown>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Descripción">
              <b-input :value="person_unit.description" type="textarea" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Tipo de documento">
              <b-input :value="person_unit.resolution_type!==undefined ? person_unit.resolution_type.name : ''" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="N° de documento">
              <b-input :value="person_unit.resolution" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha documento">
              <b-input :value="formatDate(person_unit.resolution_date)" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Tipo de nombramiento">
              <b-input :value="person_unit.appointment_type!==undefined ? person_unit.appointment_type.name : ''" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Cargo">
              <b-input :value="person_unit.work_plant!==undefined ? person_unit.work_plant.name : ''" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Calidad de contrato">
              <b-input :value="person_unit.quality!==undefined ? person_unit.quality : ''" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Función">
              <b-input :value="person_unit.function_name!==undefined ? person_unit.function_name : ''" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Unidad de contratación">
              <b-input :value="person_unit.contracting_unit!==undefined ? person_unit.contracting_unit.name : ''" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Unidad de desempeño">
              <b-input :value="person_unit.unit!==undefined? person_unit.unit.name : ''" type="text" readonly>
              </b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Área de Desempeño">
              <b-input :value="person_unit.performance_area!==undefined? person_unit.performance_area : ''" type="text" readonly>
              </b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Jornada (horas)">
              <b-input :value="person_unit.working_day!==undefined? person_unit.working_day.hired_hours : ''" type="text" readonly>
              </b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha desde">
              <b-input :value="formatDate(person_unit.start_date)" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha hasta">
              <b-input :value="formatDate(person_unit.end_date)" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Nivel">
              <b-input :value="(person_unit.level!==undefined && person_unit.level!==null)? person_unit.level.name : ''" type="text" readonly>
              </b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Grado">
              <b-input :value="(person_unit.grade!==undefined && person_unit.grade!==null)? person_unit.grade.name : ''" type="text" readonly>
              </b-input>
            </b-field>
          </div>
        </div>
        <div class="columns is-gapless">
          <div class="column">
            <b-field label="Documento">
              <b-input expanded readonly v-model="person_unit.document_name" type="text"></b-input>
            </b-field>
          </div>
          <div class="column is-narrow">
            <br>
            <b-field v-if="person_unit.can_view_pdf" label="  ">
              <b-button @click="viewFile(person_unit.document_id)" icon-left="eye" type="is-success">ver</b-button>
              <b-button @click="downloadFile(person_unit.document_id)" icon-left="download" type="is-info">Descargar</b-button>
            </b-field>
          </div>
        </div>
        <div class="columns" style="margin-bottom: 40px;" v-if="link">
          <div class="column has-text-left">
            <div class="row">
              <b-field label="Alejamiento Vinculado">
              <p class="control">
                <b-button class="is-primary" icon-right="link" @click="openLinkedDistance(person_unit.linked_distance_id)">Ver {{person_unit.linked_distance_id.custom_name}}</b-button>
              </p>
              </b-field>
            </div>
          </div>
        </div>

        <div class="columns" v-if="rectifications.length>0">
          <h3 class="is-size-3 has-text-weight-bold">Rectificaciones</h3>
        </div>
        <b-table
            v-if="rectifications.length>0"
            :data="rectifications"
            :paginated= true
            per-page="10"
            striped
            hoverable
            :current-page="currentPage"
            :pagination-simple=false
            pagination-position="bottom"
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            default-sort="product.title"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual">
          <b-table-column
              field="decree"
              label="Decreto"
              numeric
              searchable
              sortable
              width="100">
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.decree}}
            </template>
          </b-table-column>
          <b-table-column
              field="event_type_name"
              label="Tipo Evento"
              sortable
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.event_type_name}}
            </template>
          </b-table-column>
          <b-table-column
              field="start_date"
              label="Fecha Desde"
              width="110"
              sortable
              searchable :custom-search="searchStartDate">
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.start_date}}
            </template>
          </b-table-column>
          <b-table-column
              label="Acciones"
              width="10"
              v-slot="props">
            <b-icon
                pack="fas"
                icon="eye"
                size="is-medium"
                @click.native="execShowEvent(props.row)"/>
          </b-table-column>
        </b-table>

      </section>
    </div>
    <footer class="modal-card-foot actions">
      <div class="has-text-right is-grouped">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
        <b-button class="is-primary"
                  v-if="person_unit.can_edit"
                  @click="isEditModalActive = true">
          Editar
        </b-button>
      </div>
    </footer>
    <b-modal
        v-model="isEditModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <appointment-form :person-id="person_unit.person_id"
                          :id="person_unit.id"
                          query-type="update"
                          :modal-header="'Editar Nombramiento - '+person_name"
                          @close="props.close">
        </appointment-form>
      </template>
    </b-modal>
    <b-modal
        v-model="isDistanceModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <distance-form :person-id="person_unit.person_id"
                       :id="person_unit.id"
                       :person_unit_id="person_unit.id"
                       query-type="create"
                       :modal-header="'Nuevo Alejamiento - '+person_name"
                       :person_name="person_name"
                       @close="props.close">
        </distance-form>
      </template>
    </b-modal>

    <b-modal
        v-model="isLinkedDistanceModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <component  :is="showName"
                    :id="selected"
                    :modal-header="title"
                    @close="props.close"/>
      </template>
    </b-modal>

    <b-modal
        v-model="isShowModalActiveRectification"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <rectification :id="selected_event"
                       :modal-header="form_name"
                       :person_name="person_name"
                       @close="props.close"
                       :event_type_name="event_type_name">
        </rectification>
      </template>
    </b-modal>
    <b-modal
        v-model="showPdf"
        :can-cancel="true"
        :destroy-on-hide="true"
        aria-modal
        aria-role="dialog"
        has-modal-card
        trap-focus
        :width="2000"
    >
      <template #default="props">
        <pdf_view
            :src="src"
            @close="props.close"
        ></pdf_view>
      </template>

    </b-modal>
    <b-loading :is-full-page="true"
               v-model="isLoading"
               :can-cancel="false"/>

  </section>
</template>

<script>
 import axios from "axios";
 import AppointmentForm from "../life_record/modal_administrative_appointment_form.vue"
 import DistanceForm from "../life_record/modal_distance_form.vue"
 import Distance from "../life_record/distance.vue"
 import RectificationAlert from "./rectification_alert"
 import CollapseEvent from "./collapse_event"
 import {formatDate, searchDate} from "../../packs/utilities";
 import Rectification from "./rectify/rectification"
 import Pdf_view from "../performances/pdf_view.vue";
 export default {
    name: "appointment",
    props:[
        "id", "modalHeader", "person_name"
    ],
    components:{
      Pdf_view,
      AppointmentForm,
      DistanceForm,
      Distance,
      RectificationAlert,
      CollapseEvent,
      Rectification
    },
    data(){
      return {
        isLoading: false,
        isEditModalActive:false,
        person_unit: {},
        isDistanceModalActive: false,
        isLinkedDistanceModalActive: false,
        link: false,
        selected: null,
        formName: "",
        showName: "",
        title: "",
        isOpen: false,
        rectifications: [],
        CollapseEvent: "CollapseEvent",
        states_array: [],
        isShowModalActiveRectification: false,
        form_name: "",
        selected_event: null,
        type_of_event: "",
        currentPage: 1,
        event_type_name: "",
        showPdf: false,
        src:null,
      }
    },
   created() {
     this.initialize();
   },
    methods: {
      noEffect(){
        let url = "/life_record/rectify/"+this.id+"?event_type_id=13" //establishment académicos
        window.location.href = url;
      },
      changeCC(){
        console.log("cambio cc...")
        let url = "/life_record/rectify/"+this.id+"?event_type_id=12" //establishment académicos
        window.location.href = url;
      },
      addHours(){
        console.log("aumentar horas...")
        let url = "/life_record/rectify/"+this.id+"?event_type_id=11" //establishment académicos
        window.location.href = url;
      },
      reduceHours(){
        console.log("reducir horas...")
        let url = "/life_record/rectify/"+this.id+"?event_type_id=10" //establishment académicos
        window.location.href = url;
      },
      rectify(){
        console.log("rectificar...")
        let url = "/life_record/rectify/"+this.id+"?event_type_id=9" //establishment académicos
        window.location.href = url;
      },
      openLinkedDistance(linkedDistance){
        this.selected = linkedDistance.id;
        this.showName = "Distance"
        this.title = "Alejamiento"
        this.isLinkedDistanceModalActive = true;
      },
      initialize() {
        const loadingComponent = this.$buefy.loading.open();
        return axios
          .get("/admin/person_units/" + this.id + ".json")
          .then(res => {
            this.person_unit = res.data
            if(this.person_unit.linked_distance_id){
              this.link = true
            }
            this.rectifications = res.data["rectifications"]
            for(var i = 0; i<this.rectifications.length; i++){
              this.states_array.push(false)
            }
            if(this.person_unit.linked_distance_id){
              this.link = true
            }
            loadingComponent.close();
          }).catch(error => {
            console.log(error)
            loadingComponent.close();
          })
      },
      formatDate,
      execShowEvent(row){
        this.selected_event = row.id;
        this.isShowModalActiveRectification = true;
        this.type_of_event = row.event_type_name
        if(row.event_type_name==="RECTIFICACION"){
          this.form_name = "Rectificación - "+this.person_name
          this.event_type_name = "Rectificación"
        }
        else if(row.event_type_name==="REDUCCION DE HORAS"){
          this.form_name = "Reducción de Horas - "+this.person_name
          this.event_type_name = "Reducción de Horas"
        }
        else if(row.event_type_name==="AUMENTO DE HORAS"){
          this.form_name = "Aumento de Horas - "+this.person_name
          this.event_type_name = "Aumento de Horas"
        }
        else if(row.event_type_name==="CAMBIO DE CENTRO DE COSTO"){
          this.form_name = "Cambio de CC - "+this.person_name
          this.event_type_name = "Cambio de Centro de Costo"
        }
      },
      searchStartDate(row, input){
        return searchDate(row.start_date, input)
      },
      viewFile(fileId){
        this.isLoading = true;
        axios
            .get("/documents/" + fileId + "/download", {
              responseType: "blob",
              params:{
                life_record: true
              }
            })
            .then(async res => {
              const file = new File([new Blob([res.data])], "test");
              const buffer = await file.arrayBuffer();
              this.src = new Uint8Array(buffer);
              this.showPdf = true
              this.isLoading = false
            })
            .catch(e => {
              console.log(e)
              this.isLoading = false
              this.$buefy.dialog.alert({
                message: "ERROR al previsualizar",
                type: "is-danger",
                hasIcon: true
              })
            })

      },
      downloadFile(fileId){
        this.isLoading = true;

        if (fileId == null){
          this.isLoading = false;
          return;
        }

        axios
            .get("/documents/" + fileId + "/download", {
              responseType: "blob",
              params:{
                life_record: true
              }
            })
            .then(res => {
              let blob = new Blob([res.data], {
                type: "application/pdf",
              })
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = res.headers["content-disposition"]
                  .split(";")[1]
                  .split('"')[1];
              link.click();
              this.isLoading=false
              this.$buefy.notification.open({
                message: 'El documento fue descargado con exito',
                type: 'is-success',
                hasIcon: true,
                position: 'is-bottom-right',
                duration: 5000,
                progressBar: true,
              })
            })
            .catch(e => {
              console.log(e)
              this.isLoading=false
              this.$buefy.notification.open({
                message: 'El documento no se pudo descargar',
                type: 'is-danger',
                hasIcon: true,
                position: 'is-bottom-right',
                duration: 5000,
                progressBar: true,
              })
            })
      },
    },
   watch: {
     isEditModalActive: function () {
       if (!this.isEditModalActive) {
         this.initialize()
       }
     },
     isDistanceModalActive: function () {
       if (!this.isDistanceModalActive) {
         this.initialize()
       }
     },
     isLinkedDistanceModalActive: function(){
       if(!this.linkedDistanceModalActive){
         this.initialize
       }
     }

   }
 }
</script>
