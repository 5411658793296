<template>
  <section>
    <b-tabs position="is-centered" v-model="activeTab" >
      
            <b-tab-item label="Todos" icon="book">          
              <transaction-table v-bind:transacciones="this.transacciones"></transaction-table>
            </b-tab-item>
            <b-tab-item label="Bien" icon="shopping-bag">

              <transaction-table v-bind:transacciones="this.bienes" ></transaction-table>
            </b-tab-item>
            <b-tab-item label="Contratación" icon="handshake">

              <transaction-table v-bind:transacciones="this.contrataciones"  ></transaction-table>
            </b-tab-item>
            <b-tab-item label="Servicio" icon="receipt">

              <transaction-table v-bind:transacciones="this.servicios" ></transaction-table>
            </b-tab-item>
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </b-tabs>

         
  </section>
</template>
<script>
import axios from "axios";
import TransactionTable from "./TransactionTable.vue";
import moment from "moment";
export default {
  props:[
    'unitId','currentYear', 'minYear', 'maxYear', 'changeFilter'
  ],
  data(){
    return{
      transacciones:[],
      bienes: [],
      servicios: [],
      contrataciones: [],
      isLoading:false,
      activeTab: 0,
      year: new Date().getFullYear(),
    }
  },
  components:{
   TransactionTable
  },
  created(){
    this.getTransaction();

  },
  methods:{
    changeTab: function(value){
      this.currentTab = value
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    getTransaction(){
      let vm = this;
      vm.isLoading=true;

      axios.get("/sdt_transactions.json", {params:{
                                              min_year:vm.minYear,
                                              max_year:vm.maxYear,
                                              id_unit: vm.unitId,
                                              origen: "EGRESO"
                                              }})
        .then(res => {

          vm.transacciones = res.data;
          vm.transacciones = vm.transacciones.map(transaccion => {
            var newTransaccion = transaccion
            newTransaccion["payment_date"] = moment(transaccion.payment_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            return newTransaccion;
          });
          vm.bienes = vm.transacciones.filter(transaccion => transaccion.sdt_origin_transaction_type === "Bien");
          vm.servicios = vm.transacciones.filter(transaccion => transaccion.sdt_origin_transaction_type === "Servicio");
          vm.contrataciones = vm.transacciones.filter(transaccion => transaccion.sdt_origin_transaction_type === "Contratación");
          
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });


    }

  },
  computed:{

  },
  watch:{
    changeFilter: function(){
      this.getTransaction();
    }
  }
}
</script>