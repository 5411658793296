<template>
    <div class="newview">
        <div class="hero">
            <div class="hero-head">
                <br>
                <br>
                <div class="columns is-centered">
                    <h1 class="is-size-2" style="text-align:center;">Registro de un nuevo egresado</h1>
                </div>
            </div>
            <div class="hero-body">
                <NewForm/>
            </div>
        </div>
    </div>
</template>
<script>
import NewForm from './new_form.vue'
export default {
    name: 'NewView',
    components:{
        NewForm
    },
    data (){
        return{

        }
    }
}
</script>