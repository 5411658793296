<template>
  <section>
    <h1 class="subtitle is-2 has-text-primary">
      ¿Qué información hay en Mi Hoja de Vida?
    </h1>
    <b-tabs position="is-centered" class="block" type="is-toggle" @input="changeTab" v-model="currentTab">
      <b-tab-item label="Datos Personales" value="1">

      </b-tab-item>
      <b-tab-item label="Nombramientos" value="2">

      </b-tab-item>
      <b-tab-item label="Funciones" value="3">

      </b-tab-item>
      <b-tab-item label="Asignaciones Familiares" value="4">

      </b-tab-item>
      <b-tab-item label="Estudios" value="5">

      </b-tab-item>
      <b-tab-item label="Comisiones" value="6">

      </b-tab-item>
      <b-tab-item label="Eventos" value="7">

      </b-tab-item>
    </b-tabs>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Información disponible en Mi Hoja de Vida
    </h2>

  </section>
</template>
<script>
import EventBus from "../../eventBus";
export default {
  name: "what_info_mhv",
  data(){
    return {
      currentTab: "1"
    }
  },
  methods:{
    changeTab: function(value){
      this.currentTab = value
    },
  },
  mounted() {
    EventBus.$on('SELECT_TAB', (payload) => {
      this.currentTab = payload.toString()
    })
  }
}
</script>