<template>
  <div class="section">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <div class="field is-grouped">
          <p class="control">
            <b-button class="is-primary is-outlined"
                      @click="isICC = true">
              ICC
            </b-button>
          </p>
          <p class="control">
            <b-button class="is-primary is-outlined"
                      @click="isCommission = true">
              Comisión
            </b-button>
          </p>
          <p class="control">
            <b-button class="is-primary is-outlined"
                      @click="isStudyCommission = true">
              Comisión de Estudios
            </b-button>
          </p>
        </div>
      </section>

    </div>
    <footer class="modal-card-foot actions">
      <div class="field is-grouped is-pulled-right">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
      </div>
    </footer>

    <b-modal
        v-model="isICC"
        has-modal-card
        trap-focus
        width="1024"
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-icc-form :person-id="personId"
                        query-type="create"
                        :modal-header="'Nuevo ICC - '  +person_name"
                        :person_name="person_name"
                        @close="props.close"></modal-icc-form>
      </template>
    </b-modal>

    <b-modal
        v-model="isCommission"
        has-modal-card
        trap-focus
        width="90%"
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-commission-form :person-id="personId"
                               query-type="create"
                               :modal-header="'Nueva Comisión - '  +person_name"
                               :person_name="person_name"
                               @close="props.close"></modal-commission-form>
      </template>
    </b-modal>

    <b-modal
        v-model="isStudyCommission"
        has-modal-card
        trap-focus
        width="1024"
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-study-commission-form :person-id="personId"
                               query-type="create"
                               :modal-header="'Nueva Comisión de Estudios - '  +person_name"
                               :person_name="person_name"
                               @close="props.close"></modal-study-commission-form>
      </template>
    </b-modal>



  </div>

</template>

<script>
import ModalIccForm from '../life_record/modal_icc_form'
import ModalCommissionForm from '../life_record/commissions/modal_commission_form'
import ModalStudyCommissionForm from '../life_record/commissions/modal_study_commission_form'
export default {
  name: "select_commission_type",
  props:[
    'queryType', 'personId', 'modalHeader', 'person_name'
  ],
  components:{
    ModalIccForm,
    ModalCommissionForm,
    ModalStudyCommissionForm
  },
  data(){
    return{
      isICC: false,
      isCommission: false,
      isStudyCommission: false,
      isLoading: true,
      canCancel: []
    }
  },
  created() {
    this.isLoading =false
  },
  methods:{

  }
}
</script>

<style scoped>

</style>