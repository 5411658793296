<template>
  <div>
    <span>Componente Hijo</span>
    <b-field label="Puntaje Hijo">
      <b-input v-model="returnedScore"></b-input>
    </b-field>

  </div>
</template>
<script>
import { bus } from "../../packs/app_vue";
export default {
  data(){
    return{
      ReturnScore:this.score,

    }
  },
  created(){

  },
  props:[
    'score'

  ],
  methods:{
  },
  computed: {
    returnedScore: {
      get: function() {
        return this.score
      },
      set: function(value) {
        bus.$emit('updating-score', value)
      }
    }
  }
}
</script>

