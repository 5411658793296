<template>
  <section>

    <b-button
        label="Rellenar datos con bibtex"
        type="is-primary"
        @click="showBibtexModal = true" />
    <b-modal v-model="showBibtexModal" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <div class="field">
              <b-field label="Ingrese bibtex">
                <b-input
                    type="textarea"
                    maxlength="5000"
                    v-model="bibtex">
                </b-input>
              </b-field>
            </div>
            <b-message
                type="is-warning"
                has-icon
                aria-close-label="Cerrar">
              ¡Precaución! Si esta operación tiene éxito, se sobreescribiran los datos llenados en el formulario.
            </b-message>
          </div>
        </div>
        <footer class="card-footer">
          <a @click="closeModal" class="card-footer-item">Cancelar</a>
          <a @click="fillData" class="card-footer-item">Rellenar datos</a>
        </footer>

      </div>
    </b-modal>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  </section>
</template>

<script>
import axios from "axios";
import { bus } from "../../packs/app_vue";
export default {
name: "bibtex_input",
  data(){
    return {
      bibtex:"",
      showBibtexModal:false,
      isLoading:false,
    }
  },
  methods:{
    closeModal(){
      this.showBibtexModal=false;
    },
    fillData(){
      let vm = this;
      vm.isLoading = true;
      axios
          .get("/publications/parse/bibtex.json", {
            params: {bibtex:this.bibtex}
          })
          .then(response =>{
            bus.$emit('fill-publication', response.data)
            vm.isLoading = false;
            vm.closeModal();
          }).catch(error => {
            //console.log(error);
            vm.$buefy.dialog.alert({
              title: 'Error',
              message: 'Hubo un error en su solicitud. Revise el formato del bibtex.',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            vm.isLoading=false;
          })

    }

  }
}
</script>

<style scoped>

</style>