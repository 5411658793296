var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"columns is-vcentered mb-0"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"has-text-weight-semibold is-size-5 pl-2"},[_vm._v("\n        "+_vm._s(_vm.tableTitle)+"\n      ")])]),_vm._v(" "),(_vm.createMode || _vm.editDates)?_c('div',{staticClass:"column is-narrow mb-0"},[_c('b-button',{staticClass:"is-success",attrs:{"disabled":_vm.editedPermits.length >= 6,"icon-pack":"fa","icon-left":"plus"},on:{"click":_vm.addNewPermitDetail}},[_vm._v("\n        Agregar nueva fecha\n      ")])],1):_vm._e()]),_vm._v(" "),_c('ValidationObserver',{ref:"observer"},[_c('b-table',{ref:"table",staticStyle:{"width":"100%"},attrs:{"data":_vm.editedPermits},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('section',[_c('b-message',{staticClass:"my-4",attrs:{"type":"is-info","has-icon":""}},[_vm._v("\n            "+_vm._s(_vm.createMode
                ? "Para agregar una nueva fecha, presiona el botón 'Agregar nueva fecha'."
                : "No hay solicitudes de permisos administrativos con goce de sueldo registradas.")+"\n          ")])],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"has-text-right"},[_vm._v("\n          Total de días\n          "+_vm._s(_vm.createMode || _vm.editDates
              ? " a solicitar: " + _vm.totalDaysRequested
              : " solicitados: " + _vm.totalDays)+"\n        ")])]},proxy:true}])},[_c('b-table-column',{attrs:{"label":"N°","field":"id","sortable":!_vm.editMode && !_vm.createMode,"cell-class":'is-align-items-center'},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v("\n        "+_vm._s(props.index + 1)+"\n      ")]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"label":"Desde","field":"start_date","sortable":!_vm.editMode && !_vm.createMode,"cell-class":'is-align-items-center'},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(_vm.isValidState(props.row.request_state.name) && !_vm.editStatus)?_c('ValidationProvider',{attrs:{"rules":{
            required: true,
            start_date_not_past: false,
            start_date_before_end_date: props.row.end_date,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [(
              props.row.editMode &&
              (_vm.createMode || _vm.editDates || !_vm.editStatus) &&
              _vm.isValidState(props.row.request_state.name)
            )?_c('b-datepicker',{attrs:{"first-day-of-week":1,"min-date":_vm.lastTenYears,"unselectable-days-of-week":[0, 6],"position":"is-top-right","placeholder":"dd/mm/yyyy","editable":"","locale":"es-ES","icon":"calendar-alt","icon-right-clickable":"","trap-focus":"","append-to-body":""},on:{"input":function($event){return _vm.updateEndDateByStartDate(props)}},model:{value:(props.row.start_date),callback:function ($$v) {_vm.$set(props.row, "start_date", $$v)},expression:"props.row.start_date"}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),_vm._v(" "),(
            !props.row.editMode ||
            _vm.editStatus ||
            !_vm.isValidState(props.row.request_state.name)
          )?[_vm._v("\n          "+_vm._s(_vm.formatDateToLocaleString(props.row.start_date))+"\n        ")]:_vm._e()]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"label":"Hasta","field":"end_date","sortable":!_vm.editMode && !_vm.createMode,"cell-class":'is-align-items-center'},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(_vm.isValidState(props.row.request_state.name) && !_vm.editStatus)?_c('ValidationProvider',{ref:"end_date_provider",attrs:{"rules":{
            required: true,
            end_date_not_before_start_date: props.row.start_date,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [(
              props.row.editMode &&
              (_vm.createMode || _vm.editDates || !_vm.editStatus) &&
              _vm.isValidState(props.row.request_state.name)
            )?_c('b-datepicker',{attrs:{"first-day-of-week":1,"min-date":props.row.start_date,"unselectable-dates":_vm.unselectableEndDates(props.row.start_date),"unselectable-days-of-week":[0, 6],"indicators":"bars","position":"is-top-right","placeholder":"dd/mm/yyyy","editable":"","locale":"es-ES","icon":"calendar-alt","icon-right-clickable":"","trap-focus":"","append-to-body":""},on:{"input":function($event){return _vm.setNumberOfDays(props)}},model:{value:(props.row.end_date),callback:function ($$v) {_vm.$set(props.row, "end_date", $$v)},expression:"props.row.end_date"}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),_vm._v(" "),(
            !props.row.editMode ||
            _vm.editStatus ||
            !_vm.isValidState(props.row.request_state.name)
          )?[_vm._v("\n          "+_vm._s(_vm.formatDateToLocaleString(props.row.end_date))+"\n        ")]:_vm._e()]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"label":"Días solicitados","field":"number_of_days","sortable":!_vm.editMode && !_vm.createMode,"centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(
            !_vm.datesAreEqualAndNotNull(props.row) ||
            !props.row.editMode ||
            _vm.editStatus ||
            !_vm.isValidState(props.row.request_state.name)
          )?_c('b-tag',{staticClass:"is-info is-light",style:({
            width: '9rem',
          }),attrs:{"icon-pack":"fa","icon":"calendar-day","icon-size":"is-small"}},[_c('span',{staticClass:"is-size-6"},[_vm._v("\n            "+_vm._s(_vm.changeDayTextMessage(props.row.number_of_days))+"\n          ")]),_vm._v(" "),_c('span',{staticClass:"is-size-6"},[_vm._v("\n            "+_vm._s(props.row.adm_permit_journey.name === "COMPLETA"
                ? ""
                : props.row.adm_permit_journey.name)+"\n          ")])]):_vm._e(),_vm._v(" "),(
            _vm.datesAreEqualAndNotNull(props.row) &&
            props.row.editMode &&
            (_vm.createMode || _vm.editDates) &&
            _vm.isValidState(props.row.request_state.name)
          )?_c('div',[_c('b-dropdown',{attrs:{"position":"is-top-right"},on:{"input":function($event){return _vm.updateNumberOfDays(props)}},model:{value:(props.row.adm_permit_journey.name),callback:function ($$v) {_vm.$set(props.row.adm_permit_journey, "name", $$v)},expression:"props.row.adm_permit_journey.name"}},[_c('b-tag',{staticClass:"is-info is-light",style:({
                width: '9rem',
                cursor: 'pointer',
              }),attrs:{"slot":"trigger","icon-pack":"fa","icon":"calendar-day","icon-size":"is-small"},slot:"trigger"},[_c('span',{staticClass:"is-size-6"},[_vm._v("\n                "+_vm._s(_vm.changeDayTextMessage(props.row.number_of_days))+"\n              ")]),_vm._v(" "),_c('span',{staticClass:"is-size-6"},[_vm._v("\n                "+_vm._s(props.row.adm_permit_journey.name === "COMPLETA"
                    ? ""
                    : props.row.adm_permit_journey.name)+"\n                "),_c('b-icon',{attrs:{"icon":"caret-down"}})],1)]),_vm._v(" "),_c('b-dropdown-item',{staticClass:"left-aligned has-text-weight-bold",attrs:{"value":"COMPLETA"}},[_c('b-icon',{attrs:{"pack":"fas","icon":"business-time"}}),_vm._v("\n              Todo el día\n            ")],1),_vm._v(" "),_c('b-dropdown-item',{staticClass:"left-aligned",attrs:{"value":"AM"}},[_c('b-icon',{attrs:{"pack":"fas","icon":"clock"}}),_vm._v("\n              Media jornada:"),_c('span',{staticClass:"has-text-weight-bold"},[_vm._v("Mañana")])],1),_vm._v(" "),_c('b-dropdown-item',{staticClass:"left-aligned",attrs:{"value":"PM"}},[_c('b-icon',{attrs:{"pack":"fas","icon":"clock"}}),_vm._v("\n              Media jornada:"),_c('span',{staticClass:"has-text-weight-bold"},[_vm._v("Tarde")])],1)],1)],1):_vm._e()]}}])}),_vm._v(" "),(!_vm.createMode && !_vm.editDates)?_c('b-table-column',{attrs:{"label":"Estado","field":"request_state.name","numeric":"","width":"10rem","sortable":!_vm.editMode},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.editMode)?_c('b-dropdown',{attrs:{"position":"is-top-left","expanded":""},model:{value:(props.row.request_state.name),callback:function ($$v) {_vm.$set(props.row.request_state, "name", $$v)},expression:"props.row.request_state.name"}},[_c('b-tag',{style:({
              cursor: 'pointer',
              width: '8rem',
            }),attrs:{"slot":"trigger","icon-pack":"fa","icon":_vm.iconByState(props.row.request_state.name),"type":_vm.colorTypeByState(props.row.request_state.name)},slot:"trigger"},[_vm._v("\n            "+_vm._s(props.row.request_state.name)+" "),_c('b-icon',{attrs:{"icon":"caret-down"}})],1),_vm._v(" "),_vm._l((_vm.permitStates),function(state,index){return _c('b-dropdown-item',{key:index,staticClass:"left-aligned",attrs:{"value":state}},[_c('b-icon',{attrs:{"icon":_vm.iconByState(state)}}),_vm._v("\n            "+_vm._s(state)+"\n          ")],1)})],2):_vm._e(),_vm._v(" "),(!_vm.editMode || !props.row.editMode)?_c('b-tag',{style:({
            width: '8rem',
          }),attrs:{"icon-pack":"fa","icon":_vm.iconByState(props.row.request_state.name),"type":_vm.colorTypeByState(props.row.request_state.name)}},[_vm._v("\n          "+_vm._s(props.row.request_state.name)+"\n        ")]):_vm._e()]}}],null,false,413163926)}):_vm._e(),_vm._v(" "),(_vm.createMode || _vm.editDates)?_c('b-table-column',{attrs:{"label":"Acciones","field":"actions","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(
            !_vm.isValidState(props.row.request_state.name) ||
            _vm.editedPermits.length <= 1
          )?_c('b-tooltip',{attrs:{"label":_vm.getTooltipLabel(props.row.request_state.name),"position":"is-left","size":"is-small","multilined":""}},[_c('b-button',{attrs:{"type":"is-danger","icon-pack":"fa","icon-left":"trash","outlined":"","disabled":!_vm.isValidState(props.row.request_state.name) ||
              _vm.editedPermits.length <= 1},on:{"click":function($event){_vm.deletePermitDetail(
                _vm.editedPermits.indexOf(props.row),
                props.row.request_state.name
              )}}})],1):_c('b-button',{attrs:{"type":"is-danger","icon-pack":"fa","icon-left":"trash","outlined":"","disabled":!_vm.isValidState(props.row.request_state.name)},on:{"click":function($event){_vm.deletePermitDetail(
              _vm.editedPermits.indexOf(props.row),
              props.row.request_state.name
            )}}})]}}],null,false,3787692880)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }