<template>
  <section>
      <div >
        <!-- Box de Formulario Evento -->
        <div class="modal-card" style="width: auto" id="modal-duplication">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" ref="modalConfirmDuplication">
            <div class="columns" v-if="!disabled">
              <div class="column is-1">
                <b-icon
                    pack="fas"
                    icon="exclamation-triangle"
                    size="is-large"
                    type="is-danger">
                </b-icon>
              </div>
              <div class="column">
                <p>Fecha de validez del nombramiento considera el <b>31 de Diciembre</b>. <br>
                </p>
              </div>
            </div>
            <div class="columns" v-if="!disabled">
              <div class="column">
                <p>
                Fechas sugeridas primer nombramiento: <br>
                Fecha desde: {{first_start_date}} <br>
                Fecha hasta: {{first_end_date}}
                </p>
              </div>
            </div>
            <div class="columns" v-if="!disabled">
              <div class="column">
                <p>Fechas sugeridas segundo nombramiento: <br>
                  Fecha desde: {{second_start_date}}<br>
                  Fecha hasta: {{second_end_date}}
                </p>
              </div>
            </div>
            <div class="columns" v-if="!disabled">
              <div class="column">
                <div class="control">
                  <p><b>¿Desea separar el nombramiento en dos?</b></p>
                </div>
              </div>
            </div>
            <b-message type="is-danger" has-icon v-if="disabled">
              Fechas inválidas, no se puede separar/separar el nombramiento.<br>
              Puede volver y editar las fechas o guardar sin separar.
            </b-message>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button is-warning" type="button" @click="$emit('close')">Cancelar</button>
            <button class="button is-danger" @click="save" >No, guardar sin separar </button>
            <b-tooltip label="Al separar un nombramiento, toda la información ingresada será copiada"
                       position="is-top">
              <button class="button is-success" @click="duplicate" :disabled="disabled">Si, separar </button>
            </b-tooltip>
          </div>
        </footer>
      </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "modal_confirm_duplication",
  props:[
    'personId', 'id', 'modalHeader', 'person_unit'
  ],
  data(){
    return {
      isLoading:false,
      first_start_date: null,
      first_end_date: null,
      second_start_date: null,
      second_end_date: null,
      disabled: false
    }
  },
  created() {
    this.isLoading=true
    this.first_start_date = moment(this.person_unit.start_date).format("DD/MM/YYYY")
    this.first_end_date = "30/12/"+moment(this.person_unit.start_date).year()
    this.second_start_date = "01/01/"+moment(this.person_unit.end_date).year()
    this.second_end_date = moment(this.person_unit.end_date).format("DD/MM/YYYY")
    console.log("RESTA: ", Math.abs(moment(this.person_unit.start_date).year()-moment(this.person_unit.end_date).year()))
    if(Math.abs(moment(this.person_unit.start_date).year()-moment(this.person_unit.end_date).year())>1){
      console.log("mas de 1 año")
      this.disabled = true
    }
    this.isLoading=false
  },
  methods: {
    save(){
      this.$emit('clicked_save', false)
      this.$emit("close")
    },
    duplicate(){
      this.$emit('clicked_duplicate', true)
      this.$emit("close")
    },
    formatDate(date){
      if(date != null && date!==""){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
  }
}
</script>
