<template>
  <section>
    <b-table
        :data="servicios"
        :paginated=true
        :card-layout="cardLayout"
        per-page=10
        striped
        hoverable
        :current-page="currentPage"
        :pagination-simple=false
        pagination-position="bottom"
        default-sort-direction="asc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="project.name"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página actual"
        :opened-detailed="defaultOpenedDetails"
        detailed
        detail-key="code"
        :show-detail-icon="showDetailIcon"
    >
     <b-table-column
          field= 'sdt_service_type'
          label= 'Tipo de Servicio'
          width="15em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.sdt_service_type }}
        </template>
      </b-table-column>

      <b-table-column
          field= 'code'
          label= 'Código'
          width="10em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.code}}
        </template>
      </b-table-column>

      <b-table-column
          field= 'name'
          label= 'Nombre servicio'
          width="20em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.name }}
        </template>
      </b-table-column>

      <b-table-column
          field= 'unit'
          label= 'Unidad'
          width="15em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.unit }}
        </template>
      </b-table-column>


      <b-table-column
          field= 'award_year'
          label= 'Año creacion'
          width="10em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.award_year }}
        </template>
      </b-table-column>
      

      
      <b-table-column
          field= 'start_date'
          label= 'Fecha de inicio'
          width="10em"
          searchable
          :custom-search="searchPublicationDate"
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
            {{ props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL"): '' }}
        </template>
      </b-table-column>
      <b-table-column
          label="Acciones"
          width="10em"
          v-slot="props">

          <b-button type="is-primary"
                      icon-left="eye"
                      @click="execShowModal(props.row)">
          </b-button> 
          <b-button type="is-success"
                    v-if="canNullify=='true'"
                      icon-left="trash-restore"
                      @click="execRestoreModal(props.row)">
          </b-button> 
      </b-table-column>
      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>Jefe de proyecto: </strong>
                <span>{{props.row.projectManagment.person_name || "Sin definir"}}</span>
              </p>
              <p v-if="props.row.partner_entity">
                <strong>Cliente: </strong>
                <span>{{props.row.partner_entity}}</span>
              </p>

            </div>
          </div>
        </article>
      </template>
      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-danger">
              No se encontraron resultados para el año seleccionado.
            </b-message>
          </div>
        </div>
      </template>
    </b-table>

    <b-modal
        v-model="isShowModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        width="90%"
        aria-modal>
      <template #default="props">
        <modal-show-nullified-service :service-id="serviceId"
                                      :table = "serviceTable"
                                      :user-id="userId"
                                      style="width: 75vw"
                                      :cargo-principal="cargoPrincipal"
                                       modal-header="Servicio EFUSACH"
                                       @close="props.close">
        </modal-show-nullified-service>
      </template>
    </b-modal>


    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>

  import moment from "moment";
  import modalShowNullifiedService from "./ModalShowNullifiedService.vue";
  import {searchDate} from "../../packs/utilities";
  import axios from "axios";
  export default {
    name: 'nullified_services',
    components:{modalShowNullifiedService},
    props:[
        'cargoPrincipal', 'userId', 'servicios','canNullify'
    ],
    data(){
      return{
        isLoading:false,
        currentPage:1,
        cardLayout:false,
        defaultOpenedDetails: [1],
        showDetailIcon: true,
        isShowModalActive: false,
        serviceId: null,
        serviceTable: null,
        years:[],
        year:null,
        
      }
    },
    created(){      
    },
    methods:{
      searchPublicationDate(row,input){
        return searchDate(row.start_date,input)
      },
      execShowModal(row){
        this.serviceId =  row.id;
        this.serviceTable =  row.table;
        this.isShowModalActive = true
      },
      execRestoreModal(row){
        let vm = this;
        let ruta = ""
        if (row.table === "projects"){
          ruta = "/projects/" + row.id + "/restore_project.json"
        }
        else{
          ruta = "/sdt_services/" + row.id + "/restore_service.json"
        }
        vm.$buefy.dialog.confirm({
          title: 'Restaurar servicio',
          message: '¿Está seguro que desea <b>restaurar</b> el servicio EFUSACH? Esta acción revertirá la anulación.',
          confirmText: 'Restaurar Servicio EFUSACH',
          type: 'is-success',
          hasIcon: true,
          onConfirm: () => {
            vm.isLoading = true
            axios
                .put(ruta)
                .then(res => {
                  vm.$buefy.dialog.alert({
                    message: 'Servicio EFUSACH restaurado correctamente',
                    duration: 5000,
                    type: 'is-success',
                    canCancel: ["escape", "outside"]
                  })
                  var index = this.servicios.indexOf(row)
                  this.servicios.splice(index, 1)
                  this.$emit('elementoRestaurado', row);
                  vm.isLoading = false;
                }).catch(error => {
              console.log(error)
              vm.$buefy.dialog.alert({
                message: '<b>Servicio EFUSACH no pudo ser restaurado</b><br>'+error.response.data,
                duration: 5000,
                type: 'is-danger',
                canCancel: ["escape", "outside"]
              })
              vm.isLoading = false;
            })
          }
        })
      },
    },
  }
</script>