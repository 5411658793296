<template>
  <section>
    <h1 class="subtitle is-2 has-text-primary">
      ¿Qué información hay en Asignación Familiar?
    </h1>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Asignaciones Familiares
    </h2>
    <div class="mb-3 ">
      <p>En esta sección se listan las asignaciones familiares activas e históricas del académico.</p>
    </div>
    <img border="2" class="m-2" src="/tutorial_images/asignaciones.png" style="width:80%">
    <div class="mb-3 ">
      <p>El sistema permite ver una asignación familiar</p>
    </div>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Paso 1
    </h2>
    <p class="my-3 ">Estar situado en la pestaña de <b>Asignaciones Familiares</b> </p>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Paso 2
    </h2>
    <p class="my-3 ">Presionar el botón con forma de <b>ojo</b> situado en la columna de acciones:
    </p>
    <img border="2" class="m-2" src="/tutorial_images/ver_asignaciones.png" style="width:80%">
    <p class="my-3 ">La acción anterior despliega una asignación familiar y permite su visualización:
    </p>
    <img border="2" class="m-2" src="/tutorial_images/asignacion.png" style="width:80%">

    <h2 class="subtitle is-3 mt-5 mb-2">
      Cese de Asignaciones
    </h2>
    <div class="mb-3 ">
      <p>En esta sección se listan los documentos asociados a un <b>cese de asignación</b>, es decir,
        al fin de una asignación familiar.</p>
    </div>
    <img border="2" class="m-2" src="/tutorial_images/cese_asignaciones.png" style="width:80%">
    <div class="mb-3 ">
      <p>El sistema permite ver un cese de asignación</p>
    </div>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Paso 1
    </h2>
    <p class="my-3 ">Estar situado en la pestaña de <b>Cese de Asignaciones</b> </p>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Paso 2
    </h2>
    <p class="my-3 ">Presionar el botón con forma de <b>ojo</b> situado en la columna de acciones:
    </p>
    <img border="2" class="m-2" src="/tutorial_images/ver_cese_asignaciones.png" style="width:80%">
    <p class="my-3 ">La acción anterior despliega un cese de asignación y permite su visualización:
    </p>
    <img border="2" class="m-2" src="/tutorial_images/cese_asignacion.png" style="width:80%">
  </section>
</template>
<script>
export default {
  name: "what_is_household_mhv",
  data(){
    return {
    }
  },
  methods:{

  },
}
</script>