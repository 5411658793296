<template>
  <section>
    <div class="container">
      <div class="box">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><a href="/life_record">Menú principal</a></li>
            <li class="is-active"><a href="#" aria-current="page">Listado Nombramientos</a></li>
          </ul>
        </nav>
        <div class="columns">
          <div class="column">
            <h3 class="is-size-3 has-text-weight-bold">Listado Nombramientos</h3>

          </div>
          <div class="column">
            <div class="has-text-right">
              <b-button label="Exportar"
                        class="is-primary is-outlined"
                        @click="exportData"
                        icon-left="file-excel"/>
            </div>
          </div>
        </div>
        <b-collapse :open="true" aria-id="contentCollapse">
          <template #trigger>
            <b-button
                class="mb-2"
                type="is-primary"
                aria-controls="contentCollapse"
                icon-left="filter">{{isOpen ? "Ocultar Filtros" : "Desplegar Filtros"}}</b-button>
          </template>
          <!-- COMPONENTE FILTROS -->
          <div class="box">
            <div class="columns">
              <div class="column is-narrow mt-2">
                <span>Filtrar según: </span>

              </div>
              <div class="column is-3">
                <b-field>
                  <multiselect v-model="type"
                               :options="unit_types"
                               placeholder="Seleccione unidad"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
              <div class="column is-3">
                <b-field>
                  <multiselect v-model="unit"
                               :options="units"
                               placeholder="Seleccione unidad"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
              <div class="column is-narrow mt-2">
                <span>Filtrar estamento: </span>

              </div>
              <div class="column is-3">
                <b-field>
                  <multiselect v-model="establishment"
                               :options="establishments"
                               placeholder="Seleccione estamento"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column is-narrow mt-2">
                <span>Estado Nombramientos: </span>

              </div>
              <div class="column is-3">
                <b-field>
                  <multiselect v-model="state"
                               :options="states"
                               placeholder="Seleccione estado"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                No se encontraron elementos.
                   </span>
                  </multiselect>
                </b-field>
              </div>
              <div class="column is-narrow mt-2">
                <span>Filtrar calidad: </span>
              </div>
              <div class="column is-3">
                <b-field>
                  <multiselect v-model="quality"
                               :options="qualities"
                               placeholder="Seleccione calidad"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="No se puede deseleccionar"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
            </div>
            <div class="columns is-centered">
              <div class="column">
                <b-field label="Fecha inicio nombramientos">
                  <b-datepicker
                      v-model="start_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                  >
                  </b-datepicker>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha término nombramientos">
                  <b-datepicker
                      v-model="end_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column has-text-centered">
                <b-button type="is-primary" icon-left="search"  @click.prevent="updateAppointments">Buscar</b-button>
              </div>
            </div>
          </div>
        </b-collapse>
        <div class="columns is-centered" v-if="people.length===1" style="margin-bottom: 40px; margin-top: 40px;">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-warning">
              Se encontró {{this.people.length}} resultado.
            </b-message>
          </div>
        </div>
        <div class="columns is-centered" v-if="people.length!==1" style="margin-bottom: 40px; margin-top: 40px;">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-warning">
              Se encontraron {{this.people.length}} resultados.
            </b-message>
          </div>
        </div>
        <b-table
            :data="people"
            :paginated= true
            per-page="10"
            striped
            hoverable
            sticky-header
            height="800"
            :narrowed=true
            :current-page="currentPage"
            :pagination-simple=false
            pagination-position="both"
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            default-sort="product.title"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual">
          <b-table-column
              field="index"
              label="N°">
            <template v-slot="props">
              {{ people.indexOf(props.row) + 1 }}
            </template>
          </b-table-column>
          <b-table-column
              field="run_completo"
              label="Rut"
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.run.rut>0 ? props.row.run_completo : ""}}
            </template>
          </b-table-column>
          <b-table-column
              field="complete_name"
              label="Nombres"
              searchable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.complete_name}}
            </template>
          </b-table-column>
          <b-table-column
              field="resolution_type"
              label="Tipo de Documento"
              searchable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.resolution_type!==undefined ? props.row.resolution_type : ""}}
            </template>
          </b-table-column>
          <b-table-column
              field="appointment_type"
              label="Tipo Nombramiento"
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.appointment_type!==undefined ? props.row.appointment_type : ""}}
            </template>
          </b-table-column>
          <b-table-column
              field="establishment"
              label="Estamento"
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.establishment!==undefined ? props.row.establishment : ""}}
            </template>
          </b-table-column>
          <b-table-column
              field="unit"
              label="CC Unidad de Desempeño"
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.unit!==undefined ? props.row.unit : ""}}
            </template>
          </b-table-column>
          <b-table-column
              field="start_date"
              label="Fecha Desde"
              searchable :custom-search="searchStartDate"
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL")  : ''}}
            </template>
          </b-table-column>
          <b-table-column
              field="end_date"
              label="Fecha Hasta"
              searchable :custom-search="searchEndDate"
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL") : ''}}
            </template>
          </b-table-column>
          <b-table-column
              field="working_day"
              label="Jornada /Horas"
              sortable
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.working_day ? props.row.working_day.hired_hours : ""}}
            </template>
          </b-table-column>
          <b-table-column
              label="Acciones"
              v-slot="props">
            <b-icon
                pack="fas"
                icon="eye"
                size="is-medium"
                @click.native="execShowModal(props.row.person_unit_id)"/>
          </b-table-column>
          <template slot="empty" slot-scope="props">
            <div class="columns is-centered">
              <div class="column is-6 is-centered has-text-centered">
                <b-message type="is-danger">
                  No se encontraron resultados para filtros aplicados.
                </b-message>
              </div>
            </div>
          </template>
        </b-table>
        <b-modal
            v-model="isShowModalActive"
            has-modal-card
            trap-focus
            :destroy-on-hide="true"
            aria-role="dialog"
            aria-modal>
          <template #default="props">
            <component  :is="showName"
                        :id="selected"
                        :modal-header="title"
                        @close="props.close"/>
          </template>
        </b-modal>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import AppointmentAcademic from "../life_record/appointment_academic.vue"
import AppointmentAdministrative from "../life_record/appointment_administrative.vue"
import AppointmentHours from "../life_record/appointment_hours.vue";
import AppointmentFeeContract from "../life_record/appointment_feecontract";
import moment from "moment";
import {searchDate, formatDate} from "../../packs/utilities";

export default {
  name: "person_by_appointment_list",
  components:{
    AppointmentHours,
    AppointmentAcademic,
    AppointmentAdministrative,
    AppointmentFeeContract
  },
  data(){
    return {
      selected: null,
      people: [],
      currentPage: 1,
      isLoading: false,
      no_filters: true,
      formattedDate: "",
      isShowModalActive: false,
      showName: "",
      title: "",
      unit: "TODAS",
      units: [],
      unit_types: ["UNIDAD DE DESEMPEÑO", "UNIDAD DE CONTRATACIÓN"],
      states: ["TODOS", "ACTIVOS", "INACTIVOS"],
      state: "ACTIVOS",
      type: "UNIDAD DE DESEMPEÑO",
      establishments: [],
      qualities: [],
      establishment: "TODOS",
      quality: "TODAS",
      isOpen: true,
      start_date: null,
      end_date: null

    }
  },
  created(){
    this.initialize()
  },
  methods:{
    updateAppointments(){
      this.isLoading = true;
      return axios
          .get("/people/person_by_appointment_list.json",
              { params: {quality: this.quality, establishment: this.establishment, unit_type: this.type,
                  state: this.state, unit: this.unit, start_date: this.start_date, end_date: this.end_date}})
          .then(res => {
            this.people = res.data.people
            if(res.data.people["length"]===0 ){
              this.$buefy.dialog.alert({
                message: 'Su búsqueda no ha arrojado resultados.',
                confirmText: 'Ok',
                type: 'is-warning',
                hasIcon: true,
                icon: 'exclamation-triangle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
            }
/*            else{
              var mensaje = 'Se encontraron '+res.data.people["length"]+' resultados.'
              this.$buefy.dialog.alert({
                message: mensaje,
                confirmText: 'Ok',
                type: 'is-success',
                hasIcon: true,
                icon: 'check-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
            }*/
            this.units = res.data.units
            this.unit = res.data.unit
            this.establishments = res.data.establishments
            this.qualities = res.data.qualities
            this.people = this.people.map(person => {
              var newPerson = person
              newPerson["start_date"] = moment(newPerson["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newPerson["end_date"] = moment(newPerson["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newPerson;
            });
            this.isLoading = false
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
          })
    },
    execShowModal(id){
      this.selected = id;
      let establishment = this.getEstablishment(id)
      if(establishment!== undefined){
        if(establishment==="ACADEMICOS") {
          this.showName = "AppointmentAcademic"
          this.title = "Nombramiento Estamento Académico"
        }
        else if (establishment==="ADMINISTRATIVOS") {
          this.showName = "AppointmentAdministrative"
          this.title = "Nombramiento Estamento Administrativo"
        }
        else if (establishment ==="HONORARIOS") {
          this.showName = "AppointmentFeeContract"
          this.title = "Nombramiento Estamento Honorarios"
        }
        else {
          this.showName = "AppointmentHours"
          this.title = "Nombramiento Estamento Profesor Horas Clases"
        }
      }
      else{
        this.showName = "AppointmentAdministrative"
      }
      //console.log("APPOINTMENT "+ this.showName)
      this.isShowModalActive = true;
    },
    getEstablishment(selected){
      var data = this.people.find(o => o.person_unit_id === selected);
      return data.establishment
    },
    initialize(){
      this.isLoading = true
      return axios
          .get("/people/person_by_appointment_list.json",
              { params: {quality: this.quality, establishment: this.establishment, unit_type: this.type,
                state: this.state, unit: "TODAS", start_date: this.start_date, end_date: this.end_date}})
          .then(res => {
            this.people = res.data.people
            this.units = res.data.units
            this.unit = res.data.unit
            this.establishments = res.data.establishments
            this.qualities = res.data.qualities
            this.people = this.people.map(person => {
              var newPerson = person
              newPerson["start_date"] = moment(newPerson["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newPerson["end_date"] = moment(newPerson["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newPerson;
            });
            this.isLoading = false
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
          })
    },
    returnShow(person_id){
      this.isLoading = true
      window.location.href = '/people/'+person_id+'/life_record'
    },
    exportData(){
      this.isLoading = true
      window.open("/people/export_appointments.xlsx?unit="+this.unit+"&unit_type="+this.type+"&state="+this.state+
      "&start_date="+this.start_date+"&end_date="+this.end_date+"&quality="+this.quality+"&establishment="+this.establishment)
      this.isLoading = false
    },
    formatDate,
    searchStartDate(row, input){
      return searchDate(row.start_date,input)
    },
    searchEndDate(row, input){
      return searchDate(row.end_date,input)
    },
  },
}
</script>
