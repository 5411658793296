<template>
    <div class="columns is-multiline">
        <div class="column is-3 py-0">
            <b-sidebar position="static" type="is-light" open>
                <div class="p-1 pt-4">
                    <div class="block mt-5">
                        <h1 class="subtitle is-3 has-text-primary has-text-left">Índice</h1>
                    </div>
                    <b-menu class="is-custom-mobile">
                        <b-menu-list>
                            <b-menu-item :active="tutorial === 'function_life_record' ? true : false" @click="refresh('function_life_record')" label="1. Funcionalidades Hoja de Vida"></b-menu-item>
                            <b-menu-item :active="tutorial === 'life_record' ? true : false" @click="refresh('life_record')" label="2. Mi Hoja de Vida"></b-menu-item>
                            <b-menu-item :active="tutorial === 'profile' ? true : false" @click="refresh('profile')" label="3. Mi Perfil"></b-menu-item>
                            <b-menu-item :active="tutorial === 'search_help' ? true : false" @click="refresh('search_help')" label="4. Ayuda"></b-menu-item>
                            <b-menu-item :active="tutorial === 'documentation' ? true : false" @click="refresh('documentation')" label="5. Documentación"></b-menu-item>
                        </b-menu-list>
                    </b-menu>
                </div>
            </b-sidebar>
        </div>
        <div v-if="tutorial === 'function_life_record'" class="column is-9">
            <function_life_record :first_time="first_function_life_record"></function_life_record>
        </div>
        <div v-if="tutorial === 'life_record'" class="column is-9">
            <life_record :first_time="first_life_record"></life_record>
        </div>
        <div v-if="tutorial === 'profile'" class="column is-9">
            <my_profile :first_time="first_profile"></my_profile>
        </div>
        <div v-if="tutorial === 'search_help'" class="column is-9">
            <help :first_time="first_search_help"></help>
        </div>
        <div v-if="tutorial === 'documentation'" class="column is-9">
            <documentation :first_time="first_documentation"></documentation>
        </div>
    </div>
</template>

<script>
import function_life_record from "./jefe_admin_tutorial/function_life_record.vue";
import life_record from "./jefe_admin_tutorial/life_record.vue";
import my_profile from "./jefe_admin_tutorial/my_profile.vue";
import help from "./jefe_admin_tutorial/help.vue";
import documentation from "./jefe_admin_tutorial/documentation.vue";
export default {
    name: "jefe_admin",
    components: {function_life_record, life_record, my_profile, help, documentation},
    data(){
        return{
            tutorial:"function_life_record",
            key_academic_activity: 0,
            first_function_life_record: true,
            first_life_record: true,
            first_profile: true,
            first_search_help: true,
            first_agreements: true,
            first_documentation: true,
        }
    },
    methods:{
        refresh(name){
            this.tutorial = name
            if (name === "function_life_record"){
                this.first_function_life_record = false
            }
            else if(name === "life_record"){
                this.first_life_record = false
            }
            else if(name === "profile"){
                this.first_profile = false
            }
            else if(name === "search_help"){
                this.first_search_help = false
            }
            else if(name === "documentation"){
                this.first_documentation = false
            }

        }
    }
}
</script>

<style scoped>
.b-sidebar .sidebar-content.is-absolute,
.b-sidebar .sidebar-content.is-static {
    width: 100%;
    background-color: white;
    box-shadow: none;
}
.icon-up{
    position: fixed;
    width: 16.5% !important;
    bottom: 0;
    left: 15%;
}



</style>