<template>
  <section>
    <div>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
      <div class="column has-text-right">
        <b-field>
          <b-switch v-model="cardLayout">
          <span>
            Formato tarjeta (sin filtros ni búsqueda)
             <b-tooltip
                 label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                 multilined
                 position="is-left">
                  <b-icon
                      pack="fas"
                      icon="info-circle"
                      type="is-info">
                  </b-icon>
              </b-tooltip>
          </span>
          </b-switch>
        </b-field>
      </div>
      <b-table
          :data="performances"
          :paginated="true"
          per-page=10
          striped
          hoverable
          :current-page="currentPage"
          :card-layout="cardLayout"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="desc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="year"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual"
          detail-key="id">
        <b-table-column
            field="type"
            label="Tipo"
            width="15em"
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small"/>
          </template>
          <template v-slot="props">
            {{props.row.type}}
          </template>
        </b-table-column>
        <b-table-column
            field="year"
            label="Año"
            width="10em"
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small"/>
          </template>
          <template v-slot="props">
            {{props.row.year}}
          </template>
        </b-table-column>
        <b-table-column
            field="semester"
            label="Semestre"
            width="10em"
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small"/>
          </template>
          <template v-slot="props">
            {{props.row.semester}}
          </template>
        </b-table-column>
        <b-table-column
          field="unit_name"
          v-if="componentType == 'person'"
          label="Centro de costo"
          width="20em"
          searchable
          sortable>
          <template
            slot="searchable"
            slot-scope="props">
            <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small"/>
          </template>
          <template v-slot="props">
            {{props.row.unit_name}}
          </template>
        </b-table-column>
        <b-table-column
            field="person_name"
            v-if="componentType == 'unit' && !summary"
            label="Académico"
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small"/>
          </template>
          <template v-slot="props">
            {{props.row.person_name}}
          </template>
        </b-table-column>
        <b-table-column
            label="Acciones"
            class="align-right"
            width="10em"
            v-slot="props">
          <b-tooltip
              label="Mostrar en detalle la información relacionada al documento"
              :active="true"
              v-if="props.row.can_show">
            <b-button
                icon-pack="fas"
                icon-left="info"
                type="is-primary"
                target="_blank"
                @click="viewData(props.row)"/>
          </b-tooltip>

          <b-tooltip
              label="Editar la información relacionada al documento"
              :active="true"
              v-if="props.row.can_edit">
            <b-button
                pack="fas"
                icon-left="edit"
                type="is-info is-light is-outlined"
                @click="editPerformance(props.row.performance_id)"/>
          </b-tooltip>

          <b-tooltip
              label="Anular el documento"
              :active="true"
              v-if="props.row.can_nullify">
            <b-button
                pack="fas"
                icon-left="ban"
                type="is-danger is-light is-outlined"
                @click="execNullifyModal(props.row.performance_id, props.row)"/>
          </b-tooltip>

        </b-table-column>
        <b-table-column
            label="Documentación"
            class="align-right"
            width="5em"
            v-slot="props">
          <b-tooltip
              label="Descargar documento"
              :active="true"
              v-if="props.row.can_download">
            <b-button
                pack="fas"
                icon-left="download"
                type="is-primary is-light is-outlined"
                @click="downloadFile(props.row.performance_id)"/>
          </b-tooltip>

          <b-tooltip
              label="Visualizar documento"
              :active="true"
              v-if="props.row.can_download">
            <b-button
                pack="fas"
                icon-left="eye"
                type="is-success is-light is-outlined"
                @click="viewFile(props.row.performance_id)"/>
          </b-tooltip>

        </b-table-column>

        <template slot="empty" slot-scope="props">
          <div class="columns is-centered">
            <div class="column is-6 is-centered has-text-centered">
              <b-message v-if="advanceSearch != true" type="is-danger">
                No se encontraron resultados.
              </b-message>
              <b-message v-else type="is-danger">
                No se encontraron resultados para los criterios de búsqueda seleccionados.
              </b-message>
            </div>
          </div>
        </template>
      </b-table>
      <b-modal
          v-model="showPdf"
          :can-cancel="true"
          :destroy-on-hide="true"
          aria-modal
          aria-role="dialog"
          has-modal-card
          trap-focus
          :width="2000"
      >
        <template #default="props">
          <pdf_view
              :src="src"
              @close="props.close"
          ></pdf_view>
        </template>

      </b-modal>
      <b-modal
          v-model="showDocument"
          :can-cancel="true"
          :destroy-on-hide="true"
          aria-modal
          aria-role="dialog"
          has-modal-card
          trap-focus
          size="xl"
      >
        <template #default="props">
          <show-performance-modal
              :performance="performance"
              :person="person"
              @close="props.close"
          ></show-performance-modal>
        </template>

      </b-modal>
    </div>
  </section>
</template>

<script >
import {searchDate} from "../../packs/utilities";
import axios from "axios";
import pdf from 'vue-pdf';
import Pdf_view from "./pdf_view.vue";
import showPerformanceModal from "./show_performance_modal.vue";
import performance_form from "./new_performance_form.vue";

export default {
  props: [
      'componentType', 'objectId', 'hideUpdate', 'cargoPrincipal', 'advanceSearch', 'performances', 'summary', 'person'
  ],
  data(){
    return{
      showDocument: false,
      isLoading: false,
      currentPage: 1,
      cardLayout: false,
      years:[],
      year:null,
      isNullifyActive: false,
      file: null,
      src:null,
      showForm: false,
      showPdf: false,
      downloadLink: "",
      error: "",
      performance: {},
    }
  },
  components: {
    Pdf_view,
    pdf,
    showPerformanceModal
  },
  created(){
    this.generateArrayOfYears();
  },
  methods:{
    generateArrayOfYears(){
      var max = new Date().getFullYear()
      var min = 1980
      var years = []
      years.push("Todo")
      for (var i = max; i >= min; i--){
        years.push(i)
      }
      return years
    },
    viewFile(fileId){
      console.log(fileId)
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            console.log("show pdf")
            this.showPdf = true
            this.isLoading = false
          })
          .catch(e => {
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    execNullifyModal(id, row){
      console.log(id)
      this.$buefy.dialog.confirm({
        title: 'Anular Documento',
        message: '¿Está seguro que desea <b>anular</b> el documento? Esta acción lo dejará inválido.<br>',
        confirmText: 'Anular Documento',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/documents/" + id + "/nullify-document.json")
              .then(res => {
                this.$buefy.notification.open({
                  message: 'El documento fue anulado correctamente',
                  type: 'is-success',
                  hasIcon: true,
                  position: 'is-bottom-right',
                  duration: 5000,
                  progressBar: true,
                })
                var index = this.performances.indexOf(row)
                this.performances.splice(index, 1)
                this.isLoading = false;
                this.isNullifyActive = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.notification.open({
              message: 'El documento no pudo ser anulado correctamente',
              type: 'is-danger',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },
    viewData(row){
      this.performance = row
      console.log(this.performance)
      this.showDocument = true
    },
    editPerformance(id){
      window.open("/documents/" + id + "/edit-performance", '_blank')
    },
    downloadFile(fileId){
      this.isLoading = true;

      if (fileId == null){
        this.isLoading = false;
        return;
      }

      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
          })
          .then(res => {
            let blob = new Blob([res.data], {
              type: "application/pdf",
            })
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = res.headers["content-disposition"]
                .split(";")[1]
                .split('"')[1];
            link.click();
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento fue descargado con exito',
              type: 'is-success',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
          .catch(e => {
            console.log(e)
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento no se pudo descargar',
              type: 'is-danger',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
    },
    updatePerformances() {
    },
  }
}

</script>