<template>
  <section>
        <div class="columns">
          <div class="column has-text-right">
            <b-field label="Marcado para prórroga semi-automática">
              <b-checkbox :value="person_unit.renewable" disabled></b-checkbox>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Descripción">
              <b-input :value="person_unit.description" type="textarea" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Tipo de documento">
              <b-input :value="person_unit.resolution_type!==undefined ? person_unit.resolution_type.name : ''" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="N° de documento">
              <b-input :value="person_unit.resolution" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha documento">
              <b-input :value="formatDate(person_unit.resolution_date)" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Tipo de nombramiento">
              <b-input :value="person_unit.appointment_type!==undefined ? person_unit.appointment_type.name : ''" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Cargo">
              <b-input :value="person_unit.work_plant!==undefined ? person_unit.work_plant.name : ''" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Calidad de contrato">
              <b-input :value="person_unit.quality!==undefined ? person_unit.quality : ''" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Función">
              <b-input :value="person_unit.function_name!==undefined ? person_unit.function_name : ''" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Unidad de contratación">
              <b-input :value="person_unit.contracting_unit!==undefined ? person_unit.contracting_unit.name : ''" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Unidad de desempeño">
              <b-input :value="person_unit.unit!==undefined? person_unit.unit.name : ''" type="text" readonly>
              </b-input>
            </b-field>
          </div>
        </div>
      <div class="columns">
          <div class="column">
            <b-field label="Área de Desempeño">
              <b-input :value="person_unit.performance_area!==undefined? person_unit.performance_area : ''" type="text" readonly>
              </b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Jornada (horas)">
              <b-input :value="person_unit.working_day!==undefined? person_unit.working_day.hired_hours : ''" type="text" readonly>
              </b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha desde">
              <b-input :value="formatDate(person_unit.start_date)" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha hasta">
              <b-input :value="formatDate(person_unit.end_date)" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Nivel">
              <b-input :value="(person_unit.level!==undefined && person_unit.level!==null)? person_unit.level.name : ''" type="text" readonly>
              </b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Grado">
              <b-input :value="(person_unit.grade!==undefined && person_unit.grade!==null)? person_unit.grade.name : ''" type="text" readonly>
              </b-input>
            </b-field>
          </div>
        </div>

<!--        <div class="columns">
          <div class="column">
            <b-field label="Url Documentación">
              <b-input expanded readonly v-model="person_unit.documentation_link" type="text"></b-input>
              <p class="control">
                <b-button tag="a" :href="person_unit.documentation_link" target="_blank" icon-left="external-link-alt" type="is-link">Link</b-button>
              </p>
            </b-field>
          </div>
        </div>-->


  </section>
</template>

<script>
import axios from "axios";
import AppointmentForm from "../life_record/modal_administrative_appointment_form.vue"
import DistanceForm from "../life_record/modal_distance_form.vue"
import {formatDate} from "../../packs/utilities";

export default {
  name: "appointment_administrative_show",
  props:[
    "id", "modalHeader"
  ],
  components:{
    AppointmentForm,
    DistanceForm
  },
  data(){
    return {
      isLoading: false,
      isEditModalActive:false,
      person_unit: {},
      isDistanceModalActive: false
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      const loadingComponent = this.$buefy.loading.open();
      return axios
          .get("/admin/person_units/" + this.id + ".json")
          .then(res => {
            this.person_unit = res.data
            loadingComponent.close();
          }).catch(error => {
            console.log(error)
            loadingComponent.close();
          })
    },
    formatDate,
  },
  watch: {
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
  }
}
</script>
