<template>
<section>
  <br>
  <section class="container">
    <div class="box">
      <div class="columns" style="margin-bottom: -10px">
        <div class="column">
          <div class="is-size-5 has-text-weight-semibold">
            Calculo de Proyectos de I+D por Categoría, Entidad o Fondo de Financiamiento:
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-message
              title="Advertencia"
              type="is-warning"
              has-icon
              aria-close-label="Cerrar">
            Los datos de proyectos de I+D calculados en esta sección podría estar desactualizada y responde a los datos ingresados de proyectos postulados y adjudicados en SIGA.
          </b-message>
        </div>
      </div>

      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <div class="columns">
          <div class="column is-narrow">
            <b-field label="Categoría de Proyecto*">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <multiselect v-model="project_category"
                             :options="project_categories"
                             placeholder="Seleccione categoría de proyecto"
                             selectLabel="Presione para seleccionar"
                             selectedLabel="Seleccionado"
                             deselectLabel="No se puede deseleccionar"
                             :allow-empty="false">
                  <template v-slot:noOptions>
                    No existen datos
                  </template>
                  <span slot="noResult">
                  No se encontraron elementos.
                </span>
                </multiselect>
                <span class="validation-alert">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>
          </div>
          <div class="column is-5">
            <b-field label="Unidad*">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <multiselect v-model="calculus_unit"
                             :options="units"
                             label="name"
                             placeholder="Seleccione unidad"
                             selectLabel="Presione para seleccionar"
                             selectedLabel="Seleccionado"
                             deselectLabel="No se puede deseleccionar"
                             :allow-empty="false">
                  <template v-slot:noOptions>
                    No existen datos
                  </template>
                  <span slot="noResult">
                  No se encontraron elementos.
                </span>
                </multiselect>
                <span class="validation-alert">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>
          </div>

          <div class="column">
            <b-field >
              <template #label>
                <span v-if="project_category=='PROYECTOS ADJUDICADOS'">Proyectos en curso o Financiamientos del Año*</span>
                <span v-if="project_category=='PROYECTOS POSTULADOS'">Proyectos postulados o Financiamientos del Año*</span>

              </template>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <multiselect v-model="calculus_year"
                             :options="years"
                             placeholder="Seleccione año"
                             selectLabel="Presione para seleccionar"
                             selectedLabel="Seleccionado"
                             deselectLabel="No se puede deseleccionar"
                             :allow-empty="false">
                  <template v-slot:noOptions>
                    No existen datos
                  </template>
                  <span slot="noResult">
                  No se encontraron elementos.
                </span>
                </multiselect>
                <span class="validation-alert">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>

          </div>

        </div>
        <div class="columns">

          <div class="column">
            <b-field>
              <template #label>
                Entidad de Financiamiento (ingrese por lo menos 3 letras)
                <b-tooltip
                    label="Se recomienda búsqueda por sigla, ejemplo: ANID, no Agencia Nacional... mientras que para instituciones educacionales, se recomienda por nombre completo, ejemplo: universidad de santiago de chile"
                    position="is-right"
                    size="is-medium"
                    multilined>

                  <b-icon
                      pack="fas"
                      icon="info-circle"
                      size="is-small"
                      type="is-info">
                  </b-icon>
                </b-tooltip>
              </template>
              <b-autocomplete
                  :data="partner_entities"
                  placeholder="ej ANID o UNIVERSIDAD DE SANTIAGO DE CHILE  o NOMBRE EMPRESA - ENTIDAD"
                  field="show_name"
                  @typing="searchPartnerEntities"
                  v-model="partner_entity.name"
                  @select="option => {if (option) {partner_entity = option} }">

                <template slot-scope="props">
                  {{ props.option.show_name ? props.option.show_name : props.option.name }}
                </template>
              </b-autocomplete>
            </b-field>
          </div>
          <div v-if="programs.length>0" class="column">
            <b-field label="Programa de financiamiento">
              <multiselect v-model="program"
                           :options="programs"
                           :custom-label="opt => opt.name"
                           placeholder="Seleccione programa de financiamiento"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="Presione para deseleccionar"
                           :allow-empty="true">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                  No se encontraron elementos.
              </span>
              </multiselect>
            </b-field>
          </div>
        </div>

        <div class="columns">
          <div class="column">
          </div>
          <div class="column is-narrow has-text-right">
            <b-button icon-left="folder-open" type="is-primary" @click="calculateProjects">Calcular cantidad de Proyectos</b-button>
          </div>
          <div class="column is-narrow has-text-right">
            <b-button icon-left="dollar-sign" type="is-success" @click="calculateFinancings">Calcular Financiamientos de Proyectos</b-button>
          </div>
        </div>
      </ValidationObserver>




    </div>
  </section>
  <div v-if="showProjects || showPostulatedProjects || showFinancing" class="container">
    <div class="box">
      <div class="columns">
        <div v-if="showProjects || showPostulatedProjects" class="column has-text-centered is-size-5 has-text-weight-semibold">
          Total de Proyectos obtenidos: {{ projects.length }}
        </div>
        <div v-if="showFinancing" class="column has-text-centered is-size-5 has-text-weight-semibold">
          Monto Total de Financiamientos obtenidos:  ${{ getFinancingAmount(financings.reduce((a, b) => a + (b['amount'] || 0), 0)) }}
        </div>
      </div>
      <div class="columns">
        <div class="column"></div>
        <div class="column is-narrow">
          <button @click="downloadExcel" class="button has-text-white is-excel-color">
              <span class="icon">
                <i class="far fa-file-excel"></i>
              </span>
              <span>
                Descargar Datos en Excel
              </span>
          </button>
        </div>
      </div>
      <div v-if="showProjects">
        <projects-table v-bind:proyectos="projects"
                        v-bind:hide-update="true" :cargo-principal="''"></projects-table>
      </div>
      <div v-if="showPostulatedProjects">
        <postulated-projects-table :postulated-projects="projects"></postulated-projects-table>
      </div>

      <div v-if="showFinancing">
        <financings-table v-bind:financings="financings"
                          v-bind:hide-update="true"></financings-table>
      </div>
    </div>
  </div>

  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
</section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import ProjectsTable from "../projects/projects_table";
import PostulatedProjectsTable from "../projects/PostulatedProjectsTable";
import FinancingsTable from "../financings/FinancingTable.vue";
export default {
  name: "IdDashboard",
  components: {PostulatedProjectsTable, ProjectsTable,FinancingsTable},
  data(){
    return{
      isLoading: false,
      calculus_year:null,
      years:[],
      calculus_unit:null,
      units:[],
      project_types:[],
      project_type:null,
      entity_types:[],
      entity_type:null,
      program:null,
      programs:[],
      project_category:"PROYECTOS ADJUDICADOS",
      project_categories:[],
      partner_entities:[],
      partner_entity:{},
      projects:[],
      financings:[],
      showProjects:false,
      showPostulatedProjects:false,
      showFinancing:false,



    }
  },
  created() {
    this.years = this.generateArrayOfYears();
    this.getInitData();
  },
  watch: {
    'partner_entity.name'(newValue,oldValue){
      if(newValue.length<3){
        this.partner_entity.id=null;
        this.programs = [];
      }
      if(this.partner_entity.id){
        this.searchPrograms();
      }
    }
  },
  methods:{
    async checkValidation() {
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
      return valid;
    },
    async calculateProjects(){
      let vm = this;
      vm.isLoading = true;
      const validation = await vm.checkValidation();
      if(!validation){
        vm.isLoading = false;
      }
      else{
        let params_data = {
          unit_id: vm.calculus_unit.id,
          year: vm.calculus_year,
          project_category: vm.project_category,
        }
        if (vm.partner_entity){
          params_data.entity_id = vm.partner_entity.id
        }
        if (vm.program){
          params_data.program_id = vm.program.id
        }
        return axios
          .get("/dashboards/i-d/calculate-projects.json",{ params:params_data})
          .then(res => {
            if (vm.project_category == "PROYECTOS ADJUDICADOS"){
              vm.showPostulatedProjects = false;
              vm.showFinancing = false;
              vm.showProjects = true;
            }
            if (vm.project_category == "PROYECTOS POSTULADOS"){
              vm.showProjects = false;
              vm.showFinancing = false;
              vm.showPostulatedProjects = true;
            }
            vm.financings = [];
            vm.projects = res.data;
            vm.projects = vm.projects.map(proyecto => {
              var newProyecto = proyecto
              newProyecto["start_date"] =  moment(newProyecto["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newProyecto["end_date"] =  moment(newProyecto["end_date"] , ["YYYY-MM-DD","DD-MM-YYYY"]);
              newProyecto["postulation_date"] =  moment(newProyecto["postulation_date"] , ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newProyecto;
            });
            vm.$buefy.dialog.alert({
              message: 'La consulta fue calculada satisfactoriamente.',
              type: 'is-success',
              hasIcon: true,
              icon: 'check-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            vm.isLoading = false;
          })
          .catch(e => {
            //console.log(e);
            vm.$buefy.dialog.alert({
              message: 'Hubo un error en su petición.',
              type: 'is-danger',
              hasIcon: true,
              icon: 'check-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            vm.isLoading = false;
          });

      }
    },
    getInitData(){
      let vm = this;
      vm.isLoading = true;
      return axios
        .get("/dashboards/i-d.json")
        .then(res => {
          vm.units = res.data.units;
          vm.project_types = res.data.project_types;
          vm.entity_types = res.data.entity_types;
          vm.project_categories = res.data.project_categories;
          vm.isLoading = false;
        })
        .catch(e => {
          //console.log(e);
          vm.isLoading = false;
        });
    },
    generateArrayOfYears() {
      var max = new Date().getFullYear()
      var min = 2014
      var years = []

      for (var i = max; i >= min; i--) {
        years.push(i)
      }
      return years
    },
    searchPartnerEntities(name){
      if (name.length<3) {
        this.partner_entities = []
        return
      }
      let vm = this;
      vm.isLoading=true;
      axios
        .get("/admin/partner_entities/financings-search.json", { params: { name: name } })
        .then(res => {
          vm.partner_entities = res.data;
          vm.isLoading = false;
        })
        .catch(error => {
          console.log(error)
          vm.isLoading=false;
        })
    },
    searchPrograms(){
      let vm = this;
      if (vm.partner_entity.id){
        vm.isLoading = true;
        axios
          .get("/partner_entities/"+vm.partner_entity.id+"/programs.json")
          .then(res => {
            vm.programs = res.data;
            vm.programs = vm.programs.map(program => {
              var program = program
              program["start_date"] = moment(program.start_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
              program["end_date"] = moment(program.end_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
              return program;
            });
            vm.isLoading=false;
          })
          .catch(e => {
            console.log(e);
            vm.isLoading=false;
          });
      }
    },
    async calculateFinancings(){
      let vm = this;
      vm.isLoading = true;
      const validation = await vm.checkValidation();
      if(!validation){
        vm.isLoading = false;
      }
      else{
        let params_data = {
          unit_id: vm.calculus_unit.id,
          year: vm.calculus_year,
          project_category: vm.project_category,
        }
        if (vm.partner_entity){
          params_data.entity_id = vm.partner_entity.id
        }
        if (vm.program){
          params_data.program_id = vm.program.id
        }
        return axios
          .get("/dashboards/i-d/calculate-financings.json",{ params:params_data})
          .then(res => {
            vm.projects=[];
            vm.financings = res.data.financings;
            /*
            vm.financings = vm.financings.map(financing => {
              var newFinancing = financing
              newFinancing["amount"] = vm.getFinancingAmount(newFinancing["amount"]);
              return newFinancing;
            });*/
            vm.showProjects = false;
            vm.showPostulatedProjects = false;
            vm.showFinancing = true;
            vm.$buefy.dialog.alert({
              message: 'La consulta fue calculada satisfactoriamente.',
              type: 'is-success',
              hasIcon: true,
              icon: 'check-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            vm.isLoading = false;
          })
          .catch(e => {
            //console.log(e);
            vm.$buefy.dialog.alert({
              message: 'Hubo un error en su petición.',
              type: 'is-danger',
              hasIcon: true,
              icon: 'check-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            vm.isLoading = false;
          });

      }
    },
    getFinancingAmount(amount){
      return amount.toLocaleString('cl-CL');
    },
    downloadExcel(){
      let download_type=null;
      if(this.showPostulatedProjects){
        download_type = "PROYECTOS POSTULADOS"
      }
      if(this.showProjects){
        download_type = "PROYECTOS ADJUDICADOS"
      }
      if(this.showFinancing){
        download_type = "FINANCIAMIENTOS"
      }
      let windowLocation = "i-d/download.xlsx?unit_id="+this.calculus_unit.id+"&year="+this.calculus_year+"&download_type="+download_type+"&project_category="+this.project_category;
      if(this.partner_entity && this.partner_entity.id){
        windowLocation = windowLocation + "&entity_id="+this.partner_entity.id
      }
      if(this.program && this.program.id){
        windowLocation = windowLocation + "&program_id="+this.program.id
      }
      window.location.href = windowLocation;
    }
  }
}
</script>

<style scoped>

</style>