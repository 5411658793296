<template>
<section>
  <h3 class="is-size-4 has-text-weight-bold has-text-centered">Tabla de Participantes</h3>
  <div class="has-text-right">
    <b-button v-if="project.can_edit" type="is-info"
              icon-left="edit"
              @click="editProject">
      Editar Participantes
    </b-button>
  </div>
  <div class="column">
    <b-table
        :data="participants"
        :paginated=true
        per-page=5
        striped
        hoverable
        :current-page="currentPage"
        :card-layout="cardLayout"
        :pagination-simple=false
        pagination-position="bottom"
        default-sort-direction="desc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="last_patent_state_date"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página actual">
      <b-table-column
          field= 'name'
          label= 'Nombres'
          width="20em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          <a @click="goToProfile(props.row.person_id)">{{ props.row.name }}</a>
        </template>
      </b-table-column>
      <b-table-column
          field= 'corporate_mail'
          label= 'Correo Corporativo'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{  props.row.corporate_mail }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'unit'
          label= 'Unidad'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{  props.row.unit }}
        </template>
      </b-table-column>
      <b-table-column
          label="Tipo de Participación"
          v-slot="props">
        <template>
          {{  props.row.participant_type }}
        </template>
      </b-table-column>
      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-danger">
              No se encontraron participantes.
            </b-message>
          </div>
        </div>
      </template>
    </b-table>
  </div>
  <b-modal
      :active="isComponentModalActive"
      width="90%"
      :can-cancel="canCancel"
      aria-modal>
    <template #default="props">
      <new-project-form modal-cancel="true"
                        modal-header="Editar Proyecto"
                        query-type="update"
                        :cargo-principal="cargoPrincipal"
                        v-bind:project-id="project.id"
                        :show-participants-first="true"
                        @close="closeModal"></new-project-form>
    </template>
  </b-modal>
</section>
</template>

<script>
import axios from "axios";
import newProjectForm from './NewProjectForm.vue';
export default {
  name: "ProjectParticipantsTab",
  props:[ 'participants', 'project', 'cargoPrincipal', 'canEdit'],
  components:{newProjectForm},
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      years:[],
      year:null,
      isComponentModalActive:false,
      canCancel: ['escape']
    }
  },
  created(){

  },
  methods:{
    editProject(){
      this.isComponentModalActive = true;
    },
    closeModal(){
      this.isComponentModalActive = false;
    },
    goToProfile(id){
      window.location.href='/people/'+id
    },
  }

}
</script>

<style scoped>

</style>