<template>
  <div>
    <section>
      <div class="box">
        <div class="container">
          <b-tabs position="is-centered" @input="changeTab">
            <b-tab-item v-for="item in items"
                        :value="item.content"
                        v-bind:key="item.content"
                        :label="item.tab"
                        :icon="item.icon">
            </b-tab-item>

          </b-tabs>
          <!-- COMPONENTE AQUI -->

          <component v-bind:is="currentTabComponent"
                     :user-id="this.userId"
                     :cargo-principal="this.cargoPrincipal"
                     :inventores="this.inventores"
                     :disclosure-id="this.disclosures.id"
                     :disclosures="this.disclosures"
                     :can_edit="this.disclosures.can_edit"
                     :academic_activity_state_id="this.disclosures.academic_activity_state_id"
          ></component>

          <!-- FIN COMPONENTE -->

        </div>
      </div>
    </section>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>
<script>
import axios from "axios";
import General from './ShowGeneralTab'
import Participants from './DisclosureParticipantsTab'
import AdditionalInformationTab from "./AdditionalInformationTab";

export default {
  components: {General,Participants,AdditionalInformationTab},
  data(){
    return{
      disclosures:{
        name: "",
        code: "",
        project_id:null,
        financing_source:null,
        disclosure_date:null,
        invention_date:null,
        year:null
      },
      inventores: [],
      currentPage: 1,
      isLoading: false,
      currentTab: 'General',
      items: [
        {tab: 'General',content:'General',icon:"file-alt"},
        {tab: 'Participantes',content:'Participants',icon:"users"},
        {tab: 'Información Adicional',content:'AdditionalInformationTab',icon:"info"},
      ]
    }
  },
  created(){
    this.isLoading=true;
    return axios
        .get("/disclosures/"+this.disclosureId+".json")
        .then(res => {
          this.disclosures = res.data
          var index = 0
          for(const inventor of res.data["inventors"]){
            this.inventores.push({
              id:null,
              full_name:null,
              alias:null,
              unit:null,
              //unit_id:null,
              corporarte_mail:null,
            })
            this.inventores[index].id = inventor.person_id
            this.inventores[index].full_name = inventor.name
            this.inventores[index].corporate_mail = inventor.corporate_mail
            this.inventores[index].alias = inventor.person_alias
            if(this.inventores[index].corporate_mail != null){
              this.inventores[index].corporate_mail = this.inventores[index].corporate_mail.toUpperCase()
            }
            if(this.inventores[index].alias != null){
              this.inventores[index].alias = this.inventores[index].alias.toUpperCase()
            }
            this.inventores[index].unit = inventor.unit
            //this.inventores[index].unit_id = inventor.unit_id
            index = index + 1
          }

          if (this.disclosures.disclosure_date != null){
            this.disclosures.disclosure_date = this.formatDate(this.disclosures.disclosure_date)
          }
          if ( this.disclosures.invention_date != null){
            this.disclosures.invention_date = this.formatDate(this.disclosures.invention_date)
          }
          this.isLoading=false;
        })
        .catch(e => {
          console.log(e);
          this.isLoading=false;
        });

  },
  props:[
    'disclosureId','cargoPrincipal','userId'

  ],
  methods:{
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    editDisclosure(disclosure_id){
      window.location.href = "/disclosures/"+disclosure_id+"/edit"
    },
    execNullifyModal(){
      this.$buefy.dialog.confirm({
        title: 'Anular disclosure',
        message: '¿Está seguro que desea <b>anular</b> el disclosure? Esta acción lo dejará inválido.<br>',
        confirmText: 'Anular Disclosure',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/disclosures/" + this.disclosureId + "/nullify_disclosure.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Disclosure anulada correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                if (document.referrer == ''){
                  window.location.href = history.back()
                }
                else{
                  window.location.href = document.referrer
                }

                window.location.href = document.referrer
                this.isLoading = false;
                this.isNullifyActive = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Disclosure no pudo ser anulada</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },
    changeTab: function(value){
      this.currentTab = value
    }

  },
  computed:{
    currentTabComponent: function () {
      return this.currentTab
    }
  }
}
</script>