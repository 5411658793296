<template>
  <div>
    <div>
      <b-carousel
            v-model="carousel"
            :animated="animated"
            :has-drag="drag"
            :autoplay="autoPlay"
            :pause-hover="pauseHover"
            :pause-info="pauseInfo"
            :pause-info-type="pauseType"
            :interval="interval"
            :repeat="repeat"
            :indicator="indicator"
            :indicator-background="indicatorBackground"
            :indicator-inside="indicatorInside"
            :indicator-mode="indicatorMode"
            :indicator-position="indicatorPosition"
            :indicator-style="indicatorStyle"
            icon-size="is-large">
          <!--          @change="info($event)"-->


          <b-carousel-item v-for="(carousel, i) in carousels" :key="i">
            <a :href="carousel.link">
              <section :class="`hero is-medium is-${carousel.color} is-bold`" :style="`background-color: ${carousel.color};`">
                <b-image class="image" :src=carousel.image></b-image>
              </section>
            </a>
          </b-carousel-item>
        </b-carousel>
    </div>
    <br>
    <br>
    <div class="container mt-3">
      <section class="hero is-primary welcome is-small mb-4">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">
              Accesos directos
            </h1>
          </div>
        </div>
      </section>
      <div v-if="cargoPrincipal === 'ACADEMICO'" class="box">
        <section class="info-tiles">
          <div class="tile is-ancestor has-text-centered">
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a :href="'/people/'+personId">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-book"></i>
                        </span>
                        <span>Mi Actividad Académica</span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a :href="'/people/'+personId+'/life_record'">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-id-card"></i>
                        </span>
                        <span>Mi Hoja de Vida</span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
          </div>
          <div class="tile is-ancestor has-text-centered">
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a :href="'/people/'+personId+'/my-academic-data'">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-user-circle"></i>
                        </span>
                        <span>Mi Perfil</span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a href="/agreements/active">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-handshake"></i>
                        </span>
                        <span>Convenios</span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
          </div>
        </section>
      </div>

      <div v-else-if="cargoPrincipal === 'MONITOREO Y SEGUIMIENTO EFUSACH'" class="box">
        <section class="info-tiles">
          <div class="tile is-ancestor has-text-centered">
            <!--<div class="tile is-parent">
              <article class="tile is-child box">
                <a :href="'/sdt_services'">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-search"></i>
                        </span>
                        <span>Ver Servicios EFUSACH</span>
                      </span>
                  </p>
                </a>
              </article>
            </div> -->
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a :href="'/sdt_surpluses'">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-project-diagram"></i>
                        </span>
                        <span> Ver Entidades EFUSACH </span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
          </div>
        </section>
      </div>

      <div v-else-if="cargoPrincipal === 'SUPERVISOR TRANSACCIONES EFUSACH'" class="box">
        <section class="info-tiles">
          <div class="tile is-ancestor has-text-centered">
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a :href="'/sdt_surpluses'">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-project-diagram"></i>
                        </span>
                        <span> Ver Entidades EFUSACH </span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
          </div>
        </section>
      </div>

      <div v-else-if="cargoPrincipal === 'DECANO FING'" class="box">

        <section class="info-tiles">
          <div class="tile is-ancestor has-text-centered">
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a :href="'/people/'+personId+'/my-academic-data'">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-user-circle"></i>
                        </span>
                        <span>Mi Perfil</span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a :href="'/admin/units/'+unit">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-school"></i>
                        </span>
                        <span>Mi Unidad</span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
          </div>
          <!--
          <div class="tile is-ancestor has-text-centered">
            
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a :href="'/units/sdt/'+unit">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-chart-bar"></i>
                        </span>
                        <span>Dashboard de Servicios EFUSACH</span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
          
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a :href="'/sdt_transactions/reporting/'+unit">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-folder-plus"></i>
                        </span>
                        <span> Reportería de Transacciones EFUSACH </span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
          </div>
          -->
        </section>
      </div>

      <!--<div v-else-if="cargoPrincipal == 'JEFE PROYECTOS EFUSACH' || cargoPrincipal == 'SUPERVISOR EFUSACH' || cargoPrincipal == 'ANALISTA SERVICIOS EFUSACH' " class="box">
        <section class="info-tiles">
          <div class="tile is-ancestor has-text-centered">
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a :href="'/units/sdt/'+unit">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-school"></i>
                        </span>
                        <span>Mi Dashboard de Servicios EFUSACH</span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
          </div>
        </section>
      </div>
    -->

      <div v-else-if="cargoPrincipal === 'ANALISTA TRANSACCIONES EFUSACH'" class="box">
        <section class="info-tiles">
          <div class="tile is-ancestor has-text-centered">
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a :href="'/sdt_transactions/new/item'">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-shopping-bag"></i>
                        </span>
                        <span>Añadir Transacción Bien</span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a :href="'/sdt_transactions/new/contract'">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-handshake"></i>
                        </span>
                        <span>Añadir Transacción Contratación </span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
          </div>

          <div class="tile is-ancestor has-text-centered">
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a :href="'/sdt_transactions/new/service'">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-receipt"></i>
                        </span>
                        <span>Añadir Transacción Servicio</span>
                      </span>
                  </p>
                </a>
              </article>
            </div>            
          </div>

          <div class="tile is-ancestor has-text-centered">
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a :href="'/sdt_transactions/new/accountability-fund-item'">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-pallet"></i>
                        </span>
                        <span> Añadir Rendición de Fondos para Bienes </span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a :href="'/sdt_transactions/new/accountability-fund-service'">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-truck-loading"></i>
                        </span>
                        <span>Añadir Rendición de fondos para servicios</span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
          </div>

          <div class="tile is-ancestor has-text-centered">
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a :href="'/sdt_transactions/new/reimbursement-item'">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-box-open"></i>
                        </span>
                        <span> Añadir Reembolso de gastos para Bienes </span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a :href="'/sdt_transactions/new/reimbursement-service'">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-boxes"></i>
                        </span>
                        <span>Añadir Reembolso de gastos para servicios</span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
          </div>
        </section>
      </div>

      <div v-else-if="this.isPrincipals === 'true'" class="box">
        <section class="info-tiles">
          <div class="tile is-ancestor has-text-centered">
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a :href="'/admin/units/'+unit">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-school"></i>
                        </span>
                        <span>Mi Unidad</span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a href="/people">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-users-cog"></i>
                        </span>
                        <span>Listar Personas</span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
          </div>
          <div class="tile is-ancestor has-text-centered">
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a href="/life_record">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-tools"></i>
                        </span>
                        <span>Hoja de Vida</span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a :href="'/people/'+personId+'/my-academic-data'">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-user-circle"></i>
                        </span>
                        <span>Mi Perfil</span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
          </div>
          <!--
          <div class="tile is-ancestor has-text-centered">
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a :href="'/units/sdt/'+unit">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-chart-bar"></i>
                        </span>
                        <span>Dashboard de Servicios EFUSACH</span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
          
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a :href="'/sdt_transactions/reporting/'+unit">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-folder-plus"></i>
                        </span>
                        <span> Reportería de Transacciones EFUSACH </span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
          </div>
          -->
        </section>
      </div>
      <div v-else class="box">
        <section class="info-tiles">
          <div class="tile is-ancestor has-text-centered">
            <div class="tile is-parent">
              <article class="tile is-child box">
                <a :href="'/people/'+personId+'/my-academic-data'">
                  <p class="subtitle is-4">
                      <span class="icon-text">
                        <span class="icon is-medium">
                          <i class="fas fa-user-circle"></i>
                        </span>
                        <span>Mi Perfil</span>
                      </span>
                  </p>
                </a>
              </article>
            </div>
          </div>
        </section>
      </div>
    </div>
    <br>
    <br>
<!--    <div class="container mt-3">
      <section class="hero is-primary welcome is-small mb-4">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">
              Mis Indicadores
            </h1>
          </div>
        </div>
      </section>
      <div class="box">
        <div class="columns is-multiline">
          <div class="column has-text-centered">
            <div class="heading is-size-6">Publicaciones</div>
            <div class="title">{{ 0 }}</div>
          </div>
          <div class="column has-text-centered">
            <div class="heading is-size-6">Proyectos</div>
            <div class="title">{{ 0 }}</div>
          </div>
          <div class="column has-text-centered">
            <div class="heading is-size-6">Propiedad Intelectual Concedida</div>
            <div class="title">{{ 0 }}</div>
          </div>
        </div>
        <div class="columns is-multiline">
          <div class="column has-text-centered">
            <div class="heading is-size-6">Disclosures</div>
            <div class="title">{{ 0 }}</div>
          </div>
          <div class="column has-text-centered">
            <div class="heading is-size-6">Vinculación con el medio</div>
            <div class="title">{{ 0 }}</div>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
 import axios from "axios";
 export default {
   name: 'landing_page',
   props: ['personId','cargoPrincipal','unit','isPrincipals'],
   created() {
     if(this.isPrincipals === 'true'){
       this.carousels = this.carousels_principals
     }
     else if(this.cargoPrincipal === 'ACADEMICO'){
       this.carousels = this.carousels_academic
     }
   },
   data() {
     return {
       carousel: 0,
       animated: 'fade',
       drag: false,
       autoPlay: true,
       pauseHover: true,
       pauseInfo: false,
       repeat: true,
       pauseType: 'is-primary',
       interval: 6000,
       indicator: true,
       indicatorBackground: true,
       indicatorInside: false,
       indicatorMode: 'hover',
       indicatorPosition: 'is-top',
       indicatorStyle: 'is-lines',
       carousels_academic: [
         {color: '#00aea7',image:'Banners/default1.6.png',link:'/#'},
         {color: '#4f8fde',image:'Banners/academic5.jpg',link:'/people/'+this.personId},
         {color: '#09b8ab',image:'Banners/academic2.3.jpg',link:'/people/'+this.personId},
         // {color: '#f7bc56',image:'Banners/academic3.2.png',link:'/video_tutorials'},
         //{color: '#009d72',image:'Banners/academic4.2.png',link:'/people/'+this.personId+'/life_record'},
         {color: '#00aea7',image:'Banners/academic6.jpg',link:'/documents/documents-navigation'},
       ],
       carousels_principals: [
         {color: '#00aea7',image:'Banners/default1.6.png',link:'/#'},
         //{color: '#4f8fde',image:'Banners/principals1.2.png',link:'/admin/units/'+this.unit},
         //{color: '#f7bc56',image:'Banners/principals2.2.png',link:'/life_record'},
         //{color: '#009d72',image:'Banners/principals3.2.png',link:'/life_record'},
         {color: '#009d72',image:'Banners/academic6.jpg',link:'/documents/documents-navigation'},
       ],
       carousels: [
         {color: '#00aea7',image:'Banners/default1.6.png',link:'/#'},
         //{color: '#4f8fde',image:'Banners/default2.3.png',link:"/people/"+this.personId+"/my-academic-data"},
       ],
     }
   },
   methods: {
     info(value) {
       this.carousel = value
       this.$buefy.toast.open({
         message: `This Slide ${value} !`,
         type: 'is-info'
       })
     }
   }
 }
</script>
