<template>
<section>
  <div>

    <section>
      <div class="box">
        <div class="container">
          <section>
            <nav class="breadcrumb" aria-label="breadcrumbs">
              <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/projects/postulated">Proyectos Postulados</a></li>
                <li class="is-active"><a href="">Proyecto Postulado - {{project.name}}</a></li>
              </ul>
            </nav>
          </section>
          <br>
          <b-tabs position="is-centered" @input="changeTab">
            <b-tab-item v-for="item in items"
                        :value="item.content"
                        v-bind:key="item.content"
                        :label="item.tab"
                        :icon="item.icon">
            </b-tab-item>

          </b-tabs>

          <div class="columns ">
            <div class="column">

            </div>
            <div v-if="currentTabComponent=='General'" class="column is-narrow">
              <b-button type="is-success"
                        icon-left="plus"
                        @click="adjudicateProject"
                        v-if="canAdjudicate==='true'">
                Adjudicar Proyecto
              </b-button>
              <b-button type="is-primary"
                        icon-left="mouse-pointer"
                        @click="goToAdjudicated"
                        v-if="canAdjudicate==='false' && project.already_adjudicated">
                Ir a proyecto adjudicado
              </b-button>
            </div>
            <div v-if="currentTabComponent=='General'" class="column is-narrow">
              <b-button type="is-info"
                        icon-left="edit"
                        @click="editProject">
                Editar Proyecto
              </b-button>
            </div>
            <div v-if="currentTabComponent=='Participants'" class="column is-narrow">
              <b-button type="is-info"
                        icon-left="edit"
                        @click="editProject">
                Editar Participantes
              </b-button>
            </div>
            <div class="column is-narrow">
              <b-button type="is-danger"
                        icon-left="trash"
                        @click="deleteProject">
                Eliminar Proyecto
              </b-button>
            </div>
          </div>


          <!-- COMPONENTE AQUI -->
          <keep-alive>
            <component v-bind:is="currentTabComponent"
                       :cargo-principal="cargoPrincipal"
                       :participants="project.participants"
                       :show-pecuniary-contribution="true"
                       :project="project"></component>
          </keep-alive>
          <!-- FIN COMPONENTE -->

        </div>
      </div>
    </section>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        :active="isModalActive"
        width="90%"
        :can-cancel="canCancel"
        aria-modal>
      <template #default="props">
        <postulated-project-form modal-cancel="true"
                          :cargo-principal="cargoPrincipal"
                          modal-header="Editar Proyecto"
                          query-type="update" v-bind:project-id="project" @close="closeModal" @update="updateProject"></postulated-project-form>
      </template>
    </b-modal>

    <b-modal
        :active="isAdjudicateModalActive"
        width="50%"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <select-adjudication-process
            modal-cancel="true"
            :cargo-principal="cargoPrincipal"
            modal-header="Seleccionar proceso de Adjudicación"
            query-type="create" v-bind:project-id="project.id" v-bind:project="project" @close="closeModalAdjudicate"></select-adjudication-process>
      </template>
    </b-modal>

  </div>
</section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import General from './PostulatedProjectGeneralTab.vue';
import Participants from './ProjectParticipantsTab.vue';
import Entities from './ProjectAssociatedEntitiesTab.vue';
import PostulatedProjectForm from './PostulatedProjectForm.vue';
import PostulatedFinancingsTab from './PostulatedFinancingsTab.vue';
import SelectAdjudicationProcess from "./SelectAdjudicationProcess";
export default {
  name: "ShowPostulatedProject",
  components:{ General, Participants, Entities, PostulatedProjectForm, PostulatedFinancingsTab, SelectAdjudicationProcess },
  data(){
    return{
      project: {
        project_type_attributes:[
          {id:-1,name:""}
        ],
        participants_attributes:[],
      },
      isLoading:false,
      participantes: [],
      currentPage:1,
      cardLayout:false,
      currentTab: 'General',
      items: [
        {tab: 'General',content:'General',icon:"file-alt"},
        {tab: 'Participantes',content:'Participants',icon:"users"},
        {tab: 'Aportes Relacionados',content:'PostulatedFinancingsTab',icon:"file-invoice-dollar"},
        {tab: 'Entidades Relacionadas',content:'Entities',icon:"industry"},

      ],
      isModalActive:false,
      isAdjudicateModalActive: false,
      canCancel: ['X'],

    }
  },
  created(){
    this.getProject();

  },
  props:[
    'projectId','cargoPrincipal', 'canAdjudicate', 'adjudicatedId'

  ],
  methods:{
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    editProject(project_id){
      //window.location.href = "/projects/"+project_id+"/edit"
      this.isModalActive = true;
    },
    adjudicateProject(project_id){
      this.isAdjudicateModalActive = true;
    },
    goToAdjudicated(){
      if(this.project.adjudicated){
        window.location.href = "/projects/"+this.project.adjudicated.id
      }
    },
    changeTab: function(value){
      this.currentTab = value
    },
    closeModal(){
      this.isModalActive = false;
    },
    closeModalAdjudicate(){
      this.isAdjudicateModalActive = false;
    },
    getProject(){
      this.isLoading=true;
      return axios
        .get("/projects/postulated/"+this.projectId+".json")
        .then(res => {
          this.project = res.data;
          if (this.project.postulation_date){
            this.project.postulation_date = new Date(moment(this.project.postulation_date).format('MM/DD/YYYY'));
          }

          if(this.project.participants!= null){
            this.participantes = this.project.participants
          }
          if(this.project.participants== ""){
            this.project.participants = [];
            this.participantes = [];
          }
          this.isLoading=false;
        })
        .catch(e => {
          console.log(e);
          this.isLoading=false;
        });
    },
    updateProject(){
      this.getProject();
    },
    deleteProject(){
      this.$buefy.dialog.confirm({
        title: 'Anular Proyecto Postulado',
        message: '¿Está seguro que desea <b>anular</b> el proyecto postulado? Esta acción lo dejará inválido.<br>',
        confirmText: 'Anular Proyecto Postulado',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true
          axios
              .delete("/projects/" + this.project.id + "/nullify_project.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Proyecto postulado anulado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                if (document.referrer == ''){
                  window.location.href = history.back()
                }
                else{
                  window.location.href = document.referrer
                }
                this.isLoading = false;
              }).catch(error => {
            //console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Hubo un error en su requisito. Proyecto no pudo ser anulado</b><br>',
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
          })
        }
      })
    }
  },
  computed:{
    currentTabComponent: function () {
      return this.currentTab
    },
    isAdjudicateModalActive: function () {
      if (!this.isAdjudicateModalActive) {
        this.initialize()
      }
    },
  }
}
</script>

<style scoped>

</style>