<template>
  <section>

    <div class="columns is-centered">
      <div class="column">

        <b-message type="is-info" has-icon>
          Esta Publicación en Libro solo puede ser <b>editada</b> o <b>anulada</b> por quien agregó estos datos al sistema. Si hay un error en la información por favor ponerse en contacto con la persona propietaria. 
          Si el dato que ve no corresponde a usted, puede <b>eliminar</b> su participación en la sección <b>Participantes</b>, y este dato dejará de estar visible.
        </b-message>

      </div>
    </div>

    <div class="columns is-centered">
        <div class="column">
          <div v-if="show != 'True' ">
            <div class="has-text-right" v-if="publication.publication_type_id == 5">
              <b-button type="is-info"
                        icon-left="edit"
                        v-if="(publication.can_edit && publication.academic_activity_state_id == 1) || (publication.can_edit && publication.academic_activity_state_id == null)"
                        @click="editPublication()">
                Editar Artículo
              </b-button>
              <b-button v-if="(publication.is_creator || cargoPrincipal.name == 'ANALISTA I+D FING') && (publication.academic_activity_state_id == 1 || publication.academic_activity_state_id == null)" type="is-danger"
                        icon-left="ban"
                        @click="execNullifyModal">
                Anular Artículo
              </b-button>
            </div>

            <div class="has-text-right" v-else>
              <b-button type="is-info"
                        icon-left="edit"
                        v-if="(publication.can_edit && publication.academic_activity_state_id == 1) || (publication.can_edit && publication.academic_activity_state_id == null)"
                        @click="editPublication()">
                Editar Capítulo
              </b-button>
              <b-button v-if="(publication.is_creator || cargoPrincipal.name == 'ANALISTA I+D FING') && (publication.academic_activity_state_id == 1 || publication.academic_activity_state_id == null)" type="is-danger"
                        icon-left="ban"
                        @click="execNullifyModal">
                Anular Capítulo
              </b-button>
            </div>
          </div>
          


          <hr class="dropdown-divider" aria-role="menuitem">
          <div class="columns">
            <div v-if="publication.publication_type_id==5" class="column">
              <b-field label="Título de Artículo">
                  <b-input readonly v-model="publication.title" type="text"></b-input>
              </b-field>
            </div>
            <div v-else class="column">
              <b-field label="Título de Capítulo de Libro">
                  <b-input readonly v-model="publication.title" type="text"></b-input>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-field label="Autores">
                  <b-input  readonly v-model="publication.participants_references" type="text" maxlength="255" :has-counter="false"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Título de Libro">
                <b-input readonly v-model="publication.books_attributes.title" type="text"></b-input>
              </b-field>
            </div>
            <div v-if="publication.books_attributes != null && show != 'True'" class="column is-narrow">
              <br>
              <b-field label=" ">
                <b-button class="is-primary" @click="goBook(publication.books_attributes.id)" >
                  Ir al Libro
                </b-button>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-field label="Página inicio">
                <b-input readonly v-model="publication.start_page" type="text"></b-input>
              </b-field>
            </div>
            <div class="column" >
              <b-field label="Página término">
                <b-input  readonly v-model="publication.end_page" type="text"></b-input>
              </b-field>
            </div>                  
          </div>

          
        </div>
      </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        :active="isComponentModalActive"
        width="90%"
        :can-cancel="canCancel"
        aria-modal>
      <template #default="props">
        <new-inbook-form modal-cancel="true"
                          :cargo-principal="cargoPrincipal"
                          :user-id="userId"
                          :publication-type-id="tipoContent"
                          modal-header="Editar Sección"
                          query-type="update" v-bind:inbook-id="publication.id" @close="closeModal"></new-inbook-form>
      </template>
    </b-modal>
  </section>
</template>
<script>
import axios from "axios";
import NewInbookForm from "./NewInbookForm";
export default {
  name: "ShowGeneralTab",
  components: {NewInbookForm},
  props:['cargoPrincipal','userId','bookId','publication','participantes' , 'show'],
  data(){
    return{
      currentPage:1,
      isLoading:false,
      requiredFieldOnly:false,
      isComponentModalActive: false,
      tipoContent: null,
      canCancel: ['escape']

    }
  },

  methods:{
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    editPublication(){
      if (this.publication.publication_type_id === 5)
      {
        this.tipoContent = "article";
      }
      else{
        this.tipoContent = "chapter";
      }
      this.isComponentModalActive = true;
    },
    closeModal(){
      this.isComponentModalActive = false;
    },
    goBook(book_id){
      window.location.href="/books/"+book_id;
    },
    execNullifyModal(){
      let title = ''
      let msg = ''
      if (this.publication.publication_type === 'Booklet') {
        title = 'Anular Capítulo'
        msg = '¿Está seguro que desea <b>anular</b> el Capítulo? Esta acción lo dejará inválido.<br>'
      } else if (this.publication.publication_type === 'Inbook') {
        title = 'Anular Artículo'
        msg = '¿Está seguro que desea <b>anular</b> el Artículo? Esta acción lo dejará inválido.<br>'
      }
      this.$buefy.dialog.confirm({
        title: title,
        message: msg,
        confirmText: title,
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true
          axios
              .delete("/publications/" + this.publication.id + "/nullify_publication.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Publicación anulada correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                if (document.referrer == ''){
                  window.location.href = history.back()
                }
                else{
                  window.location.href = document.referrer
                }
                this.isLoading = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Publicación no pudo ser anulado</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
          })
        }
      })
    },

  },
  computed:{
  }
}
</script>
<style scoped>

</style>