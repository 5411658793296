<template>
  <div>
    <b-table  ref="table"
              class="table-border"
              :data="permit_people"
              paginated
              pagination-position="bottom"
              narrowed
              per-page="10"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
              striped>
      <b-table-column
          width="8rem"
          field="rut"
          label="RUT"
          :custom-search="searchRun"
          sortable
          searchable>
        <template #searchable="props">
          <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Ej: 12345678-9"
              icon="search"
              size="is-small"
              class="pl-2"
          />
        </template>
        <template #default="props">
          <div style="white-space: nowrap">
            {{ props.row.rut ? props.row.rut : "" }}
          </div>
        </template>
      </b-table-column>
      <b-table-column
          width="10rem"
          field="last_name"
          label="Primer Apellido"
          sortable
          searchable
      >
        <template #searchable="props">
          <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Ej: Pérez"
              icon="search"
              size="is-small"
              class="pl-2"
          />
        </template>
        <template #default="props">
          {{ props.row.last_name }}
        </template>
      </b-table-column>

      <b-table-column
          width="10rem"
          field="second_surname"
          label="Segundo Apellido"
          sortable
          searchable
      >
        <template #searchable="props">
          <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Ej: González"
              icon="search"
              size="is-small"
              class="pl-2"
          />
        </template>
        <template #default="props">
          {{ props.row.second_surname }}
        </template>
      </b-table-column>
      <b-table-column
          field="name"
          label="Nombres"
          sortable
          searchable
      >
        <template #searchable="props">
          <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Ej: Juan"
              icon="search"
              size="is-small"
              class="pl-2"
          />
        </template>
        <template #default="props">
          {{ props.row.name }}
        </template>
      </b-table-column>
      <b-table-column
          width="9rem"
          field="total_requested_days"
          label="Días solicitados"
          searchable
          sortable
          :custom-search="searchTotalRequestDays"
      >
        <template #searchable="props">
          <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Ej: 2"
              icon="search"
              size="is-small"
              class="pl-2"
          />
        </template>
        <template #default="props">
          {{ props.row.total_requested_days }}
        </template>
      </b-table-column>

      <b-table-column
          width="9rem"
          field="total_process_days"
          label="Días en proceso"
          searchable
          sortable
          :custom-search="searchTotalProcessDays"
      >
        <template #searchable="props">
          <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Ej: 2"
              icon="search"
              size="is-small"
              class="pl-2"
          />
        </template>
        <template #default="props">
          {{ props.row.total_process_days }}
        </template>
      </b-table-column>

      <b-table-column
          width="9rem"
          field="total_remaining_days"
          label="Días restantes"
          searchable
          sortable
          :custom-search="searchTotalRamainingDays"
      >
        <template #searchable="props">
          <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Ej: 2"
              icon="search"
              size="is-small"
              class="pl-2"
          />
        </template>
        <template #default="props">
          {{ props.row.total_remaining_days }}
        </template>
      </b-table-column>
      <b-table-column v-slot="props" label="Acciones">
        <b-button
            tag="a"
            :href="'/administrative-permits/' + props.row.id + '/details'"
            target="_blank"
            class="is-primary"
            icon-left="file-invoice"
        >Ver Permisos
        </b-button>
      </b-table-column>

      <template slot="empty">
        <div class="is-flex is-justify-content-center">
          <b-message type="is-danger" has-icon>
            No se encontraron resultados.
          </b-message>
        </div>
      </template>
    </b-table>
  </div>
</template>


<script>

export default {
  props: ['permit_people'],
  data(){
    return{

    }
  },
  methods: {
    searchRun(row, input) {
      return row.rut
          .replaceAll(".", "")
          .replaceAll("-", "")
          .includes(input.replaceAll(".", "").replaceAll("-", "").trim());
    },
    searchTotalRequestDays(row, input) {
      return row.total_requested_days.toString().includes(input);
    },
    searchTotalProcessDays(row, input) {
      return row.total_process_days.toString().includes(input);
    },
    searchTotalRamainingDays(row, input) {
      return row.total_remaining_days.toString().includes(input);
    },
  }
}

</script>

<style scoped>

</style>