<template>
  <div>
    <ValidationObserver ref="observerSon" v-slot="{ handleSubmit }" disabled>
      <form @submit.prevent="handleSubmit(saveDocument)">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head"  v-if='queryType == "updateTransaction" || queryType == "show"'>
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body">
            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion">
                      <div v-if="item">{{ item[0] }}</div>
                    </div>
                  </b-message>
                </div>
              </div>
            </div>
            <div class="box" v-if="queryType!='show'">
              <div class="columns">
                <div v-if="tiposAnexo.length == 2">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-field label="Seleccione Factura o Respaldo*">

                      <b-radio class="column" v-for="item in tiposAnexo" :key="item.id" v-model="element.tipoAnexo" :native-value="item" @input="actualizarTipoComputado">
                        {{item.name }}
                      </b-radio>
                    </b-field>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div  v-else>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-field label="Seleccione Boleta, Factura o Respaldo*">

                      <b-radio class="column" v-for="item in tiposAnexo" :key="item.id" v-model="element.tipoAnexo" :native-value="item" @input="actualizarTipoComputado">
                        {{item.name }}
                      </b-radio>
                    </b-field>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="columns">


                <div class="column ">
                  <b-field :label="element.tipoAnexo && element.tipoAnexo.name ? 'Código de '+capitalizarTexto(element.tipoAnexo.name) : 'Código de Boleta/Factura'" >
                    <ValidationProvider rules="required|max:20" v-slot="{errors}">
                      <b-input type="text"  maxlength="20" placeholder="Ej: 002651" v-model="element.code"></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Monto Bruto Total*" >                            
                    <ValidationProvider rules="required|numeric|positive|max:11" v-slot="{errors}">
                      <b-input type="text"  maxlength="11" placeholder="Ej: 500000" v-model="element.amount"></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column" v-if="tiposFinanzas.length>0">
                  <b-field label="Categoría de servicio*">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <multiselect v-model="element.sdt_transaction_type_id"
                                   :options="tiposFinanzas.map(type => type.id)"
                                   :custom-label="opt => tiposFinanzas.find(x => x.id == opt).name"
                                   placeholder="Seleccione Categoría de servicio"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="No se puede deseleccionar"
                                   @select="showOther"
                                   :allow-empty="false">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                            No se encontraron elementos.
                        </span>
                      </multiselect>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>

                <div class="column">
                  <b-field label="Fecha de pago*">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <b-datepicker
                          v-model="element.payment_date"
                          icon="calendar-alt"
                          trap-focus
                          locale="es-ES"
                          editable
                          placeholder="Formato de Fecha: dd/mm/aaaa">
                      </b-datepicker>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>

              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Descripción de otro*" v-if="show_other == true" >                            
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <b-autocomplete v-model="element.other_id"
                                      :keep-first="keepFirst"
                                      :open-on-focus="openOnFocus"
                                      :data="other_types"
                                      field="description"
                                      empty="Vacio"
                                      placeholder="Ej: Tramitaciones legales"
                                      @typing="searchOtherTypes"
                                      @select="option => {if (option) {element.other_id = option.id} }"
                                      :clearable="clearable">
                        <template slot-scope="props">
                          {{ props.option.description }}
                        </template>
                      </b-autocomplete>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Proveedor (ingrese por lo menos 3 letras) ">
                        <b-autocomplete v-model="element.entidad_name"
                                        :keep-first="keepFirst"
                                        :open-on-focus="openOnFocus"
                                        :data="partner_entities"
                                        field="name"
                                        empty="Vacio"
                                        placeholder="Ej: ANID"
                                        @typing="searchPartnerEntities"
                                        @select="option => {if (option) {element.partner_entity_id = option.id} }"
                                        :clearable="clearable">
                          <template slot-scope="props">
                            {{ props.option.show_name ? props.option.show_name : props.option.name}} {{ props.option.rut ? '[ RUT: ' + props.option.rut.rut + ']' : '' }}
                          </template>
                        </b-autocomplete>
                    </b-field>
                </div>
                <div class="column is-narrow">
                  <br>
                  <b-field label=" ">
                    <b-tooltip label="Si la entidad que desea agregar no existe, la puede agregar presionando el botón +"
                               position="is-bottom">
                      <b-button class="is-primary is-outlined" @click="addEntity()">
                        <b-icon icon="plus"></b-icon>
                      </b-button>
                    </b-tooltip>
                  </b-field>
                </div>
              </div>


              <div class="columns" >
                <div class="column is-centered has-text-centered">
                  <b-message type="is-warning" title="Información" has-icon aria-close-label="Close message">
                        <b>IMPORTANTE: FORMATO DE ARCHIVO</b> <br />
                        Extensiones de archivo permitidas: PDF, DOC, DOCX, XLSX, XLS.
                        <br />
                        El tamaño maximo del archivo es de <b> 10 MB</b>
                      </b-message>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-field class="file is-primary is-centered" :class="{'has-name': !!element.file}">
                      <b-upload v-model="element.file" class="file-label is-centered">
                          <span class="file-cta">
                              <b-icon class="file-icon" icon="upload"></b-icon>
                              <span class="file-label">Subir  {{ element.tipoAnexo && element.tipoAnexo.name ? capitalizarTexto(element.tipoAnexo.name) : " " }} <span class="file-label" v-if="!element.tipoAnexo && tiposAnexo.length==2"> &nbsp; Factura o Respaldo </span> <span class="file-label" v-else-if="!element.tipoAnexo"> &nbsp; Boleta, Factura o Respaldo </span> </span>
                          </span>
                          <span class="file-name" v-if="element.file">
                              {{ element.file.name }}
                          </span>
                      </b-upload>
                    </b-field>
                    <span class="validation-alert has-text-centered is-centered">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>


              <div class="columns">
                <div class="column has-text-right">
                  <div class="is-pulled-right">
                    <b-button
                      icon-left="plus"
                      class="button right-button is-primary"
                      native-type="submit" 
                      v-if="queryType=='create'"             
                      @click="checkValidation()">
                        Añadir Documento
                    </b-button>
                    <b-button
                      icon-left="plus"
                      class="button right-button is-primary"
                      native-type="submit"
                      v-if="queryType=='update'"
                      @click="checkValidation()">
                        Actualizar Documento
                    </b-button>
                  </div>

                </div>
                
              </div>

            </div>

            <div class="box" v-else>
              <div class="columns">
                <div class="column">
                  <b-field label="Categoría de transacción" >
                    <b-input readonly v-model="element.sdt_transaction_type" type="text"></b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns" v-if="element.other_id !=''">
                <div class="column">
                  <b-field label="Descripción de otro Categoría de transacción">
                    <b-input readonly v-model="element.other_id" type="text"></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Fecha de pago">
                    <b-input readonly v-model="element.payment_date" type="text"></b-input>
                  </b-field>
                </div>
                
                <div class="column">
                  <b-field label="Monto bruto total">
                    <b-input readonly v-model="element.amount" type="text"></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns" v-if="element.partner_entity != ''">
                <div class="column">
                  <b-field label="Proveedor">
                    <b-input readonly v-model="element.partner_entity" type="text"></b-input>
                  </b-field>
                </div>
              </div>
              
            </div>

            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
          </section>
          <footer class="modal-card-foot actions"  v-if='queryType == "updateTransaction" || queryType == "show"'>
          <button class="button  left-button" type="button" @click="$emit('close')">Cerrar</button>
          <b-button class="button  right-button is-primary" v-if='queryType == "updateTransaction"' left-button="save" native-type="submit" @click="checkValidation()">Guardar </b-button>

        </footer>
        </div>


        <b-modal
            v-model="isAddModalEntityActive"
            has-modal-card
            trap-focus
            :destroy-on-hide="true"
            aria-role="dialog"
            aria-modal
            :can-cancel="canCancel">
          <template #default="props">
            <modal_partner_entity
                query-type="create"
                modal-header="Añadir nueva entidad"
                @close="props.close"
                @clicked="onClickChildEntity"></modal_partner_entity>
          </template>
        </b-modal>



      </form>
    </ValidationObserver>

    

  </div>
</template>

<script>
import axios from "axios"
import modal_partner_entity from "../../moduloVIME/agreements/new/modal_partner_entity";
import moment from "moment/moment";
export default {
  name: "ModalDocumentRenderFund",
  components: { modal_partner_entity },
  props:[
    'queryType', 'tiposFinanzas', 'modalHeader','respaldo','tiposAnexo','nuevaEntidad', 'act'
  ],
  data(){
    return {
      tipos_pago: [],
      isView: false,
      errores_validacion:{},
      other_types:[],
      partner_entities:[],
      show_other: false,
      loGuardado: {},
      element: {},

      isLoading: false,
      keepFirst: false,
      openOnFocus: false,
      clearable: false,
      isAddModalEntityActive: false,
      canCancel: ['escape'],

      abb: null,
      type: ""
      
    }
  },



  watch: {
    nuevaEntidad: function (entidad) {
      this.element.entidad_name = entidad.name
      this.element.partner_entity_id = entidad.id
    },
    act: function(){
      if (this.queryType == "update"){
        this.element = { ...this.respaldo }
      }
    },
  },

  created(){
    this.element = { ...this.respaldo }
    if (this.queryType == "updateTransaction")
    {
      this.element = { ...this.element.transaction };
      this.element.file = {};
      this.element.file.name = this.respaldo.name;
      this.element.file.id = this.respaldo.id;

      this.element.tipoAnexo = this.respaldo.document_type;
      this.element.tipoAnexo.name = this.element.tipoAnexo.name.replace(/^ANEXO \s*/, '');
      this.element.tipoAnexo = this.tiposAnexo.find(item => item.name === this.element.tipoAnexo.name);
      this.element.payment_date = new Date(moment(this.element.payment_date).format('MM/DD/YYYY'));
      if(this.element.partner_entity != null){
        this.element.entidad_name = this.element.partner_entity.name;
      }
      this.showOther();
    }
    if(this.tiposAnexo.length == 2){
      this.type="Factura o Respaldo";
    }
    else{
      this.type="Boleta, Factura o Respaldo";
    }


    
  },
  methods: {
    
    async checkValidation() {
      this.is_validated = false;
      const valid = await this.$refs.observerSon.validate()
      if (!valid) {
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar los campos.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
      else{
        this.is_validated = true;
      }
    },

    async actualizarTipoComputado() {
      if(this.element.code != null){
        this.element.code = this.element.code + " "
      }
      else
      {
        this.element.code = " "
      }
      await new Promise(resolve => setTimeout(resolve, 50))
      this.element.code = this.element.code.slice(0, -1);

    },

    capitalizarTexto(texto) {
      return texto.charAt(0).toUpperCase() + texto.slice(1).toLowerCase();
    },

    searchOtherTypes(name){
      if(name == null   || name.length == 0){
        this.position = null
      }
      if (name.length<3) {
        this.partner_entities = []
        return
      }
      let vm = this;
      vm.isLoading=true;
      axios
          .get("/sdt_transaction_types/types-search.json", { params: {
            description: name,
            type: vm.element.sdt_transaction_type_id
          } })
          .then(res => {
            vm.other_types = res.data;
            vm.isLoading = false;
          })
          .catch(error => {
            console.log(error)
            vm.isLoading=false;
          })
    },
    searchPartnerEntities(name){
      if(name == null   || name.length == 0){
        this.position = null
      }
      if (name.length<3) {
        this.partner_entities = []
        return
      }
      let vm = this;
      vm.isLoading=true;
      axios
          .get("/sdt_transactions/entities-search.json", { params: {
            name: name
          } })
          .then(res => {
            vm.partner_entities = res.data;
            vm.isLoading = false;
          })
          .catch(error => {
            console.log(error)
            vm.isLoading=false;
          })
    },
    addEntity(){
      this.$emit('clickedEntity')
    },
    onClickChildEntity(value){
      this.isLoading = true
      this.element.entidad_name = value.name
      this.element.partner_entity_id = value.id
      this.isLoading = false
    },
    showOther(){
      if(this.tiposAnexo.length == 1){
        this.element.tipoAnexo = this.tiposAnexo[0];
      }
          
      if(this.tiposFinanzas.find(x => x.id == this.element.sdt_transaction_type_id).name.includes("OTROS BI") || this.tiposFinanzas.find(x => x.id == this.element.sdt_transaction_type_id).name.includes("OTROS SER") ){
        this.show_other = true;
      }
      else
      {
        this.show_other = false;
      }

    },
    
    saveDocument(){      
      let vm = this;      
      if (vm.is_validated ){
        if(vm.queryType == "create")
        {
          vm.respaldo.tipoAnexo = vm.element.tipoAnexo;
          vm.respaldo.sdt_transaction_type_id = vm.element.sdt_transaction_type_id;
          vm.respaldo.other_id = vm.element.other_id;
          vm.respaldo.code = vm.element.code;
          vm.respaldo.amount = vm.element.amount;
          vm.respaldo.payment_date = vm.element.payment_date;
          vm.respaldo.partner_entity_id = vm.element.partner_entity_id;
          vm.respaldo.file = vm.element.file;
          vm.respaldo.entidad_name = vm.element.entidad_name;
          vm.element = {};
          this.$emit('clicked', vm.respaldo)
          this.$refs.observerSon.reset();
          vm.$emit('close')
        }
        else if (vm.queryType == "updateTransaction")
        {
          vm.isLoading=true;
          const formData = new FormData();
          formData.append(`file0`, vm.element.file);
          formData.append(`typeFile0`, JSON.stringify(vm.element.tipoAnexo.id));
          formData.append(`originalFiles0`, vm.respaldo.id)      
          formData.append('transaction',JSON.stringify(vm.element));
          axios.put("/sdt_transactions/"+vm.element.id+".json", formData)
            .then((res) => {
              vm.isLoading = false;
              this.$buefy.dialog.alert({
                message: "La transacción fue actualizada con éxito.<br/>",
                confirmText: "Ok",            
              });
              vm.$emit("clickedUpdate",res.data);
              vm.$emit('close')
            })
            .catch(e => {
              vm.errores_validacion = e.errors
              vm.isLoading=false;
              vm.$emit('close')
          });   

        }
        else
        {
          vm.respaldo.tipoAnexo = vm.element.tipoAnexo;
          vm.respaldo.sdt_transaction_type_id = vm.element.sdt_transaction_type_id;
          vm.respaldo.other_id = vm.element.other_id;
          vm.respaldo.code = vm.element.code;
          vm.respaldo.amount = vm.element.amount;
          vm.respaldo.payment_date = vm.element.payment_date;
          vm.respaldo.partner_entity_id = vm.element.partner_entity_id;
          vm.respaldo.file = vm.element.file;
          vm.respaldo.entidad_name = vm.element.entidad_name;
          vm.element = {};
          this.$emit('clickedUpdate', vm.respaldo)
          this.$refs.observerSon.reset();
          vm.$emit('close')
        }
        
               
      }
      
    },
  }
}
</script>

<style scoped>

  .right-button{
    margin-left: auto;
  }
  .left-button{
    margin-right: auto;
  }


</style>