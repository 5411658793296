<template>
    <section>
        <div v-if="academicIndicator.show">

            <b-collapse v-show="academicIndicator.studies.show" :open="isOpenSummaryStudies" @open="isOpenSummaryStudies = true" class="card" animation="slide" aria-id="contentIdForA11y3">
                <template #trigger="props">
                    <div class="card-header" role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.open">
                        <p class="card-header-title is-centered">
                            Resumen Cuerpo Académico según Grado Académico Mayor Registrado
                        </p>
                        <a class="card-header-icon">
                            <b-icon :icon="props.open ? 'angle-down' : 'angle-up'">
                            </b-icon>
                        </a>
                    </div>
                </template>

                <div class="card-content">
                    <div class="content">
                        <SummaryStudies :indicator="academicIndicator.studies.summary" />
                    </div>
                </div>
            </b-collapse>

            <br>

            <b-collapse v-show="academicIndicator.studies.show" :open="isOpenStudy"
                @open="isOpenStudy = true" class="card" animation="slide" aria-id="contentIdForA11y3">
                <template #trigger="props">
                    <div class="card-header" role="button" aria-controls="contentIdForA11y3"
                        :aria-expanded="props.open">
                        <p class="card-header-title is-centered">
                            Proporción de Académicos según su Grado Académico Mayor Registrado
                        </p>
                        <a class="card-header-icon">
                            <b-icon :icon="props.open ? 'angle-down' : 'angle-up'">
                            </b-icon>
                        </a>
                    </div>
                </template>

                <div class="card-content">
                    <div class="content">
                        <IndicatorByType :types="academicIndicator.studies.indicator_by_type"
                            :title="'academic_grade'" />
                    </div>
                </div>
            </b-collapse>

            <br>

            <b-collapse v-show="academicIndicator.hierarchies.show"  :open="isOpenSummaryHierarchies" @open="isOpenSummaryHierarchies = true" class="card" animation="slide" aria-id="contentIdForA11y3">
                <template #trigger="props">
                    <div class="card-header" role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.open">
                        <p class="card-header-title is-centered">
                            Resumen Cuerpo Académico según Jerarquía Mayor Registrada
                        </p>
                        <a class="card-header-icon">
                            <b-icon :icon="props.open ? 'angle-down' : 'angle-up'">
                            </b-icon>
                        </a>
                    </div>
                </template>

                <div class="card-content">
                    <div class="content">
                        <SummaryHierarchies :indicator="academicIndicator.hierarchies.summary" />
                    </div>
                </div>
            </b-collapse>

            <br>

            <b-collapse v-show="academicIndicator.hierarchies.show" :open="isOpenHierarchyTypeBar"
                @open="isOpenHierarchyTypeBar = true" class="card" animation="slide" aria-id="contentIdForA11y3">
                <template #trigger="props">
                    <div class="card-header" role="button" aria-controls="contentIdForA11y3"
                        :aria-expanded="props.open">
                        <p class="card-header-title is-centered">
                            Cantidad de Académicos según su Mayor Jerarquía
                        </p>
                        <a class="card-header-icon">
                            <b-icon :icon="props.open ? 'angle-down' : 'angle-up'">
                            </b-icon>
                        </a>
                    </div>
                </template>

                <div class="card-content">
                    <div class="content">
                        <IndicatorByTypeBar :types="academicIndicator.hierarchies.indicator_by_type" :total="academicIndicator.hierarchies.total"
                            :title="'academic_hierarchy_type_bar'" />
                    </div>
                </div>
            </b-collapse>

            
        </div>
        <div v-else>
            <div class="columns is-centered">
                <div class="column is-6 is-centered has-text-centered">
                    <b-message has-icon type="is-warning">
                        <h1> No se encontraron resultados. Porfavor ingrese nuevos Nombramientos o Títulos para mostrar
                            indicadores del Cuerpo Académico de la Unidad.</h1>
                    </b-message>
                </div>
            </div>
        </div>

        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

    </section>
</template>

<script>
import axios from 'axios'
import IndicatorByType from './indicator_by_type.vue'
import IndicatorByTypeBar from './indicator_by_type_bar.vue'
import SummaryStudies from './summary_indicator_studies.vue'
import SummaryHierarchies from './summary_indicator_hierarchy.vue'
export default {

    components: {
        IndicatorByType, SummaryStudies, IndicatorByTypeBar, SummaryHierarchies
    },

    props: [
    'minYear', 'maxYear', 'unitId', 'changeFilter'
    ],

    data() {
        return {
            isOpenSummaryStudies: true,
            isOpenStudy: true,
            isOpenHierarchyTypeBar: true,
            academicIndicator: {},
            isLoading: false,
            isOpenSummaryHierarchies: true,
        }
    },

    async created() {
        await this.getAcademicIndicator()
    },

    methods: {

        async getAcademicIndicator() {

            this.isLoading = true

            await axios
                    .get('/dashboards/get-unit-academic-indicator.json', {
                        params: {
                            unitId: this.unitId,
                            minYear: this.minYear,
                            maxYear: this.maxYear,
                        }
                    })
                    .then(response => {
                        this.academicIndicator = response.data.academic
                        this.$emit('updatedData')
                        this.isLoading = false
                    })
                    .catch(error => {
                        var error_message
                        if (error.response != null) {
                            error_message = error.response.data.error
                        } else {
                            error_message = error
                        }
                        this.$buefy.dialog.alert({
                            title: 'Error',
                            message: 'Hubo un error al obtener los datos de Indicadores de Académicos de la Unidad.<br><br> Error:<br>' + error_message,
                            type: 'is-danger',
                            hasIcon: true,
                            icon: 'times-circle',
                            iconPack: 'fa',
                            ariaRole: 'alertdialog',
                            ariaModal: true
                        })
                        this.$emit('updatedData')
                        this.isLoading = false
                    })

        }

    },

    watch: {
        changeFilter() {
            this.getAcademicIndicator()
            deep: true
        },
    },

}
</script>