import { render, staticRenderFns } from "./showForms.vue?vue&type=template&id=2a2ba668&scoped=true"
import script from "./showForms.vue?vue&type=script&lang=js"
export * from "./showForms.vue?vue&type=script&lang=js"
import style0 from "./showForms.vue?vue&type=style&index=0&id=2a2ba668&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a2ba668",
  null
  
)

export default component.exports