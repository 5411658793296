<template>
    <section>
        <b-tabs position="is-centered" @input="changeTab" :multiline="true">
            <b-tab-item v-for="item in items" v-bind:key="item.content" :value="item.content" :label="item.tab"
                :icon="item.icon" />
        </b-tabs>
        <!-- COMPONENTE AQUI -->
        <keep-alive>
            <component v-bind:is="currentTabComponent" :person-id="personId" :cargo-principal="cargoPrincipal"
                :hide-update="true" :can-create-document="canCreateDocument" />
        </keep-alive>

        <!-- FIN COMPONENTE -->
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </section>
</template>

<script>
import MyVimeLargeResume from './my_vime_large_resume_tab.vue'
import MyVimeShortResume from './my_vime_short_resume_tab.vue'
export default {
    components:{
        MyVimeLargeResume, MyVimeShortResume,
    },
    props:[
        'personId', 'cargoPrincipal', 'canCreateDocument'
    ],
    data() {
        return {
            isLoading:false,
            currentTab: 'MyVimeLargeResume',
            items: [
                {tab: 'Curriculum VIME Largo',content: 'MyVimeLargeResume', icon:"file-contract"},
                {tab: 'Curriculum VIME Corto',content: 'MyVimeShortResume', icon:"file-contract"},
            ],
        }
    },
    methods: {
        changeTab: function(value){
            this.currentTab = value
        },
    },
    computed: {
        currentTabComponent: function () {
            return this.currentTab
        }
    }
}
</script>