<template>
  <section>
    <div class="container">
      <div class="box">
        <div class="columns">
          <h3 class="is-size-3 has-text-weight-bold">Mis Solicitudes
            <b-tooltip
                label="Se listan las solicitudes hechas por el usuario"
                multilined
                position="is-right">
              <b-icon
                  pack="fas"
                  icon="info-circle"
                  type="is-info">
              </b-icon>
            </b-tooltip>
          </h3>

        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(myRequests)">
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de Nombramiento">
                  <multiselect v-model="request_type"
                               :options="request_types.map(type => type.id)"
                               :custom-label="opt => request_types.find(x => x.id === opt).name"
                               placeholder="Seleccione tipo de solicitud"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Seleccione nuevamente para deseleccionar"
                               :allow-empty="true">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                    No se encontraron elementos.
                </span>
                  </multiselect>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Estado">
                  <multiselect v-model="request_state"
                               :options="request_states.map(type => type.id)"
                               :custom-label="opt => request_states.find(x => x.id === opt).name"
                               placeholder="Seleccione estado"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Seleccione nuevamente para deseleccionar"
                               :allow-empty="true">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                    No se encontraron elementos.
                </span>
                  </multiselect>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Unidad">
                  <multiselect v-model="unit"
                               :options="units.map(type => type.id)"
                               :custom-label="opt => units.find(x => x.id === opt).name"
                               placeholder="Seleccione unidad"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Seleccione nuevamente para deseleccionar"
                               :allow-empty="true">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                    No se encontraron elementos.
                </span>
                  </multiselect>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="actions has-text-centered">
                  <button class="button is-primary">Aplicar Filtro</button>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>

        <b-table
            :data="requests"
            :paginated= true
            per-page="10"
            striped
            hoverable
            sticky-header
            height="640"
            :narrowed=true
            :current-page="currentPage"
            :pagination-simple=false
            pagination-position="both"
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            default-sort="product.title"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual">
          <b-table-column
              field="id"
              label="#"
              width="10"
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
                <b-tag type="is-primary" style="cursor:pointer;"  size="is-medium" @click.native="goToRequest(props.row)"> {{ props.row.id}}</b-tag>
            </template>
          </b-table-column>
          <b-table-column
              field="request_type.name"
              label="Tipo de Solicitud"
              searchable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.request_type.name === "INDIVIDUAL"?"INDIVIDUAL":"MASIVA"}}
            </template>
          </b-table-column>
          <b-table-column
              field="request_type.name"
              label="Tipo de Nombramiento"
              searchable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.request_type!== undefined ? props.row.request_type.name : ""}}
            </template>
          </b-table-column>
          <b-table-column
              field="request_state.name"
              label="Estado"
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              <b-tag :type="props.row.type" size="is-large">{{ props.row.request_state!==undefined ? props.row.request_state.name : "" }}</b-tag>
            </template>
          </b-table-column>
          <b-table-column
              field="unit.name"
              label="Unidad"
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.unit!== undefined ? props.row.unit.name : ""}}
            </template>
          </b-table-column>
          <b-table-column
              field="resolution"
              label="N° Resolución"
              width="50"
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.resolution}}
            </template>
          </b-table-column>
          <b-table-column
              field="resolution_date"
              label="Fecha de Resolución"
              width="100"
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.resolution_date}}
            </template>
          </b-table-column>
          <b-table-column
              field="formatted_request_start_date"
              width="100"
              label="Fecha de Inicio"
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.formatted_request_start_date}}
            </template>
          </b-table-column>
          <b-table-column
              field="formatted_request_end_date"
              label="Fecha de Término"
              searchable
              width="100"
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.formatted_request_end_date}}
            </template>
          </b-table-column>
        </b-table>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "my_requests",
  props: ["personId"],
  components:{
    },
  data(){
    return {
      selected: null,
      requests: [],
      currentPage: 1,
      isLoading: false,
      no_filters: true,
      formattedDate: "",
      canCancel: ['escape'],
      show: false,
      edit: false,
      request_types: [],
      request_states: [],
      request_type: null,
      request_state: null,
      unit: null,
      units: []
    }
  },
  async created(){
    await this.initialize()
    await this.myRequests()
  },
  methods:{
    async initialize(){
      this.isLoading = true
      return axios
          .get("/requests/get-types.json")
          .then(res => {
            this.request_types = res.data["request_types"]
            this.request_states = res.data["request_states"]
            this.units = res.data["units"]
            this.isLoading = false
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
          })
    },
    async myRequests(){
      this.isLoading = true
      return axios
          .get("/people/"+this.personId+"/my-requests.json", {
            params:{
              request_type: this.request_type,
              request_state: this.request_state,
              unit: this.unit
            }
          })
          .then(res => {
            this.requests = res.data
            this.requests = this.requests.map(request => {
              var newRequest = request
              newRequest["request_start_date"] = this.formatDate(request.request_start_date);
              newRequest["request_end_date"] = this.formatDate(request.request_start_date);
              newRequest["resolution_date"] = this.formatDate(request.resolution_date);
              return newRequest;
            });
            this.isLoading = false
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
          })
    },
    promptRequest(request){
      this.isLoading = true
      this.$buefy.dialog.confirm({
        title: '¿Enviar solicitud?',
        message: '¿Está seguro que desea <b>enviar</b> la solicitud?. Esta acción no puede deshacerse.',
        confirmText: 'Enviar Solicitud',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.sendRequest(request)
      })
      this.isLoading = false
    },
    sendRequest(request){
      this.isLoading = true
      axios.put("/requests/"+request.id+"/send-request.json").then(res => {
        this.$buefy.dialog.alert({
          title: 'Envío de Solicitud',
          message: 'Solciitud enviada correctamente',
          confirmText: 'Ok',
          type: 'is-success',
          hasIcon: true,
        })
        this.myRequests()
        this.isLoading = false
      }).catch(error => {
        console.log(error)
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al enviar la solicitud, intente nuevamente',
          confirmText: 'Ok',
          type: 'is-danger',
          hasIcon: true,
        })
        this.isLoading = false
      })
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    goToRequest(request){
      window.open('/requests/'+request.id)
    }
  },
  watch: {
    edit: function () {
      if (!this.edit) {
        this.initialize()
      }
    },
  }
}
</script>
