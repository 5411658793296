<template>
  <div class="container">
    <br>
    <div class="box">
      <div class="columns">
        <div class="column has-text-centered">
          <div class="is-size-2">Exportación de datos de personas del estamento académico para la gestión I+D</div>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column">
          <b-message title="Importante" type="is-warning" has-icon aria-close-label="Cerrar">
            La información contenida en este documento podría estar desactualizada y responde a los datos ingresados
            por la oficina administrativa FING.
          </b-message>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Tipo de Unidad">
            <multiselect v-model="type"
                         :options="unit_types"
                         placeholder="Seleccione tipo de unidad"
                         selectLabel="Presione para seleccionar"
                         selectedLabel="Seleccionado"
                         deselectLabel="No se puede deseleccionar"
                         :allow-empty="false">
              <template v-slot:noOptions>
                No existen datos
              </template>
              <span slot="noResult">
                No se encontraron elementos.
            </span>
            </multiselect>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Unidad">
            <multiselect v-model="unit"
                         :options="units"
                         placeholder="Seleccione unidad"
                         selectLabel="Presione para seleccionar"
                         selectedLabel="Seleccionado"
                         deselectLabel="No se puede deseleccionar"
                         :allow-empty="false">
              <template v-slot:noOptions>
                No existen datos
              </template>
              <span slot="noResult">
                No se encontraron elementos.
            </span>
            </multiselect>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Estado">
            <multiselect v-model="state"
                         :options="states"
                         placeholder="Seleccione estado"
                         selectLabel="Presione para seleccionar"
                         selectedLabel="Seleccionado"
                         deselectLabel="No se puede deseleccionar"
                         :allow-empty="false">
              <template v-slot:noOptions>
                No existen datos
              </template>
              <span slot="noResult">
                No se encontraron elementos.
            </span>
            </multiselect>
          </b-field>
        </div>

      </div>
      <div class="columns">
        <div class="column">
          <div class="has-text-right">
            <b-button label="Exportar"
                      class="is-primary is-outlined"
                      @click="exportData"
                      icon-left="file-excel"/>
          </div>
        </div>
      </div>
    </div>



    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ExportMhvForId",
  data(){
    return {
      selected: null,
      people: [],
      currentPage: 1,
      isLoading: false,
      no_filters: true,
      unit: null,
      units: [],
      unit_types: [],
      genders:[],
      gender:{},
      type: "UNIDAD DE DESEMPEÑO",
      states: [],
      state: "ACTIVOS"
    }
  },
  created(){
    this.initialize()
  },
  methods: {
    initialize() {
      this.isLoading = true
      return axios
          .get("/people/show-export-people-for-i-d.json")
          .then(res => {
            this.units = res.data.units
            this.unit = res.data.units[0]
            this.states = res.data.states
            this.unit_types = res.data.unit_types
            this.genders = res.data.genders
            this.isLoading = false

          })
          .catch(error => {
            console.log(error)
            this.isLoading = false
          })
    },
    exportData() {
      this.isLoading = true
      let url = "/people/export-people-for-i-d.xlsx?e=AcademicIndex&unit_type="+this.type+"&unit="+this.unit+"&state="+this.state
      window.open(url)
      this.isLoading = false
    },
  }
}
</script>

<style scoped>

</style>