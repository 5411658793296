<template>
  <section>
    <div class="container">
      <h1 class="is-size-2">Unidades</h1>
      <div class="has-text-right">
        <b-button type="is-info"
                  icon-left="plus"
                  @click="newUnit()">
          Añadir Unidad
        </b-button>
      </div>
      <br>
      <b-table
          :data="unidades"
          :paginated=true
          per-page=10
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="publication.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field= 'name'
            label= 'Nombre'
            width="30em"
            searchable
            sortable
            v-slot="props"
        >
          <a @click="goToUnitInfo(props.row.id)">{{ props.row.name }}</a>
        </b-table-column>
        <b-table-column
            field= 'unit_type'
            label= 'Tipo de Unidad'
            searchable
            sortable
            v-slot="props"
        >
          {{ props.row.unit_type }}
        </b-table-column>
        <b-table-column
            field= 'url'
            label= 'URL'
            searchable
            sortable
            v-slot="props"
        >
          {{ props.row.url }}
        </b-table-column>
        <b-table-column
            label="Acciones"
            v-slot="props">
          <b-icon
              pack="fas"
              icon="eye"
              size="is-medium"
              @click.native="goToUnit(props.row.id)"/>
          <b-icon
              pack="fas"
              icon="edit"
              size="is-medium"
              @click.native="editUnit(props.row.id)"/>
        </b-table-column>

      </b-table>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <!-- MODALES -->
    <b-modal
        v-model="isAddModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-unit-form query-type="create"
                           modal-header="Nueva Unidad"
                           @close="props.close"></modal-unit-form>
      </template>
    </b-modal>

    <b-modal
        v-model="isEditModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-unit-form :unit-id="selected"
                         query-type="update"
                         modal-header="Editar Unidad"
                         @close="props.close"></modal-unit-form>
      </template>
    </b-modal>

    <b-modal
        v-model="isShowModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal-unit-data :unit-id="selected"
                         query-type="show"
                         modal-header="Ver Unidad"
                         @close="props.close"></modal-unit-data>
      </template>
    </b-modal>
  </section>
</template>
<script>
import axios from "axios";
import ModalUnitForm from "./units/modal_unit_form.vue";
import ModalUnitData from "./units/modal_unit_data.vue";
export default {
  props:[

  ],
  components:{
    ModalUnitForm,
    ModalUnitData
  },
  data(){
    return{
      isLoading:false,
      currentPage:1,
      isAddModalActive: false,
      isEditModalActive: false,
      isShowModalActive: false,
      canCancel: ['escape'],
      unidades:[],
      selected: null

    }
  },
  created(){
    this.initialize();
  },
  methods:{
    initialize(){
      this.isLoading=true;
      return axios.get("/admin/units.json")
          .then(res => {
            this.unidades = res.data['unidades'];
            this.isLoading=false;
          })
          .catch(e => {
            this.isLoading=false;
            console.log(e);
          });
    },
    newUnit(){
      this.isAddModalActive = true
    },
    editUnit(id){
      this.isLoading=true
      this.selected = id;
      this.isEditModalActive = true;
      this.isLoading=false
    },
    goToUnit(id){
      this.selected = id;
      this.isShowModalActive = true;
    },
    goToUnitInfo(id){
      this.isLoading=true;
      window.location.href="/admin/units/"+id
    },

  },
  watch: {
    isAddModalActive: function () {
      if (!this.isAddModalActive) {
        this.initialize()
      }
    },
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
  },
  computed:{

  }
}
</script>

