<template>
  <div class="container">
    <div
      class="custom-box"
      :style="{
        border: '2rem',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '0.5rem',
        borderColor: '#e5e7eb',
        backgroundColor: '#fff',
        paddingBottom: '2rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      }"
    >
      <section>
        <div v-if="Object.keys(errores_validacion).length !== 0">
          <b-message type="is-danger" has-icon>
            <div v-for="(item, index) in errores_validacion" :key="index">
              <div v-if="item">{{ item }}</div>
            </div>
          </b-message>
        </div>

        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(searchPerson)">
            <div class="columns is-variable is-2">
              <div class="column mb-0 py-0">
                <div class="columns is-variable is-1">
                  <b-field label="Búsqueda por Rut" class="column mb-0 pb-0">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="integer|max:10"
                    >
                      <b-input
                        v-model.trim="searchRut"
                        type="search"
                        maxlength="10"
                        placeholder="Ej. 12345678"
                        @paste.native.prevent="handlePaste"
                      />
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                  <b-field label="DV" class="column mb-0 pb-2 is-3">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="valid_dv|max:1"
                    >
                      <b-input
                        v-model="searchDv"
                        type="search"
                        placeholder="Ej. 9"
                        maxlength="1"
                      />
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>
              <div class="column mb-0 py-0">
                <b-field label="Búsqueda por Nombre o Apellidos">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="{
                      regex: /^[a-zA-ZáéíóúÁÉÍÓÚÑñ\s]*$/,
                      max: 80,
                    }"
                  >
                    <b-input
                      v-model.trim="searchFullName"
                      type="search"
                      maxlength="80"
                      placeholder="Ej. Juan Pérez o Juan o Pérez"
                    >
                      ></b-input
                    >
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column is-one-quarter mb-0 py-0">
                <b-field label="." custom-class="has-text-white">
                  <div
                    :style="{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      justifyContent: 'flex-end',
                      alignItems: 'end',
                      gap: '0.5rem',
                    }"
                  >
                    <b-tooltip label="Restablecer campos de búsqueda">
                      <b-button @click="clearForm">
                        <b-icon icon="eraser" pack="fas" type="is-primary" />
                      </b-button>
                    </b-tooltip>
                    <button
                      type="submit"
                      class="button is-primary"
                      :style="{
                        flexGrow: 1,
                      }"
                    >
                      <b-icon icon="search" pack="fas" class="mr-1" />
                      Buscar
                    </button>
                  </div>
                </b-field>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdministrativePermitsSearchPerson",
  data() {
    return {
      errores_validacion: {},
      searchFullName: "",
      searchRut: "",
      searchDv: "",
    };
  },
  watch: {
    searchRut(val) {
      this.searchRut = val.replace(".", "").replace("-", "");
    },
  },
  methods: {
    clearForm() {
      this.searchFullName = "";
      this.searchRut = "";
      this.searchDv = "";
      this.$emit("clear");
    },

    async searchPerson() {
      if (
        this.searchFullName.length === 0 &&
        this.searchRut.length === 0 &&
        this.searchDv.length === 0
      ) {
        this.$buefy.dialog.alert({
          title: "Datos insuficientes",
          message:
            'Por favor, introduzca al menos un criterio de búsqueda: Rut, nombre completo o parte del nombre (por ejemplo, "Juan" o "Juan Pérez").',
          type: "is-warning",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
        return;
      }
      this.$emit("search", {
        rut: this.searchRut,
        dv: this.searchDv,
        fullName: this.searchFullName,
      });
    },
    async handlePaste(event) {
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData("Text");

      let [rut, dv] = pastedData.includes("-")
        ? pastedData.replace(/\./g, "").split("-")
        : [pastedData.replace(/\./g, ""), ""];

      const cleanedRut = rut.replace(/[^0-9]/g, "").trim();
      const cleanedDv = dv
        .replace(/[^0-9Kk]/g, "")
        .trim()
        .toUpperCase();

      this.searchRut = cleanedRut;
      if (dv) {
        this.searchDv = cleanedDv;
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 767px) {
  .custom-box {
    padding-top: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .custom-box {
    padding-top: 1.5rem;
  }
}
@media (min-width: 1024px) {
  .custom-box {
    padding-top: 2rem;
  }
}
</style>
