<template>
  <section>
      <div class="box">
        <div class="columns">
          <div class="column">
            <h3 class="is-size-3 has-text-weight-bold">Módulo MHV</h3>
          </div>
        </div>
        <div class="columns">
          <div class="column is-3">
            <b-field label="Fechas">
              <multiselect v-model="interval"
                           :options="intervals"
                           placeholder="Seleccione acción"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="No se puede deseleccionar"
                           @input="selectYears"
                           :allow-empty="false">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                No se encontraron elementos.
            </span>
              </multiselect>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label="Entidad">
              <multiselect v-model="entity"
                           :options="entities"
                           placeholder="Seleccione tipo de entidad"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="No se puede deseleccionar"
                           :allow-empty="false">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                No se encontraron elementos.
            </span>
              </multiselect>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label="Acción">
              <multiselect v-model="action"
                           :options="actions"
                           placeholder="Seleccione acción"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="No se puede deseleccionar"
                           :allow-empty="false">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                No se encontraron elementos.
            </span>
              </multiselect>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-narrow mt-2" v-if="show_years">
            <span>Seleccionar año: </span>
          </div>
          <div class="column is-3" v-if="show_years">
            <b-field>
              <multiselect v-model="year"
                           :options="years"
                           placeholder="Seleccione año"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="No se puede deseleccionar"
                           :allow-empty="false">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                No se encontraron elementos.
            </span>
              </multiselect>
            </b-field>
          </div>
        </div>
        <h3 class="is-size-6 has-text-weight-bold">Búsqueda por usuario
          <b-tooltip
              label="Ingrese al menos 3 caracteres para realizar una búsqueda."
              position="is-bottom"
              size="is-medium"
              multilined>

            <b-icon
                pack="fas"
                icon="info-circle"
                size="is-small"
                type="is-info">
            </b-icon>
          </b-tooltip>
        </h3>
        <div class="columns">
          <div class="column">
            <b-field>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <multiselect v-model="user_id"
                             :options="users.map(type => type.id)"
                             :custom-label="opt => users.find(x => x.id === opt).show_name"
                             placeholder="Seleccione Usuario"
                             selectLabel="Presione para seleccionar"
                             selectedLabel="Seleccionado"
                             deselectLabel="No se puede deseleccionar"
                             @search-change="searchUser"
                             :internal-search="false"
                             :loading="isLoadingMultiselect"
                             :disabled="isLoadingMultiselect"
                             :allow-empty="false">
                  <template v-slot:noOptions>
                    No existen datos
                  </template>
                  <template slot="option" slot-scope="props">
                    {{ users.find(x => x.id === props.option).show_name ? users.find(x => x.id === props.option).show_name : users.find(x => x.id === props.option).email}}
                  </template>
                  <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                </multiselect>

                <span class="validation-alert">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>
          </div>
          <div class="column">
            <div class="actions has-text-right">
              <button class="button is-primary" @click="updateAudit">Aplicar Filtro</button>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-centered">
            <b-message type="is-info" has-icon v-model="isActive">
              {{message}}
            </b-message>
          </div>
        </div>
        <b-table
            :data="audit"
            :paginated= true
            per-page="30"
            striped
            hoverable
            sticky-header
            height="1080"
            :narrowed=true
            :current-page="currentPage"
            :pagination-simple=false
            pagination-position="both"
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual">
          <b-table-column
              field="index"
              label="N°">
            <template v-slot="props">
              {{ audit.indexOf(props.row) + 1 }}
            </template>
          </b-table-column>
          <b-table-column
              field="created_at"
              label="Fecha"
              searchable
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.created_at}}
            </template>
          </b-table-column>
          <b-table-column
              field="user_mail"
              label="Usuario Autor"
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.user_mail}}
            </template>
          </b-table-column>
          <b-table-column
              field="event"
              label="Acción"
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.event}}
            </template>
          </b-table-column>
          <b-table-column
              field="item_type"
              label="Tipo de Objeto"
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.item_type}}
            </template>
          </b-table-column>
          <b-table-column
              field="item_id"
              label="Identificador de Objeto"
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.item_id}}
            </template>
          </b-table-column>
          <b-table-column
              label="Acciones"
              width="10"
              v-slot="props">
            <b-icon
                pack="fas"
                icon="eye"
                size="is-medium"
                @click.native="execComparisionModal(props.row.comparision)"/>
          </b-table-column>
          <template slot="empty" slot-scope="props">
            <div class="columns is-centered">
              <div class="column is-6 is-centered has-text-centered">
                <b-message type="is-danger">
                  No se encontraron resultados para filtros aplicados.
                </b-message>
              </div>
            </div>
          </template>
        </b-table>
        <b-modal
            v-model="isModalComparisionActive"
            has-modal-card
            trap-focus
            :destroy-on-hide="true"
            aria-role="dialog"
            aria-modal
            :can-cancel="canCancel">
          <template #default="props">
            <modal-comparision :person-id="personId"
                        modal-header="Comparación de Versiones"
                               :data="data_for_comparision"
                        @close="props.close"></modal-comparision>
          </template>
        </b-modal>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
      </div>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import ModalComparision from './modal_comparision'
import {setIntervals, createYears} from "../../packs/utilities";
export default {
  name: "life_record_audit",
  components:{
    ModalComparision
  },
  data(){
    return {
      selected: null,
      audit: [],
      isModalComparisionActive: false,
      currentPage: 1,
      isLoading: false,
      canCancel: ['escape'],
      no_filters: true,
      formattedDate: "",
      show: false,
      edit: false,
      complete_rut: "",
      complete_name: "",
      corporate_mail: "",
      add: false,
      data_for_comparision: null,
      personId: "",
      queryType: "",
      id: "",
      person: "",
      prop_person: "",
      action: "TODAS",
      actions: ["TODAS", "CREATE", "UPDATE", "DESTROY"],
      entity: "TODAS",
      entities: ["TODAS", "PERSON", "PERSON STUDY", "HOUSEHOLD ALLOWANCE", "PERSON UNIT", "STUDY", "COMMISSION", "EVENT", "FINANCING", "PERSON FUNCTION", "ADM PERMIT", "ADM PERMIT DOC"],
      interval: "DIA",
      intervals: ["DIA", "SEMANA", "MES", "AÑO", "TODO"],
      interval_end: "",
      interval_start: "",
      isActive: false,
      message: "",
      year: "ACTUAL",
      years: ["ACTUAL"],
      show_years: false,
      isLoadingMultiselect: false,
      user_id: null,
      users: []
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    setIntervals,
    createYears,
    searchUser(query){
      if(query.length<3){
        return
      }
      this.isLoadingMultiselect = true;
      this.user_id = null;
      axios
          .get("/admin/search-users.json",
              {
                params: {
                  query: query
                }
              })
          .then(res => {
            this.users = res.data
            this.isLoadingMultiselect=false;

          }).catch(error => {
        console.log(error)
        this.isLoadingMultiselect=false;
      })

    },
    selectYears(){
      if(this.interval==="AÑO"){
        this.show_years = true
      }
      else{
        this.show_years = false
        this.year = "ACTUAL"
      }
    },
    execComparisionModal(comparision_array){
      this.isLoading = true
      this.isModalComparisionActive = true
      this.data_for_comparision = comparision_array
      this.isLoading = false
    },
    initialize(){
      this.isLoading = true
      this.years = createYears()
      this.isLoading = false
    },
    updateAudit(){
      this.isLoading = true
      let user = null
      if(this.user_id){
        user = this.users.find(user => user.id === this.user_id)
      }
      this.message = setIntervals(this.interval, this.year, user)
      return axios
          .get("/admin/list_mhv_audit.json", { params: { audit_action: this.action, audit_entity: this.entity, interval: this.interval, year: this.year, user: this.user_id} })
          .then(res => {
            this.audit = res.data["mhv_audit"].concat(res.data["financings_audit"])
            this.audit = this.audit.concat(res.data["people_audit"])
            this.audit = this.audit.map(audit => {
              var newAudit = audit
              newAudit["created_at"] = moment(newAudit["created_at"], "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY HH:mm:ss");
              return newAudit;
            });
            this.audit = this.audit.sort((a,b) => moment(b["created_at"], "DD-MM-YYYY HH:mm:ss").diff(moment(a["created_at"], "DD-MM-YYYY HH:mm:ss")))
            this.isActive = true;
            this.isLoading = false
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
          })
    },
    showModal(row){
      this.isLoading = true
      this.id = row.id
      this.complete_rut = row.run.rut.toString() + "-"+row.run.dv
      this.corporate_mail = row.person.corporate_mail
      this.complete_name = row.complete_name
      this.prop_person = row.person
      this.show = true
      this.isLoading = false
    },
    editModal(row){
      this.isLoading = true
      this.id = row.id
      this.personId = row.person.id
      this.queryType = "update"
      this.person = row.person
      this.edit = true
      this.isLoading = false
    },
    addModal(){
      this.isLoading = true
      this.add = true
      this.queryType = "create"
      this.isLoading = false
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    }
  },
  watch: {
    add: function () {
      if (!this.add) {
        this.initialize()
      }
    },
    edit: function () {
      if (!this.edit) {
        this.initialize()
      }
    },
  }
}
</script>
