<template>
    <div class="partnerentitiesvimeagreements">
      <div class="container">
        <div v-if="can_create_agreement" class="columns">
          <div class="column">
                        <span class="is-pulled-right">
                            <button class="button is-primary" @click="addNewAgreement()">
                                <b-icon icon="plus"></b-icon>
                                <span>Añadir Convenio</span>
                            </button>
                        </span>
          </div>
        </div>
        <div class="columns">
          <div class="column has-text-right">
            <b-field>
              <b-switch v-model="cardLayout">
              <span>
                Formato tarjeta (sin filtros ni búsqueda)
                 <b-tooltip
                     label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                     multilined
                     position="is-left">
                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          type="is-info">
                      </b-icon>
                  </b-tooltip>
              </span>
              </b-switch>
            </b-field>
          </div>
        </div>
      </div>

        <div>
            <b-table
                :data="agreements"
                :paginated=true
                per-page=10
                striped
                hoverable
                :current-page="currentPage"
                :card-layout="cardLayout"
                :pagination-simple=false
                pagination-position="bottom"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="publication.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual"
                header-class="is-primary">
                <b-table-column
                    field="name"
                    label= 'Nombre'
                    searchable
                    sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                  <template v-slot="props">
                    <a @click="goToAgreement(props.row.id)"><i class="fas fa-user-circle"></i> {{ props.row.name }}</a>
                  </template>
                </b-table-column>
                <b-table-column
                    field="agreement_stage"
                    label= 'Etapa'
                    searchable
                    sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{props.row.agreement_stage}}
                    </template>
                </b-table-column>
                <b-table-column
                    field="agreement_state"
                    label= 'Estado'
                    searchable
                    sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{props.row.agreement_state}}
                    </template>
                </b-table-column>
              <template slot="empty" slot-scope="props">
                <div class="columns is-centered">
                  <div class="column is-6 is-centered has-text-centered">
                    <b-message v-if="can_create_agreement" has-icon type="is-warning">
                      <h1> No se encontraron resultados, haz click <a @click="addNewAgreement()">aquí</a> para añadir un nuevo convenio.</h1>
                    </b-message>
                    <b-message v-else has-icon type="is-warning">
                      <h1> No se encontraron resultados.</h1>
                    </b-message>
                  </div>
                </div>
              </template>
            </b-table>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PartnerEntitiesVimeAgreements',
    props: {
        agreements:{
            type: Array,
            default: () => []
        },
      can_create_agreement:{
          type: Boolean
      },
      entity_id:{
          type: Number
      }
    },
    data(){
        return {
            currentPage: 1,
            cardLayout: false
        }
    },
    methods:{
        isAgreementsVoid(){
            return Object.entries(this.agreements).length == 0
        },
        goToAgreement(agreement_id){
            window.location.href="/agreements/"+agreement_id
        },
        addNewAgreement(){
            window.location.href="/agreements/new/"+this.entity_id
        }
    }
}
</script>