<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(savePerson)">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" ref="modalPersonForm">

            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion">
                      <div v-if="item">{{ item[0] }}</div>
                    </div>
                  </b-message>
                </div>
              </div>
            </div>

            <div v-if="requiredFieldOnly" class="is-centered" style="margin-top: 30px;">
              <div class="columns is-centered">
                <div class="box has-text-centered">
                  <template>
                    <b-button
                        outlined
                        type="is-primary"
                        icon-pack="fas"
                        icon-left="chevron-left"
                        :disabled="activeStep==0"
                        @click.prevent="back">
                    </b-button>
                    <b-button
                        outlined
                        type="is-primary"
                        icon-pack="fas"
                        icon-right="chevron-right"
                        :disabled="activeStep==2"
                        @click.prevent="next">
                    </b-button>
                  </template>
                </div>
              </div>
            </div>

            <b-steps :has-navigation="false" v-model="activeStep">
              <b-step-item label="Datos de Identificación" step="1" :clickable="true">
                <div class="columns">
                  <div class="column is-4 is-offset-8">
                    <b-switch v-model="requiredFieldOnly">Mostrar todos los campos del formulario</b-switch>
                  </div>
                </div>
                <div class="container" style="margin-top: 40px">
                  <div class="columns">
                    <div class="column">
                      <b-field label="Nombres*">
                        <ValidationProvider rules="required|alpha_spaces" v-slot="{ errors }" name="nombre">
                          <b-input placeholder="Ej: Ana María" v-model="person.name" type="text"></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Primer Apellido*">
                        <ValidationProvider rules="required|alpha_spaces" v-slot="{ errors }" name="paterno">
                          <b-input placeholder="Ej: Gonzalez" v-model="person.last_name" type="text"></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Segundo Apellido">
                       <ValidationProvider rules="alpha_spaces" v-slot="{ errors }" name="materno">
                          <b-input placeholder="EJ: Henriquez" v-model="person.second_surname" type="text"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-3">
                      <b-field label="Rut"
                               :type="{'is-danger': (checkUnique || checkDV)}"
                               :message="[{'Ingrese al menos uno de estos campos': checkUnique },
                                 {'Ingrese Rut': checkDV }]">
                        <ValidationProvider rules="integer|positive" v-slot="{ errors }" name="rut">
                          <b-input placeholder="Ej: 1234567" v-model="person.run_attributes.rut"></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div class="column is-1">
                      <b-field label="DV"
                               :type="{'is-danger': checkRut}"
                               :message="{'DV requerido': checkRut}">
                        <b-input placeholder="K" v-model="person.run_attributes.dv" maxlength="1" :has-counter=false></b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Correo personal"
                               :type="{'is-danger': checkUnique}"
                               :message="{ 'Ingrese al menos uno de estos campos': checkUnique }">
                        <ValidationProvider rules="email" v-slot="{ errors }" name="personal_mail">
                          <b-input placeholder="Ej: email@email.com" v-model="person.personal_mail"></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Pasaporte"
                               :type="{'is-danger': checkUnique}"
                               :message="{ 'Ingrese al menos uno de estos campos': checkUnique }">
                        <b-input placeholder="Ej: AB00001" v-model="person.run_attributes.passport" maxlength="20" :has-counter=false></b-input>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
<!--
                    <div class="column">
                      <b-field label="Tipo de entidad*">
                        <ValidationProvider rules="required" v-slot="{ errors }" name="type_entity">
                          <multiselect v-model="entity_type_id"
                                       :options="entities_type.map(type => type.id)"
                                       :custom-label="opt => entities_type.find(x => x.id == opt).name"
                                       placeholder="Seleccione tipo de entidad"
                                       selectLabel="Presione para seleccionar"
                                       selectedLabel="Seleccionado"
                                       deselectLabel="No se puede deseleccionar"
                                       @select="clearEntity"
                                       :allow-empty="false">
                            <template v-slot:noOptions>
                              No existen datos.
                            </template>
                            <span slot="noResult">
                              No se encontraron elementos.
                            </span>
                          </multiselect>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>-->
                    <div class="column">
                      <b-field label="Seleccione entidad">
                          <b-autocomplete v-model="person.university_name"
                                          :keep-first="keepFirst"
                                          :open-on-focus="openOnFocus"
                                          :data="partner_entities"
                                          field="name"
                                          empty="Vacio"
                                          placeholder="Ej: ANID"
                                          @typing="searchPartnerEntities"
                                          @select="option => {if (option) {person.university_id = option.id} }"
                                          :clearable="clearable">
                            <template slot-scope="props">
                              {{ props.option.show_name ? props.option.show_name : props.option.name}}
                            </template>
                          </b-autocomplete>
                      </b-field>
                    </div>
                    <div class="column is-narrow">
                      <br>
                      <b-field label=" ">
                        <b-tooltip label="Si la entidad que desea agregar no existe, la puede agregar presionando el botón +"
                                   position="is-bottom">
                          <b-button class="is-primary is-outlined" @click="addEntity()">
                            <b-icon icon="plus"></b-icon>
                          </b-button>
                        </b-tooltip>
                      </b-field>
                    </div>

                    <div class="column">
                        <b-field label="Cargo en la entidad">
                          <b-input placeholder="Ej: Jefe" v-model="position" maxlength="255" :has-counter="false" :disabled="person.university_name == null  || person.university_name.length == 0"></b-input>
                        </b-field>
                    </div>
                  </div>
                </div>
              </b-step-item>
              <b-step-item v-if="requiredFieldOnly" label="Datos Personales" step="2" :clickable="true">
                <div class="container" style="margin-top: 40px">
                  <div class="columns">
                    <div class="column">
                      <b-field label="Fecha de nacimiento">
                        <b-datepicker
                            v-model="person.birthday_date"
                            icon="calendar-alt"
                            trap-focus
                            locale="es-ES"
                            editable
                            placeholder="Formato de Fecha: dd/mm/aaaa"
                        >
                        </b-datepicker>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Género">
                        <multiselect v-model="person.gender_id"
                                     :options="genders.map(type => type.id)"
                                     :custom-label="opt => genders.find(x => x.id === opt).name"
                                     placeholder="Seleccione género"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel="Seleccionado"
                                     deselectLabel="Presione para deseleccionar"
                                     :allow-empty="true">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                        </multiselect>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="Región">
                        <multiselect v-model="person.region_id"
                                     :options="regions.map(type => type.id)"
                                     :custom-label="opt => regions.find(x => x.id === opt).name"
                                     @input="loadCommunes(person.region_id)"
                                     placeholder="Seleccione Región"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel="Seleccionado"
                                     deselectLabel="Presione para deseleccionar"
                                     :allow-empty="true">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                        </multiselect>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Comuna">
                        <multiselect v-model="person.commune_id"
                                     :options="communes.map(type => type.id)"
                                     :custom-label="opt => communes.find(x => x.id === opt).name"
                                     placeholder="Seleccione Comuna"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel="Seleccionado"
                                     @input="fixCommunes(person.commune_id)"
                                     deselectLabel="Presione para deseleccionar"
                                     :allow-empty="true">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                        </multiselect>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Dirección">
                        <b-input placeholder="Ej: Calle 123" v-model="person.address" maxlength="255" :has-counter=false></b-input>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="Nacionalidad">
                        <multiselect v-model="person.nationality_id"
                                     :options="countries.map(type => type.id)"
                                     :custom-label="opt => countries.find(x => x.id === opt).nationality"
                                     placeholder="Seleccione nacionalidad"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel="Seleccionado"
                                     deselectLabel="Presione para deseleccionar"
                                     :allow-empty="true">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                        </multiselect>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="País de origen">
                        <multiselect v-model="person.origin_country_id"
                                     :options="countries.map(type => type.id)"
                                     :custom-label="opt => countries.find(x => x.id === opt).name"
                                     placeholder="Seleccione nacionalidad"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel="Seleccionado"
                                     deselectLabel="Presione para deseleccionar"
                                     :allow-empty="true">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                        </multiselect>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Lugar de nacimiento">
                        <b-input placeholder="Ej: Nueva York, EEUU" v-model="person.birthplace"></b-input>
                      </b-field>
                    </div>
                  </div>
                </div>
              </b-step-item>
              <b-step-item v-if="requiredFieldOnly" label="Datos de Contacto" step="3" :clickable="true">
                <div class="container" style="margin-top: 40px">
                  <div class="columns">
                    <div class="column">
                      <b-field label="Celular">
                        <ValidationProvider rules="integer|positive" v-slot="{errors}" style="width: 100%" name="celular">

                          <b-input placeholder="Ej: 912345678" v-model="cellphone" expanded></b-input>

                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Teléfono">
                        <ValidationProvider rules="integer|positive" v-slot="{errors}" name="telefono">
                          <b-input placeholder="Ej: 221345678" v-model="person.phone"></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>
                </div>
              </b-step-item>
            </b-steps>
            <!-- GUARDAR PERSONA-->
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="actions has-text-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit" @click="checkSavePerson()">Guardar Persona</button>
          </div>
        </footer>
        <b-modal
            v-model="isAddModalEntityActive"
            :destroy-on-hide="true"
            width="50%"
            :can-cancel="canCancel">
          <template #default="props">
            <modal_partner_entity
                query-type="update"
                modal-header="Añadir nueva entidad"
                @close="props.close"
                @clicked="onClickChild"></modal_partner_entity>
          </template>
        </b-modal>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios"
import moment from "moment"
import modal_partner_entity from "../moduloVIME/agreements/new/modal_partner_entity"
export default {
  name: "modal_person_edit_form",
  data(){
    const data =[]
    return {
      keepFirst: false,
      openOnFocus: false,
      clearable: false,
      selected: null,
      canCancel: false,
      person:{
        run_attributes:{
          rut: null,
          dv: null,
          passport: null,
          run_completo: null
        },
        region_id: null,
        is_registered: false,
        skip_if_external_people: true,
        university_name: null,
        university_id:null
      },
      partner_entities:[],
      regions: [],
      genders: [],
      communes: [],
      countries: [],
      marital_statuses: [
        {name: "Soltero(a)" },
        {name: "Casado(a)" },
        {name: "Viudo(a)" },
        {name: "Divorciado(a)" },
        {name: "Conviviente civil" }
      ],
      checkUnique: false,
      checkRut: false,
      checkDV: false,
      checkUniversity: false,
      errores_validacion: {},
      isLoading:false,
      activeStep: 0,
      cellphone: null,
      university: [],
      universidades_filtradas:[],
      entity_type_id: null,
      entities_type: [],
      isAddModalEntityActive: false,
      position: "",
      requiredFieldOnly: false,
    }
  },

  props:[
    'userId', 'queryType', 'personId', 'modalHeader'
  ],
  components:{
    modal_partner_entity,
  },
  created(){
    this.isLoading=true;
    if (this.queryType ==="create"){
      axios
          .get("/people/new.json")
          .then(res => {
            this.regions = res.data["regions"]
            this.genders = res.data["genders"]
            this.countries = res.data["countries"]
            this.getEntityType()
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }
    else if (this.queryType === "update"){

      this.isLoading=true;
      axios
          .get("/people/"+this.personId+"/edit.json")
          .then(res => {
            this.person = res.data
            if (res.data["birthday_date"] !== null) {
              this.person.birthday_date = moment(res.data["birthday_date"], 'YYYY-MM-DD')._d;
            }
            var number = this.person.cellphone
            if(number !==null){
              var strNumber = number.toString()
              if(strNumber.includes(569) && strNumber.slice(0,3) =="569"){
                this.cellphone = parseInt(strNumber.slice(3))
              }
            }
            this.regions = res.data["regions"]
            this.genders = res.data["genders"]
            this.countries = res.data["countries"]
            if (res.data["communes"] != null) {
              this.communes = res.data["communes"]
            }
            this.isLoading=false;
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }
  },
  methods: {
    filteredDataUniversities(text){
      this.universidades_filtradas = this.university.filter((option) => {
        return option.name
            .toString()
            .toUpperCase()
            .indexOf(text.toUpperCase()) >= 0
      }).slice(0,10)
    },
    next(){
      if(this.activeStep < 4){
        this.activeStep = this.activeStep +1
      }

    },
    back(){
      if(this.activeStep > 0){
        this.activeStep = this.activeStep -1
      }
    },
    checkSavePerson(){
      if (this.person.run_attributes.passport == ""){
        this.person.run_attributes.passport = null
      }
      if(this.person.run_attributes.rut == ""){
        this.person.run_attributes.rut = null
      }
      if(this.person.personal_mail == ""){
        this.person.personal_mail = null
      }

      if (this.person.run_attributes.rut == null && this.person.run_attributes.passport == null && this.person.personal_mail == null){
        this.checkUnique = true
      }
      else if (this.person.run_attributes.rut != null && this.person.run_attributes.dv == null){
        this.checkRut = true
        this.checkDV = false
      }
      else if (this.person.run_attributes.rut == null && this.person.run_attributes.dv != null){
        this.checkDV = true
        this.checkRut = false
      }
      /*else if(this.person.university_name == '' || this.person.university_name == null){
        this.checkUniversity = true
      }*/
      else{
        this.checkRut = false
        this.checkDV = false
        this.checkUnique = false
        this.checkUniversity = false
        //this.savePerson()
      }
    },
    async savePerson(){
      this.checkSavePerson()
      if (this.checkRut == false && this.checkDV == false && this.checkUnique == false) {
        const isValid = await this.$refs.observer.validate();
        if (this.activeStep === 0) {
          if (this.$refs.observer.fields.nombre.valid === false || this.$refs.observer.fields.paterno.valid === false
              || this.$refs.observer.fields.materno.valid === false) {
            return this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Se encontraron errores en el paso 1 del formulario. Revisar formato y campos sin completar.',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          }
        } else if (this.activeStep === 2) {
          if (this.$refs.observer.fields.celular.valid === false || this.$refs.observer.fields.telefono.valid === false
          ) {
            return this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Se encontraron errores en el paso 3 del formulario. Revisar formato y campos sin completar.',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          }
        }
        this.isLoading = true;
        var number = this.cellphone
        if (number === null) {
          this.person.cellphone = null
        } else {
          var strNumber = number.toString()
          this.person.cellphone = parseInt(strNumber)
        }
        if (this.queryType === "create") {
          var full_name = this.person.name + " " + this.person.last_name + " " + this.person.second_surname
          //this.person.skip_if_import  = true
          this.person.skip_if_step_three = true
          this.person.if_external_people = true
          if(this.position.length == 0){
            this.person.position =  null
          }
          else{
            this.person.position = this.position
          }

          axios
              .post("/people/external_people/create.json", {
                person: this.person,
                position: this.person.position
              })
              .then(response => {
                this.errores_validacion = {};
                this.$emit('clicked', full_name)
                this.$emit('close')
                this.isLoading = false;
              }).catch(error => {
            this.errores_validacion = error.response.data
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            this.$refs.modalPersonForm.scrollTop = 0;
            this.isLoading = false;
          })
        } else if (this.queryType === "update") {
          var ruta = ''

          if (this.person.run_attributes == null) {
            ruta = "/people/" + this.personId + "/external_people/update.json"
          } else if (this.person.run_attributes.rut == null || this.person.run_attributes.dv == null) {
            ruta = "/people/" + this.personId + "/external_people/update.json"
          } else if (this.person.run_attributes.rut == '' || this.person.run_attributes.dv == '') {
            ruta = "/people/" + this.personId + "/external_people/update.json"
          } else {
            ruta = "/people/" + this.personId + "/external_people_run/update.json"
          }

          let clean_person = this.person
          clean_person = this.deleteData(clean_person)
          clean_person.is_registered = false
          axios
              .put(ruta, {
                person: clean_person,
                position: this.person.position
              })
              .then(response => {
                this.errores_validacion = {};
                this.$emit('close')
                this.isLoading = false;
              }).catch(error => {
            this.errores_validacion = error.response.data
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            this.$refs.modalPersonForm.scrollTop = 0;
            this.isLoading = false;
          })
        }
      }
    },
    loadCommunes(region_id){
      if( region_id!==null && region_id!==undefined && region_id!==""){
        this.isLoading=true;
        axios
            .get("/regions/"+region_id+"/communes.json")
            .then(res => {
              this.person.commune_id = null;
              this.communes = res.data["communes"];
              this.isLoading = false;
            })
            .catch(error => {
              console.log(error)
              this.isLoading=false;
            })
      }
      else{
        this.person.commune_id = null
        this.communes = []
      }

    },
    //Esta funcion solo es para mostrar las comunas selecionadas
    fixCommunes(commune){
      this.isLoading = true
      this.isLoading = false
    },
    deleteData(person){
      delete person.communes
      delete person.countries
      delete person.genders
      delete person.regions
      delete person.id
      return person
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
    getUniversity(){
      this.isLoading = true
      axios.get('/admin/partner_entities/list_institution/1.json')
          .then(response =>{
            this.university = response.data.partner_entities
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
          })
    },
    getEntityByType(value){
      this.isLoading = true
      axios.get('/admin/partner_entities/list_institution/'+this.entity_type_id+'.json')
          .then(response =>{
            this.university = response.data.partner_entities
            this.universidades_filtradas = []
            this.person.university_name = null
            this.person.university_id = null
            if (value != null){
              this.person.university_name = value.name
              this.person.university_id = value.id
            }
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
          })
    },
    getEntityType(){
      this.isLoading = true
      axios.get('/admin/entity_types.json')
          .then(response =>{
            this.entities_type = response.data
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
          })
    },
    addEntity(){
      this.isAddModalEntityActive = true;
    },
    onClickChild(value){
      this.isLoading = true
      this.entity_type_id = value.entity_type_id
      this.searchPartnerEntities(value.name)
      this.person.university_id = value.id
      this.person.university_name = value.name
    },
    searchPartnerEntities(name){
      if(name == null   || name.length == 0){
        this.position = null
      }
      if (name.length<3) {
        this.partner_entities = []
        return
      }
      let vm = this;
      vm.isLoading=true;
      axios
          .get("/admin/partner_entities/agreement-search.json", { params: {
            name: name
          } })
          .then(res => {
            vm.partner_entities = res.data;
            vm.isLoading = false;
          })
          .catch(error => {
            console.log(error)
            vm.isLoading=false;
          })
    },
    clearEntity(){
      this.person.university_id = null
      this.person.university_name = null
    },

  }
}
</script>

<style scoped>

</style>