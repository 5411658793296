<template>
    <div class="configurationagreementsinfo">
        <br>
        <br>
        <!-- Sección tipología de convenios generales -->
        <div class="columns">
            <div class="column">
                <h1 class="subtitle is-2">
                    Tipos de convenios generales
                </h1>
            </div>
            <div class="column">
                <span class="is-pulled-right">
                    <button class="button is-primary" @click="editAgreement(0)">
                        <b-icon icon="edit"></b-icon>
                        <span>Editar los tipos de convenios generales</span>
                    </button>
                    <a class="button is-primary" href="/agreement_general_types.xlsx">
                        <b-icon icon="file-excel"></b-icon>
                        <span>Descargar en excel</span>
                    </a>
                </span>
            </div>
        </div>
        <div v-if="isAgreementGeneralTypesEmpty">
            <div class="columns is-centered">
                <br>
                <b-message title='Tipos de convenios generales no encontrados' has-icon type="is-warning" size="is-medium" :closable='false'>
                    No hay registros sobre los tipos de convenios generales, agregue nuevos tipos de convenios generales haciendo click <a @click='editAgreement(0)'>aquí</a>.               
                </b-message>
                <br>
            </div>
        </div>
        <div v-else>
            <b-table
                :data="getAgreementGeneralTypes"
                :paginated=true
                per-page=5
                striped
                hoverable
                :current-page="currentPage"
                :pagination-simple=false
                pagination-position="bottom"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="publication.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
                <b-table-column
                    field= 'name'
                    label= 'Nombre'
                    v-slot="props"
                >
                {{ props.row.name }}
                </b-table-column>
                <b-table-column
                    field= 'description'
                    label= 'Descripción'
                    v-slot="props"
                >
                {{ props.row.description }}
                </b-table-column>
                <b-table-column
                    field= 'objective'
                    label= 'Objetivo'
                    v-slot="props"
                >
                {{ props.row.objective }}
                </b-table-column>
                <b-table-column
                    field= 'signatory'
                    label= 'Aprueba'
                    v-slot="props"
                >
                {{ props.row.signatory }}
                </b-table-column>
                <b-table-column
                    field= 'aditional_information'
                    label= 'Información adicional'
                    v-slot="props"
                >
                {{ props.row.aditional_information }}
                </b-table-column>
                <b-table-column
                    field= 'is_active'
                    label= '¿Activo?'
                    v-slot="props"
                >
                {{ isActive(props.row.is_active) }}
                </b-table-column>
            </b-table>
        </div>
        <br>
        <br>
        <br>
        <!-- Sección tipología de convenios específicos -->
        <div class="columns">
            <div class="column">
                <h1 class="subtitle is-2">
                    Tipos de convenios específicos
                </h1>
            </div>
            <div class="column">
                <span class="is-pulled-right">
                    <button class="button is-primary" @click="editAgreement(1)">
                        <b-icon icon="edit"></b-icon>
                        <span>Editar los tipos de convenios específicos</span>
                    </button>
                    <a class="button is-primary" href="/agreement_specifict_types.xlsx">
                        <b-icon icon="file-excel"></b-icon>
                        <span>Descargar en excel</span>
                    </a>
                </span>
            </div>
        </div>
        <div v-if="isAgreementSpecifictTypesEmpty">
            <div class="columns is-centered">
                <br>
                <b-message title='Tipos de convenios específicos no encontrados' has-icon type="is-warning" size="is-medium" :closable='false'>
                    No hay registros sobre los tipos de convenios específicos, agregue nuevos tipos de convenios específicos haciendo click <a @click='editAgreement(1)'>aquí</a>.               
                </b-message>
                <br>
                <h1>
                    No hay registros sobre los tipos de convenios específicos, agregue nuevos tipos de convenios específicos haciendo click <a @click='editAgreement(1)'>aquí</a>.
                </h1>
            </div>
        </div>
        <div v-else>
            <b-table
                :data="getAgreementSpecifictTypes"
                :paginated=true
                per-page=5
                striped
                hoverable
                :current-page="currentPage"
                :pagination-simple=false
                pagination-position="bottom"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="publication.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
                <b-table-column
                    field= 'name'
                    label= 'Nombre'
                    v-slot="props"
                >
                {{ props.row.name }}
                </b-table-column>
                <b-table-column
                    field= 'description'
                    label= 'Descripción'
                    v-slot="props"
                >
                {{ props.row.description }}
                </b-table-column>
                <b-table-column
                    field= 'is_active'
                    label= '¿Activo?'
                    v-slot="props"
                >
                {{ isActive(props.row.is_active) }}
                </b-table-column>
            </b-table>
        </div>
        <br>
        <br>
        <br>
        <!-- Sección etapas de un convenio -->
        <div class="columns">
            <div class="column">
                <h1 class="subtitle is-2">
                    Etapas de un convenio
                </h1>
            </div>
            <div class="column">
                <span class="is-pulled-right">
                    <button class="button is-primary" @click="editAgreement(2)">
                        <b-icon icon="edit"></b-icon>
                        <span>Editar las etapas de un convenio</span>
                    </button>
                    <a class="button is-primary" href="/agreement_stages.xlsx">
                        <b-icon icon="file-excel"></b-icon>
                        <span>Descargar en excel</span>
                    </a>
                </span>
            </div>
        </div>
        <div v-if="isAgreementStagesEmpty">
            <div class="columns is-centered">
                <br>
                <b-message title='Etapas no encontradas' has-icon type="is-warning" size="is-medium" :closable='false'>
                    No hay registros sobre las etapas de un convenio, agregue nuevas etapas haciendo click <a @click='editAgreement(2)'>aquí</a>.               
                </b-message>
                <br>
            </div>
        </div>
        <div v-else>
            <b-table
                :data="getAgreementStages"
                :paginated=true
                per-page=5
                striped
                hoverable
                :current-page="currentPage"
                :pagination-simple=false
                pagination-position="bottom"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="publication.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
                <b-table-column
                    field= 'name'
                    label= 'Nombre'
                    v-slot="props"
                >
                {{ props.row.name }}
                </b-table-column>
                <b-table-column
                    field= 'description'
                    label= 'Descripción'
                    v-slot="props"
                >
                {{ props.row.description }}
                </b-table-column>
                <b-table-column
                    field= 'is_active'
                    label= '¿Activa?'
                    v-slot="props"
                >
                {{ isActive(props.row.is_active) }}
                </b-table-column>
            </b-table>
        </div>
        <br>
        <br>
        <br>
        <!-- Sección procesos de un convenio -->
        <div class="columns">
            <div class="column">
                <h1 class="subtitle is-2">
                    Procesos de un convenio
                </h1>
            </div>
            <div class="column">
                <span class="is-pulled-right">
                    <button class="button is-primary" @click="editAgreement(3)">
                        <b-icon icon="edit"></b-icon>
                        <span>Editar los procesos de un convenio</span>
                    </button>
                    <a class="button is-primary" href="/agreement_processes.xlsx">
                        <b-icon icon="file-excel"></b-icon>
                        <span>Descargar en excel</span>
                    </a>
                </span>
            </div>
        </div>
        <div v-if="isAgreementProcessesEmpty">
            <div class="columns is-centered">
                <br>
                <b-message title='Procesos no encontrados' has-icon type="is-warning" size="is-medium" :closable='false'>
                    No hay registros sobre los procesos de un convenio, agregue nuevos procesos haciendo click <a @click='editAgreement(3)'>aquí</a>.               
                </b-message>
                <br>
            </div>
        </div>
        <div v-else>
            <b-table
                :data="getAgreementProcesses"
                :paginated=true
                per-page=5
                striped
                hoverable
                :current-page="currentPage"
                :pagination-simple=false
                pagination-position="bottom"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="publication.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
                <b-table-column
                    field= 'name'
                    label= 'Nombre'
                    v-slot="props"
                >
                {{ props.row.name }}
                </b-table-column>
                <b-table-column
                    field= 'description'
                    label= 'Descripción'
                    v-slot="props"
                >
                {{ props.row.description }}
                </b-table-column>
                <b-table-column
                    field= 'associated_documents'
                    label= 'Documentos asociados'
                    v-slot="props"
                >
                {{ props.row.associated_documents }}
                </b-table-column>
                <b-table-column
                    field= 'agreement_stage'
                    label= 'Etapa asociada'
                    v-slot="props"
                >
                {{ props.row.agreement_stage }}
                </b-table-column>
                <b-table-column
                    field= 'is_active'
                    label= '¿Activo?'
                    v-slot="props"
                >
                {{ isActive(props.row.is_active) }}
                </b-table-column>
            </b-table>
        </div>
        <br>
        <br>
        <br>
        <!-- Sección actores de un convenio -->
        <div class="columns">
            <div class="column">
                <h1 class="subtitle is-2">
                    Actores de un convenio
                </h1>
            </div>
            <div class="column">
                <span class="is-pulled-right">
                    <button class="button is-primary" @click="editAgreement(4)">
                        <b-icon icon="edit"></b-icon>
                        <span>Editar los actores de un convenio</span>
                    </button>
                    <a class="button is-primary" href="/agreement_actors.xlsx">
                        <b-icon icon="file-excel"></b-icon>
                        <span>Descargar en excel</span>
                    </a>
                </span>
            </div>
        </div>
        <div v-if="isAgreementActorsEmpty">
            <div class="columns is-centered">
                <br>
                <b-message title='Actores no encontrados' has-icon type="is-warning" size="is-medium" :closable='false'>
                    No hay registros sobre los actores de un convenio, agregue nuevos procesos haciendo click <a @click='editAgreement(4)'>aquí</a>.               
                </b-message>
                <br>
            </div>
        </div>
        <div v-else>
            <b-table
                :data="getAgreementActors"
                :paginated=true
                per-page=5
                striped
                hoverable
                :current-page="currentPage"
                :pagination-simple=false
                pagination-position="bottom"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="publication.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
                <b-table-column
                    field= 'name'
                    label= 'Nombre'
                    v-slot="props"
                >
                {{ props.row.name }}
                </b-table-column>
                <b-table-column
                    field= 'actor_type'
                    label= 'Tipo de actor'
                    v-slot="props"
                >
                {{ props.row.actor_type }}
                </b-table-column>
                <b-table-column
                    field= 'is_active'
                    label= '¿Activo?'
                    v-slot="props"
                >
                {{ isActive(props.row.is_active) }}
                </b-table-column>
            </b-table>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ConfigurationAgreementsInfo',
    props:{
        agreement_general_types:{
            type: Array,
            default:() => []
        },
        agreement_specifict_types:{
            type: Array,
            default:() => []
        },
        agreement_stages:{
            type: Array,
            default:() => []
        },
        agreement_processes:{
            type: Array,
            default:() => []
        },
        agreement_actors:{
            type: Array,
            default:() => []
        }
    },
    data(){
        return {
            currentPage:1
        }
    },
    computed:{
        // Validadores
        isAgreementGeneralTypesEmpty(){
            return Object.entries(this.agreement_general_types).length === 0
        },
        isAgreementSpecifictTypesEmpty(){
            return Object.entries(this.agreement_specifict_types).length === 0
        },
        isAgreementStagesEmpty(){
            return Object.entries(this.agreement_stages).length === 0
        },
        isAgreementProcessesEmpty(){
            return Object.entries(this.agreement_processes).length === 0
        },
        isAgreementActorsEmpty(){
            return Object.entries(this.agreement_actors).length === 0
        },
        // get
        getAgreementGeneralTypes(){
            return this.agreement_general_types
        },
        getAgreementSpecifictTypes(){
            return this.agreement_specifict_types
        },
        getAgreementStages(){
            return this.agreement_stages
        },
        getAgreementProcesses(){
            return this.agreement_processes
        },
        getAgreementActors(){
            return this.agreement_actors
        }
    },
    methods:{
        isActive(data){
            if(data){
                return "Sí"
            }
            else{
                return "No"
            }
        },
        editAgreement(agreement_section){
            window.location.href="/administrate/vime_configuration_activity_assistant_types_edit/"+agreement_section
        }
    }
}
</script>