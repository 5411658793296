<template>
  <section>
      <div class="modal-card"  id="modal-event">
        <header class="modal-card-head">
          <p class="modal-card-title">Programa de financiamiento</p>
          <button
              type="button"
              class="delete"
              @click="$emit('close')"/>
        </header>
        <section class="modal-card-body" id="modal-events">
          <div class="columns">
            <div class="column">
              <b-field label="Nombre">
                  <b-input type="text"
                           readonly
                           v-model="name"
                           >
                  </b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Descripción">
                <b-input type="textarea"
                         readonly
                         v-model="description">
                </b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Url">
                <b-input type="text"
                         readonly
                         v-model="program_url">
                </b-input>
              </b-field>
            </div>
          </div>
        </section>
      </div>
      <footer class="modal-card-foot actions">
        <div class="columns">
          <div class="column">
            <div class="field is-grouped is-grouped-left">
              <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            </div>
          </div>
          <div v-if="can_edit_program" class="column">
            <div class="field is-grouped is-grouped-right">
              <button class="button is-primary" type="submit" @click="editProgram">Editar Programa</button>
            </div>
          </div>
        </div>
      </footer>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal v-model="isAddModalActive"
             has-modal-card
             trap-focus
             :destroy-on-hide="true"
             aria-role="dialog"
             aria-modal
             :can-cancel="canCancel">
      <template #default="props">
        <modal_new_programs
            :partner_entity_id="partner_entity_id"
            :queryType="queryType"
            :programRow="programRow"
            :modalHeader="modalHeader"
            @close="props.close"
            @clicked="onClickChild">
        </modal_new_programs>
      </template>
    </b-modal>
  </section>
</template>

<script>
import modal_new_programs from "./modal_new_programs";
export default {
  name: "modal_show_programs",
  components: {modal_new_programs},
  props:[
    'programRow','partner_entity_id','can_edit_program'
  ],
  data(){
    return {
      isLoading: false,
      name: null,
      start_date: null,
      end_date: null,
      description: null,
      program_url: null,
      isAddModalActive: false,
      canCancel: ['escape'],
      modalHeader: 'Editar Programa',
      queryType: 'edit',
    }
  },
  created() {
    this.isLoading = true
    if (this.programRow.alt_name == null || this.programRow.alt_name == ''){
      this.name = this.programRow.name
    }
    else{
      this.name = this.programRow.alt_name
    }
    this.description = this.programRow.description
    this.program_url = this.programRow.program_url
    this.isLoading = false
  },
  methods:{
    formatDate(date){
      if (date!=null && date!="" && date!=undefined && date._isValid!=false){
        return new Date(date).toLocaleDateString("es-CL")
      }
      else{
        return ''
      }
    },
    onClickChild(){
      this.$emit('clicked')
      this.$emit('close')
    },
    editProgram() {
      this.isAddModalActive = true
    }
  }
}
</script>
