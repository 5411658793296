<template>
  <div>
    <section>
      <div class="modal-card" style="width: auto" id="modal-event">
        <header class="modal-card-head">
          <p class="modal-card-title">{{modalHeader}}</p>
          <button
              type="button"
              class="delete"
              @click="$emit('close')"/>
        </header>
        <section class="modal-card-body">
          <b-tabs position="is-centered" @input="changeTab">
            <b-tab-item v-for="item in items"
                        :value="item.content"
                        v-bind:key="item.content"
                        :label="item.tab"
                        :icon="item.icon">
            </b-tab-item>

          </b-tabs>
          <!-- COMPONENTE AQUI -->
          <keep-alive>
            <component v-bind:is="currentTabComponent"
                       :participants="project.participants"
                       :cargo-principal="cargoPrincipal"
                       :project="project"></component>
          </keep-alive>
          <!-- FIN COMPONENTE -->
        </section>
      </div>
    </section>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>
<script>
import General from './ShowGeneralTab.vue';
import FinancingsNullifiedTab from "./FinancingsNullifiedTab";
import Entities from './ProjectAssociatedEntitiesTab.vue';
import Participants from './ProjectParticipantsTab.vue';
import axios from "axios";
export default {
  components:{ FinancingsNullifiedTab, General, Entities, Participants},
  data(){
    return{
      project: {
        project_type_attributes:[
          {id:-1,name:""}
        ],
        participants_attributes:[],
        validation_attributes:{
          name:""
        },
        financings_attributes:[]
      },
      isLoading:false,
      major_project_category:null,
      minor_project_category:null,
      vridei_category:null,
      participantes: [],
      currentPage:1,
      cardLayout:false,
      currentTab: 'General',
      items: [
        {tab: 'General',content:'General',icon:"file-alt"},
        {tab: 'Participantes',content:'Participants',icon:"users"},
        {tab: 'Financiamientos',content:'FinancingsNullifiedTab',icon:"dollar-sign"},
        {tab: 'Entidades Asociadas',content:'Entities',icon:"industry"},

      ]

    }
  },
  created(){
    this.isLoading=true;
    return axios
        .get("/projects/show_project_nullified/"+this.projectId+".json")
        .then(res => {
          this.project = res.data[0];
          this.project.start_date = this.formatDate(this.project.start_date);
          this.project.end_date = this.formatDate(this.project.end_date);
          this.project.project_states_attributes = this.project.project_states_attributes.map(
              obj=> ({ ...obj, state_date: this.formatDate(obj.state_date) })
          );
          if(this.project.participants!= null){
            this.participantes = this.project.participants
          }
          if(this.project.participants== ""){
            this.project.participants = [];
            this.participantes = [];
          }
          this.project.can_edit = false
          this.project.is_creator = false
          this.isLoading=false;
        })
        .catch(e => {
          console.log(e);
          this.isLoading=false;
        });

  },
  props:[
    'projectId', 'modalHeader', 'cargoPrincipal'
  ],
  methods:{
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    editProject(project_id){
      window.location.href = "/projects/"+project_id+"/edit"
    },
    changeTab: function(value){
      this.currentTab = value
    }

  },
  computed:{
    currentTabComponent: function () {
      return this.currentTab
    }
  }
}
</script>