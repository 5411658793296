<template>
  <section>
    <div class="columns">
      <div class="column">
        <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
          <b-message type="is-danger" has-icon>
            <div v-for="(item,index) in errores_validacion">
              {{ item[0] }}
            </div>
          </b-message>
        </div>
      </div>
    </div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(adjudicateProject)">
        <!-- Box de Formulario Appointment -->
        <div class="box">
              <div class="columns">
                <div class="column">
                  <h3 class="is-size-4 has-text-weight-bold">Agregar Nuevo Proyecto </h3>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Nombre*">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-input v-model="project.name" type="text"></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column">
                  <b-field>
                    <template #label>
                      Código Adjudicado*
                      <b-tooltip
                          label="Esté código será utilizado como identificador único en caso de que haya alcance de nombre. Se sugiere ingresar código otorgado
                            por entidad auspiciante, por ejemplo el código otorgado por la universiad o por ANID"
                          position="is-right"
                          size="is-medium"
                          multilined>

                        <b-icon
                            pack="fas"
                            icon="info-circle"
                            size="is-small"
                            type="is-info">
                        </b-icon>
                      </b-tooltip>
                    </template>
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <b-input type="text" v-model="project.code"></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>


              <div class="columns">
                <div class="column" v-if="tipos_proyecto.length>0">
                  <b-field label="Tipo de Proyecto*">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <multiselect v-model="project.project_type_id"
                                   :options="tipos_proyecto.map(type => type.id)"
                                   :custom-label="opt => tipos_proyecto.find(x => x.id == opt).name"
                                   placeholder="Seleccione tipo de proyecto"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="No se puede deseleccionar"
                                   :allow-empty="false">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                                    No se encontraron elementos.
                                </span>
                      </multiselect>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Año de adjudicación">
                    <ValidationProvider rules="integer|positive" v-slot="{errors}">
                      <b-input type="number" v-model="project.award_year"></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Fecha Inicio*">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <b-datepicker
                          v-model="project.start_date"
                          icon="calendar-alt"
                          trap-focus
                          locale="es-ES"
                          editable
                          placeholder="Formato de Fecha: dd/mm/aaaa"
                      >
                      </b-datepicker>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Fecha Término">
                    <b-datepicker
                        v-model="project.end_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                    >
                    </b-datepicker>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <div class="buttons">
                    <div class="column is-5">
                      <b-field label="">
                        <b-checkbox v-model="project.keep_participants" @input="copyParticipants"> Mantener Participantes</b-checkbox>
                      </b-field>
                    </div>
                    <div class="column is-narrow">
                      <b-button class="is-primary is-outlined" @click="modalParticipants = true" icon-left="eye">
                        Ver Participantes
                      </b-button>
                    </div>
                  </div>
                </div>
                  <div class="column">
                    <div class="buttons">
                      <div class="column is-5">
                        <b-field label="">
                          <b-checkbox v-model="project.keep_entities" @input="copyEntities">Mantener Entidades Relacionadas</b-checkbox>
                        </b-field>
                      </div>
                      <div class="column is-narrow">
                        <b-button class="is-primary is-outlined" @click="modalEntities = true" icon-left="eye">
                          Ver Entidades Relacionadas
                        </b-button>
                      </div>
                    </div>
                </div>
              </div>
              <div class="columns" v-if="project.keep_participants">
                <div class="column">
                  <h3 class="is-size-6 has-text-weight-bold">
                    Dueña(o)*
                    <b-tooltip label="Debe seleccionar al menos una Dueña o Dueño del dato que se encuentre como participante en la actividad académica. Si realiza el cambio, no podrá revertirlo."
                               position="is-right"
                               size="is-large"
                               multilined>
                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          size="is-small"
                          type="is-info">
                      </b-icon>
                    </b-tooltip>
                  </h3>
                  <b-field label=" ">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <multiselect v-model="project.ownerId"
                                   :options="project.participants.map(type => type.person_id)"
                                   :custom-label="opt => project.participants.find(x => x.person_id === opt).name"
                                   placeholder="Seleccione Dueña(o)"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel=""
                                   :allow-empty="false">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                              No se encontraron elementos.
                          </span>
                      </multiselect>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>
              <div class="container" style="padding-bottom: 10px">
                <section>
                  <div class="columns">
                    <div class="column">
                      <div class="rows">
                        <div class="row">
                          <h3 class="is-size-4 has-text-weight-bold has-text-centered" v-if="project.financings.length>0">Tabla Financiamientos</h3>
                        </div>
                        <div class="row"  v-if="project.financings.length>0">
                          <b-table
                              :data="project.financings"
                              :paginated=true
                              :card-layout="cardLayout"
                              per-page=10
                              striped
                              hoverable
                              :current-page="currentPage"
                              :pagination-simple=false
                              pagination-position="bottom"
                              default-sort-direction="asc"
                              sort-icon="arrow-up"
                              sort-icon-size="is-small"
                              default-sort="financing.year"
                              aria-next-label="Siguiente"
                              aria-previous-label="Anterior"
                              aria-page-label="Página"
                              aria-current-label="Página actual"
                          >
                            <b-table-column
                                field= 'amount'
                                label= 'Monto ($)'
                                searchable
                                sortable>
                              <template
                                  slot="searchable"
                                  slot-scope="props">
                                <b-input
                                    v-model="props.filters[props.column.field]"
                                    icon="search"
                                    size="is-small" />
                              </template>
                              <template v-slot="props">
                                {{ props.row.amount }}
                              </template>
                            </b-table-column>
                            <b-table-column
                                field= 'year'
                                label= 'Año'
                                searchable
                                sortable>
                              <template
                                  slot="searchable"
                                  slot-scope="props">
                                <b-input
                                    v-model="props.filters[props.column.field]"
                                    icon="search"
                                    size="is-small" />
                              </template>
                              <template v-slot="props">
                                {{ props.row.year }}
                              </template>
                            </b-table-column>
                            <b-table-column
                                field= 'financing_type'
                                label= 'Tipo Postulado'
                                searchable
                                sortable>
                              <template
                                  slot="searchable"
                                  slot-scope="props">
                                <b-input
                                    v-model="props.filters[props.column.field]"
                                    icon="search"
                                    size="is-small" />
                              </template>
                              <template v-slot="props">
                                {{props.row.financing_type ? props.row.financing_type : ""}}
                              </template>
                            </b-table-column>
                            <b-table-column
                                field="financing_type"
                                label="Tipo Financiamiento*"
                                v-slot="props">
                              <ValidationProvider rules="required" v-slot="{errors}">
                                <multiselect v-model="props.row.new_financing_type_id"
                                             :options="financing_types.map(x => x.id)"
                                             :custom-label="opt => financing_types.find(x => x.id == opt).name"
                                             placeholder="Seleccione tipo de Financiamiento"
                                             selectLabel="Presione para seleccionar"
                                             selectedLabel="Seleccionado"
                                             deselectLabel="No se puede deseleccionar"
                                             :allow-empty="false">
                                  <template v-slot:noOptions>
                                    No existen datos
                                  </template>
                                  <span slot="noResult">
                                    No se encontraron elementos.
                                  </span>
                                </multiselect>
                                <span class="validation-alert">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </b-table-column>
                            <b-table-column
                                field= 'source'
                                label= 'Entidad de financiamiento'
                                searchable
                                sortable>
                              <template
                                  slot="searchable"
                                  slot-scope="props">
                                <b-input
                                    v-model="props.filters[props.column.field]"
                                    icon="search"
                                    size="is-small" />
                              </template>
                              <template v-slot="props">
                                {{props.row.partner_entity ? props.row.partner_entity : ""}}
                              </template>
                            </b-table-column>
                            <b-table-column
                                label="Acciones"
                                v-slot="props">
                              <b-button type="is-danger"
                                        icon-right="trash"
                                        @click.native="deleteFinancingButton(props.row)">
                              </b-button>


                            </b-table-column>

                            <template slot="empty" slot-scope="props">
                              <div class="columns is-centered">
                                <div class="column is-6 is-centered has-text-centered">
                                  <b-message type="is-danger">
                                    No se encontraron resultados para el año seleccionado.
                                  </b-message>
                                </div>
                              </div>
                            </template>
                          </b-table>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
          <div class="actions has-text-right">
            <button class="button is-danger" @click.prevent="$emit('close')">Cancelar</button>
            <button class="button is-primary" type="submit">Guardar y Adjudicar Proyecto </button>
          </div>
        </div>

      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="modalParticipants"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal-postulated-participants
                    modal-header="Participantes Relacionados"
                    :participants="project.participants"
                    @close="props.close">
        </modal-postulated-participants>
      </template>
    </b-modal>

    <b-modal
        v-model="modalEntities"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal-postulated-entities
            modal-header="Entidades Relacionados"
            :entities="project.associated_entities"
            @close="props.close">
        </modal-postulated-entities>
      </template>
    </b-modal>

  </section>
</template>
<script>
import axios from "axios";
import {searchDate} from "../../packs/utilities";
import moment from 'moment'
import ModalPostulatedParticipants from "./ModalPostulatedParticipants"
import ModalPostulatedEntities from "./ModalPostulatedEntities"

export default {
  name: "new_adjudicated_project",
  props:[
    'queryType', 'projectId', 'modalHeader'
  ],
  components:{
    ModalPostulatedParticipants,
    ModalPostulatedEntities
  },
  data(){
    return {
      project:{
        is_postulated: false,
        keep_participants: true,
        keep_entities: true,
        participants: [],
        associated_entities: [],
        financings: []
      },
      postulated_project: {},
      errores_validacion:{},
      isLoading: false,
      currentPage:1,
      cardLayout:false,
      tipos_proyecto: [],
      projects: [],
      isLoadingMultiselect: false,
      isDisabled: false,
      showDetailIcon: true,
      selected: null,
      currentPageParticipants: 1,
      currentPageEntities: 1,
      financing_types: [],
      modalParticipants: false,
      modalEntities: false
    }
  },
  created() {
    this.isLoading=true;
    if (this.queryType === "create"){
      axios
          .get("/projects/postulated/"+this.projectId+"/adjudicate-new.json")
          .then(res => {
            this.postulated_project = res.data["project"]
            this.project.name = this.postulated_project.name
            this.project.project_type_id = this.postulated_project.project_type_id
            this.project.associated_entities = this.postulated_project.associated_entities
            this.project.financings = this.postulated_project.financings
            this.tipos_proyecto = res.data["tipos_proyecto"]
            this.financing_types = res.data["financing_types"]
            this.project.financings = this.project.financings.map(financing => {
              var newFinancing = financing
              newFinancing["new_financing_type_id"] =  null
              return newFinancing;
            });
            this.project.participants = this.postulated_project.participants
            this.isLoading=false;
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }
  },
  methods: {
    deleteFinancingButton(financing){

      this.$buefy.dialog.confirm({
        title: 'Eliminar Aporte',
        message: 'Precaución, esta acción eliminará información sensible del aporte asociado ¿Desea continuar?',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteFinancing(financing)
      })

    },
    deleteFinancing(financing){
      let vm = this;
      vm.isLoading = true;
      var index = vm.project.financings.findIndex(obj => obj == financing);
      vm.project.financings.splice(index,1);
      vm.isLoading = false;
    },
    copyParticipants(){
      this.isLoading = true
      console.log("copiando participantes...")
      if(this.project.keep_participants){
        this.project.participants = this.postulated_project.participants
      }
      else{
        this.project.participants = []
      }
      this.isLoading = false
    },
    copyEntities(){
      this.isLoading = true
      console.log("copiando entidades...")
      if(this.project.keep_entities){
        this.project.associated_entities = this.postulated_project.associated_entities
      }
      else{
        this.project.associated_entities = []
      }
      this.isLoading = false
    },
    adjudicateProject(){
      this.isLoading = true
      axios
          .post("/projects/postulated/"+this.projectId+"/adjudicate-create.json",
              {
                project: this.project,
                postulated_project_id: this.projectId
              })
          .then(res => {
            var id = res.data["id"]
            this.$buefy.dialog.alert({
              message: '<p>Proyecto postulado fue adjudicado correctamente. Para abrir el proyecto en una nueva pestaña, presione</p><b><a href="/projects/'+id+'" target="_blank">aquí</a></b>',
              type: 'is-success',
              hasIcon: true,
              icon: 'check-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
              canCancel: [],
              onConfirm: () => {
                this.errores_validacion = {};
                this.isLoading = false;
                window.location.href = '/projects/postulated/' + this.projectId
              }
            })

          }).catch(error => {
        console.log(error)
        this.$buefy.dialog.alert({
          message: 'Sucedió un error al intentar adjudicar el proyecto, revise el formato.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading=false;
      })
    },
    searchProject(query){
      if(query.length<3){
        return
      }
      this.isLoadingMultiselect = true;
      this.project.parent_project_id = null;
      axios
          .get("/projects/postulated/search-projects.json",
              {
                params: {
                  query: query
                }
              })
          .then(res => {
            this.projects = res.data
            this.isLoadingMultiselect=false;

          }).catch(error => {
        console.log(error)
        this.isLoadingMultiselect=false;
      })
    },
    deleteData(project){

    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
    searchEndDate(row,input){
      return searchDate(row.end_date,input)
    },
    searchInitDate(row,input){
      return searchDate(row.start_date,input)
    },
  }

}
</script>

<style scoped>

</style>