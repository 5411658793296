<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(rectify)">
        <div class="columns">
          <div class="column">
            <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
              <b-message type="is-danger" has-icon>
                <div v-for="(item,index) in errores_validacion">
                  {{ item[0] }}
                </div>
              </b-message>
            </div>
          </div>
        </div>
        <b-steps
            v-model="activeStep"
            :animated="isAnimated"
            :rounded="isRounded"
            :has-navigation="hasNavigation"
            :icon-prev="prevIcon"
            :icon-next="nextIcon"
            :label-position="labelPosition"
            :mobile-mode="mobileMode">
          <b-step-item step="1" label="Edición Nombramiento" :clickable="isStepsClickable">
            <!-- Box de Formulario Appointment -->
            <div class="is-centered" style="margin-top: 30px;">
              <div class="title is-4 is-centered" style="text-align: center; margin-bottom: 20px">
                Nombramiento
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción*">
                  <ValidationProvider rules="required|min:5|max:200" v-slot="{ errors }">
                    <b-input v-model="person_unit.description" type="textarea"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de documento">
                  <multiselect v-model="person_unit.resolution_type_id"
                               :options="resolution_types.map(type => type.id)"
                               :custom-label="opt => resolution_types.find(x => x.id === opt).name"
                               placeholder="Seleccione tipo de documento"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                               :allow-empty="true"
                               :disabled="disable_hours || disable_change_cc">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                          No se encontraron elementos.
                      </span>
                  </multiselect>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Número de documento">
                  <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                    <b-input v-model="person_unit.resolution" type="text" :disabled="disable_hours || disable_change_cc"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de documento">
                  <b-datepicker
                      v-model="person_unit.resolution_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                      :disabled="disable_hours || disable_change_cc">
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de Nombramiento*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.appointment_type_id"
                                 :options="appointment_types.map(type => type.id)"
                                 :custom-label="opt => appointment_types.find(x => x.id === opt).name"
                                 placeholder="Seleccione tipo de nombramiento"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false"
                                 :disabled="disable_hours || disable_change_cc">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Cargo*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.work_plant_id"
                                 :options="work_plants.map(type => type.id)"
                                 :custom-label="opt => work_plants.find(x => x.id === opt).name"
                                 placeholder="Seleccione un cargo"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false"
                                 :disabled="disable_hours || disable_change_cc">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Calidad de contrato">
                  <multiselect v-model="person_unit.quality"
                               :options="qualities.map(type => type.name)"
                               placeholder="Seleccione calidad de contrato"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                               :allow-empty="true"
                               :disabled="disable_hours || disable_change_cc">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Unidad de contratación*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.contracting_unit_id"
                                 :options="contract_units.map(type => type.id)"
                                 :custom-label="opt => contract_units.find(x => x.id === opt).name"
                                 placeholder="Seleccione una unidad"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false"
                                 :disabled="disable_hours">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">

                <b-field label="Unidad de desempeño*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.unit_id"
                                 :options="units.map(type => type.id)"
                                 :custom-label="opt => units.find(x => x.id === opt).name"
                                 placeholder="Seleccione una unidad"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false"
                                 :disabled="disable_hours">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <h3 class="is-size-6 has-text-weight-bold">Área de Desempeño
                  <b-tooltip
                      label="Área de desempeño: Sub Unidad dentro de la Unidad de Desempeño.
                      Presione Enter luego de escribir el área de desempeño para que sea agregada"
                      position="is-left"
                      size="is-medium"
                      multilined>

                    <b-icon
                        pack="fas"
                        icon="info-circle"
                        size="is-small"
                        type="is-info">
                    </b-icon>
                  </b-tooltip>
                </h3>
                <b-field label=" ">
                  <b-taginput
                      v-model="performance_area"
                      :data="performance_areas"
                      type="is-primary is-light"
                      autocomplete
                      :allow-new="true"
                      field="name"
                      icon="tag"
                      maxtags="1"
                      placeholder="Agregar o escoger un área de desempeño"
                      :disabled="disable_hours || disable_change_cc">
                  </b-taginput>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Jornada (horas)">
                  <multiselect v-model="person_unit.working_day_id"
                               :options="working_days.map(type => type.id)"
                               :custom-label="opt => working_days.find(x => x.id === opt).hired_hours"
                               placeholder="Seleccione jornada"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Precione para deseleccionar"
                               :allow-empty="true"
                               :disabled="disable_change_cc">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Desde*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-datepicker
                        v-model="person_unit.start_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                        :disabled="disable_hours || disable_change_cc">
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Hasta">
                  <b-datepicker
                      v-model="person_unit.end_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                      :disabled="disable_hours || disable_change_cc">
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Nivel">
                  <multiselect v-model="person_unit.level_id"
                               :options="levels.map(type => type.id)"
                               :custom-label="opt => levels.find(x => x.id === opt).name"
                               placeholder="Seleccione nivel"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                               :allow-empty="true"
                               :disabled="disable_hours || disable_change_cc">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Grado">
                  <multiselect v-model="person_unit.grade_id"
                               :options="grades.map(type => type.id)"
                               :custom-label="opt => grades.find(x => x.id === opt).name"
                               placeholder="Seleccione grado"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                               :allow-empty="true"
                               :disabled="disable_hours || disable_change_cc">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <b-field label="Url Documentación">
                  <ValidationProvider v-slot="{ errors }">
                    <b-input v-model="person_unit.documentation_link" type="text" :disabled="disable_hours || disable_change_cc"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
          </b-step-item>
          <b-step-item step="2" :label="'Datos ' +form_name" :clickable="isStepsClickable">
            <div class="is-centered" style="margin-top: 30px;">
              <div class="title is-4 is-centered" style="text-align: center; margin-bottom: 20px">
                {{form_name}}
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de documento">
                  <multiselect v-model="event.resolution_type_id"
                               :options="resolution_types.map(type => type.id)"
                               :custom-label="opt => resolution_types.find(x => x.id === opt).name"
                               placeholder="Seleccione tipo de documento"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                               :allow-empty="true">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                          No se encontraron elementos.
                      </span>
                  </multiselect>
                </b-field>
              </div>
              <div class="column">
                <b-field label="N° Documento">
                  <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                    <b-input v-model="event.decree" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Documento">
                  <b-datepicker
                      v-model="event.decree_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field :label="'Fecha '+form_name+'*'">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-datepicker
                        v-model="event.start_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                    >
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field :label="'Url carpeta documentación ' +form_name">
                  <ValidationProvider v-slot="{ errors }" rules="max:255">
                    <b-input v-model="event.documentation_link" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input ref="input_descripcion" v-model="event.description" type="textarea" minlength="10" maxlength="4000"
                             icon-right="sync-alt"
                             icon-right-clickable
                             @icon-right-click="updateDescription"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="has-text-right is-grouped">
              <button class="button is-primary" type="submit">Guardar {{form_name}}</button>
            </div>
          </b-step-item>
        </b-steps>
      </form>
    </ValidationObserver>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import {compareObjects, formatString} from "../../../packs/utilities";
import _ from "lodash";

export default {
  name: "rectify_appointment_administrative",
  props:[
    'queryType', 'personId', 'id', 'modalHeader', 'form_name', 'event_type_id'
  ],
  data(){
    return {
      event:{
        event_type_id: null,
        person_unit_id: this.id
      },
      person_unit:{
        is_academic:true
      },
      old_person_unit:{
        is_academic:true
      },
      years:[],
      qualities: [{name: "CONTRATA"},{name: "PLANTA"},{name: "AD-HONOREM"}, {name: "INTERINO"}, {name: "SUPLENTE"}],
      appointment_types:[],
      work_plants:[],
      grade: {},
      rank: {},
      resolution_types:[],
      hierarchies: [],
      units: [],
      contract_units: [],
      working_days:[],
      errores_validacion:{},
      documentation_link:'',
      establishments:[],
      isLoading: true,
      isDistanceModalActive: false,
      link: false,
      distances: [],
      activeStep: 0, //bsteps
      showSocial: false,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      hasNavigation: true,
      customNavigation: false,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      event_types: [],
      performance_areas: [],
      performance_area: null,
      disable_rectification: false,
      disable_hours: false,
      disable_change_cc: false,
      grades: [],
      levels: [],
      options: [{type: 'Dejar de Servir', distances: []}, {type: 'Término de Contrato', distances: []}, {type: 'Renuncia', distances: []}],
    }
  },
  created() {
    this.isLoading=true;
    axios
        .get("/life_record/rectify/"+this.id+".json")
        .then(res => {
          //this.old_person_unit = res.data["person_unit"]
          this.person_unit = res.data["person_unit"]
          if(res.data["person_unit"]["start_date"]!==null){
            this.person_unit.start_date = new Date(this.formatDate(res.data["person_unit"]["start_date"]))
          }
          if(res.data["person_unit"]["end_date"]!==null){
            this.person_unit.end_date = new Date(this.formatDate(res.data["person_unit"]["end_date"]))
          }
          if(res.data["person_unit"]["decree_date"]!==null){
            this.person_unit.decree_date = new Date(this.formatDate(res.data["person_unit"]["decree_date"]))
          }
          if(res.data["person_unit"]["resolution_date"]!==null){
            this.person_unit.resolution_date = new Date(this.formatDate(res.data["person_unit"]["resolution_date"]))
          }
          if(this.person_unit.performance_area){
            this.performance_area = [this.person_unit.performance_area]
          }
          this.old_person_unit = _.cloneDeep(this.person_unit);
          this.units = res.data["person_unit"]["units"]
          this.contract_units = res.data["person_unit"]["contract_units"]
          this.grade = res.data["person_unit"]["grade"]
          this.rank = res.data["person_unit"]["rank"]
          this.hierarchies = res.data["person_unit"]["hierarchies"]
          this.resolution_types = res.data["person_unit"]["resolution_types"]
          this.work_plants = res.data["person_unit"]["work_plants"]
          this.appointment_types = res.data["person_unit"]["appointment_types"]
          this.working_days = res.data["person_unit"]["working_days"]
          this.documentation_link = res.data["person_unit"]["documentation_link"]
          this.grades = res.data["person_unit"]["grades"]
          this.levels = res.data["person_unit"]["levels"]
          this.performance_areas = res.data["person_unit"]["performance_areas"]
          this.establishments = res.data["person_unit"]["establishments"]
          this.event_types = res.data["event_types"]
          this.event.event_type_id = this.event_type_id //RECTIFICACION, AUMENTO DE HORAS, REDUCCION DE HORAS
          if(parseInt(this.event_type_id) === 10){//reduccion de horas
            this.disable_hours = true
          }
          else if(parseInt(this.event_type_id) === 11){//aumento de horas
            this.disable_hours = true
          }
          else if(parseInt(this.event_type_id) === 12){//cambio de cc
            this.disable_change_cc = true
          }
          this.event.person_unit_id = this.person_unit.id
          this.event.person_id = this.person_unit.person_id
          this.isLoading = false;
        }).catch(error => {
      console.log(error)
      this.isLoading=false;
    })

  },
  methods: {
    formatString,
    compareObjects,
    updateDescription(){
      this.isLoading=true
      if(this.performance_area){
        if(this.performance_area.length>0){
          this.person_unit.performance_area = this.performance_area[0].toUpperCase()
        }
        else{
          this.person_unit.performance_area = null
        }
      }
      else{
        this.person_unit.performance_area = null
      }
      let description = compareObjects(this.old_person_unit, this.person_unit, ["description", "function_name", "work_plants", "units",
        "contract_units", "establishments", "hierarchies", "working_days", "levels", "grades", "establishment", "function",
        "work_plant", "hr_subjects", "has_hr_subjects", "days", "appointment_days", "appointment_type",
        "performance_areas", "functions", "rank", "grade", "appointment_types", "categories", "resolution_types", "all_hr_subjects", "text_schedule"])

      this.$refs.input_descripcion.updateValue(formatString(description))
      this.isLoading = false
    },
    rectify(){
      if(this.performance_area){
        if(this.performance_area.length>0){
          this.person_unit.performance_area = this.performance_area[0].toUpperCase()
        }
        else{
          this.person_unit.performance_area = null
        }
      }
      else{
        this.person_unit.performance_area = null
      }
      this.isLoading=true;
      let personUnitEstablishment = this.establishments.find(obj => obj.name == "ADMINISTRATIVOS");
      if (personUnitEstablishment)
        this.person_unit.establishment_id = personUnitEstablishment.id;

      let clean_person_unit = this.person_unit
      clean_person_unit = this.deleteData(clean_person_unit)
      axios
          .put("/admin/person_units/" + this.id+".json", {
            person_unit:  clean_person_unit,
            grade: this.grade, rank: this.rank
          })
          .then(response => {
            this.event.end_date = this.event.start_date
            axios
                .post("/admin/events.json", {
                  event: this.event
                })
                .then(response =>{
                  this.errores_validacion = {};
                  this.$buefy.dialog.alert({
                    message: 'La Rectificación fue creada con éxito ',
                    confirmText: 'Ok'
                  })
                  this.person_unit = {}
                  this.isLoading=false;
                  this.errors = [];
                  window.location.href="/people/"+this.event.person_id+"/life_record"
                }).catch(error => {
              this.errores_validacion = error.response.data
              this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Hubo errores al agregar la rectificación, favor revisar formato',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              this.isLoading=false;
            })
          }).catch(error => {
        this.errores_validacion = error.response.data
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo errores al rectificar el nombramiento, favor revisar formato',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading=false;
      })
    },
    setGradeAndRank(){
      this.isLoading = true;
      axios
          .get("/admin/hierarchies/"+this.person_unit.hierarchy_id+"/get_grade_and_rank.json")
          .then(res => {
            this.grade = res.data["grade"]
            this.rank = res.data["rank"]
            this.isLoading = false
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    },
    deleteData(person_unit){
      delete person_unit.units
      delete person_unit.internal_positions
      delete person_unit.hierarchies
      delete person_unit.appointment_types
      return person_unit
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
  }
}
</script>
<style>
#description_rectify .control.has-icons-right .icon {
  color: #7957d5;
}
.control.has-icons-right .icon {
  color: #7957d5;
}
</style>