<template>
  <div>
    <div class="container" style="border-radius: 15px">
      <img id="pfp" src="/blank_profile_photo.png" style="display: none;"/>
      <img id="photo" :src="this.person.photo_url" style="display: none;"/>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
          <b-message type="is-danger" has-icon>
            <div v-for="(item,index) in errores_validacion">
              {{ item[0] }}
            </div>
          </b-message>
        </div>
        <form @submit.prevent="handleSubmit(saveProfile)">
          <div class="columns">
            <div class="column">
                <div class="title is-5">
                  Datos Personales
                  <hr class="dropdown-divider" aria-role="menuitem">
                </div>
                <div class="columns">
                  <div class="column is-2">
                    <div class="columns">
                      <div class="column">
                        <img id="photo2" :src="imageRounded"/>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column is-pulled-left">
                        <div class="actions has-text-centered">
                          <b-button @click="savePhoto" class="is-primary is-outlined">
                            <b-icon icon="edit"></b-icon>
                            <span>Editar Foto</span>
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <div class="columns">
                      <div class="column">
                        <b-field label="Nombres">
                          <b-input disabled readonly v-model="person.name" type="text"></b-input>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Apellido">
                          <b-input disabled readonly v-model="person.last_name" type="text"></b-input>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Segundo Apellido">
                          <b-input disabled readonly v-model="person.second_surname" type="text"></b-input>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <b-field label="Correo corporativo">
                          <b-input disabled readonly v-model="person.corporate_mail" type="text"></b-input>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Correo personal">
                          <b-input v-model="person.personal_mail" type="text"></b-input>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Nombre social">
                          <b-input v-model="person.social_name" type="text"></b-input>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns">
                      <div class = "column">
                        <b-field label="Dirección">
                          <b-input v-model="person.address" type="text"></b-input>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns">
                      <div class = "column">
                        <b-field label="Teléfono">
                          <validationProvider rules="integer|positive" v-slot="{errors}" name="telefono">
                            <b-input v-model="person.phone" type="text"></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </validationProvider>
                        </b-field>
                      </div>
                      <div class = "column">
                        <b-field label="Celular">
                          <validationProvider rules="integer|positive" v-slot="{errors}" name="celular">
                            <b-input v-model="person.cellphone" type="text"></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </validationProvider>
                        </b-field>
                      </div>
<!--                      <div class="column">
                        <b-field label="Anexo Telefónico">
                          <validationProvider rules="integer|positive" v-slot="{errors}" name="celular">
                            <b-input v-model="person.annex_phone" type="text"></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </validationProvider>
                        </b-field>
                      </div>-->
                    </div>
<!--                    <div class="columns">
                      <div class="column">
                        <b-field label="Ubicación de la oficina">
                          <b-input v-model="person.office_location" type="text" maxlength="255" :has-counter="false"></b-input>
                        </b-field>
                      </div>
                    </div>-->
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <div class="actions has-text-right">
                      <button type="submit" class="button is-primary">Guardar datos personales</button>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <b-modal
        v-model="isModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        width="1024"
        aria-role="dialog"
        aria-modal
        :can-cancel="false">
      <template #default="props">
        <modal_edit_profile_photo
            :person="person"
            :image="image"
            @close="props.close"></modal_edit_profile_photo>
      </template>
    </b-modal>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>

</template>

<script>
import axios from "axios";
import modal_edit_profile_photo from "./modal_edit_profile_photo";
export default {
  name: "personal_data_tab",
  components: {modal_edit_profile_photo},
  data(){
    return{
      isLoading:false,
      person:{},
      errores_validacion:[],
      photo:{name:""},
      imageRounded: null,
      isModalActive:false,
      image:null,

    }
  },
  created(){
    this.isLoading=true;
    axios
        .get("/people/"+this.personId+".json")
        .then(res => {
          this.person = res.data
          this.loadImage()
        })
        .catch(e => {
          console.log(e);
          this.isLoading=false;
        });
  },
  props:[
    'personId'
  ],
  methods:{
    async checkValidation(){
      const valid = await this.$refs.observer.validate()
      if(!valid){
        this.$buefy.dialog.alert({
          message: 'Algunos campos presentan errores.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
    getPerson(){
      let vm = this;
      vm.isLoading=true;
      axios
          .get("/people/"+vm.personId+".json")
          .then(res => {
            vm.person = res.data
            vm.loadImage()
          })
          .catch(e => {
            console.log(e);
            vm.queryError(vm);
            vm.isLoading=false;
          });
    },
    setImage(e) {
      e.preventDefault();
      this.photo = e.target.files[0];
    },
    saveProfile(){
      let vm = this;
      vm.isLoading = true;
      let formData = new FormData();
      axios
          .put("/people/"+this.personId+"/my-academic-data/update-personal-data.json", {
                person: vm.person,
              },
          )
          .then(response => {
            vm.errores_validacion = [];
            vm.$buefy.dialog.alert({
              message: 'Tus datos personales han sido actualizados satisfactoriamente.',
              confirmText: 'Ok'
            });
            vm.isLoading=false;
            vm.getPerson();
          }).
      catch(error => {
        vm.errores_validacion = error.response.data
        var elmnt = document.getElementById("app");
        elmnt.scrollIntoView();
        vm.isLoading=false;
      })
    },
    async savePhoto(){
      this.isModalActive = true
    },
    queryError(vm){
      vm.$buefy.dialog.alert({
        title: 'Error',
        message: 'Hubo un error en su solicitud.',
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true
      })
    },
    getPhotoUrl(){
      var defaultUrl = "/blank_profile_photo.png"
      if (this.person){
        if (this.person.photo_url){
          return this.person.photo_url;
        }
      }
      return defaultUrl;

    },
    async loadImage(){
      try {
        this.isLoading = true
        setTimeout(() => {
          //Metodo para hacer redonda la foto de perfil
          var c = document.createElement('canvas');
          var img = document.getElementById('pfp')

          if(this.person.photo_url != null){
            img = document.getElementById('photo');
            this.image = this.person.photo_url
          }
          if(img == null || img.height == 0){
            img = document.getElementById('pfp')
            this.image = '/blank_profile_photo.png'
          }


          c.height = 805;
          c.width = 805;
          var ctx = c.getContext('2d');
          ctx.save();
          ctx.beginPath();
          ctx.arc(402.5, 402.5, 402.5, 0, Math.PI * 2, true);
          ctx.closePath();
          ctx.clip();
          ctx.drawImage(img, 0, 0, 805, 805);
          ctx.beginPath();
          ctx.arc(0, 0, 402.5, 0, Math.PI * 2, true);
          ctx.clip();
          ctx.closePath();
          ctx.restore();
          this.imageRounded = c.toDataURL();
        }, 2000);
        setTimeout(()=>{
          this.isLoading = false
        },3000);
      }
      catch(err){
        console.log('Errores:')
      }

    },
  },
  computed:{

  },
  watch: {
    isModalActive: function () {
      if (!this.isModalActive) {
        this.getPerson()
      }
    },
  }
}
</script>

<style scoped>

</style>