<template>
    <div class="configurationindexview">
        <div class="hero">
            <section class="hero has-background-white-bis" style="box-shadow: 0px 6px 14px -4px rgba(196,196,196,1);">
                <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="false"></b-loading>
                <div class="hero-body">
                    <div class="container">
                        <div class="columns">
                            <h1 class="is-size-2">Configuración del módulo VIME</h1>
                        </div>
                    </div>
                </div>
                <div class="hero-foot">
                    <div class="tabs is-large is-centered is-boxed" id="tabs-research">
                        <ul class="is-large">
                            <li data-target="pane-1" id="1" class="is-active" @click="tabSelection(0)" ref="ConfiguracionActividades">
                                <a>
                                    <span class="icon"><i class="fas fa-calendar"></i></span>
                                    <span class="h1">Actividades</span>
                                </a>
                            </li>
                            <li data-target="pane-1" id="2" @click="tabSelection(1)" ref="ConfiguracionConvenios">
                                <a>
                                    <span class="icon"><i class="fas fa-file-alt"></i></span>
                                    <span class="h1">Convenios</span>
                                </a>
                            </li>
                            <li data-target="pane-1" id="3" @click="tabSelection(2)" ref="ConfiguracionRedesSociales">
                                <a>
                                    <span class="icon"><i class="fas fa-globe"></i></span>
                                    <span class="h1">Redes sociales</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
        <div class="container">
            <ConfigurationActivityInfo  v-if="ShowViewToShow == 1"
                                        :activity_types='this.vime_activity_types'
                                        :activity_assistant_types='this.vime_assistant_types'/>
            <ConfigurationAgreementsInfo v-if="ShowViewToShow == 2"
                                         :agreement_general_types='this.vime_agreement_general_types'
                                         :agreement_specifict_types='this.vime_agreement_specifict_types'
                                         :agreement_stages='this.vime_agreement_stages'
                                         :agreement_processes='this.vime_agreement_processes'
                                         :agreement_actors='this.vime_agreement_actors'/>
            <ConfigurationSocialNetworksInfo v-if="ShowViewToShow == 3" 
                                         :social_networks='this.social_networks'/>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import ConfigurationActivityInfo from '../index/configuration_activity_information.vue'
import ConfigurationAgreementsInfo from '../index/configuration_agreements_information.vue'
import ConfigurationSocialNetworksInfo from '../index/configuration_social_networks_information.vue'
export default {
    name: "ConfigurationIndexView",
    components:{
        ConfigurationActivityInfo,
        ConfigurationAgreementsInfo,
        ConfigurationSocialNetworksInfo
    },
    data(){
        return {
            isFullPage: true,
            isLoading: true,
            ShowViewToShow: 1,
            vime_activity_types: [],
            vime_assistant_types: [],
            vime_agreement_general_types: [],
            vime_agreement_specifict_types: [],
            vime_agreement_stages: [],
            vime_agreement_processes: [],
            vime_agreement_actors: [],
            social_networks: []
        }
    },
    beforeMount(){
        this.getVimeActivityTypes()
        this.getVimeActivityAssistantTypes()
        this.getVimeAgreementGeneralTypes()
        this.getVimeAgreementSpecifictTypes()
        this.getVimeAgreementStages()
        this.getVimeAgreementProcesses()
        this.getVimeAgreementActors()
        this.getSocialNetworks()
    },
    methods:{
        getVimeActivityTypes(){
            axios.get('/vime_activity_types.json')
            .then(response =>{
                this.vime_activity_types = response.data
            })
            .catch(e => console.log(e))
        },
        getVimeActivityAssistantTypes(){
            axios.get('/vime_assistant_types.json')
            .then(response =>{
                this.vime_assistant_types = response.data
            })
            .catch(e => console.log(e))
        },
        getVimeAgreementGeneralTypes(){
            axios.get('/agreement_general_types.json')
            .then(response =>{
                this.vime_agreement_general_types = response.data
            })
            .catch(e => console.log(e))
        },
        getVimeAgreementSpecifictTypes(){
            axios.get('/agreement_specifict_types.json')
            .then(response =>{
                this.vime_agreement_specifict_types = response.data
            })
            .catch(e => console.log(e))
        },
        getVimeAgreementStages(){
            axios.get('/agreement_stages.json')
            .then(response =>{
                this.vime_agreement_stages = response.data
            })
            .catch(e => console.log(e))
        },
        getVimeAgreementProcesses(){
            axios.get('/agreement_processes.json')
            .then(response =>{
                this.vime_agreement_processes = response.data
            })
            .catch(e => console.log(e))
        },
        getVimeAgreementActors(){
            axios.get('/agreement_actors.json')
            .then(response =>{
                this.vime_agreement_actors = response.data
            })
            .catch(e => console.log(e))
        },
        getSocialNetworks(){
            axios.get('/social_networks.json')
            .then( response =>{
                this.social_networks = response.data
                this.isLoading=false
            })
            .catch(e => { 
                console.log(e)
                this.isLoading=false
            })
        },
        tabSelection(tabCase){
            switch(tabCase){
                case 0:
                    this.$refs.ConfiguracionActividades.classList.add("is-active")

                    if(this.$refs.ConfiguracionConvenios.classList.contains("is-active"))
                        this.$refs.ConfiguracionConvenios.classList.remove("is-active")

                    if(this.$refs.ConfiguracionRedesSociales.classList.contains("is-active"))
                        this.$refs.ConfiguracionRedesSociales.classList.remove("is-active")

                    this.ShowViewToShow = 1 
                    break;

                case 1:
                    this.$refs.ConfiguracionConvenios.classList.add("is-active")
                    
                    if(this.$refs.ConfiguracionActividades.classList.contains("is-active"))
                        this.$refs.ConfiguracionActividades.classList.remove("is-active")
                    
                    if(this.$refs.ConfiguracionRedesSociales.classList.contains("is-active"))
                        this.$refs.ConfiguracionRedesSociales.classList.remove("is-active")

                    this.ShowViewToShow = 2
                    break;

                default:
                    this.$refs.ConfiguracionRedesSociales.classList.add("is-active")
                    
                    if(this.$refs.ConfiguracionActividades.classList.contains("is-active"))
                        this.$refs.ConfiguracionActividades.classList.remove("is-active")
                    
                    if(this.$refs.ConfiguracionConvenios.classList.contains("is-active"))
                        this.$refs.ConfiguracionConvenios.classList.remove("is-active")
        
                    this.ShowViewToShow = 3
                    break;
            }
        }
    }
}
</script>