<template>
  <section>

  <div class="columns">
    <div class="column"> 

      <b-collapse 
          class="custom-collapse" 
          animation="slide"
          :open="isOpenService"
          aria-id="contentId3">
          <template #trigger="props">
              <div
                  class="card-header"
                  role="button"
                  aria-controls="contentId3"
                  :aria-expanded="props.open">
                   <h2 class="card-header-title custom-header-title is-centered">
                    <b-icon type="is-info" class="is-pulled-left" icon="info-circle"></b-icon>
                    Información del servicio
                  </h2>
                  <a class="card-header-icon">
                     <b-icon class="is-info" :icon="props.open ? 'sort-down' : 'sort-up'"></b-icon>
                  </a>
              </div>
          </template>

          <div class="card-content">
              <div class="content">
                <div class="columns" >
                  <div class="column">
                    <p>
                      <strong>Código: </strong>
                      <span>{{service.code}}</span>
                    </p>
                  </div>
                  <div class="column">
                    <p>
                      <strong>Nombre: </strong>
                      <span>{{service.name}}</span>
                    </p>
                  </div>
                </div>
                <div class="columns" >
                  <div class="column">
                    <p>
                      <strong>Unidad: </strong>
                      <span>{{service.unit}}</span>
                    </p>
                  </div>
                  <div class="column">
                    <p>
                      <strong>Tipo servicio: </strong>
                      <span>{{service.sdt_service_type}}</span>
                    </p>
                  </div>
                </div>
                <div class="columns" >
                  <div class="column">
                    <p>
                      <strong>Nombre Jefe(a) de proyecto: </strong>
                      <span>{{service.projectManagment.person_name}}</span>
                    </p>
                  </div>
                  <div class="column">
                    <p>
                      <strong>Rut Jefe(a) de proyecto: </strong>
                      <span>{{service.projectManagment.run ? (service.projectManagment.run.rut || service.projectManagment.run.passport)  : (service.projectManagment.mail || service.projectManagment.email)}}</span>
                    </p>
                  </div>
                </div>
              </div>
          </div>
          
      </b-collapse>
    </div>
  </div>


  <b-tabs position="is-centered" type="is-toggle-rounded" >
    <b-tab-item
        label="Documentos del servicio"
        icon="file-alt">

        

        <div class="box">
          <h3 class="is-size-4 has-text-weight-bold has-text-centered">Búsqueda de Documentos de Servicio</h3>
          <br>
            <div class="columns" >

              <div class="column" v-if=" procesos.length>0">
                <b-field label="Proceso">
                  <multiselect v-model="proceso"
                               :options="procesos.map(type => type.id)"
                               :custom-label="opt => procesos.find(x => x.id == opt).name"
                               placeholder="Seleccione Proceso"
                               selectLabel=""
                               selectedLabel=""
                               deselectLabel=""
                               @select="selectProcess"
                               :allow-empty="false">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>

              <div class="column" v-if=" procesos.length>0">
                <b-field label="Tipo de documento">
                    <multiselect v-model="tipo"
                                 :options="tipos.map(type => type.id)"
                                 :custom-label="opt => tipos.find(x => x.id == opt).name"
                                 placeholder="Seleccione Tipo documento"
                                 selectLabel=""
                                 selectedLabel=""
                                 :disabled="isDisabled"
                                 deselectLabel=""
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                          No se encontraron elementos.
                      </span>
                    </multiselect>
                </b-field>
              </div>      
              
            </div>
            <div class="columns" >

              


              <div class="column">
                <b-field label="Fecha del documento" >
                  <b-datepicker
                      v-model="year"
                      type="month"
                      placeholder="Formato de Fecha: mm/aaaa"
                      icon="calendar-alt"
                      editable
                      locale="es-ES"
                      trap-focus>
                  </b-datepicker>
                </b-field>
              </div>
              <div class="column has-text-right">
                <br>
                <b-button
                          class="button right-button button-clear-filter"
                          icon-left="eraser"
                          @click="clearFilter()">
                  Limpiar Filtros
                </b-button>
                <b-button type="is-info"
                          icon-left="search"
                          class="button right-button is-info"

                          @click="updateDocumentsServiceFilter()">
                  Buscar
                </b-button>

              </div>

            </div>
          <br>
          <div class="has-text-right">
            <b-button v-if="service.can_create_document"
                      icon-left="plus"
                      type="is-primary"
                      @click="newDocument('service')">
              Agregar Documento
            </b-button>
          </div>
          <br>
          <div class="box">
            <h3 class="is-size-4 has-text-weight-bold has-text-centered">Tabla de Documentos del Servicio</h3>
            
            <b-table
              :data="documentos"
              :paginated=true
              per-page=10
              striped
              hoverable
              :current-page="currentPage"
              :card-layout="cardLayout"
              :pagination-simple=false
              pagination-position="bottom"
              default-sort-direction="desc"
              sort-icon="arrow-up"
              sort-icon-size="is-small"
              default-sort="document_date"
              aria-next-label="Siguiente"
              aria-previous-label="Anterior"
              aria-page-label="Página"
              aria-current-label="Página actual">
            <b-table-column
                field= 'origin_document_type.name'
                label= 'Proceso'
                width="10em"
                searchable
                sortable>
              <template
                  slot="searchable"
                  slot-scope="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    icon="search"
                    size="is-small" />
              </template>
              <template v-slot="props">
                {{  props.row.origin_document_type.name || " " }}
              </template>
            </b-table-column>

            <b-table-column
                field= 'document_type.name'
                label= 'Tipo documento'
                width="15em"
                searchable
                sortable>
              <template
                  slot="searchable"
                  slot-scope="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    icon="search"
                    size="is-small" />
              </template>
              <template v-slot="props">
                {{  props.row.document_type.name || " " }}
              </template>
            </b-table-column>
            <b-table-column
                field= 'document_date'
                label= 'Fecha del documento'
                width="10em"
                :custom-search="searchDocumentDate"
                searchable
                sortable>
              <template v-slot="props">
                {{ props.row.document_date._isValid ? new Date(props.row.document_date).toLocaleDateString("es-CL"): '' }}
              </template>
            </b-table-column>
            

            <b-table-column
              label="Datos"
              width="10em"
              v-slot="props">
              <b-button type="is-primary"
                          icon-left="eye"

                          @click="showDocument(props.row,'service')">
              </b-button>
              <b-button type="is-info is-light is-outlined"
                        icon-left="pen"
                        v-if="service.can_edit_document"
                          @click="editDocument(props.row,'service')">
              </b-button>
              
              <b-button
                type="is-danger is-light is-outlined"
                icon-left = "ban"
                v-if="service.can_nullify_document"
                @click.native="execNullifyModalService(props.row.id, props.row)">
              </b-button>
            </b-table-column>

            <b-table-column
                v-if="service.can_show_document"
                label="Documentación"
                width="10em"
                v-slot="props">              

              <b-button type="is-primary is-light is-outlined"
                        icon-left="download"
                        @click="dowloadDocument(props.row)">
              </b-button>
              <b-button type="is-success is-light is-outlined"
                        @click="showPdf(props.row)">
                        <span class="icon" style="padding-bottom: 1px">
                          <span class="fa-stack fa-sm">
                            <i class="fas fa-file fa-stack-2x custom-size-icon-behind"></i>
                            <i class="fas fa-eye fa-stack-1x custom-size-icon-front has-text-white"></i>
                          </span>
                        </span>
              </b-button>
            </b-table-column>

            <template slot="empty" slot-scope="props">
              <div class="columns is-centered">
                <div class="column is-6 is-centered has-text-centered">
                  <b-message type="is-danger">
                    No se encontraron documentos asociados.
                  </b-message>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </b-tab-item>
    <b-tab-item label="Documentos personal contratado" icon="users">
      <div class="box">
        <h3 class="is-size-4 has-text-weight-bold has-text-centered">Búsqueda de Documentos del Personal Contratado</h3>
        <br>
        <div class="columns" >

          <div class="column" v-if=" procesosPerson.length>0">
            <b-field label="Proceso">
              <multiselect v-model="procesoPerson"
                           :options="procesosPerson.map(type => type.id)"
                           :custom-label="opt => procesosPerson.find(x => x.id == opt).name"
                           placeholder="Seleccione Proceso"
                           selectLabel=""
                           selectedLabel=""
                           deselectLabel=""
                           @select="selectProcessPerson"
                           :allow-empty="false">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                    No se encontraron elementos.
                </span>
              </multiselect>
            </b-field>
          </div>

          <div class="column" v-if=" procesosPerson.length>0">
            <b-field label="Tipo de documento">
                <multiselect v-model="tipoPerson"
                             :options="tiposPerson.map(type => type.id)"
                             :custom-label="opt => tiposPerson.find(x => x.id == opt).name"
                             placeholder="Seleccione Tipo documento"
                             selectLabel=""
                             selectedLabel=""
                             :disabled="isDisabledPerson"
                             deselectLabel=""
                             :allow-empty="false">
                  <template v-slot:noOptions>
                    No existen datos
                  </template>
                  <span slot="noResult">
                      No se encontraron elementos.
                  </span>
                </multiselect>
            </b-field>
          </div>      
          
        </div>
        <div class="columns" >
          <div class="column">
            <b-field label="Fecha del documento" >
              <b-datepicker
                  v-model="yearPerson"
                  type="month"
                  placeholder="Formato de Fecha: mm/aaaa"
                  icon="calendar-alt"
                  editable
                  locale="es-ES"
                  trap-focus>
              </b-datepicker>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Persona">
                  <multiselect 
                      v-model="integrante" 
                      :options="integrants" 
                      :custom-label="nameWithLang" 
                      placeholder="Seleccione Persona" 
                      track-by="name"
                      selectLabel=""
                      selectedLabel=""
                      deselectLabel=""
                      :allow-empty="true">
                      </multiselect>
            </b-field>
            
          </div>

          <div class="column has-text-right">
            <br>
              <b-button
                        class="button right-button button-clear-filter"
                        icon-left="eraser"
                        @click="clearFilterPerson()">
                Limpiar Filtros
              </b-button>
              <b-button type="is-info"
                        icon-left="search"
                        class="button right-button is-info"

                        @click="updateDocumentsPersonFilter()">
                Buscar
              </b-button>

          </div>
        </div>
        <br>
        <div class="has-text-right">
          <b-button v-if="service.can_create_document"
                    icon-left="plus"
                    type="is-primary"
                    @click="newDocument('person')">
            Agregar Documento a persona
          </b-button>
        </div>
        <br>
        <div class="box">
          <h3 class="is-size-4 has-text-weight-bold has-text-centered">Tabla de Documentos del Personal Contratado</h3>
          <b-table
            :data="documentospersona"
            :paginated=true
            per-page=10
            striped
            hoverable
            :current-page="currentPage2"
            :card-layout="cardLayout"
            :pagination-simple=false
            pagination-position="bottom"
            default-sort-direction="desc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            default-sort="document_date"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual">
          <b-table-column
              field= 'owner_name'
              label= 'Nombre persona'
              width="10em"
              searchable
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{  props.row.owner_name }}
            </template>
          </b-table-column>
          <b-table-column
              field= 'owner_run'
              label= 'Rut persona'
              width="8em"
              searchable
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{  props.row.owner_run }}
            </template>
          </b-table-column>
          <b-table-column
              field= 'origin_document_type.name'
              label= 'Proceso'
              searchable
              width="10em"
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{  props.row.origin_document_type.name || " " }}
            </template>
          </b-table-column>

          <b-table-column
              field= 'document_type.name'
              label= 'Tipo documento'
              width="18em"
              searchable
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{  props.row.document_type.name || " " }}
            </template>
          </b-table-column>
          <b-table-column
              field= 'document_date'
              label= 'Fecha del documento'
              width="10em"
              :custom-search="searchDocumentDate"
              searchable
              sortable>
            <template v-slot="props">
              {{ props.row.document_date._isValid ? new Date(props.row.document_date).toLocaleDateString("es-CL"): '' }}
            </template>
          </b-table-column>
          
          <b-table-column
                label="Datos"
                width="13em"
                v-slot="props">

                <b-button type="is-primary"
                      icon-left="eye"
                      @click="showDocument(props.row,'person')">
                </b-button>

                <b-button type="is-info is-light is-outlined"                
                            icon-left="pen"
                            v-if="service.can_edit_document"
                            @click="editDocument(props.row,'person')">
                </b-button>
                
                <b-button
                  type="is-danger is-light is-outlined"
                  icon-left = "ban"
                  v-if="service.can_nullify_document"
                  @click.native="execNullifyModalPerson(props.row.id, props.row)">
                </b-button>
            </b-table-column>
            <b-table-column
              v-if="service.can_show_document"
              label="Documentación"
              width="10em"
              v-slot="props">

            <b-button type="is-primary is-light is-outlined"
                      icon-left="download"
                      @click="dowloadDocument(props.row)">
            </b-button>

            <b-button type="is-success is-light is-outlined"
                      @click="showPdf(props.row)">
                      <span class="icon" style="padding-bottom: 1px">
                          <span class="fa-stack fa-sm">
                          <i class="fas fa-file fa-stack-2x custom-size-icon-behind"></i>
                          <i class="fas fa-eye fa-stack-1x custom-size-icon-front has-text-white"></i>
                        </span>
                      </span>
            </b-button>
          </b-table-column>

          <template slot="empty" slot-scope="props">
            <div class="columns is-centered">
              <div class="column is-6 is-centered has-text-centered">
                <b-message type="is-danger">
                  No se encontraron documentos asociados.
                </b-message>
              </div>
            </div>
          </template>
        </b-table>
        </div>
      </div>
      </b-tab-item>
      <b-tab-item
        label="Documentos anulados"
        icon="ban"
        v-if="service.can_show_nullify_document"
        >

        <div class="box">
        <h3 class="is-size-4 has-text-weight-bold has-text-centered">Búsqueda de Documentos Anulados</h3>
        <br>
        <div class="columns" >

          <div class="column" v-if=" procesos.length>0">
            <b-field label="Proceso">
              <multiselect v-model="procesoNullify"
                           :options="procesos.map(type => type.id)"
                           :custom-label="opt => procesos.find(x => x.id == opt).name"
                           placeholder="Seleccione Proceso"
                           selectLabel=""
                           selectedLabel=""
                           deselectLabel=""
                           @select="selectProcessNullify"
                           :allow-empty="false">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                    No se encontraron elementos.
                </span>
              </multiselect>
            </b-field>
          </div>

          <div class="column" v-if=" procesos.length>0">
            <b-field label="Tipo de documento">
                <multiselect v-model="tipoNullify"
                             :options="tiposNullify.map(type => type.id)"
                             :custom-label="opt => tiposNullify.find(x => x.id == opt).name"
                             placeholder="Seleccione Tipo documento"
                             selectLabel=""
                             selectedLabel=""
                             :disabled="isDisabledNullify"
                             deselectLabel=""
                             :allow-empty="false">
                  <template v-slot:noOptions>
                    No existen datos
                  </template>
                  <span slot="noResult">
                      No se encontraron elementos.
                  </span>
                </multiselect>
            </b-field>
          </div>      
          
        </div>
        <div class="columns" >
          <div class="column">
            <b-field label="Fecha del documento" >
              <b-datepicker
                  v-model="yearNullify"
                  type="month"
                  placeholder="Formato de Fecha: mm/aaaa"
                  icon="calendar-alt"
                  editable
                  locale="es-ES"
                  trap-focus>
              </b-datepicker>
            </b-field>
          </div>

          <div class="column has-text-right">
            <br>
              <b-button
                        class="button right-button button-clear-filter"
                        icon-left="eraser"
                        @click="clearFilterNullify()">
                Limpiar Filtros
              </b-button>
              <b-button type="is-info"
                        icon-left="search"
                        class="button right-button is-info"

                        @click="updateDocumentsNullifyFilter()">
                Buscar
              </b-button>

          </div>
        </div>
        
        <div class="box">
          <h3 class="is-size-4 has-text-weight-bold has-text-centered">Tabla de Documentos Anulados</h3>
          <b-table
            :data="documentosAnulados"
            :paginated=true
            per-page=10
            striped
            hoverable
            :current-page="currentPage3"
            :pagination-simple=false
            pagination-position="bottom"
            default-sort-direction="desc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            default-sort="document_date"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual">
          <b-table-column
              field= 'owner_name'
              label= 'Nombre persona'
              width="10em"
              searchable
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{  props.row.owner_name || "Servicio"}}
            </template>
          </b-table-column>
          <b-table-column
              field= 'owner_run'
              label= 'Rut persona'
              width="8em"
              searchable
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{  props.row.owner_run || "---"}}
            </template>
          </b-table-column>
          <b-table-column
              field= 'origin_document_type.name'
              label= 'Proceso'
              searchable
              width="10em"
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{  props.row.origin_document_type.name || " " }}
            </template>
          </b-table-column>

          <b-table-column
              field= 'document_type.name'
              label= 'Tipo documento'
              width="20em"
              searchable
              sortable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{  props.row.document_type.name || " " }}
            </template>
          </b-table-column>
          <b-table-column
              field= 'document_date'
              label= 'Fecha del documento'
              :custom-search="searchDocumentDate"
              searchable
              width="10em"
              sortable>
            <template v-slot="props">
              {{ props.row.document_date._isValid ? new Date(props.row.document_date).toLocaleDateString("es-CL"): '' }}
            </template>
          </b-table-column>
          <b-table-column
                label="Acciones"
                width="5em"
                v-if="service.academic_activity_state_id == 1 && service.can_restore_document"
                v-slot="props">

                <b-button type="is-success"
                      icon-left="trash-restore"

                      @click="execRestoreModal(props.row)">
                </b-button> 

            </b-table-column>

          <template slot="empty" slot-scope="props">
            <div class="columns is-centered">
              <div class="column is-6 is-centered has-text-centered">
                <b-message type="is-danger">
                  No se encontraron documentos asociados.
                </b-message>
              </div>
            </div>
          </template>
        </b-table>
        </div>
      </div>

      </b-tab-item>
    </b-tabs>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>


    <b-modal
        v-model="isComponentModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        width="100%"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-document  :service-id="service.id"
                         :service-code="service.code"
                         :integrantes="integrants"
                         :service-project-managment="service.projectManagment"
                         :service-table="serviceTable"
                         :query-type = "queryType"
                         :document = "documentId"
                         style="width: 90vw"
                         :modal-header="tituloModal"
                         :isServiceCreated="true"
                         :type-document="type"
                         @close="props.close"
                         @clicked="onClickChildDocument"
                         @clickedPerson="onClickChildDocumentPerson"
                         @clickedUpdate="onClickChildUpdateDocument"
                         @clickedUpdatePerson="onClickChildDocumentUpdatePerson"
        >
        </modal-document>
      </template>
    </b-modal>

    <b-modal
        v-model="isComponentModalPDF"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        width="100%"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-show-pdf   :src="src"
                          style="width: 90vw"
                          @close="props.close"
                         
        >
        </modal-show-pdf>
      </template>
    </b-modal>
  </section>
</template>

<script>
import axios from "axios";
import modalDocument from "./ModalUploadDocument.vue";
import modalShowPdf from "./ModalShowPDF.vue";
import {searchDate} from "../../packs/utilities";
import moment from "moment";
export default {
  name: "ShowDocumentsTab",
  props:[ 'participants', 'service', 'cargoPrincipal', 'canEdit', 'userId','serviceTable','integrants'],
  components:{modalDocument, modalShowPdf},
  data(){
    return{
      isLoading:false,
      currentPage:1,
      currentPage2:1,
      currentPage3:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      queryType: null,
      documentId: null,
      tituloModal: "",
      year: null,
      yearPerson: null,
      yearNullify: null,
      documentos: [],
      documentospersona: [],
      documentosAnulados: [],
      tipos: [],
      tipo: null,
      procesos: [],
      proceso: null,
      tiposPerson: [],
      tipoPerson: null,
      tiposNullify: [],
      tipoNullify: null,
      procesosPerson: [],
      procesoPerson: null,
      procesoNullify: null,
      type:null,
      isComponentModalActive:false,
      isComponentModalPDF: false,
      isDisabled: true,
      isDisabledPerson: true,
      isDisabledNullify: true,
      canCancel: ['escape'],
      src: null,
      isOpenService: false,
      integrante: null,
    }
  },
  async created(){
    let vm = this;
    vm.isLoading=true;
    await vm.getProcesos();
    await vm.getProcesosPerson();
    if (vm.service.can_show_nullify_document=="true"){
      await vm.getDocumentAnulados();
    }
    return axios.get("/documents/get-document-sdt.json",{params:{
            id: vm.service.id,
            table: vm.serviceTable
        }})
      .then(res => {
        vm.documentos = res.data.services;
        vm.documentospersona = res.data.persons;
        vm.documentos = vm.documentos.map(documento => {
          var newDocumento = documento
          newDocumento["document_date"] = moment(documento.document_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
          return newDocumento;
        });   

        
        vm.documentospersona = vm.documentospersona.map(documento => {
          var newDocumento = documento
          newDocumento["document_date"] = moment(documento.document_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
          return newDocumento;
        });


        vm.isLoading=false;
      })
      .catch(e => {
        vm.isLoading=false;
        //console.log(e);
      });

  },
  methods:{
    nameWithLang ({ name, person_name, run }) { 
      let msg_run = ""
      if (run != null){
        if (run.passport != null){
          msg_run = " ["+run.passport+"]" 
        }
        if (run.rut != null){
          msg_run = " ["+run.rut.toString()+"-"+run.dv+"]"
        }
      }
      let msj = ""
          
      if (name != null )
      {
        msj = name + msg_run 
      }
      else{
        msj = person_name + msg_run 
      }
      return msj
    },
    newDocument(type){
      this.type = type
      this.tituloModal = "Agregar Documento"
      this.queryType = "create"
      this.isComponentModalActive = true;
    },
    editDocument(row, type){
      this.type = type
      this.tituloModal = "Editar Documento"
      this.queryType = "update"
      this.documentId = row
      this.isComponentModalActive = true;
    },
    showDocument(row, type){
      this.type = type
      this.tituloModal = "Ver Documento"
      this.queryType = "show"
      this.documentId = row
      this.isComponentModalActive = true;
    },    
    onClickChildDocument(value){
      this.isLoading = true
      value.fecha = moment(value.fecha, ["YYYY-MM-DD","DD-MM-YYYY"]);
      this.documentos.push(value)
      this.isLoading = false
    },
    onClickChildDocumentPerson(value){
      this.isLoading = true
      value.fecha = moment(value.fecha, ["YYYY-MM-DD","DD-MM-YYYY"]);
      this.documentospersona.push(value)
      this.isLoading = false
    },
    onClickChildUpdateDocument(value){
      this.isLoading = true
      this.documentos = this.documentos.filter(elemento => elemento.id !== this.documentId.id);
      value.fecha = moment(value.fecha, ["YYYY-MM-DD","DD-MM-YYYY"]);
      this.documentos.push(value)
      this.isLoading = false
    },
    onClickChildDocumentUpdatePerson(value){
      this.isLoading = true
      this.documentospersona = this.documentospersona.filter(elemento => elemento.id !== this.documentId.id);
      value.fecha = moment(value.fecha, ["YYYY-MM-DD","DD-MM-YYYY"]);
      this.documentospersona.push(value)
      this.isLoading = false
    },

    clearFilter(){
      this.proceso = null;
      this.tipos = [];
      this.tipo = null;
      this.year = null;
      this.isDisabled = true;        

    },

    clearFilterPerson(){
      this.procesoPerson = null;
      this.tiposPerson = [];
      this.tipoPerson = null;
      this.yearPerson = null;
      this.integrante = null;
      this.isDisabledPerson = true;      

    },
    clearFilterNullify(){
      this.procesoNullify = null;
      this.tiposNullify = [];
      this.tipoNullify = null;
      this.yearNullify = null;
      this.isDisabledNullify = true;      

    },
    async selectProcess(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/document_type_names/document-names.json",{params:{id:vm.proceso}})
        .then(res => {
          vm.tipos = [];
          vm.tipo = null;
          vm.tipos = res.data;
          vm.isDisabled = false;
          vm.isLoading = false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });

    },

    async selectProcessPerson(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/document_type_names/document-names.json",{params:{id:vm.procesoPerson}})
        .then(res => {
          vm.tiposPerson = [];
          vm.tipoPerson = null;
          vm.tiposPerson = res.data;
          vm.isDisabledPerson = false;
          vm.isLoading = false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });

    },
    async selectProcessNullify(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/document_type_names/document-names.json",{params:{id:vm.procesoNullify, all: true}})
        .then(res => {
          vm.tiposNullify = [];
          vm.tipoNullify = null;
          vm.tiposNullify = res.data;
          vm.isDisabledNullify = false;
          vm.isLoading = false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });

    },

    execNullifyModalPerson(document_id, row){
      this.$buefy.dialog.confirm({
        title: 'Anular Documento',
        message: '¿Está seguro que desea <b>anular</b> el documento? Esta acción lo dejará inválido.<br>',
        confirmText: 'Anular Documento',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/documents/" + document_id + "/nullify-document-sdt.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Documento anulado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })

                var index = this.documentospersona.indexOf(row);
                this.documentospersona.splice(index, 1);
                this.documentosAnulados.push(row);
                this.isLoading = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Documento no pudo ser anulado</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },
    execNullifyModalService(document_id, row){
      this.$buefy.dialog.confirm({
        title: 'Anular Documento',
        message: '¿Está seguro que desea <b>anular</b> el documento? Esta acción lo dejará inválido.<br>',
        confirmText: 'Anular Documento',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/documents/" + document_id + "/nullify-document.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Documento anulado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })

                var index = this.documentos.indexOf(row)
                this.documentos.splice(index, 1)
                this.documentosAnulados.push(row);
                this.isLoading = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Documento no pudo ser anulado</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },



    updateDocumentsServiceFilter(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/documents/get-document-sdt.json", {params:{
                                              year_f:vm.year,
                                              process:vm.proceso,
                                              type:vm.tipo,
                                              id: vm.service.id,
                                              table: vm.serviceTable
                                              }})
        .then(res => {
          vm.documentos = res.data.services;
          vm.documentos = vm.documentos.map(documento => {
            var newDocumento = documento
            newDocumento["document_date"] = moment(documento.document_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            return newDocumento;
          });
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    updateDocumentsPersonFilter(){
      let vm = this;
      vm.isLoading=true;
      let id_run = null;
      if (vm.integrante !=null){
        if (vm.integrante.run.rut != null){
          id_run = vm.integrante.run.id;
        }
        else if (vm.integrante.run.passport != null)
        {
          id_run = vm.integrante.run.id;
        }
      }
      return axios.get("/documents/get-document-sdt.json", {params:{
                                              year_f:vm.yearPerson,
                                              process:vm.procesoPerson,
                                              type:vm.tipoPerson,
                                              person: id_run,
                                              id: vm.service.id,
                                              table: vm.serviceTable
                                              }})
        .then(res => {
          vm.documentospersona = res.data.persons;
          vm.documentospersona = vm.documentospersona.map(documento => {
            var newDocumento = documento
            newDocumento["document_date"] = moment(documento.document_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            return newDocumento;
          });
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    searchDocumentDate(row,input){
      return searchDate(row.document_date,input)
    },

    updateDocumentsNullifyFilter(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/documents/get-nullify-document-sdt.json", {params:{
                                              year_f:vm.yearNullify,
                                              process:vm.procesoNullify,
                                              type:vm.tipoNullify,
                                              id: vm.service.id,
                                              table: vm.serviceTable
                                              }})
        .then(res => {
          vm.documentosAnulados = res.data.services;
          vm.documentosAnulados = vm.documentosAnulados.concat(res.data.persons);
          vm.documentosAnulados = vm.documentosAnulados.map(documento => {
            var newDocumento = documento
            newDocumento["document_date"] = moment(documento.document_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            return newDocumento;
          });
          vm.documentosAnulados.sort( (a,b) => b.document_date - a.document_date);
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },


    dowloadDocument(row){
      let vm = this;
      this.isLoading = true;
      axios.get("/documents/" + row.id + "/download", {
            responseType: "blob",
          })
      .then( response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', row.name);
        document.body.appendChild(link);
        link.click();
        vm.isLoading = false;
        this.$buefy.dialog.alert({
          message: 'Se descargo con exito.',
          type: 'is-success',
          hasIcon: true,
          icon: 'check-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      .catch(e =>{
        this.isLoading = false;
        this.$buefy.dialog.alert({
          message: 'Fallo al descargar.',
          type: 'is-danger',
        });
      })
    },

    showPdf(row){
      let vm = this;
      vm.isLoading = true;
      axios
          .get("/documents/" + row.id + "/download", {
            responseType: "blob",            
            params:{type: "pdf"},
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            vm.src = new Uint8Array(buffer);
            vm.isComponentModalPDF = true
            vm.isLoading = false
          })
          .catch(e => {
            vm.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    execRestoreModal(row){
        let vm = this;
        let ruta = ""
        vm.$buefy.dialog.confirm({
          title: 'Restaurar Documento',
          message: '¿Está seguro que desea <b>restaurar</b> el documento? Esta acción revertirá la anulación.',
          confirmText: 'Restaurar Documento',
          type: 'is-success',
          hasIcon: true,
          onConfirm: () => {
            vm.isLoading = true
            axios
                .put("/documents/"+row.id+"/restore-document.json")
                .then(res => {
                  vm.$buefy.dialog.alert({
                    message: 'Documento restaurado correctamente',
                    duration: 5000,
                    type: 'is-success',
                    canCancel: ["escape", "outside"]
                  })
                  var index = vm.documentosAnulados.indexOf(row);
                  vm.documentosAnulados.splice(index, 1);
                  if(row.owner_name.toLowerCase() != vm.service.projectManagment.person_name.toLowerCase()){
                    vm.documentospersona.push(row);
                  }
                  else{
                    vm.documentos.push(row);
                  }
                  vm.isLoading = false;
                }).catch(error => {
              console.log(error)
              vm.$buefy.dialog.alert({
                message: '<b>Documento no pudo ser restaurado</b><br>'+error.response.data,
                duration: 5000,
                type: 'is-danger',
                canCancel: ["escape", "outside"]
              })
              vm.isLoading = false;
            })
          }
        })
      },

    async getProcesos() {
      let vm = this;
      return axios.get("/origin_document_types/origin-document-sdt.json", {params:{type: "service"}})
        .then(res => {
          vm.procesos = res.data;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    async getProcesosPerson() {
      let vm = this;
      
      return axios.get("/origin_document_types/origin-document-sdt.json", {params:{type: "person"}})
        .then(res => {
          vm.procesosPerson   = res.data;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    async getDocumentAnulados(){
      let vm = this;
      
      return axios.get("/documents/get-nullify-document-sdt.json",{params:{
            id: vm.service.id,
            table: vm.serviceTable
        }})
      .then(res => {
        vm.documentosAnulados = res.data.services;
        vm.documentosAnulados = vm.documentosAnulados.concat(res.data.persons);
        vm.documentosAnulados = vm.documentosAnulados.map(documento => {
          var newDocumento = documento
          newDocumento["document_date"] = moment(documento.document_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
          return newDocumento;
        });
        vm.documentosAnulados.sort( (a,b) => b.document_date - a.document_date);
      })
      .catch(e => {
        vm.isLoading=false;
        //console.log(e);
      });
    }


  }

}
</script>

<style scoped>

  .right-button{
    margin-left: auto;
  }
  .left-button{
    margin-right: auto;
  }
  .custom-collapse {
    background-color: #F9F9F9 !important;
    border: 1px solid #000000;
  }
  .custom-header-title {
    font-size: 24px; 
    font-weight: bold; 
    text-align: center !important; 
    margin: 0; 
  }
  .custom-size-icon-behind{
    width: 0.9em; 
    height: 1em;
    margin-top: 0.1em;
  }
  .custom-size-icon-front{
    margin-top: 0.8em;
  }
  .fa-sm {
    font-size: 0.775em;
  }

</style>

