<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <form @submit.prevent="savePerson">
        <div class="modal-card" style="width: 1348px">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" ref="modalPersonForm">

            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion" :key='index'>
                      <div v-if="item">{{ item[0] }}</div>
                    </div>
                  </b-message>
                </div>
              </div>
            </div>

            <div class="is-centered" style="margin-top: 30px;" :hidden="!continueRegister">
              <div class="columns is-centered">
                <div class="box has-text-centered">
                  <template>
                    <b-button
                        outlined
                        type="is-primary"
                        icon-pack="fas"
                        icon-left="chevron-left"
                        :disabled="activeStep==0"
                        @click.prevent="back">
                    </b-button>
                    <b-button
                        outlined
                        type="is-primary"
                        icon-pack="fas"
                        icon-right="chevron-right"
                        :disabled="activeStep==4"
                        @click.prevent="next">
                    </b-button>
                  </template>
                </div>
              </div>
            </div>

            <b-steps :has-navigation="false" v-model="activeStep">
              <b-step-item label="Verificación de Rut" step="1" :clickable="true">
                <div class="is-centered" style="margin-top: 30px;">
                  <div class="title is-4 is-centered" style="text-align: center;">
                    Ingrese un Rut para validarlo
                  </div>
                  <div class="columns is-centered">
                  <div class="column is-4">
                    <b-field label="Rut*">
                        <b-input v-model="search_rut"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-2">
                    <b-field label="DV*">
                        <b-input v-model="search_dv"></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns is-centered">
                  <div class="control">
                    <b-button @click="search" class="is-primary" id="boton-buscar">Buscar</b-button>
                  </div>
                </div>
                  <div class="columns is-centered">
                    <section style="margin-top: 30px;">
                      <b-notification
                          :type="type"
                          aria-close-label="Close notification"
                          role="alert"
                          has-icon
                          v-if="show_found"
                          @close="closeFound">
                        {{message}}
                      </b-notification>
                    </section>
                  </div>

                  <div class="container" v-if="found && !continueRegister" style="margin-top: 40px">
                    <div class="is-centered">
                      <div class="title is-4 is-centered" style="text-align: center; margin-bottom: 40px;">
                        Datos Encontrados
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <b-field label="Nombres*">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <b-input v-model="person.name" type="text" disabled></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Primer Apellido*">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <b-input v-model="person.last_name" type="text" disabled></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Segundo Apellido*">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <b-input v-model="person.second_surname" type="text" disabled></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column is-3">
                        <b-field label="Rut*">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <b-input v-model="person.run_attributes.rut" disabled></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                      <div class="column is-1">
                        <b-field label="DV*">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <b-input v-model="person.run_attributes.dv" disabled></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Correo corporativo*">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <b-input v-model="person.corporate_mail" disabled></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <b-field >
                          <p class="control">
                            <b-button class="is-primary" icon-right="user" @click="goToLifeRecord(person_id)">Ir a Hoja de Vida</b-button>
                          </p>
                        </b-field>
                      </div>
                    </div>
                  </div>

                <div class="container" v-if="found && continueRegister" style="margin-top: 40px">
                  <div class="is-centered">
                    <div class="title is-4 is-centered" style="text-align: center; margin-bottom: 40px;">
                      Datos Encontrados
                    </div>
                    </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="Nombres*">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <b-input v-model="person.name" type="text" ></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Primer Apellido*">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <b-input v-model="person.last_name" type="text" ></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Segundo Apellido*">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <b-input v-model="person.second_surname" type="text" ></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-3">
                      <b-field label="Rut*">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <b-input v-model="person.run_attributes.rut" disabled></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div class="column is-1">
                      <b-field label="DV*">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <b-input v-model="person.run_attributes.dv" disabled></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Correo corporativo">
                        <ValidationProvider rules="email" v-slot="{ errors }">
                          <b-input v-model="person.corporate_mail" ></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    </div>
                </div>

                  <div class="container" v-if="!found && continueRegister" style="margin-top: 40px">
                    <div class="is-centered">
                      <div class="title is-4 is-centered" style="text-align: center; margin-bottom: 40px;">
                        Registro de Persona Nueva
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <b-field label="Nombres*">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <b-input v-model="person.name" type="text"></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Primer Apellido*">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <b-input v-model="person.last_name" type="text"></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Segundo Apellido*">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <b-input v-model="person.second_surname" type="text"></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column is-3">
                        <b-field label="Rut*">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <b-input v-model="person.run_attributes.rut" disabled></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                      <div class="column is-1">
                        <b-field label="DV*">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <b-input v-model="person.run_attributes.dv" disabled></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Correo corporativo*">
                          <ValidationProvider rules="email" v-slot="{ errors }">
                            <b-input v-model="person.corporate_mail"></b-input>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                    </div>
                  </div>

                </div>

              </b-step-item>
            <!-- Box de Formulario Persona -->
              <b-step-item label="Datos Personales" step="2" v-if="continueRegister" :clickable="true">
                <div class="container" style="margin-top: 40px">
              <div class="columns">
                <div class="column">
                  <b-field label="Fecha de nacimiento*">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <b-datepicker
                          v-model="person.birthday_date"
                          icon="calendar-alt"
                          trap-focus
                          locale="es-ES"
                          editable
                          placeholder="Formato de Fecha: dd/mm/aaaa"
                      >
                      </b-datepicker>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Género">
                    <multiselect v-model="person.gender_id"
                                 :options="genders.map(type => type.id)"
                                 :custom-label="opt => genders.find(x => x.id === opt).name"
                                 placeholder="Seleccione género"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 @input="fixCommunes()"
                                 deselectLabel="Presione para deseleccionar"
                                 :allow-empty="true">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Región">
                      <multiselect v-model="person.region_id"
                                   :options="regions.map(type => type.id)"
                                   :custom-label="opt => regions.find(x => x.id === opt).name"
                                   @input="loadCommunes(person.region_id)"
                                   placeholder="Seleccione Región"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="Presione para deseleccionar"
                                   :allow-empty="true">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                          No se encontraron elementos.
                        </span>
                      </multiselect>

                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Comuna">

                    <multiselect v-model="person.commune_id"
                                 :options="communes.map(type => type.id)"
                                 :custom-label="opt => communes.find(x => x.id === opt).name"
                                 placeholder="Seleccione Comuna"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 @input="fixCommunes(person.commune_id)"
                                 deselectLabel="Presione para deseleccionar"
                                 :allow-empty="true">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Dirección*">
                    <ValidationProvider rules="required" v-slot="{errors}">
                    <b-input v-model="person.address" maxlength="255"></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Nacionalidad">
                    <multiselect v-model="person.nationality_id"
                                 :options="countries.map(type => type.id)"
                                 :custom-label="opt => countries.find(x => x.id === opt).nationality"
                                 placeholder="Seleccione nacionalidad"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 @input="fixCommunes()"
                                 deselectLabel="Presione para deseleccionar"
                                 :allow-empty="true">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="País de origen">
                    <multiselect v-model="person.origin_country_id"
                                 :options="countries.map(type => type.id)"
                                 :custom-label="opt => countries.find(x => x.id === opt).name"
                                 placeholder="Seleccione nacionalidad"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 @input="fixCommunes()"
                                 deselectLabel="Presione para deseleccionar"
                                 :allow-empty="true">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Lugar de nacimiento">
                    <b-input v-model="person.birthplace"></b-input>
                  </b-field>
                </div>
              </div>
                </div>



              </b-step-item>
              <b-step-item label="Datos de Contacto" step="3" v-if="continueRegister" :clickable="true">
                <div class="container" style="margin-top: 40px">
                <div class="columns">
                  <div class="column">
                    <b-field label="Celular*">
                      <ValidationProvider rules="integer|positive|required" v-slot="{errors}" style="width: 100%">

                        <b-input v-model="person.cellphone" expanded></b-input>

                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Teléfono">
                      <ValidationProvider rules="integer|positive" v-slot="{errors}">
                        <b-input v-model="person.phone"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Email personal*">
                      <ValidationProvider rules="email|required" v-slot="{errors}">
                        <b-input type="text" v-model="person.personal_mail"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
                </div>
              </b-step-item>
              <b-step-item label="Datos Laborales" step="4" v-if="continueRegister" :clickable="true">
                <div class="container" style="margin-top: 40px">
                <div class="columns">
                  <div class="column">
                    <b-field label="Institución de previsión*">
                      <ValidationProvider rules="required" v-slot="{errors}">
                      <multiselect v-model="person.pension_institution_id"
                                   :options="pension_institutions.map(type => type.id)"
                                   :custom-label="opt => pension_institutions.find(x => x.id === opt).name"
                                   placeholder="Seleccione Institución de previsión"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="Presione para deseleccionar"
                                   :allow-empty="true">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                      </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Institución de salud*">
                      <ValidationProvider rules="required" v-slot="{errors}">
                      <multiselect v-model="person.health_institution_id"
                                   :options="health_institutions.map(type => type.id)"
                                   :custom-label="opt => health_institutions.find(x => x.id === opt).name"
                                   placeholder="Seleccione Institución de salud"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="Presione para deseleccionar"
                                   :allow-empty="true">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                      </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Fecha de ingreso USACH">
                      <ValidationProvider v-slot="{errors}">
                      <b-datepicker
                          v-model="person.university_entrance_date"
                          icon="calendar-alt"
                          trap-focus
                          locale="es-ES"
                          editable
                          placeholder="Formato de Fecha: dd/mm/aaaa"
                      >
                      </b-datepicker>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Fecha de ingreso administración pública">
                      <b-datepicker
                          v-model="person.public_entrance_date"
                          icon="calendar-alt"
                          trap-focus
                          locale="es-ES"
                          editable
                          placeholder="Formato de Fecha: dd/mm/aaaa"
                      >
                      </b-datepicker>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Estado civil*">
                      <ValidationProvider rules="required" v-slot="{errors}">
                      <multiselect v-model="person.civil_status"
                                   :options="marital_statuses.map(type => type.name)"
                                   placeholder="Seleccione Estado civil"
                                   selectLabel="Presione para seleccionar"
                                   selectedLabel="Seleccionado"
                                   deselectLabel="Presione para deseleccionar"
                                   :allow-empty="true">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                      </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="¿Jubilado(a)?*">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <div class="columns">
                          <div class="column">
                            <b-radio-button v-model="person.retired"
                                            :native-value="false"
                                            type="is-danger is-light is-outlined"

                            >
                              <b-icon icon="times-circle"></b-icon>
                              <span>No</span>
                            </b-radio-button>
                          </div>
                          <div class="column">
                            <b-radio-button v-model="person.retired"
                                            :native-value="true"
                                            type="is-success is-light is-outlined"
                            >
                              <b-icon icon="check-circle"></b-icon>
                              <span>Si</span>
                            </b-radio-button>
                          </div>
                        </div>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="¿Rinde fianza?*">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <div class="columns">
                        <div class="column">
                          <b-radio-button v-model="person.pays_bail"
                                      :native-value="false"
                                      type="is-danger is-light is-outlined"
                      >
                        <b-icon icon="times-circle"></b-icon>
                        <span>No</span>
                      </b-radio-button>
                        </div>
                    <div class="column">
                      <b-radio-button v-model="person.pays_bail"
                                      :native-value="true"
                                      type="is-success is-light is-outlined"
                      >
                        <b-icon icon="check-circle"></b-icon>
                        <span>Si</span>
                      </b-radio-button>
                    </div>
                        </div>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
<!--                <div class="columns">
                  <div class="column">
                    <b-field label="Url carpeta documentación Hoja de vida">
                      <ValidationProvider v-slot="{ errors }" rules="max:255">
                        <b-input v-model="person.life_record_link" type="text"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>-->
                </div>
              </b-step-item>
              <b-step-item label="Documentación de ingreso" step="5" v-if="continueRegister" :clickable="true">
                <div class="container" style="margin-top: 40px">
                  <div class="columns has-text-centered">
                    <div class="column">
                      <b-field extended label="Archivo PDF (Tamaño max. 25 MB)">
                        <b-upload v-model="files"
                                  drag-drop
                                  multiple
                                  type="is-primary"
                                  accept=".pdf"
                                  @input="processFiles">
                          <section class="section">
                            <div class="content has-text-centered">
                              <p>
                                <b-icon
                                    icon="file-pdf"
                                    size="is-large">
                                </b-icon>
                              </p>
                              <p>Arrastra tus archivos o haz click para subir.</p>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>
                    </div>
                  </div>
                  <b-table :data="process_files"
                           :paginated= true
                           per-page="10"
                           striped
                           hoverable
                           :current-page="currentPage"
                           :pagination-simple=false
                           pagination-position="bottom"
                           default-sort-direction="asc"
                           sort-icon="arrow-up"
                           sort-icon-size="is-small"
                           aria-next-label="Siguiente"
                           aria-previous-label="Anterior"
                           aria-page-label="Página"
                           aria-current-label="Página actual">
                    <b-table-column field="name"
                                    label="Archivo"
                                    sortable
                                    searchable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.name}}
                      </template>
                    </b-table-column>
                    <b-table-column label="Tipo de documento"
                                    v-slot="props"
                                    width="400">
                      <multiselect v-model="props.row.document_type_id" :options="document_types.map(x => x.id)"
                                   :custom-label="opt => document_types.find(x => x.id == opt).name"
                                   placeholder="Seleccione tipo de documento"
                                   selectLabel=""
                                   selectedLabel="Seleccionado"
                                   deselectLabel=""
                                   :allow-empty="false"
                                   @input="changeName(props.index)">
                        <template v-slot:noOptions>
                          No existen datos
                        </template>
                        <span slot="noResult">
                            No se encontraron elementos.
                          </span>
                      </multiselect>

                    </b-table-column>
                    <b-table-column field="document_name"
                                    label="Nombre Final Documento"
                                    sortable
                                    searchable
                                    width="400">
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.document_name}}
                      </template>
                    </b-table-column>
                    <b-table-column label="Acciones"
                                    v-slot="props">
                      <b-tooltip
                          label="Quitar archivo."
                          position="is-left"
                          :active="true">
                        <b-button
                            pack="fas"
                            icon-left="trash"
                            type="is-danger is-light is-outlined"
                            @click="deleteDropFile(props.index)"/>
                      </b-tooltip>

                    </b-table-column>
                  </b-table>
                </div>
              </b-step-item>
            </b-steps>
            <!-- GUARDAR PERSONA-->
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="actions has-text-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit" :disabled="(!continueRegister||activeStep!=4)">Guardar Persona</button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios"
export default {
  name: "modal_person_form",
  data(){
    const data =[]
    return {
      person:{
        run_attributes:{
          rut: null,
          dv: null
        },
        region_id: null,
        is_registered: true
      },
      regions: [],
      genders: [],
      communes: [],
      countries: [],
      marital_statuses: [
        {name: "Soltero(a)" },
        {name: "Casado(a)" },
        {name: "Viudo(a)" },
        {name: "Divorciado(a)" },
        {name: "Conviviente civil" }
      ],
      pension_institutions: [],
      health_institutions: [],
      errores_validacion: {},
      life_record_link:'',
      isLoading:false,
      search_rut: "",
      search_dv: "",
      busqueda: "",
      searchInvalid: false,
      message: "",
      type: "",
      found: false,
      show_found: false,
      continueRegister: false,
      saveActivated: false,
      foundPerson: {},
      run_attributes: {rut: null, dv: null},
      newPerson: false,
      activeStep: 0,
      atras_desactivado: false,
      adelante_desactivado: false,
      person_id: null,
      cellphone: null,
      files:[],
      process_files:[],
      currentPage: 1,
      document_types: [],
    }
  },
  props:[
    'userId', 'queryType', 'personId', 'modalHeader'
  ],
  created(){
    this.isLoading=true;
    if (this.queryType ==="create"){
      axios
        .get("/people/new.json")
        .then(res => {
          this.regions = res.data["regions"]
          this.genders = res.data["genders"]
          this.countries = res.data["countries"]
          this.pension_institutions = res.data["pension_institutions"]
          this.health_institutions = res.data["health_institutions"]
          this.life_record_link = res.data["life_record_link"]
          this.document_types = res.data["document_types"]
          this.isLoading = false;
        }).catch(error => {
          console.log(error)
          this.isLoading=false;
        })
    }
    else if (this.queryType === "update"){
      this.isLoading=true;
      axios
          .get("/people/"+this.person_id+"/edit.json")
          .then(res => {
            this.person = res.data
            if (res.data["birthday_date"] !== null) {
              var date_without_format = Date.parse(res.data["birthday_date"])
              this.person.birthday_date = new Date(date_without_format)
            }
            if (res.data["university_entrance_date"] !== null) {
              this.person.university_entrance_date = new Date(this.formatDate(res.data["university_entrance_date"]))
            }

            this.regions = res.data["regions"]
            this.genders = res.data["genders"]
            this.countries = res.data["countries"]
            this.pension_institutions = res.data["pension_institutions"]
            this.health_institutions = res.data["health_institutions"]
            this.life_record_link = res.data["life_record_link"]
            if (res.data["communes"] != null) {
              this.communes = res.data["communes"]
            }
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })
    }
  },
  methods: {
    goToLifeRecord(person_id){
      window.location.href = '/people/'+person_id+"/life_record"
    },
    next(){
      if(this.activeStep < 4){
        this.activeStep = this.activeStep +1
      }

    },
    back(){
      if(this.activeStep > 0){
        this.activeStep = this.activeStep -1
      }
    },
    close(){
      this.searchInvalid=false
    },
    closeFound(){
      this.show_found=false
    },
    search(){
      this.files = []
      this.process_files = []
      var query = ""+this.search_rut+"-"+this.search_dv
      if(query.length<5|| this.search_rut==="" || this.search_dv===""){
        this.show_found=true;
        this.message="Búsqueda inválida, ingrese nuevamente el rut"
        this.type="is-danger is-light"
        this.continueRegister = false;
        this.found = false;
        return
      }
      this.isLoading=true;
      return axios
          .get("/people/"+query+"/search_by_rut.json"
          ).then(res => {

            this.searchInvalid=res.data["invalid"];
            this.message=res.data["message"]
            this.type=res.data["type"]
            this.show_found = res.data["show_found"]
            this.continueRegister = res.data["continue"]
            this.found = res.data["found"]
            this.newPerson = res.data["new"]
            if(res.data["person"] !== null){
              this.person.name = res.data["person"].name
              this.person.last_name = res.data["person"].last_name
              this.person.second_surname = res.data["person"].second_surname
              this.person.corporate_mail = res.data["person"].corporate_mail
              this.person.run_attributes = res.data["run_attributes"]
              this.person_id = res.data["person"].id
              //SE SETEAN EL RESTO DE DATOS DE LA PERSONA
              this.person.commune_id = res.data["person"].commune_id
              this.person.region_id = res.data["region_id"]
              this.person.personal_mail = res.data["person"].personal_mail
              this.communes = res.data["communes"]
              this.person.address = res.data["person"].address
              this.person.phone = res.data["person"].phone
              this.person.birthplace = res.data["person"].birthplace
              this.person.nationality_id = res.data["person"].nationality_id
              this.person.origin_country_id = res.data["person"].origin_country_id
              this.person.gender_id = res.data["person"].gender_id
              if (res.data["person"].birthday_date !== null) {
                var date_without_format = Date.parse(res.data["person"].birthday_date)
                this.person.birthday_date = new Date(date_without_format)
              }
            }
            else{
              Object.keys(this.person).forEach((i) => this.person[i] = null);
              this.person.is_registered = true
              this.person_id = null
              this.person.run_attributes = res.data["run_attributes"]
            }
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })
    },
    async savePerson(){
      const isValid = await this.$refs.observer.validate();
      if (!isValid){
        return this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Se encontraron errores, revisar formato y datos requeridos en formulario',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

      }
      this.isLoading=true;


      if (this.queryType==="create" && this.newPerson){

        // Crea un objeto FormData
        let formData = new FormData();

        // Agrega datos al objeto FormData
        formData.append("person", JSON.stringify(this.person));
        this.files.forEach((file, index) => {
          formData.append(`file[${index}]`, file);
        });
        formData.append("process_files", JSON.stringify(this.process_files));

        await axios
            .post("/people/create-person-document.json", formData,{
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(response =>{
              this.errores_validacion = {};
              window.location.href = '/people/'+response.data["id"]+"/life_record"
              this.isLoading=false;
            }).catch(error => {
              this.errores_validacion = error.response.data
              var elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              this.$refs.modalPersonForm.scrollTop = 0;
              this.isLoading=false;
            })
      }
      else if (!this.newPerson){

        let clean_person = this.person
        clean_person = this.deleteData(clean_person)
        axios
            .put("/people/" + this.person_id+".json", {
              person:  clean_person
            })
            .then(response => {
              this.errores_validacion = {};
              window.location.href = "/people/"+this.person_id+"/life_record"
              this.isLoading = false;
            }).catch(error => {
              this.errores_validacion = error.response.data
              var elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              this.$refs.modalPersonForm.scrollTop = 0;
              this.isLoading=false;
            })
      }
    },
    loadCommunes(region_id){
      if( region_id!==null && region_id!==undefined && region_id!==""){
        this.isLoading=true;
        axios
            .get("/regions/"+region_id+"/communes.json")
            .then(res => {
              this.person.commune_id = null;
              this.communes = res.data["communes"];
              this.isLoading = false;
            })
            .catch(error => {
              console.log(error)
              this.isLoading=false;
            })
      }
      else{
        this.person.commune_id = null
        this.communes = []
      }
    },
    //Esta funcion solo es para mostrar las comunas selecionadas
    fixCommunes(commune){
      this.isLoading = true
      this.isLoading = false
    },
    deleteData(person){
      delete person.communes
      delete person.countries
      delete person.health_institutions
      delete person.pension_institutions
      delete person.genders
      delete person.regions
      delete person.id
      return person
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
    deleteDropFile(index) {
      this.process_files.splice(index, 1)
      this.files.splice(index, 1)
    },
    processFiles(){
      var process_files_aux = this.process_files
      var document_type_id = this.document_types.find(x => x.name ===  'OTROS').id
      var new_files = this.files.slice(this.process_files.length);
      var rut = this.person.run_attributes.rut.toString() + "_" + this.person.run_attributes.dv
      var file_name = "DOCUMENTO_INGRESO"
      new_files.forEach(function(file){
        file_name = "DOCUMENTO_INGRESO"
        file_name = file_name+"-"+rut+"-"+file.name
        process_files_aux.push({
          name:file.name,
          document_type_id: document_type_id,
          document_name: file_name,
          file:file
        })
      })
      this.process_files = process_files_aux
    },
    changeName(index) {
      var tipo = "DOCUMENTO_INGRESO"
      var rut = "-RUT_"+this.person.run_attributes.rut.toString() + "_" + this.person.run_attributes.dv
      var file_name = "-"+this.process_files[index].name
      var document_type_id = this.process_files[index].document_type_id
      var find_document_type = this.document_types.find(x => x.id ===  document_type_id)
      var document_name = ''
      if (find_document_type.name != "OTROS"){
        tipo = find_document_type.initials
        file_name = ''
      }
      document_name = tipo+rut+file_name
      this.process_files[index].document_name = document_name
    },
  }
}
</script>

<style scoped>

</style>