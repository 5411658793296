<template>
  <div>
    <br>
    <div class="container" style="border-radius: 15px">
      <div class="columns is-centered">
        <div class="column box">
          <div class="title is-4">
            Producto
          </div>
          <div class="has-text-right">
            <b-button type="is-info"
                      icon-left="plus"
                      @click="editProduct(productId)">
              Editar Producto
            </b-button>
        </div>
          <hr class="dropdown-divider" aria-role="menuitem">
          <div class="columns">
            <div class="column">
              <b-field label="Título">
                <b-input v-model="product.title" type="text" readonly></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Tipo de Producto">
                <b-input v-model="product.product_type_attributes.name" type="text" readonly></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <b-field label="Título Corto">
                  <b-input v-model="product.short_title" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <b-field label="Año">
                  <b-input type="number" v-model="product.year" readonly></b-input>
                </b-field>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="field">
                <b-field label="Resumen">
                  <b-input
                      type="textarea"
                      minlength="10"
                      maxlength="500"
                      v-model="product.summary"
                      readonly>
                  </b-input>
                </b-field>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="field">
                <b-field label="Serie">
                  <b-input v-model="product.serie" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <b-field label="N° Serial">
                  <b-input v-model="product.serial_number" readonly></b-input>
                </b-field>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="field">
                <b-field label="Lugar">
                  <b-input v-model="product.place" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="column">
              <b-field label="Fecha">
                <b-input v-model="product.product_date" readonly></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="URL">
                <b-input v-model="product.url" readonly></b-input>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-field label="Archivo">
                <b-input v-model="product.archive" readonly></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Posición en el archivo">
                <b-input v-model="product.archive_position" readonly></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Materia">
                <b-input v-model="product.signature" readonly></b-input>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column is-2">
              <b-field label="Edición">
                <b-input v-model="product.edition" readonly></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Origen del dato">
                <b-input v-if="product.validation_attributes!=null" readonly v-model="product.validation_attributes.data_source"></b-input>
                <b-input v-else readonly></b-input>
              </b-field>
            </div>
          </div>
          <br>
          <h3 class="is-size-3 has-text-weight-bold">Participantes</h3>
          <div v-for="(participant,index) in product.participants_attributes">

            <div class="columns">
              <div class="column">
                <b-field label="Unidad">
                  <b-input v-model="product.participants_attributes[index].unit_name" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Nombre participante">
                  <b-input v-model="product.participants_attributes[index].person_name" readonly></b-input>
                </b-field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <b-field label="Tipo de participación">
                  <b-input v-model="product.participants_attributes[index].participant_type" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Estado de participación">
                  <b-input v-model="product.participants_attributes[index].participant_state" readonly></b-input>
                </b-field>
              </div>
            </div>

            <span>
              <h3 class="is-size-3 has-text-weight-bold">
                Referencias de participantes
              </h3>
            </span>
            <div class="columns">
              <div class="column">
                <b-field label="Participantes(registrados y no registrados)">
                  <b-input readonly v-model="product.participants_references" type="text"></b-input>
                </b-field>
              </div>
            </div>

          </div>
          
        </div>
      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data(){
    return{
      product: {
        product_type_attributes:[
          {id:-1,name:""}
        ],
        participants_attributes:[],
        validation_attributes:{}
      },
      isLoading:false

    }
  },
  created(){
    this.isLoading=true;
    return axios
        .get("/products/"+this.productId+".json")
        .then(res => {
          this.product = res.data['producto'];
          this.product.product_date = this.formatDate(this.product.product_date)
          this.isLoading=false;
        })
        .catch(e => {
          console.log(e);
          this.isLoading=false;
        });

  },
  props:[
      'productId'

  ],
  methods:{
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    editProduct(product_id){
      window.location.href = "/products/"+product_id+"/edit"
    }

  },
  computed:{

  }
}
</script>