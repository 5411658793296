<template>
  <b-table
    class="table-border"
    :data="persons"
    :paginated="true"
    per-page="10"
    striped
    hoverable
    :pagination-simple="false"
    pagination-position="bottom"
    default-sort-direction="asc"
    sort-icon="arrow-up"
    sort-icon-size="is-small"
    aria-next-label="Siguiente"
    aria-previous-label="Anterior"
    aria-page-label="Página"
    aria-current-label="Página actual"
  >
    <h3 class="subtitle is-4 has-text-weight-medium pl-2 pt-2">
      {{ title }}
    </h3>
    <b-table-column v-slot="props" field="index" label="N°">
      {{ persons.indexOf(props.row) + 1 }}
    </b-table-column>
    <b-table-column width="8rem" field="run" label="RUT" sortable searchable>
      <template #searchable="props">
        <b-input
          v-model="props.filters[props.column.field]"
          icon="search"
          size="is-small"
          class="pl-2"
        />
      </template>
      <template #default="props">
        <div style="white-space: nowrap">
          {{ props.row.run ? props.row.run : "" }}
        </div>
      </template>
    </b-table-column>
    <b-table-column field="name" label="Nombre" sortable searchable>
      <template #searchable="props">
        <b-input
          v-model="props.filters[props.column.field]"
          icon="search"
          size="is-small"
          class="pl-2"
        />
      </template>
      <template #default="props">
        {{ props.row.name }}
      </template>
    </b-table-column>
    <b-table-column
      field="last_name"
      label="Primer Apellido"
      sortable
      searchable
    >
      <template #searchable="props">
        <b-input
          v-model="props.filters[props.column.field]"
          icon="search"
          size="is-small"
          class="pl-2"
        />
      </template>
      <template #default="props">
        {{ props.row.last_name }}
      </template>
    </b-table-column>

    <b-table-column
      field="second_surname"
      label="Segundo Apellido"
      sortable
      searchable
    >
      <template #searchable="props">
        <b-input
          v-model="props.filters[props.column.field]"
          icon="search"
          size="is-small"
          class="pl-2"
        />
      </template>
      <template #default="props">
        {{ props.row.second_surname }}
      </template>
    </b-table-column>
    <b-table-column v-slot="props" label="Acciones">
      <b-button
        tag="a"
        :href="'/administrative-permits/' + props.row.id + '/details'"
        target="_blank"
        class="is-primary"
        icon-left="file-invoice"
        >Ver Permisos
      </b-button>
    </b-table-column>

    <template slot="empty">
      <div class="is-flex is-justify-content-center">
        <b-message type="is-danger" has-icon>
          No se encontraron resultados para el filtro de datos aplicado.
        </b-message>
      </div>
    </template>
  </b-table>
</template>

<script>
export default {
  props: {
    persons: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "Buscador de personas (Perfil permisos administrativos)",
    },
  },
};
</script>

<style scoped>
.table-border {
  border: 2rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
  border-color: #e5e7eb;
  background-color: #fff;
  padding: 1rem;
}
</style>
