<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveEvent)">
        <!-- Box de Formulario Evento -->
        <div class="modal-card" style="width: auto" id="modal-event">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" id="modal-event2" ref="modalEventForm">
            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion">
                      {{ item[0] }}
                    </div>
                  </b-message>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de documento">
                  <multiselect v-model="event.resolution_type_id"
                               :options="resolution_types.map(type => type.id)"
                               :custom-label="opt => resolution_types.find(x => x.id === opt).name"
                               placeholder="Seleccione tipo de resolución"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                               :allow-empty="true">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
              <div class="column">
                <b-field label="N° Documento">
                  <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                    <b-input v-model="event.decree" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Documento">
                  <b-datepicker
                      v-model="event.decree_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field :label='"Fecha "+ event_type_name+"*"'>
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-datepicker
                        v-model="event.start_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                    >
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field :label='"Url carpeta documentación "+event_type_name'>
                  <ValidationProvider v-slot="{ errors }" rules="max:255">
                    <b-input v-model="event.documentation_link" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input v-model="event.description" type="textarea" minlength="10" maxlength="1000"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit" :disabled="isDisabled">Guardar </button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "rectification_form",
  props:[
    'queryType', 'personId', 'id', 'modalHeader', 'event_type_name'
  ],
  data(){
    return {
      event:{event_type_id: 9},
      errores_validacion:[],
      resolution_types: [],
      isLoading:false,
      isDisabled: false
    }
  },
  async created() {
    this.isLoading=true;
      await axios
          .get("/admin/events/"+this.id+".json")
          .then(res => {
            this.event = res.data
            if(res.data["start_date"]!==null){
              this.event.start_date = new Date(this.formatDate(res.data["start_date"]))
            }

            if(res.data["end_date"]!==null){
              this.event.end_date =  new Date(this.formatDate(res.data["end_date"]))
            }
            if(res.data["decree_date"]!==null){
              this.event.decree_date  = new  Date(this.formatDate(res.data["decree_date"]))
            }
            this.resolution_types = res.data["resolution_types"]
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })
      this.isLoading=false;
  },
  methods: {
    saveEvent(){
      this.isLoading=true;
      this.isDisabled=true;
      this.event.end_date = this.event.start_date
      let clean_event = this.event
      clean_event = this.deleteData(clean_event)
      axios
          .put("/admin/events/" + this.id+".json", {
            event:  clean_event
          })
          .then(response => {
            this.$buefy.dialog.alert({
              message: 'La rectificación fue editada con éxito ',
              confirmText: 'Ok'
            })
            this.errores_validacion = {};
            this.isLoading = false;
            this.event = {}
            this.isLoading=false;
            this.$emit('close')
          }).catch(error => {
        this.errores_validacion = error.response.data
        let elmnt = document.getElementById("app");
        elmnt.scrollIntoView();
        this.$refs.modalEventForm.scrollTop = 0;
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo errores al editar la rectificación, favor revisar formato',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isDisabled=false;
        this.isLoading=false;
      })

    },
    formatDate(date){
      if(date != null && date!==""){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
    deleteData(event){
      delete event.event_types
      return event
    }
  }
}
</script>