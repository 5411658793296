<template>
  <div class="box mb-3">
   <div class="columns">
      <div class="column">
        <b-field label="Académicos/as">
          <b-checkbox label="" v-model="academics_f"/>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Horas clase">
          <b-checkbox label="" v-model="teachers_f"/>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Administrativos/as">
          <b-checkbox label="" v-model="administratives_f"/>
        </b-field>
      </div>
<!--      <div class="column">
        <b-field label="Honorarios">
          <b-checkbox label="" v-model="feecontract_f"/>
        </b-field>
      </div>-->
     </div>
    <div class="columns">
       <div class="column">
         <b-field label="Estado Nombramiento">
           <multiselect v-model="active_f"
                        :options="appointments_states"
                        placeholder="Seleccione cargo"
                        selectLabel="Presione para seleccionar"
                        selectedLabel="Seleccionado"
                        deselectLabel="Presione para deseleccionar"
                        :allow-empty="true">
             <template v-slot:noOptions>
               No existen datos
             </template>
             <span slot="noResult">
               No se encontraron elementos.
             </span>
           </multiselect>
         </b-field>
       </div>
      <div class="column">
        <b-field label="Cargo">
          <multiselect v-model="work_plant_f"
                       :options="work_plants.map(type => type.name)"
                       placeholder="Seleccione cargo"
                       selectLabel="Presione para seleccionar"
                       selectedLabel="Seleccionado"
                       deselectLabel="Presione para deseleccionar"
                       :allow-empty="true">
            <template v-slot:noOptions>
              No existen datos
            </template>
            <span slot="noResult">
               No se encontraron elementos.
            </span>
          </multiselect>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Categoría">
          <multiselect v-model="category_f"
                       :options="categories.map(type => type.name)"
                       placeholder="Seleccione categoría"
                       selectLabel="Presione para seleccionar"
                       selectedLabel="Seleccionado"
                       deselectLabel="Presione para deseleccionar"
                       :allow-empty="true">
            <template v-slot:noOptions>
              No existen datos
            </template>
            <span slot="noResult">
               No se encontraron elementos.
             </span>
          </multiselect>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field label="Jerarquía">
          <multiselect v-model="hierarchy_f"
                       :options="hierarchies.map(type => type.name)"
                       placeholder="Seleccione jerarquía"
                       selectLabel="Presione para seleccionar"
                       selectedLabel="Seleccionado"
                       deselectLabel="Presione para deseleccionar"
                       :allow-empty="true">
            <template v-slot:noOptions>
              No existen datos
            </template>
            <span slot="noResult">
               No se encontraron elementos.
             </span>
          </multiselect>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Calidad de contratación">
          <multiselect v-model="quality_f"
                       :options="qualities.map(type => type.name)"
                       placeholder="Seleccione calidad de contratación"
                       selectLabel="Presione para seleccionar"
                       selectedLabel="Seleccionado"
                       deselectLabel="Presione para deseleccionar"
                       :allow-empty="true">
            <template v-slot:noOptions>
              No existen datos
            </template>
            <span slot="noResult">
               No se encontraron elementos.
             </span>
          </multiselect>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Género">
          <multiselect v-model="gender_f"
                       :options="genders.map(type => type.name)"
                       placeholder="Seleccione género"
                       selectLabel="Presione para seleccionar"
                       selectedLabel="Seleccionado"
                       deselectLabel="Presione para deseleccionar"
                       :allow-empty="true">
            <template v-slot:noOptions>
              No existen datos
            </template>
            <span slot="noResult">
               No se encontraron elementos.
             </span>
          </multiselect>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field label="Jornada (horas)">
          <multiselect v-model="hired_hours_f"
                       :options="working_days.map(type => type.hired_hours)"
                       placeholder="Seleccione jornada (horas)"
                       selectLabel="Presione para seleccionar"
                       selectedLabel="Seleccionado"
                       deselectLabel="Presione para deseleccionar"
                       :allow-empty="true">
            <template v-slot:noOptions>
              No existen datos
            </template>
            <span slot="noResult">
               No se encontraron elementos.
             </span>
          </multiselect>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Unidad de contratación">
          <multiselect v-model="contracting_unit_f"
                       :options="units.map(type => type.name)"
                       placeholder="Seleccione unidad"
                       selectLabel="Presione para seleccionar"
                       selectedLabel="Seleccionado"
                       deselectLabel="Presione para deseleccionar"
                       :allow-empty="true">
            <template v-slot:noOptions>
              No existen datos
            </template>
            <span slot="noResult">
               No se encontraron elementos.
             </span>
          </multiselect>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Unidad de desempeño">
          <multiselect v-model="work_unit_f"
                       :options="units.map(type => type.name)"
                       placeholder="Seleccione unidad"
                       selectLabel="Presione para seleccionar"
                       selectedLabel="Seleccionado"
                       deselectLabel="Presione para deseleccionar"
                       :allow-empty="true">
            <template v-slot:noOptions>
              No existen datos
            </template>
            <span slot="noResult">
               No se encontraron elementos.
             </span>
          </multiselect>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field label="Fecha inicio nombramientos desde">
          <b-datepicker
              v-model="start_date_start_f"
              icon="calendar-alt"
              trap-focus
              locale="es-ES"
              editable
              placeholder="Formato de Fecha: dd/mm/aaaa"
          >
          </b-datepicker>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Fecha inicio nombramientos hasta">
          <b-datepicker
              v-model="start_date_end_f"
              icon="calendar-alt"
              trap-focus
              locale="es-ES"
              editable
              placeholder="Formato de Fecha: dd/mm/aaaa"
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field label="Fecha ingreso USACH desde">
          <b-datepicker
              v-model="university_entrance_date_start_f"
              icon="calendar-alt"
              trap-focus
              locale="es-ES"
              editable
              placeholder="Formato de Fecha: dd/mm/aaaa"
          >
          </b-datepicker>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Fecha ingreso USACH hasta">
          <b-datepicker
              v-model="university_entrance_date_end_f"
              icon="calendar-alt"
              trap-focus
              locale="es-ES"
              editable
              placeholder="Formato de Fecha: dd/mm/aaaa"
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field label="Fecha de nacimiento desde">
          <b-datepicker
              v-model="birthday_date_start_f"
              icon="calendar-alt"
              trap-focus
              locale="es-ES"
              editable
              placeholder="Formato de Fecha: dd/mm/aaaa"
          >
          </b-datepicker>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Fecha de nacimiento hasta">
          <b-datepicker
              v-model="birthday_date_end_f"
              icon="calendar-alt"
              trap-focus
              locale="es-ES"
              editable
              placeholder="Formato de Fecha: dd/mm/aaaa"
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

    <!-- BOTON APLICAR FILTROS -->
    <div class="has-text-right">
      <b-button label="Aplicar"
                class="is-primary "
                @click="applyFilters">Aplicar</b-button>
    </div>

  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "filters",
  data(){
    return{
      gender_f:null,
      academics_f: false,
      teachers_f:false,
      administratives_f:false,
      feecontract_f:false,
      work_plant_f:null,
      category_f:null,
      hierarchy_f:null,
      quality_f:null,
      hired_hours_f:null,
      contracting_unit_f:null,
      work_unit_f:null,
      no_filters: false,
      start_date_start_f: null,
      start_date_end_f: null,
      work_plants:[],
      categories:[],
      hierarchies:[],
      qualities: [{name: "CONTRATA"},{name: "PLANTA"},{name: "AD-HONOREM"}, {name: "INTERINO"}, {name: "SUPLENTE"}],
      working_days:[],
      units:[],
      genders:[],
      people_results: [],
      appointments_states:[],
      active_f:"ACTIVOS",
      isLoading:false,
      university_entrance_date_start_f: null,
      university_entrance_date_end_f: null,
      birthday_date_start_f: null,
      birthday_date_end_f: null
    }
  },
  created() {
    let vm = this;
    vm.isLoading = true;
    axios
        .get("/people/data_for_filters.json")
        .then(res => {
          vm.work_plants = res.data["work_plants"]
          vm.genders = res.data["genders"]
          vm.categories= res.data["categories"]
          vm.hierarchies = res.data["hierarchies"]
          vm.working_days = res.data["working_days"]
          vm.units = res.data["units"]
          vm.appointments_states = res.data["appointments_states"]
          vm.isLoading = false;
        }).catch(error => {
          //console.log(error)
          vm.isLoading=false;
        })
  },
  methods: {
    applyFilters(){
      if((this.start_date_f > this.end_date_f) && this.end_date_f !== null){
        this.$buefy.dialog.alert({
          message: 'Fecha desde no puede ser mayor que fecha hasta.',
          confirmText: 'Ok',
          type: 'is-warning',
          hasIcon: true,
          icon: 'exclamation-triangle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        return
      }
      this.isLoading = true;
      this.checkFilterParams()
      if(this.no_filters===true){
        axios
            .get("/people/filters.json",{params:{
                no_filters: this.no_filters,active_f: this.active_f
              }})
            .then(res => {
              if(res.data["results"]["length"]===0 && res.data["empty_flag"] === true){
                this.isLoading = true
                axios
                    .get("/people.json")
                    .then(res => {
                      this.$emit("updatePeople",res.data)
                      this.isLoading = false
                    })
                    .catch(error => {
                      console.log(error)
                      this.isLoading = false;
                    })
              }
              else{
                this.$emit("updatePeople",res.data["results"],this.gender_f,this.academics_f,this.teachers_f,
                    this.administratives_f, this.work_plant_f,this.category_f,this.hierarchy_f,this.quality_f,
                    this.contracting_unit_f, this.hired_hours_f,this.work_unit_f,
                    this.start_date_start_f, this.start_date_end_f,  this.university_entrance_date_start_f, this.university_entrance_date_end_f, this.birthday_date_start_f,
                    this.birthday_date_end_f, this.feecontract_f, this.active_f)
              }
              this.isLoading = false;
            }).catch(error => {
              console.log(error)
              this.isLoading=false;
            })
      }
      else{
        axios
            .get("/people/filters.json",{params:{
                feecontract_f: this.feecontract_f,
                gender_f: this.gender_f,
                academics_f:this.academics_f,
                teachers_f:this.teachers_f,
                administratives_f:this.administratives_f,
                work_plant_f:this.work_plant_f,
                category_f: this.category_f,
                hierarchy_f: this.hierarchy_f,
                quality_f: this.quality_f,
                hired_hours_f: this.hired_hours_f,
                contracting_unit_f: this.contracting_unit_f,
                work_unit_f: this.work_unit_f,
                active_f: this.active_f,
                start_date_start_f :this.start_date_start_f,
                start_date_end_f: this.start_date_end_f,
                university_entrance_date_start_f: this.university_entrance_date_start_f,
                university_entrance_date_end_f: this.university_entrance_date_end_f,
                birthday_date_start_f: this.birthday_date_start_f,
                birthday_date_end_f: this.birthday_date_end_f
              }})
            .then(res => {
              this.$emit("updatePeople",res.data["results"],this.gender_f,this.academics_f,this.teachers_f,
                  this.administratives_f, this.work_plant_f,this.category_f,this.hierarchy_f,this.quality_f,
                  this.contracting_unit_f, this.hired_hours_f,this.work_unit_f, this.start_date_start_f, this.start_date_end_f,
                  this.university_entrance_date_start_f, this.university_entrance_date_end_f, this.birthday_date_start_f, this.birthday_date_end_f, this.feecontract_f,this.active_f
              )
              if(res.data["results"]["length"]===0 ){
                this.$buefy.dialog.alert({
                  message: 'Su búsqueda no ha arrojado resultados.',
                  confirmText: 'Ok',
                  type: 'is-warning',
                  hasIcon: true,
                  icon: 'exclamation-triangle',
                  iconPack: 'fa',
                  ariaRole: 'alertdialog',
                  ariaModal: true
                })
              }
/*            else{
                var mensaje = 'Se encontraron '+res.data["results"]["length"]+' resultados.'
                this.$buefy.dialog.alert({
                  message: mensaje,
                  confirmText: 'Ok',
                  type: 'is-success',
                  hasIcon: true,
                  icon: 'check-circle',
                  iconPack: 'fa',
                  ariaRole: 'alertdialog',
                  ariaModal: true
                })
              }*/
              this.isLoading = false;
            }).catch(error => {
              console.log(error)
              this.isLoading=false;
            })
      }
    },
    checkFilterParams(){
      this.no_filters = this.gender_f == undefined && (this.academics_f == undefined || this.academics_f == false) &&
          (this.feecontract_f == undefined || this.feecontract_f == false) && (this.teachers_f == undefined || this.teachers_f == false) && (this.administratives_f == undefined || this.administratives_f == false) &&
          this.work_plant_f == undefined && this.category_f == undefined && this.hierarchy_f == undefined && this.quality_f == undefined &&
          this.hired_hours_f == undefined && this.contracting_unit_f == undefined && this.work_unit_f == undefined &&
          this.start_date_start_f == undefined && this.start_date_end_f == undefined &&
          this.university_entrance_date_end_f == undefined
          && this.university_entrance_date_start_f == undefined
          && this.birthday_date_start_f == undefined && this.birthday_date_end_f == undefined;
    }

  }
}
</script>
