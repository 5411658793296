<template>
    <section>
        <div class="modal-card" style="width: 1280px; max-width: 100%;">
            <header class="modal-card-head">
                <p class="modal-card-title">{{ 'Detalle de la Actividad' }}</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">

                <div class="columns">
                    <div class="column">
                        <b-field label="Tipo de Actividad">
                            <b-input v-model="activity.vime_activity_type.name" placeholder="No hay Tipo de Actividad"
                                readonly />
                        </b-field>
                    </div>
                    <div class="column">
                        <b-field label="Nombre de la Actividad">
                            <b-input v-model="activity.name" placeholder="No hay Nombre de la Actividad" readonly />
                        </b-field>
                    </div>
                </div>

                <div class="columns">
                    <div class="column">
                        <b-field label="Fecha de Inicio de la Actividad">
                            <b-input v-model="activity.start_date" placeholder="No hay Fecha de Inicio de la Actividad"
                                readonly />
                        </b-field>
                    </div>

                    <div class="column">
                        <b-field label="Fecha de Término de la Actividad">
                            <b-input v-model="activity.end_date" placeholder="No hay Fecha de Término de la Actividad"
                                readonly />
                        </b-field>
                    </div>
                </div>

                <div class="columns">
                    <div class="column">
                        <b-field label="Tema de la Actividad">
                            <b-input v-model="activity.theme" placeholder="No hay Tema de la Actividad" readonly />
                        </b-field>
                    </div>

                    <div class="column is-narrow">
                        <b-field label="¿Es Internacional?">
                            <b-radio-button v-model="activity.is_national" :native-value="false" type="is-danger" disabled>
                                <b-icon icon="times-circle"></b-icon>
                                <span>No</span>
                            </b-radio-button>
                            <b-radio-button v-model="activity.is_national" :native-value="true" type="is-success" disabled>
                                <b-icon icon="check-circle"></b-icon>
                                <span>Si</span>
                            </b-radio-button>
                        </b-field>
                    </div>
                </div>

                <div class="columns">
                    <div class="column">
                        <b-field label="Descripción de la Actividad">
                            <b-input v-model="activity.description" type="textarea"
                                placeholder="No hay Descripción de la Actividad" readonly />
                        </b-field>
                    </div>
                </div>

                <div class="columns">
                    <div class="column">
                        <b-field label="Link de la Actividad">
                            <b-input v-model="activity.link" type="link" placeholder="No hay Link de la Actividad"
                                readonly />
                        </b-field>
                    </div>

                    <div class="column">
                        <b-field label="Número de Asistentes de la Actividad">
                            <b-input v-model="activity.assistants_number" type="number"
                                placeholder="No hay Número de Asistentes de la Actividad" readonly />
                        </b-field>
                    </div>
                </div>

            </section>
            <footer class="modal-card-foot">
                <div class="columns is-mobile">
                    <div class="column">
                        <b-button label="Cerrar" @click="$emit('close')" />
                    </div>
                </div>
            </footer>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </section>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {

    name: 'modal_show_academic_activity',

    props: [
        'personId', 'cargoPrincipal', 'activityId',
    ],

    data () {
        return {
            isLoading: false,

            activity: {
                vime_activity_type: {}
            },

            
        }
    },

    async created() {
        if (this.cargoPrincipal.name === 'ACADEMICO') await this.getAssistancesTypes()
        await this.getActivity()
    },

    methods: {

        async getActivity(){
            this.isLoading = true
            await axios.get("/academic_vime_activities.json", {params:{id: this.activityId}})
                        .then(res => {
                            this.activity = res.data
                            if (this.activity.start_date != null) {
                                this.activity.start_date = moment(this.activity.start_date, ["YYYY-MM-DD","DD-MM-YYYY"])
                                this.activity.start_date = new Date(this.activity.start_date)
                            }

                            if (this.activity.end_date != null) {
                                this.activity.end_date = moment(this.activity.end_date, ["YYYY-MM-DD","DD-MM-YYYY"])
                                this.activity.end_date = new Date(this.activity.end_date)
                            }
                            this.isLoading = false
                        })
                        .catch(error => {
                            var showError = ' Favor intentarlo más tarde.'
                            if (error.response) {
                                showError = ' Favor revisar errores.<br>Errores:<br>'
                                for (const propiedad in error.response.data) {
                                    if (error.response.data.hasOwnProperty(propiedad)) {
                                        showError += error.response.data[propiedad].join(', ') + '<br>'
                                    }
                                }
                            }

                            this.$buefy.dialog.alert({
                                title: 'Error',
                                message: 'Hubo un error al traer los datos de la Actividad.' + showError ,
                                type: 'is-danger',
                                hasIcon: true,
                                icon: 'times-circle',
                                iconPack: 'fa',
                                ariaRole: 'alertdialog',
                                ariaModal: true
                            })
                            this.$emit('close')
                            this.isLoading = false
                        })  
        },

    }

}
</script>