<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveFinancing)">
        <!-- Box de Formulario Evento -->
        <div class="modal-card" style="width: 1200px" id="modal-financing">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body">

            <div class="columns">
              <div class="column">
                <b-field>
                  <template #label>
                    Seleccione institución de financiamiento*
                    <b-tooltip
                        label="Ingrese al menos 3 caracteres para buscar una institución en la plataforma"
                        position="is-right"
                        multilined>
                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          type="is-info">
                      </b-icon>
                    </b-tooltip>
                  </template>
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-taginput
                        v-model="entidad_seleccionada"
                        :data="entidades_filtradas"
                        type="is-primary is-light"
                        autocomplete
                        :allow-new="false"
                        field="show_name"
                        icon="tag"
                        maxtags="1"
                        placeholder="Seleccione institución de financiamiento"
                        @typing="getFilteredEntidad">
                      <template slot-scope="props">
                        {{ props.option.show_name ? props.option.show_name : props.option.name}}
                      </template>
                    </b-taginput>

                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Seleccione Tipo de Financiamiento*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="financings_attributes.financing_type_id"
                                 :options="tipos_financiamiento.map(x => x.id)"
                                 :custom-label="opt => tipos_financiamiento.find(x => x.id == opt).name"
                                 placeholder="Seleccione tipo de financiamiento"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                          No se encontraron elementos.
                        </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label=" ">
                  <template #label>
                    Monto (USD)
                    <b-tooltip
                        label="Si el monto en USD no se especifica, será considerado como igual a 0. Posteriormente este monto puede ser modificado"
                        position="is-right"
                        multilined>
                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          type="is-info">
                      </b-icon>
                    </b-tooltip>
                  </template>
                  <ValidationProvider rules="integer|positive" v-slot="{errors}">
                    <b-input v-model="financings_attributes.usd_amount"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Monto (CLP)">
                  <ValidationProvider rules="integer|positive" v-slot="{errors}">
                    <b-input v-model="financings_attributes.amount"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label=" ">

                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label=" ">

                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label=" ">

                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label=" ">

                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label=" ">

                </b-field>
              </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit" :disabled="isDisabled">Agregar</button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
const qs = require('qs');
export default {
  name: "modal_financing_icc",
  props:[
    'queryType', 'personId', 'id', 'modalHeader', 'projectId', 'tipo', 'actual_financing', 'actual_index'
  ],
  data(){
    return {
      financings_attributes:{
        amount: null,
        usd_amount: null,
        year: null,
        financing_type_id: null,
        partner_entity_id: null,
        selected_entity: null,
        skip_if_commission: true,
        id: null

      },
      entidades_filtradas:[],
      cardLayout:false,
      entidad_seleccionada: [],
      errores_validacion:[],
      tipos_financiamiento: null,
      entidades_externas: [],
      isLoading:false,
      isDisabled: false,

    }
  },
  created() {
    this.isLoading=true;
    this.tipos_financiamiento = this.tipo

    if (this.actual_index != null){
      this.financings_attributes.year = this.actual_financing.year
      this.financings_attributes.amount = this.actual_financing.amount
      this.financings_attributes.usd_amount = this.actual_financing.usd_amount
      this.financings_attributes.financing_type_id = this.actual_financing.financing_type_id
      this.financings_attributes.selected_entity = this.actual_financing.selected_entity[0]
      this.entidad_seleccionada.push(this.actual_financing.selected_entity)

    }

    this.isLoading=false;
  },
  methods: {
    saveFinancing(){
      this.financings_attributes.partner_entity_id = this.entidad_seleccionada[0].id
      this.financings_attributes.selected_entity = this.entidad_seleccionada[0]
      this.$emit('clicked', this.financings_attributes)
      this.$emit('close')
    },
    getFilteredEntidad(query) {
      if(query.length<3){
        return
      }
      this.entidad_seleccionada = [];
      axios
          .get("/admin/partner_entities/search_partner_entities.json",
              {
                params: {
                  query: query
                }
              })
          .then(res => {
            this.entidades_filtradas = res.data
          }).catch(error => {
        console.log(error)
      })

      this.entidades_filtradas = this.entidades_externas.filter((option) => {
        return option.show_name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
  }
}
</script>
<style scoped>
.multiselect__content-wrapper {
  position: static;
}
</style>
