<template>
    <section>
      <div v-if="can_create_academic_activity === 'true'" class="columns" >
        <div class="column is-offset-8">
          <b-field label = " " class="has-text-right">
            <b-button class="is-primary" tag="a" href="/patents/new" target="_blank" icon-left="plus">
              Añadir Patente
            </b-button>
          </b-field>
        </div>
      </div>
        <patents-table v-bind:patentes="patentes" :personId="personId" :hide-update="true"
            :cargo-principal="cargoPrincipal" @updatePatents="updatePatents" />
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

    </section>
</template>
<script>
import axios from "axios";
import PatentsTable from "../patents/patents_table.vue";
import moment from "moment";
export default {
    props: [
        'personId', 'cargoPrincipal', 'minYear', 'maxYear', 'changeFilter', 'can_create_academic_activity'
    ],
    data() {
        return {
            patentes: [],
            isLoading: false,
            year: new Date().getFullYear(),
        }
    },
    components: {
        PatentsTable
    },
    created() {
        console.log(this.minYear)
        this.getPatents();
    },
    methods: {
        getPatents() {
            this.isLoading = true;
            axios
                .get("/people/" + this.personId + "/patents.json", {params: {
                    minYear: this.minYear,
                    maxYear: this.maxYear,
                    id: this.personId,
                }})
                .then(res => {
                    this.patentes = res.data
                    this.patentes = this.patentes.map(patente => {
                        var patente = patente
                        patente["presentation_date"] = moment(patente.presentation_date, ["YYYY-MM-DD", "DD-MM-YYYY"])
                        patente["last_patent_state_date"] = moment(patente.last_patent_state_date, ["YYYY-MM-DD", "DD-MM-YYYY"])
                        return patente
                    })
                    this.isLoading = false
                })
                .catch(e => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: 'Hubo un error al traer las Propiedades Intelectuales de la persona consultada, favor de revisar la consola.',
                        type: 'is-danger'
                    })
                    console.log(e)
                    this.isLoading = false
                });
        },
        updatePatents(year) {
            this.year = year
            this.getPatents()
        }
    },
    computed: {

    },
    watch: {
        changeFilter: function () {
            this.getPatents()
        }
    }
}
</script>