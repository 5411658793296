<template>
  <section>
    <div class="container">

        <b-tabs @input="changeTab" :destroy-on-hide="true" v-model="currentTab" position="is-centered" type="is-toggle">
              <b-tab-item v-for="item in items1"
                          :value="item.content"
                          v-bind:key="item.content"
                          :label="item.tab"
              :visible="(item.content ==='NullAppointmentsDistances') ? can_list_null === 'true' : true">
              </b-tab-item>
          </b-tabs>
          <!-- COMPONENTE AQUI -->
        <component v-bind:is="currentTabComponent1" :person-id="personId" :can_create_appointment="can_create_appointment"
                   :person_name="person_name" :can_list_null="can_list_null" :can_request_appointment="can_request_appointment"></component>

        <!-- FIN COMPONENTE -->

    </div>
  </section>
</template>
<script>
import Appointments from "../life_record/appointments"
import NullAppointments from "../life_record/null_appointments"
import NullDistances from "../life_record/null_distances"
import Distances from "../life_record/distances"
import EventBus from "../eventBus";
import FeeContracts from "./fee_contracts"
import NullFeeContracts from "./null_fee_contracts"
import NullAppointmentsDistances from "./null_appointments_distances"
import NoEffectAppointments from "./no_effect_appointments"
export default {
  name: "appointments_tabs",
  props:[
    'personId', 'can_create_appointment', 'can_list_null', 'person_name', 'can_request_appointment'
  ],
  data(){
    return {
      currentTab: 'Appointments',
      currentTab2: 'NullAppointments',
      items1: [
        {tab: 'Nombramientos',content:'Appointments'},
        {tab: 'Contratos de Honorarios', content: "FeeContracts"},
        {tab: 'Sin Efecto', content: "NoEffectAppointments"},
        {tab: 'Alejamientos', content: "Distances"},
        {tab: "Anulaciones", content: "NullAppointmentsDistances"}
      ],
      items2: [
        {tab: 'Nombramientos Anulados',content:'NullAppointments'},
        {tab: 'Contratos de Honorarios Anulados', content: "NullFeeContracts"},
        {tab: 'Alejamientos Anulados', content: 'NullDistances'}
      ]
    }
  },
  components:{
    Appointments,
    NullAppointments,
    Distances,
    NullDistances,
    NullAppointmentsDistances,
    FeeContracts,
    NullFeeContracts,
    NoEffectAppointments
  },
  methods:{
    changeTab: function(value){
      this.currentTab = value
    },
    changeTab2: function(value){
      this.currentTab2 = value
    },
    selectTab1(tab){
      this.currentTab1 = tab
    },
    selectTab2(tab){
      this.currentTab2 = tab
    },
  },
  computed: {
    currentTabComponent1: function () {
      return this.currentTab
    },
    currentTabComponent2: function () {
      return this.currentTab2
    },

  },
  mounted () {
    EventBus.$on('ALEJAMIENTO_CREADO', (payload) => {
      this.selectTab1("Distances")
    })
  }
}
</script>