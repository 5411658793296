<template>
    <section>

        <b-table striped :data="work_experiences" paginated per-page=10 :pagination-simple=false
            pagination-position="bottom" aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
            aria-current-label="Página actual">

            <b-table-column label="Código Asignatura" field="codigo" width="40" sortable searchable>
                <template #searchable="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder="Buscar Código..."
                        icon="search"/>
                </template>
                <template v-slot="props">
                    {{ props.row.codigo }}
                </template>
            </b-table-column>

            <b-table-column label="Asignatura" field="asignatura" sortable searchable>
                <template #searchable="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder="Buscar Asignatura..."
                        icon="search"/>
                </template>
                <template v-slot="props">
                    {{ props.row.asignatura }}
                </template>
            </b-table-column>

            <b-table-column label="Carrera" field="carrera" sortable searchable>
                <template #searchable="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder="Buscar Carrera..."
                        icon="search"/>
                </template>
                <template v-slot="props">
                    {{ props.row.nombre_carrera }}
                </template>
            </b-table-column>

            <b-table-column label="Tipo Programa" field="tipo_programa" sortable searchable>
                <template #searchable="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder="Buscar Tipo..."
                        icon="search"/>
                </template>
                <template v-slot="props">
                    {{ props.row.tipo_carrera }}
                </template>
            </b-table-column>

            <b-table-column label="Semestre/Año Dictado" field="agno" sortable searchable>
                <template #searchable="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder="Buscar Año..."
                        icon="search"/>
                </template>
                <template v-slot="props">
                    {{ props.row.semestre + '/' + props.row.agno }}
                </template>
            </b-table-column>

        </b-table>

        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

    </section>
</template>

<script>
import axios from 'axios'
export default {
    props:[
        'personId','photoUrl', 'cargoPrincipal', 'canCreateDocument'
    ],
    data() {
        return {
            isLoading:false,
            work_experiences: [],
        }
    },
    created() {
        this.getWorkExperiences()
    },
    methods: {

        async getWorkExperiences() {
            this.isLoading = true
            await axios
                .get('/people/my_usach_experiences.json',{ params: {
                    id: this.personId
                }})
                .then(response => {
                    this.work_experiences = response.data
                    this.isLoading = false
                })
                .catch(error => {
                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al traer sus datos de Experiencia Docente USACH, favor de revisar los errores.',
                        type: 'is-danger',
                        hasIcon: true,
                        iconPack: 'fa',
                        icon: 'times-circle',
                        confirmText: 'Ok',

                    })
                    console.log(error)
                    this.isLoading = false
                })
        },

        newWorkExperience() {
            this.isModalActive = true
        }
    }
}
</script>