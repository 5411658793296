<template>
  <section>
    <div class="container">
      <div class="box">
        <div class="columns">
          <h3 class="is-size-3 has-text-weight-bold">Listado Nombramientos
            <b-tooltip
                label="Se listan los nombramientos activos para editar el rol asociado o agregar uno en caso de que este no exista"
                multilined
                position="is-right">
              <b-icon
                  pack="fas"
                  icon="info-circle"
                  type="is-info">
              </b-icon>
            </b-tooltip>
          </h3>

        </div>
        <b-table
            :data="people"
            :paginated= true
            per-page="10"
            striped
            hoverable
            sticky-header
            height="640"
            :narrowed=true
            :current-page="currentPage"
            :pagination-simple=false
            pagination-position="both"
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            default-sort="product.title"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual">
          <b-table-column
              field="run_completo"
              label="Rut"
              searchable>
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{ props.row.run_completo!=="0" ? props.row.run_completo : ""}}
            </template>
          </b-table-column>
          <b-table-column
              field="complete_name"
              label="Nombres"
              searchable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.complete_name}}
            </template>
          </b-table-column>
          <b-table-column
              field="person.corporate_mail"
              label="Correo corporativo"
              searchable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.person.corporate_mail}}
            </template>
          </b-table-column>
          <b-table-column
              field="work_plant.name"
              label="Cargo"
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.work_plant!==undefined ? props.row.work_plant.name : ""}}
            </template>
          </b-table-column>
          <b-table-column
              field="internal_position.name"
              label="Rol"
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.internal_position!==undefined ? props.row.internal_position.name : ""}}
            </template>
          </b-table-column>
          <b-table-column
              field="unit.name"
              label="Unidad de desempeño"
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.unit!==undefined ? props.row.unit.name : ""}}
            </template>
          </b-table-column>
          <b-table-column
              field="start_date"
              label="Fecha Desde"
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.start_date}}
            </template>
          </b-table-column>
          <b-table-column
              field="end_date"
              label="Fecha Hasta"
              searchable
              sortable
          >
            <template
                slot="searchable"
                slot-scope="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder=" Buscar"
                  icon="search"
                  size="is-small" />
            </template>
            <template v-slot="props">
              {{props.row.end_date}}
            </template>
          </b-table-column>
          <b-table-column
              label="Acciones"
              v-slot="props">
            <b-icon
                pack="fas"
                icon="eye"
                size="is-medium"
                @click.native="showModal(props.row)"/>
            <b-icon
                pack="fas"
                icon="edit"
                size="is-medium"
                @click.native="editModal(props.row)"/>
          </b-table-column>
        </b-table>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
      </div>
    </div>
    <b-modal
        v-model="show"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        width="1024"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal-show-appointment
            :id="id"
            :complete_name="complete_name"
            :complete_rut="complete_rut"
            :corporate_mail="corporate_mail"
            :queryType="queryType"
            :person="prop_person"
            query-type="show"
            modal-header="Nombramiento"
            @close="props.close"></modal-show-appointment>
      </template>
    </b-modal>
    <b-modal
        v-model="edit"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        width="1024"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-edit-appointment
            :id="id"
            :queryType="queryType"
            :personId="personId"
            :person="prop_person"
            query-type="update"
            modal-header="Nombramiento"
            @close="props.close"></modal-edit-appointment>
      </template>
    </b-modal>
  </section>
</template>

<script>
import axios from "axios";
import ModalShowAppointment from './modal_show_appointment.vue';
import ModalEditAppointment from './modal_edit_appointment.vue';

export default {
  name: "appointments_list",
  components:{
    ModalShowAppointment,
    ModalEditAppointment,
  },
  data(){
    return {
      selected: null,
      people: [],
      currentPage: 1,
      isLoading: false,
      no_filters: true,
      formattedDate: "",
      canCancel: ['escape'],
      show: false,
      edit: false,
      complete_rut: "",
      complete_name: "",
      corporate_mail: "",
      personId: "",
      queryType: "",
      id: "",
      person: "",
      prop_person: ""
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    initialize(){
      this.isLoading = true
      return axios
          .get("/people/appointments_list.json")
          .then(res => {
            this.people = res.data
            this.people = this.people.map(person => {
              var newPerson = person
              newPerson["start_date"] = this.formatDate(person.start_date);
              newPerson["end_date"] = this.formatDate(person.end_date);
              return newPerson;
            });
            this.isLoading = false
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
          })
    },
    showModal(row){
      this.isLoading = true
      this.id = row.id
      this.complete_rut = row.run.rut.toString() + "-"+row.run.dv
      this.corporate_mail = row.person.corporate_mail
      this.complete_name = row.complete_name
      this.prop_person = row.person
      this.show = true
      this.isLoading = false
    },
    editModal(row){
      this.isLoading = true
      this.id = row.id
      this.personId = row.person.id
      this.queryType = "update"
      this.person = row.person
      this.edit = true
      this.isLoading = false
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    }
  },
  watch: {
    edit: function () {
      if (!this.edit) {
        this.initialize()
      }
    },
  }
}
</script>
