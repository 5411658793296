<template>
  <section>
    <div class="container">
      <div class="box">
        <h1 class="title has-text-left">Comparación datos ANID</h1>
        <div class="column">
          <b-message title="Importante" type="is-info" has-icon aria-close-label="Cerrar">
            <ul>
              <li>
                - La información historica de proyectos de ANID es recuperada del repositorio de GitHub. Para ingresar al repositorio de ANID lo puede hacer presionando
                <a href="https://github.com/ANID-GITHUB" target="_blank">aquí.</a>
              </li>
              <li>
                - La coincidencia de encargados solo muestra a las personas que se encuentran registradas en el sistema y que pertenecen a la facultad de ingeniería.
              </li>
            </ul>
          </b-message>
          <div class="has-text-right">
            <button class="button is-primary" @click="compare_data">Comparar datos</button>
          </div>
        </div>
<!--        <div class="box">
          <div class="columns">
            <div class="column">
              <b-field grouped group-multiline>
                <div class="control">
                  <h1 class="title is-4">Seleccionar archivo con datos provenientes de ANID</h1>
                  <h2 class="subtitle is-6 has-text-weight-bold">*El archivo debe tener la extensión .xlsx.</h2>
                </div>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div >
                <b-field class="file">
                  <b-upload v-model="file" expanded>
                    <a class="button is-primary is-outlined">
                      <b-icon icon="upload"></b-icon>
                      <span>{{ file.name || "Subir Archivo"}}</span>
                    </a>
                  </b-upload>
                </b-field>
              </div>
            </div>
          </div>
        </div>-->
        <h1 v-if="load_data" class="title has-text-centered">Contrastación de datos con ANID</h1>
        <b-tabs v-if="load_data" position="is-centered" class="block" >
          <b-tab-item label="Proyectos no encontrados">
            <b-tabs position="is-centered" type="is-toggle">
              <b-tab-item label="Con coincidencias de encargado FING">
                <b-table :data="project_not_found_person"
                         :paginated=true
                         per-page=10
                         striped
                         hoverable
                         :current-page="currentPage"
                         :pagination-simple=false
                         pagination-position="bottom"
                         default-sort-direction="desc"
                         sort-icon="arrow-up"
                         sort-icon-size="is-small"
                         default-sort="year"
                         aria-next-label="Siguiente"
                         aria-previous-label="Anterior"
                         aria-page-label="Página"
                         aria-current-label="Página actual">
                  <b-table-column field= 'code'
                                  label= 'Codigo Proyecto'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.code }}
                    </template>
                  </b-table-column>
                  <b-table-column field= 'name'
                                  label= 'Nombre Proyecto'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.name }}
                    </template>
                  </b-table-column>
                  <b-table-column field= 'subdir'
                                  label= 'Tipo'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.subdir }}
                    </template>
                  </b-table-column>
                  <b-table-column field= 'program'
                                  label= 'Programa'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.program }}
                    </template>
                  </b-table-column>
                  <b-table-column field= 'instrumento'
                                  label= 'Instrumento'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.instrumento }}
                    </template>
                  </b-table-column>
                  <b-table-column field= 'person'
                                  label= 'Encargado'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.person }}
                    </template>
                  </b-table-column>
                  <b-table-column field= 'year'
                                  label= 'Año'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.year }}
                    </template>
                  </b-table-column>
                  <b-table-column label="Coincidencias"
                                  width="10"
                                  v-slot="props">
                    <b-icon
                        pack="fas"
                        icon="eye"
                        size="is-medium"
                        @click.native="showPeople(props.row.person_found)"/>
                  </b-table-column>
                </b-table>
              </b-tab-item>
              <b-tab-item label="Sin coincidencia de encargado FING">
                <b-table :data="project_not_found"
                         :paginated=true
                         per-page=10
                         striped
                         hoverable
                         :current-page="currentPage"
                         :pagination-simple=false
                         pagination-position="bottom"
                         default-sort-direction="desc"
                         sort-icon="arrow-up"
                         sort-icon-size="is-small"
                         default-sort="year"
                         aria-next-label="Siguiente"
                         aria-previous-label="Anterior"
                         aria-page-label="Página"
                         aria-current-label="Página actual">
                  <b-table-column field= 'code'
                                  label= 'Codigo Proyecto'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.code }}
                    </template>
                  </b-table-column>
                  <b-table-column field= 'name'
                                  label= 'Nombre Proyecto'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.name }}
                    </template>
                  </b-table-column>
                  <b-table-column field= 'subdir'
                                  label= 'Tipo'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.subdir }}
                    </template>
                  </b-table-column>
                  <b-table-column field= 'program'
                                  label= 'Programa'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.program }}
                    </template>
                  </b-table-column>
                  <b-table-column field= 'instrumento'
                                  label= 'Instrumento'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.instrumento }}
                    </template>
                  </b-table-column>
                  <b-table-column field= 'person'
                                  label= 'Encargado'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.person }}
                    </template>
                  </b-table-column>
                  <b-table-column field= 'year'
                                  label= 'Año'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.year }}
                    </template>
                  </b-table-column>
                </b-table>
              </b-tab-item>
            </b-tabs>
          </b-tab-item>
          <b-tab-item label ="Proyectos encontrados">
            <b-table :data="project_found"
                     :paginated=true
                     per-page=10
                     striped
                     hoverable
                     :current-page="currentPage"
                     :pagination-simple=false
                     pagination-position="bottom"
                     default-sort-direction="desc"
                     sort-icon="arrow-up"
                     sort-icon-size="is-small"
                     default-sort="award_year"
                     aria-next-label="Siguiente"
                     aria-previous-label="Anterior"
                     aria-page-label="Página"
                     aria-current-label="Página actual">
              <b-table-column field= 'code'
                              label= 'Codigo Proyecto'
                              searchable
                              sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{ props.row.code }}
                </template>
              </b-table-column>
              <b-table-column field= 'name'
                              label= 'Nombre Proyecto'
                              searchable
                              sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  <a :href="getProjectUrl(props.row)" target="_blank">{{ props.row.name }}</a>
                </template>
              </b-table-column>
<!--              <b-table-column field= 'program'
                              label= 'Programa'
                              searchable
                              sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{ props.row.program }}
                </template>
              </b-table-column>-->
              <b-table-column field= 'award_year'
                              label= 'Año'
                              searchable
                              sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{ props.row.award_year }}
                </template>
              </b-table-column>
            </b-table>
          </b-tab-item>
        </b-tabs>
      </div>
      <b-modal v-model="showModal"
               has-modal-card
               trap-focus
               :destroy-on-hide="true"
               aria-role="dialog"
               aria-modal
               :can-cancel="canCancel">
        <template #default="props">
          <modal-people-found
              modal-header="Coincidencias de encargado"
              :people-found="peopleFound"
              @close="props.close"></modal-people-found>
        </template>

      </b-modal>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import ModalPeopleFound from "./ModalPeopleFound";

export default {
  name: "CompareAnidData",
  components:{ModalPeopleFound},
  data(){
    return{
      file:{name:""},
      load_data: false,
      project_not_found:[],
      project_not_found_person:[],
      project_found:[],
      currentPage:1,
      showModal: false,
      isLoading: false,
      canCancel: false,
      peopleFound: [],
    }
  },
  methods:{
    compare_data(){
      this.isLoading = true
      this.project_not_found = []
      this.project_found = []
      this.load_data = false
      let formData = new FormData();
      formData.append('file', this.file);
      axios.post("/projects/compare-anid-data.json",
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(response => {
        this.project_found = response.data['project_found']
        this.project_not_found = response.data['project_not_found']
        this.project_not_found_person = response.data['project_not_found_person']
        if (this.project_not_found != null && this.project_not_found.length > 0){
          this.load_data = true
        }
        else if(this.project_found != null && this.project_found.length > 0){
          this.load_data = true
        }
        else if(this.project_not_found_person != null && this.project_not_found_person.length > 0){
          this.load_data = true
        }
        else{
          this.load_data = false
        }

        this.isLoading = false

      }).catch(error =>{
        this.isLoading = false
        this.$buefy.dialog.alert({
          message: 'Los datos del archivo "'+this.file.name+'" no han podido ser analizados, por favor, revisar formato de los datos o del archivo',
          confirmText: 'Ok',
          type: 'is-warning',
          hasIcon: true,
          icon: 'exclamation-triangle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        console.log(error)
      })
    },
    getProjectUrl(project){
      if(project.is_postulated){
        return "/projects/postulated/"+project.id;
      }
      else{
        return "/projects/"+project.id;
      }
    },
    showPeople(people){
      this.peopleFound = people
      this.showModal = true

    }
  }
}
</script>

<style scoped>

</style>