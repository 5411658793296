<template>
  <section>
    <div class="container">
      <!--DATA TABLE-->
      <b-table
          :data="person_units"
          :paginated= true
          per-page="20"
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="product.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual"
          :opened-detailed="defaultOpenedDetails"
          detailed
          detail-key="id"
          detail-transition="fade"
          detail-icon="chevron-right"
          :show-detail-icon="showDetailIcon"
          scrollable
          :sticky-header=false>
        <b-table-column
            field="resolution_date"
            label="Fecha Documento"
            width="110"
            sortable
            searchable :custom-search="searchResolution">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.resolution_date._isValid ? new Date(props.row.resolution_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>

        <b-table-column
            field="resolution"
            label="N° de documento"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.resolution}}
          </template>
        </b-table-column>
        <b-table-column
            field="resolution_type.name"
            label="Tipo documento"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.resolution_type ? props.row.resolution_type.name : ""}}
          </template>
        </b-table-column>
        <b-table-column
            field="appointment_type.name"
            label="Tipo Nombramiento"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.appointment_type ? props.row.appointment_type.name : ""}}
          </template>
        </b-table-column>

        <b-table-column
            field="work_plant.name"
            label="Cargo"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.work_plant ? props.row.work_plant.name : ""}}
          </template>
        </b-table-column>

        <b-table-column
            field="unit.cost_center"
            label="CC Unidad"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            <b-tooltip
                :label="props.row.unit.name"
                multilined
                position="is-left">
              {{props.row.unit ? props.row.unit.cost_center : ""}}
            </b-tooltip>

          </template>
        </b-table-column>
        <b-table-column
            field="start_date"
            label="Fecha Desde"
            width="110"
            sortable
            searchable :custom-search="searchStart">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            field="end_date"
            label="Fecha Hasta"
            width="110"
            sortable
            searchable :custom-search="searchEndDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            field="working_day"
            label="Jornada /Horas"
            width="110"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.working_day ? props.row.working_day.hired_hours : ""}}
          </template>
        </b-table-column>

        <b-table-column
            label="Acciones"
            width="40"
            v-slot="props">
          <b-icon
              v-if="props.row.can_show"
              pack="fas"
              icon="eye"
              size="is-medium"
              @click.native="execShowModal(props.row.id)"/>
          <b-icon
              v-if="props.row.can_redo"
              pack="fas"
              icon="redo"
              type="is-success"
              size="is-medium"
              @click.native="execRedoModal(props.row.id)"/>
          <b-icon
              v-if="props.row.can_nullify"
              pack="fas"
              icon="trash-alt"
              type="is-danger"
              size="is-medium"
              @click.native="execNullifyModal(props.row.id)"/>
        </b-table-column>

        <template #detail="props">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <div class="columns">
                  <div class="column">
                    <strong>Alejamientos Asociados: </strong>
                  </div>
                </div>
                <div class="columns" v-for="(distance,index) of props.row.distances" :key=index>

                  <div class="column is-narrow">
                    <p>{{distance.appointment_type_name}}</p>
                  </div>
                  <div class="column is-narrow">
                    <p>{{distance.resolution_type_name}}</p>
                  </div>
                  <div class="column is-narrow">
                    <p>{{distance.resolution}}</p>
                  </div>
                  <div class="column is-narrow">
                    <p>{{formatDate(distance.resolution_date)}}</p>
                  </div>
                  <div class="column is-narrow">
                    <p>{{formatDate(distance.start_date)}}</p>
                  </div>
                  <div class="column is-narrow">
                    <b-icon
                        pack="fas"
                        icon="eye"
                        size="is-medium"
                        @click.native="execShowModalDistance(distance.id)"/>
                  </div>
                </div>
              </div>
            </div>
          </article>
          <article class="media" v-if="props.row.establishment_id===3">
            <div class="media-content">
              <div class="content">
                <div class="columns">
                  <div class="column">
                    <strong>Asignaturas: </strong>
                  </div>
                </div>
                <b-taglist>
                  <b-tag type="is-primary" v-for="(subject,index) of props.row.subjects_and_hours" :key=index>{{subject}}</b-tag>
                </b-taglist>
              </div>
            </div>
          </article>
        </template>

      </b-table>
      <!--FIN DATA TABLE-->
      <b-modal
          v-model="isShowModalActive"
          has-modal-card
          trap-focus
          width="1024"
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <component  :is="showName"
                      :id="selected"
                      :person-id="personId"
                      :modal-header="title"
                      :person_name="person_name"
                      @close="props.close"/>
        </template>
      </b-modal>

      <b-modal
          v-model="isShowDistanceModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <component  :is="showName"
                      :id="selected"
                      :modal-header="title"
                      @close="props.close"/>
        </template>
      </b-modal>

    </div>
    <b-loading :is-full-page="true"
               v-model="isLoading"
               :can-cancel="false"/>
  </section>
</template>

<script>
import axios from "axios";
import AppointmentAcademic from "../life_record/appointment_academic.vue"
import AppointmentAdministrative from "../life_record/appointment_administrative.vue"
import AppointmentHours from "../life_record/appointment_hours.vue";
import Distance from "../life_record/distance.vue"
import AppointmentFeeContract from "../life_record/appointment_feecontract"
import moment from "moment";
import {searchDate, formatDate} from "../../packs/utilities";
import DistanceForm from "../life_record/modal_distance_form.vue"
export default {
  name: "appointments",
  props:[
    'personId','isEditDisabled', 'person_name'
  ],
  components:{
    AppointmentHours,
    AppointmentAcademic,
    AppointmentAdministrative,
    Distance,
  },
  data(){
    return {
      formName: "",
      showName: "",
      title: "",
      selected: null,
      index: null,
      currentPage: 1,
      isAddModalActive: false,
      isEditModalActive: false,
      isShowModalActive: false,
      isLoading:false,
      person_units: [],
      person_unit:{},
      canCancel: [],
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      useTransition: false,
      isShowDistanceModalActive: false,
      canEdit: true,
      isDistanceModalActive: false,
      actual_person_unit: {},
      query_type: "",
      isNullifyActive: false,
      isRedoActive: false
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    initialize(){
      this.isLoading = true;
      return axios
          .get("/people/"+this.personId+"/no-effect-appointments.json")
          .then(res => {
            this.person_units = res.data
            this.person_units = this.person_units.map(personUnit => {
              var newPersonUnit = personUnit
              newPersonUnit["resolution_date"] = moment(newPersonUnit["resolution_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newPersonUnit["start_date"] = moment(newPersonUnit["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              newPersonUnit["end_date"] = moment(newPersonUnit["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
              return newPersonUnit;
            });
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })
    },
    execRedoModal(id){
      this.$buefy.dialog.confirm({
        title: 'Revertir nombramiento SIN EFECTO',
        message: '¿Está seguro que desea <b>revertir</b> el estado "SIN EFECTO" del nombramiento? Esta acción lo dejará válido.<br>'+
            '<b>*Se anulará/n la/s rectificacione/s que lo dejan sin efecto automáticamente</b>',
        confirmText: 'Revertir Nombramiento',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isRedoActive = true
          this.isLoading = true
          axios
              .put("/admin/person_units/" + id + "/restore-no-effect.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Estado "SIN EFECTO" revertido correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                this.isLoading = false;
                this.isRedoActive = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>No se pudo revertir el estado "SIN EFECTO" del nombramiento</b><br>'+error.response.data["base"][0],
              duration: 5000,
              type: 'is-damger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isRedoActive = false;
          })
        }
      })
    },
    execNullifyModal(id){
      this.$buefy.dialog.confirm({
        title: 'Anular nombramiento',
        message: '¿Está seguro que desea <b>anular</b> el nombramiento? Esta acción lo dejará inválido.<br>'+
            '<b>*También se anularán los alejamientos asociados al nombramiento</b>',
        confirmText: 'Anular Nombramiento',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/admin/person_units/" + id + "/nullify_appointment.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Nombramiento anulado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                this.isLoading = false;
                this.isNullifyActive = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Nombramiento no pudo ser anulado</b><br>'+error.response.data["base"][0],
              duration: 5000,
              type: 'is-damger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },
    execShowModalDistance(distance_id){
      this.selected = distance_id;
      this.showName = "Distance"
      this.title = "Alejamiento"+" - "+this.person_name
      this.isShowDistanceModalActive = true;
    },
    execShowModal(id){
      this.selected = id;
      let person_unit = this.getEstablishment(id)
      if(person_unit.establishment!== undefined){
        if(person_unit.establishment.name==="ACADEMICOS") {
          this.showName = "AppointmentAcademic"
          this.title = "Nombramiento Estamento Académico"+" - "+this.person_name
        }
        else if (person_unit.establishment.name==="ADMINISTRATIVOS") {
          this.showName = "AppointmentAdministrative"
          this.title = "Nombramiento Estamento Administrativo"+" - "+this.person_name
        }
        else if (person_unit.establishment.name==="HONORARIOS") {
          this.showName = "AppointmentFeeContract"
          this.title = "Nombramiento Estamento Honorarios"+" - "+this.person_name
        }
        else {
          this.showName = "AppointmentHours"
          this.title = "Nombramiento Estamento Profesor Horas Clases"+" - "+this.person_name
        }
      }
      else{
        this.showName = "AppointmentAdministrative"
      }
      //console.log("APPOINTMENT "+ this.showName)
      this.isShowModalActive = true;
    },
    getEstablishment(selected){
      return this.person_units.find(o => o.id === selected);
    },
    // yyyy/mm/dd -> dd/mm/yyyy
    formatDate,
    searchEndDate(row,input){
      return searchDate(row.end_date,input)
    },
    searchResolution(row,input){
      return searchDate(row.resolution_date,input)
    },
    searchStart(row,input){
      return searchDate(row.start_date,input)
    }
  },
  watch:{
    isShowModalActive: function () {
      if (!this.isShowModalActive) {
        this.initialize()
      }
    },
    isNullifyActive: function () {
      if (!this.isNullifyActive){
        this.initialize()
      }
    },
    isRedoActive: function () {
      if (!this.isRedoActive){
        this.initialize()
      }
    }
  }
}
</script>

<style>
tr.is-active {
  /*background: #7957d5;
  color: #fff;*/
}
</style>