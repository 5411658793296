var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [(Object.keys(_vm.errores_validacion).length != 0)?_c('div',{staticClass:"mb-2"},[_c('b-message',{attrs:{"type":"is-danger","has-icon":""}},_vm._l((_vm.errores_validacion),function(item,index){return _c('div',{key:index},[_vm._v("\n                    "+_vm._s(item[0])+"\n                ")])}),0)],1):_vm._e(),_vm._v(" "),_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveSocialnetworks)}}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"protocol|min:3|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Linkedin","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"expanded":"","placeholder":"Ejemplo https://www.linkedin.com/"},model:{value:(_vm.person.linkedin),callback:function ($$v) {_vm.$set(_vm.person, "linkedin", $$v)},expression:"person.linkedin"}}),_vm._v(" "),_c('b-button',{attrs:{"tag":"a","href":_vm.person.linkedin ? _vm.person.linkedin : 'https://www.linkedin.com/',"target":"_blank","icon-left":"external-link-alt","type":"is-info","label":"Link"}})],1)]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"protocol|min:3|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Instagram","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"expanded":"","placeholder":"Ejemplo https://www.instagram.com/"},model:{value:(_vm.person.instagram),callback:function ($$v) {_vm.$set(_vm.person, "instagram", $$v)},expression:"person.instagram"}}),_vm._v(" "),_c('b-button',{attrs:{"tag":"a","href":_vm.person.instagram ? _vm.person.instagram : 'https://www.instagram.com/',"target":"_blank","icon-left":"external-link-alt","type":"is-info","label":"Link"}})],1)]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"protocol|min:3|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Facebook","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"expanded":"","placeholder":"Ejemplo https://www.facebook.com/"},model:{value:(_vm.person.facebook),callback:function ($$v) {_vm.$set(_vm.person, "facebook", $$v)},expression:"person.facebook"}}),_vm._v(" "),_c('b-button',{attrs:{"tag":"a","href":_vm.person.facebook ? _vm.person.facebook : 'https://www.facebook.com/',"target":"_blank","icon-left":"external-link-alt","type":"is-info","label":"Link"}})],1)]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"protocol|min:3|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"X (Twitter)","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"expanded":"","placeholder":"Ejemplo https://www.twitter.com/"},model:{value:(_vm.person.twitter),callback:function ($$v) {_vm.$set(_vm.person, "twitter", $$v)},expression:"person.twitter"}}),_vm._v(" "),_c('b-button',{attrs:{"tag":"a","href":_vm.person.twitter ? _vm.person.twitter : 'https://www.x.com/',"target":"_blank","icon-left":"external-link-alt","type":"is-info","label":"Link"}})],1)]}}],null,true)})],1)]),_vm._v(" "),(_vm.can_update)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column has-text-right"},[_c('b-button',{attrs:{"label":"Guardar Redes Sociales","type":"is-primary","icon-left":"save","native-type":"submit"},on:{"click":_vm.checkValidation}})],1)]):_vm._e()])]}}])}),_vm._v(" "),_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }