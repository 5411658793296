<template>
  <section>
    <div class="columns">
      <div class="column is-12">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
            <b-message type="is-danger" has-icon>
              <div v-for="(item,index) in errores_validacion" :key="index">
                {{ item[0] }}
              </div>
            </b-message>
          </div>
          <form @submit.prevent="handleSubmit(saveDisclosure)">
            <div class="modal-card" style="width: auto">
              <header v-if="!hideHeader" class="modal-card-head">
                <p class="modal-card-title">{{modalHeader}}</p>
                <button
                    type="button"
                    class="delete"
                    @click="$emit('close')"/>
              </header>
              <section class="modal-card-body" ref="modalPersonForm">
                <section v-if="isUpdatingAndDeletingParticipant(false)!=undefined">
                  <b-steps v-model="activeStep"
                           :animated="isAnimated"
                           :rounded="isRounded"
                           :has-navigation="hasNavigation"
                           :icon-prev="prevIcon"
                           :icon-next="nextIcon"
                           :label-position="labelPosition"
                           :mobile-mode="mobileMode">
                    <b-step-item v-if="isUpdatingAndDeletingParticipant(false)"
                                 step="1" label="General" :clickable="isStepsClickable">
                      <h1 class="title has-text-centered">General [1/2]</h1>

                      <div class="columns">
                        <div class="column">
                          <b-field label="Nombre invención*">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                              <b-input v-model="disclosures.name" type="text"></b-input>
                              <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>
                        <div class="column">
                          <b-field label="Fuente Financiamiento">
                            <multiselect v-model="disclosures.financing_source"
                                         :options="fuentes_financiamientos"
                                         placeholder="Seleccione fuente de financiamiento"
                                         selectLabel="Presione para seleccionar"
                                         selectedLabel="Seleccionado"
                                         deselectLabel="Presione para deseleccionar"
                                         :allow-empty="true">
                              <template v-slot:noOptions>
                                No existen datos
                              </template>
                              <span slot="noResult">
                      No se encontraron elementos.
                  </span>
                            </multiselect>
                          </b-field>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column">
                          <b-field label="Fecha de Publicación">
                            <b-datepicker
                                v-model="disclosures.disclosure_date"
                                icon="calendar-alt"
                                trap-focus
                                locale="es-ES"
                                editable
                                placeholder="Formato de Fecha: dd/mm/aaaa"
                            >
                            </b-datepicker>
                          </b-field>
                        </div>
                        <div class="column">
                          <b-field label="Fecha Invención">
                            <b-datepicker
                                v-model="disclosures.invention_date"
                                icon="calendar-alt"
                                trap-focus
                                locale="es-ES"
                                editable
                                placeholder="Formato de Fecha: dd/mm/aaaa"
                            >
                            </b-datepicker>
                          </b-field>
                        </div>
                      </div>
                      <div class="columns">
                        <!--            <div class="column">
                                      <b-field label="Código Proyecto">
                                        <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                                          <b-numberinput min="0" v-model="disclosures.project_id" type="text"></b-numberinput>
                                          <span class="validation-alert">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                      </b-field>
                                    </div>-->
                        <div class="column">
                          <b-field label="Código*">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                              <b-input v-model="disclosures.code" type="text"></b-input>
                              <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>
                      </div>
                    </b-step-item>
                    <b-step-item step="2" label="Participantes" :clickable="isStepsClickable">
                      <h3 class="title has-text-centered">Participantes [2/2]
                        <b-tooltip label="En esta sección debe ingresar las y los investigadores del Disclosure que
               se encuentren registrados en el sistema, de esta manera, los datos ingresados de este Disclosure quedarán asociados a esta persona."
                                   position="is-right"
                                   size="is-medium"
                                   multilined>
                          <b-icon
                              pack="fas"
                              icon="info-circle"
                              size="is-small"
                              type="is-info">
                          </b-icon>
                        </b-tooltip>
                      </h3>
                      <div v-if="queryType=='update'" class="columns is-centered">
                        <div class="column is-centered has-text-centered">
                          <b-message
                              title="Información importante"
                              type="is-warning"
                              aria-close-label="Cerrar mensaje">
                            Solo la/el usuaria/o que es Dueña o Dueño de esta actividad académica tiene permisos para editar todos sus atributos.<br>
                            Quienes participan en la actividad académica pero no son dueños, solo pueden editar su participación.
                            <br><span v-if="disclosures.creator_name">Origen de la información: {{ disclosures.creator_name }}</span>
                          </b-message>
                        </div>
                      </div>
                      <div v-if="isUpdatingAndDeletingParticipant(false)" class="is-pulled-right">
                        <b-button class="mt-2" @click.prevent="addInvestigador(disclosureId)" type="is-primary">Administrar Participantes</b-button>
                      </div>
                      <div class = "column">
                        <h3 class="is-size-4 has-text-weight-bold">Tabla Resumen Participantes</h3>
                        <b-table
                            :data="inventores"
                            :paginated=true
                            per-page=5
                            striped
                            hoverable
                            :current-page="currentPage"
                            :pagination-simple=false
                            pagination-position="bottom"
                            default-sort-direction="asc"
                            sort-icon="arrow-up"
                            sort-icon-size="is-small"
                            default-sort="inventores.name"
                            aria-next-label="Siguiente"
                            aria-previous-label="Anterior"
                            aria-page-label="Página"
                            aria-current-label="Página actual">
                          <b-table-column
                              field="name"
                              label="Nombres"
                              sortable
                              searchable>
                            <template
                                slot="searchable"
                                slot-scope="props">
                              <b-input

                                  v-model="props.filters[props.column.field]"
                                  placeholder=" Buscar"
                                  icon="search"
                                  size="is-small" />
                            </template>
                            <template v-slot="props">
                              {{ props.row.name}}
                            </template>
                          </b-table-column>
                          <b-table-column
                              field="corporate_mail"
                              label="Correo Corporativo"
                              sortable
                              searchable>
                            <template
                                slot="searchable"
                                slot-scope="props">
                              <b-input
                                  v-model="props.filters[props.column.field]"
                                  placeholder=" Buscar"
                                  icon="search"
                                  size="is-small" />
                            </template>
                            <template v-slot="props">
                              {{ props.row.corporate_mail }}
                            </template>
                          </b-table-column>
                          <b-table-column
                              field="alias"
                              label="Alias"
                              sortable
                              searchable>
                            <template
                                slot="searchable"
                                slot-scope="props">
                              <b-input

                                  v-model="props.filters[props.column.field]"
                                  placeholder=" Buscar"
                                  icon="search"
                                  size="is-small" />
                            </template>
                            <template v-slot="props">
                              {{ props.row.alias }}
                            </template>
                          </b-table-column>
                          <b-table-column
                              field="unit"
                              label="Unidad"
                              sortable
                              searchable>
                            <template
                                slot="searchable"
                                slot-scope="props">
                              <b-input
                                  v-model="props.filters[props.column.field]"
                                  placeholder=" Buscar"
                                  icon="search"
                                  size="is-small" />
                            </template>
                            <template v-slot="props">
                              {{ props.row.unit }}
                            </template>
                          </b-table-column>
                          <b-table-column
                              label="Acciones"
                              width="10"
                              v-slot="props">
                            <b-icon
                                v-if="isUpdatingAndDeletingParticipant(props.row)"
                                pack="fas"
                                icon="trash"
                                style="color: #e50000;"
                                size="is-medium"
                                @click.native="deleteParticipantButton(props.row)"/>
                          </b-table-column>
                        </b-table>
                        <hr class="dropdown-divider" aria-role="menuitem">
                        <div class="columns" v-if="queryType === 'update'">
                          <div v-if="disclosures.is_creator" class="column">
                            <h3 class="is-size-6 has-text-weight-bold">
                              Dueña(o)*
                              <b-tooltip label="Debe seleccionar al menos una Dueña o Dueño del dato que se encuentre como participante en la actividad académica. Si realiza el cambio, no podrá revertirlo."
                                         position="is-right"
                                         size="is-large"
                                         multilined>
                                <b-icon
                                    pack="fas"
                                    icon="info-circle"
                                    size="is-small"
                                    type="is-info">
                                </b-icon>
                              </b-tooltip>
                            </h3>
                            <b-field label=" ">
                              <ValidationProvider rules="required" v-slot="{ errors }">
                                <multiselect v-model="ownerId"
                                             :options="inventores.map(type => type.person_id)"
                                             :custom-label="opt => inventores.find(x => x.person_id === opt).name"
                                             placeholder="Seleccione Dueña(o)"
                                             selectLabel="Presione para seleccionar"
                                             selectedLabel="Seleccionado"
                                             deselectLabel=""
                                             :allow-empty="false">
                                  <template v-slot:noOptions>
                                    No existen datos
                                  </template>
                                  <span slot="noResult">
                          No se encontraron elementos.
                      </span>
                                </multiselect>
                                <span class="validation-alert">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </b-field>
                          </div>
                        </div>
                        <div class="columns" v-else>
                          <div class="column">
                            <h3 class="is-size-6 has-text-weight-bold">
                              Dueña(o)*
                              <b-tooltip label="Debe seleccionar al menos una Dueña o Dueño del dato que se encuentre como participante en la actividad académica. Si realiza el cambio, no podrá revertirlo."
                                         position="is-right"
                                         size="is-large"
                                         multilined>
                                <b-icon
                                    pack="fas"
                                    icon="info-circle"
                                    size="is-small"
                                    type="is-info">
                                </b-icon>
                              </b-tooltip>
                            </h3>
                            <b-field label=" ">
                              <ValidationProvider rules="required" v-slot="{ errors }">
                                <multiselect v-model="ownerId"
                                             :options="inventores.map(type => type.person_id)"
                                             :custom-label="opt => inventores.find(x => x.person_id === opt).name"
                                             placeholder="Seleccione Dueña(o)"
                                             selectLabel="Presione para seleccionar"
                                             selectedLabel="Seleccionado"
                                             deselectLabel=""
                                             :allow-empty="false">
                                  <template v-slot:noOptions>
                                    No existen datos
                                  </template>
                                  <span slot="noResult">
                          No se encontraron elementos.
                      </span>
                                </multiselect>
                                <span class="validation-alert">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </b-field>
                          </div>
                        </div>
                      </div>

                    </b-step-item>
                    <template
                        #navigation="{previous, next}">
                      <div class="columns is-pulled-right" >
                        <div class="column">
                          <b-button
                              outlined
                              icon-left="chevron-left"
                              :disabled="previous.disabled"
                              @click.prevent="previous.action">
                          </b-button>
                          <b-button
                              outlined
                              icon-right="chevron-right"
                              :disabled="next.disabled"
                              @click.prevent="next.action">
                          </b-button>

                        </div>
                      </div>

                    </template>

                  </b-steps>
                  <div class="actions has-text-right">
                    <button v-if="modalCancel" class="button is-danger" @click.prevent="$emit('close')">Cancelar</button>
                    <button class="button is-primary" type="submit" @click="checkValidation()">Guardar Disclosure</button>
                  </div>
                </section>


              </section>

            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

    <!-- MODALES -->
    <b-modal
        width="90%"
        v-model="isAddModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-inventors
            query-type="update"
            modal-header="Añadir participantes"
            :inventores="inventores"
            @close="props.close"
            @clicked="onClickChild"></modal-inventors>
      </template>
    </b-modal>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import ModalInventors from "./modal_inventors.vue"

export default {
  components:{
    'modal-inventors': ModalInventors
  },

  data() {
    return{
      isLoading: false,
      errores_validacion:{},
      canCancel: ['escape'],
      disclosures:{
        name: "",
        code: "",
        project_id: null,
        financing_source:null,
        disclosure_date:null,
        invention_date:null,
        year:null,

      },
      inventores:[],
      fuentes_financiamientos:[
        "PUBLICO",
        "PRIVADO",
        "PROPIOS",
        "PUBLICO/PRIVADO",
        "PROPIOS/PRIVADO",
        "PROPIOS/PUBLICO",
        "OTROS FONDOS"
      ],
      requiredFieldOnly:false,
      isAddModalActive: false,
      currentPage:1,
      ownerId:null,
      activeStep: 0,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      hasNavigation: true,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
    }
  },
  props:['userId','queryType','disclosureId','cargoPrincipal','unidadPrincipal','personId','modalHeader','hideHeader','modalCancel','showParticipantsFirst'],

  created() {
    this.isLoading = true
    if (this.queryType === "update"){
      axios
          .get("/disclosures/"+this.disclosureId+".json",{params:{edit_flag:true}})
          .then(res => {
            this.disclosures = res.data
            if (res.data['disclosure_date'] != null){
              this.disclosures.disclosure_date = new Date(moment(res.data['disclosure_date']).format('MM/DD/YYYY'));
            }
            if (res.data['invention_date'] != null){
              this.disclosures.invention_date = new Date(moment(res.data['invention_date']).format('MM/DD/YYYY'));
            }

            this.inventores = res.data['inventors']

            if (this.inventores.filter(person => person.person_id == res.data['ownerId']).length > 0){
              this.ownerId = res.data['ownerId']
            }
            if(this.showParticipantsFirst){
              this.activeStep=1;
            }
            this.isLoading = false
          })
          .catch(e=>{
            console.log(e);
            this.isLoading = false;
          })
    }
    else if(this.queryType==="create"){
      axios
          .get("/disclosures/new.json")
          .then(res => {
            if (this.queryType!=="update" && this.cargoPrincipal.name==="ACADEMICO" ) {
              //se añade un participante automaticamente si es que es acádemico
              this.inventores.push({
                person_id: res.data["inventor"].person_id,
                name: res.data["inventor"].name,
                alias: res.data["inventor"].alias != null ? res.data["inventor"].alias.toUpperCase() : "",
                corporate_mail: res.data["inventor"].corporate_mail != null ? res.data["inventor"].corporate_mail.toUpperCase() : "",
                unit: res.data["inventor"].unit,
                unit_id: res.data["inventor"].unit_id,
              })
            }
            this.isLoading = false;
          }).catch(error => {
        console.log(error);
        this.isLoading = false;
      })
    }
    this.isLoading = false


  },

  methods :{
    saveDisclosure(){
      this.isLoading = true;

      if(this.queryType == "create"){
        axios
            .post("/disclosures.json",{
              disclosure: this.disclosures,
              inventores: this.inventores,
              ownerId: this.ownerId
              //user_id:this.userId
            })
            .then(response => {
              this.errores_validacion = {};
              window.location.href = '/disclosures/'+response.data["id"]
              this.isLoading=false;
            }).
        catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.isLoading=false;
        })
      }
      if(this.queryType == "update"){
        this.isLoading=true;
        axios
            .put("/disclosures/"+this.disclosureId+".json",{
              disclosure: this.disclosures,
              inventores: this.inventores,
              user_id: this.userId,
              ownerId: this.ownerId
            })
            .then(response => {
              this.errores_validacion = {};
              window.location.href = '/disclosures/'+this.disclosureId
              this.isLoading=false;
            })
            .catch(error => {
              this.errores_validacion = error.response.data
              var elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              this.isLoading=false;
            })
      }
    },

    onClickChild(value){
      this.isLoading = true
      this.inventores = value
      this.isLoading = false

    },
    addInvestigador(id){
      this.selected = id;
      this.isAddModalActive = true
    },
    deleteInvestigador(inventor){
      if (inventor.person_id == this.ownerId){
        this.ownerId = null
      }
      this.inventores = this.inventores.filter(person => person.corporate_mail != inventor.corporate_mail);
    },
    async checkValidation(){
      const valid = await this.$refs.observer.validate()
      if(!valid){
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar tanto la pestaña General como la de Participantes.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
    isUpdatingAndDeletingParticipant(row){
      if(this.queryType=="create"){
        return true;
      }
      else if(this.queryType=='update'){
        //console.log(this.project.current_user_person_id, row.id,row)
        if (row){
          return this.disclosures.is_creator || this.disclosures.current_user_person_id == row.person_id;
        }
        return this.disclosures.is_creator;
      }
    },
    deleteParticipantButton(participants){

      this.$buefy.dialog.confirm({
        title: 'Eliminar Participante',
        message: 'Precaución, al momento de guardar el disclosure esta acción eliminará la asociación con la/el participante, lo que implica que ya no podrá ver este disclosure ni será contado en su actividad académica ¿Desea continuar?',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteInvestigador(participants)
      })
    },

  },



}

</script>