<template>
  <section>
    <!-- Box de Formulario function -->
    <div class="box">
      <div class="title is-4">
        Información de Asigna Funciones
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Función">
            <b-input :value="person_function.function_name" type="text" readonly></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Descripción">
            <b-input :value="person_function.description" type="textarea" readonly></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Unidad de desempeño">
            <b-input :value="person_function.unit_name" type="text" readonly></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Tipo de documento">
            <b-input :value="person_function.resolution_type_name" type="text" readonly></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Número de documento">
            <b-input :value="person_function.resolution" type="text" readonly></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Fecha de documento">
            <b-input :value="formatDate(person_function.resolution_date)" type="text" readonly></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Fecha de inicio">
            <b-input :value="formatDate(person_function.start_date)" type="text" readonly></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Fecha de término">
            <b-input :value="formatDate(person_function.end_date)" type="text" readonly></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Jornada (horas semanales)">
            <b-input :value="person_function.hours" type="text" readonly></b-input>
          </b-field>
        </div>
      </div>
      <!--        <div class="columns">
                <div class="column">
                  <b-field label="Url carpeta documentación Función">
                    <b-input expanded readonly v-model="person_function.documentation_link" type="text"></b-input>
                    <p class="control">
                      <b-button tag="a" :href="person_function.documentation_link" target="_blank" icon-left="external-link-alt" type="is-link">Link</b-button>
                    </p>
                  </b-field>
                </div>
              </div>-->
      <div class="columns">
        <div class="column">
          <b-field label="Documento asociado a la función">
            <b-input expanded readonly v-model="person_function.document_name" type="text"></b-input>
            <p v-if="person_function.can_view_pdf" class="control">
              <b-button  @click="viewFile(person_function.document_id)" icon-left="file-alt" type="is-link">Visualizar</b-button>
            </p>
          </b-field>
        </div>
      </div>
      <b-modal
          v-model="showPdf"
          :can-cancel="true"
          :destroy-on-hide="true"
          aria-modal
          aria-role="dialog"
          has-modal-card
          trap-focus
          :width="2000"
      >
        <template #default="props">
          <pdf_view
              :src="src"
              @close="props.close"
          ></pdf_view>
        </template>
      </b-modal>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
  </section>
</template>

<script>
import {formatDate} from "../../../packs/utilities"
import axios from "axios";
import Pdf_view from "../../performances/pdf_view.vue";
export default {
  name: "function_in_distance",
  components: {Pdf_view},
  props: [
    'person_name', 'assigned_function'
  ],
  data(){
    return {
      person_function: {function_name: "", description: "", hours: "", resolution: "",
      resolution_type_name: "", resolution_date: "", start_date: "", end_date: "", unit_name: "", documentation_link: ""},
      isLoading: false,
      showPdf: false,
      src:null,
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize(){
      this.isLoading=true;
      //console.log("ALGO")
      //console.log(this.assigned_function)
      this.person_function = this.assigned_function
      this.isLoading=false;
    },
    formatDate,
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            this.showPdf = true
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },

  },
  watch:{
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
    isDistanceModalActive: function () {
      if (!this.isDistanceModalActive) {
        this.initialize()
      }
    },
  }
}
</script>