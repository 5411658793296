<template>
  <section>
    <section>
      <div>
        <div class="container">
          <h1 class="is-size-2">Actividad Académica Anulada</h1>
          <b-tabs position="is-centered" @input="changeTab" :destroy-on-hide="true" v-model="currentTab">
            <b-tab-item v-for="item in items"
                        v-bind:key="item.content"
                        :value="item.content"
                        :label="item.tab"
                        :icon="item.icon">
            </b-tab-item>
          </b-tabs>
          <!-- COMPONENTE AQUI -->
          <component v-bind:is="currentTabComponent" :cargo-principal="cargoPrincipal"></component>

          <!-- FIN COMPONENTE -->
        </div>
      </div>
    </section>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>

import nullified_project from "./projects/nullified_project";
import nulliefied_publication from "./publications/nulliefied_publication";
import nulliefied_patent from "./patents/nulliefied_patent";
import nullified_disclosure from "./disclosure/nullified_disclosure";
import nullified_book from "./books/NullifiedBook";

export default {
  name: 'nullified_activity',
  components:{
    nullified_project, nulliefied_publication, nulliefied_patent, nullified_disclosure, nullified_book
  },
  props:[
      'cargoPrincipal'
  ],
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      years:[],
      year:null,
      isNullifyActive: false,
      currentTab: 'nullified_project',
      items: [
        {tab: 'Proyectos',content:'nullified_project',icon:"folder-open"},
        {tab: 'Publicaciones',content:'nulliefied_publication',icon:"file-alt"},
        {tab: 'Propiedad intelectual',content:'nulliefied_patent',icon:"lightbulb"},
        {tab: 'Libros',content: 'nullified_book', icon:"book"},
        {tab: 'Disclosures',content: 'nullified_disclosure', icon:"folder"},

      ],
    }
  },
  created(){

  },
  methods:{
    changeTab: function(value){
      this.currentTab = value
    },

  },
  computed:{
    currentTabComponent: function () {
      return this.currentTab
    }
  }
}
</script>