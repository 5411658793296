<template>
<section>
  <div class="modal-card" style="width:100%">
    <header class="modal-card-head">
      <p class="modal-card-title">{{modalHeader}}</p>
      <button
          type="button"
          class="delete"
          @click="$emit('close')"/>
    </header>
    <section class="modal-card-body" ref="modalPersonForm" style="width:100%">
      <div class="columns">
        <div class="column is-12">

              <!-- Box de Formulario Project -->
              <div class="box">

                <div class="columns">
                  <div class="column">
                    <b-field label="Entidad Externa">
                      <b-input readonly v-model="partner_entity.name"></b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Tipo de asociación*">
                      <b-input readonly :value="partner_entity.association_type ? partner_entity.association_type.name : ''"></b-input>
                    </b-field>
                  </div>
                </div>


                <div class="columns">
                  <div class="column">
                    <b-field label="Nombre de contacto">
                      <b-input readonly v-model="partner_entity.contact_name"></b-input>
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <b-field label="Telefono de contacto">
                      <b-input readonly v-model="partner_entity.contact_phone"></b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Correo de contacto">
                      <b-input readonly v-model="partner_entity.contact_email"></b-input>
                    </b-field>
                  </div>
                </div>


                <div class="actions has-text-right">
                  <button class="button is-danger" icon-left="trash" @click.prevent="$emit('close')">Cerrar</button>
                </div>

              </div>


        </div>
      </div>
    </section>
  </div>
  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
</section>
</template>

<script>
export default {
  name: "ShowAssociatedEntity",
  data() {
    return {
      isImageModalActive: false,
      isCardModalActive: false,
      canCancel: ['escape'],
      isLoading: false,
    }
  },
  props:[ 'modalHeader', 'project','partner_entity' ],
}
</script>

<style scoped>

</style>