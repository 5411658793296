import moment from 'moment'
import { NotificationProgrammatic as Notification } from 'buefy'

/**
 * Funcion que busca coincidencias dentro de una fecha. Esta fecha se formatea a string
 * con el formato DD-MM-YYYY y se le realiza un match con el input.
 * @param {moment} date recibe como parametro una fecha de tipo objeto creado por moment.
 * @param {string} input recibe un string del cual se le hara la busqueda dentro de la fecha ya formateada a string.
 * @return {boolean} retorna un booleano que indica si el input esta incluida en la fecha.
 */
export function searchDate(date, input){
    return input && date.format("DD-MM-YYYY") &&  date.format("DD-MM-YYYY").includes(input)
}

export function formatDate(date){
    if (date!=null && date!=="" && date!==undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
    }
    else{
        return ""
    }
}

// Listado de meses en español
export const monthNames = [
    "Enero", 
    "Febrero", 
    "Marzo", 
    "Abril", 
    "Mayo", 
    "Junio", 
    "Julio",
    "Agosto", 
    "Septiembre", 
    "Octubre", 
    "Noviembre", 
    "Diciembre"
];

/**
 * Función que obtiene el nombre del mes a partir de una fecha del formato "dd-mm-yyyy".
 * @param {string} date - Recibe como parámetro una fecha de tipo string en formato "dd-mm-yyyy".
 * @return {string} Retorna una cadena de texto que representa el nombre del mes de la fecha proporcionada, o una cadena vacía si la fecha no es válida o es un valor falsy.
 */
export function getMonthNameFromDateString(dateString) {
    if (!dateString) {
        return '';
    }
    const monthIndex = moment(dateString).month();
    return monthNames[monthIndex];
} 

/**
 * Función que formatea una fecha del formato "yyyy-mm-dd" a una cadena de texto local con el formato DD-MM-YYYY.
 * @param {string} dateString - Recibe como parámetro una fecha de tipo string en formato "yyyy-mm-dd".
 * @return {string} Retorna una cadena de texto que representa la fecha formateada de acuerdo al formato local, o una cadena vacía si la fecha no es válida o es un valor falsy.
 */
export function formatDateToLocaleString(dateString) {
    if (!dateString) {
        return '';
    }
    return new Date(moment(dateString)).toLocaleDateString("es-CL")
}

function propertyToString(name){
    switch (name) {
        case "unit_id":
            return "Unidad de Desempeño";
        case "start_date":
            return "Fecha de Inicio"
        case "end_date":
            return "Fecha de Término";
        case "quality":
            return "Calidad";
        case "taken_hours":
            return "Horas";
        case "appointment_type_id":
            return "Tipo de Nombramiento";
        case "year":
            return "Año";
        case "semester":
            return "Semestre";
        case "working_day_id":
            return "Jornada";
        case "grade_id":
            return "Grado";
        case "level_id":
            return "Nivel";
        case "category_id":
            return "Categoría";
        case "function_name":
            return "Función";
        case "resolution_type_id":
            return "Tipo de Resolución";
        case "resolution":
            return "N° Documento";
        case "resolution_date":
            return "Fecha de Documento";
        case "documentation_link":
            return "Url documentación";
        case "contracting_unit_id":
            return "Unidad de Contratación";
        case "work_plant_id":
            return "Cargo";
        case "amount":
            return "Monto";
        case "total_amount":
            return "Monto Total";
        case "payment":
            return "Tipo de Pago";
        case "budget_allocation_cc":
            return "CC imputación presupuestaria";
        case "item":
            return "Item imputación presupuestaria";
        case "schedule":
            return "Horario";
        case "hierarchy_id":
            return "Jerarquía";
        case "rank_id":
            return "Rango";
        case "performance_area":
            return "Área de Desempeño"
        case "hr_subjects":
            return "Asignaturas"
        default:
            return ""
    }


}
function compareHrSubjects(oldObject, newObject){
    var old_string = ""
    var new_string = ""
    for(var i =0; i<oldObject["hr_subjects"].length;i++){
        if(oldObject["hr_subjects"][i]){
            if(oldObject["hr_subjects"][i]["id"]){
                if(i===0){
                    old_string = old_string +" "+ oldObject["all_hr_subjects"].find(o => o["id"] === oldObject["hr_subjects"][i]["id"]).name;
                }
                else{
                    old_string = old_string +" - "+ oldObject["all_hr_subjects"].find(o => o["id"] === oldObject["hr_subjects"][i]["id"]).name;
                }
            }
        }
    }
    for(var j= 0; j<newObject["hr_subjects"].length;j++){
        if(newObject["hr_subjects"][j]){
            if(newObject["hr_subjects"][j]["id"]){
                if(j===0){
                    new_string = new_string +" "+newObject["all_hr_subjects"].find(o => o["id"] === newObject["hr_subjects"][j]["id"]).name
                }
                else{
                    new_string = new_string +" - "+newObject["all_hr_subjects"].find(o => o["id"] === newObject["hr_subjects"][j]["id"]).name
                }
            }
        }
    }
    return !(old_string === new_string)
}
function nameValue(name, oldObject, newObject){
    switch (name) {
        case "unit_id":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject["contract_units"].find(o => o["id"] === oldObject[name]).name;
            }
            if(newObject[name]){
                new_value = newObject["contract_units"].find(o => o["id"] === newObject[name]).name
            }
            return old_value +" a "+new_value
        case "start_date":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = moment(oldObject[name] , ["YYYY-MM-DD","DD-MM-YYYY"]).format("DD-MM-YYYY")
            }
            if(newObject[name]){
                new_value = moment(newObject[name] , ["YYYY-MM-DD","DD-MM-YYYY"]).format("DD-MM-YYYY")
            }
            return old_value +" a "+new_value
        case "end_date":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = moment(oldObject[name] , ["YYYY-MM-DD","DD-MM-YYYY"]).format("DD-MM-YYYY")
            }
            if(newObject[name]){
                new_value = moment(newObject[name] , ["YYYY-MM-DD","DD-MM-YYYY"]).format("DD-MM-YYYY")
            }
            return old_value +" a "+new_value
        case "quality":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject[name]
            }
            if(newObject[name]){
                new_value = newObject[name]
            }
            return old_value +" a "+new_value
        case "taken_hours":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject[name]
            }
            if(newObject[name]){
                new_value = newObject[name]
            }
            return old_value +"hrs a "+new_value+"hrs"
        case "year":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject[name]
            }
            if(newObject[name]){
                new_value = newObject[name]
            }
            return old_value +" a "+new_value
        case "semester":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject[name]
            }
            if(newObject[name]){
                new_value = newObject[name]
            }
            return old_value +" a "+new_value
        case "working_day_id":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject["working_days"].find(o => o["id"] === oldObject[name]).hired_hours;
            }
            if(newObject[name]){
                new_value = newObject["working_days"].find(o => o["id"] === newObject[name]).hired_hours
            }
            return old_value +"hrs a "+new_value+"hrs"
        case "appointment_type_id":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject["appointment_types"].find(o => o["id"] === oldObject[name]).name;
            }
            if(newObject[name]){
                new_value = newObject["appointment_types"].find(o => o["id"] === newObject[name]).name
            }
            return old_value +" a "+new_value
        case "grade_id":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject["grades"].find(o => o["id"] === oldObject[name]).name;
            }
            if(newObject[name]){
                new_value = newObject["grades"].find(o => o["id"] === newObject[name]).name
            }
            return old_value +" a "+new_value
        case "rank_id":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject["ranks"].find(o => o["id"] === oldObject[name]).name;
            }
            if(newObject[name]){
                new_value = newObject["ranks"].find(o => o["id"] === newObject[name]).name
            }
            return old_value +" a "+new_value
        case "level_id":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject["levels"].find(o => o["id"] === oldObject[name]).name;
            }
            if(newObject[name]){
                new_value = newObject["levels"].find(o => o["id"] === newObject[name]).name
            }
            return old_value +" a "+new_value
        case "category_id":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject["categories"].find(o => o["id"] === oldObject[name]).name;
            }
            if(newObject[name]){
                new_value = newObject["categories"].find(o => o["id"] === newObject[name]).name
            }
            return old_value +" a "+new_value
        case "function_name":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject[name].name
            }
            if(newObject[name]){
                new_value = newObject[name].name
            }
            return old_value +" a "+new_value
        case "resolution_type_id":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject["resolution_types"].find(o => o["id"] === oldObject[name]).name;
            }
            if(newObject[name]){
                new_value = newObject["resolution_types"].find(o => o["id"] === newObject[name]).name
            }
            return old_value +" a "+new_value
        case "resolution":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject[name]
            }
            if(newObject[name]){
                new_value = newObject[name]
            }
            return old_value +" a "+new_value
        case "resolution_date":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = moment(oldObject[name] , ["YYYY-MM-DD","DD-MM-YYYY"]).format("DD-MM-YYYY")
            }
            if(newObject[name]){
                new_value = moment(newObject[name] , ["YYYY-MM-DD","DD-MM-YYYY"]).format("DD-MM-YYYY")
            }
            return old_value +" a "+new_value
        case "documentation_link":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject[name]
            }
            if(newObject[name]){
                new_value = newObject[name]
            }
            return old_value +" a "+new_value
        case "contracting_unit_id":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject["contract_units"].find(o => o["id"] === oldObject[name]).name;
            }
            if(newObject[name]){
                new_value = newObject["contract_units"].find(o => o["id"] === newObject[name]).name;
            }
            return old_value +" a "+new_value;
        case "work_plant_id":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject["work_plants"].find(o => o["id"] === oldObject[name]).name;
            }
            if(newObject[name]){
                new_value = newObject["work_plants"].find(o => o["id"] === newObject[name]).name
            }
            return old_value +" a "+new_value
        case "amount":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject[name]
            }
            if(newObject[name]){
                new_value = newObject[name]
            }
            return old_value +" a "+new_value
        case "total_amount":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject[name]
            }
            if(newObject[name]){
                new_value = newObject[name]
            }
            return old_value +" a "+new_value
        case "payment":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject[name]
            }
            if(newObject[name]){
                new_value = newObject[name]
            }
            return old_value +" a "+new_value
        case "budget_allocation_cc":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject[name]
            }
            if(newObject[name]){
                new_value = newObject[name]
            }
            return old_value +" a "+new_value
        case "item":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject[name]
            }
            if(newObject[name]){
                new_value = newObject[name]
            }
            return old_value +" a "+new_value
        case "schedule":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject[name]
            }
            if(newObject[name]){
                new_value = newObject[name]
            }
            return old_value +" a "+new_value
        case "hierarchy_id":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject["hierarchies"].find(o => o["id"] === oldObject[name]).name;
            }
            if(newObject[name]){
                new_value = newObject["hierarchies"].find(o => o["id"] === newObject[name]).name
            }
            return old_value +" a "+new_value
        case "performance_area":
            var old_value = ""
            var new_value = ""
            if(oldObject[name]){
                old_value = oldObject[name]
            }
            if(newObject[name]){
                new_value = newObject[name]
            }
            return old_value +" a "+new_value
        case "hr_subjects":
            var old_value = ""
            var new_value = ""
            for(var i =0; i<oldObject[name].length;i++){
                if(oldObject[name][i]){
                    if(oldObject[name][i]["id"]){
                        if(i===0){
                            old_value = old_value +" "+ oldObject["all_hr_subjects"].find(o => o["id"] === oldObject[name][i]["id"]).name;
                        }
                        else{
                            old_value = old_value +" - "+ oldObject["all_hr_subjects"].find(o => o["id"] === oldObject[name][i]["id"]).name;
                        }
                    }
                }
            }
            for(var j= 0; j<newObject[name].length;j++){
                if(newObject[name][j]){
                    if(newObject[name][j]["id"]){
                        if(j===0){
                            new_value = new_value +" "+newObject["all_hr_subjects"].find(o => o["id"] === newObject[name][j]["id"]).name
                        }
                        else{
                            new_value = new_value +" - "+newObject["all_hr_subjects"].find(o => o["id"] === newObject[name][j]["id"]).name
                        }
                    }
                }
            }
            return old_value +" a "+new_value
        default:
            return ""
    }


}
export function compareObjects(oldObject, newObject, exclude){
    var array_diferencias = []
    var array_dates = ["start_date", "end_date", "resolution_date", "decree_date"]
    for (const property in newObject) {
        if(!exclude.includes(property)){
            if(array_dates.includes(property) && oldObject[property]!==null && newObject[property]!==null){
                if (oldObject[property].getTime() !== newObject[property].getTime()){
                    let string = propertyToString(property)+ " cambió de: "+ nameValue(property, oldObject,newObject)
                    array_diferencias.push(string)
                }
            }
            else if(property==="hr_subjects"){
                if (compareHrSubjects(oldObject, newObject)){
                    let string = propertyToString(property)+ " cambió de: "+nameValue(property, oldObject,newObject)
                    array_diferencias.push(string)
                }
            }
            else{
                if (oldObject[property] !== newObject[property]){
                    let string = propertyToString(property)+ " cambió de: "+nameValue(property, oldObject,newObject)
                    array_diferencias.push(string)
                }
            }
        }

    }
    console.log(array_diferencias)
    return array_diferencias
}
export function formatString(array){
    let string = ""
    for(var i = 0; i<array.length;i++){
        let partial = array[i]
        if(i===0){
            string = string + partial
        }
        else{
            string = string +"\n"+ partial
        }


    }
    return string

}
export function setIntervals(interval, anio, usuario){
    const today = moment();
    let interval_end = ""
    let interval_start = ""
    let text = ""
    if(interval==="DIA"){
        interval_start = today.startOf("day").format("DD/MM/YYYY")
        interval_end = today.endOf("day").format("DD/MM/YYYY")
        text = "Fechas entre: "+interval_start.toString()+" - "+interval_end.toString()
    }
    else if(interval==="SEMANA"){
        interval_start = today.startOf("week").format("DD/MM/YYYY")
        interval_end = today.endOf("week").format("DD/MM/YYYY")
        text = "Fechas entre: "+interval_start.toString()+" - "+interval_end.toString()
    }
    else if(interval==="MES"){
        interval_start = today.startOf("month").format("DD/MM/YYYY")
        interval_end = today.endOf("month").format("DD/MM/YYYY")
        text = "Fechas entre: "+interval_start.toString()+" - "+interval_end.toString()
    }
    else if(interval==="AÑO"){//AÑO
        if(anio!==null && anio!==undefined && anio!=="ACTUAL"){
            today.set({'year': anio})
        }
        interval_start = today.startOf("year").format("DD/MM/YYYY")
        interval_end = today.endOf("year").format("DD/MM/YYYY")
        text = "Fechas entre: "+interval_start.toString()+" - "+interval_end.toString()
    }
    else{
        text = "Se muestran todos los datos históricos"
    }
    if(usuario){
        text = text + ". " +
            "Se muestran los datos correspondientes al usuario: "+usuario.show_name
    }
    return text
}
export function createYears(){
    var i = 0;
    var actualDate = new Date()
    var array = ["ACTUAL"]
    for(i = 0;i<10; i++){
        array.push(actualDate.getFullYear()-1-i)
    }
    return array
}
export function generateArrayOfYears() {
    var max = new Date().getFullYear()
    var min = 1980
    var years = []
    years.push("Todo")
    for (var i = max; i >= min; i--) {
        years.push(i)
    }
    return years
}
export function generateGrid(document_categories){
    var matrix = []
    var row_array = []
    if(document_categories.length <= 3){
        matrix.push(document_categories)
    }
    else{
        for(var i = 0; i<document_categories.length;i++){
            row_array.push(document_categories[i])
            if(i!== 0 && (i+1)%3===0 && i!==document_categories.length-1){
                matrix.push(row_array)
                row_array = []
            }
            else if(i!== 0 && (i+1)%3!==0 && i===document_categories.length-1){
                matrix.push(row_array)
                row_array = []
            }
            else if(i!==0 && (i+1)%3===0 && i===document_categories.length-1){
                matrix.push(row_array)
                row_array = []
            }
        }
    }
    return matrix
}

export function parse_drive_link(drive_link){
    var google_id = ""
    if(drive_link.includes("https://drive.google.com/file/d/")){
        let array_string = drive_link.split("https://drive.google.com/file/d/")
        if(array_string.length === 2){
            let second_array_string = array_string[1].split("/")
            if(second_array_string){
                google_id = second_array_string[0]
            }
        }
    }
    else if(drive_link.includes("https://drive.google.com/document/d/")){
        let array_string = drive_link.split("https://drive.google.com/document/d/")
        if(array_string.length === 2){
            let second_array_string = array_string[1].split("/")
            if(second_array_string){
                google_id = second_array_string[0]
            }
        }
    }
    else if(drive_link.includes("https://docs.google.com/document/d/")){
        let array_string = drive_link.split("https://docs.google.com/document/d/")
        if(array_string.length === 2){
            let second_array_string = array_string[1].split("/")
            if(second_array_string){
                google_id = second_array_string[0]
            }
        }
    }
    else if(drive_link.includes("https://docs.google.com/spreadsheets/d/")){
        let array_string = drive_link.split("https://docs.google.com/spreadsheets/d/")
        if(array_string.length === 2){
            let second_array_string = array_string[1].split("/")
            if(second_array_string){
                google_id = second_array_string[0]
            }
        }
    }
    return google_id
}

export function request_from_appointment(person_unit){
    var request = {}
    if(person_unit){
        request = {
            resolution: person_unit.resolution,
            resolution_type_id: person_unit.resolution_type_id,
            documentation_link: person_unit.documentation_link,
            description: person_unit.description,
            name: "Solicitud nombramiento individual",
            resolution_date: person_unit.resolution_date,
            start_date: person_unit.start_date,
            end_date: person_unit.end_date,
            request_type_id: 1,
            semester: person_unit.semester,
            year: person_unit.year
        }
        return request;
    }
    else{
        return request;
    }
}

export function request_from_appointment_massive(person_unit){
    var request = {}
    if(person_unit){
        request = {
            resolution: person_unit.resolution,
            resolution_type_id: person_unit.resolution_type_id,
            documentation_link: person_unit.documentation_link,
            description: person_unit.description,
            name: "Solicitud nombramiento masiva",
            resolution_date: person_unit.resolution_date,
            start_date: person_unit.start_date,
            end_date: person_unit.end_date,
            request_type_id: 2,
            semester: person_unit.semester,
            year: person_unit.year
        }
        return request;
    }
    else{
        return request;
    }
}

/**
 * Función que devuelve el color correspondiente a cada estado.
 * Los colores son representativos del estado y se usan para mejorar la experiencia de usuario.
 * @param {string} state recibe como parámetro el estado de la solicitud.
 * @return {string} retorna un string con la clase de color correspondiente al estado. Si el estado no es reconocido, retorna 'is-light'.
 */
export function colorTypeByState(state) {
    switch (state.toUpperCase().trim()) {
      case "EN CURSO":
        return "is-primary";
      case "APROBADO":
        return "is-success";
      case "RECHAZADO":
        return "is-danger";
      case "AJUSTAR":
        return "is-warning";
      case "CREADO":
        return "is-info";
      case "CORREGIDO":
        return "is-success is-light";
      case "ANULADO":
        return "is-black is-light";
      default:
        return "is-light";
    }
  }

 /**
 * Función que devuelve el ícono correspondiente a cada estado.
 * Los íconos son representativos del estado y se usan para mejorar la experiencia de usuario.
 * @param {string} state recibe como parámetro el estado de la solicitud.
 * @return {string} retorna un string con el nombre del ícono correspondiente al estado. Si el estado no es reconocido, retorna 'info-circle'.
 */
export function iconByState(state) {
    switch (state.toUpperCase().trim()) {
      case "EN CURSO":
        return "hourglass-half";
      case "APROBADO":
        return "check-circle";
      case "RECHAZADO":
        return "times-circle";
      case "AJUSTAR":
        return "wrench";
      case "CREADO":
        return "file-alt"; 
      case "CORREGIDO":
        return "check-square";
      case "ANULADO":
        return "ban";
      default:
        return "info-circle"; 
    }
  }
/**
 * Función que maneja los errores de red, mostrando una notificación al usuario.
 * Esta función mejora la experiencia del usuario al proporcionar retroalimentación sobre problemas de conexión.
 * @param {object} error recibe como parámetro el objeto de error generado por Axios.
 * @return {boolean} retorna `true` si el error es un error de red, `false` en caso contrario.
 */
export function handleNetworkError(error) {
    if (
      error.message === "Network Error" ||
      (error.response && error.response.status === 0)
    ) {
      Notification.open({
        message:
          "Se ha perdido la conexión con el servidor. Por favor, verifica tu conexión a internet y vuelve a intentarlo.",
        type: "is-danger",
        size: "is-medium",
        "has-icon": true,
        icon: "plug",
        position: "is-bottom-right",
        indefinite: true,
        queue: true,
      });
      return true;
    }
    return false;
  }