<template>
  <section>
    <div class="columns is-centered">
        <div class="column">
          <div class="has-text-right">
            <b-button type="is-info"
                      icon-left="edit"
                      v-if="service.can_edit"
                      @click="editService()">
              Editar Servicio de EFUSACH
            </b-button>
            <b-button v-if="service.can_nullify_service" type="is-danger"
                      icon-left="ban"
                      @click="execNullifyModal">
              Anular Servicio de EFUSACH
            </b-button>
          </div>
          <hr class="dropdown-divider" aria-role="menuitem">
          <div class="columns">
            <div class="column">
              <b-field label="Tipo de servicio">
                <b-input readonly v-model="service.sdt_service_type" type="text"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Unidad">
                <b-input readonly v-model="service.unit" type="text"></b-input>
              </b-field>
            </div>
                        
          </div>

          <div class="columns">
            <div class="column">
              <b-field label="Nombre">
                <b-input readonly v-model="service.name" type="text"></b-input>
              </b-field>
            </div>
          </div>
            
          <div class="columns">
            <div class="column">
              <b-field label="Código de EFUSACH asociado">
                <b-input readonly v-model="service.code" type="text"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Año de creación">
                <b-input readonly v-model="service.award_year" type="text" expanded></b-input>
              </b-field>
            </div>
            
          </div>
          <div class="columns">
            
            <div class="column">
              <b-field label="Fecha Inicio">
                <b-input readonly v-model="service.start_date" type="text"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Fecha Término">
                <b-input readonly v-model="service.end_date" type="text"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Cliente">
                <b-input readonly v-model="service.partner_entity" type="text"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <b-field label="Objetivo general">
                  <b-input readonly
                           type="textarea"
                           v-model="service.description"
                           :has-counter="false">
                  </b-input>
                </b-field>
              </div>
            </div>
          </div>
          
          
        </div>
      </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        :active="isComponentModalActive"
        width="90%"
        :can-cancel="canCancel"
        aria-modal>
      <template #default="props">
        <new-service-form modal-cancel="true"
                          :cargo-principal="cargoPrincipal"
                          :user-id="userId"
                          modal-header="Editar Servicio de EFUSACH"
                          query-type="update" v-bind:service-id="service.id" v-bind:service-table="service.table" 
                          @close="closeModal"
                          @clicked="updateData"></new-service-form>
      </template>
    </b-modal>



  </section>
</template>
<script>
import axios from "axios";
import NewServiceForm from "./NewServiceForm";
import moment from "moment";

export default {
  name: "ShowGeneralTab",
  components: {NewServiceForm},
  props:['cargoPrincipal','userId','serviceId','service','participantes', 'serviceTable'],
  data(){
    return{
      currentPage:1,
      isLoading:false,
      requiredFieldOnly:false,
      isComponentModalActive: false,
      canCancel: ['escape']

    }
  },

  methods:{
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    editService(){
      this.isComponentModalActive = true;
    },
    closeModal(){
      this.isComponentModalActive = false;
    },
    updateData(data){
      this.$emit("clicked",data)
    },
    execNullifyModal(){
      let ruta = ""
      if (this.serviceTable === "projects"){
        ruta = "/projects/" + this.serviceId + "/nullify_project.json"
      }
      else{
        ruta = "/sdt_services/" + this.serviceId + "/nullify_service.json"
      }
      this.$buefy.dialog.confirm({
        title: 'Anular servicio',
        message: '¿Está seguro que desea <b>anular</b> el servicio de EFUSACH? Esta acción la dejará inválido.<br>',
        confirmText: 'Anular Servicio de EFUSACH',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true
          axios
              .delete(ruta)
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Servicio de EFUSACH anulado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                if (document.referrer == ''){
                  window.location.href = history.back()
                }
                else{
                  window.location.href = document.referrer
                }
                this.isLoading = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Servicio de EFUSACH no pudo ser anulado</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
          })
        }
      })
    },

  },
  computed:{
  }
}
</script>
<style scoped>

</style>