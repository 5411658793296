<template>
    <div>
        <h1 class="subtitle is-2 has-text-primary">
            Mi Actividad Académica
        </h1>
        <div class="mb-3 ">
            <p>El siguiente video muestra todo lo relacionado con la actividad académica realizada por el académico. Indica las acciones que puede realizar y la información que puede visualizar.</p>
        </div>
        <div class="video-container">
<!--            <div ref="playerContainer" class="video-player"></div>-->
            <div ref="playerContainer" class="video-player"></div>
            <div class="skip-intro-button-container" v-if="showSkipIntroButton">
                <button class="skip-intro-button" @click="skipIntro">Saltar introducción</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "academic_activity",
    props:["first_time"],
    data(){
        return{
            player: null,
            introDuration: 21, // Duración de la introducción en segundos
            showSkipIntroButton: false,
            introSkipped: false, // Variable para indicar si se ha saltado la introducción
            idVideo: 'uN3NtJuXlJU',
        }
    },
    mounted() {
        // Cargar el reproductor de video de YouTube
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        //window.onYouTubeIframeAPIReady = this.onYouTubeIframeAPIReady()
        /*window.onYouTubeIframeAPIReady = () => {
            this.player = new window.YT.Player(this.$refs.playerContainer, {
                events: {
                    onStateChange: this.onPlayerStateChange,
                }
            });
        };*/
        window.onYouTubeIframeAPIReady = () => {
            const playerWidth = this.$refs.playerContainer.offsetWidth;
            const playerHeight = (playerWidth / 16) * 9;
            this.player = new window.YT.Player(this.$refs.playerContainer, {
                height: playerHeight,
                width: playerWidth,
                videoId: this.idVideo,
                events: {
                    onStateChange: this.onPlayerStateChange,
                }
            });
        };
        if(!this.first_time){
            const playerWidth = this.$refs.playerContainer.offsetWidth;
            const playerHeight = (playerWidth / 16) * 9;
            this.player = new window.YT.Player(this.$refs.playerContainer, {
                height: playerHeight,
                width: playerWidth,
                videoId: this.idVideo,
                events: {
                    onStateChange: this.onPlayerStateChange,
                }
            });
        }
        /*const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        this.introTimerId = setInterval(() => {
            const player = new window.YT.Player(this.$refs.playerContainer, {
                events: {
                    //onStateChange: this.onPlayerStateChange,
                }
            });
            if (player) {
                const currentTime = player.getCurrentTime();
                if (currentTime >= this.introDuration) {
                    this.showSkipIntroButton = false;
                    clearInterval(this.introTimerId);
                } else {
                    this.showSkipIntroButton = true;
                }
            }
        }, 1000);*/

    },
    methods: {
        onPlayerStateChange(event) {
            if (event.data === window.YT.PlayerState.PLAYING) {
                if (!this.introSkipped) {
                    this.showSkipIntroButton = true;
                    setTimeout(() => {
                        if (!this.introSkipped) {
                            this.showSkipIntroButton = false;
                        }
                    }, (this.introDuration - this.player.getCurrentTime()) * 1000);
                }
                else {
                    if (this.player.getCurrentTime() > this.introDuration) {
                        this.showSkipIntroButton = false;
                        this.introSkipped = true;
                    }
                    else{
                        this.showSkipIntroButton = true;
                        this.introSkipped = false;
                    }
                }
            }
            else {
                if (this.player.getCurrentTime() >= this.introDuration) {
                    this.showSkipIntroButton = false;
                    this.introSkipped = true;
                }
                else{
                    this.showSkipIntroButton = true;
                    this.introSkipped = false;
                }
            }
        },
        skipIntro() {
            /*const player = this.$refs.playerContainer;
            if (player && player.contentWindow) {
                player.contentWindow.postMessage('{"event":"command","func":"seekTo","args":[this.introDuration,true]}', '*');
                this.showSkipIntroButton = false;
                clearInterval(this.introTimerId);
            }*/
            this.introSkipped = true;
            this.player.seekTo(this.introDuration);
            this.player.playVideo();
            this.showSkipIntroButton = false;
        },
    }
}
</script>

<style scoped>

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    /* Mantener la proporción de 16:9 */
}

/*.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}*/


.video-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.skip-intro-button-container {
    position: absolute;
    top: 370px; /* Cambiar la posición vertical según tus necesidades */
    left: 10px;
//bottom: 40px;
}

.skip-intro-button {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 9999;
    transition: opacity 0.3s;
}

.skip-intro-button:hover {
    opacity: 1;
}

</style>