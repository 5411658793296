<template>
  <section>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Editar Foto De Perfil</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <div>
          <vue-avatar
              :width="400"
              :height="400"
              :rotation="parseInt(rotation,10)"
              :borderRadius="200"
              :scale="parseFloat(scale)"
              :image="image"
              ref="vueavatar"
              @vue-avatar-editor:image-ready="onImageReady"
          >
          </vue-avatar>
          <b-field label="Zoom">
            <b-slider v-model="scale" :min="1" :max="3" :step="0.02">
            </b-slider>
          </b-field>

          <b-field label="Rotación">
            <b-slider v-model="rotation" :min="0" :max="360" :step="1">
            </b-slider>
          </b-field>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
      </section>
    </div>
    <footer class="modal-card-foot actions">
      <div class="has-text-right is-grouped">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
        <b-button class="is-primary"
                  type="submit"
                  @click="savePhoto()">
          Guardar Foto
        </b-button>
      </div>
    </footer>
  </section>
</template>

<script>

import VueAvatar from 'vue-avatar-editor-improved/src/components/VueAvatar'
import axios from "axios";

export default {
  name: "modal_edit_profile_photo",
  components: {
    VueAvatar
  },
  props:["person","image"],
  data: function data() {
    return {
      rotation: 0,
      scale: 1,
      borderRadius: 0,
      isLoading: false
    };
  },

  methods: {
    saveClicked: function saveClicked() {
      var img = this.$refs.vueavatar.getImage();
      this.$refs.image.src = img.toDataURL();
    },
    onImageReady: function onImageReady() {
      this.scale = 1;
      this.rotation = 0;
      this.borderRadius = 0;
    },
    savePhoto(){
      var img = this.$refs.vueavatar.getImage();
      //this.$refs.image.src = img.toDataURL();
      let vm = this;
      if (vm.$refs.vueavatar.getImageScaled()){
        vm.isLoading = true;
        function DataURIToBlob(dataURI) {
          const splitDataURI = dataURI.split(',')
          const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
          const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

          const ia = new Uint8Array(byteString.length)
          for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

          return new Blob([ia], { type: mimeString })
        }
        let formData = new FormData();
        var base64 = vm.$refs.vueavatar.getImageScaled().toDataURL()
        let file = DataURIToBlob(base64)
        formData.append('file',file)
        axios
            .post("/people/"+this.person.id+"/my-academic-data/update-photo.json",
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                }
            )
            .then(async response => {
              vm.$buefy.dialog.alert({
                message: 'Tú foto de perfil ha sido actualizada satisfactoriamente.',
                confirmText: 'Ok'
              });
              vm.isLoading = false
              this.$emit('close')
            }).
            catch(error => {
              //vm.errores_validacion = error.response.data
              //var elmnt = document.getElementById("app");
              //elmnt.scrollIntoView();
              console.log(error)
              vm.$buefy.dialog.alert({
                title: 'Error',
                message: 'Ocurrio un error al guardar la foto de perfil.',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
              });
              //vm.queryError(vm);
              vm.isLoading=false;
            })
      }
    }
  }
}
</script>
