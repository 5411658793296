<template>
  <div>
    <b-tabs position="is-centered">
            <b-tab-item label="Libros" icon="book">
              <div class="columns has-text-right">
                <div class="column">
                  <b-switch v-model="cardLayout">Formato tarjeta (sin filtros ni búsqueda)
                    <b-tooltip
                        label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                        multilined
                        position="is-left">
                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          type="is-info">
                      </b-icon>
                    </b-tooltip></b-switch>
                </div>
              </div>

              <b-table
                  :data="libros"
                  :paginated=true
                  per-page=10
                  striped
                  hoverable
                  :card-layout="cardLayout"
                  :current-page="currentPage"
                  :pagination-simple=false
                  pagination-position="bottom"
                  default-sort-direction="desc"
                  sort-icon="arrow-up"
                  sort-icon-size="is-small"
                  default-sort="publication.publication_date"
                  aria-next-label="Siguiente"
                  aria-previous-label="Anterior"
                  aria-page-label="Página"
                  aria-current-label="Página actual"
                  :opened-detailed="defaultOpenedDetails"
                  detailed
                  detail-key="title"
                  @details-open="(row) => $buefy.toast.open(`Expanded ${row.title}`)"
                  :show-detail-icon="showDetailIcon"
                  >
                <b-table-column
                    field= 'title'
                    label= 'Título'
                    width="30em"
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.title}}
                  </template>
                </b-table-column>                
                <b-table-column
                    field= 'editorial'
                    label= 'Editorial'
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.editorial }}
                  </template>
                </b-table-column>
                <b-table-column
                    field= 'publication_date'
                    label= 'Fecha de publicación'
                    searchable
                    :custom-search="searchBookDate"
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.publication_date._isValid ? new Date(props.row.publication_date).toLocaleDateString("es-CL"): '' }}
                  </template>
                </b-table-column>

                <b-table-column
                    label="Acciones"
                    v-slot="props">
                  <b-icon
                      pack="fas"
                      icon="eye"
                      size="is-medium"
                      @click.native="execShowModal(props.row.id)"/>
                  <b-icon
                      pack="fas"
                      icon="trash-restore"
                      size="is-medium"
                      type="is-success"
                      @click.native="execRestoreModal(props.row.id, props.row)"/>
                </b-table-column>

                <template slot="detail" slot-scope="props">
                  <article class="media">
                    <div class="media-content">
                      <div class="content">
                        <p>
                          <strong>Autores: </strong>
                          <span>{{props.row.participants_references}}</span>
                        </p>
                      </div>
                    </div>
                  </article>
                </template>

                <template slot="empty" slot-scope="props">
                  <div class="columns is-centered">
                    <div class="column is-6 is-centered has-text-centered">
                      <b-message type="is-danger">
                        No se encontraron resultados para el año seleccionado.
                      </b-message>
                    </div>
                  </div>
                </template>


              </b-table>

            </b-tab-item>
            <b-tab-item label="Artículo" icon="bookmark">
              <div class="columns has-text-right">
                <div class="column">
                  <b-switch v-model="cardLayout">Formato tarjeta (sin filtros ni búsqueda)
                    <b-tooltip
                        label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                        multilined
                        position="is-left">
                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          type="is-info">
                      </b-icon>
                    </b-tooltip></b-switch>
                </div>
              </div>

              <b-table
                  :data="articulos"
                  :paginated=true
                  per-page=10
                  striped
                  hoverable
                  :card-layout="cardLayoutArti"
                  :current-page="currentPageArti"
                  :pagination-simple=false
                  pagination-position="bottom"
                  default-sort-direction="desc"
                  sort-icon="arrow-up"
                  sort-icon-size="is-small"
                  default-sort="publication.publication_date"
                  aria-next-label="Siguiente"
                  aria-previous-label="Anterior"
                  aria-page-label="Página"
                  aria-current-label="Página actual"
                  :opened-detailed="defaultOpenedDetailsArti"
                  detailed
                  detail-key="title"
                  @details-open="(row) => $buefy.toast.open(`Expanded ${row.title}`)"
                  :show-detail-icon="showDetailIconArti"
                  >
                <b-table-column
                    field= 'title'
                    label= 'Título'
                    width="30em"
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.title}}
                  </template>
                </b-table-column>                
                <b-table-column
                    field= 'publication_date'
                    label= 'Fecha de publicación'
                    searchable
                    :custom-search="searchBookDate"
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.publication_date._isValid ? new Date(props.row.publication_date).toLocaleDateString("es-CL"): '' }}
                  </template>
                </b-table-column>


                <b-table-column
                    field= 'editorial'
                    label= 'Editorial del libro'
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    <div v-for="(item,index) in props.row.libros" :key="index">
                      {{ item.editorial}}
                    </div>
                  </template>
                </b-table-column>

                <b-table-column
                    field= 'editorial'
                    label= 'Titulo del libro'
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    <div v-for="(item,index) in props.row.libros" :key="index">
                      {{ item.title}}
                    </div> 
                  </template>
                </b-table-column>

                <b-table-column
                    label="Acciones"
                    v-slot="props">
                  <b-icon
                      pack="fas"
                      icon="eye"
                      size="is-medium"
                      @click.native="execShowModal(props.row.id)"/>
                  <b-icon
                      pack="fas"
                      icon="trash-restore"
                      size="is-medium"
                      type="is-success"
                      @click.native="execRestoreModal(props.row.id, props.row)"/>
                </b-table-column>

                <template slot="detail" slot-scope="props">
                  <article class="media">
                    <div class="media-content">
                      <div class="content">
                        <p>
                          <strong>Autores: </strong>
                          <span>{{props.row.participants_references}}</span>
                        </p>
                      </div>
                    </div>
                  </article>
                </template>

                <template slot="empty" slot-scope="props">
                  <div class="columns is-centered">
                    <div class="column is-6 is-centered has-text-centered">
                      <b-message type="is-danger">
                        No se encontraron resultados para el año seleccionado.
                      </b-message>
                    </div>
                  </div>
                </template>


              </b-table>
            </b-tab-item>
            <b-tab-item label="Capítulo" icon="file">
              <div class="columns has-text-right">
                <div class="column">
                  <b-switch v-model="cardLayout">Formato tarjeta (sin filtros ni búsqueda)
                    <b-tooltip
                        label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                        multilined
                        position="is-left">
                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          type="is-info">
                      </b-icon>
                    </b-tooltip></b-switch>
                </div>
              </div>

              <b-table
                  :data="capitulos"
                  :paginated=true
                  per-page=10
                  striped
                  hoverable
                  :card-layout="cardLayoutChar"
                  :current-page="currentPageChar"
                  :pagination-simple=false
                  pagination-position="bottom"
                  default-sort-direction="desc"
                  sort-icon="arrow-up"
                  sort-icon-size="is-small"
                  default-sort="publication.publication_date"
                  aria-next-label="Siguiente"
                  aria-previous-label="Anterior"
                  aria-page-label="Página"
                  aria-current-label="Página actual"
                  :opened-detailed="defaultOpenedDetailsChar"
                  detailed
                  detail-key="title"
                  @details-open="(row) => $buefy.toast.open(`Expanded ${row.title}`)"
                  :show-detail-icon="showDetailIconChar"
                  >
                <b-table-column
                    field= 'title'
                    label= 'Título'
                    width="30em"
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.title}}
                  </template>
                </b-table-column>

                <b-table-column
                    field= 'publication_date'
                    label= 'Fecha de publicación'
                    searchable
                    :custom-search="searchBookDate"
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.publication_date._isValid ? new Date(props.row.publication_date).toLocaleDateString("es-CL"): '' }}
                  </template>
                </b-table-column>


                <b-table-column
                    field= 'editorial'
                    label= 'Editorial del libro'
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    <div v-for="(item,index) in props.row.libros" :key="index">
                      {{ item.editorial}}
                    </div> 
                  </template>
                </b-table-column>

                <b-table-column
                    field= 'editorial'
                    label= 'Titulo del libro'
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    <div v-for="(item,index) in props.row.libros" :key="index">
                      {{ item.title}}
                    </div> 
                  </template>
                </b-table-column>
                

                <b-table-column
                    label="Acciones"
                    v-slot="props">
                  <b-icon
                      pack="fas"
                      icon="eye"
                      size="is-medium"
                      @click.native="execShowModal(props.row.id)"/>
                  <b-icon
                      pack="fas"
                      icon="trash-restore"
                      size="is-medium"
                      type="is-success"
                      @click.native="execRestoreModal(props.row.id, props.row)"/>
                </b-table-column>

                <template slot="detail" slot-scope="props">
                  <article class="media">
                    <div class="media-content">
                      <div class="content">
                        <p>
                          <strong>Autores: </strong>
                          <span>{{props.row.participants_references}}</span>
                        </p>
                      </div>
                    </div>
                  </article>
                </template>

                <template slot="empty" slot-scope="props">
                  <div class="columns is-centered">
                    <div class="column is-6 is-centered has-text-centered">
                      <b-message type="is-danger">
                        No se encontraron resultados para el año seleccionado.
                      </b-message>
                    </div>
                  </div>
                </template>


              </b-table>
            </b-tab-item>
    </b-tabs>

    

    

    <b-modal
        v-model="isShowModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal_show_nullified_book :bookId="bookId"
                                      :cargoPrincipal="cargoPrincipal"
                                      query-type="create"
                                      :user-id="this.userId"
                                      modal-header="Libro"
                                      @close="props.close">
        </modal_show_nullified_book>
      </template>
    </b-modal>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  </div>
</template>
<script>
import {searchDate} from "../../packs/utilities";
import axios from "axios";
import moment from "moment";
import modal_show_nullified_book from "./ModalShowNullifiedBook";
export default {
  props:[
    'hideUpdate','cargoPrincipal'
  ],
  components:{
    modal_show_nullified_book
  },

  data(){
    return{
      isLoading:false,
      currentPage:1,
      currentPageChar:1,
      currentPageArti:1,
      cardLayout:false,
      cardLayoutChar:false,
      cardLayoutArti:false,
      defaultOpenedDetails: [1],
      defaultOpenedDetailsChar: [1],
      defaultOpenedDetailsArti: [1],
      showDetailIcon: true,
      showDetailIconChar: true,
      showDetailIconArti: true,
      year: new Date().getFullYear(),
      years:[],
      isNullifyActive: false,
      elementos: [],
      libros: [],
      capitulos: [],
      articulos: [],
      isShowModalActive: false,
      bookId: null
    }
  },
  created(){
    if(this.cargoPrincipal.name == 'ANALISTA I+D FING' || this.cargoPrincipal.name == 'SUPERADMIN'){
      this.getBooksNullified()
    }
    else {
      this.myGetBooksNullified()
    }
  },
  methods:{
    getBookUrl(book_id){
      return "/books/"+book_id;
    },
    generateArrayOfYears() {
      var max = new Date().getFullYear()
      var min = 1980
      var years = []
      years.push("Todo")
      for (var i = max; i >= min; i--) {
        years.push(i)
      }
      return years
    },

    searchBookDate(row,input){
      return searchDate(row.publication_date,input)
    },
    execRestoreModal(id, row){
      let titulo = ""
      let msj = ""
      let msjError = ""
      let msjExito = ""
      let conf = ""
      let tipo = 0

      if (row.publication_type=='Book'){
        titulo = "Restaurar Libro"
        msj = '¿Está seguro que desea <b>restaurar</b> el libro? Esta acción revertirá la anulación.<br>'
        msjError = '<b>Libro no pudo ser restaurado</b><br>'
        msjExito = "Libro restaurado correctamente"
      }
      else if (row.publication_type == 'Inbook'){
        titulo = "Restaurar Artículo de Libro"
        msj = '¿Está seguro que desea <b>restaurar</b> el artículo de libro? Esta acción revertirá la anulación.<br>'
        msjError = '<b>artículo de Libro no pudo ser restaurado</b><br>'
        msjExito = "Artículo de libro restaurado correctamente"
      }
      else if (row.publication_type == 'Booklet'){
        titulo = "Restaurar Capítulo de Libro"
        msj = '¿Está seguro que desea <b>restaurar</b> el capítulo de libro? Esta acción revertirá la anulación.<br>'
        msjError = '<b>Capítulo de Libro no pudo ser restaurado</b><br>'
        msjExito = "Capítulo de libro restaurado correctamente"
      }

      let vm = this;
      vm.$buefy.dialog.confirm({
        title: titulo,
        message: msj,
        confirmText: titulo,
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          vm.isLoading = true
          axios
              .put("/books/" + id + "/restore_book.json")
              .then(res => {
                vm.$buefy.dialog.alert({
                  message: msjExito,
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                if(row.publication_type=="Book"){
                  var index = this.libros.indexOf(row)
                  this.libros.splice(index, 1)
                }
                if(row.publication_type=="Inbook"){
                  var index = this.articulos.indexOf(row)
                  this.articulos.splice(index, 1)
                }
                if(row.publication_type=="Booklet"){
                  var index = this.capitulos.indexOf(row)
                  this.capitulos.splice(index, 1)
                }
                vm.isLoading = false;
              }).catch(error => {
            console.log(error)
            vm.$buefy.dialog.alert({
              message: msjError+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            vm.isLoading = false;
            vm.isRestoreActive = false;
          })
        }
      })
    },
    getBooksNullified(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/books.json", {params:{year_f:-1}})
          .then(res => {
            vm.elementos = res.data;
            vm.elementos = vm.elementos.map(libro => {
              var newLibro = libro
              if (newLibro["publication_date"]!= null){
                newLibro["publication_date"] = moment(libro.publication_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
              }              
              else{
                newLibro["publication_date"] = moment(libro.fecha, ["YYYY-MM-DD","DD-MM-YYYY"]);
              }

              if(newLibro["publication_type"]=="Book"){
                vm.libros.push(newLibro);           
              }
              else if (newLibro["publication_type"]=="Inbook"){
                vm.articulos.push(newLibro);
              }
              else{
                vm.capitulos.push(newLibro);
              }
            });
            vm.isLoading=false;
          })
          .catch(e => {
            vm.isLoading=false;
            //console.log(e);
          });
    },
    myGetBooksNullified(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/books/my_nullified_books.json")
          .then(res => {
            vm.elementos = res.data;
            vm.elementos = vm.elementos.map(libro => {
              var newLibro = libro
              if (newLibro["publication_date"]!= null){
                newLibro["publication_date"] = moment(libro.publication_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
              }
              else{
                newLibro["publication_date"] = moment(libro.fecha, ["YYYY-MM-DD","DD-MM-YYYY"]);
              }
              if (newLibro["is_creator"] == true){
                if(newLibro["publication_type"]=="Book"){
                  vm.libros.push(newLibro);           
                }
                else if (newLibro["publication_type"]=="Inbook"){
                  vm.articulos.push(newLibro);
                }
                else{
                  vm.capitulos.push(newLibro);
                }
              }
            });
            vm.isLoading=false;
          })
          .catch(e => {
            vm.isLoading=false;
            console.log(e);
          });
    },
    execShowModal(id){
      this.bookId = id
      this.isShowModalActive = true
    },

  },
  computed:{

  }
}
</script>