<template>
  <section>
    <h1 class="subtitle is-2 has-text-primary">
      ¿Qué es Mi Hoja de Vida?
    </h1>
    <div class="mb-3 ">
      <p>El sistema permite visualizar la hoja de vida propia de un académico, la cual contiene toda la información
      <br> que maneja recursos humanos y la Oficina Administrativa de la Facultad de Ingeniería. <br>
      Esta información se divide en las categorías listadas a continuación.</p>
    </div>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Información disponible en Mi Hoja de Vida
    </h2>
    <ul class=" ml-4">
      <li> <a @click="goTo(1)">1. Datos Personales</a></li>
      <li> <a @click="goTo(2)">2. Nombramientos</a></li>
      <li> <a @click="goTo(3)">3. Funciones</a></li>
      <li> <a @click="goTo(4)">4. Asignaciones Familiares</a></li>
      <li> <a @click="goTo(5)">5. Estudios</a></li>
      <li> <a @click="goTo(6)">6. Comisiones</a></li>
      <li> <a @click="goTo(7)">7. Eventos </a></li>
      <li> <a @click="goTo(1)">8. Resumen de Hoja de Vida en formato PDF</a></li>
    </ul>
    <!--<div class="box">
    <b-message type="is-warning" has-icon>
      Si encuentra información desactualizada o errónea en su hoja de vida, por favor comuníquese con
      el <b>Jefe Administrativo de su departamento</b> correspondiente y comunique el error o solicite la actualización
      de su información correspondiente a Datos Personales, Nombramientos, Funciones, Asignaciones Familiares, Estudios, Comisiones y Eventos.
    </b-message>
    </div>-->
  </section>
</template>
<script>
import EventBus from "../../eventBus";
export default {
  name: "what_is_mhv",
  data(){
    return {
    }
  },
  methods:{
    goTo(to){
      EventBus.$emit('IR_A',to);
    }
  }
}
</script>