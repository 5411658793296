<template>
  <section>
    <div class="box">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
          <b-message type="is-danger" has-icon>
            <div v-for="(item, index) in errores_validacion">
              {{ item[0] }}
            </div>
          </b-message>
        </div>
        <form @submit.prevent="handleSubmit(saveBook)">
          <section v-if="isUpdatingAndDeletingParticipant(false) != undefined" class="modal-card-body"
            ref="modalPersonForm">
            <b-steps ref="publicationsteps" v-model="activeStep" :animated="isAnimated" :rounded="isRounded"
              :has-navigation="hasNavigation" :icon-prev="prevIcon" :icon-next="nextIcon" :label-position="labelPosition"
              :mobile-mode="mobileMode">
              <b-step-item v-if="isUpdatingAndDeletingParticipant(false)" step="1" label="General"
                :clickable="isStepsClickable">
                <h1 class="title has-text-centered">General [1/2]</h1>
                <div class="columns">
                  <div class="column is-4 is-offset-8">
                    <b-switch v-model="requiredFieldOnly">Mostrar todos los campos del formulario</b-switch>
                  </div>
                </div>
                <div class="columns is-centered">
                  <div class="column is-narrow">
                    <keep-alive><bibtex-input @fill-publication="fillPublication"></bibtex-input></keep-alive>
                  </div>
                  <div class="column is-narrow">
                    <keep-alive><isbn-search @fill-publication="fillPublication"
                        @fill-isbn="fillIsbn"></isbn-search></keep-alive>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Título*">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-input v-model="publication.title" type="text"
                          placeholder="Ej: 'Don Quijote de la Mancha'"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Fecha de publicación*">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-datepicker v-model="publication.publication_date" icon="calendar-alt" trap-focus locale="es-ES"
                          editable placeholder="Formato de Fecha: dd/mm/aaaa">
                        </b-datepicker>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column" v-if="requiredFieldOnly">
                    <b-field label="ISSN">
                      <b-input v-model="publication.issn" placeholder="Formato de ISSN: 'XXXX-XXXX'"
                        type="text"></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Editorial*">
                      <template #label>
                        Nombre Editorial*
                      </template>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-autocomplete v-model="editorial_name" :keep-first="keepFirst" :open-on-focus="openOnFocus"
                          :data="editoriales_filtradas" field="name" empty="Vacio" @typing="getRevistasFiltradas"
                          @select="option => { if (option) { publication.editorial_id = option.id } }"
                          :clearable="clearable" placeholder="Editorial Ej: Editorial Universidad de Santiago">
                          <template slot-scope="props">
                            <div v-if="props.option.abbreviated_name != null">
                              {{ props.option.name }} ({{ props.option.abbreviated_name }})
                            </div>
                            <div v-else>
                              {{ props.option.name }}
                            </div>
                          </template>
                          <template #empty>No se encontraron resultados</template>
                        </b-autocomplete>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div v-if="publication.editorial_id == null || editorial_name == ''" class="column is-narrow">
                    <br>
                    <b-field label=" ">
                      <b-tooltip
                        label="Si la Editorial que desea agregar no existe, la puede agregar presionando el botón +"
                        multilined position="is-left">
                        <b-button class="is-primary is-outlined" @click="addEditorial()">
                          <b-icon icon="plus"></b-icon>
                        </b-button>
                      </b-tooltip>
                    </b-field>
                  </div>
                  <div v-else class="column is-narrow">
                    <br>
                    <b-field label=" ">
                      <b-tooltip label="Al presionar el botón, podrá editar la información del lugar de publicación"
                        multilined position="is-left">
                        <b-button class="is-success is-outlined" @click="editEditorial()">
                          <b-icon icon="pen"></b-icon>
                        </b-button>
                      </b-tooltip>
                    </b-field>
                  </div>
                </div>




                <div class="columns">
                  <div class="column">
                    <h3 class="is-size-6 has-text-weight-bold">
                      Autores*
                      <b-tooltip label="En esta sección debe ingresar en un campo de texto las y los autores del libro en el orden deseado, solo para su visualización.  
                                Este campo de texto es solo referencial.
                                Ej de texto:'Rodríguez Alonso , Jesús A. and Samuel F. Velarde'
                                En el caso de que estos sean una gran cantidad se recomienda modificar la estrucutra. 
                                Ej de texto:'Varios autores'" position="is-right" size="is-large" multilined>
                        <b-icon pack="fas" icon="info-circle" size="is-small" type="is-info">
                        </b-icon>
                      </b-tooltip>
                    </h3>
                    <b-field label=" ">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-input v-model="publication.participants_references" type="text" maxlength="255"
                          :has-counter="false"
                          placeholder="Ej:'Rodríguez Alonso , Jesús A. and Samuel F. Velarde'"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column" v-if="requiredFieldOnly">
                    <div class="field">
                      <b-field label="Reseña">
                        <b-input type="textarea" maxlength="4000" v-model="publication.summary">
                        </b-input>
                      </b-field>
                    </div>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Link del libro">
                      <b-input v-model="publication.url" placeholder="https://link.cl" expanded></b-input>
                      <p class="control">
                        <b-button tag="a" :href="publication.url" target="_blank" icon-left="external-link-alt"
                          type="is-link">Link</b-button>
                      </p>
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column" v-if="requiredFieldOnly">
                    <b-field label="Título Corto">
                      <b-input v-model="publication.short_title" placeholder="Título Corto de Libro"
                        type="text"></b-input>
                    </b-field>
                  </div>
                  <div class="column" v-if="requiredFieldOnly">
                    <b-field label="Serie">
                      <b-input v-model="publication.serie" placeholder="Serie de libro" type="text"></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column" v-if="requiredFieldOnly">
                    <b-field label="Título Serie">
                      <b-input v-model="publication.serial_title" placeholder="Título Serie" type="text"></b-input>
                    </b-field>
                  </div>
                  <div class="column" v-if="requiredFieldOnly">
                    <b-field label="Volumen">
                      <b-input v-model="publication.volumen" placeholder="Ej: 2, 3" type="text"></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column" v-if="requiredFieldOnly">
                    <b-field label="Archivo">
                      <b-input v-model="publication.archive" placeholder="Ej: Biblioteca Nacional de Chile"
                        type="text"></b-input>
                    </b-field>
                  </div>

                  <div class="column" v-if="requiredFieldOnly">
                    <b-field label="Posición en Archivo">
                      <b-input v-model="publication.archive_position" placeholder="Ej: Depósito Legal N°XX"
                        type="text"></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column" v-if="requiredFieldOnly">
                    <b-field label="DOI">
                      <b-input v-model="publication.doi" type="text"></b-input>
                      <p class="control">
                        <b-button tag="a" :href="'https://doi.org/' + publication.doi" target="_blank"
                          icon-left="external-link-alt" type="is-link">Link</b-button>
                      </p>
                    </b-field>
                  </div>
                  <div class="column" v-if="requiredFieldOnly">
                    <h3 class="is-size-6 has-text-weight-bold">
                      Adicional
                      <b-tooltip
                        label="En esta sección debe ingresar en un campo de texto la informacion extra para para su visualización. Este campo de texto es solo referencial. Ej de texto:'Revisión de texto: Shaoshuai Wang, Diseño de portada e interior: Lili Guo, Colaboradores: Ling Chen, Weiyong Liu and Yong Cao'."
                        position="is-right" size="is-large" multilined>
                        <b-icon pack="fas" icon="info-circle" size="is-small" type="is-info">
                        </b-icon>
                      </b-tooltip>
                    </h3>
                    <b-field label=" ">
                      <b-input v-model="publication.additional" type="text"></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column" v-if="requiredFieldOnly">
                    <b-field label="Imprenta">
                      <b-input v-model="publication.printing" placeholder="Ej: Lazaro" type="text"></b-input>
                    </b-field>
                  </div>
                </div>



                <div v-if="isUpdatingAndDeletingParticipant(false)" class="is-pulled-right">
                  <b-button class="mt-2" @click.prevent="addISBN(bookId)" type="is-primary">Añadir ISBN</b-button>
                </div>
                <div class="column">
                  <h3 class="is-size-4 has-text-weight-bold">Tabla De ISBN</h3>
                  <b-table :key="tableDataKey" :data="isbns" :paginated=true per-page=5 striped hoverable
                    :current-page="currentPage" :card-layout="cardLayout" :pagination-simple=false
                    pagination-position="bottom" default-sort-direction="asc" sort-icon="arrow-up"
                    sort-icon-size="is-small" default-sort="publication.title" aria-next-label="Siguiente"
                    aria-previous-label="Anterior" aria-page-label="Página" aria-current-label="Página actual">
                    <b-table-column field="code" label="Código" width="13em" sortable searchable>
                      <template slot="searchable" slot-scope="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder=" Buscar" icon="search"
                          size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.code }}
                      </template>
                    </b-table-column>
                    <b-table-column width="10em" field="country_id" label="Tipo de ISBN" v-slot="props">

                      <b-switch v-model="props.row.is_physical">
                      </b-switch>
                      <span v-if="props.row.is_physical"> Fisico </span>
                      <span v-else> Digital</span>
                    </b-table-column>

                    <b-table-column label="Acciones" width="10" v-slot="props">
                      <b-icon v-if="isUpdatingAndDeletingParticipant(props.row)" pack="fas" icon="trash"
                        style="color: #e50000;" size="is-medium" @click.native="deleteISBNButton(props.row)" />
                    </b-table-column>
                    <template slot="empty" slot-scope="props">
                      <div class="columns is-centered">
                        <div class="column is-6 is-centered has-text-centered">
                          <b-message type="is-danger">
                            No se han añadido ISBN.
                          </b-message>
                        </div>
                      </div>
                    </template>
                  </b-table>
                </div>


              </b-step-item>
              <b-step-item step="2" label="Participantes" :clickable="isStepsClickable">
                <h1 class="title has-text-centered">Participantes [2/2]
                  <b-tooltip label="En esta sección debe ingresar las(os) participantes del proyecto que
                                  se encuentren registrados en el sistema, de esta manera, los datos ingresados
                                   de esta patente quedarán asociados a esta persona." position="is-right"
                    size="is-medium" multilined>
                    <b-icon pack="fas" icon="info-circle" size="is-small" type="is-info">
                    </b-icon>
                  </b-tooltip>
                </h1>
                <div v-if="queryType == 'update'" class="columns is-centered">
                  <div class="column is-centered has-text-centered">
                    <b-message title="Información importante" type="is-warning" aria-close-label="Cerrar mensaje">
                      Solo la/el usuaria/o que es Editora o Editor de esta actividad académica tiene permisos para editar
                      todos sus atributos.<br>
                      Quienes participan en la actividad académica pero no son dueños, solo pueden editar su
                      participación.
                      <br><span v-if="publication.creator_name">Origen de la información: {{ publication.creator_name
                      }}</span>
                    </b-message>
                  </div>
                </div>
                <div v-if="isUpdatingAndDeletingParticipant(false)" class="is-pulled-right">
                  <b-button class="mt-2" @click.prevent="addParticipant(bookId)" type="is-primary">Administrar
                    participantes</b-button>
                </div>
                <div class="column">
                  <h3 class="is-size-4 has-text-weight-bold">Tabla Resumen Participantes</h3>
                  <b-table v-if="showParticipantTable" :key="tableDataKey" :data="participantes" :paginated=true
                    per-page=5 striped hoverable :current-page="currentPage" :card-layout="cardLayout"
                    :pagination-simple=false pagination-position="bottom" default-sort-direction="asc"
                    sort-icon="arrow-up" sort-icon-size="is-small" default-sort="publication.title"
                    aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
                    aria-current-label="Página actual">
                    <b-table-column field="name" label="Nombres" width="13em" sortable searchable>
                      <template slot="searchable" slot-scope="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder=" Buscar" icon="search"
                          size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.name }}
                      </template>
                    </b-table-column>
                    <b-table-column field="unit" label="Unidad" width="13em" sortable searchable>
                      <template slot="searchable" slot-scope="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder=" Buscar" icon="search"
                          size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.unit }}
                      </template>
                    </b-table-column>
                    <b-table-column width="15em" field="participant_type" label="Tipo de participación*" v-slot="props">
                      <ValidationProvider v-if="isUpdatingAndDeletingParticipant(props.row)" rules="required"
                        v-slot="{ errors }">
                        <multiselect v-model="props.row.participant_type_id" :options="tipos_participacion.map(x => x.id)"
                          :custom-label="opt => tipos_participacion.find(x => x.id == opt).name"
                          placeholder="Seleccione tipo de participación" selectLabel=" " selectedLabel=" "
                          deselectLabel="No se puede deseleccionar" :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                            No se encontraron elementos.
                          </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-table-column>
                    <b-table-column label="Acciones" width="10" v-slot="props">
                      <b-icon v-if="isUpdatingAndDeletingParticipant(props.row)" pack="fas" icon="trash"
                        style="color: #e50000;" size="is-medium" @click.native="deleteParticipantButton(props.row)" />
                    </b-table-column>
                    <template slot="empty" slot-scope="props">
                      <div class="columns is-centered">
                        <div class="column is-6 is-centered has-text-centered">
                          <b-message type="is-danger">
                            No se encontraron participantes asociados.
                          </b-message>
                        </div>
                      </div>
                    </template>
                  </b-table>
                </div>
                <hr class="dropdown-divider" aria-role="menuitem">
                <div class="columns" v-if="queryType === 'update'">
                  <div class="column" v-if="publication.is_creator">
                    <h3 class="is-size-6 has-text-weight-bold">
                      Editor(a) del dato*
                      <b-tooltip
                        label="Debe seleccionar al menos una Editora o Editor del dato que se encuentre como participante en la actividad académica, quien será el único capaz de editar toda la información. Si realiza el cambio, no podrá revertirlo."
                        position="is-right" size="is-large" multilined>
                        <b-icon pack="fas" icon="info-circle" size="is-small" type="is-info">
                        </b-icon>
                      </b-tooltip>
                    </h3>
                    <b-field label=" ">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect v-model="ownerId" :options="participantes.map(type => type.person_id)"
                          :custom-label="opt => participantes.find(x => x.person_id === opt).name"
                          placeholder="Seleccione Editor(a)" selectLabel="Presione para seleccionar"
                          selectedLabel="Seleccionado" deselectLabel="" :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                            No se encontraron elementos.
                          </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
                <div class="columns" v-else>
                  <div class="column">
                    <h3 class="is-size-6 has-text-weight-bold">
                      Editor(a) del dato*
                      <b-tooltip
                        label="Debe seleccionar al menos una Editora o Editor del dato que se encuentre como participante en la actividad académica, quien será el único capaz de editar toda la información. Si realiza el cambio, no podrá revertirlo."
                        position="is-right" size="is-large" multilined>
                        <b-icon pack="fas" icon="info-circle" size="is-small" type="is-info">
                        </b-icon>
                      </b-tooltip>
                    </h3>
                    <b-field label=" ">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect v-model="ownerId" :options="participantes.map(type => type.person_id)"
                          :custom-label="opt => participantes.find(x => x.person_id === opt).name"
                          placeholder="Seleccione Editor(a)" selectLabel="Presione para seleccionar"
                          selectedLabel="Seleccionado" deselectLabel="" :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                            No se encontraron elementos.
                          </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
              </b-step-item>

              <template #navigation="{ previous, next }">
                <div class="columns is-pulled-right">
                  <div class="column">
                    <b-button outlined icon-left="chevron-left" :disabled="previous.disabled"
                      @click.prevent="previous.action">
                    </b-button>
                    <b-button outlined icon-right="chevron-right" :disabled="next.disabled" @click.prevent="next.action">
                    </b-button>

                  </div>
                </div>
              </template>

            </b-steps>
          </section>

          <br>
          <div class="columns">
            <div class="column">
              <div class="is-pulled-right">
                <button v-if="modalCancel" class="button is-danger" @click.prevent="$emit('close')">Cancelar</button>
                <button class="button is-primary" type="submit" @click="checkValidation()">Guardar Libro</button>
              </div>
            </div>
          </div>
          <!-- Fin Box Formulario-->
        </form>
      </ValidationObserver>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
      <!-- MODALES -->
      <b-modal v-model="isAddModalActive" has-modal-card trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
        :can-cancel="canCancel">
        <template #default="props">
          <modal-participants-publications query-type="update" modal-header="Añadir participantes"
            :participantes="participantes" :tipos_participacion="tipos_participacion" :publication="publication"
            @close="props.close" @clicked="onClickChild"></modal-participants-publications>
        </template>
      </b-modal>
      <b-modal v-model="isModalEditorialActive" has-modal-card trap-focus :destroy-on-hide="true" aria-role="dialog"
        aria-modal :can-cancel="canCancel">
        <template #default="props">
          <new-editorial-modal modal-header="Añadir Editorial" @close="props.close" @clicked="onClickChildEditorial"
            v-bind:query-type="editorialQueryType" :update-editorial="updateEditorialId">
          </new-editorial-modal>
        </template>
      </b-modal>
      <b-modal v-model="isModalIsbnActive" has-modal-card trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
        :can-cancel="canCancel">
        <template #default="props">
          <new-isbn-modal modal-header="Añadir ISBN" @close="props.close" @clicked="onClickChildISBN">
          </new-isbn-modal>
        </template>
      </b-modal>

      <add-participant-filiation modal-header="Añadir Filiación a Participante" @close="closeFiliationModal"
        @setFilitation="setParticipantFilitation" :partner_entity="currentEntity" :participant="currentParticipantEntity"
        :active-modal="activeAddFiliationModal"></add-participant-filiation>
  </div>

</section></template>

<script>
import axios from "axios";
import BibtexInput from "../publications/bibtex_input.vue";
import { bus } from "../../packs/app_vue";
import ModalParticipantsForm from "../publications/modal_participants_publications.vue";
import moment from "moment";
import NewEditorialModal from "./NewEditorialModal";
import NewIsbnModal from "./NewIsbnModal";
import IsbnSearch from "./IsbnSearch";
import AddParticipantFiliation from "../filiations/addParticipantFiliation";

export default {
  name: "NewBookForm",
  components:{
    AddParticipantFiliation,
    IsbnSearch,
    NewEditorialModal,
    NewIsbnModal,
    BibtexInput,
    'modal-participants-publications': ModalParticipantsForm
  },
  data(){
    return {
      isModalEditorialActive: false,
      isModalIsbnActive: false,
      activeStep: 0,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      cardLayout: false,
      hasNavigation: true,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      isLoading:false,
      errores_validacion: {},
      canCancel: ['escape'],
      editorial_name: null,
      keepFirst: false,
      openOnFocus: false,
      clearable: false,
      requiredEditorial: true,
      skip_if_magister: false,

      publication: {
        participants_attributes: [],
        validation_attributes:{},
        editorial_id: null,
        editorial:{},
      },
      participants: [],
      participantes: [],
      unidades: [],
      isbns:[],
      tipos_participacion: [],
      editoriales: [],
      editoriales_filtradas:[],
      requiredFieldOnly: false,
      isAddModalActive: false,
      tableDataKey:0,
      currentPage:1,
      activeAddFiliationModal:false,
      currentEntity:{},
      currentParticipantEntity:{},
      ownerId: null,
      usach_filitation:{},
      showParticipantTable:true,
      doiPublicationFinded:null,
      editorialQueryType:"create",
      updateEditorialId:null
    }
  },
  watch:{
    isAddModalActive: function () {
      if (!this.isAddModalActive) {
        this.initialize()
      }
    },
  },
  props:['userId','typeModal','queryType','bookId','cargoPrincipal','unidadPrincipal','personId','modalCancel',"showParticipantsFirst",
  'responseType', 'orcidPublication', 'fromAcademic'],

  created(){
    bus.$on('fill-publication', this.fillPublication);
    bus.$on('fill-isbn', this.fillIsbn);
    
    this.isLoading = true;
    axios.get("/books/new.json")
        .then(res => {
          this.tipos_participacion = res.data['tipos_participacion'];
          this.unidades = res.data['unidades'];;
          this.editoriales = res.data['editoriales'];
          this.editoriales_filtradas = res.data['editoriales'];
          this.usach_filitation = res.data['usach_filitation'];
          if (this.queryType==="create" && this.cargoPrincipal.name==="ACADEMICO"){
            let participant_type = null
            var coautor = this.tipos_participacion.filter(obj => {
              return obj.name == "CO-AUTOR"
            })
            if (coautor){
              participant_type = coautor[0].id;
            }

            this.participantes.push({
              person_id: res.data["participante"].person_id,
              name: res.data["participante"].person_name,
              alias: res.data["participante"].person_alias != null ? res.data["participante"].person_alias.toUpperCase() : "",
              corporate_mail: res.data["participante"].corporate_mail != null ? res.data["participante"].corporate_mail.toUpperCase() : "",
              unit: res.data["participante"].unit_name,
              unit_id: res.data["participante"].unit_id,
              participant_type_id: participant_type,
            })        
          }

          if(this.orcidPublication){
            this.publication = this.orcidPublication;
            if (this.orcidPublication.editorial_id){
              //this.publication.magazine_id = newData.magazine_id;
              this.editorial_name = this.orcidPublication.magazine;
            }
            if (this.publication.participants_attributes){
              this.setParticipants();
              this.ownerId = this.publication.participants_attributes[0].person_id;
            }
          }

          this.isLoading=false;
          if (this.queryType=="update"){
            this.isLoading=true;
            axios
                .get("/books/"+this.bookId+".json",{params:{edit_flag:true}})
                .then(res => {
                  this.publication = res.data
                  this.isbns = this.publication.isbns
                  this.setParticipants();
                  if (this.publication.publication_date!=null && this.publication.publication_date!=undefined)
                    this.publication.publication_date = new Date(moment(this.publication.publication_date).format('MM/DD/YYYY'));
                  if (this.publication.validation_attributes==null){
                    this.publication.validation_attributes={}
                    this.publication.data_source=""
                  }
                  this.editorial_name = this.publication.editorial;

                  if (this.participantes.filter(person => person.person_id == this.publication.ownerId).length > 0){
                    this.ownerId = this.publication.ownerId
                  }
                  if(this.showParticipantsFirst){
                    this.activeStep=1;
                  }
                  this.isLoading=false;

                })
                .catch(e => {
                  console.log(e);
                  this.isLoading=false;
                });
          }
        })
        .catch(e => {
          console.log(e);
          this.isLoading=false;
        });
  },
  methods: {
    initialize(){

    },
    onClickChild(value){
      this.isLoading = true
      this.participantes = value
      let participant_type = null
      var coautor = this.tipos_participacion.filter(obj => {
        return obj.name == "CO-AUTOR"
      })
      if (coautor){
        participant_type = coautor[0];
      }
      this.participantes.forEach(function(participante) {
        if(!participante.participant_type_id && participant_type){
          participante.participant_type_id = participant_type.id
        }

      })
      this.isLoading = false

    },
    onClickChildEditorial(value){
      this.isLoading = true
      axios
          .get('/editorials.json')
          .then(response => {
            this.editoriales = response.data
            this.editoriales_filtradas = response.data
            this.publication.editorial_id = value.id;
            this.editorial_name = value.name;
            this.isModalEditorialActive = false
          }).catch(error => {
        console.log(error)
        this.isLoading = false
      })
      this.isLoading = false
    },
    onClickChildISBN(value){
      this.isLoading = true
      this.isbns.push(value)
      this.isModalIsbnActive = false
      this.isLoading = false
    },
    getEditorial(){
      this.isLoading = true
      axios
      .get('/editorials.json')
      .then(response => {
        this.editoriales = response.data
        this.editoriales_filtradas = response.data
      }).catch(error => {
        console.log(error)
        this.isLoading = false
      })
    },
    addEditorial(){
      this.editorialQueryType = "create";
      this.updateEditorialId = null;
      this.isModalEditorialActive = true;
    },
   async saveBook(){
      this.publication.participants_attributes = []
      var index = 0
      for(const participante of this.participantes){
        this.publication.participants_attributes.push({
          person_id:null,
          participant_type_id:null,
          unit_id:null,
          unit_participants:[],
          _destroy:null
        })
        this.publication.participants_attributes[index].person_id = participante.person_id
        this.publication.participants_attributes[index].is_doc_student = participante.is_doc_student
        this.publication.participants_attributes[index].participant_type_id = participante.participant_type_id
        this.publication.participants_attributes[index].unit_id = participante.unit_id
        this.publication.participants_attributes[index].partner_entity_id = participante.partner_entity_id
        index = index + 1
      }
      this.isLoading=true;
      var retorno = JSON.parse(JSON.stringify(this.publication.participants_attributes));

      if(this.checkEditorial() && this.checkIsbn() ){
        this.publication.academic_activity_state_id = 1
        if (this.queryType==="create") {
          if (this.skip_if_magister == true){
            this.publication.skip_if_magister = true
          }
          this.publication.academic_activity_state_id = 1
          this.publication.publication_type_id = 2
          this.publication.origin_participant_type_id = 3
          axios
              .post("/books.json", {
                book: this.publication,
                participants: retorno.map(function(x) {
                  delete x.unit_id;
                  delete x.unit_participants;
                  return x;
                }),
                user_id:this.userId,
                data_source:this.publication.validation_attributes.data_source,
                ownerId: this.ownerId,
                isbns: this.isbns
              })
              .then(response => {
                this.errores_validacion = {};

                if(this.orcidPublication){
                  this.$emit("publicationAdded",response.data);
                }                
                else if(this.typeModal){
                  this.$emit('clicked', response.data)
                  this.$emit('close')
                  this.isLoading=false;
                }
                else{ 
                  window.location.href = '/books/'+response.data["id"]
                  this.isLoading=false;
                }
              }).
          catch(error => {
            this.errores_validacion = error.response.data
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            this.isLoading=false;
          })
        }
        if (this.queryType==="update"){
          if (this.skip_if_magister == true){
            this.publication.skip_if_magister = true
          }
          axios
            .put("/books/"+this.bookId+".json", {
              book: this.publication,
              participants: retorno.map(function(x) {
                delete x.unit_id;
                delete x.unit_participants;
                return x;
              }),
              user_id:this.userId,
              data_source:this.publication.validation_attributes.data_source,
              ownerId: this.ownerId,
              isbns: this.isbns
            })
            .then(response => {
              this.errores_validacion = {};
              if(this.responseType=="RETORNO"){
                this.$emit("response",response.data);
              }
              else{
                if (this.fromAcademic) {
                  this.$emit('refresh')
                  this.$emit('close')
                }else{
                  window.location.href = '/books/'+this.bookId
                }
              }

              this.isLoading=false;
            }).
            catch(error => {
              this.errores_validacion = error.response.data
              var elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              this.isLoading=false;
            })
        }
      }
    },
    addParticipant(id){
      this.selected = id;
      this.isAddModalActive = true
    },
    addISBN(){
      this.isModalIsbnActive = true
    },

    deleteParticipant(index){
      this.publication.participants_attributes.splice(index,1)
    },

    deleteParticipantsPub(participants){
      if(participants.person_id == this.ownerId){
        this.ownerId = null
      }

      this.participantes = this.participantes.filter(person => person.corporate_mail != participants.corporate_mail);
    },
    deleteISBNsPub(oldISBN){
      this.isbns = this.isbns.filter(isbn => isbn.code != oldISBN.code);
    },
    findUnitParticipants(index){
      if(this.publication.participants_attributes[index].unit_id!=null){
        this.isLoading=true;
        axios
            .get("/get_people_by_unit.json", {
              params: {unit_id: this.publication.participants_attributes[index].unit_id}
            })
            .then(res => {
              this.publication.participants_attributes[index].unit_participants = res.data['personas'];
              this.isLoading=false;
            }).
        catch(error => {
          this.isLoading=false;
          console.log(error)
        })
      }
    },
    async getRevistasFiltradas(text) {
      if(text.length >2){
        this.isLoading=true;
        await axios
          .get("/editorials/search.json", {
            params: {name: text,}
          })
          .then(res => {
            this.editoriales_filtradas = res.data;
            this.publication.editorial_id = null

     

            setTimeout(()=>{
              this.isLoading = false
            },3000);
          }).
          catch(error => {
            this.isLoading=false;
            //console.log(error)
          })
      }



    },
    async checkValidation(){
      const valid = await this.$refs.observer.validate()
      if(!valid){
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar tanto la pestaña General como la de Participantes.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
    checkEditorial(){
      if (this.skip_if_magister == true){
       return true
      }
      else if(this.publication.editorial_id == null){
        this.$buefy.dialog.alert({
          message: 'Error en campo editorial. Revisar que la editorial exista',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = false
        return false
      }
      else{
        return true
      }
    },
    checkIsbn(){
      if(this.isbns.length == 0){
        this.$buefy.dialog.alert({
          message: 'Error en ISBN. Debe existir al menos uno.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = false
        return false
      }
      else{
        return true
      }
    },
    fillIsbn(newData){
      this.isbns = []
      newData.forEach(isbn => {
        let newIsbn = {
              code: isbn,
              country_id: null
            }
        this.isbns.push(newIsbn)
      })
      

    },
    formatDate(inputDate) {
      // Verificar si el inputDate ya está en formato aaaa-mm-dd
      if (typeof inputDate === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(inputDate)) {
        return inputDate;
      }

      // Convertir la fecha al objeto Date si no está en el formato aaaa-mm-dd
      const date = new Date(inputDate);

      if (isNaN(date.getTime())) {
        throw new Error('Fecha inválida');
      }

      // Extraer año, mes y día y formatear a aaaa-mm-dd
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses son base 0 en JavaScript
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    fillPublication(newData){

      if (newData.publication_date){
        newData.publication_date = this.formatDate(newData.publication_date)
        newData.publication_date = new Date(newData.publication_date.replace(/-/g,"/"));
      }
      else{
        //newData.publication_date = new Date();
      }
      this.editorial_name = null;
      this.publication.editorial_id = null
      if (newData.editorial_id){
        this.publication.editorial_id = newData.editorial_id;
        this.editorial_name = newData.editorial;
      }

      let is_creator = undefined;
      if(this.publication.is_creator){
        is_creator = true;
      }
      this.publication = newData;
      if (this.publication.validation_attributes==null){
                    this.publication.validation_attributes={}
                  }
      if (this.publication.participants_attributes==null){
                    this.publication.participants_attributes=[]
                  }
      this.publication.is_creator = is_creator;


    },
    isUpdatingAndDeletingParticipant(row){
      if(this.queryType=="create"){
        return true;
      }
      else if(this.queryType=='update'){
        //console.log(this.project.current_user_person_id, row.id,row)
        if (row){
          return this.publication.is_creator || this.publication.current_user_person_id == row.person_id;
        }
        return this.publication.is_creator;
      }

    },
    deleteParticipantButton(participants){

      this.$buefy.dialog.confirm({
        title: 'Eliminar Participante',
        message: 'Precaución, al momento de guardar la publicación esta acción eliminará la asociación con la/el participante, lo que implica que ya no podrá ver esta publicación ni será contada en su actividad académica ¿Desea continuar?',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteParticipantsPub(participants)
      })

    },
    deleteISBNButton(isbn){

      this.$buefy.dialog.confirm({
        title: 'Eliminar ISBN',
        message: 'Precaución, al momento de guardar la publicación esta acción eliminará la asociación con el ISBN¿Desea continuar?',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteISBNsPub(isbn)
      })

    },
    AddFiliation(row){
      if(row.partner_entity){
        this.currentEntity = row.partner_entity;
      }
      else{
        this.currentEntity = {};
      }
      this.currentParticipantEntity = row;
      this.activeAddFiliationModal = true;
    },
    DeleteFiliation(row){
      this.isLoading = true;
      this.currentEntity = {};
      this.currentParticipantEntity = {};
      let index = this.participantes.findIndex(participant => participant.person_id==row.person_id)
      if (index>=0){
        this.participantes[index].partner_entity_id = null;
        this.participantes[index].partner_entity = {};
        this.tableDataKey++;
      }
      this.isLoading = false;
    },
    closeFiliationModal(){
      this.activeAddFiliationModal = false;
    },
    setParticipantFilitation(res){
      let index = this.participantes.findIndex(participant => participant==res.participant)
      if (index>=0){
        this.participantes[index].partner_entity_id = res.partner_entity.id;
        this.participantes[index].partner_entity = res.partner_entity;
        this.tableDataKey++;
      }

      this.currentEntity = {};
      this.activeAddFiliationModal = false;
    },
    setParticipants(){
      var index = 0
      for(const participante of this.publication.participants_attributes){
        this.participantes.push({
          id:null,
          name:null,
          alias:null,
          unit:null,
          unit_id:null,
          corporarte_mail:null,
          participant_type_id:null,
          is_doc_student:null
        })
        this.participantes[index].person_id = participante.person_id
        this.participantes[index].is_doc_student = participante.is_doc_student
        this.participantes[index].name = participante.person_name
        this.participantes[index].corporate_mail = participante.person_mail
        this.participantes[index].alias = participante.person_alias
        if(this.participantes[index].corporate_mail != null){
          this.participantes[index].corporate_mail = this.participantes[index].corporate_mail.toUpperCase()
        }
        if(this.participantes[index].alias != null){
          this.participantes[index].alias = this.participantes[index].alias.toUpperCase()
        }
        this.participantes[index].unit = participante.unit_name
        this.participantes[index].unit_id = participante.unit_id
        this.participantes[index].participant_type_id = participante.participant_type_id
        this.participantes[index].partner_entity_id = participante.partner_entity_id
        this.participantes[index].partner_entity = participante.partner_entity
        index = index + 1
      }
    },

    editEditorial(){
      this.editorialQueryType = 'update';
      this.updateEditorialId = this.publication.editorial_id;
      this.isModalEditorialActive = true;
    },



  },

}
</script>