<template>
  <section>
    <h1 class="subtitle is-2 has-text-primary">
      ¿Qué información hay en Nombramientos?
    </h1>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Nombramientos
    </h2>
    <div class="mb-3 ">
      <p>En esta sección se listan los nombramientos, es decir, las relaciones contractuales del académico
        con la Universidad de Santiago de Chile. Se registran datos actuales así como también datos históricos, los que
        pueden ser de estamento: <b>administrativos, académicos y profesores horas de clases</b>.</p>
    </div>
    <img border="2" class="m-2" src="/tutorial_images/nombramientos.png" style="width:80%">
    <div class="mb-3 ">
      <p>El sistema permite ver un nombramiento</p>
    </div>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Paso 1
    </h2>
    <p class="my-3 ">Estar situado en la pestaña de <b>Nombramientos</b> </p>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Paso 2
    </h2>
    <p class="my-3 ">Presionar el botón con forma de <b>ojo</b> situado en la columna de acciones:
    </p>
    <img border="2" class="m-2" src="/tutorial_images/ver_nombramiento.png" style="width:80%">
    <p class="my-3 ">La acción anterior despliega un nombramiento y permite su visualización:
    </p>
    <img border="2" class="m-2" src="/tutorial_images/nombramiento.png" style="width:80%">

    <h2 class="subtitle is-3 mt-5 mb-2">
      Alejamientos
    </h2>
    <div class="mb-3 ">
      <p>En esta sección se listan los alejamientos asociados a los nombramientos, que pueden ser documentos
        de renuncia, deja de servir, término de contrato o término por fallecimiento.</p>
    </div>
    <img border="2" class="m-2" src="/tutorial_images/alejamientos.png" style="width:80%">
    <div class="mb-3 ">
      <p>El sistema permite ver un alejamiento</p>
    </div>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Paso 1
    </h2>
    <p class="my-3 ">Estar situado en la pestaña de <b>Alejamientos</b> </p>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Paso 2
    </h2>
    <p class="my-3 ">Presionar el botón con forma de <b>ojo</b> situado en la columna de acciones:
    </p>
    <img border="2" class="m-2" src="/tutorial_images/alejamientos_ver.png" style="width:80%">
    <p class="my-3 ">La acción anterior despliega un nombramiento y permite su visualización:
    </p>
    <img border="2" class="m-2" src="/tutorial_images/alejamiento.png" style="width:80%">
  </section>
</template>
<script>
export default {
  name: "what_is_appointment",
  data(){
    return {
    }
  },
  methods:{

  },
}
</script>