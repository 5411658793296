<template>
  <section>

    <div class="columns is-centered">
      <div class="column">

        <b-message type="is-info" has-icon>
          Esta Publicación en Revista solo puede ser <b>editada</b> o <b>anulada</b> por quien agregó estos datos al sistema. Si hay un error en la información por favor ponerse en contacto con la persona propietaria. 
          Si el dato que ve no corresponde a usted, puede <b>eliminar</b> su participación en la sección <b>Participantes</b>, y este dato dejará de estar visible.
        </b-message>

      </div>
    </div>

    <div class="columns is-centered">
        <div class="column">
          <div class="has-text-right">
            <b-button type="is-info"
                      icon-left="edit"
                      v-if="(publication.can_edit && publication.academic_activity_state_id == 1) || (publication.can_edit && publication.academic_activity_state_id == null)"
                      @click="editPublication()">
              Editar Publicación
            </b-button>
            <b-button v-if="(publication.is_creator || cargoPrincipal.name == 'ANALISTA I+D FING') && (publication.academic_activity_state_id == 1 || publication.academic_activity_state_id == null)" type="is-danger"
                      icon-left="ban"
                      @click="execNullifyModal">
              Anular Publicación
            </b-button>
          </div>
          <div class="columns">
            <div class="column has-text-right">
              <br>
              <b-switch v-model="requiredFieldOnly">Mostrar todos los campos del formulario</b-switch>
            </div>
          </div>
          <hr class="dropdown-divider" aria-role="menuitem">
          <div class="columns">
            <div class="column">
              <b-field label="Título">
                <b-input readonly v-model="publication.title" type="text"></b-input>
              </b-field>
            </div>
<!--            <div class="column">
              <b-field label="Tipo de Publicación">
                <b-input readonly v-model="publication.publication_type" type="text"></b-input>
              </b-field>
            </div>-->
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Fecha de Publicación">
                <b-input readonly v-model="publication.publication_date" type="text"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="DOI">
                <b-input readonly v-model="publication.doi" type="text" expanded></b-input>
                <p class="control">
                  <b-button tag="a" :href="'https://doi.org/'+publication.doi" target="_blank" icon-left="external-link-alt" type="is-link">Link</b-button>
                </p>
              </b-field>
            </div>
            <div class="column" v-if="requiredFieldOnly">
              <b-field label="ISSN">
                <b-input readonly v-model="publication.issn" type="text"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <b-field label="Resumen">
                  <b-input readonly
                           type="textarea"
                           minlength="10"
                           maxlength="500"
                           v-model="publication.summary"
                           :has-counter="false">
                  </b-input>
                </b-field>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Autores(registrados y no registrados)">
                <b-input readonly v-model="publication.participants_references" type="text"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Palabras clave">
                <b-taginput
                    disabled
                    v-model="publication.key_words_attributes"
                    autocomplete
                    :allow-new="true"
                    field="name"
                    icon="tag"
                    placeholder="Palabras clave">
                </b-taginput>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column" v-if="requiredFieldOnly">
              <b-field label="Título Corto">
                <b-input readonly v-model="publication.short_title" type="text"></b-input>
              </b-field>
            </div>
            <div class="column"  v-if="requiredFieldOnly">
              <b-field label="Serie">
                <b-input readonly v-model="publication.serie" type="text"></b-input>
              </b-field>
            </div>
            <div class="column" v-if="requiredFieldOnly">
              <b-field label="Título Serie">
                <b-input readonly v-model="publication.serial_title" type="text"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column" v-if="requiredFieldOnly">
              <b-field label="Texto Serie">
                <b-input readonly v-model="publication.serial_text" type="text"></b-input>
              </b-field>
            </div>
            <div class="column" v-if="requiredFieldOnly">
              <b-field label="Archivo">
                <b-input readonly v-model="publication.archive" type="text"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column" v-if="requiredFieldOnly">
              <b-field label="Posición en Archivo">
                <b-input readonly v-model="publication.archive_position" type="text"></b-input>
              </b-field>
            </div>
            <div class="column" v-if="requiredFieldOnly">
              <b-field label="Catálogo en Biblioteca">
                <b-input readonly v-model="publication.library_catalog" type="text"></b-input>
              </b-field>
            </div>
            <div class="column" v-if="requiredFieldOnly">
              <b-field readonly label="Signature">
                <b-input v-model="publication.signature" type="text"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column" v-if="requiredFieldOnly">
              <b-field label="Copyrights">
                <b-input readonly v-model="publication.copyright" type="text"></b-input>
              </b-field>
            </div>
            <div class="column" v-if="requiredFieldOnly">
              <b-field label="Adicional">
                <b-input readonly v-model="publication.additional" type="text"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Nombre Lugar de Publicación (revista ,conferencia, etc...)">
                <b-input readonly :value="publication.magazine" type="text"></b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Cuartil">
                <div class="field has-addons">
                  <p class="control">
                    <b-radio-button v-model="publication.quartile"
                                    :native-value="1"
                                    type="is-success is-light is-outlined is-narrow">
                      <span>Q1</span>
                    </b-radio-button>
                  </p>
                  <p class="control">
                    <b-radio-button v-model="publication.quartile"
                                    :native-value="2"
                                    type="is-success is-light is-outlined">
                      <span>Q2</span>
                    </b-radio-button>
                  </p>
                  <p class="control">
                    <b-radio-button v-model="publication.quartile"
                                    :native-value="3"
                                    type="is-success is-light is-outlined">
                      <span>Q3</span>
                    </b-radio-button>
                  </p>
                  <p class="control">
                    <b-radio-button v-model="publication.quartile"
                                    :native-value="4"
                                    type="is-success is-light is-outlined">
                      <span>Q4</span>
                    </b-radio-button>
                  </p>
                </div>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Página de inicio">
                <b-input readonly v-model="publication.start_page"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Página de término">
                <b-input readonly v-model="publication.end_page"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Volumen de la revista">
                <b-input readonly v-model="publication.volumen" type="text"></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="¿Es SciELO?">
                <b-radio-button v-model="publication.is_scielo"
                                disabled
                                :native-value="false"
                                type="is-danger is-light is-outlined">
                  <b-icon icon="times-circle"></b-icon>
                  <span>No</span>
                </b-radio-button>
                <b-radio-button v-model="publication.is_scielo"
                                disabled
                                :native-value="true"
                                type="is-success is-light is-outlined">
                  <b-icon icon="check-circle"></b-icon>
                  <span>Si</span>
                </b-radio-button>
              </b-field>
            </div>
            <div class="column">
              <b-field label="¿Es Scopus?">
                <b-radio-button v-model="publication.is_scopus"
                                disabled
                                :native-value="false"
                                type="is-danger is-light is-outlined">
                  <b-icon icon="times-circle"></b-icon>
                  <span>No</span>
                </b-radio-button>
                <b-radio-button v-model="publication.is_scopus"
                                disabled
                                :native-value="true"
                                type="is-success is-light is-outlined">
                  <b-icon icon="check-circle"></b-icon>
                  <span>Si</span>
                </b-radio-button>
              </b-field>
            </div>
            <div class="column">
              <b-field label="¿Es Wos?">
                <b-radio-button v-model="publication.is_wos"
                                :native-value="false"
                                disabled
                                type="is-danger is-light is-outlined">
                  <b-icon icon="times-circle"></b-icon>
                  <span>No</span>
                </b-radio-button>
                <b-radio-button v-model="publication.is_wos"
                                :native-value="true"
                                disabled
                                type="is-success is-light is-outlined">
                  <b-icon icon="check-circle"></b-icon>
                  <span>Si</span>
                </b-radio-button>
              </b-field>
            </div>
            <div class="column">
              <b-field label="¿Es Wos Emergente?">
                <b-radio-button v-model="publication.is_emergent_wos"
                                :native-value="false"
                                disabled
                                type="is-danger is-light is-outlined">
                  <b-icon icon="times-circle"></b-icon>
                  <span>No</span>
                </b-radio-button>
                <b-radio-button v-model="publication.is_emergent_wos"
                                :native-value="true"
                                disabled
                                type="is-success is-light is-outlined">
                  <b-icon icon="check-circle"></b-icon>
                  <span>Si</span>
                </b-radio-button>
              </b-field>
            </div>
            <div class="column">
              <b-field label="¿Es multidisciplinaria?">
                <b-radio-button v-model="publication.multidisciplinary"
                                disabled
                                :native-value="false"
                                type="is-danger is-light is-outlined">
                  <b-icon icon="times-circle"></b-icon>
                  <span>No</span>
                </b-radio-button>
                <b-radio-button v-model="publication.multidisciplinary"
                                disabled
                                :native-value="true"
                                type="is-success is-light is-outlined">
                  <b-icon icon="check-circle"></b-icon>
                  <span>Si</span>
                </b-radio-button>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Link de la publicación">
                <b-input readonly v-model="publication.url"></b-input>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        :active="isComponentModalActive"
        width="90%"
        :can-cancel="canCancel"
        aria-modal>
      <template #default="props">
        <new-publication-form modal-cancel="true"
                          :cargo-principal="cargoPrincipal"
                          :user-id="userId"
                          modal-header="Editar Publicación"
                          query-type="update" v-bind:publication-id="publication.id" @close="closeModal"></new-publication-form>
      </template>
    </b-modal>
  </section>
</template>
<script>
import axios from "axios";
import NewPublicationForm from "./NewPublicationForm";
export default {
  name: "ShowGeneralTab",
  components: {NewPublicationForm},
  props:['cargoPrincipal','userId','publicationId','publication','participantes'],
  data(){
    return{
      currentPage:1,
      isLoading:false,
      requiredFieldOnly:false,
      isComponentModalActive: false,
      canCancel: ['escape']

    }
  },

  methods:{
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    editPublication(){
      //window.location.href = "/projects/"+project_id+"/edit"
      this.isComponentModalActive = true;
    },
    closeModal(){
      this.isComponentModalActive = false;
    },
    execNullifyModal(){
      this.$buefy.dialog.confirm({
        title: 'Anular publicación',
        message: '¿Está seguro que desea <b>anular</b> la publicación? Esta acción la dejará inválida.<br>',
        confirmText: 'Anular Publicación',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true
          axios
              .delete("/publications/" + this.publication.id + "/nullify_publication.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Publicación anulada correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                if (document.referrer == ''){
                  window.location.href = history.back()
                }
                else{
                  window.location.href = document.referrer
                }
                this.isLoading = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Publicación no pudo ser anulado</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
          })
        }
      })
    },

  },
  computed:{
  }
}
</script>
<style scoped>

</style>