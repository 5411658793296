<template>
<section>
  <associated-entities-table v-bind:entities="entities"
                             @updateEntity="updateEntity"
                             :project="project"></associated-entities-table>
  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
</section>
</template>

<script>
import AssociatedEntitiesTable from './AssociatedEntitiesTable.vue'
import axios from "axios";
export default {
  name: "ProjectAssociatedEntitiesTab",
  components:{ AssociatedEntitiesTable },
  props:['project','showPecuniaryContribution'],
  data() {
    return {
      entities:[],
      isLoading: false,
    }
  },
  created(){
    this.getProjectAssociatedEntities();
  },
  methods:{
    getProjectAssociatedEntities(){
      let vm = this;
      vm.isLoading=true;
      return axios
        .get("/projects/"+vm.project.id+"/associated-entities.json")
        .then(res => {
          vm.entities = res.data.partner_entities;
          vm.isLoading=false;
        })
        .catch(e => {
          //console.log(e);
          vm.isLoading=false;
        });

    },
    updateEntity(){
      this.getProjectAssociatedEntities();
    }

  }

}
</script>

<style scoped>

</style>