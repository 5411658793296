<template>
    <section>
      <div v-if="can_create_academic_activity === 'true'" class="columns" >
        <div class="column is-offset-8">
          <b-field label = " " class="has-text-right">
            <b-button class="is-primary" tag="a" href="/publications/type" target="_blank" icon-left="plus">
              Añadir Publicación
            </b-button>
          </b-field>
        </div>
      </div>
        <b-tabs position="is-centered" v-model="currentTab" :multiline="true" type="is-toggle">
            <b-tab-item v-for="item in items" v-bind:key="item.content" :value="item.content" :label="item.tab"
                :icon="item.icon" :disabled="item.disabled" />
        </b-tabs>
        <!-- COMPONENTE AQUI -->
        <keep-alive>
            <component v-bind:is="currentTabComponent" :person-id="personId" :photo-url="photoUrl" :min-year="minYear" :max-year="maxYear" :change-filter="changeFilter"
                :cargo-principal="cargoPrincipal" :hide-update="true" :can-create-document="canCreateDocument" />
        </keep-alive>

        <!-- FIN COMPONENTE -->
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

    </section>
</template>

<script>
import MyMagazinePublication from './my_magazine_publication_tab.vue'
import MyBookPublications from './my_book_publications_tab.vue'
import AddAcademicActivityDropdown from "./add_academic_activity_dropdown.vue";
export default {
    props: [
        'personId', 'canCreateDocument', 'photoUrl', 'cargoPrincipal', 'minYear', 'maxYear', 'changeFilter', 'can_create_academic_activity'
    ],
    components: {
      AddAcademicActivityDropdown,
        MyMagazinePublication, MyBookPublications,
    },
    data() {
        return {
            publicaciones: [],
            isLoading: false,
            year: new Date().getFullYear(),
            currentTab: 'MyMagazinePublication',
            items: [
                {tab: 'Mis Publicaciones en Revista',content: 'MyMagazinePublication', icon:"newspaper", disabled: false},
                {tab: 'Mis Publicaciones en Libro',content: 'MyBookPublications', icon:"book", disabled: false},
                {tab: 'Mis Publicaciones en Congreso',content: 'MyCongressPublication', disabled: true},
            ],
        }
    },
    
    computed: {
        currentTabComponent: function () {
            return this.currentTab
        }
    },
}
</script>