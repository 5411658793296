<template>
  <section>
    <section>
      <div class="box">
        <div class="columns">
          <div class="column has-text-centered">
            <div class="title is-3 is-centered">
              Ficha de {{person.person_name || ""}}
            </div>
          </div>
        </div>
        <div class="container">
          <b-tabs position="is-centered" >
            <b-tab-item label="Datos personales"
                        icon="user">
              <div class="container" style="margin-top: 40px">
                <div class="columns">
                  <div class="column">
                    <b-field label="Nombres">
                        <b-input readonly v-model="person.name" type="text"></b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Primer Apellido">
                        <b-input readonly v-model="person.last_name" type="text"></b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Segundo Apellido">
                        <b-input readonly v-model="person.second_surname" type="text"></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns" v-if="person.run_attributes">
                  <div class="column is-3">
                    <b-field label="Rut">
                        <b-input readonly v-model="person.run_attributes.rut"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-1">
                    <b-field label="DV">
                      <b-input readonly v-model="person.run_attributes.dv" maxlength="1" :has-counter=false></b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Correo personal">
                        <b-input readonly v-model="person.personal_mail"></b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Pasaporte">
                      <b-input readonly v-model="person.run_attributes.passport" maxlength="20" :has-counter=false></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns" v-else>
                  <div class="column is-3">
                    <b-field label="Rut">
                        <b-input readonly ></b-input>
                    </b-field>
                  </div>
                  <div class="column is-1">
                    <b-field label="DV">
                      <b-input readonly ></b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Correo personal">
                        <b-input readonly v-model="person.personal_mail"></b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Pasaporte">
                      <b-input readonly maxlength="20" :has-counter=false></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns">

                  <div class="column">
                    <b-field label="Nacionalidad" v-if="person.nationality_attributes">
                      <b-input  readonly v-model="person.nationality_attributes.name" maxlength="255" :has-counter=false></b-input>
                    </b-field>
                    <b-field label="Nacionalidad" v-else>
                      <b-input  readonly maxlength="255" :has-counter=false></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Dirección">
                      <b-input readonly v-model="person.address" maxlength="255" :has-counter=false></b-input>
                    </b-field>
                  </div>



                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Celular">
                        <b-input readonly v-model="person.cellphone" expanded></b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Teléfono">
                        <b-input readonly v-model="person.phone"></b-input>
                    </b-field>
                  </div>
                </div>
              </div>
            </b-tab-item>
            <b-tab-item label="Documentos"
                        icon="file-alt">

              <h3 class="is-size-4 has-text-weight-bold has-text-centered">Tabla de Documentos</h3>
              <div class="has-text-right">
                <b-button icon-left="plus"
                          v-if="can_create=='true'"
                          type="is-primary"
                          @click="newDocument()">
                  Agregar Documento
                </b-button>
              </div>
                <div class="column">
                  <b-table
                    :data="documentos"
                    :paginated=true
                    per-page=10
                    striped
                    hoverable
                    :current-page="currentPage"
                    :card-layout="cardLayout"
                    :pagination-simple=false
                    pagination-position="bottom"
                    default-sort-direction="desc"
                    sort-icon="arrow-up"
                    sort-icon-size="is-small"
                    default-sort="last_patent_state_date"
                    aria-next-label="Siguiente"
                    aria-previous-label="Anterior"
                    aria-page-label="Página"
                    aria-current-label="Página actual">
                  <b-table-column
                      field= 'origin_document_type.name'
                      label= 'Proceso'
                      width="15em"
                      searchable
                      sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{  props.row.origin_document_type.name || " " }}
                    </template>
                  </b-table-column>

                  <b-table-column
                      field= 'document_type.name'
                      label= 'Tipo documento'
                      width="15em"
                      searchable
                      sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{  props.row.document_type.name || " " }}
                    </template>
                  </b-table-column>

                  <b-table-column
                      field= 'servicio.code'
                      label= 'Código'
                      searchable
                      width="10em"
                      sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      <a @click="goToServiceDocument(props.row)">
                        {{  props.row.servicio.code || " " }}
                        <b-icon icon="share-square" type="is-primary"></b-icon>
                      </a>
                      
                    </template>
                  </b-table-column>

                  <b-table-column
                      field= 'document_date'
                      label= 'Fecha del documento'
                      searchable
                      width="10em"
                      :custom-search="searchDocumentDate"
                      sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.document_date._isValid ? new Date(props.row.document_date).toLocaleDateString("es-CL"): '' }}
                    </template>
                  </b-table-column>
                  <b-table-column
                      label="Documentación"
                      width="10em"
                      v-slot="props">

                    <b-button type="is-primary is-light is-outlined"
                              icon-left="download"
                              @click="dowloadDocument(props.row)">
                    </b-button>

                    <b-button type="is-success is-light is-outlined"
                              @click="showPdf(props.row)">
                              <span class="icon" style="padding-bottom: 1px">
                                <span class="fa-stack fa-sm">
                                  <i class="fas fa-file fa-stack-2x custom-size-icon-behind"></i>
                                  <i class="fas fa-eye fa-stack-1x custom-size-icon-front has-text-white"></i>
                                </span>
                              </span>
                    </b-button>
                  </b-table-column>
                  <!--
                  <b-table-column
                      label="Acción"
                      width="10"
                      v-slot="props">
                    <b-icon
                        v-if="isUpdatingAndDeletingParticipant(props.row)"
                        pack="fas"
                        style="color: #e50000;"
                        icon="trash"
                        size="is-medium"
                        @click.native="deleteParticipantButton(props.row)"/>
                  </b-table-column>
                -->

                  <template slot="empty" slot-scope="props">
                    <div class="columns is-centered">
                      <div class="column is-6 is-centered has-text-centered">
                        <b-message type="is-danger">
                          No se encontraron documentos.
                        </b-message>
                      </div>
                    </div>
                  </template>
                </b-table>
                </div>
            </b-tab-item>
            <b-tab-item label="Servicios"
                        icon="folder-open">
              <h3 class="is-size-4 has-text-weight-bold has-text-centered">Tabla de Servicios integrados</h3>
              <b-table
                  :data="servicios"
                  :paginated=true
                  per-page=10
                  striped
                  hoverable
                  :card-layout="cardLayout"
                  :current-page="currentPage"
                  :pagination-simple=false
                  pagination-position="bottom"
                  default-sort-direction="desc"
                  sort-icon="arrow-up"
                  sort-icon-size="is-small"
                  default-sort="servicio.start_date"
                  aria-next-label="Siguiente"
                  aria-previous-label="Anterior"
                  aria-page-label="Página"
                  aria-current-label="Página actual"
                  :opened-detailed="defaultOpenedDetails"
                  detailed
                  detail-key="code"
                  @details-open="(row) => $buefy.toast.open(`Expandiendo ${row.name}`)"
                  :show-detail-icon="showDetailIcon"
              >
                <b-table-column
                    field= 'sdt_service_type'
                    label= 'Tipo de Servicio'
                    width="20em"
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.sdt_service_type }}
                  </template>
                </b-table-column>

                <b-table-column
                    field= 'code'
                    label= 'Código'
                    width="10em"
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.code}}
                  </template>
                </b-table-column>

                <b-table-column
                    field= 'name'
                    label= 'Nombre servicio'
                    width="25em"
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.name }}
                  </template>
                </b-table-column>

                <b-table-column
                    field= 'unit'
                    label= 'Unidad'
                    width="20em"
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.unit }}
                  </template>
                </b-table-column>


                <b-table-column
                    field= 'award_year'
                    label= 'Año creación'
                    width="10em"
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.award_year }}
                  </template>
                </b-table-column>



                <b-table-column
                    field= 'start_date'
                    label= 'Fecha de inicio'
                    width="10em"
                    :custom-search="searchInitDate"
                    searchable
                    sortable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL"): '' }}
                  </template>
                </b-table-column>


                <b-table-column
                    label="Acciones"
                    v-slot="props">
                  <b-button type="is-primary"
                            icon-left="eye"
                            @click="goService(props.row)">
                  </b-button>
                </b-table-column>

                <template slot="detail" slot-scope="props">
                  <article class="media">
                    <div class="media-content">
                      <div class="content">
                        <p v-if="props.row.projectManagment">
                          <strong>Jefe de proyecto: </strong>
                          <span>{{props.row.projectManagment.person_name || "Sin definir"}}</span>
                        </p>
                        <p v-if="props.row.partner_entity">
                          <strong>Cliente: </strong>
                          <span>{{props.row.partner_entity}}</span>
                        </p>

                      </div>
                    </div>
                  </article>
                </template>

                <template slot="empty" slot-scope="props">
                  <div class="columns is-centered">
                    <div class="column is-6 is-centered has-text-centered">
                      <b-message type="is-danger">
                        No se encontraron servicios.
                      </b-message>
                    </div>
                  </div>
                </template>


              </b-table>
            </b-tab-item>
          </b-tabs>

          <!-- FIN COMPONENTE -->
        </div>
      </div>
    </section>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="isComponentModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        width="100%"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-document :services="servicios"
                        :service-project-managment="person"
                        style="width: 75vw"
                        modal-header="Añadir Documento"
                        :isServiceCreated="true"
                        type-document="personExternal"
                        @close="props.close"
                        @clicked="onClickChildDocument"
        >
        </modal-document>
      </template>
    </b-modal>
    <b-modal
        v-model="isComponentModalPDF"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        width="100%"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-show-pdf   :src="src"
                          style="width: 90vw"
                          @close="props.close"
                         
        >
        </modal-show-pdf>
      </template>
    </b-modal>

  </section>
</template>

<script>
import axios from "axios";
import {searchDate} from "../../packs/utilities";
import moment from "moment/moment";
import modalDocument from "./ModalUploadDocument.vue";
import modalShowPdf from "./ModalShowPDF.vue";
export default {
  components: {modalDocument, modalShowPdf},
  name: "PersonProfileSdt",  
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      person:{run_attributes: null},
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      documentos: [],
      servicios: [],
      isComponentModalActive: false,
      canCancel: ['escape'],
      isComponentModalPDF: false,
      src: null,
    }
  },
  props:[
      'personId','cargoPrincipal','can_create',
  ],
  async created(){
    let vm = this;
    vm.isLoading=true;
    return axios.get("/people/sdt/"+vm.personId+".json")
      .then(res => {
        vm.person = res.data.person;
        vm.documentos = res.data.documents;
        vm.documentos = vm.documentos.map(documento => {
          var newDocumento = documento
          newDocumento["document_date"] = moment(documento.document_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
          return newDocumento;
        });
        vm.servicios = res.data.services;
        vm.servicios = vm.servicios.map(servicio => {
          var newServicio = servicio
          if(newServicio["start_date"]!=null)
            newServicio["start_date"] = moment(servicio.start_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
          return newServicio;
        });
        vm.isLoading=false;
      })
      .catch(e => {
        vm.isLoading=false;
        //console.log(e);
      });

  },

  methods:{
    goService(service){
      window.location.href = "/sdt_services/"+service.id+"?type="+service.table;
    },
    goToServiceDocument(row){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/sdt_services/document/"+row.id+"/service.json")
        .then(res => {
          window.open(res.data, '_blank');
          vm.isLoading = false
        })
        .catch(e => {
          vm.isLoading=false;
        });
    },
    newDocument(){
      this.isComponentModalActive = true;
    },
    onClickChildDocument(value){
      this.isLoading = true
      value.fecha = moment(value.fecha, ["YYYY-MM-DD","DD-MM-YYYY"]);
      this.documentos.push(value)
      this.isLoading = false
    },
    searchInitDate(row,input){
      return searchDate(row.start_date,input)
    },
    searchDocumentDate(row,input){
      return searchDate(row.document_date,input)
    },    
    dowloadDocument(row){
      let vm = this;
      this.isLoading = true;
      axios.get("/documents/" + row.id + "/download", {
            responseType: "blob",
          })
      .then( response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', row.name);
        document.body.appendChild(link);
        link.click();
        vm.isLoading = false;
        this.$buefy.dialog.alert({
          message: 'Se descargo con exito.',
          type: 'is-success',
          hasIcon: true,
          icon: 'check-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      .catch(e =>{
        this.isLoading = false;
        this.$buefy.dialog.alert({
          message: 'Fallo al descargar.',
          type: 'is-danger',
        });
      })
    },

    showPdf(row){
      let vm = this;
      vm.isLoading = true;
      axios
          .get("/documents/" + row.id + "/download", {
            responseType: "blob",            
            params:{type: "pdf"},
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            vm.src = new Uint8Array(buffer);
            vm.isComponentModalPDF = true
            vm.isLoading = false
          })
          .catch(e => {
            vm.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
  },
  computed:{
  }
}
</script>

<style scoped>
  .custom-size-icon-behind{
    width: 0.9em; 
    height: 1em;
    margin-top: 0.1em;
  }
  .custom-size-icon-front{
    margin-top: 0.8em;
  }
  .fa-sm {
    font-size: 0.775em;
  }
</style>