<template>
  <section>
    <div class="container">
      <div class="box">
        <h1 class="title has-text-left">Comparación datos INAPI</h1>
        <div class="column">
          <b-message title="Importante" type="is-info" has-icon aria-close-label="Cerrar">
            <ul>
              <li>
                - El archivo subido debe venir de GoogleDrive de INAPI. Para ingresar al GoogleDrive de INAPI lo puede hacer presionando
                <a href="https://drive.google.com/drive/u/0/folders/121U4DIFLokZcBaE_0l3Jqy3Ow3JMmDu8" target="_blank">aquí.</a>
              </li>
            </ul>
          </b-message>
          <div class="columns">
            <div class="column">
              <b-field grouped group-multiline>
                <div class="control">
                  <h1 class="title is-4">Seleccionar archivo con datos provenientes de INAPI</h1>
                  <h2 class="subtitle is-6 has-text-weight-bold">*El archivo debe tener la extensión .xlsx.</h2>
                </div>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div >
                <b-field class="file">
                  <b-upload v-model="file" expanded>
                    <a class="button is-primary is-outlined">
                      <b-icon icon="upload"></b-icon>
                      <span>{{ file.name || "Subir Archivo"}}</span>
                    </a>
                  </b-upload>
                </b-field>
              </div>
            </div>
          </div>
          <div class="has-text-right">
            <button class="button is-primary" :disabled="file.name.length===0" @click="compare_data">Comparar datos</button>
          </div>
        </div>
        <h1 v-if="load_data" class="title has-text-centered">Contrastación de datos con INAPI</h1>
        <b-tabs v-if="load_data"  position="is-centered" class="block">
          <b-tab-item label="Patentes no encontradas">
            <b-tabs position="is-centered" type="is-toggle">
              <b-tab-item label="Con coincidencias FING">
                <b-table :data="patents_not_found_faculty"
                         :paginated=true
                         per-page=10
                         striped
                         hoverable
                         :current-page="currentPage"
                         :pagination-simple=false
                         pagination-position="bottom"
                         default-sort-direction="desc"
                         sort-icon="arrow-up"
                         sort-icon-size="is-small"
                         default-sort="year"
                         aria-next-label="Siguiente"
                         aria-previous-label="Anterior"
                         aria-page-label="Página"
                         aria-current-label="Página actual">
                  <b-table-column field= 'code'
                                  label= 'Número de Solicitud'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.code }}
                    </template>
                  </b-table-column>
                  <b-table-column field= 'title'
                                  label= 'Título'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.title }}
                    </template>
                  </b-table-column>
                  <b-table-column field= 'type'
                                  label= 'Tipo de Protección'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.type }}
                    </template>
                  </b-table-column>
                  <b-table-column field= 'status'
                                  label= 'Estado'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.status }}
                    </template>
                  </b-table-column>
                  <b-table-column field= 'participants'
                                  label= 'Participantes'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.participants }}
                    </template>
                  </b-table-column>
                  <b-table-column label="Coincidencias"
                                  width="10"
                                  v-slot="props">
                    <b-icon
                        pack="fas"
                        icon="eye"
                        size="is-medium"
                        @click.native="showPeople(props.row.participants_faculty)"/>
                  </b-table-column>
                </b-table>
              </b-tab-item>
              <b-tab-item label="Sin coincidencias FING">
                <b-table :data="patents_not_found"
                         :paginated=true
                         per-page=10
                         striped
                         hoverable
                         :current-page="currentPage"
                         :pagination-simple=false
                         pagination-position="bottom"
                         default-sort-direction="desc"
                         sort-icon="arrow-up"
                         sort-icon-size="is-small"
                         default-sort="year"
                         aria-next-label="Siguiente"
                         aria-previous-label="Anterior"
                         aria-page-label="Página"
                         aria-current-label="Página actual">
                  <b-table-column field= 'code'
                                  label= 'Número de Solicitud'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.code }}
                    </template>
                  </b-table-column>
                  <b-table-column field= 'title'
                                  label= 'Título'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.title }}
                    </template>
                  </b-table-column>
                  <b-table-column field= 'type'
                                  label= 'Tipo de Protección'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.type }}
                    </template>
                  </b-table-column>
                  <b-table-column field= 'status'
                                  label= 'Estado'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.status }}
                    </template>
                  </b-table-column>
                  <b-table-column field= 'participants'
                                  label= 'Participantes'
                                  searchable
                                  sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{ props.row.participants }}
                    </template>
                  </b-table-column>
                </b-table>
              </b-tab-item>
            </b-tabs>
          </b-tab-item>
          <b-tab-item label="Patentes encontradas">
            <b-table :data="patents_found"
                     :paginated=true
                     per-page=10
                     striped
                     hoverable
                     :current-page="currentPage"
                     :pagination-simple=false
                     pagination-position="bottom"
                     default-sort-direction="desc"
                     sort-icon="arrow-up"
                     sort-icon-size="is-small"
                     default-sort="year"
                     aria-next-label="Siguiente"
                     aria-previous-label="Anterior"
                     aria-page-label="Página"
                     aria-current-label="Página actual">
              <b-table-column field= 'code'
                              label= 'Número de Solicitud'
                              searchable
                              sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{ props.row.code }}
                </template>
              </b-table-column>
              <b-table-column field= 'name'
                              label= 'Título'
                              searchable
                              sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  <a :href="getPatentUrl(props.row)" target="_blank">{{ props.row.name }}</a>
                </template>
              </b-table-column>
              <b-table-column field= 'representative'
                              label= 'Representante'
                              searchable
                              sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  <a :href="getPersonUrl(props.row.representative_id)" target="_blank">{{ props.row.representative }}</a>
                </template>
              </b-table-column>
              <b-table-column field= 'unit'
                              label= 'Unidad'
                              searchable
                              sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{ props.row.unit }}
                </template>
              </b-table-column>
            </b-table>
          </b-tab-item>
        </b-tabs>
      </div>
      <b-modal v-model="showModal"
               has-modal-card
               trap-focus
               :destroy-on-hide="true"
               aria-role="dialog"
               aria-modal
               :can-cancel="canCancel">
        <template #default="props">
          <modal-people-found
              modal-header="Coincidencias de encargado"
              :people-found="peopleFound"
              @close="props.close"></modal-people-found>
        </template>

      </b-modal>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import ModalPeopleFound from "../projects/ModalPeopleFound";

export default {
  name: "CompareInapiData",
  components:{ModalPeopleFound},
  data(){
    return{
      isLoading: false,
      load_data: false,
      file:{name:""},
      patents_found: [],
      patents_not_found:[],
      patents_not_found_faculty:[],
      canCancel: false,
      currentPage: 1,
      showModal: false,
      peopleFound: []
    }
  },
  methods:{
    compare_data(){
      this.isLoading = true
      this.patents_found = []
      this.patents_not_found = []
      let formData = new FormData();
      formData.append('file', this.file);
      axios.post("/patents/compare-inapi-data.json",
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(response => {
        this.patents_found = response.data['patents_found']
        this.patents_not_found = response.data['patents_not_found']
        this.patents_not_found_faculty = response.data['patents_not_found_faculty']
        if (this.patents_not_found != null && this.patents_not_found.length > 0){
          this.load_data = true
        }
        else if(this.patents_found != null && this.patents_found.length > 0){
          this.load_data = true
        }
        else if(this.patents_not_found_faculty != null && this.patents_not_found_faculty.length > 0){
          this.load_data = true
        }
        else{
          this.load_data = false
        }

        if (this.patents_found == true){
          this.$buefy.dialog.alert({
            message: 'Los cabeceras del archivo "'+this.file.name+'" no coinciden.',
            confirmText: 'Ok',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        }

        this.isLoading = false

      }).catch(error =>{
        this.isLoading = false
        this.$buefy.dialog.alert({
          message: 'Los datos del archivo "'+this.file.name+'" no han podido ser analizados, por favor, revisar formato de los datos o del archivo',
          confirmText: 'Ok',
          type: 'is-warning',
          hasIcon: true,
          icon: 'exclamation-triangle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        console.log(error)
      })
    },
    getPatentUrl(patent){
      return "/patents/"+patent.id;
    },
    getPersonUrl(person_id){
      return "/people/"+person_id;
    },
    showPeople(people){
      this.peopleFound = people
      this.showModal = true

    }
  }
}
</script>

<style scoped>

</style>