<template>
    <div class="configurationactivityeditchangetypemodal">
        <form action="">
            <div class="modal-card" style="width: auto;">
                <header class="modal-card-head" style="background-color: #8c67ef; text-align:center;">
                    <p class="modal-card-title" style="color:#fff;">Cambio del tipo de asistente a una actividad</p>
                    <button
                        type="button"
                        class="delete"
                        @click="$emit('close')"/>
                </header>
                <section class="modal-card-body">
                    <b-field label="Tipo de asistente a una actividad"
                        :type="{ 'is-danger': checkActivityType }"
                        :message="{ 'Seleccione el nuevo tipo de asistente.': checkActivityType }">
                        <b-select placeholder="Seleccione un tipo de asistente" v-model='newActivityAssistantType'>
                            <option
                                v-for="activity_type in filteredAssistantActivities"
                                :value="activity_type.id"
                                :key="activity_type.id">
                                {{ activity_type.name }}
                            </option>
                        </b-select>
                    </b-field>
                </section>
                <footer class="modal-card-foot">
                    <b-button
                        label="Cerrar"
                        @click="$emit('close')" />
                    <b-button
                        label="Añadir"
                        type="is-primary"
                        @click="changeActivityType()"/>
                </footer>
            </div>
        </form>
    </div>
</template>
<script>
export default {
    name: 'ConfigurationActivityEditChangeTypeModal',
    props: ['vime_activity_assistant_types', 'activity_assistant_type_to_delete'],
    data(){
        return {
            activityAssistantTypeId: null,
            validations:{
                firstTimeAssistantActivityType: true
            }
        }
    },
    computed:{
        filteredAssistantActivities(){
            return this.vime_activity_assistant_types.filter(element => (
                    element != this.activity_assistant_type_to_delete 
                    && element.assistant_type == this.activity_assistant_type_to_delete.assistant_type
                ) 
            )
        },
        newActivityAssistantType: {
            get(){
                return this.activityAssistantTypeId
            },
            set(newType){
                this.validations.firstTimeAssistantActivityType = false
                this.activityAssistantTypeId = newType
            }
        },
        checkActivityType() {
            if(this.validations.firstTimeAssistantActivityType)
                return false
            else
                return this.activityAssistantTypeId === null
        }
    },
    methods:{
        checkEmptyFields(){
            if(this.validations.firstTimeActivityType === true){
                this.validations.firstTimeActivityType = false
            }
        },
        checkErase(){
            return this.newActivityAssistantType !== null
        },
        changeActivityType(){
            var confirmation = this.checkErase()
            if(confirmation){
                this.$emit('change-activity-assistant-type', {
                    new_activity_assistant_type: this.activityAssistantTypeId,
                    delete_activity_assistant_type: this.activity_assistant_type_to_delete
                });
                this.$emit('close');
            }
            else{
                this.checkEmptyFields()    
            }
        }
    }
}
</script>