<template>
  <section>
    <h1 class="subtitle is-2 has-text-primary">
      ¿Qué información hay en Comisiones?
    </h1>
    <div class="mb-3 ">
      <p>En esta sección se listan las comisiones en las que ha participado el académico.
        Se listan ICC, comisiones de servicio, cometidos funcionario y comisiones de estudio.</p>
    </div>
    <img border="2" class="m-2" src="/tutorial_images/comisiones.png" style="width:80%">
    <div class="mb-3 ">
      <p>El sistema permite ver una comisión de cualquiera de los tres tipos ya mencionados</p>
    </div>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Paso 1
    </h2>
    <p class="my-3 ">Estar situado en la pestaña de <b>Comisiones</b> </p>
    <h2 class="subtitle is-3 mt-5 mb-2">
      Paso 2
    </h2>
    <p class="my-3 ">Presionar el botón con forma de <b>ojo</b> situado en la columna de acciones:
    </p>
    <img border="2" class="m-2" src="/tutorial_images/ver_comisiones.png" style="width:80%">
    <p class="my-3 ">La acción anterior despliega una comisión y permite su visualización:
    </p>
    <img border="2" class="m-2" src="/tutorial_images/comision.png" style="width:80%">
  </section>
</template>
<script>
export default {
  name: "what_is_commission_mhv",
  data(){
    return {
    }
  },
  methods:{

  },
}
</script>