<template>
  <section>
    <div class="container">
      <h1 class="is-size-2">Documentos Anulados</h1>
      <div class="columns is-centered" v-if="documents.length===1" style="margin-bottom: 40px; margin-top: 40px;">
        <div class="column is-6 is-centered has-text-centered">
          <b-message type="is-warning">
            Se encontró {{this.documents.length}} resultado.
          </b-message>
        </div>
      </div>
      <div class="columns is-centered" v-if="documents.length!==1" style="margin-bottom: 40px; margin-top: 40px;">
        <div class="column is-6 is-centered has-text-centered">
          <b-message type="is-warning">
            Se encontraron {{this.documents.length}} resultados.
          </b-message>
        </div>
      </div>
      <br>
      <b-table
          :data="documents"
          :paginated=true
          per-page=10
          striped
          hoverable
          style="word-break:break-all;"
          narrowed
          :card-layout="cardLayout"
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="desc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="documents.formatted_document_date"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual"
      >
        <b-table-column
            field= 'name'
            label= 'Nombre'
            width="300"
            searchable
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.name }}
          </template>
        </b-table-column>

        <b-table-column
            field= 'document_category_name'
            label= 'Carpeta'
            searchable
            width="300"
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.document_category_name }}
          </template>
        </b-table-column>

        <b-table-column
            field= 'document_type_name'
            label= 'Tipo Documento'
            searchable
            width="200"
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.document_type_name }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'resolution'
            label= 'N°'
            searchable
            width="100"
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.resolution }}
          </template>
        </b-table-column>
        <b-table-column
            field= 'formatted_document_date'
            label= 'Fecha'
            searchable
            width="100"
            sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.formatted_document_date}}
          </template>
        </b-table-column>
        <b-table-column
            label="Acciones"
            v-slot="props">
          <b-tooltip
              label="Restaurar documento"
              :active="true">
            <b-button
                pack="fas"
                icon-left="trash-restore"
                type="is-success is-light is-outlined"
                v-if="props.row.can_restore"
                @click.native="execRestoreModal(props.row.id, props.row)"/>
          </b-tooltip>
        </b-table-column>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <p>
                  <strong>Etiquetas: </strong>
                  <span>{{props.row.name_tags}}</span>
                </p>
              </div>
            </div>
          </article>
        </template>

        <template slot="empty" slot-scope="props">
          <div class="columns is-centered">
            <div class="column is-6 is-centered has-text-centered">
              <b-message type="is-danger">
                No se encontraron resultados.
              </b-message>
            </div>
          </div>
        </template>


      </b-table>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

    </div>
  </section>
</template>
<script>
import {searchDate, generateArrayOfYears} from "../../packs/utilities";
import axios from "axios";
export default {
  name: "null_documents",
  props:[
    'cargoPrincipal', 'can_restore'
  ],
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      isRestoreActive: false,
      documents: [],
      document_types: [],
      document_type_id: null,
      document_category_id: null,
      document_categories: [],
      resolution: null,
      errores_validacion: {},
      start_date: null,
      end_date: null
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    initialize(){
      this.isLoading = true
      return axios.get("/documents/nullified-documents.json")
          .then(res => {
            this.documents = res.data
            this.isLoading=false;
          })
          .catch(e => {
            this.isLoading=false;
          });
    },
    getDocumentUrl(document_id){
      return "/documents/"+document_id;
    },
    execRestoreModal(id, row){
      this.$buefy.dialog.confirm({
        title: 'Restaurar Documento',
        message: '¿Está seguro que desea <b>restaurar</b> el documento? Esta acción lo dejará válido.<br>',
        confirmText: 'Restaurar Documento',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isRestoreActive = true
          this.isLoading = true
          axios
              .put("/documents/" + id + "/restore-document.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Documento restaurado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                this.isLoading = false;
                this.isRestoreActive = false;
              }).catch(error => {
            console.log(error.response.data)
            this.$buefy.dialog.alert({
              message: '<b>Documento no pudo ser restaurado</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isRestoreActive = false;
          })
        }
      })
    },

  },
  watch:{
    isRestoreActive: function () {
      if (!this.isRestoreActive) {
        this.initialize()
      }
    },
  },
  computed:{

  }
}
</script>