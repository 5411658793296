<template>
  <section>
    <!-- Box de Formulario HouseholdAllowance -->
    <div class="modal-card" style="width: auto" id="distance-household">
      <header class="modal-card-head">
        <p class="modal-card-title">{{modalHeader}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body" id="distance-household" ref="distanceHousehold">
        <div class="columns">
          <div class="column">
            <b-field label="Observaciones">
              <b-input :value="household_allowance.observations" type="textarea" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Número de documento">
              <b-input :value="household_allowance.decree" type="text" readonly></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha de documento">
              <b-input :value="formatDate(household_allowance.decree_date)" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Fecha Cese de Asignación">
              <b-input :value="formatDate(household_allowance.start_date)" type="text" readonly></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns is-gapless">
          <div class="column">
            <b-field label="Documento">
              <b-input expanded readonly v-model="household_allowance.document_name" type="text"></b-input>
            </b-field>
          </div>
          <div class="column is-narrow">
            <br>
            <b-field v-if="household_allowance.can_view_pdf" label="  ">
              <b-button @click="viewFile(household_allowance.document_id)" icon-left="eye" type="is-success">ver</b-button>
              <b-button @click="downloadFile(household_allowance.document_id)" icon-left="download" type="is-info">Descargar</b-button>
            </b-field>
          </div>
        </div>
        <div class="container">
          <b-collapse
              aria-id="contentIdForA11y2"
              class="panel"
              animation="slide"
              v-model="isOpen">
            <template #trigger>
              <div
                  style="margin-bottom:40px;"
                  class="card-header"
                  role="button"
              >
                <p class="card-header-title">
                  <strong>{{isOpen ? "Ocultar Cese de Asignación" : "Mostrar Cese de Asignación"}}</strong>
                </p>
                <a class="card-header-icon">
                  <b-icon class="is-pulled-right" :icon="isOpen ? 'sort-down' : 'sort-up'"></b-icon>
                </a>
              </div>
            </template>

            <component :is="formName"
                       :household="old_household_allowance"
            />
          </b-collapse>
        </div>
      </section>
    </div>
    <footer class="modal-card-foot actions">
      <div class="field is-grouped is-pulled-right">
        <button class="button" type="button" @click="$emit('close')">Cerrar</button>
        <b-button class="is-primary"
                  v-if="household_allowance.can_edit"
                  @click="isEditModalActive = true">
          Editar
        </b-button>
      </div>
    </footer>
    <b-modal
        v-model="isEditModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        :can-cancel="canCancel"
        aria-modal>
      <template #default="props">
        <modal-distance-household :person-id="old_household_allowance.person_id"
                                       :household_allowance_id="old_household_allowance.id"
                                       :id="household_allowance.id"
                                       :person_name="old_household_allowance.name"
                                       :person_last_name="old_household_allowance.last_name"
                                       :person_second_surname="old_household_allowance.second_surname"
                                       :person_birthday="old_household_allowance.birthday"
                                       :rut="old_household_allowance.rut"
                                       :old_start_date="old_household_allowance.start_date"
                                       query-type="update"
                                       :modal-header="'Editar Cese de Asignación - '+person_name"
                                       @close="props.close">
        </modal-distance-household>
      </template>
    </b-modal>
    <b-modal
        v-model="showPdf"
        :can-cancel="true"
        :destroy-on-hide="true"
        aria-modal
        aria-role="dialog"
        has-modal-card
        trap-focus
        :width="2000"
    >
      <template #default="props">
        <pdf_view
            :src="src"
            @close="props.close"
        ></pdf_view>
      </template>

    </b-modal>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios"
import moment from "moment";
import EventBus from "../../eventBus";
import {formatDate} from "../../../packs/utilities"
import ModalDistanceHousehold from "./modal_distance_household"
import HouseholdInDistance from "./household_in_distance"
import Pdf_view from "../../performances/pdf_view.vue";
export default {
  name: "distanced_household",
  props: [
    'queryType', 'personId', 'id','modalHeader', 'person_name'
  ],
  components: {
    Pdf_view,
    ModalDistanceHousehold, HouseholdInDistance
  },
  data(){
    return {
      household_allowance: {},
      old_household_allowance: {},
      isLoading: false,
      isEditModalActive: false,
      isOpen: false,
      canCancel: ['escape'],
      formName: "",
      showPdf: false,
      src:null,
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    formatDate,
    initialize(){
      this.isLoading=true;
      axios
          .get("/household_allowances/"+this.id+"/show_distance.json")
          .then(res => {
            this.household_allowance = res.data["distance"]
            this.old_household_allowance = res.data["old_household_allowance"]
            this.formName = "HouseholdInDistance"
            this.isLoading=false;
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    },
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            this.showPdf = true
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    downloadFile(fileId){
      this.isLoading = true;

      if (fileId == null){
        this.isLoading = false;
        return;
      }

      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(res => {
            let blob = new Blob([res.data], {
              type: "application/pdf",
            })
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = res.headers["content-disposition"]
                .split(";")[1]
                .split('"')[1];
            link.click();
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento fue descargado con exito',
              type: 'is-success',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
          .catch(e => {
            console.log(e)
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento no se pudo descargar',
              type: 'is-danger',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
    },
  },
  watch:{
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
  }
}
</script>