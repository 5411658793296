<template>
  <section>
    <div class="box">
      <projects-table
          :proyectos="financed_projects" @updateProjects="updateProjects" :show-postulated="showPostulated"
          :cargo-principal="''"></projects-table>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import ProjectsTable from "/app/javascript/components/projects/projects_table";
export default {
  name: "FinancedProjectsTab",
  data(){
    return{
      errores_validacion:{},
      financed_projects:[],
      isLoading:false,
      year:new Date().getFullYear(),
      activeTab: 0,
      showPostulated:false,
    }
  },
  props:['entity','project_type'],
  components:{ ProjectsTable },
  created() {
    this.getProjectFinancings(this.year);
  },
  methods: {
    getProjectFinancings(year) {
      let vm = this;
      vm.isLoading = true;
      let url = "/partner_entities/" + vm.entity.id + "/financed-projects.json";
      if (vm.project_type=="financiados"){
        url = "/partner_entities/" + vm.entity.id + "/financed-projects.json";
      }
      else if(vm.project_type=="postulados_con_aporte"){
        vm.showPostulated = true;
        url = "/partner_entities/" + vm.entity.id + "/postulated-projects.json";
      }
      else if(vm.project_type=="postulados_asociados"){
        vm.showPostulated = true;
        url = "/partner_entities/" + vm.entity.id + "/postulated-associated-projects.json";
      }
      return axios
          .get(url, {params: {year_f: year}})
          .then(res => {
            vm.financed_projects = res.data;
            vm.financed_projects = vm.financed_projects.map(proyecto => {
              var newProyecto = proyecto
              newProyecto["start_date"] = moment(newProyecto["start_date"], ["YYYY-MM-DD", "DD-MM-YYYY"]);
              newProyecto["end_date"] = moment(newProyecto["end_date"], ["YYYY-MM-DD", "DD-MM-YYYY"]);
              newProyecto["postulation_date"] = moment(newProyecto["postulation_date"], ["YYYY-MM-DD", "DD-MM-YYYY"]);
              return newProyecto;
            });
            vm.isLoading = false;
          })
          .catch(e => {
            //console.log(e);
            vm.isLoading = false;
          });
    },
    updateProjects(year) {
      this.getProjectFinancings(year);
    }
  }
}
</script>

<style scoped>

</style>