<template>
  <div class="box">
    <section>
      <div class="columns" v-if="request">
        <div class="column">
          <h1 class="is-size-2">
            Solicitud #<b>{{ request.id }}</b>
          </h1>
        </div>
        <div class="column">
          <div class="has-text-right">
            <b-button
              type="is-info"
              icon-left="edit"
              v-if="request.can_edit"
              @click="goToEdit()"
            >
              Editar Solicitud
            </b-button>
            <b-button
              v-if="request.can_send"
              type="is-primary"
              icon-left="paper-plane"
              @click="promptRequest()"
            >
              Enviar Solicitud
            </b-button>
            <b-button
              v-if="request.can_send_correct"
              type="is-primary"
              icon-left="paper-plane"
              @click="promptRequestCorrect()"
            >
              Enviar Solicitud Corregida
            </b-button>
          </div>
        </div>
        <!--<div class="field has-addons">
              <div class="control">
                <h1 class="is-size-2">Solicitud #<b>{{ request.id }}</b></h1>
              </div>
              <div class="control">
              <b-tag type="is-light" size="is-large">Solicitud #<b>{{ request.id }}</b></b-tag>
                <b-tag :type="request.type" size="is-large">{{ request.request_state!==undefined ? request.request_state.name : "" }}</b-tag>
              </div>
            </div>-->
      </div>
      <!--<b-taglist attached v-if="request">
          <h1 class="is-size-2">Solicitud #<b>{{ request.id }}</b></h1><br>
          <b-tag type="is-light" size="is-large">SOLICITUD #<b>{{ request.id }}</b></b-tag>
          <b-tag :type="request.type" size="is-large">{{ request.request_state!==undefined ? request.request_state.name : "" }}</b-tag>
        </b-taglist>-->
      <div>
        <div
          class="has-text-right"
          v-if="cargoPrincipal.name != 'JEFE ADMINISTRATIVO FING'"
        >
          <!--          <b-button v-if="request.request_state!==undefined && request.request_state.name === 'APROBADO'"
                    @click="downloadWordResolution()"
                    icon-left="file-word"
                    type="is-word-color">
            Transcripción Resolución
          </b-button>-->
          <!--          <b-button v-if="request.request_state!==undefined && (request.request_state.name === 'CREADO' || request.request_state.name === 'AJUSTAR' )"
                    @click="downloadWordResolution()"
                    icon-left="file-word"
                    type="is-word-color">
            Borrador Transcripción Resolución
          </b-button>-->
          <b-button
            v-if="
              request.request_state !== undefined &&
              (request.request_state.name === 'CREADO' ||
                request.request_state.name === 'AJUSTAR')
            "
            @click="massiveDownloadPaf()"
            icon-left="file-word"
            type="is-word-color"
          >
            Borrador PAF
          </b-button>
          <b-button
            v-if="
              request.request_state !== undefined &&
              request.request_state.name === 'APROBADO'
            "
            @click="massiveDownloadPaf()"
            icon-left="file-word"
            type="is-word-color"
          >
            PAF
          </b-button>
          <!--          <b-button v-if="request.request_state!==undefined && request.request_state.name === 'APROBADO'"
                    @click="downloadWordResolutionCaratula()"
                    icon-left="file-word"
                    type="is-word-color">
            Caratula Resolución
          </b-button>-->
          <!--          <b-button v-if="request.request_state!==undefined && (request.request_state.name === 'CREADO' || request.request_state.name === 'AJUSTAR' )"
                    @click="downloadWordResolutionCaratula()"
                    icon-left="file-word"
                    type="is-word-color">
            Borrador Caratula Resolución
          </b-button>-->
          <b-button
            v-if="
              request.request_state !== undefined &&
              (request.request_state.name === 'CREADO' ||
                request.request_state.name === 'AJUSTAR' ||
                request.request_state.name === 'APROBADO')
            "
            @click="downloadWordPersonUnit()"
            icon-left="file-word"
            type="is-word-color"
          >
            Proposión de Nombramiento
          </b-button>

          <!--          <b-button v-if="!request.can_send"
                              @click="activeModalSemesterYear('pdf')"
                              icon-left="file-pdf"
                              type="is-pdf-color">
                      Descargar Borrador de Resolución
                    </b-button>-->
          <!--          <b-button v-if="request.request_state!==undefined && request.request_state.name == 'APROBADO'"
                              @click="downloadPdfResolution()"
                              icon-left="file-pdf"
                              type="is-pdf-color">
                      Transcripción Resolución
                    </b-button>-->
        </div>

        <div
          class="has-text-right"
          v-if="cargoPrincipal.name == 'JEFE ADMINISTRATIVO FING'"
        >
          <b-button
            v-if="
              request.request_state !== undefined &&
              (request.request_state.name === 'EN CURSO' ||
                request.request_state.name === 'CORREGIDO')
            "
            @click="massiveDownloadPaf()"
            icon-left="file-word"
            type="is-word-color"
          >
            Borrador PAF
          </b-button>
          <b-button
            v-if="
              request.request_state !== undefined &&
              request.request_state.name === 'APROBADO'
            "
            @click="massiveDownloadPaf()"
            icon-left="file-word"
            type="is-word-color"
          >
            PAF
          </b-button>
          <!--          <b-button v-if="request.request_state!==undefined && request.request_state.name === 'APROBADO'"
                    @click="downloadWordResolution()"
                    icon-left="file-word"
                    type="is-word-color">
            Transcripción Resolución
          </b-button>
          <b-button v-if="request.request_state!==undefined && (request.request_state.name === 'EN CURSO' || request.request_state.name === 'CORREGIDO' )"
                    @click="downloadWordResolution()"
                    icon-left="file-word"
                    type="is-word-color">
            Borrador Transcripción Resolución
          </b-button>
          <b-button v-if="request.request_state!==undefined && request.request_state.name === 'APROBADO'"
                    @click="downloadWordResolutionCaratula()"
                    icon-left="file-word"
                    type="is-word-color">
            Caratula Resolución
          </b-button>
          <b-button v-if="request.request_state!==undefined && (request.request_state.name === 'EN CURSO' || request.request_state.name === 'CORREGIDO' )"
                    @click="downloadWordResolutionCaratula()"
                    icon-left="file-word"
                    type="is-word-color">
            Borrador Caratula Resolución
          </b-button>-->
          <b-button
            v-if="
              request.request_state !== undefined &&
              (request.request_state.name === 'EN CURSO' ||
                request.request_state.name === 'CORREGIDO' ||
                request.request_state.name === 'APROBADO')
            "
            @click="downloadWordPersonUnit()"
            icon-left="file-word"
            type="is-word-color"
          >
            Proposión de Nombramiento
          </b-button>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column">
          <div class="columns">
            <div class="column is-12">
              <h3 class="is-size-3">Datos Básicos Solicitud</h3>
              <div class="columns">
                <div class="column">
                  <b-field label="Nombre">
                    <b-input
                      :value="request.name"
                      type="text"
                      disabled="true"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column" v-if="request">
                  <b-field label="Estado">
                    <b-tag :type="request.type" size="is-large">{{
                      request.request_state !== undefined
                        ? request.request_state.name
                        : ""
                    }}</b-tag>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Fecha creación solicitud">
                    <b-input
                      :value="request.formatted_request_start_date"
                      type="text"
                      disabled="true"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Fecha fin solicitud">
                    <b-input
                      :value="request.formatted_request_end_date"
                      type="text"
                      disabled="true"
                    ></b-input>
                  </b-field>
                </div>
              </div>
              <h3 class="is-size-3">Datos Solicitante</h3>
              <div class="columns">
                <div class="column">
                  <b-field label="Nombre Solicitante">
                    <b-input
                      :value="request.person_name"
                      type="text"
                      disabled="true"
                    ></b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Correo Solicitante">
                    <b-input
                      :value="request.person_mail"
                      type="text"
                      disabled="true"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Rut Solicitante">
                    <b-input
                      :value="request.person_rut"
                      type="text"
                      disabled="true"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Teléfono Solicitante">
                    <b-input
                      :value="request.person_cellphone"
                      type="text"
                      disabled="true"
                    ></b-input>
                  </b-field>
                </div>
              </div>
              <h3 class="is-size-3">Datos Resolución</h3>
              <div
                v-if="
                  request.person_units !== undefined &&
                  request.can_update_resolution
                "
                class="columns"
              >
                <div class="column">
                  <b-field label="Tipo de documento">
                    <multiselect
                      v-model="resolution_data.resolution_type_id"
                      :options="
                        request.person_units[0].resolution_types.map(
                          (type) => type.id
                        )
                      "
                      :custom-label="
                        (opt) =>
                          request.person_units[0].resolution_types.find(
                            (x) => x.id === opt
                          ).name
                      "
                      placeholder="Seleccione tipo de documento"
                      selectLabel="Presione para seleccionar"
                      selectedLabel="Seleccionado"
                      deselectLabel="Presione para deseleccionar"
                      :allow-empty="true"
                    >
                      <template v-slot:noOptions> No existen datos </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Número de documento">
                    <ValidationProvider
                      rules="integer|positive"
                      v-slot="{ errors }"
                    >
                      <b-input
                        v-model="resolution_data.resolution"
                        type="text"
                      ></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Fecha de documento">
                    <b-datepicker
                      v-model="resolution_data.resolution_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                    >
                    </b-datepicker>
                  </b-field>
                </div>
              </div>
              <div v-else class="columns">
                <div class="column">
                  <b-field label="Tipo de documento">
                    <b-input
                      :value="
                        request.resolution_type !== undefined &&
                        request.resolution_type !== null
                          ? request.resolution_type.name
                          : ''
                      "
                      type="text"
                      disabled="true"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Número de documento">
                    <b-input
                      :value="request.resolution"
                      type="text"
                      disabled="true"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Fecha de documento">
                    <b-input
                      :value="request.resolution_date"
                      type="text"
                      disabled="true"
                    ></b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Fecha inicio">
                    <b-input
                      :value="request.start_date"
                      type="text"
                      disabled="true"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Fecha fin">
                    <b-input
                      :value="request.end_date"
                      type="text"
                      disabled="true"
                    ></b-input>
                  </b-field>
                </div>
              </div>
              <div
                v-if="
                  request.person_units !== undefined &&
                  request.can_update_resolution
                "
                class="columns"
              >
                <div class="column">
                  <b-field label="Url carpeta Resolución">
                    <b-input
                      expanded
                      v-model="resolution_data.documentation_link"
                      type="text"
                      maxlength="255"
                      :has-counter="false"
                    ></b-input>
                    <p class="control">
                      <b-button
                        tag="a"
                        :href="resolution_data.documentation_link"
                        target="_blank"
                        icon-left="external-link-alt"
                        type="is-link"
                        >Link</b-button
                      >
                    </p>
                  </b-field>
                </div>
              </div>
              <div v-else class="columns">
                <div class="column">
                  <b-field label="Url carpeta Resolución">
                    <b-input
                      expanded
                      :value="request.documentation_link"
                      type="text"
                      disabled="true"
                    ></b-input>
                    <p class="control">
                      <b-button
                        tag="a"
                        :href="request.documentation_link"
                        target="_blank"
                        icon-left="external-link-alt"
                        type="is-link"
                        >Link</b-button
                      >
                    </p>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <div class="has-text-right">
                    <b-button
                      type="is-primary"
                      icon-left="save"
                      v-if="request.can_update_resolution"
                      @click="updateResolution()"
                    >
                      Guardar Datos de Resolución
                    </b-button>
                  </div>
                </div>
              </div>

              <div>
                <div class="has-text-right">
                  <b-button
                    v-if="
                      (requestStateId == 5 || requestStateId == 4) &&
                      requestTypeId == 2 &&
                      cargoPrincipal.name != 'JEFE ADMINISTRATIVO FING'
                    "
                    type="is-primary"
                    icon-left="edit"
                    @click="activeModalMasive()"
                  >
                    Editar Datos de Nombramiento
                  </b-button>
                  <b-button
                    v-if="
                      (requestStateId == 1 || requestStateId == 6) &&
                      requestTypeId == 2 &&
                      cargoPrincipal.name == 'JEFE ADMINISTRATIVO FING'
                    "
                    type="is-primary"
                    icon-left="edit"
                    @click="activeModalMasive()"
                  >
                    Editar Datos de Nombramiento
                  </b-button>
                </div>
              </div>
              <h3 class="is-size-3">Nombramientos Asociados</h3>

              <div class="columns is-centered">
                <div class="column is-centered has-text-centered">
                  <b-message type="is-info" has-icon>
                    Importante: El icono de advertencia que aparece en el
                    listado de nombramiento, indica que la persona sobrepasa el
                    máximo de horas permitidos, considerando las horas de esta
                    solicitud. Para ver el detalle, hacer click sobre el icono
                    de advertencia.
                  </b-message>
                </div>
              </div>

              <div v-if="requestTypeId == 2" class="columns">
                <div class="column is-4 is-offset-8">
                  <b-switch @input="changeSwitch" v-model="warningOnly"
                    >Mostrar solo nombramientos con advertencias.</b-switch
                  >
                </div>
              </div>
              <!--              <div>
                  <div class="has-text-right">
                      <b-button v-if="(requestStateId == 5 || requestStateId == 4)&&requestTypeId== 2" type="is-info"
                                icon-left="plus"
                                @click="activeModalAddPerson()">
                          Añadir un nuevo profesor
                      </b-button>
                  </div>
              </div>-->
              <div class="columns">
                <div class="column">
                  <b-table
                    :data="filter_person_units"
                    :paginated="true"
                    per-page="20"
                    :current-page="currentPageAppointments"
                    :pagination-simple="false"
                    pagination-position="bottom"
                    default-sort-direction="asc"
                    sort-icon="arrow-up"
                    sort-icon-size="is-small"
                    default-sort="product.title"
                    aria-next-label="Siguiente"
                    aria-previous-label="Anterior"
                    aria-page-label="Página"
                    aria-current-label="Página actual"
                    scrollable
                    :sticky-header="false"
                    :opened-detailed="defaultOpenedDetailsAppointments"
                    detailed
                    detail-key="id"
                    detail-transition="fade"
                    detail-icon="chevron-right"
                    :show-detail-icon="showDetailIconAppointments"
                  >
                    <b-table-column
                      field="person_name"
                      label="Nombre"
                      sortable
                      searchable
                    >
                      <template slot="searchable" slot-scope="props">
                        <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small"
                        />
                      </template>
                      <template v-slot="props">
                        {{ props.row.person_name }}
                      </template>
                    </b-table-column>
                    <b-table-column
                      v-if="requestTypeId != 2"
                      field="resolution_date"
                      label="Fecha Documento"
                      width="80"
                      sortable
                      searchable
                    >
                      <template slot="searchable" slot-scope="props">
                        <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small"
                        />
                      </template>
                      <template v-slot="props">
                        {{
                          props.row.resolution_date._isValid
                            ? new Date(
                                props.row.resolution_date
                              ).toLocaleDateString("es-CL")
                            : ""
                        }}
                      </template>
                    </b-table-column>
                    <b-table-column
                      v-if="requestTypeId != 2"
                      field="resolution"
                      label="N° de documento"
                      width="80"
                      sortable
                      searchable
                    >
                      <template slot="searchable" slot-scope="props">
                        <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small"
                        />
                      </template>
                      <template v-slot="props">
                        {{ props.row.resolution }}
                      </template>
                    </b-table-column>
                    <b-table-column
                      v-if="requestTypeId != 2"
                      field="resolution_type.name"
                      label="Tipo documento"
                      sortable
                      searchable
                    >
                      <template slot="searchable" slot-scope="props">
                        <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small"
                        />
                      </template>
                      <template v-slot="props">
                        {{
                          props.row.resolution_type !== undefined &&
                          props.row.resolution_type != null
                            ? props.row.resolution_type.name
                            : ""
                        }}
                      </template>
                    </b-table-column>
                    <b-table-column
                      field="appointment_type.name"
                      label="Tipo Nombramiento"
                      sortable
                      searchable
                    >
                      <template slot="searchable" slot-scope="props">
                        <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small"
                        />
                      </template>
                      <template v-slot="props">
                        {{
                          props.row.appointment_type
                            ? props.row.appointment_type.name
                            : ""
                        }}
                      </template>
                    </b-table-column>

                    <b-table-column
                      v-if="requestTypeId != 2"
                      field="work_plant.name"
                      label="Cargo"
                      sortable
                      searchable
                    >
                      <template slot="searchable" slot-scope="props">
                        <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small"
                        />
                      </template>
                      <template v-slot="props">
                        {{
                          props.row.work_plant ? props.row.work_plant.name : ""
                        }}
                      </template>
                    </b-table-column>

                    <b-table-column
                      field="category_name"
                      label="Categoria"
                      sortable
                      searchable
                    >
                      <template slot="searchable" slot-scope="props">
                        <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small"
                        />
                      </template>
                      <template v-slot="props">
                        {{
                          props.row.category_name ? props.row.category_name : ""
                        }}
                      </template>
                    </b-table-column>

                    <b-table-column
                      field="unit.cost_center"
                      label="CC"
                      width="20"
                      sortable
                      searchable
                    >
                      <template slot="searchable" slot-scope="props">
                        <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small"
                        />
                      </template>
                      <template v-slot="props">
                        <b-tooltip
                          :label="props.row.unit.name"
                          multilined
                          position="is-left"
                        >
                          {{ props.row.unit ? props.row.unit.cost_center : "" }}
                        </b-tooltip>
                      </template>
                    </b-table-column>
                    <b-table-column
                      v-if="requestTypeId != 2"
                      field="start_date"
                      label="Fecha Desde"
                      width="110"
                      sortable
                      searchable
                    >
                      <template slot="searchable" slot-scope="props">
                        <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small"
                        />
                      </template>
                      <template v-slot="props">
                        {{
                          props.row.start_date._isValid
                            ? new Date(props.row.start_date).toLocaleDateString(
                                "es-CL"
                              )
                            : ""
                        }}
                      </template>
                    </b-table-column>
                    <b-table-column
                      v-if="requestTypeId != 2"
                      field="end_date"
                      label="Fecha Hasta"
                      width="110"
                      sortable
                      searchable
                    >
                      <template slot="searchable" slot-scope="props">
                        <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small"
                        />
                      </template>
                      <template v-slot="props">
                        {{
                          props.row.end_date._isValid
                            ? new Date(props.row.end_date).toLocaleDateString(
                                "es-CL"
                              )
                            : ""
                        }}
                      </template>
                    </b-table-column>
                    <b-table-column
                      v-if="requestTypeId != 2"
                      field="working_day"
                      label="Jornada /Horas"
                      width="110"
                      sortable
                      searchable
                    >
                      <template slot="searchable" slot-scope="props">
                        <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small"
                        />
                      </template>
                      <template v-slot="props">
                        <div v-if="props.row.establishment_id === 3">
                          {{
                            props.row.actual_hours ? props.row.actual_hours : ""
                          }}
                        </div>
                        <div v-else>
                          {{
                            props.row.working_day
                              ? props.row.working_day.hired_hours
                              : ""
                          }}
                        </div>
                      </template>
                    </b-table-column>
                    <b-table-column
                      v-if="requestTypeId == 2"
                      field="list_of_subjects"
                      label="Asignaturas"
                      searchable
                      sortable
                    >
                      <template slot="searchable" slot-scope="props">
                        <b-input
                          v-model="props.filters[props.column.field]"
                          placeholder=" Buscar"
                          icon="search"
                          size="is-small"
                        />
                      </template>
                      <template v-slot="props">
                        <ul>
                          <li v-for="item in props.row.list_of_subjects">
                            <span class="tag is-primary">
                              {{ item.name }}
                            </span>
                          </li>
                        </ul>
                      </template>
                    </b-table-column>
                    <b-table-column
                      v-if="requestTypeId == 2"
                      field="list_of_subjects"
                      label="Horas"
                    >
                      <template slot-scope="props">
                        <b-input
                          v-model="props.filters[props.column.field]"
                          placeholder=" Buscar"
                          icon="search"
                          size="is-small"
                        />
                      </template>
                      <template v-slot="props">
                        <ul>
                          <li v-for="item in props.row.list_of_subjects">
                            <span class="tag is-primary">
                              {{ item.hours }}
                            </span>
                          </li>
                        </ul>
                      </template>
                    </b-table-column>
                    <b-table-column
                      v-if="requestTypeId == 2"
                      field="list_of_subjects"
                      label="Código Siac"
                    >
                      <template slot-scope="props">
                        <b-input
                          v-model="props.filters[props.column.field]"
                          placeholder=" Buscar"
                          icon="search"
                          size="is-small"
                        />
                      </template>
                      <template v-slot="props">
                        <ul>
                          <li v-for="item in props.row.list_of_subjects">
                            <span class="tag is-primary">
                              {{ item.siac_code ? item.siac_code : "-" }}
                            </span>
                          </li>
                        </ul>
                      </template>
                    </b-table-column>
                    <b-table-column label="Acciones" width="130" v-slot="props">
                      <b-icon
                        v-if="props.row.can_show"
                        pack="fas"
                        icon="eye"
                        size="is-medium"
                        @click.native="execShowModalAppointment(props.row)"
                      />
                      <b-icon
                        v-if="
                          props.row.can_edit_request &&
                          cargoPrincipal.name != 'JEFE ADMINISTRATIVO FING'
                        "
                        pack="fas"
                        icon="edit"
                        size="is-medium"
                        @click.native="execEditModalAppointment(props.row)"
                      />
                      <b-icon
                        v-if="props.row.can_edit_request && requestTypeId == 2"
                        pack="fas"
                        icon="trash"
                        size="is-medium"
                        @click.native="deletePersonUnitConfirm(props.row)"
                      />
                      <b-icon
                        v-if="
                          cargoPrincipal.name == 'JEFE ADMINISTRATIVO FING' &&
                          (requestStateId == 1 || requestStateId == 6)
                        "
                        pack="fas"
                        icon="edit"
                        size="is-medium"
                        @click.native="execEditModalAppointment(props.row)"
                      />
                      <b-icon
                        v-if="
                          cargoPrincipal.name == 'JEFE ADMINISTRATIVO FING' &&
                          requestTypeId == 2 &&
                          (requestStateId == 1 || requestStateId == 6)
                        "
                        pack="fas"
                        icon="trash"
                        size="is-medium"
                        @click.native="deletePersonUnitConfirm(props.row)"
                      />
                      <b-tooltip
                        v-if="
                          props.row.horas_totales > 16 &&
                          16 -
                            (props.row.horas_totales -
                              props.row.actual_hours) >=
                            0
                        "
                        :label="
                          'Advertencia. La persona tiene asignada más de 16 horas en este periodo de tiempo.\nsolo puede considerar ' +
                          (
                            16 -
                            (props.row.horas_totales - props.row.actual_hours)
                          ).toString() +
                          ' horas para esta solicitud.\nPara más detalle click acá'
                        "
                        position="is-left"
                        size="is-medium"
                        multilined
                      >
                        <b-icon
                          pack="fas"
                          icon="exclamation-circle"
                          size="is-medium"
                          type="is-warning"
                          @click.native="showWarningPersonUnits(props.row)"
                        >
                        </b-icon>
                      </b-tooltip>
                      <b-tooltip
                        v-if="
                          props.row.horas_totales > 16 &&
                          16 -
                            (props.row.horas_totales - props.row.actual_hours) <
                            0
                        "
                        :label="
                          'Advertencia. La persona tiene asignada más de 16 horas en este periodo de tiempo.\nActualmente tiene ' +
                          props.row.horas_totales.toString() +
                          ' horas.\nPara más detalle click acá'
                        "
                        position="is-left"
                        size="is-medium"
                        multilined
                      >
                        <b-icon
                          pack="fas"
                          icon="exclamation-circle"
                          size="is-medium"
                          type="is-warning"
                          @click.native="showWarningPersonUnits(props.row)"
                        >
                        </b-icon>
                      </b-tooltip>
                    </b-table-column>
                    <template #detail="props">
                      <article
                        class="media"
                        v-if="props.row.establishment_id === 3"
                      >
                        <div class="media-content">
                          <div class="content">
                            <div class="columns">
                              <div class="column">
                                <strong>Asignaturas: </strong>
                              </div>
                            </div>
                            <b-taglist>
                              <b-tag
                                type="is-primary"
                                v-for="(subject, index) of props.row
                                  .subjects_and_hours"
                                :key="index"
                                >{{ subject }}</b-tag
                              >
                            </b-taglist>
                          </div>
                        </div>
                      </article>
                    </template>
                  </b-table>
                </div>
              </div>
              <b-menu-list>
                <b-menu-item>
                  <template slot="label" slot-scope="props">
                    <b-icon
                      class="is-pulled-right"
                      :icon="props.expanded ? 'sort-down' : 'sort-up'"
                    ></b-icon>
                    <h3 class="is-size-3">Historial de Solicitud</h3>
                  </template>
                  <div class="columns">
                    <div class="column">
                      <b-table
                        :data="request.request_state_histories"
                        :paginated="true"
                        per-page="20"
                        :current-page="currentPageHistories"
                        :pagination-simple="false"
                        pagination-position="bottom"
                        default-sort-direction="asc"
                        sort-icon="arrow-up"
                        sort-icon-size="is-small"
                        default-sort="product.title"
                        aria-next-label="Siguiente"
                        aria-previous-label="Anterior"
                        aria-page-label="Página"
                        aria-current-label="Página actual"
                        scrollable
                        :sticky-header="false"
                        :opened-detailed="defaultOpenedDetails"
                        detailed
                        detail-key="id"
                        detail-transition="fade"
                        detail-icon="chevron-right"
                        :show-detail-icon="showDetailIcon"
                      >
                        <b-table-column
                          field="date"
                          label="Fecha"
                          width="110"
                          sortable
                          searchable
                        >
                          <template slot="searchable" slot-scope="props">
                            <b-input
                              v-model="props.filters[props.column.field]"
                              icon="search"
                              size="is-small"
                            />
                          </template>
                          <template v-slot="props">
                            {{ props.row.formatted_date }}
                          </template>
                        </b-table-column>

                        <b-table-column
                          field="persor_name"
                          label="Nombre"
                          sortable
                          searchable
                        >
                          <template slot="searchable" slot-scope="props">
                            <b-input
                              v-model="props.filters[props.column.field]"
                              icon="search"
                              size="is-small"
                            />
                          </template>
                          <template v-slot="props">
                            {{ props.row.person_name }}
                          </template>
                        </b-table-column>
                        <b-table-column
                          field="request_state.name"
                          label="Estado"
                          sortable
                          searchable
                        >
                          <template slot="searchable" slot-scope="props">
                            <b-input
                              v-model="props.filters[props.column.field]"
                              icon="search"
                              size="is-small"
                            />
                          </template>
                          <template v-slot="props">
                            <b-tag :type="props.row.type" size="is-large">{{
                              props.row.request_state !== undefined
                                ? props.row.request_state.name
                                : ""
                            }}</b-tag>
                          </template>
                        </b-table-column>
                        <b-table-column
                          field="internal_position.name"
                          label="Rol"
                          sortable
                          searchable
                        >
                          <template slot="searchable" slot-scope="props">
                            <b-input
                              v-model="props.filters[props.column.field]"
                              icon="search"
                              size="is-small"
                            />
                          </template>
                          <template v-slot="props">
                            {{
                              props.row.internal_position
                                ? props.row.internal_position.name
                                : ""
                            }}
                          </template>
                        </b-table-column>

                        <b-table-column
                          field="action"
                          label="Acción"
                          sortable
                          searchable
                        >
                          <template slot="searchable" slot-scope="props">
                            <b-input
                              v-model="props.filters[props.column.field]"
                              icon="search"
                              size="is-small"
                            />
                          </template>
                          <template v-slot="props">
                            {{ props.row.action }}
                          </template>
                        </b-table-column>
                        <b-table-column
                          label="Acciones"
                          width="40"
                          v-slot="props"
                        >
                          <b-icon
                            v-if="props.row.can_show"
                            pack="fas"
                            icon="eye"
                            size="is-medium"
                            @click.native="execShowModalHistory(props.row.id)"
                          />
                          <b-icon
                            v-if="props.row.can_edit"
                            pack="fas"
                            icon="edit"
                            size="is-medium"
                            @click.native="execEditModalHistory(props.row.id)"
                          />
                        </b-table-column>

                        <template #detail="props">
                          <article class="media">
                            <div class="media-content">
                              <div class="content">
                                <div class="columns">
                                  <div class="column">
                                    <strong>Comentario: </strong>
                                  </div>
                                </div>
                                <p>{{ props.row.comment }}</p>
                              </div>
                            </div>
                          </article>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </b-menu-item>
              </b-menu-list>
            </div>
          </div>
        </div>
      </div>
      <div class="columns has-text-right">
        <div class="control has-text-right">
          <b-button
            type="is-success"
            icon-left="check"
            v-if="request.can_finish"
            @click="changeStateConfirm('finish')"
          >
            Finalizar Solicitud
          </b-button>
          <!--          <b-button v-if="request.can_restart" type="is-primary"
                    icon-left="play"
                    @click="changeState('restart')">
            Reanudar Solicitud
          </b-button>-->
          <b-button
            v-if="request.can_stop"
            type="is-warning"
            icon-left="stop"
            @click="changeState('stop')"
          >
            Ajustar Solicitud
          </b-button>
          <b-button
            v-if="request.can_reject"
            type="is-danger"
            icon-left="times"
            @click="changeState('reject')"
          >
            Rechazar Solicitud
          </b-button>
          <b-button
            v-if="
              cargoPrincipal.name.includes('JEFE ADMINISTRATIVO DEPTO') &&
              requestStateId == 4
            "
            type="is-danger"
            icon-left="times"
            @click="changeState('reject')"
          >
            Descartar Solicitud
          </b-button>
        </div>
      </div>
      <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="false"
      ></b-loading>

      <!-- MODALES -->
      <b-modal
        v-model="isShowHistoryModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        width="1024"
      >
        <template #default="props">
          <request_state_history_show
            :id="selected_history"
            :modal-header="'Historial'"
            @close="props.close"
          >
          </request_state_history_show>
        </template>
      </b-modal>

      <b-modal
        v-model="isShowAppointmentModalActive"
        has-modal-card
        trap-focus
        width="1024"
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
      >
        <template #default="props">
          <component
            :is="showName"
            :id="selected_appointment"
            :person-id="personId"
            :modal-header="title"
            :person_name="person_name"
            @close="props.close"
          />
        </template>
      </b-modal>

      <b-modal
        v-model="isEditAppointmentModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        width="1024"
        :can-cancel="canCancel"
      >
        <template #default="props">
          <component
            :is="formName"
            :person-id="personId"
            :id="selected_appointment"
            query-type="update-request"
            :state_id="request.request_state.id"
            :modal-header="title"
            :person_name="person_name"
            :request_type_id="request.request_type.id"
            :editMassive="editMassive"
            :actual_hours="actual_hour"
            @close="props.close"
          />
        </template>
      </b-modal>

      <b-modal
        v-model="isModalUpdateState"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        width="1024"
        :can-cancel="canCancel"
      >
        <template #default="props">
          <modal-update-state
            :person-id="personId"
            :id="request.id"
            :query-type="change_state"
            modal-header="Modificar Estado de Solicitud"
            @close="props.close"
          />
        </template>
      </b-modal>
      <b-modal
        v-model="isModalSemesterYear"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        width="1024"
        :can-cancel="canCancel"
      >
        <template #default="props">
          <modal-semester-year
            @close="props.close"
            @clicked="onClickChild"
          ></modal-semester-year>
        </template>
      </b-modal>
      <b-modal
        v-model="isModalWarning"
        class="modal-custom"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel"
        :width="1300"
      >
        <template #default="props">
          <Modal_warning_person_units
            @close="props.close"
            :person_units_warning="person_units_warning"
            :request_id="requestId"
            :modal-header="person_name"
          ></Modal_warning_person_units>
        </template>
      </b-modal>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import RequestStateHistoryShow from "../requests/request_state_history_show";
import { formatDate } from "../../packs/utilities";
import Request_state_history_show from "./request_state_history_show";
import AppointmentHours from "../life_record/appointment_hours";
import AppointmentAcademic from "../life_record/appointment_academic";
import AppointmentAdministrative from "../life_record/appointment_administrative";
import AppointmentFeeContract from "../life_record/appointment_feecontract";
import AppointmentHoursForm from "../life_record/modal_hour_appointment_form";
import AppointmentAcademicForm from "../life_record/modal_academic_appointment_form";
import AppointmentAdministrativeForm from "../life_record/modal_administrative_appointment_form";
import ModalUpdateState from "../requests/modal_update_state";
import Modal_warning_person_units from "./modal_warning_person_units.vue";
import { TemplateHandler } from "easy-template-x";
import { saveAs } from "file-saver";
const JSZip = require("jszip");
const fs = require("fs");
import requests from "./requests.vue";
import ModalSemesterYear from "../requests/modal_semester_year";

export default {
  name: "request_show",
  components: {
    Request_state_history_show,
    AppointmentHours,
    AppointmentAcademic,
    AppointmentAdministrative,
    AppointmentHoursForm,
    AppointmentAcademicForm,
    AppointmentAdministrativeForm,
    ModalUpdateState,
    ModalSemesterYear,
    Modal_warning_person_units,
  },
  data() {
    return {
      isLoading: false,
      canCancel: ["escape"],
      request: {},
      tags: [],
      isLoadingMultiselect: false,
      currentPage: 1,
      filtered_tags: [],
      isNullifyActive: false,
      currentPageAppointments: 1,
      currentPageHistories: 0,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      defaultOpenedDetailsAppointments: [1],
      showDetailIconAppointments: false,
      isShowHistoryModalActive: false,
      selected_history: null,
      isShowAppointmentModalActive: false,
      isEditAppointmentModalActive: false,
      showName: "",
      title: "",
      selected_appointment: null,
      personId: null,
      person_name: "",
      formName: "",
      isModalUpdateState: false,
      change_state: "",
      semester: null,
      anio: null,
      isModalSemesterYear: false,
      format: null,
      resolution_data: {
        resolution: null,
        resolution_date: null,
        resolution_type_id: null,
        documentation_link: null,
      },
      editMassive: false,
      isModalAddPerson: false,
      requestStateId: null,
      requestTypeId: null,
      actual_hour: null,
      warningOnly: false,
      filter_person_units: [],
      isModalWarning: false,
      person_units_warning: null,
    };
  },
  props: [
    "userId",
    "requestId",
    "can_edit",
    "can_comment",
    "unit_name",
    "iniciales",
    "cargoPrincipal",
  ],
  created() {
    this.initialize();
  },
  methods: {
    execEditModalHistory() {},
    execShowModalHistory(id) {
      this.selected_history = id;
      this.isShowHistoryModalActive = true;
    },
    getEstablishment(selected) {
      return this.request.person_units.find((o) => o.id === selected);
    },
    execShowModalAppointment(pu) {
      this.selected_appointment = pu.id;
      this.person_name = pu.person_name;
      let person_unit = this.getEstablishment(pu.id);
      this.personId = person_unit.person_id;
      if (person_unit.establishment !== undefined) {
        if (person_unit.establishment.name === "ACADEMICOS") {
          this.showName = "AppointmentAcademic";
          this.title =
            "Solicitud Estamento Académico" + " - " + this.person_name;
        } else if (person_unit.establishment.name === "ADMINISTRATIVOS") {
          this.showName = "AppointmentAdministrative";
          this.title =
            "Solicitud Estamento Administrativo" + " - " + this.person_name;
        } else if (person_unit.establishment.name === "HONORARIOS") {
          this.showName = "AppointmentFeeContract";
          this.title =
            "Solicitud Estamento Honorarios" + " - " + this.person_name;
        } else {
          this.showName = "AppointmentHours";
          this.title =
            "Solicitud Estamento Profesor Horas Clases" +
            " - " +
            this.person_name;
        }
      } else {
        this.showName = "AppointmentAdministrative";
      }
      //console.log("APPOINTMENT "+ this.showName)
      this.isShowAppointmentModalActive = true;
    },
    execEditModalAppointment(pu) {
      this.actual_hour = null;
      this.editMassive = false;
      this.selected_appointment = pu.id;
      this.person_name = pu.person_name;
      let person_unit = this.getEstablishment(pu.id);
      if (person_unit.establishment !== undefined) {
        if (person_unit.establishment.name === "ACADEMICOS") {
          this.formName = "AppointmentAcademicForm";
          this.title =
            "Editar Solicitud Nombramiento Estamento Académico" +
            " - " +
            this.person_name;
        } else if (person_unit.establishment.name === "ADMINISTRATIVOS") {
          this.formName = "AppointmentAdministrativeForm";
          this.title =
            "Editar Solicitud Nombramiento Estamento Administrativo" +
            " - " +
            this.person_name;
        } else if (person_unit.establishment.name === "HONORARIOS") {
          this.formName = "AppointmentFeeContractForm";
          this.title =
            "Editar Solicitud Nombramiento Estamento Honorarios" +
            " - " +
            this.person_name;
        } else {
          this.formName = "AppointmentHoursForm";
          this.title =
            "Editar Solicitud Nombramiento Estamento Profesor Horas Clase" +
            " - " +
            this.person_name;
        }
      } else {
        this.formName = "AppointmentAdministrativeForm";
      }
      //console.log("APPOINTMENT "+ this.showName)
      this.actual_hour = pu.actual_hours;
      this.isEditAppointmentModalActive = true;
    },
    commentRequest() {},
    goToEdit() {
      window.location.href = "/requests/" + this.requestId + "/edit";
    },
    promptRequest() {
      this.isLoading = true;
      this.$buefy.dialog.confirm({
        title: "Confirmar Solicitud",
        message:
          "¿Está seguro que desea <b>enviar</b> la solicitud?. Esta acción no puede deshacerse.",
        confirmText: "Enviar Solicitud",
        type: "is-warning",
        hasIcon: true,
        onConfirm: () => this.sendRequest(),
      });
      this.isLoading = false;
    },
    promptRequestCorrect() {
      this.isLoading = true;

      var size_warning = this.request.person_units.filter(
        (person_unit) => person_unit.horas_totales > 16
      ).length;

      if (size_warning > 0) {
        this.$buefy.dialog.confirm({
          title: "¿Enviar solicitud corregida?",
          message:
            "Existen nombramientos con advertencias, ¿Desea <b>enviar</b> la solicitud corregida de todas formas?. Esta acción no puede deshacerse.",
          confirmText: "Enviar Solicitud corregida",
          type: "is-warning",
          hasIcon: true,
          onConfirm: () => this.sendRequest(),
        });
        this.isLoading = false;
      } else {
        this.$buefy.dialog.confirm({
          title: "Confirmar Solicitud Corregida",
          message:
            "¿Está seguro que desea <b>enviar</b> la solicitud corregida?. Esta acción no puede deshacerse.",
          confirmText: "Enviar Solicitud corregida",
          type: "is-warning",
          hasIcon: true,
          onConfirm: () => this.sendRequest(),
        });
        this.isLoading = false;
      }
    },
    sendRequest() {
      this.isLoading = true;
      axios
        .put("/requests/" + this.request.id + "/send-request.json")
        .then((res) => {
          this.$buefy.dialog.alert({
            title: "Envío de Solicitud",
            message: "Solicitud enviada correctamente",
            confirmText: "Ok",
            type: "is-success",
            hasIcon: true,
          });
          this.initialize();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$buefy.dialog.alert({
            title: "Error",
            message: "Hubo un error al enviar la solicitud, intente nuevamente",
            confirmText: "Ok",
            type: "is-danger",
            hasIcon: true,
          });
          this.isLoading = false;
        });
    },
    formatDate,
    formatDateRe(date) {
      if (date != null && date !== "" && date !== undefined) {
        return date.replace(/-/g, "/");
      } else {
        return "";
      }
    },
    initialize() {
      this.isLoading = true;
      axios
        .get("/requests/" + this.requestId + ".json")
        .then((res) => {
          this.request = res.data;
          if (this.request.resolution_date != null) {
            this.resolution_data.resolution_date = new Date(
              this.formatDateRe(res.data["resolution_date"])
            );
          }
          this.request.start_date = this.formatDate(this.request.start_date);
          this.request.end_date = this.formatDate(this.request.end_date);
          this.request.resolution_date = this.formatDate(
            this.request.resolution_date
          );
          this.request.person_units = this.request.person_units.map(
            (personUnit) => {
              var newPersonUnit = personUnit;
              newPersonUnit["resolution_date"] = moment(
                newPersonUnit["resolution_date"],
                ["YYYY-MM-DD", "DD-MM-YYYY"]
              );
              newPersonUnit["start_date"] = moment(
                newPersonUnit["start_date"],
                ["YYYY-MM-DD", "DD-MM-YYYY"]
              );
              newPersonUnit["end_date"] = moment(newPersonUnit["end_date"], [
                "YYYY-MM-DD",
                "DD-MM-YYYY",
              ]);
              return newPersonUnit;
            }
          );
          this.resolution_data.resolution = this.request.resolution;

          if (
            this.request.resolution_type !== undefined &&
            this.request.resolution_type !== null &&
            this.request.resolution_type.name != ""
          ) {
            this.resolution_data.resolution_type_id =
              this.request.resolution_type.id;
          }
          if (
            this.request.documentation_link !== undefined &&
            this.request.documentation_link !== null &&
            this.request.documentation_link != ""
          ) {
            this.resolution_data.documentation_link =
              this.request.documentation_link;
          }
          this.requestTypeId = this.request.request_type.id;
          this.requestStateId = this.request.request_state.id;
          this.warningOnly = false;
          this.filter_person_units = this.request.person_units;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    changeStateConfirm(type) {
      var size_warnings = this.request.person_units.filter(
        (person_unit) => person_unit.horas_totales > 16
      ).length;

      if (size_warnings > 0) {
        this.$buefy.dialog.confirm({
          title: "Finalizar Solicitud",
          message:
            "Precaución, se finalizará la solicitud con advertencias, ¿Desea continuar?",
          cancelText: "Cancelar",
          confirmText: "Finalizar",
          type: "is-warning",
          hasIcon: true,
          onConfirm: () => this.changeState(type),
        });
      } else if (
        this.request.resolution == null ||
        this.request.resolution_type == null ||
        this.request.resolution_date == null
      ) {
        var tipo_request = "";
        if (
          this.request.request_type != null &&
          this.request.request_type != undefined
        ) {
          tipo_request = this.request.request_type.name.toLowerCase();
          if (tipo_request == "continuidad") {
            tipo_request = "masiva";
          }
        }
        this.$buefy.dialog.confirm({
          title: "Finalizar Solicitud",
          message:
            "Precaución, se finalizará la solicitud " +
            tipo_request +
            " sin especificar los datos de resolución, ¿Desea continuar?",
          cancelText: "Cancelar",
          confirmText: "Finalizar",
          type: "is-warning",
          hasIcon: true,
          onConfirm: () => this.changeState(type),
        });
      } else {
        this.changeState(type);
      }
    },
    changeState(type) {
      this.isLoading = true;
      this.change_state = type;
      this.isModalUpdateState = true;
      this.isLoading = false;
    },
    async downloadWordResolution() {
      this.isLoading = true;
      //Se carga la plantilla Word
      const response = await fetch("/template-resolucion.docx");
      const templateFile = await response.blob();

      //Manejo de datos
      var datos = [];
      var cc = this.request.unit.cost_center;
      var start_date = new Date(this.request.person_units[0].start_date)
        .toLocaleDateString("es-CL")
        .split("-");
      var end_date = new Date(this.request.person_units[0].end_date)
        .toLocaleDateString("es-CL")
        .split("-");

      const meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Setiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

      var fecha_inicio =
        start_date[0] +
        " de " +
        meses[parseInt(start_date[1]) - 1] +
        " del " +
        start_date[2];
      var fecha_termino =
        end_date[0] +
        " de " +
        meses[parseInt(end_date[1]) - 1] +
        " del " +
        end_date[2];

      if (this.request.person_units[0].semester == 1) {
        this.semester = "PRIMER";
      } else if (this.request.person_units[0].semester == 2) {
        this.semester = "SEGUNDO";
      } else {
        this.semester = "X";
      }

      var distribucion = [];
      distribucion.push({
        tipo_distribucion: "1. Secretaría General",
      });
      distribucion.push({
        tipo_distribucion: "2. Dirección de Pregrado",
      });
      distribucion.push({
        tipo_distribucion: "3. Decano Facultad de Ingeniería",
      });
      distribucion.push({
        tipo_distribucion: "4. " + this.unit_name,
      });
      distribucion.push({
        tipo_distribucion: "5. Archivo Central",
      });

      var solicitud = this.request;
      this.request.person_units.forEach((person_unit) => {
        person_unit.list_of_subjects.forEach((subject) => {
          var code = null;
          datos.push({
            //Nombres tienen que ser de la persona en la solicitud, Vereficar
            run: person_unit.rut,
            dv: person_unit.dv,
            primer_apellido: person_unit.last_name,
            segundo_apellido: person_unit.second_surname,
            nombres: person_unit.name,
            horas: subject.hours,
            asignatura: subject.name,
            codigo: subject.siac_code,
            categoria: person_unit.category_name,
            monto: null,
            tipo_ingreso: person_unit.appointment_type.name,
          });
        });
      });
      //Se procesan los datos de la plantilla
      const data = {
        año: this.request.year,
        semestre: this.semester,
        fecha_inicio: fecha_inicio,
        fecha_termino: fecha_termino,
        cc: cc,
        datos: datos,
        iniciales: this.iniciales,
        distribucion: distribucion,
      };

      //Se procesa el documento para generar el archivo
      const handler = new TemplateHandler();
      const doc = await handler.process(templateFile, data);
      var name_file = "";
      if (this.request.request_type.id == 2) {
        name_file =
          "TRANSCRIPCIÓN_PHC_" +
          this.request.person_units[0].appointment_type.name.replace(" ", "_") +
          "_MASIVA_" +
          cc.toString() +
          ".docx";
      } else {
        name_file =
          "TRANSCRIPCIÓN_PHC_" +
          this.request.person_units[0].appointment_type.name.replace(" ", "_") +
          "_" +
          solicitud.person_units[0].name.replace(" ", "_") +
          "_" +
          solicitud.person_units[0].last_name +
          "_" +
          cc.toString() +
          ".docx";
      }

      saveAs(doc, name_file);
      this.isLoading = false;
    },
    async downloadWordResolutionCaratula() {
      this.isLoading = true;
      //Se carga la plantilla Word
      const response = await fetch("/template-resolucion-caratula.docx");
      const templateFile = await response.blob();

      //Manejo de datos
      var datos = [];
      var cc = this.request.unit.cost_center;
      var start_date = new Date(this.request.person_units[0].start_date)
        .toLocaleDateString("es-CL")
        .split("-");
      var end_date = new Date(this.request.person_units[0].end_date)
        .toLocaleDateString("es-CL")
        .split("-");

      const meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Setiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

      var fecha_inicio =
        start_date[0] +
        " de " +
        meses[parseInt(start_date[1]) - 1] +
        " del " +
        start_date[2];
      var fecha_termino =
        end_date[0] +
        " de " +
        meses[parseInt(end_date[1]) - 1] +
        " del " +
        end_date[2];

      if (this.request.person_units[0].semester == 1) {
        this.semester = "PRIMER";
      } else if (this.request.person_units[0].semester == 2) {
        this.semester = "SEGUNDO";
      } else {
        this.semester = "X";
      }

      var solicitud = this.request;
      this.request.person_units.forEach((person_unit) => {
        person_unit.list_of_subjects.forEach((subject) => {
          var code = null;
          datos.push({
            //Nombres tienen que ser de la persona en la solicitud, Vereficar
            run: person_unit.rut,
            dv: person_unit.dv,
            primer_apellido: person_unit.last_name,
            segundo_apellido: person_unit.second_surname,
            nombres: person_unit.name,
            horas: subject.hours,
            asignatura: subject.name,
            codigo: subject.siac_code,
            categoria: person_unit.category_name,
            monto: null,
            tipo_ingreso: person_unit.appointment_type.name,
          });
        });
      });

      //Se procesan los datos de la plantilla
      const data = {
        año: this.request.year,
        semestre: this.semester,
        fecha_inicio: fecha_inicio,
        fecha_termino: fecha_termino,
        cc: cc,
        datos: datos,
      };

      //Se procesa el documento para generar el archivo
      const handler = new TemplateHandler();
      const doc = await handler.process(templateFile, data);
      var name_file = "";
      if (this.request.request_type.id == 2) {
        name_file =
          "CARATULA_PHC_" +
          this.request.person_units[0].appointment_type.name.replace(" ", "_") +
          "_MASIVA_" +
          cc.toString() +
          ".docx";
      } else {
        name_file =
          "CARATULA_PHC_" +
          this.request.person_units[0].appointment_type.name.replace(" ", "_") +
          "_" +
          solicitud.person_units[0].name.replace(" ", "_") +
          "_" +
          solicitud.person_units[0].last_name +
          "_" +
          cc.toString() +
          ".docx";
      }

      saveAs(doc, name_file);
      this.isLoading = false;
    },
    async massiveDownloadPaf() {
      this.isLoading = true;
      var cc = this.request.unit.cost_center;
      var departamento = this.request.unit.name;
      var start_date = new Date(this.request.person_units[0].start_date)
        .toLocaleDateString("es-CL")
        .split("-");
      var end_date = new Date(this.request.person_units[0].end_date)
        .toLocaleDateString("es-CL")
        .split("-");
      const zip = new JSZip();
      if (this.request.person_units.length > 1) {
        for (const person_unit of this.request.person_units) {
          await this.downloadWordPaf(
            zip,
            person_unit,
            start_date,
            end_date,
            departamento,
            cc
          );
        }
        zip
          .generateAsync({ type: "blob" })
          .then((blob) => {
            // Guardar el archivo ZIP en el sistema de archivos
            saveAs(blob, "PAF-MASIVA.zip");
            this.isLoading = false;
            console.log("Archivos comprimidos en archivos.zip");
          })
          .catch((error) => {
            this.isLoading = false;
            console.error("Error al generar el archivo ZIP:", error);
          });
      } else {
        this.individualDownloadWordPaf(
          this.request.person_units[0],
          start_date,
          end_date,
          departamento,
          cc
        );
      }
    },
    async downloadWordPaf(
      zip,
      person_unit,
      start_date,
      end_date,
      departamento,
      cc
    ) {
      //Se carga la plantilla Word
      //this.isLoading = true
      const response = await fetch("/template-paf-phc.docx");
      const templateFile = await response.blob();

      //Manejo de datos
      var datos = [];
      var fecha_actual = person_unit.fecha_actual;
      var primer_apellido = person_unit.last_name;
      var segundo_apellido = person_unit.second_surname;
      var nombres = person_unit.name;
      var rut = person_unit.rut.toString() + "-" + person_unit.dv;
      var lugar_nacimiento = person_unit.lugar_nacimiento;
      var fecha_nacimiento = person_unit.fecha_nacimiento;
      var dd = null;
      var mm = null;
      var aaaa = null;
      var d_n = null;
      var m_n = null;
      var a_n = null;
      if (
        fecha_nacimiento != null &&
        fecha_nacimiento != undefined &&
        fecha_nacimiento != ""
      ) {
        d_n = fecha_nacimiento.split("-")[0];
        m_n = fecha_nacimiento.split("-")[1];
        a_n = fecha_nacimiento.split("-")[2];
      }
      if (
        fecha_actual != null &&
        fecha_actual != undefined &&
        fecha_actual != ""
      ) {
        dd = fecha_actual.split("-")[0];
        mm = fecha_actual.split("-")[1];
        aaaa = fecha_actual.split("-")[2];
      }
      var genero = person_unit.genero;
      var h = null;
      var m = null;
      if (genero === "HOMBRE") {
        h = "X";
      } else if (genero === "MUJER") {
        m = "X";
      }
      var nacionalidad = person_unit.nacionalidad;
      var estado_civil = person_unit.estado_civil;
      var correo_personal = person_unit.correo_personal;
      var correo_corporativo = person_unit.correo_corporativo;
      var direccion =
        person_unit.direccion + person_unit.comuna + person_unit.region;
      var grado = person_unit.licenciatura;
      var estudio_grado = null;
      var institucion_grado = null;
      var fecha_grado = null;
      var dig_1g = null;
      var dig_2g = null;

      if (grado != null && grado != undefined) {
        estudio_grado = grado.name;
        institucion_grado = grado.entity;
        if (grado.date != null && grado.date != undefined) {
          fecha_grado = new Date(grado.date).toLocaleDateString("es-CL");
        }
        if (grado.semester != null && grado.semester != undefined) {
          if (grado.semester < 10) {
            dig_1g = "0";
            dig_2g = grado.semester.toString();
          } else if (grado.semester >= 10) {
            var semestre_aux = grado.semester.toString();
            dig_1g = semestre_aux[0];
            dig_2g = semestre_aux[1];
          }
        }
      }
      var profesion = person_unit.last_profession;
      var estudio_uni = null;
      var institucion_uni = null;
      var fecha_uni = null;
      var dig_1u = null;
      var dig_2u = null;

      if (profesion != null && profesion != undefined) {
        estudio_uni = profesion.name;
        institucion_uni = profesion.entity;
        fecha_uni = new Date(profesion.date).toLocaleDateString("es-CL");
        if (profesion.semester != null && profesion.semester != undefined) {
          if (profesion.semester < 10) {
            dig_1u = "0";
            dig_2u = profesion.semester.toString();
          } else if (profesion.semester >= 10) {
            var semestre_aux = profesion.semester.toString();
            dig_1u = semestre_aux[0];
            dig_2u = semestre_aux[1];
          }
        }
      }
      var todos_grados = person_unit.all_grades;
      var nombre_first = null;
      var institucion_first = null;
      var fecha_first = null;
      var grados = [];
      var magisters = person_unit.magisters;
      var doctorados = person_unit.doctorados;
      var dig_1o = null;
      var dig_2o = null;

      if (magisters != null && magisters != undefined) {
        magisters.forEach((grado) => {
          if (nombre_first == null) {
            nombre_first = grado.name;
            institucion_first = grado.entity;
            fecha_first =
              grado.date != null && grado.date != undefined
                ? new Date(grado.date).toLocaleDateString("es-CL")
                : null;
          } else {
            grados.push({
              nombre: grado.name,
              institucion: grado.entity,
              fecha:
                grado.date != null && grado.date != undefined
                  ? new Date(grado.date).toLocaleDateString("es-CL")
                  : null,
            });
          }

          if (grado.semester != null && grado.semester != undefined) {
            if (grado.semester < 10) {
              dig_1o = "0";
              dig_2o = grado.semester.toString();
            } else if (grado.semester >= 10) {
              var semestre_aux = grado.semester.toString();
              dig_1o = semestre_aux[0];
              dig_2o = semestre_aux[1];
            }
          }
        });
      }

      if (doctorados != null && doctorados != undefined) {
        doctorados.forEach((grado) => {
          grados.push({
            nombre: grado.name,
            institucion: grado.entity,
            fecha:
              grado.date != null && grado.date != undefined
                ? new Date(grado.date).toLocaleDateString("es-CL")
                : null,
          });
          if (grado.semester != null && grado.semester != undefined) {
            if (grado.semester < 10) {
              dig_1o = "0";
              dig_2o = grado.semester.toString();
            } else if (grado.semester >= 10) {
              var semestre_aux = grado.semester.toString();
              dig_1o = semestre_aux[0];
              dig_2o = semestre_aux[1];
            }
          }
        });
      }

      var planta = person_unit.work_plant.name;
      var cargo = null;
      var nivel = null;
      var g_1 = null;
      var g_2 = null;
      var r_1 = null;
      var r_2 = null;

      var d_i = start_date[0];
      var m_i = start_date[1];
      var a_i = start_date[2];
      var d_f = end_date[0];
      var m_f = end_date[1];
      var a_f = end_date[2];

      var asignaturas = person_unit.list_of_subjects;
      var ramo_first = null;
      var r_h = null;
      var ramo_categoria_first = null;
      var ramos = [];
      var siac_code = "";
      asignaturas.forEach((asignatura) => {
        if (ramo_first == null) {
          if (asignatura.siac_code != null && asignatura.siac_code != "") {
            siac_code = "-" + asignatura.siac_code;
          } else {
            siac_code = "";
          }
          ramo_first = asignatura.name + siac_code;
          r_h = asignatura.hours;
          ramo_categoria_first = person_unit.category_name;
        } else {
          if (asignatura.siac_code != null && asignatura.siac_code != "") {
            siac_code = "-" + asignatura.siac_code;
          } else {
            siac_code = "";
          }
          ramos.push({
            nombre: asignatura.name + siac_code,
            h: asignatura.hours,
            categoria: person_unit.category_name,
          });
        }
      });

      var f_s = null;
      var f_n = null;

      if (person_unit.rinde_fianza) {
        f_s = "X";
      } else {
        f_n = "X";
      }
      var j_s = null;
      var j_n = null;

      if (person_unit.jubilado) {
        j_s = "X";
      } else {
        j_n = "X";
      }

      var planta_o = null;
      var cargo_o = null;
      var go_1 = null;
      var go_2 = null;
      var calidad_o = null;
      var no_1 = null;
      var no_2 = null;

      if (
        person_unit.nomb_academico != null &&
        person_unit.nomb_academico != undefined
      ) {
        planta_o = person_unit.nomb_academico.planta;
        cargo_o = person_unit.nomb_academico.cargo;
        if (
          person_unit.nomb_academico.grado != null &&
          person_unit.nomb_academico.grado != undefined
        ) {
          if (person_unit.nomb_academico.grado < 10) {
            go_1 = "0";
            go_2 = person_unit.nomb_academico.grado.toString();
          } else if (person_unit.nomb_academico.grado >= 10) {
            go_1 = person_unit.nomb_academico.grado.toString()[0];
            go_2 = person_unit.nomb_academico.grado.toString()[1];
          }
        }
        calidad_o = person_unit.nomb_academico.calidad;
        no_2 = person_unit.nomb_academico.level;
      } else if (
        person_unit.nomb_admin != null &&
        person_unit.nomb_admin != undefined
      ) {
        planta_o = person_unit.nomb_admin.planta;
        cargo_o = person_unit.nomb_admin.cargo;
        if (
          person_unit.nomb_admin.grado != null &&
          person_unit.nomb_admin.grado != undefined
        ) {
          if (person_unit.nomb_admin.grado < 10) {
            go_1 = "0";
            go_2 = person_unit.nomb_admin.grado.toString();
          } else if (person_unit.nomb_admin.grado >= 10) {
            go_1 = person_unit.nomb_admin.grado.toString()[0];
            go_2 = person_unit.nomb_admin.grado.toString()[1];
          }
        }
        calidad_o = person_unit.nomb_admin.calidad;
        no_2 = person_unit.nomb_admin.level;
      }

      var total_subjects = person_unit.total_subjects;

      var hb_1 = null;
      var hb_2 = null;
      var catb_1 = null;
      var catb_2 = null;

      var nombre_asignatura_b = null;
      var calidad_b = null;

      var hc_1 = null;
      var hc_2 = null;
      var catc_1 = null;
      var catc_2 = null;

      var nombre_asignatura_c = null;
      var calidad_c = null;

      var horas = [];

      if (total_subjects.length > 0) {
        total_subjects.forEach((ramo) => {
          if (nombre_asignatura_b == null) {
            nombre_asignatura_b = ramo.name;
            if (ramo.hours != null) {
              if (ramo.hours.length > 1) {
                hb_1 = ramo.hours[0];
                hb_2 = ramo.hours[1];
              } else if (ramo.hours.length == 1) {
                hb_1 = "0";
                hb_2 = ramo.hours;
              }
            }
            if (ramo.categoria != null) {
              var aux_category = ramo.categoria.split(" ");
              if (aux_category.length > 1) {
                catb_1 = aux_category[0].substring(0, 3);
                catb_2 = aux_category[1];
              } else if (aux_category.length == 1) {
                catb_1 = aux_category[0].substring(0, 3);
              }
            }
            calidad_b = ramo.calidad;
          } else if (nombre_asignatura_b != null) {
            nombre_asignatura_c = ramo.name;
            if (ramo.hours != null) {
              if (ramo.hours.length > 1) {
                hc_1 = ramo.hours[0];
                hc_2 = ramo.hours[1];
              } else if (ramo.hours.length == 1) {
                hc_1 = "0";
                hc_2 = ramo.hours;
              }
            }
            if (ramo.categoria != null) {
              var aux_category = ramo.categoria.split(" ");
              if (aux_category.length > 1) {
                catc_1 = aux_category[0].substring(0, 3);
                catc_2 = aux_category[1];
              } else if (aux_category.length == 1) {
                catc_1 = aux_category[0].substring(0, 3);
              }
            }
            calidad_c = ramo.calidad;
            horas.push({
              nombre_asignatura_c: nombre_asignatura_c,
              hc_1: hc_1,
              hc_2: hc_2,
              catc_1: catc_1,
              catc_2: catc_2,
              calidad_c: calidad_c,
            });
          }

          hc_1 = null;
          hc_2 = null;
          catc_1 = null;
          catc_2 = null;

          nombre_asignatura_c = null;
          calidad_c = null;
        });
      }

      //Se procesan los datos de la plantilla
      const data = {
        dd: dd,
        mm: mm,
        aaaa: aaaa,
        departamento: departamento,
        cc: cc,
        primer_apellido: primer_apellido,
        segundo_apellido: segundo_apellido,
        nombres: nombres,
        rut: rut,
        lugar_nacimiento: lugar_nacimiento,
        d_n: d_n,
        m_n: m_n,
        a_n: a_n,
        h: h,
        m: m,
        nacionalidad: nacionalidad,
        estado_civil: estado_civil,
        mail_personal: correo_personal,
        mail_corporativo: correo_corporativo,
        direccion: direccion,
        estudio_grado: estudio_grado,
        institucion_grado: institucion_grado,
        fecha_grado: fecha_grado,
        dig_1g: dig_1g,
        dig_2g: dig_2g,
        estudio_uni: estudio_uni,
        institucion_uni: institucion_uni,
        fecha_uni: fecha_uni,
        dig_1u: dig_1u,
        dig_2u: dig_2u,
        nombre_first: nombre_first,
        institucion_first: institucion_first,
        fecha_first: fecha_first,
        grados: grados,
        dig_1o: dig_1o,
        dig_2o: dig_2o,
        planta: null,
        cargo: cargo,
        nivel: nivel,
        g_1: g_1,
        g_2: g_2,
        r_1: r_1,
        r_2: r_2,
        d_i: d_i,
        m_i: m_i,
        a_i: a_i,
        d_f: d_f,
        m_f: m_f,
        a_f: a_f,
        ramo_first: ramo_first,
        r_h: r_h,
        ramo_categoria_first: ramo_categoria_first,
        ramos: ramos,
        f_s: f_s,
        f_n: f_n,
        j_s: j_s,
        j_n: j_n,
        planta_o: null,
        cargo_o: cargo_o,
        go_1: go_1,
        go_2: go_2,
        no_1: no_1,
        no_2: no_2,
        calidad_o: calidad_o,
        hb_1: hb_1,
        hb_2: hb_2,
        catb_1: catb_1,
        catb_2: catb_2,
        calidad_b: calidad_b,
        nombre_asignatura_b: nombre_asignatura_b,
        horas: horas,
        nombre_pie: person_unit.person_name,
      };

      //Se procesa el documento para generar el archivo
      const handler = new TemplateHandler();
      const doc = await handler.process(templateFile, data);
      var name_file =
        aaaa +
        "-" +
        mm +
        "-" +
        dd +
        "_CC" +
        cc +
        "_PAF_" +
        nombres.split(" ")[0] +
        "_" +
        primer_apellido +
        ".docx";

      await zip.file(name_file, doc);

      // saveAs(doc, name_file);
      //this.isLoading = false
    },
    async individualDownloadWordPaf(
      person_unit,
      start_date,
      end_date,
      departamento,
      cc
    ) {
      //Se carga la plantilla Word
      //this.isLoading = true
      const response = await fetch("/template-paf-phc.docx");
      const templateFile = await response.blob();

      //Manejo de datos
      var datos = [];
      var fecha_actual = person_unit.fecha_actual;
      var primer_apellido = person_unit.last_name;
      var segundo_apellido = person_unit.second_surname;
      var nombres = person_unit.name;
      var rut = person_unit.rut.toString() + "-" + person_unit.dv;
      var lugar_nacimiento = person_unit.lugar_nacimiento;
      var fecha_nacimiento = person_unit.fecha_nacimiento;
      var dd = null;
      var mm = null;
      var aaaa = null;
      var d_n = null;
      var m_n = null;
      var a_n = null;
      if (
        fecha_nacimiento != null &&
        fecha_nacimiento != undefined &&
        fecha_nacimiento != ""
      ) {
        d_n = fecha_nacimiento.split("-")[0];
        m_n = fecha_nacimiento.split("-")[1];
        a_n = fecha_nacimiento.split("-")[2];
      }
      if (
        fecha_actual != null &&
        fecha_actual != undefined &&
        fecha_actual != ""
      ) {
        dd = fecha_actual.split("-")[0];
        mm = fecha_actual.split("-")[1];
        aaaa = fecha_actual.split("-")[2];
      }
      var genero = person_unit.genero;
      var h = null;
      var m = null;
      if (genero === "HOMBRE") {
        h = "X";
      } else if (genero === "MUJER") {
        m = "X";
      }
      var nacionalidad = person_unit.nacionalidad;
      var estado_civil = person_unit.estado_civil;
      var correo_personal = person_unit.correo_personal;
      var correo_corporativo = person_unit.correo_corporativo;
      var direccion =
        person_unit.direccion + person_unit.comuna + person_unit.region;
      var grado = person_unit.licenciatura;
      var estudio_grado = null;
      var institucion_grado = null;
      var fecha_grado = null;
      var dig_1g = null;
      var dig_2g = null;

      if (grado != null && grado != undefined) {
        estudio_grado = grado.name;
        institucion_grado = grado.entity;
        if (grado.date != null && grado.date != undefined) {
          fecha_grado = new Date(grado.date).toLocaleDateString("es-CL");
        }
        if (grado.semester != null && grado.semester != undefined) {
          if (grado.semester < 10) {
            dig_1g = "0";
            dig_2g = grado.semester.toString();
          } else if (grado.semester >= 10) {
            var semestre_aux = grado.semester.toString();
            dig_1g = semestre_aux[0];
            dig_2g = semestre_aux[1];
          }
        }
      }
      var profesion = person_unit.last_profession;
      var estudio_uni = null;
      var institucion_uni = null;
      var fecha_uni = null;
      var dig_1u = null;
      var dig_2u = null;

      if (profesion != null && profesion != undefined) {
        estudio_uni = profesion.name;
        institucion_uni = profesion.entity;
        fecha_uni = new Date(profesion.date).toLocaleDateString("es-CL");
        if (profesion.semester != null && profesion.semester != undefined) {
          if (profesion.semester < 10) {
            dig_1u = "0";
            dig_2u = profesion.semester.toString();
          } else if (profesion.semester >= 10) {
            var semestre_aux = profesion.semester.toString();
            dig_1u = semestre_aux[0];
            dig_2u = semestre_aux[1];
          }
        }
      }
      var todos_grados = person_unit.all_grades;
      var nombre_first = null;
      var institucion_first = null;
      var fecha_first = null;
      var grados = [];
      var magisters = person_unit.magisters;
      var doctorados = person_unit.doctorados;
      var dig_1o = null;
      var dig_2o = null;

      if (magisters != null && magisters != undefined) {
        magisters.forEach((grado) => {
          if (nombre_first == null) {
            nombre_first = grado.name;
            institucion_first = grado.entity;
            fecha_first =
              grado.date != null && grado.date != undefined
                ? new Date(grado.date).toLocaleDateString("es-CL")
                : null;
          } else {
            grados.push({
              nombre: grado.name,
              institucion: grado.entity,
              fecha:
                grado.date != null && grado.date != undefined
                  ? new Date(grado.date).toLocaleDateString("es-CL")
                  : null,
            });
          }

          if (grado.semester != null && grado.semester != undefined) {
            if (grado.semester < 10) {
              dig_1o = "0";
              dig_2o = grado.semester.toString();
            } else if (grado.semester >= 10) {
              var semestre_aux = grado.semester.toString();
              dig_1o = semestre_aux[0];
              dig_2o = semestre_aux[1];
            }
          }
        });
      }

      if (doctorados != null && doctorados != undefined) {
        doctorados.forEach((grado) => {
          grados.push({
            nombre: grado.name,
            institucion: grado.entity,
            fecha:
              grado.date != null && grado.date != undefined
                ? new Date(grado.date).toLocaleDateString("es-CL")
                : null,
          });
          if (grado.semester != null && grado.semester != undefined) {
            if (grado.semester < 10) {
              dig_1o = "0";
              dig_2o = grado.semester.toString();
            } else if (grado.semester >= 10) {
              var semestre_aux = grado.semester.toString();
              dig_1o = semestre_aux[0];
              dig_2o = semestre_aux[1];
            }
          }
        });
      }

      var planta = person_unit.work_plant.name;
      var cargo = null;
      var nivel = null;
      var g_1 = null;
      var g_2 = null;
      var r_1 = null;
      var r_2 = null;

      var d_i = start_date[0];
      var m_i = start_date[1];
      var a_i = start_date[2];
      var d_f = end_date[0];
      var m_f = end_date[1];
      var a_f = end_date[2];

      var asignaturas = person_unit.list_of_subjects;
      var ramo_first = null;
      var r_h = null;
      var ramo_categoria_first = null;
      var ramos = [];
      var siac_code = "";
      asignaturas.forEach((asignatura) => {
        if (ramo_first == null) {
          if (asignatura.siac_code != null && asignatura.siac_code != "") {
            siac_code = "-" + asignatura.siac_code;
          } else {
            siac_code = "";
          }
          ramo_first = asignatura.name + siac_code;
          r_h = asignatura.hours;
          ramo_categoria_first = person_unit.category_name;
        } else {
          if (asignatura.siac_code != null && asignatura.siac_code != "") {
            siac_code = "-" + asignatura.siac_code;
          } else {
            siac_code = "";
          }
          ramos.push({
            nombre: asignatura.name + siac_code,
            h: asignatura.hours,
            categoria: person_unit.category_name,
          });
        }
      });

      var f_s = null;
      var f_n = null;

      if (person_unit.rinde_fianza) {
        f_s = "X";
      } else {
        f_n = "X";
      }
      var j_s = null;
      var j_n = null;

      if (person_unit.jubilado) {
        j_s = "X";
      } else {
        j_n = "X";
      }

      var planta_o = null;
      var cargo_o = null;
      var go_1 = null;
      var go_2 = null;
      var calidad_o = null;
      var no_1 = null;
      var no_2 = null;

      if (
        person_unit.nomb_academico != null &&
        person_unit.nomb_academico != undefined
      ) {
        planta_o = person_unit.nomb_academico.planta;
        cargo_o = person_unit.nomb_academico.cargo;
        if (
          person_unit.nomb_academico.grado != null &&
          person_unit.nomb_academico.grado != undefined
        ) {
          if (person_unit.nomb_academico.grado < 10) {
            go_1 = "0";
            go_2 = person_unit.nomb_academico.grado.toString();
          } else if (person_unit.nomb_academico.grado >= 10) {
            go_1 = person_unit.nomb_academico.grado.toString()[0];
            go_2 = person_unit.nomb_academico.grado.toString()[1];
          }
        }
        calidad_o = person_unit.nomb_academico.calidad;
        no_2 = person_unit.nomb_academico.level;
      } else if (
        person_unit.nomb_admin != null &&
        person_unit.nomb_admin != undefined
      ) {
        planta_o = person_unit.nomb_admin.planta;
        cargo_o = person_unit.nomb_admin.cargo;
        if (
          person_unit.nomb_admin.grado != null &&
          person_unit.nomb_admin.grado != undefined
        ) {
          if (person_unit.nomb_admin.grado < 10) {
            go_1 = "0";
            go_2 = person_unit.nomb_admin.grado.toString();
          } else if (person_unit.nomb_admin.grado >= 10) {
            go_1 = person_unit.nomb_admin.grado.toString()[0];
            go_2 = person_unit.nomb_admin.grado.toString()[1];
          }
        }
        calidad_o = person_unit.nomb_admin.calidad;
        no_2 = person_unit.nomb_admin.level;
      }

      var total_subjects = person_unit.total_subjects;

      var hb_1 = null;
      var hb_2 = null;
      var catb_1 = null;
      var catb_2 = null;

      var nombre_asignatura_b = null;
      var calidad_b = null;

      var hc_1 = null;
      var hc_2 = null;
      var catc_1 = null;
      var catc_2 = null;

      var nombre_asignatura_c = null;
      var calidad_c = null;
      var horas = [];

      if (total_subjects.length > 0) {
        total_subjects.forEach((ramo) => {
          if (nombre_asignatura_b == null) {
            nombre_asignatura_b = ramo.name;
            if (ramo.hours != null) {
              if (ramo.hours.length > 1) {
                hb_1 = ramo.hours[0];
                hb_2 = ramo.hours[1];
              } else if (ramo.hours.length == 1) {
                hb_1 = "0";
                hb_2 = ramo.hours;
              }
            }
            if (ramo.categoria != null) {
              var aux_category = ramo.categoria.split(" ");
              if (aux_category.length > 1) {
                catb_1 = aux_category[0].substring(0, 3);
                catb_2 = aux_category[1];
              } else if (aux_category.length == 1) {
                catb_1 = aux_category[0].substring(0, 3);
              }
            }
            calidad_b = ramo.calidad;
          } else if (nombre_asignatura_b != null) {
            nombre_asignatura_c = ramo.name;
            if (ramo.hours != null) {
              if (ramo.hours.length > 1) {
                hc_1 = ramo.hours[0];
                hc_2 = ramo.hours[1];
              } else if (ramo.hours.length == 1) {
                hc_1 = "0";
                hc_2 = ramo.hours;
              }
            }
            if (ramo.categoria != null) {
              var aux_category = ramo.categoria.split(" ");
              if (aux_category.length > 1) {
                catc_1 = aux_category[0].substring(0, 3);
                catc_2 = aux_category[1];
              } else if (aux_category.length == 1) {
                catc_1 = aux_category[0].substring(0, 3);
              }
            }
            calidad_c = ramo.calidad;
            horas.push({
              nombre_asignatura_c: nombre_asignatura_c,
              hc_1: hc_1,
              hc_2: hc_2,
              catc_1: catc_1,
              catc_2: catc_2,
              calidad_c: calidad_c,
            });
          }

          hc_1 = null;
          hc_2 = null;
          catc_1 = null;
          catc_2 = null;

          nombre_asignatura_c = null;
          calidad_c = null;
        });
      }

      //Se procesan los datos de la plantilla
      const data = {
        dd: dd,
        mm: mm,
        aaaa: aaaa,
        departamento: departamento,
        cc: cc,
        primer_apellido: primer_apellido,
        segundo_apellido: segundo_apellido,
        nombres: nombres,
        rut: rut,
        lugar_nacimiento: lugar_nacimiento,
        d_n: d_n,
        m_n: m_n,
        a_n: a_n,
        h: h,
        m: m,
        nacionalidad: nacionalidad,
        estado_civil: estado_civil,
        mail_personal: correo_personal,
        mail_corporativo: correo_corporativo,
        direccion: direccion,
        estudio_grado: estudio_grado,
        institucion_grado: institucion_grado,
        fecha_grado: fecha_grado,
        dig_1g: dig_1g,
        dig_2g: dig_2g,
        estudio_uni: estudio_uni,
        institucion_uni: institucion_uni,
        fecha_uni: fecha_uni,
        dig_1u: dig_1u,
        dig_2u: dig_2u,
        nombre_first: nombre_first,
        institucion_first: institucion_first,
        fecha_first: fecha_first,
        grados: grados,
        dig_1o: dig_1o,
        dig_2o: dig_2o,
        planta: null,
        cargo: cargo,
        nivel: nivel,
        g_1: g_1,
        g_2: g_2,
        r_1: r_1,
        r_2: r_2,
        d_i: d_i,
        m_i: m_i,
        a_i: a_i,
        d_f: d_f,
        m_f: m_f,
        a_f: a_f,
        ramo_first: ramo_first,
        r_h: r_h,
        ramo_categoria_first: ramo_categoria_first,
        ramos: ramos,
        f_s: f_s,
        f_n: f_n,
        j_s: j_s,
        j_n: j_n,
        planta_o: null,
        cargo_o: cargo_o,
        go_1: go_1,
        go_2: go_2,
        no_1: no_1,
        no_2: no_2,
        calidad_o: calidad_o,
        hb_1: hb_1,
        hb_2: hb_2,
        catb_1: catb_1,
        catb_2: catb_2,
        calidad_b: calidad_b,
        nombre_asignatura_b: nombre_asignatura_b,
        horas: horas,
        nombre_pie: person_unit.person_name,
      };

      //Se procesa el documento para generar el archivo
      const handler = new TemplateHandler();
      const doc = await handler.process(templateFile, data);
      var name_file =
        aaaa +
        "-" +
        mm +
        "-" +
        dd +
        "_CC" +
        cc +
        "_PAF_" +
        nombres.split(" ")[0] +
        "_" +
        primer_apellido +
        ".docx";

      //await zip.file(name_file, doc);

      saveAs(doc, name_file);
      this.isLoading = false;
    },
    onClickChild(value) {
      this.semester = value.semester;
      this.anio = value.anio;
      if (this.format == "word") {
        this.downloadWordResolution();
      } else if (this.format == "pdf") {
        this.downloadPdfResolution();
      }
    },
    activeModalSemesterYear(value) {
      this.format = value;
      this.isModalSemesterYear = true;
    },
    async downloadPdfResolution() {
      this.isLoading = true;
      //Se carga la plantilla Word
      const response = await fetch("/template-resolucion.docx");
      const templateFile = await response.blob();

      //Manejo de datos
      var datos = [];
      var cc = this.request.unit.cost_center;
      var start_date = new Date(this.request.person_units[0].start_date)
        .toLocaleDateString("es-CL")
        .split("-");
      var end_date = new Date(this.request.person_units[0].end_date)
        .toLocaleDateString("es-CL")
        .split("-");

      const meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Setiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

      var fecha_inicio =
        start_date[0] +
        " de " +
        meses[parseInt(start_date[1]) - 1] +
        " del " +
        start_date[2];
      var fecha_termino =
        end_date[0] +
        " de " +
        meses[parseInt(end_date[1]) - 1] +
        " del " +
        end_date[2];

      if (this.request.person_units[0].semester == 1) {
        this.semester = "PRIMER";
      } else if (this.request.person_units[0].semester == 2) {
        this.semester = "SEGUNDO";
      } else {
        this.semester = "X";
      }

      var solicitud = this.request;
      this.request.person_units[0].list_of_subjects.forEach((subject) => {
        var code = null;
        datos.push({
          //Nombres tienen que ser de la persona en la solicitud, Vereficar
          run: solicitud.person_units[0].rut,
          dv: solicitud.person_units[0].dv,
          primer_apellido: solicitud.person_units[0].last_name,
          segundo_apellido: solicitud.person_units[0].second_surname,
          nombres: solicitud.person_units[0].name,
          horas: subject.hours,
          asignatura: subject.name,
          codigo: subject.siac_code,
          categoria: solicitud.person_units[0].category_name,
          monto: null,
          tipo_ingreso: solicitud.person_units[0].appointment_type.name,
        });
      });

      //Se procesan los datos de la plantilla
      const data = {
        año: this.request.person_units[0].year,
        semestre: this.semester,
        fecha_inicio: fecha_inicio,
        fecha_termino: fecha_termino,
        cc: cc,
        datos: datos,
      };
      //const libre = require('libreoffice-convert');
      const libre = null;
      const name_file =
        "TRANSCRIPCIÓN_PHC_" +
        this.request.person_units[0].appointment_type.name.replace(" ", "_") +
        "_" +
        solicitud.person_units[0].name.replace(" ", "_") +
        "_" +
        solicitud.person_units[0].last_name +
        "_" +
        cc.toString() +
        ".docx";
      const path = require("path");
      const outputPath = path.join(__dirname, `/example.pdf`);
      //saveAs(doc, name_file);
      //this.isLoading = false

      //Se procesa el documento para generar el archivo
      const handler = new TemplateHandler();
      const doc = await handler.process(templateFile, data);

      const arrayBuffer = await doc.arrayBuffer();
      const buffer = Buffer.from(arrayBuffer);

      libre.convert(buffer, extend, undefined, (err, done) => {
        if (err) {
          console.log(`Error converting file: ${err}`);
        }

        // Here in done you have pdf file which you can save or transfer in another stream
        fs.writeFileSync(outputPath, done);
      });

      //saveAs(doc, name_file);
      this.isLoading = false;
    },
    async downloadWordPersonUnit() {
      this.isLoading = true;
      var cc = this.request.unit.cost_center;
      var unit = this.request.unit;
      const zip = new JSZip();
      if (this.request.person_units.length > 1) {
        for (const person_unit of this.request.person_units) {
          await this.downloadMassiveWordPersonUnit(
            zip,
            person_unit,
            cc,
            this.request.semester,
            unit
          );
        }
        zip
          .generateAsync({ type: "blob" })
          .then((blob) => {
            // Guardar el archivo ZIP en el sistema de archivos
            saveAs(blob, "PROPOSICIONES DE NOMBRAMIENTOS.zip");
            this.isLoading = false;
            console.log("Archivos comprimidos en archivos.zip");
          })
          .catch((error) => {
            this.isLoading = false;
            console.error("Error al generar el archivo ZIP:", error);
          });
      } else {
        this.downloadIndividualWordPersonUnit(
          this.request.person_units[0],
          cc,
          this.request.semester,
          unit
        );
      }
    },
    async downloadIndividualWordPersonUnit(
      person_unit,
      cost_center,
      semester,
      unit
    ) {
      this.isLoading = true;
      //Se carga la plantilla Word
      var datos = [];
      var cc = cost_center;
      if (semester == 1) {
        this.semester = "PRIMER";
      } else if (semester == 2) {
        this.semester = "SEGUNDO";
      } else {
        this.semester = "X";
      }
      const response = await fetch("/template-nombramiento.docx");
      const templateFile = await response.blob();
      //Manejo de datos

      var asignaturas = [];
      var start_date = new Date(person_unit.start_date)
        .toLocaleDateString("es-CL")
        .split("-");
      var end_date = new Date(person_unit.end_date)
        .toLocaleDateString("es-CL")
        .split("-");

      const meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Setiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

      var fecha_inicio =
        start_date[0] +
        " de " +
        meses[parseInt(start_date[1]) - 1] +
        " de " +
        start_date[2];
      var fecha_termino =
        end_date[0] +
        " de " +
        meses[parseInt(end_date[1]) - 1] +
        " de " +
        end_date[2];

      var estudios = " [NO REGISTRA ESTUDIOS]";
      if (person_unit.major_grade != null && person_unit.profession != null) {
        estudios =
          " de profesión " +
          person_unit.profession +
          ", con grado académico " +
          person_unit.major_grade;
      } else if (
        person_unit.major_grade == null &&
        person_unit.profession != null
      ) {
        estudios = " de profesión " + person_unit.profession;
      } else if (
        person_unit.major_grade != null &&
        person_unit.profession == null
      ) {
        estudios = " con grado académico " + person_unit.major_grade;
      }

      var contador = 0;
      var primera_asignatura = null;
      var segunda_asignatura = null;
      person_unit.list_of_subjects.forEach((subject) => {
        var code = null;
        if (subject.siac_code != null) {
          var name_asignatura =
            subject.hours + "-" + subject.siac_code + "-" + subject.name;
        } else {
          var name_asignatura = subject.hours + "-" + subject.name;
        }
        if (contador > 1) {
          asignaturas.push({
            //Falta agregar las horas individuales por asignatura
            nombre_asignatura: name_asignatura,
          });
        } else if (contador == 0) {
          primera_asignatura = name_asignatura;
        } else if (contador == 1) {
          segunda_asignatura = name_asignatura;
        }
        contador = contador + 1;
      });

      //Se procesan los datos de la plantilla
      const data = {
        rut: person_unit.rut.toString() + "-" + person_unit.dv,
        cargo: person_unit.work_plant.name,
        estudios: estudios,
        depto: unit.name,
        nombre_completo: person_unit.person_name,
        nombre_primera_asignatura: primera_asignatura,
        nombre_segunda_asignatura: segunda_asignatura,
        horas: person_unit.actual_hours, //Verificar que sean las horas totales
        categoria: person_unit.category_name,
        calidad: person_unit.quality,
        fecha_inicio: fecha_inicio,
        fecha_termino: fecha_termino,
        cc: cc,
        asignaturas: asignaturas,
      };

      //Se procesa el documento para generar el archivo
      const handler = new TemplateHandler();
      const doc = await handler.process(templateFile, data);
      var name_file = "";
      name_file =
        "PROPOSICIÓN_PHC_" +
        person_unit.appointment_type.name.replace(" ", "_") +
        "_" +
        person_unit.name.replace(" ", "_") +
        "_" +
        person_unit.last_name +
        "_" +
        cc.toString() +
        ".docx";

      saveAs(doc, name_file);
      this.isLoading = false;
    },
    async downloadMassiveWordPersonUnit(
      zip,
      person_unit,
      cost_center,
      semester,
      unit
    ) {
      this.isLoading = true;
      //Se carga la plantilla Word
      var datos = [];
      var cc = cost_center;
      if (semester == 1) {
        this.semester = "PRIMER";
      } else if (semester == 2) {
        this.semester = "SEGUNDO";
      } else {
        this.semester = "X";
      }
      const response = await fetch("/template-nombramiento.docx");
      const templateFile = await response.blob();
      //Manejo de datos

      var asignaturas = [];
      var start_date = new Date(person_unit.start_date)
        .toLocaleDateString("es-CL")
        .split("-");
      var end_date = new Date(person_unit.end_date)
        .toLocaleDateString("es-CL")
        .split("-");

      const meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Setiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

      var fecha_inicio =
        start_date[0] +
        " de " +
        meses[parseInt(start_date[1]) - 1] +
        " de " +
        start_date[2];
      var fecha_termino =
        end_date[0] +
        " de " +
        meses[parseInt(end_date[1]) - 1] +
        " de " +
        end_date[2];

      var estudios = " [NO REGISTRA ESTUDIOS]";
      if (person_unit.major_grade != null && person_unit.profession != null) {
        estudios =
          " de profesión " +
          person_unit.profession +
          ", con grado académico " +
          person_unit.major_grade;
      } else if (
        person_unit.major_grade == null &&
        person_unit.profession != null
      ) {
        estudios = " de profesión " + person_unit.profession;
      } else if (
        person_unit.major_grade != null &&
        person_unit.profession == null
      ) {
        estudios = " con grado académico " + person_unit.major_grade;
      }

      var contador = 0;
      var primera_asignatura = null;
      var segunda_asignatura = null;
      person_unit.list_of_subjects.forEach((subject) => {
        var code = null;
        if (subject.siac_code != null) {
          var name_asignatura =
            subject.hours + "-" + subject.siac_code + "-" + subject.name;
        } else {
          var name_asignatura = subject.hours + "-" + subject.name;
        }
        if (contador > 1) {
          asignaturas.push({
            //Falta agregar las horas individuales por asignatura
            nombre_asignatura: name_asignatura,
          });
        } else if (contador == 0) {
          primera_asignatura = name_asignatura;
        } else if (contador == 1) {
          segunda_asignatura = name_asignatura;
        }
        contador = contador + 1;
      });

      //Se procesan los datos de la plantilla
      const data = {
        rut: person_unit.rut.toString() + "-" + person_unit.dv,
        cargo: person_unit.work_plant.name,
        estudios: estudios,
        depto: unit.name,
        nombre_completo: person_unit.person_name,
        nombre_primera_asignatura: primera_asignatura,
        nombre_segunda_asignatura: segunda_asignatura,
        horas: person_unit.actual_hours, //Verificar que sean las horas totales
        categoria: person_unit.category_name,
        calidad: person_unit.quality,
        fecha_inicio: fecha_inicio,
        fecha_termino: fecha_termino,
        cc: cc,
        asignaturas: asignaturas,
      };

      //Se procesa el documento para generar el archivo
      const handler = new TemplateHandler();
      const doc = await handler.process(templateFile, data);
      var name_file = "";
      name_file =
        "PROPOSICIÓN_PHC_" +
        person_unit.appointment_type.name.replace(" ", "_") +
        "_" +
        person_unit.name.replace(" ", "_") +
        "_" +
        person_unit.last_name +
        "_" +
        cc.toString() +
        ".docx";
      await zip.file(name_file, doc);
    },
    updateResolution() {
      this.isLoading = true;
      return axios
        .put(
          "/admin/person_units/" +
            this.request.person_units[0].id +
            "/update_resolution_data.json",
          {
            resolution_data: this.resolution_data,
          }
        )
        .then((res) => {
          this.$buefy.dialog.alert({
            message:
              "Los datos de la resolución fueron actualizados correctamente.",
            type: "is-success",
            hasIcon: true,
            icon: "check-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
          this.initialize();
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            message: "Hubo un error al actualizar los datos de la resolución.",
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
          console.log(error);
          this.initialize();
        });
    },
    activeModalMasive() {
      this.selected_appointment = this.request.person_units[0].id;
      this.person_name = this.request.person_units[0].person_name;
      let person_unit = this.getEstablishment(this.request.person_units[0].id);
      if (person_unit.establishment !== undefined) {
        if (person_unit.establishment.name === "ACADEMICOS") {
          this.formName = "AppointmentAcademicForm";
          this.title =
            "Editar Solicitud Nombramiento Estamento Académico" +
            " - " +
            this.person_name;
        } else if (person_unit.establishment.name === "ADMINISTRATIVOS") {
          this.formName = "AppointmentAdministrativeForm";
          this.title =
            "Editar Solicitud Nombramiento Estamento Administrativo" +
            " - " +
            this.person_name;
        } else if (person_unit.establishment.name === "HONORARIOS") {
          this.formName = "AppointmentFeeContractForm";
          this.title =
            "Editar Solicitud Nombramiento Estamento Honorarios" +
            " - " +
            this.person_name;
        } else {
          this.formName = "AppointmentHoursForm";
          this.title =
            "Editar Solicitud Nombramiento Estamento Profesor Horas Clase" +
            " - " +
            this.person_name;
        }
      } else {
        this.formName = "AppointmentAdministrativeForm";
      }
      //console.log("APPOINTMENT "+ this.showName)
      this.editMassive = true;
      this.isEditAppointmentModalActive = true;
    },
    deletePersonUnitConfirm(row) {
      if (this.request.person_units.length > 1) {
        this.$buefy.dialog.confirm({
          title: "Eliminar Profesor Horas Clases",
          message:
            "Precaución, se eliminará el profesor horas clases de la solicitud, ¿Desea continuar?",
          cancelText: "Cancelar",
          confirmText: "Eliminar",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => this.deletePersonUnit(row),
        });
      } else {
        this.$buefy.dialog.alert({
          title: "Error",
          message:
            "No se puede eliminar al profesor horas de clases, al menos debe haber uno en la solicitud",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    deletePersonUnit(row) {
      this.isLoading = true;
      axios
        .delete(
          "/admin/person_units/" +
            row.id +
            "/delete_person_request_massive.json"
        )
        .then((response) => {
          this.$buefy.dialog.alert({
            message:
              "El profesor horas clases fue eliminado con éxito de la solicitud",
            confirmText: "Ok",
          });
          this.initialize();
        })
        .catch((err) => {
          console.log(err);
          this.$buefy.dialog.alert({
            title: "Error",
            message: "Hubo un error al eliminar el profesor horas clases",
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
          this.isLoading = false;
        });
    },
    activeModalAddPerson() {
      this.isModalAddPerson = true;
    },
    changeSwitch() {
      if (this.warningOnly) {
        this.filter_person_units = this.request.person_units.filter(
          (person_unit) => person_unit.horas_totales > 16
        );
      } else {
        this.filter_person_units = this.request.person_units;
      }
    },
    showWarningPersonUnits(row) {
      this.isLoading = true;
      this.person_units_warning = null;
      this.person_name = row.person_name;
      this.person_units_warning = row.nomb_horas_totales;
      this.isModalWarning = true;
      this.isLoading = false;
    },
  },
  watch: {
    isEditAppointmentModalActive: function () {
      if (!this.isEditAppointmentModalActive) {
        this.initialize();
      }
    },
    isModalUpdateState: function () {
      if (!this.isModalUpdateState) {
        this.initialize();
      }
    },
  },
};
</script>

<style>
.colored-li {
  background-color: rgb(121, 87, 213);
  color: white;
  margin-top: 3px;
}

.modal-custom .modal-content {
  width: 100%; /* Ajusta el valor según tus necesidades */
  max-width: 1800px; /* Opcionalmente, establece un ancho máximo */ /* Ajusta el valor según tus necesidades */
}
</style>
