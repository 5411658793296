<template>
  <div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
    <div class="container box my-5">
      <div class="title is-2">
        Dashboard de Control para Acreditación Digital
      </div>
      <div class="columns mb-4">
        <div class="column">
          <div
            class="column has-text-centered is-flex is-flex-direction-column"
          >
            <div class="subtitle is-4 mb-auto">
              Total de estudiantes acreditados:
            </div>
            <div class="title is-1 mt-auto">
              {{ totalStudents }}
            </div>
          </div>
        </div>
        <div class="column" v-for="gender in genders">
          <div
            class="column has-text-centered is-flex is-flex-direction-column"
          >
            <div class="subtitle is-4 mb-auto">
              Estudiantes acreditados de género {{ gender.name }}:
            </div>
            <div class="title is-1 mt-auto">
              {{ gender.count }}
            </div>
          </div>
        </div>
      </div>
      <b-collapse class="card mb-5" animation="slide" v-model="careerCollapse">
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            :aria-expanded="careerCollapse"
          >
            <p class="card-header-title">
              Ver total de estudiantes acreditados por carrera
            </p>
            <a class="card-header-icon">
              <b-icon
                type="is-primary"
                :icon="props.open ? 'minus-circle' : 'plus-circle'"
              >
              </b-icon>
            </a>
          </div>
        </template>
        <div class="card-content">
          <b-table
            :data="careers"
            paginated
            per-page="10"
            striped
            hoverable
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"
          >
            <b-table-column
              field="name"
              label="Nombre de Carrera"
              searchable
              sortable
            >
              <template slot="searchable" slot-scope="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.name }}
              </template>
            </b-table-column>
            <b-table-column
              field="count"
              label="Cantidad de Alumnos Acreditados"
              searchable
              sortable
            >
              <template slot="searchable" slot-scope="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.count }}
              </template>
            </b-table-column>
          </b-table>
        </div>
      </b-collapse>
      <div class="buttons has-addons is-right">
        <div>
          <b-button
            icon-left="sync-alt"
            type="is-primary"
            @click="fetchControl"
          >
            Actualizar información
          </b-button>
        </div>
        <div>
          <b-button
            class="ml-2 is-excel-color"
            icon-left="file-excel"
            @click="downloadExcel"
          >
            Descargar Excel
          </b-button>
        </div>
      </div>
    </div>

    <div class="container box my-5">
      <b-tabs type="is-toggle-rounded" position="is-centered">
        <b-tab-item label="Historial de estudiantes acreditados">
          <div class="title is-4 has-text-centered">
            Historial de estudiantes acreditados
          </div>
          <b-field grouped group-multiline>
            <div class="column has-text-right">
              <b-switch v-model="showDateHour"
                >Ver fechas y horas de entrada
              </b-switch>
            </div>
          </b-field>
          <b-table
            :data="
              !showDateHour ? accreditedStudentsGrouped : accreditedStudents
            "
            :paginated="true"
            per-page="10"
            striped
            hoverable
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"
          >
            <b-table-column field="rut" label="RUT" searchable sortable>
              <template slot="searchable" slot-scope="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.rut }}
              </template>
            </b-table-column>
            <b-table-column field="name" label="Nombres" searchable sortable>
              <template slot="searchable" slot-scope="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.name }}
              </template>
            </b-table-column>
            <b-table-column
              field="last_name"
              label="Primer Apellido"
              searchable
              sortable
            >
              <template slot="searchable" slot-scope="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.last_name }}
              </template>
            </b-table-column>
            <b-table-column
              field="second_last_name"
              label="Segundo Apellido"
              searchable
              sortable
            >
              <template slot="searchable" slot-scope="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.second_last_name }}
              </template>
            </b-table-column>
            <b-table-column field="career" label="Carrera" searchable sortable>
              <template slot="searchable" slot-scope="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.career }}
              </template>
            </b-table-column>
            <b-table-column field="gender" label="Género" searchable sortable>
              <template slot="searchable" slot-scope="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.gender }}
              </template>
            </b-table-column>
            <b-table-column
              field="date"
              label="Fecha y Hora de Entrada"
              searchable
              sortable
              :custom-sort="sortDates"
              v-if="showDateHour"
            >
              <template slot="searchable" slot-scope="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.date }}
              </template>
            </b-table-column>
            <template #empty>No se encontraron resultados</template>
          </b-table>
        </b-tab-item>
        <b-tab-item label="Lista total de estudiantes">
          <div class="title is-4 has-text-centered">
            Lista total de estudiantes
          </div>
          <b-table
            :data="students"
            :paginated="true"
            per-page="10"
            striped
            hoverable
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"
          >
            <b-table-column field="rut" label="RUT" searchable sortable>
              <template slot="searchable" slot-scope="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.rut }}
              </template>
            </b-table-column>
            <b-table-column field="name" label="Nombres" searchable sortable>
              <template slot="searchable" slot-scope="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.name }}
              </template>
            </b-table-column>
            <b-table-column
              field="last_name"
              label="Primer Apellido"
              searchable
              sortable
            >
              <template slot="searchable" slot-scope="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.last_name }}
              </template>
            </b-table-column>
            <b-table-column
              field="second_last_name"
              label="Segundo Apellido"
              searchable
              sortable
            >
              <template slot="searchable" slot-scope="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.second_last_name }}
              </template>
            </b-table-column>
            <b-table-column field="career" label="Carrera" searchable sortable>
              <template slot="searchable" slot-scope="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.career }}
              </template>
            </b-table-column>
            <b-table-column field="gender" label="Género" searchable sortable>
              <template slot="searchable" slot-scope="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.gender }}
              </template>
            </b-table-column>
            <b-table-column
              field="accreditations"
              label="Cantidad de Acreditaciones"
              searchable
              sortable
            >
              <template slot="searchable" slot-scope="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  icon="search"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row.accreditations }}
              </template>
            </b-table-column>
            <template #empty>No se encontraron resultados</template>
          </b-table>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      isLoading: false,
      students: [],
      genders: [],
      careers: [],
      accreditedStudents: [],
      accreditedStudentsGrouped: [],
      totalStudents: 0,
      showDateHour: true,
      careerCollapse: false,
    };
  },
  methods: {
    fetchControl() {
      let vm = this;
      vm.isLoading = true;
      axios
        .get("/digital-accreditation/control.json")
        .then((response) => {
          vm.students = response.data.students;
          vm.genders = response.data.genders;
          vm.careers = response.data.careers;
          vm.accreditedStudents = response.data.accredited_students;
          vm.totalStudents = response.data.total_students;
          vm.accreditedStudentsGrouped = vm.accreditedStudents.reduce(
            (accumulator, current) => {
              if (!accumulator.some((obj) => obj["rut"] === current["rut"])) {
                accumulator.push(current);
              }
              return accumulator;
            },
            []
          );
        })
        .catch((e) => {
          console.log(e);
          vm.$buefy.dialog.alert({
            title: "Error",
            message:
              "Error al obtener la información de los estudiantes, reintente nuevamente.",
            confirmText: "OK",
            type: "is-danger",
          });
        })
        .finally(() => {
          vm.isLoading = false;
        });
    },
    sortDates(a, b, isAsc) {
      const dateA = this.convertToDate(a.date);
      const dateB = this.convertToDate(b.date);
      return isAsc ? dateA - dateB : dateB - dateA;
    },
    convertToDate(dateStr) {
      const [day, month, yearTime] = dateStr.split("/");
      const [year, time] = yearTime.split(" ");
      const [hour, minute, second] = time.split(":");
      return new Date(year, month - 1, day, hour, minute, second);
    },
    downloadExcel() {
      let vm = this;
      vm.isLoading = true;
      axios
        .get("/digital-accreditation/download", { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/xlsx" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          const filename = vm.extractFilenameFromHeaders(response.headers);
          link.href = url;
          link.setAttribute("download", `${filename}`);
          link.click();
        })
        .catch((e) => {
          console.log(e);
          vm.$buefy.dialog.alert({
            title: "Error",
            message: "Error al descargar el archivo, reintente nuevamente.",
            confirmText: "OK",
            type: "is-danger",
          });
        })
        .finally(() => {
          vm.isLoading = false;
        });
    },
    extractFilenameFromHeaders(headers) {
      const contentDisposition = headers["content-disposition"];
      const match = contentDisposition.match(/filename="(.+?)"/);

      if (match && match[1]) {
        return decodeURIComponent(match[1]);
      }

      return null;
    },
  },
  created() {
    this.fetchControl();
  },
};
</script>
