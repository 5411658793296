<template>
    <div class="unitagreements">
        <b-table
            :data="associatedAgreements"
            :paginated=true
            per-page=10
            striped
            hoverable
            :current-page="currentPage"
            :pagination-simple=false
            pagination-position="bottom"
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            default-sort="publication.title"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual">
            <b-table-column
                field= 'name'
                label= 'Nombre convenio'
                searchable
                sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    icon="search"
                    size="is-small" />
                </template>
                <template v-slot="props">
                    <a @click="goToAgreement(props.row.id)"><i class="fas fa-user-circle"></i> {{ props.row.name }}</a>
                </template>
            </b-table-column>
            <b-table-column
                field= 'participants[0].academic_name'
                label= 'Participante'
                searchable
                sortable>
              <template
                  slot="searchable"
                  slot-scope="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    icon="search"
                    size="is-small" />
              </template>
              <template v-slot="props">
                <a @click="goToPerson(props.row.participants[0].academic_id)"><i class="fas fa-user-circle"></i> {{ props.row.participants[0].academic_name }}</a>
              </template>
            </b-table-column>
            <b-table-column
                field= 'participants[0].participant_type_name'
                label= 'Participa como'
                searchable
                sortable>
              <template
                  slot="searchable"
                  slot-scope="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    icon="search"
                    size="is-small" />
              </template>
              <template v-slot="props">
                {{ props.row.participants[0].participant_type_name }}
              </template>
            </b-table-column>
            <b-table-column
                field= 'agreement_type.agreement_general_type'
                label= 'Categoría general'
                searchable
                sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    icon="search"
                    size="is-small" />
                </template>
                <template v-slot="props">
                    {{ props.row.agreement_type.agreement_general_type }}
                </template>
            </b-table-column>
            <b-table-column
                field= 'agreement_type.agreement_specifict_type'
                label= 'Categoría específica'
                searchable
                sortable>
                <template
                    slot="searchable"
                    slot-scope="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    icon="search"
                    size="is-small" />
                </template>
                <template v-slot="props">
                    {{ props.row.agreement_type.agreement_specifict_type }}
                </template>
            </b-table-column>
            <template slot="empty">
                <div class="columns is-centered">
                    <div class="column is-6 is-centered has-text-centered">
                        <b-message type="is-danger">
                            No se encontraron convenios relacionados.
                        </b-message>
                    </div>
                </div>
            </template>
        </b-table>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"/>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: 'UnitAgreements',
    props:[
        'objectId',
        'componentType'
    ],
    data(){
        return{
            associated_agreements: [],
            currentPage: 1,
            isLoading: true,
            participant: null
        }
    },
    computed:{
        associatedAgreements:{
            get: function(){
                return this.associated_agreements
            },
            set: function(newActvities){
                this.associated_agreements = newActvities
            }
        }
    },
    beforeMount(){
        this.getAgreements()
    },
    methods:{
        // Consultas al backend
        getAgreements(){
            axios.get("/agreements/get_agreements_by_unit_id/"+this.objectId+".json")
            .then(response =>{
                this.associatedAgreements = response.data
                this.participant = response.data["participants"]
                this.isLoading = false
            })
            .catch(
                e =>{
                    console.log(e)
                }
            )
        },
        goToAgreement(agreement_id){
            this.isLoading=true;
            window.location.href="/agreements/"+agreement_id
        },
      goToPerson(person_id){
        this.isLoading=true;
        window.location.href="/people/"+person_id
      }
    }
}
</script>