<template>
  <section>
    <div class="columns">
      <div class="column">
        <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
          <b-message type="is-danger" has-icon>
            <div v-for="(item,index) in errores_validacion" :key='index'>
              <div v-if="item">{{ item[0] }}</div>
            </div>
          </b-message>
        </div>
      </div>
    </div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveTransaction)">
        <div class="box"> 
          <b-steps
                v-model="activeStep"
                :animated="isAnimated"
                :rounded="isRounded"
                :has-navigation="hasNavigation"
                :icon-prev="prevIcon"
                :icon-next="nextIcon"
                :label-position="labelPosition"
                :mobile-mode="mobileMode">
            <b-step-item step="1" label="General" :clickable="isStepsClickable">
              <div class="box">
                <h1 class="title has-text-centered">General  [1/3]</h1>
                <div class="columns">
                  
                  <div class="column" v-if="unidades.length>0">
                    <b-field label="Unidad*">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <multiselect v-model="transaction.unit_id"
                                     :options="unidades.map(type => type.id)"
                                     :custom-label="opt => unidades.find(x => x.id == opt).name"
                                     placeholder="Seleccione Unidad"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel="Seleccionado"
                                     deselectLabel="No se puede deseleccionar"
                                     :disabled="!can_create_people"
                                     @select="getCodes"
                                     :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                              No se encontraron elementos.
                          </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Código de EFUSACH asociado*">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <multiselect v-model="transaction.sdt_surplus_id"
                                     :options="codes.map(type => type.id)"
                                     :custom-label="opt => codes.find(x => x.id == opt).code"
                                     placeholder="Seleccione código de EFUSACH"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel=""
                                     deselectLabel=""
                                     :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                              No se encontraron elementos.
                          </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>   
                </div>

                <div class="columns">                
                  <div class="column">
                    <b-field label="Fecha de pago*">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <b-datepicker
                            v-model="transaction.payment_date"
                            icon="calendar-alt"
                            trap-focus
                            locale="es-ES"
                            editable
                            placeholder="Formato de Fecha: dd/mm/aaaa">
                        </b-datepicker>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Monto Bruto Total*" >                            
                      <ValidationProvider rules="required|numeric|positive|max:11" v-slot="{errors}">
                        <b-input type="text" maxlength="11" placeholder="Ej: 500000" @input="calculoMontos" v-model="transaction.amount"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
              </div>
              
            </b-step-item>
            
            <b-step-item step="2" label="Persona Receptora del Fondo" :clickable="isStepsClickable">
              <h1 class="title has-text-centered">Seleccionar Persona Receptora del Fondo [2/3]</h1>
              <div class="columns">
                <div class="column">
                  <b-field label="Primer Apellido">
                    <ValidationProvider :rules="{regex: /^[a-zA-ZáéíóúÁÉÍÓÚÑñ\s]*$/}" v-slot="{ errors }">
                      <b-input v-model="search_last_name" type="text"></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Segundo Apellido">
                    <ValidationProvider :rules="{regex: /^[a-zA-ZáéíóúÁÉÍÓÚÑñ\s]*$/}" v-slot="{ errors }">
                      <b-input v-model="search_surname" type="text"></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Nombres">
                    <ValidationProvider :rules="{regex: /^[a-zA-ZáéíóúÁÉÍÓÚÑñ\s]*$/}" v-slot="{ errors }">
                      <b-input v-model="search_name" type="text" expanded></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                      
                  </b-field>
                </div>                

              </div>
              <div class="columns">
                <div class="column is-3">
                  <b-field label="Rut">
                    <ValidationProvider rules="numeric" v-slot="{ errors }">
                      <b-input v-model="search_rut"></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="column is-1">
                  <b-field label="Dv">
                    <ValidationProvider rules="alpha_num|length:1" v-slot="{ errors }">
                      <b-input v-model="search_dv"></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column has-text-right">          
                  <b-button
                            class="button right-button button-clear-filter"
                            icon-left="eraser"
                            @click="clean()">
                    Limpiar Filtros
                  </b-button>
                  <b-button type="is-info"
                            icon-left="search"
                            class="button right-button is-info"
                            @click="buscar_persona()">
                    Buscar Personas
                  </b-button>

                </div>
              </div>

              <div class="columns" v-if="can_create_people == true">
                <div class="column">                     
                  <b-button class="is-primary is-outlined is-pulled-right"  icon-left="plus" @click="addExternalPerson">
                    Añadir persona al sistema
                  </b-button>
                </div>
              </div>

              <br>
              <br>

              <b-table
                :data="people"
                :paginated= true
                per-page="20"
                striped
                hoverable
                :current-page="currentPage"
                :pagination-simple=false
                pagination-position="bottom"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="product.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
                <b-table-column
                    field="index"
                    label="N°">
                  <template v-slot="props">
                    {{ people.indexOf(props.row) + 1 }}
                  </template>
                </b-table-column>
                <b-table-column
                    field="integral"
                    label="Rut"
                    sortable
                    searchable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.integral}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="name"
                    label="Nombre"
                    sortable
                    searchable
                >
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.name}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="last_name"
                    label="Primer Apellido"
                    sortable
                    searchable
                >
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.last_name}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="second_surname"
                    label="Segundo Apellido"
                    sortable
                    searchable>
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.second_surname}}
                  </template>
                </b-table-column>
                <b-table-column
                    field="corporate_mail"
                    label="Correo Corporativo"
                    searchable
                    sortable
                >
                  <template
                      slot="searchable"
                      slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder=" Buscar"
                        icon="search"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{props.row.corporate_mail!==undefined ? props.row.corporate_mail : ""}}
                  </template>
                </b-table-column>
                <b-table-column
                    label="Acciones"
                    v-slot="props">
                  <b-field >
                    <p class="control">
                      <b-button class="is-primary" icon-right="user" @click="selectPerson(props.row.id)">Seleccionar</b-button>
                    </p>
                  </b-field>
                </b-table-column>

                <template slot="empty" slot-scope="props">
                  <div class="columns is-centered">
                    <div class="column is-6 is-centered has-text-centered">
                      <b-message type="is-danger">
                        No se encontraron resultados para datos ingresados.
                      </b-message>
                    </div>
                  </div>
                </template>
              </b-table>

              <br>
              <div class="columns">
                <div class="column">
                  <b-field label="Persona Receptora del Fondo seleccionada*" >                            
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <b-input type="text" v-model="persona_sel"></b-input>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>                
              </div>
            </b-step-item>
            <b-step-item step="3" label="Documentos" :clickable="isStepsClickable">
              <div class="box">
                <h1 class="title has-text-centered">Adjuntar Documentos  [3/3]</h1>

                <div class="box"> 

                  <h2 class="has-text-centered new-h2"> {{capitalizarTexto(tipos) || " "}}</h2>
                  <br>


                  <div class="columns">                  
                    <div class="column has-text-centered is-centered">
                      <br>
                      <b-message type="is-warning" title="Información" has-icon aria-close-label="Close message">
                        <b>IMPORTANTE: FORMATO DE ARCHIVO</b> <br />
                        Extensiones de archivo permitidas: PDF, DOC, DOCX, XLSX, XLS.
                        <br />
                        El tamaño maximo del archivo es de <b> 10 MB</b>
                      </b-message>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-field class="file is-primary is-centered" :class="{'has-name': !!file1}">
                          <b-upload v-model="file1" class="file-label">
                              <span class="file-cta">
                                  <b-icon class="file-icon" icon="upload"></b-icon>
                                  <span class="file-label">Subir Formulario</span>
                              </span>
                              <span class="file-name" v-if="file1">
                                  {{ file1.name }}
                              </span>
                          </b-upload>
                        </b-field>
                        <span class="validation-alert has-text-centered is-centered">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>

                
                <br>
                <hr class="dropdown-divider" aria-role="menuitem">
                <h2 class="has-text-centered new-h2">Añadir Información de factura o respaldo</h2>
                <br>
                
                <modal-document-render-fund
                         :tipos-finanzas="tipos_finanzas"
                         :tipos-anexo="tiposAnexo"
                         :nueva-entidad="nueva_entidad"
                         :query-type="queryTypeModal"
                         :respaldo="elementToEdit"
                         :act="act"
                         @clicked="onClickChildDocument"
                         @clickedEntity="onClickEntity"
                         @clickedUpdate="onClickChildDocumentUpdate"
                >
                </modal-document-render-fund>
                <hr class="dropdown-divider" aria-role="menuitem">
                <h2 class="has-text-centered new-h2">Resumen de montos declarados</h2>

                <br>
                <div class="columns">
                  <div class="column">
                    <b-field label="Monto bruto total declarado (Paso 1)">
                      <b-input readonly :value="'$'+Number(transaction.amount).toLocaleString('es-ES')" type="text"></b-input>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Suma de montos por Facturas">
                      <b-input readonly type="text" :value="'$'+Number(totalAmount).toLocaleString('es-ES')"></b-input>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Estado">
                      <b-input readonly v-model="estado" type="text"></b-input>
                    </b-field>
                  </div>


                </div>
                <br>
                <hr class="dropdown-divider" aria-role="menuitem">
              
                <h3 class="is-size-4 has-text-weight-bold has-text-centered">Tabla de documentos listos para subir</h3>
                <br> <br>
                <b-table
                  :data="files"
                  :key="tableDataKey"
                  :paginated=true
                  per-page=10
                  striped
                  hoverable
                  :pagination-simple=false
                  pagination-position="bottom"
                  default-sort-direction="desc"
                  sort-icon="arrow-up"
                  sort-icon-size="is-small"
                  default-sort="last_patent_state_date"
                  aria-next-label="Siguiente"
                  aria-previous-label="Anterior"
                  aria-page-label="Página"
                  aria-current-label="Página actual">
                  <b-table-column
                      field= 'file.name'
                      label= 'Nombre'
                      width= "10em"
                      searchable
                      sortable>
                    <template
                        slot="searchable"
                        slot-scope="props">
                      <b-input
                          v-model="props.filters[props.column.field]"
                          icon="search"
                          size="is-small" />
                    </template>
                    <template v-slot="props">
                      {{  props.row.file.name }}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field= 'tipoAnexo.name'
                      width="15em"
                      label= 'Tipo de respaldo'>
                    <template v-slot="props">
                      {{  props.row.tipoAnexo.name }}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field= 'entidad_name'
                      width="15em"
                      label= 'Proveedor'>
                    <template v-slot="props">

                      {{  props.row.entidad_name || "---" }}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field= 'amount'
                      width="15em"
                      label= 'Monto Bruto'>
                    <template v-slot="props">
                     {{  "$"+Number(props.row.amount).toLocaleString('es-ES')  }}
                    </template>
                  </b-table-column>
                  <b-table-column
                      field= 'code'
                      width="15em"
                      label= 'Código'>
                    <template v-slot="props">
                      {{  props.row.code }}
                    </template>
                  </b-table-column>
                  <b-table-column
                      label="Acción"
                      width="8em"
                      v-slot="props">

                      <b-button class="is-info is-light is-outlined" icon-left="pen" @click="editFile(props.row)">
                      </b-button>

                      <b-button class="is-danger is-light is-outlined" icon-left="trash" @click="deleteFileTab(props.row)">
                      </b-button>
                      
                  </b-table-column>

                  <template slot="empty" slot-scope="props">
                    <div class="columns is-centered">
                      <div class="column is-6 is-centered has-text-centered">
                        <b-message type="is-danger">
                          No se agregado documentos.
                        </b-message>
                      </div>
                    </div>
                  </template>
                </b-table>
              </div>          
            </b-step-item>
            <template
                #navigation="{previous, next}">
              <div class="columns is-pulled-right" >
                <div class="column">
                  <b-button
                      outlined
                      icon-left="chevron-left"
                      :disabled="previous.disabled"
                      @click.prevent="previous.action">
                  </b-button>
                  <b-button
                      outlined
                      icon-right="chevron-right"
                      :disabled="next.disabled"
                      @click.prevent="next.action">
                  </b-button>

                </div>
              </div>
            </template>
          </b-steps>
          <br>
          <br>
          <br>
          <div class="columns">
            <div class="column">
              <div class="is-pulled-left">
                <button v-if="modalCancel" class="button left-button" type="button" @click="$emit('close')">Cerrar</button>
              </div>
              <div class="is-pulled-right">
                <b-button class="button is-primary" icon-left="save" native-type="submit" @click="checkValidation()">Guardar transacción</b-button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

    <b-modal
        v-model="isCreateModal"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-sdt-people-create
            query-type="create"
            modal-header="Agregar datos personales"
            @clicked="onClickChild"
            @close="props.close"></modal-sdt-people-create>
      </template>
    </b-modal>



    <b-modal
        v-model="isAddModalEntityActive"
        :destroy-on-hide="true"
        width="50%"
        :can-cancel="canCancel">
      <template #default="props">
        <modal_partner_entity
            query-type="update"
            modal-header="Añadir nueva entidad"            
            @close="props.close"
            @clicked="onClickChildEntity">
        </modal_partner_entity>
      </template>
    </b-modal>

  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import ModalSdtPeopleCreate from "../ModalSdtPeopleCreate.vue"
import ModalDocumentRenderFund from "./ModalDocumentRenderFund.vue"
import ModalPartnerEntity from "../../moduloVIME/agreements/new/modal_partner_entity";
import modal_partner_entity from "../../moduloVIME/agreements/new/modal_partner_entity";
export default {
  name: "NewTransactionBienRenderFundForm",
  components:{ModalSdtPeopleCreate,ModalDocumentRenderFund,ModalPartnerEntity,modal_partner_entity},
  data() {
    return {
      keepFirst: false,
      hasNavigation: true,
      openOnFocus: false,
      clearable: false,
      errores_validacion: {},
      transaction:{ amount: 0 },
      nueva_entidad: {},
      elementToEdit: {},
      tipos_finanzas: [],
      procesos: [],
      proceso: null,
      tipo: null,
      unidades:[],
      codes: [],
      categorias_finanzas: [],
      category_transaction:null,
      documentos:[],
      isLoading:false,
      other_types:[],
      files: [],
      isDisabled: true,
      is_validated:false,
      show_other: false,
      show_third_step: false,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      activeStep: 0,
      tableDataKey: 0,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      tipos: [],
      tiposAnexo: [],
      currentPage: 1,
      people: [],
      person_selected: null,
      persona_sel: null,
      search_rut: "",
      search_dv: "",
      search_name: "",
      search_last_name: "",
      search_surname: "",
      file1: null,
      isCreateModal: false,
      canCancel: ['escape'],
      entidad_name: null,
      partner_entities:[],
      doc: null,
      isAddModalEntityActive: false,
      totalAmount: 0,
      indiceFile: 0,
      queryTypeModal: "create",
      estado: "",
      act: false,
      can_create_people: false,
      transaction_type: {name: "", id: null}

    }
  },
  watch: {
    documentos: {
      handler(newFiles) {
        // Aquí actualizamos el arreglo 'documentos' cuando se seleccionan nuevos archivos
        this.files = newFiles.map(file => {
          const existingDocumento = this.files.find(doc => doc.file.name === file.name);
          if (existingDocumento) {
            return {
              file: file,
              amount: existingDocumento.amount || null,
            };
          } else {
            return {
              file: file,
              amount: null,
            };
          }
        });
      },
      deep: true
    }
  },
  props:[
      'modalHeader','userId','queryType','transactionId','modalCancel', "unidadPrincipal", 'hideHeader','transactionTypeRute'
  ],
  async created(){
    await this.getTransactionTypes();
    this.transaction.unit_id = this.unidadPrincipal.id;
    await this.getCodes();
    
    if (this.queryType=='update'){
      this.getTransaction();
    }
  },
  methods:{
    async saveTransaction(){
      let vm = this;
      await vm.checkValidation();
      if (vm.is_validated){
        vm.isLoading=true;
        vm.transaction.sdt_transaction_type_id = vm.transaction_type.id;
        const formData = new FormData();
        var docs_aux = [];
        docs_aux = vm.files.slice();
        docs_aux.unshift({file: vm.file1, amount: null, tipoAnexo: vm.tipos[0], sdt_transaction_type_id: null, code: null, partner_entity_id: null}); 
        docs_aux.forEach((documento, index) => {
          if (index == 1){
            vm.transaction.code = documento.code
          }
          formData.append(`file${index}`, documento.file);
          formData.append(`amount${index}`, documento.amount);
          formData.append(`typeFile${index}`, documento.tipoAnexo.id);
          formData.append(`typeTransaction${index}`, documento.sdt_transaction_type_id);
          formData.append(`codeTransaction${index}`, documento.code);
          formData.append(`paymentDateTransaction${index}`, documento.payment_date);
          formData.append(`provTransaction${index}`, documento.partner_entity_id);
          formData.append(`otherTransaction${index}`, documento.other_id);
        });
        formData.append('transaction',JSON.stringify(vm.transaction));
        if (vm.queryType=="update"){
          axios
            .put("/sdt_transactions/"+vm.transaction.id+".json", formData)
            .then((res) => {
              
              this.$buefy.dialog.alert({
                message: "La rendición de finanzas fue actualizado con éxito.<br/>",
                confirmText: "Ok",            
              });
              res.data.payment_date = moment(res.data.payment_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
              vm.$emit("clickedUpdate",res.data);
              vm.$emit('close');
              vm.isLoading = false;
            })
            .catch(e => {
              vm.errores_validacion = e.response.data
              vm.isLoading=false;
          });   
        }
        else{
          axios
          .post("/sdt_transactions.json", formData)
          .then((res) => {
            vm.isLoading = false;
            this.$buefy.dialog.alert({
              message: "La transacción fue guardada correctamente <br/>",
              confirmText: "Ok",            
            });
            window.location.href = '/sdt_transactions/'+res.data["id"]
            vm.isLoading=false;
          })
          .catch(e => {
            vm.errores_validacion = e.response.data
            vm.isLoading=false;
        });   


        }

      }


    },
    async getTransactionTypes(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/sdt_transactions/new/"+vm.transactionTypeRute+".json")
        .then(res => {
          vm.tipos_finanzas = res.data.tipos_finanzas;
          vm.unidades = res.data.unidades;
          vm.can_create_people = res.data.can_create_people;
          vm.transaction_type = res.data.tipo_transaccion;
          vm.tipos = res.data.tipos_documentos.filter(elemento => elemento.name.includes(res.data.tipo_transaccion.name));
          vm.tiposAnexo =  res.data.tipos_documentos.filter(elemento => elemento.initials.startsWith('ANX'));
          vm.tiposAnexo = vm.tiposAnexo.map(elemento => ({
                                id: elemento.id,
                                name: elemento.name.replace(/^ANEXO \s*/, ''),
                                initials: elemento.initials
                              }));
          vm.transaction.proceso = res.data.proceso;
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    onClickChildEntity(value){
      this.isLoading = true
      this.nueva_entidad = value
      this.isLoading = false
    },
    getCodes() {
      let vm = this;
      vm.isLoading=true;      
      axios.get("/sdt_surpluses/unit-surplus/"+vm.transaction.unit_id+".json")
        .then(async (res) => {
          vm.transaction.sdt_surplus_id = null;
          vm.codes = res.data;          
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },    

    onClickChildDocumentUpdate(value){
      this.tableDataKey = this.tableDataKey + 1;
      this.files.push(value);
      this.totalAmount = this.files.reduce((total, item) => total + parseInt(item.amount, 10), 0);
      this.elementToEdit = {};
      if(this.tiposAnexo.length == 1){
        this.elementToEdit.tipoAnexo = this.tiposAnexo[0];
      }
      this.calculoMontos();
      this.queryTypeModal = "create"
      this.act = false;
      this.tableDataKey = this.tableDataKey + 1;

      
    },

    async checkValidationAmount() {
      this.totalAmount = this.files.reduce((total, item) => total + parseInt(item.amount, 10), 0);

      if (this.totalAmount != this.transaction.amount) {
        this.$buefy.dialog.alert({
          message: 'El monto de boletas y facturas debe coincidir con el monto ingresado en Paso 1.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.is_validated = false;
      }
    },

    


    async checkValidation() {
      this.is_validated = false;
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar los campos a rellenar.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
      else{
        this.is_validated = true;
      }
    },
    onClickChildDocument(docum){
      docum.id = this.indiceFile;
      this.files.push(docum);
      this.nueva_entidad = {};
      this.elementToEdit = {};
      this.totalAmount = this.files.reduce((total, item) => total + parseInt(item.amount, 10), 0);
      if(this.tiposAnexo.length == 1){
        this.elementToEdit.tipoAnexo = this.tiposAnexo[0];
      }
      this.calculoMontos();

      this.indiceFile = this.indiceFile + 1;
    },
    calculoMontos(){
      if (!isNaN(this.transaction.amount)) {
        if (this.transaction.amount - this.totalAmount > 0) {
          this.estado = "Monto pendiente a declarar: $" + Number(this.transaction.amount - this.totalAmount).toLocaleString('es-ES');
        } else if (this.transaction.amount - this.totalAmount == 0) {
          this.estado = "Monto coincidente por: $" + Number(this.transaction.amount).toLocaleString('es-ES') ;
        } else {
          this.estado = "Monto declarado superado por: $" + Number(this.totalAmount - this.transaction.amount).toLocaleString('es-ES');
        }
      }
    },
    getTransaction(){
      let vm = this;
      vm.isLoading=true;
      return axios
          .get("/sdt_transactions/"+this.transactionId+".json")
          .then(res => {
            vm.transaction = res.data;
            vm.transaction.payment_date = new Date(moment(vm.transaction.payment_date).format('MM/DD/YYYY'));
            vm.getDocuments();
            if(vm.transaction.partner_entity != "")
            {
              vm.entidad_name = vm.transaction.partner_entity.name;
              vm.transaction.partner_entity_id = vm.transaction.partner_entity.id;
            }
            if (vm.transaction.person != ""){
              vm.persona_sel = vm.transaction.person.person_name + " - RUT:  "+vm.transaction.person.run_integral
            }
            vm.isLoading=false;
          })
          .catch(e => {
            console.log(e);
            vm.isLoading=false;
          });
    },
    async getDocuments() {
      let vm = this;
      await axios.get("/sdt_transactions/documents/"+vm.transactionId+".json")
        .then(res => {
          let filesRec = res.data.documents;
          vm.file1 = filesRec[0];
          vm.tableDataKey = vm.tableDataKey + 1;
          filesRec = filesRec.map(elemento => ({
                                id: elemento.id,
                                file: elemento,
                                amount: elemento.transaction.amount,
                                code: elemento.transaction.code,
                                tipoAnexo: elemento.document_type.id,
                                sdt_transaction_type_id: elemento.transaction.sdt_transaction_type_id,
                                partner_entity_id: elemento.transaction.partner_entity_id,
                                other_id: elemento.transaction.sdt_description_type,
                              }));
          filesRec.forEach((documento, index) => {
            if (index != 0){
              documento.tipoAnexo.name = documento.tipoAnexo.name.replace(/^ANEXO \s*/, '');
              vm.files.push(documento);
            }
          });
          vm.tableDataKey = vm.tableDataKey + 1;
        })

        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    onClickEntity(){
      this.isAddModalEntityActive = true;
      
    },
    
    clean(){
      this.isLoading = true
      this.search_surname = ""
      this.search_dv = ""
      this.search_last_name = ""
      this.search_name = ""
      this.search_rut = ""
      this.isLoading = false
    },
    editFile(row){
      this.queryTypeModal = "update"
      this.elementToEdit = row;
      this.act = true;
      var indic = this.files.indexOf(row);
      this.files.splice(indic, 1);
      this.queryTypeModal = "update"

    },
    capitalizarTexto(texto) {
      if (texto.length>0){
        if (texto[0].name != null)
          return texto[0].name.charAt(0).toUpperCase() + texto[0].name.slice(1).toLowerCase();
        else
          return false
      }
    },
    buscar_persona(){
      this.isLoading = true
      if(this.search_rut==="" && this.search_dv ==="" && this.name ==="" && this.search_last_name ==="" && this.search_surname ===""){
        return this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Debe llenar algún campo para realizar una búsqueda',
          type: 'is-warning',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
      else{
        return axios.get('/sdt_transactions/search-by-rut-names.json', {
          params: {
            rut: this.search_rut,
            dv: this.search_dv,
            names: this.search_name,
            last_name: this.search_last_name,
            surname: this.search_surname
          }
        }).then(response=>{
          this.people = response.data
          if(this.people.length === 0){
            this.isLoading=false;
            return this.$buefy.dialog.alert({
              title: 'Error',
              message: 'No se encontraron resultados para su búsqueda',
              type: 'is-warning',
              hasIcon: true,
              icon: 'exclamation-triangle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          }          
          this.isLoading=false;

            })
            .catch(error => {
              console.log("error")
              console.log(error.response)
              this.errores_validacion = error.response.data
              console.log(this.errores_validacion)
              this.isLoading=false;
        })
      }
    },

    selectPerson(id){
      this.person_selected = this.people.find(x => x.id == id)
      this.transaction.person_id = this.person_selected.id
      this.persona_sel = this.person_selected.name +" "+ this.person_selected.last_name + " - RUT:  "+this.person_selected.integral 
    },
    deleteFileTab(dropFile){
      var indic = this.files.indexOf(dropFile);
        //this.tableDataKey = this.tableDataKey + 1;
      this.files.splice(indic, 1);
      this.totalAmount = this.files.reduce((total, item) => total + parseInt(item.amount, 10), 0);
      this.calculoMontos();
        //this.documentos.splice(index,1);
        //this.tableDataKey = this.tableDataKey + 1;
      
    },

    addExternalPerson(){
      this.isLoading= true
      this.isCreateModal = true
      this.isLoading = false
    },
    onClickChild(value){
      value.integral = value.run_integral;
      this.people.push(value);
    },

    


  }
}
</script>

<style scoped>

  .right-button{
    margin-left: auto;
  }
  .left-button{
    margin-right: auto;
  }
  .new-h2 {
    font-size: 24px;
    font-weight: bold;
  }


</style>