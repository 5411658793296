<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(rectify)">
        <b-steps
            v-model="activeStep"
            :animated="isAnimated"
            :rounded="isRounded"
            :has-navigation="hasNavigation"
            :icon-prev="prevIcon"
            :icon-next="nextIcon"
            :label-position="labelPosition"
            :mobile-mode="mobileMode">
          <b-step-item step="1" label="Edición Nombramiento" :clickable="isStepsClickable">
            <!-- Box de Formulario Appointment -->
            <div class="is-centered" style="margin-top: 30px;">
              <div class="title is-4 is-centered" style="text-align: center; margin-bottom: 20px">
                Nombramiento
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion">
                      {{ item[0] }}
                    </div>
                  </b-message>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción*">
                  <ValidationProvider rules="required|min:5|max:200" v-slot="{ errors }">
                    <b-input v-model="person_unit.description" type="textarea"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de documento">
                  <multiselect v-model="person_unit.resolution_type_id"
                               :options="resolution_types.map(type => type.id)"
                               :custom-label="opt => resolution_types.find(x => x.id === opt).name"
                               placeholder="Seleccione tipo de documento"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                               :allow-empty="true"
                               :disabled="disable_no_effect">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Número de documento">
                  <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                    <b-input v-model="person_unit.resolution" type="text" :disabled="disable_no_effect"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de documento">
                  <b-datepicker
                      v-model="person_unit.resolution_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                      :disabled="disable_no_effect">
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de Nombramiento*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.appointment_type_id"
                                 :options="appointment_types.map(type => type.id)"
                                 :custom-label="opt => appointment_types.find(x => x.id === opt).name"
                                 placeholder="Seleccione tipo de nombramiento"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false"
                                 :disabled="disable_no_effect">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Cargo*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.work_plant_id"
                                 :options="work_plants.map(type => type.id)"
                                 :custom-label="opt => work_plants.find(x => x.id === opt).name"
                                 placeholder="Seleccione un cargo"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false"
                                 :disabled="disable_no_effect">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Categoría*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.category_id"
                                 :options="categories.map(type => type.id)"
                                 :custom-label="opt => categories.find(x => x.id === opt).name"
                                 placeholder="Seleccione una categoría"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false"
                                 :disabled="disable_no_effect">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Unidad de contratación*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.contracting_unit_id"
                                 :options="contract_units.map(type => type.id)"
                                 :custom-label="opt => contract_units.find(x => x.id === opt).name"
                                 placeholder="Seleccione una unidad"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false"
                                 :disabled="disable_no_effect">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">

                <b-field label="Unidad de desempeño*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="person_unit.unit_id"
                                 :options="units.map(type => type.id)"
                                 :custom-label="opt => units.find(x => x.id === opt).name"
                                 placeholder="Seleccione una unidad"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false"
                                 :disabled="disable_no_effect">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Calidad de contrato">
                  <multiselect v-model="person_unit.quality"
                               :options="qualities.map(type => type.name)"
                               placeholder="Seleccione calidad de contrato"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                               :allow-empty="true"
                               :disabled="disable_no_effect">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Desde*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-datepicker
                        v-model="person_unit.start_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                        :disabled="disable_no_effect">
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Hasta">
                  <b-datepicker
                      v-model="person_unit.end_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                      :disabled="disable_no_effect">
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Url Documentación">
                  <ValidationProvider v-slot="{ errors }">
                    <b-input v-model="person_unit.documentation_link" type="text" :disabled="disable_no_effect"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="container">
              <section>
                <div class="columns">
                  <div class="column">
                    <b-field>
                      <template #label>
                        Asignaturas

                      </template>
                    </b-field>
                  </div>
                  <div class="column" align="right" style="text-align:right;">
                    <b-button class="mt-2" @click.prevent="addHrSubject" type="is-primary" :disabled="!partial">Añadir Asignatura</b-button>
                  </div>
                </div>
                <b-table
                    :data="selected_hr_subjects"
                    :paginated=true
                    :bordered="true"
                    per-page=3
                    striped
                    hoverable
                    :current-page="currentPage"
                    :pagination-simple=false
                    pagination-position="bottom"
                    default-sort-direction="asc"
                    sort-icon="arrow-up"
                    sort-icon-size="is-small"
                    default-sort="publication.title"
                    aria-next-label="Siguiente"
                    aria-previous-label="Anterior"
                    aria-page-label="Página"
                    aria-current-label="Página actual">
                  <b-table-column
                      field= 'name'
                      width="30em"
                      sortable
                  >
                    <template v-slot:header="{ column }">
                      <b-tooltip
                          label="Presione Enter luego de escribir el nombre de la asignatura para que sea agregada"
                          position="is-right"
                          size="is-medium"
                          multilined>
                        {{"Nombre de la asignatura*" }}
                        <b-icon
                            pack="fas"
                            icon="info-circle"
                            size="is-small"
                            type="is-info">
                        </b-icon>
                      </b-tooltip>
                    </template>
                    <template v-slot="props">
                      <b-field>
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <b-taginput
                              v-model="props.row.subject"
                              :data="filtered_hr_subjects"
                              type="is-primary is-light"
                              maxtags="1"
                              autocomplete
                              maxlength="155"
                              :allow-new="true"
                              field="name"
                              icon="tag"
                              placeholder="Agregar o escoger asignaturas"
                              @input = "getPsCode(props.row)"
                              @typing="getFilteredHRSubjects"
                              :disabled="!partial">
                          </b-taginput>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </template>
                  </b-table-column>
                  <b-table-column
                      field= 'hours'
                      label= 'Horas de Asignatura*'
                      sortable
                      v-slot="props"
                  >
                    <b-field>
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <b-input v-model="props.row.hours" type="text" min="0" @input="updateHours" :disabled="!partial"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>

                    </b-field>
                  </b-table-column>
                  <b-table-column
                      field= 'ps_code'
                      label= 'Código PeopleSoft'
                      sortable
                      v-slot="props"
                  >
                    <b-field>
                      <ValidationProvider v-slot="{errors}">
                        <b-input minlength="0" maxlength="155" v-model="props.row.ps_code" type="text" :disabled="props.row.ps_code_disabled || !partial"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </b-table-column>
                  <b-table-column
                      field= 'siac_code'
                      label= 'Código SIAC'
                      sortable
                      v-slot="props"
                  >
                    <b-field>
                      <ValidationProvider v-slot="{errors}">
                        <b-input minlength="0" maxlength="155" v-model="props.row.siac_code" type="text" :disabled="props.row.siac_code_disabled || !partial"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </b-table-column>
                  <b-table-column
                      label="Acciones"
                      v-slot="props"
                  >
                    <b-icon
                        pack="fas"
                        style="color: #e50000;"
                        icon="trash"
                        size="is-medium"
                        v-if="partial"
                        @click.native="deleteHrSubject(props.row)"/>
                  </b-table-column>

                </b-table>
              </section>
            </div>
            <div class="columns">
              <div class="column">
                <b-field>
                  <template #label>
                    Jornada (horas)
                    <b-tooltip label="Calculado automaticamente en base a la suma de las horas por asignatura"
                               position="is-right">
                      <b-icon
                          pack="fas"
                          icon="info-circle"
                          type="is-info">
                      </b-icon>
                    </b-tooltip>
                  </template>
                  <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                    <b-input v-model="person_unit.taken_hours" type="number" :disabled="true"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Año">
                  <ValidationProvider :rules="{numeric: true, integer: true, positive: true, min_value: 1980, max_value: year_date}" v-slot="{ errors }">
                    <b-input  v-model="person_unit.year"  type="text" :disabled="disable_no_effect">
                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Semestre">
                  <ValidationProvider rules="numeric|integer|positive|min_value:1|max_value:2" v-slot="{ errors }">
                    <b-input  v-model="person_unit.semester" type="text" :disabled="disable_no_effect">
                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
          </b-step-item>
          <b-step-item step="2" :label="'Datos ' +form_name" :clickable="isStepsClickable">
            <div class="is-centered" style="margin-top: 30px;">
              <div class="title is-4 is-centered" style="text-align: center; margin-bottom: 20px">
                {{form_name}}
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de documento">
                  <multiselect v-model="event.resolution_type_id"
                               :options="resolution_types.map(type => type.id)"
                               :custom-label="opt => resolution_types.find(x => x.id === opt).name"
                               placeholder="Seleccione tipo de documento"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                               :allow-empty="true">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                          No se encontraron elementos.
                      </span>
                  </multiselect>
                </b-field>
              </div>
              <div class="column">
                <b-field label="N° Documento">
                  <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                    <b-input v-model="event.decree" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Documento">
                  <b-datepicker
                      v-model="event.decree_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field :label="'Fecha '+form_name+'*'">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-datepicker
                        v-model="event.start_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                    >
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field :label="'Url carpeta documentación ' +form_name">
                  <ValidationProvider v-slot="{ errors }" rules="max:255">
                    <b-input v-model="event.documentation_link" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input ref="input_descripcion" v-model="event.description" type="textarea" minlength="10" maxlength="4000"
                             icon-right="sync-alt"
                             icon-right-clickable
                             @icon-right-click="updateDescription"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="has-text-right is-grouped">
              <button class="button is-primary" type="submit">Guardar {{ form_name }}</button>
            </div>
          </b-step-item>
        </b-steps>
      </form>
    </ValidationObserver>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="isAlertModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal-confirm-duplication :person-id="person_unit.person_id"
                                   :id="person_unit.id"
                                   :person_unit="person_unit"
                                   modal-header="Confirmar Nombramiento"
                                   @close="props.close"
                                   @clicked_save="onClickSave"
                                   @clicked_duplicate="onClickDuplicate">
        </modal-confirm-duplication>
      </template>
    </b-modal>
  </section>
</template>

<script>
import axios from "axios";
import ModalConfirmDuplication from "../modal_confirm_duplication";
import {compareObjects, formatString} from "../../../packs/utilities";
import _ from "lodash";

export default {
  name: "no_effect_appointment_hourclass",
  props:[
    'queryType', 'personId', 'id', 'modalHeader', 'form_name', 'event_type_id', 'partial'
  ],
  components:{
    ModalConfirmDuplication
  },
  data(){
    return {
      event:{
        event_type_id: null,
        person_unit_id: this.id
      },
      person_unit:{
        is_hours:true,
        pu_relation_type_id: 1,
      },
      old_person_unit:{
        is_academic:true
      },
      pu_relation_type_id: 1,
      years:[],
      qualities: [{name: "CONTRATA"},{name: "PLANTA"},{name: "AD-HONOREM"}, {name: "INTERINO"}, {name: "SUPLENTE"}],
      appointment_types:[],
      work_plants:[],
      grade: {},
      rank: {},
      hr_subject_attributes: [],
      resolution_types:[],
      categories: [],
      selected_hr_subjects:[],
      hierarchies: [],
      units: [],
      contract_units: [],
      working_days:[],
      errores_validacion:{},
      documentation_link:'',
      establishments:[],
      isLoading: true,
      isDistanceModalActive: false,
      link: false,
      distances: [],
      activeStep: 0, //bsteps
      showSocial: false,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      hasNavigation: true,
      customNavigation: false,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      event_types: [],
      performance_areas: [],
      performance_area: null,
      grades: [],
      levels: [],
      options: [{type: 'Dejar de Servir', distances: []}, {type: 'Término de Contrato', distances: []}, {type: 'Renuncia', distances: []}],
      num_hr_subjects: 0,
      currentPage:1,
      cardLayout:false,
      allow_duplication: false,
      isAlertModalActive: false,
      disable_rectification: false,
      disable_reduce_hours: false,
      disable_add_hours: false,
      disable_change_cc: false,
      year_date: new Date().getFullYear(),
      filtered_hr_subjects: [],
      can_update_hr_subjects: false,
      disable_no_effect: false
    }
  },
  created() {
    this.isLoading=true;
    axios
        .get("/life_record/rectify/"+this.id+".json")
        .then(res => {
          //this.old_person_unit = res.data["person_unit"]
          this.person_unit = res.data["person_unit"]
          if(res.data["person_unit"]["start_date"]!==null){
            this.person_unit.start_date = new Date(this.formatDate(res.data["person_unit"]["start_date"]))
          }
          if(res.data["person_unit"]["end_date"]!==null){
            this.person_unit.end_date = new Date(this.formatDate(res.data["person_unit"]["end_date"]))
          }
          if(res.data["person_unit"]["decree_date"]!==null){
            this.person_unit.decree_date = new Date(this.formatDate(res.data["person_unit"]["decree_date"]))
          }
          if(res.data["person_unit"]["resolution_date"]!==null){
            this.person_unit.resolution_date = new Date(this.formatDate(res.data["person_unit"]["resolution_date"]))
          }
          if(this.person_unit.performance_area){
            this.performance_area = [this.person_unit.performance_area]
          }
          this.can_update_hr_subjects = res.data["person_unit"]["can_update_hr_subjects"]
          if (this.person_unit && this.person_unit.hr_subjects){
            for(var i=0;i<this.person_unit.hr_subjects.length;i++){

              this.selected_hr_subjects.push({id:this.person_unit.hr_subjects[i].id,
                name: this.person_unit.hr_subjects[i].name, ps_code: this.person_unit.hr_subjects[i].ps_code, siac_code: this.person_unit.hr_subjects[i].siac_code, siac_code_disabled: false,
                hours: this.person_unit.has_hr_subjects[i].hours, index: i, ps_code_disabled: false,
                subject: [this.person_unit.hr_subjects[i].name]})
            }
          }
          this.old_person_unit = _.cloneDeep(this.person_unit);
          this.num_hr_subjects = this.person_unit.hr_subjects.length
          this.units = res.data["person_unit"]["units"]
          this.contract_units = res.data["person_unit"]["contract_units"]
          this.grade = res.data["person_unit"]["grade"]
          this.rank = res.data["person_unit"]["rank"]
          this.categories = res.data["person_unit"]["categories"]
          this.hierarchies = res.data["person_unit"]["hierarchies"]
          this.resolution_types = res.data["person_unit"]["resolution_types"]
          this.work_plants = res.data["person_unit"]["work_plants"]
          this.appointment_types = res.data["person_unit"]["appointment_types"]
          this.working_days = res.data["person_unit"]["working_days"]
          this.documentation_link = res.data["person_unit"]["documentation_link"]
          this.grades = res.data["person_unit"]["grades"]
          this.levels = res.data["person_unit"]["levels"]
          this.performance_areas = res.data["person_unit"]["performance_areas"]
          this.establishments = res.data["person_unit"]["establishments"]
          this.event_types = res.data["event_types"]
          this.event.event_type_id = this.event_type_id //RECTIFICACION, AUMENTO DE HORAS, REDUCCION DE HORAS
          if(parseInt(this.event_type_id) === 10){//reduccion de horas
            this.disable_reduce_hours = true
          }
          else if(parseInt(this.event_type_id) === 11){//aumento de horas
            this.disable_add_hours = true
          }
          else if(parseInt(this.event_type_id) === 12){//cambio de cc
            this.disable_change_cc = true
          }
          else if(parseInt(this.event_type_id) === 13){//sin efecto
            this.disable_no_effect = true
            if(!this.partial){
              this.person_unit.pu_relation_type_id = 5
            }
          }
          this.event.person_unit_id = this.person_unit.id
          this.event.person_id = this.person_unit.person_id
          this.hr_subjects = res.data["person_unit"]["all_hr_subjects"]
          this.isLoading = false;
        }).catch(error => {
      console.log(error)
      this.isLoading=false;
    })

  },
  methods: {
    getPsCode(row){
      var newIndex = this.selected_hr_subjects.findIndex(subject => subject.index ===row.index);
      if(row.subject[0] === undefined){
        this.selected_hr_subjects[newIndex].name = null
        this.selected_hr_subjects[newIndex].id = null
        this.selected_hr_subjects[newIndex].ps_code = null
        this.selected_hr_subjects[newIndex].hours = null
        this.selected_hr_subjects[newIndex].ps_code_disabled = false
        this.selected_hr_subjects[newIndex].siac_code = null
        this.selected_hr_subjects[newIndex].siac_code_disabled = false
      }
      else if(row.subject[0].id && row.subject[0].ps_code){
        this.selected_hr_subjects[newIndex].name = row.subject[0].name
        this.selected_hr_subjects[newIndex].id = row.subject[0].id
        this.selected_hr_subjects[newIndex].ps_code = row.subject[0].ps_code
        this.selected_hr_subjects[newIndex].ps_code_disabled = false
        this.selected_hr_subjects[newIndex].siac_code = row.subject[0].siac_code
        this.selected_hr_subjects[newIndex].siac_code_disabled = false
      }
      else if(row.subject[0].id ===undefined && row.subject[0].ps_code === undefined){
        this.selected_hr_subjects[newIndex].name = row.subject[0]
      }
      else if(row.subject[0].id && (row.subject[0].ps_code===undefined || row.subject[0].ps_code===null)){
        this.selected_hr_subjects[newIndex].name = row.subject[0].name
        this.selected_hr_subjects[newIndex].id = row.subject[0].id
        this.selected_hr_subjects[newIndex].ps_code = row.subject[0].ps_code
        this.selected_hr_subjects[newIndex].ps_code_disabled = false
        this.selected_hr_subjects[newIndex].siac_code = row.subject[0].siac_code
        this.selected_hr_subjects[newIndex].siac_code_disabled = false
      }
    },
    updateHours(){
      var hours = 0
      for(var i = 0;i<this.selected_hr_subjects.length;i++){
        hours = hours + parseInt(this.selected_hr_subjects[i].hours)
      }
      this.person_unit.taken_hours = parseInt(hours)
    },
    addHrSubject(){
      this.hr_subject_attributes.push({
        index:  this.num_hr_subjects,
        subject: null,
        _destroy:null,
        ps_code_disabled: false,
        siac_code_disabled: false
      })
      this.selected_hr_subjects.push({
        index:  this.num_hr_subjects,
        name: null,
        id: null,
        ps_code: null,
        hours: null,
        _destroy: null,
        ps_code_disabled: false,
        siac_code_disabled: false,
        siac_code: null
      })
      this.num_hr_subjects =  this.num_hr_subjects+1
    },
    deleteHrSubject(hr_subject){
      this.hr_subject_attributes = this.hr_subject_attributes.filter(subject => subject.index != hr_subject.index);
      this.selected_hr_subjects = this.selected_hr_subjects.filter(subject => subject.index != hr_subject.index);
      this.updateHours()
    },
    formatString,
    compareObjects,
    updateDescription(){
      this.isLoading=true
      this.person_unit.hr_subjects = this.selected_hr_subjects;
      let description = compareObjects(this.old_person_unit, this.person_unit, ["description", "function_name", "work_plants", "units",
        "contract_units", "establishments", "hierarchies", "working_days", "levels", "grades", "establishment", "function",
        "work_plant", "has_hr_subjects", "days", "appointment_days", "appointment_type",
        "performance_areas", "functions", "rank", "grade", "appointment_types", "categories", "resolution_types", "all_hr_subjects", "text_schedule"])

      this.$refs.input_descripcion.updateValue(formatString(description))
      this.isLoading = false
    },
    rectify(){
      this.isLoading=true;
      let personUnitEstablishment = this.establishments.find(obj => obj.name == "PROFESORES HORAS DE CLASES");
      if (personUnitEstablishment)
        this.person_unit.establishment_id = personUnitEstablishment.id;

      this.person_unit.hr_subjects = this.selected_hr_subjects;
      if(parseInt(this.event_type_id) === 13 && !this.partial) {//sin efecto COMPLETO
        console.log("GUARDANDO SIN EFECTO")
        this.person_unit.pu_relation_type_id = 5
      }
      let clean_person_unit = this.person_unit
      clean_person_unit = this.deleteData(clean_person_unit)
      axios
          .put("/admin/person_units/" + this.id+".json", {
            person_unit:  clean_person_unit, selected_hr_subjects: this.selected_hr_subjects,
            allow_duplication: this.allow_duplication
          })
          .then(response => {
            this.event.end_date = this.event.start_date
            axios
                .post("/admin/events.json", {
                  event: this.event
                })
                .then(response =>{
                  this.errores_validacion = {};
                  this.$buefy.dialog.alert({
                    message: 'Deja sin efecto fue creado con éxito ',
                    confirmText: 'Ok'
                  })
                  this.person_unit = {}
                  this.isLoading=false;
                  this.errors = [];
                  window.location.href="/people/"+this.event.person_id+"/life_record"
                }).catch(error => {
              this.errores_validacion = error.response.data
              this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Hubo errores al agregar el deja sin efecto, favor revisar formato',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              this.isLoading=false;
            })
          }).catch(error => {
        this.errores_validacion = error.response.data
        if(this.errores_validacion["fechas_nombramiento"]){
          this.isAlertModalActive = true;
          this.person_unit.date_validation = true
          this.allow_duplication = false
          this.isLoading=false;
        }
        else if(this.errores_validacion["fechas_exactas_nombramiento"]){
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al dejar sin efecto el nombramiento, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.person_unit.date_validation = true
          this.allow_duplication = false
          this.isLoading=false;
        }
        else{
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al dejar sin efecto el nombramiento, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.person_unit.date_validation = true
          this.allow_duplication = false
          this.isLoading=false;
        }
      })
    },
    deleteData(person_unit){
      delete person_unit.units
      delete person_unit.internal_positions
      delete person_unit.hierarchies
      delete person_unit.appointment_types
      return person_unit
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
    getFilteredHRSubjects(text) {
      this.filtered_hr_subjects = this.hr_subjects.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    onClickSave(value){
      this.person_unit.date_validation = value
      this.saveAppointment()
      console.log("saveeee")
    },
    onClickDuplicate(value){
      this.allow_duplication = value
      this.saveAppointment()
      console.log("duplicateee")
    }
  }
}
</script>
<style>
#description_rectify .control.has-icons-right .icon {
  color: #7957d5;
}
.control.has-icons-right .icon {
  color: #7957d5;
}
</style>