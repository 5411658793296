<template>
    <div class="configurationactivitytypenewmodal">
        <form action="">
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title" v-if='edit_case == 0'>Nuevo tipo de actividad</p>
                    <p class="modal-card-title" v-if='edit_case == 1'>Editar tipo de actividad</p>
                    <button
                        type="button"
                        class="delete"
                        @click="$emit('close')"/>
                </header>
                <section class="modal-card-body" v-if='edit_case == 0'>
                    <b-field 
                        label="Nombre"
                        :type="{ 'is-danger': checkActivityTypeName }"
                        :message="{ 'Ingrese un nombre para el tipo de actividad.': checkActivityTypeName }">
                        <b-input
                            :value='activityTypeName'
                            v-model='activityTypeName'
                            placeholder="Nombre del tipo de actividad"
                            required>
                        </b-input>
                    </b-field>

                    <b-field label="Descripción"
                        :type="{ 'is-danger': checkActivityTypeDescription }"
                        :message="{ 'Ingrese un nombre para el tipo de actividad.': checkActivityTypeDescription }">
                        <b-input
                            type="textarea"
                            :value='activityTypeDescription'
                            v-model='activityTypeDescription'
                            placeholder="Ingrese una breve descripción"
                            required>
                        </b-input>
                    </b-field>
                </section>
                <section class="modal-card-body" v-if='edit_case == 1'>
                    <b-field label="Nombre"
                        :type="{ 'is-danger': checkActivityTypeNameEdit }"
                        :message="{ 'Ingrese un nombre para el tipo de actividad.': checkActivityTypeNameEdit }">
                        <b-input
                            :value='activityTypeNameEdit'
                            v-model='activityTypeNameEdit'
                            placeholder="Nombre del tipo de actividad"
                            required>
                        </b-input>
                    </b-field>

                    <b-field label="Descripción"
                        :type="{ 'is-danger': checkActivityTypeDescriptionEdit }"
                        :message="{ 'Ingrese un nombre para el tipo de actividad.': checkActivityTypeDescriptionEdit }">
                        <b-input
                            type="textarea"
                            :value='activityTypeDescriptionEdit'
                            v-model='activityTypeDescriptionEdit'
                            placeholder="Ingrese una breve descripción"
                            required>
                        </b-input>
                    </b-field>
                </section>
                <footer class="modal-card-foot">
                    <b-button
                        label="Cerrar"
                        @click="closeModal()" />
                    <b-button v-if='edit_case == 0'
                        label="Añadir"
                        type="is-primary" 
                        @click="addNewActivityType()"
                        :loading='checkSubmiting'/>
                    <b-button v-if='edit_case == 1'
                        label="Guardar cambios"
                        type="is-primary" 
                        @click="editActivityType()"
                        :loading='checkSubmiting'/>
                </footer>
            </div>
        </form>
    </div>
</template>
<script>
//import axios from 'axios'
export default {
    name: 'ConfigurationActivityTypeNewModal',
    props:{
        activity_type_edit:{
            type: Object
        },
        edit_case:{
            type: Number
        }
    },
    data(){
        return {
            new_activity_type:{
                name: '',
                description: ''
            },
            name: '',
            description: '',
            validations:{
                isActivityTypeNameEmpty: true,
                isActivityTypeDescriptionEmpty: true,
                firstTimeActivityTypeName: true,
                firstTimeActivityTypeDescription: true
            },
            isSubmiting: false
        }
    },
    computed:{
        // Añadir
        activityTypeName:{
            get: function(){
                return this.new_activity_type.name
            },
            set: function(newActivityTypeName){
                this.validations.firstTimeActivityTypeName = false
                if(newActivityTypeName === '')
                    this.validations.isActivityTypeNameEmpty = true
                else
                    this.validations.isActivityTypeNameEmpty = false
                this.new_activity_type.name = newActivityTypeName
            }
        },
        activityTypeDescription:{
            get: function(){
                return this.new_activity_type.description
            },
            set: function(newActivityTypeDescription){
                this.validations.firstTimeActivityTypeDescription = false
                if(newActivityTypeDescription === '')
                    this.validations.isActivityTypeDescriptionEmpty = true
                else
                    this.validations.isActivityTypeDescriptionEmpty = false
                this.new_activity_type.description = newActivityTypeDescription
            }
        },
        checkActivityTypeName(){
            if(this.validations.firstTimeActivityTypeName === false)
                return this.validations.isActivityTypeNameEmpty
            else
                return false
        },
        checkActivityTypeDescription(){
            if(this.validations.firstTimeActivityTypeDescription === false)
                return this.validations.isActivityTypeDescriptionEmpty
            else
                return false
        },
        // Editar
        activityTypeNameEdit:{
            get: function(){
                return this.activity_type_edit.name
            },
            set: function(newActivityTypeName){
                if(newActivityTypeName === '')
                    this.validations.isActivityTypeNameEmpty = true
                else
                    this.validations.isActivityTypeNameEmpty = false
                this.activity_type_edit.name = newActivityTypeName
            }
        },
        activityTypeDescriptionEdit:{
            get: function(){
                return this.activity_type_edit.description
            },
            set: function(newActivityTypeDescription){
                if(newActivityTypeDescription === '')
                    this.validations.isActivityTypeDescriptionEmpty = true
                else
                    this.validations.isActivityTypeDescriptionEmpty = false
                this.activity_type_edit.description = newActivityTypeDescription
            }
        },
        checkActivityTypeNameEdit(){
            return this.validations.isActivityTypeNameEmpty
        },
        checkActivityTypeDescriptionEdit(){
            return this.validations.isActivityTypeDescriptionEmpty
        },
        checkSubmiting: {
            get: function(){
                return this.isSubmiting
            },
            set: function(newState){
                this.isSubmiting = newState
            }
        }
    },
    beforeMount(){
        this.name = this.activity_type_edit.name
        this.description = this.activity_type_edit.description
        if(this.edit_case === 1){
            this.validations.isActivityTypeNameEmpty        = false
            this.validations.isActivityTypeDescriptionEmpty = false
        }
    },
    methods:{
        checkEmptyFields(){
            if(this.validations.firstTimeActivityTypeName === true){
                this.validations.firstTimeActivityTypeName = false
            }
            if(this.validations.firstTimeActivityTypeDescription === true){
                this.validations.firstTimeActivityTypeDescription = false
            }
        },
        checkSave(){
            var confirmation = true
            var arrayConfirmation = []
            arrayConfirmation.push(this.validations.isActivityTypeNameEmpty)
            arrayConfirmation.push(this.validations.isActivityTypeDescriptionEmpty)
            arrayConfirmation.forEach(function(element){
                if(element === true)
                    confirmation = false
            })
            return new Promise((resolve) => {
                resolve(confirmation)
            })
        },
        async addNewActivityType(){
            this.checkSubmiting = true
            var confirmacion = await this.checkSave()
            if(confirmacion){
                this.$emit('add-new-activity-type', this.new_activity_type);
                this.$emit('close');
            }
            else{
                this.checkEmptyFields();
                this.checkSubmiting = false
            }
        },
        async editActivityType(){
            this.checkSubmiting = true
            var confirmacion = await this.checkSave()
            if(confirmacion){
                this.$emit('edit-activity-type', this.activity_type_edit);
                this.$emit('close');
                this.checkSubmiting = false
            }
            else{
                this.checkEmptyFields();
                this.checkSubmiting = false
            }
        },
        closeModal(){
            if(this.edit_case === 1){
                this.activityTypeNameEdit = this.name
                this.activityTypeDescriptionEdit = this.description
                this.$emit('close');
            }
            else{
                this.$emit('close');
            }
        }
    }
}
</script>