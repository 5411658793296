<template>
  <section>
    <div class="columns">
      <div class="column has-text-right">
        <b-field>
          <b-switch v-model="cardLayout">
          <span>
            Formato tarjeta (sin filtros ni búsqueda)
             <b-tooltip
                 label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                 multilined
                 position="is-left">
                  <b-icon
                      pack="fas"
                      icon="info-circle"
                      type="is-info">
                  </b-icon>
              </b-tooltip>
          </span>
          </b-switch>
        </b-field>

      </div>
    </div>

    <div v-if="project.is_creator && project.add_associated_entities_permission && project.academic_activity_state_id!=2" class="columns">
      <div class="column has-text-right">
        <b-button type="is-primary" icon-left="plus" @click="openModal">Asociar nueva entidad</b-button>
      </div>
    </div>

    <b-table
        :data="entities"
        :paginated=true
        per-page=10
        striped
        hoverable
        :current-page="currentPage"
        :card-layout="cardLayout"
        :pagination-simple=false
        pagination-position="bottom"
        default-sort-direction="desc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="last_patent_state_date"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página actual">

      <b-table-column
          field= 'name'
          label= 'Nombre Entidad'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          <a @click="goToEntity(props.row)"><i class="fas fa-industry"></i> {{ props.row.name }}</a>
        </template>
      </b-table-column>
      <b-table-column
          field= 'association_type.name'
          label= 'Tipo Asociación'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{  props.row.association_type ? props.row.association_type.name : '' }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'contact_name'
          label= 'Nombre contacto'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.contact_name }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'contact_email'
          label= 'Correo contacto'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.contact_email }}
        </template>
      </b-table-column>

      <b-table-column
          v-if="project.academic_activity_state_id!=2"
          label="Acciones"
          v-slot="props">
        <b-button type="is-primary is-light"
                  icon-right="eye"
                  @click.native="showEntity(props.row)">
        </b-button>
        <b-button v-if="project.is_creator"
                  type="is-info"
                  icon-right="edit"
                  @click.native="editEntity(props.row)">
        </b-button>
        <b-button v-if="project.is_creator"
                  type="is-danger"
                  icon-right="trash"
                  @click.native="deleteEntityButton(props.row)">
        </b-button>
      </b-table-column>




      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-danger">
              No se encontraron entidades asociadas.
            </b-message>
          </div>
        </div>
      </template>

    </b-table>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

    <b-modal
        width="90%"
        aria-modal
        trap-focus
        v-model="activeModal"
        :can-cancel="['escape','x']"
    >
      <template #default="props">
        <associated-entity-modal
            :entity-id="entityId"
            :project="project"
            :modal-header="modalHeader"
            :save-button-name="saveButtonName"
            :action-type="actionType"
            @close="closeModal"
            @updateEntity="updateEntity"
            @associateEntity="associateEntity"></associated-entity-modal>
      </template>
    </b-modal>

    <b-modal
        width="90%"
        aria-modal
        trap-focus
        v-model="activeShowModal"
        :can-cancel="['escape','x']">
      <template #default="props">
        <show-associated-entity v-bind:modal-header="'Entidad Asociada'" :project="project"
                                :partner_entity="showPartnerEntity" @close="closeShowModal"></show-associated-entity>
      </template>
    </b-modal>



  </section>

</template>

<script>
import AssociatedEntityModal from './AssociatedEntityModal.vue';
import ShowAssociatedEntity from './ShowAssociatedEntity';
import axios from "axios";
export default {
  name: "AssociatedEntitiesTable",
  components: { AssociatedEntityModal, ShowAssociatedEntity },
  props:[ 'entities','project',],
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      isComponentModalActive:false,
      activeModal:false,
      entityId:null,
      errores_validacion:{},
      modalHeader:"",
      saveButtonName:"",
      actionType:"",
      activeShowModal:false,
      showPartnerEntity:{},
    }
  },
  methods:{
    openModal(){
      this.modalHeader = "Asociar Entidad"
      this.saveButtonName = "Asociar Entidad"
      this.actionType = "create"
      this.activeModal = true;
    },
    closeModal(){
      this.activeModal = false;
      this.modalHeader = "";
      this.saveButtonName = "";
      this.actionType = "";
      this.entityId = null;
      this.showPartnerEntity = {};

    },
    closeShowModal(){
      this.activeShowModal = false;
      this.entityId = null;


    },
    associateEntity(entity){
      //this.entities.push(entity)
      this.$emit("updateEntity");
    },
    deleteEntityButton(entity){

      this.$buefy.dialog.confirm({
        title: 'Desvincular Entidad Externa',
        message: 'Precaución, esta acción eliminará la información entre este proyecto y la entidad externa ¿Desea continuar?',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteEntity(entity)
      })

    },
    deleteEntity(entity){
      let vm = this;
      vm.isLoading = true;
      axios
          .delete("/projects/"+vm.project.id+"/associated-entities/"+entity.id+"/delete.json", {
            data:{project: {partner_entities_attributes: [entity]},}

          })
          .then(res => {
            vm.errores_validacion = {};
            vm.$buefy.dialog.alert({
              message: 'La entidad externa fue desvinculada exitosamente de este proyecto.',
              type: 'is-success',
              hasIcon: true,
              icon: 'check-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })

            var index = vm.entities.indexOf(entity)
            vm.entities.splice(index,1);
            vm.isLoading=false;
          }).
          catch(error => {
            vm.errores_validacion = error.response.data
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            vm.isLoading=false;
          })
    },
    editEntity(entity){
      this.entityId = entity.id;
      this.modalHeader = "Editar Entidad Asociada"
      this.saveButtonName = "Editar Entidad Asociada"
      this.actionType = "update"
      this.activeModal = true;

    },
    updateEntity(){
      this.$emit("updateEntity");
    },
    goToEntity(partner_entity){
      if(partner_entity.partner_entity_url){
        window.location.href=partner_entity.partner_entity_url
      }
    },
    showEntity(entity){
      this.showPartnerEntity = entity;
      this.activeShowModal = true;

    }
  }
}
</script>

<style scoped>

</style>