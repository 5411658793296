<template>
    <section>
        <b-tabs position="is-centered" v-model="currentTab" :multiline="true">
            <b-tab-item v-for="item in items" v-bind:key="item.content" :value="item.content" :label="item.tab"
                :disabled="item.disabled" :icon="item.icon" />
        </b-tabs>
        <!-- COMPONENTE AQUI -->
        <keep-alive>
            <component v-bind:is="currentTabComponent" :person-id="personId" :cargo-principal="cargoPrincipal"
                :min-year="minYear" :max-year="maxYear" :change-filter="changeFilter" :hide-update="true"
                :can-create-document="canCreateDocument" :can_create_award="can_create_award"
                :can_create_scholarship="can_create_scholarship" :principalUnitId="principalUnitId"
                :can_create_related_institution="can_create_related_institution"
                :canCreateMyVimeActivity="canCreateMyVimeActivity"
                :canCreateMyVimeAgreement="canCreateMyVimeAgreement" />
        </keep-alive>

        <!-- FIN COMPONENTE -->
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </section>
</template>

<script>
import MyVimeResumes from './my_vime_resume_tab.vue'
import MyVimeActivities from './my_vime_activities_tab.vue'
import MyVimeAgreements from './my_vime_agreements_tab.vue'
import MyScholarShips from './my_scholarships_data_tab.vue'
import MyRecognitions from './my_recognitions_tab.vue'
import MyRelatedInstitutions from './my_related_institutions_tab.vue'
export default {
    components:{
        MyVimeResumes, MyVimeActivities, MyVimeAgreements, MyScholarShips, MyRecognitions, MyRelatedInstitutions,
    },
    props:[
        'personId','photoUrl', 'cargoPrincipal', 'canCreateDocument', 'minYear', 'maxYear', 'changeFilter', 'can_create_award',
        'can_create_scholarship', 'can_create_related_institution', 'principalUnitId', 'canCreateMyVimeActivity', 'canCreateMyVimeAgreement'
    ],
    data() {
        return {
            isLoading:false,
            currentTab: 'MyScholarShips',
            items: [
                {tab: 'Actividades',content: 'MyVimeActivities', icon:"calendar", disabled: true},
                {tab: 'Convenios',content: 'MyVimeAgreements', icon:"handshake", disabled: true},
                {tab: 'Becas',content: 'MyScholarShips', icon:"school", disabled: false},
                {tab: 'Reconocimientos',content: 'MyRecognitions', icon:"award", disabled: false},
                {tab: 'Afiliaciones',content: 'MyRelatedInstitutions', icon:"industry", disabled: false},
                {tab: 'Curriculum VIME I+D',content: 'MyVimeResumes', icon:"file-contract", disabled: false},
            ],
        }
    },
    methods: {
        changeTab: function(value){
            this.currentTab = value
        },
    },
    computed: {
        currentTabComponent: function () {
            return this.currentTab
        }
    }
}
</script>