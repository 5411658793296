<template>
  <section>
  <b-tabs position="is-centered" type="is-toggle-rounded" >
    <b-tab-item
        label="Activos"
        icon="file-alt">

        

        <div class="box">

          <div v-if="can_create_transaction=='true'">

            <div class="column has-text-left is-pulled-right">
              <b-dropdown aria-role="list">
                <button class="button is-primary" slot="trigger" slot-scope="{ active }">
                  <b-icon icon="plus"></b-icon>
                  <span>Añadir Transacción</span>
                </button>
                <b-dropdown-item has-link aria-role="menuitem">
                  <a href="/sdt_transactions/new/item">
                    <b-icon icon="shopping-bag"></b-icon>
                    Bien
                  </a>
                </b-dropdown-item>
                <b-dropdown-item has-link aria-role="menuitem">
                  <a href="/sdt_transactions/new/contract">
                    <b-icon icon="handshake"></b-icon>
                    Contratación
                  </a>
                </b-dropdown-item>
                <b-dropdown-item has-link aria-role="menuitem">
                  <a href="/sdt_transactions/new/service">
                    <b-icon icon="receipt"></b-icon>
                    Servicio
                  </a>
                </b-dropdown-item>
                <b-dropdown-item has-link aria-role="menuitem">
                  <a href="/sdt_transactions/new/accountability-fund-item">
                    <b-icon icon="pallet"></b-icon>
                    Rendición de fondos para bienes
                  </a>
                </b-dropdown-item>
                <b-dropdown-item has-link aria-role="menuitem">
                  <a href="/sdt_transactions/new/accountability-fund-service">
                    <b-icon icon="truck-loading"></b-icon>
                    Rendición de fondos para servicios
                  </a>
                </b-dropdown-item> 
                  <b-dropdown-item has-link aria-role="menuitem">
                  <a href="/sdt_transactions/new/reimbursement-item">
                    <b-icon icon="box-open"></b-icon>
                    Reembolso de gastos para bienes
                  </a>
                </b-dropdown-item>
                <b-dropdown-item has-link aria-role="menuitem">
                  <a href="/sdt_transactions/new/reimbursement-service">
                    <b-icon icon="boxes"></b-icon>
                    Reembolso de gastos para servicios
                  </a>
                </b-dropdown-item>         
              </b-dropdown>
            </div>
            <br>
            <br>
            <br>
          </div>
          <h3 class="is-size-4 has-text-weight-bold has-text-centered">Búsqueda de Transacciones de Egreso</h3>
          <br>
            <div class="columns" >

              <div class="column" v-if=" categorias.length>0">
                <b-field label="Tipo de transacción">
                  <multiselect v-model="categoria"
                               :options="categorias.map(type => type.id)"
                               :custom-label="opt => categorias.find(x => x.id == opt).name"
                               placeholder="Seleccione categoría"
                               selectLabel=""
                               selectedLabel=""
                               deselectLabel=""
                               @select="selectCategory"
                               :allow-empty="true">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>

              <div class="column" v-if=" categorias.length>0">
                <b-field label="Categoría de transacción">
                    <multiselect v-model="tipo"
                                 :options="tipos.map(type => type.id)"
                                 :custom-label="opt => tipos.find(x => x.id == opt).name"
                                 placeholder="Seleccione Categoría de transacción"
                                 selectLabel=""
                                 selectedLabel=""
                                 :disabled="isDisabled"
                                 deselectLabel=""
                                 :allow-empty="true">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                          No se encontraron elementos.
                      </span>
                    </multiselect>
                </b-field>
              </div>      
              
            </div>
            <div class="columns" >
              <div class="column">
                <b-field label="Fecha desde:" >
                  <b-datepicker
                      v-model="minDate"
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                      icon="calendar-alt"
                      editable
                      locale="es-ES"
                      trap-focus>
                  </b-datepicker>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha hasta:" >
                  <b-datepicker
                      v-model="maxDate"
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                      icon="calendar-alt"
                      editable
                      locale="es-ES"
                      trap-focus>
                  </b-datepicker>
                </b-field>
              </div>
              <div class="column has-text-right">
                <br>
                <b-button
                          class="button right-button button-clear-filter"
                          icon-left="eraser"
                          @click="clearFilter()">
                  Limpiar Filtros
                </b-button>
                <b-button type="is-info"
                          icon-left="search"
                          class="button right-button is-info"

                          @click="updateTransactionFilter()">
                  Buscar
                </b-button>

              </div>

            </div>
          <br>
          <div class="box">
            <h3 class="is-size-4 has-text-weight-bold has-text-centered">Tabla de Transacciones de egresos</h3>
            
            <b-table
              :data="transacciones"
              :paginated=true
              per-page=10
              striped
              hoverable
              :current-page="currentPage"
              :card-layout="cardLayout"
              :pagination-simple=false
              pagination-position="bottom"
              default-sort-direction="desc"
              sort-icon="arrow-up"
              sort-icon-size="is-small"
              default-sort="payment_date"
              aria-next-label="Siguiente"
              aria-previous-label="Anterior"
              aria-page-label="Página"
              aria-current-label="Página actual">
            <b-table-column
                field= 'sdt_origin_transaction_type'
                label= 'Tipo'
                width="10em"
                searchable
                sortable>
              <template
                  slot="searchable"
                  slot-scope="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    icon="search"
                    size="is-small" />
              </template>
              <template v-slot="props">
                {{  props.row.sdt_origin_transaction_type || " " }}
              </template>
            </b-table-column>

            <b-table-column
                field= 'sdt_transaction_type'
                label= 'Categoría'
                width="15em"
                searchable
                sortable>
              <template
                  slot="searchable"
                  slot-scope="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    icon="search"
                    size="is-small" />
              </template>
              <template v-slot="props">
                {{  props.row.sdt_transaction_type || "Rendición de fondos" }}
              </template>
            </b-table-column>

            <b-table-column
                field= 'amount'
                label= 'Monto bruto total'
                width="15em"
                searchable
                :custom-search="searchAmount"
                sortable>
              <template
                  slot="searchable"
                  slot-scope="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    icon="search"
                    size="is-small" />
              </template>
              <template v-slot="props">
                {{  "$"+Number(props.row.amount).toLocaleString('es-ES') || " " }}
              </template>
            </b-table-column>

            <b-table-column
                field= 'payment_date'
                label= 'Fecha de pago'
                width="10em"
                :custom-search="searchTransactionDate"
                searchable
                sortable>
              <template v-slot="props">
                {{ props.row.payment_date._isValid ? new Date(props.row.payment_date).toLocaleDateString("es-CL"): '' }}
              </template>
            </b-table-column>


            

            <b-table-column
              label="Información"
              width="10em"
              v-slot="props">
              <b-button type="is-primary"
                          icon-left="eye"

                          @click="showTransaction(props.row)">
              </b-button>
              <b-button type="is-info is-light is-outlined"
                        icon-left="pen"
                        v-if="props.row.can_edit_transaction == true"
                          @click="editTransaction(props.row)">
              </b-button>
              
              <b-button
                type="is-danger is-light is-outlined"
                icon-left = "ban"
                v-if="props.row.can_nullify_transaction == true"
                @click.native="execNullifyModalTransaction(props.row.id, props.row)">
              </b-button>
            </b-table-column>

            <template slot="empty" slot-scope="props">
              <div class="columns is-centered">
                <div class="column is-6 is-centered has-text-centered">
                  <b-message type="is-danger">
                    No se encontraron transacciones.
                  </b-message>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </b-tab-item>
      <b-tab-item
        label="Anulados"
        icon="ban"
        >

        <div class="box">
        <h3 class="is-size-4 has-text-weight-bold has-text-centered">Búsqueda de Transacciones Anuladas</h3>
        <br>
        <div class="columns" >

          <div class="column" v-if=" categorias.length>0">
            <b-field label="Tipo de transaccion de egreso">
              <multiselect v-model="categoriaNullify"
                           :options="categorias.map(type => type.id)"
                           :custom-label="opt => categorias.find(x => x.id == opt).name"
                           placeholder="Seleccione Categoria"
                           selectLabel=""
                           selectedLabel=""
                           deselectLabel=""
                           @select="selectCategoryNullify"
                           :allow-empty="true">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                    No se encontraron elementos.
                </span>
              </multiselect>
            </b-field>
          </div>

          <div class="column" v-if=" categorias.length>0">
            <b-field label="Categoría de transaccion de egreso">
                <multiselect v-model="tipoNullify"
                             :options="tiposNullify.map(type => type.id)"
                             :custom-label="opt => tiposNullify.find(x => x.id == opt).name"
                             placeholder="Seleccione Categoría transaccion"
                             selectLabel=""
                             selectedLabel=""
                             :disabled="isDisabledNullify"
                             deselectLabel=""
                             :allow-empty="true">
                  <template v-slot:noOptions>
                    No existen datos
                  </template>
                  <span slot="noResult">
                      No se encontraron elementos.
                  </span>
                </multiselect>
            </b-field>
          </div>      
          
        </div>
        <div class="columns" >
          <div class="column">
            <b-field label="Fecha desde:" >
              <b-datepicker
                  v-model="maxDateNullify"
                  placeholder="Formato de Fecha: dd/mm/aaaa"
                  icon="calendar-alt"
                  editable
                  locale="es-ES"
                  trap-focus>
              </b-datepicker>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Fecha hasta:" >
              <b-datepicker
                  v-model="minDateNullify"
                  placeholder="Formato de Fecha: dd/mm/aaaa"
                  icon="calendar-alt"
                  editable
                  locale="es-ES"
                  trap-focus>
              </b-datepicker>
            </b-field>
          </div>

          <div class="column has-text-right">
            <br>
              <b-button
                        class="button right-button button-clear-filter"
                        icon-left="eraser"
                        @click="clearFilterNullify()">
                Limpiar Filtros
              </b-button>
              <b-button type="is-info"
                        icon-left="search"
                        class="button right-button is-info"

                        @click="updateTransactionNullifyFilter()">
                Buscar
              </b-button>

          </div>
        </div>
        
        <div class="box">
          <h3 class="is-size-4 has-text-weight-bold has-text-centered">Tabla de Transacciones Anuladas</h3>
          <b-table
            :data="transaccionesAnulados"
            :paginated=true
            per-page=10
            striped
            hoverable
            :current-page="currentPage2"
            :pagination-simple=false
            pagination-position="bottom"
            default-sort-direction="desc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            default-sort="payment_date"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Página actual">
          <b-table-column
                field= 'sdt_origin_transaction_type'
                label= 'Tipo'
                width="10em"
                searchable
                sortable>
              <template
                  slot="searchable"
                  slot-scope="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    icon="search"
                    size="is-small" />
              </template>
              <template v-slot="props">
                {{  props.row.sdt_origin_transaction_type || " " }}
              </template>
            </b-table-column>

            <b-table-column
                field= 'sdt_transaction_type'
                label= 'Categoría'
                width="15em"
                searchable
                sortable>
              <template
                  slot="searchable"
                  slot-scope="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    icon="search"
                    size="is-small" />
              </template>
              <template v-slot="props">
                {{  props.row.sdt_transaction_type || "Rendición de fondos" }}
              </template>
            </b-table-column>

            <b-table-column
                field= 'amount'
                label= 'Monto bruto total'
                width="15em"
                :custom-search="searchAmount"
                searchable
                sortable>
              <template
                  slot="searchable"
                  slot-scope="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    icon="search"
                    size="is-small" />
              </template>
              <template v-slot="props">
                 {{ "$"+Number(props.row.amount).toLocaleString('es-ES') || "" }}
              </template>
            </b-table-column>

            <b-table-column
                field= 'payment_date'
                label= 'Fecha de pago'
                width="10em"
                :custom-search="searchTransactionDate"
                searchable
                sortable>
              <template v-slot="props">
                {{ props.row.payment_date._isValid ? new Date(props.row.payment_date).toLocaleDateString("es-CL"): '' }}
              </template>
            </b-table-column>

          <b-table-column
                label="Acciones"
                width="5em"
                v-slot="props">

                <b-button type="is-success"
                      icon-left="trash-restore"
                      v-if="props.row.can_restore_transaction == true"
                      @click="execRestoreModal(props.row)">
                </b-button> 

            </b-table-column>

          <template slot="empty" slot-scope="props">
            <div class="columns is-centered">
              <div class="column is-6 is-centered has-text-centered">
                <b-message type="is-danger">
                  No se encontraron transacciones anuladas.
                </b-message>
              </div>
            </div>
          </template>
        </b-table>
        </div>
      </div>

      </b-tab-item>
    </b-tabs>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>


    <b-modal
        :active="isComponentModalServicioActive"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        width="90%"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-transaction-servicio  :transaction-id="transactionId"
                         :query-type = "queryType"  
                         :unidad-principal = "unidadPrincipal" 
                         :modal-header="tituloModal"
                         modal-cancel="true"
                         @close="closeModal"
                         @clickedUpdate="onClickUpdate"
        >
        </modal-transaction-servicio>
      </template>
    </b-modal>

    <b-modal
        :active="isComponentModalContratacionActive"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        width="90%"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-transaction-contratacion  :transaction-id="transactionId"
                         :query-type = "queryType"  
                         :unidad-principal = "unidadPrincipal" 
                         :modal-header="tituloModal"
                         modal-cancel="true"
                         @close="closeModal"
                         @clickedUpdate="onClickUpdate"
        >
        </modal-transaction-contratacion>
      </template>
    </b-modal>

    <b-modal
        :active="isComponentModalBienActive"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        width="90%"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-transaction-bien  :transaction-id="transactionId"
                         :query-type = "queryType" 
                         :unidad-principal = "unidadPrincipal" 
                         :modal-header="tituloModal"  
                         modal-cancel="true"
                         @clickedUpdate="onClickUpdate"
                         @close="closeModal"        
        >
        </modal-transaction-bien>
      </template>
    </b-modal>


    <b-modal
        :active="isComponentModalRendicionBienActive"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        width="90%"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-transaction-render-bien  :transaction-id="transactionId"
                         :query-type = "queryType"
                         :unidad-principal = "unidadPrincipal"
                         :modal-header="tituloModal"
                         modal-cancel="true"
                         :transaction-type-rute="rute"
                         @clickedUpdate="onClickUpdate"
                         @close="closeModal"
        >
        </modal-transaction-render-bien>
      </template>
    </b-modal>

    <b-modal
        :active="isComponentModalRendicionActive"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        width="90%"
        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-transaction-render
                         :transaction-id="transactionId"
                         :query-type = "queryType"
                         :tipo-transaction = "tipoTransaction"
                         :unidad-principal = "unidadPrincipal"
                         modal-cancel="true"
                         :transaction-type-rute="rute"
                         :modal-header="tituloModal"
                         @clickedUpdate="onClickUpdate"
                         @close="closeModal"
        >
        </modal-transaction-render>
      </template>
    </b-modal>

  </section>
</template>

<script>
import axios from "axios";
import modalTransactionServicio from "./NewTransactionServicioForm.vue";
import modalTransactionContratacion from "./NewTransactionContratacionForm.vue";
import modalTransactionBien from "./NewTransactionBienForm.vue";
import modalTransactionRenderBien from "./NewTransactionBienRenderFundForm.vue";
import modalTransactionRender from "./ModalEditTransactionRenderFundForm.vue";
import modalTransactionRenderServicio from "./NewTransactionServiceRenderFundForm.vue";
import {searchDate} from "../../../packs/utilities";
import moment from "moment";
export default {
  name: "ShowEgresosTab",
  props:[ 'surplus', 'cargoPrincipal', 'userId','surplusId','can_create_transaction'],
  components:{modalTransactionServicio, modalTransactionContratacion, modalTransactionBien, modalTransactionRenderServicio, modalTransactionRenderBien, modalTransactionRender},
  data(){
    return{
      isLoading:false,
      currentPage:1,
      currentPage2:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      queryType: null,
      transactionId: null,
      tituloModal: "",
      minDate: new Date(new Date().getFullYear(), 0, 1),
      maxDate: null,
      minDateNullify: null,
      maxDateNullify: null,
      transacciones: [],
      transaccionesAnulados: [],
      tipos: [],
      tipo: null,
      categorias: [],
      categoria: null,
      tiposNullify: [],
      tipoNullify: null,
      categoriaNullify: null,
      type:null,
      tipoTransaction: null,
      isComponentModalBienActive: false,
      isComponentModalServicioActive: false,
      isComponentModalContratacionActive: false,
      isComponentModalRendicionBienActive: false,
      isComponentModalRendicionServicioActive: false,
      isComponentModalRendicionActive: false,
      unidadPrincipal: {},
      isDisabled: true,
      isDisabledNullify: true,
      canCancel: ['escape'],
      src: null,
      rute:"",
    }
  },
  async created(){
    let vm = this;
    vm.isLoading=true;
    await vm.getCategorias();
    axios.get("/sdt_transactions.json", {params:{
                                              id_surplus: vm.surplusId,
                                              min_date:vm.minDate,
                                              origen: "EGRESO"
                                              }})
      .then(res => {
        vm.transacciones = res.data;        
        vm.transacciones = vm.transacciones.map(transaccion => {
          var newTransaccion = transaccion
          newTransaccion["payment_date"] = moment(transaccion.payment_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
          //newTransaccion["amount"] = Number(transaccion.amount).toLocaleString('es-ES')
          return newTransaccion;
        });
        
      })
      .catch(e => {
        vm.isLoading=false;
      });
      if (vm.surplus.can_show_nullify==true){         
        await vm.getTransactionAnulados();
      }
      vm.isLoading=false;

  },
  methods:{
    editTransaction(row){
      this.unidadPrincipal.id = this.surplus.unit_id;
      this.tituloModal = "Editar Transaccion de egreso"
      this.queryType = "update"
      this.transactionId = row.id
      this.tipoTransaction = row.sdt_origin_transaction_type
      if(row.sdt_origin_transaction_type == "Servicio"){
        if (row.is_parent){
          this.rute = this.defineRute(row.sdt_transaction_type,"service");
          this.isComponentModalRendicionActive = true;
        }
        else
        {
          this.isComponentModalServicioActive = true;
        }
      }
      else if (row.sdt_origin_transaction_type == "Bien"){
        
        if (row.is_parent){
          this.rute = this.defineRute(row.sdt_transaction_type,"item");
          this.isComponentModalRendicionActive = true;
        }
        else
        {
          
          this.isComponentModalBienActive = true;
        }
      }
      else{
        this.isComponentModalContratacionActive = true;
      }
      
    },
    defineRute(type, category){
      if (type == "Rendición De Fondos"){
        return "accountability-fund-"+category
      }
      else if (type == "Reembolso De Gastos"){
        return "reimbursement-"+category
      }
      else{
        return ""
      }

    },
    showTransaction(row){
      window.open('/sdt_transactions/'+row.id, '_blank')
    },    

    clearFilter(){
      this.categoria = null;
      this.tipos = [];
      this.tipo = null;
      this.minDate = null;
      this.maxDate = null;
      this.isDisabled = true;        

    },
    clearFilterNullify(){
      this.categoriaNullify = null;
      this.tiposNullify = [];
      this.tipoNullify = null;
      this.minDateNullify = null;
      this.maxDateNullify = null;
      this.isDisabledNullify = true;      

    },
    async selectCategory(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/sdt_transaction_types/origin-type/"+vm.categoria+".json")
        .then(res => {
          vm.tipos = [];
          vm.tipo = null;
          vm.tipos = res.data;
          vm.isDisabled = false;
          vm.isLoading = false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });

    },

    async selectCategoryNullify(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/sdt_transaction_types/origin-type/"+vm.categoriaNullify+".json")
        .then(res => {
          vm.tipoNullify = null;
          vm.tiposNullify = [];
          vm.tiposNullify = res.data;
          vm.isDisabledNullify = false;
          vm.isLoading = false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });

    },

    execNullifyModalTransaction(id, row){
      this.$buefy.dialog.confirm({
        title: 'Anular Transacción',
        message: '¿Está seguro que desea <b>anular</b> la Transacción de egreso? Esta acción lo dejará inválido.<br>',
        confirmText: 'Anular Transacción',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/sdt_transactions/" + id + "/nullify-transaction.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Transacción anulada correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })

                var index = this.transacciones.indexOf(row);
                this.transacciones.splice(index, 1);
                this.transaccionesAnulados.push(row);
                this.isLoading = false;
              }).catch(error => {
            this.$buefy.dialog.alert({
              message: '<b>transacción no pudo ser anulado</b><br>'+error.response.data.message,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },

    updateTransactionFilter(){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/sdt_transactions.json", {params:{
                                              min_date:vm.minDate,
                                              max_date:vm.maxDate,
                                              category:vm.categoria,
                                              type:vm.tipo,
                                              id_surplus: vm.surplus.id,
                                              origen: "EGRESO"
                                              }})
        .then(res => {
          vm.transacciones = res.data;
          vm.transacciones = vm.transacciones.map(transaccion => {
            var newTransaccion = transaccion
            newTransaccion["payment_date"] = moment(transaccion.payment_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            //newTransaccion["amount"] = Number(transaccion.amount).toLocaleString('es-ES')
            return newTransaccion;
          });
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    searchTransactionDate(row,input){
      return searchDate(row.payment_date,input)
    },

    updateTransactionNullifyFilter(){
      let vm = this;
      vm.isLoading=true;

      return axios.get("/sdt_transactions.json", {params:{
                                              min_date :vm.minDateNullify,
                                              max_date :vm.maxDateNullify,
                                              category:vm.categoriaNullify,
                                              type:vm.tipoNullify,
                                              id_surplus: vm.surplus.id,
                                              origen: "EGRESO",
                                              is_nullified: "True"
                                              }})
        .then(res => {
          vm.transaccionesAnulados = res.data;
          vm.transaccionesAnulados = vm.transaccionesAnulados.map(transaccion => {
            var newTransaccion = transaccion
            newTransaccion["payment_date"] = moment(transaccion.payment_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            //newTransaccion["amount"] = Number(transaccion.amount).toLocaleString('es-ES')
            return newTransaccion;
          });
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    searchAmount(row,input){
        return String(row.amount).includes(input);
    },
    execRestoreModal(row){
        let vm = this;
        let ruta = ""
        vm.$buefy.dialog.confirm({
          title: 'Restaurar transacción de egreso',
          message: '¿Está seguro que desea <b>restaurar</b> la Transacción? Esta acción revertirá la anulación.',
          confirmText: 'Restaurar transacción',
          type: 'is-success',
          hasIcon: true,
          onConfirm: () => {
            vm.isLoading = true
            axios
                .put("/sdt_transactions/"+row.id+"/restore-transaction.json")
                .then(res => {
                  vm.$buefy.dialog.alert({
                    message: 'Transacción restaurada correctamente',
                    duration: 5000,
                    type: 'is-success',
                    canCancel: ["escape", "outside"]
                  })
                  var index = vm.transaccionesAnulados.indexOf(row);
                  vm.transaccionesAnulados.splice(index, 1);
                  vm.transacciones.push(row);
                  vm.isLoading = false;
                }).catch(error => {
              vm.$buefy.dialog.alert({
                message: '<b>Transacción no pudo ser restaurado</b><br>'+error.response.data.message,
                duration: 5000,
                type: 'is-danger',
                canCancel: ["escape", "outside"]
              })
              vm.isLoading = false;
            })
          }
        })
      },

    async getCategorias() {
      let vm = this;
      return axios.get("/sdt_origin_transaction_types/origin-transaction-sdt.json", {params:{type: "surplus_egreso"}})
        .then(res => {
          vm.categorias = res.data;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    async getTransactionAnulados(){
      let vm = this;
      await axios.get("/sdt_transactions.json", {params:{
                                              id_surplus: vm.surplus.id,
                                              origen: "EGRESO",
                                              is_nullified: "True"
                                              }})
      .then(res => {
        vm.transaccionesAnulados = res.data;
          vm.transaccionesAnulados = vm.transaccionesAnulados.map(transaccion => {
            var newTransaccion = transaccion
            newTransaccion["payment_date"] = moment(transaccion.payment_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            //newTransaccion["amount"] = Number(transaccion.amount).toLocaleString('es-ES');
            return newTransaccion;
          });
      })
      .catch(e => {
      });
    },

    closeModal(){
      this.isComponentModalBienActive = false;
      this.isComponentModalServicioActive = false;
      this.isComponentModalContratacionActive = false;
      this.isComponentModalRendicionBienActive = false;
      this.isComponentModalRendicionServicioActive = false;
      this.isComponentModalRendicionActive = false;
      
    },
    onClickUpdate(transaccion){
      this.isLoading = true
      this.transacciones = this.transacciones.filter(elemento => elemento.id !== transaccion.id);
      transaccion.payment_date = moment(transaccion.payment_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
      //transaccion.amount = Number(transaccion.amount).toLocaleString('es-ES');
      if(transaccion.sdt_surplus_id == this.surplusId){
        this.transacciones.push(transaccion)
      }
      this.isLoading = false
    },


  }

}
</script>

<style scoped>

  .right-button{
    margin-left: auto;
  }
  .left-button{
    margin-right: auto;
  }
  .custom-collapse {
    background-color: #F9F9F9 !important;
    border: 1px solid #000000;
  }
  .custom-header-title {
    font-size: 24px; 
    font-weight: bold; 
    text-align: center !important; 
    margin: 0; 
  }


</style>