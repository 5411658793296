<template>
  <div>
    <section>
      <div class="modal-card" style="width: auto" id="modal-event">
        <header class="modal-card-head">
          <p class="modal-card-title">{{modalHeader}}</p>
          <button
              type="button"
              class="delete"
              @click="$emit('close')"/>
        </header>
        <section class="modal-card-body">
          <b-tabs position="is-centered" @input="changeTab">
            <b-tab-item v-for="item in items"
                        :value="item.content"
                        v-bind:key="item.content"
                        :label="item.tab"
                        :icon="item.icon">
            </b-tab-item>

          </b-tabs>
          <!-- COMPONENTE AQUI -->

          <component v-bind:is="currentTabComponent"
                     :user-id="this.userId"
                     :cargo-principal="this.cargoPrincipal"
                     :participants="this.publication.participants_attributes"
                     :publication="this.publication"
                     :participantes="this.participantes"
                     :show="this.show"
                     :book-id="this.bookId"></component>

          <!-- FIN COMPONENTE -->

        </section>
        <footer class="modal-card-foot actions">
          <div class="has-text-right is-grouped">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <b-button class="is-primary"
                      v-if="publication.can_edit"
                      @click="isComponentModalActive = true">
              Editar
            </b-button>
          </div>
        </footer>
      </div>
    </section>

    <b-modal
        :active="isComponentModalActive"
        width="90%"
        :can-cancel="canCancel"
        aria-modal>
      <template #default="props">
        <new-inbook-form modal-cancel="true"
                          :cargo-principal="cargoPrincipal"
                          :user-id="userId"
                          modal-header="Editar Sección"
                          query-type="update" v-bind:inbook-id="publication.id"
                          response-type="RETORNOBOOK"
                          @inbookAdded="inbookAdded"
                          @close="closeModal"></new-inbook-form>
      </template>
    </b-modal>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>
<script>
import GeneralBook from './ShowGeneralTab';
import Participants from './ShowParticipantsTab';
import NewInbookForm from "./NewInbookForm";
import GeneralInbook from './ShowGeneralInbookTab';
import axios from "axios";
export default {
  name: 'modal_show_nullified_book',
  components:{ GeneralBook, Participants,GeneralInbook, NewInbookForm},
  props:[
    'bookId', 'cargoPrincipal', 'userId','modalHeader', 'show'
  ],
  data(){
    return{
      publication: {
        publication_type_attributes:[
          {id:-1,name:""}
        ],
        participants_attributes:[],
        validation_attributes:{}
      },
      isLoading:false,
      participantes: [],
      currentPage:1,
      isComponentModalActive: false,
      canCancel: ['escape'],
      cardLayout:false,
      currentTab: '',
      items: []
    }
  },
  created(){
    this.isLoading=true;
    var ruta = ""
    if (this.show != null){
      ruta = "/inbooks/"+this.bookId+".json"
    }
    else
    {
      ruta = "/books/show_book_nullified/"+this.bookId+".json"
    }

    return axios
        .get(ruta)
        .then(res => {
          if (this.show != null){
            this.publication = res.data;
          }
          else
          {
            this.publication = res.data[0];
          }
          
          if(this.publication.publication_type==="Book")
          {
            this.items.push({tab: "General",content:'GeneralBook',icon:"file-alt"})
            this.currentTab = "GeneralBook"
          }
          else{
            this.items.push({tab: "General",content:'GeneralInbook',icon:"file-alt"})
            this.currentTab = "GeneralInbook"
          }
          this.items.push({tab: 'Participantes',content:'Participants',icon:"users"})
          var index = 0
          for(const participante of this.publication.participants_attributes){
            this.participantes.push({
              id:null,
              full_name:null,
              alias:null,
              unit:null,
              unit_id:null,
              corporarte_mail:null,
              participant_type_id:null,
              participant_type:null,
              is_doc_student:null
            })
            this.participantes[index].id = participante.person_id
            this.participantes[index].is_doc_student = participante.is_doc_student
            this.participantes[index].full_name = participante.person_name
            this.participantes[index].corporate_mail = participante.person_mail
            this.participantes[index].alias = participante.person_alias
            if(this.participantes[index].corporate_mail != null){
              this.participantes[index].corporate_mail = this.participantes[index].corporate_mail.toUpperCase()
            }
            if(this.participantes[index].alias != null){
              this.participantes[index].alias = this.participantes[index].alias.toUpperCase()
            }
            this.participantes[index].unit = participante.unit_name
            this.participantes[index].unit_id = participante.unit_id
            this.participantes[index].participant_type_id = participante.participant_type_id
            this.participantes[index].participant_type = participante.participant_type
            index = index + 1
          }
          this.publication.publication_date = this.formatDate(this.publication.publication_date)
          this.isLoading=false;
        })
        .catch(e => {
          console.log(e);
          this.isLoading=false;
        });

  },
  methods:{
    formatDate(date){
      if (date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{
        return ""
      }
    },
    changeTab: function(value){
      this.currentTab = value
    },
    closeModal(){
      this.isComponentModalActive = false;
    },
    inbookAdded(publication){
      this.isComponentModalActive = false; 
      this.publication = publication;
      this.publication.publication_date = this.formatDate(this.publication.publication_date)
      this.$buefy.dialog.alert({
        title: 'Petición exitosa',
        message: 'Su petición fue exitosa y el artículo ha sido añadida al sistema.',
        type: 'is-success',
        hasIcon: true,
        icon: 'check-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true
      })
      this.$emit("inbookAdded",publication);
    },

  },
  computed:{
    currentTabComponent: function () {
      return this.currentTab
    }
  }
}
</script>
