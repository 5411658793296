<template>
  <section>
    <b-message type="is-warning" has-icon title="Importante" aria-close-label="Close message">
      Este nombramiento tiene una o más <b>rectificaciones, cambios de centro de costo, aumeto de horas o reducción de horas asociadas</b>.<br>
      La información de estas rectificaciones se puede verificar en la pestaña de <b>Eventos</b> y también al final del nombramiento.
    </b-message>
  </section>
</template>
<script>
export default {
  name: "rectification_alert",
  data(){
    return {
    }
  },
  methods:{

  }
}
</script>