<template>
  <section>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveMagazine)">
        <div class="modal-card" style="width: auto" id="modal-magazine">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" ref="modalPersonForm">
            <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
              <b-message type="is-danger" has-icon>
                <div v-for="(item,index) in errores_validacion">
                  {{ item[0] }}
                </div>
              </b-message>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="magazine.magazine_type_id"
                                 :options="magazine_types.map(type => type.id)"
                                 :custom-label="opt => magazine_types.find(x => x.id == opt).name"
                                 placeholder="Seleccione tipo"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Nombre Lugar de Publicación*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input type="text" v-model="magazine.name" maxlength="255" :has-counter="false">
                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Nombre abreviado">
                  <b-input type="text" v-model="magazine.abbreviated_name" maxlength="255" :has-counter="false">
                  </b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Link*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input type="text" v-model="magazine.url" maxlength="255" :has-counter="false">
                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit"  @click="checkValidation()">Guardar </button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "NewMagazineModal",
  data(){
    return{
      magazine: {
        name: null,
        abbreviated_name: null,
        url: null,
        magazine_type_id:null
      },
      magazine_types:[],
      isLoading: false,
      canCancel: ['escape'],
      errores_validacion: {},
    }
  },
  props: ['modalHeader','queryType','updateMagazine'],
  async created() {
    await this.getMagazineTypes();
    if(this.queryType==='update' && this.updateMagazine){
      let vm = this;
      vm.isLoading = true
      axios
        .get('/magazines/'+this.updateMagazine+'.json')
        .then(res => {
          console.log(res.data);
          vm.magazine = res.data;
          vm.magazine.magazine_type_id = res.data.magazine_type ? res.data.magazine_type.id : null;
          vm.isLoading =false
        }).
      catch(error => {
        vm.isLoading = false
      })
    }
  },
  methods: {
    async getMagazineTypes(){
      let vm = this;
      vm.isLoading = true
      await axios
        .get('/magazine_types.json')
        .then(res => {
          vm.magazine_types = res.data;
          vm.isLoading =false
        }).
        catch(error => {
          vm.isLoading = false
        })
    },
    saveMagazine(){
      if (this.queryType=='create'){
        this.isLoading = true
        axios
            .post('/magazines.json',this.magazine)
            .then(response => {
              this.isLoading =false
              this.errores_validacion = {};
              this.$emit('clicked', response.data)
              this.$emit('close')
            }).
        catch(error => {
          console.log(error)
          this.errores_validacion = error.response.data
          this.isLoading = false
        })
      }
      if (this.queryType=='update'){
        this.isLoading = true
        axios
          .put('/magazines/'+this.magazine.id+'.json',this.magazine)
          .then(response => {
            this.isLoading =false
            this.errores_validacion = {};
            this.$emit('clicked', response.data)
            this.$emit('close')
          }).
          catch(error => {
            //console.log(error)
            this.errores_validacion = error.response.data
            this.isLoading = false
          })
      }

    },
    async checkValidation(){
      const valid = await this.$refs.observer.validate()
      if(!valid){
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
  }
}
</script>

<style scoped>

</style>