<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveUnit)">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head" id="modal-event">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" id="modal-event2">
              <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                <b-message type="is-danger" has-icon>
                  <div v-for="(item,index) in errores_validacion" :key="index">
                    <div v-if="item">{{ item[0] }}</div>
                  </div>
                </b-message>
              </div>
                <!-- Box de Formulario Unidad -->
                <div class="columns">
                  <div class="column">
                    <b-field label="Nombre*">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-input v-model="unit.name" type="text" minlength="3" maxlength="100"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Tipo de Unidad*">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect v-model="unit.unit_type_id"
                                     :options="unit_types.map(type => type.id)"
                                     :custom-label="opt => unit_types.find(x => x.id === opt).name"
                                     placeholder="Seleccione tipo de unidad"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel="Seleccionado"
                                     deselectLabel="No se puede deseleccionar"
                                     :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                            No se encontraron elementos.
                          </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Nombre corto">
                      <ValidationProvider v-slot="{ errors }">
                        <b-input v-model="unit.short_name" type="text" minlength="3" maxlength="50"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Centro de Costo*">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-input v-model="unit.cost_center" type="number"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <div v-if="unit.unit_type_id === 1 || unit.unit_type_id === 3">
                      <b-field label="Unidad Mayor">
                        <multiselect v-model="unit.faculty_cost_center"
                                     :options="faculties.map(type => type.cost_center)"
                                     :custom-label="opt => faculties.find(x => x.cost_center === opt).name"
                                     placeholder="Seleccione Centro de Costo de Facultad"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel="Seleccionado"
                                     deselectLabel="Presione deseleccionar"
                                     :allow-empty="true">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                            No se encontraron elementos.
                          </span>
                        </multiselect>
                      </b-field>
                    </div>

                  </div>
                  <div class="column">
                    <b-field label="¿Unidad de contratación?">
                      <b-checkbox label="" v-model="unit.contracting_unit"> Sí
                      </b-checkbox>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Url">
                      <ValidationProvider v-slot="{ errors }">
                        <b-input v-model="unit.url" type="text" maxlength="100"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
                <!-- GUARDAR UNIDAD-->
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="actions has-text-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit" :disabled="isDisabled">Guardar Unidad</button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios"
export default {
  name: "modal_unit_form",
  data(){
    const data =[]
    return {
      unit:{},
      unit_types: [],
      faculties: [],
      errores_validacion: {},
      isLoading:false,
      isDisabled: false
    }
  },
  props:[
    'userId', 'queryType', 'unitId', 'modalHeader'
  ],
  created(){
    this.isLoading=true;
    if (this.queryType ==="create"){
      axios
          .get("/admin/units/new.json")
          .then(res => {
            this.unit_types = res.data["unit_types"]
            this.faculties = res.data["faculties"]
            this.isLoading=false;
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }
    else if (this.queryType === "update"){
      this.isLoading=true;
      axios
          .get("/admin/units/"+this.unitId+"/edit.json")
          .then(res => {
            this.unit = res.data["unit"]
            this.unit_types = res.data["unit_types"]
            if (res.data["faculties"] != null) {
              this.faculties = res.data["faculties"]
            }
            this.isLoading=false;
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }
  },
  methods: {
    saveUnit(){
      this.isLoading=true;
      this.isDisabled=true;
      if(this.unit.unit_type_id === 2){
        this.unit.faculty_cost_center = null
      }
      if (this.queryType==="create"){
        axios
            .post("/admin/units.json", {
              unit: this.unit
            })
            .then(response =>{
              this.$buefy.dialog.alert({
                message: 'La unidad fue creada con éxito ',
                confirmText: 'Ok'
              })
              this.errores_validacion = {};
              this.isDisabled=false;
              this.isLoading=false;
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al agregar la unidad, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
          this.isDisabled=false;
        })
      }
      else if (this.queryType==="update"){
        let clean_unit = this.unit
        clean_unit = this.deleteData(clean_unit)
        axios
            .put("/admin/units/" + this.unitId+".json", {
              unit:  clean_unit
            })
            .then(response => {
              this.errores_validacion = {};
              this.$buefy.dialog.alert({
                message: 'La unidad fue editada con éxito ',
                confirmText: 'Ok'
              })
              this.$emit('close')
              this.isLoading = false;
            }).catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al editar la unidad, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
          this.isDisabled=false;
        })
      }
    },
    deleteData(unit){
      return unit
    },
  }
}
</script>

<style scoped>

</style>
