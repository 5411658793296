<template>
    <section>
        <div :id="'bar_type' + title" style="width: 100%; height: 700px;" />
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </section>
</template>

<script>
import * as echarts from 'echarts'
export default {

    props: [
        'types', 'title', 'total'
    ],

    data() {

        return {
            isLoading: false,
            colorPalette: ['#00A499', '#EA7600', '#394049', '#8C4799', '#498BCA', '#EAAA00', '#C8102E'],
            option: {
                tooltip: {
                    show: false,
                },
                legend: {
                    data: [],
                    selected: {},
                    show: true,
                },
                grid: {
                    left: '5%',
                    right: '5%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    name: 'Total',
                    max: null,
                    nameTextStyle: {
                        fontSize: 14,
                    }
                },
                yAxis: {
                    type: 'category',
                    data: []
                },
                series: [],
                toolbox: {
                    show: true,
                    feature: {
                        mark: { show: true },
                        dataView: {
                            show: true,
                            readOnly: true,
                            title: 'Datos',
                            lang: ["Datos", "Cerrar", "Actualizar"],
                            optionToContent: function (opt) {
                                var series = opt.series
                                var table = '<table class="echarts-dataview-table">'
                                table += '<thead><tr><th>Nombres</th><th>Cantidad</th></tr></thead>'
                                table += '<tbody>'

                                series.forEach((type) => {
                                    var value = type.data.reduce((partialSum, a) => partialSum + a, 0)
                                    value = value == 0 ? value : '<b>' + value + '</b>'
                                    table += '<tr>'
                                        + '<td style="text-align: left; border: 1px solid #ccc; ">' + type.name + '</td>'
                                        + '<td style="text-align: center; border: 1px solid #ccc">' + value + '</td>'
                                        + '</tr>';
                                })
                                table += '</tbody></table>'
                                return table
                            }
                        },
                        saveAsImage: {
                            show: true,
                            pixelRatio: 3,
                            title: "Descargar",
                            name: 'indicator_by_type_bar',
                        },
                    }
                },
            }
        }

    },

    async mounted() {
        this.$nextTick(() => {
            this.initECharts()
        })
    },


    methods: {
        async initECharts() {
            this.isLoading = true

            this.option.legend.data = []
            this.option.legend.selected = {}
            this.option.series = []
            this.option.yAxis.data = []
            this.option.xAxis.max = null

            this.option = await this.setOptions(this.types, this.option, this.colorPalette, this.total)

            // based on prepared DOM, initialize echarts instance
            this.dom = echarts.init(document.getElementById('bar_type' + this.title))
            this.dom.setOption(this.option)
            new ResizeObserver(() => this.dom.resize()).observe(document.getElementById('bar_type' + this.title))

            this.isLoading = false
        },

        setOptions(types, option, colorPalette, total) {
            return new Promise(function (resolve) {

                option.xAxis.max = total

                var i = 0

                let largo = 0
                for (const element of types) {
                    largo++
                }

                types.forEach(function(type, index) {

                    if (i == colorPalette.length) {
                        i = 0
                    }

                    let data = new Array(largo).fill(0)

                    option.yAxis.data.push(type.name)
                    option.legend.selected[type.name] = type.value !== 0

                    data[index] = type.value

                    data = data.reverse()

                    option.series.push({
                        name: type.name,
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: true,
                            formatter(param) {
                                return param.value != 0 ? + param.value : ''
                            }
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: data,
                        itemStyle: {
                            color: colorPalette[i],
                        },
                    })

                    i++

                })

                option.yAxis.data = option.yAxis.data.reverse()
                option.legend.data = option.yAxis.data

                resolve(option)
            })

        },

    },

    watch: {
        types: function() {
            this.initECharts()
            deep: true
        },
    },

}
</script>

