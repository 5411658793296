<template>
  <section>
    <div class="container">
      <div class="has-text-right mb-1">
        <b-button class="is-primary is-outlined"
                  icon-left="plus"
                  v-if="can_create_household==='true'"
                  @click="isAddModalActive = true">
          Añadir Carga Familiar
        </b-button>
      </div>
      <!--DATA TABLE-->
      <b-table
          :data="household_allowances"
          :paginated= true
          per-page="10"
          striped
          hoverable
          :current-page="currentPage"
          :pagination-simple=false
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="product.title"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Página actual">
        <b-table-column
            field="decree_date"
            label="Fecha Resolución"
            width="110"
            sortable
            searchable :custom-search="searchDecreeDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.decree_date._isValid ? new Date(props.row.decree_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            field="decree"
            label="Resolución"
            numeric
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.decree}}
          </template>
        </b-table-column>
        <b-table-column
            field="relationship"
            label="Parentesco"
            numeric
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.relationship}}
          </template>
        </b-table-column>
        <b-table-column
            field="dependent_type.name"
            label="Tipo de Carga"
            sortable
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.dependent_type.name}}
          </template>
        </b-table-column>

        <b-table-column
            field="rut"
            label="Rut"
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.rut}}
          </template>
        </b-table-column>
        <b-table-column
            field="nombre_completo"
            label="Nombres"
            searchable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.nombre_completo}}
          </template>
        </b-table-column>
        <b-table-column
            field="start_date"
            label="Inicio"
            sortable
            searchable :custom-search="searchStartDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL"):''}}
          </template>
        </b-table-column>
        <b-table-column
            field="expíration_date"
            label="Vence"
            sortable
            searchable :custom-search="searchExpirationDate">
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{props.row.expiration_date._isValid ? new Date(props.row.expiration_date).toLocaleDateString("es-CL") : ''}}
          </template>
        </b-table-column>
        <b-table-column
            label="Acciones"
            width="175"
            v-slot="props">

          <b-tooltip
              v-if="props.row.can_show"
              label="Ver información completa de la asignación familiar."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="eye"
                type="is-primary"
                @click="execShowModal(props.row.id)"/>
          </b-tooltip>


          <b-tooltip
              v-if="props.row.can_edit"
              label="Editar información de la asignación familiar."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="edit"
                type="is-info is-light is-outlined"
                @click="execEditModal(props.row.id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_distance"
              label="Crear cese a la asignación familiar."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="sign-out-alt"
                type="is-danger is-light is-outlined"
                @click="execDistanceModal(props.row)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_copy"
              label="Copiar asignación familiar."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="copy"
                type="is-danger is-light is-outlined"
                @click="execCopyModal(props.row.id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_view_pdf"
              label="Visualizar documento asociado a la asignación familiar."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="file-alt"
                type="is-success is-light is-outlined"
                @click="viewFile(props.row.document_id)"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_view_pdf"
              label="Descargar documento asociado a la asignación familiar."
              position="is-left"
              :active="true">
            <b-button
                pack="fas"
                icon-left="download"
                type="is-primary is-light is-outlined"
                @click="downloadFile(props.row.document_id)"/>
          </b-tooltip>
        </b-table-column>
      </b-table>
      <!--FIN DATA TABLE--><!-- MODALES -->
      <b-modal
          v-model="isAddModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <household-form :person-id="personId"
                            query-type="create"
                          :modal-header="'Nueva Carga Familiar - '  +person_name"
                          :person_name="person_name"
                            @close="props.close"></household-form>
        </template>
      </b-modal>
      <b-modal
          v-model="isShowModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <household :id="selected"
                     :modal-header="'Carga Familiar - '  +person_name"
                     :person_name="person_name"
                       @close="props.close">
          </household>
        </template>
      </b-modal>
      <b-modal
          v-model="isEditModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="canCancel">
        <template #default="props">
          <household-form :person-id="personId"
                            :id="selected"
                            :query-type="query_type"
                          :modal-header="form_name"
                          :person_name="person_name"
                            @close="props.close">
          </household-form>
        </template>
      </b-modal>
      <b-modal
          v-model="isDistanceModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          :can-cancel="canCancel"
          aria-modal>
        <template #default="props">
          <modal-distance-household-form :person-id="household_allowance.person_id"
                                         :household_allowance_id="household_allowance.id"
                                         :id="household_allowance.id"
                                         :person_name="household_allowance.name"
                                         :person_real_name ="person_name"
                                         :person_last_name="household_allowance.last_name"
                                         :person_second_surname="household_allowance.second_surname"
                                         :person_birthday="household_allowance.birthday"
                                         :rut="household_allowance.rut"
                                         :old_start_date="household_allowance.start_date"
                                         query-type="create"
                                         :modal-header="'Cese de Asignación - '+person_name"
                                         @close="props.close">
          </modal-distance-household-form>
        </template>
      </b-modal>
      <b-modal
          v-model="showPdf"
          :can-cancel="true"
          :destroy-on-hide="true"
          aria-modal
          aria-role="dialog"
          has-modal-card
          trap-focus
          :width="2000"
      >
        <template #default="props">
          <pdf_view
              :src="src"
              @close="props.close"
          ></pdf_view>
        </template>

      </b-modal>
    </div>
    <b-loading :is-full-page="true"
               v-model="isLoading"
               :can-cancel="false"/>
  </section>
</template>

<script>
import axios from "axios";
import HouseholdForm from "../life_record/modal_household_form.vue"
import Household from "../life_record/household_allowance.vue"
import ModalDistanceHouseholdForm from "../life_record/household_allowances/modal_distance_household"
import {searchDate, formatDate} from "../../packs/utilities";
import moment from "moment";
import Pdf_view from "../performances/pdf_view.vue";
export default {
  name: "household_allowances",
  props:[
    'personId', 'can_create_household', 'person_name'
  ],
  components:{
    Pdf_view,
    Household, HouseholdForm, ModalDistanceHouseholdForm
  },
  data(){
    return {
      selected: null,
      index: null,
      currentPage: 1,
      canCancel: [],
      isAddModalActive: false,
      isEditModalActive: false,
      isShowModalActive: false,
      isLoading:false,
      household_allowances: [],
      household_allowance: {},
      isDistanceModalActive: false,
      query_type: "",
      form_name: "",
      showPdf: false,
      src:null,
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    initialize(){
      this.isLoading = true;
      return axios
          .get("/people/"+this.personId+"/household_allowances.json")
          .then(res => {
            this.household_allowances = res.data
            this.household_allowances = this.household_allowances.map(allowance => {
              // create a new object to store full name.
              var newHousehold = allowance
              newHousehold["decree_date"] = moment(newHousehold["decree_date"] , ["YYYY-MM-DD","DD-MM-YYYY"]);
              newHousehold["start_date"] = moment(newHousehold["start_date"] , ["YYYY-MM-DD","DD-MM-YYYY"]);
              newHousehold["expiration_date"] = moment(newHousehold["expiration_date"] , ["YYYY-MM-DD","DD-MM-YYYY"]);

              // return our new object.
              return newHousehold;
            });
            this.isLoading=false;
          }).catch(error => {
            console.log(error)
            this.isLoading=false;
          })

    },
    execShowModal(id){
      this.selected = id;
      this.isShowModalActive = true;
    },
    execEditModal(id){
      this.selected = id;
      this.query_type = "update"
      this.form_name='Editar Carga Familiar - '+  this.person_name
      this.isEditModalActive = true;
    },
    execCopyModal(id){
      this.isLoading = true;
      this.selected = id;
      this.query_type = "copy"
      this.form_name = 'Copiar Asignación Familiar - '  +this.person_name
      this.isEditModalActive = true;
      this.isLoading = false
    },
    // yyyy/mm/dd -> dd/mm/yyyy
    formatDate,
    searchDecreeDate(row, input){
      return searchDate(row.decree_date, input)
    },
    searchStartDate(row, input){
      return searchDate(row.start_date, input)
    },
    searchExpirationDate(row, input){
      return searchDate(row.expiration_date, input)
    },
    execDistanceModal(householdAllowance){
      this.isLoading = true
      this.household_allowance = householdAllowance
      this.isDistanceModalActive = true
      this.isLoading = false
    },
    viewFile(fileId){
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            this.showPdf = true
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    downloadFile(fileId){
      this.isLoading = true;

      if (fileId == null){
        this.isLoading = false;
        return;
      }

      axios
          .get("/documents/" + fileId + "/download", {
            responseType: "blob",
            params:{
              life_record: true
            }
          })
          .then(res => {
            let blob = new Blob([res.data], {
              type: "application/pdf",
            })
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = res.headers["content-disposition"]
                .split(";")[1]
                .split('"')[1];
            link.click();
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento fue descargado con exito',
              type: 'is-success',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
          .catch(e => {
            console.log(e)
            this.isLoading=false
            this.$buefy.notification.open({
              message: 'El documento no se pudo descargar',
              type: 'is-danger',
              hasIcon: true,
              position: 'is-bottom-right',
              duration: 5000,
              progressBar: true,
            })
          })
    },
  },
  watch:{
    isAddModalActive: function () {
      if (!this.isAddModalActive) {
        this.initialize()
      }
    },
    isEditModalActive: function () {
      if (!this.isEditModalActive) {
        this.initialize()
      }
    },
    isShowModalActive: function () {
      if (!this.isShowModalActive) {
        this.initialize()
      }
    },
  }
}
</script>
