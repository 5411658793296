<template>
    <section>
        <div class="container">
            <br>
            <h1 class="is-size-2" style="text-align:center;">Calendario de actividades VIME</h1>
            <br>
            <div class="box">
                <div class="columns is-centered">    
                    <div class="column is-narrow">
                        <b-field horizontal label="Año"
                            :type="{ 'is-danger': checkSelectedYear }"
                            :message="{ 'Seleccione un año.': checkSelectedYear }">
                            <b-select v-model='selectedYear'>
                                <option
                                    v-for="(year, index) in years"
                                    :value="year"
                                    :key="index">
                                {{ year }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                    <div class="column is-narrow">
                        <b-field horizontal label="Mes"
                            :type="{ 'is-danger': checkSelectedMonth }"
                            :message="{ 'Seleccione un mes.': checkSelectedMonth }">
                            <b-select v-model="selectedMonth">
                                <option
                                    v-for="(month, index) in months"
                                    :value="month"
                                    :key="index">
                                {{ month }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                    <div class="column is-narrow">
                        <b-button type="is-primary" @click="searchActivities" :loading='checkSubmiting'>
                            <b-icon icon="search"></b-icon>
                            <span>Buscar</span>
                        </b-button>
                    </div>
                </div>
            </div>
            <br>
            <div v-if='!checkFilteredActivities'>
                <span class="is-pulled-right">
                    <button class="button is-primary" @click="downloadCalendar()">
                        <b-icon icon="file-pdf"></b-icon>
                        <span>Descargar reporte general de actividades</span>
                    </button>
                </span>
                <br>
                <br>
                <b-table
                    :data="filteredActivities"
                    :paginated=true
                    per-page=10
                    striped
                    hoverable
                    :current-page="currentPage"
                    :pagination-simple=false
                    pagination-position="bottom"
                    default-sort-direction="asc"
                    sort-icon="arrow-up"
                    sort-icon-size="is-small"
                    default-sort="publication.title"
                    aria-next-label="Siguiente"
                    aria-previous-label="Anterior"
                    aria-page-label="Página"
                    aria-current-label="Página actual">
                    <b-table-column
                        field= 'name'
                        label= 'Nombre actividad'
                        v-slot="props"
                        header-class="is-primary"
                    >
                        {{props.row.name}}
                    </b-table-column>
                    <b-table-column
                        field= 'theme'
                        label= 'Tema'
                        header-class="is-primary"
                        v-slot="props"
                    >
                        {{props.row.theme}}
                    </b-table-column>
                    <b-table-column
                        field= 'vime_activity_type_name'
                        label= 'tipo de actividad'
                        v-slot="props"
                        header-class="is-primary"
                    >
                        {{props.row.vime_activity_type_name}}
                    </b-table-column>
                    <b-table-column
                        field= 'start_date'
                        label= 'Fecha de inicio'
                        v-slot="props"
                        header-class="is-primary"
                    >
                        {{props.row.start_date}}
                    </b-table-column>
                    <b-table-column
                        field= 'end_date'
                        label= 'Fecha de término'
                        v-slot="props"
                        header-class="is-primary"
                    >
                        {{props.row.end_date}}
                    </b-table-column>
                </b-table>
            </div>
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
export default {
    name: 'VimeActivityCalendarView',
    data(){
        return {
            years:[],
            months: ['Enero', 'Febrero', 'Marzo', 'Abril', 
                     'Mayo', 'Junio', 'Julio', 'Agosto', 
                     'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            isLoading:false,
            currentPage:1,
            activities_filtered: [],
            activities_filtered_pdf: [],
            year: null,
            month: '',
            validations:{
                isYearEmpty: true,
                isMonthEmpty: true,
                firstTimeYear: true,
                firstTimeMonth: true
            },
            isSubmiting: false
        }
    },
    computed:{
        filteredActivities:{
            get: function() {
                return this.activities_filtered
            },
            set: function(newActivitiesFiltered){
                this.activities_filtered = newActivitiesFiltered
            }
        },
        selectedYear:{
            get: function(){
                return this.year
            },
            set: function(newYear){
                this.validations.firstTimeYear = false
                if(newYear === null)
                    this.validations.isYearEmpty = true
                else
                    this.validations.isYearEmpty = false
                this.year = newYear
            }   
        },
        selectedMonth: {
            get: function(){
                return this.month
            },
            set: function(newMonth){
                this.validations.firstTimeMonth = false
                if(newMonth === '')
                    this.validations.isMonthEmpty = true
                else
                    this.validations.isMonthEmpty = false
                this.month = newMonth
            }
        },
        checkSelectedYear(){
            if(this.validations.firstTimeYear === false)
                return this.validations.isYearEmpty
            else
                return false
        },
        checkSelectedMonth(){
            if(this.validations.firstTimeMonth === false)
                return this.validations.isMonthEmpty
            else
                return false
        },
        checkFilteredActivities(){
            return Object.entries(this.activities_filtered).length === 0 
        },
        checkSubmiting: {
            get: function(){
                return this.isSubmiting
            },
            set: function(newState){
                this.isSubmiting = newState
            }
        }
    },
    beforeMount(){
        this.createYears()
    },
    methods:{
        createYears(){
            var actualDate = new Date()
            var actualYear = actualDate.getFullYear()
            for(var i=1980; i<=parseInt(actualYear); i++){
                this.years.push(i)
            }
        },
        checkEmptyFields(){
            if(this.validations.firstTimeYear === true){
                this.validations.firstTimeYear = false
            }
            if(this.validations.firstTimeMonth === true){
                this.validations.firstTimeMonth = false
            }
        },
        checkSearch(){
            var confirmation = true
            var arrayConfirmation = []
            arrayConfirmation.push(this.validations.isYearEmpty)
            arrayConfirmation.push(this.validations.isMonthEmpty)
            arrayConfirmation.forEach(function(element){
                if(element === true)
                    confirmation = false
            })
            return confirmation
        },
        searchActivities(){
            this.checkSubmiting = true
            var confirmaton = this.checkSearch()
            if(confirmaton){
                axios.post('/vime_activities_get_activities_by_year_and_month', {
                    year: this.year,
                    month: (this.months.indexOf(this.month)+1)
                })
                .then( response =>{
                    this.filteredActivities = response.data.activities
                    this.checkSubmiting = false
                    if(this.checkFilteredActivities){
                        this.$buefy.dialog.alert({
                            title: "Búsqueda de actividades.",
                            message: "No se han encontrado actividades para el año y mes definido."
                        })
                    }else{
                        var formatedDates = []
                        this.filteredActivities.forEach(function(element){
                            formatedDates.push(element)
                        })
                        this.sectionsDateFormater(formatedDates)
                        console.log(formatedDates)
                    }
                })
                .catch(e => {
                    console.log(e)
                })
            }
            else{
                this.checkEmptyFields()
                this.$buefy.dialog.alert({
                    title: "Error en la búsqueda",
                    message: "Faltan campos de búsqueda por completar para realizar la búsqueda.",
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.checkSubmiting = false
                    }
                })
            }
        },
        dateFormater(date){
            var newDate = ""
            var localDate = new Date(date).toLocaleDateString().toString();
            for(var i =0; i<localDate.length; i++){
                if(localDate[i] === '-'){
                    newDate = newDate.concat('/')
                }
                else{
                    newDate = newDate.concat(localDate.charAt(i))
                }
            }
            return newDate
        },
        sectionsDateFormater(section){
            if(typeof section != 'undefined'){
                for(var i=0; i<section.length; i++){
                    section[i].start_date = this.dateFormater(section[i].start_date);
                    section[i].end_date = this.dateFormater(section[i].end_date);
                    section[i].is_national = this.isNationalorNot(section[i].is_national)
                }
            }
        },
        isNationalorNot(activity){
            if(activity){
                return 'SI'
            }
            else{
                return 'NO'
            }
        },
        downloadCalendar(){
            // Declaración del PDF
            var doc = new jsPDF('p','pt');
            doc.setFillColor(253, 126, 20);
            doc.rect(0, 0, 600, 10, "F");
            doc.rect(0, 832, 600, 10, "F");
            doc.addImage("/logo_negro.png", "JPEG", 20, 20, 150, 80);

            // Título del calendario
            doc.setFont("times new roman", "bold");
            doc.text("Calendario de actividades VIME mes "+this.month, 120, 130);
            doc.setFont("times new roman", "normal");

            // Encabezados tabla
            var columnsNames = [
                {title: 'Nombre', dataKey: 'name'},
                {title: 'Tema', dataKey: 'theme'},
                {title: 'Tipo de actividad', dataKey: 'activity_type'},
                {title: 'Fecha inicio', dataKey: 'start_date'},
                {title: 'Fecha término', dataKey: 'end_date'},
                {title: '¿Internacional?', dataKey: 'is_national'}
            ]

            // Información de la tabla
            var tableData = []
            this.filteredActivities.forEach(function(element){
                tableData.push({
                    name: element.name,
                    theme: element.theme,
                    activity_type: element.vime_activity_type_name,
                    start_date: element.start_date,
                    end_date: element.end_date,
                    is_national: element.is_national
                })
            })
            console.log(tableData)
            // Generación del PDF
            doc.autoTable(columnsNames, tableData, {
                headStyles:{
                    halign: 'center',
                    fillColor: [253, 126, 20],
                },
                bodyStyles:{
                    halign: 'center'
                },
                margin: { top: 180 },
                didDrawPage: function (data) { 
                    doc.setFillColor(253, 126, 20);
                    doc.rect(0, 0, 600, 10, "F");
                    doc.rect(0, 832, 600, 10, "F");
                    doc.addImage("/logo_negro.png", "JPEG", 20, 20, 150, 80);
                }
            })
            // Descarga
            doc.save('Calendario de actividades VIME mes '+this.month+'.pdf');
        }
    }
}
</script>