<template>
    <section>

        <div v-if="can_life_record === 'true'" class="columns">
            <div class="column has-text-right">
              <b-button  pack="fas" icon-left="file-alt" style="background-color: #e3f6f4; border-color:#00aea7" type="is-primary is-light" label="Ir a Mi Hoja de Vida" tag="a" :href="'/people/'+personId+'/life_record'" target="_blank"/>
            </div>
        </div>
        <b-tabs position="is-centered" @input="changeTab" :multiline="true">
            <b-tab-item v-for="item in items" v-bind:key="item.content" :value="item.content" :label="item.tab"
                :icon="item.icon" />
        </b-tabs>
        <!-- COMPONENTE AQUI -->
        <keep-alive>
            <component v-bind:is="currentTabComponent" :person-id="personId" :photo-url="photoUrl" :min-year="minYear" :max-year="maxYear" :change-filter="changeFilter"
                :cargo-principal="cargoPrincipal" :hide-update="true" :can-create-document="canCreateDocument" />
        </keep-alive>

        <!-- FIN COMPONENTE -->
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </section>
</template>

<script>
import MyAdministrativeFunctions from './my_administrative_functions_tab.vue'
export default {
    components:{
        MyAdministrativeFunctions,
    },
    props:[
        'personId','photoUrl', 'cargoPrincipal', 'canCreateDocument', 'minYear', 'maxYear', 'changeFilter', 'can_life_record'
    ],
    data() {
        return {
            isLoading:false,
            currentTab: 'MyAdministrativeFunctions',
            items: [
                {tab: 'Funciones Administrativas',content: 'MyAdministrativeFunctions', icon:"user-tie"},
            ],
        }
    },
    methods: {
        changeTab: function(value){
            this.currentTab = value
        },
    },
    computed: {
        currentTabComponent: function () {
            return this.currentTab
        }
    }
}
</script>