<template>
  <div class="modal-card" :style="'width:' + ancho + 'px'">
    <section class="modal-card-body">
      <div class="columns has-text-centered">
        <div class="column">Página: {{ currentPage }}/{{ pageCount }}</div>
      </div>

      <div class="columns">
        <div class="column is-6 has-text-right">
          <b-button icon-left="arrow-left" type="is-primary" @click="backPage">
            Retroceder página
          </b-button>
        </div>
        <div class="column is-6 has-text-left">
          <b-button icon-right="arrow-right" type="is-primary" @click="nextPage"
          >Siguiente página
          </b-button>
        </div>
      </div>

      <div class="buttons centered">
          <b-button icon-left="undo" type="is-primary is-light" @click="rotatePdf(1)">
          </b-button>
          <b-button icon-right="redo" type="is-primary is-light" @click="rotatePdf(2)">
          </b-button>
          <b-button icon-left="search-plus" type="is-primary is-light" @click="zoomIn()" :disabled="zoomInDisabled">
          </b-button>
          <b-button icon-right="search-minus" type="is-primary is-light" @click="zoomOut()" :disabled="zoomOutDisabled">
          </b-button>
      </div>

      <div class="centered">
        <pdf :page="currentPage" :src="src" @num-pages="pageCount = $event" :rotate="angle" :style="{ width: `${zoom}%` }"></pdf>
      </div>

      <div class="columns">
        <div class="column is-6 has-text-right">
          <b-button icon-left="arrow-left" type="is-primary" @click="backPage">
            Retroceder página
          </b-button>
        </div>
        <div class="column is-6 has-text-left">
          <b-button icon-right="arrow-right" type="is-primary" @click="nextPage"
          >Siguiente página
          </b-button>
        </div>
      </div>


    </section>
    <footer class="modal-card-foot actions">
      <div class="has-text-right is-grouped">
        <button
            class="button is-pulled-left"
            type="button"
            @click="closeModal()"
        >
          Cerrar
        </button>
      </div>
    </footer>
  </div>
</template>

<script>
import pdf from "vue-pdf";

export default {
  components: {
    pdf,
  },
  props: ["src"],
  data() {
    return {
      currentPage: 1,
      pageCount: null,
      angle: 0,
      zoom: 75,
      ancho: 1100,
      zoomInDisabled: false,
      zoomOutDisabled: false
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    backPage() {
      if (this.pageCount === null) return;
      if (this.currentPage - 1 < 1) return;
      this.currentPage -= 1;
    },
    nextPage() {
      if (this.pageCount === null) return;
      if (this.currentPage + 1 > this.pageCount) return;
      this.currentPage += 1;
    },
    handleKeyup(e) {
      if (e.repeat) {
        return;
      }
      switch (e.keyCode) {
        case 37:
          this.backPage();
          break;
        case 39:
          this.nextPage();
          break;
      }
    },
    rotatePdf(tipo){
      if (tipo === 1){
        this.angle -= 90
      }
      else if (tipo === 2){
        this.angle += 90
      }
      if (this.angle > 360){
        this.angle -= 360
      }
      if (this.angle < 0){
        this.angle += 360
      }
      if (this.ancho === 1100){
        this.ancho = 1200
      }
      else{
        this.ancho = 1100
      }
    },
    zoomIn(){
      if(this.zoom < 100){
        this.zoom += 5
        if(this.zoomOutDisabled){
          this.zoomOutDisabled = false
        }
      }
      else{
        this.zoomInDisabled = true
      }
    },
    zoomOut(){
      if (this.zoom > 30){
        this.zoom -= 5
        if(this.zoomInDisabled){
          this.zoomInDisabled = false
        }
      }
      else{
        this.zoomOutDisabled = true
      }
    },

  },
  created() {
    window.addEventListener("keyup", this.handleKeyup, null);
  },
};
</script>

<style>
.centered {
  display: flex;
  justify-content: center;
}

</style>