<template>
<section>
  <b-table
      :data="PostulatedProjects"
      :paginated=true
      :card-layout="cardLayout"
      per-page=10
      striped
      hoverable
      :current-page="currentPage"
      :pagination-simple=false
      pagination-position="bottom"
      default-sort-direction="asc"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort="project.name"
      aria-next-label="Siguiente"
      aria-previous-label="Anterior"
      aria-page-label="Página"
      aria-current-label="Página actual"
      :opened-detailed="defaultOpenedDetails"
      detailed
      detail-key="code"
      @details-open="(row) => $buefy.toast.open(`Expanded ${row.name}`)"
      :show-detail-icon="showDetailIcon"
  >
    <b-table-column
        field= 'name'
        label= 'Nombre'
        width="30em"
        searchable
        sortable>
      <template
          slot="searchable"
          slot-scope="props">
        <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small" />
      </template>
      <template v-slot="props">
        <a :href="getProjectUrl(props.row.id)">{{ props.row.name }}</a>
      </template>
    </b-table-column>
    <b-table-column
        field= 'project_type.name'
        label= 'Tipo de Proyecto'
        searchable
        sortable>
      <template
          slot="searchable"
          slot-scope="props">
        <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small" />
      </template>
      <template v-slot="props">
        {{ props.row.project_type ? props.row.project_type.name : "" }}
      </template>
    </b-table-column>
    <b-table-column
        field= 'code'
        label= 'Código'
        searchable
        sortable>
      <template
          slot="searchable"
          slot-scope="props">
        <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small" />
      </template>
      <template v-slot="props">
        {{ props.row.code }}
      </template>
    </b-table-column>
    <b-table-column
        field= 'postulation_date'
        label= 'Fecha Postulación'
        searchable
        :custom-search="funcionCustom"
        sortable>
      <template
          slot="searchable"
          slot-scope="props">
        <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small" />
      </template>
      <template v-slot="props">
        {{props.row.postulation_date._isValid ? new Date(props.row.postulation_date).toLocaleDateString("es-CL") : ''}}
      </template>
    </b-table-column>
    <b-table-column
        field= 'university_project_role.name'
        label= 'Rol Universidad'
        searchable
        sortable>
      <template
          slot="searchable"
          slot-scope="props">
        <b-input
            v-model="props.filters[props.column.field]"
            icon="search"
            size="is-small" />
      </template>
      <template v-slot="props">
        {{ props.row.university_project_role ? props.row.university_project_role.name : "" }}
      </template>
    </b-table-column>

    <template slot="detail" slot-scope="props">
      <article class="media">
        <div class="media-content">
          <div class="content">
            <p>
              <strong>Participantes: </strong>
              <span v-for="(participant, index) in props.row.participants" :key="index">
                  <a v-if="participant.url!=null" :href="participant.url">{{ participant.name }}</a>
                  <span v-else>{{ participant.name }}</span>
                  <span v-if="props.row.participants.length>1 && participant!=props.row.participants.slice(-1)[0]">, </span>
                </span>
            </p>
          </div>
        </div>
      </article>
    </template>
    <template slot="empty" slot-scope="props">
      <div class="columns is-centered">
        <div class="column is-6 is-centered has-text-centered">
          <b-message type="is-danger">
            No se encontraron resultados.
          </b-message>
        </div>
      </div>
    </template>
  </b-table>
  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
</section>
</template>

<script>
export default {
  name: "PostulatedProjectsTable",
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      years:[],
      year:null,

    }
  },
  props:['PostulatedProjects'],
  methods:{
    getProjectUrl(project_id) {
      return "/projects/postulated/" + project_id;
    },
    funcionCustom(row,input){
      return searchDate(row.postulation_date,input)
    }
  }
}
</script>

<style scoped>

</style>