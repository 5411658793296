<template>
  <div>
    <span>Componente padre</span>
    <b-field label="Puntaje">
      <b-input v-model="score"></b-input>
    </b-field>
    <show-son-c v-bind:score="score" @updating-score="updateScore"></show-son-c>

  </div>
</template>
<script>
import ShowSonC from "./show_son_c.vue"
import { bus } from "../../packs/app_vue";

export default {
  data(){
    return{
      score:100,

    }
  },
  components:{ShowSonC},
  created(){
    bus.$on('updating-score', this.updateScore)
  },
  props:[

  ],
  methods:{
    reRender() {
      this.$forceUpdate()
    },
    updateScore(newValue) {
      this.score = newValue
    }

  },
  computed:{

  }
}
</script>

