<template>
  <section>
    <div class="container">
      <h1 class="is-size-2">Servicios EFUSACH</h1>
      <div class="box" v-if="can_show_nullify=='true'">
        <div class="has-text-right" v-if="can_create==='true'">

          <b-button type="button is-primary"
                    icon-left="plus"
                    tag="a"
                    href="/sdt_services/new">
              Añadir Servicio EFUSACH
          </b-button>
        </div>
        
        <br>
        <b-tabs  type="is-toggle-rounded" position="is-centered" class="block">
            <b-tab-item label="Servicios Activos">
              <services-table v-bind:servicios="this.servicios" 
              :cargoPrincipal="cargoPrincipal"
              :unidad="unidadPrincipal.id"
              :message="message"
              :can-nullify="can_nullify"
              @updateServicesFilter="updateServicesFilter"
              @elementoAnulado="elementoAnulado"></services-table>

            </b-tab-item>
            <b-tab-item label="Servicios Anulados">
              <services-nullify-table v-bind:servicios="serviciosAnulados" 
              :cargoPrincipal="cargoPrincipal"
              :unidad="unidadPrincipal.id"
              :message="message" 
              :can-nullify="can_nullify"             
              @updateServicesFilter="updateServicesFilter"
              @elementoRestaurado="elementoRestaurado"
              ></services-nullify-table>

            </b-tab-item>
        </b-tabs>

        
          
      </div>
      <div class="box" v-else>

        <div class="has-text-right" v-if="can_create==='true'">

          <b-button type="button is-primary"
                    icon-left="plus"
                    tag="a"
                    href="/sdt_services/new">
              Añadir Servicio EFUSACH
          </b-button>
        </div>
        
        <br>
        <services-table v-bind:servicios="this.servicios" 
            :cargoPrincipal="cargoPrincipal"
            :unidad="unidadPrincipal.id"
            :message="message" 
            :can-nullify="can_nullify"
            @updateServicesFilter="updateServicesFilter"></services-table>

        
      </div>
     
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import axios from "axios";
import ServicesTable from "./ServicesTable.vue";
import ServicesNullifyTable from './NullifiedServices.vue'
import moment from "moment";

export default {
  components:{ServicesTable, ServicesNullifyTable},
  data(){
    return{
      isLoading:false,
      currentPage:1,
      servicios:[],
      serviciosAnulados: [],
      year: new Date().getFullYear(),
      message: '',

    }
  },
  async created(){
    if (this.can_show_nullify == 'true'){
      await this.getServicesNullufy();
    }
    this.getServices(this.year);
  },
  props:[
    'can_create', 'cargoPrincipal','unidadPrincipal', 'can_nullify', 'userId','can_show_nullify'
  ],
  methods:{
    
    getServiceUrl(service_id){
      return "/sdt_services/"+service_id;
    },
    getServices(year){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/sdt_services.json", {params:{year_f:year,}})
        .then(res => {
          vm.servicios = res.data;
          vm.servicios = vm.servicios.map(servicio => {
            var newServicio = servicio
            if(newServicio["start_date"]!=null)
              newServicio["start_date"] = moment(servicio.start_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            return newServicio;
          });
          vm.message = "Se han encontrado "+vm.servicios.length+" servicios que cumplen su búsqueda"
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    getServicesNullufy(){
      let vm = this;
      axios.get("/sdt_services.json",{params:{year_f:-1}})
        .then(res => {
          vm.serviciosAnulados = res.data;
          vm.serviciosAnulados = vm.serviciosAnulados.map(servicio => {
            var newServicio = servicio
            if(newServicio["start_date"]!=null)
              newServicio["start_date"] = moment(servicio.start_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            return newServicio;
          });
        })
        .catch(e => {
          console.log(e);
        });
      },
    getServicesFilter(year,name,code, service_type,unit){
      let vm = this;
      vm.isLoading=true;
      return axios.get("/sdt_services.json", {params:{year_f:year,
                                              name:name,
                                              code:code,
                                              service_type:service_type,
                                              unit:unit,
                                              }})
        .then(res => {
          vm.servicios = res.data;
          vm.servicios = vm.servicios.map(servicio => {
            var newServicio = servicio
            if(newServicio["start_date"]!=null)
              newServicio["start_date"] = moment(servicio.start_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
            return newServicio;
          });
          vm.message = "Se han encontrado "+vm.servicios.length+" servicios que cumplen su búsqueda"
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    elementoAnulado(element){
      this.serviciosAnulados.push(element)
    },
    elementoRestaurado(element){
      this.servicios.push(element)
    },
    updateServices(year){
      this.getServices(year);
    },
    updateServicesFilter(datos){
      this.getServicesFilter(datos["year"], datos["name"], datos["code"], datos["type"], datos["unit"]);
    }

  },
  computed:{

  }
}
</script>
