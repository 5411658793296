<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveProgram)">
        <div class="modal-card" id="modal-event">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" id="modal-events">
            <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
              <b-message type="is-danger" has-icon>
                <div v-for="(item,index) in errores_validacion" :key='index'>
                  {{ item[0] }}
                </div>
              </b-message>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Nombre*">
                  <ValidationProvider rules="required|min:3|max:255" v-slot="{ errors }">
                    <b-input type="text" maxlength="255"
                             placeholder="Ingrese nombre del programa"
                             v-model="name"
                             :has-counter=false>
                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descripción">
                  <ValidationProvider rules="min:3|max:4000" v-slot="{ errors }">
                    <b-input type="textarea" maxlength="4000"
                             placeholder="Ingrese descripción del programa"
                             v-model="description"
                             :has-counter=false>
                    </b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field
                    label="Url"
                    :type="{ 'is-danger': checkLink }"
                    :message="{ 'Formato no válido.': checkLink}">
                  <b-input type="text" maxlength="255"
                           placeholder="https://link.cl"
                           v-model="program_url"
                           :has-counter=false>
                  </b-input>
                </b-field>
              </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="columns">
            <div class="column">
              <div class="field is-grouped is-grouped-left">
                <button class="button" type="button" @click="$emit('close')">Cerrar</button>
              </div>
            </div>
            <div class="column">
              <div class="field is-grouped is-grouped-right">
                <button class="button is-primary" type="submit" >Guardar Programa</button>
              </div>
            </div>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import axios from 'axios'
export default {
  name: "modal_new_programs",
  props:[
    'modalHeader','partner_entity_id', 'queryType', 'programRow'
  ],
  data(){
    return {
      isLoading: false,
      name: null,
      description: null,
      program_url: null,
      checkFormat: null,
      errores_validacion: {},
    }
  },
  created() {
    if (this.queryType == 'edit'){
      this.isLoading = true

      if (this.programRow.alt_name == null || this.programRow.alt_name == ''){
        this.name = this.programRow.name
      }
      else{
        this.name = this.programRow.alt_name
      }

      this.description = this.programRow.description
      this.program_url = this.programRow.program_url
      this.isLoading = false
    }
  },
  computed: {
    checkLink(){
      var regExp = /http[s]?\:\/\/[a-zA-Z 0-9]+\.[a-z]+/
      if (this.program_url == null || this.program_url == ''){
        this.checkFormat = false
        return false
      }
      else{
        this.checkFormat = !regExp.test(this.program_url)
        return !regExp.test(this.program_url)
      }
    }
  },
  methods:{
    saveProgram(){
      if (this.checkFormat == false){
        if (this.queryType == 'create'){
          this.isLoading = true
          var programs = {
            name: this.name,
            description: this.description,
            program_url: this.program_url,
            partner_entity_id: this.partner_entity_id
          }
          axios.post('/programs.json',programs)
              .then(
                  response =>{
                    this.errores_validacion = {}
                    this.$emit('clicked')
                    this.isLoading = false
                    this.$emit('close')
                  })
              .catch(
                  e => {
                    console.log(e)
                    this.errores_validacion = e.response.data
                    this.$buefy.dialog.alert({
                      message: "Faltan algunos campos o presentan errores de formato. Por favor revisar",
                      type: 'is-danger',
                      hasIcon: true,
                      icon: 'times-circle',
                      onConfirm: () => {
                      }
                    })
                    this.isLoading = false
                  }
              )
        }
        if (this.queryType == 'edit'){
          this.isLoading = true
          var programs = {
            name: this.name,
            description: this.description,
            program_url: this.program_url,
            partner_entity_id: this.partner_entity_id
          }
          axios.put('/programs/'+this.programRow.id+'.json',programs)
              .then(
                  response =>{
                    this.errores_validacion = {}
                    this.$emit('clicked')
                    this.$emit('close')
                  })
              .catch(
                  e => {
                    console.log(e)
                    this.errores_validacion = e.response.data
                    this.$buefy.dialog.alert({
                      message: "Faltan algunos campos o presentan errores de formato. Por favor revisar",
                      type: 'is-danger',
                      hasIcon: true,
                      icon: 'times-circle',
                      onConfirm: () => {
                      }
                    })
                    this.isLoading = false
                  }
              )
        }
      }
    },

  }
}
</script>
