<template>
  <section>
    <b-steps
        v-model="activeStep"
        :animated="isAnimated"
        :rounded="isRounded"
        :has-navigation="hasNavigation"
        :icon-prev="prevIcon"
        :icon-next="nextIcon"
        :label-position="labelPosition"
        :mobile-mode="mobileMode">
      <b-step-item step="1" label="Importación archivo de documentos" :clickable="isStepsClickable">
        <h1 class="title has-text-centered">Importación archivo de documentos</h1>
        <b-tabs position="is-centered" class="block">
          <b-tab-item label="Importación archivo de documentos" >
            <ValidationObserver v-slot="{handleSubmit}">
              <form @submit.prevent="handleSubmit(import_file)">
                <div class="box">
                  <div class="columns">
                    <div class="column is-centered">
                      <b-message type="is-info" has-icon v-if="importacion">
                        <b>Documentos importados correctamente: </b> {{documentos.length}} de {{total_documentos}}<br/>
                      </b-message>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field grouped group-multiline>
                        <div class="control">
                          <h1 class="title is-4">Seleccionar archivo de documentos</h1>
                          <h2 class="subtitle is-6 has-text-weight-bold">*El archivo debe tener la extensión .xlsx.</h2>
                        </div>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div>
                        <b-field class="file">
                          <b-upload v-model="file" expanded>
                            <a class="button is-primary is-outlined">
                              <b-icon icon="upload"></b-icon>
                              <span>{{ file.name || "Subir Archivo"}}</span>
                            </a>
                          </b-upload>
                        </b-field>
                      </div>
                    </div>
                    <div class="column">
                      <div class="has-text-right">
                        <b-button
                            label="Ver Ejemplo"
                            type="is-primary"
                            @click="isModalSpreadSheet = true" />
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="has-text-right">
                        <button class="button is-primary" :disabled="file.name.length===0" type="submit">Importar</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </b-tab-item>
          <b-tab-item label="Errores y advertencias de importación" v-if="importacion">
            <div class="columns">
              <div class="column is-centered">
                <b-message type="is-info" has-icon>
                  <b>Advertencias:</b> Inconsistencias respecto a las carpetas del archivo de importación que no existen y deberán ser creadas, o fechas inválidas que no podrán ser creadas.<br/>
                  <b>Errores:</b> Problemas en el archivo de importación que impiden completamente la creación del documento. Pueden ser errores de tipologías.
                </b-message>
              </div>
            </div>
            <div v-if="errores.length>0" class="columns is-centered">
              <div class="column is-11" v-if="errores.length >0">
                <div class="box">
                  <h1 class="is-size-5">
                    <b-icon
                        pack="fas"
                        icon="exclamation-triangle">
                    </b-icon>
                    Errores del archivo: {{ this.file_name_errors }}
                  </h1>
                  <div v-if="import_type != 'Convenios'" class="ml-5" v-for="error in errores">
                    Fila n° {{error.FILA}}: {{ error.ERRORES }}
                  </div>
                  <div v-if="import_type == 'Convenios'" class="ml-5" v-for="error in errores">
                    Hoja:{{error.PAGINA}} -> Fila n° {{error.FILA}}: {{ error.ERRORES }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="advertencias.length>0" class="columns is-centered">
              <div class="column is-11" v-if="advertencias.length>0">
                <div class="box">
                  <h1 class="is-size-5">
                    <b-icon
                        pack="fas"
                        icon="exclamation-triangle">
                    </b-icon>
                    Advertencias del archivo: {{ this.file_name_errors }}
                  </h1>
                  <div v-if="import_type != 'Convenios'" class="ml-5" v-for="advertencia in advertencias">
                    Fila n° {{advertencia.FILA}}: {{ advertencia.ERRORES }}
                  </div>
                </div>
              </div>
            </div>
            <div class="columns has-text-right">
              <div class="column">
                <b-button @click="importErrors" v-if="errores.length!=0 || advertencias.length != 0" icon-left="file-excel" class="is-excel-color">Exportar errores y advertencias en excel</b-button>
              </div>
            </div>
          </b-tab-item>
        </b-tabs>
      </b-step-item>
      <b-step-item step="2" label="Agregar Documentos" :clickable="isStepsClickable" v-if="importacion">
        <b-tabs position="is-centered" class="block" v-if="importacion">
          <b-tab-item label="Documentos Importados">
            <div class="box">
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(submitDocuments)">
                  <div class="columns">
                    <div class="column">
                      <b-field grouped group-multiline>
                        <div class="control">
                          <h1 class="title is-5">Seleccionar documentos a agregar</h1>
                        </div>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-centered">
                      <b-message type="is-info" has-icon v-if="postAdd">
                        <b>Documentos agregados correctamente: </b> {{successful_documents.length}} de {{checkedRows.length}}.<br/>
                      </b-message>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                        <b-message type="is-danger" has-icon>
                          <div v-for="(item,index) in errores_validacion">
                            {{ item[0] }}
                          </div>
                        </b-message>
                      </div>
                    </div>
                  </div>
                  <div v-if="documentos.length > 0" class="column">
                    <div class="has-text-right">
                      <button class="button is-primary" type="submit">Agregar Documentos</button>
                    </div>
                  </div>
                  <b-table
                      :data="documentos"
                      :paginated= true
                      per-page="20"
                      striped
                      hoverable
                      style="word-break:break-all;"
                      narrowed
                      :current-page="currentPage"
                      :pagination-simple=false
                      pagination-position="both"
                      default-sort-direction="asc"
                      sort-icon="arrow-up"
                      sort-icon-size="is-small"
                      aria-next-label="Siguiente"
                      aria-previous-label="Anterior"
                      aria-page-label="Página"
                      aria-current-label="Página actual"
                      :checked-rows.sync="checkedRows"
                      checkable
                      :checkbox-position="checkboxPosition"
                      @check-all="selectAll">
                    <b-table-column
                        field="index"
                        label="N°">
                      <template v-slot="props">
                        {{ documentos.indexOf(props.row) + 1 }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="name"
                        label="Nombre"
                        sortable
                        width="300"
                        searchable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.name}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="carpeta"
                        label="Carpeta del Documento"
                        width="300"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.carpeta}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="resolution"
                        label="N°"
                        searchable
                        width="50"
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.resolution}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="document_type.name"
                        label="Tipo Documento"
                        width="200"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.document_type!==null ? props.row.document_type.name: ""}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="google_id"
                        label="ID Google (Doc)"
                        width="150"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.google_id}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="document_date"
                        label="Fecha"
                        width="110"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.formatted_date}}
                      </template>
                    </b-table-column>
                    <template slot="empty" slot-scope="props">
                      <div class="columns is-centered">
                        <div class="column is-6 is-centered has-text-centered">
                          <b-message type="is-danger">
                            No se encontraron resultados para filtros aplicados.
                          </b-message>
                        </div>
                      </div>
                    </template>
                  </b-table>

                </form>
              </ValidationObserver>
            </div>
          </b-tab-item>
          <b-tab-item label="Documentos Anulados">
                <div class="box">
                  <div class="columns">
                      <div class="column">
                          <b-field grouped group-multiline>
                              <div class="control">
                                  <h1 class="title is-5">Documentos que seran anulados</h1>
                              </div>
                          </b-field>
                      </div>
                  </div>
                    <div class="columns">
                        <div class="column is-centered">
                            <b-message type="is-info" has-icon v-if="postAdd">
                                <b>Documentos anulados correctamente: </b> {{total_anulados}} de {{total_documentos_anular}}.<br/>
                            </b-message>
                        </div>
                    </div>
                    <div v-if="documentos.length == 0 && documentos_anulados.length > 0" class="column">
                        <div class="has-text-right">
                            <button class="button is-primary" @click="submitDocuments">Anular Documentos</button>
                        </div>
                    </div>
                  <b-table
                          :data="documentos_anulados"
                          :paginated= true
                          per-page="20"
                          striped
                          hoverable
                          style="word-break:break-all;"
                          narrowed
                          :current-page="currentPage"
                          :pagination-simple=false
                          pagination-position="both"
                          default-sort-direction="asc"
                          sort-icon="arrow-up"
                          sort-icon-size="is-small"
                          aria-next-label="Siguiente"
                          aria-previous-label="Anterior"
                          aria-page-label="Página"
                          aria-current-label="Página actual"
                          >
                      <b-table-column
                              field="index"
                              label="N°">
                          <template v-slot="props">
                              {{ documentos_anulados.indexOf(props.row) + 1 }}
                          </template>
                      </b-table-column>
                      <b-table-column
                              field="name"
                              label="Nombre"
                              sortable
                              width="300"
                              searchable>
                          <template
                                  slot="searchable"
                                  slot-scope="props">
                              <b-input
                                      v-model="props.filters[props.column.field]"
                                      placeholder=" Buscar"
                                      icon="search"
                                      size="is-small" />
                          </template>
                          <template v-slot="props">
                              {{ props.row.name}}
                          </template>
                      </b-table-column>
                      <b-table-column
                              field="carpeta"
                              label="Carpeta del Documento"
                              width="300"
                              searchable
                              sortable
                      >
                          <template
                                  slot="searchable"
                                  slot-scope="props">
                              <b-input
                                      v-model="props.filters[props.column.field]"
                                      placeholder=" Buscar"
                                      icon="search"
                                      size="is-small" />
                          </template>
                          <template v-slot="props">
                              {{props.row.carpeta}}
                          </template>
                      </b-table-column>
                      <b-table-column
                              field="resolution"
                              label="N°"
                              searchable
                              width="50"
                              sortable
                      >
                          <template
                                  slot="searchable"
                                  slot-scope="props">
                              <b-input
                                      v-model="props.filters[props.column.field]"
                                      placeholder=" Buscar"
                                      icon="search"
                                      size="is-small" />
                          </template>
                          <template v-slot="props">
                              {{props.row.resolution}}
                          </template>
                      </b-table-column>
                      <b-table-column
                              field="document_type.name"
                              label="Tipo Documento"
                              width="200"
                              searchable
                              sortable
                      >
                          <template
                                  slot="searchable"
                                  slot-scope="props">
                              <b-input
                                      v-model="props.filters[props.column.field]"
                                      placeholder=" Buscar"
                                      icon="search"
                                      size="is-small" />
                          </template>
                          <template v-slot="props">
                              {{props.row.document_type!==null ? props.row.document_type: ""}}
                          </template>
                      </b-table-column>
                      <b-table-column
                              field="google_id"
                              label="ID Google (Doc)"
                              width="150"
                              searchable
                              sortable
                      >
                          <template
                                  slot="searchable"
                                  slot-scope="props">
                              <b-input
                                      v-model="props.filters[props.column.field]"
                                      placeholder=" Buscar"
                                      icon="search"
                                      size="is-small" />
                          </template>
                          <template v-slot="props">
                              {{props.row.google_id}}
                          </template>
                      </b-table-column>
                      <b-table-column
                              field="document_date"
                              label="Fecha"
                              width="110"
                              searchable
                              sortable
                      >
                          <template
                                  slot="searchable"
                                  slot-scope="props">
                              <b-input
                                      v-model="props.filters[props.column.field]"
                                      placeholder=" Buscar"
                                      icon="search"
                                      size="is-small" />
                          </template>
                          <template v-slot="props">
                              {{props.row.document_date}}
                          </template>
                      </b-table-column>
                      <template slot="empty" slot-scope="props">
                          <div class="columns is-centered">
                              <div class="column is-6 is-centered has-text-centered">
                                  <b-message type="is-info">
                                      No se encontraron documentos para ser anulados.
                                  </b-message>
                              </div>
                          </div>
                      </template>
                  </b-table>
                </div>
            </b-tab-item>
<!--          <b-tab-item label="Carpertas Anuladas">
                <div class="box">
                    <div class="columns">
                        <div class="column">
                            <b-field grouped group-multiline>
                                <div class="control">
                                    <h1 class="title is-5">Carpetas que seran anuladas</h1>
                                </div>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-centered">
                            <b-message type="is-info" has-icon v-if="postAdd">
                                <b>Carpetas anuladas correctamente: </b> {{total_carpetas_anuladas}} de {{total_carpetas_anular}}.<br/>
                            </b-message>
                        </div>
                    </div>
                    <b-table
                            :data="carpetas_anuladas"
                            :paginated= true
                            per-page="20"
                            striped
                            hoverable
                            style="word-break:break-all;"
                            narrowed
                            :current-page="currentPage"
                            :pagination-simple=false
                            pagination-position="both"
                            default-sort-direction="asc"
                            sort-icon="arrow-up"
                            sort-icon-size="is-small"
                            aria-next-label="Siguiente"
                            aria-previous-label="Anterior"
                            aria-page-label="Página"
                            aria-current-label="Página actual">
                        <b-table-column
                                field="index"
                                label="N°"
                                width="40">
                            <template v-slot="props">
                                {{ carpetas_anuladas.indexOf(props.row) + 1 }}
                            </template>
                        </b-table-column>
                        <b-table-column
                                field="name"
                                label="Nombre"
                                sortable
                                searchable>
                            <template
                                    slot="searchable"
                                    slot-scope="props">
                                <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder=" Buscar"
                                        icon="search"
                                        size="is-small" />
                            </template>
                            <template v-slot="props">
                                {{ props.row.name}}
                            </template>
                        </b-table-column>
                        <b-table-column
                                field="carpeta"
                                label="Carpeta del Documento"
                                searchable
                                sortable
                        >
                            <template
                                    slot="searchable"
                                    slot-scope="props">
                                <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder=" Buscar"
                                        icon="search"
                                        size="is-small" />
                            </template>
                            <template v-slot="props">
                                {{props.row.carpeta}}
                            </template>
                        </b-table-column>
                        <b-table-column
                                field="google_id"
                                label="ID Google (Doc)"
                                searchable
                                sortable
                        >
                            <template
                                    slot="searchable"
                                    slot-scope="props">
                                <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder=" Buscar"
                                        icon="search"
                                        size="is-small" />
                            </template>
                            <template v-slot="props">
                                {{props.row.google_id}}
                            </template>
                        </b-table-column>
                        <template slot="empty" slot-scope="props">
                            <div class="columns is-centered">
                                <div class="column is-6 is-centered has-text-centered">
                                    <b-message type="is-info">
                                        No se encontraron carpetas para ser anuladas.
                                    </b-message>
                                </div>
                            </div>
                        </template>
                    </b-table>
                </div>
            </b-tab-item>-->
          <b-tab-item label="Errores documentos" v-if="errores_documentos.length>0">
            <b-table
                :data="errores_documentos"
                :paginated= true
                per-page="20"
                striped
                hoverable
                style="word-break:break-all;"
                :narrowed=true
                :current-page="currentPage_2"
                :pagination-simple=false
                pagination-position="both"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="product.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
              <b-table-column
                  field="index"
                  width="20"
                  label="N°">
                <template v-slot="props">
                  {{ errores_documentos.indexOf(props.row) + 1 }}
                </template>
              </b-table-column>
              <b-table-column
                  field="name"
                  label="Nombre"
                  width="150"
                  sortable
                  searchable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{ props.row.name}}
                </template>
              </b-table-column>
              <b-table-column
                  field="carpeta"
                  label="Carpeta de Documento"
                  searchable
                  sortable
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.carpeta}}
                </template>
              </b-table-column>
              <b-table-column
                  field="google_id_carpeta"
                  label="ID Google (Carpeta)"
                  width="150"
                  searchable
                  sortable
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.google_id_carpeta}}
                </template>
              </b-table-column>
              <b-table-column
                  field="google_id"
                  label="ID Google (Doc)"
                  width="150"
                  searchable
                  sortable
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.google_id}}
                </template>
              </b-table-column>
              <b-table-column
                  field="motivo"
                  label="Razón de error"
              >
                <template v-slot="props">
                  {{props.row.motivo}}
                </template>
              </b-table-column>
              <template slot="empty" slot-scope="props">
                <div class="columns is-centered">
                  <div class="column is-6 is-centered has-text-centered">
                    <b-message type="is-danger">
                      No se encontraron resultados para filtros aplicados.
                    </b-message>
                  </div>
                </div>
              </template>
            </b-table>
          </b-tab-item>
        </b-tabs>
      </b-step-item>
    </b-steps>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="isModalSpreadSheet"
        has-modal-card
        trap-focus
        full-screen
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal-spreadsheet-documents
            modal-header="Cabeceras y Ejemplo"
            @close="props.close">
        </modal-spreadsheet-documents>
      </template>
    </b-modal>


  </section>
</template>
<script>
import axios from "axios"
import moment from "moment";
import {searchDate, formatDate} from "../../packs/utilities";
import ModalSpreadsheetDocuments from "./spreadsheet/spreadsheet_documents"

export default {
  name: "import_documents",
  components:{
    ModalSpreadsheetDocuments
  },
  data(){
    return{
      import_type:"",
      file:{name:""},
      dropFiles: [],
      isLoading:false,
      errores:[],
      file_name_errors:"",
      cabeceras:[],
      hojas:[],
      errorFile:{name:""},
      documentos: [],
      importacion: false,
      errores_documentos: [],
      document_types: [],
      checkedRows: [],
      checkboxPosition: "right",
      date_validation: true,
      currentPage: 1,
      currentPage_2: 1,
      isAlertModalActive: false,
      errores_validacion:{},
      valid_dates: false,
      year: null,
      isModalSpreadSheet: false,
      activeStep: 0,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      hasNavigation: true,
      customNavigation: false,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      advertencias: [],
      total_documentos: 0,
      message: "",
      postAdd: false,
      successful_documents: 0,
      documentos_anulados: [],
      total_anulados: 0,
      total_documentos_anular: 0,
      carpetas_anuladas: [],
      total_carpetas_anuladas:0,
      total_carpetas_anular:0

    }
  },
  created(){
    this.initialize()
  },
  props:[

  ],
  methods:{
    updateDocuments(){
      this.isLoading = true;
      if(this.checkedRows.length === 0 && this.documentos_anulados.length === 0){
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Debe seleccionar al menos un documento',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = false;
        return
      }
      axios
          .post("/documents/add-documents.json", {
            documents: this.checkedRows,
            documents_delete: this.documentos_anulados,
            folders_delete: this.carpetas_anuladas
          })
          .then(response =>{
            this.errores_validacion = response.data["errores_hash"];
            this.errores_documentos = response.data["errores_array"];
            this.successful_documents = response.data["successful_documents"]
            this.total_documentos_anular = response.data["total_documentos_anular"]
            this.total_anulados = response.data["total_anulados"]
            this.total_carpetas_anuladas = response.data["total_carpetas_anuladas"]
            this.total_carpetas_anular = response.data["total_carpetas_anular"]
            this.documentos_anulados = response.data["documentos_no_anulados"]
            this.postAdd = true
            if(this.errores_documentos.length>0 && this.successful_documents > 0){
              this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Los documentos fueron agregados con éxito, pero sucedieron algunos errores, revise pestaña "Errores Documentos"',
                type: 'is-warning',
                hasIcon: true,
                confirmText: 'Ok',
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
            }
            else if(this.successful_documents.length === 0 && this.errores_documentos.length > 0){
              this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Los documentos no fueron agregados con éxito, revise pestaña "Errores Documentos"',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
            }
            else{
              this.$buefy.dialog.alert({
                message: 'Los documentos fueron agregados con éxito ',
                confirmText: 'Ok'
              })
            }
            this.isLoading=false;
            this.errors = [];
            this.$emit('close')
          }).catch(error => {
        this.errores_validacion = error.response.data
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo errores al intentar agregar los documentos seleccionados. Revise los datos ingresados.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = false;
      })
    },
    selectAll(rows) {
      if (rows.length > this.checkedRows.length) {
        this.$nextTick(() => { this.checkedRows = this.documentos})
      } else {
        this.$nextTick(() => {
          this.checkedRows = []
        })
      }
    },
    searchEndDate(row,input){
      return searchDate(row.end_date,input)
    },
    searchStart(row,input){
      return searchDate(row.start_date,input)
    },
    submitDocuments(){
        this.updateDocuments()
    },
    initialize(){
      this.isLoading = true
      this.isLoading = false
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    import_file(){
      this.isLoading = true;
      this.importacion = false;
      this.successful_documents = 0
      this.file_name_errors = "";
      this.errores = [];
      this.advertencias = [];
      this.cabeceras = [];
      this.checkedRows = [];
      this.postContinuity = false;
      let formData = new FormData();
      formData.append('file', this.file);
      this.errorFile = this.file;
      axios.post("/documents/import-documents.json",
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
          }
      ).then(response =>{
        this.documentos = response.data["documentos"]
        this.total_documentos = response.data["total_documentos"]
        this.documentos_anulados = response.data["doc_show_anulado"]
        this.carpetas_anuladas = response.data["carpetas_anular"]
        console.log(response.data["dif_ids_folder"])
        console.log(response.data["dif_ids_document"])

        if (response.data["errores"].length>0){
          this.file_name_errors = this.file.name;
          this.errores = response.data["errores"];
          this.advertencias = response.data["advertencias"]
          this.cabeceras = response.data["cabeceras"];
          this.importacion = true
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: 'Algunos datos del archivo "'+this.file.name+'" no han podido ser importados, por favor, revisar errores',
            confirmText: 'Ok',
            type: 'is-warning',
            hasIcon: true,
            icon: 'exclamation-triangle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        }
        else{
          this.import_type = '';
          this.importacion = true
          this.advertencias = response.data["advertencias"]
          this.documentos = response.data["documentos"]
          this.total_documentos = response.data["total_documentos"]
          this.$buefy.dialog.alert({
            message: 'Su archivo "'+this.file.name+'" ha sido importado con éxito',
            confirmText: 'Ok'
          })
          this.file={name:""};
          this.errores = [];
          this.file_name_errors="";
          this.isLoading = false;

        }

      })
          .catch(error => {
            //console.log('FAILURE!!');
            this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Hubo errores en la importación de su archivo. Puede ser un archivo no válido, presenta errores de formato',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.isLoading = false;
          });
    },
    importErrors(){
      let vm = this;
      vm.isLoading=true;
      axios
          .post("/documents/export-errors.xlsx",{cabeceras:vm.cabeceras,errores:vm.errores,hojas:vm.hojas, advertencias: vm.advertencias},
              {
                responseType: "blob"
              })
          .then(response =>{
            vm.isLoading=false;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Errores y advertencias importacion masiva documentos '+vm.errorFile.name.replace(".xlsx","")+' '+new Date().toLocaleDateString()+'.xlsx');
            document.body.appendChild(link);
            link.click();
          }).catch(error => {
        vm.isLoading=false;
        vm.$buefy.notification.open({
          message: 'Hubo un error en la solicitud',
          type: 'is-danger'
        })
      })


    }

  },
  computed:{

  }
}
</script>