<template>
    <section>

        <div class="container">

            <div class="columns">
                <div class="column has-text-centered">
                    <div class="heading is-size-6">Publicaciones Totales en Revista</div>
                    <div class="title">{{ total }}</div>
                </div>
            </div>
            
        </div>

    </section>
</template>

<script>
export default{
    props: [
        'total',
    ],
    
    data() {
        return {
        }
    },
}
</script>