<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveData)">
        <div class="modal-card">
          <header class="modal-card-head" style="width: auto">
            <p class="modal-card-title">Periodo Resolución</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body">
            <div class="columns">
              <div class="column">
                <b-field label="Semestre resolución*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input v-model="semester" type="number" :max="2" :min="1"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Año resolución*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input v-model="anio" type="number" :max="2500" :min="1800"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit">Guardar</button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
export default {
  name: "modal_semester_year",
  data(){
    return{
      semester: null,
      anio: null
    }
  },
  methods :{
    saveData(){
      this.$emit('clicked',({semester: this.semester,
      anio: this.anio}))
      this.$emit('close')
    }
  }
}
</script>
