<template>
    <div class="agreementshistorynewview">
        <AgreementsHistoryNewForm   :agreement_history_stages='this.agreement_history_stages' 
                                    :agreement_history_processes='this.agreement_history_processes' 
                                    :agreement_history_actors='this.agreement_history_actors'
                                    :agreement_id='this.agreement_id'/>
    </div>
</template>
<script>
import axios from "axios"
import AgreementsHistoryNewForm from './agreements_history_new_form.vue'
export default {
    name: "AgreementsHistoryNewView",
    components:{
        AgreementsHistoryNewForm
    },
    props:{
        agreement_id:{
            type: Number
        }
    },
    data (){
        return {
            agreement_history_stages: [],
            agreement_history_processes: [],
            agreement_history_actors: []
        }
    },
    methods:{
        getAgreementHistoryStages(){
            axios.get('/index_active_agreement_stages.json')
            .then(response =>{
                this.agreement_history_stages = response.data
            })
            .catch(e => { 
                console.log(e)
            })
        },
        getAgreementHistoryProcesses(){
            axios.get('/index_active_agreement_processes.json')
            .then(response =>{
                this.agreement_history_processes = response.data
            })
            .catch(e => { 
                console.log(e)
            })
        },
        getAgreementHistoryActors(){
            axios.get('/index_active_agreement_actors.json')
            .then(response =>{
                this.agreement_history_actors = response.data
            })
            .catch(e => { 
                console.log(e)
            })
        }
    },
    beforeMount(){
        this.getAgreementHistoryStages()
        this.getAgreementHistoryProcesses()
        this.getAgreementHistoryActors()
    }
}
</script>