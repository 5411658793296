<template>
    <div class="agreementhistory">
        <div>
            <div v-if="can_create_history" class="columns">
              <div class="column">
                <span class="is-pulled-right">
                  <button class="button is-primary" @click="addNewAgreementHistory()">
                  <b-icon icon="plus"></b-icon>
                  <span>Añadir Antecedente</span>
                </button>
                </span>
              </div>
            </div>
          <div class="columns">
            <div class="column">
              <b-steps style="text-align:center;">
                <template v-for="(data, index) in sortAgreemetHistory">
                  <b-step-item :clickable="true" :label="data.agreement_stage_name" :key='index'>
                    <AgreementHistoryTable :can_create_history="can_create_history"  :agreement_history='data.agreement_stage_process' :agreement_id='agreement_id'/>
                  </b-step-item>
                </template>
              </b-steps>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
import AgreementHistoryTable from './agreement_history_table.vue'

export default {
    name: 'AgrementHistory',
    props:{
        agreement_id:{
            type: Number
        },
        agreement_history_found:{
            type: Boolean
        },
        agreement_history:{
            type: Array
        },
      can_create_history:{
          type: Boolean
      }
    },
    components:{
        AgreementHistoryTable
    },
    data(){
        return {
            variablePrueba: 'Solicitud (texto prueba)'
        }
    },
    computed:{
        sortAgreemetHistory(){
            this.agreement_history.sort(function(a,b){
                return a.agreement_stage_id - b.agreement_stage_id
            })
            return this.agreement_history
        }
    },
    methods:{
        addNewAgreementHistory(){
            window.location.href="/agreement_histories/new/"+this.agreement_id
        }
    }
}
</script>