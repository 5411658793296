<template>
  <section>
    <div class="box">
      <div class="columns">
        <div class="column">
          <b-field grouped group-multiline>
            <div class="control">
              <h1 class="title is-4">Seleccionar archivo de ingreso masivo</h1>
              <h2 class="subtitle is-6 has-text-weight-bold">*El archivo debe tener la extensión .xlsx.</h2>
            </div>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div >
            <b-field class="file">
              <b-upload v-model="file" expanded>
                <a class="button is-primary is-outlined">
                  <b-icon icon="upload"></b-icon>
                  <span>{{ file.name || "Subir Archivo"}}</span>
                </a>
              </b-upload>
            </b-field>
          </div>
        </div>
        <div class="column">
          <div class="has-text-right">
            <b-button
                label="Ver Ejemplo"
                type="is-primary"
                @click="isModalSpreadSheet = true" />
          </div>
        </div>
      </div>
    </div>

        <h1 class="title has-text-centered">Importación de Nombramientos</h1>
        <div class="column">
          <div class="has-text-right">
            <button class="button is-primary" :disabled="file.name.length===0" @click="import_file_nombramientos" >Cargar Nombramientos</button>
          </div>
        </div>
        <b-tabs position="is-centered" class="block" v-if="importacion_nombramientos">
          <b-tab-item label="Nombramientos Ingresables" v-if="importacion_nombramientos">
            <div class="box">
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(updateAppointments)">
                  <div class="columns">
                    <div class="column is-centered">
                      <b-message type="is-info" has-icon v-if="importacion_nombramientos">
                        <b>Total nombramientos importados: </b>{{ nombramientos.length }} de {{nombramientos.length+errores_nombramientos.length}}.<br/>
                      </b-message>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-centered">
                      <b-message type="is-info" has-icon v-if="carga_nombramientos">
                        <b>Total nombramientos ingresados: </b>{{ correctos.length }} de {{nombramientos_para_ingresar}}.<br/>
                      </b-message>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field grouped group-multiline>
                        <div class="control">
                          <h1 class="title is-5">Seleccionar nombramientos a ingresar</h1>
                        </div>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="mb-2" v-if="Object.keys(errores_validacion_nombramientos).length !== 0">
                        <b-message type="is-danger" has-icon>
                          <div v-for="(item,index) in errores_validacion_nombramientos">
                            {{ item[0] }}
                          </div>
                        </b-message>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field grouped group-multiline>
                        <div class="control">
                          <h1 class="title is-5">Seleccionar año y semestre de ingresos</h1>
                        </div>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="Año*">
                        <ValidationProvider rules="required|numeric|min_value:1980" v-slot="{errors}">
                          <b-input v-model="year"></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Semestre*">
                        <ValidationProvider rules="required|numeric|between:1,2" v-slot="{errors}">
                          <b-input v-model="semester"></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>
                  <div class="column">
                    <div class="has-text-right">
                      <button class="button is-primary" type="submit" :disabled="checkedRows2.length<1">Ingresar Nombramientos</button>
                    </div>
                  </div>

                  <b-table
                      :data="nombramientos"
                      :paginated= true
                      per-page="20"
                      striped
                      hoverable
                      :narrowed=true
                      :current-page="currentPage"
                      :pagination-simple=false
                      pagination-position="both"
                      default-sort-direction="asc"
                      sort-icon="arrow-up"
                      sort-icon-size="is-small"
                      default-sort="product.title"
                      aria-next-label="Siguiente"
                      aria-previous-label="Anterior"
                      aria-page-label="Página"
                      aria-current-label="Página actual"
                      :checked-rows.sync="checkedRows2"
                      checkable
                      :checkbox-position="checkboxPosition"
                      @check-all="selectAll2">
                    <b-table-column
                        field="index"
                        label="N°">
                      <template v-slot="props">
                        {{ nombramientos.indexOf(props.row) + 1 }}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="rut_completo"
                        label="Rut"
                        sortable
                        searchable>
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.rut_completo}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="person_name"
                        label="Nombre"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.person_name}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="nombramiento.establishment"
                        label="Estamento"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.nombramiento.establishment!==undefined ? props.row.nombramiento.establishment.name : ""}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="nombramiento.unit"
                        label="Unidad de Desempeño"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.nombramiento.unit!==undefined ? props.row.nombramiento.unit.name : ""}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="nombramiento.contracting_unit"
                        label="Unidad de Contratacion"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{props.row.nombramiento.contracting_unit!==undefined ? props.row.nombramiento.contracting_unit.name : ""}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="nombramiento.start_date"
                        label="Fecha Desde"
                        width="110"
                        sortable
                        searchable :custom-search="searchStart">
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.nombramiento.start_date._isValid ? new Date(props.row.nombramiento.start_date).toLocaleDateString("es-CL") : ''}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="nombramiento.end_date"
                        label="Fecha Hasta"
                        width="110"
                        sortable
                        searchable :custom-search="searchEndDate">
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.nombramiento.end_date._isValid ? new Date(props.row.nombramiento.end_date).toLocaleDateString("es-CL") : ''}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="nombramiento.work_plant"
                        label="Cargo"
                        searchable
                        sortable
                    >
                      <template
                          slot="searchable"
                          slot-scope="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder=" Buscar"
                            icon="search"
                            size="is-small" />
                      </template>
                      <template v-slot="props">
                        {{(props.row.nombramiento.work_plant!==undefined && props.row.nombramiento.work_plant!==null) ? props.row.nombramiento.work_plant.name : ""}}
                      </template>
                    </b-table-column>
                    <template slot="empty" slot-scope="props">
                      <div class="columns is-centered">
                        <div class="column is-6 is-centered has-text-centered">
                          <b-message type="is-danger">
                            No se encontraron nombramientos.
                          </b-message>
                        </div>
                      </div>
                    </template>
                  </b-table>
                </form>
              </ValidationObserver>
            </div>
          </b-tab-item>
          <b-tab-item label="Alertas y Errores de importación" v-if="importacion_nombramientos">
            <div class="columns">
              <div class="column is-centered">
                <b-message type="is-info" has-icon>
                  <b>Alertas:</b> Inconsistencias en el archivo de importación que impiden ingresar datos no requeridos del nombramiento.<br/>
                  <b>Errores:</b> Problemas en el archivo de importación que impiden el ingreso completo de un nombramiento, lo que puede ser causado por falta de datos requeridos, existencia de la persona que poseerá el nombramiento, etc.
                </b-message>
              </div>
            </div>
            <div v-if="alertas_nombramientos.length>0" class="columns is-centered">
              <div class="column is-11">
                <div class="box">
                  <h1 class="is-size-5">
                    <b-icon
                        pack="fas"
                        icon="exclamation-triangle">
                    </b-icon>
                    Alertas del archivo: {{ this.file_name_appointments_errors }}
                  </h1>
                  <div v-if="import_type != 'Convenios'" class="ml-5" v-for="error in alertas_nombramientos">
                    Fila n° {{error.FILA}}: {{ error.ERRORES }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="errores_nombramientos.length>0" class="columns is-centered">
              <div class="column is-11">
                <div class="box">
                  <h1 class="is-size-5">
                    <b-icon
                        pack="fas"
                        icon="exclamation-triangle">
                    </b-icon>
                    Errores del archivo: {{ this.file_name_appointments_errors }}
                  </h1>
                  <div v-if="import_type != 'Convenios'" class="ml-5" v-for="error in errores_nombramientos">
                    Fila n° {{error.FILA}}: {{ error.ERRORES }}
                  </div>
                  <div v-if="import_type == 'Convenios'" class="ml-5" v-for="error in errores_nombramientos">
                    Hoja:{{error.PAGINA}} -> Fila n° {{error.FILA}}: {{ error.ERRORES }}
                  </div>
                </div>
              </div>
            </div>
            <div class="columns has-text-right">
              <div class="column">
                <b-button @click="importErrors" v-if="errores_nombramientos.length!=0||alertas_nombramientos.length!=0" icon-left="file-excel" class="is-excel-color">Exportar errores en excel</b-button>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item label="Errores ingreso - Nombramientos" v-if="errores_custom_nombramientos.length>0">
            <b-table
                :data="errores_custom_nombramientos"
                :paginated= true
                per-page="20"
                striped
                hoverable
                :narrowed=true
                :current-page="currentPage_2"
                :pagination-simple=false
                pagination-position="both"
                default-sort-direction="asc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="product.title"
                aria-next-label="Siguiente"
                aria-previous-label="Anterior"
                aria-page-label="Página"
                aria-current-label="Página actual">
              <b-table-column
                  field="index"
                  label="N°">
                <template v-slot="props">
                  {{ errores_custom_nombramientos.indexOf(props.row) + 1 }}
                </template>
              </b-table-column>
              <b-table-column
                  field="rut"
                  label="Rut"
                  sortable
                  searchable>
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{ props.row.rut}}
                </template>
              </b-table-column>
              <b-table-column
                  field="person_name"
                  label="Nombre"
                  searchable
                  sortable
              >
                <template
                    slot="searchable"
                    slot-scope="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder=" Buscar"
                      icon="search"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{props.row.person_name}}
                </template>
              </b-table-column>
              <b-table-column
                  field="motivo"
                  label="Razón de error"
              >
                <template v-slot="props">
                  {{props.row.motivo}}
                </template>
              </b-table-column>
              <template slot="empty" slot-scope="props">
                <div class="columns is-centered">
                  <div class="column is-6 is-centered has-text-centered">
                    <b-message type="is-danger">
                      No se encontraron errores en el ingreso de nombramientos.
                    </b-message>
                  </div>
                </div>
              </template>
            </b-table>
          </b-tab-item>
        </b-tabs>





    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="isAlertModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal-confirm-duplication-for-extension :person-id="person_unit.person_id"
                                                 :id="person_unit.id"
                                                 :person_unit="person_unit"
                                                 modal-header="Confirmar Nombramientos"
                                                 @close="props.close"
                                                 @clicked_save="onClickSave"
                                                 @clicked_duplicate="onClickDuplicate">
        </modal-confirm-duplication-for-extension>
      </template>
    </b-modal>

    <b-modal
        v-model="isModalSpreadSheet"
        has-modal-card
        trap-focus
        full-screen
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <modal-spreadsheet
            modal-header="Cabeceras y Ejemplo"
            @close="props.close">
        </modal-spreadsheet>
      </template>
    </b-modal>

  </section>
</template>
<script>
import axios from "axios"
import moment from "moment";
import {searchDate, formatDate} from "../../../packs/utilities";
import ModalConfirmDuplicationForExtension from "../../life_record/modal_confirm_duplication_for_extension"
import ModalSpreadsheet from "../../life_record/spreadsheet"

export default {
  name: "ImportEntryAppointment",
  components:{
    ModalConfirmDuplicationForExtension,
    ModalSpreadsheet
  },
  data(){
    return{
      import_type:"",
      import_types: [{name: "PERSONAS"}, {name: "NOMBRAMIENTOS"}],
      person_unit: {},
      currentPage: 1,
      currentPage_2: 1,
      file:{name:""},
      dropFiles: [],
      isLoading:false,
      errores:[],
      alertas: [],
      errores_nombramientos: [],
      alertas_nombramientos: [],
      file_name_appointments_errors: "",
      cabeceras_nombramientos: [],
      file_name_errors:"",
      cabeceras:[],
      hojas:[],
      errorFileNombramientos: {name: ""},
      units: [],
      year: null,
      semester: null,
      importacion: false,
      importacion_personas: false,
      importacion_nombramientos: false,
      personas: [],
      nombramientos: [],
      checkedRows: [],
      checkedRows2: [],
      checkboxPosition: "right",
      extension_start_date: null,
      extension_end_date: null,
      errores_validacion_nombramientos: {},
      invalid_date: false,
      activate_check: false,
      date_validation: true,
      allow_duplication: false,
      isAlertModalActive: false,
      valid_dates: false,
      disable_import: false,
      disable_load: false,
      errores_custom_nombramientos: [],
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      activeStep: 0,
      isStepsClickable: true,
      disabledStepsAppointments: true,
      visibleSteps: false,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      isModalSpreadSheet: false,
      correctos: [],
      carga_nombramientos: false,
      nombramientos_para_ingresar: 0
    }
  },
  created(){
    this.initialize()
  },
  props:[

  ],
  methods:{
    initialize(){
      this.isLoading = true
      this.person_unit.person_id = this.personId
      this.isLoading = false
    },
    import_file_nombramientos(){
      this.isLoading = true;
      this.carga_nombramientos = false
      this.nombramientos = []
      this.checkedRows2 = []
      this.errores_validacion_nombramientos = {}
      this.errores_custom_nombramientos = []
      this.disable_load = false
      this.file_name_appointments_errors = "";
      this.errores_nombramientos = [];
      this.cabeceras_nombramientos = [];
      let formData = new FormData();
      formData.append('file', this.file);
      this.errorFileNombramientos = this.file;
      axios.post("/life_record/import_appointment_entry.json",
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(response =>{
        if (response.data["errores"].length>0 || response.data["alertas"].length>0){
          this.isLoading = false;
          this.importacion_nombramientos = true;
          this.importacion = true;
          this.$buefy.dialog.alert({
            message: 'Algunos datos del archivo "'+this.file.name+'" no han podido ser importados, por favor, revisar errores',
            confirmText: 'Ok',
            type: 'is-warning',
            hasIcon: true,
            icon: 'exclamation-triangle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.file_name_appointments_errors = this.file.name;
          this.errores_nombramientos = response.data["errores"];
          this.alertas_nombramientos = response.data["alertas"]
          this.cabeceras_nombramientos = response.data["cabeceras"];
          this.nombramientos = response.data["nombramientos"];
          this.nombramientos = this.nombramientos.map(appointment => {
            var newAppointment = appointment
            newAppointment["nombramiento"]["start_date"] = moment(newAppointment["nombramiento"]["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
            newAppointment["nombramiento"]["end_date"] = moment(newAppointment["nombramiento"]["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
            newAppointment["nombramiento"]["resolution_date"] = moment(newAppointment["nombramiento"]["resolution_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
            return newAppointment;
          });
        }
        else{
          this.import_type = '';
          this.disable_import = true;
          this.isLoading = false;
          this.nombramientos = response.data["nombramientos"];
          this.nombramientos = this.nombramientos.map(appointment => {
            var newAppointment = appointment
            newAppointment["nombramiento"]["start_date"] = moment(newAppointment["nombramiento"]["start_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
            newAppointment["nombramiento"]["end_date"] = moment(newAppointment["nombramiento"]["end_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
            newAppointment["nombramiento"]["resolution_date"] = moment(newAppointment["nombramiento"]["resolution_date"], ["YYYY-MM-DD","DD-MM-YYYY"]);
            return newAppointment;
          });
          this.importacion_nombramientos = true;
          this.importacion = true;
          this.$buefy.dialog.alert({
            message: 'Su archivo "'+this.file.name+'" ha sido importado con éxito',
            confirmText: 'Ok'
          })
          this.file={name:""};
          this.errores_nombramientos = [];
          this.alertas_nombramientos = [];
          this.file_name_appointments_errors="";
        }
      }).catch(error => {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo errores en la importación de su archivo. Puede ser un archivo no válido o presenta errores de formato',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = false;
      });
    },
    selectAll(rows) {
      if (rows.length > this.checkedRows.length) {
        this.$nextTick(() => { this.checkedRows = this.personas})
      } else {
        this.$nextTick(() => {
          this.checkedRows = []
        })
      }
    },
    selectAll2(rows) {
      if (rows.length > this.checkedRows2.length) {
        this.$nextTick(() => { this.checkedRows2 = this.nombramientos})
      } else {
        this.$nextTick(() => {
          this.checkedRows2 = []
        })
      }
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    searchEndDate(row,input){
      return searchDate(row.end_date,input)
    },
    searchResolution(row,input){
      return searchDate(row.new_res_date,input)
    },
    searchStart(row,input){
      return searchDate(row.start_date,input)
    },
    validDate(start_date, end_date){
      if(start_date && end_date){
        this.valid_dates = true
      }
      else{
        this.valid_dates = false
      }
    },
    updateAppointments(){
      this.isLoading = true;
      this.nombramientos_para_ingresar = this.checkedRows2.length
      if(this.checkedRows2.length === 0){
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Debe seleccionar al menos un nombramiento',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = false;
        return
      }
      axios
          .post("/life_record/appointments_entry.json", {
            appointments: this.checkedRows2, semester: this.semester, year: this.year,
            date_validation: this.date_validation, allow_duplication: this.allow_duplication
          })
          .then(response =>{
            this.errores_validacion_nombramientos = response.data["errores_hash"];
            this.errores_custom_nombramientos = response.data["errores_array"]
            this.correctos = response.data["correctos_array"]
            this.carga_nombramientos = true
            if(this.errores_custom_nombramientos.length>0){
              this.$buefy.dialog.alert({
                message: 'Los nombramientos fueron creados con éxito, pero sucedieron algunos errores',
                confirmText: 'Ok'
              })
            }
            else{
              this.$buefy.dialog.alert({
                message: 'Los nombramientos fueron creados con éxito ',
                confirmText: 'Ok'
              })
            }
            this.isLoading=false;
            this.errors = [];
            this.$emit('close')
          }).catch(error => {
        this.errores_validacion_nombramientos = error.response.data["errores_hash"]
        this.isLoading = false;
      })
    },
    importErrors(){
      let vm = this;
      vm.isLoading=true;
      axios
          .post("/life_record/export_errors_entry.xlsx",{cabeceras:vm.cabeceras_nombramientos,errores:vm.errores_nombramientos, alertas: vm.alertas_nombramientos,hojas:vm.hojas},
              {
                responseType: "blob"
              })
          .then(response =>{
            vm.isLoading=false;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Errores y alertas de ingreso de nombramientos masivo '+vm.errorFileNombramientos.name.replace(".xlsx","")+' '+new Date().toLocaleDateString()+'.xlsx');
            document.body.appendChild(link);
            link.click();
          }).catch(error => {
        vm.isLoading=false;
        vm.$buefy.notification.open({
          message: 'Hubo un error en la solicitud',
          type: 'is-danger'
        })
      })
    },
  },
  computed:{

  }
}
</script>

<style>
.custom-style-row-exit {
  background: darkgreen !important;
  color: white !important;
}

</style>