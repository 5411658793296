<template>
<section>
  <h3 class="is-size-4 has-text-weight-bold has-text-centered">Tabla de Responsables</h3>
  <div class="has-text-right">
    <b-button v-if="service.can_edit" type="is-info"
              icon-left="edit"
              @click="editResponsables()">
      Editar responsables
    </b-button>
  </div>
  <div class="column">
    <b-table
        :data="participants"
        :paginated=true
        per-page=5
        striped
        hoverable
        :current-page="currentPage"
        :card-layout="cardLayout"
        :pagination-simple=false
        pagination-position="bottom"
        default-sort-direction="desc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="last_patent_state_date"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página actual">
      <b-table-column
          field= 'name'
          label= 'Nombres'
          width="20em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.name }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'corporate_mail'
          label= 'Correo Corporativo'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{  props.row.corporate_mail }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'unit'
          label= 'Unidad'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{  props.row.unit }}
        </template>
      </b-table-column>
      <b-table-column
          label="Tipo de Participación"
          v-slot="props">
        <template>
          {{  props.row.participant_type }}
        </template>
      </b-table-column>
      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-danger">
              No se encontraron participantes.
            </b-message>
          </div>
        </div>
      </template>
    </b-table>
  </div>



  </div>
  <b-modal
      :active="isComponentModalActive"
      width="90%"
      :can-cancel="canCancel"
      aria-modal>
    <template #default="props">
      <new-service-form modal-cancel="true"
                        :cargo-principal="cargoPrincipal"
                        :user-id="userId"
                        :show-participants-first="true"
                        modal-header="Editar Servicio de EFUSACH"
                        query-type="update" v-bind:service-id="service.id" v-bind:service-table="service.table" 
                        @close="closeModal"
                        @clicked="updateData"></new-service-form>
    </template>
  </b-modal>

  <b-modal
      v-model="isComponentModalIntegrantesActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :can-cancel="canCancel">
    <template #default="props">
      <modal-member :service-id="service.id"
                    :service-table="service.table"
                    :integrants="integrants" 
                     modal-header="Añadir Integrante"
                     @close="props.close"
                     @clicked="saveMember"
                                 
      >
      </modal-member>
    </template>
  </b-modal>

  <b-modal
      v-model="isComponentModalIntegrantesView"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :can-cancel="canCancel">
    <template #default="props">
      <modal-person-member :person-id="member.person_id"
                     modal-header="Ver Integrante"
                     @close="props.close"
      >
      </modal-person-member>
    </template>
  </b-modal>

  <b-modal
      v-model="isComponentModalContract"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :can-cancel="canCancel">
    <template #default="props">
      <modal-contract :contract-id="contract.id"
                    :query-type="contractType"
                    :modal-header="contractHeader"
                     @close="props.close"
                     @clicked="updateContract"
      >
      </modal-contract>
    </template>
  </b-modal>


</section>
</template>

<script>
import axios from "axios";
import {searchDate} from "../../packs/utilities";
import NewServiceForm from "./NewServiceForm";
import modalMember from "./ModalMemberService";
import modalPersonMember from "./ModalPersonMember";
import modalContract from "./ModalContract";
import moment from "moment/moment";
export default {
  name: "ShowParticipantsTab",
  props:[ 'participants', 'service', 'cargoPrincipal', 'canEdit', 'integrants','userId'],
  components:{NewServiceForm, modalMember,modalPersonMember, modalContract},
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      years:[],
      keyupdates: 0,
      year:null,
      contractType: '',
      contractHeader: '',
      member: null,
      isComponentModalActive:false,
      isComponentModalIntegrantesActive: false,
      isComponentModalIntegrantesView: false,
      isComponentModalContract: false,
      canCancel: ['escape'],
      contract: null
    }
  },
  created(){

  },
  methods:{
    editResponsables(){
      this.isComponentModalActive = true;
    },
    editItegrantes(){
      this.isComponentModalIntegrantesActive = true;
    },
    closeModal(){
      this.isComponentModalActive = false;
      this.isComponentModalIntegrantesActive = false;
      this.isComponentModalContract = false;
      this.isComponentModalIntegrantesView = false;
    },
    saveMember(data){
      this.keyupdates = this.keyupdates+1;
      this.integrants.push(data);
      this.keyupdates = this.keyupdates+1;
    },
    goToProfile(id){
      window.location.href='/people/'+id
    },
    viewMember(row){
      
      this.member = row;
      this.isComponentModalIntegrantesView = true;

    },
    updateData(data){
      this.$emit("clicked",data)
    },
    viewContract(row){
      this.contract = row.contract;
      this.member = row;
      this.contractHeader = "Ver Contrato"
      this.contractType = "view"
      this.isComponentModalContract = true;
    },
    editContract(row){
      this.contract = row.contract;
      this.member = row;
      this.contractType = "update"
      this.contractHeader = "Editar Contrato"
      this.isComponentModalContract = true;
    },
    updateContract(data){
      this.keyupdates = this.keyupdates+1;
      this.member.contract = data
      this.keyupdates = this.keyupdates+1;
    },
    searchStartDate(row,input){
      return searchDate(row.contract.start_date,input)
    },
    searchEndDate(row,input){
      return searchDate(row.contract.end_date,input)
    },
  }

}
</script>

<style scoped>

</style>