<template>
  <section class="is-flex is-flex-direction-column custom-box">
    <article class="columns">
      <div class="column" :class="selectedOption === 'custom' ? 'pb-0' : ''">
        <b-field label="Filtrar permisos por fecha">
          <multiselect
            v-model="selectedOption"
            :options="filterOptions"
            :custom-label="(option) => option.text"
            placeholder="Seleccione opción"
            select-label=""
            selected-label="Seleccionado"
            deselect-label=""
            :allow-empty="false"
            track-by="value"
            label="text"
            @input="onFilterChange"
          >
          </multiselect>
        </b-field>
      </div>
    </article>
    <div class="columns is-flex is-align-items-flex-end is-multiline">
      <div v-if="selectedOption.value === 'custom'" class="column">
        <b-field label="Fecha solicitada desde">
          <b-datepicker
            v-model="startDate"
            :first-day-of-week="1"
            placeholder="dd/mm/yyyy"
            editable
            locale="es-ES"
            icon="calendar-alt"
            icon-right-clickable
            trap-focus
            append-to-body
          />
        </b-field>
      </div>

      <div v-if="selectedOption.value === 'custom'" class="column">
        <b-field label="Fecha solicitada hasta">
          <b-datepicker
            v-model="endDate"
            :first-day-of-week="1"
            placeholder="dd/mm/yyyy"
            editable
            locale="es-ES"
            icon="calendar-alt"
            icon-right-clickable
            trap-focus
            append-to-body
          />
        </b-field>
      </div>
      <div
        v-if="selectedOption.value === 'custom'"
        class="column is-flex is-justify-content-flex-end is-full-mobile is-narrow-mobile"
      >
        <b-button
          expanded
          type="is-primary"
          icon-left="search"
          @click="searchPermitsByDate"
        >
          Buscar permisos
        </b-button>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";
import { formatDateToLocaleString } from "../.././packs/utilities";
export default {
  data() {
    return {
      startDate: null,
      endDate: null,
      selectedOption: { value: "last_3_months", text: "Estos Últimos 3 Meses" },
      filterOptions: [
        { value: "today", text: "Hoy" },
        { value: "this_week", text: "Esta Semana" },
        { value: "this_month", text: "Este Mes" },
        { value: "last_3_months", text: "Estos Últimos 3 Meses" },
        { value: "this_year", text: "Este Año" },
        { value: "all", text: "Todos" },
        { value: "custom", text: "Intervalo Personalizado" },
      ],
      MAX_YEARS: 10,
    };
  },
  methods: {
    onFilterChange() {
      if (this.selectedOption.value !== "custom") {
        let startDate;
        let endDate = moment().endOf("day");

        switch (this.selectedOption.value) {
          case "today":
            startDate = moment().startOf("day");
            break;
          case "this_week":
            startDate = moment().startOf("week");
            endDate = moment().endOf("week");
            break;
          case "this_month":
            startDate = moment().startOf("month");
            endDate = moment().endOf("month");
            break;
          case "last_3_months":
            startDate = moment().subtract(3, "months").startOf("month");
            endDate = moment().endOf("month");
            break;
          case "this_year":
            startDate = moment().startOf("year");
            endDate = moment().endOf("year");
            break;
          case "all":
            startDate = moment()
              .subtract(this.MAX_YEARS, "years")
              .startOf("year");
            endDate = moment().add(this.MAX_YEARS, "years").endOf("day");
            break;
        }
        this.searchPermitsByDate(startDate, endDate);
      }
    },
    searchPermitsByDate(startDate, endDate) {
      if (this.selectedOption.value === "custom") {
        this.$emit("filter", {
          startDate: this.formatDateToLocaleString(this.startDate),
          endDate: this.formatDateToLocaleString(this.endDate),
        });
      } else {
        this.$emit("filter", {
          startDate: this.formatDateToLocaleString(startDate),
          endDate: this.formatDateToLocaleString(endDate),
        });
      }
    },
    clearFields() {
      this.startDate = null;
      this.endDate = null;
    },
    formatDateToLocaleString,
  },
};
</script>

<style scoped>
@media (max-width: 767px) {
  .custom-box {
    border: none;
    background-color: #fff;
    padding: 0rem;
    gap: 0.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .custom-box {
    border: 2rem;
    border-width: 1px;
    border-style: solid;
    border-radius: 0.5rem;
    border-color: #e5e7eb;
    background-color: #fff;
    padding: 1rem;
    gap: 0.5rem;
  }
}

@media (min-width: 1024px) {
  .custom-box {
    border: 2rem;
    border-width: 1px;
    border-style: solid;
    border-radius: 0.5rem;
    border-color: #e5e7eb;
    background-color: #fff;
    padding: 1rem;
    gap: 0.5rem;
  }
}
</style>
