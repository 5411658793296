<template>
  <section>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(saveStudy)">
      <div class="modal-card" style="width: auto; height: 42em">
    <header class="modal-card-head">
      <p class="modal-card-title">{{modalHeader}}</p>
      <button
          type="button"
          class="delete"
          @click="$emit('close')"/>
    </header>
    <section class="modal-card-body" ref="modalStudyForm">
      <div class="columns">
        <div class="column">
          <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
            <b-message type="is-danger" has-icon>
              <div v-for="(item,index) in errores_validacion">
                {{ item[0] }}
              </div>
            </b-message>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Tipo Institución*">
            <ValidationProvider rules="required" v-slot="{errors}">
              <multiselect v-model="person_study.entity_types_id"
                           :options="entity_types.map(type => type.id)"
                           :custom-label="opt => entity_types.find(x => x.id === opt).name"
                           placeholder="Seleccione tipo de institución"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="No se puede deseleccionar"
                           :allow-empty="false">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                        No se encontraron elementos.
                    </span>
              </multiselect>
              <span class="validation-alert">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-field>
        </div>
      </div>
      <h3 class="is-size-6 has-text-weight-bold">Institución*
        <b-tooltip
            label="Ingrese al menos 3 caracteres para realizar una búsqueda. Si la entidad que desea agregar no existe, la puede agregar presionando el botón +"
            position="is-bottom"
            size="is-medium"
            multilined>

          <b-icon
              pack="fas"
              icon="info-circle"
              size="is-small"
              type="is-info">
          </b-icon>
        </b-tooltip>
      </h3>
      <div class="columns">
              <div class="column">
                <b-field>
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-taginput
                        v-model="institucion_seleccionada"
                        :data="partner_entities"
                        type="is-primary is-light"
                        autocomplete
                        :allow-new="false"
                        field="show_name"
                        icon="tag"
                        :disabled = "person_study.entity_types_id == null"
                        placeholder="Seleccione institución"
                        maxtags="1"
                        @input = "beforeAdding"
                        @typing="searchPartnerEntities">
                      <template slot-scope="props">
                        {{ props.option.show_name ? props.option.show_name : props.option.name}}
                      </template>
                    </b-taginput>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column is-narrow">
                  <b-button class="is-primary is-outlined" @click="addEntity()">
                    <b-icon icon="plus"></b-icon>
                  </b-button>
              </div>
            </div>
      <div class="columns">
        <div class="column">
          <b-field label="Tipo de estudio*">
            <ValidationProvider rules="required" v-slot="{errors}">
              <multiselect v-model="person_study.study_type_id"
                           :options="study_types.map(type => type.id)"
                           :custom-label="opt => study_types.find(x => x.id === opt).name"
                           placeholder="Seleccione tipo de estudio"
                           selectLabel="Presione para seleccionar"
                           selectedLabel="Seleccionado"
                           deselectLabel="No se puede deseleccionar"
                           :allow-empty="false"
                           @input="fillStudiesFromInstitution">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                        No se encontraron elementos.
                    </span>
              </multiselect>
              <span class="validation-alert">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-field>
        </div>
      </div>
            <div class="columns">
              <div class="column">
                <h3 class="is-size-6 has-text-weight-bold">Nombre Estudio*
                  <b-tooltip
                      label="Presione Enter o seleccione el estudio luego de escribir para que sea agregada"
                      position="is-bottom"
                      size="is-medium"
                      multilined>

                    <b-icon
                        pack="fas"
                        icon="info-circle"
                        size="is-small"
                        type="is-info">
                    </b-icon>
                  </b-tooltip>
                </h3>
                <b-field label=" ">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-taginput
                        v-model="estudio_seleccionado"
                        :data="estudios_filtrada"
                        type="is-primary is-light"
                        autocomplete
                        :allow-new="true"
                        field="name"
                        :disabled = "person_study.entity_types_id == null || person_study.study_type_id == null"
                        icon="tag"
                        placeholder="Agregue o seleccione estudio"
                        maxtags="1"
                        @typing="filteredDataStudies"
                        @input="generateName">
                    </b-taginput>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Fecha de obtención*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-datepicker
                        v-model="person_study.egress_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                    >
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Semestres">
                  <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                    <b-input v-model="person_study.semesters" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
<!--      <div class="columns">
        <div class="column">
          <b-field label="Url carpeta documentación Estudios">
            <ValidationProvider v-slot="{ errors }" rules="max:255">
              <b-input v-model="person_study.documentation_link" type="text"></b-input>
              <span class="validation-alert">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-field>
        </div>
      </div>-->
      <div v-if="queryType == 'update'" class="columns">
        <div class="column is-4">
          <b-field label="Nombre del documento">
            <b-input v-model="document_name_update" type="text" :disabled="true"></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="¿Desea cambiar o agregar un archivo?">
            <b-radio-button v-model="changeFile"
                            :native-value="true"
                            type="is-success is-light is-outlined">
              <b-icon icon="check-circle"></b-icon>
              <span>Si</span>
            </b-radio-button>
            <b-radio-button v-model="changeFile"
                            :native-value="false"
                            type="is-danger is-light is-outlined">
              <b-icon icon="times-circle"></b-icon>
              <span>No</span>
            </b-radio-button>
          </b-field>
        </div>
      </div>
      <div v-if="queryType == 'create' || changeFile" class="columns has-text-centered">
        <div class="column">
          <b-field extended label="Archivo PDF (Tamaño max. 25 MB)">
            <b-upload v-model="file"
                      drag-drop
                      type="is-primary"
                      accept=".pdf"
                      @input="generateName">
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon
                        icon="file-pdf"
                        size="is-large">
                    </b-icon>
                  </p>
                  <p>Arrastra tu PDF o haz click para subir.</p>
                </div>
              </section>
            </b-upload>
          </b-field>
        </div>
      </div>
      <div v-if="queryType == 'create' || changeFile" class="columns tags is-centered">
              <span
                  v-if="file !== null"
                  v-model="file"
                  class="tag is-primary mt-1">
                {{ file.name }}
                <button
                    class="delete is-small"
                    type="button"
                    @click="onDeleteFile"
                ></button>
              </span>
      </div>
      <div v-if="file !== null" class="columns">
        <div class="column">
          <b-field label="Nombre del documento a subir">
            <b-input disabled v-model="document_name"></b-input>
          </b-field>
        </div>
      </div>
    </section>
    </div>
      <footer class="modal-card-foot actions">
        <div class="field is-grouped is-pulled-right">
          <button class="button" type="button" @click="$emit('close')">Cerrar</button>
          <button class="button is-primary" type="submit" :disabled="button_disabled" :loading="button_disabled">Guardar</button>
        </div>
      </footer>
    </form>
  </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
        v-model="isAddModalEntityActive"
        :destroy-on-hide="true"
        width="50%"
        :can-cancel="canCancel">
      <template #default="props">
        <modal_partner_entity_life_record
            query-type="create"
            modal-header="Añadir nueva entidad"
            @close="props.close"
            @clicked="onClickChild"></modal_partner_entity_life_record>
      </template>
    </b-modal>
  </section>
</template>
<script>
import axios from "axios";
import modal_partner_entity_life_record from "./partner_entity/modal_partner_entity_life_record";

export default {
name: "modal_study_form",
  props:[
    'queryType', 'personId', 'id', 'modalHeader'
  ],
  components:{
    modal_partner_entity_life_record,
  },
  data(){
    return {
      person_study:{
        entity_types_id: null,
        partner_entity_id: null,
        study_type_id: null,
        study_id: null,
        egress_date: null,
        semesters: null,
      },
      partner_entities:[],
      study_types:[],
      entity_types:[],
      studies:[], //carreras
      institutions:[],
      skip_if_study: true,
      errores_validacion:{},
      canCancel: false,
      isLoading: true,
      isDisabled: false,
      institucion_filtrada: [],
      institucion_seleccionada: null,
      estudio_seleccionado: null,
      estudios_filtrada: [],
      isAddModalEntityActive: false,
      button_disabled: false,
      file: null,
      document_name: null,
      changeFile: false,
      document_name_update: null,
      document_id: null,
      person: null
    }
  },
  created() {
    if (this.queryType === "create") {
      this.createMethod()
    }
    else if (this.queryType === "update"){
      this.updateMethod()
    }
  },
  methods: {
    addEntity(){
      this.isAddModalEntityActive = true;
    },
    onClickChild(){
      this.$buefy.toast.open({
        message: 'La entidad se agregó correctamente, puede buscarla y agregar algún estudio',
        type: 'is-success',
        duration: 5000,
      })
      if(this.person_study.entity_types_id!==undefined &&this.person_study.entity_types_id!==null){
        this.fillInstitutionaFromEntityType()
      }
    },
    async filteredDataInstitutions(text) {
      if (this.estudio_seleccionado && this.estudio_seleccionado[0]) {
        this.estudio_seleccionado = null
        await this.fillStudiesFromInstitution()
      }
      this.institucion_filtrada = this.institutions.filter((option) => {
        return option.name
            .toString()
            .toUpperCase()
            .indexOf(text.toUpperCase()) >= 0
      }).slice(0, 10);
    },

    filteredDataStudies(text){
      this.estudios_filtrada = this.studies.filter((option) => {
        return option.name
            .toString()
            .toUpperCase()
            .indexOf(text.toUpperCase()) >= 0
      })
    },
    async beforeAdding() {
      if (this.institucion_seleccionada && this.institucion_seleccionada[0]) {
        if (this.institucion_seleccionada[0].id) {
          this.person_study.partner_entity_id = this.institucion_seleccionada[0].id
        } else {
          this.person_study.partner_entity_id = null
        }
        await this.fillStudiesFromInstitution()
      } else {
        this.person_study.partner_entity_id = null
        await this.fillStudiesFromInstitution()
      }

    },
    async saveData(){
      //if (this.person_study.partner_entity_id == null){
      //  this.saveEntityEducational()
      //}
      //else{
      this.isLoading = true
      this.button_disabled = true
      await this.saveStudy()
      this.isLoading = false
      this.button_disabled = false
      //}
    },
    savePersonStudy(){
      //this.isDisabled=true;
      this.isLoading=true;
      if (this.queryType==="create"){
        if (this.file != null && this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Este archivo no tiene formato PDF",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        if (this.file != null && this.file.size > 1024*1024*25) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "El archivo no puede superar el maximo de 25 MB",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        if (this.file != null && !this.estudio_seleccionado) {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Para adjuntar un archivo, debe al menos especificar el nombre del estudio",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        // Crea un objeto FormData
        let formData = new FormData();

        // Agrega datos al objeto FormData
        formData.append("person_study", JSON.stringify(this.person_study));
        formData.append("file", this.file); // Aquí agregamos el archivo
        formData.append("document_name", this.document_name);

        axios
            .post("/admin/person_studies/create-document.json", formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
            .then(response =>{
              this.errores_validacion = {};
              this.$buefy.dialog.alert({
                message: 'El estudio fue creado con éxito ',
                confirmText: 'Ok'
              })
              this.person_study = {}
              //this.isDisabled=false;
              this.errors = [];
              this.isLoading=false;
              this.button_disabled=false;
              this.$emit('close')
            }).catch(error => {
              this.errores_validacion = error.response.data
              var elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              this.$refs.modalStudyForm.scrollTop = 0;
              this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Hubo errores al agregar el estudio, favor revisar formato',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
          this.isLoading=false;
          this.button_disabled=false;
          //this.isDisabled=false;
        })
      }
      else if (this.queryType==="update"){
        this.isLoading=true;
       // let clean_event = this.event
        //clean_event = this.deleteData(clean_event)
        if (this.file != null && this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Este archivo no tiene formato PDF",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        if (this.file != null && this.file.size > 1024*1024*25) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "El archivo no puede superar el maximo de 25 MB",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        if (this.file != null && !this.estudio_seleccionado) {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Para adjuntar un archivo, debe al menos especificar el nombre del estudio",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        // Crea un objeto FormData
        let formData = new FormData();
        let clean_person_study = this.deleteData(this.person_study)

        // Agrega datos al objeto FormData
        formData.append("person_study", JSON.stringify(clean_person_study));
        formData.append("file", this.file); // Aquí agregamos el archivo
        formData.append("document_name", this.document_name);

        axios
            .put("/admin/person_studies/"+ this.id+"/update-document.json", formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
            .then(response => {
              this.$buefy.dialog.alert({
                message: 'El estudio fue editado con éxito ',
                confirmText: 'Ok'
              })
              //this.isDisabled = true;
              this.errores_validacion = {};
              this.event = {}
              this.isLoading = false;
              this.button_disabled=false;
              this.$emit('close')
            }).catch(error => {
              this.errores_validacion = error.response.data
              //this.isDisabled=false;
              let elmnt = document.getElementById("app");
              elmnt.scrollIntoView();
              this.$refs.modalStudyForm.scrollTop = 0;
              this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Hubo errores al editar el estudio, favor revisar formato',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
          //this.isDisabled=false;
          this.isLoading=false;
          this.button_disabled=false;
        })
      }
      //this.isLoading=false;
    },
    async createMethod(){
      this.person_study.person_id = this.personId
      this.isLoading = true;
      await axios
          .get("/admin/study_types.json")
          .then(res => {
            this.study_types = res.data
            const updatedArray = this.study_types.map(obj => {
              if (obj.name === "DOCTOR") {
                return { ...obj, name: "DOCTORADO" };
              }
              if (obj.name === "LICENCIADO") {
                return { ...obj, name: "LICENCIATURA" };
              }
              return obj; // Deja los demás objetos sin cambios
            });
            this.study_types = updatedArray

          }).catch(error => {
            console.log(error)
            this.isLoading = false;
          })
    /*  await axios
          .get("/admin/partner_entities/list_educational.json")
          .then(res => {
            this.partner_entities = res.data
          }).catch(error => {
            console.log(error)
            this.isLoading = false;
          })*/
      await axios
          .get("/admin/entity_types/entity_type_educational.json")
          .then(res => {
            this.entity_types = res.data["entity_types"]

          }).catch(error => {
        console.log(error)
        this.isLoading = false;
      })
      await axios
          .get("/people/"+this.personId+".json")
          .then(res => {
            this.person = res.data

          }).catch(error => {
            console.log(error)
            this.isLoading = false;
          })

      this.isLoading = false;
    },
    async updateMethod(){
      this.isLoading = true;
     axios
        .get("/admin/person_studies/"+this.id+"/edit.json")
        .then(async res => {
          this.person_study = res.data
          this.study_types = res.data["study_types"]
          const updatedArray = this.study_types.map(obj => {
            if (obj.name === "DOCTOR") {
              return {...obj, name: "DOCTORADO"};
            }
            if (obj.name === "LICENCIADO") {
              return {...obj, name: "LICENCIATURA"};
            }
            return obj; // Deja los demás objetos sin cambios
          });
          this.study_types = updatedArray
          this.entity_types = res.data["entity_types"]
          //this.partner_entities = res.data["partner_entities"]
          await this.fillStudiesFromInstitution()
          this.person_study.study_id = res.data["study_id"]
          this.person_study.entity_types_id = res.data["entity_type_id"]
          this.person_study.partner_entity_id = res.data["partner_entity_id"]
          this.fillInstitutionaFromEntityType()
          this.institucion_seleccionada = [{}]
          this.estudio_seleccionado = [{}]
          this.institucion_seleccionada[0].id = res.data["partner_entity_id"]
          this.institucion_seleccionada[0].name = res.data.study.partner_entity
          this.institucion_seleccionada[0].show_name = res.data.study.partner_entity
          this.estudio_seleccionado[0].id = res.data["study_id"]
          this.estudio_seleccionado[0].name = res.data.study.name

          if (res.data["egress_date"] !== null) {
            this.person_study.egress_date = new Date(this.formatDate(res.data["egress_date"]))
          }
          this.document_id = res.data["document_id"]
          this.document_name_update = res.data["document_name"]
          this.document_name = res.data["document_name"]
          this.person = res.data["person"]
          //this.isLoading = false
        }).catch(error => {
          console.log(error)
           this.isLoading = false;
        })

    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
    fillStudiesFromInstitution(){
      if (this.institucion_seleccionada && this.institucion_seleccionada[0]){
        if (this.institucion_seleccionada[0].id){
          this.person_study.partner_entity_id = this.institucion_seleccionada[0].id
        }
        else{
          this.person_study.partner_entity_id = null
        }
      }
      if (this.person_study.partner_entity_id != null){
        this.isLoading= true;
        axios
            .get("/admin/partner_entities/"+this.person_study.partner_entity_id+"/studies_by_type.json",{
              params: {study_type_id: this.person_study.study_type_id}
            })
            .then(res => {
              this.studies = res.data;
              this.isLoading = false;
            }).catch(error => {
          this.isLoading = false;
          console.log(error)
        })
        this.estudio_seleccionado = null
      }
      else{
        this.estudio_seleccionado = null
        this.person_study.study_id = null
        this.studies = []
      }
    },
    async fillInstitutionaFromEntityType() {
      this.isLoading = true;
      axios
          .get("/admin/partner_entities/list_institution/" + this.person_study.entity_types_id + ".json", {})
          .then(res => {
            this.institutions = res.data["partner_entities"];
            this.isLoading = false;
          }).catch(error => {
        this.isLoading = false;
        console.log(error)
      })
      this.estudio_seleccionado = null
      this.institucion_seleccionada = null
      await this.fillStudiesFromInstitution()
    },
    saveEntityEducational(){
      this.isLoading=true;
      this.isDisabled=true;
      if(this.person_study.partner_entity_id == null){
        var nombre
        if (this.institucion_seleccionada[0].name){
          nombre = this.institucion_seleccionada[0].name
        }
        else{
          nombre = this.institucion_seleccionada[0]
        }
        const entidad_externa = {
          name: nombre.toUpperCase(),
          description: "",
          stablished: 0,
          entity_type_id: this.person_study.entity_types_id,
          skip_if_import: this.skip_if_study
        }
        axios
            .post("/admin/partner_entities", {
              partner_entity: entidad_externa
            })
            .then(response =>{
              this.isLoading=true;
              this.isDisabled=true;
              this.saveStudy();
              this.errores_validacion = {};
              this.errors = [];
            }).catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al agregar la entidad, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
          this.isDisabled=false;
        })
      }
      this.isLoading=false;
    },
    saveStudy(){
      this.isLoading=true;
      this.isDisabled=true;
      this.button_disabled=true;
      if(this.estudio_seleccionado && this.estudio_seleccionado[0]){
        if(this.estudio_seleccionado[0].id){
          this.person_study.study_id = this.estudio_seleccionado[0].id
        }
        else{
          this.person_study.study_id = null
        }
      }
      else{
        this.person_study.study_id = null
      }
      if(this.person_study.partner_entity_id == null){
        var nombre
        if (this.estudio_seleccionado[0].name){
          nombre = this.estudio_seleccionado[0].name
        }
        else{
          nombre = this.estudio_seleccionado[0]
        }
        const estudios = {
          name: nombre.toUpperCase(),
          study_type_id: this.person_study.study_type_id,
          partner_entity_id: null,
        }
        const tipo_entidad = {
          id : this.person_study.entity_types_id
        }
        axios
            .post("/admin/studies", {
              study: estudios,
              entity: tipo_entidad
            })
            .then(response =>{
              this.isLoading=true;
              this.isDisabled=true;
              this.savePersonStudy()
              this.errores_validacion = {};
              this.errors = [];
            }).catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al agregar el nombre de estudio, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
          this.isDisabled=false;
        })
      }
      else if(this.person_study.study_id == null){
        var nombre
        if (this.estudio_seleccionado[0].name){
          nombre = this.estudio_seleccionado[0].name
        }
        else{
          nombre = this.estudio_seleccionado[0]
        }
        const estudios = {
          name: nombre.toUpperCase(),
          study_type_id: this.person_study.study_type_id,
          partner_entity_id: this.person_study.partner_entity_id
        }
        axios
            .post("/admin/studies", {
              study: estudios,
            })
            .then(response =>{
              this.isLoading=true;
              this.isDisabled=true;
              this.errores_validacion = {};
              this.savePersonStudy();
              this.errors = [];
            }).catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al agregar el nombre de estudio, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
          this.isDisabled=false;
        })
      }
      else{
        this.savePersonStudy()
      }
      //this.isLoading=false;
    },
    searchPartnerEntities(name){
      this.isAcademicRegister =  false
      if (name.length<3) {
        this.partner_entities = []
        return
      }
      let vm = this;
      vm.isLoading=true;
      axios
          .get("/admin/partner_entities/educational-search.json", { params: { name: name, entity_type:vm.entity_types.find(x => x.id == vm.person_study.entity_types_id)} })
          .then(res => {
            vm.partner_entities = res.data;
            vm.isLoading = false;
          })
          .catch(error => {
            console.log(error)
            vm.isLoading=false;
          })
    },
    onDeleteFile() {
      this.file = null;
    },
    generateName(){
      var tipo = "EST"
      var rut = ""
      var nombre_estudio = ""

      if(this.person != null){
        rut = "-RUT_"+this.person.run.rut.toString()+"_"+this.person.run.dv
      }
      if (this.estudio_seleccionado && this.estudio_seleccionado[0].name){
        nombre_estudio = "-"+this.estudio_seleccionado[0].name.toUpperCase()
      }
      else if (this.estudio_seleccionado ){
        nombre_estudio = "-"+this.estudio_seleccionado[0].toUpperCase()
      }

      this.document_name = tipo + rut + nombre_estudio
    },
    deleteData(person_study){
      delete person_study.can_view_pdf
      delete person_study.document_name
      delete person_study.document_id
      delete person_study.partner_entities
      delete person_study.entity_types
      delete person_study.study_types
      delete person_study.partner_entity
      delete person_study.study
      delete person_study.study_type_id
      delete person_study.partner_entity_id
      delete person_study.entity_type_id
      delete person_study.entity_types_id
      delete person_study.person

      return person_study
    },
  },
}
</script>

