<template>
  <div>
    <div>
      <div class="columns" >
        <div class="column">
          <b-field label="Año de Monto de Apertura" >
            <multiselect v-model="year"
                         :options="years"
                         placeholder="Seleccione año"
                         selectLabel="Presione para seleccionar"
                         selectedLabel="Seleccionado"
                         deselectLabel="No se puede deseleccionar"
                         :allow-empty="false">
              <template v-slot:noOptions>
                No existen datos
              </template>
              <span slot="noResult">
                          No se encontraron elementos.
                      </span>
            </multiselect>
          </b-field>
        </div>

      </div>
      <div class="columns">
        <div class="column has-text-right">
          <b-button
              class="button right-button button-clear-filter"
              icon-left="eraser"
              @click="clearFilter()">
            Limpiar Filtros
          </b-button>
          <b-button type="is-info"
                    icon-left="search"
                    class="button right-button is-info"
                    @click="updateAmountFilter()">
            Buscar
          </b-button>

        </div>
      </div>

      <div class="columns" >
        <div class="column has-text-right">

          <b-button type="is-primary"
                    icon-left="plus"
                    v-if="can_create_anual_view == 'true'"
                    class="button right-button"
                    @click="goAmount('create',null)">
            Añadir monto de apertura
          </b-button>

        </div>
      </div>

    </div>
    <b-table
        :data="montos"
        :paginated=true
        per-page=10
        striped
        hoverable
        :current-page="currentPage"
        :pagination-simple=false
        pagination-position="bottom"
        default-sort-direction="desc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="monto.year"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página actual"
    >
      <b-table-column
          field= 'year'
          label= 'Año'
          width="15em"
          searchable
          sortable
          :custom-search="searchYear"
      >
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.year }}
        </template>
      </b-table-column>


      <b-table-column
          field= 'amount'
          label= 'Monto'
          width="20em"
          searchable
          sortable
          :custom-search="searchAmount">
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ "$"+Number(props.row.amount).toLocaleString('es-ES') }}
        </template>
      </b-table-column>

      <b-table-column
          label="Acciones"
          width="10em"
          v-slot="props"
          v-if="can_create_anual_view == 'true'">
        <b-button type="is-primary"
                  icon-left="pen"
                  v-if="props.row.can_edit_anual"
                  @click="goAmount('update',props.row)">
          Editar
        </b-button>
      </b-table-column>

      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-danger">
              No se encontraron resultados.
            </b-message>
          </div>
        </div>
      </template>


    </b-table>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>




    <b-modal
        v-model="isComponentModal"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        width="100%"

        aria-modal
        :can-cancel="canCancel">
      <template #default="props">
        <modal-amount  :opening-amount="element"
                       :id-surplus="surplusId"
                       :query-type = "queryType"
                       style="width: 90vw"
                       :modal-header="tituloModal"
                       @close="props.close"
                       @clickedUpdate="onClickedUpdate"
                       @clickedCreate="onClickedCreate"
        >
        </modal-amount>
      </template>
    </b-modal>

  </div>
</template>
<script>
import axios from "axios";
import modalAmount from "./ModalOpeningAmount.vue";
import {searchDate} from "../../../packs/utilities";
export default {
  props:[ 'surplus', 'cargoPrincipal', 'userId', 'surplusId','can_create_anual'],
  components:{ modalAmount},
  name: "ShowAperturaTab",
  data(){
    return {
      isLoading: false,
      currentPage: 1,
      year: "Todo",
      can_edit: false,
      years:[],
      montos: [],
      keepFirst: false,
      openOnFocus: false,
      clearable: false,
      nameFilter: '',
      isActive:false,
      isComponentModal:false,
      queryType: null,
      element: null,
      canCancel: ['escape'],
      tituloModal: null,
      can_create_anual_view: false

    }
  },
  created(){
    let vm = this;
    vm.isLoading = true
    vm.years = this.generateArrayOfYears();
    return axios.get("/sdt_opening_amounts.json", {params:{
                                                        id_surplus: vm.surplusId,
                                                      }})
        .then(res => {
          vm.montos = res.data;
          vm.can_create_anual_view = vm.can_create_anual;
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          console.log(e);
        });

  },
  methods:{

    generateArrayOfYears() {
      var max = new Date().getFullYear()
      var min = 1980
      var years = []
      years.push("Todo")
      for (var i = max; i >= min; i--) {
        years.push(i)
      }
      return years
    },
    searchYear(row,input){
      return String(row.year).includes(input);
    },
    searchAmount(row,input){
      return String(row.amount).includes(input)
    },
    goAmount(type,row) {
      this.queryType = type;
      this.element = row;
      if(type=="create") {
        this.tituloModal = "Crear Monto de apertura"
      }
      else{
        this.tituloModal = "Editar Monto de apertura"
      }
      this.isComponentModal=true;
    },
    onClickedCreate(value){
      this.isLoading = true
      //value.amount = Number(value.amount).toLocaleString('es-ES')
      this.montos.push(value)
      this.isLoading = false
    },
    onClickedUpdate(value){
      this.isLoading = true
      this.montos = this.montos.filter(elemento => elemento.id !== this.element.id);
      //value.amount = Number(value.amount).toLocaleString('es-ES')
      this.montos.push(value)
      this.isLoading = false
    },
    customIntegerSearch(row,input){
      return String(row.amout).includes(input);
    },

    async updateAmountFilter(){
      let vm = this;
      vm.isLoading = true
      return axios.get("/sdt_opening_amounts.json", {params:{
          id_surplus: vm.surplusId,
          year: vm.year
        }})
          .then(res => {
            vm.montos = res.data;
            vm.isLoading=false;
          })
          .catch(e => {
            vm.isLoading=false;
            console.log(e);
          });
    },
    clearFilter(){
      this.year = "Todo";
    }

  },
  computed:{

  }
}
</script>
<style scoped>

.right-button{
  margin-left: auto;
}
.left-button{
  margin-right: auto;
}


</style>