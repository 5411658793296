<template>
  <section>

    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <div class="mb-2" v-if="Object.keys(errores_validacion).length != 0">
        <b-message type="is-danger" has-icon>
          <div v-for="(item,index) in errores_validacion">
            {{ item[0] }}
          </div>
        </b-message>
      </div>
      <form @submit.prevent="handleSubmit(saveFinancing)">
        <!-- Box de Formulario Project -->
        <div class="box">

          <div class="columns">
            <div class="column">
              <b-field label="Monto Adjudicado Total por la Universidad">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-input :has-counter="false" maxlength="14" v-model="awarded_financing.amount"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>
          <div v-if="project.is_creator && project.add_awarded_financing_permission" class="actions has-text-right">
            <b-button icon-left="save" type="is-primary" native-type="submit" @click="checkValidation()">Guardar Monto Adjudicado</b-button>
          </div>

          <!--

                    <div class="actions has-text-right">
                      <button class="button is-primary" type="submit" @click="checkValidation()">Guardar Monto Adjudicado</button>
                    </div>
          -->

        </div>

      </form>
    </ValidationObserver>

    <div class="box" style="">
      <h2 class="title has-text-centered">Remesas</h2>

      <awarded-financings-table v-bind:financings="remesas" :project="project" @updateFinancing="updateFinancing"></awarded-financings-table>


    </div>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import AddFinancingModal from '/app/javascript/components/financings/AddFinancingModal.vue';
import AwardedFinancingsTable from '/app/javascript/components/financings/AwardedFinancingsTable.vue';
import axios from "axios";
export default {
  name: "ProjectFinancingsTab",
  components:{ AddFinancingModal, AwardedFinancingsTable },
  data(){
    return{
      errores_validacion:{},
      awarded_financing:{},
      remesas:[],
      isLoading:false,
    }
  },
  props:['project'],
  created() {
    this.getProjectFinancings();
  },
  methods: {
    getProjectFinancings(){
      let vm = this;
      vm.isLoading=true;
      return axios
          .get("/projects/"+vm.project.id+"/financings_nullified.json")
          .then(res => {
            vm.awarded_financing = res.data.awarded_financing;
            vm.remesas = res.data.remesas;
            vm.isLoading=false;
          })
          .catch(e => {
            console.log(e);
            vm.isLoading=false;
          });
    },
    saveFinancing() {
      let vm = this;
      vm.isLoading=true;
      let post_awarded_financing =  _.clone(vm.awarded_financing);
      post_awarded_financing.amount = post_awarded_financing.amount.replace("$","").replaceAll(".","");
      axios
          .post("/projects/"+vm.project.id+"/save-awarded-financing.json", {
            financings_attributes: post_awarded_financing,
            project_id:vm.project.id
          })
          .then(res => {
            vm.errores_validacion = {};
            vm.awarded_financing = res.data;
            this.$buefy.dialog.alert({
              message: 'El financiamiento adjudicado fue asociado satisfactoriamente.',
              type: 'is-success',
              hasIcon: true,
              icon: 'check-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })

            //window.location.href = '/projects/' +response.data["id"]
            vm.isLoading=false;
          }).
      catch(error => {
        vm.errores_validacion = error.response.data
        var elmnt = document.getElementById("app");
        elmnt.scrollIntoView();
        vm.isLoading=false;
      })

    },
    async checkValidation() {
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
    updateFinancing(){
      this.getProjectFinancings();

    }


  }
}
</script>

<style scoped>

</style>