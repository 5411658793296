<template>
  <div class="container">
    <div class="box">
      <div class="section pt-0">
      <!-- Titulo de la sección -->
      <div class="is-flex is-justify-content-center">
        <h1 class="subtitle is-1 has-text-secondary">
          Manual de Uso
        </h1>
      </div>
      <b-tabs v-model="tab_index" expanded>
        <b-tab-item label="Actividad Académica" icon="file-invoice">
          <b-tabs v-model="academicActivityIndexTab" expanded>
            <b-tab-item label="Proyectos" icon="folder-open">
              <projects-tutorial v-bind:person="person"></projects-tutorial>
            </b-tab-item>
            <b-tab-item label="Publicaciones" icon="file-alt">
              <publications-tutorial v-bind:person="person"></publications-tutorial>
            </b-tab-item>
            <b-tab-item label="Patentes" icon="lightbulb">
              <patents-tutorial v-bind:person="person"></patents-tutorial>
            </b-tab-item>
            <b-tab-item label="Disclosures" icon="folder">
              <disclosures-tutorial v-bind:person="person"></disclosures-tutorial>
            </b-tab-item>
            <b-tab-item label="Búsqueda ORCID" icon="orcid" icon-pack="fab">
              <orcid-search-tutorial v-bind:person="person"></orcid-search-tutorial>
            </b-tab-item>
            <b-tab-item label="Vinculación con el medio" icon="folder">
              <vime-activity-tutorial v-bind:person="person"></vime-activity-tutorial>
            </b-tab-item>
          </b-tabs>


        </b-tab-item>
        <b-tab-item label="Hoja de Vida" icon="id-badge">
          <MhvTutorial></MhvTutorial>
        </b-tab-item>
        <b-tab-item label="Participantes Externos" icon="id-card">
          <participant-external-tutorial>

          </participant-external-tutorial>
        </b-tab-item>
      </b-tabs>

      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
    </div>
  </div>
</template>

<script>
import ProjectsTutorial from "./tutorials/projectsTutorial.vue";
import PublicationsTutorial from "./tutorials/publicationsTutorial.vue";
import PatentsTutorial from "./tutorials/patentsTutorial";
import DisclosuresTutorial from "./tutorials/disclosuresTutorial";
import MhvTutorial from "./mhv_tutorial"
import OrcidSearchTutorial from "./tutorials/orcidSearchTutorial";
import participantExternalTutorial from "./tutorials/participantExternalTutorial";
import vimeActivityTutorial from "./tutorials/vimeActivityTutorial";
export default {
  name: "Tutorials",
  components: {
    OrcidSearchTutorial,
    DisclosuresTutorial, PatentsTutorial, ProjectsTutorial,PublicationsTutorial, MhvTutorial,
    participantExternalTutorial, vimeActivityTutorial},
  props: ['person'],
  data: () => {
    return {
      isLoading: false,
      tutorial: "create_request",
      tutorial2: "view_request",
      tutorial3: "filter_requests",
      tutorial4: "create_request_type",
      tab_index: 0,
      academicActivityIndexTab: 0
    }
  },
  created() {
  },
  methods: {
  },

}
</script>

<style>
.b-sidebar .sidebar-content.is-absolute,
.b-sidebar .sidebar-content.is-static {
  width: 100%;
  background-color: white;
  box-shadow: none;
}
</style>