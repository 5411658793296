<template>
<div>
  <div class="container">
    <br>
    <div class="box">
      <b-tabs position="is-centered" class="block">
        <b-tab-item label="Errores de Personas" icon="user">
          <div class="columns">
            <div class="column is-narrow is-size-4">
              Búsqueda de ruts falsos y malos
              <b-tooltip
                  position="is-bottom"
                  size="is-large"
                  multilined>
                <b-icon
                    size="is-small"
                    pack="fas"
                    icon="question-circle"
                    type="is-info">
                </b-icon>
                <template v-slot:content>
                  Busca posibles ruts malos y sus asociaciones. Busca: <br>Rut falsos: ruts que son menores a 0.<br>Ruts malos: ruts que no coinciden entre su rut y su dv.
                </template>
              </b-tooltip>
            </div>
            <div class="column">
              <b-button @click.prevent="findBadRuts"
                        icon-left="file-excel" class="is-excel-color">
                Buscar y exportar
              </b-button>
            </div>
          </div>
          <div class="columns">
            <div class="column is-narrow is-size-4">
              Búsqueda de coincidencias entre nombres y correos
              <b-tooltip
                  position="is-bottom"
                  size="is-large"
                  multilined>
                <b-icon
                    size="is-small"
                    pack="fas"
                    icon="question-circle"
                    type="is-info">
                </b-icon>
                <template v-slot:content>
                  Busca posibles coincidencias entre nombres y correos de las Personas en SIGA. Busca:
                  <br> Coincidencias entre Nombre y Apellido Paterno.
                  <br> Coincidencias entre Apellido Paterno y Materno.
                  <br> Coincidencias entre correos corporativos.
                  <br> Coincidencias entre correos personales.
                </template>
              </b-tooltip>
            </div>
            <div class="column">
              <b-button @click.prevent="findBadNames"
                        icon-left="file-excel" class="is-excel-color">
                Buscar y exportar
              </b-button>
            </div>
          </div>
          <div class="columns">
            <div class="column is-narrow is-size-4">
              Búsqueda de nombramientos coincidentes o que merezcan atención
              <b-tooltip
                  position="is-bottom"
                  size="is-large"
                  multilined>
                <b-icon
                    size="is-small"
                    pack="fas"
                    icon="question-circle"
                    type="is-info">
                </b-icon>
                <template v-slot:content>
                  Busca posibles nombramientos activos erroneos. Busca:
                  <br> Persona con más de un nombramiento indefinido (sin fecha de término).
                  <br> Personas con nombramientos que sumen más de 44 horas contratadas.
                  <br> Personas con nombramientos mismo número de resolución.
                  <br> Personas con nombramiento con misma fecha inicio, fecha término y horas contratadas.
                </template>
              </b-tooltip>
            </div>
            <div class="column">
              <b-button @click.prevent="findBadAppointments"
                        icon-left="file-excel" class="is-excel-color">
                Buscar y exportar
              </b-button>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Errores de Publicaciones" icon="file-alt">
          <div class="columns">
            <div class="column is-narrow is-size-4">
              Búsqueda de Publicaciones con DOI o Títulos repetidos
              <b-tooltip
                  position="is-bottom"
                  size="is-large"
                  multilined>
                <b-icon
                    size="is-small"
                    pack="fas"
                    icon="question-circle"
                    type="is-info">
                </b-icon>
                <template v-slot:content>
                  Busca publicaciones con DOI o títulos repetidos.
                </template>
              </b-tooltip>
            </div>
            <div class="column">
              <b-button @click.prevent="findBadDois"
                        icon-left="file-excel" class="is-excel-color">
                Buscar y exportar
              </b-button>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>


  </div>
  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
</div>
</template>

<script>
import axios from "axios";
export default {
  name: "DataControlDashboard",
  data(){
    return{
      errores_validacion:{},
      isLoading:false,
      bad_ruts:[],
      fake_runs:[],
      badPublications:[],
    }
  },
  methods:{
    findBadRuts(){
      let vm = this;
      vm.isLoading = true;
      window.location.href = "data-control/bad-ruts/export.xlsx"
      vm.isLoading = false;
      /*return axios
        .get("/dashboards/data-control/bad-ruts.json")
        .then(res => {
          //console.log(res);
          vm.bad_ruts = res.data.bad_ruts;
          vm.fake_runs = res.data.fake_runs;
          vm.isLoading = false;
        })
        .catch(e => {
          //console.log(e);
          vm.isLoading = false;
        });*/
    },
    findBadNames(){
      window.location.href = "data-control/bad-names/export.xlsx"
    },
    findBadDois(){
      window.location.href = "data-control/repeated-publications/export.xlsx"
    },
    findBadAppointments(){
      window.location.href = "data-control/bad-appointments/export.xlsx"
    },

  }
}
</script>

<style scoped>

</style>