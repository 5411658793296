<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(verifyDocument)">

        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" ref="cardStudyCommissionForm">
            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion">
                      {{ item[0] }}
                    </div>
                  </b-message>
                </div>
              </div>
            </div>
            <div class="columns">
              <h3 class="is-size-3 has-text-weight-bold">Información Personal y Nombramiento</h3>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Nombres">
                  <b-input :value="person.nombre_completo!==undefined ? person.nombre_completo : ''" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column is-4">
                <b-field label="Rut">
                  <b-input :value="person.run_attributes!==undefined ? person.run_attributes.rut : ''" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Dv">
                  <b-input :value="person.run_attributes!==undefined ? person.run_attributes.dv : ''" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Seleccionar Nombramiento*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="nombramiento"
                                 placeholder="Seleccione Nombramiento"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false"
                                 :options="options"
                                 :multiple="false"
                                 group-values="person_units"
                                 group-label="type"
                                 label="custom_name"
                                 :group-select="false"
                                 expanded>
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                          No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
                <p class="control">
                  <b-button class="mt-2" @click.prevent="isShowPersonUnitActive = true" type="is-primary" :disabled="nombramiento===undefined||nombramiento===null">Mostrar Nombramiento</b-button>
                </p>
              </div>
            </div>
            <div class="columns">
              <h3 class="is-size-3 has-text-weight-bold">Información Comisión de Estudios</h3>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Lugar*">
                  <ValidationProvider :rules="{required: true, min: 5, max: 255, regex: /^[a-zA-Z0-9áéíóúÁÉÍÓÚÑñ ,.-\s]*$/}" v-slot="{ errors }">
                    <b-input v-model="commission.place" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="País*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <multiselect v-model="commission.nationality_id"
                                 :options="nationalities.map(type => type.id)"
                                 :custom-label="opt => nationalities.find(x => x.id === opt).name"
                                 placeholder="Seleccione País"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <h3 class="is-size-6 has-text-weight-bold">Institución*
              <b-tooltip
                  label="Ingrese al menos 3 caracteres para realizar una búsqueda. Si la entidad que desea agregar no existe, la puede agregar presionando el botón +"
                  position="is-bottom"
                  size="is-medium"
                  multilined>

                <b-icon
                    pack="fas"
                    icon="info-circle"
                    size="is-small"
                    type="is-info">
                </b-icon>
              </b-tooltip>
            </h3>
            <div class="columns">
              <div class="column">
                <b-field>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <multiselect v-model="commission.partner_entity_id"
                                 :options="partner_entities.map(type => type.id)"
                                 :custom-label="opt => partner_entities.find(x => x.id === opt).show_name"
                                 placeholder="Seleccione Institución"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 @search-change="searchPartnerEntity"
                                 :internal-search="false"
                                 :loading="isLoadingMultiselect"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <template slot="option" slot-scope="props">
                        {{ partner_entities.find(x => x.id === props.option).show_name ? partner_entities.find(x => x.id === props.option).show_name : partner_entities.find(x => x.id === props.option).name}}
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>

                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column is-narrow">
                <b-button class="is-primary is-outlined" @click="addEntity()">
                  <b-icon icon="plus"></b-icon>
                </b-button>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Fecha Solicitud Comisión">
                    <b-datepicker
                        v-model="commission.commission_request_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                    >
                    </b-datepicker>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Inicio Actividad*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-datepicker
                        v-model="commission.start_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                        @input="computeDays"
                    >
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha Fin Actividad*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-datepicker
                        v-model="commission.end_date"
                        icon="calendar-alt"
                        editable
                        trap-focus
                        locale="es-ES"
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                        @input="computeDays"
                    >
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <ValidationProvider rules="required|positive|integer" v-slot="{errors}">
                  <b-field label="Duración de la Actividad (n° días)">
                    <b-input v-model="commission.activity_length" type="number" disabled></b-input>
                  </b-field>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Tipo de documento">
                  <multiselect v-model="commission.resolution_type_id"
                               :options="resolution_types.map(type => type.id)"
                               :custom-label="opt => resolution_types.find(x => x.id === opt).name"
                               placeholder="Seleccione tipo de documento"
                               selectLabel="Presione para seleccionar"
                               selectedLabel="Seleccionado"
                               deselectLabel="Presione para deseleccionar"
                               :allow-empty="true"
                               @input="generateName">
                    <template v-slot:noOptions>
                      No existen datos
                    </template>
                    <span slot="noResult">
                        No se encontraron elementos.
                    </span>
                  </multiselect>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Número de documento">
                  <ValidationProvider rules="integer|positive" v-slot="{ errors }">
                    <b-input v-model="commission.resolution" type="text" :min="0" @input="generateName"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de documento">
                  <b-datepicker
                      v-model="commission.resolution_date"
                      icon="calendar-alt"
                      trap-focus
                      locale="es-ES"
                      editable
                      placeholder="Formato de Fecha: dd/mm/aaaa"
                      @input="generateName"
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <h3 class="is-size-3 has-text-weight-bold">Actividad a Realizar
              </h3>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Duración de los estudios (N° de semestres)">
                  <ValidationProvider :rules="{min: 0, max: 200, regex: /^[a-zA-Z0-9áéíóúÁÉÍÓÚÑñ  \\,\\.\\(\\)\\;\\:\\'\\s\\-]*$/}" v-slot="{ errors }">
                    <b-input v-model="commission.study_length" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label= "Nombre Programa*">
                  <ValidationProvider :rules="{required: true, min: 1, max: 200, regex: /^[a-zA-Z0-9áéíóúÁÉÍÓÚÑñ  \\,\\.\\(\\)\\;\\:\\'\\s\\-]*$/}" v-slot="{ errors }">
                    <b-input v-model="commission.study_program_name" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Interés para la Universidad">
                  <ValidationProvider :rules="{min: 5, max: 4000, regex: /^[a-zA-Z0-9áéíóúÁÉÍÓÚÑñ  \\,\\.\\(\\)\\;\\:\\'\\s.\n\\-]*$/}" v-slot="{ errors }">
                    <b-input v-model="commission.university_interest" type="textarea"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Objetivos de la Actividad*">
                  <ValidationProvider :rules="{required: true, min: 5, max: 4000, regex: /^[a-zA-Z0-9áéíóúÁÉÍÓÚÑñ  \\,\\.\\(\\)\\;\\:\\'\\s.\n\\-]*$/}" v-slot="{ errors }">
                    <b-input v-model="commission.objectives" type="textarea"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>

            <div class="columns">
              <h3 class="is-size-3 has-text-weight-bold">Idioma
              </h3>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Nombre del examen rendido">
                  <ValidationProvider :rules="{min: 1, max: 200, regex: /^[a-zA-Z0-9áéíóúÁÉÍÓÚÑñ ,.-\s]*$/}" v-slot="{ errors }">
                    <b-input v-model="commission.exam_name" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label= "Lugar del examen rendido">
                  <ValidationProvider :rules="{min: 5, max: 200, regex: /^[a-zA-Z0-9áéíóúÁÉÍÓÚÑñ ,.-\s]*$/}" v-slot="{ errors }">
                    <b-input v-model="commission.exam_place" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label= "Puntaje del examen rendido">
                  <ValidationProvider :rules="{min: 1, max: 200, regex: /^[a-zA-Z0-9áéíóúÁÉÍÓÚÑñ ,.-\s]*$/}" v-slot="{ errors }">
                    <b-input v-model="commission.exam_score" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
<!--            <div class="columns">
              <div class="column">
                <b-field label="Url carpeta documentación Comisión de Estudios">
                  <ValidationProvider v-slot="{ errors }" rules="max:255">
                    <b-input v-model="commission.documentation_link" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>-->
            <div class="columns">
              <div class="column">
                <h3 class="is-size-3 has-text-weight-bold">Financiamientos
                  <div class="is-pulled-right">
                    <b-button class="mt-2" @click.prevent="addFinancing" type="is-primary">Añadir Financiamiento</b-button>
                  </div>
                </h3>
                <div class="column">
                  <b-table
                      :data="commission.financings_attributes"
                      :paginated=true
                      per-page=5
                      striped
                      hoverable
                      :current-page="currentPage"
                      :pagination-simple=false
                      pagination-position="bottom"
                      default-sort-direction="asc"
                      sort-icon="arrow-up"
                      sort-icon-size="is-small"
                      default-sort="publication.title"
                      aria-next-label="Siguiente"
                      aria-previous-label="Anterior"
                      aria-page-label="Página"
                      aria-current-label="Página actual">
                    <b-table-column
                        field="partner_entity"
                        label="Institución de financiamiento"
                        sortable>
                      <template v-slot="props">
                        {{props.row.selected_entity.show_name}}
                      </template>
                    </b-table-column>

                    <b-table-column
                        field="financing_type"
                        label="Tipo Financiamiento"
                        sortable>
                      <template v-slot="props">
                        {{tipos_financiamiento.find(x => x.id == props.row.financing_type_id).name}}
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="amount"
                        label="Monto (CLP)"
                        sortable>
                      <template v-slot="props">

                        <money-format :value="props.row.amount!==undefined? parseInt(props.row.amount) : 0"
                                      locale='es-CL'
                                      currency-code='CLP'
                                      v-if="props.row.amount!==undefined && props.row.amount!==null"
                        >
                        </money-format>
                        <p v-if="props.row.amount===undefined || props.row.amount===null"></p>
                      </template>
                    </b-table-column>
                    <b-table-column
                        field="usd_amount"
                        label="Monto (USD)"
                        sortable>
                      <template v-slot="props">
                        <money-format :value="props.row.usd_amount!==undefined? parseInt(props.row.usd_amount) : 0"
                                      locale='en'
                                      currency-code='USD'
                                      v-if="props.row.usd_amount!==undefined && props.row.usd_amount!==null"

                        >
                        </money-format>
                        <p v-if="props.row.usd_amount===undefined || props.row.usd_amount===null"></p>
                      </template>
                    </b-table-column>
                    <b-table-column
                        label="Acciones"
                        width="10"
                        v-slot="props">
                      <b-icon
                          pack="fas"
                          icon="edit"
                          style="color: #000000;"
                          size="is-medium"
                          @click.native="editFinancing(props.index)"/>
                      <b-icon
                          pack="fas"
                          icon="trash"
                          style="color: #e50000;"
                          size="is-medium"
                          @click.native="deleteFinancing(props.index)"/>
                    </b-table-column>
                  </b-table>
                </div>

              </div>
            </div>

            <div v-if="queryType == 'update'" class="columns">
              <div class="column is-4">
                <b-field label="Nombre del documento">
                  <b-input v-model="document_name_update" type="text" :disabled="true"></b-input>
                </b-field>
              </div>
              <div class="column is-4">
                <b-field label="¿Desea cambiar o agregar un archivo?">
                  <b-radio-button v-model="changeFile"
                                  :native-value="true"
                                  type="is-success is-light is-outlined">
                    <b-icon icon="check-circle"></b-icon>
                    <span>Si</span>
                  </b-radio-button>
                  <b-radio-button v-model="changeFile"
                                  :native-value="false"
                                  type="is-danger is-light is-outlined">
                    <b-icon icon="times-circle"></b-icon>
                    <span>No</span>
                  </b-radio-button>
                </b-field>
              </div>
            </div>
            <div v-if="queryType == 'create' || changeFile" class="columns has-text-centered">
              <div class="column">
                <b-field extended label="Archivo PDF (Tamaño max. 25 MB)">
                  <b-upload v-model="file"
                            drag-drop
                            type="is-primary"
                            accept=".pdf"
                            @input="generateName">
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon
                              icon="file-pdf"
                              size="is-large">
                          </b-icon>
                        </p>
                        <p>Arrastra tu PDF o haz click para subir.</p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>
              </div>
            </div>
            <div v-if="queryType == 'create' || changeFile" class="columns tags is-centered">
              <span
                  v-if="file !== null"
                  v-model="file"
                  class="tag is-primary mt-1">
                {{ file.name }}
                <button
                    class="delete is-small"
                    type="button"
                    @click="onDeleteFile"
                ></button>
              </span>
            </div>
            <div v-if="file !== null" class="columns">
              <div class="column">
                <b-field label="Nombre del documento a subir">
                  <b-input disabled v-model="document_name"></b-input>
                </b-field>
              </div>
            </div>

          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit">Guardar </button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

    <b-modal
        v-model="isAddModalFinancingActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        width="1200px"
        :can-cancel="canCancel">
      <template #default="props">
        <modal-financing-icc
            query-type="update"
            modal-header="Añadir financiamiento"
            :tipo = "tipos_financiamiento"
            :actual_financing = "actualFinancing"
            :actual_index = "actualIndex"
            @close="props.close"
            @clicked="onClickChild2"></modal-financing-icc>
      </template>
    </b-modal>
    <b-modal
        v-model="isShowPersonUnitActive"
        :destroy-on-hide="true">
      <template #default="props">
        <info-appointment
            modal-header="Nombramiento Asociado"
            :nombramiento = "nombramiento"
            @close="props.close"
        ></info-appointment>
      </template>
    </b-modal>
    <b-modal
        v-model="isAddModalEntityActive"
        :destroy-on-hide="true"
        width="50%"
        :can-cancel="canCancel">
      <template #default="props">
        <modal_partner_entity_life_record
            query-type="create"
            modal-header="Añadir nueva entidad"
            @close="props.close"
            @clicked="onClickChild"></modal_partner_entity_life_record>
      </template>
    </b-modal>

  </section>
</template>

<script>
import axios from "axios";
import ModalFinancingIcc from "./modal_financing_icc";
import MoneyFormat from 'vue-money-format'
import InfoAppointment from "./info_appointment"
import moment from "moment";
import modal_partner_entity_life_record from "../partner_entity/modal_partner_entity_life_record";

export default {
  name: "modal_study_commission_form",
  props:[
    'queryType', 'personId', 'id', 'modalHeader', 'person_name'
  ],
  components:{
    modal_partner_entity_life_record,
    ModalFinancingIcc,
    InfoAppointment,
    'money-format': MoneyFormat
  },
  data(){
    return {
      commission:{
        financings_attributes:[
        ],
        person_id: this.personId,
        commission_type_id: 2,//COMISION DE ESTUDIOS
      },
      commission_types: [],
      nationalities: [],
      previous_commissions: [],
      isLoading: true,
      errores_validacion:[],
      partner_entities: [],
      isLoadingMultiselect: false,
      isAddModalFinancingActive: false,
      tipos_financiamiento: [],
      resolution_types: [],
      financing_types: ["BECA", "OTROS", "PASAJE", "MATRICULA", "VIATICO", "REMUNERACIONES"],
      actualFinancing: null,
      actualIndex: null,
      canCancel: [],
      cardLayout:false,
      currentPage:1,
      isShowPersonUnitActive: false,
      nombramiento: null,
      options: [{type: 'Inactivos', person_units: []}, {type: 'Activos', person_units: []}],
      person: {},
      isAddModalEntityActive: false,
      financiamientos: [{
        amount: null,
        year: null,
        financing_type: null,
        partner_entity: null
      }],
      file: null,
      document_name: null,
      changeFile: false,
      document_name_update: null,
      document_id: null,
    }
  },
  created() {
    this.isLoading=true;
    if (this.queryType === "create"){
      axios
          .get("/admin/commissions/new.json",
          ).then(res => {
        this.commission_types = res.data["commission_types"]
        this.nationalities = res.data["nationalities"]
        this.previous_commissions = res.data["previous_commissions"]
        this.tipos_financiamiento = res.data["tipos_financiamiento"].filter(x => this.financing_types.includes(x.name) === true);
        this.resolution_types = res.data["resolution_types"]
        axios
            .get("/admin/commissions/"+this.personId+"/person_commission.json",
            ).then(res => {
          this.person = res.data["person"]
          var i = 0
          for(i = 0; i<res.data["nombramientos_activos"].length;i++){
            this.options[1].person_units.push(res.data["nombramientos_activos"][i])
          }
          this.nombramiento = res.data["nombramientos_activos"].find(x => x.estamento === "ACADEMICOS");
          for(i = 0; i<res.data["nombramientos_inactivos"].length;i++){
            this.options[0].person_units.push(res.data["nombramientos_inactivos"][i])
          }

          this.isLoading=false;
        }).catch(error => {
          console.log(error)
          this.isLoading=false;
        })
      }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }
    else if (this.queryType === "update"){
      this.isLoading=true;
      axios
          .get("/admin/commissions/"+this.id+"/edit.json")
          .then(res => {
            this.commission = res.data
            this.commission.financings_attributes = res.data["financings"]
            this.person = res.data["person"]
            this.nombramiento = res.data["nombramiento"]
            this.commission_types = res.data["commission_types"]
            this.nationalities = res.data["nationalities"]
            this.previous_commissions = res.data["previous_commissions"]
            this.tipos_financiamiento = res.data["tipos_financiamiento"]
            this.resolution_types = res.data["resolution_types"]
            this.partner_entities.push(res.data["partner_entity"])
            var i = 0
            for(i = 0; i<res.data["nombramientos_activos"].length;i++){
              this.options[1].person_units.push(res.data["nombramientos_activos"][i])
            }
            for(i = 0; i<res.data["nombramientos_inactivos"].length;i++){
              this.options[0].person_units.push(res.data["nombramientos_inactivos"][i])
            }
            if(res.data["start_date"]!==null){
              this.commission.start_date = new Date(this.formatDate(res.data["start_date"]))
            }
            if(res.data["end_date"]!==null){
              this.commission.end_date = new Date(this.formatDate(res.data["end_date"]))
            }
            if(res.data["resolution_date"]!==null){
              this.commission.resolution_date = new Date(this.formatDate(res.data["resolution_date"]))
            }
            if(res.data["commission_request_date"]!==null){
              this.commission.commission_request_date = new Date(this.formatDate(res.data["commission_request_date"]))
            }
            this.computeDays()
            this.document_id =  res.data["document_id"]
            this.document_name_update = res.data["document_name"]
            this.document_name = res.data["document_name"]
            this.isLoading=false;
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }
  },
  methods: {
    addEntity(){
      this.isAddModalEntityActive = true;
    },
    computeDays(){
      this.isLoading = true
      if(this.commission.start_date && this.commission.end_date){
        var start = moment(this.commission.start_date, "DD/MM/AAAA");
        var end = moment(this.commission.end_date, "DD/MM/AAAA");
        this.commission.activity_length = Math.floor(moment.duration(end.diff(start)).asDays())+1
      }
      this.generateName()
      this.isLoading = false
    },
    addFinancing(){
      this.isAddModalFinancingActive = true
    },
    deleteFinancing(index){
      this.commission.financings_attributes.splice(index,1)
    },
    editFinancing(index){
      this.actualFinancing = this.commission.financings_attributes[index]
      this.actualIndex = index
      this.isAddModalFinancingActive = true
    },
    onClickChild(){
      this.$buefy.toast.open({
        message: 'La entidad se agregó correctamente, puede buscarla y agregarla',
        type: 'is-success',
        duration: 5000,
      })
    },
    onClickChild2(value){
      this.isLoading = true

      if (this.actualIndex != null){
        this.commission.financings_attributes[this.actualIndex].financing_type_id = value.financing_type_id
        this.commission.financings_attributes[this.actualIndex].partner_entity_id = value.partner_entity_id
        this.commission.financings_attributes[this.actualIndex].amount = value.amount
        this.commission.financings_attributes[this.actualIndex].usd_amount = value.usd_amount
        this.actualIndex = null
        this.actualFinancing = null
        this.isLoading = false
      }
      else{
        this.commission.financings_attributes.push(value)
        this.isLoading = false
      }



    },
    searchPartnerEntity(query){
      if(query.length<3){
        return
      }
      this.isLoadingMultiselect = true;
      this.commission.partner_entity_id = null;
      axios
          .get("/admin/partner_entities/search_partner_entities.json",
              {
                params: {
                  query: query
                }
              })
          .then(res => {
            this.partner_entities = res.data
            this.isLoadingMultiselect=false;

          }).catch(error => {
        console.log(error)
        this.isLoadingMultiselect=false;
      })

    },
    verifyDocument(){
      this.isLoading= true;
      let id = null
      if(this.queryType==="update"){
        id = this.commission.id
      }
      axios
          .get("/life_record/validate_resolution_number.json", {
            params: {res_number: this.commission.resolution, query_type: this.queryType,
              entity_type: "commission", id: id}
          })
          .then(response =>{
            this.saveStudyCommission()
          }).catch(error => {
        if(error.response.status === 409){ //se encuentra
          this.$buefy.dialog.confirm({
            hasIcon: true,
            type: "is-warning",
            title: "Advertencia",
            message: error.response.data["message"],
            confirmText: "Continuar y guardar",
            cancelText: "Cancelar",
            onConfirm: () => this.saveStudyCommission(),
            onCancel: () => this.isLoading = false
          })
        }
        else if(error.response.status === 400){//bad request
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.cardStudyCommissionForm.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al agregar la comisión de estudios, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
        }
      })
    },
    saveStudyCommission(){
      if(this.nombramiento){
        this.commission.person_unit_id = this.nombramiento.id
      }
      this.isLoading=true;
      if (this.queryType==="create"){
        if (this.file != null && this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Este archivo no tiene formato PDF",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        if (this.file != null && this.file.size > 1024*1024*25) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "El archivo no puede superar el maximo de 25 MB",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        /*if (this.file != null && (this.commission.resolution_date ==  null || this.commission.resolution_date ==  undefined) &&
            (this.commission.resolution == null || this.commission.resolution == undefined || this.commission.resolution == '') &&
            (this.commission.resolution_type_id == null || this.commission.resolution_type_id == undefined)) {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Para adjuntar un archivo, debe llenar al menos uno de los campos del documento",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }*/

        // Crea un objeto FormData
        let formData = new FormData();

        // Agrega datos al objeto FormData
        formData.append("commission", JSON.stringify(this.commission));
        formData.append("file", this.file); // Aquí agregamos el archivo
        formData.append("document_name", this.document_name);
        axios
            .post("/admin/commissions/create-document.json", formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
            .then(response =>{
              this.errores_validacion = {};
              this.$buefy.dialog.alert({
                message: 'La comisión de estudios fue creada con éxito ',
                confirmText: 'Ok'
              })
              this.isLoading=false;
              this.errors = [];
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.cardStudyCommissionForm.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al agregar la comisión de estudios, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
        })
      }
      else if (this.queryType==="update"){
        if (this.file != null && this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Este archivo no tiene formato PDF",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        if (this.file != null && this.file.size > 1024*1024*25) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "El archivo no puede superar el maximo de 25 MB",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        /*if (this.file != null && (this.commission.resolution_date ==  null || this.commission.resolution_date ==  undefined) &&
            (this.commission.resolution == null || this.commission.resolution == undefined || this.commission.resolution == '') &&
            (this.commission.resolution_type_id == null || this.commission.resolution_type_id == undefined)) {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Para adjuntar un archivo, debe llenar al menos uno de los campos del documento",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }*/

        // Crea un objeto FormData
        let formData = new FormData();
        let cleanCommission = this.deleteData(this.commission)

        // Agrega datos al objeto FormData
        formData.append("commission", JSON.stringify(cleanCommission));
        formData.append("file", this.file); // Aquí agregamos el archivo
        formData.append("document_name", this.document_name);

        axios
            .put("/admin/commissions/" + this.id+"/update-document.json", formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
            .then(response => {
              this.$buefy.dialog.alert({
                message: 'La comisión de estudios fue editada con éxito ',
                confirmText: 'Ok'
              })
              this.errores_validacion = {};
              this.isLoading = false;
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          let elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.cardStudyCommissionForm.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al editar la comisión de estudios, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
        })
      }
    },
    formatDate(date){
      if (date!=null && date!=="" && date!==undefined){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
    getFilteredTags(text) {
      this.funcion_filtrada = this.functions.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    onDeleteFile() {
      this.file = null;
    },
    generateName(){
      var tipo = "COMISION"
      var number = ""
      var date = ""
      var start_date = ""
      var end_date = ""
      var full_name = "-"+this.person_name

      if (this.commission.resolution_type_id!= null){
        var nombre_tipo = this.resolution_types.find(x => x.id === this.commission.resolution_type_id).name
        if(nombre_tipo.includes("RESOLUCION")){
          tipo = "RES"
        }
        else if(nombre_tipo.includes("DECRETO")){
          tipo = "DECR"
        }
        //tipo = this.resolution_types.find(x => x.id === this.person_unit.resolution_type_id).name
        //tipo = this.documentTypesNames.find(type => type.id === this.document.document_type_id).initials
      }

      if (this.commission.resolution != null && this.commission.resolution != ''){
        number = "-N_" + this.commission.resolution
      }

      if (this.commission.resolution_date != null){
        date = "-"+("0" + this.commission.resolution_date.getDate()).slice(-2)
            + "_" + ("0" + (this.commission.resolution_date.getMonth()+1)).slice(-2)
            + "_" + this.commission.resolution_date.getFullYear()
      }

      if (this.commission.start_date != null){
        start_date = "-"+("0" + this.commission.start_date.getDate()).slice(-2)
            + "_" + ("0" + (this.commission.start_date.getMonth()+1)).slice(-2)
            + "_" + this.commission.start_date.getFullYear()
      }

      if (this.commission.end_date != null){
        end_date = "-"+("0" + this.commission.end_date.getDate()).slice(-2)
            + "_" + ("0" + (this.commission.end_date.getMonth()+1)).slice(-2)
            + "_" + this.commission.end_date.getFullYear()
      }

      if ((this.commission.resolution_date == null || this.commission.resolution_date == '')  &&
          (this.commission.resolution == null || this.commission.resolution == '')){
        tipo = "COMISION"
        this.document_name = tipo + full_name + start_date + end_date
      }
      else{
        this.document_name = tipo + number + date
      }
    },
    deleteData(commission){
      delete commission.can_view_pdf
      delete commission.document_name
      delete commission.document_id
      delete commission.nombramientos_inactivos
      delete commission.nombramientos_activos
      delete commission.nombramiento
      delete commission.person
      delete commission.partner_entity
      delete commission.financings
      delete commission.resolution_types
      delete commission.tipos_financiamiento
      delete commission.previous_commissions
      delete commission.nationalities
      delete commission.commission_types
      delete commission.resolution_type
      delete commission.nationality
      delete commission.commission_type


      return commission
    },
  }
}
</script>
