<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveGraduate)">
        <div class="modal-card" style="width: auto" id="modal-graduate">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalHeader}}</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
          </header>
          <section class="modal-card-body" id="modal-graduate2" ref="modalGraduateForm">
            <div class="columns">
              <div class="column">
                <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                  <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion">
                      {{ item[0] }}
                    </div>
                  </b-message>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Nombre*">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-input v-model="continuing_study.name" type="text" minlength="3" maxlength="255" @input="generateName"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <h3 class="is-size-6 has-text-weight-bold">Institución*
              <b-tooltip
                  label="Ingrese al menos 3 caracteres para realizar una búsqueda. Si la entidad que desea agregar no existe, la puede agregar presionando el botón +"
                  position="is-bottom"
                  size="is-medium"
                  multilined>

                <b-icon
                    pack="fas"
                    icon="info-circle"
                    size="is-small"
                    type="is-info">
                </b-icon>
              </b-tooltip>
            </h3>
            <div class="columns">
              <div class="column">
                <b-field>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <multiselect v-model="continuing_study.partner_entity_id"
                                 :options="partner_entities.map(type => type.id)"
                                 :custom-label="opt => partner_entities.find(x => x.id === opt).show_name"
                                 placeholder="Seleccione Institución"
                                 selectLabel="Presione para seleccionar"
                                 selectedLabel="Seleccionado"
                                 deselectLabel="No se puede deseleccionar"
                                 @search-change="searchPartnerEntity"
                                 :internal-search="false"
                                 :loading="isLoadingMultiselect"
                                 :allow-empty="false">
                      <template v-slot:noOptions>
                        No existen datos
                      </template>
                      <template slot="option" slot-scope="props">
                        {{ partner_entities.find(x => x.id === props.option).show_name ? partner_entities.find(x => x.id === props.option).show_name : partner_entities.find(x => x.id === props.option).name}}
                      </template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>

                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column is-narrow">
                <b-button class="is-primary is-outlined" @click="addEntity()">
                  <b-icon icon="plus"></b-icon>
                </b-button>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Semestres">
                  <ValidationProvider rules="numeric|integer|positive" v-slot="{ errors }">
                    <b-input  v-model="continuing_study.semester" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Horas">
                  <ValidationProvider rules="numeric|integer|positive" v-slot="{ errors }">
                    <b-input  v-model="continuing_study.hours" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Fecha de Obtención*">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-datepicker
                        v-model="continuing_study.obtaining_date"
                        icon="calendar-alt"
                        trap-focus
                        locale="es-ES"
                        editable
                        placeholder="Formato de Fecha: dd/mm/aaaa"
                    >
                    </b-datepicker>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
<!--            <div class="columns">
              <div class="column">
                <b-field label="Url carpeta documentación Cursos">
                  <ValidationProvider v-slot="{ errors }" rules="max:255">
                    <b-input v-model="continuing_study.documentation_link" type="text"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>-->
            <div class="columns">
              <div class="column">
                <b-field label="Descripción">
                  <ValidationProvider rules="" v-slot="{ errors }">
                    <b-input v-model="continuing_study.description" type="textarea"  maxlength="255"></b-input>
                    <span class="validation-alert">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
            <h3 class="is-size-6 has-text-weight-bold">Áreas/Habilidades del Curso
              <b-tooltip
                  label="Para agregar un área, presione enter o coma para que esta sea guardada."
                  position="is-bottom"
                  size="is-medium"
                  multilined>

                <b-icon
                    pack="fas"
                    icon="info-circle"
                    size="is-small"
                    type="is-info">
                </b-icon>
              </b-tooltip>
            </h3>
            <div class="columns">
              <div class="column">
                <b-field label=" ">
                  <b-taginput
                      v-model="continuing_study.areas_attributes"
                      :data="filtered_areas"
                      type="is-primary is-light"
                      autocomplete
                      :allow-new="true"
                      field="name"
                      icon="tag"
                      placeholder="Agrega un área/habilidad"
                      @typing="getFilteredAreas">
                  </b-taginput>
                </b-field>
              </div>
            </div>
            <div v-if="queryType == 'update'" class="columns">
              <div class="column is-4">
                <b-field label="Nombre del documento">
                  <b-input v-model="document_name_update" type="text" :disabled="true"></b-input>
                </b-field>
              </div>
              <div class="column is-4">
                <b-field label="¿Desea cambiar o agregar un archivo?">
                  <b-radio-button v-model="changeFile"
                                  :native-value="true"
                                  type="is-success is-light is-outlined">
                    <b-icon icon="check-circle"></b-icon>
                    <span>Si</span>
                  </b-radio-button>
                  <b-radio-button v-model="changeFile"
                                  :native-value="false"
                                  type="is-danger is-light is-outlined">
                    <b-icon icon="times-circle"></b-icon>
                    <span>No</span>
                  </b-radio-button>
                </b-field>
              </div>
            </div>
            <div v-if="queryType == 'create' || changeFile" class="columns has-text-centered">
              <div class="column">
                <b-field extended label="Archivo PDF (Tamaño max. 25 MB)">
                  <b-upload v-model="file"
                            drag-drop
                            type="is-primary"
                            accept=".pdf"
                            @input="generateName">
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon
                              icon="file-pdf"
                              size="is-large">
                          </b-icon>
                        </p>
                        <p>Arrastra tu PDF o haz click para subir.</p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>
              </div>
            </div>
            <div v-if="queryType == 'create' || changeFile" class="columns tags is-centered">
              <span
                  v-if="file !== null"
                  v-model="file"
                  class="tag is-primary mt-1">
                {{ file.name }}
                <button
                    class="delete is-small"
                    type="button"
                    @click="onDeleteFile"
                ></button>
              </span>
            </div>
            <div v-if="file !== null" class="columns">
              <div class="column">
                <b-field label="Nombre del documento a subir">
                  <b-input disabled v-model="document_name"></b-input>
                </b-field>
              </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot actions">
          <div class="field is-grouped is-pulled-right">
            <button class="button" type="button" @click="$emit('close')">Cerrar</button>
            <button class="button is-primary" type="submit" :disabled="isDisabled">Guardar </button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

    <b-modal
        v-model="isAddModalEntityActive"
        :destroy-on-hide="true"
        width="50%"
        :can-cancel="canCancel">
      <template #default="props">
        <modal-partner-entity-life-record
            query-type="create"
            modal-header="Añadir nueva entidad"
            @close="props.close"
            @clicked="onClickChild"></modal-partner-entity-life-record>
      </template>
    </b-modal>

  </section>
</template>

<script>
import axios from "axios";
import ModalPartnerEntityLifeRecord from "../partner_entity/modal_partner_entity_life_record"
export default {
  name: "modal_graduate_form",
  props:[
    'queryType', 'personId', 'id', 'modalHeader'
  ],
  components: {
    ModalPartnerEntityLifeRecord
  },
  data(){
    return {
      continuing_study:{continuing_studies_type_id: 2, continuing_studies_state_id: 1,
      areas_attributes: []},
      errores_validacion:{},
      partner_entities: [],
      isLoadingMultiselect: false,
      isLoading:false,
      isDisabled: false,
      isAddModalEntityActive: false,
      canCancel: [],
      filtered_areas: [],
      areas: [],
      file: null,
      document_name: null,
      changeFile: false,
      document_name_update: null,
      document_id: null,
      person: null,
    }
  },
  async created() {
    if (this.queryType === "create"){
      this.isLoading=true;
      await axios
          .get("/admin/continuing_studies/new.json")
          .then(res => {
            this.continuing_study.person_id = this.personId
            this.areas = res.data["areas"]
          }).catch(error => {
            console.log(error)
            this.isLoading = false
      })
      await axios
          .get("/people/" + this.personId + ".json")
          .then(res => {
            this.person = res.data

          }).catch(error => {
            console.log(error)
            this.isLoading = false;
          })
      this.isLoading = false
    }
    else if (this.queryType === "update"){
      this.isLoading=true;
      axios
          .get("/admin/continuing_studies/"+this.id+"/edit.json")
          .then(res => {
            this.continuing_study = res.data
            this.partner_entities.push(res.data["partner_entity"])
            if(res.data["obtaining_date"]!==null){
              this.continuing_study.obtaining_date = new Date(this.formatDate(res.data["obtaining_date"]))
            }
            this.filtered_areas = res.data["all_areas"]
            this.document_id =  res.data["document_id"]
            this.document_name_update = res.data["document_name"]
            this.document_name = res.data["document_name"]
            this.person = res.data["person"]
            this.isLoading = false
          }).catch(error => {
        console.log(error)
        this.isLoading=false;
      })
    }
  },
  methods: {
    onClickChild(){
      this.$buefy.toast.open({
        message: 'La entidad se agregó correctamente, puede buscarla y agregarla',
        type: 'is-success',
        duration: 5000,
      })
    },
    searchPartnerEntity(query){
      if(query.length<3){
        return
      }
      this.isLoadingMultiselect = true;
      this.continuing_study.partner_entity_id = null;
      axios
          .get("/admin/partner_entities/search_partner_entities.json",
              {
                params: {
                  query: query
                }
              })
          .then(res => {
            this.partner_entities = res.data
            this.isLoadingMultiselect=false;

          }).catch(error => {
        console.log(error)
        this.isLoadingMultiselect=false;
      })
    },
    saveGraduate(){
      this.isLoading=true;
      this.isDisabled=true;
      if (this.queryType==="create"){
        if (this.file != null && this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Este archivo no tiene formato PDF",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        if (this.file != null && this.file.size > 1024*1024*25) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "El archivo no puede superar el maximo de 25 MB",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        if (this.file != null && !this.continuing_study.name) {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Para adjuntar un archivo, debe al menos especificar el nombre del estudio",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        // Crea un objeto FormData
        let formData = new FormData();

        // Agrega datos al objeto FormData
        formData.append("continuing_study", JSON.stringify(this.continuing_study));
        formData.append("file", this.file); // Aquí agregamos el archivo
        formData.append("document_name", this.document_name);
        axios
            .post("/admin/continuing_studies/create-document.json", formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
            .then(response =>{
              this.errores_validacion = {};
              this.$buefy.dialog.alert({
                message: 'El curso fue creado con éxito ',
                confirmText: 'Ok'
              })
              this.isLoading=false;
              this.isDisabled=false;
              this.errors = [];
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalGraduateForm.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al agregar el curso, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading=false;
          this.isDisabled=false;
        })
      }
      else if (this.queryType==="update"){
        if (this.file != null && this.file.name.split(".").at(-1).toLowerCase() !== "pdf") {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Este archivo no tiene formato PDF",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        if (this.file != null && this.file.size > 1024*1024*25) {
          this.file = null;
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "El archivo no puede superar el maximo de 25 MB",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        if (this.file != null && !this.continuing_study.name) {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: "Para adjuntar un archivo, debe al menos especificar el nombre del estudio",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }

        // Crea un objeto FormData
        let formData = new FormData();
        let clean_continuing_study = this.deleteData(this.continuing_study)

        // Agrega datos al objeto FormData
        formData.append("continuing_study", JSON.stringify(clean_continuing_study));
        formData.append("file", this.file); // Aquí agregamos el archivo
        formData.append("document_name", this.document_name);
        axios
            .put("/admin/continuing_studies/" + this.id+"/update-document.json", formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
            .then(response => {
              this.$buefy.dialog.alert({
                message: 'El curso fue editado con éxito ',
                confirmText: 'Ok'
              })
              this.errores_validacion = {};
              this.isLoading = false;
              this.continuing_study = {}
              this.isLoading=false;
              this.$emit('close')
            }).catch(error => {
          this.errores_validacion = error.response.data
          let elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.modalGraduateForm.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores al editar el curso, favor revisar formato',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isDisabled=false;
          this.isLoading=false;
        })
      }
    },
    addEntity(){
      this.isAddModalEntityActive = true;
    },
    formatDate(date){
      if(date != null && date!==""){
        return date.replace(/-/g,"/")
      }
      else{
        return ""
      }
    },
    getFilteredAreas(text){
      this.filtered_areas = this.areas.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    onDeleteFile() {
      this.file = null;
    },
    generateName(){
      var tipo = "EST"
      var rut = ""
      var nombre_estudio = ""

      if(this.person != null){
        rut = "-RUT_"+this.person.run.rut.toString()+"_"+this.person.run.dv
      }
      if (this.continuing_study){
        nombre_estudio = "-"+this.continuing_study.name.toUpperCase()
      }

      this.document_name = tipo + rut + nombre_estudio
    },
    deleteData(continuing_study){
      delete continuing_study.can_view_pdf
      delete continuing_study.document_name
      delete continuing_study.document_id
      delete continuing_study.all_areas
      //delete continuing_study.areas_attributes
      delete continuing_study.areas
      delete continuing_study.partner_entity
      delete continuing_study.continuing_studies_state
      delete continuing_study.continuing_studies_type
      delete continuing_study.person

      return continuing_study
    },
  }
}
</script>

