<template>
    <div class="configurationactivityeditchangetypemodal">
        <form action="">
            <div class="modal-card" style="width: auto;">
                <header class="modal-card-head" style="background-color: #8c67ef; text-align:center;">
                    <p class="modal-card-title" style="color:#fff;">Cambio de tipo de actividades</p>
                    <button
                        type="button"
                        class="delete"
                        @click="$emit('close')"/>
                </header>
                <section class="modal-card-body">
                    <b-field label="Tipo de actividad"
                        :type="{ 'is-danger': checkActivityType }"
                        :message="{ 'Seleccione el nuevo tipo de actividad.': checkActivityType }">
                        <b-select placeholder="Seleccione un tipo de actividad" v-model='newActivityType'>
                            <option
                                v-for="activity_type in filteredActivities"
                                :value="activity_type.id"
                                :key="activity_type.id">
                                {{ activity_type.name }}
                            </option>
                        </b-select>
                    </b-field>
                </section>
                <footer class="modal-card-foot">
                    <b-button
                        label="Cerrar"
                        @click="$emit('close')" />
                    <b-button
                        label="Añadir"
                        type="is-primary"
                        @click="changeActivityType()"/>
                </footer>
            </div>
        </form>
    </div>
</template>
<script>
export default {
    name: 'ConfigurationActivityEditChangeTypeModal',
    props: ['vime_activity_types', 'activity_type_to_delete'],
    data(){
        return {
            activityTypeId: null,
            validations:{
                firstTimeActivityType: true
            }
        }
    },
    computed:{
        filteredActivities(){
            return this.vime_activity_types.filter(element => element != this.activity_type_to_delete)
        },
        newActivityType: {
            get(){
                return this.activityTypeId
            },
            set(newType){
                this.validations.firstTimeActivityType = false
                this.activityTypeId = newType
            }
        },
        checkActivityType() {
            if(this.validations.firstTimeActivityType)
                return false
            else
                return this.activityTypeId === null
        }
    },
    methods:{
        checkEmptyFields(){
            if(this.validations.firstTimeActivityType === true){
                this.validations.firstTimeActivityType = false
            }
        },
        checkErase(){
            return this.newActivityType !== null
        },
        changeActivityType(){
            var confirmation = this.checkErase()
            if(confirmation){
                this.$emit('change-activity-type', {
                    new_activity_type: this.activityTypeId,
                    delete_activity_type: this.activity_type_to_delete
                });
                this.$emit('close');
            }
            else{
                this.checkEmptyFields()    
            }
        }
    }
}
</script>